import React, { PureComponent } from 'react';
import { fields } from './schema';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { connect } from '../../../../index';
import { IFormRenderProps } from '../../../../common/forms/Form';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { PRESTATION_CODE } from '../../../../../config/navigation/interventions.config';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';

const CLASS_NAME = 'InterventionRequest__Intervention';

export interface IFormValues {
    type: string;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
}

interface IPrivateProps {
    translator: ITranslator;
}

class InterventionInput extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.getPrestationCodesForTypeahead = this.getPrestationCodesForTypeahead.bind(this);
    }

    private getPrestationCodesForTypeahead = (): TTypeaheadData => {
        const translationKeyPart = 'interventions.intervention_request_new.steps.intervention.fields.type.values';

        return Object.keys(PRESTATION_CODE).map((key) => {
            const prestationCodeTranslation = this.props.translator(`${translationKeyPart}.${PRESTATION_CODE[key]}`);

            return ({
                value: PRESTATION_CODE[key],
                label: prestationCodeTranslation,
            });
        });
    };

    public render() {
        const { formRenderProps, translator } = this.props;

        const typeTranslation =
            translator('interventions.intervention_request_new.steps.intervention.fields.type.label');

        return (
            <div className={CLASS_NAME}>
                <Typeahead
                    id={fields.type}
                    name={fields.type}
                    placeholder={typeTranslation}
                    value={formRenderProps.values.type}
                    data={this.getPrestationCodesForTypeahead()}
                    onItemSelected={(value) => formRenderProps.setFieldValue('type', String(value))}
                    isInvalid={formRenderProps.touched.type && !!formRenderProps.errors.type}
                >
                    {formRenderProps.touched.type && (
                        <FormFieldError
                            error={formRenderProps.errors.type}
                            placeholders={{ fieldName: typeTranslation }}
                        />
                    )}
                </Typeahead>
            </div>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
        };
    },
})(InterventionInput);
