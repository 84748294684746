export default function convertBase64ToArrayBuffer(base64: string) {
    const binaryString = atob(base64.replace(/^data\:([^\;]+)\;base64,/gmi, ''));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i = i + 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
}
