import { IDocumentCenterItem } from '../../models/general/navigation';
import ROUTE_KEYS from '../../routeKeys';
import { DOCUMENT_CENTER_SVG_IDS } from '../../models/general/lazyLoadSvg';

export const DOCUMENT_CENTER_ITEMS: IDocumentCenterItem[] = [
    {
        id: 'doc-center-medical-docs',
        linkTo: ROUTE_KEYS.R_MEDICAL_DOCUMENTS,
        titleTranslationKey: 'document_center.overview.main.medical_docs',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.MEDICAL_DOCS,
    },
    {
        id: 'doc-center-risk-mgmt-docs',
        linkTo: ROUTE_KEYS.R_RISK_MGMT_DOCUMENTS,
        titleTranslationKey: 'document_center.overview.main.risk_mgmt_docs',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.RISK_MANAGEMENT,
    },
    {
        id: 'doc-center-training-descriptions',
        linkTo: ROUTE_KEYS.R_COURSES_OVERVIEW,
        titleTranslationKey: 'document_center.overview.main.training_descriptions',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.COURSES_LEARN_CONNECT,
    },
    {
        id: 'doc-center-work-post-cards',
        linkTo: ROUTE_KEYS.R_WORK_POST_CARDS,
        titleTranslationKey: 'document_center.overview.main.work_post_cards',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.WORK_POST_CARD,
    },

    {
        id: 'doc-center-templates',
        linkTo: ROUTE_KEYS.R_TEMPLATES,
        titleTranslationKey: 'document_center.overview.main.templates',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.TEMPLATES,
    },
];

export const DOCUMENT_CENTER_MANAGEMENT_ITEMS: IDocumentCenterItem[] = [
    {
        id: 'doc-center-manage-identification-doc',
        linkTo: ROUTE_KEYS.R_MANAGE_IDENTIFICATION_DOCUMENT,
        titleTranslationKey: 'document_center.overview.sub.manage_identification_doc',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.FILE_ID,
    },
    {
        id: 'doc-center-accession-agreement',
        linkTo: ROUTE_KEYS.R_ACCESSION_AGREEMENT,
        titleTranslationKey: 'document_center.overview.sub.accession_agreement',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.FILE_AGREE,
    },
    {
        id: 'doc-center-company-and-employee-report',
        linkTo: ROUTE_KEYS.R_COMPANY_AND_EMPLOYEE_REPORT,
        titleTranslationKey: 'document_center.overview.sub.company_and_employee_report',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.FILE_INFO,
    },
    {
        id: 'doc-center-activity-report',
        linkTo: ROUTE_KEYS.R_ACTIVITY_REPORT,
        titleTranslationKey: 'document_center.overview.sub.activity_report',
        lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS.REPORT,
    },
];

export const DOC_URL = {
    // eslint-disable-next-line max-len
    psycho_social_prevention_advisors_nl: 'https://www.mensura.be/uploads/media/54b8e8b02bbf1/ninf140101-v14-identificatiedocument-bijlage-lijst-van-de-preventieadviseurs-psychosociaal.pdf',
    // eslint-disable-next-line max-len
    psycho_social_prevention_advisors_fr: 'https://www.mensura.be/uploads/media/54bccbc2e8e53/finf140101-v14-document-didentification-annexe-liste-des-conseillers-en-prevention-aspects-psychosociaux.pdf',
};
