import React from 'react';
import PageHeader from '../../../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../../../models/general/stepper';
import classNames from 'classnames';
import { getTranslatorDeprecated } from '../../../../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import { IAddUserAccountPayload } from '../../../../../../../../models/user/userAccount';
import connect from '../../../../../../../../utils/libs/redux/connect';
import { getAddUserWizardData } from '../../../../../../../../redux/company/users/selectors';
import { setAddCompanyUserWizardData } from '../../../../../../../../redux/company/users/actions';
import { TUserDataFormValues } from '../../common/userDataModels';
import { WIZARDFLOW_CLASSES } from '../../../../../../../common/navigation/Wizard/index';
import PersonalDataForm from '../PersonalDataForm';
import { tryFormattingPhoneForBackend } from '../../../../../../../../utils/formatting/formatPhone';

interface IPrivateProps {
    translator: ITranslator;
    addUserWizardData: Partial<IAddUserAccountPayload>;
    setAddUserWizardData: (values: TUserDataFormValues) => void;
}

function PersonalData(props: IStepperStepRenderProps & IPrivateProps) {
    return (
        <>
            <PageHeader
                title="account.account_settings.manage_users.add.wizard.steps.personal_data.title"
                text="account.account_settings.manage_users.add.wizard.steps.personal_data.text"
            />
            <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                <PersonalDataForm
                    handleSubmit={(values: TUserDataFormValues) => {
                        const formattedValues: TUserDataFormValues = {
                            ...values,
                            phoneNumber: tryFormattingPhoneForBackend(values.phoneNumber),
                            mobilePhoneNumber: tryFormattingPhoneForBackend(values.mobilePhoneNumber),
                        };
                        props.setAddUserWizardData(formattedValues);
                        props.goToNextStep();
                    }}
                    show={true}
                    {...props}
                />
            </div>
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            addUserWizardData: getAddUserWizardData(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            setAddUserWizardData: (values) => dispatch(setAddCompanyUserWizardData(values)),
        };
    },
})(PersonalData);
