import isSet from '@snipsonian/core/es/is/isSet';
import { ErrorTypes } from '../../../models/general/error';
import string from './string';

interface INumberValidatorConfig {
    minValue?: number;
}

/**
 * Custom number validator because the default yup number validator does a parseFloat
 * causing for example '5er' to be parsed to '5' which is not strict enough.
 */
export default (config: INumberValidatorConfig = {}) => {
    const validator = string().nullable(true)
        .matches(/^[0-9]+$/, { message: ErrorTypes.InvalidNumber });

    if (isSet(config.minValue)) {
        return validator.test(
            ErrorTypes.MinValue,
            'The \'${path}\' is not valid',
            (value) => value >= config.minValue,
        );
    }

    return validator;
};
