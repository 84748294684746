import { UrlBuilder, ModalSurvey } from '@hello-customer/website-touchpoint';

import { TCompanyStatisticalCode } from '../../../config/company/companyInfo.config';

import { getBackendLocale } from '../../../redux/i18n/selectors';

import { getStore } from '../../../redux/storeNoCircularDependencies';

import { ENVS } from '../../../config/envs.config';

export const modalSurvey = (klantsegment: TCompanyStatisticalCode = null) => new ModalSurvey(new UrlBuilder({
    baseUrl: 'https://mensura.hellocustomer.cloud',
    tenantId: '121ff460-42e3-47e1-802a-f83071caea9e',
    touchPointId: '486c7fe8-dbd0-44df-ab5b-34fa737686ff',
    language: getBackendLocale(getStore().getState()),
    extra: {
        isPreview: window.klantenzone && window.klantenzone.ENV !== ENVS.PROD,
        metadata: {
            ...!!klantsegment && {
                klantsegment,
            },
        },
    },
}), {
    quarantineConfig: {
        period: 365,
    },
});

export const LOGIN_COUNT_SHOW_HELLO_CUSTOMER_THRESHOLD = 3;
