import React from 'react';
import './message-center.scss';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getSelectedUserMessageId,
    getMessageCenterAsyncInfo,
    getUserMessagesWithMessageType,
} from '../../../redux/inbox/selectors';
import {
    IRenderSearchContentProps,
    IRenderDetailContentProps,
    IRenderFilterContentProps,
} from '../../common/widget/MasterWithDetail/typings';
import { IUserMessageDetails, IUserMessagesFilters } from '../../../models/user/inbox';
import Detail from './Detail';
import {
    MessagesSearch,
    getDefaultQueryParams,
    MessagesFilter,
    messagesClientSideFilterOfListData,
    messagesTransformFilterValuesToActiveFilters,
} from './MessageFilter';
import MessageCenterOverview, { COLUMNS, mapMessagesToListItems } from './Overview';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { BASE_NAME, TMessageCenterProps } from './common';

const CLASS_NAME = 'MessageCenterMasterWithDetail';

export default function MessageCenter() {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            className={CLASS_NAME}
            classNameWhenOnDetailRoute={`${CLASS_NAME}--open`}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MESSAGE_CENTER,
                asyncInfoSelector: getMessageCenterAsyncInfo,
                dataSelector: getUserMessagesWithMessageType,
                transformData: mapMessagesToListItems,
                transformFilterValuesToActiveFilters: messagesTransformFilterValuesToActiveFilters,
                renderContent: (renderProps: TMessageCenterProps) =>
                    <MessageCenterOverview {...renderProps} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData: messagesClientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            getDefaultQueryParams={getDefaultQueryParams}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IUserMessagesFilters>) =>
                    <MessagesSearch {...renderProps} />,
                renderFilterContent: (renderProps: IRenderFilterContentProps<{}, IUserMessagesFilters>) =>
                    <MessagesFilter {...renderProps} />,
            }}
            detailConfig={{
                routeKey: ROUTE_KEYS.R_MESSAGE_CENTER_DETAIL,
                idRouteParamName: 'id',
                renderContent: (renderProps: IRenderDetailContentProps<IUserMessageDetails>) =>
                    <Detail {...renderProps} />,
                idSelector: getSelectedUserMessageId,
                levels: undefined,
                doNotRenderInSlideOutPanel: true,
                asyncInfoSelector: () => null, /* TinyLoader used in Detail component */
                dataSelector: () => null, /* Data is selected directly in Detail component because
                                                we cache the messageDetails and select them by id */
            }}
        />
    );
}
