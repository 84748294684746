import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData, ITypeaheadDataItem, ITypeaheadProps } from '../../../../common/input/Typeahead';
import {
    getSeatsAndDivisionsForSelectedCompany, getCompanySeatsForSelectedCompany,
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
    getSeatsAndDivisionsForSelectedCompanySeat,
} from '../../../../../redux/company/selected/selectors';
import { ICompanySeat } from '../../../../../models/admin/company';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import FreeTextAsyncTypeahead, {
    IFreeTextAsyncTypeaheadProps,
} from '../../../../common/input/Typeahead/FreeTextAsyncTypeahead';

const ALL_ITEMS_COMPANY_CODE = 'All';

interface IPrivateProps {
    companySeats: ICompanySeat[];
    companySeatsTypeahead: TTypeaheadData;
    translator: ITranslator;
}

interface ISeatTypeaheadProps {
    id: string;
    name: string;
    value?: string;
    onItemSelected: (companyCode: string, name: string, seat: ICompanySeat) => void;
    onAllItemsSelected?: (seats: {
        companyCode: string;
        name: string;
        id: number;
    }[]) => void;
    placeholder?: string;
    excludeDivisions?: boolean;
    excludeCompanyCodesFromItems?: string[];
    isInvalid?: boolean;
    hasSelectAllOption?: boolean;
    children?: ReactNode;
    initialFilter?: string;
    onFilter?: (filter: string) => void;
    isFreeText?: boolean;
    allSeats?: boolean;
    disabled?: boolean;
    autoSelectOnSingleOptionIfNotAsync?: boolean;
}

function SeatTypeahead(props: ISeatTypeaheadProps & IPrivateProps) {
    const {
        children, id, value,
        name, isInvalid, onItemSelected,
        companySeats, companySeatsTypeahead,
        placeholder, isFreeText, initialFilter,
        onFilter, excludeCompanyCodesFromItems = [],
        onAllItemsSelected, disabled,
        autoSelectOnSingleOptionIfNotAsync,
    } = props;

    function onItemSelectedHandler(companyCode: string) {
        if (companyCode === ALL_ITEMS_COMPANY_CODE) {
            return onAllItemsSelected(companySeatsTypeahead.map((item) => {
                const selectedSeat = companySeats.find((s) => s.company.companyCode === item.value);
                return {
                    companyCode: item.value as string,
                    name: item.label,
                    id: selectedSeat.company.id,
                };
            }));
        }
        const selectedSeatTypeahead = companySeatsTypeahead.find((item) => item.value === companyCode);
        const selectedSeat = companySeats.find((item) => item.company.companyCode === companyCode);
        onItemSelected(companyCode, selectedSeatTypeahead && selectedSeatTypeahead.label, selectedSeat || null);
    }

    const filteredCompanySeats = companySeatsTypeahead.filter(
        (item) => !excludeCompanyCodesFromItems.includes(item.value as string),
    );

    if (props.hasSelectAllOption && companySeats.length > 1) {
        filteredCompanySeats.push(getSelectAllOption(props.translator));
    }

    const commonProps: ITypeaheadProps = {
        id,
        value,
        name,
        onItemSelected: onItemSelectedHandler,
        isInvalid,
        data: filteredCompanySeats,
        placeholder,
        disabled,
        asyncInfoSelector: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
    };

    if (isFreeText) {
        return (
            <FreeTextAsyncTypeahead
                {...commonProps as IFreeTextAsyncTypeaheadProps}
                onFilter={onFilter}
                initialFilter={initialFilter}
                autoFilteringDisabled={false}
            >
                {children}
            </FreeTextAsyncTypeahead>
        );
    }

    return (
        <Typeahead {...commonProps} autoSelectOnSingleOption={autoSelectOnSingleOptionIfNotAsync}>
            {children}
        </Typeahead>
    );
}

function getSelectAllOption(translator: ITranslator): ITypeaheadDataItem {
    return {
        label: translator('administration.seats_select.all'),
        value: ALL_ITEMS_COMPANY_CODE,
        sticky: true,
    };
}

export default connect<IPrivateProps, ISeatTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const companySeats = publicProps.excludeDivisions
                ? getCompanySeatsForSelectedCompany(state)
                : publicProps.allSeats
                    ? getSeatsAndDivisionsForSelectedCompany(state)
                    : getSeatsAndDivisionsForSelectedCompanySeat(state);
            return {
                companySeats,
                companySeatsTypeahead: mapSeatsForTypeahead(companySeats),
                translator: getTranslatorDeprecated(state),
            };
        };
    },
})(SeatTypeahead);

function mapSeatsForTypeahead(companySeats: ICompanySeat[]): TTypeaheadData {
    return Array.isArray(companySeats) ? companySeats.map((item) => ({
        value: item.company.companyCode,
        label: item.company.name,
    })) : [];
}
