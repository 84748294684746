import { dayOffsetFromNow } from '../utils/core/date/getSpecificDate';
import { COURSE_TYPE_SVG_IDS } from '../models/general/lazyLoadSvg';

export const COURSE_ATTENDEE_REMOVE_NOT_POSSIBLE_ERROR = 'klantenzone.error.course_attendee_unsubscribe_not_possible';
export const KMO_WALLET_MORE_INFO_LINK = 'http://www.vlaio.be/themas/kmo-portefeuille';
export const COURSE_REGISTRATION_DUE_DATE = dayOffsetFromNow(3);
export const CLOSED_COURSE_REGISTRATION_DUE_DATE = dayOffsetFromNow(1);

export const NO_SPOTLIGHT_COURSE_ID = -999999;
export const TEACHING_TYPES = {
    open: 3,
    online: 4,
};

export const COURSE_TYPE_MAP = {
    1: {
        lazyLoadSvgId: COURSE_TYPE_SVG_IDS.CLASSICAL,
    },
    2: {
        lazyLoadSvgId: COURSE_TYPE_SVG_IDS.BLENDED,
    },
    3: {
        lazyLoadSvgId: COURSE_TYPE_SVG_IDS.CUSTOM,
    },
    4: {
        lazyLoadSvgId: COURSE_TYPE_SVG_IDS.ELEARNING,
    },
};
