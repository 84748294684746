import localStorage from '@snipsonian/browser/es/storage/localStorage';
import { ConstantType, IConstant, ConstantMessageType, TConstants } from '../models/general/constants';
import { TBackendLocale } from '../models/general/i18n';
import { LOCALES, getBackendLocale } from '../config/i18n.config';
import { localeToBackendLocale } from '../utils/formatting/formatLocale';
import { TTypeaheadData } from '../views/common/input/Typeahead';
import { IMessageType } from '../models/user/inbox';
import { NO_RERENDER } from '../redux';
import { STATE_STORAGE_KEY } from './redux.config';

export const CONSTANTS_STORAGE_KEY = `${STATE_STORAGE_KEY}_CONST`;

const areConstantsRefreshedPerLang: {
    [backendLocale: string]: boolean;
} = {};

interface IConstantsMap {
    [backendLocale: string]: {
        [type: string]: any[];
    };
}

const INITIAL_CONSTANTS_MAP: IConstantsMap = LOCALES
    .reduce(
        (mapAccumulator, locale) => {
            const backendLocale = localeToBackendLocale(locale);

            mapAccumulator[backendLocale] = {};

            return mapAccumulator;
        },
        {},
    );

const constantsMap = getStoredConstantsMapOrInitial();

export const getAbsences = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.ABSENCES, backendLocale);
export const getLanguages = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.LANGUAGES, backendLocale);
export const getCancellationReasons = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.CANCELLATION_REASONS, backendLocale);
export const getNationalities = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.NATIONALITIES, backendLocale);
export const getRisks = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.RISKS, backendLocale);
export const getSexes = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.SEXES, backendLocale);
export const getStatutes = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.STATUTES, backendLocale);
export const getCountries = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.COUNTRIES, backendLocale);
export const getTitles = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.TITLES, backendLocale);
export const getContactTitles = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.CONTACT_TITLES, backendLocale);
export const getContactTypes = (backendLocale?: TBackendLocale) =>
    getConstantValues(ConstantType.CONTACT_TYPES, backendLocale);
export const getMessageTypes = (backendLocale?: TBackendLocale) =>
    getConstantMessageTypeValues(backendLocale);

export const getLanguageDescription = (id: number) => getDescriptionById(ConstantType.LANGUAGES, id.toString());
export const getSexDescription = (id: number) => getDescriptionById(ConstantType.SEXES, id.toString());
export const getNationalityDescription = (id: number) => getDescriptionById(ConstantType.NATIONALITIES, id.toString());
export const getStatuteDescription = (code: string) => getDescriptionByCode(ConstantType.STATUTES, code);
export const getAbsenceDescription = (code: string) => getDescriptionByCode(ConstantType.ABSENCES, code);
export const getContactTypeDescription = (id: number) => getDescriptionById(ConstantType.CONTACT_TYPES, id.toString());
export const getCountryDescription = (countryCode: string) => getDescriptionById(ConstantType.COUNTRIES, countryCode);

export const getSexByCode = (code: string) => getByCode(ConstantType.SEXES, code);

const getSexesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getSexes(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getNationalitiesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getNationalities(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getLanguagesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getLanguages(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getStatutesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getStatutes(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getAbsencesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getAbsences(backendLocale)
        .map(mapConstantToTypeaheadDataItemByCode);
const getCountriesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getCountries(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getCancellationReasonsForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getCancellationReasons(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getRisksForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getRisks(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getContactTitlesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getContactTitles(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getContactTypesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getContactTypes(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
const getTitlesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
    getTitles(backendLocale)
        .map(mapConstantToTypeaheadDataItemById);
export const getMessageTypesForTypeahead = (backendLocale: TBackendLocale): TTypeaheadData =>
        getMessageTypes(backendLocale)
            .map(mapMessageTypeToTypeaheadDataItemById);

export function getTypeaheadData(constantType: ConstantType, backendLocale: TBackendLocale) {
    switch (constantType) {
        case ConstantType.ABSENCES:
            return getAbsencesForTypeahead(backendLocale);
        case ConstantType.CANCELLATION_REASONS:
            return getCancellationReasonsForTypeahead(backendLocale);
        case ConstantType.COUNTRIES:
            return getCountriesForTypeahead(backendLocale);
        case ConstantType.LANGUAGES:
            return getLanguagesForTypeahead(backendLocale);
        case ConstantType.NATIONALITIES:
            return getNationalitiesForTypeahead(backendLocale);
        case ConstantType.RISKS:
            return getRisksForTypeahead(backendLocale);
        case ConstantType.SEXES:
            return getSexesForTypeahead(backendLocale);
        case ConstantType.STATUTES:
            return getStatutesForTypeahead(backendLocale);
        case ConstantType.CONTACT_TITLES:
            return getContactTitlesForTypeahead(backendLocale);
        case ConstantType.CONTACT_TYPES:
            return getContactTypesForTypeahead(backendLocale);
        case ConstantType.TITLES:
            return getTitlesForTypeahead(backendLocale);
        default:
            return [];
    }
}

function getConstantValues(type: ConstantType, lang: TBackendLocale = getBackendLocale()): IConstant[] {
    return constantsMap[lang][type] || NO_RERENDER.EMPTY_LIST;
}

export function setConstantValues(type: ConstantType, lang: TBackendLocale, values: IConstant[]) {
    constantsMap[lang][type] = values;

    storeRefreshedConstantsMap(constantsMap);
    markConstantsRefreshedForLang(lang);
}

export function setMultipleConstantValues(lang: TBackendLocale, constants: TConstants) {
    constantsMap[lang] = {
        ...constantsMap[lang],
        ...constants,
    };

    storeRefreshedConstantsMap(constantsMap);
    markConstantsRefreshedForLang(lang);
}

function markConstantsRefreshedForLang(lang: TBackendLocale) {
    areConstantsRefreshedPerLang[lang] = true;
}

export function areConstantsRefreshedForLang(lang: TBackendLocale = getBackendLocale()) {
    return !!areConstantsRefreshedPerLang[lang];
}

export function areConstantValuesAvailableForLang(lang: TBackendLocale) {
    /* As we always fetch all constants in one go, we just check if one of them is available or not */
    return !!constantsMap[lang][ConstantType.LANGUAGES];
}

function getConstantMessageTypeValues(lang: TBackendLocale = getBackendLocale()): IMessageType[] {
    return constantsMap[lang][ConstantMessageType.MESSAGE_TYPES] || NO_RERENDER.EMPTY_LIST;
}

export function setMessageTypeValues(lang: TBackendLocale, values: IMessageType[]) {
    constantsMap[lang][ConstantMessageType.MESSAGE_TYPES] = values;

    storeRefreshedConstantsMap(constantsMap);
}

export function areMessageTypeValuesAvailableForLang(lang: TBackendLocale) {
    return !!constantsMap[lang][ConstantMessageType.MESSAGE_TYPES];
}

function getById(type: ConstantType, id: string) {
    return getConstantValues(type)
        .find((value) => value.id === id);
}

function getByCode(type: ConstantType, code: string) {
    return getConstantValues(type)
        .find((value) => value.code === code);
}

function getDescriptionById(type: ConstantType, id: string) {
    const match = getById(type, id);
    return match ? match.description : id;
}

function getDescriptionByCode(type: ConstantType, code: string) {
    const match = getByCode(type, code);
    return match ? match.description : code;
}

function mapConstantToTypeaheadDataItemById(constant: IConstant) {
    return {
        label: constant.description,
        value: constant.id,
    };
}

function mapMessageTypeToTypeaheadDataItemById(messageType: IMessageType) {
    return {
        label: messageType.description,
        value: messageType.messageTypeId,
    };
}

function mapConstantToTypeaheadDataItemByCode(constant: IConstant) {
    return {
        label: constant.description,
        value: constant.code,
    };
}

function getStoredConstantsMapOrInitial(): IConstantsMap {
    return localStorage.read({ key: CONSTANTS_STORAGE_KEY, defaultValue: INITIAL_CONSTANTS_MAP }) as IConstantsMap;
}

function storeRefreshedConstantsMap(constantsMap: IConstantsMap) {
    localStorage.save({ key: CONSTANTS_STORAGE_KEY, value: constantsMap });
}
