import { get, post } from '../../utils/api/requestWrapper';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import {
    IFetchRiskManagementDocumentsPayload,
    IFetchRiskManagementDocumentFilesInBulk,
    IRiskManagementDocumentsFilter,
    IRiskManagementDocument,
} from '../../models/documentCenter/riskManagementDocuments';

export const URL = {
    RISK_MANAGEMENT_DOCUMENTS: '/companies/{companyCode}/company-visit/documents',
    RISK_MANAGEMENT_DOCUMENTS_BULK: '/companies/{companyCode}/company-visit/documents-bulk',
};

export const DEFAULT_RISK_MANAGEMENT_DOCUMENTS_FILTER = {
    startDate: formatDateForBackend(yearOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchRiskManagementDocuments(
    { companyCode, showFullFamily }: IFetchRiskManagementDocumentsPayload,
    {
        startDate = DEFAULT_RISK_MANAGEMENT_DOCUMENTS_FILTER.startDate,
        endDate = DEFAULT_RISK_MANAGEMENT_DOCUMENTS_FILTER.endDate,
    }: IRiskManagementDocumentsFilter,
) {
    return get<IRiskManagementDocument[]>({
        url: URL.RISK_MANAGEMENT_DOCUMENTS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.documents,
    });
}

export function fetchRiskManagementDocumentFilesInBulk({ companyCode, ids }: IFetchRiskManagementDocumentFilesInBulk) {
    return post<{}>({
        url: URL.RISK_MANAGEMENT_DOCUMENTS_BULK,
        pathParams: {
            companyCode,
        },
        body: {
            ids,
        },
        // dummy mapResponse as documents will be sent to message center
        mapResponse: (response) => ({}),
    });
}
