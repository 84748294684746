import {
    ILinkableNavigationItem,
    IDashboardNavigationItem,
    IDismissedCompanyDashboardItem,
} from '../../models/general/navigation';
import { DOCUMENT_CENTER_ITEMS, DOCUMENT_CENTER_MANAGEMENT_ITEMS } from './documentCenter.config';
import { ADMINISTRATION_ITEMS } from './administration.config';
import { INTERVENTION_MANAGEMENT_ITEMS } from './interventions.config';
import ROUTE_KEYS from '../../routeKeys';

export const DISMISSED_COMPANY_ITEMS: IDismissedCompanyDashboardItem[] = [
    ...filterRoutesFromDashboardItems(
        DOCUMENT_CENTER_ITEMS,
        [
            ROUTE_KEYS.R_MEDICAL_DOCUMENTS,
            ROUTE_KEYS.R_RISK_MGMT_DOCUMENTS,
            ROUTE_KEYS.R_COURSES_OVERVIEW,
        ],
    ),
    ...filterRoutesFromDashboardItems(
        ADMINISTRATION_ITEMS,
        [
            ROUTE_KEYS.R_INVOICES_OPEN,
            ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
            ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
        ],
    ),
];

export const DISMISSED_COMPANY_MANAGEMENT_ITEMS: IDismissedCompanyDashboardItem[] = [
    ...filterRoutesFromDashboardItems(
        DOCUMENT_CENTER_MANAGEMENT_ITEMS,
        [
            ROUTE_KEYS.R_ACTIVITY_REPORT,
        ],
    ),
    ...filterRoutesFromDashboardItems(
        INTERVENTION_MANAGEMENT_ITEMS,
        [
            ROUTE_KEYS.R_POLICY_ADVICE,
        ],
    ),
];

function filterRoutesFromDashboardItems<DashboardItem extends ILinkableNavigationItem = IDashboardNavigationItem>(
    items: DashboardItem[],
    routeKeys: ROUTE_KEYS[],
): DashboardItem[] {
    return routeKeys
        .map((routeKey) => {
            return items.find((item) => item.linkTo === routeKey);
        });
}
