import React, { PureComponent, MouseEvent } from 'react';
import './add-statute.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import { IAddEmployeeStatutePayload } from '../../../../../models/admin/employee';
import { fields, schema } from './addStatuteSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getAddEmployeeStatuteAsyncInfo, getSelectedEmployee,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import {
    formatDateForBackend,
    formatDateForDisplay,
} from '../../../../../utils/formatting/formatDate';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import { addEmployeeStatute } from '../../../../../redux/employee/info/actions';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../models/general/constants';
import { removeEmptyPropsFromObject } from '../../../../../utils/core/object/objectProps';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { dayOffsetFromNow } from '../../../../../utils/core/date/getSpecificDate';

type TValues = Pick<IAddEmployeeStatutePayload, 'dateActive' | 'dateInactive' | 'riskId'>;

interface IPrivateProps {
    onAddStatute: (values: TValues) => void;
    translator: ITranslator;
    addAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
}

interface IAddStatuteProps {
    onClose: () => void;
}

const CLASS_NAME = 'AddStatute';
const FORM_NAME = 'add-statute-form';

class AddStatute extends PureComponent<IPrivateProps & IAddStatuteProps> {

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, onAddStatute, addAsyncInfo,
        } = this.props;

        const initialValues: TValues = {
            riskId: null,
            dateActive: formatDateForBackend(new Date()),
            dateInactive: '',
        };

        const namePlaceholder = translator('administration.employees.add_statute.form.name');
        const startDatePlaceholder = translator('administration.employees.add_statute.form.start_date');
        const dateInactivePlaceholder = translator('administration.employees.add_statute.form.date_inactive');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.add_statute.title'}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={onAddStatute}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue,
                    }: IFormRenderProps<TValues>) => {
                        function onDateInactiveChanged(date: string) {
                            setFieldValue('dateInactive', date);
                        }

                        function onDateActiveChanged(date: string) {
                            setFieldValue('dateActive', date);
                        }

                        function onStatuteChanged(riskId: number) {
                            setFieldValue('riskId', riskId);
                        }

                        return (
                            <>
                                <Loader
                                    show={
                                        addAsyncInfo.status === AsyncStatus.Busy
                                    }
                                    showImmediatelly={true}
                                />
                                <FloatableTextInputWrapper floatLabel>
                                    <ConstantsTypeahead
                                        id="add-statute-risk-id"
                                        constantType={ConstantType.STATUTES}
                                        name={fields.riskId}
                                        placeholder={namePlaceholder}
                                        value={values.riskId}
                                        onItemSelected={onStatuteChanged}
                                        isInvalid={touched.riskId && !!errors.riskId}
                                    >
                                        <label htmlFor="add-statute-risk-id">
                                            <Translate
                                                msg="administration.employees.add_statute.form.name"
                                            />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.riskId && (
                                        <FormFieldError
                                            error={errors.riskId}
                                            placeholders={{ fieldName: namePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <DatePicker
                                        id="add-statute-date-active"
                                        placeholder={startDatePlaceholder}
                                        value={values.dateActive}
                                        name={fields.dateActive}
                                        onChange={onDateActiveChanged}
                                        isInvalid={touched.dateActive && !!errors.dateActive}
                                    >
                                        <label htmlFor="add-statute-date-active">
                                            <Translate
                                                msg="administration.employees.add_statute.form.start_date"
                                            />
                                        </label>
                                    </DatePicker>
                                    {touched.dateActive && (
                                        <FormFieldError
                                            error={errors.dateActive}
                                            placeholders={{ fieldName: startDatePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <DatePicker
                                        id="add-statute-date-inactive"
                                        placeholder={dateInactivePlaceholder}
                                        value={values.dateInactive}
                                        name={fields.dateInactive}
                                        onChange={onDateInactiveChanged}
                                        isInvalid={touched.dateInactive && !!errors.dateInactive}
                                        minDate={formatDateForBackend(dayOffsetFromNow())}
                                    >
                                        <label htmlFor="add-statute-date-inactive">
                                            <Translate
                                                msg="administration.employees.add_statute.form.date_inactive"
                                            />
                                        </label>
                                    </DatePicker>
                                    {touched.dateInactive && (
                                        <FormFieldError
                                            error={errors.dateInactive}
                                            placeholders={{
                                                fieldName: dateInactivePlaceholder,
                                                minValue: values.dateActive
                                                    ? formatDateForDisplay(values.dateActive) : '-',
                                            }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FormError error={addAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="add-statute-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate
                                            msg="administration.employees.add_statute.form.cancel"
                                        />
                                    </Button>
                                    <SubmitButton
                                        id="add-statute-submit-button"
                                        formName={FORM_NAME}
                                    >
                                        <Translate
                                            msg="administration.employees.add_statute.form.submit"
                                        />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.addAsyncInfo.status === AsyncStatus.Success &&
            prevProps.addAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, addAsyncInfo } = this.props;
        clearError(addAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IAddStatuteProps>({
    stateProps: (state) => {
        const addAsyncInfo = getAddEmployeeStatuteAsyncInfo(state);
        return {
            translator: getTranslatorDeprecated(state),
            addAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onAddStatute: (values) => {
                const selectedEmployee = getSelectedEmployee(getState());
                dispatch(addEmployeeStatute({
                    id: selectedEmployee.id,
                    ...removeEmptyPropsFromObject(values),
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(AddStatute);
