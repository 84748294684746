import React from 'react';
import TextInput, { ITextInputBaseProps } from '../TextInput';

interface IUncontrolledTextInputProps extends ITextInputBaseProps {
    value?: string;
}

export default function UncontrolledTextInput(props: IUncontrolledTextInputProps) {
    return (
        <TextInput {...props} isUncontrolled={true} value={props.value} />
    );
}
