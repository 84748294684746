import { ArgumentAction } from 'redux-logic/definitions/action';
import { find } from 'ramda';

import { createNotFoundError } from '../../../../utils/api/error/createNotFoundError';
import { getRouteKeysThatBelongToGroup } from '../../../../routes';
import { IAction } from '../../../../models/general/redux';
import {
    IFetchReservedMedicalExaminationPayload,
    IReservedMedicalExamination,
} from '../../../../models/interventions/medicalExaminations/reserved';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import {
    parseReservedMedicalExaminationIdInActivityIdAndBranchCode,
} from '../../../../utils/interventions/medicalExaminations/reservedMedicalExaminationId.helper';
import { ROUTE_GROUP } from '../../../../config/routeGroup.config';
import Api from '../../../../api';
import ROUTE_KEYS from '../../../../routeKeys';
import { createEpic, IState } from '../../../index';
import { getLocale } from '../../../i18n/selectors';
import { getQueryParams } from '../../../location/selectors';
import {
    getSelectedSeatCompanyCode,
    isAllSeatsSelected,
} from '../../../company/selected/selectors';
import {
    fetchReservedMedicalExaminationFailed,
    fetchReservedMedicalExaminationSucceeded,
    fetchReservedMedicalExaminationsFailed,
    fetchReservedMedicalExaminationsSucceeded,
} from '../../actions';
import {
    getReservedMedicalExaminations,
    getSelectedReservedMedicalExamination,
} from '../../selectors';
import { FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL } from '../../types';

const ACTION_TYPES_THAT_FETCH_COMPANY_BUFFERZONE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE);

createEpic({
    onActionType: ACTION_TYPES_THAT_FETCH_COMPANY_BUFFERZONE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !getSelectedReservedMedicalExamination(getState()),
    processMultiple: fetchCompanyBufferzoneDetailFromReduxWithApiFallback,
    latest: false,
});

createEpic({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL,
    processMultiple: fetchCompanyBufferzoneDetailFromReduxWithApiFallback,
    latest: false,
});

createEpic({
    onActionType: FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL,
    processMultiple: fetchCompanyBufferzoneDetailFromReduxWithApiFallback,
    latest: false,
});

type fetchCompanyReservationDetailActionPayload = {
    action: IAction<IFetchReservedMedicalExaminationPayload>,
    getState: () => IState,
    api: typeof Api
};

async function fetchCompanyBufferzoneDetailFromReduxWithApiFallback(
    { action, getState, api }: fetchCompanyReservationDetailActionPayload,
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) {
    const state = getState();
    const locale = getLocale(state);

    const { payload } = action;
    const { reservedMedicalExaminationId } = payload;

    if (action.payload.forceRefresh) {
        return fetchReservedMedicalExaminationDetailFromApi(
            reservedMedicalExaminationId,
            state,
            api,
            dispatch,
            done,
        );
    }

    const reservedMedicalExaminations = getReservedMedicalExaminations(state);

    // USE-CASE: no reserved medical examinations could be found:
    // The reserved medical examinations have not yet been retrieved if you
    // navigate directly to the detail page of a reserved medical examination
    // without going through the overview page.
    if (!reservedMedicalExaminations || reservedMedicalExaminations.length === 0) {
        try {
            const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;
            const startDate = filterFromQuery.startDate;
            const endDate = filterFromQuery.endDate;

            const fetchedReservedMedicalExaminations =
                await api.interventions.medicalExaminations.fetchReserved({
                    companyCode: getSelectedSeatCompanyCode(state),
                    showFullFamily: isAllSeatsSelected(state),
                    // Only pass start date & end date when they were set
                    // When not set, the default date range will be used in the api method
                    ...!!startDate && { startDate },
                    ...!!endDate && { endDate },
                    locale,
                });

            dispatch(fetchReservedMedicalExaminationsSucceeded(fetchedReservedMedicalExaminations));

            // Don't fetch detail when no activity id is available
            if (!reservedMedicalExaminationId) {
                return done();
            }

            // We don't need to fetch the detail of the reserved examination and can
            // search for it directly from the reserved medical examinations overview call.
            const selectedReservedMedicalExamination = find<IReservedMedicalExamination>(
                (reservedMedicalExamination) => reservedMedicalExamination.id === reservedMedicalExaminationId,
                fetchedReservedMedicalExaminations,
            );

            // Found a reserved medical examination that matches the activity id:
            if (selectedReservedMedicalExamination) {
                dispatch(fetchReservedMedicalExaminationSucceeded(selectedReservedMedicalExamination));

                return done();
            }

        } catch (error) {
            dispatch(fetchReservedMedicalExaminationsFailed(error));
        }
    }

    // Don't fetch detail when no activity id is available
    if (!reservedMedicalExaminationId) {
        return done();
    }

    // Get state after possible state updates
    const updatedState = getState();

    // USE-CASE: Reserved medical examinations have been found
    // Fetch changes for the selected reserved medical examination
    return fetchReservedMedicalExaminationDetailFromApi(
        reservedMedicalExaminationId,
        updatedState,
        api,
        dispatch,
        done,
    );
}

const fetchReservedMedicalExaminationDetailFromApi = async (
    id: string,
    state,
    api,
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) => {
    try {
        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = isAllSeatsSelected(state);
        const reservedMedicalExaminations = getReservedMedicalExaminations(state);
        const locale = getLocale(state);

        const [activityId, branchCode] = parseReservedMedicalExaminationIdInActivityIdAndBranchCode(
            id,
        );

        const companyReservationDetail = await api.interventions.medicalExaminations.fetchReservedDetail({
            activityId,
            branchCode,
            companyCode,
            locale,
            showFullFamily,
        });

        // If, for some reason, the bff returns a "204 no content" status:
        // Return the data we already have from the overview call (when available)
        if (!companyReservationDetail) {
            const cachedReservedMedicalExamination = reservedMedicalExaminations.find(
                (examination) => examination.id === id,
            );

            if (cachedReservedMedicalExamination) {
                dispatch(fetchReservedMedicalExaminationSucceeded(cachedReservedMedicalExamination));
            } else {
                dispatch(fetchReservedMedicalExaminationFailed(createNotFoundError()));
            }

            return done();
        }

        dispatch(fetchReservedMedicalExaminationSucceeded(companyReservationDetail));

        // Update reserved medical examination in overview list
        if (reservedMedicalExaminations.length !== 0) {
            const newReservations = reservedMedicalExaminations.map(
                (reservedMedicalExamination) => {
                    if (reservedMedicalExamination.id === id) {
                        return companyReservationDetail;
                    }

                    return reservedMedicalExamination;
                },
            );

            dispatch(fetchReservedMedicalExaminationsSucceeded(newReservations));
        }

    } catch (error) {
        dispatch(fetchReservedMedicalExaminationFailed(error));
    }

    return done();
};
