// See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
type Keys = 'Escape' | 'Enter' | 'ArrowDown' | 'ArrowUp' | 'Tab' | 'd' | '/' | '\\' | '-';

// https://css-tricks.com/snippets/javascript/javascript-keycodes/#article-header-id-1
function keyToKeyCode(key: Keys) {
    switch (key) {
        case 'Escape':
            return 27;
        case 'Enter':
            return 13;
        case 'ArrowDown':
            return 40;
        case 'ArrowUp':
            return 38;
        case 'Tab':
            return 9;
        case 'd':
            return 68;
        case '/':
            return 191;
        case '\\':
            return 220;
        case '-':
            return 109;
    }
    return undefined;
}

export function isKeyPressed(event: KeyboardEvent | React.KeyboardEvent<HTMLElement>, key: Keys) {
    // Keyboard event is not standardized, eg which / keyCorde are deprecated but still used a lot
    return event.key === key || event.keyCode === keyToKeyCode(key) || event.which === keyToKeyCode(key);
}

export function isNumericKeyPressed(event: KeyboardEvent | React.KeyboardEvent<HTMLElement>) {
    return event.which >= 48 && event.which <= 57;
}
