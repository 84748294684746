export default function isInViewport(el: HTMLElement, scrollContainerEl?: HTMLElement | Window) {
    if (!el) {
        return false;
    }
    const bounding = el.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    let viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    if (scrollContainerEl && scrollContainerEl !== window) {
        const containerEl = scrollContainerEl as HTMLElement;
        const marginBottom = viewportHeight - containerEl.offsetHeight - containerEl.offsetTop;
        viewportHeight -= marginBottom;
    }

    if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= viewportWidth &&
        bounding.bottom <= viewportHeight
    ) {
        return true;
    }
    return false;
}
