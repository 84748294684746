export const FETCH_COURSES_PLANNED = 'FETCH_COURSES_PLANNED';
export const FETCH_COURSES_PLANNED_DETAIL = 'FETCH_COURSES_PLANNED_DETAIL';

export const FETCH_COURSE_SESSION = 'FETCH_COURSE_SESSION';
export const FETCH_COURSE_SESSION_DAYS = 'FETCH_COURSE_SESSION_DAYS';
export const FETCH_COURSE_SESSION_ATTENDEES = 'FETCH_COURSE_SESSION_ATTENDEES';
export const FETCH_COURSE_SESSION_ATTENDANCE = 'FETCH_COURSE_SESSION_ATTENDANCE';
export const FETCH_COURSES_SESSION_CERTIFICATES = 'FETCH_COURSES_SESSION_CERTIFICATES';

export const FETCH_COURSES_FOLLOWED = 'FETCH_COURSES_FOLLOWED';

export const FETCH_COURSES_OVERVIEW = 'FETCH_COURSES_OVERVIEW';
export const FETCH_COURSES_OVERVIEW_DETAIL = 'FETCH_COURSES_OVERVIEW_DETAIL';
export const FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW = 'FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW';
export const FETCH_COURSE_TABLE = 'FETCH_COURSE_TABLE';

export const REMOVE_COURSE_ATTENDEE = 'REMOVE_COURSE_ATTENDEE';
export const SET_REMOVE_ATTENDEE_NOT_POSSIBLE = 'SET_REMOVE_ATTENDEE_NOT_POSSIBLE';

export const UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY = 'UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY';
export const RESET_COURSE_ENROLLMENT_WIZARD_ENTITY = 'RESET_COURSE_ENROLLMENT_WIZARD_ENTITY';
export const CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY = 'CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY';
export const SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP = 'SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP';
export const CREATE_COURSE_ENROLLMENT = 'CREATE_COURSE_ENROLLMENT';
export const FETCH_COURSE_POSSIBLE_LOCATIONS = 'FETCH_COURSE_POSSIBLE_LOCATIONS';
export const FETCH_COURSE_SESSIONS_BY_LOCATION = 'FETCH_COURSE_SESSIONS_BY_LOCATION';
export const CREATE_ELEARNING_COURSE_ENROLLMENT = 'CREATE_ELEARNING_COURSE_ENROLLMENT';

export const FETCH_CERTIFICATES = 'FETCH_CERTIFICATES';
export const FETCH_CERTIFICATE_DETAIL = 'FETCH_CERTIFICATE_DETAIL';

export const FETCH_EMPLOYEE_COURSES = 'FETCH_EMPLOYEE_COURSES';

export const FETCH_COURSE_EXTERNAL_EMPLOYEE = 'FETCH_COURSE_EXTERNAL_EMPLOYEE';
export const UPDATE_COURSE_EXTERNAL_EMPLOYEE = 'UPDATE_COURSE_EXTERNAL_EMPLOYEE';
