import { get } from '../../utils/api/requestWrapper';
import {
    IFetchExecutedInterventionsPayload,
    IExecutedInterventionsFilter,
    IExecutedIntervention,
} from '../../models/interventions/interventions';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';

export const URL = {
    COMPANY_INTERVENTIONS_EXECUTED: '/companies/{companyCode}/executed-interventions/{prestationCode}',
    INTERVENTION_REQUEST: '/companies/{companyCode}/intervention-requests',
};

export const DEFAULT_EXECUTED_INTERVENTIONS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchExecutedInterventions(
    {
        companyCode,
        prestationCode,
        showFullFamily = false,
    }: IFetchExecutedInterventionsPayload & { showFullFamily?: boolean },
    {
        startDate = DEFAULT_EXECUTED_INTERVENTIONS_FILTERS.startDate,
        endDate = DEFAULT_EXECUTED_INTERVENTIONS_FILTERS.endDate,
    }: IExecutedInterventionsFilter,
) {
    return get<IExecutedIntervention[]>({
        url: URL.COMPANY_INTERVENTIONS_EXECUTED,
        pathParams: {
            companyCode,
            prestationCode: prestationCode.toUpperCase(),
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.interventions,
    });
}
