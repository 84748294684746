import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncDoInfoSelector, NO_RERENDER } from '../../index';
import { IInterventionRequestWizardData } from '../../../models/interventions/requestIntervention';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Intervention request wizard
const getInterventionRequestWizardData = (state: IState) =>
    (reducerState(state).interventionRequestWizardData || NO_RERENDER.EMPTY_OBJECT) as IInterventionRequestWizardData;

export const getInterventionRequestWizardStepId = (state: IState) =>
    getInterventionRequestWizardData(state).stepId;

export const getInterventionRequestWizardEntity = (state: IState) =>
    getInterventionRequestWizardData(state).entity;

// Create intervention request
const getCreateInterventionRequestAsyncField = (state: IState) =>
    reducerState(state).createInterventionRequest;

export const getCreateInterventionRequestAsyncInfo = makeAsyncDoInfoSelector(getCreateInterventionRequestAsyncField);
