import { HEADER_NAME } from './api.config';

export const PROPERTIES_WITH_SENSITIVE_DATA = [
    'username',
    'password',
    'newPassword',
    'oldPassword',
    'login',
    'firstName',
    'lastName',
    'userId',
    'companies',
    'name',
    'csrfToken',
    HEADER_NAME.CSRF_TOKEN,
    'nationalRegisterNumber',
    'email',
    'mobilePhoneNumber',
    'phoneNumber',
    'fax',
];
