import { ICompanyCodePayload } from '../../models/admin/company';
import downloadCompanyDocumentByUrl from './downloadCompanyDocumentByUrl';
import uploadCompanyDocumentByUrl from './uploadCompanyDocumentByUrl';

export const DOC_TYPES = {
    ACTIVE: 'active',
    TEMPLATE: 'template',
};

export const URL = {
    ACCESSION_AGREEMENT: '/companies/{companyCode}/accession-agreement',
    ACCESSION_AGREEMENT_TEMPLATE: '/companies/{companyCode}/accession-agreement/template',
    ACCESSION_AGREEMENT_ACTIVE: '/companies/{companyCode}/accession-agreement/active',
};

interface IDownloadAccessionAgreement extends ICompanyCodePayload {
    docType: string;
}

export function downloadAccessionAgreement(
    { companyCode, docType }: IDownloadAccessionAgreement,
) {
    const url = docType === DOC_TYPES.ACTIVE
        ? URL.ACCESSION_AGREEMENT_ACTIVE
        : URL.ACCESSION_AGREEMENT_TEMPLATE;

    return downloadCompanyDocumentByUrl({
        companyCode,
        url,
        defaultFilename: 'accession-agreement.doc',
    });
}

interface IUploadAccessionAgreement extends ICompanyCodePayload {
    fileToUpload: File;
}

export function uploadActiveAccessionAgreement(
    { companyCode, fileToUpload }: IUploadAccessionAgreement,
) {
    return uploadCompanyDocumentByUrl({
        companyCode,
        url: URL.ACCESSION_AGREEMENT,
        fileToUpload,
    });
}
