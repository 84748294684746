import React, { ReactNode } from 'react';
import MainHeaderAndFooterTemplate from '../MainHeaderAndFooterTemplate';
import TopMenu from '../../TopMenu';

export interface IWithMenuTemplateProps {
    children: ReactNode;
}

export default function WithMenuTemplate(props: IWithMenuTemplateProps) {
    return (
        <MainHeaderAndFooterTemplate
            showAccountMenu={true}
            showCompanySelectDropdown={true}
            showFluVaccineBanner={true}
        >
            <TopMenu />
            {props.children}
        </MainHeaderAndFooterTemplate>
    );
}
