import { createSuccessAction, createFailAction } from '../../index';
import {
    FETCH_COMPANY_USERS,
    FETCH_COMPANY_USER_DETAILS,
    UPDATE_COMPANY_USER_DETAILS,
    SET_ADD_COMPANY_USER_WIZARD_DATA,
    ADD_COMPANY_USER,
    REMOVE_COMPANY_USER,
} from './types';
import {
    IUserAccount, IUpdateUserAccountPayload,
    IUserAccountDetail, IAddUserAccountPayload, IRemoveUserAccountPayload,
} from '../../../models/user/userAccount';
import { ITraceableApiError } from '../../../models/general/error';
import { createAction, createTypeActions } from '../../../utils/libs/redux/createAction';

export function fetchCompanyUsers() {
    return createAction(FETCH_COMPANY_USERS, {});
}

export function fetchCompanyUsersSucceeded(payload: IUserAccount[]) {
    return createSuccessAction<IUserAccount[]>(FETCH_COMPANY_USERS, payload);
}

export function fetchCompanyUsersFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_USERS, error);
}

export function fetchCompanyUserDetails(username: string) {
    return createAction<{ username: string }>(FETCH_COMPANY_USER_DETAILS, { username });
}

export function fetchCompanyUserDetailsSucceeded(payload: IUserAccountDetail) {
    return createSuccessAction<IUserAccountDetail>(FETCH_COMPANY_USER_DETAILS, payload);
}

export function fetchCompanyUserDetailsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_USER_DETAILS, error);
}

export const updateCompanyUserDetailsActions = createTypeActions<IUpdateUserAccountPayload>({
    type: UPDATE_COMPANY_USER_DETAILS,
});

export function setAddCompanyUserWizardData(payload: Partial<IAddUserAccountPayload>) {
    return createAction<Partial<IAddUserAccountPayload>>(SET_ADD_COMPANY_USER_WIZARD_DATA, payload);
}

export const addCompanyUserActions = createTypeActions<IAddUserAccountPayload>({
    type: ADD_COMPANY_USER,
});

export const removeCompanyUserActions = createTypeActions<IRemoveUserAccountPayload>({
    type: REMOVE_COMPANY_USER,
});
