import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import TopTaskTile from '../TopTaskTile';
import { TaskId, ISelectedTask } from '../../../../../models/user/topTasks';

interface ISortableTopTaskTileProps {
    task: ISelectedTask;
    taskIndex: number;
    onTopTaskSelected: (id: TaskId, index: number) => void;
}

const SortableTopTaskTile = SortableElement<ISortableTopTaskTileProps>(({ task, taskIndex, onTopTaskSelected }) => {
    return (
        <TopTaskTile
            task={task}
            onChange={(id) => onTopTaskSelected(id, taskIndex)}
            dirty={task.dirty}
        />
    );
});

export default SortableTopTaskTile;
