import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import ROUTE_KEYS from '../../routeKeys';
import ContactDashboard from './ContactDashboard';
import FaqOverview from './FaqOverview';
import FaqDetail from './FaqDetail';
import { IState } from '../../redux';
import { getSelectedFaqItem } from '../../redux/contact/selectors';
import { ROUTE_GROUP } from '../../config/routeGroup.config';

const BASE_ROUTE = '/contact';

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_CONTACT]: {
        path: BASE_ROUTE,
        page: {
            key: 'CONTACT',
            component: ContactDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'contact',
        breadcrumbsLabel: {
            translationKey: 'contact.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
        ],
    },
    [ROUTE_KEYS.R_CONTACT_DETAIL]: {
        path: `${BASE_ROUTE}/feedback`,
        page: {
            key: 'CONTACT_FORM',
            component: ContactDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'contact_form',
        breadcrumbsLabel: {
            translationKey: 'contact.overview.detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_FAQ_OVERVIEW]: {
        path: `${BASE_ROUTE}/faq`,
        page: {
            key: 'FAQ',
            component: FaqOverview,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'faq_overview',
        breadcrumbsLabel: {
            translationKey: 'contact.faq.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_FAQ_DETAIL]: {
        path: `${BASE_ROUTE}/faq/:id`,
        page: {
            key: 'FAQ-DETAIL',
            component: FaqDetail,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'faq_detail',
        breadcrumbsLabel: {
            translationKey: 'contact.faq_detail.breadcrumbs_label',
            translationPlaceholdersSelector: (state: IState) => {
                const selectedFaqItem = getSelectedFaqItem(state);
                return {
                    name: selectedFaqItem.question,
                };
            },
        },
    },
};

export default ROUTES;
