import React from 'react';

import { changePlanBufferzoneWizardStep } from '../../../redux/intervention/bufferzones/actions';
import {
    getPlanBufferzoneWizardStepId,
    getPlanBufferzoneWizardEntity,
} from '../../../redux/intervention/bufferzones/selectors';
import { IPlanBufferzoneWizardEntity } from '../../../models/interventions/bufferzones';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';
import ROUTE_KEYS from '../../../routeKeys';
import { connect } from '../../index';
import ButtonLinkToRoute from '../../common/navigation/ButtonLinkToRoute';
import PageHeader from '../../appShell/PageHeader';
import Translate from '../../common/Translate';
import Wizard from '../../common/navigation/Wizard/index';

import { IPlanBufferzoneWizardProps } from './PlanBufferzoneWizard.type';
import { PLAN_BUFFERZONE_STEPS } from './PlanBufferzoneWizard.const';

import './PlanBufferzoneWizard.scss';

registerWizardSteps({
    wizardCode: WIZARD_CODE.PLAN_BUFFERZONE,
    steps: PLAN_BUFFERZONE_STEPS,
});

const PlanBufferzoneWizardComponent = (props: IPlanBufferzoneWizardProps) => {
    const steps = PLAN_BUFFERZONE_STEPS.filter((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(props.wizardEntity);
        }
        return true;
    });

    return (
        <div className="PlanBufferzoneWizard">
            <Wizard
                activeStepIdSelector={getPlanBufferzoneWizardStepId}
                navigateToStepActionCreator={changePlanBufferzoneWizardStep}
                steps={steps}
            />
            <div className="PlanBufferzoneWizard__disabled">
                <PageHeader
                    title="interventions.plan_bufferzone.disabled.title"
                    text="interventions.plan_bufferzone.disabled.text"
                />
                <div className="container">
                    <ButtonLinkToRoute
                        id="go-to-manual-planning"
                        to={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON}
                        typeName="secondary"
                    >
                        <Translate msg="interventions.plan_bufferzone.disabled.button" />
                    </ButtonLinkToRoute>
                </div>
            </div>
        </div>
    );
};

export const PlanBufferzoneWizard = connect<IPlanBufferzoneWizardProps>({
    stateProps: (state) => {
        return {
            wizardEntity: getPlanBufferzoneWizardEntity(state) as IPlanBufferzoneWizardEntity,
        };
    },
})(PlanBufferzoneWizardComponent);
