import React from 'react';

import Translate from '../../../../../common/Translate';
import { TRANSLATION_PREFIX } from '../ReservedMedicalExaminationDetail.const';

import { TReservedMedicalExaminationDetailHeaderProps } from './ReservedMedicalExaminationDetailHeader.type';

export const ReservedMedicalExaminationDetailHeader = (
    { detailAsyncInfo }: TReservedMedicalExaminationDetailHeaderProps,
) => {
    return (
        <h1>
            {detailAsyncInfo.error
                ? <Translate msg="error.title" />
                : <Translate msg={`${TRANSLATION_PREFIX}.title`} />
            }
        </h1>
    );
};
