import React, { PureComponent } from 'react';
import { IRenderDetailHeaderProps, IRenderDetailContentProps }
    from '../../../../common/widget/MasterWithDetail/typings';
import connect from '../../../../../utils/libs/redux/connect';
import { ICourseSessionAttendant, ICourseSessionDay } from '../../../../../models/documentCenter/courses';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import LabelInfo from '../../../../common/widget/LabelInfo';
import { ITranslator } from '../../../../../models/general/i18n';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { formatDateWithHoursAndMinutes } from '../../../../../utils/formatting/formatDate';
import { renderAttendantStatus } from '../detail/content';

interface IPrivateProps {
}

export function DetailHeader(props: IRenderDetailHeaderProps<ICourseSessionAttendant>) {
    const {
        detailAsyncInfo,
        detailData: selectedAttendant,
    } = props;

    return (
        <Loader show={detailAsyncInfo.status}>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : selectedAttendant && formatPersonName(selectedAttendant)
                }
            </h1>
        </Loader>
    );
}

class DetailContentComp extends PureComponent<IPrivateProps & IRenderDetailContentProps<ICourseSessionAttendant>> {
    public render() {
        const {
            detailData: selectedAttendant,
        } = this.props;

        if (!selectedAttendant) {
            return null;
        }

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        { selectedAttendant.attendedDays.length > 0 && (
                            <>
                                <h6>
                                    <Translate
                                        msg="document_center.courses.followed.detail.content.attendees.detail.attended"
                                    />
                                </h6>
                                {selectedAttendant.attendedDays.map((day: ICourseSessionDay) =>
                                    this.renderCourseSessionDay(day, translator))}
                            </>
                        )}
                        { selectedAttendant.noShowDays.length > 0 && (
                            <>
                                <h6>
                                    <Translate
                                        msg="document_center.courses.followed.detail.content.attendees.detail.noshows"
                                    />
                                </h6>
                                {selectedAttendant.noShowDays.map((day: ICourseSessionDay) =>
                                    this.renderCourseSessionDay(day, translator))}
                            </>
                        )}
                        <h6>
                            <Translate
                                msg="document_center.courses.followed.detail.content.attendees.detail.result"
                            />
                        </h6>
                        <div className="FollowedCourses__attendances">
                            {renderAttendantStatus(selectedAttendant, translator)}
                        </div>
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private renderCourseSessionDay(day: ICourseSessionDay, translator: ITranslator) {
        return (
            <LabelInfo
                key={day.startDate}
                label={translator({
                    msg: 'document_center.courses.followed.detail.content.attendees.detail.day',
                    placeholders: {
                        dayNumber: day.dayNumber.toString(),
                    },
                })}
                info={`${formatDateWithHoursAndMinutes(day.startDate)}`}
            />
        );
    }
}

export const DetailContent = connect<IPrivateProps, IRenderDetailContentProps<ICourseSessionAttendant>>({
    stateProps: (state) => ({
    }),
    dispatchPropsDeprecated: (dispatch, getState) => ({
    }),
})(DetailContentComp);
