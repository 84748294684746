import createEvent from '../core/event/createEvent';

const NEW_CONTENT_AVAILABLE_EVENT_NAME = 'sw-new-content-available';

let isNewContentAvailable = false;

const newContentAvailableEvent = createEvent(
    NEW_CONTENT_AVAILABLE_EVENT_NAME,
    { bubbles: true, cancelable: true },
);

export function notifyNewContentAvailable() {
    isNewContentAvailable = true;
    window.dispatchEvent(newContentAvailableEvent);
}

export function onNewContentAvailable(
    toBeExecuted: () => void,
) {
    if (isNewContentAvailable) {
        toBeExecuted();
    } else {
        window.addEventListener(NEW_CONTENT_AVAILABLE_EVENT_NAME, toBeExecuted);
    }
}
