import React, { PureComponent } from 'react';
import Translate from '../../common/Translate';
import { UPLOAD_OR_SEND_DOCUMENT_DEFAULT_MIME_TYPES } from '../../../utils/file/fileTypes';
import FileUploadDropzone from '../../common/widget/FileUploadDropzone';
import Button from '../../common/buttons/Button/index';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import SuccessDialog from '../../common/modals/SuccessDialog';
import ErrorDialog from '../../common/modals/ErrorDialog';
import Loader from '../../common/waiting/Loader';

export interface IUploadFileProps {
    fileToUpload: File;
}

interface IPublicProps {
    baseId: string;
    uploadFileApi: (uploadProps: IUploadFileProps) => Promise<{}>;
}

interface IComponentState {
    fileToUpload: File;
    uploadedFiles: File[];
    uploadInfo: IAsyncFieldInfo;
}

const CLASS_NAME = 'UploadOrSendDocument';

export default class UploadOrSendDocument extends PureComponent<IPublicProps, IComponentState> {
    constructor(props: IPublicProps) {
        super(props);

        this.state = {
            fileToUpload: null,
            uploadedFiles: null,
            uploadInfo: getInitialAsyncInfo(),
        };

        this.onFileDrop = this.onFileDrop.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.confirmUpload = this.confirmUpload.bind(this);
        this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
    }

    public render() {
        const { baseId } = this.props;
        const { fileToUpload, uploadedFiles, uploadInfo } = this.state;

        return (
            <div className={CLASS_NAME}>
                <h2>
                    <Translate msg="document_center.actions.modify_document.title" />
                </h2>
                <div className={`${CLASS_NAME}__upload`}>
                    <Loader show={uploadInfo.status === AsyncStatus.Busy} />
                    <FileUploadDropzone
                        acceptedMimeTypes={UPLOAD_OR_SEND_DOCUMENT_DEFAULT_MIME_TYPES}
                        onFileDrop={this.onFileDrop}
                        onFileDelete={this.onFileDelete}
                        uploadedFilesToBeRemoved={uploadedFiles}
                        multiple={false}
                    />
                    <Button
                        id={`${baseId}_upload-file`}
                        typeName="secondary"
                        disabled={!fileToUpload || uploadInfo.status === AsyncStatus.Busy}
                        onClick={this.confirmUpload}
                    >
                        <Translate msg="document_center.actions.modify_document.upload.button" />
                    </Button>
                    <SuccessDialog
                        show={uploadInfo.status === AsyncStatus.Success}
                        titleTranslationKey="common.upload_file.success_title"
                        onCloseDialog={this.onCloseSuccessDialog}
                    />
                    <ErrorDialog
                        asyncInfo={uploadInfo}
                        titleTranslationKey="common.upload_file.error_title"
                        // hideRealErrorMessage={true}
                        onCloseDialog={this.onCloseErrorDialog}
                    />
                </div>

                <div className={`${CLASS_NAME}__manual-send`}>
                    <div>
                        <strong><Translate msg="document_center.actions.modify_document.send.title" />:</strong>
                    </div>
                    <div className="address">
                        <Translate msg="document_center.actions.modify_document.send.address" />
                    </div>
                    <div>
                        <Translate msg="document_center.actions.modify_document.send.fax_label" />:&nbsp;
                        <Translate msg="document_center.actions.modify_document.send.fax_number" />
                    </div>
                </div>
            </div>
        );
    }

    private async confirmUpload() {
        const { fileToUpload } = this.state;

        this.updateUploadInfo(AsyncStatus.Busy);

        try {
            await this.props.uploadFileApi({ fileToUpload });

            this.setState({
                uploadedFiles: [fileToUpload],
            });
            this.updateUploadInfo(AsyncStatus.Success);
        } catch (e) {
            this.updateUploadInfo(AsyncStatus.Error, e);
        }
    }

    private onCloseSuccessDialog() {
        this.setState({
            fileToUpload: null,
            uploadInfo: getInitialAsyncInfo(),
        });
    }

    private onCloseErrorDialog() {
        this.setState({
            uploadInfo: getInitialAsyncInfo(),
        });
    }

    private updateUploadInfo(status: AsyncStatus, error = null) {
        this.setState({
            uploadInfo: {
                status,
                error,
            },
        });
    }

    private onFileDrop(files: File[]) {
        if (files.length === 1) {
            this.setState({
                fileToUpload: files[0],
            });
        }
    }

    private onFileDelete() {
        this.setState({
            fileToUpload: null,
        });
    }
}

function getInitialAsyncInfo() {
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
}
