import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../../..';
import { IConvocationRecipient } from '../../../../../../models/general/documents';
import {
    getConvocationRecipientsAsyncInfo,
    getConvocationRecipients,
} from '../../../../../../redux/employee/documents/selectors';
import Translate from '../../../../../common/Translate';
import './convocation-recipients.scss';
import TinyLoader from '../../../../../common/waiting/TinyLoader';

const CLASS_NAME = 'ConvocationRecipients';

interface IPublicProps {
    className?: string;
}

interface IPrivateProps {
    convocationRecipients: IConvocationRecipient[];
}

function ConvocationRecipients({ className, convocationRecipients }: IPrivateProps & IPublicProps) {
    // TODO: re-enable once BFF / BE is implemented (KZUAT-1712)
    return null;

    // @ts-ignore
    // eslint-disable-next-line no-unreachable
    return (
        <div className={classNames(CLASS_NAME, className)}>
            <TinyLoader asyncInfoSelector={getConvocationRecipientsAsyncInfo} showContentOnInitialState>
                <p>
                    <Translate msg="interventions.medical_examinations.convocation_recipients.text" />
                </p>
                {convocationRecipients.length > 0 ? (
                    <ul>
                        {convocationRecipients.map(item => (
                            <li>{item.email}</li>
                        ))}
                    </ul>
                ) : (
                    <Translate msg="interventions.medical_examinations.convocation_recipients.no_data" />
                )}
            </TinyLoader>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            convocationRecipients: getConvocationRecipients(state),
        };
    },
})(ConvocationRecipients);
