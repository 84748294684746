export const ROOT_ELEMENT_ID = 'root';
export const POPPER_CONTAINER_ELEMENT_ID = 'popper-container';
export const DIALOG_CONTAINER_ELEMENT_ID = 'dialog-container';
export const SCROLL_CONTAINER_ATTRIBUTE = 'data-scroll-container';
export const TOAST_CONTAINER_ELEMENT_ID = 'toast-container';
export const FLASH_MESSAGES_CONTAINER_ELEMENT_ID = 'flash-messages-container';

export const FILTER_BUTTON_FOCUS_ELEMENT = 'filter-container';
export const SEARCH_BUTTON_FOCUS_ELEMENT = 'search-container';

export const REACT_LIST_DEFAULT_ITEM_SIZE = 50;
