import dayjs, { Dayjs } from 'dayjs';
import { NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED } from '../../../config/medicalExamination.config';

/**
 * 'now' only to be passed for test purposes.
 */
export default function isCancelExaminationAllowed(examinationFormattedStartDate: string, now: Dayjs = dayjs()) {
    return now
        .isBefore(
            dayjs(examinationFormattedStartDate)
                .add(-NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED.CANCEL, 'hour'),
        );
}
