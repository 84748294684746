import React from 'react';
import './form-error.scss';
import { ITraceableApiError } from '../../../../models/general/error';
import { translateApiError } from '../../../../utils/error/translateError';
import Translate, { TPlaceholders } from '../../Translate';
import classNames from 'classnames';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';

interface IFormErrorProps {
    error?: ITraceableApiError;
    fieldNames?: string[];
    translationKey?: string;
    placeHolders?: TPlaceholders;
    className?: string;
    align?: 'right';
}

export default function FormError(props: IFormErrorProps) {
    const formErrorClasses = classNames('FormError', props.className, {
        ['align-right']: props.align === 'right',
    });

    if (props.error && props.error.type && props.error.type === 'bff') {
        return (
            <TranslatorContext.Consumer>
                {() => (
                    <div className={formErrorClasses}>
                        <Translate msg={`common.error_placeholder.${props.error.message}`} />
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }

    return (
        props.error || props.translationKey ? (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <div className={formErrorClasses}>
                        {props.error ?
                            <p>{translateApiError(translator, props.error)}</p> : null}
                        {props.translationKey ?
                            <Translate msg={props.translationKey} placeholders={props.placeHolders} /> : null}
                    </div>
                )}
            </TranslatorContext.Consumer>
        ) : null
    );
}
