import React, { PureComponent } from 'react';
import { connect } from '../../../index';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IClientSideFilterOfListDataProps,
    ITransformToActiveFiltersProps,
    IRenderMasterActionsProps, IShouldRenderShowAllButtonProps,
    IRenderDetailHeaderProps, IRenderDetailContentProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import Translate from '../../../common/Translate';
import { ListItem } from '../../../../models/general/list';
import { ICompanyContact } from '../../../../models/admin/companyInfo';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import {
    getCompanyContactsAsyncInfo, getCompanyContacts,
    getAddContactAsyncInfo,
    getSelectedContactId,
    getSelectedContact,
    getUpdateContactAsyncInfo,
    mayUserManageCompanyInternalContacts,
} from '../../../../redux/company/info/selectors';
import Icon from '../../../common/icons/Icon';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { updateContactActions } from '../../../../redux/company/info/actions';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../models/general/i18n';
import Button from '../../../common/buttons/Button';
import Dialog from '../../../common/modals/Dialog';
import AddContact from './AddContact';
import {
    DetailHeader, DetailContent,
} from './detail';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import ContactsList, {
    IFilterValues, IColumnNamesWhenNotMainSeat,
    COLUMNS, TContactsListProps,
} from '../shared/ContactsList';
import { clearErrors } from '../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../models/general/error';

const BASE_NAME = 'company-info-contacts';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IPrivateProps {
    translator: ITranslator;
    clearError: (error: ITraceableApiError) => void;
    addAsyncInfo: IAsyncFieldInfo;
    mayAddContact: boolean;
}

interface IComponentState {
    isAddContactDialogOpen: boolean;
}

class Contacts extends PureComponent<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            isAddContactDialogOpen: false,
        };

        this.onOpenAddContact = this.onOpenAddContact.bind(this);
        this.onCloseAddContact = this.onCloseAddContact.bind(this);
        this.onContactAdded = this.onContactAdded.bind(this);
        this.renderAddContactButton = this.renderAddContactButton.bind(this);
    }

    public render() {
        const {
            isAddContactDialogOpen,
        } = this.state;

        return (
            <>
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_COMPANY_INFO_CONTACTS,
                        asyncInfoSelector: getCompanyContactsAsyncInfo,
                        dataSelector: getCompanyContacts,
                        transformData: mapContactsToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: TContactsListProps) =>
                            <ContactsList
                                {...renderProps}
                                {...this.props}
                                name={BASE_NAME}
                                nrRecordsToShow={DEFAULT_NR_OF_RECORDS_TO_SHOW}
                            />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData,
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <SearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNamesWhenNotMainSeat>[],
                                IFilterValues>) =>
                                <FilterContent {...renderProps} />,
                        exportButton: {
                            baseFilename: 'company-contacts',
                            listItemIdExtractor: toListId,
                        },
                    }}
                    detailConfig={{
                        routeKey: ROUTE_KEYS.R_COMPANY_INFO_CONTACT_DETAIL,
                        asyncInfoSelector: getCompanyContactsAsyncInfo,
                        idSelector: getSelectedContactId,
                        dataSelector: getSelectedContact,
                        updateInfo: {
                            updateAsyncInfoResetAction: updateContactActions.reset({}),
                            updateAsyncInfoSelector: getUpdateContactAsyncInfo,
                        },
                        isForm: true,
                        renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanyContact>) =>
                            <DetailHeader {...renderProps} />,
                        renderContent: (renderProps: IRenderDetailContentProps<ICompanyContact>) =>
                            <DetailContent
                                {...renderProps}
                            />,
                    }}
                    footerConfig={{
                        shouldRenderShowAllButton,
                        renderActionsLeft: (renderProps: IRenderMasterActionsProps) =>
                            this.renderAddContactButton(renderProps),
                    }}
                />
                <Dialog
                    show={isAddContactDialogOpen}
                    onCloseIntent={this.onCloseAddContact}
                >
                    <AddContact
                        onClose={this.onCloseAddContact}
                        onSucces={this.onContactAdded}
                    />
                </Dialog>
            </>
        );
    }

    private onOpenAddContact() {
        this.setState({
            isAddContactDialogOpen: true,
        });
    }

    private onCloseAddContact() {
        const { addAsyncInfo, clearError } = this.props;

        this.setState({
            isAddContactDialogOpen: false,
        });

        clearError(addAsyncInfo.error);

    }

    private onContactAdded() {
        this.onCloseAddContact();
    }

    private renderAddContactButton(renderProps: IRenderMasterActionsProps) {
        const {
            isFetchingMaster,
        } = renderProps;

        if (!this.props.mayAddContact) {
            return null;
        }

        return (
            <Button
                id="add-contact"
                typeName="text"
                className="AddButton"
                disabled={isFetchingMaster}
                onClick={this.onOpenAddContact}
            >
                <Icon typeName="plus" circle />
                <span>
                    {/*eslint-disable-next-line max-len*/}
                    <Translate msg="administration.company_info.contacts.actions.add_contact" />
                </span>
            </Button>
        );
    }
}

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
function toListId(contact: ICompanyContact) {
    return contact.customerContactId;
}

function mapContactsToListItems(masterData: ICompanyContact[]): ListItem<IColumnNamesWhenNotMainSeat>[] {
    return masterData
        .map((contact) => {
            return {
                id: toListId(contact),
                columns: {
                    type: contact.type,
                    company: contact.company.name,
                    employee: formatPersonName(contact),
                    phone: contact.phone,
                    email: contact.email,
                    companyCode: contact.company.companyCode,
                    actions: null,
                },
            };
        },
        );
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNamesWhenNotMainSeat>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.contactTypes)) {
                return true;
            }

            const contactTypes = separateStringList(filterValues.contactTypes);
            return contactTypes.includes(listItem.columns.type as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seatCodes)) {
                return true;
            }

            const seatCodes = separateStringList(filterValues.seatCodes);
            return seatCodes.includes(listItem.columns.companyCode as string);
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('administration.company_info.contacts.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="administration.company_info.contacts.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNamesWhenNotMainSeat>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<IColumnNamesWhenNotMainSeat>(
        allListItems,
        'companyCode',
        'company',
    );

    const possibleContactTypes = getUniqueTypeaheadFilterValuesFromListItems<IColumnNamesWhenNotMainSeat>(
        allListItems,
        'type',
        'type',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="type"
                labelTranslationKey="administration.company_info.contacts.filter.type"
                possibleFilterItems={possibleContactTypes}
                actualFilterValue={formRenderProps.values.contactTypes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'contactTypes',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey="administration.company_info.contacts.filter.seat"
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNamesWhenNotMainSeat>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNamesWhenNotMainSeat>({
        transformProps,
        translationKeyPrefix: 'administration.company_info.contacts.filter',
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            contactTypes: {
                show: true,
                translationKeySuffixOverride: 'type',
                multiple: {
                    enable: true,
                },
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'company',
                        searchColumnName: 'companyCode',
                    },
                },
            },
        },
    });
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        isShowAll,
    } : {};
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNamesWhenNotMainSeat>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            addAsyncInfo: getAddContactAsyncInfo(state),
            translator: getTranslatorDeprecated(state),
            mayAddContact: mayUserManageCompanyInternalContacts(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(Contacts);
