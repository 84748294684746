import React from 'react';
import { schema, fields, nationalityIdBelgium } from './updateEmployeeSchema';

import { AdvancedPropertyInput, TextPropertyInput } from '../../../common/input/PropertyInput';
import {
    ConfirmableTextPropertyInput,
    ConfirmableConstantsTypeaheadPropertyInput,
} from '../../../common/input/ConfirmablePropertyInput';
import TinyLoader from '../../../common/waiting/TinyLoader';
import Translate from '../../../common/Translate';

import connect from '../../../../utils/libs/redux/connect';
import DescriptionPeriod from './DescriptionPeriod';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import formatNationalRegisterNumber from '../../../../utils/formatting/formatNationalRegisterNumber';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { ConstantType } from '../../../../models/general/constants';
import { EmployeeDetailsOverlayType } from './common';
import { ErrorTypes } from '../../../../models/general/error';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { formatJobStudent } from '../../../../utils/formatting/formatJobStudent';
import {
    getSelectedEmployeeAbsencesAsyncInfo,
    getSelectedEmployeeStatutesAsyncInfo,
    getUpdateEmployeeAsyncInfo,
    getSelectedEmployeeStatutes,
    getSelectedEmployeeAbsences,
    getSelectedEmployeeJobStudent,
    getSelectedEmployeeJobStudentAsyncInfo,
    getSelectedEmployeeStatus,
    getSelectedEmployee,
} from '../../../../redux/employee/info/selectors';
import { getSelectedSeatHasStudentWorkers } from '../../../../redux/company/selected/selectors';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import {
    IEmployeeStatute, IEmployee,
    IEmployeeDetails, TEmployeeUpdateFields,
    IEmployeeAbsence,
    IEmployeeJobStudent,
} from '../../../../models/admin/employee';
import { isInThePast } from '../../../../utils/core/date/isInThePast';
import { isOnboardingWizardRoute } from '../../../../redux/employee/employees/selectors';
import { ITranslator } from '../../../../models/general/i18n';
import { NationalRegisterNumberConfirmablePropertyInput } from '../../../common/input/NationalRegisterNumberInput';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../utils/formatting/formatPhone';
import { updateEmployee } from '../../../../redux/employee/info/actions';

interface IPrivateProps {
    mayEdit: boolean;
    statutes: IEmployeeStatute[];
    absences: IEmployeeAbsence[];
    jobStudent: IEmployeeJobStudent;
    updateEmployeeField: (id: number, fieldName: keyof TEmployeeUpdateFields, value: string) => void;
    isOnboardingWizardRoute?: boolean;
    hasStudentWorkers: boolean;
    mayEditEmail: boolean;
}

interface IContextProps {
    translator: ITranslator;
}

interface IDetailFieldsProps {
    employee: IEmployee & IEmployeeDetails;
    getRequestId: (id: number, fieldName: string) => string;
    onOpenOverlay: (
        overlayType: EmployeeDetailsOverlayType,
        formValues?: object,
        onSave?: (data: object) => void,
    ) => void;
}

function DetailFieldsComp(props: IDetailFieldsProps & IPrivateProps & IContextProps) {
    const {
        absences,
        employee,
        getRequestId,
        hasStudentWorkers,
        isOnboardingWizardRoute: fromOnboardingWizard,
        jobStudent,
        mayEdit,
        mayEditEmail,
        onOpenOverlay,
        statutes,
        translator,
        updateEmployeeField,
    } = props;

    if (!employee && !employee.id) {
        return null;
    }

    const INITIAL_VALUES: Partial<TEmployeeUpdateFields> = employee ? {
        firstName: employee.firstName,
        name: employee.name,
        sexId: employee.sexId,
        nationalityId: employee.nationalityId,
        languageId: employee.languageId,
        email: employee.email,
        phone: tryFormattingPhoneInternational(employee.phone),
        nationalRegisterNumber: formatNationalRegisterNumber(employee.nationalRegisterNumber),
        mobilePhone: tryFormattingPhoneInternational(employee.mobilePhone),
        employeeNumber: employee.employeeNumber,
    } : {};

    return (
        <Form
            name="employee-detail-fields-form"
            initialValues={INITIAL_VALUES}
            schema={schema}
            render={({
                values, handleChange, errors, setFieldValue,
            }: IFormRenderProps<typeof fields>) => {

                function onSexItemSelected(value: number) {
                    setFieldValue('sexId', value);
                }

                function onNationalityItemSelected(value: number | string) {
                    if (typeof value === 'string') {
                        setFieldValue('nationalityId', parseInt(value as string, 10));
                        return;
                    }
                    setFieldValue('nationalityId', value);
                }

                function onLanguageItemSelected(value: number) {
                    setFieldValue('languageId', value);
                }

                function onEmailChanged(value: string) {
                    setFieldValue('email', value);
                }

                const isNewFutureEmployee = employee && !isInThePast(employee.dateInService);

                const nationalityIdAsNumber = parseInt(values.nationalityId, 10);

                const showNationalRegisterNumberError = (
                    nationalityIdAsNumber === nationalityIdBelgium
                    && (
                        employee.nationalRegisterNumber.length === 0
                        || (employee.nationalRegisterNumber === '' || null)
                    )
                );

                const missingNationalRegisterNumberError = {
                    error: {
                        //  eslint-disable-next-line max-len
                        message: 'administration.employees.detail.employee_details.personal_data.validate.missing_national_register_number',
                        type: ErrorTypes.Custom,
                    },
                    // eslint-disable-next-line max-len
                    fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.validate.missing_national_register_number',
                };

                const nationalityIdError = {
                    error: errors.nationalityId,
                    //  eslint-disable-next-line max-len
                    fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.nationality',
                };

                return (
                    <>
                        <h4>
                            <Translate msg="administration.employees.detail.employee_details.company_data.title" />
                        </h4>
                        <AdvancedPropertyInput
                            labelKey="administration.employees.detail.employee_details.company_data.function"
                            value={employee.function && employee.function.description}
                            readonly={!mayEdit}
                            onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditFunction)}
                        />
                        <AdvancedPropertyInput
                            labelKey="administration.employees.detail.employee_details.company_data.seat"
                            value={employee.company && employee.company.name}
                            readonly={!mayEdit}
                            onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditSeat)}
                        />
                        {isNewFutureEmployee ? (
                            <AdvancedPropertyInput
                                // eslint-disable-next-line max-len
                                labelKey="administration.employees.detail.employee_details.company_data.date_in_function"
                                value={formatDateForDisplay(employee.dateInFunction)}
                                readonly={!mayEdit}
                                onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditDateInFunction)}
                            />
                        ) : (
                                <TextPropertyInput
                                    id="dateInFunction"
                                    name="dateInFunction"
                                    // eslint-disable-next-line max-len
                                    labelKey="administration.employees.detail.employee_details.company_data.date_in_function"
                                    readonly={true}
                                    value={formatDateForDisplay(employee.dateInFunction)}
                                />
                            )}
                        {!fromOnboardingWizard &&
                            <>
                                <TinyLoader asyncInfoSelector={getSelectedEmployeeStatutesAsyncInfo}>
                                    <AdvancedPropertyInput
                                        // eslint-disable-next-line max-len
                                        labelKey="administration.employees.detail.employee_details.company_data.statutes"
                                        value={<DescriptionPeriod periods={statutes} />}
                                        readonly={!mayEdit}
                                        onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditStatutes)}
                                    />
                                </TinyLoader>
                                <TinyLoader asyncInfoSelector={getSelectedEmployeeAbsencesAsyncInfo}>
                                    <AdvancedPropertyInput
                                        // eslint-disable-next-line max-len
                                        labelKey="administration.employees.detail.employee_details.company_data.absences"
                                        value={<DescriptionPeriod periods={absences} />}
                                        readonly={!mayEdit}
                                        onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditAbsenses)}
                                    />
                                </TinyLoader>
                                <TextPropertyInput
                                    id="fitnessToDriveValidUntil"
                                    name="fitnessToDriveValidUntil"
                                    // eslint-disable-next-line max-len
                                    labelKey="administration.employees.detail.employee_details.company_data.fitness_to_drive_valid_until"
                                    readonly={true}
                                    value={employee.fitnessToDriveValidUntil &&
                                        formatDateForDisplay(employee.fitnessToDriveValidUntil)}
                                />
                                <TextPropertyInput
                                    id="employeeCode"
                                    name="employeeCode"
                                    // eslint-disable-next-line max-len
                                    labelKey="administration.employees.detail.employee_details.company_data.employee_code"
                                    readonly={true}
                                    value={employee.employeeCode}
                                    // eslint-disable-next-line max-len
                                    tooltipTranslationKey="administration.employees.detail.employee_details.tooltip.employee_code"
                                />
                                <ConfirmableTextPropertyInput
                                    id="employeeNumber"
                                    name="employeeNumber"
                                    // eslint-disable-next-line max-len
                                    labelKey="administration.employees.detail.employee_details.company_data.employee_number"
                                    readonly={true}
                                    value={values.employeeNumber}
                                    onChange={handleChange}
                                    initialValue={INITIAL_VALUES.employeeNumber}
                                    // eslint-disable-next-line max-len
                                    tooltipTranslationKey="administration.employees.detail.employee_details.tooltip.employee_number"
                                    asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                                    requestId={getRequestId(employee.id, fields.employeeNumber)}
                                    onSave={() => updateEmployeeField(
                                        employee.id, 'employeeNumber', values.employeeNumber,
                                    )}
                                />
                                <TinyLoader asyncInfoSelector={getSelectedEmployeeStatutesAsyncInfo}>
                                    <AdvancedPropertyInput
                                        // eslint-disable-next-line max-len
                                        labelKey="administration.employees.detail.employee_details.company_data.cost_center"
                                        value={employee.costCenter && employee.costCenter.name}
                                        readonly={!mayEdit}
                                        onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditCostCenter)}
                                    />
                                </TinyLoader>
                            </>
                        }
                        {hasStudentWorkers && <TinyLoader asyncInfoSelector={getSelectedEmployeeJobStudentAsyncInfo}>
                            <AdvancedPropertyInput
                                // eslint-disable-next-line max-len
                                labelKey="administration.employees.detail.employee_details.company_data.job_student.label"
                                value={formatJobStudent(jobStudent, translator)}
                                readonly={!mayEdit}
                                onClick={() => onOpenOverlay(
                                    EmployeeDetailsOverlayType.EditJobStudent,
                                    ({ email }: { email: string }) => onEmailChanged(email),
                                )}
                            />
                        </TinyLoader>}
                        <h4>
                            <Translate
                                msg="administration.employees.detail.employee_details.personal_data.title"
                            />
                        </h4>
                        <NationalRegisterNumberConfirmablePropertyInput
                            id="national-register-number"
                            name={fields.nationalRegisterNumber}
                            // eslint-disable-next-line max-len
                            labelKey="administration.employees.detail.employee_details.personal_data.national_register_nr"
                            readonly={
                                true
                                // [KZUAT-2340]: Disable the option to adjust the national register number until Mensura
                                // has decided whether it should be possible to do this for foreign workers.
                                // Foreign workers did not have to provide a national register number upon registration,
                                // they provided a date of birth.
                                // Changing the national register number for Belgian employees is not allowed by default

                                // Left the below values in case this needs to be reverted at a later stage.
                                // (check line 505 to uncomment isBlank() function)
                                // !mayEdit
                                // !isBlank(INITIAL_VALUES.nationalRegisterNumber)
                            }
                            value={values.nationalRegisterNumber}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.nationalRegisterNumber}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.nationalRegisterNumber)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'nationalRegisterNumber', values.nationalRegisterNumber,
                            )}
                            validationError={{
                                error: errors.nationalRegisterNumber,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.national_register_nr',
                            }}
                        />
                        <ConfirmableTextPropertyInput
                            id="firstname"
                            name={fields.firstName}
                            labelKey="administration.employees.detail.employee_details.personal_data.first_name"
                            readonly={!mayEdit}
                            value={values.firstName}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.firstName}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.firstName)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'firstName', values.firstName,
                            )}
                            validationError={{
                                error: errors.firstName,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.first_name',
                            }}
                        />
                        <ConfirmableTextPropertyInput
                            id="name"
                            name={fields.name}
                            labelKey="administration.employees.detail.employee_details.personal_data.name"
                            readonly={!mayEdit}
                            value={values.name}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.name}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.name)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'name', values.name,
                            )}
                            validationError={{
                                error: errors.name,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.name',
                            }}
                        />
                        <ConfirmableConstantsTypeaheadPropertyInput
                            id="sexId"
                            name={fields.sexId}
                            labelKey="administration.employees.detail.employee_details.personal_data.sex"
                            readonly={!mayEdit}
                            value={values.sexId}
                            initialValue={INITIAL_VALUES.sexId}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            constantType={ConstantType.SEXES}
                            onItemSelected={onSexItemSelected}
                            requestId={getRequestId(employee.id, fields.sexId)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'sexId', values.sexId,
                            )}
                            validationError={{
                                error: errors.sexId,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.sex',
                            }}
                        />
                        <ConfirmableConstantsTypeaheadPropertyInput
                            id="nationalityId"
                            name={fields.nationalityId}
                            labelKey="administration.employees.detail.employee_details.personal_data.nationality"
                            readonly={!mayEdit}
                            value={values.nationalityId}
                            initialValue={INITIAL_VALUES.nationalityId}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            constantType={ConstantType.NATIONALITIES}
                            onItemSelected={onNationalityItemSelected}
                            requestId={getRequestId(employee.id, fields.nationalityId)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'nationalityId', values.nationalityId,
                            )}
                            validationError={
                                showNationalRegisterNumberError
                                ?
                                missingNationalRegisterNumberError
                                :
                                nationalityIdError
                            }
                        />
                        <ConfirmableConstantsTypeaheadPropertyInput
                            id="languageId"
                            name={fields.languageId}
                            labelKey="administration.employees.detail.employee_details.personal_data.language"
                            readonly={!mayEdit}
                            value={values.languageId}
                            initialValue={INITIAL_VALUES.languageId}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            constantType={ConstantType.LANGUAGES}
                            onItemSelected={onLanguageItemSelected}
                            requestId={getRequestId(employee.id, fields.languageId)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'languageId', values.languageId,
                            )}
                            validationError={{
                                error: errors.languageId,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.language',
                            }}
                        />
                        <AdvancedPropertyInput
                            labelKey="administration.employees.detail.employee_details.personal_data.address"
                            value={formatAddress(employee.address)}
                            readonly={!mayEdit}
                            onClick={() => onOpenOverlay(EmployeeDetailsOverlayType.EditAddress)}
                        />
                        <ConfirmableTextPropertyInput
                            id="email"
                            name={fields.email}
                            labelKey="administration.employees.detail.employee_details.personal_data.email"
                            readonly={!mayEdit || !mayEditEmail}
                            value={values.email}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.email}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.email)}
                            onSave={() => updateEmployeeField(
                                employee.id, 'email', values.email,
                            )}
                            validationError={{
                                error: errors.email,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.email',
                            }}
                        />
                        <ConfirmableTextPropertyInput
                            id="phone"
                            name={fields.phone}
                            labelKey="administration.employees.detail.employee_details.personal_data.phone"
                            readonly={!mayEdit}
                            value={values.phone}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.phone}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.phone)}
                            onSave={() => {
                                setFieldValue('phone', tryFormattingPhoneInternational(values.phone));
                                updateEmployeeField(
                                    employee.id, 'phone', values.phone,
                                );
                            }}
                            validationError={{
                                error: errors.phone,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.phone',
                            }}
                        />
                        <ConfirmableTextPropertyInput
                            id="mobile-phone"
                            name={fields.mobilePhone}
                            labelKey="administration.employees.detail.employee_details.personal_data.mobile"
                            readonly={!mayEdit}
                            value={values.mobilePhone}
                            onChange={handleChange}
                            initialValue={INITIAL_VALUES.mobilePhone}
                            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                            requestId={getRequestId(employee.id, fields.mobilePhone)}
                            onSave={() => {
                                setFieldValue('mobilePhone', tryFormattingPhoneInternational(values.mobilePhone));
                                updateEmployeeField(
                                    employee.id, 'mobilePhone', values.mobilePhone,
                                );
                            }}
                            validationError={{
                                error: errors.mobilePhone,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.employees.detail.employee_details.personal_data.mobile',
                            }}
                        />
                    </>
                );
            }}
        />
    );
}

export default connect<IPrivateProps, IDetailFieldsProps>({
    stateProps: (state) => {
        const { id } = getSelectedEmployee(state);
        const employeeStatus = getSelectedEmployeeStatus(state);

        return {
            mayEdit: hasRequiredAccessLevels(state, { employee: 'W' }),
            statutes: getSelectedEmployeeStatutes(state),
            absences: getSelectedEmployeeAbsences(state),
            isOnboardingWizardRoute: isOnboardingWizardRoute(state),
            jobStudent: getSelectedEmployeeJobStudent(state),
            hasStudentWorkers: getSelectedSeatHasStudentWorkers(state),
            mayEditEmail: employeeStatus.id === id && !employeeStatus.isActiveInEmployeeMyMensura,
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                updateEmployeeField: (id: number, fieldName: keyof TEmployeeUpdateFields, value: string) => {
                    const updateValue =
                        fieldName === 'phone' || fieldName === 'mobilePhone' ?
                            tryFormattingPhoneForBackend(value) : value;

                    dispatch(updateEmployee({
                        requestId: publicProps.getRequestId(id, fieldName),
                        id,
                        employeeData: {
                            [fieldName]: updateValue,
                        },
                    }));
                },
            };
        };
    },
})(DetailFields);

function DetailFields(props: IPrivateProps & IDetailFieldsProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <DetailFieldsComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

// [KZUAT-2340]: Helper disabled because of previous comment (see NationalRegisterNumberConfirmablePropertyInput )

// function isBlank(input: string) {
//     if (!input) {
//         return true;
//     }

//     return input.trim().length === 0;
// }
