import {
    IMappedAdministeredVaccination,
    IMappedToAdministerVaccination,
    IRequestVaccinationPayload,
} from '../../../models/interventions/vaccines';
import { ITraceableApiError } from '../../../models/general/error';
import { createAction, createFailAction, createSuccessAction, createTypeActions } from '../../index';

import { FETCH_ADMINISTERED_VACCINATIONS, FETCH_TO_ADMINISTER_VACCINATIONS, REQUEST_VACCINATION } from './types';

/* Administered vaccinations */
export function fetchAdministeredVaccinations() {
    return createAction(FETCH_ADMINISTERED_VACCINATIONS, {});
}

export function fetchAdministeredVaccinationsSucceeded(payload: IMappedAdministeredVaccination[]) {
    return createSuccessAction<IMappedAdministeredVaccination[]>(FETCH_ADMINISTERED_VACCINATIONS, payload);
}

export function fetchAdministeredVaccinationsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_ADMINISTERED_VACCINATIONS, error);
}

/* To Administer vaccinations */
export function fetchToAdministerVaccinations() {
    return createAction(FETCH_TO_ADMINISTER_VACCINATIONS, {});
}

export function fetchToAdministerVaccinationsSucceeded(payload: IMappedToAdministerVaccination[]) {
    return createSuccessAction<IMappedToAdministerVaccination[]>(FETCH_TO_ADMINISTER_VACCINATIONS, payload);
}

export function fetchToAdministerVaccinationsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_TO_ADMINISTER_VACCINATIONS, error);
}

/* Request medical examination */
export const requestVaccinationActions =
    createTypeActions<IRequestVaccinationPayload>({
        type: REQUEST_VACCINATION,
    });
