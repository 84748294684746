import React from 'react';
import classNames from 'classnames';
import { connect } from '../../index';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import TinyLoader from '../../common/waiting/TinyLoader';
import iconMoney from '../../assets/img/icons/money.svg';
import iconAdvice from '../../assets/img/icons/advice.svg';
import iconChart from '../../assets/img/icons/chart.svg';
import iconCourse from '../../assets/img/icons/course-highlight.svg';
import ROUTE_KEYS from '../../../routeKeys';
import { getOpenInvoicesCountAsyncInfo, getOpenInvoicesCountAsText } from '../../../redux/invoice/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import { IState } from '../../../redux/IState';
import {
    doesCompanyCurrentlyUsesPreventionUnits,
    doesCompanyCurrentlyUsesFlatFee,
} from '../../../redux/preventionUnits/selectors';
import InlineSVG from '../../common/icons/InlineSVG';

interface IPrivateProps {
    openInvoicesCount: string;
    companyUsesPreventionUnits: boolean;
    companyUsesFlafFee: boolean;
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        openInvoicesCount: getOpenInvoicesCountAsText(state),
        companyUsesPreventionUnits: doesCompanyCurrentlyUsesPreventionUnits(state),
        companyUsesFlafFee: doesCompanyCurrentlyUsesFlatFee(state),
    }),
})(NavigationDashboardBlock);

function NavigationDashboardBlock(props: IPrivateProps) {
    const { openInvoicesCount, companyUsesPreventionUnits, companyUsesFlafFee } = props;

    return (
        <div className="navigation">
            <NavigationItem
                id="billing-navigation-item-link"
                iconImgSrc={iconMoney}
                translationCode={Number(openInvoicesCount) > 0 ? 'billing' : 'billing_empty_invoices'}
                translationLinkPlaceholders={{
                    nrOfInvoices: openInvoicesCount,
                }}
                targetRouteKey={ROUTE_KEYS.R_INVOICES_OPEN}
                asyncInfoSelector={getOpenInvoicesCountAsyncInfo}
            />
            <NavigationItem
                id="courses-navigation-item-link"
                iconImgSrc={iconCourse}
                translationCode="courses"
                targetRouteKey={ROUTE_KEYS.R_COURSES_OVERVIEW}
                highlight
            />
            {companyUsesPreventionUnits && (
                <NavigationItem
                    id="prevention-navigation-item-link"
                    iconImgSrc={iconChart}
                    translationCode="prevention"
                    targetRouteKey={ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW}
                />
            )}
            {companyUsesFlafFee && (
                <NavigationItem
                    id="policy-advice-navigation-item-link"
                    iconImgSrc={iconAdvice}
                    translationCode="policy_advice"
                    targetRouteKey={ROUTE_KEYS.R_POLICY_ADVICE}
                />
            )}
            {companyUsesFlafFee && (
                <NavigationItem
                    id="flat-fee-navigation-item-link"
                    iconImgSrc={iconChart}
                    translationCode="flat_fee"
                    targetRouteKey={ROUTE_KEYS.R_FLAT_FEE_OVERVIEW}
                />
            )}
            {/*TODO*/}
            {/*<NavigationItem*/}
                {/*id="reports-navigation-item-link"*/}
                {/*iconImgSrc={iconFiles}*/}
                {/*translationCode="reports"*/}
                {/*targetRouteKey={ROUTE_KEYS.R_HOME}*/}
            {/*/>*/}
        </div>
    );
}

function NavigationItem({
    id,
    iconImgSrc,
    translationCode,
    translationLinkPlaceholders,
    targetRouteKey,
    asyncInfoSelector = () => ({ status: AsyncStatus.Success, error: null }),
    highlight,
}: {
    id: string;
    iconImgSrc: any;
    translationCode: string;
    translationLinkPlaceholders?: {[key: string]: string};
    targetRouteKey: ROUTE_KEYS;
    asyncInfoSelector?: (state: IState) => IAsyncFieldInfo;
    highlight?: boolean;
}) {
    return (
        <ShowIfAllowed routeKey={targetRouteKey}>
            <div
                className={classNames('item', {
                    'item--highlight': !!highlight,
                })}
            >
                <header className="header">
                    <InlineSVG svg={iconImgSrc} />
                </header>
                <div className="content">
                    <h6><Translate msg={`home.dashboard.navigation.${translationCode}.title`} /></h6>
                    <TinyLoader asyncInfoSelector={asyncInfoSelector}>
                        <LinkToRoute
                            id={id}
                            to={targetRouteKey}
                            className="icon-link"
                        >
                        <span>
                            <Translate
                                msg={`home.dashboard.navigation.${translationCode}.link`}
                                placeholders={translationLinkPlaceholders}
                            />
                        </span>
                            <Icon typeName="chevron-right" />
                        </LinkToRoute>
                    </TinyLoader>
                </div>
            </div>
        </ShowIfAllowed>
    );
}
