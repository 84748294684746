import React, { CSSProperties, PureComponent } from 'react';
import './overlay-content.scss';
import classNames from 'classnames';
import { isKeyPressed } from '../../../../utils/keyboard';
import OverlayCurtain, { IOverlayCurtainRenderProps } from '../OverlayCurtain';
import GoBack from '../../navigation/GoBack';
import { ROOT_ELEMENT_ID } from '../../../../config/dom.config';
import { isExtraSmallScreen } from '../../../../utils/dom/screenSize';

const CLASS_NAME = 'OverlayContent';

interface IOverlayContentProps {
    show: boolean;
    onCloseIntent: () => void;
    removeOpacity?: boolean;
    children: React.ReactNode | ((renderPrpos: IOverlayCurtainRenderProps) => React.ReactNode);
}

interface IState {
    isAlreadyOpened: boolean;
}

class OverlayContent extends PureComponent<IOverlayContentProps, IState> {
    constructor(props: IOverlayContentProps) {
        super(props);
        this.state = {
            isAlreadyOpened: props.show,
        };

        this.triggerOnCloseIntent = this.triggerOnCloseIntent.bind(this);

        // Close on escape key pressed
        this.onKeyUp = this.onKeyUp.bind(this);
        if (document) {
            document.addEventListener('keyup', this.onKeyUp);
        }
    }

    public UNSAFE_componentWillUpdate(nextProps: IOverlayContentProps) {
        if (nextProps.show !== this.props.show) {
            this.setState({
                isAlreadyOpened: this.state.isAlreadyOpened || nextProps.show,
            });
        }
    }

    public componentDidMount() {
        this.updateBodyElementOverflow(this.props.show);
    }

    public componentDidUpdate(prevProps: IOverlayContentProps) {
        if (this.props.show && !prevProps.show) {
            this.updateBodyElementOverflow(true);
        } else if (prevProps.show && !this.props.show) {
            this.updateBodyElementOverflow(false);
        }
    }

    public componentWillUnmount() {
        if (document) {
            document.removeEventListener('keyup', this.onKeyUp);
        }
        this.updateBodyElementOverflow(false);
    }

    public render() {
        const { isAlreadyOpened } = this.state;
        const { children, show, removeOpacity } = this.props;

        const className = classNames(CLASS_NAME, {
            ['no-opacity']: !!removeOpacity,
        });
        const inlineStyles: CSSProperties = {
            // Prevent animation on the initial render when it's hidden
            display: !isAlreadyOpened ? 'none' : undefined,
        };

        const childrenTypedAsFunction = children as ((renderPrpos: IOverlayCurtainRenderProps) => React.ReactNode);

        return (
            <OverlayCurtain
                show={show}
                fadeEffect={removeOpacity ? 'none' : 'blur-more'}
                onClick={this.triggerOnCloseIntent}
                animationEffect="scale"
            >
                {(renderProps) => (
                    <section className={className} style={inlineStyles} data-scroll-container={true}>
                        <GoBack
                            id="close-overlay"
                            onClick={this.triggerOnCloseIntent}
                            iconTypeName="arrow-left"
                        />
                        <div className="fade" />
                        <div className="container">
                            {typeof children === 'function' ? childrenTypedAsFunction(renderProps) : children}
                        </div>
                    </section>
                )}
            </OverlayCurtain>
        );
    }

    private onKeyUp(e: KeyboardEvent) {
        if (isKeyPressed(e, 'Escape') && this.props.show) {
            this.triggerOnCloseIntent();
        }
    }

    private triggerOnCloseIntent() {
        const { onCloseIntent } = this.props;
        if (typeof onCloseIntent === 'function') {
            onCloseIntent();
        }
    }

    private updateBodyElementOverflow(disableOverflow: boolean) {
        if (disableOverflow) {
            document.body.style.overflow = 'hidden';
            if (isExtraSmallScreen()) {
                document.getElementById(ROOT_ELEMENT_ID).style.overflow = 'hidden';
            }
        } else {
            document.body.style.overflow = '';
            document.getElementById(ROOT_ELEMENT_ID).style.overflow = '';
        }
    }
}

export default OverlayContent;
