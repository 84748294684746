import React, { ChangeEvent } from 'react';
import { ITextInputBaseProps } from '../TextInput';
import MaskedTextInput from '../MaskedTextInput';
import formatNationalRegisterNumber from '../../../../utils/formatting/formatNationalRegisterNumber';
import { ConfirmableMaskedTextPropertyInput, IConfirmablePropertyTextInputProps } from '../ConfirmablePropertyInput';
import { TextPropertyInput, ITextPropertyInputProps } from '../PropertyInput';

const MASK = [
    /\d/, /\d/, '.', /\d/, /\d/, '.',
    /\d/, /\d/, '-', /\d/, /\d/, /\d/, '.',
    /\d/, /\d/,
];

export function NationalRegisterNumberTextInput(props: ITextInputBaseProps & { value: string }) {
    return (
        <MaskedTextInput
            {...props}
            placeholder="00.00.00-000.00"
            mask={MASK}
            onChange={(e) => formatNationalRegisterNumberForHandleChange(e, props.onChange)}
            disableAutoComplete
        />
    );
}

export function NationalRegisterNumberConfirmablePropertyInput(props: IConfirmablePropertyTextInputProps) {
    return (
        <ConfirmableMaskedTextPropertyInput
            {...props}
            mask={MASK}
            onChange={(e) => formatNationalRegisterNumberForHandleChange(e, props.onChange)}
        />
    );
}

export function NationalRegisterNumberPropertyInput(props: ITextPropertyInputProps) {
    return (
        <TextPropertyInput
            {...props}
            hasMask={true}
            mask={MASK}
            onChange={(e) => formatNationalRegisterNumberForHandleChange(e, props.onChange)}
        />
    );
}

function formatNationalRegisterNumberForHandleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
) {
    e.target.value = formatNationalRegisterNumber(e.target.value);
    return handleChange(e);
}
