import dayjs from 'dayjs';

interface IDate {
    start: Date | string;
    end: Date | string;
}

export function dateRangeOverlaps(date1: IDate, date2: IDate, ignoreStartAndEndEqual: boolean = false): boolean {
    const parsedDate1 = {
        start: parseDate(date1.start),
        end: parseDate(date1.end),
    };
    const parsedDate2 = {
        start: parseDate(date2.start),
        end: parseDate(date2.end),
    };
    if (ignoreStartAndEndEqual) {
        if (parsedDate1.start <= parsedDate2.start && parsedDate2.start < parsedDate1.end) {
            return true;
        }
        if (parsedDate1.start < parsedDate2.end && parsedDate2.end <= parsedDate1.end) {
            return true;
        }
    } else {
        if (parsedDate1.start <= parsedDate2.start && parsedDate2.start <= parsedDate1.end) {
            return true;
        }
        if (parsedDate1.start <= parsedDate2.end && parsedDate2.end <= parsedDate1.end) {
            return true;
        }
    }

    if (parsedDate2.start < parsedDate1.start && parsedDate1.end < parsedDate2.end) {
        return true;
    }
    return false;
}

function parseDate(date: Date | string) {
    return typeof date === 'string' ? dayjs(date).toDate().getTime() : date.getTime();
}
