import React from 'react';
import { schema } from './schema';
import InterventionInput, { IFormValues } from './InterventionInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { getInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/selectors';
import { updateInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/actions';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { IInterventionRequestWizardPayload } from '../../../../../models/interventions/requestIntervention';

const CLASS_NAME = 'InterventionRequest__Remarks';
const FORM_NAME = 'intervention-request-remarks-form';

const InterventionWizardFormStep = WizardFormStep<IFormValues>();

export default function Intervention(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <InterventionWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.intervention_request_new.steps.intervention.title',
            }}
            showLoaderSelector={() => false}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getInterventionRequestWizardEntity(state);
                    // Check if we have a prestationCode passed as route payload.
                    const { prestationCode } = getRoutePayload<IInterventionRequestWizardPayload>(state);

                    return {
                        type: wizardEntity.type || prestationCode,
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <InterventionInput {...renderProps} />
                    </div>,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => updateInterventionRequestWizardEntity({
                    type: values.type,
                }),
            }}
        />
    );
}
