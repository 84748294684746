import { object, string } from 'yup';

import { fields } from './ConfirmEmailModal.const';

export const schema = (email: string) => {
    const matchEmailRegEx = new RegExp(`^${email}$`);

    return object({
        [fields.email]: string().matches(matchEmailRegEx).required(),
    });
};
