import { object, number } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../../models/ui/form';
import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../utils/libs/yup/string';
import { internationalAddressSchema } from '../../../../common/address/schema';

export const fields: ShapeOf<FormValues> = {
    name: 'name',
    address: 'address',
    ventureNumber: 'ventureNumber',
    languageId: 'languageId',
    companyCode: 'companyCode',
    email: 'email',
    phone: 'phone',
    fax: 'fax',
};

export const schema = object({
    [fields.name]: string().required(),
    [fields.ventureNumber]: string().required(),
    [fields.languageId]: number().required(),
    [fields.email]: string().email(),
    [fields.phone]: phoneNumber(),
    [fields.fax]: phoneNumber(),
    [fields.address]: internationalAddressSchema.required(),
});
