import { FORM_MOUNTED, FORM_UNMOUNTED, FORM_UPDATED, APPLICATION_INITIALIZATION_FAILED } from './types';
import { createAction } from '../../index';
import { IActiveForm } from '../../../models/ui/form';

export function formMounted(payload: IActiveForm) {
    return createAction<IActiveForm>(FORM_MOUNTED, payload);
}

export function formUnmounted(formName: string) {
    return createAction<{name: string}>(FORM_UNMOUNTED, { name: formName });
}

export function formUpdated(payload: IActiveForm) {
    return createAction<IActiveForm>(FORM_UPDATED, payload);
}

export function applicationInitializationFailed() {
    return createAction(APPLICATION_INITIALIZATION_FAILED, {});
}
