import { object } from 'yup';
import nationalRegisterNumber from '../../../../utils/libs/yup/nationalRegisterNumber';
import number from '../../../../utils/libs/yup/number';
import phoneNumber from '../../../../utils/libs/yup/phoneNumber';
import string from '../../../../utils/libs/yup/string';

export const nationalityIdBelgium = 1831748;

export const fields = {
    firstName: 'firstName',
    name: 'name',
    sexId: 'sexId',
    nationalityId: 'nationalityId',
    nationalRegisterNumber: 'nationalRegisterNumber',
    languageId: 'languageId',
    email: 'email',
    phone: 'phone',
    mobilePhone: 'mobilePhone',
    employeeNumber: 'employeeNumber',
};

export const schema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.sexId]: number().required(),
    [fields.nationalityId]: number().required(),
    [fields.nationalRegisterNumber]: nationalRegisterNumber().nullable(true).when(
        fields.nationalityId,
        {
            is: (value) => {
                if (typeof value === 'string') {
                    const valueAsNumber = parseInt(value as string, 10);
                    return valueAsNumber === nationalityIdBelgium;
                }
                return value === nationalityIdBelgium;
            },
            then: nationalRegisterNumber().required(),
        }),
    [fields.languageId]: number().required(),
    [fields.email]: string().email(),
    [fields.phone]: phoneNumber(),
    [fields.mobilePhone]: phoneNumber(),
    [fields.employeeNumber]: string().nullable(true),
});
