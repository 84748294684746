import { IAsyncFieldInfo } from './redux';
import { IState } from '../../redux';

export enum SortOrder {
    Ascending = 'ascending',
    Descending = 'descending',
    None = 'none',
}

/**
 * Declares which sorting to use
 * Eg sort numeric or by string
 */
export enum SortType {
    String = 'string',
    Number = 'number',
    Boolean = 'boolean',
    DotSeparatedNumber = 'dotSeparatedNumber',
}

export interface ISortedColumn<ColumnNames> {
    name: keyof ColumnNames;
    sortOrder: SortOrder;
}

export type ListColumns<ColumnNames> = {
    [key in keyof ColumnNames]: IColumn<ColumnNames>
};

export type ListItemId = string | number;

export interface ListItemError {
    // TODO Jeff, change this if needed
    message: string;
}

export interface ListItem<ColumnNames, IdType = ListItemId, Data = {}> {
    id: IdType;
    error?: ListItemError;
    asyncInfoSelector?: (state: IState) => IAsyncFieldInfo;
    columns: {
        [key in keyof ColumnNames]: ListItemValueTypes
    };
    data?: Data;
}

export type ListItemValueTypes = string | number | boolean;

export interface IColumn<ColumnNames> {
    label?: string | React.ReactElement<{
        msg: string;
        placeholders?: { [key: string]: string }
    }>;
    // In case we set label to 'null' to render a specific header with 'headerRender',
    // we still want a label for the mobile view
    mobileLabel?: string | React.ReactElement<{
        msg: string;
        placeholders?: { [key: string]: string }
    }>;
    sortable?: boolean;
    sortType?: SortType;
    sortValue?: (item: ListItem<ColumnNames>) => ListItemValueTypes;
    render?: (item: ListItem<ColumnNames>, index?: number) => React.ReactNode;
    // you can hide a column if it for example should be filterable but not shown
    // (p.s. hidden columns will not be used for client side searches)
    hide?: boolean;
    excludeFromSearch?: boolean;
    includeInSearchEvenWhenHidden?: boolean;
    percentWidth: number;
    minWidth?: number;
    headerRender?: (items: ListItem<ColumnNames>[]) => React.ReactElement<{}>;
    align?: 'left' | 'center' | 'right';
    itemColumnCollSpan?: (item: ListItem<ColumnNames>) => { colSpan: number, colSpanPercentWidth: number };
    hideItemColumn?: (item: ListItem<ColumnNames>) => boolean;
    selectable?: boolean;
}
