import { ISortedColumn, SortOrder } from '../../../../../../../../models/general/list';

export const BASE_NAME = 'course-detail-sessions';
export const CLASS_NAME = 'CourseSessionByMonth';
export const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.child.sessions.list';

export interface IColumnNames {
   dateOfFirstDay: string;
   dates: string;
   dateSort: string;
   language: string;
   locationName: string;
   addressPostCode: string;
   addressCity: string;
   addressStreet: string;
   addressNumber: string;
   addressDisplay: string;
   freeSlots: number;
   maxAmount: number;
   availability: string;
   vacant: boolean;
}

export const INITIAL_SORT: ISortedColumn<IColumnNames> = {
   name: 'dateOfFirstDay',
   sortOrder: SortOrder.Ascending,
};
