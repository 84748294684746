import { object } from 'yup';

export const fields = {
    location: 'location',
    session: 'session',
};

export const schema = object({
    [fields.location]: object().required(),
    [fields.session]: object().required(),
});
