import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_MEDICAL_DOCUMENTS, FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IMedicalDocument } from '../../../models/documentCenter/medicalDocuments';
import { IDocument } from '../../../models/general/documents';
import { DISMISS_DOCUMENT_DIALOG } from '../../employee/documents/types';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.DOCUMENT_CENTER_MEDICAL_DOCUMENTS;

export interface IReducerState {
    medicalDocuments: IAsyncFetchField<IMedicalDocument[]>;
    medicalDocumentFilesInBulk: IAsyncFetchField<{}>;
}

const initialState: IReducerState = {
    medicalDocuments: getAsyncFetchInitialState(),
    medicalDocumentFilesInBulk: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(DISMISS_DOCUMENT_DIALOG)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                medicalDocumentsFiles: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<IMedicalDocument[], IReducerState, IMedicalDocument[]>({
        baseActionType: FETCH_MEDICAL_DOCUMENTS,
        fieldName: 'medicalDocuments',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_MEDICAL_DOCUMENTS,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK,
        fieldName: 'medicalDocumentFilesInBulk',
        resetDataOnTrigger: true,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
