import React, { useState } from 'react';
import Dialog from '../../../common/modals/Dialog';
import Translate from '../../../common/Translate';
import Button from '../../../common/buttons/Button';
import { connect } from '../../..';
import ROUTE_KEYS from '../../../../routeKeys';
import Checkbox from '../../../common/input/Checkbox';
import './performance-dashboard-dialog.scss';
import { getPath } from '../../../../routes';
import {
    setSkipPerformanceDialogWarningAction,
    toggleIsPerformanceDashboardDialogOpenAction,
    navigateToPerformanceDashboardAction,
} from '../../../../redux/auth/actions';
import {
    getSkipPerformanceDashboardTutorial,
    getIsPerformanceDashboardDialogOpen,
    getNavigateToPerformanceDashboardAsyncInfo,
} from '../../../../redux/auth/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader';

const CLASS_NAME = 'PerformanceDashboardDialog';
const TRANSLATION_PREFIX = 'administration.performance_dashboard.dialog';

interface IPrivateProps {
    setSkipWarning: () => void;
    skipTutorial: boolean;
    show: boolean;
    navigateToPerformanceDashboard: () => void;
    onCloseDialog: () => void;
    navigateToPerformanceDashboardAsyncInfo: IAsyncFieldInfo;
}

function PerformanceDashboardDialog(props: IPrivateProps) {
    const [checked, setChecked] = useState(false);
    const {
        show,
        onCloseDialog,
        setSkipWarning,
        skipTutorial,
        navigateToPerformanceDashboard,
        navigateToPerformanceDashboardAsyncInfo,
    } = props;

    return (
        <>
            <Loader useFullScreen show={navigateToPerformanceDashboardAsyncInfo.status} />
            <Dialog
                show={show}
                onCloseIntent={onCloseDialog}
                header={`${TRANSLATION_PREFIX}.title`}
                className={CLASS_NAME}
            >
                <p>
                    <Translate msg={`${TRANSLATION_PREFIX}.text`} />
                </p>
                <Checkbox
                    name="do-not-show-again"
                    className={`${CLASS_NAME}__skip`}
                    checked={checked}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                    }}
                >
                    <Translate msg={`${TRANSLATION_PREFIX}.skip`} />
                </Checkbox>
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="success-dialog-confirm"
                        typeName="secondary"
                        onClick={onNavigateToPerformanceDashboard}
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.confirm`} />
                    </Button>
                </div>
            </Dialog>
        </>
    );

    function onNavigateToPerformanceDashboard() {
        if (skipTutorial) {
            navigateToPerformanceDashboard();
        } else {
            const path = getPath({ routeKey: ROUTE_KEYS.R_PERFORMANCE_DASHBOARD });
            window.open(path, '_blank');
        }

        if (checked) {
            setSkipWarning();
        }
        onCloseDialog();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            show: getIsPerformanceDashboardDialogOpen(state),
            skipTutorial: getSkipPerformanceDashboardTutorial(state),
            navigateToPerformanceDashboardAsyncInfo: getNavigateToPerformanceDashboardAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            setSkipWarning: () => {
                dispatch(setSkipPerformanceDialogWarningAction());
            },
            onCloseDialog: () => {
                dispatch(toggleIsPerformanceDashboardDialogOpenAction());
            },
            navigateToPerformanceDashboard: () => {
                dispatch(navigateToPerformanceDashboardAction.trigger({ openInNewTab: true }));
            },
        };
    },
})(PerformanceDashboardDialog);
