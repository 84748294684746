import React, { useCallback, useMemo } from 'react';
import { DragStart, DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { pathOr } from 'ramda';

import {
    bufferzonePlanningCancelExistingPlanningActions,
    bufferzonePlanningCreateNewPlanningActions,
    bufferzonePlanningMoveExistingPlanningActions,
} from '../../../../../redux/intervention/bufferzones/actions';
import {
    clearActiveDraggable as clearActiveDraggableAction,
    setActiveDraggable as setActiveDraggableAction,
} from '../../../../../redux/ui/dragAndDrop/actions';
import {
    DroppablePrefix,
    DraggablePrefix,
    IDroppableFreeTimeslotData,
    ITimeSlotBase,
} from '../../../../../models/interventions/timeslots';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';
import { getCaseManager } from '../../../../../redux/contact/selectors';
import { getDate, hoursOffsetFromNow } from '../../../../../utils/core/date/getSpecificDate';
import { getExaminationReasonsAsyncInfo } from '../../../../../redux/medicalExamination/selectors';
import {
    getIdFromPrefixedDraggableId,
    getIdFromPrefixedDroppableId,
    getPrefixFromPrefixedDraggableId,
    getPrefixFromPrefixedDroppableId,
} from '../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import { getLatestPossibleTimeOnDate } from '../../../../../utils/core/date/getLatestPossibleTimeOnDate';
import { getMedExamToAddId } from '../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { IActiveDraggable } from '../../../../../models/ui/dragAndDrop';
import { ICalendarEvent } from '../../../../../models/ui/calendar';
import {
    IMedicalExaminationToAdd,
    IPlannedTimeSlot,
} from '../../../../../models/interventions/medicalExaminations';
import { IReservedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/reserved';
import { isDraggingDraggableWithPrefix } from '../../../../../redux/ui/dragAndDrop/selectors';
import { isOnSameDay } from '../../../../../utils/core/date/isOnSameDay';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../config/medicalExamination.config';
import getOverlappingEventsCount from '../../../../../utils/calendar/getOverlappingEventsCount';
import * as BUFFERZONE_SELECTORS from '../../../../../redux/intervention/bufferzones/selectors';
import { connect } from '../../../../index';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Calendar from '../../../../common/widget/Calendar';
import DroppableWrapper from '../../../../common/widget/dragAndDrop/DroppableWrapper';
import FormError from '../../../../common/forms/FormError';
import Icon from '../../../../common/icons/Icon';
import Legend from '../../../../common/widget/Legend';
import Loader from '../../../../common/waiting/Loader';
import PageHeader from '../../../../appShell/PageHeader';
import StickyFooter from '../../../../common/widget/StickyFooter';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Translate from '../../../../common/Translate';

import { CLASS_NAME, TRANSLATION_PREFIX } from './PlanBufferzone.const';
import {
    isEmployeeDroppedIntoFreeTimeslot,
    isPlannedExaminationDroppedIntoFreeTimeslot,
    isPlannedExaminationDroppedIntoEmployees,
    mapExaminationReasonsToLegendData,
} from './PlanBufferzone.helper';
import {
    IPlanBufferzoneProps,
    IPlanBufferzonePrivateProps,
} from './PlanBufferzone.type';
import BufferzoneInfo from './BufferzoneInfo';
import EmployeeBlock from './EmployeeBlock';

import './PlanBufferzone.scss';

const PlanBufferzoneComponent = ({
    asyncInfo,
    bufferzoneStartsBeforePlanningDeadline,
    caseManager,
    cancelPlannedExamination,
    clearActiveDraggable,
    employeesToPlan,
    events,
    examinationReasons,
    freeSlotsAmount,
    isDraggingPlannedExam,
    movePlannedExamination,
    planEmployee,
    totalSlotsAmount,
    renderStepButtons,
    selectedBufferzone,
    setActiveDraggable,
}: IPlanBufferzoneProps) => {
    const selectedDate = useMemo(
        () => formatDateForBackend(selectedBufferzone.activity.start),
        [selectedBufferzone],
    );

    const bufferzoneWithShortTimeslots = pathOr<number>(
        0,
        ['activity', 'timeSlotDuration'],
        selectedBufferzone,
    ) === 5;

    const renderEmployeesColumn = useCallback(
        () => {
            return (
                employeesToPlan.map((employeeToPlan, index) => (
                    <EmployeeBlock
                        key={getMedExamToAddId(employeeToPlan)}
                        employee={employeeToPlan}
                        index={index}
                    />
                ))
            );
        },
        [employeesToPlan],
    );

    const onDragStartHandler = (initial: DragStart, provided: ResponderProvided) => {
        const employeeToPlan = employeesToPlan.find((employee) =>
            getMedExamToAddId(employee) === getIdFromPrefixedDraggableId(initial.draggableId));

        const event = events.find((event) => event.id === getIdFromPrefixedDraggableId(initial.draggableId));

        setActiveDraggable({
            draggableId: initial.draggableId,
            data: employeeToPlan || (event && event.data),
        });
    };

    const onDragEndHandler = (result: DropResult, provided: ResponderProvided) => {
        clearActiveDraggable();

        if (!result.destination) {
            return;
        }

        const draggablePrefix = getPrefixFromPrefixedDraggableId(result.draggableId);
        const destinationDroppablePrefix = getPrefixFromPrefixedDroppableId(result.destination.droppableId);

        if (isEmployeeDroppedIntoFreeTimeslot(draggablePrefix, destinationDroppablePrefix)) {
            const employeeToPlan = employeesToPlan.find(
                (employee) => getMedExamToAddId(employee) === getIdFromPrefixedDraggableId(result.draggableId),
            );
            const event = events.find(
                (event) => event.id === getIdFromPrefixedDroppableId(result.destination.droppableId),
            ) as ICalendarEvent<IDroppableFreeTimeslotData>;

            if (event && employeeToPlan && (event.data.timeCell.isPlannable)) {
                planEmployee(employeeToPlan, event.data.timeCell);
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to plan an employee, but the employee or timeslot event was not found, make sure all draggable and droppable ids are correct.');
            }
        } else if (isPlannedExaminationDroppedIntoFreeTimeslot(draggablePrefix, destinationDroppablePrefix)) {
            const plannedExaminationEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDraggableId(result.draggableId)) as ICalendarEvent<IPlannedTimeSlot>;
            const freeTimeslotEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDroppableId(result.destination.droppableId)) as ICalendarEvent<IDroppableFreeTimeslotData>;

            if (plannedExaminationEvent && freeTimeslotEvent) {
                movePlannedExamination(
                    plannedExaminationEvent.data,
                    freeTimeslotEvent.data.timeCell,
                    selectedBufferzone,
                );
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to move a planning, but the planning or destination timeslot event was not found, make sure all draggable and droppable ids are correct.');
            }
        } else if (isPlannedExaminationDroppedIntoEmployees(draggablePrefix, destinationDroppablePrefix)) {
            const plannedExaminationEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDraggableId(result.draggableId)) as ICalendarEvent<IPlannedTimeSlot>;
            const { activity } = selectedBufferzone;

            if (plannedExaminationEvent && activity.id) {
                cancelPlannedExamination(plannedExaminationEvent.data, activity.id);
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to cancel a planning, but the planning event was not found, make sure all draggable and droppable ids are correct.');
            }
        }
    };

    const renderCaseManagerLink = () => (
        caseManager ? (
            <a href={`mailto:${caseManager && caseManager.email}`}>
                <Translate msg={`${TRANSLATION_PREFIX}.warning.case_manager_link`} />
            </a>
        ) : <Translate msg={`${TRANSLATION_PREFIX}.warning.case_manager_link`} />
    );

    const calendarEvents = events.map((event) => ({
        ...event,
        overlappingEventsCount: getOverlappingEventsCount(event, events),
    }));

    // The following two const hold the logic for whether there is an unplannable timeslot
    // and are used to show the legend item or not.
    const reservedTimeSlots = selectedBufferzone.reservedTimeSlots.map((event) => event);

    const unplannableTimeSlotExists =
        (reservedTimeSlots.find((timeSlot) => timeSlot.isPlannable === false));

    return (
        <DragDropContext
            onDragStart={onDragStartHandler}
            onDragEnd={onDragEndHandler}
        >
            <PageHeader
                title={`${TRANSLATION_PREFIX}.title`}
                text={`${TRANSLATION_PREFIX}.text`}
            />
            <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                <Loader show={asyncInfo.status} />
                {asyncInfo.error && <FormError className={`${CLASS_NAME}__error`} error={asyncInfo.error} />}
                {bufferzoneStartsBeforePlanningDeadline && (
                    <div className={`${CLASS_NAME}__warning`}>
                        <Icon typeName="warning" circle colorType="warning" />
                        <div>
                            <p>
                                <Translate
                                    msg={`${TRANSLATION_PREFIX}.warning.text`}
                                    placeholders={{
                                        hours: NR_OF_HOURS_BEFORE_EXAM_ALLOWED,
                                    }}
                                />
                            </p>
                            <p>
                                <Translate
                                    msg={`${TRANSLATION_PREFIX}.warning.contact_text`}
                                    placeholders={{
                                        caseManager: renderCaseManagerLink(),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                )}
                <div className={`${CLASS_NAME}__wrapper`}>
                    <div className="planning-wrapper">
                        <div className="info-calendar-wrapper">
                            <h5>
                                <Translate msg={`${TRANSLATION_PREFIX}.columns.info`} />
                            </h5>
                            <div className="info-calendar">
                                <div className="info-wrapper">
                                    <div className="scroller">
                                        <BufferzoneInfo
                                            bufferzone={selectedBufferzone}
                                            freeSlots={freeSlotsAmount}
                                            totalSlots={totalSlotsAmount}
                                        />
                                    </div>
                                </div>
                                <div className="calendar-wrapper">
                                    <div className="scroll-wrapper">
                                        <div className="scroller">
                                            <Calendar
                                                events={calendarEvents}
                                                selectable={false}
                                                selectedEvent={null}
                                                selectedDate={selectedDate}
                                                onNavigate={() => null}
                                                onSelectEvent={() => null}
                                                hideTodayButton={true}
                                                hideViewToggle={true}
                                                hideCurrentTimeIndicator={true}
                                                hideToolbar={true}
                                                view="day"
                                                inlineDatePicker={false}
                                                customDayStyles={[]}
                                                rowHeight={30} // 30px for each 15min
                                                minTime={selectedBufferzone.activity.start}
                                                maxTime={
                                                    // eslint-disable-next-line max-len
                                                    isOnSameDay(selectedBufferzone.activity.start, selectedBufferzone.activity.end) ?
                                                        selectedBufferzone.activity.end :
                                                        getLatestPossibleTimeOnDate(
                                                            selectedBufferzone.activity.start,
                                                        )
                                                }
                                                step={bufferzoneWithShortTimeslots ? 5 : undefined}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="employees-wrapper">
                            <h5>
                                <Translate msg={`${TRANSLATION_PREFIX}.columns.employees_to_plan`} />
                            </h5>
                            <div className="scroll-wrapper">
                                <div className="scroller">
                                    <DroppableWrapper
                                        className={classNames(`${CLASS_NAME}__employees-droppable`, {
                                            ['show-droppable']: isDraggingPlannedExam,
                                        })}
                                        droppableId={DroppablePrefix.Employees}
                                        render={renderEmployeesColumn}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="legend-wrapper">
                        <h5><Translate msg={`${TRANSLATION_PREFIX}.columns.legend.title`} /></h5>
                        <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                            <Legend
                                className={`${CLASS_NAME}__legend`}
                                data={mapExaminationReasonsToLegendData(
                                    examinationReasons,
                                    bufferzoneStartsBeforePlanningDeadline,
                                    !!unplannableTimeSlotExists,
                                )}
                                small
                            />
                        </TinyLoader>
                    </div>
                </div>
                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                    {renderStepButtons({
                        nextButton: {
                            translationKey: null,
                        },
                    })}
                </StickyFooter>
            </div>
        </DragDropContext>
    );
};

export const PlanBufferzone = connect<IPlanBufferzonePrivateProps>({
    stateProps: (state) => {
        const {
            getBufferzoneFreeTimeslots,
            getBufferzonePlanningStepAsyncInfo,
            getBufferzoneTotalTimeslots,
            getEmployeesToPlanSortedMemoizedSelector,
            getMergedCalendarEventsMemoizedSelector,
            getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized,
            getPlanBufferzoneWizardEntity,
        } = BUFFERZONE_SELECTORS;

        const entity = getPlanBufferzoneWizardEntity(state);

        return {
            selectedBufferzone: entity.selectedBufferzone,
            employeesToPlan: getEmployeesToPlanSortedMemoizedSelector(state),
            examinationReasons: getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized(state),
            events: getMergedCalendarEventsMemoizedSelector(state),
            asyncInfo: getBufferzonePlanningStepAsyncInfo(state),
            freeSlotsAmount: getBufferzoneFreeTimeslots(state).length || 0,
            totalSlotsAmount: getBufferzoneTotalTimeslots(state) || 0,
            isDraggingPlannedExam: isDraggingDraggableWithPrefix(state, DraggablePrefix.PlannedExamination),
            bufferzoneStartsBeforePlanningDeadline:
                hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
                getDate(entity.selectedBufferzone.activity.start),
            caseManager: getCaseManager(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            planEmployee: (employeeToPlan: IMedicalExaminationToAdd, timeslot: ITimeSlotBase) => {
                dispatch(bufferzonePlanningCreateNewPlanningActions.trigger({
                    employeeToPlan,
                    timeslot,
                }));
            },
            movePlannedExamination: (
                examination: IPlannedTimeSlot,
                newTimeslot: ITimeSlotBase,
                selectedBufferzone: IReservedMedicalExamination,
            ) => {
                dispatch(bufferzonePlanningMoveExistingPlanningActions.trigger({
                    examination,
                    newTimeslot,
                    selectedBufferzone,
                }));
            },
            cancelPlannedExamination: (examination: IPlannedTimeSlot, activityId: number) => {
                dispatch(bufferzonePlanningCancelExistingPlanningActions.trigger({
                    examination,
                    activityId,
                }));
            },
            setActiveDraggable: (activeDraggable: IActiveDraggable) => {
                dispatch(setActiveDraggableAction(activeDraggable));
            },
            clearActiveDraggable: () => {
                dispatch(clearActiveDraggableAction());
            },
        };
    },
})(PlanBufferzoneComponent);
