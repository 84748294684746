import React, { PureComponent } from 'react';
import { connect } from '../../../../index';
import ROUTE_KEYS from '../../../../../routeKeys';
import { IState } from '../../../../../redux';
import { getRouteKey, getRoutePayload } from '../../../../../redux/location/selectors';
import DetailPanel from './DetailPanel';
import MultiLevelDetailPanel, { getDetailsConfigForRoute } from './MultiLevelDetailPanel';
import { TDetailConfig, ILevelDetails, IDetailConfig, ILevelDetailConfig, IMultiLevelDetailConfig } from '../typings';
import { getFieldAscendingComparator } from '../../../../../utils/core/object/sortObjects';

export const DEFAULT_DETAIL_ID_ROUTE_PARAM_NAME = 'id';

interface IPublicProps {
    detailConfig?: TDetailConfig;
    closeDetail: () => void;
}

interface IPrivateProps {
    isOnDetailRoute: boolean;
    getDetailId: (selector: (state: IState) => string | number) => string | number;
}

class Detail extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPrivateProps & IPublicProps) {
        super(props);

        this.onDetailPanelCloseIntent = this.onDetailPanelCloseIntent.bind(this);
    }

    public render() {
        const { detailConfig, isOnDetailRoute, getDetailId } = this.props;

        if (!detailConfig) {
            return null;
        }

        if (isMultiLevelDetailConfig(detailConfig)) {
            let lastLevelVisible = 1;
            const sortedDetails = detailConfig.levels
                .sort(getFieldAscendingComparator<ILevelDetailConfig>('level'));
            let parentLevelRoutePayload = {};
            const levelsConfig: { [level: number]: ILevelDetails[] } = sortedDetails.reduce(
                (accumulator, levelConfig) => {
                    const levelDetails = levelConfig.details.map((config): ILevelDetails => {
                        const idSelector = config.idSelector ||
                            ((state) => defaultIdSelector(state, config.idRouteParamName));

                        // Check if the detail id can be retrieved from the route payload
                        // If not it is not active and should not be visible
                        const detailId = getDetailId(idSelector);
                        if (detailId) {
                            lastLevelVisible = levelConfig.level;
                        }

                        const routePayload = parentLevelRoutePayload = {
                            ...parentLevelRoutePayload,
                            [config.idRouteParamName || DEFAULT_DETAIL_ID_ROUTE_PARAM_NAME]: detailId,
                        };

                        return {
                            detailAsyncInfoSelector: config.asyncInfoSelector,
                            detailIdSelector: idSelector,
                            detailDataSelector: config.dataSelector,
                            routeKey: config.routeKey,
                            routePayload,
                            updateInfo: config.updateInfo && {
                                successTranslationKey: config.updateInfo.successTranslationKey,
                                updateAsyncInfoResetAction: config.updateInfo.updateAsyncInfoResetAction,
                                updateAsyncInfoSelector: config.updateInfo.updateAsyncInfoSelector,
                            },
                            renderHeader: config.renderHeader,
                            renderContent: config.renderContent,
                            renderFooter: config.renderFooter,
                            renderOverlay: config.renderOverlay,
                            isForm: config.isForm,
                        };
                    });
                    accumulator[levelConfig.level] = levelDetails;
                    return accumulator;
                },
                {} as { [level: number]: ILevelDetails[] },
            );

            return (
                <MultiLevelDetailPanel
                    lastLevelVisible={lastLevelVisible}
                    isOpen={isOnDetailRoute}
                    onCloseIntent={this.onDetailPanelCloseIntent}
                    levelsConfig={levelsConfig}
                />
            );
        }

        const idSelector = detailConfig.idSelector ||
            ((state) => defaultIdSelector(state, detailConfig.idRouteParamName));

        return (
            <DetailPanel
                isOpen={isOnDetailRoute}
                onCloseIntent={this.onDetailPanelCloseIntent}
                detailAsyncInfoSelector={detailConfig.asyncInfoSelector}
                detailUpdateInfo={detailConfig.updateInfo}
                detailIdSelector={idSelector}
                detailDataSelector={detailConfig.dataSelector}
                renderHeader={detailConfig.renderHeader}
                renderContent={detailConfig.renderContent}
                renderOverlay={detailConfig.renderOverlay}
                renderFooter={detailConfig.renderFooter}
                onSwitchSelectedDetail={detailConfig.onSwitchSelectedDetail}
                doNotRenderInSlideOutPanel={detailConfig.doNotRenderInSlideOutPanel}
                slideOutPanelClassName={detailConfig.slideOutPanelClassName}
                isForm={detailConfig.isForm}
            />
        );
    }

    private onDetailPanelCloseIntent(isClickedOutside: boolean) {
        if (!isClickedOutside) {
            this.props.closeDetail();
        }
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const currentRouteKey = getRouteKey(state);

            // levelIdConcatenation is here to trigger a re-render when the detail url changes
            // (otherwise 2nd level of MultiLevelDetailPanel not rendered when selected)
            const levelIdConcatenation = isMultiLevelDetailConfig(publicProps.detailConfig)
                ? publicProps.detailConfig.levels
                    .map((levelConfig) => {
                        const levelDetailsConfig = getDetailsConfigForRoute<IDetailConfig>(
                            levelConfig.details, currentRouteKey,
                        );
                        const idSelector = levelDetailsConfig.idSelector ||
                            ((state) => defaultIdSelector(state, levelDetailsConfig.idRouteParamName));
                        return idSelector(state);
                    })
                    .join('-')
                : '';

            return {
                isOnDetailRoute: isOnDetailRoute(publicProps.detailConfig, currentRouteKey),
                levelIdConcatenation,
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            getDetailId: (idSelector) => {
                const state = getState();
                return idSelector(state);
            },
        };
    },
})(Detail);

export function isOnDetailRoute(detailConfig: TDetailConfig, currentRouteKey: ROUTE_KEYS) {
    if (!detailConfig) {
        return false;
    }

    const detailRouteKeys = isMultiLevelDetailConfig(detailConfig)
        ? detailConfig.levels.map((item) => {
            const levelDetailsConfig = getDetailsConfigForRoute<IDetailConfig>(item.details, currentRouteKey);
            return levelDetailsConfig.routeKey;
        })
        : [detailConfig.routeKey];

    return detailRouteKeys.includes(currentRouteKey);
}

export function getDetailIdRouteParamName({
    detailConfig,
    level,
    currentRouteKey,
}: {
    detailConfig: TDetailConfig,
    level: number,
    currentRouteKey: ROUTE_KEYS,
}): string {
    if (!detailConfig) {
        return DEFAULT_DETAIL_ID_ROUTE_PARAM_NAME;
    }

    return (
        isMultiLevelDetailConfig(detailConfig)
            ? getDetailsConfigForRoute<IDetailConfig>(
                detailConfig.levels.find((item) => item.level === level).details,
                currentRouteKey,
            ).idRouteParamName
            : detailConfig.idRouteParamName
    ) || DEFAULT_DETAIL_ID_ROUTE_PARAM_NAME;
}

export function getFirstLevelRouteKey(
    detailConfig: TDetailConfig,
    currentRouteKey: ROUTE_KEYS,
) {
    if (!detailConfig) {
        return null;
    }

    return isMultiLevelDetailConfig(detailConfig)
        ? getDetailsConfigForRoute<IDetailConfig>(
            detailConfig.levels.find((item) => item.level === 1).details,
            currentRouteKey,
        ).routeKey
        : detailConfig.routeKey;
}

function defaultIdSelector(state: IState, idRouteParamName: string) {
    return getRoutePayload(state)[idRouteParamName || 'id'];
}

function isMultiLevelDetailConfig(config: TDetailConfig): config is IMultiLevelDetailConfig {
    const detailConfig = config as IMultiLevelDetailConfig;
    return (
        detailConfig &&
        Array.isArray(detailConfig.levels)
    );
}
