import { ICalendarEvent } from '../../models/ui/calendar';
import { dateRangeOverlaps } from '../core/date/dateRangeOverlaps';

export default function getOverlappingEventsCount(event: ICalendarEvent, allEvents: ICalendarEvent[]) {
    if (event.allDay) {
        return 0;
    }

    return allEvents.filter((item) => {
        if (item.id === event.id || item.allDay) {
            return false;
        }
        return dateRangeOverlaps(
            {
                start: item.start,
                end: item.end,
            },
            {
                start: event.start,
                end: event.end,
            },
            true,
        );
    }).length;
}
