import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    SELECT_COMPANY,
    SELECT_COMPANY_SEAT,
    FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS,
    RESET_COMPANY_SELECTED_REDUCER_TO_INITIAL_STATE,
    SET_COMPANY_ONBOARDING_VARIABLES,
    SET_INTERIM_FOR_COMPANY,
} from './types';
import {
    createActionHandlersForType,
    createActionHandler,
    registerReducer,
    createAsyncFetchActionHandlers,
    getAsyncFetchInitialState,
    IAsyncFetchField,
} from '../../index';
import {
    ICompanySeat,
    ICompanyDismissableWithDate,
    ISelectCompanySeatPayload,
    ISetCompanyOnboardingVariablesPayload,
    ICompanyInterim,
} from '../../../models/admin/company';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { ILogoutPayload } from '../../../models/auth/authentication';

export const reducerKey = REDUCER_KEYS.COMPANY_SELECTED;

export interface IReducerState {
    selectedCompany: ICompanyDismissableWithDate;
    // used to check the radiobutton of the previous selected company when changing company
    prevSelectedCompany: ICompanyDismissableWithDate;
    selectedCompanySeat: ICompanySeat;
    isAllSeatsSelected: boolean;
    selectedCompanySeatsAndDivisions: IAsyncFetchField<ICompanySeat[]>;
    isInterimCompany: boolean;
}

const initialState: IReducerState = {
    selectedCompany: null,
    prevSelectedCompany: null,
    selectedCompanySeat: null,
    isAllSeatsSelected: false,
    selectedCompanySeatsAndDivisions: getAsyncFetchInitialState(),
    isInterimCompany: false,
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(SELECT_COMPANY)
        .onTrigger<ICompanyDismissableWithDate>(({ oldState, payload }) => {
            const selectedCompanyDidNotChange =
                oldState.selectedCompany && oldState.selectedCompany.companyCode === payload.companyCode;
            return {
                ...oldState,
                selectedCompany: payload,
                // Reset the selected seat when the selected company changed
                selectedCompanySeat: selectedCompanyDidNotChange ?
                    oldState.selectedCompanySeat : initialState.selectedCompanySeat,
                isAllSeatsSelected: selectedCompanyDidNotChange ?
                    oldState.isAllSeatsSelected : initialState.isAllSeatsSelected,
                selectedCompanySeatsAndDivisions: selectedCompanyDidNotChange ?
                    oldState.selectedCompanySeatsAndDivisions : initialState.selectedCompanySeatsAndDivisions,
            };
        })
        .create(),

    ...createActionHandlersForType<IReducerState>(SELECT_COMPANY_SEAT)
        .onTrigger<ISelectCompanySeatPayload>(({ oldState, payload }) => {
            return {
                ...oldState,
                selectedCompanySeat: payload.companySeat,
                isAllSeatsSelected: payload.isAllSeatsSelected,
            };
        })
        .create(),

    ...createAsyncFetchActionHandlers<ICompanySeat[], IReducerState, ICompanySeat[]>({
        baseActionType: FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS,
        fieldName: 'selectedCompanySeatsAndDivisions',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createActionHandlersForType<IReducerState>(SET_INTERIM_FOR_COMPANY)
        .onTrigger<ICompanyInterim>(({ oldState, payload }) => {
            return {
                ...oldState,
                isInterimCompany: payload.interim,
            };
        })
        .create(),

    [ROUTE_KEYS.R_COMPANY_SELECTION]: createActionHandler<IReducerState, object>(
        ({ oldState }) => {
            return {
                ...oldState,
                selectedCompany: initialState.selectedCompany,
                prevSelectedCompany: oldState.selectedCompany,
                selectedCompanySeat: initialState.selectedCompanySeat,
                isAllSeatsSelected: initialState.isAllSeatsSelected,
                selectedCompanySeatsAndDivisions: initialState.selectedCompanySeatsAndDivisions,
            };
        },
    ),

    [ROUTE_KEYS.R_LOGOUT]: createActionHandler<IReducerState, ILogoutPayload>(
        ({ oldState, payload }) => {
            if (!payload.isSessionExpired) {
                return {
                    ...initialState,
                };
            }

            return oldState;
        },
    ),

    [RESET_COMPANY_SELECTED_REDUCER_TO_INITIAL_STATE]: createActionHandler<IReducerState, object>(
        () => {
            return {
                ...initialState,
            };
        },
    ),
    [SET_COMPANY_ONBOARDING_VARIABLES]: createActionHandler<IReducerState, ISetCompanyOnboardingVariablesPayload>(
        ({ oldState, payload }) => {
            const { companyCode, ...onboardingVariables } = payload;

            if (companyCode !== oldState.selectedCompany.companyCode) {
                return oldState;
            }

            return {
                ...oldState,
                selectedCompany: {
                    ...oldState.selectedCompany,
                    ...onboardingVariables,
                },
            };
        },
    ),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.LOCAL,
    transformReducerStateForStorage: (reducerState) => {
        return {
            selectedCompany: reducerState.selectedCompany,
            selectedCompanySeat: reducerState.selectedCompanySeat,
            isAllSeatsSelected: reducerState.isAllSeatsSelected,
        };
    },
    resetStateOnCompanySelection: false,
    resetStateOnSeatSelection: false,
    resetStateOnLogout: false,
    resetStateOnLocaleSwitch: false,
});
