import React, { ReactElement, Fragment } from 'react';
import TranslatorContext from '../../appShell/contexts/TranslatorContext';
import { connect } from '../..';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { ITranslator } from '../../../models/general/i18n';

const PLACEHOLDER_REGEX = /({.*?})/g;

export type TPlaceholders = { [key: string]: string | React.ReactNode };

export interface IPublicProps {
    msg: string;
    placeholders?: TPlaceholders;
    raw?: boolean;
    ignoreContext?: boolean;
}

interface IPrivateProps {
    translatorFromState: ITranslator;
}

function Translate({
    msg,
    placeholders = {},
    raw = false,
    ignoreContext,
    translatorFromState,
}: IPublicProps & IPrivateProps) {
    const renderedPlaceholders = renderPlaceholders(placeholders);
    // Typings don't support yet returning a string from a stateless component
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
    return (
        <TranslatorContext.Consumer>
            {({ translator: translatorFromContext }) => {
                const translator = ignoreContext ? translatorFromState : translatorFromContext;
                if (raw) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: translator({ msg, placeholders: renderedPlaceholders }),
                            }}
                        />
                    );
                }
                return (
                    injectReactPlaceholders(
                        translator(
                            { msg, placeholders: renderedPlaceholders },
                        ),
                        placeholders,
                    ) as ReactElement<{}>
                );
            }}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translatorFromState: getTranslatorDeprecated(state),
        };
    },
})(Translate);

function renderPlaceholders(placeholders: TPlaceholders): { [key: string]: string } {
    const renderedPlaceholders = {};
    Object.keys(placeholders).forEach((key) => {
        const value = placeholders[key];
        if (typeof value === 'string') {
            renderedPlaceholders[key] = value;
        }
    });
    return renderedPlaceholders;
}

function injectReactPlaceholders(message: string, placeholders: TPlaceholders) {
    if (!message) {
        return null;
    }

    const reactPlaceholders = Object.keys(placeholders)
        .filter((placeholder) => !React.isValidElement(placeholder));

    const messageSplitted = message.split(PLACEHOLDER_REGEX);

    if (reactPlaceholders.length > 0 && Array.isArray(messageSplitted)) {
        return (
            <>
                {messageSplitted.map((messagePart, index) => {
                    const placeholderReplacement = (PLACEHOLDER_REGEX.test(messagePart)) ?
                        getPlaceholderReplacement(messagePart, placeholders) :
                        null;
                    const keySuffix = placeholderReplacement ? placeholderReplacement.name : messagePart;

                    return (
                        <Fragment key={`translate-message-part-${index}-${keySuffix}`}>
                            {placeholderReplacement ? placeholderReplacement.value : messagePart}
                        </Fragment>
                    );
                })}
            </>
        );
    }
    return message;
}

function getPlaceholderReplacement(messagePart: string, placeholders: TPlaceholders) {
    const placeholderName = messagePart.substring(1, messagePart.length - 1);
    return {
        name: placeholderName,
        value: placeholders[placeholderName],
    };
}
