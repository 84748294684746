import React, { PureComponent } from 'react';
import { clone } from 'ramda';
import './activity-report.scss';
import classNames from 'classnames';
import { connect } from '../..';
import GreenDocumentCenterPage from '../shared/GreenDocumentCenterPage';
import Form, { IFormRenderProps } from '../../common/forms/Form';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import { schema, fields } from './activityReportSchema';
import FormFieldError from '../../common/forms/FormFieldError';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { ITranslator } from '../../../models/general/i18n';
import SubmitButton from '../../common/buttons/SubmitButton';
import Translate from '../../common/Translate';
import YearDropdown from '../../common/input/Dropdown/YearDropdown';
import { ListColumns, ListItem, SortType, SortOrder, ISortedColumn } from '../../../models/general/list';
import {
    fetchActivityReportsActions,
    generateActivityReportActions,
} from '../../../redux/company/documents/actions';
import {
    IActivityReport,
    IFetchActivityReportsPayload,
    IGenerateActivityReportPayload,
} from '../../../models/general/documents';
import { IAsyncFieldInfo, AsyncStatus } from '../../../models/general/redux';
import {
    getActivityReports,
    getActivityReportsAsyncInfo,
    getGenerateActivityReportsAsyncInfo,
    getActivityReportDetailAsyncInfo,
} from '../../../redux/company/documents/selectors';
import Loader from '../../common/waiting/Loader';
import { formatDateForDisplay } from '../../../utils/formatting/formatDate';
import { trimFileExtension } from '../../../utils/formatting/formatFile';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import ListWithSorting from '../../common/list/ListWithSorting';
import { getCurrentYear } from '../../../utils/core/date/getSpecificDate';
import { isAllSeatsSelected } from '../../../redux/company/selected/selectors';
import Checkbox from '../../common/input/Checkbox';
import SuccessDialog from '../../common/modals/SuccessDialog';
import FormError from '../../common/forms/FormError';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import { getDocumentAsyncInfo } from '../../../redux/employee/documents/selectors';
import LocaleDropdown from '../../common/input/Dropdown/LocaleDropdown';
import ActivityReportActions from './ActivityReportActions';
import NoDocumentsFoundErrorDialog from '../../common/modals/NoDocumentsFoundErrorDialog';

const FORM_NAME = 'activity-report-form';
const LIST_NAME = 'activity-report-list';
const CLASS_NAME = 'ActivityReport';

const YEAR_SELECTION = {
    /* previous year (as the activity report is not possible for the current one) */
    MAX: getCurrentYear() - 1,
    MIN_EXPORT_HISTORY: 2011,
};

interface IFormValues {
    locale: string;
    individualSeats: boolean;
}

const ACTIVITY_REPORT_INITIAL_VALUES: IFormValues = {
    locale: null,
    individualSeats: false,
};

interface IPrivateProps {
    translator: ITranslator;
    fetchActivityReports: (year: string) => void;
    generateActivityReport: (values: IFormValues) => void;
    activityReports: IActivityReport[];
    fetchActivityReportsAsyncInfo: IAsyncFieldInfo;
    allSeatsSelected: boolean;
    generateActivityReportAsyncInfo: IAsyncFieldInfo;
    fetchDocumentAsyncInfo: IAsyncFieldInfo;
    fetchActivityReportDetailAsyncInfo: IAsyncFieldInfo;
}

export interface IActivityReportColumnNames {
    date: string;
    dateSort: string;
    name: string;
    actions: string;
}

const COLUMNS: ListColumns<IActivityReportColumnNames> = {
    date: {
        percentWidth: 23,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IActivityReportColumnNames>) => listItem.columns.dateSort,
        label: <Translate msg="document_center.activity_report.columns.date" />,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    name: {
        percentWidth: 54,
    },
    actions: {
        percentWidth: 23,
    },
};

const INITIAL_SORT: ISortedColumn<IActivityReportColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Descending,
};

interface IState {
    selectedOverviewYear: string;
    isSuccessDialogOpen: boolean;
}

class ActivityReport extends PureComponent<IPrivateProps, IState>{
    private columns: ListColumns<IActivityReportColumnNames> = clone(COLUMNS);
    private resetForm: (newValues: object) => void;

    constructor(props) {
        super(props);

        this.state = {
            selectedOverviewYear: '',
            isSuccessDialogOpen: false,
        };

        this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onOverviewYearSelected = this.onOverviewYearSelected.bind(this);
        this.mapActivityReportsToListItems = this.mapActivityReportsToListItems.bind(this);
        this.generateActivityReportSucceeded = this.generateActivityReportSucceeded.bind(this);
        this.refreshActivityReport = this.refreshActivityReport.bind(this);

        this.columns.actions.render = (listItem: ListItem<IActivityReportColumnNames>) => {
            return (
                <ActivityReportActions
                    listItem={listItem}
                    year={this.state.selectedOverviewYear}
                />
            );
        };
    }

    public componentDidMount() {
        this.onOverviewYearSelected(YEAR_SELECTION.MAX.toString());
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (this.generateActivityReportSucceeded(prevProps)) {
            this.setState({ isSuccessDialogOpen: true });
            /* Ben: I disable this reset because otherwise - after generation success - you don't see the values
                    anymore for which you just did the request */
            // this.resetForm(ACTIVITY_REPORT_INITIAL_VALUES);
        }
    }

    public render() {
        const {
            translator, fetchActivityReportsAsyncInfo, allSeatsSelected, generateActivityReportAsyncInfo,
            fetchDocumentAsyncInfo, fetchActivityReportDetailAsyncInfo,
        } = this.props;
        const { selectedOverviewYear, isSuccessDialogOpen } = this.state;

        const listItems = this.mapActivityReportsToListItems();

        const showLoaderForList = fetchActivityReportsAsyncInfo.status === AsyncStatus.Busy ||
            fetchDocumentAsyncInfo.status === AsyncStatus.Busy ||
            fetchActivityReportDetailAsyncInfo.status === AsyncStatus.Busy;

        const submitClasses = classNames(`${CLASS_NAME}__submit`, {
            ['after-checkbox']: allSeatsSelected,
        });

        return (
            <GreenDocumentCenterPage
                titleTranslationKey="document_center.activity_report.title_with_year"
                introTranslationKey="document_center.activity_report.text_with_year"
                titlePlaceholders={{ year: YEAR_SELECTION.MAX }}
                textPlaceholders={{ year: YEAR_SELECTION.MAX }}
                disableTextRaw={true}
            >
                <div className={classNames('DocumentCenterPageContent', CLASS_NAME)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={this.onSubmit}
                        schema={schema}
                        initialValues={ACTIVITY_REPORT_INITIAL_VALUES}
                        render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                            const {
                                values, errors, touched, setFieldValue, handleChange, resetForm,
                            } = formRenderProps;
                            if (!this.resetForm) {
                                this.resetForm = resetForm;
                            }

                            return (
                                <div className={`${CLASS_NAME}__form`}>
                                    <Loader show={generateActivityReportAsyncInfo.status}>
                                        <FloatableTextInputWrapper floatLabel>
                                            <LocaleDropdown
                                                id="activity-report-locale"
                                                value={values.locale}
                                                onItemSelected={(value) => setFieldValue('locale', value as string)}
                                            />
                                            {touched.locale &&
                                                <FormFieldError
                                                    error={errors.locale}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            'document_center.activity_report.form.locale'),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        {allSeatsSelected && (
                                            <FloatableTextInputWrapper inline>
                                                <Checkbox
                                                    name={fields.individualSeats}
                                                    toggleButton={true}
                                                    checked={values.individualSeats}
                                                    onChange={handleChange}
                                                >
                                                    <Translate
                                                        msg="document_center.activity_report.form.individual_seats"
                                                    />
                                                </Checkbox>
                                            </FloatableTextInputWrapper>
                                        )}
                                        <FormError error={generateActivityReportAsyncInfo.error} />
                                        <div className={submitClasses}>
                                            <SubmitButton
                                                id="activity-report-submit"
                                                formName={FORM_NAME}
                                                disabled={!!errors.locale}
                                            >
                                                <Translate msg="document_center.activity_report.form.submit" />
                                            </SubmitButton>
                                        </div>
                                    </Loader>
                                </div>
                            );
                        }}
                    />
                    <div className={`${CLASS_NAME}__overview`}>
                        <div className={`${CLASS_NAME}__overview_toolbar`}>
                            <h2 className={`${CLASS_NAME}__overview_header`}>
                                <Translate msg="document_center.activity_report.export_history" />
                            </h2>
                            <Button
                                id="activity-report-overview-refresh-button"
                                typeName="text"
                                className={`${CLASS_NAME}__overview_button`}
                                onClick={this.refreshActivityReport}
                            >
                                <Icon typeName="update" />
                            </Button>
                            <YearDropdown
                                id="activity-report-overview-year"
                                value={selectedOverviewYear}
                                onItemSelected={this.onOverviewYearSelected}
                                min={YEAR_SELECTION.MIN_EXPORT_HISTORY}
                                max={YEAR_SELECTION.MAX}
                                size={'small'}
                            />
                        </div>
                        <div className={`${CLASS_NAME}__overview_list`}>
                            <Loader show={showLoaderForList}>
                                {fetchActivityReportsAsyncInfo.error &&
                                    <ErrorPlaceholder apiError={fetchActivityReportsAsyncInfo.error} />
                                }
                                <ListWithSorting
                                    columns={this.columns}
                                    items={listItems}
                                    name={LIST_NAME}
                                    selectedItemIds={[]}
                                    initialSort={INITIAL_SORT}
                                />
                            </Loader>
                        </div>
                    </div>
                    <SuccessDialog
                        show={isSuccessDialogOpen}
                        onCloseDialog={this.onCloseSuccessDialog}
                        titleTranslationKey="document_center.activity_report.generate_report.success.title"
                        infoTranslationKey="document_center.activity_report.generate_report.success.text"
                    />
                    <NoDocumentsFoundErrorDialog
                        asyncInfoSelector={getDocumentAsyncInfo}
                    />
                </div>
            </GreenDocumentCenterPage>
        );
    }

    private onCloseSuccessDialog() {
        this.setState({ isSuccessDialogOpen: false });
    }

    private onSubmit(values: IFormValues) {
        this.props.generateActivityReport(values);
    }

    private onOverviewYearSelected(year: string) {
        const currentSelectedYear = this.state.selectedOverviewYear;
        if (year !== currentSelectedYear) {
            this.setState({ selectedOverviewYear: year });
            this.props.fetchActivityReports(year);
        }
    }

    private refreshActivityReport() {
        this.props.fetchActivityReports(this.state.selectedOverviewYear);
    }

    private mapActivityReportsToListItems() {
        const { activityReports } = this.props;

        return activityReports.map((activityReport: IActivityReport) => {
            return {
                id: activityReport.id,
                columns: {
                    date: formatDateForDisplay(activityReport.date),
                    dateSort: activityReport.date,
                    actions: null,
                    name: trimFileExtension(activityReport.fileName),
                },
            } as ListItem<IActivityReportColumnNames>;
        });
    }

    private generateActivityReportSucceeded(prevProps: IPrivateProps) {
        return prevProps.generateActivityReportAsyncInfo.status === AsyncStatus.Busy &&
            this.props.generateActivityReportAsyncInfo.status === AsyncStatus.Success;
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            activityReports: getActivityReports(state),
            fetchActivityReportsAsyncInfo: getActivityReportsAsyncInfo(state),
            generateActivityReportAsyncInfo: getGenerateActivityReportsAsyncInfo(state),
            allSeatsSelected: isAllSeatsSelected(state),
            fetchDocumentAsyncInfo: getDocumentAsyncInfo(state),
            fetchActivityReportDetailAsyncInfo: getActivityReportDetailAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            fetchActivityReports: (year: string) => {
                dispatch(fetchActivityReportsActions.trigger({ year } as IFetchActivityReportsPayload));
            },
            generateActivityReport: (values: IFormValues) => {
                dispatch(generateActivityReportActions.trigger({
                    locale: values.locale,
                    individualSeats: values.individualSeats,
                } as IGenerateActivityReportPayload));
            },
        };
    },
})(ActivityReport);
