import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    IResumptionOfWorkManualEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import WizardStep from '../../../../../common/navigation/WizardStep';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import { formatDateForBackend, endDateOfNWeeksAfter } from '../../../../../../utils/formatting/formatDate';
import { now } from '../../../../../../utils/core/date/getSpecificDate';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';

const CLASS_NAME = 'PlanMedicalExamination__ResumptionStartDate';

export interface IContentValues {
    startDate: string;
}

const ResumptionStartDateWizardStep = WizardStep<IContentValues>();

export default function ResumptionStartDate(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <ResumptionStartDateWizardStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.medical_examinations.new.steps.resumption_start_date.title',
                titlePlaceholders: (state) => {
                    const wizardEntity =
                        getPlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>(state);

                    return {
                        person: formatPersonName(wizardEntity.searchEmployee.selectedEmployee),
                    };
                },
            }}
            content={{
                initialValuesSelector: (state) => {
                    const wizardEntity =
                        getPlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>(state);

                    return {
                        startDate: wizardEntity.startDate || '',
                    };
                },
                renderStepContent: (renderProps) =>
                    <DatePicker
                        id="resumption-start-date"
                        onChange={(formattedDate: string) => {
                            renderProps.onChangeInput({
                                startDate: formattedDate,
                            });
                        }}
                        minDate={formatDateForBackend(now())}
                        value={renderProps.values.startDate}
                        inlineCalendar={true}
                        hideTextInput={true}
                    />,
                onChangeInputActionCreator: ({ values }) =>
                    updatePlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>({
                        startDate: values.startDate,
                        selectTime: {
                            minDate: formatDateForBackend(values.startDate),
                            // Add two weeks to current date, use end of it.
                            maxDate: endDateOfNWeeksAfter(values.startDate, 2),
                            filter: {},
                        },
                    }),
            }}
            stepButtons={{
                shouldDisableNextButton: (values) => !isDateSelected(values),
            }}
        />
    );
}

function isDateSelected(values: IContentValues) {
    return isSet(values.startDate);
}
