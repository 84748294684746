import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    IAsyncDoField,
    getAsyncFetchInitialState,
    getAsyncDoInitialState,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_COMPANY_FUNCTION_ISCO,
    FETCH_COMPANY_FUNCTION_RISKS,
    FETCH_COMPANY_FUNCTION_RESEARCHES,
    FETCH_COMPANY_FUNCTIONS,
    FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD,
    ADD_COMPANY_FUNCTION,
} from './types';
import { IRisk } from '../../../models/admin/risks';
import {
    ICompanyFunctionIsco,
    ICompanyFunction, IAddedCompanyFunction,
} from '../../../models/admin/companyFunctions';
import { IResearch } from '../../../models/admin/researches';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.COMPANY_FUNCTIONS;

export interface IReducerState {
    selectedCompanyFunctionIsco: IAsyncFetchField<ICompanyFunctionIsco[]>;
    companyFunctionRisks: IAsyncFetchField<IRisk[]>;
    companyFunctionResearches: IAsyncFetchField<IResearch[]>;
    companyFunctions: IAsyncFetchField<ICompanyFunction[]>;
    companyFunctionsForTypeAhead: IAsyncFetchField<ICompanyFunction[]>;
    addCompanyFunction: IAsyncDoField;
    addedCompanyFunction: IAddedCompanyFunction;
}

const initialState: IReducerState = {
    selectedCompanyFunctionIsco: getAsyncFetchInitialState(),
    companyFunctionRisks: getAsyncFetchInitialState(),
    companyFunctionResearches: getAsyncFetchInitialState(),
    companyFunctions: getAsyncFetchInitialState(),
    companyFunctionsForTypeAhead: getAsyncFetchInitialState(),
    addCompanyFunction: getAsyncDoInitialState(),
    addedCompanyFunction: null,
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<ICompanyFunctionIsco[], IReducerState, ICompanyFunctionIsco[]>({
        baseActionType: FETCH_COMPANY_FUNCTION_ISCO,
        fieldName: 'selectedCompanyFunctionIsco',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IRisk[], IReducerState, IRisk[]>({
        baseActionType: FETCH_COMPANY_FUNCTION_RISKS,
        fieldName: 'companyFunctionRisks',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IResearch[], IReducerState, IResearch[]>({
        baseActionType: FETCH_COMPANY_FUNCTION_RESEARCHES,
        fieldName: 'companyFunctionResearches',
        resetDataOnTrigger: true,
        reducerKey,
    }),

    ...createAsyncFetchActionHandlers<ICompanyFunction[], IReducerState, ICompanyFunction[]>({
        baseActionType: FETCH_COMPANY_FUNCTIONS,
        fieldName: 'companyFunctions',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_FUNCTIONS,
            FETCH_COMPANY_FUNCTIONS,
            ROUTE_KEYS.R_WORK_POST_CARDS,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICompanyFunction[], IReducerState, ICompanyFunction[]>({
        baseActionType: FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD,
        fieldName: 'companyFunctionsForTypeAhead',
        resetDataOnTrigger: false,
        reducerKey,
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_COMPANY_FUNCTION,
        fieldName: 'addCompanyFunction',
        transformStateOnTrigger: ({ oldState }) => {
            return {
                ...oldState,
                addedCompanyFunction: null,
            };
        },
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IAddedCompanyFunction;
            return {
                ...oldState,
                addedCompanyFunction: successPayload,
            };
        },
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
