import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { IAccomplishmentsContributionDetail } from '../../../../models/admin/preventionUnits';
import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../../models/general/list';
import {
    getAccomplishmentsContributionThatWasSelected,
    getFlatFeeSelectedYear,
    getFlatFeeSelectedType,
} from '../../../../redux/preventionUnits/selectors';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import MasterWithDetailHeader from '../../../common/widget/MasterWithDetail/Header';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import connect from '../../../../utils/libs/redux/connect';

const CLASS_NAME = 'FlatFeeDetailContent';
const TRANSLATION_PREFIX = 'administration.flat_fee.detail';
const AMOUNT_FORESEEN_NOT_KNOWN_YET = -1;

interface IColumnNamesBase {
    contributionDate: string;
    contributionDateSort: string;
    customerName: string;
}

interface IColumnNamesIfMedicalExam extends IColumnNamesBase {
    employee: string;
    reason: string;
}

interface IColumnNamesIfCompanyVisit extends IColumnNamesBase {
    reference: string; // visit code
}

const INITIAL_SORT: ISortedColumn<IColumnNamesBase> = {
    name: 'contributionDate',
    sortOrder: SortOrder.Ascending,
};

function getColumsBase(isCompanyVisits: boolean): ListColumns<IColumnNamesBase> {
    return {
        contributionDate: {
            label: <Translate msg={`${TRANSLATION_PREFIX}.columns.contribution_date`} />,
            sortable: true,
            sortType: SortType.String,
            sortValue: (listItem: ListItem<IColumnNamesBase>) => listItem.columns.contributionDateSort,
            percentWidth: isCompanyVisits ? 20 : 15,
        },
        contributionDateSort: {
            hide: true,
            sortType: SortType.String,
            percentWidth: null,
        },
        customerName: {
            label: <Translate msg={`${TRANSLATION_PREFIX}.columns.customer_name`} />,
            sortable: true,
            sortType: SortType.String,
            percentWidth: isCompanyVisits ? 50 : 30,
        },
    };
}

const COLUMNS_IF_MEDICAL_EXAM: ListColumns<IColumnNamesIfMedicalExam> = {
    ...getColumsBase(false),
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    reason: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.reason`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
};

const COLUMNS_IF_COMPANY_VISIT: ListColumns<IColumnNamesIfCompanyVisit> = {
    ...getColumsBase(true),
    reference: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.reference`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
};

interface IPrivateProps {
    amountForeseen: number;
    exportBaseFilename: string;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const selectedAccomplishmentsContributionFromList = getAccomplishmentsContributionThatWasSelected(state);
        const selectedYear = getFlatFeeSelectedYear(state);
        const selectedType = getFlatFeeSelectedType(state);

        return {
            amountForeseen: selectedAccomplishmentsContributionFromList
                ? selectedAccomplishmentsContributionFromList.amount
                : AMOUNT_FORESEEN_NOT_KNOWN_YET,
            exportBaseFilename: `services_${selectedYear}-${selectedType}`,
        };
    },
})(FlatFeeDetail);

function FlatFeeDetail(props: IPrivateProps & IRenderDetailContentProps<IAccomplishmentsContributionDetail[]>) {
    const {
        detailData: contributionDetails,
        detailAsyncInfo,
        exportBaseFilename,
    } = props;

    if (!contributionDetails) {
        return null;
    }

    const contributionDetailsListItems = mapAccomplishmentsContributionDetailsToListItems(contributionDetails);

    const isCompanyVisits = contributionDetailsListItems
        .some((listItem) => isSet((listItem as ListItem<IColumnNamesIfCompanyVisit>).columns.reference));

    return (
        <div className={CLASS_NAME}>
            <MasterWithDetailHeader
                baseName={`${CLASS_NAME}ListHeader`}
                baseClassName={`${CLASS_NAME}__list-header`}
                masterAsyncInfoSelector={() => detailAsyncInfo}
                headerConfig={{
                    renderActionContentOnTheLeft: () =>
                        <DetailCount
                            {...props}
                            contributionDetailsListItems={contributionDetailsListItems}
                        />,
                    exportButton: {
                        baseFilename: exportBaseFilename,
                    },
                }}
                isFilterPanelOpen={null}
                masterData={contributionDetailsListItems}
                onFilterSubmit={null}
                onFilterPanelCloseIntent={null}
                filterValues={null}
                untransformedMasterDataSelector={() => contributionDetails}
            />
            <ListWithSorting
                name={`${CLASS_NAME}List`}
                items={contributionDetailsListItems}
                columns={isCompanyVisits ? COLUMNS_IF_COMPANY_VISIT : COLUMNS_IF_MEDICAL_EXAM}
                selectedItemIds={[]}
                initialSort={INITIAL_SORT}
                errorMessage={detailAsyncInfo.error &&
                    <ErrorPlaceholder apiError={detailAsyncInfo.error} />}
            />
        </div>
    );
}

function mapAccomplishmentsContributionDetailsToListItems(
    contributionDetails: IAccomplishmentsContributionDetail[],
): ListItem<IColumnNamesBase>[] {
    return contributionDetails
        .map((contributionDetail) => ({
            id: contributionDetail.id,
            columns: {
                contributionDate: formatDateForDisplay(contributionDetail.date),
                contributionDateSort: contributionDetail.date,
                customerName: contributionDetail.company && contributionDetail.company.name,
                employee: contributionDetail.employee,
                reason: contributionDetail.description,
                reference: contributionDetail.reference,
            },
        }));
}

function DetailCount({
    detailAsyncInfo,
    contributionDetailsListItems,
    amountForeseen,
}: IPrivateProps & IRenderDetailContentProps<IAccomplishmentsContributionDetail[]> & {
    contributionDetailsListItems: ListItem<IColumnNamesBase>[],
}) {
    return null; // see KZUAT-566
}
