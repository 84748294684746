import React from 'react';
import connect from '../../../../utils/libs/redux/connect';
import { login } from '../../../../redux/auth/actions';
import Button from '../../../common/buttons/Button';
import { isMaintenanceBusy } from '../../../../redux/entities/selectors';
import Translate from '../../../common/Translate';
import { isSessionExpired as isSessionExpiredSelector } from '../../../../redux/auth/selectors';
import './login-actions.scss';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import ROUTE_KEYS from '../../../../routeKeys';

const CLASS_NAME = 'LoginActions';

interface IPrivateProps {
    onLogin: () => void;
    // onForgotPassword: () => void;
    isMaintenanceBusy: boolean;
    isSessionExpired: boolean;
}

function LoginActions({ onLogin, isSessionExpired, isMaintenanceBusy }: IPrivateProps) {
    return (
        <div className={CLASS_NAME}>
            {isSessionExpired ? (
                <div className="FormError">
                    <p>
                        <Translate msg="auth.login.auth0.session_expired" />
                    </p>
                </div>
            ) : (
                ''
            )}
            <Button id="login-action" onClick={onLogin} typeName="secondary" disabled={isMaintenanceBusy}>
                <Translate msg="auth.login.auth0.submit" />
            </Button>
            {/*
                Hide forgot password link because the Universal login of Auth0 has no option to link
                directly to the password forgotten screen
            */}
            {/* <a
                className={isMaintenanceBusy ? 'disabled' : ''}
                id="forgot-password-link"
                onClick={isMaintenanceBusy ? null : onForgotPassword}
            >
                <Translate msg="auth.login.auth0.forgot_pw" />
            </a> */}
            <LinkToRoute
                className={isMaintenanceBusy ? 'disabled' : ''}
                id="request-account-link"
                to={ROUTE_KEYS.R_REQUEST_ACCOUNT}
                shouldDispatch={!isMaintenanceBusy}
            >
                <Translate msg="auth.login.auth0.no_account" />
            </LinkToRoute>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        isSessionExpired: isSessionExpiredSelector(state),
        isMaintenanceBusy: isMaintenanceBusy(state),
    }),
    dispatchProps: (dispatch) => ({
        onLogin: () => {
            dispatch(login());
        },
        // onForgotPassword: () => {
        //     dispatch(forgotPasswordActions.trigger({}));
        // },
    }),
})(LoginActions);
