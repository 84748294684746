import React, { Component } from 'react';
import './read-more.scss';
import Translate from '../../Translate';
import Button from '../../buttons/Button';
import Icon from '../../icons/Icon';

interface IReadMoreProps {
    id: string;
    text: string;
    characterLimit: number;
}

interface IState {
    expanded: boolean;
}

const CLASS_NAME = 'ReadMore';

export default class ReadMore extends Component<IReadMoreProps, IState> {
    constructor(props: IReadMoreProps) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    public componentDidUpdate(prevProps: IReadMoreProps) {
        if (prevProps.text !== this.props.text && this.state.expanded) {
            this.setState({ expanded: false });
        }
    }

    public render() {
        const { id, text, characterLimit } = this.props;
        const { expanded } = this.state;

        if (text.length <= characterLimit) {
            return <p>{text}</p>;
        }

        const shortenedText = text.substr(0, characterLimit);

        return (
            <div className={CLASS_NAME}>
                <p className={`${CLASS_NAME}__text`}>{expanded ? text : `${shortenedText}...`}</p>
                <p className={`${CLASS_NAME}__read_more`}>
                    <Button
                        id={id}
                        typeName="text"
                        onClick={this.toggleExpand}
                        className={`${CLASS_NAME}__read_more__button`}
                    >
                        {expanded ? (
                            <span>
                                <Icon typeName="chevron-up" />
                                <Translate msg="common.read_more.read_less" />
                            </span>
                        ) : (
                            <span>
                                <Icon typeName="chevron-down" />
                                <Translate msg="common.read_more.read_more" />
                            </span>
                        )}
                    </Button>
                </p>
            </div>
        );
    }

    private toggleExpand() {
        this.setState({ expanded: !this.state.expanded });
    }
}
