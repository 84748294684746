import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import HomeDashboard, { IHomeDashboardProps } from './HomeDashboard';
import DismissedCompanyDashboard from './DismissedCompanyDashboard';
import ROUTE_KEYS from '../../routeKeys';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';

const BASE_DISMISSED_ROUTE = '/inactive';

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_HOME]: {
        path: '/',
        page: {
            key: 'HOME',
            component: HomeDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'home',
        breadcrumbsLabel: {
            translationKey: 'home.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.spotlightCourse,
            refreshMode: 'never',
        }, {
            entityKey: ASYNC_ENTITY_KEYS.themeOfTheMonth,
            refreshMode: 'never',
        }],
    },
    [ROUTE_KEYS.R_EDIT_TOP_TASKS]: {
        path: '/edit-top-tasks',
        page: {
            key: 'EDIT_TOP_TASKS',
            component: HomeDashboard,
        },
        pageProps: {
            showEditTopTasksDialog: true,
        } as IHomeDashboardProps,
        template: templates.WithMenuTemplate,
        virtualPage: 'edit_top_tasks',
        breadcrumbsLabel: {
            translationKey: 'home.edit_top_tasks.breadcrumbs_label',
        },
    },

    [ROUTE_KEYS.R_DISMISSED_COMPANY_DASHBOARD]: {
        path: BASE_DISMISSED_ROUTE,
        page: {
            key: 'DISMISSED_COMPANY_DASHBOARD',
            component: DismissedCompanyDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'dismissed_company_dashboard',
        breadcrumbsLabel: {
            translationKey: 'home.dismissed_company.dashboard.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
};

export default ROUTES;
