import React, { Component } from 'react';
import { connect } from '../../..';
import { IRenderDetailHeaderProps } from '../../../common/widget/MasterWithDetail/typings';
import { IPolicyAdviceComment, IPolicyAdvice } from '../../../../models/interventions/policyAdvice';
import { getPolicyAdvice, getPolicyAdviceAsyncInfo } from '../../../../redux/intervention/policyAdvice/selectors';
import { getRoutePayload } from '../../../../redux/location/selectors';
import TinyLoader from '../../../common/waiting/TinyLoader';

const CLASS_NAME = 'PolicyAdviceDetailHeader';

interface IHeaderPrivateProps {
    policyAdviceId: number;
    policyAdvice: IPolicyAdvice[];
}

class DetailHeaderComp extends Component<IHeaderPrivateProps & IRenderDetailHeaderProps<IPolicyAdviceComment[]>> {

    constructor(props: IHeaderPrivateProps & IRenderDetailHeaderProps<IPolicyAdviceComment[]>) {
        super(props);
    }

    public render() {
        const {
            policyAdviceId,
            policyAdvice,
        } = this.props;

        const policyAdviceFromList = policyAdvice.find((item) => item.id === policyAdviceId);

        return (
            <div className={CLASS_NAME}>
                <TinyLoader asyncInfoSelector={getPolicyAdviceAsyncInfo}>
                    <h1>{policyAdviceFromList && policyAdviceFromList.gPPThreat}</h1>
                </TinyLoader>
            </div>
        );
    }

}

export const DetailHeader = connect<IHeaderPrivateProps, IRenderDetailHeaderProps<IPolicyAdviceComment[]>>({
    stateProps: (state) => {
        const policyAdviceId = Number(getRoutePayload<{ id: string }>(state).id);

        return {
            policyAdviceId,
            policyAdvice: getPolicyAdvice(state),
        };
    },
})(DetailHeaderComp);
