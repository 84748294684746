import React, { PureComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import './file-upload-dropzone.scss';
import Dropzone from 'react-dropzone';
import Translate from '../../Translate';
import UploadedFile from '../UploadedFile';
import fileCloudIcon from '../../../assets/img/icons/file-cloud.svg';
import Dialog from '../../modals/Dialog';
import Button from '../../buttons/Button/index';
import InlineSVG from '../../icons/InlineSVG';

interface IFileUploadDropzoneProps {
    acceptedMimeTypes?: string;
}

interface IPublicProps {
    onFileDrop: (files: File[]) => void;
    onFileDelete: (files: File[]) => void;
    // can be used for example when you go back a step in a wizard
    previousDroppedFiles?: File[];
    // can be used to clear the dropzone after the file was successfully uploaded to the server
    uploadedFilesToBeRemoved?: File[];
    multiple: boolean;
    className?: string;
}

interface IComponentState {
    acceptedFiles: File[];
    rejectedFiles: File[];
    showErrorDialog: boolean;
}

const CLASS_NAME = 'FileUploadDropzone';

export default class FileUploadDropzone
    extends PureComponent<IFileUploadDropzoneProps & IPublicProps, IComponentState> {

    constructor(props: IFileUploadDropzoneProps & IPublicProps) {
        super(props);

        this.state = {
            acceptedFiles: props.previousDroppedFiles || [],
            rejectedFiles: [],
            showErrorDialog: false,
        };

        this.fileUploadHandler = this.fileUploadHandler.bind(this);
        this.fileDeleteHandler = this.fileDeleteHandler.bind(this);
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
    }

    public componentDidUpdate(
        prevProps: Readonly<IFileUploadDropzoneProps & IPublicProps>,
    ): void {
        if (this.props.uploadedFilesToBeRemoved
            && this.props.uploadedFilesToBeRemoved !== prevProps.uploadedFilesToBeRemoved) {
            this.props.uploadedFilesToBeRemoved
                .forEach((uploadedFileToBeRemoved) => this.removeFileFromAcceptedFiles(uploadedFileToBeRemoved));
        }
    }

    public render() {
        const { acceptedMimeTypes, multiple, className } = this.props;
        const { acceptedFiles, rejectedFiles, showErrorDialog } = this.state;

        const totalFiles = acceptedFiles.length + rejectedFiles.length;

        return (
            <>
                <Dropzone
                    className={classNames(CLASS_NAME, className)}
                    onDrop={this.fileUploadHandler}
                    accept={acceptedMimeTypes}
                    multiple={multiple}
                    acceptClassName="accept"
                    rejectClassName="reject"
                >
                    {totalFiles > 0 ? this.renderFiles() : this.renderPlaceholder()}
                </Dropzone>
                {showErrorDialog && (
                    <Dialog
                        show={showErrorDialog}
                        onCloseIntent={this.onCloseErrorDialog}
                        header="common.file_upload_dropzone.error_no_multiple_allow.error"
                        type="error"
                    >
                        <div className="ErrorDialog__buttons">
                            <Button
                                id="error-dialog-confirm"
                                typeName="secondary"
                                onClick={this.onCloseErrorDialog}
                            >
                                <Translate
                                    msg="common.file_upload_dropzone.error_no_multiple_allow.confirm_button"
                                />
                            </Button>
                        </div>
                    </Dialog>
                )}
            </>
        );
    }

    private fileUploadHandler(acceptedFiles: File[], rejectedFiles: File[]) {
        const { multiple } = this.props;

        // Show error dialog if 2 items are dragged & multiple is false
        if (!multiple && (acceptedFiles.length + rejectedFiles.length > 1)) {
            this.setState({
                showErrorDialog: true,
            });
        } else {
            this.props.onFileDrop(acceptedFiles);

            this.setState({
                acceptedFiles,
                rejectedFiles,
            });
        }
    }

    private fileDeleteHandler(e: MouseEvent<HTMLElement>, file: File) {
        e.stopPropagation();

        const removeFromAcceptedResult = this.removeFileFromAcceptedFiles(file);

        if (removeFromAcceptedResult.wasRemoved) {
            this.props.onFileDelete(removeFromAcceptedResult.acceptedFiles);
            return;
        }

        const removeFromRejectedResult = this.removeFileFromRejectedFiles(file);

        if (removeFromRejectedResult.wasRemoved) {
            this.props.onFileDelete(removeFromRejectedResult.rejectedFiles);
        }
    }

    private removeFileFromAcceptedFiles(file: File) {
        const { acceptedFiles } = this.state;
        const indexAcceptedFiles = acceptedFiles.indexOf(file);

        if (indexAcceptedFiles !== -1) {
            const newAcceptedFiles = [...acceptedFiles];
            newAcceptedFiles.splice(indexAcceptedFiles, 1);

            this.setState({
                acceptedFiles: newAcceptedFiles,
            });

            return {
                wasRemoved: true,
                acceptedFiles: newAcceptedFiles,
            };
        }

        return {
            wasRemoved: false,
            acceptedFiles,
        };
    }

    private removeFileFromRejectedFiles(file: File) {
        const { rejectedFiles } = this.state;
        const indexRejectedFiles = rejectedFiles.indexOf(file);

        if (indexRejectedFiles !== -1) {
            const newRejectedFiles = [...rejectedFiles];
            newRejectedFiles.splice(indexRejectedFiles, 1);

            this.setState({
                rejectedFiles: newRejectedFiles,
            });

            return {
                wasRemoved: true,
                rejectedFiles: newRejectedFiles,
            };
        }

        return {
            wasRemoved: false,
            rejectedFiles,
        };
    }

    private renderFiles() {
        const { acceptedFiles, rejectedFiles } = this.state;

        return (
            <div
                className={classNames(`${CLASS_NAME}__inner ${CLASS_NAME}__inner--files`, {
                    [`${CLASS_NAME}__inner--rejected-files`]: rejectedFiles.length > 0,
                })}
            >
                {acceptedFiles.map((file, index) => {
                    return (
                        <UploadedFile
                            key={`${file.name}-${index}`}
                            file={file}
                            onFileDelete={this.fileDeleteHandler}
                        />
                    );
                })}

                {rejectedFiles.map((file, index) => {
                    return (
                        <UploadedFile
                            key={`${file.name}-${index}`}
                            file={file}
                            onFileDelete={this.fileDeleteHandler}
                            invalid={true}
                        />
                    );
                })}
            </div>
        );
    }

    private renderPlaceholder() {
        return (
            <div className={`${CLASS_NAME}__inner ${CLASS_NAME}__inner--placeholder`}>
                <InlineSVG svg={fileCloudIcon} />
                <p>
                    <Translate
                        msg="common.file_upload_dropzone.drop_text"
                        placeholders={{
                            selectFile:
                                <span className="placeholderLink">
                                    <Translate msg="common.file_upload_dropzone.select_file" />
                                </span>,
                        }}
                    />
                </p>
            </div>
        );
    }

    private onCloseErrorDialog() {
        this.setState({
            showErrorDialog: false,
        });
    }
}
