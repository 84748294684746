export enum ConstantType {
    ABSENCES = 'absences',
    LANGUAGES = 'languages',
    CANCELLATION_REASONS = 'cancellation_reasons',
    RISKS = 'risks',
    SEXES = 'sexes',
    STATUTES = 'statutes',
    TITLES = 'titles',
    NATIONALITIES = 'nationalities',
    COUNTRIES = 'supported_countries',
    CONTACT_TITLES = 'title_contacts',
    CONTACT_TYPES = 'contact_types',
}

export enum ConstantMessageType {
    MESSAGE_TYPES = 'message_types',
}

export const CONSTANT_TYPES = Object.values(ConstantType) as string[];

export type TConstants = {
    [key in ConstantType]: IConstant[];
};

export interface IConstant {
    id: string;
    code: string;
    description: string;
}
