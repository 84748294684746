import { object, array } from 'yup';
import number from '../../../../../utils/libs/yup/number';
import { AdminType } from '../../../../../models/user/userAccount';
import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    titleId: 'titleId',
    firstName: 'firstName',
    name: 'name',
    languageId: 'languageId',
    currentEmail: 'currentEmail',
    newEmail: 'newEmail',
    phoneNumber: 'phoneNumber',
    mobilePhoneNumber: 'mobilePhoneNumber',
    admin: 'admin',
    accessLevel: 'accessLevel',
    seats: 'seats',
    sexId: 'sexId',
};

export const schema = object({
    [fields.titleId]: number(),
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.languageId]: number().required(),
    [fields.newEmail]: string().email().required(),
    [fields.phoneNumber]: phoneNumber(),
    [fields.mobilePhoneNumber]: phoneNumber(),
    [fields.admin]: number().required(),
    [fields.accessLevel]: object().required(),
    [fields.seats]: array().when('admin', {
        is: AdminType.None || AdminType.None.toString(),
        then: array().of(object({
            companyCode: string().required(),
            name: string(),
        })).min(1).required(),
        otherwise: array().notRequired(),
    }),
    [fields.sexId]: number(),
});
