import { createAction, createSuccessAction, createFailAction } from '../index';
import {
    SWITCH_LOCALE,
    RESET_TRANSLATIONS_REFRESHED,
    REFRESH_TRANSLATIONS,
    TOGGLE_TRANSLATION_IDS,
    FETCH_TRANSLATIONS_AND_ALL_CONSTANTS,
} from './types';
import {
    ISwitchLocalePayload,
    IFetchTranslationsAndAllConstantsPayload,
} from '../../models/general/i18n';
import { createTypeActions } from '../../utils/libs/redux/createAction';

export const switchLocaleActions = createTypeActions<ISwitchLocalePayload, ISwitchLocalePayload>({
    type: SWITCH_LOCALE,
});

export function resetTranslationsRefreshed() {
    return createAction<object>(RESET_TRANSLATIONS_REFRESHED, {});
}

export function refreshTranslations() {
    return createAction<object>(REFRESH_TRANSLATIONS, {});
}

export function refreshTranslationsSucceeded() {
    return createSuccessAction<object>(REFRESH_TRANSLATIONS, {});
}

export function refreshTranslationsFailed() {
    return createFailAction<object>(REFRESH_TRANSLATIONS, {});
}

export function toggleTranslationIds() {
    return createAction(TOGGLE_TRANSLATION_IDS, {});
}

export const fetchTranslationsAndAllConstantsActions =
    createTypeActions<IFetchTranslationsAndAllConstantsPayload>({ type: FETCH_TRANSLATIONS_AND_ALL_CONSTANTS });
