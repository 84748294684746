import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux';
import { initReducerBuildingBlocks } from '@snipsonian/redux-features/es/entities/reducerBuildingBlocks';
import { REDUCER_KEYS } from '../../config/redux.config';
import { ENTITIES_REDUCER_INITIAL_STATE, IEntitiesReducerState } from '../../models/entities/entities.models';
import { registerReducer } from '../../utils/libs/redux/registerReducer';

export const reducerKey = REDUCER_KEYS.ENTITIES;

const { key, initialState, actionHandlers } = initReducerBuildingBlocks({
    reducerKey,
    initialState: ENTITIES_REDUCER_INITIAL_STATE,
    includeDefaultActionHandlers: true,
});

registerReducer<IEntitiesReducerState>({
    key,
    initialState,
    actionHandlers,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
