/**
 * https://patrickhlauke.github.io/touch/touchscreen-detection/
 */

declare global {
    interface Window {
        PointerEvent: typeof PointerEvent;
        TouchEvent: typeof TouchEvent;
    }
}

export default function isTouchDevice() {
    if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            return true;
        }
    } else {
        // no Pointer Events ...
        if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
            // check for any-pointerr:coarse which mostly means touchscreen
            return true;
        }
        if (window.TouchEvent || ('ontouchstart' in window)) {
            // last resort - check for exposed touched events API / event handler
            return true;
        }
    }
    return false;
}
