import React, { ReactNode } from 'react';
import './dropdown-item.scss';
import { IExtendedDropdownItemProps } from '..';
import classNames from 'classnames';

export type TDropdownItemValue = string | number | boolean;

export interface IDropdownItemProps {
    value?: TDropdownItemValue;
    children: ReactNode;
    withoutPadding?: boolean;
    isNotSelectable?: boolean;
    isAfterSearchFooter?: boolean;
}

export interface IDropdownItem {
    label: string;
    value: TDropdownItemValue;
}

const CLASS_NAME = 'DropdownItem';

function DropdownItem(props: IDropdownItemProps) {
    // Injected props from the dropdown component
    const extendedProps = props as IExtendedDropdownItemProps;

    const classes = classNames(CLASS_NAME, {
        [`${CLASS_NAME}--selected`]: !props.isNotSelectable && extendedProps.isSelected,
        [`${CLASS_NAME}--focused`]: !props.isNotSelectable && extendedProps.isFocused,
        [`${CLASS_NAME}--without-padding`]: extendedProps.withoutPadding,
        [`${CLASS_NAME}--not-selectable`]: !!extendedProps.isNotSelectable,
        [`${CLASS_NAME}--after-search-footer`]: !!extendedProps.isAfterSearchFooter,
    });

    return (
        <div
            className={classes}
            onClick={props.isNotSelectable ? undefined : extendedProps.onClick}
            data-dropdownitem-is-focused={extendedProps.isFocused}
            data-dropdownitem-value={JSON.stringify(extendedProps.value)}
        >
            {extendedProps.children}
        </div>
    );
}

export default DropdownItem;
