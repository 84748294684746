import { createEpic } from '..';
import {
    fetchPrivacyPolicyContentActions,
    fetchDisclaimerContentActions,
    fetchCookieConsentContentActions,
    fetchCookiesConfigurationActions,
} from './actions';
import {
    FETCH_LEGAL_PRIVACY_POLICY,
    FETCH_LEGAL_DISCLAIMER,
    FETCH_LEGAL_COOKIE_CONSENT,
    FETCH_COOKIES_CONFIGURATION,
} from './types';
import { getLocale } from '../i18n/selectors';
import { CMS_PAGE_NAME, CMS_PAGE_TYPE } from '../../config/cms.config';
import { IFetchContentPagePartsInput } from '../../models/general/cms';
import { IFetchCookiesConfigurationInput } from '../../models/general/cookies';

// fetchCookieConfigurationEpic
createEpic<IFetchCookiesConfigurationInput>({
    onActionType: FETCH_COOKIES_CONFIGURATION,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const data = await api.general.cookies.memoizedCookiesConfiguration({
                locale: getLocale(state),
            });

            dispatch(fetchCookiesConfigurationActions.succeeded(data));
        } catch (error) {
            dispatch(fetchCookiesConfigurationActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchCookieConsentEpic
createEpic<IFetchContentPagePartsInput>({
    onActionType: FETCH_LEGAL_COOKIE_CONSENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const data = await api.general.content.fetchPageByInternalName({
                pageType: CMS_PAGE_TYPE.legal,
                locale: getLocale(state),
                internalName: CMS_PAGE_NAME.cookieConsentContent,
            });

            dispatch(fetchCookieConsentContentActions.succeeded(data));
        } catch (error) {
            dispatch(fetchCookieConsentContentActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchPrivacyPolicyEpic
createEpic<IFetchContentPagePartsInput>({
    onActionType: FETCH_LEGAL_PRIVACY_POLICY,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const data = await api.general.content.fetchPageByInternalName({
                pageType: CMS_PAGE_TYPE.legal,
                locale: getLocale(state),
                internalName: CMS_PAGE_NAME.privacyPolicyContent,
            });

            dispatch(fetchPrivacyPolicyContentActions.succeeded(data));
        } catch (error) {
            dispatch(fetchPrivacyPolicyContentActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchDisclaimerEpic
createEpic<IFetchContentPagePartsInput>({
    onActionType: FETCH_LEGAL_DISCLAIMER,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const data = await api.general.content.fetchPageByInternalName({
                pageType: CMS_PAGE_TYPE.content,
                locale: getLocale(state),
                internalName: CMS_PAGE_NAME.disclaimer,
            });

            dispatch(fetchDisclaimerContentActions.succeeded(data));
        } catch (error) {
            dispatch(fetchDisclaimerContentActions.failed(error));
        }
        done();
    },
    latest: true,
});
