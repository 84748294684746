import React from 'react';
import EditSeatAddress from './EditSeatAddress';
import Availabilities from '../shared/Availabilities';
import { IAddressSave } from '../../../../models/general/address';
import { ICompanySeat } from '../../../../models/admin/company';
import { CompanyAvailabilityType } from '../../../../models/admin/companyInfo';
import { IState } from '../../../../redux/IState';
import Holidays from '../shared/Holidays';

export enum SeatDetailsOverlayType {
    EditBillingAddress,
    EditAddress,
    EditAvailabilitiesMedExams,
    EditAvailabilitiesRiskMgmt,
    EditHolidays,
}

export type TAddressOverlayType = SeatDetailsOverlayType.EditAddress | SeatDetailsOverlayType.EditBillingAddress;

export default function DetailOverlay(props: {
    overlayType: SeatDetailsOverlayType,
    closeOverlay: () => void,
    onAddressChange: (
        overlayType: TAddressOverlayType,
        address: IAddressSave,
    ) => void,
    selectedSeatSelector: (state: IState) => ICompanySeat,
}) {
    const companyNameSelector = (state: IState) => {
        const selectedSeat = props.selectedSeatSelector(state);
        return selectedSeat ? selectedSeat.company.name : '';
    };
    const companyCodeSelector = (state: IState) => {
        const selectedSeat = props.selectedSeatSelector(state);
        return selectedSeat ? selectedSeat.company.companyCode : '';
    };

    switch (props.overlayType) {
        case SeatDetailsOverlayType.EditHolidays:
            return (
                <Holidays
                    withinSidePanel={{
                        immediatelyShowOverlay: true,
                        closeOverlay: props.closeOverlay,
                    }}
                />
            );
        case SeatDetailsOverlayType.EditAvailabilitiesMedExams:
            return (
                <Availabilities
                    type={CompanyAvailabilityType.MedicalExaminations}
                    companyNameSelector={companyNameSelector}
                    companyCodeSelector={companyCodeSelector}
                    withinSidePanel={{
                        immediatelyShowOverlay: true,
                        closeOverlay: props.closeOverlay,
                    }}
                />
            );
        case SeatDetailsOverlayType.EditAvailabilitiesRiskMgmt:
            return (
                <Availabilities
                    type={CompanyAvailabilityType.RiskManagement}
                    companyNameSelector={companyNameSelector}
                    companyCodeSelector={companyCodeSelector}
                    withinSidePanel={{
                        immediatelyShowOverlay: true,
                        closeOverlay: props.closeOverlay,
                    }}
                />
            );
        default:
            return (
                <EditSeatAddress
                    onClose={props.closeOverlay}
                    onFormSubmit={(address) => {
                        props.onAddressChange(props.overlayType as TAddressOverlayType, address);
                        props.closeOverlay();
                    }}
                />
            );
    }
}
