import React from 'react';
import classNames from 'classnames';
import './course-links.scss';
import Translate from '../../../../common/Translate';
import OutgoingLink from '../../../../common/navigation/OutgoingLink';
import Icon from '../../../../common/icons/Icon';
import EntityWrapper from '../../../../common/widget/EntityWrapper';
import { getAsyncCourseLinks } from '../../../../../redux/entities/selectors';
import { ICourseLink } from '../../../../../models/general/cms';

const CLASS_NAME = 'CourseLinks';

export function CourseLinks({ noLoader = false }: { noLoader?: boolean }) {
    return (
        <div className={CLASS_NAME}>
            <div className="container">
                <EntityWrapper
                    asyncEntitySelector={getAsyncCourseLinks}
                    renderData={renderCourseLinks}
                    fetch={{ noLoader }}
                />
            </div>
        </div>
    );

    function renderCourseLinks({ data: entrypoints }: { data: ICourseLink[] }) {
        return (
            <>
                <h2 className={`${CLASS_NAME}__title`}>
                    <Translate msg="document_center.courses.overview.learn_and_connect.title"/>
                </h2>
                <div className={`${CLASS_NAME}__list`}>
                    {entrypoints.map((entrypoint) => (
                        <CourseLinkTile
                            key={`lc-entrypoint-${entrypoint.id}`}
                            {...entrypoint}
                        />
                    ))}
                </div>
            </>
        );
    }

    function CourseLinkTile(props: ICourseLink) {
        const { title, url: href, description, roundImages, image } = props;
        const itemClassName = `${CLASS_NAME}__list__item`;

        return (
            <div className={itemClassName}>
                <OutgoingLink href={href}>
                    <div className={`${itemClassName}__inner`}>
                        <div className={`${itemClassName}__content`}>
                            <h3>{title}</h3>
                            {description && <p>{description}</p>}
                            {image && (
                                <figure
                                    className={classNames(`${itemClassName}__figure`, {
                                        [`${itemClassName}__figure--rounded`]: !!roundImages,
                                    })}
                                >
                                    <img src={image.url} alt="" />
                                </figure>
                            )}
                        </div>
                        <footer>
                            <Icon typeName="chevron-right" circle />
                        </footer>
                    </div>
                </OutgoingLink>
            </div>
        );
    }
}
