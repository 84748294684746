import React, { PureComponent, MouseEvent } from 'react';
import { clone } from 'ramda';

import { connect } from '../../index';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
    IRenderActionContentProps,
    IIsSelectAllCheckboxSelected, IOnSelectAllChangeProps, IRenderSelectAllTranslateProps,
} from '../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder, ListItemId } from '../../../models/general/list';
import Translate from '../../common/Translate';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../utils/formatting/formatDate';
import DefaultDocumentCenterPage from '../shared/DefaultDocumentCenterPage';
import {
    getMedicalDocumentsAsyncInfo, getMedicalDocuments, getMedicalDocumentFilesInBulkAsyncInfo,
} from '../../../redux/documentCenter/medicalDocuments/selectors';
import { IMedicalDocument } from '../../../models/documentCenter/medicalDocuments';
import { formatPersonNameFormal } from '../../../utils/formatting/formatPerson';
import { DEFAULT_MEDICAL_DOCUMENTS_FILTER } from '../../../api/documentCenter/medicalDocuments.api';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../utils/core/string/separatedStringList';
import ListItemActions from '../../common/list/ListItemActions';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import { AsyncStatus } from '../../../models/general/redux';
import NoDocumentsFoundErrorDialog from '../../common/modals/NoDocumentsFoundErrorDialog';
import { getDocumentAsyncInfo } from '../../../redux/employee/documents/selectors';
import { fetchMedicalDocumentFilesInBulkActions } from '../../../redux/documentCenter/medicalDocuments/actions';
import Loader from '../../common/waiting/Loader';
import ListActionButton from '../../common/buttons/ListActionButton';
import medicalDocsIllu from '../../../views/assets/img/illustrations/illu_medische-documenten.svg';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { fetchDocument } from '../../../redux/employee/documents/actions';
import { MAX_NR_OF_DOCUMENT_DOWNLOADS } from '../../../config/general.config';
import sortListItems from '../../../utils/list/sortListItems';
import ListWithCheckboxSelect from '../../common/list/ListWithCheckboxSelect';

const BASE_NAME = 'medical-documents';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const TRANSLATION_PREFIX = 'document_center.medical_docs';

interface IColumnNames {
    dateCreated: string;
    dateCreatedSort: string;
    employee: string;
    templateName: string;
    examinationReason: string;
    examinationDate: string;
    examinationDateSort: string;
    actions: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    reasonIds: string;
    templateNames: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'dateCreated',
    sortOrder: SortOrder.Descending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    dateCreated: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date_created`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.dateCreatedSort,
        percentWidth: 15,
    },
    dateCreatedSort: {
        hide: true,
        percentWidth: null,
    },
    examinationDate: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.examination_date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.examinationDateSort,
        percentWidth: 15,
    },
    examinationDateSort: {
        hide: true,
        percentWidth: null,
    },
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    templateName: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.template_name`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    examinationReason: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.examination_reason`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

type TMedicalDocumentsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

interface IPrivateProps {
    isDownloadingDocument: boolean;
    onDownloadClick: (id: string) => void;
    onDownloadSelectedClick: (ids: ListItemId[]) => void;
}

interface IComponentState {
    selectedIds: ListItemId[];
    sortedColumn: ISortedColumn<IColumnNames>;
}

class MedicalDocumentsComp extends React.Component<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedIds: [],
            sortedColumn: INITIAL_SORT,
        };

        this.setSortedColumn = this.setSortedColumn.bind(this);
        this.setSelectedIds = this.setSelectedIds.bind(this);
        this.isSelectAllCheckboxSelected = this.isSelectAllCheckboxSelected.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.renderSelectAllTranslate = this.renderSelectAllTranslate.bind(this);
    }

    render() {
        return (
            <DefaultDocumentCenterPage
                titleTranslationKey={`${TRANSLATION_PREFIX}.title`}
                headerSvg={medicalDocsIllu}
            >
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_MEDICAL_DOCUMENTS,
                        asyncInfoSelector: getMedicalDocumentsAsyncInfo,
                        dataSelector: getMedicalDocuments,
                        transformData: mapMedicalDocumentsToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: TMedicalDocumentsListProps) =>
                            <MedicalDocumentsList
                                {...renderProps}
                                {...this.props}
                                {...this.state}
                                setSortedColumn={this.setSortedColumn}
                                setSelectedIds={this.setSelectedIds}
                            />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData,
                        filterValidationSchema: startEndDateSchema,
                    }}
                    headerConfig={{
                        selectAllCheckbox: {
                            renderTranslateComponent: this.renderSelectAllTranslate,
                            isSelected: this.isSelectAllCheckboxSelected,
                            onSelectionChange: this.onSelectAllChange,
                        },
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <SearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                <FilterContent {...renderProps} />,
                        renderActionContent:
                            (renderProps: IPrivateProps & IRenderActionContentProps<ListItem<IColumnNames>[]>) =>
                                <ActionContent
                                    {...renderProps}
                                    {...this.props}
                                    {...this.state}
                                />,
                        exportButton: {
                            baseFilename: 'medical-documents',
                        },
                    }}
                    footerConfig={{
                        shouldRenderShowAllButton,
                    }}
                />
            </DefaultDocumentCenterPage>
        );
    }

    private setSortedColumn(sortedColumn: ISortedColumn<IColumnNames>) {
        this.setState({
            sortedColumn,
        });
    }

    private setSelectedIds(selectedIds: ListItemId[]) {
        this.setState({
            selectedIds,
        });
    }

    private isSelectAllCheckboxSelected(
        isSelectedProps: IIsSelectAllCheckboxSelected<ListItem<IColumnNames>[], IFilterValues>,
    ) {
        const { selectedIds } = this.state;
        const {
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = isSelectedProps;

        if (selectedIds.length <= 0) {
            return false;
        }

        const maxNrSelected = getMaxNrSelected(filterValues);
        if (clientSideFilteredlistItems['length'] < maxNrSelected) {
            return selectedIds.length === clientSideFilteredlistItems['length'];
        }

        return selectedIds.length === maxNrSelected;
    }

    private onSelectAllChange(selectAllProps: IOnSelectAllChangeProps<ListItem<IColumnNames>[], IFilterValues>) {
        const {
            isSelected,
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = selectAllProps;

        const maxNrSelected = getMaxNrSelected(filterValues);

        const selectedItems = isSelected
            ? clientSideFilteredlistItems.length > maxNrSelected
                // eslint-disable-next-line max-len
                ? sortListItems(clientSideFilteredlistItems, this.state.sortedColumn, COLUMNS[this.state.sortedColumn.name])
                    .slice(0, maxNrSelected)
                : clientSideFilteredlistItems
            : [];

        this.setSelectedIds(selectedItems.map((listItem) => listItem.id as string));
    }

    private renderSelectAllTranslate(props: IRenderSelectAllTranslateProps) {
        const {
            masterData: clientSideFilteredlistItems,
        } = props;
        if (clientSideFilteredlistItems['length'] <= MAX_NR_OF_DOCUMENT_DOWNLOADS) {
            return (
                <Translate msg={`${TRANSLATION_PREFIX}.actions.select_all`} />
            );
        }

        return (
            <Translate
                msg={`${TRANSLATION_PREFIX}.actions.select_all_with_max`}
                placeholders={{
                    amount: MAX_NR_OF_DOCUMENT_DOWNLOADS,
                }}
            />
        );
    }
}

function mapMedicalDocumentsToListItems(masterData: IMedicalDocument[]): ListItem<IColumnNames>[] {
    return masterData
        .map((medicalDocument) => ({
            id: medicalDocument.id,
            columns: {
                dateCreated: formatDateInLongFormat(medicalDocument.dateCreated),
                dateCreatedSort: medicalDocument.dateCreated,
                employee: formatPersonNameFormal(medicalDocument.employee),
                templateName: medicalDocument.templateName,
                examinationReason: medicalDocument.examinationReason,
                examinationDate: formatDateInLongFormat(medicalDocument.examination.examinationDate),
                examinationDateSort: medicalDocument.examination.examinationDate,
                actions: null,
            },
        }));
}

interface ISelectDocumentsProps extends IComponentState {
    setSortedColumn: (sortedColumn: ISortedColumn<IColumnNames>) => void;
    setSelectedIds: (selectedIds: string[]) => void;
}

class MedicalDocumentsList extends PureComponent<TMedicalDocumentsListProps & IPrivateProps & ISelectDocumentsProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TMedicalDocumentsListProps & IPrivateProps & ISelectDocumentsProps) {
        super(props);

        this.onDownloadClick = this.onDownloadClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {
            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg={`${TRANSLATION_PREFIX}.actions.download`} /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                </ListItemActions>
            );
        };
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(listItem.id as string);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedIds,
            setSelectedIds,
            setSortedColumn,
            filterValues,
            footer,
            isDownloadingDocument,
        } = this.props;

        return (
            <>
                <ListWithCheckboxSelect
                    name={BASE_NAME}
                    withSorting={true}
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    selectedItemIds={selectedIds}
                    onItemSelected={setSelectedIds}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    maxSelectedItems={MAX_NR_OF_DOCUMENT_DOWNLOADS}
                    initialSort={INITIAL_SORT}
                    onColumnSortChanged={setSortedColumn}
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getDocumentAsyncInfo}
                    isMedicalExaminationDocument
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getMedicalDocumentFilesInBulkAsyncInfo}
                    isMedicalExaminationDocument
                />
                <Loader show={isDownloadingDocument} />
            </>
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.reasonIds)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.reasonIds);
            return reasonIds.includes(listItem.columns.examinationReason.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.templateNames)) {
                return true;
            }

            const templateNames = separateStringList(filterValues.templateNames);
            return templateNames.includes(listItem.columns.templateName.toString());
        });
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        masterData: allListItems,
        formRenderProps,
    } = renderProps;

    const possibleReasons = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'examinationReason',
        'examinationReason',
    );

    const possibleTemplateNames = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'templateName',
        'templateName',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
            {possibleTemplateNames.length > 0 &&
                <CheckboxesOrTypeaheadFilter
                    filterName="templateName"
                    labelTranslationKey={`${TRANSLATION_PREFIX}.filter.template_name`}
                    possibleFilterItems={possibleTemplateNames}
                    actualFilterValue={formRenderProps.values.templateNames}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue(
                        'templateNames',
                        newFilterValue,
                    )}
                />
            }
            {possibleReasons.length > 0 &&
                <CheckboxesOrTypeaheadFilter
                    filterName="reason"
                    labelTranslationKey={`${TRANSLATION_PREFIX}.filter.examination_reason`}
                    possibleFilterItems={possibleReasons}
                    actualFilterValue={formRenderProps.values.reasonIds}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue(
                        'reasonIds',
                        newFilterValue,
                    )}
                />
            }
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: {isShowAll: boolean}) {
    return isShowAll ? {
        ...DEFAULT_MEDICAL_DOCUMENTS_FILTER,
        isShowAll,
    } : DEFAULT_MEDICAL_DOCUMENTS_FILTER;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_MEDICAL_DOCUMENTS_FILTER.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_MEDICAL_DOCUMENTS_FILTER.startDate,
            },
            templateNames: {
                show: true,
                translationKeySuffixOverride: 'template_name',
                multiple: {
                    enable: true,
                },
            },
            reasonIds: {
                show: true,
                translationKeySuffixOverride: 'reason',
                multiple: {
                    enable: true,
                },
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

function ActionContent(props: IRenderActionContentProps<ListItem<IColumnNames>[]> & IPrivateProps & IComponentState) {
    const {
        masterData: allListItems,
        onDownloadSelectedClick,
        selectedIds,
    } = props;

    const isDownloadSelectedEnabled = (allListItems && allListItems.length > 0)
        && (selectedIds && selectedIds.length > 0);

    return (
        <>
            <ListActionButton
                id="medical-documents-download-all-button"
                type="text"
                iconTypeName="download-file"
                translationKey={`${TRANSLATION_PREFIX}.actions.download_selected`}
                onClick={(e) => onDownloadSelectedClick(selectedIds)}
                disabled={!isDownloadSelectedEnabled}
            />
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const downloadBulkAsyncInfo = getMedicalDocumentFilesInBulkAsyncInfo(state);
        const downloadSingleAsyncInfo = getDocumentAsyncInfo(state);
        const isDownloadingDocument = downloadBulkAsyncInfo.status === AsyncStatus.Busy
            || downloadSingleAsyncInfo.status === AsyncStatus.Busy;

        return {
            isDownloadingDocument,
        };
    },
    dispatchProps: (dispatch) => ({
        onDownloadClick: (id: string) => {
            dispatch(fetchDocument({
                ids: [id],
            }));
        },
        onDownloadSelectedClick: (ids: ListItemId[]) => {
            dispatch(fetchMedicalDocumentFilesInBulkActions.trigger({
                ids: ids.map((id) => id.toString()),
            }));
        },
    }),
})(MedicalDocumentsComp);

function getMaxNrSelected(filterValues: IFilterValues) {
    return filterValues.isShowAll
        ? MAX_NR_OF_DOCUMENT_DOWNLOADS
        : Math.min(MAX_NR_OF_DOCUMENT_DOWNLOADS, DEFAULT_NR_OF_RECORDS_TO_SHOW);
}
