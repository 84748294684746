import { object, date } from 'yup';
import number from '../../../../../utils/libs/yup/number';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    companyCode: 'companyCode',
    customerFunctionId: 'customerFunctionId',
    dateInFunction: 'dateInFunction',
};

export const schema = object({
    [fields.companyCode]: string().required(),
    [fields.customerFunctionId]: number().required(),
    [fields.dateInFunction]: date().required(),
});
