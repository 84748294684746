/**
 * Reload the current document.
 *
 * @param reloadFromServer 'false' (default) will reload from the cache, 'true' will reload from the server.
 */
export default function reloadPage({
    reloadFromServer = false,
}: {
    reloadFromServer?: boolean,
} = {}) {
    if (window) {
        window.location.reload(reloadFromServer);
    }
}
