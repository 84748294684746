import React from 'react';
import TimeGridHeader from 'react-big-calendar/lib/TimeGridHeader';
import Sticky from '../../../technical/Sticky';

export default function StickyTimeGridHeader(props) {
    return (
        <Sticky id="TimeGridHeader" includeTopOffsetForIds={['CalendarToolbar']}>
            <TimeGridHeader {...props} isOverflowing={false} />
        </Sticky>
    );
}
