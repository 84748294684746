import React, { Component, CSSProperties } from 'react';
import dayjs from 'dayjs';
import './holidays-overview.scss';
import classNames from 'classnames';
import Checkbox from '../../../../common/input/Checkbox';
import Translate from '../../../../common/Translate';
import { ListColumns, IColumn, ListItem } from '../../../../../models/general/list';
import List, { getMinWidth, getWidth } from '../../../../common/list/List';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { ITranslator } from '../../../../../models/general/i18n';
import Icon from '../../../../common/icons/Icon';
import { stopPropagation } from '../../../../../utils/browser/events/stopPropagation';
import {
    ICompanyHoliday,
    IUpdateCompanyHolidaysPayload,
    TCompanyHolidayUpdateData,
    HolidayVisitState,
} from '../../../../../models/admin/companyInfo';
import { getCompanyHolidaysAsyncInfo } from '../../../../../redux/company/info/selectors';
import { connect } from '../../../..';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import Button from '../../../../common/buttons/Button';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import {
    formatDateForBackend,
    formatDateForDisplay,
    formatDateToDayMonth,
} from '../../../../../utils/formatting/formatDate';
import ListItemActions from '../../../../common/list/ListItemActions';
import { isValidBackendDate } from '../../../../../utils/core/date/isValid';
import { isBeforeOrEqual } from '../../../../../utils/core/date/isBeforeOrEqual';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ErrorTypes } from '../../../../../models/general/error';
import { getDateWithinCurrentYear, now } from '../../../../../utils/core/date/getSpecificDate';
import ListFooter from '../../../../common/list/ListFooter';
import { hasRequiredAccessLevels } from '../../../../../redux/auth/selectors';
import FormError from '../../../../common/forms/FormError';
import { isAfterOrEqual } from '../../../../../utils/core/date/isAfterOrEqual';
import { stringComparerAscending } from '../../../../../utils/list/comparerUtils';

const CLASS_NAME = 'HolidaysOverview';
const TRANSLATION_PREFIX = 'administration.company_info.holidays.overview';
const CUSTOM_HOLIDAY_START_DATE_MIN = formatDateForBackend(getDateWithinCurrentYear({ month: 1, day: 1 }));
const CUSTOM_HOLIDAY_START_DATE_MAX = formatDateForBackend(getDateWithinCurrentYear({ month: 12, day: 31 }));

interface ICompanyHolidaysOverviewProps {
    holidays: ICompanyHoliday[];
    isEdit?: boolean;
    onOpenEdit?: () => void;
    onSaveEdit?: (updateHolidaysPayload: Pick<IUpdateCompanyHolidaysPayload, 'holidays'>) => void;
    isWithinSidePanel?: boolean;
}

interface IPrivateProps {
    fetchAsyncInfo: IAsyncFieldInfo;
    hasWriteAccessLevel: boolean;
}

interface IContextProps {
    translator: ITranslator;
}

interface IColumnNames {
    description: string;
    companyVisits: string;
    medicalExaminations: string;
    actions: string;
    period: string;
}

interface IListItemData {
    holiday: ICompanyHoliday;
}

type TExtendedHoliday = ICompanyHoliday & {
    selected: boolean;
    parentHolidayListItemId?: string;
    deleted?: boolean;
    added?: boolean;
    errorTranslationKey?: string;
    isOverlapping?: boolean;
};

enum CollapsibleState {
    Expanded = 'expanded',
    Collapsed = 'collapsed',
}

interface IState {
    extendedHolidaysFlattened: TExtendedHoliday[];
    categoryCollapsibleState: { [listItemId: string]: CollapsibleState };
    touched: boolean;
    holidaysOverlap: boolean;
}

type TProps = ICompanyHolidaysOverviewProps & IContextProps & IPrivateProps;

class HolidaysOverviewComp extends Component<TProps, IState> {
    private columns: ListColumns<IColumnNames>;

    constructor(props: TProps) {
        super(props);

        this.state = {
            extendedHolidaysFlattened: initializeAndFlattenHolidays(props.holidays),
            categoryCollapsibleState: initializeCollapsibleState(props.holidays),
            touched: false,
            holidaysOverlap: false,
        };

        this.renderTableContent = this.renderTableContent.bind(this);
        this.renderVisitState = this.renderVisitState.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.renderDescription = this.renderDescription.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.renderPeriod = this.renderPeriod.bind(this);
        this.wrapCellInCheckbox = this.wrapCellInCheckbox.bind(this);

        this.renderSingleHoliday = this.renderSingleHoliday.bind(this);
        this.renderCustomHolidayEdit = this.renderCustomHolidayEdit.bind(this);
        this.renderNestedHoliday = this.renderNestedHoliday.bind(this);

        this.onVisitStateChanged = this.onVisitStateChanged.bind(this);
        this.onHolidaySelected = this.onHolidaySelected.bind(this);
        this.onHolidayDateChanged = this.onHolidayDateChanged.bind(this);
        this.onSaveHolidays = this.onSaveHolidays.bind(this);
        this.onDeleteHoliday = this.onDeleteHoliday.bind(this);
        this.onAddHoliday = this.onAddHoliday.bind(this);
        this.onToggleExpandCategory = this.onToggleExpandCategory.bind(this);
        this.validateHolidays = this.validateHolidays.bind(this);
    }

    public componentDidUpdate(prevProps: TProps) {
        if (
            prevProps.fetchAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchAsyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                extendedHolidaysFlattened: initializeAndFlattenHolidays(this.props.holidays),
                categoryCollapsibleState: initializeCollapsibleState(this.props.holidays),
            });
        }
    }

    public render() {
        const { holidays, isEdit, onOpenEdit, isWithinSidePanel, hasWriteAccessLevel } = this.props;
        const { holidaysOverlap, touched } = this.state;

        this.columns = {
            description: {
                label: <Translate msg={`${TRANSLATION_PREFIX}.columns.description`} />,
                percentWidth: 40,
                render: this.renderDescription,
                minWidth: 385,
            },
            period: {
                label: <Translate msg={`${TRANSLATION_PREFIX}.columns.period`} />,
                percentWidth: 16,
                minWidth: 74,
                render: this.renderPeriod,
            },
            companyVisits: {
                label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company_visits`} />,
                percentWidth: 22,
                render: (item: ListItem<IColumnNames, string, IListItemData>) =>
                    this.renderVisitState(item, 'visitStateRB'),
            },
            medicalExaminations: {
                label: <Translate msg={`${TRANSLATION_PREFIX}.columns.medical_examinations`} />,
                percentWidth: 22,
                render: (item: ListItem<IColumnNames, string, IListItemData>) =>
                    this.renderVisitState(item, 'visitStateMT'),
            },
            actions: isEdit ? {
                percentWidth: null,
                minWidth: 50,
                render: this.renderActions,
            } : {
                hide: true,
                percentWidth: null,
            },
        };

        const listItems: ListItem<IColumnNames, string, IListItemData>[] = mapHolidaysToListItems(holidays);

        const overviewClasses = classNames(CLASS_NAME, {
            ['is-edit']: isEdit,
            [`${CLASS_NAME}-compact`]: isWithinSidePanel,
            disabled: !isEdit && !hasWriteAccessLevel,
        });

        return (
            <div className={overviewClasses} onClick={hasWriteAccessLevel && onOpenEdit}>
                {!isEdit && hasWriteAccessLevel && <div className={`${CLASS_NAME}__edit`}>
                    <Button
                        id="edit-holidays"
                        typeName="text"
                        onClick={onOpenEdit}
                    >
                        <Icon typeName="pencil" circle />
                    </Button>
                </div>}
                <div>
                    <List
                        name="HolidaysOverview"
                        columns={this.columns}
                        items={listItems.sort(sortHolidays)}
                        customTableContentRenderer={this.renderTableContent}
                        footer={!isWithinSidePanel && !isEdit && hasWriteAccessLevel && (
                            <ListFooter
                                left={this.renderEditHolidaysButton()}
                            />
                        )}
                    />
                    {isWithinSidePanel && this.renderEditHolidaysButton()}
                    {isEdit && (
                        <>
                            {touched && holidaysOverlap && <FormError
                                translationKey={`${TRANSLATION_PREFIX}.error.overlapping_holidays`}
                            />}
                            <div className={`${CLASS_NAME}__actions`}>
                                <Button
                                    id="holidays-overview"
                                    onClick={() => this.validateHolidays(true)}
                                    typeName="secondary"
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.submit`} />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    private renderEditHolidaysButton() {
        const {
            fetchAsyncInfo,
            onOpenEdit,
            isEdit,
        } = this.props;

        if (isEdit) {
            return null;
        }

        return (
            <Button
                id="edit-holidays"
                typeName="text"
                className="AddButton"
                disabled={fetchAsyncInfo.status === AsyncStatus.Busy}
                onClick={onOpenEdit}
            >
                <Icon typeName="plus" circle />
                <span>
                    <Translate msg={`${TRANSLATION_PREFIX}.footer_edit_button`} />
                </span>
            </Button>
        );
    }

    private renderTableContent(listItems: ListItem<IColumnNames, string, IListItemData>[]) {
        const { isEdit } = this.props;
        const { extendedHolidaysFlattened } = this.state;
        const columnNames = this.getColumnNamesToDisplay();

        const tableClasses = classNames(`${CLASS_NAME}__table`);

        if (!isEdit && extendedHolidaysFlattened.every((holiday) => isHolidayInitiallyInActive(holiday))) {
            return (
                <table>
                    <tbody>
                        <tr key={`list-${name}-empty`} className="no-results">
                            <td colSpan={columnNames.length}>
                                <Translate msg="common.list.no_items" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        return (
            <table className={tableClasses}>
                {listItems.map((item, itemIndex) => {
                    const holiday = item.data.holiday;

                    /* Custom holidays category */
                    if (isEdit && holiday.customTypeId) {
                        return this.renderCustomHolidayEdit({
                            holiday,
                            item,
                        });
                    }

                    /* A holiday without any nested holidays */
                    if (holiday.typeId && !holiday.holidayTypes) {
                        const extendedHoliday = extendedHolidaysFlattened.find((h) => getListItemId(h) === item.id);
                        return this.renderSingleHoliday({
                            extendedHoliday,
                            item,
                            itemIndex,
                        });
                    }

                    /* A category with nested holidays */
                    return this.renderNestedHoliday({
                        holiday,
                        item,
                    });
                })}
            </table>
        );
    }

    private renderNestedHoliday({
        item,
        holiday,
    }: {
        item: ListItem<IColumnNames, string, IListItemData>,
        holiday: ICompanyHoliday,
    }) {
        const { isEdit } = this.props;
        const { extendedHolidaysFlattened, categoryCollapsibleState } = this.state;

        const columnNames = this.getColumnNamesToDisplay();

        const categoryDescription = holiday.description;
        const nestedListItems = mapHolidaysToListItems(holiday.holidayTypes);

        if (
            !isEdit &&
            nestedListItems.every((nestedListItem) => {
                return isVisitStateUnknown(nestedListItem.data.holiday.visitStateMT) &&
                    isVisitStateUnknown(nestedListItem.data.holiday.visitStateRB);
            })
        ) {
            return null;
        }

        return (
            <React.Fragment key={item.id}>
                <tbody>
                    <tr className={`${CLASS_NAME}__holiday_category`}>
                        <td colSpan={columnNames.length}>
                            {isEdit ?
                                this.wrapCategoryInCheckbox(categoryDescription, item) :
                                categoryDescription
                            }
                            {isEdit && (
                                <Button
                                    id={`${item.id}-expand-category`}
                                    className={`${CLASS_NAME}__expand_button`}
                                    typeName="text"
                                    onClick={() => this.onToggleExpandCategory(item.id)}
                                >
                                    {categoryCollapsibleState[item.id] === CollapsibleState.Expanded ? (
                                        <Icon typeName="chevron-up" colorType="primary" />
                                    ) : (
                                            <Icon typeName="chevron-down" colorType="primary" />
                                        )}
                                </Button>
                            )}
                        </td>
                    </tr>
                </tbody>
                {(!isEdit || (isEdit && categoryCollapsibleState[item.id] === CollapsibleState.Expanded)) && (
                    <tbody>
                        {nestedListItems.sort(sortHolidays).map((nestedItem, nestedItemIndex) => {
                            const nestedHoliday = nestedItem.data.holiday;
                            const extendedNestedHoliday =
                                extendedHolidaysFlattened.find((h) => getListItemId(h) === nestedItem.id);

                            if (!isEdit && isHolidayInitiallyInActive(nestedHoliday)) {
                                return null;
                            }

                            return (
                                <tr
                                    key={nestedItem.id}
                                    data-item-id={nestedItem.id}
                                    className={classNames(`${CLASS_NAME}__holiday_nested`, {
                                        ['hide-label']: isEdit &&
                                            extendedNestedHoliday &&
                                            !extendedNestedHoliday.selected,
                                    })}
                                >
                                    {columnNames.map((columnName, columnIndex) => {
                                        // eslint-disable-next-line max-len
                                        return this.renderCell(nestedItem, nestedItemIndex, columnName, columnIndex);
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </React.Fragment>
        );
    }

    private renderSingleHoliday({
        item,
        itemIndex,
        extendedHoliday,
    }: {
        item: ListItem<IColumnNames, string, IListItemData>,
        itemIndex: number,
        extendedHoliday: TExtendedHoliday,
    }) {
        const { isEdit } = this.props;
        const columnNames = this.getColumnNamesToDisplay();
        if (!isEdit && isHolidayInitiallyInActive(item.data.holiday)) {
            return null;
        }

        return (
            <tbody key={item.id}>
                <tr
                    className={classNames(`${CLASS_NAME}__holiday_single`, {
                        ['hide-label']: isEdit && extendedHoliday && !extendedHoliday.selected,
                    })}
                    data-item-id={item.id}
                >
                    {columnNames.map((columnName, columnIndex) => {
                        return this.renderCell(item, itemIndex, columnName, columnIndex);
                    })}
                </tr>
            </tbody>
        );
    }

    private renderCustomHolidayEdit({
        item,
        holiday,
    }: {
        item: ListItem<IColumnNames, string, IListItemData>,
        holiday: ICompanyHoliday,
    }) {
        const { translator, isEdit } = this.props;
        const { extendedHolidaysFlattened } = this.state;

        if (!isEdit) {
            return null;
        }

        const columnNames = this.getColumnNamesToDisplay();

        const addedCustomHolidays =
            extendedHolidaysFlattened.filter((extendedHoliday) => extendedHoliday.added);

        const nestedCustomHolidays = holiday.holidayTypes || [];

        const nestedListItems =
            mapHolidaysToListItems([...nestedCustomHolidays, ...addedCustomHolidays]);

        const categoryDescription = translator(`${TRANSLATION_PREFIX}.custom_holidays.description`);

        return (
            <React.Fragment key={item.id}>
                <tbody>
                    <tr className={`${CLASS_NAME}__holiday_category`}>
                        <td colSpan={columnNames.length}>
                            {categoryDescription}
                            {isEdit && (
                                <Button
                                    id={`${item.id}-add-custom-holiday`}
                                    className={`${CLASS_NAME}__add_holiday_button`}
                                    typeName="text"
                                    onClick={() => this.onAddHoliday(holiday)}
                                >
                                    <Icon typeName="plus" circle />
                                </Button>
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    {nestedListItems.sort(sortHolidays).map((nestedItem, nestedItemIndex) => {
                        const nestedHoliday = nestedItem.data.holiday;

                        const extendedHoliday =
                            extendedHolidaysFlattened.find((h) => getListItemId(h) === nestedItem.id);

                        if (!isEdit && isHolidayInitiallyInActive(nestedHoliday)) {
                            return null;
                        }

                        if (extendedHoliday && extendedHoliday.deleted) {
                            return null;
                        }

                        return (
                            <tr
                                key={nestedItem.id}
                                data-item-id={nestedItem.id}
                                className={classNames(`${CLASS_NAME}__holiday_nested`, 'is-custom')}
                            >
                                {columnNames.map((columnName, columnIndex) => {
                                    // eslint-disable-next-line max-len
                                    return this.renderCell(nestedItem, nestedItemIndex, columnName, columnIndex);
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </React.Fragment>
        );
    }

    private renderCell(
        item: ListItem<IColumnNames, string, IListItemData>,
        itemIndex: number,
        columnName: string,
        columnIndex: number,
    ) {
        const { translator, isEdit } = this.props;
        const column = this.columns[columnName] as IColumn<IColumnNames>;

        const styles: CSSProperties = {
            minWidth: getMinWidth(column),
            width: getWidth(column, null),
            textAlign: column.align,
        };

        const cellContent = typeof column.render === 'function' ?
            column.render(item, itemIndex) : item.columns[columnName];

        const label = React.isValidElement(column.label) ?
            translator(column.label.props) : column.label;

        return (
            <td
                key={`list-item-cell-${item.id}-${columnIndex}`}
                data-label={label}
                data-column-name={columnName}
                style={styles}
            >
                {isEdit && columnIndex === 0 && !item.data.holiday.isCustomerPeriod ?
                    this.wrapCellInCheckbox(cellContent, item) :
                    cellContent
                }
            </td>
        );
    }

    private wrapCategoryInCheckbox(cellContent: any, listItem: ListItem<IColumnNames, string, IListItemData>) {
        const { extendedHolidaysFlattened, categoryCollapsibleState } = this.state;

        const extendedHolidaysInCategory =
            extendedHolidaysFlattened.filter((holiday) => holiday.parentHolidayListItemId === listItem.id);
        const selectedExtendedHolidaysInCategory =
            extendedHolidaysInCategory.filter((holiday) => holiday && holiday.selected);
        const isAllSelected =
            extendedHolidaysInCategory.length === selectedExtendedHolidaysInCategory.length;
        const isAnySelected =
            selectedExtendedHolidaysInCategory.length > 0;

        const isDisabled = categoryCollapsibleState[listItem.id] === CollapsibleState.Collapsed;

        const checkboxClasses = classNames(`${CLASS_NAME}__checkbox`, {
            selected: isAnySelected,
            disabled: isDisabled,
        });

        return (
            <Checkbox
                name={`holiday-overview-edit-${listItem.id}`}
                checked={isAnySelected}
                onDivClicked={stopPropagation}
                className={checkboxClasses}
                indeterminate={isAllSelected ? false : isAnySelected}
                onChange={isDisabled ? () => this.onToggleExpandCategory(listItem.id) : () => {
                    extendedHolidaysInCategory.forEach((holiday) => {
                        this.onHolidaySelected(getListItemId(holiday), !isAllSelected);
                    });
                }}
            >
                {cellContent}
            </Checkbox>
        );
    }

    private wrapCellInCheckbox(cellContent: any, listItem: ListItem<IColumnNames, string, IListItemData>) {
        const { extendedHolidaysFlattened } = this.state;
        const extendedHoliday = extendedHolidaysFlattened.find((holiday) => getListItemId(holiday) === listItem.id);

        const checkboxClasses = classNames(`${CLASS_NAME}__checkbox`, {
            selected: extendedHoliday && extendedHoliday.selected,
        });

        return (
            <Checkbox
                name={`holiday-overview-edit-${listItem.id}`}
                checked={extendedHoliday && extendedHoliday.selected}
                onDivClicked={stopPropagation}
                className={checkboxClasses}
                onChange={(e) => this.onHolidaySelected(getListItemId(extendedHoliday), e.target.checked)}
            >
                {cellContent}
            </Checkbox>
        );
    }

    private onSaveHolidays() {
        const { onSaveEdit, hasWriteAccessLevel } = this.props;
        const { extendedHolidaysFlattened } = this.state;

        if (!hasWriteAccessLevel) {
            return;
        }

        const holidaysToSave = extendedHolidaysFlattened.map((holiday) => {
            const holidayToSave: TCompanyHolidayUpdateData = {
                endDate: holiday.endDate,
                customerHolidayIdMT: holiday.customerHolidayIdMT,
                customerHolidayIdRB: holiday.customerHolidayIdRB,
                typeId: holiday.typeId,
                startDate: holiday.startDate,
                visitStateMT: holiday.visitStateMT,
                visitStateRB: holiday.visitStateRB,
            };
            return holidayToSave;
        });

        const savePayload = holidaysToSave.filter((holiday) => {
            if (isVisitStateUnknown(holiday.visitStateRB) || isVisitStateUnknown(holiday.visitStateMT)) {
                return false;
            }
            return true;
        });

        onSaveEdit({ holidays: savePayload });
    }

    private validateHolidays(submit?: boolean) {
        const { extendedHolidaysFlattened } = this.state;

        let hasErrors = false;
        let holidaysOverlap = false;

        const newExtendedHolidaysFlattened = extendedHolidaysFlattened.map((extendedHoliday) => {
            if (!extendedHoliday.selected || extendedHoliday.deleted) {
                return extendedHoliday;
            }
            const validatedHoliday = { ...extendedHoliday };

            if (validatedHoliday.isCustomerPeriod) {
                if (!isStartTimeAndEndTimeValid(validatedHoliday)) {
                    validatedHoliday.errorTranslationKey =
                        `${TRANSLATION_PREFIX}.custom_holidays.error.date_required`;
                    hasErrors = true;
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('Date required', validatedHoliday);
                    }
                    return validatedHoliday;
                }

                if (!isStartDateBeforeOrEqualToEndDate(validatedHoliday)) {
                    validatedHoliday.errorTranslationKey =
                        `${TRANSLATION_PREFIX}.custom_holidays.error.start_date_cannot_be_after_end_date`;
                    hasErrors = true;
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('Start date before or equal to end date', validatedHoliday);
                    }
                    return validatedHoliday;
                }

                if (!isStartDateWithinMinAndMax(validatedHoliday)) {
                    validatedHoliday.errorTranslationKey =
                        `${TRANSLATION_PREFIX}.custom_holidays.error.start_date_should_be_within_current_year`;
                    hasErrors = true;
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('Start date should be within the current year', validatedHoliday);
                    }
                    return validatedHoliday;
                }
            }

            if (doesHolidayOverlapWithOthers(validatedHoliday, this.state.extendedHolidaysFlattened)) {
                validatedHoliday.errorTranslationKey = '';
                validatedHoliday.isOverlapping = true;
                holidaysOverlap = true;
                if (process.env.NODE_ENV === 'development') {
                    console.warn('Overlaps with other holiday(s)', validatedHoliday);
                }
                return validatedHoliday;
            }

            // reset errors if holiday no longer has errors
            validatedHoliday.errorTranslationKey = '';
            validatedHoliday.isOverlapping = false;
            return validatedHoliday;
        });

        this.setState({
            extendedHolidaysFlattened: newExtendedHolidaysFlattened,
            touched: true,
            holidaysOverlap,
        });

        if (submit && !hasErrors && !holidaysOverlap) {
            this.onSaveHolidays();
        }
    }

    private onToggleExpandCategory(listItemId: string) {
        const { categoryCollapsibleState } = this.state;
        const newcategoryCollapsibleState = { ...categoryCollapsibleState };

        newcategoryCollapsibleState[listItemId] = categoryCollapsibleState[listItemId] === CollapsibleState.Expanded ?
            CollapsibleState.Collapsed : CollapsibleState.Expanded;

        this.setState({ categoryCollapsibleState: newcategoryCollapsibleState });
    }

    private onHolidaySelected(
        listItemId: string, newValue: boolean,
    ) {
        const newHolidays = [...this.state.extendedHolidaysFlattened];
        const holidayToChange = newHolidays.find((holiday) => getListItemId(holiday) === listItemId);
        holidayToChange.selected = newValue;
        if (newValue) {
            holidayToChange.visitStateMT = HolidayVisitState.NotAllowed;
            holidayToChange.visitStateRB = HolidayVisitState.NotAllowed;
        } else {
            holidayToChange.visitStateMT = HolidayVisitState.Unknown;
            holidayToChange.visitStateRB = HolidayVisitState.Unknown;
        }
        this.setState({ extendedHolidaysFlattened: [...this.state.extendedHolidaysFlattened] });
        if (this.state.touched) {
            this.validateHolidays();
        }
    }

    private onAddHoliday(customHolidayCategory: ICompanyHoliday) {
        const newHoliday: TExtendedHoliday = {
            parentHolidayListItemId: getListItemId(customHolidayCategory),
            typeId: customHolidayCategory.customTypeId,
            added: true,
            description: now().getMilliseconds().toString(), // used to generate unique list id, not displayed anywhere
            endDate: '',
            isCustomerPeriod: true,
            selected: true,
            startDate: '',
            visitStateMT: HolidayVisitState.NotAllowed,
            visitStateRB: HolidayVisitState.NotAllowed,
        };
        this.setState({ extendedHolidaysFlattened: [...this.state.extendedHolidaysFlattened, newHoliday] });
    }

    private onDeleteHoliday(
        listItemId: string,
    ) {
        const newHolidays = [...this.state.extendedHolidaysFlattened];
        const holidayToChange = newHolidays.find((extendedHoliday) => getListItemId(extendedHoliday) === listItemId);
        holidayToChange.deleted = true;
        holidayToChange.visitStateMT = HolidayVisitState.Unknown;
        holidayToChange.visitStateRB = HolidayVisitState.Unknown;
        this.setState({ extendedHolidaysFlattened: newHolidays });
        if (this.state.touched) {
            this.validateHolidays();
        }
    }

    private onVisitStateChanged(
        listItemId: string, checked: boolean, type: keyof Pick<ICompanyHoliday, 'visitStateMT' | 'visitStateRB'>,
    ) {
        const newValue = checked ? HolidayVisitState.Allowed : HolidayVisitState.NotAllowed;
        const newHolidays = [...this.state.extendedHolidaysFlattened];
        newHolidays.find((holiday) => getListItemId(holiday) === listItemId)[type] = newValue;
        this.setState({ extendedHolidaysFlattened: newHolidays });
    }

    private onHolidayDateChanged(
        listItemId: string, newDate: string, type: keyof Pick<ICompanyHoliday, 'startDate' | 'endDate'>,
    ) {
        const newHolidays = [...this.state.extendedHolidaysFlattened];
        newHolidays.find((holiday) => getListItemId(holiday) === listItemId)[type] = newDate;
        this.setState({ extendedHolidaysFlattened: newHolidays });
        if (this.state.touched) {
            this.validateHolidays();
        }
    }

    private renderDescription(listItem: ListItem<IColumnNames, string, IListItemData>) {
        const { isEdit } = this.props;
        const { extendedHolidaysFlattened, touched } = this.state;
        const { holiday } = listItem.data;

        if (!holiday.isCustomerPeriod) {
            return listItem.columns.description;
        }

        const extendedHoliday = extendedHolidaysFlattened.find((holiday) => getListItemId(holiday) === listItem.id);

        if (!extendedHoliday) {
            return null;
        }

        if (!isEdit) {
            return (
                <div className={`${CLASS_NAME}__custom_holiday_dates`}>
                    <span className={`${CLASS_NAME}__custom_holiday_date_readonly`}>
                        <span><Translate msg={`${TRANSLATION_PREFIX}.custom_holidays.period_from`} /></span>
                        <span>{formatDateForDisplay(extendedHoliday.startDate)}</span>
                    </span>
                    <span className={`${CLASS_NAME}__custom_holiday_date_readonly`}>
                        <span><Translate msg={`${TRANSLATION_PREFIX}.custom_holidays.period_to`} /></span>
                        <span>{formatDateForDisplay(extendedHoliday.endDate)}</span>
                    </span>
                </div>
            );
        }

        return (
            <div>
                <div className={`${CLASS_NAME}__custom_holiday_dates`}>
                    <FloatableTextInputWrapper inline>
                        <label htmlFor={`${listItem.id}-start-date`}>
                            <Translate msg={`${TRANSLATION_PREFIX}.custom_holidays.period_from`} />
                        </label>
                        <DatePicker
                            value={extendedHoliday.startDate}
                            id={`${listItem.id}-start-date`}
                            minDate={CUSTOM_HOLIDAY_START_DATE_MIN}
                            maxDate={CUSTOM_HOLIDAY_START_DATE_MAX}
                            isInvalid={touched && !!extendedHoliday.errorTranslationKey}
                            onChange={
                                (formattedDate) => this.onHolidayDateChanged(
                                    getListItemId(extendedHoliday), formattedDate, 'startDate')
                            }
                        />
                    </FloatableTextInputWrapper>
                    <FloatableTextInputWrapper inline>
                        <label htmlFor={`${listItem.id}-end-date`}>
                            <Translate msg={`${TRANSLATION_PREFIX}.custom_holidays.period_to`} />
                        </label>
                        <DatePicker
                            value={extendedHoliday.endDate}
                            id={`${listItem.id}-end-date`}
                            isInvalid={touched && !!extendedHoliday.errorTranslationKey}
                            onChange={
                                (formattedDate) => this.onHolidayDateChanged(
                                    getListItemId(extendedHoliday), formattedDate, 'endDate')
                            }
                        />
                    </FloatableTextInputWrapper>
                </div>
                {touched && extendedHoliday.errorTranslationKey && (
                    <span>
                        <FormFieldError
                            error={{
                                message: extendedHoliday.errorTranslationKey,
                                type: ErrorTypes.Custom,
                            }}
                        />
                    </span>
                )}
            </div>
        );
    }

    private renderActions(listItem: ListItem<IColumnNames, string, IListItemData>) {
        const { isEdit } = this.props;
        if (!isEdit || !listItem.data.holiday.isCustomerPeriod) {
            return null;
        }

        return (
            <ListItemActions>
                <Button
                    id={`${listItem.id}-remove`}
                    onClick={() => this.onDeleteHoliday(listItem.id)}
                >
                    <span><Translate msg={`${TRANSLATION_PREFIX}.custom_holidays.remove`} /></span>
                    <Icon circle typeName="bin" />
                </Button>
            </ListItemActions>
        );
    }

    private renderPeriod(listItem: ListItem<IColumnNames, string, IListItemData>) {
        const { extendedHolidaysFlattened } = this.state;

        const extendedHoliday = extendedHolidaysFlattened.find((holiday) => getListItemId(holiday) === listItem.id);

        if (extendedHoliday.isOverlapping) {
            return <span className={`${CLASS_NAME}__period-error`}>{listItem.columns.period}</span>;
        }

        return listItem.columns.period;
    }

    private renderVisitState(
        listItem: ListItem<IColumnNames, string, IListItemData>,
        type: keyof Pick<ICompanyHoliday, 'visitStateMT' | 'visitStateRB'>,
    ) {
        if (!this.props.isEdit) {
            const { [type]: vistStateType } = listItem.data.holiday;
            if (vistStateType === HolidayVisitState.Allowed) {
                return <Icon typeName="check" colorType="primary" />;
            }
            if (vistStateType === HolidayVisitState.NotAllowed) {
                return <Icon typeName="cross" colorType="grey-dark" />;
            }
            return '-';
        }

        const { extendedHolidaysFlattened } = this.state;

        const extendedHoliday = extendedHolidaysFlattened.find((holiday) => getListItemId(holiday) === listItem.id);
        if (!extendedHoliday || !extendedHoliday.selected) {
            return null;
        }

        const allowed = extendedHoliday[type] === HolidayVisitState.Allowed;

        return (
            <Checkbox
                name={`${type.toLowerCase()}-allowed-${listItem.id}`}
                toggleButton={true}
                checked={allowed}
                onChange={(e) => this.onVisitStateChanged(getListItemId(extendedHoliday), e.target.checked, type)}
            >
                <span className={`${CLASS_NAME}__visit_state_label`}>
                    <Translate msg={`${TRANSLATION_PREFIX}.toggle_button.${allowed ? 'allowed' : 'not_allowed'}`} />
                </span>
            </Checkbox>
        );
    }

    private getColumnNamesToDisplay() {
        return Object.keys(this.columns)
            .filter((columnName) => !this.columns[columnName].hide);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            fetchAsyncInfo: getCompanyHolidaysAsyncInfo(state),
            hasWriteAccessLevel: hasRequiredAccessLevels(state, { company: 'W' }),
        };
    },
})(HolidaysOverview);

function HolidaysOverview(props: ICompanyHolidaysOverviewProps & IPrivateProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <HolidaysOverviewComp {...props} translator={translator} />
            )}
        </TranslatorContext.Consumer>
    );
}

function getListItemId(holiday: ICompanyHoliday) {
    return `${holiday.typeId}-${holiday.description}-${holiday.customerHolidayIdMT}-${holiday.customerHolidayIdRB}`;
}

function mapHolidaysToListItems(holidays: ICompanyHoliday[]): ListItem<IColumnNames, string, IListItemData>[] {
    return holidays.map((holiday) => {
        const period = holiday.startDate === holiday.endDate ?
            formatDateToDayMonth(dayjs(holiday.startDate).toDate()) : // eslint-disable-next-line max-len
            `${formatDateToDayMonth(dayjs(holiday.startDate).toDate())} - ${formatDateToDayMonth(dayjs(holiday.endDate).toDate())}`;

        const item: ListItem<IColumnNames, string, IListItemData> = {
            id: getListItemId(holiday),
            columns: {
                companyVisits: holiday.visitStateRB,
                medicalExaminations: holiday.visitStateMT,
                description: holiday.description,
                actions: null,
                period: holiday.startDate && holiday.endDate ? period : '',
            },
            data: {
                holiday,
            },
        };
        return item;
    });
}

function isHolidayInitiallyInActive(holiday: ICompanyHoliday) {
    return typeof holiday.customerHolidayIdMT === 'undefined' && typeof holiday.customerHolidayIdRB === 'undefined';
}

function isVisitStateUnknown(visitState: HolidayVisitState) {
    return !visitState || visitState === HolidayVisitState.Unknown;
}

function sortHolidays(
    a: ListItem<IColumnNames, string, IListItemData>, b: ListItem<IColumnNames, string, IListItemData>,
) {
    const { holiday: holidayA } = a.data;
    const { holiday: holidayB } = b.data;

    switch (true) {
        case !!holidayA.customTypeId && !holidayB.customTypeId:
            return -1;
        case !holidayA.customTypeId && !!holidayB.customTypeId:
            return 1;
        case !holidayA.holidayTypes && !!holidayB.holidayTypes:
            return 1;
        case !!holidayA.holidayTypes && !holidayB.holidayTypes:
            return -1;
        default: {
            const startDateCompare = stringComparerAscending(holidayA.startDate, holidayB.startDate);
            if (startDateCompare !== 0) return startDateCompare;

            const endDateCompare = stringComparerAscending(holidayA.endDate, holidayB.endDate);
            if (endDateCompare !== 0) return endDateCompare;

            return stringComparerAscending(holidayA.description, holidayB.description);
        }
    }
}

function initializeAndFlattenHolidays(holidays: ICompanyHoliday[]) {
    return holidays.reduce(
        (accumulator, currentHoliday) => {
            /*  When editing it is not possible to select 'unknown' as a visitState
                when the other vsistState is known (allowed or not allowed).
                But since existing data may contain this situation,
                we will copy the known state to the unknown state when editing/creating new holiday data.
                This way both visitStates are always filled in. */
            function copyVisitStateIfOneIsUnknownWhileOtherIsNot(
                holidayValues: ICompanyHoliday,
            ) {
                const newValues: ICompanyHoliday = { ...holidayValues };
                if (
                    isVisitStateUnknown(holidayValues.visitStateMT) &&
                    !isVisitStateUnknown(holidayValues.visitStateRB)
                ) {
                    newValues.visitStateMT = holidayValues.visitStateRB;
                } else if (
                    !isVisitStateUnknown(holidayValues.visitStateMT) &&
                    isVisitStateUnknown(holidayValues.visitStateRB)
                ) {
                    newValues.visitStateRB = holidayValues.visitStateMT;
                }
                return newValues;
            }
            if (currentHoliday.typeId && !currentHoliday.holidayTypes) {
                const { holidayTypes: nestedHolidays, ...holidayValues } = currentHoliday;
                const updatedHolidayValues = copyVisitStateIfOneIsUnknownWhileOtherIsNot(holidayValues);

                return [
                    ...accumulator,
                    {
                        ...updatedHolidayValues,
                        selected: !isHolidayInitiallyInActive(currentHoliday),
                    },
                ];
            }
            const flattenedNestedHolidays = !currentHoliday.holidayTypes ? [] : currentHoliday.holidayTypes.reduce(
                (accumulator, currentNestedHoliday) => {
                    const updatedNestedHolidayValues =
                        copyVisitStateIfOneIsUnknownWhileOtherIsNot(currentNestedHoliday);

                    return [
                        ...accumulator,
                        {
                            ...updatedNestedHolidayValues,
                            selected: !isHolidayInitiallyInActive(currentNestedHoliday),
                            parentHolidayListItemId: getListItemId(currentHoliday),
                        },
                    ];
                },
                [] as TExtendedHoliday[],
            );
            return [...accumulator, ...flattenedNestedHolidays];
        },
        [] as TExtendedHoliday[],
    );
}

function initializeCollapsibleState(holidays: ICompanyHoliday[]) {
    return holidays.reduce(
        (categoryState, holiday) => {
            if (holiday.holidayTypes && holiday.holidayTypes.length > 0) {
                categoryState[getListItemId(holiday)] = CollapsibleState.Collapsed;
            }
            return categoryState;
        },
        {},
    );
}

function isStartTimeAndEndTimeValid(holiday: TExtendedHoliday) {
    return isValidBackendDate(holiday.startDate) && isValidBackendDate(holiday.endDate);
}

function isStartDateBeforeOrEqualToEndDate(holiday: TExtendedHoliday) {
    return isBeforeOrEqual(holiday.startDate, holiday.endDate);
}

function isStartDateWithinMinAndMax(holiday: TExtendedHoliday) {
    return isAfterOrEqual(holiday.startDate, CUSTOM_HOLIDAY_START_DATE_MIN)
        && isBeforeOrEqual(holiday.startDate, CUSTOM_HOLIDAY_START_DATE_MAX);
}

function doesHolidayOverlapWithOthers(holiday: TExtendedHoliday, allHolidays: TExtendedHoliday[]) {
    let overlapping = false;

    allHolidays.forEach((otherHoliday) => {
        // do not check unselected or deleted holidays
        if (!otherHoliday.selected || otherHoliday.deleted) {
            return;
        }
        // do not check holiday for overlap against itself
        if (getListItemId(holiday) === getListItemId(otherHoliday)) {
            return;
        }
        const aDates = {
            start: dayjs(holiday.startDate),
            end: dayjs(holiday.endDate),
        };
        const bDates = {
            start: dayjs(otherHoliday.startDate),
            end: dayjs(otherHoliday.endDate),
        };
        if (
            (aDates.start >= bDates.start && aDates.start <= bDates.end) ||
            (aDates.end <= bDates.end && aDates.end >= bDates.start) ||
            (bDates.start >= aDates.start && bDates.start <= aDates.end) ||
            (bDates.end <= aDates.end && bDates.end >= aDates.start)
        ) {
            overlapping = true;
        }
    });

    return overlapping;
}
