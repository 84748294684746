import React, { PureComponent } from 'react';
import { path } from 'ramda';

import './book-timeslot.scss';

import SelectFreeTimeslot from '../../shared/SelectFreeTimeslot';
import { connect } from '../../../../..';
import FlipDialog from '../../../../../common/modals/FlipDialog';
import {
    getPlanMedicalExaminationWizardEntity,
    getAddTimeslotAsyncInfo,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    IExaminationReason,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
    IMedicalExaminationTimeSlot,
} from '../../../../../../models/interventions/medicalExaminations';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import { IMedicalCenter, IEmployee } from '../../../../../../models/admin/employee';
import TimeslotRemarks from '../TimeslotRemarks';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { IDialogHeaderProps } from '../../../../../common/modals/Dialog/DialogHeader';
import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import { formatAddressStreet, formatAddressCity } from '../../../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import Translate from '../../../../../common/Translate';
import Icon from '../../../../../common/icons/Icon';
import Button from '../../../../../common/buttons/Button';
import RisksOverview from '../RisksOverview';
import { formatTimeOfDateForDisplay } from '../../../../../../utils/formatting/formatTime';
import { minutesOffsetFromDate, getDate } from '../../../../../../utils/core/date/getSpecificDate';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { getMinutesBetweenDates } from '../../../../../../utils/core/date/getDifferenceBetweenDates';
import { IBufferzoneMedicalCenter } from '../../../../../../models/admin/companyInfo';

import { getAccurateMedicalExaminationLocation } from './BookTimeslot.helper';

type TWizardValues = Partial<Pick<IPlanMedicalExaminationSingleEmployeeBaseEntity, 'selectTime'>>;

interface IPrivateProps {
    selectedEmployee: Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>;
    selectedEventId: string;
    selectedTimeslot: IMedicalExaminationTimeSlot;
    employeeName: string;
    mainMedicalCenter: IMedicalCenter | IBufferzoneMedicalCenter;
    updateWizardData: (values: Partial<TWizardValues>) => void;
    addTimeslotAsyncInfo: IAsyncFieldInfo;
    examinationReason: IExaminationReason;
    selectedTimeSlotLocation?: IMedicalCenter | IBufferzoneMedicalCenter;
}

const CLASS_NAME = 'BookTimeslot';

interface IState {
    flipDialog: boolean;
}

class BookTimeslot extends PureComponent<IStepperStepRenderProps & IPrivateProps, IState> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            flipDialog: false,
        };

        this.toggleFlipDialog = this.toggleFlipDialog.bind(this);
    }

    public render() {
        const {
            updateWizardData,
            selectedEventId,
            selectedTimeslot,
            addTimeslotAsyncInfo,
            goToNextStep,
            selectedEmployee,
            renderStepButtons,
        } = this.props;

        const { flipDialog } = this.state;

        return (
            <>
                <SelectFreeTimeslot
                    selectedEmployee={selectedEmployee}
                />
                <div className="container">
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                hide: true,
                            },
                        })}
                    </StickyFooter>
                </div>
                {selectedTimeslot &&
                    <FlipDialog
                        show={!!selectedEventId}
                        onCloseIntent={() => {
                            if (addTimeslotAsyncInfo.status === AsyncStatus.Busy) {
                                return;
                            }
                            updateWizardData({
                                selectTime: {
                                    selectedEventId: null,
                                    selectedTimeslot: null,
                                },
                            });
                            this.setState({
                                flipDialog: false,
                            });
                        }}
                        backContent={<RisksOverview />}
                        onFlipIntent={this.toggleFlipDialog}
                        flip={flipDialog}
                        header={this.getDialogHeader()}
                    >
                        <TimeslotRemarks onTimeslotPlanned={goToNextStep} />
                    </FlipDialog>
                }
            </>
        );
    }

    private toggleFlipDialog() {
        this.setState(prevState => ({
            flipDialog: !prevState.flipDialog,
        }));
    }

    private getDialogHeader(): IDialogHeaderProps {
        const {
            selectedTimeslot,
            mainMedicalCenter,
            employeeName,
            examinationReason,
            selectedTimeSlotLocation,
        } = this.props;

        const medicalCenter = selectedTimeSlotLocation || mainMedicalCenter;
        const duration =
            examinationReason && examinationReason.duration
            || getMinutesBetweenDates(getDate(selectedTimeslot.end), getDate(selectedTimeslot.start));

        const endTime = selectedTimeslot && minutesOffsetFromDate(getDate(selectedTimeslot.start), duration).toDate();
        const risksTranslationKey =
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.view_risks';

        if (selectedTimeslot && medicalCenter) {
            return {
                color: 'primary',
                children: (
                    <div className={`${CLASS_NAME}__dialog-header`}>
                        <h4>
                            <Translate
                                msg="interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.title"
                                placeholders={{
                                    date: formatDateInLongFormat(selectedTimeslot.start),
                                    startTime: formatTimeOfDateForDisplay(selectedTimeslot.start),
                                    endTime: formatTimeOfDateForDisplay(endTime),
                                }}
                            />
                        </h4>
                        <div>
                            {!!medicalCenter.name && medicalCenter.name.trim().length > 0 && (
                                <span>
                                    {medicalCenter.name}<br />
                                </span>
                            )}
                            {medicalCenter.address && (
                                <>
                                    {formatAddressStreet(medicalCenter.address)},
                                    {formatAddressCity(medicalCenter.address)}
                                </>
                            )}
                        </div>
                        <p>
                            {employeeName}<br />
                            <Button
                                id="flip-dialog"
                                typeName="text"
                                onClick={this.toggleFlipDialog}
                            >
                                <Icon typeName="arrow-right" />
                                <Translate msg={risksTranslationKey} />
                            </Button>
                        </p>
                    </div>
                ),
            };
        }
        return null;
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
        const examinationReason = getPlanMedicalExaminationWizardReason(state);

        const selectedEmployee = wizardEntity && wizardEntity.searchEmployee
            && wizardEntity.searchEmployee.selectedEmployee;
        const selectedEventId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedEventId;
        const selectedTimeslot = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedTimeslot;

        const selectedMedicalCenterCode = path<string>(
            ['selectTime', 'filter', 'selectedMedicalCenterCode'],
            wizardEntity,
        );

        const mainMedicalCenter = getCompanyMedicalCenters(state)
            .find((item) => item.code === selectedMedicalCenterCode) || null;

        return {
            selectedEmployee,
            selectedEventId,
            selectedTimeslot,
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            mainMedicalCenter,
            addTimeslotAsyncInfo: getAddTimeslotAsyncInfo(state),
            examinationReason,
            ...!!(selectedTimeslot && selectedMedicalCenterCode) && {
                selectedTimeSlotLocation: getAccurateMedicalExaminationLocation(
                    selectedTimeslot,
                    selectedMedicalCenterCode,
                    state,
                ),
            },
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;

                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                    },
                }));
            },
        };
    },
})(BookTimeslot);
