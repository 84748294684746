import React, { PureComponent } from 'react';
import './company-selection.scss';
import { connect } from '../../index';
import ListFooter from '../../common/list/ListFooter';
import Translate from '../../common/Translate';
import { getMyCompanies, hasPermission } from '../../../redux/auth/selectors';
import Button from '../../common/buttons/Button';
import { navigateToSeatsSelection, navigateTo, redirectToUrl } from '../../../redux/location/actions';
import { getQueryParams } from '../../../redux/location/selectors';
import {
    getCompanies,
    getFetchCompaniesAsyncInfo,
} from '../../../redux/company/companies/selectors';
import {
    getSelectedCompany,
    getPrevSelectedCompany,
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
    getCompanySeatsForSelectedCompany,
} from '../../../redux/company/selected/selectors';
import { Permission } from '../../../models/auth/authorisation';
import {
    IFetchCompaniesPayload,
    ICompanyDismissableWithDate,
    ICompanySeat,
} from '../../../models/admin/company';
import PageHeader from '../../appShell/PageHeader';
import CompanySelectionFilter from './CompanySelectionFilter';
import ListActionButton from '../../common/buttons/ListActionButton';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import { IAsyncFieldInfo, AsyncStatus } from '../../../models/general/redux';
import ActiveFilterButton from '../../common/buttons/ActiveFilterButton';
import { IActiveFilter } from '../../common/widget/MasterWithDetail/typings';
import { fetchCompanies } from '../../../redux/company/companies/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    fetchSelectedCompanySeatsAndDivisionsActions,
    setSelectedCompany,
    setSelectedCompanySeat,
} from '../../../redux/company/selected/actions';
import CompanyList from '../CompanyList';
import { PAYLOAD_PARAM } from '../../../utils/libs/redux/async/asyncReducerUtils';
import Loader from '../../common/waiting/Loader';

interface IPrivateProps {
    companies: ICompanyDismissableWithDate[];
    searchFilter: string;
    goToSeatsSelection: (companyCode: string) => void;
    selectedCompany: ICompanyDismissableWithDate;
    showAllCompanies: boolean;
    fetchAsyncInfo: IAsyncFieldInfo;
    activeFilters: IActiveFilter<IFetchCompaniesPayload>[];
    fetchCompanySeatsAsyncInfo: IAsyncFieldInfo;
    companySeats: ICompanySeat[];
    setSidebarSearchFilterUrlQueryParams: (filter: IFetchCompaniesPayload) => void;
    setSearchFilterUrlQueryParams: (searchFilter: string) => void;
    fetchCompanies: (filter: IFetchCompaniesPayload) => void;
    fetchCompanySeats: (company: ICompanyDismissableWithDate) => void;
    redirectToOriginalUrl: () => void;
    selectCompanySeat: (seat: ICompanySeat) => void;
}

interface IState {
    selectedCompany?: ICompanyDismissableWithDate;
    isFilterPanelOpen?: boolean;
}

const LIST_NAME = 'companies';
const CLASS_NAME = 'CompanySelection';

class CompanySelection extends PureComponent<IPrivateProps, IState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedCompany: props.selectedCompany,
            isFilterPanelOpen: false,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
        this.onFilterPanelCloseIntent = this.onFilterPanelCloseIntent.bind(this);
        this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
        this.onRemoveActiveFilter = this.onRemoveActiveFilter.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
    }

    public render() {
        const {
            showAllCompanies, fetchAsyncInfo, activeFilters,
            fetchCompanySeatsAsyncInfo, searchFilter, companies,
            setSearchFilterUrlQueryParams,
        } = this.props;

        const { selectedCompany, isFilterPanelOpen } = this.state;

        const isFetching = (
            fetchAsyncInfo.status === AsyncStatus.Busy ||
            fetchCompanySeatsAsyncInfo.status === AsyncStatus.Busy
        );

        return (
            <div className={`${CLASS_NAME} container`}>
                <PageHeader
                    title="account.company_selection.title"
                />
                <div className={`${CLASS_NAME}__content`}>
                    {showAllCompanies &&
                        <div className={`${CLASS_NAME}__filter`}>
                            {activeFilters.length === 0 &&
                                <p><Translate msg="account.company_selection.perform_search_message" /></p>}
                            <ListActionButton
                                id="company-selection-search-button"
                                iconTypeName="search"
                                translationKey="account.company_selection.search_button"
                                onClick={this.onSearchButtonClick}
                            />
                            {activeFilters && activeFilters.length > 0 &&
                                <div className={`${CLASS_NAME}__active-filters`}>
                                    {activeFilters.map((activeFilter, index) => (
                                        <ActiveFilterButton
                                            // eslint-disable-next-line max-len
                                            key={`company-selection-active-filter-${activeFilter.filterKey}-${index}`}
                                            onClick={() => this.onRemoveActiveFilter(
                                                activeFilter.filterKey as keyof IFetchCompaniesPayload,
                                            )}
                                            translationKey={activeFilter.filterNameTranslationKey}
                                            value={activeFilter.filterValue}
                                        />
                                    ))}
                                </div>
                            }
                            <CompanySelectionFilter
                                isOpen={isFilterPanelOpen}
                                onCloseIntent={this.onFilterPanelCloseIntent}
                                onSubmit={this.onFilterSubmit}
                            />
                        </div>
                    }
                    <CompanyList
                        name={LIST_NAME}
                        selectedCompanyCompanyCode={selectedCompany && selectedCompany.companyCode}
                        onCompanySelected={this.onItemSelected}
                        initialSearchValue={searchFilter}
                        withSearch={!showAllCompanies}
                        companies={companies}
                        fetchAsyncInfo={fetchAsyncInfo}
                        onSearchInput={setSearchFilterUrlQueryParams}
                        footer={(
                            <ListFooter
                                left={fetchCompanySeatsAsyncInfo.error &&
                                    <ErrorPlaceholder apiError={fetchCompanySeatsAsyncInfo.error} />
                                }
                                right={(
                                    <Button
                                        id="company-select-next-button"
                                        typeName="secondary"
                                        disabled={isFetching || !selectedCompany}
                                        onClick={this.goToNextPage}
                                    >
                                        <Translate msg="account.company_selection.next" />
                                    </Button>
                                )}
                            />
                        )}
                    />
                    <Loader show={isFetching} />
                </div>
            </div>
        );
    }

    public componentDidMount() {
        const { activeFilters, fetchCompanies } = this.props;
        if (activeFilters.length > 0) {
            fetchCompanies(mapActiveFiltersToPayload(activeFilters));
        }
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        const {
            fetchAsyncInfo, fetchCompanySeatsAsyncInfo, companySeats,
            goToSeatsSelection, selectedCompany, redirectToOriginalUrl,
            selectCompanySeat,
        } = this.props;
        if (
            prevProps.fetchAsyncInfo.status === AsyncStatus.Busy &&
            fetchAsyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                isFilterPanelOpen: false,
            });
        }

        if (
            prevProps.fetchCompanySeatsAsyncInfo.status === AsyncStatus.Busy &&
            fetchCompanySeatsAsyncInfo.status === AsyncStatus.Success
        ) {
            if (companySeats.length === 1) {
                selectCompanySeat(companySeats[0]);
                redirectToOriginalUrl();
            } else {
                goToSeatsSelection(selectedCompany.companyCode);
            }
        }
    }

    private onItemSelected(itemId: string | number) {
        const { companies } = this.props;
        this.setState({
            selectedCompany: companies.find((company) => company.companyCode === itemId),
        });
    }

    private goToNextPage() {
        const { fetchCompanySeats } = this.props;
        const { selectedCompany } = this.state;

        fetchCompanySeats(selectedCompany);
    }

    private onFilterPanelCloseIntent() {
        this.setState({
            isFilterPanelOpen: false,
        });
    }

    private onSearchButtonClick() {
        if (!this.state.isFilterPanelOpen) {
            this.setState({
                isFilterPanelOpen: true,
            });
        }
    }

    private onRemoveActiveFilter(filterKey: keyof IFetchCompaniesPayload) {
        const { setSidebarSearchFilterUrlQueryParams, fetchCompanies, activeFilters } = this.props;
        const newFilter = mapActiveFiltersToPayload(activeFilters.filter((filter) => {
            return filter.filterKey !== filterKey;
        }));
        setSidebarSearchFilterUrlQueryParams(newFilter);
        fetchCompanies(newFilter);
    }

    private onFilterSubmit(filter: IFetchCompaniesPayload) {
        const { setSidebarSearchFilterUrlQueryParams, fetchCompanies } = this.props;
        this.setState({
            isFilterPanelOpen: false,
        });
        setSidebarSearchFilterUrlQueryParams(filter);
        fetchCompanies(filter);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const canAccessAllCompanies = hasPermission(state, Permission.CAN_ACCESS_ALL_COMPANIES);
        const { companyCodeOrNameFilter } = getQueryParams<IFetchCompaniesPayload>(state);
        const companies = canAccessAllCompanies && companyCodeOrNameFilter
            ? getCompanies(state) || []
            : getMyCompanies(state);
        return {
            companies,
            selectedCompany: getSelectedCompany(state) || getPrevSelectedCompany(state),
            showAllCompanies: canAccessAllCompanies,
            fetchAsyncInfo: getFetchCompaniesAsyncInfo(state),
            activeFilters: [
                {
                    filterKey: 'companyCodeOrNameFilter',
                    filterValue: companyCodeOrNameFilter,
                    filterNameTranslationKey: 'account.company_selection.active_filters.company_code_or_name',
                },
            ].filter((filter) => !!filter.filterValue) as IActiveFilter<IFetchCompaniesPayload>[],
            fetchCompanySeatsAsyncInfo: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state),
            companySeats: getCompanySeatsForSelectedCompany(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            goToSeatsSelection: (companyCode: string) => {
                const state = getState();
                const { redirectUrl } = getQueryParams(state);
                dispatch(navigateToSeatsSelection(companyCode, redirectUrl));
            },
            setSidebarSearchFilterUrlQueryParams: (filter: IFetchCompaniesPayload) => {
                dispatch(navigateTo(ROUTE_KEYS.R_COMPANY_SELECTION, {}, { ...filter }));
            },
            fetchCompanies: (filter: IFetchCompaniesPayload) => {
                // Only filter when an actual filter was provided
                if (filter.companyCodeOrNameFilter) {
                    dispatch(fetchCompanies(filter));
                }
            },
            fetchCompanySeats: (company: ICompanyDismissableWithDate) => {
                dispatch(setSelectedCompany(company));
                dispatch(fetchSelectedCompanySeatsAndDivisionsActions.trigger({}));
            },
            selectCompanySeat: (seat: ICompanySeat) => {
                const canSelectAllSeats = hasPermission(getState(), Permission.CAN_SELECT_ALL_SEATS);
                dispatch(setSelectedCompanySeat({
                    isAllSeatsSelected: canSelectAllSeats,
                    companySeat: seat,
                }));
            },
            redirectToOriginalUrl: () => {
                const state = getState();
                const { redirectUrl } = getQueryParams<{ redirectUrl: string }>(state);
                dispatch(redirectToUrl(redirectUrl));
            },
            setSearchFilterUrlQueryParams: (searchFilter: string) => {
                const state = getState();
                const currentQuery = getQueryParams(state);
                dispatch(navigateTo(
                    ROUTE_KEYS.R_COMPANY_SELECTION,
                    {
                        [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                    },
                    {
                        ...currentQuery,
                        searchFilter,
                    },
                ));
            },
        };
    },
})(CompanySelection);

function mapActiveFiltersToPayload(activeFilters: IActiveFilter<IFetchCompaniesPayload>[]) {
    return activeFilters.reduce(
        (accumulator, current) => {
            accumulator[current.filterKey as string] = current.filterValue;
            return accumulator;
        },
        {},
    ) as IFetchCompaniesPayload;
}
