import React from 'react';
import Wizard from '../../common/navigation/Wizard/index';
import { getFluVaccinesOrderWizardStepId } from '../../../redux/intervention/fluVaccine/selectors';
import { changeFluVaccinesOrderWizardStep } from '../../../redux/intervention/fluVaccine/actions';
import { IStepperStep } from '../../../models/general/stepper';
import { FLU_VACCINES_ORDER_WIZARD_STEP_ID } from '../../../models/interventions/fluVaccines';
import Amount from './wizard/Amount';
import Seat from './wizard/Seat';
import Delivery from './wizard/Delivery';
import Contact from './wizard/Contact';
import Remarks from './wizard/Remarks';
import Validate from './wizard/Validate';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';

const FLU_VACCINES_ORDER_STEPS: IStepperStep[] = [{
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.NR_OF_VACCINES,
    translationKey: 'interventions.flu_orders_new.steps.nr_of_vaccines.step_label',
    renderStepContent: (props) => <Amount {...props}/>,
}, {
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.SEAT,
    translationKey: 'interventions.flu_orders_new.steps.seat.step_label',
    renderStepContent: (props) => <Seat {...props}/>,
}, {
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.DELIVERY,
    translationKey: 'interventions.flu_orders_new.steps.delivery.step_label',
    renderStepContent: (props) => <Delivery {...props}/>,
}, {
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.CONTACT,
    translationKey: 'interventions.flu_orders_new.steps.contact.step_label',
    renderStepContent: (props) => <Contact {...props}/>,
}, {
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.REMARKS,
    translationKey: 'interventions.flu_orders_new.steps.remarks.step_label',
    renderStepContent: (props) => <Remarks {...props}/>,
}, {
    id: FLU_VACCINES_ORDER_WIZARD_STEP_ID.VALIDATE,
    translationKey: 'interventions.flu_orders_new.steps.validate.step_label',
    renderStepContent: (props) => <Validate {...props}/>,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.ORDER_FLU_VACCINES,
    steps: FLU_VACCINES_ORDER_STEPS,
});

export default function FluVaccinesOrderWizard() {
    return (
        <div className="FluVaccinesOrderWizard">
            <Wizard
                activeStepIdSelector={getFluVaccinesOrderWizardStepId}
                navigateToStepActionCreator={changeFluVaccinesOrderWizardStep}
                steps={FLU_VACCINES_ORDER_STEPS}
            />
        </div>
    );
}
