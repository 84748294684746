import React from 'react';
import './profile.scss';
import
ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../common/widget/ContentWithSidebar';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../../utils/core/string/anchor';
import Translate from '../../../common/Translate';
import PersonalData from './PersonalData';
import AccessLevels from './AccessLevels';
import connect from '../../../../utils/libs/redux/connect';
import { IUserProfile } from '../../../../models/auth/authentication';
import {
    getMyUserProfile,
    getDisplayName,
    getFetchMyUserAsyncInfo,
} from '../../../../redux/auth/selectors';
import { translateAccountTypeByUserProfile } from '../helpers/translateAccountType';
import { Permission } from '../../../../models/auth/authorisation';
import { getCompanySeatsForSelectedCompany } from '../../../../redux/company/selected/selectors';
import { ICompanySeat } from '../../../../models/admin/company';
import ROUTE_KEYS from '../../../../routeKeys';
import { getRouteKey } from '../../../../redux/location/selectors';
import Dialog from '../../../common/modals/Dialog';
import { navigateTo } from '../../../../redux/location/actions';
import UpdateAvatar from './UpdateAvatar/UpdateAvatar.component';
import AvatarImage from './AvatarImage';
import classNames from 'classnames';
import Notifications from './Notifications';
import { BASE_CLASS_NAME } from './common';
import TinyLoader from '../../../common/waiting/TinyLoader';

interface IPrivateProps {
    userProfile: IUserProfile;
    displayName: string;
    companySeats: ICompanySeat[];
    isUpdatingAvatar: boolean;
    updateAvatar: () => void;
    closeUpdateAvatar: () => void;
}

interface ITitleConfig {
    id: string;
    translationKey: string;
    content: React.ComponentType;
}

interface ITitlesConfig {
    [key: string]: ITitleConfig;
}

const TITLES_CONFIG: ITitlesConfig = {
    PERSONAL_DATA: {
        id: 'PERSONAL_DATA',
        translationKey: 'account.account_settings.profile.sidebar.personal_data',
        content: PersonalData,
    },
    ACCESS_LEVELS: {
        id: 'ACCESS_LEVELS',
        translationKey: 'account.account_settings.profile.sidebar.access_levels',
        content: AccessLevels,
    },
    NOTIFICATIONS: {
        id: 'NOTIFICATIONS',
        translationKey: 'account.account_settings.profile.sidebar.notifications',
        content: Notifications,
    },
};

function Profile(props: IPrivateProps) {
    if (!props.userProfile) {
        return null;
    }

    const { accessLevel } = props.userProfile;
    const canAcessAllSeats = accessLevel.extraPermissions.includes(Permission.CAN_SELECT_ALL_SEATS);
    return (
        <div className={BASE_CLASS_NAME}>
            <div className={`${BASE_CLASS_NAME}__detail`}>
                <TinyLoader asyncInfoSelector={getFetchMyUserAsyncInfo} showContentOnInitialState>
                    <AvatarImage
                        onClick={props.updateAvatar}
                        imageUrl={props.userProfile.avatarUrl}
                        imageAlt={props.displayName}
                    />
                </TinyLoader>
                <div className={`${BASE_CLASS_NAME}__detail__content`}>
                    <h4>{props.displayName}</h4>
                    <p>
                        <Translate msg={translateAccountTypeByUserProfile(props.userProfile)} /><br />
                        <Translate
                            msg={
                                `account.account_settings.profile.header.${canAcessAllSeats
                                    ? 'access_to_all_seats'
                                    : 'access_to_seats'}`
                            }
                            placeholders={{
                                seatsList: props.companySeats.map((item) => item.company.name).join(', '),
                            }}
                        />
                    </p>
                </div>
            </div>
            <ContentWithSidebar
                sidebar={SidebarContent}
                content={MainContent}
                titleIds={Object.values(TITLES_CONFIG).map((item) => item.id)}
            />
            <Dialog show={props.isUpdatingAvatar} onCloseIntent={props.closeUpdateAvatar}>
                <UpdateAvatar />
            </Dialog>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            userProfile: getMyUserProfile(state),
            displayName: getDisplayName(state),
            companySeats: getCompanySeatsForSelectedCompany(state),
            isUpdatingAvatar: getRouteKey(state) === ROUTE_KEYS.R_ACCOUNT_SETTINGS_UPDATE_AVATAR,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateAvatar: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_SETTINGS_UPDATE_AVATAR)),
            closeUpdateAvatar: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_SETTINGS)),
        };
    },
})(Profile);

const SidebarContent = ({ activeTitleId, setActiveTitleIdOverride }: IContentWithSidebarRenderProps) => (
    <nav className={`${BASE_CLASS_NAME}__sidebar`}>
        {Object.keys(TITLES_CONFIG).map((key, index) => {
            const titleConfig: ITitleConfig = TITLES_CONFIG[key];
            const titleClass = classNames({
                active: activeTitleId === titleConfig.id,
            });
            return (
                <SideBarLink
                    key={`profile_sidebar_${key}`}
                    href={toAnchorLinkHref(titleConfig.id)}
                    className={titleClass}
                    setActiveTitleIdOverride={() => setActiveTitleIdOverride(titleConfig.id)}
                >
                    <Translate msg={titleConfig.translationKey} />
                </SideBarLink>
            );
        })}
    </nav>
);

const MainContent = (
    <div className={`${BASE_CLASS_NAME}__content`}>
        {Object.keys(TITLES_CONFIG).map((key) => (
            <ContentBlock
                key={`profile_content_${key}`}
                titleConfig={TITLES_CONFIG[key]}
            />
        ))}
    </div>
);

function ContentBlock({ titleConfig }: { titleConfig: ITitleConfig }) {
    const Content = titleConfig.content;

    return (
        <>
            <h4 id={toAnchorTargetId(titleConfig.id)}>
                <Translate msg={titleConfig.translationKey} />
            </h4>
            <div className="ContentData">
                <Content />
            </div>
        </>
    );
}
