import React, { PureComponent, ChangeEvent, ReactNode, ReactElement } from 'react';
import List from '../List';
import ListWithSorting from '../ListWithSorting';
import Radiobutton from '../../input/Radiobutton';
import './list-with-radio-button-select.scss';
import { ListColumns, ListItem, ISortedColumn, SortOrder, ListItemId } from '../../../../models/general/list';
import classNames from 'classnames';
import { IListFooterProps } from '../ListFooter';
import TooltipWithIcon from '../../widget/TooltipWithIcon';
import { IPublicProps as ITranslateProps } from '../../Translate';
import Icon from '../../icons/Icon';

export interface IListWithRadioButtonSelectProps<ColumnNames> {
    name: string;
    withSorting: boolean;
    columns: ListColumns<ColumnNames>;
    items: ListItem<ColumnNames>[];
    selectedItemId: (string | number);
    onItemSelected: (id: string | number) => void;
    noResultsMessage?: ReactNode;
    errorMessage?: ReactNode;
    fadeOutListItems?: boolean;
    footer?: ReactElement<IListFooterProps>;
    maxNrOfRecordsToShow?: number;
    isItemClickable?: (item: ListItem<ColumnNames>) => boolean; // only has effect if 'onItemRowClicked' provided
    getCustomRowClasses?: (listItem: ListItem<ColumnNames>) => string;

    // Only when withSorting = true
    initialSort?: ISortedColumn<ColumnNames>;

    // Only when withSorting = false
    sortedColumn?: ISortedColumn<ColumnNames>;
    onColumnSortChanged?: (nextSortedColumn: ISortedColumn<ColumnNames>) => void;

    idToScrollIntoView?: ListItemId;
    doNotRenderRadioButtonCondition?: (listItem: ListItem<ColumnNames>) => boolean;
    doNotRenderRadioButtonConditionReason?: string | ReactElement<ITranslateProps>;
}

type ExtendedColumns<ColumnNames> = ListColumns<ColumnNames & { selectCheckbox: string }>;

interface IState<ColumnNames> {
    columns: ExtendedColumns<ColumnNames>;
}

class ListWithRadioButtonSelect<ColumnNames>
    extends PureComponent<IListWithRadioButtonSelectProps<ColumnNames>, IState<ColumnNames>> {

    constructor(props: IListWithRadioButtonSelectProps<ColumnNames>) {
        super(props);

        this.state = {
            columns: {
                selectCheckbox: {
                    render: (listItem) => this.renderRadioButton(props.name, listItem),
                },
                ...(props.columns as object),
            } as ExtendedColumns<ColumnNames>,
        };

        this.onItemRowClicked = this.onItemRowClicked.bind(this);
        this.onRadioButtonClick = this.onRadioButtonClick.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IListWithRadioButtonSelectProps<ColumnNames>) {
        if (nextProps.columns !== this.props.columns) {
            this.setState({
                columns: {
                    selectCheckbox: {
                        render: (listItem) => this.renderRadioButton(nextProps.name, listItem),
                    },
                    ...(nextProps.columns as object),
                } as ExtendedColumns<ColumnNames>,
            });
        }
        // Remove selected items which are not in the items list anymore
        if (
            nextProps.selectedItemId &&
            this.props.items !== nextProps.items
        ) {
            const itemsIds = nextProps.items.map((item) => item.id);
            const isSelectedItemInList = itemsIds.includes(nextProps.selectedItemId);

            if (!isSelectedItemInList) {
                nextProps.onItemSelected(null);
            }
        }
    }

    public render() {
        const {
            name, items,
            selectedItemId, onColumnSortChanged,
            sortedColumn, withSorting, noResultsMessage,
            errorMessage, fadeOutListItems, footer,
            idToScrollIntoView, maxNrOfRecordsToShow, getCustomRowClasses,
            isItemClickable,
        } = this.props;
        const { columns } = this.state;

        // If initial sort is not defined sort on the second column
        // This override is needed as we inject a column (selectCheckbox)
        // and we don't want this to be used for initial sorting
        const columnNames = Object.keys(columns);
        const initialSort = this.props.initialSort || (withSorting && columnNames.length > 1 && {
            name: columnNames[1],
            sortOrder: SortOrder.Ascending,
        } as ISortedColumn<ColumnNames> || null);

        return (
            <div className="ListWithRadioButtonSelect">
                {withSorting ?
                    <ListWithSorting
                        name={name}
                        columns={columns}
                        initialSort={initialSort}
                        items={items}
                        onItemRowClicked={this.onItemRowClicked}
                        selectedItemIds={[selectedItemId]}
                        getTableCellClasses={this.getTableCellClasses}
                        noResultsMessage={noResultsMessage}
                        errorMessage={errorMessage}
                        fadeOutListItems={fadeOutListItems}
                        footer={footer}
                        idToScrollIntoView={idToScrollIntoView}
                        maxNrOfRecordsToShow={maxNrOfRecordsToShow}
                        getCustomRowClasses={getCustomRowClasses}
                        isItemClickable={isItemClickable}
                    /> :
                    <List
                        name={name}
                        columns={columns}
                        items={items}
                        sortedColumn={sortedColumn}
                        onItemRowClicked={this.onItemSelected}
                        selectedItemIds={[selectedItemId]}
                        onColumnSortChanged={onColumnSortChanged}
                        getTableCellClasses={this.getTableCellClasses}
                        noResultsMessage={noResultsMessage}
                        errorMessage={errorMessage}
                        fadeOutListItems={fadeOutListItems}
                        footer={footer}
                        idToScrollIntoView={idToScrollIntoView}
                        maxNrOfRecordsToShow={maxNrOfRecordsToShow}
                        getCustomRowClasses={getCustomRowClasses}
                        isItemClickable={isItemClickable}
                    />
                }
            </div>
        );
    }

    private onItemRowClicked(id: string) {
        const { items, doNotRenderRadioButtonCondition } = this.props;

        if (typeof doNotRenderRadioButtonCondition === 'function') {
            const listItem = items.find((item) => item.id === id);
            if (doNotRenderRadioButtonCondition(listItem)) {
                return; // no row click
            }
        }

        this.onItemSelected(id);
    }

    private onRadioButtonClick(e: ChangeEvent<HTMLInputElement>) {
        this.props.onItemSelected(e.target.value);
    }

    private onItemSelected(id: string) {
        this.props.onItemSelected(id);
    }

    private renderRadioButton(listName: string, listItem: ListItem<ColumnNames>) {
        const { selectedItemId, doNotRenderRadioButtonCondition, doNotRenderRadioButtonConditionReason } = this.props;

        if (typeof doNotRenderRadioButtonCondition === 'function' && doNotRenderRadioButtonCondition(listItem)) {
            return doNotRenderRadioButtonConditionReason ? (
                <TooltipWithIcon
                    icon={<Icon
                        typeName="info"
                        circle
                    />}
                    typeName="info-bubble"
                    iconSize="small"
                >
                    {doNotRenderRadioButtonConditionReason}
                </TooltipWithIcon>
            ) : (
                <span/>
            );
        }

        return (
            <Radiobutton
                name={`list-select-${listName}`}
                value={listItem.id}
                onChange={this.onRadioButtonClick}
                checked={selectedItemId === listItem.id}
            />
        );
    }

    private getTableCellClasses(columnKey: string) {
        const classes = classNames({
            selectCheckbox: columnKey === 'selectCheckbox',
        });
        return classes;
    }
}

export default ListWithRadioButtonSelect;
