import { CustomLocale } from 'flatpickr/dist/types/locale';

import { ILocalePayload } from '../../../models/general/i18n';

import localeToFlatpickrLocale from './localeToFlatpickrLocale';

interface IOptions extends ILocalePayload {
    month: number;
    year: number;
    minDate?: Date;
    maxDate?: Date;
}

export default function getDaysInMonthView({
    month,
    year,
    locale,
    minDate,
    maxDate,
}: IOptions): Date[] {
    const flatpickLocale = localeToFlatpickrLocale(locale);
    const firstOfMonth =
        (new Date(year, month, 1).getDay() - flatpickLocale.firstDayOfWeek + 7) % 7;

    const prevMonthDays = getDaysInMonth((month - 1 + 12) % 12, year, flatpickLocale);

    const daysInMonth = getDaysInMonth(month, year, flatpickLocale);
    const days: Date[] = [];

    const appendDateToList = (date: Date) => {
        if (minDate && date.getTime() < minDate.getTime()) {
            return;
        }
        if (maxDate && date.getTime() > maxDate.getTime()) {
            return;
        }
        days.push(date);
    };

    let dayNumber = prevMonthDays + 1 - firstOfMonth;
    let dayIndex = 0;

    // prepend days from the ending of previous month
    for (; dayNumber <= prevMonthDays; dayNumber += 1, dayIndex += 1) {
        appendDateToList(new Date(year, month - 1, dayNumber));
    }

    // Start at 1 since there is no 0th day
    for (dayNumber = 1; dayNumber <= daysInMonth; dayNumber += 1, dayIndex += 1) {
        appendDateToList(new Date(year, month, dayNumber));
    }

    // append days from the next month
    for (
        let dayNum = daysInMonth + 1;
        dayNum <= 42 - firstOfMonth;
        dayNum += 1, dayIndex += 1
    ) {
        appendDateToList(new Date(year, month + 1, dayNum % daysInMonth));
    }

    return days;
}

function getDaysInMonth(month: number, year: number, locale: CustomLocale): number {
    if (month === 1 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
        return 29;
    }

    return locale.daysInMonth[month];
}
