import { ListItem } from '../../models/general/list';

export function findListItemByColumnValue<ColumnNames>(
    allListItems: ListItem<ColumnNames>[],
    searchColumnName: keyof ColumnNames,
    searchColumnValue: string,
) {
    return allListItems
        .find((listItem) => listItem.columns[searchColumnName].toString() === searchColumnValue);
}

export function getColumnValueFromListItemThatMatchesSearchColumn<ColumnNames>(
    allListItems: ListItem<ColumnNames>[],
    searchColumnName: keyof ColumnNames,
    searchColumnValue: string,
    columnNameToReturn: keyof ColumnNames,
): string {
    const matchingListItem = findListItemByColumnValue<ColumnNames>(allListItems, searchColumnName, searchColumnValue);

    return matchingListItem ? matchingListItem.columns[columnNameToReturn] as string : undefined;
}
