import {
    findFirstFocusableElement, findNextFocusableElement,
    focusIsInContainer, findLastFocusableElement, findPreviousFocusableElement,
} from '../../../../utils/dom/focus';
import React, { PureComponent } from 'react';
import { isKeyPressed } from '../../../../utils/keyboard';
import isEmptyObject from '../../../../utils/core/object/isEmptyObject';

interface IContainFocusProps {
    hasFocus: boolean;
    priority: number;
    containerTofocus?: string;
}

const activeElements: {[key: number]: () => IContainFocusProps} = {};

/**
 * Responsible for making sure that the user cannot focus any
 * elements outside of the borders of this component (eg when using the Tab key)
 */
export default class ContainFocus extends PureComponent<IContainFocusProps> {
    private container: React.RefObject<HTMLSpanElement>;
    private previousFocusedElement: HTMLElement;
    private setFocusTimeout: number;
    private elementId: number;

    constructor(props: IContainFocusProps) {
        super(props);

        this.container = React.createRef();

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    public componentDidMount() {
        this.elementId = this.calculateElementId() as number;
        activeElements[this.elementId] = () => this.props;

        window.addEventListener('keydown', this.onKeyDown);
    }

    public componentDidUpdate(prevProps: IContainFocusProps) {
        if (this.otherContainFocusElementHasFocus()) {
            return;
        }

        if (this.container.current && !prevProps.hasFocus && this.props.hasFocus) {
            this.previousFocusedElement = document.activeElement as HTMLElement;
            this.setFocusToContainer();
        } else if (this.previousFocusedElement && prevProps.hasFocus && !this.props.hasFocus) {
            this.restoreFocus();
        } else {
            this.validateIfFocusIsInContainer();
        }
    }

    public componentWillUnmount() {
        this.clearFocusTimeout();
        this.clearActiveContainFocusElement();

        window.removeEventListener('keydown', this.onKeyDown);
    }

    public render() {
        return <span ref={this.container}>{this.props.children}</span>;
    }

    private setFocusToContainer() {
        this.clearFocusTimeout();

        let elementToFocus = null;

        // Check if we need to focus on an element in a given container.
        if (this.props.containerTofocus) {
            elementToFocus = findFirstFocusableElement(document.getElementById(this.props.containerTofocus));
        }

        if (!elementToFocus) {
            elementToFocus = this.container.current;
        }

        if (elementToFocus) {
            this.setFocusTimeout = window.setTimeout(
                () => {
                    elementToFocus.focus();
                },
                100);
        }
    }

    private restoreFocus() {
        this.clearFocusTimeout();

        const elementToFocus = this.gotFocusFromButtonOrLink()
            ? this.previousFocusedElement
            : findNextFocusableElement(this.previousFocusedElement);
        if (elementToFocus) {
            elementToFocus.focus();
        }
    }

    private validateIfFocusIsInContainer() {
        if (this.props.hasFocus && !focusIsInContainer(this.container.current)) {
            this.setFocusToContainer();
        }
    }

    private onKeyDown(e: KeyboardEvent) {
        if (!this.props.hasFocus || this.otherContainFocusElementHasFocus()) {
            return;
        }

        if (isKeyPressed(e, 'Tab')) {
            const containerEl = this.container.current;
            const targetEl = e.target as HTMLElement;
            const elementReceivingFocus = e.shiftKey
                ? findPreviousFocusableElement(targetEl, document.body)
                : findNextFocusableElement(targetEl, document.body);
            if (!focusIsInContainer(containerEl, elementReceivingFocus)) {
                e.preventDefault();
                const elementToFocus = e.shiftKey
                    ? findLastFocusableElement(containerEl)
                    : findFirstFocusableElement(containerEl);
                if (elementToFocus) {
                    elementToFocus.focus();
                }
            }
        }
    }

    private clearFocusTimeout() {
        if (this.setFocusTimeout) {
            window.clearTimeout(this.setFocusTimeout);
        }
    }

    private gotFocusFromButtonOrLink() {
        if (this.previousFocusedElement) {
            return (
                !!this.previousFocusedElement.getAttribute('href') ||
                this.previousFocusedElement.nodeName.toLowerCase() === 'button'
            );
        }
        return false;
    }

    private calculateElementId() {
        if (isEmptyObject(activeElements)) {
            return 0;
        }

        const highestElementId =
            Object.keys(activeElements).reduce((a, b) => activeElements[a] > activeElements[b] ? a : b);

        return Number(highestElementId) + 1;
    }

    private otherContainFocusElementHasFocus() {
        if (isEmptyObject(activeElements)) {
            return false;
        }

        return Object.keys(activeElements).find((key) => {
            const value = activeElements[key]();
            return value.priority > this.props.priority && value.hasFocus;
        });
    }

    private clearActiveContainFocusElement() {
        if (activeElements[this.elementId]) {
            delete activeElements[this.elementId];
        }
    }
}
