import { ITraceableApiError } from '../../../models/general/error';
import generateErrorId from './generateErrorId';

export function createNotFoundError(): ITraceableApiError {
    return {
        id: generateErrorId(),
        code: 404,
        message: 'klantenzone.error.not_found',
        extraData: {},
        requestMethod: null,
        wasCancelled: false,
        type: 'api',
    };
}
