import React, { DragEvent, PureComponent } from 'react';
import './top-task-tile.scss';
import classNames from 'classnames';
import Translate from '../../../../common/Translate';
import Icon from '../../../../common/icons/Icon';
import { ITopTask, TaskId } from '../../../../../models/user/topTasks';
import LazyLoadSVG from '../../../../common/widget/LazyLoadSVG';
import { SVG_GROUP_NAME } from '../../../../../models/general/lazyLoadSvg';

interface ITopTaskTileProps {
    task: ITopTask;
    onChange: (taskId: TaskId) => void;
    dirty: boolean;
}

interface ITopTaskTileState {
    hover: boolean;
}

const CLASS_NAME = 'TopTaskTile';

class TopTaskTile extends PureComponent<ITopTaskTileProps, ITopTaskTileState> {

    constructor(props: ITopTaskTileProps) {
        super(props);

        this.state = {
            hover: false,
        };

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    public render() {
        const { dirty, task } = this.props;
        const { hover } = this.state;
        const classes = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--dirty`]: dirty,
            [`${CLASS_NAME}--hover`]: hover,
        });

        return (
            <li
                className={classes}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
            >
                <LazyLoadSVG id={task.lazyLoadSvgId} group={SVG_GROUP_NAME.TOP_TASKS} />
                <span><Translate msg={task.translationKey} /></span>
                <Icon typeName="plus"/>
            </li>
        );
    }

    private onDragOver(ev: DragEvent<HTMLLIElement>) {
        ev.preventDefault();
    }

    private onDragEnter(ev: DragEvent<HTMLLIElement>) {
        this.setState({
            hover: true,
        });
    }

    private onDragLeave() {
        this.setState({
            hover: false,
        });
    }

    private onDrop(ev: DragEvent<HTMLLIElement>) {
        // Make sure if we drop something on the page we don't navigate away
        ev.preventDefault();

        const { onChange } = this.props;
        const id = ev.dataTransfer.getData('Text') as TaskId;

        onChange(id);
        ev.dataTransfer.clearData();

        return false;
    }
}

export default TopTaskTile;
