import {
    FETCH_MEDICAL_DOCUMENTS, FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK,
} from './types';
import { createTypeActions } from '../../../utils/libs/redux/createAction';
import {
    IFetchMedicalDocumentFilesInBulkPayload,
    IFetchMedicalDocumentsPayload,
    IMedicalDocument,

} from '../../../models/documentCenter/medicalDocuments';

// Fetch medical documents
export const fetchMedicalDocumentsActions = createTypeActions<IFetchMedicalDocumentsPayload, IMedicalDocument[]>({
    type: FETCH_MEDICAL_DOCUMENTS,
});

// Medical document get document and file
export const fetchMedicalDocumentFilesInBulkActions =
    createTypeActions<IFetchMedicalDocumentFilesInBulkPayload>({
        type: FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK,
    });
