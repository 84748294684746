import React, { PureComponent } from 'react';
import './legal-cookies-pagepart.scss';
import { getCookiesConfiguration, getCookiesConfigurationAsyncInfo } from '../../../../../redux/legal/selectors';
import connect from '../../../../../utils/libs/redux/connect';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import {
    ICMSCookieConfiguration, ICMSCookiesConfiguration,
} from '../../../../../models/general/cookieConsent';
import CookieType, { ICookieType } from './cookieType';
import { fetchCookiesConfigurationActions } from '../../../../../redux/legal/actions';
import { isFalsyOrEmptyObject } from '../../../../../utils/core/object/isEmptyObject';
import { LEGAL_COOKIES_PAGEPART_CLASS_NAME as CLASS_NAME } from './shared';

interface IPrivateProps {
    cookiesConfiguration: ICMSCookiesConfiguration;
    cookiesConfigurationAsyncInfo: IAsyncFieldInfo;
    fetchCookiesConfiguration: () => void;
}

class LegalCookiesPagePart extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public componentDidMount() {
        const { cookiesConfigurationAsyncInfo, fetchCookiesConfiguration } = this.props;

        if (cookiesConfigurationAsyncInfo.status === AsyncStatus.Initial) {
            fetchCookiesConfiguration();
        }
    }

    public render() {
        const { cookiesConfiguration } = this.props;

        if (!cookiesConfiguration || isFalsyOrEmptyObject(cookiesConfiguration.cookies)) {
            return null;
        }

        const cookiesByType = getCookiesByType(cookiesConfiguration.cookies);

        return (
            <div className={CLASS_NAME}>
                {cookiesByType.map((cookieType) => <CookieType key={cookieType.id} cookieType={cookieType} />)}
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            cookiesConfiguration: getCookiesConfiguration(state),
            cookiesConfigurationAsyncInfo: getCookiesConfigurationAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            fetchCookiesConfiguration: () => dispatch(fetchCookiesConfigurationActions.trigger({})),
        };
    },
})(LegalCookiesPagePart);

function getCookiesByType(cookies: ICMSCookieConfiguration[]) {
    return Object.values(cookies.reduce(
        (acc, cookie) => {
            const result = acc;
            result[cookie.type.id] = acc[cookie.type.id] || {
                ...cookie.type,
                cookies: [],
            };

            result[cookie.type.id].cookies.push({
                name: cookie.name,
                description: cookie.description,
            });

            return result;
        },
        {} as { [key: number]: ICookieType },
    ));
}
