/**
 * Do not directly import this file, but use the 'routes.ts' at the top folder
 * so that we avoid circular dependencies !!!!
 */

import { IRoutes } from '../utils/routing/typings';
import { setRoutes } from '../routes';

import ACCOUNT_ROUTES from './account/routes';
import ADMINISTRATION_ROUTES from './administration/routes';
import AUTH_ROUTES from './auth/routes';
import CONTACT_ROUTES from './contact/routes';
import DOCUMENT_CENTER_ROUTES from './documentCenter/routes';
import ERROR_ROUTES from './error/routes';
import HOME_ROUTES from './home/routes';
import INTERVENTIONS_ROUTES from './interventions/routes';
import PLANNING_ROUTES from './planning/routes';
import ONBOARDING_ROUTES from './onboarding/routes';

// Use the following syntax if you want the component to be loaded asynchronously (bundled in separate chunk):
// - for a stateless component (export function) :
//     page: () => import(/* webpackChunkName: "?" */ './relative/path')
//
// - for a component with state (export class) :
//     page: () => import(/* webpackChunkName: "?" */ './relative/path')

const ALL_ROUTES: IRoutes = {
    ...ACCOUNT_ROUTES,
    ...ADMINISTRATION_ROUTES,
    ...AUTH_ROUTES,
    ...CONTACT_ROUTES,
    ...DOCUMENT_CENTER_ROUTES,
    ...ERROR_ROUTES,
    ...HOME_ROUTES,
    ...INTERVENTIONS_ROUTES,
    ...ONBOARDING_ROUTES,
    ...PLANNING_ROUTES,
};

setRoutes(ALL_ROUTES);

export default ALL_ROUTES;
