import isFunction from '@snipsonian/core/es/is/isFunction';
import { VISIBILITY_BROWSER_SPECIFICS, isSupported } from './visibilityBrowserSpecifics';

enum VISIBILITY_STATE {
    visible = 'visible',
    hidden = 'hidden',
    prerender = 'prerender', // not supported by all browsers
    unloaded = 'unloaded', // not supported by all browsers
}

export function isBrowserTabActive() {
    if (!isSupported) {
        return true;
    }

    return !document[VISIBILITY_BROWSER_SPECIFICS.hidden];
}

export function getBrowserTabVisibilityState(): VISIBILITY_STATE {
    return document[VISIBILITY_BROWSER_SPECIFICS.visibilityState];
}

/**
 * p.s. The tab's visibility will, at least in chrome, not change when another browser window becomes active.
 * This will only be triggered when:
 * a) the user switches between tabs within a single browser window
 * b) the user minifies/un-minifies the browser window containing the active tab
 */
export function onBrowserTabVisibilityChange({
    onActivatedHandler,
    onDeactivatedHandler,
}: {
    onActivatedHandler?: () => void;
    onDeactivatedHandler?: () => void;
}) {
    if (!isSupported) {
        return;
    }

    document.addEventListener(VISIBILITY_BROWSER_SPECIFICS.event, () => {
        if (isBrowserTabActive()) {
            if (isFunction(onActivatedHandler)) {
                onActivatedHandler();
            }
        } else {
            if (isFunction(onDeactivatedHandler)) {
                onDeactivatedHandler();
            }
        }
    });
}
