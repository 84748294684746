/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export default function getWeekNumber(date: Date) {
    const clonedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = clonedDate.getUTCDay() || 7;
    clonedDate.setUTCDate(clonedDate.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(clonedDate.getUTCFullYear(), 0, 1));
    const timeDiff = clonedDate.getTime() - yearStart.getTime();
    return Math.ceil(((timeDiff / 86400000) + 1) / 7);
}
