import React from 'react';
import './finish-planning-dialog.scss';
import { connect } from '../../../../..';
import { navigateTo } from '../../../../../../redux/location/actions';
import { resetPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';
import Dialog from '../../../../../common/modals/Dialog';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button';
import { getWizardExitLinkActionMemoized } from '../../../../../../redux/ui/history/selectors';

const CLASS_NAME = 'FinishPlanningDialog';

interface IFinishPlanningDialogProps {
    open: boolean;
    onClose: () => void;
    downloadAmount: number;
    multipleConvocationsCreated?: boolean;
}

interface IPrivateProps {
    navigateToPlanAnother: () => void;
    navigateToPlanningFinished: () => void;
}

function FinishPlanningDialog(props: IFinishPlanningDialogProps & IPrivateProps) {
    const {
        open, onClose,
        navigateToPlanAnother, navigateToPlanningFinished,
        downloadAmount, multipleConvocationsCreated,
    } = props;

    const noDownload = downloadAmount <= 0;
    const multipleDownloads = downloadAmount > 1;

    return (
        <Dialog
            show={open}
            onCloseIntent={onClose}
            disableClosing={true}
            type="success"
            header={multipleConvocationsCreated ?
                'interventions.medical_examinations.new.steps.finish_planning.dialog_title_multiple' :
                'interventions.medical_examinations.new.steps.finish_planning.dialog_title'}
        >
            <div className={CLASS_NAME}>
                {!noDownload && (
                    <p>
                        <Translate
                            msg={multipleDownloads ?
                                'interventions.medical_examinations.new.steps.finish_planning.download_multiple' :
                                'interventions.medical_examinations.new.steps.finish_planning.download'}
                            placeholders={multipleDownloads ? { amount: downloadAmount } : {}}
                        />
                    </p>
                )}
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="notify-employee-plan-another-button"
                        typeName="secondary"
                        onClick={navigateToPlanAnother}
                    >
                        <Translate
                            msg="interventions.medical_examinations.new.steps.finish_planning.plan_again"
                        />
                    </Button>
                    <Button
                        id="notify-employee-finish-planning-button"
                        typeName="secondary"
                        onClick={navigateToPlanningFinished}
                    >
                        <Translate
                            msg="interventions.medical_examinations.new.steps.finish_planning.end"
                        />
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch, getState) => {
        return {
            navigateToPlanAnother: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN));
                dispatch(resetPlanMedicalExaminationWizardEntity());
            },
            navigateToPlanningFinished: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
                dispatch(resetPlanMedicalExaminationWizardEntity());
            },
        };
    },
})(FinishPlanningDialog);
