import convertDataUrlToBlob from './convertDataUrlToBlob';

export default function createFileFromBase64(base64: string, fileName: string) {
    const blob = convertDataUrlToBlob(base64);
    try {
        return new File([blob], fileName);
    } catch (err) {
        // Workaround for browser not supporting the File constructor
        return blobToFile(blob, fileName);
    }
}

function blobToFile(blob: Blob, fileName: string): File {
    const file: any = blob;
    // A Blob() is almost a File() -
    // it's just missing the two properties below which we will add
    file.lastModifiedDate = new Date();
    file.name = fileName;
    return file;
}
