import { object } from 'yup';
import string from '../../../../../../utils/libs/yup/string';

export const fields = {
    email: 'email',
};

export const schema = object({
    [fields.email]: string().email().required(),
});
