import { get, IResponseMapper, IResponseType } from '../../utils/api/requestWrapper';
import {
    IOpenInvoicesCount,
    IFetchInvoicesPayload,
    IInvoicesFilter,
    IInvoice,
    IFetchInvoicePayload,
    IInvoiceParameters,
    IFetchInvoiceParametersRequestPayload,
} from '../../models/admin/invoices';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow, monthOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';
import { ONE_MINUTE } from '../../utils/core/time/periodsInMillis';

export const URL = {
    INVOICES: '/companies/{companyCode}/invoices',
    INVOICES_COUNT: '/companies/{companyCode}/invoices/count',
    INVOICE_DOCUMENT: '/invoices/document',
    INVOICE_DETAIL: '/invoices/detail',
    INVOICE_FULL: '/invoices/full',
    INVOICE_PARAMETERS: '/companies/{companyCode}/tariffs/{year}',
};

export function fetchInvoices(
    { companyCode, showFullFamily, type }: IFetchInvoicesPayload,
    {
        startDate = DEFAULT_OPEN_INVOICES_FILTERS.startDate,
        endDate = DEFAULT_OPEN_INVOICES_FILTERS.endDate,
    }: IInvoicesFilter,
) {
    return get<IInvoice[]>({
        url: URL.INVOICES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
            type,
        },
        mapResponse: (response) => response.invoices,
    });
}

export function fetchInvoicesCount(
    { companyCode, showFullFamily, type }: IFetchInvoicesPayload,
) {
    return get<IOpenInvoicesCount>({
        url: URL.INVOICES_COUNT,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate: DEFAULT_OPEN_INVOICES_FILTERS.startDate,
            endDate: DEFAULT_OPEN_INVOICES_FILTERS.endDate,
            showFullFamily: showFullFamily.toString(),
            type,
        },
    });
}

export const DEFAULT_OPEN_INVOICES_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-3)),
    endDate: formatDateForBackend(monthOffsetFromNow(1)),
};

export const DEFAULT_PAID_INVOICES_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-1)),
    endDate: formatDateForBackend(monthOffsetFromNow(1)),
};

export function fetchInvoiceDocument({ invoiceIds, delayed }: IFetchInvoicePayload) {
    return get<IDocument>({
        responseType: IResponseType.blob,
        url: URL.INVOICE_DOCUMENT,
        timeoutInMillis: 5 * ONE_MINUTE,
        queryParams: {
            invoiceIds: invoiceIds.join(','),
            delayed,
        },
        mapResponse: initMapResponseThatReturnsDocumentIfNotDelayed({
            delayed,
            defaultFilename: 'invoiceDocument.pdf',
        }),
    });
}

export function fetchInvoiceDetail({ invoiceIds, delayed }: IFetchInvoicePayload) {
    return get<IDocument>({
        responseType: IResponseType.blob,
        url: URL.INVOICE_DETAIL,
        timeoutInMillis: 5 * ONE_MINUTE,
        queryParams: {
            invoiceIds: invoiceIds.join(','),
            delayed,
        },
        mapResponse: initMapResponseThatReturnsDocumentIfNotDelayed({
            delayed,
            defaultFilename: 'invoiceDetail.xlsx',
        }),
    });
}

export function fetchInvoiceFull({ invoiceIds, delayed }: IFetchInvoicePayload) {
    return get<IDocument>({
        responseType: IResponseType.blob,
        url: URL.INVOICE_FULL,
        timeoutInMillis: 5 * ONE_MINUTE,
        queryParams: {
            invoiceIds: invoiceIds.join(','),
            delayed,
        },
        mapResponse: initMapResponseThatReturnsDocumentIfNotDelayed({
            delayed,
            defaultFilename: 'invoiceFull.xlsx',
        }),
    });
}

function initMapResponseThatReturnsDocumentIfNotDelayed({
    delayed,
    defaultFilename,
}: {
    delayed: boolean;
    defaultFilename: string;
}): IResponseMapper<IDocument> {
    return (response, headers) => {
        if (delayed) {
            return null as IDocument;
        }

        return {
            data: response,
            filename: getFilenameFromHeaders(headers, defaultFilename),
        };
    };
}

export function fetchInvoiceParameters({ companyCode, year }: IFetchInvoiceParametersRequestPayload) {
    return get<IInvoiceParameters[]>({
        url: URL.INVOICE_PARAMETERS,
        pathParams: {
            companyCode,
            year,
        },
        mapResponse: (response) => response.tariffs,
    });
}
