import React, { ReactNode } from 'react';
import './icon-header.scss';
import Icon from '../../icons/Icon';
import { TIconTypeName } from '../../../../models/general/icon';

interface IIconHeaderProps {
    children: ReactNode;
    iconTypeName: TIconTypeName;
}

export default function IconHeader(props: IIconHeaderProps) {
    return (
        <h2 className="IconHeader">
            <Icon typeName={props.iconTypeName} />
            <span>{props.children}</span>
        </h2>
    );
}
