import { IRoutes } from '../../utils/routing/typings';
import { pathToAction, Meta } from 'redux-first-router';
import querystring from 'query-string';

export default function urlToLocationAction(url: string, routes: IRoutes) {
    const action = pathToAction(url, routes, querystring);

    return {
        type: action.type,
        payload: action.payload,
        meta: action.meta as Meta,
    };
}
