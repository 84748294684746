import React from 'react';
import { connect } from '../../../index';
import {
    getSelectedSeatCompanyCode,
} from '../../../../redux/company/selected/selectors';
import {
    getCompanyDetailAsyncInfo,
    getCompanyDetail,
    getUpdateCompanySingleFieldAsyncInfo,
} from '../../../../redux/company/info/selectors';
import { updateCompanySingleField } from '../../../../redux/company/info/actions';
import { ICompanyDetail, ICompanyDetailSingleFieldUpdate } from '../../../../models/admin/companyInfo';
import { schema, fields, IFormValues } from './updateCompanyContactDataSchema';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { ConfirmableTextPropertyInput } from '../../../common/input/ConfirmablePropertyInput';
import TinyLoader from '../../../common/waiting/TinyLoader';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import {
    tryFormattingPhoneForBackend,
    tryFormattingPhoneInternational,
} from '../../../../utils/formatting/formatPhone';

const CLASS_NAME = 'ContactData';
const FORM_NAME = 'edit-contact-data-form';

interface IPrivateProps {
    companyCode: string;
    companyDetail: ICompanyDetail;
    mayEdit: boolean;
    updateCompanyField: (companyCode: string, fieldName: keyof IFormValues, value: string) => void;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            companyCode: getSelectedSeatCompanyCode(state),
            companyDetail: getCompanyDetail(state),
            mayEdit: hasRequiredAccessLevels(state, { company: 'W' }),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateCompanyField: (companyCode: string, fieldName: keyof IFormValues, value: string) => {
                const formattedValue = fieldName === 'phone' || fieldName === 'fax' ?
                    tryFormattingPhoneForBackend(value) : value;
                dispatch(updateCompanySingleField({
                    requestId: getRequestId(companyCode, fieldName),
                    companyCode,
                    companyData: {
                        [fieldName]: formattedValue,
                    },
                }));
            },
        };
    },
})(ContactData);

function ContactData(props: IPrivateProps) {
    return (
        <div className={CLASS_NAME}>
            <TinyLoader asyncInfoSelector={getCompanyDetailAsyncInfo} >
                <EditableDataFields {...props} />
            </TinyLoader>
        </div>
    );
}

function EditableDataFields(props: IPrivateProps) {
    const { companyCode, companyDetail, mayEdit, updateCompanyField } = props;

    if (!companyDetail) {
        return null;
    }

    const INITIAL_VALUES: ICompanyDetailSingleFieldUpdate = {
        email: companyDetail.email,
        phone: tryFormattingPhoneInternational(companyDetail.phone),
        fax: tryFormattingPhoneInternational(companyDetail.fax),
    };

    return (
        <Form
            name={FORM_NAME}
            initialValues={INITIAL_VALUES}
            schema={schema}
            render={(formRenderProps: IFormRenderProps<ICompanyDetailSingleFieldUpdate>) => {
                return (
                    <>
                        <ConfirmableTextPropertyInput
                            id="email"
                            name={fields.email}
                            labelKey="administration.company_info.general.contact_data.fields.email"
                            readonly={!mayEdit}
                            value={formRenderProps.values.email}
                            onChange={formRenderProps.handleChange}
                            initialValue={INITIAL_VALUES.email}
                            asyncInfoSelector={getUpdateCompanySingleFieldAsyncInfo}
                            requestId={getRequestId(companyCode, fields.email)}
                            onSave={() => updateCompanyField(
                                companyCode, fields.email, formRenderProps.values.email,
                            )}
                            validationError={{
                                error: formRenderProps.errors.email,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.company_info.general.contact_data.fields.email',
                            }}
                        />

                        <ConfirmableTextPropertyInput
                            id="phone"
                            name={fields.phone}
                            labelKey="administration.company_info.general.contact_data.fields.phone"
                            readonly={!mayEdit}
                            value={formRenderProps.values.phone}
                            onChange={formRenderProps.handleChange}
                            initialValue={INITIAL_VALUES.phone}
                            asyncInfoSelector={getUpdateCompanySingleFieldAsyncInfo}
                            requestId={getRequestId(companyCode, fields.phone)}
                            onSave={() => {
                                formRenderProps.setFieldValue(
                                    'phone',
                                    tryFormattingPhoneInternational(formRenderProps.values.phone),
                                );
                                updateCompanyField(
                                    companyCode, fields.phone, formRenderProps.values.phone,
                                );
                            }}
                            validationError={{
                                error: formRenderProps.errors.phone,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.company_info.general.contact_data.fields.phone',
                            }}
                        />

                        <ConfirmableTextPropertyInput
                            id="fax"
                            name={fields.fax}
                            labelKey="administration.company_info.general.contact_data.fields.fax"
                            readonly={!mayEdit}
                            value={formRenderProps.values.fax}
                            onChange={formRenderProps.handleChange}
                            initialValue={INITIAL_VALUES.fax}
                            asyncInfoSelector={getUpdateCompanySingleFieldAsyncInfo}
                            requestId={getRequestId(companyCode, fields.fax)}
                            onSave={() => {
                                formRenderProps.setFieldValue(
                                    'fax',
                                    tryFormattingPhoneInternational(formRenderProps.values.fax),
                                );
                                updateCompanyField(
                                    companyCode, fields.fax, formRenderProps.values.fax,
                                );
                            }}
                            validationError={{
                                error: formRenderProps.errors.fax,
                                // eslint-disable-next-line max-len
                                fieldNameTranslationKey: 'administration.company_info.general.contact_data.fields.fax',
                            }}
                        />
                    </>
                );
            }}
        />
    );
}

function getRequestId(companyCode: string, fieldName: string): string {
    return `${companyCode}_${fieldName}`;
}
