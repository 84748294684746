const DYNAMIC_PART_START = '[[';
const DYNAMIC_PART_END = ']]';
const DYNAMIC_PART_INTERNAL_SEPARATOR = '|';
const NO_CONTENT = '';

export interface IMessageBodyPart {
    content: string;
    extraRef?: string;
}

export default function parseMessageBody(body: string): IMessageBodyPart[] {
    if (!body) {
        return [];
    }

    return splitupByExtractingDynamicPartsRecursive(body);
}

function splitupByExtractingDynamicPartsRecursive(bodyPart: string): IMessageBodyPart[] {
    const dynamicStartIndex = bodyPart.indexOf(DYNAMIC_PART_START);
    const dynamicEndIndex = bodyPart.indexOf(DYNAMIC_PART_END);

    if (dynamicStartIndex > -1 && dynamicEndIndex > -1) {
        const before = bodyPart.substring(0, dynamicStartIndex);
        const dynamic = bodyPart.substring(dynamicStartIndex + DYNAMIC_PART_START.length, dynamicEndIndex);
        const after = bodyPart.substring(dynamicEndIndex + DYNAMIC_PART_END.length);

        return [
            { content: before },
            parseDynamicBodyPart(dynamic),
            ...splitupByExtractingDynamicPartsRecursive(after),
        ];
    }

    return [{
        content: bodyPart,
    }];
}

/**
 * Parses input like:
 *   linkToEmployee|text="hier"
 * >> 'linkToEmployee' is the 'extraRef' (= reference to an extra parameter of the IUserMessageDetails)
 * >> 'hier' is the content
 * The text is optional.
 */
function parseDynamicBodyPart(dynamicBodyPart: string = ''): IMessageBodyPart {
    const parts = dynamicBodyPart
        .split(DYNAMIC_PART_INTERNAL_SEPARATOR)
        .map((part) => part.trim());

    const [extraRef, ...otherParts] = parts;

    if (otherParts.length === 0) {
        return {
            content: NO_CONTENT,
            extraRef,
        };
    }

    let content = NO_CONTENT;

    otherParts.forEach((otherPart) => {
        if (otherPart.indexOf('text=') === 0) {
            const textMatches = otherPart.match('text="(.*)"');
            if (textMatches && textMatches.length > 1) {
                content = textMatches[1];
            }
        }
    });

    return {
        content,
        extraRef,
    };
}
