import React from 'react';
import CollapsibleDetailTrigger from './CollapsibleDetailTrigger';
import {
    IEmployeeRiskAndResearch,
    IEmployeeRisksAndResearches,
} from '../../../../../../../../models/admin/employee';
import { IAsyncFetchState } from '../../../../../../../../models/general/redux';
import ListItem from '../../../../../../../common/list/ListItem';
import Translate from '../../../../../../../common/Translate';
import TinyLoader from '../../../../../../../common/waiting/TinyLoader';
import CollapsibleItem from '../../../../../../../common/widget/CollapsibleItem';
import isEmptyObject from '../../../../../../../../utils/core/object/isEmptyObject';

const TRANSLATION_PREFIX =
    'interventions.medical_examinations.new.steps.select_timeslot.planned_examination_dialog.risks';

const RisksTrigger = <CollapsibleDetailTrigger titleTranslationKey={getPrefixedTranslation('title')} />;

interface IPublicPorps {
    baseClassName: string;
    employeeRisksAndResearches: IAsyncFetchState<IEmployeeRisksAndResearches>;
}

export default function RisksDetails(props: IPublicPorps) {
    const { data: risksAndResearches, ...asyncInfo } = props.employeeRisksAndResearches;

    return (
        <CollapsibleItem trigger={RisksTrigger}>
            <TinyLoader asyncInfoSelector={asyncInfo}>
                {risksAndResearches && !isEmptyObject(risksAndResearches) ?
                    <>
                        {
                            Object.keys(risksAndResearches)
                                .map((riskCode, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            title={risksAndResearches[riskCode][0].riskDescription}
                                            text={
                                                risksAndResearches[riskCode]
                                                    .map((item: IEmployeeRiskAndResearch) =>
                                                        item.researchDescription,
                                                    )
                                            }
                                            arrow={true}
                                        />
                                    );
                                })
                        }
                    </> :
                    <p>
                        <Translate
                            msg="administration.employees.detail.risks.no_results"
                        />
                    </p>
                }
            </TinyLoader>
        </CollapsibleItem>
    );
}

function getPrefixedTranslation(suffix: string) {
    return `${TRANSLATION_PREFIX}.${suffix}`;
}
