import { IPlannedCourseAppointment } from '../documentCenter/courses';
import { IDroppableFreeTimeslotData } from '../interventions/timeslots';
import { IExecutedMedicalExaminationAppointment } from '../interventions/medicalExaminations/executed';
import { IMedicalExaminationTimeSlot, IPlannedTimeSlot } from '../interventions/medicalExaminations';
import { IPlannedCompanyVisit, ICompanyVisit } from '../interventions/company-visits';
import { IInterventionAppointment } from '../interventions/appointment';
import { IPlannedMedicalExamination } from '../interventions/medicalExaminations/planned';

export enum CalendarEventDataType {
    ExecutedCompanyVisit,
    ExecutedIntervention,
    ExecutedMedicalExamination,
    PlannedCompanyVisit,
    PlannedIntervention,
    PlannedMedicalExamination,
    DraggablePlannedMedicalExamination,
    TimeCell,
    Course,
    DroppableFreeTimeSlot,
}

type TCalendarEventData =
    // Used for planning
    IPlannedTimeSlot[] | IMedicalExaminationTimeSlot[] | IPlannedMedicalExamination[] |
    // Used for draggable (bufferzone) planning
    IDroppableFreeTimeslotData | IPlannedTimeSlot |
    // Used for agenda
    IPlannedMedicalExamination | IExecutedMedicalExaminationAppointment |
    IInterventionAppointment |
    IPlannedCompanyVisit | ICompanyVisit | IPlannedCourseAppointment;

export interface ICalendarEvent<Data = TCalendarEventData> {
    id: string;
    start: Date;
    end: Date;
    allDay: boolean;
    type: CalendarEventType;
    dataType: CalendarEventDataType;
    data?: Data;
    isConcatenated: boolean;
    title?: string;
    overlappingEventsCount?: number;
    reserved?: boolean;
    afterNoon?: boolean;
}

export type TExecutedCompanyVisitCalendarEvent = ICalendarEvent<ICompanyVisit>;
export type TExecutedInterventionCalendarEvent = ICalendarEvent<IInterventionAppointment>;
export type TExecutedMedicalExaminationCalendarEvent = ICalendarEvent<IExecutedMedicalExaminationAppointment>;
export type TPlannedCompanyVisitCalendarEvent = ICalendarEvent<IPlannedCompanyVisit>;
export type TPlannedInterventionCalendarEvent = ICalendarEvent<IInterventionAppointment>;
export type TPlannedMedicalExaminationCalendarEvent = ICalendarEvent<IPlannedMedicalExamination>;

export enum CalendarEventType {
    Course = 'course',
    MedicalExamination = 'medicalExamination',
    MedicalExaminationInCompany = 'medicalExaminationInCompany',
    CompanyVisit = 'companyVisit',
    Intervention = 'intervention',
    FreeTimeslot = 'freeTimeslot',
    DroppableFreeTimeslot = 'droppableFreeTimeslot',
    DraggableMedicalExamination = 'draggableMedicalExamination',
}
