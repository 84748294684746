import { createAction, createSuccessAction, createFailAction, createTypeActions } from '../../index';
import {
    FETCH_EMPLOYEES,
    SET_TOTAL_EMPLOYEES_COUNT,
    FETCH_COMPANY_FUNCTION_EMPLOYEES,
    FETCH_EMPLOYEES_WITHOUT_EMAIL,
} from './types';
import {
    IEmployee,
    IFetchEmployeesPayload,
    IEmployeeWithoutEmail,
} from '../../../models/admin/employee';
import {
    ICompanyFunctionEmployee,
    IFetchCompanyFunctionEmployeesPayload,
} from '../../../models/admin/companyFunctions';
import { ITraceableApiError } from '../../../models/general/error';

// Employees

export const fetchEmployeesActions = createTypeActions<IFetchEmployeesPayload, IEmployee[]>({
    type: FETCH_EMPLOYEES,
});

// Employees count

export function setTotalEmployeesCount(count: number) {
    return createAction<{ count: number }>(SET_TOTAL_EMPLOYEES_COUNT, { count });
}

// Company function employees

export function fetchCompanyFunctionEmployees(payload: IFetchCompanyFunctionEmployeesPayload) {
    return createAction<IFetchCompanyFunctionEmployeesPayload>(FETCH_COMPANY_FUNCTION_EMPLOYEES, payload);
}

export function fetchCompanyFunctionEmployeesSucceeded(payload: ICompanyFunctionEmployee[]) {
    return createSuccessAction<ICompanyFunctionEmployee[]>(FETCH_COMPANY_FUNCTION_EMPLOYEES, payload);
}

export function fetchCompanyFunctionEmployeesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTION_EMPLOYEES, error);
}

// Employees without email

export const fetchEmployeesWithoutEmailActions = createTypeActions<{}, IEmployeeWithoutEmail[]>({
    type: FETCH_EMPLOYEES_WITHOUT_EMAIL,
});
