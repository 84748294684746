import React from 'react';
import ErrorDialog from '../ErrorDialog';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { IState } from '../../../../redux';
import { connect } from '../../..';

const REAL_ERROR_MESSAGE_WHITELIST = [
    'klantenzone.error.work_accident.document_not_found',
];

interface IPrivateProps {
    asyncInfo: IAsyncFieldInfo;
}

interface IPublicProps {
    asyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    isMedicalExaminationDocument?: boolean;
}

function NoDocumentsFoundErrorDialog(props: IPrivateProps & IPublicProps) {
    const {
        asyncInfo,
        isMedicalExaminationDocument,
    } = props;

    return (
        <ErrorDialog
            asyncInfo={asyncInfo}
            titleTranslationKey="common.no_documents_found_dialog.title"
            // eslint-disable-next-line max-len
            infoTranslationKey={isMedicalExaminationDocument ? 'common.no_documents_found_dialog.medical_examination_text' : 'common.no_documents_found_dialog.text'}
            hideRealErrorMessage={true}
            realErrorMessageWhiteList={REAL_ERROR_MESSAGE_WHITELIST}
            clearAsyncInfoErrorOnClose={true}
        />
    );
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                asyncInfo: publicProps.asyncInfoSelector(state),
            };
        };
    },
})(NoDocumentsFoundErrorDialog);
