import { ICompanySeat } from '../../../../models/admin/company';

export default function filterCompanySeatDivisionsByCompanyCode(
    companyCode: string,
    companySeatsAndDivisions: ICompanySeat[] = [],
): ICompanySeat[] {
    return companySeatsAndDivisions.filter((seat) => {
        const seatCompanyCode = seat.company.companyCode;
        return seatCompanyCode === companyCode || seatCompanyCode.indexOf(companyCode + '.') === 0;
    });
}
