import React from 'react';
import { MENSURA_SITE_URL } from '../../../config/general.config';
import OutgoingLink from '../../common/navigation/OutgoingLink';
import Translate from '../../common/Translate';

export default function MensuraWebsiteLink() {
    return (
        <OutgoingLink href={MENSURA_SITE_URL}>
            <Translate msg="app_shell.footer.nav.mensura" />
        </OutgoingLink>
    );
}
