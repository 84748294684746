import React, { ReactNode, PureComponent } from 'react';
import { getMessageTypesForTypeahead } from '../../../../../config/constants';
import { getFetchConstantsAsyncInfo } from '../../../../../redux/constants/selectors';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { TBackendLocale } from '../../../../../models/general/i18n';
import { localeToBackendLocale } from '../../../../../utils/formatting/formatLocale';
import { TTypeaheadData } from '../../../../common/input/Typeahead';
import FreeTextAsyncTypeahead from '../../../../common/input/Typeahead/FreeTextAsyncTypeahead';
import { IAsyncTypeaheadProps } from '../../../../common/input/Typeahead/AsyncTypeahead';
import { connect } from '../../../..';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';

interface IMessageTypeTypeaheadProps {
    id: string;
    value: string;
    name: string;
    onItemSelected: (messageTypeId: number, description: string) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholder?: string;
    disabled?: boolean;
    initialFilter?: string;
    onFilter?: (filter: string) => void;
}

interface IPrivateProps {
    asyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    backendLocale: TBackendLocale;
}

interface IComponentState {
    data: TTypeaheadData;
}

class BaseMessageTypeTypeahead extends
    PureComponent<IMessageTypeTypeaheadProps & IContextProps & IPrivateProps, IComponentState> {

    constructor(props: IMessageTypeTypeaheadProps & IContextProps & IPrivateProps) {
        super(props);
        this.state = {
            data: getMessageTypesForTypeahead(this.props.backendLocale),
        };

        this.onItemSelectedHandler = this.onItemSelectedHandler.bind(this);
    }

    public render() {
        const {
            children,
            id,
            value,
            name,
            isInvalid,
            placeholder,
            disabled,
            onFilter,
            initialFilter,
        } = this.props;

        const commonProps: IAsyncTypeaheadProps = {
            id,
            value,
            name,
            onItemSelected: this.onItemSelectedHandler,
            isInvalid,
            onFilter,
            data: this.state.data,
            placeholder,
            disabled,
            asyncInfoSelector: getFetchConstantsAsyncInfo,
        };

        return (
            <FreeTextAsyncTypeahead
                {...commonProps}
                initialFilter={initialFilter}
                autoFilteringDisabled={false}
            >
                {children}
            </FreeTextAsyncTypeahead>
        );
    }

    public componentDidUpdate(prevProps: IMessageTypeTypeaheadProps & IPrivateProps) {
        if (
            prevProps.asyncInfo.status === AsyncStatus.Busy &&
            this.props.asyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                data: getMessageTypesForTypeahead(this.props.backendLocale),
            });
        }
    }

    private onItemSelectedHandler(value: number) {
        const { onItemSelected } = this.props;
        const { data } = this.state;
        const dataItem = data.find(item => item.value === value);
        onItemSelected(value, dataItem && dataItem.label);
    }
}

export default connect<IPrivateProps, IMessageTypeTypeaheadProps>({
    stateProps: (state) => ({
        asyncInfo: getFetchConstantsAsyncInfo(state),
    }),
})(MessageTypeTypeahead);

function MessageTypeTypeahead(props: IMessageTypeTypeaheadProps & IPrivateProps) {
    return (
        <TranslatorContext.Consumer>
            {({ locale }) => <BaseMessageTypeTypeahead {...props} backendLocale={localeToBackendLocale(locale)} />}
        </TranslatorContext.Consumer>
    );
}
