import React, { PureComponent } from 'react';

import { AsyncStatus } from '../../../../../models/general/redux';
import { ConstantType } from '../../../../../models/general/constants';
import {
    getFetchMyUserAsyncInfo,
    getMyUserInfo,
    getUpdateMyUserProfileAsyncInfo,
} from '../../../../../redux/auth/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IUpdateMyUserAccountInfoPayload } from '../../../../../models/user/userAccount';
import { navigateTo } from '../../../../../redux/location/actions';
import { tryFormattingPhoneForBackend } from '../../../../../utils/formatting/formatPhone';
import { updateMyUserInfo, resetUpdateMyUserInfo } from '../../../../../redux/auth/actions';
import { connect } from '../../../../index';
import { TextPropertyInput, ConstantsTypeaheadPropertyInput } from '../../../../common/input/PropertyInput';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import Loader from '../../../../common/waiting/Loader';
import ROUTE_KEYS from '../../../../../routeKeys';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import SuccessDialog from '../../../../common/modals/SuccessDialog';
import Translate from '../../../../common/Translate';
import { BASE_CLASS_NAME } from '../common';

import { ConfirmEmailModal } from './ConfirmEmailModal/ConfirmEmailModal.component';
import { fields, schema } from './updateUserInfoSchema';
import { IPrivateProps, IPersonalDataState } from './PersonalData.type';
import { FORM_NAME, PLACEHOLDER_KEYS } from './PersonalData.const';
import { mapMyUserInfoToUpdatePayload } from './PersonalData.helper';

class PersonalData extends PureComponent<IPrivateProps, IPersonalDataState> {
    private resetForm: (values: IUpdateMyUserAccountInfoPayload) => void;

    constructor(props) {
        super(props);

        this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);

        this.state = {
            showEmailDialog: false,
            values: undefined,
        };
    }

    private onCloseSuccessDialog() {
        this.props.resetAction();
    }

    public componentDidUpdate(prevProps: IPrivateProps, prevState: IPersonalDataState) {
        const didFetchMyUserAsyncInfoChangeToSuccess = (
            prevProps.fetchMyUserAsyncInfo.status !== AsyncStatus.Success
            && this.props.fetchMyUserAsyncInfo.status === AsyncStatus.Success
        );

        const didEmailAdressChange = (
            prevState.values
            && (prevState.values.newEmail !== prevState.values.currentEmail)
        );

        if (
            this.resetForm &&
            prevProps.fetchMyUserAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchMyUserAsyncInfo.status === AsyncStatus.Success
        ) {
            this.resetForm(
                mapMyUserInfoToUpdatePayload(this.props.myUserInfo),
            );
        }

        // Log out when e-mailadres was changed & user update api call was succesfull
        if (didFetchMyUserAsyncInfoChangeToSuccess && didEmailAdressChange) {
            this.props.logout();
        }
    }

    public onModalClose = () => {
        this.setState({
            showEmailDialog: false,
        });
    };

    public onFormSubmit = (values: IUpdateMyUserAccountInfoPayload) => {
        const { currentEmail, newEmail = currentEmail } = values;

        if (newEmail !== currentEmail) {
            this.setState({
                showEmailDialog: true,
                values,
            });

            return;
        }

        this.updateUserDetails(values);
    };

    public onConfirmEmail = () => {
        const { values } = this.state;

        if (values) {
            this.props.updateDetails(values);

            this.setState({
                showEmailDialog: false,
            });
        }
    };

    public updateUserDetails = (values: IUpdateMyUserAccountInfoPayload) => {
        this.props.updateDetails(values);
    };

    public render() {
        const {
            updateAsyncInfo,
            myUserInfo,
            translator,
            fetchMyUserAsyncInfo,
        } = this.props;

        const isBusy =
            fetchMyUserAsyncInfo.status === AsyncStatus.Busy ||
            updateAsyncInfo.status === AsyncStatus.Busy;

        if (fetchMyUserAsyncInfo.error) {
            this.resetForm = null;
            return (
                <ErrorPlaceholder apiError={fetchMyUserAsyncInfo.error} />
            );
        }

        if (!myUserInfo) {
            this.resetForm = null;
            return <Loader show={isBusy} />;
        }

        return (
            <>
                <Form
                    name={FORM_NAME}
                    initialValues={mapMyUserInfoToUpdatePayload(myUserInfo)}
                    schema={schema}
                    handleSubmit={this.onFormSubmit}
                    render={(renderProps: IFormRenderProps<IUpdateMyUserAccountInfoPayload>) => {
                        const {
                            values, handleChange, errors, setFieldValue, touched, resetForm,
                        } = renderProps;

                        if (this.resetForm !== resetForm) {
                            this.resetForm = resetForm;
                        }

                        return (
                            <>
                                <Loader show={isBusy}>
                                    <ConstantsTypeaheadPropertyInput
                                        id={fields.titleId}
                                        name={fields.titleId}
                                        labelKey={PLACEHOLDER_KEYS.titleId}
                                        readonly={false}
                                        value={values.titleId}
                                        constantType={ConstantType.TITLES}
                                        onItemSelected={(value) => setFieldValue('titleId', Number(value))}
                                        isInvalid={touched.titleId && !!errors.titleId}
                                    >
                                        {touched.titleId && (
                                            <FormFieldError
                                                error={errors.titleId}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.titleId),
                                                }}
                                            />
                                        )}
                                    </ConstantsTypeaheadPropertyInput>
                                    <TextPropertyInput
                                        id={fields.firstName}
                                        name={fields.firstName}
                                        labelKey={PLACEHOLDER_KEYS.firstName}
                                        readonly={false}
                                        value={values.firstName}
                                        onChange={handleChange}
                                        isInvalid={touched.firstName && !!errors.firstName}
                                    >
                                        {touched.firstName && (
                                            <FormFieldError
                                                error={errors.firstName}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.firstName),
                                                }}
                                            />
                                        )}
                                    </TextPropertyInput>
                                    <TextPropertyInput
                                        id={fields.name}
                                        name={fields.name}
                                        labelKey={PLACEHOLDER_KEYS.name}
                                        readonly={false}
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    >
                                        {touched.name && (
                                            <FormFieldError
                                                error={errors.name}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.name),
                                                }}
                                            />
                                        )}
                                    </TextPropertyInput>
                                    <ConstantsTypeaheadPropertyInput
                                        id={fields.sexId}
                                        name={fields.sexId}
                                        labelKey={PLACEHOLDER_KEYS.sexId}
                                        readonly={false}
                                        value={values.sexId}
                                        constantType={ConstantType.SEXES}
                                        onItemSelected={(value) => setFieldValue('sexId', Number(value))}
                                        isInvalid={touched.sexId && !!errors.sexId}
                                    >
                                        {touched.sexId &&
                                            <FormFieldError
                                                error={errors.sexId}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.sexId),
                                                }}
                                            />}
                                    </ConstantsTypeaheadPropertyInput>
                                    <ConstantsTypeaheadPropertyInput
                                        id={fields.languageId}
                                        name={fields.languageId}
                                        labelKey={PLACEHOLDER_KEYS.languageId}
                                        readonly={false}
                                        value={values.languageId}
                                        constantType={ConstantType.LANGUAGES}
                                        onItemSelected={(value) => setFieldValue('languageId', Number(value))}
                                        isInvalid={touched.languageId && !!errors.languageId}
                                    >
                                        {touched.languageId && (
                                            <FormFieldError
                                                error={errors.languageId}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.languageId),
                                                }}
                                            />
                                        )}
                                    </ConstantsTypeaheadPropertyInput>
                                    <TextPropertyInput
                                        id={fields.newEmail}
                                        name={fields.newEmail}
                                        labelKey={PLACEHOLDER_KEYS.newEmail}
                                        readonly={false}
                                        value={values.newEmail}
                                        onChange={handleChange}
                                        isInvalid={touched.newEmail && !!errors.newEmail}
                                    >
                                        {touched.newEmail && (
                                            <FormFieldError
                                                error={errors.newEmail}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.newEmail),
                                                }}
                                            />
                                        )}
                                    </TextPropertyInput>
                                    <TextPropertyInput
                                        id={fields.phoneNumber}
                                        name={fields.phoneNumber}
                                        labelKey={PLACEHOLDER_KEYS.phoneNumber}
                                        readonly={false}
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                    >
                                        {touched.phoneNumber && (
                                            <FormFieldError
                                                error={errors.phoneNumber}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.phoneNumber),
                                                }}
                                            />
                                        )}
                                    </TextPropertyInput>
                                    <TextPropertyInput
                                        id={fields.mobilePhoneNumber}
                                        name={fields.mobilePhoneNumber}
                                        labelKey={PLACEHOLDER_KEYS.mobilePhoneNumber}
                                        readonly={false}
                                        value={values.mobilePhoneNumber}
                                        onChange={handleChange}
                                        isInvalid={touched.mobilePhoneNumber && !!errors.mobilePhoneNumber}
                                    >
                                        {touched.mobilePhoneNumber && (
                                            <FormFieldError
                                                error={errors.mobilePhoneNumber}
                                                placeholders={{
                                                    fieldName: translator(PLACEHOLDER_KEYS.mobilePhoneNumber),
                                                }}
                                            />
                                        )}
                                    </TextPropertyInput>
                                    <FormError error={updateAsyncInfo.error} />
                                    <div className={`${BASE_CLASS_NAME}__content__actions`}>
                                        <SubmitButton
                                            id="save-account-settings"
                                            formName={FORM_NAME}
                                        >
                                            <Translate msg="account.account_settings.profile.personal_data.submit" />
                                        </SubmitButton>
                                    </div>
                                </Loader>
                                <SuccessDialog
                                    show={!this.state.values && (updateAsyncInfo.status === AsyncStatus.Success)}
                                    titleTranslationKey="account.account_settings.profile.personal_data.success"
                                    onCloseDialog={this.onCloseSuccessDialog}
                                />
                            </>
                        );
                    }}
                />
                { (this.state.values && this.state.values.newEmail) && (
                    <ConfirmEmailModal
                        show={this.state.showEmailDialog}
                        onChangeEmail={this.onConfirmEmail}
                        onDismiss={this.onModalClose}
                        emailToMatch={this.state.values.newEmail}
                        translator={translator}
                    />
                )}
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
        updateAsyncInfo: getUpdateMyUserProfileAsyncInfo(state),
        myUserInfo: getMyUserInfo(state),
        fetchMyUserAsyncInfo: getFetchMyUserAsyncInfo(state),
    }),
    dispatchProps: (dispatch) => ({
        updateDetails: (values: IUpdateMyUserAccountInfoPayload) => {
            const formattedValues: IUpdateMyUserAccountInfoPayload = {
                ...values,
                mobilePhoneNumber: tryFormattingPhoneForBackend(values.mobilePhoneNumber),
                phoneNumber: tryFormattingPhoneForBackend(values.phoneNumber),
            };

            dispatch(updateMyUserInfo(formattedValues));
        },
        resetAction: () => {
            dispatch(resetUpdateMyUserInfo());
        },
        logout: () => {
            dispatch(navigateTo(
                ROUTE_KEYS.R_LOGOUT,
            ));
        },
    }),
})(PersonalData);

