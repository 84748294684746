import React, { Component } from 'react';
import './work-post-card-risks.scss';
import { connect } from '../../../index';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import {
    getWorkPostCardWizardEntity,
    getWorkPostCardDefaultValues,
} from '../../../../redux/documentCenter/workPostCards/selectors';
import {
    IWorkPostCardWizardEntity,
    IWorkPostCardRisks,
    IWorkPostCardDefaultValues,
    IWorkPostCardDefaultValue,
    IWorkPostCardDefaultRiskValues,
} from '../../../../models/documentCenter/workPostCards';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import { createFormTextInput } from '../../../common/forms/FormTextInput';
import { schema, fields } from './risksSchema';
import { createFormCheckbox } from '../../../common/forms/FormCheckbox';
import Translate from '../../../common/Translate';
import { ShapeOf } from '../../../../models/ui/form';

export type FormValues = IWorkPostCardRisks;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    updateWizardEntity: (values: FormValues) => void;
    defaultValues: IWorkPostCardDefaultValues;
}

const FORM_NAME = 'work-post-card-risks';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.risks';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;
const CLASS_NAME = 'WorkPostCardRisks';

const RisksTextInput = createFormTextInput<FormValues>();
const RisksCheckbox = createFormCheckbox<FormValues>();

class Protection extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            renderStepButtons, goToNextStep, updateWizardEntity, wizardEntity, defaultValues,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            healthSurveillance: wizardEntity.risks && wizardEntity.risks.healthSurveillance || false,
            safetyFunction: wizardEntity.risks && wizardEntity.risks.safetyFunction || false,
            increasedVigilanceFunction: wizardEntity.risks && wizardEntity.risks.increasedVigilanceFunction || false,
            chemical: wizardEntity.risks && wizardEntity.risks.chemical || '',
            checkNoise: wizardEntity.risks && wizardEntity.risks.checkNoise || false,
            noise: wizardEntity.risks && wizardEntity.risks.noise || '',
            checkTemperature: wizardEntity.risks && wizardEntity.risks.checkTemperature || false,
            temperature: wizardEntity.risks && wizardEntity.risks.temperature || '',
            checkIonizing: wizardEntity.risks && wizardEntity.risks.checkIonizing || false,
            ionizing: wizardEntity.risks && wizardEntity.risks.ionizing || '',
            checkOtherPhysicalResources: wizardEntity.risks && wizardEntity.risks.checkOtherPhysicalResources || false,
            otherPhysicalResources: wizardEntity.risks && wizardEntity.risks.otherPhysicalResources || '',
            biologicalResources: wizardEntity.risks && wizardEntity.risks.biologicalResources || '',
            vaccinations: wizardEntity.risks && wizardEntity.risks.vaccinations || '',
            checkWorkStationLoad: wizardEntity.risks && wizardEntity.risks.checkWorkStationLoad || false,
            workStationLoad: wizardEntity.risks && wizardEntity.risks.workStationLoad || '',
            checkLiftingLoad: wizardEntity.risks && wizardEntity.risks.checkLiftingLoad || false,
            liftingLoad: wizardEntity.risks && wizardEntity.risks.liftingLoad || '',
            checkOthersLoad: wizardEntity.risks && wizardEntity.risks.checkOthersLoad || false,
            othersLoad: wizardEntity.risks && wizardEntity.risks.othersLoad || '',
            nightOrShiftWork: wizardEntity.risks && wizardEntity.risks.nightOrShiftWork || '',
            nightOrShiftWorkRisks: wizardEntity.risks && wizardEntity.risks.nightOrShiftWorkRisks || '',
            psychosocialLoad: wizardEntity.risks && wizardEntity.risks.psychosocialLoad || '',
            associatedActivities: wizardEntity.risks && wizardEntity.risks.associatedActivities || '',
            youngPeople: wizardEntity.risks && wizardEntity.risks.youngPeople || '',
        };

        function handleSubmit(values: FormValues) {
            updateWizardEntity(values);
            goToNextStep();
        }

        const editableMap: ShapeOf<IWorkPostCardDefaultRiskValues> = { ...defaultValues.healthSurveillance };

        for (const key in editableMap) {
            const defaultValue: IWorkPostCardDefaultValue = editableMap[key];
            editableMap[key] = defaultValue && defaultValue.editable === '1';
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    text={`${BASE_KEY}.text`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values } = formRenderProps;
                            return (
                                <>
                                    <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                        <h2><Translate msg={`${BASE_KEY}.form.title.health_surveillance`} /></h2>
                                        <RisksCheckbox
                                            name="healthSurveillance"
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.checkYes}
                                        />
                                        <RisksCheckbox
                                            name="safetyFunction"
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.checkSafetyFunction}
                                        />
                                        <RisksCheckbox
                                            name="increasedVigilanceFunction"
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.checkIncreasedVigilanceFunction}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.chemical`} /></h2>
                                        <RisksTextInput
                                            name="chemical"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.chemical}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.physical`} /></h2>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkNoise"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkNoisePhysicalResources}
                                            />
                                            {values.checkNoise && (
                                                <RisksTextInput
                                                    name="noise"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.noisePhysicalResources}
                                                    hideValue={!values.checkNoise}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkTemperature"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkTemperaturePhysicalResources}
                                            />
                                            {values.checkTemperature && (
                                                <RisksTextInput
                                                    name="temperature"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.temperaturePhysicalResources}
                                                    hideValue={!values.checkTemperature}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkIonizing"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkIonizingPhysicalResources}
                                            />
                                            {values.checkIonizing && (
                                                <RisksTextInput
                                                    name="ionizing"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.ionizingPhysicalResources}
                                                    hideValue={!values.checkIonizing}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkOtherPhysicalResources"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkOtherPhysicalResources}
                                            />
                                            {values.checkOtherPhysicalResources && (
                                                <RisksTextInput
                                                    name="otherPhysicalResources"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.otherPhysicalResources}
                                                    hideValue={!values.checkOtherPhysicalResources}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <h2><Translate msg={`${BASE_KEY}.form.title.biological`} /></h2>
                                        <RisksTextInput
                                            name="biologicalResources"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.biologicalResources}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.vaccinations`} /></h2>
                                        <RisksTextInput
                                            name="vaccinations"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.vaccinations}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.load`} /></h2>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkWorkStationLoad"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkOthersLoad}
                                            />
                                            {values.checkWorkStationLoad && (
                                                <RisksTextInput
                                                    name="workStationLoad"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.workStationLoad}
                                                    hideValue={!values.checkWorkStationLoad}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkLiftingLoad"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkLiftingLoad}
                                            />
                                            {values.checkLiftingLoad && (
                                                <RisksTextInput
                                                    name="liftingLoad"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.liftingLoad}
                                                    hideValue={!values.checkLiftingLoad}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_textinput`}>
                                            <RisksCheckbox
                                                name="checkOthersLoad"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                disabled={!editableMap.checkOthersLoad}
                                            />
                                            {values.checkOthersLoad && (
                                                <RisksTextInput
                                                    name="othersLoad"
                                                    baseId={FORM_NAME}
                                                    fields={fields}
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    formRenderProps={formRenderProps}
                                                    disabled={!editableMap.othersLoad}
                                                    hideValue={!values.checkOthersLoad}
                                                    noFloatableWrapper={true}
                                                />
                                            )}
                                        </div>
                                        <h2><Translate msg={`${BASE_KEY}.form.title.night_or_shift_work`} /></h2>
                                        <RisksTextInput
                                            name="nightOrShiftWork"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.nightOrShiftWork}
                                        />
                                        <RisksTextInput
                                            name="nightOrShiftWorkRisks"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.nightOrShiftWorkExtra}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.psychosocial`} /></h2>
                                        <RisksTextInput
                                            name="psychosocialLoad"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.psychosocialLoad}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.food_associated_activities`} /></h2>
                                        <RisksTextInput
                                            name="associatedActivities"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.associatedActivities}
                                        />
                                        <h2><Translate msg={`${BASE_KEY}.form.title.young_people`} /></h2>
                                        <RisksTextInput
                                            name="youngPeople"
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                            formRenderProps={formRenderProps}
                                            disabled={!editableMap.youngPeople}
                                        />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                                disabled: false,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
            defaultValues: getWorkPostCardDefaultValues(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateWorkPostCardWizardEntity({
                    risks: {
                        ...mapWorkPostRisksValues(values),
                    },
                }));
            },
        };
    },
})(Protection);

function mapWorkPostRisksValues(values: FormValues): FormValues {
    return {
        healthSurveillance: values.healthSurveillance,
        safetyFunction: values.safetyFunction,
        increasedVigilanceFunction: values.increasedVigilanceFunction,
        chemical: values.chemical,
        checkNoise: values.checkNoise,
        noise: values.checkNoise ? values.noise : '',
        checkTemperature: values.checkTemperature,
        temperature: values.checkTemperature ? values.temperature : '',
        checkIonizing: values.checkIonizing,
        ionizing: values.checkIonizing ? values.ionizing : '',
        checkOtherPhysicalResources: values.checkOtherPhysicalResources,
        otherPhysicalResources: values.checkOtherPhysicalResources ? values.otherPhysicalResources : '',
        biologicalResources: values.biologicalResources,
        vaccinations: values.vaccinations,
        checkWorkStationLoad: values.checkWorkStationLoad,
        workStationLoad: values.checkWorkStationLoad ? values.workStationLoad : '',
        checkLiftingLoad: values.checkLiftingLoad,
        liftingLoad: values.checkLiftingLoad ? values.liftingLoad : '',
        checkOthersLoad: values.checkOthersLoad,
        othersLoad: values.checkOthersLoad ? values.othersLoad : '',
        nightOrShiftWork: values.nightOrShiftWork,
        nightOrShiftWorkRisks: values.nightOrShiftWorkRisks,
        psychosocialLoad: values.psychosocialLoad,
        associatedActivities: values.associatedActivities,
        youngPeople: values.youngPeople,
    };
}
