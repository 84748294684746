import React from 'react';
import { IVideoPagePartProps } from './shared';
import VideoJsPlayer from '../../../widget/VideoJsPlayer';

export default function HostedVideo({ data, hostname }: IVideoPagePartProps) {
    const posterUrl = data.image && data.image.url;

    if (data.video.metadata.code) {
        return (
            <VideoJsPlayer
                options={{
                    sources: [
                        {
                            src: data.video.metadata.code,
                            type: 'video/mp4',
                        },
                    ],
                    poster: posterUrl && `${hostname}${posterUrl}`,
                }}
            />
        );
    }

    return null;
}
