import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.scss';
import ButtonLinkToRoute from '../../common/navigation/ButtonLinkToRoute';
import ROUTE_KEYS from '../../../routeKeys';
import Translate from '../../common/Translate';
import Icon from '../../common/icons/Icon';
import Button from '../../common/buttons/Button';
import TranslatorContext from '../../appShell/contexts/TranslatorContext';
import { isExtraSmallScreen } from '../../../utils/dom/screenSize';

const MEDIA_SLIDER_FRAME_IMG = require('../../assets/img/general/macbook.png');

interface ICarouselState {
    mediaSliderState: Slider;
    contentSliderState: Slider;
    currentSlideId: string;
    isChangingSlide: boolean;
}

const SLIDE_IDS = ['startpage', 'plan', 'docs'];
const TRANSLATION_PREFIX = 'auth.login.features.carousel';
const CLASS_NAME = 'Carousel';

export default class Carousel extends PureComponent<{}, ICarouselState> {
    private mediaSlider: Slider;
    private contentSlider: Slider;
    private videoRefs: HTMLVideoElement[];

    constructor(props) {
        super(props);

        this.mediaSlider = null;
        this.contentSlider = null;
        this.videoRefs = [];

        this.state = {
            mediaSliderState: null,
            contentSliderState: null,
            currentSlideId: SLIDE_IDS[0],
            isChangingSlide: false,
        };

        this.goToNextSlide = this.goToNextSlide.bind(this);
        this.goToPrevSlide = this.goToPrevSlide.bind(this);
        this.handleAfterChange = this.handleAfterChange.bind(this);
        this.handleBeforeChange = this.handleBeforeChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            mediaSliderState: this.mediaSlider,
            contentSliderState: this.contentSlider,
        });
        this.playVideo(this.state.currentSlideId);
    }

    render() {
        const {
            mediaSliderState, contentSliderState,
            currentSlideId, isChangingSlide,
        } = this.state;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__inner`}>
                    <ButtonLinkToRoute
                        id="carousel-close"
                        to={ROUTE_KEYS.R_LOGIN}
                        className={`${CLASS_NAME}__back-button`}
                    >
                        <Icon typeName="cross" />
                    </ButtonLinkToRoute>
                    <div className={`${CLASS_NAME}__top`}>
                        {currentSlideId && (
                            <h1 className={isChangingSlide ? 'animate' : ''}>
                                <Translate msg={`${TRANSLATION_PREFIX}.slides.${currentSlideId}.title`} />
                            </h1>
                        )}
                    </div>
                    <div className={`${CLASS_NAME}__bottom`}>
                        <figure className={`${CLASS_NAME}__media-wrapper`}>
                            <img
                                className={`${CLASS_NAME}__media-wrapper__frame`}
                                src={MEDIA_SLIDER_FRAME_IMG}
                                alt="macbook"
                            />
                            <div className="viewport">
                                <Slider
                                    ref={(slider) => (this.mediaSlider = slider)}
                                    asNavFor={contentSliderState}
                                    dots
                                    arrows={false}
                                    infinite={false}
                                    draggable={false}
                                    swipe
                                    className={`${CLASS_NAME}__media-slider`}
                                >
                                    {SLIDE_IDS.map((id, i) => (
                                        <div key={`video-${id}`} className={`${CLASS_NAME}__media-slider__slide`}>
                                            {/* eslint-disable max-len */}
                                            <TranslatorContext.Consumer>
                                                {({ translator }) => (
                                                    isExtraSmallScreen() ? (
                                                        <>
                                                            <figure>
                                                                <img src={translator(`${TRANSLATION_PREFIX}.slides.${id}.mobile_image`)} />
                                                            </figure>
                                                            <div className={`${CLASS_NAME}__content-slider__slide__footer`}>
                                                                {this.renderSlideFooterActions(id, i)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <video
                                                                poster={translator(`${TRANSLATION_PREFIX}.slides.${id}.video.placeholder`)}
                                                                playsInline
                                                                muted
                                                                loop
                                                                ref={(video) => (this.videoRefs[id] = video)}
                                                            >
                                                                <source src={translator(`${TRANSLATION_PREFIX}.slides.${id}.video.webm`)} type="video/webm" />
                                                                <source src={translator(`${TRANSLATION_PREFIX}.slides.${id}.video.mp4`)} type="video/mp4" />
                                                            </video>
                                                            <img src={translator(`${TRANSLATION_PREFIX}.slides.${id}.video.placeholder`)} />
                                                        </>
                                                    )
                                                )}
                                            </TranslatorContext.Consumer>
                                            {/* tslint:enable:max-line-length */}
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </figure>
                        <div className={`${CLASS_NAME}__content-wrapper`}>
                            <Slider
                                ref={(slider) => (this.contentSlider = slider)}
                                asNavFor={mediaSliderState}
                                dots={true}
                                infinite={false}
                                arrows={false}
                                draggable={false}
                                swipe
                                beforeChange={this.handleBeforeChange}
                                afterChange={this.handleAfterChange}
                                className={`${CLASS_NAME}__content-slider`}
                            >

                                {SLIDE_IDS.map((id, i) => (
                                    <div key={`content-${id}`} className={`${CLASS_NAME}__content-slider__slide`}>
                                        <Translate msg={`${TRANSLATION_PREFIX}.slides.${id}.content`} raw />
                                        <div className={`${CLASS_NAME}__content-slider__slide__footer`}>
                                            {this.renderSlideFooterActions(id, i)}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private goToNextSlide() {
        this.contentSlider.slickNext();
    }

    private goToPrevSlide() {
        this.contentSlider.slickPrev();
    }

    private handleAfterChange(index: number) {
        const currentSlideId = SLIDE_IDS[index];

        this.setState({
            currentSlideId,
            isChangingSlide: false,
        });

        this.playVideo(currentSlideId);
    }

    private handleBeforeChange() {
        SLIDE_IDS.map((id) => this.resetVideo(id));
        this.setState({ isChangingSlide: true });
    }

    private resetVideo(id: string) {
        if (typeof this.videoRefs[id] !== 'undefined') {
            const video = this.videoRefs[id] as HTMLVideoElement;
            if (video.readyState === 4) {
                video.pause();
                if (!Number.isNaN(video.currentTime)) {
                    video.currentTime = 0;
                }
                video.load();
            }
        }
    }

    private playVideo(id: string) {
        if (typeof this.videoRefs[id] !== 'undefined') {
            const video = this.videoRefs[id] as HTMLVideoElement;

            // https://stackoverflow.com/a/36898221
            const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;

            if (!isPlaying) {
                // play video if ready, or wait until it is ready to play.
                if (video.readyState === 4) {
                    video.play();
                } else {
                    video.addEventListener('canplay', function onCanPlay() {
                        video.removeEventListener('canplay', onCanPlay);
                        video.play();
                    });
                }
            }
        }
    }

    private renderSlideFooterActions(id: string, i: number) {
        return (
            <>
                {i !== 0 &&
                    <Button
                        id={`carousel-go-to-prev-slide-${id}`}
                        onClick={this.goToPrevSlide}
                        typeName="white"
                        outline
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.previous`}/>
                    </Button>
                }
                {SLIDE_IDS.length === i + 1 ? (
                    // stop on last slide
                    <ButtonLinkToRoute
                        id="carousel-end"
                        to={ROUTE_KEYS.R_LOGIN}
                        typeName="secondary"
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.start`}/>
                    </ButtonLinkToRoute>
                ) : (
                    <Button
                        id={`carousel-go-to-next-slide-${id}`}
                        onClick={this.goToNextSlide}
                        typeName="secondary"
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.next`}/>
                    </Button>
                )}
            </>
        );
    }
}
