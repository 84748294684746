import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './video-js-player.scss';
import classNames from 'classnames';
import Icon from '../../icons/Icon';

interface IVideoPlayerProps {
    options: videojs.PlayerOptions;
}

const initialOptions: videojs.PlayerOptions = {
    bigPlayButton: false,
    controls: true,
    fluid: true,
    controlBar: {
        volumePanel: {
            inline: false,
        },
    },
};

const CLASS_NAME = 'VideoJsPlayer';

const VideoJsPlayer: React.FC<IVideoPlayerProps> = ({ options }) => {
    const videoNode = React.useRef<HTMLVideoElement>();
    const player = React.useRef<videojs.Player>();

    React.useEffect(() => {
        player.current = videojs(videoNode.current, {
            ...initialOptions,
            ...options,
        }).ready(() => {
            // console.log('onPlayerReady', ready);
        });
        return () => {
            if (player.current) {
                player.current.dispose();
            }
        };
    }, [options]);

    return (
        <div className={CLASS_NAME}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video ref={videoNode} className={classNames(CLASS_NAME, 'video-js')} />
            <Icon typeName="play" circle className={`${CLASS_NAME}__icon`} />
        </div>
    );
};

export default VideoJsPlayer;
