import { DroppablePrefix, DraggablePrefix } from '../../../models/interventions/timeslots';
import { DroppableId, DraggableId } from 'react-beautiful-dnd';

const ID_SEPARATOR = '_';
const ID_SEPARATOR_REGEX = /_(.+)/;

export function createPrefixedDroppableId(id: string | number, prefix: DroppablePrefix): DroppableId {
    return `${prefix}${ID_SEPARATOR}${id}`;
}

export function createPrefixedDraggableId(id: string | number, prefix: DraggablePrefix): DraggableId {
    return `${prefix}${ID_SEPARATOR}${id}`;
}

export function getPrefixFromPrefixedDroppableId(prefixedDroppableId: DroppableId): DroppablePrefix {
    return prefixedDroppableId.split(ID_SEPARATOR_REGEX)[0] as DroppablePrefix;
}

export function getIdFromPrefixedDroppableId(prefixedDroppableId: DroppableId) {
    return prefixedDroppableId.split(ID_SEPARATOR_REGEX)[1];
}

export function getPrefixFromPrefixedDraggableId(prefixedDraggableId: DraggableId): DraggablePrefix {
    return prefixedDraggableId.split(ID_SEPARATOR_REGEX)[0] as DraggablePrefix;
}

export function getIdFromPrefixedDraggableId(prefixedDraggableId: DraggableId) {
    return prefixedDraggableId.split(ID_SEPARATOR_REGEX)[1];
}
