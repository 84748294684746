import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers,
    getAsyncDoInitialState,
} from '../../index';
import {
    FETCH_ACTIVITY_REPORTS, GENERATE_ACTIVITY_REPORT,
    GENERATE_COMPANY_AND_EMPLOYEE_REPORT, FETCH_ACTIVITY_REPORT_DETAIL,
} from './types';
import { IActivityReport } from '../../../models/general/documents';
import { IAsyncDoField } from '../../../models/general/redux';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.COMPANY_DOCUMENTS;

export interface IReducerState {
    activityReports: IAsyncFetchField<IActivityReport[]>;
    generateActivityReport: IAsyncDoField;
    generateCompanyAndEmployeeReport: IAsyncDoField;
    activityReportDetail: IAsyncDoField;
}

const initialState: IReducerState = {
    activityReports: getAsyncFetchInitialState(),
    generateActivityReport: getAsyncDoInitialState(),
    generateCompanyAndEmployeeReport: getAsyncDoInitialState(),
    activityReportDetail: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IActivityReport[], IReducerState, IActivityReport[]>({
        baseActionType: FETCH_ACTIVITY_REPORTS,
        fieldName: 'activityReports',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: GENERATE_ACTIVITY_REPORT,
        fieldName: 'generateActivityReport',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: GENERATE_COMPANY_AND_EMPLOYEE_REPORT,
        fieldName: 'generateCompanyAndEmployeeReport',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: FETCH_ACTIVITY_REPORT_DETAIL,
        fieldName: 'activityReportDetail',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
