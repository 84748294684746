import React from 'react';
import './video-page-part.scss';
import { VideoProviders } from '../../../../../models/general/cms';
import RemoteVideo from './RemoteVideo';
import HostedVideo from './HostedVideo';
import { IVideoPagePartProps } from './shared';

const CLASS_NAME = 'VideoPagePart';
const REMOTE_VIDEO_PROVIDERS: VideoProviders[] = ['youtube', 'vimeo'];
const HOSTED_VIDEO_PROVIDERS: VideoProviders[] = ['azure'];

export default function VideoPagePart(props: IVideoPagePartProps) {
    const { data } = props;
    const provider = data.video.metadata.type;

    if (REMOTE_VIDEO_PROVIDERS.includes(provider)) {
        return (
            <div className={CLASS_NAME}>
                <RemoteVideo {...props} />
            </div>
        );
    }

    if (HOSTED_VIDEO_PROVIDERS.includes(provider)) {
        return (
            <div className={CLASS_NAME}>
                <HostedVideo {...props} />
            </div>
        );
    }

    return null;
}

