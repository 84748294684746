import React, { PureComponent } from 'react';
import './remarks-input.scss';
import { fields } from './interventionRequestRemarksSchema';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { connect } from '../../../../index';
import { IFormRenderProps } from '../../../../common/forms/Form';
import FormFieldError from '../../../../common/forms/FormFieldError';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../common/input/TextInput';
import Translate from '../../../../common/Translate';

const CLASS_NAME = 'InterventionRequest__RemarksInput';

export interface IFormValues {
    remark: string;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
}

interface IPrivateProps {
    translator: ITranslator;
}

class RemarksInput extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);
    }

    public render() {
        const { formRenderProps, translator } = this.props;

        const remarkTranslationKey = 'interventions.intervention_request_new.steps.remarks.fields.remark';

        return (
            <div className={CLASS_NAME}>
                <div className="Remarks__textarea">
                    <FloatableTextInputWrapper>
                        <TextInput
                            id="intervention-request-remark"
                            name={fields.remark}
                            value={formRenderProps.values.remark}
                            onChange={formRenderProps.handleChange}
                            isInvalid={formRenderProps.touched.remark && !!formRenderProps.errors.remark}
                            multiLine={true}
                            rows={6}
                        />
                        <label htmlFor="intervention-request-remark">
                            <Translate msg={remarkTranslationKey} />
                        </label>
                        {formRenderProps.touched.remark && (
                            <FormFieldError
                                error={formRenderProps.errors.remark}
                                placeholders={{ fieldName: translator(remarkTranslationKey) }}
                            />
                        )}
                    </FloatableTextInputWrapper>
                </div>
            </div>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
        };
    },
})(RemarksInput);
