import { bffDefaultApiConfig } from '../../config/api/bffApiParameters.config';
import { get, post } from '../../utils/api/requestWrapper';
import { ICompanyCodePayload } from '../../models/admin/company';
import {
    IFetchAdministeredVaccinationsPayload,
    IFetchToAdministerVaccinationsPayload,
} from '../../redux/intervention/vaccinations/types';
import { IVaccinationsApiResponse, IRequestVaccinationPayload } from '../../models/interventions/vaccines';
import { Locales } from '../../config/i18n.config';
import { ONE_SECOND } from '../../utils/core/time/periodsInMillis';

export const VACCINATION_URLS = {
    HISTORY: '/vaccinations/history/{mode}/customer/{customerId}',
    FUTURE: '/vaccinations/future/{mode}/customer/{customerId}',
    REQUEST_VACCINATION: '/companies/{companyCode}/vaccinations/request',
};

export function fetchAdministeredVaccinations(
    payload: IFetchAdministeredVaccinationsPayload,
    locale: Locales,
): Promise<IVaccinationsApiResponse[]> {
    const {
        mode,
        customerId,
        administeredDateFrom,
        administeredDateUntil,
    } = payload;

    return get({
        ...bffDefaultApiConfig({ locale }),
        url: VACCINATION_URLS.HISTORY,
        pathParams: {
            mode,
            customerId: customerId.toString(),
        },
        queryParams: {
            ...!!administeredDateFrom && {
                administeredDateFrom,
            },
            ...!!administeredDateUntil && {
                administeredDateUntil,
            },
        },
        timeoutInMillis: ONE_SECOND * 90,
    });
}

export function fetchToAdministerVaccinations(
    payload: IFetchToAdministerVaccinationsPayload,
    locale: Locales,
): Promise<IVaccinationsApiResponse[]> {
    const {
        mode,
        customerId,
        administerDateFrom,
        administerDateUntil,
    } = payload;

    return get({
        ...bffDefaultApiConfig({ locale }),
        url: VACCINATION_URLS.FUTURE,
        pathParams: {
            mode,
            customerId: customerId.toString(),
        },
        queryParams: {
            ...!!administerDateFrom && {
                administerDateFrom,
            },
            ...!!administerDateUntil && {
                administerDateUntil,
            },
        },
        timeoutInMillis: ONE_SECOND * 90,
    });
}

export function requestVaccination(payload: IRequestVaccinationPayload & ICompanyCodePayload) {
    const { companyCode, ...requestBody } = payload;
    return post({
        url: VACCINATION_URLS.REQUEST_VACCINATION,
        pathParams: {
            companyCode,
        },
        body: requestBody,
    });
}
