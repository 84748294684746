import { IState, getReducerState, makeAsyncDoInfoSelector } from '../index';
import { IReducerState, reducerKey } from './reducer';
import { getPreGeneratedTranslator } from './translators';
import { ITranslator } from '../../models/general/i18n';
import { localeToBackendLocale, localeToBffLocale } from '../../utils/formatting/formatLocale';
import { Locales } from '../../config/i18n.config';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export const getLocale = (state: IState) => reducerState(state).locale;

export const getBackendLocale = (state: IState) => localeToBackendLocale(getLocale(state));
export const getBffLocale = (state: IState) => localeToBffLocale(getLocale(state));

export const areTranslationsRefreshed = (state: IState) => reducerState(state).areTranslationsRefreshed;

export const showTranslationIds = (state: IState) => reducerState(state).showTranslationIds;

/**
 * Getting translator by connecting a component to the state is deprecated
 * because it does not take into account the TranslatorContext the component is in.
 * We use React Context to get a different translator in certain parts of the app.
 * For new uses of getTranslator, get the translator using TranslatorContext.Consumer instead.
 */
export const getTranslatorDeprecated = (state: IState): ITranslator => {
    const locale = getLocale(state);
    const showTranslationsAsIds = showTranslationIds(state);

    if (areTranslationsRefreshed(state)) {
        return getPreGeneratedTranslator(
            locale,
            showTranslationsAsIds,
        );
    }

    return getTranslatorCopySoThatAfterTranslationsRefreshAllTranslationsWillBeReRendered(
        locale,
        showTranslationsAsIds,
    );
};

export const getTranslatorForContext = (state: IState, localeOverride?: Locales): ITranslator => {
    const locale = localeOverride ? localeOverride : getLocale(state);
    const showTranslationsAsIds = showTranslationIds(state);

    if (areTranslationsRefreshed(state)) {
        return getPreGeneratedTranslator(
            locale,
            showTranslationsAsIds,
        );
    }

    return getTranslatorCopySoThatAfterTranslationsRefreshAllTranslationsWillBeReRendered(
        locale,
        showTranslationsAsIds,
    );
};

const translatorCopyCache = {};

function getTranslatorCopySoThatAfterTranslationsRefreshAllTranslationsWillBeReRendered(
    locale: Locales,
    showTranslationIds: boolean,
): ITranslator {
    const uniqueKey = `${locale}-${showTranslationIds}`;
    if (!translatorCopyCache[uniqueKey]) {
        /**
         * The bind ensures that it returns a different/new copy then the default one.
         * So after a refresh a re-render of all translations is triggered.
         */
        translatorCopyCache[uniqueKey] = getPreGeneratedTranslator(locale, showTranslationIds).bind(null);
    }
    return translatorCopyCache[uniqueKey];
}

// fetch translations and all constants

const getFetchTranslationsAndAllConstantsAsyncField = (state: IState) =>
    reducerState(state).fetchTranslationsAndAllConstants;

export const getFetchTranslationsAndAllConstantsAsyncInfo =
    makeAsyncDoInfoSelector(getFetchTranslationsAndAllConstantsAsyncField);
