interface IErrorData {
    [key: string]: string;
}

export interface IApiError {
    code: number;
    message: string;
    /**
     * The optional underlying mensura error will be passed to the front-end in this 'mensuraCode' field.
     * But as it can contain technical sensitive information, or even stack traces, we don't want to have this
     * kind of information in a production environment.
     * So it will only be passed to the front-end in the dev environment (for develop/debug purposes)
     * and not in val/prod environments.
     * As we then shouldn't base any logic on this mensuraCode field, it's removed here from the interface.
     */
    // mensuraCode: string;
    extraData: IErrorData;
    requestMethod: string;
    wasCancelled: boolean;
}

export interface IBffError {
    detail: string;
    status: number;
    title: string;
    type: string;

    instance?: string;
    traceId?: string;
}

export interface ITraceableApiError extends IApiError {
    id: string;
    type: 'api' | 'bff';
}

export const TRACEABLE_API_PREFIX = 'TRACEABLE-API-ERROR-';

export interface IValidationError {
    type: ErrorTypes;
    message: string;
}

export type ValidationErrors<Values> = { [key in keyof Values]: IValidationError };

export enum ErrorTypes {
    // Yup error types
    Required = 'required',
    OneOf = 'oneOf',
    Min = 'min', // min string length
    // Custom
    MinValue = 'minValue',
    InvalidEmail = 'invalidEmail',
    InvalidNumber = 'invalidNumber',
    InvalidNationalRegisterNumber = 'invalidNationalRegisterNumber',
    InvalidPassword = 'invalidPassword',
    InvalidTime = 'invalidTime',
    InvalidPhoneNumber = 'InvalidPhoneNumber',
    PasswordConfirm = 'passwordConfirm',
    PasswordMinLength = 'passwordMinLength',
    SelectAtLeastOne = 'selectAtLeastOne',
    MinDate = 'minDate',
    MaxDate = 'maxDate',
    InvalidPeriod = 'InvalidPeriod',
    CountryNotSupported = 'CountryNotSupported',
    StartsWithEqualsPlusMinusAt = 'StartsWithEqualsPlusMinusAt',
    // If you want to use a custom non-default translation key, then you can use this Custom type
    // which will cause the IValidationError.message to be used as translation key
    Custom = 'custom',
}

// Maps to the resources declared inside error translations
export enum ErrorResourceIds {
    Default = 'default',
    Required = 'required',
    CountryNotSupported = 'country_not_supported',
    PasswordConfirm = 'password_confirm',
    PasswordMinLength = 'password_min_length',
    MustBeMinimumLength = 'must_be_minimum_length',
    MustBeMinimumValue = 'must_be_minimum_value',
    MustBeMinimumDate = 'must_be_minimum_date',
    MustBeMaximumDate = 'must_be_maximum_date',
    InvalidEmail = 'invalid_email',
    InvalidNumber = 'invalid_number',
    InvalidPassword = 'invalid_password',
    InvalidPeriod = 'invalid_period',
    InvalidNationalRegisterNumber = 'invalid_national_register_number',
    InvalidTime = 'invalid_time',
    InvalidPhoneNumber = 'invalid_phone',
    SelectAtLeastOne = 'select_at_least_one',
    StartsWithEqualsPlusMinusAt = 'starts_with_equals_plus_minus_at',
}
