import { object, boolean } from 'yup';
import nationalRegisterNumber from '../../../../../../utils/libs/yup/nationalRegisterNumber';
import number from '../../../../../../utils/libs/yup/number';
import phoneNumber from '../../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../../utils/libs/yup/string';

export const nationalityIdBelgium = 1831748;

export const personalDataFields = {
    address: 'address',
    birthDate: 'birthDate',
    email: 'email',
    employeeNumber: 'employeeNumber',
    firstName: 'firstName',
    languageId: 'languageId',
    mobilePhone: 'mobilePhone',
    name: 'name',
    nationalityId: 'nationalityId',
    nationalRegisterNumber: 'nationalRegisterNumber',
    phone: 'phone',
    sexId: 'sexId',
    employer: 'employer',
    medicalFunction: 'medicalFunction',
    safetyFunction: 'safetyFunction',
    studentWorker: 'studentWorker',
};

export const personalDataSchema = object({
    [personalDataFields.address]: object().nullable(true),
    [personalDataFields.birthDate]: string().nullable(true).when(
        personalDataFields.nationalRegisterNumber,
        {
            is: (value) => {
                return value === null;
            },
            then: string().required(),
        }),
    [personalDataFields.email]: string().email().nullable(true),
    [personalDataFields.employeeNumber]: string().nullable(true),
    [personalDataFields.firstName]: string().required(),
    [personalDataFields.languageId]: number().required(),
    [personalDataFields.mobilePhone]: phoneNumber(),
    [personalDataFields.name]: string().required(),
    [personalDataFields.nationalityId]: number().required(),
    // Non-numeric chars are stripped from this value
    [personalDataFields.nationalRegisterNumber]: nationalRegisterNumber().nullable(true).when(
        personalDataFields.nationalityId,
        {
            is: (value) => {
                if (typeof value === 'string') {
                    const valueAsNumber = parseInt(value, 10);
                    return valueAsNumber === nationalityIdBelgium;
                }
                return value === nationalityIdBelgium;
            },
            then: nationalRegisterNumber().required(),
        }),
    [personalDataFields.phone]: phoneNumber(),
    [personalDataFields.sexId]: number().required(),
    [personalDataFields.employer]: string(),
    [personalDataFields.medicalFunction]: boolean(),
    [personalDataFields.safetyFunction]: boolean(),
    [personalDataFields.studentWorker]: boolean(),
});
