import React, { Component } from 'react';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { connect } from '../../../../../index';
import { ICompanySeat } from '../../../../../../models/admin/company';
import {
    getSelectedSeatCompanyCode,
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
    getSeatsAndDivisionsForEmployee,
} from '../../../../../../redux/company/selected/selectors';
import classNames from 'classnames';
import { IEmployeeToAdd } from '../../../../../../models/admin/employee';
import { updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';
import { getEmployeeToAdd } from '../../../../../../redux/employee/wizard/selectors';
import SeatsList from '../../../../../account/SeatsList';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { IAsyncFieldInfo } from '../../../../../../models/general/redux';
import ListFooter from '../../../../../common/list/ListFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

type UpdateValues = Pick<IEmployeeToAdd, 'company'>;

interface IPrivateProps {
    companySeats: ICompanySeat[];
    updateEmployeeToAdd: (values: UpdateValues) => void;
    currentCompanyCode: string;
    employeeToAdd: IEmployeeToAdd;
    fetchAsyncInfo: IAsyncFieldInfo;
}

interface IState {
    selectedCompanySeatCompanyCode: string;
}

const LIST_NAME = 'add-employee-seats';
const CLASS_NAME = 'AddEmployeeSeat';

class Seat extends Component<IStepperStepRenderProps & IPrivateProps, IState> {
    constructor(props: IPrivateProps & IStepperStepRenderProps) {
        super(props);

        this.state = {
            selectedCompanySeatCompanyCode: props.currentCompanyCode,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
    }

    public render() {
        const { renderStepButtons, employeeToAdd, companySeats, fetchAsyncInfo } = this.props;
        const { selectedCompanySeatCompanyCode } = this.state;

        return (
            <div
                className={classNames(
                    'container',
                    CLASS_NAME,
                )}
            >
                <PageHeader
                    title="administration.employees.add.steps.seat.title"
                    text="administration.employees.add.steps.seat.text"
                    titlePlaceholders={{ name: formatPersonName(employeeToAdd) }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <SeatsList
                        name={LIST_NAME}
                        selectedSeatCompanyCode={selectedCompanySeatCompanyCode}
                        onSeatSelected={this.onItemSelected}
                        companySeats={companySeats}
                        fetchAsyncInfo={fetchAsyncInfo}
                        footer={
                            <ListFooter
                                right={renderStepButtons({
                                    nextButton: {
                                        disabled: !selectedCompanySeatCompanyCode,
                                        onClick: this.onClickNextHandler,
                                    },
                                })}
                            />
                        }
                    />
                </div>
            </div>
        );
    }

    private onItemSelected(itemId: string | number) {
        this.setState({
            selectedCompanySeatCompanyCode: itemId ? itemId.toString() : null,
        });
    }

    private onClickNextHandler() {
        const { companySeats, updateEmployeeToAdd, goToNextStep } = this.props;
        const { selectedCompanySeatCompanyCode } = this.state;
        const selectedCompanySeat = companySeats
            .find((item) => item.company.companyCode === selectedCompanySeatCompanyCode);
        updateEmployeeToAdd({
            company: {
                companyCode: selectedCompanySeatCompanyCode,
                name: selectedCompanySeat && selectedCompanySeat.company.name,
            },
        });
        goToNextStep();
    }

}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const companySeats = getSeatsAndDivisionsForEmployee(state);
        const employeeToAdd = getEmployeeToAdd(state);
        return {
            employeeToAdd,
            companySeats,
            fetchAsyncInfo: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state),
            currentCompanyCode: employeeToAdd && employeeToAdd.company
                ? employeeToAdd.company.companyCode
                : getSelectedSeatCompanyCode(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateEmployeeToAdd: (values) => {
                dispatch(updateAddEmployeeData(values));
            },
        };
    },
})(Seat);
