import { ICourse, ICMSCourse } from '../../../../models/documentCenter/courses';
import { TEACHING_TYPES } from '../../../../config/courses.config';

const COURSE_TYPE_OPEN_VALUES_LOWERCASE = ['open', 'ouvert'];

export function isCourseOfTypeOpen(course: ICourse) {
    if (course && course.courseType) {
        if (COURSE_TYPE_OPEN_VALUES_LOWERCASE.includes(course.courseType.toLowerCase())) {
            return true;
        }
    }

    return false;
}

export function isCourseWithCustomPrice(course: ICMSCourse) {
    return (course.teachingType && course.teachingType === TEACHING_TYPES.open) || course.price === 0;
}
