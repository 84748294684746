import React, { PureComponent, MouseEvent } from 'react';
import './edit-employee-contact-data.scss';
import * as employeeApi from '../../../../../../../api/admin/employee/employee.api';
import { ADD_EMPLOYEE_PLACEHOLDER_KEYS } from '../../../../../../../config/administration.config';
import { IEmployee, IEmployeeDetails, TEmployeeUpdateFields } from '../../../../../../../models/admin/employee';
import TranslatorContext from '../../../../../../appShell/contexts/TranslatorContext';
import Button from '../../../../../../common/buttons/Button';
import SubmitButton from '../../../../../../common/buttons/SubmitButton';
import FloatableTextInputWrapper from '../../../../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../../../../common/forms/Form';
import FormFieldError from '../../../../../../common/forms/FormFieldError';
import TextInput from '../../../../../../common/input/TextInput';
import Translate from '../../../../../../common/Translate';
import { FormValues, fields, schema } from './updateEmployeeContactDataSchema';
import { AsyncStatus, IAsyncFetchState, IAsyncFieldInfo } from '../../../../../../../models/general/redux';
import { connect } from '../../../../../..';
import { getUpdateEmployeeAsyncInfo } from '../../../../../../../redux/employee/info/selectors';
import FormError from '../../../../../../common/forms/FormError';
import { updateEmployee, fetchEmployeeDetailsReset } from '../../../../../../../redux/employee/info/actions';
import Loader from '../../../../../../common/waiting/Loader';
import {
    getAsyncFetchStateBusy,
    getAsyncFetchStateError,
    getAsyncFetchStateInitial,
    getAsyncFetchStateSuccess,
} from '../../../../../../../utils/api/async/asyncFetchState';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import Checkbox from '../../../../../../common/input/Checkbox';
import RequiredMarker from '../../../../../../common/input/RequiredMarker';

interface IPublicProps {
    onClose: () => void;
    onSuccess: (employee: IEmployee) => void;
    employee: IEmployee;
}

interface IPrivateProps {
    updateEmployeeAsyncInfo: IAsyncFieldInfo;
    updateEmployee: (id: number, employeeData: Partial<TEmployeeUpdateFields>) => void;
    resetSelectedEmployee: () => void;
}

interface IState {
    employeeDetails: IAsyncFetchState<IEmployeeDetails>;
}

const UPDATE_EMPLOYEE_REQUEST_ID = 'updateEmployee';
const CLASS_NAME = 'EditEmployeeContactData';
const FORM_NAME = 'edit-employee-contact-data-form';
const TRANSLATION_KEY_PREFIX = 'document_center.courses.new.steps.employee.add.edit_contact_data';

class EditEmployeeContactData extends PureComponent<IPublicProps & IPrivateProps, IState>{
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            employeeDetails: getAsyncFetchStateInitial(),
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    public render() {
        const { employee, updateEmployeeAsyncInfo } = this.props;
        const { employeeDetails } = this.state;

        const initialValues: FormValues = {
            email: (employeeDetails && employeeDetails.data && employeeDetails.data.email) || '',
            phone: (employeeDetails && employeeDetails.data && employeeDetails.data.phone) || '',
            skipMail: false,
        };

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        <div className={CLASS_NAME}>
                            <header className={`${CLASS_NAME}__header`}>
                                <h2>
                                    <Translate
                                        msg={`${TRANSLATION_KEY_PREFIX}.title`}
                                        placeholders={{
                                            name: formatPersonName({
                                                name: employee.name,
                                                firstName: employee.firstName,
                                            }),
                                        }}
                                    />
                                </h2>
                                <p>
                                    <Translate msg={`${TRANSLATION_KEY_PREFIX}.text`} />
                                </p>
                            </header>
                            <Form
                                name={FORM_NAME}
                                handleSubmit={this.onFormSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                schema={schema}
                                render={({ values, touched, errors, handleChange }: IFormRenderProps<FormValues>) => {
                                    return (
                                        <>
                                            <Loader
                                                show={
                                                    (employeeDetails && employeeDetails.status === AsyncStatus.Busy)
                                                    || (updateEmployeeAsyncInfo
                                                        && updateEmployeeAsyncInfo.status === AsyncStatus.Busy)}
                                            />
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="edit-employee-email"
                                                    name={fields.email}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={touched.email && !!errors.email}
                                                />
                                                <label htmlFor="edit-employee-email">
                                                    <Translate msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.email}/>
                                                    {!values.skipMail && <RequiredMarker />}
                                                </label>
                                                {touched.email &&
                                                    <FormFieldError
                                                        error={errors.email}
                                                        placeholders={{
                                                            fieldName: translator(ADD_EMPLOYEE_PLACEHOLDER_KEYS.email),
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="edit-employee-phone"
                                                    name={fields.phone}
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={touched.phone && !!errors.phone}
                                                />
                                                <label htmlFor="edit-employee-phone">
                                                    <Translate msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.phone} />
                                                </label>
                                                {touched.phone &&
                                                    <FormFieldError
                                                        error={errors.phone}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                ADD_EMPLOYEE_PLACEHOLDER_KEYS.phone),
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <Checkbox
                                                name="skipMail"
                                                checked={values.skipMail}
                                                onChange={handleChange}
                                            >
                                                <Translate msg={`${TRANSLATION_KEY_PREFIX}.form.skip_email`} />
                                            </Checkbox>
                                            <FormError
                                                error={updateEmployeeAsyncInfo && updateEmployeeAsyncInfo.error}
                                            />
                                            <div className={`${CLASS_NAME}__actions`}>
                                                <Button
                                                    id="edit-employee-cancel-button"
                                                    typeName="secondary"
                                                    outline={true}
                                                    onClick={this.onCancelClick}
                                                >
                                                    <Translate
                                                        msg={`${TRANSLATION_KEY_PREFIX}.form.cancel`}
                                                    />
                                                </Button>
                                                <SubmitButton
                                                    id="edit-employee-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    <Translate
                                                        msg={`${TRANSLATION_KEY_PREFIX}.form.submit`}
                                                    />
                                                </SubmitButton>
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidMount() {
        this.fetchEmployeeDetails();
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        // Goes from initial to busy => busy to initial (never goes to a success state)
        // This is because it uses a request id in the redux state instead of the regular flow
        if (
            prevProps.updateEmployeeAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateEmployeeAsyncInfo.status === AsyncStatus.Initial
        ) {
            this.props.onSuccess(this.props.employee);
            this.props.resetSelectedEmployee();
            this.props.onClose();
        }
    }

    private async fetchEmployeeDetails() {
        const { employee } = this.props;

        try {
            this.setState({
                employeeDetails: getAsyncFetchStateBusy(),
            });

            const employeeDetailsData = await employeeApi.fetchEmployeeDetails((employee.id).toString());

            this.setState({
                employeeDetails: getAsyncFetchStateSuccess(employeeDetailsData),
            });
        } catch (error) {
            this.setState({
                employeeDetails: getAsyncFetchStateError(error),
            });
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const { onClose } = this.props;

        onClose();
    }

    private onFormSubmit(values: FormValues) {
        const { employee, updateEmployee } = this.props;
        const { email, phone } = values;

        updateEmployee(employee.id, {
            ...employee as Partial<TEmployeeUpdateFields>,
            email,
            phone,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            updateEmployeeAsyncInfo: getUpdateEmployeeAsyncInfo(state, UPDATE_EMPLOYEE_REQUEST_ID),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateEmployee: (id: number, employeeData: Partial<TEmployeeUpdateFields>) => {
                dispatch(updateEmployee({
                    requestId: UPDATE_EMPLOYEE_REQUEST_ID,
                    id,
                    employeeData,
                }));
            },
            resetSelectedEmployee: () => {
                dispatch(fetchEmployeeDetailsReset());
            },
        };
    },
})(EditEmployeeContactData);
