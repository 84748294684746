import React, { PureComponent, ReactNode } from 'react';
import { ICity, IFetchCitiesPayload } from '../../../../models/general/address';
import AsyncTypeahead from '../../input/Typeahead/AsyncTypeahead';
import api from '../../../../api';
import { IAsyncFetchField } from '../../../../models/general/redux';
import { getAsyncFetchInitialState, getAsyncFetchInfo } from '../../../../redux';
import { IRequestWrapperPromise } from '../../../../utils/api/requestWrapper';
import isTraceableApiError from '../../../../utils/api/isTraceableApiError';
import { BE_COUNTRY_CODE, BE_COUNTRY_CODE_FOR_BACKEND } from '../../../../config/general.config';

export const UNSELECTED_ZIPCODEID = -1;

interface ICityTypeaheadProps {
    id: string;
    name: string;
    countryCode: string;
    initialPostcode?: string;
    initialSelectedCity?: ICity;
    value: number;
    onItemSelected: (zipCodeId: number, city: ICity) => void;
    isInvalid?: boolean;
    placeholder?: string;
    children: ReactNode;
    disabled?: boolean;
}

interface IState {
    asyncFetchInfo: IAsyncFetchField<ICity[]>;
}

export default class CityTypeahead extends PureComponent<ICityTypeaheadProps, IState> {
    private cityFetch: IRequestWrapperPromise<ICity[]>;

    constructor(props: ICityTypeaheadProps) {
        super(props);

        this.state = {
            asyncFetchInfo: getAsyncFetchInitialState(),
        };

        this.onFilter = this.onFilter.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
        this.asyncInfoSelector = this.asyncInfoSelector.bind(this);
        this.initializeTypeahead = this.initializeTypeahead.bind(this);
    }

    public render() {
        const {
            id,
            name,
            children,
            isInvalid,
            placeholder,
            countryCode,
            value,
            disabled,
        } = this.props;
        const { asyncFetchInfo } = this.state;
        const typeaheadData = asyncFetchInfo.data
            ? this.mapCitiesForTypeahead(asyncFetchInfo.data) : [];

        return (
            <AsyncTypeahead
                id={id}
                value={value}
                valueIfNotSelected={UNSELECTED_ZIPCODEID}
                name={name}
                onItemSelected={this.onItemSelected}
                isInvalid={isInvalid}
                onFilter={this.onFilter}
                asyncInfoSelector={this.asyncInfoSelector}
                data={typeaheadData}
                placeholder={placeholder}
                disabled={!countryCode || disabled}
                minCharsToTriggerSearch={1}
                asyncInfoSelectorDoesNotRequireState={true}
            >
                {children}
            </AsyncTypeahead>
        );
    }

    public componentDidMount() {
        this.initializeTypeahead();
    }

    public componentDidUpdate(prevProps: ICityTypeaheadProps, prevState: IState) {
        const { initialPostcode } = this.props;
        const { asyncFetchInfo } = this.state;
        if (asyncFetchInfo.data !== prevState.asyncFetchInfo.data) {
            this.updateSelectedCity();
        }
        if (initialPostcode !== prevProps.initialPostcode) {
            this.initializeTypeahead();
        }
    }

    public componentWillUnmount() {
        if (this.cityFetch) {
            this.cityFetch.cancelRequest();
        }
    }

    private initializeTypeahead() {
        const { countryCode, initialPostcode, value, initialSelectedCity } = this.props;
        if (value && initialSelectedCity) {
            return this.setState({
                asyncFetchInfo: {
                    isFetching: false,
                    error: null,
                    data: [initialSelectedCity],
                },
            });
        }
        if (value && countryCode && initialPostcode) {
            this.fetchCities({
                countryCode,
                postalCodeFilter: initialPostcode,
                cityNameFilter: null,
            });
        }
    }

    private mapCitiesForTypeahead(cities: ICity[]) {
        return Array.isArray(cities) ? cities.map((item) => ({
            value: item.zipCodeId,
            label: `${item.postalCode} ${item.name}`,
        })) : [];
    }

    private onFilter(filter: string) {
        const { countryCode } = this.props;
        if (countryCode) {
            if (!Number.isNaN(Number(filter))) {
                return this.fetchCities({ countryCode, postalCodeFilter: filter, cityNameFilter: null });
            }
            return this.fetchCities({ countryCode, postalCodeFilter: null, cityNameFilter: filter });
        }
        return null;
    }

    private onItemSelected(value: string) {
        const { onItemSelected } = this.props;
        const { data: cities } = this.state.asyncFetchInfo;
        const selectedZipCodeId = Number(value);
        const selectedCity = (cities || []).find((item) => item.zipCodeId === selectedZipCodeId);
        onItemSelected(selectedZipCodeId, selectedCity || null);
    }

    private updateSelectedCity() {
        const { value, onItemSelected } = this.props;
        const { data: cities } = this.state.asyncFetchInfo;
        if (value && cities) {
            const selectedCity = cities.find((item) => item.zipCodeId === value);
            if (selectedCity) {
                onItemSelected(value, selectedCity);
            }
        }
    }

    private async fetchCities(payload: IFetchCitiesPayload) {
        if (this.cityFetch) {
            this.cityFetch.cancelRequest();
        }
        this.setState({
            asyncFetchInfo: {
                isFetching: true,
                error: null,
                data: null,
            },
        });
        try {
            /* TODO: Right now we can only fetch cities in Belgium using the CRAB database.
            Once more countries are supported this should probably use the actual countryCode instead of
            us mapping the Belgian countryCode to 'be' to communicate with CRAB */
            const fetchPayload: IFetchCitiesPayload = {
                ...payload,
                countryCode: payload.countryCode === BE_COUNTRY_CODE ?
                    BE_COUNTRY_CODE_FOR_BACKEND : payload.countryCode,
            };
            this.cityFetch = api.general.address.fetchCities(fetchPayload);
            const cities = await this.cityFetch;
            this.setState({
                asyncFetchInfo: {
                    isFetching: false,
                    error: null,
                    data: cities,
                },
            });
        } catch (error) {
            if (isTraceableApiError(error) && error.wasCancelled) {
                return;
            }
            this.setState({
                asyncFetchInfo: {
                    isFetching: false,
                    error,
                    data: null,
                },
            });
        }
    }

    private asyncInfoSelector() {
        return getAsyncFetchInfo(this.state.asyncFetchInfo);
    }
}
