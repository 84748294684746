export enum FlashMessageType {
    DOWNLOAD_TRIGGERED_TO_MESSAGE_CENTER = 'DOWNLOAD_TRIGGERED_TO_MESSAGE_CENTER',
    NEW_APP_VERSION = 'NEW_APP_VERSION',
    NEW_UNREAD_MESSAGES = 'NEW_UNREAD_MESSAGES',
    SESSION_EXPIRE_WARNING = 'SESSION_EXPIRE_WARNING',
}

export interface IFlashMessage<FlashData> {
    type: FlashMessageType;
    data: FlashData;
    triggerTime: number; // millis since 01/01/1970
}

export interface IFlashDataCount {
    count: number;
}

export interface IFlashDataSessionAboutToExpire {
    sessionEndTime: string;
}
