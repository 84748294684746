import React from 'react';
import './step-divider.scss';

function StepDivider() {
    return (
        <div className="StepDivider" />
    );
}

export default StepDivider;
