import React, { Component, MouseEvent } from 'react';
import './interim-work-post-card.scss';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import {
    updatePlanMedicalExaminationWizardEntity,
} from '../../../../../../redux/medicalExamination/actions';
import { IInterimManualEntity } from '../../../../../../models/interventions/medicalExaminations';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import FileUploadDropzone from '../../../../../common/widget/FileUploadDropzone';
import { EXAMINATION_DOCUMENTS_MIME_TYPES } from '../../../../../../config/medicalExamination.config';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import { uploadEmployeeDocumentActions, fetchDocument } from '../../../../../../redux/employee/documents/actions';
import Loader from '../../../../../common/waiting/Loader';
import {
    getUploadEmployeeDocumentAsyncInfo,
    getDocumentAsyncInfo,
} from '../../../../../../redux/employee/documents/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../models/general/redux';
import FormError from '../../../../../common/forms/FormError';
import { ITraceableApiError } from '../../../../../../models/general/error';
import { areFilesEqual } from '../../../../../../utils/core/file/diffFiles';
import {
    getWorkPostFileOfEmployee,
    getWorkPostFileOfEmployeeAsyncInfo,
} from '../../../../../../redux/documentCenter/workPostCards/selectors';
import { IWorkPostFile } from '../../../../../../models/documentCenter/workPostCards';
import Translate from '../../../../../common/Translate';
import Radiobutton from '../../../../../common/input/Radiobutton';
import Icon from '../../../../../common/icons/Icon';
import { isFalsyOrEmptyObject } from '../../../../../../utils/core/object/isEmptyObject';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.work_post_card';
const CLASS_NAME = 'InterimWorkPostCard';

interface IPrivateProps {
    updateWizardEntity: (workPostCard: File) => void;
    currentWorkPostCard: File;
    uploadWorkPostCard: (workPostCard: File) => void;
    uploadStatus: AsyncStatus;
    uploadError: ITraceableApiError;
    workPostFile: IWorkPostFile;
    workPostFileAsyncInfo: IAsyncFieldInfo;
    onDownloadClick: (id: string) => void;
    isDownloadingDocument: boolean;
}

interface IState {
    uploadedFile: File;
    selectedRadioButtonValue: string;
}

enum RadiobuttonValue {
    old = 'old',
    new = 'new',
}

interface IRadioButtonConfig {
    label: string;
    value: RadiobuttonValue;
}

const RADIO_BUTTONS: IRadioButtonConfig[] = [
    {
        label: `${TRANSLATION_PREFIX}.radiobutton.old`,
        value: RadiobuttonValue.old,
    },
    {
        label: `${TRANSLATION_PREFIX}.radiobutton.new`,
        value: RadiobuttonValue.new,
    },
];

class InterimWorkPostCard extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            uploadedFile: this.props.currentWorkPostCard,
            selectedRadioButtonValue: '',
        };

        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.onFileDrop = this.onFileDrop.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onChangeRadioValueHandler = this.onChangeRadioValueHandler.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IStepperStepRenderProps) {
        if (prevProps.uploadStatus === AsyncStatus.Busy && this.props.uploadStatus === AsyncStatus.Success) {
            const { updateWizardEntity, goToNextStep } = this.props;
            updateWizardEntity(this.state.uploadedFile);
            goToNextStep();
        }
    }

    public render() {
        const {
            renderStepButtons, uploadStatus, uploadError,
            workPostFile, workPostFileAsyncInfo,
            isDownloadingDocument,
        } = this.props;
        const { uploadedFile, selectedRadioButtonValue } = this.state;

        const isFetchingWorkPostFile = workPostFileAsyncInfo.status === AsyncStatus.Busy;

        const showLoader =
            isFetchingWorkPostFile
            || uploadStatus === AsyncStatus.Busy
            || isDownloadingDocument;

        const hasExistingWorkPostFile = !isFalsyOrEmptyObject(workPostFile);

        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={hasExistingWorkPostFile && !isFetchingWorkPostFile && `${TRANSLATION_PREFIX}.text_choose`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <Loader show={showLoader}>
                        {hasExistingWorkPostFile && !isFetchingWorkPostFile && (
                            <>
                                <div className={`${CLASS_NAME}__existing-file`}>
                                    <div
                                        className="download-file"
                                        onClick={(e) => this.onDownloadClick(e, workPostFile.documentId)}
                                    >
                                        <Icon typeName="doc" />
                                        <span>{workPostFile.fileName}</span>
                                        <Icon typeName="download" />
                                    </div>
                                </div>
                                <div className={`${CLASS_NAME}__selection-wrapper`}>
                                    <div className={`${CLASS_NAME}__selection`}>
                                        {RADIO_BUTTONS.map((config, index) => {
                                            const isSelected = selectedRadioButtonValue === config.value;
                                            return (
                                                <Radiobutton
                                                    key={`work_post_fiche_radiobutton_${index}`}
                                                    name="choose-file"
                                                    value={config.value}
                                                    checked={isSelected}
                                                    onChange={() => this.onChangeRadioValueHandler(config.value)}
                                                    className={isSelected ? 'checked' : null}
                                                >
                                                    <Translate msg={config.label} />
                                                </Radiobutton>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                        {(!hasExistingWorkPostFile || selectedRadioButtonValue === RadiobuttonValue.new) && (
                            <FileUploadDropzone
                                className={`${CLASS_NAME}__upload`}
                                acceptedMimeTypes={EXAMINATION_DOCUMENTS_MIME_TYPES}
                                onFileDrop={this.onFileDrop}
                                onFileDelete={this.onFileDelete}
                                multiple={false}
                                previousDroppedFiles={uploadedFile
                                    ? [uploadedFile]
                                    : []
                                }
                            />
                        )}
                        <FormError className={`${CLASS_NAME}__error`} error={uploadError} />
                        <StickyFooter
                            className={WIZARDFLOW_CLASSES.ACTIONS}
                        >
                            {renderStepButtons({
                                nextButton: {
                                    onClick: this.onClickNextHandler,
                                    disabled: !uploadedFile && (selectedRadioButtonValue !== RadiobuttonValue.old),
                                },
                            })}
                        </StickyFooter>
                    </Loader>
                </div>
            </>
        );
    }

    private onFileDrop(files: File[]) {
        if (files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onload = () => {
                this.setState({
                    uploadedFile: file,
                });
            };

            reader.readAsDataURL(file);
        }
    }

    private onFileDelete() {
        this.setState({ uploadedFile: null });
    }

    private onClickNextHandler() {
        const { currentWorkPostCard, goToNextStep, uploadWorkPostCard } = this.props;
        const { uploadedFile, selectedRadioButtonValue } = this.state;
        if (selectedRadioButtonValue === RadiobuttonValue.old || areFilesEqual(uploadedFile, currentWorkPostCard)) {
            goToNextStep();
        } else {
            uploadWorkPostCard(uploadedFile);
        }
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, documentId: number) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(documentId as unknown as string);
    }

    private onChangeRadioValueHandler(value: string) {
        this.setState({ selectedRadioButtonValue: value });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const uploadAsyncInfo = getUploadEmployeeDocumentAsyncInfo(state);
        const downloadSingleAsyncInfo = getDocumentAsyncInfo(state);
        const isDownloadingDocument = downloadSingleAsyncInfo.status === AsyncStatus.Busy;
        return {
            currentWorkPostCard: getPlanMedicalExaminationWizardEntity<IInterimManualEntity>(state).workPostCard,
            uploadStatus: uploadAsyncInfo && uploadAsyncInfo.status,
            uploadError: uploadAsyncInfo && uploadAsyncInfo.error,
            workPostFile: getWorkPostFileOfEmployee(state),
            workPostFileAsyncInfo: getWorkPostFileOfEmployeeAsyncInfo(state),
            isDownloadingDocument,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardEntity: (workPostCard: File) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IInterimManualEntity>({
                    workPostCard,
                }));
            },
            uploadWorkPostCard: (workPostCard: File) => {
                const state = getState();
                const entity = getPlanMedicalExaminationWizardEntity<IInterimManualEntity>(state);
                dispatch(uploadEmployeeDocumentActions.trigger({
                    id: entity.searchEmployee.selectedEmployee.id,
                    file: workPostCard,
                }));
            },
            onDownloadClick: (id: string) => {
                dispatch(fetchDocument({
                    ids: [id],
                }));
            },
        };
    },
})(InterimWorkPostCard);
