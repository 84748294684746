import { path } from 'ramda';


import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { getUpdateEmployeeRequestId } from '../../../views/administration/Employees/EmployeeDetails';
import {
    IAddEmployeeAbsencePayload,
    IAddEmployeeJobStudentPayload,
    IAddEmployeeStatutePayload,
    IAddPersonalRiskPayload,
    IChangeEmployeeOutOfServicePayload,
    IClearEmployeeOutOfServicePayload,
    IFetchEmployeeDetailsByInszPayload,
    IFetchEmployeeDetailsPayload,
    IFetchEmployeeFunctionRisksAndResearchesPayload,
    IFetchEmployeeJobStudentPayload,
    IFetchEmployeePersonalRisksPayload,
    IFetchEmployeeRisksAndResearchesPayload,
    IRemoveEmployeeAbsencePayload,
    IRemoveEmployeeRiskPayload,
    IRemoveEmployeeStatutePayload,
    ISetEmployeeOutOfServicePayload,
    IUpdateEmloyeeByIdPayload,
    IUpdateEmployeeAbsencePayload,
    IUpdateEmployeeAllFieldsPayload,
    IUpdateEmployeeCostCenterPayload,
    IUpdateEmployeeEmploymentPayload,
    IUpdateEmployeeJobStudentPayload,
    IUpdateEmployeePayload,
    IUpdateEmployeeRiskPayload,
    IUpdateEmployeeStatutePayload,
} from '../../../models/admin/employee';
import { IFetchEmployeeMedicalExaminationsActionPayload } from '../../../models/admin/employee/medical-examination';
import { isToday } from '../../../utils/core/date/isToday';
import { isInThePast } from '../../../utils/core/date/isInThePast';
import { ITraceableApiError } from '../../../models/general/error';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import ROUTE_KEYS from '../../../routeKeys';
import { areExaminationReasonsAvailable } from '../../medicalExamination/selectors';
import { createEpic, IParallelCallInput } from '../../index';
import { fetchCompanyCostCentersActions, fetchCompanyMedicalCentersActions } from '../../company/info/actions';
import { fetchEmployeeCoursesActions } from '../../documentCenter/courses/actions';
import { fetchExaminationReasons, fetchExecutedMedicalExaminationDetail } from '../../medicalExamination/actions';
import { getEmployeesWithoutEmail, isOnboardingWizardRoute } from '../employees/selectors';
import { getLocale } from '../../i18n/selectors';
import { getQueryParams, getRouteKey, getRoutePayload } from '../../location/selectors';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../company/selected/selectors';
import { IState } from '../../IState';
import { navigateTo, redirectToRoute } from '../../location/actions';
import { fetchEmployeesActions, fetchEmployeesWithoutEmailActions } from '../employees/actions';

import {
    ADD_EMPLOYEE_ABSENCE,
    ADD_EMPLOYEE_JOB_STUDENT,
    ADD_EMPLOYEE_PERSONAL_RISK,
    ADD_EMPLOYEE_STATUTE,
    CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    FETCH_EMPLOYEE_ABSENCES,
    FETCH_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_JOB_STUDENT,
    FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    FETCH_EMPLOYEE_PERSONAL_RISKS,
    FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_STATUTES,
    FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    FETCH_SMALL_EMPLOYEE_DETAILS,
    REMOVE_EMPLOYEE_ABSENCE,
    REMOVE_EMPLOYEE_RISK,
    REMOVE_EMPLOYEE_STATUTE,
    SET_EMPLOYEE_OUT_OF_SERVICE,
    UPDATE_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ALL_FIELDS,
    UPDATE_EMPLOYEE_COST_CENTER,
    UPDATE_EMPLOYEE_EMPLOYMENT,
    UPDATE_EMPLOYEE_JOB_STUDENT,
    UPDATE_EMPLOYEE_RISK,
    UPDATE_EMPLOYEE_STATUTE,
    UPDATE_EMPLOYEE,
} from './types';
import {
    getSelectedEmployee,
    isEmployeeDetailsDataAvailable,
} from './selectors';
import * as EMPLOYEE_INFO_ACTIONS from './actions';

const ACTION_TYPES_THAT_FETCH_EMPLOYEE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE);
const EMPLOYEE_DETAIL_ROUTES = [
    ROUTE_KEYS.R_EMPLOYEE_DETAILS,
    ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
];

// fetchEmployeeDetailsIfNotAlreadyAvailableEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_EMPLOYEE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !isEmployeeDetailsDataAvailable(getState()),
    processReturn: ({ action }) => EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({ id: action.payload.id }),
    latest: true,
});

// fetchEmployeeDetailsEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: [
        ...EMPLOYEE_DETAIL_ROUTES,
        FETCH_EMPLOYEE_DETAILS,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const id = action.payload.id;

            dispatch(fetchCompanyMedicalCentersActions.trigger({ companyCode }));

            // When in onboarding wizard, we don't need all data, only the details.
            if (!isOnboardingWizardRoute(state)) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeePersonalRisks({ id }));
                dispatch(fetchEmployeeCoursesActions.trigger({ id, showFullFamily }));
                dispatch(fetchCompanyCostCentersActions.trigger({ companyCode }));
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.trigger({ id }));

                if (!areExaminationReasonsAvailable(state)) {
                    dispatch(fetchExaminationReasons());
                }

                await Promise.all([
                    fetchEmployeeDetails(id, { api, dispatch }),
                    fetchEmployeeStatutes(id, { api, dispatch }),
                    fetchEmployeeAbsences(id, { api, dispatch }),
                ]);

                const stateAfterFetchingEmployeeDetails = getState();
                const employeeDetails = getSelectedEmployee(stateAfterFetchingEmployeeDetails);
                const employeeId = path<number>(['employeeId'], employeeDetails);
                const functionId = path<number>(['function', 'id'], employeeDetails);

                // Fetch employee medical examinations after receiving employee id
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeMedicalExaminations({ id, employeeId }));

                // Fetch employee function risks and reasearches after receiving function id
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeFunctionRisksAndResearchesActions.trigger({
                    functionId,
                }));
            } else {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.trigger({ id }));
                await fetchEmployeeDetails(id, { api, dispatch });
            }

            done();
        } catch (err) {
            done();
        }
    },
    latest: true,
});

// fetchSmallEmployeeDetailsEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_SMALL_EMPLOYEE_DETAILS,
    async processMultiple({ api, action }, dispatch, done) {
        await fetchEmployeeDetails(action.payload.id, { api, dispatch });
        done();
    },
    latest: true,
});

// fetchSmallEmployeeDetailsByInszEpic
createEpic<IFetchEmployeeDetailsByInszPayload>({
    onActionType: FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    async processMultiple({ api, action }, dispatch, done) {
        await fetchEmployeeDetailsByInsz(action.payload, { api, dispatch });
        done();
    },
    latest: true,
});

export async function fetchEmployeeDetails(id, { api, dispatch }: IParallelCallInput) {
    try {
        const employee = await api.admin.employee.fetchEmployeeDetails(id);
        const employeeStatus = await api.admin.employee.fetchEmployeeStatus(employee.id);

        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatusActions.succeeded({ ...employeeStatus, id }));
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetailsSucceeded(employee));
    } catch (error) {
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetailsFailed(error));
    }
}

export async function fetchEmployeeDetailsByInsz(
    payload: IFetchEmployeeDetailsByInszPayload,
    { api, dispatch }: IParallelCallInput,
) {
    try {
        const employee = await api.admin.employee.fetchEmployeeByInsz({
            nationalRegisterNumber: payload.nationalRegisterNumber,
            selectedCompanyCompanyCode: payload.companyCode,
        });

        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetailsSucceeded(employee));
    } catch (error) {
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetailsFailed(error));
    }
}

async function fetchEmployeeStatutes(id, { api, dispatch }: IParallelCallInput) {
    try {
        const statutes = await api.admin.employee.fetchEmployeeStatutes(id);
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutesSucceeded(statutes));
    } catch (error) {
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutesFailed(error));
    }
}

async function fetchEmployeeAbsences(id, { api, dispatch }: IParallelCallInput) {
    try {
        const absences = await api.admin.employee.fetchEmployeeAbsences(id);
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeAbsencesSucceeded(absences));
    } catch (error) {
        dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeAbsencesFailed(error));
    }
}

// fetchEmployeeStatutesEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_EMPLOYEE_STATUTES,
    async processMultiple({ api, action }, dispatch) {
        return fetchEmployeeStatutes(action.payload.id, { api, dispatch });
    },
    latest: true,
});

// fetchEmployeAbsencesEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_EMPLOYEE_ABSENCES,
    async processMultiple({ api, action }, dispatch) {
        return fetchEmployeeAbsences(action.payload.id, { api, dispatch });
    },
    latest: true,
});

// updateEmployeeEpic
createEpic<IUpdateEmployeePayload>({
    onActionType: UPDATE_EMPLOYEE,
    latest: false, // because each update has it's own async status
    async processMultiple({ api, action, getState }, dispatch, done) {
        await updateEmployee(action.payload, { api, dispatch }, getState);
        done();
    },
});

export async function updateEmployee(
    payload: IUpdateEmployeePayload,
    { api, dispatch }: IParallelCallInput,
    getState: () => IState,
) {
    try {
        const result = await api.admin.employee.updateEmployee(payload);

        dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeSucceeded({
            ...result,
            requestId: payload.requestId,
        }));

        const idTypedPayload = payload as IUpdateEmloyeeByIdPayload;
        if (idTypedPayload.updatedFromAddEmailList) {
            const state = getState();
            const employeesWithoutEmail = getEmployeesWithoutEmail(state);

            const updatedEmployees = employeesWithoutEmail.map((item) => {
                if (item.id === idTypedPayload.id) {
                    return {
                        ...item,
                        ...idTypedPayload.employeeData,
                    };
                }
                return item;
            });

            dispatch(fetchEmployeesWithoutEmailActions.succeeded(updatedEmployees));
        }

    } catch (error) {
        dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeFailed({
            ...error,
            requestId: payload.requestId,
        }));
    }
}

// updateEmployeeAllFieldsEpic
createEpic<IUpdateEmployeeAllFieldsPayload>({
    onActionType: UPDATE_EMPLOYEE_ALL_FIELDS,
    async processReturn({ api, action }) {
        try {
            const result = await api.admin.employee.updateEmployee(action.payload);
            return EMPLOYEE_INFO_ACTIONS.updateEmployeeAllFieldsSucceeded(result);
        } catch (error) {
            return EMPLOYEE_INFO_ACTIONS.updateEmployeeAllFieldsFailed(error);
        }
    },
    latest: false,
});

// updateEmployeeEmploymentEpic
createEpic<IUpdateEmployeeEmploymentPayload>({
    onActionType: UPDATE_EMPLOYEE_EMPLOYMENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const {
                companyCode, customerFunctionId,
                dateInFunction, dateInService, id,
                duplicate,
            } = action.payload;
            const shouldUseNewId = isInThePast(dateInFunction) || isToday(dateInFunction);

            const newId = await api.admin.employee.updateEmployeeEmployment({
                id,
                companyCode,
                customerFunctionId,
                dateInFunction,
                dateInService,
            });

            if (duplicate && duplicate.risks) {
                await api.admin.employee.duplicateEmployeeRisks({
                    idFrom: id,
                    idTo: newId,
                    absences: false,
                    risks: duplicate.risks,
                    statutes: false,
                });
            }

            const newState = getState();
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeEmploymentSucceeded({
                id: newId,
                shouldUseNewId,
            }));
            const currentRouteKey = getRouteKey(newState);
            if (shouldUseNewId && EMPLOYEE_DETAIL_ROUTES.includes(currentRouteKey)) {
                dispatch(redirectToRoute(
                    currentRouteKey,
                    {
                        ...getRoutePayload(newState),
                        id: newId,
                    },
                    getQueryParams(newState)),
                );
            } else {
                // Refresh the current employee details
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({
                    id,
                }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeEmploymentFailed(error));
            done();
        }
    },
    latest: false,
});

// setEmployeeOutOfServiceEpic
createEpic<ISetEmployeeOutOfServicePayload>({
    onActionType: SET_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const employeeToEdit = getSelectedEmployee(state);
            const wasFutureEmployee = employeeToEdit && !isInThePast(employeeToEdit.dateInService);

            await api.admin.employee.setEmployeeOutOfService(action.payload);

            if (wasFutureEmployee) {
                dispatch(navigateTo(ROUTE_KEYS.R_EMPLOYEES));
                dispatch(EMPLOYEE_INFO_ACTIONS.setEmployeeOutOfServiceSucceeded());
                return done();
            }

            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.setEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.setEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// changeEmployeeOutOfServiceEpic
createEpic<IChangeEmployeeOutOfServicePayload>({
    onActionType: CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.changeEmployeeOutOfService(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.changeEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.changeEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// clearEmployeeOutOfServiceEpic
createEpic<IClearEmployeeOutOfServicePayload>({
    onActionType: CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.clearEmployeeOutOfService(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.clearEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.clearEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// updateEmployeeRiskEpic
createEpic<IUpdateEmployeeRiskPayload>({
    onActionType: UPDATE_EMPLOYEE_RISK,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.risks.updateRisk(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutes({ id: selectedEmployee.id }));
            }
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeRiskActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeRiskActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// removeEmployeeRiskEpic
createEpic<IRemoveEmployeeRiskPayload>({
    onActionType: REMOVE_EMPLOYEE_RISK,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.risks.removeRisk(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutes({ id: selectedEmployee.id }));
            }
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeRiskActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeRiskActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// updateEmployeeStatuteEpic
createEpic<IUpdateEmployeeStatutePayload>({
    onActionType: UPDATE_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.statutes.updateStatute(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutes({ id: selectedEmployee.id }));
            }
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeStatutesActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeStatutesActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// removeEmployeeStatuteEpic
createEpic<IRemoveEmployeeStatutePayload>({
    onActionType: REMOVE_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.statutes.removeStatute(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutes({ id: selectedEmployee.id }));
            }
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeStatuteActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeStatuteActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// addEmployeeStatuteEpic
createEpic<IAddEmployeeStatutePayload>({
    onActionType: ADD_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeeStatute(payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeStatutes({ id: payload.id }));
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeStatuteSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeStatuteFailed(error));
            done();
        }
    },
    latest: false,
});

// addEmployeeAbsenceEpic
createEpic<IAddEmployeeAbsencePayload>({
    onActionType: ADD_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeeAbsence(payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeAbsences({ id: payload.id }));
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeAbsenceFailed(error));
            done();
        }
    },
    latest: false,
});

// updateEmployeeAbsenceEpic
createEpic<IUpdateEmployeeAbsencePayload>({
    onActionType: UPDATE_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.updateEmployeeAbsence(payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeAbsences({ id: payload.id }));
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeAbsenceFailed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// updateEmployeeCostCenterEpic
createEpic<IUpdateEmployeeCostCenterPayload>({
    onActionType: UPDATE_EMPLOYEE_COST_CENTER,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.updateEmployeeCostCenter(payload);

            // Refresh the current employee details
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeDetails({
                id: payload.id,
            }));

            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeCostCenterSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeCostCenterFailed(error));
            done();
        }
    },
    latest: false,
});

// removeEmployeeAbsenceEpic
createEpic<IRemoveEmployeeAbsencePayload>({
    onActionType: REMOVE_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.removeEmployeeAbsence(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeAbsences({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(EMPLOYEE_INFO_ACTIONS.removeEmployeeAbsenceFailed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// fetchEmployeeJobStudent
createEpic<IFetchEmployeeJobStudentPayload>({
    onActionType: FETCH_EMPLOYEE_JOB_STUDENT,
    async processReturn({ api, action }) {
        try {
            const jobStudent = await api.admin.employee.fetchEmployeeJobStudent({ id: action.payload.id });
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.succeeded(jobStudent);
        } catch (error) {
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.failed(error);
        }
    },
    latest: true,
});

// addEmployeeJobStudent
createEpic<IAddEmployeeJobStudentPayload>({
    onActionType: ADD_EMPLOYEE_JOB_STUDENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            if (action.payload.emailAddressToUpdate) {
                const updateEmployeePayload = {
                    id: action.payload.id,
                    requestId: getUpdateEmployeeRequestId(action.payload.id, 'email'),
                    employeeData: {
                        email: action.payload.emailAddressToUpdate,
                    },
                };
                await updateEmployee(updateEmployeePayload, { api, dispatch }, getState);
            }
            await api.admin.employee.addEmployeeJobStudent(action.payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.trigger({ id: action.payload.id }));
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeJobStudentActions.succeeded({}));
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeeJobStudentActions.failed(error));
        }
        return done();
    },
    latest: true,
});

// updateEmployeeJobStudent
createEpic<IUpdateEmployeeJobStudentPayload>({
    onActionType: UPDATE_EMPLOYEE_JOB_STUDENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            if (action.payload.emailAddressToUpdate) {
                const updateEmployeePayload = {
                    id: action.payload.id,
                    requestId: getUpdateEmployeeRequestId(action.payload.id, 'email'),
                    employeeData: {
                        email: action.payload.emailAddressToUpdate,
                    },
                };
                await updateEmployee(updateEmployeePayload, { api, dispatch }, getState);
            }
            await api.admin.employee.updateEmployeeJobStudent(action.payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeJobStudentActions.trigger({ id: action.payload.id }));
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeJobStudentActions.succeeded({}));
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.updateEmployeeJobStudentActions.failed(error));
        }
        return done();
    },
    latest: true,
});

// fetchEmployeeRisksAndResearchesEpic
createEpic<IFetchEmployeeRisksAndResearchesPayload>({
    onActionType: FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    async processReturn({ api, action }) {
        try {
            const risksAndResearches = await api.admin.employee
                .fetchEmployeeRisksAndResearches(action.payload.id.toString());
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeRisksAndResearchesSucceeded(risksAndResearches);
        } catch (error) {
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeRisksAndResearchesFailed(error);
        }
    },
    latest: true,
});

// fetchEmployeeRisksAndResearchesEpic
createEpic<IFetchEmployeeFunctionRisksAndResearchesPayload>({
    onActionType: FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    async processReturn({ api, action }) {
        try {
            const risks = await api.admin.functions
                .fetchCompanyFunctionRisks(action.payload.functionId.toString());
            const researches = await api.admin.functions
                .fetchCompanyFunctionResearches(action.payload.functionId.toString());

            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeFunctionRisksAndResearchesActions.succeeded({
                risks,
                researches,
            });
        } catch (error) {
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeeFunctionRisksAndResearchesActions.failed(error);
        }
    },
    latest: true,
});

// fetchEmployeePersonalRisksEpic
createEpic<IFetchEmployeePersonalRisksPayload>({
    onActionType: FETCH_EMPLOYEE_PERSONAL_RISKS,
    async processReturn({ api, action }) {
        try {
            const personalRisks = await api.admin.employee.fetchEmployeePersonalRisks(action.payload.id.toString());
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeePersonalRisksSucceeded(personalRisks);
        } catch (error) {
            return EMPLOYEE_INFO_ACTIONS.fetchEmployeePersonalRisksFailed(error);
        }
    },
    latest: true,
});

// addEmployeePersonalRiskEpic
createEpic<IAddPersonalRiskPayload>({
    onActionType: ADD_EMPLOYEE_PERSONAL_RISK,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeePersonalRisk(payload);
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeRisksAndResearches({ id: payload.employee.id }));
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeePersonalRisks({ id: payload.employee.id }));
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeePersonalRiskSucceeded());
            done();
        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.addEmployeePersonalRiskFailed(error));
            done();
        }
    },
    latest: false,
});

// fetchEmployeeMedicalExaminationsEpic
createEpic<IFetchEmployeeMedicalExaminationsActionPayload>({
    onActionType: FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    async processMultiple({ api, action, getState }, dispatch, done) {
        const state = getState();
        const { id, employeeId } = action.payload;

        try {
            const companyCode = getSelectedSeatCompanyCode(state);
            const locale = getLocale(state);
            let planned = [];

            // Fetch planned medical examinations from new BFF endpoint
            if (employeeId) {
                planned = await api.admin.employee.fetchEmployeePlannedMedicalExaminations({
                    id: employeeId,
                    companyCode,
                    locale,
                });
            }

            const { executed, next } = await api.admin.employee.fetchEmployeeExectutedAndNextMedicalExaminations({
                id,
            });

            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeMedicalExaminationsSucceeded({ planned, executed, next }));

            // Fetch executed medical examination detail
            if (getRouteKey(state) === ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL) {
                dispatch(fetchExecutedMedicalExaminationDetail(getRoutePayload(state)));
            }

        } catch (error) {
            dispatch(EMPLOYEE_INFO_ACTIONS.fetchEmployeeMedicalExaminationsFailed(error));
        }

        done();
    },
    latest: true,
});
