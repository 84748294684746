import React, { PureComponent } from 'react';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { getSelectedCompanyCode } from '../../../../../redux/company/selected/selectors';
import api from '../../../../../api';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import Loader from '../../../../common/waiting/Loader';
import presentDownloadedFile from '../../../../../utils/file/presentDownloadedFile';
import connect from '../../../../../utils/libs/redux/connect';

interface IPublicProps {
    attachmentId: string;
    linkText: string;
}

interface IPrivateProps {
    companyCode: string;
}

interface IComponentState {
    downloadStatus: IAsyncFieldInfo;
}

class DownloadAttachmentAction extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            downloadStatus: getInitialAsyncInfo(),
        };

        this.downloadDocument = this.downloadDocument.bind(this);
        this.isDownloading = this.isDownloading.bind(this);
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
    }

    public render() {
        const { linkText } = this.props;
        const { downloadStatus } = this.state;

        return (
            <>
                <Loader show={downloadStatus.status} />

                <span
                    className="download-attachment"
                    onClick={this.downloadDocument}
                >
                    {linkText}
                </span>

                <ErrorDialog
                    asyncInfo={downloadStatus}
                    titleTranslationKey="account.message_center.detail.body.download_document.error_title"
                    hideRealErrorMessage={true}
                    onCloseDialog={this.onCloseErrorDialog}
                />
            </>
        );
    }

    private async downloadDocument() {
        this.updateDownloadStatus(AsyncStatus.Busy);

        const { companyCode, attachmentId } = this.props;

        try {
            const document = await api.user.inbox.fetchMessageAttachment({
                companyCode,
                attachmentId,
            });

            this.updateDownloadStatus(AsyncStatus.Success);

            presentDownloadedFile(document);
        } catch (e) {
            this.updateDownloadStatus(AsyncStatus.Error, e);
        }
    }

    private updateDownloadStatus(status: AsyncStatus, error = null) {
        this.setState({
            downloadStatus: {
                status,
                error,
            },
        });
    }

    private isDownloading() {
        return this.state.downloadStatus.status === AsyncStatus.Busy;
    }

    private onCloseErrorDialog() {
        // remove error status
        this.setState({
            downloadStatus: {
                status: this.state.downloadStatus.status === AsyncStatus.Error
                    ? AsyncStatus.Initial
                    : this.state.downloadStatus.status,
                error: null,
            },
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            companyCode: getSelectedCompanyCode(state),
        };
    },
})(DownloadAttachmentAction);

function getInitialAsyncInfo() {
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
}
