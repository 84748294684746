import {
    IAutoPlanApiResponse,
    IAutoPlanEmployeePayload,
    TAutoPlanEmployeeApiResponse,
} from '../../../../models/interventions/timeslots';
import { post } from '../../../../utils/api/requestWrapper';
import { ONE_MINUTE } from '../../../../utils/core/time/periodsInMillis';

import { URL } from '../timeSlots.const';

export const autoPlanEmployees = (payload: IAutoPlanEmployeePayload[]) =>
    post<TAutoPlanEmployeeApiResponse[]>({
        url: URL.AUTO_PLAN,
        body: payload,
        mapResponse: (response: IAutoPlanApiResponse) => response.plannedEmployees,
        timeoutInMillis: 5 * ONE_MINUTE,
    });
