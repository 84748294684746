import React from 'react';
import './theme-of-the-month.scss';
import { IThemeOfTheMonth } from '../../../../../models/general/cms';
import { getAsyncThemeOfTheMonth } from '../../../../../redux/entities/general/selectors';
import OutgoingLink from '../../../../common/navigation/OutgoingLink';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import EntityWrapper from '../../../../common/widget/EntityWrapper';

const CLASS_NAME = 'ThemeOfTheMonth';

export default function ThemeOfTheMonth() {
    return (
        <div className={CLASS_NAME}>
            <EntityWrapper
                asyncEntitySelector={getAsyncThemeOfTheMonth}
                renderData={renderThemeOfTheMonth}
                fetch={{
                    useTinyLoader: true,
                }}
            />
        </div>
    );

    function renderThemeOfTheMonth({ data: theme }: { data: IThemeOfTheMonth }) {
        return (
            <OutgoingLink
                href={theme.url}
                className={`${CLASS_NAME}__content`}
            >
                <h3><Translate msg="home.dashboard.suggestions.entry_points.theme_of_the_month.title" /></h3>
                <p>{theme.title}</p>
                <figure>
                    {theme.icon && (
                        <img src={theme.icon.url} />
                    )}
                </figure>
                <Icon typeName="chevron-right" />
            </OutgoingLink>
        );
    }
}
