import { IAutoPlanEmployeePayload } from '../../../models/interventions/timeslots';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IMedicalExaminationToAdd,
    IExaminationReason,
} from '../../../models/interventions/medicalExaminations';
import { removeEmptyPropsFromObject } from '../../core/object/objectProps';

export function mapMedExamsToAutoPlanPayload(
    props: {
        medicalExaminationsToAdd: IMedicalExaminationToAdd[],
        entity: IPeriodicHealthAssessmentAutomaticEntity,
        examinationReason: IExaminationReason,
    }): IAutoPlanEmployeePayload[] {

    const { entity, medicalExaminationsToAdd, examinationReason: examinationReasonOfWizard } = props;

    return medicalExaminationsToAdd.map((medExam) => {
        const medExamToPlan: IAutoPlanEmployeePayload = {
            startDate: medExam.startDate || entity.startDate,
            startTime: medExam.startTime || entity.startTime,
            endTime: medExam.endTime || entity.endTime,
            examinationReason: {
                id: (medExam.examinationReason && medExam.examinationReason.id) ?
                    medExam.examinationReason.id : examinationReasonOfWizard.id,
            },
            employee: {
                id: medExam.employee.id,
            },
            ...!!medExam.planningRequestId && {
                planningRequestId: medExam.planningRequestId,
            },
            company: {
                companyCode: medExam.company.companyCode,
            },
        };

        return removeEmptyPropsFromObject(medExamToPlan);
    });
}
