import { object, array } from 'yup';
import string from '../../../../../../../../utils/libs/yup/string';
import number from '../../../../../../../../utils/libs/yup/number';

export const fields = {
    seats: 'seats',
};

export const schema = object({
    [fields.seats]: array().of(object({
        companyCode: string().required(),
        name: string(),
        id: number(),
    })).min(1).required(),
});
