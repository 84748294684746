import { toSuccessType } from '../../utils/libs/redux/typeGenerator';

export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_LOCALE_SUCCEEDED = toSuccessType(SWITCH_LOCALE);

export const RESET_TRANSLATIONS_REFRESHED = 'RESET_TRANSLATIONS_REFRESHED';

export const REFRESH_TRANSLATIONS = 'REFRESH_TRANSLATIONS';

export const TOGGLE_TRANSLATION_IDS = 'TOGGLE_TRANSLATION_IDS';

export const FETCH_TRANSLATIONS_AND_ALL_CONSTANTS = 'FETCH_TRANSLATIONS_AND_ALL_CONSTANTS';
