import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../index';
import { IReducerState, reducerKey } from './reducer';
import {
    AccessLevel,
    IAccessLevel,
    Permission,
    PermissionsAllowedIfDismissedCompany,
} from '../../models/auth/authorisation';
import ROUTE_KEYS from '../../routeKeys';
import { getRoute } from '../../routes';
import { formatPersonName, NO_PERSON_NAME } from '../../utils/formatting/formatPerson';
import { IRoute } from '../../utils/routing/typings';
import { didCompanyOnceUsePreventionUnits, didCompanyOnceUseFlatFee } from '../preventionUnits/selectors';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { isSelectedCompanyDismissed } from '../company/selected/selectors';
import { IUserProfile } from '../../models/auth/authentication';
import { AdminType } from '../../models/user/userAccount';
import { IRedirectTo } from '../../models/auth/auth0';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export const isLoggedIn = (state: IState) => reducerState(state).isLoggedIn;

export const loginCount = (state: IState) => reducerState(state).loginCount || 0;

export const isLoggingIn = (state: IState) => reducerState(state).login.isDoing;

export const loginError = (state: IState) => reducerState(state).login.error;

export const getMyUserProfile = (state: IState) => reducerState(state).userProfile;

export const getMyUsername = (state: IState) => {
    const profile = getMyUserProfile(state);
    if (profile) {
        return profile.username;
    }
    return null;
};

export const getPreviousUsername = (state: IState) => reducerState(state).previousUsername;

export const getMyUserAccessLevel = (state: IState) =>
    isUserProfileInitialized(state) ? getMyUserProfile(state).accessLevel : null;

const getMyUserExtraPermissions = (state: IState) =>
    isUserProfileInitialized(state) ? getMyUserProfile(state).accessLevel.extraPermissions : NO_RERENDER.EMPTY_LIST;

export const getMyCompanies = (state: IState) => {
    const userProfile = getMyUserProfile(state);
    return userProfile ? userProfile.companies : NO_RERENDER.EMPTY_LIST;
};

export const getDisplayName = (state: IState) => {
    const userProfile = getMyUserProfile(state);
    if (userProfile) {
        const formattedName = formatPersonName(userProfile);
        return formattedName === NO_PERSON_NAME
            ? userProfile.username
            : formattedName;
    }

    return '';
};

export const getFirstName = (state: IState) => {
    const userProfile = getMyUserProfile(state);
    return userProfile ? userProfile.firstName : '';
};

export const getUsername = (state: IState) => {
    const userProfile = getMyUserProfile(state);
    return userProfile ? userProfile.username : '';
};

export const getCsrfToken = (state: IState) => reducerState(state).csrfToken;

export const isSessionExpired = (state: IState) => reducerState(state).isSessionExpired;

const getFetchMyUserInfo = (state: IState) => reducerState(state).fetchMyUserInfo;

export const getFetchMyUserAsyncInfo = makeAsyncFetchInfoSelector(getFetchMyUserInfo);

export const getMyUserInfo = (state: IState) => {
    const myUserInfo = getFetchMyUserInfo(state).data;
    return myUserInfo && myUserInfo.user;
};
export const isMyUserInfoDataAvailable = (state: IState) => !!getFetchMyUserInfo(state).data;

const getUpdateMyUserInfo = (state: IState) => reducerState(state).updateMyUserInfo;

export const getUpdateMyUserProfileAsyncInfo = makeAsyncDoInfoSelector(getUpdateMyUserInfo);

const getForgotPassword = (state: IState) => reducerState(state).forgotPassword;

export const isLoadingForgotPassword = (state: IState) => getForgotPassword(state).isDoing;

export const forgotPasswordError = (state: IState) => getForgotPassword(state).error;

export const getForgotPassworAsyncInfo = makeAsyncDoInfoSelector(getForgotPassword);

export const fetchMyUserInfoError = (state: IState) => getFetchMyUserInfo(state).error;

export const getRememberMe = (state: IState) => reducerState(state).rememberMe;

// Auth0 selectors
export const getRedirectToRoute = (state: IState): IRedirectTo => reducerState(state).redirectTo || {
    key: ROUTE_KEYS.R_HOME,
};

const getSession = (state: IState): { accessToken?: string; expiresAt?: number } =>
    reducerState(state).session || { accessToken: null, expiresAt: null };
export const getAccessToken = (state: IState): string => getSession(state).accessToken;
export const getAuth0Profile = (state: IState) => reducerState(state).profile;
export const getAuth0Session = (state: IState) => reducerState(state).session;

// Request account
const getRequestAccountAsyncField = (state: IState) => reducerState(state).requestAccount;

export const requestAccountHasAdministrators = (state: IState) =>
    getRequestAccountAdministrators(state).length > 0;

export const getRequestAccountAdministrators = (state: IState) =>
    getRequestAccountAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getRequestAccountAsyncInfo = makeAsyncFetchInfoSelector(getRequestAccountAsyncField);

// Create account
const getCreateAccountAsyncField = (state: IState) => reducerState(state).createAccount;

export const getCreateAccountAsyncInfo = makeAsyncDoInfoSelector(getCreateAccountAsyncField);

export const isRequestingOrCreatingAccount = (state: IState) => getRequestAccountAsyncField(state).isFetching ||
    getCreateAccountAsyncField(state).isDoing;

export const hasOneOfRequiredAccessLevels = (
    state: IState,
    requiredAccessLevelsCombos: Partial<IAccessLevel>[] = [],
): boolean => {
    if (requiredAccessLevelsCombos.length === 0) {
        return true;
    }

    return requiredAccessLevelsCombos
        .some((requiredAccessLevels) => hasRequiredAccessLevels(state, requiredAccessLevels));
};

export const hasRequiredAccessLevels = (
    state: IState,
    requiredAccessLevels: Partial<IAccessLevel> = {},
): boolean => {
    if (isLoggedIn(state) && isUserProfileInitialized(state)) {
        const missingAccessLevels = Object.keys(requiredAccessLevels)
            .filter((requiredAccessLevelKey: keyof IAccessLevel) => {
                const requiredAccessLevelValue = requiredAccessLevels[requiredAccessLevelKey];

                if (requiredAccessLevelKey === 'extraPermissions' && Array.isArray(requiredAccessLevelValue)) {
                    return !requiredAccessLevelValue.reduce(
                        (accumulator, current) => {
                            if (!accumulator) {
                                return false;
                            }
                            return hasPermission(state, current);
                        },
                        true,
                    );
                }

                switch (requiredAccessLevelValue) {
                    case 'W':
                        return !hasWriteAccessForAccessLevel(state, requiredAccessLevelKey);
                    case 'R':
                        return !hasReadAccessForAccessLevel(state, requiredAccessLevelKey);
                }

                return false;
            });

        return missingAccessLevels.length === 0;
    }
    return false;
};

export const hasReadAccessForAccessLevel = (state: IState, accessLevelSection: keyof IAccessLevel): boolean => {
    if (isLoggedIn(state) && isUserProfileInitialized(state)) {
        const accessLevel = getMyUserAccessLevel(state)[accessLevelSection];
        return accessLevel && (accessLevel === 'R' || accessLevel === 'W');
    }
    return false;
};

export const hasWriteAccessForAccessLevel = (state: IState, accessLevelSection: keyof IAccessLevel): boolean => {
    if (isSelectedCompanyDismissed(state)) {
        return false;
    }
    return hasExpectedAccessForAccessLevel(state, accessLevelSection, 'W');
};

export const hasNopAccessForAccessLevel = (state: IState, accessLevelSection: keyof IAccessLevel): boolean => {
    return hasExpectedAccessForAccessLevel(state, accessLevelSection, 'nop');
};

function hasExpectedAccessForAccessLevel(
    state: IState,
    accessLevelSection: keyof IAccessLevel,
    expectedAccess: AccessLevel,
): boolean {
    if (isLoggedIn(state) && isUserProfileInitialized(state)) {
        const accessLevel = getMyUserAccessLevel(state)[accessLevelSection];
        return accessLevel && accessLevel === expectedAccess;
    }

    return false;
}

export function hasPermission(state: IState, permission: Permission): boolean {
    if (isLoggedIn(state) && isUserProfileInitialized(state)) {
        if (getMyUserExtraPermissions(state).includes(permission)) {
            if (isSelectedCompanyDismissed(state)) {
                return PermissionsAllowedIfDismissedCompany.includes(permission);
            }
            return true;
        }
    }
    return false;
}

export function isMainAdministrator(state: IState): boolean {
    return hasPermission(state, Permission.CAN_ADD_USER);
}

export function userIsAdminOrSuperAdmin(state: IState) {
    const userProfile = getMyUserProfile(state) || {} as IUserProfile;
    return userProfile.admin === AdminType.Administrator || userProfile.admin === AdminType.MainAdministrator;
}

function isUserProfileInitialized(state: IState) {
    const userProfile = getMyUserProfile(state);
    return userProfile && userProfile.accessLevel;
}

export const mayUserAccessRoute = (state: IState, routeKey: ROUTE_KEYS) => {
    const targetRoute = getRoute({ routeKey });
    const hasRequiredAccessLevels = hasOneOfRequiredAccessLevels(state, targetRoute.requiredAccessLevels);

    return hasRequiredAccessLevels && doesRouteMeetGroupRequirements(state, targetRoute);
};

export const getUpdateMyAvatarAsyncInfo =
    makeAsyncDoInfoSelector((state: IState) => reducerState(state).updateMyAvatar);

const doesRouteMeetGroupRequirements = (state: IState, route: IRoute) => {
    if (!isRouteAllowedIfDismissedCompany(route)) {
        if (isSelectedCompanyDismissed(state)) {
            return false;
        }
    }

    if (isRouteOnlyAllowedIfPreventionUnits(route)) {
        return didCompanyOnceUsePreventionUnits(state);
    }

    if (isRouteOnlyAllowedIfFlatFee(route)) {
        return didCompanyOnceUseFlatFee(state);
    }

    return true;
};

export function isRouteAllowedIfDismissedCompany(route: IRoute) {
    return doesRouteContainGroup(route, ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY);
}

export function isRouteOnlyAllowedIfPreventionUnits(route: IRoute) {
    return doesRouteContainGroup(route, ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS);
}

export function isRouteOnlyAllowedIfFlatFee(route: IRoute) {
    return doesRouteContainGroup(route, ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_FLAT_FEE);
}

export function isRouteOnlyAllowedIfValidSituationHistory(route: IRoute) {
    return doesRouteContainGroup(route, ROUTE_GROUP.ONLY_ALLOWED_IF_VALID_SITUATION_HISTORY);
}

function doesRouteContainGroup(route: IRoute, routeGroup: string) {
    if (doesRouteHaveGroups(route)) {
        return route.groups.includes(routeGroup);
    }

    return false;
}

function doesRouteHaveGroups(route: IRoute) {
    return route.groups && route.groups.length > 0;
}

// Performance dashboard
const getPerformanceDashboardAccessAsyncField = (state: IState) => reducerState(state).performanceDashboardAccess;

export const getPerformanceDashboardAccessData = (state: IState) =>
    getPerformanceDashboardAccessAsyncField(state).data;

export const getFetchPerformanceDashboardAccessAsyncInfo =
    makeAsyncFetchInfoSelector(getPerformanceDashboardAccessAsyncField);

export const getSkipPerformanceDashboardWarning =
    (state: IState) => reducerState(state).skipPerformanceDashboardWarning;

export const getSkipPerformanceDashboardTutorial =
    (state: IState) => reducerState(state).skipPerformanceDashboardTutorial;

export const getIsPerformanceDashboardDialogOpen =
    (state: IState) => reducerState(state).isPerformanceDashboardDialogOpen;

const getNavigateToPerformanceDashboardAsyncField =
    (state: IState) => reducerState(state).navigateToPerformanceDashboard;

export const getNavigateToPerformanceDashboardAsyncInfo =
    makeAsyncDoInfoSelector(getNavigateToPerformanceDashboardAsyncField);

