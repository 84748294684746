import { object, ref } from 'yup';
import string from '../../../../../utils/libs/yup/string';
import { datePeriodValidation } from '../../../../../utils/libs/yup/period';

export const fields = {
    riskCode: 'riskCode',
    startDate: 'startDate',
    endDate: 'endDate',
};

export const schema = object({
    [fields.riskCode]: string().required(),
    [fields.startDate]: string().required(),
    [fields.endDate]: datePeriodValidation(ref(fields.startDate)).nullable(true),
});
