import React from 'react';
import '../questionnaires.scss';
import { ISortedColumn, SortOrder, ListColumns, SortType, ListItem } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../../routeKeys';
import {
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
    IRenderDetailContentProps,
    IShouldRenderShowAllButtonProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    getQuestionnairesScreenworkAsyncInfo,
    getQuestionnairesScreenwork,
    getQuestionnairesScreenworkDetail,
} from '../../../../redux/questionnaires/selectors';
import { IScreenworkQuestionnaire } from '../../../../models/admin/questionnaires';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { Detail } from './detail';
import { OVERVIEW_CLASS_NAME } from '../shared';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS } from '../../../../api/admin/questionnaires.api';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';

const BASE_NAME = 'questionnaires-screenwork';
const TRANSLATION_PREFIX = 'administration.questionnaires.screenwork.overview';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    seat: string;
    function: string;
    isShowAll: boolean;
}

interface IColumnNames {
    sendDate: string;
    deadline: string;
    employee: string;
    function: string;
    companyName: string;
    companyCode: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'sendDate',
    sortOrder: SortOrder.Descending,
};

const SCREENWORK_COLUMNS: ListColumns<IColumnNames> = {
    sendDate: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.send_date`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 14,
        render: (listItem) => listItem.columns.sendDate && formatDateForDisplay(listItem.columns.sendDate as string),
    },
    deadline: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.deadline`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 14,
        render: (listItem) => listItem.columns.deadline && formatDateForDisplay(listItem.columns.deadline as string),
    },
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    function: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.function`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    companyName: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    companyCode: {
        hide: true,
        percentWidth: null,
    },
};

export default function Overview() {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            className={OVERVIEW_CLASS_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK,
                asyncInfoSelector: getQuestionnairesScreenworkAsyncInfo,
                dataSelector: getQuestionnairesScreenwork,
                transformData: mapScreenworkToListItems,
                renderContent: (
                    renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <ScreenWorkList {...renderProps} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: SCREENWORK_COLUMNS,
                },
                clientSideFilterOfListData,
                transformFilterValuesToActiveFilters,
                filterValidationSchema: startEndDateSchema,
            }}
            detailConfig={{
                routeKey: ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK_DETAIL,
                asyncInfoSelector: getQuestionnairesScreenworkAsyncInfo,
                dataSelector: getQuestionnairesScreenworkDetail,
                renderHeader: undefined,
                renderContent: (renderProps: IRenderDetailContentProps<IScreenworkQuestionnaire>) =>
                    <Detail {...renderProps} />,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'questionnaires-screenwork',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

function ScreenWorkList(props: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        masterAsyncInfo,
        masterData: clientSideFilteredlistItems,
        selectedItemId,
        onItemSelected,
        footer,
        filterValues,
    } = props;

    return (
        <>
            <ListWithSorting
                columns={SCREENWORK_COLUMNS}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                onItemRowClicked={onItemSelected}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
            />
        </>
    );
}

function toListId(item: IScreenworkQuestionnaire) {
    return item.loggingId;
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS,
        isShowAll,
    } : DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS;
}

function mapScreenworkToListItems(masterData: IScreenworkQuestionnaire[]): ListItem<IColumnNames>[] {
    return masterData
        .map((item) => {
            const listItem: ListItem<IColumnNames> = {
                id: toListId(item),
                columns: {
                    deadline: item.dateTill,
                    employee: formatPersonNameFormal(item.employee),
                    function: item.employee.function.description,
                    companyName: item.employee.company.name,
                    companyCode: item.employee.company.companyCode,
                    sendDate: item.dateFrom,
                },
            };
            return listItem;
        });
}

function mapListRowForExport(questionnaire: IScreenworkQuestionnaire) {
    const {
        dateFrom,
        dateTill,
        employee: { company: { companyCode } },
        employee: { company: { name: companyName } },
        employee: { function: { description } },
        employee: { email },
        employee: { name },
        employee: { firstName },
        languageCode,
    } = questionnaire;

    return {
        dateFrom,
        dateTill,
        employee: {
            company: {
                companyCode,
                companyName,
            },
            function: {
                description,
            },
            email,
            name,
            firstName,
        },
        languageCode,
    };
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleFunctions = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'function',
        'function',
    );

    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'companyCode',
        'companyName',
    );
    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="function"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.function`}
                possibleFilterItems={possibleFunctions}
                actualFilterValue={formRenderProps.values.function}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'function',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.seat`}
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seat}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seat',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seat)) {
                return true;
            }

            const companyCodes = separateStringList(filterValues.seat);
            return companyCodes.includes(listItem.columns.companyCode as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.function)) {
                return true;
            }

            const functions = separateStringList(filterValues.function);
            return functions.includes(listItem.columns.function as string);
        });
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS.startDate,
            },
            seat: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'companyName',
                        searchColumnName: 'companyCode',
                    },
                },
            },
            function: {
                show: true,
                multiple: {
                    enable: true,
                },
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
