import isSet from '@snipsonian/core/es/is/isSet';
import { StandardAction } from 'redux-logic';
import { createEpic } from '..';
import { ICompanyCodeWithShowFullFamilyPayload, ICompanyCodePayload } from '../../models/admin/company';
import {
    IFetchAccomplishmentsContributionsOfYearPayload,
    IFetchAccomplishmentsContributionDetailsPayload,
    IFetchPreventionUnitsDetailPayload,
    IExportPreventionUnitsPayload,
    ICompanySituationHistorySuccessPayload,
    IFetchPreventionUnitsPayload,
} from '../../models/admin/preventionUnits';
import {
    fetchCompanySituationHistoryActions,
    fetchAccomplishmentsContributionsOfYearActions,
    fetchAccomplishmentsContributionDetailsActions,
    fetchPreventionUnitsActions,
    fromAccomplishmentsContributionDetailId,
    fetchPreventionUnitsDetailActions,
    exportPreventionUnitsActions,
} from './actions';
import {
    FETCH_COMPANY_SITUATION_HISTORY,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
    FETCH_PREVENTION_UNITS,
    EXPORT_PREVENTION_UNITS,
} from './types';
import {
    isCompanySituationHistoryAvailable,
    getCompanySituationHistory,
    getFlatFeeSelectedYear,
    areAccomplishmentsContributionsOfSelectedYearAvailable,
    arePreventionUnitsAvailable,
} from './selectors';
import {
    getFlatFeeYearsSortedDescending,
    getLatestStatisticalCode,
    getLatestCompanySituationYear,
    getPreviousCompanySituationYear,
} from './companySituationHistoryUtils';
import { getSelectedSeatCompanyCode, isAllSeatsSelected, getSelectedCompanyCode } from '../company/selected/selectors';
import Api from '../../api';
import { IState } from '../IState';
import ROUTE_KEYS from '../../routeKeys';
import { logCompanyStatisticalCode } from '../../utils/logging/analytics/eventLogger';
import { getLocationState } from '../location/selectors';
import { PREVENTION_UNIT_ID_TO_TYPE_MAP } from '../../config/administration.config';

// fetchCompanySituationHistoryEpic
createEpic<ICompanyCodeWithShowFullFamilyPayload>({
    onActionType: FETCH_COMPANY_SITUATION_HISTORY,
    async processMultiple({ api, action, getState }, dispatch, done) {
        await fetchCompanySituationHistory({ api, getState, dispatch, actionPayload: action.payload });

        done();
    },
    latest: false,
});

interface IFetchCompanySituationHistoryResult {
    succeeded: boolean;
    companySituationHistory?: ICompanySituationHistorySuccessPayload;
}

export async function fetchCompanySituationHistory({
    actionPayload = ({} as ICompanyCodePayload),
    api,
    getState,
    dispatch,
}: {
    actionPayload?: ICompanyCodePayload;
    api: typeof Api;
    getState: () => IState;
    dispatch: (action: StandardAction<string, {}, undefined>) => void;
}): Promise<IFetchCompanySituationHistoryResult> {
    try {
        const state = getState();
        // CompanySituationHistory is on company level, not seat level. So we can always fetch with showFullFamily
        const companyCode = actionPayload.companyCode || getSelectedCompanyCode(state);
        const showFullFamily = true;

        const companySituationHistory = await api.admin.preventionUnits.fetchCompanySituationHistory({
            companyCode,
            showFullFamily,
        });

        const latestStatisticalCode = getLatestStatisticalCode(companySituationHistory.situationHistory);
        logCompanyStatisticalCode({ companyStatisticalCode: latestStatisticalCode });

        dispatch(fetchCompanySituationHistoryActions.succeeded(companySituationHistory));

        return {
            succeeded: true,
            companySituationHistory,
        };
    } catch (error) {
        dispatch(fetchCompanySituationHistoryActions.failed(error));

        return {
            succeeded: false,
        };
    }
}

// fetchAccomplishmentsContributionsOfYearEpic
createEpic<IFetchAccomplishmentsContributionsOfYearPayload>({
    onActionType: [
        ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
        FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);
            let year = action.payload.year;

            if (!isSet(year)) {
                year = getFlatFeeSelectedYear(state); // when page refresh
            }

            if (!isSet(year)) {
                let companySituationHistory;
                if (!isCompanySituationHistoryAvailable(state)) {
                    const companySituationHistoryResult = await fetchCompanySituationHistory({
                        api,
                        getState,
                        dispatch,
                    });

                    if (!companySituationHistoryResult.succeeded) {
                        throw new Error('Situation history not available.');
                    }

                    companySituationHistory = companySituationHistoryResult.companySituationHistory;
                } else {
                    companySituationHistory = getCompanySituationHistory(state);
                }

                const flatFeeYearsSortedDesc = getFlatFeeYearsSortedDescending(companySituationHistory);

                if (!flatFeeYearsSortedDesc || flatFeeYearsSortedDesc.length === 0) {
                    throw new Error('No year found that matches the criteria.');
                }

                year = flatFeeYearsSortedDesc[0];
            }

            const accomplishmentsContributions =
                await api.admin.preventionUnits.fetchAccomplishmentsContributionsOfYear({
                    companyCode,
                    showFullFamily,
                    year,
                });

            dispatch(fetchAccomplishmentsContributionsOfYearActions.succeeded({
                year,
                accomplishmentsContributions,
            }));
        } catch (error) {
            dispatch(fetchAccomplishmentsContributionsOfYearActions.failed(error));
        }

        done();
    },
    latest: false,
});

// fetchAccomplishmentsContributionDetailsEpic
createEpic<
    IFetchAccomplishmentsContributionDetailsPayload
>({
    onActionType: [
        ROUTE_KEYS.R_FLAT_FEE_DETAIL,
        FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);

            const { year, type } = fromAccomplishmentsContributionDetailId(action.payload.yearAndType);

            if (!areAccomplishmentsContributionsOfSelectedYearAvailable(state)) {
                dispatch(fetchAccomplishmentsContributionsOfYearActions.trigger({
                    year,
                }));
            }

            const accomplishmentsContributionDetails =
                await api.admin.preventionUnits.fetchAccomplishmentsContributionDetailsOfYearAndType({
                    companyCode,
                    showFullFamily,
                    year,
                    type,
                });

            dispatch(fetchAccomplishmentsContributionDetailsActions.succeeded(accomplishmentsContributionDetails));
        } catch (error) {
            dispatch(fetchAccomplishmentsContributionDetailsActions.failed(error));
        }

        done();
    },
    latest: true,
});

// fetchPreventionUnitsEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
        ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR,
        FETCH_PREVENTION_UNITS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        if (!arePreventionUnitsAvailable(state)) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type: prevRoute } = getLocationState(state);
        return prevRoute !== action.type;
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();

            const { type: currentRouteKey } = getLocationState(state);
            const companySituationHistory = getCompanySituationHistory(state);
            const year =
                (currentRouteKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW ||
                currentRouteKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL) ?
                getLatestCompanySituationYear(companySituationHistory) :
                getPreviousCompanySituationYear(companySituationHistory);

            const payload: IFetchPreventionUnitsPayload = {
                companyCode: getSelectedSeatCompanyCode(state),
                year,
            };
            const result = await api.admin.preventionUnits.fetchPreventionUnits(payload);

            return fetchPreventionUnitsActions.succeeded(result);
        } catch (error) {
            return fetchPreventionUnitsActions.failed(error);
        }
    },
    latest: false,
});

// fetchPreventionUnitsDetailEpic
createEpic<Pick<IFetchPreventionUnitsDetailPayload, 'type'>>({
    onActionType: [
        ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL,
        ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { type } = action.payload;
            const state = getState();

            if (!arePreventionUnitsAvailable(state)) {
                dispatch(fetchPreventionUnitsActions.trigger({}));
            }

            const { type: currentRouteKey } = getLocationState(state);
            const companySituationHistory = getCompanySituationHistory(state);
            const year =
                (currentRouteKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW ||
                currentRouteKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL) ?
                getLatestCompanySituationYear(companySituationHistory) :
                getPreviousCompanySituationYear(companySituationHistory);

            const payload: IFetchPreventionUnitsDetailPayload = {
                companyCode: getSelectedSeatCompanyCode(state),
                type: PREVENTION_UNIT_ID_TO_TYPE_MAP[type],
                year,
            };
            const result = await api.admin.preventionUnits.fetchPreventionUnitsDetail(payload);

            dispatch(fetchPreventionUnitsDetailActions.succeeded(result));
        } catch (error) {
            dispatch(fetchPreventionUnitsDetailActions.failed(error));
        }
        return done();
    },
    latest: true,
});

// Export prevention units
createEpic<IExportPreventionUnitsPayload>({
    onActionType: EXPORT_PREVENTION_UNITS,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);

            const result = await api.admin.preventionUnits.exportPreventionUnits({
                companyCode,
                year: action.payload.year,
            });

            return exportPreventionUnitsActions.succeeded(result);
        } catch (error) {
            return exportPreventionUnitsActions.failed(error);
        }
    },
    latest: false,
});
