import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    LOGIN,
    LOGOUT,
    FORGOT_PASSWORD,
    FETCH_MY_USER_INFO,
    UPDATE_MY_USER_INFO,
    REMEMBER_ME, RESET_REMEMBER_ME,
    UPDATE_ACCESS_LEVEL,
    ADD_EXTRA_PERMISSIONS_FOR_COMPANY,
    REQUEST_ACCOUNT,
    CREATE_ACCOUNT,
    UPDATE_MY_AVATAR,
    FETCH_PERFORMANCE_DASHBOARD_ACCESS,
    SET_SKIP_PERFORMANCE_DASHBOARD_WARNING,
    SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL,
    TOGGLE_PERFORMANCE_DASHBOARD_DIALOG,
    NAVIGATE_TO_PERFORMANCE_DASHBOARD,
    UPDATE_AUTH0_SESSION,
} from './types';
import {
    IAsyncDoField,
    getAsyncDoInitialState,
    createActionHandlersForType,
    createAsyncDoActionHandlers,
    registerReducer,
    createActionHandler,
} from '../index';
import { IAccessLevel, Permission } from '../../models/auth/authorisation';
import {
    ILoggedInUser,
    IMyUserInfo,
    ILogoutPayload,
    IUserProfile,
} from '../../models/auth/authentication';
import { ITraceableApiError } from '../../models/general/error';
import ROUTE_KEYS from '../../routeKeys';
import { IUserAccount } from '../../models/user/userAccount';
import {
    getAsyncFetchInitialState, createAsyncFetchActionHandlers,
} from '../../utils/libs/redux/async/asyncReducerUtils';
import { IAsyncFetchField } from '../../models/general/redux';
import { REDUCER_KEYS } from '../../config/redux.config';
import { SET_COMPANY_ONBOARDING_VARIABLES } from '../company/selected/types';
import { ISetCompanyOnboardingVariablesPayload } from '../../models/admin/company';
import { PERMISSIONS_DEPENDENT_ON_COMPANY } from '../../config/authorisation';
import { IPerformanceDashboardAccessData } from '../../models/general/performance';
import {
    IAuthSessionProfileData,
    IAuthSessionTokenData,
    IRedirectTo,
    IUpdateAuth0SessionPayload,
} from '../../models/auth/auth0';

export const reducerKey = REDUCER_KEYS.AUTH;

export interface IReducerState {
    isLoggedIn: boolean;
    loginCount: number;
    login: IAsyncDoField;
    logout: IAsyncDoField;
    userProfile: IUserProfile;
    csrfToken: string;
    isSessionExpired: boolean;
    forgotPassword: IAsyncDoField;
    fetchMyUserInfo: IAsyncFetchField<ILoggedInUser<IMyUserInfo>>;
    updateMyUserInfo: IAsyncDoField;
    createAccount: IAsyncDoField;
    requestAccount: IAsyncFetchField<IUserAccount[]>;
    rememberMe: boolean;
    updateMyAvatar: IAsyncDoField;
    previousUsername: string;
    performanceDashboardAccess: IAsyncFetchField<IPerformanceDashboardAccessData>;
    skipPerformanceDashboardWarning: boolean;
    skipPerformanceDashboardTutorial: boolean;
    isPerformanceDashboardDialogOpen: boolean;
    navigateToPerformanceDashboard: IAsyncDoField;

    session?: IAuthSessionTokenData;
    profile?: IAuthSessionProfileData;
    redirectTo?: IRedirectTo;
}

const initialState: IReducerState = {
    isLoggedIn: false,
    loginCount: null,
    login: getAsyncDoInitialState(),
    logout: getAsyncDoInitialState(),
    userProfile: null,
    csrfToken: null,
    isSessionExpired: false,
    forgotPassword: getAsyncDoInitialState(),
    fetchMyUserInfo: getAsyncFetchInitialState(),
    updateMyUserInfo: getAsyncDoInitialState(),
    createAccount: getAsyncDoInitialState(),
    requestAccount: getAsyncFetchInitialState(),
    rememberMe: false,
    updateMyAvatar: getAsyncDoInitialState(),
    previousUsername: null,
    performanceDashboardAccess: getAsyncFetchInitialState(),
    skipPerformanceDashboardWarning: false,
    skipPerformanceDashboardTutorial: false,
    isPerformanceDashboardDialogOpen: false,
    navigateToPerformanceDashboard: getAsyncDoInitialState(),

    session: null,
    profile: null,
    redirectTo: null,
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(ROUTE_KEYS.R_LOGIN)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                requestAccount: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(LOGIN)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                isLoggedIn: false,
                loginCount: null,
                login: {
                    isDoing: true,
                    isDone: false,
                    error: null,
                },
                isSessionExpired: false,
                fetchMyUserInfo: {
                    ...oldState.fetchMyUserInfo,
                    error: null,
                },
            };
        })
        .onSuccess<ILoggedInUser & { loginCount: number }>(({ oldState, payload }) => {
            return {
                ...oldState,
                isLoggedIn: true,
                loginCount: payload.loginCount,
                login: {
                    isDoing: false,
                    isDone: true,
                    error: null,
                },
                userProfile: toUserProfile(payload.user),
                csrfToken: payload.csrfToken,
            };
        })
        .onFail<ITraceableApiError>(({ oldState, payload }) => {
            return {
                ...oldState,
                isLoggedIn: false,
                loginCount: null,
                login: {
                    isDoing: false,
                    isDone: false,
                    error: payload,
                },
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(UPDATE_AUTH0_SESSION)
        .onTrigger<IUpdateAuth0SessionPayload>(({ oldState, payload: { session, profile } }) => ({
        ...oldState,
        session,
        profile,
    })).create(),
    ...createActionHandlersForType<IReducerState>(LOGOUT)
        .onTrigger<ILogoutPayload>(
            ({ oldState, payload }) => {
                return {
                    ...initialState,
                    logout: {
                        isDoing: true,
                        isDone: false,
                        error: null,
                    },
                    isSessionExpired: !!payload.isSessionExpired,
                    ...determineRememberMeAndPreviousUsernameOnLogout({ oldState }),
                    skipPerformanceDashboardTutorial: oldState.skipPerformanceDashboardTutorial,
                    skipPerformanceDashboardWarning: oldState.skipPerformanceDashboardWarning,
                };
            },
            ROUTE_KEYS.R_LOGOUT,
        )
        .onSuccess(({ oldState }) => {
            return {
                ...oldState,
                logout: {
                    isDoing: false,
                    isDone: true,
                    error: null,
                },
                session: undefined,
                profile: undefined,
            };
        })
        .onFail<ITraceableApiError>(({ oldState, payload }) => {
            return {
                ...oldState,
                logout: {
                    isDoing: false,
                    isDone: false,
                    error: payload,
                },
            };
        })
        .create(),
    ...createAsyncDoActionHandlers({
        baseActionType: FORGOT_PASSWORD,
        fieldName: 'forgotPassword',
    }),
    ...createAsyncDoActionHandlers({
        baseActionType: CREATE_ACCOUNT,
        fieldName: 'createAccount',
    }),
    ...createAsyncFetchActionHandlers<IUserAccount[], IReducerState, IUserAccount[]>({
        baseActionType: REQUEST_ACCOUNT,
        fieldName: 'requestAccount',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ILoggedInUser<IMyUserInfo>, IReducerState, ILoggedInUser<IMyUserInfo>>({
        baseActionType: FETCH_MY_USER_INFO,
        fieldName: 'fetchMyUserInfo',
        resetDataOnTrigger: false,
        transformStateOnSuccess: ({ payload, oldState }) => {
            const { user, csrfToken } = payload as ILoggedInUser<IMyUserInfo>;
            return {
                ...oldState,
                userProfile: toUserProfile(user, oldState.userProfile),
                csrfToken,
            };
        },
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_MY_USER_INFO,
        fieldName: 'updateMyUserInfo',
    }),
    ...createActionHandlersForType<IReducerState>(REMEMBER_ME)
        .onTrigger<{ username: string }>(({ oldState, payload }) => {
            return {
                ...oldState,
                rememberMe: true,
                userProfile: {
                    ...oldState.userProfile,
                    username: payload.username,
                },
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(RESET_REMEMBER_ME)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                rememberMe: false,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(UPDATE_ACCESS_LEVEL)
        .onTrigger<IAccessLevel>(({ oldState, payload }) => {
            if (!oldState.userProfile || !oldState.userProfile.accessLevel) {
                return oldState;
            }

            return {
                ...oldState,
                userProfile: {
                    ...oldState.userProfile,
                    accessLevel: {
                        ...payload,
                    },
                },
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(ADD_EXTRA_PERMISSIONS_FOR_COMPANY)
        .onTrigger<Permission[]>(({ oldState, payload }) => {
            if (!oldState.userProfile || !oldState.userProfile.accessLevel) {
                return oldState;
            }
            const currentExtraPermissions = oldState.userProfile.accessLevel.extraPermissions;

            const extraPermissions = payload.reduce(
                (extraPermissionsAccumulator, permission) => {
                    if (!extraPermissionsAccumulator.includes(permission)) {
                        return extraPermissionsAccumulator.concat([permission]); // returns new array
                    }
                    return extraPermissionsAccumulator;
                },
                currentExtraPermissions,
            );

            PERMISSIONS_DEPENDENT_ON_COMPANY.forEach((permission) => {
                const indexOfPermission = extraPermissions.indexOf(permission);
                if (indexOfPermission !== -1 && !payload.includes(permission)) {
                    extraPermissions.splice(indexOfPermission, 1);
                }
            });

            return {
                ...oldState,
                userProfile: {
                    ...oldState.userProfile,
                    accessLevel: {
                        ...oldState.userProfile.accessLevel,
                        extraPermissions,
                    },
                },
            };
        })
        .create(),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_MY_AVATAR,
        fieldName: 'updateMyAvatar',
    }),
    [SET_COMPANY_ONBOARDING_VARIABLES]: createActionHandler<IReducerState, ISetCompanyOnboardingVariablesPayload>(
        ({ oldState, payload }) => {
            const { companyCode, statusNotPaid, startWizardNewCustomer } = payload;

            if (!oldState.userProfile || !oldState.userProfile.companies) {
                return oldState;
            }

            const changedCompanies = oldState.userProfile.companies.slice();
            const matchingCompany = changedCompanies
                .find((company) => company.companyCode === companyCode);

            if (!matchingCompany) {
                return oldState;
            }

            matchingCompany.statusNotPaid = statusNotPaid;
            matchingCompany.startWizardNewCustomer = startWizardNewCustomer;

            return {
                ...oldState,
                userProfile: {
                    ...oldState.userProfile,
                    companies: changedCompanies,
                },
            };
        },
    ),
    // eslint-disable-next-line max-len
    ...createAsyncFetchActionHandlers<IPerformanceDashboardAccessData, IReducerState, IPerformanceDashboardAccessData>({
        baseActionType: FETCH_PERFORMANCE_DASHBOARD_ACCESS,
        fieldName: 'performanceDashboardAccess',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createActionHandlersForType<IReducerState>(SET_SKIP_PERFORMANCE_DASHBOARD_WARNING)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                skipPerformanceDashboardWarning: true,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                skipPerformanceDashboardTutorial: true,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(TOGGLE_PERFORMANCE_DASHBOARD_DIALOG)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                isPerformanceDashboardDialogOpen: !oldState.isPerformanceDashboardDialogOpen,
            };
        })
        .create(),
    ...createAsyncDoActionHandlers({
        baseActionType: NAVIGATE_TO_PERFORMANCE_DASHBOARD,
        fieldName: 'navigateToPerformanceDashboard',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.LOCAL,
    resetStateOnCompanySelection: false,
    resetStateOnSeatSelection: false,
    transformReducerStateForStorage: (reducerState: IReducerState) => ({
        isLoggedIn: reducerState.isLoggedIn,
        userProfile: reducerState.userProfile,
        csrfToken: reducerState.csrfToken,
        rememberMe: reducerState.rememberMe,
        session: reducerState.session,
        previousUsername: reducerState.previousUsername,
        skipPerformanceDashboardWarning: reducerState.skipPerformanceDashboardWarning,
        skipPerformanceDashboardTutorial: reducerState.skipPerformanceDashboardTutorial,
    }),
    resetStateOnLocaleSwitch: false,
});

function toUserProfile(userInfo: IUserProfile, currentUserProfile?: IUserProfile): IUserProfile {
    const currentExtraPermission = currentUserProfile ? currentUserProfile.accessLevel.extraPermissions : [];
    const newExtraPermissions = userInfo.accessLevel.extraPermissions.filter(
        (item) => !currentExtraPermission.includes(item),
    ).concat(currentExtraPermission);

    return {
        ...currentUserProfile,
        ...userInfo,
        accessLevel: {
            ...userInfo.accessLevel,
            extraPermissions: newExtraPermissions,
        },
    };
}

function determineRememberMeAndPreviousUsernameOnLogout({ oldState }: { oldState: IReducerState }) {
    return {
        rememberMe: oldState.rememberMe,
        previousUsername: oldState.userProfile && oldState.userProfile.username
            ? oldState.userProfile.username
            : oldState.previousUsername,
    };
}
