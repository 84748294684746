import { ArgumentAction } from 'redux-logic/definitions/action';
import { path, pathOr } from 'ramda';

import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import { createNotFoundError } from '../../../utils/api/error/createNotFoundError';
import {
    DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../api/interventions/medicalExaminations/reserved/medicalExaminationsReserved.const';
import { getDate } from '../../../utils/core/date/getSpecificDate';
import { getMedExamToAddId } from '../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { getMinutesBetweenDates } from '../../../utils/core/date/getDifferenceBetweenDates';
import { getPlanBufferzoneWizardSteps } from '../../../config/navigation/wizardStepsMap';
import {
    IBffExaminationReason,
    IMedicalExaminationToAdd,
    IMedicalExaminationToPlan,
    IPlannedTimeSlot,
} from '../../../models/interventions/medicalExaminations';
import {
    IBufferzonePlanningCancelExistingPlanningPayload,
    IBufferzonePlanningCreateNewPlanningPayload,
    IBufferzonePlanningMoveExistingPlanningPayload,
    IPlanBufferzoneWizardEntity,
    IPlanBufferzoneWizardPayload,
    IPlanBufferzoneWizardSelectedBufferzone,
    ISkipToPlanBufferzoneWizardStepPayload,
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
} from '../../../models/interventions/bufferzones';
import { IFetchReservedMedicalExaminationPayload } from '../../../models/interventions/medicalExaminations/reserved';
import { ITraceableApiError } from '../../../models/general/error';
import {
    mapEmployeeToPlanToMedicalExaminationToAdd,
} from '../../../utils/interventions/medicalExaminations/mapEmployeesToPlanToMedicalExaminationsToAdd';
import {
    parseReservedMedicalExaminationIdInActivityIdAndBranchCode,
} from '../../../utils/interventions/medicalExaminations/reservedMedicalExaminationId.helper';
import Api from '../../../api';
import generateErrorId from '../../../utils/api/error/generateErrorId';
import HTTP_STATUS from '../../../utils/api/httpStatus';
import isMainSeatCompanyCode from '../../../utils/administration/companyInfo/isMainSeatCompanyCode';
import ROUTE_KEYS from '../../../routeKeys';
import {
    areExaminationReasonsAvailable,
    getExaminationReasonByCode,
} from '../../medicalExamination/selectors';
import { createEpic } from '../../index';
import { fetchCaseManagerActions } from '../../contact/actions';
import { fetchConvocationRecipientsActions } from '../../employee/documents/actions';
import {
    fetchEmployeesToPlan,
    fetchEmployeesToPlanFailed,
    fetchEmployeesToPlanSucceeded,
    fetchExaminationReasons,
    fetchReservedMedicalExaminations,
} from '../../medicalExamination/actions';
import { getLocale } from '../../i18n/selectors';
import { getLocationState } from '../../location/selectors';
import {
    getSelectedCompanySeat,
    getSelectedSeatCompanyCode,
    isAllSeatsSelected,
} from '../../company/selected/selectors';
import { isCaseManagerAvailable } from '../../contact/selectors';
import { IState } from '../../IState';

import {
    bufferzonePlanningCancelExistingPlanningActions,
    bufferzonePlanningCreateNewPlanningActions,
    bufferzonePlanningMoveExistingPlanningActions,
    createPlanBufferzoneWizardEntity,
    fetchBufferzoneTimeslotsActions,
    navigateToPlanBufferzoneWizardStep,
    resetPlanBufferzoneWizardEntity,
    skipToPlanBufferzoneWizardStepActions,
    triggerPlanBufferzoneWizard,
    updatePlanBufferzoneWizardEntity,
} from './actions';
import {
    BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
    BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    FETCH_BUFFERZONE_TIMESLOTS,
    SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
} from './types';
import {
    getPlanBufferzoneWizardEntity,
    getPlanBufferzoneWizardStepId,
} from './selectors';
import {
    getBufferzoneCompanyCode,
} from '../../../views/interventions/PlanBufferzoneWizard/PlanBufferzoneWizard.helper';

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validatePlanBufferzoneWizardStepIdEpic
createEpic<IPlanBufferzoneWizardPayload>({
    onActionType: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const PLAN_BUFFERZONE_STEP_IDS = getPlanBufferzoneWizardSteps().stepIds;
        if (!PLAN_BUFFERZONE_STEP_IDS.includes(requestedStep)) {
            return next(triggerPlanBufferzoneWizard());
        }

        // check no step skipped
        const currentStep = getPlanBufferzoneWizardStepId(getState());
        const currentStepIndex = PLAN_BUFFERZONE_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = PLAN_BUFFERZONE_STEP_IDS.indexOf(requestedStep);
        if (!action.payload.skipToStep && requestedStepIndex > currentStepIndex + 1) {
            return next(triggerPlanBufferzoneWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringPlanBufferzoneWizardEpic
createEpic<IPlanBufferzoneWizardPayload>({
    onActionType: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
    async processMultiple({ action, getState, api }, dispatch, done) {
        const { step } = action.payload;
        const state = getState();

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_BUFFERZONE) {
            // do not refresh if only clientside (query) filtering changed
            const {
                type: prevActionType,
                payload: prevActionPayload,
                query: prevQuery,
            } = getLocationState(state).prev as {
                type: string;
                payload: IPlanBufferzoneWizardPayload;
                query: object;
            };

            const prevQueryWithDefaults = {
                ...DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
                ...prevQuery,
            };

            if (
                prevActionType !== ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES &&
                (
                    action.type !== prevActionType ||
                    step !== prevActionPayload.step ||
                    !areObjectParamsEqual(prevQueryWithDefaults, action.meta.query, ['startDate', 'endDate'])
                )
            ) {
                dispatch(fetchReservedMedicalExaminations({}));
            }

            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES) {
            // Fetch examination reasons for this company
            if (!areExaminationReasonsAvailable(state)) {
                dispatch(fetchExaminationReasons());
            }

            // do not refresh if only clientside (query) filtering changed
            const { type: prevActionType, payload: prevActionPayload } = getLocationState(state).prev as {
                type: string;
                payload: IPlanBufferzoneWizardPayload;
            };

            const updatedState = getState();

            if (action.type !== prevActionType || step !== prevActionPayload.step) {
                try {
                    const wizardEntity = getPlanBufferzoneWizardEntity(updatedState);
                    const allSeatsSelected = isAllSeatsSelected(updatedState);

                    const bufferzoneBranchCode = path<string>(['selectedBufferzone', 'branch', 'code'], wizardEntity);

                    if (!bufferzoneBranchCode) {
                        console.warn('No bufferzone branch code could be found');
                        fetchEmployeesToPlanFailed(createNotFoundError());
                        return;
                    }

                    const selectedCompany = state.company_selected.selectedCompany.companyCode;
                    const selectedSeat = state.company_selected.selectedCompanySeat.company.companyCode;
                    const headSeat = selectedCompany === selectedSeat && !allSeatsSelected;
                    const subSeat = selectedCompany !== selectedSeat;

                    const companyCode = getBufferzoneCompanyCode(
                        allSeatsSelected,
                        bufferzoneBranchCode,
                        headSeat,
                        subSeat,
                        selectedCompany,
                        selectedSeat,
                    );

                    /*
                        During bufferzone planning, we only want to show all employees (showFullFamily)
                        if we are planning on a bufferzone created from the main seat when we are
                        logged in for all seats.
                        In all other cases we only want the selected seats employees
                    */

                    const showFullFamily = allSeatsSelected && isMainSeatCompanyCode(bufferzoneBranchCode);

                    const allowedExaminationReasonCodes = pathOr<IBffExaminationReason[]>(
                        [],
                        ['selectedBufferzone', 'allowedExaminationReasons'],
                        wizardEntity,
                    ).map((reason: IBffExaminationReason) => reason.code);

                    // Tirgger loading state
                    dispatch(fetchEmployeesToPlan({ companyCode, allowedExaminationReasonCodes }));

                    const employeesToPlan = await api.interventions.medicalExaminations.fetchToPlan({
                        companyCode,
                        showFullFamily,
                        allowedExaminationReasonCodes,
                        toBePlanned: true,
                    });

                    // Store employees to plan
                    dispatch(fetchEmployeesToPlanSucceeded(employeesToPlan));
                } catch (error) {
                    dispatch(fetchEmployeesToPlanFailed(error));
                }
            }

            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING) {
            dispatch(bufferzonePlanningCreateNewPlanningActions.reset({}));
            dispatch(bufferzonePlanningMoveExistingPlanningActions.reset({}));
            dispatch(bufferzonePlanningCancelExistingPlanningActions.reset({}));

            if (!areExaminationReasonsAvailable(state)) {
                dispatch(fetchExaminationReasons());
            }

            if (!isCaseManagerAvailable(state)) {
                dispatch(fetchCaseManagerActions.trigger({}));
            }

            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.OVERVIEW) {
            const {
                payload: prevActionPayload,
            } = getLocationState(state).prev as {
                payload: IPlanBufferzoneWizardPayload;
            };

            dispatch(fetchConvocationRecipientsActions.trigger({}));

            if (
                prevActionPayload && prevActionPayload.step !== PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING
            ) {
                dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            }

            return done();
        }

        done();
    },
    latest: false,
});

// FetchBufferzoneTimeslots
createEpic<IFetchReservedMedicalExaminationPayload>({
    onActionType: FETCH_BUFFERZONE_TIMESLOTS,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);
            const locale = getLocale(state);

            const { reservedMedicalExaminationId } = action.payload;

            if (!reservedMedicalExaminationId) {
                return fetchBufferzoneTimeslotsActions.succeeded({
                    freeTimeslots: [],
                    plannedExaminations: [],
                    totalTimeslots: 0,
                });
            }

            // Get activityId & branchCode from bufferzone ID (ID format: `activityId-branchCode`)
            const [activityId, branchCode] = parseReservedMedicalExaminationIdInActivityIdAndBranchCode(
                reservedMedicalExaminationId,
            );

            const response = await api.interventions.medicalExaminations.fetchReservedDetailTimeSlots({
                activityId,
                branchCode,
                companyCode,
                locale,
                showFullFamily,
            });

            const freeTimeslots = pathOr([], ['freeTimeslots'], response);
            const plannedExaminations = pathOr([], ['plannedExaminations'], response);
            const totalTimeslots = pathOr(0, ['totalTimeslots'], response);

            return fetchBufferzoneTimeslotsActions.succeeded({
                freeTimeslots,
                plannedExaminations,
                totalTimeslots,
            });
        } catch (error) {
            return fetchBufferzoneTimeslotsActions.failed(error);
        }
    },
    latest: true,
});

// Skip to plan bufferzone wizard step
createEpic<ISkipToPlanBufferzoneWizardStepPayload>({
    onActionType: SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    async processMultiple({ action }, dispatch, done) {
        try {
            const payload = action.payload;

            dispatch(resetPlanBufferzoneWizardEntity());
            dispatch(createPlanBufferzoneWizardEntity(payload.entity));
            dispatch(navigateToPlanBufferzoneWizardStep({
                step: getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(payload, payload.entity),
                skipToStep: true,
                resetDataEntity: false,
            }));
            dispatch(skipToPlanBufferzoneWizardStepActions.succeeded({}));
        } catch (error) {
            dispatch(skipToPlanBufferzoneWizardStepActions.failed(error));
        }
        done();
    },
    latest: false,
});

function getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(
    payload: ISkipToPlanBufferzoneWizardStepPayload,
    entityData: IPlanBufferzoneWizardEntity,
) {
    if (payload.wizardPayload.step) {
        return payload.wizardPayload.step;
    }
    const wizardSteps = getPlanBufferzoneWizardSteps().steps;
    const firstVisibleStep = wizardSteps.find((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(entityData);
        }
        return true;
    });
    return firstVisibleStep && firstVisibleStep.id as PLAN_BUFFERZONE_WIZARD_STEP_ID;
}

// Create new planning
createEpic<IBufferzonePlanningCreateNewPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    async processMultiple({ action, getState, api }, dispatch, done) {
        try {
            const { employeeToPlan, timeslot } = action.payload;

            const state = getState();
            const entity = getPlanBufferzoneWizardEntity(state);
            const locale = getLocale(state);

            const planningRequestMSId = await determineThePlanningRecordIdWhichPossiblyGetsOverruled({
                employeeToPlan,
                state,
                api,
            });

            const baseParams = {
                activityId: entity.selectedBufferzone.activity.id,
                remarks: '',
                timeSlotId: timeslot.id,
                locale,
            };

            if (planningRequestMSId) {
                await api.interventions.timeslots.addTimeslotByPlanningRecordId({
                    ...baseParams,
                    planningRequestMSId,
                });
            } else {
                await api.interventions.timeslots.addTimeslot({
                    ...baseParams,
                    employmentId: employeeToPlan.employee.id,
                    examinationReasonCode: employeeToPlan.examinationReason.code,
                });
            }

            const newEmployeesToPlan = entity && entity.employeesToPlan ? [...entity.employeesToPlan] : [];

            // remove employee from employees to plan
            const indexOfEmployee = newEmployeesToPlan.findIndex((item) =>
                getMedExamToAddId(item) === getMedExamToAddId(employeeToPlan));

            if (indexOfEmployee !== -1) {
                newEmployeesToPlan.splice(indexOfEmployee, 1);
                dispatch(updatePlanBufferzoneWizardEntity({
                    employeesToPlan: entity.employeesToPlan ? newEmployeesToPlan : [],
                }));
            }

            dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({ getState, timeSlotId: timeslot.id, dispatch });
            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningCreateNewPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningCreateNewPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

/**
 * When planning, we possibly have to overrule the planningRequestId.
 * Reason:
 *   - when cancelling, a new planning request id is always created, but this is asynchronously,
 *     so we don't know this yet (the cancel call does not return it)
 *   - so when we re-plan that block, it would be with an old planningRequestId, or without
 *     a planningRequestId in case the employee was manually added
 * Therefore we fetch the 'to-plan' records for the employee to compare.
 * See KZUAT-1368
 */
async function determineThePlanningRecordIdWhichPossiblyGetsOverruled({
    employeeToPlan,
    state,
    api,
}: {
    employeeToPlan: IMedicalExaminationToAdd;
    state: IState;
    api: typeof Api;
}): Promise<number> {
    const origPlanningRequestId = employeeToPlan.planningRequestId;

    const toPlanRecordsForEmployee = await api.interventions.medicalExaminations.fetchToPlan({
        companyCode: getSelectedSeatCompanyCode(state),
        showFullFamily: getSelectedCompanySeat(state).isAllSeatsSelected,
        employeeCustomerId: employeeToPlan.employee.id,
    });
    const areTherePlanRecordsForEmployee = toPlanRecordsForEmployee
        && Array.isArray(toPlanRecordsForEmployee)
        && toPlanRecordsForEmployee.length > 0;

    if (areTherePlanRecordsForEmployee) {
        if (origPlanningRequestId) {
            const matchOnPlanningRequestId = toPlanRecordsForEmployee.find((examinationToPlan) =>
                examinationToPlan.planningRequestId === origPlanningRequestId);

            if (matchOnPlanningRequestId) {
                /* The initial planningRequestId is still to-be-planned */
                return origPlanningRequestId;
            }
        }

        const matchOnEmployeeAndReason = toPlanRecordsForEmployee.find((examinationToPlan) =>
            examinationToPlan.employee.id === employeeToPlan.employee.id
            && examinationToPlan.examinationReason.id === employeeToPlan.examinationReason.id);

        if (matchOnEmployeeAndReason) {
            /* We take another planningRequestId that matched the employee and reason
               - either there was initially no planningRequestId (manually added employee) but after
                 canceling an "unexpected" planningRequestId was created by the mensura backend
               - OR either the initial planningRequestId is not valid anymore because the planned
                 examination was cancelled again (which results in a new planningRecord) */
            return matchOnEmployeeAndReason.planningRequestId;
        }
    }

    if (origPlanningRequestId) {
        /* The initial planningRequestId is not to-be-planned anymore + no valid replacement found */
        const error: ITraceableApiError = {
            id: generateErrorId(),
            code: HTTP_STATUS.NOT_FOUND,
            message: 'interventions.plan_bufferzone.steps.planning.error.planning_record_id_not_found',
            extraData: {},
            requestMethod: '-',
            wasCancelled: false,
            type: 'api',
        };
        throw error;
    }

    /* No initial planningRequestId + no matching planningRequest found */
    return null;
}

// Move existing planning
createEpic<IBufferzonePlanningMoveExistingPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    async processMultiple({ action, getState, api }, dispatch, done) {
        try {
            const state = getState();
            const locale = getLocale(state);
            const { examination, newTimeslot, selectedBufferzone } = action.payload;

            await api.interventions.timeslots.updateTimeSlot({
                locale,
                activityId: selectedBufferzone.activity.id,
                timeSlotId: examination.timeSlotId,
                remarks: '',
                newTimeSlot: {
                    activityId: selectedBufferzone.activity.id,
                    id: newTimeslot.id,
                },
            });

            // eslint-disable-next-line max-len
            dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({ getState, timeSlotId: newTimeslot.id, dispatch });
            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningMoveExistingPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningMoveExistingPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

// Cancel existing planning
createEpic<IBufferzonePlanningCancelExistingPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            const state = getState();
            const entity = getPlanBufferzoneWizardEntity(state);
            const locale = getLocale(state);

            const { examination, activityId } = action.payload;

            await api.interventions.timeslots.removeTimeSlot({
                activityId,
                timeSlotId: examination.timeSlotId,
                locale,
            });

            const examinationReasonId = getExaminationReasonByCode(state, examination.examinationReason.code);

            const cancelledPlanningToMedExamToPlan = mapPlannedExaminationToMedicalExaminationToPlan(
                examination,
                examinationReasonId.id || undefined,
            );
            const cancelledPlanningToMedExamToAdd =
                mapEmployeeToPlanToMedicalExaminationToAdd(cancelledPlanningToMedExamToPlan);

            dispatch(updatePlanBufferzoneWizardEntity({
                addedEmployees: addCancelledPlanningToAddedEmployeesIfNotAlreadyThere({
                    addedEmployees: entity.addedEmployees,
                    cancelledPlanning: cancelledPlanningToMedExamToPlan,
                }),
                employeesToPlan: entity.employeesToPlan ? [
                    ...entity.employeesToPlan,
                    cancelledPlanningToMedExamToAdd,
                ] : [cancelledPlanningToMedExamToAdd],
            }));

            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningCancelExistingPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningCancelExistingPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

function addCancelledPlanningToAddedEmployeesIfNotAlreadyThere({
    addedEmployees,
    cancelledPlanning,
}: {
    addedEmployees: IMedicalExaminationToPlan[];
    cancelledPlanning: IMedicalExaminationToPlan;
}): IMedicalExaminationToPlan[] {
    if (addedEmployees) {
        if (addedEmployees.find((alreadyAddedEmployee) =>
            alreadyAddedEmployee.employee.id === cancelledPlanning.employee.id
            && alreadyAddedEmployee.examinationReason.id === cancelledPlanning.examinationReason.id)
        ) {
            return addedEmployees;
        }

        return [
            ...addedEmployees,
            cancelledPlanning,
        ];
    }

    return [cancelledPlanning];
}

function dispatchRefetchBufferzoneTimeslotsActions({
    getState,
    dispatch,
}: {
    getState: () => IState;
    dispatch: (action: ArgumentAction) => void;
}) {
    const state = getState();
    const entity = getPlanBufferzoneWizardEntity(state);

    const selectedBufferzone = path<IPlanBufferzoneWizardSelectedBufferzone>(['selectedBufferzone'], entity);
    const companyCode = path<string>(
        ['branch', 'code'],
        selectedBufferzone,
    );

    if (!selectedBufferzone || !companyCode) {
        return;
    }

    dispatch(fetchBufferzoneTimeslotsActions.trigger({
        reservedMedicalExaminationId: selectedBufferzone.id,
    }));
}

function dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({
    timeSlotId,
    getState,
    dispatch,
}: {
    timeSlotId: number;
    getState: () => IState;
    dispatch: (action: ArgumentAction) => void;
}) {
    const state = getState();
    const wizardEntity = getPlanBufferzoneWizardEntity(state);
    const currentModifiedPlanningEntityIds = wizardEntity.modifiedTimeCellIds || [];

    // Skip adding the modified time slot to the current modified
    if (currentModifiedPlanningEntityIds.find((id) => id === timeSlotId)) {
        return null;
    }

    dispatch(updatePlanBufferzoneWizardEntity({
        modifiedTimeCellIds: [
            ...currentModifiedPlanningEntityIds,
            timeSlotId,
        ],
    }));
}

function mapPlannedExaminationToMedicalExaminationToPlan(
    examination: IPlannedTimeSlot,
    examinationReasonId: number,
) {
    const cancelledEmployeeToEmployeeToPlan: IMedicalExaminationToPlan = {
        employee: {
            id: examination.employee.employment.id, // Bff employee.employment.id equals PHP layer employee.id
            employeeId: examination.employee.id,
            firstName: examination.employee.firstName,
            name: examination.employee.lastName,
            birthDate: null,
            dateOutOfService: null,
        },
        company: {
            name: examination.branch.name,
            companyCode: examination.branch.code,
            id: null,
        },
        function: {
            id: null,
            description: null,
        },
        planningRequestId: examination.planningRequestId,
        medicalCenter: null,
        examinationReason: {
            code: examination.examinationReason.code,
            title: examination.examinationReason.description,
            id: examinationReasonId,
        },
        duration: getMinutesBetweenDates(getDate(examination.end), getDate(examination.start)),
        rescheduleAbsent: false,
        toBePlannedDate: null,
        toBePlanned: true,
        absent: null,
        absentDescription: null,
    };
    return cancelledEmployeeToEmployeeToPlan;
}
