import React, { PureComponent, MouseEvent } from 'react';
import './add-absence.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import { IAddEmployeeAbsencePayload } from '../../../../../models/admin/employee';
import { fields, schema } from './addAbsenceSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee, getAddEmployeeAbsenceAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import {
    ITraceableApiError,
} from '../../../../../models/general/error';
import {
    formatDateForBackend,
    formatDateForDisplay,
} from '../../../../../utils/formatting/formatDate';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import { addEmployeeAbsence } from '../../../../../redux/employee/info/actions';
import { ConstantType } from '../../../../../models/general/constants';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { removeEmptyPropsFromObject } from '../../../../../utils/core/object/objectProps';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import RequiredMarker from '../../../../common/input/RequiredMarker';

type TValues = Pick<IAddEmployeeAbsencePayload, 'startDate' | 'endDate' | 'riskCode'>;

interface IPrivateProps {
    onAddAbsence: (values: TValues) => void;
    translator: ITranslator;
    addAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
}

interface IAbsenceProps {
    onClose: () => void;
}

const CLASS_NAME = 'AddAbsence';
const FORM_NAME = 'add-absence-form';

class AddAbsence extends PureComponent<IPrivateProps & IAbsenceProps> {

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, onAddAbsence, addAsyncInfo,
        } = this.props;

        const initialValues: TValues = {
            riskCode: null,
            startDate: formatDateForBackend(new Date()),
            endDate: '',
        };

        const namePlaceholder = translator('administration.employees.add_absence.form.name');
        const startDatePlaceholder = translator('administration.employees.add_absence.form.start_date');
        const endDatePlaceholder = translator('administration.employees.add_absence.form.end_date');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.add_absence.title'}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={onAddAbsence}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue,
                    }: IFormRenderProps<TValues>) => {
                        return (
                            <>
                                <Loader
                                    show={
                                        addAsyncInfo.status === AsyncStatus.Busy
                                    }
                                    showImmediatelly={true}
                                />
                                <FloatableTextInputWrapper floatLabel>
                                    <ConstantsTypeahead
                                        id="add-absence-risk-code"
                                        constantType={ConstantType.ABSENCES}
                                        name={fields.riskCode}
                                        placeholder={namePlaceholder}
                                        value={values.riskCode}
                                        onItemSelected={(value) => setFieldValue('riskCode', value)}
                                        isInvalid={touched.riskCode && !!errors.riskCode}
                                    >
                                        <label htmlFor="add-absence-risk-code">
                                            <Translate
                                                msg="administration.employees.add_absence.form.name"
                                            />
                                            <RequiredMarker />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.riskCode && (
                                        <FormFieldError
                                            error={errors.riskCode}
                                            placeholders={{ fieldName: namePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <DatePicker
                                        id="add-absence-start-date"
                                        placeholder={startDatePlaceholder}
                                        value={values.startDate}
                                        name={fields.startDate}
                                        onChange={(value) => setFieldValue('startDate', value)}
                                        isInvalid={touched.startDate && !!errors.startDate}
                                    >
                                        <label htmlFor="add-absence-start-date">
                                            <Translate
                                                msg="administration.employees.add_absence.form.start_date"
                                            />
                                            <RequiredMarker />
                                        </label>
                                    </DatePicker>
                                    {touched.startDate && (
                                        <FormFieldError
                                            error={errors.startDate}
                                            placeholders={{ fieldName: startDatePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <DatePicker
                                        id="add-absence-end-date"
                                        placeholder={endDatePlaceholder}
                                        value={values.endDate}
                                        name={fields.endDate}
                                        onChange={(value) => setFieldValue('endDate', value)}
                                        isInvalid={touched.endDate && !!errors.endDate}
                                        minDate={values.startDate}
                                    >
                                        <label htmlFor="add-absence-end-date">
                                            <Translate
                                                msg="administration.employees.add_absence.form.end_date"
                                            />
                                        </label>
                                    </DatePicker>
                                    {touched.endDate && (
                                        <FormFieldError
                                            error={errors.endDate}
                                            placeholders={{
                                                fieldName: endDatePlaceholder,
                                                minValue: values.startDate
                                                    ? formatDateForDisplay(values.startDate) : '-',
                                            }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FormError error={addAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="add-absence-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate
                                            msg="administration.employees.add_absence.form.cancel"
                                        />
                                    </Button>
                                    <SubmitButton
                                        id="add-absence-submit-button"
                                        formName={FORM_NAME}
                                    >
                                        <Translate
                                            msg="administration.employees.add_absence.form.submit"
                                        />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.addAsyncInfo.status === AsyncStatus.Success &&
            prevProps.addAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, addAsyncInfo } = this.props;
        clearError(addAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IAbsenceProps>({
    stateProps: (state) => {
        const addAsyncInfo = getAddEmployeeAbsenceAsyncInfo(state);
        return {
            translator: getTranslatorDeprecated(state),
            addAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onAddAbsence: (values) => {
                const selectedEmployee = getSelectedEmployee(getState());
                dispatch(addEmployeeAbsence({
                    id: selectedEmployee.id,
                    ...removeEmptyPropsFromObject(values),
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(AddAbsence);
