import React from 'react';
import isBoolean from '@snipsonian/core/es/is/isBoolean';
import ShowAfterDelay from '../ShowAfterDelay';
import './loader.scss';
import classNames from 'classnames';
import AnimatedLeafs from '../AnimatedLeafs';
import { AsyncStatus } from '../../../../models/general/redux';

interface ILoaderProps extends React.Props<{}> {
    show: boolean | AsyncStatus;
    useFullScreen?: boolean;
    showImmediatelly?: boolean;
    transparentBackground?: boolean;
    positionFixed?: boolean;
}

const TIMEOUT_BEFORE_SHOW_IN_MILLIS = 0;
const TIMEOUT_MIN_DURATION_IN_MILLIS = 500;

const Loader = (props: ILoaderProps) => {
    const { show, useFullScreen, children, showImmediatelly, transparentBackground, positionFixed } = props;

    const loaderClass = classNames('Loader', {
        'Loader--full-screen': useFullScreen,
        'Loader--transparent-background': transparentBackground,
        'Loader--fixed': positionFixed,
    });

    const duringDelayComponent = (
        <>
            <div className={loaderClass}>
                <div className="content">
                    <AnimatedLeafs />
                </div>
            </div>
            {children}
        </>
    );

    const enabled = isBoolean(show) ? show as boolean : show === AsyncStatus.Busy;

    return (
        <ShowAfterDelay
            enabled={enabled}
            delayBeforeShow={showImmediatelly ? 0 : TIMEOUT_BEFORE_SHOW_IN_MILLIS}
            minDurationToShow={TIMEOUT_MIN_DURATION_IN_MILLIS}
            showDuringDelayComponent={duringDelayComponent}
        >
            {children}
        </ShowAfterDelay>
    );
};

export default Loader;
