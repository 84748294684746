import React, { PureComponent, MouseEvent } from 'react';
import { IRenderDetailContentProps } from '../../../../common/widget/MasterWithDetail/typings';
import { connect } from '../../../..';
import {
    ICourse, ICourseSession,
    ICourseSessionAttendee,
} from '../../../../../models/documentCenter/courses';
import Loader from '../../../../common/waiting/Loader/index';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import {
    getSelectedCourseSession, getFetchCourseSessionAsyncInfo,
    getFetchCourseSessionDaysAsyncInfo,
    getCourseSessionAttendees,
    getFetchCourseSessionAttendeesAsyncInfo,
    getRemoveCourseAttendeeAsyncInfo,
    isRemoveAttendeeNotPossible,
    getCoursesOverviewDetail,
    getCoursesOverviewDetailByHawAsyncInfo,
} from '../../../../../redux/documentCenter/courses/selectors';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import LabelInfo from '../../../../common/widget/LabelInfo';
import Button from '../../../../common/buttons/Button/index';
import Translate from '../../../../common/Translate';
import ListItem from '../../../../common/list/ListItem';
import { formatPersonName, formatPersonNameFormal } from '../../../../../utils/formatting/formatPerson';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import CourseSessionDays from '../../shared/days';
import ConfirmationDialog from '../../../../common/modals/ConfirmationDialog';
import { ITraceableApiError } from '../../../../../models/general/error';
import FormError from '../../../../common/forms/FormError';
import {
    removeCourseAttendeeActions,
    resetRemoveAttendeeNotPossible,
    fetchSelectedPlannedCourseActions,
} from '../../../../../redux/documentCenter/courses/actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
    isAllSeatsSelected,
} from '../../../../../redux/company/selected/selectors';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import CantRemoveCourseAttendeeDialog from './CantRemoveCourseAttendeeDialog';
import { COURSE_ATTENDEE_REMOVE_NOT_POSSIBLE_ERROR } from '../../../../../config/courses.config';
import AddressMapLink from '../../../../common/navigation/AddressMapLink';
import FreeSlotsIndicator from '../../../../common/widget/FreeSlotsIndicator';
import { BASE_CLASS_NAME, IPlannedCoursesDetailOverlayFormData, IPlannedCoursesDetailOverlayType } from '../common';
import { isDateBeforeCurrentDate } from '../../../../../utils/core/date/isDateBetween';
import { navigateTo } from '../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../routeKeys';
import { isCourseOfTypeOpen } from '../../shared/courseUtils';
import { createSelector } from 'reselect';
import { sortByPersonNameFormal } from '../../../../../utils/list/sortMethods';

const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    courseSession: ICourseSession;
    courseSessionAttendees: ICourseSessionAttendee[];
    removeCourseAttendee: (props: {
        attendee: ICourseSessionAttendee;
        course: ICourse;
    }) => void;
    removeAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    resetRemoveAttendeeNotPossible: () => void;
    navigateToCourseDetail: (nodeId: number) => void;
    showAttendeeRemoveNotPossibleDialog: boolean;
    fetchPlannedCourseDetail: (coursesOrganizedId: number) => void;
    courseDetailId: number;
    coursesOverviewDetailByHawAsyncInfo: IAsyncFieldInfo;
}

interface IComponentState {
    isConfirmationDialogOpen: boolean;
    isCantRemoveCourseAttendeeDialogOpen: boolean;
    isSuccessDialogOpen: boolean;
    courseAttendeeToRemove: ICourseSessionAttendee;
}

class DetailContent extends
    PureComponent<IPrivateProps & IRenderDetailContentProps<ICourse>, IComponentState> {

    constructor(props: IPrivateProps & IRenderDetailContentProps<ICourse>) {
        super(props);

        this.state = {
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
            courseAttendeeToRemove: null,
            isCantRemoveCourseAttendeeDialogOpen: null,
        };

        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onDeleteConfirmClicked = this.onDeleteConfirmClicked.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSuccessClick = this.onSuccessClick.bind(this);
        this.renderCourseSessionAttendees = this.renderCourseSessionAttendees.bind(this);
        this.dismissCourseAttendeeDialog = this.dismissCourseAttendeeDialog.bind(this);
    }

    public render() {
        const {
            detailAsyncInfo,
            detailData: selectedPlannedCourse,
            courseSession,
            removeAsyncInfo,
            courseSessionAttendees,
            navigateToCourseDetail,
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo,
        } = this.props;

        if (!selectedPlannedCourse) {
            return null;
        }

        const {
            isConfirmationDialogOpen,
            isSuccessDialogOpen,
            isCantRemoveCourseAttendeeDialogOpen,
            courseAttendeeToRemove,
        } = this.state;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
                        <div className={`${BASE_CLASS_NAME}__detail`}>
                            <h6>{translator('document_center.courses.planned.detail.content.days.title')}</h6>
                            <TinyLoader asyncInfoSelector={getFetchCourseSessionDaysAsyncInfo}>
                                <CourseSessionDays />
                            </TinyLoader>
                            {selectedPlannedCourse.address && (
                                <>
                                    <h6>{translator('document_center.courses.planned.detail.content.location')}</h6>
                                    <AddressMapLink address={selectedPlannedCourse.address} />
                                </>
                            )}

                            {isCourseOfTypeOpen(selectedPlannedCourse) && (
                                <>
                                    <h6>{translator('document_center.courses.planned.detail.content.language')}</h6>
                                    <TinyLoader asyncInfoSelector={getFetchCourseSessionAsyncInfo}>
                                        <span>{courseSession.language || EMPTY_PLACEHOLDER}</span>
                                    </TinyLoader>
                                </>
                            )}

                            <h6>{translator('document_center.courses.planned.detail.content.availability')}</h6>
                            <LabelInfo
                                labelKey={'document_center.courses.planned.detail.content.count_attendees_company'}
                                info={(
                                    <TinyLoader asyncInfoSelector={getFetchCourseSessionAttendeesAsyncInfo}>
                                        {courseSessionAttendees.length}
                                    </TinyLoader>
                                )}
                            />
                            <LabelInfo
                                labelKey={'document_center.courses.planned.detail.content.max_attendees'}
                                info={selectedPlannedCourse.maxAmountAttendees}
                            />
                            <LabelInfo
                                labelKey={'document_center.courses.planned.detail.content.free_slots'}
                                info={(
                                    <TinyLoader asyncInfoSelector={getFetchCourseSessionAttendeesAsyncInfo}>
                                        <FreeSlotsIndicator
                                            maxSlots={selectedPlannedCourse.maxAmountAttendees}
                                            freeSlots={
                                                selectedPlannedCourse.maxAmountAttendees -
                                                selectedPlannedCourse.countAmountAttendees
                                            }
                                        />
                                    </TinyLoader>
                                )}
                            />
                            <TinyLoader
                                asyncInfoSelector={coursesOverviewDetailByHawAsyncInfo}
                                hideErrorOnError
                            >
                                {courseDetailId && (
                                    <Button
                                        id="to-course-detail"
                                        typeName="primary"
                                        onClick={() => navigateToCourseDetail(courseDetailId)}
                                        className={`${BASE_CLASS_NAME}__detail__detail-button`}
                                    >
                                        <span>
                                            {translator('document_center.courses.planned.detail.content.course_detail')}
                                        </span>
                                    </Button>
                                )}
                            </TinyLoader>

                            <h6>{translator('document_center.courses.planned.detail.content.attendees.title')}</h6>
                            <TinyLoader asyncInfoSelector={getFetchCourseSessionAttendeesAsyncInfo}>
                                    {this.renderCourseSessionAttendees()}
                                </TinyLoader>
                                <ConfirmationDialog
                                    show={isConfirmationDialogOpen}
                                    showSuccess={isSuccessDialogOpen}
                                    onConfirm={this.onDeleteConfirmClicked}
                                    onCancel={this.onCancelClick}
                                    onSuccess={this.onSuccessClick}
                                    showLoader={removeAsyncInfo.status === AsyncStatus.Busy}
                                    type="error"
                                    header={translator({
                                        msg: 'document_center.courses.planned.detail.content.attendees.remove.title',
                                        placeholders: {
                                            name: formatPersonName(courseAttendeeToRemove),
                                        },
                                    })}
                                    successHeader={translator({
                                        // eslint-disable-next-line max-len
                                        msg: 'document_center.courses.planned.detail.content.attendees.remove.success_title',
                                        placeholders: {
                                            name: formatPersonName(courseAttendeeToRemove),
                                        },
                                    })}
                                >
                                    <p>
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="document_center.courses.planned.detail.content.attendees.remove.subtitle"
                                        />
                                    </p>
                                    {removeAsyncInfo.error
                                        && removeAsyncInfo.error.message !== COURSE_ATTENDEE_REMOVE_NOT_POSSIBLE_ERROR
                                        && <FormError error={removeAsyncInfo.error} />
                                    }
                                </ConfirmationDialog>

                                <CantRemoveCourseAttendeeDialog
                                    open={isCantRemoveCourseAttendeeDialogOpen}
                                    onCloseDialog={this.dismissCourseAttendeeDialog}
                                />
                        </div>
                    </Loader>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private renderCourseSessionAttendees() {
        const {
            courseSessionAttendees, courseSession, onOpenOverlay,
        } = this.props;

        return courseSessionAttendees.map((attendee: ICourseSessionAttendee, index) => {
            return (
                <ListItem
                    key={index}
                    title={formatPersonNameFormal(attendee)}
                    text={[attendee.company.companyCode, attendee.company.name]}
                    arrow={false}
                    button={
                        !isDateBeforeCurrentDate(courseSession.start) &&
                        <ShowIfAllowed requiredAccessLevels={{ interventions: 'W' }}>
                            {attendee.external && (
                                <Button
                                    id="edit-course-attendee"
                                    typeName="secondary"
                                    size="small"
                                    onClick={() => onOpenOverlay(
                                        IPlannedCoursesDetailOverlayType.EditExternalEmployee,
                                        undefined,
                                        { employeeId: attendee.employeeId } as IPlannedCoursesDetailOverlayFormData,
                                    )}
                                    className={`${BASE_CLASS_NAME}__edit-action`}
                                >
                                    <Translate
                                        msg="document_center.courses.planned.detail.content.attendees.action.edit"
                                    />
                                </Button>
                            )}
                            <Button
                                id="remove-course-attendee"
                                typeName="secondary"
                                size="small"
                                outline={true}
                                onClick={(e) => this.onDeleteClicked(e, attendee)}
                            >
                                <Translate
                                    msg="document_center.courses.planned.detail.content.attendees.action.cancel"
                                />
                            </Button>
                        </ShowIfAllowed>
                    }
                />
            );
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPrivateProps) {
        // If we get an error that we cannot unsubscribe anymore, show dialog with info about case manager.
        if (nextProps.showAttendeeRemoveNotPossibleDialog) {
            this.setState({
                isSuccessDialogOpen: false,
                isConfirmationDialogOpen: false,
                isCantRemoveCourseAttendeeDialogOpen: true,
            });
        }

        if (
            this.props.removeAsyncInfo.status === AsyncStatus.Busy &&
            nextProps.removeAsyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                isSuccessDialogOpen: true,
            });
        }
    }

    private onDeleteClicked(e: MouseEvent<HTMLElement>, attendee: ICourseSessionAttendee) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isConfirmationDialogOpen: true,
            courseAttendeeToRemove: attendee,
        });
    }

    private onCancelClick() {
        const { clearError, removeAsyncInfo, fetchPlannedCourseDetail, detailData } = this.props;
        this.setState({
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
        });
        clearError(removeAsyncInfo.error);
        fetchPlannedCourseDetail(detailData.coursesOrganizedId);
    }

    private onDeleteConfirmClicked() {
        const { courseAttendeeToRemove } = this.state;
        const {
            removeCourseAttendee,
            detailData: selectedPlannedCourse,
        } = this.props;

        removeCourseAttendee({
            attendee: courseAttendeeToRemove,
            course: selectedPlannedCourse,
        });
    }

    private onSuccessClick() {
        const { fetchPlannedCourseDetail, detailData } = this.props;
        this.setState({
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
        });

        fetchPlannedCourseDetail(detailData.coursesOrganizedId);
    }

    private dismissCourseAttendeeDialog() {
        const { clearError, removeAsyncInfo, resetRemoveAttendeeNotPossible } = this.props;
        clearError(removeAsyncInfo.error);
        resetRemoveAttendeeNotPossible();

        this.setState({
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
            isCantRemoveCourseAttendeeDialogOpen: false,
        });
    }
}

const getCourseSessionAttendeesMemoized = createSelector(
    getCourseSessionAttendees,
    (attendees) => attendees.sort(sortByPersonNameFormal),
);

export default connect<IPrivateProps, IRenderDetailContentProps<ICourse>>({
    stateProps: (state) => {
        const coursesOverviewDetailByHawAsyncInfo = getCoursesOverviewDetailByHawAsyncInfo(state);
        const coursesOverviewDetail = getCoursesOverviewDetail(state);
        const courseDetailId = coursesOverviewDetailByHawAsyncInfo.status === AsyncStatus.Success
            && coursesOverviewDetail
            && coursesOverviewDetail.course.nodeId;

        return {
            courseSession: getSelectedCourseSession(state),
            courseSessionAttendees: getCourseSessionAttendeesMemoized(state),
            removeAsyncInfo: getRemoveCourseAttendeeAsyncInfo(state),
            showAttendeeRemoveNotPossibleDialog: isRemoveAttendeeNotPossible(state),
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo: getCoursesOverviewDetailByHawAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => ({
        navigateToCourseDetail: (nodeId: number) => {
            dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                nodeId,
            }));
        },
        removeCourseAttendee: ({
            attendee,
            course,
        }: {
            attendee: ICourseSessionAttendee;
            course: ICourse;
        }) => {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            dispatch(removeCourseAttendeeActions.trigger({
                companyCode,
                showFullFamily,
                employeeId: attendee.id,
                coursesOrganizedId: course.coursesOrganizedId,
                courseId: course.id,
            }));
        },
        resetRemoveAttendeeNotPossible: () => {
            dispatch(resetRemoveAttendeeNotPossible());
        },
        clearError: (error) => {
            if (error) {
                dispatch(clearErrors([error.id]));
            }
        },
        fetchPlannedCourseDetail: (coursesOrganizedId: number) => {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);
            dispatch(fetchSelectedPlannedCourseActions.trigger({
                companyCode,
                showFullFamily,
                coursesOrganizedId,
            }));
        },

    }),
})(DetailContent);
