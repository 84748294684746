import { IState } from '../../IState';
import getEntitiesReducerState from '../getEntitiesReducerState';

// Theme of the month (for home page)
export const getAsyncThemeOfTheMonth = (state: IState) =>
    getEntitiesReducerState(state).themeOfTheMonth;

// Course links
export const getAsyncCourseLinks = (state: IState) =>
    getEntitiesReducerState(state).courseLinks;

// Cookie consent
export const getAsyncCookiesConfiguration = (state: IState) =>
    getEntitiesReducerState(state).cookiesConfiguration;
