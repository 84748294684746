import * as Yup from 'yup';
import { isAfterOrEqual } from '../../core/date/isAfterOrEqual';
import { ErrorTypes, ErrorResourceIds } from '../../../models/general/error';
import { IYupTestOptionsTestFunctionValueOfThis } from '../../../models/ui/form';

export function hourPeriodValidation(startHourRef: Yup.Ref) {
    return Yup.string().test({
        name: ErrorTypes.InvalidPeriod,
        message: ErrorResourceIds.InvalidPeriod,
        test: function test(this: IYupTestOptionsTestFunctionValueOfThis, value: string) {
            const startHourValue = this.resolve(startHourRef) as string;
            if (!value || !startHourValue) {
                return true;
            }
            return startHourValue <= value;
        },
    });
}

Yup.addMethod(Yup.string, ErrorTypes.InvalidPeriod, hourPeriodValidation);

export function datePeriodValidation(startDateRef: Yup.Ref) {
    return Yup.string().test({
        name: ErrorTypes.InvalidPeriod,
        message: ErrorResourceIds.InvalidPeriod,
        test: function test(this: IYupTestOptionsTestFunctionValueOfThis, value: string) {
            const startDateValue = this.resolve(startDateRef) as string;
            if (!value || !startDateValue) {
                return true;
            }
            return isAfterOrEqual(value, startDateValue);
        },
    });
}

Yup.addMethod(Yup.string, ErrorTypes.InvalidPeriod, datePeriodValidation);
