import extractColumnNames from './extractColumnNames';
import convertListDataItemsForCsv, { COLUMN_SEPARATOR, ROW_SEPARATOR } from './convertListDataItemsForCsv';
import presentDownloadedFile from '../presentDownloadedFile';
import { getCurrentTimestamp } from '../../formatting/formatDate';
import { CSV_EXPORT_VULNERABILITIES_REGEX } from '../../libs/yup/string';
import { PHONE_NUMBER_REGEX } from '../../libs/yup/phoneNumber';

/**
 * BOM = Byte Order Mark
 * Needed for proper encoding of special characters like Ä and ö.
 * See https://donatstudios.com/CSV-An-Encoding-Nightmare
 *
 * p.s. Because these bytes have to be at the beginning of the csv string,
 * we can not include the - excel specific - trick ("sep=;"\n) at the beginning of the file to specify
 * which separator/delimiter excel should use to open the file.
 * As a result, users will have to 'File > Open' the csv files so that they can specify the delimiter (or
 * they should configure ; as the default delimiter/separator)
 */
const UTF8_BOM = '\ufeff';
const CSV_MIME_TYPE = 'text/csv;charset=utf-8';
const CSV_FILE_EXTENSION = 'csv';

interface IListDataToExport {
    baseFilename: string;
    listData: object[];
}

export default function exportListDataToCsv({
    baseFilename,
    listData,
}: IListDataToExport) {
    const filename = getCsvFilename(baseFilename);

    const csvData = generateCsvData(listData);

    const data = new Blob([csvData], { type: CSV_MIME_TYPE });

    presentDownloadedFile({
        data,
        filename,
    });
}

export function getCsvFilename(baseFilename: string) {
    return `${baseFilename}_${getCurrentTimestamp()}.${CSV_FILE_EXTENSION}`;
}

export function generateCsvData(listData: object[]): string {
    const columnNames = extractColumnNames(listData);
    const columnNameHeaders = columnNames.map((columnName) => columnName.header);

    const rows: string[][] = [columnNameHeaders]
        .concat(convertListDataItemsForCsv(listData, columnNames));

    return convertStringDataToCsv(rows);
}

function convertStringDataToCsv(stringData: string[][]) {
    return UTF8_BOM
        + stringData
            .map((row) => {
                return row.map((value) => {
                    // Values starting with =+@\- could potentially be harmful, that's why we escape these values with '
                    if (!value.match(PHONE_NUMBER_REGEX) && !value.match(CSV_EXPORT_VULNERABILITIES_REGEX)) {
                        return `'${value}`;
                    }
                    return value;
                }).join(COLUMN_SEPARATOR);
            })
            .join(ROW_SEPARATOR);
}
