export const FETCH_EMPLOYEE_DETAILS = 'FETCH_EMPLOYEE_DETAILS';
export const FETCH_SMALL_EMPLOYEE_DETAILS = 'FETCH_SMALL_EMPLOYEE_DETAILS';
export const FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ = 'FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ';

export const FETCH_EMPLOYEE_STATUS = 'FETCH_EMPLOYEE_STATUS';

export const FETCH_EMPLOYEE_STATUTES = 'FETCH_EMPLOYEE_STATUTES';
export const ADD_EMPLOYEE_STATUTE = 'ADD_EMPLOYEE_STATUTE';

export const FETCH_EMPLOYEE_ABSENCES = 'FETCH_EMPLOYEE_ABSENCES';
export const ADD_EMPLOYEE_ABSENCE = 'ADD_EMPLOYEE_ABSENCE';
export const UPDATE_EMPLOYEE_ABSENCE = 'UPDATE_EMPLOYEE_ABSENCE';
export const REMOVE_EMPLOYEE_ABSENCE = 'REMOVE_EMPLOYEE_ABSENCE';

export const UPDATE_EMPLOYEE_RISK = 'UPDATE_EMPLOYEE_RISK';
export const REMOVE_EMPLOYEE_RISK = 'REMOVE_EMPLOYEE_RISK';

export const UPDATE_EMPLOYEE_STATUTE = 'UPDATE_EMPLOYEE_STATUTE';
export const REMOVE_EMPLOYEE_STATUTE = 'REMOVE_EMPLOYEE_STATUTE';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_ALL_FIELDS = 'UPDATE_EMPLOYEE_ALL_FIELDS';
export const UPDATE_EMPLOYEE_EMPLOYMENT = 'UPDATE_EMPLOYEE_EMPLOYMENT';

export const SET_EMPLOYEE_OUT_OF_SERVICE = 'SET_EMPLOYEE_OUT_OF_SERVICE';
export const CHANGE_EMPLOYEE_OUT_OF_SERVICE = 'CHANGE_EMPLOYEE_OUT_OF_SERVICE';
export const CLEAR_EMPLOYEE_OUT_OF_SERVICE = 'CLEAR_EMPLOYEE_OUT_OF_SERVICE';

export const FETCH_EMPLOYEE_RISKS_AND_RESEARCHES = 'FETCH_EMPLOYEE_RISKS_AND_RESEARCHES';
export const FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES = 'FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES';

export const FETCH_EMPLOYEE_PERSONAL_RISKS = 'FETCH_EMPLOYEE_PERSONAL_RISKS';
export const ADD_EMPLOYEE_PERSONAL_RISK = 'ADD_EMPLOYEE_PERSONAL_RISK';

export const FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS = 'FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS';

export const UPDATE_EMPLOYEE_COST_CENTER = 'UPDATE_EMPLOYEE_COST_CENTER';

export const FETCH_EMPLOYEE_JOB_STUDENT = 'FETCH_EMPLOYEE_JOB_STUDENT';
export const ADD_EMPLOYEE_JOB_STUDENT = 'ADD_EMPLOYEE_JOB_STUDENT';
export const UPDATE_EMPLOYEE_JOB_STUDENT = 'UPDATE_EMPLOYEE_JOB_STUDENT';

export const SET_JOB_STUDENT_REQUEST_FAILED_WARNING = 'SET_JOB_STUDENT_REQUEST_FAILED_WARNING';
