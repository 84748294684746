import { Store } from 'redux';
import { IState } from '../IState';
import { RELEASE_BUILD, SHOULD_REPORT_CURRENT_ENV } from '../../config/errorReporting.config';
import { triggerFlashThatThereIsANewAppVersion } from '../flashMessages/actions';
import isSetString from '../../utils/core/string/isSetString';
import { reportInfo } from '../../utils/logging/errorReporter';
import { fetchAppVersion } from '../../api/auth/env.api';

/**
 * Will trigger a flash message when the app-version on the server (/version.txt)
 * is different than the current app-version.
 */
export default async function checkAppVersionHandler(store: Store<IState>) {
    const receivedAppVersion = await fetchAppVersion();

    // Early return when appVersion is not available
    if (!receivedAppVersion) {
        return;
    }

    // Convert received app version to string and compare to release build app version
    const receivedAppVersionString = receivedAppVersion.toString();
    if (isSetString(receivedAppVersionString) && receivedAppVersionString.trim() !== RELEASE_BUILD) {
        /**
         * On localhost and DEV (frontend doesn't know the correct version)
         * we can not correctly know when to show the warning.
         * version.txt will always be 'latest'
         */
        if (SHOULD_REPORT_CURRENT_ENV) {
            console.log('New app version available.', `old: ${RELEASE_BUILD}`, `new: ${receivedAppVersionString}`);
            reportInfo('', {
                level: 'warning',
                category: 'app_meta',
                extra: {
                    oldVersion: RELEASE_BUILD,
                    newVersion: receivedAppVersionString,
                },
            });

            store.dispatch(triggerFlashThatThereIsANewAppVersion());
        }
    }
}
