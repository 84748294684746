import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';
import { getCoursePossibleLocations } from '../../../../../redux/documentCenter/courses/selectors';
import { ICMSCourseAddress } from '../../../../../models/documentCenter/courses';
import { formatAddressWithName } from '../../../../../utils/formatting/formatAddress';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import Translate from '../../../../common/Translate';

interface IPrivateProps {
    locations: ICMSCourseAddress[];
    locationsTypeahead: TTypeaheadData;
}

interface ICourseLocationTypeaheadProps {
    id: string;
    value: number; // location id
    name: string;
    onItemSelected: (id: number, location: ICMSCourseAddress) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholderTranslationKey?: string;
    disabled?: boolean;
}

function CourseLocationTypeahead(props: ICourseLocationTypeaheadProps & IPrivateProps) {
    const {
        children, id, value,
        name, isInvalid, onItemSelected,
        locations, locationsTypeahead,
        placeholderTranslationKey, disabled,
    } = props;

    function onItemSelectedHandler(id: number) {
        const selectedLocation = locations.find((item) => item.id === id);

        onItemSelected(id, selectedLocation || null);
    }

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <Typeahead
                    id={id}
                    value={value}
                    name={name}
                    onItemSelected={onItemSelectedHandler}
                    isInvalid={isInvalid}
                    data={locationsTypeahead}
                    disableSort={true}
                    placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
                    disabled={disabled}
                >
                    {children}
                </Typeahead>
            )}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps, ICourseLocationTypeaheadProps>({
    statePropsPerInstance: (state) => {
        return (state) => {
            const locations = getCoursePossibleLocations(state);
            return {
                locations,
                locationsTypeahead: mapLocationsForTypeahead(locations),
            };
        };
    },
})(CourseLocationTypeahead);

function mapLocationsForTypeahead(
    locations: ICMSCourseAddress[],
): TTypeaheadData {
    return Array.isArray(locations) ? locations.map((item: ICMSCourseAddress) => ({
        value: item.id,
        label: formatAddressWithName(item, true),
        dropdownItemContent: getDropdownItemContent(item),
    })) : [];
}

function getDropdownItemContent(address: ICMSCourseAddress) {
    const languageTranslationKey =
        `document_center.courses.new.steps.course.language.entries.${address.languageCode}`;
    return (
        <>
            <strong>{address.city}</strong><br />
            {formatAddressWithName(address, true)}<br />
            <i><Translate msg={languageTranslationKey} /></i>
        </>
    );
}
