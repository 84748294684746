import {
    FETCH_FAQ_OVERVIEW, SEND_COMPANY_FEEDBACK, FETCH_CASE_MANAGER, FETCH_MENSURA_CONTACTS,
} from './types';
import { createSuccessAction, createFailAction } from '../index';
import { IFaqOverview } from '../../models/contact/faq';
import { ITraceableApiError } from '../../models/general/error';
import { createTypeActions } from '../../utils/libs/redux/createAction';
import { ISendCompanyFeedbackPayload } from '../../models/contact/feedback';
import { ICompanyCodePayload } from '../../models/admin/company';
import { IFetchMensuraContactsSuccessPayload } from '../../models/admin/companyInfo';

// FAQ overview
export function fetchFaqOverviewSucceeded(payload: IFaqOverview) {
    return createSuccessAction<IFaqOverview>(FETCH_FAQ_OVERVIEW, payload);
}

export function fetchFaqOverviewFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_FAQ_OVERVIEW, error);
}

// Feedback
export const sendFeedbackActions =
    createTypeActions<ISendCompanyFeedbackPayload>({ type: SEND_COMPANY_FEEDBACK });

// Case manager
export const fetchCaseManagerActions = createTypeActions({
    type: FETCH_CASE_MANAGER,
});

// Mensura contacts
export const fetchMensuraContactsActions =
    createTypeActions<ICompanyCodePayload, IFetchMensuraContactsSuccessPayload>({
        type: FETCH_MENSURA_CONTACTS,
    });
