import React from 'react';
import classNames from 'classnames';
import { schema } from '../../../shared/ContactInput/schema';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { getCompanyContactsAsyncInfo } from '../../../../../redux/company/info/selectors';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import { getCompanyVisitRequestWizardEntity } from '../../../../../redux/company/visits/selectors';
import { updateCompanyVisitRequestWizardEntity } from '../../../../../redux/company/visits/actions';
import ContactInput, { IFormValues } from '../../../shared/ContactInput/ContactInput';

const CLASS_NAME = 'RequestCompanyVisit__Contact';
const FORM_NAME = 'request-company-visit-contact-form';

const ContactWizardFormStep = WizardFormStep<IFormValues>();

export default function Contact(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <ContactWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={classNames(CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT)}
            header={{
                titleTranslationKey: 'interventions.company_visits_new.steps.contact.title',
            }}
            showLoaderSelector={(state) => getCompanyContactsAsyncInfo(state).status}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getCompanyVisitRequestWizardEntity(state);

                    return {
                        contact: wizardEntity.contact,
                    };
                },
                renderStepFormContent: (renderProps) => (
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <ContactInput {...renderProps} />
                    </div>
                ),
                onChangeInputActionCreator: ({ values }) => updateCompanyVisitRequestWizardEntity({
                    contact: values.contact,
                }),
            }}
        />
    );
}
