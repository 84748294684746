import React, { PureComponent } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import './edit-top-tasks.scss';
import Translate from '../../../common/Translate';
import { connect } from '../../..';
import { TaskCategory, TaskId, ITopTask, ISelectedTask } from '../../../../models/user/topTasks';
import {
    getAllowedPossibleTopTasks,
    getTopTasksAsyncInfo,
    getUpdateTopTasksAsyncInfo,
    getTopTasksMemoized,
} from '../../../../redux/navigation/selectors';
import TinyLoader from '../../../common/waiting/TinyLoader';
import Button from '../../../common/buttons/Button';
import { getPossibleTasksIdMap } from '../../../../config/navigation/tasks.config';
import TopTasksList, { ISelectableTopTask } from './TopTasksList';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader';
import { updateTopTasks } from '../../../../redux/navigation/actions';
import FormError from '../../../common/forms/FormError';
import { clearErrors } from '../../../../utils/libs/redux/generic/actions';
import SelectedTopTasksList from './SelectedTopTasksList';
import { navigateTo } from '../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../routeKeys';

const CLASS_NAME = 'EditTopTasks';

interface IPrivateProps {
    topTasksAsyncInfo: IAsyncFieldInfo;
    updateTopTasksAsyncInfo: IAsyncFieldInfo;
    topTasks: ITopTask[];
    allowedPossibleTopTasks: ITopTask[];
    triggerUpdate: (topTasks: ITopTask[]) => void;
    clearUpdateError: () => void;
    navigateToHome: () => void;
}

interface IState {
    selectedTopTasks: ISelectedTask[];
    isDirty: boolean;
}

class EditTopTasks extends PureComponent<IPrivateProps, IState> {

    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedTopTasks: this.getInitialTopTasks(),
            isDirty: false,
        };

        this.onSortEnd = this.onSortEnd.bind(this);
        this.onTopTaskSelected = this.onTopTaskSelected.bind(this);
    }

    public render() {
        const { updateTopTasksAsyncInfo, triggerUpdate, allowedPossibleTopTasks } = this.props;
        const { selectedTopTasks, isDirty } = this.state;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__fixed-header`}>
                    <Loader show={updateTopTasksAsyncInfo.status === AsyncStatus.Busy}>
                        <h1><Translate msg="home.edit_top_tasks.title" /></h1>
                        <div className={`${CLASS_NAME}__selection`}>
                            <TinyLoader asyncInfoSelector={getTopTasksAsyncInfo}>
                                <SelectedTopTasksList
                                    items={selectedTopTasks}
                                    onSortEnd={this.onSortEnd}
                                    onTopTaskSelected={this.onTopTaskSelected}
                                    axis="x"
                                />
                                <div className={`${CLASS_NAME}__selection__actions`}>
                                    <Button
                                        id="save-top-tasks-button"
                                        typeName="secondary"
                                        onClick={() => triggerUpdate(selectedTopTasks)}
                                        disabled={!isDirty}
                                    >
                                        <Translate msg="home.edit_top_tasks.submit" />
                                    </Button>
                                </div>
                            </TinyLoader>
                        </div>
                        <FormError error={updateTopTasksAsyncInfo.error} />
                    </Loader>
                </div>
                <div className={`${CLASS_NAME}__lists`}>
                    {Object.values(TaskCategory).map((category) => {
                        const tasks = allowedPossibleTopTasks
                            .filter((task) => task.category === category)
                            .map((task) => {
                                const selectedItems = selectedTopTasks || [];
                                const selectedItem = selectedItems.find((item) => item.id === task.id);
                                const selectedIds = selectedItems.map((item) => item.id);
                                const isSelected = selectedIds.includes(task.id);
                                return {
                                    ...task,
                                    isSelected,
                                    selectionNumber: isSelected && selectedIds.indexOf(task.id) + 1,
                                    dirty: selectedItem && selectedItem.dirty,
                                } as ISelectableTopTask;
                            });
                        return (
                            <TopTasksList
                                key={`category-${category}`}
                                categoryValue={category}
                                tasks={tasks}
                            />
                        );
                    })
                    }
                </div>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        this.setTopTasksInStateIfTopTasksHaveChanged(prevProps);
        this.goToHomeWhenTopTasksAreSaved(prevProps);
    }

    public componentWillUnmount() {
        this.props.clearUpdateError();
    }

    private getInitialTopTasks() {
        const { topTasksAsyncInfo } = this.props;
        if (topTasksAsyncInfo.status !== AsyncStatus.Success) {
            return null;
        }
        return this.props.topTasks;
    }

    private onTopTaskSelected(taskId: TaskId, index: number) {
        const { selectedTopTasks } = this.state;
        selectedTopTasks[index] = {
            ...getPossibleTasksIdMap()[taskId],
            dirty: true,
        };
        this.setState({
            selectedTopTasks: selectedTopTasks.slice(),
            isDirty: true,
        });
    }

    private onSortEnd({ oldIndex, newIndex }) {
        this.setState({
            selectedTopTasks: arrayMove(this.state.selectedTopTasks, oldIndex, newIndex),
            isDirty: true,
        });
    }

    private setTopTasksInStateIfTopTasksHaveChanged(prevProps: IPrivateProps) {
        const { topTasks } = this.props;
        const { topTasks: prevTopTasks } = prevProps;
        if (topTasks !== prevTopTasks && this.props.updateTopTasksAsyncInfo.status !== AsyncStatus.Busy) {
            this.setState({
                selectedTopTasks: topTasks,
            });
        }
    }

    private goToHomeWhenTopTasksAreSaved(prevProps: IPrivateProps) {
        const { topTasks, updateTopTasksAsyncInfo, navigateToHome } = this.props;
        if (
            prevProps.updateTopTasksAsyncInfo.status === AsyncStatus.Busy &&
            updateTopTasksAsyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                selectedTopTasks: topTasks,
            });
            return navigateToHome();
        }
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const updateAsyncInfo = getUpdateTopTasksAsyncInfo(state);
        return {
            topTasksAsyncInfo: getTopTasksAsyncInfo(state),
            updateTopTasksAsyncInfo: updateAsyncInfo,
            topTasks: getTopTasksMemoized(state),
            allowedPossibleTopTasks: getAllowedPossibleTopTasks(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerUpdate: (topTasks: ITopTask[]) => {
                dispatch(updateTopTasks({
                    topTasks: topTasks.map((item) => ({ id: item.id })),
                }));
            },
            clearUpdateError: () => {
                const state = getState();
                const updateAsyncInfo = getUpdateTopTasksAsyncInfo(state);
                if (updateAsyncInfo.error) {
                    dispatch(clearErrors([updateAsyncInfo.error.id]));
                }
            },
            navigateToHome: () => dispatch(navigateTo(ROUTE_KEYS.R_HOME)),
        };
    },
})(EditTopTasks);
