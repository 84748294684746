import React, { ReactNode } from 'react';
import './list-footer.scss';
import classNames from 'classnames';

export interface IListFooterProps {
    left?: ReactNode;
    center?: ReactNode;
    right?: ReactNode;
}

const CLASS_NAME = 'ListFooter';

export default function ListFooter(props: IListFooterProps) {
    return (
        <div className={classNames(CLASS_NAME, { [`${CLASS_NAME}--hasCenterItem`]: !!props.center })}>
            <div className={`${CLASS_NAME}__left`}>
                {props.left}
            </div>
            <div className={`${CLASS_NAME}__center`}>
                {props.center}
            </div>
            <div className={`${CLASS_NAME}__right`}>
                {props.right}
            </div>
        </div>
    );
}
