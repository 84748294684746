import React, { ReactNode } from 'react';
import './invoices.scss';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import PageHeader from '../../appShell/PageHeader';
import ROUTE_KEYS from '../../../routeKeys';
import { ITabItem } from '../../../models/general/navigation';
import connect from '../../../utils/libs/redux/connect';
import { getOpenInvoices, getOpenInvoicesAsyncInfo } from '../../../redux/invoice/selectors';
import { AsyncStatus } from '../../../models/general/redux';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const CLASS_NAME = 'Invoices';

interface IPrivateProps {
    openInvoicesCount: number;
    hideParametersTab: boolean;
}

interface IPublicProps {
    children: ReactNode;
}

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const areOpenInvoicesFetched = getOpenInvoicesAsyncInfo(state).status === AsyncStatus.Success;

        const openInvoices = getOpenInvoices(state);

        return {
            openInvoicesCount: openInvoices && areOpenInvoicesFetched ? openInvoices.length : null,
        };
    },
})(InvoicesTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function InvoicesTemplate(props: IPublicProps & IPrivateProps) {
    const {
        children,
        openInvoicesCount,
    } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'administration.invoices.open.tab_title',
        linkTo: ROUTE_KEYS.R_INVOICES_OPEN,
        notificationContent: openInvoicesCount,
    }, {
        translationKey: 'administration.invoices.paid.tab_title',
        linkTo: ROUTE_KEYS.R_INVOICES_PAID,
    }];

    const TABS_TO_SHOW = TABS.filter((tab) => !tab.hide);

    const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_INVOICES_OPEN;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className={`${CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS_TO_SHOW}
                    title="administration.invoices.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.ADMINISTRATION,
                    }}
                    type="grey"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
