import dayjs from 'dayjs';
import { IState } from '../../IState';
import getEntitiesReducerState from '../getEntitiesReducerState';
import { isDateBetween } from '../../../utils/core/date/isDateBetween';
import { now } from '../../../utils/core/date/getSpecificDate';
import { NO_RERENDER } from '../..';

export const getAsyncMaintenanceMessages = (state: IState) =>
    getEntitiesReducerState(state).maintenanceMessages;

export const isMaintenanceBusy = (state: IState) => {
    const messages = getAsyncMaintenanceMessages(state).data || NO_RERENDER.EMPTY_LIST;

    return !!messages.find((message) => {
        if (message.disableLogin && message.disableLoginStart && message.disableLoginEnd) {
            if (isDateBetween(now(), dayjs(message.disableLoginStart), dayjs(message.disableLoginEnd))) {
                return true;
            }
        }
        return false;
    });
};
