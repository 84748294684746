import React, { PureComponent, ReactNode } from 'react';
import './dialog-header.scss';
import classNames from 'classnames';

export type TDialogHeaderColor = 'primary' | 'primary--dark' | 'secondary' | 'tertiary' |
    'danger' | 'conifer' | 'yellow' | 'blue--light';

export interface IDialogHeaderProps {
    color?: TDialogHeaderColor;
    children: ReactNode;
}

const CLASS_NAME = 'DialogHeader';

class DialogHeader extends PureComponent<IDialogHeaderProps> {
    constructor(props: IDialogHeaderProps) {
        super(props);
    }

    public render() {
        const { children, color } = this.props;

        const className = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--${color}`]: !!color,
        });

        return (
            <header className={className}>
                {children}
            </header>
        );
    }
}

export default DialogHeader;
