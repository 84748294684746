import { object } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../models/ui/form';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    acquiredTraining: 'acquiredTraining',
    priorInstruction: 'priorInstruction',
    requiredTraining: 'requiredTraining',
};

export const schema = object({
    [fields.acquiredTraining]: string(),
    [fields.priorInstruction]: string(),
    [fields.requiredTraining]: string(),
});
