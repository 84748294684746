import React from 'react';
import {
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
} from '../../../common/widget/MasterWithDetail/typings';
import {
    IPreventionUnitTask,
    PreventionUnitId,
    IPreventionUnitNumbers,
} from '../../../../models/admin/preventionUnits';
import ListItem from '../../../common/list/ListItem';
import { connect } from '../../..';
import { getRoutePayload } from '../../../../redux/location/selectors';
import Translate from '../../../common/Translate';
import { PREVENTION_UNITS_CONFIG } from '../../../../config/administration.config';
import {
    getTotalPreventionUnits,
    getCompanySituationHistory,
    isPEOverviewRoute,
} from '../../../../redux/preventionUnits/selectors';
import {
    getLatestCompanySituationYear,
    getPreviousCompanySituationYear,
} from '../../../../redux/preventionUnits/companySituationHistoryUtils';
import { formatFloat } from '../../../../utils/formatting/formatFloat';
import { AsyncStatus } from '../../../../models/general/redux';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import LabelInfo from '../../../common/widget/LabelInfo';

const CLASS_NAME = 'PreventionUnitsOverviewDetail';
const EMPTY_PLACEHOLDER = '-';

interface IPrivateHeaderProps {
    selectedType: PreventionUnitId;
}

export function DetailComp(props: IRenderDetailContentProps<IPreventionUnitTask[]> & IPrivateHeaderProps) {
    const {
        detailData,
        detailAsyncInfo,
    } = props;

    const emptyTranslationKey = 'administration.prevention_units.shared.detail.empty';

    return (
        <div className={CLASS_NAME}>
            {detailData.length > 0 ? detailData.map((task, index) => {
                const listItemText = createListItemText(task, props.selectedType);
                return (
                    <ListItem
                        id={`prevention-unit-task-${index}`}
                        key={index}
                        text={listItemText}
                    />
                );
            }) : detailAsyncInfo.status === AsyncStatus.Success ? (
                <div className="no-data"><Translate msg={emptyTranslationKey} /></div>
            ) : null}
        </div>
    );
}

function createListItemText(task: IPreventionUnitTask, selectedType: PreventionUnitId) {
    const detailTranslationBase = 'administration.prevention_units.shared.detail';

    const config = PREVENTION_UNITS_CONFIG.find((config) => config.id === selectedType);
    const color = config && (config.legendColorOverride || config.color);

    return (
        <>
            <h4>{task.title}</h4>
            <LabelInfo
                info={(
                    <span className="amount" style={{ color }}>
                        {typeof task.pe !== 'undefined' ?
                            <Translate
                                msg={`${detailTranslationBase}.amount`}
                                placeholders={{ amount: formatFloat(task.pe, 4, true) }}
                            /> :
                            EMPTY_PLACEHOLDER}
                    </span>
                )}
                labelKey={`${detailTranslationBase}.label.amount`}
            />
            <LabelInfo
                info={task.company && task.company.name}
                labelKey={`${detailTranslationBase}.label.seat`}
            />
            <LabelInfo
                info={task.description}
                labelKey={`${detailTranslationBase}.label.description`}
            />
            <LabelInfo
                info={task.code}
                labelKey={`${detailTranslationBase}.label.code`}
            />
            <LabelInfo
                info={formatDateForDisplay(task.taskDate)}
                labelKey={`${detailTranslationBase}.label.date`}
            />
        </>
    );
}

export const Detail = connect<IPrivateHeaderProps>({
    stateProps: (state) => {
        const selectedType = getRoutePayload<{ type: PreventionUnitId }>(state).type;

        return {
            selectedType,
        };
    },
})(DetailComp);

interface IPrivateHeaderProps {
    selectedType: PreventionUnitId;
    totalPreventionUnits: IPreventionUnitNumbers;
    year: number;
}

function HeaderComp(props: IRenderDetailHeaderProps<IPreventionUnitTask[]> & IPrivateHeaderProps) {
    const {
        selectedType,
        detailAsyncInfo,
        totalPreventionUnits,
        year,
    } = props;

    const config = PREVENTION_UNITS_CONFIG.find((config) => config.id === selectedType);
    const titleTranslationKey = config && config.detailTitleTranslationKey;

    return (
        <h1>
            {detailAsyncInfo.error
                ? <Translate msg="error.title" />
                : <Translate
                    msg={titleTranslationKey}
                    placeholders={{
                        year,
                        amount: totalPreventionUnits ?
                            formatFloat(totalPreventionUnits[selectedType], 4, true) : ' ',
                    }}
                />
            }
        </h1>
    );
}

export const Header = connect<IPrivateHeaderProps>({
    stateProps: (state) => {
        const selectedType = getRoutePayload<{ type: PreventionUnitId }>(state).type;
        const totalPreventionUnits = getTotalPreventionUnits(state);
        const companySituationHistory = getCompanySituationHistory(state);

        const year = isPEOverviewRoute(state) ?
            getLatestCompanySituationYear(companySituationHistory) :
            getPreviousCompanySituationYear(companySituationHistory);

        return {
            selectedType,
            totalPreventionUnits,
            year,
        };
    },
})(HeaderComp);
