import { object } from 'yup';
import number from '../../../../utils/libs/yup/number';
import string from '../../../../utils/libs/yup/string';
import { internationalAddressSchema } from '../../../common/address/schema';

export const fields = {
    address: 'address',
    gvn: 'gvn',
    languageId: 'languageId',
    name: 'name',
    ventureNumber: 'ventureNumber',
    billingAddress: 'billingAddress',
};

export const schema = object({
    [fields.gvn]: string().required(),
    [fields.languageId]: number().required(),
    [fields.name]: string().required(),
    [fields.ventureNumber]: string().required(),
    [fields.address]: internationalAddressSchema.required(),
});
