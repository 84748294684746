import { ShapeOf } from '../../../../../models/ui/form';
import { VACCINATIONS_TRANS_PREFIX } from '../../vaccines.const';

import { IRequestVaccinationFormValues } from './RequestVaccinationDialog.type';

export const CLASS_NAME = 'RequestVaccinationDialog';
export const FORM_NAME = 'request-vaccination-form';
export const TRANSLATION_PREFIX = `${VACCINATIONS_TRANS_PREFIX}.request_dialog`;
export const FIELD_NAME_PREFIX = `${TRANSLATION_PREFIX}.form`;

export const fields: ShapeOf<IRequestVaccinationFormValues> = {
    comment: 'comment',
    deadline: 'deadline',
    name: 'name',
    phoneNumber: 'phoneNumber',
    seat: 'seat',
    stepCyclus: 'stepCyclus',
    type: 'type',
};

export const requestVaccinationDialogInitialValues = {
    comment: '',
    deadline: '',
    name: '',
    phoneNumber: '',
    seat: '',
    stepCyclus: '',
    type: '',
};
