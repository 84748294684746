import { createAction, createTypeActions } from '../../index';
import {
    UPDATE_INTERVENTION_REQUEST_WIZARD_ENTITY,
    CREATE_INTERVENTION_REQUEST,
} from './types';
import { navigateTo } from '../../location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    INTERVENTION_REQUEST_WIZARD_STEP_ID, IInterventionRequestWizardPayload,
    IInterventionRequestWizardEntity, ICreateInterventionRequest,
} from '../../../models/interventions/requestIntervention';
import { PRESTATION_CODE } from '../../../config/navigation/interventions.config';
import { getRequestInterventionWizardSteps } from '../../../config/navigation/wizardStepsMap';

// Intervention request wizard
function navigateToInterventionRequestWizardStep(payload: IInterventionRequestWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW, payload);
}

export function triggerInterventionRequestWizard(prestationCode?: PRESTATION_CODE) {
    return navigateToInterventionRequestWizardStep({
        step: getRequestInterventionWizardSteps().firstStepId,
        resetDataEntity: true,
        prestationCode,
    });
}

export function changeInterventionRequestWizardStep(step: INTERVENTION_REQUEST_WIZARD_STEP_ID) {
    return navigateToInterventionRequestWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateInterventionRequestWizardEntity(payload: Partial<IInterventionRequestWizardEntity>) {
    return createAction<object>(
        UPDATE_INTERVENTION_REQUEST_WIZARD_ENTITY,
        payload,
    );
}

// Create intervention request
export const createInterventionRequestActions = createTypeActions<ICreateInterventionRequest>({
    type: CREATE_INTERVENTION_REQUEST,
});
