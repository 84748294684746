import React from 'react';
import classNames from 'classnames';
import './free-time-slot.scss';
import { IEventProps } from '../../../TimeGridEvent';
import { formatTimeOfDateForDisplay } from '../../../../../../../utils/formatting/formatTime';

const CLASS_NAME = 'FreeTimeSlot';

function FreeTimeSlot(props: IEventProps) {
    const className = classNames('rbc-event', CLASS_NAME, {
        selected: props.selected,
        reserved: props.event.reserved,
    });
    return (
        <span className={className}>
            <span className="time">{formatTimeOfDateForDisplay(props.event.start)}</span>
            <span className="label">{props.title}</span>
            <span>{props.title}</span>
        </span>
    );
}

export default FreeTimeSlot;
