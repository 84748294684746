import {
    IUploadEmployeeDocumentPayload,
    IUploadEmployeeDocumentSuccessPayload,
} from '../../../../../models/general/documents';
import { post } from '../../../../../utils/api/requestWrapper';
import buildMultiPartForm from '../../../../../utils/api/buildMultiPartForm';
import { URL } from '../../documents.const';

export function uploadEmployeeDocument(payload: IUploadEmployeeDocumentPayload) {
    const { file } = payload;
    const formData = buildMultiPartForm({
        mediaUpload: file,
        description: file.name,
    });

    return post<IUploadEmployeeDocumentSuccessPayload>({
        url: URL.UPLOAD_EMPLOYEE_DOCUMENT,
        pathParams: {
            id: payload.id,
        },
        body: formData,
        mapResponse: (response) => {
            return {
                savedDocumentId: response.savedDocumentId,
            } as IUploadEmployeeDocumentSuccessPayload;
        },
    });
}
