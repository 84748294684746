import { TUserAccountAccessLevel, AdminType, AdministratorType } from '../models/user/userAccount';

const userFallbackImage = require('../views/assets/img/general/user-placeholder.jpg');

export const AVATAR_IMAGE_TYPES = 'image/png, image/jpeg, image/jpg, image/gif';
export const AVATAR_IMAGE_PLACEHOLDER = userFallbackImage;
export const AVATAR_IMAGE_HEADER_PLACEHOLDER = userFallbackImage;

export const DEFAULT_ACCESS_LEVELS: TUserAccountAccessLevel = {
    company: 'R',
    employee: 'nop',
    invoicing: 'nop',
    planning: 'nop',
    interventions: 'nop',
};

// 1814 = Dutch
export const DEFAULT_LANGUAGE_ID = 1814;

export const NUMBER_ADMIN_TYPE_TO_STRING_MAP = {
    [AdminType.Administrator]: AdministratorType.Administrator,
    [AdminType.MainAdministrator]: AdministratorType.MainAdministrator,
    [AdminType.None]: AdministratorType.GeneralUser,
};
