import React from 'react';
import Button from '../../../buttons/Button';
import Translate from '../../../Translate';
import ListFooter from '../../../list/ListFooter';
import { IFooterConfig, TMasterData } from '../typings';
import isEmptyObject from '../../../../../utils/core/object/isEmptyObject';

interface IPublicProps {
    isFetchingMaster: boolean;
    footerConfig?: IFooterConfig;
    masterData: TMasterData;
    clientSideFilteredListItems?: TMasterData;
    filterValues: object;
    onShowAll: () => void;
}

export default function Footer(props: IPublicProps) {
    const {
        isFetchingMaster, footerConfig,
        masterData, clientSideFilteredListItems, filterValues, onShowAll,
    } = props;

    if (!footerConfig) {
        return null;
    }

    const showAllButton = !isFetchingMaster && footerConfig.shouldRenderShowAllButton &&
        footerConfig.shouldRenderShowAllButton({
            masterData: clientSideFilteredListItems || masterData,
            filterValues,
        }) &&
        (
            <Button
                id="MasterWithDetail_showAll"
                onClick={onShowAll}
                className="ShowAllButton"
                typeName="text"
            >
                <span>
                    <Translate msg={'common.master_with_detail.action.show_all_results'} />
                    {getTotalNumberOfResultsSuffix(masterData, clientSideFilteredListItems)}
                </span>
            </Button>
        );

    if (!showAllButton &&
        !footerConfig.renderActionsLeft &&
        !footerConfig.renderActionsCenter &&
        !footerConfig.renderActionsRight
    ) {
        return null;
    }

    return (
        <ListFooter
            left={footerConfig.renderActionsLeft && footerConfig.renderActionsLeft({
                isFetchingMaster,
            })}
            center={
                <>
                    {showAllButton}
                    {footerConfig.renderActionsCenter && footerConfig.renderActionsCenter({
                        isFetchingMaster,
                    })}
                </>
            }
            right={footerConfig.renderActionsRight && footerConfig.renderActionsRight({
                isFetchingMaster,
            })}
        />
    );
}

function getTotalNumberOfResultsSuffix(
    masterData: TMasterData,
    clientSideFilteredListItems?: TMasterData,
): string {
    const data = clientSideFilteredListItems || masterData;

    return !isEmptyObject(data)
        ? ` (${Object.keys(data).length})`
        : '';
}
