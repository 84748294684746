import React from 'react';
import { IDescriptionPeriod } from '../../../../models/admin/employee';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import Translate from '../../../common/Translate';

export default function DescriptionPeriod({ periods }: { periods: IDescriptionPeriod[] }) {
    if (!periods || periods.length === 0) {
        // tslint:disable-next-line no-any
        return '-' as any;
    }

    return (
        <>
        {periods.map((period) => (
            <div
                key={`desc-period-${period.id}-${period.personalId}`}
                className="DescriptionPeriod"
            >
                {period.description}<br />
                {formatDateForDisplay(period.beginDate)} - <DisplayPresentDateOtherwiseFormat date={period.endDate} />
            </div>
        ))}
        </>
    );
}

function DisplayPresentDateOtherwiseFormat({ date }: { date?: string }): JSX.Element {
    if (!date) {
        return (
            <Translate msg="app_shell.dates.present" />
        );
    }

    // tslint:disable-next-line no-any
    return formatDateForDisplay(date) as any;
}
