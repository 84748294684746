import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../../index';
import { getRoutePayload } from '../../location/selectors';
import { getFieldAscendingComparator } from '../../../utils/core/object/sortObjects';
import { IRisk } from '../../../models/admin/risks';
import { IResearch } from '../../../models/admin/researches';
import { IAddedCompanyFunction } from '../../../models/admin/companyFunctions';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Company functions
const getCompanyFunctionsAsyncField = (state: IState) => reducerState(state).companyFunctions;

export const getCompanyFunctions = (state: IState) =>
    getCompanyFunctionsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getFetchCompanyFunctionsAsyncInfo = makeAsyncFetchInfoSelector(getCompanyFunctionsAsyncField);

export const getSelectedCompanyFunctionFromList = (state: IState, id) =>
    getCompanyFunctions(state)
        .find((item) => item.id === id);

export const isCompanyFunctionsDataAvailable = (state: IState) => !!getCompanyFunctionsAsyncField(state).data;

export const getSelectedCompanyFunctionId = (state: IState) => {
    return getRoutePayload<{ functionId: string }>(state).functionId || null;
};

export const getSelectedCompanyFunction = (state: IState) => {
    const functionId = getSelectedCompanyFunctionId(state);

    if (functionId) {
        const selectedCompanyFunction = getSelectedCompanyFunctionFromList(state, functionId);

        return selectedCompanyFunction;
    }

    return null;
};

// Company function isco

const getCompanyFunctionIscoAsyncField = (state: IState) =>
    reducerState(state).selectedCompanyFunctionIsco;

export const getCompanyFunctionIscoAsyncInfo = makeAsyncFetchInfoSelector(getCompanyFunctionIscoAsyncField);

export const getCompanyFunctionIsco = (state: IState) =>
    getCompanyFunctionIscoAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Company function risks

const getCompanyFunctionRisksAsyncField = (state: IState) =>
    reducerState(state).companyFunctionRisks;

export const getCompanyFunctionRisksAsyncInfo = makeAsyncFetchInfoSelector(getCompanyFunctionRisksAsyncField);

export const getCompanyFunctionRisks = (state: IState) => {
    const list = getCompanyFunctionRisksAsyncField(state).data;

    if (!list || list.length === 0) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return list
        .sort(getFieldAscendingComparator<IRisk>('description'));
};

// Company function researches

const getCompanyFunctionResearchesAsyncField = (state: IState) =>
    reducerState(state).companyFunctionResearches;

export const getCompanyFunctionResearchesAsyncInfo = makeAsyncFetchInfoSelector(getCompanyFunctionResearchesAsyncField);

export const getCompanyFunctionResearches = (state: IState) => {
    const list = getCompanyFunctionResearchesAsyncField(state).data;

    if (!list || list.length === 0) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return list
        .sort(getFieldAscendingComparator<IResearch>('researchDescription'));
};

// Company functions for typeahead

const getCompanyFunctionsForTypeAheadAsyncField = (state: IState) => reducerState(state).companyFunctionsForTypeAhead;

export const getCompanyFunctionsForTypeAhead = (state: IState) =>
    getCompanyFunctionsForTypeAheadAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getFetchCompanyFunctionsForTypeAheadAsyncInfo =
    makeAsyncFetchInfoSelector(getCompanyFunctionsForTypeAheadAsyncField);

// Add company function

const getAddCompanyFunctionAsyncField = (state: IState) => reducerState(state).addCompanyFunction;

export const getAddCompanyFunctionAsyncInfo = makeAsyncDoInfoSelector(getAddCompanyFunctionAsyncField);
export const getAddedCompanyFunction = (state: IState) =>
    reducerState(state).addedCompanyFunction || NO_RERENDER.EMPTY_OBJECT as IAddedCompanyFunction;
