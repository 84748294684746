import { IContactItem } from '../../models/general/navigation';
import ROUTE_KEYS from '../../routeKeys';
import { IFeedbackItem, FeedbackType } from '../../models/contact/feedback';
import { CONTACT_SVG_IDS } from '../../models/general/lazyLoadSvg';

export const CONTACT_ITEMS: IContactItem[] = [
    {
        id: 'contact-plan-link',
        linkTo: ROUTE_KEYS.R_PLAN_INTERVENTION,
        titleTranslationKey: 'contact.overview.main.plan.title',
        descriptionTranslationKey: 'contact.overview.main.plan.description',
        lazyLoadSvgId: CONTACT_SVG_IDS.PLAN,
    },
    {
        id: 'contact-faq-link',
        linkTo: ROUTE_KEYS.R_FAQ_OVERVIEW,
        titleTranslationKey: 'contact.overview.main.faq.title',
        descriptionTranslationKey: 'contact.overview.main.faq.description',
        lazyLoadSvgId: CONTACT_SVG_IDS.FAQ,
    },
    {
        id: 'contact-contact-link',
        linkTo: ROUTE_KEYS.R_CONTACT_DETAIL,
        titleTranslationKey: 'contact.overview.main.contact.title',
        descriptionTranslationKey: 'contact.overview.main.contact.description',
        lazyLoadSvgId: CONTACT_SVG_IDS.CONTACT,
    },
];

export const FEEDBACK_ITEMS: IFeedbackItem[] = [
    {
        id: 'contact-feedback-suggestion',
        descriptionTranslationKey: 'contact.overview.detail.options.suggestion',
        lazyLoadSvgId: CONTACT_SVG_IDS.SUGGESTION,
        feedbackType: FeedbackType.SUGGESTION,
    },
    {
        id: 'contact-feedback-complaint',
        descriptionTranslationKey: 'contact.overview.detail.options.complaint',
        lazyLoadSvgId: CONTACT_SVG_IDS.COMPLAINT,
        feedbackType: FeedbackType.COMPLAINT,
    },
    {
        id: 'contact-feedback-question',
        descriptionTranslationKey: 'contact.overview.detail.options.question',
        lazyLoadSvgId: CONTACT_SVG_IDS.QUESTION,
        feedbackType: FeedbackType.QUESTION,
    },
];
