import { get, remove, post, put, IResponseType } from '../../utils/api/requestWrapper';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import {
    IFetchCoursesPayload, ICoursesFilter,
    ICourse,
    ICMSCourse,
    IFetchCourseSessionPayload,
    ICourseSession,
    IFetchCourseSessionDaysPayload,
    ICourseSessionDay,
    IFetchCourseSessionAttendeesPayload,
    ICourseSessionAttendee,
    IFetchCourseSessionCertificatesPayload,
    ICourseCategory,
    IFetchCMSCourseDetailPayload,
    ICMSCourseDetail,
    IRemoveCourseAttendeePayload,
    IFetchCourseSessionAttendancePayload,
    ICourseSessionAttendant,
    IFetchCoursePossibleLocationsPayload,
    IFetchCourseSessionsByLocationPayload,
    ICourseSessionByLocation,
    ICourseEnrollWizardEntity,
    ICourseExternalEmployee,
    ICertificate,
    IFetchCertificateDetailPayload,
    ICertificateEmployee,
    IFetchEmployeeCoursesPayload,
    IEmployeeCourse,
    IFetchCourseExternalEmployeePayload,
    IUpdateCourseExternalEmployeePayload,
    IFetchCMSCourseTablePayload,
    ICourseSessionsByMonth,
    IFetchCMSCourseDetailByHawIdPayload,
} from '../../models/documentCenter/courses';
import { ONE_MINUTE } from '../../utils/core/time/periodsInMillis';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';
import { IDocument } from '../../models/general/documents';
import { IAddress } from '../../models/general/address';
import { IEmployee } from '../../models/admin/employee';
import { ICompanyCodeWithShowFullFamilyPayload, ICompanyCodePayload } from '../../models/admin/company';
import { NO_SPOTLIGHT_COURSE_ID } from '../../config/courses.config';

export const URL = {
    CERTIFICATES: '/companies/{companyCode}/achieved-certificates',
    CERTIFICATE_DETAIL: '/companies/{companyCode}/achieved-certificates/{code}/employees',
    COURSES: '/companies/{companyCode}/courses/planned',
    COURSES_OVERVIEW: '/courses',
    COURSE_DETAIL: '/courses/{nodeId}',
    COURSE_HAW: '/courses/haw/{id}',
    COURSE_DETAIL_TABLE: '/courses/table/{nodeId}',
    COURSE_SESSION: '/sessions/{id}',
    COURSE_SESSION_DAYS: '/courses/{coursesOrganizedId}/days',
    COURSE_SESSION_ATTENDEES: '/companies/{companyCode}/courses/{coursesOrganizedId}/attendees',
    REMOVE_COURSE_ATTENDEE: '/companies/{companyCode}/courses/{coursesOrganizedId}/attendees/{id}',
    COURSE_SESSION_CERTIFICATES: '/companies/{companyCode}/courses/{coursesOrganizedId}/certificates',
    COURSE_SESSION_ATTENDANCE: '/companies/{companyCode}/courses/{coursesOrganizedId}/attendance',
    COURSE_POSSIBLE_LOCATIONS: '/courses/{id}/locations',
    COURSE_SESSIONS_BY_LOCATION: '/courses/{id}/sessions/{locationId}',
    CREATE_COURSE_ENROLLMENT: '/companies/{companyCode}/courses/{coursesOrganizedId}/enroll',
    EMPLOYEE_COURSES: '/employees/{id}/courses',
    COURSE_EXTERNAL_EMPLOYEE: '/companies/{companyCode}/courses/employees/{employeeId}',
    SPOTLIGHT_COURSE: '/courses/spotlight',
};

export const DEFAULT_COURSES_PLANNED_FILTER = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(yearOffsetFromNow(2)),
};

export function fetchCoursesPlanned(
    { companyCode, showFullFamily }: IFetchCoursesPayload,
    {
        startDate = DEFAULT_COURSES_PLANNED_FILTER.startDate,
        endDate = DEFAULT_COURSES_PLANNED_FILTER.endDate,
    }: ICoursesFilter,
) {
    return get<ICourse[]>({
        url: URL.COURSES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['courses'],
    });
}

export function fetchCourseSession(
    { id }: IFetchCourseSessionPayload,
) {
    return get<ICourseSession>({
        url: URL.COURSE_SESSION,
        pathParams: {
            id,
        },
    });
}

export function fetchCourseSessionDays(
    { coursesOrganizedId }: IFetchCourseSessionDaysPayload,
) {
    return get<ICourseSessionDay[]>({
        url: URL.COURSE_SESSION_DAYS,
        pathParams: {
            coursesOrganizedId,
        },
        mapResponse: (response) => response['course-days'],
    });
}

export function fetchCourseSessionAttendees(
    { companyCode, showFullFamily, coursesOrganizedId }: IFetchCourseSessionAttendeesPayload,
) {
    return get<ICourseSessionAttendee[]>({
        url: URL.COURSE_SESSION_ATTENDEES,
        pathParams: {
            companyCode,
            coursesOrganizedId,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['course-attendees'],
    });
}

export const DEFAULT_COURSES_FOLLOWED_FILTER = {
    startDate: formatDateForBackend(yearOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchCoursesFollowed(
    { companyCode, showFullFamily }: IFetchCoursesPayload,
    {
        startDate = DEFAULT_COURSES_FOLLOWED_FILTER.startDate,
        endDate = DEFAULT_COURSES_FOLLOWED_FILTER.endDate,
    }: ICoursesFilter,
) {
    return get<ICourse[]>({
        url: URL.COURSES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['courses'],
    });
}

export function fetchCourseSessionCertificates(
    { companyCode, coursesOrganizedId }: IFetchCourseSessionCertificatesPayload,
) {
    return get<IDocument>({
        url: URL.COURSE_SESSION_CERTIFICATES,
        timeoutInMillis: 5 * ONE_MINUTE,
        pathParams: {
            companyCode,
            coursesOrganizedId,
        },
        responseType: IResponseType.blob,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers),
            };
        },
    });
}

export function fetchCoursesOverview() {
    return get<ICourseCategory[]>({
        url: URL.COURSES_OVERVIEW,
        timeoutInMillis: 5 * ONE_MINUTE,
        mapResponse: (response) => Object.keys(response)
            .map((courseCategoryId) => response[courseCategoryId]),
    });
}

export function fetchCourseTable({ childNodeId }: IFetchCMSCourseTablePayload) {
    return get<ICourseSessionsByMonth>({
        url: URL.COURSE_DETAIL_TABLE,
        pathParams: {
            nodeId: childNodeId,
        },
        mapResponse: (response) => response['sessions'],
    });
}

export function removeCourseAttendee(
    { companyCode, showFullFamily, coursesOrganizedId, employeeId }: IRemoveCourseAttendeePayload,
) {
    return remove<ICourseSession>({
        url: URL.REMOVE_COURSE_ATTENDEE,
        pathParams: {
            companyCode,
            coursesOrganizedId,
            id: employeeId,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
    });
}

export function fetchCoursesOverviewDetail({ nodeId }: IFetchCMSCourseDetailPayload) {
    return get<ICMSCourseDetail>({
        url: URL.COURSE_DETAIL,
        pathParams: {
            nodeId,
        },
    });
}

export function fetchCourseByHawId({ id }: IFetchCMSCourseDetailByHawIdPayload) {
    return get<ICMSCourseDetail>({
        url: URL.COURSE_HAW,
        pathParams: { id },
    });
}

export function fetchCourseSessionAttendance(
    { companyCode, showFullFamily, coursesOrganizedId }: IFetchCourseSessionAttendancePayload,
) {
    return get<ICourseSessionAttendant[]>({
        url: URL.COURSE_SESSION_ATTENDANCE,
        pathParams: {
            companyCode,
            coursesOrganizedId,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['employees'],
    });
}

export function fetchCoursePossibleLocations(
    { id }: IFetchCoursePossibleLocationsPayload,
) {
    return get<IAddress[]>({
        url: URL.COURSE_POSSIBLE_LOCATIONS,
        pathParams: {
            id,
        },
    });
}

export function fetchCourseSessionsByLocation(
    { id, locationId }: IFetchCourseSessionsByLocationPayload,
) {
    return get<ICourseSessionByLocation[]>({
        url: URL.COURSE_SESSIONS_BY_LOCATION,
        pathParams: {
            id,
            locationId,
        },
    });
}

export function createCourseEnrollment(companyCode: string, wizardEntity: Partial<ICourseEnrollWizardEntity>) {
    return post({
        url: URL.CREATE_COURSE_ENROLLMENT,
        pathParams: {
            companyCode,
            coursesOrganizedId: wizardEntity.session.coursesOrganizedId,
        },
        body: mapCourseWizardEntityToEnrollPayload(wizardEntity),
    });
}

function mapCourseWizardEntityToEnrollPayload(wizardEntity: Partial<ICourseEnrollWizardEntity>) {
    const employees = wizardEntity.employees.map((employee: IEmployee) => {
        return { employeeId: employee.employeeId };
    });

    Array.prototype.push.apply(employees, wizardEntity.externalEmployees);

    return employees;

}

export function fetchCertificates(
    { companyCode, showFullFamily }: ICompanyCodeWithShowFullFamilyPayload,
) {
    return get<ICertificate[]>({
        url: URL.CERTIFICATES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['certificates'],
    });
}

export function fetchCertificateDetail(
    {
        companyCode,
        showFullFamily,
        code,
    }: IFetchCertificateDetailPayload & ICompanyCodeWithShowFullFamilyPayload,
) {
    return get<ICertificateEmployee[]>({
        url: URL.CERTIFICATE_DETAIL,
        pathParams: {
            companyCode,
            code,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['employees'],
    });
}

export function fetchEmployeeCourses(
    { id, showFullFamily }: IFetchEmployeeCoursesPayload) {
    return get<IEmployeeCourse[]>({
        url: URL.EMPLOYEE_COURSES,
        pathParams: {
            id,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['courses'],
    });
}

export function fetchCourseExternalEmployee(
    { companyCode, employeeId }: IFetchCourseExternalEmployeePayload & ICompanyCodePayload) {
    return get<ICourseExternalEmployee>({
        url: URL.COURSE_EXTERNAL_EMPLOYEE,
        pathParams: {
            employeeId,
            companyCode,
        },
        mapResponse: (response) => response['employee'],
    });
}

export function updateCourseExternalEmployee(
    { companyCode, employeeId, fieldsToUpdate }: IUpdateCourseExternalEmployeePayload & ICompanyCodePayload) {
    return put({
        url: URL.COURSE_EXTERNAL_EMPLOYEE,
        pathParams: {
            employeeId,
            companyCode,
        },
        body: fieldsToUpdate,
    });
}

const noSpotlightCourseFound = { nodeId: NO_SPOTLIGHT_COURSE_ID };

export function fetchSpotlightCourse() {
    return get<ICMSCourse>({
        url: URL.SPOTLIGHT_COURSE,
        mapResponse: (response) => response || noSpotlightCourseFound,
    });
}
