import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_WORK_ACCIDENTS, FETCH_WORK_ACCIDENT_FILES,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IWorkAccident } from '../../../models/interventions/workAccidents';
import { IDocument } from '../../../models/general/documents';
import { DISMISS_DOCUMENT_DIALOG } from '../../employee/documents/types';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INTERVENTION_WORK_ACCIDENTS;

export interface IReducerState {
    workAccidents: IAsyncFetchField<IWorkAccident[]>;
    workAccidentFiles: IAsyncFetchField<IDocument>;
}

const initialState: IReducerState = {
    workAccidents: getAsyncFetchInitialState(),
    workAccidentFiles: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(DISMISS_DOCUMENT_DIALOG)
    .onTrigger(({ oldState }) => {
        return {
            ...oldState,
            workAccidentFiles: getAsyncFetchInitialState(),
        };
    })
    .create(),
    ...createAsyncFetchActionHandlers<IWorkAccident[], IReducerState, IWorkAccident[]>({
        baseActionType: FETCH_WORK_ACCIDENTS,
        fieldName: 'workAccidents',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_WORK_ACCIDENTS,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument, IReducerState, IDocument>({
        baseActionType: FETCH_WORK_ACCIDENT_FILES,
        fieldName: 'workAccidentFiles',
        resetDataOnTrigger: true,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
