import { get, remove, put } from '../../utils/api/requestWrapper';
import {
    IRemoveEmployeeRiskPayload,
    IUpdateEmployeeRiskPayload,
} from '../../models/admin/employee';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { IRisk } from '../../models/admin/risks';

export const URL = {
    EMPLOYEE_RISK: '/employees/{employeeId}/risks/{personalId}',
    COMPANY_RISKS: '/companies/{companyCode}/risks',
};

export function updateRisk(payload: IUpdateEmployeeRiskPayload) {
    return put({
        url: URL.EMPLOYEE_RISK,
        pathParams: {
            employeeId: payload.employeeId,
            personalId: payload.personalId,
        },
        queryParams: {
            dateInactive: payload.dateInactive,
        },
    });
}

export function removeRisk(payload: IRemoveEmployeeRiskPayload) {
    return remove({
        url: URL.EMPLOYEE_RISK,
        pathParams: {
            employeeId: payload.employeeId,
            personalId: payload.personalId,
        },
        queryParams: {
            dateInactive: formatDateForBackend(new Date()),
        },
    });
}

export function fetchRisks(
    companyCode: string,
    showFullFamily = false,
) {
    return get<IRisk[]>({
        url: URL.COMPANY_RISKS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            ...!!showFullFamily && {
                showFullFamily: showFullFamily.toString(),
            },
        },
        mapResponse: (response) => response['risks'],
    });
}
