import React, { ReactNode } from 'react';
import './no-header-or-footer-template.scss';

export default function NoHeaderOrFooterTemplate(props: {children: ReactNode}) {
    return (
        <div className="NoHeaderOrFooterTemplate">
            {props.children}
        </div>
    );
}
