import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';

import {
    ICompanyAddress,
    ICompanyAvailabilities,
    ICompanyContact,
    ICompanyDetail,
    ICompanyHoliday,
    ICompanyMedicalCenter,
    IUpdateCompanySingleFieldPayload,
} from '../../../models/admin/companyInfo';
import { ICompanySeat } from '../../../models/admin/company';
import { ICostCenter } from '../../../models/admin/employee';
import { IRates } from '../../../models/admin/rates';
import { IRisk } from '../../../models/admin/risks';
import { ITraceableApiError } from '../../../models/general/error';
import { ITraceableAsyncRequestPayload } from '../../../models/general/redux';
import { REDUCER_KEYS } from '../../../config/redux.config';
import isTraceableApiError from '../../../utils/api/isTraceableApiError';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IAsyncFetchField,
    IAsyncDoField,
    registerReducer,
    getAsyncFetchInitialState,
    getAsyncDoInitialState,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
} from '../../index';

import * as COMPANY_INFO_TYPES from './types';

export const reducerKey = REDUCER_KEYS.COMPANY_INFO;

export interface IReducerState {
    risks: IAsyncFetchField<IRisk[]>;
    companyMedicalCenters: IAsyncFetchField<ICompanyMedicalCenter[]>;
    companyCostCenters: IAsyncFetchField<ICostCenter[]>;
    companyAddresses: IAsyncFetchField<ICompanyAddress[]>;
    companyDetail: IAsyncFetchField<ICompanyDetail>;
    companyRates: IAsyncFetchField<IRates>;
    updateCompany: IAsyncDoField;
    updateCompanySingleFieldRequests: { [requestId: string]: IAsyncDoField };
    addCompanySeat: IAsyncDoField;

    companyContacts: IAsyncFetchField<ICompanyContact[]>;
    companySeatContacts: IAsyncFetchField<ICompanyContact[]>;
    companySeatDetail: IAsyncFetchField<ICompanyDetail>;
    companySeatsWithEmployeeCount: IAsyncFetchField<ICompanySeat[]>;
    removeContact: IAsyncDoField;
    addContact: IAsyncDoField;
    updateContact: IAsyncDoField;

    companyAvailabilitiesMedExams: IAsyncFetchField<ICompanyAvailabilities>;
    companyAvailabilitiesMedExamsReplace: IAsyncDoField;
    companyAvailabilitiesRiskMgmt: IAsyncFetchField<ICompanyAvailabilities>;
    companyAvailabilitiesRiskMgmtReplace: IAsyncDoField;

    companyHolidays: IAsyncFetchField<ICompanyHoliday[]>;
    updateCompanyHolidays: IAsyncDoField;
}

const initialState: IReducerState = {
    risks: getAsyncFetchInitialState(),
    companyMedicalCenters: getAsyncFetchInitialState(),
    companyCostCenters: getAsyncFetchInitialState(),
    companyAddresses: getAsyncFetchInitialState(),
    companyDetail: getAsyncFetchInitialState(),
    companyRates: getAsyncFetchInitialState(),
    updateCompany: getAsyncDoInitialState(),
    updateCompanySingleFieldRequests: {},
    addCompanySeat: getAsyncDoInitialState(),

    companyContacts: getAsyncFetchInitialState(),
    companySeatContacts: getAsyncFetchInitialState(),
    companySeatDetail: getAsyncFetchInitialState(),
    companySeatsWithEmployeeCount: getAsyncFetchInitialState(),
    removeContact: getAsyncDoInitialState(),
    addContact: getAsyncDoInitialState(),
    updateContact: getAsyncDoInitialState(),

    companyAvailabilitiesMedExams: getAsyncFetchInitialState(),
    companyAvailabilitiesMedExamsReplace: getAsyncDoInitialState(),
    companyAvailabilitiesRiskMgmt: getAsyncFetchInitialState(),
    companyAvailabilitiesRiskMgmtReplace: getAsyncDoInitialState(),

    companyHolidays: getAsyncFetchInitialState(),
    updateCompanyHolidays: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IRisk[], IReducerState, IRisk[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_RISKS,
        fieldName: 'risks',
        overrideTriggerActionType: ROUTE_KEYS.R_COMPANY_RISKS,
        resetDataOnTrigger: false,
        reducerKey,
    }),

    ...createAsyncFetchActionHandlers<ICompanyMedicalCenter[], IReducerState, ICompanyMedicalCenter[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_MEDICAL_CENTERS,
        fieldName: 'companyMedicalCenters',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICostCenter[], IReducerState, ICostCenter[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_COST_CENTERS,
        fieldName: 'companyCostCenters',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICompanyAddress[], IReducerState, ICompanyAddress[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_ADDRESSES,
        fieldName: 'companyAddresses',
        resetDataOnTrigger: false,
        reducerKey,
    }),

    ...createAsyncFetchActionHandlers<ICompanyDetail, IReducerState, ICompanyDetail>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_DETAIL,
        fieldName: 'companyDetail',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
            COMPANY_INFO_TYPES.FETCH_SMALL_COMPANY_DETAIL,
        ],
        extraAsyncFetchFieldNamesToResetOnTrigger: [
            'companyAvailabilitiesMedExams',
            'companyAvailabilitiesRiskMgmt',
        ],
        reducerKey,
    }),

    ...createAsyncFetchActionHandlers<ICompanyDetail, IReducerState, ICompanyDetail>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_SEAT_DETAIL,
        fieldName: 'companySeatDetail',
        reducerKey,
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY,
        fieldName: 'updateCompany',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY_SINGLE_FIELD,
        fieldName: 'updateCompanySingleFieldRequests',
        transformStateOnTrigger: transformUpdateCompanySingleFieldRequestsState,
        transformStateOnFail: transformUpdateCompanySingleFieldRequestsState,
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IUpdateCompanySingleFieldPayload;
            const requestId = successPayload.requestId;

            return {
                ...oldState,
                updateCompanySingleFieldRequests: {
                    ...oldState.updateCompanySingleFieldRequests,
                    [requestId]: undefined,
                },
                // overwrite the company detail data with the changed field
                companyDetail: {
                    ...oldState.companyDetail,
                    data: {
                        ...oldState.companyDetail.data,
                        ...successPayload.companyData,
                    },
                },
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.ADD_COMPANY_SEAT,
        fieldName: 'addCompanySeat',
    }),

    ...createAsyncFetchActionHandlers<ICompanyContact[], IReducerState, ICompanyContact[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_INTERNAL_CONTACTS,
        fieldName: 'companyContacts',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_INFO_CONTACTS,
            COMPANY_INFO_TYPES.FETCH_COMPANY_INTERNAL_CONTACTS,
        ],
        resetDataOnTrigger: false,
        extraAsyncFetchFieldNamesToResetOnTrigger: [
            'companyAvailabilitiesMedExams',
            'companyAvailabilitiesRiskMgmt',
        ],
        extraAsyncDoFieldNamesToResetOnTrigger: [
            'removeContact',
            'addContact',
        ],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICompanyContact[], IReducerState, ICompanyContact[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
        fieldName: 'companySeatContacts',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
            COMPANY_INFO_TYPES.FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
        ],
        resetDataOnTrigger: false,
        extraAsyncDoFieldNamesToResetOnTrigger: [
            'addContact',
        ],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICompanySeat[], IReducerState, ICompanySeat[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
        fieldName: 'companySeatsWithEmployeeCount',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_INFO_SEATS,
            COMPANY_INFO_TYPES.FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.REMOVE_CONTACT,
        fieldName: 'removeContact',
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.ADD_CONTACT,
        fieldName: 'addContact',
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.UPDATE_CONTACT,
        fieldName: 'updateContact',
        transformStateOnTrigger: ({ oldState }) => {
            // reset updateCompany async info (so that the user does not see the error of the previous seat)
            return {
                ...oldState,
                updateCompany: getAsyncDoInitialState(),
            };
        },
    }),

    ...createAsyncFetchActionHandlers<ICompanyAvailabilities, IReducerState, ICompanyAvailabilities>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_AVAILABILITIES_MED_EXAMS,
        fieldName: 'companyAvailabilitiesMedExams',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS,
        fieldName: 'companyAvailabilitiesMedExamsReplace',
    }),
    ...createAsyncFetchActionHandlers<ICompanyAvailabilities, IReducerState, ICompanyAvailabilities>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_AVAILABILITIES_RISK_MGMT,
        fieldName: 'companyAvailabilitiesRiskMgmt',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT,
        fieldName: 'companyAvailabilitiesRiskMgmtReplace',
    }),

    ...createAsyncFetchActionHandlers<ICompanyHoliday[], IReducerState, ICompanyHoliday[]>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_HOLIDAYS,
        fieldName: 'companyHolidays',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY_HOLIDAYS,
        fieldName: 'updateCompanyHolidays',
    }),
    ...createAsyncFetchActionHandlers<IRates, IReducerState, IRates>({
        baseActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_RATES,
        fieldName: 'companyRates',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COMPANY_RATES,
            COMPANY_INFO_TYPES.FETCH_COMPANY_RATES,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});

function transformUpdateCompanySingleFieldRequestsState({
    oldState,
    payload,
}: {
    oldState: IReducerState,
    payload: IUpdateCompanySingleFieldPayload | (ITraceableApiError & ITraceableAsyncRequestPayload),
}): IReducerState {
    const requestId = payload.requestId;
    let requestStatus: IAsyncDoField;

    if (isTraceableApiError(payload)) {
        requestStatus = {
            isDoing: false,
            isDone: true,
            error: payload,
        } as IAsyncDoField;
    } else {
        requestStatus = {
            isDoing: true,
            isDone: false,
            error: null,
        } as IAsyncDoField;
    }

    return {
        ...oldState,
        updateCompanySingleFieldRequests: {
            ...oldState.updateCompanySingleFieldRequests,
            [requestId]: requestStatus,
        },
    };
}
