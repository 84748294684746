import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import AmountInput, { IFormValues } from './AmountInput';
import { schema } from './fluVaccineAmountToOrderSchema';
import {
    getFluVaccinesAvailableAsyncInfo,
    getFluVaccinesOrderWizardEntity,
    getFluVaccineTariffsAsyncInfo,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import { updateFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/actions';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { AsyncStatus } from '../../../../../models/general/redux';

const CLASS_NAME = 'OrderFluVaccine__Amount';
const FORM_NAME = 'flu-vaccine-amount-form';
const DEFAULT_AMOUNT = 1;

const AmountWizardFormStep = WizardFormStep<IFormValues>();

export default function Amount(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <AmountWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.flu_orders_new.steps.nr_of_vaccines.title',
                textTranslationKey: 'interventions.flu_orders_new.steps.nr_of_vaccines.text',
            }}
            showLoaderSelector={(state) => {
                const tariffsAsyncInfo = getFluVaccineTariffsAsyncInfo(state);
                const availableAsyncInfo = getFluVaccinesAvailableAsyncInfo(state);

                return tariffsAsyncInfo.status === AsyncStatus.Busy
                    || availableAsyncInfo.status === AsyncStatus.Busy;
            }}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const amountVaccines = getFluVaccinesOrderWizardEntity(state).amountVaccines;

                    return {
                        amountToOrder: (isSet(amountVaccines) && amountVaccines >= 0) ? amountVaccines : DEFAULT_AMOUNT,
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <AmountInput {...renderProps} initialAmountToOrder={0} />
                    </div>,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => updateFluVaccinesOrderWizardEntity({
                    amountVaccines: values.amountToOrder,
                }),
            }}
        />
    );
}
