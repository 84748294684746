import { TUserDataFormValues } from './userDataModels';

type TPlaceholerKeys = { [key in keyof TUserDataFormValues]: string };

const TRANSLATION_KEY_PREFIX = 'account.account_settings.manage_users.add.wizard.steps.personal_data.fields';
const PLACEHOLDER_KEYS: TPlaceholerKeys = {
    firstName: `${TRANSLATION_KEY_PREFIX}.first_name`,
    name: `${TRANSLATION_KEY_PREFIX}.name`,
    email: `${TRANSLATION_KEY_PREFIX}.email`,
    languageId: `${TRANSLATION_KEY_PREFIX}.language`,
    mobilePhoneNumber: `${TRANSLATION_KEY_PREFIX}.mobile_phone`,
    phoneNumber: `${TRANSLATION_KEY_PREFIX}.phone`,
    titleId: `${TRANSLATION_KEY_PREFIX}.salutation`,
    sexId: `${TRANSLATION_KEY_PREFIX}.sex`,
    admin: null,
};

export default PLACEHOLDER_KEYS;
