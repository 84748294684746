import { IRoutes, IRoute, IEntityToFetchOnRoute } from '../../utils/routing/typings';
import Login, { ILoginProps } from './Login';
import templates from '../appShell/templates';
import ROUTE_KEYS from '../../routeKeys';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import NoHeaderOrFooterTemplate from '../appShell/templates/NoHeaderOrFooterTemplate';
import LoginInProgress from './LoginInProgress';
import LogoutInProgress from './LogoutInProgress';

const LOGIN_PAGE = {
    key: 'LOGIN',
    component: Login,
};

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_LOGIN]: getLoginRoute({
        path: '/login',
        virtualPage: 'login',
        entitiesToFetch: [
            {
                entityKey: ASYNC_ENTITY_KEYS.maintenanceMessages,
                refreshMode: 'never',
            },
        ],
    }),
    [ROUTE_KEYS.R_LOGIN_REDIRECT]: {
        path: '/auth-callback',
        virtualPage: 'auth_login_redirect',
        page: {
            key: 'LOGIN_REDIRECT',
            component: LoginInProgress,
        },
        template: NoHeaderOrFooterTemplate,
        allowAnonymousAccess: true,
    },
    [ROUTE_KEYS.R_LOGOUT]: {
        path: '/logout',
        virtualPage: 'logout',
        page: {
            key: 'LOGOUT',
            component: LogoutInProgress,
        },
        template: NoHeaderOrFooterTemplate,
        allowAnonymousAccess: true,
    },
    [ROUTE_KEYS.R_REQUEST_ACCOUNT]: getLoginRoute({
        path: '/login/request-account',
        pageProps: {
            showRequestAccount: true,
        } as ILoginProps,
        virtualPage: 'request_account',
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.maintenanceMessages,
            refreshMode: 'never',
        }],
    }),
    [ROUTE_KEYS.R_NEW_FEATURES_CAROUSEL]: getLoginRoute({
        path: '/login/new-features',
        pageProps: {
            showCarousel: true,
        } as ILoginProps,
        virtualPage: 'new_features_carousel',
    }),
};

export default ROUTES;

function getLoginRoute({
    path,
    virtualPage,
    pageProps,
    entitiesToFetch,
}: {
    path: string
    virtualPage: string
    pageProps?: object,
    entitiesToFetch?: IEntityToFetchOnRoute<{}, {}>[],
}): IRoute {
    return {
        path,
        pageProps,
        virtualPage,
        page: LOGIN_PAGE,
        template: templates.NoHeaderOrFooterTemplate,
        allowAnonymousAccess: true,
        entitiesToFetch,
        groups: [
            ROUTE_GROUP.NO_DATA_REFRESH_ON_LOCALE_SWITCH,
        ],
    };
}
