import { object } from 'yup';
import { ShapeOf } from '../../../../models/ui/form';
import { FormValues } from '.';
import phoneNumber from '../../../../utils/libs/yup/phoneNumber';

export const fields: ShapeOf<FormValues> = {
    identificationDate: 'identificationDate',
    identificationIdentification: 'identificationIdentification',
    userAddress: 'userAddress',
    userExternalPbw: 'userExternalPbw',
    userName: 'userName',
    userPhoneContact: 'userPhoneContact',
};

export const schema = object({
    [fields.userPhoneContact]: phoneNumber(),
});
