import isFunction from '@snipsonian/core/es/is/isFunction';
import isSet from '@snipsonian/core/es/is/isSet';
import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../index';
import { ITopTask, ITopTaskTargetRouteKey, TTopTaskTargetActionCreator } from '../../models/user/topTasks';
import { getPossibleTasks, getPossibleTasksIdMap, NR_OF_TOP_TASKS } from '../../config/navigation/tasks.config';
import { mayUserAccessRoute } from '../auth/selectors';
import { ICmsWebAppMessages } from '../../models/general/webAppMessages';
import { createSelector } from 'reselect';
import { isCompanyAnInterimCompany } from '../company/selected/selectors';
import ROUTE_KEYS from '../../routeKeys';
import { getRoute } from '../../routes';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getTopTasksAsyncField = (state: IState) => reducerState(state).topTasks;
const getUpdateTopTasksAsyncField = (state: IState) => reducerState(state).updateTopTasks;

export const getTopTasksAsyncInfo = makeAsyncFetchInfoSelector(getTopTasksAsyncField);

export const getTopTasksMemoized = createSelector(
    (state: IState) => getTopTasksAsyncField(state).data,
    getAllowedPossibleTopTasks,
    (topTasks, allowedTopTasks) => {
        if (topTasks === null) {
            return NO_RERENDER.EMPTY_LIST;
        }

        const userTopTaskIds = topTasks
            .filter((topTaskId) =>
                allowedTopTasks.some((allowedTopTask) => allowedTopTask.id === topTaskId),
            );

        return tryAddingTopTaskIdsUntilThereAreFour(userTopTaskIds, allowedTopTasks)
            .map((topTaskId) => getPossibleTasksIdMap()[topTaskId]);
    },
);

export const getUpdateTopTasksAsyncInfo = makeAsyncDoInfoSelector(getUpdateTopTasksAsyncField);

export function getAllowedPossibleTopTasks(state: IState): ITopTask[] {
    let targetRouteKey;
    return getPossibleTasks()
        .filter((topTask) => {
            targetRouteKey = isFunction(topTask.targetRoute) ?
                (topTask.targetRoute as TTopTaskTargetActionCreator)().type :
                (topTask.targetRoute as ITopTaskTargetRouteKey).key;

            /* The redirectToRouteIfInterim is not added within the 'mayUserAccessRoute'
               because otherwise that condition would also affect the intervention dashboard items,
               causing the medical examinations tile to not be shown anymore for interim companies. */
            return mayUserAccessRoute(state, targetRouteKey)
                && maySelectedCompanyAccessRoute(state, targetRouteKey);
        });
}

function maySelectedCompanyAccessRoute(state: IState, routeKey: ROUTE_KEYS) {
    const route = getRoute({ routeKey });
    if (isSet(route.redirectToRouteIfInterim)) {
        return !isCompanyAnInterimCompany(state);
    }
    return true;
}

function tryAddingTopTaskIdsUntilThereAreFour(userTopTaskIds: string[], allowedTopTasks: ITopTask[]): string[] {
    if (userTopTaskIds.length < NR_OF_TOP_TASKS) {
        const availableTasks = allowedTopTasks
            .filter((allowedTopTask) => !userTopTaskIds.includes(allowedTopTask.id));

        userTopTaskIds.push(
            ...availableTasks.slice(0, NR_OF_TOP_TASKS - userTopTaskIds.length).map((task) => task.id),
        );
    }

    return userTopTaskIds;
}

// CMS web app messages

const getWebAppMessagesAsyncField = (state: IState) =>
    reducerState(state).webAppMessages;

export const getWebAppMessagesAsyncInfo = makeAsyncFetchInfoSelector(getWebAppMessagesAsyncField);

export const getWebAppMessages = (state: IState) =>
    getWebAppMessagesAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT as ICmsWebAppMessages;

export const areWebAppMessagesAvailable = (state: IState) =>
    !!getWebAppMessagesAsyncField(state).data;
