import { object, date, ref } from 'yup';
import number from '../../../../../utils/libs/yup/number';

export const fields = {
    riskId: 'riskId',
    dateActive: 'dateActive',
    dateInactive: 'dateInactive',
};

export const schema = object({
    [fields.riskId]: number().required(),
    [fields.dateActive]: date().required(),
    [fields.dateInactive]: date().min(ref(fields.dateActive)),
});
