import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Medical documents overview
const getMedicalDocumentsAsyncField = (state: IState) =>
    reducerState(state).medicalDocuments;

export const getMedicalDocumentsAsyncInfo = makeAsyncFetchInfoSelector(getMedicalDocumentsAsyncField);

export const getMedicalDocuments = (state: IState) =>
    getMedicalDocumentsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areMedicalDocumentsAvailable = (state: IState) =>
    getMedicalDocumentsAsyncField(state).data !== null;

// Medical document files in bulk
const getMedicalDocumentFilesInBulkAsyncField = (state: IState) =>
    reducerState(state).medicalDocumentFilesInBulk;

export const getMedicalDocumentFilesInBulkAsyncInfo =
    makeAsyncFetchInfoSelector(getMedicalDocumentFilesInBulkAsyncField);
