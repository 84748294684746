import React, { ReactNode, PureComponent } from 'react';
import { connect } from '../../../index';
import { TTypeaheadData } from '../../../common/input/Typeahead';
import { IEmployee } from '../../../../models/admin/employee';
import { getEmployees, getEmployeesAsyncInfo } from '../../../../redux/employee/employees/selectors';
import { fetchEmployeesActions } from '../../../../redux/employee/employees/actions';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import AsyncTypeahead from '../../../common/input/Typeahead/AsyncTypeahead';
import { EMPLOYEE_SEARCH_MIN_NR_OF_CHARS } from '../../../../config/administration.config';

interface IPrivateProps {
    employees: IEmployee[];
    fetchEmployees: (filter: string, isFirstFetch: boolean) => void;
}

interface IEmployeeTypeaheadProps {
    id: string;
    value: number;
    name: string;
    onItemSelected: (value: number, employee: IEmployee) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholder: string;
    employeeIdsToExcludeFromData?: number[];
}

interface IState {
    employeeData: TTypeaheadData;
}

class EmployeeTypeahead extends PureComponent<IEmployeeTypeaheadProps & IPrivateProps, IState> {
    private isFirstFetch: boolean = true;

    constructor(props: IEmployeeTypeaheadProps & IPrivateProps) {
        super(props);

        this.state = {
            employeeData: this.mapEmployeesForTypeahead(props.employees),
        };

        this.onFilter = this.onFilter.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
    }

    public render() {
        const { id, value, name, isInvalid, children, placeholder } = this.props;
        const { employeeData } = this.state;

        return (
            <AsyncTypeahead
                id={id}
                value={value}
                name={name}
                onItemSelected={this.onItemSelected}
                isInvalid={isInvalid}
                onFilter={this.onFilter}
                asyncInfoSelector={getEmployeesAsyncInfo}
                data={employeeData}
                placeholder={placeholder}
                minCharsToTriggerSearch={EMPLOYEE_SEARCH_MIN_NR_OF_CHARS}
            >
                {children}
            </AsyncTypeahead>
        );
    }

    public componentDidMount() {
        this.updateTypeaheadData();
    }

    public componentDidUpdate(prevProps: IEmployeeTypeaheadProps & IPrivateProps) {
        if (prevProps.employees !== this.props.employees) {
            this.updateTypeaheadData();
        }
    }

    private updateTypeaheadData() {
        const { employees } = this.props;
        const typeaheadData = this.mapEmployeesForTypeahead(employees);

        this.setState({
            employeeData: typeaheadData,
        });
    }

    private onFilter(filter: string) {
        const { fetchEmployees } = this.props;
        fetchEmployees(filter, this.isFirstFetch);
        this.isFirstFetch = false;
    }

    private mapEmployeesForTypeahead(employees: IEmployee[]) {
        return Array.isArray(employees) ? employees.filter((item) => {
            const itemsToExclude = this.props.employeeIdsToExcludeFromData || [];
            return !itemsToExclude.includes(item.employeeId);
        }).map((item) => ({
            value: item.id,
            label: formatPersonName(item),
        })) : [];
    }

    private onItemSelected(selectedValue: number) {
        const employee = this.props.employees.find((item) => item.id === selectedValue);
        this.props.onItemSelected(selectedValue, employee || null);
    }
}

export default connect<IPrivateProps, IEmployeeTypeaheadProps>({
    stateProps: (state) => {
        return {
            employees: getEmployees(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            fetchEmployees: (filter, isFirstFetch) => {
                dispatch(fetchEmployeesActions.trigger({
                    maxRecordCount: 20,
                    refreshSources: isFirstFetch ? 'true' : 'false',
                    nameFilter: filter,
                    search: '',
                }));
            },
        };
    },
})(EmployeeTypeahead);
