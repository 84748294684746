import { IState, getReducerState } from '../IState';
import { IReducerState, reducerKey } from './reducer';
import { NO_RERENDER, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector } from '..';
import {
    IScreenworkQuestionnaire,
    IJobStudentQuestionnaire,
    IQuestionnaireAdvice,
} from '../../models/admin/questionnaires';
import { getRoutePayload } from '../location/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Screenwork
const getQuestionnairesScreenworkAsyncField = (state: IState) =>
    reducerState(state).screenwork;

export const getQuestionnairesScreenworkAsyncInfo = makeAsyncFetchInfoSelector(getQuestionnairesScreenworkAsyncField);

export const getQuestionnairesScreenwork = (state: IState) =>
    getQuestionnairesScreenworkAsyncField(state).data || NO_RERENDER.EMPTY_LIST as IScreenworkQuestionnaire[];

export const isQuestionnairesScreenworkAvailable = (state: IState) =>
    getQuestionnairesScreenworkAsyncField(state).data !== null;

// Screenwork detail
export const getQuestionnairesScreenworkDetail = (state: IState) => {
    const id = getRoutePayload<{ id: number }>(state).id;
    return getQuestionnairesScreenwork(state).find((item) => item.loggingId === id);
};

// JobStudents
const getQuestionnairesJobStudentsAsyncField = (state: IState) =>
    reducerState(state).jobStudents;

export const getQuestionnairesJobStudentsAsyncInfo = makeAsyncFetchInfoSelector(getQuestionnairesJobStudentsAsyncField);

export const getQuestionnairesJobStudents = (state: IState) =>
    getQuestionnairesJobStudentsAsyncField(state).data || NO_RERENDER.EMPTY_LIST as IJobStudentQuestionnaire[];

export const isQuestionnairesJobStudentsAvailable = (state: IState) =>
    getQuestionnairesJobStudentsAsyncField(state).data !== null;

// JobStudents detail
export const getQuestionnairesJobStudentsDetail = (state: IState) => {
    const id = getRoutePayload<{ id: number }>(state).id;
    return getQuestionnairesJobStudents(state).find((item) => item.id === id);
};

// Questionnaire advice
const getQuestionnairesAdviceAsyncField = (state: IState) =>
    reducerState(state).advice;

export const getQuestionnairesAdviceAsyncInfo = makeAsyncFetchInfoSelector(getQuestionnairesAdviceAsyncField);

export const getQuestionnairesAdvice = (state: IState) =>
    getQuestionnairesAdviceAsyncField(state).data || NO_RERENDER.EMPTY_LIST as IQuestionnaireAdvice[];

export const isQuestionnairesAdviceAvailable = (state: IState) =>
    getQuestionnairesAdviceAsyncField(state).data !== null;

// JobStudent reminder emails
const getSendJobStudentReminderEmailAsyncField = (state: IState) =>
    reducerState(state).sendJobStudentReminderEmail;

export const getSendJobStudentReminderEmailAsyncInfo =
    makeAsyncDoInfoSelector(getSendJobStudentReminderEmailAsyncField);

// Trigger plan job student med exam
const getTriggerPlanJobStudentMedExamAsyncField = (state: IState) =>
    reducerState(state).triggerPlanJobStudentMedExam;

export const getTriggerPlanJobStudentMedExamAsyncInfo =
    makeAsyncDoInfoSelector(getTriggerPlanJobStudentMedExamAsyncField);
