import { get } from '../../utils/api/requestWrapper';
import { IRates, IFetchCompanyRatesPayload } from '../../models/admin/rates';

export const URL = {
    COMPANY_RATES: '/companies/{companyCode}/rates',
};

export function fetchRates({ companyCode }: IFetchCompanyRatesPayload) {
    return get<IRates>({
        url: URL.COMPANY_RATES,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response['documents'],
    });
}
