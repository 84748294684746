import { FormikValues } from 'formik';
import { ValidationError } from 'yup';
import isObjectPure from '@snipsonian/core/es/is/isObjectPure';
import { IValidationError } from '../../models/general/error';
import { mapMessageToErrorType, mapFieldErrorToErrorType } from '../../views/common/forms/Form/errorTypeMapper';

interface YupErrors {
    [key: string]: IValidationError;
}

type TYupErrorsProps = {
    values: FormikValues;
    validationError: ValidationError;
    parent?: string
};

export default function convertYupErrors(props: TYupErrorsProps) {
    const errors: YupErrors = {};

    mapYupErrors(errors, props);

    return errors;
}

function mapYupErrors(errors: YupErrors, props: TYupErrorsProps) {
    const {
        values, validationError, parent,
    } = props;

    Object.keys(values).map((key) => {
        let innerKey = key;
        if (parent) {
            innerKey = `${parent}.${key}`;
        }
        const fieldError = validationError.inner.find((detail) => {
            return detail.path === innerKey;
        });
        if (fieldError) {
            errors[innerKey] = {
                type: mapMessageToErrorType(fieldError.message) || mapFieldErrorToErrorType(fieldError),
                message: fieldError.message,
            };
        }

        // When the value is an object, do convert one level deeper.
        if (isObjectPure(values[key])) {
            mapYupErrors(errors, {
                values: values[key],
                validationError,
                parent: key,
            });
        }
    });
}
