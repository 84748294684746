import React, { PureComponent } from 'react';
import { IStepperStepRenderProps } from '../../../../../../../../models/general/stepper';
import Form, { IFormRenderProps } from '../../../../../../../common/forms/Form';
import Translate from '../../../../../../../common/Translate';
import { fields, schema as fullSchema, requiredOnlySchema } from '../../common/userDataSchema';
import TextInput from '../../../../../../../common/input/TextInput';
import { getTranslatorDeprecated } from '../../../../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import FloatableTextInputWrapper from '../../../../../../../common/forms/FloatableTextInputWrapper';
import FormFieldError from '../../../../../../../common/forms/FormFieldError';
import RequiredMarker from '../../../../../../../common/input/RequiredMarker';
import { IAddUserAccountPayload, AdminType } from '../../../../../../../../models/user/userAccount';
import connect from '../../../../../../../../utils/libs/redux/connect';
import { getAddUserWizardData } from '../../../../../../../../redux/company/users/selectors';
import ConstantsTypeahead from '../../../../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../../../../models/general/constants';
import SelectUserTypeField from '../../../SelectUserTypeField';
import { TUserDataFormValues } from '../../common/userDataModels';
import PLACEHOLDER_KEYS from '../../common/placeholders';
import StickyFooter from '../../../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../../../common/navigation/Wizard/index';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../../models/general/redux';
import Loader from '../../../../../../../common/waiting/Loader';
import { tryFormattingPhoneInternational } from '../../../../../../../../utils/formatting/formatPhone';

interface IPrivateProps {
    translator: ITranslator;
    addUserWizardData: Partial<IAddUserAccountPayload>;
}

interface IPublicProps {
    onlyShowRequiredFields?: boolean;
    show: boolean;
    handleSubmit: (values: TUserDataFormValues) => void;
    asyncInfoLoader?: IAsyncFieldInfo;
}

const FORM_NAME = 'add-user-account-personal-data-form';

class PersonalDataForm extends PureComponent<IStepperStepRenderProps & IPrivateProps & IPublicProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps & IPublicProps) {
        super(props);
    }

    public render() {
        const {
            addUserWizardData, translator, renderStepButtons, onlyShowRequiredFields, asyncInfoLoader, show,
        } = this.props;

        if (!addUserWizardData || !show) {
            return null;
        }

        const INITIAL_VALUES: TUserDataFormValues = {
            firstName: addUserWizardData.firstName,
            name: addUserWizardData.name,
            titleId: addUserWizardData.titleId,
            sexId: addUserWizardData.sexId,
            email: addUserWizardData.email,
            languageId: addUserWizardData.languageId,
            mobilePhoneNumber: tryFormattingPhoneInternational(addUserWizardData.mobilePhoneNumber),
            phoneNumber: tryFormattingPhoneInternational(addUserWizardData.phoneNumber),
            admin: addUserWizardData.admin || AdminType.None,
        } as TUserDataFormValues;

        return (
            <>
                { asyncInfoLoader &&
                    (
                        <Loader
                            show={asyncInfoLoader.status === AsyncStatus.Busy}
                        />
                    )
                }
                <Form
                    name={FORM_NAME}
                    initialValues={INITIAL_VALUES}
                    handleSubmit={this.props.handleSubmit}
                    schema={onlyShowRequiredFields ? requiredOnlySchema : fullSchema}
                    render={(renderProps: IFormRenderProps<TUserDataFormValues>) => {
                        const {
                            values, handleChange, errors, touched, setFieldValue,
                        } = renderProps;
                        return (
                            <>
                                <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                    <FloatableTextInputWrapper floatLabel hide={onlyShowRequiredFields}>
                                        <ConstantsTypeahead
                                            id="add-user-account-salutation"
                                            name={fields.titleId}
                                            value={values.titleId}
                                            constantType={ConstantType.TITLES}
                                            onItemSelected={(value) => setFieldValue('titleId', value)}
                                            isInvalid={touched.titleId &&
                                                !!errors.titleId}
                                        >
                                            <label htmlFor="add-user-account-salutation">
                                                <Translate
                                                    msg={PLACEHOLDER_KEYS.titleId}
                                                />
                                                <RequiredMarker />
                                            </label>
                                        </ConstantsTypeahead>
                                        {touched.titleId &&
                                            <FormFieldError
                                                error={errors.titleId}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.titleId),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-user-account-first-name"
                                            name={fields.firstName}
                                            value={values.firstName || ''}
                                            onChange={handleChange}
                                            isInvalid={touched.firstName &&
                                                !!errors.firstName}
                                        />
                                        <label htmlFor="add-user-account-first-name">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.firstName}
                                            />
                                            <RequiredMarker />
                                        </label>
                                        {touched.firstName &&
                                            <FormFieldError
                                                error={errors.firstName}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.firstName),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-user-account-name"
                                            name={fields.name}
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            isInvalid={touched.name &&
                                                !!errors.name}
                                        />
                                        <label htmlFor="add-user-account-name">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.name}
                                            />
                                            <RequiredMarker />
                                        </label>
                                        {touched.name &&
                                            <FormFieldError
                                                error={errors.name}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.name),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <ConstantsTypeahead
                                            id="add-user-account-sexid"
                                            name={fields.sexId}
                                            value={values.sexId}
                                            constantType={ConstantType.SEXES}
                                            onItemSelected={(value) => setFieldValue('sexId', Number(value))}
                                            isInvalid={touched.sexId &&
                                                !!errors.sexId}
                                        >
                                            <label htmlFor="add-user-account-sexid">
                                                <Translate
                                                    msg={PLACEHOLDER_KEYS.sexId}
                                                />
                                                <RequiredMarker />
                                            </label>
                                        </ConstantsTypeahead>
                                        {touched.sexId &&
                                            <FormFieldError
                                                error={errors.sexId}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.sexId),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <ConstantsTypeahead
                                            id="add-user-account-language"
                                            name={fields.languageId}
                                            value={values.languageId}
                                            constantType={ConstantType.LANGUAGES}
                                            onItemSelected={(value) => setFieldValue('languageId', value)}
                                            isInvalid={touched.languageId &&
                                                !!errors.languageId}
                                        >
                                            <label htmlFor="add-user-account-language">
                                                <Translate
                                                    msg={PLACEHOLDER_KEYS.languageId}
                                                />
                                                <RequiredMarker />
                                            </label>
                                        </ConstantsTypeahead>
                                        {touched.languageId &&
                                            <FormFieldError
                                                error={errors.languageId}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.languageId),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-user-account-email"
                                            name={fields.email}
                                            value={values.email || ''}
                                            onChange={handleChange}
                                            isInvalid={touched.email &&
                                                !!errors.email}
                                        />
                                        <label htmlFor="add-user-account-email">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.email}
                                            />
                                            <RequiredMarker />
                                        </label>
                                        {touched.email &&
                                            <FormFieldError
                                                error={errors.email}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.email),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel hide={onlyShowRequiredFields}>
                                        <TextInput
                                            id="add-user-account-phone"
                                            name={fields.phoneNumber}
                                            value={values.phoneNumber || ''}
                                            onChange={handleChange}
                                            isInvalid={touched.phoneNumber &&
                                                !!errors.phoneNumber}
                                        />
                                        <label htmlFor="add-user-account-phone">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.phoneNumber}
                                            />
                                        </label>
                                        {touched.phoneNumber &&
                                            <FormFieldError
                                                error={errors.phoneNumber}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.phoneNumber),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel hide={onlyShowRequiredFields}>
                                        <TextInput
                                            id="add-user-account-mobile-phone"
                                            name={fields.mobilePhoneNumber}
                                            value={values.mobilePhoneNumber || ''}
                                            onChange={handleChange}
                                            isInvalid={touched.mobilePhoneNumber &&
                                                !!errors.mobilePhoneNumber}
                                        />
                                        <label htmlFor="add-user-account-mobile-phone">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.mobilePhoneNumber}
                                            />
                                        </label>
                                        {touched.mobilePhoneNumber &&
                                            <FormFieldError
                                                error={errors.mobilePhoneNumber}
                                                placeholders={{
                                                    fieldName: translator(
                                                        PLACEHOLDER_KEYS.mobilePhoneNumber),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    {!onlyShowRequiredFields &&
                                        (
                                            <SelectUserTypeField {...renderProps} />
                                        )
                                    }
                                </div>
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {renderStepButtons({
                                        nextButton: {
                                            isSubmit: true,
                                            formName: FORM_NAME,
                                        },
                                    })}
                                </StickyFooter>
                            </>
                        );
                    }}
                />
            </>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            addUserWizardData: getAddUserWizardData(state),
        };
    },
})(PersonalDataForm);
