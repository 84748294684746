import React from 'react';

import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../../models/general/list';
import { now, yearOffsetFromNow } from '../../../../utils/core/date/getSpecificDate';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import Translate from '../../../common/Translate';
import { VACCINATIONS_TRANS_PREFIX } from '../vaccines.const';

import {
    IAdministeredVaccinationsListColumnNames,
} from './AdministeredVaccinationsList/AdministeredVaccinationList.types';


export const BASE_NAME = 'administered-vaccinations';
export const CLASS_NAME = 'administered-vaccinations';

export const TRANSLATION_PREFIX = `${VACCINATIONS_TRANS_PREFIX}.administered`;
export const DEFAULT_NR_OF_VACCINES_TO_SHOW = 20;

export const INITIAL_SORT: ISortedColumn<IAdministeredVaccinationsListColumnNames> = {
    name: 'dateOfAdministration',
    sortOrder: SortOrder.Descending,
};

export const DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-1)),
    endDate: formatDateForBackend(now()),
};

export const COLUMNS: ListColumns<IAdministeredVaccinationsListColumnNames> = {
    name: {
        label: <Translate msg="interventions.vaccinations.administered.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 18,
    },
    employer: {
        label: <Translate msg="interventions.vaccinations.administered.columns.employer" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 24,
    },
    dateOfAdministration: {
        label: <Translate msg="interventions.vaccinations.administered.columns.date_of_administration" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IAdministeredVaccinationsListColumnNames>) => (
            listItem.columns.dateOfAdministrationSort
        ),
        percentWidth: 18,
    },
    dateOfAdministrationSort: {
        hide: true,
        sortType: SortType.String,
        percentWidth: null,
    },
    description: {
        label: <Translate msg="interventions.vaccinations.administered.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    injectionDescription: {
        label: <Translate msg="interventions.vaccinations.administered.columns.step" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
};
