const isGroupingSupported = console.group;

export function startLog(groupTitle: string) {
    isGroupingSupported ? console.groupCollapsed(groupTitle) : console.log(groupTitle);
}

export function endLog() {
    if (isGroupingSupported) {
        console.groupEnd();
    }
}
