import { get } from '../../utils/api/requestWrapper';

import { IThemeOfTheMonth, ICourseLink } from '../../models/general/cms';

export const URL = {
    THEME_OF_THE_MONTH: '/theme',
    COURSE_LINKS: '/course-links',
};

export function fetchThemeOfTheMonth() {
    return get<IThemeOfTheMonth>({
        url: URL.THEME_OF_THE_MONTH,
        mapResponse: (response) => response.config,
    });
}

export function fetchCourseLinks() {
    return get<ICourseLink[]>({
        url: URL.COURSE_LINKS,
        mapResponse: (response) => response.links,
    });
}
