import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './validate.scss';
import {
    calculateTotalVaccinesPrice,
    getTravelCost,
    calculateTotalVaccinesOrderPrice,
} from '../../fluVaccinesOrderPriceCalculator';
import {
    getDeliveryOptionByLocationId,
    IDeliveryOption,
} from '../../../../../config/intervention.config';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    ICreateFluVaccinesOrder,
    IFluVaccinesOrderWizardEntity,
} from '../../../../../models/interventions/fluVaccines';
import {
    getFluVaccinesOrderWizardEntity,
    getFluVaccineTariffs,
    getCreateFluVaccinesOrderAsyncInfo,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import { createFluVaccinesOrderActions } from '../../../../../redux/intervention/fluVaccine/actions';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';
import { connect } from '../../../../index';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import Translate from '../../../../common/Translate';
import LabelInfo from '../../../../common/widget/LabelInfo';
import ResultAfterDoAction from '../../../../common/widget/ResultAfterDoAction';
import ValidateInfo from '../../../../common/widget/ValidateInfo';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { formatCompanyContact } from '../../../../../utils/formatting/formatContact';
import { formatPrice } from '../../../../../utils/formatting/formatPrice';

const CLASS_NAME = 'OrderFluVaccine__Validate';
const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    wizardEntity: IFluVaccinesOrderWizardEntity;
    deliveryOption: IDeliveryOption;
    totalVaccinesPrice: number;
    travelCost: number;
    totalVaccinesOrderPrice: number;
    onCreateOrder: () => void;
    resetCreateAction: () => void;
    navigateToAfterSuccessRoute: () => void;
}

class Validate extends PureComponent<IStepperStepRenderProps & IPrivateProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.closeCreationSuccessDialog = this.closeCreationSuccessDialog.bind(this);
        this.closeCreationErrorDialog = this.closeCreationErrorDialog.bind(this);
    }

    public render() {
        const {
            renderStepButtons,
            wizardEntity,
            deliveryOption,
            totalVaccinesPrice,
            travelCost,
            totalVaccinesOrderPrice,
            onCreateOrder,
        } = this.props;

        const DeliveryOptionInfo = <Translate raw msg={deliveryOption.label}/>;

        return (
            <>
                <PageHeader
                    title="interventions.flu_orders_new.steps.validate.title"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <ResultAfterDoAction
                        doActionAsyncInfoSelector={getCreateFluVaccinesOrderAsyncInfo}
                        successConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.flu_orders_new.steps.validate.create_result.success.title',
                            onConfirm: this.closeCreationSuccessDialog,
                        }}
                        errorConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.flu_orders_new.steps.validate.create_result.error.title',
                            onConfirm: this.closeCreationErrorDialog,
                        }}
                    >
                        <ValidateInfo>
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.amount_to_order"
                                info={wizardEntity.amountVaccines}
                            />
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.seat"
                                info={wizardEntity.seat.company.name}
                            />
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.delivery"
                                info={DeliveryOptionInfo}
                            />
                            {deliveryOption.extraFields.companyAddress &&
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.address"
                                info={formatAddress(wizardEntity.delivery.companyAddress)}
                                isInfoMultiLine={true}
                            />
                            }
                            {deliveryOption.extraFields.medicalCenter &&
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.medical_center"
                                info={wizardEntity.delivery.medicalCenter.name}
                            />
                            }
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.contact"
                                info={formatCompanyContact(wizardEntity.contact)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.referention_code"
                                info={wizardEntity.remarks.referentionCode || EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.remark"
                                info={wizardEntity.remarks.remark || EMPTY_PLACEHOLDER}
                            />

                            <h4>
                                <Translate
                                    msg="interventions.flu_orders_new.steps.validate.sub_title.price"
                                />
                            </h4>
                            {deliveryOption.isTravelCostCharged &&
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.price_vaccines"
                                info={formatPrice(totalVaccinesPrice)}
                            />
                            }
                            {deliveryOption.isTravelCostCharged &&
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.travel_cost"
                                info={formatPrice(travelCost)}
                            />
                            }
                            <LabelInfo
                                labelKey="interventions.flu_orders_new.steps.validate.fields.order_price"
                                info={formatPrice(totalVaccinesOrderPrice)}
                                className="LabelInfo--order_price"
                            />
                        </ValidateInfo>
                        <div className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    translationKey: 'interventions.flu_orders_new.steps.validate.finish_button',
                                    onClick: onCreateOrder,
                                },
                            })}
                        </div>
                    </ResultAfterDoAction>
                </div>
            </>
        );
    }

    private closeCreationSuccessDialog() {
        this.props.resetCreateAction();
        this.props.navigateToAfterSuccessRoute();
    }

    private closeCreationErrorDialog() {
        this.props.resetCreateAction();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getFluVaccinesOrderWizardEntity(state);
        const tariffs = getFluVaccineTariffs(state);

        return {
            wizardEntity: wizardEntity as IFluVaccinesOrderWizardEntity,
            deliveryOption: getDeliveryOptionByLocationId(wizardEntity.delivery.vaccinationLocationId),
            totalVaccinesPrice: calculateTotalVaccinesPrice(wizardEntity.amountVaccines, tariffs),
            travelCost: getTravelCost(tariffs, wizardEntity.delivery.vaccinationLocationId),
            totalVaccinesOrderPrice: calculateTotalVaccinesOrderPrice(
                wizardEntity.amountVaccines,
                tariffs,
                wizardEntity.delivery.vaccinationLocationId,
            ),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onCreateOrder: () => {
                const wizardEntity = getFluVaccinesOrderWizardEntity(getState()) as IFluVaccinesOrderWizardEntity;

                dispatch(createFluVaccinesOrderActions.trigger(
                    mapWizardEntityToCreatePayload(wizardEntity),
                ));
            },
            resetCreateAction: () => {
                dispatch(createFluVaccinesOrderActions.reset({}));
            },
            navigateToAfterSuccessRoute: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
            },
        };
    },
})(Validate);

function mapWizardEntityToCreatePayload(wizardEntity: IFluVaccinesOrderWizardEntity): ICreateFluVaccinesOrder {
    return {
        address: mapOptionalCompanyAddress(wizardEntity),
        amountVaccinesOrdered: wizardEntity.amountVaccines,
        company: {
            companyCode: wizardEntity.seat.company.companyCode,
        },
        contact: {
            id: wizardEntity.contact.customerContactId,
        },
        locationId: wizardEntity.delivery.vaccinationLocationId,
        medicalCenter: mapOptionalMedicalCenter(wizardEntity),
        referentionCode: wizardEntity.remarks.referentionCode,
        remark: wizardEntity.remarks.remark,
    };
}

function mapOptionalCompanyAddress(wizardEntity: IFluVaccinesOrderWizardEntity) {
    if (wizardEntity.delivery && wizardEntity.delivery.companyAddress) {
        return {
            id: wizardEntity.delivery.companyAddress.id,
        };
    }

    return null;
}

function mapOptionalMedicalCenter(wizardEntity: IFluVaccinesOrderWizardEntity) {
    if (wizardEntity.delivery && wizardEntity.delivery.medicalCenter) {
        return {
            id: wizardEntity.delivery.medicalCenter.id,
        };
    }

    return null;
}
