import React, { PureComponent } from 'react';
import PageHeader from '../../../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../../../models/general/stepper';
import classNames from 'classnames';
import { IAddUserAccountPayload, AdminType } from '../../../../../../../../models/user/userAccount';
import connect from '../../../../../../../../utils/libs/redux/connect';
import { getAddUserWizardData } from '../../../../../../../../redux/company/users/selectors';
import { setAddCompanyUserWizardData } from '../../../../../../../../redux/company/users/actions';
import Form, { IFormRenderProps } from '../../../../../../../common/forms/Form';
import { formatPersonName } from '../../../../../../../../utils/formatting/formatPerson';
import {
    IPerson, IEmployee,
    IEmployeeDetails, TEmployeeUpdateFields,
} from '../../../../../../../../models/admin/employee';
import EmployeeTypeahead from '../../../../../../../administration/employee/EmployeeTypeahead';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../../../../redux/i18n/selectors';
import SelectUserTypeField from '../../../SelectUserTypeField';
import { fields, requiredOnlySchema } from '../../common/userDataSchema';
import FormFieldError from '../../../../../../../common/forms/FormFieldError';
import { ErrorTypes, ITraceableApiError } from '../../../../../../../../models/general/error';
import Translate from '../../../../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../../../../common/forms/FloatableTextInputWrapper';
import ConstantsTypeahead from '../../../../../../../common/input/ConstantsTypeahead';
import RequiredMarker from '../../../../../../../common/input/RequiredMarker';
import { ConstantType } from '../../../../../../../../models/general/constants';
import { TUserDataFormValues } from '../../common/userDataModels';
import PLACEHOLDER_KEYS from '../../common/placeholders';
import { fetchSmallEmployeeDetails, updateEmployee } from '../../../../../../../../redux/employee/info/actions';
import {
    getSelectedEmployeeAsyncInfo,
    getSelectedEmployee,
    getUpdateEmployeeAsyncInfo,
} from '../../../../../../../../redux/employee/info/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../../models/general/redux';
import Loader from '../../../../../../../common/waiting/Loader';
import ErrorPlaceholder from '../../../../../../../common/error/ErrorPlaceholder';
import StickyFooter from '../../../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../../../common/navigation/Wizard/index';
import Dialog from '../../../../../../../common/modals/Dialog';
import PersonalDataForm from '../PersonalDataForm';
import FormError from '../../../../../../../common/forms/FormError';
import { clearErrors } from '../../../../../../../../utils/libs/redux/generic/actions';

interface IFormValues extends TUserDataFormValues {
    selectedEmployee: IEmployee;
}

interface IComponentState {
    isEmployeeDetailsDialogOpen: boolean;
}

interface IPrivateProps {
    addUserWizardData: Partial<IAddUserAccountPayload>;
    setAddUserWizardData: (values: IFormValues) => void;
    translator: ITranslator;
    fetchEmployeeDetails: (id: number) => void;
    updateEmployeeAsyncInfo: IAsyncFieldInfo;
    selectedEmployeeDetailsAsyncInfo: IAsyncFieldInfo;
    selectedEmployeeDetails: IEmployeeDetails;
    updateEmployee: (id: number, employeeData: Partial<TEmployeeUpdateFields>) => void;
    clearError: (error: ITraceableApiError) => void;
}

const FORM_NAME = 'add-user-account-select-employee-form';
const UPDATE_EMPLOYEE_REQUEST_ID = 'updateEmployee';
const EMPLOYEE_PLACEHOLDER_KEY =
    'account.account_settings.manage_users.add.wizard.steps.select_employee.employee_placeholder';

class SelectEmployee extends PureComponent<IStepperStepRenderProps & IPrivateProps, IComponentState> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isEmployeeDetailsDialogOpen: false,
        };

        this.onChangeEmployeeDetails = this.onChangeEmployeeDetails.bind(this);
        this.onCloseEmployeeDetailsDialog = this.onCloseEmployeeDetailsDialog.bind(this);
        this.onEmployeeDetailsChanged = this.onEmployeeDetailsChanged.bind(this);
    }

    private setFieldValue: (
        field: keyof IFormValues,
        value: string | number | object,
        shouldValidate?: boolean | undefined,
    ) => void;

    public componentDidUpdate(prevProps: IStepperStepRenderProps & IPrivateProps) {
        const {
            selectedEmployeeDetailsAsyncInfo, updateEmployeeAsyncInfo,
            selectedEmployeeDetails, addUserWizardData,
         } = this.props;

        if (
            prevProps.selectedEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy &&
            selectedEmployeeDetailsAsyncInfo.status === AsyncStatus.Success
        ) {
            const { languageId, phone, mobilePhone, email, sexId } = selectedEmployeeDetails;
            this.setFieldValue('languageId', languageId);
            this.setFieldValue('mobilePhoneNumber', mobilePhone);
            this.setFieldValue('phoneNumber', phone);
            this.setFieldValue('email', email);
            this.setFieldValue('sexId', sexId);
        }
         // Goes from initial to busy => busy to initial (never goes to a success state)
        // This is because it uses a request id in the redux state instead of the regular flow
        if (updateEmployeeAsyncInfo &&
            updateEmployeeAsyncInfo.status === AsyncStatus.Initial &&
            prevProps.updateEmployeeAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.setState({
                isEmployeeDetailsDialogOpen: false,
            });

            this.setFieldValue('firstName', addUserWizardData.languageId);
            this.setFieldValue('languageId', addUserWizardData.languageId);
            this.setFieldValue('email', addUserWizardData.email);
            this.setFieldValue('sexId', addUserWizardData.sexId);
            this.setFieldValue('name', addUserWizardData.name);
            this.setFieldValue('firstName', addUserWizardData.firstName);
        }
    }

    public render() {
        const {
            isEmployeeDetailsDialogOpen,
        } = this.state;

        const {
            goToNextStep, addUserWizardData,
            renderStepButtons, setAddUserWizardData,
            translator, selectedEmployeeDetailsAsyncInfo,
            fetchEmployeeDetails, updateEmployeeAsyncInfo,
        } = this.props;

        const INITIAL_VALUES: IFormValues = addUserWizardData ? {
            firstName: addUserWizardData.firstName,
            name: addUserWizardData.name,
            titleId: addUserWizardData.titleId,
            email: addUserWizardData.email,
            languageId: addUserWizardData.languageId,
            mobilePhoneNumber: addUserWizardData.mobilePhoneNumber,
            phoneNumber: addUserWizardData.phoneNumber,
            admin: addUserWizardData.admin || AdminType.None,
            selectedEmployee: addUserWizardData.selectedEmployee || null,
            sexId: addUserWizardData.sexId,
        } : {} as IFormValues;

        const isFetchingDetails = selectedEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy;

        function handleSubmit(values: IFormValues) {
            setAddUserWizardData(values);
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title="account.account_settings.manage_users.add.wizard.steps.select_employee.title"
                    titlePlaceholders={{
                        name: formatPersonName(addUserWizardData as IPerson),
                    }}
                    text="account.account_settings.manage_users.add.wizard.steps.select_employee.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        initialValues={INITIAL_VALUES}
                        handleSubmit={handleSubmit}
                        schema={requiredOnlySchema}
                        render={(renderProps: IFormRenderProps<IFormValues>) => {
                            const { errors, values, setFieldValue, touched } = renderProps;

                            if (this.setFieldValue !== setFieldValue) {
                                this.setFieldValue = setFieldValue;
                            }

                            const employeeSelectErrors = {
                                ...errors,
                            };
                            delete employeeSelectErrors.admin;
                            delete employeeSelectErrors.titleId;

                            const employeeIsMissingData = values.selectedEmployee
                                && Object.keys(employeeSelectErrors).length > 0;

                            return (
                                <>
                                    <Loader show={isFetchingDetails} />
                                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                        <FloatableTextInputWrapper floatLabel>
                                            <EmployeeTypeahead
                                                id="add-user-account-select-employee"
                                                name="employee"
                                                value={values.selectedEmployee && values.selectedEmployee.id}
                                                onItemSelected={(employeeId, employee) => {
                                                    if (!employee) {
                                                        setFieldValue('languageId', INITIAL_VALUES.languageId);
                                                        setFieldValue(
                                                            'mobilePhoneNumber',
                                                            INITIAL_VALUES.mobilePhoneNumber,
                                                        );
                                                        setFieldValue('phoneNumber', INITIAL_VALUES.phoneNumber);
                                                        setFieldValue('email', INITIAL_VALUES.email);
                                                        setFieldValue('sexId', INITIAL_VALUES.sexId);
                                                    } else {
                                                        const {
                                                            name, firstName, id,
                                                        } = employee;
                                                        setFieldValue('name', name);
                                                        setFieldValue('firstName', firstName);

                                                        if (
                                                            !values.selectedEmployee ||
                                                            id !== values.selectedEmployee.id
                                                        ) {
                                                            setFieldValue('languageId', INITIAL_VALUES.languageId);
                                                            setFieldValue(
                                                                'mobilePhoneNumber',
                                                                INITIAL_VALUES.mobilePhoneNumber,
                                                            );
                                                            setFieldValue('phoneNumber', INITIAL_VALUES.phoneNumber);
                                                            setFieldValue('email', INITIAL_VALUES.email);
                                                            setFieldValue('sexId', INITIAL_VALUES.sexId);
                                                            fetchEmployeeDetails(id);
                                                        }
                                                    }

                                                    setFieldValue('selectedEmployee', employee);
                                                }}
                                                placeholder={
                                                    translator(EMPLOYEE_PLACEHOLDER_KEY)
                                                }
                                                isInvalid={
                                                    touched.selectedEmployee &&
                                                    (!values.selectedEmployee || employeeIsMissingData)
                                                }
                                            >
                                                <label htmlFor="add-user-account-select-employee">
                                                    <Translate
                                                        msg={EMPLOYEE_PLACEHOLDER_KEY}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                            </EmployeeTypeahead>
                                        </FloatableTextInputWrapper>
                                        {touched.selectedEmployee && !values.selectedEmployee && <FormFieldError
                                            error={{
                                                // eslint-disable-next-line max-len
                                                message: 'account.account_settings.manage_users.add.wizard.steps.select_employee.employee_required',
                                                type: ErrorTypes.Custom,
                                            }}
                                        />}
                                        {touched.selectedEmployee && employeeIsMissingData &&
                                            <FormFieldError
                                                error={{
                                                    // eslint-disable-next-line max-len
                                                    message: 'account.account_settings.manage_users.add.wizard.steps.select_employee.employee_missing_data',
                                                    type: ErrorTypes.Custom,
                                                }}
                                                placeholders={{
                                                    detailsLink: (
                                                        <a
                                                            onClick={() => this.onChangeEmployeeDetails(values)}
                                                            id="add-user-account-employee-details"
                                                        >
                                                            <Translate
                                                                // eslint-disable-next-line max-len
                                                                msg="account.account_settings.manage_users.add.wizard.steps.select_employee.employee_missing_data_link_label"
                                                            />
                                                        </a>
                                                    ),
                                                }}
                                            />
                                        }
                                        {selectedEmployeeDetailsAsyncInfo.error &&
                                            <ErrorPlaceholder apiError={selectedEmployeeDetailsAsyncInfo.error} />
                                        }
                                        <FloatableTextInputWrapper floatLabel>
                                            <ConstantsTypeahead
                                                id="add-user-account-salutation"
                                                name={fields.titleId}
                                                value={values.titleId}
                                                constantType={ConstantType.TITLES}
                                                onItemSelected={(value) => setFieldValue('titleId', value)}
                                                isInvalid={touched.titleId &&
                                                    !!errors.titleId}
                                            >
                                                <label htmlFor="add-user-account-salutation">
                                                    <Translate
                                                        msg={PLACEHOLDER_KEYS.titleId}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                            </ConstantsTypeahead>
                                            {touched.titleId &&
                                                <FormFieldError
                                                    error={errors.titleId}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            PLACEHOLDER_KEYS.titleId),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <SelectUserTypeField {...renderProps} />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                disabled: isFetchingDetails,
                                                formName: FORM_NAME,
                                            },
                                        })}
                                    </StickyFooter>
                                    <Dialog
                                        show={isEmployeeDetailsDialogOpen}
                                        onCloseIntent={this.onCloseEmployeeDetailsDialog}
                                    >
                                        <PersonalDataForm
                                            handleSubmit={this.onEmployeeDetailsChanged}
                                            onlyShowRequiredFields={true}
                                            show={isEmployeeDetailsDialogOpen}
                                            asyncInfoLoader={updateEmployeeAsyncInfo}
                                            {...this.props}
                                        />
                                        {updateEmployeeAsyncInfo.error &&
                                            <FormError error={updateEmployeeAsyncInfo.error} />
                                        }
                                    </Dialog>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }

    private onChangeEmployeeDetails(values: IFormValues) {
        this.props.setAddUserWizardData(values);

        this.setState({
            isEmployeeDetailsDialogOpen: true,
        });
    }

    private onCloseEmployeeDetailsDialog() {
        this.setState({
            isEmployeeDetailsDialogOpen: false,
        });

        const { clearError, updateEmployeeAsyncInfo } = this.props;
        clearError(updateEmployeeAsyncInfo.error);
    }

    private onEmployeeDetailsChanged(values: IFormValues) {
        const {
            languageId, sexId, email, firstName, name,
        } = values;

        this.props.setAddUserWizardData(values);

        this.props.updateEmployee(this.props.addUserWizardData.selectedEmployee.id, {
            ...values.selectedEmployee as Partial<TEmployeeUpdateFields>,
            languageId,
            sexId,
            email,
            firstName,
            name,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const addUserWizardData = getAddUserWizardData(state);

        return {
            updateEmployeeAsyncInfo: getUpdateEmployeeAsyncInfo(state, UPDATE_EMPLOYEE_REQUEST_ID),
            addUserWizardData,
            translator: getTranslatorDeprecated(state),
            selectedEmployeeDetailsAsyncInfo: getSelectedEmployeeAsyncInfo(state),
            selectedEmployeeDetails: getSelectedEmployee(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            setAddUserWizardData: (values) => dispatch(setAddCompanyUserWizardData(values)),
            fetchEmployeeDetails: (id: number) => dispatch(fetchSmallEmployeeDetails({ id })),
            updateEmployee: (id: number, employeeData: Partial<TEmployeeUpdateFields>) => {
                dispatch(updateEmployee({
                    requestId: UPDATE_EMPLOYEE_REQUEST_ID,
                    id,
                    employeeData,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(SelectEmployee);
