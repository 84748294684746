import { object } from 'yup';
import time from '../../../../../../utils/libs/yup/time';

export const fields = {
    startTime: 'startTime',
    endTime: 'endTime',
};

export const schema = object({
    [fields.startTime]: time().required(),
    [fields.endTime]: time().required(),
});
