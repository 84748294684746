import React, { ReactElement, ReactNode, PureComponent } from 'react';
import './detail-panel.scss';
import { connect } from '../../../../index';
import SlideOutPanel from '../../SlideOutPanel';
import Loader from '../../../waiting/Loader';
import { IAsyncFieldInfo, IAction } from '../../../../../models/general/redux';
import { IState } from '../../../../../redux/IState';
import {
    IRenderDetailHeaderProps,
    IRenderDetailFooterProps,
    IRenderDetailContentProps,
    IRenderDetailOverlayProps,
} from '../typings';
import DetailPanelHeader from './DetailPanelHeader';
import DetailPanelFooter from './DetailPanelFooter';
import classNames from 'classnames';

interface IPublicProps {
    isOpen: boolean;
    onCloseIntent: (isClickedOutside: boolean) => void;
    detailAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    detailUpdateInfo?: {
        updateAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
        updateAsyncInfoResetAction: IAction<{}>;
        successTranslationKey?: string;
    };
    detailIdSelector: (state: IState) => string | number;
    detailDataSelector: (state: IState) => object;
    renderHeader: (props: IRenderDetailHeaderProps) => ReactElement<{ children: ReactNode }>;
    renderFooter?: (props: IRenderDetailFooterProps) => ReactElement<{ children: ReactNode }>;
    renderContent: (props: IRenderDetailContentProps) => React.ReactElement<{}>;
    renderOverlay?: (props: IRenderDetailOverlayProps) => ReactElement<{ children: ReactNode }>;
    onSwitchSelectedDetail?: () => void;
    doNotRenderInSlideOutPanel?: boolean;
    slideOutPanelClassName?: string;
    isForm?: boolean;
}

interface IPrivateProps {
    detailAsyncInfo: IAsyncFieldInfo;
    detailUpdateAsyncInfo: IAsyncFieldInfo;
    detailData: object;
    id: string | number;
    resetUpdateAsyncInfoField: () => void;
}

interface IComponentState {
    isOverlayOpen: boolean;
    overlayType: string | number;
    formValues: object;
}

export const CLASS_NAME = 'DetailPanel';

class DetailPanel extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    private onOverlaySave: (data: object) => void = null;

    constructor(props) {
        super(props);

        this.state = DetailPanel.getInitialState();
        this.closeOverlay = this.closeOverlay.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(DetailPanel.getInitialState());
            this.props.resetUpdateAsyncInfoField();
            if (this.props.onSwitchSelectedDetail) {
                this.props.onSwitchSelectedDetail();
            }
        }
    }

    private static getInitialState(): IComponentState {
        return {
            isOverlayOpen: false,
            overlayType: null,
            formValues: null,
        };
    }

    private closeOverlay() {
        this.setState({
            isOverlayOpen: false,
        });
    }

    public render() {
        const {
            isOpen, onCloseIntent, renderHeader,
            doNotRenderInSlideOutPanel, slideOutPanelClassName,
        } = this.props;
        const { renderOverlay } = this.props;
        const { isOverlayOpen, overlayType, formValues } = this.state;

        if (doNotRenderInSlideOutPanel) {
            return isOpen ? this.renderDetailContent() : null;
        }

        return (
            <SlideOutPanel
                isOpen={isOpen}
                onCloseIntent={onCloseIntent}
                header={renderHeader ? <DetailPanelHeader {...this.props} /> : undefined}
                footer={<DetailPanelFooter {...this.props} />}
                showOverlay={isOverlayOpen}
                className={slideOutPanelClassName}
                overlay={renderOverlay ? renderOverlay({
                    overlayType,
                    closeOverlay: this.closeOverlay,
                    formValues,
                    onSave: (data) => {
                        if (typeof this.onOverlaySave === 'function') {
                            this.onOverlaySave(data);
                        }
                    },
                }) : null}
            >
                {this.renderDetailContent()}
            </SlideOutPanel>
        );
    }

    private renderDetailContent() {
        const { detailAsyncInfo, renderContent, detailData, detailUpdateAsyncInfo, onCloseIntent } = this.props;

        const detailPanelClasses = classNames(CLASS_NAME, {
            ['no-slide-out-panel']: this.props.doNotRenderInSlideOutPanel,
            [`${CLASS_NAME}--form`]: this.props.isForm,
        });

        return (
            <section className={detailPanelClasses}>
                <Loader show={detailAsyncInfo ? detailAsyncInfo.status : false}>
                    {renderContent({
                        detailAsyncInfo,
                        detailData,
                        detailUpdateAsyncInfo,
                        onOpenOverlay: (overlayType, onSave, formValues) => {
                            this.onOverlaySave = onSave;
                            this.setState({
                                isOverlayOpen: true,
                                overlayType,
                                formValues,
                            });
                        },
                        onCloseIntent,
                    })}
                </Loader>
            </section>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const detailAsyncInfo = publicProps.detailAsyncInfoSelector(state);
            const detailUpdateAsyncInfo =
                publicProps.detailUpdateInfo && publicProps.detailUpdateInfo.updateAsyncInfoSelector(state);

            return {
                detailAsyncInfo,
                id: publicProps.detailIdSelector(state),
                detailData: publicProps.detailDataSelector(state),
                detailUpdateAsyncInfo,
            };
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                resetUpdateAsyncInfoField: () => {
                    if (publicProps.detailUpdateInfo) {
                        dispatch(publicProps.detailUpdateInfo.updateAsyncInfoResetAction);
                    }
                },
            };
        };
    },
})(DetailPanel);
