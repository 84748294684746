import { REDUCER_STORAGE_TYPE, STATE_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { isStateStorageEnabled } from '../../../config/develop.config';

export function keepStateStorageTypeIfNotDisabled(storageType: STATE_STORAGE_TYPE): STATE_STORAGE_TYPE {
    return isStateStorageEnabled ? storageType : STATE_STORAGE_TYPE.NO_STORAGE;
}

export function keepReducerStorageTypeIfNotDisabled(storageType: REDUCER_STORAGE_TYPE): REDUCER_STORAGE_TYPE {
    return isStateStorageEnabled ? storageType : REDUCER_STORAGE_TYPE.NO_STORAGE;
}
