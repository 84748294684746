import { Omit } from '../../utils/types/omit';
import { TCompanyStatisticalCode } from '../../config/company/companyInfo.config';
import { IAddress, IAddressSave } from '../general/address';
import { ITraceableAsyncRequestPayload } from '../general/redux';

import { ICompany, ICompanyCodePayload } from './company';
import { IMedicalCenter, IPerson } from './employee';

export interface ICompanyDetail {
    name: string;
    companyCode: string;
    billingAddress: IAddress;
    address: IAddress;
    naceCode: string;
    naceDescription: string;
    statisticalCode: TCompanyStatisticalCode;
    ventureNumber: string; // Ondernemingsnummer (~ BTW number)
    gvn: string; // RSZ number
    languageId: number;
    phone: string;
    fax: string;
    email: string;
    interim: boolean;
}

export interface ICompanyDetailUpdate {
    name?: string;
    ventureNumber?: string;
    gvn?: string;
    address?: IAddressSave;
    billingAddress?: IAddressSave;
    languageId?: number;
    phone?: string;
    fax?: string;
    email?: string;
}

export interface IUpdateCompanyPayload {
    companyData: Partial<ICompanyDetailUpdate>;
    companyCode: string;
}

export interface IAddCompanySeatPayload {
    address: Pick<IAddress, 'country' | 'number' | 'postcode' | 'street'>;
    name: string;
}

export interface ICompanyDetailSingleFieldUpdate {
    phone: string;
    fax: string;
    email: string;
}

export interface IUpdateCompanySingleFieldPayload extends ITraceableAsyncRequestPayload {
    companyData: Partial<ICompanyDetailSingleFieldUpdate>;
    companyCode: string;
}

export interface ICompanyMedicalCenter extends IMedicalCenter {
    prefered: boolean;
}

export interface IBufferzoneMedicalCenter extends Omit<IMedicalCenter, 'id'> {
    id: string;
}

export interface IFetchCompanySeatContactsPayload {
    companyCode: string;
}

export interface IFetchCompanyInternalContactsPayload {
    companyCode?: string;
    showFullFamilyIfApplicable?: boolean;
}

export interface IBaseContactPerson extends IPerson {
    email: string;
    phone: string;
    type?: string;
}

export interface IBaseContactPersonWithMobilePhone extends IBaseContactPerson {
    mobilePhone: string;
}

export interface IMensuraContactPerson extends IBaseContactPerson {
    companyCode: string;
}

export interface IFetchMensuraContactsSuccessPayload {
    contacts: IMensuraContactPerson[];
    companyCode: string;
}

export interface ICompanyContact extends IBaseContactPersonWithMobilePhone {
    id: number;
    address: IAddress;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    type: string;
    typeId?: number;
    customerContactId: number;
    fax: string;
    language: string;
    languageId?: string;
    titleContactId?: string;
    newsletter: boolean;
}

export interface IRemoveCompanyContactPayload {
    customerContactId: number;
}

export interface ICompanyContactToAdd extends IBaseContactPersonWithMobilePhone {
    firstName: string;
    name: string;
    languageId: string;
    typeId: number;
    titleId: string;
    fax: string;
}

export interface IAddCompanyContactPayload {
    companyCode: string;
    contactData: ICompanyContactToAdd;
}

export interface IUpdateCompanyContactPayload {
    companyCode: string;
    contactData: ICompanyContactToAdd;
    customerContactId: number;
    address?: IAddress;
}

/** The values of this enum are also used as translation keys, so don't just change them! */
export enum CompanyAvailabilityType {
    MedicalExaminations = 'med_exam',
    RiskManagement = 'risk_mgmt',
}

export interface ICompanyAvailabilityPeriod extends ICompanyAvailabilityBlock {
    days?: number[];
}

export interface ICompanyAvailabilities {
    morning: ICompanyAvailabilityPeriod;
    afternoon: ICompanyAvailabilityPeriod;
    available: boolean;
}

export type TCreateCompanyAvailabilities = Pick<ICompanyAvailabilities, 'morning' | 'afternoon'>;

export interface ICompanyAvailabilityBlock {
    startTime: string; // format "19:00"
    endTime: string; // format "21:00"
}

export interface IFetchCompanyAvailabilitiesPayload {
    companyCode?: string;
}

export interface IReplaceCompanyAvailabilitiesPayload {
    companyCode: string;
    applyFullFamily?: boolean;
    availabilities: ICompanyAvailabilities;
}

export interface ICompanyAddress extends Pick<IAddress, 'street' | 'number' | 'city' | 'postcode' | 'country' | 'box'> {
    id: number;
}

export interface IFetchCompanyHolidaysPayload extends ICompanyCodePayload {
    year: number;
}

export type TCompanyHolidayUpdateData = Pick<IHolidayBase, // eslint-disable-next-line max-len
    'customerHolidayIdMT' | 'customerHolidayIdRB' | 'typeId' | 'startDate' | 'endDate' | 'visitStateMT' | 'visitStateRB'>;

export interface IUpdateCompanyHolidaysPayload extends ICompanyCodePayload {
    holidays: TCompanyHolidayUpdateData[];
    doNotRefreshAfterUpdate?: boolean;
}

export enum HolidayVisitState {
    Unknown = -1,
    NotAllowed = 1,
    Allowed = 2,
}

export interface IHolidayBase {
    typeId: number;
    description: string;
    isCustomerPeriod?: boolean;
    startDate?: string;
    endDate?: string;
    customerHolidayIdMT: number; // if both ids are empty, there is no record for this holiday
    customerHolidayIdRB: number; // if both ids are empty, there is no record for this holiday
    visitStateMT: HolidayVisitState;
    visitStateRB: HolidayVisitState;
}

export interface ICompanyHoliday extends Pick<
    IHolidayBase, 'description' | 'isCustomerPeriod' | 'startDate' | 'endDate'
    > {
    typeId?: number; // optional - only filled if there are no nestedHolidays (only 1 holiday in the category)
    holidayTypes?: IHolidayBase[];
    customerHolidayIdMT?: number; // if both ids are empty, there is no record for this holiday
    customerHolidayIdRB?: number; // if both ids are empty, there is no record for this holiday
    visitStateMT?: HolidayVisitState;
    visitStateRB?: HolidayVisitState;
    customTypeId?: number; // Used to add custom holidays with the correct typeId and mark the custom holidays category
}
