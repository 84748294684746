import React, { PureComponent } from 'react';
import { connect } from '../../../../index';
import CompanyAddressesTypeahead from './CompanyAddressesTypeahead';
import {
    MedicalCenterTypeahead,
} from '../../../../administration/Employees/shared/MedicalCenterTypeahead/MedicalCenterTypeahead.component';
import {
    getFluVaccineTariffs,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import Radiobutton from '../../../../common/input/Radiobutton';
import { IFormRenderProps } from '../../../../common/forms/Form';
import FormFieldError from '../../../../common/forms/FormFieldError';
import Translate from '../../../../common/Translate';
import { formatPrice } from '../../../../../utils/formatting/formatPrice';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    DELIVERY_OPTIONS,
    IDeliveryOption,
    getDeliveryOptionByLocationId,
    isCompanyAddressRequiredForDeliveryOption,
    isMedicalCenterRequiredForDeliveryOption,
    areEnoughVaccinesOrderedToEnableDeliveryOption,
} from '../../../../../config/intervention.config';
import { ITranslator } from '../../../../../models/general/i18n';
import { ICompanyAddress } from '../../../../../models/admin/companyInfo';
import { IMedicalCenter } from '../../../../../models/admin/employee';
import RadioTileGroup from '../../../../common/input/RadioTileGroup';

const SELECT_MEDICAL_CENTER_PLACEHOLDER_TRANS_KEY =
    'interventions.flu_orders_new.steps.delivery.extra_fields.medical_center.select_placeholder';

export interface IFormValues {
    vaccinationLocationId: number;
    companyAddress: ICompanyAddress;
    medicalCenter: IMedicalCenter;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues) => void;
    inDetailPanel?: boolean;
    amountVaccines: number;
}

interface IPrivateProps {
    translator: ITranslator;
    travelCost: number;
}

interface IComponentState {
    selectedDeliveryOption: IDeliveryOption;
}

class DeliveryInput extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedDeliveryOption: getDeliveryOptionByLocationId(props.formRenderProps.values.vaccinationLocationId),
        };

        this.onChangeOptionHandler = this.onChangeOptionHandler.bind(this);
        this.onChangeCompanyAddressHandler = this.onChangeCompanyAddressHandler.bind(this);
        this.onChangeMedicalCenterHandler = this.onChangeMedicalCenterHandler.bind(this);
    }

    public render() {
        const { formRenderProps, inDetailPanel } = this.props;

        return (
            <div>
                <RadioTileGroup
                    radiobuttons={this.renderRadiobuttons()}
                    error={formRenderProps.touched.vaccinationLocationId && (
                        <FormFieldError
                            error={formRenderProps.errors.vaccinationLocationId}
                        />
                    )}
                    size={inDetailPanel ? 'small' : undefined}
                />
                {this.renderExtraFields()}
            </div>
        );
    }

    private renderRadiobuttons() {
        const { travelCost, amountVaccines, formRenderProps } = this.props;

        return DELIVERY_OPTIONS.map((deliveryOption) => {
            const hasMinNrOfVaccines = areEnoughVaccinesOrderedToEnableDeliveryOption(
                deliveryOption,
                amountVaccines,
            );

            return (
                <Radiobutton
                    key={`option-${deliveryOption.vaccinationLocationId}`}
                    name="select-delivery"
                    value={deliveryOption.vaccinationLocationId}
                    disabled={!hasMinNrOfVaccines}
                    // eslint-disable-next-line max-len
                    checked={hasMinNrOfVaccines && deliveryOption.vaccinationLocationId === formRenderProps.values.vaccinationLocationId}
                    onChange={() => this.onChangeOptionHandler(deliveryOption)}
                >
                    <p>
                        <Translate
                            raw
                            msg={deliveryOption.label}
                        />
                        {hasMinNrOfVaccines && deliveryOption.isTravelCostCharged &&
                            <Translate
                                raw
                                msg="interventions.flu_orders_new.steps.delivery.options.travel_cost"
                                placeholders={{
                                    travelCost: formatPrice(travelCost),
                                }}
                            />
                        }
                        {!hasMinNrOfVaccines &&
                            <Translate
                                raw
                                msg="interventions.flu_orders_new.steps.delivery.options.min_nr_of_vaccines"
                                placeholders={{
                                    minAmount: deliveryOption.minAmountOfVaccines.toString(),
                                }}
                            />
                        }
                    </p>
                </Radiobutton>
            );
        });
    }

    private renderExtraFields() {
        const { selectedDeliveryOption } = this.state;

        return (
            <>
                {isMedicalCenterRequiredForDeliveryOption(selectedDeliveryOption) &&
                    this.renderExtraFieldMedicalCenter()
                }
                {isCompanyAddressRequiredForDeliveryOption(selectedDeliveryOption) &&
                    this.renderExtraFieldAddress()
                }
            </>
        );
    }

    private renderExtraFieldMedicalCenter() {
        const { formRenderProps, translator } = this.props;

        const medicalCenterPlaceholder = translator(
            'interventions.flu_orders_new.steps.delivery.extra_fields.medical_center.placeholder');

        return (
            <div className="WizardFlow__content__narrow-form">
                <h4>
                    <Translate msg="interventions.flu_orders_new.steps.delivery.extra_fields.medical_center.title" />
                </h4>
                <MedicalCenterTypeahead
                    id="flu-delivery-medical-center"
                    name="medicalCenter"
                    value={formRenderProps.values.medicalCenter ? formRenderProps.values.medicalCenter.id : null}
                    onItemSelected={this.onChangeMedicalCenterHandler}
                    isInvalid={formRenderProps.touched.medicalCenter && !!formRenderProps.errors.medicalCenter}
                    placeholderTranslationKey={SELECT_MEDICAL_CENTER_PLACEHOLDER_TRANS_KEY}
                    excludeInCompanyMedicalCenter
                />
                {formRenderProps.touched.medicalCenter && (
                    <FormFieldError
                        error={formRenderProps.errors.medicalCenter}
                        placeholders={{ fieldName: medicalCenterPlaceholder }}
                    />
                )}
            </div>
        );
    }

    private renderExtraFieldAddress() {
        const { formRenderProps, translator } = this.props;

        const selectAddressPlaceholder = translator(
            'interventions.flu_orders_new.steps.delivery.extra_fields.address.select_placeholder');
        const addressPlaceholder = translator(
            'interventions.flu_orders_new.steps.delivery.extra_fields.address.placeholder');

        return (
            <div className="WizardFlow__content__narrow-form">
                <h4>
                    <Translate msg="interventions.flu_orders_new.steps.delivery.extra_fields.address.title" />
                </h4>
                <CompanyAddressesTypeahead
                    id="flu-delivery-address"
                    name="companyAddress"
                    value={formRenderProps.values.companyAddress ? formRenderProps.values.companyAddress.id : null}
                    onItemSelected={this.onChangeCompanyAddressHandler}
                    isInvalid={formRenderProps.touched.companyAddress && !!formRenderProps.errors.companyAddress}
                    placeholder={selectAddressPlaceholder}
                />
                {formRenderProps.touched.companyAddress && (
                    <FormFieldError
                        error={formRenderProps.errors.companyAddress}
                        placeholders={{ fieldName: addressPlaceholder }}
                    />
                )}
            </div>
        );
    }

    private onChangeOptionHandler(deliveryOption: IDeliveryOption) {
        this.setState({
            selectedDeliveryOption: deliveryOption,
        });

        this.props.onChangeInput({
            vaccinationLocationId: deliveryOption.vaccinationLocationId,
            companyAddress: isCompanyAddressRequiredForDeliveryOption(deliveryOption) ?
                this.props.formRenderProps.values.companyAddress : null,
            medicalCenter: isMedicalCenterRequiredForDeliveryOption(deliveryOption) ?
                this.props.formRenderProps.values.medicalCenter : null,
        });
    }

    private onChangeMedicalCenterHandler(medicalCenterId: number, medicalCenter: IMedicalCenter) {
        this.props.onChangeInput({
            vaccinationLocationId: this.props.formRenderProps.values.vaccinationLocationId,
            companyAddress: null,
            medicalCenter,
        });
    }

    private onChangeCompanyAddressHandler(formattedAddress: string, companyAddress: ICompanyAddress) {
        this.props.onChangeInput({
            vaccinationLocationId: this.props.formRenderProps.values.vaccinationLocationId,
            companyAddress,
            medicalCenter: null,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const tariffs = getFluVaccineTariffs(state);

        return {
            translator: getTranslatorDeprecated(state),
            travelCost: tariffs ? tariffs.travelCostTariff : 0,
        };
    },
})(DeliveryInput);
