import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { get } from '../../../../utils/api/requestWrapper';
import {
    IFetchPlannedCompanyVisitsPayload,
    IPlannedCompanyVisitsFilter,
    IPlannedCompanyVisit,
} from '../../../../models/interventions/company-visits';
import { Locales } from '../../../../config/i18n.config';
import { URL } from '../company-visits.const';

import { DEFAULT_PLANNED_COMPANY_VISITS_FILTERS } from './planned.const';

export function fetchPlannedCompanyVisits(
    { companyCode, showFullFamily }: IFetchPlannedCompanyVisitsPayload,
    {
        startDate = DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.endDate,
    }: IPlannedCompanyVisitsFilter,
    locale: Locales,
) {
    return get<IPlannedCompanyVisit[]>({
        ...bffDefaultApiConfig({ locale }),
        url: URL.COMPANY_VISITS_PLANNED,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            start: startDate,
            end: endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response: IPlannedCompanyVisit[] | null) => {
            // The BFF returns status 204 when no planned company visits were found.
            // When this happens, the response is null. To prevent the call from timing out,
            // we return an empty array
            if (!response) {
                return [];
            }

            return response;
        },
    });
}
