import React from 'react';

import { ISortedColumn, ListColumns, SortOrder, SortType } from '../../../../../../../models/general/list';
import Translate from '../../../../../../common/Translate';
import { ISelectBufferzoneOverviewColumnNames } from '../SelectBufferzoneOverview.type';
import { renderWarningTooltip } from '../SelectBufferzoneOverview.helper';
import { TRANSLATION_PREFIX } from '../SelectBufferzoneOverview.const';

export const INITIAL_SORT: ISortedColumn<ISelectBufferzoneOverviewColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Ascending,
};

export const COLUMNS: ListColumns<ISelectBufferzoneOverviewColumnNames> = {
    warning: {
        sortable: false,
        minWidth: 50,
        percentWidth: null,
        render: renderWarningTooltip,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    locationId: {
        hide: true,
        percentWidth: null,
    },
    address: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.address`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem) => listItem.columns.dateSort,
        percentWidth: 15,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.time`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    freeSlots: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.free_slots`} />,
        sortable: true,
        sortType: SortType.Number,
        sortValue: (listItem) => listItem.columns.freeSlots,
        percentWidth: 15,
    },
    totalSlots: {
        hide: true,
        percentWidth: null,
    },
    endTime: {
        percentWidth: null,
        sortable: false,
    },
    startTime: {
        percentWidth: null,
        sortable: false,
    },
};
