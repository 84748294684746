const TWENTY_FOUR_HOURS_IN_MINUTES = 1440;
const TWELVE_HOURS_IN_MINUTES = 720;

export default function validateMinimumTimespan(
    formattedTime: string,
    formattedOtherTime: string,
    minDifferenceInMinutesLowerThanTwelveHours: number,
) {
    if (minDifferenceInMinutesLowerThanTwelveHours >= TWELVE_HOURS_IN_MINUTES) {
        return false;
    }

    const timeInMinutes = getTimeInMinutesFromFormattedTime(formattedTime);
    const otherTimeInMinutes = getTimeInMinutesFromFormattedTime(formattedOtherTime);

    if (timeInMinutes > otherTimeInMinutes) {
        return checkIfOverNightAndReturnCorrectValidationElseReturnTrue(
            timeInMinutes,
            otherTimeInMinutes,
            minDifferenceInMinutesLowerThanTwelveHours,
        );
    }
    return Math.abs(timeInMinutes - otherTimeInMinutes) >= minDifferenceInMinutesLowerThanTwelveHours;
}

function getTimeInMinutesFromFormattedTime(formattedTime: string) {
    const hoursAndMinutes = formattedTime.split(':');

    return (parseInt(hoursAndMinutes[0], 10) * 60) + parseInt(hoursAndMinutes[1], 10);
}

function checkIfOverNightAndReturnCorrectValidationElseReturnTrue(
    timeInMinutes: number,
    otherTimeInMinutes: number,
    minDifferenceInMinutes: number,
) {
    if (timeInMinutes >= (TWENTY_FOUR_HOURS_IN_MINUTES - minDifferenceInMinutes) &&
        otherTimeInMinutes <= minDifferenceInMinutes
    ) {
        return Math.abs(timeInMinutes - otherTimeInMinutes - TWENTY_FOUR_HOURS_IN_MINUTES) >= minDifferenceInMinutes;
    }
    return true;
}
