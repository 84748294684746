import React, { Component } from 'react';
import './onboarding-wizard-seats.scss';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { connect } from '../../../..';
import SeatsList from '../../../../account/SeatsList';
import PageHeader from '../../../../appShell/PageHeader';
import classNames from 'classnames';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import ListFooter from '../../../../common/list/ListFooter';
import {
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo, getSeatsAndDivisionsForSelectedCompany,
} from '../../../../../redux/company/selected/selectors';
import { ICompanySeat } from '../../../../../models/admin/company';
import Translate from '../../../../common/Translate';
import Icon from '../../../../common/icons/Icon';
import SlideOutPanel from '../../../../common/widget/SlideOutPanel';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import DetailContent, { FormValues } from './detail';
import { IAddressSave } from '../../../../../models/general/address';
import EditOnboardingSeatAddress from './EditOnboardingSeatAddress';
import AddSeat from './AddSeat';
import { updateOnboardingWizardEntity } from '../../../../../redux/onboarding/actions';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Button from '../../../../common/buttons/Button';

const CLASS_NAME = 'OnboardingWizardSeats';
const LIST_NAME = 'onboarding-wizard-seats-list';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.seats';

interface IPrivateProps {
    seats: ICompanySeat[];
    fetchAsyncInfo: IAsyncFieldInfo;
    addSeatsToWizardEntity: () => void;
}

export interface IRenderOnboardingSeatsDetailContentProps {
    onOpenOverlay?: (
        onSave?: (address: IAddressSave & { countryCode: string }) => void,
        formValues?: FormValues,
    ) => void;
}

interface IRenderOnboardingSeatsDetailOverlayProps {
    closeOverlay: () => void;
    onSave?: (address?: IAddressSave & { countryCode: string }) => void;
    formValues?: FormValues;
}

interface IState {
    selectedCompanySeat: ICompanySeat;
    isOverlayOpen: boolean;
    formValues: FormValues;
    isAddSeatDialogOpen: boolean;
}

type TOnboardingSeatsProps = IPrivateProps & IStepperStepRenderProps;

class Seats extends Component<TOnboardingSeatsProps, IState> {
    private onOverlaySave: (address: IAddressSave & { countryCode: string }) => void = null;

    constructor(props: TOnboardingSeatsProps) {
        super(props);

        this.state = {
            selectedCompanySeat: null,
            isOverlayOpen: false,
            formValues: null,
            isAddSeatDialogOpen: false,
        };

        this.renderAddSeatButton = this.renderAddSeatButton.bind(this);
        this.renderAddSeatTextButton = this.renderAddSeatTextButton.bind(this);
        this.onCloseSlideOutPanel = this.onCloseSlideOutPanel.bind(this);
        this.onSeatSelected = this.onSeatSelected.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
        this.renderOverlay = this.renderOverlay.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.toggleAddSeatDialog = this.toggleAddSeatDialog.bind(this);
        this.onNextHandler = this.onNextHandler.bind(this);
    }

    public render() {
        const { fetchAsyncInfo, renderStepButtons, seats } = this.props;
        const { selectedCompanySeat, isOverlayOpen, formValues, isAddSeatDialogOpen } = this.state;

        return (
            <>
                <div
                    className={classNames(
                        'container',
                        CLASS_NAME,
                    )}
                >
                    <PageHeader
                        title={`${TRANSLATION_PREFIX}.title`}
                        text={`${TRANSLATION_PREFIX}.text`}
                        textPlaceholders={{
                            button: this.renderAddSeatTextButton(),
                        }}
                    />
                    <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                        <p className={`${CLASS_NAME}__subtext`}>
                            <Translate msg={`${TRANSLATION_PREFIX}.subtext`} />
                        </p>
                        <SeatsList
                            name={LIST_NAME}
                            hideListActions={true}
                            listWithoutRadioButtonSelect={true}
                            selectedSeatCompanyCode={selectedCompanySeat && selectedCompanySeat.company.companyCode}
                            onSeatSelected={this.onSeatSelected}
                            companySeats={seats}
                            fetchAsyncInfo={fetchAsyncInfo}
                            footer={
                                <ListFooter
                                    right={renderStepButtons({
                                        nextButton: {
                                            onClick: this.onNextHandler,
                                        },
                                    })}
                                    left={this.renderAddSeatButton()}
                                />
                            }
                        />
                        <SlideOutPanel
                            className={`${CLASS_NAME}__slide-out-panel`}
                            isOpen={!!selectedCompanySeat}
                            onCloseIntent={this.onCloseSlideOutPanel}
                            footer={this.renderFooter(true)}
                            showOverlay={isOverlayOpen}
                            overlay={this.renderOverlay({
                                closeOverlay: this.closeOverlay,
                                onSave: (data) => {
                                    if (typeof this.onOverlaySave === 'function') {
                                        this.onOverlaySave(data);
                                    }
                                },
                                formValues,
                            })}
                        >
                            {this.renderDetailContent({
                                onOpenOverlay: (onSave, formValues) => {
                                    this.onOverlaySave = onSave;
                                    this.setState({
                                        isOverlayOpen: true,
                                        formValues,
                                    });
                                },
                            })}
                        </SlideOutPanel>
                    </div>
                </div>
                {this.renderFooter()}
                <AddSeat
                    show={isAddSeatDialogOpen}
                    onCloseIntent={this.toggleAddSeatDialog}
                />
            </>
        );
    }

    private onNextHandler() {
        this.props.addSeatsToWizardEntity();
        this.props.goToNextStep();
    }

    private toggleAddSeatDialog() {
        this.setState({ isAddSeatDialogOpen: !this.state.isAddSeatDialogOpen });
    }

    private renderOverlay(props: IRenderOnboardingSeatsDetailOverlayProps) {
        const { formValues } = props;
        const { selectedCompanySeat } = this.state;

        return (
            <EditOnboardingSeatAddress
                selectedSeatName={selectedCompanySeat && selectedCompanySeat.company.name}
                initialValues={formValues && formValues.address}
                onClose={props.closeOverlay}
                onFormSubmit={(address) => {
                    props.onSave(address);
                    props.closeOverlay();
                }}
            />
        );
    }

    private renderDetailContent(props: IRenderOnboardingSeatsDetailContentProps) {
        return (
            <DetailContent
                selectedCompanySeat={this.state.selectedCompanySeat}
                {...props}
            />
        );
    }

    private closeOverlay() {
        this.setState({
            isOverlayOpen: false,
        });
    }

    private onCloseSlideOutPanel(isClickedOutside: boolean) {
        if (!isClickedOutside) {
            this.setState({ selectedCompanySeat: null });
        }
    }

    private onSeatSelected(companyCode: string) {
        const companySeat = this.props.seats.find((seat) => seat.company.companyCode === companyCode);
        this.setState({
            selectedCompanySeat: companySeat,
            isOverlayOpen: false,
        });
    }

    private renderAddSeatButton() {
        const {
            fetchAsyncInfo,
        } = this.props;

        return (
            <ShowIfAllowed requiredAccessLevels={{ company: 'W' }}>
                <Button
                    id="add-seat"
                    typeName="text"
                    className="AddButton"
                    disabled={fetchAsyncInfo.status === AsyncStatus.Busy}
                    onClick={this.toggleAddSeatDialog}
                >
                    <Icon typeName="plus" circle />
                    <span>
                        <Translate msg={`${TRANSLATION_PREFIX}.footer_add_button`} />
                    </span>
                </Button>
            </ShowIfAllowed>
        );
    }

    private renderAddSeatTextButton() {
        return (
            <a
                id="onboarding-wizard-add-seat-header-button"
                onClick={this.toggleAddSeatDialog}
            >
                <Icon typeName="plus-circle" />
                <span><Translate msg={`${TRANSLATION_PREFIX}.header_add_button`} /></span>
            </a>
        );
    }

    private renderFooter(detail?: boolean) {
        return (
            <div
                className={classNames(`${CLASS_NAME}__footer`, {
                    ['detail-footer']: detail,
                })}
            >
                <div className="container">
                    <Icon
                        className={`${CLASS_NAME}__footer-icon`}
                        typeName="info"
                        circle
                    />
                    <Translate msg={`${TRANSLATION_PREFIX}.footer`} />
                </div>
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            seats: getSeatsAndDivisionsForSelectedCompany(state),
            fetchAsyncInfo: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            addSeatsToWizardEntity: () => {
                const seats = getSeatsAndDivisionsForSelectedCompany(getState());
                dispatch(updateOnboardingWizardEntity({
                    seatsData: seats,
                }));
            },
        };
    },
})(Seats);
