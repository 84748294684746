import React from 'react';
import { connect } from '../../index';
import ROUTE_KEYS from '../../../routeKeys';
import { getWebAppMessages, getWebAppMessagesAsyncInfo } from '../../../redux/navigation/selectors';
import TinyLoader from '../../common/waiting/TinyLoader';
import { getFirstName } from '../../../redux/auth/selectors';
import TranslatorContext from '../../appShell/contexts/TranslatorContext';
import { formatDateForGreeting } from '../../../utils/formatting/formatDate';
import Icon from '../../common/icons/Icon';
import Notification from '../../common/widget/Notification';
import { getUnreadMessagesCountIfAny } from '../../../redux/inbox/selectors';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';

interface IPrivateProps {
    welcomeMessage: string;
    firstName: string;
    unreadMessagesCount: number;
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        welcomeMessage: getWebAppMessages(state).welcomeMessage,
        firstName: getFirstName(state),
        unreadMessagesCount: getUnreadMessagesCountIfAny(state),
    }),
})(WelcomeDashboardBlock);

function WelcomeDashboardBlock(props: IPrivateProps) {
    const {
        welcomeMessage,
        firstName,
        unreadMessagesCount,
    } = props;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => {
                return (
                    <div className="section section--welcome">
                        <div className="content">
                            <h3>{translator({
                                msg: 'home.welcome.' + formatDateForGreeting(),
                                placeholders: {
                                    name: firstName,
                                },
                            })}</h3>
                            <TinyLoader asyncInfoSelector={getWebAppMessagesAsyncInfo}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: welcomeMessage }}
                                />
                            </TinyLoader>
                        </div>
                        <div className="bottom">
                            <LinkToRoute
                                id="navigate-to-message-center"
                                to={ROUTE_KEYS.R_MESSAGE_CENTER}
                                className="icon-link"
                            >
                                <span>
                                    <Icon typeName="message" />
                                    <Notification count={unreadMessagesCount} />
                                </span>
                                <span>
                                    <Translate msg="home.welcome.action"/>
                                </span>
                                <Icon typeName="chevron-right" />
                            </LinkToRoute>
                        </div>
                    </div>
                );
            }}
        </TranslatorContext.Consumer>
    );
}
