import { createEpic, IParallelCallInput } from '..';
import {
    fetchQuestionnairesScreenworkActions,
    fetchQuestionnairesJobStudentsActions,
    fetchQuestionnaireAdviceActions,
    sendJobStudentReminderEmailActions,
    triggerPlanJobStudentMedExamActions,
} from './actions';
import ROUTE_KEYS from '../../routeKeys';
import { getLocationState, getQueryParams } from '../location/selectors';
import {
    isQuestionnairesScreenworkAvailable,
    isQuestionnairesJobStudentsAvailable,
    getQuestionnairesJobStudents } from './selectors';
import {
    IQuestionnaireAdvice,
    IFetchQuestionnaireAdvicePayload,
    ISendJobStudentReminderEmailPayload,
    ITriggerPlanJobStudentMedExamPayload,
} from '../../models/admin/questionnaires';
import { getSelectedSeatCompanyCode, isAllSeatsSelected } from '../company/selected/selectors';
import { areObjectParamsEqual } from '../../utils/core/object/diffObjects';
import {
    DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS,
    DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS,
} from '../../api/admin/questionnaires.api';
import { IStartEndDateFilterValues } from '../../models/ui/form';
import {
    FETCH_QUESTIONNAIRES_JOB_STUDENTS,
    SEND_JOB_STUDENT_REMINDER_EMAIL,
    TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
} from './types';
import Api from '../../api';
import { PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE } from '../../models/interventions/medicalExaminations';
import {
    skipToPlanMedicalExaminationWizardStepActions,
    fetchExaminationReasonsSucceeded,
} from '../medicalExamination/actions';
import { getPeriodicHealthAssessmentReason, areExaminationReasonsAvailable } from '../medicalExamination/selectors';
import { formatPersonName } from '../../utils/formatting/formatPerson';
import { getSelectedEmployee } from '../employee/info/selectors';
import { fetchSmallEmployeeDetailsByInszActions } from '../employee/info/actions';

// fetchQuestionnairesScreenworkEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK,
        ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK_DETAIL,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        if (!isQuestionnairesScreenworkAvailable(state)) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed or when switching between overview/detail
        const { type: prevRoute, query: prevQuery } = getLocationState(state);

        const queryWithDefaults = {
            ...DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS,
            ...prevQuery,
        };

        if (
            prevRoute !== ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK &&
            prevRoute !== ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK_DETAIL
        ) {
            return true;
        }

        if (
            action.type === ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK &&
            !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
        ) {
            return true;
        }

        return false;
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);
            const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;

            const response = await api.admin.questionnaires.fetchQuestionnairesScreenwork({
                ...filterFromQuery,
                companyCode,
                showFullFamily,
            });
            return fetchQuestionnairesScreenworkActions.succeeded(response);
        } catch (error) {
            return fetchQuestionnairesScreenworkActions.failed(error);
        }
    },
    latest: false,
});

// fetchQuestionnairesJobStudentsEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS,
        FETCH_QUESTIONNAIRES_JOB_STUDENTS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        if (!isQuestionnairesJobStudentsAvailable(state)) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed or when switching between overview/detail
        const { type: prevRoute, query: prevQuery } = getLocationState(state);

        const queryWithDefaults = {
            ...DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS,
            ...prevQuery,
        };

        if (
            prevRoute !== ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS &&
            prevRoute !== ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL
        ) {
            return true;
        }

        return !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    processReturn: fetchQuestionnairesJobStudents,
    latest: false,
});

// fetchQuestionnairesJobStudentsEpic
createEpic<IFetchQuestionnaireAdvicePayload>({
    onActionType: ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const id = Number(action.payload.id);

            if (!isQuestionnairesJobStudentsAvailable(state)) {
                fetchQuestionnairesJobStudentsActions.trigger({});
                dispatch(await fetchQuestionnairesJobStudents({ api, getState }));
            }

            const stateAfterJobStudentFetch = getState();
            const questionnaires = getQuestionnairesJobStudents(stateAfterJobStudentFetch);

            const selectedQuestionnaire = questionnaires.find((item) => item.id === id);

            const currentEmployeeDetails = getSelectedEmployee(stateAfterJobStudentFetch);
            const currentEmployeeDetailsInsz = currentEmployeeDetails && currentEmployeeDetails.nationalRegisterNumber;
            if (selectedQuestionnaire.employee.nationalRegisterNumber !== currentEmployeeDetailsInsz) {
                dispatch(fetchSmallEmployeeDetailsByInszActions.trigger({
                    nationalRegisterNumber: selectedQuestionnaire.employee.nationalRegisterNumber,
                    companyCode: selectedQuestionnaire.employee.company.companyCode,
                }));
            }

            if (selectedQuestionnaire.status !== 5) {
                dispatch(fetchQuestionnaireAdviceActions.succeeded([]));
                return done();
            }

            const response = await api.admin.questionnaires.fetchQuestionnaireAdvice({ id });
            dispatch(fetchQuestionnaireAdviceActions.succeeded(response as IQuestionnaireAdvice[]));
            return done();
        } catch (error) {
            dispatch(fetchQuestionnaireAdviceActions.failed(error));
            return done();
        }
    },
    latest: true,
});

async function fetchQuestionnairesJobStudents(
    { api, getState }: { api: typeof Api, getState },
) {
    try {
        const state = getState();
        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = isAllSeatsSelected(state);
        const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;

        const response = await api.admin.questionnaires.fetchQuestionnairesJobStudents({
            ...filterFromQuery,
            companyCode,
            showFullFamily,
        });

        return fetchQuestionnairesJobStudentsActions.succeeded(response);
    } catch (error) {
        return fetchQuestionnairesJobStudentsActions.failed(error);
    }
}

// sendJobStudentReminderMails
createEpic<ISendJobStudentReminderEmailPayload[]>({
    onActionType: SEND_JOB_STUDENT_REMINDER_EMAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            if (action.payload.length > 0) {
                await Promise.all(
                    action.payload.map(
                        (payload) => sendJobStudentReminderEmail(payload, { api, dispatch }),
                    ),
                ).catch((error) => {
                    throw error;
                });
            }

            dispatch(sendJobStudentReminderEmailActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(sendJobStudentReminderEmailActions.failed(error));
            return done();
        }
    },
    latest: true,
});

async function sendJobStudentReminderEmail(
    payload: ISendJobStudentReminderEmailPayload, { api, dispatch }: IParallelCallInput,
) {
    try {
        await api.admin.employee.updateEmployeeJobStudent({
            interimEmployeeId: payload.interimEmployeeId,
            id: payload.employee.id,
            sendMail: true,
            emailAddressToUpdate: null,
            studentWorker: true,
        });
    } catch (error) {
        throw Error();
    }
}

// planJobStudentMedicalExamination
createEpic<ITriggerPlanJobStudentMedExamPayload>({
    onActionType: TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            if (!areExaminationReasonsAvailable(state)) {
                const companyCode = getSelectedSeatCompanyCode(getState());
                const reasons = await api.interventions.medicalExaminations.fetchExaminationReasons(companyCode);
                dispatch(fetchExaminationReasonsSucceeded(reasons));
            }

            const employee = await api.admin.employee.fetchEmployeeByInsz({
                nationalRegisterNumber: action.payload.nationalRegisterNumber,
                selectedCompanyCompanyCode: action.payload.companyCode,
            });

            const stateAfterPossibleExaminationReasonsFetch = getState();

            dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                wizardPayload: {
                    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                    reason: getPeriodicHealthAssessmentReason(stateAfterPossibleExaminationReasonsFetch),
                },
                entity: {
                    searchEmployee: {
                        autoSelected: true,
                        searchValue: formatPersonName(employee),
                        selectedEmployee: employee,
                    },
                    selectTime: undefined,
                },
            }));

            dispatch(triggerPlanJobStudentMedExamActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(triggerPlanJobStudentMedExamActions.failed(error));
            return done();
        }
    },
    latest: true,
});
