import React from 'react';
import {
    IRenderDetailHeaderProps, IRenderDetailContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import {
    IExecutedMedicalExamination,
    IMedicalExaminationDocuments,
    IMedicalExaminationDocument,
} from '../../../../models/interventions/medicalExaminations';
import Translate from '../../../common/Translate';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import CollapsibleItem from '../../../common/widget/CollapsibleItem/index';
import ListItem from '../../../common/list/ListItem/index';
import { formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';
import connect from '../../../../utils/libs/redux/connect';
import {
    IEmployeeRisksAndResearches, IEmployeeRiskAndResearch,
} from '../../../../models/admin/employee';
import TinyLoader from '../../../common/waiting/TinyLoader';
import isEmptyObject from '../../../../utils/core/object/isEmptyObject';
import {
    getEmployeeRisksAndResearchesAsyncInfo, getEmployeeRisksAndResearches,
} from '../../../../redux/employee/info/selectors';
import isEmptyString from '../../../../utils/core/string/isEmptyString';
import { ITranslator } from '../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import Loader from '../../../common/waiting/Loader';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import {
    getMedicalExaminationDocuments, getMedicalExaminationDocumentsAsyncInfo,
} from '../../../../redux/medicalExamination/selectors';
import { fetchDocument } from '../../../../redux/employee/documents/actions';
import { getDocumentAsyncInfo } from '../../../../redux/employee/documents/selectors';
import Icon from '../../../common/icons/Icon';
import NoDocumentsFoundErrorDialog from '../../../common/modals/NoDocumentsFoundErrorDialog';

const CLASS_NAME = 'MedicalExaminationsExecutedDetailContent';

interface IPrivateProps {
    employeeRisksAndResearches: IEmployeeRisksAndResearches;
    examinationDocuments: IMedicalExaminationDocuments;
    translator: ITranslator;
    downloadDocument: (document: IMedicalExaminationDocument) => void;
    fetchDocumentAsyncInfo: IAsyncFieldInfo;
}

export const DetailHeader = (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) => {
    const {
        detailAsyncInfo,
        detailData: selectedExamination,
    } = renderProps;

    return (
        <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : selectedExamination && formatPersonName(selectedExamination.employee)
                }
            </h1>
            {selectedExamination &&
                <p>
                    <strong>{selectedExamination.employee.function.description}</strong><br />
                    {selectedExamination.employee.company.name}
                </p>
            }
        </Loader>
    );
};

const DetailContentComp = (props: IPrivateProps & IRenderDetailContentProps<IExecutedMedicalExamination>) => {
    const {
        detailData: selectedExamination,
        detailAsyncInfo, employeeRisksAndResearches,
        examinationDocuments, translator,
        downloadDocument, fetchDocumentAsyncInfo,
    } = props;

    if (!selectedExamination) {
        return null;
    }

    function documentDownloadHandler(document: IMedicalExaminationDocument) {
        downloadDocument(document);
    }

    const showLoader =
        detailAsyncInfo.status === AsyncStatus.Busy ||
        fetchDocumentAsyncInfo.status === AsyncStatus.Busy;

    return (
        <div className={`${CLASS_NAME}`}>
            <Loader show={showLoader} />
            <CollapsibleItem
                trigger="interventions.medical_examinations.executed.detail.research.title"
                initialOpen
            >
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.reason" />}
                    text={selectedExamination.examinationReason.title}
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.location" />}
                    text={selectedExamination.medicalCenter && selectedExamination.medicalCenter.name}
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.date" />}
                    text={formatDateInLongFormat(selectedExamination.examinationDate)}
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.start" />}
                    text={selectedExamination.startTime
                        ? formatTimeOfDateForDisplay(selectedExamination.startTime)
                        : <Translate
                            msg="interventions.medical_examinations.executed.detail.research.start_unknown"
                        />}
                    arrow={false}
                />
            </CollapsibleItem>
            <CollapsibleItem
                // eslint-disable-next-line max-len
                trigger="interventions.medical_examinations.executed.detail.risks_and_researches.title"
            >
                <TinyLoader asyncInfoSelector={getEmployeeRisksAndResearchesAsyncInfo}>
                    {renderEmployeeRisksAndResearches(employeeRisksAndResearches)}
                </TinyLoader>
            </CollapsibleItem>
            <CollapsibleItem trigger="interventions.medical_examinations.executed.detail.results.title">
                {selectedExamination.isFinal ? (
                    <>
                        {!isEmptyString(selectedExamination.conclusion) && <ListItem
                            title={
                                <Translate
                                    msg="interventions.medical_examinations.executed.detail.results.conclusion"
                                />
                            }
                            text={selectedExamination.conclusion}
                            arrow={false}
                        />}
                        {!isEmptyString(selectedExamination.conclusionSigns) && <ListItem
                            title={
                                <Translate
                                    msg="interventions.medical_examinations.executed.detail.results.conclusion_signs"
                                />
                            }
                            text={selectedExamination.conclusionSigns}
                            arrow={false}
                        />}
                        {(selectedExamination.validUntil && !isEmptyString(selectedExamination.validUntil)) && <ListItem
                            title={
                                <Translate
                                    msg="interventions.medical_examinations.executed.detail.results.attest"
                                />
                            }
                            text={translator({
                                msg: 'interventions.medical_examinations.executed.detail.results.valid_until',
                                placeholders: {
                                    date: formatDateInLongFormat(selectedExamination.validUntil),
                                },
                            })
                            }
                            arrow={false}
                        />}
                        {!isEmptyString(selectedExamination.conclusionPregnancySentences) && <ListItem
                            title={
                                <Translate
                                    msg="interventions.medical_examinations.executed.detail.results.pregnancy"
                                />
                            }
                            text={selectedExamination.conclusionPregnancySentences}
                            arrow={false}
                        />}
                        <TinyLoader
                            asyncInfoSelector={getMedicalExaminationDocumentsAsyncInfo}
                        >
                            {renderExaminationDocuments(examinationDocuments)}
                        </TinyLoader>
                    </>
                ) : (
                    <p>
                        <Translate msg="interventions.medical_examinations.executed.detail.results.not_final" />
                    </p>
                )}
            </CollapsibleItem>
            <NoDocumentsFoundErrorDialog
                asyncInfoSelector={getDocumentAsyncInfo}
                isMedicalExaminationDocument
            />
        </div>
    );

    function renderEmployeeRisksAndResearches(employeeRisksAndResearches: IEmployeeRisksAndResearches) {
        return (
            !isEmptyObject(employeeRisksAndResearches) ? (
                <>
                    {
                        Object.keys(employeeRisksAndResearches).map((riskCode, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    title={employeeRisksAndResearches[riskCode][0].riskDescription}
                                    text={
                                        // eslint-disable-next-line max-len
                                        employeeRisksAndResearches[riskCode].map((item: IEmployeeRiskAndResearch, index) =>
                                            item.researchDescription,
                                        )
                                    }
                                    arrow={true}
                                />
                            );
                        })
                    }
                </>) : (
                    <p>
                        <Translate
                            msg="interventions.medical_examinations.executed.detail.risks_and_researches.no_results"
                        />
                    </p>
                )
        );
    }

    function renderExaminationDocuments(examinationDocuments: IMedicalExaminationDocuments) {
        return (
            !isEmptyObject(examinationDocuments) && (
                <>
                    {
                        Object.keys(examinationDocuments).map((documentClass) => {
                            return Object.keys(examinationDocuments[documentClass]).map(
                                (document, index) => {
                                    const examinationDocument: IMedicalExaminationDocument =
                                        examinationDocuments[documentClass][document];

                                    return (
                                        <div
                                            className="download-file"
                                            key={index}
                                            onClick={(e) => documentDownloadHandler(examinationDocument)}
                                        >
                                            <Icon typeName="doc" />
                                            <span>{examinationDocument.templateName}</span>
                                        </div>
                                    );
                                },
                            );
                        })
                    }
                </>
            )
        );
    }
};

export const DetailContent = connect<IPrivateProps, IRenderDetailContentProps<IExecutedMedicalExamination>>({
    stateProps: (state) => {
        return {
            employeeRisksAndResearches: getEmployeeRisksAndResearches(state),
            examinationDocuments: getMedicalExaminationDocuments(state),
            translator: getTranslatorDeprecated(state),
            fetchDocumentAsyncInfo: getDocumentAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            downloadDocument: (document: IMedicalExaminationDocument) => {
                dispatch(fetchDocument({
                    ids: [document.id.toString()],
                }));
            },
        };
    },
})(DetailContentComp);
