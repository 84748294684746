export default function getPossiblyNestedObjectField(obj: object, ...pathParts: string[]) {
    if (!obj) {
        return null;
    }

    if (pathParts.length === 1) {
        return obj[pathParts[0]];
    }

    const [firstPathPart, ...deeperPathParts] = pathParts;

    return getPossiblyNestedObjectField(
        obj[firstPathPart],
        ...deeperPathParts,
    );
}
