import React from 'react';
import Translate from '../../Translate';
import Icon from '../../icons/Icon';
import ConfirmationDialog from '../ConfirmationDialog';
import { ITraceableApiError } from '../../../../models/general/error';
import './warning-dialog.scss';

interface IWarningDialogProps {
    show: boolean;
    title?: string;
    showLoader: boolean;
    onConfirm: () => void;
    confirmTranslationKey?: string;
    hideConfirmOption?: boolean;
    onCancel: () => void;
    cancelTranslationKey?: string;
    error?: ITraceableApiError;
    children?: React.ReactNode;
}

const CLASS_NAME = 'WarningDialog';

export default function WarningDialog(props: IWarningDialogProps) {
    const {
        show, showLoader, title, children,
        onConfirm, confirmTranslationKey, hideConfirmOption,
        onCancel, cancelTranslationKey,
    } = props;

    return (
        <ConfirmationDialog
            show={show}
            showLoader={showLoader}
            onConfirm={onConfirm}
            confirmTranslationKey={confirmTranslationKey ? confirmTranslationKey : undefined}
            hideConfirmOption={hideConfirmOption}
            onCancel={onCancel}
            cancelTranslationKey={cancelTranslationKey ? cancelTranslationKey : undefined}
            dialogClassName={`${CLASS_NAME}__dialog`}
        >
            <Icon className={`${CLASS_NAME}__icon`} circle={true} typeName="warning" colorType="warning" />
            {title && (
                <h3 className={`${CLASS_NAME}__title`}>
                    <Translate msg={title} />
                </h3>
            )}
            {children}
        </ConfirmationDialog>
    );
}
