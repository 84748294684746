import React, { MouseEvent, PureComponent } from 'react';
import classNames from 'classnames';
import {
    FlashMessageType,
    IFlashDataCount,
    IFlashDataSessionAboutToExpire,
    IFlashMessage,
} from '../../../models/general/flashMessages';
import { TIconTypeName } from '../../../models/general/icon';
import ROUTE_KEYS from '../../../routeKeys';
import Icon from '../../common/icons/Icon';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Translate, { TPlaceholders } from '../../common/Translate';
import Notification from '../../common/widget/Notification';
import HideAfterDelay from '../../common/waiting/HideAfterDelay';
import { ONE_SECOND } from '../../../utils/core/time/periodsInMillis';
import { now } from '../../../utils/core/date/getSpecificDate';
import { connect } from '../..';
import { extendBackEndSession, updateSessionEndTime } from '../../../redux/auth/actions';
import Button from '../../common/buttons/Button';
import { redirectToLogout } from '../../../redux/location/actions';
import { getLocationState } from '../../../redux/location/selectors';
import { formatTimeOfDateForDisplay } from '../../../utils/formatting/formatTime';
import reloadPage from '../../../utils/browser/reloadPage';

const TRANSLATION_PREFIX = 'app_shell.flash_messages';
const HIDE_DELAY_IN_MILLIS = {
    DEFAULT: 10 * ONE_SECOND,
    NEW_UNREAD_MESSAGES: 30 * ONE_SECOND,
};

export type TDismissFlash = (flash: IFlashMessage<{}>) => void;

interface IPublicProps {
    flash: IFlashMessage<{}>;
    dismissFlash: TDismissFlash;
}

interface IPrivateProps {
    triggerLogout: () => void;
    extendSession: () => void;
}

interface IComponentState {
    animateIn: boolean;
    animateOut: boolean;
}

const CLASS_NAME = 'Flash';

class FlashMessage extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    private setShowTimeout: number;

    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            animateIn: false,
            animateOut: false,
        };

        this.renderFlash = this.renderFlash.bind(this);
        this.onCloseFlash = this.onCloseFlash.bind(this);
        this.dismissFlash = this.dismissFlash.bind(this);
        this.renderLinkToRoute = this.renderLinkToRoute.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.calculateRemainingHideDelayInMillis = this.calculateRemainingHideDelayInMillis.bind(this);
    }

    private clearShowTimeout() {
        if (this.setShowTimeout) {
            window.clearTimeout(this.setShowTimeout);
        }
    }

    public componentDidMount() {
        this.setShowTimeout = window.setTimeout(
            () => {
                this.setState({
                    animateIn: true,
                });
            },
            500);
    }

    public componentWillUnmount() {
        this.clearShowTimeout();
    }

    render() {
        const { flash, triggerLogout } = this.props;

        switch (flash.type) {
            case FlashMessageType.DOWNLOAD_TRIGGERED_TO_MESSAGE_CENTER: {
                return this.renderFlash({
                    icon: {
                        type: 'download-file',
                    },
                    title: {
                        translationKey: `${TRANSLATION_PREFIX}.download_triggered_to_message_center.title`,
                    },
                    message: {
                        translationKey: `${TRANSLATION_PREFIX}.download_triggered_to_message_center.message`,
                    },
                });
            }

            case FlashMessageType.SESSION_EXPIRE_WARNING: {
                const flashData = flash.data as IFlashDataSessionAboutToExpire;

                return this.renderFlash({
                    maxDelayInMillis: 0,
                    keepOnScreen: true,
                    hideCloseIcon: true,
                    icon: {
                        type: 'warning',
                    },
                    title: {
                        translationKey: `${TRANSLATION_PREFIX}.session_expire_warning.title`,
                    },
                    message: {
                        translationKey: `${TRANSLATION_PREFIX}.session_expire_warning.message`,
                        placeholders: {
                            sessionEndTime: formatTimeOfDateForDisplay(flashData.sessionEndTime),
                        },
                    },
                    buttonConfig: {
                        triggerTime: flash.triggerTime,
                        cancel: {
                            translationKey: `${TRANSLATION_PREFIX}.session_expire_warning.log_out`,
                            onClick: triggerLogout,
                        },
                        confirm: {
                            translationKey: `${TRANSLATION_PREFIX}.session_expire_warning.stay_logged_in`,
                            onClick: () => {},
                        },
                    },
                });
            }

            case FlashMessageType.NEW_UNREAD_MESSAGES: {
                const flashData = flash.data as IFlashDataCount;

                return this.renderFlash({
                    maxDelayInMillis: HIDE_DELAY_IN_MILLIS.NEW_UNREAD_MESSAGES,
                    icon: {
                        type: 'message',
                        notificationCount: flashData.count,
                    },
                    title: {
                        translationKey: `${TRANSLATION_PREFIX}.new_unread_messages.title`,
                    },
                    message: {
                        translationKey: `${TRANSLATION_PREFIX}.new_unread_messages.message`,
                        placeholders: {
                            count: flashData.count,
                            link: this.renderLinkToRoute(
                                ROUTE_KEYS.R_MESSAGE_CENTER,
                                `${TRANSLATION_PREFIX}.new_unread_messages.link`,
                            ),
                        },
                    },
                });
            }

            case FlashMessageType.NEW_APP_VERSION: {
                return this.renderFlash({
                    maxDelayInMillis: 0,
                    keepOnScreen: true,
                    hideCloseIcon: false,
                    icon: {
                        type: 'warning',
                    },
                    title: {
                        translationKey: `${TRANSLATION_PREFIX}.new_app_version.title`,
                    },
                    message: {
                        translationKey: `${TRANSLATION_PREFIX}.new_app_version.message`,
                    },
                    buttonConfig: {
                        triggerTime: flash.triggerTime,
                        confirm: {
                            translationKey: `${TRANSLATION_PREFIX}.new_app_version.refresh_app`,
                            onClick: () => reloadPage({ reloadFromServer: true }),
                        },
                    },
                });
            }
        }

        /* Unexpected type */
        console.log(`Unexpected flash message of type "${flash.type}"`, flash);
        return null;
    }

    private renderFlash({
        maxDelayInMillis = HIDE_DELAY_IN_MILLIS.DEFAULT,
        icon,
        title,
        message,
        keepOnScreen,
        buttonConfig,
        hideCloseIcon,
    }: {
        maxDelayInMillis?: number;
        keepOnScreen?: boolean;
        buttonConfig?: {
            triggerTime: number;
            confirm: {
                translationKey: string;
                onClick: () => void;
            };
            cancel?: {
                translationKey: string;
                onClick: () => void;
            }
        }
        hideCloseIcon?: boolean;
        icon: {
            type: TIconTypeName;
            notificationCount?: number;
        };
        title: {
            translationKey: string;
        };
        message: {
            translationKey: string;
            placeholders?: TPlaceholders;
        };
    }) {
        const hideDelayInMillis = this.calculateRemainingHideDelayInMillis({ maxDelayInMillis });
        const { animateIn, animateOut } = this.state;

        const onConfirmClick = (buttonConfig && buttonConfig.confirm)
            ? (e: MouseEvent<HTMLElement>) => {
                this.onCloseFlash(e);
                buttonConfig.confirm.onClick();
            }
            : null;

        return (
            <HideAfterDelay enabled={!keepOnScreen} hideDelay={hideDelayInMillis} onHide={this.dismissFlash}>
                <div
                    className={classNames(CLASS_NAME, {
                        ['is-animating-in']: animateIn,
                        ['is-animating-out']: animateOut,
                    })}
                    onTransitionEnd={this.onTransitionEnd}
                >
                    <div className={`${CLASS_NAME}__inner`}>
                        <div className={`${CLASS_NAME}__icon`}>
                            <div className={`${CLASS_NAME}__icon-wrapper`}>
                                <Icon typeName={icon.type} />
                                {icon.notificationCount && icon.notificationCount > 0 &&
                                    <Notification count={icon.notificationCount} />
                                }
                            </div>
                        </div>
                        <div className={`${CLASS_NAME}__content`}>
                            {title &&
                                <div className={`${CLASS_NAME}__title`}>
                                    <Translate msg={title.translationKey} />
                                </div>
                            }
                            {message &&
                                <div className={`${CLASS_NAME}__text`}>
                                    <Translate msg={message.translationKey} placeholders={message.placeholders} />
                                </div>
                            }
                            {buttonConfig &&
                                <div className={`${CLASS_NAME}__actions`}>
                                    <Button
                                        id={`${buttonConfig.triggerTime}_confirm`}
                                        onClick={onConfirmClick}
                                        typeName="primary"
                                    >
                                        <Translate msg={buttonConfig.confirm.translationKey} />
                                    </Button>
                                    {buttonConfig.cancel &&
                                        <Button
                                            id={`${buttonConfig.triggerTime}_cancel`}
                                            onClick={buttonConfig.cancel.onClick}
                                            typeName="text"
                                        >
                                            <Translate msg={buttonConfig.cancel.translationKey}/>
                                        </Button>
                                    }
                                </div>
                            }
                        </div>
                        {!hideCloseIcon &&
                            <div className={`${CLASS_NAME}__close-icon`} onClick={this.onCloseFlash}>
                                <Icon typeName="cross" />
                            </div>
                        }
                    </div>
                </div>
            </HideAfterDelay>
        );
    }

    private onCloseFlash(e: MouseEvent<HTMLElement>) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            animateOut: true,
        });
    }

    private onTransitionEnd() {
        const { animateOut } = this.state;

        if (animateOut) {
            this.dismissFlash();
        }
    }

    private dismissFlash() {
        const { flash, extendSession } = this.props;

        if (flash.type === FlashMessageType.SESSION_EXPIRE_WARNING) {
            extendSession();
        }

        this.props.dismissFlash(this.props.flash);
    }

    private calculateRemainingHideDelayInMillis({ maxDelayInMillis }: { maxDelayInMillis: number }) {
        // console.log('maxDelayInMillis', maxDelayInMillis);
        const alreadyPassedInMillis = now().getTime() - this.props.flash.triggerTime;
        let remainingDelayInMillis = maxDelayInMillis - alreadyPassedInMillis;
        // console.log('remainingDelayInMillis', remainingDelayInMillis);
        if (remainingDelayInMillis < 0) {
            remainingDelayInMillis = ONE_SECOND;
            // console.log('remainingDelayInMillis adjusted', remainingDelayInMillis);
        }
        return Math.min(maxDelayInMillis, remainingDelayInMillis);
    }

    private renderLinkToRoute(to: ROUTE_KEYS, linkTranslationKey: string) {
        return (
            <LinkToRoute
                id={`FlashMessageLink-to-${to}`}
                to={to}
                onClick={this.dismissFlash}
            >
                <Translate msg={linkTranslationKey} />
            </LinkToRoute>
        );
    }
}

export default connect({
    dispatchProps: (dispatch, getState) => {
        return {
            extendSession: () => {
                dispatch(updateSessionEndTime());
                dispatch(extendBackEndSession());
            },
            triggerLogout: () => {
                dispatch(redirectToLogout({
                    locationState: getLocationState(getState()),
                }));
            },
        };
    },
})(FlashMessage);
