import React, { PureComponent } from 'react';
import { clone } from 'ramda';

import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../../common/list/ListWithSorting';
import { COLUMNS, BASE_NAME, DEFAULT_NR_OF_RECORDS_TO_SHOW } from '../planned.const';
import { INITIAL_SORT } from '../planned.type';

import { TPlannedCompanyVisitsListProps, TPlannedCompanyVisitListColumns } from './PlannedCompanyVisitsList.type';

class PlannedCompanyVisitsList extends PureComponent<TPlannedCompanyVisitsListProps> {
    private columns: TPlannedCompanyVisitListColumns = clone(COLUMNS);

    constructor(props: TPlannedCompanyVisitsListProps) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
            />
        );
    }
}

export { PlannedCompanyVisitsList };
