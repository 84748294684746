export const FETCH_PLANNED_MEDICAL_EXAMINATIONS = 'FETCH_PLANNED_MEDICAL_EXAMINATIONS';
export const FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL = 'FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL';

export const FETCH_EXECUTED_MEDICAL_EXAMINATIONS = 'FETCH_EXECUTED_MEDICAL_EXAMINATIONS';
export const FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL = 'FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL';

export const FETCH_RESERVED_MEDICAL_EXAMINATIONS = 'FETCH_RESERVED_MEDICAL_EXAMINATIONS';
export const FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL = 'FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL';

export const FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS = 'FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS';

export const FETCH_MEDICAL_EXAMINATION_DOCUMENTS = 'FETCH_MEDICAL_EXAMINATION_DOCUMENTS';
export const FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES = 'FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES';

export const FETCH_MEDICAL_EXAMINATIONS_TO_PLAN = 'FETCH_MEDICAL_EXAMINATIONS_TO_PLAN';
export const FETCH_EXAMINATION_REASONS = 'FETCH_EXAMINATION_REASONS';
export const UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY = 'UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY';
export const CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY = 'CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY';
export const RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY = 'RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY';
export const REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY = 'REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY';
export const REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY = 'REMOVE_UPLOADED_FILE_FROM_ENTITY';
export const REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY = 'REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY';

export const FETCH_EMPLOYEES_TO_PLAN = 'FETCH_EMPLOYEES_TO_PLAN';

export const ADD_TIMESLOT = 'ADD_TIMESLOT';
export const UPDATE_TIMESLOT = 'UPDATE_TIMESLOT';
export const REMOVE_TIMESLOT = 'REMOVE_TIMESLOT';
export const AUTO_PLAN_EMPLOYEES = 'AUTO_PLAN_EMPLOYEES';

export const FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS = 'FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS';
export const FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS = 'FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS';

export const SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP = 'SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP';

export const CREATE_RE_INTEGRATION_REQUEST = 'CREATE_RE_INTEGRATION_REQUEST';

export const VALIDATE_EMPLOYEE_TO_PLAN = 'VALIDATE_EMPLOYEE_TO_PLAN';

export const REQUEST_MEDICAL_EXAMINATION = 'REQUEST_MEDICAL_EXAMINATION';
