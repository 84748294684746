import React from 'react';
import './contact-dashboard.scss';
import { connect } from '../../index';
import PageHeader from '../../appShell/PageHeader';
import { IContactItem } from '../../../models/general/navigation';
import { CONTACT_ITEMS } from '../../../config/navigation/contact.config';
import LinkToRoute from '../../common/navigation/LinkToRoute/index';
import Tile from '../../common/widget/Tile';
import Translate from '../../common/Translate';
import ContactDetail from '../ContactDetail';
import ContactInformation from '../shared/ContactInformation';
import {
    getCaseManager, getCaseManagerAsyncInfo, getMensuraContactsAsyncInfo, getMensuraContactsForSeatSorted,
} from '../../../redux/contact/selectors';
import { ICaseManager } from '../../../models/contact/caseManager';
import TinyLoader from '../../common/waiting/TinyLoader';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import {
    getCompanySeatsForSelectedCompany,
    isAllSeatsSelected,
    getSelectedSeatCompanyCode,
} from '../../../redux/company/selected/selectors';
import { ICompanySeat } from '../../../models/admin/company';
import MensuraContactsCollapsible from './MensuraContactsCollapsible';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import Loader from '../../common/waiting/Loader';
import { IMensuraContactPerson } from '../../../models/admin/companyInfo';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const getAllowedContactItems = makeAllowedNavigationItemsMemoizedSelector<IContactItem>(
    CONTACT_ITEMS,
);

interface IPrivateProps {
    allowedContactItems: IContactItem[];
    caseManager: ICaseManager;
    seats: ICompanySeat[];
    contactsAsyncInfo: IAsyncFieldInfo;
    mensuraContactsForSeat: IMensuraContactPerson[];
    isAllSeatsSelected: boolean;
}

function ContactDashboard(props: IPrivateProps) {
    const {
        caseManager,
        allowedContactItems,
        seats,
        contactsAsyncInfo,
        isAllSeatsSelected,
        mensuraContactsForSeat,
    } = props;

    return (
        <div className="ContactDashboard">
            <Loader show={contactsAsyncInfo.status} useFullScreen={true} positionFixed={true} />
            <div className="tile-dashboard">
                <PageHeader
                    title="contact.overview.title"
                    breadcrumbs={true}
                />
                <div className="container">
                    <div className="sections-wrapper">
                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems
                                    allowedContactItems={allowedContactItems}
                                />
                            </div>
                        </div>
                        <ShowIfAllowed requiredAccessLevels={{ company: 'R' }}>
                            <div className="section-sub">
                                <div className="section-inner">
                                    <div className="tile-wrapper">
                                        <div className="Tile">
                                            <TinyLoader asyncInfoSelector={getCaseManagerAsyncInfo}>
                                                <ContactInformation
                                                    contact={caseManager}
                                                    contactType={
                                                        <Translate msg="contact.overview.contacts.case_manager" />
                                                    }
                                                    showMobilePhone={true}
                                                />
                                            </TinyLoader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ShowIfAllowed>
                    </div>
                </div>
            </div>
            <ShowIfAllowed requiredAccessLevels={{ company: 'R' }}>
                <div className="container">
                    <div className="ContactDashboard__contacts">
                        <h2><Translate msg="contact.overview.contacts.title" /></h2>
                        <p><Translate msg="contact.overview.contacts.description" /></p>
                        {renderMensuraContacts()}
                    </div>
                </div>
            </ShowIfAllowed>
            <ContactDetail />
        </div>
    );

    function renderMensuraContacts() {
        if (isAllSeatsSelected) {
            return (
                seats.map((seat) => (
                    <MensuraContactsCollapsible
                        key={seat.company.companyCode}
                        seat={seat}
                    />
                ))
            );
        }

        return (
            <div className="ContactDashboard__contacts__list">
                {mensuraContactsForSeat.map((contact: IMensuraContactPerson, index) => (
                    <ContactInformation
                        key={`person-${index}`}
                        contact={contact}
                        showMobilePhone={false}
                    />
                ))}
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            allowedContactItems: getAllowedContactItems(state),
            caseManager: getCaseManager(state),
            seats: getCompanySeatsForSelectedCompany(state),
            contactsAsyncInfo: getMensuraContactsAsyncInfo(state),
            isAllSeatsSelected: isAllSeatsSelected(state),
            mensuraContactsForSeat: getMensuraContactsForSeatSorted(state, getSelectedSeatCompanyCode(state)),
        };
    },
})(ContactDashboard);

function NavigationItems({ allowedContactItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedContactItems
                    .map((contactItem) => (
                        <div className="tile-wrapper" key={contactItem.id}>
                            <LinkToRoute
                                id={contactItem.id}
                                to={contactItem.linkTo}
                            >
                                <Tile
                                    title={contactItem.titleTranslationKey}
                                    description={contactItem.descriptionTranslationKey}
                                    lazyLoadSvg={{
                                        id: contactItem.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.CONTACT,
                                    }}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}
