import React from 'react';
import { IPagePartComponentProps } from '@snipsonian/react/es/components/pageParts/pageParts.models';
import './legal-centered-icon-pagepart.scss';

export interface ILegalCenteredIconPagePartData {
    icon?: {
        id: number;
        url: string; // '/uploads/media/5d31d53cb2c06/cookie-monster.svg'
    };
}

export default function LegalCenteredIconPagePart(props: IPagePartComponentProps<ILegalCenteredIconPagePartData>) {
    const { data } = props;

    return (
        <div className="LegalCenteredIconPagePart">
            { data.icon &&
                <figure>
                    <img src={data.icon.url} alt="" />
                </figure>
            }
        </div>
    );
}
