import { mixed } from 'yup';
import { ErrorTypes } from '../../../models/general/error';
import { parsePhoneNumber } from '../../formatting/formatPhone';

export const PHONE_NUMBER_REGEX = /(^\+?\s?[\d.\/\s]*\d$)|(^$)/;

export default () =>
    mixed()
        .nullable(true)
        .test(
            ErrorTypes.InvalidPhoneNumber,
            'Invalid mobile phone',
            (value: string) => {
                if (!value) {
                    return true;
                }

                const parsedPhoneNumber = parsePhoneNumber(value);

                /* If a value was entered but we were unable to parse it, return false */
                if (!parsedPhoneNumber) {
                    return false;
                }

                return parsedPhoneNumber.isValid();
            },
        );
