import { DayElement, Instance } from 'flatpickr/dist/types/instance';
import { Plugin } from 'flatpickr/dist/types/options';
import getWeekNumber from '../../../../../utils/core/date/getWeekNumber';

interface IConfig {
    isEnabled: () => boolean;
}

function customWeekSelectPlugin(config: IConfig): Plugin<{}> {
    // eslint-disable-next-line func-names
    return function (fp) {

        function renderCustomDay(dates: Date[], currentDateString: string, fp: Instance, dayElem: DayElement) {
            const isEnabled = config.isEnabled();
            if (isEnabled && dayElem && fp.days) {
                const selectedDate = fp.selectedDates[0] || new Date();
                const currentWeekNumber = getWeekNumber(selectedDate);
                if (getWeekNumber(dayElem.dateObj) === currentWeekNumber) {
                    dayElem.classList.add('selected');
                }

                // Wait for last day to render, to highlight weeknr
                const days = [].slice.call(fp.days.childNodes);
                const lastDayElem = days.pop();
                if (lastDayElem.dateObj.getTime() === dayElem.dateObj.getTime()) {
                    Array.prototype.find.call(fp.weekNumbers.childNodes, (node) => {
                        if (parseInt(node.innerHTML, 10) === currentWeekNumber) {
                            node.classList.add('selected');
                            node.classList.add('selected-week');
                        }
                    });
                }
            }
        }

        return {
            onDayCreate: renderCustomDay,
        };
    };
}

export default customWeekSelectPlugin;
