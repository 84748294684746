import { object } from 'yup';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    name: 'name',
};

export const schema = object({
    [fields.name]: string().required(),
});
