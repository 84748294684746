import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getLastCalledOn } from './requestLogOfSuccessfulGets';
import getErrorStatus from './getErrorStatus';
import { isApiLoggingEnabled } from '../../config/develop.config';
import { startLog, endLog } from '../logging/consoleLogger';

export function logRequest(request: AxiosRequestConfig) {
    if (isApiLoggingEnabled) {
        try {
            startLog(`REQUEST ${request.method} ${request.url}`);

            console.log('request:', request);

            endLog();
        } catch (error) {
            console.log('Request could not be logged', error);
        }
    }
}

export function logResponse(result: AxiosResponse) {
    if (isApiLoggingEnabled) {
        try {
            startLog(`RESPONSE ${result.config.method} ${result.config.url} ${result.status}`);

            console.log('data:', result.data);

            endLog();
        } catch (error) {
            console.log('Response could not be logged', error);
        }
    }
}

export function logErrorResponse(error: AxiosError) {
    if (isApiLoggingEnabled) {
        try {
            const status = getErrorStatus(error);

            startLog(`ERROR-RESPONSE ${error.config.method} ${error.config.url} ${status}`);

            console.log('status:', status);
            console.log('code:', error.code);
            console.log('message:', error.message);
            console.log('response:', error.response);

            endLog();
        } catch (error) {
            console.log('ErrorResponse could not be logged', error);
        }
    }
}

export function logCancelledRequest(error: AxiosError) {
    if (isApiLoggingEnabled) {
        console.log('REQUEST CANCELLED');
    }
}

export function logNoRefresh(request: AxiosRequestConfig, noRefreshPeriodInMillis: number) {
    if (isApiLoggingEnabled) {
        try {
            startLog(`NO-REFRESH ${request.method} ${request.url}`);

            const lastCalledOn = getLastCalledOn(request);

            console.log('noRefreshPeriod (millis):', noRefreshPeriodInMillis);
            console.log('last called on (millis):', lastCalledOn);
            console.log('last called on (date):', formatMillisAsDate(lastCalledOn));

            endLog();
        } catch (error) {
            console.log('NoRefresh could not be logged', error);
        }
    }
}

function formatMillisAsDate(millis: number) {
    return (new Date(millis)).toString();
}
