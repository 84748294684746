import React from 'react';
import isFunction from '@snipsonian/core/es/is/isFunction';
import { connect } from '../../index';
import { ITopTask, ITopTaskTargetRouteKey, TTopTaskTargetActionCreator } from '../../../models/user/topTasks';
import { getTopTasksAsyncInfo, getTopTasksMemoized } from '../../../redux/navigation/selectors';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import TinyLoader from '../../common/waiting/TinyLoader';
import { navigateTo } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { getStore } from '../../../redux/storeNoCircularDependencies';
import { ILocationAction } from '../../../models/general/redux';
import { isDraggableSupported } from '../../../utils/browser/draggable';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';
import LazyLoadSVG from '../../common/widget/LazyLoadSVG';

interface IPrivateProps {
    topTasks: ITopTask[];
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        topTasks: getTopTasksMemoized(state),
    }),
})(TopTasksDashboardBlock);

function TopTasksDashboardBlock({ topTasks }: IPrivateProps) {
    return (
        <div className="section section--tasks">
            <header className="header">
                <div>
                    <h4><Translate msg="home.dashboard.tasks.title" /></h4>
                    {isDraggableSupported &&
                        <LinkToRoute
                            id="update-top-tasks-link"
                            to={ROUTE_KEYS.R_EDIT_TOP_TASKS}
                            className="icon-link update-top-tasks"
                        >
                            <span><Translate msg="home.dashboard.tasks.link_all" /></span>
                            <Icon typeName="chevron-right"/>
                        </LinkToRoute>
                    }
                </div>
                {isDraggableSupported &&
                    <div className="update-top-tasks">
                        <Button id="update-top-tasks-button" onClick={navigateToEditTopTasks}>
                            <span><Translate msg="home.dashboard.tasks.link_change_top_tasks" /></span>
                            <Icon typeName="pencil" />
                        </Button>
                    </div>
                }
            </header>
            <div className="content">
                <TinyLoader asyncInfoSelector={getTopTasksAsyncInfo}>
                    {
                        topTasks.map((topTask) => (
                            <LinkToRoute
                                id={`top-task_${topTask.id}-link`}
                                key={`top-task_${topTask.id}`}
                                to={getTaskLinkTo(topTask)}
                                className="task"
                            >
                                <LazyLoadSVG id={topTask.lazyLoadSvgId} group={SVG_GROUP_NAME.TOP_TASKS} />
                                <span>
                                    <Translate msg={topTask.translationKey} />
                                </span>
                                <Icon typeName="chevron-right" />
                            </LinkToRoute>
                        ))
                    }
                </TinyLoader>
            </div>
        </div>
    );
}

function navigateToEditTopTasks() {
    const store = getStore();
    store.dispatch(navigateTo(ROUTE_KEYS.R_EDIT_TOP_TASKS));
}

function getTaskLinkTo(topTask: ITopTask): ILocationAction<{}> {
    return isFunction(topTask.targetRoute) ?
        (topTask.targetRoute as TTopTaskTargetActionCreator)() :
        {
            type: (topTask.targetRoute as ITopTaskTargetRouteKey).key,
            payload: (topTask.targetRoute as ITopTaskTargetRouteKey).payload || {},
        };
}
