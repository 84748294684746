import React, { ReactElement, ReactNode } from 'react';
import ErrorPlaceholder from '../../../error/ErrorPlaceholder';
import Alert from '../../Alert';
import Translate from '../../../Translate';
import { AsyncStatus, IAsyncFieldInfo, IAction } from '../../../../../models/general/redux';
import { IRenderDetailHeaderProps } from '../typings';
import { IState } from '../../../../../redux';

interface IDetailPanelHeaderProps {
    renderHeader: (props: IRenderDetailHeaderProps) => ReactElement<{ children: ReactNode }>;
    detailAsyncInfo: IAsyncFieldInfo;
    detailData: object;
    detailUpdateAsyncInfo: IAsyncFieldInfo;
    detailUpdateInfo?: {
        updateAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
        updateAsyncInfoResetAction: IAction<{}>;
        successTranslationKey?: string;
    };
    detailLevel?: number;
}

export default function DetailPanelHeader(props: IDetailPanelHeaderProps) {
    const {
        renderHeader, detailAsyncInfo, detailData, detailUpdateAsyncInfo, detailUpdateInfo, detailLevel,
    } = props;
    const successTranslationKey = (
        (detailUpdateInfo && detailUpdateInfo.successTranslationKey) ||
        'common.master_with_detail.update.success'
    );
    return (
        <>
            {renderHeader({ detailLevel, detailAsyncInfo, detailData, detailUpdateAsyncInfo })}
            {detailAsyncInfo && detailAsyncInfo.error &&
                <ErrorPlaceholder apiError={detailAsyncInfo.error} />
            }
            {detailUpdateAsyncInfo && detailUpdateAsyncInfo.status === AsyncStatus.Success &&
                <Alert type="success">
                    <Translate msg={successTranslationKey} />
                </Alert>
            }
        </>
    );
}
