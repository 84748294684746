import { head } from 'ramda';

import {
    getBufferzonesWithinPlanningCalendarRangeMemoized,
    getCompanyBufferzonesForMedicalCenterCode,
} from '../../../../../../redux/medicalExamination/selectors';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { IBufferzoneMedicalCenter } from '../../../../../../models/admin/companyInfo';
import { IMedicalCenter } from '../../../../../../models/admin/employee';
import {
    IMedicalExaminationTimeSlot,
    MedicalExaminationTimeSlotType,
} from '../../../../../../models/interventions/medicalExaminations';
import { IState } from '../../../../../../redux/IState';
import {
    mapBufferzoneToBufferzoneMedicalCenter,
} from '../../../../../administration/Employees/shared/LocationTypeahead/LocationTypeahead.helper';

export const getAccurateMedicalExaminationLocation = (
    timeSlot: IMedicalExaminationTimeSlot,
    medicalCenterCode: string,
    state: IState,
): IMedicalCenter | IBufferzoneMedicalCenter => {
    const bufferzones = getBufferzonesWithinPlanningCalendarRangeMemoized(state);

    // Can timeSlot be found on a Bufferzone?
    if (timeSlot.type === MedicalExaminationTimeSlotType.Reserved) {
        const selectedBufferzone = bufferzones.find((bufferzone) => {
            return bufferzone.reservedTimeSlots.some((ts) => timeSlot.id === ts.id);
        });

        if (selectedBufferzone) {
            return mapBufferzoneToBufferzoneMedicalCenter(selectedBufferzone);
        }
    }

    const medicalCenter = getCompanyMedicalCenters(state).find(
        (medicalCenter) => medicalCenter.code === medicalCenterCode,
    );

    if (medicalCenter) {
        return medicalCenter;
    }

    if (!medicalCenter && medicalCenterCode) {
        // When selectedMedicalCenterData isn't set, it is probably a cabinet/location which doesn't
        // exist in de list of medical-centers. In this case we try to find the medicalCenter in
        // the list of bufferzones.
        const firstBufferZone = head(getCompanyBufferzonesForMedicalCenterCode(state, medicalCenterCode));

        if (firstBufferZone) {
            return mapBufferzoneToBufferzoneMedicalCenter(firstBufferZone);
        }
    }

    return null;
};
