import React, { Component } from 'react';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import { IScreenworkQuestionnaire } from '../../../../models/admin/questionnaires';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { schema, fields } from './screenworkSchema';
import {
    ConfirmableTextPropertyInput,
    ConfirmableConstantsTypeaheadPropertyInput,
} from '../../../common/input/ConfirmablePropertyInput';
import { getUpdateEmployeeAsyncInfo } from '../../../../redux/employee/info/selectors';
import { TextPropertyInput } from '../../../common/input/PropertyInput';
import { connect } from '../../..';
import { getRoutePayload } from '../../../../redux/location/selectors';
import { formatDateWithTimeForDisplay } from '../../../../utils/formatting/formatDate';
import { TEmployeeUpdateFields } from '../../../../models/admin/employee';
import { updateEmployee } from '../../../../redux/employee/info/actions';
import { getUpdateEmployeeRequestId } from '../../Employees/EmployeeDetails';
import { ConstantType } from '../../../../models/general/constants';
import DetailItem from '../../../common/widget/DetailItem';
import { QuestionnaireLabelDateItem } from '../shared';

const FORM_NAME = 'questionnaires-screenwork-detail-form';
const TRANSLATION_PREFIX = 'administration.questionnaires.screenwork.detail';

export interface FormValues {
    email: string;
    languageId: string;
    function: string;
    seat: string;
}

interface IPrivateProps {
    selectedDetailId: number;
    updateEmployeeField: (
        nationalRegisterNumber: string,
        companyCode: string,
        fieldName: keyof TEmployeeUpdateFields,
        value: string,
    ) => void;
}

class DetailComp extends Component<IPrivateProps & IRenderDetailContentProps<IScreenworkQuestionnaire>> {
    private resetForm: (values: FormValues) => void;

    constructor(props: IPrivateProps & IRenderDetailContentProps<IScreenworkQuestionnaire>) {
        super(props);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IRenderDetailContentProps<IScreenworkQuestionnaire>) {
        if (prevProps.selectedDetailId !== this.props.selectedDetailId) {
            this.resetForm(this.createInitialValuesFromDetailData(this.props.detailData));
        }
    }

    public render() {
        const {
            detailData,
            updateEmployeeField,
        } = this.props;

        if (!detailData) {
            return null;
        }

        const { employee } = detailData;

        const INITIAL_VALUES: FormValues = this.createInitialValuesFromDetailData(detailData);

        return (
            <div>
                <DetailItem>
                    <h2>{formatPersonNameFormal(detailData.employee)}</h2>
                    <Form
                        name={FORM_NAME}
                        schema={schema}
                        initialValues={INITIAL_VALUES}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values, errors, handleChange, resetForm, setFieldValue } = formRenderProps;

                            this.resetForm = resetForm;

                            function onLanguageItemSelected(value: number) {
                                setFieldValue('languageId', value);
                            }

                            return (
                                <div>
                                    <ConfirmableTextPropertyInput
                                        id="email"
                                        name={fields.email}
                                        labelKey={`${TRANSLATION_PREFIX}.form.placeholder.email`}
                                        readonly={true}
                                        value={values.email}
                                        onChange={handleChange}
                                        initialValue={INITIAL_VALUES.email}
                                        asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                                        requestId={getUpdateEmployeeRequestId(
                                            employee.nationalRegisterNumber,
                                            fields.email,
                                        )}
                                        onSave={() => updateEmployeeField(
                                            employee.nationalRegisterNumber,
                                            employee.company.companyCode,
                                            'email',
                                            values.email,
                                        )}
                                        validationError={{
                                            error: errors.email,
                                            fieldNameTranslationKey: `${TRANSLATION_PREFIX}.form.placeholder.email`,
                                        }}
                                    />
                                    <ConfirmableConstantsTypeaheadPropertyInput
                                        id="languageId"
                                        name={fields.languageId}
                                        labelKey={`${TRANSLATION_PREFIX}.form.placeholder.language`}
                                        readonly={true}
                                        value={values.languageId}
                                        initialValue={INITIAL_VALUES.languageId}
                                        asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                                        constantType={ConstantType.LANGUAGES}
                                        onItemSelected={onLanguageItemSelected}
                                        requestId={getUpdateEmployeeRequestId(
                                            employee.nationalRegisterNumber,
                                            fields.languageId,
                                        )}
                                        onSave={() => updateEmployeeField(
                                            employee.nationalRegisterNumber,
                                            employee.company.companyCode,
                                            'languageId',
                                            values.languageId,
                                        )}
                                        validationError={{
                                            error: errors.languageId,
                                            fieldNameTranslationKey: `${TRANSLATION_PREFIX}.form.placeholder.language`,
                                        }}
                                    />
                                    <TextPropertyInput
                                        id="function"
                                        name={fields.function}
                                        labelKey={`${TRANSLATION_PREFIX}.form.placeholder.function`}
                                        readonly={true}
                                        value={values.function}
                                    />
                                    <TextPropertyInput
                                        id="seat"
                                        name={fields.seat}
                                        labelKey={`${TRANSLATION_PREFIX}.form.placeholder.seat`}
                                        readonly={true}
                                        value={values.seat}
                                    />
                                </div>
                            );
                        }}
                    />
                </DetailItem>
                <DetailItem>
                    <QuestionnaireLabelDateItem
                        labelKey={`${TRANSLATION_PREFIX}.send_date`}
                        date={formatDateWithTimeForDisplay(detailData.dateFrom)}
                    />
                </DetailItem>
            </div>
        );
    }

    private createInitialValuesFromDetailData(detailData: IScreenworkQuestionnaire): FormValues {
        return {
            email: detailData && detailData.employee && detailData.employee.email,
            function: detailData && detailData.employee &&
                detailData.employee.function && detailData.employee.function.description,
            languageId: detailData && detailData.languageId,
            seat: detailData && detailData.employee && detailData.employee.company && detailData.employee.company.name,
        };
    }
}

export const Detail = connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            selectedDetailId: getRoutePayload<{ id: number }>(state).id,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateEmployeeField: (
                nationalRegisterNumber: string,
                companyCode: string,
                fieldName: keyof TEmployeeUpdateFields,
                value: string,
            ) => {
                dispatch(updateEmployee({
                    requestId: getUpdateEmployeeRequestId(nationalRegisterNumber, fieldName),
                    nationalRegisterNumber,
                    employeeData: {
                        [fieldName]: value,
                    },
                    companyCode,
                }));
            },
        };
    },
})(DetailComp);
