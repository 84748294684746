import React, { Component } from 'react';
import connect from '../../../../../../../utils/libs/redux/connect';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../models/general/redux';
import {
    getPlanMedicalExaminationWizardEntity,
    getAutoPlanEmployeesAsyncInfo,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../../redux/medicalExamination/selectors';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IExaminationReason,
    IMedicalExaminationToAdd,
    ITriggerPlanMedicalExaminationWizardProps,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../../../../../models/interventions/medicalExaminations';
import {
    autoPlanEmployees,
    navigateToPlanMedicalExaminationWizardStep,
    updatePlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/actions';
import PageHeader from '../../../../../../appShell/PageHeader';
import {
    mapMedExamsToAutoPlanPayload,
} from '../../../../../../../utils/interventions/medicalExaminations/mapMedExamsToAutoPlanPayload';
import Loader from '../../../../../../common/waiting/Loader';
import DatePicker from '../../../../../../common/widget/DateTimePicker/DatePicker';
import classNames from 'classnames';
import { formatDateForBackend } from '../../../../../../../utils/formatting/formatDate';
import { dayOffsetFromNow } from '../../../../../../../utils/core/date/getSpecificDate';
import Translate from '../../../../../../common/Translate';
import Button from '../../../../../../common/buttons/Button';
import { ITranslator } from '../../../../../../../models/general/i18n';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import { getTranslatorDeprecated } from '../../../../../../../redux/i18n/selectors';
import FormError from '../../../../../../common/forms/FormError';
import { WIZARDFLOW_CLASSES } from '../../../../../../common/navigation/Wizard/index';

const MINIMUM_DATE = formatDateForBackend(dayOffsetFromNow(1));

interface IPrivateProps {
    currentStartDate: string;
    asyncInfo: IAsyncFieldInfo;
    dispatchUpdateEntityToSetCorrectInitialValues: (selectedDate: string) => void;
    dispatchAutoPlanEmployeesWithNewStartDate: (
        selectedDate: string,
        selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
    ) => void;
    triggerPlanMedicalExaminationWizard: (triggerProps: ITriggerPlanMedicalExaminationWizardProps) => void;
    examinationReason: IExaminationReason;
    translator: ITranslator;
}

interface IEditExaminationStartDate {
    onClose: () => void;
    selectedMedicalExaminations: IMedicalExaminationToAdd[];
}

interface IState {
    date: string;
}

type IProps = IPrivateProps & IEditExaminationStartDate;

class EditExaminationStartDate extends Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            date: props.currentStartDate || '',
        };

        this.onClickLinkHandler = this.onClickLinkHandler.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
    }

    public render() {
        const { asyncInfo, selectedMedicalExaminations, translator } = this.props;
        const { date } = this.state;
        return (
            <Loader show={asyncInfo.status === AsyncStatus.Busy}>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.validate_auto_plan.edit_day.title"
                    text="interventions.medical_examinations.new.steps.validate_auto_plan.edit_day.text"
                    textPlaceholders={{
                        manualPlan:
                            <span className="placeholderLink">
                                <a onClick={this.onClickLinkHandler}>
                                    <Translate
                                        msg="interventions.medical_examinations.new.steps.start_date.manual_plan"
                                    />
                                </a>
                            </span>,
                    }}
                    titlePlaceholders={{
                        employee: selectedMedicalExaminations.length > 1 ?
                            // eslint-disable-next-line max-len
                            translator('interventions.medical_examinations.new.steps.validate_auto_plan.all_employees') :
                            formatPersonName(selectedMedicalExaminations[0].employee),
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, WIZARDFLOW_CLASSES.CONTENT_DIALOG)}>
                    <div>
                        <DatePicker
                            id="medical-examination-start-date"
                            onChange={this.onChangeHandler}
                            value={date}
                            inlineCalendar={true}
                            hideTextInput={true}
                            minDate={MINIMUM_DATE}
                        />
                    </div>
                    {asyncInfo.error &&
                        <FormError error={asyncInfo.error} />
                    }
                    <div className={WIZARDFLOW_CLASSES.ACTIONS}>
                        <Button
                            id="medical-examination-time-confirm"
                            typeName="secondary"
                            onClick={this.onClickNextHandler}
                            disabled={!date}
                        >
                            <Translate msg="common.confirmation_dialog.continue" />
                        </Button>
                    </div>
                </div>
            </Loader>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.asyncInfo.status === AsyncStatus.Success &&
            prevProps.asyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onChangeHandler(formattedDate: string) {
        this.setState({
            date: formattedDate,
        });
    }

    private onClickLinkHandler() {
        this.props.triggerPlanMedicalExaminationWizard({
            wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
            reason: this.props.examinationReason,
        });
    }

    private onClickNextHandler() {
        const {
            dispatchAutoPlanEmployeesWithNewStartDate,
            selectedMedicalExaminations,
            dispatchUpdateEntityToSetCorrectInitialValues,
        } = this.props;
        const { date } = this.state;
        dispatchUpdateEntityToSetCorrectInitialValues(date);
        dispatchAutoPlanEmployeesWithNewStartDate(date, selectedMedicalExaminations);
    }
}

export default connect<IPrivateProps, IEditExaminationStartDate>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>(state);
        const asyncInfo = getAutoPlanEmployeesAsyncInfo(state);
        return {
            currentStartDate: entity.startDate,
            asyncInfo,
            examinationReason: getPlanMedicalExaminationWizardReason(state),
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            dispatchUpdateEntityToSetCorrectInitialValues: (selectedDate: string) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>({
                    startDate: selectedDate,
                }));
            },
            dispatchAutoPlanEmployeesWithNewStartDate: (
                selectedDate: string,
                selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
            ) => {
                const state = getState();

                const examinationReason = getPlanMedicalExaminationWizardReason(state) as IExaminationReason;
                const entity = getPlanMedicalExaminationWizardEntity(state) as IPeriodicHealthAssessmentAutomaticEntity;

                const updatedSelectedMedicalExaminationsToAdd =
                    addStartDateToExaminationsToAdd(selectedDate, selectedMedicalExaminationsToAdd);

                dispatch(autoPlanEmployees(mapMedExamsToAutoPlanPayload({
                    medicalExaminationsToAdd: updatedSelectedMedicalExaminationsToAdd,
                    entity,
                    examinationReason,
                })));
            },
            triggerPlanMedicalExaminationWizard:
                ({ wizardType, reason }: ITriggerPlanMedicalExaminationWizardProps) => {
                    dispatch(
                        navigateToPlanMedicalExaminationWizardStep({
                            wizardType,
                            reason,
                            resetDataEntity: false, // so that the selected employees remain selected
                        }),
                    );
                },
        };
    },
})(EditExaminationStartDate);

function addStartDateToExaminationsToAdd(
    selectedDate: string,
    selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
) {
    return selectedMedicalExaminationsToAdd.map((selectedExam) => {
        const newExaminationToAdd = { ...selectedExam };

        newExaminationToAdd.startDate = selectedDate;

        return newExaminationToAdd;
    });
}
