import React from 'react';
import './employee-block.scss';
import {
    IMedicalExaminationToAdd,
    IExaminationReason,
} from '../../../../../../models/interventions/medicalExaminations';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { connect } from '../../../../..';
import { getExaminationReasons } from '../../../../../../redux/medicalExamination/selectors';
import { getMedExamToAddId } from '../../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import DraggableWrapper from '../../../../../common/widget/dragAndDrop/DraggableWrapper';
import { createPrefixedDraggableId } from '../../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import { DraggablePrefix } from '../../../../../../models/interventions/timeslots';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../../config/planning.config';
import { getTextColorBasedOnBackgroundColor } from '../../../../../../utils/color/color';

const CLASS_NAME = 'EmployeeBlock';
const BASE_LINE_HEIGHT = 2;

interface IPublicProps {
    employee: IMedicalExaminationToAdd;
    index: number;
}

interface IPrivateProps {
    examinationReasons: IExaminationReason[];
}

function EmployeeBlock({
    employee,
    examinationReasons,
    index,
}: IPublicProps & IPrivateProps) {
    function getBlockColorsFromExaminationReason() {
        const examinationReason = examinationReasons.find((reason) =>
            reason.id === (employee.examinationReason && employee.examinationReason.id),
        );

        return examinationReason && examinationReason.colour && {
            backgroundColor: examinationReason.colour,
            textColor: getTextColorBasedOnBackgroundColor(examinationReason.colour),
        };
    }

    function getBlockHeight() {
        const duration = employee.duration || DEFAULT_MEDICAL_EXAMINATION_DURATION;
        const multiplier = Math.ceil(duration / DEFAULT_MEDICAL_EXAMINATION_DURATION);
        return BASE_LINE_HEIGHT * multiplier;
    }

    return (
        <DraggableWrapper
            draggableId={createPrefixedDraggableId(getMedExamToAddId(employee), DraggablePrefix.Employee)}
            index={index}
            render={() => {
                const blockColors = getBlockColorsFromExaminationReason();
                return (
                    <div
                        className={CLASS_NAME}
                        style={{
                            backgroundColor: blockColors && blockColors.backgroundColor,
                            color: blockColors && blockColors.textColor,
                            lineHeight: getBlockHeight(),
                        }}
                    >
                        <span>{formatPersonName(employee.employee)}</span>
                    </div>
                );
            }}
        />
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            examinationReasons: getExaminationReasons(state),
        };
    },
})(EmployeeBlock);
