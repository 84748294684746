import { NO_RERENDER, IState, getReducerState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector } from '..';
import { IReducerState, reducerKey } from './reducer';
import {
    TCompanySituationHistory,
    IAccomplishmentsContribution,
    IAccomplishmentsContributionDetail,
    IPreventionUnits,
    IFetchAccomplishmentsContributionDetailsPayload,
    IPreventionUnitNumbers,
    IPreventionUnitTask,
} from '../../models/admin/preventionUnits';
import {
    doesLatestYearIndicatePreventionUnits,
    doesLatestYearIndicateFlatFee,
    doesAtLeastOneYearIndicatePreventionUnits,
    doesAtLeastOneYearIndicateFlatFee,
    getFlatFeeYearsSortedDescending,
    getPreventionUnitsYearsSortedDescending,
} from './companySituationHistoryUtils';
import { getLocationState, getRouteKey } from '../location/selectors';
import ROUTE_KEYS from '../../routeKeys';
import { fromAccomplishmentsContributionDetailId } from './actions';
import { sumFloatsUpToSixDigitsAfterComma } from '../../utils/core/number/calculations';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Company situation history
const getCompanySituationHistoryAsyncField = (state: IState) =>
    reducerState(state).companySituationHistory;

export const getCompanySituationHistoryAsyncInfo = makeAsyncFetchInfoSelector(getCompanySituationHistoryAsyncField);

export const isCompanySituationHistoryAvailable = (state: IState) =>
    getCompanySituationHistoryAsyncField(state).data !== null
    && getCompanySituationHistoryAsyncField(state).data.length > 0;

export const getCompanySituationHistory = (state: IState) =>
    getCompanySituationHistoryAsyncField(state).data || (NO_RERENDER.EMPTY_LIST as TCompanySituationHistory);

export const doesCompanyCurrentlyUsesPreventionUnits = (state: IState) =>
    doesLatestYearIndicatePreventionUnits(
        getCompanySituationHistory(state),
    );

export const doesCompanyCurrentlyUsesFlatFee = (state: IState) =>
    doesLatestYearIndicateFlatFee(
        getCompanySituationHistory(state),
    );

export const didCompanyOnceUsePreventionUnits = (state: IState) =>
    doesAtLeastOneYearIndicatePreventionUnits(
        getCompanySituationHistory(state),
    );

export const didCompanyOnceUseFlatFee = (state: IState) =>
    doesAtLeastOneYearIndicateFlatFee(
        getCompanySituationHistory(state),
    );

export const getShowPreventionUnitsPreviousYearUntilDate = (state: IState) =>
    reducerState(state).showPreviousYearUntilDate;

// Accomplishments Contributions of selected year (Flat fee)

export const getFlatFeeYears = (state: IState) =>
    getFlatFeeYearsSortedDescending(
        getCompanySituationHistory(state),
    );

const getAccomplishmentsContributionsOfSelectedYearAsyncField = (state: IState) =>
    reducerState(state).accomplishmentsContributionsOfSelectedYear;

export const getAccomplishmentsContributionsOfSelectedYearAsyncInfo =
    makeAsyncFetchInfoSelector(getAccomplishmentsContributionsOfSelectedYearAsyncField);

export const areAccomplishmentsContributionsOfSelectedYearAvailable = (state: IState) =>
    getAccomplishmentsContributionsOfSelectedYearAsyncField(state).data !== null;

export const getAccomplishmentsContributionsOfSelectedYear = (state: IState) => {
    return getAccomplishmentsContributionsOfSelectedYearAsyncField(state).data
        || (NO_RERENDER.EMPTY_LIST as IAccomplishmentsContribution[]);
};

export const getFlatFeeSelectedYear = (state: IState) => {
    const year = reducerState(state).selectedFlatFeeYear;
    return year ? Number(year) : null;
};

// Accomplishments Contribution details (Flat fee)

const getAccomplishmentsContributionDetailsAsyncField = (state: IState) =>
    reducerState(state).accomplishmentsContributionDetails;

export const getAccomplishmentsContributionDetailsAsyncInfo =
    makeAsyncFetchInfoSelector(getAccomplishmentsContributionDetailsAsyncField);

export const getAccomplishmentsContributionDetails = (state: IState) =>
    getAccomplishmentsContributionDetailsAsyncField(state).data
    || (NO_RERENDER.EMPTY_LIST as IAccomplishmentsContributionDetail[]);

export const getFlatFeeSelectedType = (state: IState) => {
    const currentRoute = getLocationState(state);

    if (currentRoute.type !== ROUTE_KEYS.R_FLAT_FEE_DETAIL) {
        return null;
    }

    const { type } = fromAccomplishmentsContributionDetailId(
        (currentRoute.payload as IFetchAccomplishmentsContributionDetailsPayload).yearAndType,
    );

    return type;
};

export const getAccomplishmentsContributionThatWasSelected = (state: IState) => {
    const selectedType = getFlatFeeSelectedType(state);

    if (!selectedType) {
        return null;
    }

    const accomplishmentsContributions = getAccomplishmentsContributionsOfSelectedYear(state);

    if (!accomplishmentsContributions) {
        return null;
    }

    const selectedContribution = accomplishmentsContributions
        .find((contribution) => contribution.type === selectedType);

    return selectedContribution;
};

// Prevention units history
export const getPreventionUnitsYears = (state: IState) =>
    getPreventionUnitsYearsSortedDescending(
        getCompanySituationHistory(state),
    );

// Prevention units

const getPreventionUnitsAsyncField = (state: IState) =>
    reducerState(state).preventionUnits;

export const getPreventionUnitsAsyncInfo =
    makeAsyncFetchInfoSelector(getPreventionUnitsAsyncField);

export const arePreventionUnitsAvailable = (state: IState) =>
    getPreventionUnitsAsyncField(state).data !== null;

export const getPreventionUnits = (state: IState) => {
    if (!arePreventionUnitsAvailable(state)) {
        return NO_RERENDER.EMPTY_LIST as IPreventionUnits[];
    }

    return getPreventionUnitsAsyncField(state).data;
};

export const getTotalPreventionUnits: (state: IState) => IPreventionUnitNumbers = (state: IState) => {
    const preventionUnits = getPreventionUnits(state);

    const accumulatorBase: IPreventionUnitNumbers = {
        assigned: 0,
        invoiced: 0,
        planned: 0,
        registered: 0,
        remaining: 0,
        unused: 0,
    };

    if (preventionUnits.length > 0) {
        return preventionUnits.reduce(
            (accummulator, item) => {
                const units: IPreventionUnitNumbers = {
                    assigned: sumFloatsUpToSixDigitsAfterComma([accummulator.assigned, item.units.assigned]),
                    invoiced: sumFloatsUpToSixDigitsAfterComma([accummulator.invoiced, item.units.invoiced]),
                    planned: sumFloatsUpToSixDigitsAfterComma([accummulator.planned, item.units.planned]),
                    registered: sumFloatsUpToSixDigitsAfterComma([accummulator.registered, item.units.registered]),
                    remaining: sumFloatsUpToSixDigitsAfterComma([accummulator.remaining, item.units.remaining]),
                    unused: sumFloatsUpToSixDigitsAfterComma([accummulator.unused, item.units.unused]),
                };
                return units;
            },
            accumulatorBase,
        );
    }

    return null;
};

export const getPreventionUnitsGroupType = (state: IState) => reducerState(state).preventionUnitsGroupType || 'type';

// Prevention units detail

const getPreventionUnitsDetailAsyncField = (state: IState) =>
    reducerState(state).preventionUnitsDetail;

export const getPreventionUnitsDetailAsyncInfo =
    makeAsyncFetchInfoSelector(getPreventionUnitsDetailAsyncField);

export const isPreventionUnitsDetailAvailable = (state: IState) =>
    getPreventionUnitsDetailAsyncField(state).data !== null;

export const getPreventionUnitsDetail = (state: IState) => {
    if (!isPreventionUnitsDetailAvailable(state)) {
        return NO_RERENDER.EMPTY_LIST as IPreventionUnitTask[];
    }

    return getPreventionUnitsDetailAsyncField(state).data;
};

export const isPEOverviewRoute = (state: IState) => {
    const routeKey = getRouteKey(state);
    return routeKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW ||
        routeKey === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL;
};

// Prevention units export

const getExportPreventionUnitsAsyncField = (state: IState) =>
    reducerState(state).exportPreventionUnits;

export const getExportPreventionUnitsAsyncInfo = makeAsyncDoInfoSelector(getExportPreventionUnitsAsyncField);
