import { NO_RERENDER, IState, getReducerState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector } from '..';
import { IReducerState, reducerKey } from './reducer';
import { IOnboardingWizardData } from '../../models/onboarding/wizard';
import { CompanyAvailabilityType } from '../../models/admin/companyInfo';
import { NO_AVAILABILITIES } from '../company/info/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Onboarding wizard
const getOnboardingWizardData = (state: IState) =>
    (reducerState(state).onboardingWizardData || NO_RERENDER.EMPTY_OBJECT) as IOnboardingWizardData;

export const getOnboardingWizardStepId = (state: IState) =>
    getOnboardingWizardData(state).stepId;

export const getOnboardingWizardEntity = (state: IState) =>
    getOnboardingWizardData(state).entity;

// Onboarding availabilities
const getOnboardingSeatAvailabilitiesAsyncField = (state: IState) =>
    reducerState(state).fetchOnboardingSeatsAvailabilities;

export const getOnboardingSeatAvailabilitiesAsyncInfo =
    makeAsyncFetchInfoSelector(getOnboardingSeatAvailabilitiesAsyncField);

export const getOnboardingSeatAvailabilities = (state: IState, availabilityType: CompanyAvailabilityType) =>
    (availabilityType === CompanyAvailabilityType.MedicalExaminations) ?
        getOnboardingWizardEntity(state).medicalExaminationsAvailabilities :
        getOnboardingWizardEntity(state).riskManagementAvailabilities;

export const getOnboardingSeatAvailabilitiesByCompanyCode = (
    state: IState, availabilityType: CompanyAvailabilityType, companyCode: string,
) => {
    const availabilities = getOnboardingSeatAvailabilities(state, availabilityType);
    return availabilities ? availabilities[companyCode] || NO_AVAILABILITIES : NO_AVAILABILITIES;
};

// Copy risk mgmt availabilities to medexam availabilities
const getCopyOnboardingAvailabilitiesAsyncField = (state: IState) =>
    reducerState(state).copyOnboardingAvailabilities;

export const getCopyOnboardingAvailabilitiesAsyncInfo =
    makeAsyncDoInfoSelector(getCopyOnboardingAvailabilitiesAsyncField);

// Confirm onboarding wizard
const getConfirmOnboardingWizardAsyncField = (state: IState) =>
    reducerState(state).confirmOnboardingWizard;

export const getConfirmOnboardingWizardAsyncInfo =
    makeAsyncDoInfoSelector(getConfirmOnboardingWizardAsyncField);
