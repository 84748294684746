import dayjs from 'dayjs';
import DATE_LOCALIZATION_NL from 'dayjs/locale/nl';
import DATE_LOCALIZATION_FR from 'dayjs/locale/fr';

import { TBackendLocale } from '../models/general/i18n';
import { STATE_STORAGE_KEY } from './redux.config';

export enum Locales {
    nl_BE = 'nl_BE',
    fr_BE = 'fr_BE',
}

export const LOCALES = Object.values(Locales);

export const DEFAULT_LOCALE = Locales.nl_BE;

export const LOCALE_TO_BACKEND_LOCALE_MAP = {
    [Locales.nl_BE]: 'nl',
    [Locales.fr_BE]: 'fr',
};

export const LOCALE_TO_BFF_LOCALE_MAP = {
    [Locales.nl_BE]: 'nl-BE',
    [Locales.fr_BE]: 'fr-BE',
};

export const BACKEND_LOCALE_TO_LOCALE_MAP =
    Object.keys(LOCALE_TO_BACKEND_LOCALE_MAP)
        .reduce(
            (mapAccumulator, locale) => {
                const backendLocale = LOCALE_TO_BACKEND_LOCALE_MAP[locale];
                mapAccumulator[backendLocale] = locale;
                return mapAccumulator;
            },
            {},
        );

export const LOCALE_TO_DATE_LOCALIZATION_MAP = {
    [Locales.nl_BE]: DATE_LOCALIZATION_NL,
    [Locales.fr_BE]: DATE_LOCALIZATION_FR,
};

/**
 * Because we don't want to pass the state - just for the backendLocale - each time we ask a description for a constant
 * we also store the backend locale here (and keep it in sync when the user switches his/her locale) so that the
 * constant descriptions can just be retrieved by e.g. their id.
 */
let backendLocale: TBackendLocale = 'nl';

export function setBackendLocale(newValue: TBackendLocale) {
    backendLocale = newValue;
}

export function getBackendLocale() {
    return backendLocale;
}

export function setDateLocalization(newLocale: Locales) {
    const dateLocalization = LOCALE_TO_DATE_LOCALIZATION_MAP[newLocale];
    // use loaded localization file globally + overwrite the default name (e.g. 'nl')
    // with the locale name (e.g. 'nl_BE')
    dayjs.locale(newLocale, dateLocalization);
}

function getBrowserLanguage() {
    return navigator.language;
}

export function getDefaultLocale() {
    // Check if browser lang is french
    const browserLanguage = getBrowserLanguage().toLocaleLowerCase();
    if (browserLanguage && browserLanguage.startsWith('fr')) {
        return Locales.fr_BE;
    }

    return DEFAULT_LOCALE;
}

export const DATE_FORMATS = {
    BACKEND: 'YYYY-MM-DD',
    BACKEND_FULL: 'YYYY-MM-DD HH:mm:ss',
    LONG_WITH_HOURS_AND_MINUTES: 'dd DD MMM YYYY HH:mm',
    DAY_WITH_SHORT_MONTH: 'D MMM',
    DISPLAY: 'DD/MM/YYYY',
    DISPLAY_WITH_TIME: 'DD/MM/YYYY HH:mm',
    FULL: 'dddd D MMMM YYYY',
    LONG: 'dd DD MMM YYYY',
    LONG_WITH_DIVIDERS: 'dd DD/MM/YYYY',
    TIMESTAMP: 'YYYYMMDD-HHmmss',
};

export const TIME_FORMATS = {
    DISPLAY: 'HH:mm',
};

export const TRANSLATIONS_STORAGE_KEY = `${STATE_STORAGE_KEY}_TRANSLATIONS`;
