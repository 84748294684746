import React from 'react';
import BaseTypeahead, { IBaseTypeaheadProps } from '../BaseTypeahead';
import { TDropdownItemValue } from '../../Dropdown';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import { IState } from '../../../../../redux';

export interface IAsyncTypeaheadProps extends Pick<
    IBaseTypeaheadProps,
    'id' | 'name' | 'data' | 'onItemSelected' | 'placeholder' | 'isInvalid' |
    'disabled' | 'children' | 'onFocus' | 'disableReset' |
    'value' | 'valueIfNotSelected' | 'minCharsToTriggerSearch' | 'onBlur' |
    'asyncInfoSelectorDoesNotRequireState' | 'clearSelectedItemOnTextInput' |
    'afterSearchFooter' | 'valueToSetOnClear'
    > {
    value: TDropdownItemValue;
    asyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    onFilter: (filter: string) => void;
}

/**
 * Typeahead to use when data is fetched in an async matter (eg via an ajax request)
 */
export default function AsyncTypeahead(props: IAsyncTypeaheadProps) {
    return <BaseTypeahead {...props} autoFilteringDisabled={true} />;
}
