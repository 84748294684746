import React from 'react';
import '../../employee-details.scss';
import { connect } from '../../../../../index';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import { IEmployeeCourse } from '../../../../../../models/documentCenter/courses';
import {
    getFetchEmployeeCoursesAsyncInfo, getEmployeeCourses,
} from '../../../../../../redux/documentCenter/courses/selectors';
import { IEmployeeDetails } from '../../../../../../models/admin/employee';
import Translate from '../../../../../common/Translate';
import { isDateBeforeCurrentDate } from '../../../../../../utils/core/date/isDateBetween';
import { formatDateForDisplay } from '../../../../../../utils/formatting/formatDate';
import CollapsibleItem from '../../../../../common/widget/CollapsibleItem';
import LabelInfo from '../../../../../common/widget/LabelInfo';
import ListItem from '../../../../../common/list/ListItem';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { getRoutePayload } from '../../../../../../redux/location/selectors';

const CLASS_NAME = 'EmployeeDetailsCourses';

interface IPrivateProps {
    employeeCourses: IEmployeeCourse[];
    selectedCoursesOrganizedId: number;
    extraRoutePayload?: object;
}

interface IEmployeeCoursesProps {
    employee: IEmployeeDetails;
    followedCoursesRouteKey: ROUTE_KEYS;
    plannedCoursesRouteKey: ROUTE_KEYS;
    extraRoutePayload?: object;
}

const TRANSLATION_PREFIX = 'administration.employees.detail.courses';

function EmployeeCoursesComp(props: IPrivateProps & IEmployeeCoursesProps) {
    const {
        employeeCourses,
        employee,
        followedCoursesRouteKey,
        selectedCoursesOrganizedId,
        plannedCoursesRouteKey,
        extraRoutePayload,
    } = props;

    const plannedCourses = getPlannedCoursesForEmployee(employeeCourses);
    const followedCourses = getFollowedCoursesForEmployee(employeeCourses);

    return (
        <CollapsibleItem trigger={`${TRANSLATION_PREFIX}.title`}>
            <div className={CLASS_NAME}>
                <TinyLoader asyncInfoSelector={getFetchEmployeeCoursesAsyncInfo}>
                    <h4><Translate msg={`${TRANSLATION_PREFIX}.planned.title`} /></h4>
                    {plannedCourses.length > 0 ? (
                        <div className={`${CLASS_NAME}__planned`}>
                            {plannedCourses.map((plannedCourse: IEmployeeCourse, index) => (
                                <ListItem
                                    id={`employee-detail-followed-course-${plannedCourse.course.id}`}
                                    key={index}
                                    text={renderEmployeeCourse(plannedCourse)}
                                    to={{
                                        type: plannedCoursesRouteKey,
                                        payload: {
                                            ...extraRoutePayload,
                                            id: employee.id,
                                            coursesOrganizedId: plannedCourse.course.coursesOrganizedId,
                                        },
                                    }}
                                    selected={selectedCoursesOrganizedId ===
                                        plannedCourse.course.coursesOrganizedId}
                                />
                            ))}
                        </div>
                    ) : <p><Translate msg={`${TRANSLATION_PREFIX}.planned.no_results`} /></p>}
                    <h4><Translate msg={`${TRANSLATION_PREFIX}.followed.title`} /></h4>
                    {followedCourses.length > 0 ? (
                        <div className={`${CLASS_NAME}__followed`}>
                            {followedCourses.map((followedCourse: IEmployeeCourse, index) => (
                                <ListItem
                                    id={`employee-detail-followed-course-${followedCourse.course.id}`}
                                    key={index}
                                    text={renderEmployeeCourse(followedCourse)}
                                    to={{
                                        type: followedCoursesRouteKey,
                                        payload: {
                                            ...extraRoutePayload,
                                            id: employee.id,
                                            coursesOrganizedId: followedCourse.course.coursesOrganizedId,
                                        },
                                    }}
                                    selected={selectedCoursesOrganizedId ===
                                        followedCourse.course.coursesOrganizedId}
                                />
                            ))}
                        </div>
                    ) : <p><Translate msg={`${TRANSLATION_PREFIX}.followed.no_results`} /></p>}
                </TinyLoader>
            </div>
        </CollapsibleItem>
    );
}

function renderEmployeeCourse(employeeCourse: IEmployeeCourse) {
    return (
        <LabelInfo
            key={employeeCourse.courseEmployeeId}
            label={formatDateForDisplay(employeeCourse.course.endDate)}
            info={employeeCourse.course.courseName}
            isInfoMultiLine={true}
            hideColon={true}
        />
    );
}

function getPlannedCoursesForEmployee(employeeCourses: IEmployeeCourse[]) {
    return employeeCourses.filter((employeeCourse: IEmployeeCourse) => {
        return !isDateBeforeCurrentDate(employeeCourse.course.endDate);
    });
}

function getFollowedCoursesForEmployee(employeeCourses: IEmployeeCourse[]) {
    return employeeCourses.filter((employeeCourse: IEmployeeCourse) => {
        return isDateBeforeCurrentDate(employeeCourse.course.endDate);
    });
}

export default connect<IPrivateProps, IEmployeeCoursesProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const { coursesOrganizedId } = getRoutePayload<{ coursesOrganizedId: number }>(state);
            return {
                employeeCourses: getEmployeeCourses(state),
                selectedCoursesOrganizedId: coursesOrganizedId,
            };
        };
    },
})(EmployeeCoursesComp);
