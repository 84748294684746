import React, { Component, ComponentClass, createElement, StatelessComponent } from 'react';
import { connect } from '../../index';
import { getRouteInfo } from '../../../redux/location/selectors';
import { IPage, ITemplate } from '../../../routes';
import { isReactComponentClass } from '../../../utils/libs/react';
import { getLocale, areTranslationsRefreshed, showTranslationIds } from '../../../redux/i18n/selectors';
import Loader from '../../common/waiting/Loader';

interface IPrivateProps {
    page: IPage;
    pageProps: object;
    template: ITemplate;
    templateProps: object;
    localeNeededForRerendering: string;
    areTranslationsRefreshedNeededForRerendering: boolean;
    showTranslationIdsNeededForRerendering: boolean;
}

const cachedAsyncPages: {[pageKey: string]: ComponentClass | (() => JSX.Element)} = {};

class BodySwitcher extends Component<IPrivateProps> {
    public shouldComponentUpdate(nextProps: IPrivateProps) {
        return (
            isPageDifferentPage(this.props.page, nextProps.page) ||
            arePagePropsDifferent(this.props.pageProps, nextProps.pageProps) ||
            this.props.localeNeededForRerendering !== nextProps.localeNeededForRerendering ||
            (!this.props.areTranslationsRefreshedNeededForRerendering &&
                nextProps.areTranslationsRefreshedNeededForRerendering) ||
            this.props.showTranslationIdsNeededForRerendering !== nextProps.showTranslationIdsNeededForRerendering
        );
    }

    public loadPage(renderProps?: object) {
        const { page, pageProps } = this.props;
        const props = { ...pageProps, renderProps };
        if (!isReactComponentClass(page.component)) {
            const pageResult = page.component(props);
            if (pageResult instanceof Promise) {
                pageResult.then((asyncPage) => {
                    cachedAsyncPages[page.key] = asyncPage.default;
                    this.forceUpdate();
                });
                return <Loader show={true} />;
            }
            return createElement<{ renderProps?: object }>(page.component as StatelessComponent, props);
        }
        return createElement<{ renderProps?: object }>(page.component, props);
    }

    public renderPage(renderProps?: object) {
        const { page, pageProps } = this.props;
        const possibleAsyncPage = cachedAsyncPages[page.key];
        if (typeof possibleAsyncPage !== 'undefined') {
            return createElement<{ renderProps?: object}>(possibleAsyncPage, { ...pageProps, renderProps });
        }

        return this.loadPage(renderProps);
    }

    public render() {
        const { template: Template, templateProps } = this.props;

        return (
            <Template {...templateProps}>
                {Template.hasRenderProps ? (renderProps) => this.renderPage(renderProps) : this.renderPage()}
            </Template>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const routeInfo = getRouteInfo(state);
        return {
            page: routeInfo.page,
            pageProps: routeInfo.pageProps || {},
            template: routeInfo.template,
            templateProps: routeInfo.templateProps || {},
            localeNeededForRerendering: getLocale(state),
            areTranslationsRefreshedNeededForRerendering: areTranslationsRefreshed(state),
            showTranslationIdsNeededForRerendering: showTranslationIds(state),
        };
    },
})(BodySwitcher);

export function getCachedAsyncPageComponent(pageKey: string) {
    return cachedAsyncPages[pageKey];
}

function isPageDifferentPage(currentPage: IPage, nextPage: IPage): boolean {
    if (!currentPage || !nextPage) {
        return true;
    }
    return currentPage.key !== nextPage.key;
}

function arePagePropsDifferent(currentPageProps, nextPageProps): boolean {
    return JSON.stringify(currentPageProps) !== JSON.stringify(nextPageProps);
}
