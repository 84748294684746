import dayjs, { ConfigType as TDateToFormat } from 'dayjs';

import { DATE_FORMATS } from '../../config/i18n.config';
import { now } from '../core/date/getSpecificDate';
import { IGreeting } from '../../models/general/webAppMessages';
import { joinParts } from './formatHelper';

const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

export function formatCurrentDateForBackend() {
    return formatDateForBackend(now());
}

export function formatDateToDayMonth(date: Date) {
    return `${prependZeroToMax2Digits(date.getDate())}/${prependZeroToMax2Digits(date.getMonth() + 1)}`;
}

export function formatDateForDisplay(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.DISPLAY);
}

export function formatDateWithTimeForDisplay(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.DISPLAY_WITH_TIME);
}

export function formatDateToDayWithShortMonth(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.DAY_WITH_SHORT_MONTH);
}

export function formatDate(date: TDateToFormat, format: string) {
    return dayjs(date).format(format);
}

export function formatDateForBackend(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.BACKEND);
}

export function formatDateForBackendFull(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.BACKEND_FULL);
}

export function formatDateWithHoursAndMinutes(date: TDateToFormat) {
    return formatDate(date, DATE_FORMATS.LONG_WITH_HOURS_AND_MINUTES);
}

export function formatDateInLongFormat(date: TDateToFormat) {
    return undefined !== date ? formatDate(date, DATE_FORMATS.LONG) : null;
}

export function formatDateInLongFormatWithDividers(date: TDateToFormat) {
    return undefined !== date ? formatDate(date, DATE_FORMATS.LONG_WITH_DIVIDERS) : null;
}

export function formatDateInFullFormat(date: TDateToFormat) {
    return undefined !== date ? formatDate(date, DATE_FORMATS.FULL) : null;
}

export function dateToDayOfWeek(date: Date) {
    // TODO this could be refactored by also using dayjs (then we don't need translations for this)
    return weekdays[date.getDay()];
}

export function endDateOfNWeeksAfter(date: TDateToFormat, nWeeks: number) {
    // Currently no way to set the start day of a week in dayjs. So we have to add one day to it.
    return dayjs(date).add(nWeeks, 'week').endOf('week').add(1, 'day').format(DATE_FORMATS.BACKEND);
}

export function startDateOfNWeeksBefore(date: TDateToFormat, nWeeks: number) {
    // Currently no way to set the start day of a week in dayjs. So we have to add one day to it.
    return dayjs(date).subtract(nWeeks, 'week').startOf('week').add(1, 'day').format(DATE_FORMATS.BACKEND);
}

export function prependZeroToMax2Digits(input: number) {
    return input
        .toString()
        .replace(/^(\d)$/, '0$1');
}

/**
 * Checks that input is of the format DD/MM.
 * If not, null is returned (which will be displayed in the list as a '-').
 */
export function formatDayAndMonthForDisplay(dayAndMonth: string) {
    if (!dayAndMonth) {
        return null;
    }

    return dayAndMonth.split('/').length === 2
        ? dayAndMonth
        : null;
}

/**
 * Formats input of DD/MM to MM/DD
 */
export function formatDayAndMonthForSorting(dayAndMonth: string) {
    if (!dayAndMonth) {
        return null;
    }

    const parts = dayAndMonth.split('/').reverse();
    return parts.join('/');
}

export function getCurrentTimestamp() {
    return formatDate(now(), DATE_FORMATS.TIMESTAMP);
}

export function formatDateForGreeting() {
    const currentHour = dayjs(now()).hour();

    if (currentHour > 6 && currentHour < 12) {
        return IGreeting.MORNING;
    }
    if (currentHour >= 12 && currentHour < 18) {
        return IGreeting.AFTERNOON;
    }
    if (currentHour >= 18 && currentHour < 24) {
        return IGreeting.EVENING;
    }

    return IGreeting.NIGHT;
}

export function formatMultipleDatesOnOneLine(dates: TDateToFormat[]) {
    const formattedDates = dates.map((date: TDateToFormat, index, dates: TDateToFormat[]) => {
        const month = dayjs(date).month();

        // When we don't have a next date, show the full date.
        if (typeof dates[index + 1] === 'undefined') {
            return formatDate(date, 'dd DD MMM YYYY');
        }

        const nextDateMonth = dayjs(dates[index + 1]).month();
        // If the next date has the same month, don't show the month for this date.
        if (month === nextDateMonth) {
            return formatDate(date, 'dd DD');
        }

        return formatDate(date, 'dd DD MMM');
    });

    return joinParts(formattedDates, ' & ');
}
