import dayjs from 'dayjs';
import { isInSameMonth } from './isInSameMonth';

export function isOnSameDay(date1: Date | string, date2: Date | string): boolean {
    if (!date1 || !date2) {
        return false;
    }

    const parsedDate1 = typeof date1 === 'string' ? dayjs(date1).toDate() : date1;
    const parsedDate2 = typeof date2 === 'string' ? dayjs(date2).toDate() : date2;

    return (
        isInSameMonth(date1, date2) &&
        parsedDate1.getDate() === parsedDate2.getDate()
    );
}
