import React, { PureComponent, ReactElement } from 'react';
import classNames from 'classnames';
import { AsyncStatus, IAction } from '../../../../models/general/redux';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import { IState, NO_RERENDER } from '../../../../redux';
import { connect } from '../../../index';
import { WIZARDFLOW_CLASSES } from '../Wizard';
import Loader from '../../waiting/Loader';
import PageHeader from '../../../appShell/PageHeader';
import StickyFooter from '../../widget/StickyFooter';
import { TPlaceholders } from '../../Translate';

export interface IRenderStepContentProps<ContentValues> {
    baseClassName: string;
    values: ContentValues;
    onChangeInput: (values: ContentValues) => void;
}

interface IContentValuesActionCreatorProps<ContentValues> {
    values: ContentValues;
    publicProps?: IPublicProps<ContentValues>;
    getState?: () => IState;
}

type TContentValuesActionCreator<ContentValues> =
    (actionCreatorProps: IContentValuesActionCreatorProps<ContentValues>) => IAction<{}>;

interface IPublicProps<ContentValues> {
    baseClassName: string;
    header: {
        titleTranslationKey: string;
        titlePlaceholders?: (state: IState) => TPlaceholders;
        textTranslationKey?: string;
        textPlaceholders?: (state: IState) => TPlaceholders;
    };
    showLoaderSelector?: (state: IState) => boolean | AsyncStatus;
    content: {
        initialValuesSelector?: (state: IState, publicProps?: IPublicProps<ContentValues>) => ContentValues;
        renderStepContent: (renderProps: IRenderStepContentProps<ContentValues>) => ReactElement<{}>;
        onChangeInputActionCreator?: TContentValuesActionCreator<ContentValues>;
    };
    stepButtons?: {
        shouldDisableNextButton?: (values: ContentValues) => boolean;
        nextButtonTranslationKey?: string;
        onConfirmActionCreator?: TContentValuesActionCreator<ContentValues>;
        isFinalStep?: boolean;
        disbaleGoToNextStepByDefault?: boolean;
    };
}

interface IPrivateProps<ContentValues> {
    initialValues: ContentValues;
    showLoader: boolean | AsyncStatus;
    headerTitlePlaceholders: {} | TPlaceholders;
    headerTextPlaceholders: {} | TPlaceholders;
    onChangeInput: (values: ContentValues) => void;
    onConfirm: (values: ContentValues) => void;
}

interface IComponentState<ContentValues> {
    values: ContentValues;
    isNextButtonDisabled: boolean;
}

class WizardStep<ContentValues extends object> extends PureComponent<
    IStepperStepRenderProps & IPrivateProps<ContentValues> & IPublicProps<ContentValues>,
    IComponentState<ContentValues>
> {

    constructor(props: IStepperStepRenderProps & IPrivateProps<ContentValues> & IPublicProps<ContentValues>) {
        super(props);

        this.state = {
            values: props.initialValues,
            isNextButtonDisabled: isNextButtonDisabled(props.stepButtons, props.initialValues),
        };

        this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
        this.onConfirmHandler = this.onConfirmHandler.bind(this);
    }

    public render() {
        const {
            baseClassName, header, content,
            showLoader,
            stepButtons,
            renderStepButtons,
            headerTitlePlaceholders, headerTextPlaceholders,
        } = this.props;
        const { values, isNextButtonDisabled } = this.state;

        return (
            <>
                <PageHeader
                    title={header.titleTranslationKey}
                    titlePlaceholders={headerTitlePlaceholders}
                    text={header.textTranslationKey}
                    textPlaceholders={headerTextPlaceholders}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, baseClassName)}>
                    <Loader show={showLoader}>
                        {content.renderStepContent({
                            baseClassName,
                            values,
                            onChangeInput: this.onChangeInputHandler,
                        })}
                        <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    disabled: isNextButtonDisabled,
                                    translationKey: stepButtons.nextButtonTranslationKey || null,
                                    onClick: this.onConfirmHandler,
                                },
                            })}
                        </StickyFooter>
                    </Loader>
                </div>
            </>
        );
    }

    private onChangeInputHandler(values: ContentValues) {
        this.setState({
            values,
            isNextButtonDisabled: isNextButtonDisabled(this.props.stepButtons, values),
        });
        this.props.onChangeInput(values);
    }

    private onConfirmHandler() {
        const { onConfirm, goToNextStep, stepButtons } = this.props;
        const preventGoToNextStep =
            stepButtons && (stepButtons.disbaleGoToNextStepByDefault || stepButtons.isFinalStep);

        onConfirm(this.state.values);
        if (!preventGoToNextStep) {
            goToNextStep();
        }
    }
}

export default function GenericWizardStep<ContentValues extends object>() {
    return connect<IPrivateProps<ContentValues>, IPublicProps<ContentValues>>({
        statePropsPerInstance: (state, publicProps) => {
            return (state) => {
                return {
                    initialValues: publicProps.content.initialValuesSelector
                        ? publicProps.content.initialValuesSelector(state, publicProps)
                        : NO_RERENDER.EMPTY_OBJECT as ContentValues,
                    showLoader: publicProps.showLoaderSelector ?
                        publicProps.showLoaderSelector(state) : false,
                    headerTitlePlaceholders: publicProps.header.titlePlaceholders ?
                        publicProps.header.titlePlaceholders(state) : NO_RERENDER.EMPTY_OBJECT,
                    headerTextPlaceholders: publicProps.header.textPlaceholders ?
                        publicProps.header.textPlaceholders(state) : NO_RERENDER.EMPTY_OBJECT,
                };
            };
        },
        dispatchPropsDeprecated: (dispatch, getState, publicProps) => ({
            onChangeInput: (values: ContentValues) => {
                if (publicProps.content.onChangeInputActionCreator) {
                    const actionToDispatch = publicProps.content.onChangeInputActionCreator({
                        values,
                        publicProps,
                        getState,
                    });
                    if (actionToDispatch) {
                        dispatch(actionToDispatch);
                    }
                }
            },
            onConfirm: (values: ContentValues) => {
                if (publicProps.stepButtons && publicProps.stepButtons.onConfirmActionCreator) {
                    const actionToDispatch = publicProps.stepButtons.onConfirmActionCreator({
                        values,
                        publicProps,
                        getState,
                    });
                    if (actionToDispatch) {
                        dispatch(actionToDispatch);
                    }
                }
            },
        }),
    })(WizardStep as new() => WizardStep<ContentValues>);
}

function isNextButtonDisabled(stepButtons, values) {
    return stepButtons && stepButtons.shouldDisableNextButton ?
        stepButtons.shouldDisableNextButton(values) : false;
}
