import { ENVS } from '../../config/envs.config';

export default (onToggle: () => void) => {
    if (window.klantenzone && window.klantenzone.ENV !== ENVS.PROD) {
        document.addEventListener('keydown', (e) => {
            // Show translation ids when Alt + Shift + L is pressed
            if (e.altKey && e.shiftKey && (e.key === 'l' || e.code === 'KeyL' || e.keyCode === 76)) {
                onToggle();
            }
        });
    }
};
