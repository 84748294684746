import { pathOr } from 'ramda';

import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../config/planning.config';
import { IPlannedMedicalExamination } from '../../../models/interventions/medicalExaminations/planned';
import { ILocationTypeCodes } from '../../../models/general/location';

export const isPlannedMedicalExaminationInCompany = (
    plannedMedicalExamination: IPlannedMedicalExamination,
) => {
    const code = pathOr(
        plannedMedicalExamination.location.typeCode === ILocationTypeCodes.CUSTOMER
            ? IN_COMPANY_MEDICAL_CENTER_CODE
            : null,
        ['location', 'code'],
        plannedMedicalExamination,
    );

    return code && code === IN_COMPANY_MEDICAL_CENTER_CODE;
};
