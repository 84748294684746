import React, { MouseEvent } from 'react';
import './close-icon.scss';
import Icon from '../Icon';

interface ICloseIconProps {
    onClick: (e: MouseEvent<HTMLSpanElement>) => void;
}

export default function CloseIcon(props: ICloseIconProps) {
    return (
        <span className="CloseIcon" onClick={props.onClick}>
            <Icon typeName="cross" />
        </span>
    );
}
