import React from 'react';
import { IAccomplishmentsContributionDetail } from '../../../../models/admin/preventionUnits';
import {
    getAccomplishmentsContributionThatWasSelected,
    getAccomplishmentsContributionsOfSelectedYearAsyncInfo,
} from '../../../../redux/preventionUnits/selectors';
import { IRenderDetailHeaderProps } from '../../../common/widget/MasterWithDetail/typings';
import TinyLoader from '../../../common/waiting/TinyLoader';
import connect from '../../../../utils/libs/redux/connect';

const CLASS_NAME = 'FlatFeeDetailHeader';

interface IPrivateProps {
    selectedTypeDescription: string;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const selectedAccomplishmentsContributionFromList = getAccomplishmentsContributionThatWasSelected(state);

        return {
            selectedTypeDescription: selectedAccomplishmentsContributionFromList
                ? selectedAccomplishmentsContributionFromList.description
                : null,
        };
    },
})(FlatFeeDetailHeader);

function FlatFeeDetailHeader(props: IPrivateProps & IRenderDetailHeaderProps<IAccomplishmentsContributionDetail[]>) {
    const { selectedTypeDescription } = props;

    return (
        <div className={CLASS_NAME}>
            <TinyLoader asyncInfoSelector={getAccomplishmentsContributionsOfSelectedYearAsyncInfo}>
                <h1>{selectedTypeDescription}</h1>
            </TinyLoader>
        </div>
    );
}
