import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import ROUTE_KEYS from '../../routeKeys';
import StartOnboardingWizard from './StartOnboardingWizard';
import { IWizardTemplateProps } from '../appShell/templates/WizardTemplate';
import OnboardingWizard from './OnboardingWizard';
import { getPropertyFromRoutePayload } from '../../redux/location/selectors';
import FinishOnboardingWizard from './FinishOnboardingWizard';
import { BASE_ROUTES } from '../../config/route.config';
import { ROUTE_GROUP } from '../../config/routeGroup.config';

const BASE_ROUTE = BASE_ROUTES.onboarding;

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_ONBOARDING_START]: {
        path: BASE_ROUTE,
        page: {
            key: 'ONBOARDING',
            component: StartOnboardingWizard,
        },
        template: templates.WizardTemplate,
        virtualPage: 'onboarding',
        requiredAccessLevels: [
            { company: 'W' },
        ],
        templateProps: {
            wizardTitle: {
                hideExitLink: true,
                titleTranslationKey: 'onboarding.breadcrumbs_label',
            },
            hideFooterRouteLinks: true,
        } as IWizardTemplateProps,
        breadcrumbsLabel: {
            translationKey: 'onboarding.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_ONBOARDING_NEW]: {
        path: `${BASE_ROUTE}/new/:step`,
        page: {
            key: 'ONBOARDING_NEW',
            component: OnboardingWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'onboarding.wizard.title',
            },
            hideFooterRouteLinks: true,
        } as IWizardTemplateProps,
        virtualPage: [
            'onboarding_new',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            { company: 'W' },
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL]: {
        path: `${BASE_ROUTE}/new/:step/details/:id`,
        page: {
            key: 'ONBOARDING_EMPLOYEE_DETAILS',
            component: OnboardingWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'onboarding.wizard.title',
            },
            hideFooterRouteLinks: true,
        } as IWizardTemplateProps,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'onboarding_employee_details',
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_ONBOARDING_FINISH]: {
        path: `${BASE_ROUTE}/finish`,
        page: {
            key: 'FINISH_ONBOARDING',
            component: FinishOnboardingWizard,
        },
        template: templates.WizardTemplate,
        virtualPage: 'finish_onboarding',
        requiredAccessLevels: [
            { company: 'R' },
        ],
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'onboarding.breadcrumbs_label',
            },
            hideFooterRouteLinks: true,
        } as IWizardTemplateProps,
        breadcrumbsLabel: {
            translationKey: 'onboarding.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
};

export default ROUTES;
