import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers,
} from '../index';
import ROUTE_KEYS from '../../routeKeys';
import { IFaqOverview } from '../../models/contact/faq';
import { FETCH_FAQ_OVERVIEW, SEND_COMPANY_FEEDBACK, FETCH_CASE_MANAGER, FETCH_MENSURA_CONTACTS } from './types';
import { getAsyncDoInitialState } from '../../utils/libs/redux/async/asyncReducerUtils';
import { IAsyncDoField } from '../../models/general/redux';
import { ICaseManager } from '../../models/contact/caseManager';
import { REDUCER_KEYS } from '../../config/redux.config';
import { IFetchMensuraContactsSuccessPayload } from '../../models/admin/companyInfo';
import { IMensuraContacts } from '../../models/contact/mensuraContacts';

export const reducerKey = REDUCER_KEYS.CONTACT;

export interface IReducerState {
    faqOverview: IAsyncFetchField<IFaqOverview>;
    caseManager: IAsyncFetchField<ICaseManager>;
    fetchMensuraContacts: IAsyncDoField;
    mensuraContacts: IMensuraContacts;
    sendCompanyFeedback: IAsyncDoField;
}

const initialState: IReducerState = {
    faqOverview: getAsyncFetchInitialState(),
    caseManager: getAsyncFetchInitialState(),
    fetchMensuraContacts: getAsyncDoInitialState(),
    mensuraContacts: {},
    sendCompanyFeedback: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IFaqOverview, IReducerState, IFaqOverview>({
        baseActionType: FETCH_FAQ_OVERVIEW,
        fieldName: 'faqOverview',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_FAQ_OVERVIEW, ROUTE_KEYS.R_FAQ_DETAIL],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICaseManager, IReducerState, ICaseManager>({
        baseActionType: FETCH_CASE_MANAGER,
        overrideTriggerActionType: [
            FETCH_CASE_MANAGER,
            ROUTE_KEYS.R_CONTACT,
            ROUTE_KEYS.R_COURSES_PLANNED_DETAIL,
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
        ],
        fieldName: 'caseManager',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: FETCH_MENSURA_CONTACTS,
        overrideTriggerActionType: [
            FETCH_MENSURA_CONTACTS,
            ROUTE_KEYS.R_CONTACT,
            ROUTE_KEYS.R_CONTACT_DETAIL,
        ],
        fieldName: 'fetchMensuraContacts',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IFetchMensuraContactsSuccessPayload;
            if (!successPayload.companyCode) {
                return oldState;
            }
            return {
                ...oldState,
                mensuraContacts: {
                    ...oldState.mensuraContacts,
                    [successPayload.companyCode]: successPayload.contacts,
                },
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SEND_COMPANY_FEEDBACK,
        fieldName: 'sendCompanyFeedback',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
