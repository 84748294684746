import { createSelector } from 'reselect';

import {
    getBufferzonePlannedTimeslots,
    getPlanBufferzoneModifiedTimeCellIds,
} from '../../../../../redux/intervention/bufferzones/selectors';

import { mapBufferzonePlannedSlotsToListItems } from './PlanBufferzoneWizardOverview.helper';

export const modifiedExaminationsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getPlanBufferzoneModifiedTimeCellIds,
    (examinations, modifiedTimeCellIds) => {
        return examinations.filter((exam) => modifiedTimeCellIds.includes(exam.timeSlotId));
    },
);

export const modifiedExaminationsAsListItemsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getPlanBufferzoneModifiedTimeCellIds,
    (examinations, modifiedTimeCellIds) => {
        const filteredExams = examinations.filter((exam) => modifiedTimeCellIds.includes(exam.timeSlotId));
        return mapBufferzonePlannedSlotsToListItems(filteredExams);
    },
);

export const plannedExaminationsAsListItemsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    (examinations) => mapBufferzonePlannedSlotsToListItems(examinations),
);
