import React, { PureComponent } from 'react';
import './contact-detail.scss';
import { IRenderDetailHeaderProps, IRenderDetailContentProps }
    from '../../../common/widget/MasterWithDetail/typings';
import connect from '../../../../utils/libs/redux/connect';
import { AsyncStatus } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader/index';
import Translate from '../../../common/Translate';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { schema, fields } from './AddContact/addContactSchema';
import { TextPropertyInput, ConstantsTypeaheadPropertyInput } from '../../../common/input/PropertyInput';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { ICompanyContact, IUpdateCompanyContactPayload } from '../../../../models/admin/companyInfo';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import { ITranslator } from '../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { TFormValuesContactToAdd } from './AddContact';
import { updateContactActions } from '../../../../redux/company/info/actions';
import { mayUserManageCompanyInternalContacts } from '../../../../redux/company/info/selectors';
import FormFieldError from '../../../common/forms/FormFieldError';
import LabelInfo from '../../../common/widget/LabelInfo';
import SubmitButton from '../../../common/buttons/SubmitButton';
import { ConstantType } from '../../../../models/general/constants';
import { ConstantsAdvancedPropertyInput } from '../../../common/input/PropertyInput/index';
import isDivisionCompanyCode from '../../../../utils/administration/companyInfo/isDivisionCompanyCode';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../utils/formatting/formatPhone';

interface IPrivateProps {
    mayEdit: boolean;
    translator: ITranslator;
    isDetailsLoaded: boolean;
    updateContact: (values: IFormValues) => void;
}

interface IFormValues extends TFormValuesContactToAdd {
    id: number;
}

const FORM_NAME = 'contact-detail-fields-form';

export function DetailHeader(props: IRenderDetailHeaderProps<ICompanyContact>) {
    const {
        detailAsyncInfo,
        detailData: selectedContact,
    } = props;

    return (
        <Loader show={detailAsyncInfo.status}>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : selectedContact && formatPersonName(selectedContact)
                }
            </h1>
        </Loader>
    );
}

class DetailContentComp extends PureComponent<IPrivateProps & IRenderDetailContentProps<ICompanyContact>> {
    private resetForm: (newValues: object) => void;

    public render() {
        const {
            detailData: selectedContact,
            mayEdit,
            updateContact,
            isDetailsLoaded,
            translator,
            detailUpdateAsyncInfo,
        } = this.props;

        if (!selectedContact) {
            this.resetForm = null;
            return null;
        }

        const initialValues = getContactInitialValues(selectedContact);
        const showLoader = !isDetailsLoaded || detailUpdateAsyncInfo.status === AsyncStatus.Busy;

        return (
            <Form
                name={FORM_NAME}
                initialValues={initialValues}
                schema={schema}
                handleSubmit={updateContact}
                footer={mayEdit &&
                    <SubmitButton
                        id="edit-contact-submit-button"
                        formName={FORM_NAME}
                    >
                        <Translate
                            msg="administration.employees.edit_absences.form.submit"
                        />
                    </SubmitButton>
                }
                render={({
                    values, handleChange, errors, setFieldValue, resetForm, touched,
                }: IFormRenderProps<IFormValues>) => {
                    if (this.resetForm !== resetForm) {
                        this.resetForm = resetForm;
                    }

                    const contactCompanyIsDivision = isDivisionCompanyCode(values.companyCode);

                    return (
                        <Loader show={showLoader}>
                            <TextPropertyInput
                                id="name"
                                name={fields.name}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.name"
                                readonly={!mayEdit}
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={touched.name && !!errors.name}
                            >
                                {touched.name && (
                                    <FormFieldError
                                        error={errors.name}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.name'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id="firstName"
                                name={fields.firstName}
                                // eslint-disable-next-line max-len
                                labelKey="administration.company_info.contacts.add_contact.form.fields.first_name"
                                readonly={!mayEdit}
                                value={values.firstName}
                                onChange={handleChange}
                                isInvalid={touched.firstName && !!errors.firstName}
                            >
                                {touched.firstName && (
                                    <FormFieldError
                                        error={errors.firstName}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.first_name'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <ConstantsTypeaheadPropertyInput
                                id="titleId"
                                name={fields.titleId}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.title"
                                readonly={!mayEdit}
                                value={values.titleId}
                                constantType={ConstantType.CONTACT_TITLES}
                                onItemSelected={(value) => setFieldValue('titleId', value as string)}
                                isInvalid={touched.titleId && !!errors.titleId}
                            >
                                {touched.titleId && (
                                    <FormFieldError
                                        error={errors.titleId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.title'),
                                        }}
                                    />
                                )}
                            </ConstantsTypeaheadPropertyInput>
                            <ConstantsTypeaheadPropertyInput
                                id="languageId"
                                name={fields.languageId}
                                // eslint-disable-next-line max-len
                                labelKey="administration.company_info.contacts.add_contact.form.fields.language"
                                readonly={!mayEdit}
                                value={values.languageId}
                                constantType={ConstantType.LANGUAGES}
                                onItemSelected={(value) => setFieldValue('languageId', value as string)}
                                isInvalid={touched.languageId && !!errors.languageId}
                            >
                                {touched.languageId && (
                                    <FormFieldError
                                        error={errors.languageId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.language'),
                                        }}
                                    />
                                )}
                            </ConstantsTypeaheadPropertyInput>
                            <ConstantsAdvancedPropertyInput
                                id="typeId"
                                name={fields.typeId}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.type"
                                readonly={true}
                                value={values.typeId}
                                constantType={ConstantType.CONTACT_TYPES}
                                isInvalid={touched.typeId && !!errors.typeId}
                            >
                                {touched.typeId && (
                                    <FormFieldError
                                        error={errors.typeId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.type'),
                                        }}
                                    />
                                )}
                            </ConstantsAdvancedPropertyInput>
                            <LabelInfo
                                labelKey="administration.company_info.contacts.add_contact.form.fields.address"
                                info={formatAddress(selectedContact.address)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                labelKey={contactCompanyIsDivision ?
                                    'administration.company_info.contacts.add_contact.form.fields.division' :
                                    'administration.company_info.contacts.add_contact.form.fields.seat'
                                }
                                info={values.companyName}
                            />
                            <TextPropertyInput
                                id="email"
                                name={fields.email}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.email"
                                readonly={!mayEdit}
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={touched.email && !!errors.email}
                            >
                                {touched.email && (
                                    <FormFieldError
                                        error={errors.email}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.email'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id="phone"
                                name={fields.phone}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.phone"
                                readonly={!mayEdit}
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={touched.phone && !!errors.phone}
                            >
                                {touched.phone && (
                                    <FormFieldError
                                        error={errors.phone}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.phone"'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id="fax"
                                name={fields.fax}
                                labelKey="administration.company_info.contacts.add_contact.form.fields.fax"
                                readonly={!mayEdit}
                                value={values.fax}
                                onChange={handleChange}
                                isInvalid={touched.fax && !!errors.fax}
                            >
                                {touched.fax && (
                                    <FormFieldError
                                        error={errors.fax}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.fax'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id="mobilePhone"
                                name={fields.mobilePhone}
                                // eslint-disable-next-line max-len
                                labelKey="administration.company_info.contacts.add_contact.form.fields.mobile_phone"
                                readonly={!mayEdit}
                                value={values.mobilePhone}
                                onChange={handleChange}
                                isInvalid={touched.mobilePhone && !!errors.mobilePhone}
                            >
                                {touched.mobilePhone && (
                                    <FormFieldError
                                        error={errors.mobilePhone}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('administration.company_info.contacts.add_contact.form.fields.mobile_phone'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                        </Loader>
                    );
                }}
            />
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps & IRenderDetailContentProps<ICompanyContact>) {
        if (
            this.resetForm &&
            prevProps.detailData && this.props.detailData &&
            prevProps.detailData.customerContactId !== this.props.detailData.customerContactId
        ) {
            const initialValues = getContactInitialValues(this.props.detailData);
            this.resetForm(initialValues);
        }
    }
}

function getContactInitialValues(contact: ICompanyContact): IFormValues {
    return {
        id: contact.id,
        firstName: contact.firstName || '',
        name: contact.name || '',
        languageId: contact.languageId || '',
        typeId: contact.typeId,
        titleId: contact.titleContactId || '',
        email: contact.email || '',
        fax: tryFormattingPhoneInternational(contact.fax) || '',
        phone: tryFormattingPhoneInternational(contact.phone) || '',
        mobilePhone: tryFormattingPhoneInternational(contact.mobilePhone) || '',
        companyCode: contact.company.companyCode || '',
        companyName: contact.company.name || '',
    };
}

export const DetailContent = connect<IPrivateProps, IRenderDetailContentProps<ICompanyContact>>({
    statePropsDeprecated: (state, publicProps) => {
        const isDetailsLoaded = publicProps.detailAsyncInfo.status !== AsyncStatus.Busy;

        return {
            mayEdit: mayUserManageCompanyInternalContacts(state),
            isDetailsLoaded,
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        return {
            updateContact: (values: IFormValues) => {
                const formattedValues: IFormValues = {
                    ...values,
                    phone: tryFormattingPhoneForBackend(values.phone),
                    mobilePhone: tryFormattingPhoneForBackend(values.mobilePhone),
                };
                dispatch(updateContactActions.trigger(
                    transformToUpdateCompanyContactPayload(publicProps.detailData.customerContactId, formattedValues),
                ));
            },
        };
    },
})(DetailContentComp);

function transformToUpdateCompanyContactPayload(
    customerContactId: number,
    formValues: IFormValues,
): IUpdateCompanyContactPayload {
    const { companyCode, ...other } = formValues;

    return {
        companyCode,
        contactData: other,
        customerContactId,
    };
}
