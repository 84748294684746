import { IParseTimeSlotToJsonPatchPayload } from '../../../../models/interventions/timeslots';

interface JSONPatchEntity {
    op: 'add' | 'remove' | 'replace' | 'move' | 'copy';
    path: string;
    value: string | number;
}

// If this seems weird / tricky, check out this article:
// http://erosb.github.io/post/json-patch-vs-merge-patch/
export const parseTimeSlotJsonPatchBody = (payload: IParseTimeSlotToJsonPatchPayload): JSONPatchEntity[] => {
    const apiBody: JSONPatchEntity[] = [];
    const { timeSlotId = null, activityId = null, comment = null } = payload;

    if (timeSlotId) {
        apiBody.push({
            op: 'replace',
            path: '/timeSlotId',
            value: timeSlotId,
        });
    }

    if (activityId) {
        apiBody.push({
            op: 'replace',
            path: '/activityId',
            value: activityId,
        });
    }

    if (typeof comment === 'string') {
        apiBody.push({
            op: 'replace',
            path: '/comment',
            value: comment,
        });
    }

    return apiBody;
};
