import { createSelector } from 'reselect';

import {
    filterOutInfirmMedicalCenters,
} from '../../../../../utils/interventions/medicalExaminations/filterOutInFirmMedicalCenters';
import { getCompanyMedicalCenters } from '../../../../../redux/company/info/selectors';
import { ICompanyMedicalCenter } from '../../../../../models/admin/companyInfo';

import { mapMedicalCentersForTypeahead } from './MedicalCenterTypeahead.helper';

export const makeMedicalCentersForTypeaheadMemoizedSelector = (excludeInFirm: boolean) => {
    return createSelector(
        getCompanyMedicalCenters,
        (medicalCenters) => {
            const medicalCentersToMap = excludeInFirm
                ? filterOutInfirmMedicalCenters(medicalCenters) as ICompanyMedicalCenter[]
                : [...medicalCenters];

            return mapMedicalCentersForTypeahead(medicalCentersToMap);
        },
    );
};
