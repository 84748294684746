import { ICompanyVisitRequestWizardPayload } from '../../../../../models/interventions/company-visits';
import ROUTE_KEYS from '../../../../../routeKeys';
import { createEpic } from '../../../../index';
import { getCompanyVisitRequestWizardStepId } from '../../selectors';
import { triggerCompanyVisitRequestWizard } from '../../actions';
import { getRequestCompanyVisitWizardSteps } from '../../../../../config/navigation/wizardStepsMap';

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validateCompanyVisitRequestWizardStepIdEpic
createEpic<ICompanyVisitRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS =
            getRequestCompanyVisitWizardSteps().stepIds;
        if (!COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.includes(requestedStep)) {
            return next(triggerCompanyVisitRequestWizard());
        }

        // check no step skipped
        const currentStep = getCompanyVisitRequestWizardStepId(getState());
        const currentStepIndex = COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(triggerCompanyVisitRequestWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});
