export type TLazyLoadSvgId =
    ADMINISTRATION_SVG_IDS | CONTACT_SVG_IDS | INTERVENTIONS_SVG_IDS |
    TOP_TASKS_SVG_IDS | DOCUMENT_CENTER_SVG_IDS | COURSE_TYPE_SVG_IDS;

export type TLazyLoadSvgGroup = SVG_GROUP_NAME;

export interface ILazyLoadSvgConfig {
    id: TLazyLoadSvgId;
    src: string;
}

export interface ISvgGroupProps {
    id: TLazyLoadSvgId;
    className?: string;
    preserveAspectRatio?: string;
}

export enum SVG_GROUP_NAME {
    ADMINISTRATION = 'ADMINISTRATION',
    CONTACT = 'CONTACT',
    DOCUMENT_CENTER = 'DOCUMENT_CENTER',
    INTERVENTIONS = 'INTERVENTIONS',
    TOP_TASKS = 'TOP_TASKS',
    COURSE_TYPES = 'COURSE_TYPES',
}

export enum ADMINISTRATION_SVG_IDS {
    EMPLOYEES = 'EMPLOYEES',
    EMPLOYEES_DETAIL = 'EMPLOYEES_DETAIL',
    FUNCTIONS = 'FUNCTIONS',
    FUNCTIONS_DETAIL = 'FUNCTIONS_DETAIL',
    RISKS = 'RISKS',
    RISKS_DETAIL = 'RISKS_DETAIL',
    BILLING = 'BILLING',
    PREVENTION = 'PREVENTION',
    COMPANY_VISIT = 'COMPANY_VISIT',
    CHECK_LIST = 'CHECK_LIST',
    BUILDING = 'BUILDING',
    MONEY = 'MONEY',
    CALENDAR = 'CALENDAR',
}

export enum CONTACT_SVG_IDS {
    COMPLAINT = 'COMPLAINT',
    CONTACT = 'CONTACT',
    FAQ = 'FAQ',
    PLAN = 'PLAN',
    QUESTION = 'QUESTION',
    SUGGESTION = 'SUGGESTION',
}

export enum DOCUMENT_CENTER_SVG_IDS {
    MEDICAL_DOCS = 'MEDICAL_DOCS',
    RISK_MANAGEMENT = 'RISK_MANAGEMENT',
    WORK_POST_CARD = 'WORK_POST_CARD',
    TEMPLATES = 'TEMPLATES',
    COURSES = 'COURSES',
    COURSES_LEARN_CONNECT = 'COURSES_LEARN_CONNECT',
    FILE_ID = 'FILE_ID',
    FILE_AGREE = 'FILE_AGREE',
    FILE_INFO = 'FILE_INFO',
    REPORT = 'REPORT',
}

export enum INTERVENTIONS_SVG_IDS {
    ADVICE = 'ADVICE',
    ALL_INTERVENTIONS = 'ALL_INTERVENTIONS',
    BAND_AID = 'BAND_AID',
    COMPANY_VISIT = 'COMPANY_VISIT',
    COURSES = 'COURSES',
    COURSES_LEARN_CONNECT = 'COURSES_LEARN_CONNECT',
    ENVIROMENTAL_INTERVENTION = 'ENVIROMENTAL_INTERVENTION',
    ERGONOMIC_INTERVENTION = 'ERGONOMIC_INTERVENTION',
    HEALTH_INTERVENTION = 'HEALTH_INTERVENTION',
    MED_EXAM = 'MED_EXAM',
    OTHER_INTERVENTION = 'OTHER_INTERVENTION',
    PSYCHO_SOCIAL_INTERVENTION = 'PSYCHO_SOCIAL_INTERVENTION',
    SAFETY_INTERVENTION = 'SAFETY_INTERVENTION',
    SYRINGE = 'SYRINGE',
    TOXILOGICAL_INTERVENTION = 'TOXILOGICAL_INTERVENTION',
    VACCINES = 'VACCINES',
}

export enum TOP_TASKS_SVG_IDS {
    ADD_EMPLOYEE = 'ADD_EMPLOYEE',
    ADD_FUNCTION = 'ADD_FUNCTION',
    ADD_USER = 'ADD_USER',
    MANAGE_ACTIVITY_REPORT = 'MANAGE_ACTIVITY_REPORT',
    MANAGE_AGENDA = 'MANAGE_AGENDA',
    MANAGE_BUFFERZONES = 'MANAGE_BUFFERZONES',
    MANAGE_COMPANY_FUNCTIONS = 'MANAGE_COMPANY_FUNCTIONS',
    MANAGE_COMPANY_INFO = 'MANAGE_COMPANY_INFO',
    MANAGE_COMPANY_RISKS = 'MANAGE_COMPANY_RISKS',
    MANAGE_COMPANY_VISIT = 'MANAGE_COMPANY_VISIT',
    MANAGE_COURSES = 'MANAGE_COURSES',
    MANAGE_ENVIROMENTAL_INTERVENTIONS = 'MANAGE_ENVIROMENTAL_INTERVENTIONS',
    MANAGE_ERGONOMIC_INTERVENTIONS = 'MANAGE_ERGONOMIC_INTERVENTIONS',
    MANAGE_FLU_VACCINS = 'MANAGE_FLU_VACCINS',
    MANAGE_HEALTH_INTERVENTIONS = 'MANAGE_HEALTH_INTERVENTIONS',
    MANAGE_INVOICES = 'MANAGE_INVOICES',
    MANAGE_MEDICAL_INTERVENTIONS = 'MANAGE_MEDICAL_INTERVENTIONS',
    MANAGE_MISSING_EMAILS = 'manage_missing_emails',
    MANAGE_OTHER_INTERVENTIONS = 'MANAGE_OTHER_INTERVENTIONS',
    MANAGE_POLICY_ADVICE = 'MANAGE_POLICY_ADVICE',
    MANAGE_PREVENTION_UNITS = 'MANAGE_PREVENTION_UNITS',
    MANAGE_PSYCHOLOGICAL_INTERVENTIONS = 'MANAGE_PSYCHOLOGICAL_INTERVENTIONS',
    MANAGE_QUESTIONNAIRES = 'MANAGE_QUESTIONNAIRES',
    MANAGE_RATES = 'MANAGE_RATES',
    MANAGE_SAFETY_INTERVENTIONS = 'MANAGE_SAFETY_INTERVENTIONS',
    MANAGE_TOXILOGICAL_INTERVENTIONS = 'MANAGE_TOXILOGICAL_INTERVENTIONS',
    MANAGE_USERS = 'MANAGE_USERS',
    MANAGE_WORK_ACCIDENTS = 'MANAGE_WORK_ACCIDENTS',
    ORDER_FLU_VACCINS = 'ORDER_FLU_VACCINS',
    PLAN_COMPANY_VISIT = 'PLAN_COMPANY_VISIT',
    PLAN_ENVIROMENTAL_INTERVENTION = 'PLAN_ENVIROMENTAL_INTERVENTION',
    PLAN_ERGONOMIC_INTERVENTION = 'PLAN_ERGONOMIC_INTERVENTION',
    PLAN_HEALTH_INTERVENTION = 'PLAN_HEALTH_INTERVENTION',
    PLAN_MEDICAL_INTERVENTION = 'PLAN_MEDICAL_INTERVENTION',
    PLAN_OTHER_INTERVENTION = 'PLAN_OTHER_INTERVENTION',
    PLAN_PSYCHOLOGICAL_INTERVENTION = 'PLAN_PSYCHOLOGICAL_INTERVENTION',
    PLAN_SAFETY_INTERVENTION = 'PLAN_SAFETY_INTERVENTION',
    PLAN_TOXILOGICAL_INTERVENTION = 'PLAN_TOXILOGICAL_INTERVENTION',
}


export enum COURSE_TYPE_SVG_IDS {
    CLASSICAL = 'CLASSICAL',
    CUSTOM = 'CUSTOM',
    BLENDED = 'BLENDED',
    ELEARNING = 'ELEARNING',
}
