import React from 'react';

import ShowIfAllowed from '../../../auth/ShowIfAllowed';
import { getUpcomingActivity } from '../../../../redux/planning/selectors';
import { navigateTo } from '../../../../redux/location/actions';
import { connect } from '../../../index';

import { AgendaDashboardBlockProps } from './AgendaDashboardBlock.type';
import { PLAN_INTERVENTION_ROUTE_KEY, AGENDA_ROUTE } from './AgendaDashboardBlock.const';
import { PlanInterventionButton } from './PlanInterventionButton/PlanInterventionButton.component';
import { MiniAgenda } from './MiniAgenda/MiniAgenda.component';

const AgendaDashboardBlockComponent = (props: AgendaDashboardBlockProps) => {
    const { planIntervention, upcomingActivity } = props;
    return (
        <ShowIfAllowed routeKey={AGENDA_ROUTE.type}>
            <div className="section section--agenda">
                <ShowIfAllowed routeKey={PLAN_INTERVENTION_ROUTE_KEY}>
                    <PlanInterventionButton planIntervention={planIntervention} />
                </ShowIfAllowed>
                <MiniAgenda
                    upcomingActivity={upcomingActivity}
                />
            </div>
        </ShowIfAllowed>
    );
};

export const AgendaDashboardBlock = connect<AgendaDashboardBlockProps>({
    stateProps: (state) => {
        return {
            upcomingActivity: getUpcomingActivity(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            planIntervention: () => {
                dispatch(navigateTo(PLAN_INTERVENTION_ROUTE_KEY));
            },
        };
    },
})(AgendaDashboardBlockComponent);
