import React from 'react';
import ErrorDialog from '../../../../common/modals/ErrorDialog';

const TRANSLATION_PREFIX = 'document_center.courses.shared.passed_register_deadline_dialog';

interface IComponentProps {
    open: boolean;
    onClose: () => void;
    isClosedCourse?: boolean;
}

export function PassedRegistrationDueDateDialog(props: IComponentProps) {
    const translationKey = props.isClosedCourse
        ? `${TRANSLATION_PREFIX}.text`
        : `${TRANSLATION_PREFIX}.text_closed`;
    return (
        <ErrorDialog
            showOverride={props.open}
            hideRealErrorMessage={true}
            titleTranslationKey={`${TRANSLATION_PREFIX}.title`}
            infoTranslationKey={translationKey}
            onCloseDialog={props.onClose}
        />
    );
}
