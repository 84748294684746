import React from 'react';

import { ListColumns, ISortedColumn, SortOrder, SortType } from '../../../../../../models/general/list';
import Translate from '../../../../../common/Translate';

import { IPlanBufferzoneWizardOverviewListColumnNames } from '../PlanBufferzoneWizardOverview.type';
import { TRANSLATION_PREFIX } from '../PlanBufferzoneWizardOverview.const';

export const INITIAL_SORT: ISortedColumn<IPlanBufferzoneWizardOverviewListColumnNames> = {
    name: 'time',
    sortOrder: SortOrder.Ascending,
};

export const COLUMNS: ListColumns<IPlanBufferzoneWizardOverviewListColumnNames> = {
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    company: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 28,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 16,
        sortValue: (listItem) => listItem.columns.dateSort,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.start_hour`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
    },
};
