import React from 'react';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Translate from '../../common/Translate';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Tile from '../../common/widget/Tile/index';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import PageHeader from '../../appShell/PageHeader';
import { connect } from '../../index';
import { IInterventionItem } from '../../../models/general/navigation';
import {
    getInterventionPlanItems,
    INTERVENTION_ITEMS,
    INTERVENTION_MANAGEMENT_ITEMS,
} from '../../../config/navigation/interventions.config';
import ROUTE_KEYS from '../../../routeKeys';
import { getFluVaccinesConfig, showFluVaccinesCampaign } from '../../../redux/intervention/fluVaccine/selectors';
import { IFluVaccinesConfig } from '../../../models/interventions/fluVaccines';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import { navigateTo } from '../../../redux/location/actions';
import { getCompanyStatisticalCode } from '../../../redux/company/info/selectors';
import { TCompanyStatisticalCode } from '../../../config/company/companyInfo.config';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const getAllowedInterventionItems = makeAllowedNavigationItemsMemoizedSelector<IInterventionItem>(
    INTERVENTION_ITEMS,
);
const getAllowedInterventionMgmtItems = makeAllowedNavigationItemsMemoizedSelector<IInterventionItem>(
    INTERVENTION_MANAGEMENT_ITEMS,
);

const getAllowedInterventionPlanItemsMemoizedSelector = makeAllowedNavigationItemsMemoizedSelector<IInterventionItem>(
    getInterventionPlanItems(),
);

const FLU_VACCINES_ROUTE_KEY = ROUTE_KEYS.R_FLU_VACCINES_ORDERS;
const PLAN_INTERVENTION_ROUTE_KEY = ROUTE_KEYS.R_PLAN_INTERVENTION;

interface IPrivateProps {
    allowedInterventionItems: IInterventionItem[];
    allowedInterventionMgmtItems: IInterventionItem[];
    fluVaccinesConfig: IFluVaccinesConfig;
    showFluVaccinesCampaign: boolean;
    planIntervention: () => void;
    selectedCompanyStatisticalCode: TCompanyStatisticalCode;
    hasAllowedPlanInterventionItems: boolean;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            allowedInterventionItems: getAllowedInterventionItems(state),
            allowedInterventionMgmtItems: getAllowedInterventionMgmtItems(state),
            fluVaccinesConfig: getFluVaccinesConfig(state),
            showFluVaccinesCampaign: showFluVaccinesCampaign(state),
            selectedCompanyStatisticalCode: getCompanyStatisticalCode(state),
            hasAllowedPlanInterventionItems: getAllowedInterventionPlanItemsMemoizedSelector(state).length > 0,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            planIntervention: () => {
                dispatch(navigateTo(PLAN_INTERVENTION_ROUTE_KEY));
            },
        };
    },
})(InterventionsDashboard);

function InterventionsDashboard(props: IPrivateProps) {
    const {
        allowedInterventionItems,
        allowedInterventionMgmtItems,
        fluVaccinesConfig,
        showFluVaccinesCampaign,
        selectedCompanyStatisticalCode,
        hasAllowedPlanInterventionItems,
    } = props;

    return (
        <div className="tile-dashboard">
            <PageHeader
                title="interventions.overview.title"
                breadcrumbs={true}
            />
            <div className="container">
                {hasAllowedPlanInterventionItems && (
                    <ShowIfAllowed routeKey={PLAN_INTERVENTION_ROUTE_KEY}>
                        <PlanInterventionButton {...props} />
                    </ShowIfAllowed>
                )}
                <div className="sections-wrapper">
                    { allowedInterventionItems.length > 0 && (
                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems
                                    allowedInterventionItems={allowedInterventionItems}
                                />
                            </div>
                        </div>
                    )}
                    { (showFluVaccinesCampaign || allowedInterventionMgmtItems.length > 0) && (
                        <div className="section-sub">
                            <div>
                                <div className="section-inner">
                                    {showFluVaccinesCampaign && (
                                        <div className="tile-wrapper">
                                            <LinkToRoute
                                                id="to-flu-vaccines-tile"
                                                to={FLU_VACCINES_ROUTE_KEY}
                                            >
                                                <Tile
                                                    description={fluVaccinesConfig.description}
                                                    svg={fluVaccinesConfig.image.url}
                                                    className="Tile--flu-vaccines"
                                                    arrow={true}
                                                    svgAsImage
                                                />
                                            </LinkToRoute>
                                        </div>
                                    )}
                                    <NavigationManagementItems
                                        allowedInterventionMgmtItems={allowedInterventionMgmtItems}
                                        selectedCompanyStatisticalCode={selectedCompanyStatisticalCode}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function PlanInterventionButton(props: IPrivateProps) {
    return (
        <div className="top-button-wrapper">
            <Button
                id="interventions-plan-intervention-button"
                typeName="secondary"
                onClick={props.planIntervention}
            >
                <Icon typeName="calendar" />
                <Translate msg="interventions.overview.plan_intervention_button" />
            </Button>
        </div>
    );
}

function NavigationItems({ allowedInterventionItems }: Partial<IPrivateProps>) {
    const infoIcon = (
        <Icon
            typeName="info"
            circle
        />
    );

    return (
        <>
        {
            allowedInterventionItems
                .map((item) => {
                    const to = item.linkToPayload ? {
                        type: ROUTE_KEYS[item.linkTo],
                        payload: item.linkToPayload,
                    } : item.linkTo;

                    return (
                        <div className="tile-wrapper" key={item.id}>
                            <LinkToRoute
                                id={item.id}
                                to={to}
                            >
                                <Tile
                                    title={item.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: item.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.INTERVENTIONS,
                                    }}
                                    tooltipWithIcon={
                                        item.tooltipTranslationKey &&
                                        <TooltipWithIcon
                                            icon={infoIcon}
                                            typeName="info-bubble"
                                        >
                                            <span>
                                                <Translate msg={item.tooltipTranslationKey} />
                                            </span>
                                        </TooltipWithIcon>
                                    }
                                />
                            </LinkToRoute>
                        </div>
                    );
                })
        }
        </>
    );
}

function NavigationManagementItems({
    allowedInterventionMgmtItems,
    selectedCompanyStatisticalCode,
}: Partial<IPrivateProps>) {
    return (
        <>
        {
            allowedInterventionMgmtItems
                .map((item) => {
                    return (
                        <div className="tile-wrapper" key={item.id}>
                            <LinkToRoute
                                id={item.id}
                                to={item.linkTo}
                            >
                                <Tile
                                    title={item.titleTranslationKey}
                                    typeName="green"
                                    arrow={true}
                                    lazyLoadSvg={{
                                        id: item.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.INTERVENTIONS,
                                    }}
                                />
                            </LinkToRoute>
                        </div>
                    );
                })
        }
        </>
    );
}
