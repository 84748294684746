import React, { PureComponent } from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import './pageheader.scss';
import Breadcrumbs from '../Breadcrumbs';
import Translate, { TPlaceholders } from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import { ITabItem } from '../../../models/general/navigation';
import Notification from '../../common/widget/Notification';
import { Dropdown, DropdownItem } from '../../common/input/Dropdown';
import {TextNotification} from '../../common/widget/TextNotification/TextNotification.component';
import { connect } from '../../';
import { getRouteKey, getRoutePayload, isChildRouteKeyOf } from '../../../redux/location/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import Icon from '../../common/icons/Icon';
import { areAllMasterParamsEqualInOtherObject } from '../../../utils/core/object/diffObjects';
import InlineSVG from '../../common/icons/InlineSVG';
import LazyLoadSVG, { ILazyLoadSvgProps } from '../../common/widget/LazyLoadSVG';

interface IPrivateProps {
    activeRouteKey: ROUTE_KEYS;
    activeRoutePayload: object;
}

interface IPageHeaderProps {
    type?: 'white' | 'grey' | 'green';
    title: string;
    titlePlaceholders?: TPlaceholders;
    titleIcon?: string;
    breadcrumbs?: boolean;
    button?: React.ReactNode;
    text?: string;
    textPlaceholders?: TPlaceholders;
    isTextRaw?: boolean;
    svg?: string;
    lazyLoadSvg?: ILazyLoadSvgProps;
    tabs?: ITabItem[];
    customContent?: React.ReactNode;
    ignoreTranslationContext?: boolean;
}

interface IPageHeaderState {
    pageHeaderMenuOpen?: boolean;
}

const CLASS_NAME = 'PageHeader';

class PageHeader extends PureComponent<IPrivateProps & IPageHeaderProps, IPageHeaderState> {
    public static defaultProps: Partial<IPageHeaderProps> = {
        type: 'white',
    };

    constructor(props: IPrivateProps & IPageHeaderProps) {
        super(props);

        this.state = {
            pageHeaderMenuOpen: false,
        };

        this.onPageHeaderDropdownCloseIntent = this.onPageHeaderDropdownCloseIntent.bind(this);
        this.onPageHeaderDropdownOpenIntent = this.onPageHeaderDropdownOpenIntent.bind(this);
        this.renderTab = this.renderTab.bind(this);
    }

    public render() {
        const {
            type, title, titlePlaceholders, titleIcon,
            breadcrumbs, customContent, button,
            text, textPlaceholders, isTextRaw,
            svg, tabs, activeRouteKey, activeRoutePayload,
            ignoreTranslationContext,
            lazyLoadSvg,
        } = this.props;
        const { pageHeaderMenuOpen } = this.state;
        const showTabs = tabs && tabs.length > 0;
        const currentTab = getCurrentTab({
            tabs,
            activeRouteKey,
            activeRoutePayload,
        });

        return (
            <div className={`${CLASS_NAME} ${CLASS_NAME}--${type}`}>
                {breadcrumbs && <Breadcrumbs />}
                <div className="container">
                    <div className="content">
                        <div className="page-info">
                            <h1>
                                {titleIcon && (
                                    <InlineSVG
                                        svg={titleIcon}
                                        className="illustration"
                                    />
                                )}
                                <Translate
                                    msg={title}
                                    placeholders={titlePlaceholders}
                                    ignoreContext={ignoreTranslationContext}
                                />
                            </h1>
                            {customContent}
                            {button}
                            {text &&
                                <p className="text">
                                    <Translate
                                        msg={text}
                                        placeholders={textPlaceholders}
                                        raw={isTextRaw}
                                        ignoreContext={ignoreTranslationContext}
                                    />
                                </p>
                            }
                        </div>
                        {showTabs && (
                            // Show tabs on desktop
                            <nav className="tabs-nav">
                                {tabs.map((tab, index) => (
                                    <LinkToRoute
                                        key={`pageheader-tab-${tab.linkTo}-${index}`}
                                        id={`pageheader-tab-${tab.linkTo}-${index}`}
                                        to={getTabLinkToAction(tab)}
                                        activeClassName="active"
                                        shouldDispatch={tab !== currentTab}
                                    >
                                        {this.renderTab(tab, true)}
                                    </LinkToRoute>
                                ))}
                            </nav>
                        )}
                    </div>
                    {svg && (
                        <InlineSVG svg={svg} className="illustration" preserveAspectRatio="xMaxYMid meet" />
                    )}
                    {lazyLoadSvg && (
                        <LazyLoadSVG
                            id={lazyLoadSvg.id}
                            group={lazyLoadSvg.group}
                            className="illustration"
                            preserveAspectRatio="xMaxYMid meet"
                        />
                    )}
                </div>
                {showTabs && (
                    // Show select on mobile
                    <nav className="select-nav">
                        <Dropdown
                            id="pageheader-select"
                            isOpen={pageHeaderMenuOpen}
                            onOpenIntent={() => this.onPageHeaderDropdownOpenIntent()}
                            onCloseIntent={this.onPageHeaderDropdownCloseIntent}
                            defaultLabel={this.renderTab(currentTab)}
                            className="select-nav__dropdown"
                        >
                            {({ isDropdownOpen }) => {
                                return tabs.map((tab, index) => {
                                    if (tab !== currentTab) {
                                        return (
                                            <DropdownItem
                                                key={`pageheader-select-${tab.linkTo}-${index}`}
                                                withoutPadding={true}
                                            >
                                                <LinkToRoute
                                                    id={`pageheader-select-${tab.linkTo}-${index}`}
                                                    to={getTabLinkToAction(tab)}
                                                    activeClassName="active"
                                                >
                                                    {this.renderTab(tab, false, isDropdownOpen)}
                                                </LinkToRoute>
                                            </DropdownItem>
                                        );
                                    }
                                    return null;
                                });
                            }}
                        </Dropdown>
                    </nav>
                )}
            </div>
        );
    }

    private renderTab(tab: ITabItem, renderForDesktop: boolean = false, renderTooltips: boolean = true) {
        const { ignoreTranslationContext } = this.props;
        const infoIcon = (
            <Icon
                typeName="info"
                circle
            />
        );

        const notificationType = typeof tab.notificationContent;

        return (
            <div className={renderForDesktop ? `${CLASS_NAME}__tab-container` : 'select-nav__dropdown__item'}>
                {(tab.notificationContent && (notificationType === 'number' && tab.notificationContent > 0)) ?
                    <Notification
                        count={tab.notificationContent as number}
                        className={renderForDesktop && `${CLASS_NAME}__notification`}
                    /> :
                    undefined
                }
                {
                    (
                        tab.notificationContent &&
                        notificationType === 'string'
                    ) ? (
                        <TextNotification
                            text={tab.notificationContent as string}
                            className={renderForDesktop && `${CLASS_NAME}__notification`}
                        />
                    )
                    : undefined
                }
                <span>
                    <Translate
                        msg={tab.translationKey}
                        placeholders={tab.translationPlaceholders}
                        ignoreContext={ignoreTranslationContext}
                    />
                </span>
                {renderTooltips && tab.tooltipTranslationKey &&
                    <TooltipWithIcon
                        icon={infoIcon}
                        typeName="info-bubble"
                    >
                        <span>
                            <Translate msg={tab.tooltipTranslationKey} ignoreContext={ignoreTranslationContext} />
                        </span>
                    </TooltipWithIcon>
                }
            </div>
        );
    }

    private onPageHeaderDropdownCloseIntent() {
        this.setState({
            pageHeaderMenuOpen: false,
        });
    }

    private onPageHeaderDropdownOpenIntent() {
        this.setState({
            pageHeaderMenuOpen: true,
        });
    }
}

export default connect<IPrivateProps, IPageHeaderProps>({
    stateProps: (state) => ({
        activeRouteKey: getRouteKey(state),
        activeRoutePayload: getRoutePayload(state),
    }),
})(PageHeader);

function getTabLinkToAction(tab: ITabItem) {
    const tabRoutePayload = tab.linkToPayload || {};

    return {
        type: tab.linkTo,
        payload: {
            disableScrollToTop: true,
            ...tabRoutePayload,
        },
    };
}

function getCurrentTab({
    tabs,
    activeRouteKey,
    activeRoutePayload,
}: {
    tabs?: ITabItem[];
} & IPrivateProps) {
    if (!tabs || tabs.length === 0) {
        return null;
    }

    const currentTab = tabs
        .find((tab) => {
            if (tab.linkTo === activeRouteKey) {
                if (isSet(tab.linkToPayload)) {
                    return areAllMasterParamsEqualInOtherObject(tab.linkToPayload, activeRoutePayload);
                }

                return true;
            }

            return isChildRouteKeyOf(activeRouteKey, tab.linkTo);
        });

    return currentTab || tabs[0];
}
