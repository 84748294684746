import { createAction } from '@snipsonian/redux/es/action/createAction';
import {
    INIT_COOKIE_CONSENT,
    PUSH_COOKIE_CONSENTS_TO_GTM,
    PUSH_VISITOR_TYPE_TO_GTM,
    STORE_COOKIE_CONSENTS,
    UPDATE_COOKIE_CONSENTS,
} from './actionTypes';
import {
    IBaseCookiesConfiguration,
    ICookieConsentState,
    IVisitorTypePayload,
} from './types';

export function initCookieConsent(version: number = null) {
    return createAction(
        INIT_COOKIE_CONSENT,
        { version },
    );
}

export function updateCookieConsent(cookies: IBaseCookiesConfiguration) {
    return createAction<IBaseCookiesConfiguration>(
        UPDATE_COOKIE_CONSENTS,
        cookies,
    );
}

export function storeCookieConsent<ExtraMetadata extends {} = {}>(
    version: number,
    cookies: IBaseCookiesConfiguration,
    metadata: ExtraMetadata|null,
) {
    return createAction<ICookieConsentState>(
        STORE_COOKIE_CONSENTS,
        { version, cookies, metadata },
    );
}

export function pushCookieConfigurationToGTM(cookies: IBaseCookiesConfiguration) {
    return createAction<IBaseCookiesConfiguration>(
        PUSH_COOKIE_CONSENTS_TO_GTM,
        cookies,
    );
}

export function pushVisitorTypeToGTM(visitorType: string) {
    return createAction<IVisitorTypePayload>(
        PUSH_VISITOR_TYPE_TO_GTM,
        { visitorType },
    );
}
