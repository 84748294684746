import React from 'react';
import './course-tile.scss';
import { connect } from '../../../..';
import { ICMSCourse } from '../../../../../models/documentCenter/courses';
import { doesCompanyCurrentlyUsesPreventionUnits } from '../../../../../redux/preventionUnits/selectors';
import ROUTE_KEYS from '../../../../../routeKeys';
import { formatFloat } from '../../../../../utils/formatting/formatFloat';
import Icon from '../../../../common/icons/Icon';
import LinkToRoute from '../../../../common/navigation/LinkToRoute';
import Translate from '../../../../common/Translate';
import { isCourseWithCustomPrice } from '../courseUtils';
import LearningGoalIcon from '../LearningGoalIcon';

const CLASS_NAME = 'CourseTile';

interface ICourseTileProps {
    course: ICMSCourse;
}

interface IPrivateProps {
    hidePE: boolean;
}

function CourseTile(props: ICourseTileProps & IPrivateProps) {
    const { course, hidePE } = props;
    const {
        hostName,
        learningGoal,
        mainTargetGroup,
        mainTheme,
        media,
        name,
        new: newCourse,
        nodeId,
        pe,
        price,
    } = course;
    const isCustomPrice = isCourseWithCustomPrice(course);

    return (
        <div className={CLASS_NAME}>
            <LinkToRoute
                id={`link-to-course-${nodeId}`}
                to={{
                    type: ROUTE_KEYS.R_COURSES_DETAIL_INTRO,
                    payload: { nodeId },
                }}
                onClick={() => window.scrollTo(0, 0)}
            >
                {newCourse && (
                    <span className={`${CLASS_NAME}__flag`}>
                        <Translate msg="document_center.courses.overview.tile.new" />
                    </span>
                )}
                {(media && hostName) && (
                    <figure className={`${CLASS_NAME}__figure`}>
                        <img src={`${hostName}${media}`} alt="" />
                    </figure>
                )}
                <div className={`${CLASS_NAME}__content`}>
                    <div className="category">
                        {mainTheme}
                    </div>
                    <h3>{name}</h3>
                    <footer className="details">
                        <div>
                            {
                                mainTargetGroup && (
                                    <div className="details__item">
                                        <Icon typeName="user-outline" />
                                        <span>
                                            {mainTargetGroup}
                                        </span>
                                    </div>
                                )
                            }
                            <div className="details__item">
                                <Icon typeName="euro" />
                                <span>
                                    {!isCustomPrice ? (
                                        <Translate
                                            msg={`document_center.courses.overview.tile.price${hidePE ? '_no_pe' : ''}`}
                                            placeholders={{
                                                price: formatFloat(price),
                                                pe,
                                            }}
                                        />
                                    ) : (
                                        <Translate msg={'document_center.courses.overview.tile.custom_price'} />
                                    )}
                                </span>
                            </div>
                            <div className="details__item">
                                <LearningGoalIcon colour={learningGoal.colour} />
                                <span>
                                    {learningGoal.name || learningGoal}
                                </span>
                            </div>
                        </div>
                        <Icon typeName="chevron-right" circle />
                    </footer>
                </div>
            </LinkToRoute>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        hidePE: !doesCompanyCurrentlyUsesPreventionUnits(state),
    }),
})(CourseTile);
