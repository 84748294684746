import React, { Component } from 'react';
import classNames from 'classnames';
import isSet from '@snipsonian/core/es/is/isSet';

import formatNationalRegisterNumber from '../../../../../../../utils/formatting/formatNationalRegisterNumber';
import {
    addEmployeeActions,
    resetAddEmployeeData,
    updateAddEmployeeData,
} from '../../../../../../../redux/employee/wizard/actions';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../../models/general/redux';
import { BE_COUNTRY_CODE } from '../../../../../../../config/general.config';
import { confirmOnboardingWizardActions } from '../../../../../../../redux/onboarding/actions';
import { fetchEmployeesActions } from '../../../../../../../redux/employee/employees/actions';
import { formatDateForBackend } from '../../../../../../../utils/formatting/formatDate';
import {
    getAddEmployeeAsyncInfo,
    getEmployeeToAdd,
    isEmployeeToAddNotEmpty,
} from '../../../../../../../redux/employee/wizard/selectors';
import { getSelectedSeatHasStudentWorkers } from '../../../../../../../redux/company/selected/selectors';
import { IEmployeeToAdd } from '../../../../../../../models/admin/employee';
import { IOnboardEmployeeToAdd } from '../../../../../../../models/onboarding/wizard';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../../../../utils/formatting/formatPhone';
import { SHOWALL_FETCH_EMPLOYEES_PARAMETERS } from '../../../../../../../config/administration.config';

import AddressFields from '../../../../../../common/address/AddressFields';
import Button from '../../../../../../common/buttons/Button';
import Dialog from '../../../../../../common/modals/Dialog';
import Form, { IFormRenderProps } from '../../../../../../common/forms/Form';
import FormError from '../../../../../../common/forms/FormError';
import Loader from '../../../../../../common/waiting/Loader';
import SubmitButton from '../../../../../../common/buttons/SubmitButton';
import Translate from '../../../../../../common/Translate';
import TranslatorContext from '../../../../../../appShell/contexts/TranslatorContext';
import { connect } from '../../../../../..';
import { SLIDEOUTPANEL_CLASSES } from '../../../../../../common/widget/SlideOutPanel';
import { UNSELECTED_STREET } from '../../../../../../common/address/StreetTypeahead';
import { UNSELECTED_ZIPCODEID } from '../../../../../../common/address/CityTypeahead';

import AddEmployeeContact from './Contact';
import AddEmployeeGeneral from './General';
import AddEmployeeProfessional from './Professional';
import {
    fields,
    schema,
} from './personalDataSchema';

import './personal-data.scss';


type FormValues = IOnboardEmployeeToAdd;

interface IAddEmployeeProps {
    onCloseIntent: (restart?: boolean) => void;
    show: boolean;
}

interface IPrivateProps {
    employeeToAdd: IEmployeeToAdd;
    isEmployeeToAddNotEmpty: boolean;
    addEmployeeAsyncInfo: IAsyncFieldInfo;
    resetAddEmployeeData: () => void;
    dispatchAddEmployee: (employeeToAdd: IEmployeeToAdd) => void;
    fetchEmployees: () => void;
    hasStudentWorkers: boolean;
}

interface IComponentState {
    isAddFunctionDialogOpen: boolean;
    addAnotherEmployeeClicked: boolean;
}

const CLASS_NAME = 'OnboardingWizardAddEmployeePersonalData';

const FORM_NAME = 'onboarding-wizard-add-employee-personal-data';

const TRANSLATION_PREFIX = 'onboarding.wizard.steps.employees.add_employee.personal_data';

class PersonalData extends Component<IAddEmployeeProps & IPrivateProps, IComponentState> {
    private resetForm: (values: FormValues) => void;
    private submitForm: () => void;

    constructor(props: IAddEmployeeProps & IPrivateProps) {
        super(props);

        this.state = {
            isAddFunctionDialogOpen: false,
            addAnotherEmployeeClicked: false,
        };

        this.onCloseIntent = this.onCloseIntent.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resetComponent = this.resetComponent.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
        this.onToggleAddFunctionDialogHandler = this.onToggleAddFunctionDialogHandler.bind(this);
        this.addAnotherEmployeeHandler = this.addAnotherEmployeeHandler.bind(this);
    }

    public componentDidUpdate(prevProps: IAddEmployeeProps & IPrivateProps) {
        if (!prevProps.show && this.props.show) {
            this.resetForm(this.getInitialValues());
        }

        const {
            addEmployeeAsyncInfo,
            fetchEmployees,
        } = this.props;
        if (prevProps.addEmployeeAsyncInfo.status === AsyncStatus.Busy &&
            addEmployeeAsyncInfo.status === AsyncStatus.Success
        ) {
            this.onCloseIntent(this.state.addAnotherEmployeeClicked);

            this.setState({
                addAnotherEmployeeClicked: false,
            });

            fetchEmployees();
        }
    }

    public render() {
        const {
            addEmployeeAsyncInfo,
            employeeToAdd,
            hasStudentWorkers,
            isEmployeeToAddNotEmpty,
            show,
        } = this.props;
        const { isAddFunctionDialogOpen } = this.state;

        if (!isEmployeeToAddNotEmpty) {
            return null;
        }

        const initialValues: FormValues = this.getInitialValues();

        const header = `${TRANSLATION_PREFIX}.header`;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <Dialog
                        className={CLASS_NAME}
                        show={show}
                        onCloseIntent={this.onCloseIntent}
                        header={header}
                        transparent={isAddFunctionDialogOpen}
                    >

                        <Loader show={addEmployeeAsyncInfo.status === AsyncStatus.Busy}/>
                        <Form
                            name={FORM_NAME}
                            handleSubmit={this.onSubmit}
                            initialValues={initialValues}
                            schema={schema}
                            render={(formRenderProps: IFormRenderProps<FormValues>) => {

                                if (!this.resetForm) {
                                    this.resetForm = formRenderProps.resetForm;
                                }
                                this.submitForm = formRenderProps.submitForm;

                                formRenderProps.values.nationalRegisterNumber =
                                    formatNationalRegisterNumber(formRenderProps.values.nationalRegisterNumber || '');

                                return (
                                    <div className={`${CLASS_NAME}__form`}>
                                        <h4>
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.general`}/>
                                        </h4>
                                        <AddEmployeeGeneral
                                            formName={FORM_NAME}
                                            fields={fields}
                                            translator={translator}
                                            formRenderProps={formRenderProps}
                                            baseTranslationKey={TRANSLATION_PREFIX}
                                            isForeignEmployee={employeeToAdd.isForeignEmployee}
                                        />
                                        <h4>
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.address`}/>
                                        </h4>
                                        <AddressFields
                                            {...formRenderProps}
                                            translator={translator}
                                            initialAddress={initialValues.address}
                                            className={CLASS_NAME}
                                            hideRequiredMarkers
                                        />
                                        <h4>
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.professional`}/>
                                        </h4>
                                        <AddEmployeeProfessional
                                            formName={FORM_NAME}
                                            fields={fields}
                                            translator={translator}
                                            formRenderProps={formRenderProps}
                                            baseTranslationKey={TRANSLATION_PREFIX}
                                            onToggleAddFunctionDialog={this.onToggleAddFunctionDialogHandler}
                                            hasStudentWorkers={hasStudentWorkers}
                                        />
                                        <h4>
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.contact`}/>
                                        </h4>
                                        <AddEmployeeContact
                                            formName={FORM_NAME}
                                            fields={fields}
                                            translator={translator}
                                            formRenderProps={formRenderProps}
                                            baseTranslationKey={TRANSLATION_PREFIX}
                                        />
                                        {/* eslint-disable-next-line max-len */}
                                        <div className={classNames(SLIDEOUTPANEL_CLASSES.ACTIONS, `${CLASS_NAME}__actions`)}>
                                            <SubmitButton
                                                id="add-employee-submit-button"
                                                formName={FORM_NAME}
                                                alwaysEnabled={true}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.form.submit`} />
                                            </SubmitButton>
                                            <Button
                                                id="add-another-employee-submit-button"
                                                typeName="secondary"
                                                onClick={this.addAnotherEmployeeHandler}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.form.add_another`} />
                                            </Button>
                                        </div>
                                        {addEmployeeAsyncInfo.error && <FormError error={addEmployeeAsyncInfo.error} />}
                                    </div>
                                );
                            }}
                        />
                    </Dialog>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private addAnotherEmployeeHandler() {
        this.setState({
            addAnotherEmployeeClicked: true,
        });

        this.submitForm();
    }

    private onToggleAddFunctionDialogHandler(open: boolean) {
        this.setState({ isAddFunctionDialogOpen: open });
    }

    private getInitialValues(): FormValues {
        const { employeeToAdd } = this.props;
        return {
            address: employeeToAdd.address || {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: UNSELECTED_STREET,
                zipCodeId: UNSELECTED_ZIPCODEID,
            },
            customerFunctionId: employeeToAdd.function && employeeToAdd.function.id,
            employeeId: employeeToAdd.employeeId,
            companyCode: employeeToAdd.company && employeeToAdd.company.companyCode || '',
            dateInFunction: employeeToAdd.dateInFunction || formatDateForBackend(new Date()),
            email: employeeToAdd.email || '',
            medicalCenterId: employeeToAdd.medicalCenterId,
            employeeNumber: employeeToAdd.employeeNumber || '',
            firstName: employeeToAdd.firstName || '',
            languageId: employeeToAdd.languageId,
            mobilePhone: tryFormattingPhoneInternational(employeeToAdd.mobilePhone) || '',
            name: employeeToAdd.name || '',
            nationalityId: employeeToAdd.nationalityId,
            nationalRegisterNumber: employeeToAdd.nationalRegisterNumber,
            phone: tryFormattingPhoneInternational(employeeToAdd.phone) || '',
            sexId: employeeToAdd.sexId,
            birthDate: employeeToAdd.birthDate || '',
            employer: employeeToAdd.jobStudent && employeeToAdd.jobStudent.employer || '',
            medicalFunction: employeeToAdd.jobStudent && employeeToAdd.jobStudent.medicalFunction || false,
            safetyFunction: employeeToAdd.jobStudent && employeeToAdd.jobStudent.safetyFunction || false,
            sendMail: employeeToAdd.jobStudent && isSet(employeeToAdd.jobStudent.sendMail) ?
                employeeToAdd.jobStudent.sendMail : true,
            studentWorker: employeeToAdd.studentWorker || false,
        };
    }

    private onSubmit(values: FormValues) {
        const { employeeToAdd, dispatchAddEmployee } = this.props;

        const {
            nationalRegisterNumber,
            companyCode,
            customerFunctionId,
            employer,
            safetyFunction,
            medicalFunction,
            studentWorker,
            sendMail,
            phone,
            mobilePhone,
            ...valuesToUse } = values;

        const updatedEmployeeToAdd: IEmployeeToAdd = {
            ...valuesToUse,
            id: null,
            company: {
                companyCode: values.companyCode,
            },
            function: {
                id: values.customerFunctionId,
            },
            dateInService: values.dateInFunction,
            nationalRegisterNumber: employeeToAdd.nationalRegisterNumber,
            jobStudent: {
                employer: studentWorker ? employer : '',
                medicalFunction: studentWorker ? medicalFunction : false,
                safetyFunction: studentWorker ? safetyFunction : false,
                sendMail: studentWorker ? sendMail : false,
            },
            studentWorker,
            address: {
                ...values.address,
                zipCodeId: (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                    null : values.address.zipCodeId,
            },
            phone: tryFormattingPhoneForBackend(phone),
            mobilePhone: tryFormattingPhoneForBackend(mobilePhone),
            isForeignEmployee: false,
            birthDate: values.birthDate,
        };

        dispatchAddEmployee(updatedEmployeeToAdd);
    }

    private onCloseIntent(restart? : boolean) {
        const { onCloseIntent } = this.props;
        this.resetComponent();
        onCloseIntent(restart);
    }

    private resetComponent() {
        const { resetAddEmployeeData } = this.props;

        resetAddEmployeeData();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            employeeToAdd: getEmployeeToAdd(state),
            isEmployeeToAddNotEmpty: isEmployeeToAddNotEmpty(state),
            addEmployeeAsyncInfo: getAddEmployeeAsyncInfo(state),
            hasStudentWorkers: getSelectedSeatHasStudentWorkers(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            resetAddEmployeeData: () => {
                dispatch(resetAddEmployeeData());
            },
            dispatchAddEmployee: (employeeToAdd: IEmployeeToAdd) => {
                dispatch(addEmployeeActions.trigger(employeeToAdd));
                dispatch(confirmOnboardingWizardActions.reset({}));
            },
            fetchEmployees: () => {
                dispatch(fetchEmployeesActions.trigger({
                    ...SHOWALL_FETCH_EMPLOYEES_PARAMETERS,
                }));
            },
            updateEmployeeToAdd: (values) => {
                dispatch(updateAddEmployeeData(values));
            },
        };
    },
})(PersonalData);
