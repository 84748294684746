import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    UPDATE_WORK_POST_CARD_WIZARD_ENTITY,
    FETCH_WORK_POST_CARD_BLANK_FORMS,
    CREATE_WORK_POST_CARD_FORM,
    FETCH_WORK_POST_CARD_DEFAULT_VALUES,
    RESET_WORK_POST_CARD_WIZARD,
    FETCH_WORK_POST_FILE_OF_EMPLOYEE,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IWorkPostCardBlankForms,
    IWorkPostCardWizardData,
    IWorkPostCardWizardPayload,
    IWorkPostCardDefaultValues,
    IWorkPostFile,
} from '../../../models/documentCenter/workPostCards';
import { IAsyncFetchField, IAsyncDoField } from '../../../models/general/redux';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';
import { registerReducer } from '../../../utils/libs/redux/registerReducer';
import { getAsyncFetchInitialState, getAsyncDoInitialState } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { createAsyncFetchActionHandlers, createAsyncDoActionHandlers } from '../../index';
import { getCreateWorkPostCardWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.DOCUMENT_CENTER_WORKPOSTCARDS;

export interface IReducerState {
    workPostCardWizardData: IWorkPostCardWizardData;
    workPostCardBlankForms: IAsyncFetchField<IWorkPostCardBlankForms>;
    createWorkPostCardForm: IAsyncDoField;
    workPostCardDefaultValues: IAsyncFetchField<IWorkPostCardDefaultValues>;
    workPostCardFileOfEmployee: IAsyncFetchField<IWorkPostFile>;
}

const initialState: IReducerState = {
    workPostCardWizardData: null,
    workPostCardBlankForms: getAsyncFetchInitialState(),
    createWorkPostCardForm: getAsyncDoInitialState(),
    workPostCardDefaultValues: getAsyncFetchInitialState(),
    workPostCardFileOfEmployee: getAsyncFetchInitialState(),
};

const actionHandlers = {
    [ROUTE_KEYS.R_WORK_POST_CARD_NEW]:
        createActionHandler<IReducerState, IWorkPostCardWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                const WIZARD_FIRST_STEP_ID = getCreateWorkPostCardWizardSteps().firstStepId;
                return {
                    ...oldState,
                    workPostCardDefaultValues: (stepId === WIZARD_FIRST_STEP_ID) && payload.resetDataEntity ?
                        initialState.workPostCardDefaultValues : oldState.workPostCardDefaultValues,
                    workPostCardWizardData: (stepId === WIZARD_FIRST_STEP_ID)
                        ? {
                            stepId,
                            functionId: payload.resetDataEntity ?
                                payload.functionId :
                                oldState.workPostCardWizardData ?
                                    oldState.workPostCardWizardData.functionId : null,
                            locale: payload.resetDataEntity ?
                                payload.locale :
                                oldState.workPostCardWizardData ?
                                    oldState.workPostCardWizardData.locale : null,
                            entity: payload.resetDataEntity
                                ? {}
                                : (oldState.workPostCardWizardData && oldState.workPostCardWizardData.entity) || {},
                        }
                        : {
                            ...oldState.workPostCardWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_WORK_POST_CARD_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    workPostCardWizardData: {
                        ...oldState.workPostCardWizardData,
                        entity: {
                            ...oldState.workPostCardWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),

    ...createAsyncFetchActionHandlers<IWorkPostCardBlankForms, IReducerState, IWorkPostCardBlankForms>({
        baseActionType: FETCH_WORK_POST_CARD_BLANK_FORMS,
        fieldName: 'workPostCardBlankForms',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_WORK_POST_CARD_FORM,
        fieldName: 'createWorkPostCardForm',
    }),
    ...createAsyncFetchActionHandlers<IWorkPostCardDefaultValues, IReducerState, IWorkPostCardDefaultValues>({
        baseActionType: FETCH_WORK_POST_CARD_DEFAULT_VALUES,
        fieldName: 'workPostCardDefaultValues',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IWorkPostFile, IReducerState, IWorkPostFile>({
        baseActionType: FETCH_WORK_POST_FILE_OF_EMPLOYEE,
        fieldName: 'workPostCardFileOfEmployee',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    [RESET_WORK_POST_CARD_WIZARD]:
        createActionHandler<IReducerState>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    workPostCardWizardData: null,
                };
            },
        ),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that work-post-card steps are not lost when refreshing
        workPostCardWizardData: reducerState.workPostCardWizardData,
        workPostCardDefaultValues: reducerState.workPostCardDefaultValues,
    }),
    resetStateOnCompanySelection: true,
    resetStateOnSeatSelection: true,
});
