import React, { PureComponent } from 'react';
import { ListColumns, SortType, ListItem, ISortedColumn, SortOrder, ListItemId } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import { TMessageCenterProps, BASE_NAME } from '../common';
import { clone } from 'ramda';
import sortListItems from '../../../../utils/list/sortListItems';
import classNames from 'classnames';
import Icon from '../../../common/icons/Icon';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import List from '../../../common/list/List';
import './message-center-overview.scss';
import { IUserMessageWithMessageType } from '../../../../models/user/inbox';
import { formatDateToDayWithShortMonth } from '../../../../utils/formatting/formatDate';
import { isToday, isYesterDay } from '../../../../utils/core/date/isToday';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';

const CLASS_NAME = 'MessageCenterOverview';

export interface IColumnNames {
    attachment: string;
    subject: string;
    category: string;
    date: string;
    dateSort: string;
    read: boolean;
    companyName: string;
    companyCode: string;
}

export const COLUMNS: ListColumns<IColumnNames> = {
    category: {
        label: <Translate msg="account.message_center.overview.columns.category" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 18,
        excludeFromSearch: true,
    },
    attachment: {
        sortable: false,
        percentWidth: 5,
        align: 'right',
    },
    subject: {
        label: <Translate msg="account.message_center.overview.columns.subject" />,
        sortable: false,
        percentWidth: 28,
    },
    companyName: {
        label: <Translate msg="account.message_center.overview.columns.seat" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    companyCode: {
        label: <Translate msg="account.message_center.overview.columns.company_code" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 14,
    },
    date: {
        label: <Translate msg="account.message_center.overview.columns.date" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
        align: 'right',
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.dateSort,
        excludeFromSearch: true,
    },
    dateSort: {
        hide: true,
        percentWidth: 0,
    },
    read: {
        hide: true,
        percentWidth: null,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Descending,
};

interface IState<ColumnNames> {
    sortedItems: ListItem<ColumnNames>[];
    sortedColumn: ISortedColumn<ColumnNames>;
}

export default class MessageCenterOverview
    extends PureComponent<TMessageCenterProps, IState<IColumnNames>> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TMessageCenterProps) {
        super(props);

        const initialSort = INITIAL_SORT;

        this.state = {
            sortedItems: sortListItems(props.masterData, initialSort, this.columns[initialSort.name]),
            sortedColumn: initialSort,
        };

        this.onColumnSortChanged = this.onColumnSortChanged.bind(this);
        this.sortItems = this.sortItems.bind(this);
        this.columns.attachment.render = this.renderAttachment.bind(this);
        this.columns.date.render = this.renderDate.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: TMessageCenterProps) {
        if (nextProps.masterData !== this.props.masterData) {
            this.sortItems(nextProps, this.state.sortedColumn);
        }
    }

    public render() {
        const {
            masterAsyncInfo,
            selectedItemId,
            onItemSelected,
            footer,
        } = this.props;

        const { sortedColumn, sortedItems } = this.state;

        if (selectedItemId) {
            return (
                <div className={CLASS_NAME}>
                    <aside className={`${CLASS_NAME}__aside`}>
                        <div className={`${CLASS_NAME}__label`}>
                            <Translate msg="account.message_center.detail.columns.sender" />
                        </div>
                        <div className={`${CLASS_NAME}__aside__list`}>
                            <div className={`${CLASS_NAME}__aside__list__inner`}>
                                {sortedItems.map((message, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => onItemSelected(message.id)}
                                            className={classNames(`${CLASS_NAME}__aside__list__item`, {
                                                active: message.id === selectedItemId,
                                                unread: !message.columns.read,
                                            })}
                                        >
                                            <div className={`${CLASS_NAME}__aside__list__item__main`}>
                                                <div className="text">{message.columns.category}</div>
                                                <div className="date">{this.renderDate(message)}</div>
                                            </div>
                                            <div className={`${CLASS_NAME}__aside__list__item__subject`}>
                                                {message.columns.attachment && <Icon typeName="paper-clip" />}
                                                {message.columns.subject}
                                            </div>
                                        </div>
                                    );
                                })}
                                {masterAsyncInfo.error &&
                                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                            </div>
                        </div>
                    </aside>
                </div>
            );
        }

        return (
            <div className={CLASS_NAME}>
                <List
                    name={BASE_NAME}
                    columns={this.columns}
                    items={sortedItems}
                    sortedColumn={sortedColumn}
                    onItemRowClicked={onItemSelected}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onColumnSortChanged={this.onColumnSortChanged}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    footer={footer}
                    getCustomRowClasses={this.getCustomRowClasses}
                />
            </div>
        );
    }

    private renderAttachment(listItem: ListItem<IColumnNames>) {
        if (listItem.columns.attachment) {
            return (
                <Icon typeName="paper-clip" />
            );
        }

        return <span />;
    }

    private renderDate(listItem: ListItem<IColumnNames>) {
        if (listItem.columns.dateSort) {
            const date = listItem.columns.dateSort as string;
            const displayTime = <span>{formatTimeOfDateForDisplay(date)}</span>;

            const displayDate = isToday(date)
                ? <Translate msg="common.date.today" />
                : isYesterDay(date)
                    ? <Translate msg="common.date.yesterday" />
                    : <span>{formatDateToDayWithShortMonth(date)}</span>;

            return (
                <>
                    {displayDate}, {displayTime}
                </>
            );
        }

        return <span />;
    }

    public getCustomRowClasses(listItem: ListItem<IColumnNames, ListItemId>) {
        return listItem.columns.read ? 'read' : 'unread';
        // return listItem.data && !listItem.data.matchesClientSideFilterAndSearch
        //     ? 'no-filter-match'
        //     : null;
    }

    private onColumnSortChanged(nextSortedColumn: ISortedColumn<IColumnNames>) {
        this.sortItems(this.props, nextSortedColumn);
    }

    private sortItems(props: TMessageCenterProps, sortedColumn: ISortedColumn<IColumnNames>) {
        const { masterData } = props;
        const columnConfig = this.columns[sortedColumn.name];

        this.setState({
            sortedItems: sortListItems(masterData, sortedColumn, columnConfig),
            sortedColumn,
        });
    }
}

export function mapMessagesToListItems(masterData: IUserMessageWithMessageType[]): ListItem<IColumnNames>[] {
    return masterData.map((userMessage) => {
        return {
            id: userMessage.id,
            columns: {
                attachment: !!userMessage.attachment,
                category: userMessage.messageType && userMessage.messageType.description || '',
                date: '',
                dateSort: userMessage.created,
                read: userMessage.read,
                subject: userMessage.subject,
                companyName: userMessage.company.name || '',
                companyCode: userMessage.company.companyCode,
                actions: '',
            },
        } as ListItem<IColumnNames>;
    });
}
