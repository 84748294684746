import React from 'react';
import { pathOr } from 'ramda';

import connect from '../../../../../utils/libs/redux/connect';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    getCompanyMedicalCenters,
    getFetchCompanyMedicalCentersAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { getReservedMedicalExaminations } from '../../../../../redux/medicalExamination/selectors';
import Typeahead from '../../../../common/input/Typeahead';

import { ILocationTypeaheadProps, ILocationTypeaheadPrivateProps } from './LocationTypeahead.type';
import { makeLocationsForTypeaheadMemoizedSelector } from './LocationTypeahead.selector';
import { mapBufferzoneToBufferzoneMedicalCenter } from './LocationTypeahead.helper';

const LocationTypeaheadComponent = (props: ILocationTypeaheadProps & ILocationTypeaheadPrivateProps) => {

    const {
        bufferzones,
        children,
        disabled,
        disableReset,
        id,
        isInvalid,
        medicalCenters,
        medicalCentersTypeahead,
        name,
        onItemSelected,
        placeholderTranslationKey,
        translator,
        value,
    } = props;

    function onItemSelectedHandler(medicalCenterCode: string) {
        const selectedMedicalCenter = medicalCenters.find((item) => item.code === medicalCenterCode);

        if (props.includeBufferzones && !selectedMedicalCenter) {
            const selectedCompanyBufferzone =
                bufferzones.find((item) => item.location.code === medicalCenterCode || item.id === medicalCenterCode);
            const selectedBufferzoneLocation = pathOr(null, ['location'], selectedCompanyBufferzone);

            let bufferzoneMedicalCenter;

            if (selectedCompanyBufferzone && selectedBufferzoneLocation) {
                bufferzoneMedicalCenter = mapBufferzoneToBufferzoneMedicalCenter(
                    selectedCompanyBufferzone,
                );
            }

            onItemSelected(selectedMedicalCenter || bufferzoneMedicalCenter || null);
        } else {
            onItemSelected(selectedMedicalCenter || null);
        }
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={medicalCentersTypeahead}
            placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
            disabled={disabled}
            disableReset={disableReset}
            asyncInfoSelector={getFetchCompanyMedicalCentersAsyncInfo}
        >
            {children}
        </Typeahead>
    );
};

const LocationTypeahead = connect<ILocationTypeaheadPrivateProps, ILocationTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const medicalCenters = getCompanyMedicalCenters(state);
            const typeaheadItems = makeLocationsForTypeaheadMemoizedSelector({
                excludeInFirm: publicProps.excludeInCompanyMedicalCenter,
                includeBufferzones: publicProps.includeBufferzones,
            })(state);
            return {
                medicalCenters,
                bufferzones: getReservedMedicalExaminations(state),
                medicalCentersTypeahead: typeaheadItems,
                translator: getTranslatorDeprecated(state),
            };
        };
    },
})(LocationTypeaheadComponent);

export { LocationTypeahead };
