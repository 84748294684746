import { get } from '../../utils/api/requestWrapper';
import {
    IScreenworkQuestionnaire,
    IJobStudentQuestionnaire,
    IFetchQuestionnaireAdvicePayload,
    IQuestionnaireAdvice,
} from '../../models/admin/questionnaires';
import { ICompanyCodeWithShowFullFamilyPayload } from '../../models/admin/company';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import { IStartEndDateFilterValues } from '../../models/ui/form';

export const URL = {
    QUESTIONNAIRES: '/companies/{companyCode}/questionnaires',
    QUESTIONNAIRES_STUDENT: '/companies/{companyCode}/questionnaires/student',
    QUESTIONNAIRE_ADVICE: '/questionnaires/{id}/advice',
};

export const DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-1)),
    endDate: formatDateForBackend(now()),
};

export function fetchQuestionnairesScreenwork({
    companyCode,
    startDate = DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS.startDate,
    endDate = DEFAULT_QUESTIONNAIRES_SCREENWORK_FILTERS.endDate,
    showFullFamily,
}: IStartEndDateFilterValues & ICompanyCodeWithShowFullFamilyPayload) {
    return get<IScreenworkQuestionnaire[]>({
        url: URL.QUESTIONNAIRES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            dateFrom: startDate,
            dateUntil: endDate,
        },
        mapResponse: (response) => response.questionnaires,
    });
}

export const DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-1)),
    endDate: formatDateForBackend(now()),
};

export function fetchQuestionnairesJobStudents({
    companyCode,
    startDate = DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS.startDate,
    endDate = DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS.endDate,
    showFullFamily,
}: IStartEndDateFilterValues & ICompanyCodeWithShowFullFamilyPayload) {
    return get<IJobStudentQuestionnaire[]>({
        url: URL.QUESTIONNAIRES_STUDENT,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            dateFrom: startDate,
            dateUntil: endDate,
        },
        mapResponse: (response) => response['student-questionnaires'],
    });
}

export function fetchQuestionnaireAdvice({
    id,
}: IFetchQuestionnaireAdvicePayload) {
    return get<IQuestionnaireAdvice[]>({
        url: URL.QUESTIONNAIRE_ADVICE,
        pathParams: {
            id,
        },
        mapResponse: (response) => response['student-employee-advice'],
    });
}
