import {
    TUserAccountSeat,
    AdminType,
    IAddUserAccountPayload,
} from '../../../../../../../../models/user/userAccount';
import {
    ICompanyDismissableWithDate,
    ICompanySeat,
} from '../../../../../../../../models/admin/company';

export const parseAllSeatsToUserAccountSeats = (
    companyAllSeats: ICompanyDismissableWithDate[],
): TUserAccountSeat[] => companyAllSeats.map((seat) => ({
    companyCode: seat.companyCode,
    name: seat.name,
    id: seat.id,
}));

export const parseSeatsToUserAccountSeats = (
    companySeats: ICompanySeat[],
): TUserAccountSeat[] => companySeats.map((seat) => ({
    companyCode: seat.company.companyCode,
    name: seat.company.name,
    id: seat.company.id,
}));

export const isFutureAdminUser = (
    addUserWizardData: Partial<IAddUserAccountPayload>,
) => Number(addUserWizardData.admin) !== AdminType.None;
