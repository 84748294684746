import React from 'react';
import './rates.scss';
import { IInvoiceParameters } from '../../../models/admin/invoices';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import Translate from '../../common/Translate';
import ListItem from '../../common/list/ListItem';
import LabelInfo from '../../common/widget/LabelInfo';
import { formatFloat } from '../../../utils/formatting/formatFloat';
import { connect } from '../..';
import { getInvoiceParameters, getInvoiceParametersAsyncInfo } from '../../../redux/invoice/selectors';
import Loader from '../../common/waiting/Loader';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';

interface IPrivateProps {
    invoiceParameters: IInvoiceParameters[];
    asyncInfo: IAsyncFieldInfo;
}

const CLASS_NAME = 'RatesParameters';
const TRANSLATION_PREFIX = 'administration.rates.parameters';

function Parameters(props: IPrivateProps) {
    const {
        invoiceParameters,
        asyncInfo,
    } = props;

    return (
        <div className={CLASS_NAME}>
            <h2><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h2>
            <div className={`${CLASS_NAME}__content`}>
                <Loader show={asyncInfo.status} />

                {invoiceParameters.length > 0 &&
                    invoiceParameters.map((parameter) => (
                        <ListItem key={parameter.naceCode} text={ParametersItem(parameter)} />
                    ))
                }
                {asyncInfo.status === AsyncStatus.Success && invoiceParameters.length === 0 &&
                    <p><Translate msg={`${TRANSLATION_PREFIX}.no_data`} /></p>
                }
                {asyncInfo.error && <ErrorPlaceholder apiError={asyncInfo.error} />}
            </div>
        </div>
    );
}

const ParametersItem = (parameters: IInvoiceParameters) => (
    <>
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.nace_code`}
            info={parameters.naceCode}
        />
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.nace_description`}
            info={parameters.naceDescription}
        />
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.statistical_code`}
            info={parameters.statisticalCode}
        />
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.number_of_employees`}
            info={parameters.numberOfEmployees}
        />
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.internal_prevention_advisor`}
            info={parameters.internalPreventieAdvisor}
        />
        <LabelInfo
            labelKey={`${TRANSLATION_PREFIX}.label.tariff`}
            info={`€${formatFloat(parameters.tariff)}`}
        />
        <h6><Translate msg={`${TRANSLATION_PREFIX}.prevention_units.title`} /></h6>
        {parameters.calculatePU ? (
            <>
                <LabelInfo
                    labelKey={`${TRANSLATION_PREFIX}.label.prevention_units_partition`}
                    // eslint-disable-next-line max-len
                    info={<Translate msg={`${TRANSLATION_PREFIX}.prevention_units.type.${parameters.pePartition && parameters.pePartition.toLowerCase()}`} />}
                />
                <LabelInfo
                    labelKey={`${TRANSLATION_PREFIX}.label.prevention_units`}
                    info={formatFloat(parameters.walletPU, 4, true)}
                />
            </>
        ) : (
                <p>
                    <Translate msg={`${TRANSLATION_PREFIX}.prevention_units.no_prevention_units`} />
                </p>
            )}
    </>
);

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            invoiceParameters: getInvoiceParameters(state),
            asyncInfo: getInvoiceParametersAsyncInfo(state),
        };
    },
})(Parameters);
