import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Risk management documents overview
const getRiskManagementDocumentsAsyncField = (state: IState) =>
    reducerState(state).riskManagementDocuments;

export const getRiskManagementDocumentsAsyncInfo = makeAsyncFetchInfoSelector(getRiskManagementDocumentsAsyncField);

export const getRiskManagementDocuments = (state: IState) =>
    getRiskManagementDocumentsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areRiskManagementDocumentsAvailable = (state: IState) =>
    getRiskManagementDocumentsAsyncField(state).data !== null;

// Risk management document get document and file
const getFilesForRiskManagementDocumentsAsyncField = (state: IState) =>
    reducerState(state).riskManagementDocumentsFiles;

export const getFilesForRiskManagementDocumentsAsyncInfo =
    makeAsyncFetchInfoSelector(getFilesForRiskManagementDocumentsAsyncField);

export const getFilesForRiskManagementDocuments = (state: IState) =>
    getFilesForRiskManagementDocumentsAsyncField(state).data;

// Risk Management document files in bulk
const getRiskManagementDocumentFilesInBulkAsyncField = (state: IState) =>
    reducerState(state).riskManagementDocumentFilesInBulk;

export const getRiskManagementDocumentFilesInBulkAsyncInfo =
    makeAsyncFetchInfoSelector(getRiskManagementDocumentFilesInBulkAsyncField);
