import React from 'react';
import classNames from 'classnames';
import './button-page-part.scss';
import { IButtonPagePartData } from '../../../../../models/general/pageParts';
import OutgoingLink from '../../../navigation/OutgoingLink';

interface IPublicProps {
    data: IButtonPagePartData;
}

const CLASS_NAME = 'ButtonPagePart';

export default function ButtonPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <div className={classNames(CLASS_NAME, `${CLASS_NAME}--${data.position}`)}>
            <p>
                <OutgoingLink href={data.linkUrl} className={`${CLASS_NAME}__button`}>
                    {data.linkText}
                </OutgoingLink>
            </p>
        </div>
    );
}
