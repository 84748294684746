import { IState, getReducerState, makeAsyncFetchInfoSelector } from '..';
import { IReducerState, reducerKey } from './reducer';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Cookies configuration
const getCookiesConfigurationAsyncField = (state: IState) => reducerState(state).cookiesConfiguration;
export const getCookiesConfigurationAsyncInfo = makeAsyncFetchInfoSelector(getCookiesConfigurationAsyncField);
export const getCookiesConfiguration = (state: IState) => getCookiesConfigurationAsyncField(state).data;

// Cookie Consent
const getCookieConsentContentAsyncField = (state: IState) => reducerState(state).cookieConsentContent;
export const getCookieConsentContentAsyncInfo = makeAsyncFetchInfoSelector(getCookieConsentContentAsyncField);
export const getCookieConsentContent = (state: IState) => getCookieConsentContentAsyncField(state).data;

// Privacy Policy
const getPrivacyPolicyContentAsyncField = (state: IState) => reducerState(state).privacyPolicyContent;
export const getPrivacyPolicyContentAsyncInfo = makeAsyncFetchInfoSelector(getPrivacyPolicyContentAsyncField);
export const getPrivacyPolicyContent = (state: IState) => getPrivacyPolicyContentAsyncField(state).data;

// Disclaimer
const getDisclaimerContentAsyncField = (state: IState) => reducerState(state).disclaimerContent;
export const getDisclaimerContentAsyncInfo = makeAsyncFetchInfoSelector(getDisclaimerContentAsyncField);
export const getDisclaimerContent = (state: IState) => getDisclaimerContentAsyncField(state).data;
