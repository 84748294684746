import { LOCALES } from '../config/i18n.config';
import stripFileExtension from '../utils/file/stripFileExtension';
import supportWindowsPath from '../utils/file/supportWindowsPath';

/**
 * Only add the translation files of those labels that have to be rendered immediately (while waiting for the
 * rest call to retrieve all the translations), for example of the login page.
 */
const TRANSLATION_MODULES_FOR_INITIAL_RENDER = [
    'account',
    'appShell',
    'auth',
    'error',
];

const shippedTranslations = getInitialTranslationsFromYmlFiles();

export default shippedTranslations;

function getInitialTranslationsFromYmlFiles() {
    const emptyTranslations = prepareEmptyTranslationsForEachSupportedLocale();

    // jest does not know of require.context
    if (process.env.NODE_ENV === 'test') {
        return emptyTranslations;
    }

    return addYmlTranslationsOfSupportedLocales(emptyTranslations);
}

function prepareEmptyTranslationsForEachSupportedLocale() {
    return LOCALES.reduce(
        (accumulator, locale) => {
            accumulator[locale] = {};
            return accumulator;
        },
        {},
    );
}

function addYmlTranslationsOfSupportedLocales(emptyTranslations) {
    const translationFilesContext = getTranslationFilesContext();

    return translationFilesContext.keys()
        .map(supportWindowsPath)
        .map(toTranslationFileInfo)
        .filter(isForInitialRender)
        .filter(isLocaleSupported)
        .reduce(
            (translationsAccumulator, translationFileInfo) => addFileTranslationsToCorrectLocale(
                translationsAccumulator,
                translationFileInfo,
                translationFilesContext,
            ),
            emptyTranslations,
        );
}

// WARNING ⚠️: require.context is Webpack specific code!
function getTranslationFilesContext() {
    return require.context('.', true, /translations\/[\w\-]+\.yml$/);
}

function toTranslationFileInfo(translationFilePath) {
    const parts = translationFilePath.split('/');
    return {
        translationFilePath,
        locale: determineLocale(parts),
        module: parts[1], // parts[0] is always '.'
    };
}

// This assumes the yaml files are named [locale].yml
function determineLocale(parts) {
    return stripFileExtension(parts[parts.length - 1]);
}

function isForInitialRender(translationFileInfo) {
    return TRANSLATION_MODULES_FOR_INITIAL_RENDER.indexOf(translationFileInfo.module) > -1;
}

function isLocaleSupported(translationFileInfo) {
    return LOCALES.indexOf(translationFileInfo.locale) > -1;
}

function addFileTranslationsToCorrectLocale(
    translationsAccumulator,
    { translationFilePath, locale },
    translationFilesContext,
) {
    translationsAccumulator[locale] = {
        ...translationsAccumulator[locale],
        ...loadYamlFile(translationFilesContext, translationFilePath),
    };

    return translationsAccumulator;
}

function loadYamlFile(translationFilesContext, translationFilePath: string) {
    return translationFilesContext(translationFilePath);
}
