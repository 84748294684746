import React, { Component, MouseEvent } from 'react';
import './work-post-cards.scss';
import { connect } from '../../index';
import { Locales } from '../../../config/i18n.config';
import { triggerWorkPostCardWizard } from '../../../redux/documentCenter/workPostCards/actions';
import {
    getWorkPostCardBlankForms,
    getWorkPostCardBlankFormsAsyncInfo,
} from '../../../redux/documentCenter/workPostCards/selectors';
import Button from '../../common/buttons/Button';
import CloseIcon from '../../common/icons/CloseIcon';
import Translate from '../../common/Translate';
import Tooltip, { ITooltipProps } from '../../common/widget/Tooltip';
import { IWorkPostCardBlankForms } from '../../../models/documentCenter/workPostCards';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import { fetchTranslationsAndAllConstantsActions } from '../../../redux/i18n/actions';
import { getFetchTranslationsAndAllConstantsAsyncInfo } from '../../../redux/i18n/selectors';
import Loader from '../../common/waiting/Loader';
import openInNewTab from '../../../utils/browser/openInNewTab';

const TRANSLATION_PREFIX = 'document_center.work_post_cards.download_actions';

interface IPublicProps extends Pick<ITooltipProps, 'target' | 'placement'> {
    functionId: number;
}

interface IPrivateProps {
    workPostCardBlankFormsAsyncInfo: IAsyncFieldInfo;
    workPostCardBlankForms: IWorkPostCardBlankForms;
    goToWorkPostCardWizard: (functionId: number, locale: Locales) => void;
    fetchTranslationsAndAllConstants: (locale: Locales) => void;
    fetchTranslationsAndAllConstantsAsyncInfo: IAsyncFieldInfo;
}

interface IState {
    selectedLocale: Locales;
}

class DownloadActions extends Component<IPublicProps & IPrivateProps, IState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedLocale: null,
        };

        this.onDownloadBlankFormNl = this.onDownloadBlankFormNl.bind(this);
        this.onDownloadBlankFormFr = this.onDownloadBlankFormFr.bind(this);
        this.onTriggerFormWizardNl = this.onTriggerFormWizardNl.bind(this);
        this.onTriggerFormWizardFr = this.onTriggerFormWizardFr.bind(this);
        this.areTranslationsAvailableAndLocaleSelected = this.areTranslationsAvailableAndLocaleSelected.bind(this);
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        if (this.areTranslationsAvailableAndLocaleSelected(prevProps)) {
            this.props.goToWorkPostCardWizard(this.props.functionId, this.state.selectedLocale);
        }
    }

    public render() {
        const { fetchTranslationsAndAllConstantsAsyncInfo: fetchTranslationsAsyncInfo } = this.props;

        return (
            <Tooltip
                placement={this.props.placement}
                typeName="tooltip"
                target={this.props.target}
                spaceBetweenTarget={15}
            >
                {({ closeTooltip }) => {
                    return (
                        <div className="WorkPostCardDownloadActions">
                            <Loader show={fetchTranslationsAsyncInfo.status} />
                            <CloseIcon onClick={closeTooltip} />
                            <div className="button-list">
                                <Button
                                    id="trigger-wizard-form-NL"
                                    typeName="text"
                                    onClick={this.onTriggerFormWizardNl}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.create_form_nl`} />
                                </Button>
                                <Button
                                    id="download-blank-form-NL"
                                    typeName="text"
                                    disabled={!this.blankFormAvailableForLocale(Locales.nl_BE)}
                                    onClick={this.onDownloadBlankFormNl}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.get_blank_form_nl`} />
                                </Button>
                                <Button
                                    id="trigger-wizard-form-FR"
                                    typeName="text"
                                    onClick={this.onTriggerFormWizardFr}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.create_form_fr`} />
                                </Button>
                                <Button
                                    id="download-blank-form-FR"
                                    typeName="text"
                                    disabled={!this.blankFormAvailableForLocale(Locales.fr_BE)}
                                    onClick={this.onDownloadBlankFormFr}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.get_blank_form_fr`} />
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </Tooltip>
        );
    }

    private areTranslationsAvailableAndLocaleSelected(prevProps: IPublicProps & IPrivateProps) {
        return (
            prevProps.fetchTranslationsAndAllConstantsAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchTranslationsAndAllConstantsAsyncInfo.status === AsyncStatus.Success &&
            !!this.state.selectedLocale
        );
    }

    private onDownloadBlankFormNl(e: MouseEvent<HTMLElement>) {
        this.onDownloadBlankForm(e, Locales.nl_BE);
    }

    private onDownloadBlankFormFr(e: MouseEvent<HTMLElement>) {
        this.onDownloadBlankForm(e, Locales.fr_BE);
    }

    private onDownloadBlankForm(e: MouseEvent<HTMLElement>, locale: Locales) {
        e.preventDefault();
        e.stopPropagation();

        const { workPostCardBlankForms } = this.props;

        if (workPostCardBlankForms && workPostCardBlankForms.urls) {
            const urlBasedOnLocale = workPostCardBlankForms.urls[locale];
            openInNewTab(urlBasedOnLocale);
        }
    }

    private onTriggerFormWizardNl(e: MouseEvent<HTMLElement>) {
        e.preventDefault();
        e.stopPropagation();

        this.onTriggerFormWizard(e, Locales.nl_BE);
    }

    private onTriggerFormWizardFr(e: MouseEvent<HTMLElement>) {
        e.preventDefault();
        e.stopPropagation();

        this.onTriggerFormWizard(e, Locales.fr_BE);
    }

    private onTriggerFormWizard(e: MouseEvent<HTMLElement>, locale: Locales) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ selectedLocale: locale });
        this.props.fetchTranslationsAndAllConstants(locale);
    }

    private blankFormAvailableForLocale(locale: Locales) {
        const { workPostCardBlankFormsAsyncInfo, workPostCardBlankForms } = this.props;
        if (workPostCardBlankFormsAsyncInfo.status !== AsyncStatus.Success) {
            return false;
        }
        if (workPostCardBlankForms && workPostCardBlankForms.urls && workPostCardBlankForms.urls[locale]) {
            return true;
        }
        return false;
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            workPostCardBlankFormsAsyncInfo: getWorkPostCardBlankFormsAsyncInfo(state),
            workPostCardBlankForms: getWorkPostCardBlankForms(state),
            fetchTranslationsAndAllConstantsAsyncInfo: getFetchTranslationsAndAllConstantsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            goToWorkPostCardWizard: (functionId: number, locale: Locales) => {
                dispatch(triggerWorkPostCardWizard(functionId, locale));
            },
            fetchTranslationsAndAllConstants: (locale: Locales) => {
                dispatch(fetchTranslationsAndAllConstantsActions.trigger({ locale }));
            },
        };
    },
})(DownloadActions);
