import React from 'react';

import { formatDateForDisplay } from '../../../../../../utils/formatting/formatDate';
import {
    IEmployeeExecutedMedicalExamination,
    IEmployeePlannedMedicalExamination,
} from '../../../../../../models/admin/employee/medical-examination';
import ROUTE_KEYS from '../../../../../../routeKeys';
import ListItem from '../../../../../common/list/ListItem';


export function renderPlannedExaminations(
    employeeId: number,
    routeKey: ROUTE_KEYS,
    examinations: IEmployeePlannedMedicalExamination[],
    selectedExaminationId: number,
    routePayload: object = {},
) {
    return (
        <>
            {
                examinations.map((examination) => {
                    return (
                        <ListItem
                            id={`examination-detail-planned-medical-examination-${examination.timeSlotId}`}
                            key={examination.timeSlotId}
                            text={formatDateForDisplay(examination.start)}
                            to={{
                                type: routeKey,
                                payload: {
                                    ...routePayload,
                                    timeSlotId: examination.timeSlotId,
                                    id: employeeId,
                                },
                            }}
                            selected={selectedExaminationId === examination.timeSlotId}
                        />
                    );
                })
            }
        </>
    );
}

export function renderExecutedExaminations(
    employeeId: number,
    routeKey: ROUTE_KEYS,
    examinations: IEmployeeExecutedMedicalExamination[],
    selectedExaminationId: number,
    routePayload: object = {},
) {
    return (
        <>
            {
                examinations.map((examination) => {
                    return (
                        <ListItem
                            id={`examination-detail-executed-medical-examination-${examination.id}`}
                            key={examination.id}
                            text={formatDateForDisplay(examination.examinationDate)}
                            to={{
                                type: routeKey,
                                payload: {
                                    ...routePayload,
                                    examinationId: examination.id,
                                    id: employeeId,
                                },
                            }}
                            selected={selectedExaminationId === examination.id}
                        />
                    );
                })
            }
        </>
    );
}
