import React, { ReactNode, ChangeEvent, MouseEvent, TouchEvent } from 'react';
import classNames from 'classnames';
import './checkbox.scss';

interface ICheckboxProps {
    name: string;
    checked?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    toggleButton?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    boldOnChecked?: boolean;
    size?: 'small';
    onDivClicked?: (e: MouseEvent<HTMLDivElement>) => void;
    indeterminate?: boolean; // show indeterminate icon when checked
}

const Checkbox = (props: ICheckboxProps) => {
    const checkboxClass = classNames({
        Checkbox: !props.toggleButton,
        ToggleButton: props.toggleButton,
        [`${props.className}`]: !!props.className,
        ['bold']: props.boldOnChecked && props.checked,
        small: props.size === 'small',
        indeterminate: props.indeterminate,
    });

    let xDown = null;
    let yDown = null;

    return (
        <div
            className={checkboxClass}
            onClick={props.onDivClicked}
        >
            <input
                type="checkbox"
                name={props.name}
                id={props.name}
                defaultChecked={!props.onChange ? props.checked || false : undefined}
                checked={props.onChange ? props.checked || false : undefined}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <label
                htmlFor={props.name}
                onTouchStart={props.toggleButton && handleTouchStartToggleButton}
                onTouchEnd={props.toggleButton && handleTouchMoveToggleButton}
            >
                {props.children}
            </label>
        </div>
    );

    // Touch support for toggle buttons
    function handleTouchStartToggleButton(evt: TouchEvent) {
        const firstTouch = evt.touches[0];
        if (firstTouch) {
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
        }
    }

    function handleTouchMoveToggleButton(evt: TouchEvent) {
        const label = evt.currentTarget as HTMLElement;
        const divContainer = label.parentNode as HTMLElement;
        const checkbox = divContainer.querySelector('input[type=checkbox]') as HTMLInputElement;

        if (!xDown || !yDown || !checkbox || !evt.touches[0]) {
            return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0 && checkbox.checked || xDiff < 0 && !checkbox.checked) {
                label.click();
            }
        }

        // reset values
        xDown = null;
        yDown = null;
    }
};

export default Checkbox;
