import 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { STATE_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import createReduxStore from '@snipsonian/redux/es/store/createReduxStore';
import createConsoleLoggerMiddleware from '@snipsonian/redux/es/middleware/createConsoleLoggerMiddleware';
import createJourneyMiddleware from '@snipsonian/redux/es/middleware/journey/createJourneyMiddleware';
import localStorage from '@snipsonian/browser/es/storage/localStorage';

import configureReduxFirstRouter from './configureReduxFirstRouter';
import { STATE_STORAGE_KEY, REDUCER_KEYS } from '../config/redux.config';
import { isReduxLoggingEnabled } from '../config/develop.config';
import api from '../api';
import { IState } from './IState';
import createErrorLoggerMiddleware from './middleware/createErrorLoggerMiddleware';
import { getCsrfToken } from './auth/selectors';
import { getLocale } from './i18n/selectors';
import {
    isCsrfTokenSet,
    setCsrfToken,
    isLocaleHeaderSet,
    setLocaleHeader,
} from '../utils/api/requestWrapper';
import { keepStateStorageTypeIfNotDisabled } from '../utils/libs/redux/keepStorageTypeIfNotDisabled';
import { getRegisteredLogicsGroupedToAvoidOutOfStackSpaceError } from '../utils/libs/redux/epic/logicManager';
import { reportError } from '../utils/logging/errorReporter';
import { isSecureEnv } from '../utils/env/index';
import { createCookieStorage } from '../snipsonian/redux/src/store/cookieStorageFactory';

import './allReducers';
import './allEpicsAndJourneys';

const epicInjectedDependencies = {
    api,
};

const logicMiddlewares = getRegisteredLogicsGroupedToAvoidOutOfStackSpaceError()
    .map((epicGroup) => createLogicMiddleware(epicGroup, epicInjectedDependencies));

// has to come after importing all reducers (otherwise state not persisted)
const routerConfig = configureReduxFirstRouter();

/**
 * When a deep-linked route does a fetch the csrf token and locale header could not be set yet
 * This makes sure that it is set from the first request
 */
setCsrfTokenFromLocalstorage();
setLocaleHeaderFromLocalstorage();

const store = createReduxStore<IState>({
    // tslint:disable no-any
    middlewares: getMiddleWares() as any[], // TODO: Snipsonian types need to be added
    enhancers: getEnhancers() as any[], // TODO: Snipsonian types need to be added
    // tslint:enable no-any
    stateStorageType: keepStateStorageTypeIfNotDisabled(STATE_STORAGE_TYPE.SESSION),
    stateStorageKey: STATE_STORAGE_KEY,
    customStorage: createCookieStorage({
        reducerConfigs: [
            {
                cookieName: 'legal_cookie',
                reducerKey: REDUCER_KEYS.COOKIE_CONSENT,
                ttlInMillis: (10 * 365 * 24 * 60 * 60),
                path: '/',
                secure: isSecureEnv,
            },
        ],
    }),
});

export default store;

function getMiddleWares() {
    /* uncomment for additional monitoring of redux-logic internal operations */
    // logicMiddlewares[0].monitor$.subscribe(
    //     (x) => console.log('LOGIC', x),
    // );

    /* The middlewares will run in the order specified here */
    const middlewares = [
        ...logicMiddlewares,
        createJourneyMiddleware({
            extraProcessInput: epicInjectedDependencies,
        }),
        createErrorLoggerMiddleware(),
        routerConfig.middleware,
    ];

    if (isReduxLoggingEnabled) {
        middlewares.push(createConsoleLoggerMiddleware({ collapsed: true }));
    }

    return middlewares;
}

function getEnhancers() {
    return [routerConfig.enhancer];
}

function setCsrfTokenFromLocalstorage() {
    if (!isCsrfTokenSet()) {
        try {
            const stateInStorage = readStateFromLocalStorage();
            if (stateInStorage) {
                setCsrfToken(getCsrfToken(stateInStorage));
            }
        } catch (err) {
            reportError(err, { level: 'warning' });
        }
    }
}

function setLocaleHeaderFromLocalstorage() {
    if (!isLocaleHeaderSet()) {
        try {
            const stateInStorage = readStateFromLocalStorage();
            if (stateInStorage) {
                setLocaleHeader(getLocale(stateInStorage));
            }
        } catch (err) {
            reportError(err, { level: 'warning' });
        }
    }
}

export function readStateFromLocalStorage() {
    return localStorage.read({ key: STATE_STORAGE_KEY }) as IState;
}
