import React, { ReactElement, ReactNode } from 'react';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import { IRenderDetailFooterProps } from '../typings';
import FormError from '../../../forms/FormError';

interface IDetailPanelFooterProps {
    renderFooter?: (props: IRenderDetailFooterProps) => ReactElement<{ children: ReactNode }>;
    detailAsyncInfo: IAsyncFieldInfo;
    detailData: object;
    detailUpdateAsyncInfo: IAsyncFieldInfo;
}

export default function DetailPanelFooter(props: IDetailPanelFooterProps) {
    const {
        renderFooter, detailAsyncInfo, detailData, detailUpdateAsyncInfo,
    } = props;
    return (
        <>
            {detailUpdateAsyncInfo && detailUpdateAsyncInfo.error &&
                <FormError error={detailUpdateAsyncInfo.error} />
            }
            {typeof renderFooter === 'function' && renderFooter({
                detailAsyncInfo,
                detailData,
                detailUpdateAsyncInfo,
            })}
        </>
    );
}
