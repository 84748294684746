import React from 'react';
import SingleAttachment from './SingleAttachment';
import { IUserMessageDetails } from '../../../../../models/user/inbox';
import { isArrayOfIUserMessageDetailAction } from '../MessageBody/messageBodyUtils';

const CLASS_NAME = 'MessageAttachment';
const ATTACHMENT_EXTRA_KEY = 'attachment';

interface IPublicProps {
    messageDetail: IUserMessageDetails;
}

export default function MessageAttachment(props: IPublicProps) {
    return (
        <div className={CLASS_NAME}>
            {renderMessageAttachment(props.messageDetail)}
        </div>
    );
}

function renderMessageAttachment(messageDetail: IUserMessageDetails) {
    const oneOrMoreAttachments = messageDetail.extra && messageDetail.extra[ATTACHMENT_EXTRA_KEY];

    if (!oneOrMoreAttachments) {
        return null;
    }

    if (isArrayOfIUserMessageDetailAction(oneOrMoreAttachments)) {
        if (oneOrMoreAttachments.length === 0) {
            return null;
        }

        /**
         * For now we assume there will only be max 1 attachment.
         */
        return (
            <SingleAttachment attachment={oneOrMoreAttachments[0]} />
        );
    }

    return (
        <SingleAttachment attachment={oneOrMoreAttachments} />
    );
}
