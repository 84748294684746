export const URL = {
    DOCUMENT: '/documents',
    CONVOCATIONS: '/convocations',
    CONVOCATION_RECIPIENTS: '/companies/{companyCode}/convocation-recipients',
    UPLOAD_EMPLOYEE_DOCUMENT: '/uploads/employees/{id}/documents',
    ACTIVITY_REPORTS: '/companies/{companyCode}/activity-report/{year}',
    ACTIVITY_REPORT_GENERATE: '/companies/{companyCode}/activity-report',
    ACTIVITY_REPORT_DETAIL: '/companies/{companyCode}/activity-report/{year}/{id}',
    COMPANY_INFO_REPORT: '/companies/{companyCode}/info-report',
};

export const BFF_URL = {
    CONVOCATIONS: '/activities/planning-slots/invitation/customerId/{customerId}',
};
