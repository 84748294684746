import React from 'react';

import {
    getReservedMedicalExaminations,
    getReservedMedicalExaminationsAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import { getRoutePayload } from '../../../../../../redux/location/selectors';
import { IPlanBufferzoneWizardPayload } from '../../../../../../models/interventions/bufferzones';
import { ListItem } from '../../../../../../models/general/list';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { connect } from '../../../../../index';
import {
    IRenderFilterContentProps,
    IRenderMasterContentProps,
    IRenderSearchContentProps,
} from '../../../../../common/widget/MasterWithDetail/typings';
import { startEndDateSchema } from '../../../../../common/input/StartEndDateFilter/startEndDateSchema';
import MasterWithDetail from '../../../../../common/widget/MasterWithDetail';
import {
    clientSideFilterOfListData,
    getDefaultQueryParams,
    shouldRenderShowAllButton,
    transformFilterValuesToActiveFilters,
} from '../../../../MedicalExaminations/Reserved/Overview/ReservedMedicalExaminationOverview.helper';
import {
    FilterContent,
    SearchContent,
} from '../../../../MedicalExaminations/Reserved/Overview/ReservedMedicalExaminationOverview.component';
import {
    IFilterValues,
} from '../../../../MedicalExaminations/Reserved/Overview/ReservedMedicalExaminationOverview.type';

import { BASE_NAME } from './SelectBufferzoneOverview.const';
import { BufferzonesList } from './BufferzonesList/BufferzonesList.component';
import { COLUMNS } from './BufferzonesList/BufferzonesList.const';
import {
    IPrivateProps,
    ISelectBufferzoneOverviewColumnNames,
    ISelectBufferzoneOverviewProps,
} from './SelectBufferzoneOverview.type';
import { mapBufferzonesToListItems } from './SelectBufferzoneOverview.helper';

const SelectBufferzoneOverviewComponent = (props: ISelectBufferzoneOverviewProps) => {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
                routePayload: props.currentRoutePayload,
                asyncInfoSelector: getReservedMedicalExaminationsAsyncInfo,
                dataSelector: getReservedMedicalExaminations,
                transformData: mapBufferzonesToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<
                    ListItem<ISelectBufferzoneOverviewColumnNames>[],
                    IFilterValues
                >) => <BufferzonesList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent: (
                    renderProps: IRenderFilterContentProps<
                    ListItem<ISelectBufferzoneOverviewColumnNames>[],
                    IFilterValues
                >) => <FilterContent {...renderProps} />,
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
};

export const SelectBufferzoneOverview = connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            bufferzones: getReservedMedicalExaminations(state),
            currentRoutePayload: getRoutePayload<IPlanBufferzoneWizardPayload>(state),
        };
    },
})(SelectBufferzoneOverviewComponent);
