
import classNames from 'classnames';
import React, { PureComponent } from 'react';

import { nationalityIdBelgium,
    personalDataFields,
    personalDataSchema,
} from './PersonalDataSchema';

import EditEmployeeAddress from '../../../shared/EditEmployeeAddress';

import ConstantsTypeahead from '../../../../../common/input/ConstantsTypeahead';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import Dialog from '../../../../../common/modals/Dialog';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import FormError from '../../../../../common/forms/FormError';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Loader from '../../../../../common/waiting/Loader';
import PageHeader from '../../../../../appShell/PageHeader';
import RequiredMarker from '../../../../../common/input/RequiredMarker';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import TextInput from '../../../../../common/input/TextInput';
import Translate from '../../../../../common/Translate';
import { connect } from '../../../../..';
import { NationalRegisterNumberTextInput } from '../../../../../common/input/NationalRegisterNumberInput';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

import formatNationalRegisterNumber from '../../../../../../utils/formatting/formatNationalRegisterNumber';
import { ADD_EMPLOYEE_PLACEHOLDER_KEYS } from '../../../../../../config/administration.config';
import { AsyncStatus } from '../../../../../../models/general/redux';
import { ConstantType } from '../../../../../../models/general/constants';
import { ErrorTypes, ITraceableApiError } from '../../../../../../models/general/error';
import { formatAddress } from '../../../../../../utils/formatting/formatAddress';
import { formatDateForBackend } from '../../../../../../utils/formatting/formatDate';
import {
    getEmployeeToAdd,
} from '../../../../../../redux/employee/wizard/selectors';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { IAddressSave } from '../../../../../../models/general/address';
import { IEmployeeToAdd } from '../../../../../../models/admin/employee';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { ITranslator } from '../../../../../../models/general/i18n';
import { now } from '../../../../../../utils/core/date/getSpecificDate';
import {
    tryFormattingPhoneInternational,
} from '../../../../../../utils/formatting/formatPhone';
import { updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';

type FormValues = Pick<IEmployeeToAdd,
    'address' |
    'birthDate' |
    'email' |
    'employeeNumber' |
    'firstName' |
    'languageId' |
    'mobilePhone' |
    'name' |
    'nationalityId' |
    'nationalRegisterNumber' |
    'phone' |
    'sexId'>;

interface IPrivateProps {
    translator: ITranslator;
    employeeToAdd: IEmployeeToAdd;
    shouldUpdateOnNext: boolean;
    updateIsBusy: boolean;
    updateError: ITraceableApiError;
    updateStatus: AsyncStatus;
    updateEmployeeToAdd: (values: FormValues) => void;
    updateEmployee: (values: FormValues) => void;
}

interface IState {
    isEditAddressDialogOpen: boolean;
    currentAddress: IAddressSave;
}

const FORM_NAME = 'add-employee-personal-data-form';

const INSZ_MISSING = 'administration.employees.add.steps.personal_data.validate.national_register_number_missing';

class PersonalData extends PureComponent<IStepperStepRenderProps & IPrivateProps, IState> {

    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isEditAddressDialogOpen: false,
            currentAddress: null,
        };

        this.onCloseEditAddress = this.onCloseEditAddress.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IStepperStepRenderProps) {
        if (this.props.shouldUpdateOnNext &&
            prevProps.updateStatus === AsyncStatus.Busy &&
            this.props.updateStatus === AsyncStatus.Success
        ) {
            this.props.goToNextStep();
        }
    }

    public render() {
        const {
            employeeToAdd,
            goToNextStep,
            renderStepButtons,
            translator,
            shouldUpdateOnNext,
            updateEmployee,
            updateEmployeeToAdd,
            updateIsBusy,
            updateError,
        } = this.props;

        const INITIAL_VALUES: FormValues = employeeToAdd ? {
            address: employeeToAdd.address || null,
            birthDate: employeeToAdd.birthDate,
            email: employeeToAdd.email,
            employeeNumber: employeeToAdd.employeeNumber,
            firstName: employeeToAdd.firstName,
            languageId: employeeToAdd.languageId,
            mobilePhone: tryFormattingPhoneInternational(employeeToAdd.mobilePhone),
            name: employeeToAdd.name,
            nationalityId: employeeToAdd.nationalityId,
            nationalRegisterNumber: formatNationalRegisterNumber(employeeToAdd.nationalRegisterNumber),
            phone: tryFormattingPhoneInternational(employeeToAdd.phone),
            sexId: employeeToAdd.sexId,
        } : {} as FormValues;

        function handleSubmit(values: FormValues) {
            const formattedValues: FormValues = {
                ...values,
                // Phone numbers formatted for backend in Epic
                phone: tryFormattingPhoneInternational(values.phone),
                mobilePhone: tryFormattingPhoneInternational(values.mobilePhone),
            };
            if (shouldUpdateOnNext) {
                updateEmployee(formattedValues);
            } else {
                updateEmployeeToAdd(formattedValues);
                goToNextStep();
            }
        }

        let setFormFieldValue: (
            field: keyof FormValues,
            value: string | number | object,
        ) => void;

        return (
            <>
                <PageHeader
                    title="administration.employees.add.steps.personal_data.title"
                    text="administration.employees.add.steps.personal_data.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        initialValues={INITIAL_VALUES}
                        handleSubmit={handleSubmit}
                        schema={personalDataSchema}
                        render={({
                            errors,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                            touched,
                            values,
                        }: IFormRenderProps<FormValues>) => {
                            setFormFieldValue = setFieldValue;

                            function onSexItemSelected(value: number) {
                                setFieldValue('sexId', value);
                            }
                            function onNationalityItemSelected(value: number | string) {
                                if (typeof value === 'string') {
                                    setFieldValue('nationalityId', parseInt(value as string, 10));
                                    return;
                                }
                                setFieldValue('nationalityId', value);
                            }
                            function onLanguageItemSelected(value: number) {
                                setFieldValue('languageId', value);
                            }
                            return (
                                <Loader show={updateIsBusy}>
                                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                        <h4>
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.detail.employee_details.subtitles.personal"
                                            />
                                        </h4>
                                        {employeeToAdd.isForeignEmployee ?
                                            <FloatableTextInputWrapper floatLabel>
                                                <DatePicker
                                                    id="add-employee-birth-date"
                                                    placeholder={translator(
                                                        ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate,
                                                    )}
                                                    value={values.birthDate}
                                                    name={personalDataFields.birthDate}
                                                    onChange={(value) => setFieldValue('birthDate', value)}
                                                    maxDate={formatDateForBackend(now())}
                                                    isInvalid={touched.birthDate && !!errors.birthDate}
                                                >
                                                    <label htmlFor="add-employee-birth-date">
                                                        <Translate
                                                            msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate}
                                                        />
                                                        <RequiredMarker />
                                                    </label>
                                                </DatePicker>
                                                {touched.birthDate && (
                                                    <FormFieldError
                                                        error={errors.birthDate}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate,
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>
                                        :
                                            <FloatableTextInputWrapper floatLabel>
                                                <NationalRegisterNumberTextInput
                                                    id="add-employee-nationalregisternumber"
                                                    name={personalDataFields.nationalRegisterNumber}
                                                    value={values.nationalRegisterNumber || ''}
                                                    onChange={handleChange}
                                                    disabled={true}
                                                    isInvalid={touched.nationalRegisterNumber
                                                        && !!errors.nationalRegisterNumber
                                                    }
                                                />
                                                <label htmlFor="add-employee-nationalregisternumber">
                                                    <Translate
                                                        msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalRegisterNumber}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                                {touched.nationalRegisterNumber
                                                    && values.nationalityId === nationalityIdBelgium
                                                    &&
                                                    (<FormFieldError
                                                        error={errors.nationalRegisterNumber}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalRegisterNumber),
                                                        }}
                                                    />)
                                                }
                                            </FloatableTextInputWrapper>
                                        }
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-firstname"
                                                name={personalDataFields.firstName}
                                                value={values.firstName || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.firstName && !!errors.firstName}
                                            />
                                            <label htmlFor="add-employee-firstname">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.firstName}
                                                />
                                                <RequiredMarker />
                                            </label>
                                            {touched.firstName &&
                                                <FormFieldError
                                                    error={errors.firstName}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.firstName),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-name"
                                                name={personalDataFields.name}
                                                value={values.name || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && !!errors.name}
                                            />
                                            <label htmlFor="add-employee-name">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.name}
                                                />
                                                <RequiredMarker />
                                            </label>
                                            {touched.name &&
                                                <FormFieldError
                                                    error={errors.name}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.name),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <ConstantsTypeahead
                                                id="add-employee-sexid"
                                                name={personalDataFields.sexId}
                                                value={values.sexId}
                                                constantType={ConstantType.SEXES}
                                                onItemSelected={onSexItemSelected}
                                                isInvalid={touched.sexId && !!errors.sexId}
                                            >
                                                <label htmlFor="add-employee-sexid">
                                                    <Translate
                                                        msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.sexId}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                            </ConstantsTypeahead>
                                            {touched.sexId &&
                                                <FormFieldError
                                                    error={errors.sexId}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.sexId),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <ConstantsTypeahead
                                                id="add-employee-nationalityid"
                                                name={personalDataFields.nationalityId}
                                                value={values.nationalityId}
                                                constantType={ConstantType.NATIONALITIES}
                                                onItemSelected={onNationalityItemSelected}
                                                isInvalid={touched.nationalityId && !!errors.nationalityId}
                                            >
                                                <label htmlFor="add-employee-nationalityid">
                                                    <Translate
                                                        msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalityId}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                            </ConstantsTypeahead>
                                            {touched.nationalityId &&
                                                <FormFieldError
                                                    error={errors.nationalityId}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalityId),
                                                    }}
                                                />}
                                            {/*
                                                A Belgian citizen always has an INSZ.
                                                That's why nationality can't be Belgian when "foreign employee" checkbox
                                                was checked in the previous step (NationalRegisterNumber).
                                            */}
                                            {(
                                                touched.nationalityId
                                                && values.nationalityId === nationalityIdBelgium
                                                && employeeToAdd.isForeignEmployee
                                            ) &&
                                                <FormFieldError
                                                    error={{
                                                        message: INSZ_MISSING,
                                                        type: ErrorTypes.Custom,
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <ConstantsTypeahead
                                                id="add-employee-languageId"
                                                name={personalDataFields.languageId}
                                                value={values.languageId}
                                                constantType={ConstantType.LANGUAGES}
                                                onItemSelected={onLanguageItemSelected}
                                                isInvalid={touched.languageId && !!errors.languageId}
                                            >
                                                <label htmlFor="add-employee-languageId">
                                                    <Translate
                                                        msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.languageId}
                                                    />
                                                    <RequiredMarker />
                                                </label>
                                            </ConstantsTypeahead>
                                            {touched.languageId &&
                                                <FormFieldError
                                                    error={errors.languageId}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.languageId),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-address"
                                                name={personalDataFields.address}
                                                value={(values.address && formatAddress(values.address)) || ''}
                                                onFocus={() => this.setState({
                                                    isEditAddressDialogOpen: true,
                                                    currentAddress: values.address,
                                                })}
                                                isInvalid={touched.address && !!errors.address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                multiLine={true}
                                                disableMultilineResize={true}
                                                disableMultilineStyles={true}
                                                rows={2}
                                            />
                                            <label htmlFor="add-employee-address">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.address}
                                                />
                                            </label>
                                            {touched.address &&
                                                <FormFieldError
                                                    error={errors.address}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.address),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <h4>
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.detail.employee_details.subtitles.contact"
                                            />
                                        </h4>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-phone"
                                                name={personalDataFields.phone}
                                                value={values.phone || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.phone && !!errors.phone}
                                            />
                                            <label htmlFor="add-employee-phone">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.phone}
                                                />
                                            </label>
                                            {touched.phone &&
                                                <FormFieldError
                                                    error={errors.phone}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.phone),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-mobile"
                                                name={personalDataFields.mobilePhone}
                                                value={values.mobilePhone || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.mobilePhone && !!errors.mobilePhone}
                                            />
                                            <label htmlFor="add-employee-mobile">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.mobilePhone}
                                                />
                                            </label>
                                            {touched.mobilePhone &&
                                                <FormFieldError
                                                    error={errors.mobilePhone}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.mobilePhone),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-email"
                                                name={personalDataFields.email}
                                                value={values.email || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.email && !!errors.email}
                                            />
                                            <label htmlFor="add-employee-email">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.email}
                                                />
                                            </label>
                                            {touched.email &&
                                                <FormFieldError
                                                    error={errors.email}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.email),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <h4>
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.detail.employee_details.subtitles.employer"
                                            />
                                        </h4>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="add-employee-employee-number"
                                                name={personalDataFields.employeeNumber}
                                                value={values.employeeNumber || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.employeeNumber && !!errors.employeeNumber}
                                            />
                                            <label htmlFor="add-employee-employee-number">
                                                <Translate
                                                    msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.employeeNumber}
                                                />
                                            </label>
                                            {touched.employeeNumber &&
                                                <FormFieldError
                                                    error={errors.employeeNumber}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.employeeNumber),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        {updateError && <FormError error={updateError}/>}
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                                alwaysEnabled: true,
                                            },
                                        })}
                                    </StickyFooter>
                                </Loader>
                            );
                        }}
                    />
                </div>
                <Dialog
                    show={this.state.isEditAddressDialogOpen}
                    onCloseIntent={this.onCloseEditAddress}
                >
                    <EditEmployeeAddress
                        objectToAdd={{
                            ...employeeToAdd,
                            address: this.state.currentAddress || (employeeToAdd && employeeToAdd.address),
                        }}
                        onClose={this.onCloseEditAddress}
                        onFormSubmit={(address) => {
                            setFormFieldValue('address', address);
                            this.onCloseEditAddress();
                        }}
                    />
                </Dialog>
            </>
        );
    }

    private onCloseEditAddress() {
        this.setState({
            isEditAddressDialogOpen: false,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);

        return {
            employeeToAdd,
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateEmployeeToAdd: (values) => {
                dispatch(updateAddEmployeeData(values));
            },
        };
    },
})(PersonalData);
