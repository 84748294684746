import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import InlineSVGWrapper from 'svg-inline-react';
import classNames from 'classnames';
import './inline-svg.scss';

export interface IInlineSVGProps {
    svg: string;
    className?: string;
    preserveAspectRatio?: string;
}

const CLASS_NAME = 'InlineSVG';

/**
 * Internet Explorer 9–11 suffer from various bugs that prevent proper scaling
 * of inline SVG’s. This is particularly problematic for responsive SVGs.
 * This is the canvas-based hack:
 *
 * http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/
 */

class InlineSVG extends PureComponent<IInlineSVGProps> {
    constructor (props) {
        super(props);
    }

    public componentDidMount() {
        if (this.props.preserveAspectRatio) {
            const element = ReactDOM.findDOMNode(this);

            if (element instanceof HTMLElement) {
                const svg = element.querySelector('svg');

                if (svg) {
                    svg.setAttribute('preserveAspectRatio', this.props.preserveAspectRatio);
                }
            }

        }
    }

    public render() {
        const DOM = document.createElement('div');
        DOM.innerHTML = this.props.svg;

        const svg = DOM.querySelector('svg');

        if (!svg) {
            if (process.env.NODE_ENV === 'development') {
                console.warn(
                    'Invalid src for the InlineSVG component. Make sure the filename does not have \
                    the \'.img.svg\' extension, so that the svg-inline-loader of webpack handles it.',
                );
            }
            return null;
        }

        const viewBoxString = svg && svg.getAttribute('viewBox');
        const viewBoxProps = viewBoxString && viewBoxString.split(/\s+|,/);

        return (
            <figure className={classNames(CLASS_NAME, this.props.className)}>
                <div className={`${CLASS_NAME}__wrapper`}>
                    <canvas
                        className={`${CLASS_NAME}__canvas`}
                        width={viewBoxProps && viewBoxProps[2]}
                        height={viewBoxProps && viewBoxProps[3]}
                    />
                    <InlineSVGWrapper
                        src={this.props.svg}
                        className={`${CLASS_NAME}__svg`}
                    />
                </div>
            </figure>
        );
    }
}

export default InlineSVG;
