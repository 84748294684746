import { ADD_FLASH_MESSAGE, DISMISS_FLASH_MESSAGE } from './types';
import {
    FlashMessageType,
    IFlashMessage,
    IFlashDataCount,
    IFlashDataSessionAboutToExpire,
} from '../../models/general/flashMessages';
import { createAction } from '../../utils/libs/redux/createAction';
import { now } from '../../utils/core/date/getSpecificDate';

export function dismissFlashMessage(flash: IFlashMessage<{}>) {
    return createAction<IFlashMessage<{}>>(DISMISS_FLASH_MESSAGE, flash);
}

export function triggerFlashThatDownloadToMessageCenterIsTriggered() {
    return triggerFlashMessage<{}>({
        type: FlashMessageType.DOWNLOAD_TRIGGERED_TO_MESSAGE_CENTER,
        data: {},
    });
}

export function triggerFlashThatSessionIsAboutToExpire(data: IFlashDataSessionAboutToExpire) {
    return triggerFlashMessage<IFlashDataSessionAboutToExpire>({
        type: FlashMessageType.SESSION_EXPIRE_WARNING,
        data,
    });
}

export function triggerFlashThatThereAreNewUnreadMessages(data: IFlashDataCount) {
    return triggerFlashMessage<IFlashDataCount>({
        type: FlashMessageType.NEW_UNREAD_MESSAGES,
        data,
    });
}

export function triggerFlashThatThereIsANewAppVersion() {
    return triggerFlashMessage<{}>({
        type: FlashMessageType.NEW_APP_VERSION,
        data: {},
    });
}

function triggerFlashMessage<FlashData>({
    type,
    data,
}: {
    type: FlashMessageType;
    data: FlashData;
}) {
    return createAction<IFlashMessage<FlashData>>(ADD_FLASH_MESSAGE, {
        type,
        data,
        triggerTime: now().getTime(),
    });
}
