import React from 'react';
import './plan-bufferzone-info.scss';
import { formatDateInFullFormat } from '../../../../../../utils/formatting/formatDate';
import Translate from '../../../../../common/Translate';
import { formatLocationAddressWithoutName } from '../../../../../../utils/formatting/formatAddress';
import DetailItem from '../../../../../common/widget/DetailItem';
import { formatStartEndTimeOfDatesForDisplay } from '../../../../../../utils/formatting/formatTime';
import { getIndicatorStatus } from '../../../../../common/widget/FreeSlotsIndicator';
import StatusIndicator from '../../../../../common/widget/StatusIndicator';
import { IReservedMedicalExamination } from '../../../../../../models/interventions/medicalExaminations/reserved';

const CLASS_NAME = 'PlanBufferzoneInfo';
const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps.planning.bufferzone_info';

interface IPublicProps {
    bufferzone: IReservedMedicalExamination;
    freeSlots: number;
    totalSlots: number;
}

export default function BufferzoneInfo({ bufferzone, freeSlots, totalSlots }: IPublicProps) {
    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__header`}>
                <h6>{formatDateInFullFormat(bufferzone.activity.start)}</h6>
            </div>
            <div className={`${CLASS_NAME}__content`}>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.location`} /></h6>
                    {formatLocationAddressWithoutName(bufferzone.location.address)}
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.start_end_hour`} /></h6>
                    {formatStartEndTimeOfDatesForDisplay(bufferzone.activity.start, bufferzone.activity.end)}
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.availability.title`} /></h6>
                    <div className={`${CLASS_NAME}__availability`}>
                        <StatusIndicator
                            value={
                                getIndicatorStatus(
                                    freeSlots,
                                    totalSlots,
                                ).statusIndicatorValue
                            }
                            useRedYellowGreen={true}
                        />
                        <span>
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.availability.indicator`}
                                placeholders={{
                                    freeSlots: <strong>{freeSlots}</strong>,
                                    totalSlots,
                                }}
                            />
                        </span>
                    </div>
                </DetailItem>
            </div>
        </div>
    );
}
