import { AsyncStatus, IAsyncFetchState } from '../../../models/general/redux';
import { ITraceableApiError } from '../../../models/general/error';

const DEFAULT_DATA = null;
const DEFAULT_ERROR = null;

interface IOptions<Data> {
    prevData?: Data;
}

export function getAsyncFetchStateInitial<Data>(
    options?: IOptions<Data>,
): IAsyncFetchState<Data> {
    return {
        data: (options && options.prevData) || DEFAULT_DATA,
        status: AsyncStatus.Initial,
        error: DEFAULT_ERROR,
    };
}

export function getAsyncFetchStateBusy<Data>(
    options?: IOptions<Data>,
): IAsyncFetchState<Data> {
    return {
        data: (options && options.prevData) || DEFAULT_DATA,
        status: AsyncStatus.Busy,
        error: DEFAULT_ERROR,
    };
}

export function getAsyncFetchStateSuccess<Data>(
    data: Data,
): IAsyncFetchState<Data> {
    return {
        data,
        status: AsyncStatus.Success,
        error: DEFAULT_ERROR,
    };
}

export function getAsyncFetchStateError<Data>(
    error: ITraceableApiError,
    options?: IOptions<Data>,
): IAsyncFetchState<Data> {
    return {
        data: (options && options.prevData) || DEFAULT_DATA,
        status: AsyncStatus.Error,
        error,
    };
}
