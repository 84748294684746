import { createEpic, triggerDownloadToMessageCenterFlashIfDelayed } from '../../index';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat,
} from '../../company/selected/selectors';
import { getLocationState, getQueryParams } from '../../location/selectors';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IFetchMedicalDocumentFilesInBulkPayload,
    IFetchMedicalDocumentsPayload,
    IMedicalDocumentsFilter,
} from '../../../models/documentCenter/medicalDocuments';
import { fetchMedicalDocumentsActions, fetchMedicalDocumentFilesInBulkActions } from './actions';
import { FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK } from './types';
import { DEFAULT_MEDICAL_DOCUMENTS_FILTER } from '../../../api/documentCenter/medicalDocuments.api';
import { areMedicalDocumentsAvailable } from './selectors';

// fetchMedicalDocumentsEpic
createEpic<IFetchMedicalDocumentsPayload[]>({
    onActionType: ROUTE_KEYS.R_MEDICAL_DOCUMENTS,
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_MEDICAL_DOCUMENTS_FILTER,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
            || !areMedicalDocumentsAvailable(getState());
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const filterFromQuery = getQueryParams(state) as IMedicalDocumentsFilter;

            const result = await api.documentCenter.medicalDocuments.fetchMedicalDocuments(
                { companyCode, showFullFamily }, filterFromQuery,
            );
            return fetchMedicalDocumentsActions.succeeded(result);
        } catch (error) {
            return fetchMedicalDocumentsActions.failed(error);
        }
    },
    latest: false,
});

// fetchMedicalDocumentFilesInBulkEpic
createEpic<IFetchMedicalDocumentFilesInBulkPayload>({
    onActionType: FETCH_MEDICAL_DOCUMENT_FILES_IN_BULK,
    async processMultiple({ getState, action, api }, dispatch, done) {
        try {
            await api.documentCenter.medicalDocuments.fetchMedicalDocumentFilesInBulk({
                companyCode: getSelectedSeatCompanyCode(getState()),
                ids: action.payload.ids,
            });

            triggerDownloadToMessageCenterFlashIfDelayed(true);

            dispatch(fetchMedicalDocumentFilesInBulkActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(fetchMedicalDocumentFilesInBulkActions.failed(error));
            return done();
        }
    },
    latest: false,
});
