import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import { REDUCER_KEYS } from '../../config/redux.config';
import { IFlashMessage } from '../../models/general/flashMessages';
import { registerReducer } from '../../utils/libs/redux/registerReducer';
import { ADD_FLASH_MESSAGE, DISMISS_FLASH_MESSAGE } from './types';

export const reducerKey = REDUCER_KEYS.FLASH_MESSAGES;

export interface IReducerState {
    flashes: IFlashMessage<{}>[];
}

const initialState: IReducerState = {
    flashes: [],
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(ADD_FLASH_MESSAGE)
        .onTrigger<IFlashMessage<{}>>(({ oldState, payload }) => {
            const flashToAdd = payload;
            const newFlashes = [...oldState.flashes];

            const flashWithSameType = newFlashes
                .find((flash) => flash.type === flashToAdd.type);

            if (flashWithSameType) {
                flashWithSameType.data = flashToAdd.data;
                flashWithSameType.triggerTime = flashToAdd.triggerTime;
            } else {
                newFlashes.push(flashToAdd);
            }

            return {
                ...oldState,
                flashes: newFlashes,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(DISMISS_FLASH_MESSAGE)
        .onTrigger<IFlashMessage<{}>>(({ oldState, payload }) => {
            const flashTypeToDismiss = payload.type;

            const indexOfFlashWithSameType = oldState.flashes
                .findIndex((flash) => flash.type === flashTypeToDismiss);

            if (indexOfFlashWithSameType !== -1) {
                const newFlashes = [...oldState.flashes];
                newFlashes.splice(indexOfFlashWithSameType, 1);

                return {
                    ...oldState,
                    flashes: newFlashes,
                };
            }

            return oldState;
        })
        .create(),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
