import { connect } from '../../../../..';
import { BE_COUNTRY_CODE } from '../../../../../../config/general.config';
import { clearErrors } from '../../../../../../utils/libs/redux/generic/actions';
import { IAddress } from '../../../../../../models/general/address';
import EditAddress, {
    IPublicProps, IEditAddressProps,
} from '../../../../../administration/Employees/shared/EditAddress';

interface IEditOnboardingSeatAddressProps extends IEditAddressProps {
    initialValues: IAddress;
    selectedSeatName: string;
}

export default connect<IPublicProps, IEditOnboardingSeatAddressProps>({
    statePropsDeprecated: (state, publicProps) => {
        const { initialValues, selectedSeatName } = publicProps;

        return {
            isDetailsLoaded: !!initialValues,
            initialValues: initialValues ? {
                countryCode: initialValues.countryCode || '',
                box: initialValues.box || '',
                city: initialValues.city || '',
                number: initialValues.number || '',
                postcode: initialValues.postcode || '',
                street: initialValues.street || '',
                zipCodeId: initialValues.zipCodeId || -1,
            } : {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: '',
                zipCodeId: -1,
            },
            personName: selectedSeatName,
            allowInternationalAddress: true,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditAddress);
