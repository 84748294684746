/**
 * Taken from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
import isFunction from '@snipsonian/core/es/is/isFunction';

const UUID_FORMAT = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

export default function generateUuid() {
    let d = new Date().getTime();

    if (typeof performance !== 'undefined' && isFunction(performance.now)) {
        // use high-precision timer if available
        d += performance.now();
    }

    return UUID_FORMAT.replace(/[xy]/g, (c) => {
        /* eslint-disable no-mixed-operators,no-bitwise */
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        /* eslint-enable no-mixed-operators,no-bitwise */
    });
}
