import { get } from '../../../../utils/api/requestWrapper';
import {
    IExecutedCompanyVisit,
    IExecutedCompanyVisitsFilter,
    IFetchExecutedCompanyVisitsPayload,
} from '../../../../models/interventions/company-visits';
import { URL } from '../company-visits.const';

import { DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS } from './executed.const';

export function fetchExecutedCompanyVisits(
    { companyCode, showFullFamily }: IFetchExecutedCompanyVisitsPayload,
    {
        startDate = DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.startDate,
        endDate = DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.endDate,
    }: IExecutedCompanyVisitsFilter,
) {
    return get<IExecutedCompanyVisit[]>({
        url: URL.COMPANY_VISITS_EXECUTED,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.visits,
    });
}
