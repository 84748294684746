import React, { ReactElement, PureComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import './tile.scss';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';
import { ITooltipWithIconProps } from '../TooltipWithIcon';
import isHtmlString from '../../../../utils/core/string/isHtmlString';
import InlineSVG from '../../icons/InlineSVG';
import LazyLoadSVG, { ILazyLoadSvgProps } from '../LazyLoadSVG';

interface ITileProps {
    title?: string;
    description?: string;
    className?: string;
    typeName?: 'grey' | 'green' | 'white';
    svg?: string;
    svgHover?: string;
    svgActive?: string;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    tooltipWithIcon?: ReactElement<ITooltipWithIconProps>;
    arrow?: boolean;
    objectFitSvg?: boolean;
    svgAsImage?: boolean;
    lazyLoadSvg?: ILazyLoadSvgProps;
}

interface IState {
    isTooltipOpen: boolean;
}

class Tile extends PureComponent<ITileProps, IState> {

    constructor(props: ITileProps) {
        super(props);

        this.state = {
            isTooltipOpen: false,
        };

        this.onTooltipClose = this.onTooltipClose.bind(this);
        this.onTooltipOpen = this.onTooltipOpen.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    public render() {
        const {
            typeName, tooltipWithIcon, className,
            svg, svgHover, svgActive, objectFitSvg, svgAsImage, lazyLoadSvg,
            title, onClick, description, arrow,
        } = this.props;
        const { isTooltipOpen } = this.state;

        const tileClass = classNames('Tile', {
            'Tile--green': typeName === 'green',
            'Tile--white': typeName === 'white',
            'Tile--with-tooltip': !!tooltipWithIcon,
            'Tile--tooltip-open': isTooltipOpen,
        });

        const tileClasses = classNames(tileClass, className);

        return (
            <div
                className={tileClasses}
                onClick={onClick}
                onTransitionEnd={this.onTransitionEnd}
            >
                {title && (
                    <h3><Translate msg={title} /></h3>
                )}
                {description && (
                    isHtmlString(description) ? (
                        <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                    ) : (
                        <div className="description">
                            <Translate msg={description} />
                        </div>
                    )
                )}
                {svg && (
                    <>
                    {svgAsImage ? (
                        <figure
                            className={svgHover && 'hover'}
                            style={{
                                backgroundImage: objectFitSvg && `url(${svg})`,
                            }}
                        >
                            <img src={svg} />
                            {svgHover && (
                                <img src={svgHover} className="img-hover" />
                            )}
                            {svgActive && (
                                <img src={svgActive} className="img-active" />
                            )}
                        </figure>
                    ) : (
                        <InlineSVG svg={svg} />
                    )}
                    </>
                )}
                {lazyLoadSvg && (
                    <LazyLoadSVG id={lazyLoadSvg.id} group={lazyLoadSvg.group} />
                )}
                {arrow && (
                    <Icon typeName="chevron-right" />
                )}
                {tooltipWithIcon && React.createElement(tooltipWithIcon.type, {
                    ...tooltipWithIcon.props,
                    onClose: this.onTooltipClose,
                    onOpen: this.onTooltipOpen,
                    delayBeforeShow: 300, // The duration of the animation
                } as ITooltipWithIconProps)}
            </div>
        );
    }

    private onTooltipClose() {
        this.setState({
            isTooltipOpen: false,
        });
    }

    private onTooltipOpen() {
        this.setState({
            isTooltipOpen: true,
        });
    }

    private onTransitionEnd() {
        // Due to the animation on the tile the popper needs to be repositioned
        // This is done by force updating the component
        this.forceUpdate();
    }
}

export default Tile;
