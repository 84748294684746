import { get, post, IResponseType } from '../../utils/api/requestWrapper';
import {
    IWorkPostCardBlankForms,
    ICreateWorkPostCardFormPayload,
    IFetchWorkPostCardDefaultValuesRequestPayload,
    IWorkPostCardDefaultValues,
    IWorkPostFile,
    IFetchWorkPostFileOfEmployeePayload,
} from '../../models/documentCenter/workPostCards';
import { BACKEND_LOCALE_TO_LOCALE_MAP, Locales } from '../../config/i18n.config';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';
import { IDocument } from '../../models/general/documents';
import { HEADER_NAME } from '../../config/api.config';
import { localeToBackendLocale } from '../../utils/formatting/formatLocale';

export const URL = {
    BLANK_WORK_POST_FORM: '/work-post/blank-form',
    WORK_POST_FORM: '/work-post/form',
    WORK_POST_DEFAULT_VALUES: '/work-post/form/default-values',
    WORK_POST_FILE: '/companies/{companyCode}/work-post/employment/{employeeId}',
};

export function fetchWorkPostCardBlankForms() {
    return get<IWorkPostCardBlankForms>({
        url: URL.BLANK_WORK_POST_FORM,
        mapResponse: (response) => mapBackendLocaleUrls(response['blank-forms']),
    });
}

export function fetchWorkPostCardDefaultValues(
    payload: IFetchWorkPostCardDefaultValuesRequestPayload,
    locale: Locales,
) {
    return post<IWorkPostCardDefaultValues>({
        url: URL.WORK_POST_DEFAULT_VALUES,
        body: {
            ...payload,
        },
        headers: {
            [HEADER_NAME.LOCALE]: localeToBackendLocale(locale),
        },
        mapResponse: (response) => response['work-post-form'],
    });
}

export function mapBackendLocaleUrls(backendLocaleUrls: { [backendLocale: string]: string; }): IWorkPostCardBlankForms {
    const backendLocales = Object.keys(backendLocaleUrls);

    const urls = backendLocales
        .reduce(
            (urlAccumulator, backendLocale) => {
                const locale = BACKEND_LOCALE_TO_LOCALE_MAP[backendLocale];
                if (locale) {
                    const url = backendLocaleUrls[backendLocale];
                    urlAccumulator[locale] = url;
                }
                return urlAccumulator;
            },
            {},
        );

    return {
        urls,
    };
}

export function createWorkPostCardForm(payload: ICreateWorkPostCardFormPayload, locale: Locales) {
    return post<IDocument>({
        responseType: IResponseType.blob,
        url: URL.WORK_POST_FORM,
        body: payload,
        headers: {
            [HEADER_NAME.LOCALE]: localeToBackendLocale(locale),
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers),
            };
        },
    });
}

export function fetchWorkPostFileOfEmployee({
    companyCode,
    employeeId,
}: IFetchWorkPostFileOfEmployeePayload) {
    return get<IWorkPostFile>({
        url: URL.WORK_POST_FILE,
        pathParams: {
            companyCode,
            employeeId,
        },
        mapResponse: (response) => response.employment,
    });
}
