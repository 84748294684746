import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './list-item-actions.scss';

interface IListItemActionsProps {
    children: ReactNode;
    className?: string;
}

export default function ListItemActions(props: IListItemActionsProps) {
    return (
        <span className={classNames('ListItemActions', props.className)}>
            {props.children}
        </span>
    );
}
