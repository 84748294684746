import React from 'react';
import { IEmployee } from '../../../../../models/admin/employee';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import TooltipWithIcon from '../../../../common/widget/TooltipWithIcon';
import { isInThePast } from '../../../../../utils/core/date/isInThePast';

interface IPublicProps {
    employee: Partial<IEmployee>;
}

const warningIcon = (
    <Icon circle typeName="warning" />
);

export default function EmployeeConditionTooltip({ employee }: IPublicProps) {
    const isGoingOutOfService = employee && employee.dateOutOfService &&
        !employee.newCompany && !employee.newFunction;

    const newFutureEmployment = employee && employee.dateInService && !isInThePast(employee.dateInService);

    if (!employee ||
        (
            !employee.absent && !employee.newFunction && !employee.newCompany && !isGoingOutOfService
            && !newFutureEmployment && !employee.newEmployee
        )
    ) {
        return <span />;
    }

    return (
        <TooltipWithIcon icon={warningIcon} tooltipBubbleIcon={warningIcon} typeName="info-bubble">
            <div>
                {employee.absent &&
                    <div><Translate msg={'administration.employees.conditions.absent'} /></div>
                }
                {employee.newFunction &&
                    <div><Translate msg={'administration.employees.conditions.new_function'} /></div>
                }
                {employee.newCompany &&
                    <div><Translate msg={'administration.employees.conditions.new_seat'} /></div>
                }
                {isGoingOutOfService &&
                    <div><Translate msg={'administration.employees.conditions.out_of_service'} /></div>
                }
                {newFutureEmployment &&
                    <div><Translate msg={'administration.employees.conditions.future_employment'} /></div>
                }
                {employee.newEmployee &&
                    <div><Translate msg={'administration.employees.conditions.new_employee'} /></div>
                }
            </div>
        </TooltipWithIcon>
    );
}
