import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './validate.scss';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { connect } from '../../../../index';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import LabelInfo from '../../../../common/widget/LabelInfo';
import ResultAfterDoAction from '../../../../common/widget/ResultAfterDoAction';
import ValidateInfo from '../../../../common/widget/ValidateInfo';
import { formatCompanyContact } from '../../../../../utils/formatting/formatContact';
import {
    ICompanyVisitRequestWizardEntity, ICreateCompanyVisitRequest,
} from '../../../../../models/interventions/company-visits';
import { createCompanyVisitRequestActions } from '../../../../../redux/company/visits/actions';
import {
    getCreateCompanyVisitRequestAsyncInfo, getCompanyVisitRequestWizardEntity,
} from '../../../../../redux/company/visits/selectors';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';

const CLASS_NAME = 'RequestCompanyVisit__Validate';
const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    wizardEntity: ICompanyVisitRequestWizardEntity;
    onCreateRequest: () => void;
    resetCreateAction: () => void;
    navigateToAfterSuccessRoute: () => void;
}

class Validate extends PureComponent<IStepperStepRenderProps & IPrivateProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.closeCreationSuccessDialog = this.closeCreationSuccessDialog.bind(this);
        this.closeCreationErrorDialog = this.closeCreationErrorDialog.bind(this);
    }

    public render() {
        const {
            renderStepButtons,
            wizardEntity,
            onCreateRequest,
        } = this.props;

        return (
            <>
                <PageHeader
                    title="interventions.company_visits_new.steps.validate.title"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <ResultAfterDoAction
                        doActionAsyncInfoSelector={getCreateCompanyVisitRequestAsyncInfo}
                        successConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.company_visits_new.steps.validate.create_result.success.title',
                            onConfirm: this.closeCreationSuccessDialog,
                        }}
                        errorConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.company_visits_new.steps.validate.create_result.error.title',
                            onConfirm: this.closeCreationErrorDialog,
                        }}
                    >
                        <ValidateInfo>
                            <LabelInfo
                                labelKey="interventions.company_visits_new.steps.validate.fields.contact"
                                info={formatCompanyContact(wizardEntity.contact)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                labelKey="interventions.company_visits_new.steps.validate.fields.remark"
                                info={wizardEntity.remarks.remark || EMPTY_PLACEHOLDER}
                            />
                        </ValidateInfo>
                        <div className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    translationKey: 'interventions.company_visits_new.steps.validate.finish_button',
                                    onClick: onCreateRequest,
                                },
                            })}
                        </div>
                    </ResultAfterDoAction>
                </div>
            </>
        );
    }

    private closeCreationSuccessDialog() {
        this.props.resetCreateAction();
        this.props.navigateToAfterSuccessRoute();
    }

    private closeCreationErrorDialog() {
        this.props.resetCreateAction();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getCompanyVisitRequestWizardEntity(state);

        return {
            wizardEntity: wizardEntity as ICompanyVisitRequestWizardEntity,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onCreateRequest: () => {
                const wizardEntity =
                    getCompanyVisitRequestWizardEntity(getState()) as ICompanyVisitRequestWizardEntity;

                dispatch(createCompanyVisitRequestActions.trigger(
                    mapWizardEntityToCreatePayload(wizardEntity),
                ));
            },
            resetCreateAction: () => {
                dispatch(createCompanyVisitRequestActions.reset({}));
            },
            navigateToAfterSuccessRoute: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
            },
        };
    },
})(Validate);

function mapWizardEntityToCreatePayload(wizardEntity: ICompanyVisitRequestWizardEntity): ICreateCompanyVisitRequest {
    return {
        contact: {
            firstName: wizardEntity.contact.firstName,
            name: wizardEntity.contact.name,
            email: wizardEntity.contact.email,
            phone: wizardEntity.contact.phone,
            mobilePhone: wizardEntity.contact.mobilePhone,
        },
        remarks: wizardEntity.remarks.remark,
    };
}
