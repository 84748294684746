import React, { PureComponent } from 'react';
import PreHeader from '..';
import Icon from '../../../common/icons/Icon';
import './flu-vaccine-banner.scss';
import { IFluVaccinesConfig } from '../../../../models/interventions/fluVaccines';
import { connect } from '../../..';
import { getFluVaccinesConfig, showFluVaccinesCampaign } from '../../../../redux/intervention/fluVaccine/selectors';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import ROUTE_KEYS from '../../../../routeKeys';
import localStorage from '@snipsonian/browser/es/storage/localStorage';

interface IPrivateProps {
    fluVaccinesConfig: IFluVaccinesConfig;
    showFluVaccinesCampaign: boolean;
}

const FLU_VACCINES_ROUTE = ROUTE_KEYS.R_FLU_VACCINES_ORDERS;
const CLASS_NAME = 'FluVaccineBanner';
const LOCAL_STORAGE_KEY = 'MNSR_KLNTZN_FLU_VACCINE_BANNER';

interface IFluVaccineStorage {
    startDate: string;
    endDate: string;
}

class FluVaccineBanner extends PureComponent<IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { fluVaccinesConfig, showFluVaccinesCampaign } = this.props;

        if (!fluVaccinesConfig || this.isItemDismissed(fluVaccinesConfig.startDate, fluVaccinesConfig.endDate)) {
            return null;
        }

        return showFluVaccinesCampaign ? (
            <PreHeader
                className={CLASS_NAME}
                onClose={() => {
                    this.dismissItem(fluVaccinesConfig.startDate, fluVaccinesConfig.endDate);
                }}
            >
                <LinkToRoute
                    id="to-flu-vaccines-banner"
                    className={`${CLASS_NAME}__link-wrapper`}
                    to={FLU_VACCINES_ROUTE}
                >
                    <div
                        className={`${CLASS_NAME}__content`}
                        dangerouslySetInnerHTML={{ __html: fluVaccinesConfig.description }}
                    />
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </PreHeader>
        ) : null;
    }

    // When a banner with the same start & end date is already dismissed
    // we won't show it again
    private isItemDismissed(startDate: string, endDate: string) {
        const itemsInStorage = localStorage.read({ key: LOCAL_STORAGE_KEY }) as IFluVaccineStorage[];

        if (itemsInStorage) {
            const currentConfig = itemsInStorage.find((item) => {
                return item.startDate === startDate && item.endDate === endDate;
            });

            return itemsInStorage.includes(currentConfig);
        }

        return false;
    }

    // Store dismissed start & end date in localStorage
    private dismissItem(startDate: string, endDate: string) {
        const itemsInStorage = (localStorage.read({ key: LOCAL_STORAGE_KEY }) || []) as IFluVaccineStorage[];
        itemsInStorage.push({
            startDate,
            endDate,
        });
        localStorage.save({ key: LOCAL_STORAGE_KEY, value: itemsInStorage });
    }
}

export function resetDismissedItems() {
    localStorage.remove({ key: LOCAL_STORAGE_KEY });
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            fluVaccinesConfig: getFluVaccinesConfig(state),
            showFluVaccinesCampaign: showFluVaccinesCampaign(state),
        };
    },
})(FluVaccineBanner);
