import { head } from 'ramda';

import { ICalendarEvent } from '../../models/ui/calendar';
import { IMedicalExaminationTimeSlot } from '../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../models/interventions/medicalExaminations/planned';
import { getDateWithoutSeconds } from '../core/date/getSpecificDate';

export const mergeSimultaniousPlannedMedicalExaminations = (
    calendarEvents: ICalendarEvent<IPlannedMedicalExamination[]>[],
) => {
    return calendarEvents.reduce((accumulator, event) => {

        const { start, end } = event;

        const eventWithSameStartAndEndTimeIndex = accumulator.findIndex((item) => {
            return (
                item.allDay === event.allDay
                && item.type === event.type
                && getDateWithoutSeconds(item.start).getTime() === getDateWithoutSeconds(start).getTime()
                && getDateWithoutSeconds(item.end).getTime() === getDateWithoutSeconds(end).getTime()
            );
        });

        event.isConcatenated = true;

        if (eventWithSameStartAndEndTimeIndex !== -1) {
            const eventToPush = head(event.data);

            if (!eventToPush) {
                return accumulator;
            }

            const isAlreadyAdded = accumulator[eventWithSameStartAndEndTimeIndex].data.find(e => {
                return (
                    e.timeSlotId === eventToPush.timeSlotId
                    && e.activityId === eventToPush.activityId
                );
            });

            if (!isAlreadyAdded) {
                accumulator[eventWithSameStartAndEndTimeIndex].data.push(eventToPush);
            }

        } else {
            accumulator.push(event);
        }

        return accumulator;
    }, [] as ICalendarEvent<IPlannedMedicalExamination[]>[]);
};

export const mergeSimultaniousMedicalExaminationTimeSlots = (
    calendarEvents: ICalendarEvent<IMedicalExaminationTimeSlot[]>[],
) => {
    return calendarEvents.reduce((accumulator, event) => {

        const { start, end } = event;

        const eventWithSameStartAndEndTimeIndex = accumulator.findIndex((item) => {
            return (
                item.allDay === event.allDay
                && item.type === event.type
                && getDateWithoutSeconds(item.start).getTime() === getDateWithoutSeconds(start).getTime()
                && getDateWithoutSeconds(item.end).getTime() === getDateWithoutSeconds(end).getTime()
            );
        });

        event.isConcatenated = true;

        if (eventWithSameStartAndEndTimeIndex !== -1) {
            const eventToPush = head(event.data);

            if (!eventToPush) {
                return accumulator;
            }

            const isAlreadyAdded = accumulator[eventWithSameStartAndEndTimeIndex].data.find(e => {
                return (
                    e.id === eventToPush.id
                    && e.activityId === eventToPush.activityId
                );
            });

            if (!isAlreadyAdded) {
                accumulator[eventWithSameStartAndEndTimeIndex].data.push(eventToPush);
            }

        } else {
            accumulator.push(event);
        }

        return accumulator;
    }, [] as ICalendarEvent<IMedicalExaminationTimeSlot[]>[]);
};
