import {
    SELECT_COMPANY,
    SELECT_COMPANY_SEAT,
    FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS,
    RESET_COMPANY_SELECTED_REDUCER_TO_INITIAL_STATE,
    SET_COMPANY_ONBOARDING_VARIABLES,
    SET_INTERIM_FOR_COMPANY,
} from './types';
import { createAction, createTypeActions } from '../../index';
import {
    ICompanyDismissableWithDate, ISelectCompanySeatPayload, ICompanySeat,
    ISetCompanyOnboardingVariablesPayload,
    ICompanyInterim,
} from '../../../models/admin/company';
import { logCompanyCode } from '../../../utils/logging/analytics/eventLogger';

// Select company and seat

export function setSelectedCompany(payload: ICompanyDismissableWithDate) {
    logCompanyCode({ companyCode: payload.companyCode });
    return createAction<ICompanyDismissableWithDate>(SELECT_COMPANY, payload);
}

export function setSelectedCompanySeat(payload: ISelectCompanySeatPayload) {
    return createAction<ISelectCompanySeatPayload>(SELECT_COMPANY_SEAT, payload);
}

export function setCompanyOnboardingVariables(payload: ISetCompanyOnboardingVariablesPayload) {
    return createAction<ISetCompanyOnboardingVariablesPayload>(SET_COMPANY_ONBOARDING_VARIABLES, payload);
}

// Selected company seats / divisions

export const fetchSelectedCompanySeatsAndDivisionsActions = createTypeActions<{}, ICompanySeat[]>(
    { type: FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS },
);

export function resetCompanySelectedReducerToInitialState() {
    return createAction(RESET_COMPANY_SELECTED_REDUCER_TO_INITIAL_STATE, {});
}

export function setInterimForCompany(payload: ICompanyInterim) {
    return createAction(SET_INTERIM_FOR_COMPANY, payload);
}
