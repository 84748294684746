import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './list-item.scss';
import Icon from '../../icons/Icon';
import { IButtonProps } from '../../buttons/Button';
import { IPublicProps as ITranslateProps } from '../../Translate';
import ROUTE_KEYS from '../../../../routeKeys';
import { ILocationAction } from '../../../../models/general/redux';
import LinkToRoute from '../../navigation/LinkToRoute';

interface IListItemProps {
    text: string | string[] | ReactElement<ITranslateProps>;
    title?: string | ReactElement<ITranslateProps>;
    arrow?: boolean;
    button?: ReactElement<IButtonProps>;
    to?: ROUTE_KEYS | ILocationAction<object>;
    id?: string;
    selected?: boolean;
}

interface ISingleListItemProps {
    text: string | ReactElement<ITranslateProps>;
    arrow?: boolean;
    selected?: boolean;
}

export default function ListItem(props: IListItemProps) {
    return (
        <div className="ListItem">
            {props.to ? (
                <LinkToRoute id={props.id} to={props.to} className="content-wrapper" preserveQuery={true}>
                    {props.title && <div className="title">{props.title}</div>}
                    {Array.isArray(props.text) ? (
                        (props.text).map((item, index) => (
                            <SingleListItem
                                key={`single-list-item-${index}`}
                                selected={props.selected}
                                text={item}
                                arrow={props.arrow}
                            />
                        ))
                    ) : (
                            <SingleListItem selected={props.selected} text={props.text} arrow={props.arrow} />
                        )}
                </LinkToRoute>
            ) : (
                    <div className="content-wrapper">
                        {props.title && <div className="title">{props.title}</div>}
                        {Array.isArray(props.text) ? (
                            (props.text).map((item, index) => (
                                <SingleListItem key={`single-list-item-${index}`} text={item} arrow={props.arrow} />
                            ))
                        ) : (
                                <SingleListItem selected={props.selected} text={props.text} arrow={props.arrow} />
                            )}
                    </div>
                )}
            {props.button && <div className="button-wrapper">{props.button}</div>}
        </div>
    );
}

function SingleListItem(props: ISingleListItemProps) {
    const className = classNames('item', {
        ['item--with-arrow']: props.arrow,
        ['item--selected']: props.selected,
    });
    return (
        <div className={className}>
            {props.arrow && <Icon typeName="arrow-right" className="item__icon" />}
            {props.text}
        </div>
    );
}
