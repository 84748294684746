import { IFetchEmployeesPayload } from '../models/admin/employee';
import { TPreventionUnitConfig, PreventionUnitId } from '../models/admin/preventionUnits';
import { ColorHex } from '../models/general/colors';

export const INITIAL_FETCH_EMPLOYEES_PARAMETERS: IFetchEmployeesPayload = {
    maxRecordCount: 20,
    refreshSources: 'true',
};

export const FILTER_FETCH_EMPLOYEES_PARAMETERS: IFetchEmployeesPayload = {
    maxRecordCount: 20,
    refreshSources: 'false',
};

export const SHOWALL_FETCH_EMPLOYEES_PARAMETERS: IFetchEmployeesPayload = {
    // 0 = no limit
    maxRecordCount: 0,
    refreshSources: 'false',
};

export const ADD_EMPLOYEE_PLACEHOLDER_KEYS = {
    nationalRegisterNumber:
        'administration.employees.detail.employee_details.personal_data.national_register_nr',
    firstName:
        'administration.employees.detail.employee_details.personal_data.first_name',
    name:
        'administration.employees.detail.employee_details.personal_data.name',
    sexId:
        'administration.employees.detail.employee_details.personal_data.sex',
    nationalityId:
        'administration.employees.detail.employee_details.personal_data.nationality',
    address:
        'administration.employees.detail.employee_details.personal_data.address',
    languageId:
        'administration.employees.detail.employee_details.personal_data.language',
    phone:
        'administration.employees.detail.employee_details.personal_data.phone',
    mobilePhone:
        'administration.employees.detail.employee_details.personal_data.mobile',
    email:
        'administration.employees.detail.employee_details.personal_data.email',
    birthDate:
        'administration.employees.detail.employee_details.personal_data.birth_date',
    dateInService:
        'administration.employees.detail.employee_details.company_data.date_in_service',
    employeeNumber:
        'administration.employees.detail.employee_details.company_data.employee_number',
    seat:
        'administration.employees.detail.employee_details.company_data.seat',
    function:
        'administration.employees.detail.employee_details.company_data.function',
    medical_center:
        'administration.employees.detail.employee_details.company_data.medical_center',
    jobStudent:
        'administration.employees.detail.employee_details.company_data.job_student.label',
};

export const INVOICE_TYPES = {
    open: 'open',
    paid: 'paid',
};

export const EMPLOYEE_SEARCH_MIN_NR_OF_CHARS = 3;

/* Prevention Units */

export const PREVENTION_UNITS_CONFIG: TPreventionUnitConfig = [{
    id: PreventionUnitId.Planned,
    labelTranslationKey: 'administration.prevention_units.types.planned.label',
    tooltipTranslationKey: 'administration.prevention_units.types.planned.tooltip',
    detailTitleTranslationKey: 'administration.prevention_units.types.planned.detail_title',
    color: ColorHex.Blue,
}, {
    id: PreventionUnitId.Registered,
    labelTranslationKey: 'administration.prevention_units.types.registered.label',
    tooltipTranslationKey: 'administration.prevention_units.types.registered.tooltip',
    detailTitleTranslationKey: 'administration.prevention_units.types.registered.detail_title',
    color: ColorHex.DarkGreen,
}, {
    id: PreventionUnitId.Invoiced,
    labelTranslationKey: 'administration.prevention_units.types.invoiced.label',
    tooltipTranslationKey: 'administration.prevention_units.types.invoiced.tooltip',
    detailTitleTranslationKey: 'administration.prevention_units.types.invoiced.detail_title',
    color: ColorHex.Orange,
}, {
    id: PreventionUnitId.Remaining,
    labelTranslationKey: 'administration.prevention_units.types.remaining.label',
    tooltipTranslationKey: 'administration.prevention_units.types.remaining.tooltip',
    detailTitleTranslationKey: 'administration.prevention_units.types.remaining.detail_title',
    color: ColorHex.GreyLight,
    legendColorOverride: ColorHex.SlateGrey,
}, {
    id: PreventionUnitId.Unused,
    labelTranslationKey: 'administration.prevention_units.types.unused.label',
    color: ColorHex.GreyDark,
}, {
    id: PreventionUnitId.Assigned,
    labelTranslationKey: 'administration.prevention_units.types.assigned.label',
    color: ColorHex.SlateGrey,
    detailTitleTranslationKey: 'administration.prevention_units.types.assigned.detail_title',
    tooltipTranslationKey: 'administration.prevention_units.types.assigned.tooltip',
}];

const PREVENTION_UNIT_IDS_FOR_OVERVIEW_LEGEND = [
    PreventionUnitId.Invoiced,
    PreventionUnitId.Planned,
    PreventionUnitId.Registered,
    PreventionUnitId.Remaining,
];

export const PREVENTION_UNITS_CONFIG_FOR_OVERVIEW_LEGEND = PREVENTION_UNITS_CONFIG.filter((config) => {
    return PREVENTION_UNIT_IDS_FOR_OVERVIEW_LEGEND.includes(config.id);
});

const PREVENTION_UNIT_IDS_FOR_OVERVIEW_PREVIOUS_YEAR_LEGEND = [
    PreventionUnitId.Invoiced,
    PreventionUnitId.Unused,
];

export const PREVENTION_UNITS_CONFIG_FOR_OVERVIEW_PREVIOUS_YEAR_LEGEND = PREVENTION_UNITS_CONFIG.filter((config) => {
    return PREVENTION_UNIT_IDS_FOR_OVERVIEW_PREVIOUS_YEAR_LEGEND.includes(config.id);
});

export const PREVENTION_UNIT_ID_TO_TYPE_MAP = {
    [PreventionUnitId.Assigned]: 'ASS',
    [PreventionUnitId.Invoiced]: 'INV',
    [PreventionUnitId.Planned]: 'PLN',
    [PreventionUnitId.Registered]: 'RES',
};
