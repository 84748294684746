import React from 'react';
import classNames from 'classnames';
import './spotlight-page-part.scss';
import { ISpotlightPagePartData } from '../../../../../models/general/pageParts';
import Icon from '../../../icons/Icon';
import WysiwygContentType from '../WysiwygContentType';

interface IPublicProps {
    data: ISpotlightPagePartData;
}

const CLASS_NAME = 'SpotlightPagePart';

export default function SpotlightPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <div className={classNames(CLASS_NAME, `${CLASS_NAME}--${data.color}`)}>
            <Icon typeName="info" />
            <div className={`${CLASS_NAME}__content`}>
                {data.title && (
                    <h6 className={`${CLASS_NAME}__title`}>{data.title}</h6>
                )}
                {data.description && (
                    <WysiwygContentType html={data.description} />
                )}
            </div>
        </div>
    );
}
