import React from 'react';
import { IFormValues } from './AddEmployeeFunctionSchema';
import { CLASS_NAME, TRANSLATIONS_PREFIX } from './shared';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import { IFormRenderProps } from '../../../../../common/forms/Form';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import Checkbox from '../../../../../common/input/Checkbox';
import RequiredMarker from '../../../../../common/input/RequiredMarker';
import TextInput from '../../../../../common/input/TextInput';
import Translate from '../../../../../common/Translate';
import AnimateHeight from 'react-animate-height';

const JOBSTUDENT_TRANS_PREFIX = `${TRANSLATIONS_PREFIX}.job_student`;

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
}

export default function JobStudentSection(props: IPublicProps) {
    const { values, handleChange, touched, errors } = props.formRenderProps;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <div className={`${CLASS_NAME}__job-student`}>
                    <Checkbox
                        name="studentWorker"
                        checked={values.studentWorker}
                        onChange={handleChange}
                    >
                        <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.student_worker`} />
                    </Checkbox>
                    <AnimateHeight
                        duration={500}
                        height={values.studentWorker ? 'auto' : 0}
                    >
                        <div className={`${CLASS_NAME}__job-student__expandable`}>
                            <h6><Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.subtitle`} /></h6>
                            <Checkbox
                                name="safetyFunction"
                                checked={values.safetyFunction}
                                onChange={handleChange}
                                toggleButton={true}
                                disabled={!values.studentWorker}
                            >
                                <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.safety_function`} />
                            </Checkbox>
                            <Checkbox
                                name="medicalFunction"
                                checked={values.medicalFunction}
                                onChange={handleChange}
                                toggleButton={true}
                                disabled={!values.studentWorker}
                            >
                                <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.medical_function`} />
                            </Checkbox>
                            <Checkbox
                                name="sendMail"
                                checked={values.sendMail}
                                onChange={handleChange}
                                toggleButton={true}
                                disabled={!values.studentWorker}
                            >
                                <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.send_mail`} />
                            </Checkbox>
                            <FloatableTextInputWrapper floatLabel>
                                <TextInput
                                    id="add-employee-email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    disabled={!values.studentWorker}
                                />
                                <label htmlFor="add-employee-email">
                                    <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.email`} />
                                    {values.sendMail && <RequiredMarker />}
                                </label>
                                {touched.email &&
                                    <FormFieldError
                                        error={errors.email}
                                        placeholders={{
                                            fieldName: translator(`${JOBSTUDENT_TRANS_PREFIX}.email`),
                                        }}
                                    />}
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper floatLabel>
                                <TextInput
                                    id="add-employee-function-job-student-employer"
                                    name="employer"
                                    value={values.employer}
                                    onChange={handleChange}
                                    disabled={!values.studentWorker}
                                />
                                <label htmlFor="add-employee-function-job-student-employer">
                                    <Translate msg={`${JOBSTUDENT_TRANS_PREFIX}.employer`} />
                                    <RequiredMarker />
                                </label>
                                {touched.employer &&
                                    <FormFieldError
                                        error={errors.employer}
                                        placeholders={{
                                            fieldName: translator(`${JOBSTUDENT_TRANS_PREFIX}.employer`),
                                        }}
                                    />}
                            </FloatableTextInputWrapper>
                        </div>
                    </AnimateHeight>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}
