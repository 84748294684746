export default function formatOndernemingsnummer(ondNr: string) {
    if (!ondNr) {
        return '';
    }

    return [ondNr]
        .map(stripExistingFormatting)
        .map((unformatted) => formatIfRequiredLength(unformatted, ondNr))
        .pop();
}

function stripExistingFormatting(ondNr: string) {
    return ondNr
        .trim()
        .replace(/\./g, '')
        .replace(/ /g, '');
}

function formatIfRequiredLength(unformatted: string, original: string) {
    if (unformatted.length !== 10) {
        return original;
    }

    return unformatted.substr(0, 4)
        + '.' + unformatted.substr(4, 3)
        + '.' + unformatted.substr(7, 3);
}
