import React from 'react';
import BaseTypeahead, { IBaseTypeaheadProps } from '../BaseTypeahead';
import { TDropdownItemValue } from '../../Dropdown';
import { IAsyncTypeaheadProps } from '../AsyncTypeahead';

export interface IFreeTextAsyncTypeaheadProps extends IAsyncTypeaheadProps, Pick<
    IBaseTypeaheadProps,
    'initialFilter'
> {
    value: TDropdownItemValue;
    autoFilteringDisabled: boolean;
}

/**
 * Typeahead to use when data is fetched in an async matter (eg via an ajax request)
 * and the data which is inputted is free text
 * does not have to match an item in the data, a partial match is ok
 */
function FreeTextAsyncTypeahead(props: IFreeTextAsyncTypeaheadProps) {
    return <BaseTypeahead {...props} keepFilterOnSelect={true} />;
}

export default FreeTextAsyncTypeahead;
