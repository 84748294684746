import React, { PureComponent, ChangeEvent, KeyboardEvent } from 'react';
import './time-picker.scss';
import classNames from 'classnames';
import { CustomLocale } from 'flatpickr/dist/types/locale';
import '../date-picker.scss';
import { connect } from '../../../..';
import { getLocale } from '../../../../../redux/i18n/selectors';
import localeToFlatpickrLocale from '../../../../../utils/libs/flatpickr/localeToFlatpickrLocale';
import TextInput from '../../../input/TextInput';
import Button from '../../../buttons/Button';
import Icon from '../../../icons/Icon';

interface ITimePickerProps {
    id: string;
    onChange?: (formattedTime: string) => void;
    value: string;
    isInvalid?: boolean;
    minTime?: string; // for example "16:00"
    maxTime?: string; // for example "22:30"
    disabled?: boolean;
    withClear?: boolean;
}

interface IPrivateProps {
    locale: CustomLocale;
}

interface IState {
    hasChangedAfterSetToInvalid: boolean;
    isFocused: boolean;
    hoursCharactersTyped: number;
}

enum TimeUnit {
    hours = 'hours',
    minutes = 'minutes',
}

const HOURS_INDEX = 0;
const MINUTES_INDEX = 1;
const SEPARATOR = ':';

interface IMinMaxValues {
    minHours: number;
    maxHours: number;
    minMinutes: number;
    maxMinutes: number;
}

class TimePicker extends PureComponent<ITimePickerProps & IPrivateProps, IState> {
    private hourRef: HTMLElement;
    private minuteRef: HTMLElement;

    constructor(props: IPrivateProps & ITimePickerProps) {
        super(props);

        this.state = {
            hasChangedAfterSetToInvalid: false,
            isFocused: false,
            hoursCharactersTyped: 0,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.setHourRef = this.setHourRef.bind(this);
        this.setMinuteRef = this.setMinuteRef.bind(this);
        this.valueIncludesSeparator = this.valueIncludesSeparator.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    }

    public render() {
        const {
            id, isInvalid, disabled, value, withClear,
        } = this.props;

        const { hasChangedAfterSetToInvalid, isFocused } = this.state;

        const datePickerClasses = classNames('TimePicker', 'TextInput', {
            'reset-color': hasChangedAfterSetToInvalid || !isInvalid,
            invalid: isInvalid,
            'hide-input': true,
            placeholder: !value,
            focused: isFocused,
        });

        const clearButtonClasses = classNames('TimePicker__clear-button', {
            hide: !value,
        });

        return (
            <span id={id} className={datePickerClasses}>
                <TextInput
                    id={`${id}-timepicker-hour`}
                    name="timepicker-hour"
                    value={getTimeUnitFromFormattedTime(value, TimeUnit.hours)}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    disableAutoComplete={true}
                    onChange={(e) => this.handleOnChange(e, TimeUnit.hours)}
                    placeholder="00"
                    onBlur={(e) => this.handleOnBlur(e, TimeUnit.hours)}
                    onFocus={this.handleOnFocus}
                    selectOnFocus={true}
                    setRef={this.setHourRef}
                    onKeyDown={this.handleOnKeyDown}
                />
                <span className="separator">{SEPARATOR}</span>
                <TextInput
                    id={`${id}-timepicker-minute`}
                    name="timepicker-minute"
                    value={getTimeUnitFromFormattedTime(value, TimeUnit.minutes)}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    disableAutoComplete={true}
                    onChange={(e) => this.handleOnChange(e, TimeUnit.minutes)}
                    placeholder="00"
                    onBlur={(e) => this.handleOnBlur(e, TimeUnit.minutes)}
                    onFocus={this.handleOnFocus}
                    selectOnFocus={true}
                    setRef={this.setMinuteRef}
                />
                {withClear && <Button
                    id="clear-timepicker-value-button"
                    className={clearButtonClasses}
                    typeName="text"
                    onClick={this.handleClear}
                >
                    <Icon typeName="cross" />
                </Button>}
            </span>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps & ITimePickerProps) {
        if (prevProps.isInvalid !== this.props.isInvalid) {
            this.setState({
                hasChangedAfterSetToInvalid: false,
            });
        } else if (!this.state.hasChangedAfterSetToInvalid && this.props.value !== prevProps.value) {
            this.setState({
                hasChangedAfterSetToInvalid: true,
            });
        }
    }

    private setHourRef(ref: HTMLElement) {
        this.hourRef = ref;
    }

    private setMinuteRef(ref: HTMLElement) {
        this.minuteRef = ref;
    }

    private handleOnBlur(e, timeUnit: TimeUnit) {
        this.setState({ isFocused: false });
        if (this.props.value) {
            this.handleOnChange(e, timeUnit, true, true);
        }
    }

    private handleOnFocus() {
        this.setState({ isFocused: true, hoursCharactersTyped: 0 });
    }

    private handleOnKeyDown(e: KeyboardEvent<HTMLElement>) {
        if (keyDownIsBackSpaceOrDelete(e)) {
            this.setState({ hoursCharactersTyped: -1 });
        }
    }

    private handleOnChange(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        timeUnit: TimeUnit,
        check?: boolean,
        triggeredByOnBlur?: boolean,
    ) {
        const { onChange } = this.props;

        if (typeof onChange === 'function' && changeEventValueIsNumberOrSeparator(e)) {
            const newHoursCharactersTyped = this.state.hoursCharactersTyped + 1;
            const shouldTab = this.valueIncludesSeparator(e) || newHoursCharactersTyped === 2;
            onChange(getNewFormattedTime(e, this.props, timeUnit, shouldTab ? true : check));
            if (shouldTab && !triggeredByOnBlur) {
                this.minuteRef.focus();
            }
            this.setState({ hoursCharactersTyped: newHoursCharactersTyped });
        }
    }

    private valueIncludesSeparator(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        return e.target === this.hourRef && e.target.value.includes(SEPARATOR);
    }

    private handleClear() {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange('');
        }
    }
}

function changeEventValueIsNumberOrSeparator(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(e.target.value.replace(SEPARATOR, ''));

    return !isNaN(value);
}

function keyDownIsBackSpaceOrDelete(e: KeyboardEvent<HTMLElement>) {
    return e.keyCode === 8 || e.keyCode === 46;
}

function mapTimeUnitToIndex(timeUnit: TimeUnit) {
    return timeUnit === TimeUnit.hours ? HOURS_INDEX : TimeUnit.minutes ? MINUTES_INDEX : undefined;
}

function getNewFormattedTime(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    props: IPrivateProps & ITimePickerProps,
    timeUnit: TimeUnit,
    shouldValidate?: boolean,
) {
    const { value } = props;
    const newValue = Number(e.target.value.replace(SEPARATOR, ''));

    const currentTime = value || '00:00';
    let hoursAndMinutes = currentTime.split(':');

    if (shouldValidate) {
        hoursAndMinutes = validateTime(newValue, hoursAndMinutes, props, timeUnit);
    } else {
        const index = mapTimeUnitToIndex(timeUnit);
        hoursAndMinutes[index] = newValue.toString();
    }

    return hoursAndMinutes
        .map((time) => mapNumberToDoubleDigitString(Number(time)))
        .join(':');
}

function validateTime(newValue, hoursAndMinutes, props: IPrivateProps & ITimePickerProps, timeUnit: TimeUnit) {
    const { minTime, maxTime } = props;
    let updatedHoursAndMinutes = [...hoursAndMinutes];

    const MIN_MAX_VALUES: IMinMaxValues = {
        minHours: getTimeUnitFromFormattedTimeAsNumber(minTime, TimeUnit.hours),
        maxHours: getTimeUnitFromFormattedTimeAsNumber(maxTime, TimeUnit.hours),
        minMinutes: getTimeUnitFromFormattedTimeAsNumber(minTime, TimeUnit.minutes),
        maxMinutes: getTimeUnitFromFormattedTimeAsNumber(maxTime, TimeUnit.minutes),
    };

    if (timeUnit === TimeUnit.hours) {
        updatedHoursAndMinutes = validateHours(newValue, hoursAndMinutes, MIN_MAX_VALUES);
    } else if (timeUnit === TimeUnit.minutes) {
        updatedHoursAndMinutes = validateMinutes(newValue, hoursAndMinutes, MIN_MAX_VALUES);
    }
    return updatedHoursAndMinutes;
}

function validateHours(newValue, hoursAndMinutes, minMaxValues: IMinMaxValues) {
    const updatedHoursAndMinutes = [...hoursAndMinutes];
    const { minHours, minMinutes, maxHours, maxMinutes } = minMaxValues;

    switch (true) {
        case newValue < minHours:
            updatedHoursAndMinutes[HOURS_INDEX] = minHours.toString();
            break;
        case newValue > maxHours:
            updatedHoursAndMinutes[HOURS_INDEX] = maxHours.toString();
            break;
        case newValue < 0:
            updatedHoursAndMinutes[HOURS_INDEX] = '0';
            break;
        case newValue > 23:
            updatedHoursAndMinutes[HOURS_INDEX] = '23';
            break;
        default:
            updatedHoursAndMinutes[HOURS_INDEX] = newValue.toString();
    }

    if (Number(updatedHoursAndMinutes[HOURS_INDEX]) === maxHours) {
        const minutes = Number(updatedHoursAndMinutes[MINUTES_INDEX]);
        if (minutes > maxMinutes) {
            updatedHoursAndMinutes[MINUTES_INDEX] = mapNumberToDoubleDigitString(maxMinutes);
        }
    }

    if (Number(updatedHoursAndMinutes[HOURS_INDEX]) === minHours) {
        const minutes = Number(updatedHoursAndMinutes[MINUTES_INDEX]);
        if (minutes < minMinutes) {
            updatedHoursAndMinutes[MINUTES_INDEX] = mapNumberToDoubleDigitString(minMinutes);
        }
    }

    return updatedHoursAndMinutes;
}

function validateMinutes(newValue, hoursAndMinutes, minMaxValues: IMinMaxValues) {
    const updatedHoursAndMinutes = [...hoursAndMinutes];
    const hours = Number(hoursAndMinutes[HOURS_INDEX]);
    const { minMinutes, maxHours, maxMinutes, minHours } = minMaxValues;

    switch (true) {
        case newValue < 0:
            updatedHoursAndMinutes[MINUTES_INDEX] = '0';
            break;
        case newValue > 59:
            updatedHoursAndMinutes[MINUTES_INDEX] = '59';
            break;
        default:
            updatedHoursAndMinutes[MINUTES_INDEX] = newValue.toString();
    }

    if (hours === maxHours) {
        if (Number(updatedHoursAndMinutes[MINUTES_INDEX]) > maxMinutes) {
            updatedHoursAndMinutes[MINUTES_INDEX] = mapNumberToDoubleDigitString(maxMinutes);
        }
    }
    if (hours === minHours) {
        if (Number(updatedHoursAndMinutes[MINUTES_INDEX]) < minMinutes) {
            updatedHoursAndMinutes[MINUTES_INDEX] = mapNumberToDoubleDigitString(minMinutes);
        }
    }

    return updatedHoursAndMinutes;
}

function mapNumberToDoubleDigitString(value: number) {
    return value < 10 ? '0' + value.toString() : value.toString();
}

function getTimeUnitFromFormattedTime(formattedTime: string, timeUnit: TimeUnit) {
    const index = mapTimeUnitToIndex(timeUnit);
    return typeof formattedTime === 'string' ? formattedTime.split(':')[index] : undefined;
}

function getTimeUnitFromFormattedTimeAsNumber(formattedTime: string, timeUnit: TimeUnit) {
    const unit = getTimeUnitFromFormattedTime(formattedTime, timeUnit);
    return unit ? Number(unit) : undefined;
}

export default connect<IPrivateProps, ITimePickerProps>({
    stateProps: (state) => {
        return {
            locale: localeToFlatpickrLocale(getLocale(state), false),
        };
    },
})(TimePicker);
