import React from 'react';
import './priority-status-indicator.scss';
import { PolicyAdvisePriorityId } from '../../../../config/intervention.config';
import Translate from '../../Translate';
import Icon from '../../icons/Icon';
import { TIconTypeName, TIconColorType } from '../../../../models/general/icon';

interface IPriorityIndicatorProps {
    value: PolicyAdvisePriorityId;
}

interface IPriorityStatus {
    colorType: TIconColorType;
    iconTypeName: TIconTypeName;
    translationKey: string;
}

interface IPriorityStatuses {
    [key: string]: IPriorityStatus;
}

const CLASS_NAME = 'PriorityIndicator';

export default function PriorityIndicator(props: IPriorityIndicatorProps) {
    const { value } = props;

    const status = POLICY_ADVISE_PRIORITY_ID_TO_STATUS_INDICATOR_VALUE[value];

    return (
        <div
            className={CLASS_NAME}
        >
            <Icon typeName={status.iconTypeName} colorType={status.colorType} />
            <span>
                <Translate msg={status.translationKey} />
            </span>
        </div>

    );
}

export const POLICY_ADVISE_PRIORITY_ID_TO_STATUS_INDICATOR_VALUE: IPriorityStatuses = {
    [PolicyAdvisePriorityId.Low]: {
        colorType: 'success',
        iconTypeName: 'bell',
        translationKey: 'common.priority.low',
    },
    [PolicyAdvisePriorityId.Medium]: {
        colorType: 'warning',
        iconTypeName: 'bell-1',
        translationKey: 'common.priority.medium',
    },
    [PolicyAdvisePriorityId.High]: {
        colorType: 'danger',
        iconTypeName: 'bell-2',
        translationKey: 'common.priority.high',
    },
};
