import React, { PureComponent } from 'react';
import '../shared/plan-exam-tile-select.scss';
import { connect } from '../../../index';
import PageHeader from '../../../appShell/PageHeader';
import Button from '../../../common/buttons/Button';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import Icon from '../../../common/icons/Icon';
import Dialog from '../../../common/modals/Dialog';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader';
import Tile from '../../../common/widget/Tile';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import { EXAMINATION_REASON_CODE } from '../../../../config/navigation/interventions.config';
import {
    IExaminationReason,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    ITriggerPlanMedicalExaminationWizardProps,
} from '../../../../models/interventions/medicalExaminations';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import {
    getExaminationReasonsAsyncInfo,
    getActiveExaminationReasons,
} from '../../../../redux/medicalExamination/selectors';
import {navigateToPlanMedicalExaminationWizardStep,
    resetPlanMedicalExaminationWizardEntity,
} from '../../../../redux/medicalExamination/actions';
import stethoscopeSvg from '../../../assets/img/icons/stethoscope.svg';

const NO_SELECTED_REASON = null;
const CLASS_NAME = 'SelectExaminationReason';

interface IPrivateProps {
    reasonsAsyncInfo: IAsyncFieldInfo;
    reasons: IExaminationReason[];
    triggerPlanMedicalExaminationWizard: (triggerProps: ITriggerPlanMedicalExaminationWizardProps) => void;
}

interface IComponentState {
    selectedReason: IExaminationReason;
    manualChooseWizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE;
    automaticChooseWizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE;
}

class SelectExaminationReason extends PureComponent<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedReason: NO_SELECTED_REASON,
            manualChooseWizardType: null,
            automaticChooseWizardType: null,
        };

        this.selectReason = this.selectReason.bind(this);
        this.closeChooseWizardType = this.closeChooseWizardType.bind(this);
    }

    public render() {
        const { reasonsAsyncInfo, reasons } = this.props;

        const infoIcon = (
            <Icon
                typeName="info"
                circle
            />
        );

        return (
            <div className={`${CLASS_NAME} tile-dashboard`}>
                <PageHeader
                    title="interventions.medical_examinations.new.reasons_title"
                />
                <div className="container">
                    {reasonsAsyncInfo.error &&
                    <ErrorPlaceholder apiError={reasonsAsyncInfo.error} />
                    }

                    <div className="tiles">
                        <Loader show={reasonsAsyncInfo.status}>
                            {
                                reasons.map((reason) => (
                                    <div
                                        className="tile-wrapper"
                                        key={reason.id}
                                    >
                                        <Tile
                                            title={reason.title}
                                            svg={reason.regularIcon ? reason.regularIcon.url : stethoscopeSvg}
                                            svgAsImage={!!reason.regularIcon}
                                            svgHover={reason.hoverIcon && reason.hoverIcon.url}
                                            typeName="white"
                                            onClick={() => this.selectReason({ reason })}
                                            tooltipWithIcon={
                                                reason.infoText &&
                                                <TooltipWithIcon
                                                    icon={infoIcon}
                                                    typeName="info-bubble"
                                                >
                                                    <span>{reason.infoText}</span>
                                                </TooltipWithIcon>
                                            }
                                        />
                                    </div>
                                ))
                            }
                        </Loader>
                    </div>
                </div>

                {this.renderChooseWizardType()}
            </div>
        );
    }

    private selectReason({ reason }: {reason: IExaminationReason}) {
        switch (reason.code) {
            case EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT:
                this.setState({
                    selectedReason: reason,
                    manualChooseWizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                    // eslint-disable-next-line max-len
                    automaticChooseWizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC,
                });
                break;
            case EXAMINATION_REASON_CODE.RESUMPTION_OF_WORK:
                this.props.triggerPlanMedicalExaminationWizard({
                    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL,
                    reason,
                });
                break;
            case EXAMINATION_REASON_CODE.RE_INTEGRATION:
                this.props.triggerPlanMedicalExaminationWizard({
                    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RE_INTEGRATION_MANUAL,
                    reason,
                });
                break;
            default:
                // for the other types there is no 'automatic' option and we directly go to the correct wizard flow
                // TODO trigger different flows based on selected reason

                this.props.triggerPlanMedicalExaminationWizard({
                    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.OTHER_MANUAL,
                    reason,
                });
        }
    }

    private closeChooseWizardType() {
        this.setState({
            selectedReason: NO_SELECTED_REASON,
        });
    }

    private renderChooseWizardType() {
        const { selectedReason, manualChooseWizardType, automaticChooseWizardType } = this.state;
        const { triggerPlanMedicalExaminationWizard } = this.props;

        const AutomaticActionLink = (
            <a
                onClick={() => triggerPlanMedicalExaminationWizard({
                    wizardType: automaticChooseWizardType,
                    reason: selectedReason,
                })}
            >
                <span className="placeholderLink">
                    <Translate
                        msg="interventions.medical_examinations.new.choose_wizard_type.automatic.action"
                    />
                </span>
            </a>
        );

        return (
            <Dialog
                show={selectedReason !== NO_SELECTED_REASON}
                onCloseIntent={this.closeChooseWizardType}
                className={`${CLASS_NAME}__dialog`}
                footer={
                    <>
                        <h6>
                            <Translate
                                msg="interventions.medical_examinations.new.choose_wizard_type.automatic.title"
                            />
                        </h6>
                        <Translate
                            msg="interventions.medical_examinations.new.choose_wizard_type.automatic.message"
                            placeholders={{
                                action: AutomaticActionLink,
                            }}
                        />
                    </>}
            >
                {selectedReason &&
                <>
                    <h1>{selectedReason.title}</h1>
                    <h3>
                        <Translate msg="interventions.medical_examinations.new.choose_wizard_type.manual.title" />
                    </h3>
                    <p>
                        <Translate msg="interventions.medical_examinations.new.choose_wizard_type.manual.message" />
                    </p>
                    <Button
                        id="plan-examination-reason-manually"
                        typeName="secondary"
                        onClick={() => triggerPlanMedicalExaminationWizard({
                            wizardType: manualChooseWizardType,
                            reason: selectedReason,
                        })}
                    >
                        <Translate msg="interventions.medical_examinations.new.choose_wizard_type.manual.action" />
                    </Button>
                </>
                }
            </Dialog>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            reasonsAsyncInfo: getExaminationReasonsAsyncInfo(state),
            reasons: getActiveExaminationReasons(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            triggerPlanMedicalExaminationWizard:
                ({ wizardType, reason }: ITriggerPlanMedicalExaminationWizardProps) => {
                    dispatch(resetPlanMedicalExaminationWizardEntity());
                    dispatch(
                        navigateToPlanMedicalExaminationWizardStep({
                            wizardType,
                            reason,
                            resetDataEntity: true,
                        }),
                    );
                },
        };
    },
})(SelectExaminationReason);
