import React, { Component } from 'react';
import './onboarding-availabilities.scss';
import classNames from 'classnames';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import
ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../../common/widget/ContentWithSidebar';
import { connect } from '../../../..';
import {
    getOnboardingWizardEntity,
    getOnboardingWizardStepId,
    getOnboardingSeatAvailabilitiesAsyncInfo,
    getCopyOnboardingAvailabilitiesAsyncInfo,
} from '../../../../../redux/onboarding/selectors';
import { ICompanySeat } from '../../../../../models/admin/company';
import { createSelector } from 'reselect';
import { IState } from '../../../../../redux';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../../../utils/core/string/anchor';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import PageHeader from '../../../../appShell/PageHeader';
import Availabilities from './Availabilities';
import { CompanyAvailabilityType } from '../../../../../models/admin/companyInfo';
import StickyFooter from '../../../../common/widget/StickyFooter';
import { ONBOARDING_WIZARD_STEP_ID } from '../../../../../models/onboarding/wizard';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import Radiobutton from '../../../../common/input/Radiobutton';
import Translate from '../../../../common/Translate';
import {
    copyOnboardingAvailabilitiesActions,
    updateOnboardingWizardEntity,
    fetchOnboardingSeatsAvailabilitiesActions,
} from '../../../../../redux/onboarding/actions';
import Loader from '../../../../common/waiting/Loader';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';

const CLASS_NAME = 'OnboardingAvailabilities';

interface ITitleConfig {
    id: string;
    companyName: string;
    type: CompanyAvailabilityType;
    companyCode: string;
}

interface ITitlesConfig {
    [key: string]: ITitleConfig;
}

interface IPrivateProps {
    isMedicalExaminationAvailabilities: boolean;
    titlesConfig: ITitlesConfig;
    fetchAvailabilitiesAsyncInfo: IAsyncFieldInfo;
    copyRiskMgmtAvailabilitiesToMedExamAvailabilities: () => void;
    copyOnboardingAvailabilitiesAsyncInfo: IAsyncFieldInfo;
    copiedMedExamAvailabilities: boolean;
    setCopiedMedExamAvailabilitiesOnEntity: (selectedCopy: boolean) => void;
    resetCopyOnboardingAvailabilities: () => void;
    resetFetchOnboardAvailabilities: () => void;
}

interface IComponentState {
    selectedRadioButtonValue: RadiobuttonValue;
}

type TProps = IPrivateProps & IStepperStepRenderProps;

enum RadiobuttonValue {
    copy = 'copy',
    change = 'change',
}

interface IRadioButtonConfig {
    label: string;
    value: RadiobuttonValue;
}

const RADIO_BUTTONS: IRadioButtonConfig[] = [{
    label: 'onboarding.wizard.steps.medical_examinations.radiobutton.copy_hours',
    value: RadiobuttonValue.copy,
}, {
    label: 'onboarding.wizard.steps.medical_examinations.radiobutton.change_hours',
    value: RadiobuttonValue.change,
}];

class OnboardingAvailabilities extends Component<TProps, IComponentState> {
    constructor(props: TProps) {
        super(props);

        this.state = {
            selectedRadioButtonValue: props.isMedicalExaminationAvailabilities && props.copiedMedExamAvailabilities ?
                RadiobuttonValue.copy : RadiobuttonValue.change,
        };

        this.renderSidebar = this.renderSidebar.bind(this);
        this.renderMainContent = this.renderMainContent.bind(this);
        this.renderRadiobuttons = this.renderRadiobuttons.bind(this);
        this.onChangeRadioValueHandler = this.onChangeRadioValueHandler.bind(this);
        this.onNextHandler = this.onNextHandler.bind(this);
        this.onPreviousHandler = this.onPreviousHandler.bind(this);
        this.resetAsyncInfoAndGoToNextStep = this.resetAsyncInfoAndGoToNextStep.bind(this);
        this.resetAsyncInfoAndGoToPreviousStep = this.resetAsyncInfoAndGoToPreviousStep.bind(this);
    }

    public componentDidUpdate(prevProps: TProps) {
        if (prevProps.copyOnboardingAvailabilitiesAsyncInfo.status === AsyncStatus.Busy &&
            this.props.copyOnboardingAvailabilitiesAsyncInfo.status === AsyncStatus.Success
        ) {
            this.resetAsyncInfoAndGoToNextStep();
        }
    }

    public render() {
        const {
            titlesConfig, renderStepButtons, isMedicalExaminationAvailabilities, fetchAvailabilitiesAsyncInfo,
        } = this.props;

        const TRANSLATION_PREFIX = isMedicalExaminationAvailabilities ?
            'onboarding.wizard.steps.medical_examinations' : 'onboarding.wizard.steps.risk_mgmt';

        if (!titlesConfig) {
            return null;
        }

        return (
            <div className={classNames('container', CLASS_NAME)}>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <ContentWithSidebar
                        sidebar={(renderProps) => this.renderSidebar(renderProps)}
                        content={this.renderMainContent()}
                        titleIds={Object.values(titlesConfig).map((item) => item.id)}
                    />
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                onClick: this.onNextHandler,
                                disabled: fetchAvailabilitiesAsyncInfo.status === AsyncStatus.Busy,
                            },
                            prevButton: {
                                onClick: this.onPreviousHandler,
                            },
                        })}
                    </StickyFooter>
                </div>
            </div>
        );
    }

    private onNextHandler() {
        const {
            isMedicalExaminationAvailabilities,
            setCopiedMedExamAvailabilitiesOnEntity,
            copyRiskMgmtAvailabilitiesToMedExamAvailabilities,
        } = this.props;

        if (isMedicalExaminationAvailabilities) {
            const copyHours = this.state.selectedRadioButtonValue === RadiobuttonValue.copy;
            if (copyHours) {
                setCopiedMedExamAvailabilitiesOnEntity(true);
                copyRiskMgmtAvailabilitiesToMedExamAvailabilities();
            } else {
                setCopiedMedExamAvailabilitiesOnEntity(false);
                this.resetAsyncInfoAndGoToNextStep();
            }
        } else {
            this.resetAsyncInfoAndGoToNextStep();
        }
    }

    private onPreviousHandler() {
        const {
            isMedicalExaminationAvailabilities,
            setCopiedMedExamAvailabilitiesOnEntity,
        } = this.props;

        if (isMedicalExaminationAvailabilities) {
            const copyHours = this.state.selectedRadioButtonValue === RadiobuttonValue.copy;
            if (copyHours) {
                setCopiedMedExamAvailabilitiesOnEntity(true);
            } else {
                setCopiedMedExamAvailabilitiesOnEntity(false);
            }
        }
        this.resetAsyncInfoAndGoToPreviousStep();
    }

    private resetAsyncInfoAndGoToNextStep() {
        const { resetFetchOnboardAvailabilities, goToNextStep } = this.props;

        resetFetchOnboardAvailabilities();
        goToNextStep();
    }

    private resetAsyncInfoAndGoToPreviousStep() {
        const { resetFetchOnboardAvailabilities, goToPreviousStep } = this.props;

        resetFetchOnboardAvailabilities();
        goToPreviousStep();
    }

    private renderSidebar({ activeTitleId, setActiveTitleIdOverride }: IContentWithSidebarRenderProps) {
        const { titlesConfig } = this.props;

        return (
            <nav className={`${CLASS_NAME}__nav`}>
                {
                    Object.keys(titlesConfig).map((key, index) => {
                        const titleConfig: ITitleConfig = titlesConfig[key];
                        const titleClass = classNames({
                            active: activeTitleId === titleConfig.id,
                        });
                        return (
                            <SideBarLink
                                key={`onboarding_availabilities_${key}`}
                                href={toAnchorLinkHref(titleConfig.id)}
                                className={titleClass}
                                setActiveTitleIdOverride={() => setActiveTitleIdOverride(titleConfig.id)}
                            >
                                {titleConfig.companyName}
                            </SideBarLink>
                        );
                    })
                }
            </nav>
        );
    }

    private renderMainContent() {
        const { titlesConfig, isMedicalExaminationAvailabilities, copyOnboardingAvailabilitiesAsyncInfo } = this.props;
        const { selectedRadioButtonValue } = this.state;

        return (
            <div className={`${CLASS_NAME}__main`}>
                {isMedicalExaminationAvailabilities && copyOnboardingAvailabilitiesAsyncInfo.error && (
                    <ErrorPlaceholder apiError={copyOnboardingAvailabilitiesAsyncInfo.error} />
                )}
                {isMedicalExaminationAvailabilities && (
                    <Loader show={copyOnboardingAvailabilitiesAsyncInfo.status} >
                        <div className={`${CLASS_NAME}__radiobuttons`}>
                            {this.renderRadiobuttons()}
                        </div>
                    </Loader>
                )}

                <Loader show={copyOnboardingAvailabilitiesAsyncInfo.status} >
                    {Object.keys(titlesConfig).map((key) => {
                        const titleConfig: ITitleConfig = titlesConfig[key];

                        return (
                            <div key={`onboarding_availabilities_${titleConfig.id}`}>
                                <h2 id={toAnchorTargetId(titleConfig.id)}>
                                    {titleConfig.companyName}
                                </h2>
                                <div className="ContentData">
                                    <Availabilities
                                        type={titleConfig.type}
                                        companyCode={titleConfig.companyCode}
                                        companyName={titleConfig.companyName}
                                        copiedAvailabilities={selectedRadioButtonValue === RadiobuttonValue.copy}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Loader>
            </div>
        );
    }

    private renderRadiobuttons() {
        const { selectedRadioButtonValue } = this.state;
        return RADIO_BUTTONS.map((config, index) => {
            const isChecked = selectedRadioButtonValue === config.value;
            return (
                <Radiobutton
                    key={`copy_availabilities_radiobutton_${index}`}
                    name="select-delivery"
                    value={config.value}
                    checked={isChecked}
                    onChange={() => this.onChangeRadioValueHandler(config.value)}
                    className={isChecked ? 'checked' : null}
                >
                    <p><Translate msg={config.label} /></p>
                </Radiobutton>
            );
        });
    }

    private onChangeRadioValueHandler(value: RadiobuttonValue) {
        if (this.props.copyOnboardingAvailabilitiesAsyncInfo.error) {
            this.props.resetCopyOnboardingAvailabilities();
        }
        this.setState({ selectedRadioButtonValue: value });
    }
}

const createCoursesLocationSessionsMemoizedSelector = createSelector(
    (state: IState) => getOnboardingWizardEntity(state).seatsData,
    (state: IState) => getOnboardingWizardStepId(state),
    (seats, stepId) => mapSeatsToTitlesConfig(seats, stepId),
);

function mapSeatsToTitlesConfig(seats: ICompanySeat[], stepId: ONBOARDING_WIZARD_STEP_ID): ITitlesConfig {
    return seats
        .reduce(
            (accumulator, seat) => {
                const config: ITitleConfig = {
                    id: seat.company.companyCode,
                    companyName: seat.company.name,
                    type: stepId === ONBOARDING_WIZARD_STEP_ID.MEDICAL_EXAMINATION ?
                        CompanyAvailabilityType.MedicalExaminations :
                        CompanyAvailabilityType.RiskManagement,
                    companyCode: seat.company.companyCode,
                };
                accumulator[seat.company.companyCode] = config;

                return accumulator;
            },
            {},
        );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const stepId = getOnboardingWizardStepId(state);
        const copiedMedExamAvailabilities = getOnboardingWizardEntity(state).copiedMedExamAvailabilities;

        return {
            copiedMedExamAvailabilities,
            copyOnboardingAvailabilitiesAsyncInfo: getCopyOnboardingAvailabilitiesAsyncInfo(state),
            isMedicalExaminationAvailabilities: stepId === ONBOARDING_WIZARD_STEP_ID.MEDICAL_EXAMINATION,
            titlesConfig: createCoursesLocationSessionsMemoizedSelector(state),
            fetchAvailabilitiesAsyncInfo: getOnboardingSeatAvailabilitiesAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            copyRiskMgmtAvailabilitiesToMedExamAvailabilities: () => {
                dispatch(copyOnboardingAvailabilitiesActions.trigger({}));
            },
            setCopiedMedExamAvailabilitiesOnEntity: (selectedCopy: boolean) => {
                dispatch(updateOnboardingWizardEntity({
                    copiedMedExamAvailabilities: selectedCopy,
                }));
            },
            resetCopyOnboardingAvailabilities: () => {
                dispatch(copyOnboardingAvailabilitiesActions.reset({}));
            },
            resetFetchOnboardAvailabilities: () => {
                dispatch(fetchOnboardingSeatsAvailabilitiesActions.reset({}));
            },
        };
    },
})(OnboardingAvailabilities);
