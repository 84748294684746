import React from 'react';

import { ListColumns, SortType, ListItem } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import { IPlannedCompanyVisitsColumnNames } from './planned.type';

export const BASE_NAME = 'planned-company-visits';
export const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

export const COLUMNS: ListColumns<IPlannedCompanyVisitsColumnNames> = {
    visitDate: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IPlannedCompanyVisitsColumnNames>) => listItem.columns.visitDateSort,
        percentWidth: 13,
    },
    visitDateSort: {
        hide: true,
        percentWidth: null,
    },
    visitDateTime: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_date_time" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
    },
    visitCode: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_code" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 10,
    },
    company: {
        label: <Translate msg="interventions.company_visits.planned.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    visitor: {
        label: <Translate msg="interventions.company_visits.planned.columns.visitor" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    description: {
        label: <Translate msg="interventions.company_visits.planned.columns.description" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
};
