import React, { Component } from 'react';
import connect from '../../../../../../../utils/libs/redux/connect';
import { ITranslator } from '../../../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../models/general/redux';
import TimeSpanPicker, { TimeSpanFormValues } from '../../../shared/TimeSpanPicker';
import {
    getPlanMedicalExaminationWizardEntity,
    getAutoPlanEmployeesAsyncInfo,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../../redux/medicalExamination/selectors';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IExaminationReason,
    IMedicalExaminationToAdd,
} from '../../../../../../../models/interventions/medicalExaminations';
import { IStepperStepRenderProps } from '../../../../../../../models/general/stepper';
import {
    autoPlanEmployees, updatePlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/actions';
import PageHeader from '../../../../../../appShell/PageHeader';
import {
    mapMedExamsToAutoPlanPayload,
} from '../../../../../../../utils/interventions/medicalExaminations/mapMedExamsToAutoPlanPayload';
import Loader from '../../../../../../common/waiting/Loader';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import classNames from 'classnames';
import { WIZARDFLOW_CLASSES } from '../../../../../../common/navigation/Wizard/index';

interface IPrivateProps {
    currentStartTime: string;
    currentEndTime: string;
    translator: ITranslator;
    asyncInfo: IAsyncFieldInfo;
    dispatchUpdateEntityToSetCorrectInitialValues: (values: TimeSpanFormValues) => void;
    dispatchAutoPlanEmployeesWithNewTimes: (
        values: TimeSpanFormValues,
        selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
    ) => void;
}

interface IEditExaminationTime {
    onClose: () => void;
    selectedMedicalExaminations: IMedicalExaminationToAdd[];
}

interface IState {
    timeSpanError: boolean;
}

class EditExaminationTime extends Component<IPrivateProps & IStepperStepRenderProps & IEditExaminationTime, IState> {

    constructor(props) {
        super(props);

        this.onTimeSelected = this.onTimeSelected.bind(this);
    }

    public render() {
        const {
            currentEndTime, currentStartTime, asyncInfo, renderStepButtons, translator, selectedMedicalExaminations,
        } = this.props;
        return (
            <Loader show={asyncInfo.status === AsyncStatus.Busy}>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.validate_auto_plan.edit_time.title"
                    text="interventions.medical_examinations.new.steps.validate_auto_plan.edit_time.text"
                    titlePlaceholders={{
                        employee: selectedMedicalExaminations.length > 1 ?
                            // eslint-disable-next-line max-len
                            translator('interventions.medical_examinations.new.steps.validate_auto_plan.all_employees') :
                            formatPersonName(selectedMedicalExaminations[0].employee),
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <TimeSpanPicker
                        error={asyncInfo.error}
                        initialStartTime={currentStartTime}
                        initialEndTime={currentEndTime}
                        minDifferenceInMinutes={180}
                        onConfirm={this.onTimeSelected}
                        renderStepButtons={renderStepButtons}
                    />
                </div>
            </Loader>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.asyncInfo.status === AsyncStatus.Success &&
            prevProps.asyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onTimeSelected(values: TimeSpanFormValues) {
        const {
            dispatchAutoPlanEmployeesWithNewTimes,
            selectedMedicalExaminations,
            dispatchUpdateEntityToSetCorrectInitialValues,
        } = this.props;
        dispatchUpdateEntityToSetCorrectInitialValues(values);
        dispatchAutoPlanEmployeesWithNewTimes(values, selectedMedicalExaminations);
    }
}

export default connect<IPrivateProps, IEditExaminationTime>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>(state);
        const asyncInfo = getAutoPlanEmployeesAsyncInfo(state);
        return {
            currentStartTime: entity.startTime,
            currentEndTime: entity.endTime,
            translator: getTranslatorDeprecated(state),
            asyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            dispatchUpdateEntityToSetCorrectInitialValues: (values: TimeSpanFormValues) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>({
                    ...values,
                }));
            },
            dispatchAutoPlanEmployeesWithNewTimes: (
                values: TimeSpanFormValues,
                selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
            ) => {
                const state = getState();

                const examinationReason = getPlanMedicalExaminationWizardReason(state) as IExaminationReason;
                const entity = getPlanMedicalExaminationWizardEntity(state) as IPeriodicHealthAssessmentAutomaticEntity;

                const updatedSelectedMedicalExaminationsToAdd =
                    addStartAndEndTimeToExaminationsToAdd(values, selectedMedicalExaminationsToAdd);

                dispatch(autoPlanEmployees(mapMedExamsToAutoPlanPayload({
                    medicalExaminationsToAdd: updatedSelectedMedicalExaminationsToAdd,
                    entity,
                    examinationReason,
                })));
            },
        };
    },
})(EditExaminationTime);

function addStartAndEndTimeToExaminationsToAdd(
    values: TimeSpanFormValues,
    selectedMedicalExaminationsToAdd: IMedicalExaminationToAdd[],
) {
    return selectedMedicalExaminationsToAdd.map((selectedExam) => {
        const newExaminationToAdd = { ...selectedExam };

        newExaminationToAdd.startTime = values.startTime;
        newExaminationToAdd.endTime = values.endTime;

        return newExaminationToAdd;
    });
}
