import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../../index';
import { ICompanyVisitRequestWizardData } from '../../../models/interventions/company-visits';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Executed company visits

const getExecutedCompanyVisitsAsyncField = (state: IState) =>
    reducerState(state).executedCompanyVisits;

export const areExecutedCompanyVisitsAvailable = (state: IState) =>
    getExecutedCompanyVisitsAsyncField(state).data !== null;

export const getExecutedCompanyVisitsAsyncInfo = makeAsyncFetchInfoSelector(getExecutedCompanyVisitsAsyncField);

export const getExecutedCompanyVisits = (state: IState) =>
    getExecutedCompanyVisitsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Planned company visits

const getPlannedCompanyVisitsAsyncField = (state: IState) =>
    reducerState(state).plannedCompanyVisits;

export const arePlannedCompanyVisitsAvailable = (state: IState) =>
    getPlannedCompanyVisitsAsyncField(state).data !== null;

export const getPlannedCompanyVisitsAsyncInfo = makeAsyncFetchInfoSelector(getPlannedCompanyVisitsAsyncField);

export const getPlannedCompanyVisits = (state: IState) =>
    getPlannedCompanyVisitsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

const getCompanyVisitDocumentsAsyncField = (state: IState) =>
    reducerState(state).visitDocuments;

export const getCompanyVisitDocumentsAsyncInfo = makeAsyncFetchInfoSelector(getCompanyVisitDocumentsAsyncField);

export const getCompanyVisitDocuments = (state: IState) =>
    getCompanyVisitDocumentsAsyncField(state).data;

// Company visits wizard
const getCompanyVisitRequestWizardData = (state: IState) =>
    (reducerState(state).companyVisitRequestWizardData || NO_RERENDER.EMPTY_OBJECT) as ICompanyVisitRequestWizardData;

export const getCompanyVisitRequestWizardStepId = (state: IState) =>
    getCompanyVisitRequestWizardData(state).stepId;

export const getCompanyVisitRequestWizardEntity = (state: IState) =>
    getCompanyVisitRequestWizardData(state).entity;

// Request company visit
const getCreateCompanyVisitRequestAsyncField = (state: IState) =>
    reducerState(state).createCompanyVisitRequest;

export const getCreateCompanyVisitRequestAsyncInfo = makeAsyncDoInfoSelector(getCreateCompanyVisitRequestAsyncField);
