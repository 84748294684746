import { getNewInterventionRequestLink, PRESTATION_CODE } from './interventions.config';
import {
    ITopTask,
    ITopTaskTargetRouteKey,
    TaskCategory,
    TaskId,
} from '../../models/user/topTasks';
import { ADD_EMPLOYEE_WIZARD_STEP_ID } from '../../models/admin/employee';
import { triggerFluVaccinesOrderWizard } from '../../redux/intervention/fluVaccine/actions';
import ROUTE_KEYS from '../../routeKeys';
import { triggerCompanyVisitRequestWizard } from '../../redux/company/visits/actions';
import { TOP_TASKS_SVG_IDS } from '../../models/general/lazyLoadSvg';

export const DEFAULT_TASK_IDS: TaskId[] = [
    TaskId.PLAN_MEDICAL_EXAMINATION,
    TaskId.MANAGE_MISSING_EMAILS,
    TaskId.ADD_EMPLOYEE,
    TaskId.MANAGE_AGENDA,
];

export const NR_OF_TOP_TASKS = DEFAULT_TASK_IDS.length;

interface ITopTaskMap {
    [taskId: string]: ITopTask;
}

let possibleTasks: ITopTask[] = null;
let possibleTasksIdMap: ITopTaskMap = null;

/**
 * The tasks are not initialized @load, but @first request.
 * Otherwise the needed wizards steps were not registered yet.
 */
export function getPossibleTasks() {
    if (!possibleTasks) {
        possibleTasks = [
            ...initPossibleAddOrReportTasks(),
            ...initPossiblePlanOrRequestTasks(),
            ...initPossibleManageTasks(),
        ];
    }

    return possibleTasks;
}

export function getPossibleTasksIdMap(): { [taskId: string]: ITopTask } {
    if (!possibleTasksIdMap) {
        possibleTasksIdMap = getPossibleTasks()
            .reduce(
                (mapAccumulator, task) => {
                    mapAccumulator[task.id] = task;
                    return mapAccumulator;
                },
                {},
            );
    }

    return possibleTasksIdMap;
}

function initPossibleAddOrReportTasks(): ITopTask[] {
    return [
        toNavigationTask({
            id: TaskId.ADD_COMPANY_FUNCTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.ADD_FUNCTION,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_FUNCTIONS_ADD,
            },
            category: TaskCategory.ADD_OR_REPORT,
        }),
        toNavigationTask({
            id: TaskId.ADD_EMPLOYEE,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.ADD_EMPLOYEE,
            targetRoute: {
                key: ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD,
                payload: {
                    step: ADD_EMPLOYEE_WIZARD_STEP_ID.NATIONAL_REGISTER_NUMBER,
                },
            },
            category: TaskCategory.ADD_OR_REPORT,
        }),
        toNavigationTask({
            id: TaskId.ADD_USER,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.ADD_USER,
            targetRoute: {
                key: ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD,
            },
            category: TaskCategory.ADD_OR_REPORT,
        }),
    ];
}

function initPossiblePlanOrRequestTasks(): ITopTask[] {
    return [
        toNavigationTask({
            id: TaskId.PLAN_COMPANY_VISIT,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_COMPANY_VISIT,
            targetRoute: triggerCompanyVisitRequestWizard,
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.ORDER_FLU_VACCINES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.ORDER_FLU_VACCINS,
            targetRoute: triggerFluVaccinesOrderWizard,
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_MEDICAL_EXAMINATION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_MEDICAL_INTERVENTION,
            targetRoute: {
                key: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES,
            },
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_ENVIROMENTAL_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_ENVIROMENTAL_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.ENVIRONMENTAL),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_ERGONOMIC_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_ERGONOMIC_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.ERGONOMICS),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_HEALTH_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_HEALTH_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.HEALTH),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_PSYCHOLOGICAL_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_PSYCHOLOGICAL_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.PSYCHOSOCIAL),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_SAFETY_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_SAFETY_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.SAFETY),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_TOXILOGICAL_INTERNVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_TOXILOGICAL_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.HYG_TOX),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.PLAN_OTHER_INTERVENTION,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.PLAN_OTHER_INTERVENTION,
            targetRoute: getPlanInterventionTargetRoute(PRESTATION_CODE.OTHER),
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
        toNavigationTask({
            id: TaskId.ACTIVITY_REPORT,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_ACTIVITY_REPORT,
            targetRoute: {
                key: ROUTE_KEYS.R_ACTIVITY_REPORT,
            },
            category: TaskCategory.PLAN_OR_REQUEST,
        }),
    ];
}

function initPossibleManageTasks(): ITopTask[] {
    return [
        toNavigationTask({
            id: TaskId.MANAGE_AGENDA,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_AGENDA,
            targetRoute: {
                key: ROUTE_KEYS.R_AGENDA,
                payload: {
                    viewType: 'calendar',
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_BUFFERZONES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_BUFFERZONES,
            targetRoute: {
                key: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_COMPANY_FUNCTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_COMPANY_FUNCTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_FUNCTIONS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_COMPANY_INFO,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_COMPANY_INFO,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_COMPANY_RISKS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_COMPANY_RISKS,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_RISKS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_COMPANY_VISITS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_COMPANY_VISIT,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_FLU_VACCINES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_FLU_VACCINS,
            targetRoute: {
                key: ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_MEDICAL_EXAMINATIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_MEDICAL_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_ENVIROMENTAL_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_ENVIROMENTAL_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.ENVIRONMENTAL,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_ERGONOMIC_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_ERGONOMIC_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.ERGONOMICS,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_HEALTH_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_HEALTH_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.HEALTH,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_MISSING_EMAILS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_MISSING_EMAILS,
            targetRoute: {
                key: ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_PSYCHOLOGICAL_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_PSYCHOLOGICAL_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.PSYCHOSOCIAL,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_SAFETY_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_SAFETY_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.SAFETY,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_TOXILOGICAL_INTERNVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_TOXILOGICAL_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.HYG_TOX,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_OTHER_INTERVENTIONS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_OTHER_INTERVENTIONS,
            targetRoute: {
                key: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                payload: {
                    prestationCode: PRESTATION_CODE.OTHER,
                },
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_INVOICES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_INVOICES,
            targetRoute: {
                key: ROUTE_KEYS.R_INVOICES_OPEN,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_PREVENTION_UNITS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_PREVENTION_UNITS,
            targetRoute: {
                key: ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_FLAT_FEE,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_PREVENTION_UNITS,
            targetRoute: {
                key: ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_RATES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_RATES,
            targetRoute: {
                key: ROUTE_KEYS.R_COMPANY_RATES,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_WORK_ACCIDENTS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_WORK_ACCIDENTS,
            targetRoute: {
                key: ROUTE_KEYS.R_WORK_ACCIDENTS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_USERS,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_USERS,
            targetRoute: {
                key: ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_POLICY_ADVICE,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_POLICY_ADVICE,
            targetRoute: {
                key: ROUTE_KEYS.R_POLICY_ADVICE,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_COURSES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_COURSES,
            targetRoute: {
                key: ROUTE_KEYS.R_COURSES_OVERVIEW,
            },
            category: TaskCategory.MANAGE,
        }),
        toNavigationTask({
            id: TaskId.MANAGE_QUESTIONNAIRES,
            lazyLoadSvgId: TOP_TASKS_SVG_IDS.MANAGE_QUESTIONNAIRES,
            targetRoute: {
                key: ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK,
            },
            category: TaskCategory.MANAGE,
        }),
    ];
}

function toNavigationTask({
    id,
    code = undefined,
    lazyLoadSvgId,
    targetRoute,
    category,
}): ITopTask {
    const translationBase = code ? code : id;

    return {
        id,
        translationKey: `home.top_tasks.tasks.${translationBase.toLowerCase()}.title`,
        lazyLoadSvgId,
        targetRoute,
        category,
    };
}

function getPlanInterventionTargetRoute(prestationCode: PRESTATION_CODE): ITopTaskTargetRouteKey {
    const {
        linkTo: key,
        linkToPayload: payload,
    } = getNewInterventionRequestLink(prestationCode);

    return {
        key,
        payload,
    };
}
