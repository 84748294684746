import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import './courses-overview.scss';
import { ICourseCategory, ICMSCourse } from '../../../../models/documentCenter/courses';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import {
    getCoursesOverview, getFetchCoursesOverviewAsyncInfo,
} from '../../../../redux/documentCenter/courses/selectors';
import { navigateTo } from '../../../../redux/location/actions';
import { getQueryParams } from '../../../../redux/location/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import { connect } from '../../../index';
import CoursesList from './CoursesList';
import { FeaturedCourses } from '../shared/FeaturedCourses';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import CollapsibleCheckboxesFilter from '../../../common/input/CollapsibleCheckboxesFilter';
import TextInput from '../../../common/input/TextInput';
import Loader from '../../../common/waiting/Loader/index';
import ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../common/widget/ContentWithSidebar';
import {
    IRenderSearchContentProps, IRenderFilterContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import { CLASS_NAME as MASTER_WITH_DETAIL_CLASS_NAME } from '../../../common/widget/MasterWithDetail/index';
import ActiveFilters, {
    createGenericActiveFilters,
} from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import Header from '../../../common/widget/MasterWithDetail/Header';
import Translate from '../../../common/Translate';
import { toAnchorLinkHref } from '../../../../utils/core/string/anchor';
import clientSideFilter, {
    getUniqueTypeaheadFilterValuesFromCourses,
} from '../../../../utils/documentCenter/courses/filterCourses';
import { CourseLinks } from '../shared/CourseLinks';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import CollapsibleItem from '../../../common/widget/CollapsibleItem';
import isSet from '@snipsonian/core/es/is/isSet';

const CLASS_NAME = 'CoursesOverview';
const BASE_NAME = 'courses-overview';

export interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    theme: string;
    targetGroup: string;
    businessSectors: string;
    learningGoal: string;
    learningMethod: string;
    language: string;
    province: string;
    financingMethod: string;
}

interface IPrivateProps {
    coursesByCategories: ICourseCategory[];
    clientSideFilteredCoursesByCategories: ICourseCategory[];
    coursesInTheSpotlight: ICMSCourse[];
    fetchCoursesOverviewAsyncInfo: IAsyncFieldInfo;
    filterValues: object;
    setSearchFilterUrlQueryParams: (filter: IFilterValues) => void;
}

interface IComponentState {
    isFilterPanelOpen: boolean;
    slideOutPanelElementToFocus: string;
}

function CoursesOverview(props: IPrivateProps) {
    const {
        clientSideFilteredCoursesByCategories, fetchCoursesOverviewAsyncInfo, coursesInTheSpotlight,
    } = props;

    if (!clientSideFilteredCoursesByCategories) {
        return null;
    }

    return (
        <div className={`${CLASS_NAME}`}>
            <FeaturedCourses courses={coursesInTheSpotlight} />
            <ContentWithSidebar
                sidebar={(renderProps) => <SidebarContent {...props} {...renderProps} />}
                content={<MainContent {...props}/>}
                titleIds={clientSideFilteredCoursesByCategories
                    .map((courseCategory: ICourseCategory) => courseCategory.category)}
            />
            <CourseLinks noLoader={fetchCoursesOverviewAsyncInfo.status === AsyncStatus.Busy} />
            <Loader show={fetchCoursesOverviewAsyncInfo.status}/>
        </div>
    );
}

class MainContent extends PureComponent<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            isFilterPanelOpen: false,
            slideOutPanelElementToFocus: null,
        };

        this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
        this.onFilterPanelCloseIntent = this.onFilterPanelCloseIntent.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
    }

    public render() {
        const {
            isFilterPanelOpen, slideOutPanelElementToFocus,
        } = this.state;

        const {
            coursesByCategories, clientSideFilteredCoursesByCategories, filterValues,
        } = this.props;

        const headerConfig = {
            renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                <SearchContent {...renderProps} />,
            renderFilterContent:
                (renderProps: IRenderFilterContentProps<ICourseCategory[], IFilterValues>) =>
                    <FilterContent {...renderProps} />,
        };

        return (
            <>
                <Header
                    baseName={BASE_NAME}
                    baseClassName={MASTER_WITH_DETAIL_CLASS_NAME}
                    masterAsyncInfoSelector={getFetchCoursesOverviewAsyncInfo}
                    headerConfig={headerConfig}
                    isFilterPanelOpen={isFilterPanelOpen}
                    slideOutPanelElementToFocus={slideOutPanelElementToFocus}
                    masterData={coursesByCategories}
                    clientSideFilteredListItems={clientSideFilteredCoursesByCategories}
                    onFilterButtonClick={this.onSearchButtonClick}
                    onFilterSubmit={this.onFilterSubmit}
                    onFilterPanelCloseIntent={this.onFilterPanelCloseIntent}
                    filterValues={filterValues}
                    untransformedMasterDataSelector={getCoursesOverview}
                    filterValidationSchema={null}
                />
                <ActiveFilters
                    baseName={BASE_NAME}
                    baseClassName={MASTER_WITH_DETAIL_CLASS_NAME}
                    transformFilterValuesToActiveFilters={transformFilterValuesToActiveFilters}
                    masterData={coursesByCategories}
                    filterValues={filterValues}
                    onFilterSubmit={this.onFilterSubmit}
                    masterAsyncInfoSelector={getFetchCoursesOverviewAsyncInfo}
                />
                <CoursesList
                    coursesByCategories={this.props.clientSideFilteredCoursesByCategories}
                />
            </>
        );
    }

    private onFilterSubmit(filter: IFilterValues) {
        const { setSearchFilterUrlQueryParams } = this.props;
        this.setState({
            isFilterPanelOpen: false,
        });
        setSearchFilterUrlQueryParams(filter);
    }

    private onFilterPanelCloseIntent() {
        this.setState({
            isFilterPanelOpen: false,
        });
    }

    private onSearchButtonClick(elementToFocus: string) {
        if (!this.state.isFilterPanelOpen) {
            this.setState({
                isFilterPanelOpen: true,
                slideOutPanelElementToFocus: elementToFocus,
            });
        }
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('document_center.courses.overview.search.title')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="document_center.courses.overview.search.title" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ICourseCategory[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: coursesByCategories,
    } = renderProps;

    const flattenedCourses = toFlattenedCourses(coursesByCategories);

    const possibleThemes = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'mainTheme',
        'mainTheme',
    );

    const possibleTargetGroups = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'targetGroups',
        'targetGroups',
    );

    const possibleBusinessSectors = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'businessSectors',
        'businessSectors',
    );

    const possibleLearningGoals = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'learningGoal',
        'learningGoal',
    );

    const possibleLearningMethods = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'learningMethods',
        'learningMethods',
    );

    const possibleLanguages = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'languages',
        'languages',
    );

    const possibleProvinces = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'provinces',
        'provinces',
    );

    const possibleFinancingMethods = getUniqueTypeaheadFilterValuesFromCourses<ICMSCourse>(
        flattenedCourses,
        'financingMethods',
        'financingMethods',
    );

    return (
        <div className={`${CLASS_NAME}__filters`}>
            <CollapsibleCheckboxesFilter
                filterName="theme"
                labelTranslationKey="document_center.courses.overview.filter.theme"
                possibleFilterItems={possibleThemes}
                actualFilterValue={formRenderProps.values.theme}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'theme',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.theme}
            />
            <CollapsibleCheckboxesFilter
                filterName="targetGroup"
                labelTranslationKey="document_center.courses.overview.filter.target_group"
                possibleFilterItems={possibleTargetGroups}
                actualFilterValue={formRenderProps.values.targetGroup}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'targetGroup',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.targetGroup}
            />
            <CollapsibleCheckboxesFilter
                filterName="businessSectors"
                labelTranslationKey="document_center.courses.overview.filter.business_sector"
                possibleFilterItems={possibleBusinessSectors}
                actualFilterValue={formRenderProps.values.businessSectors}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'businessSectors',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.businessSectors}
            />
            <CollapsibleCheckboxesFilter
                filterName="learningGoals"
                labelTranslationKey="document_center.courses.overview.filter.learning_goal"
                possibleFilterItems={possibleLearningGoals}
                actualFilterValue={formRenderProps.values.learningGoal}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'learningGoal',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.learningGoal}
            />
            <CollapsibleItem
                trigger="document_center.courses.overview.filter.period"
                triggerTagName="h6"
                initialOpen={isSet(formRenderProps.values.startDate) || isSet(formRenderProps.values.endDate)}
            >
                <StartEndDateFilter
                    translationKeyPrefix="document_center.courses.overview.filter"
                    formRenderProps={formRenderProps}
                    hideTitle
                />
            </CollapsibleItem>
            <CollapsibleCheckboxesFilter
                filterName="learningMethods"
                labelTranslationKey="document_center.courses.overview.filter.learning_method"
                possibleFilterItems={possibleLearningMethods}
                actualFilterValue={formRenderProps.values.learningMethod}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'learningMethod',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.learningMethod}
            />
            <CollapsibleCheckboxesFilter
                filterName="language"
                labelTranslationKey="document_center.courses.overview.filter.language"
                possibleFilterItems={possibleLanguages}
                actualFilterValue={formRenderProps.values.language}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'language',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.language}
            />
            <CollapsibleCheckboxesFilter
                filterName="province"
                labelTranslationKey="document_center.courses.overview.filter.province"
                possibleFilterItems={possibleProvinces}
                actualFilterValue={formRenderProps.values.province}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'province',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.province}
            />
            <CollapsibleCheckboxesFilter
                filterName="financingMethods"
                labelTranslationKey="document_center.courses.overview.filter.financing_method"
                possibleFilterItems={possibleFinancingMethods}
                actualFilterValue={formRenderProps.values.financingMethod}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'financingMethod',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.financingMethod}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps,
) {
    return createGenericActiveFilters<IFilterValues, {}>({
        transformProps,
        translationKeyPrefix: 'document_center.courses.overview.active_filter',
        filters: {
            search: {
                show: true,
            },
            endDate: {
                show: true,
            },
            startDate: {
                show: true,
            },
            theme: {
                show: true,
            },
            targetGroup: {
                show: true,
                translationKeySuffixOverride: 'target_group',
            },
            businessSectors: {
                show: true,
                translationKeySuffixOverride: 'business_sectors',
            },
            learningGoal: {
                show: true,
                translationKeySuffixOverride: 'learning_goal',
            },
            learningMethod: {
                show: true,
                translationKeySuffixOverride: 'learning_method',
            },
            language: {
                show: true,
            },
            province: {
                show: true,
            },
            financingMethod: {
                show: true,
                translationKeySuffixOverride: 'financing_method',
            },
        },
    });
}

function SidebarContent(props: IPrivateProps & IContentWithSidebarRenderProps) {
    const {
        activeTitleId,
        clientSideFilteredCoursesByCategories,
        setActiveTitleIdOverride,
    } = props;

    return (
        <nav>
            {
                clientSideFilteredCoursesByCategories.map((courseCategory: ICourseCategory, index) => {
                    const titleClass = classNames({
                        active: activeTitleId === courseCategory.category,
                    });
                    return (
                        <SideBarLink
                            key={`coursesOverview_sidebar_${courseCategory.category}`}
                            href={toAnchorLinkHref(courseCategory.category)}
                            className={titleClass}
                            setActiveTitleIdOverride={() => setActiveTitleIdOverride(courseCategory.category)}
                        >
                            {`${courseCategory.category} (${courseCategory.courses.length.toString()})`}
                        </SideBarLink>
                    );
                })
            }
        </nav>
    );
}

export function toFlattenedCoursesInTheSpotlight(coursesByCategories: ICourseCategory[]): ICMSCourse[] {
    return toFlattenedCourses(coursesByCategories)
        .filter((course: ICMSCourse) => {
            return course.spotlight;
        });
}

export function toFlattenedCourses(coursesByCategories: ICourseCategory[]): ICMSCourse[] {
    return Object.keys(coursesByCategories)
    .reduce(
        (coursesAccumulator, index) => {
            return coursesAccumulator.concat(
                coursesByCategories[index].courses
                    .map((course: ICMSCourse) => {
                        return course;
                    }),
            );
        },
        [],
    );
}

export default connect<IPrivateProps>({
    statePropsPerInstance: (state) => {
        const clientSideFilteredCoursesMemoizedSelector = createSelector(
            getCoursesOverview,
            getQueryParams,
            (coursesByCategories, filterValues) => clientSideFilter(
                coursesByCategories,
                filterValues as IFilterValues,
            ),
        );
        const coursesInTheSpotlightMemoizedSelector = createSelector(
            getCoursesOverview,
            (coursesByCategories) => toFlattenedCoursesInTheSpotlight(coursesByCategories),
        );

        return (state) => {
            const coursesByCategories = getCoursesOverview(state);

            return {
                coursesByCategories,
                clientSideFilteredCoursesByCategories: clientSideFilteredCoursesMemoizedSelector(state),
                fetchCoursesOverviewAsyncInfo: getFetchCoursesOverviewAsyncInfo(state),
                coursesInTheSpotlight: coursesInTheSpotlightMemoizedSelector(state),
                filterValues: getQueryParams(state),
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setSearchFilterUrlQueryParams: (filter: IFilterValues) => {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_OVERVIEW, {}, { ...filter }));
            },
        };
    },
})(CoursesOverview);
