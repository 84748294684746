import { HEADER_NAME } from '../../../../../config/api.config';
import { IGenerateActivityReportPayload } from '../../../../../models/general/documents';
import { post } from '../../../../../utils/api/requestWrapper';
import { URL } from '../../documents.const';

export function generateActivityReport(payload: IGenerateActivityReportPayload) {
    return post({
        url: URL.ACTIVITY_REPORT_GENERATE,
        pathParams: {
            companyCode: payload.companyCode,
        },
        queryParams: {
            showFullFamily: payload.showFullFamily,
            individualSeats: payload.individualSeats,
        },
        headers: {
            [HEADER_NAME.LOCALE]: payload.locale,
        },
    });
}
