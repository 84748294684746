import React from 'react';

import Dialog from '../../../../../common/modals/Dialog';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import TextInput from '../../../../../common/input/TextInput';
import Translate from '../../../../../common/Translate';

import { ConfirmEmailModalProps, IConfirmEmailModalPayload } from './ConfirmEmailModal.type';
import { CLASS_NAME_BASE, fields, FORM_NAME, PLACEHOLDER_KEYS, TRANSLATION_KEYS } from './ConfirmEmailModal.const';
import { schema } from './ConfirmEmailForm.schema';

import './ConfirmEmailModal.style.scss';

const ConfirmEmailModal = ({ onChangeEmail, onDismiss, show, emailToMatch, translator }: ConfirmEmailModalProps) => {
    const handleFormSubmit = (values: IConfirmEmailModalPayload) => {
        if (emailToMatch === values.email) {
            onChangeEmail();
        }
    };

    return (
        <Dialog show={show} onCloseIntent={onDismiss}>
            <div className={`${CLASS_NAME_BASE}__content`}>
                <h3 className={`${CLASS_NAME_BASE}__title`}>
                    <Translate
                        msg={TRANSLATION_KEYS.title}
                    />
                </h3>

                <p>
                    <Translate
                        msg={TRANSLATION_KEYS.changeTo}
                        placeholders={{
                            email: emailToMatch,
                        }}
                    />
                </p>

                <p className={`${CLASS_NAME_BASE}__logout-warning`}>
                    <Translate
                        msg={TRANSLATION_KEYS.logoutWarning}
                    />
                </p>

                <div className={`${CLASS_NAME_BASE}__form`}>
                    <Form
                        name={FORM_NAME}
                        initialValues={{
                            email: '',
                        }}
                        schema={schema(emailToMatch)}
                        handleSubmit={handleFormSubmit}
                        render={(renderProps: IFormRenderProps<IConfirmEmailModalPayload>) => {
                            const { values, handleChange, errors, touched } = renderProps;

                            return (
                                <>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id={fields.email}
                                            name={fields.email}
                                            placeholder={translator(PLACEHOLDER_KEYS.confirmEmail)}
                                            value={values.email || ''}
                                            isInvalid={touched.email && !!errors.email}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor={fields.email}>
                                            {translator(PLACEHOLDER_KEYS.confirmEmail)}
                                        </label>
                                        {touched.email && (
                                            <FormFieldError
                                                error={errors.email}
                                                placeholders={{ fieldName: PLACEHOLDER_KEYS.email }}
                                            />
                                        )}
                                    </FloatableTextInputWrapper>
                                    <div className={`${CLASS_NAME_BASE}__content__actions`}>
                                        <SubmitButton
                                            id="confirm-email-change"
                                            formName={FORM_NAME}
                                            alwaysEnabled={false}
                                        >
                                            <Translate
                                                msg={TRANSLATION_KEYS.submit}
                                            />
                                        </SubmitButton>
                                    </div>
                                </>
                            );
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export { ConfirmEmailModal };
