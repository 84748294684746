import isObjectPure from '@snipsonian/core/es/is/isObjectPure';
import isTraceableApiError from '../../../api/isTraceableApiError';

export default function clearErrorsActionHandler(state: {}, errorIds: string[] = []) {
    if (errorIds.length === 0) {
        return state;
    }
    return clearMatchingTraceableApiErrors(state, errorIds);
}

function clearMatchingTraceableApiErrors(statePart: {}, errorIds: string[]) {
    if (!containsMatchingTraceableApiErrorRecursive(statePart, errorIds)) {
        return statePart;
    }

    return Object.keys(statePart)
        .reduce(
            (accumulator, key) => {
                const value = statePart[key];
                if (isMatchingTraceableApiError(value, errorIds)) {
                    accumulator[key] = null;
                } else if (isObjectPure(value)) {
                    accumulator[key] = clearMatchingTraceableApiErrors(value, errorIds);
                } else {
                    accumulator[key] = value;
                }
                return accumulator;
            },
            {},
        );
}

function containsMatchingTraceableApiErrorRecursive(statePart: {}, errorIds: string[]): boolean {
    return Object.keys(statePart)
        .some((key) => {
            const value = statePart[key];
            if (isMatchingTraceableApiError(value, errorIds)) {
                return true;
            }
            if (isObjectPure(value)) {
                return containsMatchingTraceableApiErrorRecursive(value, errorIds);
            }
            return false;
        });
}

function isMatchingTraceableApiError(value, errorIds: string[]): boolean {
    return isTraceableApiError(value) && errorIds.indexOf(value.id) !== -1;
}
