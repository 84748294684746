import {
    FETCH_LEGAL_PRIVACY_POLICY,
    FETCH_LEGAL_DISCLAIMER,
    FETCH_LEGAL_COOKIE_CONSENT,
    FETCH_COOKIES_CONFIGURATION,
} from './types';
import { createTypeActions } from '../../utils/libs/redux/createAction';

export const fetchCookiesConfigurationActions = createTypeActions({
    type: FETCH_COOKIES_CONFIGURATION,
});

export const fetchCookieConsentContentActions = createTypeActions({
    type: FETCH_LEGAL_COOKIE_CONSENT,
});

export const fetchPrivacyPolicyContentActions = createTypeActions({
    type: FETCH_LEGAL_PRIVACY_POLICY,
});

export const fetchDisclaimerContentActions = createTypeActions({
    type: FETCH_LEGAL_DISCLAIMER,
});
