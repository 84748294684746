import React, { Component } from 'react';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import { formatCurrentDateForBackend } from '../../../../../../utils/formatting/formatDate';
import Translate from '../../../../../common/Translate';
import {
    updatePlanMedicalExaminationWizardEntity,
    navigateToPlanMedicalExaminationWizardStep,
} from '../../../../../../redux/medicalExamination/actions';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    IExaminationReason,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../redux/medicalExamination/selectors';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

interface IState {
    date: string;
}

const MINIMUM_DATE = formatCurrentDateForBackend();

interface IPrivateProps {
    reason: IExaminationReason;
    initialStartDate: string;
    updateWizardEntity: (selectedDate: string) => void;
    triggerPlanMedicalExaminationWizard: () => void;
}

class AutomaticStartDate extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    constructor(props: IPrivateProps & IStepperStepRenderProps) {
        super(props);

        this.state = {
            date: props.initialStartDate || '',
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
    }

    public render() {
        const { renderStepButtons, triggerPlanMedicalExaminationWizard } = this.props;
        const { date } = this.state;

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.start_date.title"
                    text="interventions.medical_examinations.new.steps.start_date.text"
                    textPlaceholders={{
                        linkToManual:
                            <span className="placeholderLink">
                                <a onClick={triggerPlanMedicalExaminationWizard}>
                                    <Translate
                                        msg="interventions.medical_examinations.new.steps.start_date.manual_plan"
                                    />
                                </a>
                            </span>,
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <DatePicker
                        id="medical-examination-start-date"
                        onChange={this.onChangeHandler}
                        value={date}
                        inlineCalendar={true}
                        hideTextInput={true}
                        minDate={MINIMUM_DATE}
                    />
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                disabled: !date,
                                onClick: this.onClickNextHandler,
                            },
                        })}
                    </StickyFooter>
                </div>
            </>
        );
    }

    private onChangeHandler(formattedDate: string) {
        this.setState({
            date: formattedDate,
        });
    }

    private onClickNextHandler() {
        this.props.updateWizardEntity(this.state.date);
        this.props.goToNextStep();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            initialStartDate: getPlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>(state)
                .startDate,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardEntity: (selectedDate: string) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>({
                    startDate: selectedDate,
                }));
            },
            triggerPlanMedicalExaminationWizard: () => {
                dispatch(
                    navigateToPlanMedicalExaminationWizardStep({
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                        reason: getPlanMedicalExaminationWizardReason(getState()),
                        resetDataEntity: false, // so that the selected employees remain selected
                    }),
                );
            },
        };
    },
})(AutomaticStartDate);
