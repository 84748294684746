import React from 'react';

import { INTERVENTION_MANAGEMENT_ITEMS } from '../../../config/navigation/interventions.config';
import { ITabItem } from '../../../models/general/navigation';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';
import connect from '../../../utils/libs/redux/connect';
import ROUTE_KEYS from '../../../routeKeys';
import PageHeader from '../../appShell/PageHeader';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';

import { IPrivateProps, IPublicProps } from './vaccines.types';
import { VACCINES_CLASS_NAME } from './vaccines.const';
import './vaccines.scss';

const VaccinesTemplate = (props: IPublicProps & IPrivateProps) => {
    const { children } = props;

    const TABS: ITabItem[] = [
        {
            translationKey: 'interventions.vaccinations.to_administer.tab_title',
            linkTo: ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER,
        },
        {
            translationKey: 'interventions.vaccinations.administered.tab_title',
            linkTo: ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED,
        },
    ];

    const TABS_TO_SHOW = TABS.filter((tab) => !tab.hide);

    const CURRENT_ITEM = INTERVENTION_MANAGEMENT_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className={`${VACCINES_CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS_TO_SHOW}
                    title="interventions.vaccinations.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.INTERVENTIONS,
                    }}
                    type="grey"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
};


const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {};
    },
})(VaccinesTemplate);

export default function Template({ children }: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}
