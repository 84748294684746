import React from 'react';
import { RELEASE_BUILD } from '../../../config/errorReporting.config';

export default function AppVersion() {
    return (
        <div className="AppVersion">
            {RELEASE_BUILD}
        </div>
    );
}
