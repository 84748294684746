import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Work accidents overview
const getWorkAccidentsAsyncField = (state: IState) =>
    reducerState(state).workAccidents;

export const areWorkAccidentsAvailable = (state: IState) =>
    getWorkAccidentsAsyncField(state).data !== null;

export const getWorkAccidentsAysncInfo = makeAsyncFetchInfoSelector(getWorkAccidentsAsyncField);

export const getWorkAccidents = (state: IState) =>
    getWorkAccidentsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Work accident files
const getFilesForWorkAccidentAsyncField = (state: IState) =>
    reducerState(state).workAccidentFiles;

export const getFilesForWorkAccidentAsyncInfo =
    makeAsyncFetchInfoSelector(getFilesForWorkAccidentAsyncField);

export const getFilesForWorkAccident = (state: IState) =>
    getFilesForWorkAccidentAsyncField(state).data;
