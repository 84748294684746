import { createLogic, Logic } from 'redux-logic';
import { CreateLogic } from 'redux-logic/definitions/logic';
import { StandardAction } from 'redux-logic/definitions/action';
import isSet from '@snipsonian/core/es/is/isSet';
import { registerJourney } from '@snipsonian/redux/es/middleware/journey/journeyManager';
import { IJourneyConfig } from '@snipsonian/redux/es/middleware/journey/types';
import { shouldDataBeRefreshed } from '../async/asyncReducerUtils';
import { ONE_SECOND } from '../../../core/time/periodsInMillis';
import { IShouldRefreshDataPayload } from '../../../../models/general/redux';
import { REDUCER_KEYS } from '../../../../config/redux.config';
import { registerLogic } from './logicManager';
import isEmptyObject from '../../../core/object/isEmptyObject';
import {
    IOnActionTypeRegex, TEpicDependencies,
    TProcessMultipleHook, TProcessReturnHook,
} from './typings';
import { isPromiseTypeGuard } from '../../../core/promise/isPromise';
import { AnyAction } from 'redux';

const DEFAULT_LOGIC_NOT_COMPLETE_TIMEOUT_IN_MILLIS = 30 * ONE_SECOND;

export type ActionObj<Payload extends object, Query extends object = {}>
    = StandardAction<string, Payload, { query: Query }>;

export type EpicDepObj<State, Payload extends object, Query extends object, CustomEpicDependencies> =
    TEpicDependencies<State, ActionObj<Payload, Query>, CustomEpicDependencies>;

// tslint:disable no-any
// any because the input action can be transformed
type TAllowOrReject<Payload extends object, Query extends object> =
    (action: ActionObj<Payload, Query> | any) => void;
// tslint:enable no-any

type TFilterHook<EpicDependencies> = (
    deps: EpicDependencies,
) => boolean;

type TValidateHook<EpicDependencies, Payload extends object, Query extends object> = (
    deps: EpicDependencies,
    hooks: {
        allow: TAllowOrReject<Payload, Query>,
        reject: TAllowOrReject<Payload, Query>,
    },
) => void;

type TTransformHook<EpicDependencies, Payload extends object, Query extends object> = (
    deps: EpicDependencies,
    hooks: {
        next: TAllowOrReject<Payload, Query>,
    },
) => void;

type TRefreshDataIfHook<EpicDependencies> = (
    deps: EpicDependencies,
) => boolean;

type TOnActionType = string | string[] | IOnActionTypeRegex;

function isOnActionTypeRegex(onActionType: TOnActionType): onActionType is IOnActionTypeRegex {
    return onActionType && isSet(onActionType['pattern']);
}

export interface IEpicConfig<CustomEpicDependencies extends object,
    State extends object, Payload extends object, Query extends object> {
    onActionType: TOnActionType;
    onCancelActionType?: string;
    throttleTimeInMillis?: number;
    warnTimeout?: number;
    /* pre-reducer
     * (e.g. rejecting an action will cause it NOT to reach the reducers)
     * - filter: Custom convenience function to prevent actions from being processed (will not reach reducers on false).
     *           Expects a boolean return value.
     *           Internally calls validate.allow (if true) or validate.reject (if false)
     */
    filter?: TFilterHook<EpicDepObj<State, Payload, Query, CustomEpicDependencies>>;
    validate?: TValidateHook<EpicDepObj<State, Payload, Query, CustomEpicDependencies>, Payload, Query>;
    transform?: TTransformHook<EpicDepObj<State, Payload, Query, CustomEpicDependencies>, Payload, Query>;
    refreshDataIf?: TRefreshDataIfHook<EpicDepObj<State, Payload, Query, CustomEpicDependencies>>;
    noDataRefreshOnlyInTheseReducers?: REDUCER_KEYS[];
    /* post-reducer
     * (processing an action AFTER it reached the reducers)
     * - processFilter: Custom convenience function to prevent actions being processed by THIS epic (on false), but the
     *                  actions will still have been processed by the reducers.
     */
    skipProcessIfDataNotToBeRefreshed?: boolean;
    processFilter?: TFilterHook<EpicDepObj<State, Payload, Query, CustomEpicDependencies>>;
    processReturn?: TProcessReturnHook<State, ActionObj<Payload, Query>, CustomEpicDependencies>;
    processMultiple?: TProcessMultipleHook<State, ActionObj<Payload, Query>, CustomEpicDependencies>;
    latest: boolean;
}

export default function createEpic<CustomEpicDependencies extends object,
    State extends object, Payload extends object, Query extends object = {}>(
        epicId: string,
        config: IEpicConfig<CustomEpicDependencies, State, Payload, Query>,
        customConfig: Partial<CreateLogic.Config<
            State,
            ActionObj<Payload, Query>,
            EpicDepObj<State, Payload, Query, CustomEpicDependencies>,
            {},
            string
            >> = {},
) {
    if (toBeHandledByLogicMiddleware()) {
        const logic = createLogic<
            State,
            Payload,
            { query: Query },
            EpicDepObj<State, Payload, Query, CustomEpicDependencies>,
            {},
            string
        >({ // tslint:disable-line:ter-func-call-spacing
            type: isOnActionTypeRegex(config.onActionType)
                ? new RegExp(config.onActionType.pattern, config.onActionType.flags)
                : config.onActionType,
            cancelType: config.onCancelActionType,
            throttle: config.throttleTimeInMillis,
            warnTimeout: config.warnTimeout || DEFAULT_LOGIC_NOT_COMPLETE_TIMEOUT_IN_MILLIS,
            validate: validateOrFilter(),
            process: oneOfProcessConfigs(),
            transform: oneOfTransforms(),
            latest: config.latest,
            ...customConfig,
        });

        registerLogic(logic as Logic);

        return logic;
    }

    const journeyConfig: IJourneyConfig<
        State, ActionObj<Payload, Query>, CustomEpicDependencies
    > = {
        onActionType: isOnActionTypeRegex(config.onActionType) ? undefined : config.onActionType,
        onActionTypeRegex: isOnActionTypeRegex(config.onActionType) ? config.onActionType : undefined,
        process: (processInput) => {
            if (config.processFilter) {
                const shouldProcess = config.processFilter(processInput);
                if (!shouldProcess) {
                    return;
                }
            }

            if (config.processReturn) {
                const result = config.processReturn(processInput);
                if (isPromiseTypeGuard(result)) {
                    result.then((resultAction) => dispatchActionIfDefined(resultAction));
                } else {
                    dispatchActionIfDefined(result);
                }
            } else if (config.processMultiple) {
                config.processMultiple(processInput, processInput.dispatch, processMultipleDone);
            }

            function dispatchActionIfDefined(actionToDispatch: AnyAction) {
                if (actionToDispatch) {
                    processInput.dispatch(actionToDispatch);
                }
            }
        },
    };

    registerJourney<State, ActionObj<Payload, Query>, CustomEpicDependencies>(journeyConfig);

    return journeyConfig;

    type DepObj = EpicDepObj<State, Payload, Query, CustomEpicDependencies>;

    // validate has precedence over filter configuration
    function validateOrFilter() {
        if (config.validate) {
            return validate;
        }

        return config.filter ? validateByFiltering : undefined;
    }

    function validate(deps, allow, reject) {
        return config.validate(deps, { allow, reject });
    }

    function validateByFiltering(
        deps: DepObj,
        allow,
        reject,
    ) {
        if (config.filter(deps)) {
            allow(deps.action);
        } else {
            reject(undefined);
        }
    }

    function oneOfTransforms() {
        if (config.transform) {
            return transform;
        }

        return config.refreshDataIf ? transformRefreshDataIf : undefined;
    }

    function transform(deps, next) {
        return config.transform(deps, { next });
    }

    function transformRefreshDataIf(
        deps: DepObj,
        next,
    ) {
        const origAction = deps.action;
        const origPayload = origAction.payload as IShouldRefreshDataPayload;

        const shouldRefreshDataAlreadySet = isSet(origPayload.shouldRefreshData);

        if (shouldRefreshDataAlreadySet && !isSet(origPayload.noDataRefreshEpicIds)) {
            // 'no refresh' (if false) OR 'force refresh' (if true) already set 'globally' for all epics
            // --> orig action remains unchanged
            next(deps.action);
            return;
        }

        if (config.refreshDataIf(deps)) {
            // orig action remains unchanged
            // p.s. it can be that shouldRefreshData is false but not for this specific epic
            next(deps.action);
        } else {
            const noDataRefreshEpicIds = origPayload.noDataRefreshEpicIds || [];
            noDataRefreshEpicIds.push(epicId);

            const noDataRefreshOnlyInTheseReducers = isSet(config.noDataRefreshOnlyInTheseReducers)
                ? isSet(origPayload.noDataRefreshOnlyInTheseReducers)
                    ? origPayload.noDataRefreshOnlyInTheseReducers.concat(config.noDataRefreshOnlyInTheseReducers)
                    : config.noDataRefreshOnlyInTheseReducers
                : origPayload.noDataRefreshOnlyInTheseReducers;

            // add payload params to orig action to indicate that data is not to be refreshed
            const shouldRefreshDataPayload: IShouldRefreshDataPayload = {
                shouldRefreshData: false,
                noDataRefreshEpicIds,
                noDataRefreshOnlyInTheseReducers,
            };

            next({
                ...deps.action as object,
                payload: Object.assign(
                    deps.action.payload,
                    shouldRefreshDataPayload,
                ),
            });
        }
    }

    function oneOfProcessConfigs() {
        if (config.processReturn) {
            return processReturn;
        }

        if (config.processMultiple) {
            return processMultiple;
        }

        return undefined;
    }

    function processReturn(deps: DepObj) {
        if (!shouldActionBeProcessed(deps)) {
            return undefined;
        }
        return config.processReturn(deps);
    }

    function processMultiple(
        deps: DepObj,
        dispatch: (action) => void,
        done: () => void,
    ) {
        if (!shouldActionBeProcessed(deps)) {
            return undefined;
        }
        return config.processMultiple(deps, dispatch, done);
    }

    function shouldActionBeProcessed(deps: DepObj) {
        if (config.processFilter) {
            return config.processFilter(deps);
        }

        // tslint:disable-next-line no-boolean-literal-compare
        if (config.skipProcessIfDataNotToBeRefreshed === false) {
            return true;
        }

        return shouldDataBeRefreshed(deps.action.payload)
            ? true
            : shouldThisEpicProcessTheActionEvenThoughNoDataRefreshSet(deps.action.payload);
    }

    function shouldThisEpicProcessTheActionEvenThoughNoDataRefreshSet(payload: object) {
        const noDataRefreshEpicIds = (payload as IShouldRefreshDataPayload).noDataRefreshEpicIds;
        return isSet(noDataRefreshEpicIds)
            ? !noDataRefreshEpicIds.includes(epicId)
            : false; // no epicId set, so all epics will skip the process
    }

    function toBeHandledByLogicMiddleware(): boolean {
        if (customConfig && !isEmptyObject(customConfig)) {
            return true;
        }

        if (config.latest) {
            return true;
        }

        if (config.onCancelActionType || config.throttleTimeInMillis) {
            return true;
        }

        if (config.filter || config.validate || config.transform || config.refreshDataIf) {
            return true;
        }

        if (isOnActionTypeRegex(config.onActionType)) {
            return true;
        }

        return false;
    }
}

const processMultipleDone = () => {};
