import React, { ReactNode } from 'react';
import MainHeaderAndFooterTemplate from '../MainHeaderAndFooterTemplate';
import { ITranslator } from '../../../../models/general/i18n';
import { ILocationAction } from '../../../../models/general/redux';
import { IStepperStep } from '../../../../models/general/stepper';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { IState } from '../../../../redux';
import { getRoutePayload } from '../../../../redux/location/selectors';
import connect from '../../../../utils/libs/redux/connect';
import Icon from '../../../common/icons/Icon';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import Translate from '../../../common/Translate';
import { getWizardExitLinkActionMemoized } from '../../../../redux/ui/history/selectors';

type TWizardTitleCreator = (routePayload: object, translator: ITranslator, customInput?: any) => string;

export interface IWizardTemplateProps {
    getActiveStepConfigSelector?: (state: IState) => IStepperStep;
    wizardTitle: {
        titleTranslationKey?: string;
        hideExitLink?: boolean;
        // titleCreator takes precedence over titleTranslationKey
        titleCreator?: TWizardTitleCreator;
        getTitleCreatorCustomInputSelector?: (state: IState) => any;
    };
    hideExitLink?: boolean;
    hideFooterRouteLinks?: boolean;
    children: ReactNode;
}

interface IPrivateProps {
    wizardTitleMsg: string;
    hideExitLink: boolean;
    exitLinkAction: ILocationAction<{}>;
}

function WizardTemplate(props: IWizardTemplateProps & IPrivateProps) {
    const { wizardTitleMsg, hideExitLink, exitLinkAction, wizardTitle, hideFooterRouteLinks } = props;

    const WizardTitle = wizardTitleMsg ? (
        <>
            <div className="section section--title">
                <Translate msg={wizardTitleMsg}/>
            </div>
            { !hideExitLink && !wizardTitle.hideExitLink &&
            <div className="section">
                <LinkToRoute id="header-exit-link" to={exitLinkAction} className="exit-link">
                    <Icon className="cross"/>
                    <span><Translate msg="app_shell.header.exit"/></span>
                </LinkToRoute>
            </div>
            }
        </>
    ) : null;

    return (
        <MainHeaderAndFooterTemplate
            showAccountMenu={false}
            showCompanySelectDropdown={false}
            customHeaderClass="Header--flow"
            customHeaderPart={WizardTitle}
            showFluVaccineBanner={false}
            hideFooterRouteLinks={hideFooterRouteLinks}
        >
            {props.children}
        </MainHeaderAndFooterTemplate>
    );
}

export default connect<IPrivateProps, IWizardTemplateProps>({
    statePropsDeprecated: (state, publicProps) => {
        const routePayload = getRoutePayload(state);
        const translator = getTranslatorDeprecated(state);

        const customInput = publicProps.wizardTitle.getTitleCreatorCustomInputSelector
            ? publicProps.wizardTitle.getTitleCreatorCustomInputSelector(state)
            : undefined;

        const activeStepConfig = publicProps.getActiveStepConfigSelector
            ? publicProps.getActiveStepConfigSelector(state)
            : null;

        return {
            wizardTitleMsg: publicProps.wizardTitle.titleCreator
                ? publicProps.wizardTitle.titleCreator(routePayload, translator, customInput)
                : publicProps.wizardTitle.titleTranslationKey,
            hideExitLink: publicProps.hideExitLink || (activeStepConfig && activeStepConfig.hideExitLink),
            exitLinkAction: getWizardExitLinkActionMemoized(state),
        };
    },
})(WizardTemplate);
