import React, { Component } from 'react';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../../../../models/interventions/medicalExaminations';
import TimePicker from '../../../../../common/widget/DateTimePicker/TimePicker';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../common/Translate';
import './timespan-picker.scss';
import FormError from '../../../../../common/forms/FormError';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { schema } from './timeSchema';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import validateMinimumTimespan from './validateMinimumTimespan';
import { ITranslator } from '../../../../../../models/general/i18n';
import { IRenderStepButtonsOptions } from '../../../../../../models/general/stepper';
import { ITraceableApiError } from '../../../../../../models/general/error';
import connect from '../../../../../../utils/libs/redux/connect';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { navigateToPlanMedicalExaminationWizardStep } from '../../../../../../redux/medicalExamination/actions';
import { getPlanMedicalExaminationWizardReason } from '../../../../../../redux/medicalExamination/selectors';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import SubmitButton from '../../../../../common/buttons/SubmitButton';

export type TimeSpanFormValues = Pick<IPeriodicHealthAssessmentAutomaticEntity, 'startTime' | 'endTime'>;

const CLASS_NAME = 'TimeSpanPicker';
const FORM_NAME = 'time-span-picker-form';
const MIN_DIFFERENCE_IN_MINUTES = 180;

interface IPrivateProps {
    translator: ITranslator;
    triggerPlanMedicalExaminationWizard: () => void;
}

interface ITimeSpanPickerProps {
    initialStartTime: string;
    initialEndTime: string;
    minDifferenceInMinutes: number;
    error: ITraceableApiError;
    renderStepButtons?: (options?: IRenderStepButtonsOptions) => JSX.Element;
    onConfirm: (values: TimeSpanFormValues) => void;
    withLinkToManualPlanning?: boolean;
}

interface IState {
    timeSpanError: boolean;
}

class TimeSpanPicker extends Component<IPrivateProps & ITimeSpanPickerProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            timeSpanError: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        const {
            renderStepButtons, translator, initialStartTime, initialEndTime, error, triggerPlanMedicalExaminationWizard,
            withLinkToManualPlanning,
        } = this.props;
        const { timeSpanError } = this.state;

        const startTimeErrorMessageFieldName =
            translator('interventions.medical_examinations.new.steps.time.start_time');
        const endTimeErrorMessageFieldName =
            translator('interventions.medical_examinations.new.steps.time.end_time');

        const INITIAL_VALUES: TimeSpanFormValues = {
            startTime: initialStartTime || '',
            endTime: initialEndTime || '',
        };

        return (
            <Form
                name={FORM_NAME}
                handleSubmit={this.handleSubmit}
                initialValues={INITIAL_VALUES}
                className={CLASS_NAME}
                schema={schema}
                render={({ values, errors, touched, setFieldValue, isValid }: IFormRenderProps<TimeSpanFormValues>) => {
                    return (
                        <>
                            <div className={`${CLASS_NAME}__wrapper`}>
                                <div className={`${CLASS_NAME}__time`}>
                                    <FloatableTextInputWrapper inline>
                                        <label htmlFor="start-time">
                                            <Translate msg="common.time.from" />
                                        </label>
                                        <TimePicker
                                            id="start-time"
                                            value={values.startTime}
                                            onChange={(formattedTime) => setFieldValue(
                                                'startTime',
                                                formattedTime,
                                            )}
                                            isInvalid={touched.startTime && !!errors.startTime}
                                        />
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper inline>
                                        <label htmlFor="end-time">
                                            <Translate msg="common.time.to" />
                                        </label>
                                        <TimePicker
                                            id="end-time"
                                            value={values.endTime}
                                            onChange={(formattedTime) => {
                                                this.setState({ timeSpanError: false });
                                                setFieldValue(
                                                    'endTime',
                                                    formattedTime,
                                                );
                                            }}
                                            isInvalid={touched.startTime && (!!errors.endTime || timeSpanError)}
                                        />
                                    </FloatableTextInputWrapper>
                                </div>
                                {touched.startTime && (
                                    <FormFieldError
                                        error={errors.startTime}
                                        placeholders={{ fieldName: startTimeErrorMessageFieldName }}
                                    />
                                )}
                                {touched.endTime && (
                                    <FormFieldError
                                        error={errors.endTime}
                                        placeholders={{ fieldName: endTimeErrorMessageFieldName }}
                                    />
                                )}
                                {timeSpanError && (withLinkToManualPlanning ?
                                    <FormError
                                        // eslint-disable-next-line max-len
                                        translationKey="interventions.medical_examinations.new.steps.time.timespan_error_with_link_to_manual"
                                        placeHolders={{
                                            linkToManual:
                                                <span className="placeholderLink">
                                                    <a onClick={triggerPlanMedicalExaminationWizard}>
                                                        <Translate
                                                            // eslint-disable-next-line max-len
                                                            msg="interventions.medical_examinations.new.steps.time.timespan_error_link"
                                                        />
                                                    </a>
                                                </span>,
                                        }}
                                    /> :
                                    <FormError
                                        // eslint-disable-next-line max-len
                                        translationKey="interventions.medical_examinations.new.steps.time.timespan_error"
                                    />)
                                }
                                {error &&
                                    <FormError error={error} />
                                }
                            </div>
                            {typeof renderStepButtons === 'function' ? (
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {renderStepButtons({
                                        nextButton: {
                                            formName: FORM_NAME,
                                            isSubmit: true,
                                            onClick: () => null,
                                        },
                                    })}
                                </StickyFooter>
                            ) : (
                                <div className={`${CLASS_NAME}__buttons`}>
                                    <SubmitButton
                                        id="medical-examination-time-confirm"
                                        formName={FORM_NAME}
                                    >
                                        <Translate msg="common.confirmation_dialog.continue" />
                                    </SubmitButton>
                                </div>
                            )}
                        </>
                    );
                }}
            />
        );
    }

    private handleSubmit(values: TimeSpanFormValues) {
        const { onConfirm, minDifferenceInMinutes } = this.props;
        if (validateMinimumTimespan(
            values.startTime,
            values.endTime,
            minDifferenceInMinutes || MIN_DIFFERENCE_IN_MINUTES)
        ) {
            this.setState({ timeSpanError: false });
            onConfirm(values);
        } else {
            this.setState({ timeSpanError: true });
        }
    }
}

export default connect<IPrivateProps, ITimeSpanPickerProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerPlanMedicalExaminationWizard: () => {
                dispatch(
                    navigateToPlanMedicalExaminationWizardStep({
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                        reason: getPlanMedicalExaminationWizardReason(getState()),
                        resetDataEntity: false, // so that the selected employees remain selected
                    }),
                );
            },
        };
    },
})(TimeSpanPicker);
