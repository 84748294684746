import { createEpic } from '../../index';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat,
} from '../../company/selected/selectors';
import { getLocationState } from '../../location/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import { isPolicyAdviceAvailable, getPolicyAdvice } from './selectors';
import { fetchPolicyAdviceActions, fetchPolicyAdviceCommentsActions, sendPolicyAdviceCommentActions } from './actions';
import {
    IFetchPolicyAdvicePayload,
    IFetchPolicyAdviceCommentsPayload,
    ISendPolicyAdviceCommentPayload,
} from '../../../models/interventions/policyAdvice';
import { IState } from '../../IState';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { FETCH_POLICY_ADVICE, SEND_POLICY_ADVICE_COMMENT } from './types';

// fetchPolicyAdviceEpic
createEpic<IFetchPolicyAdvicePayload[]>({
    onActionType: [ROUTE_KEYS.R_POLICY_ADVICE, FETCH_POLICY_ADVICE],
    refreshDataIf: ({ getState }) => {
        const state = getState();

        if (!isPolicyAdviceAvailable(state)) {
            return true;
        }

        const {
            type: prevRouteKey,
        } = getLocationState(state);

        if (prevRouteKey === ROUTE_KEYS.R_POLICY_ADVICE_DETAIL) {
            /* No refresh if we navigate from details back to the overview */
            return false;
        }

        if (prevRouteKey === ROUTE_KEYS.R_POLICY_ADVICE) {
            /* No refresh if we stay on the overview and only client side filtering changed */
            return false;
        }

        return true;
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            const result = await api.interventions.policyAdvice.fetchPolicyAdvice(
                { companyCode, showFullFamily },
            );
            return fetchPolicyAdviceActions.succeeded(result);
        } catch (error) {
            return fetchPolicyAdviceActions.failed(error);
        }
    },
    latest: false,
});

// fetchPolicyAdviceCommentsEpic
createEpic<IFetchPolicyAdviceCommentsPayload>({
    onActionType: ROUTE_KEYS.R_POLICY_ADVICE_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            dispatch(sendPolicyAdviceCommentActions.reset({}));

            const state = getState();
            fetchPolicyAdviceIfNotAvailable(state, dispatch);
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const result = await api.interventions.policyAdvice.fetchPolicyAdviceComments({
                id: action.payload.id,
                companyCode,
                showFullFamily,
            });
            dispatch(fetchPolicyAdviceCommentsActions.succeeded(result));
        } catch (error) {
            dispatch(fetchPolicyAdviceCommentsActions.failed(error));
        }

        return done();
    },
    latest: true,
});

function fetchPolicyAdviceIfNotAvailable(
    state: IState,
    dispatch: (action: ArgumentAction<string, undefined, undefined>) => void,
) {
    const policyAdvice = getPolicyAdvice(state);

    if (policyAdvice.length <= 0) {
        dispatch(fetchPolicyAdviceActions.trigger({}));
    }
}

// sendPolicyAdviceCommentEpic
createEpic<ISendPolicyAdviceCommentPayload>({
    onActionType: SEND_POLICY_ADVICE_COMMENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.interventions.policyAdvice.sendPolicyAdviceComment(action.payload);

            updateSelectedPolicyAdviceInOverviewIfItHasNoFeedbackHistoryYet(action.payload.id, getState(), dispatch);

            dispatch(sendPolicyAdviceCommentActions.succeeded({}));
        } catch (error) {
            dispatch(sendPolicyAdviceCommentActions.failed(error));
        }
        return done();
    },
    latest: false,
});

function updateSelectedPolicyAdviceInOverviewIfItHasNoFeedbackHistoryYet(
    id: number,
    state: IState,
    dispatch: (action: ArgumentAction<string, undefined, undefined>) => void,
) {
    const policyAdvice = getPolicyAdvice(state);
    const selectedPolicyAdvice = policyAdvice.find((policyAdvice) => policyAdvice.id === id);
    if (selectedPolicyAdvice && !selectedPolicyAdvice.feedbackHistory) {
        const newPolicyAdvice =
            policyAdvice
                .map((policyAdvice) => {
                    if (policyAdvice.id === id) {
                        return {
                            ...policyAdvice,
                            feedbackHistory: 'true', /* string content does not matter as long as it's not empty
                                                            a message bubble will be displayed in the overview */
                        };
                    }
                    return policyAdvice;
                });
        dispatch(fetchPolicyAdviceActions.succeeded(newPolicyAdvice));
    }
}
