import React from 'react';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IRenderMasterActionsProps,
    IRenderDetailContentProps, IRenderDetailHeaderProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../routeKeys';
import { ListItem } from '../../../../models/general/list';
import {
    getFetchCompanyUsersAsyncInfo, getCompanyUsers,
    getFetchCompanyUserDetailsAsyncInfo, getSelectedCompanyUserDetails, getUpdateCompanyUserDetailsAsyncInfo,
} from '../../../../redux/company/users/selectors';
import { IUserAccount, IUserAccountDetail } from '../../../../models/user/userAccount';
import CompanyUsersList from './list';
import { ITranslator } from '../../../../models/general/i18n';
import DetailContent from './detail/content';
import DetailHeader from './detail/header';
import AddUserAccountButton from './AddUser/AddUserAccountButton';
import SelectUserTypeDialog from './AddUser/SelectUserTypeDialog';
import { translateAccountTypeByAdminType } from '../helpers/translateAccountType';
import { updateCompanyUserDetailsActions } from '../../../../redux/company/users/actions';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import { BASE_NAME, IColumnNames } from './common';

export default function ManageUsers() {
    return (
        <>
            <MasterWithDetail
                baseName={BASE_NAME}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS,
                    asyncInfoSelector: getFetchCompanyUsersAsyncInfo,
                    dataSelector: getCompanyUsers,
                    transformData: mapCompanyUserToListItems,
                    renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>) =>
                        <CompanyUsersList {...renderProps} />,
                }}
                detailConfig={{
                    routeKey: ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_DETAIL,
                    asyncInfoSelector: getFetchCompanyUserDetailsAsyncInfo,
                    idRouteParamName: 'username',
                    dataSelector: getSelectedCompanyUserDetails,
                    updateInfo: {
                        updateAsyncInfoSelector: getUpdateCompanyUserDetailsAsyncInfo,
                        updateAsyncInfoResetAction: updateCompanyUserDetailsActions.reset({}),
                    },
                    isForm: true,
                    renderContent: (renderProps: IRenderDetailContentProps<IUserAccountDetail>) =>
                        <DetailContent {...renderProps} />,
                    renderHeader: (renderProps: IRenderDetailHeaderProps<IUserAccount>) =>
                        <DetailHeader {...renderProps} />,
                }}
                footerConfig={{
                    renderActionsLeft: (renderProps: IRenderMasterActionsProps) =>
                        <AddUserAccountButton {...renderProps} />,
                }}
            />
            <SelectUserTypeDialog />
        </>
    );
}

function mapCompanyUserToListItems(
    masterData: IUserAccount[],
    translator: ITranslator,
): ListItem<IColumnNames>[] {
    return masterData
        .map((userAccount) => ({
            id: userAccount.username,
            columns: {
                // health@work does not update the user name of the user when he/she changes the
                // e-mail address of his/her account.
                // To avoid confusion, we display the e-mail address instead of the user name
                username: userAccount.email,
                email: userAccount.email,
                name: formatPersonNameFormal(userAccount),
                accountType: translator(
                    translateAccountTypeByAdminType(userAccount.admin, userAccount.superUser),
                ),
                actions: null,
            },
        }));
}
