import React, { Component, MouseEvent } from 'react';
import { clone } from 'ramda';

import exportListDataToCsv from '../../../../../../utils/file/csv/exportListDataToCsv';
import { ListItem } from '../../../../../../models/general/list';
import Button from '../../../../../common/buttons/Button';
import Icon from '../../../../../common/icons/Icon';
import List from '../../../../../common/list/List';
import ListActionButton from '../../../../../common/buttons/ListActionButton';
import ListItemActions from '../../../../../common/list/ListItemActions';
import Loader from '../../../../../common/waiting/Loader';
import ShowIfAllowed from '../../../../../auth/ShowIfAllowed';
import Translate from '../../../../../common/Translate';
import { mapPlannedMedicalExaminationForExport } from '../../../Planned/index/PlannedMedicalExaminations.helper';
import EditAppointmentDialog from '../../../Planned/EditAppointmentDialog';
import { CLASS_NAME, TRANSLATION_PREFIX } from '../ReservedMedicalExaminationDetail.const';
import { IReservedMedicalExaminationDetailListColumnNames } from '../ReservedMedicalExaminationDetail.type';

import { COLUMNS } from './PlannedMedicalExaminationsList.const';
import {
    PlannedMedicalExaminationsListProps,
    IListState,
} from './PlannedMedicalExaminationsList.type';

import './PlannedMedicalExaminationsList.scss';

class PlannedMedicalExaminationsList extends Component<
    PlannedMedicalExaminationsListProps, IListState
> {
    private columns = clone(COLUMNS);

    public constructor(props: PlannedMedicalExaminationsListProps) {
        super(props);

        this.state = {
            isEditAppointmentDialogOpen: false,
            selectedItemId: null,
            selectedItem: null,
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IReservedMedicalExaminationDetailListColumnNames>, index) => {
            return (
                <ListItemActions>
                    <Button
                        id={`download-file-button-${listItem.id}`}
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg={`${TRANSLATION_PREFIX}.actions.download_invitation`} /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                    <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                        <Icon
                            circle
                            typeName="pencil"
                            onClick={(e) => this.onEditClick(e, listItem)}
                        />
                    </ShowIfAllowed>
                </ListItemActions>
            );
        };
    }

    private onEditClick(
        e: MouseEvent<HTMLElement>,
        listItem: ListItem<IReservedMedicalExaminationDetailListColumnNames>,
    ) {
        e.preventDefault();
        e.stopPropagation();

        const { plannedMedicalExaminations = [] } = this.props;

        const selectedItem = plannedMedicalExaminations.find((plannedMedicalExamination) => {
            return plannedMedicalExamination.timeSlotId === listItem.id;
        });

        this.setState({
            isEditAppointmentDialogOpen: true,
            selectedItemId: listItem.id as number,
            selectedItem,
        });
    }

    private onDownloadClick(
        e: MouseEvent<HTMLElement>,
        listItem: ListItem<IReservedMedicalExaminationDetailListColumnNames>,
    ) {
        e.preventDefault();
        e.stopPropagation();

        const { onDownloadClick } = this.props;

        onDownloadClick(listItem);
    }

    public render() {
        const {
            plannedMedicalExaminationListItems,
            navigateToEmployeeDetails,
            reservedMedicalExaminationId,
            isDownloadingDocument,
            plannedMedicalExaminations,
        } = this.props;

        function onItemRowClicked(id: number) {
            const listItem = plannedMedicalExaminationListItems.find((item) => item.id === id);
            navigateToEmployeeDetails(reservedMedicalExaminationId, listItem.columns.idOfEmployee as number);
        }

        function onExportClicked() {
            const exportItems = plannedMedicalExaminations
                .map(mapPlannedMedicalExaminationForExport);

            exportListDataToCsv({
                baseFilename: 'MyMensura-bufferzone-planned-medical-examinations-export',
                listData: exportItems,
            });
        }

        return (
            <>
                <EditAppointmentDialog
                    show={this.state.isEditAppointmentDialogOpen}
                    onCloseIntent={() => {
                        this.setState({
                            isEditAppointmentDialogOpen: false,
                            selectedItem: null,
                        });
                    }}
                    plannedMedicalExamination={this.state.selectedItem}
                />
                <div className={`${CLASS_NAME}__list`}>
                    <ListActionButton
                        id="bufferzone-planned-medexams-export"
                        type="text"
                        iconTypeName="excel"
                        translationKey={`${TRANSLATION_PREFIX}.planned.export`}
                        onClick={onExportClicked}
                    />
                    <Loader show={isDownloadingDocument} />
                    <List
                        items={plannedMedicalExaminationListItems}
                        columns={this.columns}
                        name="bufferzone-planned-list"
                        selectedItemIds={[]}
                        hideHeaders={true}
                        onItemRowClicked={onItemRowClicked}
                    />
                </div>
            </>
        );
    }

}

export { PlannedMedicalExaminationsList };
