import { IState } from '../../IState';
import Api from '../../../api';
import { getSelectedCompanySeat, getSelectedSeatCompanyCode } from '../../company/selected/selectors';

export function doExportEmployeesCall({
    state,
    api,
    defaultFilename,
}: {
    state: IState,
    api: typeof Api,
    defaultFilename: string,
}) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

    return api.admin.employee
        .exportEmployees(
            { companyCode, showFullFamily },
            defaultFilename,
        );
}
