import { ICompanyCodePayload } from '../../models/admin/company';
import downloadCompanyDocumentByUrl from './downloadCompanyDocumentByUrl';
import uploadCompanyDocumentByUrl from './uploadCompanyDocumentByUrl';

export const DOC_TYPES = {
    ACTIVE: 'active',
    TEMPLATE: 'template',
};

export const URL = {
    IDENTIFICATION_DOCUMENT: '/companies/{companyCode}/identification-document',
    IDENTIFICATION_DOCUMENT_TEMPLATE: '/companies/{companyCode}/identification-document/template',
    IDENTIFICATION_DOCUMENT_ACTIVE: '/companies/{companyCode}/identification-document/active',
};

interface IDownloadIdentificationDocument extends ICompanyCodePayload {
    docType: string;
}

export function downloadIdentificationDocument(
    { companyCode, docType }: IDownloadIdentificationDocument,
) {
    const url = docType === DOC_TYPES.ACTIVE
        ? URL.IDENTIFICATION_DOCUMENT_ACTIVE
        : URL.IDENTIFICATION_DOCUMENT_TEMPLATE;

    return downloadCompanyDocumentByUrl({
        companyCode,
        url,
        defaultFilename: 'identificationdocument.doc',
    });
}

interface IUploadIdentificationDocument extends ICompanyCodePayload {
    fileToUpload: File;
}

export function uploadActiveIdentificationDocument(
    { companyCode, fileToUpload }: IUploadIdentificationDocument,
) {
    return uploadCompanyDocumentByUrl({
        companyCode,
        url: URL.IDENTIFICATION_DOCUMENT,
        fileToUpload,
    });
}
