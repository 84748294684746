import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { registerReducer } from '../../index';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';
import { SET_ACTIVE_DRAGGABLE, CLEAR_ACTIVE_DRAGGABLE } from './types';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { IActiveDraggable } from '../../../models/ui/dragAndDrop';

export const reducerKey = REDUCER_KEYS.DRAG_AND_DROP;

export interface IReducerState {
    activeDraggable: IActiveDraggable;
}

const initialState: IReducerState = {
    activeDraggable: null,
};

const actionHandlers = {
    [SET_ACTIVE_DRAGGABLE]: createActionHandler<IReducerState, IActiveDraggable>(
        ({ oldState, payload }) => {
            return {
                ...oldState,
                activeDraggable: payload,
            };
        }),
    [CLEAR_ACTIVE_DRAGGABLE]: createActionHandler<IReducerState>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    activeDraggable: null,
                };
            }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
