import { createEpic } from '../index';
import {
    fetchTopTasksSucceeded,
    fetchTopTasksFailed,
    updateTopTasksSucceeded,
    updateTopTasksFailed,
    fetchWebAppMessagesActions,
} from './actions';
import ROUTE_KEYS from '../../routeKeys';
import {
    UPDATE_TOP_TASKS,
    FETCH_COMPANY_DETAIL_BEFORE_NAVIGATING,
    FETCH_COMPANY_SITUATION_HISTORY_BEFORE_NAVIGATING,
} from './types';
import { IUpdateTopTasksPayload } from '../../models/user/topTasks';
import { areWebAppMessagesAvailable, getTopTasksMemoized } from './selectors';
import './validateEachRouteEpic';
import {
    IFetchCompanyDetailBeforeNavigatingPayload,
    IFetchCompanySituationHistoryBeforeNavigatingPayload,
} from '../../models/general/navigation';
import { getSelectedSeatCompanyCode } from '../company/selected/selectors';
import { redirectToUnexpectedError } from '../location/actions';
import { REDUCER_KEYS } from '../../config/redux.config';
import { fetchCompanySituationHistory } from '../preventionUnits/epics';
import { fetchCompanyDetailSucceeded } from '../company/info/actions';

// fetchTopTasksEpic
createEpic<{}>({
    onActionType: [
        ROUTE_KEYS.R_HOME,
        ROUTE_KEYS.R_EDIT_TOP_TASKS,
    ],
    refreshDataIf: ({ getState }) => {
        // do not refresh if top tasks already available
        const topTasks = getTopTasksMemoized(getState());
        return !topTasks || topTasks.length === 0;
    },
    noDataRefreshOnlyInTheseReducers: [REDUCER_KEYS.NAVIGATION],
    async processReturn({ api }) {
        try {
            const topTaskIds = await api.user.topTasks.fetchTopTasks();
            return fetchTopTasksSucceeded(topTaskIds);
        } catch (error) {
            return fetchTopTasksFailed(error);
        }
    },
    latest: false,
});

// updateTopTasksEpic
createEpic<IUpdateTopTasksPayload>({
    onActionType: UPDATE_TOP_TASKS,
    async processReturn({ api, action }) {
        try {
            await api.user.topTasks.updateTopTasks(action.payload);
            return updateTopTasksSucceeded(action.payload);
        } catch (error) {
            return updateTopTasksFailed(error);
        }
    },
    latest: false,
});

// fetchWebAppMessagesEpic
createEpic<{}>({
    onActionType: ROUTE_KEYS.R_HOME,
    async processMultiple({ api, action, getState }, dispatch, done) {
        if (!areWebAppMessagesAvailable(getState())) {
            try {
                dispatch(fetchWebAppMessagesActions.trigger({}));

                const messages = await api.general.webAppMessages.fetchWebAppMessages();
                dispatch(fetchWebAppMessagesActions.succeeded(messages));
            } catch (error) {
                dispatch(fetchWebAppMessagesActions.failed(error));
            }
        }
        done();
    },
    latest: false,
});

// fetchCompanyDetailBeforeNavigatingEpic
createEpic<IFetchCompanyDetailBeforeNavigatingPayload>({
    onActionType: FETCH_COMPANY_DETAIL_BEFORE_NAVIGATING,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            const state = getState();
            const companyDetail = await api.admin.companyInfo.fetchCompanyDetail(getSelectedSeatCompanyCode(state));

            dispatch(fetchCompanyDetailSucceeded(companyDetail));
            dispatch(payload.getNavigateAction(companyDetail, payload.requestedAction));
        } catch (error) {
            dispatch(redirectToUnexpectedError());
        }
        return done();
    },
    latest: false,
});

// fetchCompanySituationHistoryBeforeNavigatingEpic
createEpic<IFetchCompanySituationHistoryBeforeNavigatingPayload>({
    onActionType: FETCH_COMPANY_SITUATION_HISTORY_BEFORE_NAVIGATING,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;

            const companySituationHistoryResult = await fetchCompanySituationHistory({ api, getState, dispatch });

            if (companySituationHistoryResult.succeeded) {
                dispatch(payload.getNavigateAction(
                    companySituationHistoryResult.companySituationHistory,
                    payload.requestedAction,
                ));
            } else {
                dispatch(redirectToUnexpectedError());
            }
        } catch (error) {
            dispatch(redirectToUnexpectedError());
        }

        done();
    },
    latest: false,
});
