import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './alert.scss';
import Icon from '../../icons/Icon';

interface IAlertProps {
    children: ReactNode;
    small?: boolean;
    className?: string;
    type?: 'info' | 'warning' | 'success';
}

export default function Alert(props: IAlertProps) {
    const classes = classNames('Alert', {
        [props.className]: !!props.className,
        ['Alert--small']: props.small,
        ['Alert--info']: props.type === 'info',
        ['Alert--warning']: props.type === 'warning',
        ['Alert--success']: props.type === 'success',
    });

    return (
        <span className={classes}>
            {props.type === 'warning' && <Icon typeName="warning" />}
            {props.type === 'success' && <Icon typeName="check" />}
            {props.children}
        </span>
    );
}
