import {
    IState,
    getReducerState,
    makeAsyncFetchInfoSelector,
    NO_RERENDER,
    makeAsyncDoInfoSelector,
} from '../index';
import { IReducerState, reducerKey } from './reducer';
import {
    IUserMessage, IUserMessageDetails,
    IUserMessageWithMessageType,
    IMessageType,
} from '../../models/user/inbox';
import { getRoutePayload } from '../location/selectors';
import { getFetchConstantsAsyncInfo } from '../constants/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../models/general/redux';
import { getMessageTypes } from '../../config/constants';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getUnreadMessagesCountField = (state: IState) => reducerState(state).unreadMessagesCount;

export const getUnreadMessagesCountAsyncInfo = makeAsyncFetchInfoSelector(getUnreadMessagesCountField);

export const getUnreadMessagesCountIfAny = (state: IState) => {
    return getUnreadMessagesCountField(state).data;
};

// Fetch user messages
const getFetchUserMessagesAsyncField = (state: IState) => reducerState(state).userMessages;

export const getFetchUserMessagesAsyncInfo = makeAsyncFetchInfoSelector(getFetchUserMessagesAsyncField);

export const getUserMessages = (state: IState) =>
    (getFetchUserMessagesAsyncField(state).data || NO_RERENDER.EMPTY_LIST) as IUserMessage[];

export const getUserMessagesWithMessageType = (state: IState) => {
    const userMessages = getUserMessages(state);
    const messageTypes = getMessageTypes();

    return userMessages.map((message) => {
        return {
            ...message,
            messageType: messageTypes.find((type) => type.messageTypeId === message.typeId),
        } as IUserMessageWithMessageType;
    });
};

export const areUserMessagesAvailable = (state: IState) => !!reducerState(state).userMessages.data;

// Message center specific async info
export const getMessageCenterAsyncInfo = (state: IState) => {
    const userMessagesAsyncInfo = getFetchUserMessagesAsyncInfo(state);
    const constantsAsyncInfo = getFetchConstantsAsyncInfo(state);

    let status: AsyncStatus;

    switch (true) {
        case userMessagesAsyncInfo.status === AsyncStatus.Busy || constantsAsyncInfo.status === AsyncStatus.Busy:
            status = AsyncStatus.Busy;
            break;
        case userMessagesAsyncInfo.status === AsyncStatus.Success:
            status = AsyncStatus.Success;
            break;
        case userMessagesAsyncInfo.status === AsyncStatus.Error:
            status = AsyncStatus.Error;
            break;
        default:
            status = AsyncStatus.Initial;
    }

    return {
        error: userMessagesAsyncInfo.error,
        status,
    } as IAsyncFieldInfo;
};

// Fetch user message detail
const getFetchUserMessageDetailsAsyncField = (state: IState) => reducerState(state).userMessageDetails;

export const getFetchUserMessageDetailsAsyncInfo = makeAsyncDoInfoSelector(getFetchUserMessageDetailsAsyncField);

export const getSelectedUserMessageId = (state: IState) => {
    return getRoutePayload<{ id: string }>(state).id || null;
};

// List of user message details
const getUserMessageDetailsList = (state: IState) => reducerState(state).userMessageDetailsList;

export const getUserMessageDetailsById = (state: IState, id: string) => {
    const userMessageDetailsList = getUserMessageDetailsList(state);
    return userMessageDetailsList[id] || NO_RERENDER.EMPTY_OBJECT as IUserMessageDetails;
};

// Mark message as read
const getMarkMessageAsReadAsyncField = (state: IState) => reducerState(state).markMessageAsRead;

export const getMarkMessageAsReadAsyncInfo = makeAsyncDoInfoSelector(getMarkMessageAsReadAsyncField);

// Delete message
const getDeleteUserMessageAsyncField = (state: IState) => reducerState(state).deleteUserMessage;

export const getDeleteUserMessageAsyncInfo = makeAsyncDoInfoSelector(getDeleteUserMessageAsyncField);

// Update user message types
const getUpdateUserMessageTypesAsyncField = (state: IState) =>
    reducerState(state).updateUserMessageTypes;

export const getUpdateUserMessageTypesAsyncInfo = makeAsyncDoInfoSelector(getUpdateUserMessageTypesAsyncField);

// Fetch active user message types
const getFetchUserMessageTypesAsyncField = (state: IState) => reducerState(state).userMessageTypes;

export const getFetchUserMessageTypesAsyncInfo = makeAsyncFetchInfoSelector(getFetchUserMessageTypesAsyncField);

export const getUserMessageTypes = (state: IState) =>
    (getFetchUserMessageTypesAsyncField(state).data || NO_RERENDER.EMPTY_LIST) as IMessageType[];
