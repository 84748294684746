import React from 'react';
import './course-not-vacant-dialog.scss';
import Dialog from '../../../../../common/modals/Dialog';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button';

const CLASS_NAME = 'CourseNotVacantDialog';

interface ICourseNotVacantDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function CourseNotVacantDialog(props: ICourseNotVacantDialogProps) {
    const { open, onClose } = props;

    return (
        <Dialog
            show={open}
            onCloseIntent={onClose}
            disableClosing={true}
            type="error"
            header="document_center.courses.new.steps.course_not_vacant.title"
        >
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="course-not-vacant-approve"
                        typeName="secondary"
                        onClick={() => onClose()}
                    >
                        <Translate
                            msg="document_center.courses.new.steps.course_not_vacant.actions.approve"
                        />
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
