import { ICourseSessionsByMonth, ICourseSessions } from '../../../models/documentCenter/courses';
import { isFilterSet } from '../../../views/common/widget/MasterWithDetail/Master';
import {
    IFilterValues,
} from '../../../views/documentCenter/Courses/Overview/CourseIntroduction/CourseDetail/CourseDetailSessions';
import { separateStringList } from '../../core/string/separatedStringList';
import { isAfterOrEqual } from '../../core/date/isAfterOrEqual';
import { getDateWithoutTime } from '../../core/date/getSpecificDate';
import { isBeforeOrEqual } from '../../core/date/isBeforeOrEqual';

export default function clientSideFilter(
    sessionsByMonth: ICourseSessionsByMonth,
    filterValues: IFilterValues,
) {
    return applyFiltersOnCourseSessions(
        sessionsByMonth,
        filterValues,
    );
}

function applyFiltersOnCourseSessions(
    sessionsByMonth: ICourseSessionsByMonth,
    filterValues: IFilterValues,
): ICourseSessionsByMonth {
    return Object.keys(sessionsByMonth)
        .reduce((acc, monthId) => {
            const filteredSessions = clientSideFilterOfCourseSessions(sessionsByMonth[monthId], filterValues);

            return {
                ...acc,
                [monthId]: filteredSessions,
            };
        }, {});
}

function clientSideFilterOfCourseSessions(courseSessions: ICourseSessions, filterValues: IFilterValues) {
    return Object.keys(courseSessions)
        .reduce((acc, sessionId) => {
            // apply filter based on firstday session data
            const firstdaySession = courseSessions[sessionId].find((session) => session.dayNumber === 1);
            let matchesFilters = true;

            if (matchesFilters && isFilterSet(filterValues.province)) {
                const provincesFromFilter = separateStringList(filterValues.province);
                matchesFilters = provincesFromFilter.some((province) => firstdaySession.province.includes(province));
            }

            if (matchesFilters && isFilterSet(filterValues.language)) {
                const languagesFromFilter = separateStringList(filterValues.language);
                matchesFilters = languagesFromFilter.some((lang) => firstdaySession.language.includes(lang));
            }

            if (matchesFilters && isFilterSet(filterValues.startDate)) {
                matchesFilters = firstdaySession.start
                    && isAfterOrEqual(getDateWithoutTime(firstdaySession.start), filterValues.startDate);
            }

            if (matchesFilters && isFilterSet(filterValues.endDate)) {
                matchesFilters = firstdaySession.start
                    && isBeforeOrEqual(getDateWithoutTime(firstdaySession.start), filterValues.endDate);
            }

            return {
                ...acc,
                ...(matchesFilters && {
                    [sessionId]: courseSessions[sessionId],
                }),
            };
        }, {});
}
