import { get, post } from '../../utils/api/requestWrapper';
import { DEFAULT_LOCALE, Locales } from '../../config/i18n.config';
import { localeToBackendLocale } from '../../utils/formatting/formatLocale';
import { ICMSCookiesConfiguration, IExtraCookieMetaData } from '../../models/general/cookieConsent';
import { IFetchCookiesConfigurationInput } from '../../models/general/cookies';
import { API_URL } from '../../config/api.config';

const URL = {
    COOKIE_CONFIGURATION: '/public/cookie-config/klantenzone',
    COOKIE_LOG_ENTRY: '/public/cookie-consent',
};

let currentConfigLocale: Locales;
let cookieConfigPromise: Promise<ICMSCookiesConfiguration>;
export function memoizedCookiesConfiguration({
    locale = DEFAULT_LOCALE,
}: IFetchCookiesConfigurationInput) {
    if (!cookieConfigPromise || locale !== currentConfigLocale) {
        cookieConfigPromise = fetchCookiesConfiguration({ locale });
        currentConfigLocale = locale;
    }
    return cookieConfigPromise;
}

export function fetchCookiesConfiguration({
    locale = DEFAULT_LOCALE,
}: IFetchCookiesConfigurationInput) {
    return get<ICMSCookiesConfiguration>({
        url: URL.COOKIE_CONFIGURATION,
        api: API_URL.CMS,
        queryParams: {
            locale: localeToBackendLocale(locale),
        },
        mapResponse(data) {
            return data;
        },
    });
}

export function createCookieLogEntry() {
    return post<IExtraCookieMetaData>({
        url: URL.COOKIE_LOG_ENTRY,
        api: API_URL.CMS,
        mapResponse({ id: logId }) {
            return {
                logId,
            };
        },
    });
}
