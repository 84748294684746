import { REQUEST_METHOD } from '../../config/api.config';
import { IKeyValuePair } from '../core/typings';

export enum AutomaticHeadersType {
    ALL_REQUEST_METHODS = 'ALL_REQUEST_METHODS',
    NON_GET = 'NON_GET',
}

const automaticHeaders = {
    ALL_REQUEST_METHODS: {},
    NON_GET: {},
};

export function getAutomaticHeaders(requestMethod: REQUEST_METHOD) {
    if (requestMethod === REQUEST_METHOD.GET) {
        return automaticHeaders.ALL_REQUEST_METHODS;
    }

    return {
        ...automaticHeaders.ALL_REQUEST_METHODS,
        ...automaticHeaders.NON_GET,
    };
}

export function appendAutomaticHeaders(headers: object, requestMethod: REQUEST_METHOD): object {
    return {
        ...getAutomaticHeaders(requestMethod),
        ...headers,
    };
}

export function addAutomaticHeader(type: AutomaticHeadersType, ...keyValuePairs: IKeyValuePair[]) {
    automaticHeaders[type] = keyValuePairs
        .reduce(
            (headersAccumulator, keyValuePair) => ({
                ...headersAccumulator,
                [keyValuePair.key]: keyValuePair.value,
            }),
            automaticHeaders[type],
        );
}

export function isAutomaticHeaderSet(type: AutomaticHeadersType, headerName: string) {
    return !!automaticHeaders[type][headerName];
}

export function clearAutomaticHeader(type: AutomaticHeadersType, headerName: string) {
    delete automaticHeaders[type][headerName];
}
