import React, { Component } from 'react';
import AmountInput, { IFormValues } from '../../FluVaccinesOrderWizard/wizard/Amount/AmountInput';
import Button from '../../../common/buttons/Button';
import Translate from '../../../common/Translate';
import { connect } from '../../..';
import {
    getFluVaccineTariffsAsyncInfo, getSelectedFluVaccineOrder,
} from '../../../../redux/intervention/fluVaccine/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import Form, { IFormRenderProps, TSetFieldValue } from '../../../common/forms/Form';
import Loader from '../../../common/waiting/Loader';
import {
    schema,
} from '../../FluVaccinesOrderWizard/wizard/Amount/fluVaccineAmountToOrderSchema';
import { IEditAmountOverlaySaveData } from '../../FluVaccinesOrders/detailOverlay';
import { SLIDEOUTPANEL_CLASSES } from '../../../common/widget/SlideOutPanel/index';
import { IFormValues as IDetailFormValues } from '../../FluVaccinesOrders/detail';
import SubmitButton from '../../../common/buttons/SubmitButton';
import { IFluVaccinesOrder } from '../../../../models/interventions/fluVaccines';

const CLASS_NAME = 'FluVaccinesOrderEditAmount';
const FORM_NAME = 'flu-vaccine-order-edit-amount-form';

interface IPrivateProps {
    selectedFluVaccineOrder: IFluVaccinesOrder;
    unitPriceAsyncInfo: IAsyncFieldInfo;
}
interface IEditAmountProps {
    onConfirm: (data: IEditAmountOverlaySaveData) => void;
    onClose: () => void;
    detailFormValues: IDetailFormValues;
}

interface IState {
    selectedPrice: number;
}

class EditAmount extends Component<IPrivateProps & IEditAmountProps, IState> {
    private setFormFieldValue: TSetFieldValue<IFormValues>;

    constructor(props) {
        super(props);

        this.state = {
            selectedPrice: null,
        };

        this.amountChangedHandler = this.amountChangedHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const { onClose, unitPriceAsyncInfo, detailFormValues, selectedFluVaccineOrder } = this.props;

        if (!detailFormValues) {
            return null;
        }

        const INITIAL_VALUES: IFormValues = {
            amountToOrder: detailFormValues.amountVaccinesOrdered,
        };

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg="interventions.flu_orders_detail.edit_amount.title"
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onSubmit}
                    initialValues={INITIAL_VALUES}
                    schema={schema}
                    render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                        this.setFormFieldValue = formRenderProps.setFieldValue;

                        return (
                            <Loader show={unitPriceAsyncInfo.status}>
                                <div>
                                    <AmountInput
                                        formRenderProps={formRenderProps}
                                        onChangeInput={this.amountChangedHandler}
                                        initialAmountToOrder={selectedFluVaccineOrder.amountVaccinesOrdered}
                                    />
                                </div>
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="edit-amount-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={onClose}
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_amount.cancel" />
                                    </Button>
                                    <SubmitButton
                                        formName={FORM_NAME}
                                        id="edit-amount-submit-button"
                                        disabled={!this.state.selectedPrice}
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_amount.submit" />
                                    </SubmitButton>
                                </div>
                            </Loader>
                        );
                    }}
                />

            </div>
        );
    }

    private amountChangedHandler(values: IFormValues, totalVaccinesPrice: number) {
        this.setFormFieldValue('amountToOrder', values.amountToOrder);
        this.setState({
            selectedPrice: totalVaccinesPrice,
        });
    }

    private onSubmit(values: IFormValues) {
        this.props.onConfirm({ newAmount: values.amountToOrder, newPrice: this.state.selectedPrice });
    }
}

export default connect<IPrivateProps, IEditAmountProps>({
    stateProps: (state) => {
        return {
            selectedFluVaccineOrder: getSelectedFluVaccineOrder(state),
            unitPriceAsyncInfo: getFluVaccineTariffsAsyncInfo(state),
        };
    },
})(EditAmount);
