import React from 'react';
import { connect } from '../../index';
import PageHeader from '../../appShell/PageHeader';
import { IFaqItem } from '../../../models/contact/faq';
import { getSelectedFaqItem, getFaqOverviewAsyncInfo } from '../../../redux/contact/selectors';
import GoBack from '../../common/navigation/GoBack';
import ROUTE_KEYS from '../../../routeKeys';
import Loader from '../../common/waiting/Loader';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import './faq-detail.scss';

const CLASS_NAME = 'FaqDetail';

interface IPrivateProps {
    selectedFaqItem: IFaqItem;
    faqOverviewAsyncInfo: IAsyncFieldInfo;
}

function FaqDetailComp(props: IPrivateProps) {
    const {
        selectedFaqItem,
        faqOverviewAsyncInfo,
    } = props;

    if (!selectedFaqItem) {
        return null;
    }

    return (
        <div className={CLASS_NAME}>
            <Loader show={faqOverviewAsyncInfo.status}>
                <PageHeader
                    title={selectedFaqItem.question}
                    breadcrumbs={true}
                />
                <div className="container">
                    <div className={`${CLASS_NAME}__category`}>{selectedFaqItem.category}</div>
                    <div
                        className={`${CLASS_NAME}__content`}
                        dangerouslySetInnerHTML={{ __html: selectedFaqItem.answer }}
                    />
                    <GoBack id="go-back-to-faq-overview" to={ROUTE_KEYS.R_FAQ_OVERVIEW} />
                </div>
            </Loader>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const selectedFaqItem = getSelectedFaqItem(state);

        return {
            selectedFaqItem,
            faqOverviewAsyncInfo: getFaqOverviewAsyncInfo(state),
        };
    },
})(FaqDetailComp);
