import React, { PureComponent, ReactNode, ReactElement, ChangeEvent } from 'react';
import classNames from 'classnames';
import './list-with-checkbox-select.scss';
import { ListColumns, ListItem, ISortedColumn, SortOrder, SortType, ListItemId } from '../../../../models/general/list';
import List from '../List';
import ListWithSorting, { TItemsSorter } from '../ListWithSorting';
import Checkbox from '../../input/Checkbox';
import { IListFooterProps } from '../ListFooter';
import { stopPropagation } from '../../../../utils/browser/events/stopPropagation';

type ItemId = string | number;

interface IPublicProps<ColumnNames> {
    name: string;
    withSorting: boolean;
    columns: ListColumns<ColumnNames>;
    items: ListItem<ColumnNames>[];
    selectedItemIds: ItemId[];
    onItemSelected: (selectedIds: ItemId[]) => void;
    onItemRowClicked?: (id: ItemId) => void;
    selectOnItemRowClicked?: boolean;
    getCustomRowClasses?: (item: ListItem<ColumnNames>) => string;
    noResultsMessage?: ReactNode;
    errorMessage?: ReactNode;
    fadeOutListItems?: boolean;
    maxNrOfRecordsToShow?: number;
    footer?: ReactElement<IListFooterProps>;
    idToScrollIntoView?: ListItemId;
    maxSelectedItems?: number;
    onColumnSortChanged?: (nextSortedColumn: ISortedColumn<ColumnNames>) => void;

    // Only when withSorting = true
    initialSort?: ISortedColumn<ColumnNames>;
    sortableOnSelected?: boolean;
    customSorter?: TItemsSorter<ColumnNames>;

    // Only when withSorting = false
    sortedColumn?: ISortedColumn<ColumnNames>;

    customTableContentRenderer?: (listItems: ListItem<ColumnNames>[]) => React.ReactNode;
}

type ExtendedColumns<ColumnNames> = ListColumns<ColumnNames & { selectCheckbox: string }>;

interface IComponentState<ColumnNames> {
    columns: ExtendedColumns<ColumnNames>;
}

class ListWithCheckboxSelect<ColumnNames>
    extends PureComponent<IPublicProps<ColumnNames>, IComponentState<ColumnNames>> {

    constructor(props: IPublicProps<ColumnNames>) {
        super(props);

        this.getColumnsWithExtraSelectCheckboxColumn = this.getColumnsWithExtraSelectCheckboxColumn.bind(this);
        this.onCheckboxClicked = this.onCheckboxClicked.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
        this.onSelectOnItemRowClicked = this.onSelectOnItemRowClicked.bind(this);

        this.state = {
            columns: this.getColumnsWithExtraSelectCheckboxColumn(props),
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPublicProps<ColumnNames>) {
        if (nextProps.columns !== this.props.columns) {
            this.setState({
                columns: this.getColumnsWithExtraSelectCheckboxColumn(nextProps),
            });
        }
        // Remove selected items which are not in the items list anymore
        if (
            nextProps.selectedItemIds &&
            this.props.items !== nextProps.items
        ) {
            const itemsIds = nextProps.items.map((item) => item.id);
            const selectedItemIdsInList = nextProps.selectedItemIds
                .filter((selectedItemId) => itemsIds.includes(selectedItemId));

            if (selectedItemIdsInList.length !== nextProps.selectedItemIds.length) {
                nextProps.onItemSelected(selectedItemIdsInList);
            }
        }
    }

    private getColumnsWithExtraSelectCheckboxColumn(
        inputProps: IPublicProps<ColumnNames>,
    ): ExtendedColumns<ColumnNames> {
        return {
            selectCheckbox: {
                render: (item) => this.renderCheckbox(inputProps.name, item.id),
                sortable: this.props.sortableOnSelected,
                sortValue: (item) => this.props.selectedItemIds.includes(item.id) ? 2 : 1,
                sortType: SortType.Number,
            },
            ...(inputProps.columns as object),
        } as ExtendedColumns<ColumnNames>;
    }

    public render() {
        const {
            name, items,
            selectedItemIds, onColumnSortChanged,
            sortedColumn, withSorting, customSorter, noResultsMessage,
            errorMessage, fadeOutListItems, maxNrOfRecordsToShow,
            onItemRowClicked, footer, customTableContentRenderer,
            idToScrollIntoView, selectOnItemRowClicked,
        } = this.props;
        const { columns } = this.state;

        // If initial sort is not defined sort on the second column
        // This override is needed as we inject a column (selectCheckbox)
        // and we don't want this to be used for initial sorting
        const columnNames = Object.keys(columns);
        const initialSort = this.props.initialSort || (withSorting && columnNames.length > 1 && {
            name: columnNames[1],
            sortOrder: SortOrder.Ascending,
        } as ISortedColumn<ColumnNames> || null);

        return (
            <div className="ListWithCheckboxSelect">
                {withSorting ?
                    <ListWithSorting
                        name={name}
                        columns={columns}
                        initialSort={initialSort}
                        items={items}
                        onItemRowClicked={selectOnItemRowClicked ? this.onSelectOnItemRowClicked : onItemRowClicked}
                        selectedItemIds={selectedItemIds}
                        getCustomRowClasses={this.getCustomRowClasses}
                        getTableCellClasses={this.getTableCellClasses}
                        noResultsMessage={noResultsMessage}
                        errorMessage={errorMessage}
                        fadeOutListItems={fadeOutListItems}
                        maxNrOfRecordsToShow={maxNrOfRecordsToShow}
                        footer={footer}
                        idToScrollIntoView={idToScrollIntoView}
                        customSorter={customSorter}
                        onColumnSortChanged={onColumnSortChanged}
                        customTableContentRenderer={customTableContentRenderer}
                    /> :
                    <List
                        name={name}
                        columns={columns}
                        items={items}
                        sortedColumn={sortedColumn}
                        onItemRowClicked={selectOnItemRowClicked ? this.onSelectOnItemRowClicked : onItemRowClicked}
                        selectedItemIds={selectedItemIds}
                        onColumnSortChanged={onColumnSortChanged}
                        getCustomRowClasses={this.getCustomRowClasses}
                        getTableCellClasses={this.getTableCellClasses}
                        noResultsMessage={noResultsMessage}
                        errorMessage={errorMessage}
                        fadeOutListItems={fadeOutListItems}
                        maxNrOfRecordsToShow={maxNrOfRecordsToShow}
                        footer={footer}
                        idToScrollIntoView={idToScrollIntoView}
                        customTableContentRenderer={customTableContentRenderer}
                    />
                }
            </div>
        );
    }

    private onSelectOnItemRowClicked(itemId: ItemId) {
        const newSelectedItemIds = this.props.selectedItemIds.includes(itemId) ?
            removeIdFromListIfItWasThere(this.props.selectedItemIds, itemId) :
            addIdToListIfNotAlready(this.props.selectedItemIds, itemId);

        this.props.onItemSelected(newSelectedItemIds.sort());
    }

    private onCheckboxClicked(itemId: ItemId, event: ChangeEvent<HTMLInputElement>) {
        const isSelected = event.target.checked;
        const newSelectedItemIds = isSelected ?
            addIdToListIfNotAlready(this.props.selectedItemIds, itemId) :
            removeIdFromListIfItWasThere(this.props.selectedItemIds, itemId);

        this.props.onItemSelected(newSelectedItemIds.sort());
    }

    private renderCheckbox(listName: string, itemId: ItemId) {
        const { selectedItemIds, maxSelectedItems } = this.props;

        const isChecked = selectedItemIds.includes(itemId);
        const hideCheckbox = !isChecked && selectedItemIds.length >= maxSelectedItems;

        if (hideCheckbox) {
            return <span/>;
        }

        return (
            <Checkbox
                name={`list-select-multiple-${listName}-${itemId}`}
                checked={isChecked}
                onChange={(event) => this.onCheckboxClicked(itemId, event)}
                onDivClicked={stopPropagation}
            />
        );
    }

    private getTableCellClasses(columnKey: string) {
        const classes = classNames({
            selectCheckbox: columnKey === 'selectCheckbox',
        });
        return classes;
    }

    private getCustomRowClasses(listItem: ListItem<ColumnNames>) {
        const { getCustomRowClasses, selectedItemIds, maxSelectedItems } = this.props;

        const isChecked = selectedItemIds.includes(listItem.id);
        const disableRow = !isChecked && selectedItemIds.length >= maxSelectedItems;

        if (typeof getCustomRowClasses === 'function') {
            return classNames(getCustomRowClasses(listItem), {
                disabled: disableRow,
            });
        }

        return disableRow ? 'disabled' : '';
    }
}

export default ListWithCheckboxSelect;

function addIdToListIfNotAlready(ids: ItemId[], idToAdd: ItemId): ItemId[] {
    if (!ids.includes(idToAdd)) {
        return ids.concat([idToAdd]);
    }
    return ids;
}

function removeIdFromListIfItWasThere(ids: ItemId[], idToRemove: ItemId): ItemId[] {
    const index = ids.indexOf(idToRemove);
    if (index > -1) {
        ids.splice(index, 1);
    }
    return ids;
}
