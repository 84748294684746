import React from 'react';
import './related-courses.scss';
import { ICMSCourse } from '../../../../../models/documentCenter/courses';
import Translate from '../../../../common/Translate';
import CourseTile from '../CourseTile';

const CLASS_NAME = 'RelatedCourses';
const TRANSLATION_PREFIX = 'document_center.courses.overview.related_courses';
const MAX_ITEMS = 3;

interface IPublicProps {
    courses: ICMSCourse[];
    hidePE: boolean;
}

export function RelatedCourses(props: IPublicProps) {
    const { courses } = props;

    const coursesToShow = courses.slice(0, MAX_ITEMS);

    if (!coursesToShow.length) {
        return null;
    }

    return (
        <div className={CLASS_NAME}>
            <div className="container">
                <h2><Translate raw msg={`${TRANSLATION_PREFIX}.title`} /></h2>
                <div className={`${CLASS_NAME}__list`}>
                    {coursesToShow.map((course) => {
                        return (
                            <CourseTile
                                key={`related-course-${course.nodeId}`}
                                course={course}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
