import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../index';
import { FETCH_ALL_CONSTANTS } from './types';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.CONSTANTS;

export interface IReducerState {
    constants: IAsyncFetchField<{}>;
}

const initialState: IReducerState = {
    constants: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<{}, IReducerState, {}>({
        baseActionType: FETCH_ALL_CONSTANTS,
        fieldName: 'constants',
        reducerKey,
    }),
};

/**
 * The actual constants are not stored in the state
 * This reducer is used for triggering a re-render when the constants are fetched
 */
registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    resetStateOnCompanySelection: false,
    resetStateOnSeatSelection: false,
    resetStateOnLogout: false,
    resetStateOnLocaleSwitch: false, // are specifically fetched when switching locale
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
