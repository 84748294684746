import React from 'react';
import Translate from '../../common/Translate';
import ROUTE_KEYS from '../../../routeKeys';
import './unexpected-error.scss';
import illustration from '../../assets/img/illustrations/illu_500.img.svg';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import { connect } from '../..';
import { navigateTo } from '../../../redux/location/actions';
import reloadPage from '../../../utils/browser/reloadPage';

const CLASS_NAME = 'UnexpectedError';

export interface IUnexpectedErrorProps {
    isInitializationError?: boolean;
}

interface IPrivateProps {
    doLogout: () => void;
}

function UnexpectedError(props: IUnexpectedErrorProps & IPrivateProps) {
    const translationPrefix = props.isInitializationError ? 'error.initialization_failed' : 'error.unexpected';
    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__content`}>
                <div className="container">
                    <h1><Translate msg={`${translationPrefix}.title`} /></h1>
                    <p><Translate msg={`${translationPrefix}.description`} /></p>
                    {props.isInitializationError ? (
                        <div>
                            <p>
                                <a
                                    id="retry-link"
                                    onClick={() => reloadPage()}
                                >
                                    <Translate msg={`${translationPrefix}.retry`} />
                                </a>
                            </p>
                            <p>
                                <a
                                    id="initialization-error-navigate-to-login-link"
                                    onClick={props.doLogout}
                                >
                                    <Translate msg={`${translationPrefix}.back_to_login`} />
                                </a>
                            </p>
                        </div>
                    ) : (
                            <div>
                                <p>
                                    <LinkToRoute
                                        id="navigate-to-home-link"
                                        to={ROUTE_KEYS.R_HOME}
                                    >
                                        <Translate msg={`${translationPrefix}.home_link`} />
                                    </LinkToRoute>
                                </p>
                                <p>
                                    <a
                                        id="unexpected-error-navigate-to-login-link"
                                        onClick={props.doLogout}
                                    >
                                        <Translate msg={`${translationPrefix}.back_to_login`} />
                                    </a>
                                </p>
                            </div>
                        )}
                </div>
            </div>
            <figure className={`${CLASS_NAME}__figure`} style={{ backgroundImage: `url(${illustration})` }} />
        </div>
    );
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => ({
        doLogout: () => {
            dispatch(navigateTo(ROUTE_KEYS.R_LOGOUT, { isSessionExpired: false }));
        },
    }),
})(UnexpectedError);
