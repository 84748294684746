import React, { useMemo } from 'react';
import { path } from 'ramda';
import isSet from '@snipsonian/core/es/is/isSet';

import {
    dateToDayOfWeek,
    formatDateToDayMonth,
    formatDateForBackend,
} from '../../../../../../utils/formatting/formatDate';
import { DayParts } from '../../../../../../models/general/daypart';
import { getDate } from '../../../../../../utils/core/date/getSpecificDate';
import { IAgendaQueryParams } from '../../../../../../models/planning/agenda';
import { UpcomingActivityType, IUpcomingCompanyVisitActivity } from '../../../../../../models/planning/activities';
import Icon from '../../../../../common/icons/Icon';
import LinkToRoute from '../../../../../common/navigation/LinkToRoute';
import Translate from '../../../../../common/Translate';
import { AGENDA_ROUTE } from '../../AgendaDashboardBlock.const';

import { UpcomingActivityBlockTitle } from './UpcomingActivityBlockTitle/UpcomingActivityBlockTitle.component';
import { TUpcomingActivityBlockProps } from './UpcomingActivityBlock.type';

const UpcomingActivityBlock = ({ upcomingActivity }: TUpcomingActivityBlockProps) => {
    const dayOfWeek = useMemo(() => {
        return dateToDayOfWeek(getDate(upcomingActivity.date));
    }, [upcomingActivity.date]);
    const dayMonth = useMemo(() => {
        return formatDateToDayMonth(getDate(upcomingActivity.date));
    }, [upcomingActivity.date]);

    const showDayPart =
        upcomingActivity.type === UpcomingActivityType.CompanyVisit
        && isSet((upcomingActivity as IUpcomingCompanyVisitActivity).dayPart);

    return (
        <div className="appointment">
            {upcomingActivity.date && (
                <div className="date">
                    <span className="day">
                        <Translate msg={`app_shell.dates.days.${dayOfWeek}`}/>
                    </span>
                    {showDayPart && <DayPart upcomingActivity={upcomingActivity} />}
                    <span>{dayMonth}</span>
                </div>
            )}
            <div className="description">
                <LinkToRoute
                    id="agenda-appointment-link"
                    className="icon-link"
                    to={{
                        ...AGENDA_ROUTE,
                        meta: {
                            query: {
                                selectedDate: formatDateForBackend(upcomingActivity.date),
                            } as IAgendaQueryParams,
                            location: null,
                        },
                    }}
                >
                    <UpcomingActivityBlockTitle upcomingActivity={upcomingActivity} />
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </div>
        </div>
    );
};

const DayPart = ({ upcomingActivity }: TUpcomingActivityBlockProps) => {
    const dayPart = path<DayParts>(['dayPart'], upcomingActivity);

    if (!dayPart) {
        return null;
    }

    return (
        <span className="timing">
            <Translate
                msg={`common.calendar.${dayPart === DayParts.PM ? 'afternoon' : 'morning'}`}
            />
        </span>
    );
};

export { UpcomingActivityBlock };
