import { createSelector } from 'reselect';

import { AsyncStatus } from '../../../models/general/redux';
import { CompanyAvailabilityType, ICompanyAvailabilities } from '../../../models/admin/companyInfo';
import ROUTE_KEYS from '../../../routeKeys';
import {
    makeAsyncFetchInfoSelector,
    makeAsyncDoInfoSelector,
    getAsyncDoInfo,
    getReducerState,
    IState,
    NO_RERENDER,
} from '../../index';
import { getMyUserProfile, hasRequiredAccessLevels } from '../../auth/selectors';
import { getRouteKey, getRoutePayload } from '../../location/selectors';
import filterCompanySeats from '../selected/helpers/filterCompanySeats';

import { IReducerState, reducerKey } from './reducer';

export const NO_AVAILABILITIES: ICompanyAvailabilities = {
    morning: {
        startTime: null,
        endTime: null,
        days: NO_RERENDER.EMPTY_LIST,
    },
    afternoon: {
        startTime: null,
        endTime: null,
        days: NO_RERENDER.EMPTY_LIST,
    },
    available: true,
};

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Risks

const getRisksAsyncField = (state: IState) => reducerState(state).risks;

export const getRisks = (state: IState) => getRisksAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getRisksAsyncInfo = makeAsyncFetchInfoSelector(getRisksAsyncField);

export const areCompanyRisksAvailable = (state: IState) =>
    Array.isArray(getRisksAsyncField(state).data);

// Medical Centers

const getCompanyMedicalCentersAsyncField = (state: IState) => reducerState(state).companyMedicalCenters;

export const getCompanyMedicalCenters = (state: IState) =>
    getCompanyMedicalCentersAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getFetchCompanyMedicalCentersAsyncInfo = makeAsyncFetchInfoSelector(getCompanyMedicalCentersAsyncField);

export const areCompanyMedicalCentersAvailable = (state: IState) =>
    Array.isArray(getCompanyMedicalCentersAsyncField(state).data);

// Cost Centers

const getCompanyCostCentersAsyncField = (state: IState) => reducerState(state).companyCostCenters;

export const getCompanyCostCenters = (state: IState) =>
    getCompanyCostCentersAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getFetchCompanyCostCentersAsyncInfo = makeAsyncFetchInfoSelector(getCompanyCostCentersAsyncField);

export const areCompanyCostCentersAvailable = (state: IState) =>
    Array.isArray(getCompanyCostCentersAsyncField(state).data);

// Company addresses

const getCompanyAddressesAsyncField = (state: IState) => reducerState(state).companyAddresses;

export const getCompanyAddresses = (state: IState) =>
    getCompanyAddressesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getFetchCompanyAddressesAsyncInfo = makeAsyncFetchInfoSelector(getCompanyAddressesAsyncField);

export const areCompanyAddressesAvailable = (state: IState) =>
    Array.isArray(getCompanyAddressesAsyncField(state).data);

// Company detail

const getCompanyDetailAsyncField = (state: IState) => reducerState(state).companyDetail;

export const getCompanyDetailAsyncInfo = makeAsyncFetchInfoSelector(getCompanyDetailAsyncField);

export const getCompanyDetail = (state: IState) => getCompanyDetailAsyncField(state).data;

export const getCompanyDetailName = (state: IState) => {
    const detail = getCompanyDetail(state);
    return detail ? detail.name : '';
};

export const getCompanyStatisticalCode = (state: IState) => {
    const detail = getCompanyDetail(state);
    return detail ? detail.statisticalCode : undefined;
};

// Update company

const getUpdateCompanyAsyncField = (state: IState) =>
    reducerState(state).updateCompany;

export const getUpdateCompanyAsyncInfo = makeAsyncDoInfoSelector(getUpdateCompanyAsyncField);

// Update company single fields

const getUpdateCompanySingleFieldRequestsAsyncField = (state: IState) =>
    reducerState(state).updateCompanySingleFieldRequests;

// TODO use createSelector to make a memoization selector
export const getUpdateCompanySingleFieldAsyncInfo = (state: IState, requestId: string) => {
    const updateCompanySingleFieldRequestsAsyncField = getUpdateCompanySingleFieldRequestsAsyncField(state);
    const asyncDoField = updateCompanySingleFieldRequestsAsyncField[requestId];
    if (asyncDoField) {
        return getAsyncDoInfo(asyncDoField);
    }
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
};

// Add company seat

const getAddCompanySeatAsyncField = (state: IState) =>
    reducerState(state).addCompanySeat;

export const getAddCompanySeatAsyncInfo = makeAsyncDoInfoSelector(getAddCompanySeatAsyncField);

// Company info seat detail
export const getSelectedCompanyInfoSeatCompanyCode = (state: IState) => {
    const currentRouteKey = getRouteKey(state);
    if ([
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ].includes(currentRouteKey)) {
        return getRoutePayload<{ companyCode: string }>(state).companyCode;
    }

    return null;
};

export const getCompanyInfoSeatFromList = (state: IState, id: string) =>
    getCompanySeatsWithEmployeeCount(state)
        .find((item) => item.company.companyCode === id);

export const getSelectedCompanyInfoSeat = (state: IState) => {
    const id = getSelectedCompanyInfoSeatCompanyCode(state);

    if (id) {
        return getCompanyInfoSeatFromList(state, id);
    }

    return null;
};

const getSelectedCompanyInfoSeatDetailsAsyncField = (state: IState) =>
    reducerState(state).companySeatDetail;

export const getSelectedCompanyInfoSeatDetailsAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedCompanyInfoSeatDetailsAsyncField);

export const getSelectedCompanyInfoSeatDetails = (state: IState) => reducerState(state).companySeatDetail.data;

// Internal company contacts

export const areCompanyContactsAvailable = (state: IState) =>
    Array.isArray(getCompanyContactsAsyncField(state).data);

const getCompanyContactsAsyncField = (state: IState) =>
    reducerState(state).companyContacts;

export const getCompanyContactsAsyncInfo = makeAsyncFetchInfoSelector(getCompanyContactsAsyncField);

export const getCompanyContacts = (state: IState) =>
    getCompanyContactsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Internal company contacts (filtered by seat)

const getCompanySeatContactsAsyncField = (state: IState) =>
    reducerState(state).companySeatContacts;

export const areCompanySeatContactsAvailable = (state: IState) =>
    Array.isArray(getCompanySeatContactsAsyncField(state).data);

export const getCompanySeatContactsAsyncInfo = makeAsyncFetchInfoSelector(getCompanySeatContactsAsyncField);

export const getCompanySeatContacts = (state: IState) =>
    getCompanySeatContactsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Remove internal contact
const getRemoveContactAsyncField = (state: IState) =>
    reducerState(state).removeContact;

export const getRemoveContactAsyncInfo = makeAsyncDoInfoSelector(getRemoveContactAsyncField);

// Add internal contact
const getAddContactAsyncField = (state: IState) =>
    reducerState(state).addContact;

export const getAddContactAsyncInfo = makeAsyncDoInfoSelector(getAddContactAsyncField);

// Contact detail
export const getSelectedContactId = (state: IState) => {
    const currentRouteKey = getRouteKey(state);
    if ([
        ROUTE_KEYS.R_COMPANY_INFO_CONTACT_DETAIL,
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ].includes(currentRouteKey)) {
        return getRoutePayload<{ id: number }>(state).id;
    }

    return null;
};

export const getSelectedCompanySeatContact = (state: IState) => {
    const id = getSelectedContactId(state);

    if (id) {
        return getCompanySeatContacts(state)
            .find((item) => item.customerContactId === id) || null;
    }

    return null;
};

export const getSelectedContact = (state: IState) => {
    const id = getSelectedContactId(state);

    if (id) {
        return getCompanyContacts(state)
            .find((item) => item.customerContactId === id) || null;
    }

    return null;
};

// Update internal contact
const getUpdateContactAsyncField = (state: IState) =>
    reducerState(state).updateContact;

export const getUpdateContactAsyncInfo = makeAsyncDoInfoSelector(getUpdateContactAsyncField);

// Company availabilities

const getCompanyAvailabilitiesAsyncField = (state: IState, availabilityType: CompanyAvailabilityType) => {
    return (availabilityType === CompanyAvailabilityType.MedicalExaminations) ?
        reducerState(state).companyAvailabilitiesMedExams :
        reducerState(state).companyAvailabilitiesRiskMgmt;
};

const getCompanyMedExamAvailabilitiesAsyncField = (state: IState) => reducerState(state).companyAvailabilitiesMedExams;
const getCompanyRiskMgmtAvailabilitiesAsyncField = (state: IState) => reducerState(state).companyAvailabilitiesRiskMgmt;

export const getCompanyMedExamAvailabilitiesAsyncInfo =
    makeAsyncFetchInfoSelector(getCompanyMedExamAvailabilitiesAsyncField);

export const getCompanyRiskMgmtAvailabilitiesAsyncInfo =
    makeAsyncFetchInfoSelector(getCompanyRiskMgmtAvailabilitiesAsyncField);

export const getCompanyAvailabilities = (state: IState, availabilityType: CompanyAvailabilityType) =>
    getCompanyAvailabilitiesAsyncField(state, availabilityType).data || NO_AVAILABILITIES;

// Replace company availabilities

const getReplaceCompanyMedExamAvailabilitiesAsyncField = (state: IState) =>
    reducerState(state).companyAvailabilitiesMedExamsReplace;
const getReplaceCompanyRiskMgmtAvailabilitiesAsyncField = (state: IState) =>
    reducerState(state).companyAvailabilitiesRiskMgmtReplace;

export const getReplaceCompanyMedExamAvailabilitiesAsyncInfo =
    makeAsyncDoInfoSelector(getReplaceCompanyMedExamAvailabilitiesAsyncField);

export const getReplaceCompanyRiskMgmtAvailabilitiesAsyncInfo =
    makeAsyncDoInfoSelector(getReplaceCompanyRiskMgmtAvailabilitiesAsyncField);

// Company holidays

const getCompanyHolidaysAsyncField = (state: IState) =>
    reducerState(state).companyHolidays;

export const getCompanyHolidaysAsyncInfo = makeAsyncFetchInfoSelector(getCompanyHolidaysAsyncField);

export const getCompanyHolidays = (state: IState) =>
    getCompanyHolidaysAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

const getUpdateCompanyHolidaysAsyncField = (state: IState) =>
    reducerState(state).updateCompanyHolidays;

export const getUpdateCompanyHolidaysAsyncInfo = makeAsyncDoInfoSelector(getUpdateCompanyHolidaysAsyncField);

// Company rates

const getCompanyRatesAsyncField = (state: IState) =>
    reducerState(state).companyRates;

export const getFetchCompanyRatesAsyncInfo = makeAsyncFetchInfoSelector(getCompanyRatesAsyncField);

export const getCompanyRates = (state: IState) =>
    getCompanyRatesAsyncField(state).data;

// Company seats with employee count

const getCompanySeatsWithEmployeeCountAsyncField =
    (state: IState) => reducerState(state).companySeatsWithEmployeeCount;

export const getFetchCompanySeatsWithEmployeeCountAsyncInfo =
    makeAsyncFetchInfoSelector(getCompanySeatsWithEmployeeCountAsyncField);

export const areCompanySeatsWithEmployeeCountAvailable = (state: IState) => {
    const asyncInfo = getCompanySeatsWithEmployeeCountAsyncField(state);
    return asyncInfo.data !== null && !asyncInfo.isFetching;
};

export const getCompanySeatsWithEmployeeCount = (state: IState) => {
    return getCompanySeatsWithEmployeeCountAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
};

export const getFilteredCompanySeatsWithEmployeeCount = createSelector(
    getCompanySeatsWithEmployeeCount,
    data => filterCompanySeats(data),
);

export const mayUserManageCompanyInternalContacts = (state: IState) =>
    hasRequiredAccessLevels(state, { company: 'W' }) && getMyUserProfile(state).admin !== 2;
