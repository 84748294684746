import React, { PureComponent } from 'react';
import './contact-detail.scss';
import Form, { IFormRenderProps } from '../../common/forms/Form';
import { schema, fields } from './contactDetailSchema';
import Loader from '../../common/waiting/Loader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../models/general/redux';
import TextInput from '../../common/input/TextInput';
import { connect } from '../..';
import FormFieldError from '../../common/forms/FormFieldError';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { ITranslator } from '../../../models/general/i18n';
import SubmitButton from '../../common/buttons/SubmitButton';
import Translate from '../../common/Translate';
import Tile from '../../common/widget/Tile';
import { FEEDBACK_ITEMS } from '../../../config/navigation/contact.config';
import PageHeader from '../../appShell/PageHeader';
import { ISendCompanyFeedbackPayload, FeedbackType } from '../../../models/contact/feedback';
// import Alert from '../../common/widget/Alert';
import FormError from '../../common/forms/FormError';
import { getSendCompanyFeedbackAsyncInfo } from '../../../redux/contact/selectors';
import { sendFeedbackActions } from '../../../redux/contact/actions';
import classNames from 'classnames';
import SlideOutPanel from '../../common/widget/SlideOutPanel';
import { getRouteKey } from '../../../redux/location/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import { navigateTo } from '../../../redux/location/actions';
import Button from '../../common/buttons/Button';
import successIllu from '../../assets/img/icons/thumb-up.svg';
import InlineSVG from '../../common/icons/InlineSVG';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const FORM_NAME = 'contact-detail-form';
const CLASS_NAME = 'ContactDetailForm';

interface IFormValues {
    feedback: string;
    type: FeedbackType;
}

const INITIAL_CONTACT_DETAIL_VALUES: IFormValues = {
    feedback: undefined,
    type: undefined,
};

interface IPrivateProps {
    translator: ITranslator;
    sendCompanyFeedback: (values: IFormValues) => void;
    sendCompanyFeedbackAsyncInfo: IAsyncFieldInfo;
    isDetailPanelOpen: boolean;
    closeDetailPanel: () => void;
}

interface IState {
    submittedType: FeedbackType;
}

export class ContactDetail extends PureComponent<IPrivateProps, IState>{
    private resetForm: (values: IFormValues) => void;
    constructor(props) {
        super(props);

        this.state = {
            submittedType: undefined,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.renderPageHeader = this.renderPageHeader.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.resetForm &&
            prevProps.sendCompanyFeedbackAsyncInfo.status === AsyncStatus.Busy &&
            this.props.sendCompanyFeedbackAsyncInfo.status === AsyncStatus.Success
        ) {
            this.resetForm(INITIAL_CONTACT_DETAIL_VALUES);
        }
    }

    public render() {
        const { translator, sendCompanyFeedbackAsyncInfo, isDetailPanelOpen, closeDetailPanel } = this.props;
        const { submittedType } = this.state;

        const feedbackTranslationKey = 'contact.overview.detail.form.feedback';

        const sendFeedbackSuccess = sendCompanyFeedbackAsyncInfo &&
            sendCompanyFeedbackAsyncInfo.status === AsyncStatus.Success;

        return (
            <SlideOutPanel
                isOpen={isDetailPanelOpen}
                onCloseIntent={closeDetailPanel}
            >
                <div className={CLASS_NAME}>
                    {sendCompanyFeedbackAsyncInfo.status === AsyncStatus.Success ? (
                        <div className={`${CLASS_NAME}__success`}>
                            <InlineSVG svg={successIllu} />
                            <h1>
                                <Translate
                                    msg={`contact.overview.detail.form.success.${submittedType}`}
                                />
                            </h1>
                            <Button
                                id="contact-form-close"
                                typeName="secondary"
                                onClick={closeDetailPanel}
                            >
                                <Translate msg="contact.overview.detail.form.close" />
                            </Button>
                        </div>
                    ) : (
                        <>
                            {this.renderPageHeader()}
                            <Form
                                name={FORM_NAME}
                                handleSubmit={this.onSubmit}
                                initialValues={INITIAL_CONTACT_DETAIL_VALUES}
                                schema={schema}
                                render={(formRenderProps: IFormRenderProps<IFormValues>) => {

                                    const {
                                        touched, errors, values, handleChange, setFieldValue, resetForm,
                                    } = formRenderProps;

                                    if (this.resetForm !== resetForm) {
                                        this.resetForm = resetForm;
                                    }

                                    const feedbackStyles = classNames(`${CLASS_NAME}__feedback`, {
                                        show: !!values.type,
                                    });

                                    return (
                                        <Loader
                                            show={sendCompanyFeedbackAsyncInfo && sendCompanyFeedbackAsyncInfo.status}
                                        >
                                            <div className={`${CLASS_NAME}__tiles`}>
                                                {FEEDBACK_ITEMS
                                                    .map((feedbackItem) => (
                                                        <div className="tile-wrapper" key={feedbackItem.id}>
                                                            <Tile
                                                                description={feedbackItem.descriptionTranslationKey}
                                                                lazyLoadSvg={{
                                                                    id: feedbackItem.lazyLoadSvgId,
                                                                    group: SVG_GROUP_NAME.CONTACT,
                                                                }}
                                                                className={classNames({
                                                                    active: values.type === feedbackItem.feedbackType,
                                                                })}
                                                                onClick={sendFeedbackSuccess ?
                                                                    // eslint-disable-next-line max-len
                                                                    undefined : () => setFieldValue('type', feedbackItem.feedbackType)
                                                                }
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className={feedbackStyles}>
                                                <TextInput
                                                    id="contact-form-remark"
                                                    name={fields.feedback}
                                                    value={values.feedback}
                                                    onChange={handleChange}
                                                    isInvalid={touched.feedback && !!errors.feedback}
                                                    multiLine={true}
                                                    rows={6}
                                                    placeholder={translator(feedbackTranslationKey)}
                                                    disableAutoComplete={true}
                                                    disableMultilineResize={true}
                                                    disabled={sendFeedbackSuccess}
                                                />
                                                {formRenderProps.touched.feedback &&
                                                    <FormFieldError
                                                        error={formRenderProps.errors.feedback}
                                                        placeholders={{
                                                            fieldName: translator(feedbackTranslationKey),
                                                        }}
                                                    />}
                                                <div className={`${CLASS_NAME}__submit`}>
                                                    <SubmitButton
                                                        id="submit-medical-examination"
                                                        formName={FORM_NAME}
                                                        disabled={sendFeedbackSuccess || !!errors.feedback}
                                                    >
                                                        <Translate
                                                            msg={'contact.overview.detail.form.submit'}
                                                        />
                                                    </SubmitButton>
                                                </div>
                                            </div>
                                        </Loader>
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            </SlideOutPanel>
        );
    }

    private onSubmit(values: IFormValues) {
        this.setState({ submittedType: values.type });
        this.props.sendCompanyFeedback(values);
    }

    private renderPageHeader() {
        const { translator, sendCompanyFeedbackAsyncInfo } = this.props;
        return (
            <>
                <PageHeader
                    title={translator('contact.overview.detail.title')}
                />
                {sendCompanyFeedbackAsyncInfo.error &&
                    <FormError error={sendCompanyFeedbackAsyncInfo.error} />}
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
        sendCompanyFeedbackAsyncInfo: getSendCompanyFeedbackAsyncInfo(state),
        isDetailPanelOpen: getRouteKey(state) === ROUTE_KEYS.R_CONTACT_DETAIL,
    }),
    dispatchProps: (dispatch) => ({
        sendCompanyFeedback: (payload: ISendCompanyFeedbackPayload) => {
            dispatch(sendFeedbackActions.trigger(payload));
        },
        closeDetailPanel: () => {
            dispatch(navigateTo(ROUTE_KEYS.R_CONTACT));
        },
    }),
})(ContactDetail);
