import React, { PureComponent } from 'react';
import './policy-advice.scss';
import { clone } from 'ramda';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
    IRenderDetailFooterProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
} from '../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import Translate from '../../common/Translate';
import ListWithSorting from '../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import {
    formatDateForDisplay,
    formatDateForBackend,
} from '../../../utils/formatting/formatDate';
import policyAdviceIllu from '../../../views/assets/img/illustrations/svg_bedrijfsbezoek.svg';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { navigateTo } from '../../../redux/location/actions';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import PolicyAdvicePage from './PolicyAdvicePage';
import { IPolicyAdvice, IPolicyAdviceComment } from '../../../models/interventions/policyAdvice';
import {
    getPolicyAdviceAsyncInfo,
    getPolicyAdvice,
    getPolicyAdviceComments,
} from '../../../redux/intervention/policyAdvice/selectors';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import Icon from '../../common/icons/Icon';
import { PolicyAdvisePriorityId, PolicyAdviceStatus } from '../../../config/intervention.config';
import PriorityIndicator, {
    POLICY_ADVISE_PRIORITY_ID_TO_STATUS_INDICATOR_VALUE,
} from '../../common/widget/PriorityIndicator';
import { DetailHeader } from './Detail/detailHeader';
import { DetailContent } from './Detail/detailContent';
import { DetailFooter } from './Detail/detailFooter';
import { stopPropagation } from '../../../utils/browser/events/stopPropagation';
import { ITranslator } from '../../../models/general/i18n';
import { DEFAULT_POLICY_ADVICE_FILTERS } from '../../../api/interventions/policyAdvice.api';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { connect } from '../..';
import { getSeatsAndDivisionsForSelectedCompany } from '../../../redux/company/selected/selectors';
import SeatTypeahead from '../../administration/Employees/shared/SeatTypeahead';
import
    getUniqueTypeaheadFilterValuesFromListItems
from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';

const BASE_NAME = 'policy-advice';
const CLASS_NAME = 'PolicyAdvice';
const CLASS_NAME_SLIDE_OUT_PANEL = `${CLASS_NAME}__slide-out-panel`;
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const TRANSLATION_PREFIX = 'interventions.policy_advice';

interface IColumnNames {
    feedback: string;
    description: string;
    dateCreatedUnformatted: string;
    checklistComment: string;
    priority: string;
    priorityLabel: string;
    riskAnalysisPriorityThreat: string;
    seat: string;
    companyVisitDate: string;
    companyVisitDateSort: string;
    status: string;
    statusId: string;
}

export interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    seat: string;
    companyVisit: string;
    priority: string;
    status: string;
}

interface IFilterPrivateProps {
    showSeatsFilter: boolean;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'priority',
    sortOrder: SortOrder.Ascending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    feedback: {
        sortable: false,
        render: renderFeedbackIcon,
        percentWidth: null,
        minWidth: 36,
    },
    description: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.description`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    dateCreatedUnformatted: {
        hide: true,
        percentWidth: null,
    },
    checklistComment: {
        hide: true,
        percentWidth: null,
        includeInSearchEvenWhenHidden: true,
    },
    priority: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.priority`} />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 17,
    },
    priorityLabel: {
        percentWidth: null,
        hide: true,
        includeInSearchEvenWhenHidden: true,
    },
    riskAnalysisPriorityThreat: {
        percentWidth: null,
        hide: true,
        includeInSearchEvenWhenHidden: true,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    companyVisitDate: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company_visit`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 18,
    },
    companyVisitDateSort: {
        hide: true,
        percentWidth: null,
    },
    status: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.status`} />,
        sortable: false,
        percentWidth: 10,
    },
    statusId: {
        hide: true,
        percentWidth: null,
    },
};

type TPolicyAdviceListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

export default function PolicyAdvice() {
    return (
        <PolicyAdvicePage
            titleTranslationKey={`${TRANSLATION_PREFIX}.title`}
            textTranslationKey={`${TRANSLATION_PREFIX}.text`}
            headerSvg={policyAdviceIllu}
        >
            <MasterWithDetail
                baseName={BASE_NAME}
                className={CLASS_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_POLICY_ADVICE,
                    asyncInfoSelector: getPolicyAdviceAsyncInfo,
                    dataSelector: getPolicyAdvice,
                    transformData: mapPolicyAdviceToListItems,
                    renderContent: (renderProps: TPolicyAdviceListProps) =>
                        <PolicyAdviceList {...renderProps} />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                    clientSideFilterOfListData,
                    transformFilterValuesToActiveFilters,
                    filterValidationSchema: startEndDateSchema,
                }}
                detailConfig={{
                    routeKey: ROUTE_KEYS.R_POLICY_ADVICE_DETAIL,
                    asyncInfoSelector: getPolicyAdviceAsyncInfo,
                    idRouteParamName: 'id',
                    dataSelector: getPolicyAdviceComments,
                    slideOutPanelClassName: CLASS_NAME_SLIDE_OUT_PANEL,
                    renderHeader: (renderProps: IRenderDetailHeaderProps<IPolicyAdviceComment[]>) =>
                        <DetailHeader {...renderProps} />,
                    renderContent: (renderProps: IRenderDetailContentProps<IPolicyAdviceComment[]>) =>
                        <DetailContent {...renderProps} />,
                    renderFooter: (renderProps: IRenderDetailFooterProps<IPolicyAdviceComment[]>) =>
                        <DetailFooter {...renderProps} />,
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    exportButton: {
                        baseFilename: 'policy-advice',
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        </PolicyAdvicePage>
    );
}

function mapPolicyAdviceToListItems(masterData: IPolicyAdvice[], translator: ITranslator): ListItem<IColumnNames>[] {
    return masterData
        .map((policyAdvice) => {
            const statusForTranslation = policyAdvice.resolved === PolicyAdviceStatus.Resolved ? 'resolved' : 'open';
            const statusTranslationKey = `${TRANSLATION_PREFIX}.status.${statusForTranslation}`;
            const companyVisitAbbreviation = translator(`${TRANSLATION_PREFIX}.company_visit_abbreviation`);
            const priorityTranslated = // eslint-disable-next-line max-len
                translator(POLICY_ADVISE_PRIORITY_ID_TO_STATUS_INDICATOR_VALUE[policyAdvice.riskAnalysisPriorityId].translationKey);
            return {
                id: policyAdvice.id,
                columns: {
                    feedback: policyAdvice.feedbackHistory,
                    description: policyAdvice.gPPThreat,
                    companyVisitDate:
                        `${companyVisitAbbreviation} ${formatDateForDisplay(policyAdvice.companyVisitDate)}`,
                    companyVisitDateSort: policyAdvice.companyVisitDate,
                    priority: policyAdvice.riskAnalysisPriorityId,
                    priorityLabel: priorityTranslated,
                    seat: policyAdvice.company && policyAdvice.company.name,
                    status: translator(statusTranslationKey),
                    statusId: policyAdvice.resolved,
                    checklistComment: policyAdvice.checklistComment,
                    dateCreatedUnformatted: policyAdvice.dateCreated,
                    riskAnalysisPriorityThreat: policyAdvice.riskAnalysisPriorityThreat,
                },
            } as ListItem<IColumnNames>;
        });
}

class PolicyAdviceList extends PureComponent<TPolicyAdviceListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TPolicyAdviceListProps) {
        super(props);

        this.renderInterventionDate = this.renderInterventionDate.bind(this);
        this.renderPriorityIndicator = this.renderPriorityIndicator.bind(this);

        this.columns.companyVisitDate.render = this.renderInterventionDate;
        this.columns.priority.render = this.renderPriorityIndicator;
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
            onItemSelected,
        } = this.props;

        const highRiskItems = clientSideFilteredlistItems.filter(policyAdviceItem => (
            policyAdviceItem.columns.riskAnalysisPriorityThreat === '1'
        ));

        const allOtherItems = clientSideFilteredlistItems.filter(policyAdviceItem => (
            policyAdviceItem.columns.riskAnalysisPriorityThreat !== '1'
        ));

        return (
            <>
                <p className={`${CLASS_NAME}__pre-list`}>
                    <Translate raw msg={`${TRANSLATION_PREFIX}.pre_table`} />
                </p>
                <h4 className={`${CLASS_NAME}__table--description`}>
                    <TooltipWithIcon
                        label={
                            <Translate msg={`${TRANSLATION_PREFIX}.table_description.high_priorities.tooltip_label`} />
                        }
                    >
                        <div>
                            <Translate msg={`${TRANSLATION_PREFIX}.table_description.high_priorities.tooltip_inner`} />
                        </div>
                    </TooltipWithIcon>
                </h4>
                <ListWithSorting
                    columns={this.columns}
                    items={highRiskItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    maxNrOfRecordsToShow={undefined}
                    initialSort={INITIAL_SORT}
                    getCustomRowClasses={this.getCustomRowClasses}
                />
                <h4 className={`${CLASS_NAME}__table--description`}>
                    <Translate msg={`${TRANSLATION_PREFIX}.table_description.priorities`} />
                </h4>
                <ListWithSorting
                    columns={this.columns}
                    items={allOtherItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    initialSort={INITIAL_SORT}
                    getCustomRowClasses={this.getCustomRowClasses}
                />
            </>
        );
    }

    private getCustomRowClasses(listItem: ListItem<IColumnNames>) {
        if (listItem.columns.statusId === PolicyAdviceStatus.Resolved) {
            return 'resolved';
        }
        return '';
    }

    private renderInterventionDate(listItem: ListItem<IColumnNames>, index: number) {
        const visitDate = formatDateForBackend(listItem.columns.companyVisitDateSort as string);
        const query: IStartEndDateFilterValues = {
            startDate: visitDate,
            endDate: visitDate,
        };
        return (
            <LinkToRoute
                id={`company-visit-link-${index}`}
                key={`company-visit-link-${index}`}
                onClick={stopPropagation}
                to={navigateTo(ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED, {}, query)}
            >
                {listItem.columns.companyVisitDate}
            </LinkToRoute>
        );
    }

    private renderPriorityIndicator(listItem: ListItem<IColumnNames>, index: number) {
        const value = listItem.columns.priority as PolicyAdvisePriorityId;
        return (
            <div key={`company-visit-priority-${index}`}>
                <PriorityIndicator value={value} />
            </div>
        );
    }
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seat)) {
                return true;
            }

            const seat = listItem.columns.seat as string;

            return filterValues.seat.includes(seat);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.companyVisit)) {
                return true;
            }

            const companyVisitDate = listItem.columns.companyVisitDate as string;

            return filterValues.companyVisit.includes(companyVisitDate);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.priority)) {
                return true;
            }

            const priority = listItem.columns.priority.toString() as string;

            return filterValues.priority.includes(priority);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.status)) {
                return true;
            }

            const status = listItem.columns.statusId.toString() as string;

            return filterValues.status.includes(status);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.startDate)) {
                return true;
            }

            return listItem.columns.dateCreatedUnformatted >= filterValues.startDate;
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.endDate)) {
                return true;
            }

            return listItem.columns.dateCreatedUnformatted <= filterValues.endDate;
        });
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContentComp(
    props: IFilterPrivateProps & IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        formRenderProps,
        showSeatsFilter,
        translator,
        masterData: allListItems,
    } = props;

    const possibleCompanyVisits = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'companyVisitDate',
        'companyVisitDate',
    );

    const possiblePriorities = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'priority',
        'priorityLabel',
    );

    const possibleStatusses = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'statusId',
        'status',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="priority"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.priority`}
                possibleFilterItems={possiblePriorities}
                actualFilterValue={formRenderProps.values.priority}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'priority',
                    newFilterValue,
                )}
            />
            {showSeatsFilter &&
                <FloatableTextInputWrapper floatLabel>
                    <SeatTypeahead
                        id="policy-advice-filter-seat"
                        name="seatFilter"
                        placeholder={translator(`${TRANSLATION_PREFIX}.filter.seat`)}
                        value={formRenderProps.values.seat || ''}
                        initialFilter={formRenderProps.values.seat || ''}
                        onItemSelected={(companyCode, name) =>
                            formRenderProps.setFieldValue('seat', name)}
                        onFilter={(filter) =>
                            formRenderProps.setFieldValue('seat', filter)}
                        isFreeText={true}
                    >
                        <label htmlFor="policy-advice-filter-seat">
                            <Translate msg={`${TRANSLATION_PREFIX}.filter.seat`} />
                        </label>
                    </SeatTypeahead>
                </FloatableTextInputWrapper>
            }
            <CheckboxesOrTypeaheadFilter
                filterName="companyVisit"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.company_visit`}
                possibleFilterItems={possibleCompanyVisits}
                actualFilterValue={formRenderProps.values.companyVisit}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'companyVisit',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="status"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.status`}
                possibleFilterItems={possibleStatusses}
                actualFilterValue={formRenderProps.values.status}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'status',
                    newFilterValue,
                )}
            />
        </div>
    );
}

const FilterContent = connect<IFilterPrivateProps, IRenderFilterContentProps<{}, IFilterValues>>({
    stateProps: (state) => {
        return {
            showSeatsFilter: getSeatsAndDivisionsForSelectedCompany(state).length > 1,
        };
    },
})(FilterContentComp);

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_POLICY_ADVICE_FILTERS,
        isShowAll,
    } : DEFAULT_POLICY_ADVICE_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_POLICY_ADVICE_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_POLICY_ADVICE_FILTERS.startDate,
            },
            seat: {
                show: true,
            },
            companyVisit: {
                show: true,
                translationKeySuffixOverride: 'company_visit',
                multiple: {
                    enable: true,
                },
            },
            priority: {
                show: true,
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'priorityLabel',
                        searchColumnName: 'priority',
                    },
                },
            },
            status: {
                show: true,
                defaultValue: DEFAULT_POLICY_ADVICE_FILTERS.status,
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'status',
                        searchColumnName: 'statusId',
                    },
                },
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: allOtherItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && allOtherItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

function renderFeedbackIcon(listItem: ListItem<IColumnNames>) {
    if (!listItem.columns.feedback) {
        return <span />;
    }

    return (
        <Icon className={`${CLASS_NAME}__feedback-icon`} typeName="conversation" />
    );
}
