import { pathOr } from 'ramda';

import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import { createServerUnavailableError } from '../../../../utils/api/error/createServerUnavailableError';
import {
    DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../../api/interventions/medicalExaminations/reserved/medicalExaminationsReserved.const';
import { IAction } from '../../../../models/general/redux';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import {
    IFetchReservedMedicalExaminationsApiPayload,
    IFetchReservedMedicalExaminationsPayload,
} from '../../../../models/interventions/medicalExaminations/reserved';
import Api from '../../../../api';
import HTTP_STATUS from '../../../../utils/api/httpStatus';
import ROUTE_KEYS from '../../../../routeKeys';
import { createEpic, IState } from '../../../index';
import { getLocale } from '../../../i18n/selectors';
import { getLocationState, getQueryParams } from '../../../location/selectors';
import {
    getSelectedSeatCompanyCode,
    isAllSeatsSelected,
    isCompanyAnInterimCompany,
} from '../../../company/selected/selectors';
import { redirectToRoute } from '../../../location/actions';
import { FETCH_RESERVED_MEDICAL_EXAMINATIONS } from '../../types';
import {
    fetchReservedMedicalExaminationsSucceeded,
    fetchReservedMedicalExaminationsFailed,
} from '../../actions';
import { getReservedMedicalExaminations } from '../../selectors';

createEpic<IFetchReservedMedicalExaminationsApiPayload>({
    onActionType: [
        FETCH_RESERVED_MEDICAL_EXAMINATIONS,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES,
    ],
    transform: ({ action, getState }, { next }) => {
        if (action.type === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES) {
            if (isCompanyAnInterimCompany(getState())) {
                return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON, {}));
            }
        }

        return next(action);
    },
    async processMultiple({ action, getState, api }, dispatch, done) {
        dispatch(await asyncFetchReservedMedicalExaminations({ api, action, getState }));

        return done();
    },
    latest: false,
});

createEpic<IFetchReservedMedicalExaminationsPayload>({
    onActionType: [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES],
    refreshDataIf: ({ getState, action }) => {
        const state = getState() as IState;
        const reservedExaminations = getReservedMedicalExaminations(state);

        if (!reservedExaminations || reservedExaminations.length === 0) {
            return true;
        }

        const {
            type: prevRouteKey,
            query: prevFilter,
        } = getLocationState(state);

        if (prevRouteKey === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL) {
            /* No refresh if we navigate from details back to the overview */
            return false;
        }

        if (prevRouteKey === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES) {
            /* No refresh if we stay on overview and only client side filtering changed */

            const prevFilterWithDefaults = {
                ...DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
                ...prevFilter,
            };

            const queryWithDefaults = {
                ...DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
                ...action.meta.query,
            };

            return !areObjectParamsEqual(prevFilterWithDefaults, queryWithDefaults, ['startDate', 'endDate']);
        }

        return true;
    },
    processReturn: asyncFetchReservedMedicalExaminations,
    latest: true,
});

export async function asyncFetchReservedMedicalExaminations({
    api,
    action,
    getState,
}: {
    api: typeof Api;
    action: IAction<IFetchReservedMedicalExaminationsPayload>;
    getState: () => IState;
}) {
    try {
        const state = getState();
        const {
            companyCode: filterCompanyCode,
            startDate: filterStartDate,
            endDate: filterEndDate,
        } = action.payload;


        const companyCode = filterCompanyCode || getSelectedSeatCompanyCode(state);
        const showFullFamily = pathOr(isAllSeatsSelected(state), ['payload', 'showFullFamily'], action);
        const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;
        const locale = getLocale(state);

        const startDate = filterStartDate || filterFromQuery.startDate;
        const endDate = filterEndDate || filterFromQuery.endDate;

        const bufferzones = await await api.interventions.medicalExaminations.fetchReserved({
            companyCode,
            showFullFamily,
            locale,
            // Only pass start date & end date when they were set
            // When not set, the default date range will be used in the api method
            ...!!startDate && { startDate },
            ...!!endDate && { endDate },
        });

        return fetchReservedMedicalExaminationsSucceeded(bufferzones);
    } catch (error) {
        const { code = '' } = error;

        if (code === HTTP_STATUS.INTERNAL_SERVER_ERROR || code === HTTP_STATUS.REQUEST_TIMEOUT) {
            return fetchReservedMedicalExaminationsFailed(createServerUnavailableError());
        }

        return fetchReservedMedicalExaminationsFailed(error);
    }
}
