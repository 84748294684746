import { Middleware } from 'redux';
import { reportError } from '../../utils/logging/errorReporter';
import {
    TMiddlewareStoreParam,
    TMiddlewareNextParam,
} from '../../utils/libs/redux/epic/typings';
import { IAction } from '../../models/general/redux';
import { IState } from '../IState';

type TAction = IAction<{}>;

export default function createErrorLoggerMiddleware() {
    const middleware = (store: TMiddlewareStoreParam<TAction, IState>) =>
        (next: TMiddlewareNextParam<TAction>) =>
            (action: TAction) => {
                try {
                    return next(action);
                } catch (error) {
                    // An error occured in dispatching an action
                    const { type } = action;
                    reportError(error, {
                        extra: {
                            actionType: type,
                            // we don't log action.payload to avoid logging confidential data to a 3th party
                        },
                    });
                    // console.error(`Redux middleware error: ${error.message}`)
                    throw error;
                }
            };

    return middleware as Middleware;
}
