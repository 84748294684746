import React from 'react';
import FloatableTextInputWrapper from '../../forms/FloatableTextInputWrapper';
import Translate from '../../Translate';
import DatePicker from '../../widget/DateTimePicker/DatePicker';
import { IFormRenderProps } from '../../forms/Form';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import FormFieldError from '../../forms/FormFieldError';
import { ErrorTypes, IValidationError } from '../../../../models/general/error';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';

interface IStartEndDateFilterProps {
    formRenderProps: IFormRenderProps<IStartEndDateFilterValues>;
    translationKeyPrefix: string;
    hideTitle?: boolean;
    minDate?: string;
    maxDate?: string;
}

export default function StartEndDateFilter(props: IStartEndDateFilterProps) {

    const {
        formRenderProps,
        translationKeyPrefix,
        hideTitle,
        minDate,
        maxDate,
    } = props;

    const { values, errors, setFieldValue, touched } = formRenderProps;

    const minDateError = !passedMinDateValidation(values, minDate);
    const maxDateError = !passedMaxDateValidation(values, maxDate);

    /* We modify the original errors object to prevent the form from submitting */
    if (minDateError) {
        errors.startDate = {
            type: ErrorTypes.MinDate,
        } as IValidationError;
    }

    if (maxDateError) {
        errors.endDate = {
            type: ErrorTypes.MaxDate,
        } as IValidationError;
    }

    const hasInvalidPeriodError = errors.endDate && errors.endDate.type === ErrorTypes.InvalidPeriod;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <>
                    {!hideTitle && <h6><Translate msg={`${translationKeyPrefix}.period`} /></h6>}
                    <div className="FilterAndSearchPanel__dates">
                        <FloatableTextInputWrapper inline>
                            <label htmlFor="filter-start-date">
                                <Translate msg={`${translationKeyPrefix}.period_from`} />
                            </label>
                            <DatePicker
                                id="filter-start-date"
                                value={values.startDate}
                                onChange={(formattedDate) => setFieldValue('startDate', formattedDate)}
                                inlineCalendar={false}
                                minDate={minDate}
                                isInvalid={touched.startDate &&
                                    (!!errors.startDate || !!hasInvalidPeriodError)}
                            />
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper inline>
                            <label htmlFor="filter-end-date">
                                <Translate msg={`${translationKeyPrefix}.period_to`} />
                            </label>
                            <DatePicker
                                id="filter-end-date"
                                value={values.endDate}
                                onChange={(formattedDate) => setFieldValue('endDate', formattedDate)}
                                inlineCalendar={false}
                                maxDate={maxDate}
                                isInvalid={touched.endDate &&
                                    (!!errors.endDate || !!hasInvalidPeriodError)}
                            />
                        </FloatableTextInputWrapper>
                        {hasInvalidPeriodError ?
                            <FormFieldError
                                error={touched.endDate && errors.endDate}
                            /> : (
                                <>
                                    <FormFieldError
                                        error={touched.startDate && errors.startDate}
                                        placeholders={{
                                            fieldName: translator(`${translationKeyPrefix}.period_from`),
                                            minValue: minDate && formatDateForDisplay(minDate),
                                        }}
                                    />
                                    <FormFieldError
                                        error={touched.endDate && errors.endDate}
                                        placeholders={{
                                            fieldName: translator(`${translationKeyPrefix}.period_to`),
                                            maxValue: maxDate && formatDateForDisplay(maxDate),
                                        }}
                                    />
                                </>
                            )
                        }
                    </div>
                </>
            )}
        </TranslatorContext.Consumer>
    );
}

function passedMinDateValidation(values: IStartEndDateFilterValues, minDate: string) {
    if (!minDate) {
        return true;
    }
    return values.startDate >= minDate;
}

function passedMaxDateValidation(values: IStartEndDateFilterValues, maxDate: string) {
    if (!maxDate) {
        return true;
    }
    return values.endDate <= maxDate;
}
