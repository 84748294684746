import { pathOr } from 'ramda';

import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import {
    composeReservedMedicalExaminationId,
} from '../../../../utils/interventions/medicalExaminations/reservedMedicalExaminationId.helper';
import { get, IRequestWrapperPromise } from '../../../../utils/api/requestWrapper';
import { IBufferzoneTimeslots } from '../../../../models/interventions/timeslots';
import {
    IFetchReservedMedicalExaminationApiPayload,
    IFetchReservedMedicalExaminationsApiPayload,
    IReservedMedicalExamination,
    IReservedMedicalExaminationWithoutId,
} from '../../../../models/interventions/medicalExaminations/reserved';
import { ILocationTypeCodes } from '../../../../models/general/location';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../../config/planning.config';
import { mapBffMedicalExaminationToBufferzonePlannedTimeSlot } from '../../../../redux/medicalExamination/mappers';
import { ONE_SECOND } from '../../../../utils/core/time/periodsInMillis';
import { COMPANY_MEDICAL_EXAMINATIONS_URL } from '../medicalExaminations.const';

import { DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS } from './medicalExaminationsReserved.const';

export function fetchReserved({
    companyCode,
    showFullFamily,
    startDate = DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
    endDate = DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    locale,
}: IFetchReservedMedicalExaminationsApiPayload) {
    return get<IReservedMedicalExamination[]>({
        ...bffDefaultApiConfig({ locale }),
        url: COMPANY_MEDICAL_EXAMINATIONS_URL.RESERVED,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            /**
             * When getting the list of bufferzones, we should always do this FullFamily,
             * because we also always get the timecells fullfamily (default setting in back-end).
             * Otherwise the timecells call would return more cells than those that are
             * within the bufferzone (when the bufferzone was fetched for a single seat).
             * But we can not by default pass always 'true' from the front-end because that would
             * result in authorisation errors from the back-end.
             * So also here the back-end will by default pass 'true' to the mensura backend.
             * See KZUAT-1367.
             */
            showFullFamily: showFullFamily.toString(),
            start: startDate,
            end: endDate,
        },
        timeoutInMillis: 90 * ONE_SECOND,
        mapResponse: (response: IReservedMedicalExaminationWithoutId[] | null) => {
            // The BFF returns status 204 when no medical examinations were found.
            // When this happens, the response is null. To prevent the call from timing out,
            // we return an empty array
            if (!response) {
                return [];
            }

            return response.map(mapReservedMedicalExaminationWithId);
        },
    });
}

export function fetchReservedDetail({
    companyCode,
    showFullFamily,
    branchCode,
    activityId,
    locale,
}: IFetchReservedMedicalExaminationApiPayload): IRequestWrapperPromise<IReservedMedicalExamination> {
    return get<IReservedMedicalExamination>({
        ...bffDefaultApiConfig({ locale }),
        url: COMPANY_MEDICAL_EXAMINATIONS_URL.RESERVED_DETAIL,
        pathParams: {
            organizationalUnitCode: companyCode,
            activityId,
        },
        queryParams: {
            branchCode,
            showFullFamily: showFullFamily.toString(),
        },
        timeoutInMillis: 90 * ONE_SECOND,
        mapResponse: (response: IReservedMedicalExaminationWithoutId | null) => {
            if (!response) {
                return null;
            }

            return {
                ...response,
                id: composeReservedMedicalExaminationId(response),
            };
        },
    });
}

export function fetchReservedDetailTimeSlots({
    companyCode,
    showFullFamily,
    activityId,
    branchCode,
    locale,
}: IFetchReservedMedicalExaminationApiPayload): IRequestWrapperPromise<IBufferzoneTimeslots> {
    return get<IBufferzoneTimeslots>({
        ...bffDefaultApiConfig({ locale }),
        url: COMPANY_MEDICAL_EXAMINATIONS_URL.RESERVED_DETAIL,
        pathParams: {
            organizationalUnitCode: companyCode,
            activityId,
        },
        queryParams: {
            branchCode,
            showFullFamily: showFullFamily.toString(),
        },
        timeoutInMillis: 90 * ONE_SECOND,
        mapResponse: (response: IReservedMedicalExaminationWithoutId | null) => {
            if (!response) {
                return {
                    freeTimeslots: [],
                    plannedExaminations: [],
                    totalTimeslots: 0,
                };
            }

            const bufferzone = {
                ...response,
                id: composeReservedMedicalExaminationId(response),
            };

            return {
                freeTimeslots: bufferzone.reservedTimeSlots,
                plannedExaminations: bufferzone.plannedMedicalExaminations.map(
                    (plannedMedicalExamination) => mapBffMedicalExaminationToBufferzonePlannedTimeSlot(
                        bufferzone,
                        plannedMedicalExamination,
                    ),
                ),
                totalTimeslots: bufferzone.availability.totalTimeSlots,
            };
        },
    });
}

// We asked Mensura IL team to add an id property per buffer zone object.
// The answer was that they cannot add it because it does not exist.
// A buffer zone is unique based on a combination of 2 parameters:
//   - activityId: activity on which timeslots are reserved (not unique)
//   - branchCode: organizationalUnitCode of the branch/company that has reserved timeslots

// Making an id on the back-end based on 2 parameters is considered an anti-pattern.
// Therefore we add the id property based on the 2 parameters above: activityId-branchCode.
const mapReservedMedicalExaminationWithId = (
    reservedMedicalExamination: IReservedMedicalExaminationWithoutId,
): IReservedMedicalExamination => {

    // Manually set location code to 'In Company medical center code' ('7')
    // as a fallback when no location code is found & location type
    // code is customer.
    const locationCode = pathOr(
        reservedMedicalExamination.location.typeCode === ILocationTypeCodes.CUSTOMER
            ? IN_COMPANY_MEDICAL_CENTER_CODE
            : null,
        ['location', 'code'],
        reservedMedicalExamination,
    );

    return {
        ...reservedMedicalExamination,
        id: composeReservedMedicalExaminationId(reservedMedicalExamination),
        location: {
            ...reservedMedicalExamination.location,
            code: locationCode,
        },
    };
};
