import React, { Component, createRef, RefObject } from 'react';
import classNames from 'classnames';
import './course-introduction.scss';
import {
    ICMSCourseDetail,
    ICMSCourse,
} from '../../../../../models/documentCenter/courses';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import {
    getCoursesOverviewDetail,
    getCoursesOverviewDetailAsyncInfo,
    getSelectedCourseIntroductionId,
    getCourseSessionsAsyncInfo,
} from '../../../../../redux/documentCenter/courses/selectors';
import { navigateTo } from '../../../../../redux/location/actions';
import { doesCompanyCurrentlyUsesPreventionUnits } from '../../../../../redux/preventionUnits/selectors';
import { toAnchorTargetId, toAnchorLinkHref } from '../../../../../utils/core/string/anchor';
import ROUTE_KEYS from '../../../../../routeKeys';
import PageHeader from '../../../../appShell/PageHeader';
import Button from '../../../../common/buttons/Button';
import PagePart from '../../../../common/page/PagePart';
import Translate from '../../../../common/Translate';
import Loader from '../../../../common/waiting/Loader';
import ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../../common/widget/ContentWithSidebar';
import { connect } from '../../../../index';
import { CourseDetails } from '../../shared/CourseDetails';
import { RelatedCourses } from '../../shared/RelatedCourses';
import { SideBarCta } from '../../shared/SidebarCta';
import mapToCourseDetailInfo, { ICourseDetailInfo } from './mapToCourseDetailInfo';
import CourseMethods from './CourseMethods';
import {
    createElearningCourseEnrollWizardEntity,
} from '../../../../../redux/documentCenter/courses/actions';
import { isSelectedCompanyDismissed } from '../../../../../redux/company/selected/selectors';
import { TEACHING_TYPES } from '../../../../../config/courses.config';

interface IPrivateProps {
    courseIntroductionAsyncInfo: IAsyncFieldInfo;
    courseIntroductionData: ICMSCourseDetail;
    courseSessionsAsyncInfo: IAsyncFieldInfo;
    hidePE: boolean;
    onMethodSelect: (course: ICMSCourse) => void;
    navigateToOverview: () => void;
    isSelectedCompanyDismissed: boolean;
}

const CLASS_NAME = 'CourseIntroduction';
const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.intro';

class CourseIntroduction extends Component<IPrivateProps> {
    private courseMethodsRef: RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.scrollToCourseMethods = this.scrollToCourseMethods.bind(this);
        this.courseMethodsRef = createRef();
    }

    public render() {
        const {
            courseIntroductionAsyncInfo, courseIntroductionData, courseSessionsAsyncInfo,
            hidePE, onMethodSelect, navigateToOverview, isSelectedCompanyDismissed,
        } = this.props;

        const courseDetailInfo = mapToCourseDetailInfo(courseIntroductionData);

        return (
            <div className={CLASS_NAME}>
                <Loader show={courseIntroductionAsyncInfo.status} />
                {courseIntroductionData && (
                    <>
                        <PageHeader
                            breadcrumbs={true}
                            title={courseIntroductionData.course.name}
                            text={courseIntroductionData.course.subTitle}
                        />
                        <div className="container">
                            <div className={`${CLASS_NAME}__page-header-button`}>
                                <Button
                                    id="page-header-course-intro-button"
                                    typeName="secondary"
                                    onClick={this.scrollToCourseMethods}
                                >
                                    {courseIntroductionData.course.ctaText || (
                                        <Translate msg={`${TRANSLATION_PREFIX}.page_header.button`} />
                                    )}
                                </Button>
                            </div>
                            <CourseDetails
                                course={courseIntroductionData.course}
                                hidePE={hidePE}
                            />
                            <ContentWithSidebar
                                sidebar={(renderProps) =>
                                    <NavigationSidebar
                                        courseDetailInfo={courseDetailInfo}
                                        onSidebarCtaClick={this.scrollToCourseMethods}
                                        sidebarCtaButtonTranslation={courseIntroductionData.course.ctaStickyText}
                                        {...renderProps}
                                    />}
                                content={<MainContent courseDetailInfo={courseDetailInfo} />}
                                titleIds={getNavigationTitleIds(courseDetailInfo)}
                            />
                        </div>
                        <div className={`${CLASS_NAME}__methods`}>
                            <Loader show={courseSessionsAsyncInfo.status} />
                            <CourseMethods
                                titleTranslationKey={`${TRANSLATION_PREFIX}.methods.title`}
                                methods={courseIntroductionData.children}
                                onMethodSelect={onMethodSelect}
                                hidePE={hidePE}
                                ref={this.courseMethodsRef}
                                hideELearningSubscribeButton={isSelectedCompanyDismissed}
                            />
                        </div>
                        <RelatedCourses
                            courses={courseIntroductionData.related
                                .filter((relatedCourse) =>
                                    relatedCourse.course.nodeId !== courseIntroductionData.course.nodeId)
                                .map((relatedCourse) => relatedCourse.course)}
                            hidePE={hidePE}
                        />
                    </>
                )}
                {(courseIntroductionAsyncInfo.status === AsyncStatus.Error) && (
                    <div className={classNames('container', `${CLASS_NAME}__not-found`)}>
                        <h2><Translate msg={`${TRANSLATION_PREFIX}.not_found.title`} /></h2>
                        <p><Translate msg={`${TRANSLATION_PREFIX}.not_found.description`} /></p>
                        <Button
                            id="course-not-found-back-button"
                            typeName="secondary"
                            onClick={navigateToOverview}
                        >
                            <Translate msg={`${TRANSLATION_PREFIX}.not_found.button`} />
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    private scrollToCourseMethods() {
        if (this.courseMethodsRef && this.courseMethodsRef.current) {
            this.courseMethodsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }
}

interface ICourseDetailInfoProps {
    courseDetailInfo: ICourseDetailInfo;
}

interface ICourseDetailSidebarProps extends ICourseDetailInfoProps {
    onSidebarCtaClick: () => void;
    sidebarCtaButtonTranslation?: string;
}

function NavigationSidebar(props: ICourseDetailSidebarProps & IContentWithSidebarRenderProps) {
    const {
        courseDetailInfo,
        activeTitleId,
        setActiveTitleIdOverride,
        onSidebarCtaClick,
        sidebarCtaButtonTranslation,
    } = props;

    return (
        <nav>
            {courseDetailInfo.anchors
                .map(({ anchorId, text }) => {
                    const titleClass = classNames({
                        active: activeTitleId === anchorId,
                    });
                    return (
                        <SideBarLink
                            key={`sidebar-${anchorId}`}
                            href={toAnchorLinkHref(anchorId)}
                            className={titleClass}
                            setActiveTitleIdOverride={() => setActiveTitleIdOverride(anchorId)}
                        >
                            <Translate msg={text} />
                        </SideBarLink>
                    );
                })
            }
            <SideBarCta
                titleTranslationKey={`${TRANSLATION_PREFIX}.sidebar.cta.title`}
                buttonTranslationKey={sidebarCtaButtonTranslation || `${TRANSLATION_PREFIX}.sidebar.cta.button`}
                buttonOnClickHandler={onSidebarCtaClick}
            />
        </nav>
    );
}

function MainContent(props: ICourseDetailInfoProps) {
    const { courseDetailInfo } = props;

    return (
        <div className="content">
            {courseDetailInfo.pageParts
                .map((courseDetailPagePart, index) => {
                    const pagePartId = courseDetailPagePart.anchorId
                        ? toAnchorTargetId(courseDetailPagePart.anchorId)
                        : `pagePart-without-title-${index}`;
                    return (
                        <div
                            key={`course-detail-info-pagePart-${index}`}
                            id={pagePartId}
                        >
                            <PagePart pagePart={courseDetailPagePart} />
                        </div>
                    );
                })
            }
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        courseIntroductionAsyncInfo: getCoursesOverviewDetailAsyncInfo(state),
        courseIntroductionData: getCoursesOverviewDetail(state),
        courseSessionsAsyncInfo: getCourseSessionsAsyncInfo(state),
        hidePE: !doesCompanyCurrentlyUsesPreventionUnits(state),
        isSelectedCompanyDismissed: isSelectedCompanyDismissed(state),
    }),
    dispatchProps: (dispatch, getState) => ({
        onMethodSelect: (course: ICMSCourse) => {
            const state = getState();
            const introductionNodeId = getSelectedCourseIntroductionId(state);

            if (course.teachingType === TEACHING_TYPES.online) {
                dispatch(createElearningCourseEnrollWizardEntity({
                    childNodeId: course.nodeId,
                }));
            } else {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_COURSE, {
                    nodeId: introductionNodeId,
                    childNodeId: course.nodeId,
                }));
            }
        },
        navigateToOverview: () => {
            dispatch(navigateTo(ROUTE_KEYS.R_COURSES_OVERVIEW));
        },
    }),
})(CourseIntroduction);

function getNavigationTitleIds(courseDetailInfo: ICourseDetailInfo) {
    return courseDetailInfo
        .anchors
        .map((title) => title.anchorId);
}
