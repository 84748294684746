import React from 'react';
import { IIconLeftWithTextPagePartData } from '../../../../../models/general/pageParts';
import './icon-left-with-text-page-part.scss';
import WysiwygContentType from '../WysiwygContentType';

interface IPublicProps {
    hostname: string;
    data: IIconLeftWithTextPagePartData;
}

const CLASS_NAME = 'IconLeftWithTextPagePart';

export default function IconLeftWithTextPagePart(props: IPublicProps) {
    const { data, hostname } = props;

    return (
        <div className={CLASS_NAME}>
            {(data.image && hostname) && (
                <figure className={`${CLASS_NAME}__visual`}>
                    <img src={`${hostname}${data.image.url}`} alt={data.imageAltText} />
                </figure>
            )}
            {data.body && (
                <WysiwygContentType
                    className={`${CLASS_NAME}__content`}
                    html={data.body}
                />
            )}
        </div>
    );
}
