import { DayParts } from '../../general/daypart';
import { IBranch } from '../../general/branch';

export * from './document';
export * from './executed';
export * from './planned';
export * from './request';

export interface ICompanyVisit {
    visitReason: IVisitReason;
    branch: IBranch;
    visitor: IVisitor;
    activityId: number;
    dayPart? : DayParts;
    start: string; // Example: 2021-10-28T09:00:00
    end: string; // Example: 2021-10-28T11:00:00
}

export interface IVisitReason {
    code: string
    description: string
}

export interface IVisitor {
    firstName: string
    lastName: string
}
