import React from 'react';
import { IPagePart } from '@snipsonian/react/es/components/pageParts/pageParts.models';
import {
    PagePartType,
    IHeaderPagePartData,
    ITextPagePartData,
    ITextBlockPagePartData,
    IVideoPagePartData,
    ISpotlightPagePartData,
    IIconLeftWithTextPagePartData,
    IButtonPagePartData,
    ITimeLinePagePartData,
    ITipPagePartData,
} from '../../../../models/general/pageParts';
import HeaderPagePart from './HeaderPagePart';
import TextPagePart from './TextPagePart';
import TextBlockPagePart from './TextBlockPagePart';
import LegalCenteredIconPagePart, { ILegalCenteredIconPagePartData } from './LegalCenteredIconPagePart';
import LegalCookiesPagePart from './LegalCookiesPagePart/index';
import LegalIconTextPagePart from './LegalIconTextPagePart';
import LegalTipPagePart, { ILegalTipPagePartData } from './LegalTipPagePart';
import { ILegalIconTextPagePartData } from './LegalIconTextPagePart/index';
import VideoPagePart from './VideoPagePart';
import CourseAnchorPagePart from './CourseAnchorPagePart';
import LinePagePart from './LinePagePart';
import SpotlightPagePart from './SpotlightPagePart';
import IconLeftWithTextPagePart from './IconLeftWithTextPagePart';
import ButtonPagePart from './ButtonPagePart';
import TimeLinePagePart from './TimeLinePagePart';
import TipPagePart from './TipPagePart';

const CLASS_NAME = 'PagePart';

interface IPublicProps {
    pagePart: IPagePart<{}> & {
        hostname?: string;
    };
}

export default function PagePart(props: IPublicProps) {
    return (
        <div className={CLASS_NAME}>
            {renderPagePartComponent(props)}
        </div>
    );
}

function renderPagePartComponent({ pagePart }: IPublicProps) {
    const { type, data, hostname } = pagePart;

    switch (type) {
        case PagePartType.HEADER:
        case PagePartType.MENSURA_HEADER:
            return (
                <HeaderPagePart data={data as IHeaderPagePartData} />
            );
        case PagePartType.KUMA_TEXT:
        case PagePartType.MENSURA_TEXT:
        case PagePartType.MENSURA_WEBSITE_TEXT:
            return (
                <TextPagePart data={data as ITextPagePartData} />
            );
        case PagePartType.TEXT_BLOCK:
            return (
                <TextBlockPagePart data={data as ITextBlockPagePartData} />
            );
        case PagePartType.LEGAL_CENTERED_ICON:
            return (
                <LegalCenteredIconPagePart data={data as ILegalCenteredIconPagePartData} />
            );
        case PagePartType.LEGAL_COOKIES:
            return (
                <LegalCookiesPagePart />
            );
        case PagePartType.LEGAL_ICON_TEXT:
            return (
                <LegalIconTextPagePart data={data as ILegalIconTextPagePartData} />
            );
        case PagePartType.LEGAL_TIP:
            return (
                <LegalTipPagePart data={data as ILegalTipPagePartData} />
            );
        case PagePartType.MENSURA_VIDEO:
            return (
                <VideoPagePart data={data as IVideoPagePartData} hostname={hostname} />
            );
        case PagePartType.MENSURA_COURSE_ANCHOR:
            return (
                <CourseAnchorPagePart />
            );
        case PagePartType.KUMA_LINE:
            return (
                <LinePagePart />
            );
        case PagePartType.MENSURA_SPOTLIGHT:
            return (
                <SpotlightPagePart data={data as ISpotlightPagePartData} />
            );
        case PagePartType.MENSURA_ICON_LEFT_WITH_TEXT:
            return (
                <IconLeftWithTextPagePart data={data as IIconLeftWithTextPagePartData} hostname={hostname} />
            );
        case PagePartType.MENSURA_BUTTON:
            return (
                <ButtonPagePart data={data as IButtonPagePartData} />
            );
        case PagePartType.MENSURA_TIMELINE:
            return (
                <TimeLinePagePart data={data as ITimeLinePagePartData} />
            );
        case PagePartType.MENSURA_TIP:
            return (
                <TipPagePart data={data as ITipPagePartData} />
            );
    }

    return null;
}
