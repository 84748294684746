import React from 'react';
import { connect } from '../../index';
import './footer.scss';
import LocaleSwitcher from '../LocaleSwitcher';
import FooterSubNavs from './FooterSubNavs';
import FooterAppMeta from './FooterAppMeta';
import MensuraWebsiteLink from './MensuraWebsiteLink';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import ROUTE_KEYS from '../../../routeKeys';
import { shouldHideFaqFooterLink } from '../../../redux/location/selectors';

interface IPublicProps {
    hideRouteLinks?: boolean;
}

interface IPrivateProps {
    hideFaqLink: boolean;
}

/**
 * hideRouteLinks : Set this to true if you don't want to show route links such as the FAQ yet.
 *                  (e.g. when the user is still in his login flow > each route link would not work yet as
 *                  the user first has to select a company and seat)
 */
function Footer(props: IPublicProps & IPrivateProps) {
    const { hideRouteLinks, hideFaqLink } = props;

    return (
        <footer className="Footer">
            <div className="nav">
                {!hideRouteLinks && !hideFaqLink &&
                    <LinkToRoute
                        id="faq-footer"
                        to={ROUTE_KEYS.R_FAQ_OVERVIEW}
                    >
                        <Translate msg="app_shell.footer.nav.faq" />
                    </LinkToRoute>
                }

                <MensuraWebsiteLink />

                <div className="lang-switch-wrapper">
                    <LocaleSwitcher />
                </div>
            </div>
            <div className="sub-nav">
                <FooterSubNavs />
            </div>
            <FooterAppMeta />
        </footer>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            hideFaqLink: shouldHideFaqFooterLink(state),
        };
    },
})(Footer);
