import React, { PureComponent } from 'react';
import isArray from '@snipsonian/core/es/is/isArray';
import { connect } from '../../../../index';
import ActiveFilterButton from '../../../buttons/ActiveFilterButton';
import { getDefinedObjectProps, removePropFromObject } from '../../../../../utils/core/object/objectProps';
import {
    removeStringListValueFromObjectProp,
    separateStringList,
} from '../../../../../utils/core/string/separatedStringList';
import {
    TMasterData,
    TTransformFilterValuesToActiveFilters,
    IActiveFilter,
    ITransformToActiveFiltersProps,
} from '../typings';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { IState } from '../../../../../redux';
import { IKeyValuePair } from '../../../../../utils/core/typings';
import { camelCaseToSnakeCase } from '../../../../../utils/formatting/formatTranslationKey';
import { ListItem } from '../../../../../models/general/list';
import { ShapeOf } from '../../../../../models/ui/form';
import { getColumnValueFromListItemThatMatchesSearchColumn } from '../../../../../utils/list/findListItemByColumnValue';
import { ITranslator } from '../../../../../models/general/i18n';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

interface IPublicProps {
    baseName: string;
    baseClassName: string;
    transformFilterValuesToActiveFilters?: TTransformFilterValuesToActiveFilters;
    masterData: TMasterData;
    filterValues: object;
    onFilterSubmit: (filters: object) => void;
    masterAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
}

interface IPrivateProps {
    activeFilters: IActiveFilter[];
    masterAsyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    translator: ITranslator;
}

class ActiveFiltersComp extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPrivateProps & IPublicProps) {
        super(props);

        this.onRemoveActiveFilter = this.onRemoveActiveFilter.bind(this);
    }

    public render() {
        const {
            baseName, baseClassName,
            filterValues, activeFilters,
        } = this.props;

        if (!activeFilters || activeFilters.length === 0) {
            return null;
        }

        return (
            <div className={`${baseClassName}__active-filters`}>
                {activeFilters.map((activeFilter, index) => (
                    <ActiveFilterButton
                        key={`${baseName}-active-filter-button-${activeFilter.filterKey}-${index}`}
                        onClick={() => this.onRemoveActiveFilter(
                            filterValues,
                            activeFilter.filterKey,
                            activeFilter.filterValue,
                        )}
                        translationKey={activeFilter.filterNameTranslationKey}
                        value={activeFilter.filterValueLabel || activeFilter.filterValue}
                        disabled={activeFilter.disabled}
                    />
                ))}
            </div>
        );
    }

    public onRemoveActiveFilter(filters: object, filterKey: string | string[], filterValue: string) {
        let newFilters;
        if (isArray(filterKey)) {
            const filterKeys = filterKey as string[];
            newFilters = filterKeys.reduce(
                (filtersAccumulator, singleFilterKey) => removePropFromObject(filtersAccumulator, singleFilterKey),
                filters,
            );
        } else {
            const singleFilterKey = filterKey as string;
            newFilters = (filters[singleFilterKey] === filterValue) ?
                removePropFromObject(filters, singleFilterKey) :
                removeStringListValueFromObjectProp(filters, singleFilterKey, filterValue);
        }
        if (this.props.masterAsyncInfo.status !== AsyncStatus.Busy) {
            this.props.onFilterSubmit(newFilters);
        }
    }
}

export default function ActiveFilters(props: IPublicProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <ConnectedActiveFilters {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

const ConnectedActiveFilters = connect<IPrivateProps, IPublicProps & IContextProps>({
    statePropsDeprecated: (state, props) => {
        const activeFilters = props.transformFilterValuesToActiveFilters ?
            props.transformFilterValuesToActiveFilters({
                masterData: props.masterData,
                filterValues: props.filterValues,
                getDefinedFilterValues: () => getDefinedObjectProps(props.filterValues),
                translator: props.translator,
            }) : [];

        return {
            activeFilters,
            masterAsyncInfo: props.masterAsyncInfoSelector(state),
        };
    },
})(ActiveFiltersComp);

interface ICreateGenericFilterConfig<ColumnNames> {
    show: boolean;
    translationKeySuffixOverride?: string;
    multiple?: {
        enable: true,
        filterValueLabelFromListItem?: {
            searchColumnName: keyof ColumnNames;
            columnNameToReturn: keyof ColumnNames;
        },
    };
    defaultValue?: string | number | boolean;
    type?: 'boolean';
}

interface IGenericActiveFiltersConfig<FilterValues, ColumnNames> {
    transformProps: ITransformToActiveFiltersProps<ListItem<ColumnNames>[], FilterValues>;
    translationKeyPrefix: string;
    filters?: ShapeOf<FilterValues, ICreateGenericFilterConfig<ColumnNames>>;
    groupConfig?: {
        filterKeys: (keyof FilterValues)[],
        translationKeySuffix: string,
        formatFilterValueForPlaceholder?: (value: any) => string;
    };
}

export function createGenericActiveFilters<FilterValues, ColumnNames>(
    config: IGenericActiveFiltersConfig<FilterValues, ColumnNames>,
): IActiveFilter<FilterValues>[] {

    const {
        getDefinedFilterValues,
        masterData: allListItems,
        translator,
        filterValues,
    } = config.transformProps;

    let groupAlreadyAdded = false;
    const translationKeyPrefix = config.translationKeyPrefix;

    return getDefinedFilterValues().reduce(
        (activeFiltersAccumulator: IActiveFilter<FilterValues>[], filter) => {

            const filterConfig: ICreateGenericFilterConfig<ColumnNames> = config.filters[filter.key];
            const inGroup = config.groupConfig && config.groupConfig.filterKeys.find((key) => key === filter.key);

            if (filterConfig && filterConfig.show) {
                if (filterConfig.multiple && filterConfig.multiple.enable) {
                    const filterValues = separateStringList(filter.value);
                    const filterValueLabelOverride = filterConfig.multiple.filterValueLabelFromListItem;

                    filterValues.forEach((value) => activeFiltersAccumulator.push(
                        createGenericActiveFilter<FilterValues>({
                            filter,
                            defaultValue: filterConfig.defaultValue,
                            translationKeyPrefix: config.translationKeyPrefix,
                            translationKeySuffixOverride: filterConfig.translationKeySuffixOverride,
                            activeFilterOverride: {
                                filterValue: value,
                                filterValueLabel: filterValueLabelOverride ?
                                    getColumnValueFromListItemThatMatchesSearchColumn<ColumnNames>(
                                        allListItems,
                                        filterValueLabelOverride.searchColumnName,
                                        value,
                                        filterValueLabelOverride.columnNameToReturn,
                                    ) : undefined,
                            },
                        }),
                    ));
                } else if (inGroup) {
                    if (!groupAlreadyAdded) {
                        const groupConfig = config.groupConfig;
                        const placeholders = {};

                        for (const key of groupConfig.filterKeys) {
                            placeholders[key as string] =
                                typeof groupConfig.formatFilterValueForPlaceholder === 'function' ?
                                    groupConfig.formatFilterValueForPlaceholder(filterValues[key]) : filterValues[key];
                        }

                        let dirtyGroupValue = false;
                        groupConfig.filterKeys.forEach((key) => {
                            if (
                                config.filters[key] &&
                                filterValues[key as string] !== config.filters[key].defaultValue
                            ) {
                                dirtyGroupValue = true;
                            }
                        });

                        activeFiltersAccumulator.push(
                            createGenericActiveFilter<FilterValues>({
                                filter,
                                translationKeyPrefix: config.translationKeyPrefix,
                                translationKeySuffixOverride: filterConfig.translationKeySuffixOverride,
                                activeFilterOverride: {
                                    filterValueLabel: translator({
                                        msg: `${translationKeyPrefix}.${config.groupConfig.translationKeySuffix}`,
                                        placeholders,
                                    }),
                                    filterKey: config.groupConfig.filterKeys,
                                    filterNameTranslationKey: '',
                                    disabled: !dirtyGroupValue,
                                },
                            }),
                        );
                    }
                    groupAlreadyAdded = true;
                } else if (filterConfig.type === 'boolean') {
                    if (!!filter.value) {
                        activeFiltersAccumulator.push(
                            createGenericActiveFilter<FilterValues>({
                                filter,
                                defaultValue: filterConfig.defaultValue,
                                translationKeyPrefix: config.translationKeyPrefix,
                                activeFilterOverride: {
                                    filterValueLabel: translator({
                                        msg: `${translationKeyPrefix}.${filterConfig.translationKeySuffixOverride ||
                                            camelCaseToSnakeCase(filter.key as string)}`,
                                    }),
                                    filterNameTranslationKey: '',
                                },
                            }),
                        );
                    }
                } else {
                    activeFiltersAccumulator.push(
                        createGenericActiveFilter<FilterValues>({
                            filter,
                            defaultValue: filterConfig.defaultValue,
                            translationKeyPrefix: config.translationKeyPrefix,
                            translationKeySuffixOverride: filterConfig.translationKeySuffixOverride,
                        }),
                    );
                }
            }

            return activeFiltersAccumulator;
        },
        [],
    );
}

function createGenericActiveFilter<FilterValues>(
    config: {
        filter: IKeyValuePair<keyof FilterValues>,
        translationKeyPrefix: string,
        translationKeySuffixOverride?: string,
        activeFilterOverride?: Partial<IActiveFilter<FilterValues>>,
        defaultValue?: string | number | boolean;
    },
): IActiveFilter<FilterValues> {
    const {
        filter,
        translationKeyPrefix,
        translationKeySuffixOverride,
        activeFilterOverride,
        defaultValue,
    } = config;

    return {
        filterKey: filter.key,
        filterNameTranslationKey:
            `${translationKeyPrefix}.${translationKeySuffixOverride || camelCaseToSnakeCase(filter.key as string)}`,
        filterValue: filter.value,
        disabled: filter.value === defaultValue,
        ...activeFilterOverride,
    };
}
