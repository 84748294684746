import React, { ReactNode } from 'react';
import './list-item-bordered.scss';
import Icon from '../../icons/Icon';
import ROUTE_KEYS from '../../../../routeKeys';
import { ILocationAction } from '../../../../models/general/redux';
import LinkToRoute from '../../navigation/LinkToRoute';

interface IListItemBorderedProps {
    text: string | ReactNode;
    to?: ROUTE_KEYS | ILocationAction<object>;
    id?: string;
    raw?: boolean;
}

const CLASS_NAME = 'ListItemBordered';

export default function ListItemBordered(props: IListItemBorderedProps) {
    return (
        <div className={CLASS_NAME}>
            {props.to ? (
                <LinkToRoute id={props.id} to={props.to} className="content-wrapper">
                    <SingleListItemBordered {...props}/>
                </LinkToRoute>
            ) : (
                <div>
                    <SingleListItemBordered {...props}/>
                </div>
            )}
        </div>
    );
}

function SingleListItemBordered(props: IListItemBorderedProps) {
    const {
        to,
        raw,
        text,
    } = props;

    return (
        <>
            {
                raw && typeof text === 'string' ? (
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                ) : (
                    text
                )
            }
            {to && <Icon typeName="chevron-right" circle className={`${CLASS_NAME}__arrow-icon`}/>}
        </>
    );
}
