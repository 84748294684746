import React, { PureComponent, ReactNode, RefObject, createRef } from 'react';
import './content-with-sidebar.scss';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import { toAnchorTargetId } from '../../../../utils/core/string/anchor';
import Sticky from '../../technical/Sticky';

interface IContentWithSidebarProps {
    sidebar: (renderProps: IContentWithSidebarRenderProps) => ReactNode;
    content: ReactNode;
    titleIds: string[];
}

export interface IContentWithSidebarRenderProps {
    activeTitleId: string;
    setActiveTitleIdOverride: (id: string) => void;
}

interface IState {
    activeTitleId: string;
    activeTitleIdOverride: string;
}

const CLASS_NAME = 'ContentWithSidebar';
const OFFSET = {
    TOP_OUT_OF_SCREEN_BEFORE_INACTIVE: -10,
};

class ContentWithSidebar extends PureComponent<IContentWithSidebarProps, IState> {
    private contentElement: RefObject<HTMLDivElement> = createRef();
    private sidebarElement: RefObject<HTMLElement> = createRef();
    private onScrollDebounced: TDebounced;

    constructor(props) {
        super(props);

        this.state = {
            activeTitleId: '',
            activeTitleIdOverride: '',
        };

        this.onUpdateActiveTitle = this.onUpdateActiveTitle.bind(this);
        this.setActiveTitleIdOverride = this.setActiveTitleIdOverride.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollDebounced = debounce(this.onScroll, 50);
    }

    public render() {
        const { sidebar, content } = this.props;
        return (

            <div className={CLASS_NAME}>
                <aside
                    className={`${CLASS_NAME}__aside ${CLASS_NAME}__aside--fixed`}
                    ref={this.sidebarElement}
                >
                    <Sticky id="SidebarTitles" className={`${CLASS_NAME}__aside__fixed`}>
                        {sidebar({
                            activeTitleId: this.state.activeTitleIdOverride || this.state.activeTitleId,
                            setActiveTitleIdOverride: this.setActiveTitleIdOverride,
                        })}
                    </Sticky>
                </aside>
                <div className={`${CLASS_NAME}__main`} ref={this.contentElement}>
                    {content}
                </div>
            </div>
        );
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.onScrollDebounced);
        this.onUpdateActiveTitle();
    }

    public componentDidUpdate() {
        this.onUpdateActiveTitle();
    }

    public componentWillUnmount() {
        this.onScrollDebounced.cancel();
        window.removeEventListener('scroll', this.onScrollDebounced);
    }

    private setActiveTitleIdOverride(id: string) {
        this.setState({ activeTitleIdOverride: id });
    }

    private onScroll() {
        const { activeTitleIdOverride } = this.state;
        if (activeTitleIdOverride) {
            if (!this.isActiveTitleIdOverrideElementVisibleOnScreen()) {
                this.setState({ activeTitleIdOverride: '' });
            }
        } else {
            this.onUpdateActiveTitle();
        }
    }

    private isActiveTitleIdOverrideElementVisibleOnScreen() {
        const { activeTitleIdOverride } = this.state;
        const activeEl = document.getElementById(toAnchorTargetId(activeTitleIdOverride));
        if (!activeEl) {
            return false;
        }
        const activeElRect = activeEl.getBoundingClientRect();

        return activeElRect.top >= 0 && activeElRect.bottom <= window.innerHeight;
    }

    private onUpdateActiveTitle() {
        if (
            !this.contentElement.current ||
            !this.sidebarElement.current ||
            getComputedStyle(this.sidebarElement.current).display === 'none'
        ) {
            return;
        }

        const activeElementId = this.getElementThatIsTheFirstElementWithTopVisibleOnScreen();

        this.setState({ activeTitleId: activeElementId });
    }

    private getElementThatIsTheFirstElementWithTopVisibleOnScreen() {
        const { titleIds } = this.props;
        if (!Array.isArray(titleIds) || titleIds.length === 0) {
            return null;
        }

        return titleIds.reduce((prev, current) => {
            const prevElement = document.getElementById(toAnchorTargetId(prev));
            const currentElement = document.getElementById(toAnchorTargetId(current));

            if (!prevElement) {
                return current;
            }
            if (!currentElement) {
                return prev;
            }

            const prevTop = prevElement.getBoundingClientRect().top;
            const currentTop = currentElement.getBoundingClientRect().top;

            return prevTop < currentTop && prevTop >= OFFSET.TOP_OUT_OF_SCREEN_BEFORE_INACTIVE ?
                prev : current;
        });
    }
}

export default ContentWithSidebar;

interface ISideBarLinkProps {
    href: string;
    className: string;
    setActiveTitleIdOverride: () => void;
    children: ReactNode;
}

export const SideBarLink = (props: ISideBarLinkProps) => {
    return (
        <a
            href={props.href}
            className={props.className}
            onClick={props.setActiveTitleIdOverride}
        >
            {props.children}
        </a>
    );
};
