import React from 'react';
import Dialog from '../Dialog';
import Button from '../../buttons/Button';
import Translate from '../../Translate';

interface IPublicProps {
    show: boolean;
    titleTranslationKey: string;
    infoTranslationKey?: string;
    confirmButtonTranslationKey?: string;
    onCloseDialog: () => void;
}

export default function SuccessDialog(props: IPublicProps) {
    const {
        show,
        titleTranslationKey,
        infoTranslationKey,
        onCloseDialog,
    } = props;

    const confirmButtonTranslationKey = props.confirmButtonTranslationKey || 'common.success_dialog.confirm';

    return (
        <Dialog
            show={show}
            onCloseIntent={onCloseDialog}
            header={titleTranslationKey}
            type="success"
        >
            {infoTranslationKey &&
            <p>
                <Translate msg={infoTranslationKey} />
            </p>
            }
            <div className="SuccessDialog__buttons">
                <Button
                    id="success-dialog-confirm"
                    typeName="secondary"
                    onClick={onCloseDialog}
                >
                    <Translate msg={confirmButtonTranslationKey} />
                </Button>
            </div>
        </Dialog>
    );
}
