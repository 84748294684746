import React, { PureComponent, MouseEvent } from 'react';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee, getUpdateEmployeeCostCenterAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import { schema, fields } from './updateCostCenterSchema';
import FormError from '../../../../common/forms/FormError';
import { updateEmployeeCostCenter } from '../../../../../redux/employee/info/actions';
import CostCenterTypeahead from '../CostCenterTypeahead';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../../common/buttons/SubmitButton';

interface IFormValues {
    name: string;
}

interface IPrivateProps {
    onSubmit: (values: IFormValues) => void;
    initialValues: IFormValues;
    translator: ITranslator;
    isDetailsLoaded: boolean;
    clearError: (error: ITraceableApiError) => void;
    updateAsyncInfo: IAsyncFieldInfo;
}

interface IEditCostCenterProps {
    onClose: () => void;
}

const FORM_NAME = 'edit-cost-center-form';
const CLASS_NAME = 'EditCostCenter';

const COST_CENTER_PLACEHOLDER_TRANS_KEY = 'administration.employees.edit_cost_center.form.id';

class EditCostCenter extends PureComponent<IPrivateProps & IEditCostCenterProps> {

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, initialValues, onSubmit,
            isDetailsLoaded, updateAsyncInfo,
        } = this.props;

        if (!isDetailsLoaded) {
            return null;
        }

        const placeholder = translator(COST_CENTER_PLACEHOLDER_TRANS_KEY);

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.edit_cost_center.title'}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={(values: IFormValues) => onSubmit(values)}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue,
                    }: IFormRenderProps<IFormValues>) => {
                        function onCostCenterSelected(name: string) {
                            setFieldValue('name', name);
                        }

                        return (
                            <>
                                <Loader show={updateAsyncInfo.status === AsyncStatus.Busy} />
                                <FloatableTextInputWrapper>
                                    <CostCenterTypeahead
                                        id="edit-employee-cost-center"
                                        value={values.name}
                                        name={fields.name}
                                        onItemSelected={onCostCenterSelected}
                                        isInvalid={touched.name && !!errors.name}
                                        placeholderTranslationKey={COST_CENTER_PLACEHOLDER_TRANS_KEY}
                                    >
                                        <label htmlFor="edit-employee-cost-center">
                                            {placeholder}
                                        </label>
                                    </CostCenterTypeahead>
                                    {touched.name && (
                                        <FormFieldError
                                            error={errors.name}
                                            placeholders={{ fieldName: placeholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FormError error={updateAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="edit-cost-center-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate msg="administration.employees.edit_cost_center.form.cancel" />
                                    </Button>
                                    <SubmitButton
                                        id="edit-cost-center-submit-button"
                                        formName={FORM_NAME}
                                    >
                                        <Translate
                                            msg="administration.employees.edit_cost_center.form.submit"
                                        />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (this.props.updateAsyncInfo &&
            this.props.updateAsyncInfo.status === AsyncStatus.Success &&
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, updateAsyncInfo } = this.props;
        clearError(updateAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IEditCostCenterProps>({
    stateProps: (state) => {
        const selectedEmployee = getSelectedEmployee(state);
        const updateAsyncInfo = getUpdateEmployeeCostCenterAsyncInfo(state);

        return {
            isDetailsLoaded: !!selectedEmployee,
            initialValues: {
                name: selectedEmployee && selectedEmployee.costCenter.name,
            },
            translator: getTranslatorDeprecated(state),
            updateAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSubmit: (values: IFormValues) => {
                const employee = getSelectedEmployee(getState());

                dispatch(updateEmployeeCostCenter({
                    id: employee.id,
                    costCenter: values.name,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditCostCenter);
