import React, { Fragment, forwardRef } from 'react';
import { ICMSCourseDetail, ICMSCourse } from '../../../../../../models/documentCenter/courses';
import isEmptyString from '../../../../../../utils/core/string/isEmptyString';
import { formatFloat } from '../../../../../../utils/formatting/formatFloat';
import Button from '../../../../../common/buttons/Button';
import Icon from '../../../../../common/icons/Icon';
import Translate from '../../../../../common/Translate';
import './course-methods.scss';
import OutgoingLink from '../../../../../common/navigation/OutgoingLink';
import { isCourseWithCustomPrice } from '../../../shared/courseUtils';
import classNames from 'classnames';
import { TEACHING_TYPES } from '../../../../../../config/courses.config';

const CLASS_NAME = 'CourseMethods';
const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.intro.methods';

interface IPublicProps {
    titleTranslationKey?: string;
    methods: ICMSCourseDetail[];
    onMethodSelect: (course: ICMSCourse) => void;
    hidePE: boolean;
    hideELearningSubscribeButton?: boolean;
}

const CourseMethods = (
    { titleTranslationKey, methods, onMethodSelect, hidePE, hideELearningSubscribeButton }: IPublicProps,
    ref: React.Ref<HTMLDivElement>,
) => {
    if (!methods || methods.length === 0) {
        return null;
    }

    return (
        <div id="course-methods" className={CLASS_NAME} ref={ref}>
            <div className="container">
                {titleTranslationKey && (
                    <h2><Translate msg={titleTranslationKey} raw /></h2>
                )}
                <ul className={`${CLASS_NAME}__list`}>
                    {methods.map((method) => (
                        <li key={method.course.nodeId} className={`${CLASS_NAME}__list__item`}>
                            <CourseMethod
                                method={method}
                                onMethodSelect={onMethodSelect}
                                hidePE={hidePE}
                                hideELearningSubscribeButton={hideELearningSubscribeButton}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

function CourseMethod({ method, onMethodSelect, hidePE, hideELearningSubscribeButton }: {
    method: ICMSCourseDetail;
    onMethodSelect: (course: ICMSCourse) => void;
    hidePE: boolean;
    hideELearningSubscribeButton?: boolean;
}) {
    const { course } = method;
    const isCustomPrice = isCourseWithCustomPrice(course);
    const isELearningType = course.teachingType === TEACHING_TYPES.online;

    return (
        <div className={`${CLASS_NAME}__method`}>
            {course.spotlight && (
                <span className={`${CLASS_NAME}__method__flag`}>
                    <Translate msg={`${TRANSLATION_PREFIX}.item.spotlight`} />
                </span>
            )}
            {(course.media && course.hostName) && (
                <figure className={`${CLASS_NAME}__method__figure`}>
                    <img src={`${course.hostName}${course.media}`} alt="" />
                </figure>
            )}
            <div className={`${CLASS_NAME}__method__content`}>
                <div className={`${CLASS_NAME}__method__content__info`}>
                    <h3>{course.name}</h3>
                    {course.pageName && (
                        <p>{course.pageName}</p>
                    )}
                </div>
                <div className={`${CLASS_NAME}__method__content__footer`}>
                    <ul className={`${CLASS_NAME}__content__details-list`}>
                        <li>
                            <Icon typeName="time" colorType="secondary" />
                            {course.duration || <Translate msg={`${TRANSLATION_PREFIX}.item.duration.custom`} />}
                        </li>
                        <li>
                            <Icon typeName="text-balloons" colorType="secondary" />
                            {isCustomPrice ? <Translate msg={`${TRANSLATION_PREFIX}.item.language.all`} /> : (
                                course.languages && Object.keys(course.languages).map((lang, index) => {
                                    const langKey = course.languages[lang].toLowerCase();
                                    if (!langKey || isEmptyString(langKey)) {
                                        return null;
                                    }

                                    return (
                                        <Fragment key={langKey}>
                                            {index > 0 && ', '}
                                            <Translate msg={`${TRANSLATION_PREFIX}.item.language.${langKey}`} />
                                        </Fragment>
                                    );
                                })
                            )}
                        </li>
                    </ul>
                    <div className={`${CLASS_NAME}__method__price`}>
                        <div className={`${CLASS_NAME}__method__price__amount`}>
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.item.price.${isCustomPrice ? 'custom_price' : 'price'}`}
                                placeholders={{ price: formatFloat(course.price) }}
                            />
                        </div>
                        {!isCustomPrice && (
                            <>
                                {!hidePE && (
                                    <div className={`${CLASS_NAME}__method__price__pe`}>
                                        <Translate
                                            msg={`${TRANSLATION_PREFIX}.item.price.pe`}
                                            placeholders={{ pe: course.pe }}
                                        />
                                    </div>
                                )}
                                <div className={`${CLASS_NAME}__method__price__disclaimer`}>
                                    <Translate msg={`${TRANSLATION_PREFIX}.item.price.disclaimer`} />
                                </div>
                            </>
                        )}
                    </div>
                    {(!isELearningType || !hideELearningSubscribeButton) && (
                        (isCustomPrice && course.formUrl) ? (
                            <OutgoingLink
                                href={course.formUrl}
                                className={classNames(`${CLASS_NAME}__method__button`, {
                                    primary: !course.spotlight,
                                    secondary: !!course.spotlight,
                                })}
                            >
                                <Translate msg={course.ctaText || `${TRANSLATION_PREFIX}.item.button`} />
                            </OutgoingLink>
                        ) : (
                            <Button
                                id={`course-method-select-${course.nodeId}`}
                                typeName={course.spotlight ? 'secondary' : 'primary'}
                                onClick={() => onMethodSelect(course)}
                                size="small"
                            >
                                <Translate msg={course.ctaText || `${TRANSLATION_PREFIX}.item.button`} />
                            </Button>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default forwardRef<HTMLDivElement, IPublicProps>(CourseMethods);
