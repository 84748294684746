import React from 'react';
import './free-slots-indicator.scss';
import Translate from '../../Translate';
import StatusIndicator from '../StatusIndicator';

interface IPriorityIndicatorProps {
    maxSlots: number;
    freeSlots: number;
    showMaxSlots?: boolean;
    hideFreeSlots?: boolean;
}

enum FreeSlotsValueForStatusIndicator {
    High = 25,
    Medium = 15,
    Low = 5,
}

interface IFreeSlotsStatus {
    statusIndicatorValue: FreeSlotsValueForStatusIndicator;
    full?: boolean;
}

const CLASS_NAME = 'FreeSlotsIndicator';

export default function FreeSlotsIndicator(props: IPriorityIndicatorProps) {
    const { freeSlots, maxSlots, showMaxSlots, hideFreeSlots } = props;

    const status = getIndicatorStatus(freeSlots, maxSlots);

    return (
        <div className={CLASS_NAME}>
            {status.full ? (
                <span className={`${CLASS_NAME}__no-slots`}>
                    <Translate msg="common.free_slots_indicator.no_slots" />
                </span>
            ) : (
                    <>
                        {!hideFreeSlots && (
                            showMaxSlots ? (
                                <span>
                                    <strong>{freeSlots}</strong>
                                    {` / ${maxSlots}`}
                                </span>
                            ) : <span>{freeSlots}</span>
                        )}
                        <StatusIndicator
                            value={status.statusIndicatorValue}
                            className={`${CLASS_NAME}__status-indicator`}
                        />
                    </>
                )}
        </div>

    );
}

export function getIndicatorStatus(freeSlots: number, maxSlots: number): IFreeSlotsStatus {
    const ratio = freeSlots / maxSlots;
    switch (true) {
        case ratio > 0.7:
            return {
                statusIndicatorValue: FreeSlotsValueForStatusIndicator.High,
            };
        case ratio <= 0.7 && ratio > 0.25:
            return {
                statusIndicatorValue: FreeSlotsValueForStatusIndicator.Medium,
            };
        case ratio <= 0.25 && ratio > 0:
            return {
                statusIndicatorValue: FreeSlotsValueForStatusIndicator.Low,
            };
        default:
            return {
                full: true,
                statusIndicatorValue: null,
            };
    }
}
