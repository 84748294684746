export const FORM_NAME = 'request-account-form';

export const TRANSLATION_KEY_PREFIX = 'auth.request_account.step_two.form.placeholders';
export const PLACEHOLDER_KEYS = {
    firstName: `${TRANSLATION_KEY_PREFIX}.first_name`,
    name: `${TRANSLATION_KEY_PREFIX}.name`,
    email: `${TRANSLATION_KEY_PREFIX}.email`,
    titleId: `${TRANSLATION_KEY_PREFIX}.salutation`,
    sexId: `${TRANSLATION_KEY_PREFIX}.sex`,
};

export const INITIAL_VALUES = {
    companyCode: '',
    ventureNumber: '',
    firstName: '',
    name: '',
    email: '',
    sexId: null,
    titleId: null,
};
