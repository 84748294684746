import defaultLocale from 'flatpickr/dist/l10n/default';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Locales } from '../../../config/i18n.config';
import { CustomLocale } from 'flatpickr/dist/types/locale';

export default function localeToFlatpickrLocale(locale: Locales, includeDefault: boolean = true): CustomLocale {
    if (!includeDefault) {
        return LOCALE_MAPPING[locale] || defaultLocale;
    }
    return LOCALE_MAPPING_WITH_DEFAULT[locale] || defaultLocale;
}

const LOCALE_MAPPING = {
    [Locales.nl_BE]: Dutch,
    [Locales.fr_BE]: French,
};

const LOCALE_MAPPING_WITH_DEFAULT = {
    [Locales.nl_BE]: {
        ...defaultLocale,
        ...Dutch,
    },
    [Locales.fr_BE]: {
        ...defaultLocale,
        ...French,
    },
};
