import React from 'react';
import { IValidateEmployeeToPlanData } from '../../../../../../models/interventions/medicalExaminations';
import { connect } from '../../../../..';
import {
    getValidateEmployeeToPlanData,
} from '../../../../../../redux/medicalExamination/selectors';
import { validateEmployeeToPlanActions } from '../../../../../../redux/medicalExamination/actions';
import Translate from '../../../../../common/Translate';
import WarningDialog from '../../../../../common/modals/WarningDialog';
import ListItem from '../../../../../common/list/ListItem';
import { formatDateWithTimeForDisplay } from '../../../../../../utils/formatting/formatDate';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.validate.dialog';

interface IPublicProps {
    onConfirm: () => void;
}

interface IPrivateProps {
    validationData: IValidateEmployeeToPlanData;
    resetValidateEmployeeToPlan: () => void;
}

class ValidateEmployeeToPlanDialog extends React.Component<IPublicProps & IPrivateProps> {
    public constructor(props: IPublicProps & IPrivateProps) {
        super(props);
    }

    public componentDidMount() {
        this.props.resetValidateEmployeeToPlan();
    }

    public render() {
        const { validationData, resetValidateEmployeeToPlan, onConfirm } = this.props;

        if (!validationData || !validationData.warning) {
            return null;
        }

        const hideConfirmOption = !validationData.warning.canWarningBeOverruled;
        const titleTranslationKeySuffix = hideConfirmOption
            ? 'header_not_allowed'
            : 'header';

        function confirmEmployee() {
            onConfirm();
            resetValidateEmployeeToPlan();
        }

        return (
            <WarningDialog
                show={true}
                onCancel={resetValidateEmployeeToPlan}
                onConfirm={confirmEmployee}
                hideConfirmOption={hideConfirmOption}
                confirmTranslationKey={`${TRANSLATION_PREFIX}.confirm`}
                cancelTranslationKey={`${TRANSLATION_PREFIX}.cancel`}
                title={`${TRANSLATION_PREFIX}.${titleTranslationKeySuffix}`}
                showLoader={false}
            >
                <>
                    {validationData.warning.hasAlreadyPlannedMedicalExamination && (
                        <>
                            <p><Translate msg={`${TRANSLATION_PREFIX}.already_has_planned_examination`} /></p>
                            <div>
                                {validationData.warning.plannedMedicalExaminations.map(
                                    (examination) => (
                                        <ListItem
                                            key={examination.activityId}
                                            text={formatDateWithTimeForDisplay(examination.start)}
                                            title={examination.examinationReason.description}
                                        />
                                    ),
                                )}
                            </div>
                        </>
                    )}
                    {validationData.warning.isNotToBePlanned && (
                        <p><Translate msg={`${TRANSLATION_PREFIX}.not_subject_to_examination`} /></p>
                    )}
                </>
            </WarningDialog>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            validationData: getValidateEmployeeToPlanData(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            resetValidateEmployeeToPlan: () => {
                dispatch(validateEmployeeToPlanActions.reset({}));
            },
        };
    },
})(ValidateEmployeeToPlanDialog);
