import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Document

const getDocumentAsyncField = (state: IState) =>
    reducerState(state).document;

export const getDocumentAsyncInfo = makeAsyncFetchInfoSelector(getDocumentAsyncField);

export const getDocument = (state: IState) =>
    getDocumentAsyncField(state).data;

// Create convocations and send emails or download

const getCreateConvocationsAsyncField = (state: IState) =>
    reducerState(state).createConvocations;

export const getCreateConvocationsAsyncInfo =
    makeAsyncDoInfoSelector(getCreateConvocationsAsyncField);

// Upload Employee Document

const getUploadEmployeeDocumentAsyncField = (state: IState) =>
    reducerState(state).uploadEmployeeDocument;

export const getUploadEmployeeDocumentAsyncInfo =
    makeAsyncDoInfoSelector(getUploadEmployeeDocumentAsyncField);

// Convocation recipients

const getConvocationRecipientsAsyncField = (state: IState) =>
    reducerState(state).convocationRecipients;

export const getConvocationRecipientsAsyncInfo = makeAsyncFetchInfoSelector(getDocumentAsyncField);

export const getConvocationRecipients = (state: IState) =>
    getConvocationRecipientsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
