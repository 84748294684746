import { object } from 'yup';
import number from '../../../../../utils/libs/yup/number';

export const fields = {
    medicalCenterId: 'medicalCenterId',
};

export const schema = object({
    [fields.medicalCenterId]: number().required(),
});
