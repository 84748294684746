// Keep in sync with _z-index.scss
// Uses overlay value
const START_Z_INDEX = 500;

export enum Z_INDEX_TYPE {
    OVERLAY = 'OVERLAY',
    POPPER = 'POPPER',
    DEV = 'DEV',
}

interface IZIndexItem {
    type: Z_INDEX_TYPE;
    zIndex: number;
    onChangesOfSameType?: (otherZIndexesOfSameType: number[]) => void;
}

const indexesInUse: IZIndexItem[] = [];

export function getZIndex(
    zIndexType: Z_INDEX_TYPE,
    onChangesOfSameType?: (otherZIndexesOfSameType: number[]) => void,
) {
    const lastItem = indexesInUse.slice().pop();
    const lastIndex = lastItem ? lastItem.zIndex : START_Z_INDEX;
    const newZIndex = lastIndex + 1;
    const newItem = { type: zIndexType, zIndex: newZIndex, onChangesOfSameType };
    indexesInUse.push(newItem);

    triggerCallbacksForOtherItemsWithSameType(newItem);

    return newZIndex;
}

export function releaseZIndex(zIndex: number) {
    const item = indexesInUse.find((item) => item.zIndex === zIndex);
    const index = indexesInUse.indexOf(item);
    if (index > -1) {
        const type = item && item.type;
        indexesInUse.splice(index, 1);
        triggerCallbacksForOtherItemsWithSameType({ zIndex, type });
    }
}

function triggerCallbacksForOtherItemsWithSameType(itemThatCausedChange: IZIndexItem) {
    const { type, zIndex } = itemThatCausedChange;
    const itemsWithSameType = indexesInUse.filter((item) => item.type === type);

    const itemsWithCallbacks = itemsWithSameType.filter((item) => {
        return typeof item.onChangesOfSameType === 'function' && item.zIndex !== zIndex;
    });

    itemsWithCallbacks.forEach((itemWithCallback) => {
        const otherZIndexesOfSameType = mapZIndexItemsToZIndexNumbers(
            itemsWithSameType.filter((item) => item.zIndex !== itemWithCallback.zIndex),
        );
        itemWithCallback.onChangesOfSameType(otherZIndexesOfSameType);
    });
}

function mapZIndexItemsToZIndexNumbers(items: IZIndexItem[]) {
    return items.reduce(
        (accumulator, item) => {
            accumulator.push(item.zIndex);
            return accumulator;
        },
        [] as number[],
    );
}
