import React from 'react';
import Checkbox from '../Checkbox';
import Typeahead, { ITypeaheadDataItem } from '../Typeahead';
import FloatableTextInputWrapper from '../../forms/FloatableTextInputWrapper';
import Translate from '../../Translate';
import { addOrRemoveStringListValue, separateStringList } from '../../../../utils/core/string/separatedStringList';
import { TDropdownItemValue } from '../Dropdown';

interface IPublicProps {
    filterName: string;
    labelTranslationKey: string;
    possibleFilterItems: ITypeaheadDataItem[];
    actualFilterValue: string;
    onChange: (newFilterValue: string) => void;
    showCheckboxesUptoThisValuesLength?: number;
    filterItemTranslationKey?: string;
}

export default function CheckboxesOrTypeaheadFilter(props: IPublicProps) {
    const {
        filterName,
        labelTranslationKey,
        possibleFilterItems,
        actualFilterValue,
        onChange,
        filterItemTranslationKey,
        showCheckboxesUptoThisValuesLength = 5,
    } = props;

    const showCheckboxes = possibleFilterItems.length <= showCheckboxesUptoThisValuesLength;

    const selectedFilterValues = separateStringList(actualFilterValue);

    if (possibleFilterItems.length === 0) {
        return null;
    }

    if (showCheckboxes) {
        return (
            <>
                <h6><Translate msg={labelTranslationKey} /></h6>
                {possibleFilterItems.sort(sortByLabel).map((filterItem) => {
                    const filterItemValue = filterItem.value ? filterItem.value.toString() : '';
                    return (
                        <Checkbox
                            key={`filter-${filterName}-${filterItemValue}`}
                            name={`filter_${filterName}_${filterItemValue}`}
                            checked={selectedFilterValues.includes(filterItemValue)}
                            onChange={(e) => onChange(
                                addOrRemoveStringListValue(
                                    actualFilterValue,
                                    filterItemValue,
                                    e.target.checked,
                                ),
                            )}
                        >
                            {!!filterItemTranslationKey
                                ? <Translate msg={`${filterItemTranslationKey}.${filterItem.label}`} />
                                : filterItem.label}
                        </Checkbox>
                    );
                })}
            </>
        );
    }

    const onlyFirstFilterValue = (selectedFilterValues.length > 1) ?
        selectedFilterValues[0] : actualFilterValue;

    return (
        <FloatableTextInputWrapper floatLabel>
            <Typeahead
                id={`filter_${filterName}`}
                name={filterName}
                value={onlyFirstFilterValue || ''}
                data={possibleFilterItems}
                onItemSelected={(value) => onChange(value && value.toString())}
            >
                <label htmlFor={`filter_${filterName}`}>
                    <Translate msg={labelTranslationKey} />
                </label>
            </Typeahead>
        </FloatableTextInputWrapper>
    );
}

function sortByLabel(a: ITypeaheadDataItem<TDropdownItemValue>, b: ITypeaheadDataItem<TDropdownItemValue>) {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
}
