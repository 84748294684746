import React, { PureComponent, CSSProperties } from 'react';
import classNames from 'classnames';
import './toast.scss';
import CloseIcon from '../../icons/CloseIcon';
import Portal from '../../technical/Portal';
import getOrCreateElementInBodyById from '../../../../utils/dom/getOrCreateElementInBodyById';
import { TOAST_CONTAINER_ELEMENT_ID, ROOT_ELEMENT_ID } from '../../../../config/dom.config';
import HideAfterDelay from '../../waiting/HideAfterDelay';

interface IToastProps {
    show: boolean;
    autoHide: boolean;
    autoHideDuration?: number;
    height?: number;
    children: React.ReactNode;
    onCloseIntent?: () => void;
}

const CLASS_NAME = 'Toast';
const DEFAULT_HEIGHT = 78;
const END_ZINDEX = 499; // Keep in sync with _z-index.scss

const TOAST_CONTAINER_ELEMENT = getOrCreateElementInBodyById(TOAST_CONTAINER_ELEMENT_ID);
const ROOT_ELEMENT = getOrCreateElementInBodyById(ROOT_ELEMENT_ID);

let toastsCreated = 0;

export default class Toast extends PureComponent<IToastProps> {
    private id: string;
    private zIndex: number;

    constructor(props: IToastProps) {
        super(props);
        toastsCreated += 1;
        this.id = `Toast-${toastsCreated}`;
        this.zIndex = END_ZINDEX - toastsCreated;
    }

    public render() {
        const { show, onCloseIntent, children, height, autoHide, autoHideDuration } = this.props;

        const classes = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--open`]: show,
        });

        const styles: CSSProperties = {};

        const toastHeight = height || DEFAULT_HEIGHT;
        styles.height = toastHeight;
        styles.maxHeight = toastHeight;
        styles.zIndex = this.zIndex;
        if (!show) {
            styles.marginTop = -toastHeight;
        } else {
            styles.top = this.getToastTopPosition();
        }

        return (
            <HideAfterDelay enabled={autoHide} hideDelay={autoHideDuration} onHide={onCloseIntent}>
                <Portal domNode={TOAST_CONTAINER_ELEMENT}>
                    <div id={this.id} className={classes} style={styles}>
                        <CloseIcon onClick={onCloseIntent} />
                        {children}
                    </div>
                </Portal>
            </HideAfterDelay>
        );
    }

    public componentDidMount() {
        const { show } = this.props;
        if (show) {
            this.moveMainAppUpOrDown();
            this.resetTopPositionOtherToastComponents();
        }
    }

    public componentDidUpdate(prevProps: IToastProps) {
        if (this.props.show !== prevProps.show) {
            this.moveMainAppUpOrDown();
            this.resetTopPositionOtherToastComponents();
        }
    }

    public componentWillUnmount() {
        this.moveMainAppUpOrDown(true);
        this.resetTopPositionOtherToastComponents();
    }

    private moveMainAppUpOrDown(isBeingUnmounted: boolean = false) {
        const totalHeight = this.getTotalHeightToastComponents();
        const toastHeight = this.props.height || DEFAULT_HEIGHT;
        ROOT_ELEMENT.style.paddingTop = `${isBeingUnmounted ? totalHeight - toastHeight : totalHeight}px`;
    }

    private getToastTopPosition() {
        const openToastElements = TOAST_CONTAINER_ELEMENT.querySelectorAll(`.${CLASS_NAME}`);
        let top = 0;
        for (let i = 0, length = openToastElements.length; i < length; i += 1) {
            const el = openToastElements[i] as HTMLElement;
            if (el.getAttribute('id') === this.id) {
                break;
            }
            if (el.classList.contains(`${CLASS_NAME}--open`)) {
                top += parseInt(el.style.height, 10);
            }
        }
        return top;
    }

    private getTotalHeightToastComponents() {
        const openToastElements = TOAST_CONTAINER_ELEMENT.querySelectorAll(`.${CLASS_NAME}--open`);
        let totalHeight = 0;
        for (let i = 0, length = openToastElements.length; i < length; i += 1) {
            const el = openToastElements[i] as HTMLElement;
            totalHeight += parseInt(el.style.height, 10);
        }
        return totalHeight;
    }

    private resetTopPositionOtherToastComponents() {
        // React does not update other toast components when this instance closes or opens
        const openToastElements = TOAST_CONTAINER_ELEMENT.querySelectorAll(`.${CLASS_NAME}--open`);
        let totalHeightPreviousToasts = 0;
        for (let i = 0, length = openToastElements.length; i < length; i += 1) {
            const el = openToastElements[i] as HTMLElement;
            if (el.getAttribute('id') !== this.id) {
                el.style.top = `${totalHeightPreviousToasts}px`;
            }
            totalHeightPreviousToasts += parseInt(el.style.height, 10);
        }
    }
}
