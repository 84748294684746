import closest from './closest';
import { SCROLL_CONTAINER_ATTRIBUTE } from '../../config/dom.config';

export default function findClosestScrollContainer(target: HTMLElement) {
    if (target) {
        const scrollContainer = closest(target, (el: HTMLElement) => {
            const isHtmlElement = typeof el.getAttribute === 'function';
            return isHtmlElement && !!el.getAttribute(SCROLL_CONTAINER_ATTRIBUTE);
        });
        return (scrollContainer as HTMLElement) || window;
    }
    return null;
}
