import { object } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../models/ui/form';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    breastfeedingAdjustment: 'breastfeedingAdjustment',
    breastfeedingRemovalPeriod: 'breastfeedingRemovalPeriod',
    pregnantAdjustment: 'pregnantAdjustment',
    pregnantRemovalPeriod: 'pregnantRemovalPeriod',
};

export const schema = object({
    [fields.breastfeedingAdjustment]: string(),
    [fields.breastfeedingRemovalPeriod]: string(),
    [fields.pregnantAdjustment]: string(),
    [fields.pregnantRemovalPeriod]: string(),
});
