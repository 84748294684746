import React from 'react';

import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import { formatLocationAddressWithoutName } from '../../../../../../utils/formatting/formatAddress';
import { formatStartEndTimeOfDatesForDisplay } from '../../../../../../utils/formatting/formatTime';
import { hoursOffsetFromNow, getDate } from '../../../../../../utils/core/date/getSpecificDate';
import { IReservedMedicalExamination } from '../../../../../../models/interventions/medicalExaminations/reserved';
import { ListItem } from '../../../../../../models/general/list';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../../config/medicalExamination.config';
import Icon from '../../../../../common/icons/Icon';
import TooltipWithIcon from '../../../../../common/widget/TooltipWithIcon';
import Translate from '../../../../../common/Translate';

import { ISelectBufferzoneOverviewColumnNames } from './SelectBufferzoneOverview.type';
import { TRANSLATION_PREFIX } from './SelectBufferzoneOverview.const';

export const renderWarningTooltip = (item: ListItem<ISelectBufferzoneOverviewColumnNames>) => {
    const warningIcon = (
        <Icon typeName="warning" circle colorType="warning" />
    );

    const startsBeforePlanningDeadline =
        hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
        getDate(item.columns.startTime as string);

    if (!startsBeforePlanningDeadline) {
        return <span />;
    }

    return (
        <TooltipWithIcon icon={warningIcon} tooltipBubbleIcon={warningIcon} typeName="info-bubble">
            <div>
                <Translate
                    msg={`${TRANSLATION_PREFIX}.warning_tooltip`}
                    placeholders={{
                        hours: NR_OF_HOURS_BEFORE_EXAM_ALLOWED,
                    }}
                />
            </div>
        </TooltipWithIcon>
    );
};

export const mapBufferzonesToListItems = (
    masterData: IReservedMedicalExamination[],
): ListItem<ISelectBufferzoneOverviewColumnNames>[] => {

    return masterData
    .map((bufferzone) => {
        const address = formatLocationAddressWithoutName(bufferzone.location.address, true);

        return {
            id: bufferzone.activity.id,
            columns: {
                address,
                date: formatDateInLongFormat(bufferzone.activity.start),
                dateSort: bufferzone.activity.start,
                endTime: bufferzone.activity.end,
                freeSlots: bufferzone.availability.plannableTimeSlots,
                location: bufferzone.location.name,
                locationId: bufferzone.location.name,
                seat: bufferzone.branch.name,
                seatCode: bufferzone.branch.code,
                startTime: bufferzone.activity.start,
                time: formatStartEndTimeOfDatesForDisplay(bufferzone.activity.start, bufferzone.activity.end),
                totalSlots: bufferzone.availability.totalTimeSlots,
                warning: null,
            },
        };
    });
};
