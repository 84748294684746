const TYPE_SUFFIX = {
    SUCCEEDED: '_SUCCEEDED',
    FAILED: '_FAILED',
    CANCELLED: '_CANCELLED',
    RESET: '_RESET',
};

const TYPE_REGEX = {
    SUCCEEDED: new RegExp(`.*${TYPE_SUFFIX.SUCCEEDED}$`),
};

export function toSuccessType(baseType) {
    return `${baseType}${TYPE_SUFFIX.SUCCEEDED}`;
}

export function toFailType(baseType) {
    return `${baseType}${TYPE_SUFFIX.FAILED}`;
}

export function toCancelType(baseType) {
    return `${baseType}${TYPE_SUFFIX.CANCELLED}`;
}

export function toResetType(baseType) {
    return `${baseType}${TYPE_SUFFIX.RESET}`;
}

export function isSuccessType(actionType: string) {
    return actionType.match(TYPE_REGEX.SUCCEEDED) !== null;
}
