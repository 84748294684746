import React, { Component } from 'react';
import './resumption-not-absent-dialog.scss';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { connect } from '../../../../../index';
import Button from '../../../../../common/buttons/Button';
import Translate from '../../../../../common/Translate';
import Dialog from '../../../../../common/modals/Dialog';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';

interface IPrivateProps {
    goToPlanMedicalExamination: () => void;
}

interface IPublicProps {
    show: boolean;
    onCloseDialog: () => void;
}

class ResumptionNotAbsentDialog extends Component<IPrivateProps & IStepperStepRenderProps & IPublicProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            show, onCloseDialog,
            goToPlanMedicalExamination,
         } = this.props;

        return (
            <Dialog
                show={show}
                onCloseIntent={onCloseDialog}
                header="interventions.medical_examinations.new.steps.resumption_absence.dialog.title"
            >
                <div className="ResumptionNotAbsentDialog">
                    <p>
                        <Translate
                            msg="interventions.medical_examinations.new.steps.resumption_absence.dialog.message"
                        />
                    </p>
                    <div className="Dialog__buttons">
                        <Button
                            id="plan-examination-reason-automatically"
                            typeName="secondary"
                            onClick={() => goToPlanMedicalExamination()}
                        >
                            <Translate
                                msg="interventions.medical_examinations.new.steps.resumption_absence.dialog.action"
                            />
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    dispatchProps: (dispatch) => {
        return {
            goToPlanMedicalExamination: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
            },
        };
    },
})(ResumptionNotAbsentDialog);
