import React, { PureComponent, MouseEvent } from 'react';
import isEmptyString from '@snipsonian/core/es/string/isEmptyString';
import './edit-absences.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import {
    IUpdateEmployeeAbsencePayload,
    IEmployeeAbsence,
    IRemoveEmployeeAbsencePayload,
} from '../../../../../models/admin/employee';
import { fields, schema } from './updateAbsenceSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee,
    getRemoveEmployeeAbsenceAsyncInfo,
    getSelectedEmployeeAbsencesAsyncInfo,
    getSelectedEmployeeAbsences,
    getUpdateEmployeeAbsenceAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import {
    formatDateForBackend,
    formatDateForDisplay,
} from '../../../../../utils/formatting/formatDate';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import {
    removeEmployeeAbsence, updateEmployeeAbsence,
} from '../../../../../redux/employee/info/actions';
import Icon from '../../../../common/icons/Icon';
import AddAbsence from '../AddAbsence';
import ConfirmationDialog from '../../../../common/modals/ConfirmationDialog';
import { getAbsenceDescription } from '../../../../../config/constants';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../models/general/constants';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import RequiredMarker from '../../../../common/input/RequiredMarker';

type TValues = Pick<IUpdateEmployeeAbsencePayload, 'personalId' | 'riskCode' | 'startDate' | 'endDate'>;
type TRemoveValues = Pick<IRemoveEmployeeAbsencePayload, 'personalId' | 'riskCode' | 'startDate'>;

interface IPrivateProps {
    onUpdateAbsence: (values: TValues) => void;
    onRemoveAbsence: (values: TRemoveValues) => void;
    translator: ITranslator;
    employeeName: string;
    absences: IEmployeeAbsence[];
    isDetailsLoaded: boolean;
    updateAsyncInfo: IAsyncFieldInfo;
    removeAsyncInfo: IAsyncFieldInfo;
    fetchAbsencesAsyncInfo: IAsyncFieldInfo;
    clearPreviousErrors: () => void;
}

interface IEditAbsencesProps {
    onClose: () => void;
}

interface IState {
    isAddAbsenceOpen: boolean;
    isConfirmationDialogOpen: boolean;
    absenceToRemove: IEmployeeAbsence;
}

const CLASS_NAME = 'EditAbsences';

class EditAbsences extends PureComponent<IPrivateProps & IEditAbsencesProps, IState> {
    private resetLastSubmittedForm: (values: TValues) => void;
    private activeAbsencePersonalId: string;

    constructor(props) {
        super(props);

        this.state = {
            isAddAbsenceOpen: false,
            isConfirmationDialogOpen: false,
            absenceToRemove: null,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onNewClick = this.onNewClick.bind(this);
    }

    public render() {
        const {
            isDetailsLoaded, employeeName, absences,
            updateAsyncInfo, removeAsyncInfo, fetchAbsencesAsyncInfo,
        } = this.props;
        const { isConfirmationDialogOpen, absenceToRemove } = this.state;

        if (!isDetailsLoaded) {
            return null;
        }

        if (this.state.isAddAbsenceOpen) {
            return <AddAbsence onClose={() => this.setState({ isAddAbsenceOpen: false })} />;
        }

        return (
            <div className={CLASS_NAME}>
                <Loader
                    show={
                        updateAsyncInfo.status === AsyncStatus.Busy ||
                        removeAsyncInfo.status === AsyncStatus.Busy ||
                        fetchAbsencesAsyncInfo.status === AsyncStatus.Busy
                    }
                    showImmediatelly={true}
                />
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.edit_absences.title'}
                            placeholders={{ name: employeeName }}
                        />
                    </h2>
                </header>
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="edit-absences-new-button"
                        typeName="secondary"
                        onClick={this.onNewClick}
                    >
                        <Icon typeName="plus-circle" />
                        <span><Translate msg="administration.employees.edit_absences.new" /></span>
                    </Button>
                </div>
                <div className={`${CLASS_NAME}__content`}>
                    {absences.map((absence, index) => {
                        return (
                            <div key={`form-${absence.personalId}`} className={`${CLASS_NAME}__form`}>
                                {index === 0 && <hr />}
                                {this.renderAbsenceForm(absence, index)}
                                <hr />
                            </div>
                        );
                    })}
                    {absences.length === 0 &&
                        <div className={`${CLASS_NAME}__form`}>
                            <Translate
                                msg="administration.employees.edit_absences.no_absences"
                            />
                        </div>
                    }
                </div>
                <div className={`${CLASS_NAME}__close`}>
                    <Button
                        id="edit-absences-close-button"
                        typeName="primary"
                        onClick={this.onCloseClick}
                        size="small"
                    >
                        <Translate
                            msg="administration.employees.edit_absences.close"
                        />
                    </Button>
                </div>
                <ConfirmationDialog
                    show={isConfirmationDialogOpen}
                    onCancel={() => this.setState({ isConfirmationDialogOpen: false })}
                    onConfirm={() => {
                        this.setState({ isConfirmationDialogOpen: false });
                        this.props.onRemoveAbsence({
                            personalId: absenceToRemove.personalId,
                            startDate: absenceToRemove.beginDate,
                            riskCode: absenceToRemove.code,
                        });
                    }}
                >
                    <Translate
                        msg="administration.employees.edit_absences.confirm_delete"
                        placeholders={{
                            type: absenceToRemove && getAbsenceDescription(absenceToRemove.code),
                        }}
                    />
                </ConfirmationDialog>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.fetchAbsencesAsyncInfo.status === AsyncStatus.Success &&
            prevProps.fetchAbsencesAsyncInfo.status === AsyncStatus.Busy
        ) {
            // Reset the form so the dirty flag is resetted
            const updatedAbsence = this.props.absences
                .find((absence) => absence.personalId === this.activeAbsencePersonalId);
            if (updatedAbsence) {
                this.resetLastSubmittedForm(getAbsenceInitialValues(updatedAbsence));
            }
        }
    }

    private renderAbsenceForm(absence: IEmployeeAbsence, index: number) {
        const {
            translator, onUpdateAbsence,
            updateAsyncInfo, removeAsyncInfo,
        } = this.props;

        const formName = `edit-absences-form-${index}`;
        const initialValues = getAbsenceInitialValues(absence);

        const namePlaceholder = translator('administration.employees.edit_absences.form.name');
        const startDatePlaceholder = translator('administration.employees.edit_absences.form.start_date');
        const endDatePlaceholder = translator('administration.employees.edit_absences.form.end_date');

        const formError = updateAsyncInfo.error || removeAsyncInfo.error;
        const isErrorForCurrentForm = formError && formError.extraData.personalId === absence.personalId;
        return (
            <Form
                name={formName}
                handleSubmit={(values: TValues) => {
                    this.activeAbsencePersonalId = absence.personalId;
                    onUpdateAbsence({
                        personalId: values.personalId,
                        riskCode: values.riskCode,
                        startDate: values.startDate,
                        ...!isEmptyString(values.endDate) && { endDate: values.endDate },
                    } as TValues);
                }}
                initialValues={initialValues}
                schema={schema}
                render={({
                    values, touched, errors, setFieldValue, resetForm,
                }: IFormRenderProps<IUpdateEmployeeAbsencePayload>) => {
                    this.resetLastSubmittedForm = resetForm;
                    return (
                        <>
                            <FloatableTextInputWrapper>
                                <ConstantsTypeahead
                                    id={`edit-absence-name-${index}`}
                                    constantType={ConstantType.ABSENCES}
                                    name={fields.riskCode}
                                    placeholder={namePlaceholder}
                                    value={values.riskCode}
                                    onItemSelected={(value) => setFieldValue('riskCode', value)}
                                    isInvalid={touched.riskCode && !!errors.riskCode}
                                >
                                    <label htmlFor={`edit-absence-name-${index}`}>
                                        <Translate
                                            msg="administration.employees.edit_absences.form.name"
                                        />
                                        <RequiredMarker />
                                    </label>
                                </ConstantsTypeahead>
                                {touched.riskCode && (
                                    <FormFieldError
                                        error={errors.riskCode}
                                        placeholders={{ fieldName: namePlaceholder }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper>
                                <DatePicker
                                    id={`edit-absence-start-date-${index}`}
                                    placeholder={startDatePlaceholder}
                                    value={values.startDate}
                                    name={fields.startDate}
                                    onChange={(date) => setFieldValue('startDate', date)}
                                    isInvalid={touched.startDate && !!errors.startDate}
                                >
                                    <label htmlFor={`edit-absence-start-date-${index}`}>
                                        <Translate
                                            msg="administration.employees.edit_absences.form.start_date"
                                        />
                                        <RequiredMarker />
                                    </label>
                                </DatePicker>
                                {touched.startDate && (
                                    <FormFieldError
                                        error={errors.startDate}
                                        placeholders={{ fieldName: startDatePlaceholder }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper>
                                <DatePicker
                                    id={`edit-absence-end-date-${index}`}
                                    placeholder={endDatePlaceholder}
                                    value={values.endDate}
                                    name={fields.endDate}
                                    onChange={(date) => setFieldValue('endDate', date)}
                                    isInvalid={touched.endDate && !!errors.endDate}
                                    minDate={values.startDate}
                                >
                                    <label htmlFor={`edit-absence-end-date-${index}`}>
                                        <Translate
                                            msg="administration.employees.edit_absences.form.end_date"
                                        />
                                    </label>
                                </DatePicker>
                                {touched.endDate && (
                                    <FormFieldError
                                        error={errors.endDate}
                                        placeholders={{
                                            fieldName: endDatePlaceholder,
                                            minValue: formatDateForDisplay(values.startDate),
                                        }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FormError error={isErrorForCurrentForm && formError} />
                            <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                <Button
                                    id={`edit-absence-delete-button-${index}`}
                                    typeName="secondary"
                                    outline={true}
                                    onClick={(e) => this.onRemoveClicked(e, absence)}
                                >
                                    <Icon typeName="bin" />
                                    <Translate
                                        msg="administration.employees.edit_absences.form.delete"
                                    />
                                </Button>
                                <SubmitButton
                                    id={`edit-absence-submit-button-${index}`}
                                    formName={formName}
                                >
                                    <Translate
                                        msg="administration.employees.edit_absences.form.submit"
                                    />
                                </SubmitButton>
                            </div>
                        </>
                    );
                }}
            />
        );
    }

    private onRemoveClicked(
        e: MouseEvent<HTMLButtonElement>,
        absence: IEmployeeAbsence,
    ) {
        e.preventDefault();
        this.setState({
            isConfirmationDialogOpen: true,
            absenceToRemove: absence,
        });
    }

    private onCloseClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearPreviousErrors } = this.props;
        clearPreviousErrors();
        onClose();
    }

    private onNewClick() {
        this.setState({
            isAddAbsenceOpen: true,
        });
    }
}

export default connect<IPrivateProps, IEditAbsencesProps>({
    stateProps: (state) => {
        const selectedEmployee = getSelectedEmployee(state);
        const updateAsyncInfo = getUpdateEmployeeAbsenceAsyncInfo(state);
        const removeAsyncInfo = getRemoveEmployeeAbsenceAsyncInfo(state);
        const fetchAbsencesAsyncInfo = getSelectedEmployeeAbsencesAsyncInfo(state);
        return {
            isDetailsLoaded: !!selectedEmployee,
            translator: getTranslatorDeprecated(state),
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            absences: getSelectedEmployeeAbsences(state) || [],
            updateAsyncInfo,
            removeAsyncInfo,
            fetchAbsencesAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        const clearPreviousErrors = () => {
            const state = getState();
            const updateAsyncInfo = getUpdateEmployeeAbsenceAsyncInfo(state);
            const removeAsyncInfo = getRemoveEmployeeAbsenceAsyncInfo(state);

            const errorIds = [];
            if (updateAsyncInfo.error) {
                errorIds.push(updateAsyncInfo.error.id);
            }
            if (removeAsyncInfo.error) {
                errorIds.push(removeAsyncInfo.error.id);
            }
            dispatch(clearErrors(errorIds));
        };
        return {
            onUpdateAbsence: (values) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);
                clearPreviousErrors();
                dispatch(updateEmployeeAbsence({
                    ...values,
                    id: selectedEmployee.id,
                }));
            },
            onRemoveAbsence: (values) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);
                clearPreviousErrors();
                dispatch(removeEmployeeAbsence({
                    ...values,
                    id: selectedEmployee.id,
                }));
            },
            clearPreviousErrors,
        };
    },
})(EditAbsences);

function getAbsenceInitialValues(absence: IEmployeeAbsence): TValues {
    return {
        riskCode: absence.code,
        startDate: absence.beginDate || formatDateForBackend(new Date()),
        endDate: absence.endDate || '',
        personalId: absence.personalId,
    };
}
