import React, { PureComponent, ReactElement } from 'react';
import { ITabProps } from './tab';
import './tabs.scss';
import { isArray } from 'util';
import { ReactNode } from 'react-redux';

interface ITabsProps {
    children: ReactElement<ITabProps>[];
}

interface IState {
    activeTabIndex: number;
}

const CLASS_NAME = 'Tabs';

export default class Tabs extends PureComponent<ITabsProps, IState> {
    constructor(props: ITabsProps) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };

        this.handleTabClick = this.handleTabClick.bind(this);
    }

    private renderChildrenWithTabsApiAsProps() {
        return React.Children.map(this.props.children, (child: ReactElement<ITabProps>, index) => {
            return React.cloneElement(child, {
                onClick: this.handleTabClick,
                tabIndex: index,
                isActive: index === this.state.activeTabIndex,
            });
        });
    }

    private renderActiveTabContent() {
        if (this.state.activeTabIndex !== undefined) {
            const { children } = this.props;
            const { activeTabIndex } = this.state;

            if (children) {
                if (!isArray(children)) {
                    const child = children as ReactElement<{ children: ReactNode }>;
                    return child.props.children;
                }

                if (children[activeTabIndex]) {
                    return children[activeTabIndex].props.children;
                }
            }
        }

        return null;
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                <nav className={`${CLASS_NAME}__nav`}>
                    {this.renderChildrenWithTabsApiAsProps()}
                </nav>
                <div className={`${CLASS_NAME}__tab-content`}>
                    {this.renderActiveTabContent()}
                </div>
           </div>
        );
    }

    private handleTabClick(tabIndex: number) {
        this.setState({
            activeTabIndex: tabIndex === this.state.activeTabIndex ? this.state.activeTabIndex : tabIndex,
        });
    }
}
