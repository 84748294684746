import {
    init,
    Severity, User,
    captureException, captureMessage, addBreadcrumb,
    configureScope, withScope, Scope,
} from '@sentry/browser';
import isArray from '@snipsonian/core/es/is/isArray';
import { IUserProfile } from '../../models/auth/authentication';
import {
    TErrorReportLogLevel,
    RELEASE_BUILD,
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    SHOULD_REPORT_CURRENT_ENV,
} from '../../config/errorReporting.config';
import removeSensitiveData from './removeSensitiveData';

let isInitialized = false;

interface ILogOptions {
    level?: TErrorReportLogLevel;
    extra?: object;
    groupingFingerprint?: TGroupingFingerprint;
}

export type TGroupingFingerprint = string[];

export function initErrorReporting() {
    init({
        dsn: SENTRY_DSN,
        release: RELEASE_BUILD,
        environment: SENTRY_ENVIRONMENT,
        enabled: SHOULD_REPORT_CURRENT_ENV,
    });

    isInitialized = true;
}

export function setUserContext(userInfo: IUserProfile) {
    if (!shouldLog()) {
        return;
    }
    /* do not set the username for GDPR reasons */
    if (userInfo && userInfo.userId) {
        const userIdWithoutName = userInfo.userId.replace(userInfo.username, '****');

        setSentryUser({
            user: {
                ...getPublicUserData(userInfo),
                id: userIdWithoutName,
            },
        });
    }
}

export function removeUserContext() {
    if (!shouldLog()) {
        return;
    }
    setSentryUser({ user: null });
}

export function reportError(
    e: Error,
    {
        level = 'error',
        extra,
        groupingFingerprint,
    }: ILogOptions = {},
) {
    if (shouldLog()) {
        const error = removeSensitiveData(e) as Error;
        withScope((scope) => {
            scope.setLevel(toSeverity(level));
            scope.setExtras({ extra: removeSensitiveData(extra) });
            overrideDefaultEventGroupingIfSet({ scope, groupingFingerprint });
            captureException(error);
        });
    }
}

export function reportErrorMessage(
    msg: string,
    {
        level = 'error',
        extra,
        groupingFingerprint,
    }: ILogOptions = {},
) {
    if (shouldLog()) {
        withScope((scope) => {
            scope.setLevel(toSeverity(level));
            scope.setExtras({ extra: removeSensitiveData(extra) });
            overrideDefaultEventGroupingIfSet({ scope, groupingFingerprint });
            captureMessage(msg);
        });
    }
}

interface ILogInfoOptions extends ILogOptions {
    category?: string;
}

export function reportInfo(
    message: string,
    {
        category,
        level = 'info',
        extra,
    }: ILogInfoOptions = {},
) {
    if (shouldLog()) {
        addBreadcrumb({
            message,
            category,
            level: toSeverity(level),
            data: { extra: removeSensitiveData(extra) },
        });
    }
}

function setSentryUser({ user }: { user: User }) {
    configureScope((scope) => {
        scope.setUser(user);
    });
}

function shouldLog(): boolean {
    return isInitialized && SHOULD_REPORT_CURRENT_ENV;
}

function getPublicUserData(userInfo: IUserProfile) {
    /* do not set the login/username for GDPR reasons */
    return {
        accessLevel: userInfo.accessLevel ?
            Object.keys(userInfo.accessLevel).map((key) => `${key}: ${userInfo.accessLevel[key]}`).join(', ') : '',
        companyIds: Array.isArray(userInfo.companies) ? userInfo.companies.map((company) => company.id).join(', ') : '',
    };
}

function toSeverity(level: TErrorReportLogLevel): Severity {
    switch (level) {
        case 'critical':
            return Severity.Critical;
        case 'error':
            return Severity.Error;
        case 'warning':
            return Severity.Warning;
        case 'info':
            return Severity.Info;
        case 'debug':
            return Severity.Debug;

        default:
            return Severity.Log;
    }
}

function overrideDefaultEventGroupingIfSet({
    scope,
    groupingFingerprint,
}: {
    scope: Scope;
    groupingFingerprint: TGroupingFingerprint;
}) {
    if (isArray(groupingFingerprint)) {
        scope.setFingerprint(groupingFingerprint);
    }
}
