import React from 'react';
import { pathOr } from 'ramda';

import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { IBufferzoneMedicalCenter } from '../../../../../models/admin/companyInfo';
import { ILocationTypeCodes } from '../../../../../models/general/location';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../../../config/planning.config';
import { IReservedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/reserved';
import { TTypeaheadData } from '../../../../common/input/Typeahead';

import { TLocationTypeaheadItems } from './LocationTypeahead.type';

export function mapLocationsForLocationTypeahead(medicalCenters: TLocationTypeaheadItems): TTypeaheadData {
    return Array.isArray(medicalCenters) ? medicalCenters.map((item) => {
        return {
            value: item.code,
            label: item.name,
            dropdownItemContent: item.name && (
                <>
                    <strong>{item.name}</strong><br />
                    {item.address && formatAddress(item.address)}
                </>
            ),
        };
    }) : [];
}

export const mapBufferzoneToBufferzoneMedicalCenter = (
    bufferzone: IReservedMedicalExamination,
): IBufferzoneMedicalCenter | null => {
    if (!bufferzone.location) {
        return null;
    }

    const code = pathOr(
        bufferzone.location.typeCode === ILocationTypeCodes.CUSTOMER
            ? IN_COMPANY_MEDICAL_CENTER_CODE
            : null,
        ['location', 'code'],
        bufferzone,
    );
    const name = pathOr('', ['location', 'name'], bufferzone);

    return {
        id: bufferzone.id,
        code,
        name,
        address: {
            postcode: bufferzone.location.address.zipCode,
            number: bufferzone.location.address.houseNumber,
            city: bufferzone.location.address.city,
            street: bufferzone.location.address.street,
            box: bufferzone.location.address.box,
        },
        prefered: false,
        hasCabinets: false,
    };
};

export const mapBufferzoneMainCenterToBufferzoneMedicalCenter = (
    bufferzone: IReservedMedicalExamination,
): IBufferzoneMedicalCenter | null => {
    if (!bufferzone.location || !bufferzone.location.mainCenter) {
        return null;
    }

    const code = pathOr(
        null,
        ['location', 'mainCenter', 'code'],
        bufferzone,
    );
    const name = pathOr('', ['location', 'mainCenter', 'name'], bufferzone);

    return {
        id: bufferzone.id,
        code,
        name,
        address: {
            postcode: bufferzone.location.address.zipCode,
            number: bufferzone.location.address.houseNumber,
            city: bufferzone.location.address.city,
            street: bufferzone.location.address.street,
            box: bufferzone.location.address.box,
        },
        prefered: false,
        hasCabinets: false,
    };
};
