import { TPlaceholerKeys } from './PersonalData.type';

export const FORM_NAME = 'user-profile-personal-data-form';

export const TRANSLATION_KEY_PREFIX = 'account.account_settings.profile.personal_data.fields';
export const PLACEHOLDER_KEYS: TPlaceholerKeys = {
    firstName: `${TRANSLATION_KEY_PREFIX}.first_name`,
    name: `${TRANSLATION_KEY_PREFIX}.name`,
    newEmail: `${TRANSLATION_KEY_PREFIX}.email`,
    languageId: `${TRANSLATION_KEY_PREFIX}.language`,
    mobilePhoneNumber: `${TRANSLATION_KEY_PREFIX}.mobile_phone`,
    phoneNumber: `${TRANSLATION_KEY_PREFIX}.phone`,
    titleId: `${TRANSLATION_KEY_PREFIX}.salutation`,
    sexId: `${TRANSLATION_KEY_PREFIX}.sex`,
};
