import React from 'react';
import './access-to-list.scss';
import { ListColumns, ListItem } from '../../../../../models/general/list';
import List from '../../../../common/list/List';
import Translate from '../../../../common/Translate';
import { AccessLevel } from '../../../../../models/auth/authorisation';
import Checkbox from '../../../../common/input/Checkbox';
import Icon from '../../../../common/icons/Icon';
import { TUserAccountAccessLevel } from '../../../../../models/user/userAccount';
import { DEFAULT_ACCESS_LEVELS } from '../../../../../config/user.config';

interface IColumnNames {
    name: string;
    read: boolean;
    write: boolean;
}

interface IAccessLevelData {
    readAlwaysTrue?: boolean;
}

interface ILinkedAccessLevel {
    parent: keyof TUserAccountAccessLevel;
    child: keyof TUserAccountAccessLevel;
    minAccessLevelType: AccessLevel;
}

interface IAccessToListProps {
    accessLevel: TUserAccountAccessLevel;
    readOnly?: boolean;
    onChange?: (accessLevel: TUserAccountAccessLevel) => void;
}

const READ_ONLY_COLUMNS_CONFIG = getColumnsConfig(true);
const CLASS_NAME = 'AccessToList';

const ACCESS_LEVEL_LINKS: ILinkedAccessLevel[] = [
    {
        parent: 'planning',
        child: 'employee',
        minAccessLevelType: 'W',
    },
    {
        parent: 'planning',
        child: 'employee',
        minAccessLevelType: 'R',
    },
];

export default function AccessToList(props: IAccessToListProps) {
    const currentAccessLevel = props.accessLevel || DEFAULT_ACCESS_LEVELS;
    const onChange = typeof props.onChange === 'function'
        ? (onChangeProps: {
            accessLevel: keyof TUserAccountAccessLevel, accessLevelType: AccessLevel, checked: boolean,
        }) => {
            props.onChange(matchChildrenAccessLevelWithParentsMinimumAccessLevel(
                currentAccessLevel, onChangeProps,
            ) as TUserAccountAccessLevel);
        }
        : undefined;
    return (
        <div className={CLASS_NAME}>
            <List
                name="AccessToList"
                columns={props.readOnly ? READ_ONLY_COLUMNS_CONFIG : getColumnsConfig(false, onChange)}
                items={mapUserAccountToListItems(currentAccessLevel)}
                selectedItemIds={[]}
            />
        </div>
    );
}

function mapUserAccountToListItems(
    accessLevel: TUserAccountAccessLevel,
): ListItem<IColumnNames, keyof TUserAccountAccessLevel, IAccessLevelData>[] {
    return [
        {
            id: 'planning',
            columns: {
                name: 'account.account_settings.access_to_list.items.planning',
                read: canRead(accessLevel.planning),
                write: canWrite(accessLevel.planning),
            },
        },
        {
            id: 'employee',
            columns: {
                name: 'account.account_settings.access_to_list.items.employee',
                read: canRead(accessLevel.employee),
                write: canWrite(accessLevel.employee),
            },
        },
        {
            id: 'company',
            columns: {
                name: 'account.account_settings.access_to_list.items.company',
                read: true,
                write: canWrite(accessLevel.company),
            },
            data: { readAlwaysTrue: true },
        },
        {
            id: 'invoicing',
            columns: {
                name: 'account.account_settings.access_to_list.items.invoicing',
                read: canRead(accessLevel.invoicing),
                write: canWrite(accessLevel.invoicing),
            },
        },
        {
            id: 'interventions',
            columns: {
                name: 'account.account_settings.access_to_list.items.interventions',
                read: canRead(accessLevel.interventions),
                write: canWrite(accessLevel.interventions),
            },
        },
    ];
}

function canRead(accessLevel: AccessLevel) {
    return accessLevel === 'R' || accessLevel === 'W';
}

function canWrite(accessLevel: AccessLevel) {
    return accessLevel === 'W';
}

function renderReadWriteColumn(options: {
    readOnly: boolean
    id: keyof TUserAccountAccessLevel,
    canReadOrWrite: boolean,
    onChange?: (onChangeProps: {
        accessLevel: keyof TUserAccountAccessLevel, accessLevelType: AccessLevel, checked: boolean,
    }) => void;
    accessLevelType: AccessLevel;
    readAlwaysTrue?: boolean;
    linkedAccessLevel?: ILinkedAccessLevel;
}) {
    const { readOnly, canReadOrWrite, id, onChange, accessLevelType, readAlwaysTrue } = options;
    if (readOnly) {
        return <Icon typeName={canReadOrWrite ? 'check' : 'cross'} />;
    }
    return (
        <Checkbox
            name={`access-to-list-${id}-${accessLevelType}`}
            toggleButton={true}
            checked={readAlwaysTrue ? true : canReadOrWrite}
            onChange={ readAlwaysTrue ? undefined :
                typeof onChange === 'function'
                    ? (e) => onChange({ accessLevel: id, accessLevelType, checked: e.target.checked })
                    : undefined
            }
            disabled={readAlwaysTrue}
        />
    );
}

function getColumnsConfig(
    readOnly: boolean,
    onChange?: (onChangeProps: {
        accessLevel: keyof TUserAccountAccessLevel, accessLevelType: AccessLevel, checked: boolean,
    }) => void,
): ListColumns<IColumnNames> {
    return {
        name: {
            label: <Translate msg="account.account_settings.access_to_list.headers.access_to" />,
            sortable: false,
            percentWidth: 50,
            render: (listItem) => <Translate msg={listItem.columns.name as string} />,
        },
        read: {
            label: <Translate msg="account.account_settings.access_to_list.headers.read" />,
            sortable: false,
            percentWidth: 25,
            render: (listItem: ListItem<IColumnNames, keyof TUserAccountAccessLevel, IAccessLevelData>) =>
                renderReadWriteColumn({
                    readOnly,
                    canReadOrWrite: listItem.columns.read as boolean,
                    id: listItem.id as keyof TUserAccountAccessLevel,
                    onChange,
                    accessLevelType: 'R',
                    readAlwaysTrue: listItem.data && listItem.data.readAlwaysTrue,
                }),
        },
        write: {
            label: <Translate msg="account.account_settings.access_to_list.headers.write" />,
            sortable: false,
            percentWidth: 25,
            render: (listItem: ListItem<IColumnNames, keyof TUserAccountAccessLevel, IAccessLevelData>) =>
                renderReadWriteColumn({
                    readOnly,
                    canReadOrWrite: listItem.columns.write as boolean,
                    id: listItem.id as keyof TUserAccountAccessLevel,
                    onChange,
                    accessLevelType: 'W',
                }),
        },
    };
}

function getNewAccessLevelType(accessLevelType: AccessLevel, checked: boolean): AccessLevel {
    switch (accessLevelType) {
        case 'W':
            return checked ? 'W' : 'R';
        case 'R':
            return checked ? 'R' : 'nop';
    }
    return 'nop';
}

function getOneAccessLevelLower(accessLevelType: AccessLevel): AccessLevel {
    switch (accessLevelType) {
        case 'W':
            return 'R';
        case 'R':
            return 'nop';
    }
    return 'nop';
}

function hasMinimumAccessLevel(accessLevelType: AccessLevel, requiredAccessLevelType: AccessLevel) {
    switch (requiredAccessLevelType) {
        case 'W':
            return accessLevelType === 'W';
        case 'R':
            return accessLevelType === 'W' || accessLevelType === 'R';
    }
    return false;
}

function matchChildrenAccessLevelWithParentsMinimumAccessLevel(
    currentAccessLevel,
    onChangeProps: { accessLevel: keyof TUserAccountAccessLevel, accessLevelType: AccessLevel, checked: boolean },
): TUserAccountAccessLevel {
    const { accessLevel, accessLevelType, checked } = onChangeProps;

    const parentLevels = ACCESS_LEVEL_LINKS.filter((item) => item.parent === accessLevel);
    const childLevels = ACCESS_LEVEL_LINKS.filter((item) => item.child === accessLevel);

    let newAccessLevel = { ...currentAccessLevel };

    for (const parentLevel of parentLevels) {
        newAccessLevel = matchChildrenWithParentsAccessLevel(
            parentLevel,
            newAccessLevel,
            onChangeProps,
        );
    }

    for (const childLevel of childLevels) {
        newAccessLevel = lowerParentsAccessLevelIfChildLevelNoLongerMatchesMinimum(
            childLevel,
            newAccessLevel,
            onChangeProps,
        );
    }

    newAccessLevel = {
        ...newAccessLevel,
        [accessLevel]: getNewAccessLevelType(accessLevelType, checked),
    };

    return newAccessLevel;
}

function matchChildrenWithParentsAccessLevel(parentLevel, currentAccessLevel, onChangeProps) {
    const { accessLevelType, checked } = onChangeProps;

    if (hasMinimumAccessLevel(getNewAccessLevelType(accessLevelType, checked), parentLevel.minAccessLevelType) &&
        !hasMinimumAccessLevel(currentAccessLevel[parentLevel.child], parentLevel.minAccessLevelType)
    ) {
        return {
            ...currentAccessLevel,
            [parentLevel.child]: parentLevel.minAccessLevelType,
        };
    }
    return currentAccessLevel;
}

function lowerParentsAccessLevelIfChildLevelNoLongerMatchesMinimum(childLevel, currentAccessLevel, onChangeProps) {
    const { accessLevelType, checked } = onChangeProps;

    if (!hasMinimumAccessLevel(getNewAccessLevelType(accessLevelType, checked), childLevel.minAccessLevelType) &&
        hasMinimumAccessLevel(currentAccessLevel[childLevel.parent], childLevel.minAccessLevelType)
    ) {
        return {
            ...currentAccessLevel,
            [childLevel.parent]: getOneAccessLevelLower(childLevel.minAccessLevelType),
        };
    }
    return currentAccessLevel;
}
