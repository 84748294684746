import { AdminType } from '../../../../models/user/userAccount';
import { IUserProfile } from '../../../../models/auth/authentication';
import { Permission } from '../../../../models/auth/authorisation';

const BASE_TRANSLATION_KEY = 'account.account_settings.account_types.';
enum UserTypeTranslations {
    Admin = 'admin',
    RegularUser = 'regular_user',
    MainAdmin = 'main_admin',
    SuperAdmin = 'super_admin',
}

export function translateAccountTypeByAdminType(adminType: AdminType, superUser: boolean) {
    if (superUser) {
        return BASE_TRANSLATION_KEY + UserTypeTranslations.SuperAdmin;
    }

    switch (adminType) {
        case AdminType.Administrator:
            return BASE_TRANSLATION_KEY + UserTypeTranslations.Admin;
        case AdminType.MainAdministrator:
            return BASE_TRANSLATION_KEY + UserTypeTranslations.MainAdmin;
        default:
            return BASE_TRANSLATION_KEY + UserTypeTranslations.RegularUser;
    }
}

export function translateAccountTypeByUserProfile(userProfile: IUserProfile) {
    const { extraPermissions } = userProfile.accessLevel;
    if (extraPermissions.includes(Permission.CAN_ACCESS_ALL_COMPANIES)) {
        return BASE_TRANSLATION_KEY + UserTypeTranslations.SuperAdmin;
    }

    if (extraPermissions.includes(Permission.CAN_ADD_USER)) {
        return BASE_TRANSLATION_KEY + UserTypeTranslations.MainAdmin;
    }

    if (extraPermissions.includes(Permission.CAN_SELECT_ALL_SEATS)) {
        return BASE_TRANSLATION_KEY + UserTypeTranslations.Admin;
    }

    return BASE_TRANSLATION_KEY + UserTypeTranslations.RegularUser;
}
