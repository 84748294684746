import React, { ReactElement } from 'react';
import './stepper.scss';
import { IStepProps } from '../Step';
import StepDivider from './StepDivider';
import GoBack from '../GoBack';
import Button from '../../buttons/Button';
import Translate from '../../Translate';
import { IRenderStepButtonsOptions } from '../../../../models/general/stepper';
import SubmitButton from '../../buttons/SubmitButton';

interface IStepperProps {
    activeStepId: string | null;
    children: ReactElement<IStepProps> | ReactElement<IStepProps>[];
    onStepSelectIntent: (stepId: string) => void;
}

// Props injected by the Stepper
export interface IExtendedStepProps extends IStepProps {
    active: boolean;
    completed: boolean;
    onClick: () => void;
}

const CLASS_NAME = 'Stepper';

function Stepper({ children, activeStepId, onStepSelectIntent }: IStepperProps) {
    let isActiveStepFound = false;

    const stepIds = React.Children.map(children, (step: ReactElement<IExtendedStepProps>, index: number) => {
        return step.props.id;
    });

    const steps = React.Children.map(children, (step: ReactElement<IExtendedStepProps>, index: number) => {
        const isActive = isStepActive(activeStepId, step.props, index);
        const stepEl = React.createElement(step.type, {
            ...step.props,
            completed: !isActiveStepFound,
            active: isActive,
            onClick: !isActiveStepFound && !isActive ? () => onStepSelectIntent(step.props.id) : undefined,
            children: undefined,
        });
        if (isActive) {
            isActiveStepFound = true;
        }
        if (index !== 0) {
            return (
                <>
                    <StepDivider />
                    {stepEl}
                </>
            );
        }
        return stepEl;
    });

    const stepsContent = React.Children.map(children, (step: ReactElement<IExtendedStepProps>, index: number) => {
        // Only render the active step
        const isActive = isStepActive(activeStepId, step.props, index);
        if (isActive) {
            const previousStepId = stepIds[index - 1];
            const nextStepId = stepIds[index + 1];
            return step.props.children({
                renderStepButtons: (options) => renderButtons(
                    {
                        previousStepId,
                        nextStepId,
                        stepId: step.props.id,
                    },
                    onStepSelectIntent,
                    options,
                ),
                goToNextStep: () => onStepSelectIntent(nextStepId),
                goToPreviousStep: () => onStepSelectIntent(previousStepId),
            });
        }
        return null;
    });

    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__steps`}>
                {steps}
            </div>
            <div className={`${CLASS_NAME}__content`}>
                {stepsContent}
            </div>
        </div>
    );
}

function renderButtons(
    {
        stepId,
        previousStepId,
        nextStepId,
    }: {
        stepId: string;
        previousStepId: string;
        nextStepId: string;
    },
    onStepSelectIntent: (stepId: string) => void,
    options: IRenderStepButtonsOptions = {},
) {
    const isNextButtonHidden = options.nextButton && options.nextButton.hide;
    const showNextButton = !isNextButtonHidden && (nextStepId || (options.nextButton && options.nextButton.onClick));
    const nextButtonTranslationKey =
        (options.nextButton && options.nextButton.translationKey)
        || 'common.stepper.next_button';
    const onNextButtonClickHandler = (options.nextButton && options.nextButton.onClick)
        || (() => onStepSelectIntent(nextStepId));
    const onPrevButtonClickHandler = (options.prevButton && options.prevButton.onClick)
        || (() => onStepSelectIntent(previousStepId));
    const isNextButtonDisabled = options.nextButton && options.nextButton.disabled;
    const isNextButtonSubmitButton = options.nextButton && options.nextButton.isSubmit;
    const nextButtonFormName = options.nextButton && options.nextButton.formName;
    const hideprevButton = options.prevButton && options.prevButton.hide;
    const renderIcon = options.nextButton && options.nextButton.renderIcon;
    const alwaysEnabled = options.nextButton && options.nextButton.alwaysEnabled;

    return (
        <>
            {
                previousStepId &&
                !hideprevButton &&
                <GoBack id="stepper-previous-link" onClick={onPrevButtonClickHandler} ignoreTranslationContext />
            }
            {showNextButton && !isNextButtonSubmitButton && <Button
                id="stepper-next-button"
                typeName="secondary"
                onClick={isNextButtonSubmitButton ? undefined : onNextButtonClickHandler}
                disabled={!alwaysEnabled && isNextButtonDisabled}
            >
                {typeof renderIcon === 'function' ? renderIcon() : null}
                <Translate msg={nextButtonTranslationKey} ignoreContext />
            </Button>}
            {showNextButton && isNextButtonSubmitButton && <SubmitButton
                id="stepper-next-button"
                disabled={isNextButtonDisabled}
                formName={nextButtonFormName}
                enabledWhenValid={true}
                alwaysEnabled={alwaysEnabled}
            >
                {typeof renderIcon === 'function' ? renderIcon() : null}
                <Translate msg={nextButtonTranslationKey} ignoreContext />
            </SubmitButton>}
        </>
    );
}

function isStepActive(activeStepId: string, props: IStepProps, index: number) {
    // The active step is the one selected or the first one when not set
    return props.id === activeStepId || (activeStepId === null && index === 0);
}

export default Stepper;
