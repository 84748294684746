import {
    FETCH_POLICY_ADVICE, FETCH_POLICY_ADVICE_COMMENTS, SEND_POLICY_ADVICE_COMMENT,
} from './types';
import { createTypeActions } from '../../../utils/libs/redux/createAction';
import {
    IPolicyAdvice,
    IPolicyAdviceComment,
    IFetchPolicyAdviceCommentsPayload,
    ISendPolicyAdviceCommentPayload,
} from '../../../models/interventions/policyAdvice';

// Fetch policy advice
export const fetchPolicyAdviceActions =
    createTypeActions<{}, IPolicyAdvice[]>({
        type: FETCH_POLICY_ADVICE,
    });

// Fetch policy advice comments
export const fetchPolicyAdviceCommentsActions =
    createTypeActions<IFetchPolicyAdviceCommentsPayload, IPolicyAdviceComment[]>({
        type: FETCH_POLICY_ADVICE_COMMENTS,
    });

// Send policy advice comment
export const sendPolicyAdviceCommentActions =
    createTypeActions<ISendPolicyAdviceCommentPayload>({
        type: SEND_POLICY_ADVICE_COMMENT,
    });
