import React from 'react';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { TRANSLATION_PREFIX_OVERVIEW } from './common';
import Button from '../../../common/buttons/Button';
import Dialog from '../../../common/modals/Dialog';
import Translate from '../../../common/Translate';

export default function SendReminderDialog(props: {
    show: boolean;
    onClose: () => void;
    asyncInfo: IAsyncFieldInfo;
}) {
    const error = props.asyncInfo.error;
    const isBusy = props.asyncInfo.status === AsyncStatus.Busy;

    return (
        <Dialog
            show={props.show}
            onCloseIntent={props.onClose}
            type={error ? 'error' : 'success'}
            header={error ?
                `${TRANSLATION_PREFIX_OVERVIEW}.send_reminder_dialog.error` :
                `${TRANSLATION_PREFIX_OVERVIEW}.send_reminder_dialog.success`}
            showLoader={isBusy}
            disableClosing={!error}
        >
            {
                <Button
                    id="error-dialog-close"
                    typeName="secondary"
                    onClick={props.onClose}
                >
                    {error ?
                        <Translate msg="common.confirmation_dialog.close" /> :
                        <Translate msg="common.confirmation_dialog.continue" />
                    }
                </Button>
            }
        </Dialog>
    );
}
