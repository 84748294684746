import React from 'react';
import { ITextPagePartData } from '../../../../../models/general/pageParts';
import './text-page-part.scss';
import WysiwygContentType from '../WysiwygContentType';

interface IPublicProps {
    data: ITextPagePartData;
}

export default function TextPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <WysiwygContentType className="TextPagePart" html={data.content} />
    );
}
