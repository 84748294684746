import { createSelector } from 'reselect';

import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import {
    getSelectedReservedMedicalExaminationPlannedExaminations,
} from '../../../../../redux/medicalExamination/selectors';
import { IBufferzonePlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations';
import { ListItem } from '../../../../../models/general/list';
import { stringComparerAscending } from '../../../../../utils/list/comparerUtils';

import { IReservedMedicalExaminationDetailListColumnNames } from './ReservedMedicalExaminationDetail.type';

export const getListItemsMemoized = createSelector(
    getSelectedReservedMedicalExaminationPlannedExaminations,
    (exams) => sortAndMapPlannedExaminationsToListData(exams),
);


const sortAndMapPlannedExaminationsToListData = (
    examinations: IBufferzonePlannedMedicalExamination[],
): ListItem<IReservedMedicalExaminationDetailListColumnNames>[] => examinations
    .map((exam) => ({
        id: exam.timeSlotId,
        columns: {
            time: formatTimeOfDateForDisplay(exam.start),
            name: formatPersonName({ firstName: exam.employee.firstName, name: exam.employee.lastName }),
            actions: null,
            idOfEmployee: exam.employee.employment.id,
            employmentId: exam.employee.id,
            planningRequestId: exam.planningRequestId,
            timeSlotId: exam.timeSlotId,
        },
    }))
    .sort((a, b) => stringComparerAscending(a.columns.time, b.columns.time));

