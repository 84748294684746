import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers,
} from '../../index';
import {
    FETCH_POLICY_ADVICE, FETCH_POLICY_ADVICE_COMMENTS, SEND_POLICY_ADVICE_COMMENT,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IPolicyAdvice,
    IPolicyAdviceComment,
    ISendPolicyAdviceCommentPayload,
} from '../../../models/interventions/policyAdvice';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { formatCurrentDateForBackend } from '../../../utils/formatting/formatDate';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INTERVENTIONS_POLICYADVICE;

export interface IReducerState {
    policyAdvice: IAsyncFetchField<IPolicyAdvice[]>;
    policyAdviceComments: IAsyncFetchField<IPolicyAdviceComment[]>;
    sendPolicyAdviceComment: IAsyncDoField;
}

const initialState: IReducerState = {
    policyAdvice: getAsyncFetchInitialState(),
    policyAdviceComments: getAsyncFetchInitialState(),
    sendPolicyAdviceComment: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IPolicyAdvice[], IReducerState, IPolicyAdvice[]>({
        baseActionType: FETCH_POLICY_ADVICE,
        fieldName: 'policyAdvice',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_POLICY_ADVICE, FETCH_POLICY_ADVICE],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IPolicyAdviceComment[], IReducerState, IPolicyAdviceComment[]>({
        baseActionType: FETCH_POLICY_ADVICE_COMMENTS,
        fieldName: 'policyAdviceComments',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_POLICY_ADVICE_DETAIL,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SEND_POLICY_ADVICE_COMMENT,
        fieldName: 'sendPolicyAdviceComment',
        transformStateOnTrigger: ({ oldState, payload: triggerPayload }) => {
            const payload = triggerPayload as ISendPolicyAdviceCommentPayload;

            const currentPolicyAdviceComments: IPolicyAdviceComment[] = oldState.policyAdviceComments.data || [];
            const newPolicyAdviceComments = currentPolicyAdviceComments.filter((comment) => !comment.placeholder);

            newPolicyAdviceComments.push({
                comment: payload.comment,
                userComment: true,
                placeholder: true,
                date: formatCurrentDateForBackend(),
            } as IPolicyAdviceComment);

            return {
                ...oldState,
                policyAdviceComments: {
                    ...oldState.policyAdviceComments,
                    data: newPolicyAdviceComments,
                },
            };
        },
        transformStateOnSuccess: ({ oldState }) => {
            const currentPolicyAdviceComments: IPolicyAdviceComment[] = oldState.policyAdviceComments.data || [];
            const newPolicyAdviceComments = currentPolicyAdviceComments.map((comment) => {
                return {
                    ...comment,
                    placeholder: false,
                };
            });

            return {
                ...oldState,
                policyAdviceComments: {
                    ...oldState.policyAdviceComments,
                    data: newPolicyAdviceComments,
                },
            };
        },
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
