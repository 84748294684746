import React, { PureComponent } from 'react';
import { connect } from '../../../index';
import { fields, schema } from './updateSeatSchema';
import { SeatDetailsOverlayType } from './detailOverlay';
import Availabilities from '../shared/Availabilities';
import AddContact from '../Contacts/AddContact';
import { ICompanySeat } from '../../../../models/admin/company';
import {
    CompanyAvailabilityType, ICompanyDetailUpdate,
    ICompanyContact, ICompanyDetail,
} from '../../../../models/admin/companyInfo';
import { IAddressSave } from '../../../../models/general/address';
import { ITranslator } from '../../../../models/general/i18n';
import { AsyncStatus } from '../../../../models/general/redux';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import {
    getCompanySeatContacts,
    getCompanySeatContactsAsyncInfo,
    getSelectedContactId,
    getSelectedCompanyInfoSeatDetails,
    mayUserManageCompanyInternalContacts,
} from '../../../../redux/company/info/selectors';
import { updateCompanyActions, fetchCompanySeatContactsActions } from '../../../../redux/company/info/actions';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import Button from '../../../common/buttons/Button';
import Form, { IFormRenderProps, TSetFieldValue } from '../../../common/forms/Form';
import FormFieldError from '../../../common/forms/FormFieldError';
import { AdvancedPropertyInput, TextPropertyInput } from '../../../common/input/PropertyInput';
import ListItem from '../../../common/list/ListItem';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader/index';
import TinyLoader from '../../../common/waiting/TinyLoader';
import CollapsibleItem from '../../../common/widget/CollapsibleItem';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import { getFieldAscendingComparator } from '../../../../utils/core/object/sortObjects';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import Icon from '../../../common/icons/Icon';
import Dialog from '../../../common/modals/Dialog';
import { SLIDEOUTPANEL_CLASSES } from '../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../common/buttons/SubmitButton';
import ROUTE_KEYS from '../../../../routeKeys';
import Holidays from '../shared/Holidays';

const CLASS_NAME = 'CompanySeatDetail';
const FORM_NAME = 'company-detail-fields-form';

interface ISeatContact extends ICompanyContact {
    personName: string;
}

interface IFormValues {
    name: string;
    companyCode: string;
    address: IAddressSave;
    billingAddress: IAddressSave;
}

interface IPrivateProps {
    mayEdit: boolean;
    seatContacts: ISeatContact[];
    seatDetails: ICompanyDetail;
    translator: ITranslator;
    selectedContactId: number;
    updateCompany: (values: IFormValues) => void;
    refreshSeatContacts: () => void;
    mayEditContact: boolean;
}

interface IPublicProps {
    selectedDetailOverlayAddress: IAddressSave;
    selectedDetailOverlayBillingAddress: IAddressSave;
}

interface IComponentState {
    isAddContactDialogOpen: boolean;
}

class DetailContent extends PureComponent<
    IPrivateProps & IPublicProps & IRenderDetailContentProps<ICompanySeat>,
    IComponentState
    > {
    constructor(props) {
        super(props);

        this.state = {
            isAddContactDialogOpen: false,
        };

        this.submitDataChanges = this.submitDataChanges.bind(this);
        this.onOpenAddContact = this.onOpenAddContact.bind(this);
        this.onCloseAddContact = this.onCloseAddContact.bind(this);
        this.onContactAdded = this.onContactAdded.bind(this);
        this.renderContactPersonListItem = this.renderContactPersonListItem.bind(this);
    }

    private resetForm: (newValues: object) => void;
    private setFormFieldValue: TSetFieldValue<IFormValues>;

    public componentDidUpdate(prevProps: IPrivateProps & IPublicProps & IRenderDetailContentProps<ICompanySeat>) {
        if (
            this.resetForm &&
            prevProps.detailData && this.props.detailData &&
            (prevProps.detailData.company.companyCode !== this.props.detailData.company.companyCode)
        ) {
            this.resetForm(this.props.detailData.company);
        }

        if (prevProps.selectedDetailOverlayAddress !== this.props.selectedDetailOverlayAddress) {
            this.setFormFieldValue('address', this.props.selectedDetailOverlayAddress);
        }
        if (prevProps.selectedDetailOverlayBillingAddress !== this.props.selectedDetailOverlayBillingAddress) {
            this.setFormFieldValue('billingAddress', this.props.selectedDetailOverlayBillingAddress);
        }
    }

    public render() {
        const {
            detailData: selectedCompany,
            seatDetails,
        } = this.props;

        if (!selectedCompany || !seatDetails) {
            this.resetForm = null;
            return null;
        }

        return (
            <>
                {this.renderSeatData()}
                {this.renderSeatContacts()}
                {this.renderAddContactDialog()}
                {this.renderSeatAvailabilities()}
            </>
        );
    }

    private renderSeatData() {
        const {
            detailData: selectedCompany,
            detailAsyncInfo,
            mayEdit,
            onOpenOverlay,
            selectedDetailOverlayAddress,
            selectedDetailOverlayBillingAddress,
            translator, detailUpdateAsyncInfo,
            seatDetails,
        } = this.props;

        const INITIAL_VALUES: IFormValues = {
            name: selectedCompany.company.name,
            companyCode: selectedCompany.company.companyCode,
            address: selectedCompany.address || ({} as IAddressSave),
            billingAddress: seatDetails.billingAddress || ({} as IAddressSave),
        };

        const formattedAddress = formatAddress(
            selectedDetailOverlayAddress ? selectedDetailOverlayAddress : INITIAL_VALUES.address,
        );
        const formattedBillingAddress = formatAddress(
            selectedDetailOverlayBillingAddress ? selectedDetailOverlayBillingAddress : INITIAL_VALUES.billingAddress,
        );

        const showLoader = detailAsyncInfo.status === AsyncStatus.Busy ||
            detailUpdateAsyncInfo.status === AsyncStatus.Busy;

        return (
            <Form
                name={FORM_NAME}
                handleSubmit={this.submitDataChanges}
                initialValues={INITIAL_VALUES}
                schema={schema}
                render={({
                    values, handleChange, errors, setFieldValue, resetForm, touched,
                }: IFormRenderProps<IFormValues>) => {
                    if (!this.resetForm) {
                        this.resetForm = resetForm;
                    }
                    this.setFormFieldValue = setFieldValue;

                    return (
                        <Loader show={showLoader}>
                            {/* eslint-disable-next-line max-len */}
                            <CollapsibleItem trigger="administration.company_info.seats.detail.data.title" initialOpen>
                                <p><Translate msg="administration.company_info.seats.detail.text" /></p>

                                <TextPropertyInput
                                    id={fields.name}
                                    name={fields.name}
                                    labelKey="administration.company_info.seats.detail.data.name"
                                    readonly={!mayEdit}
                                    value={values.name}
                                    onChange={handleChange}
                                >
                                    {touched.name &&
                                        <FormFieldError
                                            error={errors.name}
                                            placeholders={{
                                                fieldName: translator(
                                                    'administration.company_info.seats.detail.data.name',
                                                ),
                                            }}
                                        />
                                    }
                                </TextPropertyInput>
                                <TextPropertyInput
                                    id={fields.companyCode}
                                    name={fields.companyCode}
                                    labelKey="administration.company_info.seats.detail.data.company_code"
                                    readonly={true}
                                    value={values.companyCode}
                                    onChange={handleChange}
                                />
                                <AdvancedPropertyInput
                                    labelKey="administration.company_info.seats.detail.data.address"
                                    value={formattedAddress}
                                    readonly={!mayEdit}
                                    onClick={() => onOpenOverlay(SeatDetailsOverlayType.EditAddress)}
                                >
                                    {touched.address &&
                                        <FormFieldError
                                            error={errors.address}
                                            placeholders={{
                                                fieldName: translator(
                                                    'administration.company_info.seats.detail.data.address',
                                                ),
                                            }}
                                        />
                                    }
                                </AdvancedPropertyInput>
                                <AdvancedPropertyInput
                                    labelKey="administration.company_info.seats.detail.data.billing_address"
                                    value={formattedBillingAddress}
                                    /* TODO: change to readonly={!mayEdit} when it can be saved via the backend
                                            also re-enable validation */
                                    readonly={true}
                                    onClick={() => onOpenOverlay(SeatDetailsOverlayType.EditBillingAddress)}
                                >
                                    {touched.billingAddress &&
                                        <FormFieldError
                                            error={errors.billingAddress}
                                            placeholders={{
                                                fieldName: translator(
                                                    'administration.company_info.seats.detail.data.billing_address',
                                                ),
                                            }}
                                        />
                                    }
                                </AdvancedPropertyInput>
                                {mayEdit &&
                                    <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                        <SubmitButton
                                            id="edit-seat-submit-button"
                                            formName={FORM_NAME}
                                        >
                                            <Translate
                                                msg="administration.company_info.seats.detail.action.save"
                                            />
                                        </SubmitButton>
                                    </div>
                                }
                            </CollapsibleItem>
                        </Loader>
                    );
                }}
            />
        );
    }

    private submitDataChanges(values: IFormValues) {
        this.props.updateCompany(values);
    }

    private renderSeatContacts() {
        const {
            seatContacts,
            detailData: selectedSeat,
            selectedContactId,
            mayEditContact,
        } = this.props;

        return (
            <CollapsibleItem
                trigger="administration.company_info.seats.detail.contacts.title"
                initialOpen={!!selectedContactId}
            >
                <TinyLoader asyncInfoSelector={getCompanySeatContactsAsyncInfo}>
                    {seatContacts.length > 0 ?
                        <>
                            {
                                seatContacts.map((seatContact, index) => {
                                    const listItemText = this.renderContactPersonListItem(seatContact);
                                    return (
                                        <ListItem
                                            id={`mensura-contact-${seatContact.id}`}
                                            key={index}
                                            text={listItemText}
                                            to={{
                                                type: ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
                                                payload: {
                                                    companyCode: selectedSeat.company.companyCode,
                                                    id: seatContact.customerContactId,
                                                },
                                            }}
                                            selected={selectedContactId === seatContact.customerContactId}
                                        />
                                    );
                                })
                            }
                        </> :
                        <p><Translate msg="administration.company_info.seats.detail.contacts.no_results" /></p>
                    }
                    {mayEditContact && (
                        <Button
                            id="add-contact"
                            typeName="text"
                            className="AddButton"
                            onClick={this.onOpenAddContact}
                        >
                            <Icon typeName="plus" circle />
                            <span>
                                {/*eslint-disable-next-line max-len*/}
                                <Translate msg="administration.company_info.seats.detail.contacts.add_contact" />
                            </span>
                        </Button>
                    )}
                </TinyLoader>
            </CollapsibleItem>
        );
    }

    private renderContactPersonListItem(item: ISeatContact) {
        return (
            <>
                <strong>{item.personName}</strong><br />
                {`${item.company.companyCode} ${item.company.name ? item.company.name : ''}`}
            </>
        );
    }

    private onOpenAddContact() {
        this.setState({
            isAddContactDialogOpen: true,
        });
    }

    private onCloseAddContact() {
        this.setState({
            isAddContactDialogOpen: false,
        });
    }

    private onContactAdded() {
        this.onCloseAddContact();
        this.props.refreshSeatContacts();
    }

    private renderAddContactDialog() {
        const {
            detailData: selectedCompany,
        } = this.props;
        const { isAddContactDialogOpen } = this.state;

        return (
            <Dialog
                show={isAddContactDialogOpen}
                onCloseIntent={this.onCloseAddContact}
            >
                <AddContact
                    onClose={this.onCloseAddContact}
                    onSucces={this.onContactAdded}
                    fixedCompanyCode={selectedCompany.company.companyCode}
                />
            </Dialog>
        );
    }

    private renderSeatAvailabilities() {
        const {
            detailData: selectedCompany,
            onOpenOverlay,
        } = this.props;
        const companyNameSelector = () => selectedCompany.company.name;
        const companyCodeSelector = () => selectedCompany.company.companyCode;

        return (
            <CollapsibleItem trigger="administration.company_info.seats.detail.availabilities.title">
                <h4 className={`${CLASS_NAME}__availabilities__title`}>
                    <Translate
                        msg="administration.company_info.seats.detail.availabilities.medical_examinations.title"
                    />&nbsp;
                    <TooltipWithIcon iconSize="small">
                        <Translate
                            msg="administration.company_info.seats.detail.availabilities.medical_examinations.help"
                        />
                    </TooltipWithIcon>
                </h4>
                <Availabilities
                    type={CompanyAvailabilityType.MedicalExaminations}
                    companyNameSelector={companyNameSelector}
                    companyCodeSelector={companyCodeSelector}
                    withinSidePanel={{
                        immediatelyShowOverlay: false,
                        onSelectAvailabilities: () => onOpenOverlay(SeatDetailsOverlayType.EditAvailabilitiesMedExams),
                    }}
                />

                <h4 className={`${CLASS_NAME}__availabilities__title`}>
                    <Translate
                        msg="administration.company_info.seats.detail.availabilities.risk_management.title"
                    />&nbsp;
                    <TooltipWithIcon iconSize="small">
                        <Translate
                            msg="administration.company_info.seats.detail.availabilities.risk_management.help"
                        />
                    </TooltipWithIcon>
                </h4>
                <Availabilities
                    type={CompanyAvailabilityType.RiskManagement}
                    companyNameSelector={companyNameSelector}
                    companyCodeSelector={companyCodeSelector}
                    withinSidePanel={{
                        immediatelyShowOverlay: false,
                        onSelectAvailabilities: () => onOpenOverlay(SeatDetailsOverlayType.EditAvailabilitiesRiskMgmt),
                    }}
                />

                <h4>
                    <Translate
                        msg="administration.company_info.seats.detail.availabilities.holidays.title"
                    />&nbsp;
                    <TooltipWithIcon iconSize="small">
                        <Translate
                            msg="administration.company_info.seats.detail.availabilities.holidays.help"
                        />
                    </TooltipWithIcon>
                </h4>
                <Holidays
                    withinSidePanel={{
                        immediatelyShowOverlay: false,
                        onSelectHolidays: () => onOpenOverlay(SeatDetailsOverlayType.EditHolidays),
                    }}
                />
            </CollapsibleItem>
        );
    }
}

export default connect<IPrivateProps, IRenderDetailContentProps<ICompanySeat> & IPublicProps>({
    stateProps: (state) => {
        return {
            mayEdit: hasRequiredAccessLevels(state, { company: 'W' }),
            seatContacts: getCompanySeatContacts(state)
                .map(toSeatContact)
                .sort(getFieldAscendingComparator<ISeatContact>('personName')),
            seatDetails: getSelectedCompanyInfoSeatDetails(state),
            translator: getTranslatorDeprecated(state),
            selectedContactId: getSelectedContactId(state),
            mayEditContact: mayUserManageCompanyInternalContacts(state),
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        const {
            detailData: selectedCompany,
        } = publicProps;

        return {
            updateCompany: (values: IFormValues) => {
                dispatch(updateCompanyActions.trigger({
                    companyCode: selectedCompany.company.companyCode,
                    companyData: toCompanyDetailUpdate(values),
                }));
            },
            refreshSeatContacts: () => {
                dispatch(fetchCompanySeatContactsActions.trigger({
                    companyCode: selectedCompany.company.companyCode,
                }));
            },
        };
    },
})(DetailContent);

function toCompanyDetailUpdate(values: IFormValues): ICompanyDetailUpdate {
    return {
        name: values.name,
        address: values.address,
        billingAddress: values.billingAddress,
    };
}

function toSeatContact(contact: ICompanyContact): ISeatContact {
    return {
        ...contact,
        personName: formatPersonName(contact),
    };
}
