import React, { Component } from 'react';
import connect from '../../../../../../utils/libs/redux/connect';
import { ITranslator } from '../../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import TimeSpanPicker, { TimeSpanFormValues } from '../../shared/TimeSpanPicker';
import classNames from 'classnames';
import {
    getPlanMedicalExaminationWizardEntity,
    getAutoPlanEmployeesAsyncInfo,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IExaminationReason,
} from '../../../../../../models/interventions/medicalExaminations';

import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    updatePlanMedicalExaminationWizardEntity,
    autoPlanEmployees,
} from '../../../../../../redux/medicalExamination/actions';
import PageHeader from '../../../../../appShell/PageHeader';
import Loader from '../../../../../common/waiting/Loader';
import {
    mapMedExamsToAutoPlanPayload,
} from '../../../../../../utils/interventions/medicalExaminations/mapMedExamsToAutoPlanPayload';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

interface IPrivateProps {
    currentStartTime: string;
    currentEndTime: string;
    translator: ITranslator;
    asyncInfo: IAsyncFieldInfo;
    dispatchAutoPlanEmployees: () => void;
    updateWizardEntity: (values) => void;
}

interface IState {
    timeSpanError: boolean;
}

class AutomaticTime extends Component<IPrivateProps & IStepperStepRenderProps, IState> {

    constructor(props) {
        super(props);

        this.onTimeSelected = this.onTimeSelected.bind(this);
    }

    public render() {
        const { currentEndTime, currentStartTime, asyncInfo, renderStepButtons } = this.props;
        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.time.title"
                    text="interventions.medical_examinations.new.steps.time.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Loader show={asyncInfo.status === AsyncStatus.Busy}>
                        <TimeSpanPicker
                            error={asyncInfo.error}
                            initialStartTime={currentStartTime}
                            initialEndTime={currentEndTime}
                            minDifferenceInMinutes={180}
                            onConfirm={this.onTimeSelected}
                            renderStepButtons={renderStepButtons}
                            withLinkToManualPlanning={true}
                        />
                    </Loader>
                </div>
            </>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.asyncInfo.status === AsyncStatus.Success &&
            prevProps.asyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.goToNextStep();
        }
    }

    private onTimeSelected(values: TimeSpanFormValues) {
        const { updateWizardEntity, dispatchAutoPlanEmployees } = this.props;
        updateWizardEntity(values);
        dispatchAutoPlanEmployees();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>(state);
        const asyncInfo = getAutoPlanEmployeesAsyncInfo(state);
        return {
            currentStartTime: entity.startTime,
            currentEndTime: entity.endTime,
            translator: getTranslatorDeprecated(state),
            asyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardEntity: (values) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPeriodicHealthAssessmentAutomaticEntity>({
                    ...values,
                }));
            },
            dispatchAutoPlanEmployees: () => {
                const state = getState();
                const examinationReason = getPlanMedicalExaminationWizardReason(state) as IExaminationReason;
                const entity = getPlanMedicalExaminationWizardEntity(state) as IPeriodicHealthAssessmentAutomaticEntity;
                dispatch(autoPlanEmployees(mapMedExamsToAutoPlanPayload({
                    medicalExaminationsToAdd: entity.medicalExaminationsToAdd,
                    entity,
                    examinationReason,
                })));
            },
        };
    },
})(AutomaticTime);
