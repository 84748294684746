import { IInterventionItem } from '../../models/general/navigation';
import ROUTE_KEYS from '../../routeKeys';
import { getRequestInterventionWizardSteps } from './wizardStepsMap';
import { IInterventionRequestWizardPayload } from '../../models/interventions/requestIntervention';
import { INTERVENTIONS_SVG_IDS } from '../../models/general/lazyLoadSvg';

export enum EXAMINATION_REASON_CODE {
    INTERIM = 'POI', // 1604759 - Interim onderzoek
    MATERNITY_PROTECTION = 'MB', // 1604743 - Bescherming van het moederschap
    PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT = 'AAN', // 1604723 - Voorafgaande gezondheidsbeoordeling bij aanwerving
    PERIODIC_HEALTH_ASSESSMENT = 'PO', // 1604757 - Periodieke gezondheidsbeoordeling
    PERIODIC_CHECK_PLUS_SAFETY_FUNCTION = 'POVF', // 1604816 - Periodiek onderzoek + veiligheidsfunctie
    RESUMPTION_OF_WORK = 'WHV', // 1604777 - Onderzoek bij werkhervatting
    RE_INTEGRATION = 'REINT', // 1604807 - Re-integratiebeoordeling
    SPONTANEOUS_CONSULTATION = 'SPN', // 1604771 - Spontane raadpleging
}

// When updated, also change the translations for the intervention input.
export enum PRESTATION_CODE {
    PSYCHOSOCIAL = 'psy',
    ENVIRONMENTAL = 'mil',
    HYG_TOX = 'iht',
    ERGONOMICS = 'ergo',
    HEALTH = 'hea',
    SAFETY = 'vei',
    OTHER = 'andere',
}

export enum PRESTATION_CODE_INTERVENTION {
    COMPANY_VISIT = 'BB',
    PSYCHOSOCIAL = 'PSY',
    ENVIRONMENTAL = 'MIL',
    HYG_TOX = 'IHT',
    HEALTH = 'HEA',
    SAFETY = 'VEI',
    OTHER = 'ANDERE',
    ALL = 'all',
}

export const INTERVENTION_ILLUSTRATIONS = {
    psy: INTERVENTIONS_SVG_IDS.PSYCHO_SOCIAL_INTERVENTION,
    mil: INTERVENTIONS_SVG_IDS.ENVIROMENTAL_INTERVENTION,
    iht: INTERVENTIONS_SVG_IDS.TOXILOGICAL_INTERVENTION,
    ergo: INTERVENTIONS_SVG_IDS.ERGONOMIC_INTERVENTION,
    hea: INTERVENTIONS_SVG_IDS.HEALTH_INTERVENTION,
    vei: INTERVENTIONS_SVG_IDS.SAFETY_INTERVENTION,
    other: INTERVENTIONS_SVG_IDS.OTHER_INTERVENTION,
    all: INTERVENTIONS_SVG_IDS.ALL_INTERVENTIONS,
};

export const INTERVENTION_ITEMS: IInterventionItem[] = [
    toInterventionItem({
        id: 'interventions-medical-examinations-link',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN,
        titleTranslationKey: 'interventions.overview.main.medical_examinations',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.MED_EXAM,
        tooltipTranslationKey: 'interventions.overview.tooltip.medical_examinations',
    }),
    toInterventionItem({
        id: 'interventions-company-visits-link',
        linkTo: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
        titleTranslationKey: 'interventions.overview.main.company_visits',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.COMPANY_VISIT,
        tooltipTranslationKey: 'interventions.overview.tooltip.company_visits',
    }),
    toInterventionItem({
        id: 'interventions-all-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE_INTERVENTION.ALL,
        },
        titleTranslationKey: 'interventions.overview.main.all_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.all,
        tooltipTranslationKey: 'interventions.overview.tooltip.all_interventions',
    }),
    toInterventionItem({
        id: 'interventions-vei-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.SAFETY,
        },
        titleTranslationKey: 'interventions.overview.main.vei_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.vei,
        tooltipTranslationKey: 'interventions.overview.tooltip.vei_interventions',
    }),
    toInterventionItem({
        id: 'interventions-erg-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.ERGONOMICS,
        },
        titleTranslationKey: 'interventions.overview.main.ergo_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.ergo,
        tooltipTranslationKey: 'interventions.overview.tooltip.ergo_interventions',
    }),
    toInterventionItem({
        id: 'interventions-psy-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.PSYCHOSOCIAL,
        },
        titleTranslationKey: 'interventions.overview.main.psy_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.psy,
        tooltipTranslationKey: 'interventions.overview.tooltip.psy_interventions',
    }),
    toInterventionItem({
        id: 'interventions-hea-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.HEALTH,
        },
        titleTranslationKey: 'interventions.overview.main.hea_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.hea,
        tooltipTranslationKey: 'interventions.overview.tooltip.hea_interventions',
    }),
    toInterventionItem({
        id: 'interventions-hyg-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.HYG_TOX,
        },
        titleTranslationKey: 'interventions.overview.main.iht_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.iht,
        tooltipTranslationKey: 'interventions.overview.tooltip.iht_interventions',
    }),
    toInterventionItem({
        id: 'interventions-mil-interventions-link',
        linkTo: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        linkToPayload: {
            prestationCode: PRESTATION_CODE.ENVIRONMENTAL,
        },
        titleTranslationKey: 'interventions.overview.main.mil_interventions',
        lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.mil,
        tooltipTranslationKey: 'interventions.overview.tooltip.mil_interventions',
    }),
    toInterventionItem({
        id: 'interventions-training-overview-link',
        linkTo: ROUTE_KEYS.R_COURSES_OVERVIEW,
        titleTranslationKey: 'interventions.overview.main.training_overview',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.COURSES_LEARN_CONNECT,
    }),
];

export const INTERVENTION_MANAGEMENT_ITEMS: IInterventionItem[] = [
    toInterventionItem({
        id: 'interventions-policy-advice-link',
        linkTo: ROUTE_KEYS.R_POLICY_ADVICE,
        titleTranslationKey: 'interventions.overview.sub.policy_advice',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.ADVICE,
    }),
    toInterventionItem({
        id: 'interventions-manage-flu-vacins-link',
        linkTo: ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
        titleTranslationKey: 'interventions.overview.sub.manage_flu_vaccines',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.SYRINGE,
    }),
    toInterventionItem({
        id: 'interventions-manage-vaccines-link',
        linkTo: ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER,
        titleTranslationKey: 'interventions.overview.sub.manage_vaccinations',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.VACCINES,
        detailLazyLoadSvgId: INTERVENTIONS_SVG_IDS.MED_EXAM,
    }),
    toInterventionItem({
        id: 'interventions-manage-work-accidents-link',
        linkTo: ROUTE_KEYS.R_WORK_ACCIDENTS,
        titleTranslationKey: 'interventions.overview.sub.manage_work_accidents',
        lazyLoadSvgId: INTERVENTIONS_SVG_IDS.BAND_AID,
    }),
];

let interventionPlanItems: IInterventionItem[] = null;

export function getInterventionPlanItems() {
    if (!interventionPlanItems) {
        interventionPlanItems = initInterventionPlanItems();
    }

    return interventionPlanItems;
}

function initInterventionPlanItems(): IInterventionItem[] {
    return [
        toInterventionItem({
            id: 'interventions-medical-examination-new-link',
            linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES,
            titleTranslationKey: 'interventions.plan_intervention.tiles.plan_medical_examination',
            lazyLoadSvgId: INTERVENTIONS_SVG_IDS.MED_EXAM,
        }),
        toInterventionItem({
            id: 'interventions-company-visit-new-link',
            linkTo: ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW,
            titleTranslationKey: 'interventions.plan_intervention.tiles.request_company_visit',
            lazyLoadSvgId: INTERVENTIONS_SVG_IDS.COMPANY_VISIT,
        }),
        toInterventionItem({
            id: 'interventions-vei-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.SAFETY),
            titleTranslationKey: 'interventions.overview.main.vei_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.vei,
        }),
        toInterventionItem({
            id: 'interventions-erg-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.ERGONOMICS),
            titleTranslationKey: 'interventions.overview.main.ergo_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.ergo,
        }),
        toInterventionItem({
            id: 'interventions-psy-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.PSYCHOSOCIAL),
            titleTranslationKey: 'interventions.overview.main.psy_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.psy,
        }),
        toInterventionItem({
            id: 'interventions-hea-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.HEALTH),
            titleTranslationKey: 'interventions.overview.main.hea_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.hea,
        }),
        toInterventionItem({
            id: 'interventions-hyg-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.HYG_TOX),
            titleTranslationKey: 'interventions.overview.main.iht_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.iht,
        }),
        toInterventionItem({
            id: 'interventions-mil-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.ENVIRONMENTAL),
            titleTranslationKey: 'interventions.overview.main.mil_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.mil,
        }),
        toInterventionItem({
            id: 'interventions-other-interventions-new-link',
            ...getNewInterventionRequestLink(PRESTATION_CODE.OTHER),
            titleTranslationKey: 'interventions.overview.main.andere_interventions',
            lazyLoadSvgId: INTERVENTION_ILLUSTRATIONS.other,
        }),
    ];
}

export function getNewInterventionRequestLink(prestationCode: PRESTATION_CODE) {
    return {
        linkTo: ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW,
        linkToPayload: getRequestInterventionPayload(prestationCode),
    };
}

function getRequestInterventionPayload(prestationCode: PRESTATION_CODE): IInterventionRequestWizardPayload {
    return {
        prestationCode,
        resetDataEntity: true,
        step: getRequestInterventionWizardSteps().firstStepId,
    };
}

interface IInterventionItemConfig {
    id: string;
    linkTo: ROUTE_KEYS;
    linkToPayload?: {};
    titleTranslationKey: string;
    lazyLoadSvgId: INTERVENTIONS_SVG_IDS;
    detailLazyLoadSvgId?: INTERVENTIONS_SVG_IDS;
    tooltipTranslationKey?: string;
}

function toInterventionItem({
    id,
    linkTo,
    linkToPayload,
    titleTranslationKey,
    lazyLoadSvgId,
    tooltipTranslationKey,
    detailLazyLoadSvgId = null,
}: IInterventionItemConfig): IInterventionItem {
    return {
        id,
        linkTo,
        linkToPayload,
        titleTranslationKey,
        lazyLoadSvgId,
        tooltipTranslationKey,
        ...!!detailLazyLoadSvgId && {
            detailLazyLoadSvgId,
        },
    };
}
