// import { EXAMINATION_REASON_CODE } from './navigation/interventions.config';

export const DEFAULT_NR_OF_DAYS_IN_THE_FUTURE = {
    TO_SHOW_EMPLOYEES_TO_PLAN: 42,
    TO_INITIALLY_SELECT_EMPLOYEES_TO_PLAN: 14,
};

export const EXAMINATION_DOCUMENTS_MIME_TYPES =
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf';

export const MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN = 25;

export const NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY = 'klantenzone.error.autoplan.no_timecells_found';

export const NR_OF_HOURS_BEFORE_EXAM_ALLOWED = 4;

export const NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED = {
    CANCEL: 48,
    CHANGE_EMPLOYEE: 4,
};

export const EXAMINATION_REASON_CODES_THAT_REQUIRE_TO_BE_PLANNED_CHECK: string[] = [
    // EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT,
    // EXAMINATION_REASON_CODE.PERIODIC_CHECK_PLUS_SAFETY_FUNCTION,
    // EXAMINATION_REASON_CODE.PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT,
];

enum ExaminationReasonId {
    Periodic = 1604757,
    PeriodicSafety = 1604817,
    PeriodicSafetyTwo = 1604816,
    PeriodicDrivers = 1604758,
}

export const AUTO_PLAN_ALLOWED_EXAMINATION_REASON_IDS = [
    ExaminationReasonId.Periodic,
    ExaminationReasonId.PeriodicSafety,
    ExaminationReasonId.PeriodicSafetyTwo,
    ExaminationReasonId.PeriodicDrivers,
];
