import { createEpic } from '../../index';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat,
} from '../../company/selected/selectors';
import { getLocationState, getQueryParams } from '../../location/selectors';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IFetchWorkAccidentsPayload, IWorkAccidentsFilter,
    IFetchWorkAccidentDocumentPayload,
} from '../../../models/interventions/workAccidents';
import { DEFAULT_WORK_ACCIDENTS_FILTERS } from '../../../api/interventions/workAccidents.api';
import { fetchWorkAccidentsActions, fetchWorkAccidentsFilesActions } from './actions';
import { FETCH_WORK_ACCIDENT_FILES } from './types';
import { areWorkAccidentsAvailable } from './selectors';

// fetchWorkAccidentsEpic
createEpic<IFetchWorkAccidentsPayload[]>({
    onActionType: ROUTE_KEYS.R_WORK_ACCIDENTS,
    refreshDataIf: ({ getState, action }) => {
        if (!areWorkAccidentsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_WORK_ACCIDENTS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(getState()).isAllSeatsSelected;
            const filterFromQuery = getQueryParams(state) as IWorkAccidentsFilter;

            const result = await api.interventions.workAccidents.fetchWorkAccidents(
                { companyCode, showFullFamily }, filterFromQuery,
            );
            return fetchWorkAccidentsActions.succeeded(result);
        } catch (error) {
            return fetchWorkAccidentsActions.failed(error);
        }
    },
    latest: false,
});

// fetchWorkAccidentFilesEpic
createEpic<IFetchWorkAccidentDocumentPayload>({
    onActionType: FETCH_WORK_ACCIDENT_FILES,
    async processMultiple({ getState, action, api }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);

            const result = await api.interventions.workAccidents.fetchWorkAccidentFiles(
                action.payload, companyCode,
            );
            dispatch(fetchWorkAccidentsFilesActions.succeeded(result));
            return done();
        } catch (error) {
            dispatch(fetchWorkAccidentsFilesActions.failed(error));
            return done();
        }
    },
    latest: false,
});
