import React from 'react';

import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import LabelInfo from '../../../../common/widget/LabelInfo';
import { getPrefixedTranslation } from '../AppointmentDetailDialog.helper';

import { IExecutedCompanyVisitAppointmentDialogProps } from './ExecutedCompanyVisitAppointmentDialog.type';

const ExecutedCompanyVisitAppointmentDialog = ({
    data,
}: IExecutedCompanyVisitAppointmentDialogProps) => {

    return (
        <>
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.where')}
                info={data.branch.name}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.who')}
                info={formatPersonName({
                    firstName: data.visitor.firstName,
                    name: data.visitor.lastName,
                })}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.company_visit_reason')}
                info={data.visitReason.description}
                hideColon={true}
            />
        </>
    );
};

export { ExecutedCompanyVisitAppointmentDialog };
