import { IFaqOverview } from '../../models/contact/faq';
import { get } from '../../utils/api/requestWrapper';

export const URL = {
    FAQ_OVERVIEW: '/faq',
};

export function fetchFaqOverview() {
    return get<IFaqOverview>({
        url: URL.FAQ_OVERVIEW,
        mapResponse: (response) => response.items,
    });
}
