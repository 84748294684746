import React from 'react';

import { ListItem } from '../../../../../../../models/general/list';
import FreeSlotsIndicator from '../../../../../../common/widget/FreeSlotsIndicator';
import { ISelectBufferzoneOverviewColumnNames } from '../SelectBufferzoneOverview.type';

export const renderFreeSlots = (item: ListItem<ISelectBufferzoneOverviewColumnNames>) => {
    return (
        <FreeSlotsIndicator
            maxSlots={item.columns.totalSlots as number}
            freeSlots={item.columns.freeSlots as number}
            showMaxSlots
        />
    );
};
