import { ICompanySeat } from '../../../../models/admin/company';

export default function filterCompanySeats(
    companySeatsAndDivisions: ICompanySeat[] = [],
): ICompanySeat[] {
    return companySeatsAndDivisions.filter((seat) => {
        const companyCode = seat.company.companyCode;
        return /^[0-9]+\.[0-9]+$/.test(companyCode);
    });
}
