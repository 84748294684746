import React, { PureComponent } from 'react';
import './agenda-filter-form.scss';
import Translate from '../../../../common/Translate';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import { IAgendaQueryParams, IAgendaRoutePayload } from '../../../../../models/planning/agenda';
import IconHeader from '../../../../common/headers/IconHeader';
import EventTypeFilter from '../../../../common/widget/Calendar/EventTypeFilter';
import { getQueryParams } from '../../../../../redux/location/selectors';
import { navigateTo } from '../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../routeKeys';
import { ICalendarEvent, CalendarEventType } from '../../../../../models/ui/calendar';
import connect from '../../../../../utils/libs/redux/connect';
import { ITranslator } from '../../../../../models/general/i18n';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import TextInput from '../../../../common/input/TextInput';
import GoBack from '../../../../common/navigation/GoBack';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import FormFieldError from '../../../../common/forms/FormFieldError';
import classNames from 'classnames';
import { startEndDateSchema } from '../../../../common/input/StartEndDateFilter/startEndDateSchema';
import StartEndDateFilter from '../../../../common/input/StartEndDateFilter';
import { getDefaultSearchDates } from '../../../../../config/calendar.config';

const CLASS_NAME = 'AgendaFilterForm';
const FORM_NAME = 'agenda-search-filter-form';

interface IFormValues extends Pick<IAgendaQueryParams, 'startDate' | 'endDate'> {
    search: string;
}

interface IPrivateProps {
    dateRangeFilter: Pick<IAgendaQueryParams, 'startDate' | 'endDate'>;
    search: string;
    selectedDate: string;
    onSearch: (filter: IFormValues) => void;
}

interface IContextProps {
    translator: ITranslator;
}

interface IAgendaFilterFormProps {
    eventTypesIncludedInFilter: CalendarEventType[];
    onEventTypeFilterChange: (newSelectedEventTypes: CalendarEventType[]) => void;
    calendarEvents: ICalendarEvent[];
    onSearchTriggered?: () => void;
    hidePreviousButton?: boolean;
}

class AgendaFilterForm extends PureComponent<IAgendaFilterFormProps & IPrivateProps & IContextProps> {

    private initialValues: IFormValues = null;

    constructor(props: IAgendaFilterFormProps & IPrivateProps & IContextProps) {
        super(props);

        this.initialValues = {
            ...props.dateRangeFilter,
            search: props.search,
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    public render() {
        const {
            eventTypesIncludedInFilter,
            onEventTypeFilterChange, translator,
            selectedDate, hidePreviousButton,
        } = this.props;

        const actionsClassName = classNames(`${CLASS_NAME}__actions`, {
            [`${CLASS_NAME}__actions--no-previous-button`]: !!hidePreviousButton,
        });
        return (
            <Form
                name={FORM_NAME}
                initialValues={this.initialValues}
                schema={startEndDateSchema}
                handleSubmit={this.onFormSubmit}
                render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                    const { touched, values, handleChange, errors } = formRenderProps;

                    return (
                        <div className={CLASS_NAME}>
                            <IconHeader iconTypeName="search">
                                <Translate msg="planning.agenda.search.filter.search_subtitle" />
                            </IconHeader>
                            <TextInput
                                id="agenda-search-input"
                                name="search"
                                value={values.search}
                                onChange={handleChange}
                                placeholder={translator('planning.agenda.search.filter.search_placeholder')}
                                isInvalid={touched.search && !!errors.search}
                            />
                            {touched.search &&
                                <FormFieldError
                                    error={errors.search}
                                    placeholders={{
                                        fieldName: translator(
                                            'planning.agenda.search.filter.keyword',
                                        ),
                                    }}
                                />}
                            <IconHeader iconTypeName="sliders">
                                <Translate msg="planning.agenda.search.filter.filter_subtitle" />
                            </IconHeader>
                            <StartEndDateFilter
                                translationKeyPrefix="planning.agenda.search.filter"
                                formRenderProps={formRenderProps}
                                hideTitle={true}
                            />
                            <EventTypeFilter
                                eventTypesIncludedInFilter={eventTypesIncludedInFilter}
                                onFilterChange={onEventTypeFilterChange}
                            />
                            <div className={actionsClassName}>
                                {!hidePreviousButton &&
                                    <GoBack
                                        id="agenda-search-stop"
                                        to={{
                                            type: ROUTE_KEYS.R_AGENDA,
                                            payload: {
                                                viewType: 'calendar',
                                            } as IAgendaRoutePayload,
                                            meta: {
                                                query: {
                                                    selectedDate,
                                                } as IAgendaQueryParams,
                                                location: null,
                                            },
                                        }}
                                    />
                                }
                                <SubmitButton
                                    id="agenda-search-submit-button"
                                    size={hidePreviousButton ? undefined : 'small'}
                                    formName={FORM_NAME}
                                    enabledWhenValid={true}
                                    disabledWhenInvalid={true}
                                >
                                    <Translate msg="planning.agenda.search.submit" />
                                </SubmitButton>
                            </div>
                        </div>
                    );
                }}
            />
        );
    }

    private onFormSubmit(filter: IFormValues) {
        const { onSearch, onSearchTriggered } = this.props;
        if (typeof onSearchTriggered === 'function') {
            onSearchTriggered();
        }
        onSearch(filter);
    }

}

const AgendaFilterFormWithState = connect<IPrivateProps, IContextProps & IAgendaFilterFormProps>({
    stateProps: (state) => {
        const {
            startDate,
            endDate,
            search,
            selectedDate,
        } = getQueryParams<IAgendaQueryParams>(state);
        const { defaultSearchFrom, defaultSearchUntil } = getDefaultSearchDates(selectedDate);
        const searchDateFrom = startDate || defaultSearchFrom;
        const searchDateUntil = endDate || defaultSearchUntil;
        return {
            dateRangeFilter: {
                startDate: searchDateFrom,
                endDate: searchDateUntil,
            },
            search,
            selectedDate,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSearch: (filter: IFormValues) => {
                const state = getState();
                const currentQuery = getQueryParams<IAgendaQueryParams>(state);

                dispatch(navigateTo(
                    ROUTE_KEYS.R_AGENDA_SEARCH,
                    {
                        viewType: 'list',
                        shouldRefreshData: true,
                    } as IAgendaRoutePayload,
                    { ...currentQuery, ...filter },
                ));
            },
        };
    },
})(AgendaFilterForm);

export default function AgendaFilterFormWithContext(publicProps: IAgendaFilterFormProps) {
    return (
        <TranslatorContext.Consumer>
            {(props) => <AgendaFilterFormWithState {...publicProps} translator={props.translator} />}
        </TranslatorContext.Consumer>
    );
}
