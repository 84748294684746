import { ITimeSlotBase } from '../../../models/interventions/timeslots';
import { getDate } from '../../core/date/getSpecificDate';
import { getMinutesBetweenDates } from '../../core/date/getDifferenceBetweenDates';
import { stringComparerAscending } from '../../list/comparerUtils';

export function getMinutesAvailableAfterTimeslot(
    timeCell: ITimeSlotBase,
    timeCells: ITimeSlotBase[],
) {
    let freeMinutesAfterTimeslot = 0;

    // Get start & end of current timecell
    const timeCellStart = getDate(timeCell.start);
    // Use this variable to store the end of the latest successive timeslot
    let latestEnd = getDate(timeCell.end);

    // Sort timecells by date
    const timeCellsSortedByDate = timeCells.sort(sortTimeslotsByDate);
    const timeCellIndex = timeCellsSortedByDate
        .map((timeCell) => timeCell.id)
        .indexOf(timeCell.id);

    // We calculate the time available after a time slot by adding up the
    // durations of successive time slots until an interruption is detected.
    for (let i = timeCellIndex + 1; i < timeCellsSortedByDate.length; i = i + 1) {
        const otherTimeCell = timeCells[i];

        // Store dates of next time slot
        const otherStart = getDate(otherTimeCell.start);
        const otherEnd = getDate(otherTimeCell.end);

        // Store duration of first time slot to subtract from available time
        // This way we know how many minutes available time we have after a given time slot.
        const duration = getMinutesBetweenDates(getDate(timeCell.end), timeCellStart);

        // Validate whether the next time slot is continuous/successive with the current time slot
        // If so, subtract the initial time slot duration (the one we want to
        // calculate "the available time after" from) from the duration of all succesive
        // time slots combined.

        // The end date of this time slot is saved in 'latestEnd' because we need to check
        // if upcoming time slots are successive.
        if (latestEnd >= otherStart) {
            freeMinutesAfterTimeslot = getMinutesBetweenDates(otherEnd, timeCellStart) - duration;

            latestEnd = otherEnd;
        }
    }

    return freeMinutesAfterTimeslot;
}

function sortTimeslotsByDate(a: ITimeSlotBase, b: ITimeSlotBase) {
    return stringComparerAscending(a.start, b.start);
}
