import isSet from '@snipsonian/core/es/is/isSet';
import { mergeDeepRight } from 'ramda';

import store, { readStateFromLocalStorage } from '../store';
import { IState } from '../IState';
import { STATE_STORAGE_KEY } from '../../config/redux.config';
import { getCsrfToken, isLoggedIn } from '../auth/selectors';
import {
    navigateToCompanySelection, navigateToSeatsSelection,
    redirectToHome, redirectToLogin,
} from '../location/actions';
import { getLocationState, getRouteKey } from '../location/selectors';
import { shouldUserFirstSelectACompanyOrSeat } from '../navigation/validateEachRouteEpic';
import { triggerCustomNotification } from '../notifications/actions';
import { setCsrfToken } from '../../utils/api/requestWrapper';
import { onStorageUpdatedByOtherBrowserTabOrWindow } from '../../utils/browser/storage';
import { diffPropValuesDeep } from '../../utils/core/object/diffObjects';
import { hasProps } from '../../utils/core/object/objectProps';
import { reportInfo } from '../../utils/logging/errorReporter';

const CHANGE_BY_OTHER_TAB_NOTIFICATIONID = 'CHANGE_BY_OTHER_TAB_NOTIFICATIONID';

/**
 * As 'localStorage' data is shared across different tabs, we here keep the localStorage state props in sync between
 * the different klantenzone tabs.
 *
 * This will affect for example the following scenario's:
 * - another tab gets a new csrf token:
 *   > login + keep tab open
 *   > after that 1st session expired (e.g. next day), login on another tab
 *   > click something in your first tab
 *   ==> in the first tab the user would see csrf token errors because the cookie is now ok again (following
 *       re-login in second tab) but this tab would still have the old/previous csrf token
 *
 * - the user selects another company/seat in another tab OR even logs in with another user
 *   > we can't allow another company because otherwise, after page refresh of the 1st tab, the user would
 *     suddenly see the company of the 2nd tab
 */
export default function checkStoreChangesByOtherBrowserTabsOrWindows() {
    onStorageUpdatedByOtherBrowserTabOrWindow({
        storageKeyToWatch: STATE_STORAGE_KEY,
        handler: () => {
            const latestLocalStorageStateChanges = getLocalStorageStatePropsUpdatedByOtherTab();

            if (hasProps(latestLocalStorageStateChanges)) {
                const updatedState = directlyOverwriteStateWithoutDispatchingAction(latestLocalStorageStateChanges);

                let shouldShowNotification = false;
                const specificChanges = getStateChangesThatRequireSpecialAttention(latestLocalStorageStateChanges);

                if (isSet(specificChanges.changedCsrfToken)) {
                    setCsrfToken(getCsrfToken(updatedState));
                    reportTabsInfo('Copied csrf token that was renewed in another browser tab.');
                }

                if (isSet(specificChanges.changedIsLoggedIn)) {
                    shouldShowNotification = true;

                    if (isLoggedIn(updatedState)) {
                        redirectToCompanyOrSeatSelectionOrToHome(updatedState);
                    } else {
                        store.dispatch(
                            redirectToLogin(getLocationState(store.getState())),
                        );
                    }
                } else if (isSet(specificChanges.changedCompanyOrSeat)) {
                    if (isLoggedIn(updatedState)) {
                        shouldShowNotification = true;

                        redirectToCompanyOrSeatSelectionOrToHome(updatedState);
                    }
                }

                if (shouldShowNotification) {
                    store.dispatch(triggerCustomNotification({
                        id: CHANGE_BY_OTHER_TAB_NOTIFICATIONID,
                        titleTranslationKey: 'app_shell.notifications.change_by_other_browser_tab.title',
                        messageTranslationKey: 'app_shell.notifications.change_by_other_browser_tab.message',
                    }));
                    reportTabsInfo('Showed screen-renewed-notification following changes by another browser tab.');
                }
            }
        },
    });
}

interface ISpecificStateChanges {
    changedIsLoggedIn?: boolean;
    changedCsrfToken?: boolean;
    changedCompanyOrSeat?: boolean;
}

function getStateChangesThatRequireSpecialAttention(stateChanges: Partial<IState>): ISpecificStateChanges {
    const specificChanges: ISpecificStateChanges = {};
    if (stateChanges.auth) {
        if (isSet(stateChanges.auth.isLoggedIn)) {
            specificChanges.changedIsLoggedIn = true;
        }
        if (isSet(stateChanges.auth.csrfToken)) {
            specificChanges.changedCsrfToken = true;
        }
    }
    if (stateChanges.company_selected) {
        specificChanges.changedCompanyOrSeat = true;
    }
    return specificChanges;
}

function redirectToCompanyOrSeatSelectionOrToHome(updatedState: IState) {
    const should = shouldUserFirstSelectACompanyOrSeat(getRouteKey(updatedState), updatedState);

    if (should.indeed) {
        if (should.selectedCompany) {
            store.dispatch(
                navigateToSeatsSelection(should.selectedCompany.companyCode),
            );
        } else {
            store.dispatch(
                navigateToCompanySelection(),
            );
        }
    } else {
        store.dispatch(
            redirectToHome(),
        );
    }
}

function getLocalStorageStatePropsUpdatedByOtherTab(): Partial<IState> {
    const wholeStateCurrentTab = store.getState();
    const latestStateOtherTab = readStateFromLocalStorage();

    return diffPropValuesDeep({
        master: latestStateOtherTab || {},
        other: wholeStateCurrentTab,
        propNamesToIgnore: ['isFetching', 'error', 'isDoing', 'isDone'],
    }) as IState;
}

/**
 * We don't update the state via an action because:
 * - this redux update will also do a localStorage, but that is overkill since the values are already there
 *   p.s. this would also trigger the storage event in the other tab that did the original update, and so on
 *        so without a proper diff this would have resulted in an endless loop
 * - more importantly, we also have to do a redirect to another page (login / home / etc.)
 *   - but doing the action update 'before' the redirect caused errors when the other tab did a logout (error
 *     because current page can't be rendered properly anymore)
 *   - doing first the redirect gave problems when doing a login
 *   - so doing the update directly (without an action) makes it much less complex as this direct update does not
 *     retrigger a react re-rendering
 */
function directlyOverwriteStateWithoutDispatchingAction(latestLocalStorageStateChanges: Partial<IState>): IState {
    const state = store.getState();

    Object.keys(latestLocalStorageStateChanges)
        .forEach((reducerKey) => {
            const changesInReducerState = latestLocalStorageStateChanges[reducerKey];
            state[reducerKey] = mergeDeepRight(state[reducerKey], changesInReducerState);
        });

    return state;
}

function reportTabsInfo(message: string) {
    reportInfo(message, { category: 'multiple_tabs' });
}
