import React, { Component } from 'react';
import { IRenderDetailContentProps } from '../../../../common/widget/MasterWithDetail/typings';
import {
    IUserAccountDetail,
    AdminType,
    IUpdateUserAccountPayload,
} from '../../../../../models/user/userAccount';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import { fields, schema } from './updateUserSchema';
import { TextPropertyInput, ConstantsTypeaheadPropertyInput } from '../../../../common/input/PropertyInput';
import Translate from '../../../../common/Translate';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import { connect } from '../../../..';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import TooltipWithIcon from '../../../../common/widget/TooltipWithIcon';
import AccessToList from '../../shared/AccessToList';
import Loader from '../../../../common/waiting/Loader';
import { updateCompanyUserDetailsActions } from '../../../../../redux/company/users/actions';
import { getMyUsername } from '../../../../../redux/auth/selectors';
import SelectUserTypeField from '../AddUser/SelectUserTypeField';
import Icon from '../../../../common/icons/Icon';
import SelectSeatsField from '../AddUser/SelectSeatsField';
import { ConstantType } from '../../../../../models/general/constants';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { AsyncStatus } from '../../../../../models/general/redux';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../../utils/formatting/formatPhone';

export const FORM_NAME = 'user-detail-fields-form';

interface IPrivateProps {
    translator: ITranslator;
    myUsername: string;
    updateDetails: (values: IUpdateUserAccountPayload) => void;
}

class DetailContent extends Component<IPrivateProps & IRenderDetailContentProps<IUserAccountDetail>> {

    constructor(props: IPrivateProps & IRenderDetailContentProps<IUserAccountDetail>) {
        super(props);

        this.renderFooter = this.renderFooter.bind(this);
    }

    public render() {
        const { detailData, detailUpdateAsyncInfo, translator, myUsername, updateDetails } = this.props;
        if (!detailData) {
            return null;
        }

        const infoIcon = (
            <Icon
                typeName="info"
                circle
            />
        );

        const {
            firstName, name, titleId, email,
            languageId,
            admin, accessLevel,
            seats, mobilePhoneNumber, phoneNumber,
            webUserId, sexId, username,
        } = this.props.detailData;

        const initialValues: IUpdateUserAccountPayload = {
            name,
            firstName,
            titleId,
            currentEmail: email,
            newEmail: email,
            languageId,
            phoneNumber: tryFormattingPhoneInternational(phoneNumber),
            mobilePhoneNumber: tryFormattingPhoneInternational(mobilePhoneNumber),
            admin,
            accessLevel,
            seats,
            webUserId,
            sexId,
            username,
            seatsToAdd: [],
            seatsToRemove: [],
        };

        const isCurrentUser = username === myUsername;

        return (
            <Form
                name={FORM_NAME}
                initialValues={initialValues}
                schema={schema}
                footer={<SubmitButton
                    id="manage-users-detail-submit-button"
                    formName={FORM_NAME}
                >
                    <Translate msg="account.account_settings.manage_users.detail.content.submit" />
                </SubmitButton>}
                handleSubmit={updateDetails}
                render={(renderProps: IFormRenderProps<IUpdateUserAccountPayload>) => {
                    const {
                        values, handleChange, errors, setFieldValue, touched,
                    } = renderProps;
                    return (
                        <Loader show={detailUpdateAsyncInfo.status}>
                            <ConstantsTypeaheadPropertyInput
                                id={fields.titleId}
                                name={fields.titleId}
                                labelKey="account.account_settings.manage_users.detail.content.fields.salutation"
                                readonly={isCurrentUser}
                                value={values.titleId}
                                constantType={ConstantType.TITLES}
                                onItemSelected={(value) => setFieldValue('titleId', Number(value))}
                                isInvalid={touched.titleId && !!errors.titleId}
                            >
                                {touched.titleId && (
                                    <FormFieldError
                                        error={errors.titleId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.salutation'),
                                        }}
                                    />
                                )}
                            </ConstantsTypeaheadPropertyInput>
                            <TextPropertyInput
                                id={fields.firstName}
                                name={fields.firstName}
                                labelKey="account.account_settings.manage_users.detail.content.fields.first_name"
                                readonly={isCurrentUser}
                                value={values.firstName}
                                onChange={handleChange}
                                isInvalid={touched.firstName && !!errors.firstName}
                            >
                                {touched.firstName && (
                                    <FormFieldError
                                        error={errors.firstName}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.first_name'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id={fields.name}
                                name={fields.name}
                                labelKey="account.account_settings.manage_users.detail.content.fields.name"
                                readonly={isCurrentUser}
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={touched.name && !!errors.name}
                            >
                                {touched.name && (
                                    <FormFieldError
                                        error={errors.name}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.name'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <ConstantsTypeaheadPropertyInput
                                id={fields.sexId}
                                name={fields.sexId}
                                labelKey="account.account_settings.manage_users.detail.content.fields.sex"
                                readonly={isCurrentUser}
                                value={values.sexId}
                                constantType={ConstantType.SEXES}
                                onItemSelected={(value) => setFieldValue('sexId', Number(value))}
                                isInvalid={touched.sexId && !!errors.sexId}
                            >
                                {touched.sexId &&
                                    <FormFieldError
                                        error={errors.sexId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.sex'),
                                        }}
                                    />}
                            </ConstantsTypeaheadPropertyInput>
                            <ConstantsTypeaheadPropertyInput
                                id={fields.languageId}
                                name={fields.languageId}
                                labelKey="account.account_settings.manage_users.detail.content.fields.language"
                                readonly={isCurrentUser}
                                value={values.languageId}
                                constantType={ConstantType.LANGUAGES}
                                onItemSelected={(value) => setFieldValue('languageId', Number(value))}
                                isInvalid={touched.languageId && !!errors.languageId}
                            >
                                {touched.languageId && (
                                    <FormFieldError
                                        error={errors.languageId}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.language'),
                                        }}
                                    />
                                )}
                            </ConstantsTypeaheadPropertyInput>
                            <TextPropertyInput
                                id={fields.newEmail}
                                name={fields.newEmail}
                                labelKey="account.account_settings.manage_users.detail.content.fields.email"
                                value={values.newEmail}
                                onChange={handleChange}
                                isInvalid={touched.newEmail && !!errors.newEmail}
                                readonly
                            >
                                {touched.newEmail && (
                                    <FormFieldError
                                        error={errors.newEmail}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.email'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id={fields.phoneNumber}
                                name={fields.phoneNumber}
                                labelKey="account.account_settings.manage_users.detail.content.fields.phone"
                                readonly={isCurrentUser}
                                value={values.phoneNumber}
                                onChange={handleChange}
                                isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                            >
                                {touched.phoneNumber && (
                                    <FormFieldError
                                        error={errors.phoneNumber}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.phone'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>
                            <TextPropertyInput
                                id={fields.mobilePhoneNumber}
                                name={fields.mobilePhoneNumber}
                                labelKey="account.account_settings.manage_users.detail.content.fields.mobile_phone"
                                readonly={isCurrentUser}
                                value={values.mobilePhoneNumber}
                                onChange={handleChange}
                                isInvalid={touched.mobilePhoneNumber && !!errors.mobilePhoneNumber}
                            >
                                {touched.mobilePhoneNumber && (
                                    <FormFieldError
                                        error={errors.mobilePhoneNumber}
                                        placeholders={{
                                            // eslint-disable-next-line max-len
                                            fieldName: translator('account.account_settings.manage_users.detail.content.fields.mobile_phone'),
                                        }}
                                    />
                                )}
                            </TextPropertyInput>

                            {admin !== AdminType.MainAdministrator &&
                                <SelectUserTypeField {...renderProps} disabled={isCurrentUser} />
                            }

                            <h4>
                                <Translate
                                    msg="account.account_settings.manage_users.detail.content.access_level.title"
                                />
                                <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                                    <Translate
                                        msg="account.account_settings.manage_users.detail.content.access_level.help"
                                    />
                                </TooltipWithIcon>
                            </h4>
                            <AccessToList
                                accessLevel={values.accessLevel}
                                readOnly={isCurrentUser}
                                onChange={(accessLevel) => setFieldValue('accessLevel', accessLevel)}
                            />

                            {Number(values.admin) === AdminType.None &&
                                <>
                                    <h4>
                                        <Translate
                                            msg="account.account_settings.manage_users.detail.content.seats.title"
                                        />
                                    </h4>
                                    <SelectSeatsField disabled={isCurrentUser} {...renderProps} />
                                </>
                            }
                        </Loader>
                    );
                }}
            />
        );
    }

    private renderFooter() {
        const { detailAsyncInfo, detailData, myUsername, detailUpdateAsyncInfo } = this.props;
        if (detailAsyncInfo.status !== AsyncStatus.Success) {
            return null;
        }

        const isCurrentUser = detailData.username === myUsername;
        if (isCurrentUser) {
            return null;
        }

        return (
            <SubmitButton
                id="manage-users-detail-submit-button"
                formName={FORM_NAME}
                disabled={detailUpdateAsyncInfo.status === AsyncStatus.Busy}
            >
                <Translate msg="account.account_settings.manage_users.detail.content.submit" />
            </SubmitButton>
        );
    }

}

export default connect<IPrivateProps, IRenderDetailContentProps<IUserAccountDetail>>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
        myUsername: getMyUsername(state),
    }),
    dispatchProps: (dispatch) => ({
        updateDetails: (values: IUpdateUserAccountPayload) => {
            const formattedValues: IUpdateUserAccountPayload = {
                ...values,
                phoneNumber: tryFormattingPhoneForBackend(values.phoneNumber),
                mobilePhoneNumber: tryFormattingPhoneForBackend(values.mobilePhoneNumber),
            };
            dispatch(updateCompanyUserDetailsActions.trigger(formattedValues));
        },
    }),
})(DetailContent);
