import React, { ReactElement } from 'react';
import './tooltip-with-icon.scss';
import Icon, { IIconProps } from '../../icons/Icon';
import Tooltip, { ITooltipProps } from '../Tooltip';
import { isExtraSmallScreen } from '../../../../utils/dom/screenSize';
import classNames from 'classnames';
import { Placement } from 'popper.js';

export interface ITooltipWithIconProps
    extends Pick<ITooltipProps, 'onOpen' | 'onClose' | 'children' | 'delayBeforeShow' | 'icon'> {
    tooltipBubbleIcon?: ReactElement<IIconProps>;
    iconSize?: 'small' | 'large';
    typeName?: 'tooltip' | 'info-bubble';
    placement?: Placement;
    label?: string | React.ReactElement<{}>;
}

const CLASS_NAME = 'TooltipWithIcon';

export default function TooltipWithIcon(props: ITooltipWithIconProps) {
    const isSmall = props.iconSize === 'small';
    const className = classNames(`${CLASS_NAME}__icon`, {
        [`${CLASS_NAME}__icon--small`]: isSmall,
    });

    const icon = props.icon || (
        <Icon
            typeName="info"
            circle
        />
    );
    const typeName = props.typeName || 'info-bubble';

    const toolTipTarget = (
        <span className={className}>
            {icon}
        </span>
    );

    const spaceBetweenTargetLarge = isExtraSmallScreen() ? -33 : -24;
    const spaceBetweenTargetSmall = spaceBetweenTargetLarge + 6;

    const toolTip = (
        <Tooltip
            target={toolTipTarget}
            spaceBetweenTarget={isSmall ? spaceBetweenTargetSmall : spaceBetweenTargetLarge}
            onOpen={props.onOpen}
            onClose={props.onClose}
            delayBeforeShow={props.delayBeforeShow}
            icon={props.tooltipBubbleIcon}
            typeName={typeName}
            placement={props.placement}
        >
            {props.children}
        </Tooltip>
    );

    return props.label ? (
        <>
            {/* Prevent line break between last word & tooltip icon */}
            {props.label}<span className={`${CLASS_NAME}--keep-icon-with-label`}>&nbsp;{toolTip}</span>
        </>
    ) : toolTip;
}
