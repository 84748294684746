import {
    IFetchFluVaccinesOrdersPayload,
    IFluVaccinesOrder,
    IFluVaccinesConfig,
    IFluVaccineTariffs,
    ICreateFluVaccinesOrder,
    IUpdateFluVaccinesOrderPayload,
    IFluVaccinesAvailable,
} from '../../models/interventions/fluVaccines';
import { ICompanyCodePayload } from '../../models/admin/company';
import { get, post, put } from '../../utils/api/requestWrapper';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';

export const URL = {
    COMPANY_FLU_VACCINES_ORDERS: '/companies/{companyCode}/vaccines',
    COMPANY_FLU_VACCINES_ORDER: '/companies/{companyCode}/vaccines/{id}',
    COMPANY_FLU_VACCINE_TARIFF: '/companies/{companyCode}/vaccines/tariff',
    CMS_FLU_VACCINES_CONFIG: '/flu-shots',
    FLU_VACCINES: '/vaccines',
    FLU_VACCINES_AVAILABLE: '/vaccines/available',
};

export function fetchFluVaccinesOrders(
    { companyCode, showFullFamily }: IFetchFluVaccinesOrdersPayload,
) {
    return get<IFluVaccinesOrder[]>({
        url: URL.COMPANY_FLU_VACCINES_ORDERS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['vaccines'],
    });
}

export const DEFAULT_FLU_ORDERS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-1)),
    endDate: formatDateForBackend(now()),
};

export function fetchFluVaccinesConfig() {
    return get<IFluVaccinesConfig>({
        url: URL.CMS_FLU_VACCINES_CONFIG,
        mapResponse: (response) => response.config,
    });
}

export function fetchFluVaccineTariffs({ companyCode }: ICompanyCodePayload) {
    return get<IFluVaccineTariffs>({
        url: URL.COMPANY_FLU_VACCINE_TARIFF,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => ({
            influenzaTariff: parseFloat(response.influenzaTariff),
            travelCostTariff: parseFloat(response.travelCostTariff),
        }),
    });
}

export function createFluVaccinesOrder(body: ICreateFluVaccinesOrder) {
    return post({
        url: URL.FLU_VACCINES,
        body,
    });
}

export function updateVaccinesOrder({ companyCode, showFullFamily, data }: IUpdateFluVaccinesOrderPayload) {
    const { id, ...body } = data;

    return put({
        url: URL.COMPANY_FLU_VACCINES_ORDER,
        pathParams: {
            companyCode,
            id,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        body,
    });
}

export function fetchAvailableFluVaccines() {
    return get<IFluVaccinesAvailable>({
        url: URL.FLU_VACCINES_AVAILABLE,
    });
}
