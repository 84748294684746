import React, {
    ReactNode, PureComponent, KeyboardEvent,
    MouseEvent, ReactElement, ReactChild, isValidElement, createElement, StatelessComponent,
} from 'react';
import './dropdown.scss';
import DropdownItem, { IDropdownItemProps, TDropdownItemValue } from './DropdownItem';
import Popper from '../../technical/Popper';
import { isKeyPressed } from '../../../../utils/keyboard';
import classNames from 'classnames';
import Icon from '../../icons/Icon';
import { Placement } from 'popper.js';
import { IPublicProps as ITranslateProps } from '../../Translate';

export interface IRenderDropdownTargetProps {
    label: string | ReactElement<ITranslateProps>;
    hasItems: boolean;
    isOpen: boolean;
    dropdownItemsPlacement: Placement;
    onClick: () => void;
    onKeyUp: (e: KeyboardEvent<HTMLElement>) => void;
    onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
}

interface IDropdownRenderProps {
    focusedItemIndex: number;
    isDropdownOpen: boolean;
}

interface IDropdownProps {
    id: string;
    isOpen: boolean;
    onOpenIntent: () => void;
    onCloseIntent: () => void;
    children?: ReactNode | ((dropdownRenderProps: IDropdownRenderProps) => ReactElement<{children: ReactNode}>);
    onItemSelected?: (value: TDropdownItemValue) => void;
    selectedItem?: TDropdownItemValue;
    typeName?: 'primary' | 'secondary';
    iconTypeName?: 'primary';
    size?: 'small';
    defaultLabel: string | ReactElement<ITranslateProps>;
    renderTargetComponent?: (targetComponentProps: IRenderDropdownTargetProps) => ReactNode;
    className?: string;
    disabled?: boolean;
}

export interface IExtendedDropdownItemProps extends IDropdownItemProps {
    isSelected: boolean;
    isFocused: boolean;
    onClick: (e: MouseEvent<HTMLElement>) => void;
}

interface IState {
    focusedItemIndex: number;
    currentPlacement: Placement;
}

const CLASS_NAME = 'Dropdown';

class Dropdown extends PureComponent<IDropdownProps, IState> {
    private dropdownItemsContainerRef: HTMLDivElement;
    private dropdownItemsMap: { [value: string]: ReactElement<IDropdownItemProps> };
    private dropdownItems: ReactChild[];

    constructor(props: IDropdownProps) {
        super(props);

        this.state = {
            focusedItemIndex: 0,
            currentPlacement: null,
        };

        this.onCloseIntent = this.onCloseIntent.bind(this);
        this.onOpenIntent = this.onOpenIntent.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.scrollFocusedElementIntoView = this.scrollFocusedElementIntoView.bind(this);
        this.onPopperPlacementChanged = this.onPopperPlacementChanged.bind(this);
    }

    public render() {
        const {
            id, isOpen, typeName, size, defaultLabel, renderTargetComponent, selectedItem,
            className, iconTypeName, disabled,
        } = this.props;
        const { currentPlacement } = this.state;

        const hasItems = this.dropdownItems.length > 0;

        const dropdownBaseClasses = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--primary`]: typeName === 'primary',
            [`${CLASS_NAME}--secondary`]: typeName === 'secondary',
            [`${CLASS_NAME}--small`]: size === 'small',
            [className]: !!className,
            ['item-selected']: !!selectedItem,
            [`${CLASS_NAME}--icon-primary`]: iconTypeName === 'primary',
            [`${CLASS_NAME}--disabled`]: disabled,
        });

        const dropDownItemsClasses = classNames(dropdownBaseClasses, `${CLASS_NAME}__items`);

        const dropdownClasses = classNames(dropdownBaseClasses, {
            [`${CLASS_NAME}--open`]: hasItems && isOpen,
            [`${CLASS_NAME}--has-items`]: hasItems,
            [`${CLASS_NAME}--placement-top`]: currentPlacement && currentPlacement.indexOf('top') === 0,
        });

        const label = (this.getSelectedItemLabel() || defaultLabel) as string | ReactElement<ITranslateProps>;
        const onClick = hasItems && !disabled ? this.onOpenIntent : undefined;
        const dropdownEl = (
            <>
                {typeof renderTargetComponent === 'function'
                    ? renderTargetComponent({
                        label, hasItems,
                        onClick,
                        onKeyDown: this.onKeyDown,
                        onKeyUp: this.onKeyUp,
                        isOpen,
                        dropdownItemsPlacement: currentPlacement,
                    }) :
                    (
                        <div
                            id={id}
                            title={typeof defaultLabel === 'string' ? defaultLabel : undefined}
                            className={dropdownClasses}
                            tabIndex={0}
                            onClick={onClick}
                            onKeyUp={this.onKeyUp}
                            onKeyDown={this.onKeyDown}
                        >
                            {label}
                            {hasItems && <Icon typeName="chevron-down" />}
                        </div>
                    )
                }
            </>
        );

        if (!hasItems) {
            return dropdownEl;
        }

        return (
            <Popper
                isOpen={isOpen}
                target={dropdownEl}
                onClickOutside={this.onCloseIntent}
                onTargetOutsideViewport={this.onCloseIntent}
                spaceBetweenPopper={0}
                useTargetWidth={true}
                onPlacementChanged={this.onPopperPlacementChanged}
            >
                {({ placement }) => {
                    const classes = classNames(dropDownItemsClasses, {
                        [`${CLASS_NAME}__items--placement-top`]: placement && placement.indexOf('top') === 0,
                    });

                    return (
                        <div
                            className={classes}
                            ref={(ref) => (this.dropdownItemsContainerRef = ref)}
                        >
                            {this.dropdownItems}
                        </div>
                    );
                }}
            </Popper>
        );
    }

    public componentDidUpdate(prevProps: IDropdownProps, prevState: IState) {
        if (this.props.isOpen && prevState.focusedItemIndex !== this.state.focusedItemIndex) {
            this.scrollFocusedElementIntoView();
        }
        // Reset the focused item when the amount of dropdown item decreases and the selected index is out of bounds
        if (this.props.isOpen && this.state.focusedItemIndex >= this.dropdownItems.length) {
            this.setState({
                focusedItemIndex: 0,
            });
        }
    }

    public UNSAFE_componentWillMount() {
        this.mapDropdownItems(this.props, this.state);
    }

    public UNSAFE_componentWillUpdate(nextProps: IDropdownProps, nextState: IState) {
        if (
            this.props.children !== nextProps.children ||
            this.props.selectedItem !== nextProps.selectedItem ||
            this.state.focusedItemIndex !== nextState.focusedItemIndex
        ) {
            this.mapDropdownItems(nextProps, nextState);
        }
    }

    private onCloseIntent() {
        const { onCloseIntent, isOpen } = this.props;
        if (isOpen) {
            onCloseIntent();
        }
    }

    private onOpenIntent() {
        const { onOpenIntent, isOpen } = this.props;
        if (!isOpen) {
            onOpenIntent();
        }
    }

    private onDropdownItemClick(e: MouseEvent<HTMLElement>, value: TDropdownItemValue) {
        const { onItemSelected } = this.props;
        if (typeof onItemSelected === 'function') {
            onItemSelected(value);
        }
        this.onCloseIntent();
    }

    private onKeyUp(e: KeyboardEvent<HTMLElement>) {
        if (isKeyPressed(e, 'Escape') && this.props.isOpen) {
            e.preventDefault();
            return this.onCloseIntent();
        }
        if (isKeyPressed(e, 'Enter')) {
            e.preventDefault();
            if (this.props.isOpen) {
                return this.onCloseIntent();
            }
            return this.onOpenIntent();
        }
    }

    private onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        const { isOpen } = this.props;
        const dropdownItemsLength = this.dropdownItems.length;
        if (isOpen && dropdownItemsLength > 0) {
            const { focusedItemIndex } = this.state;
            const isTabKeyPressed = isKeyPressed(e, 'Tab');
            if (isKeyPressed(e, 'ArrowUp') || (isTabKeyPressed && e.shiftKey)) {
                e.preventDefault();

                if (focusedItemIndex > 0) {
                    this.setState({
                        focusedItemIndex: focusedItemIndex - 1,
                    });
                } else if (isTabKeyPressed) {
                    this.onCloseIntent();
                }
            } else if (isKeyPressed(e, 'ArrowDown') || isTabKeyPressed) {
                e.preventDefault();

                if (focusedItemIndex < (dropdownItemsLength - 1)) {
                    this.setState({
                        focusedItemIndex: focusedItemIndex + 1,
                    });
                } else if (isTabKeyPressed) {
                    this.onCloseIntent();
                }
            } else if (isKeyPressed(e, 'Enter')) {
                const focusedElement = this.getFocusedDropdownItem();
                if (focusedElement) {
                    e.preventDefault();

                    const { onItemSelected } = this.props;
                    const value = JSON.parse(
                        focusedElement.getAttribute('data-dropdownitem-value'),
                    ) as TDropdownItemValue;
                    if (typeof onItemSelected === 'function') {
                        onItemSelected(value);
                    } else if (focusedElement.children[0]) {
                        // Could be an a tag or a button, enforce a click
                        const firstChild = focusedElement.children[0] as HTMLElement;
                        firstChild.click();
                    }
                    // Close is done by the "keyup" handler
                }
            }
        }
    }

    private scrollFocusedElementIntoView() {
        const focusedDropdownItemEl = this.getFocusedDropdownItem();
        if (focusedDropdownItemEl) {
            window.requestAnimationFrame(() => {
                this.dropdownItemsContainerRef.scrollTop =
                    focusedDropdownItemEl.offsetTop;
            });
        }
    }

    private getFocusedDropdownItem(): HTMLElement {
        if (this.dropdownItemsContainerRef) {
            return this.dropdownItemsContainerRef.querySelector('[data-dropdownitem-is-focused=true]');
        }
        return null;
    }

    private getSelectedItemLabel(): ReactNode {
        const { selectedItem } = this.props;
        if (selectedItem) {
            const dropdownItem = this.dropdownItemsMap[selectedItem.toString()];
            if (dropdownItem) {
                return dropdownItem.props.children;
            }
        }
        return null;
    }

    private mapDropdownItems(props: IDropdownProps, state: IState) {
        const { children, selectedItem, isOpen } = props;
        const { focusedItemIndex } = state;

        if (children) {
            let childrenComp = null;
            if (typeof children === 'function') {
                const childrenTypedAsFunction =
                    children as ((dropdownRenderProps: IDropdownRenderProps) => ReactElement<{children: ReactNode}>);
                const child = childrenTypedAsFunction({ focusedItemIndex, isDropdownOpen: isOpen });
                if (child.props && child.props.children) {
                    childrenComp = child.props.children;
                } else {
                    childrenComp = child;
                }
            } else {
                childrenComp = children;
            }

            let index = 0;
            this.dropdownItems = React.Children.map(childrenComp, (child) => {
                if (React.isValidElement(child)) {
                    const props = child.props as IDropdownItemProps;
                    if (child.type === DropdownItem) {
                        const extendedProps: IExtendedDropdownItemProps = {
                            ...props,
                            isSelected: selectedItem && props.value &&
                                props.value.toString() === selectedItem.toString(),
                            isFocused: focusedItemIndex === index,
                            onClick: (e) => this.onDropdownItemClick(e, props.value),
                        };
                        index += 1;
                        return createElement<IExtendedDropdownItemProps>(
                            child.type as StatelessComponent<IExtendedDropdownItemProps>,
                            extendedProps);
                    }
                }
                return child;
            }) || [];

            this.dropdownItemsMap = this.dropdownItems.reduce(
                (accumulator, child, index) => {
                    if (isValidElement(child)) {
                        const props = child.props as IDropdownItemProps;
                        if (child.type === DropdownItem) {
                            if (props.value) {
                                accumulator[props.value.toString()] = child;
                            } else {
                                accumulator[`item-${index}`] = child;
                            }
                        }
                    }
                    return accumulator;
                },
                {},
            );
        } else {
            this.dropdownItems = [];
            this.dropdownItemsMap = {};
        }
    }

    private onPopperPlacementChanged(placement: Placement) {
        this.setState({
            currentPlacement: placement,
        });
    }
}

export { Dropdown, DropdownItem, TDropdownItemValue };
