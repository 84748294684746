import { IReducerState, reducerKey } from './reducer';
import { makeAsyncFetchInfoSelector, getReducerState, IState, NO_RERENDER } from '../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Appointments

const getAppointmentsAsyncField = (state: IState) => reducerState(state).appointments;

export const getAppointmentsAsyncInfo = makeAsyncFetchInfoSelector(getAppointmentsAsyncField);

export const getAppointments = (state: IState) => {
    const data = getAppointmentsAsyncField(state).data;
    if (!data || !data.events) {
        return NO_RERENDER.EMPTY_LIST;
    }
    return data.events;
};
export const getAppointmentsCount = (state: IState) => {
    const data = getAppointmentsAsyncField(state).data;
    if (!data || !data.events) {
        return 0;
    }
    return data.count;
};

export const areAppointmentsAvailableForDateRange = (state: IState, range: { dateFrom: string, dateUntil: string }) => {
    const appointmentsInfo = getAppointmentsAsyncField(state).data;
    if (
        appointmentsInfo &&
        appointmentsInfo.dateFrom === range.dateFrom &&
        appointmentsInfo.dateUntil === range.dateUntil
    ) {
        return true;
    }
    return false;
};

// Next appointment

const getUpcomingActivityAsyncField = (state: IState) => reducerState(state).upcomingActivity;

export const getUpcomingActivityAsyncInfo = makeAsyncFetchInfoSelector(getUpcomingActivityAsyncField);

export const getUpcomingActivity = (state: IState) => getUpcomingActivityAsyncField(state).data;

// Agenda Export

export const getAgendaExportData = (state: IState) => reducerState(state).agendaExportData || NO_RERENDER.EMPTY_LIST;
