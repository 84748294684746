import React, { StatelessComponent } from 'react';
import Button, { IButtonProps, IInternalButtonProps } from '../Button';
import connect from '../../../../utils/libs/redux/connect';
import { getFormInfo } from '../../../../redux/ui/form/selectors';
import { IActiveForm } from '../../../../models/ui/form';

interface ISubmitButtonProps extends Pick<
    IButtonProps & IInternalButtonProps,
    'id' | 'children' | 'typeName' | 'size' | 'disabled' | 'outline'
    > {
    formName: string;
    alwaysEnabled?: boolean;
    enabledWhenValid?: boolean;
    disabledWhenInvalid?: boolean;
}

interface IPrivateProps {
    formInfo: IActiveForm;
}

function SubmitButton(props: ISubmitButtonProps & IPrivateProps) {
    const ButtonComp = Button as StatelessComponent<IButtonProps & IInternalButtonProps>;
    return (
        <ButtonComp
            {...props}
            isSubmit={true}
            typeName={props.typeName || 'secondary'}
            disabled={isDisabled(props)}
        />
    );
}

function isDisabled(props: ISubmitButtonProps & IPrivateProps) {
    if (props.alwaysEnabled) {
        return false;
    }

    if (props.enabledWhenValid && props.formInfo.isValid) {
        return false;
    }

    if (props.disabledWhenInvalid && !props.formInfo.isValid) {
        return true;
    }

    return typeof props.disabled !== 'undefined' ? props.disabled : !props.formInfo.dirty;
}

export default connect<IPrivateProps, ISubmitButtonProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                formInfo: getFormInfo(state, publicProps.formName),
            };
        };
    },
})(SubmitButton);
