import {
    FETCH_COMPANIES,
} from './types';
import { createAction, createSuccessAction, createFailAction } from '../../index';
import {
    ICompany, IFetchCompaniesPayload,
} from '../../../models/admin/company';
import { ITraceableApiError } from '../../../models/general/error';

export function fetchCompanies(payload: IFetchCompaniesPayload) {
    return createAction<IFetchCompaniesPayload>(FETCH_COMPANIES, payload);
}

export function fetchCompaniesSucceeded(payload: ICompany[]) {
    return createSuccessAction<ICompany[]>(FETCH_COMPANIES, payload);
}

export function fetchCompaniesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANIES, error);
}
