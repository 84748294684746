import React from 'react';
import { ICalendarEvent } from '../../../../models/ui/calendar';
import { IDialogHeaderProps } from '../../../common/modals/Dialog/DialogHeader';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../config/calendar.config';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';
import { formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import Translate from '../../../common/Translate';
import EventTitle from '../../../common/widget/Calendar/EventTitle';

const TRANSLATION_PREFIX = 'planning.agenda.appointment_detail';

interface IAppointmentDetailHeaderProps {
    selectedEvent: ICalendarEvent;
}

export default function getAppointmentDetailHeaderProps(props: IAppointmentDetailHeaderProps): IDialogHeaderProps {
    if (!props.selectedEvent) {
        return null;
    }

    const translationKeySuffix = !props.selectedEvent.allDay
        ? 'timeboxed_event'
        : typeof props.selectedEvent.afterNoon === 'undefined'
            ? 'all_day_event'
            : !props.selectedEvent.afterNoon
                ? 'morning_event'
                : 'afternoon_event';

    return {
        color: getHeaderColor(props.selectedEvent),
        children: (
            <>
                <h4>
                    <EventTitle event={props.selectedEvent} titleType="appointment-detail" />
                </h4>
                <Translate
                    msg={`${TRANSLATION_PREFIX}.sub_title.${translationKeySuffix}`}
                    placeholders={{
                        date: formatDateInLongFormat(props.selectedEvent.start),
                        startDate: formatTimeOfDateForDisplay(props.selectedEvent.start),
                        endDate: formatTimeOfDateForDisplay(props.selectedEvent.end),
                    }}
                />
            </>
        ),
    };
}

function getHeaderColor(event: ICalendarEvent) {
    return CALENDAR_EVENT_TYPES_CONFIG[event.type].appointmentDetailHeaderColor;
}
