import { get, IResponseType, patch, post, put, remove } from '../../../utils/api/requestWrapper';
import {
    IEmployeeDetails,
    IEmployeeToAdd,
    IEmployeeStatute,
    IEmployeeAbsence,
    IUpdateEmployeePayload,
    IUpdateEmployeeSucceededPayload,
    IFetchEmployeesData,
    IUpdateEmployeeEmploymentPayload,
    ISetEmployeeOutOfServicePayload,
    IAddEmployeeStatutePayload,
    IAddEmployeeAbsencePayload,
    IUpdateEmployeeAbsencePayload,
    IRemoveEmployeeAbsencePayload,
    IEmployeeRisksAndResearches,
    IUpdateEmployeeAllFieldsPayload,
    IPersonalRisk,
    IAddPersonalRiskPayload,
    IAddEmployeeSucceededPayload,
    IChangeEmployeeOutOfServicePayload,
    IClearEmployeeOutOfServicePayload,
    IDuplicateEmployeeRisksPayload,
    IUpdateEmployeeCostCenterPayload,
    IFetchEmployeeByInszRequestPayload,
    IUpdateEmloyeeByInszPayload,
    IUpdateEmloyeeByIdPayload,
    IFetchEmployeeJobStudentPayload,
    IEmployeeJobStudent,
    IUpdateEmployeeJobStudentPayload,
    IAddEmployeeJobStudentPayload,
    IFetchEmployeesWithoutEmailPayload,
    IEmployeeStatusResponse,
    EMPLOYEE_CONDITION,
} from '../../../models/admin/employee';
import { formatDateForBackend } from '../../../utils/formatting/formatDate';
import { IUrlParams } from '../../../utils/api/url/appendQueryParams';
import { dayOffsetFromNow, getDate } from '../../../utils/core/date/getSpecificDate';
import { ONE_SECOND, ONE_MINUTE } from '../../../utils/core/time/periodsInMillis';
import isSetString from '../../../utils/core/string/isSetString';
import { ICompanyCodeWithShowFullFamilyPayload } from '../../../models/admin/company';
import { IDocument } from '../../../models/general/documents';
import { NO_RERENDER } from '../../../redux';
import { compareAscending } from '../../../utils/core/object/sortObjects';
import { bffDefaultApiConfig } from '../../../config/api/bffApiParameters.config';
import { areCompanyCodesDifferent } from '../../../redux/employee/info/selectors';
import { stripExistingNationalRegisterNumberFormatting } from '../../../utils/formatting/formatNationalRegisterNumber';
import getFilenameFromHeaders from '../../general/getFilenameFromHeaders';

import { BFF_URL, URL } from './emloyee.const';

export * from './medicalExaminations/medicalExaminations.api';

type ConditionFilter = {
    [key in EMPLOYEE_CONDITION]?: string;
};

interface IPrivateFetchEmployeesFilters extends ConditionFilter {
    search?: string;
    company_name?: string;
    function_description?: string;
    name?: string;
    condition?: string[];
}

interface IPrivateFetchEmployeesParameters {
    maxRecordCount?: number;
    refreshSources: 'true' | 'false';
    showFullFamily: 'true' | 'false';
    showFutureEmployees: 'true' | 'false';
}

export interface IFetchEmployeesRequestBody {
    keyword?: string; // name
    filters?: ({
        key: keyof IPrivateFetchEmployeesFilters;
        value: string;
    })[];
}

export function fetchEmployees(
    companyCode: string,
    parameters: IPrivateFetchEmployeesParameters,
    filters: IPrivateFetchEmployeesFilters,
) {
    return post<IFetchEmployeesData>({
        url: URL.COMPANY_EMPLOYEES_QUERY,
        pathParams: {
            companyCode,
        },
        queryParams: {
            numRecords: parameters.maxRecordCount,
            refreshSources: parameters.refreshSources,
            showFullFamily: parameters.showFullFamily,
            showFutureEmployees: parameters.showFutureEmployees,
        },
        body: toFetchEmployeesRequestBody(filters),
        timeoutInMillis: 45 * ONE_SECOND,
    });
}

function toFetchEmployeesRequestBody(filters: IPrivateFetchEmployeesFilters = {}): IFetchEmployeesRequestBody {
    return Object.keys(filters)
        .filter((filterKey) => !!filters[filterKey])
        .reduce(
            (bodyAccumulator: IFetchEmployeesRequestBody, filterKey: keyof IPrivateFetchEmployeesFilters) => {
                if (filterKey === 'search') {
                    const filterValue = filters[filterKey];
                    if (isSetString(filterValue)) {
                        bodyAccumulator.keyword = filterValue;
                    }
                } else if (filterKey === 'condition') {
                    const filterValue = filters[filterKey];
                    if (filterValue && filterValue.length > 0) {
                        if (!bodyAccumulator.filters) {
                            bodyAccumulator.filters = [];
                        }
                        filterValue.forEach((condition) => {
                            if (Object.values(EMPLOYEE_CONDITION).includes(condition as EMPLOYEE_CONDITION)) {
                                bodyAccumulator.filters.push({
                                    key: condition as EMPLOYEE_CONDITION,
                                    value: 'true',
                                });
                            }
                        });
                    }
                } else {
                    addFetchEmployeesRequestBodyFilter(
                        bodyAccumulator,
                        filterKey,
                        filters[filterKey],
                    );
                }
                return bodyAccumulator;
            },
            {},
        );
}

function addFetchEmployeesRequestBodyFilter(
    requestBody: IFetchEmployeesRequestBody,
    key: keyof IPrivateFetchEmployeesFilters,
    value: string,
) {
    if (!requestBody.filters) {
        requestBody.filters = [];
    }
    requestBody.filters.push({ key, value });
    return requestBody;
}

export function fetchEmployeeDetails(id: string) {
    return get<IEmployeeDetails>({
        url: URL.EMPLOYEE,
        pathParams: {
            id,
        },
        mapResponse: (response) => response.employee,
    });
}

/**
 * Even though this is a fetch, the method used is a POST so that we can pass the
 * sensitive nationalRegisterNumber in the body instead of a a path param.
 */
export function fetchEmployeeByInsz({
    nationalRegisterNumber,
    selectedCompanyCompanyCode,
}: IFetchEmployeeByInszRequestPayload) {
    return post<IEmployeeDetails>({
        url: URL.EMPLOYEE_BY_INSZ_QUERY,
        pathParams: {
            companyCode: selectedCompanyCompanyCode,
        },
        body: {
            nationalRegisterNumber,
        },
        mapResponse: (response) => findEmployeeToAdd(response.employees, selectedCompanyCompanyCode),
    });
}

function findEmployeeToAdd(
    employees: IEmployeeDetails[], companyCode: string): IEmployeeDetails {
    let employee = findEmployeeInSelectedCompany(employees, companyCode);
    if (!employee) {
        // When we didn't find an employee of the selected company yet, get the latest data for the INSZ given.
        employee = getLatestDataFromEmployee(employees);
    }

    return employee;
}

function findEmployeeInSelectedCompany(
    employees: IEmployeeDetails[], companyCode: string): IEmployeeDetails {
    return employees.find((employee) => {
        return !areCompanyCodesDifferent(employee.company.companyCode, companyCode);
    });
}

function getLatestDataFromEmployee(employees: IEmployeeDetails[]): IEmployeeDetails {
    return employees.sort((a, b) => {
        return compareAscending(getDate(a.dateInFunction).getTime(), getDate(b.dateInFunction).getTime());
    }).pop();
}

export function fetchEmployeeStatutes(id: string) {
    return get<IEmployeeStatute[]>({
        url: URL.EMPLOYEE_STATUTES,
        pathParams: {
            id,
        },
        mapResponse: (response) => {
            const statutes = response.statutes as IEmployeeStatute[];
            // TODO: backend returns sometimes dates in a format like 2018-08-01T00:00:00
            // To save it the date needs to be stripped
            return statutes.map((statute) => ({
                ...statute,
                beginDate: statute.beginDate && formatDateForBackend(statute.beginDate),
                endDate: statute.endDate && formatDateForBackend(statute.endDate),
            }));
        },
    });
}

export function addEmployeeStatute(payload: IAddEmployeeStatutePayload) {
    const { id, ...body } = payload;
    return post({
        url: URL.EMPLOYEE_STATUTES,
        pathParams: {
            id,
        },
        body,
    });
}

export function addEmployeePersonalRisk(payload: IAddPersonalRiskPayload) {
    const { employee, ...body } = payload;
    return post({
        url: URL.EMPLOYEE_PERSONAL_RISKS,
        pathParams: {
            id: employee.id,
        },
        body,
    });
}

export function fetchEmployeeAbsences(id: string) {
    return get<IEmployeeAbsence[]>({
        url: URL.EMPLOYEE_ABSENCES,
        pathParams: {
            id,
        },
        mapResponse: (response) => {
            const absences = response.absences as IEmployeeAbsence[];
            // TODO: backend returns sometimes dates in a format like 2018-08-01T00:00:00
            // To save it the date needs to be stripped
            return absences.map((absence) => ({
                ...absence,
                beginDate: absence.beginDate && formatDateForBackend(absence.beginDate),
                endDate: absence.endDate && formatDateForBackend(absence.endDate),
            }));
        },
    });
}

export function addEmployeeAbsence(payload: IAddEmployeeAbsencePayload) {
    const { id, ...body } = payload;
    return post({
        url: URL.EMPLOYEE_ABSENCES,
        pathParams: {
            id,
        },
        body,
    });
}

export function updateEmployeeAbsence(payload: IUpdateEmployeeAbsencePayload) {
    const { id, personalId, ...body } = payload;
    return put({
        url: URL.EMPLOYEE_ABSENCE,
        pathParams: {
            id,
            absenceId: personalId,
        },
        body,
    });
}

export function removeEmployeeAbsence(payload: IRemoveEmployeeAbsencePayload) {
    const { id, personalId, ...body } = payload;
    return remove({
        url: URL.EMPLOYEE_ABSENCE,
        pathParams: {
            id,
            absenceId: personalId,
        },
        body: {
            ...body,
            endDate: formatDateForBackend(dayOffsetFromNow(-1)),
        },
    });
}

export function addEmployee(employeeToAdd: Partial<IEmployeeToAdd>) {
    return post<IAddEmployeeSucceededPayload>({
        url: URL.EMPLOYEES,
        body: employeeToAdd,
        mapResponse: (response) => {
            return {
                id: response.id,
                employeeId: response.employeeId,
            } as IAddEmployeeSucceededPayload;
        },
    });
}

export function updateEmployee(employeeToUpdate: IUpdateEmployeePayload | IUpdateEmployeeAllFieldsPayload) {
    const payload = employeeToUpdate as IUpdateEmloyeeByIdPayload & IUpdateEmloyeeByInszPayload;
    const updateById = !!payload.id;

    let url: string;
    let pathParams: IUrlParams;
    let body: object;

    if (updateById) {
        url = URL.EMPLOYEE;
        pathParams = {
            id: payload.id,
        };
        body = {
            ...employeeToUpdate.employeeData,
            // eslint-disable-next-line max-len
            nationalRegisterNumber: stripExistingNationalRegisterNumberFormatting(employeeToUpdate.employeeData.nationalRegisterNumber),
        };
    } else {
        url = URL.EMPLOYEE_BY_INSZ;
        pathParams = {
            companyCode: payload.companyCode,
        };
        body = {
            ...employeeToUpdate.employeeData,
            nationalRegisterNumber: payload.nationalRegisterNumber,
        };
    }

    return put<IUpdateEmployeeSucceededPayload>({
        url,
        pathParams,
        body,
        mapResponse: (response) => response.employee,
    });
}

export function updateEmployeeEmployment(
    payload: IUpdateEmployeeEmploymentPayload,
) {
    const { id, ...changes } = payload;
    return put<number>({
        url: URL.EMPLOYEE_EMPLOYMENT,
        pathParams: {
            id,
        },
        body: changes,
        mapResponse: (response) => response.id,
    });
}

export function setEmployeeOutOfService(
    payload: ISetEmployeeOutOfServicePayload,
    ) {
    const { id, ...changes } = payload;
    return patch({
        ...bffDefaultApiConfig({}),
        url: BFF_URL.EMPLOYEE_EMPLOYEE_OUT_SERVICE,
        pathParams: {
            id,
        },
        body: changes.body,
    });
}

export function changeEmployeeOutOfService(payload: IChangeEmployeeOutOfServicePayload) {
    const { id, ...changes } = payload;
    return put({
        url: URL.EMPLOYEE_CHANGE_EMPLOYEE_OUT_SERVICE,
        pathParams: {
            id,
        },
        body: changes,
    });
}

export function clearEmployeeOutOfService(payload: IClearEmployeeOutOfServicePayload) {
    const { id, ...changes } = payload;
    return put({
        url: URL.EMPLOYEE_CLEAR_EMPLOYEE_OUT_SERVICE,
        pathParams: {
            id,
        },
        body: changes,
    });
}

export function fetchEmployeeJobStudent({ id }: IFetchEmployeeJobStudentPayload) {
    return get<IEmployeeJobStudent>({
        url: URL.EMPLOYEE_JOB_STUDENT,
        pathParams: {
            id,
        },
        mapResponse: (response) => response['student-employee-questionnaires'][0] || NO_RERENDER.EMPTY_OBJECT,
    });
}

export function addEmployeeJobStudent(payload: IAddEmployeeJobStudentPayload) {
    const { id, emailAddressToUpdate, ...body } = payload;
    return post<IEmployeeJobStudent>({
        url: URL.EMPLOYEE_JOB_STUDENT,
        pathParams: {
            id,
        },
        body,
    });
}

export function updateEmployeeJobStudent(payload: IUpdateEmployeeJobStudentPayload) {
    const { id, emailAddressToUpdate, ...changes } = payload;
    return put<IEmployeeJobStudent>({
        url: URL.EMPLOYEE_JOB_STUDENT,
        pathParams: {
            id,
        },
        body: changes,
    });
}

export function fetchEmployeeRisksAndResearches(id: string) {
    return get<IEmployeeRisksAndResearches>({
        url: URL.EMPLOYEE_RISK_RESEARCHES,
        pathParams: {
            id,
        },
        mapResponse: (response) => response.riskResearches,
    });
}

export function fetchEmployeePersonalRisks(id: string) {
    return get<IPersonalRisk[]>({
        url: URL.EMPLOYEE_PERSONAL_RISKS,
        pathParams: {
            id,
        },
        mapResponse: (response) => response.risks,
    });
}

export function duplicateEmployeeRisks(payload: IDuplicateEmployeeRisksPayload) {
    const { idFrom, idTo, ...duplicate } = payload;
    return put({
        url: URL.EMPLOYEE_DUPLICATE_RISKS,
        pathParams: {
            idFrom,
            idTo,
        },
        queryParams: duplicate as object as IUrlParams,
    });
}



export function updateEmployeeCostCenter(payload: IUpdateEmployeeCostCenterPayload) {
    const { id, costCenter } = payload;
    return put({
        url: URL.EMPLOYEE_COST_CENTER,
        pathParams: {
            id,
            costCenter,
        },
    });
}

export function exportEmployees(
    { companyCode, showFullFamily }: ICompanyCodeWithShowFullFamilyPayload,
    defaultFilename: string,
) {
    return get<IDocument>({
        url: URL.COMPANY_EMPLOYEES_EXPORT,
        responseType: IResponseType.blob,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        timeoutInMillis: 2 * ONE_MINUTE,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });
}

export function fetchEmployeesWithoutEmail({
    companyCode,
    refreshSources,
    showFullFamily,
}: IFetchEmployeesWithoutEmailPayload) {
    return get<IFetchEmployeesData>({
        url: URL.EMPLOYEES_WITHOUT_EMAIL,
        pathParams: {
            companyCode,
        },
        queryParams: {
            refreshSources,
            showFullFamily,
        },
        timeoutInMillis: 45 * ONE_SECOND,
    });
}

export function fetchEmployeeStatus(employeeId: number) {
    return get<IEmployeeStatusResponse>({
        url: URL.EMPLOYEE_STATUS,
        pathParams: {
            employeeId,
        },
    });
}
