import { get } from '../../utils/api/requestWrapper';
import { ConstantType, TConstants } from '../../models/general/constants';
import { TBackendLocale } from '../../models/general/i18n';
import { HEADER_NAME } from '../../config/api.config';

export const URL = {
    CONSTANT: '/constants',
};

export function fetchConstants(types: ConstantType[], language: TBackendLocale) {
    return get<TConstants>({
        url: URL.CONSTANT,
        queryParams: {
            // although the lang is by default passed as a header, we also pass it as a query param
            // for cache busting reasons (service worker)
            types: types.join(','),
            langCacheBuster: language,
        },
        headers: {
            [HEADER_NAME.LOCALE]: language,
        },
    });
}
