import { get } from '../../utils/api/requestWrapper';
import { IFetchCitiesPayload, ICity, IFetchStreetsPayload, IStreet } from '../../models/general/address';

export const URL = {
    CITIES: '/addresses/cities',
    STREETS: '/addresses/streets',
};

export function fetchCities(payload: IFetchCitiesPayload) {
    return get<ICity[]>({
        url: URL.CITIES,
        queryParams: {
            countryCode: payload.countryCode,
            postalCode: payload.postalCodeFilter,
            cityName: payload.cityNameFilter,
        },
        mapResponse: (data) => {
            // TODO: backend needs to add an id value
            return data.cities.map((item) => ({
                name: item.name,
                postalCode: item.postalCode,
                nisCode: item.nisCode,
                zipCodeId: Number(item.zipCodeId),
            } as ICity));
        },
    });
}

export function fetchStreets(payload: IFetchStreetsPayload) {
    return get<IStreet[]>({
        url: URL.STREETS,
        queryParams: {
            nisCode: payload.nisCode,
            streetName: payload.streetNameFilter,
        },
        mapResponse: (data) => data.streets,
    });
}
