import { get, IResponseType } from '../../../../utils/api/requestWrapper';
import { IDocument, IFetchDocumentPayload } from '../../../../models/general/documents';
import { ONE_MINUTE } from '../../../../utils/core/time/periodsInMillis';
import { reportErrorMessage } from '../../../../utils/logging/errorReporter';
import ROUTE_KEYS from '../../../../routeKeys';
import getFilenameFromHeaders from '../../getFilenameFromHeaders';
import { URL } from '../documents.const';

export function fetchDocuments(
    { ids, defaultFilename }: IFetchDocumentPayload,
    { routeKeyForErrorLogging }: { routeKeyForErrorLogging: ROUTE_KEYS },
) {
    // KZUAT 1902: We're getting unknown BE errors for the /documents call because the ids parameter is empty
    // We're not sure where the error occurs, thats why we're logging this specific error.
    if (!isIdsPayloadValid()) {
        reportErrorMessage(
            'Trying to fetch documents with empty or invalid ids parameter',
            {
                extra: {
                    routeKey: routeKeyForErrorLogging,
                },
            },
        );
    }

    return get<IDocument>({
        url: URL.DOCUMENT,
        timeoutInMillis: 5 * ONE_MINUTE,
        queryParams: {
            ids: ids.map(item => item).join(','),
        },
        responseType: IResponseType.blob,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });

    function isIdsPayloadValid() {
        if (!ids || ids.length <= 0) {
            return false;
        }
        return ids.findIndex((id) => !id) === -1;
    }
}
