import React, { PureComponent, MouseEvent } from 'react';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import { fields, schema } from './setOutOfServiceSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee,
    getSetEmployeeOutOfServiceAsyncInfo,
    getChangeEmployeeOutOfServiceAsyncInfo,
    getClearEmployeeOutOfServiceAsyncInfo,
    getSetChangeOrClearOutOfServiceIsBusy,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import {
    setEmployeeOutOfService,
    changeEmployeeOutOfService,
    clearEmployeeOutOfService,
} from '../../../../../redux/employee/info/actions';
import TextInput from '../../../../common/input/TextInput';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import Checkbox from '../../../../common/input/Checkbox';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { isInThePast } from '../../../../../utils/core/date/isInThePast';

interface IFormValues {
    reasonOutService: string;
    dateOutService: string;
    clearOutOfService: 'true' | 'false';
}

interface IPrivateProps {
    onSubmit: (values: IFormValues, editFlow: boolean) => void;
    initialValues: IFormValues;
    translator: ITranslator;
    employeeName: string;
    employeeDateInService: string;
    employeeDateInFunction: string;
    isDetailsLoaded: boolean;
    setAsyncInfo: IAsyncFieldInfo;
    changeAsyncInfo: IAsyncFieldInfo;
    clearAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    editFlow: boolean;
    isBusy: boolean;
    isFutureEmployee: boolean;
}

interface IOutOfServiceProps {
    onClose: () => void;
}

const FORM_NAME = 'out-of-service-form';
const CLASS_NAME = 'OutOfService';

class OutOfService extends PureComponent<IPrivateProps & IOutOfServiceProps> {

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, initialValues, onSubmit,
            isDetailsLoaded, employeeName,
            employeeDateInService,
            employeeDateInFunction,
            setAsyncInfo, editFlow, isBusy, changeAsyncInfo, clearAsyncInfo, isFutureEmployee,
        } = this.props;

        if (!isDetailsLoaded) {
            return null;
        }

        const endDatePlaceholder = translator('administration.employees.out_of_service.form.end_date');
        const reasonPlaceholder = translator('administration.employees.out_of_service.form.reason');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.out_of_service.title'}
                            placeholders={{ name: employeeName }}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={(values: IFormValues) => onSubmit(values, editFlow)}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue, handleChange,
                    }: IFormRenderProps<IFormValues>) => {
                        function onEndDateChanged(date: string) {
                            setFieldValue('dateOutService', date);
                        }

                        return (
                            <>
                                <Loader show={isBusy} />
                                <FloatableTextInputWrapper>
                                    <DatePicker
                                        id="out-of-service-end-date"
                                        placeholder={endDatePlaceholder}
                                        value={values.dateOutService}
                                        name={fields.dateOutService}
                                        onChange={onEndDateChanged}
                                        isInvalid={touched.dateOutService && !!errors.dateOutService}
                                        disabled={isFutureEmployee || values.clearOutOfService === 'true'}
                                        minDate={employeeDateInFunction || employeeDateInService}
                                    >
                                        <label htmlFor="out-of-service-end-date">
                                            <Translate
                                                msg="administration.employees.out_of_service.form.end_date"
                                            />
                                        </label>
                                    </DatePicker>
                                    {touched.dateOutService && (
                                        <FormFieldError
                                            error={errors.dateOutService}
                                            placeholders={{ fieldName: endDatePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="out-of-service-reason"
                                        placeholder={reasonPlaceholder}
                                        value={values.reasonOutService}
                                        name={fields.reasonOutService}
                                        onChange={handleChange}
                                        isInvalid={touched.reasonOutService && !!errors.reasonOutService}
                                        multiLine={true}
                                        disabled={values.clearOutOfService === 'true'}
                                    />
                                    <label htmlFor="out-of-service-reason">
                                        <Translate
                                            msg="administration.employees.out_of_service.form.reason"
                                        />
                                    </label>
                                    {touched.reasonOutService && (
                                        <FormFieldError
                                            error={errors.reasonOutService}
                                            placeholders={{ fieldName: reasonPlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                {editFlow &&
                                    <FloatableTextInputWrapper>
                                        <Checkbox
                                            name={fields.clearOutOfService}
                                            toggleButton
                                            checked={values.clearOutOfService === 'true'}
                                            onChange={(e) => setFieldValue(
                                                'clearOutOfService',
                                                e.target.checked.toString(),
                                            )}
                                        >
                                            <Translate msg="administration.employees.out_of_service.form.clear" />
                                        </Checkbox>
                                    </FloatableTextInputWrapper>
                                }
                                <FormError error={setAsyncInfo.error} />
                                <FormError error={changeAsyncInfo.error} />
                                <FormError error={clearAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="out-of-service-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate
                                            msg="administration.employees.out_of_service.form.cancel"
                                        />
                                    </Button>
                                    <SubmitButton
                                        id="out-of-service-submit-button"
                                        formName={FORM_NAME}
                                        alwaysEnabled={true}
                                    >
                                        <Translate
                                            msg="administration.employees.out_of_service.form.submit"
                                        />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.setAsyncInfo.status === AsyncStatus.Success &&
            prevProps.setAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
        if (
            this.props.changeAsyncInfo.status === AsyncStatus.Success &&
            prevProps.changeAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
        if (
            this.props.clearAsyncInfo.status === AsyncStatus.Success &&
            prevProps.clearAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, setAsyncInfo, changeAsyncInfo, clearAsyncInfo } = this.props;
        clearError(setAsyncInfo.error);
        clearError(changeAsyncInfo.error);
        clearError(clearAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IOutOfServiceProps>({
    stateProps: (state) => {
        const selectedEmployee = getSelectedEmployee(state);

        const isFutureEmployee = selectedEmployee && !isInThePast(selectedEmployee.dateInService);

        return {
            isDetailsLoaded: !!selectedEmployee,
            initialValues: selectedEmployee && {
                reasonOutService: selectedEmployee.outOfServiceReason || '',
                dateOutService: isFutureEmployee ?
                    selectedEmployee.dateInService :
                    selectedEmployee.dateOutOfService || formatDateForBackend(new Date()),
                clearOutOfService: 'false',
            },
            translator: getTranslatorDeprecated(state),
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            employeeDateInService: selectedEmployee && selectedEmployee.dateInService,
            employeeDateInFunction: selectedEmployee && selectedEmployee.dateInFunction,
            setAsyncInfo: getSetEmployeeOutOfServiceAsyncInfo(state),
            changeAsyncInfo: getChangeEmployeeOutOfServiceAsyncInfo(state),
            clearAsyncInfo: getClearEmployeeOutOfServiceAsyncInfo(state),
            editFlow: selectedEmployee && !!selectedEmployee.dateOutOfService,
            isBusy: getSetChangeOrClearOutOfServiceIsBusy(state),
            isFutureEmployee,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSubmit: (values: IFormValues, editFlow: boolean) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);
                const addReason = !!values.reasonOutService
                    ? {value: values.reasonOutService}
                    : {value: ''};

                if (editFlow) {
                    if (values.clearOutOfService === 'true') {
                        dispatch(clearEmployeeOutOfService({
                            id: selectedEmployee.id,
                            dateInService: selectedEmployee.dateInService,
                            dateInFunction: selectedEmployee.dateInFunction,
                        }));
                    } else {
                        dispatch(changeEmployeeOutOfService({
                            id: selectedEmployee.id,
                            dateInService: selectedEmployee.dateInService,
                            dateInFunction: selectedEmployee.dateInFunction,
                            dateOutService: values.dateOutService,
                            reasonOutService: values.reasonOutService,
                        }));
                    }
                } else {
                    dispatch(setEmployeeOutOfService({
                        id: selectedEmployee.id,
                        body: [
                            {
                                value: `${formatDateForBackend(values.dateOutService)}T00:00:00.0`,
                                path: '/dateOutService',
                                op: 'replace',
                            },
                            {
                                ...addReason,
                                path: '/reasonOutService',
                                op: 'replace',
                            },
                        ],
                    }));
                }
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(OutOfService);
