import React from 'react';

import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../common/input/TextInput';
import Translate from '../../../../common/Translate';

import { TPlannedCompanyVisitsSearchContentProps } from './PlannedCompanyVisitsSearchContent.type';

const PlannedCompanyVisitsSearchContent = ({
    formRenderProps,
    translator,
}: TPlannedCompanyVisitsSearchContentProps) => {

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.company_visits.planned.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.company_visits.planned.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
};

export { PlannedCompanyVisitsSearchContent };
