import { pathOr } from 'ramda';

import { formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { ListItem } from '../../../../../models/general/list';
import { createFormTextInput } from '../../../../common/forms/FormTextInput';
import {
    IToAdministerVaccinationsListColumnNames,
} from '../../ToAdminister/ToAdministerVaccinationsList/ToAdministerVaccinationList.types';

import { IRequestVaccinationFormValues } from './RequestVaccinationDialog.type';

export const FormTextInput = createFormTextInput<IRequestVaccinationFormValues>();

export const formatDateIfAvailable = (date: string | null): string => {
    if (typeof date === 'string' && date.length > 0) {
        return formatDateForDisplay(date);
    }

    return '';
};

export const getPrefilledValues = (selectedVaccination: ListItem<IToAdministerVaccinationsListColumnNames>) => {
    return {
        deadline: formatDateIfAvailable(pathOr<string>('', ['columns', 'dateToAdministerSort'], selectedVaccination)),
        name: pathOr<string>('', ['columns', 'name'], selectedVaccination),
        seat: pathOr<string>('', ['columns', 'employer'], selectedVaccination),
        stepCyclus: pathOr<string>('', ['columns', 'injectionDescription'], selectedVaccination),
        type: pathOr<string>('', ['columns', 'description'], selectedVaccination),
    };
};
