import React, { ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';
import './button.scss';
import Icon from '../../icons/Icon';

export interface IButtonProps {
    id: string;
    children?: ReactNode;
    className?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    typeName?: 'primary' | 'secondary' | 'grey' | 'white' | 'text';
    size?: 'small';
    disabled?: boolean;
    outline?: boolean;
}

export interface IInternalButtonProps {
    formName?: string;
    isSubmit?: boolean;
}

const Button = (publicProps: IButtonProps) => {
    const props = publicProps as IButtonProps & IInternalButtonProps;
    let hasIcon = false;
    let hasIconPrepend = false;

    if (React.Children.count(props.children) > 1) {
        React.Children.map(props.children, (child, i) => {
            if (React.isValidElement(child) && child.type === Icon) {
                hasIcon = true;
                hasIconPrepend = (i === 0);
            }
        });
    }

    const btnClass = classNames('Button', {
        'Button--primary': props.typeName === 'primary',
        'Button--secondary': props.typeName === 'secondary',
        'Button--grey': props.typeName === 'grey',
        'Button--white': props.typeName === 'white',
        'Button--text': props.typeName === 'text',
        'Button--small': props.size === 'small',
        'Button--icon-prepend': hasIcon && hasIconPrepend,
        'Button--icon-append': hasIcon && !hasIconPrepend,
        'Button--disabled': props.disabled,
        'Button--outline': props.outline,
    });

    const buttonClasses = classNames(btnClass, props.className);

    if (props.isSubmit && !props.formName) {
        console.warn('Also provide the form name when using a button to submit a form. ' +
            'This is needed to prevent the button from submitting all forms on the page.');
    }
    if (props.isSubmit && props.onClick) {
        console.warn('For submitting the form you have to specify the handleSubmit in the form, ' +
            'and not the onClick (because the onClick does no form validation!).');
    }

    return (
        <button
            id={props.id}
            className={buttonClasses}
            onClick={props.onClick}
            form={props.formName}
            type={props.isSubmit ? 'submit' : 'button'}
        >
            {props.children}
        </button>
    );
};

export default Button;
