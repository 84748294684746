import React from 'react';
import './onboarding-wizard.scss';
import Wizard from '../../common/navigation/Wizard/index';
import { IStepperStep } from '../../../models/general/stepper';
import { ONBOARDING_WIZARD_STEP_ID } from '../../../models/onboarding/wizard';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';
import { getOnboardingWizardStepId } from '../../../redux/onboarding/selectors';
import { changeOnboardingWizardStep } from '../../../redux/onboarding/actions';
import Data from './steps/Data';
import Seats from './steps/Seats';
import Contacts from './steps/Contacts';
import OnboardingAvailabilities from './steps/OnboardingAvailabilities';
import Employees from './steps/Employees';
import Holidays from './steps/Holidays';

const ONBOARDING_STEPS: IStepperStep[] = [{
    id: ONBOARDING_WIZARD_STEP_ID.DATA,
    translationKey: 'onboarding.wizard.steps.data.step_label',
    renderStepContent: (props) => <Data {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.SEAT,
    translationKey: 'onboarding.wizard.steps.seats.step_label',
    renderStepContent: (props) => <Seats {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.CONTACT,
    translationKey: 'onboarding.wizard.steps.contacts.step_label',
    renderStepContent: (props) => <Contacts {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.RISK_MGMT,
    translationKey: 'onboarding.wizard.steps.risk_mgmt.step_label',
    renderStepContent: (props) => <OnboardingAvailabilities {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.MEDICAL_EXAMINATION,
    translationKey: 'onboarding.wizard.steps.medical_examinations.step_label',
    renderStepContent: (props) => <OnboardingAvailabilities {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.HOLIDAY,
    translationKey: 'onboarding.wizard.steps.holidays.step_label',
    renderStepContent: (props) => <Holidays {...props} />,
}, {
    id: ONBOARDING_WIZARD_STEP_ID.EMPLOYEE,
    translationKey: 'onboarding.wizard.steps.employees.step_label',
    renderStepContent: (props) => <Employees {...props} />,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.ONBOARDING,
    steps: ONBOARDING_STEPS,
});

export default function OnboardingWizard() {
    return (
        <div className="OnboardingWizard">
            <Wizard
                activeStepIdSelector={getOnboardingWizardStepId}
                navigateToStepActionCreator={changeOnboardingWizardStep}
                steps={ONBOARDING_STEPS}
            />
        </div>
    );
}
