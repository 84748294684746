import React, { ReactNode } from 'react';
import './company-visits.scss';
import connect from '../../../utils/libs/redux/connect';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import PageHeader from '../../appShell/PageHeader';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import ROUTE_KEYS from '../../../routeKeys';
import { ITabItem } from '../../../models/general/navigation';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import { triggerCompanyVisitRequestWizard } from '../../../redux/company/visits/actions';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const CLASS_NAME = 'CompanyVisits';

interface IPrivateProps {
    goToRequestCompanyVisit: () => void;
}

interface IPublicProps {
    children: ReactNode;
}

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    dispatchProps: (dispatch) => {
        return {
            goToRequestCompanyVisit: () => {
                dispatch(triggerCompanyVisitRequestWizard());
            },
        };
    },
})(CompanyVisitsTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function CompanyVisitsTemplate(props: IPrivateProps & IPublicProps) {
    const { children, goToRequestCompanyVisit } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'interventions.company_visits.planned.tab_title',
        linkTo: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
    }, {
        translationKey: 'interventions.company_visits.executed.tab_title',
        linkTo: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
    }];

    const buttonElement = (
        <ShowIfAllowed routeKey={ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW}>
            <Button
                id="page-header-calendar-button"
                typeName="secondary"
                onClick={goToRequestCompanyVisit}
            >
                <Icon typeName="calendar" />
                <Translate msg="interventions.company_visits.action.request_company_visit" />
            </Button>
        </ShowIfAllowed>
    );

    const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_COMPANY_VISITS_PLANNED;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className={`${CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    button={buttonElement}
                    tabs={TABS}
                    title="interventions.company_visits.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.INTERVENTIONS,
                    }}
                    type="grey"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
