import {
    IUnreadMessagesCount,
    IFetchUserMessagesPayload,
    IUserMessage,
    IFetchUserMessageDetailPayload,
    IUserMessageDetails,
    IChangeMessageStatusPayload,
    IMessageType,
    IUpdateUserMessageTypesPayload,
} from '../../models/user/inbox';
import { get, IResponseType, put } from '../../utils/api/requestWrapper';
import { ONE_MINUTE, ONE_SECOND } from '../../utils/core/time/periodsInMillis';
import { ICompanyCodePayload } from '../../models/admin/company';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';

export const URL = {
    FETCH_UNREAD_MESSAGES_COUNT: '/companies/{companyCode}/message-count',
    FETCH_USER_MESSAGES: '/companies/{companyCode}/messages',
    FETCH_USER_MESSAGE_DETAILS: '/companies/{companyCode}/messages/{id}',
    CHANGE_MESSAGE_STATUS: '/companies/{companyCode}/messages/{id}/status',
    FETCH_MESSAGE_ATTACHMENT: '/companies/{companyCode}/messages/attachment/{attachmentId}',
    FETCH_MESSAGE_TYPES: '/message-center/types',
    USER_MESSAGE_TYPES: '/message-center/types/me',
};

export function fetchUnreadMessagesCount(companyCode: string, companyId: number, showFullFamily: boolean) {
    return get<IUnreadMessagesCount>({
        url: URL.FETCH_UNREAD_MESSAGES_COUNT,
        pathParams: { companyCode },
        queryParams: { companyId, showFullFamily },
        resetSessionTimerOnSuccess: false,
    });
}

export function fetchUserMessages(payload: IFetchUserMessagesPayload) {
    const { companyCode, ...queryParams } = payload;
    return get<IUserMessage[]>({
        url: URL.FETCH_USER_MESSAGES,
        timeoutInMillis: 30 * ONE_SECOND,
        pathParams: { companyCode },
        queryParams: { ...queryParams },
        mapResponse: (response) => response.messages,
    });
}

export function fetchUserMessageDetails(
    payload: IFetchUserMessageDetailPayload,
    companyCode: string,
    showFullFamily: boolean,
) {
    return get<IUserMessageDetails>({
        url: URL.FETCH_USER_MESSAGE_DETAILS,
        pathParams: {
            companyCode,
            id: payload.id,
        },
        queryParams: {
            showFullFamily,
        },
    });
}

export function changeMessageStatus(
    payload: IChangeMessageStatusPayload,
    companyCode: string,
    showFullFamily: boolean,
) {
    return put({
        url: URL.CHANGE_MESSAGE_STATUS,
        pathParams: {
            companyCode,
            id: payload.id,
        },
        queryParams: {
            showFullFamily,
        },
        body: {
            read: payload.read,
            deleted: payload.deleted,
        },
    });
}

export function fetchMessageAttachment({
    companyCode,
    attachmentId,
}: ICompanyCodePayload & { attachmentId: string }) {
    return get<IDocument>({
        url: URL.FETCH_MESSAGE_ATTACHMENT,
        responseType: IResponseType.blob,
        timeoutInMillis: 1 * ONE_MINUTE,
        pathParams: {
            companyCode,
            attachmentId,
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, 'message-attachment.pdf'),
            };
        },
    });
}

export function fetchMessageTypes() {
    return get<IMessageType[]>({
        url: URL.FETCH_MESSAGE_TYPES,
        mapResponse: (response) => response.types,
    });
}

export function fetchUserMessageTypes() {
    return get<IMessageType[]>({
        url: URL.USER_MESSAGE_TYPES,
        mapResponse: (response) => response.types,
    });
}

export function updateUserMessageTypes(
    body: IUpdateUserMessageTypesPayload,
) {
    return put({
        url: URL.USER_MESSAGE_TYPES,
        body,
    });
}
