import React from 'react';
import { ShapeOf } from '../../../../../../../../models/ui/form';
import { IFormRenderProps } from '../../../../../../../common/forms/Form';
import { createFormTextInput } from '../../../../../../../common/forms/FormTextInput';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import { IOnboardEmployeeToAdd } from '../../../../../../../../models/onboarding/wizard';

type FormValues = Pick<IOnboardEmployeeToAdd,
    'studentWorker' |
    'sendMail' |
    'phone' |
    'mobilePhone' |
    'email'>;

interface IAddEmployeeContactProps {
    fields: ShapeOf<FormValues>;
    formRenderProps: IFormRenderProps<FormValues>;
    baseTranslationKey: string;
    formName: string;
    translator: ITranslator;
}

const AddEmployeeFormTextInput = createFormTextInput<FormValues>();

const AddEmployeeContact = (props: IAddEmployeeContactProps) => {

    const {
        formName, formRenderProps, baseTranslationKey, fields,
    } = props;

    return (
        <>
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'email'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
                markAsRequired={props.formRenderProps.values.studentWorker && props.formRenderProps.values.sendMail}
            />
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'phone'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
            />
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'mobilePhone'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
            />
        </>
    );
};

export default AddEmployeeContact;
