import React from 'react';
import classNames from 'classnames';

import {
    createPrefixedDroppableId,
    getPrefixFromPrefixedDraggableId,
} from '../../../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import {
    DraggablePrefix,
    DroppablePrefix,
    IDroppableFreeTimeslotData,
} from '../../../../../../../models/interventions/timeslots';
import { getActiveDraggable } from '../../../../../../../redux/ui/dragAndDrop/selectors';
import { getDate } from '../../../../../../../utils/core/date/getSpecificDate';
import { getMinutesBetweenDates } from '../../../../../../../utils/core/date/getDifferenceBetweenDates';
import { IActiveDraggable } from '../../../../../../../models/ui/dragAndDrop';
import { ICalendarEvent } from '../../../../../../../models/ui/calendar';
import { IMedicalExaminationToAdd } from '../../../../../../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../../../../../../models/interventions/medicalExaminations/planned';
import { connect } from '../../../../../../index';
import Translate from '../../../../../Translate';
import DroppableWrapper from '../../../../dragAndDrop/DroppableWrapper';
import { IEventProps } from '../../../TimeGridEvent';

import { ALLOW_MOVE_PLANNING, CLASS_NAME } from './DroppableFreeTimeSlot.const';
import { IPrivateProps } from './DroppableFreeTimeSlot.type';

import './droppable-free-time-slot.scss';

function DroppableFreeTimeSlot(props: IEventProps & IPrivateProps) {
    const isDropDisabled = getIsDropDisabled(props);

    const event = props.event as ICalendarEvent<IDroppableFreeTimeslotData>;

    const isPlannable = event.data.timeCell.isPlannable;

    return (
        <DroppableWrapper
            droppableId={createPrefixedDroppableId(props.event.id, DroppablePrefix.FreeTimeslot)}
            className={`${CLASS_NAME}__wrapper`}
            isDropDisabled={isDropDisabled}
            disableMove
            render={(renderProps) => {
                const className = classNames('rbc-event', CLASS_NAME, {
                    selected: props.selected,
                    reserved: props.event.reserved,
                    'dragging-over': renderProps.dropState.isDraggingOver,
                    disabled: isDropDisabled,
                    [`${CLASS_NAME}--unplannable`]: !isPlannable,
                });
                return (
                    <span className={className}>
                        <span className="label">
                            {isPlannable
                            ?
                                <Translate msg="interventions.plan_bufferzone.calendar.free_time_slot_label" />
                            :
                                <Translate msg="interventions.plan_bufferzone.calendar.unplannable_time_slot_label" />
                            }
                            {event && event.data.amountOfFreeTimeslotsWithSameTime > 1 &&
                                ` (${event.data.amountOfFreeTimeslotsWithSameTime})`}
                        </span>
                    </span>
                );
            }}
        />
    );
}

function getIsDropDisabled(props: IEventProps & IPrivateProps) {
    const event = props.event as ICalendarEvent<IDroppableFreeTimeslotData>;
    const duration = getMinutesBetweenDates(getDate(event.data.timeCell.end), getDate(event.data.timeCell.start));

    const totalConsecutiveDurationAvailable = duration + event.data.minutesAvailableAfterTimeslot;

    if (props.activeDraggablePrefix === DraggablePrefix.Employee) {
        const typedActiveDraggable = props.activeDraggable as IActiveDraggable<IMedicalExaminationToAdd>;

        return !!(props.activeDraggable && (
            typedActiveDraggable.data.duration > totalConsecutiveDurationAvailable
        ));
    }

    if (props.activeDraggablePrefix === DraggablePrefix.PlannedExamination) {
        if (!ALLOW_MOVE_PLANNING) {
            return true;
        }

        const typedActiveDraggable = props.activeDraggable as IActiveDraggable<IPlannedMedicalExamination>;

        const plannedExamDuration = getMinutesBetweenDates(
            getDate(typedActiveDraggable.data.end),
            getDate(typedActiveDraggable.data.start),
        );

        return !!(props.activeDraggable && (
            plannedExamDuration > totalConsecutiveDurationAvailable
        ));
    }

    return false;
}

export default connect({
    stateProps: (state) => {
        const activeDraggable = getActiveDraggable(state);
        return {
            activeDraggable,
            activeDraggablePrefix: activeDraggable && getPrefixFromPrefixedDraggableId(activeDraggable.draggableId),
        };
    },
})(DroppableFreeTimeSlot);
