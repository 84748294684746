import React, { ReactNode } from 'react';
import './main-header-and-footer-template.scss';
import Header from '../../Header';
import Footer from '../../Footer';
import FluVaccineBanner from '../../PreHeader/FluVaccineBanner';

export interface IMainHeaderAndFooterTemplateProps {
    children: ReactNode;
    showAccountMenu: boolean;
    showCompanySelectDropdown: boolean;
    hideFooterRouteLinks?: boolean;
    showFluVaccineBanner?: boolean;
    customHeaderClass?: string;
    customHeaderPart?: React.ReactElement<{}>;
}

export default function MainHeaderAndFooterTemplate(props: IMainHeaderAndFooterTemplateProps) {
    return (
        <div className="MainHeaderAndFooterTemplate">
            {props.showFluVaccineBanner && <FluVaccineBanner/>}
            <Header
                showAccountMenu={props.showAccountMenu}
                showCompanySelectDropdown={props.showCompanySelectDropdown}
                customHeaderClass={props.customHeaderClass}
                customHeaderPart={props.customHeaderPart}
            />
            <div className="content">
                {props.children}
            </div>
            <Footer hideRouteLinks={props.hideFooterRouteLinks} />
        </div>
    );
}
