import { DayParts } from '../../models/general/daypart';
import { IPlannedCompanyVisit } from '../../models/interventions/company-visits';
import { ITranslator } from '../../models/general/i18n';
import { formatTimeOfDateForDisplay } from '../formatting/formatTime';

export function getPlannedCompanyVisitTimeFormatted(visit: IPlannedCompanyVisit, translator: ITranslator) {
    const isPeriod = visit.dayPart !== undefined;

    if (isPeriod) {
        if (visit.dayPart === DayParts.PM) {
            return translator('common.calendar.afternoon');
        }
        return translator('common.calendar.morning');
    }

    return formatTimeOfDateForDisplay(visit.start);
}
