import React from 'react';
import DeliveryInput, { IFormValues } from './DeliveryInput';
import { schema } from './fluVaccineDeliverySchema';
import { AsyncStatus } from '../../../../../models/general/redux';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    getFluVaccinesOrderWizardEntity,
    getFluVaccineTariffsAsyncInfo,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import {
    getFetchCompanyMedicalCentersAsyncInfo,
    getFetchCompanyAddressesAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { updateFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/actions';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import {
    getDeliveryOptionByLocationId,
    areEnoughVaccinesOrderedToEnableDeliveryOption,
} from '../../../../../config/intervention.config';
import { connect } from '../../../../index';

const CLASS_NAME = 'OrderFluVaccine__Location';
const FORM_NAME = 'flu-vaccine-delivery-form';

const DeliveryWizardFormStep = WizardFormStep<IFormValues>();

interface IPrivateProps {
    amountVaccines: number;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getFluVaccinesOrderWizardEntity(state);

        return {
            amountVaccines: entity && entity.amountVaccines,
        };
    },
})(Delivery);

function Delivery(props: IStepperStepRenderProps & IPrivateProps) {
    return (
        <DeliveryWizardFormStep
            {...props}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.flu_orders_new.steps.delivery.title',
                textTranslationKey: 'interventions.flu_orders_new.steps.delivery.text',
            }}
            showLoaderSelector={(state) => {
                const travelCostAsyncInfo = getFluVaccineTariffsAsyncInfo(state);
                const medicalCentersAsyncInfo = getFetchCompanyMedicalCentersAsyncInfo(state);
                const companyAddressesAsyncInfo = getFetchCompanyAddressesAsyncInfo(state);

                return (travelCostAsyncInfo.status === AsyncStatus.Busy)
                    || (medicalCentersAsyncInfo.status === AsyncStatus.Busy)
                    || (companyAddressesAsyncInfo.status === AsyncStatus.Busy);
            }}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getFluVaccinesOrderWizardEntity(state);
                    const vaccinationLocationId = wizardEntity.delivery && wizardEntity.delivery.vaccinationLocationId;
                    const deliveryOption = getDeliveryOptionByLocationId(vaccinationLocationId);
                    const hasMinNrOfVaccines = deliveryOption ?
                        areEnoughVaccinesOrderedToEnableDeliveryOption(deliveryOption, wizardEntity.amountVaccines) :
                        false;

                    return {
                        vaccinationLocationId: hasMinNrOfVaccines ? vaccinationLocationId : undefined,
                        companyAddress: (wizardEntity.delivery && wizardEntity.delivery.companyAddress) || null,
                        medicalCenter: (wizardEntity.delivery && wizardEntity.delivery.medicalCenter) || null,
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <DeliveryInput {...renderProps} amountVaccines={props.amountVaccines} />,
                onChangeInputActionCreator: ({ values }) => updateFluVaccinesOrderWizardEntity({
                    delivery: {
                        vaccinationLocationId: values.vaccinationLocationId,
                        companyAddress: values.companyAddress,
                        medicalCenter: values.medicalCenter,
                    },
                }),
            }}
        />
    );
}
