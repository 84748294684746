/* Keep these colors up to date with the _colors.scss file */
export enum ColorHex {
    GreyDark = '#868686',
    GreyLight = '#E7E2D9',
    White = '#FFFFFF',

    SlateGrey = '#3E444C',

    Blue = '#019DC6',

    Green = '#2F9E1E',
    DarkGreen = '#0B6517',

    Orange = '#EE8600',
}
