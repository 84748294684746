import { AxiosError } from 'axios';
import removeSensitiveData from '../logging/removeSensitiveData';

export default function removeSensitiveDataFromApiError(error: AxiosError, includeBodyData: boolean = false) {
    return {
        ...error,
        request: undefined,
        config: {
            ...error.config,
            headers: removeSensitiveData(error.request.headers),
            // data - the body - is by default not logged at all to avoid logging any confidential data
            data: includeBodyData ? removeSensitiveData(error.config.data) : undefined,
        },
        response: {
            ...error.response,
            config: undefined,
            request: undefined,
            headers: undefined,
        },
    } as Error;
}
