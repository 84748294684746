import EditAddress, {
    IPublicProps, IEditAddressProps,
    UPDATE_ADDRESS_REQUEST_ID,
} from '../EditAddress';
import connect from '../../../../../utils/libs/redux/connect';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { updateEmployee } from '../../../../../redux/employee/info/actions';
import {
    getSelectedEmployee, getUpdateEmployeeAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { UNSELECTED_ZIPCODEID } from '../../../../common/address/CityTypeahead';
import { UNSELECTED_STREET } from '../../../../common/address/StreetTypeahead';
import { BE_COUNTRY_CODE } from '../../../../../config/general.config';

export default connect<IPublicProps, IEditAddressProps>({
    statePropsDeprecated: (state, publicProps) => {
        const employee = publicProps.objectToAdd || getSelectedEmployee(state);
        const updateAsyncInfo = getUpdateEmployeeAsyncInfo(state, UPDATE_ADDRESS_REQUEST_ID);

        return {
            isDetailsLoaded: !!employee,
            initialValues: employee && employee.address ? {
                countryCode: employee.address.countryCode || '',
                box: employee.address.box || '',
                city: employee.address.city || '',
                number: employee.address.number || '',
                postcode: employee.address.postcode || '',
                street: employee.address.street || UNSELECTED_STREET,
                zipCodeId: employee.address.zipCodeId || UNSELECTED_ZIPCODEID,
            } : {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: UNSELECTED_STREET,
                zipCodeId: UNSELECTED_ZIPCODEID,
            },
            personName: !publicProps.objectToAdd && employee && formatPersonName(employee),
            updateAsyncInfo,
            optional: true,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSubmit: (values) => {
                const state = getState();
                const employee = getSelectedEmployee(state);
                const valuesToSave = { ...values };

                dispatch(updateEmployee({
                    requestId: UPDATE_ADDRESS_REQUEST_ID,
                    employeeData: {
                        address: valuesToSave,
                    },
                    id: employee.id,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditAddress);
