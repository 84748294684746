import { ITranslator } from '../../models/general/i18n';
import { IUpdateEmployeeJobStudentPayload } from '../../models/admin/employee';
import { joinParts } from './formatHelper';

export function formatJobStudent(
    // eslint-disable-next-line max-len
    jobStudent: Partial<Pick<IUpdateEmployeeJobStudentPayload, 'employer' | 'safetyFunction' | 'medicalFunction' | 'studentWorker' | 'sendMail'>>,
    translator: ITranslator,
) {
    const baseKey = 'administration.employees.detail.employee_details.company_data.job_student';

    if (!jobStudent.studentWorker) {
        return translator(`${baseKey}.no`);
    }

    return joinParts(
        [
            translator(`${baseKey}.yes`),
            jobStudent.medicalFunction ? translator(`${baseKey}.medical_function`) : undefined,
            jobStudent.safetyFunction ? translator(`${baseKey}.safety_function`) : undefined,
            jobStudent.sendMail ? translator(`${baseKey}.send_mail`) : undefined,
            jobStudent.employer,
        ].filter((item) => typeof item !== 'undefined'),
        '\n',
    );
}
