import React, { Component } from 'react';
import { connect } from '../../../../../index';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import PageHeader from '../../../../../appShell/PageHeader';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../common/Translate';
import { fields, schema } from './contactPersonManualSchema';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../../models/general/i18n';
import classNames from 'classnames';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import { ICompanyContactToAddFormValues } from '../../../../../administration/CompanyInfo/Contacts/AddContact';
import TextInput from '../../../../../common/input/TextInput';
import ConstantsTypeahead from '../../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../../models/general/constants';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import {
    IPlanMedicalExaminationManualContactBaseEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import RequiredMarker from '../../../../../common/input/RequiredMarker';
import {
    tryFormattingPhoneForBackend,
    tryFormattingPhoneInternational,
} from '../../../../../../utils/formatting/formatPhone';

type FormValues = Pick<ICompanyContactToAddFormValues,
    'firstName' |
    'name' |
    'typeId' |
    'email' |
    'phone'>;

interface IPrivateProps {
    translator: ITranslator;
    wizardEntity: IPlanMedicalExaminationManualContactBaseEntity;
    updateWizardEntity: (values: FormValues) => void;
}

const FORM_NAME = 'contact-person-manual-form';

class ContactPersonManual extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);

        this.getContactInitialValues = this.getContactInitialValues.bind(this);
    }

    public render() {
        const {
            translator, renderStepButtons, updateWizardEntity, goToNextStep,
        } = this.props;

        const INITIAL_VALUES: FormValues = this.getContactInitialValues();

        function handleSubmit(values: FormValues) {
            const formattedValues: FormValues = {
                ...values,
                phone: tryFormattingPhoneInternational(values.phone),
            };
            updateWizardEntity(formattedValues);
            goToNextStep();
        }

        const typePlaceholder = translator(
            'interventions.medical_examinations.new.steps.contact_person_manual.form.fields.type',
        );

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.contact_person_manual.title"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={({
                            values, errors, handleChange, touched, dirty, setFieldValue,
                        }: IFormRenderProps<FormValues>) => (
                            <>
                                <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-contact-first-name"
                                            name={fields.firstName}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isInvalid={touched.firstName && !!errors.firstName}
                                        />
                                        <label htmlFor="add-contact-first-name">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="interventions.medical_examinations.new.steps.contact_person_manual.form.fields.first_name"
                                            />
                                            <RequiredMarker/>
                                        </label>
                                        {touched.firstName &&
                                            <FormFieldError
                                                error={errors.firstName}
                                                placeholders={{
                                                    // eslint-disable-next-line max-len
                                                    fieldName: translator('interventions.medical_examinations.new.steps.contact_person_manual.form.fields.first_name'),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-contact-name"
                                            name={fields.name}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <label htmlFor="add-contact-name">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="interventions.medical_examinations.new.steps.contact_person_manual.form.fields.name"
                                            />
                                            <RequiredMarker/>
                                        </label>
                                        {touched.name &&
                                            <FormFieldError
                                                error={errors.name}
                                                placeholders={{
                                                    fieldName: translator(
                                                        // eslint-disable-next-line max-len
                                                        'interventions.medical_examinations.new.steps.contact_person_manual.form.fields.name',
                                                    ),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <ConstantsTypeahead
                                            id="add-contact-type-id"
                                            constantType={ConstantType.CONTACT_TYPES}
                                            name={fields.typeId}
                                            placeholder={typePlaceholder}
                                            value={values.typeId}
                                            onItemSelected={(value) => setFieldValue('typeId', value)}
                                            isInvalid={touched.typeId && !!errors.typeId}
                                        >
                                            <label htmlFor="add-contact-type-id">
                                                <Translate
                                                    msg={typePlaceholder}
                                                />
                                                <RequiredMarker/>
                                            </label>
                                        </ConstantsTypeahead>
                                        {touched.typeId && (
                                            <FormFieldError
                                                error={errors.typeId}
                                                placeholders={{ fieldName: typePlaceholder }}
                                            />
                                        )}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-contact-phone"
                                            name={fields.phone}
                                            value={values.phone}
                                            onChange={handleChange}
                                            isInvalid={touched.phone && !!errors.phone}
                                        />
                                        <label htmlFor="add-contact-phone">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="interventions.medical_examinations.new.steps.contact_person_manual.form.fields.phone"
                                            />
                                            <RequiredMarker/>
                                        </label>
                                        {touched.phone &&
                                            <FormFieldError
                                                error={errors.phone}
                                                placeholders={{
                                                    fieldName: translator(
                                                        // eslint-disable-next-line max-len
                                                        'interventions.medical_examinations.new.steps.contact_person_manual.form.fields.phone',
                                                    ),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper floatLabel>
                                        <TextInput
                                            id="add-contact-email"
                                            name={fields.email}
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <label htmlFor="add-contact-email">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="interventions.medical_examinations.new.steps.contact_person_manual.form.fields.email"
                                            />
                                            <RequiredMarker/>
                                        </label>
                                        {touched.email &&
                                            <FormFieldError
                                                error={errors.email}
                                                placeholders={{
                                                    fieldName: translator(
                                                        // eslint-disable-next-line max-len
                                                        'interventions.medical_examinations.new.steps.contact_person_manual.form.fields.email',
                                                    ),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>
                                </div>
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {renderStepButtons({
                                        nextButton: {
                                            isSubmit: true,
                                            formName: FORM_NAME,
                                            disabled: !dirty,
                                        },
                                    })}
                                </StickyFooter>
                            </>
                        )}
                    />
                </div>
            </>
        );
    }

    private getContactInitialValues(): FormValues {
        const {
            wizardEntity,
        } = this.props;

        return {
            firstName: wizardEntity.contact && wizardEntity.contact.firstName || '',
            name: wizardEntity.contact && wizardEntity.contact.name || '',
            typeId: wizardEntity.contact && wizardEntity.contact.typeId || null,
            email: wizardEntity.contact && wizardEntity.contact.email || '',
            phone: wizardEntity.contact && tryFormattingPhoneForBackend(wizardEntity.contact.phone) || '',
        };
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            // eslint-disable-next-line max-len
            wizardEntity: getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationManualContactBaseEntity>(state) as
                IPlanMedicalExaminationManualContactBaseEntity,
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationManualContactBaseEntity>({
                    contact: values,
                }));
            },
        };
    },
})(ContactPersonManual);
