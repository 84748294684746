import React, { PureComponent } from 'react';
import { connect } from '../../index';
import './start-onboarding-wizard.scss';
import Translate from '../../common/Translate';
import Button from '../../common/buttons/Button';
import illustration from '../../assets/img/illustrations/illu_onboarding.img.svg';
import { triggerOnboardingWizard } from '../../../redux/onboarding/actions';
import { getSelectedCompany } from '../../../redux/company/selected/selectors';
import { setSelectedCompanySeat } from '../../../redux/company/selected/actions';

const CLASS_NAME = 'StartOnboardingWizard';

interface IPrivateProps {
    triggerOnboardingWizard: () => void;
}

class StartOnboardingWizard extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public render() {
        const { triggerOnboardingWizard } = this.props;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__content`}>
                    <div className="container">
                        <h1><Translate msg="onboarding.start.title" /></h1>
                        <p>
                            <Translate msg="onboarding.start.description"/>
                        </p>
                        <Button
                            id="start-onboarding-wizard"
                            typeName="secondary"
                            onClick={() => triggerOnboardingWizard()}
                        >
                            <Translate msg="onboarding.start.action"/>
                        </Button>
                    </div>
                </div>
                <figure className={`${CLASS_NAME}__figure`} style={{ backgroundImage: `url(${illustration})` }} />
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch, getState) => {
        return {
            triggerOnboardingWizard: () => {
                const company = getSelectedCompany(getState());
                dispatch(setSelectedCompanySeat({
                    isAllSeatsSelected: true,
                    companySeat: {
                        company,
                        alternativeName: '',
                    },
                }));

                dispatch(triggerOnboardingWizard());
            },
        };
    },
})(StartOnboardingWizard);
