import React, { PureComponent } from 'react';
import Dialog from '../../../../../../common/modals/Dialog';
import classNames from 'classnames';
import Translate from '../../../../../../common/Translate';
import Reserved from './Reserved';
import Tab from '../../../../../../common/widget/Tabs/tab';
import Tabs from '../../../../../../common/widget/Tabs';
import Loader from '../../../../../../common/waiting/Loader';

const CLASS_NAME = 'BufferzoneDialog';
const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.select_timeslot.bufferzones';

interface IBufferzoneDialogProps {
    show: boolean;
    showLoader: boolean;
    onClose: () => void;
}

export default class BufferzoneDialog extends PureComponent<IBufferzoneDialogProps> {
    constructor(props: IBufferzoneDialogProps) {
        super(props);
    }

    public render() {
        const { show, showLoader, onClose } = this.props;

        return (
            <Dialog
                className={classNames(CLASS_NAME)}
                show={show}
                onCloseIntent={onClose}
                size="full-width"
            >
                <Loader show={showLoader} />
                <h2><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h2>
                <Tabs>
                    <Tab title={<Translate msg={`${TRANSLATION_PREFIX}.reserved.tab_title`} />}>
                        <Reserved onClose={onClose} />
                    </Tab>
                    {/*<Tab title={<Translate msg={`${TRANSLATION_PREFIX}.open.tab_title`} />}>*/}
                    {/*    <p>TODO: open content</p>*/}
                    {/*</Tab>*/}
                </Tabs>
            </Dialog>
        );
    }
}
