import React from 'react';

import { getUpcomingActivityAsyncInfo } from '../../../../../../redux/planning/selectors';
import Icon from '../../../../../common/icons/Icon';
import LinkToRoute from '../../../../../common/navigation/LinkToRoute';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import Translate from '../../../../../common/Translate';
import { AGENDA_ROUTE } from '../../AgendaDashboardBlock.const';

import { IMiniAgendaContentProps } from './MiniAgendaContent.type';

const MiniAgendaContent = ({
    hasUpcomingActivity,
    children,
}: IMiniAgendaContentProps) => {
    return (
        <div className="content">
            <div className="content-inner">
                <h6><Translate msg="home.dashboard.agenda.next_title" /></h6>
                <TinyLoader asyncInfoSelector={getUpcomingActivityAsyncInfo}>
                    {
                        (hasUpcomingActivity && children) && children }
                    {
                        !hasUpcomingActivity &&
                        <div className="appointment">
                            <div className="description">
                                <span><Translate msg="home.dashboard.agenda.no_appointments" /></span>
                            </div>
                        </div>
                    }
                </TinyLoader>
                <div className="appointment-footer">
                    <LinkToRoute id="agenda-footer-link" className="icon-link" to={AGENDA_ROUTE}>
                        <span><Translate msg="home.dashboard.agenda.footer_link" /></span>
                        <Icon typeName="chevron-right" />
                    </LinkToRoute>
                </div>
            </div>
        </div>
    );
};

export { MiniAgendaContent };
