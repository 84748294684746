import React, { PureComponent } from 'react';
import { connect } from '../../..';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';
import { getDisclaimerContent, getDisclaimerContentAsyncInfo } from '../../../../redux/legal/selectors';
import { fetchDisclaimerContentActions } from '../../../../redux/legal/actions';
import { isFalsyOrEmptyObject } from '../../../../utils/core/object/isEmptyObject';
import { ICmsPage } from '../../../../models/general/cms';
import PagePart from '../../../common/page/PagePart';

interface IPrivateProps {
    disclaimerContentAsyncInfo: IAsyncFieldInfo;
    disclaimerContent: ICmsPage;
    fetchDisclaimerContent: () => void;
}

class DisclaimerContent extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public componentDidMount() {
        const { disclaimerContentAsyncInfo, fetchDisclaimerContent } = this.props;

        if (disclaimerContentAsyncInfo.status === AsyncStatus.Initial) {
            fetchDisclaimerContent();
        }
    }

    public render() {
        const { disclaimerContent } = this.props;

        if (isFalsyOrEmptyObject(disclaimerContent)) {
            return null;
        }

        const { main: pageParts } = disclaimerContent.pagePartsByRegion;

        return (
            <>
                {disclaimerContent.page && disclaimerContent.page.title && (
                    <h1>{disclaimerContent.page.title}</h1>
                )}
                {pageParts.map((pagePart, index) => (
                    <PagePart pagePart={pagePart} key={`disclaimer-pagePart-${index}`}/>
                ))}
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            disclaimerContent: getDisclaimerContent(state),
            disclaimerContentAsyncInfo: getDisclaimerContentAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            fetchDisclaimerContent: () => dispatch(fetchDisclaimerContentActions.trigger({})),
        };
    },
})(DisclaimerContent);
