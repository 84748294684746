import {
    IOnboardingWizardPayload, IOnboardingWizardEntity,
    ONBOARDING_WIZARD_STEP_ID,
    IFetchOnboardingSeatsAvailabilitiesPayload,
} from '../../models/onboarding/wizard';
import { navigateTo } from '../location/actions';
import ROUTE_KEYS from '../../routeKeys';
import { createAction, createTypeActions } from '../../utils/libs/redux/createAction';
import {
    UPDATE_ONBOARDING_WIZARD_ENTITY,
    FETCH_ONBOARDING_SEATS_AVAILABILITIES,
    COPY_ONBOARDING_AVAILABILITIES,
    CONFIRM_ONBOARDING_WIZARD,
} from './types';
import { getOnboardingWizardSteps } from '../../config/navigation/wizardStepsMap';

// Onboarding wizard
function navigateToOnboardingWizardStep(payload: IOnboardingWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_ONBOARDING_NEW, payload);
}

export function triggerOnboardingWizard() {
    return navigateToOnboardingWizardStep({
        step: getOnboardingWizardSteps().firstStepId,
        resetDataEntity: true,
    });
}

export function changeOnboardingWizardStep(step: ONBOARDING_WIZARD_STEP_ID) {
    return navigateToOnboardingWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateOnboardingWizardEntity(payload: Partial<IOnboardingWizardEntity>) {
    return createAction<object>(
        UPDATE_ONBOARDING_WIZARD_ENTITY,
        payload,
    );
}

export const fetchOnboardingSeatsAvailabilitiesActions = createTypeActions<IFetchOnboardingSeatsAvailabilitiesPayload>({
    type: FETCH_ONBOARDING_SEATS_AVAILABILITIES,
});

export const copyOnboardingAvailabilitiesActions = createTypeActions({
    type: COPY_ONBOARDING_AVAILABILITIES,
});

export const confirmOnboardingWizardActions = createTypeActions({
    type: CONFIRM_ONBOARDING_WIZARD,
});
