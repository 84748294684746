// validateEachRouteEpic
import { createEpic } from '../../index';
import { ON_ACTION_TYPE_REGEX } from '../../../config/redux.config';
import { doesRouteBelongToGroup } from '../../../routes';
import ROUTE_KEYS from '../../../routeKeys';
import { IUiHistory } from '../../../models/ui/history';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { updateUiHistory } from './actions';
import { NO_ROUTE } from './reducer';
import { getRouteThatTriggeredWizard } from './selectors';
import { getLocationState } from '../../location/selectors';
import isSetString from '../../../utils/core/string/isSetString';

const ROUTE_KEYS_NOT_TO_SAVE_AS_WIZARD_TRIGGER_ROUTE: string[] = [
    ROUTE_KEYS.R_LOGIN,
    ROUTE_KEYS.R_LOGOUT,
];

// updateUiHistoryEpic
createEpic<{}>({
    onActionType: ON_ACTION_TYPE_REGEX.ALL_ROUTE_ACTIONS,
    processMultiple: ({ action, getState }, dispatch, done) => {
        const routeKey = action.type as ROUTE_KEYS;

        let uiHistoryToUpdate: Partial<IUiHistory> = {};
        let shouldTriggerUpdate = false;

        if (doesRouteBelongToGroup({ routeKey, group: ROUTE_GROUP.IS_DASHBOARD })) {
            shouldTriggerUpdate = true;

            uiHistoryToUpdate = {
                ...uiHistoryToUpdate,
                lastDashboardRoute: {
                    type: routeKey,
                },
            };
        }

        const state = getState();
        const routeThatTriggeredWizard = getRouteThatTriggeredWizard(state);

        if (doesRouteBelongToGroup({ routeKey, group: ROUTE_GROUP.IS_PART_OF_WIZARD })) {
            if (!routeThatTriggeredWizard) {
                shouldTriggerUpdate = true;

                const previousRoute = getLocationState(state).prev;

                if (
                    previousRoute
                    && isSetString(previousRoute.type)
                    && !ROUTE_KEYS_NOT_TO_SAVE_AS_WIZARD_TRIGGER_ROUTE.includes(previousRoute.type)
                    && !doesRouteBelongToGroup({ routeKey: previousRoute.type, group: ROUTE_GROUP.IS_PART_OF_WIZARD })
                ) {
                    uiHistoryToUpdate = {
                        ...uiHistoryToUpdate,
                        routeThatTriggeredWizard: previousRoute,
                    };
                }
            }
        } else {
            if (routeThatTriggeredWizard) {
                shouldTriggerUpdate = true;

                uiHistoryToUpdate = {
                    ...uiHistoryToUpdate,
                    routeThatTriggeredWizard: NO_ROUTE,
                };
            }
        }

        if (shouldTriggerUpdate) {
            dispatch(updateUiHistory(uiHistoryToUpdate));
        }

        return done();
    },
    latest: false,
});
