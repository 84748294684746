import React from 'react';
import './select-user-type-dialog.scss';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button';
import connect from '../../../../../../utils/libs/redux/connect';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';
import Dialog from '../../../../../common/modals/Dialog';
import { getRouteKey } from '../../../../../../redux/location/selectors';
import { AddUserAccountWizardStep } from '../../../../../../models/user/userAccount';
import { getWizardExitLinkActionMemoized } from '../../../../../../redux/ui/history/selectors';

interface IPrivateProps {
    show: boolean;
    onClose: () => void;
    navigateToAddExternal: () => void;
    navigateToAddInternal: () => void;
}

const CLASS_NAME = 'SelectUserTypeDialog';

function SelectUserTypeDialog(props: IPrivateProps) {
    return (
        <Dialog show={props.show} onCloseIntent={props.onClose}>
            <div className={CLASS_NAME}>
                <h4>
                    <Translate msg="account.account_settings.manage_users.add.select_type_dialog.title" />
                </h4>
                <Button
                    id="add-user-account-internal-button"
                    onClick={props.navigateToAddInternal}
                    typeName="secondary"
                >
                    <Translate msg="account.account_settings.manage_users.add.select_type_dialog.options.internal" />
                </Button>
                <Button
                    id="add-user-account-external-button"
                    onClick={props.navigateToAddExternal}
                    typeName="secondary"
                >
                    <Translate msg="account.account_settings.manage_users.add.select_type_dialog.options.external" />
                </Button>
            </div>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        show: getRouteKey(state) === ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD,
    }),
    dispatchProps: (dispatch, getState) => {
        return {
            onClose: () => dispatch(getWizardExitLinkActionMemoized(getState())),
            navigateToAddExternal: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL, {
                stepId: AddUserAccountWizardStep.PersonalData,
            })),
            navigateToAddInternal: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL, {
                stepId: AddUserAccountWizardStep.SelectEmployee,
            })),
        };
    },
})(SelectUserTypeDialog);
