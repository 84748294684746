import isArray from '@snipsonian/core/es/is/isArray';
import { IUserMessageDetailAction, TUserMessageDetailExtraParam } from '../../../../../models/user/inbox';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';

export function getLinkId({ actionType, id }: IUserMessageDetailAction) {
    return `body-link-${actionType}-${id}`;
}

export function formatDateForFilterInput(date: string) {
    return formatDateForBackend(date);
}

export function isArrayOfIUserMessageDetailAction(
    extraRefParam: TUserMessageDetailExtraParam,
): extraRefParam is IUserMessageDetailAction[] {
    return isArray(extraRefParam);
}
