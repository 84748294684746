import React, { PureComponent, MouseEvent } from 'react';
import './edit-address.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import {
    schema,
    optionalSchema,
    FormValues,
    TAddressFormValue,
    internationalSchema,
    optionalInternationalSchema,
} from './updateAddressSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { UNSELECTED_ZIPCODEID } from '../../../../common/address/CityTypeahead';
import { ITraceableApiError } from '../../../../../models/general/error';
import { IEmployeeToAdd } from '../../../../../models/admin/employee';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import AddressFields from '../../../../common/address/AddressFields';

export interface IPublicProps {
    onSubmit: (values: TAddressFormValue) => void;
    initialValues: TAddressFormValue;
    personName: string;
    isDetailsLoaded: boolean;
    updateAsyncInfo?: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    optional?: boolean;
    allowInternationalAddress?: boolean;
}

export interface IEditAddressProps {
    onClose: () => void;
    objectToAdd?: IEmployeeToAdd;
    onFormSubmit?: (address: TAddressFormValue) => void;
}

const CLASS_NAME = 'EditAddress';
const FORM_NAME = 'edit-address-form';

export const UPDATE_ADDRESS_REQUEST_ID = 'address';

interface IState {
    showCountryNotSupportedDialog: boolean;
}

export default class EditAddress extends PureComponent<IPublicProps & IEditAddressProps, IState> {
    constructor(props: IPublicProps & IEditAddressProps) {
        super(props);

        this.state = {
            showCountryNotSupportedDialog: false,
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onToggleShowCountryNotSupportedDialog = this.onToggleShowCountryNotSupportedDialog.bind(this);
        this.onCloseCountryNotSupportedDialog = this.onCloseCountryNotSupportedDialog.bind(this);
    }

    public render() {
        const {
            initialValues,
            isDetailsLoaded, personName,
            updateAsyncInfo, optional, allowInternationalAddress,
        } = this.props;

        if (!isDetailsLoaded) {
            return null;
        }

        const titleTranslationKey =
            `administration.employees.edit_address.${!personName ? 'title_new_employee' : 'title'}`;

        const formSchema =
            (allowInternationalAddress && optional) ?
                optionalInternationalSchema : allowInternationalAddress ?
                internationalSchema : optional ?
                    optionalSchema : schema;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        <div className={CLASS_NAME}>
                            <header className={`${CLASS_NAME}__header`}>
                                <h2>
                                    <Translate
                                        msg={titleTranslationKey}
                                        placeholders={{ name: personName }}
                                    />
                                </h2>
                            </header>
                            <Form
                                name={FORM_NAME}
                                handleSubmit={this.onFormSubmit}
                                initialValues={{ address: initialValues }}
                                schema={formSchema}
                                render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                    return (
                                        <>
                                            <Loader
                                                show={updateAsyncInfo && updateAsyncInfo.status === AsyncStatus.Busy}
                                            />
                                            <AddressFields
                                                {...formRenderProps}
                                                translator={translator}
                                                initialAddress={initialValues}
                                                allowInternationalAddress={allowInternationalAddress}
                                            />
                                            <FormError error={updateAsyncInfo && updateAsyncInfo.error} />
                                            <div className={`${CLASS_NAME}__actions`}>
                                                <Button
                                                    id="update-address-cancel-button"
                                                    typeName="secondary"
                                                    outline={true}
                                                    onClick={this.onCancelClick}
                                                >
                                                    <Translate
                                                        msg="administration.employees.edit_address.form.cancel"
                                                    />
                                                </Button>
                                                <SubmitButton
                                                    id="update-address-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    <Translate
                                                        msg="administration.employees.edit_address.form.submit"
                                                    />
                                                </SubmitButton>
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </div>
                        <ErrorDialog
                            showOverride={this.state.showCountryNotSupportedDialog}
                            onCloseDialog={this.onCloseCountryNotSupportedDialog}
                            titleTranslationKey="administration.employees.edit_address.form.country_not_supported"
                            hideRealErrorMessage={true}
                        />
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidUpdate(prevProps: IPublicProps) {
        // Goes from initial to busy => busy to initial (never goes to a success state)
        // This is because it uses a request id in the redux state instead of the regular flow
        if (this.props.updateAsyncInfo &&
            this.props.updateAsyncInfo.status === AsyncStatus.Initial &&
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onToggleShowCountryNotSupportedDialog() {
        this.setState({ showCountryNotSupportedDialog: !this.state.showCountryNotSupportedDialog });
    }

    private onCloseCountryNotSupportedDialog() {
        this.setState({ showCountryNotSupportedDialog: false });
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, updateAsyncInfo } = this.props;

        if (updateAsyncInfo) {
            clearError(updateAsyncInfo.error);
        }
        onClose();
    }

    private onFormSubmit(values: FormValues) {
        const { onSubmit, onFormSubmit } = this.props;
        if (typeof onFormSubmit === 'function') {
            onFormSubmit({
                ...values.address,
                zipCodeId: (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                    null : values.address.zipCodeId,
            });
        } else {
            onSubmit({
                ...values.address,
                zipCodeId: (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                    null : values.address.zipCodeId,
            });
        }
    }
}
