import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux';

import { getAsyncDoInitialState } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { IMappedAdministeredVaccination, IMappedToAdministerVaccination } from '../../../models/interventions/vaccines';
import { REDUCER_KEYS } from '../../../config/redux.config';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getAsyncFetchInitialState,
    registerReducer,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
} from '../../index';

import {
    FETCH_ADMINISTERED_VACCINATIONS,
    FETCH_TO_ADMINISTER_VACCINATIONS,
    IReducerState,
    REQUEST_VACCINATION,
} from './types';

export const reducerKey = REDUCER_KEYS.INTERVENTION_VACCINATIONS;

const initialState: IReducerState = {
    administered: getAsyncFetchInitialState(),
    toAdminister: getAsyncFetchInitialState(),
    requestVaccination: getAsyncDoInitialState(),
};

const vaccinationsActionHandlers = {
    ...createAsyncFetchActionHandlers<
        IMappedAdministeredVaccination[],
        IReducerState,
        IMappedAdministeredVaccination[]>({
            baseActionType: FETCH_ADMINISTERED_VACCINATIONS,
            fieldName: 'administered',
            overrideTriggerActionType: [
                ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED,
                FETCH_ADMINISTERED_VACCINATIONS,
            ],
            resetDataOnTrigger: false,
            reducerKey,
    }),
    ...createAsyncFetchActionHandlers<
        IMappedToAdministerVaccination[],
        IReducerState,
        IMappedToAdministerVaccination[]>({
            baseActionType: FETCH_TO_ADMINISTER_VACCINATIONS,
            fieldName: 'toAdminister',
            overrideTriggerActionType: [
                ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER,
                FETCH_TO_ADMINISTER_VACCINATIONS,
            ],
            resetDataOnTrigger: false,
            reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REQUEST_VACCINATION,
        fieldName: 'requestVaccination',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers: vaccinationsActionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
