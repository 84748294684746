import { createEpic } from '../../index';
import {
    fetchCompaniesSucceeded, fetchCompaniesFailed,
} from './actions';
import {
    FETCH_COMPANIES,
} from './types';
import {
    IFetchCompaniesPayload,
} from '../../../models/admin/company';

// fetchCompaniesEpic
createEpic<IFetchCompaniesPayload>({
    onActionType: FETCH_COMPANIES,
    async processReturn({ api, action }) {
        try {
            const { companyCodeOrNameFilter } = action.payload;
            const companies = await api.admin.company.fetchCompanies(companyCodeOrNameFilter);
            return fetchCompaniesSucceeded(companies);
        } catch (error) {
            return fetchCompaniesFailed(error);
        }
    },
    latest: false,
});
