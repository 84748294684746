import { REDUCER_KEYS } from '../config/redux.config';
import {
    IAuthState,
    ICompaniesState,
    ICompanyDocumentsState,
    ICompanyFunctionsState,
    ICompanyInfoState,
    ICompanySelectedState,
    ICompanyUsersState,
    ICompanyVisitsState,
    IConstantsState,
    IContactState,
    ICookieConsentState,
    IDocumentCenterCoursesState,
    IDocumentCenterMedicalDocumentsState,
    IDocumentCenterRiskManagementState,
    IDocumentCenterWorkPostCardsState,
    IEmployeesState,
    IEmployeeConvocationState,
    IEmployeeInfoState,
    IEmployeeWizardState,
    IFlashMessagesState,
    II18nState,
    IInboxState,
    IInterventionExecutedState,
    IInterventionFluVaccineState,
    IInterventionPolicyAdviceState,
    IInterventionRequestState,
    IInterventionWorkAccidentsState,
    IInterventionVaccinationsState,
    IInvoiceState,
    ILegalState,
    ILocationState,
    IMedicalExaminationState,
    INavigationState,
    INotificationsState,
    IOnboardingState,
    IPlanningState,
    IPreventionUnitsState,
    IUIState,
    IUiDragAndDropState,
    IUiHistoryState,
    IInterventionBufferzonesState,
} from './allReducers';
import { IEntitiesReducerState } from '../models/entities/entities.models';

/**
 * As computed property names don't exist for interfaces we need to explicitly name the property again
 * Always keep the individual reducer keys in sync with this interface
 */
export interface IState {
    auth: IAuthState;
    companies: ICompaniesState;
    company_documents: ICompanyDocumentsState;
    company_functions: ICompanyFunctionsState;
    company_info: ICompanyInfoState;
    company_selected: ICompanySelectedState;
    company_users: ICompanyUsersState;
    company_visits: ICompanyVisitsState;
    constants: IConstantsState;
    contact: IContactState;
    cookie_consent: ICookieConsentState;
    document_center_courses: IDocumentCenterCoursesState;
    document_center_medical_documents: IDocumentCenterMedicalDocumentsState;
    document_center_risk_management_documents: IDocumentCenterRiskManagementState;
    document_center_workpostcards: IDocumentCenterWorkPostCardsState;
    drag_and_drop: IUiDragAndDropState;
    employees: IEmployeesState;
    employee_convocation: IEmployeeConvocationState;
    employee_info: IEmployeeInfoState;
    employee_wizard: IEmployeeWizardState;
    entities: IEntitiesReducerState;
    flash_messages: IFlashMessagesState;
    i18n: II18nState;
    inbox: IInboxState;
    intervention_bufferzones: IInterventionBufferzonesState;
    intervention_executed: IInterventionExecutedState;
    intervention_fluVaccines: IInterventionFluVaccineState;
    interventions_policyAdvice: IInterventionPolicyAdviceState;
    intervention_request: IInterventionRequestState;
    intervention_work_accidents: IInterventionWorkAccidentsState;
    intervention_vaccinations: IInterventionVaccinationsState;
    invoice: IInvoiceState;
    legal: ILegalState;
    location: ILocationState;
    medicalExamination: IMedicalExaminationState;
    navigation: INavigationState;
    notifications: INotificationsState;
    onboarding: IOnboardingState;
    planning: IPlanningState;
    prevention_units: IPreventionUnitsState;
    ui: IUIState;
    ui_history: IUiHistoryState;
}

export function getReducerState<S>(state: IState, reducerKey: REDUCER_KEYS): S {
    return state[reducerKey as string];
}
