import { object } from 'yup';
import { ShapeOf } from '../../../../models/ui/form';
import { FormValues } from './detail';
import number from '../../../../utils/libs/yup/number';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    email: 'email',
    function: 'function',
    languageId: 'languageId',
    seat: 'seat',
};

export const schema = object({
    [fields.email]: string().email(),
    [fields.function]: string(),
    [fields.languageId]: number(),
    [fields.seat]: string(),
});
