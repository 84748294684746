import { get } from '../../../../../utils/api/requestWrapper';
import { IConvocationRecipient } from '../../../../../models/general/documents';
import { ICompanyCodePayload } from '../../../../../models/admin/company';
import { URL } from '../../documents.const';

export function fetchConvocationRecipients(payload: ICompanyCodePayload) {
    return get<IConvocationRecipient[]>({
        url: URL.CONVOCATION_RECIPIENTS,
        pathParams: {
            companyCode: payload.companyCode,
        },
        mapResponse: (response) => response['recipients'],
    });
}
