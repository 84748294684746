import { get, post } from '../../utils/api/requestWrapper';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, monthOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import {
    IFetchMedicalDocumentFilesInBulk,
    IFetchMedicalDocumentsPayload, IMedicalDocument,
    IMedicalDocumentsFilter,
} from '../../models/documentCenter/medicalDocuments';

export const URL = {
    MEDICAL_DOCUMENTS: '/companies/{companyCode}/medical-documents',
    MEDICAL_DOCUMENT_FILES_BULK: '/companies/{companyCode}/bulk-documents/medical',
};

export const DEFAULT_MEDICAL_DOCUMENTS_FILTER = {
    startDate: formatDateForBackend(monthOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchMedicalDocuments(
    { companyCode, showFullFamily }: IFetchMedicalDocumentsPayload,
    {
        startDate = DEFAULT_MEDICAL_DOCUMENTS_FILTER.startDate,
        endDate = DEFAULT_MEDICAL_DOCUMENTS_FILTER.endDate,
    }: IMedicalDocumentsFilter,
) {
    return get<IMedicalDocument[]>({
        url: URL.MEDICAL_DOCUMENTS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['medical-documents'],
    });
}

export function fetchMedicalDocumentFilesInBulk(
    { ids, companyCode }: IFetchMedicalDocumentFilesInBulk,
) {
    return post<{}>({
        url: URL.MEDICAL_DOCUMENT_FILES_BULK,
        pathParams: {
            companyCode,
        },
        body: {
            ids,
        },
        // dummy mapResponse as documents will be sent to message center
        mapResponse: (response) => ({}),
    });
}
