 // See src/views/assets/scss/config/_media-queries.scss
const screenSizes = {
    extraSmallMin: 480,
    smallMin: 768,
    mediumMin: 992,
    largeMin: 1200,
    extraLargeMin: 1400,
};

export function isExtraSmallScreen() {
    return document.body.clientWidth < screenSizes.smallMin;
}

export function isSmallScreen() {
    return document.body.clientWidth < screenSizes.mediumMin;
}

export function isMediumScreen() {
    return document.body.clientWidth < screenSizes.largeMin;
}

export function isLargeScreen() {
    return document.body.clientWidth < screenSizes.extraLargeMin;
}

export function isExtraLargeScreen() {
    return document.body.clientWidth >= screenSizes.extraLargeMin;
}
