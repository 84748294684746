import { get } from '../../utils/api/requestWrapper';
import { IMaintenanceMessage } from '../../models/general/maintenance';

export const URL = {
    MAINTENANCE: '/maintenance',
};

export function fetchMaintenanceMessages() {
    return get<IMaintenanceMessage[]>({
        url: URL.MAINTENANCE,
        mapResponse: (data) => data.config,
        resetSessionTimerOnSuccess: false,
    });
}
