import React, { PureComponent } from 'react';
import { logOutgoingLink } from '../../../../utils/logging/analytics/eventLogger';

interface IPublicProps {
    href: string;
    children: React.ReactNode;
    className?: string;
}

export default class OutgoingLink extends PureComponent<IPublicProps> {
    constructor(props: IPublicProps) {
        super(props);

        this.onClickLink = this.onClickLink.bind(this);
    }

    render() {
        const { href, children, className } = this.props;

        return (
            <a href={href} target="_blank" onClick={this.onClickLink} className={className}>
                {children}
            </a>
        );
    }

    onClickLink() {
        logOutgoingLink({
            outgoingUrl: this.props.href,
        });
    }
}
