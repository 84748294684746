import { TCompanyStatisticalCode } from '../../config/company/companyInfo.config';
import { ICompany, ICompanyCodePayload } from './company';
import { ColorHex } from '../general/colors';

export type TCompanySituationHistory = ICompanySituationHistoryOfOneYear[];

export interface ICompanySituationHistorySuccessPayload {
    showPreviousYearUntilDate: string;
    situationHistory: TCompanySituationHistory;
}

export interface ICompanySituationHistoryOfOneYear {
    company: Pick<ICompany, 'name' | 'id' | 'companyCode'>;
    fixedSituationId: number;
    statisticalCodeId: number;
    statisticalCode: TCompanyStatisticalCode;
    year: string; // e.g. 2017
    calculatePU: boolean;
}

export interface IFetchAccomplishmentsContributionsOfYearPayload {
    year: number;
}

export interface IFetchAccomplishmentsContributionDetailsOfYearAndTypePayload
    extends IFetchAccomplishmentsContributionsOfYearPayload {
    type: string;
}

export interface IFetchAccomplishmentsContributionDetailsPayload {
    yearAndType: string;
}

export interface IAccomplishmentsContributionsOfYear extends IFetchAccomplishmentsContributionsOfYearPayload {
    accomplishmentsContributions: IAccomplishmentsContribution[];
}

export interface IAccomplishmentsContribution {
    paragraph: string; // e.g. '1', '2', '2.1'
    type: string;
    description: string;
    confidential: boolean;
    amount: number;
}

export interface IAccomplishmentsContributionDetail {
    id: number; // is either the medicalExaminationId or either the companyVisitId
    company: Pick<ICompany, 'name' | 'companyCode'>;
    groupCode: string; // e.g. 24072.1 (always the same as companyCode?)
    date: string; // e.g. 2018-03-20
    description: string; // e.g. Periodieke gezondheidsbeoordeling
    // will contain either 'employee' or either 'reference' (= company visit code)
    employee?: string; // e.g. 'firstname lastname'
    reference?: string; // e.g. BB-R001
}

/* PreventionUnitId values should be the same as IPreventionUnitNumbers property names! */
export enum PreventionUnitId {
    Assigned = 'assigned',
    Invoiced = 'invoiced',
    Planned = 'planned',
    Registered = 'registered',
    Remaining = 'remaining',
    Unused = 'unused',
}
/* IPreventionUnitNumbers property names should be the same as PreventionUnitId values! */
export interface IPreventionUnitNumbers {
    assigned: number;
    invoiced: number;
    planned: number;
    registered: number;
    remaining: number;
    unused: number;
}

export type TPreventionUnitConfig = IPreventionUnitConfigItem[];

export interface IPreventionUnitConfigItem {
    id: PreventionUnitId;
    labelTranslationKey: string;
    tooltipTranslationKey?: string;
    color: ColorHex;
    legendColorOverride?: string;
    detailTitleTranslationKey?: string;
}

export interface IPreventionUnits {
    id: number;
    headQuarterId: number;
    units: IPreventionUnitNumbers;
    description: string;
    company: Pick<ICompany, 'companyCode'>;
}

export type TPreventionUnitsGroupType = 'company' | 'seat' | 'type';

export interface IPreventionUnitsResponse {
    preventionUnits: IPreventionUnits[];
    groupType: TPreventionUnitsGroupType;
}

export interface IPreventionUnitTask {
    count: number;
    code: string;
    codeOfAccomplishment: string;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    description: string;
    pe: number;
    taskDate: string;
    title: string;
}

export interface IFetchPreventionUnitsDetailPayload extends ICompanyCodePayload {
    year: number;
    type: string;
}

export interface IExportPreventionUnitsPayload {
    year: number;
}

export interface IFetchPreventionUnitsPayload extends ICompanyCodePayload {
    year: number;
}
