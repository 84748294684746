import { get } from '../../utils/api/requestWrapper';
import {
    ICompanySeatsData,
    IFetchCompanySeatsPayload,
    TCompanyOnboardingVariables,
    ICompanyCodePayload, ICompanyDismissable,
} from '../../models/admin/company';

export const URL = {
    COMPANIES: '/companies/',
    COMPANY_SEATS: '/companies/{companyCode}/seats',
    COMPANY_ONBOARDING: '/companies/{companyCode}/onboarding',
};

export function fetchCompanies(companyCodeOrNameFilter?: string) {
    return get<ICompanyDismissable[]>({
        url: URL.COMPANIES,
        queryParams: {
            name: companyCodeOrNameFilter,
        },
    });
}

export function fetchCompanySeats(
    { companyCode, showFullSeats = false, noEmployeeCount = false }: IFetchCompanySeatsPayload) {
    return get<ICompanySeatsData>({
        url: URL.COMPANY_SEATS,
        pathParams: {
            companyCode,
        },
        queryParams: { showFullSeats, noEmployeeCount },
    });
}

export function fetchCompanyOnboardingVariables({ companyCode }: ICompanyCodePayload) {
    return get<TCompanyOnboardingVariables>({
        url: URL.COMPANY_ONBOARDING,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response.variables,
    });
}
