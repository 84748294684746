export const URL = {
    COMPANY_EMPLOYEES: '/companies/{companyCode}/employees',
    COMPANY_EMPLOYEES_QUERY: '/companies/{companyCode}/employees/query',
    COMPANY_EMPLOYEES_EXPORT: '/companies/{companyCode}/employees/export',
    EMPLOYEES: '/employees',
    EMPLOYEE: '/employees/{id}',
    EMPLOYEE_STATUS: '/employees/{employeeId}/status',
    EMPLOYEE_EMPLOYMENT: '/employees/{id}/employment',
    EMPLOYEE_CHANGE_EMPLOYEE_OUT_SERVICE: '/employees/{id}/changedateoutservice',
    EMPLOYEE_CLEAR_EMPLOYEE_OUT_SERVICE: '/employees/{id}/cleardateoutservice',
    EMPLOYEE_STATUTES: '/employees/{id}/statutes',
    EMPLOYEE_PERSONAL_RISKS: '/employees/{id}/personalrisks',
    EMPLOYEE_ABSENCES: '/employees/{id}/absences',
    EMPLOYEE_ABSENCE: '/employees/{id}/absences/{absenceId}',
    EMPLOYEE_COST_CENTER: '/employees/{id}/cost-center/{costCenter}',
    EMPLOYEE_RISK_RESEARCHES: '/employees/{id}/riskresearches',
    EMPLOYEE_BY_INSZ: '/companies/{companyCode}/employees/insz',
    EMPLOYEE_BY_INSZ_QUERY: '/companies/{companyCode}/employees/insz/query',
    EMPLOYEE_DUPLICATE_RISKS: '/employees/{idFrom}/duplicate-risks/{idTo}',
    EMPLOYEE_EXECUTED_AND_NEXT_MEDICAL_EXAMINATIONS: '/employees/{id}/medical-examinations',
    EMPLOYEE_JOB_STUDENT: '/employees/{id}/questionnaires',
    EMPLOYEES_WITHOUT_EMAIL: '/companies/{companyCode}/employees/without-email',
};

export const BFF_URL = {
    // eslint-disable-next-line max-len
    EMPLOYEE_PLANNED_MEDICAL_EXAMINATIONS: '/companies/{organizationalUnitCode}/employees/{employeeId}/activities/medical-examinations/planned',
    EMPLOYEE_EMPLOYEE_OUT_SERVICE: '/employments/{id}',
};
