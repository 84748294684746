import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createActionHandler,
} from '../../index';
import {
    IFluVaccinesOrder,
    IFluVaccinesConfig,
    IFluVaccinesOrderWizardData,
    IFluVaccinesOrderWizardPayload,
    IFluVaccineTariffs,
    IFluVaccinesAvailable,
} from '../../../models/interventions/fluVaccines';
import {
    FETCH_FLU_VACCINES_ORDERS,
    FETCH_FLU_VACCINES_CONFIG,
    FETCH_FLU_VACCINE_TARIFFS,
    FETCH_FLU_VACCINES_AVAILABLE,
    UPDATE_FLU_VACCINES_ORDER_WIZARD_ENTITY,
    CREATE_FLU_VACCINES_ORDER,
    UPDATE_FLU_VACCINES_ORDER,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState, createAsyncDoActionHandlers } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { getOrderFluVaccinesWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { IResetStateActionHandlerFactory } from '../../../utils/libs/redux/registerReducer';

export const reducerKey = REDUCER_KEYS.INTERVENTION_FLUVACCINES;

export interface IReducerState {
    fluVaccinesOrders: IAsyncFetchField<IFluVaccinesOrder[]>;
    fluVaccinesConfig: IAsyncFetchField<IFluVaccinesConfig>;
    fluVaccineTariffs: IAsyncFetchField<IFluVaccineTariffs>;
    fluVaccinesAvailable: IAsyncFetchField<IFluVaccinesAvailable>;
    fluVaccinesOrderWizardData: IFluVaccinesOrderWizardData;
    createFluVaccinesOrder: IAsyncDoField;
    updateFluVaccinesOrder: IAsyncDoField;
}

const initialState: IReducerState = {
    fluVaccinesOrders: getAsyncFetchInitialState(),
    fluVaccinesConfig: getAsyncFetchInitialState(),
    fluVaccineTariffs: getAsyncFetchInitialState(),
    fluVaccinesAvailable: getAsyncFetchInitialState(),
    fluVaccinesOrderWizardData: null,
    createFluVaccinesOrder: getAsyncDoInitialState(),
    updateFluVaccinesOrder: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IFluVaccinesOrder[], IReducerState, IFluVaccinesOrder[]>({
        baseActionType: FETCH_FLU_VACCINES_ORDERS,
        fieldName: 'fluVaccinesOrders',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [FETCH_FLU_VACCINES_ORDERS, ROUTE_KEYS.R_FLU_VACCINES_ORDERS],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IFluVaccinesConfig, IReducerState, IFluVaccinesConfig>({
        baseActionType: FETCH_FLU_VACCINES_CONFIG,
        fieldName: 'fluVaccinesConfig',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IFluVaccineTariffs, IReducerState, IFluVaccineTariffs>({
        baseActionType: FETCH_FLU_VACCINE_TARIFFS,
        fieldName: 'fluVaccineTariffs',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IFluVaccinesAvailable, IReducerState, IFluVaccinesAvailable>({
        baseActionType: FETCH_FLU_VACCINES_AVAILABLE,
        fieldName: 'fluVaccinesAvailable',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    [ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW]:
        createActionHandler<IReducerState, IFluVaccinesOrderWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    fluVaccinesOrderWizardData: (stepId === getOrderFluVaccinesWizardSteps().firstStepId)
                        ? {
                            stepId,
                            entity: payload.resetDataEntity
                                ? {}
                                // eslint-disable-next-line max-len
                                : (oldState.fluVaccinesOrderWizardData && oldState.fluVaccinesOrderWizardData.entity) || {},
                        }
                        : {
                            ...oldState.fluVaccinesOrderWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_FLU_VACCINES_ORDER_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    fluVaccinesOrderWizardData: {
                        ...oldState.fluVaccinesOrderWizardData,
                        entity: {
                            ...oldState.fluVaccinesOrderWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_FLU_VACCINES_ORDER,
        fieldName: 'createFluVaccinesOrder',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_FLU_VACCINES_ORDER,
        fieldName: 'updateFluVaccinesOrder',
    }),
};

const dontResetOnCompanyOrSeatSelectionActionHandlerFactory: IResetStateActionHandlerFactory<IReducerState> =
    ({ initialStateClone }) =>
        createActionHandler<IReducerState>(({ oldState }) => {
            return {
                ...initialStateClone,
                fluVaccinesConfig: oldState.fluVaccinesConfig,
            };
        });

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that order-flu-vaccines steps are not lost when refreshing
        fluVaccinesOrderWizardData: reducerState.fluVaccinesOrderWizardData,
    }),
    resetStateOnCompanySelection: dontResetOnCompanyOrSeatSelectionActionHandlerFactory,
    resetStateOnSeatSelection: dontResetOnCompanyOrSeatSelectionActionHandlerFactory,
    resetStateOnLocaleSwitch: true,
});
