import { IBffAddress } from './address';

export interface ILocation {
    name: string;
    code?: string;
    typeCode?: ILocationTypeCodes;
    address: IBffAddress;
    mainCenter?: {
        code: string;
        name: string;
    }
}

export enum ILocationTypeCodes {
    CUSTOMER = 'Customer',
    FIXED_CENTER = 'FixedCenter',
    MOBILE_CENTER = 'MobileCenter',
    TOUR = 'Tour',
    Virtual = 'Virtual',
}
