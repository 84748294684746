import React from 'react';
import { IPagePartComponentProps } from '@snipsonian/react/es/components/pageParts/pageParts.models';
import './legal-icon-text-pagepart.scss';
import WysiwygContentType from '../WysiwygContentType';

export interface ILegalIconTextPagePartData {
    icon?: {
        id: number;
        url: string; // '/uploads/media/5d31d53cb2c06/cookie-monster.svg'
    };
    title: string;
    subtitle: string;
    content: string;
}

export default function LegalIconTextPagePart(props: IPagePartComponentProps<ILegalIconTextPagePartData>) {
    const { data } = props;

    return (
        <div className="LegalIconTextPagePart">
            <hgroup>
                <p>{data.title}</p>
                <h3>{data.subtitle}</h3>
            </hgroup>

            { data.icon &&
                <figure>
                    <img src={data.icon.url} alt="" />
                </figure>
            }

            <WysiwygContentType html={data.content} />
        </div>
    );
}
