import React from 'react';
import { ITextBlockPagePartData } from '../../../../../models/general/pageParts';
import './text-block-page-part.scss';
import WysiwygContentType from '../WysiwygContentType';

interface IPublicProps {
    data: ITextBlockPagePartData;
}

export default function TextBlockPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <WysiwygContentType className="TextBlockPagePart" html={data.text} />
    );
}
