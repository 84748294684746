import { object } from 'yup';
import string from '../../../../utils/libs/yup/string';

export const fields = {
    companyCodeOrNameFilter: 'companyCodeOrNameFilter',
};

export const schema = object({
    [fields.companyCodeOrNameFilter]: string().required(),
});
