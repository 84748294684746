import React from 'react';
import { connect } from '../../..';
import './prevention-units-archive.scss';
import {
    getPreventionUnitsYears,
    getExportPreventionUnitsAsyncInfo,
} from '../../../../redux/preventionUnits/selectors';
import ListItem from '../../../common/list/ListItem';
import Icon from '../../../common/icons/Icon';
import Button from '../../../common/buttons/Button';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import { exportPreventionUnitsActions } from '../../../../redux/preventionUnits/actions';
import ErrorDialog from '../../../common/modals/ErrorDialog';
import { clearErrors as clearErrorsAction } from '../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../models/general/error';

const CLASS_NAME = 'PreventionUnitsArchive';

interface IPrivateProps {
    preventionUnitsYears: number[];
    exportAsyncInfo: IAsyncFieldInfo;
    exportPreventionUnits: (year: number) => void;
    clearErrors: (error: ITraceableApiError) => void;
}

function Archive(props: IPrivateProps) {
    const { preventionUnitsYears, exportAsyncInfo, exportPreventionUnits, clearErrors } = props;

    function renderArchiveListItem(year: number) {
        return (
            <div className={`${CLASS_NAME}__list-item`}>
                <span>{year}</span>
                <span>
                    <Button
                        id={`prevention-units-export-${year}`}
                        typeName="text"
                        onClick={() => exportPreventionUnits(year)}
                    >
                        <Icon typeName="excel" />
                        <Translate msg="administration.prevention_units.archive.export" />
                    </Button>
                </span>
            </div>
        );
    }

    return (
        <div className={CLASS_NAME}>
            <ErrorDialog
                titleTranslationKey="common.master_with_detail.export.error_during_fetch"
                asyncInfo={exportAsyncInfo}
                onCloseDialog={() => clearErrors(exportAsyncInfo.error)}
            />
            <Loader show={exportAsyncInfo.status === AsyncStatus.Busy}>
                {preventionUnitsYears.map((year) => (
                    <ListItem
                        key={year}
                        text={renderArchiveListItem(year)}
                    />
                ))}
            </Loader>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            preventionUnitsYears: getPreventionUnitsYears(state),
            exportAsyncInfo: getExportPreventionUnitsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            exportPreventionUnits: (year: number) => {
                dispatch(exportPreventionUnitsActions.trigger({ year }));
            },
            clearErrors: (error: ITraceableApiError) => {
                dispatch(clearErrorsAction([error.id]));
            },
        };
    },
})(Archive);
