import {
    FETCH_RISK_MANAGEMENT_DOCUMENTS,
    FETCH_RISK_MANAGEMENT_DOCUMENTS_FILES,
    FETCH_RISK_MANAGEMENT_DOCUMENT_FILES_IN_BULK,
} from './types';
import { createTypeActions } from '../../../utils/libs/redux/createAction';
import {
    IFetchRiskManagementDocumentsPayload,
    IRiskManagementDocument,
    IFetchRiskManagementDocumentsFilesPayload,
} from '../../../models/documentCenter/riskManagementDocuments';

// Fetch risk management documents
export const fetchRiskManagementDocumentsActions =
    createTypeActions<IFetchRiskManagementDocumentsPayload, IRiskManagementDocument[]>({
        type: FETCH_RISK_MANAGEMENT_DOCUMENTS,
    });

// Risk Management document get document and file
export const fetchRiskManagementDocumentsFilesActions =
    createTypeActions<IFetchRiskManagementDocumentsFilesPayload>({
        type: FETCH_RISK_MANAGEMENT_DOCUMENTS_FILES,
    });

export const fetchRiskManagementDocumentFilesInBulkActions =
    createTypeActions<IFetchRiskManagementDocumentsFilesPayload>({
        type: FETCH_RISK_MANAGEMENT_DOCUMENT_FILES_IN_BULK,
    });
