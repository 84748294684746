import React from 'react';
import { IRenderDetailHeaderProps } from '../../../../common/widget/MasterWithDetail/typings';
import { IUserAccount } from '../../../../../models/user/userAccount';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';

export default function DetailHeader({ detailData }: IRenderDetailHeaderProps<IUserAccount>) {
    if (!detailData) {
        return null;
    }

    const { firstName, name } = detailData;
    return (
        <h1>{formatPersonName({ firstName, name })}</h1>
    );
}
