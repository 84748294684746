export const FLU_VACCINES_MIN_AMOUNT_TO_ORDER = 1;

interface IDeliveryExtraFieldKeys {
    medicalCenter: boolean;
    companyAddress: boolean;
}

export interface IDeliveryOption {
    label: string;
    detailLabel: string;
    extraFields: {
        [key in keyof IDeliveryExtraFieldKeys]: boolean;
    };
    vaccinationLocationId: number;
    isTravelCostCharged: boolean;
    minAmountOfVaccines?: number;
}

export enum VaccinationLocationId {
    MedicalExamination = 1837284,
    MedicalCenter = 1837286,
    Company = 1837285,
}

export const DELIVERY_OPTIONS: IDeliveryOption[] = [
    {
        label: 'interventions.flu_orders_new.steps.delivery.options.with_medical_examination',
        detailLabel: 'interventions.flu_orders_detail.edit_location.delivery_options.with_medical_examination',
        extraFields: {
            medicalCenter: false,
            companyAddress: true,
        },
        vaccinationLocationId: VaccinationLocationId.MedicalExamination,
        isTravelCostCharged: false,
    },
    {
        label: 'interventions.flu_orders_new.steps.delivery.options.medical_center',
        detailLabel: 'interventions.flu_orders_detail.edit_location.delivery_options.medical_center',
        extraFields: {
            medicalCenter: true,
            companyAddress: false,
        },
        vaccinationLocationId: VaccinationLocationId.MedicalCenter,
        isTravelCostCharged: false,
    },
    {
        label: 'interventions.flu_orders_new.steps.delivery.options.company',
        detailLabel: 'interventions.flu_orders_detail.edit_location.delivery_options.company',
        extraFields: {
            medicalCenter: false,
            companyAddress: true,
        },
        vaccinationLocationId: VaccinationLocationId.Company,
        isTravelCostCharged: true,
        minAmountOfVaccines: 20,
    },
];

export enum PolicyAdvisePriorityId {
    Low = 2291850,
    Medium = 2291839,
    High = 2291825,
}

export enum PolicyAdviceStatus {
    Open = '0',
    Resolved = '1',
}

export function getDeliveryOptionByLocationId(vaccinationLocationId) {
    if (!vaccinationLocationId) {
        return null;
    }

    return DELIVERY_OPTIONS
        .find((option) => option.vaccinationLocationId === parseInt(vaccinationLocationId, 10));
}

export function isMedicalCenterRequiredForDeliveryOption(deliveryOption: IDeliveryOption): boolean {
    if (!deliveryOption) {
        return false;
    }

    return deliveryOption.extraFields.medicalCenter;
}

export function isCompanyAddressRequiredForDeliveryOption(deliveryOption: IDeliveryOption): boolean {
    if (!deliveryOption) {
        return false;
    }

    return deliveryOption.extraFields.companyAddress;
}

export function isMedicalCenterRequired(vaccinationLocationId: number): boolean {
    return isMedicalCenterRequiredForDeliveryOption(
        getDeliveryOptionByLocationId(vaccinationLocationId),
    );
}

export function isCompanyAddressRequired(vaccinationLocationId: number): boolean {
    return isCompanyAddressRequiredForDeliveryOption(
        getDeliveryOptionByLocationId(vaccinationLocationId),
    );
}

export function isTravelCostCharged(vaccinationLocationId: number): boolean {
    const deliveryOption = getDeliveryOptionByLocationId(vaccinationLocationId);
    if (!deliveryOption) {
        return false;
    }
    return deliveryOption.isTravelCostCharged;
}

export function areEnoughVaccinesOrderedToEnableDeliveryOption(
    deliveryOption: IDeliveryOption,
    amountVaccines: number,
): boolean {
    if (!deliveryOption) {
        return false;
    }

    if (!deliveryOption.minAmountOfVaccines) {
        return true;
    }

    if (typeof amountVaccines === 'string') {
        return parseFloat(amountVaccines) >= deliveryOption.minAmountOfVaccines;
    }
    return amountVaccines >= deliveryOption.minAmountOfVaccines;
}
