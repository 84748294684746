import { object } from 'yup';
import number from '../../../../../utils/libs/yup/number';
import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    titleId: 'titleId',
    firstName: 'firstName',
    name: 'name',
    languageId: 'languageId',
    newEmail: 'newEmail',
    phoneNumber: 'phoneNumber',
    mobilePhoneNumber: 'mobilePhoneNumber',
    sexId: 'sexId',
};

export const schema = object({
    [fields.titleId]: number(),
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.languageId]: number().required(),
    [fields.newEmail]: string().email().required(),
    [fields.phoneNumber]: phoneNumber(),
    [fields.mobilePhoneNumber]: phoneNumber(),
    [fields.sexId]: number(),
});
