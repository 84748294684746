import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, TDropdownItemValue } from '../Dropdown';
import Translate from '../../Translate';

interface IComponentProps {
    id: string;
    value: TDropdownItemValue;
    options: {
        label: string,
        value: TDropdownItemValue,
    }[];
    onChange: (selectedValue: TDropdownItemValue) => void;
    typeName?: 'primary' | 'secondary';
    size?: 'small';
    className?: string;
    disabled?: boolean;
}

interface IComponentState {
    isOpen: boolean;
}

// export default class Select<Value extends keyof TDropdownItemValue>
export default class Select extends PureComponent<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
    }

    public render() {
        const { id, options, value, typeName, size, className, disabled } = this.props;
        const { isOpen } = this.state;

        return (
            <Dropdown
                id={id}
                isOpen={isOpen}
                onOpenIntent={() => this.setState({ isOpen: true })}
                onCloseIntent={() => this.setState({ isOpen: false })}
                onItemSelected={this.onItemSelected}
                selectedItem={value}
                defaultLabel={<Translate msg="common.select.default_label" />}
                typeName={typeName}
                size={size}
                className={className}
                disabled={disabled}
            >
                {options.map((option) => (
                    <DropdownItem key={`select-option-${option.value}`} value={option.value}>
                        <Translate msg={option.label} />
                    </DropdownItem>
                ))}
            </Dropdown>
        );
    }

    private onItemSelected(selectedValue: TDropdownItemValue) {
        this.props.onChange(selectedValue);
    }
}
