import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';
import { getRoutePayload } from '../../location/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Executed interventions
export const getPrestationCodeQueryParam = (state: IState) =>
    getRoutePayload<{ prestationCode: string }>(state).prestationCode;

const getExecutedInterventionsAsyncField = (state: IState) =>
    reducerState(state).executedInterventions;

export const getExecutedInterventionsAsyncInfo = makeAsyncFetchInfoSelector(getExecutedInterventionsAsyncField);

export const getExecutedInterventions = (state: IState) =>
    getExecutedInterventionsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areExecutedInterventionsAvailable = (state: IState) => !!reducerState(state).executedInterventions.data;
