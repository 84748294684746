import {
    FETCH_TOP_TASKS, UPDATE_TOP_TASKS, FETCH_WEB_APP_MESSAGES,
    FETCH_COMPANY_DETAIL_BEFORE_NAVIGATING, FETCH_COMPANY_SITUATION_HISTORY_BEFORE_NAVIGATING,
} from './types';
import { createSuccessAction, createFailAction } from '../index';
import { ITraceableApiError } from '../../models/general/error';
import { IUpdateTopTasksPayload } from '../../models/user/topTasks';
import { createAction, createTypeActions } from '../../utils/libs/redux/createAction';
import { ICmsWebAppMessages } from '../../models/general/webAppMessages';
import {
    IFetchCompanyDetailBeforeNavigatingPayload,
    IFetchCompanySituationHistoryBeforeNavigatingPayload,
} from '../../models/general/navigation';

export function fetchTopTasksSucceeded(payload: string[]) {
    return createSuccessAction<string[]>(FETCH_TOP_TASKS, payload);
}

export function fetchTopTasksFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_TOP_TASKS, error);
}

export function updateTopTasks(payload: IUpdateTopTasksPayload) {
    return createAction<IUpdateTopTasksPayload>(UPDATE_TOP_TASKS, payload);
}

export function updateTopTasksSucceeded(payload: IUpdateTopTasksPayload) {
    return createSuccessAction<IUpdateTopTasksPayload>(UPDATE_TOP_TASKS, payload);
}

export function updateTopTasksFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(UPDATE_TOP_TASKS, error);
}

// Web app messages
export const fetchWebAppMessagesActions = createTypeActions<{}, ICmsWebAppMessages>(
    { type: FETCH_WEB_APP_MESSAGES },
);

// Fetch company detail before navigating
export function fetchCompanyDetailBeforeNavigating(
    payload: IFetchCompanyDetailBeforeNavigatingPayload,
) {
    return createAction<IFetchCompanyDetailBeforeNavigatingPayload>(
        FETCH_COMPANY_DETAIL_BEFORE_NAVIGATING,
        payload,
    );
}

// Fetch company situation history before navigating
export function fetchCompanySituationHistoryBeforeNavigating(
    payload: IFetchCompanySituationHistoryBeforeNavigatingPayload,
) {
    return createAction<IFetchCompanySituationHistoryBeforeNavigatingPayload>(
        FETCH_COMPANY_SITUATION_HISTORY_BEFORE_NAVIGATING,
        payload,
    );
}
