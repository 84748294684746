import React, { Component } from 'react';
import './onboarding-wizard-seats.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import { IAddress, IAddressSave } from '../../../../../models/general/address';
import { AdvancedPropertyInput, TextPropertyInput } from '../../../../common/input/PropertyInput';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import Translate from '../../../../common/Translate';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { ICompanySeat } from '../../../../../models/admin/company';
import { IRenderOnboardingSeatsDetailContentProps } from '.';
import { schema } from './onBoardingSeatsSchema';
import { connect } from '../../../..';
import { getUpdateCompanyAsyncInfo } from '../../../../../redux/company/info/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { updateCompanyActions } from '../../../../../redux/company/info/actions';
import Alert from '../../../../common/widget/Alert';
import Loader from '../../../../common/waiting/Loader';
import FormFieldError from '../../../../common/forms/FormFieldError';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

const CLASS_NAME = 'OnboardingWizardSeatsDetail';
const FORM_NAME = 'onboarding-wizard-seats-detail';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.seats.detail';

interface IDetailContentProps {
    selectedCompanySeat: ICompanySeat;
}

interface IPrivateProps {
    updateCompany: (values: FormValues) => void;
    updateCompanyAsyncInfo: IAsyncFieldInfo;
    resetUpdateCompany: () => void;
}

export interface FormValues {
    name: string;
    companyCode: string;
    address: IAddress;
}

type TDetailProps = IDetailContentProps & IRenderOnboardingSeatsDetailContentProps & IPrivateProps;

class DetailContent extends Component<TDetailProps> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props: TDetailProps) {
        super(props);

        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.updateCompanyInfoSuccess = this.updateCompanyInfoSuccess.bind(this);
        this.changedSelectedSeat = this.changedSelectedSeat.bind(this);
    }

    public componentDidUpdate(prevProps: TDetailProps) {
        const { selectedCompanySeat, resetUpdateCompany } = this.props;
        const changedSeat = this.changedSelectedSeat(prevProps);
        const updateCompanySuccess = this.updateCompanyInfoSuccess(prevProps);

        if (updateCompanySuccess) {
            if (this.formRenderProps) {
                this.formRenderProps.resetForm(this.formRenderProps.values);
            }
        }

        if (changedSeat) {
            if (this.formRenderProps) {
                this.formRenderProps.resetForm({
                    address: selectedCompanySeat.address,
                    companyCode: selectedCompanySeat.company.companyCode,
                    name: selectedCompanySeat.company.name,
                });
            }
            resetUpdateCompany();
        }
    }

    public render() {
        const { selectedCompanySeat, onOpenOverlay, updateCompany, updateCompanyAsyncInfo } = this.props;

        if (!selectedCompanySeat) {
            return null;
        }

        const INITIAL_VALUES: FormValues = {
            address: selectedCompanySeat.address,
            companyCode: selectedCompanySeat.company.companyCode,
            name: selectedCompanySeat.company.name,
        };

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <div className={CLASS_NAME}>
                        <h1>{selectedCompanySeat.company.name}</h1>
                        {updateCompanyAsyncInfo.status === AsyncStatus.Success && (
                            <Alert type="success">
                                <Translate msg={`${TRANSLATION_PREFIX}.edit_seat_success`} />
                            </Alert>
                        )}
                        <Form
                            name={FORM_NAME}
                            handleSubmit={updateCompany}
                            initialValues={INITIAL_VALUES}
                            schema={schema}
                            render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                this.formRenderProps = formRenderProps;

                                const {
                                    values, handleChange, errors, touched,
                                } = formRenderProps;

                                return (
                                    <div className={`${CLASS_NAME}__form`}>
                                        <Loader show={updateCompanyAsyncInfo.status}>
                                            <h2>
                                                <Translate msg={`${TRANSLATION_PREFIX}.title`} />
                                            </h2>
                                            <TextPropertyInput
                                                id="name"
                                                name="name"
                                                labelKey={`${TRANSLATION_PREFIX}.form.placeholder.name`}
                                                readonly={false}
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={touched.name && !!errors.name}
                                            >
                                                {touched.name &&
                                                    <FormFieldError
                                                        error={errors.name}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                `${TRANSLATION_PREFIX}.form.placeholder.name`,
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </TextPropertyInput>
                                            <TextPropertyInput
                                                id="companyCode"
                                                name="companyCode"
                                                labelKey={`${TRANSLATION_PREFIX}.form.placeholder.company_code`}
                                                readonly={true}
                                                value={values.companyCode}
                                                onChange={handleChange}
                                                isInvalid={touched.companyCode && !!errors.companyCode}
                                            >
                                                {touched.companyCode &&
                                                    <FormFieldError
                                                        error={errors.companyCode}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                `${TRANSLATION_PREFIX}.form.placeholder.company_code`,
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </TextPropertyInput>
                                            <AdvancedPropertyInput
                                                labelKey={`${TRANSLATION_PREFIX}.form.placeholder.address`}
                                                value={formatAddress(values.address)}
                                                readonly={false}
                                                onClick={() => onOpenOverlay(
                                                    this.handleAddressChange,
                                                    values,
                                                )}
                                                isInvalid={touched.address && !!errors.address}
                                            >
                                                {touched.address &&
                                                    <FormFieldError
                                                        error={errors.address}
                                                        placeholders={{
                                                            fieldName: translator(
                                                                `${TRANSLATION_PREFIX}.form.placeholder.address`,
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </AdvancedPropertyInput>
                                            <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                                <SubmitButton
                                                    id="onboarding-wizard-seats-detail-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    <Translate
                                                        msg={`${TRANSLATION_PREFIX}.form.submit`}
                                                    />
                                                </SubmitButton>
                                            </div>
                                        </Loader>
                                    </div>
                                );
                            }}
                        />
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private handleAddressChange(address: IAddressSave & { countryCode: string }) {
        if (this.formRenderProps) {
            this.formRenderProps.setFieldValue('address', address);
        }
    }

    private updateCompanyInfoSuccess(prevProps: TDetailProps) {
        const { updateCompanyAsyncInfo } = this.props;
        return prevProps.updateCompanyAsyncInfo.status === AsyncStatus.Busy &&
            updateCompanyAsyncInfo.status === AsyncStatus.Success;
    }

    private changedSelectedSeat(prevProps: TDetailProps) {
        const { selectedCompanySeat } = this.props;
        return selectedCompanySeat &&
            (prevProps.selectedCompanySeat !== selectedCompanySeat);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            updateCompanyAsyncInfo: getUpdateCompanyAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateCompany: (values: FormValues) => {
                dispatch(updateCompanyActions.trigger({
                    companyCode: values.companyCode,
                    companyData: {
                        address: values.address,
                        name: values.name,
                    },
                }));
            },
            resetUpdateCompany: () => {
                dispatch(updateCompanyActions.reset({}));
            },
        };
    },
})(DetailContent);
