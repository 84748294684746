/**
 * Extracts the hour and minute from an input string of the format '12:00'.
 */
export default function extractHourAndMinute(time: string) {
    if (!time) {
        return null;
    }

    const parts = time.split(':');

    if (parts.length < 2) {
        return null;
    }

    return {
        hour: parseInt(parts[0], 10),
        minute: parseInt(parts[1], 10),
    };
}
