import { path } from 'ramda';

import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { get } from '../../../../utils/api/requestWrapper';
import {
    IEmployeeExecutedAndNextMedicalExaminationsApiResponse,
    IEmployeeNextMedicalExamination,
    IFetchEmployeePlannedMedicalExaminationsApiPayload,
    TFetchEmployeeExecutedAndNextMedicalExaminationsApiPayload,
    TEmployeePlannedMedicalExaminationsApiResponse,
    IEmployeeExecutedMedicalExamination,
} from '../../../../models/admin/employee/medical-examination';
import { URL, BFF_URL } from '../emloyee.const';

export function fetchEmployeePlannedMedicalExaminations({
    companyCode,
    id,
    locale,
}: IFetchEmployeePlannedMedicalExaminationsApiPayload) {
    return get<TEmployeePlannedMedicalExaminationsApiResponse>({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.EMPLOYEE_PLANNED_MEDICAL_EXAMINATIONS,
        pathParams: {
            employeeId: id,
            organizationalUnitCode: companyCode,
        },
        mapResponse: (response: TEmployeePlannedMedicalExaminationsApiResponse) => {
            // The BFF returns status 204 when no medical examinations were found.
            // When this happens, the response is null. To prevent the call from timing out,
            // we return an empty array
            if (!response) {
                return [];
            }

            return response;
        },
    });
}

export function fetchEmployeeExectutedAndNextMedicalExaminations({
    id,
}: TFetchEmployeeExecutedAndNextMedicalExaminationsApiPayload) {
    return get<IEmployeeExecutedAndNextMedicalExaminationsApiResponse>({
        url: URL.EMPLOYEE_EXECUTED_AND_NEXT_MEDICAL_EXAMINATIONS,
        pathParams: {
            id,
        },
        mapResponse: (
            response: IEmployeeExecutedAndNextMedicalExaminationsApiResponse,
        ): IEmployeeExecutedAndNextMedicalExaminationsApiResponse => {
            const executed = path<IEmployeeExecutedMedicalExamination[]>(
                ['executed'],
                response,
            );
            const next = path<IEmployeeNextMedicalExamination>(['next'], response);

            return {
                ...executed && {
                    executed,
                },
                ...next && {
                    next,
                },
            };
        },
    });
}
