import React from 'react';
import './error-dialog.scss';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import Dialog from '../Dialog';
import Button from '../../buttons/Button';
import Translate, { TPlaceholders } from '../../Translate';
import FormError from '../../forms/FormError';
import { connect } from '../../..';
import { ITraceableApiError } from '../../../../models/general/error';
import { clearErrors } from '../../../../utils/libs/redux/generic/actions';

const CLASS_NAME = 'ErrorDialog';

interface IPublicProps {
    asyncInfo?: IAsyncFieldInfo;
    titleTranslationKey: string;
    titlePlaceholders?: TPlaceholders;
    infoTranslationKey?: string; // not shown when real error message is shown
    confirmButtonTranslationKey?: string;
    hideRealErrorMessage?: boolean;
    realErrorMessageWhiteList?: string[]; // only used when hideRealErrorMessage is true
    onCloseDialog?: () => void;
    showOverride?: boolean;
    clearAsyncInfoErrorOnClose?: boolean;
    hideCloseIcon?: boolean;
}

interface IPrivateProps {
    clearError: (error: ITraceableApiError) => void;
}

function ErrorDialog(props: IPublicProps & IPrivateProps) {
    const {
        asyncInfo,
        titleTranslationKey,
        infoTranslationKey,
        hideRealErrorMessage,
        realErrorMessageWhiteList,
        onCloseDialog,
        showOverride,
        titlePlaceholders,
        clearError,
        clearAsyncInfoErrorOnClose,
        hideCloseIcon,
    } = props;

    const confirmButtonTranslationKey = props.confirmButtonTranslationKey || 'common.error_dialog.confirm';
    const showRealErrorMessage = shouldShowRealErrorMessage();
    const showInfoTranslationKey = !showRealErrorMessage && infoTranslationKey;

    function shouldShowRealErrorMessage() {
        if (!hideRealErrorMessage) {
            return true;
        }

        if (!asyncInfo || !asyncInfo.error) {
            return false;
        }

        if (realErrorMessageWhiteList && realErrorMessageWhiteList.length > 0) {
            return realErrorMessageWhiteList.includes(asyncInfo.error.message);
        }

        return false;
    }

    function onCloseHandler() {
        if (asyncInfo && asyncInfo.error && clearAsyncInfoErrorOnClose) {
            clearError(asyncInfo.error);
        }
        if (typeof onCloseDialog === 'function') {
            onCloseDialog();
        }
    }

    return (
        <Dialog
            className={CLASS_NAME}
            show={showOverride || (asyncInfo ? asyncInfo.status === AsyncStatus.Error : false)}
            onCloseIntent={onCloseHandler}
            header={titleTranslationKey}
            headerPlaceholders={titlePlaceholders}
            type="error"
            disableClosing={hideCloseIcon}
        >
            {showInfoTranslationKey &&
                <p>
                    <Translate msg={infoTranslationKey} />
                </p>
            }
            {showRealErrorMessage &&
                <div>
                    <FormError error={asyncInfo.error} />
                </div>
            }
            <div className={`${CLASS_NAME}__actions`}>
                <Button
                    id="error-dialog-confirm"
                    typeName="secondary"
                    onClick={onCloseHandler}
                >
                    <Translate msg={confirmButtonTranslationKey} />
                </Button>
            </div>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => {
        return {
            clearError: (error: ITraceableApiError) => {
                dispatch(clearErrors([error.id]));
            },
        };
    },
})(ErrorDialog);
