import {
    FETCH_UNREAD_MESSAGES_COUNT,
    FETCH_USER_MESSAGES,
    FETCH_USER_MESSAGE_DETAILS,
    MARK_MESSAGE_AS_READ,
    DELETE_USER_MESSAGE,
    UPDATE_USER_MESSAGE_TYPES,
    FETCH_USER_MESSAGE_TYPES,
} from './types';
import { createAction, createSuccessAction, createFailAction } from '../index';
import {
    IUnreadMessagesCount,
    IUserMessage,
    IUserMessageDetails,
    IFetchUserMessageDetailPayload,
    IMarkMessageAsReadStatus,
    IMarkMessageAsReadStatusFailedPayload,
    IDeleteUserMessagePayload,
    IUpdateUserMessageTypesPayload,
    IMessageType,
} from '../../models/user/inbox';
import { ITraceableApiError } from '../../models/general/error';
import { createTypeActions } from '../../utils/libs/redux/createAction';

export function fetchUnreadMessagesCount() {
    return createAction<{}>(FETCH_UNREAD_MESSAGES_COUNT, {});
}

export function fetchUnreadMessagesCountSucceeded(payload: IUnreadMessagesCount) {
    return createSuccessAction<IUnreadMessagesCount>(FETCH_UNREAD_MESSAGES_COUNT, payload);
}

export function fetchUnreadMessagesCountFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_UNREAD_MESSAGES_COUNT, error);
}

// Fetch user messages
export const fetchUserMessagesActions = createTypeActions<{}, IUserMessage[]>({
    type: FETCH_USER_MESSAGES,
});

// Fetch user message detail
export const fetchUserMessageDetailActions = createTypeActions<IFetchUserMessageDetailPayload, IUserMessageDetails>({
    type: FETCH_USER_MESSAGE_DETAILS,
});

// Mark message as read
export const markMessageAsReadActions =
    createTypeActions<IMarkMessageAsReadStatus, IMarkMessageAsReadStatus, IMarkMessageAsReadStatusFailedPayload>({
        type: MARK_MESSAGE_AS_READ,
    });

// Mark message as read
export const deleteUserMessageActions =
    createTypeActions<IDeleteUserMessagePayload, IDeleteUserMessagePayload>({
        type: DELETE_USER_MESSAGE,
    });

// Update user message types
export const updateUserMessageTypesActions =
    createTypeActions<IUpdateUserMessageTypesPayload, IUpdateUserMessageTypesPayload>({
        type: UPDATE_USER_MESSAGE_TYPES,
    });

// Fetch user message types
export const fetchUserMessageTypesActions = createTypeActions<{}, IMessageType[]>({
    type: FETCH_USER_MESSAGE_TYPES,
});
