import { get } from '../../utils/api/requestWrapper';
import { ICompanyCodePayload } from '../../models/admin/company';
import { IBaseContactPerson } from '../../models/admin/companyInfo';

export const URL = {
    MENSURA_CONTACTS: '/companies/{companyCode}/contacts-mensura',
};

export interface IMensuraContactsResponse {
    contacts: {
        [companyName: string]: IBaseContactPerson[];
    };
}

export function fetchMensuraContacts({ companyCode }: ICompanyCodePayload) {
    return get<IMensuraContactsResponse>({
        url: URL.MENSURA_CONTACTS,
        pathParams: {
            companyCode,
        },
    });
}
