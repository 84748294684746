import React, { PureComponent } from 'react';
import './breadcrumbs.scss';
import classNames from 'classnames';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Translate from '../../common/Translate';
import ROUTE_KEYS from '../../../routeKeys';
import { connect } from '../../index';
import { getBreadcrumbsPath } from '../../../redux/location/selectors';
import { IBreadcrumbsPath } from '../../../utils/routing/typings';
import * as screenSizeUtils from '../../../utils/dom/screenSize';
import debounce, { TDebounced } from '../../../utils/core/debounce';
import GoBack from '../../common/navigation/GoBack';

interface IPrivateProps {
    breadcrumbsPath: IBreadcrumbsPath;
    executeTranslationPlaceholdersSelector: (selector: Function) => { [key: string]: string };
}

interface IBreadcrumbsProps {
    /**
     * Used when the breadcrumbs are displayed on a dark background
     */
    inverse?: boolean;
}

interface IState {
    isExtraSmallScreen: boolean;
}

const CLASS_NAME = 'Breadcrumbs';

export class Breadcrumbs extends PureComponent<IBreadcrumbsProps & IPrivateProps, IState> {
    private onResizeDebounced: TDebounced;

    constructor(props) {
        super(props);

        this.state = {
            isExtraSmallScreen: screenSizeUtils.isExtraSmallScreen(),
        };

        this.onResizeDebounced = debounce(() => this.onWindowResize(), 100);
    }

    public render() {
        const { breadcrumbsPath, inverse, executeTranslationPlaceholdersSelector } = this.props;

        const classes = classNames(CLASS_NAME, {
            // TODO: inverse styles (eg when background is green)
            [`${CLASS_NAME}--inverse`]: inverse,
        });

        if (this.state.isExtraSmallScreen) {
            const { to, label } = this.getParentLocation();
            const parentTranslationKey = (label && label.translationKey)
                || 'app_shell.bread_crumbs.parent_link_on_small_screen';

            return (
                <nav className={classes}>
                    <GoBack
                        id="breadcrumbs-link-back"
                        to={to}
                        iconTypeName="arrow-left"
                        labelTranslationKey={parentTranslationKey}
                    />
                </nav>
            );
        }

        return (
            <nav className={classes}>
                {
                    breadcrumbsPath.map((item) => {
                        const placeholders = item.label && item.label.translationPlaceholdersSelector &&
                            executeTranslationPlaceholdersSelector(item.label.translationPlaceholdersSelector);
                        const label = item.label ?
                            <Translate
                                msg={item.label.translationKey}
                                placeholders={placeholders}
                            /> :
                            item.type;

                        const key = `breadcrumbs-link-${item.type.toLowerCase()}`;

                        if (item.isCurrent) {
                            return (
                                <span key={key} className="item current">
                                    {label}
                                </span>
                            );
                        }

                        const to = item.payload ? {
                            type: ROUTE_KEYS[item.type],
                            payload: item.payload,
                        } : item.type;

                        return (
                            <LinkToRoute
                                id={key}
                                className="item"
                                to={to}
                                key={key}
                            >
                                {label}
                            </LinkToRoute>
                        );
                    })
                }
            </nav >
        );
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResizeDebounced);
    }

    public componentWillUnmount() {
        this.onResizeDebounced.cancel();
        window.removeEventListener('resize', this.onResizeDebounced);
    }

    private onWindowResize() {
        const isExtraSmallScreen = screenSizeUtils.isExtraSmallScreen();
        if (this.state.isExtraSmallScreen !== isExtraSmallScreen) {
            this.setState({
                isExtraSmallScreen,
            });
        }
    }

    private getParentLocation() {
        const { breadcrumbsPath } = this.props;
        const item = breadcrumbsPath.filter((item) => !item.isCurrent).reverse()[0];
        const to = item.payload ? {
            type: ROUTE_KEYS[item.type],
            payload: item.payload,
        } : item.type;
        return {
            to,
            label: item.label,
        };
    }
}

export default connect<IPrivateProps, IBreadcrumbsProps>({
    stateProps: (state) => ({
        breadcrumbsPath: getBreadcrumbsPath(state),
        executeTranslationPlaceholdersSelector: (selector) => selector(state),
    }),
})(Breadcrumbs);
