import React, { PureComponent, MouseEvent } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import { IAddPersonalRiskPayload } from '../../../../../models/admin/employee';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee, getAddEmployeePersonalRiskAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import Translate from '../../../../common/Translate';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import { schema, fields } from './addPersonalRiskSchema';
import Loader from '../../../../common/waiting/Loader';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import FormError from '../../../../common/forms/FormError';
import Button from '../../../../common/buttons/Button';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead/index';
import { ConstantType } from '../../../../../models/general/constants';
import { addEmployeePersonalRisk } from '../../../../../redux/employee/info/actions';
import { removeEmptyPropsFromObject } from '../../../../../utils/core/object/objectProps';
import { formatCurrentDateForBackend } from '../../../../../utils/formatting/formatDate';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';

type TValues = Pick<IAddPersonalRiskPayload, 'riskId'>;

interface IPrivateProps {
    isDetailsLoaded: boolean;
    translator: ITranslator;
    employeeName: string;
    addPersonalRiskAsyncInfo: IAsyncFieldInfo;
    onAddPersonalRisk: (values: TValues) => void;
    clearError: (error: ITraceableApiError) => void;
}

interface IAddPersonalRiskProps {
    onClose: () => void;
}

const FORM_NAME = 'add-personal-risk-form';
const CLASS_NAME = 'AddPersonalRisk';

class AddPersonalRisk extends PureComponent<IPrivateProps & IAddPersonalRiskProps> {

    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, isDetailsLoaded, employeeName,
            onAddPersonalRisk, addPersonalRiskAsyncInfo,
        } = this.props;

        if (!isDetailsLoaded) {
            return null;
        }

        const initialValues: TValues = {
            riskId: null,
        };

        const riskIdPlaceholder = translator('administration.employees.add_personal_risk.form.risk_id');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.add_personal_risk.title'}
                            placeholders={{ name: employeeName }}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={onAddPersonalRisk}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue, handleChange,
                    }: IFormRenderProps<TValues>) => {
                        return (
                            <>
                                <Loader
                                    show={
                                        addPersonalRiskAsyncInfo.status === AsyncStatus.Busy
                                    }
                                    showImmediatelly={true}
                                />
                                <FloatableTextInputWrapper>
                                    <ConstantsTypeahead
                                        id="add-personal-risk-risk-id"
                                        constantType={ConstantType.RISKS}
                                        name={fields.riskId}
                                        placeholder={riskIdPlaceholder}
                                        value={values.riskId}
                                        onItemSelected={(value) => setFieldValue('riskId', value)}
                                        isInvalid={touched.riskId && !!errors.riskId}
                                    >
                                        <label htmlFor="add-personal-risks-risk-id">
                                            <Translate
                                                msg={riskIdPlaceholder}
                                            />
                                        </label>
                                    </ConstantsTypeahead>
                                </FloatableTextInputWrapper>
                                <FormError error={addPersonalRiskAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="add-personal-risk-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate msg="administration.employees.add_personal_risk.form.cancel" />
                                    </Button>
                                    <SubmitButton
                                        id="add-personal-risk-submit-button"
                                        formName={FORM_NAME}
                                    >
                                        <Translate msg="administration.employees.add_personal_risk.form.submit" />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.addPersonalRiskAsyncInfo.status === AsyncStatus.Success &&
            prevProps.addPersonalRiskAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onClose();
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, addPersonalRiskAsyncInfo } = this.props;
        clearError(addPersonalRiskAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IAddPersonalRiskProps>({
    stateProps: (state) => {
        const selectedEmployee = getSelectedEmployee(state);
        const addPersonalRiskAsyncInfo = getAddEmployeePersonalRiskAsyncInfo(state);
        return {
            isDetailsLoaded: !!selectedEmployee,
            translator: getTranslatorDeprecated(state),
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            addPersonalRiskAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onAddPersonalRisk: (values) => {
                const selectedEmployee = getSelectedEmployee(getState());
                dispatch(addEmployeePersonalRisk({
                    employee: selectedEmployee,
                    dateActive: formatCurrentDateForBackend(),
                    ...removeEmptyPropsFromObject(values),
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(AddPersonalRisk);
