import { formatDateInLongFormatWithDividers, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { IMappedToAdministerVaccination } from '../../../../models/interventions/vaccines';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IClientSideFilterOfListDataProps,
    IShouldRenderShowAllButtonProps,
} from '../../../common/widget/MasterWithDetail/typings';
import { ListItem } from '../../../../models/general/list';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';

import {
    TToAdministerVaccinationListItem,
    IToAdministerVaccinationsListColumnNames,
} from './ToAdministerVaccinationsList/ToAdministerVaccinationList.types';
import {
    IFilterValues,
    TToAdministerVaccinationsListActiveFiltersProps,
} from './ToAdministerVaccinationsFilters/ToAdministerVaccinationsFilters.type';
import { DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS, DEFAULT_NR_OF_VACCINES_TO_SHOW } from './to-administer.const';

export function mapToAdministerVaccinationsToListItems(
    masterData: IMappedToAdministerVaccination[],
): TToAdministerVaccinationListItem[] {
    return masterData
        .map((vaccination) => ({
            id: toListId(vaccination),
            columns: {
                name: vaccination.name,
                employer: vaccination.employer,
                dateToAdminister: formatDateInLongFormatWithDividers(vaccination.dateToAdminister),
                dateToAdministerSort: vaccination.dateToAdminister,
                description: vaccination.description,
                injectionDescription: vaccination.injectionDescription,
                employeeCode: vaccination.employeeCode,
                actions: null,
            },
        }));
}

export const toListId = (vaccination: IMappedToAdministerVaccination): string => {
    return vaccination.name
        + vaccination.dateToAdminister
        + vaccination.injectionDescription
        + vaccination.description
        + vaccination.employer;
};

export const shouldRenderShowAllButton = (
    shouldRenderProps: IShouldRenderShowAllButtonProps<
        ListItem<IToAdministerVaccinationsListColumnNames>[], IFilterValues
    >,
) => {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_VACCINES_TO_SHOW;
};

export function getDefaultQueryParams({ isShowAll = true }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS;
}

export const mapListRowForExport = (administeredVaccination: IMappedToAdministerVaccination) => {
    const {
        companyCode,
        dateToAdminister,
        description,
        employer,
        firstName,
        injectionDescription,
        lastName,
    } = administeredVaccination;

    return {
        companyCode,
        employer,
        firstName,
        lastName,
        dateToAdminister,
        description,
        injectionDescription,
    };
};

export const transformFilterValuesToActiveFilters = (
    transformProps: TToAdministerVaccinationsListActiveFiltersProps,
) => {
    return createGenericActiveFilters<IFilterValues, IToAdministerVaccinationsListColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.vaccinations.to_administer.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS.startDate,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS.endDate,
            },
            employer: {
                show: true,
                translationKeySuffixOverride: 'employer',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'employer',
                        searchColumnName: 'employer',
                    },
                },
            },
            type: {
                show: true,
                translationKeySuffixOverride: 'type',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'description',
                        searchColumnName: 'description',
                    },
                },
            },
        },
    });
};

export function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IToAdministerVaccinationsListColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.employer)) {
                return true;
            }

            const employers = separateStringList(filterValues.employer);

            return employers.includes(listItem.columns.employer as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.type)) {
                return true;
            }

            const descriptions = separateStringList(filterValues.type);

            return descriptions.includes(listItem.columns.description as string);
        });
}
