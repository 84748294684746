import { object } from 'yup';
import { IAddCompanyFunctionPayload } from '../../../../../models/admin/companyFunctions';
import isNotSetOrEmptyString from '../../../../../utils/core/string/isNotSetOrEmptyString';
import { ErrorTypes } from '../../../../../models/general/error';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    descriptionFR: 'descriptionFR',
    descriptionNL: 'descriptionNL',
} as IAddCompanyFunctionPayload;

export const schema = object({
    [fields.descriptionFR]: string(),
    [fields.descriptionNL]: string()
    .when(fields.descriptionFR, {
        is: isNotSetOrEmptyString,
        then: string().test(
            ErrorTypes.Custom,
            'account.add_function.form.fields.error.either_nl_or_fr',
            (value) => !isNotSetOrEmptyString(value),
        ),
    }),
});
