import { object, boolean } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../models/ui/form';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    healthSurveillance: 'healthSurveillance',
    safetyFunction: 'safetyFunction',
    increasedVigilanceFunction: 'increasedVigilanceFunction',
    chemical: 'chemical',
    checkNoise: 'checkNoise',
    noise: 'noise',
    checkTemperature: 'checkTemperature',
    temperature: 'temperature',
    checkIonizing: 'checkIonizing',
    ionizing: 'ionizing',
    checkOtherPhysicalResources: 'checkOtherPhysicalResources',
    otherPhysicalResources: 'otherPhysicalResources',
    biologicalResources: 'biologicalResources',
    vaccinations: 'vaccinations',
    checkWorkStationLoad: 'checkWorkPostLoad',
    workStationLoad: 'workPostLoad',
    checkLiftingLoad: 'checkLiftingLoad',
    liftingLoad: 'liftingLoad',
    checkOthersLoad: 'checkOthersLoad',
    othersLoad: 'othersLoad',
    nightOrShiftWork: 'nightOrShiftWork',
    nightOrShiftWorkRisks: 'nightOrShiftWorkRisks',
    psychosocialLoad: 'psychosocialLoad',
    associatedActivities: 'associatedActivities',
    youngPeople: 'youngPeople',
};

export const schema = object({
    [fields.healthSurveillance]: boolean(),
    [fields.safetyFunction]: boolean(),
    [fields.increasedVigilanceFunction]: boolean(),
    [fields.chemical]: string(),
    [fields.checkNoise]: boolean(),
    [fields.noise]: string(),
    [fields.checkTemperature]: boolean(),
    [fields.temperature]: string(),
    [fields.checkIonizing]: boolean(),
    [fields.ionizing]: string(),
    [fields.checkOtherPhysicalResources]: boolean(),
    [fields.otherPhysicalResources]: string(),
    [fields.biologicalResources]: string(),
    [fields.vaccinations]: string(),
    [fields.checkWorkStationLoad]: boolean(),
    [fields.workStationLoad]: string(),
    [fields.checkLiftingLoad]: boolean(),
    [fields.liftingLoad]: string(),
    [fields.checkOthersLoad]: boolean(),
    [fields.othersLoad]: string(),
    [fields.nightOrShiftWork]: string(),
    [fields.nightOrShiftWorkRisks]: string(),
    [fields.psychosocialLoad]: string(),
    [fields.associatedActivities]: string(),
    [fields.youngPeople]: string(),
});
