import React from 'react';

import { formatLocationAddressWithoutName } from '../../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import Button from '../../../../common/buttons/Button';
import LabelInfo from '../../../../common/widget/LabelInfo';
import Loader from '../../../../common/waiting/Loader';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Translate from '../../../../common/Translate';
import { CLASS_NAME, EMPTY_PLACEHOLDER } from '../AppointmentDetailDialog.const';
import { getPrefixedTranslation } from '../AppointmentDetailDialog.helper';

import { concatenateAttendees } from './CourseAppointmentDialog.helper';
import { ICourseAppointmentDialogProps } from './CourseAppointmentDialog.type';

const CourseAppointmentDialog = ({
    courseAttendeesAsyncFetchInfo,
    courseDetailId,
    courseSessionAttendees,
    coursesOverviewDetailByHawAsyncInfo,
    data,
    onNavigateToCourseClick,
}: ICourseAppointmentDialogProps) => {

    const onNavigateToCourseHandler = () => {
        if (!courseDetailId) {
            console.warn('No courseDetailId could be found');
            return;
        }

        onNavigateToCourseClick(courseDetailId);
    };

    return (
        <Loader
            show={courseAttendeesAsyncFetchInfo.status}
        >
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.where')}
                info={data.organizedCourse.location.address
                    ? formatLocationAddressWithoutName(data.organizedCourse.location.address)
                    : EMPTY_PLACEHOLDER
                }
                isInfoMultiLine={true}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.course.coordinator')}
                info={formatPersonName({
                    firstName: data.organizedCourse.coordinator.firstName,
                    name: data.organizedCourse.coordinator.lastName,
                })}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.who')}
                info={concatenateAttendees(courseSessionAttendees)}
                isInfoMultiLine={true}
                hideColon={true}
            />
            <div className={`${CLASS_NAME}__actions`}>
                <TinyLoader
                    asyncInfoSelector={coursesOverviewDetailByHawAsyncInfo}
                    hideErrorOnError
                >
                    {
                        courseDetailId && (
                            <Button
                                id="course-appointment-to-detail-button"
                                onClick={onNavigateToCourseHandler}
                                typeName="secondary"
                            >
                                <Translate msg={getPrefixedTranslation('labels.course.to_info')} />
                            </Button>
                        )
                    }
                </TinyLoader>
            </div>
        </Loader>
    );
};

export { CourseAppointmentDialog };
