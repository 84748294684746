import { ICourseCategory, ICMSCourseValueObject } from '../../../models/documentCenter/courses';
import { ITypeaheadDataItem } from '../../../views/common/input/Typeahead';
import { getFieldAscendingComparator } from '../../core/object/sortObjects';
import { isFilterSet } from '../../../views/common/widget/MasterWithDetail/Master/index';
import { separateStringList } from '../../core/string/separatedStringList';
import { IFilterValues } from '../../../views/documentCenter/Courses/Overview';
import isArray from '@snipsonian/core/es/is/isArray';
import { isAfterOrEqual } from '../../core/date/isAfterOrEqual';
import { isBeforeOrEqual } from '../../core/date/isBeforeOrEqual';
import { getDateWithoutTime } from '../../core/date/getSpecificDate';
import isObjectPure from '@snipsonian/core/es/is/isObjectPure';

export default function clientSideFilter(
    courses: ICourseCategory[],
    filterValues: IFilterValues,
) {
    return applyFiltersAndSearchOnCourses(
        courses,
        filterValues,
    );
}

function applyFiltersAndSearchOnCourses(
    courses: ICourseCategory[],
    filterValues: IFilterValues,
): ICourseCategory[] {
    return courses
    .reduce(
        (coursesAccumulator, courseCategory: ICourseCategory) => {
            const courses = clientSideFilterOfCourses(courseCategory, filterValues);

            if (courses.length > 0) {
                coursesAccumulator.push({
                    category: courseCategory.category,
                    courses,
                });
            }

            return coursesAccumulator;
        },
        [],
    );
}

function clientSideFilterOfCourses(courseCategory: ICourseCategory, filterValues: IFilterValues) {
    return courseCategory.courses
        .filter((course) => {
            if (!isFilterSet(filterValues.theme)) {
                return true;
            }

            const theme = separateStringList(filterValues.theme);
            return theme.includes(course.mainTheme.toString());
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.targetGroup)) {
                return true;
            }

            const targetGroupsFromFilter = separateStringList(filterValues.targetGroup);
            const targetGroupsFromCourse = course.targetGroups
                .filter((group) => group.name !== undefined)
                .map((group) => group.name);

            return targetGroupsFromFilter.some((group) => targetGroupsFromCourse.includes(group));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.businessSectors)) {
                return true;
            }

            const businessSectorsFromFilter = separateStringList(filterValues.businessSectors);
            const businessSectorsFromCourse = course.businessSectors
                .filter((sector) => sector.name !== undefined)
                .map((sector) => sector.name);

            return businessSectorsFromFilter.some((sector) => businessSectorsFromCourse.includes(sector));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.learningGoal)) {
                return true;
            }

            const learningGoalsFromFilter = separateStringList(filterValues.learningGoal);
            if (isObjectPure(course.learningGoal)) {
                return learningGoalsFromFilter.includes(course.learningGoal.name.toString());
            }
            return learningGoalsFromFilter.includes(course.learningGoal.toString());
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.learningMethod)) {
                return true;
            }

            const learningMethodsFromFilter = separateStringList(filterValues.learningMethod);
            const learningMethodsFromCourse = course.learningMethods
                .filter((method) => method.name !== undefined)
                .map((method) => method.name);

            return learningMethodsFromFilter.some((method) => learningMethodsFromCourse.includes(method));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.language)) {
                return true;
            }

            const languagesFromFilter = separateStringList(filterValues.language);
            return languagesFromFilter.some((lang) => course.languages.includes(lang));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.province)) {
                return true;
            }

            const provincesFromFilter = separateStringList(filterValues.province);
            return provincesFromFilter.some((province) => course.provinces.includes(province));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.financingMethod)) {
                return true;
            }

            const financingMethodsFromFilter = separateStringList(filterValues.financingMethod);
            const financingMethodsFromCourse = course.financingMethods
                .filter((method) => method.name !== undefined)
                .map((method) => method.name);

            return financingMethodsFromFilter.some((method) => financingMethodsFromCourse.includes(method));
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.startDate)) {
                return true;
            }

            if (!course.startDate) {
                return false;
            }

            return isAfterOrEqual(getDateWithoutTime(course.startDate), filterValues.startDate);
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.endDate)) {
                return true;
            }

            if (!course.startDate) {
                return false;
            }

            return isBeforeOrEqual(getDateWithoutTime(course.startDate), filterValues.endDate);
        })
        .filter((course) => {
            if (!isFilterSet(filterValues.search)) {
                return true;
            }

            return Object.keys(course)
                .find((key) => {
                    let value = course[key] || '';
                    if (typeof value !== 'string') {
                        value = value.toString();
                    }
                    return value.toLowerCase().includes(filterValues.search.toLowerCase());
                });
        });
}

export function getUniqueTypeaheadFilterValuesFromCourses<DataType>(
    data: DataType[],
    valueColumnName: keyof DataType,
    labelColumnName: keyof DataType,
): ITypeaheadDataItem<string>[] {
    return data.reduce(
        (uniqueAccumulator, item) => {
            if (item[valueColumnName]) {
                if (isArray(item[valueColumnName])) {
                    if (item[valueColumnName][0]) {
                        let values = [];
                        if (typeof item[valueColumnName][0] === 'object') {
                            values = (item[valueColumnName] as unknown as ICMSCourseValueObject[])
                                .filter((valueObject) => valueObject.name !== undefined)
                                .map((valueObject) => valueObject.name);
                        } else if (typeof item[valueColumnName][0] === 'string') {
                            values = item[valueColumnName] as unknown as string[];
                        }

                        values.forEach((value) => {
                            uniqueAccumulator = uniqueAccumulatorValues(uniqueAccumulator, value);
                        });
                    }
                } else {
                    if (isObjectPure(item[valueColumnName])) {
                        const valueObject = item[valueColumnName] as unknown as ICMSCourseValueObject;
                        const labelObect = item[labelColumnName] as unknown as ICMSCourseValueObject;
                        if (valueObject.name) {
                            const value = valueObject.name;
                            const label = labelObect.name;
                            uniqueAccumulator = uniqueAccumulatorValues(uniqueAccumulator, value, label);
                        }
                    } else {
                        const value = item[valueColumnName].toString();
                        const label = item[labelColumnName];
                        uniqueAccumulator = uniqueAccumulatorValues(uniqueAccumulator, value, label);
                    }

                }
            }
            return uniqueAccumulator;
        },
        [],
    )
    .sort(getFieldAscendingComparator<ITypeaheadDataItem>('label'));
}

function uniqueAccumulatorValues(uniqueAccumulator: any[], value: string, label?: any) {
    if (uniqueAccumulator.every((entry) =>
        entry.value !== value)
    ) {
        uniqueAccumulator.push({
            value,
            label: label || value,
        });
    }

    return uniqueAccumulator;
}

export function getUniqueTypeaheadFilterValuesFromCourseCategories(
    courses: ICourseCategory[],
): ITypeaheadDataItem<string>[] {
    return courses.reduce(
        (uniqueAccumulator, course: ICourseCategory) => {
            const value = course.category;

            if (uniqueAccumulator.every((entry) =>
                entry.value !== value)
            ) {
                uniqueAccumulator.push({
                    value,
                    label: value,
                });
            }

            return uniqueAccumulator;
        },
        [],
    )
        .sort(getFieldAscendingComparator<ITypeaheadDataItem>('label'));
}
