import React from 'react';

import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    getCompanyMedicalCenters,
    getFetchCompanyMedicalCentersAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead from '../../../../common/input/Typeahead';

import {
    IMedicalCenterTypeaheadProps,
    IMedicalCenterTypeaheadPropsPrivateProps,
} from './MedicalCenterTypeahead.type';
import { makeMedicalCentersForTypeaheadMemoizedSelector } from './MedicalCenterTypeahead.selector';

// Medical Center (without bufferzone locations) typeahead lists all company medical centers
// If you need bufferzone locations use LocationTypeahead component
const MedicalCenterTypeaheadComponent = ({
    children,
    disabled,
    disableReset,
    id,
    isInvalid,
    medicalCenters,
    medicalCentersTypeahead,
    name,
    onItemSelected,
    placeholderTranslationKey,
    translator,
    value,
}: IMedicalCenterTypeaheadProps & IMedicalCenterTypeaheadPropsPrivateProps) => {
    function onItemSelectedHandler(medicalCenterId: number) {
        const selectedMedicalCenter = medicalCenters.find((item) => item.id === medicalCenterId);

        onItemSelected(medicalCenterId, selectedMedicalCenter || null);
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={medicalCentersTypeahead}
            placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
            disabled={disabled}
            disableReset={disableReset}
            asyncInfoSelector={getFetchCompanyMedicalCentersAsyncInfo}
        >
            {children}
        </Typeahead>
    );
};

const MedicalCenterTypeahead = connect<IMedicalCenterTypeaheadPropsPrivateProps, IMedicalCenterTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const { excludeInCompanyMedicalCenter = false } = publicProps;

            const medicalCenters = getCompanyMedicalCenters(state);
            const typeaheadItems = makeMedicalCentersForTypeaheadMemoizedSelector(
                excludeInCompanyMedicalCenter,
            )(state);

            return {
                medicalCenters,
                medicalCentersTypeahead: typeaheadItems,
                translator: getTranslatorDeprecated(state),
            };
        };
    },
})(MedicalCenterTypeaheadComponent);

export { MedicalCenterTypeahead };
