import { ComponentClass, ReactNode } from 'react';
import { RouteObject, Action, NOT_FOUND } from 'redux-first-router';
import { IEventCustomData } from '../../snipsonian/analytics/src/tracker/providers/gtmTracker';
import ROUTE_KEYS from '../../routeKeys';
import { IAccessLevel } from '../../models/auth/authorisation';
import { IState } from '../../redux';
import { IAction, ILocationAction } from '../../models/general/redux';
import { IAsyncEntityToFetch } from '@snipsonian/redux-features/es/entities/types';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';

export const NOT_FOUND_ROUTE_KEY = NOT_FOUND;

type ISyncComponent = ComponentClass | ((props?: object) => JSX.Element);
type IAsyncComponent = ((props?: object) => Promise<{ default: ComponentClass | ((props?: object) => JSX.Element) }>);

type IPageComponent = ISyncComponent | IAsyncComponent;

export interface IPage {
    key: string;
    component: IPageComponent;
}

type PageChildren = ReactNode | ((renderProps: object) => ReactNode);
export type ITemplate = (
    ComponentClass | ((props: { children: PageChildren }) => JSX.Element)
) & { hasRenderProps?: boolean };

export type TVirtualPage = string | (string | ((state: IState) => string))[];

export type TParentRouteKeyOverride = false | ROUTE_KEYS;

export interface IRoute extends RouteObject {
    page: IPage;
    pageProps?: object;
    template: ITemplate;
    templateProps?: object;
    virtualPage: TVirtualPage;
    extraDataToLog?: (state: IState) => IEventCustomData;
    allowAnonymousAccess?: boolean;
    groups?: string[]; // to group related routes
    /**
     * requiredAccessLevels
     * - At least 1 of the specified Partial<IAccessLevel> is needed (OR relation)
     * - If a Partial<IAccessLevel> contains multiple levels within, then all those levels are needed (AND relation)
     */
    requiredAccessLevels?: Partial<IAccessLevel>[];
    checkForRedirect?: (state: IState) => IAction<{}>;
    breadcrumbsLabel?: IBreadcrumbsLabel;
    redirectToRouteIfInterim?: ROUTE_KEYS;
    entitiesToFetch?: IEntityToFetchOnRoute<{}, {}>[];
    /**
     * Some route configurations can get duplicated to another route, e.g. for having a same page
     * under another parent dashboard. If 'isDuplicate' is true, the system will allow for instance
     * that the same virtualPage is used.
     * Beware that duplication can also require some epics/reducers to also be triggered by the extra route.
     */
    isDuplicate?: boolean;
    /**
     * Showing a single route under multiple dashboards, causes inconsistent breadcrumbs.
     * For example for the route with path /dashboardA/specificPage, the user will always
     * see 'dashboardA > specificPage' as breadcrumbsPath, even though he navigated to the
     * route from /dashboardB.
     * With the optional 'overrideParentRouteKey' one can conditionally override the parentRouteKey
     * that will be used for determining the breadcrumbsPath.
     * When this function is not specified, or if it returns false, the parentRoute will be
     * determined by the path of the route (just as the rest of the breadcrumbsPath is determined).
     */
    overrideParentRouteKey?: (state: IState) => TParentRouteKeyOverride;
}

export interface IRoutes {
    [key: string]: IRoute;
}

export interface IEntityToFetchOnRoute<
    ApiInput extends object,
    RoutePayload extends object,
    RouteQuery extends object = {},
    Result extends object = {}
> extends IAsyncEntityToFetch<IState, ILocationAction<RoutePayload, RouteQuery>, ApiInput, Result> {
    entityKey: ASYNC_ENTITY_KEYS;
}

export interface IRouteAction extends Action {
    type: ROUTE_KEYS;
}

export type IBreadcrumbsPath = IBreadcrumb[];

export interface IBreadcrumb {
    type: ROUTE_KEYS;
    payload?: object;
    label: IBreadcrumbsLabel;
    isCurrent: boolean;
}

export interface IBreadcrumbsLabel {
    translationKey: string;
    translationPlaceholdersSelector?: (state: IState) => { [key: string]: string };
}
