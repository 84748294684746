export const WEBAPP_TRANSLATIONS_DOMAIN = 'webapp';

export enum CMS_CONTEXTS {
    page = 'main',
    legalHeader = 'legal_header',
    legalMain = 'legal_main',
}

export enum CMS_PAGE_NAME {
    cookieConsentContent = 'kz_legal_cookie_preferences',
    privacyPolicyContent = 'kz_legal_privacy_policy',
    disclaimer = 'mkz_legal_disclaimer',
}

export enum CMS_PAGE_TYPE {
    content = 'content',
    legal = 'legal',
}
