import React, { Component } from 'react';
import classNames from 'classnames';
import './course-detail.scss';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../../../../utils/core/string/anchor';
import { COURSE_TYPE_MAP, TEACHING_TYPES } from '../../../../../../config/courses.config';
import { ICMSCourseDetail, ICMSCourse } from '../../../../../../models/documentCenter/courses';
import { SVG_GROUP_NAME } from '../../../../../../models/general/lazyLoadSvg';
import { IAsyncFieldInfo } from '../../../../../../models/general/redux';
import {
    getCoursesOverviewDetailAsyncInfo,
    getCoursesOverviewDetail,
    getChildCourse,
    getSelectedCourseIntroductionId,
    getCourseSessionsAsyncInfo,
} from '../../../../../../redux/documentCenter/courses/selectors';
import { navigateTo } from '../../../../../../redux/location/actions';
import { getPropertyFromRoutePayload } from '../../../../../../redux/location/selectors';
import { doesCompanyCurrentlyUsesPreventionUnits } from '../../../../../../redux/preventionUnits/selectors';
import { connect } from '../../../../../index';
import PageHeader from '../../../../../appShell/PageHeader';
import Loader from '../../../../../common/waiting/Loader';
import Button from '../../../../../common/buttons/Button';
import PagePart from '../../../../../common/page/PagePart';
import Translate from '../../../../../common/Translate';
import ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../../../common/widget/ContentWithSidebar';
import LazyLoadSVG from '../../../../../common/widget/LazyLoadSVG';
import { CourseDetails } from '../../../shared/CourseDetails';
import { SideBarCta } from '../../../shared/SidebarCta';
import CourseMethods from '../CourseMethods';
import mapToCourseDetailInfo, { ICourseDetailInfo } from '../mapToCourseDetailInfo';
import CourseDetailSessions from './CourseDetailSessions';
import { createElearningCourseEnrollWizardEntity } from '../../../../../../redux/documentCenter/courses/actions';

interface IPrivateProps {
    courseIntroductionAsyncInfo: IAsyncFieldInfo;
    courseIntroductionData: ICMSCourseDetail;
    selectedCourse: ICMSCourseDetail;
    hidePE: boolean;
    courseSessionsAsyncInfo: IAsyncFieldInfo;
    onMethodSelect: (course: ICMSCourse) => void;
}

const CLASS_NAME = 'CourseDetail';
const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.child';

class CourseDetail extends Component<IPrivateProps> {
    private courseDetailSessionsRef: HTMLDivElement;

    constructor(props) {
        super(props);

        this.scrollToSessions = this.scrollToSessions.bind(this);
        this.setCourseDetailSessionsRef = this.setCourseDetailSessionsRef.bind(this);
    }

    public render() {
        const {
            courseIntroductionAsyncInfo, courseIntroductionData,
            selectedCourse, hidePE, onMethodSelect,
            courseSessionsAsyncInfo,
        } = this.props;

        const courseDetailInfo = mapToCourseDetailInfo(selectedCourse);

        const CourseHeaderInfo = () => {
            return selectedCourse && (
                <div className="CourseHeaderInfo">
                    {selectedCourse.course.teachingType && COURSE_TYPE_MAP[selectedCourse.course.teachingType] && (
                        <LazyLoadSVG
                            id={COURSE_TYPE_MAP[selectedCourse.course.teachingType].lazyLoadSvgId}
                            group={SVG_GROUP_NAME.COURSE_TYPES}
                        />
                    )}
                    <p>{selectedCourse.course.pageName}</p>
                </div>
            );
        };

        return (
            <div className={CLASS_NAME}>
                <Loader show={courseIntroductionAsyncInfo.status} />
                {courseIntroductionData && (
                    <>
                        <PageHeader
                            breadcrumbs={true}
                            title={courseIntroductionData.course.name}
                            customContent={<CourseHeaderInfo />}
                        />
                        <div className="container">
                            <div className={`${CLASS_NAME}__page-header-button`}>
                                <Button
                                    id="page-header-course-detail-button"
                                    typeName="secondary"
                                    onClick={this.scrollToSessions}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.page_header.button`} />
                                </Button>
                            </div>
                            <CourseDetails
                                course={courseIntroductionData.course}
                                hidePE={hidePE}
                            />
                            <ContentWithSidebar
                                sidebar={(renderProps) =>
                                    <NavigationSidebar
                                        courseDetailInfo={courseDetailInfo}
                                        onSidebarCtaClick={this.scrollToSessions}
                                        {...renderProps}
                                    />}
                                content={<MainContent courseDetailInfo={courseDetailInfo} />}
                                titleIds={getNavigationTitleIds(courseDetailInfo)}
                            />
                        </div>
                        <CourseDetailSessions setRef={this.setCourseDetailSessionsRef} />
                        <div className={`${CLASS_NAME}__methods`}>
                            <Loader show={courseSessionsAsyncInfo.status} />
                            <CourseMethods
                                titleTranslationKey={`${TRANSLATION_PREFIX}.methods_title`}
                                methods={courseIntroductionData.children.filter((method) =>
                                    method.course.nodeId !== selectedCourse.course.nodeId)}
                                onMethodSelect={onMethodSelect}
                                hidePE={hidePE}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }

    private scrollToSessions() {
        if (this.courseDetailSessionsRef && this.courseDetailSessionsRef) {
            this.courseDetailSessionsRef.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private setCourseDetailSessionsRef(ref: HTMLDivElement) {
        this.courseDetailSessionsRef = ref;
    }
}

interface ICourseDetailInfoProps {
    courseDetailInfo: ICourseDetailInfo;
}

interface ICourseDetailSidebarProps extends ICourseDetailInfoProps {
    onSidebarCtaClick: () => void;
}

function NavigationSidebar(props: ICourseDetailSidebarProps & IContentWithSidebarRenderProps) {
    const { courseDetailInfo, activeTitleId, setActiveTitleIdOverride, onSidebarCtaClick } = props;

    return (
        <nav>
            {courseDetailInfo.anchors
                .map(({ anchorId, text }) => {
                    const titleClass = classNames({
                        active: activeTitleId === anchorId,
                    });
                    return (
                        <SideBarLink
                            key={`sidebar-${anchorId}`}
                            href={toAnchorLinkHref(anchorId)}
                            className={titleClass}
                            setActiveTitleIdOverride={() => setActiveTitleIdOverride(anchorId)}
                        >
                            <Translate msg={text} />
                        </SideBarLink>
                    );
                })
            }
            <SideBarCta
                titleTranslationKey={`${TRANSLATION_PREFIX}.sidebar.cta.title`}
                descriptionTranslationKey={`${TRANSLATION_PREFIX}.sidebar.cta.description`}
                buttonTranslationKey={`${TRANSLATION_PREFIX}.sidebar.cta.button`}
                buttonOnClickHandler={onSidebarCtaClick}
            />
        </nav>
    );
}

function MainContent(props: ICourseDetailInfoProps) {
    const { courseDetailInfo } = props;

    return (
        <div className="content">
            {courseDetailInfo.pageParts
                .map((courseDetailPagePart, index) => {
                    const pagePartId = courseDetailPagePart.anchorId
                        ? toAnchorTargetId(courseDetailPagePart.anchorId)
                        : `pagePart-without-title-${index}`;
                    return (
                        <div
                            key={`course-detail-info-pagePart-${index}`}
                            id={pagePartId}
                        >
                            <PagePart pagePart={courseDetailPagePart} />
                        </div>
                    );
                })
            }
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const selectedCourseId = getPropertyFromRoutePayload(state, 'childNodeId');
        const selectedCourse = getChildCourse(state, selectedCourseId);

        return {
            courseIntroductionAsyncInfo: getCoursesOverviewDetailAsyncInfo(state),
            courseIntroductionData: getCoursesOverviewDetail(state),
            selectedCourse,
            hidePE: !doesCompanyCurrentlyUsesPreventionUnits(state),
            courseSessionsAsyncInfo: getCourseSessionsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => ({
        onMethodSelect: (course: ICMSCourse) => {
            const state = getState();
            const introductionNodeId = getSelectedCourseIntroductionId(state);

            if (course.teachingType === TEACHING_TYPES.online) {
                dispatch(createElearningCourseEnrollWizardEntity({
                    childNodeId: course.nodeId,
                }));
            } else {
                window.scrollTo(0, 0);
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_COURSE, {
                    nodeId: introductionNodeId,
                    childNodeId: course.nodeId,
                }));
            }
        },
    }),
})(CourseDetail);

function getNavigationTitleIds(courseDetailInfo: ICourseDetailInfo) {
    return courseDetailInfo
        .anchors
        .map((title) => title.anchorId);
}
