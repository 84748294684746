import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from '../../index';
import './login.scss';
import './login-split-screen.scss';
import Translate from '../../common/Translate';
import LoginHeader from './LoginHeader';
import Footer from '../../appShell/Footer';
import {
    isLoggedIn as isLoggedInSelector,
    requestAccountHasAdministrators,
    isRequestingOrCreatingAccount,
    isLoggingIn,
    isLoadingForgotPassword,
    loginError,
} from '../../../redux/auth/selectors';
import { navigateTo } from '../../../redux/location/actions';
import illustration from '../../assets/img/illustrations/svg_man-aan-computer-login.svg';
import Dialog from '../../common/modals/Dialog';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
} from '../../../redux/company/selected/selectors';
import RequestAccount from '../RequestAccount';
import { IUserAccount } from '../../../models/user/userAccount';
import ButtonLinkToRoute from '../../common/navigation/ButtonLinkToRoute';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import MaintenanceBar from './MaintenanceBar';
import { isMaintenanceBusy } from '../../../redux/entities/selectors';
import Carousel from '../Carousel/index';
import InlineSVG from '../../common/icons/InlineSVG';
import LoginActions from './LoginActions';
import Loader from '../../common/waiting/Loader';
import FormError from '../../common/forms/FormError';
import { ITraceableApiError } from '../../../models/general/error';

interface IPrivateProps {
    isLoggedIn: boolean;
    authError: ITraceableApiError;
    isRequestingOrCreatingAccount: boolean;
    requestAccountHasAdministrators: boolean;
    requestAccountAdministrators: IUserAccount[];
    fetchCompanySeatsAsyncInfo: IAsyncFieldInfo;
    onDialogClosed: () => void;
    isMaintenanceBusy: boolean;
    showLoader: boolean;
}

export interface ILoginProps {
    showRequestAccount?: boolean;
    showSplitScreenLogin?: boolean;
    showCarousel?: boolean;
}

const USE_SPLIT_SCREEN_LOGIN = true;

class Login extends PureComponent<IPrivateProps & ILoginProps> {

    constructor(props: IPrivateProps & ILoginProps) {
        super(props);

        this.onRequestAccountDialogCloseIntent = this.onRequestAccountDialogCloseIntent.bind(this);
    }

    public render() {
        const {
            isMaintenanceBusy,
            showRequestAccount, requestAccountHasAdministrators,
            showCarousel,
        } = this.props;

        if (showCarousel) {
            return <Carousel />;
        }

        return (
            <div
                className={classNames('Login', {
                    'Login--split-screen': !!USE_SPLIT_SCREEN_LOGIN,
                })}
            >
                {!USE_SPLIT_SCREEN_LOGIN ? this.renderDefaultLoginScreen() : this.renderSplitScreenLogin()}
                <Footer hideRouteLinks={true} />
                <Dialog
                    show={!isMaintenanceBusy && showRequestAccount}
                    onCloseIntent={this.onRequestAccountDialogCloseIntent}
                    header={
                        requestAccountHasAdministrators ? 'auth.request_account.step_one.error.title' : null
                    }
                    type={requestAccountHasAdministrators ? 'error' : null}
                >
                    <RequestAccount showAdministrators={requestAccountHasAdministrators} />
                </Dialog>
            </div>
        );
    }

    private onRequestAccountDialogCloseIntent() {
        const { onDialogClosed, isRequestingOrCreatingAccount } = this.props;
        if (!isRequestingOrCreatingAccount) {
            onDialogClosed();
        }
    }

    private renderDefaultLoginScreen() {
        const { isMaintenanceBusy, showLoader, authError } = this.props;

        return (
            <>
                <MaintenanceBar />
                <LoginHeader />
                <div className="content">
                    <div className="content__section">
                        <Loader show={showLoader} />
                        <div className="login-box">
                            <div className="login-box__welcome">
                                <h1><Translate msg="auth.login.auth0.title" /></h1>
                                <p>
                                    <Translate msg="auth.login.auth0.text" />
                                </p>
                            </div>
                            {authError && (
                                <div className="login-box__error">
                                    <FormError error={authError} />
                                </div>
                            )}
                            <LoginActions />
                            <InlineSVG svg={illustration} className="login-box__figure" />
                        </div>
                        <div className="no-account">
                            <h3><Translate msg="auth.login.info.title" /></h3>
                            <div>
                                <Translate raw msg="auth.login.info.content" />
                            </div>
                            <div className="content__section__actions">
                                <ButtonLinkToRoute
                                    id="login-no-account-button"
                                    typeName="secondary"
                                    to={ROUTE_KEYS.R_REQUEST_ACCOUNT}
                                    disabled={isMaintenanceBusy}
                                >
                                    <Translate msg="auth.login.info.no_account" />
                                </ButtonLinkToRoute>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderSplitScreenLogin() {
        const { showLoader, authError } = this.props;
        const splitScreenBgImage = require('../../assets/img/general/split-screen-login-features.jpg');

        return (
            <>
                <div className="content">
                    <div className="content__section content__section--bg">
                        <div className="content__section__inner">
                            <div className="top">
                                <LoginHeader />
                            </div>
                            <div className="bottom">
                                <h2><Translate msg="auth.login.features.title" /></h2>
                                <ButtonLinkToRoute
                                    id="login-start-features-carousel"
                                    typeName="secondary"
                                    to={ROUTE_KEYS.R_NEW_FEATURES_CAROUSEL}
                                >
                                    <Translate msg="auth.login.features.button" />
                                </ButtonLinkToRoute>
                            </div>
                        </div>
                        <figure style={{ backgroundImage: `url(${splitScreenBgImage})` }}>
                            <img src={splitScreenBgImage} />
                        </figure>
                    </div>
                    <div className="content__section content__section--login">
                        <Loader show={showLoader} />
                        <MaintenanceBar />
                        <div className="login-box">
                            <div className="login-box__welcome">
                                <h1><Translate msg="auth.login.auth0.title" /></h1>
                                <p>
                                    <Translate msg="auth.login.auth0.text" />
                                </p>
                            </div>
                            {authError && (
                                <div className="login-box__error">
                                    <FormError error={authError} />
                                </div>
                            )}
                            <LoginActions />
                            <InlineSVG svg={illustration} className="login-box__figure" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps, ILoginProps>(
    {
        stateProps: (state) => ({
            authError: loginError(state),
            showLoader: isLoggingIn(state) || isLoadingForgotPassword(state),
            isLoggedIn: isLoggedInSelector(state),
            requestAccountHasAdministrators: requestAccountHasAdministrators(state),
            isRequestingOrCreatingAccount: isRequestingOrCreatingAccount(state),
            fetchCompanySeatsAsyncInfo: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state),
            isMaintenanceBusy: isMaintenanceBusy(state),
        }),
        dispatchProps: (dispatch, getState) => ({
            onDialogClosed: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_LOGIN));
            },
        }),
    },
)(Login);
