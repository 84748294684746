import React from 'react';
import CompanyData from './CompanyData';
import ContactData from './ContactData';
import Availabilities from '../shared/Availabilities';
import { CompanyAvailabilityType } from '../../../../models/admin/companyInfo';
import { getCompanyDetailName } from '../../../../redux/company/info/selectors';
import { getSelectedSeatCompanyCode, isAllSeatsSelected } from '../../../../redux/company/selected/selectors';
import Translate from '../../../common/Translate';
import
ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../common/widget/ContentWithSidebar';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../../utils/core/string/anchor';
import classNames from 'classnames';
import Holidays from '../shared/Holidays';

const CLASS_NAME = 'CompanyInfoGeneral';

interface ITitleConfig {
    id: string;
    translationKey: string;
    content: React.ComponentType;
    tooltipTranslationKey?: string;
}

interface ITitlesConfig {
    [key: string]: ITitleConfig;
}

const TITLES_CONFIG: ITitlesConfig = {
    COMPANY_DATA: {
        id: 'COMPANY_DATA',
        translationKey: 'administration.company_info.general.sidebar.company_data',
        content: CompanyData,
    },
    CONTACT_DATA: {
        id: 'CONTACT_DATA',
        translationKey: 'administration.company_info.general.sidebar.contact_data',
        content: ContactData,
    },
    YEARLY_VACATION: {
        id: 'YEARLY_VACATION',
        translationKey: 'administration.company_info.general.sidebar.yearly_vacation',
        content: Holidays,
        tooltipTranslationKey: 'administration.company_info.general.tooltip.yearly_vacation',
    },
    AVAILABILITIES_MEDICAL_EXAMINATIONS: {
        id: 'AVAILABILITIES_MEDICAL_EXAMINATIONS',
        translationKey: 'administration.company_info.general.sidebar.availabilities_medical_examinations',
        content: AvailabilitiesMedicalExaminations,
        tooltipTranslationKey: 'administration.company_info.general.tooltip.availabilities_medical_examinations',
    },
    AVAILABILITIES_RISK_MANAGEMENT: {
        id: 'AVAILABILITIES_RISK_MANAGEMENT',
        translationKey: 'administration.company_info.general.sidebar.availabilities_risk_management',
        content: AvailabilitiesRiskManagement,
        tooltipTranslationKey: 'administration.company_info.general.tooltip.availabilities_risk_management',
    },
};

function General() {
    return (
        <ContentWithSidebar
            sidebar={SidebarContent}
            content={MainContent}
            titleIds={Object.values(TITLES_CONFIG).map((item) => item.id)}
        />
    );
}

export default General;

const SidebarContent = ({ activeTitleId, setActiveTitleIdOverride }: IContentWithSidebarRenderProps) => (
    <nav>
        {
            Object.keys(TITLES_CONFIG).map((key, index) => {
                const titleConfig: ITitleConfig = TITLES_CONFIG[key];
                const titleClass = classNames({
                    active: activeTitleId === titleConfig.id,
                });
                return (
                    <SideBarLink
                        key={`companyInfo_sidebar_${key}`}
                        href={toAnchorLinkHref(titleConfig.id)}
                        className={titleClass}
                        setActiveTitleIdOverride={() => setActiveTitleIdOverride(titleConfig.id)}
                    >
                        <Translate msg={titleConfig.translationKey} />
                    </SideBarLink>
                );
            })
        }
    </nav>
);

const MainContent = (
    <div className={CLASS_NAME}>
        {Object.keys(TITLES_CONFIG).map((key) => (
            <ContentBlock
                key={`companyInfo_content_${key}`}
                titleConfig={TITLES_CONFIG[key]}
            />
        ))}
    </div>
);

function ContentBlock({ titleConfig }: { titleConfig: ITitleConfig }) {
    const Content = titleConfig.content;

    return (
        <>
            <h4 id={toAnchorTargetId(titleConfig.id)}>
                <Translate msg={titleConfig.translationKey} />&nbsp;
                {titleConfig.tooltipTranslationKey &&
                    <TooltipWithIcon iconSize="small">
                        <Translate msg={titleConfig.tooltipTranslationKey} />
                    </TooltipWithIcon>
                }
            </h4>
            <div className="ContentData">
                <Content />
            </div>
        </>
    );
}

function AvailabilitiesMedicalExaminations() {
    return (
        <Availabilities
            type={CompanyAvailabilityType.MedicalExaminations}
            companyNameSelector={getCompanyDetailName}
            companyCodeSelector={getSelectedSeatCompanyCode}
            isCopyToAllSeatsAllowedSelector={isAllSeatsSelected}
        />
    );
}

function AvailabilitiesRiskManagement() {
    return (
        <Availabilities
            type={CompanyAvailabilityType.RiskManagement}
            companyNameSelector={getCompanyDetailName}
            companyCodeSelector={getSelectedSeatCompanyCode}
            isCopyToAllSeatsAllowedSelector={isAllSeatsSelected}
        />
    );
}
