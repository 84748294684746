import { createSelector } from 'reselect';
import { path } from 'ramda';

import {
    filterOutInfirmMedicalCenters,
} from '../../../../../utils/interventions/medicalExaminations/filterOutInFirmMedicalCenters';
import { getCompanyMedicalCenters } from '../../../../../redux/company/info/selectors';
import { getReservedMedicalExaminations } from '../../../../../redux/medicalExamination/selectors';
import { ILocationTypeCodes } from '../../../../../models/general/location';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../../../config/planning.config';

import {
    mapBufferzoneMainCenterToBufferzoneMedicalCenter,
    mapBufferzoneToBufferzoneMedicalCenter,
    mapLocationsForLocationTypeahead,
} from './LocationTypeahead.helper';
import { TLocationTypeaheadItems } from './LocationTypeahead.type';

export function makeLocationsForTypeaheadMemoizedSelector({
    includeBufferzones,
    excludeInFirm,
}: {
    includeBufferzones: boolean,
    excludeInFirm: boolean,
}) {
    return createSelector(
        getCompanyMedicalCenters,
        getReservedMedicalExaminations,
        (medicalCenters, reservedMedicalExaminations) => {
            let medicalCentersToMap: TLocationTypeaheadItems = [...medicalCenters];

            if (includeBufferzones) {
                reservedMedicalExaminations
                    // We can assume that a bufferzone location is "IN_FIRMA" when no location code is present.
                    // We filter out all the in firma locations because they are bundled as "IN FIRMA"
                    // in the dropdown since they all should have location code 7.
                    .filter((bufferzone) => {
                        const locationCode = path(['location', 'code'], bufferzone);
                        const locationTypeCode = path(['location', 'typeCode'], bufferzone);

                        // Filter out bufferzones on IN_FIRMA (empty code property or code: "7")
                        if (!locationCode || locationCode === IN_COMPANY_MEDICAL_CENTER_CODE) {
                            return false;
                        }

                        // Location type code CUSTOMER === 'IN FIRMA'
                        return locationTypeCode !== ILocationTypeCodes.CUSTOMER;
                    })
                    .forEach((bufferzone) => {
                        if (bufferzone.location.mainCenter) {
                            if (!medicalCentersToMap.find(
                                medicalCenter => medicalCenter.code === bufferzone.location.mainCenter.code,
                            )) {
                                medicalCentersToMap.push(mapBufferzoneMainCenterToBufferzoneMedicalCenter(bufferzone));
                            }

                            return;
                        }


                        if (!medicalCentersToMap.find(
                            (medicalCenter) => medicalCenter.code === bufferzone.location.code,
                        )) {
                            medicalCentersToMap.push(mapBufferzoneToBufferzoneMedicalCenter(bufferzone));
                        }
                    });
            }

            if (excludeInFirm) {
                medicalCentersToMap = filterOutInfirmMedicalCenters(medicalCentersToMap);
            }

            return mapLocationsForLocationTypeahead(medicalCentersToMap);
        },
    );
}
