import React, { PureComponent, MouseEvent } from 'react';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import { schema, FormValues, fields } from './editCourseExternalEmployeeSchema';
import { createFormTextInput } from '../../../../common/forms/FormTextInput';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import Button from '../../../../common/buttons/Button';
import {
    fetchCourseExternalEmployeeActions,
    updateCourseExternalEmployeeActions,
} from '../../../../../redux/documentCenter/courses/actions';
import {
    getUpdateCourseExternalEmployeeAsyncInfo,
    getFetchCourseExternalEmployeeAsyncInfo,
    getCourseExternalEmployee,
} from '../../../../../redux/documentCenter/courses/selectors';
import {
    ICourseSessionAttendee,
    ICourseExternalEmployee,
    IUpdateCourseExternalEmployeePayload,
} from '../../../../../models/documentCenter/courses';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import FormFieldError from '../../../../common/forms/FormFieldError';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../models/general/constants';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../../utils/formatting/formatPhone';

interface IPrivateProps {
    clearError: (error: ITraceableApiError) => void;
    updateAsyncInfo: IAsyncFieldInfo;
    fetchAsyncInfo: IAsyncFieldInfo;
    fetchCourseExternalEmployee: (employeeId: number) => void;
    updateCourseExternalEmployee: (payload: IUpdateCourseExternalEmployeePayload) => void;
    courseExternalEmployee: ICourseExternalEmployee;
}

interface IEditCourseExternalEmployeeProps {
    onClose: () => void;
    selectedAttendee: Pick<ICourseSessionAttendee, 'employeeId'>;
}

const FORM_NAME = 'edit-course-external-employee-form';
const CLASS_NAME = 'EditCourseExternalEmployee';

const TRANSLATION_PREFIX = 'administration.employees.edit_course_external_employee';
const FIELD_NAME_PREFIX = `${TRANSLATION_PREFIX}.form`;

const FormTextInput = createFormTextInput<FormValues>();

class EditCourseExternalEmployee extends PureComponent<IPrivateProps & IEditCourseExternalEmployeeProps> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props: IPrivateProps & IEditCourseExternalEmployeeProps) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const {
            updateAsyncInfo, fetchAsyncInfo,
        } = this.props;

        const showLoader =
            fetchAsyncInfo.status === AsyncStatus.Busy ||
            updateAsyncInfo.status === AsyncStatus.Busy;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <div className={CLASS_NAME}>
                        <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                            <h2><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h2>
                        </header>
                        <Form
                            name={FORM_NAME}
                            handleSubmit={this.onSubmit}
                            schema={schema}
                            initialValues={{}}
                            render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                this.formRenderProps = formRenderProps;

                                const { values, errors, setFieldValue, touched } = formRenderProps;

                                const birthdatePlaceholder =
                                    translator(`${FIELD_NAME_PREFIX}.birth_date`);
                                const languagePlaceholder =
                                    translator(`${FIELD_NAME_PREFIX}.language`);

                                const apiError = updateAsyncInfo.error || fetchAsyncInfo.error;

                                return (
                                    <>
                                        <Loader show={showLoader} />
                                        {apiError && <ErrorPlaceholder apiError={apiError} />}
                                        <FormTextInput
                                            name="firstName"
                                            baseId={FORM_NAME}
                                            baseTranslationKey={FIELD_NAME_PREFIX}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                        />
                                        <FormTextInput
                                            name="name"
                                            baseId={FORM_NAME}
                                            baseTranslationKey={FIELD_NAME_PREFIX}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                        />
                                        <FloatableTextInputWrapper floatLabel>
                                            <DatePicker
                                                id="edit-external-employee-birth-date"
                                                placeholder={birthdatePlaceholder}
                                                value={values.birthDate}
                                                name={fields.birthDate}
                                                onChange={(value) => setFieldValue('birthDate', value)}
                                                isInvalid={touched.birthDate && !!errors.birthDate}
                                            >
                                                <label htmlFor="add-external-employee-birth-date">
                                                    {birthdatePlaceholder}
                                                </label>
                                            </DatePicker>
                                            {touched.birthDate && (
                                                <FormFieldError
                                                    error={errors.birthDate}
                                                    placeholders={{ fieldName: birthdatePlaceholder }}
                                                />
                                            )}
                                        </FloatableTextInputWrapper>
                                        <FormTextInput
                                            name="functionDescription"
                                            baseId={FORM_NAME}
                                            baseTranslationKey={FIELD_NAME_PREFIX}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                        />
                                        <FloatableTextInputWrapper floatLabel>
                                            <ConstantsTypeahead
                                                id="edit-external-employee-language"
                                                constantType={ConstantType.LANGUAGES}
                                                name={fields.languageId}
                                                placeholder={languagePlaceholder}
                                                value={values.languageId}
                                                onItemSelected={(value) => setFieldValue('languageId', Number(value))}
                                                isInvalid={touched.languageId && !!errors.languageId}
                                            >
                                                <label htmlFor="add-external-employee-language">
                                                    <Translate
                                                        msg={languagePlaceholder}
                                                    />
                                                </label>
                                            </ConstantsTypeahead>
                                            {touched.languageId && (
                                                <FormFieldError
                                                    error={errors.languageId}
                                                    placeholders={{ fieldName: languagePlaceholder }}
                                                />
                                            )}
                                        </FloatableTextInputWrapper>
                                        <FormTextInput
                                            name="email"
                                            baseId={FORM_NAME}
                                            baseTranslationKey={FIELD_NAME_PREFIX}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                        />
                                        <FormTextInput
                                            name="phone"
                                            baseId={FORM_NAME}
                                            baseTranslationKey={FIELD_NAME_PREFIX}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                        />
                                        <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                            <Button
                                                id="edit-external-employee-submit-button"
                                                typeName="secondary"
                                                outline={true}
                                                onClick={this.onCancelClick}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.actions.cancel`} />
                                            </Button>
                                            <SubmitButton
                                                id="edit-external-employee-submit-button"
                                                formName={FORM_NAME}
                                                disabled={!!fetchAsyncInfo.error}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.actions.submit`} />
                                            </SubmitButton>
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidMount() {
        const {
            clearError, updateAsyncInfo, fetchCourseExternalEmployee, selectedAttendee,
        } = this.props;
        clearError(updateAsyncInfo.error);
        fetchCourseExternalEmployee(selectedAttendee.employeeId);
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            (this.props.updateAsyncInfo.status === AsyncStatus.Success &&
                prevProps.updateAsyncInfo.status === AsyncStatus.Busy)
        ) {
            this.props.onClose();
        }

        if (
            (this.props.fetchAsyncInfo.status === AsyncStatus.Success &&
                prevProps.fetchAsyncInfo.status === AsyncStatus.Busy)
        ) {
            const {
                birthDate,
                email,
                firstName,
                function: employeeFunction,
                languageId,
                name,
                phone,
            } = this.props.courseExternalEmployee;

            this.formRenderProps.resetForm({
                birthDate: formatDateForBackend(birthDate),
                email,
                firstName,
                functionDescription: employeeFunction.description,
                languageId,
                name,
                phone: tryFormattingPhoneInternational(phone),
            });
        }
    }

    private onSubmit(values: FormValues) {
        const { functionDescription, ...fields } = values;
        this.props.updateCourseExternalEmployee({
            employeeId: this.props.selectedAttendee.employeeId,
            fieldsToUpdate: {
                ...fields,
                function: {
                    description: functionDescription,
                },
                phone: tryFormattingPhoneForBackend(values.phone),
            },
        });
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, updateAsyncInfo, fetchAsyncInfo } = this.props;
        clearError(updateAsyncInfo.error);
        clearError(fetchAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IEditCourseExternalEmployeeProps>({
    stateProps: (state) => {
        const updateAsyncInfo = getUpdateCourseExternalEmployeeAsyncInfo(state);
        const fetchAsyncInfo = getFetchCourseExternalEmployeeAsyncInfo(state);
        const courseExternalEmployee = getCourseExternalEmployee(state);

        return {
            updateAsyncInfo,
            fetchAsyncInfo,
            courseExternalEmployee,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateCourseExternalEmployee: (payload: IUpdateCourseExternalEmployeePayload) => {
                dispatch(updateCourseExternalEmployeeActions.trigger(payload));
            },
            fetchCourseExternalEmployee: (employeeId: number) => {
                dispatch(fetchCourseExternalEmployeeActions.trigger({ employeeId }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditCourseExternalEmployee);
