import React, { PureComponent } from 'react';
import SearchInput from '../../input/SearchInput';
import Radiobutton from '../../input/Radiobutton';
import './list-actions.scss';

interface IListActionsProps {
    name: string;

    withSelectAll: boolean;
    withSearch: boolean;

    selectAllChecked?: boolean;
    selectAllLabel?: string | React.ReactElement<{}>;
    selectAllDisabled?: boolean;
    onToggleSelectAll?: (checked: boolean) => void;

    searchPlaceholder?: string;
    initialSearchValue?: string;
    searchInputTooltipTranslationKey?: string;
    searchDisabled?: boolean;
    onSearchInput?: (value: string) => void;
    isSearchInputInvalid?: boolean;

    customListAction?: React.ReactElement<{}>;
}

interface IState {
    selectAllChecked: boolean;
}

export default class ListActions extends PureComponent<IListActionsProps, IState> {
    constructor(props: IListActionsProps) {
        super(props);

        this.state = {
            selectAllChecked: props.selectAllChecked || false,
        };

        this.onSelectAllClick = this.onSelectAllClick.bind(this);
        this.onSearchInput = this.onSearchInput.bind(this);
    }

    public render() {
        const {
            withSelectAll, withSearch, name, selectAllLabel,
            searchPlaceholder, selectAllDisabled, initialSearchValue,
            searchDisabled, isSearchInputInvalid, searchInputTooltipTranslationKey,
            customListAction,
        } = this.props;
        const { selectAllChecked } = this.state;

        return (
            <div className="ListActions">
                {customListAction}
                {withSelectAll && <div className="select-all">
                    <Radiobutton
                        name={`list-actions-select-all-${name}`}
                        value="all"
                        onClick={this.onSelectAllClick}
                        onChange={this.onSelectAllClick}
                        checked={selectAllChecked}
                        disabled={selectAllDisabled}
                    >
                        {selectAllLabel}
                    </Radiobutton>
                </div>}
                {withSearch && <SearchInput
                    placeholder={searchPlaceholder}
                    onChange={this.onSearchInput}
                    defaultValue={initialSearchValue}
                    disabled={searchDisabled}
                    isInvalid={isSearchInputInvalid}
                    tooltipTranslationKey={searchInputTooltipTranslationKey}
                />}
            </div>
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IListActionsProps) {
        this.setState({
            selectAllChecked: nextProps.selectAllChecked || false,
        });
    }

    private onSelectAllClick() {
        const { onToggleSelectAll } = this.props;
        const checked = !this.state.selectAllChecked;
        this.setState({
            selectAllChecked: checked,
        });
        if (typeof onToggleSelectAll === 'function') {
            onToggleSelectAll(checked);
        }
    }

    private onSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
        const { onSearchInput } = this.props;
        if (typeof onSearchInput === 'function') {
            onSearchInput(e.target.value);
        }
    }
}
