import React from 'react';
import { connect } from '../../..';
import { registerWizardSteps, WIZARD_CODE } from '../../../../config/navigation/wizardStepsMap';
import {
    COURSE_ENROLL_WIZARD_STEP_ID,
    ICourseEnrollWizardEntity,
    ICourseEnrollWizardStep,
} from '../../../../models/documentCenter/courses';
import { changeCourseEnrollWizardStep } from '../../../../redux/documentCenter/courses/actions';
import {
    getCourseEnrollWizardEntity,
    getCourseEnrollWizardStepId,
} from '../../../../redux/documentCenter/courses/selectors';
import { isDateInNextCalendarYear } from '../../../../utils/core/date/isDateInNextCalendarYear';
import Wizard from '../../../common/navigation/Wizard';
import IndexInfoBox from '../shared/IndexInfoBox';
import Course from './wizard/Course';
import Employee from './wizard/Employee';

const COURSE_ENROLL_STEPS: ICourseEnrollWizardStep[] = [{
    id: COURSE_ENROLL_WIZARD_STEP_ID.COURSE,
    translationKey: 'document_center.courses.new.steps.course.step_label',
    renderStepContent: (props) => <Course {...props} />,
    hide: (entity) =>
        entity &&
        entity.session &&
        !!entity.session.selectedFromOverview &&
        !!entity.location.selectedClosedCourseFromOverview,
}, {
    id: COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE,
    translationKey: 'document_center.courses.new.steps.employee.step_label',
    renderStepContent: (props) => <Employee {...props} />,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.ENROLL_COURSE,
    steps: COURSE_ENROLL_STEPS,
});

interface IPrivateProps {
    wizardEntity: ICourseEnrollWizardEntity;
}

function CourseEnrollWizard(props: IPrivateProps) {
    const steps = COURSE_ENROLL_STEPS.filter((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(props.wizardEntity);
        }
        return true;
    });

    const isSelectedSessionInNextCalendarYear = props.wizardEntity
        && props.wizardEntity.session
        && isDateInNextCalendarYear(props.wizardEntity.session.start);

    return (
        <div className="CourseEnrollWizard">
            <Wizard
                activeStepIdSelector={getCourseEnrollWizardStepId}
                navigateToStepActionCreator={changeCourseEnrollWizardStep}
                steps={steps}
            />
            {isSelectedSessionInNextCalendarYear && (
                <div className="container">
                    <IndexInfoBox
                        titleTranslationKey="document_center.courses.new.index_info_box.title"
                        descriptionTranslationKey="document_center.courses.new.index_info_box.description"
                    />
                </div>
            )}
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getCourseEnrollWizardEntity(state) as ICourseEnrollWizardEntity;

        return {
            wizardEntity: entity,
        };
    },
})(CourseEnrollWizard);
