import {
    ISwitchLocalePayload,
    IFetchTranslationsAndAllConstantsPayload,
} from '../../models/general/i18n';
import { localeToBackendLocale } from '../../utils/formatting/formatLocale';
import { logUserLocale } from '../../utils/logging/analytics/eventLogger';
import { PAYLOAD_PARAM } from '../../utils/libs/redux/async/asyncReducerUtils';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { setBackendLocale, setDateLocalization } from '../../config/i18n.config';
import { setLocaleHeader } from '../../utils/api/requestWrapper';
import { createEpic } from '../index';
import { doesCurrentRouteBelongToGroup, getLocationState } from '../location/selectors';
import { fetchAllConstants } from '../constants/actions';
import { isLoggedIn } from '../auth/selectors';
import { navigateTo } from '../location/actions';

import { areTranslationsRefreshedForLocale, setMessagesOfLocale } from './translations';
import {
    FETCH_TRANSLATIONS_AND_ALL_CONSTANTS,
    REFRESH_TRANSLATIONS,
    SWITCH_LOCALE,
} from './types';
import {
    fetchTranslationsAndAllConstantsActions,
    refreshTranslationsFailed,
    refreshTranslationsSucceeded,
    switchLocaleActions,
} from './actions';
import { getLocale } from './selectors';

// switchLocaleEpic
createEpic<ISwitchLocalePayload>({
    onActionType: SWITCH_LOCALE,
    filter: ({ action, getState }) => action.payload.newLocale !== getLocale(getState()),
    async processMultiple({ action, api, getState }, dispatch, done) {
        const currentLocale = getLocale(getState());
        const payload = action.payload;
        try {
            const locale = payload.newLocale;

            const isAlreadyLoggedIn = isLoggedIn(getState());

            setLocaleHeader(locale);
            const backendLocale = localeToBackendLocale(locale);
            setBackendLocale(backendLocale);
            dispatch(fetchAllConstants(backendLocale, isAlreadyLoggedIn));
            setDateLocalization(locale);

            if (!areTranslationsRefreshedForLocale(locale)) {
                const messages = await api.general.i18n.getTranslations({
                    locale: payload.newLocale,
                });
                setMessagesOfLocale({ messages, locale });
            }

            /**
             * This success action will also automatically clear most of the redux state (see 'registerReducer').
             * This is needed so that the re-trigger of the current route (see below) will definitely
             * fetch all data again, because passing 'shouldRefreshData: true' will take care of most,
             * but not all: data that is not triggered by route would not be fetched again
             * + the 'entities-refresh' mechanism (e.g. used for templates='standaard documenten') does
             * not work with the shouldRefreshData mechanism.
             */
            dispatch(switchLocaleActions.succeeded(payload));
            logUserLocale({
                locale,
            });

            if (!doesCurrentRouteBelongToGroup(getState(), ROUTE_GROUP.NO_DATA_REFRESH_ON_LOCALE_SWITCH)) {
                /**
                 * Retrigger current route so that all data is fetched again, because a lot of data
                 * contains locale-specific text.
                 */
                const currentLocation = getLocationState(getState());

                dispatch(navigateTo(
                    currentLocation.type,
                    {
                        ...currentLocation.payload,
                        [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: true,
                        [PAYLOAD_PARAM.NO_DATA_REFRESH_EPIC_IDS]: undefined,
                    },
                    /**
                     * Instead of passing currentLocation.query, we pass an empty object for the query
                     * to not reload the current query as the current query can contain filter criteria which
                     * are in a particular language causing the result list to not contain any data anymore.
                     * So - when a user switches the language - he/she will loose for example
                     * potentially-selected-filters in a list screen.
                     */
                    {},
                ));
                return done();
            }

            return done();
        } catch (error) {
            setLocaleHeader(currentLocale);
            setBackendLocale(localeToBackendLocale(currentLocale));
            setDateLocalization(currentLocale);
            dispatch(switchLocaleActions.failed(error));
            return done();
        }
    },
    latest: false,
});

// refreshTranslationsEpic
createEpic({
    onActionType: REFRESH_TRANSLATIONS,
    async processReturn({ api, getState }) {
        const currentLocale = getLocale(getState());
        try {
            const messages = await api.general.i18n.getTranslations({
                locale: currentLocale,
            });
            setMessagesOfLocale({ messages, locale: currentLocale });
            return refreshTranslationsSucceeded();
        } catch (error) {
            return refreshTranslationsFailed();
        }
    },
    latest: false,
});

// fetchTranslationsAndAllConstantsEpic
createEpic<IFetchTranslationsAndAllConstantsPayload>({
    onActionType: FETCH_TRANSLATIONS_AND_ALL_CONSTANTS,
    throttleTimeInMillis: 1, // to make sure AsyncStatus goes to Busy before going to Success
    async processMultiple({ action, api, getState }, dispatch, done) {
        const payload = action.payload;
        try {
            const locale = payload.locale;

            if (locale === getLocale(getState())) {
                dispatch(fetchTranslationsAndAllConstantsActions.succeeded({}));
                return done();
            }

            const backendLocale = localeToBackendLocale(locale);
            dispatch(fetchAllConstants(backendLocale));

            if (!areTranslationsRefreshedForLocale(locale)) {
                const messages = await api.general.i18n.getTranslations({
                    locale,
                });
                setMessagesOfLocale({ messages, locale });
            }

            dispatch(fetchTranslationsAndAllConstantsActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(fetchTranslationsAndAllConstantsActions.failed(error));
            return done();
        }
    },
    latest: false,
});
