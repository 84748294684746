import React, { PureComponent, InputHTMLAttributes, ChangeEvent } from 'react';
import './maskedtextinput.scss';
import classNames from 'classnames';
import MaskedInput, { maskArray } from 'react-text-mask';
import { ITextInputProps, ITextInputBaseProps } from '../TextInput';

interface IMaskedTextInputProps extends ITextInputBaseProps {
    mask: maskArray;
    value: string;
    pipe?: (
        conformedValue: string,
        config: any,
    ) => false | string | { value: string; indexesOfPipedChars: number[] };
    inputRef?: (el: HTMLInputElement) => void;
}

interface IState {
    hasChangedAfterSetToInvalid: boolean;
}

export default class MaskedTextInput extends PureComponent<IMaskedTextInputProps, IState> {
    private carretPosition: number;
    private inputEl: HTMLInputElement;

    constructor(props: IMaskedTextInputProps) {
        super(props);

        this.state = {
            hasChangedAfterSetToInvalid: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const {
            value, isInvalid, mask,
            disableAutoComplete, disabled,
            id, name, defaultValue, type,
            onFocus, onBlur, onKeyUp, onKeyDown,
            onKeyPress, onClick, placeholder,
            pipe, inputRef,
        } = this.props;
        const textInputClasses = classNames('MaskedTextInput', 'TextInput', {
            dirty: value && value.toString().length > 0 || isInvalid,
            invalid: !!isInvalid,
            'reset-color': this.state.hasChangedAfterSetToInvalid || !isInvalid,
            disabled: !!disabled,
        });

        // Autocomplete behaves strange in Chrome
        // When it has a value use 'off'
        // When it is empty use any other invalid value
        const autoComplete = disableAutoComplete ? (!value ? 'nope' : 'off') : 'on';

        const inputProps: InputHTMLAttributes<HTMLInputElement> = {
            value: value || '',
            disabled, id, name,
            defaultValue, type,
            onChange: this.onChange,
            onFocus, onBlur, onKeyDown, onKeyUp,
            onKeyPress, onClick, placeholder,
        };

        return (
            <MaskedInput
                {...inputProps}
                autoComplete={autoComplete}
                className={textInputClasses}
                mask={mask}
                guide={true}
                placeholderChar="_"
                pipe={pipe}
                data-input
                keepCharPositions={true}
                render={(ref, props) => {
                    const wrappedRef = (el: HTMLInputElement) => {
                        this.inputEl = el;
                        if (typeof inputRef === 'function') {
                            inputRef(el);
                        }
                        ref(el);
                    };
                    return (
                        <input ref={wrappedRef} {...props} data-input />
                    );
                }}
            />
        );
    }

    public UNSAFE_componentWillUpdate(nextProps: ITextInputProps) {
        if (nextProps.isInvalid !== this.props.isInvalid) {
            this.setState({
                hasChangedAfterSetToInvalid: false,
            });
        } else if (!this.state.hasChangedAfterSetToInvalid && this.props.value !== nextProps.value) {
            this.setState({
                hasChangedAfterSetToInvalid: true,
            });
        }
        this.restoreCarretPosition();
    }

    private onChange(e: ChangeEvent<HTMLInputElement>) {
        this.carretPosition = e.currentTarget.selectionStart;
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(e);
        }
    }

    private restoreCarretPosition() {
        if (this.inputEl && document.activeElement === this.inputEl) {
            this.inputEl.setSelectionRange(this.carretPosition, this.carretPosition);
        }
    }
}
