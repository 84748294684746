import React from 'react';
import EditCourseExternalEmployee from '../../../../administration/Employees/shared/EditCourseExternalEmployee';
import { IPlannedCoursesDetailOverlayType, IPlannedCoursesDetailOverlayFormData } from '../common';

export default function DetailOverlay(props: {
    overlayType: IPlannedCoursesDetailOverlayType,
    closeOverlay: () => void,
    formValues: IPlannedCoursesDetailOverlayFormData,
}) {
    switch (props.overlayType) {
        default:
            return (
                <EditCourseExternalEmployee
                    onClose={props.closeOverlay}
                    selectedAttendee={{
                        employeeId: props.formValues && props.formValues.employeeId,
                    }}
                />
            );
    }
}
