import React, { Component, DOMAttributes, MouseEvent, ReactElement, ReactNode } from 'react';
import './tooltip.scss';
import Popper from '../../technical/Popper';
import Icon, { IIconProps } from '../../icons/Icon';
import HideAfterDelay from '../../waiting/HideAfterDelay';
import classNames from 'classnames';
import { Placement } from 'popper.js';
import isTouchDevice from '../../../../utils/dom/isTouchDevice';
import { isSmallScreen } from '../../../../utils/dom/screenSize';

interface ITooltipRenderProps {
    closeTooltip: () => void;
}

export interface ITooltipProps {
    target: React.ReactElement<DOMAttributes<{}>>;
    spaceBetweenTarget?: number;
    onOpen?: () => void;
    onClose?: () => void;
    tooltipBubbleClassName?: string;
    delayBeforeShow?: number;
    icon?: ReactElement<IIconProps>;
    children?: ReactNode | ((renderProps: ITooltipRenderProps) => ReactNode);
    disableAutoHide?: boolean;
    placement?: Placement;
    isOpenOnInitialRender?: boolean;
    typeName: 'tooltip' | 'info-bubble';
}

interface IState {
    isOpen: boolean;
    isAnimating: boolean;
    isMouseOnTooltip: boolean;
    isMouseOnTooltipBubble: boolean;
}

const AUTO_HIDE_DELAY = 100;

const activeTooltips: Tooltip[] = [];

class Tooltip extends Component<ITooltipProps, IState> {
    private showTimeout: number;
    private onTargetMouseLeaveTimeout: number;

    constructor(props: ITooltipProps) {
        super(props);

        this.closeTooltip = this.closeTooltip.bind(this);
        this.openTooltip = this.openTooltip.bind(this);
        this.onTargetClick = this.onTargetClick.bind(this);
        this.onTargetMouseEnter = this.onTargetMouseEnter.bind(this);
        this.onTargetMouseLeave = this.onTargetMouseLeave.bind(this);
        this.onBubbleMouseEnter = this.onBubbleMouseEnter.bind(this);
        this.onBubbleMouseLeave = this.onBubbleMouseLeave.bind(this);
        this.onBubbleClickCapture = this.onBubbleClickCapture.bind(this);
        this.onBubbleClick = this.onBubbleClick.bind(this);
        this.onBubbleTransitionEnd = this.onBubbleTransitionEnd.bind(this);

        this.state = {
            isOpen: props.isOpenOnInitialRender || false,
            isAnimating: false,
            isMouseOnTooltip: false,
            isMouseOnTooltipBubble: false,
        };

        activeTooltips.push(this);
    }

    public render() {
        const {
            target, children, spaceBetweenTarget,
            tooltipBubbleClassName, icon, disableAutoHide,
            placement, typeName,
        } = this.props;
        const { isOpen, isMouseOnTooltipBubble, isMouseOnTooltip, isAnimating } = this.state;

        const targetComponent = React.createElement(target.type, {
            ...target.props,
            onMouseEnter: this.onTargetMouseEnter,
            onMouseLeave: this.onTargetMouseLeave,
            onClick: target.props.onClick || this.onTargetClick,
        });

        const tooltipBubbleClasses = classNames('TooltipBubble', tooltipBubbleClassName, {
            'TooltipBubble--open': isOpen,
            'TooltipBubble--tooltip': typeName === 'tooltip',
            'TooltipBubble--info-bubble': typeName === 'info-bubble',
        });

        const hideAfterDelayEnabled = !isTouchDevice() && !isSmallScreen() &&
            isOpen && !isMouseOnTooltipBubble && !isMouseOnTooltip && !disableAutoHide;

        const childrenTypedAsFunction = children as ((renderProps: ITooltipRenderProps) => ReactNode);

        return (
            <HideAfterDelay
                enabled={hideAfterDelayEnabled}
                onHide={this.closeTooltip}
                hideDelay={AUTO_HIDE_DELAY}
            >
                <Popper
                    isOpen={isOpen || isAnimating}
                    target={targetComponent}
                    targetClassName="TooltipTarget"
                    onClickOutside={this.closeTooltip}
                    onTargetOutsideViewport={this.closeTooltip}
                    placement={placement || 'right-start'}
                    flipBehavior={['right', 'left']}
                    spaceBetweenPopper={spaceBetweenTarget || 0}
                >
                    {({ placement }) => {
                        return (
                            <div
                                className={classNames(tooltipBubbleClasses, `placement-${placement}`)}
                                onMouseLeave={this.onBubbleMouseLeave}
                                onMouseEnter={this.onBubbleMouseEnter}
                                onClick={this.onBubbleClick}
                                onClickCapture={this.onBubbleClickCapture}
                                onTransitionEnd={this.onBubbleTransitionEnd}
                            >
                                {typeName === 'info-bubble' && (icon || <Icon typeName="info" />)}
                                {typeName === 'tooltip' && (<span className="TooltipBubble__arrow" />)}
                                {typeof children === 'function' ? childrenTypedAsFunction({
                                    closeTooltip: this.closeTooltip,
                                }) : children}
                            </div>
                        );
                    }}
                </Popper>
            </HideAfterDelay>
        );
    }

    public UNSAFE_componentWillUpdate(nextProps: ITooltipProps, nextState: IState) {
        if (nextState.isOpen) {
            activeTooltips.forEach((tooltip) => {
                if (tooltip !== this) {
                    tooltip.closeTooltip();
                }
            });
        }
    }

    public componentWillUnmount() {
        const currentIndex = activeTooltips.indexOf(this);
        if (currentIndex !== -1) {
            activeTooltips.splice(currentIndex, 1);
        }
        this.clearShowTimeout();
        this.clearOnTargetMouseLeaveTimeout();
    }

    private onTargetClick(e: MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        const nextIsOpen = !this.state.isOpen;
        if (nextIsOpen) {
            this.openTooltip();
        } else {
            this.closeTooltip();
        }
    }

    private closeTooltip() {
        if (this.state.isOpen) {
            this.setState({
                isOpen: false,
                isAnimating: true,
            });
        }
    }

    private openTooltip() {
        if (!this.state.isOpen) {
            this.clearShowTimeout();
            this.showTimeout = window.setTimeout(
                () => {
                    this.setState({
                        isOpen: true,
                    });
                },
                this.props.delayBeforeShow || 0,
            );
            this.setState({
                isAnimating: true,
            });
            this.triggerOnOpenOrCloseProp(true);
        }
    }

    private onBubbleMouseLeave() {
        this.setState({
            isMouseOnTooltipBubble: false,
        });
    }

    private onBubbleMouseEnter() {
        this.setState({
            isMouseOnTooltipBubble: true,
        });
    }

    private onTargetMouseEnter() {
        if (isTouchDevice() || isSmallScreen()) {
            return;
        }
        this.clearOnTargetMouseLeaveTimeout();
        this.setState({
            isMouseOnTooltip: true,
        });
        this.openTooltip();
    }

    private onTargetMouseLeave() {
        // Execute this in a timeout as there could be empty space between the
        // tooltip target and the bubble
        this.clearOnTargetMouseLeaveTimeout();
        this.onTargetMouseLeaveTimeout = window.setTimeout(
            () => {
                if (!this.state.isMouseOnTooltipBubble) {
                    this.closeTooltip();
                }
                this.setState({
                    isMouseOnTooltip: false,
                });
            },
            200,
        );
    }

    private onBubbleClickCapture(e: MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        if (!this.state.isOpen) {
            e.stopPropagation();
        }
    }

    private onBubbleClick(e: MouseEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
    }

    private triggerOnOpenOrCloseProp(isOpen: boolean) {
        const { onOpen, onClose } = this.props;
        if (isOpen && typeof onOpen === 'function') {
            onOpen();
        }
        if (!isOpen && typeof onClose === 'function') {
            onClose();
        }
    }

    private clearShowTimeout() {
        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }
    }

    private clearOnTargetMouseLeaveTimeout() {
        if (this.onTargetMouseLeaveTimeout) {
            clearTimeout(this.onTargetMouseLeaveTimeout);
        }
    }

    private onBubbleTransitionEnd() {
        this.setState({
            isAnimating: false,
        });
        this.triggerOnOpenOrCloseProp(this.state.isOpen);
    }
}

export default Tooltip;
