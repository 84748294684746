import React, { PureComponent } from 'react';
import { connect } from '../../../..';
import './company-data.scss';
import { fields, schema } from '../updateCompanyInfoSchema';
import { getLanguageDescription } from '../../../../../config/constants';
import { ICompanyDetail } from '../../../../../models/admin/companyInfo';
import { ICity, IAddress } from '../../../../../models/general/address';
import { ITraceableApiError } from '../../../../../models/general/error';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { ITranslator } from '../../../../../models/general/i18n';
import { ConstantType } from '../../../../../models/general/constants';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    getCompanyDetailAsyncInfo,
    getCompanyDetail,
    getUpdateCompanyAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { updateCompanyActions } from '../../../../../redux/company/info/actions';
import { getSelectedSeatCompanyCode } from '../../../../../redux/company/selected/selectors';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import Translate from '../../../../common/Translate';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Alert from '../../../../common/widget/Alert';
import LabelInfo from '../../../../common/widget/LabelInfo';
import SlideOutPanel from '../../../../common/widget/SlideOutPanel';
import Loader from '../../../../common/waiting/Loader';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../common/input/TextInput';
import FormFieldError from '../../../../common/forms/FormFieldError';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import formatOndernemingsnummer from '../../../../../utils/formatting/formatOndernemingsnummer';
import formatRszNumber from '../../../../../utils/formatting/formatRszNumber';
import { joinParts } from '../../../../../utils/formatting/formatHelper';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { BE_COUNTRY_CODE } from '../../../../../config/general.config';
import { UNSELECTED_STREET } from '../../../../common/address/StreetTypeahead';
import { UNSELECTED_ZIPCODEID } from '../../../../common/address/CityTypeahead';
import AddressFields from '../../../../common/address/AddressFields';

export const CLASS_NAME = 'CompanyData';
const FORM_NAME = 'edit-company-data';

export const PLACEHOLDER_KEYS = {
    gvn: 'administration.company_info.general.company_data.edit_info.placeholders.rsz_number',
    language: 'administration.company_info.general.company_data.edit_info.placeholders.language',
    name: 'administration.company_info.general.company_data.edit_info.placeholders.name',
    ventureNumber: 'administration.company_info.general.company_data.edit_info.placeholders.venture_number',
};

export interface IFormValues {
    address: IAddress;
    gvn: string;
    languageId: number;
    name: string;
    ventureNumber: string;
    billingAddress: IAddress;
}

interface IPrivateProps {
    companyDetail: ICompanyDetail;
    updateCompanyAsyncInfo: IAsyncFieldInfo;
    dispatchUpdateCompany: (values: IFormValues, selectedBillingAddressCity: ICity) => void;
    translator: ITranslator;
    dispatchClearErrors: (error: ITraceableApiError) => void;
}

interface IComponentState {
    isEditPanelOpen: boolean;
    selectedBillingAddressCity: ICity;
}

class CompanyData extends PureComponent<IPrivateProps, IComponentState> {
    private footer = CompanyDataFooter();

    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            isEditPanelOpen: false,
            selectedBillingAddressCity: null,
        };

        this.onOpenEditPanel = this.onOpenEditPanel.bind(this);
        this.onCloseEditPanelIntent = this.onCloseEditPanelIntent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                <TinyLoader asyncInfoSelector={getCompanyDetailAsyncInfo} >
                    <DataFields {...this.props} />
                    <OpenEditPanelButton onClick={this.onOpenEditPanel} />
                </TinyLoader>

                <SlideOutPanel
                    isOpen={this.state.isEditPanelOpen}
                    onCloseIntent={this.onCloseEditPanelIntent}
                    header={this.renderEditHeader()}
                >
                    {this.renderEditContent()}
                </SlideOutPanel>
            </div>
        );
    }

    private renderEditHeader() {
        const { companyDetail, updateCompanyAsyncInfo } = this.props;

        if (!companyDetail) {
            return null;
        }

        return (
            <>
                <h1><Translate msg="administration.company_info.general.company_data.edit_info.title" /></h1>
                <p><Translate msg="administration.company_info.general.company_data.edit_info.text" /></p>
                {updateCompanyAsyncInfo.error &&
                    <FormError error={updateCompanyAsyncInfo.error} />}
                {updateCompanyAsyncInfo.status === AsyncStatus.Success &&
                    <Alert type="success">
                        <Translate
                            msg="administration.company_info.general.company_data.actions.edit_confirm_success"
                        />
                    </Alert>}
            </>
        );
    }

    private renderEditContent() {
        const { companyDetail, updateCompanyAsyncInfo, translator } = this.props;

        if (!companyDetail) {
            return null;
        }

        const INITIAL_VALUES: IFormValues = {
            address: companyDetail.address || {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: UNSELECTED_STREET,
                zipCodeId: UNSELECTED_ZIPCODEID,
            },
            gvn: companyDetail.gvn,
            languageId: companyDetail.languageId,
            name: companyDetail.name,
            ventureNumber: companyDetail.ventureNumber,
            billingAddress: companyDetail.billingAddress || {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: UNSELECTED_STREET,
                zipCodeId: UNSELECTED_ZIPCODEID,
            },
        };

        return (
            <Form
                name={FORM_NAME}
                initialValues={INITIAL_VALUES}
                schema={schema}
                handleSubmit={this.handleSubmit}
                className={`${CLASS_NAME}__edit`}
                footer={this.footer}
                render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                    const {
                        values, handleChange, errors, setFieldValue, touched,
                    } = formRenderProps;

                    return (
                        <>
                            <Loader show={updateCompanyAsyncInfo.status === AsyncStatus.Busy} />
                            <div className={`${CLASS_NAME}__edit__form`}>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="edit-company-info-name"
                                        name={fields.name}
                                        value={values.name || ''}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    />
                                    <label htmlFor="edit-company-info-name">
                                        <Translate
                                            msg={PLACEHOLDER_KEYS.name}
                                        />
                                    </label>
                                    {touched.name &&
                                        <FormFieldError
                                            error={errors.name}
                                            placeholders={{
                                                fieldName: translator(
                                                    PLACEHOLDER_KEYS.name),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="edit-company-info-kbo"
                                        name={fields.ventureNumber}
                                        value={values.ventureNumber || ''}
                                        onChange={handleChange}
                                        isInvalid={touched.ventureNumber && !!errors.ventureNumber}
                                    />
                                    <label htmlFor="edit-company-info-kbo">
                                        <Translate
                                            msg={PLACEHOLDER_KEYS.ventureNumber}
                                        />
                                    </label>
                                    {touched.ventureNumber &&
                                        <FormFieldError
                                            error={errors.ventureNumber}
                                            placeholders={{
                                                fieldName: translator(
                                                    PLACEHOLDER_KEYS.ventureNumber),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="edit-company-info-gvn"
                                        name={fields.gvn}
                                        value={values.gvn || ''}
                                        onChange={handleChange}
                                        isInvalid={touched.gvn && !!errors.gvn}
                                    />
                                    <label htmlFor="edit-company-info-gvn">
                                        <Translate
                                            msg={PLACEHOLDER_KEYS.gvn}
                                        />
                                    </label>
                                    {touched.gvn &&
                                        <FormFieldError
                                            error={errors.gvn}
                                            placeholders={{
                                                fieldName: translator(
                                                    PLACEHOLDER_KEYS.gvn),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <ConstantsTypeahead
                                        id="edit-company-info-languageId"
                                        name={fields.languageId}
                                        value={values.languageId}
                                        constantType={ConstantType.LANGUAGES}
                                        onItemSelected={(value) => setFieldValue(
                                            'languageId', value)}
                                        isInvalid={touched.languageId && !!errors.languageId}
                                    >
                                        <label htmlFor="edit-company-info-languageId">
                                            <Translate
                                                msg={PLACEHOLDER_KEYS.language}
                                            />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.languageId &&
                                        <FormFieldError
                                            error={errors.languageId}
                                            placeholders={{
                                                fieldName: translator(
                                                    PLACEHOLDER_KEYS.language),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <AddressFields
                                    {...formRenderProps}
                                    translator={translator}
                                    initialAddress={INITIAL_VALUES.address}
                                    allowInternationalAddress
                                />
                                {
                                    // eslint-disable max-len
                                    /* TODO: enable again when billing address is editable
                                    <h4>
                                        <Translate
                                            msg="administration.company_info.general.company_data.fields.address"
                                        />
                                    </h4>
                                    <AddressFields
                                        {...formRenderProps}
                                        addressField="address"
                                        translator={translator}
                                        initialAddress={INITIAL_VALUES.address}
                                        selectedCity={selectedCity}
                                        className={CLASS_NAME}
                                        onCitySelected={(city) => this.setState({ selectedAddressCity: city })}
                                    />
                                    <h4>
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="
                                                administration.company_info.general.company_data.fields.billing_address"
                                        />
                                    </h4>
                                    <AddressFields
                                        {...formRenderProps}
                                        addressField="billingAddress"
                                        translator={translator}
                                        initialAddress={INITIAL_VALUES.billingAddress}
                                        selectedCity={selectedBillingAddressCity}
                                        className={CLASS_NAME}
                                        onCitySelected={(city) => this.setState({ selectedBillingAddressCity: city })}
                                    />
                                    */
                                    // tslint:enable:max-line-length
                                }
                            </div>
                        </>
                    );
                }}
            />
        );
    }

    private handleSubmit(values: IFormValues) {
        const { selectedBillingAddressCity } = this.state;
        this.props.dispatchUpdateCompany(values, selectedBillingAddressCity);
    }

    private onOpenEditPanel() {
        this.setState({
            isEditPanelOpen: true,
        });
    }

    private onCloseEditPanelIntent() {
        const { dispatchClearErrors, updateCompanyAsyncInfo } = this.props;

        this.setState({
            isEditPanelOpen: false,
        });

        dispatchClearErrors(updateCompanyAsyncInfo.error);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            companyDetail: getCompanyDetail(state),
            updateCompanyAsyncInfo: getUpdateCompanyAsyncInfo(state),
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            dispatchUpdateCompany: (
                values: IFormValues, selectedBillingAddressCity: ICity,
            ) => {
                dispatch(updateCompanyActions.trigger({
                    companyCode: getSelectedSeatCompanyCode(getState()),
                    companyData: {
                        billingAddress: {
                            number: values.billingAddress.number,
                            street: values.billingAddress.street,
                            zipCodeId: values.billingAddress.zipCodeId,
                            box: values.billingAddress.box,
                            city: selectedBillingAddressCity && selectedBillingAddressCity.name,
                            postcode: selectedBillingAddressCity && selectedBillingAddressCity.postalCode,
                            countryCode: values.address.countryCode,
                        },
                        address: {
                            number: values.address.number,
                            street: values.address.street,
                            zipCodeId:
                                (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                                    null : values.address.zipCodeId,
                            box: values.address.box,
                            city: values.address.city,
                            postcode: values.address.postcode,
                            countryCode: values.address.countryCode,
                        },
                        gvn: values.gvn,
                        languageId: values.languageId,
                        name: values.name,
                        ventureNumber: values.ventureNumber,
                    },
                }));
            },
            dispatchClearErrors: (error: ITraceableApiError) => {
                const updateCompanyErrorId = error && error.id;
                dispatch(clearErrors([updateCompanyErrorId]));
            },
        };
    },
})(CompanyData);

function DataFields({ companyDetail }: IPrivateProps) {
    if (!companyDetail) {
        return null;
    }
    return (
        <div>
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.name'}
                info={companyDetail.name}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.company_code'}
                info={companyDetail.companyCode}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.nace'}
                info={displayNaceInfo(companyDetail)}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.category'}
                info={companyDetail.statisticalCode}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.venture_number'}
                info={formatOndernemingsnummer(companyDetail.ventureNumber)}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.rsz_number'}
                info={formatRszNumber(companyDetail.gvn)}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.language'}
                info={companyDetail.languageId ? getLanguageDescription(companyDetail.languageId) : ''}
            />
            <LabelInfo
                labelKey={'administration.company_info.general.company_data.fields.address'}
                info={formatAddress(companyDetail.address)}
                isInfoMultiLine={true}
            />
            {companyDetail.billingAddress &&
                <LabelInfo
                    labelKey={'administration.company_info.general.company_data.fields.billing_address'}
                    info={formatAddress(companyDetail.billingAddress)}
                    isInfoMultiLine={true}
                />
            }
        </div>
    );
}

function OpenEditPanelButton({ onClick }) {
    return (
        <ShowIfAllowed requiredAccessLevels={{ company: 'W' }}>
            <Button
                id="to_edit_company_data"
                onClick={onClick}
                typeName="text"
            >
                <span>
                    <Translate msg={'administration.company_info.general.company_data.actions.to_edit'} />
                </span>
            </Button>
        </ShowIfAllowed>
    );
}

function displayNaceInfo(companyDetail: ICompanyDetail) {
    return joinParts(
        [
            companyDetail.naceCode,
            companyDetail.naceDescription,
        ],
        ' - ',
    );
}

function CompanyDataFooter() {
    return (
        <SubmitButton
            id={`${FORM_NAME}_submit`}
            formName={FORM_NAME}
        >
            <Translate msg="administration.company_info.general.company_data.actions.edit_confirm" />
        </SubmitButton>
    );
}
