import { RoutesMap } from 'redux-first-router';
import { IRoutes } from './typings';

export default function getRoutesMap(routes: IRoutes): RoutesMap {
    return Object.keys(routes)
        .reduce(
            (accumulator, routeKey) => {
                const { path } = routes[routeKey];
                accumulator[routeKey] = { path };
                return accumulator;
            },
            {},
        );
}
