import React, { PureComponent } from 'react';

import { clearErrors } from '../../../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../../../models/general/error';
import {
    fetchEmployeeToAddInitialData,
    resetAddEmployeeData,
    updateAddEmployeeData,
} from '../../../../../../../redux/employee/wizard/actions';
import {
    getEmployeeToAddInitialData,
    getEmployeeToAddInitialDataError,
    getEmployeeToAddInitialDataIsFetching,
    isEmployeeToAddNotEmpty,
} from '../../../../../../../redux/employee/wizard/selectors';
import { IEmployeeToAdd } from '../../../../../../../models/admin/employee';

import Button from '../../../../../../common/buttons/Button';
import Checkbox from '../../../../../../common/input/Checkbox';
import Dialog from '../../../../../../common/modals/Dialog';
import FloatableTextInputWrapper from '../../../../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../../../../common/forms/Form';
import FormError from '../../../../../../common/forms/FormError';
import FormFieldError from '../../../../../../common/forms/FormFieldError';
import Loader from '../../../../../../common/waiting/Loader';
import SubmitButton from '../../../../../../common/buttons/SubmitButton';
import Translate from '../../../../../../common/Translate';
import TranslatorContext from '../../../../../../appShell/contexts/TranslatorContext';
import {
    fields,
    schema,
    // eslint-disable-next-line max-len
} from '../../../../../../administration/Employees/AddEmployee/steps/NationalRegisterNumber/NationalRegisterNumberSchema';
import { NationalRegisterNumberTextInput } from '../../../../../../common/input/NationalRegisterNumberInput';

import { connect } from '../../../../../..';

import './national-register-number.scss';

type FormValues = Pick<IEmployeeToAdd, 'nationalRegisterNumber'> & {
    isForeignEmployee: boolean,
};

type EmployeeDetails = Pick<IEmployeeToAdd,
    'address'
    | 'email'
    | 'employeeNumber'
    | 'firstName'
    | 'languageId'
    | 'mobilePhone'
    | 'name'
    | 'nationalityId'
    | 'nationalRegisterNumber'
    | 'phone'
    | 'sexId'
    | 'isForeignEmployee'
>;

interface IPrivateProps {
    clearError: (error: ITraceableApiError) => void;
    employeeToAddInitialData: Partial<IEmployeeToAdd>;
    error: ITraceableApiError;
    fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => void;
    isEmployeeToAddNotEmpty: boolean;
    isFetching: boolean;
    proceedAsForeignEmployee: (values: EmployeeDetails) => void;
    resetAddEmployeeData: () => void;
}

interface INationalRegisterNumberProps {
    onCloseIntent: () => void;
    onSuccess: () => void;
    show: boolean;
}

const CLASS_NAME = 'OnboardingWizardAddEmployeeNationalRegisterNumber';

const FORM_NAME = 'onboarding-wizard-add-employee-national-register-form';

const TRANSLATION_PREFIX = 'onboarding.wizard.steps.employees.add_employee.national_register_number';

class NationalRegisterNumber extends PureComponent<IPrivateProps & INationalRegisterNumberProps> {
    private resetForm: (values: FormValues) => void;

    constructor(props: IPrivateProps & INationalRegisterNumberProps) {
        super(props);

        this.onCloseIntent = this.onCloseIntent.bind(this);
        this.resetComponent = this.resetComponent.bind(this);
    }

    public render() {
        const {
            error,
            employeeToAddInitialData,
            fetchEmployeeDataByInsz,
            isEmployeeToAddNotEmpty,
            isFetching,
            onSuccess,
            proceedAsForeignEmployee,
            show,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            nationalRegisterNumber: '',
            isForeignEmployee: false,
        };

        function handleSubmit(values: FormValues) {
            if (values.isForeignEmployee) {
                proceedAsForeignEmployee({
                    address: null,
                    email: null,
                    employeeNumber: null,
                    firstName: null,
                    languageId: null,
                    mobilePhone: null,
                    name: null,
                    nationalityId: null,
                    nationalRegisterNumber: null,
                    phone: null,
                    sexId: null,
                    isForeignEmployee: true,
                });
                onSuccess();
                return;
            }
            fetchEmployeeDataByInsz(values.nationalRegisterNumber);
        }
        return (
            <TranslatorContext.Consumer>
                {({ translator }) => {
                    const nationalRegisterErrorMessageFieldName =
                    translator(`${TRANSLATION_PREFIX}.field_label`);

                    return (
                        <Dialog
                            className={CLASS_NAME}
                            show={show}
                            onCloseIntent={this.onCloseIntent}
                            header={`${TRANSLATION_PREFIX}.header`}
                        >
                            <Form
                                name={FORM_NAME}
                                className={`${CLASS_NAME}__form`}
                                handleSubmit={handleSubmit}
                                initialValues={INITIAL_VALUES}
                                schema={schema}
                                render={({
                                    errors,
                                    handleChange,
                                    resetForm,
                                    touched,
                                    values,
                                }: IFormRenderProps<FormValues>) => {
                                    if (!this.resetForm) {
                                        this.resetForm = resetForm;
                                    }

                                    return (
                                        <Loader show={isFetching}>
                                            <FloatableTextInputWrapper floatLabel>
                                                <NationalRegisterNumberTextInput
                                                    id="nationalRegisterNumber"
                                                    name={fields.nationalRegisterNumber}
                                                    value={values.nationalRegisterNumber}
                                                    onChange={handleChange}
                                                    isInvalid={touched.nationalRegisterNumber
                                                        && !!errors.nationalRegisterNumber
                                                        && !!values.isForeignEmployee}
                                                    disabled={values.isForeignEmployee}

                                                />
                                                <label htmlFor="nationalRegisterNumber">
                                                    <Translate
                                                        msg={`${TRANSLATION_PREFIX}.field_label`}
                                                    />
                                                </label>
                                                {touched.nationalRegisterNumber && (
                                                    <FormFieldError
                                                        error={errors.nationalRegisterNumber}
                                                        // eslint-disable-next-line max-len
                                                        placeholders={{ fieldName: nationalRegisterErrorMessageFieldName }}
                                                    />
                                                )}
                                                {
                                                    error &&
                                                    <FormError error={error} />
                                                }
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper>
                                                <Checkbox
                                                    name={fields.isForeignEmployee}
                                                    onChange={handleChange}
                                                    checked={values.isForeignEmployee}
                                                    disabled={
                                                        typeof values.nationalRegisterNumber === 'string'
                                                        && values.nationalRegisterNumber.trim().length > 0
                                                    }
                                                >
                                                    <Translate
                                                        // eslint-disable-next-line max-len
                                                        msg="administration.employees.add.steps.national_register_number.checkbox_foreign_employee_info"
                                                    />
                                                </Checkbox>
                                            </FloatableTextInputWrapper>
                                            { employeeToAddInitialData && !isEmployeeToAddNotEmpty &&
                                                <FormError
                                                    translationKey={`${TRANSLATION_PREFIX}.employee_found_error`}
                                                    placeHolders={{
                                                        linkTo:
                                                            <a
                                                                onClick={this.onCloseIntent}
                                                            >
                                                                <span className="placeholderLink">
                                                                    {/* eslint-disable-next-line max-len */}
                                                                    <Translate msg={`${TRANSLATION_PREFIX}.employee_found_error_link_to`} />
                                                                </span>
                                                            </a>,
                                                    }}
                                                />
                                            }
                                            <div className={`${CLASS_NAME}__actions`}>
                                                <Button
                                                    id="add-employee-national-register-number-cancel-button"
                                                    typeName="secondary"
                                                    outline={true}
                                                    onClick={this.onCloseIntent}
                                                >
                                                    <Translate msg={`${TRANSLATION_PREFIX}.form.cancel`} />
                                                </Button>
                                                <SubmitButton
                                                    id="add-employee-national-register-number-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    <Translate msg={`${TRANSLATION_PREFIX}.form.submit`} />
                                                </SubmitButton>
                                            </div>
                                        </Loader>
                                    );
                                }}
                            />
                        </Dialog>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidMount() {
        this.resetComponent();
    }

    public componentDidUpdate(prevProps: INationalRegisterNumberProps) {
        const { onSuccess, isEmployeeToAddNotEmpty } = this.props;

        if (isEmployeeToAddNotEmpty) {
            this.resetForm({
                nationalRegisterNumber: '',
                isForeignEmployee: false,
            });
            onSuccess();
        }
    }

    private onCloseIntent() {
        const { onCloseIntent } = this.props;
        this.resetComponent();
        onCloseIntent();
    }

    private resetComponent() {
        const {
            clearError,
            error,
            resetAddEmployeeData: resetFetchEmployeeByInsz,
        } = this.props;

        this.resetForm({
            nationalRegisterNumber: '',
            isForeignEmployee: false,
        });
        clearError(error);
        resetFetchEmployeeByInsz();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            isEmployeeToAddNotEmpty: isEmployeeToAddNotEmpty(state),
            isFetching: getEmployeeToAddInitialDataIsFetching(state),
            error: getEmployeeToAddInitialDataError(state),
            employeeToAddInitialData: getEmployeeToAddInitialData(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            // fetches employee by insz, only continues the flow when NOT found
            fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => {
                dispatch(fetchEmployeeToAddInitialData({ nationalRegisterNumber }));
            },
            resetAddEmployeeData: () => {
                dispatch(resetAddEmployeeData());
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
            proceedAsForeignEmployee: (values: EmployeeDetails) => {
                dispatch(updateAddEmployeeData(values));
            },
        };
    },
})(NationalRegisterNumber);
