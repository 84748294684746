import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import { createNotFoundError } from '../../../../utils/api/error/createNotFoundError';
import { DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS } from '../../../../api/interventions/medicalExaminations.api';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import { getRouteKeysThatBelongToGroup } from '../../../../routes';
import {
    IFetchPlannedMedicalExaminationDetailPayload,
    IPlannedMedicalExaminationsFilter,
} from '../../../../models/interventions/medicalExaminations';
import { REDUCER_KEYS } from '../../../../config/redux.config';
import { ROUTE_GROUP } from '../../../../config/routeGroup.config';
import Api from '../../../../api';
import ROUTE_KEYS from '../../../../routeKeys';
import { createEpic, IState } from '../../../index';
import { fetchEmployeeRisksAndResearches, fetchSmallEmployeeDetails } from '../../../employee/info/actions';
import { getEmployeeMedicalExaminations } from '../../../employee/info/selectors';
import { getLocale } from '../../../i18n/selectors';
import { getLocationState, getQueryParams } from '../../../location/selectors';
import { getSelectedCompanySeat, getSelectedSeatCompanyCode } from '../../../company/selected/selectors';
import {
    arePlannedMedicalExaminationsAvailable,
    getPlannedMedicalExaminationFromList,
    getSelectedPlannedMedicalExamination,
} from '../../selectors';
import * as MEDICAL_EXAMINATION_TYPES from '../../types';
import * as MEDICAL_EXAMINATION_ACTIONS from '../../actions';

const ACTION_TYPES_THAT_FETCH_PLANNED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE);

// fetchPlannedMedicalExaminationsForRoutesEpic
createEpic<{}, IPlannedMedicalExaminationsFilter>({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
    refreshDataIf: ({ getState, action }) => {
        if (!arePlannedMedicalExaminationsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    noDataRefreshOnlyInTheseReducers: [REDUCER_KEYS.MEDICALEXAMINATION],
    processReturn: ({ api, getState }) => {
        const state = getState();
        return fetchPlannedMedicalExaminations({
            api,
            getState,
            filter: getQueryParams(state) as IPlannedMedicalExaminationsFilter,
        });
    },
    latest: true,
});

// fetchPlannedMedicalExaminationsEpic
createEpic<IPlannedMedicalExaminationsFilter>({
    onActionType: MEDICAL_EXAMINATION_TYPES.FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    processReturn: ({ api, getState, action }) => {
        return fetchPlannedMedicalExaminations({
            api,
            getState,
            filter: action.payload,
        });
    },
    latest: false,
});

async function fetchPlannedMedicalExaminations({ api, getState, filter }:
    { api: typeof Api, getState, filter: IPlannedMedicalExaminationsFilter}) {
    try {
        const state = getState();

        const plannedMedicalExaminations = await doFetchPlannedMedExamsCall(api, state, filter);

        return MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationsActions.succeeded(plannedMedicalExaminations);
    } catch (error) {
        return MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationsActions.failed(error);
    }
}

function doFetchPlannedMedExamsCall(api: typeof Api, state: IState, filter: IPlannedMedicalExaminationsFilter) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
    const locale = getLocale(state);

    return api.interventions.medicalExaminations
        .fetchPlanned(
            companyCode,
            filter,
            showFullFamily,
            locale,
        );
}

// fetchPlannedMedicalExaminationDetailEpicIfNotAvailableEpic
createEpic<IFetchPlannedMedicalExaminationDetailPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_PLANNED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState, action }) => {
        const selectedExamination = getSelectedPlannedMedicalExamination(getState());

        if (!selectedExamination || selectedExamination.timeSlotId !== action.payload.timeSlotId) {
            return true;
        }

        return false;
    },
    processReturn({ action }) {
        return MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationDetail(action.payload);
    },
    latest: false,
});

// fetchPlannedMedicalExaminationDetailEpic
createEpic<IFetchPlannedMedicalExaminationDetailPayload>({
    onActionType: MEDICAL_EXAMINATION_TYPES.FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { id: employeeId } = action.payload;
            const state = getState();

            const timeSlotId = typeof action.payload.timeSlotId === 'string' ?
                Number(action.payload.timeSlotId) : action.payload.timeSlotId;

            let selectedPlannedMedicalExamination = getPlannedMedicalExaminationFromList(state, timeSlotId);

            if (!selectedPlannedMedicalExamination) {
                const filter: IPlannedMedicalExaminationsFilter = getQueryParams(state);
                const selectedEmployeeMedicalExaminations = getEmployeeMedicalExaminations(state);

                if (employeeId && selectedEmployeeMedicalExaminations.planned) {
                    const plannedExamination = selectedEmployeeMedicalExaminations.planned
                        .find((plannedExamination) => plannedExamination.timeSlotId === timeSlotId);

                    if (plannedExamination) {
                        filter.startDate = formatDateForBackend(plannedExamination.start);
                        filter.endDate = formatDateForBackend(plannedExamination.end);
                    }
                }

                const plannedMedicalExaminations = await doFetchPlannedMedExamsCall(api, state, filter);
                dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationsActions.succeeded(
                    plannedMedicalExaminations,
                ));

                selectedPlannedMedicalExamination = plannedMedicalExaminations
                    .find((item) => item.timeSlotId === timeSlotId);
            }

            if (selectedPlannedMedicalExamination) {
                const employeeCustomerId = selectedPlannedMedicalExamination.employee.employment.id;

                if (!employeeId && employeeCustomerId) {
                    // This is fetched by the employee details if the employee id is set on the route
                    dispatch(fetchSmallEmployeeDetails({ id: employeeCustomerId }));
                }

                // Fetch employee risks and researches
                if (employeeCustomerId) {
                    dispatch(fetchEmployeeRisksAndResearches({ id: employeeCustomerId }));
                }

                dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationDetailSucceeded(
                    selectedPlannedMedicalExamination,
                ));
            } else {
                dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationDetailFailed(createNotFoundError()));
            }

            return done();
        } catch (error) {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchPlannedMedicalExaminationDetailFailed(error));
            return done();
        }
    },
    latest: true,
});
