import isSet from '@snipsonian/core/es/is/isSet';
import isString from '@snipsonian/core/es/is/isString';
import { IColumnName } from './extractColumnNames';
import getPossiblyNestedObjectField from '../../core/object/getPossiblyNestedObjectField';

export const COLUMN_SEPARATOR = ';';
export const ROW_SEPARATOR = '\r\n';
const ROW_SEPARATOR_WITHIN_FIELD = '\n';

const REGEXP_FIELD_CONTAINING_ROW_SEPARATOR = new RegExp(ROW_SEPARATOR, 'g');
const REGEXP_WHEN_TO_SURROUND_FIELD_WITH_DOUBLE_QUOTES =
    new RegExp(`"|${COLUMN_SEPARATOR}|${ROW_SEPARATOR_WITHIN_FIELD}`); // = /"|;|\n/

export default function convertListDataItemsForCsv(listData: object[], columnNames: IColumnName[]): string[][] {
    return listData
        .map((dataRow) => convertDataColumnsToStringArray(dataRow, columnNames));
}

function convertDataColumnsToStringArray(dataRow: object, columnNames: IColumnName[]) {
    return columnNames
        .map((columnName) => {
            const field = getPossiblyNestedObjectField(dataRow, ...columnName.pathParts);

            if (!isSet(field)) {
                return '';
            }

            if (!isString(field)) {
                return field.toString();
            }

            return transformFieldValueIfNecessary(field);
        });
}

function transformFieldValueIfNecessary(fieldValue: string) {
    return [fieldValue]
        .map(replaceNewLines)
        .map(replaceDoubleQuotes)
        .map(surroundWithDoubleQuotesIfNecessary)
        .pop();
}

function replaceNewLines(fieldValue: string) {
    return fieldValue.replace(REGEXP_FIELD_CONTAINING_ROW_SEPARATOR, ROW_SEPARATOR_WITHIN_FIELD);
}

function replaceDoubleQuotes(fieldValue: string) {
    // to two double quotes (is for escaping double quotes according to csv specs)
    return fieldValue.replace(/"/g, '""');
}

function surroundWithDoubleQuotesIfNecessary(fieldValue: string) {
    if (fieldValue.search(REGEXP_WHEN_TO_SURROUND_FIELD_WITH_DOUBLE_QUOTES) > -1) {
        return `"${fieldValue}"`;
    }

    return fieldValue;
}
