export default function formatRszNumber(rszNr: string) {
    if (!rszNr) {
        return '';
    }

    return [rszNr]
        .map(stripExistingFormatting)
        .map((unformatted) => formatIfRequiredLength(unformatted, rszNr))
        .pop();
}

function stripExistingFormatting(rszNr: string) {
    return rszNr
        .trim()
        .replace(/\./g, '')
        .replace(/ /g, '');
}

function formatIfRequiredLength(unformatted: string, original: string) {
    if (unformatted.length !== 12) {
        return original;
    }

    return unformatted.substr(0, 3)
        + '.' + unformatted.substr(3, 7)
        + '.' + unformatted.substr(10, 2);
}
