import React from 'react';
import Translate from '../../common/Translate';
import illustration from '../../assets/img/illustrations/illu_404.img.svg';
import './no-access.scss';

const CLASS_NAME = 'NoAccess';

const NoAccess = () => (
    <div className={CLASS_NAME}>
        <div className={`${CLASS_NAME}__content`}>
            <div className="container">
                <h1><Translate msg="error.no_access.title" /></h1>
                <p><Translate msg="error.no_access.description" /></p>
            </div>
        </div>
        <figure className={`${CLASS_NAME}__figure`} style={{ backgroundImage: `url(${illustration})` }} />
    </div>
);

export default NoAccess;
