import React, { PureComponent, ReactElement } from 'react';
import classNames from 'classnames';
import { MixedSchema } from 'yup';
import { AsyncStatus, IAction } from '../../../../models/general/redux';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import { IState, NO_RERENDER } from '../../../../redux';
import { connect } from '../../../index';
import { WIZARDFLOW_CLASSES } from '../Wizard';
import Form, { IFormRenderProps, TSetFieldValue } from '../../forms/Form';
import Loader from '../../waiting/Loader';
import PageHeader from '../../../appShell/PageHeader';
import StickyFooter from '../../widget/StickyFooter';
import { TPlaceholders } from '../../Translate';

export interface IRenderStepFormContentProps<FormValues> {
    formRenderProps: IFormRenderProps<FormValues>;
    baseClassName: string;
    onChangeInput: (values: FormValues) => void;
}

interface IFormValuesActionCreatorProps<FormValues> {
    values: FormValues;
    publicProps?: IPublicProps<FormValues>;
    getState?: () => IState;
}

type TFormValuesActionCreator<FormValues> =
    (actionCreatorProps: IFormValuesActionCreatorProps<FormValues>) => IAction<{}>;

interface IPublicProps<FormValues> {
    baseClassName: string;
    header: {
        titleTranslationKey: string;
        titlePlaceholders?: (state: IState) => TPlaceholders;
        textTranslationKey?: string | ((state: IState) => string);
        textPlaceholders?: (state: IState) => TPlaceholders;
    };
    showLoaderSelector: (state: IState) => boolean | AsyncStatus;
    form: {
        name: string;
        schema: MixedSchema;
        initialValuesSelector: (state: IState) => FormValues;
        renderStepFormContent: (renderProps: IRenderStepFormContentProps<FormValues>) => ReactElement<{}>;
        onChangeInputActionCreator?: TFormValuesActionCreator<FormValues>;
    };
    stepButtons?: {
        onConfirmActionCreator?: TFormValuesActionCreator<FormValues>;
        disbaleGoToNextStepByDefault?: boolean;
    };
}

interface IPrivateProps<FormValues> {
    initialValues: FormValues;
    showLoader: boolean | AsyncStatus;
    onChangeInput: (values: FormValues) => void;
    onConfirm: (values: FormValues) => void;
    headerTitlePlaceholders: {} | TPlaceholders;
    headerTextPlaceholders: {} | TPlaceholders;
    headerTextTranslationKey: string;
}

class WizardFormStep<FormValues extends object>
    extends PureComponent<IStepperStepRenderProps & IPrivateProps<FormValues> & IPublicProps<FormValues>> {

    private setFormFieldValue: TSetFieldValue<FormValues>;

    constructor(props: IStepperStepRenderProps & IPrivateProps<FormValues> & IPublicProps<FormValues>) {
        super(props);

        this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
        this.onConfirmHandler = this.onConfirmHandler.bind(this);
    }

    public render() {
        const {
            baseClassName, header, form,
            showLoader,
            initialValues,
            renderStepButtons,
            headerTitlePlaceholders, headerTextPlaceholders,
            headerTextTranslationKey,
        } = this.props;

        return (
            <>
                <PageHeader
                    title={header.titleTranslationKey}
                    titlePlaceholders={headerTitlePlaceholders}
                    text={headerTextTranslationKey}
                    isTextRaw
                    textPlaceholders={headerTextPlaceholders}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, baseClassName)}>
                    <Form
                        name={form.name}
                        handleSubmit={this.onConfirmHandler}
                        initialValues={initialValues}
                        schema={form.schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            this.setFormFieldValue = formRenderProps.setFieldValue;

                            return (
                                <>
                                    <Loader show={showLoader} />
                                    {form.renderStepFormContent({
                                        formRenderProps,
                                        baseClassName,
                                        onChangeInput: this.onChangeInputHandler,
                                    })}
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: form.name,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }

    private onChangeInputHandler(values: FormValues) {
        Object.keys(values)
            .forEach((fieldName) => {
                this.setFormFieldValue(fieldName as keyof FormValues, values[fieldName]);
            });

        this.props.onChangeInput(values);
    }

    private onConfirmHandler(values: FormValues) {
        const { onConfirm, goToNextStep, stepButtons } = this.props;
        const preventGoToNextStep = stepButtons && stepButtons.disbaleGoToNextStepByDefault;

        onConfirm(values);
        if (!preventGoToNextStep) {
            goToNextStep();
        }
    }
}

export default function GenericWizardFormStep<FormValues extends object>() {
    return connect<IPrivateProps<FormValues>, IPublicProps<FormValues>>({
        statePropsPerInstance: (state, publicProps) => {
            return (state) => {
                return {
                    initialValues: publicProps.form.initialValuesSelector(state),
                    showLoader: publicProps.showLoaderSelector(state),
                    headerTitlePlaceholders: publicProps.header.titlePlaceholders ?
                        publicProps.header.titlePlaceholders(state) : NO_RERENDER.EMPTY_OBJECT,
                    headerTextPlaceholders: publicProps.header.textPlaceholders ?
                        publicProps.header.textPlaceholders(state) : NO_RERENDER.EMPTY_OBJECT,
                    headerTextTranslationKey: typeof publicProps.header.textTranslationKey === 'function'
                        ? publicProps.header.textTranslationKey(state)
                        : publicProps.header.textTranslationKey,
                };
            };
        },
        dispatchPropsDeprecated: (dispatch, getState, publicProps) => ({
            onChangeInput: (values: FormValues) => {
                if (publicProps.form.onChangeInputActionCreator) {
                    const actionToDispatch = publicProps.form.onChangeInputActionCreator({
                        values,
                        publicProps,
                        getState,
                    });
                    if (actionToDispatch) {
                        dispatch(actionToDispatch);
                    }
                }
            },
            onConfirm: (values: FormValues) => {
                if (publicProps.stepButtons && publicProps.stepButtons.onConfirmActionCreator) {
                    const actionToDispatch = publicProps.stepButtons.onConfirmActionCreator({
                        values,
                        publicProps,
                        getState,
                    });
                    if (actionToDispatch) {
                        dispatch(actionToDispatch);
                    }
                }
            },
        }),
    })(WizardFormStep as new () => WizardFormStep<FormValues>);
}
