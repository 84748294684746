import {
    FETCH_WORK_ACCIDENTS, FETCH_WORK_ACCIDENT_FILES,
} from './types';
import { createTypeActions } from '../../../utils/libs/redux/createAction';
import {
    IFetchWorkAccidentsPayload, IWorkAccident,
    IFetchWorkAccidentDocumentPayload,
} from '../../../models/interventions/workAccidents';
import { IDocument } from '../../../models/general/documents';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';

// Fetch work accidents.
export const fetchWorkAccidentsActions = createTypeActions<IFetchWorkAccidentsPayload, IWorkAccident[]>({
    type: FETCH_WORK_ACCIDENTS,
});

// Work accidents document download
export const fetchWorkAccidentsFilesActions =
    createTypeActions<IFetchWorkAccidentDocumentPayload, IDocument>({
        type: FETCH_WORK_ACCIDENT_FILES,
        onSuccess: (payload: IDocument) => {
            presentDownloadedFile(payload);
        },
    });

