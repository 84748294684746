import React from 'react';
import './course-session-by-month.scss';
import { concatenateStringList } from '../../../../../../../../utils/core/string/separatedStringList';
import { ICourseSessions, ICourseSession } from '../../../../../../../../models/documentCenter/courses';
import { ListItem } from '../../../../../../../../models/general/list';
import Translate from '../../../../../../../common/Translate';
import { IColumnNames, CLASS_NAME, TRANSLATION_PREFIX } from './common';
import CourseSessionsList from './list';

export default function CourseSessionByMonth({ title, data, noSessionsMessage }: {
    title: string;
    data: ICourseSessions;
    noSessionsMessage?: string;
}) {
    const totalSessionsThisMonth = Object.keys(data).length;
    return (
        <div className={CLASS_NAME}>
            <h3 className={`${CLASS_NAME}__title`}>{title}</h3>
            {totalSessionsThisMonth > 0 ? (
                <CourseSessionsList items={mapSessionsToListItems(data)} />
            ) : (
                <NoSessionsMessage msg={noSessionsMessage} />
            )}
        </div>
    );
}

const NoSessionsMessage = ({ msg }: { msg?: string }) => (
    <div className={`${CLASS_NAME}__no-sessions`}>
        <Translate msg={msg || `${TRANSLATION_PREFIX}.no_sessions`} />
    </div>
);

function mapSessionsToListItems(
    sessions: ICourseSessions,
): ListItem<IColumnNames, string, ICourseSession>[] {
    return Object.keys(sessions)
        .map((sessionId) => sessions[sessionId])
        .reduce((accumulator: ListItem<IColumnNames, string, ICourseSession>[], sessions) => {
            const firstDaySession = sessions.find((session) => session.dayNumber === 1);

            if (firstDaySession) {
                const dates = sessions
                    .map((session) => `${session.start}.${session.stop}`);

                const item: ListItem<IColumnNames, string, ICourseSession> = {
                    id: `session-${firstDaySession.coursesOrganizedId}`,
                    columns: {
                        dateSort: firstDaySession.start,
                        dates: concatenateStringList(dates),
                        language: firstDaySession.language,
                        locationName: firstDaySession.address.name,
                        addressPostCode: firstDaySession.address.postcode,
                        addressCity: firstDaySession.address.city,
                        addressStreet: firstDaySession.address.street,
                        addressNumber: firstDaySession.address.number,
                        freeSlots: Math.max(
                            (firstDaySession.maxAmount - firstDaySession.attendees),
                            0,
                        ),
                        maxAmount: firstDaySession.maxAmount,
                        vacant: firstDaySession.vacant,
                        dateOfFirstDay: '',
                        addressDisplay: '',
                        availability: null,
                    },
                    data: firstDaySession,
                };

                accumulator.push(item);
            }

            return accumulator;
        }, []);
}

