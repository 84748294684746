export interface ICmsWebAppMessages {
    welcomeMessage?: string;
}

/** The values of this enum are also used as translation keys, so don't just change them! */
export enum IGreeting {
    MORNING = 'morning',
    AFTERNOON = 'afternoon',
    EVENING = 'evening',
    NIGHT = 'night',
}
