import { createTypeActions } from '../../../utils/libs/redux/createAction';
import {
    IFetchActivityReportsPayload,
    IActivityReport,
    IGenerateActivityReportPayload,
    IGenerateCompanyAndEmployeeReportPayload, IDocument, IFetchActivityReportDetailPayload,
} from '../../../models/general/documents';
import {
    FETCH_ACTIVITY_REPORTS,
    GENERATE_ACTIVITY_REPORT,
    GENERATE_COMPANY_AND_EMPLOYEE_REPORT,
    FETCH_ACTIVITY_REPORT_DETAIL,
} from './types';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';

// Activity reports

export const fetchActivityReportsActions =
    createTypeActions<IFetchActivityReportsPayload, IActivityReport[]>({
        type: FETCH_ACTIVITY_REPORTS,
    });

export const generateActivityReportActions =
    createTypeActions<IGenerateActivityReportPayload>({
        type: GENERATE_ACTIVITY_REPORT,
    });

// Company and employee reports

export const generateCompanyAndEmployeeReportActions =
    createTypeActions<IGenerateCompanyAndEmployeeReportPayload>({
        type: GENERATE_COMPANY_AND_EMPLOYEE_REPORT,
    });

// Activity report detail
export const fetchActivityReportDetailActions =
    createTypeActions<IFetchActivityReportDetailPayload, IDocument>({
        type: FETCH_ACTIVITY_REPORT_DETAIL,
        onSuccess: (payload) => {
            presentDownloadedFile(payload);
        },
    });
