import React from 'react';
import './course-details.scss';
import { ICMSCourse } from '../../../../../models/documentCenter/courses';
import isEmptyString from '../../../../../utils/core/string/isEmptyString';
import Translate from '../../../../common/Translate';
import { formatFloat } from '../../../../../utils/formatting/formatFloat';
import { isCourseWithCustomPrice } from '../courseUtils';
import Icon from '../../../../common/icons/Icon';
import LearningGoalIcon from '../LearningGoalIcon';

const CLASS_NAME = 'CourseDetails';
const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.course_details';

interface IPublicProps {
    course: ICMSCourse;
    hidePE: boolean;
}

export function CourseDetails(props: IPublicProps) {
    const { course, hidePE } = props;
    const isCustomPrice = isCourseWithCustomPrice(course);

    return (
        <div className={CLASS_NAME}>
            <div className="container">
                <div className={`${CLASS_NAME}__inner`}>
                    <div className={`${CLASS_NAME}__details`}>
                        <h4><Translate msg={`${TRANSLATION_PREFIX}.list.title`} /></h4>
                        <ul className={`${CLASS_NAME}__details__list`}>
                            <li>
                                <Icon typeName="time" colorType="secondary" />
                                {(course.duration && !isEmptyString(course.duration)) ? (
                                    course.duration
                                ) : (
                                    <Translate msg={`${TRANSLATION_PREFIX}.list.duration_open`} />
                                )}
                            </li>
                            <li>
                                <Icon typeName="euro" colorType="secondary" />
                                {!isCustomPrice ? (
                                    <Translate
                                        msg={`${TRANSLATION_PREFIX}.list.price${hidePE ? '_no_pe' : ''}`}
                                        placeholders={{
                                            price: formatFloat(course.price),
                                            pe: formatFloat(course.pe),
                                        }}
                                    />
                                ) : (
                                    <Translate msg={`${TRANSLATION_PREFIX}.list.custom_price`} />
                                )}
                            </li>
                            <li>
                                <Icon typeName="text-balloons" colorType="secondary" />
                                {course.languages.join(', ')}
                            </li>
                            {(course.learningMethods && course.learningMethods.length > 0) && (
                                <li>
                                    <Icon typeName="graduation-hat" colorType="secondary" />
                                    {course.learningMethods.map((method) => method.name).join(', ')}
                                </li>
                            )}
                            {course.learningGoal && (
                                <li>
                                    <LearningGoalIcon colour={course.learningGoal.colour} />
                                    {course.learningGoal.name || course.learningGoal}
                                </li>
                            )}
                            {(course.courseCertificates && course.courseCertificates.length > 0) && (
                                <li>
                                    <Icon typeName="certificate" colorType="secondary" />
                                    {course.courseCertificates.map((certificate) => certificate.name).join(', ')}
                                </li>
                            )}
                            {(course.financingMethods && course.financingMethods.length > 0) && (
                                <li>
                                    <Icon typeName="financing" colorType="secondary" />
                                    {course.financingMethods.map((method) => method.name).join(', ')}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={`${CLASS_NAME}__description`}>
                        <p>{course.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
