import React, { ReactNode, MouseEvent } from 'react';
import { pathOr } from 'ramda';

import {
    IMedicalExaminationToAdd,
} from '../../../../../../models/interventions/medicalExaminations';
import { ListItem } from '../../../../../../models/general/list';
import Translate from '../../../../../common/Translate';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../../../utils/formatting/formatTime';
import ListItemActions from '../../../../../common/list/ListItemActions';
import Icon from '../../../../../common/icons/Icon';
import { getMedExamToAddId } from '../../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import Tooltip from '../../../../../common/widget/Tooltip';

import { IAutomaticValidateColumnNames, TEditActionTranslationKey } from './AutomaticValidate.type';
import { CLASS_NAME } from './AutomaticValidate.const';

export const renderEditActionHeader = (
    onClick: (e: MouseEvent<HTMLElement>, listItems: ListItem<IAutomaticValidateColumnNames>[]) => void,
    key: TEditActionTranslationKey,
    listItems: ListItem<IAutomaticValidateColumnNames>[],
): ReactNode => (
        (
            listItemsNotEmptyAndNoPlannedAppointments(listItems) ? (
                <ListItemActions>
                    <Tooltip
                        placement="top-start"
                        target={
                            <div
                                className="Button Button--text ListActionButton"
                                onClick={(e) => onClick(e, listItems)}
                            >
                                <Icon typeName="pencil" />
                            </div>}
                        typeName="info-bubble"
                        spaceBetweenTarget={15}
                    >
                        <div>
                            <Translate
                                // eslint-disable-next-line max-len
                                msg={`interventions.medical_examinations.new.steps.validate_auto_plan.action_labels.edit_${key}`}
                            />
                        </div>
                    </Tooltip>
                </ListItemActions>
            ) : null
        )
    );

export const listItemsNotEmptyAndNoPlannedAppointments = (listItems: ListItem<IAutomaticValidateColumnNames>[]) => {
    return listItems.length > 0 && !listItems.find((item) => !item.columns.errorMessage);
};

export function renderWarningIcon(item: ListItem<IAutomaticValidateColumnNames>) {
    if (!item.columns.errorMessage) {
        return <span />;
    }

    return (
        <div className={`${CLASS_NAME}__list__warning`}>
            <Icon typeName="warning" circle colorType="warning" />
        </div>
    );
}

export const mapExams = (
    medicalExaminationsToAdd: IMedicalExaminationToAdd[],
): ListItem<IAutomaticValidateColumnNames>[] => {
    return medicalExaminationsToAdd.map((exam) => {
        const day = exam && exam.timeSlot ?
            formatDateInLongFormat(exam.timeSlot.start) : '-';

        const location = pathOr('-', ['location', 'name'], exam);

        const startHour = exam && exam.timeSlot ?
            formatTimeOfDateForDisplay(exam.timeSlot.start) : '-';

        return {
            id: getMedExamToAddId(exam),
            columns: {
                employee: formatPersonNameFormal({
                    firstName: exam.employee.firstName,
                    name: exam.employee.name,
                }),
                day,
                location,
                startHour,
                errorMessage: exam.errorMessage,
            },
        } as ListItem<IAutomaticValidateColumnNames>;
    });
};
