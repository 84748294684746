import { IPerson } from '../../models/admin/employee';

export const NO_PERSON_NAME = '-';

export function formatPersonName(person: IPerson) {
    if (!person || (!person.firstName && !person.name)) {
        return NO_PERSON_NAME;
    }
    return `${person.firstName || ''} ${person.name || ''}`;
}

export function formatPersonNameFormal(person: IPerson) {
    if (!person || (!person.firstName && !person.name)) {
        return NO_PERSON_NAME;
    }
    return `${person.name || ''} ${person.firstName || ''}`;
}
