import React from 'react';
import classNames from 'classnames';
import './notifications-bar.scss';
import Toast from '../../common/widget/Toast';
import { connect } from '../../index';
import {
    hasErrorNotifications,
    hasNewVersionNotifications,
    getCustomNotifications,
    getNotificationIdsByType,
} from '../../../redux/notifications/selectors';
import { dismissNotifications } from '../../../redux/notifications/actions';
import connectionErrorIllu from '../../assets/img/illustrations/svg_verbindingsfout.svg';
import { ITranslator } from '../../../models/general/i18n';
import Translate from '../../common/Translate';
import TranslatorContext from '../contexts/TranslatorContext';
import { ICustomNotification, NotificationType } from '../../../models/general/notification';
import InlineSVG from '../../common/icons/InlineSVG';

interface IPrivateProps {
    hasErrorNotifications: boolean;
    hasNewVersionNotification: boolean;
    customNotifications: ICustomNotification[];
    dismissErrorNotifications: () => void;
    dismissNewVersionNotifications: () => void;
    dismissCustomNotification: (notificationId: string) => void;
}

interface IContextProps {
    translator: ITranslator;
}

const CLASS_NAME = 'NotificationsBar';

function NotificationsBar(props: IPrivateProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <>
                    <ErrorNotification {...props} translator={translator} />
                    <NewVersionNotification {...props} translator={translator} />
                    <CustomNotifications {...props} translator={translator} />
                </>
            )}
        </TranslatorContext.Consumer>
    );
}

function ErrorNotification(props: IPrivateProps & IContextProps) {
    return (
        <Toast
            show={props.hasErrorNotifications}
            autoHide={true}
            onCloseIntent={props.dismissErrorNotifications}
            height={125}
        >
            <div className={classNames(CLASS_NAME, `${CLASS_NAME}__error`)}>
                <InlineSVG svg={connectionErrorIllu} />
                <h1>
                    <Translate msg="app_shell.notifications.error.title" />
                </h1>
                <p>
                    <Translate msg="app_shell.notifications.error.message" />
                </p>
            </div>
        </Toast>
    );
}

function NewVersionNotification(props: IPrivateProps & IContextProps) {
    return (
        <Toast
            show={props.hasNewVersionNotification}
            autoHide={false}
            onCloseIntent={props.dismissNewVersionNotifications}
        >
            <div className={classNames(CLASS_NAME, `${CLASS_NAME}__new-version`)}>
                <h1>
                    <Translate msg="app_shell.notifications.new_version.title" />
                </h1>
                <p>
                    <Translate msg="app_shell.notifications.new_version.message" />
                </p>
            </div>
        </Toast>
    );
}

function CustomNotifications({ customNotifications, dismissCustomNotification }: IPrivateProps & IContextProps) {
    if (!customNotifications || customNotifications.length === 0) {
        return null;
    }

    return (
        <>
            {customNotifications
                .map((customNotification) => {
                    return (
                        <Toast
                            key={`notification-custom-${customNotification.id}`}
                            show={true}
                            autoHide={customNotification.payload.autoHide}
                            onCloseIntent={() => dismissCustomNotification(customNotification.id)}
                        >
                            <div className={classNames(CLASS_NAME, `${CLASS_NAME}__custom`)}>
                                <h1>
                                    <Translate msg={customNotification.payload.titleTranslationKey} />
                                </h1>
                                <p>
                                    <Translate msg={customNotification.payload.messageTranslationKey} />
                                </p>
                            </div>
                        </Toast>
                    );
                })}
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            hasErrorNotifications: hasErrorNotifications(state),
            hasNewVersionNotification: hasNewVersionNotifications(state),
            customNotifications: getCustomNotifications(state) as ICustomNotification[],
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            dismissErrorNotifications: () => {
                const notificationIds = getNotificationIdsByType(getState(), NotificationType.Error);
                if (notificationIds.length > 0) {
                    dispatch(dismissNotifications(notificationIds));
                }
            },
            dismissNewVersionNotifications: () => {
                const notificationIds = getNotificationIdsByType(getState(), NotificationType.NewVersion);
                if (notificationIds.length > 0) {
                    dispatch(dismissNotifications(notificationIds));
                }
            },
            dismissCustomNotification: (notificationId: string) => {
                dispatch(dismissNotifications([notificationId]));
            },
        };
    },
})(NotificationsBar);
