import { connect as reactReduxConnect, Dispatch, MapStateToPropsParam } from 'react-redux';
import { IState } from '../../../redux';
import { getStore } from '../../../redux/storeNoCircularDependencies';

type TMapStateToPropsOfParticularComponentInstance<PrivateProps, PublicProps> =
    (state: IState) => Partial<PrivateProps>;

type TMapStateToPropsOfParticularComponentInstanceDeprecated<PrivateProps, PublicProps> =
    (state: IState, publicProps?: PublicProps) => Partial<PrivateProps>;

type TMapDispatchToPropsOfParticularComponentInstance<PrivateProps, PublicProps> =
    (dispatch: Dispatch) => Partial<PrivateProps>;

type TMapDispatchToPropsOfParticularComponentInstanceDeprecated<PrivateProps, PublicProps> =
    (dispatch: Dispatch, publicProps?: PublicProps) => Partial<PrivateProps>;

export interface IMapPrivateProps<PrivateProps, PublicProps = {}> {
    stateProps?: (state: IState) => Partial<PrivateProps>;
    /**
     * See dispatchPropsPerInstance.
     */
    statePropsPerInstance?: (state: IState, publicProps?: PublicProps) =>
        TMapStateToPropsOfParticularComponentInstance<PrivateProps, PublicProps>;
    /**
     * Using publicProps is slower because this causes a re-render
     * each time those props change, but in case of stateProps this is not
     * so easily avoidable (but you can try returning each time the same props).
     * It's still a separate function so that all the other components that don't need the publicProps
     * will not have the re-render issue.
     */
    statePropsDeprecated?: (state: IState, publicProps: PublicProps) =>
        Partial<PrivateProps> | TMapStateToPropsOfParticularComponentInstanceDeprecated<PrivateProps, PublicProps>;
    dispatchProps?: (dispatch: Dispatch, getState?: () => IState) => Partial<PrivateProps>;
    /**
     * You can use this if the publicProps don't change during the lifecycle of the component,
     * but when you do need them, e.g. when a selector or actionCreator was passed via a public prop.
     * This returns a function (instead of an object) without publicProps as input,
     * resulting in less re-renders.
     */
    dispatchPropsPerInstance?: (dispatch: Dispatch, getState?: () => IState, publicProps?: PublicProps) =>
        TMapDispatchToPropsOfParticularComponentInstance<PrivateProps, PublicProps>;
    /**
     * Using publicProps is kinda deprecated because this causes a re-render
     * each time those props change (even if you're not using them in mapDispatchToProps).
     * If needed, as a workaround, try passing the publicProp as a parameter to the
     * dispatchProp function.
     * Also see https://spin.atomicobject.com/2018/04/02/redux-rerendering/
     */
    dispatchPropsDeprecated?: (dispatch: Dispatch, getState: () => IState, publicProps: PublicProps) =>
        Partial<PrivateProps> | TMapDispatchToPropsOfParticularComponentInstanceDeprecated<PrivateProps, PublicProps>;
}

export default function connect<PrivateProps, PublicProps = {}>(
    mapPrivateProps?: IMapPrivateProps<PrivateProps, PublicProps>,
) {
    function mapStateToProps(state: IState) {
        return mapPrivateProps.stateProps(state);
    }

    function mapStateToPropsPerInstance(state: IState, publicProps: PublicProps) {
        return mapPrivateProps.statePropsPerInstance(state, publicProps);
    }

    function mapStateToPropsDeprecated(state: IState, publicProps: PublicProps) {
        return mapPrivateProps.statePropsDeprecated(state, publicProps);
    }

    function mapDispatchToProps(dispatch: Dispatch) {
        const store = getStore();
        return mapPrivateProps.dispatchProps(dispatch, store.getState);
    }

    function mapDispatchToPropsPerInstance(dispatch: Dispatch, publicProps: PublicProps) {
        const store = getStore();
        return mapPrivateProps.dispatchPropsPerInstance(dispatch, store.getState, publicProps);
    }

    function mapDispatchToPropsDeprecated(dispatch: Dispatch, publicProps: PublicProps) {
        const store = getStore();
        return mapPrivateProps.dispatchPropsDeprecated(dispatch, store.getState, publicProps);
    }

    return reactReduxConnect<Partial<PrivateProps>, Partial<PrivateProps>, PublicProps>(
        mapPrivateProps.stateProps
            ? mapStateToProps
            : mapPrivateProps.statePropsPerInstance
                ? mapStateToPropsPerInstance
                : mapPrivateProps.statePropsDeprecated
                    ? mapStateToPropsDeprecated as MapStateToPropsParam<PrivateProps, PublicProps, IState>
                    : undefined,
        mapPrivateProps.dispatchProps
            ? mapDispatchToProps
            : mapPrivateProps.dispatchPropsPerInstance
                ? mapDispatchToPropsPerInstance
                : mapPrivateProps.dispatchPropsDeprecated
                    ? mapDispatchToPropsDeprecated
                    : undefined,
    );
}
