import React, { ReactNode } from 'react';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import PageHeader from '../../appShell/PageHeader';
import ROUTE_KEYS from '../../../routeKeys';
import { ITabItem } from '../../../models/general/navigation';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import { connect } from '../../index';
import { createSelector } from 'reselect';
import { getCompanySituationHistory } from '../../../redux/preventionUnits/selectors';
import { isCompanySituationHistoryValid } from '../../../redux/preventionUnits/companySituationHistoryUtils';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const CLASS_NAME = 'Rates';

const TABS_ALWAYS: ITabItem[] = [{
    translationKey: 'administration.rates.standard.tab_title',
    linkTo: ROUTE_KEYS.R_COMPANY_RATES,
}];
/**
 * Interims don't work with PE's or with flat fee, so also don't have a situation history.
 * [MENSKLNTZN-3355]
 */
const TABS_IF_VALID_SITUATION_HISTORY: ITabItem[] = [{
    translationKey: 'administration.rates.parameters.tab_title',
    linkTo: ROUTE_KEYS.R_COMPANY_RATES_PARAMETERS,
}];

const getAllowedTabItems = createSelector(
    // we re-calculate the allowed tab items when the situation history changes
    getCompanySituationHistory,
    (companySituationHistory) => {
        if (isCompanySituationHistoryValid(companySituationHistory)) {
            return TABS_ALWAYS
                .concat(TABS_IF_VALID_SITUATION_HISTORY);
        }

        return TABS_ALWAYS;
    },
);

interface IPublicProps {
    children: ReactNode;
}

interface IPrivateProps {
    allowedTabs: ITabItem[];
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => ({
        allowedTabs: getAllowedTabItems(state),
    }),
})(RatesTemplate);

function RatesTemplate(props: IPublicProps & IPrivateProps) {
    const {
        allowedTabs,
        children,
    } = props;

    const MATCHING_ADMIN_ITEM = ADMINISTRATION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_COMPANY_RATES;
    });

    const lazyLoadSvgId = MATCHING_ADMIN_ITEM
        ? MATCHING_ADMIN_ITEM.detailLazyLoadSvgId || MATCHING_ADMIN_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className={`${CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    tabs={allowedTabs}
                    title="administration.rates.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.ADMINISTRATION,
                    }}
                    type="green"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
