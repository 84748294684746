import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { REDUCER_KEYS } from '../../config/redux.config';
import { IAsyncFetchField, IAsyncDoField } from '../../models/general/redux';
import {
    TCompanySituationHistory,
    IAccomplishmentsContribution,
    IFetchAccomplishmentsContributionsOfYearPayload,
    IAccomplishmentsContributionsOfYear,
    IFetchAccomplishmentsContributionDetailsPayload,
    IAccomplishmentsContributionDetail,
    IPreventionUnits,
    IPreventionUnitTask,
    ICompanySituationHistorySuccessPayload,
    TPreventionUnitsGroupType,
    IPreventionUnitsResponse,
} from '../../models/admin/preventionUnits';
import {
    FETCH_COMPANY_SITUATION_HISTORY,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
    FETCH_PREVENTION_UNITS,
    FETCH_PREVENTION_UNITS_DETAIL,
    EXPORT_PREVENTION_UNITS,
} from './types';
import {
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
    getAsyncDoInitialState,
    createActionHandler,
} from '../index';
import { registerReducer } from '../../utils/libs/redux/registerReducer';
import ROUTE_KEYS from '../../routeKeys';
import { fromAccomplishmentsContributionDetailId } from './actions';

export const reducerKey = REDUCER_KEYS.PREVENTION_UNITS;

export interface IReducerState {
    companySituationHistory: IAsyncFetchField<TCompanySituationHistory>;
    showPreviousYearUntilDate: string;
    selectedFlatFeeYear: string; // needs to be a string instead of a number otherwise not kept after page refresh
    accomplishmentsContributionsOfSelectedYear: IAsyncFetchField<IAccomplishmentsContribution[]>;
    accomplishmentsContributionDetails: IAsyncFetchField<IAccomplishmentsContributionDetail[]>;
    preventionUnits: IAsyncFetchField<IPreventionUnits[]>;
    preventionUnitsDetail: IAsyncFetchField<IPreventionUnitTask[]>;
    exportPreventionUnits: IAsyncDoField;
    preventionUnitsGroupType: TPreventionUnitsGroupType;
}

const initialState: IReducerState = {
    companySituationHistory: getAsyncFetchInitialState(),
    showPreviousYearUntilDate: null,
    selectedFlatFeeYear: null,
    accomplishmentsContributionsOfSelectedYear: getAsyncFetchInitialState(),
    accomplishmentsContributionDetails: getAsyncFetchInitialState(),
    preventionUnits: getAsyncFetchInitialState(),
    preventionUnitsDetail: getAsyncFetchInitialState(),
    exportPreventionUnits: getAsyncDoInitialState(),
    preventionUnitsGroupType: null,
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<TCompanySituationHistory, IReducerState, ICompanySituationHistorySuccessPayload>({
        baseActionType: FETCH_COMPANY_SITUATION_HISTORY,
        fieldName: 'companySituationHistory',
        resetDataOnTrigger: false,
        reducerKey,
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as ICompanySituationHistorySuccessPayload;
            return {
                ...oldState,
                companySituationHistory: {
                    ...oldState.companySituationHistory,
                    data: successPayload.situationHistory,
                },
                showPreviousYearUntilDate: successPayload.showPreviousYearUntilDate,
            };
        },
    }),
    // eslint-disable-next-line max-len
    ...createAsyncFetchActionHandlers<IAccomplishmentsContribution[], IReducerState, IAccomplishmentsContributionsOfYear>({
        baseActionType: FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
        fieldName: 'accomplishmentsContributionsOfSelectedYear',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
            FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
        ],
        resetDataOnTrigger: true,
        transformStateOnTrigger: ({ oldState, payload }) => {
            const triggerPayload = payload as IFetchAccomplishmentsContributionsOfYearPayload;

            if (!triggerPayload.year) {
                return oldState;
            }

            return {
                ...oldState,
                selectedFlatFeeYear: triggerPayload.year.toString(),
            };
        },
        mapSuccessPayload: (payload) => payload.accomplishmentsContributions,
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IAccomplishmentsContributionsOfYear;

            return {
                ...oldState,
                selectedFlatFeeYear: successPayload.year.toString(),
            };
        },
        reducerKey,
    }),
    // eslint-disable-next-line max-len
    ...createAsyncFetchActionHandlers<IAccomplishmentsContributionDetail[], IReducerState, IAccomplishmentsContributionDetail[]>({
        baseActionType: FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
        fieldName: 'accomplishmentsContributionDetails',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_FLAT_FEE_DETAIL,
            FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
        ],
        resetDataOnTrigger: true,
        transformStateOnTrigger: ({ oldState, payload }) => {
            const triggerPayload = payload as IFetchAccomplishmentsContributionDetailsPayload;
            const { year } = fromAccomplishmentsContributionDetailId(triggerPayload.yearAndType);

            return {
                ...oldState,
                selectedFlatFeeYear: year.toString(),
            };
        },
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IPreventionUnits[], IReducerState, IPreventionUnitsResponse>({
        baseActionType: FETCH_PREVENTION_UNITS,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR,
            ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
            FETCH_PREVENTION_UNITS,
        ],
        fieldName: 'preventionUnits',
        resetDataOnTrigger: false,
        reducerKey,
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IPreventionUnitsResponse;

            return {
                ...oldState,
                preventionUnits: {
                    ...oldState.preventionUnits,
                    data: successPayload.preventionUnits,
                },
                preventionUnitsGroupType: successPayload.groupType,
            };
        },
    }),
    ...createAsyncFetchActionHandlers<IPreventionUnitTask[], IReducerState, IPreventionUnitTask[]>({
        baseActionType: FETCH_PREVENTION_UNITS_DETAIL,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL,
            ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL,
        ],
        fieldName: 'preventionUnitsDetail',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: EXPORT_PREVENTION_UNITS,
        fieldName: 'exportPreventionUnits',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState: IReducerState) => ({
        /* keep selected year so that it remains after page refresh */
        selectedFlatFeeYear: reducerState.selectedFlatFeeYear,
        /* keep situation history so that the (latest) companyStatisticalCode
           will also get logged to google analytics just after a page refresh */
        companySituationHistory: reducerState.companySituationHistory,
    }),
    resetStateOnCompanySelection: true,
    resetStateOnSeatSelection: ({ initialStateClone }) =>
        createActionHandler<IReducerState>(({ oldState }) => {
            return {
                ...initialStateClone,
                // don't reset on seat selection
                companySituationHistory: oldState.companySituationHistory,
                showPreviousYearUntilDate: oldState.showPreviousYearUntilDate,
            };
        }),
    resetStateOnLocaleSwitch: ({ initialStateClone }) =>
        createActionHandler<IReducerState>(({ oldState }) => {
            return {
                ...initialStateClone,
                // don't reset on locale switch
                selectedFlatFeeYear: oldState.selectedFlatFeeYear,
                companySituationHistory: oldState.companySituationHistory,
                showPreviousYearUntilDate: oldState.showPreviousYearUntilDate,
            };
        }),
});
