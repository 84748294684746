import { SET_ACTIVE_DRAGGABLE, CLEAR_ACTIVE_DRAGGABLE } from './types';
import { createAction } from '../../index';
import { IActiveDraggable } from '../../../models/ui/dragAndDrop';

export function setActiveDraggable(payload: IActiveDraggable) {
    return createAction<IActiveDraggable>(SET_ACTIVE_DRAGGABLE, payload);
}

export function clearActiveDraggable() {
    return createAction(CLEAR_ACTIVE_DRAGGABLE, {});
}
