import { monthOffsetFromNow, now } from '../utils/core/date/getSpecificDate';
import { ONE_MINUTE } from '../utils/core/time/periodsInMillis';
import { formatDateForBackend } from '../utils/formatting/formatDate';

export const DEFAULT_USER_MESSAGES_FILTERS = {
    startDate: formatDateForBackend(monthOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export const FETCH_UNREAD_MESSAGE_COUNT_INTERVAL_IN_MILLIS = 3 * ONE_MINUTE;
