import { object, boolean } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../models/ui/form';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    glasses: 'glasses',
    gloves: 'gloves',
    helmet: 'helmet',
    keel: 'keel',
    mask: 'mask',
    ointments: 'ointments',
    otherProtection: 'otherProtection',
    overall: 'overall',
    seatbelt: 'seatbelt',
    shells: 'shells',
    shoes: 'shoes',
    specificEquipment: 'specificEquipment',
    trousers: 'trousers',
};

export const schema = object({
    [fields.glasses]: boolean(),
    [fields.gloves]: boolean(),
    [fields.helmet]: boolean(),
    [fields.keel]: boolean(),
    [fields.mask]: boolean(),
    [fields.ointments]: boolean(),
    [fields.otherProtection]: string(),
    [fields.overall]: boolean(),
    [fields.seatbelt]: boolean(),
    [fields.shells]: boolean(),
    [fields.shoes]: boolean(),
    [fields.specificEquipment]: boolean(),
    [fields.trousers]: boolean(),
});
