import React, { Component } from 'react';
import { connect } from '../../../../index';
import classNames from 'classnames';
import { IOnboardingWizardEntity, IOnboardingData } from '../../../../../models/onboarding/wizard';
import { createFormTextInput } from '../../../../common/forms/FormTextInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import StickyFooter from '../../../../common/widget/StickyFooter';
import { schema, fields } from './dataSchema';
import { getOnboardingWizardEntity } from '../../../../../redux/onboarding/selectors';
import { updateOnboardingWizardEntity } from '../../../../../redux/onboarding/actions';
import { ICompanyDetail } from '../../../../../models/admin/companyInfo';
import {
    getCompanyDetail,
    getCompanyDetailAsyncInfo,
    getUpdateCompanyAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import Loader from '../../../../common/waiting/Loader';
import { BE_COUNTRY_CODE } from '../../../../../config/general.config';
import { UNSELECTED_STREET } from '../../../../common/address/StreetTypeahead';
import { UNSELECTED_ZIPCODEID } from '../../../../common/address/CityTypeahead';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../models/general/constants';
import Translate from '../../../../common/Translate';
import FormFieldError from '../../../../common/forms/FormFieldError';
import Icon from '../../../../common/icons/Icon';
import { updateCompanyActions } from '../../../../../redux/company/info/actions';
import RequiredMarker from '../../../../common/input/RequiredMarker';
import Alert from '../../../../common/widget/Alert';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../../utils/formatting/formatPhone';
import AddressFields from '../../../../common/address/AddressFields';

export type FormValues = IOnboardingData;

interface IPrivateProps {
    wizardEntity: Partial<IOnboardingWizardEntity>;
    selectedCompany: ICompanyDetail;
    updateCompanyInfo: (values: FormValues) => void;
    fetchCompanyDetailAsyncInfo: IAsyncFieldInfo;
    updateCompanyDetailAsyncInfo: IAsyncFieldInfo;
    updateWizardEntity: (values: FormValues) => void;
}

const FORM_NAME = 'onboarding-base-data';
const CLASS_NAME = 'OnboardingBaseData';
const BASE_KEY = 'onboarding.wizard.steps.data';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

const DataFormTextInput = createFormTextInput<FormValues>();

class Data extends Component<IStepperStepRenderProps & IPrivateProps> {
    private resetForm: (newValues: FormValues) => void;
    private dirty: boolean;

    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
    }

    public render() {
        const {
            renderStepButtons, fetchCompanyDetailAsyncInfo, updateCompanyDetailAsyncInfo,
        } = this.props;

        const INITIAL_VALUES: FormValues = this.getInitialValues();

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT_WIDE, CLASS_NAME)}>
                    <Loader show={fetchCompanyDetailAsyncInfo.status} />
                    {updateCompanyDetailAsyncInfo.error &&
                        <ErrorPlaceholder apiError={updateCompanyDetailAsyncInfo.error} />}

                    {fetchCompanyDetailAsyncInfo.error &&
                        <ErrorPlaceholder apiError={fetchCompanyDetailAsyncInfo.error} />}

                    {!fetchCompanyDetailAsyncInfo.error && (
                        <Form
                            name={FORM_NAME}
                            handleSubmit={this.handleSubmit}
                            initialValues={INITIAL_VALUES}
                            schema={schema}
                            render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                const { resetForm, values, setFieldValue, touched, errors, dirty } = formRenderProps;

                                this.dirty = dirty;

                                if (this.resetForm !== resetForm) {
                                    this.resetForm = resetForm;
                                }

                                return (
                                    <TranslatorContext.Consumer>
                                        {({ translator }) => (
                                            <>
                                                <Loader show={updateCompanyDetailAsyncInfo.status} />
                                                <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                                    <h4>
                                                        <Translate msg={`${BASE_KEY}.company_data.title`} />
                                                    </h4>
                                                    <Alert className={`${CLASS_NAME}__alert`}>
                                                        <Icon typeName="info" circle />
                                                        <Translate msg={`${BASE_KEY}.company_data.subtitle`} />
                                                    </Alert>
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'name'}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                        markAsRequired={true}
                                                    />
                                                    <AddressFields
                                                        {...formRenderProps}
                                                        translator={translator}
                                                        initialAddress={INITIAL_VALUES.address}
                                                        countryAndCityOnSameLine
                                                        allowInternationalAddress
                                                    />
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'ventureNumber'}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                        markAsRequired={true}
                                                    />
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'companyCode'}
                                                        disabled={true}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    />
                                                    <FloatableTextInputWrapper floatLabel>
                                                        <ConstantsTypeahead
                                                            id={`${FORM_NAME}-languageId`}
                                                            name={fields.languageId}
                                                            value={values.languageId}
                                                            constantType={ConstantType.LANGUAGES}
                                                            onItemSelected={(value) =>
                                                                setFieldValue('languageId', value)}
                                                            isInvalid={touched.languageId &&
                                                                !!errors.languageId}
                                                        >
                                                            <label htmlFor={`${FORM_NAME}-languageId`}>
                                                                <Translate
                                                                    msg={`${BASE_FIELD_TRANSLATION_KEY}.language_id`}
                                                                />
                                                                <RequiredMarker />
                                                            </label>
                                                        </ConstantsTypeahead>
                                                        {touched.languageId &&
                                                            <FormFieldError
                                                                error={errors.languageId}
                                                                placeholders={{
                                                                    fieldName: translator(
                                                                        `${BASE_FIELD_TRANSLATION_KEY}.language_id`),
                                                                }}
                                                            />}
                                                    </FloatableTextInputWrapper>
                                                    <h4><Translate msg={`${BASE_KEY}.contact_data.title`} /></h4>
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'email'}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    />
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'phone'}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    />
                                                    <DataFormTextInput
                                                        baseId={FORM_NAME}
                                                        fields={fields}
                                                        formRenderProps={formRenderProps}
                                                        name={'fax'}
                                                        baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    />
                                                </div>
                                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                                    {renderStepButtons({
                                                        nextButton: {
                                                            isSubmit: true,
                                                            formName: FORM_NAME,
                                                            alwaysEnabled: true,
                                                        },
                                                    })}
                                                </StickyFooter>
                                            </>
                                        )}
                                    </TranslatorContext.Consumer>
                                );
                            }}
                        />
                    )}
                </div>
            </>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            prevProps.updateCompanyDetailAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateCompanyDetailAsyncInfo.status === AsyncStatus.Success
        ) {
            this.props.goToNextStep();
        }

        if (
            prevProps.fetchCompanyDetailAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchCompanyDetailAsyncInfo.status === AsyncStatus.Success
        ) {
            this.resetForm(this.getInitialValues());
        }
    }

    private getInitialValues(): FormValues {
        const {
            wizardEntity,
            selectedCompany,
        } = this.props;

        return {
            name: wizardEntity.baseData &&
                wizardEntity.baseData.name || selectedCompany && selectedCompany.name,
            address: wizardEntity.baseData &&
                wizardEntity.baseData.address || selectedCompany && selectedCompany.address || {
                    countryCode: BE_COUNTRY_CODE,
                    box: '',
                    city: '',
                    number: '',
                    postcode: '',
                    street: UNSELECTED_STREET,
                    zipCodeId: UNSELECTED_ZIPCODEID,
                },
            ventureNumber: wizardEntity.baseData &&
                wizardEntity.baseData.ventureNumber || selectedCompany && selectedCompany.ventureNumber,
            companyCode: selectedCompany && selectedCompany.companyCode,
            languageId: wizardEntity.baseData &&
                wizardEntity.baseData.languageId || selectedCompany && selectedCompany.languageId,
            email: wizardEntity.baseData &&
                wizardEntity.baseData.email || selectedCompany && selectedCompany.email,
            phone: wizardEntity.baseData &&
                tryFormattingPhoneInternational(wizardEntity.baseData.phone) ||
                selectedCompany && tryFormattingPhoneInternational(selectedCompany.phone),
            fax: wizardEntity.baseData &&
                wizardEntity.baseData.fax || selectedCompany && selectedCompany.fax,
        };
    }

    private handleSubmit(values: FormValues) {
        const { updateCompanyInfo, goToNextStep, updateWizardEntity } = this.props;

        const transformedValues: FormValues = {
            ...values,
            phone: tryFormattingPhoneForBackend(values.phone),
        };
        if (this.dirty) {
            updateWizardEntity(transformedValues);
            updateCompanyInfo(transformedValues);
        } else {
            updateWizardEntity(transformedValues);
            goToNextStep();
        }
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getOnboardingWizardEntity(state);
        const selectedCompany = getCompanyDetail(state);
        const fetchCompanyDetailAsyncInfo = getCompanyDetailAsyncInfo(state);
        const updateCompanyDetailAsyncInfo = getUpdateCompanyAsyncInfo(state);

        return {
            wizardEntity,
            selectedCompany,
            fetchCompanyDetailAsyncInfo,
            updateCompanyDetailAsyncInfo,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateOnboardingWizardEntity({
                    baseData: {
                        ...values,
                        address: {
                            number: values.address.number,
                            street: values.address.street,
                            zipCodeId:
                                (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                                    null : values.address.zipCodeId,
                            box: values.address.box,
                            city: values.address.city,
                            postcode: values.address.postcode,
                            countryCode: values.address.countryCode,
                        },
                    },
                }));
            },
            updateCompanyInfo: (values: FormValues) => {
                dispatch(updateCompanyActions.trigger({
                    companyCode: values.companyCode,
                    companyData: {
                        address: {
                            number: values.address.number,
                            street: values.address.street,
                            zipCodeId:
                                (!values.address.zipCodeId || values.address.zipCodeId === UNSELECTED_ZIPCODEID) ?
                                    null : values.address.zipCodeId,
                            box: values.address.box,
                            city: values.address.city,
                            postcode: values.address.postcode,
                            countryCode: values.address.countryCode,
                        },
                        email: values.email,
                        fax: values.fax,
                        languageId: values.languageId,
                        name: values.name,
                        phone: values.phone,
                        ventureNumber: values.ventureNumber,
                    },
                }));
            },
        };
    },
})(Data);
