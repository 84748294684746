import React, { PureComponent, ChangeEvent } from 'react';
import './company-and-employee-report.scss';
import classNames from 'classnames';
import { connect } from '../..';
import GreenDocumentCenterPage from '../shared/GreenDocumentCenterPage';
import Form, { IFormRenderProps } from '../../common/forms/Form';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import { schema, fields } from './companyAndEmployeeReportSchema';
import FormFieldError from '../../common/forms/FormFieldError';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { ITranslator } from '../../../models/general/i18n';
import SubmitButton from '../../common/buttons/SubmitButton';
import Translate from '../../common/Translate';
import Loader from '../../common/waiting/Loader';
import Checkbox from '../../common/input/Checkbox';
import { getGenerateCompanyAndEmployeeReportAsyncInfo } from '../../../redux/company/documents/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import FormError from '../../common/forms/FormError';
import { generateCompanyAndEmployeeReportActions } from '../../../redux/company/documents/actions';
import { IGenerateCompanyAndEmployeeReportPayload } from '../../../models/general/documents';
import LocaleDropdown from '../../common/input/Dropdown/LocaleDropdown';

const FORM_NAME = 'company-and-employee-report';
const CLASS_NAME = 'CompanyAndEmployeeReport';

interface IFormValues {
    companyFunctions: boolean;
    companyData: boolean;
    employeeList: boolean;
    companyVisits: boolean;
    riskManagement: boolean;
    locale: string;
    linkedEmployees: boolean;
    selectAll: boolean;
}

const COMPANY_AND_EMPLOYEE_INFO_INITIAL_VALUES: IFormValues = {
    companyFunctions: false,
    companyData: false,
    employeeList: false,
    companyVisits: true,
    riskManagement: true,
    locale: '',
    linkedEmployees: false,
    selectAll: false,
};

interface IState {
    previousDownloadValues: Pick<IFormValues,
        'companyData' | 'companyFunctions' | 'linkedEmployees' | 'employeeList' | 'locale'>;
}

interface IPrivateProps {
    translator: ITranslator;
    generateReportAsyncInfo: IAsyncFieldInfo;
    generateCompanyAndEmployeeReport: (values: IFormValues) => void;
}

class CompanyAndEmployeeReport extends PureComponent<IPrivateProps, IState>{
    private resetForm: (newValues: object) => void;
    private formRenderProps: IFormRenderProps<IFormValues>;

    constructor(props) {
        super(props);

        this.state = {
            previousDownloadValues: null,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.generateReportSucceeded = this.generateReportSucceeded.bind(this);
        this.onSelectAllToggle = this.onSelectAllToggle.bind(this);
        this.onLinkedEmployeesToggle = this.onLinkedEmployeesToggle.bind(this);
        this.onCompanyFunctionsToggle = this.onCompanyFunctionsToggle.bind(this);
        this.areCurrentValuesSameAsPreviousDownloadValues =
            this.areCurrentValuesSameAsPreviousDownloadValues.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (this.generateReportSucceeded(prevProps)) {
            const currentValues = this.formRenderProps.values;
            this.setState({
                previousDownloadValues: {
                    companyData: currentValues.companyData,
                    companyFunctions: currentValues.companyFunctions,
                    employeeList: currentValues.employeeList,
                    linkedEmployees: currentValues.linkedEmployees,
                    locale: currentValues.locale,
                },
            });
            this.resetForm(COMPANY_AND_EMPLOYEE_INFO_INITIAL_VALUES);
        }
    }

    public render() {
        const {
            translator, generateReportAsyncInfo,
        } = this.props;

        const BASE_KEY = 'document_center.company_and_employee_report.form';

        const TRANSLATION_KEYS = {
            companyFunctions: `${BASE_KEY}.company_functions`,
            companyData: `${BASE_KEY}.company_data`,
            employeeList: `${BASE_KEY}.employee_list`,
            companyVisits: `${BASE_KEY}.company_visits`,
            riskManagement: `${BASE_KEY}.risk_management`,
            locale: `${BASE_KEY}.locale`,
            linkedEmployees: `${BASE_KEY}.linked_employees`,
            selectAll: `${BASE_KEY}.select_all`,
            submit: `${BASE_KEY}.submit`,
        };

        return (
            <GreenDocumentCenterPage
                titleTranslationKey="document_center.company_and_employee_report.title"
                introTranslationKey="document_center.company_and_employee_report.text"
            >
                <div className={classNames('DocumentCenterPageContent', CLASS_NAME)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={this.onSubmit}
                        schema={schema}
                        initialValues={COMPANY_AND_EMPLOYEE_INFO_INITIAL_VALUES}
                        render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                            const {
                                values, errors, touched, setFieldValue, handleChange, resetForm,
                            } = formRenderProps;
                            if (!this.resetForm) {
                                this.resetForm = resetForm;
                            }
                            this.formRenderProps = formRenderProps;

                            const allSelected = values.selectAll;

                            return (
                                <div className={`${CLASS_NAME}__form`}>
                                    <Loader show={generateReportAsyncInfo.status}>
                                        <FloatableTextInputWrapper floatLabel>
                                            <LocaleDropdown
                                                id="company-and-employee-report-locale"
                                                value={values.locale}
                                                onItemSelected={(value) => setFieldValue('locale', value as string)}
                                            />
                                            {touched.locale &&
                                                <FormFieldError
                                                    error={errors.locale}
                                                    placeholders={{ fieldName: translator(TRANSLATION_KEYS.locale) }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small" className={`${CLASS_NAME}__select_all`}>
                                            <Checkbox
                                                name={fields.selectAll}
                                                checked={values.selectAll}
                                                onChange={this.onSelectAllToggle}
                                                boldOnChecked={true}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.selectAll} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small">
                                            <Checkbox
                                                name={fields.companyData}
                                                checked={values.companyData}
                                                onChange={handleChange}
                                                boldOnChecked={true}
                                                disabled={allSelected}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.companyData} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small">
                                            <Checkbox
                                                name={fields.companyFunctions}
                                                checked={values.companyFunctions}
                                                onChange={this.onCompanyFunctionsToggle}
                                                boldOnChecked={true}
                                                disabled={allSelected}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.companyFunctions} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small" type="sub">
                                            <Checkbox
                                                name={fields.linkedEmployees}
                                                checked={values.linkedEmployees}
                                                onChange={this.onLinkedEmployeesToggle}
                                                boldOnChecked={true}
                                                disabled={allSelected}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.linkedEmployees} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small">
                                            <Checkbox
                                                name={fields.employeeList}
                                                checked={values.employeeList}
                                                onChange={handleChange}
                                                boldOnChecked={true}
                                                disabled={allSelected}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.employeeList} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small">
                                            <Checkbox
                                                name={fields.companyVisits}
                                                checked={values.companyVisits}
                                                onChange={handleChange}
                                                boldOnChecked={true}
                                                disabled={true}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.companyVisits} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FloatableTextInputWrapper size="small">
                                            <Checkbox
                                                name={fields.riskManagement}
                                                checked={values.riskManagement}
                                                onChange={handleChange}
                                                boldOnChecked={true}
                                                disabled={true}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.riskManagement} />
                                            </Checkbox>
                                        </FloatableTextInputWrapper>
                                        <FormError error={generateReportAsyncInfo.error} />
                                        <div className={`${CLASS_NAME}__submit`}>
                                            <SubmitButton
                                                id="company-and-employee-report-submit"
                                                formName={FORM_NAME}
                                                disabled={this.areCurrentValuesSameAsPreviousDownloadValues()}
                                            >
                                                <Translate msg={TRANSLATION_KEYS.submit} />
                                            </SubmitButton>
                                        </div>
                                    </Loader>
                                </div>
                            );
                        }}
                    />
                </div>
            </GreenDocumentCenterPage>
        );
    }

    private onSubmit(values: IFormValues) {
        if (!this.areCurrentValuesSameAsPreviousDownloadValues()) {
            this.props.generateCompanyAndEmployeeReport(values);
        }
    }

    private onSelectAllToggle(e: ChangeEvent<HTMLInputElement>) {
        const { setFieldValue } = this.formRenderProps;
        const checked = e.target.checked;
        setFieldValue('companyFunctions', checked);
        setFieldValue('companyData', checked);
        setFieldValue('employeeList', checked);
        setFieldValue('linkedEmployees', checked);
        setFieldValue('selectAll', checked);
    }

    private onLinkedEmployeesToggle(e: ChangeEvent<HTMLInputElement>) {
        const { values, setFieldValue } = this.formRenderProps;
        const checked = e.target.checked;

        setFieldValue('linkedEmployees', checked);

        if (checked && !values.companyFunctions) {
            setFieldValue('companyFunctions', checked);
        }
    }

    private onCompanyFunctionsToggle(e: ChangeEvent<HTMLInputElement>) {
        const { values, setFieldValue } = this.formRenderProps;
        const checked = e.target.checked;

        setFieldValue('companyFunctions', checked);

        if (!checked && values.linkedEmployees) {
            setFieldValue('linkedEmployees', checked);
        }
    }

    private generateReportSucceeded(prevProps: IPrivateProps) {
        return prevProps.generateReportAsyncInfo.status === AsyncStatus.Busy &&
            this.props.generateReportAsyncInfo.status === AsyncStatus.Success;
    }

    private areCurrentValuesSameAsPreviousDownloadValues() {
        const { previousDownloadValues } = this.state;
        if (!previousDownloadValues) {
            return false;
        }
        return !Object.keys(previousDownloadValues)
            .map((key) => previousDownloadValues[key] !== this.formRenderProps.values[key])
            // tslint:disable-next-line:no-boolean-literal-compare
            .some((valueHasChanged) => valueHasChanged === true);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            generateReportAsyncInfo: getGenerateCompanyAndEmployeeReportAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            generateCompanyAndEmployeeReport: (values: IFormValues) => {
                const { selectAll, ...payloadValues } = values;
                dispatch(generateCompanyAndEmployeeReportActions.trigger({
                    ...payloadValues,
                } as IGenerateCompanyAndEmployeeReportPayload));
            },
        };
    },
})(CompanyAndEmployeeReport);
