import { ICourseSessionsByMonth, ICourseSession } from '../../../models/documentCenter/courses';

export function toFlattenedFirstdaySessions(sessionDataByMonth: ICourseSessionsByMonth): ICourseSession[] {
    return Object.keys(sessionDataByMonth)
        .reduce(
            (sessionsAccumulator, index) => {
                return sessionsAccumulator.concat(
                    Object.keys(sessionDataByMonth[index])
                        .map((sessionId) => sessionDataByMonth[index][sessionId])
                        .map((sessions: ICourseSession[]) => sessions.find((session) => session.dayNumber === 1)),
                );
            },
            [],
        );
}
