import React, { Suspense, lazy } from 'react';
import { SVG_GROUP_NAME, TLazyLoadSvgGroup, TLazyLoadSvgId } from '../../../../models/general/lazyLoadSvg';

const AdministrationGroup = lazy(() => import('./groups/administration'));
const ContactGroup = lazy(() => import('./groups/contact'));
const DocumentCenterGroup = lazy(() => import('./groups/documentCenter'));
const InterventionsGroup = lazy(() => import('./groups/interventions'));
const TopTasksGroup = lazy(() => import('./groups/topTasks'));
const CourseTypesGroup = lazy(() => import('./groups/courseTypes'));

export interface ILazyLoadSvgProps {
    id: TLazyLoadSvgId;
    group: TLazyLoadSvgGroup;
    preserveAspectRatio?: string;
    className?: string;
}

const LazyLoadSVG = (props: ILazyLoadSvgProps) => {
    return (
        <Suspense fallback={<span />}>
            {props.group === SVG_GROUP_NAME.ADMINISTRATION && <AdministrationGroup {...props} />}
            {props.group === SVG_GROUP_NAME.CONTACT && <ContactGroup {...props} />}
            {props.group === SVG_GROUP_NAME.DOCUMENT_CENTER && <DocumentCenterGroup {...props} />}
            {props.group === SVG_GROUP_NAME.INTERVENTIONS && <InterventionsGroup {...props} />}
            {props.group === SVG_GROUP_NAME.TOP_TASKS && <TopTasksGroup {...props} />}
            {props.group === SVG_GROUP_NAME.COURSE_TYPES && <CourseTypesGroup {...props} />}
        </Suspense>
    );
};

export default LazyLoadSVG;
