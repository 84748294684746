import { ICompanyContact } from '../../admin/companyInfo';

export interface ICreateCompanyVisitRequest {
    contact: Pick<ICompanyContact, 'firstName' | 'name' | 'email' | 'phone' | 'mobilePhone'>;
    remarks: string;
}

export enum COMPANY_VISITS_REQUEST_WIZARD_STEP_ID {
    CONTACT = 'contact',
    REMARKS = 'remarks',
    VALIDATE = 'validate',
}

export interface ICompanyVisitRequestWizardPayload {
    step: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID;
    resetDataEntity: boolean;
}

export interface ICompanyVisitRequestWizardEntity {
    contact: ICompanyContact;
    remarks: {
        remark: string;
    };
}

export interface ICompanyVisitRequestWizardData {
    stepId: string;
    entity: Partial<ICompanyVisitRequestWizardEntity>;
}
