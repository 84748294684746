import React, { Component } from 'react';

import {
    getPlanMedicalExaminationWizardStepId,
    getPlanMedicalExaminationWizardType,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../redux/medicalExamination/selectors';
import { getPlanMedicalExaminatonWizardSteps } from '../../../../config/navigation/wizardStepsMap';
import { navigateToPlanMedicalExaminationWizardStep } from '../../../../redux/medicalExamination/actions';
import { TPlanMedicalExaminationWizardEntity } from '../../../../models/interventions/medicalExaminations';
import { connect } from '../../../index';
import Wizard from '../../../common/navigation/Wizard/index';

import { IPrivateProps } from './plan-medical-examination-wizard.type';
import registerPlanMedicalExaminationWizardSteps from './registerPlanMedicalExaminationWizardSteps';

registerPlanMedicalExaminationWizardSteps();

class PlanMedicalExaminationWizard extends Component<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);

        this.navigateToStepActionCreator = this.navigateToStepActionCreator.bind(this);
    }

    public render() {
        const { wizardEntity, wizardSteps } = this.props;
        const steps = wizardSteps ? wizardSteps.filter((step) => {
            if (typeof step.hide === 'function') {
                return !step.hide(wizardEntity);
            }
            return true;
        }) : [];
        return (
            <div className="PlanMedicalExaminationWizard">
                <Wizard
                    activeStepIdSelector={getPlanMedicalExaminationWizardStepId}
                    navigateToStepActionCreator={this.navigateToStepActionCreator}
                    steps={steps}
                />
            </div>
        );
    }

    private navigateToStepActionCreator(stepId: string) {
        return navigateToPlanMedicalExaminationWizardStep({
            wizardType: this.props.wizardType,
            step: stepId,
            resetDataEntity: false,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardType = getPlanMedicalExaminationWizardType(state);
        const entity = getPlanMedicalExaminationWizardEntity(state) as TPlanMedicalExaminationWizardEntity;

        return {
            wizardType,
            wizardEntity: entity,
            wizardSteps: getPlanMedicalExaminatonWizardSteps(wizardType).steps,
        };
    },
})(PlanMedicalExaminationWizard);
