import React, { Component } from 'react';

import { AddUserAccountWizardStep } from '../../../../../../models/user/userAccount';
import { getAddlUserWizardStepId } from '../../../../../../redux/company/users/selectors';
import { getCompanySeatsForSelectedCompany } from '../../../../../../redux/company/selected/selectors';
import { getRouteKey } from '../../../../../../redux/location/selectors';
import { IStepperStep } from '../../../../../../models/general/stepper';
import { navigateTo } from '../../../../../../redux/location/actions';
import connect from '../../../../../../utils/libs/redux/connect';
import ROUTE_KEYS from '../../../../../../routeKeys';
import Wizard from '../../../../../common/navigation/Wizard/index';

import PersonalData from './steps/PersonalData';
import SelectEmployee from './steps/SelectEmployee';
import AccessLevel from './steps/AccessLevel';
import Seats from './steps/Seats';

import './add-user.scss';

interface IPrivateProps {
    isExternal: boolean;
    skipSeatsStep: boolean;
}

interface IWizardStepConfig extends IPrivateProps {}

const CLASS_NAME = 'AddUserWizard';

class AddUserWizard extends Component<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);

        this.navigateToStepActionCreator = this.navigateToStepActionCreator.bind(this);
    }
    public render() {
        const { skipSeatsStep } = this.props;

        const EXTERNAL_WIZARD_STEPS = this.getWizardSteps({ isExternal: true, skipSeatsStep });
        const INTERNAL_WIZARD_STEPS = this.getWizardSteps({ isExternal: false, skipSeatsStep });

        return (
            <div className={CLASS_NAME}>
                <Wizard
                    activeStepIdSelector={getAddlUserWizardStepId}
                    navigateToStepActionCreator={this.navigateToStepActionCreator}
                    steps={this.props.isExternal ? EXTERNAL_WIZARD_STEPS : INTERNAL_WIZARD_STEPS}
                />
            </div>
        );
    }

    private navigateToStepActionCreator(stepId: AddUserAccountWizardStep) {
        return navigateToAddUserAccountWizardStep(stepId, this.props.isExternal);
    }

    private getWizardSteps({ isExternal, skipSeatsStep }: IWizardStepConfig): IStepperStep[] {
        let steps: IStepperStep[] = [
            isExternal
                ? {
                    id: AddUserAccountWizardStep.PersonalData,
                    // eslint-disable-next-line max-len
                    translationKey: 'account.account_settings.manage_users.add.wizard.steps.personal_data.step_label',
                    renderStepContent: (props) => <PersonalData {...props} />,
                }
                : {
                    id: AddUserAccountWizardStep.SelectEmployee,
                    // eslint-disable-next-line max-len
                    translationKey: 'account.account_settings.manage_users.add.wizard.steps.select_employee.step_label',
                    renderStepContent: (props) => <SelectEmployee {...props} />,
                },
            {
                id: AddUserAccountWizardStep.Seats,
                // eslint-disable-next-line max-len
                translationKey: 'account.account_settings.manage_users.add.wizard.steps.seats.step_label',
                renderStepContent: (props) => <Seats {...props} />,
            },
            {
                id: AddUserAccountWizardStep.AccessLevel,
                // eslint-disable-next-line max-len
                translationKey: 'account.account_settings.manage_users.add.wizard.steps.access_level.step_label',
                renderStepContent: (props) => <AccessLevel {...props} />,
            },
        ];

        if (skipSeatsStep) {
            steps = steps.filter((step) => step.id !== AddUserAccountWizardStep.Seats);
        }

        return steps;
    }
}

function navigateToAddUserAccountWizardStep(stepId: AddUserAccountWizardStep, isExternal: boolean) {
    const routeKey = isExternal
        ? ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL
        : ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL;
    return navigateTo(routeKey, { stepId });
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const routeKey = getRouteKey(state);
        const companySeats = getCompanySeatsForSelectedCompany(state);

        return {
            isExternal: routeKey === ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL,
            skipSeatsStep: companySeats && companySeats.length <= 1,
        };
    },
})(AddUserWizard);
