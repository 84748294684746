import React from 'react';
import classNames from 'classnames';
import './function.scss';
import isSet from '@snipsonian/core/es/is/isSet';
import { CLASS_NAME, FORM_NAME, TRANSLATIONS_PREFIX } from './shared';
import FunctionInput, { IFormValues, schema } from './FunctionInput';
import { Permission } from '../../../../../../models/auth/authorisation';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { IState } from '../../../../../../redux/IState';
import { hasPermission } from '../../../../../../redux/auth/selectors';
import { updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';
import { getEmployeeToAdd } from '../../../../../../redux/employee/wizard/selectors';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import Icon from '../../../../../common/icons/Icon';
import WizardFormStep from '../../../../../common/navigation/WizardFormStep';
import Translate from '../../../../../common/Translate';
import TooltipWithIcon from '../../../../../common/widget/TooltipWithIcon';
import { getSelectedEmployeeJobStudentAsyncInfo } from '../../../../../../redux/employee/info/selectors';
import { AsyncStatus } from '../../../../../../models/general/redux';

const FunctionWizardFormStep = WizardFormStep<IFormValues>();

export default function Function(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <FunctionWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: `${TRANSLATIONS_PREFIX}.title`,
                titlePlaceholders: getHeaderTitlePlaceholders,
                textTranslationKey: getHeaderTextTranslationKey,
                textPlaceholders: getHeaderTextPlaceholders,
            }}
            showLoaderSelector={(state) => getSelectedEmployeeJobStudentAsyncInfo(state).status === AsyncStatus.Busy}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const employeeToAdd = getEmployeeToAdd(state);
                    const currentJobStudentData = employeeToAdd && employeeToAdd.jobStudent;

                    return {
                        functionId: (employeeToAdd && employeeToAdd.function && employeeToAdd.function.id) || null,
                        functionDescription: (employeeToAdd && employeeToAdd.function
                            && employeeToAdd.function.description) || '',
                        studentWorker: (employeeToAdd && employeeToAdd.studentWorker) || false,
                        medicalFunction: (currentJobStudentData && currentJobStudentData.medicalFunction) || false,
                        safetyFunction: (currentJobStudentData && currentJobStudentData.safetyFunction) || false,
                        employer: (currentJobStudentData && currentJobStudentData.employer) || '',
                        email: (employeeToAdd && employeeToAdd.email) || null,
                        sendMail: currentJobStudentData && isSet(currentJobStudentData.sendMail) ?
                            currentJobStudentData.sendMail : true,
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <FunctionInput {...renderProps} />,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => {
                    const { functionId, functionDescription, studentWorker, email, ...jobStudentFields } = values;

                    if (!studentWorker) {
                        jobStudentFields.medicalFunction = false;
                        jobStudentFields.safetyFunction = false;
                        jobStudentFields.employer = '';
                        jobStudentFields.sendMail = false;
                    }

                    return updateAddEmployeeData({
                        function: {
                            id: functionId,
                            description: functionDescription,
                        },
                        jobStudent: { ...jobStudentFields },
                        studentWorker,
                        email,
                    });
                },
            }}
        />
    );
}

function getHeaderTitlePlaceholders(state: IState) {
    const employeeToAdd = getEmployeeToAdd(state);

    return {
        name: formatPersonName(employeeToAdd),
    };
}

function getHeaderTextTranslationKey(state: IState) {
    const canCreateTempFunction = hasPermission(state, Permission.CAN_CREATE_TEMP_FUNC);

    return canCreateTempFunction
        ? `${TRANSLATIONS_PREFIX}.text`
        : `${TRANSLATIONS_PREFIX}.text_no_add`;
}

function getHeaderTextPlaceholders(state: IState) {
    return {
        tooltip: (
            <span
                className={classNames(
                    'placeholderLink',
                    `${CLASS_NAME}__header-text-placeholder`)}
            >
                <TooltipWithIcon
                    tooltipBubbleIcon={<Icon circle typeName="info" />}
                    icon={
                        <Icon
                            className={`${CLASS_NAME}__header-tooltip-icon`}
                            circle
                            typeName="info"
                        />
                    }
                    iconSize="small"
                    typeName="info-bubble"
                >
                    <Translate msg={`${TRANSLATIONS_PREFIX}.header_tooltip`} />
                </TooltipWithIcon>
            </span>
        ),
    };
}
