import { ITraceableApiError } from './error';

export const NOTIFICATION_ID = {
    NEW_VERSION: 'new_version',
    CUSTOM: 'custom',
};

export enum NotificationType {
    Error = 'error',
    NewVersion = 'new-version',
    Custom = 'custom',
}

export interface INotification {
    id: string;
    type: NotificationType;
    payload?: object;
}

export interface IErrorNotification extends INotification {
    payload: ITraceableApiError;
}

export interface ICustomNotification extends INotification {
    payload: {
        titleTranslationKey: string;
        messageTranslationKey: string;
        autoHide: boolean;
    };
}
