import React, { PureComponent } from 'react';
import './company-selection-filter.scss';
import Translate from '../../../common/Translate';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { connect } from '../../../index';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { getQueryParams } from '../../../../redux/location/selectors';
import { IFetchCompaniesPayload } from '../../../../models/admin/company';
import { ITranslator } from '../../../../models/general/i18n';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import IconHeader from '../../../common/headers/IconHeader';
import SlideOutPanel from '../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../common/buttons/SubmitButton';
import { schema, fields } from './companySelectionFilterSchema';
import FormFieldError from '../../../common/forms/FormFieldError';

interface ICompanySelectionFilterProps {
    isOpen: boolean;
    onCloseIntent: (isClickedOutside: boolean) => void;
    onSubmit: (filter: IFetchCompaniesPayload) => void;
}

interface IPrivateProps {
    translator: ITranslator;
    filter: IFetchCompaniesPayload;
}

const FORM_NAME = 'company-selection-filter-form';

class CompanySelectionFilter extends PureComponent<IPrivateProps & ICompanySelectionFilterProps> {
    private resetForm: (newFilterValues: object) => void;
    private footer = Footer();

    public render() {
        const { translator, filter, isOpen, onCloseIntent, onSubmit } = this.props;

        const onSearch = (filter) => onSubmit(filter);

        const placeholder = translator('account.company_selection.filter.company_code_or_name');

        const initialValues = {
            companyCodeOrNameFilter: filter.companyCodeOrNameFilter || '',
        };

        const searchContainerId = 'company-selection-search-container';

        return (
            <SlideOutPanel
                isOpen={isOpen}
                onCloseIntent={onCloseIntent}
                footer={this.footer}
                containerTofocus={searchContainerId}
            >
                {(renderProps) => {
                    return (
                        <Form
                            name={FORM_NAME}
                            handleSubmit={onSearch}
                            initialValues={initialValues}
                            schema={schema}
                            render={({
                                values, handleChange, touched, errors, resetForm,
                            }: IFormRenderProps<IFetchCompaniesPayload>) => {
                                if (!this.resetForm) {
                                    this.resetForm = resetForm;
                                }

                                const content = (
                                    <section className="CompanySelectionFilter" id={searchContainerId}>
                                        <IconHeader iconTypeName="search">
                                            <Translate msg="account.company_selection.filter.title" />
                                        </IconHeader>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="company-selection-filter-company-code-or-name"
                                                name={fields.companyCodeOrNameFilter}
                                                placeholder={placeholder}
                                                value={values.companyCodeOrNameFilter || ''}
                                                isInvalid={touched.companyCodeOrNameFilter &&
                                                    !!errors.companyCodeOrNameFilter}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="company-selection-filter-company-code-or-name">
                                                {placeholder}
                                            </label>
                                            {touched.companyCodeOrNameFilter && (
                                                <FormFieldError
                                                    error={errors.companyCodeOrNameFilter}
                                                    placeholders={{ fieldName: placeholder }}
                                                />
                                            )}
                                        </FloatableTextInputWrapper>
                                    </section>
                                );

                                return (
                                    <>
                                        {renderProps.renderContent(content)}
                                        {renderProps.renderFooter()}
                                    </>
                                );
                            }}
                        />
                    );
                }}
            </SlideOutPanel >
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps & ICompanySelectionFilterProps) {
        if (this.resetForm && this.props.filter.companyCodeOrNameFilter !== prevProps.filter.companyCodeOrNameFilter) {
            // Reset the form, otherwise e.g. 'old' filters will still be visible although a user removed them
            // by clicking on an active filter cross (because formRenderProps.values would still contain the old ones)
            this.resetForm(this.props.filter);
        }
    }

}

export default connect<IPrivateProps, ICompanySelectionFilterProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            filter: getQueryParams<IFetchCompaniesPayload>(state),
        };
    },
})(CompanySelectionFilter);

function Footer() {
    return (
        <SubmitButton
            id="company-select-filter-submit"
            formName={FORM_NAME}
        >
            <Translate msg="account.company_selection.filter.submit" />
        </SubmitButton>
    );
}
