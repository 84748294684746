import { createEpic, IState } from '../index';
import { ON_ACTION_TYPE_REGEX } from '../../config/redux.config';
import { ICompany } from '../../models/admin/company';
import ROUTE_KEYS from '../../routeKeys';
import { getRoute, getRoutes } from '../../routes';
import {
    hasOneOfRequiredAccessLevels,
    isLoggedIn,
    isRouteAllowedIfDismissedCompany,
    isRouteOnlyAllowedIfFlatFee,
    isRouteOnlyAllowedIfPreventionUnits,
    isRouteOnlyAllowedIfValidSituationHistory,
} from '../auth/selectors';
import {
    getSelectedCompany,
    getSelectedCompanySeat,
    isCompanyAnInterimCompany,
    isSelectedCompanyDismissed,
} from '../company/selected/selectors';
import { getLocationState } from '../location/selectors';
import {
    navigateTo,
    navigateToCompanySelection,
    navigateToSeatsSelection,
    redirectToLogin,
    redirectToLogout,
    redirectToNotFound,
    redirectToRoute,
} from '../location/actions';
import locationActionToUrl from '../../utils/routing/locationActionToUrl';
import { getCompanyDetail } from '../company/info/selectors';
import { ICompanyDetail } from '../../models/admin/companyInfo';
import { IAction } from '../../models/general/redux';
import { fetchCompanyDetailBeforeNavigating, fetchCompanySituationHistoryBeforeNavigating } from './actions';
import { getOnboardingRoute } from '../company/companies/selectors';
import {
    didCompanyOnceUseFlatFee,
    didCompanyOnceUsePreventionUnits,
    getCompanySituationHistory,
    getShowPreventionUnitsPreviousYearUntilDate,
    isCompanySituationHistoryAvailable,
} from '../preventionUnits/selectors';
import { ICompanySituationHistorySuccessPayload, TCompanySituationHistory } from '../../models/admin/preventionUnits';
import {
    doesAtLeastOneYearIndicateFlatFee,
    doesAtLeastOneYearIndicatePreventionUnits,
    shouldPEOverviewBeActive,
    shouldPEPreviousYearBeActive,
} from '../preventionUnits/companySituationHistoryUtils';
import sessionManager from '../../utils/auth/sessionManager';

/**
 * We do these checks in an epic so that we can redirect BEFORE the route action is processed
 * in the reducer(s).
 * Otherwise (if after action processed) the BE rest calls would be triggered even though the user is
 * for example not logged in.
 */
// validateEachRouteEpic
createEpic<{}>({
    onActionType: ON_ACTION_TYPE_REGEX.ALL_ROUTE_ACTIONS,
    transform({ action, getState }, { next }) {
        const state = getState();
        const routeKey = action.type as ROUTE_KEYS;
        const requestedRoute = getRoute({ routeKey });

        if (!requestedRoute) {
            return next(redirectToNotFound());
        }

        if (!requestedRoute.allowAnonymousAccess) {
            if (!isLoggedIn(state)) {
                return next(
                    redirectToLogin(getLocationState(state)),
                );
            }

            /* we check on 'isValidSessionEndTime', otherwise users who don't have localStorage would be
               redirected to login - because of the 'isSessionExpired' check - each time after a page refresh */
            if (sessionManager.isValidSessionEndTime() && sessionManager.isSessionExpired()) {
                return next(
                    redirectToLogout({
                        isSessionExpired: true,
                        locationState: getLocationState(state),
                    }),
                );
            }

            const should = shouldUserFirstSelectACompanyOrSeat(routeKey, state);
            if (should.indeed) {
                const redirectUrl = locationActionToUrl(action, getRoutes());

                if (should.onboardingRoute) {
                    return next(
                        navigateTo(should.onboardingRoute),
                    );
                }

                if (should.selectedCompany) {
                    return next(
                        navigateToSeatsSelection(should.selectedCompany.companyCode, redirectUrl),
                    );
                }

                return next(
                    navigateToCompanySelection(redirectUrl),
                );
            }

            if (isSelectedCompanyDismissed(state)) {
                if (routeKey === ROUTE_KEYS.R_HOME) {
                    return next(
                        redirectToRoute(ROUTE_KEYS.R_DISMISSED_COMPANY_DASHBOARD),
                    );
                }

                if (!isRouteAllowedIfDismissedCompany(requestedRoute)) {
                    return next(redirectToNotFound());
                }
            }
        }

        if (requestedRoute.requiredAccessLevels) {
            if (!hasOneOfRequiredAccessLevels(state, requestedRoute.requiredAccessLevels)) {
                return next(redirectToNotFound());
            }
        }

        if (typeof requestedRoute.checkForRedirect === 'function') {
            const redirectAction = requestedRoute.checkForRedirect(state);
            if (redirectAction !== null) {
                return next(redirectAction);
            }
        }

        if (!!requestedRoute.redirectToRouteIfInterim) {
            if (!getCompanyDetail(state)) {
                return next(fetchCompanyDetailBeforeNavigating({
                    getNavigateAction: navigateToRouteIfInterimCompany,
                    requestedAction: action,
                }));
            }
            if (isCompanyAnInterimCompany(state)) {
                return next(navigateTo(requestedRoute.redirectToRouteIfInterim));
            }
        }

        const routeRequiresPE = isRouteOnlyAllowedIfPreventionUnits(requestedRoute);
        const routeRequiresFlatFee = isRouteOnlyAllowedIfFlatFee(requestedRoute);
        const routeRequiresValidSituationHistory = isRouteOnlyAllowedIfValidSituationHistory(requestedRoute);

        if (routeRequiresPE || routeRequiresFlatFee || routeRequiresValidSituationHistory) {
            if (!isCompanySituationHistoryAvailable(state)) {
                return next(fetchCompanySituationHistoryBeforeNavigating({
                    getNavigateAction: (
                        companySituationHistory: ICompanySituationHistorySuccessPayload,
                        requestedAction: IAction<{}>,
                    ) => {
                        const situationHistory = companySituationHistory.situationHistory;
                        const showPreviousYearUntilDate = companySituationHistory.showPreviousYearUntilDate;

                        if (routeRequiresPE) {
                            if (!doesAtLeastOneYearIndicatePreventionUnits(situationHistory)) {
                                return redirectToNotFound();
                            }
                            if (isNotAllowedToAccessPEOverviewRoute(situationHistory, requestedAction)) {
                                return getRedirectRouteWhenUserIsNotAllowedToAccessPEOverview(
                                    situationHistory,
                                    showPreviousYearUntilDate,
                                );
                            }
                            // eslint-disable-next-line max-len
                            if (isNotAllowedToAccessPEPreviousYearRoute(situationHistory, requestedAction, showPreviousYearUntilDate)) {
                                return getRedirectRouteWhenUserIsNotAllowedToAccessPEPreviousYear(
                                    situationHistory,
                                );
                            }
                        }
                        if (routeRequiresFlatFee && !doesAtLeastOneYearIndicateFlatFee(situationHistory)) {
                            return redirectToNotFound();
                        }
                        return requestedAction;
                    },
                    requestedAction: action,
                }));
            }

            if (routeRequiresPE) {
                const situationHistory = getCompanySituationHistory(state);
                const showPreviousYearUntilDate = getShowPreventionUnitsPreviousYearUntilDate(state);
                if (!didCompanyOnceUsePreventionUnits(state)) {
                    return next(redirectToNotFound());
                }
                if (isNotAllowedToAccessPEOverviewRoute(situationHistory, action)) {
                    return next(getRedirectRouteWhenUserIsNotAllowedToAccessPEOverview(
                        situationHistory,
                        showPreviousYearUntilDate,
                    ));
                }
                if (isNotAllowedToAccessPEPreviousYearRoute(situationHistory, action, showPreviousYearUntilDate)) {
                    return next(getRedirectRouteWhenUserIsNotAllowedToAccessPEPreviousYear(
                        situationHistory,
                    ));
                }
            }
            if (routeRequiresFlatFee && !didCompanyOnceUseFlatFee(state)) {
                return next(redirectToNotFound());
            }
        }

        // route action is allowed
        return next(action);
    },
    latest: false,
});

function isNotAllowedToAccessPEOverviewRoute(
    companySituationHistory: TCompanySituationHistory,
    requestedAction: IAction<{}>,
) {
    if (
        requestedAction.type === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW ||
        requestedAction.type === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL
    ) {
        return !shouldPEOverviewBeActive(companySituationHistory);
    }

    return false;
}

function isNotAllowedToAccessPEPreviousYearRoute(
    companySituationHistory: TCompanySituationHistory,
    requestedAction: IAction<{}>,
    showPreviousYearUntilDate: string,
) {
    if (
        requestedAction.type === ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR ||
        requestedAction.type === ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL
    ) {
        return !shouldPEPreviousYearBeActive(companySituationHistory, showPreviousYearUntilDate);
    }

    return false;
}

function getRedirectRouteWhenUserIsNotAllowedToAccessPEOverview(
    companySituationHistory: TCompanySituationHistory,
    showPreviousYearUntilDate: string,
): IAction<{}> {
    if (shouldPEPreviousYearBeActive(companySituationHistory, showPreviousYearUntilDate)) {
        return {
            payload: {},
            type: ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR,
        };
    }
    return {
        payload: {},
        type: ROUTE_KEYS.R_PREVENTION_UNITS_ARCHIVE,
    };
}

function getRedirectRouteWhenUserIsNotAllowedToAccessPEPreviousYear(
    companySituationHistory: TCompanySituationHistory,
): IAction<{}> {
    if (shouldPEOverviewBeActive(companySituationHistory)) {
        return {
            payload: {},
            type: ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
        };
    }
    return {
        payload: {},
        type: ROUTE_KEYS.R_PREVENTION_UNITS_ARCHIVE,
    };
}

export function shouldUserFirstSelectACompanyOrSeat(
    routeKey: ROUTE_KEYS,
    state: IState,
): { indeed: boolean, selectedCompany?: ICompany, onboardingRoute?: ROUTE_KEYS } {
    if (routeKey === ROUTE_KEYS.R_COMPANY_SELECTION) {
        return {
            indeed: false,
        };
    }

    const selectedCompany = getSelectedCompany(state);
    if (!selectedCompany) {
        return {
            indeed: true,
        };
    }

    if (isOnboardingWizardRoute(routeKey)) {
        return {
            indeed: false,
        };
    }

    // Check if we need to start onboarding.
    const onboardingRoute = getOnboardingRoute(selectedCompany);
    if (onboardingRoute) {
        return {
            indeed: true,
            onboardingRoute,
        };
    }

    const selectedCompanySeat = getSelectedCompanySeat(state);
    if (!selectedCompanySeat || !selectedCompanySeat.companySeat) {
        return {
            indeed: true,
            selectedCompany,
        };
    }

    return {
        indeed: false,
    };
}

function isOnboardingWizardRoute(routeKey: ROUTE_KEYS) {
    const routesToCheck = [
        ROUTE_KEYS.R_ONBOARDING_START,
        ROUTE_KEYS.R_ONBOARDING_NEW,
        ROUTE_KEYS.R_ONBOARDING_FINISH,
        ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL,
    ];

    return routesToCheck.includes(routeKey);
}

function navigateToRouteIfInterimCompany(
    companyDetail: ICompanyDetail, requestedAction: IAction<{}>,
): IAction<{}> {
    const routeKey = requestedAction.type as ROUTE_KEYS;
    const requestedRoute = getRoute({ routeKey });
    if (companyDetail.interim) {
        return navigateTo(requestedRoute.redirectToRouteIfInterim);
    }
    return requestedAction;
}
