import React, { PureComponent, MouseEvent, ReactNode } from 'react';
import './header-dropdown.scss';
import classNames from 'classnames';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';

interface IPublicProps {
    id: string;
    className?: string;
    buttonContent: string | ReactNode;
    dropdownContent: ReactNode;
}

interface IHeaderDropdownState {
    isDropdownOpen: boolean;
    isMenuClosing: boolean;
}

const CLASS_NAME = 'HeaderDropdown';

export default class HeaderDropdown extends PureComponent<IPublicProps, IHeaderDropdownState> {
    private wrapperRef: HTMLElement;
    private boundHandleClickOutside: (e) => void;

    constructor(props: IPublicProps) {
        super(props);

        this.state = {
            isDropdownOpen: false,
            isMenuClosing: false,
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.boundHandleClickOutside = this.handleClickOutside.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);

        if (document) {
            document.addEventListener('click', this.boundHandleClickOutside);
        }
    }

    public render() {
        const { id, buttonContent, dropdownContent, className } = this.props;
        const { isDropdownOpen, isMenuClosing } = this.state;

        return (
            <div
                className={classNames(CLASS_NAME, {
                    open: isDropdownOpen,
                    closing: isMenuClosing,
                    [className]: !!className,
                })}
                ref={this.setWrapperRef}
                onTransitionEnd={this.onTransitionEnd}
            >
                <Button
                    id={id}
                    onClick={this.toggleDropdown}
                    className={`${CLASS_NAME}__button`}
                >
                    {buttonContent}
                    <Icon typeName="chevron-down" />
                </Button>
                <div className={`${CLASS_NAME}__dropdown`}>
                    {dropdownContent}
                </div>
            </div>
        );
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.boundHandleClickOutside);
    }

    private setWrapperRef(node: HTMLElement) {
        this.wrapperRef = node;
    }

    private handleClickOutside(e: Event) {
        if (!this.state.isDropdownOpen) {
            return;
        }

        if (this.wrapperRef && !this.wrapperRef.contains(e.target as HTMLElement)) {
            this.closeDropdown();
        }
    }

    private toggleDropdown(e: MouseEvent<HTMLElement>) {
        e.preventDefault();

        if (!this.state.isDropdownOpen) {
            this.openDropdown();
        } else {
            this.closeDropdown();
        }
    }

    private openDropdown() {
        this.setState({
            isDropdownOpen: true,
        });
    }

    private closeDropdown() {
        this.setState({
            isDropdownOpen: false,
            isMenuClosing: true,
        });
    }

    private onTransitionEnd() {
        this.setState({
            isMenuClosing: false,
        });
    }
}
