import { DayElement, Instance } from 'flatpickr/dist/types/instance';
import { Plugin } from 'flatpickr/dist/types/options';

export interface ICustomDayStyle {
    date: Date;
    className?: string;
    styles?: Partial<CSSStyleDeclaration>;
    childEl?: HTMLElement;
}

interface IConfig {
    getCustomDayStyles: () => ICustomDayStyle[];
}

function customDayPlugin(config: IConfig): Plugin<{}> {
    // eslint-disable-next-line func-names
    return function (fp) {
        function renderCustomDay(dates: Date[], currentDateString: string, fp: Instance, dayElem: DayElement) {
            const customDayStyles = config.getCustomDayStyles();
            if (!customDayStyles || !dayElem || !dayElem.dateObj) {
                return;
            }
            const customProps = customDayStyles.find((item) => item.date.getTime() === dayElem.dateObj.getTime());
            if (customProps && customProps.childEl) {
                dayElem.appendChild(customProps.childEl);
            }
            if (customProps && customProps.styles) {
                Object.keys(customProps.styles).forEach((styleAttr) => {
                    dayElem.style[styleAttr] = customProps.styles[styleAttr];
                });
            }
            if (customProps && customProps.className) {
                const classes = customProps.className.split(' ');
                classes.forEach((className) => dayElem.classList.add(className));
            }
        }

        return {
            onDayCreate: renderCustomDay,
        };
    };
}

export default customDayPlugin;
