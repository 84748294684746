import isSet from '@snipsonian/core/es/is/isSet';
import {
    getSelectedCompanySeat,
    getSelectedSeatCompanyCode,
} from '../../company/selected/selectors';
import { IState } from '../../IState';
import { getQueryParams } from '../../location/selectors';
import { IFetchEmployeesPayload } from '../../../models/admin/employee';
import { INITIAL_FETCH_EMPLOYEES_PARAMETERS } from '../../../config/administration.config';
import Api from '../../../api';
import { separateStringList } from '../../../utils/core/string/separatedStringList';

export function doFetchEmployeesCall({
    actionPayload,
    api,
    state,
}: {
    actionPayload: IFetchEmployeesPayload,
    api: typeof Api,
    state: IState,
}) {
    const {
        maxRecordCount,
        refreshSources,
        showFutureEmployees,
        search,
        nameFilter,
        companyCode: companyCodeFromPayload,
        showFullFamily: showFullFamilyFromPayload,
    } = getFetchEmployeesPayload(actionPayload, state);

    const companyCode = isSet(companyCodeFromPayload) ?
    companyCodeFromPayload :
    getSelectedSeatCompanyCode(state);

    const showFullFamily = isSet(showFullFamilyFromPayload) ?
        showFullFamilyFromPayload ? 'true' : 'false' :
        getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';

    const query = getQueryParams(state) as IFetchEmployeesPayload;
    const { seatFilter, functionFilter, conditionFilter } = query;

    const conditionsFromFilter = separateStringList(conditionFilter);

    return api.admin.employee.fetchEmployees(
        companyCode,
        {
            refreshSources,
            maxRecordCount,
            showFullFamily,
            // tslint:disable-next-line no-boolean-literal-compare
            showFutureEmployees: showFutureEmployees === false ? 'false' : 'true', // default true
        },
        {
            search,
            company_name: seatFilter,
            function_description: functionFilter,
            name: nameFilter,
            condition: conditionsFromFilter,
        },
    );
}

export function getFetchEmployeesPayload(actionPayload: IFetchEmployeesPayload, state: IState) {
    const query = getQueryParams(state) as IFetchEmployeesPayload;
    const { seatFilter, functionFilter, nameFilter, conditionFilter, ...queryPayload } = query;

    return { ...INITIAL_FETCH_EMPLOYEES_PARAMETERS, ...queryPayload, ...actionPayload };
}
