import { object, date } from 'yup';
import { ShapeOf } from '../../../../models/ui/form';
import { FormValues } from '.';
import string from '../../../../utils/libs/yup/string';
import phoneNumber from '../../../../utils/libs/yup/phoneNumber';

export const fields: ShapeOf<FormValues> = {
    agencyName: 'agencyName',
    agencyAddress: 'agencyAddress',
    agencyPhoneContact: 'agencyPhoneContact',
    agencyPbw: 'agencyPbw',
    agencyDateOfIssue: 'agencyDateOfIssue',
    workerName: 'workerName',
    workerFirstName: 'workerFirstName',
    workerPhone: 'workerPhone',
    workerDateOfBirth: 'workerDateOfBirth',
    workerQualification: 'workerQualification',
};

export const schema = object({
    [fields.agencyName]: string(),
    [fields.agencyPhoneContact]: phoneNumber(),
    [fields.agencyPbw]: string(),
    [fields.agencyDateOfIssue]: date(),
    [fields.workerName]: string(),
    [fields.workerFirstName]: string(),
    [fields.workerPhone]: phoneNumber(),
    [fields.workerDateOfBirth]: date(),
    [fields.workerQualification]: string(),
});
