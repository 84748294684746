import React from 'react';
import classNames from 'classnames';
import { schema } from '../../../shared/ContactInput/schema';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { getCompanyContactsAsyncInfo } from '../../../../../redux/company/info/selectors';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import ContactInput, { IFormValues } from '../../../shared/ContactInput/ContactInput';
import { getInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/selectors';
import { updateInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/actions';

const CLASS_NAME = 'InterventionRequest__Contact';
const FORM_NAME = 'intervention-request-contact-form';

const ContactWizardFormStep = WizardFormStep<IFormValues>();

export default function Contact(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <ContactWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={classNames(CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT)}
            header={{
                titleTranslationKey: 'interventions.intervention_request_new.steps.contact.title',
            }}
            showLoaderSelector={(state) => getCompanyContactsAsyncInfo(state).status}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getInterventionRequestWizardEntity(state);

                    return {
                        contact: wizardEntity.contact,
                    };
                },
                renderStepFormContent: (renderProps) => (
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <ContactInput {...renderProps} />
                    </div>
                ),
                onChangeInputActionCreator: ({ values }) => updateInterventionRequestWizardEntity({
                    contact: values.contact,
                }),
            }}
        />
    );
}
