import { createAction, createSuccessAction, createFailAction } from '../../index';
import {
    FETCH_COMPANY_FUNCTION_ISCO,
    FETCH_COMPANY_FUNCTION_RISKS,
    FETCH_COMPANY_FUNCTION_RESEARCHES,
    FETCH_COMPANY_FUNCTIONS,
    FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD,
    ADD_COMPANY_FUNCTION,
} from './types';
import {
    IRisk, IFetchCompanyFunctionRisksPayload,
} from '../../../models/admin/risks';
import {
    ICompanyFunctionIsco,
    IFetchCompanyFunctionIscoPayload,
    IFetchCompanyFunctionsPayload,
    ICompanyFunction,
    IAddedCompanyFunction, IAddCompanyFunctionPayload,
} from '../../../models/admin/companyFunctions';
import { IResearch, IFetchCompanyFunctionResearchesPayload } from '../../../models/admin/researches';
import { ITraceableApiError } from '../../../models/general/error';

export function fetchCompanyFunctionIsco(payload: IFetchCompanyFunctionIscoPayload) {
    return createAction<IFetchCompanyFunctionIscoPayload>(FETCH_COMPANY_FUNCTION_ISCO, payload);
}

export function fetchCompanyFunctionIscoSucceeded(payload: ICompanyFunctionIsco[]) {
    return createSuccessAction<ICompanyFunctionIsco[]>(FETCH_COMPANY_FUNCTION_ISCO, payload);
}

export function fetchCompanyFunctionIscoFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTION_ISCO, error);
}

export function fetchCompanyFunctionRisks(payload: IFetchCompanyFunctionRisksPayload) {
    return createAction<IFetchCompanyFunctionResearchesPayload>(FETCH_COMPANY_FUNCTION_RISKS, payload);
}

export function fetchCompanyFunctionRisksSucceeded(payload: IRisk[]) {
    return createSuccessAction<IRisk[]>(FETCH_COMPANY_FUNCTION_RISKS, payload);
}

export function fetchCompanyFunctionRisksFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTION_RISKS, error);
}

export function fetchCompanyFunctionResearches(payload: IFetchCompanyFunctionResearchesPayload) {
    return createAction<IFetchCompanyFunctionResearchesPayload>(FETCH_COMPANY_FUNCTION_RESEARCHES, payload);
}

export function fetchCompanyFunctionResearchesSucceeded(payload: IResearch[]) {
    return createSuccessAction<IResearch[]>(FETCH_COMPANY_FUNCTION_RESEARCHES, payload);
}

export function fetchCompanyFunctionResearchesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTION_RESEARCHES, error);
}

// Company functions
export function fetchCompanyFunctions(payload: IFetchCompanyFunctionsPayload) {
    return createAction<IFetchCompanyFunctionsPayload>(FETCH_COMPANY_FUNCTIONS, payload);
}

export function fetchCompanyFunctionsSucceeded(payload: ICompanyFunction[]) {
    return createSuccessAction<ICompanyFunction[]>(FETCH_COMPANY_FUNCTIONS, payload);
}

export function fetchCompanyFunctionsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTIONS, error);
}

// Company functions for typeahead
export function fetchCompanyFunctionsForTypeahead(payload: IFetchCompanyFunctionsPayload) {
    return createAction<IFetchCompanyFunctionsPayload>(FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD, payload);
}

export function fetchCompanyFunctionsForTypeaheadSucceeded(payload: ICompanyFunction[]) {
    return createSuccessAction<ICompanyFunction[]>(FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD, payload);
}

export function fetchCompanyFunctionsForTypeaheadFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD, error);
}

// Add company function
export function addCompanyFunction(payload: IAddCompanyFunctionPayload) {
    return createAction(ADD_COMPANY_FUNCTION, payload);
}

export function addCompanyFunctionSucceeded(payload: IAddedCompanyFunction) {
    return createSuccessAction<IAddedCompanyFunction>(ADD_COMPANY_FUNCTION, payload);
}

export function addCompanyFunctionFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(ADD_COMPANY_FUNCTION, error);
}
