import React, { PureComponent } from 'react';
import { clone, pathOr } from 'ramda';

import { hoursOffsetFromNow, getDate } from '../../../../../../utils/core/date/getSpecificDate';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../../config/medicalExamination.config';
import { ListColumns, ListItem } from '../../../../../../models/general/list';
import Translate from '../../../../../common/Translate';
import ListWithSorting from '../../../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import FreeSlotsIndicator from '../../../../../common/widget/FreeSlotsIndicator';
import ShowIfAllowed from '../../../../../auth/ShowIfAllowed';
import ListItemActions from '../../../../../common/list/ListItemActions';
import Button from '../../../../../common/buttons/Button';
import Icon from '../../../../../common/icons/Icon';
import {
    COLUMNS,
    BASE_NAME,
    INITIAL_SORT,
    DEFAULT_NR_OF_RECORDS_TO_SHOW,
    TRANSLATION_PREFIX,
} from '../ReservedMedicalExaminationOverview.const';
import { IColumnNames } from '../ReservedMedicalExaminationOverview.type';

import { IReservedMedicalExaminationsListProps } from './ReservedMedicalExaminationsList.type';

class ReservedMedicalExaminationsList extends
    PureComponent<IReservedMedicalExaminationsListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: IReservedMedicalExaminationsListProps) {
        super(props);

        this.renderActions = this.renderActions.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
            onItemSelected,
        } = this.props;

        this.columns.freeSlots.render = this.renderFreeSlots;
        this.columns.actions.render = this.renderActions;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
                onItemRowClicked={onItemSelected}
            />
        );
    }

    private renderActions(listItem: ListItem<IColumnNames>) {
        const { triggerPlanBufferzoneWizardWithSelectedBufferzone } = this.props;
        const isPlannable = pathOr<boolean>(false, ['data', 'isPlannable'], listItem);

        const isPlanningAllowed =
            hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() <=
            getDate(listItem.columns.endTime as string) && isPlannable;

        if (!isPlanningAllowed) {
            return <span/>;
        }

        return (
            <ListItemActions>
                <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                    <Button
                        id={`plan-bufferzone-${listItem.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            triggerPlanBufferzoneWizardWithSelectedBufferzone(listItem.id as string);
                        }}
                    >
                        <span>
                            <Translate msg={`${TRANSLATION_PREFIX}.actions.plan_bufferzone`} />
                        </span>
                        <Icon circle typeName="calendar" />
                    </Button>
                </ShowIfAllowed>
            </ListItemActions>
        );
    }

    private renderFreeSlots(item: ListItem<IColumnNames>) {
        return (
            <FreeSlotsIndicator
                maxSlots={item.columns.totalSlots as number}
                freeSlots={item.columns.freeSlots as number}
                showMaxSlots
            />
        );
    }
}

export { ReservedMedicalExaminationsList };
