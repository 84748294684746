import React from 'react';
import classNames from 'classnames';
import './label-info.scss';
import Translate from '../../Translate';

const EMPTY_PLACEHOLDER = '-';

interface ILabelInfoProps {
    label?: string;
    labelKey?: string; // will be translated
    info: string | React.ReactNode;
    className?: string;
    isInfoMultiLine?: boolean;
    hideColon?: boolean;
}

const LabelInfo = (props: ILabelInfoProps) => {
    return (
        <div className={classNames('LabelInfo', props.className)}>
            <div className="label-wrapper">
                <label>{props.labelKey ? <Translate msg={props.labelKey} /> : props.label}</label>
                {!props.hideColon && <span>:</span>}
            </div>
            <div className={classNames('info-wrapper', { multiline: props.isInfoMultiLine })}>
                {props.info || EMPTY_PLACEHOLDER}
            </div>
        </div>
    );
};

export default LabelInfo;
