import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';

import { ITimeSlotRemarksPrivateProps, ITimeSlotRemarksProps } from './TimeSlotRemarks.type';

export const addOrMoveTimeslotSucceeded = (
    prevProps: ITimeSlotRemarksPrivateProps & ITimeSlotRemarksProps,
    props: ITimeSlotRemarksPrivateProps & ITimeSlotRemarksProps,
): boolean => {
    const addTimeSlotSuccess =
        prevProps.addTimeSlotAsyncInfo.status === AsyncStatus.Busy
        && props.addTimeSlotAsyncInfo.status === AsyncStatus.Success;

    const moveTimeSlotSuccess =
        prevProps.moveTimeSlotAsyncInfo.status === AsyncStatus.Busy
        && props.moveTimeSlotAsyncInfo.status === AsyncStatus.Success;

    return (addTimeSlotSuccess || moveTimeSlotSuccess);
};

export const showLoaderIfBusy = (
    addTimeSlotAsyncInfo: IAsyncFieldInfo,
    moveTimeSlotAsyncInfo: IAsyncFieldInfo,
): boolean => {
    const addTimeSlotBusy = addTimeSlotAsyncInfo.status === AsyncStatus.Busy;
    const moveTimeSlotBusy = moveTimeSlotAsyncInfo.status === AsyncStatus.Busy;

    return addTimeSlotBusy || moveTimeSlotBusy;
};
