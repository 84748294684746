import React, { ReactNode } from 'react';
import { createSelector } from 'reselect';
import './courses.scss';
import connect from '../../../utils/libs/redux/connect';
import { ITabItem } from '../../../models/general/navigation';
import ROUTE_KEYS from '../../../routeKeys';
import { getCoursesOverview } from '../../../redux/documentCenter/courses/selectors';
import PageHeader from '../../appShell/PageHeader';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import { toFlattenedCoursesInTheSpotlight } from './Overview';
import classNames from 'classnames';

interface IPrivateProps {
    hasCoursesInTheSpotlight: boolean;
}

interface IPublicProps {
    children: ReactNode;
}

const CLASS_NAME = 'Courses';

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const coursesInTheSpotlightMemoizedSelector = createSelector(
            getCoursesOverview,
            (coursesByCategories) => toFlattenedCoursesInTheSpotlight(coursesByCategories),
        );

        return {
            hasCoursesInTheSpotlight: coursesInTheSpotlightMemoizedSelector(state).length > 0,
        };
    },
})(CoursesTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function CoursesTemplate(props: IPrivateProps & IPublicProps) {
    const {
        children,
        hasCoursesInTheSpotlight,
    } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'document_center.courses.overview.tab_title',
        linkTo: ROUTE_KEYS.R_COURSES_OVERVIEW,
    }, {
        translationKey: 'document_center.courses.planned.tab_title',
        linkTo: ROUTE_KEYS.R_COURSES_PLANNED,
    }, {
        translationKey: 'document_center.courses.followed.tab_title',
        linkTo: ROUTE_KEYS.R_COURSES_FOLLOWED,
    }, /* {
        translationKey: 'document_center.courses.certificates.tab_title',
        linkTo: ROUTE_KEYS.R_COURSES_CERTIFICATES,
    } */];

    return (
        <WithMenuTemplate>
            <div
                className={classNames(CLASS_NAME, {
                    [`${CLASS_NAME}--has-spotlight-courses`]: hasCoursesInTheSpotlight,
                })}
            >
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS}
                    title="document_center.courses.title"
                    type="grey"
                    text="document_center.courses.text"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
