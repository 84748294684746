import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER, makeAsyncDoInfoSelector } from '../../index';
import {
    ICourse,
    ICourseSession,
    ICourseEnrollWizardData,
    ICertificate,
    ICertificateEmployee,
    ICourseInfo,
    ICourseExternalEmployee,
} from '../../../models/documentCenter/courses';
import { getPropertyFromRoutePayload, getRoutePayload } from '../../location/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Courses planned
const getCoursesPlannedAsyncField = (state: IState) =>
    reducerState(state).coursesPlanned;

export const getCoursesPlannedAsyncInfo = makeAsyncFetchInfoSelector(getCoursesPlannedAsyncField);

export const getCoursesPlanned = (state: IState) =>
    getCoursesPlannedAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areCoursesPlannedAvailable = (state: IState) =>
    getCoursesPlannedAsyncField(state).data !== null;

// Planned course detail
const getFetchPlannedCourseDetailAsyncField = (state: IState) =>
    reducerState(state).selectedPlannedCourse;

export const getFetchPlannedCourseDetailAsyncInfo = makeAsyncFetchInfoSelector(getFetchPlannedCourseDetailAsyncField);

export const getSelectedPlannedCourse = (state: IState) =>
    getFetchPlannedCourseDetailAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

export const getSelectedPlannedCourseFromList = (state: IState, id: number) =>
    getCoursesPlanned(state)
        .find((item: ICourse) => item.coursesOrganizedId === id);

// Course session
const getFetchCourseSessionAsyncField = (state: IState) =>
    reducerState(state).selectedCourseSession;

export const getFetchCourseSessionAsyncInfo = makeAsyncFetchInfoSelector(getFetchCourseSessionAsyncField);

export const getSelectedCourseSession = (state: IState): ICourseSession =>
    getFetchCourseSessionAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT as ICourseSession;

// Course session days
const getFetchCourseSessionDaysAsyncField = (state: IState) =>
    reducerState(state).courseSessionDays;

export const getFetchCourseSessionDaysAsyncInfo = makeAsyncFetchInfoSelector(getFetchCourseSessionDaysAsyncField);

export const getCourseSessionDays = (state: IState) =>
    getFetchCourseSessionDaysAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Course session attendees
const getFetchCourseSessionAttendeesAsyncField = (state: IState) =>
    reducerState(state).courseSessionAttendees;

export const getFetchCourseSessionAttendeesAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCourseSessionAttendeesAsyncField);

export const getCourseSessionAttendees = (state: IState) =>
    getFetchCourseSessionAttendeesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Courses followed
const getCoursesFollowedAsyncField = (state: IState) =>
    reducerState(state).courseFollowed;

export const getCoursesFollowedAsyncInfo = makeAsyncFetchInfoSelector(getCoursesFollowedAsyncField);

export const getCoursesFollowed = (state: IState) =>
    getCoursesFollowedAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areCoursesFollowedAvailable = (state: IState) =>
    getCoursesFollowedAsyncField(state).data !== null;

// Followed course detail
export const getSelectedFollowedCourseFromList = (state: IState, id: number) =>
    getCoursesFollowed(state)
        .find((item: ICourse) => item.coursesOrganizedId === id);

export const getSelectedFollowedCoursesOrganizedId = (state: IState) => {
    return getRoutePayload<{ coursesOrganizedId: number }>(state).coursesOrganizedId || null;
};

export const getSelectedFollowedCourse = (state: IState) => {
    const coursesOrganizedId = getSelectedFollowedCoursesOrganizedId(state);

    if (coursesOrganizedId) {
        const selectedFollowedCourse = getSelectedFollowedCourseFromList(state, coursesOrganizedId);

        return selectedFollowedCourse;
    }

    return null;
};

// Course session certificates
const getFetchCourseSessionCertificatesAsyncField = (state: IState) =>
    reducerState(state).courseSessionCertificates;

export const getFetchCourseSessionCertificatesAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCourseSessionCertificatesAsyncField);

export const getCourseSessionCertificates = (state: IState) =>
    getFetchCourseSessionCertificatesAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

// Course overview from mensura CMS
const getFetchCoursesOverviewAsyncField = (state: IState) =>
    reducerState(state).coursesOverview;

export const getFetchCoursesOverviewAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCoursesOverviewAsyncField);

export const getCoursesOverview = (state: IState) =>
    getFetchCoursesOverviewAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const isCoursesOverviewAvailable = (state: IState) =>
    getFetchCoursesOverviewAsyncField(state).data !== null;

// Remove course attendee
const getRemoveCourseAttendeeAsyncField = (state: IState) =>
    reducerState(state).removeCourseAttendee;

export const getRemoveCourseAttendeeAsyncInfo = makeAsyncDoInfoSelector(getRemoveCourseAttendeeAsyncField);

export const isRemoveAttendeeNotPossible = (state: IState) => reducerState(state).removeAttendeeNotPossible;

// Course overview detail from mensura CMS
export const getSelectedCourseIntroductionId = (state: IState) =>
    getPropertyFromRoutePayload(state, 'nodeId');

const getCoursesOverviewDetailAsyncField = (state: IState) =>
    reducerState(state).coursesOverviewDetail;

export const getCoursesOverviewDetailAsyncInfo =
    makeAsyncFetchInfoSelector(getCoursesOverviewDetailAsyncField);

export const getCoursesOverviewDetail = (state: IState) =>
    getCoursesOverviewDetailAsyncField(state).data;

export const getChildCourse = (state: IState, id) =>
    getCoursesOverviewDetail(state) && getCoursesOverviewDetail(state)
        .children
        .find((c) => c.course.nodeId === id);

const getCoursesOverviewDetailByHawAsyncField = (state: IState) =>
    reducerState(state).coursesOverviewDetailHaw;

export const getCoursesOverviewDetailByHawAsyncInfo =
    makeAsyncFetchInfoSelector(getCoursesOverviewDetailByHawAsyncField);

// Course table
const getCourseSessionsAsyncField = (state: IState) =>
    reducerState(state).courseSessions;

export const getCourseSessionsAsyncInfo =
    makeAsyncFetchInfoSelector(getCourseSessionsAsyncField);

export const getCourseSessions = (state: IState) =>
    getCourseSessionsAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

// Course session attendance
const getFetchCourseSessionAttendanceAsyncField = (state: IState) =>
    reducerState(state).courseSessionAttendance;

export const getFetchCourseSessionAttendanceAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCourseSessionAttendanceAsyncField);

export const getCourseSessionAttendance = (state: IState) =>
    getFetchCourseSessionAttendanceAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getSelectedCourseSessionAttendantEmployeeId = (state: IState) => {
    return getRoutePayload<{ employeeId: number }>(state).employeeId;
};

export const getSelectedCourseSessionAttendant = (state: IState) => {
    const employeeId = getSelectedCourseSessionAttendantEmployeeId(state);

    if (employeeId) {
        return getCourseSessionAttendance(state)
            .find((item) => item.employeeId === employeeId) || null;
    }

    return null;
};

// Course enroll wizard
const getCourseEnrollWizardData = (state: IState) =>
    (reducerState(state).courseEnrollWizardData || NO_RERENDER.EMPTY_OBJECT) as ICourseEnrollWizardData;

export const getCourseEnrollWizardStepId = (state: IState) =>
    getCourseEnrollWizardData(state).stepId;

export const getCourseEnrollWizardEntity = (state: IState) =>
    getCourseEnrollWizardData(state).entity;

export const getCourseEnrollWizardCourse = (state: IState): ICourseInfo =>
    getCourseEnrollWizardData(state).course || NO_RERENDER.EMPTY_OBJECT as ICourseInfo;

// Create course enrollment
const getCreateCourseEnrollAsyncField = (state: IState) =>
    reducerState(state).createCourseEnrollment;

export const getCreateCourseEnrollAsyncInfo = makeAsyncDoInfoSelector(getCreateCourseEnrollAsyncField);

// Course possible locations
const getFetchCoursePossibleLocationsAsyncField = (state: IState) =>
    reducerState(state).coursePossibleLocations;

export const getFetchCoursePossibleLocationsAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCoursePossibleLocationsAsyncField);

export const getCoursePossibleLocations = (state: IState) =>
    getFetchCoursePossibleLocationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areCoursePossibleLocationsAvailable = (state: IState) =>
    Array.isArray(getFetchCoursePossibleLocationsAsyncField(state).data);

// Course sessions by location
const getFetchCourseSessionsByLocationsAsyncField = (state: IState) =>
    reducerState(state).courseSessionsByLocation;

export const getFetchCourseSessionsByLocationsAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCoursePossibleLocationsAsyncField);

export const getCourseSessionsByLocation = (state: IState) =>
    getFetchCourseSessionsByLocationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areCourseSessionsByLocationAvailable = (state: IState) =>
    Array.isArray(getFetchCourseSessionsByLocationsAsyncField(state).data);

// Certificates
const getFetchCertificatesAsyncField = (state: IState) =>
    reducerState(state).certificates;

export const getFetchCertificatesAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCertificatesAsyncField);

export const getCertificates = (state: IState) =>
    getFetchCertificatesAsyncField(state).data || NO_RERENDER.EMPTY_LIST as ICertificate[];

export const areCertificatesAvailable = (state: IState) =>
    Array.isArray(getFetchCertificatesAsyncField(state).data);

export const getCertificateFromListByCode = (state: IState, code: string) => {
    return getCertificates(state).find((certificate) => certificate.code === code);
};

// Certificate detail
const getFetchCertificateDetailAsyncField = (state: IState) =>
    reducerState(state).selectedCertificate;

export const getFetchCertificateDetailAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCertificateDetailAsyncField);

export const getSelectedCertificate = (state: IState) =>
    getFetchCertificateDetailAsyncField(state).data || NO_RERENDER.EMPTY_LIST as ICertificateEmployee[];

export const getSelectedCertificateCode = (state: IState) => {
    return getRoutePayload<{ code: string }>(state).code || null;
};

// Employee courses
const getFetchEmployeeCoursesAsyncField = (state: IState) =>
    reducerState(state).employeeCourses;

export const getFetchEmployeeCoursesAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchEmployeeCoursesAsyncField);

export const getEmployeeCourses = (state: IState) =>
    getFetchEmployeeCoursesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Fetch course external employee
const getFetchCourseExternalEmployeeAsyncField = (state: IState) =>
    reducerState(state).courseExternalEmployee;

export const getFetchCourseExternalEmployeeAsyncInfo =
    makeAsyncFetchInfoSelector(getFetchCourseExternalEmployeeAsyncField);

export const getCourseExternalEmployee = (state: IState) =>
getFetchCourseExternalEmployeeAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT as ICourseExternalEmployee;

// Update course external employee
const getUpdateCourseExternalEmployeeAsyncField = (state: IState) =>
    reducerState(state).updateCourseExternalEmployee;

export const getUpdateCourseExternalEmployeeAsyncInfo =
    makeAsyncDoInfoSelector(getUpdateCourseExternalEmployeeAsyncField);
