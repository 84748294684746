import React, { useState, useEffect } from 'react';
import LazyLoadEmbed from '../../../widget/LazyLoadEmbed';
import { IVideoPagePartProps } from './shared';


interface ILazyPlaceholderLoadImage {
    url: string;
    size?: string;
}

export default function RemoteVideo({ data, hostname }: IVideoPagePartProps) {
    const videoId = data.video.metadata.code;
    const provider = data.video.metadata.type;
    const [thumbnail, setThumbnail] = useState<string>(null);
    const url = getVideoUrl();

    useEffect(() => {
        if (hostname && data.image) {
            setThumbnail(`${hostname}${data.image.url}`);
        } else {
            if (provider === 'youtube') {
                getBestQualityYoutubeThumbnailUrl(videoId);
            } else if (provider === 'vimeo') {
                getVimeoThumbnailUrl(videoId);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LazyLoadEmbed
            embedTitle={`Video: ${data.video.name}`}
            embedUrl={url}
            placeholderUrl={thumbnail}
            placeholderAlt={data.image && data.image.name}
        />
    );

    function getVideoUrl() {
        const provider = data.video.metadata.type;
        const id = data.video.metadata.code;

        switch (provider) {
            case 'youtube':
                return `//www.youtube.com/embed/${id}?title=0&amp;byline=0&amp;portrait=0;&amp;badge=0&amp;autoplay=1`;
            case 'vimeo':
                return `//player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autoplay=1`;
        }

        return null;
    }

    async function getBestQualityYoutubeThumbnailUrl(id: string) {
        const imageUrls = [];
        const resolution = [
            'maxresdefault',
            'sddefault',
            'mqdefault',
            'hqdefault',
            'default',
        ];

        const loadImg = (...images: ILazyPlaceholderLoadImage[]) => Promise.all(images.map(checkImage));

        resolution.forEach((size) => {
            const url = `//img.youtube.com/vi/${id}/${size}.jpg`;
            const imageObject: ILazyPlaceholderLoadImage = { size, url };
            imageUrls.push(imageObject);
        });

        loadImg(...imageUrls)
            .then((values: { image: ILazyPlaceholderLoadImage }[]) => {
                setThumbnail(values[0].image.url);
            });
    }

    async function getVimeoThumbnailUrl(vimeoId: string) {
        const url = `//vumbnail.com/${vimeoId}.jpg`;
        return checkImage({ url }).then(({ image, status }) => {
            if (status === 'ok') {
                setThumbnail(image.url);
            }
        });
    }
}

function checkImage(image: ILazyPlaceholderLoadImage) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({ image, status: 'ok' });
        };
        img.onerror = () => resolve({ image, status: 'error' });
        img.src = image.url;
    });
}
