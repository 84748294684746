import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableTopTaskTile from '../SortableTopTaskTile';
import { TaskId, ISelectedTask } from '../../../../../models/user/topTasks';

interface ISelectedTopTasksListProps {
    items: ISelectedTask[];
    onTopTaskSelected: (id: TaskId, index: number) => void;
}

const SelectedTopTasksList = SortableContainer<ISelectedTopTasksListProps>(({ items, onTopTaskSelected }) => {
    return (
        <ul>
            {Array.isArray(items) && items.map((task, index) => {
                return (
                    <SortableTopTaskTile
                        key={`${index}-${task.id}`}
                        index={index}
                        taskIndex={index}
                        task={task}
                        onTopTaskSelected={onTopTaskSelected}
                    />
                );
            })
            }
        </ul>
    );
});

export default SelectedTopTasksList;
