/**
 * IMPORTANT:
 * - The id's are stored in the CMS databse, so don't just change them,
 *   even though they are currently also used to generate the translation key's!
 */
import ROUTE_KEYS from '../../routeKeys';
import { ILocationAction } from '../general/redux';
import { TOP_TASKS_SVG_IDS } from '../general/lazyLoadSvg';

export enum TaskId {
    /* Add or report */
    ADD_COMPANY_FUNCTION = 'add_company_function',
    ADD_EMPLOYEE = 'add_employee',
    ADD_USER = 'add_user',

    /* Plan or request */
    PLAN_COMPANY_VISIT = 'plan_company_visit',
    ORDER_FLU_VACCINES = 'order_flu_vaccines',
    PLAN_MEDICAL_EXAMINATION = 'plan_medical_examination',
    PLAN_ENVIROMENTAL_INTERVENTION = 'plan_environmental_intervention',
    PLAN_ERGONOMIC_INTERVENTION = 'plan_ergonomic_intervention',
    PLAN_HEALTH_INTERVENTION = 'plan_health_intervention',
    PLAN_PSYCHOLOGICAL_INTERVENTION = 'plan_psychological_intervention',
    PLAN_SAFETY_INTERVENTION = 'plan_safety_intervention',
    PLAN_TOXILOGICAL_INTERNVENTION = 'plan_toxilogical_intervention',
    PLAN_OTHER_INTERVENTION = 'plan_other_intervention',
    ACTIVITY_REPORT = 'activity_report',

    /* Manage */
    MANAGE_AGENDA = 'manage_agenda',
    MANAGE_BUFFERZONES = 'manage_bufferzones',
    MANAGE_COMPANY_FUNCTIONS = 'manage_company_functions',
    MANAGE_COMPANY_INFO = 'manage_company_info',
    MANAGE_COMPANY_RISKS = 'manage_company_risks',
    MANAGE_COMPANY_VISITS = 'manage_company_visits',
    MANAGE_COURSES = 'manage_courses',
    MANAGE_FLU_VACCINES = 'manage_flu_vaccines',
    MANAGE_MEDICAL_EXAMINATIONS = 'manage_medical_examinations',
    MANAGE_ENVIROMENTAL_INTERVENTIONS = 'manage_environmental_interventions',
    MANAGE_MISSING_EMAILS = 'manage_missing_emails',
    MANAGE_ERGONOMIC_INTERVENTIONS = 'manage_ergonomic_interventions',
    MANAGE_HEALTH_INTERVENTIONS = 'manage_health_interventions',
    MANAGE_POLICY_ADVICE = 'manage_policy_advice',
    MANAGE_PSYCHOLOGICAL_INTERVENTIONS = 'manage_psychological_interventions',
    MANAGE_SAFETY_INTERVENTIONS = 'manage_safety_interventions',
    MANAGE_TOXILOGICAL_INTERNVENTIONS = 'manage_toxilogical_interventions',
    MANAGE_OTHER_INTERVENTIONS = 'manage_other_interventions',
    MANAGE_INVOICES = 'manage_invoices',
    MANAGE_PREVENTION_UNITS = 'manage_prevention_units',
    MANAGE_FLAT_FEE = 'manage_flat_fee',
    MANAGE_RATES = 'manage_rates',
    MANAGE_WORK_ACCIDENTS = 'manage_work_accidents',
    MANAGE_USERS = 'manage_users',
    MANAGE_QUESTIONNAIRES = 'manage_questionnaires',
}

export enum TaskCategory {
    PLAN_OR_REQUEST = 'plan_or_request',
    ADD_OR_REPORT = 'add_or_report',
    MANAGE = 'manage',
}

export interface ITopTaskTargetRouteKey {
    key: ROUTE_KEYS;
    payload?: object;
}

export type TTopTaskTargetActionCreator = () => ILocationAction<{}>;

export interface ITopTask {
    id: TaskId;
    translationKey: string;
    lazyLoadSvgId: TOP_TASKS_SVG_IDS;
    targetRoute: ITopTaskTargetRouteKey | TTopTaskTargetActionCreator;
    category: TaskCategory;
}

export interface IUpdateTopTasksPayload {
    topTasks: {
        id: TaskId;
    }[];
}

export interface ISelectedTask extends ITopTask {
    dirty?: boolean;
}
