import { Locales } from '../../config/i18n.config';
import { DayParts } from '../general/daypart';
import { ICalendarEvent } from '../ui/calendar';
import { ILocalePayload } from '../general/i18n';

export interface IFetchAppointmentsPayload {
    companyCode: string;
    showFullFamily: boolean;
    dateFrom: string;
    dateUntil: string;
    query?: string;
}

export interface IFetchAppointmentsApiPayload extends ILocalePayload {
    companyCode: string;
    showFullFamily: boolean;
    start: string;
    end: string;
    query?: string;
}

export interface IFetchappointmentsResult {
    events: ICalendarEvent[];
    count: number;
}

export interface IAppointmentsInfo extends IFetchappointmentsResult {
    dateFrom: string;
    dateUntil: string;
}

export interface IFetchUpcomingActivityPayload {
    companyCode: string;
    showFullFamily: boolean;
    locale: Locales;
}

export enum UpcomingActivityType {
    Intervention = 'intervention',
    MedicalExamination = 'medical-examination',
    Course = 'course',
    CompanyVisit = 'company-visit'
}

export interface IUpcomingActivityBase {
    count: number;
    type: UpcomingActivityType;
    date: string; // Example: "2021-10-28T08:00:00",
    description?: string;
    participant?: {
        firstName: string;
        lastName: string;
    }
}

export interface IUpcomingMedicalExaminationActivity extends IUpcomingActivityBase {
    type: UpcomingActivityType.MedicalExamination,
}

export interface IUpcomingCourseActivity extends IUpcomingActivityBase {
    type: UpcomingActivityType.Course,
}

export interface IUpcomingCompanyVisitActivity extends IUpcomingActivityBase {
    type: UpcomingActivityType.CompanyVisit,
    dayPart?: DayParts;
}

export interface IUpcomingInterventionActivity extends IUpcomingActivityBase {
    type: UpcomingActivityType.CompanyVisit,
}

export type TUpcomingActivity =
    IUpcomingCompanyVisitActivity
    | IUpcomingCourseActivity
    | IUpcomingInterventionActivity
    | IUpcomingMedicalExaminationActivity;
