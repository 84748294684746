import { object, boolean } from 'yup';
import string from '../../../utils/libs/yup/string';

export const fields = {
    locale: 'locale',
    individualSeats: 'individualSeats',
};

export const schema = object({
    [fields.locale]: string().required(),
    [fields.individualSeats]: boolean(),
});
