import { CONTACT_SVG_IDS } from '../general/lazyLoadSvg';

export enum FeedbackType {
    COMPLAINT = 'complaint',
    QUESTION = 'question',
    SUGGESTION = 'suggestion',
}

export interface ISendCompanyFeedbackPayload {
    type: FeedbackType;
    feedback: string;
}

export interface IFeedbackItem {
    id: string;
    descriptionTranslationKey: string;
    lazyLoadSvgId: CONTACT_SVG_IDS;
    feedbackType: FeedbackType;
}
