import React, { PureComponent, ReactNode, CSSProperties, ReactElement, RefObject } from 'react';
import ReactList from 'react-list';
import classNames from 'classnames';
import isFunction from '@snipsonian/core/es/is/isFunction';
import './list.scss';
import Icon from '../../icons/Icon';
import { SortOrder, ListColumns, ListItem, ISortedColumn, IColumn, ListItemId } from '../../../../models/general/list';
import Translate from '../../Translate';
import { ITranslator } from '../../../../models/general/i18n';
import { IListFooterProps } from '../ListFooter';
import * as screenSizeUtils from '../../../../utils/dom/screenSize';
import findClosestScrollContainer from '../../../../utils/dom/findClosestScrollContainer';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import { REACT_LIST_DEFAULT_ITEM_SIZE } from '../../../../config/dom.config';
import createEvent from '../../../../utils/core/event/createEvent';

interface IContextProps {
    translator: ITranslator;
}

interface IRenderItemProps<ColumnNames> {
    index: number;
    key: string | number;
    columnNames: string[];
    item: ListItem<ColumnNames>;
}

interface IReactList extends ReactList {
    el: HTMLDivElement;
}

export interface IListProps<ColumnNames> {
    name: string;
    columns: ListColumns<ColumnNames>;
    items: ListItem<ColumnNames>[];
    selectedItemIds?: (string | number)[];
    sortedColumn?: ISortedColumn<ColumnNames>;
    onItemRowClicked?: (id: string | number) => void; // not clickable if not provided
    isItemClickable?: (item: ListItem<ColumnNames>) => boolean; // only has effect if 'onItemRowClicked' provided
    onColumnSortChanged?: (nextSortedColumn: ISortedColumn<ColumnNames>) => void;
    getCustomRowClasses?: (item: ListItem<ColumnNames>) => string;
    getTableCellClasses?: (columnKey: string, item?: ListItem<ColumnNames>) => string;
    noResultsMessage?: ReactNode;
    errorMessage?: ReactNode;
    fadeOutListItems?: boolean;
    maxNrOfRecordsToShow?: number;
    footer?: ReactElement<IListFooterProps>;
    idToScrollIntoView?: ListItemId;
    hideHeaders?: boolean;
    selectedColumnName?: string;
    onColumnClicked?: (columnName: string) => void;
    /**
     * 'itemSizeGetter' is a property for the 'react-list':
     * - A function that receives an item index and returns the height of that item at that index.
     *
     * In normal cases you don't have to provide this (in which case the 'itemSizeEstimator' will be used),
     * but there was an issue where the react-list rendered one less item than received (so 1 instead of 2)
     * presumably when the List was within a Modal. Specifying this itemSizeGetter fixed that issue.
     * Also see KZUAT-783
     */
    itemSizeGetter?: (index: number) => number;
    customTableContentRenderer?: (listItems: ListItem<ColumnNames>[]) => React.ReactNode;
}

interface IComponentState {
    isFixedHeaderAndFooterInitialized: boolean;
    tableHeaderIsFixed: boolean;
    tableFooterIsFixed: boolean;
    tableHeaderWidth: number;
    tableFooterWidth: number;
    hoveredColumnName: string;
}

const EMPTY_CELL_PLACEHOLDER = '-';
const OFFSET = {
    FIXED_HEADER: {
        TOP: -20,
        BOTTOM: 0,
    },
    FIXED_FOOTER: {
        TOP: 100,
        BOTTOM: 60,
    },
};

class ListComp<ColumnNames> extends PureComponent<IListProps<ColumnNames> & IContextProps, IComponentState> {
    private listElement: HTMLDivElement;
    private scrollContainer: HTMLElement | Window;
    private scrollIntoViewTimeout: number;
    private checkFixedElementsStatesTimeout: number;
    private refTableHeaderRelative: RefObject<HTMLDivElement>;
    private refTableFooterRelative: RefObject<HTMLDivElement>;
    private refTableContent: RefObject<HTMLDivElement>;
    private setHoveredColumnNameDebounced: TDebounced<[string]>;
    private reactListRef: IReactList;
    private hasScrolledToRowOnLoad: boolean = false;
    private timesScrollToHasBeenCalledInARow: number = 0; /* number of times scrollTo index has been called in a row,
                                            stop after x times to prevent a loop */

    constructor(props: IListProps<ColumnNames> & IContextProps) {
        super(props);

        this.state = {
            isFixedHeaderAndFooterInitialized: false,
            tableHeaderIsFixed: false,
            tableHeaderWidth: 0,
            tableFooterIsFixed: false,
            tableFooterWidth: 0,
            hoveredColumnName: '',
        };

        this.refTableHeaderRelative = React.createRef();
        this.refTableFooterRelative = React.createRef();
        this.refTableContent = React.createRef();

        this.onColumnHeaderClick = this.onColumnHeaderClick.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollFirstSelectedItemIntoView = this.scrollFirstSelectedItemIntoView.bind(this);
        this.scrollItemWithSpecificIdIntoView = this.scrollItemWithSpecificIdIntoView.bind(this);
        this.setHoveredColumnName = this.setHoveredColumnName.bind(this);
        this.setHoveredColumnNameDebounced = debounce(this.setHoveredColumnName, 5);
        this.setReactListRef = this.setReactListRef.bind(this);
        this.scrollRowIntoViewIfListItemsAreLoaded =
            this.scrollRowIntoViewIfListItemsAreLoaded.bind(this);
        this.listRenderer = this.listRenderer.bind(this);
    }

    public render() {
        const { footer, items, customTableContentRenderer } = this.props;
        const { tableHeaderIsFixed, tableHeaderWidth, tableFooterIsFixed, tableFooterWidth } = this.state;

        return (
            <div className="List" ref={(ref) => this.listElement = ref} >
                <div className="table-headers" ref={this.refTableHeaderRelative}>
                    {this.renderTableHeaders()}
                </div>
                <div
                    className={classNames('table-headers', 'table-headers--fixed', { fixed: !!tableHeaderIsFixed })}
                    style={{ width: tableHeaderWidth }}
                >
                    {this.renderTableHeaders()}
                </div>
                <div className="table-content" ref={this.refTableContent}>
                    {typeof customTableContentRenderer === 'function' ?
                        customTableContentRenderer(items) : this.renderListItems()
                    }
                </div>
                {footer && (
                    <>
                        <div className="table-footer" ref={this.refTableFooterRelative}>
                            {footer}
                        </div>
                        <div
                            className={classNames('table-footer table-footer--fixed', {
                                fixed: !!tableFooterIsFixed,
                            })}
                            style={{ width: tableFooterWidth }}
                        >
                            {footer}
                        </div>
                    </>
                )}
            </div>
        );
    }

    public componentDidMount() {
        this.validatTotalListWidth();

        window.addEventListener('resize', this.onWindowResize);
        this.scrollContainer = findClosestScrollContainer(this.listElement);
        this.scrollContainer.addEventListener('scroll', this.onScroll);

        this.checkFixedElementsStatesTimeout = window.setTimeout(() => this.checkFixedElementsStates(), 300);

        this.scrollRowIntoViewIfListItemsAreLoaded();
    }

    public componentWillUnmount() {
        if (this.scrollIntoViewTimeout) {
            window.clearTimeout(this.scrollIntoViewTimeout);
        }
        if (this.checkFixedElementsStatesTimeout) {
            window.clearTimeout(this.checkFixedElementsStatesTimeout);
        }
        window.removeEventListener('resize', this.onWindowResize);
        this.scrollContainer.removeEventListener('scroll', this.onScroll);
    }

    public componentDidUpdate(prevProps: IContextProps & IListProps<ColumnNames>) {
        this.validatTotalListWidth();
        this.updateHeaderAndFooterWidth();

        if (!this.state.isFixedHeaderAndFooterInitialized || this.props.items.length !== prevProps.items.length) {
            if (this.checkFixedElementsStatesTimeout) {
                window.clearTimeout(this.checkFixedElementsStatesTimeout);
            }
            this.checkFixedElementsStatesTimeout = window.setTimeout(() => this.checkFixedElementsStates(), 300);
        }

        this.scrollRowIntoViewIfListItemsAreLoaded();

        /**
         * Na een page-refresh worden soms de elementen van een List niet getoond, en komen ze pas zichtbaar
         * na een window resize.
         * De eigenlijke oorzaak nog niet gevonden:
         * - timing issue zodat soms een parent element op het moment van de render nog hidden is?
         * - of issue in ReactList zelf? (of combinatie?)
         *
         * Workaround door zelf het resize event te dispatchen.
         * Zie ook [KZUAT-901] Refresh van een gefilterde lijst geeft geen resultaat meer
         */
        window.requestAnimationFrame(() => {
            if (window) {
                window.dispatchEvent(createEvent('resize'));
            }

            /**
             * Wanneer een listItem groter is dan de default grootte, wordt de height van de container
             * bij de eerste render niet juist berekend (daarna wel omdat de heights in cache object bijgehouden
             * worden, zie "itemSizeEstimator") waardoor de footer soms over de lijst komt te staan.
             * Als de content een grotere height heeft gaan we hier manueel de height van de container ook aanpassen.
             */
            if (this.reactListRef && this.reactListRef.el) {
                const reactListEl = this.reactListRef.el;
                if (reactListEl && reactListEl.children.length > 0) {
                    const wrapperHeight = reactListEl.getBoundingClientRect().height;
                    const contentHeight = reactListEl.children[0].getBoundingClientRect().height;

                    // Use a 5px buffer
                    if (contentHeight - 5 > wrapperHeight) {
                        reactListEl.style.height = `${contentHeight}px`;
                    }
                }
            }
        });
    }

    private renderTableHeaders() {
        const { columns, name, hideHeaders, selectedColumnName, onColumnClicked, items } = this.props;
        const columnKeys = this.getColumnNamesToDisplay();

        if (hideHeaders) {
            return null;
        }

        return (
            <table>
                <thead>
                    <tr>
                        {columnKeys.map((columnName) => {
                            const column = columns[columnName] as IColumn<{}>;

                            const isSelectable = column.selectable && typeof onColumnClicked === 'function';

                            const columnClasses = classNames({
                                sortable: !!column.sortable,
                                center: columnName === 'selectCheckbox',
                                clickable: isSelectable,
                                selected: selectedColumnName === columnName,
                                hover: this.state.hoveredColumnName === columnName,
                            });

                            const onClick = () => this.onColumnHeaderClick(
                                columnName,
                                column.sortable,
                                column.selectable,
                            );

                            const styles: CSSProperties = {
                                minWidth: getMinWidth(column),
                                width: getWidth(column),
                                textAlign: column.align,
                            };

                            return (
                                <th
                                    key={`list-${name}-column-${columnName}`}
                                    className={columnClasses}
                                    data-column-name={columnName}
                                    onClick={column.sortable || column.selectable ? onClick : undefined}
                                    onMouseEnter={column.selectable ?
                                        () => this.setHoveredColumnNameDebounced(columnName) : undefined}
                                    onMouseLeave={column.selectable ?
                                        () => this.setHoveredColumnNameDebounced('') : undefined}
                                    style={styles}
                                >
                                    {column.label && <span>{column.label}</span>}
                                    {typeof column.headerRender === 'function' ? column.headerRender(items) : ''}
                                    {column.sortable && this.renderSortIcon(columnName)}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
            </table>
        );
    }

    private renderListItems() {
        const {
            items, noResultsMessage, maxNrOfRecordsToShow, errorMessage, itemSizeGetter,
        } = this.props;

        const columnNames = this.getColumnNamesToDisplay();

        if (errorMessage) {
            return (
                <table>
                    <tbody>
                        <tr key={`list-${name}-error`} className="error-message">
                            <td colSpan={columnNames.length}>
                                {errorMessage}
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        if (items.length === 0) {
            return (
                <table>
                    <tbody>
                        <tr key={`list-${name}-empty`} className="no-results">
                            <td colSpan={columnNames.length}>
                                {noResultsMessage || <Translate msg="common.list.no_items" />}
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        const itemsToRender = maxNrOfRecordsToShow && (items.length > maxNrOfRecordsToShow) ?
            items.slice(0, maxNrOfRecordsToShow) :
            items;

        return (
            <ReactList
                itemRenderer={(index, key) => this.renderItem({
                    index,
                    key,
                    columnNames,
                    item: itemsToRender[index],
                })}
                itemsRenderer={this.listRenderer}
                length={itemsToRender.length}
                type="variable"
                itemSizeGetter={itemSizeGetter}
                itemSizeEstimator={this.itemSizeEstimator}
                pageSize={20} // Up this to render more items initially -> itemSizeEstimator should be more accurate
                threshold={100} // Buffer size in px before/after first/last listItem
                ref={this.setReactListRef}
                useTranslate3d
            />
        );
    }

    private listRenderer(items: JSX.Element[], ref: string) {
        return (
            <table>
                <tbody ref={ref}>
                    {items}
                </tbody>
            </table>
        );
    }

    private itemSizeEstimator(index: number, cache: {}) {
        const cacheKeys = Object.keys(cache);

        if (!cacheKeys || cacheKeys.length <= 0) {
            return REACT_LIST_DEFAULT_ITEM_SIZE;
        }

        const sum = cacheKeys.reduce(
            (accumulator, key) => accumulator + cache[key],
            0,
        );

        if (sum === 0) {
            return REACT_LIST_DEFAULT_ITEM_SIZE;
        }

        return sum / cacheKeys.length;
    }

    // eslint-disable-next-line max-len
    private renderItem(props: IRenderItemProps<ColumnNames>) {
        const {
            columns, selectedItemIds, onItemRowClicked, isItemClickable,
            translator, getCustomRowClasses, getTableCellClasses,
            fadeOutListItems, selectedColumnName, onColumnClicked,
        } = this.props;
        const {
            item,
            index: itemIndex,
            key,
            columnNames,
        } = props;

        const hasOnColumnClicked = typeof onColumnClicked === 'function';
        const isSelected = !hasOnColumnClicked && selectedItemIds.indexOf(item.id) !== -1;
        const isClickable = !hasOnColumnClicked && !!onItemRowClicked
            && (!isFunction(isItemClickable) || isItemClickable(item));
        const rowClasses = classNames(
            {
                clickable: isClickable,
                selected: isSelected,
                'fade-out': fadeOutListItems,
                'disable-click': hasOnColumnClicked,
            },
            typeof getCustomRowClasses === 'function' ? getCustomRowClasses(item) : null,
        );
        const onRowClick = isClickable ? () => onItemRowClicked(item.id) : undefined;

        return (
            <tr
                key={key}
                onClick={onRowClick}
                className={rowClasses}
                data-is-selected={isSelected}
                data-item-id={item.id}
            >
                {
                    columnNames.map((columnName, index) => {
                        const column = columns[columnName] as IColumn<ColumnNames>;
                        const label = React.isValidElement(column.label) ?
                            translator(column.label.props) : column.label;
                        const mobileLabel = React.isValidElement(column.mobileLabel) ?
                            translator(column.mobileLabel.props) : column.mobileLabel;
                        const cellContent = typeof column.render === 'function' ?
                            column.render(item, itemIndex) : item.columns[columnName];
                        // specifix logic for numbers, otherwise a numeric value 0 would we replaced by ''
                        const adjustedCellContent = (typeof cellContent === 'number') ?
                            cellContent :
                            cellContent || '';
                        const isEmptyCell = !React.isValidElement(adjustedCellContent) && (
                            typeof adjustedCellContent === 'string' && !adjustedCellContent.trim()
                        );
                        const colSpanData = typeof column.itemColumnCollSpan === 'function'
                            ? column.itemColumnCollSpan(item)
                            : null;
                        const hideData = typeof column.hideItemColumn === 'function'
                            ? column.hideItemColumn(item)
                            : false;

                        const styles: CSSProperties = {
                            minWidth: getMinWidth(column),
                            width: getWidth(column, colSpanData && colSpanData.colSpanPercentWidth),
                            textAlign: column.align,
                        };

                        const isSelectable = hasOnColumnClicked && column.selectable;

                        if (hideData) {
                            return null;
                        }

                        const cellClasses = classNames(
                            {
                                clickable: isSelectable,
                                selected: selectedColumnName === columnName,
                                hover: this.state.hoveredColumnName === columnName,
                            },
                            typeof getTableCellClasses === 'function' ?
                                getTableCellClasses(columnName, item) : '',
                        );

                        const onClick = isSelectable ? () => onColumnClicked(columnName) : undefined;

                        return (
                            <td
                                key={`list-item-cell-${item.id}-${index}`}
                                data-label={mobileLabel ? mobileLabel : label}
                                data-column-name={columnName}
                                className={cellClasses}
                                style={styles}
                                onClick={onClick}
                                onMouseEnter={column.selectable ?
                                    () => this.setHoveredColumnNameDebounced(columnName) : undefined}
                                onMouseLeave={column.selectable ?
                                    () => this.setHoveredColumnNameDebounced('') : undefined}
                                colSpan={colSpanData ? colSpanData.colSpan : null}
                            >
                                {isEmptyCell ?
                                    EMPTY_CELL_PLACEHOLDER : cellContent}
                            </td>
                        );
                    })
                }
            </tr>
        );
    }

    private renderSortIcon(columnName: string) {
        const { sortedColumn } = this.props;
        const sorted = sortedColumn && sortedColumn.name === columnName;

        const classes = classNames({
            sorted,
            ascending: sorted && sortedColumn.sortOrder === SortOrder.Ascending,
        });

        return <Icon typeName="chevron-down" className={classes} />;
    }

    private onColumnHeaderClick(columnName: string, sortable: boolean, selectable: boolean) {
        const { onColumnSortChanged, onColumnClicked } = this.props;

        if (selectable && typeof onColumnClicked === 'function') {
            onColumnClicked(columnName);
        }

        if (sortable && typeof onColumnSortChanged === 'function') {
            const nextSortOrder = this.getNextSortOrder(columnName);
            onColumnSortChanged({ name: columnName, sortOrder: nextSortOrder } as ISortedColumn<{}>);
        }
    }

    private getNextSortOrder(columnNameToSort: string) {
        const { sortedColumn } = this.props;

        if (sortedColumn && sortedColumn.name === columnNameToSort) {
            if (sortedColumn.sortOrder === SortOrder.Ascending) {
                return SortOrder.Descending;
            }
        }

        return SortOrder.Ascending;
    }

    private onWindowResize() {
        this.checkFixedElementsStates();
        this.updateHeaderAndFooterWidth();
    }

    private scrollRowIntoViewIfListItemsAreLoaded() {
        if (this.props.items.length > 0 && !this.hasScrolledToRowOnLoad) {
            if (this.props.idToScrollIntoView) {
                this.scrollItemWithSpecificIdIntoView();
            } else {
                this.scrollFirstSelectedItemIntoView();
            }
            this.hasScrolledToRowOnLoad = true;
        }
    }

    private scrollFirstSelectedItemIntoView() {
        if (this.props.selectedItemIds && this.props.selectedItemIds[0]) {
            this.scrollItemIntoViewIfNotVisible(this.props.selectedItemIds[0]);
        }
    }

    private scrollItemWithSpecificIdIntoView() {
        if (this.props.idToScrollIntoView) {
            this.scrollItemIntoViewIfNotVisible(this.props.idToScrollIntoView);
        }
    }

    private scrollItemIntoViewIfNotVisible(id: number | string) {
        const { maxNrOfRecordsToShow, items } = this.props;

        if (!id) {
            return;
        }

        const itemsToRender = maxNrOfRecordsToShow && (items.length > maxNrOfRecordsToShow) ?
            items.slice(0, maxNrOfRecordsToShow) :
            items;

        const indexToScrollTo = itemsToRender.findIndex((item) => item.id === id);

        this.scrollIntoViewTimeout = window.requestAnimationFrame(() => {
            if (this.reactListRef && this.timesScrollToHasBeenCalledInARow <= 3) {
                const visibleRange = this.reactListRef.getVisibleRange();
                if (indexToScrollTo < visibleRange[0] || indexToScrollTo > visibleRange[1]) {
                    this.reactListRef.scrollTo(
                        indexToScrollTo - 2 >= 0 ? indexToScrollTo - 2 : indexToScrollTo);
                    this.timesScrollToHasBeenCalledInARow = this.timesScrollToHasBeenCalledInARow + 1;
                    this.scrollItemIntoViewIfNotVisible(id);
                } else {
                    this.timesScrollToHasBeenCalledInARow = 0;
                }
            } else {
                this.timesScrollToHasBeenCalledInARow = 0;
            }
        });

    }

    private getColumnNamesToDisplay() {
        return Object.keys(this.props.columns)
            .filter((columnName) => !this.props.columns[columnName].hide);
    }

    private onScroll() {
        this.checkFixedElementsStates();
    }

    private updateHeaderAndFooterWidth() {
        if (screenSizeUtils.isExtraSmallScreen()) {
            return;
        }

        if (this.refTableHeaderRelative.current) {
            this.setState({
                tableHeaderWidth: this.refTableHeaderRelative.current.offsetWidth,
            });
        }

        if (this.refTableFooterRelative.current) {
            this.setState({
                tableFooterWidth: this.refTableFooterRelative.current.offsetWidth,
            });
        }
    }

    private checkFixedElementsStates() {
        if (screenSizeUtils.isExtraSmallScreen()) {
            return;
        }

        if (this.refTableContent.current) {
            const content = this.refTableContent.current;
            if (!content) {
                return;
            }
            const bounding = content.getBoundingClientRect();
            const documentHeight = window.innerHeight || document.documentElement.clientHeight;

            // Ensure that the scrolltainer is initialized / visible
            if (
                this.scrollContainer !== window &&
                (this.scrollContainer as HTMLElement).clientHeight === 0
            ) {
                return;
            }

            const tableHeaderIsFixed = bounding.top < -(OFFSET.FIXED_HEADER.TOP) &&
                bounding.top + bounding.height >= -(OFFSET.FIXED_HEADER.BOTTOM);
            const tableFooterIsFixed = bounding.top - documentHeight < -(OFFSET.FIXED_FOOTER.TOP) &&
                bounding.top + bounding.height - documentHeight >= -(OFFSET.FIXED_FOOTER.BOTTOM);

            this.setState({
                isFixedHeaderAndFooterInitialized: true,
                tableHeaderIsFixed,
                tableFooterIsFixed,
            });
        }
    }

    private validatTotalListWidth() {
        if (process.env.NODE_ENV === 'development') {
            const { columns, name } = this.props;
            const sum = Object.keys(columns)
                .filter((columnKey) => !columns[columnKey].hide && typeof columns[columnKey].percentWidth === 'number')
                .map((columnKey) => columns[columnKey].percentWidth)
                .reduce((accumulator, current) => accumulator + current, 0);
            if (sum !== 100) {
                console.warn(`[List ${name}] The total sum of the column widths should be 100, got: ${sum}`);
            }
        }
    }

    private setHoveredColumnName(columnName: string) {
        if (this.state.hoveredColumnName !== columnName) {
            this.setState({
                hoveredColumnName: columnName,
            });
        }
    }

    private setReactListRef(ref) {
        this.reactListRef = ref;
    }
}

export default function List(props: IListProps<{}>) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <ListComp {...props} translator={translator} />
            )}
        </TranslatorContext.Consumer>
    );
}

export function getWidth(column: IColumn<object>, colSpanPercentWidth: number = null) {
    if (colSpanPercentWidth) {
        return colSpanPercentWidth + '%';
    }
    if (column.percentWidth) {
        return column.percentWidth + '%';
    }
    if (column.minWidth) {
        return column.minWidth + 'px';
    }
    return null;
}

export function getMinWidth(column: IColumn<object>) {
    return column.minWidth && column.minWidth + 'px';
}
