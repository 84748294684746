import React, { PureComponent } from 'react';
import { IEmployee } from '../../../../../../models/admin/employee';
import EmployeeRadioSelectList from '../EmployeeRadioSelectList';
import Loader from '../../../../../common/waiting/Loader';
import { IAsyncFieldInfo } from '../../../../../../models/general/redux';
import { connect } from '../../../../..';
import { getValidateEmployeeToPlanAsyncInfo } from '../../../../../../redux/medicalExamination/selectors';
import FormError from '../../../../../common/forms/FormError';
import ListFooter from '../../../../../common/list/ListFooter';
import { IExaminationReason } from '../../../../../../models/interventions/medicalExaminations';

const CLASS_NAME = 'SearchEmployeeInput';

export interface IContentValues {
    searchValue: string;
    selectedEmployee: Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>;
    examinationReason: IExaminationReason;
}

interface IPublicProps {
    values: IContentValues;
    onChangeInput: (values: IContentValues) => void;
    onEmployeeSelected?: (selectedEmployee: IEmployee) => void;
}

interface IPrivateProps {
    validateEmployeeToPlanAsyncInfo: IAsyncFieldInfo;
}

class SearchEmployeeInput extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.onSearchInputHandler = this.onSearchInputHandler.bind(this);
        this.onSelectEmployeeHandler = this.onSelectEmployeeHandler.bind(this);
    }

    public render() {
        const { values, validateEmployeeToPlanAsyncInfo } = this.props;

        return (
            <div className={CLASS_NAME}>
                <Loader show={validateEmployeeToPlanAsyncInfo.status} />
                <EmployeeRadioSelectList
                    initialSearchValue={values.searchValue}
                    // eslint-disable-next-line max-len
                    searchPlaceholderTranslationKey="interventions.medical_examinations.new.steps.search_employee.search_placeholder"
                    showEmployeeConditionTooltips={true}
                    onSearchInput={this.onSearchInputHandler}
                    onSelect={this.onSelectEmployeeHandler}
                    selectedEmployeeId={values.selectedEmployee ?
                        values.selectedEmployee.id : null}
                    footer={
                        <ListFooter
                            left={(
                                <FormError error={validateEmployeeToPlanAsyncInfo.error} />
                            )}
                        />
                    }
                />
            </div>
        );
    }

    private onSearchInputHandler(searchValue: string) {
        this.props.onChangeInput({
            ...this.props.values,
            searchValue,
            /**
             * Reset selected employee when search value changes
             * - so that a selected employee that doesn't match the search anymore, gets automatically deselected
             */
            selectedEmployee: null,
        });
    }

    private onSelectEmployeeHandler(employee: IEmployee) {
        // Trigger event when employee gets selected.
        if (this.props.onEmployeeSelected) {
            this.props.onEmployeeSelected(employee);
        }

        this.props.onChangeInput({
            ...this.props.values,
            selectedEmployee: employee,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            validateEmployeeToPlanAsyncInfo: getValidateEmployeeToPlanAsyncInfo(state),
        };
    },
})(SearchEmployeeInput);
