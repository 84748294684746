import { object } from 'yup';
import phoneNumber from '../../../../utils/libs/yup/phoneNumber';
import string from '../../../../utils/libs/yup/string';

export interface IFormValues {
    email: 'email';
    phone: 'phone';
    fax: 'fax';
}

export const fields: IFormValues = {
    email: 'email',
    phone: 'phone',
    fax: 'fax',
};

export const schema = object({
    [fields.email]: string().email(),
    [fields.phone]: phoneNumber(),
    [fields.fax]: phoneNumber(),
});
