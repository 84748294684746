import getLocalDvlpConfig from '@snipsonian/dvlp/es/storedConfig/getLocalDvlpConfig';
import { STATE_STORAGE_KEY } from './redux.config';

interface WhyDidYouUpdateConfig {
    enable: boolean;
    include: string;
    exclude: string;
}

const DEFAULT_DEV_CONFIG = {
    ENABLE_API_LOGGING: false,
    ENABLE_REDUX_LOGGING: false,
    ENABLE_STATE_STORAGE: true,
    // React why-did-you-update
    WDYU: {
        enable: false,
        include: '^Connect', // as an example, by default only show why-did-you-update info for connected components
        exclude: '',
    } as WhyDidYouUpdateConfig,
};

export const DVLP_STORAGE_KEY = `${STATE_STORAGE_KEY}_DVLP`;

const devConfig = getLocalDvlpConfig({
    storageKey: DVLP_STORAGE_KEY,
    defaultDevConfig: DEFAULT_DEV_CONFIG,
});

export const isApiLoggingEnabled = devConfig.ENABLE_API_LOGGING as boolean;
export const isReduxLoggingEnabled = devConfig.ENABLE_REDUX_LOGGING as boolean;
export const isStateStorageEnabled = devConfig.ENABLE_STATE_STORAGE as boolean;
export const reactWhyDidYouUpdateConfig = devConfig.WDYU as WhyDidYouUpdateConfig;
