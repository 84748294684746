import React, { ReactNode, PureComponent } from 'react';
import Collapsible from 'react-collapsible';
import './collapsible-item.scss';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';

interface ICollapsibleItemProps {
    trigger: string | ReactNode;
    children: ReactNode;
    initialOpen?: boolean;
    onOpen?: () => void;
    triggerTagName?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

export default class CollapsibleItem extends PureComponent<ICollapsibleItemProps> {
    private openOnInitialRender: boolean = false;

    static defaultProps: Partial<ICollapsibleItemProps> = {
        triggerTagName: 'h2',
    };

    constructor(props: ICollapsibleItemProps) {
        super(props);
        this.openOnInitialRender = props.initialOpen;
    }

    public render() {
        const { trigger, children, onOpen, triggerTagName } = this.props;
        const TriggerTag = triggerTagName;

        return (
            <Collapsible
                classParentString="CollapsibleItem"
                transitionTime={300}
                easing="ease-out"
                trigger={
                    typeof trigger === 'string' ? (
                        <TriggerTag className="CollapsibleItem__trigger__inner">
                            <Translate msg={trigger} />
                            <Icon typeName="chevron-down" />
                        </TriggerTag>
                    ) : trigger
                }
                open={this.openOnInitialRender}
                onOpen={onOpen}
            >
                {children}
            </Collapsible>
        );
    }
}
