import React from 'react';
import Overview from '.';
import ROUTE_KEYS from '../../../../routeKeys';
import { connect } from '../../..';
import { IPreventionUnitNumbers, TPreventionUnitsGroupType } from '../../../../models/admin/preventionUnits';
import {
    getTotalPreventionUnits,
    getCompanySituationHistory,
    getPreventionUnitsGroupType,
} from '../../../../redux/preventionUnits/selectors';
import { getSelectedSeatCompanyCode } from '../../../../redux/company/selected/selectors';
import { getLatestCompanySituationYear } from '../../../../redux/preventionUnits/companySituationHistoryUtils';

interface IPrivateProps {
    totalPreventionUnits: IPreventionUnitNumbers;
    preventionUnitsGroupType: TPreventionUnitsGroupType;
    companyCode: string;
    overviewYear: number;
}

function PEOverviewCurrentYear({
    totalPreventionUnits,
    preventionUnitsGroupType,
    companyCode,
    overviewYear,
}: IPrivateProps) {
    return (
        <Overview
            routeKey={ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW}
            detailRouteKey={ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL}
            totalPreventionUnits={totalPreventionUnits}
            preventionUnitsGroupType={preventionUnitsGroupType}
            companyCode={companyCode}
            overviewYear={overviewYear}
        />
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const totalPreventionUnits = getTotalPreventionUnits(state);
        const companySituationHistory = getCompanySituationHistory(state);

        return {
            totalPreventionUnits,
            preventionUnitsGroupType: getPreventionUnitsGroupType(state),
            companyCode: getSelectedSeatCompanyCode(state),
            overviewYear: getLatestCompanySituationYear(companySituationHistory),
        };
    },
})(PEOverviewCurrentYear);
