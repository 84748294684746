import { formatHourAndMinute } from '../../utils/formatting/formatTime';

export const AVAILABILITY_HOUR_LIMITS = {
    min: 8,
    max: 18,
};

export const AVILABILITY_MIN_TIME = formatHourAndMinute({ hour: AVAILABILITY_HOUR_LIMITS.min });
export const AVILABILITY_MAX_TIME = formatHourAndMinute({ hour: AVAILABILITY_HOUR_LIMITS.max });

export const AVAILABILITY_HOURS = getHoursBetween(AVAILABILITY_HOUR_LIMITS);

export const AVAILABILITY_DAYS = {
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    7: 'sunday',
};

function getHoursBetween({ min, max }) {
    const hours = [];
    for (let hour = min; hour < max; hour += 1) {
        hours.push(hour);
    }
    return hours;
}

export const COMPANY_CONTACT_TYPE_ID = {
    FLU_VACCINES: 1830339,
};

export type TCompanyStatisticalCode = 'A' | 'B' | 'C+' | 'C-' | 'D' | 'M';
