import React from 'react';
import './featured-courses.scss';
import CourseTile from '../CourseTile';
import { ICMSCourse } from '../../../../../models/documentCenter/courses';
import Translate from '../../../../common/Translate';

const CLASS_NAME = 'FeaturedCourses';
const MAX_NR_OF_SPOTLIGHT_ITEMS = 3;

interface IPublicProps {
    courses: ICMSCourse[];
    maxItems?: number;
}

export function FeaturedCourses({
    courses,
    maxItems = MAX_NR_OF_SPOTLIGHT_ITEMS,
}: IPublicProps) {
    if (!courses || courses.length === 0) {
        return null;
    }

    const coursesToShow = courses.slice(0, maxItems);

    return (
        <div className={CLASS_NAME}>
            <div className="container">
                <h2><Translate msg="document_center.courses.overview.featured.title"/></h2>
                <div className={`${CLASS_NAME}__list`}>
                    {coursesToShow.map((course) => {
                        return (
                            <CourseTile
                                key={`featured-course-${course.nodeId}`}
                                course={course}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
