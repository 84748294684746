import { createEpic } from '../../index';
import {
    fetchExecutedInterventionsSucceeded, fetchExecutedInterventionsFailed,
} from './actions';
import {
    getPrestationCodeQueryParam, areExecutedInterventionsAvailable,
} from './selectors';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat,
} from '../../company/selected/selectors';
import { getLocationState, getQueryParams } from '../../location/selectors';
import {
    IExecutedInterventionsFilter,
    IFetchExecutedInterventionsPayload,
} from '../../../models/interventions/interventions';
import { DEFAULT_EXECUTED_INTERVENTIONS_FILTERS } from '../../../api/interventions/executedInterventions.api';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import ROUTE_KEYS from '../../../routeKeys';

// fetchExecutedInterventionsEpic
createEpic<IFetchExecutedInterventionsPayload[]>({
    onActionType: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        if (!areExecutedInterventionsAvailable(state)) {
            return true;
        }

        const { type: prevRouteKey, query: prevFilter } = getLocationState(state);
        const prevFilterWithDefaults = {
            ...DEFAULT_EXECUTED_INTERVENTIONS_FILTERS,
            ...prevFilter,
        };

        if (prevRouteKey === ROUTE_KEYS.R_EXECUTED_INTERVENTIONS) {
            // do not refresh if only clientside (query) filtering changed
            const onlyClientSideFilteringChanged = areObjectParamsEqual(
                prevFilterWithDefaults,
                action.meta.query,
                ['startDate', 'endDate'],
            );
            return !onlyClientSideFilteringChanged;
        }
        return true;
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const prestationCode = getPrestationCodeQueryParam(state);
            const companyCode = getSelectedSeatCompanyCode(state);
            const filterFromQuery = getQueryParams(state) as IExecutedInterventionsFilter;
            const selectedCompanySeat = getSelectedCompanySeat(state);
            const showFullFamily = selectedCompanySeat && selectedCompanySeat.isAllSeatsSelected;

            const result = await api.interventions.executedInterventions.fetchExecutedInterventions(
                { companyCode, prestationCode, showFullFamily }, filterFromQuery,
            );
            return fetchExecutedInterventionsSucceeded(result);
        } catch (error) {
            return fetchExecutedInterventionsFailed(error);
        }
    },
    latest: false,
});
