import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './validate.scss';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { connect } from '../../../../index';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import LabelInfo from '../../../../common/widget/LabelInfo';
import ResultAfterDoAction from '../../../../common/widget/ResultAfterDoAction';
import ValidateInfo from '../../../../common/widget/ValidateInfo';
import { formatCompanyContact } from '../../../../../utils/formatting/formatContact';
import {
    IInterventionRequestWizardEntity, ICreateInterventionRequest,
} from '../../../../../models/interventions/requestIntervention';
import {
    getCreateInterventionRequestAsyncInfo, getInterventionRequestWizardEntity,
} from '../../../../../redux/intervention/requestIntervention/selectors';
import { createInterventionRequestActions } from '../../../../../redux/intervention/requestIntervention/actions';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { PRESTATION_CODE } from '../../../../../config/navigation/interventions.config';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';

const CLASS_NAME = 'InterventionRequest__Validate';
const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    wizardEntity: IInterventionRequestWizardEntity;
    translator: ITranslator;
    onCreateRequest: () => void;
    resetCreateAction: () => void;
    navigateToAfterSuccessRoute: () => void;
}

class Validate extends PureComponent<IStepperStepRenderProps & IPrivateProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.closeCreationSuccessDialog = this.closeCreationSuccessDialog.bind(this);
        this.closeCreationErrorDialog = this.closeCreationErrorDialog.bind(this);
    }

    public render() {
        const {
            renderStepButtons,
            wizardEntity,
            onCreateRequest,
            translator,
        } = this.props;

        const typeTranslationKeyPart = 'interventions.intervention_request_new.steps.intervention.fields.type.values';

        return (
            <>
                <PageHeader
                    title="interventions.intervention_request_new.steps.validate.title"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <ResultAfterDoAction
                        doActionAsyncInfoSelector={getCreateInterventionRequestAsyncInfo}
                        successConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.intervention_request_new.steps.validate.create_result.success.title',
                            // eslint-disable-next-line max-len
                            infoTranslationKey: 'interventions.intervention_request_new.steps.validate.create_result.success.info',
                            onConfirm: this.closeCreationSuccessDialog,
                        }}
                        errorConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.intervention_request_new.steps.validate.create_result.error.title',
                            onConfirm: this.closeCreationErrorDialog,
                        }}
                    >
                        <ValidateInfo>
                            <LabelInfo
                                labelKey="interventions.intervention_request_new.steps.validate.fields.type"
                                info={translator(`${typeTranslationKeyPart}.${wizardEntity.type}`)}
                            />
                            <LabelInfo
                                labelKey="interventions.intervention_request_new.steps.validate.fields.contact"
                                info={formatCompanyContact(wizardEntity.contact)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                labelKey="interventions.intervention_request_new.steps.validate.fields.remark"
                                info={wizardEntity.remarks.remark || EMPTY_PLACEHOLDER}
                            />
                        </ValidateInfo>
                        <div className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    // eslint-disable-next-line max-len
                                    translationKey: 'interventions.intervention_request_new.steps.validate.finish_button',
                                    onClick: onCreateRequest,
                                },
                            })}
                        </div>
                    </ResultAfterDoAction>
                </div>
            </>
        );
    }

    private closeCreationSuccessDialog() {
        this.props.resetCreateAction();
        this.props.navigateToAfterSuccessRoute();
    }

    private closeCreationErrorDialog() {
        this.props.resetCreateAction();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getInterventionRequestWizardEntity(state);

        return {
            wizardEntity: wizardEntity as IInterventionRequestWizardEntity,
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onCreateRequest: () => {
                const wizardEntity = getInterventionRequestWizardEntity(getState()) as IInterventionRequestWizardEntity;
                const translator = getTranslatorDeprecated(getState());

                dispatch(createInterventionRequestActions.trigger(
                    mapWizardEntityToCreatePayload(wizardEntity, translator),
                ));
            },
            resetCreateAction: () => {
                dispatch(createInterventionRequestActions.reset({}));
            },
            navigateToAfterSuccessRoute: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
            },
        };
    },
})(Validate);

function mapWizardEntityToCreatePayload(
    wizardEntity: IInterventionRequestWizardEntity, translator: ITranslator,
): ICreateInterventionRequest {
    return {
        type: translator(
            `interventions.intervention_request_new.steps.intervention.fields.type.values.${wizardEntity.type}`,
        ),
        contact: {
            firstName: wizardEntity.contact.firstName,
            name: wizardEntity.contact.name,
            email: wizardEntity.contact.email,
            phone: wizardEntity.contact.phone,
            mobilePhone: wizardEntity.contact.mobilePhone,
        },
        remarks: wizardEntity.remarks.remark,
        prestationCode: wizardEntity.type as PRESTATION_CODE,
    };
}
