import React from 'react';

import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import isEmptyString from '../../../../../utils/core/string/isEmptyString';
import LabelInfo from '../../../../common/widget/LabelInfo';

import { getPrefixedTranslation } from '../AppointmentDetailDialog.helper';

import { IExecutedMedicalExaminationAppointmentDialogProps } from './ExecutedMedicalExaminationAppointmentDialog.type';

const ExecutedMedicalExaminationAppointmentDialog = ({
    data,
}: IExecutedMedicalExaminationAppointmentDialogProps) => {
    const hasConclusion = !isEmptyString(data.conclusion.description);

    return (
        <>
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.where')}
                info={data.location.name}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.who')}
                info={formatPersonName({
                    firstName: data.employee.firstName,
                    name: data.employee.lastName,
                })}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.examination_reason')}
                info={data.examinationReason.description}
                hideColon={true}
            />
            {hasConclusion && (
                <LabelInfo
                    labelKey={getPrefixedTranslation('labels.examination_result.conclusion')}
                    info={data.conclusion.description}
                    hideColon={true}
                />
            )}
        </>
    );
};

export { ExecutedMedicalExaminationAppointmentDialog };
