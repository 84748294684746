import { createAction, createSuccessAction, createFailAction } from '../../index';
import {
    FETCH_DOCUMENT,
    DISMISS_DOCUMENT_DIALOG,
    CREATE_CONVOCATIONS,
    UPLOAD_EMPLOYEE_DOCUMENT,
    FETCH_CONVOCATION_RECIPIENTS,
} from './types';
import {
    IConvocationRecipient,
    IDocument,
    IFetchDocumentPayload,
    IUploadEmployeeDocumentPayload,
    IUploadEmployeeDocumentSuccessPayload,
    ICreateConvocationActionPayload,
} from '../../../models/general/documents';
import { ITraceableApiError } from '../../../models/general/error';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';
import { createResetAction, createTypeActions } from '../../../utils/libs/redux/createAction';

// Document

export function fetchDocument(payload: IFetchDocumentPayload) {
    return createAction(FETCH_DOCUMENT, payload);
}

export function fetchDocumentSucceeded(payload: IDocument) {
    presentDownloadedFile(payload);

    return createSuccessAction<IDocument>(FETCH_DOCUMENT, payload);
}

export function fetchDocumentFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_DOCUMENT, error);
}

export function resetFetchDocument() {
    return createResetAction(FETCH_DOCUMENT, {});
}

export function dismissDocumentDialog() {
    return createAction(DISMISS_DOCUMENT_DIALOG, {});
}

// Create convocation and download or send via email
export const createConvocationsActions =
    createTypeActions<ICreateConvocationActionPayload>({
        type: CREATE_CONVOCATIONS,
    });

// Upload employee document

export const uploadEmployeeDocumentActions =
    createTypeActions<IUploadEmployeeDocumentPayload, IUploadEmployeeDocumentSuccessPayload>({
        type: UPLOAD_EMPLOYEE_DOCUMENT,
    });

// Fetch convocation recipients
export const fetchConvocationRecipientsActions =
    createTypeActions<{}, IConvocationRecipient[]>({
        type: FETCH_CONVOCATION_RECIPIENTS,
    });
