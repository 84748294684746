export function joinParts(parts, joinSeparator = ' ') {
    return parts
        .filter(isNotBlank)
        .join(joinSeparator)
        .trim();
}

export function isNotBlank(input): boolean {
    if (!input) {
        return false;
    }

    return `${input}`.trim().length > 0;
}
