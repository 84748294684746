import React from 'react';
import PageHeader from '../../../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../../../models/general/stepper';
import classNames from 'classnames';
import { IAddUserAccountPayload, AdminType } from '../../../../../../../../models/user/userAccount';
import connect from '../../../../../../../../utils/libs/redux/connect';
import {
    getAddUserWizardData,
} from '../../../../../../../../redux/company/users/selectors';
import { setAddCompanyUserWizardData } from '../../../../../../../../redux/company/users/actions';
import Form, { IFormRenderProps } from '../../../../../../../common/forms/Form';
import { formatPersonName } from '../../../../../../../../utils/formatting/formatPerson';
import { IPerson } from '../../../../../../../../models/admin/employee';
import SelectSeatsField from '../../../SelectSeatsField';
import Translate from '../../../../../../../common/Translate';
import { schema } from './seatsSchema';
import StickyFooter from '../../../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../../../common/navigation/Wizard/index';

type TFormValues = Pick<IAddUserAccountPayload,
    'seats'>;

interface IPrivateProps {
    addUserWizardData: Partial<IAddUserAccountPayload>;
    setAddUserWizardData: (values: TFormValues) => void;
}

const FORM_NAME = 'add-user-account-seats-form';

function Seats(props: IStepperStepRenderProps & IPrivateProps) {
    const isAdmin = Number(props.addUserWizardData.admin) !== AdminType.None;

    const INITIAL_VALUES: TFormValues = props.addUserWizardData ? {
        seats: isAdmin ? null : (props.addUserWizardData.seats || []),
    } : {} as TFormValues;

    function handleSubmit(values: TFormValues) {
        props.setAddUserWizardData(values);
        props.goToNextStep();
    }

    return (
        <>
            <PageHeader
                title="account.account_settings.manage_users.add.wizard.steps.seats.title"
                titlePlaceholders={{
                    name: formatPersonName(props.addUserWizardData as IPerson),
                }}
                text="account.account_settings.manage_users.add.wizard.steps.seats.text"
            />
            <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                <Form
                    name={FORM_NAME}
                    initialValues={INITIAL_VALUES}
                    handleSubmit={handleSubmit}
                    schema={isAdmin ? null : schema}
                    render={(renderProps: IFormRenderProps<TFormValues>) => {
                        return (
                            <>
                                <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                    <SelectSeatsField {...renderProps} disabled={isAdmin} />
                                    {isAdmin &&
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="account.account_settings.manage_users.add.wizard.steps.seats.admin_info"
                                        />
                                    }
                                </div>
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {props.renderStepButtons({
                                        nextButton: {
                                            isSubmit: true,
                                            formName: FORM_NAME,
                                        },
                                    })}
                                </StickyFooter>
                            </>
                        );
                    }}
                />
            </div>
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            addUserWizardData: getAddUserWizardData(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setAddUserWizardData: (values) => dispatch(setAddCompanyUserWizardData(values)),
        };
    },
})(Seats);
