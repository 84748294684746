import React, { PureComponent, MouseEvent } from 'react';
import { connect } from '../../../../index';
import Icon from '../../../../common/icons/Icon';
import { getYearNumber } from '../../../../../utils/core/date/getYearNumber';
import ListItemActions from '../../../../common/list/ListItemActions';
import Button from '../../../../common/buttons/Button';
import Translate from '../../../../common/Translate';
import Tooltip from '../../../../common/widget/Tooltip';
import CloseIcon from '../../../../common/icons/CloseIcon';
import { ListItem, ListItemId } from '../../../../../models/general/list';
import { IInvoiceColumnNames, IInvoiceItemData } from '../typings';
import { fetchInvoiceDocument, fetchInvoiceDetail, fetchInvoiceFull } from '../../../../../redux/invoice/actions';

interface IPublicProps {
    listItem: ListItem<IInvoiceColumnNames, ListItemId, IInvoiceItemData>;
}

interface IPrivateProps {
    onDownloadDocument: (invoiceIds: number[]) => void;
    onDownloadDetail: (invoiceIds: number[]) => void;
    onDownloadFull: (invoiceIds: number[]) => void;
}

class InvoiceActions extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
        this.onDownloadDetail = this.onDownloadDetail.bind(this);
        this.onDownloadFull = this.onDownloadFull.bind(this);
    }

    public render() {
        const {
            listItem,
        } = this.props;

        const downloadIcon = (
            <div>
                <Icon circle typeName="download-file" />
            </div>
        );

        /**
         * Invoices before 2016 don't have a proper invoice detail so we don't allow downloading it.
         * + manual invoices also don't have an invoice detail
         */
        if (
            getYearNumber(listItem.columns.invoiceDateSort as string) < 2016
            || listItem.data.doneManually
        ) {
            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadDocument(e, listItem)}
                    >
                        <span><Translate msg="administration.invoices.actions.download_invoice" /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                </ListItemActions>
            );
        }

        return (
            <ListItemActions>
                <Tooltip
                    placement="left"
                    typeName="tooltip"
                    target={downloadIcon}
                    spaceBetweenTarget={15}
                >
                    {({ closeTooltip }) => {
                        return (
                            <>
                                <CloseIcon onClick={closeTooltip} />
                                <div className="button-list">
                                    <Button
                                        id="download-invoices"
                                        typeName="text"
                                        onClick={(e) => this.onDownloadDocument(e, listItem)}
                                    >
                                        <Translate msg={'administration.invoices.actions.download_invoice'} />
                                    </Button>
                                    <Button
                                        id="download-details"
                                        typeName="text"
                                        onClick={(e) => this.onDownloadDetail(e, listItem)}
                                    >
                                        <Translate msg={'administration.invoices.actions.download_detail'} />
                                    </Button>
                                    <Button
                                        id="download-full"
                                        typeName="text"
                                        onClick={(e) => this.onDownloadFull(e, listItem)}
                                    >
                                        <Translate msg={'administration.invoices.actions.download_full'} />
                                    </Button>
                                </div>
                            </>
                        );
                    }}
                </Tooltip>
            </ListItemActions>
        );
    }

    private onDownloadDocument(e: MouseEvent<HTMLElement>, listItem: ListItem<IInvoiceColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDocument,
        } = this.props;

        onDownloadDocument([listItem.id as number]);
    }

    private onDownloadDetail(e: MouseEvent<HTMLElement>, listItem: ListItem<IInvoiceColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDetail,
        } = this.props;

        onDownloadDetail([listItem.id as number]);
    }

    private onDownloadFull(e: MouseEvent<HTMLElement>, listItem: ListItem<IInvoiceColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadFull,
        } = this.props;

        onDownloadFull([listItem.id as number]);
    }
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => {
        return {
            onDownloadDocument: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceDocument({ invoiceIds, delayed: false }));
            },
            onDownloadDetail: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceDetail({ invoiceIds, delayed: true }));
            },
            onDownloadFull: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceFull({ invoiceIds, delayed: true }));
            },
        };
    },
})(InvoiceActions);
