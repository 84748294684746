import { IRoutes, IRoute } from './utils/routing/typings';
export { IPage, ITemplate } from './utils/routing/typings';
import ROUTE_KEYS from './routeKeys';

let allRoutes: IRoutes;

export function setRoutes(routes: IRoutes) {
    allRoutes = routes;
}

export function getRoutes() {
    return allRoutes;
}

export function getRoutesAsList(): { key: ROUTE_KEYS, route: IRoute }[] {
    if (!allRoutes) {
        return [];
    }

    return Object.keys(allRoutes)
        .reduce(
            (accumulator, key) => {
                accumulator.push({ key, route: allRoutes[key] });
                return accumulator;
            },
            [],
        );
}

export function getRoute({ routeKey }: { routeKey: ROUTE_KEYS }) {
    return allRoutes[routeKey];
}

export function getPath({ routeKey }: { routeKey: ROUTE_KEYS }) {
    return getRoute({ routeKey }).path;
}

export function getRouteKeyByPath({ path }: {path: string}) {
    return Object.keys(allRoutes)
        .find((routeKey: ROUTE_KEYS) => getRoute({ routeKey }).path === path);
}

export function doesRouteBelongToGroup({ routeKey, group }: {routeKey: ROUTE_KEYS, group: string}) {
    const route = getRoute({ routeKey });
    if (!route || !route.groups) {
        return false;
    }
    return route.groups.indexOf(group) > -1;
}

export function getRouteKeysThatBelongToGroup(group: string) {
    return Object.keys(allRoutes)
        .filter((routeKey: ROUTE_KEYS) => doesRouteBelongToGroup({ routeKey, group }));
}
