import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { AsyncStatus, IEntityAsyncOperation } from '@snipsonian/redux-features/es/entities/types';
import { connect } from '../../../index';
import { IState } from '../../../../redux';
import { ICustomAsyncEntity } from '../../../../models/entities/entities.models';
import ErrorPlaceholder from '../../error/ErrorPlaceholder';
import TinyLoader from '../../waiting/TinyLoader';
import Loader from '../../waiting/Loader';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { ITraceableApiError } from '../../../../models/general/error';

interface IPublicProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncEntitySelector: (state: IState) => ICustomAsyncEntity<any>;
    renderDataEvenIfNotSet?: boolean; // default false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderData?: (renderProps: { data: any }) => React.ReactElement<{}>;
    fetch?: IFetchProps | false;
}

interface IFetchProps {
    useTinyLoader?: boolean; // default false
    noLoader?: boolean; // default false
}

interface IPrivateProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asyncEntity: ICustomAsyncEntity<any>;
}

function EntityWrapper(props: IPublicProps & IPrivateProps) {
    const {
        asyncEntity,
        renderDataEvenIfNotSet = false,
        renderData,
        fetch = {},
    } = props;

    return (
        <>
            {fetch &&
                <EntityFetchWrapper asyncEntity={asyncEntity} {...fetch} />
            }
            {
                asyncEntity.fetch.status !== AsyncStatus.Error
                && renderData
                && (renderDataEvenIfNotSet || isSet(asyncEntity.data)) &&
                renderData({
                    data: asyncEntity.data,
                })
            }
        </>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    // eslint-disable-next-line arrow-body-style
    statePropsPerInstance: (outerState, publicProps) => {
        return (state) => ({
            asyncEntity: publicProps.asyncEntitySelector(state),
        });
    },
})(EntityWrapper);

function EntityFetchWrapper(props: IPrivateProps & IFetchProps) {
    const {
        asyncEntity,
        useTinyLoader = false,
        noLoader = false,
    } = props;

    return (
        <>
            {!noLoader && (
                useTinyLoader
                    ? <TinyLoader asyncInfoSelector={mapToAsyncFieldInfoWithoutError(asyncEntity.fetch)} />
                    : <Loader show={asyncEntity.fetch.status} />
            )}
            {asyncEntity.fetch.status === AsyncStatus.Error &&
                <ErrorPlaceholder apiError={asyncEntity.fetch.error} />
            }
        </>
    );
}

function mapToAsyncFieldInfoWithoutError(asyncOperation: IEntityAsyncOperation<ITraceableApiError>): IAsyncFieldInfo {
    return {
        status: asyncOperation.status === AsyncStatus.Busy
            ? AsyncStatus.Busy
            : AsyncStatus.Initial,
        error: null,
    };
}
