export const ROUTE_GROUP = {
    EMPLOYEES_FETCH_IF_NOT_AVAILABLE: 'EMPLOYEES_FETCH_IF_NOT_AVAILABLE',
    EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE: 'EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE',
    COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE: 'COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE',
    COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE: 'COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE',
    PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE: 'PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE',
    EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE: 'EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE',
    TO_PLAN_EXAMINATIONS_FETCH_IF_NOT_AVAILABLE: 'TO_PLAN_EXAMINATIONS_FETCH_IF_NOT_AVAILABLE',
    WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE: 'WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE',
    FOLLOWED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE: 'FOLLOWED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE',
    PLANNED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE: 'PLANNED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE',
    ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS: 'ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS',
    ONLY_ALLOWED_IF_ONCE_FLAT_FEE: 'ONLY_ALLOWED_IF_ONCE_FLAT_FEE',
    ONLY_ALLOWED_IF_VALID_SITUATION_HISTORY: 'ONLY_ALLOWED_IF_VALID_SITUATION_HISTORY',
    NO_DATA_REFRESH_ON_LOCALE_SWITCH: 'NO_DATA_REFRESH_ON_LOCALE_SWITCH',
    COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE: 'COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE',

    /* routes who don't have ALLOWED_IF_DISMISSED_COMPANY, will not be allowed
       if the selected company is dismissed (= not active) */
    ALLOWED_IF_DISMISSED_COMPANY: 'ALLOWED_IF_DISMISSED_COMPANY',
    /* used to get the top path for a correct breadcrumb path */
    IS_DASHBOARD: 'IS_DASHBOARD',
    /* if a route with this IS_PART_OF_WIZARD is triggered, the 'previous' route gets stored
       as "routeThatTriggeredWizard" to determine the after-wizard-success destination route
       + when a session timeout occurred on such a route, the user will - after re-login - not be redirected
         to that wizard route
       */
    IS_PART_OF_WIZARD: 'IS_PART_OF_WIZARD',
};
