import { IMenuItem, MenuType } from '../../models/general/navigation';
import { TIconTypeName } from '../../models/general/icon';
import ROUTE_KEYS from '../../routeKeys';
import { IAccessLevel } from '../../models/auth/authorisation';

const MENU_ITEMS: IMenuItem[] = [
    toMenuItem({
        translationKeySuffix: 'app.home',
        linkTo: ROUTE_KEYS.R_HOME,
        iconTypeName: 'home-outline',
        iconAlt: 'Home',
    }),
    toMenuItem({
        translationKeySuffix: 'app.interventions',
        linkTo: ROUTE_KEYS.R_INTERVENTIONS,
    }),
    toMenuItem({
        translationKeySuffix: 'app.administration',
        linkTo: ROUTE_KEYS.R_ADMINISTRATION,
    }),
    toMenuItem({
        translationKeySuffix: 'app.document_center',
        linkTo: ROUTE_KEYS.R_DOCUMENT_CENTER,
    }),
    toMenuItem({
        translationKeySuffix: 'app.contact',
        linkTo: ROUTE_KEYS.R_CONTACT,
    }),
    toMenuItem({
        translationKeySuffix: 'app.performance',
        linkTo: ROUTE_KEYS.R_PERFORMANCE_DASHBOARD,
        isPerformanceDashboardLink: true,
    }),
    toMenuItem({
        translationKeySuffix: 'user.account_settings',
        linkTo: ROUTE_KEYS.R_ACCOUNT_SETTINGS,
        iconTypeName: 'gear',
        type: MenuType.USER_PROFILE,
    }),
    toMenuItem({
        translationKeySuffix: 'user.messages',
        linkTo: ROUTE_KEYS.R_MESSAGE_CENTER,
        iconTypeName: 'message',
        type: MenuType.USER_PROFILE,
    }),
    toMenuItem({
        translationKeySuffix: 'user.logout',
        linkTo: ROUTE_KEYS.R_LOGOUT,
        iconTypeName: 'shutdown',
        type: MenuType.USER_PROFILE,
    }),
];

interface IMenuItemConfig {
    translationKeySuffix: string;
    iconTypeName?: TIconTypeName;
    iconAlt?: string;
    linkTo: ROUTE_KEYS;
    type?: MenuType;
    requiredAccessLevelCombos?: Partial<IAccessLevel>[];
    isPerformanceDashboardLink?: boolean;
}

function toMenuItem({
    translationKeySuffix,
    iconTypeName,
    iconAlt,
    linkTo,
    type = MenuType.TOP,
    isPerformanceDashboardLink,
}: IMenuItemConfig): IMenuItem {
    return {
        code: translationKeySuffix.toUpperCase(),
        translationKey: `app_shell.header.nav.${translationKeySuffix.toLowerCase()}`,
        iconTypeName,
        iconAlt,
        linkTo,
        type,
        isPerformanceDashboardLink,
    };
}

export const MENU_ITEMS_ON_MOBILE = MENU_ITEMS;

export const TOP_MENU_ITEMS = MENU_ITEMS
    .filter((menuItem) => menuItem.type === MenuType.TOP);

export const USER_PROFILE_MENU_ITEMS = MENU_ITEMS
    .filter((menuItem) => menuItem.type === MenuType.USER_PROFILE);
