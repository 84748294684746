import * as Yup from 'yup';
import {
    ShapeOf,
    IStartEndDateFilterValues,
    IStartEndHourFilterValues,
} from '../../../../models/ui/form';
import { fields as hourFields } from '../StartEndHourFilter/startEndHourSchema';
import { datePeriodValidation, hourPeriodValidation } from '../../../../utils/libs/yup/period';

export const fields: ShapeOf<IStartEndDateFilterValues & IStartEndHourFilterValues, string> = {
    endDate: 'endDate',
    startDate: 'startDate',
    endHour: 'endHour',
    startHour: 'startHour',
};

export const startEndDateAndHoursSchema = Yup.object({
    [fields.startDate]: Yup.string().required(),
    [fields.endDate]: datePeriodValidation(Yup.ref(fields.startDate)).required(),
    [hourFields.startHour]: Yup.string().required(),
    [hourFields.endHour]: hourPeriodValidation(Yup.ref(hourFields.startHour)).required(),
});
