import React from 'react';

import { ADD_EMPLOYEE_WIZARD_STEP_ID } from '../../../../models/admin/employee';
import { IStepperStep } from '../../../../models/general/stepper';

import Function from './steps/Function';
import NationalRegisterNumber from './steps/NationalRegisterNumber/index';
import PersonalData from './steps/PersonalData';
import Seat from './steps/Seat';
import StartDate from './steps/StartDate';
import Validate from './steps/Validate';

export const WIZARD_STEPS: IStepperStep[] = [
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.NATIONAL_REGISTER_NUMBER,
        translationKey: 'administration.employees.add.steps.national_register_number.step_label',
        renderStepContent: (props) => <NationalRegisterNumber {...props} />,
    },
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.PERSONAL_DATA,
        translationKey: 'administration.employees.add.steps.personal_data.step_label',
        renderStepContent: (props) => <PersonalData {...props} />,
    },
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.SEAT,
        translationKey: 'administration.employees.add.steps.seat.step_label',
        renderStepContent: (props) => <Seat {...props} />,
    },
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.FUNCTION,
        translationKey: 'administration.employees.add.steps.function.step_label',
        renderStepContent: (props) => <Function {...props} />,
    },
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.STARTDATE,
        translationKey: 'administration.employees.add.steps.startdate.step_label',
        renderStepContent: (props) => <StartDate {...props} />,
    },
    {
        id: ADD_EMPLOYEE_WIZARD_STEP_ID.VALIDATE,
        translationKey: 'administration.employees.add.steps.validate.step_label',
        renderStepContent: (props) => <Validate {...props} />,
    },
];
