import axios, { AxiosRequestConfig, AxiosError, Canceler, AxiosResponse } from 'axios';
import { path, pathOr } from 'ramda';

import {
    DEFAULT_TIMEOUT_IN_MILLIS,
    DEFAULT_HEADERS, HEADER_NAME,
    REQUEST_METHOD,
    API_URL,
} from '../../config/api.config';
import { IApiError, ITraceableApiError, IBffError } from '../../models/general/error';
import { localeToBackendLocale } from '../formatting/formatLocale';
import { Locales } from '../../config/i18n.config';
import { STATE_STORAGE_KEY } from '../../config/redux.config';
import { updateSessionEndTime } from '../../redux/auth/actions';
import { getStore } from '../../redux/storeNoCircularDependencies';
import generateUuid from '../logging/generateUuid';

import {
    AutomaticHeadersType,
    addAutomaticHeader,
    isAutomaticHeaderSet,
    clearAutomaticHeader,
    appendAutomaticHeaders,
} from './automaticHeaders';
import {
    logCancelledRequest,
    logErrorResponse,
    logNoRefresh,
    logRequest,
    logResponse,
} from './apiLogger';
import {
    markAsSuccessfulRequest,
    shouldBeRefreshed,
} from './requestLogOfSuccessfulGets';
import constructResourceUrl, { IUrlParams } from './url/constructResourceUrl';
import generateErrorId from './error/generateErrorId';
import getErrorStatus from './getErrorStatus';
import reportApiError from './reportApiError';

const CancelToken = axios.CancelToken;

const ALWAYS_REFRESH = 0;
const RETURN_DATA_AS_IS = (data) => data; // default do nothing with the response headers

type AccessTokenFetcher = () => string;
let getApiAccessToken: AccessTokenFetcher;

export interface IHttpHeaders {
    [key: string]: string;
}

// tslint:disable-next-line no-empty
let errorHandler = (error: ITraceableApiError): void => {};
let responseHeadersHandler = (headers: IHttpHeaders): void => {};

export const NOT_REFRESHED = { notRefreshed: true };

export enum IResponseType {
    json = 'json',
    text = 'text',
    blob = 'blob',
    document = 'document'
}

export function setErrorHandler(newErrorHandler: typeof errorHandler) {
    errorHandler = newErrorHandler;
}

export function setResponseHeadersHandler(newResponseHeadersHandler: typeof responseHeadersHandler) {
    responseHeadersHandler = newResponseHeadersHandler;
}

export function setCsrfToken(token: string) {
    addAutomaticHeader(
        AutomaticHeadersType.NON_GET,
        { key: HEADER_NAME.CSRF_TOKEN, value: token },
    );
}

export function isCsrfTokenSet() {
    return isAutomaticHeaderSet(
        AutomaticHeadersType.NON_GET,
        HEADER_NAME.CSRF_TOKEN,
    );
}

export function clearCsrfToken() {
    clearAutomaticHeader(
        AutomaticHeadersType.NON_GET,
        HEADER_NAME.CSRF_TOKEN,
    );
}

export function setAccessTokenGetter({ getAccessToken }: { getAccessToken: AccessTokenFetcher }) {
    getApiAccessToken = getAccessToken;
}

export function setLocaleHeader(userLocale: Locales) {
    addAutomaticHeader(
        AutomaticHeadersType.ALL_REQUEST_METHODS,
        { key: HEADER_NAME.LOCALE, value: localeToBackendLocale(userLocale) },
    );
}

export function isLocaleHeaderSet() {
    return isAutomaticHeaderSet(
        AutomaticHeadersType.ALL_REQUEST_METHODS,
        HEADER_NAME.LOCALE,
    );
}

export type IResponseMapper<R> = (data, headers) => R;
export type IRequestWrapperPromise<R> = Promise<R> & { cancelRequest?: Canceler };

export interface IRequestConfig<R> {
    url: string;
    api?: API_URL;
    pathParams?: IUrlParams;
    queryParams?: IUrlParams;
    responseType?: IResponseType;
    timeoutInMillis?: number;
    noRefreshPeriodInMillis?: number;
    mapResponse?: IResponseMapper<R>;
    headers?: object;
    resetSessionTimerOnSuccess?: boolean;
    doNotPrefixUrl?: boolean;
    addAuthorizationHeader?: boolean; // default false
    addCorrelationIdHeader?: boolean; // default false
    body?: object | string;
}

export function get<R>({
    url,
    api = API_URL.BASE,
    pathParams = {},
    queryParams = {},
    responseType = IResponseType.json,
    timeoutInMillis = DEFAULT_TIMEOUT_IN_MILLIS,
    noRefreshPeriodInMillis = ALWAYS_REFRESH,
    mapResponse = RETURN_DATA_AS_IS,
    headers = {},
    resetSessionTimerOnSuccess = true,
    doNotPrefixUrl,
    addAuthorizationHeader = false,
    addCorrelationIdHeader = false,
}: IRequestConfig<R>) {
    const request = {
        responseType,
        url: constructResourceUrl({ url, pathParams, queryParams, api, doNotPrefixUrl }),
        method: REQUEST_METHOD.GET,
        timeout: timeoutInMillis,
        headers: appendConditionalHeadersToBaseConfigHeaders({
            headers,
            addAuthorizationHeader,
            addCorrelationIdHeader,
        }),
    };

    return executeApiCall<R>(request, mapResponse, resetSessionTimerOnSuccess, noRefreshPeriodInMillis);
}

export function post<R>({
    url,
    api = API_URL.BASE,
    pathParams = {},
    queryParams = {},
    body = {},
    responseType = IResponseType.json,
    timeoutInMillis = DEFAULT_TIMEOUT_IN_MILLIS,
    mapResponse = RETURN_DATA_AS_IS,
    headers = { ...DEFAULT_HEADERS },
    resetSessionTimerOnSuccess = true,
    doNotPrefixUrl,
    addAuthorizationHeader = false,
    addCorrelationIdHeader = false,
}: IRequestConfig<R>) {
    const request = {
        responseType,
        url: constructResourceUrl({ url, pathParams, queryParams, api, doNotPrefixUrl }),
        data: body,
        method: REQUEST_METHOD.POST,
        timeout: timeoutInMillis,
        headers: appendConditionalHeadersToBaseConfigHeaders({
            headers,
            addAuthorizationHeader,
            addCorrelationIdHeader,
        }),
    };

    return executeApiCall<R>(request, mapResponse, resetSessionTimerOnSuccess);
}

export function put<R>({
    url,
    api = API_URL.BASE,
    pathParams = {},
    queryParams = {},
    body = {},
    responseType = IResponseType.json,
    timeoutInMillis = DEFAULT_TIMEOUT_IN_MILLIS,
    mapResponse = RETURN_DATA_AS_IS,
    headers = { ...DEFAULT_HEADERS },
    resetSessionTimerOnSuccess = true,
    doNotPrefixUrl,
    addAuthorizationHeader = false,
    addCorrelationIdHeader = false,
}: IRequestConfig<R>) {
    const request = {
        responseType,
        url: constructResourceUrl({ url, pathParams, queryParams, api, doNotPrefixUrl }),
        data: body,
        method: REQUEST_METHOD.PUT,
        timeout: timeoutInMillis,
        headers: appendConditionalHeadersToBaseConfigHeaders({
            headers,
            addAuthorizationHeader,
            addCorrelationIdHeader,
        }),
    };

    return executeApiCall<R>(request, mapResponse, resetSessionTimerOnSuccess);
}

export function patch<R>({
    url,
    api = API_URL.BASE,
    pathParams = {},
    queryParams = {},
    body = {},
    responseType = IResponseType.json,
    timeoutInMillis = DEFAULT_TIMEOUT_IN_MILLIS,
    mapResponse = RETURN_DATA_AS_IS,
    headers = { ...DEFAULT_HEADERS },
    resetSessionTimerOnSuccess = true,
    doNotPrefixUrl,
    addAuthorizationHeader = false,
    addCorrelationIdHeader = false,
}: IRequestConfig<R>) {
    const request = {
        responseType,
        url: constructResourceUrl({ url, pathParams, queryParams, api, doNotPrefixUrl }),
        data: body,
        method: REQUEST_METHOD.PATCH,
        timeout: timeoutInMillis,
        headers: appendConditionalHeadersToBaseConfigHeaders({
            headers,
            addAuthorizationHeader,
            addCorrelationIdHeader,
        }),
    };

    return executeApiCall<R>(request, mapResponse, resetSessionTimerOnSuccess);
}

export function remove<R>({
    url,
    api = API_URL.BASE,
    pathParams = {},
    queryParams = {},
    body = {},
    responseType = IResponseType.json,
    timeoutInMillis = DEFAULT_TIMEOUT_IN_MILLIS,
    mapResponse = RETURN_DATA_AS_IS,
    headers = { ...DEFAULT_HEADERS },
    resetSessionTimerOnSuccess = true,
    doNotPrefixUrl,
    addAuthorizationHeader = false,
    addCorrelationIdHeader = false,
}: IRequestConfig<R>) {
    const request = {
        responseType,
        url: constructResourceUrl({ url, pathParams, queryParams, api, doNotPrefixUrl }),
        data: body,
        method: REQUEST_METHOD.DELETE,
        timeout: timeoutInMillis,
        headers: appendConditionalHeadersToBaseConfigHeaders({
            headers,
            addAuthorizationHeader,
            addCorrelationIdHeader,
        }),
    };

    return executeApiCall<R>(request, mapResponse, resetSessionTimerOnSuccess);
}

function executeApiCall<R>(
    request: AxiosRequestConfig,
    mapResponse: IResponseMapper<R>,
    resetSessionTimerOnSuccess: boolean,
    noRefreshPeriodInMillis?: number,
) {
    request.headers = appendAutomaticHeaders(request.headers, request.method as REQUEST_METHOD);

    logRequest(request);

    if (request.method === REQUEST_METHOD.GET) {
        if (!shouldBeRefreshed(request, noRefreshPeriodInMillis)) {
            logNoRefresh(request, noRefreshPeriodInMillis);
            return Promise.reject(NOT_REFRESHED) as IRequestWrapperPromise<R>;
        }
    }

    const source = CancelToken.source();
    request.cancelToken = source.token;

    const requestPromise = axios(request)
        .then((result) => {
            logResponse(result);
            if (resetSessionTimerOnSuccess) {
                getStore().dispatch(updateSessionEndTime());
            }
            if (request.method === REQUEST_METHOD.GET) {
                markAsSuccessfulRequest(request);
            }
            return result;
        })
        .then(({ data, headers }: AxiosResponse) => {
            responseHeadersHandler(headers);
            return mapResponse(data, headers);
        })
        .catch(async (error: AxiosError) => {
            if (error.response && error.response.headers) {
                responseHeadersHandler(error.response.headers);
            }
            const apiError = await transformError(error);
            if (!wasApiCancelled(error)) {
                logErrorResponse(error);
                reportApiError(error, apiError);
            } else {
                logCancelledRequest(error);
            }
            errorHandler(apiError);
            throw apiError;
        }) as IRequestWrapperPromise<R>;

    requestPromise.cancelRequest = source.cancel;

    return requestPromise;
}

async function transformError(error: AxiosError): Promise<ITraceableApiError> {
    const serverErrorResponse = await extractServerErrorResponse(error);
    const requestMethod = error.config && error.config.method.toUpperCase();
    const wasCancelled = wasApiCancelled(error);

    if (
        serverErrorResponse
        && (serverErrorResponse as IApiError).code
        && (serverErrorResponse as IApiError).message
    ) {
        return {
            id: generateErrorId(),
            requestMethod,
            wasCancelled,
            type: 'api',
            ...(serverErrorResponse as IApiError),
        };
    }

    if (
        serverErrorResponse
        && (serverErrorResponse as IBffError).status
        && (serverErrorResponse as IBffError).title
    ) {
        const code = pathOr('', ['status'], serverErrorResponse);
        const message = pathOr('', ['type'], serverErrorResponse);

        return {
            id: generateErrorId(),
            requestMethod,
            wasCancelled,
            extraData: {},
            code,
            message: message.toLowerCase(),
            type: 'bff',
        };
    }

    return {
        id: generateErrorId(),
        code: getErrorStatus(error),
        message: error.message,
        extraData: {},
        requestMethod,
        wasCancelled,
        type: 'api',
    };
}

async function extractServerErrorResponse(error: AxiosError): Promise<IApiError | IBffError> {
    if (error.response) {
        let responseJson = error.response.data as IApiError;
        // Some browsers like IE don't parse the JSON automatically
        if (typeof responseJson !== 'object') {
            try {
                responseJson = JSON.parse(responseJson);
            } catch (err) {
                // Not a valid json
            }
        }
        if (responseJson instanceof Blob) {
            try {
                const data = await blobToString(responseJson);
                responseJson = JSON.parse(data);
            } catch (err) {
                // Not a valid json
            }
        }
        return responseJson;
    }
    return undefined;
}

function wasApiCancelled(error: AxiosError) {
    return axios.isCancel(error);
}

function blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (err) => reject(err);
        reader.readAsText(blob);
    });
}

function appendConditionalHeadersToBaseConfigHeaders<R>({
    headers,
    addAuthorizationHeader,
    addCorrelationIdHeader,
}: Pick<IRequestConfig<R>, 'headers' | 'addAuthorizationHeader' | 'addCorrelationIdHeader'>): object {

    if (addAuthorizationHeader) {
        const apiAccessToken = getAccessToken();

        if (apiAccessToken) {
            // eslint-disable-next-line no-param-reassign
            headers[HEADER_NAME.AUTHORIZATION] = `Bearer ${apiAccessToken}`;
        }
    }

    if (addCorrelationIdHeader) {
        /*
         * This unique uuid will be passed by the IntegrationLayer to all the underlying
         * systems for traceability purposes.
         * */

        // eslint-disable-next-line no-param-reassign
        headers[HEADER_NAME.CORRELATION_ID] = generateUuid();
    }

    return headers;
}

// I'm so terribly sorry for this 😅
const getAccessToken = () => {
    let accessTokenFallback;

    const store = getStore();

    if (!store) {
        accessTokenFallback = path(
            ['auth', 'session', 'accessToken'],
            (JSON.parse(window.localStorage.getItem(STATE_STORAGE_KEY))),
        );
    } else {
        accessTokenFallback = path(
            ['auth', 'session', 'accessToken'],
            store.getState(),
        );
    }

    if (typeof getApiAccessToken !== 'function') {
        return accessTokenFallback;
    }

    return getApiAccessToken();
};
