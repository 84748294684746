import { object } from 'yup';
import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';
import number from '../../../../../utils/libs/yup/number';
import { ICourseExternalEmployee } from '../../../../../models/documentCenter/courses';
import string from '../../../../../utils/libs/yup/string';

export type FormValues =
    Pick<ICourseExternalEmployee,
        'birthDate' | 'email' | 'firstName' | 'languageId' | 'name' | 'phone'> & { functionDescription: string };

export const fields = {
    firstName: 'firstName',
    name: 'name',
    birthDate: 'birthDate',
    phone: 'phone',
    email: 'email',
    languageId: 'languageId',
    functionDescription: 'functionDescription',
};

export const schema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.birthDate]: string().required(),
    [fields.phone]: phoneNumber().required(),
    [fields.email]: string().email().required(),
    [fields.languageId]: number().required(),
    [fields.functionDescription]: string().required(),
});
