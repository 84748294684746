import React, { PureComponent } from 'react';
import './planned-courses.scss';
import { clone } from 'ramda';
import { connect } from '../../../index';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IClientSideFilterOfListDataProps,
    ITransformToActiveFiltersProps,
    IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
    IRenderDetailFooterProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../../models/general/list';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { ICourse } from '../../../../models/documentCenter/courses';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import {
    getCoursesPlannedAsyncInfo, getCoursesPlanned,
    getSelectedPlannedCourse,
} from '../../../../redux/documentCenter/courses/selectors';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { DEFAULT_COURSES_PLANNED_FILTER } from '../../../../api/documentCenter/courses.api';
import { isFilterSet } from '../../../common/widget/MasterWithDetail/Master';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import
getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter/index';
import DetailHeader from '../shared/header';
import DetailContent from './detail/content';
import DetailFooter from './detail/footer';
import FreeSlotsIndicator from '../../../common/widget/FreeSlotsIndicator';
import DetailOverlay from './detail/detailOverlay';
import { IPlannedCoursesDetailOverlayFormData, IPlannedCoursesDetailOverlayType } from './common';

const BASE_NAME = 'courses-planned';

const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IColumnNames {
    name: string;
    type: string;
    location: string;
    coordinator: string;
    availability: string;
    startDate: string;
    startDateSort: string;
    endDate: string;
    endDateSort: string;
    capacity: number;
    freeSlots: number;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    location: string;
}

interface IPrivateProps {
}

type TPlannedCoursesListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="document_center.courses.planned.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    type: {
        label: <Translate msg="document_center.courses.planned.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 10,
    },
    location: {
        label: <Translate msg="document_center.courses.planned.columns.location" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    coordinator: {
        label: <Translate msg="document_center.courses.planned.columns.coordinator" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    availability: {
        label: <Translate msg="document_center.courses.planned.columns.availability" />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 13,
        render: (item: ListItem<IColumnNames>, index?: number) => {
            return (
                <FreeSlotsIndicator
                    maxSlots={item.columns.capacity as number}
                    freeSlots={item.columns.freeSlots as number}
                />
            );
        },
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.freeSlots,
    },
    startDate: {
        label: <Translate msg="document_center.courses.planned.columns.start" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 11,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.startDateSort,
    },
    startDateSort: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
    endDate: {
        label: <Translate msg="document_center.courses.planned.columns.end" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 11,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.endDateSort,
    },
    endDateSort: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
    freeSlots: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
    capacity: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
};

class CoursesPlanned extends PureComponent<IPrivateProps> {
    public render() {
        return (
            <>
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_COURSES_PLANNED,
                        asyncInfoSelector: getCoursesPlannedAsyncInfo,
                        dataSelector: getCoursesPlanned,
                        transformData: mapCoursesToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: TPlannedCoursesListProps) =>
                            <CoursesList {...renderProps} {...this.props} />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData,
                        filterValidationSchema: startEndDateSchema,
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <SearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                <FilterContent {...renderProps} />,
                        exportButton: {
                            baseFilename: 'courses-planned',
                            listItemIdExtractor: toListId,
                            mapListRowForExport,
                        },
                    }}
                    detailConfig={{
                        routeKey: ROUTE_KEYS.R_COURSES_PLANNED_DETAIL,
                        asyncInfoSelector: getCoursesPlannedAsyncInfo,
                        idRouteParamName: 'coursesOrganizedId',
                        dataSelector: getSelectedPlannedCourse,
                        renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
                            <DetailContent {...renderProps} />,
                        renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
                            <DetailHeader {...renderProps} />,
                        renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
                            <DetailFooter {...renderProps} />,
                        renderOverlay: ({
                            overlayType, closeOverlay, formValues,
                        }) =>
                            <DetailOverlay
                                overlayType={overlayType as IPlannedCoursesDetailOverlayType}
                                closeOverlay={closeOverlay}
                                formValues={formValues as IPlannedCoursesDetailOverlayFormData}
                            />,
                    }}
                    footerConfig={{
                        shouldRenderShowAllButton,
                    }}
                />
            </>
        );
    }
}

function toListId(course: ICourse) {
    return course.coursesOrganizedId;
}

function mapCoursesToListItems(masterData: ICourse[]): ListItem<IColumnNames>[] {
    return masterData
        .map((course) => {
            return {
                id: toListId(course),
                columns: {
                    name: course.courseName,
                    type: course.courseType,
                    location: course.address ? formatAddress(course.address) : '',
                    coordinator: course.coordinator,
                    availability: null,
                    capacity: course.maxAmountAttendees,
                    freeSlots: course.maxAmountAttendees - course.countAmountAttendees,
                    startDate: formatDateForDisplay(course.startDate),
                    startDateSort: course.startDate,
                    endDate: formatDateForDisplay(course.endDate),
                    endDateSort: course.endDate,
                },
            };
        },
        );
}

function mapListRowForExport(course: ICourse) {
    const {
        amountDays,
        address: { city },
        company: { companyCode },
        company: { name },
        countAmountAttendees,
        courseName,
        courseType,
        endDate,
        startDate,
    } = course;

    return {
        company: {
            companyCode,
            name,
        },
        courseName,
        courseType,
        address: {
            city,
        },
        startDate,
        endDate,
        amountDays,
        countAmountAttendees,
    };
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.location)) {
                return true;
            }

            const locations = separateStringList(filterValues.location);
            return locations.includes(listItem.columns.location && listItem.columns.location.toString());
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('document_center.courses.planned.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="document_center.courses.planned.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleLocations = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'location',
        'location',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="document_center.courses.planned.filter"
                formRenderProps={formRenderProps}
                minDate={DEFAULT_COURSES_PLANNED_FILTER.startDate}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="location"
                labelTranslationKey="document_center.courses.planned.filter.location"
                possibleFilterItems={possibleLocations}
                actualFilterValue={formRenderProps.values.location}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'location',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'document_center.courses.planned.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            location: {
                show: true,
                translationKeySuffixOverride: 'location',
                multiple: {
                    enable: true,
                },
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_COURSES_PLANNED_FILTER.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_COURSES_PLANNED_FILTER.startDate,
            },
        },
    });
}

class CoursesList extends
    PureComponent<TPlannedCoursesListProps & IPrivateProps> {

    private columns = clone(COLUMNS);

    constructor(props: TPlannedCoursesListProps & IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        const INITIAL_SORT: ISortedColumn<IColumnNames> = {
            name: 'startDate',
            sortOrder: SortOrder.Ascending,
        };

        return (
            <>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    initialSort={INITIAL_SORT}
                />
            </>
        );
    }
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_COURSES_PLANNED_FILTER,
        isShowAll,
    } : DEFAULT_COURSES_PLANNED_FILTER;
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps, TPlannedCoursesListProps>({
    stateProps: (state) => {
        return {
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
        };
    },
})(CoursesPlanned);
