import React, { PureComponent, ReactNode } from 'react';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import './pre-header.scss';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import HideAfterDelay from '../../common/waiting/HideAfterDelay';

interface IPreHeaderProps {
    className?: string;
    children: ReactNode;
    onClose?: () => void;
    autoHide?: boolean;
    autoHideDuration?: number;
    disableAnimation?: boolean;
}

interface IPreHeaderState {
    isVisible?: boolean;
    endOfAnimation?: boolean;
}

const CLASS_NAME = 'PreHeader';

export default class PreHeader extends PureComponent<IPreHeaderProps, IPreHeaderState> {
    private setShowTimeout: number;

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            endOfAnimation: false,
        };

        this.onCloseIntent = this.onCloseIntent.bind(this);
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    public render() {
        const { autoHide, autoHideDuration, disableAnimation } = this.props;
        const { isVisible } = this.state;

        return (
            <HideAfterDelay enabled={autoHide} hideDelay={autoHideDuration} onHide={this.onCloseIntent}>
                {disableAnimation ? (
                    this.renderContent()
                ) : (
                    <AnimateHeight
                        duration={500}
                        height={isVisible ? 'auto' : 0}
                        onAnimationEnd={this.onAnimationEnd}
                    >
                        {this.renderContent()}
                    </AnimateHeight>
                )}
            </HideAfterDelay>
        );
    }

    private renderContent() {
        const { children, className, disableAnimation } = this.props;
        const { endOfAnimation } = this.state;

        const PreHeaderClass = classNames(CLASS_NAME, {
            [`${className}`]: !!className,
            [`${CLASS_NAME}--visible`]: disableAnimation || !!endOfAnimation,
        });

        return (
            <div className={PreHeaderClass}>
                <div className={`${CLASS_NAME}__inner`}>
                    {children}
                </div>
                <div className={`${CLASS_NAME}__close`}>
                    <Button
                        id="pre-header-close"
                        typeName="text"
                        onClick={this.onCloseIntent}
                    >
                        <Icon typeName="cross" />
                    </Button>
                </div>
            </div>
        );
    }

    private onCloseIntent() {
        this.setState({
            isVisible: false,
        });

        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    }

    private clearShowTimeout() {
        if (this.setShowTimeout) {
            window.clearTimeout(this.setShowTimeout);
        }
    }

    public componentDidMount() {
        this.setShowTimeout = window.setTimeout(
            () => {
                this.setState({
                    isVisible: true,
                });
            },
            500);
    }

    public componentWillUnmount() {
        this.clearShowTimeout();
    }

    private onAnimationEnd() {
        this.setState({
            endOfAnimation: true,
        });
    }
}
