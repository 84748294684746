import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import {
    IFilterValues,
} from '../../../../views/interventions/MedicalExaminations/Reserved/Overview/ReservedMedicalExaminationOverview.type';
import { monthOffsetFromNow, now } from '../../../../utils/core/date/getSpecificDate';

export const DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS: Partial<IFilterValues> = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(monthOffsetFromNow(6)),
};


