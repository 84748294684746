import { registerJourney } from '@snipsonian/redux/es/middleware/journey/journeyManager';
import { IAction } from '@snipsonian/redux/es/action/types';
import { IOnActionTypeRegex, IJourneyConfig } from '@snipsonian/redux/es/middleware/journey/types';
import { initCookieConsent, pushCookieConfigurationToGTM, storeCookieConsent, pushVisitorTypeToGTM } from './actions';
import {
    INIT_COOKIE_CONSENT,
    UPDATE_COOKIE_CONSENTS,
    STORE_COOKIE_CONSENTS,
    PUSH_COOKIE_CONSENTS_TO_GTM,
    PUSH_VISITOR_TYPE_TO_GTM,
} from './actionTypes';
import { getCurrentCookieConsentConfiguration } from './selectors';
import {
    IState,
    TPreCookiesUpdateHandler,
    TPostCookiesUpdateHandler,
    IBaseCookiesConfiguration,
    ICookieConsentState,
    IVisitorTypePayload,
    IInitCookieConsentPayload,
    ICookiesConfiguration,
} from './types';
import { VISITOR_TYPE_EVENT, COOKIE_CONFIGURATION_EVENT } from '../config/dataLayer.config';

declare let dataLayer: any[];

// eslint-disable-next-line max-len
export default function registerCookieConsentJourneys<CConfig extends IBaseCookiesConfiguration, ExtraMetadata extends {} = {}>({
    actionToInitializeCookieConsent,
    getConfiguration,
    preCookiesUpdate,
    postCookiesUpdate,
}: {
    // you can choose to specify an action which triggers the init, or you can do this yourself
    actionToInitializeCookieConsent?: string|IOnActionTypeRegex;
    getConfiguration?: ({ getState }: { getState: () => IState<CConfig> }) => Promise<ICookiesConfiguration>;
    preCookiesUpdate?: TPreCookiesUpdateHandler<CConfig, ExtraMetadata>;
    postCookiesUpdate?: TPostCookiesUpdateHandler<CConfig>;
}) {
    const getCurrentCookieConfiguration = (getState: () => IState<CConfig>): Promise<ICookiesConfiguration> => {
        return getConfiguration({ getState }) || Promise.resolve({
            version: 1,
            enabled: true,
        });
    };

    if (actionToInitializeCookieConsent) {
        registerCCJourney({
            onActionType: typeof actionToInitializeCookieConsent === 'string' ?
                actionToInitializeCookieConsent as string : null,
            onActionTypeRegex: typeof actionToInitializeCookieConsent !== 'string' ?
                actionToInitializeCookieConsent as IOnActionTypeRegex : null,
            process: (({ dispatch }) => {
                dispatch(initCookieConsent());
            }),
        });
    }

    let isInitialized = false;
    registerCCJourney<IInitCookieConsentPayload>({
        onActionType: INIT_COOKIE_CONSENT,
        filter: ({ getState, action, dispatch }) => {
            if (isInitialized) {
                return false;
            }

            if (!action.payload.version) {
                (async () => {
                    const config = await getCurrentCookieConfiguration(getState);
                    if (config && config.enabled) {
                        dispatch(initCookieConsent(config.version));
                    }
                })();

                return false;
            }

            return action;
        },
        process: async ({ getState, dispatch }) => {
            isInitialized = true;

            const state = getState();
            const config = await getCurrentCookieConfiguration(getState);
            if (config.enabled) {
                dispatch(pushCookieConfigurationToGTM(getCurrentCookieConsentConfiguration<CConfig>(state)));
                if (config.visitorType) {
                    (async () => {
                        dispatch(pushVisitorTypeToGTM(config.visitorType));
                    })();
                }
            }
        },
    });

    registerCCJourney<CConfig>({
        onActionType: UPDATE_COOKIE_CONSENTS,
        filter: ({ action }) => {
            if (!isInitialized) {
                return false; // is this ok?
            }

            return action;
        },
        process: async ({ action, getState, dispatch }) => {
            const state = getState();
            const currentCookies = getCurrentCookieConsentConfiguration<CConfig>(state);

            const [configuration, metadata] = await Promise.all([
                getCurrentCookieConfiguration(getState),
                preCookiesUpdate({
                    currentCookies: action.payload,
                    previousCookies: currentCookies,
                }),
            ]);

            if (configuration.enabled) {
                dispatch(storeCookieConsent<ExtraMetadata>(configuration.version, action.payload, metadata));
            }
        },
    });

    registerCCJourney<ICookieConsentState>({
        onActionType: STORE_COOKIE_CONSENTS,
        filter: ({ action }) => {
            if (!isInitialized) {
                return false; // is this ok?
            }

            return action;
        },
        process: async ({ getState, dispatch }) => {
            const state = getState();
            const currentCookies = getCurrentCookieConsentConfiguration<CConfig>(state);
            const config = await getCurrentCookieConfiguration(getState);
            if (config.enabled) {
                dispatch(pushCookieConfigurationToGTM(currentCookies));
                await (postCookiesUpdate ? postCookiesUpdate({
                    currentCookies,
                }) : Promise.resolve());
            }
        },
    });

    registerCCJourney<CConfig>({
        onActionType: PUSH_COOKIE_CONSENTS_TO_GTM,
        filter: ({ action }) => {
            if (!isInitialized) {
                return false; // is this ok?
            }

            return action;
        },
        process: async ({ action }) => {
            pushCookieConfigurationEventOnDatalayer(action.payload);
        },
    });

    registerCCJourney<IVisitorTypePayload>({
        onActionType: PUSH_VISITOR_TYPE_TO_GTM,
        filter: ({ action }) => {
            if (!isInitialized) {
                return false; // is this ok?
            }

            return action;
        },
        process: async ({ action }) => {
            pushVisitorTypeEventOnDatalayer(action.payload.visitorType);
        },
    });

    function pushCookieConfigurationEventOnDatalayer(cookieObject: CConfig) {
        pushOnDataLayer({
            event: COOKIE_CONFIGURATION_EVENT,
            attributes: cookieObject,
        });
    }

    function pushVisitorTypeEventOnDatalayer(visitorType: string) {
        if (visitorType === null) {
            return;
        }

        pushOnDataLayer({
            event: VISITOR_TYPE_EVENT,
            type: visitorType,
        });
    }

    function pushOnDataLayer(eventObject: object) {
        if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
            dataLayer.push(eventObject);
        } else {
            throw new Error('Could not find dataLayer on window.');
        }
    }

    function registerCCJourney<IncomingActionPayload>(
        journeyConfig: IJourneyConfig<IState<CConfig>, IAction<IncomingActionPayload>, {}>,
    ) {
        return registerJourney<IState<CConfig>, IAction<IncomingActionPayload>, {}>(journeyConfig);
    }
}
