import { get, IResponseType } from '../../utils/api/requestWrapper';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, monthOffsetFromNow } from '../../utils/core/date/getSpecificDate';
import {
    IFetchWorkAccidentsPayload, IWorkAccidentsFilter,
    IWorkAccident,
    IFetchWorkAccidentDocumentPayload,
} from '../../models/interventions/workAccidents';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';

export const URL = {
    WORK_ACCIDENTS: '/companies/{companyCode}/work-accidents',
    WORK_ACCIDENT_FILES: '/companies/{companyCode}/work-accident/{id}/documents',
};

export const DEFAULT_WORK_ACCIDENTS_FILTERS = {
    startDate: formatDateForBackend(monthOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchWorkAccidents(
    { companyCode, showFullFamily }: IFetchWorkAccidentsPayload,
    {
        startDate = DEFAULT_WORK_ACCIDENTS_FILTERS.startDate,
        endDate = DEFAULT_WORK_ACCIDENTS_FILTERS.endDate,
    }: IWorkAccidentsFilter,
) {
    return get<IWorkAccident[]>({
        url: URL.WORK_ACCIDENTS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['work-accidents'],
    });
}

export function fetchWorkAccidentFiles(
    { id }: IFetchWorkAccidentDocumentPayload,
    companyCode: string,
) {
    return get<IDocument>({
        url: URL.WORK_ACCIDENT_FILES,
        pathParams: {
            companyCode,
            id,
        },
        responseType: IResponseType.blob,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers),
            };
        },
    });
}
