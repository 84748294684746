export default function closest(el: Node, predicate: (el: Node) => boolean): Node {
    // tslint:disable no-conditional-assignment no-parameter-reassignment
    do {
        if (el && predicate(el)) {
            return el;
        }
    } while ((el = el && el.parentNode));
    // tslint: enable
    return null;
}
