import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncDoInfoSelector } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getUpdateEmployeeAllFieldsAndEmploymentAsyncField =
    (state: IState) => reducerState(state).updateEmployeeAllFieldsAndEmployment;
export const getUpdateEmployeeAllFieldsAndEmploymentAsyncInfo =
    makeAsyncDoInfoSelector(getUpdateEmployeeAllFieldsAndEmploymentAsyncField);

// Add employee

const getAddEmployeeAsyncField = (state: IState) => reducerState(state).addEmployee;

export const getEmployeeToAdd = (state: IState) => reducerState(state).employeeToAdd;
export const getAddEmployeeAsyncInfo = makeAsyncDoInfoSelector(getAddEmployeeAsyncField);
export const isEmployeeToAddNotEmpty = (state: IState) => !!reducerState(state).employeeToAdd;

export const getAddEmployeeWizardStepId = (state: IState) => reducerState(state).addEmployeeWizardStepId;
export const getAddEmployeeWizardShouldUpdate = (state: IState) => reducerState(state).addEmployeeWizardShouldUpdate;

export const getEmployeeToAddInitialData = (state: IState) => {
    return reducerState(state).employeeToAddInitialData.data;
};
export const isEmployeeToAddInitialDataAvailable = (state: IState) => {
    return !!reducerState(state).employeeToAddInitialData.data;
};
export const getEmployeeToAddInitialDataIsFetching = (state: IState) => {
    return reducerState(state).employeeToAddInitialData.isFetching;
};

export const getEmployeeToAddInitialDataError = (state: IState) => {
    return reducerState(state).employeeToAddInitialData.error;
};
