import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers,
} from '../index';
import ROUTE_KEYS from '../../routeKeys';
import { FETCH_TOP_TASKS, UPDATE_TOP_TASKS, FETCH_WEB_APP_MESSAGES } from './types';
import { IAsyncDoField } from '../../models/general/redux';
import { getAsyncDoInitialState } from '../../utils/libs/redux/async/asyncReducerUtils';
import { IUpdateTopTasksPayload } from '../../models/user/topTasks';
import { ICmsWebAppMessages } from '../../models/general/webAppMessages';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.NAVIGATION;

export interface IReducerState {
    topTasks: IAsyncFetchField<string[]>;
    updateTopTasks: IAsyncDoField;
    webAppMessages: IAsyncFetchField<ICmsWebAppMessages>;
}

const initialState: IReducerState = {
    topTasks: getAsyncFetchInitialState(),
    updateTopTasks: getAsyncDoInitialState(),
    webAppMessages: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<string[], IReducerState, string[]>({
        baseActionType: FETCH_TOP_TASKS,
        fieldName: 'topTasks',
        overrideTriggerActionType: [ROUTE_KEYS.R_HOME, ROUTE_KEYS.R_EDIT_TOP_TASKS],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<string[], IReducerState, string[]>({
        baseActionType: FETCH_WEB_APP_MESSAGES,
        fieldName: 'webAppMessages',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_TOP_TASKS,
        fieldName: 'updateTopTasks',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const { topTasks } = payload as IUpdateTopTasksPayload;
            return {
                ...oldState,
                topTasks: {
                    ...oldState.topTasks,
                    data: topTasks.map((item) => item.id),
                },
            };
        },
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
