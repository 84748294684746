import { IAsyncFieldInfo } from '../../../models/general/redux';
import { IRequestAccountPayload, ICreateAccountPayload } from '../../../models/auth/authentication';
import { ITranslator } from '../../../models/general/i18n';
import { IUserAccount } from '../../../models/user/userAccount';

export enum RequestAccountSteps { Request, Create, Success }

export interface IPublicProps {
    showAdministrators: boolean;
}

export interface IPrivateProps {
    translator: ITranslator;
    isRequestingOrCreatingAccount: boolean;
    isFormActive: boolean;
    requestAccountAdministrators: IUserAccount[];
    requestAccountAsyncInfo: IAsyncFieldInfo;
    createAccountAsyncInfo: IAsyncFieldInfo;
    navigateToLogin: () => void;
    onRequestAccount: (values: IRequestAccountPayload) => void;
    onCreateAccount: (values: ICreateAccountPayload) => void;
}

export interface IComponentState {
    activeStep: RequestAccountSteps;
    requestAccount: IRequestAccountPayload;
}
