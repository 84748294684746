import { object } from 'yup';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    referentionCode: 'referentionCode',
    remark: 'remark',
};

export const schema = object({
    [fields.referentionCode]: string(),
    [fields.remark]: string(),
});
