import React from 'react';
import { DroppableId, Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd';

interface IPublicProps {
    draggableId: DroppableId;
    index: number;
    render: (renderProps: IDraggableRenderProps) => React.ReactNode;
    className?: string;
    disableMove?: boolean;
    isDragDisabled?: boolean;
}

export interface IDraggableRenderProps {
    dragState: DraggableStateSnapshot;
}

export default function DraggableWrapper({
    render, draggableId, index, className, disableMove, isDragDisabled,
}: IPublicProps) {
    return (
        <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => {
                const disableMoveStyle = {
                    ...provided.draggableProps.style,
                    transform: 'translate(0px, 0px)',
                };

                return (
                    <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className={className}
                        style={disableMove && !snapshot.isDragging ? disableMoveStyle : provided.draggableProps.style}
                    >
                        {render({ dragState: snapshot })}
                    </div> as React.ReactElement<HTMLElement>
                );
            }}
        </Draggable>
    );
}
