import React, { ChangeEvent, PureComponent } from 'react';
import './notifications.scss';
import { ListColumns, ListItem } from '../../../../../models/general/list';
import List from '../../../../common/list/List';
import Translate from '../../../../common/Translate';
import Checkbox from '../../../../common/input/Checkbox';
import connect from '../../../../../utils/libs/redux/connect';
import {
    getUpdateUserMessageTypesAsyncInfo, getUserMessageTypes, getFetchUserMessageTypesAsyncInfo,
} from '../../../../../redux/inbox/selectors';
import { IMessageType, IFormMessageType, IUpdateUserMessageTypesPayload } from '../../../../../models/user/inbox';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { updateUserMessageTypesActions } from '../../../../../redux/inbox/actions';
import FormError from '../../../../common/forms/FormError';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import Loader from '../../../../common/waiting/Loader';
import SuccessDialog from '../../../../common/modals/SuccessDialog/index';
import { getFetchConstantsAsyncInfo } from '../../../../../redux/constants/selectors';

type TFormValues = {
    'messageType': IFormMessageType[],
};

interface IColumnNames {
    name: string;
    notification: string;
}

interface IListItemData {
    disabled: boolean;
}

interface IPrivateProps {
    userMessageTypes: IMessageType[];
    updateUserMessageTypesAsyncInfo: IAsyncFieldInfo;
    updateUserMessageTypes: (payload: IUpdateUserMessageTypesPayload) => void;
    resetAction: () => void;
    fetchUserMessageTypesAsyncInfo: IAsyncFieldInfo;
}

const CLASS_NAME = 'Notifications';
const FORM_NAME = 'change-user-notifications-form';

class Notifications extends PureComponent<IPrivateProps>{
    private formRenderProps: IFormRenderProps<TFormValues>;

    constructor(props) {
        super(props);

        this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isUserMessageActive = this.isUserMessageActive.bind(this);
        this.renderNotificationColumn = this.renderNotificationColumn.bind(this);
        this.getColumnsConfig = this.getColumnsConfig.bind(this);
        this.onChangeMessageType = this.onChangeMessageType.bind(this);
        this.getMessageTypeIndexInFormValues = this.getMessageTypeIndexInFormValues.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
        this.mapMessageTypesToNotifications = this.mapMessageTypesToNotifications.bind(this);
    }

    private handleSubmit(values: TFormValues) {
        this.props.updateUserMessageTypes(values.messageType);
    }

    private onCloseSuccessDialog() {
        this.props.resetAction();
    }

    private getColumnsConfig(): ListColumns<IColumnNames> {
        return {
            name: {
                label: <Translate msg="account.account_settings.notifications.headers.name" />,
                sortable: false,
                percentWidth: 50,
                render: (listItem) => <Translate msg={listItem.columns.name as string} />,
            },
            notification: {
                label: <Translate msg="account.account_settings.notifications.headers.notification" />,
                sortable: false,
                percentWidth: 50,
                render: this.renderNotificationColumn,
            },
        };
    }

    private isUserMessageActive(id: number): boolean {
        const values = this.formRenderProps.values;
        const index = this.getMessageTypeIndexInFormValues(id);
        // If we can't find the message type, it's not active.
        if (index === -1) {
            return false;
        }
        return values.messageType[index].active;
    }

    private renderNotificationColumn(listItem: ListItem<IColumnNames, number, IListItemData>) {
        const id = listItem.id;

        return (
            <Checkbox
                name={`message-type-${id}`}
                toggleButton={true}
                checked={this.isUserMessageActive(listItem.id)}
                onChange={(e) => this.onChangeMessageType(e, { messageTypeId: id, active: e.target.checked })}
                disabled={listItem.data.disabled}
            />
        );
    }

    private onChangeMessageType(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        messageType: IFormMessageType,
    ) {
        const {
            values, setFieldValue, handleChange,
        } = this.formRenderProps;

        const updatedValues = [...values.messageType];
        const index = this.getMessageTypeIndexInFormValues(messageType.messageTypeId);
        // If we can't find the message type yet, just add it to the updated values.
        index === -1 ? updatedValues.push(messageType) : updatedValues[index].active = messageType.active;
        setFieldValue(
            'messageType',
            updatedValues,
        );
        return handleChange(e);
    }

    private getMessageTypeIndexInFormValues(id: number) {
        const {
            values,
        } = this.formRenderProps;

        return values.messageType
            .map((item) => item.messageTypeId)
            .indexOf(id);
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (prevProps.updateUserMessageTypesAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateUserMessageTypesAsyncInfo.status === AsyncStatus.Success) {
            if (this.formRenderProps) {
                this.formRenderProps.resetForm(this.formRenderProps.values);
            }
        }
        if (prevProps.fetchUserMessageTypesAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchUserMessageTypesAsyncInfo.status === AsyncStatus.Success) {
            if (this.formRenderProps) {
                this.formRenderProps.resetForm(this.getInitialValues());
            }
        }
    }

    private getInitialValues() {
        return {
            messageType: mapUserMessageTypesToFormValues(this.props.userMessageTypes),
        } as TFormValues;
    }

    public render() {
        const {
            userMessageTypes,
            updateUserMessageTypesAsyncInfo,
        } = this.props;

        if (!userMessageTypes) {
            return null;
        }

        const INITIAL_VALUES: TFormValues = this.getInitialValues();

        return (
            <TinyLoader asyncInfoSelector={getFetchConstantsAsyncInfo}>
                <div className={CLASS_NAME}>
                    <Form
                        name={FORM_NAME}
                        initialValues={INITIAL_VALUES}
                        handleSubmit={this.handleSubmit}
                        render={(renderProps: IFormRenderProps<TFormValues>) => {
                            this.formRenderProps = renderProps;

                            return (
                                <>
                                    <List
                                        name={`${CLASS_NAME}__list`}
                                        columns={this.getColumnsConfig()}
                                        items={this.mapMessageTypesToNotifications()}
                                        selectedItemIds={[]}
                                    />
                                    <FormError error={updateUserMessageTypesAsyncInfo.error} />
                                    <div className={`${CLASS_NAME}__actions`}>
                                        <SubmitButton
                                            id="submit-notifications-list"
                                            formName={FORM_NAME}
                                            disabled={updateUserMessageTypesAsyncInfo.status === AsyncStatus.Busy}
                                        >
                                            <Translate
                                                msg={'account.account_settings.notifications.submit'}
                                            />
                                        </SubmitButton>
                                    </div>
                                </>
                            );
                        }}
                    />
                    <Loader
                        show={updateUserMessageTypesAsyncInfo.status === AsyncStatus.Busy}
                    />
                    <SuccessDialog
                        show={updateUserMessageTypesAsyncInfo.status === AsyncStatus.Success}
                        titleTranslationKey="account.account_settings.notifications.success"
                        onCloseDialog={this.onCloseSuccessDialog}
                    />
                </div>
            </TinyLoader>
        );
    }

    public mapMessageTypesToNotifications(): ListItem<IColumnNames, number, IListItemData>[] {
        return this.props.userMessageTypes
            .map((messageType) => ({
                id: messageType.messageTypeId,
                columns: {
                    name: messageType.description,
                    notification: '',
                },
                data: {
                    disabled: messageType.disabled,
                },
            }));
    }
}

function mapUserMessageTypesToFormValues(userMessageTypes: IMessageType[]) {
    return userMessageTypes.map((messageType) => {
        return {
            messageTypeId: messageType.messageTypeId,
            active: messageType.active,
            userMessageTypeId: messageType.userMessageTypeId ? messageType.userMessageTypeId : false,
        } as IFormMessageType;
    });
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            userMessageTypes: getUserMessageTypes(state),
            updateUserMessageTypesAsyncInfo: getUpdateUserMessageTypesAsyncInfo(state),
            fetchUserMessageTypesAsyncInfo: getFetchUserMessageTypesAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => ({
        updateUserMessageTypes: (payload: IUpdateUserMessageTypesPayload) => {
            dispatch(updateUserMessageTypesActions.trigger(payload));
        },
        resetAction: () => {
            dispatch(updateUserMessageTypesActions.reset({}));
        },
    }),
})(Notifications);
