import React from 'react';
import { IFormValues } from './detail';
import EditAmount from '../shared/EditAmount';
import EditContact from '../shared/EditContact';
import EditLocation from '../shared/EditLocation';
import EditSeat from '../shared/EditSeat';
import { ICompanySeat } from '../../../models/admin/company';
import { ICompanyAddress } from '../../../models/admin/companyInfo';
import { IMedicalCenter } from '../../../models/admin/employee';
import { IFluVaccineContact } from '../../../models/interventions/fluVaccines';

export enum FluVaccinesOrdersDetailsOverlayType {
    EditAmount,
    EditContact,
    EditLocation,
    EditSeat,
}

export interface IEditAmountOverlaySaveData {
    newAmount: number;
    newPrice: number;
}

export interface IEditLocationOverlaySaveData {
    vaccinationLocationId: number;
    description: string;
    companyAddress: ICompanyAddress;
    medicalCenter: IMedicalCenter;
}

export default function DetailOverlay(props: {
    overlayType: FluVaccinesOrdersDetailsOverlayType,
    closeOverlay: () => void,
    onSave: (data: object) => void,
    formValues: IFormValues,
}) {
    switch (props.overlayType) {
        case FluVaccinesOrdersDetailsOverlayType.EditContact:
            return (
                <EditContact
                    onClose={props.closeOverlay}
                    onConfirm={(data: IFluVaccineContact) => {
                        props.onSave(data);
                        props.closeOverlay();
                    }}
                    detailFormValues={props.formValues}
                />
            );
        case FluVaccinesOrdersDetailsOverlayType.EditLocation:
            return (
                <EditLocation
                    onClose={props.closeOverlay}
                    onConfirm={(data: IEditLocationOverlaySaveData) => {
                        props.onSave(data);
                        props.closeOverlay();
                    }}
                    detailFormValues={props.formValues}
                />
            );
        case FluVaccinesOrdersDetailsOverlayType.EditSeat:
            return (
                <EditSeat
                    onClose={props.closeOverlay}
                    onConfirm={(data: ICompanySeat) => {
                        props.onSave(data);
                        props.closeOverlay();
                    }}
                    detailFormValues={props.formValues}
                />
            );
        default:
            return (
                <EditAmount
                    onClose={props.closeOverlay}
                    onConfirm={(data: IEditAmountOverlaySaveData) => {
                        props.onSave(data);
                        props.closeOverlay();
                    }}
                    detailFormValues={props.formValues}
                />
            );
    }
}
