import React, { Component } from 'react';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import connect from '../../../../../../utils/libs/redux/connect';
import { TextPropertyInput } from '../../../../../common/input/PropertyInput';
import {
    IEmployeeToAdd, IFetchEmployeesPayload,
} from '../../../../../../models/admin/employee';
import formatNationalRegisterNumber from '../../../../../../utils/formatting/formatNationalRegisterNumber';
import { getEmployeeToAdd, getAddEmployeeAsyncInfo } from '../../../../../../redux/employee/wizard/selectors';
import classNames from 'classnames';
import './validate.scss';
import Translate from '../../../../../common/Translate';
import { formatAddress } from '../../../../../../utils/formatting/formatAddress';
import { ADD_EMPLOYEE_PLACEHOLDER_KEYS } from '../../../../../../config/administration.config';
import { ITraceableApiError } from '../../../../../../models/general/error';
import { AsyncStatus } from '../../../../../../models/general/redux';
import { addEmployeeActions } from '../../../../../../redux/employee/wizard/actions';
import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import {
    getLanguageDescription,
    getNationalityDescription,
    getSexDescription,
} from '../../../../../../config/constants';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import Dialog from '../../../../../common/modals/Dialog';
import Button from '../../../../../common/buttons/Button';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { formatJobStudent } from '../../../../../../utils/formatting/formatJobStudent';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import { getSelectedSeatHasStudentWorkers } from '../../../../../../redux/company/selected/selectors';
import { getJobStudentRequestFailedWarning } from '../../../../../../redux/employee/info/selectors';
import { setJobStudentRequestFailedWarningActions } from '../../../../../../redux/employee/info/actions';

interface IPrivateProps {
    employeeToAdd: IEmployeeToAdd;
    isBusy: boolean;
    error: ITraceableApiError;
    addStatus: AsyncStatus;
    dispatchAddEmployee: (employeeToAdd: IEmployeeToAdd) => void;
    dispatchNavigateToEmployeeOverviewWithSearchParam: (search: string) => void;
    hasStudentWorkers: boolean;
    jobStudentRequestFailedWarning: boolean;
    clearJobStudenRequestFailedWarning: () => void;
}

interface IState {
    isAddEmployeeDialogOpen: boolean;
}

const CLASS_NAME = 'AddEmployeeValidate';
const BASE_KEY = 'administration.employees.add.steps.validate';

class Validate extends Component<IStepperStepRenderProps & IPrivateProps, IState> {

    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isAddEmployeeDialogOpen: false,
        };

        this.onConfirm = this.onConfirm.bind(this);
        this.onCloseAddEmployeeDialog = this.onCloseAddEmployeeDialog.bind(this);
        this.onOpenAddEmployeeDialog = this.onOpenAddEmployeeDialog.bind(this);
        this.onNavigateToEmployeeOverview = this.onNavigateToEmployeeOverview.bind(this);
    }

    public render() {
        const {
            employeeToAdd,
            error,
            hasStudentWorkers,
            isBusy,
            jobStudentRequestFailedWarning,
            renderStepButtons,
        } = this.props;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        <PageHeader
                            title={`${BASE_KEY}.title`}
                            titlePlaceholders={{ name: formatPersonName(employeeToAdd) }}
                        />
                        <div className={classNames('container', CLASS_NAME)}>
                            <div className={`${CLASS_NAME}__input-container`}>
                                <h4>
                                    <Translate
                                        msg="administration.employees.detail.employee_details.subtitles.personal"
                                    />
                                </h4>
                                {employeeToAdd.isForeignEmployee ?
                                    <TextPropertyInput
                                        id="add-employee-validate-birth-date"
                                        name={'birthDate'}
                                        labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate}
                                        readonly={true}
                                        value={employeeToAdd.birthDate}
                                        readOnlyColor="primary"
                                    />
                                :
                                    <TextPropertyInput
                                        id="add-employee-validate-national-register-number"
                                        name={'nationalRegisterNumber'}
                                        labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalRegisterNumber}
                                        readonly={true}
                                        value={formatNationalRegisterNumber(employeeToAdd.nationalRegisterNumber)}
                                        readOnlyColor="primary"
                                    />
                                }
                                <TextPropertyInput
                                    id="add-employee-validate-first-name"
                                    name={'firstName'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.firstName}
                                    readonly={true}
                                    value={employeeToAdd.firstName}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-name"
                                    name={'name'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.name}
                                    readonly={true}
                                    value={employeeToAdd.name}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-sex-id"
                                    name={'sexId'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.sexId}
                                    readonly={true}
                                    value={getSexDescription(employeeToAdd.sexId)}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-nationality-id"
                                    name={'nationalityId'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.nationalityId}
                                    readonly={true}
                                    value={getNationalityDescription(employeeToAdd.nationalityId)}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-address"
                                    name={'address'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.address}
                                    readonly={true}
                                    value={employeeToAdd.address && formatAddress(employeeToAdd.address)}
                                    readOnlyColor="primary"
                                />
                                {hasStudentWorkers && <TextPropertyInput
                                    id="add-employee-validate-job-student"
                                    name={'jobStudent'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.jobStudent}
                                    readonly={true}
                                    value={employeeToAdd.jobStudent &&
                                        formatJobStudent(
                                            {
                                                ...employeeToAdd.jobStudent,
                                                studentWorker: employeeToAdd.studentWorker,
                                            },
                                            translator,
                                        )}
                                    readOnlyColor="primary"
                                />}
                                <h4>
                                    <Translate
                                        msg="administration.employees.detail.employee_details.subtitles.contact"
                                    />
                                </h4>
                                <TextPropertyInput
                                    id="add-employee-validate-language-id"
                                    name={'languageId'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.languageId}
                                    readonly={true}
                                    value={getLanguageDescription(employeeToAdd.languageId)}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-phone"
                                    name={'phone'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.phone}
                                    readonly={true}
                                    value={employeeToAdd.phone}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-mobile"
                                    name={'mobile'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.mobilePhone}
                                    readonly={true}
                                    value={employeeToAdd.mobilePhone}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-email"
                                    name={'email'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.email}
                                    readonly={true}
                                    value={employeeToAdd.email}
                                    readOnlyColor="primary"
                                />
                                <h4>
                                    <Translate
                                        msg="administration.employees.detail.employee_details.subtitles.employer"
                                    />
                                </h4>
                                <TextPropertyInput
                                    id="add-employee-validate-date-in-service"
                                    name={'dateInService'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.dateInService}
                                    readonly={true}
                                    value={formatDateInLongFormat(employeeToAdd.dateInService)}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-employee-id"
                                    name={'employeeNumber'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.employeeNumber}
                                    readonly={true}
                                    value={employeeToAdd.employeeNumber}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-seat"
                                    name={'seat'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.seat}
                                    readonly={true}
                                    value={employeeToAdd.company.name}
                                    readOnlyColor="primary"
                                />
                                <TextPropertyInput
                                    id="add-employee-validate-function"
                                    name={'function'}
                                    labelKey={ADD_EMPLOYEE_PLACEHOLDER_KEYS.function}
                                    readonly={true}
                                    value={employeeToAdd.function.description}
                                    readOnlyColor="primary"
                                />
                            </div>
                            <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                {renderStepButtons({
                                    nextButton: {
                                        translationKey: `${BASE_KEY}.finish_button`,
                                        onClick: this.onConfirm,
                                        disabled: isBusy,
                                    },
                                })}
                            </StickyFooter>
                        </div>
                        <Dialog
                            show={this.state.isAddEmployeeDialogOpen}
                            onCloseIntent={this.onCloseAddEmployeeDialog}
                            type={error || jobStudentRequestFailedWarning ? 'error' : 'success'}
                            header={error ?
                                `${BASE_KEY}.dialog.error.title` : `${BASE_KEY}.dialog.success.title`}
                            showLoader={isBusy}
                            disableClosing={!error}
                        >
                            {
                                error ? (
                                    <Button
                                        id="error-dialog-close"
                                        typeName="secondary"
                                        onClick={this.onCloseAddEmployeeDialog}
                                    >
                                        <Translate msg="common.confirmation_dialog.close" />
                                    </Button>
                                ) :
                                    <>
                                        {jobStudentRequestFailedWarning && (
                                            <div className={`${CLASS_NAME}__job-student-failed`}>
                                                <Translate
                                                    msg={`${BASE_KEY}.dialog.job_student_request_failed_warning`}
                                                />
                                            </div>
                                        )}
                                        <Button
                                            id="success-dialog-confirm"
                                            typeName="secondary"
                                            onClick={this.onNavigateToEmployeeOverview}
                                        >
                                            <Translate msg={`${BASE_KEY}.dialog.success.button`} />
                                        </Button>
                                    </>
                            }
                        </Dialog>
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private onConfirm() {
        this.props.dispatchAddEmployee(this.props.employeeToAdd);
        this.onOpenAddEmployeeDialog();
    }

    private onCloseAddEmployeeDialog() {
        this.setState({ isAddEmployeeDialogOpen: false });
    }

    private onOpenAddEmployeeDialog() {
        this.setState({ isAddEmployeeDialogOpen: true });
    }

    private onNavigateToEmployeeOverview() {
        const {
            clearJobStudenRequestFailedWarning,
            dispatchNavigateToEmployeeOverviewWithSearchParam,
            employeeToAdd,
            jobStudentRequestFailedWarning,
        } = this.props;

        if (jobStudentRequestFailedWarning) {
            clearJobStudenRequestFailedWarning();
        }
        if (employeeToAdd.nationalRegisterNumber) {
            dispatchNavigateToEmployeeOverviewWithSearchParam(employeeToAdd.nationalRegisterNumber);
            return;
        }
        dispatchNavigateToEmployeeOverviewWithSearchParam(`${employeeToAdd.name} ${employeeToAdd.firstName}`);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const addEmployeeAsyncInfo = getAddEmployeeAsyncInfo(state);
        const addStatus = addEmployeeAsyncInfo.status;
        return {
            addStatus,
            employeeToAdd: getEmployeeToAdd(state),
            isBusy: addStatus === AsyncStatus.Busy,
            error: addEmployeeAsyncInfo.error,
            hasStudentWorkers: getSelectedSeatHasStudentWorkers(state),
            jobStudentRequestFailedWarning: getJobStudentRequestFailedWarning(state),
        };
    },
    dispatchProps: (dispatch) => ({
        dispatchAddEmployee: (employeeToAdd: IEmployeeToAdd) => {
            dispatch(addEmployeeActions.trigger(employeeToAdd));
        },
        dispatchNavigateToEmployeeOverviewWithSearchParam: (search: string) => {
            dispatch(navigateTo(
                ROUTE_KEYS.R_EMPLOYEES,
                {},
                { search } as IFetchEmployeesPayload,
            ));
        },
        clearJobStudenRequestFailedWarning: () => {
            dispatch(setJobStudentRequestFailedWarningActions.trigger({ showWarning: false }));
        },
    }),
})(Validate);
