import React, { Component } from 'react';
import Button from '../../../common/buttons/Button';
import Translate from '../../../common/Translate';
import { connect } from '../../..';
import {
    getFluVaccineTariffsAsyncInfo,
} from '../../../../redux/intervention/fluVaccine/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import Form, { IFormRenderProps, TSetFieldValue } from '../../../common/forms/Form';
import Loader from '../../../common/waiting/Loader';
import {
    schema,
} from '../../FluVaccinesOrderWizard/wizard/Delivery/fluVaccineDeliverySchema';
import { IEditLocationOverlaySaveData } from '../../FluVaccinesOrders/detailOverlay';
import DeliveryInput, { IFormValues } from '../../FluVaccinesOrderWizard/wizard/Delivery/DeliveryInput';
import { DELIVERY_OPTIONS } from '../../../../config/intervention.config';
import { SLIDEOUTPANEL_CLASSES } from '../../../common/widget/SlideOutPanel';
import { IFormValues as IDetailFormValues } from '../../FluVaccinesOrders/detail';
import SubmitButton from '../../../common/buttons/SubmitButton';

const CLASS_NAME = 'FluVaccinesOrderEditLocation';
const FORM_NAME = 'flu-vaccine-order-edit-location-form';

interface IPrivateProps {
    travelCostAsyncInfo: IAsyncFieldInfo;
}
interface IEditLocationProps {
    onConfirm: (data: IEditLocationOverlaySaveData) => void;
    onClose: () => void;
    detailFormValues: IDetailFormValues;
}

class EditLocation extends Component<IPrivateProps & IEditLocationProps> {
    private setFormFieldValue: TSetFieldValue<IFormValues>;

    constructor(props) {
        super(props);

        this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const { onClose, travelCostAsyncInfo, detailFormValues } = this.props;

        if (!detailFormValues) {
            return null;
        }

        const companyAddress = detailFormValues.location.companyAddress;
        const medicalCenter = detailFormValues.location.medicalCenter;

        const INITIAL_VALUES: IFormValues = {
            vaccinationLocationId: detailFormValues.location.vaccinationLocationId,
            companyAddress:
                (!!companyAddress && companyAddress.street !== '')
                ? companyAddress
                : null,
            medicalCenter:
                (!!medicalCenter && medicalCenter.name !== '')
                ? medicalCenter
                : null,
        };

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg="interventions.flu_orders_detail.edit_location.title"
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onSubmit}
                    initialValues={INITIAL_VALUES}
                    schema={schema}
                    render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                        this.setFormFieldValue = formRenderProps.setFieldValue;

                        return (
                            <Loader show={travelCostAsyncInfo.status}>
                                <DeliveryInput
                                    formRenderProps={formRenderProps}
                                    onChangeInput={this.onChangeInputHandler}
                                    inDetailPanel={true}
                                    amountVaccines={
                                        typeof detailFormValues.amountVaccinesOrdered !== 'undefined' ?
                                            detailFormValues.amountVaccinesOrdered : 0
                                    }
                                />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="edit-location-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={onClose}
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_location.cancel" />
                                    </Button>
                                    <SubmitButton
                                        formName={FORM_NAME}
                                        id="edit-location-submit-button"
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_location.submit" />
                                    </SubmitButton>
                                </div>
                            </Loader>
                        );
                    }}
                />

            </div>
        );
    }

    private onChangeInputHandler(values: IFormValues) {
        this.setFormFieldValue('vaccinationLocationId', values.vaccinationLocationId);
        this.setFormFieldValue('companyAddress', values.companyAddress);
        this.setFormFieldValue('medicalCenter', values.medicalCenter);
    }

    private onSubmit(values: IFormValues) {
        const deliveryOption = DELIVERY_OPTIONS.find((option) => (
            option.vaccinationLocationId === values.vaccinationLocationId),
        );
        this.props.onConfirm({
            companyAddress: values.companyAddress,
            description: deliveryOption && deliveryOption.detailLabel,
            vaccinationLocationId: values.vaccinationLocationId,
            medicalCenter: values.medicalCenter,
        });
    }
}

export default connect<IPrivateProps, IEditLocationProps>({
    stateProps: (state) => {
        return {
            travelCostAsyncInfo: getFluVaccineTariffsAsyncInfo(state),
        };
    },
})(EditLocation);
