import React, { PureComponent, MouseEvent } from 'react';
import './add-function.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import { fields, schema } from './addFunctionSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import TextInput from '../../../../common/input/TextInput';
import {
    getAddCompanyFunctionAsyncInfo, getAddedCompanyFunction,
} from '../../../../../redux/company/functions/selectors';
import { addCompanyFunction } from '../../../../../redux/company/functions/actions';
import { IAddCompanyFunctionPayload, IAddedCompanyFunction } from '../../../../../models/admin/companyFunctions';
import FormError from '../../../../common/forms/FormError';
import Button from '../../../../common/buttons/Button';

type TValues = IAddCompanyFunctionPayload;

interface IPrivateProps {
    onAddFunction: (values: TValues) => void;
    translator: ITranslator;
    addAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    addedCompanyFunction: IAddedCompanyFunction;
}

interface IAddFunctionProps {
    onClose: () => void;
    onSuccess: (addedCompanyFunction: IAddedCompanyFunction) => void;
}

const CLASS_NAME = 'AddFunction';
const FORM_NAME = 'add-function-form';

class AddFunction extends PureComponent<IPrivateProps & IAddFunctionProps> {
    private resetForm: (values: TValues) => void;

    constructor(props: IPrivateProps & IAddFunctionProps) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            translator, addAsyncInfo, onAddFunction,
        } = this.props;

        const initialValues: TValues = {
            descriptionNL: '',
            descriptionFR: '',
        };

        const descriptionNLPlaceholder = translator('account.add_function.form.fields.description_nl');
        const descriptionFRPlaceholder = translator('account.add_function.form.fields.description_fr');

        return (
            <div className={CLASS_NAME}>
                <header>
                    <h2>
                        <Translate
                            msg={'account.add_function.form.title'}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    className={`${CLASS_NAME}__form`}
                    handleSubmit={onAddFunction}
                    initialValues={initialValues}
                    schema={schema}
                    render={({
                        values, touched, errors, handleChange, resetForm, submitForm,
                    }: IFormRenderProps<TValues>) => {
                        if (!this.resetForm) {
                            this.resetForm = resetForm;
                        }

                        return (
                            <>
                                <Loader
                                    show={
                                        addAsyncInfo.status === AsyncStatus.Busy
                                    }
                                    showImmediatelly={true}
                                />
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-function-description-nl"
                                        name={fields.descriptionNL}
                                        value={values.descriptionNL}
                                        onChange={handleChange}
                                        isInvalid={touched.descriptionNL && !!errors.descriptionNL}
                                    />
                                    <label htmlFor="add-function-description-nl">
                                        <Translate
                                            msg={descriptionNLPlaceholder}
                                        />
                                    </label>
                                    {touched.descriptionNL &&
                                        <FormFieldError
                                            error={errors.descriptionNL}
                                            placeholders={{
                                                fieldName: translator(descriptionNLPlaceholder),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-function-description-fr"
                                        name={fields.descriptionFR}
                                        value={values.descriptionFR}
                                        onChange={handleChange}
                                        isInvalid={touched.descriptionFR && !!errors.descriptionFR}
                                    />
                                    <label htmlFor="add-function-description-fr">
                                        <Translate
                                            msg={descriptionFRPlaceholder}
                                        />
                                    </label>
                                    {touched.descriptionFR &&
                                        <FormFieldError
                                            error={errors.descriptionFR}
                                            placeholders={{
                                                fieldName: translator(descriptionFRPlaceholder),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FormError error={addAsyncInfo.error} />
                                <div className={`${CLASS_NAME}__actions`}>
                                    <Button
                                        id="add-function-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate msg="account.add_function.form.cancel" />
                                    </Button>
                                    <Button
                                        id="add-function-submit-button"
                                        typeName="secondary"
                                        onClick={submitForm}
                                    >
                                        <Translate msg="account.add_function.form.submit" />
                                    </Button>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.addAsyncInfo.status === AsyncStatus.Success &&
            prevProps.addAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.props.onSuccess(this.props.addedCompanyFunction);
            this.resetForm({ descriptionFR: '', descriptionNL: '' });
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, addAsyncInfo } = this.props;
        clearError(addAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IAddFunctionProps>({
    stateProps: (state) => {
        const addAsyncInfo = getAddCompanyFunctionAsyncInfo(state);
        return {
            translator: getTranslatorDeprecated(state),
            addAsyncInfo,
            addedCompanyFunction: getAddedCompanyFunction(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            onAddFunction: (values) => {
                dispatch(addCompanyFunction({
                    ...values,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(AddFunction);
