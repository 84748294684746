export default function buildMultiPartForm(formBody: object) {
    const formData = new FormData();

    Object.keys(formBody).forEach((key) => {
        const value = formBody[key];

        if (value instanceof File) {
            formData.append(key, value);
        } else {
            formData.append(key, JSON.stringify(value));
        }
    });

    return formData;
}
