import React from 'react';

import Button from '../../../../common/buttons/Button';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';

import { TPlanInterventionButtonProps } from './PlanInterventionButton.type';

const PlanInterventionButton = ({ planIntervention }: TPlanInterventionButtonProps) => {
    return (
        <Button
            id="agenda-dashboard-plan-intervention"
            typeName="secondary"
            onClick={planIntervention}
        >
            <Icon typeName="calendar" />
            <Translate msg="home.dashboard.agenda.plan_intervention" />
        </Button>
    );
};

export { PlanInterventionButton };
