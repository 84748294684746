import React, { ReactNode } from 'react';
import './validate-info.scss';

interface IPublicProps {
    children: ReactNode;
}

/**
 * Bordered block to use for example around the labelInfo's on the last Validate/verification step of a wizard.
 */
export default function ValidateInfo(props: IPublicProps) {
    return (
        <div className="ValidateInfo">
            {props.children}
        </div>
    );
}
