import React from 'react';
import classNames from 'classnames';
import { schema } from '../../../shared/ContactInput/schema';
import { COMPANY_CONTACT_TYPE_ID } from '../../../../../config/company/companyInfo.config';
import { ICompanyContact } from '../../../../../models/admin/companyInfo';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { IState } from '../../../../../redux';
import { getCompanyContactsAsyncInfo, getCompanyContacts } from '../../../../../redux/company/info/selectors';
import { getFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/selectors';
import { updateFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/actions';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import ContactInput, { IFormValues } from '../../../shared/ContactInput/ContactInput';

const CLASS_NAME = 'OrderFluVaccine__Contact';
const FORM_NAME = 'flu-vaccine-contact-form';

const ContactWizardFormStep = WizardFormStep<IFormValues>();

export default function Contact(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <ContactWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={classNames(CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT)}
            header={{
                titleTranslationKey: 'interventions.flu_orders_new.steps.contact.title',
            }}
            showLoaderSelector={(state) => getCompanyContactsAsyncInfo(state).status}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getFluVaccinesOrderWizardEntity(state);

                    return {
                        contact: wizardEntity.contact || getFluVaccineContactAsInitialContact(state),
                    };
                },
                renderStepFormContent: (renderProps) => (
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <ContactInput {...renderProps} />
                    </div>
                ),
                onChangeInputActionCreator: ({ values }) => updateFluVaccinesOrderWizardEntity({
                    contact: values.contact,
                }),
            }}
        />
    );
}

function getFluVaccineContactAsInitialContact(state: IState): ICompanyContact {
    return getCompanyContacts(state)
        .find((contact) => contact.typeId === COMPANY_CONTACT_TYPE_ID.FLU_VACCINES) || null;
}
