import React from 'react';
import { connect } from '../../index';
import PageHeader from '../../appShell/PageHeader';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Translate from '../../common/Translate';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Tile from '../../common/widget/Tile/index';
import { IAdministrationItem } from '../../../models/general/navigation';
import {
    ADMINISTRATION_ITEMS,
    ADMINISTRATION_MANAGEMENT_ITEMS,
} from '../../../config/navigation/administration.config';
import { navigateToAddEmployee } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import { ILocationAction } from '../../../models/general/redux';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const getAllowedAdminItems = makeAllowedNavigationItemsMemoizedSelector<IAdministrationItem>(
    ADMINISTRATION_ITEMS,
);
const getAllowedAdminMgmtItems = makeAllowedNavigationItemsMemoizedSelector<IAdministrationItem>(
    ADMINISTRATION_MANAGEMENT_ITEMS,
);

interface IPrivateProps {
    toAddEmployee: () => void;
    allowedAdministrationItems: IAdministrationItem[];
    allowedAdministrationManagementItems: IAdministrationItem[];
}

const TARGET_ROUTE_KEY = {
    addEmployee: ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD,
};

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        allowedAdministrationItems: getAllowedAdminItems(state),
        allowedAdministrationManagementItems: getAllowedAdminMgmtItems(state),
    }),
    dispatchProps: (dispatch) => ({
        toAddEmployee: () => {
            dispatch(navigateToAddEmployee());
        },
    }),
})(AdministrationDashboard);

function AdministrationDashboard({
    toAddEmployee,
    allowedAdministrationItems,
    allowedAdministrationManagementItems,
}: IPrivateProps) {
    return (
        <div className="tile-dashboard">
            <PageHeader
                title="administration.overview.title"
                breadcrumbs={true}
            />
            <div className="container">
                <ShowIfAllowed routeKey={TARGET_ROUTE_KEY.addEmployee}>
                    <AddEmployeeButton toAddEmployee={toAddEmployee} />
                </ShowIfAllowed>
                <div className="sections-wrapper">
                    { allowedAdministrationItems.length > 0 && (

                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems
                                    allowedAdministrationItems={allowedAdministrationItems}
                                />
                            </div>
                        </div>
                    )}
                    { allowedAdministrationManagementItems.length > 0 && (
                        <div className="section-sub">
                            <div>
                                <div className="section-inner">
                                    <NavigationManagementItems
                                        allowedAdministrationManagementItems={allowedAdministrationManagementItems}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function AddEmployeeButton({ toAddEmployee }: Partial<IPrivateProps>) {
    return (
        <div className="top-button-wrapper">
            <Button
                id="administration-add-employee-button"
                typeName="secondary"
                onClick={toAddEmployee}
            >
                <Icon typeName="plus-circle" />
                <span><Translate msg="administration.overview.add_employee_button" /></span>
            </Button>
        </div>
    );
}

function NavigationItems({ allowedAdministrationItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedAdministrationItems
                    .map((administrationItem) => (
                        <div className="tile-wrapper" key={administrationItem.id}>
                            <LinkToRoute
                                id={administrationItem.id}
                                to={administrationItem.linkTo}
                            >
                                <Tile
                                    title={administrationItem.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: administrationItem.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.ADMINISTRATION,
                                    }}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}

function NavigationManagementItems({ allowedAdministrationManagementItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedAdministrationManagementItems
                    .map((administrationItem) => {
                        const to = typeof administrationItem.linkToPayload === 'object' ? {
                            type: administrationItem.linkTo,
                            payload: administrationItem.linkToPayload,
                        } as ILocationAction<{}> : administrationItem.linkTo;
                        return (
                            <div className="tile-wrapper" key={administrationItem.id}>
                                <LinkToRoute
                                    id={administrationItem.id}
                                    key={administrationItem.id}
                                    to={to}
                                >
                                    <Tile
                                        title={administrationItem.titleTranslationKey}
                                        lazyLoadSvg={{
                                            id: administrationItem.lazyLoadSvgId,
                                            group: SVG_GROUP_NAME.ADMINISTRATION,
                                        }}
                                        typeName="green"
                                        arrow={true}
                                    />
                                </LinkToRoute>
                            </div>
                        );
                    })
            }
        </>
    );
}
