import { boolean, object } from 'yup';
import nationalRegisterNumber from '../../../../../../utils/libs/yup/nationalRegisterNumber';

export const fields = {
    nationalRegisterNumber: 'nationalRegisterNumber',
    isForeignEmployee: 'isForeignEmployee',
};

export const schema = object({
    [fields.nationalRegisterNumber]: nationalRegisterNumber().when(
        'isForeignEmployee',
        {
            is: false,
            then: nationalRegisterNumber().required(),
        }),
    [fields.isForeignEmployee]: boolean(),
});
