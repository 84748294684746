import React from 'react';
import { reportError } from '../../../../utils/logging/errorReporter';
import UnexpectedError from '../../../error/UnexpectedError';
import WithMenuTemplate from '../../../appShell/templates/WithMenuTemplate';
import connect from '../../../../utils/libs/redux/connect';
import ROUTE_KEYS from '../../../../routeKeys';
import { getRouteKey } from '../../../../redux/location/selectors';
import { isOnboardingWizardRoute } from '../../../../redux/employee/employees/selectors';
import MainHeaderAndFooterTemplate from '../../../appShell/templates/MainHeaderAndFooterTemplate';

interface IComponentProps {
    children: JSX.Element[] | JSX.Element;
}

interface IComponentState {
    hasError: boolean;
}

interface IPrivateProps {
    currentRouteKey: ROUTE_KEYS;
    isOnboardingWizardRoute: boolean;
}

class ErrorBoundary extends React.Component<IComponentProps & IPrivateProps, IComponentState> {
    constructor(props: IComponentProps & IPrivateProps) {
        super(props);
        this.state = {
            hasError: false,
        };

        this.disableErrorBoundary = this.disableErrorBoundary.bind(this);
    }

    public componentDidCatch(error, info) {
        this.setState({
            hasError: true,
        });

        reportError(error, {
            extra: {
                info,
            },
        });
    }

    public render() {
        if (this.state.hasError) {
            if (this.props.isOnboardingWizardRoute) {
                return (
                    <MainHeaderAndFooterTemplate
                        showAccountMenu={false}
                        showCompanySelectDropdown={false}
                    >
                        <UnexpectedError />
                    </MainHeaderAndFooterTemplate>
                );
            }
            return (
                <WithMenuTemplate>
                    <UnexpectedError />
                </WithMenuTemplate>
            );
        }
        return this.props.children;
    }

    public componentDidUpdate(prevProps: IPrivateProps & IComponentProps) {
        if (this.state.hasError && this.props.currentRouteKey !== prevProps.currentRouteKey) {
            this.disableErrorBoundary();
        }
    }

    private disableErrorBoundary() {
        this.setState({
            hasError: false,
        });
    }
}

export default connect<IPrivateProps, IComponentProps>({
    stateProps: (state) => ({
        currentRouteKey: getRouteKey(state),
        isOnboardingWizardRoute: isOnboardingWizardRoute(state),
    }),
})(ErrorBoundary);
