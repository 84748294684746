import React from 'react';
import {
    ICalendarEvent, CalendarEventType, CalendarEventDataType,
    TPlannedMedicalExaminationCalendarEvent,
    TExecutedMedicalExaminationCalendarEvent,
    TExecutedCompanyVisitCalendarEvent,
    TPlannedCompanyVisitCalendarEvent,
} from '../../../../../models/ui/calendar';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../config/calendar.config';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { ITranslator } from '../../../../../models/general/i18n';
import { IInterventionAppointment } from '../../../../../models/interventions/appointment';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/planned';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

interface IContextProps {
    translator: ITranslator;
}

interface IEventTitleProps {
    event: ICalendarEvent;
    titleType: 'calendar-event' | 'list-event' | 'appointment-detail';
}

function EventTitleComp(props: IEventTitleProps & IContextProps) {
    return (
        <>
            {getEventTitle(props)}
        </>
    );
}

export default function EventTitle(props: IEventTitleProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <EventTitleComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

export function getEventTitle(props: IEventTitleProps & IContextProps): string {
    if (props.event.type === CalendarEventType.CompanyVisit) {
        const translationKey = props.titleType === 'list-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.companyVisit.listEventTranslationKey
            : props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.companyVisit.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.companyVisit.appointmentDetailTitleTranslationKey;

        const data = (props.event as TPlannedCompanyVisitCalendarEvent).data;
        return props.translator({
            msg: translationKey,
            placeholders: {
                name: formatName(props.event),
                seat: data.branch.name,
            },
        });
    }
    if (props.event.type === CalendarEventType.Intervention) {
        const translationKey = props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.intervention.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.intervention.appointmentDetailTitleTranslationKey;

        const title = (props.event.data as IInterventionAppointment).interventionReason.description;

        return props.translator({
            msg: translationKey,
            placeholders: { title },
        });
    }
    if (
        props.event.type === CalendarEventType.MedicalExamination ||
        props.event.type === CalendarEventType.MedicalExaminationInCompany
    ) {
        // Display employee name in calendar view
        if (props.titleType === 'calendar-event') {
            return props.translator({
                msg: CALENDAR_EVENT_TYPES_CONFIG.medicalExamination.calendarEventTranslationKey,
                placeholders: {
                    name: formatName(props.event),
                },
            });
        }

        // Display examination reason in the "afspraak" column in calendar list view
        if (props.titleType === 'list-event') {
            return (props.event.data as IPlannedMedicalExamination).examinationReason.description;
        }

        // Display appointment type in appointment detail
        return props.translator({
            msg: CALENDAR_EVENT_TYPES_CONFIG.medicalExamination.appointmentDetailTitleTranslationKey,
        });
    }
    if (props.event.type === CalendarEventType.Course) {
        const translationKey = props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.course.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.course.appointmentDetailTitleTranslationKey;
        return props.translator({
            msg: translationKey,
            placeholders: {
                title: props.event.title,
            },
        });
    }
    if (props.event.type === CalendarEventType.FreeTimeslot) {
        return props.translator({
            msg: CALENDAR_EVENT_TYPES_CONFIG.freeTimeslot.calendarEventTranslationKey,
        });
    }
    return null;
}

function formatName(event: ICalendarEvent): string {
    if (Array.isArray(event.data) && event.dataType === CalendarEventDataType.PlannedMedicalExamination) {
        return (event.data as IPlannedMedicalExamination[])
            .map((data) => formatPersonName({
                firstName: data.employee.firstName,
                name: data.employee.lastName,
            }))
            .join(', ');
    }
    if (event.dataType === CalendarEventDataType.PlannedMedicalExamination) {
        const data = (event as TPlannedMedicalExaminationCalendarEvent).data;
        return formatPersonName({
            firstName: data.employee.firstName,
            name: data.employee.lastName,
        });
    }
    if (event.dataType === CalendarEventDataType.ExecutedMedicalExamination) {
        const data = (event as TExecutedMedicalExaminationCalendarEvent).data;
        return formatPersonName({
            firstName: data.employee.firstName,
            name: data.employee.lastName,
        });
    }
    if (event.dataType === CalendarEventDataType.ExecutedCompanyVisit) {
        const data = (event as TExecutedCompanyVisitCalendarEvent).data;
        return formatPersonName({
            firstName: data.visitor.firstName,
            name: data.visitor.lastName,
        });
    }
    if (event.dataType === CalendarEventDataType.PlannedCompanyVisit) {
        const data = (event as TPlannedCompanyVisitCalendarEvent).data;
        return formatPersonName({
            firstName: data.visitor.firstName,
            name: data.visitor.lastName,
        });
    }
    return event.type;
}
