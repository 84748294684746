import { ICompany } from '../admin/company';
import { IPerson, IEmployee } from '../admin/employee';
import { IAccessLevel } from '../auth/authorisation';

export interface IUserAccountBase extends IPerson {
    admin: AdminType;
    email: string;
    languageId: number;
    username: string;
    webUserId: number;
}

export interface IUserAccount extends IUserAccountBase {
    accessLowerRanks: boolean;
    active: boolean;
    company: Pick<ICompany, 'companyCode' | 'id'>;
    language: string;
    superUser: boolean;
}

export type TUserAccountSeat = Pick<ICompany, 'companyCode' | 'name' | 'id'>;

export type TUserAccountAccessLevel = Pick<
    IAccessLevel, 'company' | 'invoicing' | 'planning' | 'employee' | 'interventions'
>;

export interface IUserAccountDetail extends IUserAccountBase {
    accessLevel: TUserAccountAccessLevel;
    mobilePhoneNumber: string;
    nationalRegisterNumber: string;
    phoneNumber: string;
    seats?: TUserAccountSeat[];
    sexId: number;
    titleId: number;
    fax: string;
}

export interface IUpdateUserAccountInfoPayload extends Pick<
    IUserAccountDetail,
    'titleId' | 'name' | 'firstName' | 'languageId' |
    'admin' | 'phoneNumber' | 'mobilePhoneNumber' |
    'sexId'
> {
    username?: string;
    webUserId?: number;
    currentEmail: string;
    newEmail: string;
}

export interface IUpdateMyUserAccountInfoPayload extends Pick<
    IUpdateUserAccountInfoPayload,
    Exclude<
        keyof IUpdateUserAccountInfoPayload,
        'webUserId' | 'username'
    >
> {}

export interface IUpdateUserAccountConfigPayload extends Pick<
    IUserAccountDetail,
    'accessLevel' | 'seats' | 'admin'
> {
    username: string;
    seatsToAdd?: TUserAccountSeat[];
    seatsToRemove?: TUserAccountSeat[];
}

export interface IUpdateUserAccountPayload extends IUpdateUserAccountInfoPayload, IUpdateUserAccountConfigPayload {
    username: string;
}

interface IAddUserAccountBasePayload extends Pick<
    IUserAccountDetail,
    'titleId' | 'name' | 'firstName' | 'languageId' |
    'email' | 'admin' | 'phoneNumber' |
    'mobilePhoneNumber' | 'webUserId' | 'sexId'
> {
    selectedEmployee: IEmployee;
}

export interface IAddUserAccountPayload extends IAddUserAccountBasePayload, Pick<
    IUserAccountDetail, 'accessLevel' | 'seats'
> {}

export interface IAddUserAccountApiPayload extends IAddUserAccountBasePayload {
    customerIds: number[]; // seats
    userGroupId: number;
}

export interface IRemoveUserAccountPayload extends Pick<IUserAccountDetail, 'email'> {}

export enum AddUserAccountWizardStep {
    AccessLevel = 'access-level',
    PersonalData = 'personal-data',
    SelectEmployee = 'select-employee',
    Seats = 'seats',
}

// PHP uses a number
export enum AdminType {
    Administrator = 0,
    MainAdministrator = 1,
    None = 2,
}

// BFF uses strings
export enum AdministratorType {
    Administrator = 'Administrator',
    MainAdministrator = 'MainAdministrator',
    GeneralUser = 'GeneralUser',
}
