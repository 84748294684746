/**
 * The accept attribute for an input of type 'file', specifies the types of files
 * that the server accepts (that can be submitted through a file upload).
 *
 * Next to the values below, also following values are possible:
 * - a file_extension
 * - a media_type
 *
 * p.s. To specify more than one value, separate the values with a comma.
 */
export const ACCEPT_FILE_TYPES = {
    ALL_AUDIO: 'audio/*',
    ALL_IMAGES: 'image/*',
    ALL_VIDEO: 'video/*',
};

export const MEDIA_TYPE = {
    PDF: 'application/pdf',
};

export const UPLOAD_OR_SEND_DOCUMENT_DEFAULT_MIME_TYPES =
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf';

const FILE_TYPE_REGEXP = {
    ALL_IMAGES: /^image\//,
};

/**
 * Returns true if the type (e.g. "image/png") of the input file is an image.
 */
export function validateFileIsAnImage(file: File): boolean {
    if (file) {
        if (file.type.search(FILE_TYPE_REGEXP.ALL_IMAGES) === 0) { // startsWith
            return true;
        }
    }

    return false;
}
