import isSet from '@snipsonian/core/es/is/isSet';
import { TCompanyStatisticalCode } from '../../../config/company/companyInfo.config';
import initOptionalFields from '@snipsonian/analytics/es/tracker/providers/initOptionalFields';

/**
 * 'hit' scope = value is applied to the single hit for which it has been set
 */

const VALUE_NOT_APPLICABLE = 'N/A';

/********** name in GA: 'customer' **********/
let companyStatisticalCode: string = VALUE_NOT_APPLICABLE;

export function setCompanyStatisticalCode(newValue: TCompanyStatisticalCode) {
    companyStatisticalCode = isSet(newValue)
        ? newValue
        : VALUE_NOT_APPLICABLE;
}

/********** name in GA: 'client-id' **********/
let companyCode: string = VALUE_NOT_APPLICABLE;

/**
 * We only log the first part of the company code.
 * For example for companyCode "12345.1" we will only send "12345".
 */
export function setCompanyCode(newValue: string) {
    companyCode = isSet(newValue)
        ? extractBasePartOfCompanyCode(newValue)
        : VALUE_NOT_APPLICABLE;
}

function extractBasePartOfCompanyCode(companyCode: string) {
    return companyCode.split('.')[0];
}

/********** name in GA: 'courseId' **********/

export const CUSTOM_DIMENSION_COURSE_ID = 'courseId';

/**
 * Will only return those dimensions that have a value, others will not be included.
 * p.s. N/A is also a proper value
 */
export function getHitScopedCustomDimensions() {
    return {
        ...initOptionalFields({
            'client-id': companyCode,
            customer: companyStatisticalCode,
        }),
    };
}
