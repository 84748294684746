import React from 'react';
import { Droppable, DroppableId, DroppableStateSnapshot, TypeId } from 'react-beautiful-dnd';

interface IPublicProps {
    droppableId: DroppableId;
    render: (renderProps: IDroppableRenderProps) => React.ReactNode;
    className?: string;
    type?: TypeId;
    disableMove?: boolean;
    isDropDisabled?: boolean;
}

export interface IDroppableRenderProps {
    dropState: DroppableStateSnapshot;
}

export default function DroppableWrapper({
    render, droppableId, className, type, disableMove, isDropDisabled,
}: IPublicProps) {
    return (
        <Droppable isDropDisabled={isDropDisabled} droppableId={droppableId} type={type}>
            {(provided, snapshot) => {
                const disableMovePlaceholder = disableMove ? (
                    <div style={{ height: 0 }}>
                        {provided.placeholder}
                    </div>
                ) : provided.placeholder;

                return (
                    <div
                        className={className}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {render({ dropState: snapshot })}
                        {disableMovePlaceholder}
                    </div> as React.ReactElement<HTMLElement>
                );
            }}
        </Droppable>
    );
}
