import { path, pathOr } from 'ramda';

import { CalendarEventDataType, CalendarEventType, ICalendarEvent } from '../../../../models/ui/calendar';
import { DayParts } from '../../../../models/general/daypart';
import { getDate } from '../../../../utils/core/date/getSpecificDate';
import {
    ICompanyVisit,
    IPlannedCompanyVisit,
} from '../../../../models/interventions/company-visits';
import { IExecutedMedicalExaminationAppointment } from '../../../../models/interventions/medicalExaminations/executed';
import { IInterventionAppointment } from '../../../../models/interventions/appointment';
import { ILocationTypeCodes } from '../../../../models/general/location';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../../../config/planning.config';
import { IPlannedCourseAppointment } from '../../../../models/documentCenter/courses';
import { IPlannedMedicalExamination } from '../../../../models/interventions/medicalExaminations/planned';

interface IAppointmentsPerType {
    date: string;
    'executedMedicalExaminations'?: IExecutedMedicalExaminationAppointment[];
    'plannedMedicalExaminations'?: IPlannedMedicalExamination[];
    'plannedCompanyVisits'?: IPlannedCompanyVisit[];
    'executedCompanyVisits'?: ICompanyVisit[];
    'executed-intervention'?: IInterventionAppointment[];
    'plannedInterventions'?: IInterventionAppointment[];
    'plannedCourseParts'?: IPlannedCourseAppointment[];
    'executedCourseParts'?: IPlannedCourseAppointment[];
}

export const mapAppointmentsResponse = (appointmentsPerDate): ICalendarEvent[] => {
    return Object.keys(appointmentsPerDate).reduce(
        (accumulator, date) => {
            const appointmentsPerType = appointmentsPerDate[date] as IAppointmentsPerType;
            const executedCompanyVisits = (
                validateIfArray(appointmentsPerType['executedCompanyVisits'])
                    ? appointmentsPerType['executedCompanyVisits']
                    : []
            ).map(mapExecutedCompanyVisitToCalendarEvent);
            const plannedCompanyVisits = (
                validateIfArray(appointmentsPerType['plannedCompanyVisits'])
                    ? appointmentsPerType['plannedCompanyVisits']
                    : []
            ).map(mapPlannedCompanyVisitToCalendarEvent);
            const plannedExaminations = (
                validateIfArray(appointmentsPerType['plannedMedicalExaminations'])
                    ? appointmentsPerType['plannedMedicalExaminations']
                    : []
            )
                .map(mapPlannedExaminationToCalendarEvent);
            const executedExaminations = (
                validateIfArray(appointmentsPerType['executedMedicalExaminations'])
                    ? appointmentsPerType['executedMedicalExaminations']
                    : []
            )
            .map(mapExecutedExaminationToCalendarEvent);
            const plannedInterventions = (
                validateIfArray(appointmentsPerType['plannedInterventions'])
                    ? appointmentsPerType['plannedInterventions']
                    : []
            ).map(mapPlannedIntervention);
            const executedInterventions = (
                validateIfArray(appointmentsPerType['executedInterventions'])
                    ? appointmentsPerType['executedInterventions']
                    : []
            ).map(mapExecutedInterventionToCalendarEvent);
            const plannedCourses = (
                validateIfArray(appointmentsPerType['plannedCourseParts'])
                    ? appointmentsPerType['plannedCourseParts']
                    : []
            ).map(mapCourseToCalenderEvent);
            const executedCourses = (
                validateIfArray(appointmentsPerType['executedCourseParts'])
                    ? appointmentsPerType['executedCourseParts']
                    : []
            ).map(mapCourseToCalenderEvent);
            return accumulator.concat([
                ...executedCompanyVisits,
                ...plannedCompanyVisits,
                ...plannedExaminations,
                ...executedExaminations,
                ...plannedInterventions,
                ...executedInterventions,
                ...plannedCourses,
                ...executedCourses,
            ]);
        },
        [],
    );
};

export const mapExecutedCompanyVisitToCalendarEvent =
    (companyVisit: ICompanyVisit): ICalendarEvent<ICompanyVisit> => {
    const time = getDate(companyVisit.start);

    const isPeriod = companyVisit.dayPart !== undefined;

    return {
        id: `${CalendarEventType.CompanyVisit}_${companyVisit.activityId}`,
        start: !isPeriod ? getDate(companyVisit.start) : time,
        end: !isPeriod ? getDate(companyVisit.end) : time,
        allDay: isPeriod,
        ...!!isPeriod && {
            afterNoon: companyVisit.dayPart === DayParts.PM,
        },
        type: CalendarEventType.CompanyVisit,
        data: companyVisit,
        dataType: CalendarEventDataType.ExecutedCompanyVisit,
        isConcatenated: false,
    };
};

export const mapPlannedCompanyVisitToCalendarEvent =
    (companyVisit: IPlannedCompanyVisit): ICalendarEvent<IPlannedCompanyVisit> => {
    const time = getDate(companyVisit.start);

    const isPeriod = companyVisit.dayPart !== undefined;

    return {
        id: `${CalendarEventType.CompanyVisit}_${companyVisit.activityId}`,
        start: !isPeriod ? getDate(companyVisit.start) : time,
        end: !isPeriod ? getDate(companyVisit.end) : time,
        allDay: isPeriod,
        ...!!isPeriod && {
            afterNoon: companyVisit.dayPart === DayParts.PM,
        },
        type: CalendarEventType.CompanyVisit,
        data: companyVisit,
        dataType: CalendarEventDataType.PlannedCompanyVisit,
        isConcatenated: false,
    };
};

export const mapCourseToCalenderEvent =
    (plannedCourseAppointment: IPlannedCourseAppointment): ICalendarEvent<IPlannedCourseAppointment> => {
    const start = getDate(plannedCourseAppointment.start);
    const end = getDate(plannedCourseAppointment.end);

    return {
        id: `${CalendarEventType.Course}_${plannedCourseAppointment.id}`,
        start,
        end,
        allDay: false,
        type: CalendarEventType.Course,
        data: plannedCourseAppointment,
        dataType: CalendarEventDataType.Course,
        isConcatenated: false,
        title: plannedCourseAppointment.organizedCourse.course.description,
    };
};

export const mapPlannedExaminationToCalendarEvent = (
    examination: IPlannedMedicalExamination,
): ICalendarEvent<IPlannedMedicalExamination> => {
    const start = getDate(examination.start);
    const end = getDate(examination.end);

    const locationTypeCode = path(['location', 'typeCode'], examination);
    const locationCode = pathOr(
        (locationTypeCode && locationTypeCode === ILocationTypeCodes.CUSTOMER)
        ? IN_COMPANY_MEDICAL_CENTER_CODE
        : null,
        ['location', 'code'],
        examination,
    );

    const isIncompany =
        locationTypeCode === ILocationTypeCodes.CUSTOMER
        || locationCode === IN_COMPANY_MEDICAL_CENTER_CODE;

    return {
        id: `${CalendarEventType.MedicalExamination}_${examination.timeSlotId}`,
        start,
        end,
        allDay: false,
        type: isIncompany
            ? CalendarEventType.MedicalExaminationInCompany
            : CalendarEventType.MedicalExamination,
        data: examination,
        dataType: CalendarEventDataType.PlannedMedicalExamination,
        isConcatenated: false,
    };
};

export const mapExecutedExaminationToCalendarEvent =
    (examination: IExecutedMedicalExaminationAppointment): ICalendarEvent<IExecutedMedicalExaminationAppointment> => {

    const locationTypeCode = path(['location', 'typeCode'], examination);
    const locationCode = pathOr(
        (locationTypeCode && locationTypeCode === ILocationTypeCodes.CUSTOMER)
        ? IN_COMPANY_MEDICAL_CENTER_CODE
        : null,
        ['location', 'code'],
        examination,
    );

    const isIncompany =
        locationTypeCode === ILocationTypeCodes.CUSTOMER
        || locationCode === IN_COMPANY_MEDICAL_CENTER_CODE;

    const start = getDate(examination.start);
    const end = getDate(examination.end);
    const isAllDay = start === end;

    return {
        id: `${CalendarEventType.MedicalExamination}_${examination.id}`,
        start,
        end,
        allDay: isAllDay,
        type: isIncompany
            ? CalendarEventType.MedicalExaminationInCompany
            : CalendarEventType.MedicalExamination,
        data: examination,
        dataType: CalendarEventDataType.ExecutedMedicalExamination,
        isConcatenated: false,
    };
};

export const mapPlannedIntervention = (
    intervention: IInterventionAppointment,
): ICalendarEvent<IInterventionAppointment> => ({
        id: `${CalendarEventType.Intervention}_${intervention.activityId}`,
        start: getDate(intervention.start),
        end: getDate(intervention.end),
        allDay: false,
        type: CalendarEventType.Intervention,
        data: intervention,
        dataType: CalendarEventDataType.PlannedIntervention,
        isConcatenated: false,
});

export const mapExecutedInterventionToCalendarEvent =
    (intervention: IInterventionAppointment): ICalendarEvent<IInterventionAppointment> => ({
        id: `${CalendarEventType.Intervention}_${intervention.activityId}`,
        start: getDate(intervention.start),
        end: getDate(intervention.end),
        allDay: false,
        type: CalendarEventType.Intervention,
        data: intervention,
        dataType: CalendarEventDataType.ExecutedIntervention,
        isConcatenated: false,
});

export function validateIfArray(appointments: object[]) {
    if (!Array.isArray(appointments)) {
        if (appointments) {
            console.warn(
                `Appointments were returned in an incorrect format, expected an array, got a ${typeof appointments}`,
                appointments,
            );
        }
        return false;
    }
    return true;
}
