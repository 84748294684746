import { ISortedColumn, SortOrder } from '../../../../models/general/list';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';

export interface IPlannedCompanyVisitsColumnNames {
    visitDate: string;
    visitDateSort: string;
    visitDateTime: string;
    visitCode: string;
    company: string;
    visitor: string;
    description: string;
}

export interface IPlannedCompanyVisitsFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
}

export const INITIAL_SORT: ISortedColumn<IPlannedCompanyVisitsColumnNames> = {
    name: 'visitDate',
    sortOrder: SortOrder.Ascending,
};
