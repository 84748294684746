import dayjs from 'dayjs';
import { now } from './getSpecificDate';

export function isToday(date: Date | string): boolean {
    if (!date) {
        return false;
    }

    return areDatesOnSameDay(parseDate(date), now());
}

export function isYesterDay(date: Date | string): boolean {
    if (!date) {
        return false;
    }

    const yesterday = dayjs().add(-1, 'day').toDate();

    return areDatesOnSameDay(parseDate(date), yesterday);
}

function parseDate(date: Date | string): Date {
    return typeof date === 'string'
        ? dayjs(date).toDate()
        : new Date(date.getTime());
}

function areDatesOnSameDay(date: Date, otherDate: Date) {
    return resetDateHours(date) === resetDateHours(otherDate);
}

function resetDateHours(date: Date) {
    return date.setHours(0, 0, 0, 0);
}
