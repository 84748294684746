import { createEpic } from '../index';
import ROUTE_KEYS from '../../routeKeys';
import { IFaqSearchPayload } from '../../models/contact/faq';
import {
    fetchFaqOverviewSucceeded, fetchFaqOverviewFailed,
    sendFeedbackActions, fetchCaseManagerActions, fetchMensuraContactsActions,
} from './actions';
import { isFaqOverviewAvailable, isCaseManagerAvailable, getMensuraContactsForSeat } from './selectors';
import { getRouteKey } from '../location/selectors';
import { ISendCompanyFeedbackPayload } from '../../models/contact/feedback';
import { getSelectedSeatCompanyCode, isAllSeatsSelected } from '../company/selected/selectors';
import { SEND_COMPANY_FEEDBACK, FETCH_MENSURA_CONTACTS, FETCH_CASE_MANAGER } from './types';
import { ICompanyCodePayload } from '../../models/admin/company';
import { mapFeedbackTypeToSubmittedFormActionType } from '../../utils/logging/analytics/mapToSubmittedFormActionType';
import { hasReadAccessForAccessLevel } from '../auth/selectors';
import { IBaseContactPerson, IMensuraContactPerson } from '../../models/admin/companyInfo';
import { IMensuraContactsResponse } from '../../api/contact/mensuraContacts.api';
import { AnyAction } from 'redux';
import { REDUCER_KEYS } from '../../config/redux.config';

// fetchFaqOverviewEpic
createEpic<IFaqSearchPayload>({
    onActionType: [ROUTE_KEYS.R_FAQ_OVERVIEW, ROUTE_KEYS.R_FAQ_DETAIL],
    refreshDataIf: ({ getState, action }) => {
        if (!isFaqOverviewAvailable(getState())) {
            /** either the first visit of the faq overview
             * OR either a direct link to the detail page (so first fetch the list if faq overview not available)
             */
            return true;
        }

        const prevRouteKey = getRouteKey(getState());
        if (action.type === ROUTE_KEYS.R_FAQ_DETAIL || prevRouteKey === ROUTE_KEYS.R_FAQ_DETAIL) {
            /** No refresh if
             * - we open a faq detial when coming from the faq overview
             * - we navigate from a faq detail back to the faq overview
             */
            return false;
        }

        /* But always refresh when navigating to the faq overview when coming from another page than the faq detail */
        return true;
    },
    async processReturn({ api }) {
        try {
            const result = await api.contact.faq.fetchFaqOverview();

            return fetchFaqOverviewSucceeded(result);
        } catch (error) {
            return fetchFaqOverviewFailed(error);
        }
    },
    latest: false,
});

// sendCompanyFeedbackEpic
createEpic<ISendCompanyFeedbackPayload>({
    onActionType: SEND_COMPANY_FEEDBACK,
    async processReturn({ api, action, getState }) {
        try {
            const companyCode = getSelectedSeatCompanyCode(getState());

            await api.contact.feedback.sendCompanyFeedback(companyCode, action.payload);

            return sendFeedbackActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: () => mapFeedbackTypeToSubmittedFormActionType(action.payload.type),
                },
            );
        } catch (error) {
            return sendFeedbackActions.failed(error);
        }
    },
    latest: false,
});

// resetSendCompanyFeedbackEpic
createEpic({
    onActionType: ROUTE_KEYS.R_CONTACT_DETAIL,
    processReturn() {
        return sendFeedbackActions.reset({});
    },
    latest: false,
});

// fetchCaseManagerEpic
createEpic<ICompanyCodePayload>({
    onActionType: [
        ROUTE_KEYS.R_CONTACT,
        ROUTE_KEYS.R_COURSES_PLANNED_DETAIL,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
    ],
    refreshDataIf: ({ getState }) => {
        const state = getState();
        if (!isCaseManagerAvailable(state) && hasReadAccessForAccessLevel(state, 'company')) {
            return true;
        }
        return false;
    },
    noDataRefreshOnlyInTheseReducers: [REDUCER_KEYS.CONTACT],
    async processMultiple({ api, action, getState }, dispatch, done) {
        const state = getState();
        try {
            dispatch(fetchCaseManagerActions.trigger(action.payload));

            const companyCode = getSelectedSeatCompanyCode(state);
            const caseManager = await api.contact.caseManager.fetchCaseManager({ companyCode });
            dispatch(fetchCaseManagerActions.succeeded(caseManager));
        } catch (error) {
            dispatch(fetchCaseManagerActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchCaseManagerByActionEpic
createEpic({
    onActionType: FETCH_CASE_MANAGER,
    async processMultiple({ api, getState }, dispatch, done) {
        const state = getState();
        try {
            const companyCode = getSelectedSeatCompanyCode(state);
            const caseManager = await api.contact.caseManager.fetchCaseManager({ companyCode });
            dispatch(fetchCaseManagerActions.succeeded(caseManager));
        } catch (error) {
            dispatch(fetchCaseManagerActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchMensuraContactsEpic
createEpic<ICompanyCodePayload>({
    onActionType: [
        ROUTE_KEYS.R_CONTACT,
        ROUTE_KEYS.R_CONTACT_DETAIL,
        FETCH_MENSURA_CONTACTS,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        const state = getState();
        if (hasReadAccessForAccessLevel(state, 'company')) {
            try {
                const selectedSeatCompanyCode = getSelectedSeatCompanyCode(state);

                const shouldUseSelectedSeatCompanyCode =
                    (action.type === ROUTE_KEYS.R_CONTACT || action.type === ROUTE_KEYS.R_CONTACT_DETAIL) &&
                    !isAllSeatsSelected(state);

                if (shouldUseSelectedSeatCompanyCode) {
                    const contactsForSelectedSeat = getMensuraContactsForSeat(state, selectedSeatCompanyCode);
                    if (contactsForSelectedSeat.length > 0) {
                        doNotUpdateContactsForCompany(dispatch);
                        return done();
                    }
                }

                const companyCode =
                    shouldUseSelectedSeatCompanyCode ? selectedSeatCompanyCode : action.payload.companyCode;

                if (!companyCode) {
                    doNotUpdateContactsForCompany(dispatch);
                    return done();
                }

                const contactsResponse = await api.contact.mensuraContacts.fetchMensuraContacts({
                    companyCode,
                });

                dispatch(fetchMensuraContactsActions.succeeded({
                    contacts: mapContactsResponseToPayload(contactsResponse, companyCode),
                    companyCode,
                }));
            } catch (error) {
                dispatch(fetchMensuraContactsActions.failed(error));
            }
        } else {
            doNotUpdateContactsForCompany(dispatch);
        }
        return done();
    },
    latest: false,
});

function mapContactsResponseToPayload(
    response: IMensuraContactsResponse,
    companyCode: string,
): IMensuraContactPerson[] {
    const mensuraContacts = [] as IMensuraContactPerson[];
    Object.keys(response.contacts).forEach((companyName) => {
        response.contacts[companyName].forEach((contact: IBaseContactPerson) => {
            mensuraContacts.push({
                ...contact,
                companyCode,
            });
        });
    });
    return mensuraContacts;
}

function doNotUpdateContactsForCompany(dispatch: (action: AnyAction) => void) {
    dispatch(fetchMensuraContactsActions.succeeded({
        contacts: [],
        companyCode: null,
    }));
}
