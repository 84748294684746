import { createSuccessAction, createFailAction } from '../../index';
import {
    FETCH_EXECUTED_INTERVENTIONS,
} from './types';
import { IExecutedIntervention } from '../../../models/interventions/interventions';
import { ITraceableApiError } from '../../../models/general/error';

// Fetch executed interventions
export function fetchExecutedInterventionsSucceeded(payload: IExecutedIntervention[]) {
    return createSuccessAction<IExecutedIntervention[]>(FETCH_EXECUTED_INTERVENTIONS, payload);
}

export function fetchExecutedInterventionsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXECUTED_INTERVENTIONS, error);
}
