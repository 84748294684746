import { post } from '../../utils/api/requestWrapper';
import { ICreateInterventionRequest } from '../../models/interventions/requestIntervention';

export const URL = {
    INTERVENTION_REQUEST: '/companies/{companyCode}/intervention-requests',
};

export function createInterventionRequest(companyCode: string, payload: ICreateInterventionRequest) {
    const { prestationCode, ...body } = payload;

    return post({
        url: URL.INTERVENTION_REQUEST,
        body,
        pathParams: {
            companyCode,
        },
    });
}
