import React, { MouseEvent, useState, useEffect, useMemo } from 'react';

import './avatar-image.scss';

import { AVATAR_IMAGE_PLACEHOLDER } from '../../../../../config/user.config';
import Icon from '../../../../common/icons/Icon';

const CLASS_NAME = 'AvatarImage';

interface IAvatarImageProps {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    imageUrl?: string;
    imageAlt?: string;
    imageRotation?: number;
}

export default function AvatarImage({ imageUrl, imageAlt, imageRotation, onClick }: IAvatarImageProps) {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(false);
    }, [imageUrl]);

    const avatar = useMemo(() => {
        return hasError ? AVATAR_IMAGE_PLACEHOLDER : imageUrl;
    }, [imageUrl, hasError]);

    const setUserFallbackImage = () => {
        setHasError(true);
    };

    return (
        <div className={CLASS_NAME} onClick={onClick}>
            <figure
                onError={setUserFallbackImage}
                style={{
                    backgroundImage: `url(${avatar})`,
                    transform: imageRotation ? `rotate3d(0, 0, 1, ${imageRotation}deg)` : 'none',
                }}
            >
                <img
                    src={avatar}
                    onError={setUserFallbackImage}
                    alt={imageAlt}
                />
            </figure>
            <Icon typeName="pencil" circle />
        </div>
    );
}
