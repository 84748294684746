import React from 'react';
import { clone } from 'ramda';

import { ListColumns } from '../../../../../../models/general/list';
import ListWithSorting from '../../../../../common/list/ListWithSorting';
// eslint-disable-next-line max-len
import ConvocationRecipients from '../../../../PlanMedicalExamination/PlanMedicalExaminationWizard/shared/ConvocationRecipients';
import { BASE_NAME } from '../PlanBufferzoneWizardOverview.const';
import { IPlanBufferzoneWizardOverviewListColumnNames } from '../PlanBufferzoneWizardOverview.type';

import { COLUMNS, INITIAL_SORT } from './PlanBufferzoneWizardOverviewList.const';
import { TPlanBufferzoneWizardOverviewListProps } from './PlanBufferzoneWizardOverviewList.type';

const PlanBufferzoneWizardOverviewList = ({
    showOnlyModifiedTimeslots,
    modifiedExamsListItems,
    plannedExamsListItems,
    footer,
}: TPlanBufferzoneWizardOverviewListProps) => {
    const columns: ListColumns<IPlanBufferzoneWizardOverviewListColumnNames> = clone(COLUMNS);

    return (
        <ListWithSorting
            name={BASE_NAME}
            initialSort={INITIAL_SORT}
            columns={columns}
            items={showOnlyModifiedTimeslots ? modifiedExamsListItems : plannedExamsListItems}
            selectedItemIds={[]}
            footer={
                <>
                    <ConvocationRecipients />
                    {footer}
                </>
            }
        />
    );
};

export { PlanBufferzoneWizardOverviewList };
