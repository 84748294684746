import React, { PureComponent } from 'react';
import { equals } from 'ramda';
import { MixedSchema } from 'yup';

import SlideOutPanel from '../../SlideOutPanel';
import IconHeader from '../../../headers/IconHeader';
import Translate from '../../../Translate';
import Form, { IFormRenderProps } from '../../../forms/Form';
import { ITranslator } from '../../../../../models/general/i18n';
import SubmitButton from '../../../buttons/SubmitButton';
import { TMasterData, IRenderSearchContentProps, IRenderFilterContentProps } from '../typings';
import { FILTER_BUTTON_FOCUS_ELEMENT, SEARCH_BUTTON_FOCUS_ELEMENT } from '../../../../../config/dom.config';

interface IPublicProps {
    baseName: string;
    isOpen: boolean;
    slideOutPanelElementToFocus?: string;
    isFetchingMaster: boolean;
    masterData: TMasterData;
    onSubmit: (filter: object) => void;
    onCloseIntent: (isClickedOutside: boolean) => void;
    translator: ITranslator;
    filterValues: object;
    renderSearchContent?: (props: IRenderSearchContentProps) => React.ReactElement<{}>;
    renderFilterContent?: (props: IRenderFilterContentProps) => React.ReactElement<{}>;
    filterValidationSchema?: MixedSchema;
}

class FilterAndSearchPanel extends PureComponent<IPublicProps> {
    private resetForm: (newFilterValues: object) => void;

    constructor(props: IPublicProps) {
        super(props);
    }

    public render() {
        const { baseName, isOpen, isFetchingMaster, masterData, onSubmit, onCloseIntent, translator,
            filterValues, renderSearchContent, renderFilterContent, slideOutPanelElementToFocus, filterValidationSchema,
         } = this.props;
        const FORM_NAME = `${baseName}-filter-form`;

        const footer = (
            <SubmitButton
                id="employee-filter-submit"
                disabled={isFetchingMaster}
                formName={FORM_NAME}
            >
                <Translate msg="common.master_with_detail.action.execute" />
            </SubmitButton>
        );

        return (
            <SlideOutPanel
                isOpen={isOpen}
                onCloseIntent={onCloseIntent}
                containerTofocus={slideOutPanelElementToFocus}
            >
                {(renderProps) => {
                    return (
                        <Form
                            name={FORM_NAME}
                            schema={filterValidationSchema}
                            handleSubmit={onSubmit}
                            initialValues={filterValues}
                            footer={footer}
                            render={(formRenderProps: IFormRenderProps) => {
                                if (!this.resetForm) {
                                    this.resetForm = formRenderProps.resetForm;
                                }

                                const content = (
                                    <section className="FilterAndSearchPanel">
                                        {renderSearchContent &&
                                        <div className="filter-group-container" id={SEARCH_BUTTON_FOCUS_ELEMENT}>
                                            <IconHeader iconTypeName="search">
                                                <Translate msg="common.master_with_detail.search.title" />
                                            </IconHeader>
                                            {renderSearchContent({ formRenderProps, translator })}
                                        </div>
                                        }

                                        {renderFilterContent &&
                                        <div className="filter-group-container" id={FILTER_BUTTON_FOCUS_ELEMENT}>
                                            <IconHeader iconTypeName="sliders">
                                                <Translate msg="common.master_with_detail.filter.title" />
                                            </IconHeader>
                                            {renderFilterContent({ formRenderProps, translator, masterData })}
                                        </div>
                                        }
                                    </section>
                                );
                                return (
                                    <>
                                    {renderProps.renderContent(content)}
                                    {renderProps.renderFooter()}
                                    </>
                                );
                            }}
                        />
                    );
                }}
            </SlideOutPanel>
        );
    }

    public componentDidUpdate(prevProps: IPublicProps) {
        if (this.resetForm && !equals(this.props.filterValues, prevProps.filterValues)) {
            // Reset the form, otherwise e.g. 'old' filters will still be visible although a user removed them
            // by clicking on an active filter cross (because formRenderProps.values would still contain the old ones)
            this.resetForm(this.props.filterValues);
        }
    }
}

export default FilterAndSearchPanel;
