import React, { Component } from 'react';
import classNames from 'classnames';
import '../questionnaires.scss';
import { ISortedColumn, SortOrder, ListColumns, SortType, ListItem } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../../routeKeys';
import {
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
    IRenderDetailContentProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    getQuestionnairesJobStudentsAsyncInfo,
    getQuestionnairesJobStudents,
    getQuestionnairesJobStudentsDetail,
    getSendJobStudentReminderEmailAsyncInfo,
    getTriggerPlanJobStudentMedExamAsyncInfo,
} from '../../../../redux/questionnaires/selectors';
import { IJobStudentQuestionnaire, ISendJobStudentReminderEmailPayload } from '../../../../models/admin/questionnaires';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { Detail } from './detail';
import Icon from '../../../common/icons/Icon';
import { OVERVIEW_CLASS_NAME } from '../shared';
import Checkbox from '../../../common/input/Checkbox';
import { stopPropagation } from '../../../../utils/browser/events/stopPropagation';
import ListActionButton from '../../../common/buttons/ListActionButton';
import { connect } from '../../..';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS } from '../../../../api/admin/questionnaires.api';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import {
    sendJobStudentReminderEmailActions,
    triggerPlanJobStudentMedExamActions,
} from '../../../../redux/questionnaires/actions';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import TinyLoader from '../../../common/waiting/TinyLoader';
import { TRANSLATION_PREFIX_OVERVIEW } from './common';
import SendReminderDialog from './SendReminderDialog';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';

const BASE_NAME = 'questionnaires-job-students';
const LIST_CLASS_NAME = 'QuestionnairesJobStudentsList';

interface IPrivateProps {
    sendReminderEmails: (ids: number[]) => void;
    sendReminderEmailsAsyncInfo: IAsyncFieldInfo;
    triggerPlanJobStudentMedExam: (id: number) => void;
}
interface IState {
    completedSelectedIds: number[];
    isSendReminderDialogOpen: boolean;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    function: string;
    isShowAll: boolean;
    status: string;
}

interface IColumnNames {
    actions: string;
    sendDate: string;
    employee: string;
    function: string;
    statusDescription: string;
    status: string;
    completed: string;
    idOfEmployee: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'sendDate',
    sortOrder: SortOrder.Descending,
};

const JOB_STUDENTS_COLUMNS: ListColumns<IColumnNames> = {
    actions: {
        label: null, // rendered in component render
        sortType: SortType.Boolean,
        minWidth: 36,
        percentWidth: null,
    },
    sendDate: {
        label: <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.columns.send_date`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
        render: (listItem) => listItem.columns.sendDate && formatDateForDisplay(listItem.columns.sendDate as string),
    },
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    function: {
        label: <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.columns.function`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 27,
    },
    statusDescription: {
        label: <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.columns.result`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 27,
    },
    status: {
        percentWidth: null,
        hide: true,
    },
    completed: {
        label: <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.columns.completed`} />,
        sortable: true,
        sortType: SortType.Boolean,
        percentWidth: 12,
    },
    idOfEmployee: {
        percentWidth: null,
        hide: true,
    },
};

class Overview extends Component<IPrivateProps, IState> {

    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            completedSelectedIds: [],
            isSendReminderDialogOpen: false,
        };

        this.renderActionContent = this.renderActionContent.bind(this);
        this.renderJobStudentsList = this.renderJobStudentsList.bind(this);
        this.renderActionsHeader = this.renderActionsHeader.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.renderCompleted = this.renderCompleted.bind(this);

        this.areAllCheckboxesSelected = this.areAllCheckboxesSelected.bind(this);
        this.onCheckAllClicked = this.onCheckAllClicked.bind(this);
        this.onCheckedClicked = this.onCheckedClicked.bind(this);
        this.onSendReminderEmails = this.onSendReminderEmails.bind(this);
        this.onCloseSendReminderDialog = this.onCloseSendReminderDialog.bind(this);
    }

    public render() {
        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                className={OVERVIEW_CLASS_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS,
                    asyncInfoSelector: getQuestionnairesJobStudentsAsyncInfo,
                    dataSelector: getQuestionnairesJobStudents,
                    transformData: mapJobStudentsToListItems,
                    renderContent: this.renderJobStudentsList,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: JOB_STUDENTS_COLUMNS,
                    },
                    clientSideFilterOfListData,
                    transformFilterValuesToActiveFilters,
                    filterValidationSchema: startEndDateSchema,
                }}
                detailConfig={{
                    routeKey: ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL,
                    asyncInfoSelector: getQuestionnairesJobStudentsAsyncInfo,
                    dataSelector: getQuestionnairesJobStudentsDetail,
                    renderHeader: undefined,
                    renderContent: (renderProps: IRenderDetailContentProps<IJobStudentQuestionnaire>) =>
                        <Detail {...renderProps} />,
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    renderActionContent: this.renderActionContent,
                    exportButton: {
                        baseFilename: 'questionnaires-job-students',
                        listItemIdExtractor: toListId,
                        mapListRowForExport,
                    },
                }}
            />
        );
    }

    private renderJobStudentsList(props: IRenderMasterContentProps<ListItem<IColumnNames>[]>) {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            onItemSelected,
            footer,
        } = props;
        const { sendReminderEmailsAsyncInfo } = this.props;

        JOB_STUDENTS_COLUMNS.actions.headerRender = this.renderActionsHeader;
        JOB_STUDENTS_COLUMNS.actions.render = this.renderActions;
        JOB_STUDENTS_COLUMNS.completed.render = this.renderCompleted;

        return (
            <div className={LIST_CLASS_NAME}>
                <ListWithSorting
                    columns={JOB_STUDENTS_COLUMNS}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    initialSort={INITIAL_SORT}
                    footer={footer}
                />
                <SendReminderDialog
                    show={this.state.isSendReminderDialogOpen}
                    onClose={this.onCloseSendReminderDialog}
                    asyncInfo={sendReminderEmailsAsyncInfo}
                />
            </div>
        );
    }

    private onCloseSendReminderDialog() {
        this.setState({ isSendReminderDialogOpen: false, completedSelectedIds: [] });
    }

    private renderActionsHeader(listItems: ListItem<IColumnNames>[]) {
        if (listItems.length <= 0) {
            return null;
        }

        return (
            <span className={`${LIST_CLASS_NAME}__actions-header`}>
                <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }} >
                    <Checkbox
                        onDivClicked={stopPropagation}
                        checked={this.areAllCheckboxesSelected(listItems)}
                        onChange={(e) => this.onCheckAllClicked(e, listItems)}
                        name="job-students-completed-checkbox-all"
                    />
                </ShowIfAllowed>
            </span>
        );
    }

    private renderActions(listItem: ListItem<IColumnNames>, index: number) {
        if (listItem.columns.completed || !listItem.columns.idOfEmployee || listItem.columns.idOfEmployee === 0) {
            return null;
        }

        const isChecked = this.state.completedSelectedIds.includes(listItem.id as number);

        return (
            <div className={`${LIST_CLASS_NAME}__actions`}>
                <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }} >
                    <Checkbox
                        onDivClicked={stopPropagation}
                        onChange={() => this.onCheckedClicked(listItem)}
                        checked={isChecked}
                        name={`job-students-completed-checkbox-${index}`}
                    />
                </ShowIfAllowed>
            </div>
        );
    }

    private renderCompleted(listItem: ListItem<IColumnNames>, index: number) {
        const completed = listItem.columns.completed;

        const { triggerPlanJobStudentMedExam } = this.props;

        function renderAction(listItem: ListItem<IColumnNames>) {
            if (listItem.columns.status === 5) {
                return (
                    <ListItemActions>
                        <Button id={`job-students-completed-advice-${index}`}>
                            <span><Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.completed.advice`} /></span>
                            <Icon circle typeName="conversation" />
                        </Button>
                    </ListItemActions>
                );
            }
            if (listItem.columns.status === 1) {
                return (
                    <ListItemActions>
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            <TinyLoader
                                showContentOnInitialState={true}
                                asyncInfoSelector={getTriggerPlanJobStudentMedExamAsyncInfo}
                            >
                                <Button
                                    id={`job-students-completed-plan-${index}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        triggerPlanJobStudentMedExam(listItem.id as number);
                                    }}
                                >
                                    <span><Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.completed.plan`} /></span>
                                    <Icon circle typeName="calendar" />
                                </Button>
                            </TinyLoader>
                        </ShowIfAllowed>
                    </ListItemActions>
                );
            }
            return null;
        }

        return (
            <div
                className={classNames(`${LIST_CLASS_NAME}__completed`, {
                    [`${LIST_CLASS_NAME}__completed--success`]: !!completed,
                })}
            >
                {completed ? (
                    <span><Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.completed.yes`} /></span>
                ) : (
                        <span><Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.completed.no`} /></span>
                    )}
                {renderAction(listItem)}
            </div>
        );
    }

    private areAllCheckboxesSelected(listItems: ListItem<IColumnNames>[]): boolean {
        const { completedSelectedIds } = this.state;
        return filterNotCompletedListItems(listItems)
            .map((listItem) => listItem.id as number)
            .every((listItemId: number) => completedSelectedIds.includes(listItemId));
    }

    private onCheckAllClicked(e: React.ChangeEvent<HTMLInputElement>, listItems: ListItem<IColumnNames>[]) {
        const isSelected = e.target.checked;

        if (isSelected) {
            const newCompletedSelectedIds = filterNotCompletedListItems(listItems)
                .map((listItem) => listItem.id as number);
            this.setState({ completedSelectedIds: newCompletedSelectedIds });
        } else {
            this.setState({ completedSelectedIds: [] });
        }
    }

    private onCheckedClicked(listItem: ListItem<IColumnNames>) {
        const { completedSelectedIds } = this.state;

        const id = listItem.id as number;

        const newCompletedSelectedIds = [...completedSelectedIds];

        const indexOfId = newCompletedSelectedIds.indexOf(id);

        if (indexOfId !== -1) {
            newCompletedSelectedIds.splice(indexOfId, 1);
        } else {
            newCompletedSelectedIds.push(id);
        }

        this.setState({ completedSelectedIds: newCompletedSelectedIds });
    }

    private renderActionContent() {
        const { completedSelectedIds } = this.state;
        return (
            <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                <ListActionButton
                    id="questionnaires-job-students-send-reminder"
                    type="text"
                    iconTypeName="message"
                    translationKey={`${TRANSLATION_PREFIX_OVERVIEW}.action.send_reminder`}
                    onClick={this.onSendReminderEmails}
                    disabled={completedSelectedIds.length <= 0}
                />
            </ShowIfAllowed>
        );
    }

    private onSendReminderEmails() {
        const { completedSelectedIds } = this.state;
        const { sendReminderEmails } = this.props;

        sendReminderEmails(completedSelectedIds);

        this.setState({
            isSendReminderDialogOpen: true,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            sendReminderEmailsAsyncInfo: getSendJobStudentReminderEmailAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            sendReminderEmails: (ids: number[]) => {
                const jobStudents =
                    getQuestionnairesJobStudents(getState())
                        .filter((jobStudent) => ids.includes(jobStudent.id));

                dispatch(sendJobStudentReminderEmailActions.trigger(
                    jobStudents.map((jobStudent) => {
                        const payload: ISendJobStudentReminderEmailPayload = {
                            employee: {
                                id: jobStudent.employee.id,
                            },
                            interimEmployeeId: jobStudent.interimEmployeeId,
                        };
                        return payload;
                    }),
                ));
            },
            triggerPlanJobStudentMedExam: (id: number) => {
                const jobStudent =
                    getQuestionnairesJobStudents(getState())
                        .find((jobStudent) => jobStudent.id === id);

                dispatch(triggerPlanJobStudentMedExamActions.trigger({
                    companyCode: jobStudent.employee.company.companyCode,
                    nationalRegisterNumber: jobStudent.employee.nationalRegisterNumber,
                }));
            },
        };
    },
})(Overview);

function toListId(item: IJobStudentQuestionnaire) {
    return item.id;
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS,
        isShowAll,
    } : DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS;
}

function mapJobStudentsToListItems(masterData: IJobStudentQuestionnaire[]): ListItem<IColumnNames>[] {
    return masterData
        .map((item) => {
            const listItem: ListItem<IColumnNames> = {
                id: toListId(item),
                columns: {
                    actions: null,
                    completed: item.completed,
                    employee: formatPersonNameFormal(item.employee),
                    function: item.employee.function.description,
                    statusDescription: item.statusDescription,
                    status: item.status,
                    sendDate: item.dateCreated,
                    idOfEmployee: item.employee.id,
                },
            };
            return listItem;
        });
}

function mapListRowForExport(jobStudent: IJobStudentQuestionnaire) {
    const {
        completed,
        dateCreated,
        dateLastSendPass,
        employee: { company: { companyCode } },
        employee: { company: { name: companyName } },
        employee: { email },
        employee: { firstName },
        employee: { name },
        employee: { function: { description } },
        languageCode,
        statusDescription,
    } = jobStudent;

    return {
        completed,
        dateCreated,
        dateLastSendPass,
        employee: {
            company: {
                companyCode,
                name: companyName,
            },
            email,
            firstName,
            name,
            function: {
                description,
            },
        },
        languageCode,
        statusDescription,
    };
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX_OVERVIEW}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX_OVERVIEW}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleFunctions = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'function',
        'function',
    );

    const possibleResults = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'status',
        'statusDescription',
    );
    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX_OVERVIEW}.filter`}
                formRenderProps={formRenderProps}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="function"
                labelTranslationKey={`${TRANSLATION_PREFIX_OVERVIEW}.filter.function`}
                possibleFilterItems={possibleFunctions}
                actualFilterValue={formRenderProps.values.function}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'function',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="result"
                labelTranslationKey={`${TRANSLATION_PREFIX_OVERVIEW}.filter.result`}
                possibleFilterItems={possibleResults}
                actualFilterValue={formRenderProps.values.status}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'status',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.status)) {
                return true;
            }

            const statusses = separateStringList(filterValues.status);
            return statusses.includes(listItem.columns.status.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.function)) {
                return true;
            }

            const functions = separateStringList(filterValues.function);
            return functions.includes(listItem.columns.function as string);
        });
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX_OVERVIEW}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_QUESTIONNAIRES_JOB_STUDENTS_FILTERS.startDate,
            },
            status: {
                show: true,
                translationKeySuffixOverride: 'result',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'statusDescription',
                        searchColumnName: 'status',
                    },
                },
            },
            function: {
                show: true,
                multiple: {
                    enable: true,
                },
            },
        },
    });
}

function filterNotCompletedListItems(listItems: ListItem<IColumnNames>[]) {
    return listItems
        .filter((listItem) => !listItem.columns.actions);
}
