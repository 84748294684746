import React, { useRef, useEffect } from 'react';
import { logOutgoingLink } from '../../../../utils/logging/analytics/eventLogger';

interface IPublicProps {
    html: string;
    className?: string;
    openLinksInNewTab?: boolean;
}

function WysiwygContentType({ openLinksInNewTab = true, html, className }: IPublicProps) {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const anchorLinkListener = (e: MouseEvent) => {
            if ((e.target as HTMLElement).tagName === 'A') {
                const { href } = e.target as HTMLAnchorElement;
                if (href) {
                    if ((/^mailto:/i).test(href)) {
                        e.preventDefault();
                        window.open(href, 'mail');
                    } else if (openLinksInNewTab) {
                        e.preventDefault();
                        window.open(href, '_blank');
                    }

                    logOutgoingLink({
                        outgoingUrl: href,
                    });
                }
            }
        };

        if (divRef && divRef.current) {
            const { current: currentRef } = divRef;
            currentRef.addEventListener('click', anchorLinkListener);

            return () => {
                currentRef.removeEventListener('click', anchorLinkListener);
            };
        }

        return () => {};
    }, [openLinksInNewTab]);

    return (
        <div
            ref={divRef}
            className={className}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}

export default WysiwygContentType;
