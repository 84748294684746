import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';
import { ICompanyAddress } from '../../../../../models/admin/companyInfo';
import { ITranslator } from '../../../../../models/general/i18n';
import { getCompanyAddresses, getFetchCompanyAddressesAsyncInfo } from '../../../../../redux/company/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';

interface IPrivateProps {
    companyAddresses: ICompanyAddress[];
    addresses: TTypeaheadData;
    translator: ITranslator;
}

interface IPublicProps {
    id: string;
    value: number;
    name: string;
    isInvalid?: boolean;
    onItemSelected: (formattedAddress: string, companyAddress: ICompanyAddress) => void;
    children?: ReactNode;
    placeholder?: string;
    disabled?: boolean;
}

function CompanyAddressesTypeahead(props: IPublicProps & IPrivateProps) {
    const {
        children,
        id, value, name, isInvalid, onItemSelected,
        placeholder, disabled,
        companyAddresses, addresses,
    } = props;

    function onItemSelectedHandler(addressId: number) {
        const selectedAddress = addresses.find((item) => item.value === addressId);
        const selectedCompanyAddress = companyAddresses.find((item) => item.id === addressId);

        onItemSelected(selectedAddress && selectedAddress.label, selectedCompanyAddress || null);
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={addresses}
            placeholder={placeholder}
            disabled={disabled}
            asyncInfoSelector={getFetchCompanyAddressesAsyncInfo}
        >
            {children}
        </Typeahead>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const companyAddresses = getCompanyAddresses(state);

        return {
            companyAddresses,
            addresses: mapCompanyAddressesForTypeahead(
                companyAddresses,
            ),
            translator: getTranslatorDeprecated(state),
        };
    },
})(CompanyAddressesTypeahead);

function mapCompanyAddressesForTypeahead(companyAddresses: ICompanyAddress[]): TTypeaheadData {
    return Array.isArray(companyAddresses) ? companyAddresses.map((item: ICompanyAddress) => ({
        value: item.id,
        label: formatAddress(item, true),
    })) : [];
}
