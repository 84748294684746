import React, { PureComponent } from 'react';
import './overlay-curtain.scss';
import classNames from 'classnames';
import { DIALOG_CONTAINER_ELEMENT_ID, ROOT_ELEMENT_ID } from '../../../../config/dom.config';
import getOrCreateElementInBodyById from '../../../../utils/dom/getOrCreateElementInBodyById';
import Portal from '../../technical/Portal';
import { getZIndex, releaseZIndex, Z_INDEX_TYPE } from '../../../../utils/dom/zIndexManager';

const DIALOG_CONTAINER_ELEMENT = getOrCreateElementInBodyById(DIALOG_CONTAINER_ELEMENT_ID);
const ROOT_ELEMENT = getOrCreateElementInBodyById(ROOT_ELEMENT_ID);
const ANIMATION_DURATION = 300; // ms

export interface IOverlayCurtainRenderProps {
    isTransitionOnOpenFinished: boolean;
}

interface IProps {
    show: boolean;
    fadeEffect: 'blur' | 'darken' | 'blur-more' | 'none';
    children?: React.ReactNode | ((renderPrpos: IOverlayCurtainRenderProps) => React.ReactNode);
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    animationEffect: 'scale' | 'none';
    allowClickingOnUnderlyingElements?: boolean;
    showAtTop?: boolean;
}

interface IComponentState {
    isTransitionOnOpenFinished: boolean;
    isTopOverlay: boolean;
}

class OverlayCurtain extends PureComponent<IProps, IComponentState> {
    private zIndex: number;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isTransitionOnOpenFinished: props.show,
            isTopOverlay: true,
        };

        this.onCurtainClick = this.onCurtainClick.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.onZIndexChangeOfOtherOverlays = this.onZIndexChangeOfOtherOverlays.bind(this);
        this.setZindex = this.setZindex.bind(this);
    }

    public render() {
        const { children, show, animationEffect, allowClickingOnUnderlyingElements, showAtTop } = this.props;
        const curtainClass = classNames('OverlayCurtain', this.getFadeEffectClass(), {
            open: show,
            'OverlayCurtain--scale': animationEffect === 'scale',
            'OverlayCurtain--no-pointer-events': allowClickingOnUnderlyingElements,
            'OverlayCurtain--top': !!showAtTop,
            'OverlayCurtain--is-not-most-top': this.zIndex && !this.state.isTopOverlay,
        });

        const childrenTypedAsFunction = children as ((renderPrpos: IOverlayCurtainRenderProps) => React.ReactNode);

        return (
            <Portal domNode={DIALOG_CONTAINER_ELEMENT}>
                <div
                    className={curtainClass}
                    onClick={this.onCurtainClick}
                    style={{
                        zIndex: this.zIndex,
                    }}
                    onTransitionEnd={this.onTransitionEnd}
                >
                    <div className="OverlayCurtain__faded-area" onTransitionEnd={this.onTransitionEnd} />
                    <div className="OverlayCurtain__content">{
                        typeof children === 'function'
                            ? childrenTypedAsFunction({
                                isTransitionOnOpenFinished: this.state.isTransitionOnOpenFinished,
                            }) : children
                    }</div>
                </div>
            </Portal>
        );
    }

    public UNSAFE_componentWillMount() {
        this.setZindex(this.props);
    }

    public componentDidMount() {
        this.setFadeEffect(this.props);
    }

    public componentWillUnmount() {
        this.removeFadeEffect();
        releaseZIndex(this.zIndex);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        // Only update the z-index when going from hidden to shown
        // On close the z-index should be removed after the transition is finished
        if (!this.props.show && nextProps.show) {
            this.setZindex(nextProps);
        }

        if (this.props.show !== nextProps.show) {
            this.setFadeEffect(nextProps);
        }
    }

    private onTransitionEnd() {
        if (this.props.show) {
            this.setState({
                isTransitionOnOpenFinished: true,
            });
        }
        if (this.zIndex && !this.props.show) {
            this.setZindex(this.props);
            this.setState({
                isTransitionOnOpenFinished: false,
            });
        }
    }

    private onCurtainClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        const { onClick } = this.props;
        if (typeof onClick === 'function') {
            const target = e.target as HTMLElement;
            if (target && target.classList.contains('OverlayCurtain__faded-area')) {
                onClick(e);
            }
        }
    }

    private setZindex(props: IProps) {
        if (props.show && !this.zIndex) {
            this.zIndex = getZIndex(Z_INDEX_TYPE.OVERLAY, this.onZIndexChangeOfOtherOverlays);
            this.setState({ isTopOverlay: true });
        } else if (!props.show) {
            releaseZIndex(this.zIndex);
            this.zIndex = null;
        }
    }

    private onZIndexChangeOfOtherOverlays(otherOverlayZIndexes: number[]) {
        if (otherOverlayZIndexes.length <= 0 || this.zIndex > Math.max(...otherOverlayZIndexes)) {
            this.setState({ isTopOverlay: true });
        } else {
            this.setState({ isTopOverlay: false });
        }
    }

    private setFadeEffect(props: IProps) {
        const { show } = props;
        if (show) {
            this.addFadeEffect();
        } else {
            this.removeFadeEffect();
        }
    }

    private addFadeEffect() {
        const rootEl = ROOT_ELEMENT;
        if (rootEl) {
            const fadeEffectClass = this.getFadeEffectClass();
            rootEl.classList.add(fadeEffectClass);
        }
    }

    private removeFadeEffect() {
        const rootEl = ROOT_ELEMENT;
        const fadeEffectClass = this.getFadeEffectClass();
        if (rootEl && rootEl.classList.contains(fadeEffectClass)) {
            // There is an animation on the visibility so we need to wait until animation is done
            setTimeout(
                () => {
                    let openCount = 0;
                    const curtainsShown = document.querySelectorAll(`.OverlayCurtain.open.${fadeEffectClass}`);
                    for (let i = 0, length = curtainsShown.length; i < length; i += 1) {
                        const curtainEl = curtainsShown[i];
                        if (getComputedStyle(curtainEl).visibility === 'visible') {
                            openCount += 1;
                        }
                    }

                    // Remove blur when no overlay curtains are opened
                    if (openCount === 0) {
                        rootEl.classList.remove(fadeEffectClass);
                    }
                },
                ANIMATION_DURATION,
            );
        }
    }

    private getFadeEffectClass() {
        const { fadeEffect } = this.props;
        if (fadeEffect === 'blur') {
            return 'OverlayCurtain__blur';
        }
        if (fadeEffect === 'blur-more') {
            return 'OverlayCurtain__blur-more';
        }
        if (fadeEffect === 'darken') {
            return 'OverlayCurtain__darken';
        }
        return 'OverlayCurtain__none';
    }
}

export default OverlayCurtain;
