import { createEpic } from '../../index';
import {
    FETCH_EMPLOYEES,
    FETCH_COMPANY_FUNCTION_EMPLOYEES,
} from './types';
import {
    fetchEmployeesActions,
    setTotalEmployeesCount,
    fetchCompanyFunctionEmployeesSucceeded,
    fetchCompanyFunctionEmployeesFailed,
    fetchEmployeesWithoutEmailActions,
} from './actions';
import { doFetchEmployeesCall, getFetchEmployeesPayload } from './fetchEmployeesHelper';
import ROUTE_KEYS from '../../../routeKeys';
import { IFetchEmployeesPayload } from '../../../models/admin/employee';
import { getSelectedCompanySeat, getSelectedSeatCompanyCode } from '../../company/selected/selectors';
import { isEmployeesDataAvailable } from './selectors';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { IFetchCompanyFunctionEmployeesPayload } from '../../../models/admin/companyFunctions';
import { getLocationState } from '../../location/selectors';

const ACTION_TYPES_THAT_FETCH_EMPLOYEES_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE);

// fetchEmployeesEpic
createEpic<IFetchEmployeesPayload>({
    onActionType: [ROUTE_KEYS.R_EMPLOYEES, FETCH_EMPLOYEES],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const { employees, count } = await doFetchEmployeesCall({
                state,
                actionPayload: action.payload,
                api,
            });

            const { prev: { type: prevRouteKey } } = getLocationState(state);

            if (action.type === ROUTE_KEYS.R_EMPLOYEES && prevRouteKey !== ROUTE_KEYS.R_EMPLOYEES) {
                const companyCode = getSelectedSeatCompanyCode(state);
                const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

                const response = await api.admin.employee.fetchEmployeesWithoutEmail({
                    companyCode,
                    showFullFamily,
                    refreshSources: false,
                });

                dispatch(fetchEmployeesWithoutEmailActions.succeeded(response.employees));
            }

            dispatch(fetchEmployeesActions.succeeded(employees));
            dispatch(setTotalEmployeesCount(count));

            done();
        } catch (error) {
            dispatch(fetchEmployeesActions.failed(error));
            dispatch(setTotalEmployeesCount(0));
            done();
        }
    },
    latest: true,
});

// fetchEmployeesIfNotAlreadyAvailableEpic
createEpic<IFetchEmployeesPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_EMPLOYEES_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !isEmployeesDataAvailable(getState()),
    processReturn: ({ action, getState }) => {
        const state = getState();
        const {
            maxRecordCount,
            refreshSources,
            search,
        } = getFetchEmployeesPayload(action.payload, state);

        return fetchEmployeesActions.trigger({
            maxRecordCount,
            refreshSources,
            search,
        });
    },
    latest: false,
});

// fetchCompanyFunctionEmployeesEpic
createEpic<IFetchCompanyFunctionEmployeesPayload>({
    onActionType: FETCH_COMPANY_FUNCTION_EMPLOYEES,
    async processReturn({ api, getState, action }) {
        try {
            const state = getState();
            const id = action.payload.id.toString();
            const companyCode = action.payload.companyCode;
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            const employees = await api.admin.functions.fetchCompanyFunctionEmployees(companyCode, id, showFullFamily);
            return fetchCompanyFunctionEmployeesSucceeded(employees);
        } catch (error) {
            return fetchCompanyFunctionEmployeesFailed(error);
        }
    },
    latest: true,
});

// fetchEmployeesWithoutEmailEpic
createEpic<{}>({
    onActionType: ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS,
    refreshDataIf: ({ getState }) => {
        const {
            type: prevRouteKey,
        } = getLocationState(getState());

        if (prevRouteKey === ROUTE_KEYS.R_EMPLOYEES) {
            /* No refresh if we navigate from employee list to addEmails */
            return false;
        }
        return true;
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            const response = await api.admin.employee.fetchEmployeesWithoutEmail({
                companyCode,
                showFullFamily,
                refreshSources: false,
            });

            return fetchEmployeesWithoutEmailActions.succeeded(response.employees);
        } catch (error) {
            return fetchEmployeesWithoutEmailActions.failed(error);
        }
    },
    latest: true,
});
