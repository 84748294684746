import React, { ReactNode } from 'react';
import PageHeader from '../../appShell/PageHeader';

interface IPublicProps {
    children: ReactNode;
    titleTranslationKey: string;
    textTranslationKey: string;
    headerSvg?: string;
}

export default function PolicyAdvicePage(props: IPublicProps) {
    const {
        children,
        titleTranslationKey,
        headerSvg,
        textTranslationKey,
    } = props;

    return (
        <div>
            <PageHeader
                breadcrumbs={true}
                title={titleTranslationKey}
                text={textTranslationKey}
                type="green"
                svg={headerSvg}
            />
            <div className="container">
                {children}
            </div>
        </div>
    );
}
