import { object, date, boolean } from 'yup';
import number from '../../../../../../../utils/libs/yup/number';
import phoneNumber from '../../../../../../../utils/libs/yup/phoneNumber';
import {
    nationalityIdBelgium,
    personalDataFields,
} from '../../../../../../administration/Employees/AddEmployee/steps/PersonalData/PersonalDataSchema';
import { fields as seatAndFunctionFields }
    from '../../../../../../administration/Employees/shared/EditSeatAndFunction/updateSeatAndFunctionSchema';
import { fields as medicalCenterFields }
    from '../../../../../../administration/Employees/shared/EditMedicalCenter/updateMedicalCenterSchema';
import { fields as jobStudentFields }
    from '../../../../../../administration/Employees/shared/EditJobStudent/editJobStudentSchema';
import string from '../../../../../../../utils/libs/yup/string';
import { nationalAddressOptionalSchema } from '../../../../../../common/address/schema';
import nationalRegisterNumber from '../../../../../../../utils/libs/yup/nationalRegisterNumber';

export const fields = {
    ...seatAndFunctionFields,
    ...personalDataFields,
    ...medicalCenterFields,
    ...jobStudentFields,
};

export const schema = object({
    [fields.address]: nationalAddressOptionalSchema,
    [fields.birthDate]: string().nullable(true).when(
        fields.nationalRegisterNumber,
        {
            is: (value) => {
                return value === undefined;
            },
            then: string().required(),
        }),
    [fields.email]: string().when([fields.studentWorker, fields.sendMail], {
        is: true,
        then: string().email().required(),
        otherwise: string().email(),
    }),
    [fields.employeeNumber]: string().nullable(true),
    [fields.firstName]: string().required(),
    [fields.languageId]: number().required(),
    [fields.mobilePhone]: phoneNumber(),
    [fields.nationalityId]: number().required(),
    [fields.nationalRegisterNumber]: nationalRegisterNumber().nullable(true).when(
        fields.nationalityId,
        {
            is: (value) => {
                if (typeof value === 'string') {
                    const valueAsNumber = parseInt(value, 10);
                    return valueAsNumber === nationalityIdBelgium;
                }
                return value === nationalityIdBelgium;
            },
            then: nationalRegisterNumber().required(),
        }),
    [fields.name]: string().required(),
    [fields.phone]: phoneNumber(),
    [fields.sexId]: number().required(),
    [fields.dateInFunction]: date().required(),
    [fields.medicalCenterId]: number(),
    [fields.companyCode]: string().required(),
    [fields.customerFunctionId]: number().required(),
    [fields.dateInFunction]: date().required(),
    [fields.medicalFunction]: boolean().nullable(true),
    [fields.safetyFunction]: boolean().nullable(true),
    [fields.sendMail]: boolean().nullable(true),
    [fields.studentWorker]: boolean().nullable(true),
    [fields.employer]: string().when(fields.studentWorker, {
        is: true,
        then: string().required(),
        otherwise: string(),
    }),
});
