import {
    IVaccinationsApiResponse,
    IMappedAdministeredVaccination,
    IMappedToAdministerVaccination,
} from '../../../models/interventions/vaccines';

export const mapApiResponseToAdministeredVaccinations = (
    vaccinations: IVaccinationsApiResponse[],
): IMappedAdministeredVaccination[] | IMappedToAdministerVaccination[] =>
    vaccinations.reduce(
        (acc, seat) => {
            const { employees } = seat;

            const vaccins = employees.map((employee) => {
                return employee.vaccinations.map(
                    (vaccination): IMappedAdministeredVaccination | IMappedToAdministerVaccination => ({
                        ...vaccination,
                        employer: seat.name,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        name: `${employee.lastName} ${employee.firstName}`,
                        companyCode: seat.code,
                        ...!!employee.employeeCode && {
                            employeeCode: employee.employeeCode,
                        },
                    }),
                );
            });

            return acc.concat(...vaccins);
        },
        []);
