import { object } from 'yup';
import { FormValues } from './detailFooter';
import string from '../../../../utils/libs/yup/string';

export const fields: FormValues = {
    feedback: 'feedback',
};

export const schema = object({
    [fields.feedback]: string().required(),
});
