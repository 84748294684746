import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './document-center-page.scss';
import PageHeader from '../../appShell/PageHeader';

export const CLASS_NAME = 'DocumentCenterPage';

interface IPublicProps {
    children: ReactNode;
    titleTranslationKey: string;
    headerSvg?: string;
}

export default function DefaultDocumentCenterPage(props: IPublicProps) {
    const {
        children,
        titleTranslationKey,
        headerSvg,
    } = props;

    return (
        <div className={classNames(CLASS_NAME, `${CLASS_NAME}--default`)}>
            <PageHeader
                breadcrumbs={true}
                title={titleTranslationKey}
                type="grey"
                svg={headerSvg}
            />
            <div className="container">
                {children}
            </div>
        </div>
    );
}
