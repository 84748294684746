import React from 'react';
import './notification.scss';
import classNames from 'classnames';

interface INotificationProps {
    count: number;
    animate?: boolean;
    className?: string;
}

const Notification = (props: INotificationProps) => {
    const className = classNames('Notification', props.className, {
        'Notification--small': props.count >= 100,
        animate: !!props.animate,
    });

    if (!props.count || props.count <= 0) {
        return null;
    }

    return (
        <span className={className}>{props.count}</span>
    );
};

export default Notification;
