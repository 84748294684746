export default function isEmptyObject(obj: Object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isFalsyOrEmptyObject(obj: Object) {
    if (!obj) {
        return true;
    }
    return isEmptyObject(obj);
}
