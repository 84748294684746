import React from 'react';
import { ICourseCategory, ICMSCourse } from '../../../../../models/documentCenter/courses';
import { toAnchorTargetId } from '../../../../../utils/core/string/anchor';
import CourseTile from '../../shared/CourseTile/index';
import Translate from '../../../../common/Translate';

const CLASS_NAME = 'CoursesOverview';

interface ICoursesListProps {
    coursesByCategories: ICourseCategory[];
}

export default function CoursesList({ coursesByCategories }: ICoursesListProps) {
    if (!coursesByCategories) {
        return null;
    }

    return (
        <>
            {coursesByCategories.length > 0
                ? coursesByCategories.map((courseCategory: ICourseCategory) => (
                    <ContentBlock
                        key={`coursesOverview_content_${courseCategory.category}`}
                        courseCategory={courseCategory}
                    />
                ))
                : (
                    <h2>
                        <Translate msg="document_center.courses.overview.filter.no_results"/>
                    </h2>
                )
            }
        </>
    );
}

function ContentBlock({ courseCategory }: { courseCategory: ICourseCategory }) {
    return (
        <>
            <h2 id={toAnchorTargetId(courseCategory.category)}>
                {`${courseCategory.category} (${courseCategory.courses.length.toString()})`}&nbsp;
            </h2>
            <div className="ContentData">
                <CourseTiles
                    courses={courseCategory.courses}
                />
            </div>
        </>
    );
}

function CourseTiles({ courses }: {courses: ICMSCourse[]}) {
    return (
        <div className={`${CLASS_NAME}__list`}>
            {courses.map((course) => (
                <CourseTile
                    key={`course-${course.nodeId}`}
                    course={course}
                />
            ))}
        </div>
    );
}
