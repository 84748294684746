import { createAction, createSuccessAction, createFailAction, createTypeActions } from '../../index';
import {
    FETCH_FLU_VACCINES_ORDERS,
    FETCH_FLU_VACCINES_CONFIG,
    FETCH_FLU_VACCINE_TARIFFS,
    FETCH_FLU_VACCINES_AVAILABLE,
    UPDATE_FLU_VACCINES_ORDER_WIZARD_ENTITY,
    CREATE_FLU_VACCINES_ORDER,
    UPDATE_FLU_VACCINES_ORDER,
} from './types';
import { navigateTo } from '../../location/actions';
import {
    FLU_VACCINES_ORDER_WIZARD_STEP_ID,
    IFluVaccinesOrder,
    IFluVaccinesConfig,
    IFluVaccinesOrderWizardPayload,
    IFluVaccinesOrderWizardEntity,
    IFluVaccineTariffs,
    IFluVaccinesAvailable,
    ICreateFluVaccinesOrder,
    IUpdateFluVaccinesOrderActionPayload,
} from '../../../models/interventions/fluVaccines';
import { ITraceableApiError } from '../../../models/general/error';
import ROUTE_KEYS from '../../../routeKeys';
import { ICompanyCodePayload } from '../../../models/admin/company';
import { getOrderFluVaccinesWizardSteps } from '../../../config/navigation/wizardStepsMap';

// Flu vaccines orders
export function fetchFluVaccinesOrders() {
    return createAction(FETCH_FLU_VACCINES_ORDERS, {});
}

export function fetchFluVaccinesOrdersSucceeded(payload: IFluVaccinesOrder[]) {
    return createSuccessAction<IFluVaccinesOrder[]>(FETCH_FLU_VACCINES_ORDERS, payload);
}

export function fetchFluVaccinesOrdersFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_FLU_VACCINES_ORDERS, error);
}

// Flu vaccines config
export function fetchFluVaccinesConfig() {
    return createAction(FETCH_FLU_VACCINES_CONFIG, {});
}

export function fetchFluVaccinesConfigSucceeded(payload: IFluVaccinesConfig) {
    return createSuccessAction<IFluVaccinesConfig>(FETCH_FLU_VACCINES_CONFIG, payload);
}

export function fetchFluVaccinesConfigFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_FLU_VACCINES_CONFIG, error);
}

// Flu vaccine tariffs
export const fluVaccineTariffActions = createTypeActions<ICompanyCodePayload, IFluVaccineTariffs>({
    type: FETCH_FLU_VACCINE_TARIFFS,
});

// Flu vaccines available
export const fluVaccinesAvailableActions = createTypeActions<{}, IFluVaccinesAvailable>({
    type: FETCH_FLU_VACCINES_AVAILABLE,
});

// Flu vaccines wizard
function navigateToFluVaccinesOrderWizardStep(payload: IFluVaccinesOrderWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW, payload);
}

export function triggerFluVaccinesOrderWizard() {
    return navigateToFluVaccinesOrderWizardStep({
        step: getOrderFluVaccinesWizardSteps().firstStepId,
        resetDataEntity: true,
    });
}

export function changeFluVaccinesOrderWizardStep(step: FLU_VACCINES_ORDER_WIZARD_STEP_ID) {
    return navigateToFluVaccinesOrderWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateFluVaccinesOrderWizardEntity(payload: Partial<IFluVaccinesOrderWizardEntity>) {
    return createAction<object>(
        UPDATE_FLU_VACCINES_ORDER_WIZARD_ENTITY,
        payload,
    );
}

// Create flu vaccines order
export const createFluVaccinesOrderActions = createTypeActions<ICreateFluVaccinesOrder>({
    type: CREATE_FLU_VACCINES_ORDER,
});

// Flu vaccines order update
export const updateFluVaccinesOrderActions = createTypeActions<IUpdateFluVaccinesOrderActionPayload>({
    type: UPDATE_FLU_VACCINES_ORDER,
});
