import { IApiError } from '../../models/general/error';
import { ITranslator } from '../../models/general/i18n';

export function translateApiError(translator: ITranslator, error: IApiError): string {
    // Timeout has occured
    if (error && (error.code === -1 || error.code === 504)) {
        return translator('error.timeout');
    }
    if (error && error.code === 500) {
        return translator('error.unexpected.title');
    }
    if (error && error.message) {
        return translator({ msg: error.message, placeholders: error.extraData });
    }
    return translator('error.unexpected.title');
}
