import { createEpic } from '../../index';
import {
    getSelectedSeatCompanyCode,
} from '../../company/selected/selectors';
import {
    fetchContacts,
} from '../../company/info/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { getInterventionRequestWizardStepId } from './selectors';
import {
    IInterventionRequestWizardPayload, INTERVENTION_REQUEST_WIZARD_STEP_ID,
    ICreateInterventionRequest,
} from '../../../models/interventions/requestIntervention';
import { triggerInterventionRequestWizard, createInterventionRequestActions } from './actions';
import { CREATE_INTERVENTION_REQUEST } from './types';
import { mapPrestationCodeToSubmittedFormActionType }
    from '../../../utils/logging/analytics/mapToSubmittedFormActionType';
import { getRequestInterventionWizardSteps } from '../../../config/navigation/wizardStepsMap';

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validateInterventionRequestWizardStepIdEpic
createEpic<IInterventionRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const requestedPrestationCode = action.payload.prestationCode;
        const INTERVENTION_REQUEST_STEP_IDS = getRequestInterventionWizardSteps().stepIds;
        if (!INTERVENTION_REQUEST_STEP_IDS.includes(requestedStep)) {
            return next(triggerInterventionRequestWizard(requestedPrestationCode));
        }

        // check no step skipped
        const currentStep = getInterventionRequestWizardStepId(getState());
        const currentStepIndex = INTERVENTION_REQUEST_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = INTERVENTION_REQUEST_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(triggerInterventionRequestWizard(requestedPrestationCode));
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringInterventionRequestWizardEpic
createEpic<IInterventionRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW,
    async processMultiple({ action, getState, api }, dispatch, done) {
        const { step } = action.payload;

        if (step === INTERVENTION_REQUEST_WIZARD_STEP_ID.CONTACT) {
            dispatch(fetchContacts());

            return done();
        }

        done();
    },
    latest: false,
});

// createInterventionRequestEpic
createEpic<ICreateInterventionRequest>({
    onActionType: CREATE_INTERVENTION_REQUEST,
    async processReturn({ action, getState, api }) {
        try {
            const companyCode = getSelectedSeatCompanyCode(getState());

            await api.interventions.requestIntervention.createInterventionRequest(companyCode, action.payload);

            return createInterventionRequestActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: () =>
                        mapPrestationCodeToSubmittedFormActionType(action.payload.prestationCode),
                },
            );
        } catch (error) {
            return createInterventionRequestActions.failed(error);
        }
    },
    latest: false,
});
