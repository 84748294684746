import { IOnActionTypeRegex } from '../utils/libs/redux/epic/typings';

export const STATE_STORAGE_KEY = 'MNSR_KLNTZN';

export const ON_ACTION_TYPE_REGEX: { [key: string]: IOnActionTypeRegex } = {
    ALL_ROUTE_ACTIONS: {
        pattern: '^R_.+',
    },
};

export enum REDUCER_KEYS {
    AUTH = 'auth',
    COMPANIES = 'companies',
    COMPANY_DOCUMENTS = 'company_documents',
    COMPANY_FUNCTIONS = 'company_functions',
    COMPANY_INFO = 'company_info',
    COMPANY_SELECTED = 'company_selected',
    COMPANY_USERS = 'company_users',
    COMPANY_VISITS = 'company_visits',
    CONSTANTS = 'constants',
    CONTACT = 'contact',
    COOKIE_CONSENT = 'cookie_consent',
    DOCUMENT_CENTER_COURSES = 'document_center_courses',
    DOCUMENT_CENTER_MEDICAL_DOCUMENTS = 'document_center_medical_documents',
    DOCUMENT_CENTER_RISK_MANAGEMENT_DOCUMENTS = 'document_center_risk_management_documents',
    DOCUMENT_CENTER_WORKPOSTCARDS = 'document_center_workpostcards',
    DRAG_AND_DROP = 'drag_and_drop',
    EMPLOYEES = 'employees',
    EMPLOYEE_CONVOCATION = 'employee_convocation',
    EMPLOYEE_INFO = 'employee_info',
    EMPLOYEE_WIZARD = 'employee_wizard',
    ENTITIES = 'entities',
    FLASH_MESSAGES = 'flash_messages',
    I18N = 'i18n',
    INBOX = 'inbox',
    INTERVENTION_BUFFERZONES = 'intervention_bufferzones',
    INTERVENTION_EXECUTED = 'intervention_executed',
    INTERVENTION_FLUVACCINES = 'intervention_fluVaccines',
    INTERVENTIONS_POLICYADVICE = 'interventions_policyAdvice',
    INTERVENTION_REQUEST = 'intervention_request',
    INTERVENTION_WORK_ACCIDENTS = 'intervention_work_accidents',
    INTERVENTION_VACCINATIONS = 'intervention_vaccinations',
    INVOICE = 'invoice',
    LEGAL = 'legal',
    LOCATION = 'location',
    MEDICALEXAMINATION = 'medicalExamination',
    NAVIGATION = 'navigation',
    NOTIFICATIONS = 'notifications',
    ONBOARDING = 'onboarding',
    PLANNING = 'planning',
    PREVENTION_UNITS = 'prevention_units',
    QUESTIONNAIRES = 'questionnaires',
    UI = 'ui',
    UI_HISTORY = 'ui_history',
}
