import localStorage from '@snipsonian/browser/es/storage/localStorage';

export function onStorageUpdatedByOtherBrowserTabOrWindow({
    storageKeyToWatch,
    handler,
}: {
    storageKeyToWatch: string;
    handler: (newValue: string) => void;
}) {
    if (!localStorage.isSupported) {
        return;
    }

    window.addEventListener('storage', (event: StorageEvent) => {
        if (event.key === storageKeyToWatch) {
            handler(event.newValue);
        }
    });
}
