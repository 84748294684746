import React, { PureComponent } from 'react';
import './add-external-employee.scss';
import { ICourseExternalEmployee } from '../../../../../../../models/documentCenter/courses';
import Translate from '../../../../../../common/Translate';
import Form, { IFormRenderProps } from '../../../../../../common/forms/Form';
import { SLIDEOUTPANEL_CLASSES } from '../../../../../../common/widget/SlideOutPanel';
import { schema, fields, FormValues } from './addExternalEmployeeSchema';
import FloatableTextInputWrapper from '../../../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../../../common/input/TextInput';
import RequiredMarker from '../../../../../../common/input/RequiredMarker';
import FormFieldError from '../../../../../../common/forms/FormFieldError';
import TranslatorContext from '../../../../../../appShell/contexts/TranslatorContext';
import ConstantsTypeahead from '../../../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../../../models/general/constants';
import SubmitButton from '../../../../../../common/buttons/SubmitButton';
import DatePicker from '../../../../../../common/widget/DateTimePicker/DatePicker/index';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../../../../utils/formatting/formatPhone';

interface IPublicProps {
    employee?: ICourseExternalEmployee;
    onClose: () => void;
    onSucces: (employee: ICourseExternalEmployee) => void;
}

const CLASS_NAME = 'AddExternalEmployee';
const FORM_NAME = 'add-external-employee-form';

export default class AddExternalEmployee extends PureComponent<IPublicProps> {
    private resetForm: (newValues: object) => void;
    private submitForm: () => void;

    constructor(props) {
        super(props);

        this.onAddEmployee = this.onAddEmployee.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
    }

    public render() {
        return (
            <TranslatorContext.Consumer>
                {({ translator }) => {
                    // eslint-disable-next-line max-len
                    const firstnamePlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.firstname');
                    // eslint-disable-next-line max-len
                    const namePlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.name');
                    // eslint-disable-next-line max-len
                    const birthdatePlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.birthdate');
                    // eslint-disable-next-line max-len
                    const phonePlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.phone');
                    // eslint-disable-next-line max-len
                    const emailPlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.email');
                    // eslint-disable-next-line max-len
                    const functionPlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.function');
                    // eslint-disable-next-line max-len
                    const languagePlaceholder = translator('document_center.courses.new.steps.employee.add_external.fields.language');

                    return (
                        <div className={CLASS_NAME}>
                            <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                                <h2>
                                    <Translate
                                        msg={'document_center.courses.new.steps.employee.add_external.title'}
                                    />
                                </h2>
                            </header>
                            <Form
                                name={FORM_NAME}
                                handleSubmit={this.onAddEmployee}
                                initialValues={this.getInitialValues()}
                                schema={schema}
                                render={({
                                    values, touched, errors, setFieldValue, handleChange, resetForm,
                                    submitForm,
                                }: IFormRenderProps<FormValues>) => {
                                    if (!this.resetForm) {
                                        this.resetForm = resetForm;
                                    }
                                    if (!this.submitForm) {
                                        this.submitForm = submitForm;
                                    }
                                    return (
                                        <>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="add-external-employee-first-name"
                                                    name={fields.firstName}
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    isInvalid={touched.firstName && !!errors.firstName}
                                                />
                                                <label htmlFor="add-external-employee-first-name">
                                                    {firstnamePlaceholder}
                                                    <RequiredMarker />
                                                </label>
                                                {touched.firstName &&
                                                    <FormFieldError
                                                        error={errors.firstName}
                                                        placeholders={{
                                                            fieldName: firstnamePlaceholder,
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="add-external-employee-name"
                                                    name={fields.name}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={touched.name && !!errors.name}
                                                />
                                                <label htmlFor="add-external-employee-name">
                                                    {namePlaceholder}
                                                    <RequiredMarker />
                                                </label>
                                                {touched.name &&
                                                    <FormFieldError
                                                        error={errors.name}
                                                        placeholders={{
                                                            fieldName: namePlaceholder,
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <DatePicker
                                                    id="add-external-employee-birth-date"
                                                    placeholder={birthdatePlaceholder}
                                                    value={values.birthDate}
                                                    name={fields.birthDate}
                                                    onChange={(value) => setFieldValue('birthDate', value)}
                                                    isInvalid={touched.birthDate && !!errors.birthDate}
                                                >
                                                    <label htmlFor="add-external-employee-birth-date">
                                                        {birthdatePlaceholder}
                                                        <RequiredMarker />
                                                    </label>
                                                </DatePicker>
                                                {touched.birthDate && (
                                                    <FormFieldError
                                                        error={errors.birthDate}
                                                        placeholders={{ fieldName: birthdatePlaceholder }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="add-external-employee-function"
                                                    name={fields.functionDescription}
                                                    value={values.functionDescription}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        touched.functionDescription && !!errors.functionDescription
                                                    }
                                                />
                                                <label htmlFor="add-external-employee-function">
                                                    {functionPlaceholder}
                                                    <RequiredMarker />
                                                </label>
                                                {touched.functionDescription &&
                                                    <FormFieldError
                                                        error={errors.functionDescription}
                                                        placeholders={{
                                                            fieldName: functionPlaceholder,
                                                        }}
                                                    />
                                                }
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <ConstantsTypeahead
                                                    id="add-external-employee-language"
                                                    constantType={ConstantType.LANGUAGES}
                                                    name={fields.languageId}
                                                    placeholder={languagePlaceholder}
                                                    value={values.languageId}
                                                    onItemSelected={(value) => setFieldValue('languageId', value)}
                                                    isInvalid={touched.languageId && !!errors.languageId}
                                                >
                                                    <label htmlFor="add-external-employee-language">
                                                        <Translate
                                                            msg={languagePlaceholder}
                                                        />
                                                        <RequiredMarker />
                                                    </label>
                                                </ConstantsTypeahead>
                                                {touched.languageId && (
                                                    <FormFieldError
                                                        error={errors.languageId}
                                                        placeholders={{ fieldName: languagePlaceholder }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="add-external-employee-email"
                                                    name={fields.email}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={touched.email && !!errors.email}
                                                />
                                                <label htmlFor="add-external-employee-email">
                                                    {emailPlaceholder}
                                                    <RequiredMarker />
                                                </label>
                                                {touched.email &&
                                                    <FormFieldError
                                                        error={errors.email}
                                                        placeholders={{
                                                            fieldName: emailPlaceholder,
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="add-external-employee-phone"
                                                    name={fields.phone}
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={touched.phone && !!errors.phone}
                                                />
                                                <label htmlFor="add-external-employee-phone">
                                                    {phonePlaceholder}
                                                    <RequiredMarker />
                                                </label>
                                                {touched.phone &&
                                                    <FormFieldError
                                                        error={errors.phone}
                                                        placeholders={{
                                                            fieldName: phonePlaceholder,
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                            <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                                <SubmitButton
                                                    id="add-external-employee-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    {/*eslint-disable-next-line max-len*/}
                                                    <Translate msg="document_center.courses.new.steps.employee.add_external.submit" />
                                                </SubmitButton>
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }

    private onAddEmployee(employee: FormValues) {
        const { functionDescription, ...fields } = employee;
        this.props.onSucces({
            ...fields,
            function: {
                description: functionDescription,
            },
            phone: tryFormattingPhoneForBackend(employee.phone),
        });
    }

    private getInitialValues(): FormValues {
        const { employee } = this.props;
        if (employee) {
            const { function: employeeFunction, ...employeeFields } = employee;
            return {
                ...employeeFields,
                functionDescription: employeeFunction.description,
                phone: tryFormattingPhoneInternational(employee.phone),
            };
        }

        return {
            firstName: '',
            name: '',
            birthDate: '',
            functionDescription: '',
            languageId: null,
            email: '',
            phone: '',
        };
    }
}
