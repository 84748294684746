import { get } from '../../utils/api/requestWrapper';
import { IPerformanceDashboardTokenData, IPerformanceDashboardAccessData } from '../../models/general/performance';
import { ICompanyCodePayload } from '../../models/admin/company';

export const URL = {
    TOKEN: '/ecia/{companyCode}/token',
    ACCESS: '/ecia/{companyCode}/access',
};

export function fetchPerformanceDashboardToken({ companyCode }: ICompanyCodePayload) {
    return get<IPerformanceDashboardTokenData>({
        url: URL.TOKEN,
        pathParams: {
            // BFF expects main seat company code without .1
            companyCode: companyCode.slice(0, companyCode.length - 2),
        },
    });
}

export function fetchPerformanceDashboardAccess({ companyCode }: ICompanyCodePayload) {
    return get<IPerformanceDashboardAccessData>({
        url: URL.ACCESS,
        pathParams: {
            // BFF expects main seat company code without .1
            companyCode: companyCode.slice(0, companyCode.length - 2),
        },
    });
}
