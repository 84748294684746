import { IDocument } from '../../../models/general/documents';

export function areDocumentsEqual(document: IDocument, otherDocument: IDocument) {
    if (!document || !otherDocument) {
        return false;
    }

    return document.data === otherDocument.data && document.filename === otherDocument.filename;
}

export function areFilesEqual(file: File, otherFile: File) {
    if (!file || !otherFile) {
        return false;
    }

    return file.name === otherFile.name && file.lastModified === otherFile.lastModified;
}
