import React, { PureComponent } from 'react';
import './flu-vaccines-footer.scss';
import { connect } from '../../../index';
import { getFluVaccinesConfig, showFluVaccinesCampaign } from '../../../../redux/intervention/fluVaccine/selectors';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import ROUTE_KEYS from '../../../../routeKeys';
import { IFluVaccinesConfig } from '../../../../models/interventions/fluVaccines';
import Icon from '../../../common/icons/Icon';

export interface IPrivateProps {
    fluVaccinesConfig: IFluVaccinesConfig;
    showFluVaccinesCampaign: boolean;
}

const CLASS_NAME = 'FluVaccinesFooter';
const FLU_VACCINES_ROUTE = ROUTE_KEYS.R_FLU_VACCINES_ORDERS;

class FluVaccinesFooter extends PureComponent<IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { fluVaccinesConfig, showFluVaccinesCampaign } = this.props;

        return showFluVaccinesCampaign && (
            <div className={CLASS_NAME}>
                <LinkToRoute
                    id="to-flu-vaccines-tile"
                    to={FLU_VACCINES_ROUTE}
                >
                    <div className={`${CLASS_NAME}__inner`}>
                        <figure style={{ backgroundImage: `url(${fluVaccinesConfig.image.url})` }}>
                            <img src={fluVaccinesConfig.image.url} />
                        </figure>
                        <div
                            className={`${CLASS_NAME}__description`}
                            dangerouslySetInnerHTML={{ __html: fluVaccinesConfig.description }}
                        />
                        <Icon typeName="chevron-right" />
                    </div>
                </LinkToRoute>
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            fluVaccinesConfig: getFluVaccinesConfig(state),
            showFluVaccinesCampaign: showFluVaccinesCampaign(state),
        };
    },
})(FluVaccinesFooter);
