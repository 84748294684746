import { get } from '../../utils/api/requestWrapper';
import { ICaseManager } from '../../models/contact/caseManager';
import { ICompanyCodePayload } from '../../models/admin/company';

export const URL = {
    CASE_MANAGER: '/companies/{companyCode}/case-manager',
};

export function fetchCaseManager({ companyCode }: ICompanyCodePayload) {
    return get<ICaseManager>({
        url: URL.CASE_MANAGER,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response.caseManager,
    });
}
