import React, { Component } from 'react';
import './work-post-card-protection.scss';
import { connect } from '../../../index';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import { getWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/selectors';
import { IWorkPostCardWizardEntity, IWorkClothes } from '../../../../models/documentCenter/workPostCards';
import { schema, fields } from './protectionSchema';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import { createFormTextInput } from '../../../common/forms/FormTextInput';
import { createFormCheckbox } from '../../../common/forms/FormCheckbox';

export type FormValues = IWorkClothes;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    updateWizardEntity: (values: FormValues) => void;
}

const FORM_NAME = 'work-post-card-protection';
const CLASS_NAME = 'WorkPostCardProtection';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.protection';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

const ProtectionFormTextInput = createFormTextInput<FormValues>();
const ProtectionFormCheckbox = createFormCheckbox<FormValues>();

class Protection extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            renderStepButtons, wizardEntity, goToNextStep, updateWizardEntity,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            glasses: wizardEntity.workClothes && wizardEntity.workClothes.glasses || false,
            gloves: wizardEntity.workClothes && wizardEntity.workClothes.gloves || false,
            helmet: wizardEntity.workClothes && wizardEntity.workClothes.helmet || false,
            keel: wizardEntity.workClothes && wizardEntity.workClothes.keel || false,
            mask: wizardEntity.workClothes && wizardEntity.workClothes.mask || false,
            ointments: wizardEntity.workClothes && wizardEntity.workClothes.ointments || false,
            otherProtection: wizardEntity.workClothes && wizardEntity.workClothes.otherProtection || '',
            overall: wizardEntity.workClothes && wizardEntity.workClothes.overall || false,
            seatbelt: wizardEntity.workClothes && wizardEntity.workClothes.seatbelt || false,
            shells: wizardEntity.workClothes && wizardEntity.workClothes.shells || false,
            shoes: wizardEntity.workClothes && wizardEntity.workClothes.shoes || false,
            specificEquipment: wizardEntity.workClothes && wizardEntity.workClothes.specificEquipment || false,
            trousers: wizardEntity.workClothes && wizardEntity.workClothes.trousers || false,
        };

        function handleSubmit(values: FormValues) {
            updateWizardEntity(values);
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {

                            return (
                                <>
                                    <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="trousers"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="helmet"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="overall"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="mask"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="keel"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="glasses"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="shoes"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="shells"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="seatbelt"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="ointments"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <div className={`${CLASS_NAME}__checkbox_row`}>
                                            <ProtectionFormCheckbox
                                                name="gloves"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                            <ProtectionFormCheckbox
                                                name="specificEquipment"
                                                fields={fields}
                                                baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                formRenderProps={formRenderProps}
                                                size="small"
                                            />
                                        </div>
                                        <ProtectionFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'otherProtection'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                                disabled: false,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateWorkPostCardWizardEntity({
                    workClothes: {
                        ...values,
                    },
                }));
            },
        };
    },
})(Protection);
