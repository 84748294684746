import {
    DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
} from '../../../../api/interventions/companyVisits/planned/planned.const';
import { formatCompanyWithCity } from '../../../../utils/formatting/formatCompany';
import { formatDateForDisplay, formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import { getPlannedCompanyVisitTimeFormatted } from '../../../../utils/interventions/companyVisits';
import { IPlannedCompanyVisit } from '../../../../models/interventions/company-visits';
import { ITranslator } from '../../../../models/general/i18n';
import { ListItem } from '../../../../models/general/list';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
} from '../../../common/widget/MasterWithDetail/typings';

import { DEFAULT_NR_OF_RECORDS_TO_SHOW } from './planned.const';
import { IPlannedCompanyVisitsColumnNames, IPlannedCompanyVisitsFilterValues } from './planned.type';

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
export const toListId = (companyVisit: IPlannedCompanyVisit) => {
    return `${companyVisit.activityId}_${companyVisit.start}_${companyVisit.end}`;
};

export const mapCompanyVisitsToListItems = (
    masterData: IPlannedCompanyVisit[],
    translator: ITranslator,
): ListItem<IPlannedCompanyVisitsColumnNames>[] => {
    return masterData
        .map((companyVisit) => ({
            id: toListId(companyVisit),
            columns: {
                visitDate: formatDateInLongFormat(companyVisit.start),
                visitDateTime: getPlannedCompanyVisitTimeFormatted(companyVisit, translator),
                visitDateSort: companyVisit.start,
                visitCode: companyVisit.visitReason.code,
                company: companyVisit.branch.city
                    ? formatCompanyWithCity(companyVisit.branch.name, companyVisit.branch.city)
                    : companyVisit.branch.name,
                visitor: formatPersonName({
                    firstName: companyVisit.visitor.firstName,
                    name: companyVisit.visitor.lastName,
                }),
                description: companyVisit.visitReason.description,
            },
        }));
};

export function mapListRowForExport(companyVisit: IPlannedCompanyVisit) {
    const {
        visitReason: { code, description },
        start,
        branch: { name, code: companyCode },
        visitor,
    } = companyVisit;

    return {
        'Company code': companyCode,
        'Company name': name,
        Employee: formatPersonName({
            firstName: visitor.firstName,
            name: visitor.lastName,
        }),
        'Visit Code': code,
        'Visit Date': start,
        'Visit Kind': description,
    };
}

export const shouldRenderShowAllButton = (
    shouldRenderProps: IShouldRenderShowAllButtonProps<
        ListItem<IPlannedCompanyVisitsColumnNames>[],
        IPlannedCompanyVisitsFilterValues
    >,
) => {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
};

export const getDefaultQueryParams = ({ isShowAll }: { isShowAll: boolean }) => {
    return isShowAll ? {
        ...DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
        isShowAll,
    } : DEFAULT_PLANNED_COMPANY_VISITS_FILTERS;
};

export const transformFilterValuesToActiveFilters = (
    transformProps: ITransformToActiveFiltersProps<
        ListItem<IPlannedCompanyVisitsColumnNames>[],
        IPlannedCompanyVisitsFilterValues
    >,
) => {
    return createGenericActiveFilters<IPlannedCompanyVisitsFilterValues, IPlannedCompanyVisitsColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.company_visits.planned.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.startDate,
            },
        },
    });
};
