import React from 'react';
import './disclaimer-modal.scss';
import { getQueryParams, getRouteKey, getRoutePayload } from '../../../../redux/location/selectors';
import { navigateTo } from '../../../../redux/location/actions';
import { connect } from '../../..';
import { DISCLAIMER_QUERY_PARAM } from '../route';
import Dialog from '../../../common/modals/Dialog';
import DisclaimerContent from '../DisclaimerContent';
import { getDisclaimerContentAsyncInfo } from '../../../../redux/legal/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';

const CLASS_NAME = 'DisclaimerModal';

interface IPrivateProps {
    isShown: boolean;
    onCloseIntent: () => void;
    disclaimerContentAsyncInfo: IAsyncFieldInfo;
}

function DisclaimerModal({ isShown, onCloseIntent, disclaimerContentAsyncInfo }: IPrivateProps) {
    return (
        <Dialog
            show={isShown}
            onCloseIntent={onCloseIntent}
            className={CLASS_NAME}
            size="large"
            showLoader={disclaimerContentAsyncInfo.status === AsyncStatus.Busy}
            showScrollToTopOfModalButton={true}
        >
            {(isShown || disclaimerContentAsyncInfo.status === AsyncStatus.Success) && (
                <DisclaimerContent />
            )}
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        isShown: Object.keys(getQueryParams(state)).includes(DISCLAIMER_QUERY_PARAM),
        disclaimerContentAsyncInfo: getDisclaimerContentAsyncInfo(state),
    }),
    dispatchProps: (dispatch, getState) => ({
        onCloseIntent: (() => {
            const state = getState();
            const query = getQueryParams(state) as { [key: string]: any };
            dispatch(navigateTo(
                getRouteKey(state),
                getRoutePayload(state),
                Object.keys(query)
                    .reduce(
                        (acc, currentKey) => {
                            if (currentKey === DISCLAIMER_QUERY_PARAM) {
                                return acc;
                            }

                            return {
                                ...acc,
                                [currentKey]: query[currentKey],
                            };
                        },
                        {},
                    ),
            ));
        }),
    }),
})(DisclaimerModal);
