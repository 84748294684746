import { createAction, createSuccessAction, createFailAction } from '../../index';
import {
    ADD_EMPLOYEE,
    UPDATE_ADD_EMPLOYEE_DATA,
    FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA,
    SET_ADD_EMPLOYEE_WIZARD_TO_UPDATE,
    UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
} from './types';
import { navigateTo } from '../../location/actions';
import {
    IEmployeeToAdd,
    IAddEmployeeWizardPayload,
    IFetchEmployeeByInszPayload,
    IUpdateEmployeeAllFieldsAndEmploymentSucceededPayload,
    IAddEmployeeSucceededPayload,
} from '../../../models/admin/employee';
import { ITraceableApiError } from '../../../models/general/error';
import ROUTE_KEYS from '../../../routeKeys';
import { createTypeActions, createResetAction } from '../../../utils/libs/redux/createAction';

// Fetch employee initial data

export function fetchEmployeeToAddInitialData(payload: IFetchEmployeeByInszPayload) {
    return createAction<IFetchEmployeeByInszPayload>(FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA, payload);
}

export function fetchEmployeeToAddInitialDataSucceeded(payload: Partial<IEmployeeToAdd>) {
    return createSuccessAction<Partial<IEmployeeToAdd>>(FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA, payload);
}

export function fetchEmployeeToAddInitialDataFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA, error);
}

export function resetAddEmployeeData() {
    return createResetAction(FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA, {});
}

// This action is used in the interim flow for updating an employees data and function/seat at the same time

export const updateEmployeeAllFieldsAndEmploymentActions =
    createTypeActions<{}, IUpdateEmployeeAllFieldsAndEmploymentSucceededPayload>({
        type: UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
    });

// Add employee

export const addEmployeeActions =
    createTypeActions<IEmployeeToAdd, IAddEmployeeSucceededPayload>({
        type: ADD_EMPLOYEE,
    });

// Add Employee Wizard Wizard

export function navigateToAddEmployeeWizardStep(payload: IAddEmployeeWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD, {
        step: payload.step,
    });
}

export function setAddEmployeeWizardToUpdate() {
    return createAction<object>(SET_ADD_EMPLOYEE_WIZARD_TO_UPDATE, {});
}

// update add-employee wizard object

export function updateAddEmployeeData(payload: Partial<IEmployeeToAdd>) {
    return createAction<Partial<IEmployeeToAdd>>(UPDATE_ADD_EMPLOYEE_DATA, payload);
}
