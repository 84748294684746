import { object } from 'yup';
import string from '../../../utils/libs/yup/string';
import { ErrorTypes } from '../../../models/general/error';
import {
    countryCodeIsBelgium,
    countryCodeIsNotBelgium,
    countryCodeIsBelgiumOrFalsy,
} from '../../../utils/validation/countryCodeIsBelgium';
import number from '../../../utils/libs/yup/number';
import street from '../../../utils/libs/yup/street';

export const fields = {
    countryCode: 'countryCode',
    zipCodeId: 'zipCodeId',
    street: 'street',
    number: 'number',
    box: 'box',
    postcode: 'postcode',
    city: 'city',
};

export const nationalAddressSchema = object({
    [fields.countryCode]: string().test(
        ErrorTypes.CountryNotSupported,
        'error.validation.country_not_supported',
        countryCodeIsBelgium,
    ),
    [fields.zipCodeId]: number().min(0).required(),
    [fields.street]: street().required(),
    [fields.number]: string().required(),
    [fields.box]: string(),
});

export const nationalAddressOptionalSchema = object({
    [fields.countryCode]: string().nullable(true).test(
        ErrorTypes.CountryNotSupported,
        'error.validation.country_not_supported',
        countryCodeIsBelgiumOrFalsy,
    ),
    [fields.zipCodeId]: string(),
    [fields.street]: street(),
    [fields.number]: string(),
    [fields.box]: string(),
});

export const internationalAddressSchema = object({
    [fields.countryCode]: string().required(),
    [fields.zipCodeId]: string().nullable(true)
        .when(fields.countryCode, { is: countryCodeIsBelgium, then: number().min(0).required() }),
    [fields.street]: string().required(),
    [fields.number]: string().required(),
    [fields.box]: string(),
    [fields.city]: string().nullable(true)
        .when(fields.countryCode, { is: countryCodeIsNotBelgium, then: string().required() }),
    [fields.postcode]: string().nullable(true)
        .when(fields.countryCode, { is: countryCodeIsNotBelgium, then: string().required() }),
});

export const internationalAddressOptionalSchema = object({
    [fields.countryCode]: string().nullable(true),
    [fields.zipCodeId]: string().nullable(true),
    [fields.street]: string().nullable(true),
    [fields.number]: string().nullable(true),
    [fields.box]: string().nullable(true),
    [fields.city]: string().nullable(true),
    [fields.postcode]: string().nullable(true),
});
