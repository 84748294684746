import React, { PureComponent } from 'react';
import { connect } from '../..';
import { ICompanySeat } from '../../../models/admin/company';
import CollapsibleItem from '../../common/widget/CollapsibleItem';
import { IMensuraContactPerson } from '../../../models/admin/companyInfo';
import ContactInformation from '../shared/ContactInformation';
import { getMensuraContactsForSeatSorted } from '../../../redux/contact/selectors';
import Translate from '../../common/Translate';
import { fetchMensuraContactsActions } from '../../../redux/contact/actions';

interface IPublicProps {
    seat: ICompanySeat;
}

interface IPrivateProps {
    seatContacts: IMensuraContactPerson[];
    fetchMensuraContactsForSeat: () => void;
}

class MensuraContactsCollapsible extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            seat,
            seatContacts,
            fetchMensuraContactsForSeat,
        } = this.props;
        return (
            <CollapsibleItem
                onOpen={seatContacts.length <= 0 ? fetchMensuraContactsForSeat : undefined}
                trigger={seat.company.name}
            >
                {
                    seatContacts.length > 0 ? (
                        <div className="ContactDashboard__contacts__list">
                            {seatContacts.map((contact: IMensuraContactPerson, index) => (
                                <ContactInformation
                                    key={`person-${index}`}
                                    contact={contact}
                                />
                            ))}
                        </div>
                    ) : <p><Translate msg="contact.overview.contacts.no_contacts_for_seat" /></p>
                }
            </CollapsibleItem>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsDeprecated: (state, publicProps) => {
        return {
            seatContacts: getMensuraContactsForSeatSorted(state, publicProps.seat.company.companyCode),
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        return {
            fetchMensuraContactsForSeat: () => {
                dispatch(fetchMensuraContactsActions.trigger({
                    companyCode: publicProps.seat.company.companyCode,
                }));
            },
        };
    },
})(MensuraContactsCollapsible);
