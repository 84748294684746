import React, { Component } from 'react';
import './HolidaysOverview/holidays-overview.scss';
import classNames from 'classnames';
import { connect } from '../../../index';
import {
    ICompanyHoliday,
    IUpdateCompanyHolidaysPayload,
} from '../../../../models/admin/companyInfo';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import {
    getCompanyHolidaysAsyncInfo,
    getCompanyHolidays,
    getUpdateCompanyHolidaysAsyncInfo,
} from '../../../../redux/company/info/selectors';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import TinyLoader from '../../../common/waiting/TinyLoader';
import OverlayContent from '../../../common/modals/OverlayContent';
import Loader from '../../../common/waiting/Loader';
import PageHeader from '../../../appShell/PageHeader';
import { updateCompanyHolidaysActions } from '../../../../redux/company/info/actions';
import { getSelectedCompanyCode } from '../../../../redux/company/selected/selectors';
import HolidaysOverview from './HolidaysOverview';
import GoBack from '../../../common/navigation/GoBack';
import ErrorDialog from '../../../common/modals/ErrorDialog';

const CLASS_NAME = 'Holidays';
const TRANSLATION_PREFIX = 'administration.company_info.holidays.overview';

export interface IWithinSidePanel {
    immediatelyShowOverlay: boolean;
    onSelectHolidays?: () => void; // needed when immediatelyShowOverlay=false
    closeOverlay?: () => void; // needed when immediatelyShowOverlay=true
}

interface IPublicProps {
    withinSidePanel?: IWithinSidePanel;
}

interface IPrivateProps {
    holidays: ICompanyHoliday[];
    mayEdit: boolean;
    updateAsyncInfo: IAsyncFieldInfo;
    updateCompanyHolidays: (updateHolidaysPayload: Pick<IUpdateCompanyHolidaysPayload, 'holidays'>) => void;
    resetUpdateCompanyHolidays: () => void;
}

interface IState {
    isEditHolidaysOpen: boolean;
}

class Holidays extends Component<IPrivateProps & IPublicProps, IState> {
    public constructor(props: IPrivateProps & IPublicProps) {
        super(props);

        this.state = {
            isEditHolidaysOpen: props.withinSidePanel && props.withinSidePanel.immediatelyShowOverlay,
        };
    }

    public componentDidUpdate(prevProps: IPrivateProps & IPublicProps) {
        const { withinSidePanel } = this.props;
        if (
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateAsyncInfo.status === AsyncStatus.Success
        ) {
            if (withinSidePanel && typeof withinSidePanel.closeOverlay === 'function') {
                withinSidePanel.closeOverlay();
            } else {
                this.setState({ isEditHolidaysOpen: false });
            }
        }
    }

    public render() {
        const {
            holidays, updateAsyncInfo, updateCompanyHolidays, resetUpdateCompanyHolidays, withinSidePanel,
        } = this.props;
        const { isEditHolidaysOpen } = this.state;

        if (withinSidePanel) {
            if (isEditHolidaysOpen) {
                return (
                    <div className={classNames(`${CLASS_NAME}__in_overlay`, 'container')}>
                        <GoBack
                            id="close-overlay"
                            onClick={withinSidePanel.closeOverlay}
                            iconTypeName="arrow-left"
                        />
                        <Loader show={updateAsyncInfo.status} />
                        <ErrorDialog
                            asyncInfo={updateAsyncInfo}
                            titleTranslationKey={`${TRANSLATION_PREFIX}.update_error.title`}
                            onCloseDialog={resetUpdateCompanyHolidays}
                        />
                        <PageHeader
                            title={`${TRANSLATION_PREFIX}.title`}
                            text={`${TRANSLATION_PREFIX}.text`}
                        />
                        <HolidaysOverview
                            holidays={holidays}
                            isEdit={true}
                            onSaveEdit={updateCompanyHolidays}
                            isWithinSidePanel={true}
                        />
                    </div>
                );
            }
            return (
                <TinyLoader asyncInfoSelector={getCompanyHolidaysAsyncInfo}>
                    <HolidaysOverview
                        holidays={holidays}
                        onOpenEdit={withinSidePanel.onSelectHolidays}
                        isWithinSidePanel={true}
                    />
                </TinyLoader>
            );
        }

        return (
            <div className={CLASS_NAME}>
                <TinyLoader asyncInfoSelector={getCompanyHolidaysAsyncInfo}>
                    <HolidaysOverview
                        holidays={holidays}
                        onOpenEdit={() => this.setState({ isEditHolidaysOpen: true })}
                    />
                </TinyLoader>

                <OverlayContent
                    show={isEditHolidaysOpen}
                    onCloseIntent={() => this.setState({ isEditHolidaysOpen: false })}
                >
                    {isEditHolidaysOpen && (
                        <>
                            <Loader show={updateAsyncInfo.status} />
                            <ErrorDialog
                                asyncInfo={updateAsyncInfo}
                                titleTranslationKey={`${TRANSLATION_PREFIX}.update_error.title`}
                                onCloseDialog={resetUpdateCompanyHolidays}
                            />
                            <PageHeader
                                title={`${TRANSLATION_PREFIX}.title`}
                                text={`${TRANSLATION_PREFIX}.text`}
                            />
                            <HolidaysOverview
                                holidays={holidays}
                                isEdit={true}
                                onSaveEdit={updateCompanyHolidays}
                            />
                        </>
                    )}
                </OverlayContent>
            </div>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            holidays: getCompanyHolidays(state),
            mayEdit: hasRequiredAccessLevels(state, { company: 'W' }),
            updateAsyncInfo: getUpdateCompanyHolidaysAsyncInfo(state),
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                updateCompanyHolidays: (updateHolidaysPayload: Pick<IUpdateCompanyHolidaysPayload, 'holidays'>) => {
                    dispatch(updateCompanyHolidaysActions.trigger({
                        companyCode: getSelectedCompanyCode(getState()),
                        holidays: updateHolidaysPayload.holidays,
                    }));
                },
                resetUpdateCompanyHolidays: () => {
                    dispatch(updateCompanyHolidaysActions.reset({}));
                },
            };
        };
    },
})(Holidays);
