import React, { CSSProperties, StatelessComponent } from 'react';
import EventWrapper from 'react-big-calendar/lib/EventWrapper';
import classNames from 'classnames';
import { EventWrapperProps } from 'react-big-calendar';
import { ICalendarEvent, CalendarEventType } from '../../../../../models/ui/calendar';
import FreeTimeSlot from '../Events/planning/FreeTimeSlot';
import DefaultEvent from '../Events/common/DefaultEvent';
import MedicalExaminationPlanning from '../Events/planning/MedicalExamination';
import MedicalExaminationAgenda from '../Events/agenda/MedicalExamination';
import isTouchDevice from '../../../../../utils/dom/isTouchDevice';
import { isSmallScreen } from '../../../../../utils/dom/screenSize';
import DroppableFreeTimeSlot from '../Events/planning/DroppableFreeTimeSlot';
import DraggableMedicalExamination from '../Events/planning/DraggableMedicalExamination';

interface ITimeGridEventProps extends EventWrapperProps<ICalendarEvent> {
    onSelect?: (event: ICalendarEvent, e: React.SyntheticEvent<HTMLElement>) => void;
}

export interface IEventProps {
    event: ICalendarEvent;
    label: string;
    title: string;
    className: string;
    selected: boolean;
    continuesEarlier: boolean;
    continuesLater: boolean;
}

function TimeGridEvent(props: ITimeGridEventProps) {
    const {
        style,
        className,
        event,
        accessors,
        isRtl,
        selected,
        label,
        getters,
        onClick,
        onDoubleClick,
        onSelect,
    } = props;

    const tooltip = accessors.tooltip(event);
    const end = accessors.end(event);
    const start = accessors.start(event);

    const userProps = getters.eventProp(event, start, end, selected);

    const cssClassName = classNames(className, {
        'rbc-event-expanded': selected && (isTouchDevice() || isSmallScreen()),
    });
    let inlineStyles: CSSProperties = {};
    if (style) {
        const { height, top, xOffset, width } = style;
        inlineStyles = {
            position: 'absolute',
            top: `${top}%`,
            height: `${height}%`,
            [isRtl ? 'right' : 'left']: `${Math.max(0, xOffset)}%`,
            width: `${width}%`,
            maxWidth: event.overlappingEventsCount && event.overlappingEventsCount > 0
                ? `${100 / event.overlappingEventsCount}%`
                : null,
        };
    }

    function onClickAllDayEvent(e: React.SyntheticEvent<HTMLElement>) {
        onSelect(event, e);
    }

    return (
        <EventWrapper type="time" {...props}>
            <div
                onClick={onClick || onClickAllDayEvent}
                onDoubleClick={onDoubleClick}
                style={{
                    ...userProps.style,
                    ...inlineStyles,
                }}
                title={
                    tooltip
                        ? (typeof label === 'string' ? label + ': ' : '') + tooltip
                        : undefined
                }
                className={cssClassName}
            >
                {renderEventType(props)}
            </div>
        </EventWrapper>
    );
}

function renderEventType(props: EventWrapperProps<ICalendarEvent>) {
    const { event, accessors, continuesEarlier, continuesLater, selected, getters } = props;

    const title = accessors.title(event);
    const end = accessors.end(event);
    const start = accessors.start(event);
    const userProps = getters.eventProp(event, start, end, selected);

    const eventProps: IEventProps = {
        event,
        title,
        label: props.label,
        continuesEarlier,
        continuesLater,
        selected,
        className: userProps.className,
    };

    if (event.type === CalendarEventType.DraggableMedicalExamination) {
        return <DraggableMedicalExamination {...eventProps} />;
    }

    if (event.type === CalendarEventType.DroppableFreeTimeslot) {
        return <DroppableFreeTimeSlot {...eventProps} />;
    }

    if (event.type === CalendarEventType.FreeTimeslot) {
        return <FreeTimeSlot {...eventProps} />;
    }

    if (isManualPlanningMedicalExaminationEvent(event)) {
        return <MedicalExaminationPlanning {...eventProps} />;
    }

    if (
        event.type === CalendarEventType.MedicalExamination ||
        event.type === CalendarEventType.MedicalExaminationInCompany
    ) {
        return <MedicalExaminationAgenda {...eventProps} />;
    }

    return <DefaultEvent {...eventProps} />;
}

function isManualPlanningMedicalExaminationEvent(event: ICalendarEvent) {
    return (
        (
            event.type === CalendarEventType.MedicalExamination ||
            event.type === CalendarEventType.MedicalExaminationInCompany
        ) &&
        event.isConcatenated
    );
}

export default TimeGridEvent as StatelessComponent<ITimeGridEventProps>;
