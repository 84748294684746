import React from 'react';
import './sidebar-cta.scss';
import Translate from '../../../../common/Translate';
import Button from '../../../../common/buttons/Button';

const CLASS_NAME = 'SidebarCta';

interface ISideBarCtaProps {
    titleTranslationKey: string;
    descriptionTranslationKey?: string;
    buttonTranslationKey: string;
    buttonOnClickHandler: () => void;
}

export function SideBarCta(props: ISideBarCtaProps) {
    const {
        titleTranslationKey,
        descriptionTranslationKey,
        buttonTranslationKey,
        buttonOnClickHandler,
    } = props;

    return (
        <div className={CLASS_NAME}>
            <h5 className={`${CLASS_NAME}__title`}>
                <Translate msg={titleTranslationKey} />
            </h5>
            {descriptionTranslationKey && (
                <p>
                    <Translate msg={descriptionTranslationKey} />
                </p>
            )}
            <Button
                id="sidebar-cta"
                typeName="secondary"
                size="small"
                onClick={buttonOnClickHandler}
            >
                <Translate msg={buttonTranslationKey} />
            </Button>
        </div>
    );
}
