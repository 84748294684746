import { object } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../../../models/ui/form';
import string from '../../../../../../utils/libs/yup/string';
import { internationalAddressSchema } from '../../../../../common/address/schema';

export const fields: ShapeOf<FormValues> = {
    name: 'name',
    address: 'address',
};

export const schema = object({
    [fields.name]: string().required(),
    [fields.address]: internationalAddressSchema.required(),
});
