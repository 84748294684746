import React from 'react';
import Wizard from '../../common/navigation/Wizard/index';
import { IStepperStep } from '../../../models/general/stepper';
import Contact from './wizard/Contact';
import Remarks from './wizard/Remarks';
import Validate from './wizard/Validate';
import { INTERVENTION_REQUEST_WIZARD_STEP_ID } from '../../../models/interventions/requestIntervention';
import { changeInterventionRequestWizardStep } from '../../../redux/intervention/requestIntervention/actions';
import { getInterventionRequestWizardStepId } from '../../../redux/intervention/requestIntervention/selectors';
import Intervention from './wizard/Intervention';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';

const INTERVENTION_REQUEST_STEPS: IStepperStep[] = [{
    id: INTERVENTION_REQUEST_WIZARD_STEP_ID.INTERVENTION,
    translationKey: 'interventions.intervention_request_new.steps.intervention.step_label',
    renderStepContent: (props) => <Intervention {...props}/>,
}, {
    id: INTERVENTION_REQUEST_WIZARD_STEP_ID.CONTACT,
    translationKey: 'interventions.intervention_request_new.steps.contact.step_label',
    renderStepContent: (props) => <Contact {...props}/>,
}, {
    id: INTERVENTION_REQUEST_WIZARD_STEP_ID.REMARKS,
    translationKey: 'interventions.intervention_request_new.steps.remarks.step_label',
    renderStepContent: (props) => <Remarks {...props}/>,
}, {
    id: INTERVENTION_REQUEST_WIZARD_STEP_ID.VALIDATE,
    translationKey: 'interventions.intervention_request_new.steps.validate.step_label',
    renderStepContent: (props) => <Validate {...props}/>,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.REQUEST_INTERVENTION,
    steps: INTERVENTION_REQUEST_STEPS,
});

export default function InterventionRequestWizard() {
    return (
        <div className="InterventionRequestWizard">
            <Wizard
                activeStepIdSelector={getInterventionRequestWizardStepId}
                navigateToStepActionCreator={changeInterventionRequestWizardStep}
                steps={INTERVENTION_REQUEST_STEPS}
            />
        </div>
    );
}
