import ROUTE_KEYS from '../../../routeKeys';
import { navigateTo } from '../../location/actions';
import {
    WORK_POST_CARD_WIZARD_STEP_ID,
    IWorkPostCardWizardPayload,
    IWorkPostCardWizardEntity,
    IWorkPostCardBlankForms,
    IWorkPostCardDefaultValues,
    IWorkPostFile,
} from '../../../models/documentCenter/workPostCards';
import {
    UPDATE_WORK_POST_CARD_WIZARD_ENTITY,
    FETCH_WORK_POST_CARD_BLANK_FORMS,
    CREATE_WORK_POST_CARD_FORM,
    FETCH_WORK_POST_CARD_DEFAULT_VALUES,
    RESET_WORK_POST_CARD_WIZARD,
    FETCH_WORK_POST_FILE_OF_EMPLOYEE,
} from './types';
import { createAction, createTypeActions } from '../../../utils/libs/redux/createAction';
import { Locales } from '../../../config/i18n.config';
import { IDocument } from '../../../models/general/documents';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';
import { getCreateWorkPostCardWizardSteps } from '../../../config/navigation/wizardStepsMap';

// Work post card wizard
function navigateToWorkPostCardWizardStep(payload: IWorkPostCardWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_WORK_POST_CARD_NEW, payload);
}

export function triggerWorkPostCardWizard(functionId: number, locale: Locales) {
    return navigateToWorkPostCardWizardStep({
        step: getCreateWorkPostCardWizardSteps().firstStepId,
        resetDataEntity: true,
        functionId,
        locale,
    });
}

export function changeWorkPostCardWizardStep(step: WORK_POST_CARD_WIZARD_STEP_ID) {
    return navigateToWorkPostCardWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateWorkPostCardWizardEntity(payload: Partial<IWorkPostCardWizardEntity>) {
    return createAction<object>(
        UPDATE_WORK_POST_CARD_WIZARD_ENTITY,
        payload,
    );
}

export function resetWorkPostCardWizard() {
    return createAction(RESET_WORK_POST_CARD_WIZARD, {});
}

// Fetch work post card blank forms
export const fetchWorkPostCardBlankFormsActions = createTypeActions<{}, IWorkPostCardBlankForms>({
    type: FETCH_WORK_POST_CARD_BLANK_FORMS,
});

// Create work post card form
export const createWorkPostCardFormActions = createTypeActions<{}, IDocument>({
    type: CREATE_WORK_POST_CARD_FORM,
    onSuccess: (payload) => {
        presentDownloadedFile(payload);
    },
});

// Fetch work post card default values
export const fetchWorkPostCardDefaultValuesActions =
    createTypeActions<{}, IWorkPostCardDefaultValues>({
        type: FETCH_WORK_POST_CARD_DEFAULT_VALUES,
    });

// Fetch work post card of employee
export const fetchWorkPostFileOfEmployeeActions =
    createTypeActions<{}, IWorkPostFile>({
        type: FETCH_WORK_POST_FILE_OF_EMPLOYEE,
    });
