import React, { PureComponent, MouseEvent } from 'react';
import { connect } from '../../../../../../index';
import Translate from '../../../../../../common/Translate';
import ConfirmationDialog from '../../../../../../common/modals/ConfirmationDialog';
import {
    getRemoveTimeslotAsyncInfo,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/selectors';
import {
    IMedicalExaminationTimeSlot,
    IMedicalExaminationToAdd,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
} from '../../../../../../../models/interventions/medicalExaminations';
import {
    removeTimeSlot,
    updatePlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/actions';
import { getTranslatorDeprecated } from '../../../../../../../redux/i18n/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../../models/general/redux';
import { ITraceableApiError } from '../../../../../../../models/general/error';
import { ITranslator } from '../../../../../../../models/general/i18n';
import { clearErrors } from '../../../../../../../utils/libs/redux/generic/actions';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import FormError from '../../../../../../common/forms/FormError';
import { NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY } from '../../../../../../../config/medicalExamination.config';

interface IPrivateProps {
    cancelTimeSlot: (medicalExamination: IMedicalExaminationToAdd) => void;
    removeMedicalExaminationTimeSlot: (medicalExamination: IMedicalExaminationToAdd) => void;
    translator: ITranslator;
    removeAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
}

interface IAutomaticDeleteTimeSlotProps {
    show: boolean;
    selectedMedicalExamination: IMedicalExaminationToAdd;
    onClose: () => void;
}

class AutomaticDeleteTimeSlot extends PureComponent<IPrivateProps & IAutomaticDeleteTimeSlotProps> {

    constructor(props) {
        super(props);

        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    public render() {
        const {
            show, onClose, selectedMedicalExamination, removeAsyncInfo,
        } = this.props;

        return (
            <ConfirmationDialog
                show={show}
                onConfirm={this.onDeleteClicked}
                onCancel={onClose}
                showLoader={removeAsyncInfo.status === AsyncStatus.Busy}
            >
                <Translate
                    msg="interventions.medical_examinations.new.steps.validate_auto_plan.delete.confirm"
                    placeholders={{
                        employee: selectedMedicalExamination ?
                            formatPersonName(selectedMedicalExamination.employee) : '',
                    }}
                />
                {removeAsyncInfo.error &&
                    <FormError error={removeAsyncInfo.error} />
                }
            </ConfirmationDialog>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.removeAsyncInfo.status === AsyncStatus.Success &&
            prevProps.removeAsyncInfo.status === AsyncStatus.Busy
        ) {
            const { onClose, removeMedicalExaminationTimeSlot, selectedMedicalExamination } = this.props;
            removeMedicalExaminationTimeSlot(selectedMedicalExamination);
            onClose();
        }
    }

    private onDeleteClicked() {
        const {
            selectedMedicalExamination,
            cancelTimeSlot,
        } = this.props;

        if (selectedMedicalExamination && selectedMedicalExamination.timeSlot) {
            cancelTimeSlot(selectedMedicalExamination);
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, removeAsyncInfo } = this.props;
        clearError(removeAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IAutomaticDeleteTimeSlotProps>({
    stateProps: (state) => {
        const removeAsyncInfo = getRemoveTimeslotAsyncInfo(state);
        return {
            translator: getTranslatorDeprecated(state),
            removeAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            cancelTimeSlot: (medicalExamination: IMedicalExaminationToAdd) => {
                dispatch(removeTimeSlot({
                    timeSlotId: medicalExamination.timeSlot.id,
                    activityId: (medicalExamination.timeSlot as IMedicalExaminationTimeSlot).activityId,
                }));
            },
            removeMedicalExaminationTimeSlot: (medicalExamination: IMedicalExaminationToAdd) => {
                const state = getState();
                const wizardEntity = getPlanMedicalExaminationWizardEntity<
                    IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
                const prevMedicalExaminationsToAdd =
                    wizardEntity ? wizardEntity.medicalExaminationsToAdd : [];
                const newMedicalExaminationsToAdd = prevMedicalExaminationsToAdd.map((item) => {
                    if (
                        item.employee.id === medicalExamination.employee.id &&
                        item.planningRequestId === medicalExamination.planningRequestId
                    ) {
                        return {
                            ...medicalExamination,
                            timeSlot: null,
                            errorMessage: NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY,
                        };
                    }
                    return item;
                });
                dispatch(
                    updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                        medicalExaminationsToAdd: newMedicalExaminationsToAdd,
                    }),
                );
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(AutomaticDeleteTimeSlot);
