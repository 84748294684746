import { createAction, createSuccessAction, createFailAction } from '../index';
import { FETCH_ALL_CONSTANTS } from './types';
import { ITraceableApiError } from '../../models/general/error';
import { TBackendLocale } from '../../models/general/i18n';

export interface IFetchAllConstants {
    language?: TBackendLocale;
    fetchMessageTypes?: boolean;
}

export function fetchAllConstants(language?: TBackendLocale, fetchMessageTypes = true) {
    return createAction<IFetchAllConstants>(FETCH_ALL_CONSTANTS, { language, fetchMessageTypes });
}

export function fetchAllConstantsSucceeded() {
    return createSuccessAction<{}>(FETCH_ALL_CONSTANTS, {});
}

export function fetchAllConstantsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_ALL_CONSTANTS, error);
}
