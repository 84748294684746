import { SubmittedFormActionType, TOptionalSubmittedFormActionType } from '../../../config/analytics.config';
import { FeedbackType } from '../../../models/contact/feedback';
import { EXAMINATION_REASON_CODE, PRESTATION_CODE } from '../../../config/navigation/interventions.config';
import {
    IExaminationReason,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../models/interventions/medicalExaminations';
import ROUTE_KEYS from '../../../routeKeys';

const FEEDBACK_TYPE_2_FORM_ACTION_MAP = {
    [FeedbackType.COMPLAINT]: SubmittedFormActionType.FEEDBACK_GIVEN_COMPLAINT,
    [FeedbackType.QUESTION]: SubmittedFormActionType.FEEDBACK_GIVEN_QUESTION,
    [FeedbackType.SUGGESTION]: SubmittedFormActionType.FEEDBACK_GIVEN_SUGGESTION,
};

const PRESTATION_CODE_2_FORM_ACTION_MAP = {
    [PRESTATION_CODE.ENVIRONMENTAL]: SubmittedFormActionType.INTERVENTION_REQUESTED_ENVIRONMENTAL,
    [PRESTATION_CODE.ERGONOMICS]: SubmittedFormActionType.INTERVENTION_REQUESTED_ERGONOMICS,
    [PRESTATION_CODE.HEALTH]: SubmittedFormActionType.INTERVENTION_REQUESTED_HEALTH,
    [PRESTATION_CODE.HYG_TOX]: SubmittedFormActionType.INTERVENTION_REQUESTED_HYG_TOX,
    [PRESTATION_CODE.OTHER]: SubmittedFormActionType.INTERVENTION_REQUESTED_OTHER,
    [PRESTATION_CODE.PSYCHOSOCIAL]: SubmittedFormActionType.INTERVENTION_REQUESTED_PSYCHOSOCIAL,
    [PRESTATION_CODE.SAFETY]: SubmittedFormActionType.INTERVENTION_REQUESTED_SAFETY,
};

const ADDITIONAL_EXAMINATION_REASON_MAPPING_NEEDED = null;

const PLAN_MEDICAL_EXAM_WIZARD_TYPE_2_FORM_ACTION_MAP = {
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.INTERIM_MANUAL]:
        SubmittedFormActionType.MEDICAL_EXAM_PLANNED_INTERIM,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING]:
        SubmittedFormActionType.MEDICAL_EXAM_MOVE_PLANNING,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.OTHER_MANUAL]:
        ADDITIONAL_EXAMINATION_REASON_MAPPING_NEEDED,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL]:
        SubmittedFormActionType.MEDICAL_EXAM_PLANNED_PERIODIC_HEALTH_ASSESSMENT,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC]:
        SubmittedFormActionType.MEDICAL_EXAM_PLANNED_PERIODIC_HEALTH_ASSESSMENT_BULK,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RE_INTEGRATION_MANUAL]:
        SubmittedFormActionType.MEDICAL_EXAM_PLANNED_RE_INTEGRATION,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL]:
        SubmittedFormActionType.MEDICAL_EXAM_PLANNED_RESUMPTION_OF_WORK,
};

const ROUTE_KEY_2_FORM_ACTION_MAP = {
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL]: SubmittedFormActionType.USER_ADDED_EXTERNAL,
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL]: SubmittedFormActionType.USER_ADDED_INTERNAL,
    /**
     * Only log when in the add-employee-wizard and
     * NOT when in the new-medical-examination-for-interim-wizard (= ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD)
     * (because another form event will be triggered at the end of that flow and not at the time
     * when the employee gets created which is somewhere in the middle of that flow).
     */
    [ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD]: SubmittedFormActionType.EMPLOYEE_ADDED,
};

export function mapFeedbackTypeToSubmittedFormActionType(
    feedbackType: FeedbackType,
): TOptionalSubmittedFormActionType {
    return getMappedSubmittedFormActionTypeOrFalse(FEEDBACK_TYPE_2_FORM_ACTION_MAP, feedbackType);
}

export function mapPrestationCodeToSubmittedFormActionType(
    prestationCode: PRESTATION_CODE,
): TOptionalSubmittedFormActionType {
    return getMappedSubmittedFormActionTypeOrFalse(PRESTATION_CODE_2_FORM_ACTION_MAP, prestationCode);
}

export function mapRouteKeyToSubmittedFormActionType(
    routeKey: ROUTE_KEYS,
): TOptionalSubmittedFormActionType {
    return getMappedSubmittedFormActionTypeOrFalse(ROUTE_KEY_2_FORM_ACTION_MAP, routeKey);
}

export function mapMedicalExaminationToSubmittedFormActionType({
    wizardType,
    examinationReason,
}: {
    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    examinationReason: IExaminationReason,
}): TOptionalSubmittedFormActionType {
    if (!wizardType || !examinationReason) {
        return false;
    }

    const formActionTypeByWizardType = PLAN_MEDICAL_EXAM_WIZARD_TYPE_2_FORM_ACTION_MAP[wizardType];

    if (formActionTypeByWizardType !== ADDITIONAL_EXAMINATION_REASON_MAPPING_NEEDED) {
        return formActionTypeByWizardType;
    }

    // only the examination reasons that are not already covered by the wizardType mapping, are checked here
    switch (examinationReason.code) {
        case EXAMINATION_REASON_CODE.MATERNITY_PROTECTION:
            return SubmittedFormActionType.MEDICAL_EXAM_PLANNED_MATERNITY_PROTECTION;
        case EXAMINATION_REASON_CODE.PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT:
            return SubmittedFormActionType.MEDICAL_EXAM_PLANNED_PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT;
        case EXAMINATION_REASON_CODE.PERIODIC_CHECK_PLUS_SAFETY_FUNCTION:
            return SubmittedFormActionType.MEDICAL_EXAM_PLANNED_PERIODIC_CHECK_PLUS_SAFETY_FUNCTION;
        case EXAMINATION_REASON_CODE.SPONTANEOUS_CONSULTATION:
            return SubmittedFormActionType.MEDICAL_EXAM_PLANNED_SPONTANEOUS_CONSULTATION;
    }

    return SubmittedFormActionType.MEDICAL_EXAM_PLANNED_OTHER;
}

function getMappedSubmittedFormActionTypeOrFalse(
    map: { [key: string]: SubmittedFormActionType },
    keyToFind: string,
): TOptionalSubmittedFormActionType {
    const submittedFormAction = map[keyToFind];
    return submittedFormAction || false;
}
