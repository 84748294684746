import React from 'react';

import { ICourse } from '../../../../../models/documentCenter/courses';
import { IEmployee, IEmployeeDetails } from '../../../../../models/admin/employee';
import { IExecutedMedicalExamination} from '../../../../../models/interventions/medicalExaminations';
import {
    IPlanBufferzoneWizardEntity,
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
} from '../../../../../models/interventions/bufferzones';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/planned';
import { IReservedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/reserved';
import { ListItem } from '../../../../../models/general/list';
import { skipToPlanBufferzoneWizardStepActions } from '../../../../../redux/intervention/bufferzones/actions';
import ROUTE_KEYS from '../../../../../routeKeys';
// eslint-disable-next-line max-len
import getUniqueTypeaheadFilterValuesFromListItems from '../../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import * as COURSES_SELECTORS from '../../../../../redux/documentCenter/courses/selectors';
import * as MEDICAL_EXAMINATION_SELECTORS from '../../../../../redux/medicalExamination/selectors';
import * as EMPLOYEE_INFO_SELECTORS from '../../../../../redux/employee/info/selectors';
import { connect } from '../../../../index';
import {
    EmployeeDetailsContent,
    EmployeeDetailsHeader,
    EmployeeDetailsOverlay,
} from '../../../../administration/Employees/EmployeeDetails';
import { EmployeeDetailsOverlayType } from '../../../../administration/Employees/EmployeeDetails/common';
import {
    IRenderFilterContentProps,
    IRenderMasterContentProps,
    IRenderSearchContentProps,
} from '../../../../common/widget/MasterWithDetail/typings';
import { startEndDateSchema } from '../../../../common/input/StartEndDateFilter/startEndDateSchema';
import CheckboxesOrTypeaheadFilter from '../../../../common/input/CheckboxesOrTypeaheadFilter';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import StartEndDateFilter from '../../../../common/input/StartEndDateFilter';
import TextInput from '../../../../common/input/TextInput';
import Translate from '../../../../common/Translate';
import DetailContentCoursesFollowed from '../../../../documentCenter/Courses/Followed/detail/content';
import DetailContentCoursesPlanned from '../../../../documentCenter/Courses/Planned/detail/content';
import DetailFooterCoursesFollowed from '../../../../documentCenter/Courses/Followed/detail/footer';
import DetailFooterCoursesPlanned from '../../../../documentCenter/Courses/Planned/detail/footer';
import DetailHeaderCourses from '../../../../documentCenter/Courses/shared/header';
import {
    IDetailConfig,
    IRenderDetailContentProps,
    IRenderDetailFooterProps,
    IRenderDetailHeaderProps,
} from '../../../../common/widget/MasterWithDetail/typings';

// 2
import {
    DetailHeader as DetailHeaderPlanned,
    DetailContent as DetailContentPlanned,
} from '../../Planned/detail';
import {
    DetailHeader as DetailHeaderExecuted,
    DetailContent as DetailContentExecuted,
} from '../../Executed/detail';
import {
    ReservedMedicalExaminationDetail,
} from '../Detail/ReservedMedicalExaminationDetail.component';
import {
    ReservedMedicalExaminationDetailHeader,
} from '../Detail/Header/ReservedMedicalExaminationDetailHeader.component';

import {
    clientSideFilterOfListData,
    getDefaultQueryParams,
    mapListRowForExport,
    mapReservedMedicalExaminationsToListItems,
    shouldRenderShowAllButton,
    toListId,
    transformFilterValuesToActiveFilters,
} from './ReservedMedicalExaminationOverview.helper';
import {
    IColumnNames,
    IFilterValues,
    IPrivateProps,
    ReservedMedicalExaminationOverviewProps,
    TFilterColumnNames,
} from './ReservedMedicalExaminationOverview.type';
import {
    BASE_NAME,
    COLUMNS,
    TRANSLATION_PREFIX,
} from './ReservedMedicalExaminationOverview.const';
import {
    ReservedMedicalExaminationsList,
} from './ReservedMedicalExaminationsList/ReservedMedicalExaminationsList.component';

const ReservedMedicalExaminationOverviewComponent = (props: ReservedMedicalExaminationOverviewProps) => {
    const { selectedReservedMedicalExaminationId, triggerPlanBufferzoneWizardWithSelectedBufferzone } = props;
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES,
                asyncInfoSelector: MEDICAL_EXAMINATION_SELECTORS.getReservedMedicalExaminationsAsyncInfo,
                dataSelector: MEDICAL_EXAMINATION_SELECTORS.getReservedMedicalExaminations,
                transformData: mapReservedMedicalExaminationsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <ReservedMedicalExaminationsList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'bufferzones',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            detailConfig={
                {
                    levels: [
                        {
                            level: 1,
                            details: [{
                                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL,
                                // eslint-disable-next-line max-len
                                asyncInfoSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedReservedMedicalExaminationAsyncInfo,
                                idRouteParamName: 'reservedMedicalExaminationId',
                                dataSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedReservedMedicalExamination,
                                renderHeader: (renderProps: IRenderDetailHeaderProps<IReservedMedicalExamination>) =>
                                    <ReservedMedicalExaminationDetailHeader {...renderProps} />,
                                renderContent: (renderProps: IRenderDetailContentProps<IReservedMedicalExamination>) =>
                                    <ReservedMedicalExaminationDetail
                                        {...renderProps}
                                        triggerPlanBufferzoneWizardWithSelectedBufferzone={
                                            triggerPlanBufferzoneWizardWithSelectedBufferzone
                                        }
                                    />,
                            }],
                        },
                        {
                            level: 2,
                            details: [{
                                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
                                asyncInfoSelector: EMPLOYEE_INFO_SELECTORS.getSelectedEmployeeAsyncInfo,
                                idSelector: EMPLOYEE_INFO_SELECTORS.getSelectedEmployeeId,
                                dataSelector: EMPLOYEE_INFO_SELECTORS.getSelectedEmployee,
                                renderHeader: (
                                    renderProps: IRenderDetailHeaderProps<IEmployee & IEmployeeDetails>,
                                ) =>
                                    <EmployeeDetailsHeader {...renderProps} />,
                                renderContent: (
                                    renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>,
                                ) => selectedReservedMedicalExaminationId &&
                                    <EmployeeDetailsContent
                                        {...renderProps}
                                        // eslint-disable-next-line max-len
                                        plannedMedicalExaminationRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL}
                                        // eslint-disable-next-line max-len
                                        executedMedicalExaminationRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL}
                                        // eslint-disable-next-line max-len
                                        followedCoursesRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL}
                                        // eslint-disable-next-line max-len
                                        plannedCoursesRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL}
                                        extraRoutePayload={{
                                            reservedMedicalExaminationId: selectedReservedMedicalExaminationId,
                                        }}
                                    />,
                                renderOverlay: ({
                                    overlayType, closeOverlay, onSave,
                                }) =>
                                    <EmployeeDetailsOverlay
                                        overlayType={overlayType as EmployeeDetailsOverlayType}
                                        closeOverlay={closeOverlay}
                                        onSave={onSave}
                                    />,
                            }],
                        },
                        {
                            level: 3,
                            details: [
                                PLANNED_EXAMINATION_DETAIL_CONFIG,
                                EXECUTED_EXAMINATION_DETAIL_CONFIG,
                                PLANNED_COURSES_DETAIL_CONFIG,
                                FOLLOWED_COURSES_DETAIL_CONFIG,
                            ],
                        },
                    ],
                }
            }
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
};

const ReservedMedicalExaminationOverview = connect<IPrivateProps>({
    stateProps: (state) => {
        const { getSelectedReservedMedicalExaminationIdFromPathParams } = MEDICAL_EXAMINATION_SELECTORS;

        return {
            selectedReservedMedicalExaminationId: getSelectedReservedMedicalExaminationIdFromPathParams(
                state,
            ),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerPlanBufferzoneWizardWithSelectedBufferzone: (reservedMedicalExaminationId: string) => {
                const bufferzones = MEDICAL_EXAMINATION_SELECTORS.getReservedMedicalExaminations(getState());

                const bufferzone = bufferzones.find((bufferzone) => bufferzone.id === reservedMedicalExaminationId);

                if (!bufferzone) {
                    return;
                }

                dispatch(skipToPlanBufferzoneWizardStepActions.trigger({
                    wizardPayload: {
                        step: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES,
                    },
                    entity: {
                        selectedBufferzone: {
                            ...bufferzone,
                            selectedFromOverview: true,
                        },
                    } as IPlanBufferzoneWizardEntity,
                }));
            },
        };
    },
})(ReservedMedicalExaminationOverviewComponent);

export { ReservedMedicalExaminationOverview };

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.bufferzones.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.bufferzones.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

export function FilterContent(renderProps: IRenderFilterContentProps<ListItem<TFilterColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleLocations = getUniqueTypeaheadFilterValuesFromListItems<TFilterColumnNames>(
        allListItems,
        'locationId',
        'location',
    );

    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<TFilterColumnNames>(
        allListItems,
        'seatCode',
        'seat',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="location"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.location`}
                possibleFilterItems={possibleLocations}
                actualFilterValue={formRenderProps.values.locationIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'locationIds',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="reason"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.seat`}
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />

        </div>
    );
}

export const PLANNED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
    asyncInfoSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedPlannedMedicalExaminationAsyncInfo,
    idRouteParamName: 'timeSlotId',
    dataSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedPlannedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
        <DetailHeaderPlanned {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
        <DetailContentPlanned {...renderProps} />,
};

export const EXECUTED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
    asyncInfoSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedExecutedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: MEDICAL_EXAMINATION_SELECTORS.getSelectedExecutedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
        <DetailHeaderExecuted {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
        <DetailContentExecuted {...renderProps} />,
};

export const PLANNED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL,
    asyncInfoSelector: COURSES_SELECTORS.getCoursesPlannedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: COURSES_SELECTORS.getSelectedPlannedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
        <DetailContentCoursesPlanned {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesPlanned {...renderProps} />,
};

export const FOLLOWED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL,
    asyncInfoSelector: COURSES_SELECTORS.getCoursesFollowedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: COURSES_SELECTORS.getSelectedFollowedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) => (
        <DetailContentCoursesFollowed
            {...renderProps}
            followedCoursesAttendantRouteKey={ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT}
        />),
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesFollowed {...renderProps} />,
};
