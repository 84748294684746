import { createResetAction, createTypeActions } from '../../utils/libs/redux/createAction';
import { getPlanMedicalExaminatonWizardSteps } from '../../config/navigation/wizardStepsMap';
import {
    IAddTimeslotSuccessPayload,
    IAutoPlanEmployeePayload,
    IMoveUpdateTimeslotPayload,
    IRemoveTimeSlotPayload,
    // IReplaceEmployeeUpdateTimeslotPayload,
    IUpdateTimeSlotSuccessPayload,
    TAddTimeSlotPayload,
    TAutoPlanEmployeeResponse,
    TUpdateTimeSlotPayload,
} from '../../models/interventions/timeslots';
import {
    IExaminationReason,
    IExecutedMedicalExamination,
    IExecutedMedicalExaminationsFilter,
    IFetchMedicalCenterTimeslotsByEmployeePayload,
    IFetchMedicalExaminationDetailPayload,
    IFetchMedicalExaminationDocumentsPayload,
    IFetchNoShowsMedicalExaminationsPayload,
    IFetchPlannedMedicalExaminationDetailPayload,
    IMedicalCenterFreeSlotsPayload,
    IMedicalCentersFreeSlotsSuccessPayload,
    IMedicalExaminationDocuments,
    IMedicalExaminationTimeSlot,
    IMedicalExaminationToAdd,
    IMedicalExaminationToPlan,
    IPlanMedicalExaminationWizardPayload,
    IPlannedMedicalExaminationsFilter,
    IRequestMedicalExaminationPayload,
    IRequestReIntegrationPayload,
    ISkipToPlanMedicalExaminationWizardStepPayload,
    IValidateEmployeeToPlanData,
    IValidateEmployeeToPlanPayload,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../models/interventions/medicalExaminations/planned';
import {
    IFetchReservedMedicalExaminationsPayload,
    IReservedMedicalExamination,
    IFetchReservedMedicalExaminationPayload,
} from '../../models/interventions/medicalExaminations/reserved';
import { ITraceableApiError } from '../../models/general/error';
import { ITraceableAsyncRequestPayload } from '../../models/general/redux';
import ROUTE_KEYS from '../../routeKeys';

import { createAction, createSuccessAction, createFailAction } from '../index';
import { navigateTo, redirectToRoute } from '../location/actions';

import {
    ADD_TIMESLOT,
    AUTO_PLAN_EMPLOYEES,
    CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    CREATE_RE_INTEGRATION_REQUEST,
    FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    FETCH_EMPLOYEES_TO_PLAN,
    FETCH_EXAMINATION_REASONS,
    FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
    FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
    FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
    FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    FETCH_RESERVED_MEDICAL_EXAMINATIONS,
    REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY,
    REMOVE_TIMESLOT,
    REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY,
    REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY,
    REQUEST_MEDICAL_EXAMINATION,
    RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    UPDATE_TIMESLOT,
    VALIDATE_EMPLOYEE_TO_PLAN,
    FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL,
} from './types';

// To plan medical examinations
export function fetchMedicalExaminationsToPlan() {
    return createAction(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, {});
}

export function fetchMedicalExaminationsToPlanSucceeded(payload: IMedicalExaminationToPlan[]) {
    return createSuccessAction<IMedicalExaminationToPlan[]>(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, payload);
}

export function fetchMedicalExaminationsToPlanFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, error);
}

// Planned medical examinations
export const fetchPlannedMedicalExaminationsActions =
    createTypeActions<IPlannedMedicalExaminationsFilter, IPlannedMedicalExamination[]>({
        type: FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    });

// Planned medical examination detail
export function fetchPlannedMedicalExaminationDetail(payload: IFetchPlannedMedicalExaminationDetailPayload) {
    return createAction<IFetchPlannedMedicalExaminationDetailPayload>(
        FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
        payload,
    );
}

export function fetchPlannedMedicalExaminationDetailSucceeded(payload: IPlannedMedicalExamination) {
    return createSuccessAction<IPlannedMedicalExamination>(FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchPlannedMedicalExaminationDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL, error);
}

// Executed medical examinations
export const fetchExecutedMedicalExaminationsActions =
    createTypeActions<IExecutedMedicalExaminationsFilter, IExecutedMedicalExamination[]>({
        type: FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    });

/* Reserved medical examinations */

export function fetchReservedMedicalExaminations(payload: IFetchReservedMedicalExaminationsPayload) {
    return createAction(FETCH_RESERVED_MEDICAL_EXAMINATIONS, payload);
}

export function fetchReservedMedicalExaminationsSucceeded(payload: IReservedMedicalExamination[]) {
    return createSuccessAction<IReservedMedicalExamination[]>(FETCH_RESERVED_MEDICAL_EXAMINATIONS, payload);
}

export function fetchReservedMedicalExaminationsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_RESERVED_MEDICAL_EXAMINATIONS, error);
}

/* Reserved medical examination detail */
export function fetchReservedMedicalExamination(payload: IFetchReservedMedicalExaminationPayload) {
    return createAction(FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchReservedMedicalExaminationSucceeded(payload: IReservedMedicalExamination) {
    return createSuccessAction<IReservedMedicalExamination>(FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchReservedMedicalExaminationFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_RESERVED_MEDICAL_EXAMINATION_DETAIL, error);
}

// Executed medical examination detail
export function fetchExecutedMedicalExaminationDetail(payload: IFetchMedicalExaminationDetailPayload) {
    return createAction<IFetchMedicalExaminationDetailPayload>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchExecutedMedicalExaminationDetailSucceeded(payload: IExecutedMedicalExamination) {
    return createSuccessAction<IExecutedMedicalExamination>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchExecutedMedicalExaminationDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, error);
}

// Examination reasons
export function fetchExaminationReasons() {
    return createAction(FETCH_EXAMINATION_REASONS, {});
}

export function fetchExaminationReasonsSucceeded(payload: IExaminationReason[]) {
    return createSuccessAction<IExaminationReason[]>(FETCH_EXAMINATION_REASONS, payload);
}

export function fetchExaminationReasonsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXAMINATION_REASONS, error);
}

// Plan wizard
export function navigateToPlanMedicalExaminationWizardStep(
    payload: IPlanMedicalExaminationWizardPayload<PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE>,
    isRedirect: boolean = false,
) {
    const wizardPayload = {
        ...payload,
        step: payload.step || getFirstPlanMedExamStepByWizardType(payload.wizardType),
    };

    if (isRedirect) {
        return redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD, wizardPayload);
    }

    return navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD, wizardPayload);
}

function getFirstPlanMedExamStepByWizardType(wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE) {
    return getPlanMedicalExaminatonWizardSteps(wizardType).firstStepId;
}

export function updatePlanMedicalExaminationWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
        payload,
    );
}

export function createPlanMedicalExaminationWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
        payload,
    );
}

export function removeUploadedFileFromReIntegrationEntity<Entity>() {
    return createAction<object>(
        REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY,
        {},
    );
}

export function removeUploadedWorkPostCardFromInterimEntity<Entity>() {
    return createAction<object>(
        REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY,
        {},
    );
}

export function resetPlanMedicalExaminationWizardEntity() {
    return createAction(RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY, {});
}

export function removeExaminationToAddFromEntity(payload: IMedicalExaminationToAdd) {
    return createAction<IMedicalExaminationToAdd>(REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY, payload);
}

// Employees to plan

export interface IFetchEmployeeToPlanPayload {
    companyCode: string;
    allowedExaminationReasonIds?: number[];
    allowedExaminationReasonCodes?: string[];
}

export function fetchEmployeesToPlan(payload: IFetchEmployeeToPlanPayload) {
    return createAction<IFetchEmployeeToPlanPayload>(FETCH_EMPLOYEES_TO_PLAN, payload);
}

export function fetchEmployeesToPlanSucceeded(payload: IMedicalExaminationToPlan[]) {
    return createSuccessAction<IMedicalExaminationToPlan[]>(FETCH_EMPLOYEES_TO_PLAN, payload);
}

export function fetchEmployeesToPlanFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEES_TO_PLAN, error);
}

export const skipToPlanMedicalExaminationWizardStepActions =
    createTypeActions<ISkipToPlanMedicalExaminationWizardStepPayload>({
        type: SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    });

// Timeslots
export const addTimeslotActions =
    createTypeActions<
        TAddTimeSlotPayload,
        IAddTimeslotSuccessPayload>({ type: ADD_TIMESLOT });

export const updateTimeSlotActions = createTypeActions<
    TUpdateTimeSlotPayload,
    IUpdateTimeSlotSuccessPayload
>({ type: UPDATE_TIMESLOT });

export function triggerMoveUpdateTimeSlot(payload: IMoveUpdateTimeslotPayload) {
    return updateTimeSlotActions.trigger(payload);
}

// export function triggerReplaceEmployeeUpdateTimeSlot(payload: IReplaceEmployeeUpdateTimeslotPayload) {
//     return updateTimeSlotActions.trigger(payload);
// }

export function removeTimeSlot(payload: IRemoveTimeSlotPayload) {
    return createAction<IRemoveTimeSlotPayload>(REMOVE_TIMESLOT, payload);
}

export function removeTimeslotSucceeded() {
    return createSuccessAction(REMOVE_TIMESLOT, {});
}

export function removeTimeslotFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(REMOVE_TIMESLOT, error);
}

export function removeTimeslotReset() {
    return createResetAction(REMOVE_TIMESLOT, {});
}

// Auto plan
export function autoPlanEmployees(payload: IAutoPlanEmployeePayload[]) {
    return createAction<IAutoPlanEmployeePayload[]>(AUTO_PLAN_EMPLOYEES, payload);
}

export function autoPlanEmployeesSucceeded(payload: TAutoPlanEmployeeResponse[]) {
    return createSuccessAction<TAutoPlanEmployeeResponse[]>(AUTO_PLAN_EMPLOYEES, payload);
}

export function autoPlanEmployeesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(AUTO_PLAN_EMPLOYEES, error);
}

export function autoPlanEmployeesReset() {
    return createResetAction(AUTO_PLAN_EMPLOYEES, {});
}

// Company medical centers freeSlots

export function fetchCompanyMedicalCentersFreeSlots(payload: IMedicalCenterFreeSlotsPayload) {
    return createAction<IMedicalCenterFreeSlotsPayload>(FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, payload);
}

export function fetchCompanyMedicalCentersFreeSlotsSucceeded(payload: IMedicalCentersFreeSlotsSuccessPayload) {
    return createSuccessAction<IMedicalCentersFreeSlotsSuccessPayload>(
        FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, payload,
    );
}

export function fetchCompanyMedicalCentersFreeSlotsFailed(error: ITraceableApiError & ITraceableAsyncRequestPayload) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, error);
}

// Company medical center timeslot by employee id
export const fetchCompanyMedicalCenterTimeslotsByEmployeeActions =
    createTypeActions<IFetchMedicalCenterTimeslotsByEmployeePayload, IMedicalExaminationTimeSlot[]>({
        type: FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    });

// Request re-integration
export const createReIntegrationRequestActions = createTypeActions<IRequestReIntegrationPayload>({
    type: CREATE_RE_INTEGRATION_REQUEST,
});

// Executed examination documents
export const fetchMedicalExaminationDocumentsActions =
    createTypeActions<IFetchMedicalExaminationDocumentsPayload, IMedicalExaminationDocuments>({
        type: FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    });

// Executed examination documents and files
export const fetchExaminationDocumentsAndFilesActions =
    createTypeActions<IFetchMedicalExaminationDocumentsPayload>({
        type: FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    });

// No shows medical examinations
export const fetchNoShowsExaminationsActions =
    createTypeActions<IFetchNoShowsMedicalExaminationsPayload>({
        type: FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    });

// Validate employee to plan
export const validateEmployeeToPlanActions =
    createTypeActions<IValidateEmployeeToPlanPayload, IValidateEmployeeToPlanData>({
        type: VALIDATE_EMPLOYEE_TO_PLAN,
    });

// Request medical examination
export const requestMedicalExaminationActions =
    createTypeActions<IRequestMedicalExaminationPayload>({
        type: REQUEST_MEDICAL_EXAMINATION,
    });
