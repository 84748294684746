import { createEpic } from '../../index';
import {
    FETCH_FLU_VACCINES_ORDERS,
    FETCH_FLU_VACCINES_CONFIG,
    FETCH_FLU_VACCINE_TARIFFS,
    FETCH_FLU_VACCINES_AVAILABLE,
    CREATE_FLU_VACCINES_ORDER,
    UPDATE_FLU_VACCINES_ORDER,
} from './types';
import {
    fetchFluVaccinesOrdersSucceeded, fetchFluVaccinesOrdersFailed,
    fetchFluVaccinesConfig, fetchFluVaccinesConfigSucceeded, fetchFluVaccinesConfigFailed,
    fetchFluVaccinesOrders,
    triggerFluVaccinesOrderWizard,
    fluVaccineTariffActions,
    fluVaccinesAvailableActions,
    createFluVaccinesOrderActions,
    updateFluVaccinesOrderActions,
} from './actions';
import {
    doesFluVaccineOrderListContainSelectedOrderId,
    getFluVaccinesOrderWizardStepId,
    getFluVaccineTariffs,
    getFluVaccinesOrders,
    areFluVaccineOrdersAvailable,
} from './selectors';
import { fetchSelectedCompanySeatsAndDivisionsActions } from '../../company/selected/actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
    getSeatsAndDivisionsForSelectedCompany,
} from '../../company/selected/selectors';
import {
    fetchCompanyAddressesActions,
    fetchCompanyMedicalCentersActions,
    fetchContacts,
} from '../../company/info/actions';
import {
    FLU_VACCINES_ORDER_WIZARD_STEP_ID,
    IFluVaccinesOrderWizardPayload,
    ICreateFluVaccinesOrder,
    IUpdateFluVaccinesOrderActionPayload,
} from '../../../models/interventions/fluVaccines';
import { ICompanyCodePayload } from '../../../models/admin/company';
import ROUTE_KEYS from '../../../routeKeys';
import { areCompanyContactsAvailable } from '../../company/info/selectors';
import { getLocationState } from '../../location/selectors';
import { SubmittedFormActionType } from '../../../config/analytics.config';
import { getOrderFluVaccinesWizardSteps } from '../../../config/navigation/wizardStepsMap';

// fetchFluVaccinesOrdersEpic
createEpic({
    onActionType: [FETCH_FLU_VACCINES_ORDERS, ROUTE_KEYS.R_FLU_VACCINES_ORDERS],
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!areFluVaccineOrdersAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(getState());
        return type !== action.type;
    },
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            const result = await api.interventions.fluVaccines.fetchFluVaccinesOrders(
                { companyCode, showFullFamily },
            );

            dispatch(fetchFluVaccinesConfig());
            dispatch(fetchFluVaccinesOrdersSucceeded(result));
            done();
        } catch (error) {
            dispatch(fetchFluVaccinesOrdersFailed(error));
            done();
        }
    },
    latest: false,
});

// fetchFluVaccinesOrderDetailEpic
createEpic<{ id: number }>({
    onActionType: ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL,
    processMultiple: fetchFluVaccinesOrderDetail,
    latest: true,
});

async function fetchFluVaccinesOrderDetail({ api, action, getState }, dispatch, done) {
    try {
        const id = action.payload.id;
        const state = getState();

        if (!doesFluVaccineOrderListContainSelectedOrderId(state, id)) {
            dispatch(fetchFluVaccinesOrders());
        }

        /**
         * Because of a bug in the get flu vaccines call, the list does not contain all the required
         * data of the contact (e.g. email missing although it is available on the contact)
         * + because there is no backend call to fetch the details of a flu vaccine,
         * we have to enrich the flu vaccine detail info with some info of the internal contact.
         * See 'getSelectedFluVaccineOrder'.
         * So we have to fetch the internal contacts list if it is not available yet.
         */
        if (!areCompanyContactsAvailable(state)) {
            dispatch(fetchContacts());
        }

        const companyCode = getSelectedSeatCompanyCode(getState());
        dispatch(fluVaccineTariffActions.trigger({ companyCode }));

        dispatch(fluVaccinesAvailableActions.trigger({}));
        return done();
    } catch (error) {
        return done();
    }
}

// fetchFluVaccinesConfigEpic
createEpic({
    onActionType: FETCH_FLU_VACCINES_CONFIG,
    async processReturn({ api }) {
        try {
            const result = await api.interventions.fluVaccines.fetchFluVaccinesConfig();
            return fetchFluVaccinesConfigSucceeded(result);
        } catch (error) {
            return fetchFluVaccinesConfigFailed(error);
        }
    },
    latest: false,
});

// fetchFluVaccineTariffsEpic
createEpic<ICompanyCodePayload>({
    onActionType: FETCH_FLU_VACCINE_TARIFFS,
    refreshDataIf: ({ getState }) => getFluVaccineTariffs(getState()) === null,
    async processReturn({ action, api }) {
        try {
            const tariffs = await api.interventions.fluVaccines.fetchFluVaccineTariffs(action.payload);
            return fluVaccineTariffActions.succeeded(tariffs);
        } catch (error) {
            return fluVaccineTariffActions.failed(error);
        }
    },
    latest: true,
});

// fetchFluVaccinesAvailableEpic
createEpic({
    onActionType: FETCH_FLU_VACCINES_AVAILABLE,
    async processReturn({ api }) {
        try {
            const available = await api.interventions.fluVaccines.fetchAvailableFluVaccines();
            return fluVaccinesAvailableActions.succeeded(available);
        } catch (error) {
            return fluVaccinesAvailableActions.failed(error);
        }
    },
    latest: true,
});

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validateFluVaccinesOrderWizardStepIdEpic
createEpic<IFluVaccinesOrderWizardPayload>({
    onActionType: ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const FLU_VACCINES_ORDER_STEP_IDS = getOrderFluVaccinesWizardSteps().stepIds;
        if (!FLU_VACCINES_ORDER_STEP_IDS.includes(requestedStep)) {
            return next(triggerFluVaccinesOrderWizard());
        }

        // check no step skipped
        const currentStep = getFluVaccinesOrderWizardStepId(getState());
        const currentStepIndex = FLU_VACCINES_ORDER_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = FLU_VACCINES_ORDER_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(triggerFluVaccinesOrderWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringFluVaccinesOrderWizardEpic
createEpic<IFluVaccinesOrderWizardPayload>({
    onActionType: ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW,
    async processMultiple({ action, getState }, dispatch, done) {
        const state = getState();
        const { step } = action.payload;

        if (step === FLU_VACCINES_ORDER_WIZARD_STEP_ID.NR_OF_VACCINES) {
            const companyCode = getSelectedSeatCompanyCode(state);

            dispatch(fluVaccineTariffActions.trigger({ companyCode }));
            dispatch(fluVaccinesAvailableActions.trigger({}));

            return done();
        }

        if (step === FLU_VACCINES_ORDER_WIZARD_STEP_ID.SEAT) {
            const seatsAndDivisions = getSeatsAndDivisionsForSelectedCompany(state);

            if (!seatsAndDivisions || seatsAndDivisions.length === 0) {
                dispatch(fetchSelectedCompanySeatsAndDivisionsActions.trigger({}));
            }

            return done();
        }

        if (step === FLU_VACCINES_ORDER_WIZARD_STEP_ID.DELIVERY) {
            const companyCode = getSelectedSeatCompanyCode(state);

            dispatch(fluVaccineTariffActions.trigger({ companyCode }));
            dispatch(fetchCompanyAddressesActions.trigger({ companyCode }));
            dispatch(fetchCompanyMedicalCentersActions.trigger({ companyCode }));

            return done();
        }

        if (step === FLU_VACCINES_ORDER_WIZARD_STEP_ID.CONTACT) {
            dispatch(fetchContacts());

            return done();
        }

        if (step === FLU_VACCINES_ORDER_WIZARD_STEP_ID.VALIDATE) {
            const companyCode = getSelectedSeatCompanyCode(state);

            dispatch(fluVaccineTariffActions.trigger({ companyCode }));

            return done();
        }

        done();
    },
    latest: false,
});

// createFluVaccinesOrderEpic
createEpic<ICreateFluVaccinesOrder>({
    onActionType: CREATE_FLU_VACCINES_ORDER,
    async processReturn({ action, api }) {
        try {
            await api.interventions.fluVaccines.createFluVaccinesOrder(action.payload);

            return createFluVaccinesOrderActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.FLU_VACCINES_ORDERED,
                },
            );
        } catch (error) {
            return createFluVaccinesOrderActions.failed(error);
        }
    },
    latest: false,
});

// updateFluVaccinesOrderEpic
createEpic<IUpdateFluVaccinesOrderActionPayload>({
    onActionType: UPDATE_FLU_VACCINES_ORDER,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            await api.interventions.fluVaccines.updateVaccinesOrder({
                companyCode,
                showFullFamily,
                data: action.payload.data,
            });

            const updatedFluVaccinesOrders = getFluVaccinesOrders(state)
                .map((item) => (
                    item.id === action.payload.fullOrder.id ? { ...item, ...action.payload.fullOrder } : item
                ));

            dispatch(fetchFluVaccinesOrdersSucceeded(updatedFluVaccinesOrders));
            dispatch(fluVaccinesAvailableActions.trigger({}));
            dispatch(updateFluVaccinesOrderActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(updateFluVaccinesOrderActions.failed(error));
            return done();
        }
    },
    latest: false,
});
