import React from 'react';
import { DEFAULT_LOCALE } from '../../../config/i18n.config';
import { ITranslator } from '../../../models/general/i18n';

const TranslatorContext = React.createContext({
    translator: {} as ITranslator,
    locale: DEFAULT_LOCALE,
});

export default TranslatorContext;
