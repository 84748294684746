import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import { IActionHandlers } from '@snipsonian/redux/src/reducer/createReducer';
import { IBaseCookiesConfiguration, ICookieConsentState, IInitCookieConsentPayload } from './types';
import { STORE_COOKIE_CONSENTS, INIT_COOKIE_CONSENT } from './actionTypes';

/**
 * Right now the cookie/storage handling is done client-side ... we could also
 * reuse the functions of the CMS to let them handle the creation of the cookies
 * toggle-cookies and toggle-all-cookies
 *
 * https://github.com/Kunstmaan/KunstmaanCookieBundle/blob/master/src/Controller/LegalController.php
 */

export const DEFAULT_REDUCER_KEY = 'cookieConsent';

let ccReducerKey: string;
let ccInitialState: ICookieConsentState;

// eslint-disable-next-line max-len
export function initCookieConsentReducerBuildingBlocks<CConfig extends IBaseCookiesConfiguration, ExtraMetadata extends {} = {}>({
    reducerKey = DEFAULT_REDUCER_KEY,
    initialCookiesConfiguration,
}: {
    reducerKey: string;
    initialCookiesConfiguration: CConfig;
}) {
    ccReducerKey = reducerKey;

    ccInitialState = {
        cookies: initialCookiesConfiguration,
    };

    const actionHandlers: IActionHandlers<ICookieConsentState<CConfig, ExtraMetadata>> = {
        ...createActionHandlersForType<ICookieConsentState<CConfig, ExtraMetadata>>(INIT_COOKIE_CONSENT)
            .onTrigger<IInitCookieConsentPayload>(({ oldState, payload: { version } }) => {
                // if there is a new version reset to initial state
                if (!oldState.version || oldState.version < version) {
                    return {
                        ...(ccInitialState as ICookieConsentState<CConfig, ExtraMetadata>),
                    };
                }

                return {
                    ...oldState,
                };
            })
            .create(),
        ...createActionHandlersForType<ICookieConsentState<CConfig, ExtraMetadata>>(STORE_COOKIE_CONSENTS)
            .onTrigger<ICookieConsentState<CConfig, ExtraMetadata>>(({ payload: newState }) => newState)
            .create(),
    };

    return {
        key: ccReducerKey,
        initialState: (ccInitialState as ICookieConsentState<CConfig, ExtraMetadata>),
        actionHandlers,
    };
}

export function getCookieConsentReducerKey(): string {
    return ccReducerKey;
}

// eslint-disable-next-line max-len
export function getCookieConsentInitialState<CConfig extends IBaseCookiesConfiguration>(): ICookieConsentState<CConfig> {
    return ccInitialState as ICookieConsentState<CConfig>;
}
