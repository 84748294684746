import { object } from 'yup';
import { schema as contactSchema } from '../shared/ContactInput/editSchema';
import {
    schema as locationSchema,
} from '../FluVaccinesOrderWizard/wizard/Delivery/fluVaccineDeliverySchema';
import { ErrorTypes } from '../../../models/general/error';
import number from '../../../utils/libs/yup/number';
import string from '../../../utils/libs/yup/string';

export const fields = {
    amountVaccinesOrdered: 'amountVaccinesOrdered',
    orderDate: 'orderDate',
    referentionCode: 'referentionCode',
    remark: 'remark',
    contact: 'contact',
    location: 'location',
};

export const schema = object({
    [fields.amountVaccinesOrdered]: number().required(),
    [fields.orderDate]: string().required(),
    [fields.referentionCode]: string(),
    [fields.remark]: string(),
    [fields.contact]: object()
        .test(
            ErrorTypes.Required,
            'The \'${path}\' is not valid',
            isValidContact,
        ),
    [fields.location]: object()
        .test(
            ErrorTypes.Required,
            'The \'${path}\' is not valid',
            isValidLocation,
        ),
});

function isValidContact(value: { [field: string]: string }) {
    try {
        contactSchema.validateSync(value);
    } catch (validationError) {
        return false;
    }

    return true;
}

function isValidLocation(value: { [field: string]: string }) {
    try {
        locationSchema.validateSync(value);
    } catch (validationError) {
        return false;
    }

    return true;
}
