import dayjs from 'dayjs';
import React, { Component } from 'react';
import {
    CLOSED_COURSE_REGISTRATION_DUE_DATE,
    COURSE_REGISTRATION_DUE_DATE,
} from '../../../../../config/courses.config';
import {
    COURSE_ENROLL_WIZARD_STEP_ID,
    ICourse,
    ICourseEnrollWizardEntity,
    ICourseSession,
} from '../../../../../models/documentCenter/courses';
import { AsyncStatus } from '../../../../../models/general/redux';
import { skipToCourseEnrollWizardStepActions } from '../../../../../redux/documentCenter/courses/actions';
import {
    getSelectedCourseSession,
    getFetchCourseSessionAsyncInfo,
} from '../../../../../redux/documentCenter/courses/selectors';
import connect from '../../../../../utils/libs/redux/connect';
import Button from '../../../../common/buttons/Button/index';
import Translate from '../../../../common/Translate';
import { IRenderDetailFooterProps } from '../../../../common/widget/MasterWithDetail/typings';
import { isCourseOfTypeOpen } from '../../shared/courseUtils';
import { PassedRegistrationDueDateDialog } from '../../shared/PassedRegistrationDueDateDialog';
import { BASE_CLASS_NAME } from '../common';
import TinyLoader from '../../../../common/waiting/TinyLoader';

interface IPrivateProps {
    courseSession: ICourseSession;
    toRegisterCourseWizard: (course: ICourse, session: ICourseSession) => void;
}

interface IState {
    isPassedRegistrationDueDateDialogOpen: boolean;
}

class DetailFooter extends Component<IPrivateProps & IRenderDetailFooterProps<ICourse>, IState> {

    constructor(props: IPrivateProps & IRenderDetailFooterProps<ICourse>) {
        super(props);

        this.state = {
            isPassedRegistrationDueDateDialogOpen: false,
        };

        this.onRegister = this.onRegister.bind(this);
        this.onClosePassedRegistrationDueDateDialog = this.onClosePassedRegistrationDueDateDialog.bind(this);
    }

    public render() {
        const {
            detailData: course,
            detailAsyncInfo,
        } = this.props;

        const { isPassedRegistrationDueDateDialogOpen } = this.state;

        if (!course || course.countAmountAttendees >= course.maxAmountAttendees) {
            return null;
        }

        if (detailAsyncInfo.status !== AsyncStatus.Success) {
            return null;
        }

        return (
            <div className={`${BASE_CLASS_NAME}__details__footer`}>
                <PassedRegistrationDueDateDialog
                    open={isPassedRegistrationDueDateDialogOpen}
                    onClose={this.onClosePassedRegistrationDueDateDialog}
                    isClosedCourse={isCourseOfTypeOpen(course)}
                />
                {!isCourseOfTypeOpen(course) ? (
                    this.renderAddAttendeeButton()
                ) : (
                    <TinyLoader asyncInfoSelector={getFetchCourseSessionAsyncInfo}>
                        {this.renderAddAttendeeButton()}
                    </TinyLoader>
                )}
            </div>
        );
    }

    private onRegister() {
        const {
            detailData: course,
            toRegisterCourseWizard,
            courseSession,
        } = this.props;

        if (dayjs(course.startDate).isBefore(COURSE_REGISTRATION_DUE_DATE) && isCourseOfTypeOpen(course)) {
            this.setState({ isPassedRegistrationDueDateDialogOpen: true });
            return;
        }

        if (!isCourseOfTypeOpen(course) && dayjs(course.startDate).isBefore(CLOSED_COURSE_REGISTRATION_DUE_DATE)) {
            this.setState({ isPassedRegistrationDueDateDialogOpen: true });
            return;
        }

        toRegisterCourseWizard(course, courseSession);
    }

    private onClosePassedRegistrationDueDateDialog() {
        this.setState({ isPassedRegistrationDueDateDialogOpen: false });
    }

    private renderAddAttendeeButton() {
        return (
            <Button
                id="to-add-attendee"
                typeName="secondary"
                onClick={this.onRegister}
            >
                <span>
                    <Translate msg="document_center.courses.planned.detail.footer.add_attendee" />
                </span>
            </Button>
        );
    }
}

export default connect<IPrivateProps, IRenderDetailFooterProps<ICourse>>({
    stateProps: (state) => ({
        courseSession: getSelectedCourseSession(state),
    }),
    dispatchProps: (dispatch) => {
        return {
            toRegisterCourseWizard: (course: ICourse, session: ICourseSession) => {
                dispatch(skipToCourseEnrollWizardStepActions.trigger({
                    wizardPayload: {
                        step: COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE,
                        course: {
                            name: course.courseName,
                            description: course.description,
                            id: course.id,
                        },
                    },
                    entity: {
                        session: {
                            coursesOrganizedId: course.coursesOrganizedId,
                            start: course.startDate,
                            stop: course.endDate,
                            price: session.price,
                            amountPE: session.amountPE,
                            maxAmount: course.maxAmountAttendees,
                            attendees: course.countAmountAttendees,
                            selectedFromOverview: true,
                        },
                        location: {
                            ...session.address,
                            selectedFromOverview: true,
                            selectedClosedCourseFromOverview: !isCourseOfTypeOpen(course),
                        },
                    } as ICourseEnrollWizardEntity,
                }));
            },
        };
    },
})(DetailFooter);
