import { Meta } from 'redux-first-router';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';
export { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';
import { ITraceableApiError } from './error';
import ROUTE_KEYS from '../../routeKeys';
import { REDUCER_KEYS } from '../../config/redux.config';

export interface IAction<P> {
    type: string;
    payload: P;
}

export interface ILocationAction<P, Q = {}> extends IAction<P> {
    type: ROUTE_KEYS;
    meta?: Meta & { query?: Q };
    payload: P & { disableScrollToTop?: boolean };
}

export interface IInitAppActionPayload {
    triggeredOnApplicationInit: boolean;
}

export interface IShouldRefreshDataPayload {
    shouldRefreshData: boolean;
    // if this epicId is set, only the process hook of the epic that originally set the shouldRefreshData to false
    // will not be executed (process hook of other epics will be executed)
    noDataRefreshEpicIds?: string[];
    noDataRefreshOnlyInTheseReducers?: REDUCER_KEYS[];
}

export type IActionHandler<S, P> = (input: {state: S, action: IAction<P>}) => S;

export type ICreateNewState<S, P> = (input: {oldState: S, payload?: P}) => S;

export interface IAsyncFieldInfo {
    status: AsyncStatus;
    error: ITraceableApiError;
}

export interface IAsyncFetchField<Data> {
    data: Data;
    isFetching: boolean;
    error: ITraceableApiError;
}

export interface IAsyncDoField {
    isDoing: boolean;
    isDone: boolean;
    error: ITraceableApiError;
}

export interface IAsyncFetchState<Data> extends IAsyncFieldInfo {
    data: Data;
}

export interface ITraceableAsyncRequestPayload {
    requestId: string;
}
