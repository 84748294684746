import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    createActionHandlersForType,
    registerReducer,
} from '../../index';
import {
    FETCH_EMPLOYEES,
    SET_TOTAL_EMPLOYEES_COUNT,
    FETCH_COMPANY_FUNCTION_EMPLOYEES,
    FETCH_EMPLOYEES_WITHOUT_EMAIL,
} from './types';
import { UPDATE_EMPLOYEE } from '../info/types';
import {
    IEmployee,
    IFetchEmployeesData,
    IUpdateEmployeeSucceededPayload,
    IEmployeeWithoutEmail,
} from '../../../models/admin/employee';
import { ICompanyFunctionEmployee } from '../../../models/admin/companyFunctions';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.EMPLOYEES;

export interface IReducerState {
    employees: IAsyncFetchField<IEmployee[]>;
    totalEmployeesCount: number;
    companyFunctionEmployees: IAsyncFetchField<ICompanyFunctionEmployee[]>;
    employeesWithoutEmail: IAsyncFetchField<IEmployeeWithoutEmail[]>;
}

const initialState: IReducerState = {
    employees: getAsyncFetchInitialState(),
    totalEmployeesCount: 0,
    companyFunctionEmployees: getAsyncFetchInitialState(),
    employeesWithoutEmail: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IEmployee[], IReducerState, IEmployee[]>({
        baseActionType: FETCH_EMPLOYEES,
        fieldName: 'employees',
        overrideTriggerActionType: [ROUTE_KEYS.R_EMPLOYEES, FETCH_EMPLOYEES],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createActionHandlersForType<IReducerState>(SET_TOTAL_EMPLOYEES_COUNT)
        .onTrigger<Partial<IFetchEmployeesData>>(({ oldState, payload }) => {
            return {
                ...oldState,
                totalEmployeesCount: payload.count,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(UPDATE_EMPLOYEE)
        .onSuccess<IUpdateEmployeeSucceededPayload>(({ oldState, payload }) => {
            const successPayload = payload as IUpdateEmployeeSucceededPayload;

            function updateEmployeeData(employee) {
                if (employee.id === successPayload.id) {
                    return {
                        ...employee,
                        ...successPayload,
                    };
                }
                return employee;
            }

            return {
                ...oldState,
                employees: {
                    ...oldState.employees,
                    data: Array.isArray(oldState.employees.data)
                        ? oldState.employees.data.map(updateEmployeeData)
                        : oldState.employees.data,
                },
                companyFunctionEmployees: {
                    ...oldState.companyFunctionEmployees,
                    data: Array.isArray(oldState.companyFunctionEmployees.data)
                        ? oldState.companyFunctionEmployees.data.map(updateEmployeeData)
                        : oldState.companyFunctionEmployees.data,
                },
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<ICompanyFunctionEmployee[], IReducerState, ICompanyFunctionEmployee[]>({
        baseActionType: FETCH_COMPANY_FUNCTION_EMPLOYEES,
        fieldName: 'companyFunctionEmployees',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeWithoutEmail[], IReducerState, IEmployeeWithoutEmail[]>({
        baseActionType: FETCH_EMPLOYEES_WITHOUT_EMAIL,
        fieldName: 'employeesWithoutEmail',
        overrideTriggerActionType: [ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS],
        resetDataOnTrigger: false,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
