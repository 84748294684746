import React from 'react';
import { connect } from '../../../index';
import {
    CompanyAvailabilityType,
    ICompanyAvailabilities,
} from '../../../../models/admin/companyInfo';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { ITranslator } from '../../../../models/general/i18n';
import { IState } from '../../../../redux/IState';
import {
    getCompanyAvailabilities,
    getCompanyMedExamAvailabilitiesAsyncInfo,
    getCompanyRiskMgmtAvailabilitiesAsyncInfo,
    getReplaceCompanyMedExamAvailabilitiesAsyncInfo,
    getReplaceCompanyRiskMgmtAvailabilitiesAsyncInfo,
} from '../../../../redux/company/info/selectors';
import {
    replaceCompanyAvailabilitiesReset,
    replaceCompanyAvailabilities,
} from '../../../../redux/company/info/actions';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import AvailabilityOverview, { ISaveAvailabilitiesProps, IWithinSidePanel } from '../shared/AvailabilityOverview';
import TinyLoader from '../../../common/waiting/TinyLoader';

const CLASS_NAME = 'Availabilities';

interface IPublicProps {
    type: CompanyAvailabilityType;
    companyNameSelector: (state: IState) => string;
    companyCodeSelector: (state: IState) => string;
    isCopyToAllSeatsAllowedSelector?: (state: IState) => boolean;
    withinSidePanel?: IWithinSidePanel;
}

interface IPrivateProps {
    availabilities: ICompanyAvailabilities;
    availabilitiesAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    companyName: string;
    isCopyToAllSeatsAllowed: boolean;
    mayEdit: boolean;
    saveAsyncInfo: IAsyncFieldInfo;
    translator: ITranslator;
    saveAvailabilities: (saveProps: ISaveAvailabilitiesProps) => void;
    resetSaveAvailabilities: () => void;
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        const {
            type: availabilityType,
            companyNameSelector,
            isCopyToAllSeatsAllowedSelector,
        } = publicProps;

        return (state) => {
            return {
                availabilities: getCompanyAvailabilities(state, availabilityType),
                availabilitiesAsyncInfoSelector:
                    availabilityType === CompanyAvailabilityType.MedicalExaminations ?
                    getCompanyMedExamAvailabilitiesAsyncInfo :
                    getCompanyRiskMgmtAvailabilitiesAsyncInfo,
                companyName: companyNameSelector(state),
                isCopyToAllSeatsAllowed: isCopyToAllSeatsAllowedSelector
                    ? isCopyToAllSeatsAllowedSelector(state)
                    : false,
                mayEdit: hasRequiredAccessLevels(state, { company: 'W' }),
                saveAsyncInfo: availabilityType === CompanyAvailabilityType.MedicalExaminations ?
                    getReplaceCompanyMedExamAvailabilitiesAsyncInfo(state) :
                    getReplaceCompanyRiskMgmtAvailabilitiesAsyncInfo(state),
                translator: getTranslatorDeprecated(state),
            };
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                saveAvailabilities: (saveProps: ISaveAvailabilitiesProps) => {
                    dispatch(replaceCompanyAvailabilities({
                        availabilityType: publicProps.type,
                        payload: {
                            ...saveProps,
                            companyCode: publicProps.companyCodeSelector(getState()),
                        },
                    }));
                },
                resetSaveAvailabilities: () => {
                    dispatch(replaceCompanyAvailabilitiesReset(publicProps.type));
                },
            };
        };
    },
})(Availabilities);

function Availabilities(props: IPrivateProps & IPublicProps) {
    return (
        <div className={CLASS_NAME}>
            <TinyLoader asyncInfoSelector={props.availabilitiesAsyncInfoSelector} >
                <AvailabilityOverview
                    id="AvailabilityOverview"
                    availabilityType={props.type}
                    availabilities={props.availabilities}
                    companyName={props.companyName}
                    isCopyToAllSeatsAllowed={props.isCopyToAllSeatsAllowed}
                    mayEdit={props.mayEdit}
                    saveAsyncInfo={props.saveAsyncInfo}
                    translator={props.translator}
                    onSaveAvailabilities={props.saveAvailabilities}
                    resetSaveAvailabilities={props.resetSaveAvailabilities}
                    withinSidePanel={props.withinSidePanel}
                />
            </TinyLoader>
        </div>
    );
}
