import React, { ReactNode } from 'react';
import './account-settings.scss';
import PageHeader from '../../appShell/PageHeader';
import connect from '../../../utils/libs/redux/connect';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import { ITabItem } from '../../../models/general/navigation';
import ROUTE_KEYS from '../../../routeKeys';
import gearIllu from '../../assets/img/icons/gear.svg';
import { hasPermission } from '../../../redux/auth/selectors';
import { Permission } from '../../../models/auth/authorisation';

interface IPrivateProps {
    canManageUsers: boolean;
}

interface IPublicProps {
    children: ReactNode;
}

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            canManageUsers: hasPermission(state, Permission.CAN_ADD_USER),
        };
    },
})(AccountSettingsTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function AccountSettingsTemplate(props: IPrivateProps & IPublicProps) {
    const {
        children,
        canManageUsers,
    } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'account.account_settings.profile.tab_title',
        linkTo: ROUTE_KEYS.R_ACCOUNT_SETTINGS,
    }];

    if (canManageUsers) {
        TABS.push({
            translationKey: 'account.account_settings.manage_users.tab_title',
            linkTo: ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS,
        });
    }

    return (
        <WithMenuTemplate>
            <div className="AccountSettings">
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS}
                    title="account.account_settings.title"
                    type="white"
                    titleIcon={gearIllu}
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
