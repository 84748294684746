import isFunction from '@snipsonian/core/es/is/isFunction';
import isObjectPure from '@snipsonian/core/es/is/isObjectPure';

export default function isPromise(val) {
    return !!val
        && (isObjectPure(val) || isFunction(val))
        && isFunction(val.then);
}

export function isPromiseTypeGuard<PromiseResult = any>(val): val is Promise<PromiseResult> {
    return isPromise(val);
}
