import { IN_COMPANY_MEDICAL_CENTER_CODE, IN_COMPANY_MEDICAL_CENTER_ID } from '../../../config/planning.config';
import {
    TLocationTypeaheadItems,
} from '../../../views/administration/Employees/shared/LocationTypeahead/LocationTypeahead.type';

export function filterOutInfirmMedicalCenters(medicalCenters: TLocationTypeaheadItems) {
    return medicalCenters.filter(
        (item) => {
            return item.code !== IN_COMPANY_MEDICAL_CENTER_CODE || item.id !== IN_COMPANY_MEDICAL_CENTER_ID;
        },
    );
}
