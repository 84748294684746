import { makeAsyncFetchInfoSelector, NO_RERENDER, makeAsyncDoInfoSelector } from '../..';
import { getReducerState, IState } from '../../IState';
import { IReducerState, reducerKey } from './reducer';
import {
    IWorkPostCardWizardData,
    IWorkPostCardBlankForms,
    IWorkPostCardDefaultValues,
    IWorkPostFile,
} from '../../../models/documentCenter/workPostCards';
import { ITranslator } from '../../../models/general/i18n';
import { getTranslatorForContext } from '../../i18n/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Work post card wizard
const getWorkPostCardWizardData = (state: IState) =>
    (reducerState(state).workPostCardWizardData || NO_RERENDER.EMPTY_OBJECT) as IWorkPostCardWizardData;

export const getWorkPostCardWizardStepId = (state: IState) =>
    getWorkPostCardWizardData(state).stepId;

export const getWorkPostCardWizardEntity = (state: IState) =>
    getWorkPostCardWizardData(state).entity;

export const getWorkPostCardWizardFunctionId = (state: IState) =>
    getWorkPostCardWizardData(state).functionId;

export const getWorkPostCardWizardLocale = (state: IState) =>
    getWorkPostCardWizardData(state).locale;

export const getWorkPostCardWizardTranslator = (state): ITranslator => {
    const wizardLocale = getWorkPostCardWizardLocale(state);
    return getTranslatorForContext(state, wizardLocale);
};

// Work post card blank forms
const getWorkPostCardBlankFormsAsyncField = (state: IState) =>
    reducerState(state).workPostCardBlankForms;

export const getWorkPostCardBlankFormsAsyncInfo =
    makeAsyncFetchInfoSelector(getWorkPostCardBlankFormsAsyncField);

export const getWorkPostCardBlankForms = (state: IState) =>
    (getWorkPostCardBlankFormsAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT) as IWorkPostCardBlankForms;

export const areWorkPostCardBlankFormsAvailable = (state: IState) =>
    getWorkPostCardBlankFormsAsyncField(state).data !== null;

// Create work post card form
const getCreateWorkPostCardFormAsyncField = (state: IState) =>
    reducerState(state).createWorkPostCardForm;

export const getCreateWorkPostCardFormAsyncInfo =
    makeAsyncDoInfoSelector(getCreateWorkPostCardFormAsyncField);

// Work post card default values
const getWorkPostCardDefaultValuesAsyncField = (state: IState) =>
    reducerState(state).workPostCardDefaultValues;

export const getWorkPostCardDefaultValuesAsyncInfo =
    makeAsyncFetchInfoSelector(getWorkPostCardDefaultValuesAsyncField);

export const getWorkPostCardDefaultValues = (state: IState) =>
    (getWorkPostCardDefaultValuesAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT) as IWorkPostCardDefaultValues;

// Work post card files of employee
const getWorkPostFileOfEmployeeAsyncField = (state: IState) =>
    reducerState(state).workPostCardFileOfEmployee;

export const getWorkPostFileOfEmployeeAsyncInfo =
    makeAsyncFetchInfoSelector(getWorkPostFileOfEmployeeAsyncField);

export const getWorkPostFileOfEmployee = (state: IState) =>
    (getWorkPostFileOfEmployeeAsyncField(state).data || NO_RERENDER.EMPTY_LIST) as IWorkPostFile;
