import React from 'react';
import Button from '../../../../../common/buttons/Button';
import Translate from '../../../../../common/Translate';
import Icon from '../../../../../common/icons/Icon';
import { IRenderMasterActionsProps } from '../../../../../common/widget/MasterWithDetail/typings';
import connect from '../../../../../../utils/libs/redux/connect';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';

interface IPrivateProps {
    navigateToAddUser: () => void;
}

function AddUserAccountButton(props: IPrivateProps & IRenderMasterActionsProps) {
    const {
        isFetchingMaster,
    } = props;

    return (
        <Button
            id="add-user-account-button"
            typeName="text"
            className="AddButton"
            disabled={isFetchingMaster}
            onClick={() => props.navigateToAddUser()}
        >
            <Icon typeName="plus" circle />
            <span>
                {/*eslint-disable-next-line max-len*/}
                <Translate msg="account.account_settings.manage_users.actions.add_user" />
            </span>
        </Button>
    );
}

export default connect<IPrivateProps, IRenderMasterActionsProps>({
    dispatchProps: (dispatch) => ({
        navigateToAddUser: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD)),
    }),
})(AddUserAccountButton);
