import React from 'react';
import Translate from '../../../common/Translate';
import { connect } from '../../../index';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { getSeatsAndDivisionsForSelectedCompany } from '../../../../redux/company/selected/selectors';
import {
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    ITransformToActiveFiltersProps,
    IClientSideFilterOfListDataProps,
} from '../../../common/widget/MasterWithDetail/typings';
import SeatTypeahead from '../../../administration/Employees/shared/SeatTypeahead';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { ListItem } from '../../../../models/general/list';
import { IColumnNames } from '../Overview';
import MessageTypeTypeahead from './MessageTypeTypehead';
import { DEFAULT_USER_MESSAGES_FILTERS } from '../../../../config/inbox.config';
import { IUserMessagesFilters } from '../../../../models/user/inbox';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';

interface IPrivateProps {
    showSeatsFilter: boolean;
}

export function MessagesSearch(renderProps: IRenderSearchContentProps<IUserMessagesFilters>) {
    const {
        formRenderProps,
    } = renderProps;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <FloatableTextInputWrapper floatLabel>
                    <TextInput
                        id="filter-global-search"
                        name="search"
                        placeholder={translator('common.search')}
                        value={formRenderProps.values.search || ''}
                        onChange={formRenderProps.handleChange}
                    />
                    <label htmlFor="filter-global-search">
                        <Translate msg="common.search" />
                    </label>
                </FloatableTextInputWrapper>
            )}
        </TranslatorContext.Consumer>
    );
}

export function getDefaultQueryParams() {
    return DEFAULT_USER_MESSAGES_FILTERS;
}

function MessagesFilterComp(props: IPrivateProps & IRenderFilterContentProps<{}, IUserMessagesFilters>) {
    const { values, setFieldValue } = props.formRenderProps;

    function setFilter(key: keyof IUserMessagesFilters, value: string) {
        setFieldValue(key, value);
    }

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <>
                    <FloatableTextInputWrapper floatLabel>
                        <MessageTypeTypeahead
                            id="messages-filter-category"
                            name="categoryFilter"
                            placeholder={translator('account.message_center.overview.filter.category')}
                            value={values.category || ''}
                            initialFilter={values.category || ''}
                            onItemSelected={(messageTypeId, description) => {
                                setFilter('category', description);
                            }}
                            onFilter={(filter) =>
                                setFilter('category', filter)}
                        >
                            <label htmlFor="messages-filter-category">
                                <Translate msg="account.message_center.overview.filter.category" />
                            </label>
                        </MessageTypeTypeahead>
                    </FloatableTextInputWrapper>

                    {props.showSeatsFilter &&
                        <FloatableTextInputWrapper floatLabel>
                            <SeatTypeahead
                                id="messages-filter-seat"
                                name="seatFilter"
                                placeholder={translator('account.message_center.overview.filter.seat')}
                                value={values.seat || ''}
                                initialFilter={values.seat || ''}
                                onItemSelected={(companyCode, name) =>
                                    setFilter('seat', name)}
                                onFilter={(filter) =>
                                    setFilter('seat', filter)}
                                isFreeText={true}
                            >
                                <label htmlFor="messages-filter-seat">
                                    <Translate msg="account.message_center.overview.filter.seat" />
                                </label>
                            </SeatTypeahead>
                        </FloatableTextInputWrapper>
                    }

                    <StartEndDateFilter
                        translationKeyPrefix="account.message_center.overview.filter"
                        formRenderProps={props.formRenderProps}
                    />
                </>
            )}
        </TranslatorContext.Consumer>
    );
}

export const MessagesFilter = connect<IPrivateProps, IRenderFilterContentProps<{}, IUserMessagesFilters>>({
    stateProps: (state) => {
        return {
            showSeatsFilter: getSeatsAndDivisionsForSelectedCompany(state).length > 1,
        };
    },
})(MessagesFilterComp);

export function messagesTransformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IUserMessagesFilters>,
) {
    return createGenericActiveFilters<IUserMessagesFilters, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'account.message_center.overview.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            category: {
                show: true,
            },
            seat: {
                show: true,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_USER_MESSAGES_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_USER_MESSAGES_FILTERS.startDate,
            },
        },
    });
}

export function messagesClientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IUserMessagesFilters>,
) {
    const { listItems, filterValues } = filterProps;

    let filteredItems = listItems;

    if (filterValues.seat) {
        filteredItems = filteredItems.filter((listItem) => {
            const companyName = listItem.columns.companyName as unknown as string;
            return companyName.includes(filterValues.seat);
        });
    }

    if (filterValues.category) {
        filteredItems = filteredItems.filter((listItem) => {
            const category = listItem.columns.category as unknown as string;
            return category.includes(filterValues.category);
        });
    }

    return filteredItems;
}
