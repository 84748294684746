import { createEpic } from '../..';
import {
    getWorkPostCardWizardStepId,
    getWorkPostCardWizardFunctionId,
    areWorkPostCardBlankFormsAvailable,
    getWorkPostCardWizardLocale,
    getWorkPostCardWizardEntity,
} from './selectors';
import {
    fetchWorkPostCardBlankFormsActions,
    createWorkPostCardFormActions,
    fetchWorkPostCardDefaultValuesActions,
    updateWorkPostCardWizardEntity,
    fetchWorkPostFileOfEmployeeActions,
} from './actions';
import { fetchCompanyFunctionIsco } from '../../company/functions/actions';
import { redirectToRoute } from '../../location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IWorkPostCardWizardPayload,
    WORK_POST_CARD_WIZARD_STEP_ID,
    IWorkPostCardWizardEntity,
    ICreateWorkPostCardFormPayload,
    IFetchWorkPostCardDefaultValuesRequestPayload,
    IWorkPostCardDefaultValues,
    IFetchWorkPostFileOfEmployeePayload,
} from '../../../models/documentCenter/workPostCards';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import {
    CREATE_WORK_POST_CARD_FORM,
    FETCH_WORK_POST_CARD_DEFAULT_VALUES,
    FETCH_WORK_POST_FILE_OF_EMPLOYEE,
} from './types';
import { getSelectedCompanySeat } from '../../company/selected/selectors';
import { isFalsyOrEmptyObject } from '../../../utils/core/object/isEmptyObject';
import { SubmittedFormActionType } from '../../../config/analytics.config';
import { getCreateWorkPostCardWizardSteps } from '../../../config/navigation/wizardStepsMap';

const ACTION_TYPES_THAT_FETCH_WORKPOSTCARD_BLANKFORMS_IF_NOT_AVAILABLE = [
    ...getRouteKeysThatBelongToGroup(ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE),
];

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here the work post card overview.
 */
// validateWorkPostCardWizardStepIdEpic
createEpic<IWorkPostCardWizardPayload>({
    onActionType: ROUTE_KEYS.R_WORK_POST_CARD_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const WORK_POST_CARD_STEP_IDS = getCreateWorkPostCardWizardSteps().stepIds;
        if (!WORK_POST_CARD_STEP_IDS.includes(requestedStep)) {
            return next(redirectToRoute(ROUTE_KEYS.R_WORK_POST_CARDS));
        }

        // check no step skipped
        const currentStep = getWorkPostCardWizardStepId(getState());
        const currentStepIndex = WORK_POST_CARD_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = WORK_POST_CARD_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(redirectToRoute(ROUTE_KEYS.R_WORK_POST_CARDS));
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringWorkPostCardWizardEpic
createEpic<IWorkPostCardWizardPayload>({
    onActionType: ROUTE_KEYS.R_WORK_POST_CARD_NEW,
    async processMultiple({ action, getState }, dispatch, done) {
        const { step } = action.payload;
        const state = getState();
        const wizardEntity = getWorkPostCardWizardEntity(state);
        const wizardFunctionId = getWorkPostCardWizardFunctionId(state);
        const wizardLocale = getWorkPostCardWizardLocale(state);

        if (!wizardFunctionId) {
            // probably because of a deep link directly to the first step
            dispatch(redirectToRoute(ROUTE_KEYS.R_WORK_POST_CARDS));
            return done();
        }

        if (step === WORK_POST_CARD_WIZARD_STEP_ID.DATA && isFalsyOrEmptyObject(wizardEntity)) {
            dispatch(fetchWorkPostCardDefaultValuesActions.trigger({}));
        }

        if (step === WORK_POST_CARD_WIZARD_STEP_ID.PROPERTIES) {
            dispatch(fetchCompanyFunctionIsco({ id: wizardFunctionId, localeOverride: wizardLocale }));
        }

        if (step === WORK_POST_CARD_WIZARD_STEP_ID.VALIDATE) {
            dispatch(createWorkPostCardFormActions.reset({}));
        }

        return done();
    },
    latest: false,
});

/**
 * On this route, also the functions are retrieved (see other epic)
 */
// fetchWorkPostCardBlankFormsEpic
createEpic<{}>({
    onActionType: ACTION_TYPES_THAT_FETCH_WORKPOSTCARD_BLANKFORMS_IF_NOT_AVAILABLE,
    // no refreshDataIf because that would also have an effect on the other epic(s) listening to the same route
    // but we do the check inside the processMultiple
    async processMultiple({ getState, action, api }, dispatch, done) {
        if (!areWorkPostCardBlankFormsAvailable(getState())) {
            try {
                dispatch(fetchWorkPostCardBlankFormsActions.trigger({}));

                const blankForms = await api.documentCenter.workPostCard.fetchWorkPostCardBlankForms();

                dispatch(fetchWorkPostCardBlankFormsActions.succeeded(blankForms));
            } catch (error) {
                dispatch(fetchWorkPostCardBlankFormsActions.failed(error));
            }
        }

        done();
    },
    latest: true,
});

// createWorkPostCardFormEpic
createEpic<{}>({
    onActionType: CREATE_WORK_POST_CARD_FORM,
    async processMultiple({ getState, action, api }, dispatch, done) {
        try {
            const state = getState();
            const wizardEntity = getWorkPostCardWizardEntity(state);
            const functionId = getWorkPostCardWizardFunctionId(state);
            const selectedSeatCompanyCode = getSelectedCompanySeat(state).companySeat.company.companyCode;
            const wizardLocale = getWorkPostCardWizardLocale(state);

            const payload =
                mapWorkPostCardWizardEntityToRequestPayload(wizardEntity, functionId, selectedSeatCompanyCode);

            const workPostCardForm =
                await api.documentCenter.workPostCard.createWorkPostCardForm(payload, wizardLocale);

            dispatch(createWorkPostCardFormActions.succeeded(
                workPostCardForm,
                {
                    logFormSubmissionEvent: SubmittedFormActionType.WORK_POST_CARD_CREATED,
                },
            ));
        } catch (error) {
            dispatch(createWorkPostCardFormActions.failed(error));
        }

        return done();
    },
    latest: false,
});

// fetchWorkPostCardDefaultValuesEpic
createEpic({
    onActionType: FETCH_WORK_POST_CARD_DEFAULT_VALUES,
    async processMultiple({ getState, action, api }, dispatch, done) {
        try {
            const state = getState();
            const functionId = getWorkPostCardWizardFunctionId(state);
            const selectedSeatCompanyCode = getSelectedCompanySeat(state).companySeat.company.companyCode;
            const workPostCardLocale = getWorkPostCardWizardLocale(state);

            const payload: IFetchWorkPostCardDefaultValuesRequestPayload = {
                company: {
                    companyCode: selectedSeatCompanyCode,
                },
                companyFunction: {
                    id: functionId,
                },
            };

            const uniCodeDefaultValues = await api.documentCenter.workPostCard.fetchWorkPostCardDefaultValues(
                payload,
                workPostCardLocale,
            );

            // eslint-disable-next-line max-len
            const defaultValues = JSON.parse(JSON.stringify(uniCodeDefaultValues).replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
                String.fromCharCode(parseInt(b, 16))));

            dispatch(updateWorkPostCardWizardEntity(mapWorkPostDefaultValuesToEntity(defaultValues)));

            dispatch(fetchWorkPostCardDefaultValuesActions.succeeded(defaultValues));
        } catch (error) {
            dispatch(fetchWorkPostCardDefaultValuesActions.failed(error));
        }

        return done();
    },
    latest: false,
});

function mapWorkPostCardWizardEntityToRequestPayload(
    entity: Partial<IWorkPostCardWizardEntity>,
    functionId: number,
    selectedSeatCompanyCode: string,
): ICreateWorkPostCardFormPayload {
    const {
        baseData, education, identification, maternityProtection, risks, workClothes, workPostProperties,
    } = entity;
    return {
        company: {
            companyCode: selectedSeatCompanyCode,
            phone: baseData.userPhoneContact,
        },
        companyFunction: {
            id: functionId,
            description: baseData.identificationIdentification,
        },
        employmentAgency: {
            name: identification.agencyName,
            phone: identification.agencyPhoneContact,
            edpbw: identification.agencyPbw,
            dateOfIssue: identification.agencyDateOfIssue,
            address: identification.agencyAddress,
        },
        temporaryWorker: {
            name: identification.workerName,
            firstName: identification.workerFirstName,
            phone: identification.workerPhone,
            birthDate: identification.workerDateOfBirth,
            qualification: identification.workerQualification,
        },
        workPostProperties: {
            jobFunction: workPostProperties.jobFunction,
            qualification: workPostProperties.qualification,
            localization: workPostProperties.localization,
            usedWorkTools: workPostProperties.usedWorkTools,
            forbiddenForYoungPeople: workPostProperties.forbiddenForYoungPeople,
        },
        maternityProtection: {
            breastfeedingAdjustmentWorkstation: maternityProtection.breastfeedingAdjustment,
            breastfeedingRemovalPeriod: maternityProtection.breastfeedingRemovalPeriod,
            pregnantAdjustmentWorkstation: maternityProtection.pregnantAdjustment,
            pregnantRemovalPeriod: maternityProtection.pregnantRemovalPeriod,
        },
        workClothes: {
            checkTrousers: workClothes.trousers,
            checkOverall: workClothes.overall,
            checkKeel: workClothes.keel,
            checkShoe: workClothes.shoes,
            checkSeatBelt: workClothes.seatbelt,
            checkHelmet: workClothes.helmet,
            checkGlove: workClothes.gloves,
            checkMask: workClothes.mask,
            checkGlasses: workClothes.glasses,
            checkShells: workClothes.shells,
            checkOintments: workClothes.ointments,
            checkSpecificEquipment: workClothes.specificEquipment,
            checkOthers: !!workClothes.otherProtection,
            others: workClothes.otherProtection,
        },
        education: {
            priorInstruction: education.priorInstruction,
            acquiredTraining: education.acquiredTraining,
            requiredTraining: education.requiredTraining,
        },
        healthSurveillance: {
            checkYes: risks.healthSurveillance,
            checkNo: !risks.healthSurveillance,
            checkSafetyFunction: risks.safetyFunction,
            checkIncreasedVigilanceFunction: risks.increasedVigilanceFunction,
            chemical: risks.chemical,
            checkNoisePhysicalResources: risks.checkNoise,
            noisePhysicalResources: risks.noise,
            checkTemperaturePhysicalResources: risks.checkTemperature,
            temperaturePhysicalResources: risks.temperature,
            checkIonizingPhysicalResources: risks.checkIonizing,
            ionizingPhysicalResources: risks.ionizing,
            checkOtherPhysicalResources: risks.checkOtherPhysicalResources,
            otherPhysicalResources: risks.otherPhysicalResources,
            biologicalResources: risks.biologicalResources,
            vaccinations: risks.vaccinations,
            checkWorkStationLoad: risks.checkWorkStationLoad,
            workStationLoad: risks.workStationLoad,
            checkLiftingLoad: risks.checkLiftingLoad,
            liftingLoad: risks.liftingLoad,
            checkOthersLoad: risks.checkOthersLoad,
            othersLoad: risks.othersLoad,
            nightOrShiftWork: risks.nightOrShiftWork,
            nightOrShiftWorkExtra: risks.nightOrShiftWorkRisks,
            psychosocialLoad: risks.psychosocialLoad,
            associatedActivities: risks.associatedActivities,
            youngPeople: risks.youngPeople,
        },
    };
}

function mapWorkPostDefaultValuesToEntity(
    defaultValues: IWorkPostCardDefaultValues,
): Partial<IWorkPostCardWizardEntity> {
    const {
        healthSurveillance, identification, user, workPostProperties,
    } = defaultValues;
    return {
        baseData: {
            identificationDate: identification.date.value,
            identificationIdentification: identification.identification.value,
            userAddress: user.address.value,
            userExternalPbw: user.edpbw.value,
            userName: user.name.value,
            userPhoneContact: user.phoneContactPerson.value,
        },
        risks: {
            healthSurveillance: healthSurveillance.checkYes.value === '1',
            safetyFunction: healthSurveillance.checkSafetyFunction.value === '1',
            increasedVigilanceFunction: healthSurveillance.checkIncreasedVigilanceFunction.value === '1',
            chemical: healthSurveillance.chemical.value,
            checkNoise: healthSurveillance.checkNoisePhysicalResources.value === '1',
            noise: healthSurveillance.noisePhysicalResources.value,
            checkTemperature: healthSurveillance.checkTemperaturePhysicalResources.value === '1',
            temperature: healthSurveillance.temperaturePhysicalResources.value,
            checkIonizing: healthSurveillance.checkIonizingPhysicalResources.value === '1',
            ionizing: healthSurveillance.ionizingPhysicalResources.value,
            checkOtherPhysicalResources: healthSurveillance.checkOtherPhysicalResources.value === '1',
            otherPhysicalResources: healthSurveillance.otherPhysicalResources.value,
            biologicalResources: healthSurveillance.biologicalResources.value,
            vaccinations: healthSurveillance.vaccinations.value,
            checkWorkStationLoad: healthSurveillance.checkWorkStationLoad.value === '1',
            workStationLoad: healthSurveillance.workStationLoad.value,
            checkLiftingLoad: healthSurveillance.checkLiftingLoad.value === '1',
            liftingLoad: healthSurveillance.liftingLoad.value,
            checkOthersLoad: healthSurveillance.checkOthersLoad.value === '1',
            othersLoad: healthSurveillance.othersLoad.value,
            nightOrShiftWork: healthSurveillance.nightOrShiftWork.value,
            nightOrShiftWorkRisks: healthSurveillance.nightOrShiftWorkExtra.value,
            psychosocialLoad: healthSurveillance.psychosocialLoad.value,
            associatedActivities: healthSurveillance.associatedActivities.value,
            youngPeople: healthSurveillance.youngPeople.value,
        },
        workPostProperties: {
            jobFunction: workPostProperties.jobFunction.value,
            forbiddenForYoungPeople: '',
            localization: '',
            qualification: '',
            usedWorkTools: '',
        },
    };
}

// fetchWorkPostCardFilesEpic
createEpic<IFetchWorkPostFileOfEmployeePayload>({
    onActionType: FETCH_WORK_POST_FILE_OF_EMPLOYEE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const workPostFile = await api.documentCenter.workPostCard.fetchWorkPostFileOfEmployee(action.payload);
            dispatch(fetchWorkPostFileOfEmployeeActions.succeeded(workPostFile));
        } catch (error) {
            dispatch(fetchWorkPostFileOfEmployeeActions.failed(error));
        }
        done();
    },
    latest: true,
});
