import { Store } from 'redux';
import { IState } from '../index';
import { isLoggedIn } from '../auth/selectors';
import { redirectToLogout } from '../location/actions';
import { getLocationState } from '../location/selectors';
import { setErrorHandler } from '../../utils/api/requestWrapper';
import HTTP_STATUS from '../../utils/api/httpStatus';
import { triggerErrorNotification } from '../notifications/actions';

export default (store: Store<IState>) => {
    setErrorHandler((error) => {
        if (error) {
            const state = store.getState();

            if (error.code === HTTP_STATUS.UNAUTHORIZED && wasUserLoggedIn(state)) {
                const action = redirectToLogout({
                    isSessionExpired: true,
                    locationState: getLocationState(state),
                });
                return store.dispatch(action);
            }

            // Trigger a global notification when an error occurs on a GET request
            if (
                error.requestMethod === 'GET' &&
                (error.code === HTTP_STATUS.REQUEST_TIMEOUT || error.code === HTTP_STATUS.INTERNAL_SERVER_ERROR)
            ) {
                return store.dispatch(triggerErrorNotification(error));
            }
        }

        return null;
    });
};

/* If the session cookie expired in the browser, the backend can not determine
 * that the session expired, therefore we add this check */
function wasUserLoggedIn(state: IState) {
    return isLoggedIn(state);
}
