import EditAddress, {
    IPublicProps, IEditAddressProps,
} from '../../../../administration/Employees/shared/EditAddress';
import connect from '../../../../../utils/libs/redux/connect';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { getWorkPostCardWizardEntity } from '../../../../../redux/documentCenter/workPostCards/selectors';
import { BE_COUNTRY_CODE } from '../../../../../config/general.config';

interface IEditAgencyAddressProps extends IEditAddressProps {
    namePlaceholder: string;
}

export default connect<IPublicProps, IEditAgencyAddressProps>({
    statePropsDeprecated: (state, publicProps) => {
        const entity = getWorkPostCardWizardEntity(state);
        const agencyAddress = entity && entity.identification && entity.identification.agencyAddress;

        return {
            isDetailsLoaded: true,
            initialValues: agencyAddress ? {
                countryCode: agencyAddress.countryCode || '',
                box: agencyAddress.box || '',
                city: agencyAddress.city || '',
                number: agencyAddress.number || '',
                postcode: agencyAddress.postcode || '',
                street: agencyAddress.street || '',
                zipCodeId: agencyAddress.zipCodeId || -1,
            } : {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: '',
                zipCodeId: -1,
            },
        };
    },
    dispatchProps: (dispatch) => {
        return {
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditAddress);
