import React from 'react';
import './status-indicator.scss';
import classNames from 'classnames';

interface IStatusIndicatorProps {
    ranges?: [number, number, number];
    value: number;
    className?: string;
    showValue?: boolean;
    useRedYellowGreen?: boolean;
}

const RANGES = [0, 10, 20];
const CLASS_NAME = 'StatusIndicator';

export default function StatusIndicator(props: IStatusIndicatorProps) {
    const ranges = props.ranges || RANGES;
    let currentRange = 0;

    while (currentRange < ranges.length && props.value > ranges[currentRange]) {
        currentRange = currentRange + 1;
    }

    return (
        <div className={CLASS_NAME}>
            {props.showValue && (
                <span className={`${CLASS_NAME}__label`}>{props.value}</span>
            )}
            <div
                className={
                    classNames(
                        `${CLASS_NAME}__indicator state--${currentRange}`,
                        props.className, {
                            ['red-yellow-green']: props.useRedYellowGreen,
                        },
                    )
                }
            >
                {ranges.map((range) => {
                    return (
                        <span key={`range-${range}`} />
                    );
                })}
            </div>
        </div>
    );
}
