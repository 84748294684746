import React, { PureComponent } from 'react';
import { connect } from '../../../../..';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import {
    getEmployeeRisksAndResearches,
    getEmployeeRisksAndResearchesAsyncInfo,
    getSelectedEmployee,
} from '../../../../../../redux/employee/info/selectors';
import {
    IEmployeeRisksAndResearches,
    IEmployeeRiskAndResearch,
    IEmployee,
} from '../../../../../../models/admin/employee';
import isEmptyObject from '../../../../../../utils/core/object/isEmptyObject';
import ListItem from '../../../../../common/list/ListItem';
import Translate from '../../../../../common/Translate';
import { fetchEmployeeRisksAndResearches } from '../../../../../../redux/employee/info/actions';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import {
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../../../../models/interventions/medicalExaminations';

interface IPrivateProps {
    employeeRisksAndResearches: IEmployeeRisksAndResearches;
    fetchEmployeeRisksAndResearches: () => void;
    selectedEmployee: IEmployee;
}

class RisksOverview extends PureComponent<IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public componentDidMount() {
        this.props.fetchEmployeeRisksAndResearches();
    }

    public render() {
        const { employeeRisksAndResearches, selectedEmployee } = this.props;
        return (
            <>
                <h2>
                    <Translate
                        msg="interventions.medical_examinations.new.steps.select_timeslot.riskoverview_dialog.title"
                        placeholders={{ employee: formatPersonName(selectedEmployee) }}
                    />
                </h2>
                <TinyLoader asyncInfoSelector={getEmployeeRisksAndResearchesAsyncInfo}>
                    {!isEmptyObject(employeeRisksAndResearches) ?
                        <>
                            {
                                Object.keys(employeeRisksAndResearches).map((riskCode, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            title={employeeRisksAndResearches[riskCode][0].riskDescription}
                                            text={
                                                // eslint-disable-next-line max-len
                                                employeeRisksAndResearches[riskCode].map((item: IEmployeeRiskAndResearch, index) =>
                                                    item.researchDescription,
                                                )
                                            }
                                            arrow={true}
                                        />
                                    );
                                })
                            }
                        </> :
                        <p>
                            <Translate
                                msg="administration.employees.detail.risks.no_results"
                            />
                        </p>
                    }
                </TinyLoader>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            employeeRisksAndResearches: getEmployeeRisksAndResearches(state),
            selectedEmployee: getSelectedEmployee(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            fetchEmployeeRisksAndResearches: () => {
                const state = getState();
                const entity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                dispatch(fetchEmployeeRisksAndResearches({ id: entity.searchEmployee.selectedEmployee.id }));
            },
        };
    },
})(RisksOverview);
