import React, { Component } from 'react';
import { path } from 'ramda';
import classNames from 'classnames';

import './notify-employee.scss';
import illu from '../../../../../assets/img/illustrations/illu_desk_success.svg';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { IEmployeeDetails } from '../../../../../../models/admin/employee';
import Loader from '../../../../../common/waiting/Loader';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../../common/input/TextInput';
import Translate from '../../../../../common/Translate';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import { connect } from '../../../../..';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../../models/general/i18n';
import { schema, fields } from './notifyEmployeeSchema';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { getSelectedEmployee, getSelectedEmployeeAsyncInfo } from '../../../../../../redux/employee/info/selectors';
import Button from '../../../../../common/buttons/Button';
import {
    createConvocationsActions,
} from '../../../../../../redux/employee/documents/actions';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../models/general/redux';
import {
    getMedicalExaminationNotifyIsBusy,
    getMedicalExaminationNotifyError,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../redux/medicalExamination/selectors';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import { ITraceableApiError } from '../../../../../../models/general/error';
import FormError from '../../../../../common/forms/FormError';
import { clearErrors } from '../../../../../../utils/libs/redux/generic/actions';
import {
    IPlanMedicalExaminationBaseEntity, IMedicalExaminationTimeSlot,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    getCreateConvocationsAsyncInfo,
} from '../../../../../../redux/employee/documents/selectors';
import FinishPlanningDialog from '../FinishPlanningDialog';
import InlineSVG from '../../../../../common/icons/InlineSVG';
import ConvocationRecipients from '../ConvocationRecipients';
import { TConvocationRequestType } from '../../../../../../models/general/documents';


const FORM_NAME = 'plan-medical-examination-notify-employee-form';
const CLASS_NAME = 'PlanMedicalExaminationNotifyEmployee';

type FormValues = Pick<IEmployeeDetails, 'email'>;

interface ICreateConvocationParams {
    send?: {
        email: string;
    };
    download?: boolean;
}

interface IPrivateProps {
    translator: ITranslator;
    selectedEmployee: IEmployeeDetails;
    isBusy: boolean;
    isConvocationNotFoundDialogOpen: boolean;
    clearError: () => void;
    fetchSmallEmployeeDetailsAsyncInfo: IAsyncFieldInfo;
    createConvocations: (props: ICreateConvocationParams) => void;
    error: ITraceableApiError;
    createConvocationsAsyncInfo: IAsyncFieldInfo;
}

interface IState {
    isFinishPlanningDialogOpen: boolean;
    newEmail: string;
    downloadAmount: number;
}

class NotifyEmployee extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    private resetForm: (newValues: object) => void;

    constructor(props) {
        super(props);

        this.state = {
            isFinishPlanningDialogOpen: false,
            newEmail: undefined,
            downloadAmount: 0,
        };

        // Render functions
        this.renderButtons = this.renderButtons.bind(this);

        // Convocation handlers
        this.onDownloadConvocationHandler = this.onDownloadConvocationHandler.bind(this);
        this.onSendConvocationHandler = this.onSendConvocationHandler.bind(this);
        this.didCreateConvocationsSucceed = this.didCreateConvocationsSucceed.bind(this);
        this.didFetchEmployeeDetailsSucceed = this.didFetchEmployeeDetailsSucceed.bind(this);

        // Finish Planning handlers
        this.onCloseFinishPlanningHandler = this.onCloseFinishPlanningHandler.bind(this);
    }

    public componentDidUpdate(
        prevProps: IPrivateProps & IStepperStepRenderProps,
    ) {
        if (this.resetForm && this.didFetchEmployeeDetailsSucceed(prevProps, this.props)) {
            const newInitialValues: FormValues = { email: this.props.selectedEmployee.email };
            this.resetForm(newInitialValues);
        }

        if (this.didCreateConvocationsSucceed(prevProps, this.props)) {
            this.setState({ isFinishPlanningDialogOpen: true });
        }
    }

    public render() {
        const { translator, selectedEmployee, isBusy, error } = this.props;
        const { isFinishPlanningDialogOpen, downloadAmount } = this.state;

        const selectedEmployeeEmailAddress = selectedEmployee && selectedEmployee.email;

        const INITIAL_VALUES: FormValues = {
            email: selectedEmployee && selectedEmployee.email || '',
        };

        const PLACEHOLDER_KEYS: FormValues = {
            email: 'interventions.medical_examinations.new.steps.notify.placeholders.email',
        };

        const LABEL_KEYS: FormValues = {
            email: 'interventions.medical_examinations.new.steps.notify.labels.email',
        };

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.notify.title"
                    text="interventions.medical_examinations.new.steps.notify.text_invitation"
                />
                <div className={classNames(CLASS_NAME, 'container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Loader show={isBusy} />
                        <Form
                            name={FORM_NAME}
                            handleSubmit={this.onSendConvocationHandler}
                            initialValues={INITIAL_VALUES}
                            schema={schema}
                            render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                if (!this.resetForm) {
                                    this.resetForm = formRenderProps.resetForm;
                                }

                                const { touched, errors, values, handleChange } = formRenderProps;

                                return (
                                        <div>
                                            <div
                                                className={classNames(
                                                    `${CLASS_NAME}__send`,
                                                    { ['has-error']: !!touched.email && !!errors.email },
                                                )}
                                            >
                                                <FloatableTextInputWrapper>
                                                    <TextInput
                                                        id="notify-employee-email"
                                                        name={fields.email}
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        disabled={!!selectedEmployeeEmailAddress}
                                                        isInvalid={touched.email && !!errors.email}
                                                        placeholder={translator(PLACEHOLDER_KEYS.email)}
                                                    />
                                                    <label htmlFor="notify-employee-email">
                                                        <Translate
                                                            msg={LABEL_KEYS.email}
                                                            placeholders={{
                                                                employee: formatPersonName(selectedEmployee),
                                                            }}
                                                        />
                                                    </label>
                                                    {touched.email &&
                                                        <FormFieldError
                                                            error={errors.email}
                                                            placeholders={{
                                                                fieldName: translator(
                                                                    PLACEHOLDER_KEYS.email),
                                                            }}
                                                        />}
                                                </FloatableTextInputWrapper>
                                                <SubmitButton
                                                    formName={FORM_NAME}
                                                    id="send-convocation-button"
                                                    typeName="secondary"
                                                    alwaysEnabled={true}
                                                >
                                                    <Translate
                                                        // eslint-disable-next-line max-len
                                                        msg={'interventions.medical_examinations.new.steps.notify.buttons.send_invitation'}
                                                    />
                                                </SubmitButton>
                                            </div>
                                            <ConvocationRecipients className={`${CLASS_NAME}__recipients`} />
                                            <FormError
                                                error={error}
                                                className={`${CLASS_NAME}__form-error`}
                                            />
                                            {this.renderButtons()}
                                        </div>
                                );
                            }}
                        />
                </div>
                <InlineSVG svg={illu} className={`${CLASS_NAME}__illustration`} />
                <FinishPlanningDialog
                    open={isFinishPlanningDialogOpen}
                    onClose={this.onCloseFinishPlanningHandler}
                    downloadAmount={downloadAmount}
                />
            </>
        );
    }

    private renderButtons() {
        return (
            <div className={`${CLASS_NAME}__actions`}>
                <Button
                    id="download-convocation-button"
                    typeName="secondary"
                    outline={true}
                    onClick={this.onDownloadConvocationHandler}
                >
                    <Translate
                        msg={'interventions.medical_examinations.new.steps.notify.buttons.download_invitation'}
                    />
                </Button>
            </div>
        );
    }

    private onDownloadConvocationHandler() {
        this.props.clearError();
        this.props.createConvocations({ download: true });
        this.setState({ downloadAmount: 1 });
    }

    private onSendConvocationHandler(values: FormValues) {
        const { clearError, createConvocations } = this.props;
        clearError();

        createConvocations({
            send: {
                email: values.email,
            },
        });

        this.setState({ downloadAmount: 0 });
    }

    private didFetchEmployeeDetailsSucceed(
        prevProps: IPrivateProps & IStepperStepRenderProps,
        props: IPrivateProps & IStepperStepRenderProps,
    ) {
        return (prevProps.fetchSmallEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy &&
            props.fetchSmallEmployeeDetailsAsyncInfo.status === AsyncStatus.Success);
    }

    private didCreateConvocationsSucceed(
        prevProps: IPrivateProps & IStepperStepRenderProps,
        props: IPrivateProps & IStepperStepRenderProps,
    ) {
        return (prevProps.createConvocationsAsyncInfo.status === AsyncStatus.Busy &&
            props.createConvocationsAsyncInfo.status === AsyncStatus.Success);
    }

    private onCloseFinishPlanningHandler() {
        this.setState({ isFinishPlanningDialogOpen: false });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const fetchSmallEmployeeDetailsAsyncInfo = getSelectedEmployeeAsyncInfo(state);
        const createConvocationsAsyncInfo = getCreateConvocationsAsyncInfo(state);

        return {
            translator: getTranslatorDeprecated(state),
            selectedEmployee: getSelectedEmployee(state),
            isBusy: getMedicalExaminationNotifyIsBusy(state),
            error: getMedicalExaminationNotifyError(state),
            fetchSmallEmployeeDetailsAsyncInfo,
            createConvocationsAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            createConvocations: (props: ICreateConvocationParams) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);
                const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
                const email = path<string>(['send', 'email'], props);
                const selectedTimeslot = path<IMedicalExaminationTimeSlot>(
                    ['selectTime', 'selectedTimeslot'],
                    wizardEntity,
                );

                const planningRequestId = path<number>(
                    ['selectedMedicalExaminationToPlan', 'planningRequestId'],
                    wizardEntity,
                );

                dispatch(createConvocationsActions.trigger({
                    convocations: [{
                        timeSlotId: selectedTimeslot.id,
                        requestType: (props.download ? 'Letter' : 'Email') as TConvocationRequestType,
                        ...(!!planningRequestId) && {
                            planningRequestMsId: planningRequestId,
                        },
                        ...!!(!planningRequestId && selectedEmployee.id) && {
                            employmentId: selectedEmployee.id,
                        },
                        ...!!(email && (email !== selectedEmployee.email) && !props.download) && {
                            saveEmail: true,
                            email,
                        },
                    }],
                    logFormSubmission: true,
                }));
            },
            clearError: () => {
                const error = getMedicalExaminationNotifyError(getState());
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(NotifyEmployee);
