import React, { ReactNode, PureComponent, MouseEvent } from 'react';
import './confirmation-dialog.scss';
import Dialog from '../Dialog';
import Button from '../../buttons/Button';
import Translate from '../../Translate';
import Loader from '../../waiting/Loader';

interface IConfirmationDialogProps {
    show: boolean;
    children: ReactNode;
    header?: string;
    type?: 'error' | 'success';
    showLoader?: boolean;
    dialogClassName?: string;

    showSuccess?: boolean;
    onSuccess?: () => void;
    successTranslationKey?: string;
    successHeader?: string;

    onConfirm: () => void;
    confirmTranslationKey?: string;
    hideConfirmOption?: boolean;
    onCancel: () => void;
    cancelTranslationKey?: string;
    onCancelButtonOnlyOverride?: () => void;
}

const CLASS_NAME = 'ConfirmationDialog';

export default class ConfirmationDialog extends
    PureComponent<IConfirmationDialogProps> {

    constructor(props: IConfirmationDialogProps) {
        super(props);

        this.onConfirmClick = this.onConfirmClick.bind(this);
    }

    public render() {
        const {
            show, onCancel, successHeader,
            showLoader, children, showSuccess, dialogClassName,
        } = this.props;

        const header = showSuccess ? successHeader : this.props.header;
        const type = showSuccess ? 'success' : this.props.type;

        return (
            <Dialog
                className={dialogClassName}
                show={show}
                onCloseIntent={onCancel}
                header={header}
                type={type}
            >
                <Loader show={showLoader}/>
                <div className={`${CLASS_NAME}__content`}>
                    {!showSuccess && children}
                </div>
                <div className={`${CLASS_NAME}__buttons`}>
                    {!showSuccess ? this.renderDefaultButtons() : this.renderSuccessButtons()}
                </div>
            </Dialog>
        );
    }

    private renderDefaultButtons() {
        const {
            cancelTranslationKey, onCancel, onCancelButtonOnlyOverride,
            confirmTranslationKey,
            hideConfirmOption = false,
        } = this.props;

        return (
            <>
                <Button
                    id="confirmation-dialog-no"
                    typeName="secondary"
                    outline={true}
                    onClick={onCancelButtonOnlyOverride || onCancel}
                >
                    <Translate
                        msg={cancelTranslationKey ? cancelTranslationKey : 'common.confirmation_dialog.cancel'}
                    />
                </Button>
                {!hideConfirmOption &&
                    <Button
                        id="confirmation-dialog-yes"
                        typeName="secondary"
                        onClick={this.onConfirmClick}
                    >
                        <Translate
                            msg={confirmTranslationKey ? confirmTranslationKey : 'common.confirmation_dialog.continue'}
                        />
                    </Button>
                }
            </>
        );
    }

    private renderSuccessButtons() {
        const { onSuccess, successTranslationKey } = this.props;

        return (
            <Button
                id="confirmation-dialog-yes"
                typeName="secondary"
                onClick={onSuccess}
            >
                <Translate
                    msg={successTranslationKey ? successTranslationKey : 'common.confirmation_dialog.close'}
                />
            </Button>
        );
    }

    private onConfirmClick(e: MouseEvent<HTMLButtonElement>) {
        const { onConfirm } = this.props;

        e.preventDefault();
        onConfirm();
    }
}
