import { TIconTypeName } from '../general/icon';
import ROUTE_KEYS from '../../routeKeys';
import { IAction } from './redux';
import { ICompanyDetail } from '../admin/companyInfo';
import { ICompanySituationHistorySuccessPayload } from '../admin/preventionUnits';
import { TPlaceholders } from '../../views/common/Translate';
import {
    ADMINISTRATION_SVG_IDS,
    INTERVENTIONS_SVG_IDS,
    DOCUMENT_CENTER_SVG_IDS,
    CONTACT_SVG_IDS,
} from './lazyLoadSvg';

export interface ILinkableNavigationItem {
    linkTo: ROUTE_KEYS;
    linkToPayload?: object;
}

export enum MenuType {
    TOP = 'top',
    USER_PROFILE = 'userProfile',
}

export interface IMenuItem extends ILinkableNavigationItem {
    code: string;
    translationKey: string;
    iconTypeName?: TIconTypeName;
    iconAlt?: string;
    type: MenuType;
    isPerformanceDashboardLink?: boolean;
}

export interface IDashboardNavigationItem extends ILinkableNavigationItem {
    titleTranslationKey: string;
    svgSrc?: string;
    svgDetailSrc?: string;
}

export interface IInterventionItem extends IDashboardNavigationItem {
    id: string;
    linkToPayload?: object | (() => object);
    tooltipTranslationKey?: string;
    lazyLoadSvgId: INTERVENTIONS_SVG_IDS;
    detailLazyLoadSvgId?: INTERVENTIONS_SVG_IDS;
}

export interface IAdministrationItem extends IDashboardNavigationItem {
    id: string;
    lazyLoadSvgId: ADMINISTRATION_SVG_IDS;
    detailLazyLoadSvgId?: ADMINISTRATION_SVG_IDS;
}

export interface IDocumentCenterItem extends IDashboardNavigationItem {
    id: string;
    lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS;
    detailLazyLoadSvgId?: DOCUMENT_CENTER_SVG_IDS;
}

export interface IDismissedCompanyDashboardItem extends IDashboardNavigationItem {
    id: string;
    lazyLoadSvgId: DOCUMENT_CENTER_SVG_IDS | INTERVENTIONS_SVG_IDS | ADMINISTRATION_SVG_IDS;
    detailLazyLoadSvgId?: DOCUMENT_CENTER_SVG_IDS | INTERVENTIONS_SVG_IDS | ADMINISTRATION_SVG_IDS;
}

export interface IContactItem extends ILinkableNavigationItem {
    id: string;
    titleTranslationKey: string;
    descriptionTranslationKey: string;
    lazyLoadSvgId: CONTACT_SVG_IDS;
    detailLazyLoadSvgId?: CONTACT_SVG_IDS;
}

export interface ITabItem extends ILinkableNavigationItem {
    translationKey: string;
    translationPlaceholders?: TPlaceholders;
    notificationContent?: number | string;
    tooltipTranslationKey?: string;
    hide? : boolean;
}

export interface IFetchCompanyDetailBeforeNavigatingPayload {
    getNavigateAction: (companyDetail: ICompanyDetail, requestedAction: IAction<{}>) => IAction<{}>;
    requestedAction: IAction<{}>;
}

export interface IFetchCompanySituationHistoryBeforeNavigatingPayload {
    getNavigateAction: (
        companySituationHistory: ICompanySituationHistorySuccessPayload,
        requestedAction: IAction<{}>,
    ) => IAction<{}>;
    requestedAction: IAction<{}>;
}
