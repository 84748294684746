import React from 'react';
import { IRenderDetailHeaderProps, IRenderDetailContentProps }
    from '../../common/widget/MasterWithDetail/typings';
import './functions.scss';
import Translate from '../../common/Translate';
import {
    ICompanyFunctionIsco,
    ICompanyFunctionEmployee,
    ICompanyFunction,
} from '../../../models/admin/companyFunctions';
import Loader from '../../common/waiting/Loader';
import LabelInfo from '../../common/widget/LabelInfo';
import CollapsibleItem from '../../common/widget/CollapsibleItem/index';
import ListItem from '../../common/list/ListItem/index';
import { formatPersonNameFormal } from '../../../utils/formatting/formatPerson';
import { IRisk } from '../../../models/admin/risks';
import { IResearch } from '../../../models/admin/researches';
import { AsyncStatus } from '../../../models/general/redux';
import TinyLoader from '../../common/waiting/TinyLoader';
import {
    getCompanyFunctionIscoAsyncInfo,
    getCompanyFunctionIsco,
    getCompanyFunctionRisksAsyncInfo,
    getCompanyFunctionRisks,
    getCompanyFunctionResearchesAsyncInfo,
    getCompanyFunctionResearches,
} from '../../../redux/company/functions/selectors';
import {
    getCompanyFunctionEmployeesAsyncInfo,
    getCompanyFunctionEmployees,
} from '../../../redux/employee/employees/selectors';
import connect from '../../../utils/libs/redux/connect';
import ROUTE_KEYS from '../../../routeKeys';
import { getRoutePayload } from '../../../redux/location/selectors';
import { sortByPersonNameFormal } from '../../../utils/list/sortMethods';

interface IPrivateProps {
    companyFunctionIsco: ICompanyFunctionIsco[];
    companyFunctionEmployees: ICompanyFunctionEmployee[];
    companyFunctionRisks: IRisk[];
    companyFunctionResearches: IResearch[];
    selectedEmployeeId: number;
}

interface IContentPublicProps {
    employeeDetailRouteKey: ROUTE_KEYS;
}

export const CompanyFunctionDetailHeader = (renderProps: IRenderDetailHeaderProps<ICompanyFunction>) => {
    const {
        detailAsyncInfo,
        detailData: selectedCompanyFunction,
    } = renderProps;

    return (
        <h1>
            {detailAsyncInfo.error
                ? <Translate msg="error.title" />
                : selectedCompanyFunction && selectedCompanyFunction.description
            }
        </h1>
    );
};

const DetailContentComp = (
    props: IPrivateProps & IRenderDetailContentProps<ICompanyFunction> & IContentPublicProps,
) => {
    const CLASS_NAME = 'FunctionDetailContent';

    const {
        detailData: selectedCompanyFunction,
        companyFunctionIsco,
        companyFunctionEmployees,
        companyFunctionResearches,
        companyFunctionRisks,
        detailAsyncInfo,
        selectedEmployeeId,
        employeeDetailRouteKey,
    } = props;

    if (!selectedCompanyFunction) {
        return null;
    }

    return (
        <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
            <div className={`${CLASS_NAME}`}>
                <h2>
                    <Translate msg="administration.company_function.isco.title" />
                </h2>
                <div className="isco-wrapper">
                    <TinyLoader asyncInfoSelector={getCompanyFunctionIscoAsyncInfo}>
                        {companyFunctionIsco.length > 0 ?
                            companyFunctionIsco.map((item) => {
                                return (
                                    <LabelInfo
                                        key={item.code}
                                        label={item.code}
                                        info={item.description}
                                    />
                                );
                            }) :
                            <p><Translate msg="administration.company_function.isco.no_results" /></p>
                        }
                    </TinyLoader>
                </div>

                <CollapsibleItem
                    trigger="administration.company_function.employees.title"
                    initialOpen={!!selectedEmployeeId}
                >
                    <TinyLoader asyncInfoSelector={getCompanyFunctionEmployeesAsyncInfo}>
                        {companyFunctionEmployees.length > 0 ?
                            <>
                                {
                                    companyFunctionEmployees.map((item, index) => {
                                        return (
                                            <ListItem
                                                id={`employee-details-${item.id}`}
                                                key={index}
                                                text={formatPersonNameFormal(item)}
                                                to={{
                                                    type: employeeDetailRouteKey,
                                                    payload: {
                                                        functionId: selectedCompanyFunction.id,
                                                        id: item.id,
                                                    },
                                                }}
                                                selected={item.id === selectedEmployeeId}
                                            />
                                        );
                                    })
                                }
                            </> :
                            <p><Translate msg="administration.company_function.employees.no_results" /></p>
                        }
                    </TinyLoader>
                </CollapsibleItem>

                <CollapsibleItem trigger="administration.company_function.risks.title">
                    <TinyLoader asyncInfoSelector={getCompanyFunctionRisksAsyncInfo}>
                        {companyFunctionRisks.length > 0 ?
                            <>
                                {
                                    companyFunctionRisks.sort(sortFunctionRisk).map((item) => {
                                        return (
                                            <ListItem
                                                key={item.riskCode}
                                                title={item.description}
                                                text={item.riskCode}
                                                arrow={true}
                                            />
                                        );
                                    })
                                }
                            </> :
                            <p><Translate msg="administration.company_function.risks.no_results" /></p>
                        }
                    </TinyLoader>
                </CollapsibleItem>

                <CollapsibleItem trigger="administration.company_function.researches.title">
                    <TinyLoader asyncInfoSelector={getCompanyFunctionResearchesAsyncInfo}>
                        {companyFunctionResearches.length > 0 ?
                            <>
                                {
                                    companyFunctionResearches.sort(sortFunctionResearch).map((item) => {
                                        return (
                                            <ListItem
                                                key={item.researchCode}
                                                title={item.researchDescription}
                                                text={item.periodicityDescription}
                                                arrow={true}
                                            />
                                        );
                                    })
                                }
                            </> :
                            <p><Translate msg="administration.company_function.researches.no_results" /></p>
                        }
                    </TinyLoader>
                </CollapsibleItem>
            </div>
        </Loader>
    );
};

export const CompanyFunctionDetailContent =
    connect<IPrivateProps, IRenderDetailContentProps<ICompanyFunction> & IContentPublicProps>({
        stateProps: (state) => {
            const companyFunctionIsco = getCompanyFunctionIsco(state);
            const companyFunctionEmployees = getCompanyFunctionEmployees(state).sort(sortByPersonNameFormal);
            const companyFunctionRisks = getCompanyFunctionRisks(state);
            const companyFunctionResearches = getCompanyFunctionResearches(state);

            return {
                companyFunctionIsco,
                companyFunctionEmployees,
                companyFunctionRisks,
                companyFunctionResearches,
                selectedEmployeeId: getRoutePayload<{ id: number }>(state).id,
            };
        },
    })(DetailContentComp);

export function sortFunctionResearch(a: IResearch, b: IResearch) {
    return a.researchDescription > b.researchDescription ? 1 : a.researchDescription < b.researchDescription ? -1 : 0;
}

export function sortFunctionRisk(a: IRisk, b: IRisk) {
    return a.description > b.description ? 1 : a.description < b.description ? -1 : 0;
}
