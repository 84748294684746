import isSet from '@snipsonian/core/es/is/isSet';
import { pointToDecimalSeparator } from './formatFloat';

export function formatPrice(price: number) {
    if (!isSet(price) || Number.isNaN(Number(price))) {
        return '-';
    }

    return '€ ' + pointToDecimalSeparator(price.toFixed(2));
}
