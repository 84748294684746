import {
    Formats,
    DateRangeFormatFunction,
    DateLocalizerSpec,
} from 'react-big-calendar';
import { DateLocalizer } from 'react-big-calendar/lib/localizer';
import { formatDate } from '../../../../utils/formatting/formatDate';
import { isInSameMonth } from '../../../../utils/core/date/isInSameMonth';
import { } from '../../../../utils/formatting/formatTime';
import { TIME_FORMATS, DATE_FORMATS } from '../../../../config/i18n.config';

const dateRangeFormat: DateRangeFormatFunction = ({ start, end }, culture, local: DateLocalizer) =>
    `${local.format(start, 'DD MMMM', culture)} - ${local.format(end, 'DD MMMM', culture)}`;

const timeRangeFormat: DateRangeFormatFunction = ({ start, end }, culture, local: DateLocalizer) =>
    `${local.format(start, TIME_FORMATS.DISPLAY, culture)} - ${local.format(end, TIME_FORMATS.DISPLAY, culture)}`;

const timeRangeStartFormat: DateRangeFormatFunction = ({ start }, culture, local: DateLocalizer) =>
    `${local.format(start, TIME_FORMATS.DISPLAY, culture)} - `;

const timeRangeEndFormat: DateRangeFormatFunction = ({ end }, culture, local: DateLocalizer) =>
    ` - ${local.format(end, TIME_FORMATS.DISPLAY, culture)}`;

const weekRangeFormat: DateRangeFormatFunction = ({ start, end }, culture, local: DateLocalizer) => {
    const sameMonth = isInSameMonth(start, end);
    return (
        local.format(start, sameMonth ? 'DD ' : 'DD MMMM ', culture) +
        ' - ' +
        local.format(end, 'DD MMMM YYYY', culture)
    );
};

const formats: Formats = {
    dateFormat: 'DD',
    dayFormat: 'dd DD MMM',
    weekdayFormat: 'dd',

    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,
    eventTimeRangeStartFormat: timeRangeStartFormat,
    eventTimeRangeEndFormat: timeRangeEndFormat,

    timeGutterFormat: TIME_FORMATS.DISPLAY,

    monthHeaderFormat: 'MMMM YYYY',
    dayHeaderFormat: 'dd DD MMMM',
    dayRangeHeaderFormat: weekRangeFormat,
    agendaHeaderFormat: dateRangeFormat,

    agendaDateFormat: DATE_FORMATS.DISPLAY,
    agendaTimeFormat: TIME_FORMATS.DISPLAY,
    agendaTimeRangeFormat: timeRangeFormat,
};

// eslint-disable-next-line func-names
export default function () {
    return new DateLocalizer({
        formats,
        firstOfWeek() {
            // Monday is used as the first day
            return 1;
        },
        format(value, format) {
            return formatDate(value, format);
        },
    } as DateLocalizerSpec);
}
