import React, { PureComponent } from 'react';
import './help-tooltip.scss';
import Tooltip, { ITooltipProps } from '../Tooltip';
import CloseIcon from '../../icons/CloseIcon';
import localStorage from '@snipsonian/browser/es/storage/localStorage';
import Button from '../../buttons/Button';
import Translate from '../../Translate';

export interface IHelpTooltipProps extends Pick<ITooltipProps, 'target' | 'children'> {
    id: string;
    onCloseIntent?: () => void;
}

const CLASS_NAME = 'HelpTooltip';
const LOCAL_STORAGE_KEY = 'MNSR_KLNTZN_HELP_TOOLTIPS';

type TItemsInStorage = string[];

interface IComponentState {
    // Set when the tooltip closes due to a scroll or a click outside
    // A real dismiss is done by clicking on the "X" icon
    isTemporarilyDismissed: boolean;
}

export default class HelpTooltip extends PureComponent<IHelpTooltipProps, IComponentState> {
    constructor(props: IHelpTooltipProps) {
        super(props);

        this.state = {
            isTemporarilyDismissed: false,
        };
    }

    public render() {
        const { id, target, children, onCloseIntent } = this.props;
        if (this.state.isTemporarilyDismissed || isItemDismissed(id)) {
            return target;
        }

        return (
            <span className={CLASS_NAME}>
                <Tooltip
                    target={target}
                    spaceBetweenTarget={15}
                    onClose={() => {
                        if (typeof onCloseIntent === 'function') {
                            onCloseIntent();
                        }
                        this.setState({ isTemporarilyDismissed: true });
                    }}
                    tooltipBubbleClassName={`${CLASS_NAME}__bubble`}
                    disableAutoHide={true}
                    placement="right"
                    isOpenOnInitialRender={true}
                    typeName="info-bubble"
                >
                    {({ closeTooltip }) => {
                        const dismissNotification = () => {
                            dismissItem(id);
                            closeTooltip();
                        };
                        return (
                            <>
                                <CloseIcon onClick={closeTooltip} />
                                {children}
                                <Button id="dismiss-help-notification" onClick={dismissNotification} typeName="text">
                                    <Translate msg="common.help_tooltip.dismiss" />
                                </Button>
                            </>
                        );
                    }}
                </Tooltip>
            </span>
        );
    }
}

export function resetDismissedItems() {
    localStorage.remove({ key: LOCAL_STORAGE_KEY });
}

function isItemDismissed(id: string) {
    const itemsInStorage = localStorage.read({ key: LOCAL_STORAGE_KEY }) as TItemsInStorage;
    if (itemsInStorage) {
        return itemsInStorage.includes(id);
    }
    return false;
}

function dismissItem(id: string) {
    const itemsInStorage = (localStorage.read({ key: LOCAL_STORAGE_KEY }) || []) as TItemsInStorage;
    itemsInStorage.push(id);
    localStorage.save({ key: LOCAL_STORAGE_KEY, value: itemsInStorage });
}
