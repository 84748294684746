import React from 'react';
import AppVersion from './AppVersion';
import CreatedBy from './CreatedBy';

export default function FooterAppMeta() {
    return (
        <div className="footer-app-meta">
            <AppVersion />
            <CreatedBy />
        </div>
    );
}
