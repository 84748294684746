import { ColorHex } from '../../models/general/colors';

type rgbColorType = {
    r: number,
    g: number,
    b: number,
};

function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } as rgbColorType : null;
}

// Returns black or white, based on bg color, for better contrast
export function getTextColorBasedOnBackgroundColor(bgcolor: string) {
    const rgb = hexToRgb(bgcolor);

    // Counting the perceptive luminance
    // human eye favors green color...
    const luminance = rgb && (1 - (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255);
    return luminance < 0.5 ? ColorHex.SlateGrey : ColorHex.White;
}
