import isArray from '@snipsonian/core/es/is/isArray';
import isSet from '@snipsonian/core/es/is/isSet';

import {
    IMedicalExaminationTimeSlot,
    MedicalExaminationTimeSlotType,
} from '../../../models/interventions/medicalExaminations';
import { IReservedMedicalExamination } from '../../../models/interventions/medicalExaminations/reserved';

/**
 * allowedExaminations
 * - if empty array --> all reasons allowed
 * - if array --> the included reasons are the allowed ones
 *       p.s. There is a potential configuration problem where in H@W both the include- as the exclude-list
 *            has been configured so that it's not really clear which are the allowed reasons. In this case,
 *            the mensura rest service will only pass the 'include' reasons, so that we don't have to do
 *            anything special for that exception case.
 */
export function canBePlannedOnBufferzone({
    bufferzone,
}: {
    bufferzone: IReservedMedicalExamination;
}): boolean {
    if (!isSet(bufferzone.allowedExaminationReasons) || bufferzone.allowedExaminationReasons.length === 0) {
        return false;
    }

    return true;
}

export function canExaminationReasonBePlannedOnBufferzone({
    bufferzone,
    examinationReasonCode,
}: {
    bufferzone: IReservedMedicalExamination;
    examinationReasonCode: string;
}): boolean {
    if (!canBePlannedOnBufferzone({ bufferzone })) {
        return false;
    }

    if (hasBufferzoneAnExaminationReasonRestriction({ bufferzone })) {
        if (!bufferzone.allowedExaminationReasons.some(
            (allowedExamination) => allowedExamination.code === examinationReasonCode)
        ) {
            return false;
        }
    }

    return true;
}

export function hasBufferzoneAnExaminationReasonRestriction({
    bufferzone,
}: {
    bufferzone: IReservedMedicalExamination;
}): boolean {
    if (isArray(bufferzone.allowedExaminationReasons) && bufferzone.allowedExaminationReasons.length > 0) {
        return true;
    }

    return false;
}

export function isReservedTimeSlotPartOfOneOfTheseBufferzones({
    timeSlot: reservedTimeSlot,
    bufferzones,
}: {
    timeSlot: IMedicalExaminationTimeSlot,
    bufferzones: IReservedMedicalExamination[];
}): boolean {
    if (!reservedTimeSlot || reservedTimeSlot.type !== MedicalExaminationTimeSlotType.Reserved) {
        throw new Error('This check can only be used for a reserved timecell.');
    }

    return bufferzones.some((bufferzone) => {
        return bufferzone.reservedTimeSlots.some((timeSlot) => reservedTimeSlot.id === timeSlot.id);
    });
}
