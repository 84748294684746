import Translate from '../../../../../common/Translate';
import React from 'react';
import Radiobutton from '../../../../../common/input/Radiobutton';
import { IFormRenderProps } from '../../../../../common/forms/Form';
import { IUserAccountDetail, AdminType } from '../../../../../../models/user/userAccount';
import TooltipWithIcon from '../../../../../common/widget/TooltipWithIcon';
import Icon from '../../../../../common/icons/Icon';

export default function SelectUserTypeField(
    props: IFormRenderProps<Pick<IUserAccountDetail, 'admin'>> & {disabled?: boolean},
) {
    const { values, handleChange } = props;

    const infoIcon = (
        <Icon
            typeName="info"
            circle
        />
    );

    return (
        <>
            <h4>
                <Translate
                    msg="account.account_settings.manage_users.select_user_type_field.title"
                />
                <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                    <Translate
                        msg="account.account_settings.manage_users.select_user_type_field.help"
                    />
                </TooltipWithIcon>
            </h4>
            <Radiobutton
                name="admin"
                value={AdminType.None}
                onChange={handleChange}
                checked={Number(values.admin) === AdminType.None}
                disabled={props.disabled}
            >
                <Translate
                    msg="account.account_settings.manage_users.select_user_type_field.options.user"
                />
            </Radiobutton>
            <Radiobutton
                name="admin"
                value={AdminType.Administrator}
                onChange={handleChange}
                checked={Number(values.admin) === AdminType.Administrator}
                disabled={props.disabled}
            >
                <Translate
                    msg="account.account_settings.manage_users.select_user_type_field.options.admin"
                />
            </Radiobutton>
        </>
    );
}
