import React, { Component, PureComponent } from 'react';
import { clone } from 'ramda';
import classNames from 'classnames';

import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { dayOffsetFromNow, getDate } from '../../../../../utils/core/date/getSpecificDate';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../config/planning.config';
import { DEFAULT_NR_OF_DAYS_IN_THE_FUTURE } from '../../../../../config/medicalExamination.config';
import { EXAMINATION_REASON_CODE } from '../../../../../config/navigation/interventions.config';
import { formatDateForBackend, formatDateInLongFormat } from '../../../../../utils/formatting/formatDate';
import { formatPersonNameFormal } from '../../../../../utils/formatting/formatPerson';
import { getEmployeesToPlan, getEmployeesToPlanAsyncInfo } from '../../../../../redux/medicalExamination/selectors';
import { getMedExamToAddId } from '../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { getMinutesBetweenDates } from '../../../../../utils/core/date/getDifferenceBetweenDates';
import {
    getPlanBufferzoneEmployeesToPlanCombinedWithAddedEmployees,
    getPlanBufferzoneWizardEntity,
} from '../../../../../redux/intervention/bufferzones/selectors';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { IEmployee } from '../../../../../models/admin/employee';
import {
    IExaminationReason,
    IMedicalExaminationToAdd,
    IMedicalExaminationToPlan,
} from '../../../../../models/interventions/medicalExaminations';
import {
    IPlanBufferzoneWizardEntity,
    IPlanBufferzoneWizardPayload,
} from '../../../../../models/interventions/bufferzones';
import { IReservedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/reserved';
import { isAfter } from '../../../../../utils/core/date/isAfter';
import { isBefore } from '../../../../../utils/core/date/isBefore';
import {
    ISortedColumn,
    ListColumns,
    ListItem,
    ListItemId,
    SortOrder,
    SortType,
} from '../../../../../models/general/list';
import { IState, NO_RERENDER } from '../../../../../redux';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    mapEmployeeToPlanToMedicalExaminationToAdd, mapEmployeesToPlanToMedicalExaminationsToAdd,
} from '../../../../../utils/interventions/medicalExaminations/mapEmployeesToPlanToMedicalExaminationsToAdd';
import { updatePlanBufferzoneWizardEntity } from '../../../../../redux/intervention/bufferzones/actions';
import ROUTE_KEYS from '../../../../../routeKeys';
import { connect } from '../../../../index';
import {
    IIsSelectAllCheckboxSelected,
    IOnSelectAllChangeProps,
    IRenderFilterContentProps,
    IRenderMasterContentProps,
    IRenderSearchContentProps,
} from '../../../../common/widget/MasterWithDetail/typings';
import { startEndDateSchema } from '../../../../common/input/StartEndDateFilter/startEndDateSchema';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Button from '../../../../common/buttons/Button';
import EmployeeConditionTooltip from '../../../../administration/Employees/shared/EmployeeConditionTooltip';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import Icon from '../../../../common/icons/Icon';
import ListWithCheckboxSelect from '../../../../common/list/ListWithCheckboxSelect';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import PageHeader from '../../../../appShell/PageHeader';
import Translate from '../../../../common/Translate';
import WarningDialog from '../../../../common/modals/WarningDialog';
import {
    clientSideSearchAndFilterOfListData,
    customSorter,
    DEADLINE_FILTER,
    FilterContent,
    filterEmployeesInList,
    findEmployeeInList,
    getCustomRowClasses,
    getDefaultQueryParams,
    IFilterValues,
    SearchContent,
    toMedicalExaminationToPlan,
    transformFilterValuesToActiveFilters,
} from '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/automatic/EmployeesToPlan';
import SearchEmployeeToPlanOverlay
    from '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/automatic/SearchEmployeeToPlanOverlay';
import SelectExaminationReasonOverlay
    from '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/shared/SelectExaminationReasonOverlay';

const BASE_NAME = 'PlanBufferzoneSelectEmployees';
const CLASS_NAME = 'PlanBufferzone__SelectEmployees';
const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps.select_employees';

enum AddEmployeeOverlayState {
    Closed,
    SelectEmployee,
    SelectReason,
}

interface IPrivateProps {
    employeesToPlan: IMedicalExaminationToAdd[];
    selectedBufferzone: IReservedMedicalExamination;
    selectedToPlanIds: string[];
    currentRoutePayload: IPlanBufferzoneWizardPayload;
    addEmployeeToEntity: (employee: IEmployee, examinationReason: IExaminationReason) => string;
    setToPlanIds: (selectedToPlanIds: string[]) => void;
    isInitialLoad: boolean;
    employeesToPlanAsyncInfo: IAsyncFieldInfo;
}

interface IComponentState {
    isWarningDialogOpen: boolean;
    addEmployeeOverlayState: AddEmployeeOverlayState;
    selectedEmployee: IEmployee;
    idToScrollIntoView?: ListItemId;
}

class SelectEmployee extends Component<IStepperStepRenderProps & IPrivateProps, IComponentState> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isWarningDialogOpen: false,
            addEmployeeOverlayState: AddEmployeeOverlayState.Closed,
            selectedEmployee: null,
        };

        this.goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots =
            this.goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots.bind(this);
        this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);

        this.isSelectAllSelected = this.isSelectAllSelected.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);

        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots =
            this.goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots.bind(this);

        this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);
        this.onCloseAddEmployee = this.onCloseAddEmployee.bind(this);
        this.onOpenSelectEmployee = this.onOpenSelectEmployee.bind(this);
        this.onSelectEmployeeToAdd = this.onSelectEmployeeToAdd.bind(this);
        this.onSelectReason = this.onSelectReason.bind(this);
        this.renderAddEmployeeButton = this.renderAddEmployeeButton.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IStepperStepRenderProps) {
        const {
            selectedToPlanIds,
            isInitialLoad,
            setToPlanIds,
            employeesToPlanAsyncInfo,
            selectedBufferzone,
        } = this.props;

        const availableFreeSlots = selectedBufferzone && selectedBufferzone.availability.plannableTimeSlots;

        if (prevProps.employeesToPlanAsyncInfo.status === AsyncStatus.Busy &&
            employeesToPlanAsyncInfo.status === AsyncStatus.Success) {
            if (isInitialLoad) {
                // the selectedToPlanIds are in this case the initialSelectedPlanIds
                const selectedToPlanIdsMax =
                    selectedToPlanIds.length > availableFreeSlots ?
                        selectedToPlanIds.slice(0, availableFreeSlots) :
                        selectedToPlanIds;
                setToPlanIds(selectedToPlanIdsMax);
            }
        }
    }

    public render() {
        const { renderStepButtons, currentRoutePayload, selectedToPlanIds, selectedBufferzone } = this.props;
        const { addEmployeeOverlayState, selectedEmployee } = this.state;
        const AddEmployeeShortLink = (
            <a onClick={this.onOpenSelectEmployee}>
                <Icon typeName="plus-circle" />
                <span>
                    <Translate msg={`${TRANSLATION_PREFIX}.text_add_employee_placeholder`} />
                </span>
            </a>
        );

        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                    textPlaceholders={{
                        freeSlots: selectedBufferzone.availability.plannableTimeSlots,
                        addButton: AddEmployeeShortLink,
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        getDefaultQueryParams={getDefaultQueryParams}
                        masterConfig={{
                            routeKey: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
                            routePayload: currentRoutePayload,
                            asyncInfoSelector: getEmployeesToPlanAsyncInfo,
                            dataSelector: getPlanBufferzoneEmployeesToPlanCombinedWithAddedEmployees,
                            transformData: mapEmployeesToPlanToListItems,
                            renderContent: (
                                renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>,
                            ) => <SelectEmployeesList {...this.props} {...renderProps} {...this.state} />,
                            clientSideFilterCustomDataSelector,
                            clientSideFilterOfListData: clientSideSearchAndFilterOfListData,
                            transformFilterValuesToActiveFilters,
                            filterValidationSchema: startEndDateSchema,
                        }}
                        headerConfig={{
                            renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                                <SearchContent {...renderProps} />,
                            renderFilterContent:
                                (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                    <FilterContent {...renderProps} />,
                            selectAllCheckbox: {
                                renderTranslateComponent: this.renderSelectAllTranslate,
                                isSelected: this.isSelectAllSelected,
                                onSelectionChange: this.onSelectAllChange,
                            },
                        }}
                        footerConfig={{
                            renderActionsLeft: this.renderAddEmployeeButton,
                            renderActionsRight: () =>
                                renderStepButtons({
                                    nextButton: {
                                        disabled: selectedToPlanIds.length <= 0,
                                        onClick: this.goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots,
                                    },
                                }),
                        }}
                    />
                </div>
                <WarningDialog
                    onCancel={this.onCloseWarningDialog}
                    onConfirm={this.props.goToNextStep}
                    show={this.state.isWarningDialogOpen}
                    showLoader={false}
                    title={`${TRANSLATION_PREFIX}.free_slots_warning_dialog.title`}
                    confirmTranslationKey={`${TRANSLATION_PREFIX}.free_slots_warning_dialog.confirm`}
                    cancelTranslationKey={`${TRANSLATION_PREFIX}.free_slots_warning_dialog.cancel`}
                >
                    <Translate msg={`${TRANSLATION_PREFIX}.free_slots_warning_dialog.text`} />
                </WarningDialog>
                <SearchEmployeeToPlanOverlay
                    show={addEmployeeOverlayState === AddEmployeeOverlayState.SelectEmployee}
                    onClose={this.onCloseAddEmployee}
                    onSelect={this.onSelectEmployeeToAdd}
                    enableEmployeeToPlanValidation={false}
                    isBufferzonePlanning={true}
                />
                <SelectExaminationReasonOverlay
                    show={addEmployeeOverlayState === AddEmployeeOverlayState.SelectReason}
                    onClose={this.onOpenSelectEmployee}
                    onSelect={this.onSelectReason}
                    employeeToValidate={selectedEmployee}
                />
            </>
        );
    }

    /* Select all */
    private renderSelectAllTranslate() {
        return <Translate msg={`${TRANSLATION_PREFIX}.select_all`} />;
    }

    private isSelectAllSelected(props: IIsSelectAllCheckboxSelected<ListItem<IColumnNames>[]>) {
        const { selectedToPlanIds } = this.props;
        const { masterData } = props;
        if (selectedToPlanIds.length <= 0) {
            return false;
        }

        return selectedToPlanIds.length === masterData.length;
    }

    private onSelectAllChange(props: IOnSelectAllChangeProps<ListItem<IColumnNames>[]>) {
        const { setToPlanIds } = this.props;
        const { masterData, isSelected } = props;
        if (isSelected) {
            setToPlanIds(masterData.map((listItem) => listItem.id as string));
        } else {
            setToPlanIds([]);
        }
    }

    private goToNextStepOrOpenWarningDialogIfMoreEmployeesThanFreeslots() {
        const { employeesToPlan, selectedBufferzone } = this.props;
        const employeesToPlanTotalDuration = employeesToPlan.reduce(
            (totalDuration, employeeToPlan) => {
                return totalDuration + (employeeToPlan.duration || DEFAULT_MEDICAL_EXAMINATION_DURATION);
            },
            0,
        );

        const timeslotsTotalDuration = selectedBufferzone.reservedTimeSlots.reduce(
            (totalDuration, reservedTimeSlots) => {
                return totalDuration + getMinutesBetweenDates(
                    getDate(reservedTimeSlots.end),
                    getDate(reservedTimeSlots.start),
                );
            },
            0,
        );

        if (employeesToPlanTotalDuration > timeslotsTotalDuration) {
            this.setState({ isWarningDialogOpen: true });
        } else {
            this.onClickNextHandler();
        }
    }

    private onClickNextHandler() {
        if (this.props.isInitialLoad) {
            /**
             * On the initial load, an initial selection is made that is not yet stored in the redux state.
             * The selected examinations are only stored after a user changes a selection (causing 'isInitialLoad'
             * to be false).
             * Therefore we make sure that if a user clicks on next, without first changing the initial
             * selection, that that initial selection gets saved.
             */
            this.props.setToPlanIds(this.props.selectedToPlanIds);
        }
        this.props.goToNextStep();
    }

    private onCloseWarningDialog() {
        this.setState({ isWarningDialogOpen: false });
    }

    private onCloseAddEmployee() {
        this.setState({ addEmployeeOverlayState: AddEmployeeOverlayState.Closed, selectedEmployee: null });
    }

    private onOpenSelectEmployee() {
        this.setState({ addEmployeeOverlayState: AddEmployeeOverlayState.SelectEmployee, selectedEmployee: null });
    }

    private onSelectEmployeeToAdd(employee: IEmployee) {
        this.setState({ selectedEmployee: employee, addEmployeeOverlayState: AddEmployeeOverlayState.SelectReason });
    }

    private onSelectReason(examinationReason: IExaminationReason) {
        const { addEmployeeToEntity } = this.props;
        const { selectedEmployee } = this.state;

        const uniqueId = addEmployeeToEntity(selectedEmployee, examinationReason);

        this.setState({ addEmployeeOverlayState: AddEmployeeOverlayState.Closed });

        this.jumpToRowWithId(uniqueId);
    }

    private jumpToRowWithId(uniqueId: string) {
        this.setState({
            idToScrollIntoView: uniqueId,
        });
    }

    private renderAddEmployeeButton() {
        return (
            <Button
                id="add-employee-to-plan"
                typeName="text"
                className="AddButton"
                onClick={this.onOpenSelectEmployee}
            >
                <Icon typeName="plus" circle />
                <span>
                    <Translate msg={`${TRANSLATION_PREFIX}.add_employee`} />
                </span>
            </Button>
        );
    }
}

/* List */

interface IColumnNames {
    condition: string;
    employee: string;
    function: string;
    functionId: number;
    seat: string;
    seatCode: string;
    examinationReason: string;
    deadline: string;
    deadlineSort: string;
    dateOutOfService: string;
    absent: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    condition: {
        sortable: false,
        percentWidth: 5,
    },
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    function: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.function`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    functionId: {
        hide: true,
        percentWidth: null,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    examinationReason: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.reason`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    deadline: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.deadline`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.deadlineSort,
        percentWidth: 15,
    },
    deadlineSort: {
        hide: true,
        percentWidth: null,
    },
    dateOutOfService: {
        percentWidth: null,
        hide: true,
    },
    absent: {
        percentWidth: null,
        hide: true,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'deadline',
    sortOrder: SortOrder.Ascending,
};

type IListProps = IRenderMasterContentProps<ListItem<IColumnNames>[]> & IPrivateProps & IComponentState;

class SelectEmployeesList extends PureComponent<IListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: IListProps) {
        super(props);

        this.renderEmployeeConditionTooltip = this.renderEmployeeConditionTooltip.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            footer,
            selectedToPlanIds,
            idToScrollIntoView,
        } = this.props;

        this.columns.condition.render = this.renderEmployeeConditionTooltip;

        return (
            <ListWithCheckboxSelect
                name={BASE_NAME}
                withSorting={true}
                initialSort={INITIAL_SORT}
                columns={this.columns}
                items={clientSideFilteredlistItems}
                selectedItemIds={selectedToPlanIds}
                onItemSelected={this.onItemSelected}
                idToScrollIntoView={idToScrollIntoView}
                sortableOnSelected={true}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                footer={footer}
                customSorter={customSorter}
                getCustomRowClasses={getCustomRowClasses}
                selectOnItemRowClicked
            />
        );
    }

    private onItemSelected(selectedIds: string[]) {
        const { setToPlanIds } = this.props;
        setToPlanIds(selectedIds);
    }

    private renderEmployeeConditionTooltip(listItem: ListItem<IColumnNames>) {
        return (
            <EmployeeConditionTooltip
                employee={{
                    absent: listItem.columns.absent as boolean,
                    dateOutOfService: listItem.columns.dateOutOfService as string,
                }}
            />
        );
    }
}

function mapEmployeesToPlanToListItems(masterData: IMedicalExaminationToPlan[]): ListItem<IColumnNames>[] {
    return masterData
        .map((toPlan) => ({
            id: getMedExamToAddId(toPlan),
            columns: {
                condition: '',
                employee: formatPersonNameFormal(toPlan.employee),
                function: toPlan.function.description,
                functionId: toPlan.function.id,
                seat: toPlan.company.name,
                seatCode: toPlan.company.companyCode,
                deadline: formatToBePlannedDateIfExists(toPlan),
                deadlineSort: getToBePlannedDateIfExists(toPlan),
                examinationReason: toPlan.examinationReason.title,
                absent: toPlan.absent,
                dateOutOfService: toPlan.employee.dateOutOfService,
            },
        }));
}

function formatToBePlannedDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.toBePlannedDate) {
        return formatDateInLongFormat(toPlan.toBePlannedDate);
    }

    return '';
}

function getToBePlannedDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.toBePlannedDate) {
        return toPlan.toBePlannedDate;
    }

    return '1900-01-01';
}

function clientSideFilterCustomDataSelector(state: IState) {
    return {
        selectedToPlanIds: getSelectedToPlanIds(state),
    };
}

function getSelectedToPlanIds(state: IState) {
    const wizardEntity = getPlanBufferzoneWizardEntity(state);
    const availableFreeSlots =
        wizardEntity.selectedBufferzone && wizardEntity.selectedBufferzone.availability.plannableTimeSlots;

    if (isInitialLoad(wizardEntity)) {
        const initialSelectedPlanIds = getInitialSelectedPlanIds(getEmployeesToPlan(state));
        return initialSelectedPlanIds.length > availableFreeSlots ?
            initialSelectedPlanIds.slice(0, availableFreeSlots) :
            initialSelectedPlanIds;
    }

    const currentSelectedMedicalExaminations = wizardEntity.employeesToPlan || NO_RERENDER.EMPTY_LIST;

    return currentSelectedMedicalExaminations
        .map((medExam) => getMedExamToAddId(medExam));
}

function isInitialLoad(wizardEntity: Partial<IPlanBufferzoneWizardEntity>) {
    // wizardEntity is resetted to an empty object when resetDataEntity=true
    return (typeof wizardEntity.employeesToPlan === 'undefined');
}

function getInitialSelectedPlanIds(masterData: IMedicalExaminationToPlan[]) {
    const deadlineThreshold = dayOffsetFromNow(DEFAULT_NR_OF_DAYS_IN_THE_FUTURE.TO_INITIALLY_SELECT_EMPLOYEES_TO_PLAN);

    return masterData
        .filter((item) => {
            if (item.toBePlannedDate > formatDateForBackend(deadlineThreshold)) {
                return false;
            }
            if (
                isBefore(item.toBePlannedDate, DEADLINE_FILTER.FROM_DEFAULT) ||
                isAfter(item.toBePlannedDate, DEADLINE_FILTER.TO_DEFAULT)
            ) {
                return false;
            }
            return true;
        })
        .sort((a, b) => {
            return (
                a.toBePlannedDate < b.toBePlannedDate ?
                    -1 : a.toBePlannedDate > b.toBePlannedDate ?
                        1 : 0
            );
        })
        .map((item) => getMedExamToAddId(item));
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getPlanBufferzoneWizardEntity(state);
        return {
            currentRoutePayload: getRoutePayload<IPlanBufferzoneWizardPayload>(state),
            // TODO reselect
            selectedToPlanIds: getSelectedToPlanIds(state),
            employeesToPlan: wizardEntity.employeesToPlan,
            selectedBufferzone: wizardEntity.selectedBufferzone,
            isInitialLoad: isInitialLoad(wizardEntity),
            employeesToPlanAsyncInfo: getEmployeesToPlanAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setToPlanIds: (selectedToPlanIds: string[]) => {
                const employeesToPlan = getPlanBufferzoneEmployeesToPlanCombinedWithAddedEmployees(getState());
                const selectedEmployeesToPlan = employeesToPlan
                    .filter((employeeToPlan) => selectedToPlanIds.includes(getMedExamToAddId(employeeToPlan)));
                const valuesToUpdate = mapEmployeesToPlanToMedicalExaminationsToAdd(selectedEmployeesToPlan);

                dispatch(updatePlanBufferzoneWizardEntity({
                    employeesToPlan: valuesToUpdate,
                }));
            },
            addEmployeeToEntity: (employee, examinationReason) => {
                const state = getState();
                const entity = getPlanBufferzoneWizardEntity(state);
                const medicalExaminationsToAdd = entity.employeesToPlan ? entity.employeesToPlan.slice() : [];
                const addedEmployees = entity.addedEmployees ? entity.addedEmployees.slice() : [];

                const employeesToPlan = getEmployeesToPlan(state);
                const examinationsToPlanForEmployee = filterEmployeesInList(employeesToPlan, employee);

                let matchingEmployeeToPlan;
                if (examinationsToPlanForEmployee && examinationsToPlanForEmployee.length > 0) {
                    const toPlanMatchedOnIdenticalReason = examinationsToPlanForEmployee
                        .find((examination) => examination.examinationReason.code === examinationReason.code);
                    if (toPlanMatchedOnIdenticalReason) {
                        matchingEmployeeToPlan = toPlanMatchedOnIdenticalReason;
                    } else if (examinationReason.code === EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT) {
                        matchingEmployeeToPlan = examinationsToPlanForEmployee[0];
                    }
                }

                if (!matchingEmployeeToPlan) {
                    matchingEmployeeToPlan = toMedicalExaminationToPlan(
                        employee,
                        examinationReason,
                    );

                    if (!findEmployeeInList(addedEmployees, employee)) {
                        addedEmployees.push(matchingEmployeeToPlan);
                    }
                }

                const uniqueId = getMedExamToAddId(matchingEmployeeToPlan);
                if (!medicalExaminationsToAdd.find((medExamToAdd) => getMedExamToAddId(medExamToAdd) === uniqueId)) {
                    medicalExaminationsToAdd.push(
                        mapEmployeeToPlanToMedicalExaminationToAdd(matchingEmployeeToPlan),
                    );
                }

                dispatch(updatePlanBufferzoneWizardEntity({
                    employeesToPlan: medicalExaminationsToAdd,
                    addedEmployees,
                }));

                return uniqueId;
            },
        };
    },
})(SelectEmployee);
