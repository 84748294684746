import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    registerReducer,
    createActionHandler,
} from '../../index';
import {
    UPDATE_INTERVENTION_REQUEST_WIZARD_ENTITY,
    CREATE_INTERVENTION_REQUEST,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState, createAsyncDoActionHandlers } from '../../../utils/libs/redux/async/asyncReducerUtils';
import {
    IInterventionRequestWizardPayload, IInterventionRequestWizardData,
} from '../../../models/interventions/requestIntervention';
import { getRequestInterventionWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INTERVENTION_REQUEST;

export interface IReducerState {
    interventionRequestWizardData: IInterventionRequestWizardData;
    createInterventionRequest: IAsyncDoField;
}

const initialState: IReducerState = {
    interventionRequestWizardData: null,
    createInterventionRequest: getAsyncDoInitialState(),
};

const actionHandlers = {
    [ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW]:
        createActionHandler<IReducerState, IInterventionRequestWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    interventionRequestWizardData: (stepId === getRequestInterventionWizardSteps().firstStepId)
                        ? {
                            stepId,
                            entity: payload.resetDataEntity
                                ? {}
                                : (oldState.interventionRequestWizardData
                                    && oldState.interventionRequestWizardData.entity) || {},
                        }
                        : {
                            ...oldState.interventionRequestWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_INTERVENTION_REQUEST_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    interventionRequestWizardData: {
                        ...oldState.interventionRequestWizardData,
                        entity: {
                            ...oldState.interventionRequestWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_INTERVENTION_REQUEST,
        fieldName: 'createInterventionRequest',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that request-intervention steps are not lost when refreshing
        interventionRequestWizardData: reducerState.interventionRequestWizardData,
    }),
});
