import dayjs from 'dayjs';

export function isInThePast(date: Date | string): boolean {
    if (!date) {
        return false;
    }

    const parsedDate = typeof date === 'string' ? dayjs(date).toDate() : new Date(date.getTime());

    return parsedDate.getTime() < new Date().getTime();
}
