import { object } from 'yup';
import { IAddressSave } from '../../../../../models/general/address';
import {
    nationalAddressSchema,
    nationalAddressOptionalSchema,
    internationalAddressSchema,
    internationalAddressOptionalSchema,
} from '../../../../common/address/schema';

export type TAddressFormValue = IAddressSave & { countryCode: string };
export type FormValues = {
    address: TAddressFormValue,
};

export const fields = {
    address: 'address',
};

export const schema = object({
    [fields.address]: nationalAddressSchema.required(),
});

export const optionalSchema = object({
    [fields.address]: nationalAddressOptionalSchema.required(),
});

export const internationalSchema = object({
    [fields.address]: internationalAddressSchema.required(),
});

export const optionalInternationalSchema = object({
    [fields.address]: internationalAddressOptionalSchema.required(),
});
