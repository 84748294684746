import React, { PureComponent } from 'react';
import classNames from 'classnames';

import {
    addCompanyUserActions,
    setAddCompanyUserWizardData,
} from '../../../../../../../../redux/company/users/actions';
import { AsyncStatus } from '../../../../../../../../models/general/redux';
import { DEFAULT_ACCESS_LEVELS } from '../../../../../../../../config/user.config';
import { formatPersonName } from '../../../../../../../../utils/formatting/formatPerson';
import {
    getAddUserWizardData,
    getAddCompanyUserAsyncInfo,
} from '../../../../../../../../redux/company/users/selectors';
import {
    getCompanySeatsForSelectedCompany,
    getSelectedCompany,
} from '../../../../../../../../redux/company/selected/selectors';
import { getWizardExitLinkActionMemoized } from '../../../../../../../../redux/ui/history/selectors';
import { IAddUserAccountPayload } from '../../../../../../../../models/user/userAccount';
import { IPerson } from '../../../../../../../../models/admin/employee';
import { IStepperStepRenderProps } from '../../../../../../../../models/general/stepper';
import connect from '../../../../../../../../utils/libs/redux/connect';
import { WIZARDFLOW_CLASSES } from '../../../../../../../common/navigation/Wizard/index';
import Form, { IFormRenderProps } from '../../../../../../../common/forms/Form';
import FormError from '../../../../../../../common/forms/FormError';
import Loader from '../../../../../../../common/waiting/Loader';
import PageHeader from '../../../../../../../appShell/PageHeader';
import StickyFooter from '../../../../../../../common/widget/StickyFooter';
import AccessToList from '../../../../../shared/AccessToList';

import { FORM_NAME, CLASS_NAME } from './AddUserAccessLevel.const';
import { IPrivateProps, TFormValues } from './AddUserAccessLevel.types';
import {
    isFutureAdminUser,
    parseAllSeatsToUserAccountSeats,
    parseSeatsToUserAccountSeats,
} from './AddUserAccessLevel.helpers';

class AccessLevel extends PureComponent<IStepperStepRenderProps & IPrivateProps> {
    constructor (props) {
        super(props);
    }

    public componentDidMount() {
        this.props.resetAddUser();
    }

    public componentDidUpdate(prevProps: IStepperStepRenderProps & IPrivateProps) {
        const { addCompanyUserAsyncInfo, navigateToAfterSuccessRoute } = this.props;
        if (prevProps.addCompanyUserAsyncInfo.status === AsyncStatus.Busy &&
            addCompanyUserAsyncInfo.status === AsyncStatus.Success
        ) {
            navigateToAfterSuccessRoute();
        }
    }

    public render() {
        const {
            addCompanyUser,
            addCompanyUserAsyncInfo,
            addUserWizardData,
            companyAllSeats,
            companySeats,
            renderStepButtons,
            setAddUserWizardData,
            skippedSeatsStep,
        } = this.props;

        const isAdmin = isFutureAdminUser(addUserWizardData);
        const isBusy = addCompanyUserAsyncInfo.status === AsyncStatus.Busy;

        const INITIAL_VALUES: TFormValues = addUserWizardData && addUserWizardData.accessLevel ? {
            accessLevel: addUserWizardData.accessLevel,
        } : {
            accessLevel: DEFAULT_ACCESS_LEVELS,
        };

        function handleSubmit(values: TFormValues) {
            if (skippedSeatsStep) {
                setAddUserWizardData({ ...values, seats: isAdmin
                    ? parseAllSeatsToUserAccountSeats(companyAllSeats)
                    : parseSeatsToUserAccountSeats(companySeats),
                });
            } else {
                setAddUserWizardData({
                    ...values,
                    ...(isAdmin) && {
                        seats: parseAllSeatsToUserAccountSeats(companyAllSeats),
                    },
                });
            }

            addCompanyUser();
        }

        return (
            <>
                <PageHeader
                    title="account.account_settings.manage_users.add.wizard.steps.access_level.title"
                    titlePlaceholders={{
                        name: formatPersonName(addUserWizardData as IPerson),
                    }}
                    text="account.account_settings.manage_users.add.wizard.steps.access_level.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <Form
                        name={FORM_NAME}
                        initialValues={INITIAL_VALUES}
                        handleSubmit={handleSubmit}
                        render={({ values, setFieldValue, submitForm }: IFormRenderProps<TFormValues>) => {
                            return (
                                <Loader show={isBusy}>
                                    <div className={classNames(WIZARDFLOW_CLASSES.NARROW_FORM, `${CLASS_NAME}__list`)}>
                                        <AccessToList
                                            accessLevel={values.accessLevel}
                                            onChange={(accessLevel) => setFieldValue('accessLevel', accessLevel)}
                                        />
                                        <FormError error={addCompanyUserAsyncInfo.error} />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                disabled: isBusy,
                                                onClick: submitForm,
                                                // eslint-disable-next-line max-len
                                                translationKey: 'account.account_settings.manage_users.add.wizard.steps.seats.finish_button',
                                            },
                                        })}
                                    </StickyFooter>
                                </Loader>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const companySeats = getCompanySeatsForSelectedCompany(state);
        const companyAllSeats = [getSelectedCompany(state)];

        return {
            addUserWizardData: getAddUserWizardData(state),
            addCompanyUserAsyncInfo: getAddCompanyUserAsyncInfo(state),
            skippedSeatsStep: companySeats && companySeats.length <= 1,
            companySeats,
            companyAllSeats,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setAddUserWizardData: (values) => dispatch(setAddCompanyUserWizardData(values)),
            addCompanyUser: () => {
                const wizardData = getAddUserWizardData(getState()) as IAddUserAccountPayload;
                dispatch(addCompanyUserActions.trigger(wizardData));
            },
            navigateToAfterSuccessRoute: () => dispatch(getWizardExitLinkActionMemoized(getState())),
            resetAddUser: () => dispatch(addCompanyUserActions.reset({})),
        };
    },
})(AccessLevel);
