import { clone } from 'ramda';

import { createEpic, IState } from '../../../index';
import {
    fetchExecutedMedicalExaminationsActions,
    fetchExecutedMedicalExaminationDetailSucceeded,
    fetchExecutedMedicalExaminationDetailFailed,
    fetchMedicalExaminationDocumentsActions,
    fetchExaminationDocumentsAndFilesActions,
    fetchExecutedMedicalExaminationDetail,
} from '../../actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
} from '../../../company/selected/selectors';
import { getQueryParams, getLocationState } from '../../../location/selectors';
import {
    IExecutedMedicalExamination,
    IExecutedMedicalExaminationsFilter,
    IFetchMedicalExaminationDetailPayload,
    IFetchMedicalExaminationDocumentsPayload,
} from '../../../../models/interventions/medicalExaminations';
import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import {
    DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../../api/interventions/medicalExaminations.api';
import Api from '../../../../api';
import ROUTE_KEYS from '../../../../routeKeys';
import { createNotFoundError } from '../../../../utils/api/error/createNotFoundError';
import {
    getEmployeeMedicalExaminations,
    areEmployeeMedicalExaminationsAvailable,
} from '../../../employee/info/selectors';
import {
    FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
    FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
} from '../../types';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import {
    getExecutedMedicalExaminationFromList, areExecutedMedicalExaminationsAvailable,
    getMedicalExaminationDocuments,
    toFlattenExaminationDocuments,
    getSelectedExecutedMedicalExamination,
} from '../../selectors';
import { fetchEmployeeRisksAndResearches } from '../../../employee/info/actions';
import { fetchDocument } from '../../../employee/documents/actions';
import isEmptyObject from '../../../../utils/core/object/isEmptyObject';
import { ROUTE_GROUP } from '../../../../config/routeGroup.config';
import { getRouteKeysThatBelongToGroup } from '../../../../routes';
import isTraceableApiError from '../../../../utils/api/isTraceableApiError';

const ACTION_TYPES_THAT_FETCH_EXECUTED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE);

// fetchExecutedMedicalExaminationsEpic
createEpic<IExecutedMedicalExamination[], IExecutedMedicalExaminationsFilter>({
    onActionType: [
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED,
        FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS,
            ...query,
        };

        return type !== ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
            || !areExecutedMedicalExaminationsAvailable(state);
    },
    processReturn: fetchExecutedMedicalExaminations,
    latest: false,
});

async function fetchExecutedMedicalExaminations({ api, getState }: { api: typeof Api, getState }) {
    try {
        const state = getState();
        const filterFromQuery = getQueryParams(state) as IExecutedMedicalExaminationsFilter;

        const executedMedicalExaminations = await doFetchExecutedMedExamsCall(api, state, filterFromQuery);

        return fetchExecutedMedicalExaminationsActions.succeeded(executedMedicalExaminations);
    } catch (error) {
        return fetchExecutedMedicalExaminationsActions.failed(error);
    }
}

function doFetchExecutedMedExamsCall(api: typeof Api, state: IState, filter: IExecutedMedicalExaminationsFilter) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

    return api.interventions.medicalExaminations
        .fetchExecuted(
            companyCode,
            filter,
            showFullFamily,
        );
}

// fetchExecutedMedicalExaminationDetailEpicIfNotAvailableEpic
createEpic<IFetchMedicalExaminationDetailPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_EXECUTED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState, action }) => {
        const state = getState();
        const shouldFetchAfterEmployeeExaminationsAreFetched =
            action.type === ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL &&
            !areEmployeeMedicalExaminationsAvailable(state);
        if (shouldFetchAfterEmployeeExaminationsAreFetched) {
            return false;
        }
        const selectedExamination = getSelectedExecutedMedicalExamination(state);
        return !selectedExamination || selectedExamination.id !== action.payload.examinationId;
    },
    processReturn({ action }) {
        return fetchExecutedMedicalExaminationDetail(action.payload);
    },
    latest: true,
});

// fetchExecutedMedicalExaminationDetailEpic
createEpic<IFetchMedicalExaminationDetailPayload>({
    onActionType: FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { id: employeeId } = action.payload;
            const state = getState();

            const examinationId = typeof action.payload.examinationId === 'string' ?
                Number(action.payload.examinationId) : action.payload.examinationId;

            let executedMedicalExaminationFromList = getExecutedMedicalExaminationFromList(state, examinationId);

            if (!executedMedicalExaminationFromList) {
                const filter: IExecutedMedicalExaminationsFilter =
                    clone(getQueryParams(state)) as IExecutedMedicalExaminationsFilter;
                const selectedEmployeeMedicalExaminations = getEmployeeMedicalExaminations(state);

                if (employeeId && selectedEmployeeMedicalExaminations.executed) {
                    const employeeExamination = selectedEmployeeMedicalExaminations.executed
                        .find((item) => item.id === examinationId);

                    if (employeeExamination) {
                        filter.startDate = formatDateForBackend(employeeExamination.examinationDate);
                        filter.endDate = formatDateForBackend(employeeExamination.examinationDate);
                    }
                }

                const executedMedicalExaminations = await doFetchExecutedMedExamsCall(api, state, filter);
                dispatch(fetchExecutedMedicalExaminationsActions.succeeded(executedMedicalExaminations));

                executedMedicalExaminationFromList = executedMedicalExaminations
                    .find((item) => item.id === examinationId);
            }

            if (executedMedicalExaminationFromList) {
                const employeeCustomerId = executedMedicalExaminationFromList.employee.id;

                if (!employeeId) {
                    dispatch(fetchEmployeeRisksAndResearches({ id: employeeCustomerId }));
                }
                dispatch(fetchMedicalExaminationDocumentsActions.trigger({
                    employeeCustomerId,
                    examinationId: executedMedicalExaminationFromList.id,
                }));
                dispatch(fetchExecutedMedicalExaminationDetailSucceeded(executedMedicalExaminationFromList));
            } else {
                dispatch(fetchExecutedMedicalExaminationDetailFailed(createNotFoundError()));
            }
            return done();
        } catch (error) {
            dispatch(fetchExecutedMedicalExaminationDetailFailed(error));
            return done();
        }
    },
    latest: true,
});

// fetchExaminationDocumentsEpic
createEpic<IFetchMedicalExaminationDocumentsPayload>({
    onActionType: FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    processReturn: fetchExaminationDocuments,
    latest: true,
});

async function fetchExaminationDocuments({ api, action }: { api: typeof Api, action }) {
    try {
        const examinationDocuments = await api.interventions.medicalExaminations
            .fetchExaminationDocuments(action.payload);

        return fetchMedicalExaminationDocumentsActions.succeeded(examinationDocuments);
    } catch (error) {
        return fetchMedicalExaminationDocumentsActions.failed(error);
    }
}

// fetchExaminationDocumentsAndFilesEpic
createEpic<IFetchMedicalExaminationDocumentsPayload>({
    onActionType: FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    async processMultiple({ getState, action, api }, dispatch, done) {
        try {
            const fetchDocumentsAction = await fetchExaminationDocuments({ api, action });
            dispatch(fetchDocumentsAction);

            if (isTraceableApiError(fetchDocumentsAction.payload)) {
                dispatch(fetchExaminationDocumentsAndFilesActions.failed(fetchDocumentsAction.payload));
                return done();
            }

            const examinationDocuments = getMedicalExaminationDocuments(getState());

            if (!isEmptyObject(examinationDocuments)) {
                dispatch(fetchDocument({
                    ids: toFlattenExaminationDocuments(examinationDocuments),
                }));
            }

            dispatch(fetchExaminationDocumentsAndFilesActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(fetchExaminationDocumentsAndFilesActions.failed(error));
            return done();
        }
    },
    latest: false,
});
