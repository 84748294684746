import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../index';
import { FETCH_APPOINTMENTS, FETCH_UPCOMING_ACTIVITY, SET_AGENDA_EXPORT_DATA } from './types';
import ROUTE_KEYS from '../../routeKeys';
import { TUpcomingActivity, IAppointmentsInfo } from '../../models/planning/activities';
import { REDUCER_KEYS } from '../../config/redux.config';
import { IAgendaExportData } from '../../models/planning/agenda';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';

export const reducerKey = REDUCER_KEYS.PLANNING;

export interface IReducerState {
    appointments: IAsyncFetchField<IAppointmentsInfo>;
    upcomingActivity: IAsyncFetchField<TUpcomingActivity>;
    agendaExportData: IAgendaExportData[];
}

const initialState: IReducerState = {
    appointments: getAsyncFetchInitialState(),
    upcomingActivity: getAsyncFetchInitialState(),
    agendaExportData: [],
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IAppointmentsInfo, IReducerState, IAppointmentsInfo>({
        baseActionType: FETCH_APPOINTMENTS,
        fieldName: 'appointments',
        overrideTriggerActionType: [
            FETCH_APPOINTMENTS,
            ROUTE_KEYS.R_AGENDA,
            ROUTE_KEYS.R_AGENDA_EVENT_DETAILS,
            ROUTE_KEYS.R_AGENDA_SEARCH,
            ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS,
        ],
        transformStateOnTrigger: ({ oldState }) => {
            return {
                ...oldState,
                agendaExportData: [],
            };
        },
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<TUpcomingActivity, IReducerState, TUpcomingActivity>({
        baseActionType: FETCH_UPCOMING_ACTIVITY,
        fieldName: 'upcomingActivity',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_HOME,
        ],
        reducerKey,
    }),
    ...createActionHandlersForType<IReducerState>(SET_AGENDA_EXPORT_DATA)
        .onTrigger<IAgendaExportData[]>(({ oldState, payload }) => {
            return {
                ...oldState,
                agendaExportData: payload,
            };
        })
        .create(),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
