import dayjs from 'dayjs';

export function now() {
    return new Date();
}

export function yearOffsetFromNow(nrOfYearsToAdd = 1) {
    return dayjs().clone()
        .add(nrOfYearsToAdd, 'year');
}

export function monthOffsetFromNow(nrOfMonthsToAdd = 1) {
    return dayjs().clone()
        .add(nrOfMonthsToAdd, 'month');
}

export function dayOffsetFromNow(nrOfDaysToAdd = 1) {
    return dayjs().clone()
        .add(nrOfDaysToAdd, 'day');
}

export function dayOffsetFromDate(date: Date, nrOfDaysToAdd = 1) {
    return dayjs(date).clone()
        .add(nrOfDaysToAdd, 'day');
}

export function monthOffsetFromDate(date: Date, nrOfMonthsToAdd = 1) {
    return dayjs(date).clone()
        .add(nrOfMonthsToAdd, 'month');
}

export function minutesOffsetFromDate(date: Date, nrOfMinutesAdd = 1) {
    return dayjs(date).clone()
        .add(nrOfMinutesAdd, 'minute');
}

export function hoursOffsetFromNow(nrOfHoursToAdd = 1) {
    return dayjs().clone()
        .add(nrOfHoursToAdd, 'hour');
}

export function getMonday(date: string | Date) {
    const parsedDate = typeof date === 'string' ? getDate(date) : new Date(date.getTime());
    const day = parsedDate.getDay();
    const diff = parsedDate.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(parsedDate.setDate(diff));
}

/* Currently returns for endOfWeek the next monday (+7 days) because the calendar's endDate filter is not inclusive */
export function getStartAndEndDateOfWeek(date: string | Date) {
    const startOfWeek = getDateWithoutTime(getMonday(date));
    return {
        startOfWeek,
        endOfWeek: dayOffsetFromDate(startOfWeek, 7).toDate(),
    };
}

export function getNextWorkday(date: string | Date) {
    const parsedDate = typeof date === 'string' ? getDate(date) : new Date(date.getTime());
    // check if first of the month is a Sunday, if so set date to the second
    if (parsedDate.getDay() === 0) {
        return new Date(parsedDate.setDate(2));
    }

    // check if first of the month is a Saturday,  if so set date to the third
    if (parsedDate.getDay() === 6) {
        return new Date(parsedDate.setDate(3));
    }
    return getMonday(parsedDate);
}

export function getDateWithoutTime(date: string | Date) {
    const parsedDate = typeof date === 'string' ? getDate(date) : new Date(date.getTime());
    return new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
}

export function getDate(formattedDate: string) {
    return dayjs(formattedDate).toDate();
}

export function getDateWithoutSeconds(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
}

export function getFirstWorkday(month: number, year: number) {
    const firstDayOfTheMonth = new Date(year, month, 1, 0, 0, 0, 0);

    // check if first of the month is a Sunday, if so set date to the second
    if (firstDayOfTheMonth.getDay() === 0) {
        return new Date(firstDayOfTheMonth.setDate(2));
    }

    // check if first of the month is a Saturday,  if so set date to the third
    if (firstDayOfTheMonth.getDay() === 6) {
        return new Date(firstDayOfTheMonth.setDate(3));
    }

    return firstDayOfTheMonth;
}

export function getCurrentYear(): number {
    return now().getFullYear();
}

export function getDateWithinCurrentYear({ month, day }: { month: number; day: number }) {
    return new Date(getCurrentYear(), month - 1, day, 0, 0, 0, 0);
}
export function getNextYear(): number {
    return (now().getFullYear()) + 1;
}

export function getDateWithinNextYear({month, day}: {month: number; day: number}) {
    return new Date(getNextYear(), month - 1, day, 0, 0, 0, 0);
}

export function getCurrentMonth() {
    return new Date().getMonth() + 1;
}
