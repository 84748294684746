import { ICompanyContact } from '../admin/companyInfo';
import { PRESTATION_CODE } from '../../config/navigation/interventions.config';

export interface ICreateInterventionRequest {
    type: string;
    contact: Pick<ICompanyContact, 'firstName' | 'name' | 'email' | 'phone' | 'mobilePhone'>;
    remarks: string;
    prestationCode: PRESTATION_CODE; // only needed for google analytics event
}

export enum INTERVENTION_REQUEST_WIZARD_STEP_ID {
    INTERVENTION = 'intervention',
    CONTACT = 'contact',
    REMARKS = 'remarks',
    VALIDATE = 'validate',
}

export interface IInterventionRequestWizardPayload {
    step: INTERVENTION_REQUEST_WIZARD_STEP_ID;
    resetDataEntity: boolean;
    prestationCode?: PRESTATION_CODE;
}

export interface IInterventionRequestWizardEntity {
    contact: ICompanyContact;
    type: string;
    remarks: {
        remark: string;
    };
}

export interface IInterventionRequestWizardData {
    stepId: string;
    entity: Partial<IInterventionRequestWizardEntity>;
}
