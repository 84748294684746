import isString from '@snipsonian/core/es/is/isString';
import { IColumn, ISortedColumn, ListItem, SortOrder, SortType } from '../../models/general/list';
import { stringComparerAscending, numberComparerAscending, partsComparer } from './comparerUtils';

export default function sortListItems<LI extends ListItem<{}>>(
    items: LI[],
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
): LI[] {
    if (shouldNotBeSorted(sortedColumn)) {
        return items.slice();
    }

    const ascendingComparer = getAscendingComparer(sortedColumn, columnConfig);

    if (!ascendingComparer) {
        // Unable to sort this, return as is
        return items;
    }

    const sortedAscending = items
        .slice()
        .sort(ascendingComparer);

    return reverseIfDescending(sortedAscending, sortedColumn.sortOrder);
}

function getAscendingComparer(
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
) {
    switch (columnConfig.sortType) {
        case SortType.String:
            return getAscendingComparerForStrings(sortedColumn, columnConfig);
        case SortType.Number:
            return getAscendingComparerForNumbers(sortedColumn, columnConfig);
        case SortType.Boolean:
            return getAscendingComparerForBooleans(sortedColumn, columnConfig);
        case SortType.DotSeparatedNumber:
            return getAscendingComparerForDotSeparatedNumbers(sortedColumn, columnConfig);
    }

    return null;
}

function getAscendingComparerForStrings<LI extends ListItem<{}>>(
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
) {
    return (a: LI, b: LI) => {
        const x = (getItemSortValue(a, sortedColumn.name, columnConfig) || '').toLowerCase();
        const y = (getItemSortValue(b, sortedColumn.name, columnConfig) || '').toLowerCase();

        return stringComparerAscending(x, y);
    };
}

function getAscendingComparerForNumbers<LI extends ListItem<{}>>(
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
) {
    return (a: LI, b: LI) => {
        const x = getItemSortValue(a, sortedColumn.name, columnConfig);
        const y = getItemSortValue(b, sortedColumn.name, columnConfig);

        return numberComparerAscending(x, y);
    };
}

function getAscendingComparerForBooleans<LI extends ListItem<{}>>(
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
) {
    return (a: LI, b: LI) => {
        const x = getItemSortValue(a, sortedColumn.name, columnConfig);
        const y = getItemSortValue(b, sortedColumn.name, columnConfig);

        if (x === null) {
            if (y === null) {
                return 0;
            }
            return -1;
        }
        if (y === null) {
            return 1;
        }

        return (!x && y) ? -1 : (x && !y) ? 1 : 0;
    };
}

function getAscendingComparerForDotSeparatedNumbers<LI extends ListItem<{}>>(
    sortedColumn: ISortedColumn<{}>,
    columnConfig: IColumn<{}>,
) {
    return (a: LI, b: LI) => {
        const x = getItemSortValue(a, sortedColumn.name, columnConfig);
        const y = getItemSortValue(b, sortedColumn.name, columnConfig);

        const xParts = splitStringToNumericParts(x, '.');
        const yParts = splitStringToNumericParts(y, '.');

        return partsComparer(xParts, yParts, numberComparerAscending);
    };
}

function shouldNotBeSorted(sortedColumn: ISortedColumn<{}>) {
    return !sortedColumn || sortedColumn.sortOrder === SortOrder.None;
}

function reverseIfDescending<LI extends ListItem<{}>>(items: LI[], sortOrder: SortOrder): LI[] {
    return sortOrder === SortOrder.Descending ? items.reverse() : items;
}

function getItemSortValue<LI extends ListItem<{}>>(item: LI, columnName: string, columnConfig: IColumn<{}>) {
    if (typeof columnConfig.sortValue === 'function') {
        return columnConfig.sortValue(item);
    }

    return item.columns[columnName];
}

export function splitStringToNumericParts(str: string, separator: string) {
    return isString(str)
        ? str.trim()
            .toLowerCase()
            .split(separator)
            .map((strPart) => Number(strPart))
        : [null];
}
