import store from '../store';
import checkStoreChangesByOtherBrowserTabsOrWindows from './checkStoreChangesByOtherBrowserTabsOrWindows';
import initApiErrorHandler from './initApiErrorHandler';
import startListeningForTranslationIdsToggles from './startListeningForTranslationIdsToggles';
import { FETCH_UNREAD_MESSAGE_COUNT_INTERVAL_IN_MILLIS } from '../../config/inbox.config';
import { setBackendLocale, setDateLocalization } from '../../config/i18n.config';
import { AsyncStatus, IAction, IInitAppActionPayload } from '../../models/general/redux';
import {
    fetchMyUserInfo,
    checkSessionEndTime,
    fetchPerformanceDashboardAccessActions,
} from '../auth/actions';
import { getCsrfToken, isLoggedIn, getMyUserProfile, getFetchMyUserAsyncInfo, getAccessToken } from '../auth/selectors';
import { fetchSmallCompanyDetail } from '../company/info/actions';
import { fetchSelectedCompanySeatsAndDivisionsActions } from '../company/selected/actions';
import { isSeatSelected, getSelectedSeatCompanyCode, getSelectedCompanyCode } from '../company/selected/selectors';
import { fetchAllConstants } from '../constants/actions';
import { resetTranslationsRefreshed, refreshTranslations, toggleTranslationIds } from '../i18n/actions';
import { getLocale, getBackendLocale } from '../i18n/selectors';
import { fetchUnreadMessagesCount } from '../inbox/actions';
import { fetchFluVaccinesConfig } from '../intervention/fluVaccine/actions';
import { getRouteKey } from '../location/selectors';
import { triggerNewVersionNotification } from '../notifications/actions';
import ROUTE_KEYS from '../../routeKeys';
import { setAccessTokenGetter, setCsrfToken, setLocaleHeader } from '../../utils/api/requestWrapper';
import executePeriodicallyWhenBrowserTabActive
    from '../../utils/browser/tabVisibility/executePeriodicallyWhenBrowserTabActive';
import { setUserContext } from '../../utils/logging/errorReporter';
import { onNewContentAvailable } from '../../utils/serviceWorker/newContentAvailable';
import executeOnLoad from '../../utils/browser/events/executeOnLoad';
import { fetchCompanySituationHistoryActions } from '../preventionUnits/actions';
import { CHECK_SESSION_END_INTERVAL_IN_MILLIS, CHECK_APP_VERSION_INTERVAL_IN_MILLIS } from '../../config/authorisation';
import { initCookieConsent as initCookieConsentAction } from '../../snipsonian/react-cookie-consent/src/redux/actions';
import checkAppVersionHandler from './checkAppVersionHandler';
import { ENVS } from '../../config/envs.config';

export default function initApp() {
    initRefreshTranslations();

    listenForServiceWorkerChanges();

    startListeningForTranslationIdsToggles(() => {
        dispatchAction(toggleTranslationIds());
    });

    initApiErrorHandler(store);
    initCookieConsent();

    setCsrfTokenAfterPageReload();
    setLocaleApiHeaderAfterPageReload();
    setBackendLocaleAfterPageReload();
    setDateLocalizationAfterPageReload();
    setAccessTokenAfterPageReload();

    checkStoreChangesByOtherBrowserTabsOrWindows();

    setErrorReporterUserContextAfterPageReload();

    /**
     * The order of these api fetches is important in case there's a fallback from http/2 to http/1,
     * as http/1 has a limited number of simultaneous connections causing the api calls - that are executed
     * after the limit is reached - to have a significant stall time.
     * (also see KZUAT-606)
     */
    fetchUserInfoAfterPageReload();
    fetchCompanyHistoryAfterPageReload();
    fetchCompanyDetailAfterPageReload();
    fetchCompanySeatsAndDivisionsAfterPageReload();
    refreshConstantsAfterPageReload();
    preFetchFluVaccinesConfigAfterPageReload();
    periodicallyFetchNumberOfUnreadMessages();
    periodicallyCheckIfSessionIsAboutToExpire();
    periodicallyCheckIfAppVersionChanged();
    fetchPerformanceDashboardAccessAfterPageReload();
}

function initCookieConsent() {
    dispatchAction(initCookieConsentAction());
}

function initRefreshTranslations() {
    dispatchAction(resetTranslationsRefreshed());

    executeOnLoad({
        toBeExecuted: () => dispatchAction(refreshTranslations()),
    });
}

function listenForServiceWorkerChanges() {
    onNewContentAvailable(() => {
        dispatchAction(triggerNewVersionNotification());
    });
}

function setCsrfTokenAfterPageReload() {
    const csrfToken = getCsrfToken(store.getState());
    if (csrfToken) {
        setCsrfToken(csrfToken);
    }
}

function setLocaleApiHeaderAfterPageReload() {
    const locale = getLocale(store.getState());
    setLocaleHeader(locale);
}

function setBackendLocaleAfterPageReload() {
    const backendLocale = getBackendLocale(store.getState());
    setBackendLocale(backendLocale);
}

function setDateLocalizationAfterPageReload() {
    const locale = getLocale(store.getState());
    setDateLocalization(locale);
}

function setErrorReporterUserContextAfterPageReload() {
    const userInfo = getMyUserProfile(store.getState());
    if (userInfo) {
        setUserContext(userInfo);
    }
}

function setAccessTokenAfterPageReload() {
    setAccessTokenGetter({
        getAccessToken: () => getAccessToken(store.getState()),
    });
}

function fetchUserInfoAfterPageReload() {
    if (isAlreadyLoggedIn() && !isFetchingMyUserInfo()) {
        dispatchAction(fetchMyUserInfo());
    }
}

function fetchCompanyHistoryAfterPageReload() {
    const selectedCompanyCode = getSelectedCompanyCode(store.getState());
    if (isAlreadyLoggedIn() && !!selectedCompanyCode) {
        dispatchAction(fetchCompanySituationHistoryActions.trigger({}));
    }
}

function fetchCompanyDetailAfterPageReload() {
    const selectedSeatCompanyCode = getSelectedSeatCompanyCode(store.getState());
    if (isAlreadyLoggedIn() && !!selectedSeatCompanyCode) {
        dispatchAction(fetchSmallCompanyDetail({ companyCode: selectedSeatCompanyCode }));
    }
}

function fetchPerformanceDashboardAccessAfterPageReload() {
    const selectedCompanyCode = getSelectedCompanyCode(store.getState());
    if (isAlreadyLoggedIn() && !!selectedCompanyCode) {
        dispatchAction(fetchPerformanceDashboardAccessActions.trigger({}));
    }
}

function fetchCompanySeatsAndDivisionsAfterPageReload() {
    if (isAlreadyLoggedIn()) {
        const state = store.getState();
        const currentRouteKey = getRouteKey(state);
        if (![ROUTE_KEYS.R_COMPANY_SELECTION, ROUTE_KEYS.R_SEATS_SELECTION].includes(currentRouteKey)) {
            dispatchAction(fetchSelectedCompanySeatsAndDivisionsActions.trigger({}));
        }
    }
}

function refreshConstantsAfterPageReload() {
    executeOnLoad({
        toBeExecuted: () => dispatchAction(fetchAllConstants(null, isAlreadyLoggedIn())),
    });
}

function preFetchFluVaccinesConfigAfterPageReload() {
    if (isAlreadyLoggedIn()) {
        executeOnLoad({
            toBeExecuted: () => dispatchAction(fetchFluVaccinesConfig()),
        });
    }
}

function periodicallyFetchNumberOfUnreadMessages(waitOnLoad: boolean = false) {
    executeOnLoad({
        toBeExecuted: () => executePeriodicallyWhenBrowserTabActive({
            toBeExecuted: fetchNumberOfUnreadMessages,
            intervalInMillis: FETCH_UNREAD_MESSAGE_COUNT_INTERVAL_IN_MILLIS,
            executeImmediatelyInActiveTab: true,
        }),
    });

    function fetchNumberOfUnreadMessages() {
        if (isAlreadyLoggedIn() && isSeatSelected(store.getState())) {
            dispatchAction(fetchUnreadMessagesCount());
        }
    }
}

function periodicallyCheckIfSessionIsAboutToExpire() {
    executeOnLoad({
        toBeExecuted: () => executePeriodicallyWhenBrowserTabActive({
            toBeExecuted: checkSession,
            intervalInMillis: CHECK_SESSION_END_INTERVAL_IN_MILLIS,
            executeImmediatelyInActiveTab: true,
        }),
    });

    function checkSession() {
        if (isAlreadyLoggedIn()) {
            dispatchAction(checkSessionEndTime());
        }
    }
}

function periodicallyCheckIfAppVersionChanged() {
    /**
         * On localhost and DEV (frontend doesn't know the correct version)
         * we can not correctly know when to show the warning.
         * version.txt will always be 'latest'
         */
    if (
        !window.klantenzone
            || (window.klantenzone.ENV !== ENVS.LOCAL
                && window.klantenzone.ENV !== ENVS.DEV)
    ) {
        executeOnLoad({
            toBeExecuted: () => executePeriodicallyWhenBrowserTabActive({
                toBeExecuted: () => checkAppVersionHandler(store),
                intervalInMillis: CHECK_APP_VERSION_INTERVAL_IN_MILLIS,
                executeImmediatelyInActiveTab: true,
            }),
        });
    }
}

function isAlreadyLoggedIn() {
    const state = store.getState();
    return isLoggedIn(state);
}

function isFetchingMyUserInfo() {
    const state = store.getState();
    return getFetchMyUserAsyncInfo(state).status === AsyncStatus.Busy;
}

function dispatchAction(action: IAction<{}>) {
    const payload: IInitAppActionPayload = {
        ...action.payload,
        triggeredOnApplicationInit: true,
    };
    store.dispatch({
        ...action,
        payload,
    });
}
