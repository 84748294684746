import React, { ReactNode, PureComponent, ReactElement } from 'react';
import './slide-out-panel.scss';
import OverlayCurtain from '../../modals/OverlayCurtain';
import classNames from 'classnames';
import CloseIcon from '../../icons/CloseIcon';
import closest from '../../../../utils/dom/closest';
import isPopperElement from '../../../../utils/dom/isPopperElement';
import ContainFocus from '../../technical/ContainFocus';
import { isKeyPressed } from '../../../../utils/keyboard';

export interface ISlideOutPanelProps {
    header?: ReactNode;
    footer?: ReactNode;
    containerTofocus?: string;
    children?: ReactNode | ((renderProps: ISlideoutPanelRenderProps) => ReactNode);
    isOpen: boolean;
    onCloseIntent: (isClickedOutside: boolean) => void;
    showOverlay?: boolean;
    overlay?: ReactElement<{ children: ReactNode }>;
    className?: string;
}

export interface ISlideoutPanelRenderProps {
    renderContent: (children: ReactNode) => ReactNode;
    renderHeader: (header?: ReactNode) => ReactNode;
    renderFooter: (footer?: ReactNode) => ReactNode;
}

interface IState {
    isAnimatingOnClose: boolean;
    isAnimatingOnOpen: boolean;
    isAnimatingOverlayOnClose: boolean;
}

const CLASS_NAME = 'SlideOutPanel';

export const SLIDEOUTPANEL_CLASSES = {
    ACTIONS: `${CLASS_NAME}__content__actions`,
    OVERLAY: {
        HEADER: `${CLASS_NAME}__overlay__header`,
    },
};

export default class SlideOutPanel extends PureComponent<ISlideOutPanelProps, IState> {

    constructor(props: ISlideOutPanelProps) {
        super(props);

        this.state = {
            isAnimatingOnClose: false,
            isAnimatingOnOpen: props.isOpen,
            isAnimatingOverlayOnClose: false,
        };

        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.onTransitionOverlayEnd = this.onTransitionOverlayEnd.bind(this);
        this.onBodyClick = this.onBodyClick.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

        document.body.addEventListener('click', this.onBodyClick);
        document.body.addEventListener('keyup', this.onKeyUp);
    }

    public render() {
        const { isOpen, onCloseIntent, children, showOverlay, overlay, className, containerTofocus } = this.props;
        const { isAnimatingOnClose, isAnimatingOverlayOnClose, isAnimatingOnOpen } = this.state;

        const classes = classNames(
            CLASS_NAME,
            className,
            {
                [`${CLASS_NAME}--open`]: isOpen,
                [`${CLASS_NAME}--blurred`]: showOverlay,
            },
        );

        const overlayClasses = classNames(`${CLASS_NAME}__overlay`, {
            [`${CLASS_NAME}__overlay--open`]: showOverlay && isOpen,
        });

        const childrenTypedAsFunction = children as ((renderProps: ISlideoutPanelRenderProps) => ReactNode);

        return (
            <OverlayCurtain
                show={isOpen || isAnimatingOnClose}
                fadeEffect="darken"
                animationEffect="none"
                allowClickingOnUnderlyingElements={true}
            >
                <ContainFocus
                    hasFocus={isOpen && !showOverlay}
                    priority={1}
                    containerTofocus={!isAnimatingOnOpen && containerTofocus}
                >
                    <div className={classes} onTransitionEnd={this.onTransitionEnd}>
                        <CloseIcon onClick={() => onCloseIntent(false)} />
                        {typeof children === 'function' ? childrenTypedAsFunction({
                            renderHeader: this.renderHeader,
                            renderContent: this.renderContent,
                            renderFooter: this.renderFooter,
                        }) : (
                                <>
                                    {this.renderHeader()}
                                    {this.renderContent(children)}
                                    {this.renderFooter()}
                                </>
                            )
                        }
                    </div>
                    <ContainFocus hasFocus={showOverlay} priority={1}>
                        <div className={overlayClasses} onTransitionEnd={this.onTransitionOverlayEnd}>
                            {showOverlay || isAnimatingOverlayOnClose ? overlay : null}
                        </div>
                    </ContainFocus>
                </ContainFocus>
            </OverlayCurtain>
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ISlideOutPanelProps) {
        if (this.props.isOpen && !nextProps.isOpen) {
            this.setState({
                isAnimatingOnClose: true,
            });
        }
        if (!this.props.isOpen && nextProps.isOpen) {
            this.setState({
                isAnimatingOnOpen: true,
            });
        }
        if (this.props.showOverlay && !nextProps.showOverlay) {
            this.setState({
                isAnimatingOverlayOnClose: true,
            });
        }
    }

    public componentWillUnmount() {
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.removeEventListener('keyup', this.onKeyUp);
    }

    private onTransitionEnd() {
        const { isOpen } = this.props;
        if (!isOpen) {
            this.setState({
                isAnimatingOnClose: false,
            });
        } else {
            this.setState({
                isAnimatingOnOpen: false,
            });
        }
    }

    private onTransitionOverlayEnd() {
        const { showOverlay } = this.props;
        if (!showOverlay) {
            this.setState({
                isAnimatingOverlayOnClose: false,
            });
        }
    }

    private onBodyClick(e: MouseEvent) {
        const { isOpen, onCloseIntent } = this.props;
        if (isOpen) {
            const targetEl = e.target as HTMLElement;
            const isClickedOnPanel = closest(targetEl, (node) => {
                const el = node as HTMLElement;
                return el.classList && el.classList.contains(CLASS_NAME);
            });
            if (!isClickedOnPanel && !isPopperElement(targetEl)) {
                onCloseIntent(true);
            }
        }
    }

    private renderHeader(header?: ReactNode) {
        const headerComp = header || this.props.header;
        return headerComp && (
            <header className={`${CLASS_NAME}__header`}>
                {headerComp}
            </header>
        );
    }

    private renderContent(children: ReactNode) {
        return (
            <div className={`${CLASS_NAME}__content`} data-scroll-container={true}>
                {children}
            </div>
        );
    }

    private renderFooter(footer?: ReactNode) {
        const footerComp = footer || this.props.footer;
        return footerComp && (
            <footer className={`${CLASS_NAME}__footer`}>
                {footerComp}
            </footer>
        );
    }

    private onKeyUp(e: KeyboardEvent) {
        if (isKeyPressed(e, 'Escape') && this.props.isOpen) {
            e.preventDefault();
            this.props.onCloseIntent(false);
        }
    }
}
