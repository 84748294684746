import { createSelector } from 'reselect';
import { IState, getReducerState, NO_RERENDER, makeAsyncFetchInfoSelector } from '../../index';
import { IReducerState, reducerKey } from './reducer';
import { ISelectCompanySeatPayload, ICompanySeat } from '../../../models/admin/company';
import filterCompanySeats from './helpers/filterCompanySeats';
import filterCompanySeatDivisionsByCompanyCode from './helpers/filterCompanySeatDivisionsByCompanyCode';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Selected company

export const getSelectedCompany = (state: IState) => reducerState(state).selectedCompany;

export const getSelectedCompanyCode = (state: IState) => {
    const selectedCompany = getSelectedCompany(state);
    return selectedCompany && selectedCompany.companyCode;
};

export const getPrevSelectedCompany = (state: IState) => reducerState(state).prevSelectedCompany;

export const isSelectedCompanyDismissed = (state: IState) => {
    const selectedCompany = getSelectedCompany(state);
    if (!selectedCompany) {
        return false;
    }
    return selectedCompany.dismissed;
};

// All seats selected

export const isAllSeatsSelected = (state: IState) => reducerState(state).isAllSeatsSelected;

// Seats selected

export const isSeatSelected = (state: IState) => reducerState(state).selectedCompanySeat;

// is interim company
export const isCompanyAnInterimCompany = (state: IState) => reducerState(state).isInterimCompany;

// Website Student Worker

export const getSelectedSeatHasStudentWorkers = (state: IState) => {
    const seat = getSelectedCompanySeat(state);
    if (!seat.companySeat) {
        return false;
    }
    return seat.companySeat.websiteStudentWorker;
};

// Selected company seat

export const getSelectedCompanySeat = (state: IState) => {
    return {
        companySeat: reducerState(state).selectedCompanySeat,
        isAllSeatsSelected: isAllSeatsSelected(state),
    } as ISelectCompanySeatPayload;
};

export const getSelectedSeatCompanyCode = (state: IState) => {
    const { selectedCompanySeat } = reducerState(state);
    return selectedCompanySeat && selectedCompanySeat.company.companyCode;
};

export const getSelectedSeatCompanyId = (state: IState) => {
    const { selectedCompanySeat } = reducerState(state);
    return selectedCompanySeat && selectedCompanySeat.company.id;
};

// Selected company seats / divisions

const getSelectedCompanySeatsAndDivisionsAsyncField =
    (state: IState) => reducerState(state).selectedCompanySeatsAndDivisions;
export const areSelectedCompanySeatsAndDivisionsAvailable = (state: IState) => {
    const asyncInfo = getSelectedCompanySeatsAndDivisionsAsyncField(state);
    return asyncInfo.data !== null && !asyncInfo.isFetching;
};

export const getSeatsAndDivisionsForSelectedCompany = (state: IState) => {
    return reducerState(state).selectedCompanySeatsAndDivisions.data || NO_RERENDER.EMPTY_LIST as ICompanySeat[];
};

export const getSeatsAndDivisionsForEmployee = (state: IState) => {
    if (isAllSeatsSelected(state)) {
        return getSeatsAndDivisionsForSelectedCompany(state);
    }
    return getSeatsAndDivisionsForSelectedCompanySeat(state);
};

export const getCompanySeatsForSelectedCompany = createSelector(
    getSeatsAndDivisionsForSelectedCompany,
    data => filterCompanySeats(data),
);

export const getSeatsAndDivisionsForSelectedCompanySeat = createSelector(
    getSeatsAndDivisionsForSelectedCompany,
    getSelectedCompanySeat,
    (companySeatsAndDivisions, { isAllSeatsSelected, companySeat }) => {
        if (!companySeat) {
            return NO_RERENDER.EMPTY_LIST;
        }
        if (isAllSeatsSelected) {
            return companySeatsAndDivisions;
        }
        return filterCompanySeatDivisionsByCompanyCode(companySeat.company.companyCode, companySeatsAndDivisions);
    },
);
export const getFetchSelectedCompanySeatsAndDivisionsAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedCompanySeatsAndDivisionsAsyncField);
