import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState } from '../../index';

const DEFAULT_FORM_INFO = {
    dirty: false,
    isValid: false,
    name: null,
};

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export const getFormInfo = (state: IState, formName: string) => {
    const activeForm = reducerState(state).activeForms.find((form) => form.name === formName);
    return activeForm || DEFAULT_FORM_INFO;
};

export function isApplicationInitializationFailed(state: IState) {
    return reducerState(state).applicationInitilizationFailed;
}
