import ROUTE_KEYS from '../routeKeys';
import { IState } from '../redux';
import { isSelectedCompanyDismissed } from '../redux/company/selected/selectors';
import { getLastDashboardRoute } from '../redux/ui/history/selectors';
import { TParentRouteKeyOverride } from '../utils/routing/typings';

export function overrideParentRouteKeyToHomeDashboardIfDismissedCompany(state: IState): TParentRouteKeyOverride {
    return isSelectedCompanyDismissed(state) ? ROUTE_KEYS.R_HOME : false;
}

/**
 * To be used when two or more dashboard tiles, from different dashboards, link to the same route.
 * This will override the parent route depending on the dashboard that was lastly viewes, resulting
 * in a breadcrumb that resembles the path the user took to get to the specific route.
 */
export function overrideParentRouteKeyByLastDashboardRoute(state: IState): TParentRouteKeyOverride {
    const lastDashboardRoute = getLastDashboardRoute(state);
    return lastDashboardRoute ? lastDashboardRoute.type : false;
}

export function conditionallyOverrideParentRouteKey(
    state: IState,
    {
        checkDismissedCompany = false,
        checkLastDashboardRoute = false,
    }: {
        checkDismissedCompany?: boolean;
        checkLastDashboardRoute?: boolean;
    },
): TParentRouteKeyOverride {
    if (checkDismissedCompany) {
        const overrideIfDismissedCompany = overrideParentRouteKeyToHomeDashboardIfDismissedCompany(state);
        if (overrideIfDismissedCompany) {
            return overrideIfDismissedCompany;
        }
    }

    if (checkLastDashboardRoute) {
        const overrideByLastDashboardRoute = overrideParentRouteKeyByLastDashboardRoute(state);
        if (overrideByLastDashboardRoute) {
            return overrideByLastDashboardRoute;
        }
    }

    return false;
}
