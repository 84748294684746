import React, { Component, Fragment } from 'react';
import { createSelector } from 'reselect';
import isSet from '@snipsonian/core/es/is/isSet';
import './course-detail-sessions.scss';
import isEmptyObject from '../../../../../../../utils/core/object/isEmptyObject';
import { ICourseSession, ICourseSessionsByMonth } from '../../../../../../../models/documentCenter/courses';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../models/general/redux';
import { IStartEndDateFilterValues } from '../../../../../../../models/ui/form';
import {
    getCourseSessions,
    getCourseSessionsAsyncInfo,
} from '../../../../../../../redux/documentCenter/courses/selectors';
import { navigateTo } from '../../../../../../../redux/location/actions';
import { getQueryParams, getRoutePayload } from '../../../../../../../redux/location/selectors';
import ROUTE_KEYS from '../../../../../../../routeKeys';
import {
    getUniqueTypeaheadFilterValuesFromCourses,
} from '../../../../../../../utils/documentCenter/courses/filterCourses';
import clientSideFilter from '../../../../../../../utils/documentCenter/courses/filterCourseSessions';
import CollapsibleCheckboxesFilter from '../../../../../../common/input/CollapsibleCheckboxesFilter';
import StartEndDateFilter from '../../../../../../common/input/StartEndDateFilter';
import Translate from '../../../../../../common/Translate';
import Loader from '../../../../../../common/waiting/Loader';
import CollapsibleItem from '../../../../../../common/widget/CollapsibleItem';
import Header from '../../../../../../common/widget/MasterWithDetail/Header';
import { CLASS_NAME as MASTER_WITH_DETAIL_CLASS_NAME } from '../../../../../../common/widget/MasterWithDetail/index';
import ActiveFilters, {
    createGenericActiveFilters,
} from '../../../../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { IRenderFilterContentProps } from '../../../../../../common/widget/MasterWithDetail/typings';
import { connect } from '../../../../../../index';
import CourseSessionByMonth from './CourseSessionByMonth';
import { BASE_NAME } from './CourseSessionByMonth/common';
import IndexInfoBox from '../../../../shared/IndexInfoBox';
import { toFlattenedFirstdaySessions } from '../../../../../../../utils/documentCenter/courses/sessions';

export interface IFilterValues extends IStartEndDateFilterValues {
    province: string;
    language: string;
}

interface IPrivateProps {
    courseSessionsData: ICourseSessionsByMonth;
    clientSideFilteredCourseSessionsData: ICourseSessionsByMonth;
    courseSessionsAsyncInfo: IAsyncFieldInfo;
    filterValues: object;
    setSearchFilterUrlQueryParams: (filter: IFilterValues) => void;
}

interface IPublicProps {
    setRef?: (el: HTMLDivElement) => void;
}

interface IState {
    isFilterPanelOpen?: boolean;
    slideOutPanelElementToFocus: string;
}

const CLASS_NAME = 'CourseDetailSessions';
const TRANSLATION_PREFIX = 'document_center.courses.overview.detail.child.sessions';

class CourseDetailSessions extends Component<IPrivateProps & IPublicProps, IState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            isFilterPanelOpen: false,
            slideOutPanelElementToFocus: null,
        };

        this.onFilterPanelCloseIntent = this.onFilterPanelCloseIntent.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
    }

    public render() {
        const {
            isFilterPanelOpen, slideOutPanelElementToFocus,
        } = this.state;

        const {
            courseSessionsData, clientSideFilteredCourseSessionsData, filterValues,
            courseSessionsAsyncInfo, setRef,
        } = this.props;

        const headerConfig = {
            renderFilterContent:
                (renderProps: IRenderFilterContentProps<ICourseSessionsByMonth, IFilterValues>) =>
                    <FilterContent {...renderProps} />,
        };

        const noCourseSessionsFound = (
            courseSessionsAsyncInfo.status === AsyncStatus.Success
            || courseSessionsAsyncInfo.status === AsyncStatus.Error
        ) && courseSessionsData && Object.keys(courseSessionsData).length === 0;

        return (
            <div className={CLASS_NAME} ref={setRef}>
                <Loader show={courseSessionsAsyncInfo.status} />
                <div className="container">
                    <h2><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h2>
                    {!noCourseSessionsFound ? (
                        <>
                            <Header
                                baseName={BASE_NAME}
                                baseClassName={MASTER_WITH_DETAIL_CLASS_NAME}
                                masterAsyncInfoSelector={getCourseSessionsAsyncInfo}
                                headerConfig={headerConfig}
                                isFilterPanelOpen={isFilterPanelOpen}
                                slideOutPanelElementToFocus={slideOutPanelElementToFocus}
                                masterData={courseSessionsData}
                                clientSideFilteredListItems={clientSideFilteredCourseSessionsData}
                                onFilterButtonClick={this.onFilterButtonClick}
                                onFilterSubmit={this.onFilterSubmit}
                                onFilterPanelCloseIntent={this.onFilterPanelCloseIntent}
                                filterValues={filterValues}
                                untransformedMasterDataSelector={getCourseSessions}
                                filterValidationSchema={null}
                            />
                            <ActiveFilters
                                baseName={BASE_NAME}
                                baseClassName={MASTER_WITH_DETAIL_CLASS_NAME}
                                transformFilterValuesToActiveFilters={transformFilterValuesToActiveFilters}
                                masterData={courseSessionsData}
                                filterValues={filterValues}
                                onFilterSubmit={this.onFilterSubmit}
                                masterAsyncInfoSelector={getCourseSessionsAsyncInfo}
                            />
                            {Object.keys(clientSideFilteredCourseSessionsData).map((month, index) => {
                                const previousMonth = Object.keys(clientSideFilteredCourseSessionsData)[index - 1];
                                const showIndexInfoBox = currentMonthHasMoreRecentYear(month, previousMonth);

                                return (
                                    <Fragment key={month}>
                                        {showIndexInfoBox && (
                                            <IndexInfoBox
                                                titleTranslationKey={`${TRANSLATION_PREFIX}.index_info_box.title`}
                                                // eslint-disable-next-line max-len
                                                descriptionTranslationKey={`${TRANSLATION_PREFIX}.index_info_box.description`}
                                            />
                                        )}
                                        <CourseSessionByMonth
                                            title={month}
                                            data={clientSideFilteredCourseSessionsData[month]}
                                            noSessionsMessage={!isEmptyObject(filterValues)
                                                && `${TRANSLATION_PREFIX}.list.no_sessions_filtered`}
                                        />
                                    </Fragment>
                                );
                            })}
                        </>
                    ) : (
                        <p><Translate msg={`${TRANSLATION_PREFIX}.no_results`} /></p>
                    )}
                </div>
            </div>
        );
    }

    private onFilterSubmit(filter: IFilterValues) {
        const { setSearchFilterUrlQueryParams } = this.props;
        this.setState({
            isFilterPanelOpen: false,
        });
        setSearchFilterUrlQueryParams(filter);
    }

    private onFilterPanelCloseIntent() {
        this.setState({
            isFilterPanelOpen: false,
        });
    }

    private onFilterButtonClick(elementToFocus: string) {
        if (!this.state.isFilterPanelOpen) {
            this.setState({
                isFilterPanelOpen: true,
                slideOutPanelElementToFocus: elementToFocus,
            });
        }
    }
}

function FilterContent(renderProps: IRenderFilterContentProps<ICourseSessionsByMonth, IFilterValues>) {
    const {
        formRenderProps,
        masterData: sessionsByMonth,
    } = renderProps;

    const flattenedFirstdaySessions = toFlattenedFirstdaySessions(sessionsByMonth);

    const possibleProvinces = getUniqueTypeaheadFilterValuesFromCourses<ICourseSession>(
        flattenedFirstdaySessions,
        'province',
        'province',
    );

    const possibleLanguages = getUniqueTypeaheadFilterValuesFromCourses<ICourseSession>(
        flattenedFirstdaySessions,
        'language',
        'language',
    );

    return (
        <div className={`${CLASS_NAME}__filters`}>
            <CollapsibleCheckboxesFilter
                filterName="province"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.province`}
                possibleFilterItems={possibleProvinces}
                actualFilterValue={formRenderProps.values.province}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'province',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.province}
            />
            <CollapsibleCheckboxesFilter
                filterName="language"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.language`}
                possibleFilterItems={possibleLanguages}
                actualFilterValue={formRenderProps.values.language}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'language',
                    newFilterValue,
                )}
                initialOpen={!!formRenderProps.values.language}
            />
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.filter.period`}
                triggerTagName="h6"
                initialOpen={isSet(formRenderProps.values.startDate) || isSet(formRenderProps.values.endDate)}
            >
                <StartEndDateFilter
                    translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                    formRenderProps={formRenderProps}
                    hideTitle
                />
            </CollapsibleItem>
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps,
) {
    return createGenericActiveFilters<IFilterValues, {}>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        filters: {
            province: {
                show: true,
            },
            language: {
                show: true,
            },
            endDate: {
                show: true,
            },
            startDate: {
                show: true,
            },
        },
    });
}

function currentMonthHasMoreRecentYear(currentMonth: string, previousMonth: string) {
    if (!currentMonth || !previousMonth) {
        return false;
    }

    const yearRegex = /.*(\d\d)$/;
    const currentMonthMatches = currentMonth.match(yearRegex);
    const previousMonthMatches = previousMonth.match(yearRegex);

    if (
        !currentMonthMatches
        || !previousMonthMatches
        || currentMonthMatches.length < 2
        || previousMonthMatches.length < 2
    ) {
        return false;
    }

    const currentMonthYear = parseInt(currentMonthMatches[1], 10);
    const previousMonthYear = parseInt(previousMonthMatches[1], 10);

    return currentMonthYear > previousMonthYear;
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state) => {
        const clientSideFilteredCoursesSessionsMemoizedSelector = createSelector(
            getCourseSessions,
            getQueryParams,
            (coursesByMonth, filterValues) => clientSideFilter(
                coursesByMonth,
                filterValues as IFilterValues,
            ),
        );
        return (state) => {
            return {
                courseSessionsData: getCourseSessions(state),
                courseSessionsAsyncInfo: getCourseSessionsAsyncInfo(state),
                clientSideFilteredCourseSessionsData: clientSideFilteredCoursesSessionsMemoizedSelector(state),
                filterValues: getQueryParams(state),
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        const state = getState();
        const routePayload = getRoutePayload(state);

        return {
            setSearchFilterUrlQueryParams: (filter: IFilterValues) => {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_COURSE, { ...routePayload }, { ...filter }));
            },
        };
    },
})(CourseDetailSessions);
