import { createTypeActions } from '../../utils/libs/redux/createAction';
import {
    FETCH_QUESTIONNAIRES_SCREENWORK,
    FETCH_QUESTIONNAIRES_JOB_STUDENTS,
    FETCH_QUESTIONNAIRE_ADVICE,
    SEND_JOB_STUDENT_REMINDER_EMAIL,
    TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
} from './types';
import {
    IJobStudentQuestionnaire,
    IScreenworkQuestionnaire,
    IQuestionnaireAdvice,
    IFetchQuestionnaireAdvicePayload,
    ISendJobStudentReminderEmailPayload,
    ITriggerPlanJobStudentMedExamPayload,
} from '../../models/admin/questionnaires';

export const fetchQuestionnairesScreenworkActions = createTypeActions<{}, IScreenworkQuestionnaire[]>({
    type: FETCH_QUESTIONNAIRES_SCREENWORK,
});

export const fetchQuestionnairesJobStudentsActions = createTypeActions<{}, IJobStudentQuestionnaire[]>({
    type: FETCH_QUESTIONNAIRES_JOB_STUDENTS,
});

export const fetchQuestionnaireAdviceActions =
    createTypeActions<IFetchQuestionnaireAdvicePayload, IQuestionnaireAdvice[]>({
        type: FETCH_QUESTIONNAIRE_ADVICE,
    });

export const sendJobStudentReminderEmailActions = createTypeActions<ISendJobStudentReminderEmailPayload[]>({
    type: SEND_JOB_STUDENT_REMINDER_EMAIL,
});

export const triggerPlanJobStudentMedExamActions = createTypeActions<ITriggerPlanJobStudentMedExamPayload>({
    type: TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
});
