import React from 'react';
import './drag-and-drop-wrapper.scss';
import { DraggableId, DroppableId, DroppableStateSnapshot, DraggableStateSnapshot } from 'react-beautiful-dnd';
import DroppableWrapper from '../DroppableWrapper';
import DraggableWrapper from '../DraggableWrapper';

const CLASS_NAME = 'DragAndDropWrapper';

interface IPublicProps {
    droppableId: DroppableId;
    draggableId: DraggableId;
    render: (renderProps: IDragAndDropRenderProps | DroppableStateSnapshot) => React.ReactNode;
    disableMove?: boolean;
    isDragDisabled?: boolean;
    isNotDraggable?: boolean;
}

export interface IDragAndDropRenderProps {
    dropState: DroppableStateSnapshot;
    dragState: DraggableStateSnapshot;
}

export default function DragAndDropWrapper({
    droppableId,
    draggableId,
    render,
    disableMove,
    isDragDisabled,
    isNotDraggable,
}: IPublicProps) {
    return (
        <DroppableWrapper
            droppableId={droppableId}
            className={`${CLASS_NAME}__drop-wrapper`}
            disableMove={disableMove}
            render={({ dropState }) => (
                isNotDraggable ? render(dropState) : (
                    <DraggableWrapper
                        draggableId={draggableId}
                        index={0}
                        disableMove={disableMove}
                        isDragDisabled={isDragDisabled}
                        className={`${CLASS_NAME}__drag-wrapper`}
                        render={({ dragState }) => {
                            return render({
                                dropState,
                                dragState,
                            });
                        }}
                    />
                )
            )}
        />
    );
}
