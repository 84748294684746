import {
    NotificationType,
    INotification,
    IErrorNotification,
    ICustomNotification,
    NOTIFICATION_ID,
} from '../../models/general/notification';
import { createAction } from '../../utils/libs/redux/createAction';
import { ADD_NOTIFICATION, DISMISS_NOTIFICATIONS } from './types';
import { ITraceableApiError } from '../../models/general/error';

export function triggerErrorNotification(error: ITraceableApiError) {
    return createAction<IErrorNotification>(ADD_NOTIFICATION, {
        id: error.id,
        type: NotificationType.Error,
        payload: error,
    });
}

export function triggerNewVersionNotification() {
    return createAction<INotification>(ADD_NOTIFICATION, {
        id: NOTIFICATION_ID.NEW_VERSION,
        type: NotificationType.NewVersion,
    });
}

export function triggerCustomNotification({
    // you can specify a specific id (because there can be only one notification per unique id)
    id = NOTIFICATION_ID.CUSTOM,
    titleTranslationKey,
    messageTranslationKey,
    autoHide = false,
}: {
    id?: string;
    titleTranslationKey: string;
    messageTranslationKey: string;
    autoHide?: boolean;
}) {
    return createAction<ICustomNotification>(ADD_NOTIFICATION, {
        id,
        type: NotificationType.Custom,
        payload: {
            titleTranslationKey,
            messageTranslationKey,
            autoHide,
        },
    });
}

export function dismissNotifications(notificationIds: string[]) {
    return createAction<{ notificationIds: string[] }>(DISMISS_NOTIFICATIONS, { notificationIds });
}
