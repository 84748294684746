import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import ROUTE_KEYS from '../../routeKeys';
import Agenda from './Agenda';

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_AGENDA]: {
        path: '/agenda/:viewType',
        page: {
            key: 'AGENDA',
            component: Agenda,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'agenda',
        requiredAccessLevels: [{ planning: 'R' }],
        breadcrumbsLabel: {
            translationKey: 'planning.agenda.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_AGENDA_EVENT_DETAILS]: {
        path: '/agenda/:viewType/details/:eventId',
        page: {
            key: 'AGENDA_EVENT_DETAILS',
            component: Agenda,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'agenda_event_details',
        requiredAccessLevels: [{ planning: 'R' }],
        breadcrumbsLabel: {
            translationKey: 'planning.agenda.appointment_detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_AGENDA_SEARCH]: {
        path: '/agenda/:viewType/search',
        page: {
            key: 'AGENDA_SEARCH',
            component: Agenda,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'agenda_search',
        requiredAccessLevels: [{ planning: 'R' }],
        breadcrumbsLabel: {
            translationKey: 'planning.agenda.search.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS]: {
        path: '/agenda/:viewType/search/details/:eventId',
        page: {
            key: 'AGENDA_SEARCH_EVENT_DETAILS',
            component: Agenda,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'agenda_search_event_details',
        requiredAccessLevels: [{ planning: 'R' }],
        breadcrumbsLabel: {
            translationKey: 'planning.agenda.appointment_detail.breadcrumbs_label',
        },
    },
};

export default ROUTES;
