import React, { PureComponent } from 'react';
import OverlayContent from '../../common/modals/OverlayContent';
import { navigateTo } from '../../../redux/location/actions';
import { connect } from '../../index';
import ROUTE_KEYS from '../../../routeKeys';
import Translate from '../../common/Translate';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import { IInterventionItem } from '../../../models/general/navigation';
import { getInterventionPlanItems } from '../../../config/navigation/interventions.config';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Tile from '../../common/widget/Tile';
import { getLocationState } from '../../../redux/location/selectors';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

let allowedInterventionPlanItemsMemoizedSelector = null;

function getAllowedInterventionPlanItemsMemoizedSelector() {
    if (!allowedInterventionPlanItemsMemoizedSelector) {
        allowedInterventionPlanItemsMemoizedSelector = makeAllowedNavigationItemsMemoizedSelector<IInterventionItem>(
            getInterventionPlanItems(),
        );
    }

    return allowedInterventionPlanItemsMemoizedSelector;
}

interface IPrivateProps {
    allowedInterventionPlanItems: IInterventionItem[];
    goBack: () => void;
}

class PlanIntervention extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);

        this.onCloseOverlay = this.onCloseOverlay.bind(this);
    }

    public render() {
        return (
            <OverlayContent
                show={true}
                onCloseIntent={this.onCloseOverlay}
            >
                {this.renderOverlayContent()}
            </OverlayContent>
        );
    }

    private onCloseOverlay() {
        this.props.goBack();
    }

    private renderOverlayContent() {
        const {
            allowedInterventionPlanItems,
        } = this.props;

        return (
            <div className="tile-dashboard">
                <h1>
                    <Translate msg="interventions.plan_intervention.title" />
                </h1>
                <div className="container">
                    <div className="sections-wrapper">
                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems
                                    allowedInterventionPlanItems={allowedInterventionPlanItems}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function NavigationItems({ allowedInterventionPlanItems }: Partial<IPrivateProps>) {
    return (
        <>
        {
            allowedInterventionPlanItems
                .map((item) => {
                    const linkToPayloadTypedAsFunction = item.linkToPayload as (() => object);
                    const to = item.linkToPayload ? {
                        type: ROUTE_KEYS[item.linkTo],
                        payload: typeof item.linkToPayload === 'function' ?
                            linkToPayloadTypedAsFunction() : item.linkToPayload,
                    } : item.linkTo;

                    return (
                        <div className="tile-wrapper" key={item.id}>
                            <LinkToRoute
                                id={item.id}
                                to={to}
                            >
                                <Tile
                                    title={item.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: item.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.INTERVENTIONS,
                                    }}
                                    typeName="white"
                                />
                            </LinkToRoute>
                        </div>
                    );
                })
        }
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            allowedInterventionPlanItems: getAllowedInterventionPlanItemsMemoizedSelector()(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            goBack: () => {
                const state = getState();
                const { prev } = getLocationState(state);
                if (prev) {
                    dispatch(navigateTo(prev.type, prev.payload, prev.query));
                } else {
                    dispatch(navigateTo(ROUTE_KEYS.R_INTERVENTIONS));
                }
            },
        };
    },
})(PlanIntervention);
