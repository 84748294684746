import { object, boolean, array } from 'yup';
import string from '../../../../../../utils/libs/yup/string';

export const fields = {
    notifyValues: 'notifyValues',
};

export const subfields = {
    email: 'email',
    notifyByMail: 'notifyByMail',
    id: 'id',
};

export const schema = object().shape({
    [fields.notifyValues]: array().of(object().shape({
        [subfields.id]: string(),
        [subfields.email]: string().when(subfields.notifyByMail, {
            is: true,
            then: string().email().required(),
            otherwise: string(),
        }),
        [subfields.notifyByMail]: boolean(),
    })),
});
