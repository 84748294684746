import { get, post } from '../../utils/api/requestWrapper';
import { DEFAULT_TASK_IDS } from '../../config/navigation/tasks.config';
import { IUpdateTopTasksPayload } from '../../models/user/topTasks';

export const URL = {
    TOP_TASKS: '/users/me/toptasks',
};

export function fetchTopTasks() {
    return get<string[]>({
        url: URL.TOP_TASKS,
        mapResponse: (data) => {
            const topTaskIds = data.topTasks.map((task) => task.id);
            if (topTaskIds.length === 0) {
                return DEFAULT_TASK_IDS;
            }
            return topTaskIds;
        },
    });
}

export function updateTopTasks(payload: IUpdateTopTasksPayload) {
    return post({
        url: URL.TOP_TASKS,
        body: payload,
    });
}
