import { isBrowserTabActive, onBrowserTabVisibilityChange } from './index';

const NO_INTERVAL_ID = null;

interface IExecutePeriodicallyConfig {
    toBeExecuted: () => void;
    intervalInMillis: number;
    executeImmediatelyInActiveTab?: boolean; // default false
}

export default function executePeriodicallyWhenBrowserTabActive(config: IExecutePeriodicallyConfig) {
    let intervalId = executeWhenBrowserTabActive(config);

    onBrowserTabVisibilityChange({
        onActivatedHandler: () => {
            clearIntervalIfSet(intervalId);
            intervalId = executeWhenBrowserTabActive(config);
        },
        onDeactivatedHandler: () => clearIntervalIfSet(intervalId),
    });

    function clearIntervalIfSet(intervalId: number) {
        if (intervalId !== NO_INTERVAL_ID) {
            window.clearInterval(intervalId);
            // tslint:disable-next-line:no-parameter-reassignment
            intervalId = NO_INTERVAL_ID;
        }
    }
}

function executeWhenBrowserTabActive({
    toBeExecuted,
    intervalInMillis,
    executeImmediatelyInActiveTab = false,
}: IExecutePeriodicallyConfig): number {
    if (isBrowserTabActive()) {
        if (executeImmediatelyInActiveTab) {
            toBeExecuted();
        }

        return window.setInterval(toBeExecuted, intervalInMillis);
    }

    return NO_INTERVAL_ID;
}
