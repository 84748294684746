import { areObjectParamsEqual } from '../../../../../utils/core/object/diffObjects';
import {
    DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS,
} from '../../../../../api/interventions/companyVisits/executed/executed.const';
import {
    IFetchExecutedCompanyVisitsPayload,
    IExecutedCompanyVisitsFilter,
} from '../../../../../models/interventions/company-visits';
import ROUTE_KEYS from '../../../../../routeKeys';
import { createEpic } from '../../../../index';
import { getLocationState, getQueryParams } from '../../../../location/selectors';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../../selected/selectors';
import { areExecutedCompanyVisitsAvailable } from '../../selectors';
import { fetchExecutedCompanyVisitsFailed, fetchExecutedCompanyVisitsSucceeded } from '../../actions';

// fetchExecutedCompanyVisitsEpic
createEpic<IFetchExecutedCompanyVisitsPayload[]>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
    refreshDataIf: ({ getState, action }) => {
        if (!areExecutedCompanyVisitsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IExecutedCompanyVisitsFilter;

            const result = await api.interventions.companyVisits.fetchExecutedCompanyVisits(
                { companyCode, showFullFamily }, filterFromQuery,
            );
            return fetchExecutedCompanyVisitsSucceeded(result);
        } catch (error) {
            return fetchExecutedCompanyVisitsFailed(error);
        }
    },
    latest: false,
});
