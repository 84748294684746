import React from 'react';
import './holidays.scss';
import classNames from 'classnames';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import PageHeader from '../../../../appShell/PageHeader';
import StickyFooter from '../../../../common/widget/StickyFooter';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import Checkbox from '../../../../common/input/Checkbox';
import Translate from '../../../../common/Translate';
import { connect } from '../../../..';
import {
    getUpdateCompanyHolidaysAsyncInfo, getCompanyHolidays,
} from '../../../../../redux/company/info/selectors';
import { IUpdateCompanyHolidaysPayload } from '../../../../../models/admin/companyInfo';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import Holidays from '../../../../administration/CompanyInfo/shared/Holidays';
import { updateCompanyHolidaysActions } from '../../../../../redux/company/info/actions';
import { getSelectedCompanyCode } from '../../../../../redux/company/selected/selectors';
import { updateOnboardingWizardEntity } from '../../../../../redux/onboarding/actions';
import { getOnboardingWizardEntity } from '../../../../../redux/onboarding/selectors';
import Loader from '../../../../common/waiting/Loader';

const CLASS_NAME = 'OnboardingHolidays';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.holidays';

interface IPrivateProps {
    updateCompanyHolidays: (updateHolidaysPayload: Pick<IUpdateCompanyHolidaysPayload, 'holidays'>) => void;
    updateAsyncInfo: IAsyncFieldInfo;
    updateEntityNoFixedHolidaysChecked: (noFixedHolidaysChecked: boolean) => void;
    noFixedHolidaysInitialChecked: boolean;
    noHolidays: boolean;
}
interface IState {
    isNoHolidaysChecked: boolean;
}

class HolidaysStep extends React.Component<IStepperStepRenderProps & IPrivateProps, IState> {
    public constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isNoHolidaysChecked: this.props.noFixedHolidaysInitialChecked,
        };

        this.onClickNext = this.onClickNext.bind(this);
    }

    public componentDidUpdate(prevProps: IStepperStepRenderProps & IPrivateProps) {
        if (
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateAsyncInfo.status === AsyncStatus.Success &&
            this.state.isNoHolidaysChecked
        ) {
            this.props.goToNextStep();
        }
    }

    public render() {
        const { renderStepButtons, updateAsyncInfo, noHolidays } = this.props;
        const { isNoHolidaysChecked } = this.state;
        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    {isNoHolidaysChecked && <Loader show={updateAsyncInfo.status} />}
                    <Checkbox
                        name="holidays-no-fixed-holidays"
                        className={`${CLASS_NAME}__no_fixed_holidays_checkbox`}
                        checked={isNoHolidaysChecked}
                        onChange={(e) => this.setState({ isNoHolidaysChecked: e.target.checked })}
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.no_fixed_holidays`} />
                    </Checkbox>

                    {!isNoHolidaysChecked && (
                        <Holidays />
                    )}
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                onClick: this.onClickNext,
                                disabled: !isNoHolidaysChecked && noHolidays,
                            },
                        })}
                    </StickyFooter>
                </div>
            </>
        );
    }

    private onClickNext() {
        const { updateCompanyHolidays, goToNextStep, updateEntityNoFixedHolidaysChecked } = this.props;
        const { isNoHolidaysChecked } = this.state;

        if (isNoHolidaysChecked) {
            updateEntityNoFixedHolidaysChecked(true);
            updateCompanyHolidays({ holidays: [] });
        } else {
            updateEntityNoFixedHolidaysChecked(false);
            goToNextStep();
        }
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getOnboardingWizardEntity(state);
        return {
            updateAsyncInfo: getUpdateCompanyHolidaysAsyncInfo(state),
            noFixedHolidaysInitialChecked: entity.noFixedHolidaysChecked,
            noHolidays: getCompanyHolidays(state).length === 0,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateEntityNoFixedHolidaysChecked: (noFixedHolidaysChecked: boolean) => {
                dispatch(updateOnboardingWizardEntity({
                    noFixedHolidaysChecked,
                }));
            },
            updateCompanyHolidays: (updateHolidaysPayload: Pick<IUpdateCompanyHolidaysPayload, 'holidays'>) => {
                dispatch(updateCompanyHolidaysActions.trigger({
                    companyCode: getSelectedCompanyCode(getState()),
                    holidays: updateHolidaysPayload.holidays,
                    doNotRefreshAfterUpdate: true,
                }));
            },
        };
    },
})(HolidaysStep);
