export const FETCH_RISKS = 'FETCH_RISKS';

export const FETCH_COMPANY_COST_CENTERS = 'FETCH_COMPANY_COST_CENTERS';
export const FETCH_COMPANY_MEDICAL_CENTERS = 'FETCH_COMPANY_MEDICAL_CENTERS';
export const FETCH_COMPANY_ADDRESSES = 'FETCH_COMPANY_ADDRESSES';

export const FETCH_COMPANY_DETAIL = 'FETCH_COMPANY_DETAIL';
export const FETCH_COMPANY_SEAT_DETAIL = 'FETCH_COMPANY_SEAT_DETAIL';
export const FETCH_SMALL_COMPANY_DETAIL = 'FETCH_SMALL_COMPANY_DETAIL';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SINGLE_FIELD = 'UPDATE_COMPANY_SINGLE_FIELD';
export const ADD_COMPANY_SEAT = 'ADD_COMPANY_SEAT';

export const FETCH_COMPANY_AVAILABILITIES_MED_EXAMS = 'FETCH_COMPANY_AVAILABILITIES_MED_EXAMS';
export const FETCH_COMPANY_AVAILABILITIES_RISK_MGMT = 'FETCH_COMPANY_AVAILABILITIES_RISK_MGMT';
export const REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS = 'REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS';
export const REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT = 'REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT';

export const FETCH_COMPANY_HOLIDAYS = 'FETCH_COMPANY_HOLIDAYS';
export const UPDATE_COMPANY_HOLIDAYS = 'UPDATE_COMPANY_HOLIDAYS';

export const FETCH_COMPANY_INTERNAL_CONTACTS = 'FETCH_COMPANY_INTERNAL_CONTACTS';
export const FETCH_COMPANY_SEAT_INTERNAL_CONTACTS = 'FETCH_COMPANY_SEAT_INTERNAL_CONTACTS';
export const FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT = 'FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';

export const FETCH_COMPANY_RATES = 'FETCH_COMPANY_RATES';
