import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { ICompanyDismissableWithDate } from '../../../models/admin/company';
import Icon from '../../common/icons/Icon';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import Translate from '../../common/Translate';
import { isInThePast } from '../../../utils/core/date/isInThePast';
import { formatDateForDisplay } from '../../../utils/formatting/formatDate';

const warningIcon = (
    <Icon circle typeName="warning" />
);

enum CompanyCondition {
    default = 'default',
    dismissalInTheFuture = 'dismissalInTheFuture',
    dismissalInThePast = 'dismissalInThePast',
    dismissedWithoutDate = 'dismissedWithoutDate',
}

interface IPublicProps {
    company: ICompanyDismissableWithDate;
}

export default function CompanyConditionTooltip({ company }: IPublicProps) {
    const condition = getCompanyCondition(company);

    if (condition === CompanyCondition.default) {
        return <span />;
    }

    return (
        <TooltipWithIcon icon={warningIcon} tooltipBubbleIcon={warningIcon} typeName="info-bubble">
            <div>
                {(condition === CompanyCondition.dismissalInTheFuture) &&
                    <div><Translate
                        msg={'account.company_selection.conditions.dismissal_in_the_future'}
                        placeholders={getDismissalDatePlaceholders()}
                    /></div>
                }
                {(condition === CompanyCondition.dismissalInThePast) &&
                    <div><Translate
                        msg={'account.company_selection.conditions.dismissal_in_the_past'}
                        placeholders={getDismissalDatePlaceholders()}
                    /></div>
                }
                {(condition === CompanyCondition.dismissedWithoutDate) &&
                    <div><Translate
                        msg={'account.company_selection.conditions.dismissed_company'}
                    /></div>
                }
            </div>
        </TooltipWithIcon>
    );

    function getDismissalDatePlaceholders() {
        return {
            dismissal_date: formatDateForDisplay(company.dismissalDate),
        };
    }
}

function getCompanyCondition(company: ICompanyDismissableWithDate): CompanyCondition {
    if (!company) {
        return CompanyCondition.default;
    }

    if (company.dismissed) {
        if (isSet(company.dismissalDate) && isInThePast(company.dismissalDate)) {
            return CompanyCondition.dismissalInThePast;
        }

        return CompanyCondition.dismissedWithoutDate;
    }

    if (isSet(company.dismissalDate) && !isInThePast(company.dismissalDate)) {
        return CompanyCondition.dismissalInTheFuture;
    }

    return CompanyCondition.default;
}
