import React, { ReactNode } from 'react';
import './message-center.scss';
import PageHeader from '../../appShell/PageHeader';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import messagesIllu from '../../assets/img/icons/envelope.svg';

interface IPublicProps {
    children: ReactNode;
}

export default function MessageCenterTemplate(props: IPublicProps) {
    const {
        children,
    } = props;

    return (
        <WithMenuTemplate>
            <div className="MessageCenterTemplate">
                <PageHeader
                    breadcrumbs={true}
                    title="account.message_center.title"
                    type="white"
                    titleIcon={messagesIllu}
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
