import isEmptyString from '../string/isEmptyString';
import isSet from '@snipsonian/core/es/is/isSet';
import isString from '@snipsonian/core/es/is/isString';
import { IKeyValuePair } from '../typings';

export function removeEmptyPropsFromObject<T = {}>(obj: object): T {
    const newObj = { ...obj };
    for (const propName in newObj) {
        if (newObj[propName] === null || newObj[propName] === undefined || isEmptyString(newObj[propName])) {
            delete newObj[propName];
        }
    }
    return newObj as T;
}

export function removePropFromObject<T = {}>(obj: object, key: string): T {
    const newObj = { ...obj };
    delete newObj[key];
    return newObj as T;
}

export function getDefinedObjectProps<Keys extends string>(obj: object): IKeyValuePair<Keys>[] {
    return getObjectPropsAsList<Keys>(obj)
        .filter((keyValuePair) => {
            if (!isSet(keyValuePair.value)) {
                return false;
            }

            return isString(keyValuePair.value) ? keyValuePair.value.trim().length > 0 : true;
        });
}

export function getObjectPropsAsList<Keys extends string>(obj: object): IKeyValuePair<Keys>[] {
    return Object.keys(obj)
        .map((key) => ({
            key: key as Keys,
            value: obj[key],
        }));
}

export function hasProps(obj: object): boolean {
    if (!obj) {
        return false;
    }
    return Object.keys(obj).length > 0;
}
