import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';

import { MiniAgendaContent } from './MiniAgendaContent/MiniAgendaContent.component';
import { MiniAgendaHeader } from './MiniAgendaHeader/MiniAgendaHeader.component';
import { UpcomingActivityBlock } from './UpcomingActivityBlock/UpcomingActivityBlock.component';
import { TMiniAgendaProps } from './MiniAgenda.type';

const MiniAgenda = ({ upcomingActivity }: TMiniAgendaProps) => {
    const hasUpcomingActivity = !!upcomingActivity && isSet(upcomingActivity.count) && upcomingActivity.count > 0;

    return (
        <>
            <MiniAgendaHeader />
            <MiniAgendaContent hasUpcomingActivity={hasUpcomingActivity}>
                <UpcomingActivityBlock upcomingActivity={upcomingActivity} />
            </MiniAgendaContent>
        </>
    );
};

export { MiniAgenda };
