const MILLIS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;

// dateA: end date
// dateB: start date
export function getMinutesBetweenDates(dateA: Date, dateB: Date) {
    return Math.round(
        (dateA.getTime() - dateB.getTime()) / MILLIS_PER_SECOND / SECONDS_PER_MINUTE,
    );
}
