import React, { PureComponent, ReactNode, ReactElement } from 'react';
import List from '../List';
import { ListColumns, ListItem, ISortedColumn, SortOrder, ListItemId, IColumn } from '../../../../models/general/list';
import sortListItems from '../../../../utils/list/sortListItems';
import { IListFooterProps } from '../ListFooter';

export type TItemsSorter<ColumnNames> = (
    items: ListItem<ColumnNames>[],
    sortedColumn: ISortedColumn<ColumnNames>,
    columnConfig: IColumn<ColumnNames>,
) => ListItem<ColumnNames>[];

export interface IListWithSortingProps<ColumnNames> {
    name: string;
    columns: ListColumns<ColumnNames>;
    items: ListItem<ColumnNames>[];
    initialSort?: ISortedColumn<ColumnNames>;
    selectedItemIds?: (string | number)[];
    onItemRowClicked?: (id: string | number) => void;
    getCustomRowClasses?: (item: ListItem<ColumnNames>) => string;
    getTableCellClasses?: (columnKey: string, listItem: ListItem<ColumnNames>) => string;
    noResultsMessage?: ReactNode;
    errorMessage?: ReactNode;
    fadeOutListItems?: boolean;
    maxNrOfRecordsToShow?: number;
    footer?: ReactElement<IListFooterProps>;
    idToScrollIntoView?: ListItemId;
    customSorter?: TItemsSorter<ColumnNames>;
    hideHeaders?: boolean;
    selectedColumnName?: string;
    onColumnClicked?: (columnName: string) => void;
    onColumnSortChanged?: (nextSortedColumn: ISortedColumn<ColumnNames>) => void;
    itemSizeGetter?: (index: number) => number;
    customTableContentRenderer?: (listItems: ListItem<ColumnNames>[]) => React.ReactNode;
    isItemClickable?: (item: ListItem<ColumnNames>) => boolean; // only has effect if 'onItemRowClicked' provided
}

interface IState<ColumnNames> {
    sortedItems: ListItem<ColumnNames>[];
    sortedColumn: ISortedColumn<ColumnNames>;
}

class ListWithSorting<ColumnNames> extends PureComponent<IListWithSortingProps<ColumnNames>, IState<ColumnNames>> {

    constructor(props: IListWithSortingProps<ColumnNames>) {
        super(props);

        const initialSort = getColumnToUseForInitialSorting(props.initialSort, props.columns);

        const itemSorter = props.customSorter || sortListItems;

        this.state = {
            sortedItems: itemSorter(props.items, initialSort, props.columns[initialSort.name]),
            sortedColumn: initialSort,
        };

        this.onColumnSortChanged = this.onColumnSortChanged.bind(this);
    }

    public render() {
        const {
            name, selectedItemIds,
            columns, getCustomRowClasses, getTableCellClasses,
            onItemRowClicked, noResultsMessage,
            errorMessage, fadeOutListItems,
            maxNrOfRecordsToShow, footer,
            idToScrollIntoView, hideHeaders,
            selectedColumnName, onColumnClicked,
            itemSizeGetter, customTableContentRenderer,
            isItemClickable,
        } = this.props;
        const { sortedColumn, sortedItems } = this.state;

        return (
            <List
                name={name}
                columns={columns}
                items={sortedItems}
                sortedColumn={sortedColumn}
                onItemRowClicked={onItemRowClicked}
                selectedItemIds={selectedItemIds || []}
                onColumnSortChanged={this.onColumnSortChanged}
                getCustomRowClasses={getCustomRowClasses}
                getTableCellClasses={getTableCellClasses}
                noResultsMessage={noResultsMessage}
                errorMessage={errorMessage}
                fadeOutListItems={fadeOutListItems}
                maxNrOfRecordsToShow={maxNrOfRecordsToShow}
                footer={footer}
                idToScrollIntoView={idToScrollIntoView}
                hideHeaders={hideHeaders}
                selectedColumnName={selectedColumnName}
                onColumnClicked={onColumnClicked}
                itemSizeGetter={itemSizeGetter}
                customTableContentRenderer={customTableContentRenderer}
                isItemClickable={isItemClickable}
            />
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IListWithSortingProps<ColumnNames>) {
        if (nextProps.items !== this.props.items) {
            const sortedColumn = isKnownColumn(this.state.sortedColumn, nextProps.columns)
                ? this.state.sortedColumn
                : getColumnToUseForInitialSorting(nextProps.initialSort, nextProps.columns);
            this.sortItems(nextProps, sortedColumn);
        }
    }

    private onColumnSortChanged(nextSortedColumn: ISortedColumn<ColumnNames>) {
        this.sortItems(this.props, nextSortedColumn);

        if (this.props.onColumnSortChanged) {
            this.props.onColumnSortChanged(nextSortedColumn);
        }
    }

    private sortItems(props: IListWithSortingProps<ColumnNames>, sortedColumn: ISortedColumn<ColumnNames>) {
        const { columns, items } = props;
        const columnConfig = columns[sortedColumn.name];
        const itemSorter = props.customSorter || sortListItems;

        this.setState({
            sortedItems: itemSorter(items, sortedColumn, columnConfig),
            sortedColumn,
        });
    }
}

export default ListWithSorting;

function getColumnToUseForInitialSorting<ColumnNames>(
    initialSort: ISortedColumn<ColumnNames>,
    columns: ListColumns<ColumnNames>,
) {
    if (initialSort) {
        return initialSort;
    }

    const columnNames = Object.keys(columns);
    return (columnNames.length > 0 && {
        name: columnNames[0],
        sortOrder: SortOrder.Ascending,
    } as ISortedColumn<ColumnNames> || null);
}

function isKnownColumn<ColumnNames>(
    column: ISortedColumn<ColumnNames>,
    columns: ListColumns<ColumnNames>,
) {
    const columnNames = Object.keys(columns);
    return columnNames.includes(column.name as string);
}
