import React from 'react';
import './message-body.scss';
import HtmlReactParser from 'html-react-parser';
import {
    IUserMessageDetails,
    TUserMessageDetailExtraParam,
} from '../../../../../models/user/inbox';
import parseMessageBody from './parseMessageBody';
import UserMessageDetailAction from './UserMessageDetailAction';
import { isArrayOfIUserMessageDetailAction } from './messageBodyUtils';

const CLASS_NAME = 'MessageBody';
const P_TAGS_TO_REPLACE = [
    { from: '<p>', to: '<div class="paragraph">' },
    { from: '</p>', to: '</div>' },
];

interface IPublicProps {
    messageDetail: IUserMessageDetails;
}

export default function MessageBody(props: IPublicProps) {
    const { messageDetail } = props;

    return (
        <div className={CLASS_NAME}>
            {insertDynamicBodyPartsIntoBodyHtml(messageDetail)}
        </div>
    );
}

/**
 * We can't use 'dangerouslySetInnerHTML' because we want to put real React components
 * (UserMessageDetailAction) inside an html string that has to be parsed.
 * p.s. 'dangerouslySetInnerHTML' will also - at least currently - always wrap the input html
 * with a wrap element like a div. (so also not usable to parse individual body parts)
 *
 * Therefore we use 'html-react-parser' of which we will use the replace functionality to
 * 'insert' the UserMessageDetailAction components within the html body string.
 */
function insertDynamicBodyPartsIntoBodyHtml(messageDetail: IUserMessageDetails) {
    if (!messageDetail.body) {
        return null;
    }

    const bodyParts = parseMessageBody(
        replaceParagraphsWithDivs(messageDetail.body),
    );

    const dynamicBodyPartComponentMap = {};

    const bodyHtmlToBeParsed = bodyParts
        .map((bodyPart, index) => {
            if (bodyPart.extraRef) {
                const replaceId = `replace-body-part-${index}-${bodyPart.extraRef}`;

                dynamicBodyPartComponentMap[replaceId] = (
                    <DynamicBodyPart
                        content={bodyPart.content}
                        oneOrMoreActions={messageDetail.extra[bodyPart.extraRef]}
                    />
                );

                // will be replaced with the real component via the 'replace' parser option
                return `<span id="${replaceId}"></span>`;
            }

            return bodyPart.content;
        })
        .join('');

    return HtmlReactParser(
        bodyHtmlToBeParsed,
        {
            // The element is replaced only if a valid React element is returned.
            replace: (domNode) => {
                if (domNode.attribs && domNode.attribs.id) {
                    const dynamicComponent = dynamicBodyPartComponentMap[domNode.attribs.id];
                    if (dynamicComponent) {
                        return dynamicComponent;
                    }
                }

                return undefined;
            },
        },
    );
}

function DynamicBodyPart({ content, oneOrMoreActions }: {
    content: string;
    oneOrMoreActions: TUserMessageDetailExtraParam;
}) {
    if (!oneOrMoreActions) {
        return null;
    }

    if (isArrayOfIUserMessageDetailAction(oneOrMoreActions)) {
        if (oneOrMoreActions.length === 0) {
            return null;
        }

        return (
            <ul className={`${CLASS_NAME}__actions`}>
                {oneOrMoreActions.map((action) => {
                    return (
                        <li key={`${action.actionType}-${action.id}`}>
                            <UserMessageDetailAction
                                content={content}
                                action={action}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <UserMessageDetailAction
            content={content}
            action={oneOrMoreActions}
        />
    );
}

/**
 * The input body html contains <p> tags (automatically generated by the WYSIWYG editor in the CMS) for each
 * paragraph body part, but as we need to be able to dynamically insert e.g. <div>'s or <ul>'s inside those paragraph's
 * (which is not allowd in <p>'s), we here replace the <p> tags with styled divs.
 */
function replaceParagraphsWithDivs(html: string) {
    return P_TAGS_TO_REPLACE
        .reduce(
            (replacedHtmlAccumulator, tagToReplace) => {
                return replacedHtmlAccumulator.replace(
                    new RegExp(`${tagToReplace.from}`, 'ig'),
                    tagToReplace.to,
                );
            },
            html,
        );
}
