import { object } from 'yup';
import string from '../../../utils/libs/yup/string';

export const fields = {
    feedback: 'feedback',
    type: 'type',
};

export const schema = object({
    [fields.feedback]: string().required(),
    [fields.type]: string().required(),
});
