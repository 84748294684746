import React, { ReactElement, ReactNode } from 'react';
import './step.scss';
import { IPublicProps as ITranslateProps } from '../../Translate';
import Icon from '../../icons/Icon';
import { IExtendedStepProps } from '../Stepper';
import classNames from 'classnames';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';

export interface IStepProps {
    id: string;
    label: string | ReactElement<ITranslateProps>;
    children?: (renderProps: IStepperStepRenderProps) => ReactNode;
    disableOnClick?: boolean;
}

function Step(props: IStepProps) {
    const {
        label,
        active,
        completed,
        onClick,
        disableOnClick,
    } = props as IExtendedStepProps;

    const classes = classNames('Step', {
        active,
        completed,
        disabled: !!disableOnClick,
    });

    return (
        <div className={classes} onClick={disableOnClick ? undefined : onClick}>
            <span className="label">{label}</span>
            <span className="status"><Icon typeName="check" /></span>
        </div>
    );
}

export default Step;
