import React, { Component } from 'react';
import { connect } from '../../../index';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../models/general/i18n';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import { getWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/selectors';
import {
    IWorkPostCardWizardEntity,
    IIdentification,
} from '../../../../models/documentCenter/workPostCards';
import Translate from '../../../common/Translate';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { schema, fields } from './identificationSchema';
import FormFieldError from '../../../common/forms/FormFieldError';
import { ShapeOf } from '../../../../models/ui/form';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import Dialog from '../../../common/modals/Dialog';
import EditAgencyAddress from './EditAgencyAddress';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import DatePicker from '../../../common/widget/DateTimePicker/DatePicker';
import { createFormTextInput } from '../../../common/forms/FormTextInput';
import {
    tryFormattingPhoneInternational,
} from '../../../../utils/formatting/formatPhone';

export type FormValues = IIdentification;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    translator: ITranslator;
    updateWizardEntity: (values: FormValues) => void;
}

const FORM_NAME = 'work-post-card-identification';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.identification';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

interface IState {
    isEditAddressDialogOpen: boolean;
}

type IFormTextInputFormValues = Pick<FormValues, 'agencyName' | 'agencyPhoneContact' | 'agencyPbw' |
    'workerFirstName' | 'workerName' | 'workerPhone' | 'workerQualification'>;

const IdentificationFormTextInput = createFormTextInput<IFormTextInputFormValues>();

class Identification extends Component<IStepperStepRenderProps & IPrivateProps, IState> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props) {
        super(props);

        this.state = {
            isEditAddressDialogOpen: false,
        };

        this.onToggleAddressDialog = this.onToggleAddressDialog.bind(this);
        this.onAddressSubmit = this.onAddressSubmit.bind(this);
        this.onDateOfIssueChanged = this.onDateOfIssueChanged.bind(this);
        this.onDateOfBirthChanged = this.onDateOfBirthChanged.bind(this);
        this.onCloseAddressDialog = this.onCloseAddressDialog.bind(this);
    }

    public render() {
        const {
            renderStepButtons, translator, wizardEntity, goToNextStep, updateWizardEntity,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            agencyName: wizardEntity.identification && wizardEntity.identification.agencyName || '',
            agencyAddress: wizardEntity.identification && wizardEntity.identification.agencyAddress || null,
            agencyPhoneContact: wizardEntity.identification &&
                tryFormattingPhoneInternational(wizardEntity.identification.agencyPhoneContact) || '',
            agencyPbw: wizardEntity.identification && wizardEntity.identification.agencyPbw || '',
            agencyDateOfIssue: wizardEntity.identification && wizardEntity.identification.agencyDateOfIssue || '',
            workerName: wizardEntity.identification && wizardEntity.identification.workerName || '',
            workerFirstName: wizardEntity.identification && wizardEntity.identification.workerFirstName || '',
            workerPhone: wizardEntity.identification &&
                tryFormattingPhoneInternational(wizardEntity.identification.workerPhone) || '',
            workerDateOfBirth: wizardEntity.identification && wizardEntity.identification.workerDateOfBirth || '',
            workerQualification: wizardEntity.identification && wizardEntity.identification.workerQualification || '',
        };

        const ADVANCED_INPUT_PLACEHOLDER_KEYS:
            Pick<ShapeOf<FormValues>, 'agencyAddress' | 'workerDateOfBirth' | 'agencyDateOfIssue'> = {
                agencyAddress: `${BASE_KEY}.form.placeholder.agency_address`,
                agencyDateOfIssue: `${BASE_KEY}.form.placeholder.agency_date_of_issue`,
                workerDateOfBirth: `${BASE_KEY}.form.placeholder.worker_date_of_birth`,
            };

        function handleSubmit(values: FormValues) {
            updateWizardEntity({
                ...values,
                agencyPhoneContact: tryFormattingPhoneInternational(values.agencyPhoneContact),
                workerPhone: tryFormattingPhoneInternational(values.workerPhone),
            });
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    text={`${BASE_KEY}.text`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values, errors, handleChange, touched } = formRenderProps;

                            this.formRenderProps = formRenderProps;

                            return (
                                <>
                                    <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                        <h2><Translate msg={`${BASE_KEY}.form.title.employment_agency`} /></h2>
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'agencyName'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="work-post-card-agency-address"
                                                name={fields.agencyAddress}
                                                value={(values.agencyAddress &&
                                                    formatAddress(values.agencyAddress)) || ''}
                                                onFocus={this.onToggleAddressDialog}
                                                isInvalid={touched.agencyAddress && !!errors.agencyAddress}
                                                onChange={handleChange}
                                                multiLine={true}
                                                disableMultilineResize={true}
                                                disableMultilineStyles={true}
                                                rows={2}
                                                disableAutoComplete={true}
                                            />
                                            <label htmlFor="work-post-card-agency-address">
                                                <Translate msg={ADVANCED_INPUT_PLACEHOLDER_KEYS.agencyAddress} />
                                            </label>
                                            {touched.agencyAddress &&
                                                <FormFieldError
                                                    ignoreTranslationContext
                                                    error={errors.agencyAddress}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADVANCED_INPUT_PLACEHOLDER_KEYS.agencyAddress),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'agencyPhoneContact'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'agencyPbw'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <FloatableTextInputWrapper floatLabel>
                                            <DatePicker
                                                id="work-post-card-agency-date-of-issue"
                                                name={fields.agencyDateOfIssue}
                                                value={values.agencyDateOfIssue}
                                                onChange={this.onDateOfIssueChanged}
                                                inlineCalendar={false}
                                                isInvalid={touched.agencyDateOfIssue && !!errors.agencyDateOfIssue}
                                            >
                                                <label htmlFor="work-post-card-agency-date-of-issue">
                                                    <Translate
                                                        msg={ADVANCED_INPUT_PLACEHOLDER_KEYS.agencyDateOfIssue}
                                                    />
                                                </label>
                                            </DatePicker>
                                            {touched.agencyDateOfIssue &&
                                                <FormFieldError
                                                    ignoreTranslationContext
                                                    error={errors.agencyDateOfIssue}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADVANCED_INPUT_PLACEHOLDER_KEYS.agencyDateOfIssue),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <h2><Translate msg={`${BASE_KEY}.form.title.temporary_worker`} /></h2>
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'workerFirstName'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'workerName'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <FloatableTextInputWrapper floatLabel>
                                            <DatePicker
                                                id="work-post-card-worker-date-of-birth"
                                                name={fields.workerDateOfBirth}
                                                value={values.workerDateOfBirth}
                                                onChange={this.onDateOfBirthChanged}
                                                inlineCalendar={false}
                                                isInvalid={touched.workerDateOfBirth && !!errors.workerDateOfBirth}
                                            >
                                                <label htmlFor="work-post-card-worker-date-of-birth">
                                                    <Translate
                                                        msg={ADVANCED_INPUT_PLACEHOLDER_KEYS.workerDateOfBirth}
                                                    />
                                                </label>
                                            </DatePicker>
                                            {touched.workerDateOfBirth &&
                                                <FormFieldError
                                                    ignoreTranslationContext
                                                    error={errors.workerDateOfBirth}
                                                    placeholders={{
                                                        fieldName: translator(
                                                            ADVANCED_INPUT_PLACEHOLDER_KEYS.workerDateOfBirth),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'workerPhone'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <IdentificationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'workerQualification'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                                disabled: false,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
                {!!this.formRenderProps &&
                    <Dialog
                        show={this.state.isEditAddressDialogOpen}
                        onCloseIntent={this.onCloseAddressDialog}
                    >
                        <EditAgencyAddress
                            onClose={this.onCloseAddressDialog}
                            onFormSubmit={this.onAddressSubmit}
                            namePlaceholder={this.formRenderProps.values.agencyName}
                        />
                    </Dialog>
                }

            </>
        );
    }

    private onToggleAddressDialog() {
        this.setState({ isEditAddressDialogOpen: !this.state.isEditAddressDialogOpen });
    }

    private onCloseAddressDialog() {
        this.setState({ isEditAddressDialogOpen: false });
    }

    private onAddressSubmit(address) {
        this.formRenderProps.setFieldValue('agencyAddress', address);
        this.onToggleAddressDialog();
    }

    private onDateOfIssueChanged(formattedDate) {
        this.formRenderProps.setFieldValue('agencyDateOfIssue', formattedDate);
    }

    private onDateOfBirthChanged(formattedDate) {
        this.formRenderProps.setFieldValue('workerDateOfBirth', formattedDate);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateWorkPostCardWizardEntity({
                    identification: {
                        ...values,
                    },
                }));
            },
        };
    },
})(Identification);
