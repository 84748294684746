import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { camelCaseToSnakeCase } from '../../../../utils/formatting/formatTranslationKey';
import { TextPropertyInput } from '../../input/PropertyInput';
import Icon from '../../icons/Icon';
import './form-property-input-for-display.scss';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { IAddressSave } from '../../../../models/general/address';

interface IValidatePropertyInputProps<FormValues> {
    name: keyof FormValues;
    values: FormValues;
    baseTranslationKey: string;
    baseId: string;
    readOnlyColor?: 'primary';
    valueType?: 'address' | 'date';
}

const CLASS_NAME = 'FormPropertyInputForDisplay';

class PropertyInputForDisplay<FormValues extends object>
    extends PureComponent<IValidatePropertyInputProps<FormValues>> {

    public render() {
        const { name, baseTranslationKey, baseId, readOnlyColor, values, valueType } = this.props;

        const id = `${baseId}-${name}`;
        const fullTranslationKey = `${baseTranslationKey}.${camelCaseToSnakeCase(name as unknown as string)}`;

        const value = values[name];
        const valueIsBoolean = typeof value === 'boolean';
        const valueIsBooleanAndFalse = valueIsBoolean && !value;

        const formattedValue =
            !!value ?
                valueType === 'address' ? formatAddress(value as unknown as IAddressSave) :
                    valueType === 'date' ? formatDateForDisplay(value as unknown as string) :
                    value :
                '';

        const iconClasses = classNames(`${CLASS_NAME}__icon`, {
            ['color-slate-grey']: valueIsBooleanAndFalse,
        });

        return (
            <TextPropertyInput
                id={id}
                name={name as unknown as string}
                labelKey={fullTranslationKey}
                readonly={true}
                value={formattedValue}
                readOnlyColor={readOnlyColor}
                readOnlyHideValuePlaceholder={valueIsBooleanAndFalse}
            >
                {valueIsBoolean &&
                    <Icon className={iconClasses} typeName={valueIsBooleanAndFalse ? 'cross' : 'check'} />}
            </TextPropertyInput>
        );
    }
}

export function createPropertyInputForDisplay<FormValues extends object>() {
    return PropertyInputForDisplay as any as new () => PropertyInputForDisplay<FormValues>;
}
