import React from 'react';

import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { IMedicalCenter } from '../../../../../models/admin/employee';
import { TTypeaheadData } from '../../../../common/input/Typeahead';

export const mapMedicalCentersForTypeahead = (medicalCenters: IMedicalCenter[]): TTypeaheadData => {
    return Array.isArray(medicalCenters) ? medicalCenters.map((item) => ({
        value: item.id,
        label: item.name,
        dropdownItemContent: item.name && (
            <>
                <strong>{item.name}</strong><br />
                {item.address && formatAddress(item.address)}
            </>
        ),
    })) : [];
};
