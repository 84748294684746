import React, { PureComponent, ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import './textinput.scss';

interface IMultiLineTextInputBaseProps {
    multiLine?: boolean;
    disableMultilineResize?: boolean;
    disableMultilineStyles?: boolean;
    scaleWithTextIfMultiLine?: boolean;
    rows?: number;
}

interface INumberTextInputProps {
    min?: number;
    max?: number;
    step?: number;
}

export interface ITextInputBaseProps {
    id: string;
    name: string;
    type?: 'text' | 'password' | 'number';
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyUp?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick?: (e: MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    placeholder?: string;
    isInvalid?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    disableAutoComplete?: boolean;
    isUncontrolled?: boolean;
    selectOnFocus?: boolean;
    setRef?: (ref: HTMLElement) => void;
}
export interface ITextInputProps extends ITextInputBaseProps, IMultiLineTextInputBaseProps, INumberTextInputProps {
    value: string;
}

interface ITextInputState {
    hasChangedAfterSetToInvalid: boolean;
}

class TextInput extends PureComponent<ITextInputProps, ITextInputState> {

    private textAreaElement: HTMLElement;

    constructor(props: ITextInputProps) {
        super(props);

        this.state = {
            hasChangedAfterSetToInvalid: false,
        };

        this.autoScaleTextArea = this.autoScaleTextArea.bind(this);
        this.selectOnFocusHandler = this.selectOnFocusHandler.bind(this);
    }

    public componentDidUpdate(prevProps: ITextInputProps) {
        if (this.props.scaleWithTextIfMultiLine && prevProps.value !== this.props.value) {
            this.autoScaleTextArea();
        }
    }

    public componentDidMount() {
        if (this.props.scaleWithTextIfMultiLine) {
            this.autoScaleTextArea();
        }
    }

    public UNSAFE_componentWillUpdate(nextProps: ITextInputProps, nextState: ITextInputState) {
        if (nextProps.isInvalid !== this.props.isInvalid) {
            this.setState({
                hasChangedAfterSetToInvalid: false,
            });
        } else if (!this.state.hasChangedAfterSetToInvalid && this.props.value !== nextProps.value) {
            this.setState({
                hasChangedAfterSetToInvalid: true,
            });
        }
    }

    public render() {
        const {
            id,
            placeholder,
            name,
            type,
            onChange,
            isInvalid,
            defaultValue,
            disabled,
            onBlur,
            onFocus,
            onClick,
            onKeyDown,
            onKeyUp,
            onKeyPress,
            value,
            disableAutoComplete,
            multiLine,
            disableMultilineStyles = false,
            min,
            max,
            rows = 10,
            isUncontrolled,
            step,
            selectOnFocus,
            disableMultilineResize = false,
            setRef,
        } = this.props;

        const { hasChangedAfterSetToInvalid } = this.state;

        const textInputClass = classNames('TextInput', {
            dirty: value && value.toString().length > 0,
            invalid: isInvalid,
            'reset-color': hasChangedAfterSetToInvalid || !isInvalid,
            'multi-line': !disableMultilineStyles && multiLine,
            'disable-resize': multiLine && disableMultilineResize,
            disabled: !!disabled,
        });

        // Autocomplete behaves strange in Chrome
        // When it has a value use 'off'
        // When it is empty use any other invalid value
        const autoComplete = disableAutoComplete ? (!value ? 'nope' : 'off') : 'on';

        const commonProps = {
            className: textInputClass,
            placeholder: placeholder || '',
            value: isUncontrolled ? value : value || '',
            name,
            id,
            onChange,
            defaultValue: !value ? defaultValue : undefined,
            disabled,
            onFocus: selectOnFocus ? this.selectOnFocusHandler : onFocus,
            onBlur,
            onKeyDown,
            onKeyUp,
            onKeyPress,
            onClick,
            autoComplete,
            min,
            max,
            step,
        } as React.InputHTMLAttributes<{}>;

        if (multiLine) {
            return (
                <textarea
                    {...commonProps}
                    ref={(ref) => {
                        this.textAreaElement = ref;
                        if (typeof setRef === 'function') {
                            setRef(ref);
                        }
                    }}
                    rows={rows}
                />
            );
        }

        return (
            <input
                type={type ? type : 'text'}
                ref={setRef}
                {...commonProps}
            />
        );
    }

    private autoScaleTextArea() {
        if (this.textAreaElement) {
            this.textAreaElement.style.height = 'auto';
            this.textAreaElement.style.height = `${this.textAreaElement.scrollHeight}px`;
        }
    }

    private selectOnFocusHandler(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.props.onFocus(e);
        e.target.select();
    }
}

export default TextInput;
