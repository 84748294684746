import { createEpic } from '../../../index';
import { getSelectedSeatCompanyCode } from '../../../company/selected/selectors';
import { IRequestVaccinationPayload } from '../../../../models/interventions/vaccines';

import { REQUEST_VACCINATION } from '../types';
import { requestVaccinationActions } from '../actions';

// requestMedicalExaminationEpic
createEpic<IRequestVaccinationPayload>({
    onActionType: REQUEST_VACCINATION,
    async processReturn({ getState, api, action }) {
        try {
            await api.interventions.vaccinations.requestVaccination({
                companyCode: getSelectedSeatCompanyCode(getState()),
                ...action.payload,
            });

            return requestVaccinationActions.succeeded({});
        } catch (error) {
            return requestVaccinationActions.failed(error);
        }
    },
    latest: false,
});
