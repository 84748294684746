export default function convertDataUrlToBlob(data: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded datas - see SO answer #6850276 for code that does this
    const byteString = atob(data.split(',')[1]);

    // separate out the mime component
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    let i = 0;
    // set the bytes of the buffer to the correct values
    while (i < byteString.length) {
        ia[i] = byteString.charCodeAt(i);
        i = i + 1;
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
}
