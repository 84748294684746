import { IMappedAdministeredVaccination, IMappedToAdministerVaccination } from '../../../models/interventions/vaccines';
import { IAsyncFetchField } from '../../index';
import { IAsyncDoField } from '../../../models/general/redux';

export const FETCH_ADMINISTERED_VACCINATIONS = 'FETCH_ADMINISTERED_VACCINATIONS';
export const FETCH_TO_ADMINISTER_VACCINATIONS = 'FETCH_TO_ADMINISTER_VACCINATIONS';
export const REQUEST_VACCINATION = 'REQUEST_VACCINATION';

export interface IFetchVaccinationsPayload {
    mode: 'MainSeat' | 'Seat';
    customerId: number | string;
}

export interface IFetchAdministeredVaccinationsPayload extends IFetchVaccinationsPayload {
    administeredDateFrom: string;
    administeredDateUntil: string;
}

export interface IFetchToAdministerVaccinationsPayload extends IFetchVaccinationsPayload {
    administerDateFrom: string;
    administerDateUntil: string;
}

export interface IReducerState {
    administered: IAsyncFetchField<IMappedAdministeredVaccination[]>;
    toAdminister: IAsyncFetchField<IMappedToAdministerVaccination[]>;
    requestVaccination: IAsyncDoField;
}
