
export function isValidDisplayDate(date: string) {
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const isValidFormat = regex.test(date);
    if (!isValidFormat) {
        return false;
    }
    const [, day, month, year] = date.match(regex);
    return validateMonthAndDayValuesAreValid(day, month, year);
}

export function isValidBackendDate(date: string) {
    const regex = /^(\d{4})\-(\d{2})\-(\d{2})$/;
    const isValidFormat = regex.test(date);
    if (!isValidFormat) {
        return false;
    }
    const [, year, month, day] = date.match(regex);
    return validateMonthAndDayValuesAreValid(day, month, year);
}

function validateMonthAndDayValuesAreValid(day: string, month: string, year: string) {
    const monthIndex = Number(month) - 1;
    // By parsing the data and comparing the output we know that for example "13" as month is not valid
    // The parsing itself will never fail but instead turn "13" into "1" and increase the year
    // By comparing the parsed output we know it is valid or not
    const parsedDate = new Date(Number(year), monthIndex, Number(day));
    return (
        parsedDate.getDate() === Number(day) &&
        parsedDate.getMonth() === monthIndex &&
        parsedDate.getFullYear() === Number(year)
    );
}
