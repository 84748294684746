import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { ICourseSessionAttendee } from '../../../../../models/documentCenter/courses';

export const concatenateAttendees = (attendees: ICourseSessionAttendee[]) => {
    if (attendees.length <= 0) {
        return '';
    }
    return attendees.reduce(
        (accumulator, attendee) => {
            return accumulator + `${formatPersonName(attendee)}, `;
        },
        '',
    ).slice(0, -2);
};
