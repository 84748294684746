import React, { PureComponent, MouseEvent } from 'react';
import { connect } from '../../../index';
import Icon from '../../../common/icons/Icon';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import Translate from '../../../common/Translate';
import Tooltip from '../../../common/widget/Tooltip';
import CloseIcon from '../../../common/icons/CloseIcon';
import { ListItem } from '../../../../models/general/list';
import { IActivityReportColumnNames } from '..';
import { fetchDocument } from '../../../../redux/employee/documents/actions';
import { fetchActivityReportDetailActions } from '../../../../redux/company/documents/actions';
import { IFetchActivityReportDetailPayload } from '../../../../models/general/documents';

interface IPublicProps {
    listItem: ListItem<IActivityReportColumnNames>;
    year: string;
}

interface IPrivateProps {
    onDownloadDocument: (id: string) => void;
    onDownloadDetail: (id: string, year: string) => void;
}

class ActivityReportActions extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
        this.onDownloadDetail = this.onDownloadDetail.bind(this);
    }

    public render() {
        const {
            listItem, year,
        } = this.props;

        const downloadIcon = (
            <div>
                <Icon circle typeName="download-file" />
            </div>
        );

        return (
            <ListItemActions>
                <Tooltip
                    placement="left"
                    typeName="tooltip"
                    target={downloadIcon}
                    spaceBetweenTarget={15}
                >
                    {({ closeTooltip }) => {
                        return (
                            <>
                                <CloseIcon onClick={closeTooltip} />
                                <div className="button-list">
                                    <Button
                                        id="download-activity-report"
                                        typeName="text"
                                        onClick={(e) => this.onDownloadDocument(e, listItem)}
                                    >
                                        <Translate msg={'document_center.activity_report.actions.download'} />
                                    </Button>
                                    <Button
                                        id="download-activity-report-detail"
                                        typeName="text"
                                        onClick={(e) => this.onDownloadDetail(e, listItem, year)}
                                    >
                                        <Translate msg={'document_center.activity_report.actions.download_detail'} />
                                    </Button>
                                </div>
                            </>
                        );
                    }}
                </Tooltip>
            </ListItemActions>
        );
    }

    private onDownloadDocument(e: MouseEvent<HTMLElement>, listItem: ListItem<IActivityReportColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDocument,
        } = this.props;

        onDownloadDocument(listItem.id as string);
    }

    private onDownloadDetail(e: MouseEvent<HTMLElement>, listItem: ListItem<IActivityReportColumnNames>, year: string) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDetail,
        } = this.props;

        onDownloadDetail(listItem.id as string, year);
    }
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => {
        return {
            onDownloadDocument: (id: string) => {
                dispatch(fetchDocument({
                    ids: [id.toString()],
                }));
            },
            onDownloadDetail: (id: string, year: string) => {
                dispatch(fetchActivityReportDetailActions.trigger({ year, id } as IFetchActivityReportDetailPayload));
            },
        };
    },
})(ActivityReportActions);
