import React, { PureComponent } from 'react';
import { connect } from '../../index';
import './finish-onboarding-wizard.scss';
import Translate from '../../common/Translate';
import Button from '../../common/buttons/Button';
import illustration from '../../assets/img/illustrations/illu_onboarding.img.svg';
import {
    getSelectedCompany,
} from '../../../redux/company/selected/selectors';
import { ICompany } from '../../../models/admin/company';
import { navigateTo, navigateToSeatsSelection } from '../../../redux/location/actions';
import { hasCustomerNotPaid } from '../../../redux/company/companies/selectors';
import ROUTE_KEYS from '../../../routeKeys';

const CLASS_NAME = 'FinishOnboardingWizard';

interface IPrivateProps {
    company: ICompany;
    goToSeatsSelection: () => void;
    doLogout: () => void;
}

class FinishOnboardingWizard extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public render() {
        const { company, goToSeatsSelection, doLogout } = this.props;

        if (!company) {
            return null;
        }

        const isNotPaid = hasCustomerNotPaid(company);

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__content`}>
                    <div className="container">
                        <h1>
                            { isNotPaid
                                ? <Translate msg="onboarding.finish_not_paid.title" />
                                : <Translate msg="onboarding.finish.title" />
                            }
                        </h1>
                        { isNotPaid
                            ? <>
                                <p><Translate msg="onboarding.finish_not_paid.text" raw={true} /></p>
                                <p>
                                    <Button
                                        id="onboarding-logout"
                                        typeName="secondary"
                                        onClick={doLogout}
                                    >
                                        <Translate msg="onboarding.finish.logout"/>
                                    </Button>
                                </p>
                            </>
                            : <p>
                                <Button
                                    id="onboarding-to-seat-selection"
                                    typeName="secondary"
                                    onClick={goToSeatsSelection}
                                >
                                    <Translate msg="onboarding.finish.action"/>
                                </Button>
                            </p>
                        }
                    </div>
                </div>
                <figure className={`${CLASS_NAME}__figure`} style={{ backgroundImage: `url(${illustration})` }} />
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            company: getSelectedCompany(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            goToSeatsSelection: () => {
                const state = getState();
                const company = getSelectedCompany(state);
                dispatch(navigateToSeatsSelection(company.companyCode));
            },
            doLogout: () => {
                dispatch(
                    navigateTo(ROUTE_KEYS.R_LOGOUT, { isSessionExpired: false }),
                );
            },
        };
    },
})(FinishOnboardingWizard);
