import React from 'react';

import { changeWorkPostCardWizardStep } from '../../../redux/documentCenter/workPostCards/actions';
import {
    getWorkPostCardWizardStepId,
    getWorkPostCardWizardTranslator,
    getWorkPostCardWizardLocale,
} from '../../../redux/documentCenter/workPostCards/selectors';
import { ILocalePayload, ITranslator } from '../../../models/general/i18n';
import { IStepperStep } from '../../../models/general/stepper';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';
import { WORK_POST_CARD_WIZARD_STEP_ID } from '../../../models/documentCenter/workPostCards';
import { connect } from '../../index';
import TranslatorContext from '../../appShell/contexts/TranslatorContext';
import Wizard from '../../common/navigation/Wizard/index';

import Data from './Data';
import Education from './Education';
import Identification from './Identification';
import Maternity from './Maternity';
import Properties from './Properties';
import Protection from './Protection';
import Risks from './Risks';
import Validate from './Validate';

interface IPrivateProps extends ILocalePayload {
    translator: ITranslator;
}

const WORK_POST_CARD_STEPS: IStepperStep[] = [{
    id: WORK_POST_CARD_WIZARD_STEP_ID.DATA,
    translationKey: 'document_center.work_post_card_wizard.steps.base_data.step_label',
    renderStepContent: (props) => <Data {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.IDENTIFICATION,
    translationKey: 'document_center.work_post_card_wizard.steps.identification.step_label',
    renderStepContent: (props) => <Identification {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.PROPERTIES,
    translationKey: 'document_center.work_post_card_wizard.steps.properties.step_label',
    renderStepContent: (props) => <Properties {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.MATERNITY,
    translationKey: 'document_center.work_post_card_wizard.steps.maternity.step_label',
    renderStepContent: (props) => <Maternity {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.RISKS,
    translationKey: 'document_center.work_post_card_wizard.steps.risks.step_label',
    renderStepContent: (props) => <Risks {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.PROTECTION,
    translationKey: 'document_center.work_post_card_wizard.steps.protection.step_label',
    renderStepContent: (props) => <Protection {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.EDUCATION,
    translationKey: 'document_center.work_post_card_wizard.steps.education.step_label',
    renderStepContent: (props) => <Education {...props} />,
}, {
    id: WORK_POST_CARD_WIZARD_STEP_ID.VALIDATE,
    translationKey: 'document_center.work_post_card_wizard.steps.validate.step_label',
    renderStepContent: (props) => <Validate {...props} />,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.CREATE_WORK_POST_CARD,
    steps: WORK_POST_CARD_STEPS,
});

function WorkPostCardWizard(props: IPrivateProps) {
    const { translator, locale } = props;
    return (
        <TranslatorContext.Provider value={{ translator, locale }} >
            <div className="WorkPostCardWizard">
                <Wizard
                    activeStepIdSelector={getWorkPostCardWizardStepId}
                    navigateToStepActionCreator={changeWorkPostCardWizardStep}
                    steps={WORK_POST_CARD_STEPS}
                />
            </div>
        </TranslatorContext.Provider>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translator: getWorkPostCardWizardTranslator(state),
            locale: getWorkPostCardWizardLocale(state),
        };
    },
})(WorkPostCardWizard);
