import { path } from 'ramda';

import {
    IAutoPlanEmployeePayload,
    IAutoPlanEmployeeApiSuccessResponse,
    TAutoPlanEmployeeResponse,
} from '../../../models/interventions/timeslots';
import { IMedicalExaminationToAdd } from '../../../models/interventions/medicalExaminations';

type TGetMedExamToAddIdPayload = IMedicalExaminationToAdd
    | IAutoPlanEmployeeApiSuccessResponse
    | TAutoPlanEmployeeResponse
    | IAutoPlanEmployeePayload;

export const getMedExamToAddId = (exam: TGetMedExamToAddIdPayload): string => {
    const planningRequestId = path<number>(['planningRequestId'], exam);

    if (planningRequestId) {
        return '' + planningRequestId;
    }

    return `ADDED-${exam.employee.id}`;
};
