import { IAdministrationItem } from '../../models/general/navigation';
import ROUTE_KEYS from '../../routeKeys';
import { IAgendaRoutePayload } from '../../models/planning/agenda';
import { ADMINISTRATION_SVG_IDS } from '../../models/general/lazyLoadSvg';

export const ADMINISTRATION_ITEMS: IAdministrationItem[] = [
    {
        id: 'administration-employees-link',
        linkTo: ROUTE_KEYS.R_EMPLOYEES,
        titleTranslationKey: 'administration.overview.main.employees',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.EMPLOYEES,
        detailLazyLoadSvgId: ADMINISTRATION_SVG_IDS.EMPLOYEES_DETAIL,
    },
    {
        id: 'administration-functions-link',
        linkTo: ROUTE_KEYS.R_COMPANY_FUNCTIONS,
        titleTranslationKey: 'administration.overview.main.functions',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.FUNCTIONS,
        detailLazyLoadSvgId: ADMINISTRATION_SVG_IDS.FUNCTIONS_DETAIL,
    },
    {
        id: 'administration-risks-link',
        linkTo: ROUTE_KEYS.R_COMPANY_RISKS,
        titleTranslationKey: 'administration.overview.main.risks_and_dangers',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.RISKS,
        detailLazyLoadSvgId: ADMINISTRATION_SVG_IDS.RISKS_DETAIL,
    },
    {
        id: 'administration-billing-link',
        linkTo: ROUTE_KEYS.R_INVOICES_OPEN,
        titleTranslationKey: 'administration.overview.main.billing',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.BILLING,
    },
    {
        id: 'administration-prevention-link',
        linkTo: ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
        titleTranslationKey: 'administration.overview.main.prevention',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.PREVENTION,
    },
    {
        id: 'administration-flat-fee-link',
        linkTo: ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
        titleTranslationKey: 'administration.overview.main.flat_fee',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.PREVENTION,
    },
    {
        id: 'administration-company-visits-link',
        linkTo: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
        titleTranslationKey: 'administration.overview.main.company_visits',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.COMPANY_VISIT,
    },
    {
        id: 'administration-questionnaires-link',
        linkTo: ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK,
        titleTranslationKey: 'administration.overview.main.questionnaires',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.CHECK_LIST,
    },
];

export const ADMINISTRATION_MANAGEMENT_ITEMS: IAdministrationItem[] = [
    {
        id: 'administration-manage-company-link',
        linkTo: ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
        titleTranslationKey: 'administration.overview.sub.manage_company',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.BUILDING,
    },
    {
        id: 'administration-agenda-link',
        linkTo: ROUTE_KEYS.R_AGENDA,
        linkToPayload: { viewType: 'calendar' } as IAgendaRoutePayload,
        titleTranslationKey: 'administration.overview.sub.agenda',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.CALENDAR,
    },
    {
        id: 'administration-rates-link',
        linkTo: ROUTE_KEYS.R_COMPANY_RATES,
        titleTranslationKey: 'administration.overview.sub.rates',
        lazyLoadSvgId: ADMINISTRATION_SVG_IDS.MONEY,
    },
];
