import { ArgumentAction } from 'redux-logic/definitions/action';
import { createEpic } from '../index';
import {
    FETCH_UNREAD_MESSAGES_COUNT,
    FETCH_USER_MESSAGES,
    MARK_MESSAGE_AS_READ,
    DELETE_USER_MESSAGE,
    UPDATE_USER_MESSAGE_TYPES,
    FETCH_USER_MESSAGE_TYPES,
} from './types';
import {
    fetchUnreadMessagesCountSucceeded,
    fetchUnreadMessagesCountFailed,
    fetchUserMessagesActions,
    fetchUserMessageDetailActions,
    markMessageAsReadActions,
    deleteUserMessageActions,
    updateUserMessageTypesActions,
    fetchUserMessageTypesActions,
} from './actions';
import {
    getUnreadMessagesCountIfAny,
    getUserMessageDetailsById,
    getUserMessages,
    areUserMessagesAvailable,
} from './selectors';
import {
    IFetchUserMessageDetailPayload,
    IMarkMessageAsReadStatus,
    IDeleteUserMessagePayload,
    IUpdateUserMessageTypesPayload, IUserMessagesFilters,
} from '../../models/user/inbox';
import ROUTE_KEYS from '../../routeKeys';
import { DEFAULT_USER_MESSAGES_FILTERS } from '../../config/inbox.config';
import {
    getSelectedSeatCompanyCode, isAllSeatsSelected, getSelectedSeatCompanyId,
} from '../company/selected/selectors';
import isEmptyObject from '../../utils/core/object/isEmptyObject';
import { getLocationState, getQueryParams } from '../location/selectors';
import { areObjectParamsEqual } from '../../utils/core/object/diffObjects';
import { IState } from '../IState';
import { triggerFlashThatThereAreNewUnreadMessages } from '../flashMessages/actions';

// fetchUnreadMessagesCountEpic
createEpic<{}>({
    onActionType: FETCH_UNREAD_MESSAGES_COUNT,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const companyId = getSelectedSeatCompanyId(state);
            const showFullFamily = isAllSeatsSelected(state);
            const currentCount = getUnreadMessagesCountIfAny(state) || 0;

            const newCount = await api.user.inbox.fetchUnreadMessagesCount(companyCode, companyId, showFullFamily);
            if (newCount && newCount.count > 0 && newCount.count > currentCount) {
                dispatch(triggerFlashThatThereAreNewUnreadMessages({
                    count: newCount.count,
                }));
            }

            dispatch(fetchUnreadMessagesCountSucceeded(newCount));
        } catch (error) {
            dispatch(fetchUnreadMessagesCountFailed(error));
        }
        return done();
    },
    latest: false,
});

// fetchUserMessagesEpic
createEpic<{}>({
    onActionType: [ROUTE_KEYS.R_MESSAGE_CENTER, FETCH_USER_MESSAGES],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        if (!areUserMessagesAvailable(state)) {
            return true;
        }

        const {
            type: prevRouteKey,
            query: prevFilter,
        } = getLocationState(state);

        if (prevRouteKey === ROUTE_KEYS.R_MESSAGE_CENTER_DETAIL) {
            /* No refresh if we navigate from details back to the message overview */
            return false;
        }

        if (prevRouteKey === ROUTE_KEYS.R_MESSAGE_CENTER) {
            /* No refresh if we stay on message center overview and only client side filtering changed */

            const prevFilterWithDefaults = {
                ...DEFAULT_USER_MESSAGES_FILTERS,
                ...prevFilter,
            };

            return !areObjectParamsEqual(prevFilterWithDefaults, action.meta.query, ['startDate', 'endDate']);
        }

        return true;
    },
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();

            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);

            const filter = getQueryParams(state) as IUserMessagesFilters;

            const messages = await api.user.inbox.fetchUserMessages({
                companyCode,
                showFullFamily,
                dateFrom: filter.startDate || DEFAULT_USER_MESSAGES_FILTERS.startDate,
                dateUntil: filter.endDate || DEFAULT_USER_MESSAGES_FILTERS.endDate,
            });

            dispatch(fetchUserMessagesActions.succeeded(messages));
        } catch (error) {
            dispatch(fetchUserMessagesActions.failed(error));
        }
        return done();
    },
    latest: false,
});

// fetchUserMessageDetailEpic
createEpic<IFetchUserMessageDetailPayload>({
    onActionType: ROUTE_KEYS.R_MESSAGE_CENTER_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            fetchUserMessagesIfNotAvailable(getState, dispatch);

            const state = getState();
            const messageId = action.payload.id;

            let message = getUserMessageDetailsById(state, messageId);
            if (isEmptyObject(message)) {
                const companyCode = getSelectedSeatCompanyCode(state);
                const showFullFamily = isAllSeatsSelected(state);
                message = await api.user.inbox.fetchUserMessageDetails(action.payload, companyCode, showFullFamily);
            } else {
                const userMessages = getUserMessages(state);
                const selectedUserMessageFromList = userMessages.find((item) => item.id === message.id);

                if (selectedUserMessageFromList && !selectedUserMessageFromList.read) {
                    dispatch(markMessageAsReadActions.trigger({
                        id: messageId,
                        read: true,
                    }));
                }
            }

            dispatch(fetchUserMessageDetailActions.succeeded({ ...message, id: action.payload.id }));
        } catch (error) {
            dispatch(fetchUserMessageDetailActions.failed(error));
        }
        return done();
    },
    latest: true,
});

function fetchUserMessagesIfNotAvailable(
    getState: () => IState,
    dispatch: (action: ArgumentAction<string, undefined, undefined>) => void,
) {
    const state = getState();
    const userMessages = getUserMessages(state);

    if (userMessages.length <= 0) {
        dispatch(fetchUserMessagesActions.trigger({}));
    }
}

// markMessageAsReadEpic
createEpic<IMarkMessageAsReadStatus>({
    onActionType: MARK_MESSAGE_AS_READ,
    latest: false,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);

            await api.user.inbox.changeMessageStatus(action.payload, companyCode, showFullFamily);

            dispatch(markMessageAsReadActions.succeeded(action.payload));
        } catch (error) {
            dispatch(markMessageAsReadActions.failed({
                ...error,
                messageId: action.payload.id,
                read: !action.payload.read,
            }));
        }
        return done();
    },
});

// deleteUserMessageEpic
createEpic<IDeleteUserMessagePayload>({
    onActionType: DELETE_USER_MESSAGE,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);

            await api.user.inbox.changeMessageStatus(
                {
                    id: action.payload.id,
                    deleted: true,
                },
                companyCode,
                showFullFamily,
            );

            dispatch(deleteUserMessageActions.succeeded(action.payload));
        } catch (error) {
            dispatch(deleteUserMessageActions.failed(error));
        }
        return done();
    },
    latest: false,
});

// updateUserMessageTypes
createEpic<IUpdateUserMessageTypesPayload>({
    onActionType: UPDATE_USER_MESSAGE_TYPES,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            await api.user.inbox.updateUserMessageTypes(action.payload);

            dispatch(fetchUserMessageTypesActions.trigger({}));

            dispatch(updateUserMessageTypesActions.succeeded(action.payload));
        } catch (error) {
            dispatch(updateUserMessageTypesActions.failed(error));
        }
        return done();
    },
    latest: false,
});

// fetchUserMessageTypesIfNotAvailableEpic
createEpic<{}>({
    onActionType: [ROUTE_KEYS.R_ACCOUNT_SETTINGS, FETCH_USER_MESSAGE_TYPES],
    async processReturn({ api }) {
        try {
            const messageTypes = await api.user.inbox.fetchUserMessageTypes();

            return fetchUserMessageTypesActions.succeeded(messageTypes);

        } catch (error) {
            return fetchUserMessageTypesActions.failed(error);
        }
    },
    latest: false,
});
