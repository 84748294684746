import React from 'react';
import WithMenuTemplate from '../WithMenuTemplate';
import MainHeaderAndFooterTemplate from '../MainHeaderAndFooterTemplate';
import connect from '../../../../utils/libs/redux/connect';
import { isLoggedIn } from '../../../../redux/auth/selectors';

interface IPrivateProps {
    loggedIn: boolean;
}

interface IPublicProps {
    children: React.ReactChild;
}

function PageNotFoundTemplate(props: IPrivateProps & IPublicProps) {
    if (props.loggedIn) {
        return (
            <WithMenuTemplate>
                {props.children}
            </WithMenuTemplate>
        );
    }
    return (
        <MainHeaderAndFooterTemplate
            showAccountMenu={true}
            showCompanySelectDropdown={false}
        >
            {props.children}
        </MainHeaderAndFooterTemplate>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => ({
        loggedIn: isLoggedIn(state),
    }),
})(PageNotFoundTemplate);
