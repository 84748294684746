import ROUTE_KEYS from '../../routeKeys';
import { getRoutes } from '../../routes';
import { IRoutes, IBreadcrumb } from './typings';

export function getClosestParentRouteKeyOfInputRoute({
    inputRouteKey,
    routes = getRoutes(),
}: {
    inputRouteKey: ROUTE_KEYS;
    routes?: IRoutes;
}): ROUTE_KEYS {
    const parentRouteKeys = getAllParentRouteKeysOfInputRoute({ routes, inputRouteKey })
        .sort((a, b) => sortParentRouteKeysFromTopParentToClosestParent(a, b, routes));

    return (parentRouteKeys && parentRouteKeys.length > 0)
        ? parentRouteKeys.pop()
        : null;
}

export function getBreadcrumbForInputRoute({
    inputRouteKey,
    routePayload,
    isCurrentRoute = false,
    routes = getRoutes(),
}: {
    inputRouteKey: ROUTE_KEYS;
    routePayload?: object;
    isCurrentRoute?: boolean;
    routes?: IRoutes;
}): IBreadcrumb {
    const inputRoute = routes[inputRouteKey];

    return {
        type: inputRouteKey,
        payload: routePayload || undefined,
        label: inputRoute.breadcrumbsLabel,
        isCurrent: isCurrentRoute,
    };
}

// Find parent routes by checking if any existing routes matches the current route
function getAllParentRouteKeysOfInputRoute({
    routes,
    inputRouteKey,
}: {
    routes: IRoutes;
    inputRouteKey: string;
}): ROUTE_KEYS[] {
    const inputRoute = routes[inputRouteKey];

    return Object.keys(routes)
        .filter((routeKey) => {
            const route = routes[routeKey];
            return routeKey !== inputRouteKey
                && route.path !== inputRoute.path
                && inputRoute.path.indexOf(route.path) === 0;
        }) as ROUTE_KEYS[];
}

function sortParentRouteKeysFromTopParentToClosestParent(routeKeyA: string, routeKeyB: string, routes: IRoutes) {
    const pathA = routes[routeKeyA].path;
    const pathB = routes[routeKeyB].path;
    if (pathA.indexOf(pathB) === 0) {
        return 1;
    }
    if (pathB.indexOf(pathA) === 0) {
        return -1;
    }
    return 0;
}
