import {
    FETCH_COURSES_PLANNED, FETCH_COURSES_PLANNED_DETAIL,
    FETCH_COURSE_SESSION, FETCH_COURSE_SESSION_DAYS,
    FETCH_COURSE_SESSION_ATTENDEES, FETCH_COURSES_SESSION_CERTIFICATES,
    FETCH_COURSES_OVERVIEW, FETCH_COURSES_OVERVIEW_DETAIL,
    FETCH_COURSES_FOLLOWED,
    REMOVE_COURSE_ATTENDEE, SET_REMOVE_ATTENDEE_NOT_POSSIBLE, FETCH_COURSE_SESSION_ATTENDANCE,
    UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY,
    FETCH_COURSE_POSSIBLE_LOCATIONS,
    FETCH_COURSE_SESSIONS_BY_LOCATION,
    SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
    RESET_COURSE_ENROLLMENT_WIZARD_ENTITY,
    CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY,
    CREATE_COURSE_ENROLLMENT,
    FETCH_EMPLOYEE_COURSES,
    FETCH_COURSE_EXTERNAL_EMPLOYEE,
    UPDATE_COURSE_EXTERNAL_EMPLOYEE,
    FETCH_COURSE_TABLE,
    FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
    CREATE_ELEARNING_COURSE_ENROLLMENT,
} from './types';
import { createTypeActions, createAction, createResetAction } from '../../../utils/libs/redux/createAction';
import {
    COURSE_ENROLL_WIZARD_STEP_ID,
    ICMSCourseDetail,
    ICourse,
    ICourseCategory,
    ICourseEnrollWizardEntity,
    ICourseEnrollWizardPayload,
    ICourseExternalEmployee,
    ICourseSession,
    ICourseSessionAttendant,
    ICourseSessionAttendee,
    ICourseSessionByLocation,
    ICourseSessionDay,
    IEmployeeCourse,
    IFetchCMSCourseDetailByHawIdPayload,
    IFetchCMSCourseDetailPayload,
    IFetchCMSCourseTablePayload,
    IFetchCourseDetailPayload,
    IFetchCourseExternalEmployeePayload,
    IFetchCoursePossibleLocationsPayload,
    IFetchCourseSessionAttendancePayload,
    IFetchCourseSessionAttendeesPayload,
    IFetchCourseSessionCertificatesPayload,
    IFetchCourseSessionDaysPayload,
    IFetchCourseSessionPayload,
    IFetchCourseSessionsByLocationPayload,
    IFetchCoursesPayload,
    IFetchEmployeeCoursesPayload,
    IRemoveCourseAttendeePayload,
    ISkipToCourseEnrollmentWizardStepPayload,
    ITriggerCourseEnrollWizardPayload,
    IUpdateCourseExternalEmployeePayload,
} from '../../../models/documentCenter/courses';
import { IDocument } from '../../../models/general/documents';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';
import { navigateTo } from '../../location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { IAddress } from '../../../models/general/address';
import { getEnrollCourseWizardSteps } from '../../../config/navigation/wizardStepsMap';

// Fetch courses planned
export const fetchCoursesPlannedActions = createTypeActions<IFetchCoursesPayload, ICourse[]>({
    type: FETCH_COURSES_PLANNED,
});

// Fetch planned course detail
export const fetchSelectedPlannedCourseActions = createTypeActions<IFetchCourseDetailPayload, ICourse>({
    type: FETCH_COURSES_PLANNED_DETAIL,
});

// Fetch course session
export const fetchCourseSessionActions = createTypeActions<IFetchCourseSessionPayload, ICourseSession>({
    type: FETCH_COURSE_SESSION,
});

// Fetch course session days
export const fetchCourseSessionDaysActions = createTypeActions<IFetchCourseSessionDaysPayload, ICourseSessionDay[]>({
    type: FETCH_COURSE_SESSION_DAYS,
});

// Fetch course session attendees
export const fetchCourseSessionAttendeesActions =
    createTypeActions<IFetchCourseSessionAttendeesPayload, ICourseSessionAttendee[]>({
        type: FETCH_COURSE_SESSION_ATTENDEES,
    });

// Fetch courses followed
export const fetchCoursesFollowedActions = createTypeActions<IFetchCoursesPayload, ICourse[]>({
    type: FETCH_COURSES_FOLLOWED,
});

// Fetch followed course certificates
export const fetchCourseSessionCertificatesActions =
    createTypeActions<IFetchCourseSessionCertificatesPayload, IDocument>({
        type: FETCH_COURSES_SESSION_CERTIFICATES,
        onSuccess: (payload) => {
            presentDownloadedFile(payload);
        },
    });

// Fetch courses overview
export const fetchCoursesOverviewActions = createTypeActions<{}, ICourseCategory[]>({
    type: FETCH_COURSES_OVERVIEW,
});

// Remove course attendee
export const removeCourseAttendeeActions = createTypeActions<IRemoveCourseAttendeePayload>({
    type: REMOVE_COURSE_ATTENDEE,
});

export function setRemoveAttendeeNotPossible() {
    return createAction<object>(SET_REMOVE_ATTENDEE_NOT_POSSIBLE, {});
}

export function resetRemoveAttendeeNotPossible() {
    return createResetAction(SET_REMOVE_ATTENDEE_NOT_POSSIBLE, {});
}

export const fetchCoursesOverviewDetailActions = createTypeActions<IFetchCMSCourseDetailPayload, ICMSCourseDetail>({
    type: FETCH_COURSES_OVERVIEW_DETAIL,
});

export const fetchCourseTableActions = createTypeActions<IFetchCMSCourseTablePayload, any>({
    type: FETCH_COURSE_TABLE,
});

export const fetchCoursesOverviewDetailByHawActions = createTypeActions<IFetchCMSCourseDetailByHawIdPayload>({
    type: FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
});

// Fetch course session attendance
export const fetchCourseSessionAttendanceActions =
    createTypeActions<IFetchCourseSessionAttendancePayload, ICourseSessionAttendant[]>({
        type: FETCH_COURSE_SESSION_ATTENDANCE,
    });

// Course enroll wizard
export function navigateToCourseEnrollWizardStep(payload: ICourseEnrollWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_COURSE_ENROLL_NEW, payload);
}

export function triggerCourseEnrollWizard({ course }: ITriggerCourseEnrollWizardPayload) {
    return navigateToCourseEnrollWizardStep({
        step: getEnrollCourseWizardSteps().firstStepId,
        resetDataEntity: true,
        course,
    });
}

export function changeCourseEnrollWizardStep(step: COURSE_ENROLL_WIZARD_STEP_ID) {
    return navigateToCourseEnrollWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateCourseEnrollWizardEntity(payload: Partial<ICourseEnrollWizardEntity>) {
    return createAction<object>(
        UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY,
        payload,
    );
}

export const skipToCourseEnrollWizardStepActions =
    createTypeActions<ISkipToCourseEnrollmentWizardStepPayload>({
        type: SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
    });

export function resetCourseEnrollWizardEntity() {
    return createAction(RESET_COURSE_ENROLLMENT_WIZARD_ENTITY, {});
}

export function createCourseEnrollWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY,
        payload,
    );
}

// Create course enrollment
export const createCourseEnrollmentActions = createTypeActions({
    type: CREATE_COURSE_ENROLLMENT,
});

// Fetch course possible locations
export const fetchCoursePossibleLocationsActions =
    createTypeActions<IFetchCoursePossibleLocationsPayload, IAddress[]>({
        type: FETCH_COURSE_POSSIBLE_LOCATIONS,
    });

// Fetch course sessions by location
export const fetchCourseSessionsByLocationActions =
    createTypeActions<IFetchCourseSessionsByLocationPayload, ICourseSessionByLocation[]>({
        type: FETCH_COURSE_SESSIONS_BY_LOCATION,
    });

// Fetch certificates
/* export const fetchCertificatesActions = createTypeActions<{}, ICertificate[]>({
    type: FETCH_CERTIFICATES,
});

export const fetchCertificateDetailActions = createTypeActions<IFetchCertificateDetailPayload, ICertificateEmployee[]>({
    type: FETCH_CERTIFICATE_DETAIL,
}); */

// Fetch employee courses
export const fetchEmployeeCoursesActions =
    createTypeActions<IFetchEmployeeCoursesPayload, IEmployeeCourse[]>({
        type: FETCH_EMPLOYEE_COURSES,
    });

// Fetch course external employee
export const fetchCourseExternalEmployeeActions =
    createTypeActions<IFetchCourseExternalEmployeePayload, ICourseExternalEmployee>({
        type: FETCH_COURSE_EXTERNAL_EMPLOYEE,
    });

export const updateCourseExternalEmployeeActions =
    createTypeActions<IUpdateCourseExternalEmployeePayload>({
        type: UPDATE_COURSE_EXTERNAL_EMPLOYEE,
    });

export function createElearningCourseEnrollWizardEntity(payload: IFetchCMSCourseTablePayload) {
    return createAction<IFetchCMSCourseTablePayload>(
        CREATE_ELEARNING_COURSE_ENROLLMENT,
        payload,
    );
}
