import React, { PureComponent } from 'react';
import FloatableTextInputWrapper from '../FloatableTextInputWrapper';
import TextInput from '../../input/TextInput';
import { IFormRenderProps } from '../Form';
import Translate from '../../Translate';
import { ShapeOf } from '../../../../models/ui/form';
import RequiredMarker from '../../input/RequiredMarker';
import FormFieldError from '../FormFieldError';
import { camelCaseToSnakeCase } from '../../../../utils/formatting/formatTranslationKey';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import TooltipWithIcon from '../../widget/TooltipWithIcon';
import Icon from '../../icons/Icon';

interface IFormTextInputProps<FormValues> {
    name: keyof FormValues;
    baseId: string;
    fields: ShapeOf<FormValues>;
    formRenderProps: IFormRenderProps<FormValues>;
    baseTranslationKey: string;
    tooltipTranslationKey?: string;
    markAsRequired?: boolean;
    disabled?: boolean;
    hideValue?: boolean;
    noFloatableWrapper?: boolean;
}

class FormTextInput<FormValues extends object> extends PureComponent<IFormTextInputProps<FormValues>> {

    public render() {
        const {
            formRenderProps, fields, name, baseId, markAsRequired, baseTranslationKey, disabled, hideValue,
            noFloatableWrapper, tooltipTranslationKey,
        } = this.props;
        const { values, errors, handleChange, touched } = formRenderProps;

        const id = `${baseId}-${name}`;
        const fullTranslationKey = `${baseTranslationKey}.${camelCaseToSnakeCase(name as unknown as string)}`;

        const infoIcon = (
            <Icon
                typeName="info"
                circle
            />
        );

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => {
                    if (noFloatableWrapper) {
                        return (
                            <>
                                <TextInput
                                    id={id}
                                    name={fields[name] as unknown as string}
                                    value={hideValue ? '' : values[name] as unknown as string}
                                    onChange={handleChange}
                                    isInvalid={touched[name] && !!errors[name]}
                                    disabled={disabled}
                                    placeholder={translator(fullTranslationKey)}
                                />
                                {touched[name] &&
                                    <FormFieldError
                                        error={errors[name]}
                                        placeholders={{
                                            fieldName: translator(fullTranslationKey),
                                        }}
                                        ignoreTranslationContext
                                    />}
                            </>
                        );
                    }
                    return (
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id={id}
                                name={fields[name] as unknown as string}
                                value={hideValue ? '' : values[name] as unknown as string}
                                onChange={handleChange}
                                isInvalid={touched[name] && !!errors[name]}
                                disabled={disabled}
                            />
                            <label htmlFor={id}>
                                <Translate
                                    msg={fullTranslationKey}
                                />
                                { tooltipTranslationKey && (
                                    <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                                        <Translate
                                            msg={tooltipTranslationKey}
                                        />
                                    </TooltipWithIcon>
                                )}
                                {markAsRequired && <RequiredMarker />}
                            </label>
                            {touched[name] &&
                                <FormFieldError
                                    error={errors[name]}
                                    placeholders={{
                                        fieldName: translator(fullTranslationKey),
                                    }}
                                    ignoreTranslationContext
                                />}
                        </FloatableTextInputWrapper>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }
}

export function createFormTextInput<FormValues extends object>() {
    return FormTextInput as any as new () => FormTextInput<FormValues>;
}
