import { IState, getReducerState, makeAsyncFetchInfoSelector } from '../../index';
import { IReducerState, reducerKey } from './reducer';
import { CompanyOnboardingStatus, ICompany } from '../../../models/admin/company';
import ROUTE_KEYS from '../../../routeKeys';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getCompaniesAsyncField = (state: IState) => reducerState(state).companies;
export const getCompanies = (state: IState) => getCompaniesAsyncField(state).data;
export const getFetchCompaniesAsyncInfo = makeAsyncFetchInfoSelector(getCompaniesAsyncField);

export const getOnboardingRoute = (company: ICompany) => {
    if (hasCustomerNotPaid(company)) {
        return ROUTE_KEYS.R_ONBOARDING_FINISH;
    }

    if (shouldStartWizardNewCustomer(company)) {
        return ROUTE_KEYS.R_ONBOARDING_START;
    }

    return false;
};

export const shouldStartWizardNewCustomer = (company: ICompany) =>
    company.startWizardNewCustomer === CompanyOnboardingStatus.TRUE;
export const hasCustomerNotPaid = (company: ICompany) =>
    (company.statusNotPaid === CompanyOnboardingStatus.TRUE) && !shouldStartWizardNewCustomer(company);
