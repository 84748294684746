import React from 'react';
import './learning-goal-icon.scss';
import Icon from '../../../../common/icons/Icon';

const CLASS_NAME = 'LearningGoalIcon';

export default function LearningGoalIcon({ colour }: {
    colour: string;
}) {
    return (
        <Icon typeName="text-balloon" className={`${CLASS_NAME} ${colour}`} />
    );
}
