import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers, createActionHandler,
} from '../index';
import {
    FETCH_UNREAD_MESSAGES_COUNT,
    FETCH_USER_MESSAGES,
    FETCH_USER_MESSAGE_DETAILS,
    MARK_MESSAGE_AS_READ,
    DELETE_USER_MESSAGE,
    UPDATE_USER_MESSAGE_TYPES,
    FETCH_USER_MESSAGE_TYPES,
} from './types';
import {
    IUnreadMessagesCount,
    IUserMessage,
    IUserMessageDetails,
    IMarkMessageAsReadStatus,
    IMarkMessageAsReadStatusFailedPayload,
    IDeleteUserMessagePayload,
    IMessageType,
} from '../../models/user/inbox';
import ROUTE_KEYS from '../../routeKeys';
import { IAsyncDoField } from '../../models/general/redux';
import { getAsyncDoInitialState } from '../../utils/libs/redux/async/asyncReducerUtils';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INBOX;

export interface IReducerState {
    unreadMessagesCount: IAsyncFetchField<number>;
    userMessages: IAsyncFetchField<IUserMessage[]>;
    userMessageDetails: IAsyncDoField;
    userMessageDetailsList: { [id: number]: IUserMessageDetails };
    markMessageAsRead: IAsyncDoField;
    deleteUserMessage: IAsyncDoField;
    updateUserMessageTypes: IAsyncDoField;
    userMessageTypes: IAsyncFetchField<IMessageType[]>;
}

const initialState: IReducerState = {
    unreadMessagesCount: getAsyncFetchInitialState(),
    userMessages: getAsyncFetchInitialState(),
    userMessageDetails: getAsyncDoInitialState(),
    userMessageDetailsList: {},
    markMessageAsRead: getAsyncDoInitialState(),
    deleteUserMessage: getAsyncDoInitialState(),
    updateUserMessageTypes: getAsyncDoInitialState(),
    userMessageTypes: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<number, IReducerState, IUnreadMessagesCount>({
        baseActionType: FETCH_UNREAD_MESSAGES_COUNT,
        fieldName: 'unreadMessagesCount',
        resetDataOnTrigger: false,
        // mapSuccessPayload: (payload) => payload.count,
        mapSuccessPayload: (payload) => {
            return payload.count;
        },
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IUserMessage[], IReducerState, IUserMessage[]>({
        baseActionType: FETCH_USER_MESSAGES,
        overrideTriggerActionType: [
            FETCH_USER_MESSAGES,
            ROUTE_KEYS.R_MESSAGE_CENTER,
        ],
        fieldName: 'userMessages',
        transformStateOnTrigger: ({ oldState }) => {
            return {
                ...oldState,
                deleteUserMessage: getAsyncDoInitialState(),
            };
        },
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: FETCH_USER_MESSAGE_DETAILS,
        overrideTriggerActionType: [ROUTE_KEYS.R_MESSAGE_CENTER_DETAIL],
        fieldName: 'userMessageDetails',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IUserMessageDetails;
            return {
                ...oldState,
                userMessageDetailsList: {
                    ...oldState.userMessageDetailsList,
                    [successPayload.id]: successPayload,
                },
                userMessages: {
                    ...oldState.userMessages,
                    data: setMessageReadStatusWithinMessages({
                        currentUserMessages: oldState.userMessages.data || [],
                        newStatus: {
                            id: successPayload.id,
                            read: true,
                        },
                    }),
                },
                unreadMessagesCount: lowerUnreadMessagesCountIfSelectedMessageHadStatusUnread({
                    oldState,
                    selectedMessageId: successPayload.id,
                }),
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: MARK_MESSAGE_AS_READ,
        fieldName: 'markMessageAsRead',
        transformStateOnTrigger: ({ oldState, payload }) => {
            const triggerPayload = payload as IMarkMessageAsReadStatus;

            return {
                ...oldState,
                userMessages: {
                    ...oldState.userMessages,
                    data: setMessageReadStatusWithinMessages({
                        currentUserMessages: oldState.userMessages.data || [],
                        newStatus: triggerPayload,
                    }),
                },
                unreadMessagesCount: adjustUnreadMessagesCount({
                    oldCountState: oldState.unreadMessagesCount,
                    read: triggerPayload.read,
                }),
            };
        },
        transformStateOnFail: ({ oldState, payload }) => {
            const failedPayload = payload as IMarkMessageAsReadStatusFailedPayload;

            return {
                ...oldState,
                userMessages: {
                    ...oldState.userMessages,
                    data: setMessageReadStatusWithinMessages({
                        currentUserMessages: oldState.userMessages.data || [],
                        newStatus: {
                            id: failedPayload.messageId,
                            read: failedPayload.read,
                        },
                    }),
                },
                unreadMessagesCount: adjustUnreadMessagesCount({
                    oldCountState: oldState.unreadMessagesCount,
                    read: failedPayload.read,
                }),
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: DELETE_USER_MESSAGE,
        fieldName: 'deleteUserMessage',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IDeleteUserMessagePayload;
            return {
                ...oldState,
                userMessageDetailsList: {
                    ...oldState.userMessageDetailsList,
                    [successPayload.id]: undefined,
                },
                userMessages: {
                    ...oldState.userMessages,
                    data: removeDeletedMessageFromMessages({
                        currentUserMessages: oldState.userMessages.data || [],
                        id: successPayload.id,
                    }),
                },
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_USER_MESSAGE_TYPES,
        fieldName: 'updateUserMessageTypes',
    }),
    ...createAsyncFetchActionHandlers<IMessageType[], IReducerState, IMessageType[]>({
        baseActionType: FETCH_USER_MESSAGE_TYPES,
        overrideTriggerActionType: [ROUTE_KEYS.R_ACCOUNT_SETTINGS, FETCH_USER_MESSAGE_TYPES],
        resetDataOnTrigger: false,
        fieldName: 'userMessageTypes',
        reducerKey,
    }),

};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.LOCAL,
    transformReducerStateForStorage: (reducerState) => {
        return {
            unreadMessagesCount: reducerState.unreadMessagesCount,
        };
    },
    resetStateOnCompanySelection: true,
    resetStateOnSeatSelection: true,
    resetStateOnLocaleSwitch: ({ initialStateClone }) =>
        createActionHandler<IReducerState>(({ oldState }) => {
            return {
                ...initialStateClone,
                // don't reset on locale switch
                unreadMessagesCount: oldState.unreadMessagesCount,
            };
        }),
});

function setMessageReadStatusWithinMessages(
    props: {
        currentUserMessages: IUserMessage[],
        newStatus: IMarkMessageAsReadStatus,
    },
) {
    const { currentUserMessages, newStatus } = props;
    return currentUserMessages.map((message) => {
        if (message.id === newStatus.id) {
            return { ...message, read: newStatus.read } as IUserMessage;
        }
        return message;
    });
}

function removeDeletedMessageFromMessages(
    props: {
        currentUserMessages: IUserMessage[],
        id: string,
    },
) {
    const { currentUserMessages, id } = props;
    return currentUserMessages.filter((message) => message.id !== id);
}

function adjustUnreadMessagesCount({
    oldCountState,
    read,
}: {
    oldCountState: IAsyncFetchField<number>;
    read: boolean;
}) {
    const currentCount = oldCountState.data;

    if (currentCount === null) {
        return oldCountState;
    }

    let adjustedCount = read
        ? currentCount - 1
        : currentCount + 1;
    if (adjustedCount < 0) {
        adjustedCount = 0;
    }

    return {
        ...oldCountState,
        data: adjustedCount,
    };
}

function lowerUnreadMessagesCountIfSelectedMessageHadStatusUnread({
    oldState,
    selectedMessageId,
}: {
    oldState: IReducerState;
    selectedMessageId: string;
}) {
    const currentUserMessages = oldState.userMessages.data || [];
    const selectedMessage = currentUserMessages.find((message) => message.id === selectedMessageId);

    if (!selectedMessage || selectedMessage.read) {
        return oldState.unreadMessagesCount;
    }

    // message is "unread"

    return adjustUnreadMessagesCount({
        oldCountState: oldState.unreadMessagesCount,
        read: true,
    });
}
