export const isDraggableSupported = isDragAndDropSupported();

function isDragAndDropSupported() {
    const div = document.createElement('div');

    // Modernizr way to check if draggable is supported
    if (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) {

        // iOS 10 thinks it supports draggable, but it doesn't
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);

            if (parseInt(v[1], 10) <= 10) {
                return false;
            }
        }
        return true;
    }
    return false;
}
