import React from 'react';
import Icon from '../../../../../../../common/icons/Icon';
import Translate from '../../../../../../../common/Translate';

interface IPublicProps {
    titleTranslationKey: string;
}

export default function CollapsibleDetailTrigger(props: IPublicProps) {
    return (
        <h5>
            <Translate msg={props.titleTranslationKey}/>
            <Icon typeName="chevron-down"/>
        </h5>
    );
}
