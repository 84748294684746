import React, { PureComponent } from 'react';
import { last, pathOr } from 'ramda';
import isArray from '@snipsonian/core/es/is/isArray';

import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import {
    CourseSessionDayStatus,
    ICourse,
    ICourseSession,
    ICourseSessionAttendant,
    ICourseSessionDay,
} from '../../../../../models/documentCenter/courses';
import { DETAIL_PANEL_MAX_LEVEL } from '../../../../../config/components.config';
import exportListDataToCsv from '../../../../../utils/file/csv/exportListDataToCsv';
import { formatCourseLocation } from '../../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import {
    getCourseSessionAttendance,
    getCourseSessionDays,
    getCoursesOverviewDetail,
    getCoursesOverviewDetailByHawAsyncInfo,
    getFetchCourseSessionAsyncInfo,
    getFetchCourseSessionAttendanceAsyncInfo,
    getFetchCourseSessionCertificatesAsyncInfo,
    getFetchCourseSessionDaysAsyncInfo,
    getSelectedCourseSession,
} from '../../../../../redux/documentCenter/courses/selectors';
import { IRenderDetailHeaderProps } from '../../../../common/widget/MasterWithDetail/typings';
import { ITranslator } from '../../../../../models/general/i18n';
import { navigateTo } from '../../../../../redux/location/actions';
import { TIconTypeName } from '../../../../../models/general/icon';
import ROUTE_KEYS from '../../../../../routeKeys';
import { connect } from '../../../../index';
import AddressMapLink from '../../../../common/navigation/AddressMapLink';
import Button from '../../../../common/buttons/Button/index';
import CollapsibleItem from '../../../../common/widget/CollapsibleItem';
import Icon from '../../../../common/icons/Icon';
import ListActionButton from '../../../../common/buttons/ListActionButton';
import ListItem from '../../../../common/list/ListItem';
import Loader from '../../../../common/waiting/Loader/index';
import NoDocumentsFoundErrorDialog from '../../../../common/modals/NoDocumentsFoundErrorDialog';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Translate from '../../../../common/Translate/index';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { isCourseOfTypeOpen } from '../../shared/courseUtils';
import CourseSessionDays from '../../shared/days';
import { BASE_CLASS_NAME } from '../common';
import { formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { getLocale } from '../../../../../redux/i18n/selectors';

const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    courseSession: ICourseSession;
    course: ICourse;
    courseSessionAttendance: ICourseSessionAttendant[];
    courseSessionDays: ICourseSessionDay[];
    navigateToCourseDetail: (nodeId: number) => void;
    courseDetailId: number;
    coursesOverviewDetailByHawAsyncInfo: IAsyncFieldInfo;
    locale: string;
}

interface IContentPublicProps {
    followedCoursesAttendantRouteKey: ROUTE_KEYS;
    extraRoutePayload?: object;
}

class DetailContent extends
    PureComponent<IPrivateProps & IRenderDetailHeaderProps<ICourse> & IContentPublicProps> {

    constructor(props: IPrivateProps & IRenderDetailHeaderProps<ICourse> & IContentPublicProps) {
        super(props);

        this.renderCourseSessionAttendance = this.renderCourseSessionAttendance.bind(this);
    }

    public render() {
        const {
            detailAsyncInfo,
            detailData: selectedCourse,
            courseSession,
            courseSessionAttendance,
            courseSessionDays,
            navigateToCourseDetail,
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo,
            locale,
        } = this.props;

        if (!selectedCourse) {
            return null;
        }

        const attendedEmployees = this.getAttendedEmployees(courseSessionAttendance);
        const noShowEmployees = this.getNoShowEmployees(courseSessionAttendance);
        const isFrench = locale === 'fr_BE';

        const mapAttendedEmployees = (
            attendant: ICourseSessionAttendant,
        ) => {
            const mostRecentCourseSession = attendant.attendedDays[attendant.attendedDays.length - 1];
            const lastDayOfCourse = courseSessionDays[courseSessionDays.length - 1];
            const firstCourseSessionDay = courseSessionDays[0];

            const addIfExists = (value, edittedValue?) => {
                if (
                    value === undefined
                    || value === null
                    || typeof value === 'string' && value === ''
                    || !!value.length && value.length === 0
                ){
                    return ' - ';
                }

                if (!!value && (value !== null || undefined || '') && !!edittedValue) {
                    return edittedValue;
                }

                if (!!value && value !== null || undefined || ''){
                    return value;
                }

                return ' - ';
            };

            const renderStatusName = (
                status: string,
                isFrench?: boolean,
            ) => {
                switch (status) {
                    case CourseSessionDayStatus.SUCCEEDED:
                        if (isFrench) {
                            return 'Réussi';
                        } return 'Geslaagd';
                    case CourseSessionDayStatus.BUSY:
                        if (isFrench) {
                            return 'Formation en cours';
                        } return 'Opleiding bezig';
                    case CourseSessionDayStatus.EVALUATION:
                        if (isFrench) {
                            return 'Évaluation en cours';
                        } return 'Evaluatie bezig';
                    case CourseSessionDayStatus.FAILED:
                        if (isFrench) {
                            return 'Pas réussi';
                        } return 'Niet geslaagd';

                    default:
                        if (isFrench) {
                            return 'Résultat inconnu';
                        } return 'Status onbekend';
                }
            };

            if (!!attendedEmployees){
                return {
                    ...isFrench ?
                        {
                            Formation: selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.courseName)
                            :
                                ' - ',
                        }
                    :
                        {
                            Opleiding: selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.courseName)
                            :
                                ' - ',
                        },
                    Type: selectedCourse !== (undefined || null)
                    ?
                        addIfExists(selectedCourse.courseType)
                    :
                        ' - ',
                    ...isFrench ?
                        {
                            'Nombre de jours': selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.amountDays)
                            :
                                ' - ',
                        }
                    :
                        {
                            'Aantal dagen': selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.amountDays)
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            'Date de début':
                                courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    firstCourseSessionDay.startDate,
                                    formatDateForDisplay(firstCourseSessionDay.startDate),
                                )
                            :
                                ' - ',
                        }
                    :
                        {
                            Startdatum:
                                courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    firstCourseSessionDay.startDate,
                                    formatDateForDisplay(firstCourseSessionDay.startDate),
                                )
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            'Heure de début':
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    firstCourseSessionDay.startDate,
                                    formatTimeOfDateForDisplay(firstCourseSessionDay.startDate),
                                )
                            :
                                ' - ',
                        }
                    :
                        {
                            Startuur:
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    firstCourseSessionDay.startDate,
                                    formatTimeOfDateForDisplay(firstCourseSessionDay.startDate),
                                )
                            :
                                ' - ',
                        },
                    ...isFrench ?
                        {
                            'Date de fin':
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    lastDayOfCourse.endDate,
                                    formatDateForDisplay(lastDayOfCourse.endDate),
                                )
                            :
                                ' - ',
                        }
                    :
                        {
                            Einddatum:
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    lastDayOfCourse.endDate,
                                    formatDateForDisplay(lastDayOfCourse.endDate),
                                )
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            'Heure de fin':
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    lastDayOfCourse.endDate,
                                    formatTimeOfDateForDisplay(lastDayOfCourse.endDate),
                                )
                            :
                                ' - ',
                        }
                    :
                        {
                            Einduur:
                            courseSessionDays.length !== 0
                            ?
                                addIfExists(
                                    lastDayOfCourse.endDate,
                                    formatTimeOfDateForDisplay(lastDayOfCourse.endDate),
                                )
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            Lieu:
                            selectedCourse !== (undefined || null)
                            ?
                                addIfExists(
                                    selectedCourse.address,
                                    formatCourseLocation(selectedCourse.address),
                                )
                            :
                                ' - ',
                        }
                    :
                        {
                            Locatie:
                            selectedCourse !== (undefined || null)
                            ?
                                addIfExists(
                                    selectedCourse.address,
                                    formatCourseLocation(selectedCourse.address),
                                )
                            :
                                ' - ',
                        },
                    ...(isCourseOfTypeOpen(selectedCourse) && {
                        ...isFrench ?
                            {
                                Langue: courseSession !== (undefined || null)
                                ? addIfExists(courseSession.languageCode)
                                : ' - ',
                            }
                        :
                            {
                                Taal: courseSession !== (undefined || null)
                                ? addIfExists(courseSession.languageCode)
                                : ' - ',
                            },
                        }
                    ),

                    ...isFrench ?
                        {
                            "Code de l'entreprise": selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.company.companyCode)
                            :
                                ' - ',
                        }
                    :
                        {
                            Bedrijfscode:
                            selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.company.companyCode)
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            "Nom de l'entreprise":
                            selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.company.name)
                            :
                                ' - ',
                        }
                    :
                        {
                            Bedrijfsnaam:
                            selectedCourse !== (undefined || null)
                            ?
                                addIfExists(selectedCourse.company.name)
                            :
                                ' - ',
                        },

                    ...isFrench ?
                        {
                            Prénom:
                            addIfExists(attendant.firstName),
                        }
                    :
                        {
                            Voornaam:
                            addIfExists(attendant.firstName),
                        },
                    ...isFrench ?
                        {
                            Nom:
                            addIfExists(attendant.name),
                        }
                    :
                        {
                            Naam:
                            addIfExists(attendant.name),
                        },
                    ...isFrench ?
                        {
                            Statut:
                                addIfExists(
                                    mostRecentCourseSession.status,
                                    renderStatusName(mostRecentCourseSession.status, isFrench),
                                ),
                        }
                    :
                        {
                            Status:
                                addIfExists(
                                    mostRecentCourseSession.status,
                                    renderStatusName(mostRecentCourseSession.status, isFrench),
                                ),
                        },
                };
            }
            return [];
        };

        function onExportClicked() {
            const exportItems = attendedEmployees.map(mapAttendedEmployees);

            exportListDataToCsv({
                baseFilename: 'MyMensura-followed-course-attendants-detail',
                listData: exportItems,
            });
        }

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>

                        <Loader show={detailAsyncInfo.status === AsyncStatus.Busy} />
                        <div className={`${BASE_CLASS_NAME}__detail`}>
                            <h6>{translator('document_center.courses.followed.detail.content.days.title')}</h6>
                            <TinyLoader asyncInfoSelector={getFetchCourseSessionDaysAsyncInfo}>
                                <CourseSessionDays />
                            </TinyLoader>
                            {selectedCourse.address && (
                                <>
                                    <h6>{translator('document_center.courses.followed.detail.content.location')}</h6>
                                    <AddressMapLink address={selectedCourse.address} />
                                </>
                            )}
                            {isCourseOfTypeOpen(selectedCourse) && (
                                <>
                                    <h6>{translator('document_center.courses.followed.detail.content.language')}</h6>
                                    <TinyLoader asyncInfoSelector={getFetchCourseSessionAsyncInfo}>
                                        <span>{courseSession.language || EMPTY_PLACEHOLDER}</span>
                                    </TinyLoader>
                                </>
                            )}
                            <TinyLoader
                                asyncInfoSelector={coursesOverviewDetailByHawAsyncInfo}
                                hideErrorOnError
                            >
                                {courseDetailId && (
                                    <Button
                                        id="to-course-detail"
                                        typeName="primary"
                                        onClick={() => navigateToCourseDetail(courseDetailId)}
                                        className={`${BASE_CLASS_NAME}__detail__detail-button`}
                                    >
                                        {translator('document_center.courses.followed.detail.content.course_detail')}
                                    </Button>
                                )}
                            </TinyLoader>
                            <CollapsibleItem
                                initialOpen
                                trigger={(
                                    <>
                                        <h2>
                                            <Translate
                                                msg={'document_center.courses.followed.detail.content.attendees.title'}
                                            />
                                            <Icon typeName="chevron-down" />
                                        </h2>
                                    </>
                                )}
                            >
                                <TinyLoader asyncInfoSelector={getFetchCourseSessionAttendanceAsyncInfo}>
                                    <div className={`${BASE_CLASS_NAME}__attendances`}>
                                        {attendedEmployees.length !== 0 &&
                                            <div className={`${BASE_CLASS_NAME}__attendances--export-button`}>
                                                <ListActionButton
                                                        id="download-selected-followed-course-participants"
                                                        iconTypeName="excel"
                                                        translationKey="Export"
                                                        type="text"
                                                        onClick={onExportClicked}
                                                />
                                            </div>
                                        }
                                        {this.renderCourseSessionAttendance(attendedEmployees, translator)}
                                    </div>
                                </TinyLoader>
                            </CollapsibleItem>
                        </div>
                        <NoDocumentsFoundErrorDialog
                            asyncInfoSelector={getFetchCourseSessionCertificatesAsyncInfo}
                        />
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    private getAttendedEmployees(courseSessionAttendance: ICourseSessionAttendant[]) {
        return courseSessionAttendance.filter((courseSessionAttendant: ICourseSessionAttendant) => {
            return courseSessionAttendant.attendedDays.length > 0;
        });
    }

    private getNoShowEmployees(courseSessionAttendance: ICourseSessionAttendant[]) {
        return courseSessionAttendance.filter((courseSessionAttendant: ICourseSessionAttendant) => {
            return courseSessionAttendant.noShowDays.length > 0 &&
                courseSessionAttendant.attendedDays.length === 0;
        });
    }

    private renderCourseSessionAttendance(
        attendedEmployees: ICourseSessionAttendant[],
        translator: ITranslator,
    ) {
        const {
            courseSession,
            detailData: selectedCourse,
            followedCoursesAttendantRouteKey,
            extraRoutePayload,
            detailLevel,
        } = this.props;

        if (!courseSession) {
            return null;
        }

        return attendedEmployees.map((attendant: ICourseSessionAttendant, index) => {
            return (
                <ListItem
                    key={index}
                    title={formatPersonName(attendant)}
                    text={
                        <>
                            {attendant.company.companyCode} - {attendant.company.name}<br />
                            {renderAttendantStatus(attendant, translator)}
                        </>
                    }
                    arrow={false}
                    to={detailLevel === DETAIL_PANEL_MAX_LEVEL ?
                        undefined :
                        {
                            type: followedCoursesAttendantRouteKey,
                            payload: {
                                ...extraRoutePayload,
                                coursesOrganizedId: selectedCourse.coursesOrganizedId,
                                employeeId: attendant.employeeId,
                            },
                        }}
                />
            );
        });
    }
}

const renderStatus = (iconName: TIconTypeName, translator: ITranslator, translationKey: string) => {
    return (
        <>
            <Icon typeName={iconName} />
            {translator(translationKey)}
        </>
    );
};

export function renderAttendantStatus(
    attendant: ICourseSessionAttendant,
    translator: ITranslator,
) {
    const isNoShow = attendant.noShowDays.length > 0 && attendant.attendedDays.length === 0;

    const attendedDays = pathOr([], ['attendedDays'], attendant);
    const noShowDays = pathOr([], ['noShowDays'], attendant);

    const courseDays = isNoShow ? noShowDays : attendedDays;

    if (isArray(courseDays) && courseDays.length === 0) {
        return renderStatus(
            'broken-link',
            translator,
            'document_center.courses.followed.detail.content.attendees.unknown',
        );
    }

    const status = pathOr('unknown', ['status'], last(courseDays));

    switch (status) {
        case CourseSessionDayStatus.SUCCEEDED:
            return renderStatus(
                'check',
                translator,
                'document_center.courses.followed.detail.content.attendees.succeeded',
            );

        case CourseSessionDayStatus.BUSY:
            return renderStatus(
                'update',
                translator,
                'document_center.courses.followed.detail.content.attendees.busy',
            );

        case CourseSessionDayStatus.EVALUATION:
            return renderStatus(
                'time',
                translator,
                'document_center.courses.followed.detail.content.attendees.evaluation',
            );

        case CourseSessionDayStatus.FAILED:
            return renderStatus(
                'cross',
                translator,
                'document_center.courses.followed.detail.content.attendees.failed',
            );

        default:
            return renderStatus(
                'broken-link',
                translator,
                'document_center.courses.followed.detail.content.attendees.unknown',
            );
    }
}

export default connect<IPrivateProps, IRenderDetailHeaderProps<ICourse> & IContentPublicProps>({
    stateProps: (state) => {
        const coursesOverviewDetailByHawAsyncInfo = getCoursesOverviewDetailByHawAsyncInfo(state);
        const coursesOverviewDetail = getCoursesOverviewDetail(state);
        const courseDetailId = coursesOverviewDetailByHawAsyncInfo.status === AsyncStatus.Success
            && coursesOverviewDetail
            && coursesOverviewDetail.course.nodeId;

        return {
            courseSession: getSelectedCourseSession(state),
            courseSessionAttendance: getCourseSessionAttendance(state),
            courseSessionDays: getCourseSessionDays(state),
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo,
            locale: getLocale(state),
        };
    },
    dispatchProps: (dispatch) => ({
        navigateToCourseDetail: (nodeId: number) => {
            dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                nodeId,
            }));
        },
    }),
})(DetailContent);
