import { getReducerState, IState } from '../IState';
import { IReducerState, reducerKey } from './reducer';
import { makeAsyncFetchInfoSelector } from '..';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getConstantsAsyncField = (state: IState) =>
    reducerState(state).constants;

export const getFetchConstantsAsyncInfo = makeAsyncFetchInfoSelector(getConstantsAsyncField);
