import React, { Component } from 'react';
import './re-integration-details.scss';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import {
    updatePlanMedicalExaminationWizardEntity,
    removeUploadedFileFromReIntegrationEntity,
} from '../../../../../../redux/medicalExamination/actions';
import { IReintegrationManualEntity } from '../../../../../../models/interventions/medicalExaminations';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import FileUploadDropzone from '../../../../../common/widget/FileUploadDropzone';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { IDocument } from '../../../../../../models/general/documents';
import Icon from '../../../../../common/icons/Icon';
import TooltipWithIcon from '../../../../../common/widget/TooltipWithIcon';
import Translate from '../../../../../common/Translate';
import { EXAMINATION_DOCUMENTS_MIME_TYPES } from '../../../../../../config/medicalExamination.config';
import createFileFromBase64 from '../../../../../../utils/file/createFileFromBase64';

interface IPrivateProps {
    wizardEntity: IReintegrationManualEntity;
    updateWizardEntity: (certificate: IDocument) => void;
    removeUploadedFile: () => void;
}

class ReIntegrationDetails extends Component<IPrivateProps & IStepperStepRenderProps> {
    constructor(props) {
        super(props);

        this.onFileDrop = this.onFileDrop.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
    }

    public render() {
        const {
            renderStepButtons, wizardEntity,
        } = this.props;

        const infoIcon = (
            <Icon typeName="info" circle />
        );

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.re_integration_details.title"
                    titlePlaceholders={{
                        tooltip:
                            <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                                <Translate
                                    msg="interventions.medical_examinations.new.steps.re_integration_details.tooltip"
                                />
                            </TooltipWithIcon>,
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, 'ReIntegrationDetails')}>
                    <FileUploadDropzone
                        acceptedMimeTypes={EXAMINATION_DOCUMENTS_MIME_TYPES}
                        onFileDrop={this.onFileDrop}
                        onFileDelete={this.onFileDelete}
                        multiple={false}
                        previousDroppedFiles={wizardEntity.certificate
                            ? [createFileFromBase64(wizardEntity.certificate.data, wizardEntity.certificate.filename)]
                            : []
                        }
                    />
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                onClick: this.onClickNextHandler,
                                disabled: !wizardEntity.certificate,
                            },
                        })}
                    </StickyFooter>
                </div>
            </>
        );
    }

    private onFileDrop(files: File[]) {
        if (files.length > 0) {
            // Read the file content and save it as instance of IDocument.
            files.forEach(file => {
                const reader = new FileReader();

                reader.onload = () => {
                    const fileURL = reader.result;

                    this.props.updateWizardEntity({
                        data: fileURL as string,
                        filename: file.name,
                        size: file.size,
                    });
                };

                reader.readAsDataURL(file);
            });
        }
    }

    private onFileDelete() {
        this.props.removeUploadedFile();
    }

    private onClickNextHandler() {
        this.props.goToNextStep();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            // eslint-disable-next-line max-len
            wizardEntity: getPlanMedicalExaminationWizardEntity<IReintegrationManualEntity>(state) as IReintegrationManualEntity,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (certificate: IDocument) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IReintegrationManualEntity>({
                    certificate,
                }));
            },
            removeUploadedFile: () => {
                dispatch(removeUploadedFileFromReIntegrationEntity<IReintegrationManualEntity>());
            },
        };
    },
})(ReIntegrationDetails);
