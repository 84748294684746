import React, { Component } from 'react';
import { connect } from '../../../../../index';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import PageHeader from '../../../../../appShell/PageHeader';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../common/Translate';
import { fields, schema }
    // eslint-disable-next-line max-len
    from '../../../../../administration/Employees/AddEmployee/steps/NationalRegisterNumber/NationalRegisterNumberSchema';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../../models/general/i18n';
import {
    getEmployeeToAdd,
    getEmployeeToAddInitialDataIsFetching,
    getEmployeeToAddInitialDataError,
    getEmployeeToAddInitialData,
    isEmployeeToAddNotEmpty,
} from '../../../../../../redux/employee/wizard/selectors';
import {
    IEmployeeToAdd,
} from '../../../../../../models/admin/employee';
import { fetchEmployeeToAddInitialData, updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';
import Loader from '../../../../../common/waiting/Loader';
import { ITraceableApiError } from '../../../../../../models/general/error';
import FormError from '../../../../../common/forms/FormError';

type FormValues = Pick<IEmployeeToAdd, 'nationalRegisterNumber' | 'isForeignEmployee'>;

import classNames from 'classnames';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import { NationalRegisterNumberTextInput } from '../../../../../common/input/NationalRegisterNumberInput';
import Checkbox from '../../../../../common/input/Checkbox';

interface IPrivateProps {
    translator: ITranslator;
    currentRegistrationNumber: string;
    fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => void;
    isFetching: boolean;
    isEmployeeToAddNotEmpty: boolean;
    error: ITraceableApiError;
    employeeToAddPersonalData: Partial<IEmployeeToAdd>;
    dispatchNavigateToEmployeeDetail: (nationalRegisterNumber: string) => void;
    proceedWithEmptyEmployeeToAdd: (values: EmployeeDetails) => void;
}

type EmployeeDetails = Pick<IEmployeeToAdd,
            'address' |
            'email' |
            'employeeNumber' |
            'firstName' |
            'languageId' |
            'mobilePhone' |
            'name' |
            'nationalityId' |
            'nationalRegisterNumber' |
            'phone' |
            'sexId' |
            'isForeignEmployee'
        >;

const FORM_NAME = 'interim-national-register-number-form';

class InterimNationalRegisterNumber extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);

        this.onClickFoundEmployeeError = this.onClickFoundEmployeeError.bind(this);
    }

    public componentDidUpdate() {
        const { isEmployeeToAddNotEmpty } = this.props;
        if (isEmployeeToAddNotEmpty) {
            this.props.goToNextStep();
        }
    }

    public render() {
        const {
            translator, currentRegistrationNumber, fetchEmployeeDataByInsz, renderStepButtons, isFetching,
            error, goToNextStep, proceedWithEmptyEmployeeToAdd,
        } = this.props;

        const nationalRegisterErrorMessageFieldName =
            translator('administration.employees.add.steps.national_register_number.field_label');

        const INITIAL_VALUES: FormValues = {
            nationalRegisterNumber: currentRegistrationNumber || '',
            isForeignEmployee: false,
        };

        function handleSubmit(values: FormValues) {
            if (values.isForeignEmployee){
                proceedWithEmptyEmployeeToAdd({
                    address: null,
                    email: null,
                    employeeNumber: null,
                    firstName: null,
                    languageId: null,
                    mobilePhone: null,
                    name: null,
                    nationalityId: null,
                    nationalRegisterNumber: null,
                    phone: null,
                    sexId: null,
                    isForeignEmployee: true,
                });
                goToNextStep();
                return;
            }
            fetchEmployeeDataByInsz(values.nationalRegisterNumber);
        }

        return (
            <>
                <PageHeader
                    title="administration.employees.add.steps.national_register_number.title"
                    text="administration.employees.add.steps.national_register_number.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={({ values, errors, handleChange, touched }: IFormRenderProps<FormValues>) => (
                            <Loader show={isFetching}>
                                <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                    <FloatableTextInputWrapper floatLabel>
                                        <NationalRegisterNumberTextInput
                                            id="nationalRegisterNumber"
                                            name={fields.nationalRegisterNumber}
                                            value={values.nationalRegisterNumber}
                                            onChange={handleChange}
                                            // eslint-disable-next-line max-len
                                            isInvalid={touched.nationalRegisterNumber && !!errors.nationalRegisterNumber}
                                            disableAutoComplete
                                            disabled={values.isForeignEmployee}
                                        />
                                        <label htmlFor="nationalRegisterNumber">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.add.steps.national_register_number.field_label"
                                            />
                                        </label>
                                        {touched.nationalRegisterNumber && (
                                            <FormFieldError
                                                error={errors.nationalRegisterNumber}
                                                placeholders={{ fieldName: nationalRegisterErrorMessageFieldName }}
                                            />
                                        )}
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper>
                                        <Checkbox
                                            name={fields.isForeignEmployee}
                                            onChange={handleChange}
                                            checked={values.isForeignEmployee}
                                            disabled={
                                                typeof values.nationalRegisterNumber === 'string'
                                                && values.nationalRegisterNumber.trim().length > 0
                                            }
                                        >
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.add.steps.national_register_number.checkbox_foreign_employee_info"
                                            />
                                        </Checkbox>
                                    </FloatableTextInputWrapper>
                                    {
                                        error &&
                                        <FormError error={error} />
                                    }
                                </div>
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {renderStepButtons({
                                        nextButton: {
                                            isSubmit: true,
                                            formName: FORM_NAME,
                                            disabled: isFetching,
                                        },
                                    })}
                                </StickyFooter>
                            </Loader>
                        )}
                    />
                </div>
            </>
        );
    }

    private onClickFoundEmployeeError(nationalRegisterNumber: string) {
        this.props.dispatchNavigateToEmployeeDetail(nationalRegisterNumber);
    }

}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);
        return {
            translator: getTranslatorDeprecated(state),
            currentRegistrationNumber: employeeToAdd && employeeToAdd.nationalRegisterNumber,
            isEmployeeToAddNotEmpty: isEmployeeToAddNotEmpty(state),
            employeeToAddPersonalData: getEmployeeToAddInitialData(state),
            isFetching: getEmployeeToAddInitialDataIsFetching(state),
            error: getEmployeeToAddInitialDataError(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => {
                dispatch(fetchEmployeeToAddInitialData({ nationalRegisterNumber, isInterim: true }));
            },
            proceedWithEmptyEmployeeToAdd: (values) => {
                dispatch(updateAddEmployeeData(values));
            },
        };
    },
})(InterimNationalRegisterNumber);
