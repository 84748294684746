import { IMyUserInfo } from '../../../../../models/auth/authentication';
import { IUpdateMyUserAccountInfoPayload } from '../../../../../models/user/userAccount';
import { tryFormattingPhoneInternational } from '../../../../../utils/formatting/formatPhone';

export const mapMyUserInfoToUpdatePayload = (userInfo: IMyUserInfo): IUpdateMyUserAccountInfoPayload => {
    const {
        firstName,
        name,
        titleId,
        email,
        languageId,
        mobilePhoneNumber,
        phoneNumber,
        sexId,
        admin,
    } = userInfo;

    return {
        admin,
        name,
        firstName,
        titleId,
        currentEmail: email,
        newEmail: email,
        languageId,
        phoneNumber: tryFormattingPhoneInternational(phoneNumber),
        mobilePhoneNumber: tryFormattingPhoneInternational(mobilePhoneNumber),
        sexId,
    };
};
