import isArray from '@snipsonian/core/es/is/isArray';
import dayjs from 'dayjs';
import { ICompanySituationHistoryOfOneYear, TCompanySituationHistory } from '../../models/admin/preventionUnits';
import { TCompanyStatisticalCode } from '../../config/company/companyInfo.config';
import { getFieldAscendingComparator } from '../../utils/core/object/sortObjects';
import { NO_RERENDER } from '../index';
import { now } from '../../utils/core/date/getSpecificDate';

export function getLatestStatisticalCode(companySituationHistory: TCompanySituationHistory): TCompanyStatisticalCode {
    if (!isCompanySituationHistoryValid(companySituationHistory)) {
        return null;
    }

    return getLatestCompanySituation(companySituationHistory)
        .statisticalCode;
}

export function doesLatestYearIndicatePreventionUnits(companySituationHistory: TCompanySituationHistory): boolean {
    return doesSituationHistoryIndicate({
        companySituationHistory,
        checkOnlyLatestYear: true,
        expectedCalculatePU: true,
    });
}

export function doesLatestYearIndicateFlatFee(companySituationHistory: TCompanySituationHistory): boolean {
    return doesSituationHistoryIndicate({
        companySituationHistory,
        checkOnlyLatestYear: true,
        expectedCalculatePU: false,
    });
}

export function doesAtLeastOneYearIndicatePreventionUnits(companySituationHistory: TCompanySituationHistory): boolean {
    return doesSituationHistoryIndicate({
        companySituationHistory,
        checkOnlyLatestYear: false,
        expectedCalculatePU: true,
    });
}

export function doesAtLeastOneYearIndicateFlatFee(companySituationHistory: TCompanySituationHistory): boolean {
    return doesSituationHistoryIndicate({
        companySituationHistory,
        checkOnlyLatestYear: false,
        expectedCalculatePU: false,
    });
}

function doesSituationHistoryIndicate({
    companySituationHistory,
    checkOnlyLatestYear,
    expectedCalculatePU,
}: {
    companySituationHistory: TCompanySituationHistory;
    checkOnlyLatestYear: boolean;
    expectedCalculatePU: boolean;
}) {
    if (!isCompanySituationHistoryValid(companySituationHistory)) {
        return false;
    }

    if (checkOnlyLatestYear) {
        return getLatestCompanySituation(companySituationHistory)
            .calculatePU === expectedCalculatePU;
    }

    return companySituationHistory
        .some((companySituationOfOneYear) => companySituationOfOneYear.calculatePU === expectedCalculatePU);
}

export function getPreventionUnitsYearsSortedDescending(companySituationHistory: TCompanySituationHistory) {
    return getYearsSortedDescending({
        companySituationHistory,
        expectedCalculatePU: true,
    });
}

export function getFlatFeeYearsSortedDescending(companySituationHistory: TCompanySituationHistory) {
    return getYearsSortedDescending({
        companySituationHistory,
        expectedCalculatePU: false,
    });
}

function getYearsSortedDescending({
    companySituationHistory,
    expectedCalculatePU,
}: {
    companySituationHistory: TCompanySituationHistory,
    expectedCalculatePU: boolean,
}): number[] {
    if (!isCompanySituationHistoryValid(companySituationHistory)) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return companySituationHistory
        .filter((companySituationOfOneYear) => companySituationOfOneYear.calculatePU === expectedCalculatePU)
        .map((companySituationOfOneYear) => Number(companySituationOfOneYear.year))
        .sort()
        .reverse();
}

export function getLatestCompanySituation(companySituationHistory: TCompanySituationHistory) {
    return companySituationHistory
        .slice()
        .sort(getFieldAscendingComparator<ICompanySituationHistoryOfOneYear>('year'))
        .pop();
}

export function getPreviousYearCompanySituation(companySituationHistory: TCompanySituationHistory) {
    const sortedCompanySituationHistory = companySituationHistory
        .slice()
        .sort(getFieldAscendingComparator<ICompanySituationHistoryOfOneYear>('year'));

    if (sortedCompanySituationHistory.length <= 1) {
        return null;
    }

    return sortedCompanySituationHistory[sortedCompanySituationHistory.length - 2];
}

export function isCompanySituationHistoryValid(companySituationHistory: TCompanySituationHistory) {
    return isArray(companySituationHistory) && companySituationHistory.length > 0;
}

export function shouldPEOverviewBeActive(companySituationHistory: TCompanySituationHistory) {
    const latestCompanySituation = getLatestCompanySituation(companySituationHistory);
    if (!latestCompanySituation) {
        return false;
    }
    return latestCompanySituation.calculatePU;
}

export function shouldPEPreviousYearBeActive(
    companySituationHistory: TCompanySituationHistory,
    showPreviousYearUntilDate: string,
) {
    const previousYearCompanySituation = getPreviousYearCompanySituation(companySituationHistory);
    if (!previousYearCompanySituation) {
        return false;
    }

    if (dayjs(showPreviousYearUntilDate).isBefore(now())) {
        return false;
    }
    return previousYearCompanySituation.calculatePU;
}

export function getLatestCompanySituationYear(companySituationHistory: TCompanySituationHistory): number {
    const latestCompanySituation = companySituationHistory && getLatestCompanySituation(companySituationHistory);
    if (!latestCompanySituation) {
        return null;
    }
    return Number(latestCompanySituation.year);
}

export function getPreviousCompanySituationYear(companySituationHistory: TCompanySituationHistory): number {
    const previousYearCompanySituation =
        companySituationHistory && getPreviousYearCompanySituation(companySituationHistory);

    if (!previousYearCompanySituation) {
        return null;
    }
    return Number(previousYearCompanySituation.year);
}
