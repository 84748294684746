import { createEpic, IParallelCallInput } from '../index';
import { FETCH_ALL_CONSTANTS } from './types';
import { IFetchAllConstants, fetchAllConstantsSucceeded, fetchAllConstantsFailed } from './actions';
import { ConstantType, CONSTANT_TYPES } from '../../models/general/constants';
import { TBackendLocale } from '../../models/general/i18n';
import {
    areConstantValuesAvailableForLang,
    areMessageTypeValuesAvailableForLang,
    areConstantsRefreshedForLang,
    setMultipleConstantValues,
    setMessageTypeValues,
} from '../../config/constants';
import { getBackendLocale } from '../i18n/selectors';
import { IInitAppActionPayload } from '../../models/general/redux';
import { applicationInitializationFailed } from '../ui/form/actions';
import isApplicationInitializationError from '../../utils/api/isApplicationInitializationError';
import { reportInfo } from '../../utils/logging/errorReporter';

// fetchConstantsEpic
createEpic<IFetchAllConstants & IInitAppActionPayload>({
    onActionType: [FETCH_ALL_CONSTANTS],
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const language = action.payload.language || getBackendLocale(getState());

            if (action.payload.fetchMessageTypes) {
                await Promise.all([
                    fetchConstants(CONSTANT_TYPES as ConstantType[], language, { api }),
                    fetchMessageTypes(language, { api }),
                ]);
            } else {
                await fetchConstants(CONSTANT_TYPES as ConstantType[], language, { api });
            }

            dispatch(fetchAllConstantsSucceeded());
        } catch (error) {
            if (isApplicationInitializationError(action.payload, error)) {
                dispatch(applicationInitializationFailed());
            }
            dispatch(fetchAllConstantsFailed(error));
        }
        done();
    },
    latest: true,
});

async function fetchConstants(types: ConstantType[], language: TBackendLocale, { api }: IParallelCallInput) {
    const areValuesAvailable = areConstantValuesAvailableForLang(language);
    try {
        if (!areValuesAvailable || !areConstantsRefreshedForLang(language)) {
            const values = await api.general.constants.fetchConstants(types, language);

            setMultipleConstantValues(language, values);
        }
    } catch (error) {
        if (!areValuesAvailable) {
            throw error;
        } else {
            reportInfo(
                'Refresh of constants failed, but available in cache.',
                { level: 'warning', extra: { error } },
            );
        }
    }
}

async function fetchMessageTypes(language: TBackendLocale, { api }: IParallelCallInput) {
    const areValuesAvailable = areMessageTypeValuesAvailableForLang(language);
    try {
        if (!areValuesAvailable || !areConstantsRefreshedForLang(language)) {
            const messageTypes = await api.user.inbox.fetchMessageTypes();

            setMessageTypeValues(language, messageTypes);
        }
    } catch (error) {
        if (!areValuesAvailable) {
            throw error;
        } else {
            reportInfo(
                'Refresh of message types failed, but available in cache.',
                { level: 'warning', extra: { error } },
            );
        }
    }
}
