import React from 'react';
import { schema } from './interventionRequestRemarksSchema';
import RemarksInput, { IFormValues } from './RemarksInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { getInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/selectors';
import { updateInterventionRequestWizardEntity } from '../../../../../redux/intervention/requestIntervention/actions';

const CLASS_NAME = 'InterventionRequest__Remarks';
const FORM_NAME = 'intervention-request-remarks-form';

const RemarksWizardFormStep = WizardFormStep<IFormValues>();

export default function Remarks(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <RemarksWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.intervention_request_new.steps.remarks.title',
            }}
            showLoaderSelector={() => false}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getInterventionRequestWizardEntity(state);

                    return {
                        remark: (wizardEntity.remarks && wizardEntity.remarks.remark) || '',
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <RemarksInput {...renderProps} />
                    </div>,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => updateInterventionRequestWizardEntity({
                    remarks: values,
                }),
            }}
        />
    );
}
