import { ICmsImage } from '../general/cms';
import { ICompany, ICompanySeat } from '../admin/company';
import { ICompanyAddress, ICompanyContact } from '../admin/companyInfo';
import { IMedicalCenter } from '../admin/employee';

export interface IFluVaccinesOrder {
    id: number;
    address: ICompanyAddress;
    amountVaccinesOrdered: number;
    company: ICompany;
    contact: IFluVaccineContact;
    confirmed: string;
    locationId: number;
    locationDescription: string;
    medicalCenter: IMedicalCenter;
    travelExpenses: string;
    unitPrice: string;
    referentionCode: string;
    remark: string;
    orderDate: string;
    invoiceDate: string;
}

export interface ICreateFluVaccinesOrder {
    address: {
        id: number;
    };
    amountVaccinesOrdered: number;
    company: {
        companyCode: string;
    };
    contact: {
        id: number;
    };
    locationId: number;
    medicalCenter: {
        id: number;
    };
    referentionCode: string;
    remark: string;
}

export interface IUpdateFluVaccinesOrder extends ICreateFluVaccinesOrder {
    id: number; // id of the flu vaccine order
}

export interface IUpdateFluVaccinesOrderActionPayload {
    data: IUpdateFluVaccinesOrder;
    fullOrder: IFluVaccinesOrder; // needed for 'refreshing' the corresponding listItem after the update success
}

export interface IUpdateFluVaccinesOrderPayload {
    companyCode: string; // needed for security check
    showFullFamily: boolean; // needed for security check
    data: IUpdateFluVaccinesOrder;
}

export interface IFluVaccinesConfig {
    id: string;
    description: string;
    startDate: string;
    endDate: string;
    image: ICmsImage;
}

export interface IFluVaccineContact {
    id: number; // = the customerContactId !!!
    name: string;
    firstName: string;
    email: string;
    mobilePhone: string;
    phone: string;
}

export interface IFetchFluVaccinesOrdersPayload {
    companyCode: string;
    showFullFamily: boolean;
}

export enum FLU_VACCINES_ORDER_WIZARD_STEP_ID {
    NR_OF_VACCINES = 'nr-of-vaccines',
    SEAT = 'seat',
    DELIVERY = 'delivery',
    CONTACT = 'contact',
    REMARKS = 'remarks',
    VALIDATE = 'validate',
}

export interface IFluVaccinesOrderWizardPayload {
    step: FLU_VACCINES_ORDER_WIZARD_STEP_ID;
    resetDataEntity: boolean;
}

export interface IFluVaccinesOrderWizardEntity {
    amountVaccines: number;
    seat: ICompanySeat;
    delivery: {
        vaccinationLocationId: number;
        companyAddress?: ICompanyAddress;
        medicalCenter?: IMedicalCenter;
    };
    contact: ICompanyContact;
    remarks: {
        referentionCode: string;
        remark: string;
    };
}

export interface IFluVaccinesOrderWizardData {
    stepId: string;
    entity: Partial<IFluVaccinesOrderWizardEntity>;
}

export interface IFluVaccineTariffs {
    influenzaTariff: number;
    travelCostTariff: number;
}

export interface IFluVaccinesAvailable {
    amount: number;
}
