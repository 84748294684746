import React from 'react';
import './medical-examination.scss';
import classNames from 'classnames';
import { IEventProps } from '../../../TimeGridEvent';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../../../config/calendar.config';
import { formatTimeOfDateForDisplay } from '../../../../../../../utils/formatting/formatTime';

const CLASS_NAME = 'MedicalExaminationAgenda';

function MedicalExamination({
    title, continuesEarlier,
    continuesLater, selected, className,
    event,
}: IEventProps) {
    const config = CALENDAR_EVENT_TYPES_CONFIG[event.type];
    const extraCssClassName = config ? config.cssClassName : '';
    const cssClassName = classNames(CLASS_NAME, 'rbc-event', className, {
        'rbc-selected': selected,
        'rbc-event-continues-earlier': continuesEarlier,
        'rbc-event-continues-later': continuesLater,
        [extraCssClassName]: !!config,
    });

    return (
        <span className={cssClassName}>
            <span className="rbc-event-content">
                {event.start.getTime() !== event.end.getTime() && (
                    <>
                        <strong>
                            {formatTimeOfDateForDisplay(event.start)}
                        </strong>
                        <br />
                    </>
                )}
                {title}
            </span>
        </span>
    );
}

export default MedicalExamination;
