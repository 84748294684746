import isSet from '@snipsonian/core/es/is/isSet';
import isNumber from '@snipsonian/core/es/is/isNumber';
import { IState, getReducerState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../index';
import { IReducerState, reducerKey } from './reducer';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Open invoices count
const getOpenInvoicesCountField = (state: IState) => reducerState(state).openInvoicesCount;

export const getOpenInvoicesCountAsyncInfo = makeAsyncFetchInfoSelector(getOpenInvoicesCountField);

export const getOpenInvoicesCountAsText = (state: IState) => {
    const count = getOpenInvoicesCountField(state).data;
    return (isSet(count) && isNumber(count)) ? `${count}` : '';
};

// Open invoices
const getOpenInvoicesAsyncField = (state: IState) =>
    reducerState(state).openInvoices;

export const areOpenInvoicesAvailable = (state: IState) =>
    getOpenInvoicesAsyncField(state).data !== null;

export const getOpenInvoicesAsyncInfo = makeAsyncFetchInfoSelector(getOpenInvoicesAsyncField);

export const getOpenInvoices = (state: IState) =>
    getOpenInvoicesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Paid invoices
const getPaidInvoicesAsyncField = (state: IState) =>
    reducerState(state).paidInvoices;

export const arePaidInvoicesAvailable = (state: IState) =>
    getPaidInvoicesAsyncField(state).data !== null;

export const getPaidInvoicesAsyncInfo = makeAsyncFetchInfoSelector(getPaidInvoicesAsyncField);

export const getPaidInvoices = (state: IState) =>
    getPaidInvoicesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Invoice document
const getInvoiceDocumentAsyncField = (state: IState) =>
    reducerState(state).invoiceDocument;

export const getInvoiceDocumentAsyncInfo = makeAsyncFetchInfoSelector(getInvoiceDocumentAsyncField);

// Invoice Detail
const getInvoiceDetailAsyncField = (state: IState) =>
    reducerState(state).invoiceDetail;

export const getInvoiceDetailAsyncInfo = makeAsyncFetchInfoSelector(getInvoiceDetailAsyncField);

// Invoice Full
const getInvoiceFullAsyncField = (state: IState) =>
    reducerState(state).invoiceFull;

export const getInvoiceFullAsyncInfo = makeAsyncFetchInfoSelector(getInvoiceFullAsyncField);

// Invoice Parameters
const getInvoiceParametersAsyncField = (state: IState) =>
    reducerState(state).invoiceParameters;

export const getInvoiceParametersAsyncInfo = makeAsyncFetchInfoSelector(getInvoiceParametersAsyncField);

export const getInvoiceParameters = (state: IState) =>
getInvoiceParametersAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
