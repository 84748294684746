import { object } from 'yup';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    remark: 'remark',
};

export const schema = object({
    [fields.remark]: string(),
});
