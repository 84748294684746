import React, { MouseEvent } from 'react';
import { Location } from 'redux-first-router';
import Link, { NavLink, Match } from 'redux-first-router-link';
import ROUTE_KEYS from '../../../../routeKeys';
import { getPath } from '../../../../routes';
import { ILocationAction } from '../../../../models/general/redux';
import connect from '../../../../utils/libs/redux/connect';
import { getQueryParams } from '../../../../redux/location/selectors';
import { ReactNode } from 'react-redux';

interface IComponentProps {
    id: string;
    to: ROUTE_KEYS | ILocationAction<object>;
    className?: string;
    activeClassName?: string;
    isActive?: (match: Match<object>, location: Location) => boolean;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    preserveQuery?: boolean;
    children?: ReactNode;
    shouldDispatch?: boolean;
}

interface IPrivateProps {
    currentRouteQuery?: object;
}

// tslint:disable-next-line:ter-prefer-arrow-callback
const LinkToRoute = React.memo(function LinkToRoute(props: IComponentProps & IPrivateProps) {
    const target = typeof props.to === 'string' ? getPath({ routeKey: props.to }) : props.to;
    let to: typeof target | ILocationAction<{}> = null;

    if (props.preserveQuery) {
        if (typeof target === 'string') {
            to = {
                type: target as ROUTE_KEYS,
                payload: null,
                meta: {
                    location: null,
                    query: props.currentRouteQuery,
                },
            };
        } else {
            const queryForRoute = target.meta && target.meta.query;
            const query = {
                ...props.currentRouteQuery,
                ...queryForRoute,
            };
            to = {
                ...target,
                meta: {
                    location: null,
                    query,
                },
            };
        }
    } else {
        to = target;
    }

    if (props.className || props.activeClassName) {
        return (
            <NavLink
                id={props.id}
                to={to}
                className={props.className}
                activeClassName={props.activeClassName}
                isActive={props.isActive}
                onClick={props.onClick}
                shouldDispatch={props.shouldDispatch}
            >
                {props.children}
            </NavLink>
        );
    }

    return (
        <Link
            to={to}
            onClick={props.onClick}
        >
            {props.children}
        </Link >
    );
});

export default connect({
    stateProps: (state) => {
        return {
            currentRouteQuery: getQueryParams(state),
        };
    },
})(LinkToRoute);
