import React, { MouseEvent } from 'react';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';

interface IPublicProps {
    id: string;
    disabled?: boolean;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function DownloadButton(props: IPublicProps) {
    return (
        <Button
            id={props.id}
            typeName="secondary"
            size="small"
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <Icon typeName="download" />
            <Translate msg="document_center.actions.download_document.button" />
        </Button>
    );
}
