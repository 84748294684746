import initOptionalFields from '@snipsonian/analytics/es/tracker/providers/initOptionalFields';

import {
    IEventCustomData,
    sendCustomDimension,
    sendEventBase,
} from '../../../snipsonian/analytics/src/tracker/providers/gtmTracker';
import { ILocalePayload } from '../../../models/general/i18n';
import { SubmittedFormActionType, VIRTUAL_URL_PREFIX } from '../../../config/analytics.config';
import { TCompanyStatisticalCode } from '../../../config/company/companyInfo.config';

import {
    setCompanyStatisticalCode,
    setCompanyCode,
    getHitScopedCustomDimensions,
} from './hitScopedCustomDimensions';

let prevVirtualPage: string = null;

export function logVirtualPageView({
    virtualPage,
    extraData = {},
}: {
    virtualPage: string;
    extraData?: IEventCustomData;
}) {
    if (virtualPage !== prevVirtualPage) {
        prevVirtualPage = virtualPage;

        sendEventCustom({
            event: 'virtualPageView',
            customData: {
                ...extraData,
                virtualUrl: getVirtualUrl(virtualPage),
            },
        });
    }
}

function getVirtualUrl(virtualPage: string) {
    return VIRTUAL_URL_PREFIX + virtualPage;
}

export function logOutgoingLink({
    outgoingUrl,
    extraData = {},
}: {
    outgoingUrl: string;
    extraData?: IEventCustomData;
}) {
    sendEventCustom({
        event: 'event',
        category: 'outgoing',
        customData: {
            ...extraData,
            linkUrl: outgoingUrl,
        },
    });
}

/**
 * To measure the submission of a form or another important action.
 * Only necessary when there is no unique (virtual) URL on the end of the wizard/funnel or important action.
 */
export function logFormSubmission({
    submittedActionType,
    extraData = {},
}: {
    submittedActionType: SubmittedFormActionType;
    extraData?: IEventCustomData;
}) {
    sendEventCustom({
        event: 'event',
        category: 'form-submit',
        value: submittedActionType,
        customData: extraData,
    });
}

// The logging of each document download is currently OUT OF SCOPE
export function logDocumentDownload({
    pageUrlWhereDownloadTriggered,
    extraData = {},
}: {
    pageUrlWhereDownloadTriggered: string;
    extraData?: IEventCustomData;
}) {
    sendEventCustom({
        event: 'event',
        category: 'download',
        value: pageUrlWhereDownloadTriggered,
        customData: extraData,
    });
}

/**
 * So that all other measurements can be grouped/compared by 'klantensegment'.
 */
export function logCompanyStatisticalCode({
    companyStatisticalCode,
}: {
    companyStatisticalCode: TCompanyStatisticalCode;
}) {
    setCompanyStatisticalCode(companyStatisticalCode);
}

export function logCompanyCode({
    companyCode,
}: {
    companyCode: string;
}) {
    setCompanyCode(companyCode);
}

export function clearCompanyRelatedCustomDimensions() {
    logCompanyCode({ companyCode: null });
    logCompanyStatisticalCode({ companyStatisticalCode: null });
}

/**
 * The user locale (name in GA = 'language') is session-scoped (= value is applied to all hits in a single session).
 *
 * So, if a user would switch the language during his/her user session, then all previously logged events/pages
 * of that session will also get the new language. But this is ok, as in most cases the user will switch the
 * language only one time (during first login).
 */
export function logUserLocale({
    locale,
}: ILocalePayload) {
    sendCustomDimension({
        key: 'language',
        value: locale,
    });
}

function sendEventCustom({
    event = 'event',
    category,
    value,
    customData = {},
}: {
    event?: string;
    category?: string;
    value?: string;
    customData?: IEventCustomData;
}) {
    sendEventBase({
        event,
        customData: {
            ...initOptionalFields({
                eventcategory: category,
                eventvalue: value,
            }),
            ...customData,
            ...getHitScopedCustomDimensions(),
        },
    });
}
