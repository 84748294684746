import { HEADER_NAME } from '../../../../../config/api.config';
import { IGenerateCompanyAndEmployeeReportPayload } from '../../../../../models/general/documents';
import { post } from '../../../../../utils/api/requestWrapper';
import { URL } from '../../documents.const';

export function generateCompanyAndEmployeeReport(payload: IGenerateCompanyAndEmployeeReportPayload) {
    const { companyCode, locale, showFullFamily, ...body } = payload;
    return post({
        url: URL.COMPANY_INFO_REPORT,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
        },
        body,
        headers: {
            [HEADER_NAME.LOCALE]: locale,
        },
    });
}
