import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_COURSES_PLANNED, FETCH_COURSES_PLANNED_DETAIL,
    FETCH_COURSE_SESSION, FETCH_COURSE_SESSION_DAYS,
    FETCH_COURSE_SESSION_ATTENDEES, FETCH_COURSES_SESSION_CERTIFICATES,
    FETCH_COURSES_OVERVIEW, FETCH_COURSES_OVERVIEW_DETAIL,
    FETCH_COURSES_FOLLOWED,
    REMOVE_COURSE_ATTENDEE, SET_REMOVE_ATTENDEE_NOT_POSSIBLE, FETCH_COURSE_SESSION_ATTENDANCE,
    UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY, CREATE_COURSE_ENROLLMENT,
    FETCH_COURSE_POSSIBLE_LOCATIONS, FETCH_COURSE_SESSIONS_BY_LOCATION,
    SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
    RESET_COURSE_ENROLLMENT_WIZARD_ENTITY,
    CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY,
    FETCH_EMPLOYEE_COURSES,
    FETCH_COURSE_EXTERNAL_EMPLOYEE,
    UPDATE_COURSE_EXTERNAL_EMPLOYEE,
    FETCH_COURSE_TABLE,
    FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
    CREATE_ELEARNING_COURSE_ENROLLMENT,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import {
    ICourse, ICourseSession,
    ICourseSessionDay, ICourseSessionAttendee,
    ICourseCategory, ICMSCourseDetail,
    ICourseSessionAttendant,
    ICourseEnrollWizardData,
    ICourseEnrollWizardPayload,
    ICMSCourseAddress,
    ICourseSessionByLocation,
    ICertificate,
    ICertificateEmployee,
    IEmployeeCourse,
    ICourseExternalEmployee,
    ICourseSessionsByMonth,
} from '../../../models/documentCenter/courses';
import { IDocument } from '../../../models/general/documents';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState, createAsyncDoActionHandlers } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';
import { toResetType } from '../../../utils/libs/redux/typeGenerator';
import { getEnrollCourseWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.DOCUMENT_CENTER_COURSES;

export interface IReducerState {
    coursesPlanned: IAsyncFetchField<ICourse[]>;
    courseFollowed: IAsyncFetchField<ICourse[]>;
    selectedPlannedCourse: IAsyncFetchField<ICourse>;
    selectedCourseSession: IAsyncFetchField<ICourseSession>;
    courseSessionDays: IAsyncFetchField<ICourseSessionDay[]>;
    courseSessionAttendees: IAsyncFetchField<ICourseSessionAttendee[]>;
    courseSessionAttendance: IAsyncFetchField<ICourseSessionAttendant[]>;
    courseSessionCertificates: IAsyncFetchField<IDocument>;
    coursesOverview: IAsyncFetchField<ICourseCategory[]>;
    coursesOverviewDetail: IAsyncFetchField<ICMSCourseDetail>;
    coursesOverviewDetailHaw: IAsyncFetchField<{}>;
    courseSessions: IAsyncFetchField<ICourseSessionsByMonth>;
    removeCourseAttendee: IAsyncDoField;
    removeAttendeeNotPossible: boolean;

    skipToCourseEnrollmentWizardStep: IAsyncDoField;

    courseEnrollWizardData: ICourseEnrollWizardData;
    createCourseEnrollment: IAsyncDoField;
    coursePossibleLocations: IAsyncFetchField<ICMSCourseAddress[]>;
    courseSessionsByLocation: IAsyncFetchField<ICourseSessionByLocation[]>;

    certificates: IAsyncFetchField<ICertificate[]>;
    selectedCertificate: IAsyncFetchField<ICertificateEmployee[]>;

    employeeCourses: IAsyncFetchField<IEmployeeCourse[]>;

    courseExternalEmployee: IAsyncFetchField<ICourseExternalEmployee>;
    updateCourseExternalEmployee: IAsyncDoField;
}

const initialState: IReducerState = {
    coursesPlanned: getAsyncFetchInitialState(),
    courseFollowed: getAsyncFetchInitialState(),
    selectedPlannedCourse: getAsyncFetchInitialState(),
    selectedCourseSession: getAsyncFetchInitialState(),
    courseSessionDays: getAsyncFetchInitialState(),
    courseSessionAttendees: getAsyncFetchInitialState(),
    courseSessionAttendance: getAsyncFetchInitialState(),
    courseSessionCertificates: getAsyncFetchInitialState(),
    coursesOverview: getAsyncFetchInitialState(),
    coursesOverviewDetail: getAsyncFetchInitialState(),
    coursesOverviewDetailHaw: getAsyncFetchInitialState(),
    courseSessions: getAsyncFetchInitialState(),
    removeCourseAttendee: getAsyncDoInitialState(),
    removeAttendeeNotPossible: false,

    skipToCourseEnrollmentWizardStep: getAsyncDoInitialState(),

    courseEnrollWizardData: null,
    createCourseEnrollment: getAsyncDoInitialState(),
    coursePossibleLocations: getAsyncFetchInitialState(),
    courseSessionsByLocation: getAsyncFetchInitialState(),

    certificates: getAsyncFetchInitialState(),
    selectedCertificate: getAsyncFetchInitialState(),

    employeeCourses: getAsyncFetchInitialState(),

    courseExternalEmployee: getAsyncFetchInitialState(),
    updateCourseExternalEmployee: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<ICourse[], IReducerState, ICourse[]>({
        baseActionType: FETCH_COURSES_PLANNED,
        fieldName: 'coursesPlanned',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_PLANNED, FETCH_COURSES_PLANNED],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourse, IReducerState, ICourse>({
        baseActionType: FETCH_COURSES_PLANNED_DETAIL,
        fieldName: 'selectedPlannedCourse',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_PLANNED_DETAIL, FETCH_COURSES_PLANNED_DETAIL],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseSession, IReducerState, ICourseSession>({
        baseActionType: FETCH_COURSE_SESSION,
        fieldName: 'selectedCourseSession',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_PLANNED_DETAIL, FETCH_COURSE_SESSION],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseSessionDay[], IReducerState, ICourseSessionDay[]>({
        baseActionType: FETCH_COURSE_SESSION_DAYS,
        fieldName: 'courseSessionDays',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_PLANNED_DETAIL, FETCH_COURSE_SESSION_DAYS],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseSessionAttendee[], IReducerState, ICourseSessionAttendee[]>({
        baseActionType: FETCH_COURSE_SESSION_ATTENDEES,
        fieldName: 'courseSessionAttendees',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_PLANNED_DETAIL, FETCH_COURSE_SESSION_ATTENDEES],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseSessionAttendant[], IReducerState, ICourseSessionAttendant[]>({
        baseActionType: FETCH_COURSE_SESSION_ATTENDANCE,
        fieldName: 'courseSessionAttendance',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL, FETCH_COURSE_SESSION_ATTENDANCE],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourse[], IReducerState, ICourse[]>({
        baseActionType: FETCH_COURSES_FOLLOWED,
        fieldName: 'courseFollowed',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COURSES_FOLLOWED,
            FETCH_COURSES_FOLLOWED,
        ],
        reducerKey,
    }),
    /* ...createAsyncFetchActionHandlers<ICertificate[], IReducerState, ICertificate[]>({
        baseActionType: FETCH_CERTIFICATES,
        fieldName: 'certificates',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COURSES_CERTIFICATES,
            FETCH_CERTIFICATES,
        ],
        reducerKey,
    }), */
    /* ...createAsyncFetchActionHandlers<ICertificateEmployee[], IReducerState, ICertificateEmployee[]>({
        baseActionType: FETCH_CERTIFICATE_DETAIL,
        fieldName: 'selectedCertificate',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_COURSES_CERTIFICATES_DETAIL,
        reducerKey,
    }), */
    ...createAsyncFetchActionHandlers<IDocument, IReducerState, IDocument>({
        baseActionType: FETCH_COURSES_SESSION_CERTIFICATES,
        fieldName: 'courseSessionCertificates',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseCategory[], IReducerState, ICourseCategory[]>({
        baseActionType: FETCH_COURSES_OVERVIEW,
        fieldName: 'coursesOverview',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [ROUTE_KEYS.R_COURSES_OVERVIEW, FETCH_COURSES_OVERVIEW],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICMSCourseDetail, IReducerState, ICMSCourseDetail>({
        baseActionType: FETCH_COURSES_OVERVIEW_DETAIL,
        fieldName: 'coursesOverviewDetail',
        resetDataOnTrigger: true,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COURSES_DETAIL_COURSE,
            ROUTE_KEYS.R_COURSES_DETAIL_INTRO,
            FETCH_COURSES_OVERVIEW_DETAIL,
            FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
        ],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseSessionsByMonth, IReducerState, ICourseSessionsByMonth>({
        baseActionType: FETCH_COURSE_TABLE,
        fieldName: 'courseSessions',
        resetDataOnTrigger: true,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_COURSES_DETAIL_COURSE,
            FETCH_COURSE_TABLE,
            CREATE_ELEARNING_COURSE_ENROLLMENT,
        ],
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_COURSE_ATTENDEE,
        fieldName: 'removeCourseAttendee',
    }),
    [SET_REMOVE_ATTENDEE_NOT_POSSIBLE]:
        createActionHandler<IReducerState, object>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    removeAttendeeNotPossible: true,
                };
            },
        ),
    [toResetType(SET_REMOVE_ATTENDEE_NOT_POSSIBLE)]:
        createActionHandler<IReducerState, object>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    removeAttendeeNotPossible: false,
                };
            },
        ),
    [RESET_COURSE_ENROLLMENT_WIZARD_ENTITY]:
        createActionHandler<IReducerState>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    courseEnrollWizardData: null,
                };
            },
        ),
    [CREATE_COURSE_ENROLLMENT_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    courseEnrollWizardData: {
                        ...oldState.courseEnrollWizardData,
                        entity: {
                            ...payload,
                        },
                    },
                };
            },
        ),
    [ROUTE_KEYS.R_COURSE_ENROLL_NEW]:
        createActionHandler<IReducerState, ICourseEnrollWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    courseEnrollWizardData:
                        (stepId === getEnrollCourseWizardSteps().firstStepId || payload.skipToStep)
                        ? {
                            stepId,
                            course: payload.resetDataEntity || payload.skipToStep ?
                                payload.course : oldState.courseEnrollWizardData.course,
                            entity: payload.resetDataEntity
                                ? {}
                                : (oldState.courseEnrollWizardData && oldState.courseEnrollWizardData.entity) || {},
                        }
                        : {
                            ...oldState.courseEnrollWizardData,
                            stepId,
                        },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
        fieldName: 'skipToCourseEnrollmentWizardStep',
    }),
    [UPDATE_COURSE_ENROLLMENT_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    courseEnrollWizardData: {
                        ...oldState.courseEnrollWizardData,
                        entity: {
                            ...oldState.courseEnrollWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_COURSE_ENROLLMENT,
        fieldName: 'createCourseEnrollment',
    }),
    ...createAsyncFetchActionHandlers<ICMSCourseAddress[], IReducerState, ICMSCourseAddress[]>({
        baseActionType: FETCH_COURSE_POSSIBLE_LOCATIONS,
        fieldName: 'coursePossibleLocations',
        overrideTriggerActionType: [
            FETCH_COURSE_POSSIBLE_LOCATIONS,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICMSCourseDetail, IReducerState, ICMSCourseDetail>({
        baseActionType: FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
        fieldName: 'coursesOverviewDetailHaw',
        overrideTriggerActionType: [
            FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
        ],
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICMSCourseAddress[], IReducerState, ICMSCourseAddress[]>({
        baseActionType: FETCH_COURSE_SESSIONS_BY_LOCATION,
        fieldName: 'courseSessionsByLocation',
        overrideTriggerActionType: [
            FETCH_COURSE_SESSIONS_BY_LOCATION,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeCourse[], IReducerState, IEmployeeCourse[]>({
        baseActionType: FETCH_EMPLOYEE_COURSES,
        fieldName: 'employeeCourses',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICourseExternalEmployee, IReducerState, ICourseExternalEmployee>({
        baseActionType: FETCH_COURSE_EXTERNAL_EMPLOYEE,
        fieldName: 'courseExternalEmployee',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_COURSE_EXTERNAL_EMPLOYEE,
        fieldName: 'updateCourseExternalEmployee',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that course enroll steps are not lost when refreshing
        courseEnrollWizardData: reducerState.courseEnrollWizardData,
    }),
});
