// p.s. do not use a comma as certain typeahead values have a comma inside them (e.g. functions)
const DEFAULT_SEPARATOR = ';';

type TListItemToConcatenate = string | number;

export function concatenateStringList(
    values: TListItemToConcatenate[] = [],
    separator = DEFAULT_SEPARATOR,
): string {
    return values.join(separator);
}

export function separateStringList(
    stringList: string = '',
    separator = DEFAULT_SEPARATOR,
): string[] {
    if (!stringList || stringList.trim() === '') {
        return [];
    }
    return stringList.split(separator);
}

export function addOrRemoveStringListValue(
    stringList: string = '',
    value: string,
    shouldAdd: boolean,
    separator = DEFAULT_SEPARATOR,
): string {
    const list = separateStringList(stringList, separator);
    if (shouldAdd) {
        if (!list.includes(value)) {
            list.push(value);
        }
    } else {
        const index = list.indexOf(value);
        if (index > -1) {
            list.splice(index, 1);
        }
    }
    return concatenateStringList(list, separator);
}

export function removeStringListValueFromObjectProp(
    obj: object,
    stringListPropName: string,
    valueToRemove,
    separator = DEFAULT_SEPARATOR,
) {
    return {
        ...obj,
        [stringListPropName]: addOrRemoveStringListValue(
            obj[stringListPropName],
            valueToRemove,
            false,
            separator,
        ),
    };
}
