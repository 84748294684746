import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import './icon.scss';
import { TIconTypeName, TIconColorType } from '../../../../models/general/icon';

export interface IIconProps {
    className?: string;
    circle?: boolean;
    alt?: string;
    typeName?: TIconTypeName;
    colorType?: TIconColorType;
    onMouseOver?: () => void;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const Icon = (props: IIconProps) => {
    const iconClass = classNames('Icon', {
        // other classes
        circle: props.circle,
        // colorName classes
        'color--success': props.colorType === 'success',
        'color--warning': props.colorType === 'warning',
        'color--danger': props.colorType === 'danger',
        'color--grey': props.colorType === 'grey',
        'color--primary': props.colorType === 'primary',
        'color--secondary': props.colorType === 'secondary',
        'color--grey-dark': props.colorType === 'grey-dark',
        // typeName classes
        'arrow-down': props.typeName === 'arrow-down',
        'arrow-left': props.typeName === 'arrow-left',
        'arrow-right': props.typeName === 'arrow-right',
        'arrow-up': props.typeName === 'arrow-up',
        bell: props.typeName === 'bell',
        'bell-1': props.typeName === 'bell-1',
        'bell-2': props.typeName === 'bell-2',
        bin: props.typeName === 'bin',
        'broken-link': props.typeName === 'broken-link',
        calendar: props.typeName === 'calendar',
        'calendar-date': props.typeName === 'calendar-date',
        certificate: props.typeName === 'certificate',
        check: props.typeName === 'check',
        'chevron-down': props.typeName === 'chevron-down',
        'chevron-left': props.typeName === 'chevron-left',
        'chevron-right': props.typeName === 'chevron-right',
        'chevron-up': props.typeName === 'chevron-up',
        conversation: props.typeName === 'conversation',
        cross: props.typeName === 'cross',
        doc: props.typeName === 'doc',
        download: props.typeName === 'download',
        'download-file': props.typeName === 'download-file',
        euro: props.typeName === 'euro',
        excel: props.typeName === 'excel',
        eye: props.typeName === 'eye',
        financing: props.typeName === 'financing',
        gear: props.typeName === 'gear',
        'graduation-hat': props.typeName === 'graduation-hat',
        home: props.typeName === 'home',
        'home-outline': props.typeName === 'home-outline',
        info: props.typeName === 'info',
        kunstmaan: props.typeName === 'kunstmaan',
        location: props.typeName === 'location',
        message: props.typeName === 'message',
        'paper-clip': props.typeName === 'paper-clip',
        pencil: props.typeName === 'pencil',
        play: props.typeName === 'play',
        plus: props.typeName === 'plus',
        'plus-circle': props.typeName === 'plus-circle',
        shutdown: props.typeName === 'shutdown',
        search: props.typeName === 'search',
        sliders: props.typeName === 'sliders',
        'text-balloon': props.typeName === 'text-balloon',
        'text-balloons': props.typeName === 'text-balloons',
        time: props.typeName === 'time',
        'triangle-left': props.typeName === 'triangle-left',
        'triangle-right': props.typeName === 'triangle-right',
        'triangle-down': props.typeName === 'triangle-down',
        'triangle-up': props.typeName === 'triangle-up',
        'user-outline': props.typeName === 'user-outline',
        update: props.typeName === 'update',
        warning: props.typeName === 'warning',
        'warning-triangle': props.typeName === 'warning-triangle',
    });

    return (
        <i
            className={classNames(iconClass, props.className)}
            title={props.alt}
            onMouseOver={props.onMouseOver}
            onClick={props.onClick}
        />
    );
};

export default Icon;
