import React, { Component } from 'react';
import '../../../shared/plan-exam-tile-select.scss';
import PageHeader from '../../../../../appShell/PageHeader';
import OverlayContent from '../../../../../common/modals/OverlayContent';
import { connect } from '../../../../..';
import {
    getExaminationReasonsAsyncInfo,
    getValidateEmployeeToPlanAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import Loader from '../../../../../common/waiting/Loader';
import { IExaminationReason } from '../../../../../../models/interventions/medicalExaminations';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import Tile from '../../../../../common/widget/Tile';
import TooltipWithIcon from '../../../../../common/widget/TooltipWithIcon';
import Icon from '../../../../../common/icons/Icon';
import stethoscopeSvg from '../../../../../assets/img/icons/stethoscope.svg';
import { IEmployee } from '../../../../../../models/admin/employee';
import { validateEmployeeToPlanActions } from '../../../../../../redux/medicalExamination/actions';
import FormError from '../../../../../common/forms/FormError';
import ValidateEmployeeToPlanDialog from '../ValidateEmployeeToPlanDialog';
import {
    getPlanBufferzoneExaminationReasonsForSelectedBufferzone,
} from '../../../../../../redux/intervention/bufferzones/selectors';

const CLASS_NAME = 'SelectExaminationReasonOverlay';

interface IPublicProps {
    show: boolean;
    onClose: () => void;
    onSelect: (examinationReason: IExaminationReason) => void;
    employeeToValidate?: IEmployee;
}

interface IPrivateProps {
    examinationReasons: IExaminationReason[];
    examinationReasonsAsyncInfo: IAsyncFieldInfo;
    validateEmployeeToPlanAsyncInfo: IAsyncFieldInfo;
    validateSelectedEmployee: (
        employeeToPlan: IEmployee,
        examinationReason: IExaminationReason,
        onValidationPassed: () => void,
    ) => void;
}

interface IState {
    selectedReason: IExaminationReason;
}

class SelectExaminationReasonOverlay extends Component<IPublicProps & IPrivateProps, IState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedReason: null,
        };

        this.onSelectHandler = this.onSelectHandler.bind(this);
        this.onConfirmEmployeeHandler = this.onConfirmEmployeeHandler.bind(this);
    }

    public render() {
        const {
            show, onClose, examinationReasonsAsyncInfo, examinationReasons, validateEmployeeToPlanAsyncInfo,
        } = this.props;

        const title = 'interventions.medical_examinations.new.reasons_title';

        const infoIcon = (
            <Icon
                typeName="info"
                circle
            />
        );

        const showLoader = examinationReasonsAsyncInfo.status === AsyncStatus.Busy
            || validateEmployeeToPlanAsyncInfo.status === AsyncStatus.Busy;

        return (
            <>
                <OverlayContent
                    show={show}
                    onCloseIntent={onClose}
                >
                    <div className={CLASS_NAME}>
                        <Loader show={examinationReasonsAsyncInfo.status} useFullScreen />
                        <PageHeader
                            title={title}
                        />
                        <div className="container">
                            {examinationReasonsAsyncInfo.error &&
                                <ErrorPlaceholder apiError={examinationReasonsAsyncInfo.error} />
                            }
                            {validateEmployeeToPlanAsyncInfo.error &&
                                <FormError error={validateEmployeeToPlanAsyncInfo.error} />
                            }

                            <div className="tiles">
                                <Loader show={showLoader}>
                                    {
                                        examinationReasons.map((reason) => (
                                            <div
                                                className="tile-wrapper"
                                                key={reason.id}
                                            >
                                                <Tile
                                                    title={reason.title}
                                                    svg={reason.regularIcon ? reason.regularIcon.url : stethoscopeSvg}
                                                    svgAsImage={!!reason.regularIcon}
                                                    svgHover={reason.hoverIcon && reason.hoverIcon.url}
                                                    typeName="white"
                                                    onClick={() => this.onSelectHandler(reason)}
                                                    tooltipWithIcon={
                                                        reason.infoText &&
                                                        <TooltipWithIcon
                                                            icon={infoIcon}
                                                            typeName="info-bubble"
                                                        >
                                                            <span>{reason.infoText}</span>
                                                        </TooltipWithIcon>
                                                    }
                                                />
                                            </div>
                                        ))
                                    }
                                </Loader>
                            </div>
                        </div>
                    </div>
                    {show && <ValidateEmployeeToPlanDialog onConfirm={this.onConfirmEmployeeHandler} />}
                </OverlayContent>
            </>
        );
    }

    private onSelectHandler(reason: IExaminationReason) {
        const { employeeToValidate, validateSelectedEmployee, onSelect } = this.props;

        if (employeeToValidate) {
            this.setState({ selectedReason: reason });
            validateSelectedEmployee(employeeToValidate, reason, this.onConfirmEmployeeHandler);
        } else {
            onSelect(reason);
        }
    }

    private onConfirmEmployeeHandler() {
        const { onSelect } = this.props;
        const { selectedReason } = this.state;

        onSelect(selectedReason);

        this.setState({ selectedReason: null });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            examinationReasons: getPlanBufferzoneExaminationReasonsForSelectedBufferzone(state),
            examinationReasonsAsyncInfo: getExaminationReasonsAsyncInfo(state),
            validateEmployeeToPlanAsyncInfo: getValidateEmployeeToPlanAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            validateSelectedEmployee: (
                employeeToPlan: IEmployee,
                examinationReason: IExaminationReason,
                onValidationPassed: () => void,
            ) => {
                dispatch(validateEmployeeToPlanActions.trigger({
                    employeeToPlan,
                    examinationReason,
                    onValidationPassed,
                }));
            },
        };
    },
})(SelectExaminationReasonOverlay);
