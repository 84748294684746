import React, { PureComponent, ReactNode } from 'react';
import Portal from '../../technical/Portal';
import { Popper as ReactPopper, Target } from 'react-popper';
import { Placement, Behavior, Position } from 'popper.js';
import { getZIndex, releaseZIndex, Z_INDEX_TYPE } from '../../../../utils/dom/zIndexManager';
import { POPPER_CONTAINER_ELEMENT_ID } from '../../../../config/dom.config';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import isInViewport from '../../../../utils/dom/isInViewport';
import getOrCreateElementInBodyById from '../../../../utils/dom/getOrCreateElementInBodyById';
import findClosestScrollContainer from '../../../../utils/dom/findClosestScrollContainer';

const POPPER_CONTAINER_ELEMENT = getOrCreateElementInBodyById(POPPER_CONTAINER_ELEMENT_ID);

export interface IPopperRenderProps {
    placement: Placement;
}

interface IPopperProps {
    isOpen: boolean;
    target: HTMLElement | ReactNode;
    targetClassName?: string;
    onClickOutside: () => void;
    onTargetOutsideViewport: () => void;
    placement?: Placement;
    flipBehavior?: Behavior | Position[];
    children: ((props: IPopperRenderProps) => ReactNode) | ReactNode;
    spaceBetweenPopper?: number;
    useTargetWidth?: boolean;
    onPlacementChanged?: (placement: Placement) => void;
}

const DEFAULT_PLACEMENT = 'bottom-start';

class Popper extends PureComponent<IPopperProps> {
    private boundHandleClickOutside: (e) => void;
    private areEventsHandled: boolean = false;
    private isUnmounted: boolean = false;
    private zIndex: number;
    private onWindowResizeDebounced: TDebounced;
    private onScrollDebounced: TDebounced;
    private targetRef: HTMLElement;
    private popperRef: HTMLElement;
    private scrollContainer: HTMLElement | Window;
    private previousPlacement: Placement;
    private placementChangedTimeout: number;

    constructor(props: IPopperProps) {
        super(props);

        this.state = {
            currentPlacement: props.placement || DEFAULT_PLACEMENT,
        };

        // Bind handler so it can be easily removed
        this.boundHandleClickOutside = this.handleClickOutside.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.onScrollDebounced = debounce(this.onScroll, 10);
        this.onWindowResizeDebounced = debounce(this.onWindowResize, 100);
    }

    public render() {
        const {
            isOpen, children, target, placement, spaceBetweenPopper,
            useTargetWidth, flipBehavior, targetClassName, onPlacementChanged,
        } = this.props;

        if (!target) {
            return null;
        }

        const isTargetReactElement = React.isValidElement(target);
        if (!isTargetReactElement) {
            this.targetRef = target as HTMLElement;
        }

        let targetWidth: number;
        if (useTargetWidth && this.targetRef) {
            targetWidth = this.targetRef.offsetWidth;
        }

        const childrenTypedAsFunction = children as ((props: IPopperRenderProps) => ReactNode);

        return (
            <>
                {isTargetReactElement &&
                    <Target
                        component={useTargetWidth ? 'div' : 'span'}
                        className={targetClassName}
                        innerRef={(ref) => (this.targetRef = ref)}
                    >
                        {target}
                    </Target>
                }
                <Portal domNode={POPPER_CONTAINER_ELEMENT}>
                    <ReactPopper
                        innerRef={(ref) => (this.popperRef = ref)}
                        placement={placement || DEFAULT_PLACEMENT}
                        target={!isTargetReactElement ? target : undefined}
                        modifiers={{
                            flip: {
                                behavior: flipBehavior || ['bottom', 'top'],
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'viewport',
                            },
                        }}
                        positionFixed={true}
                    >
                        {({ popperProps: { ref, style, ...otherProps } }) => {
                            const currentPlacement = otherProps['data-placement'];
                            if (
                                this.previousPlacement !== currentPlacement &&
                                typeof onPlacementChanged === 'function'
                            ) {
                                if (this.placementChangedTimeout) {
                                    window.clearTimeout(this.placementChangedTimeout);
                                }

                                this.placementChangedTimeout = window.setTimeout(
                                    () => onPlacementChanged(currentPlacement),
                                    10,
                                );
                            }
                            this.previousPlacement = currentPlacement;

                            const addTopMargin =
                                spaceBetweenPopper && currentPlacement && currentPlacement.indexOf('bottom') === 0;
                            const addBottomMargin =
                                spaceBetweenPopper && currentPlacement && currentPlacement.indexOf('top') === 0;
                            const addLeftMargin =
                                spaceBetweenPopper && currentPlacement && currentPlacement.indexOf('right') === 0;
                            const addRightMargin =
                                spaceBetweenPopper && currentPlacement && currentPlacement.indexOf('left') === 0;
                            const customStyle = {
                                ...style,
                                zIndex: this.zIndex,
                                display: !isOpen && 'none',
                                marginTop: addTopMargin && spaceBetweenPopper,
                                marginBottom: addBottomMargin && spaceBetweenPopper,
                                marginLeft: addLeftMargin && spaceBetweenPopper,
                                marginRight: addRightMargin && spaceBetweenPopper,
                                width: targetWidth,
                            };
                            return (
                                <div style={customStyle} ref={ref} data-placement={currentPlacement}>
                                    {
                                        typeof children === 'function' ?
                                            childrenTypedAsFunction({ placement: currentPlacement }) :
                                            children
                                    }
                                </div>
                            );
                        }}
                    </ReactPopper>
                </Portal>
            </>
        );
    }

    public componentDidMount() {
        this.scrollContainer = findClosestScrollContainer(this.targetRef);

        if (this.props.isOpen) {
            this.setupEventHandlers();
            this.zIndex = getZIndex(Z_INDEX_TYPE.POPPER);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPopperProps) {
        if (nextProps.isOpen && !this.zIndex) {
            this.zIndex = getZIndex(Z_INDEX_TYPE.POPPER);
        } else if (!nextProps.isOpen) {
            releaseZIndex(this.zIndex);
            this.zIndex = null;
        }
    }

    public componentDidUpdate(prevProps: IPopperProps) {
        if (!this.scrollContainer) {
            this.scrollContainer = findClosestScrollContainer(this.targetRef);
        }

        if (this.props.isOpen && !prevProps.isOpen) {
            this.setupEventHandlers();
        } else if (!this.props.isOpen) {
            this.removeEventHandlers();
        }
    }

    public componentWillUnmount() {
        this.removeEventHandlers();
        releaseZIndex(this.zIndex);
        this.onWindowResizeDebounced.cancel();
        this.onScrollDebounced.cancel();
        this.isUnmounted = true;
        if (this.placementChangedTimeout) {
            window.clearTimeout(this.placementChangedTimeout);
        }
    }

    private handleClickOutside(e: Event) {
        if (this.isUnmounted) {
            return;
        }

        // Do nothing if clicking in the popper
        if (this.popperRef && this.popperRef.contains(e.target as HTMLElement)) {
            return;
        }

        // Execute parent component handler if outside of popper
        const { onClickOutside } = this.props;
        if (typeof onClickOutside === 'function') {
            e.preventDefault();
            onClickOutside();
        }
    }

    private setupEventHandlers() {
        if (!this.areEventsHandled) {
            document.addEventListener('click', this.boundHandleClickOutside);
            window.addEventListener('resize', this.onWindowResizeDebounced);
            if (this.scrollContainer) {
                this.scrollContainer.addEventListener('scroll', this.onScrollDebounced);
            }
            this.areEventsHandled = true;
        }
    }

    private removeEventHandlers() {
        if (this.areEventsHandled) {
            document.removeEventListener('click', this.boundHandleClickOutside);
            window.removeEventListener('resize', this.onWindowResizeDebounced);
            if (this.scrollContainer) {
                this.scrollContainer.removeEventListener('scroll', this.onScrollDebounced);
            }
            this.areEventsHandled = false;
        }
    }

    private onWindowResize() {
        this.forceUpdate();
    }

    private onScroll() {
        const { onTargetOutsideViewport, isOpen } = this.props;
        if (
            isOpen && typeof onTargetOutsideViewport === 'function' &&
            !isInViewport(this.targetRef, this.scrollContainer)
        ) {
            onTargetOutsideViewport();
        }
    }
}

export default Popper;
