import { IStepperStep } from '../../models/general/stepper';

export function isPreviousAllowedFromThisStep(stepId: string, steps: IStepperStep[], activeStepId: string) {
    const stepIdOrder = steps.map((step) => step.id);

    const indexOfStepId = stepIdOrder.indexOf(stepId);
    const indexOfActiveStepId = stepIdOrder.indexOf(activeStepId);

    const stepIdsThatDoNotAllowPrevious = steps
        .filter((step, index) => {
            if (index <= indexOfActiveStepId) {
                return step.doNotAllowPreviousFromThisStep;
            }
            return false;
        })
        .map((step) => step.id);

    const lastStepIdThatDoesNotAllowPrevious = stepIdsThatDoNotAllowPrevious[stepIdsThatDoNotAllowPrevious.length - 1];

    const indexOfLastStepIdThatDoesNotAllowPrevious = !!lastStepIdThatDoesNotAllowPrevious ?
        stepIdOrder.indexOf(lastStepIdThatDoesNotAllowPrevious) : undefined;

    return !(indexOfLastStepIdThatDoesNotAllowPrevious &&
        (indexOfActiveStepId >= indexOfLastStepIdThatDoesNotAllowPrevious) &&
        (indexOfStepId < indexOfLastStepIdThatDoesNotAllowPrevious));
}
