import classNames from 'classnames';
import React, { Component } from 'react';

import { fields, schema } from './NationalRegisterNumberSchema';

import Checkbox from '../../../../../common/input/Checkbox';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import FormError from '../../../../../common/forms/FormError';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import PageHeader from '../../../../../appShell/PageHeader';
import Loader from '../../../../../common/waiting/Loader';
import Translate from '../../../../../common/Translate';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { connect } from '../../../../../index';
import { NationalRegisterNumberTextInput } from '../../../../../common/input/NationalRegisterNumberInput';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

import ROUTE_KEYS from '../../../../../../routeKeys';
import { fetchEmployeeToAddInitialData, updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';
import {
    getEmployeeToAdd,
    getEmployeeToAddInitialDataIsFetching,
    getEmployeeToAddInitialDataError,
    isEmployeeToAddNotEmpty,
    getEmployeeToAddInitialData,
} from '../../../../../../redux/employee/wizard/selectors';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import {
    IEmployeeToAdd,
    IFetchEmployeesPayload,
} from '../../../../../../models/admin/employee';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { ITraceableApiError } from '../../../../../../models/general/error';
import { ITranslator } from '../../../../../../models/general/i18n';
import { navigateTo } from '../../../../../../redux/location/actions';

type FormValues = Pick<IEmployeeToAdd, 'nationalRegisterNumber'> & {
    isForeignEmployee: boolean,
};

type EmployeeDetails = Pick<IEmployeeToAdd,
    'address'
    | 'email'
    | 'employeeNumber'
    | 'firstName'
    | 'languageId'
    | 'mobilePhone'
    | 'name'
    | 'nationalityId'
    | 'nationalRegisterNumber'
    | 'phone'
    | 'sexId'
    | 'isForeignEmployee'
>;

interface IPrivateProps {
    translator: ITranslator;
    currentRegistrationNumber: string;
    fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => void;
    isEmployeeToAddNotEmpty: boolean;
    isFetching: boolean;
    error: ITraceableApiError;
    employeeToAddInitialData: Partial<IEmployeeToAdd>;
    dispatchNavigateToEmployeeDetail: (nationalRegisterNumber: string) => void;
    proceedAsForeignEmployee: (values: EmployeeDetails) => void;
}

const FORM_NAME = 'nationalRegisterNumberForm';

class NationalRegisterNumber extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);

        this.onClickFoundEmployeeError = this.onClickFoundEmployeeError.bind(this);
    }

    public componentDidUpdate() {
        const { isEmployeeToAddNotEmpty } = this.props;
        if (isEmployeeToAddNotEmpty) {
            this.props.goToNextStep();
        }
    }

    public render() {
        const {
            currentRegistrationNumber,
            employeeToAddInitialData,
            error,
            fetchEmployeeDataByInsz,
            goToNextStep,
            isFetching,
            proceedAsForeignEmployee,
            renderStepButtons,
            translator,
        } = this.props;

        const nationalRegisterErrorMessageFieldName =
            translator('administration.employees.add.steps.national_register_number.field_label');

        const INITIAL_VALUES: FormValues = {
            nationalRegisterNumber: currentRegistrationNumber || '',
            isForeignEmployee: false,
        };

        // When a user adds a foreign (non-belgian) employee, we won't fetch
        // employee data by INSZ(national register number).
        function handleSubmit(values: FormValues) {
            if (values.isForeignEmployee) {
                proceedAsForeignEmployee({
                    address: null,
                    email: null,
                    employeeNumber: null,
                    firstName: null,
                    languageId: null,
                    mobilePhone: null,
                    name: null,
                    nationalityId: null,
                    nationalRegisterNumber: null,
                    phone: null,
                    sexId: null,
                    isForeignEmployee: true,
                });
                goToNextStep();
                return;
            }
            fetchEmployeeDataByInsz(values.nationalRegisterNumber);
        }

        return (
            <>
                <PageHeader
                    title="administration.employees.add.steps.national_register_number.title"
                    text="administration.employees.add.steps.national_register_number.text"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={({ values, errors, handleChange, touched }: IFormRenderProps<FormValues>) => (
                            <Loader show={isFetching}>
                                <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                                    <FloatableTextInputWrapper floatLabel>
                                        <NationalRegisterNumberTextInput
                                            id="nationalRegisterNumber"
                                            name={fields.nationalRegisterNumber}
                                            value={values.nationalRegisterNumber}
                                            onChange={handleChange}
                                            isInvalid={touched.nationalRegisterNumber
                                                && !!errors.nationalRegisterNumber
                                                && !!values.isForeignEmployee}
                                            disabled={values.isForeignEmployee}
                                        />
                                        <label htmlFor="nationalRegisterNumber">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.add.steps.national_register_number.field_label"
                                            />
                                        </label>
                                        {touched.nationalRegisterNumber && (
                                            <FormFieldError
                                                error={errors.nationalRegisterNumber}
                                                placeholders={{ fieldName: nationalRegisterErrorMessageFieldName }}
                                            />
                                        )}
                                        {
                                            error &&
                                            <FormError error={error} />
                                        }
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper>
                                        <Checkbox
                                            name={fields.isForeignEmployee}
                                            onChange={handleChange}
                                            checked={values.isForeignEmployee}
                                            disabled={
                                                typeof values.nationalRegisterNumber === 'string'
                                                && values.nationalRegisterNumber.trim().length > 0
                                            }
                                        >
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.add.steps.national_register_number.checkbox_foreign_employee_info"
                                            />
                                        </Checkbox>
                                    </FloatableTextInputWrapper>
                                    {
                                        employeeToAddInitialData &&
                                        <FormError
                                            // eslint-disable-next-line max-len
                                            translationKey="administration.employees.add.steps.national_register_number.employee_found_error"
                                            placeHolders={{
                                                linkTo:
                                                    <a
                                                        // eslint-disable-next-line max-len
                                                        onClick={() => this.onClickFoundEmployeeError(employeeToAddInitialData.nationalRegisterNumber)}
                                                    >
                                                        <span className="placeholderLink">
                                                            {/* eslint-disable-next-line max-len */}
                                                            <Translate msg="administration.employees.add.steps.national_register_number.employee_found_error_link_to" />
                                                        </span>
                                                    </a>,
                                            }}
                                        />
                                    }
                                </div>
                                <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                    {renderStepButtons({
                                        nextButton: {
                                            isSubmit: true,
                                            formName: FORM_NAME,
                                            disabled: isFetching,
                                        },
                                    })}
                                </StickyFooter>
                            </Loader>
                        )}
                    />
                </div>
            </>
        );
    }

    private onClickFoundEmployeeError(nationalRegisterNumber: string) {
        this.props.dispatchNavigateToEmployeeDetail(nationalRegisterNumber);
    }

}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);
        return {
            translator: getTranslatorDeprecated(state),
            currentRegistrationNumber: employeeToAdd && employeeToAdd.nationalRegisterNumber,
            isEmployeeToAddNotEmpty: isEmployeeToAddNotEmpty(state),
            employeeToAddInitialData: getEmployeeToAddInitialData(state),
            isFetching: getEmployeeToAddInitialDataIsFetching(state),
            error: getEmployeeToAddInitialDataError(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            // fetches employee by insz, only continues the flow when NOT found, else shows redirect to employeeDetails
            fetchEmployeeDataByInsz: (nationalRegisterNumber: string) => {
                dispatch(fetchEmployeeToAddInitialData({ nationalRegisterNumber }));
            },
            dispatchNavigateToEmployeeDetail: (nationalRegisterNumber: string) => {
                dispatch(navigateTo(
                    ROUTE_KEYS.R_EMPLOYEES,
                    {},
                    { search: nationalRegisterNumber } as IFetchEmployeesPayload,
                ));
            },
            proceedAsForeignEmployee: (values: EmployeeDetails) => {
                dispatch(updateAddEmployeeData(values));
            },

        };
    },
})(NationalRegisterNumber);
