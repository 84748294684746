import isSet from '@snipsonian/core/es/is/isSet';

const DECIMAL_SEPARATOR = ',';

export function formatFloat(float: number, numbersAfterComma?: number, stripTrailingZeros?: boolean) {
    if (!isSet(float) || Number.isNaN(Number(float))) {
        return '-';
    }

    const formattedString = pointToDecimalSeparator(float.toFixed(numbersAfterComma || 2));

    if (!stripTrailingZeros) {
        return formattedString;
    }

    return formattedString.replace(/0+$/, '').replace(/[,]$/, '');
}

export function pointToDecimalSeparator(input, decimalSeparator = DECIMAL_SEPARATOR) {
    if (typeof input === 'undefined') {
        return '';
    }
    return `${input}`.replace(/\./g, decimalSeparator);
}
