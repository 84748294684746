import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export function parsePhoneNumber(phone: string) {
    return parsePhoneNumberFromString(phone, 'BE');
}

export function tryFormattingPhoneInternational(phone: string) {
    const parsedPhone = phone && parsePhoneNumber(phone);

    return parsedPhone ? parsedPhone.formatInternational() : phone;
}

// This is a separate function so we can change the backend format later.
export function tryFormattingPhoneForBackend(phone: string) {
    return tryFormattingPhoneInternational(phone);
}
