import React from 'react';

import { ISortedColumn, ListColumns, SortOrder, SortType } from '../../../../../models/general/list';
import Translate from '../../../../common/Translate';

import { IColumnNames } from './ReservedMedicalExaminationOverview.type';


export const TRANSLATION_PREFIX = 'interventions.medical_examinations.bufferzones';
export const BASE_NAME = 'bufferzones';
export const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

export const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Ascending,
};

export const COLUMNS: ListColumns<IColumnNames> = {
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 36,
    },
    locationId: {
        hide: true,
        percentWidth: null,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem) => listItem.columns.dateSort,
        percentWidth: 18,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.time`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    freeSlots: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.free_slots`} />,
        sortable: true,
        sortType: SortType.Number,
        sortValue: (listItem) => listItem.columns.freeSlots,
        percentWidth: null,
        minWidth: 175,
        align: 'right',
    },
    totalSlots: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        percentWidth: 6,
        sortable: false,
    },
    endTime: {
        percentWidth: null,
        sortable: false,
    },
};
