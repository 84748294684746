/**
 * Needed because just creating an event on the default way like
 *   window.dispatchEvent(new Event('resize'))
 * will result in a "TypeError: Object doesn't support this action" in IE 11.
 *
 * See https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
 */

interface IEventOptions {
    bubbles?: boolean;
    cancelable?: boolean;
}

export default function createEvent(
    eventType: string,
    {
        bubbles = false,
        cancelable = false,
    }: IEventOptions = {},
) {
    const newEvent = document.createEvent('Event');
    newEvent.initEvent(eventType, bubbles, cancelable);
    return newEvent;
}
