import React, { PureComponent } from 'react';
import './calendar-toolbar.scss';
import { ToolbarProps } from 'react-big-calendar';
import Button from '../../../buttons/Button';
import Icon from '../../../icons/Icon';
import Translate from '../../../Translate';
import getWeekNumber from '../../../../../utils/core/date/getWeekNumber';
import Sticky from '../../../technical/Sticky';
import {
    getMonday, dayOffsetFromDate,
    getDateWithoutTime, getFirstWorkday,
} from '../../../../../utils/core/date/getSpecificDate';
import { isSmallScreen } from '../../../../../utils/dom/screenSize';
import DatePicker from '../../DateTimePicker/DatePicker';
import { ICustomDayStyle } from '../../DateTimePicker/DatePicker/customDayPlugin';
import debounce, { TDebounced } from '../../../../../utils/core/debounce';

export interface ICalendarToolbarProps extends ToolbarProps {
    hideTodayButton?: boolean;
    hideViewToggle?: boolean;
    selectedDate: string;
    minDate?: string;
    maxDate?: string;
    inlineDatePicker?: boolean;
    isExtraSmallScreen?: boolean;
    customDayStyles: ICustomDayStyle[];
}

const CLASS_NAME = 'CalendarToolbar';

class Toolbar extends PureComponent<ICalendarToolbarProps> {
    public static defaultProps = {
        hideTodayButton: false,
    };

    private onSelectedDateChangedDebounced: TDebounced<[string]>;
    private onMonthChangeDebounced: TDebounced<[number, number]>;
    private onYearChangeDebounced: TDebounced<[number, number]>;

    constructor(props: ICalendarToolbarProps) {
        super(props);

        this.onSelectedDateChangedDebounced = debounce<[string]>(
            (formattedDate) => {
                if (formattedDate) {
                    props.onNavigate('DATE', new Date(formattedDate));
                }
            },
            1000,
        );
        this.onMonthChangeDebounced = debounce<[number, number]>(
            (month, year) => {
                const firstWorkDay = getFirstWorkday(month, year);
                if (firstWorkDay) {
                    props.onNavigate('DATE', firstWorkDay);
                }
            },
            1000,
        );
        this.onYearChangeDebounced = debounce<[number, number]>(
            (month, year) => {
                const firstWorkDay = getFirstWorkday(month, year);

                if (firstWorkDay) {
                    props.onNavigate('DATE', firstWorkDay);
                }
            },
            1000,
        );
    }

    public render() {
        const {
            localizer: { messages }, label,
            onNavigate, date, children,
            hideTodayButton, minDate, maxDate,
            selectedDate, hideViewToggle,
            onView, view, inlineDatePicker,
            isExtraSmallScreen, customDayStyles,
        } = this.props;

        const startOfWeek = getDateWithoutTime(getMonday(selectedDate || new Date()));
        const endOfWeek = getDateWithoutTime(dayOffsetFromDate(startOfWeek, 7).toDate());
        const minDateObj = minDate && getDateWithoutTime(minDate);
        const maxDateObj = maxDate && getDateWithoutTime(maxDate);
        const disablePrevButton = minDate
            ? startOfWeek.getTime() <= minDateObj.getTime() && endOfWeek.getTime() >= minDateObj.getTime()
            : false;
        const disableNextButton = maxDate
            ? startOfWeek.getTime() <= maxDateObj.getTime() && endOfWeek.getTime() >= maxDateObj.getTime()
            : false;
        const topOffset = isSmallScreen() ? 10 : 60;

        return (
            <Sticky id="CalendarToolbar" className={CLASS_NAME} topOffset={topOffset}>
                <span className={`${CLASS_NAME}__btn-group`}>
                    {!hideTodayButton &&
                        <Button
                            id="toolbar-today-button"
                            onClick={() => onNavigate('DATE', new Date())}
                            typeName="grey"
                        >
                            {messages.today}
                        </Button>
                    }
                    <div className={`${CLASS_NAME}__btn-group ${CLASS_NAME}__btn-group--date-nav`}>
                        <Button
                            id="toolbar-prev-button"
                            onClick={() => onNavigate('PREV')}
                            typeName="grey"
                            disabled={disablePrevButton}
                        >
                            <Icon typeName="chevron-left" />
                        </Button>
                        <Button
                            id="toolbar-next-button"
                            onClick={() => onNavigate('NEXT')}
                            typeName="grey"
                            disabled={disableNextButton}
                        >
                            <Icon typeName="chevron-right" />
                        </Button>
                    </div>
                </span>

                {inlineDatePicker ? (
                    <div className={`${CLASS_NAME}__datepicker`}>
                        <DatePicker
                            id="toolbar-change-agenda-date"
                            value={selectedDate}
                            onChange={this.onSelectedDateChangedDebounced}
                            onMonthChange={this.onMonthChangeDebounced}
                            onYearChange={this.onYearChangeDebounced}
                            inlineCalendar={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            highlightWeek={!isExtraSmallScreen}
                            customDayStyles={customDayStyles}
                            zIndex="99" // One less than the loader
                        />
                    </div>
                ) : (
                        <span className={`${CLASS_NAME}__label`}>
                            <strong>
                                <Translate
                                    msg="common.calendar.week_number"
                                    placeholders={{ number: getWeekNumber(date) }}
                                />{': '}
                            </strong>
                            {label}
                        </span>
                    )}

                {children}

                {!hideViewToggle && (
                    <div className={`${CLASS_NAME}__right`}>
                        <div className={`${CLASS_NAME}__toggle-buttons`}>
                            <Button
                                id="toolbar-calendar-view-button"
                                onClick={() => onView('week')}
                                typeName="grey"
                                size="small"
                                disabled={view !== 'agenda'}
                                // eslint-disable-next-line max-len
                                className={`${CLASS_NAME}__toggle-buttons__button ${CLASS_NAME}__toggle-buttons__button--calendar`}
                            >
                                <span>
                                    <span />
                                    <span />
                                    <span />
                                </span>
                                <Translate msg="common.calendar.views.calendar" />
                            </Button>
                            <Button
                                id="toolbar-list-view-button"
                                onClick={() => onView('agenda')}
                                typeName="grey"
                                size="small"
                                disabled={view === 'agenda'}
                                // eslint-disable-next-line max-len
                                className={`${CLASS_NAME}__toggle-buttons__button ${CLASS_NAME}__toggle-buttons__button--list`}
                            >
                                <span>
                                    <span />
                                    <span />
                                    <span />
                                </span>
                                <Translate msg="common.calendar.views.list" />
                            </Button>
                        </div>
                    </div>
                )}
            </Sticky>
        );
    }

    public componentWillUnmount() {
        this.onSelectedDateChangedDebounced.cancel();
        this.onMonthChangeDebounced.cancel();
        this.onYearChangeDebounced.cancel();
    }
}

export default Toolbar;
