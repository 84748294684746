import React from 'react';
import logo from '../../../assets/img/general/logo--white.svg';
import './login-header.scss';
import InlineSVG from '../../../common/icons/InlineSVG';

export default function LoginHeader() {
    return (
        <header className="LoginHeader">
            <InlineSVG svg={logo} />
        </header>
    );
}
