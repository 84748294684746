import replacePlaceholders from '@snipsonian/core/es/string/replacePlaceholders';
import { LOCALES, Locales } from '../../config/i18n.config';
import { ITranslator } from '../../models/general/i18n';
import { getMessage } from './translations';

/**
 * To avoid un-necessary re-renders, we want that the 'getTranslator' selector
 * keeps returning the same translator function as much as possible (vs. generating
 * a new lambda/function each time the selector gets called).
 * Therefor we here generate all possible translators (1 per locale + 1 for when the
 * user wants to see the translation keys) at startup + return one of those pre-generated
 * translators depending on the current locale (from the redux state).
 */

const SHOW_MSG_KEY_TRANSLATOR = (input) => {
    const { msg } = getInputParts(input);

    return msg;
};

const LOCALE_TRANSLATORS = LOCALES
    .reduce(
        (translatorAccumulator, locale) => {
            translatorAccumulator[locale] = (input) => {
                const { msg, placeholders } = getInputParts(input);

                const translation = getMessage({ locale, msgKey: msg });

                if (!translation) {
                    return msg;
                }

                return replacePlaceholders({ placeholders, msg: translation });
            };
            return translatorAccumulator;
        },
        {},
    );

function getInputParts(input) {
    const { msg, placeholders = {} } = typeof input === 'string' ? { msg: input } : input;
    return {
        msg,
        placeholders,
    };
}

export function getPreGeneratedTranslator(locale: Locales, showTranslationIds: boolean): ITranslator {
    if (showTranslationIds) {
        return SHOW_MSG_KEY_TRANSLATOR;
    }

    return LOCALE_TRANSLATORS[locale];
}
