import { IState, ICookieConsentState, IBaseCookiesConfiguration } from './types';
import { getCookieConsentReducerKey, getCookieConsentInitialState } from './reducerBuildingBlocks';

const reducerState =
    <CConfig extends IBaseCookiesConfiguration>(state: IState<CConfig>) => {
        return state[getCookieConsentReducerKey()] as unknown as ICookieConsentState<CConfig>
            || getCookieConsentInitialState<CConfig>();
    };

export const getCurrentCookieConsentVersion = (state: IState) => reducerState(state).version;
export const getCurrentCookieConsentConfiguration =
    <CConfig extends IBaseCookiesConfiguration>(state: IState<CConfig>): CConfig =>
        reducerState<CConfig>(state).cookies;

// will be always be filled in if we posted to the server ... will become an empty object if no metadata is specified
export const hasCookieConsentCookie = (state: IState) => reducerState(state).version !== undefined;
