import {
    DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../../../api/interventions/medicalExaminations/reserved/medicalExaminationsReserved.const';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { formatLocationAddress } from '../../../../../utils/formatting/formatAddress';
import {
    formatStartEndTimeOfDatesForDisplay,
    formatTimeOfDateForDisplay,
} from '../../../../../utils/formatting/formatTime';
import { ListItem } from '../../../../../models/general/list';
import { IReservedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/reserved';
import { separateStringList } from '../../../../../utils/core/string/separatedStringList';
import { createGenericActiveFilters } from '../../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IClientSideFilterOfListDataProps,
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
} from '../../../../common/widget/MasterWithDetail/typings';

import { IColumnNames, IFilterValues } from './ReservedMedicalExaminationOverview.type';
import { DEFAULT_NR_OF_RECORDS_TO_SHOW } from './ReservedMedicalExaminationOverview.const';

export const toListId = (reservedExamination: IReservedMedicalExamination) => {
    return reservedExamination.id;
};

export const mapReservedMedicalExaminationsToListItems =
    (reservedExaminations: IReservedMedicalExamination[]) => reservedExaminations.map(
    (reservedExamination: IReservedMedicalExamination) => {
        const location = formatLocationAddress(reservedExamination.location, true);

        const listItem: ListItem<IColumnNames> = {
            id: reservedExamination.id,
            columns: {
                actions: null,
                date: formatDateInLongFormat(reservedExamination.activity.start),
                dateSort: reservedExamination.activity.start as string,
                endTime: reservedExamination.activity.end as string,
                freeSlots: reservedExamination.availability.plannableTimeSlots,
                location,
                locationId: location,
                seat: reservedExamination.branch.name,
                seatCode: reservedExamination.branch.code,
                time: formatStartEndTimeOfDatesForDisplay(
                    reservedExamination.activity.start,
                    reservedExamination.activity.end,
                ),
                totalSlots: reservedExamination.availability.totalTimeSlots,
            },
            data: reservedExamination,
        };
        return listItem;
});

export const mapListRowForExport = (reservedExamination: IReservedMedicalExamination) => {
    const { activity, availability, location, branch } = reservedExamination;

    return {
        company: {
            companyCode: branch.code,
            name: branch.name,
        },
        medicalCenter: {
            name: location.name,
            address: {
                city: location.address.city,
                postcode: location.address.zipCode,
                street: location.address.street,
            },
            freeSlots: availability.plannableTimeSlots,
            totalSlots: availability.totalTimeSlots,
        },
        date: formatDateInLongFormat(activity.start),
        startTime: formatTimeOfDateForDisplay(activity.start),
        endTime: formatTimeOfDateForDisplay(activity.end),
    };
};

export const transformFilterValuesToActiveFilters = (
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) => {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.bufferzones.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            locationIds: {
                show: true,
                translationKeySuffixOverride: 'location',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'location',
                        searchColumnName: 'locationId',
                    },
                },
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'seat',
                        searchColumnName: 'seatCode',
                    },
                },
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
            },
        },
    });
};

export const getDefaultQueryParams = ({ isShowAll }: { isShowAll: boolean }) => {
    return isShowAll ? {
        ...DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_RESERVED_MEDICAL_EXAMINATIONS_FILTERS;
};



export const shouldRenderShowAllButton = (
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
): boolean => {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
};

export const clientSideFilterOfListData = (
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) => {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.locationIds)) {
                return true;
            }

            const locationIds = separateStringList(filterValues.locationIds);
            return locationIds.includes(listItem.columns.locationId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seatCodes)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.seatCodes);
            return reasonIds.includes(listItem.columns.seatCode.toString());
        });
};
