import { IFetchCompanyVisitDocumentsPayload } from '../../../../../models/interventions/company-visits';
import { createEpic } from '../../../../index';
import { getLocationState } from '../../../../location/selectors';
import { FETCH_COMPANY_VISIT_DOCUMENTS } from '../../types';
import { fetchCompanyVisitDocumentsFailed, fetchCompanyVisitDocumentsSucceeded } from '../../actions';

createEpic<IFetchCompanyVisitDocumentsPayload>({
    onActionType: FETCH_COMPANY_VISIT_DOCUMENTS,
    async processReturn({ api, action, getState }) {
        try {
            const documents = await api.interventions.companyVisits.fetchCompanyVisitDocuments(action.payload);

            const files = await api.general.documents.fetchDocuments(
                { ids: documents.map(document => document.id) },
                { routeKeyForErrorLogging: getLocationState(getState()).type },
            );

            return fetchCompanyVisitDocumentsSucceeded(files);
        } catch (error) {
            return fetchCompanyVisitDocumentsFailed(error);
        }
    },
    latest: false,
});
