import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './detail-item.scss';

interface IDetailItemProps {
    children: ReactNode;
    noBorder?: boolean;
}

export default function DetailItem(props: IDetailItemProps) {
    return (
        <div className={classNames('DetailItem', { ['no-border']: props.noBorder })}>
            {props.children}
        </div>
    );
}
