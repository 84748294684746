import React, { Component } from 'react';
import { connect } from '../../../../../index';
import {
    CompanyAvailabilityType,
    ICompanyAvailabilities,
} from '../../../../../../models/admin/companyInfo';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import { ITranslator } from '../../../../../../models/general/i18n';
import { IState } from '../../../../../../redux/IState';
import {
    getReplaceCompanyMedExamAvailabilitiesAsyncInfo,
    getReplaceCompanyRiskMgmtAvailabilitiesAsyncInfo,
} from '../../../../../../redux/company/info/selectors';
import {
    replaceCompanyAvailabilitiesReset,
    replaceCompanyAvailabilities,
} from '../../../../../../redux/company/info/actions';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { hasRequiredAccessLevels } from '../../../../../../redux/auth/selectors';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import AvailabilityOverview, {
    ISaveAvailabilitiesProps,
} from '../../../../../administration/CompanyInfo/shared/AvailabilityOverview';
import {
    getOnboardingSeatAvailabilitiesByCompanyCode,
    getOnboardingWizardEntity,
    getOnboardingSeatAvailabilitiesAsyncInfo,
} from '../../../../../../redux/onboarding/selectors';
import { updateOnboardingWizardEntity } from '../../../../../../redux/onboarding/actions';
import { makeSureBothAvailabilityPeriodsAreThere } from '../../../../../../api/admin/companyInfo.api';

const CLASS_NAME = 'Availabilities';

interface IPublicProps {
    type: CompanyAvailabilityType;
    companyCode: string;
    companyName: string;
    copiedAvailabilities?: boolean;
}

interface IPrivateProps {
    availabilities: ICompanyAvailabilities;
    availabilitiesRiskManagement: ICompanyAvailabilities;
    availabilitiesAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    companyName: string;
    isCopyToAllSeatsAllowed: boolean;
    mayEdit: boolean;
    saveAsyncInfo: IAsyncFieldInfo;
    translator: ITranslator;
    saveAvailabilities: (saveProps: ISaveAvailabilitiesProps) => void;
    resetSaveAvailabilities: () => void;
    updateWizardEntity: (saveProps: ISaveAvailabilitiesProps) => void;
}

interface IComponentState {
    availabilitiesToSave: ISaveAvailabilitiesProps;
    shouldUpdateWizardEntityOnSuccess: boolean;
}

class Availabilities extends Component<IPrivateProps & IPublicProps, IComponentState> {

    constructor(props: IPrivateProps & IPublicProps) {
        super(props);

        this.state = {
            availabilitiesToSave: {
                applyFullFamily: false,
                availabilities: this.props.availabilities,
            },
            shouldUpdateWizardEntityOnSuccess: false,
        };

        this.onSave = this.onSave.bind(this);
        this.resetStateToInitial = this.resetStateToInitial.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IPublicProps) {
        if ((prevProps.saveAsyncInfo.status === AsyncStatus.Busy &&
            this.props.saveAsyncInfo.status === AsyncStatus.Success) &&
            this.state.shouldUpdateWizardEntityOnSuccess
        ) {
            this.props.updateWizardEntity(this.state.availabilitiesToSave);
            this.resetStateToInitial();
        }

        if (prevProps.saveAsyncInfo.status === AsyncStatus.Busy &&
            this.props.saveAsyncInfo.status === AsyncStatus.Error
        ) {
            this.resetStateToInitial();
        }
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                <TinyLoader
                    asyncInfoSelector={this.props.availabilitiesAsyncInfoSelector}
                >
                    <AvailabilityOverview
                        id={this.props.companyCode}
                        availabilityType={this.props.type}
                        availabilities={this.props.copiedAvailabilities
                            ? this.props.availabilitiesRiskManagement
                            : this.props.availabilities}
                        companyName={this.props.companyName}
                        isCopyToAllSeatsAllowed={this.props.isCopyToAllSeatsAllowed}
                        mayEdit={this.props.mayEdit && !this.props.copiedAvailabilities}
                        saveAsyncInfo={this.props.saveAsyncInfo}
                        translator={this.props.translator}
                        onSaveAvailabilities={this.onSave}
                        resetSaveAvailabilities={this.props.resetSaveAvailabilities}
                    />
                </TinyLoader>
            </div>
        );
    }

    private onSave(saveProps: ISaveAvailabilitiesProps) {
        saveProps.availabilities = makeSureBothAvailabilityPeriodsAreThere(saveProps.availabilities);

        this.setState({ availabilitiesToSave: saveProps, shouldUpdateWizardEntityOnSuccess: true });
        this.props.saveAvailabilities(saveProps);
    }

    private resetStateToInitial() {
        this.setState({
            availabilitiesToSave: {
                applyFullFamily: false,
                availabilities: this.props.availabilities,
            },
            shouldUpdateWizardEntityOnSuccess: false,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        const {
            type: availabilityType,
            companyCode,
            companyName,
        } = publicProps;

        const seats = getOnboardingWizardEntity(state).seatsData;

        return (state) => {
            return {
                availabilities: getOnboardingSeatAvailabilitiesByCompanyCode(state, availabilityType, companyCode),
                availabilitiesRiskManagement: getOnboardingSeatAvailabilitiesByCompanyCode(
                    state, CompanyAvailabilityType.RiskManagement, companyCode,
                ),
                availabilitiesAsyncInfoSelector: getOnboardingSeatAvailabilitiesAsyncInfo,
                companyName,
                isCopyToAllSeatsAllowed: seats && seats.length > 1,
                mayEdit: hasRequiredAccessLevels(state, { company: 'W' }),
                saveAsyncInfo: availabilityType === CompanyAvailabilityType.MedicalExaminations ?
                    getReplaceCompanyMedExamAvailabilitiesAsyncInfo(state) :
                    getReplaceCompanyRiskMgmtAvailabilitiesAsyncInfo(state),
                translator: getTranslatorDeprecated(state),
            };
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                updateWizardEntity: (saveProps: ISaveAvailabilitiesProps) => {
                    const entity = getOnboardingWizardEntity(getState());
                    if (publicProps.type === CompanyAvailabilityType.MedicalExaminations) {
                        if (saveProps.applyFullFamily) {
                            dispatch(updateOnboardingWizardEntity({
                                medicalExaminationsAvailabilities: mapAvailabilitiesToAllSeats(
                                    saveProps.availabilities,
                                    entity.medicalExaminationsAvailabilities,
                                ),
                            }));
                        } else {
                            dispatch(updateOnboardingWizardEntity({
                                medicalExaminationsAvailabilities: {
                                    ...entity.medicalExaminationsAvailabilities,
                                    [publicProps.companyCode]: saveProps.availabilities,
                                },
                            }));
                        }
                    } else {
                        if (saveProps.applyFullFamily) {
                            dispatch(updateOnboardingWizardEntity({
                                riskManagementAvailabilities: mapAvailabilitiesToAllSeats(
                                    saveProps.availabilities,
                                    entity.riskManagementAvailabilities,
                                ),
                            }));
                        } else {
                            dispatch(updateOnboardingWizardEntity({
                                riskManagementAvailabilities: {
                                    ...entity.riskManagementAvailabilities,
                                    [publicProps.companyCode]: saveProps.availabilities,
                                },
                            }));
                        }
                    }
                },
                saveAvailabilities: (saveProps: ISaveAvailabilitiesProps) => {
                    dispatch(replaceCompanyAvailabilities({
                        availabilityType: publicProps.type,
                        payload: {
                            ...saveProps,
                            companyCode: publicProps.companyCode,
                        },
                    }));
                },
                resetSaveAvailabilities: () => {
                    dispatch(replaceCompanyAvailabilitiesReset(publicProps.type));
                },
            };
        };
    },
})(Availabilities);

function mapAvailabilitiesToAllSeats(
    availabilities: ICompanyAvailabilities,
    currentAvailabilities: { [companyCode: string]: ICompanyAvailabilities },
): { [companyCode: string]: ICompanyAvailabilities } {
    return Object.keys(currentAvailabilities)
        .reduce(
            (accumulator, key) => {
                accumulator[key] = availabilities;
                return accumulator;
            },
            {},
        );
}
