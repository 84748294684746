import * as Yup from 'yup';
import {
    ShapeOf,
    IStartEndHourFilterValues,
} from '../../../../models/ui/form';
import { hourPeriodValidation } from '../../../../utils/libs/yup/period';

export const fields: ShapeOf<IStartEndHourFilterValues, string> = {
    startHour: 'startHour',
    endHour: 'endHour',
};

export const startEndHourSchema = Yup.object({
    [fields.startHour]: Yup.string().required(),
    [fields.endHour]: hourPeriodValidation(Yup.ref(fields.startHour)).required(),
});
