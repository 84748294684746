import { IRequestConfig } from '../../utils/api/requestWrapper';
import { localeToBffLocale } from '../../utils/formatting/formatLocale';
import { API_URL, HEADER_NAME } from '../api.config';
import { Locales } from '../i18n.config';

interface IBffDefaultApiConfig extends Partial<IRequestConfig<any>> {
    locale?: Locales;
}

export const bffDefaultApiConfig = ({
    locale,
    headers,
    addAuthorizationHeader = true,
    addCorrelationIdHeader = true,
}: IBffDefaultApiConfig): Partial<IRequestConfig<any>> => {
    return {
        api: API_URL.BFF,
        headers: {
            ...!!locale && {
                [HEADER_NAME.ACCEPT_LANGUAGE]: localeToBffLocale(locale),
            },
            ...!!headers && {
                ...headers,
            },
        },
        addAuthorizationHeader,
        addCorrelationIdHeader,
    };
};
