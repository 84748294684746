import React, { MouseEvent, ReactNode } from 'react';
import './list-item-button.scss';
import Button from '../Button';
import Icon from '../../icons/Icon';

interface IListItemButtonButtonProps {
    id: string;
    children: ReactNode;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export default function ListItemButton(props: IListItemButtonButtonProps) {
    return (
        <Button
            id={props.id}
            className={'ListItemButton'}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
            {!props.disabled &&
                <Icon typeName={'cross'} />
            }
        </Button>
    );
}
