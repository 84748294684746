import { LocationState, Location } from 'redux-first-router';
import ROUTE_KEYS from '../../routeKeys';
import { REDUCER_KEYS } from '../../config/redux.config';

export interface IReducerState extends LocationState {
    type: ROUTE_KEYS;
    prev: IPrevLocation;
}

export interface IPrevLocation extends Location {
    type: ROUTE_KEYS;
}

export const reducerKey = REDUCER_KEYS.LOCATION;
