import React, { ChangeEvent } from 'react';
import './event-type-filter.scss';
import { CalendarEventType } from '../../../../../models/ui/calendar';
import Checkbox from '../../../input/Checkbox';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../config/calendar.config';
import Translate from '../../../Translate';

const CLASS_NAME = 'EventTypeFilter';

interface IEventTypeFilterProps {
    eventTypesIncludedInFilter: CalendarEventType[];
    onFilterChange: (newSelectedEventTypes: CalendarEventType[]) => void;
}

export default function EventTypeFilter(props: IEventTypeFilterProps) {
    const { eventTypesIncludedInFilter } = props;

    const checkboxes = Object.values(CalendarEventType)
        .filter((type) => !!CALENDAR_EVENT_TYPES_CONFIG[type].filterTranslationKey)
        .map((type: CalendarEventType) => {
            const { cssClassName, filterTranslationKey, subTypes } = CALENDAR_EVENT_TYPES_CONFIG[type];
            return (
                <Checkbox
                    key={type}
                    name={type}
                    checked={eventTypesIncludedInFilter.includes(type)}
                    onChange={(e) => onEventTypeFilterChange(e, props)}
                    className={cssClassName}
                >
                    <Translate msg={filterTranslationKey} />
                    {Array.isArray(subTypes) && subTypes.map((subFilter, index) => {
                        return (
                            <div key={`${type}-${index}`} className={subFilter.cssClassName}>
                                <span />
                                <Translate msg={subFilter.translationKey} />
                            </div>
                        );
                    })}
                </Checkbox>
            );
        });

    return (
        <div className={CLASS_NAME}>
            {checkboxes}
        </div>
    );
}

function onEventTypeFilterChange(e: ChangeEvent<HTMLInputElement>, props: IEventTypeFilterProps) {
    const type = e.target.name as CalendarEventType;
    const { eventTypesIncludedInFilter } = props;
    const subTypes = CALENDAR_EVENT_TYPES_CONFIG[type].subTypes;
    const newSelectedEventTypes = eventTypesIncludedInFilter.slice();
    if (e.target.checked) {
        newSelectedEventTypes.push(type);
        if (Array.isArray(subTypes)) {
            newSelectedEventTypes.push(...subTypes.map((item) => item.type));
        }
    } else {
        const index = newSelectedEventTypes.indexOf(type);
        if (index !== -1) {
            newSelectedEventTypes.splice(index, 1);
        }
        if (Array.isArray(subTypes)) {
            subTypes.forEach((subType) => {
                const subTypeIndex = newSelectedEventTypes.indexOf(subType.type);
                if (subTypeIndex !== -1) {
                    newSelectedEventTypes.splice(subTypeIndex, 1);
                }
            });
        }
    }
    props.onFilterChange(newSelectedEventTypes);
}
