import React, { ReactNode } from 'react';
import './tiny-loader.scss';
import { connect } from '../../../index';
import ShowAfterDelay from '../ShowAfterDelay';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { IState } from '../../../../redux/IState';
import tinyLoaderAnimation from '../../../assets/img/animated/tiny-loader.gif';
import ErrorPlaceholder from '../../error/ErrorPlaceholder';

const TIMEOUT_BEFORE_SHOW_IN_MILLIS = 100;
const TIMEOUT_MIN_DURATION_IN_MILLIS = 500;

const DuringDelay = (
    <div className="TinyLoader">
        <div className="content">
            <img src={tinyLoaderAnimation} />
        </div>
    </div>
);

type TAsyncInfoSelector = (state: IState) => IAsyncFieldInfo;

interface IPublicProps extends React.Props<{}> {
    asyncInfoSelector: TAsyncInfoSelector | IAsyncFieldInfo;
    showContentOnInitialState?: boolean;
    errorPlaceholderTranslationKey?: string;
    children?: ReactNode;
    hideErrorOnError?: boolean;
}

interface IPrivateProps {
    asyncInfo: IAsyncFieldInfo;
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsDeprecated: (state, publicProps) => {
        const asyncInfo = typeof publicProps.asyncInfoSelector === 'function'
            ? publicProps.asyncInfoSelector(state)
            : publicProps.asyncInfoSelector;

        return {
            asyncInfo,
        };
    },
})(TinyLoader);

function TinyLoader(props: IPrivateProps & IPublicProps) {
    const { asyncInfo, children, hideErrorOnError } = props;

    if (asyncInfo.status === AsyncStatus.Error) {
        if (hideErrorOnError) {
            return null;
        }

        return (
            <ErrorPlaceholder
                typeName="inline"
                apiError={asyncInfo.error}
                translationKey={props.errorPlaceholderTranslationKey}
            />
        );
    }

    if (!props.showContentOnInitialState && asyncInfo.status === AsyncStatus.Initial) {
        return null;
    }

    return (
        <ShowAfterDelay
            enabled={asyncInfo.status === AsyncStatus.Busy}
            delayBeforeShow={TIMEOUT_BEFORE_SHOW_IN_MILLIS}
            minDurationToShow={TIMEOUT_MIN_DURATION_IN_MILLIS}
            showDuringDelayComponent={DuringDelay}
        >
            {children}
        </ShowAfterDelay>
    );
}
