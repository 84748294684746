import {
    AdminType,
    IAddUserAccountApiPayload,
    IRemoveUserAccountPayload,
    IUpdateUserAccountConfigPayload,
    IUpdateUserAccountInfoPayload,
    IUserAccount,
    IUserAccountDetail,
} from '../../models/user/userAccount';
import { bffDefaultApiConfig } from '../../config/api/bffApiParameters.config';
import { DEFAULT_LANGUAGE_ID } from '../../config/user.config';
import { get, post, put, remove } from '../../utils/api/requestWrapper';
import { Locales } from '../../config/i18n.config';
import { numberAdminTypeToStringType } from '../../utils/formatting/formatAdminType';
import { ONE_SECOND } from '../../utils/core/time/periodsInMillis';

export const URL = {
    USERS: '/companies/{companyCode}/users',
    USER: '/companies/{companyCode}/users/{username}',
    USER_UPDATE: '/companies/{companyCode}/users/{username}',
    ADMINISTRATORS: '/companies/{companyCode}/administrators',
    PERMISSIONS_UPDATE: '/companies/{companyCode}/user-permissions/{username}',

    CUSTOMER_USER: '/customeruser/profile/{emailAddress}',
    CUSTOMER_USER_CREATE: '/customeruser/profile',
    CUSTOMER_USER_REMOVE: '/customeruser/profile/{emailAddress}/customer/{customerId}',
};

export function fetchCompanyUsers(companyCode: string, showFullFamily: boolean) {
    return get<IUserAccount[]>({
        url: URL.USERS,
        pathParams: { companyCode },
        queryParams: { showFullFamily },
        mapResponse: (data) => data['users'].map((user) => ({
            ...user,
            accessLowerRanks: !!user.accessLowerRanks,
            active: !!user.active,
            superUser: !!user.superUser,
        })),
    });
}

export function fetchCompanyUser(companyCode: string, username: string) {
    return get<IUserAccountDetail>({
        url: URL.USER,
        pathParams: { companyCode, username },
        timeoutInMillis: 60 * ONE_SECOND,
        mapResponse: (user): IUserAccountDetail => {
            return {
                ...user,
                accessLowerRanks: !!user.accessLowerRanks,
                active: !!user.active,
                superUser: !!user.superUser,
            };
        },
    });
}

export function updateUserInfo(payload: IUpdateUserAccountInfoPayload, locale: Locales) {
    const { name, mobilePhoneNumber, currentEmail, newEmail, admin, sexId, titleId, ...body } = payload;

    return put({
        ...bffDefaultApiConfig({ locale }),
        url: URL.CUSTOMER_USER,
        pathParams: {
            emailAddress: payload.currentEmail,
        },
        body: {
            ...body,
            lastName: payload.name,
            mobilePhone: payload.mobilePhoneNumber,
            email: newEmail,
            administratorType: numberAdminTypeToStringType(payload.admin),
            sexId,
            titleId,
            ...(sexId === 0) && {
                sexId: null,
            },
            ...(titleId === 0) && {
                titleId: null,
            },
        },
    });
}

export function updateUserPermissions(companyCode: string, payload: IUpdateUserAccountConfigPayload) {
    if (payload.admin !== AdminType.None) {
        delete payload.seats;
    }
    delete payload.admin; // BFF Rest will handle admin type

    return put({
        url: URL.PERMISSIONS_UPDATE,
        pathParams: {
            companyCode,
            username: payload.username,
        },
        body: {
            ...payload,
        },
    });
}

export function createCompanyUser(payload: IAddUserAccountApiPayload, locale: Locales) {
    const { languageId, admin, name, mobilePhoneNumber, selectedEmployee, ...body } = payload;

    return post({
        ...bffDefaultApiConfig({ locale }),
        url: URL.CUSTOMER_USER_CREATE,
        body: {
            ...body,
            lastName: payload.name,
            mobilePhone: payload.mobilePhoneNumber,
            administratorType: numberAdminTypeToStringType(payload.admin),
            languageId: payload.languageId || DEFAULT_LANGUAGE_ID,
        },
        timeoutInMillis: ONE_SECOND * 30,
    });
}

export function removeCompanyUser(customerId: number, payload: IRemoveUserAccountPayload, locale: Locales) {
    return remove({
        ...bffDefaultApiConfig({ locale }),
        url: URL.CUSTOMER_USER_REMOVE,
        pathParams: {
            emailAddress: payload.email,
            customerId,
        },
        timeoutInMillis: ONE_SECOND * 30,
    });
}

export function fetchAdministrators(companyCode: string) {
    return get<IUserAccount[]>({
        url: URL.ADMINISTRATORS,
        pathParams: {
            companyCode,
        },
        mapResponse: (data) => data['users'].map((user) => ({
            ...user,
            accessLowerRanks: !!user.accessLowerRanks,
            active: !!user.active,
            superUser: !!user.superUser,
        })),
    });
}
