import React, { PureComponent, MouseEvent } from 'react';
import { connect } from '../../../../index';
import Icon from '../../../../common/icons/Icon';
import Button from '../../../../common/buttons/Button';
import Translate from '../../../../common/Translate';
import Tooltip from '../../../../common/widget/Tooltip';
import CloseIcon from '../../../../common/icons/CloseIcon';
import { ListItem } from '../../../../../models/general/list';
import { IInvoiceColumnNames } from '../typings';
import { fetchInvoiceDocument, fetchInvoiceDetail, fetchInvoiceFull } from '../../../../../redux/invoice/actions';
import ListActionButton from '../../../../common/buttons/ListActionButton';
import { getYearNumber } from '../../../../../utils/core/date/getYearNumber';
import Dialog from '../../../../common/modals/Dialog';
import { TMasterData } from '../../../../common/widget/MasterWithDetail/typings';
import isArray from '@snipsonian/core/es/is/isArray';

interface IPublicProps {
    masterData: ListItem<IInvoiceColumnNames>[];
    clientSideFilteredListItems?: TMasterData;
    selectedInvoiceIds: string[];
}

interface IPrivateProps {
    onDownloadDocument: (invoiceIds: number[]) => void;
    onDownloadDetail: (invoiceIds: number[]) => void;
    onDownloadFull: (invoiceIds: number[]) => void;
}

interface IComponentState {
    showOldInvoicesDialog: boolean;
}

class InvoiceHeaderActions extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            showOldInvoicesDialog: false,
        };

        this.onDownloadDocument = this.onDownloadDocument.bind(this);
        this.onDownloadDetail = this.onDownloadDetail.bind(this);
        this.onDownloadFull = this.onDownloadFull.bind(this);
        this.onCloseShowOldInvoicesDialog = this.onCloseShowOldInvoicesDialog.bind(this);
        this.renderListItemActions = this.renderListItemActions.bind(this);
        this.isDownloadPossible = this.isDownloadPossible.bind(this);
    }

    private renderListItemActions() {
        const {
            masterData: allListItems,
            selectedInvoiceIds,
        } = this.props;

        const areInvoicesSelected = selectedInvoiceIds && selectedInvoiceIds.length > 0;
        const selectedListItems = allListItems
            .filter((listItem) => selectedInvoiceIds.includes(listItem.id as string));

        // If we only find invoices older then 2016, then only show download invoice and export button.
        // Or if download is not possible, show this button disabled.
        if (
            !areInvoicesSelected ||
            !this.isDownloadPossible() ||
            (listItemsContainsInvoiceOlderThanGivenYear(selectedListItems, 2016)
            && !listItemsContainsInvoiceNewerThanGivenYear(selectedListItems, 2016))
        ) {
            return (
                <ListActionButton
                    id="invoices-paid-download-button"
                    type="text"
                    iconTypeName="download-file"
                    translationKey="administration.invoices.actions.download_selected"
                    onClick={(e) => this.onDownloadDocument(e, selectedListItems)}
                    disabled={!areInvoicesSelected || !this.isDownloadPossible()}
                />
            );
        }

        const downloadButton = (
            <div className="Button Button--text ListActionButton Button--icon-prepend">
                <Icon typeName="download-file" />
                <Translate msg="administration.invoices.actions.download_selected" />
            </div>
        );

        return (
            <Tooltip
                placement="bottom"
                typeName="tooltip"
                target={downloadButton}
                spaceBetweenTarget={15}
            >
                {({ closeTooltip }) => {
                    return (
                        <>
                            <CloseIcon onClick={closeTooltip} />
                            <div className="button-list">
                                <Button
                                    id="download-invoices"
                                    typeName="text"
                                    onClick={(e) => this.onDownloadDocument(e, selectedListItems)}
                                >
                                    <Translate msg={'administration.invoices.actions.download_invoices'} />
                                </Button>
                                <Button
                                    id="download-details"
                                    typeName="text"
                                    onClick={(e) => this.onDownloadDetail(e, selectedListItems)}
                                >
                                    <Translate msg={'administration.invoices.actions.download_details'} />
                                </Button>
                                <Button
                                    id="download-full"
                                    typeName="text"
                                    onClick={(e) => this.onDownloadFull(e, selectedListItems)}
                                >
                                    <Translate msg={'administration.invoices.actions.download_full'} />
                                </Button>
                            </div>
                        </>
                    );
                }}
            </Tooltip>
        );

    }

    private isDownloadPossible() {
        const data = this.props.clientSideFilteredListItems || this.props.masterData;

        if (isArray(data)) {
            return (data as []).length > 0;
        }

        return false;
    }

    public render() {
        return (
            <>
                {this.renderListItemActions()}
                <Dialog
                    show={this.state.showOldInvoicesDialog}
                    onCloseIntent={() => this.onCloseShowOldInvoicesDialog()}
                    header="administration.invoices.old_invoices_dialog.title"
                    type="error"
                >
                    <p>
                        <Translate msg="administration.invoices.old_invoices_dialog.text" />
                    </p>
                    <div className="Dialog__buttons">
                        <Button
                            id="close-dialog"
                            typeName="secondary"
                            onClick={() => this.onCloseShowOldInvoicesDialog()}
                        >
                            <Translate msg="administration.invoices.old_invoices_dialog.button" />
                        </Button>
                    </div>
                </Dialog>
            </>
        );
    }

    private onCloseShowOldInvoicesDialog() {
        this.setState({
            showOldInvoicesDialog: false,
        });
    }

    private onDownloadDocument(e: MouseEvent<HTMLElement>, listItems: ListItem<IInvoiceColumnNames>[]) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDocument,
        } = this.props;

        onDownloadDocument(listItems.map(item => item.id as number));
    }

    private onDownloadDetail(e: MouseEvent<HTMLElement>, listItems: ListItem<IInvoiceColumnNames>[]) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadDetail,
        } = this.props;

        onDownloadDetail(getInvoiceIdsWhereInvoiceDateOlderThanGivenYear(listItems, 2016));

        if (listItemsContainsInvoiceOlderThanGivenYear(listItems, 2016)) {
            this.setState({
                showOldInvoicesDialog: true,
            });
        }
    }

    private onDownloadFull(e: MouseEvent<HTMLElement>, listItems: ListItem<IInvoiceColumnNames>[]) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadFull,
        } = this.props;

        onDownloadFull(getInvoiceIdsWhereInvoiceDateOlderThanGivenYear(listItems, 2016));

        if (listItemsContainsInvoiceOlderThanGivenYear(listItems, 2016)) {
            this.setState({
                showOldInvoicesDialog: true,
            });
        }
    }
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => {
        return {
            onDownloadDocument: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceDocument({ invoiceIds, delayed: true }));
            },
            onDownloadDetail: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceDetail({ invoiceIds, delayed: true }));
            },
            onDownloadFull: (invoiceIds: number[]) => {
                dispatch(fetchInvoiceFull({ invoiceIds, delayed: true }));
            },
        };
    },
})(InvoiceHeaderActions);

function listItemsContainsInvoiceOlderThanGivenYear(listItems: ListItem<IInvoiceColumnNames>[], year: number) {
    return listItems.find((listItem: ListItem<IInvoiceColumnNames>) => {
        return getYearNumber(listItem.columns.invoiceDateSort as string) < year;
    });
}

function listItemsContainsInvoiceNewerThanGivenYear(listItems: ListItem<IInvoiceColumnNames>[], year: number) {
    return listItems.find((listItem: ListItem<IInvoiceColumnNames>) => {
        return getYearNumber(listItem.columns.invoiceDateSort as string) >= year;
    });
}

export function getInvoiceIdsWhereInvoiceDateOlderThanGivenYear(
    listItems: ListItem<IInvoiceColumnNames>[], year: number) {
    return listItems
        .reduce(
            (accumulator, listItem) => {
                if (getYearNumber(listItem.columns.invoiceDateSort as string) >= year) {
                    accumulator.push(listItem.id as number);
                }
                return accumulator;
            },
            [],
        );
}
