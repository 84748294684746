import { AxiosError } from 'axios';
import isSet from '@snipsonian/core/es/is/isSet';
import { reportError, reportInfo, TGroupingFingerprint } from '../logging/errorReporter';
import removeSensitiveDataFromApiError from './removeSensitiveDataFromApiError';
import getErrorStatus from './getErrorStatus';
import HTTP_STATUS from './httpStatus';
import { ITraceableApiError } from '../../models/general/error';

const ERROR_STATUS_NOT_TO_REPORT: number[] = [
    HTTP_STATUS.UNAUTHORIZED,
];

const ERROR_STATUS_TO_REPORT_AS_ERROR_INSTEAD_OF_INFO: number[] = [
    HTTP_STATUS.NOT_FOUND,
    HTTP_STATUS.REQUEST_TIMEOUT,
];

// keep in lowercase!
const API_ERROR_MESSAGES_NOT_TO_REPORT: string[] = [
    'klantenzone.error.company_code_same',
    'klantenzone.error.duplicate_email_user',
    'klantenzone.error.employee.insz_not_found',
    'klantenzone.error.empty_certificate_documents',
    'klantenzone.error.login.invalid',
    'klantenzone.error.no_content',
    'klantenzone.error.request_account.venture_number_incorrect',
    'klantenzone.error.work_accident.document_not_found',
];

// keep in lowercase!
const API_ERROR_MESSAGES_TO_REPORT_AS_ERROR_INSTEAD_OF_INFO: string[] = [
    'klantenzone.error.bad_request',
];

const BASE_API_ERROR_GROUPING_FINGERPRINT: TGroupingFingerprint = ['api-error'];

export default function reportApiError(error: AxiosError, apiError: ITraceableApiError) {
    const status = getErrorStatus(error);

    if (shouldReportApiError(status, apiError)) {
        const message = apiError ? apiError.message : '-';
        // eslint-disable-next-line max-len
        const reportMessage = `ApiError - method: ${error.config.method} - url: ${error.config.url} - status: ${status} - message: ${message}`;

        if (shouldBeReportedAsError(status, apiError)) {
            reportError(
                new Error(reportMessage),
                {
                    extra: {
                        ...removeSensitiveDataFromApiError(error, true),
                    },
                    groupingFingerprint: determineGroupingFingerprintForApiError({ status, message, error }),
                },
            );
        } else {
            reportInfo(
                reportMessage,
                {
                    category: 'api_error_info',
                },
            );
        }
    }
}

/**
 * To reduce the number of error logs we send to sentry,
 * otherwise we risk hitting the sentry rate limit.
 */
function shouldReportApiError(status: number, apiError: ITraceableApiError): boolean {
    if (ERROR_STATUS_NOT_TO_REPORT.indexOf(status) > -1) {
        return false;
    }
    if (apiError) {
        if (API_ERROR_MESSAGES_NOT_TO_REPORT.indexOf(toLowerCaseIfSet(apiError.message)) > -1) {
            return false;
        }
    }
    return true;
}

function shouldBeReportedAsError(status: number, apiError: ITraceableApiError) {
    if (ERROR_STATUS_TO_REPORT_AS_ERROR_INSTEAD_OF_INFO.indexOf(status) > -1) {
        return true;
    }
    if (apiError) {
        if (API_ERROR_MESSAGES_TO_REPORT_AS_ERROR_INSTEAD_OF_INFO.indexOf(toLowerCaseIfSet(apiError.message)) > -1) {
            return true;
        }
    }
    return false;
}

function toLowerCaseIfSet(message: string) {
    return isSet(message) ? message.toLowerCase() : '';
}

function determineGroupingFingerprintForApiError({
    status,
    message,
    error,
}: {
    status: number;
    message: string;
    error: AxiosError;
}): TGroupingFingerprint {
    const apiMethod = error.config.method; // GET or POST or ...
    const apiUrlWithoutNumericIds = (error.config.url || '')
        .replace(/[0-9]/g, '');

    let granularErrorGroup: string;
    if (status === HTTP_STATUS.REQUEST_TIMEOUT) {
        if (message && message.includes('timeout')) {
            granularErrorGroup = 'no-response_timeout';
        } else {
            granularErrorGroup = `no-response_${message}`;
        }
    } else {
        granularErrorGroup = status ? status.toString() : '-';
    }

    return [
        ...BASE_API_ERROR_GROUPING_FINGERPRINT,
        apiUrlWithoutNumericIds,
        apiMethod,
        granularErrorGroup,
    ];
}
