import React, { Component } from 'react';
import './message-attachment.scss';
import { connect } from '../../../../index';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { IUserMessageDetailAction, UserMessageDetailActionType } from '../../../../../models/user/inbox';
import { fetchInvoiceDocument, resetFetchInvoiceDocument } from '../../../../../redux/invoice/actions';
import { getInvoiceDocumentAsyncInfo } from '../../../../../redux/invoice/selectors';
import Icon from '../../../../common/icons/Icon';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import Loader from '../../../../common/waiting/Loader';
import { IState } from '../../../../../redux';

const CLASS_NAME = 'Attachment';

const INITIAL_ASYNC_INFO = {
    status: AsyncStatus.Initial,
    error: null,
};

interface IPublicProps {
    attachment: IUserMessageDetailAction;
}

interface IPrivateProps {
    downloadAttachmentAsyncInfo: IAsyncFieldInfo;
    downloadAttachment: (attachment: IUserMessageDetailAction) => void;
    resetDownloadAttachmentAsyncInfo: () => void;
}

class SingleAttachment extends Component<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.downloadAttachmentHandler = this.downloadAttachmentHandler.bind(this);
    }

    render() {
        const { attachment, downloadAttachmentAsyncInfo, resetDownloadAttachmentAsyncInfo } = this.props;

        if (!isAttachmentTypeSupported(attachment)) {
            return (
                <div
                    className={`${CLASS_NAME} ${CLASS_NAME}--disabled`}
                >
                    <Icon typeName="paper-clip" />
                    <span>{attachment.filename}</span>
                </div>
            );
        }

        return (
            <>
                <Loader show={downloadAttachmentAsyncInfo.status} />

                <div
                    className={`${CLASS_NAME} ${CLASS_NAME}--enabled`}
                    onClick={this.downloadAttachmentHandler}
                >
                    <Icon typeName="paper-clip" />
                    <span>{attachment.filename}</span>
                </div>

                <ErrorDialog
                    asyncInfo={downloadAttachmentAsyncInfo}
                    titleTranslationKey="account.message_center.detail.attachment.download.error_title"
                    hideRealErrorMessage={true}
                    onCloseDialog={resetDownloadAttachmentAsyncInfo}
                />
            </>
        );
    }

    downloadAttachmentHandler() {
        this.props.downloadAttachment(this.props.attachment);
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        const downloadAttachmentAsyncInfoSelector = isInvoiceAttachment(publicProps.attachment)
            ? getInvoiceDocumentAsyncInfo
            : defaultDownloadAttachmentAsyncInfoSelector;

        return (state) => {
            return {
                downloadAttachmentAsyncInfo: downloadAttachmentAsyncInfoSelector(state),
            };
        };
    },
    dispatchProps: (dispatch) => {
        return {
            downloadAttachment: (attachment: IUserMessageDetailAction) => {
                if (isInvoiceAttachment(attachment)) {
                    const { invoiceId } = attachment;

                    dispatch(fetchInvoiceDocument({
                        invoiceIds: [invoiceId],
                        delayed: false,
                    }));

                    return;
                }

                console.log('Unexpected attachment type was asked to be downloaded', attachment.actionType);
            },
            resetDownloadAttachmentAsyncInfo: () => dispatch(resetFetchInvoiceDocument()),
        };
    },
})(SingleAttachment);

function defaultDownloadAttachmentAsyncInfoSelector(state: IState): IAsyncFieldInfo {
    return INITIAL_ASYNC_INFO;
}

function isAttachmentTypeSupported(attachment: IUserMessageDetailAction): boolean {
    return isInvoiceAttachment(attachment);
}

function isInvoiceAttachment(attachment: IUserMessageDetailAction) {
    return attachment.actionType === UserMessageDetailActionType.invoiceAttachment;
}
