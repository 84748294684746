import { API_URL } from '../../../../../config/api.config';
import { bffDefaultApiConfig } from '../../../../../config/api/bffApiParameters.config';
import { ICreateConvocationsPayload } from '../../../../../models/general/documents';
import { Locales } from '../../../../../config/i18n.config';
import { ONE_MINUTE } from '../../../../../utils/core/time/periodsInMillis';
import { post } from '../../../../../utils/api/requestWrapper';
import { BFF_URL } from '../../documents.const';

export function createConvocations(
    payload: ICreateConvocationsPayload[],
    customerId: number,
    locale: Locales,
) {
    return post({
        ...bffDefaultApiConfig({ locale }),
        api: API_URL.BFF,
        url: BFF_URL.CONVOCATIONS,
        timeoutInMillis: 5 * ONE_MINUTE,
        pathParams: {
            customerId: customerId.toString(),
        },
        body: payload,
    });
}
