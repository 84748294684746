import React from 'react';
import Icon from '../../common/icons/Icon';
import OutgoingLink from '../../common/navigation/OutgoingLink';
import Translate from '../../common/Translate';

export default function CreatedBy() {
    return (
        <div className="credits">
            <span><Translate msg="app_shell.footer.created_by" /></span>
            <OutgoingLink href="https://kunstmaan.be/">
                <Icon typeName="kunstmaan"/>
            </OutgoingLink>
        </div>
    );
}
