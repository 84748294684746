import React, { useState, ChangeEvent, useEffect } from 'react';
import './templates-search.scss';
import {
    ITemplatesCategoryDetail,
    IDocumentsByCategory,
    ITemplatesCategoryDocument,
} from '../../../../models/documentCenter/templates';
import { ListColumns, ListItem } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import openInNewTab from '../../../../utils/browser/openInNewTab';
import Icon from '../../../common/icons/Icon';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import SearchInput from '../../../common/input/SearchInput';
import List from '../../../common/list/List';
import { slugifyStringAsId } from '../../../../utils/core/string/slugify';

const CLASS_NAME = 'TemplateSearch';
const TRANSLATION_PREFIX = 'document_center.templates.search';
const MIN_CHARACTERS_SEARCH = 3;

interface IColumnNames {
    title: string;
    actions: string;
    url: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    title: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.files.columns.title`} />,
        sortable: false,
        percentWidth: 90,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
        align: 'right',
        mobileLabel: <Translate msg={`${TRANSLATION_PREFIX}.files.actions.download_file`} />,
        render: (categoryDocumentItem: ListItem<IColumnNames>) => renderDownloadAction({ categoryDocumentItem }),
    },
    url: {
        hide: true,
        percentWidth: null,
    },
};

export default function TemplateSearch({ data, onShowResults }: {
    data: ITemplatesCategoryDetail[];
    onShowResults: (show: boolean) => void;
}) {
    const [searchValue, setSearchValue] = useState('');
    const [showResults, setShowResults] = useState(false);

    const documentsByCategory = data.map(({ title, description, documentClasses }) => ({
        title,
        description,
        documents: documentClasses.reduce((acc, dc) => [...acc, ...dc.documents], []),
    }));

    const onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setShowResults(e.target.value.length >= MIN_CHARACTERS_SEARCH);
    };

    useEffect(() => {
        onShowResults(showResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResults]);

    const checkIfNoResults = (filteredDocs: IDocumentsByCategory[]) => {
        //eslint-disable-next-line vars-on-top
        for (var key in filteredDocs) {
            // eslint-disable-next-line max-len
            if (
                filteredDocs[key].documents.toString() !== null
                && filteredDocs[key].documents.toString() !== ''
            ) return false;
        }
        return true;
    };

    return (
        <div className={`${CLASS_NAME}__actions`}>
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <SearchInput
                        placeholder={translator(`${TRANSLATION_PREFIX}.placeholder`)}
                        defaultValue={searchValue}
                        onChange={onSearchInput}
                        tooltipTranslationKey={translator(`${TRANSLATION_PREFIX}.search_tooltip`)}
                    />
                )}
            </TranslatorContext.Consumer>
            {showResults && documentsByCategory && (
                getFilteredTemplatesByCategory().map(({ title, documents }) =>
                    documents.length > 0 ? (
                        <div key={title}>
                            <h2>{title}</h2>
                            <List
                                name="DownloadList"
                                columns={COLUMNS}
                                items={mapDocumentsByCategoryToListItems({ categoryTitle: title, documents })}
                                selectedItemIds={[]}
                                hideHeaders
                            />
                        </div>
                    ) : null)
            )}
            {showResults && checkIfNoResults(getFilteredTemplatesByCategory()) && (
                <p className={`${CLASS_NAME}__no-results`}>
                    <Translate msg={`${TRANSLATION_PREFIX}.no_results`}/>
                </p>
            )}
        </div>
    );

    function getFilteredTemplatesByCategory(): IDocumentsByCategory[] {
        return documentsByCategory.map((category) => {
            return {
                ...category,
                documents: category.documents.filter((doc) =>
                    doc.title.toLowerCase().includes(searchValue.toLowerCase())),
            };
        });
    }
}

function mapDocumentsByCategoryToListItems({
    categoryTitle,
    documents,
}: {
    categoryTitle: string;
    documents: ITemplatesCategoryDocument[];
}): ListItem<IColumnNames>[] {
    return documents
        .map((categoryDocument, index) => ({
            id: slugifyStringAsId(`document_${categoryTitle}_${index}`),
            columns: {
                title: categoryDocument.title,
                actions: null,
                url: categoryDocument.url,
            },
        }));
}

function renderDownloadAction({ categoryDocumentItem }: { categoryDocumentItem: ListItem<IColumnNames> }) {
    return (
        <ListItemActions>
            <Button
                id={`download_${categoryDocumentItem.id}`}
                onClick={() => openInNewTab(categoryDocumentItem.columns.url as string)}
            >
                <span><Translate msg={`${TRANSLATION_PREFIX}.files.actions.download_file`} /></span>
                <Icon circle typeName="download-file" />
            </Button>
        </ListItemActions>
    );
}
