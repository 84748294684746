import React, { PureComponent, MouseEvent } from 'react';
import isNull from '@snipsonian/core/es/is/isNull';
import './edit-seat-and-function.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import { IUpdateEmployeeEmploymentPayload } from '../../../../../models/admin/employee';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { ITraceableApiError } from '../../../../../models/general/error';
import SeatTypeahead from '../SeatTypeahead';
import FunctionTypeahead from '../FunctionTypeahead';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import ConfirmationDialog from '../../../../common/modals/ConfirmationDialog';
import Checkbox from '../../../../common/input/Checkbox';
import { schema, fields } from './updateSeatAndFunctionSchema';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { IAddedCompanyFunction, ICompanyFunction } from '../../../../../models/admin/companyFunctions';
import { connect } from '../../../..';
import { fetchCompanyFunctionsForTypeahead } from '../../../../../redux/company/functions/actions';
import {
    getFetchCompanyFunctionsForTypeAheadAsyncInfo,
    getCompanyFunctionsForTypeAhead,
} from '../../../../../redux/company/functions/selectors';

type TValues = Pick<IUpdateEmployeeEmploymentPayload, 'companyCode' | 'customerFunctionId' | 'dateInFunction'>;

export interface IStateProps {
    onSubmit?: (values: TValues, duplicateOptions?: {
        risks: boolean;
    }) => void;
    initialValues: TValues;
    translator: ITranslator;
    personName: string;
    isDetailsLoaded: boolean;
    updateAsyncInfo: IAsyncFieldInfo;
    currentFunctionDescription?: string;
    clearError: (error: ITraceableApiError) => void;
    canTransferRisks?: boolean;
    minDate: string;
}

export interface IEditSeatProps {
    onClose: () => void;
    isEditFunction?: boolean;
    isEditDateInFunction?: boolean;
    isOnboardingWizardRoute?: boolean;
}

interface IComponentState {
    isDuplicateRisksConfirmationDialogOpen: boolean;
    formValues: TValues;
    isDuplicateRisksEnabled: boolean;
}

interface IPrivateProps {
    refetchCompanyFunctions: (companyCode: string) => void;
    companyFunctionsAsyncInfo: IAsyncFieldInfo;
    companyFunctions: ICompanyFunction[];
}

const FORM_NAME = 'edit-seat-and-function-form';
const CLASS_NAME = 'EditSeatAndFunction';

class EditSeatAndFunction extends PureComponent<IStateProps & IEditSeatProps & IPrivateProps, IComponentState> {
    private formRenderProps: IFormRenderProps<TValues>;

    constructor(props) {
        super(props);

        this.state = {
            isDuplicateRisksConfirmationDialogOpen: false,
            formValues: null,
            isDuplicateRisksEnabled: true,
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    public render() {
        const {
            translator, initialValues,
            isDetailsLoaded, personName,
            updateAsyncInfo, isEditFunction, isEditDateInFunction,
            currentFunctionDescription,
            canTransferRisks,
            minDate,
            isOnboardingWizardRoute,
            refetchCompanyFunctions,
            companyFunctionsAsyncInfo,
            companyFunctions,
        } = this.props;
        const {
            isDuplicateRisksEnabled,
        } = this.state;

        if (!isDetailsLoaded) {
            return null;
        }

        const namePlaceholder = {
            name: personName,
        };

        const seatPlaceholder = translator('administration.employees.edit_seat_and_function.form.seat');
        const functionPlaceholder = translator('administration.employees.edit_seat_and_function.form.function');
        const startDatePlaceholder = translator({
            msg: 'administration.employees.edit_seat_and_function.form.start_date',
            placeholders: namePlaceholder,
        });
        const startDateErrorPlaceholder =
            translator('administration.employees.edit_seat_and_function.form.error_field_names.start_date');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.edit_seat_and_function.' +
                                `${isEditFunction ? 'title_new_function' :
                                    isEditDateInFunction ? 'title_new_date' : 'title'}`}
                            placeholders={{ name: personName }}
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onFormSubmit}
                    initialValues={initialValues}
                    schema={schema}
                    render={(formRenderProps: IFormRenderProps<TValues>) => {
                        this.formRenderProps = formRenderProps;

                        const { values, touched, errors, setFieldValue } = formRenderProps;

                        function onSeatItemSelected(companyCode: string) {
                            if (values.companyCode !== companyCode && !isNull(companyCode)) {
                                refetchCompanyFunctions(companyCode);
                            }
                            setFieldValue('companyCode', companyCode);
                        }

                        function onFunctionItemSelected(value: number) {
                            setFieldValue('customerFunctionId', value);
                        }

                        function onFunctionAdded(companyFunction: IAddedCompanyFunction) {
                            setFieldValue('customerFunctionId', companyFunction.id);
                        }

                        function onStartDateChanged(date: string) {
                            setFieldValue('dateInFunction', date);
                        }

                        const displayFunctionsDirectly = companyFunctions.length <= 20;


                        return (
                            <>
                                <Loader show={updateAsyncInfo.status === AsyncStatus.Busy} />
                                <FloatableTextInputWrapper>
                                    <SeatTypeahead
                                        id="edit-seat-seat-field"
                                        value={values.companyCode}
                                        name={fields.companyCode}
                                        onItemSelected={onSeatItemSelected}
                                        isInvalid={touched.companyCode && !!errors.companyCode}
                                        // eslint-disable-next-line max-len
                                        placeholder={translator('administration.employees.edit_seat_and_function.form.seat')}
                                        allSeats={true}
                                    >
                                        <label htmlFor="edit-seat-seat-field">
                                            <Translate
                                                msg="administration.employees.edit_seat_and_function.form.seat"
                                            />
                                        </label>
                                    </SeatTypeahead>
                                    {touched.companyCode && (
                                        <FormFieldError
                                            error={errors.companyCode}
                                            placeholders={{ fieldName: seatPlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <FunctionTypeahead
                                        id="edit-function-function-field"
                                        currentFunctionDescription={currentFunctionDescription}
                                        value={values.customerFunctionId}
                                        name={fields.customerFunctionId}
                                        onItemSelected={onFunctionItemSelected}
                                        isInvalid={touched.customerFunctionId && !!errors.customerFunctionId}
                                        showFullFamily={false}
                                        companyCodeOverride={values.companyCode}
                                        // eslint-disable-next-line max-len
                                        placeholder={translator('administration.employees.edit_seat_and_function.form.function')}
                                        onFunctionAdded={onFunctionAdded}
                                        immediatelyTriggerSearch={isOnboardingWizardRoute || displayFunctionsDirectly}
                                    >
                                        <label htmlFor="edit-function-function-field">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.edit_seat_and_function.form.function"
                                            />
                                        </label>
                                    </FunctionTypeahead>
                                    {touched.customerFunctionId && (
                                        <FormFieldError
                                            error={errors.customerFunctionId}
                                            placeholders={{ fieldName: functionPlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <DatePicker
                                        id="edit-function-start-date-field"
                                        placeholder={startDatePlaceholder}
                                        value={values.dateInFunction}
                                        name={fields.dateInFunction}
                                        onChange={onStartDateChanged}
                                        isInvalid={touched.dateInFunction && !!errors.dateInFunction}
                                        minDate={minDate}
                                    >
                                        <label htmlFor="edit-function-start-date-field">
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.edit_seat_and_function.form.start_date"
                                                placeholders={namePlaceholder}
                                            />
                                        </label>
                                    </DatePicker>
                                    {touched.dateInFunction && (
                                        <FormFieldError
                                            error={errors.dateInFunction}
                                            placeholders={{ fieldName: startDateErrorPlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FormError error={updateAsyncInfo.error} />
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="update-seat-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={this.onCancelClick}
                                    >
                                        <Translate msg="administration.employees.edit_seat_and_function.form.cancel" />
                                    </Button>
                                    <SubmitButton
                                        id="update-seat-submit-button"
                                        formName={FORM_NAME}
                                        disabled={companyFunctionsAsyncInfo.status === AsyncStatus.Busy}
                                    >
                                        <Translate msg="administration.employees.edit_seat_and_function.form.submit" />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
                <ConfirmationDialog
                    show={this.state.isDuplicateRisksConfirmationDialogOpen}
                    onCancel={() => this.onConfirmationDialogAction(false)}
                    onConfirm={() => this.onConfirmationDialogAction(true)}
                    header={'administration.employees.edit_seat_and_function.transfer_risks_dialog.title'}
                >
                    <div className={`${CLASS_NAME}__confirmation-dialog`}>
                        <div className={`${CLASS_NAME}__confirmation-dialog__options`}>
                            {canTransferRisks &&
                                <Checkbox
                                    name="risks"
                                    checked={isDuplicateRisksEnabled}
                                    onChange={(e) => this.setState({ isDuplicateRisksEnabled: e.target.checked })}
                                >
                                    <Translate
                                        // eslint-disable-next-line max-len
                                        msg="administration.employees.edit_seat_and_function.transfer_risks_dialog.options.risks"
                                    />
                                </Checkbox>
                            }
                        </div>
                    </div>
                </ConfirmationDialog>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IStateProps & IPrivateProps) {
        const { updateAsyncInfo, onClose, companyFunctionsAsyncInfo, companyFunctions } = this.props;
        if (
            updateAsyncInfo.status === AsyncStatus.Success &&
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy
        ) {
            return onClose();
        }
        if (
            companyFunctionsAsyncInfo.status === AsyncStatus.Success &&
            prevProps.companyFunctionsAsyncInfo.status === AsyncStatus.Busy
        ) {
            const { values, setFieldValue } = this.formRenderProps;
            if (!companyFunctions || !companyFunctions.find((item) => item.id === values.customerFunctionId)) {
                setFieldValue('customerFunctionId', null);
            }
        }
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, updateAsyncInfo } = this.props;
        clearError(updateAsyncInfo.error);
        onClose();
    }

    private onFormSubmit(values: TValues) {
        const { canTransferRisks, onSubmit, companyFunctionsAsyncInfo } = this.props;
        if (companyFunctionsAsyncInfo.status === AsyncStatus.Busy) {
            return;
        }
        if (canTransferRisks) {
            this.setState({
                formValues: values,
                isDuplicateRisksConfirmationDialogOpen: true,
                isDuplicateRisksEnabled: canTransferRisks,
            });
        } else {
            onSubmit(values);
        }
    }

    private onConfirmationDialogAction(confirmed: boolean) {
        const { isDuplicateRisksEnabled } = this.state;
        this.setState({ isDuplicateRisksConfirmationDialogOpen: false });
        if (confirmed) {
            this.props.onSubmit(this.state.formValues, {
                risks: isDuplicateRisksEnabled,
            });
        }
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            companyFunctions: getCompanyFunctionsForTypeAhead(state),
            companyFunctionsAsyncInfo: getFetchCompanyFunctionsForTypeAheadAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            refetchCompanyFunctions: (companyCode: string) => {
                dispatch(fetchCompanyFunctionsForTypeahead({
                    companyCode,
                    showFullFamily: false,
                    keyword: '',
                }));
            },
        };
    },
})(EditSeatAndFunction);
