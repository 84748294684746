import React from 'react';
import './header-with-sidebar-content.scss';

const CLASS_NAME = 'HeaderWithSidebarContent';

interface IPublicProps {
    header: React.ReactNode;
    sidebar: React.ReactNode;
    content: React.ReactNode;
}

export default function HeaderWithSidebarContent(props: IPublicProps) {
    return (
        <div className={CLASS_NAME}>
            <header className={`${CLASS_NAME}__header`}>
                {props.header}
            </header>
            <div className={`${CLASS_NAME}__content`}>
                <div className={`${CLASS_NAME}__sidebar`}>
                    {props.sidebar}
                </div>
                <div className={`${CLASS_NAME}__main`}>
                    {props.content}
                </div>
            </div>
        </div>
    );
}
