import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState } from '../../index';
import { IActiveDraggable } from '../../../models/ui/dragAndDrop';
import { DraggablePrefix } from '../../../models/interventions/timeslots';
import { getPrefixFromPrefixedDraggableId } from '../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export function getActiveDraggable<IData>(state: IState) {
    return reducerState(state).activeDraggable as IActiveDraggable<IData>;
}

export function getDraggableEmployeeActiveDraggable(state: IState) {
    const activeDraggable = getActiveDraggable(state);
    if (!activeDraggable) {
        return null;
    }

    const draggablePrefix = getPrefixFromPrefixedDraggableId(activeDraggable.draggableId);
    const isDraggableEmployee = draggablePrefix === DraggablePrefix.Employee;

    if (!isDraggableEmployee) {
        return null;
    }

    return activeDraggable;
}

export const isDraggingDraggableWithPrefix = (state: IState, prefix: DraggablePrefix) => {
    const activeDraggable = getActiveDraggable(state);
    if (!activeDraggable) {
        return null;
    }

    const draggablePrefix = getPrefixFromPrefixedDraggableId(activeDraggable.draggableId);
    return draggablePrefix === prefix;
};
