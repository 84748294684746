import React from 'react';

import { getAddEmployeeWizardStepId } from '../../../../redux/employee/wizard/selectors';
import { registerWizardSteps, WIZARD_CODE } from '../../../../config/navigation/wizardStepsMap';
import Wizard from '../../../common/navigation/Wizard/index';

import { navigateToAddEmployeeStepActionCreator } from './AddEmployeeWizard.helper';
import { WIZARD_STEPS } from './AddEmployeeWizard.const';

registerWizardSteps({
    wizardCode: WIZARD_CODE.ADD_EMPLOYEE,
    steps: WIZARD_STEPS,
});

const AddEmployeeWizard = () => (
    <div className="AddEmployee">
        <Wizard
            activeStepIdSelector={getAddEmployeeWizardStepId}
            navigateToStepActionCreator={navigateToAddEmployeeStepActionCreator}
            steps={WIZARD_STEPS}
        />
    </div>
);

export { AddEmployeeWizard };
