import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    FETCH_COMPANIES,
} from './types';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createActionHandler,
} from '../../index';
import {
    ICompanyDismissableWithDate,
} from '../../../models/admin/company';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.COMPANIES;

export interface IReducerState {
    companies: IAsyncFetchField<ICompanyDismissableWithDate[]>;
}

const initialState: IReducerState = {
    companies: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<ICompanyDismissableWithDate[], IReducerState, ICompanyDismissableWithDate[]>({
        baseActionType: FETCH_COMPANIES,
        fieldName: 'companies',
        resetDataOnTrigger: false,
        reducerKey,
    }),

    [ROUTE_KEYS.R_COMPANY_SELECTION]: createActionHandler<IReducerState>(
        ({ oldState }) => {
            return {
                ...oldState,
                companies: initialState.companies,
            };
        },
    ),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
