import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { IOnboardingWizardPayload, IOnboardingWizardData } from '../../models/onboarding/wizard';
import ROUTE_KEYS from '../../routeKeys';
import { createActionHandler } from '../../utils/libs/redux/createActionHandler';
import { registerReducer } from '../../utils/libs/redux/registerReducer';
import { getOnboardingWizardSteps } from '../../config/navigation/wizardStepsMap';
import {
    UPDATE_ONBOARDING_WIZARD_ENTITY,
    FETCH_ONBOARDING_SEATS_AVAILABILITIES,
    COPY_ONBOARDING_AVAILABILITIES,
    CONFIRM_ONBOARDING_WIZARD,
} from './types';
import { IAsyncFetchField, IAsyncDoField } from '../../models/general/redux';
import {
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    getAsyncDoInitialState,
    createAsyncDoActionHandlers,
} from '../index';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.ONBOARDING;

export interface IReducerState {
    onboardingWizardData: IOnboardingWizardData;
    fetchOnboardingSeatsAvailabilities: IAsyncFetchField<{}>;
    copyOnboardingAvailabilities: IAsyncDoField;
    confirmOnboardingWizard: IAsyncDoField;
}

const initialState: IReducerState = {
    onboardingWizardData: null,
    fetchOnboardingSeatsAvailabilities: getAsyncFetchInitialState(),
    copyOnboardingAvailabilities: getAsyncDoInitialState(),
    confirmOnboardingWizard: getAsyncDoInitialState(),
};

const actionHandlers = {
    [ROUTE_KEYS.R_ONBOARDING_NEW]:
        createActionHandler<IReducerState, IOnboardingWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    onboardingWizardData: (stepId === getOnboardingWizardSteps().firstStepId)
                        ? {
                            stepId,
                            entity: payload.resetDataEntity
                                ? {}
                                // eslint-disable-next-line max-len
                                : (oldState.onboardingWizardData && oldState.onboardingWizardData.entity) || {},
                        }
                        : {
                            ...oldState.onboardingWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_ONBOARDING_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    onboardingWizardData: {
                        ...oldState.onboardingWizardData,
                        entity: {
                            ...oldState.onboardingWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncFetchActionHandlers<{}, IReducerState, {}>({
        baseActionType: FETCH_ONBOARDING_SEATS_AVAILABILITIES,
        fieldName: 'fetchOnboardingSeatsAvailabilities',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: COPY_ONBOARDING_AVAILABILITIES,
        fieldName: 'copyOnboardingAvailabilities',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CONFIRM_ONBOARDING_WIZARD,
        fieldName: 'confirmOnboardingWizard',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that onboarding wizard steps are not lost when refreshing
        onboardingWizardData: reducerState.onboardingWizardData,
    }),
});
