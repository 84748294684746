import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { get } from '../../../../utils/api/requestWrapper';
import { IFetchappointmentsResult, IFetchAppointmentsApiPayload } from '../../../../models/planning/activities';
import { ONE_MINUTE } from '../../../../utils/core/time/periodsInMillis';
import { BFF_URL } from '../activities.const';

import { mapAppointmentsResponse } from './appointments.mapper';
import { NO_RERENDER } from '../../../../redux';

export function fetchAppointments({
    companyCode,
    showFullFamily,
    start,
    end,
    query = null,
    locale,
}: IFetchAppointmentsApiPayload) {
    return get<IFetchappointmentsResult>({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.COMPANY_APPOINTMENTS,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            start,
            end,
            ...!!query && {
                query,
            },
        },
        mapResponse: (response) => {
            if (!response) {
                return {
                    events: NO_RERENDER.EMPTY_LIST,
                    count: 0,
                };
            }

            const events = mapAppointmentsResponse(response);

            return {
                events,
                count: events.length || 0,
            };
        },
        timeoutInMillis: 1 * ONE_MINUTE,
    });
}
