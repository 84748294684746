import {
    getReducerState,
    IState,
    makeAsyncFetchInfoSelector,
    NO_RERENDER,
    makeAsyncDoInfoSelector,
} from '../../index';

import { IReducerState } from './types';
import { reducerKey } from './reducer';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

/* Administered vaccinations */
const getAdministeredVaccinationsAsyncField = (state: IState) => reducerState(state).administered;

export const getAdministeredVaccinationsAsyncInfo = makeAsyncFetchInfoSelector(getAdministeredVaccinationsAsyncField);

export const getAdministeredVaccinations = (state: IState) =>
    getAdministeredVaccinationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areAdministeredVaccinationsAvailable = (state: IState) => {
    const { data } = getAdministeredVaccinationsAsyncField(state);

    return data !== null;
};

/* Administered vaccinations */
const getToAdministerVaccinationsAsyncField = (state: IState) => reducerState(state).toAdminister;

export const getToAdministerVaccinationsAsyncInfo = makeAsyncFetchInfoSelector(getToAdministerVaccinationsAsyncField);

export const getToAdministerVaccinations = (state: IState) =>
    getToAdministerVaccinationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areToAdministerVaccinationsAvailable = (state: IState) => {
    const { data } = getToAdministerVaccinationsAsyncField(state);

    return data !== null;
};

// Request Vaccination
const getRequestVaccinationAsyncField = (state: IState) =>
    reducerState(state).requestVaccination;

export const getRequestVaccinationAsyncInfo =
    makeAsyncDoInfoSelector(getRequestVaccinationAsyncField);
