import React from 'react';
import './agenda-filter-actions.scss';
import ButtonLinkToRoute from '../../../common/navigation/ButtonLinkToRoute';
import Icon from '../../../common/icons/Icon';
import Translate from '../../../common/Translate';
import ROUTE_KEYS from '../../../../routeKeys';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';

const CLASS_NAME = 'AgendaFilterActions';

export default function AgendaFilterActions() {
    return (
        <ShowIfAllowed routeKey={ROUTE_KEYS.R_PLAN_INTERVENTION}>
            <div className={CLASS_NAME}>
                <ButtonLinkToRoute
                    id="agenda-plan-or-request"
                    typeName="secondary"
                    to={ROUTE_KEYS.R_PLAN_INTERVENTION}
                >
                    <Icon typeName="calendar" />
                    <Translate msg="planning.agenda.plan_or_request" />
                </ButtonLinkToRoute>
            </div>
        </ShowIfAllowed>
    );
}
