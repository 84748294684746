import { Logic } from 'redux-logic/definitions/logic';
import isSet from '@snipsonian/core/es/is/isSet';

const registeredLogics: Logic[] = [];

export function registerLogic(logic: Logic) {
    registeredLogics.push(logic);
}

export function getRegisteredLogics() {
    if (process.env.NODE_ENV === 'development') {
        console.log('# Registered logics:', registeredLogics.length);
    }
    return registeredLogics;
}

/**
 * Doing the 'createLogicMiddleware' for all epics at once (see store.ts)
 * causes - sometimes - an "Out of stack space" error in IE11.
 * To prevent this from happening, we do the createLogicMiddleware per
 * epic subset.
 *
 * Original ticket: https://jira.kunstmaan.com/browse/MENSKLNTZN-2755
 * Also see https://github.com/jeffbski/redux-logic/issues/83
 */
const NUMBER_OF_EPICS_PER_GROUP = 20;

export function getRegisteredLogicsGroupedToAvoidOutOfStackSpaceError() {
    let logicGroupIndex = 0;

    return getRegisteredLogics()
        .reduce(
            (accumulator, epic) => {
                if (!isSet(accumulator[logicGroupIndex])) {
                    accumulator[logicGroupIndex] = [];
                }
                accumulator[logicGroupIndex].push(epic);

                if (accumulator[logicGroupIndex].length >= NUMBER_OF_EPICS_PER_GROUP) {
                    logicGroupIndex += 1;
                }

                return accumulator;
            },
            [],
        );
}
