import React, { PureComponent } from 'react';
import { path } from 'ramda';

import {
    addTimeslotActions,
    fetchCompanyMedicalCenterTimeslotsByEmployeeActions,
    fetchMedicalExaminationsToPlan,
    fetchPlannedMedicalExaminationsActions,
    triggerMoveUpdateTimeSlot,
    updatePlanMedicalExaminationWizardEntity,
    updateTimeSlotActions,
} from '../../../../../../../redux/medicalExamination/actions';
import {
    areMedicalExaminationsToPlanAvailable,
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
    getUpdateTimeslotAsyncInfo,
    getAddTimeslotAsyncInfo,
} from '../../../../../../../redux/medicalExamination/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../../models/general/redux';
import { fetchSmallEmployeeDetails } from '../../../../../../../redux/employee/info/actions';
import { getCompanyMedicalCenters } from '../../../../../../../redux/company/info/selectors';
import { getSelectedEmployee } from '../../../../../../../redux/employee/info/selectors';
import {
    IMedicalExaminationTimeSlot,
    IMedicalExaminationTimeSlotWithoutType,
    IMedicalExaminationToAdd,
    IPeriodicHealthAssessmentAutomaticEntity,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
} from '../../../../../../../models/interventions/medicalExaminations';
import { IPerson } from '../../../../../../../models/admin/employee';
import connect from '../../../../../../../utils/libs/redux/connect';
import ErrorDialog from '../../../../../../common/modals/ErrorDialog';
import Loader from '../../../../../../common/waiting/Loader';
import SelectFreeTimeslot from '../../../shared/SelectFreeTimeslot';

interface IEditTimeslotProps {
    selectedMedicalExamination: IMedicalExaminationToAdd;
    onClose: () => void;
    hideDatepicker?: boolean;
    isActive: boolean;
}

interface IPrivateProps {
    updateAsyncInfo: IAsyncFieldInfo;
    addAsyncInfo: IAsyncFieldInfo;
    fetchDataIfNotavailable: (employee: {
        id: number;
        employeeId: number;
    } & IPerson) => void;
    addOrMoveTimeslot: (medicalExamination: IMedicalExaminationToAdd, newTimecell: IMedicalExaminationTimeSlot) => void;
    updateMedicalExaminationTimeSlot: (
        medicalExamination: IMedicalExaminationToAdd, newTimecell: IMedicalExaminationTimeSlot
    ) => void;
    resetUpdateTimeslot: () => void;
    resetAddTimeslot: () => void;
    refreshCalendarData: (medicalExamination: IMedicalExaminationToAdd) => void;
    resetCalendarData: () => void;
}

interface IComponentState {
    selectedTimecell: IMedicalExaminationTimeSlot;
}

class EditTimeslot extends PureComponent<IEditTimeslotProps & IPrivateProps, IComponentState> {

    constructor(props: IEditTimeslotProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedTimecell: null,
        };

        this.onTimeslotSelected = this.onTimeslotSelected.bind(this);
        this.clearUpdateAsyncInfo = this.clearUpdateAsyncInfo.bind(this);
        this.clearAddAsyncInfo = this.clearAddAsyncInfo.bind(this);
    }

    public render() {
        const { selectedMedicalExamination, updateAsyncInfo, addAsyncInfo, hideDatepicker } = this.props;

        if (!selectedMedicalExamination) {
            return null;
        }

        return (
            <>
                <Loader show={addAsyncInfo.status} />
                <ErrorDialog
                    asyncInfo={addAsyncInfo}
                    // eslint-disable-next-line max-len
                    titleTranslationKey="interventions.medical_examinations.new.steps.validate_auto_plan.edit.error.title"
                    onCloseDialog={this.clearAddAsyncInfo}
                />
                <Loader show={updateAsyncInfo.status} />
                <ErrorDialog
                    asyncInfo={updateAsyncInfo}
                    // eslint-disable-next-line max-len
                    titleTranslationKey="interventions.medical_examinations.new.steps.validate_auto_plan.edit.error.title"
                    onCloseDialog={this.clearUpdateAsyncInfo}
                />
                <SelectFreeTimeslot
                    onTimeslotSelected={this.onTimeslotSelected}
                    selectedEmployee={selectedMedicalExamination.employee}
                    hideDatepicker={hideDatepicker}
                />
            </>
        );
    }

    public componentDidMount() {
        const { selectedMedicalExamination, fetchDataIfNotavailable, isActive } = this.props;
        if (isActive && selectedMedicalExamination) {
            fetchDataIfNotavailable(selectedMedicalExamination.employee);
        }
    }

    public componentDidUpdate(prevProps: IEditTimeslotProps & IPrivateProps) {
        const {
            selectedMedicalExamination, fetchDataIfNotavailable,
            updateAsyncInfo, onClose, updateMedicalExaminationTimeSlot,
            addAsyncInfo, refreshCalendarData, resetCalendarData, isActive,
        } = this.props;

        const selectedMedicalExaminationChanged = isActive && selectedMedicalExamination
            && prevProps.selectedMedicalExamination !== selectedMedicalExamination;

        if (selectedMedicalExaminationChanged) {
            fetchDataIfNotavailable(selectedMedicalExamination.employee);
            resetCalendarData();
        }

        const updateMedicalExaminationSucceeded =
            prevProps.updateAsyncInfo.status === AsyncStatus.Busy
            && updateAsyncInfo.status === AsyncStatus.Success;

        const addMedicalExaminationSucceeded =
            prevProps.addAsyncInfo.status === AsyncStatus.Busy
            && addAsyncInfo.status === AsyncStatus.Success;

        if (updateMedicalExaminationSucceeded || addMedicalExaminationSucceeded) {
            updateMedicalExaminationTimeSlot(selectedMedicalExamination, this.state.selectedTimecell);
            refreshCalendarData(selectedMedicalExamination);
            resetCalendarData();
            onClose();
        }
    }

    private onTimeslotSelected(timeSlot: IMedicalExaminationTimeSlot) {
        const { addOrMoveTimeslot, selectedMedicalExamination } = this.props;

        addOrMoveTimeslot(selectedMedicalExamination, timeSlot);

        this.setState({
            selectedTimecell: timeSlot,
        });
    }

    private clearUpdateAsyncInfo() {
        this.props.resetUpdateTimeslot();
    }

    private clearAddAsyncInfo() {
        this.props.resetAddTimeslot();
    }
}

export default connect<IPrivateProps, IEditTimeslotProps>({
    stateProps: (state) => {
        return {
            updateAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            addAsyncInfo: getAddTimeslotAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            fetchDataIfNotavailable: (employee) => {
                const state = getState();
                if (!areMedicalExaminationsToPlanAvailable(state)) {
                    dispatch(fetchMedicalExaminationsToPlan());
                }
                const selectedEmployee = getSelectedEmployee(state);
                if (!selectedEmployee || selectedEmployee.id !== employee.id) {
                    dispatch(fetchSmallEmployeeDetails({
                        id: employee.id,
                    }));
                }
            },
            addOrMoveTimeslot: (
                medicalExamination: IMedicalExaminationToAdd,
                timeSlot: IMedicalExaminationTimeSlotWithoutType,
            ) => {
                const state = getState();
                const activityId = path<number>(['timeSlot', 'activityId'], medicalExamination);
                const timeSlotId = path<number>(['timeSlot', 'id'], medicalExamination);

                if (activityId && timeSlotId) {
                    dispatch(triggerMoveUpdateTimeSlot({
                        activityId,
                        timeSlotId,
                        remarks: '',
                        newTimeSlot: {
                            id: timeSlot.id,
                            activityId: timeSlot.activityId,
                        },
                    }));
                } else if (medicalExamination) {
                    if (medicalExamination.planningRequestId) {
                        dispatch(addTimeslotActions.trigger({
                            timeSlotId: timeSlot.id,
                            activityId: timeSlot.activityId,
                            remarks: '',
                            planningRequestMSId: medicalExamination.planningRequestId,
                        }));
                    } else {
                        const examinationReason = getPlanMedicalExaminationWizardReason(state);

                        dispatch(addTimeslotActions.trigger({
                            activityId: timeSlot.activityId,
                            timeSlotId: timeSlot.id,
                            remarks: '',
                            employmentId: medicalExamination.employee.id,
                            examinationReasonCode: examinationReason.code,
                        }));
                    }
                }
            },
            resetUpdateTimeslot: () => {
                dispatch(updateTimeSlotActions.reset({}));
            },
            resetAddTimeslot: () => {
                dispatch(addTimeslotActions.reset({}));
            },
            updateMedicalExaminationTimeSlot:
                (medicalExamination: IMedicalExaminationToAdd, timeSlot: IMedicalExaminationTimeSlot) => {
                    const state = getState();

                    const wizardEntity = getPlanMedicalExaminationWizardEntity<
                        IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
                    const timeSlotId = wizardEntity && wizardEntity.selectTime.timeSlotId;
                    const prevMedicalExaminationsToAdd =
                        wizardEntity ? wizardEntity.medicalExaminationsToAdd : [];

                    const wizard = getPlanMedicalExaminationWizardEntity<
                        IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
                    const medicalCenterCode = wizard.selectTime.filter.selectedMedicalCenterCode;
                    const selectedMedicalCenterData = getCompanyMedicalCenters(state)
                        .find((item) => item.code === medicalCenterCode);

                    const newMedicalExaminationsToAdd = prevMedicalExaminationsToAdd.map((item) => {
                        if (
                            item.employee.id === medicalExamination.employee.id &&
                            item.planningRequestId === medicalExamination.planningRequestId
                        ) {
                            return {
                                ...medicalExamination,
                                errorMessage: null,
                                timeSlotId,
                                location: {
                                    id: selectedMedicalCenterData.id || 0,
                                    name: selectedMedicalCenterData.name,
                                    code: selectedMedicalCenterData.code,
                                },
                                timeSlot,
                            };
                        }
                        return item;
                    });

                    dispatch(
                        updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                            medicalExaminationsToAdd: newMedicalExaminationsToAdd,
                        }),
                    );
                },
            refreshCalendarData: (medicalExamination: IMedicalExaminationToAdd) => {
                const state = getState();
                const wizardEntity = getPlanMedicalExaminationWizardEntity<
                    IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);

                if (wizardEntity && wizardEntity.selectTime) {
                    const medicalCenterCode = wizardEntity.selectTime.filter.selectedMedicalCenterCode;

                    const { start, end } = wizardEntity.selectTime.filter.dateRangeToFetch;
                    const selectedMedicalCenterData = getCompanyMedicalCenters(state)
                        .find((item) => item.code === medicalCenterCode);
                    const examinationReason = getPlanMedicalExaminationWizardReason(state);

                    if (selectedMedicalCenterData) {
                        dispatch(fetchCompanyMedicalCenterTimeslotsByEmployeeActions.trigger({
                            medicalCenterCode: selectedMedicalCenterData.code,
                            startDate: start,
                            endDate: end,
                            employeeId: medicalExamination.employee.employeeId,
                            employmentId: medicalExamination.employee.id,
                            examinationReasonCode: examinationReason.code,
                        }));

                        dispatch(fetchPlannedMedicalExaminationsActions.trigger({
                            endDate: end,
                            startDate: start,
                            medicalCenterCode: selectedMedicalCenterData.code,
                        }));
                    }
                }
            },
            resetCalendarData: () => {
                const state = getState();
                const wizardEntity =
                    // eslint-disable-next-line max-len
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity & IPeriodicHealthAssessmentAutomaticEntity>(state);
                if (wizardEntity) {
                    dispatch(
                        updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                            selectTime: {
                                ...wizardEntity.selectTime,
                                filter: wizardEntity.selectTime ? {
                                    ...wizardEntity.selectTime.filter,
                                    selectedDate: wizardEntity.startDate,
                                    selectedMedicalCenterCode: null,
                                } : {
                                    selectedDate: wizardEntity.startDate,
                                },
                            },
                        }),
                    );
                }
            },
        };
    },
})(EditTimeslot);
