import { areObjectParamsEqual } from '../../../../../utils/core/object/diffObjects';
import {
    DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
} from '../../../../../api/interventions/companyVisits/planned/planned.const';
import {
    IFetchPlannedCompanyVisitsPayload,
    IPlannedCompanyVisitsFilter,
} from '../../../../../models/interventions/company-visits';
import ROUTE_KEYS from '../../../../../routeKeys';
import { createEpic } from '../../../../index';
import { getLocationState, getQueryParams } from '../../../../location/selectors';
import { getLocale } from '../../../../i18n/selectors';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../../selected/selectors';
import { arePlannedCompanyVisitsAvailable } from '../../selectors';
import { fetchPlannedCompanyVisitsFailed, fetchPlannedCompanyVisitsSucceeded } from '../../actions';

createEpic<IFetchPlannedCompanyVisitsPayload[]>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
    refreshDataIf: ({ getState, action }) => {
        if (!arePlannedCompanyVisitsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IPlannedCompanyVisitsFilter;
            const locale = getLocale(state);

            const result = await api.interventions.companyVisits.fetchPlannedCompanyVisits(
                { companyCode, showFullFamily },
                filterFromQuery,
                locale,
            );
            return fetchPlannedCompanyVisitsSucceeded(result);
        } catch (error) {
            return fetchPlannedCompanyVisitsFailed(error);
        }
    },
    latest: false,
});
