import React from 'react';
import Checkbox from '../Checkbox';
import { addOrRemoveStringListValue, separateStringList } from '../../../../utils/core/string/separatedStringList';
import { TDropdownItemValue } from '../Dropdown';
import { ITypeaheadDataItem } from '../Typeahead';
import CollapsibleItem from '../../widget/CollapsibleItem';

interface IPublicProps {
    filterName: string;
    labelTranslationKey: string;
    possibleFilterItems: ITypeaheadDataItem[];
    actualFilterValue: string;
    onChange: (newFilterValue: string) => void;
    initialOpen?: boolean;
}

export default function CollapsibleCheckboxesFilter(props: IPublicProps) {
    const {
        filterName,
        labelTranslationKey,
        possibleFilterItems,
        actualFilterValue,
        onChange,
        initialOpen,
    } = props;

    const selectedFilterValues = separateStringList(actualFilterValue);

    if (possibleFilterItems.length === 0) {
        return null;
    }

    return (
        <CollapsibleItem
            trigger={labelTranslationKey}
            triggerTagName="h6"
            initialOpen={initialOpen}
        >
            {possibleFilterItems.sort(sortByLabel).map((filterItem) => {
                const filterItemValue = filterItem.value ? filterItem.value.toString() : '';
                return (
                    <Checkbox
                        key={`filter-${filterName}-${filterItemValue}`}
                        name={`filter_${filterName}_${filterItemValue}`}
                        checked={selectedFilterValues.includes(filterItemValue)}
                        onChange={(e) => onChange(
                            addOrRemoveStringListValue(
                                actualFilterValue,
                                filterItemValue,
                                e.target.checked,
                            ),
                        )}
                    >
                        {filterItem.label}
                    </Checkbox>
                );
            })}
        </CollapsibleItem>
    );
}

function sortByLabel(a: ITypeaheadDataItem<TDropdownItemValue>, b: ITypeaheadDataItem<TDropdownItemValue>) {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
}
