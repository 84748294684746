import { get, post } from '../../utils/api/requestWrapper';
import {
    ICompanyFunction,
    IAddCompanyFunctionPayload,
    ICompanyFunctionIsco,
    ICompanyFunctionEmployee,
    ICompanyFunctionsFilter,
    IFetchCompanyFunctionsPayload,
} from '../../models/admin/companyFunctions';
import { IRisk } from '../../models/admin/risks';
import { IResearch } from '../../models/admin/researches';
import { Locales } from '../../config/i18n.config';
import { localeToBackendLocale } from '../../utils/formatting/formatLocale';
import { HEADER_NAME } from '../../config/api.config';
import { ONE_SECOND } from '../../utils/core/time/periodsInMillis';

export const URL = {
    COMPANY_FUNCTIONS: '/companies/{companyCode}/functions',
    COMPANY_FUNCTION_EMPLOYEES: '/companies/{companyCode}/functions/{functionId}/employees',
    FUNCTION: '/functions/{functionId}',
    FUNCTION_ISCO: '/functions/{functionId}/ISCO',
    FUNCTION_RISKS: '/functions/{functionId}/risks',
    FUNCTION_RESEARCHES: '/functions/{functionId}/researches',
};

export const DEFAULT_COMPANY_FUNCTIONS_FILTERS = {
    showUnmanned: false,
};

export function fetchCompanyFunctions(
    { companyCode, showFullFamily, keyword = '' }: IFetchCompanyFunctionsPayload,
    {
        showUnmanned = DEFAULT_COMPANY_FUNCTIONS_FILTERS.showUnmanned,
    }: ICompanyFunctionsFilter,
) {
    return get<ICompanyFunction[]>({
        url: URL.COMPANY_FUNCTIONS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            keyword,
            showFullFamily: showFullFamily.toString(),
            showUnmanned,
        },
        mapResponse: (response) => response.functions,
        timeoutInMillis: 30 * ONE_SECOND,
    });
}

export function addCompanyFunction(companyCode: string, requestBody: IAddCompanyFunctionPayload) {
    return post({
        url: URL.COMPANY_FUNCTIONS,
        pathParams: {
            companyCode,
        },
        body: requestBody,
        mapResponse: (response) => response.functionId,
    });
}

export function fetchCompanyFunctionIsco(functionId: string, localeOverride?: Locales) {
    return get<ICompanyFunctionIsco[]>({
        url: URL.FUNCTION_ISCO,
        pathParams: {
            functionId,
        },
        headers: localeOverride ? {
            [HEADER_NAME.LOCALE]: localeToBackendLocale(localeOverride),
        } : {},
        mapResponse: (response) => response.ISCO_entities,
    });
}

export function fetchCompanyFunctionEmployees(companyCode: string, functionId: string, showFullFamily: boolean) {
    return get<ICompanyFunctionEmployee[]>({
        url: URL.COMPANY_FUNCTION_EMPLOYEES,
        pathParams: {
            companyCode,
            functionId,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.employees,
    });
}

export function fetchCompanyFunctionRisks(
    functionId: string,
) {
    return get<IRisk[]>({
        url: URL.FUNCTION_RISKS,
        pathParams: {
            functionId,
        },
        mapResponse: (response) => response.risks,
    });
}

export function fetchCompanyFunctionResearches(
    functionId: string,
) {
    return get<IResearch[]>({
        url: URL.FUNCTION_RESEARCHES,
        pathParams: {
            functionId,
        },
        mapResponse: (response) => response.researches,
    });
}
