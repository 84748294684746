export enum EmployeeDetailsOverlayType {
    EditFunction,
    EditSeat,
    EditDateInFunction,
    EditStatutes,
    EditAbsenses,
    EditAddress,
    EditOutOfService,
    AddPersonalRisk,
    SetOutOfService,
    EditMedicalCenter,
    EditCostCenter,
    EditJobStudent,
}
