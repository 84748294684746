import React, { PureComponent, MouseEvent } from 'react';
import { clone } from 'ramda';

import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
    ITransformToActiveFiltersProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import {
    DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS,
} from '../../../../api/interventions/companyVisits/executed/executed.const';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import { connect } from '../../../index';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import {
    getExecutedCompanyVisitsAsyncInfo, getExecutedCompanyVisits, getCompanyVisitDocumentsAsyncInfo,
} from '../../../../redux/company/visits/selectors';
import { IExecutedCompanyVisit } from '../../../../models/interventions/company-visits';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { formatCompanyWithCity } from '../../../../utils/formatting/formatCompany';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import { fetchCompanyVisitDocuments } from '../../../../redux/company/visits/actions';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader';
import Dialog from '../../../common/modals/Dialog';
import { navigateTo } from '../../../../redux/location/actions';
import { PAYLOAD_PARAM } from '../../../../utils/libs/redux/async/asyncReducerUtils';
import { getQueryParams } from '../../../../redux/location/selectors';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';

const BASE_NAME = 'executed-company-visits';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IColumnNames {
    visitDate: string;
    visitDateSort: string;
    visitCode: string;
    company: string;
    visitor: string;
    description: string;
    actions: string;
    companyCodeForDownload: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'visitDate',
    sortOrder: SortOrder.Descending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    visitDate: {
        label: <Translate msg="interventions.company_visits.executed.columns.visit_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.visitDateSort,
        percentWidth: 15,
    },
    visitDateSort: {
        hide: true,
        percentWidth: null,
    },
    visitCode: {
        label: <Translate msg="interventions.company_visits.executed.columns.visit_code" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 10,
    },
    company: {
        label: <Translate msg="interventions.company_visits.executed.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    visitor: {
        label: <Translate msg="interventions.company_visits.executed.columns.visitor" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    description: {
        label: <Translate msg="interventions.company_visits.executed.columns.description" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
    companyCodeForDownload: {
        hide: true,
        percentWidth: null,
    },
};

interface IPrivateProps {
    downloadDocumentAsyncInfo: IAsyncFieldInfo;
    onDownloadClick: (id: string, companyCode: string) => void;
    dismissDocumentDialog: () => void;
}

type TCompanyVisitsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

function ExecutedCompanyVisits(props: IPrivateProps) {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
                asyncInfoSelector: getExecutedCompanyVisitsAsyncInfo,
                dataSelector: getExecutedCompanyVisits,
                transformData: mapCompanyVisitsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <CompanyVisitsList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'executed-company-visits',
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

function mapCompanyVisitsToListItems(masterData: IExecutedCompanyVisit[]): ListItem<IColumnNames>[] {
    return masterData
        .map((companyVisit) => ({
            id: companyVisit.id,
            columns: {
                visitDate: formatDateInLongFormat(companyVisit.visitDate),
                visitDateSort: companyVisit.visitDate,
                visitCode: companyVisit.visitCode,
                company: formatCompanyWithCity(companyVisit.company.name, companyVisit.city),
                visitor: formatPersonName(companyVisit.employee),
                description: companyVisit.visitKind,
                actions: null,
                companyCodeForDownload: companyVisit.company.companyCode,
            },
        }));
}

function mapListRowForExport(companyVisit: IExecutedCompanyVisit) {
    const {
        visitCode,
        visitDate,
        company: { companyCode },
        company: { name },
        employee,
        visitKind,
    } = companyVisit;

    return {
        company: {
            companyCode,
            name,
        },
        employee: formatPersonName(employee),
        visitCode,
        visitDate,
        visitKind,
    };
}

class CompanyVisitsList extends
    PureComponent<TCompanyVisitsListProps & IPrivateProps> {

    private columns = clone(COLUMNS);

    constructor(props: TCompanyVisitsListProps & IPrivateProps) {
        super(props);

        this.state = {
            isEditAppointmentDialogOpen: false,
            selectedItemId: null,
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {
            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg="interventions.company_visits.executed.actions.download" /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                </ListItemActions>
            );
        };
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(listItem.id as string, listItem.columns.companyCodeForDownload as string);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
            dismissDocumentDialog,
            downloadDocumentAsyncInfo,
        } = this.props;

        return (
            <>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    initialSort={INITIAL_SORT}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
                <Dialog
                    show={downloadDocumentAsyncInfo.status === AsyncStatus.Error}
                    onCloseIntent={() => dismissDocumentDialog()}
                    header="interventions.company_visits.executed.dialog.title"
                    type="error"
                >
                    <p>
                        <Translate msg="interventions.company_visits.executed.dialog.text" />
                    </p>
                    <div className="Dialog__buttons">
                        <Button
                            id="close-dialog"
                            typeName="secondary"
                            onClick={() => dismissDocumentDialog()}
                        >
                            <Translate msg="interventions.company_visits.executed.dialog.button" />
                        </Button>
                    </div>
                </Dialog>
                <Loader show={downloadDocumentAsyncInfo.status === AsyncStatus.Busy} />
            </>
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.company_visits.executed.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.company_visits.executed.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
    } = renderProps;

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.company_visits.executed.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS,
        isShowAll,
    } : DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.company_visits.executed.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.startDate,
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            downloadDocumentAsyncInfo: getCompanyVisitDocumentsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => ({
        onDownloadClick: (id: string, companyCode: string) => {
            dispatch(fetchCompanyVisitDocuments({ visitId: id, companyCode }));
        },
        dismissDocumentDialog: () => {
            const currentQuery = getQueryParams(getState());

            dispatch(navigateTo(
                ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
                {
                    [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                },
                {
                    ...currentQuery,
                },
            ));
        },
    }),
})(ExecutedCompanyVisits);
