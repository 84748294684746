import React, { Component } from 'react';
import dayjs from 'dayjs';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import {
    getEmployeeToAdd,
    getAddEmployeeWizardShouldUpdate,
    getUpdateEmployeeAllFieldsAndEmploymentAsyncInfo,
    getAddEmployeeAsyncInfo,
} from '../../../../../../redux/employee/wizard/selectors';
import {
    updateAddEmployeeData,
    addEmployeeActions,
    updateEmployeeAllFieldsAndEmploymentActions,
} from '../../../../../../redux/employee/wizard/actions';
import { IEmployeeToAdd } from '../../../../../../models/admin/employee';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { AsyncStatus } from '../../../../../../models/general/redux';
import Loader from '../../../../../common/waiting/Loader';
import { ITraceableApiError } from '../../../../../../models/general/error';
import FormError from '../../../../../common/forms/FormError';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import { formatDateForBackend } from '../../../../../../utils/formatting/formatDate';
import { getJobStudentRequestFailedWarning } from '../../../../../../redux/employee/info/selectors';
import ErrorDialog from '../../../../../common/modals/ErrorDialog';
import { setJobStudentRequestFailedWarningActions } from '../../../../../../redux/employee/info/actions';
import { now } from '../../../../../../utils/core/date/getSpecificDate';

const TRANSLATION_PREFIX = 'administration.employees.add.steps.startdate';

interface IPrivateProps {
    currentDateInFunction: string;
    updateEmployeeToAdd: (values: Pick<IEmployeeToAdd, 'dateInFunction' | 'dateInService'>) => void;
    employeeToAdd: IEmployeeToAdd;
    shouldUpdateOnNext: boolean;
    dispatchUpdateEmployeeAllFieldsAndEmployment: () => void;
    updateStatus: AsyncStatus;
    isBusy: boolean;
    error: ITraceableApiError;
    addStatus: AsyncStatus;
    dispatchAddEmployee: () => void;
    showJobStudentRequestFailedWarning: boolean;
    closeJobStudentRequestFailedWarning: () => void;
}

interface IState {
    date: string;
}

class InterimStartDate extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            date: formatDateForBackend(now()),
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.closeJobStudentWarningAndGoToNextStep = this.closeJobStudentWarningAndGoToNextStep.bind(this);

        this.renderJobStudentRequestFailedWarningDialog = this.renderJobStudentRequestFailedWarningDialog.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IStepperStepRenderProps) {
        if (this.props.shouldUpdateOnNext &&
            prevProps.updateStatus === AsyncStatus.Busy &&
            this.props.updateStatus === AsyncStatus.Success &&
            !this.props.showJobStudentRequestFailedWarning
        ) {
            this.props.goToNextStep();
        }

        if (prevProps.addStatus === AsyncStatus.Busy &&
            this.props.addStatus === AsyncStatus.Success &&
            !this.props.showJobStudentRequestFailedWarning
        ) {
            this.props.goToNextStep();
        }
    }

    public render() {
        const {
            renderStepButtons, employeeToAdd, isBusy, error, shouldUpdateOnNext, showJobStudentRequestFailedWarning,
            currentDateInFunction,
        } = this.props;
        const { date } = this.state;
        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    titlePlaceholders={{ name: formatPersonName(employeeToAdd) }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    {showJobStudentRequestFailedWarning && this.renderJobStudentRequestFailedWarningDialog()}
                    <Loader show={isBusy}>
                        <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                            <DatePicker
                                id="employee-add-start-date"
                                onChange={this.onChangeHandler}
                                value={date}
                                inlineCalendar={true}
                                hideTextInput={true}
                                minDate={(shouldUpdateOnNext && currentDateInFunction)
                                    ? formatDateForBackend(dayjs(currentDateInFunction).add(1, 'day')) : undefined}
                            />
                            {error && <FormError error={error} />}
                        </div>
                        <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    disabled: !date,
                                    onClick: this.onClickNextHandler,
                                    translationKey: shouldUpdateOnNext ?
                                        'administration.employees.add.button_label.edit' :
                                        'administration.employees.add.button_label.add',
                                },
                            })}
                        </StickyFooter>
                    </Loader>
                </div>
            </>
        );
    }

    public onChangeHandler(formattedDate: string) {
        this.setState({
            date: formattedDate,
        });
    }

    private onClickNextHandler() {
        const {
            employeeToAdd,
            updateEmployeeToAdd,
            shouldUpdateOnNext,
            dispatchUpdateEmployeeAllFieldsAndEmployment,
            dispatchAddEmployee,
        } = this.props;

        const date = this.state.date;

        if (shouldUpdateOnNext) {
            updateEmployeeToAdd({ dateInFunction: date, dateInService: employeeToAdd.dateInService });
            dispatchUpdateEmployeeAllFieldsAndEmployment();
        } else {
            updateEmployeeToAdd({ dateInFunction: date, dateInService: date });
            dispatchAddEmployee();
        }
    }

    private renderJobStudentRequestFailedWarningDialog() {
        return (
            <ErrorDialog
                showOverride={true}
                titleTranslationKey={`${TRANSLATION_PREFIX}.job_student_request_failed_dialog.title`}
                infoTranslationKey={`${TRANSLATION_PREFIX}.job_student_request_failed_dialog.text`}
                onCloseDialog={this.closeJobStudentWarningAndGoToNextStep}
                hideRealErrorMessage={true}
                hideCloseIcon={true}
            />
        );
    }

    private closeJobStudentWarningAndGoToNextStep() {
        this.props.closeJobStudentRequestFailedWarning();
        this.props.goToNextStep();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);

        const updateAsyncInfo = getUpdateEmployeeAllFieldsAndEmploymentAsyncInfo(state);
        const updateStatus = updateAsyncInfo.status;

        const addAsyncInfo = getAddEmployeeAsyncInfo(state);
        const addStatus = addAsyncInfo.status;

        return {
            employeeToAdd,
            currentDateInFunction: employeeToAdd && employeeToAdd.dateInFunction,
            shouldUpdateOnNext: getAddEmployeeWizardShouldUpdate(state),
            isBusy: updateStatus === AsyncStatus.Busy || addStatus === AsyncStatus.Busy,
            error: updateAsyncInfo.error || addAsyncInfo.error,
            updateStatus,
            addStatus,
            showJobStudentRequestFailedWarning: getJobStudentRequestFailedWarning(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateEmployeeToAdd: (values: Pick<IEmployeeToAdd, 'dateInFunction' | 'dateInService'>) => {
                dispatch(updateAddEmployeeData({ ...values }));
            },
            dispatchUpdateEmployeeAllFieldsAndEmployment: () => {
                dispatch(updateEmployeeAllFieldsAndEmploymentActions.trigger({}));
            },
            dispatchAddEmployee: () => {
                const employeeToAdd = getEmployeeToAdd(getState());
                dispatch(addEmployeeActions.trigger(employeeToAdd));
            },
            closeJobStudentRequestFailedWarning: () => {
                dispatch(setJobStudentRequestFailedWarningActions.trigger({ showWarning: false }));
            },
        };
    },
})(InterimStartDate);
