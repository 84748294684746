import React, { Component } from 'react';
import SeatInput, { IFormValues } from '../../FluVaccinesOrderWizard/wizard/Seat/SeatInput';
import { schema } from '../../FluVaccinesOrderWizard/wizard/Seat/fluVaccineSeatSchema';
import { IFormValues as IDetailFormValues } from '../../FluVaccinesOrders/detail';
import Button from '../../../common/buttons/Button';
import SubmitButton from '../../../common/buttons/SubmitButton';
import Form, { IFormRenderProps, TSetFieldValue } from '../../../common/forms/Form';
import Translate from '../../../common/Translate';
import { SLIDEOUTPANEL_CLASSES } from '../../../common/widget/SlideOutPanel/index';
import { ICompany, ICompanySeat } from '../../../../models/admin/company';

const CLASS_NAME = 'FluVaccinesOrderEditSeat';
const FORM_NAME = 'flu-vaccine-order-edit-seat-form';

interface IPublicProps {
    onConfirm: (data: ICompanySeat) => void;
    onClose: () => void;
    detailFormValues: IDetailFormValues;
}

class EditSeat extends Component<IPublicProps> {
    private setFormFieldValue: TSetFieldValue<IFormValues>;

    constructor(props) {
        super(props);

        this.seatChangedHandler = this.seatChangedHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const { onClose, detailFormValues } = this.props;

        const INITIAL_VALUES: IFormValues = {
            seat: mapCompanyToCompanySeat(detailFormValues.seat),
        };

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg="interventions.flu_orders_detail.edit_seat.title"
                        />
                    </h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onSubmit}
                    initialValues={INITIAL_VALUES}
                    schema={schema}
                    render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                        this.setFormFieldValue = formRenderProps.setFieldValue;

                        return (
                            <>
                                <div>
                                    <SeatInput
                                        formRenderProps={formRenderProps}
                                        onChangeInput={this.seatChangedHandler}
                                    />
                                </div>
                                <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                    <Button
                                        id="edit-seat-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={onClose}
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_seat.cancel" />
                                    </Button>
                                    <SubmitButton
                                        formName={FORM_NAME}
                                        id="edit-seat-submit-button"
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_seat.submit" />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />

            </div>
        );
    }

    private seatChangedHandler(values: IFormValues) {
        this.setFormFieldValue('seat', values.seat);
    }

    private onSubmit(values: IFormValues) {
        this.props.onConfirm(values.seat);
    }
}

export default EditSeat;

function mapCompanyToCompanySeat(company: ICompany): ICompanySeat {
    return {
        alternativeName: '',
        company,
    };
}
