import React from 'react';
import './go-back.scss';
import LinkToRoute from '../LinkToRoute';
import Translate from '../../Translate';
import { connect } from '../../../index';
import { Location } from 'redux-first-router';
import { getLocationState } from '../../../../redux/location/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import { ILocationAction } from '../../../../models/general/redux';
import Icon from '../../icons/Icon';
import classNames from 'classnames';
import Button from '../../buttons/Button';

interface IPrivateProps {
    prevLocation: Location;
}

interface IPublicProps {
    id: string;
    to?: ROUTE_KEYS | ILocationAction<object>;
    onClick?: () => void;
    iconTypeName?: 'chevron-left' | 'arrow-left';
    className?: string;
    preserveQuery?: boolean;
    labelTranslationKey?: string;
    ignoreTranslationContext?: boolean;
}

function GoBack(props: IPublicProps & IPrivateProps) {
    return (
        <div className={classNames('GoBack', props.className)}>
            {renderContent(props)}
        </div>
    );
}

function renderContent(props: IPublicProps & IPrivateProps) {
    if (typeof props.onClick === 'function') {
        return (
            <Button id="go-back" typeName="text" onClick={props.onClick}>
                {renderIconText(props)}
            </Button>
        );
    }
    return (
        <LinkToRoute
            id={props.id}
            to={props.to || (props.prevLocation as ILocationAction<{}>)}
            preserveQuery={props.preserveQuery}
        >
            {renderIconText(props)}
        </LinkToRoute>
    );
}

function renderIconText(props: IPublicProps & IPrivateProps) {
    const text = props.labelTranslationKey || 'common.go_back.label';

    return (
        <>
            <Icon typeName={props.iconTypeName || 'chevron-left'} />
            <Translate msg={text} ignoreContext={props.ignoreTranslationContext} />
        </>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            prevLocation: getLocationState(state).prev,
        };
    },
})(GoBack);
