import { HEADER_NAME } from '../../config/api.config';

export default function getFilenameFromHeaders(headers, defaultFilename = 'document.pdf') {
    if (headers && headers[HEADER_NAME.CONTENT_DISPOSITION]) {
        let matches = /"([^"]*)"/.exec(headers[HEADER_NAME.CONTENT_DISPOSITION]);
        if (matches != null && matches[1]) {
            return matches[1];
        }

        matches = /filename=([^"]*)/.exec(headers[HEADER_NAME.CONTENT_DISPOSITION]);
        return (matches != null && matches[1] ? matches[1] : defaultFilename);
    }

    return defaultFilename;
}

export const getFilenameFromBffHeaders = (
    headers,
    fileExtension: string,
    defaultFilename = 'document.pdf',
) => {
    // Content Disposition header value example:
    // eslint-disable-next-line max-len
    // attachment; filename=20211027_0812_Export_PlannedMedicalExamination.xlsx; filename*=UTF-8''20211027_0812_Export_PlannedMedicalExamination.xlsx"

    if (headers && headers[HEADER_NAME.CONTENT_DISPOSITION]) {
        const fileNameParameterToSearchFor = 'filename=';

        const start = headers[HEADER_NAME.CONTENT_DISPOSITION].indexOf(
            fileNameParameterToSearchFor,
        ) + fileNameParameterToSearchFor.length;
        const end = headers[HEADER_NAME.CONTENT_DISPOSITION].indexOf(
            fileExtension,
        ) + fileExtension.length;

        const filename = headers[HEADER_NAME.CONTENT_DISPOSITION].substring(start, end);

        // Check if start & end are found and if there are no whitespaces in the filename;
        if (start !== -1 && end !== -1 && !/\s/g.test(filename)) {
            return headers[HEADER_NAME.CONTENT_DISPOSITION].substring(start, end);
        }
    }

    return defaultFilename;
};
