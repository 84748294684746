import React from 'react';

import {
    getAdministeredVaccinations,
    getAdministeredVaccinationsAsyncInfo,
} from '../../../../redux/intervention/vaccinations/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import { connect } from '../../../index';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';

import { AdministeredVaccinationsList } from './AdministeredVaccinationsList/AdministeredVaccinationsList';
import {
AdministeredVaccinationsFilters,
} from './AdministeredVaccinationsFilters/AdministeredVaccinationsFilters.component';
import {
    TAdministeredVaccinationsFilterContentProps,
} from './AdministeredVaccinationsFilters/AdministeredVaccinationsFilters.type';
import { TAdministeredVaccinationsListProps } from './AdministeredVaccinationsList/AdministeredVaccinationList.types';
import { BASE_NAME } from './administered.const';
import {
    clientSideFilterOfListData,
    getDefaultQueryParams,
    mapAdministeredVaccinationsToListItems,
    mapListRowForExport,
    shouldRenderShowAllButton,
    toListId,
    transformFilterValuesToActiveFilters,
} from './administered.helpers';
import {
    IAdministeredVaccinationsPrivateProps,
    IAdministeredVaccinationsState,
} from './administered.types';

class AdministeredVaccinations extends React.Component<
    IAdministeredVaccinationsPrivateProps,
    IAdministeredVaccinationsState
> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render () {
        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    asyncInfoSelector: getAdministeredVaccinationsAsyncInfo,
                    clientSideFilterOfListData,
                    dataSelector: getAdministeredVaccinations,
                    filterValidationSchema: startEndDateSchema,
                    routeKey: ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED,
                    transformData: mapAdministeredVaccinationsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: TAdministeredVaccinationsListProps) => {
                        return (
                            <AdministeredVaccinationsList {...renderProps} {...this.props} {...this.state} />
                        );
                    },
                }}
                headerConfig={{
                    exportButton: {
                        baseFilename: 'administered-vaccinations',
                        listItemIdExtractor: toListId,
                        mapListRowForExport,

                    },
                    renderFilterContent:
                        (renderProps: TAdministeredVaccinationsFilterContentProps) =>
                            <AdministeredVaccinationsFilters {...renderProps} />,
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        );
    }
}

export default connect<IAdministeredVaccinationsPrivateProps>({
    stateProps: (state) => ({}),
    dispatchProps: (dispatch) => ({}),
})(AdministeredVaccinations);
