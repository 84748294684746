import React from 'react';
import { connect } from '../../index';
import classNames from 'classnames';
import './locale-switcher.scss';
import { LOCALES, Locales } from '../../../config/i18n.config';
import { getLocale } from '../../../redux/i18n/selectors';
import { switchLocaleActions } from '../../../redux/i18n/actions';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';

interface IPrivateProps {
    currentLocale: Locales;
    onSwitchLocale: (newLocale: Locales) => void;
}

function LocaleSwitcher({ currentLocale, onSwitchLocale }: IPrivateProps) {
    return (
        <div className="LocaleSwitcher">
            <span className="item active">
                <Translate msg={getLocaleTranslationLabel(currentLocale)} />
            </span>
            <div className="other-wrapper">
                <div className="other">
                    {LOCALES.map((locale) => {
                        return (
                            <span
                                className={classNames('item', {
                                    active: currentLocale === locale,
                                })}
                                key={`switch-locale-${locale}`}
                                onClick={() => onSwitchLocale(locale)}
                            >
                                <Translate msg={getLocaleTranslationLabel(locale)} />
                            </span>
                        );
                    })}
                    <Icon typeName="chevron-right" />
                </div>
            </div>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        currentLocale: getLocale(state),
    }),
    dispatchProps: (dispatch) => ({
        onSwitchLocale: (newLocale: Locales) => {
            dispatch(switchLocaleActions.trigger({ newLocale }));
        },
    }),
})(LocaleSwitcher);

function getLocaleTranslationLabel(locale) {
    return `app_shell.locale_switcher.locales.${locale.toLowerCase()}`;
}
