import { createEpic } from '../../index';
import {
    ADD_EMPLOYEE,
    FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA,
    UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
} from './types';
import {
    fetchEmployeeToAddInitialDataSucceeded, fetchEmployeeToAddInitialDataFailed,
    updateAddEmployeeData,
    setAddEmployeeWizardToUpdate,
    updateEmployeeAllFieldsAndEmploymentActions,
    addEmployeeActions,
} from './actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IEmployeeToAdd,
    IFetchEmployeeByInszPayload,
    IAddEmployeeWizardPayload,
    IUpdateEmployeeAllFieldsAndEmploymentPayload,
    IEmployeeJobStudent,
    IUpdateEmployeeSucceededPayload,
    IUpdateEmployeeAllFieldsAndEmploymentSucceededPayload,
    TEmployeeToAddJobStudentData,
    IAddEmployeeSucceededPayload,
} from '../../../models/admin/employee';
import { getSelectedCompany } from '../../company/selected/selectors';
import {
    getAddEmployeeWizardStepId,
    getEmployeeToAdd,
    getEmployeeToAddInitialData,
} from './selectors';
import { navigateTo } from '../../location/actions';
import isEmptyObject, { isFalsyOrEmptyObject } from '../../../utils/core/object/isEmptyObject';
import {
    compareObjectsAndReturnParamsWithDifferentValuesThanOtherObj,
} from '../../../utils/core/object/diffObjects';
import { removeEmptyPropsFromObject } from '../../../utils/core/object/objectProps';
import Api from '../../../api';
import { areCompanyCodesDifferent, getSelectedEmployeeJobStudent } from '../info/selectors';
import { stripExistingNationalRegisterNumberFormatting } from '../../../utils/formatting/formatNationalRegisterNumber';
import { ITraceableApiError } from '../../../models/general/error';
import { getRouteKey } from '../../location/selectors';
import { mapRouteKeyToSubmittedFormActionType } from '../../../utils/logging/analytics/mapToSubmittedFormActionType';
import { getAddEmployeeWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { setJobStudentRequestFailedWarningActions } from '../info/actions';
import { tryFormattingPhoneForBackend } from '../../../utils/formatting/formatPhone';
import { formatDateForBackend } from '../../../utils/formatting/formatDate';

// fetchEmployeeToAddInitialDataEpic
createEpic<IFetchEmployeeByInszPayload>({
    onActionType: FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA,
    async processMultiple({ api, action, getState }, dispatch, done) {
        const { isInterim, nationalRegisterNumber } = action.payload;
        const selectedCompanyCompanyCode = getSelectedCompany(getState()).companyCode;
        try {
            const employee = await api.admin.employee.fetchEmployeeByInsz({
                nationalRegisterNumber,
                selectedCompanyCompanyCode,
            });

            dispatch(fetchEmployeeToAddInitialDataSucceeded(employee));

            if (areCompanyCodesDifferent(employee.company.companyCode, selectedCompanyCompanyCode)) {
                dispatch(updateAddEmployeeData({
                    nationalRegisterNumber: employee.nationalRegisterNumber,
                    firstName: employee.firstName,
                    name: employee.name,
                    sexId: employee.sexId,
                    nationalityId: employee.nationalityId,
                    languageId: employee.languageId,
                    address: employee.address,
                    phone: employee.phone,
                    mobilePhone: employee.mobilePhone,
                    email: employee.email,
                    employeeNumber: '',
                    employeeId: employee.employeeId,
                }));
            } else {
                if (isInterim) {
                    dispatch(updateAddEmployeeData(employee));
                    // Instead of creating an employee during the flow, the flow will update an existing employee
                    dispatch(setAddEmployeeWizardToUpdate());
                }
                // else: The AddEmployeeWizard will show an already-exist-error
                //       + a link to the employee already existing in the company
            }
        } catch (error) {
            const errorObj = error as ITraceableApiError;
            if (errorObj.code === 404 &&
                errorObj.message === 'klantenzone.error.employee.insz_not_found'
            ) {
                dispatch(
                    updateAddEmployeeData({
                        nationalRegisterNumber: stripExistingNationalRegisterNumberFormatting(nationalRegisterNumber),
                    }));

                dispatch(fetchEmployeeToAddInitialDataSucceeded({})); // dispatch succeeded to set isFetching correctly
            } else {
                dispatch(fetchEmployeeToAddInitialDataFailed(error));
            }
        }
        done();
    },
    latest: false,
});

// addEmployeeEpic
createEpic<IEmployeeToAdd>({
    onActionType: ADD_EMPLOYEE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const addedEmployeeInfo = await api.admin.employee.addEmployee(createEmployeeToAddBody(action.payload));

            const currentRouteKey = getRouteKey(getState());

            dispatch(addEmployeeActions.succeeded(
                addedEmployeeInfo,
                {
                    logFormSubmissionEvent: () => mapRouteKeyToSubmittedFormActionType(currentRouteKey),
                },
            ));

            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            if (apiError.message === 'klantenzone.error.register_as_student') {
                const addedEmployeeInfo: IAddEmployeeSucceededPayload = {
                    employeeId: apiError.extraData.employeeId as unknown as number,
                    id: apiError.extraData.id as unknown as number,
                };

                const currentRouteKey = getRouteKey(getState());

                dispatch(setJobStudentRequestFailedWarningActions.trigger({ showWarning: true }));
                dispatch(addEmployeeActions.succeeded(
                    addedEmployeeInfo,
                    {
                        logFormSubmissionEvent: () => mapRouteKeyToSubmittedFormActionType(currentRouteKey),
                    },
                ));
            } else {
                dispatch(addEmployeeActions.failed(error));
            }
            done();
        }
    },
    latest: false,
});

function createEmployeeToAddBody(employeeToAdd: IEmployeeToAdd): Partial<IEmployeeToAdd> {
    return {
        ...(!!employeeToAdd.birthDate) && {
            birthDate: formatDateForBackend(employeeToAdd.birthDate),
        },
        ...(!!employeeToAdd.nationalRegisterNumber) && {
            nationalRegisterNumber: stripExistingNationalRegisterNumberFormatting(employeeToAdd.nationalRegisterNumber),
        },
        firstName: employeeToAdd.firstName,
        name: employeeToAdd.name,
        company: employeeToAdd.company && {
            companyCode: employeeToAdd.company.companyCode,
        },
        dateInFunction: employeeToAdd.dateInService,
        dateInService: employeeToAdd.dateInService,
        function: employeeToAdd.function && {
            id: employeeToAdd.function.id,
        },
        address: employeeToAdd.address && {
            zipCodeId: employeeToAdd.address.zipCodeId,
            street: employeeToAdd.address.street,
            number: employeeToAdd.address.number,
            box: employeeToAdd.address.box,
        },
        email: employeeToAdd.email,
        languageId: employeeToAdd.languageId,
        phone: tryFormattingPhoneForBackend(employeeToAdd.phone),
        mobilePhone: tryFormattingPhoneForBackend(employeeToAdd.mobilePhone),
        nationalityId: employeeToAdd.nationalityId,
        sexId: employeeToAdd.sexId,
        employeeNumber: employeeToAdd.employeeNumber,
        employeeId: employeeToAdd.employeeId,
        medicalCenterId: employeeToAdd.medicalCenterId,
        jobStudent: {
            ...employeeToAdd.jobStudent,
        },
        studentWorker: employeeToAdd.studentWorker,
    };
}

// updateEmployeeAllFieldsAndEmploymentEpic
createEpic<IUpdateEmployeeAllFieldsAndEmploymentPayload>({
    onActionType: UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();
            const newEmployeeValuesSetDuringFlow = getEmployeeToAdd(state);
            const initialEmployeeValuesSetAtBeginningOfFlow = getEmployeeToAddInitialData(state);
            const initialJobStudentData = getSelectedEmployeeJobStudent(state);

            const updatedFields = await updateEmployeeIfAtLeastOneValueChanged(
                newEmployeeValuesSetDuringFlow,
                initialEmployeeValuesSetAtBeginningOfFlow,
                api,
            );
            updatedFields.id = await updateEmploymentIfFunctionSeatOrDateInFunctionChanged(
                newEmployeeValuesSetDuringFlow,
                initialEmployeeValuesSetAtBeginningOfFlow,
                api,
            );

            let jobStudentError: ITraceableApiError = null;
            let updatedJobStudentData: TEmployeeToAddJobStudentData = null;

            try {
                updatedJobStudentData = await updateOrAddJobStudentIfAtLeastOneValueChanged(
                    {
                        ...newEmployeeValuesSetDuringFlow,
                        id: updatedFields.id,
                    },
                    initialJobStudentData,
                    api,
                );
            } catch (error) {
                jobStudentError = error;
            }

            if (jobStudentError) {
                dispatch(setJobStudentRequestFailedWarningActions.trigger({ showWarning: true }));
            }

            const successPayload = {
                employee: {
                    ...updatedFields,
                    jobStudent: updatedJobStudentData ?
                        updatedJobStudentData : newEmployeeValuesSetDuringFlow.jobStudent,
                },
            } as IUpdateEmployeeAllFieldsAndEmploymentSucceededPayload;

            dispatch(updateEmployeeAllFieldsAndEmploymentActions.succeeded(successPayload));

        } catch (error) {
            dispatch(updateEmployeeAllFieldsAndEmploymentActions.failed(error));
        }
        return done();
    },
    latest: false,
});

async function updateOrAddJobStudentIfAtLeastOneValueChanged(
    employee: IEmployeeToAdd,
    jobStudentInitial: IEmployeeJobStudent,
    api: typeof Api,
): Promise<TEmployeeToAddJobStudentData> {
    const newJobStudentData: TEmployeeToAddJobStudentData = {
        ...employee.jobStudent,
    };

    if (isFalsyOrEmptyObject(jobStudentInitial)) {
        if (employee.studentWorker) {
            await api.admin.employee.addEmployeeJobStudent({
                id: employee.id,
                sendMail: newJobStudentData.sendMail,
                employer: newJobStudentData.employer,
                medicalFunction: newJobStudentData.medicalFunction,
                safetyFunction: newJobStudentData.safetyFunction,
                emailAddressToUpdate: '',
            });
        }

        return newJobStudentData;
    }

    const initialJobStudentData: TEmployeeToAddJobStudentData = {
        employer: jobStudentInitial.employer,
        medicalFunction: jobStudentInitial.medicalFunction,
        safetyFunction: jobStudentInitial.safetyFunction,
        sendMail: undefined,
    };

    if (jobStudentInitial.studentWorker && !employee.studentWorker) {
        await api.admin.employee.updateEmployeeJobStudent({
            id: employee.id,
            studentWorker: false,
            sendMail: false,
            employer: '',
            medicalFunction: false,
            safetyFunction: false,
            interimEmployeeId: jobStudentInitial.interimEmployeeId,
            emailAddressToUpdate: '',
        });
        return newJobStudentData;
    }

    const jobStudentChanges =
        compareObjectsAndReturnParamsWithDifferentValuesThanOtherObj({
            obj: newJobStudentData,
            otherObj: initialJobStudentData,
            paramsToIgnore: [
                'sendMail',
            ] as (keyof TEmployeeToAddJobStudentData)[],
        }) as TEmployeeToAddJobStudentData;

    if (isEmptyObject(jobStudentChanges)) {
        return newJobStudentData;
    }

    await api.admin.employee
        .updateEmployeeJobStudent({
            id: employee.id,
            studentWorker: employee.studentWorker,
            sendMail: newJobStudentData.sendMail,
            employer: newJobStudentData.employer,
            medicalFunction: newJobStudentData.medicalFunction,
            safetyFunction: newJobStudentData.safetyFunction,
            interimEmployeeId: jobStudentInitial.interimEmployeeId,
            emailAddressToUpdate: '',
        });

    return newJobStudentData;
}

async function updateEmployeeIfAtLeastOneValueChanged(
    employee: IEmployeeToAdd,
    employeeInitial: Partial<IEmployeeToAdd>,
    api: typeof Api,
) {
    const employeeChanges = compareObjectsAndReturnParamsWithDifferentValuesThanOtherObj({
        obj: employee,
        otherObj: employeeInitial,
        paramsToIgnore: [
            'company',
            'dateInService',
            'dateInFunction',
            'function',
            'jobStudent',
            'studentWorker',
        ] as (keyof IEmployeeToAdd)[],
    });

    if (isEmptyObject(employeeChanges)) {
        return { id: employee.id } as IUpdateEmployeeSucceededPayload;
    }

    return await api.admin.employee
        .updateEmployee({ id: employee.id, employeeData: employeeChanges });
}

async function updateEmploymentIfFunctionSeatOrDateInFunctionChanged(
    employee: IEmployeeToAdd,
    employeeInitial: Partial<IEmployeeToAdd>,
    api: typeof Api,
) {
    const employmentChanges = {
        companyCode: employee.company.companyCode !== employeeInitial.company.companyCode ?
            employee.company.companyCode : undefined,
        customerFunctionId: employee.function.id !== employeeInitial.function.id ?
            employee.function.id : undefined,
        dateInFunction: employee.dateInFunction,
        dateInService: employeeInitial.dateInService,
    };

    const noUpdate = employmentChanges.companyCode === undefined &&
        employmentChanges.customerFunctionId === undefined;

    if (noUpdate) {
        return employee.id;
    }

    return await api.admin.employee.updateEmployeeEmployment({
        id: employee.id,
        ...removeEmptyPropsFromObject(employmentChanges),
    });
}

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the initial step.
 */
// validateAddEmployeeStepIdEpic
createEpic<IAddEmployeeWizardPayload>({
    onActionType: ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD,
    transform: ({ action, getState }, { next }) => {

        const stepIdOrder = getAddEmployeeWizardSteps().stepIds;
        const requestedStep = action.payload.step;
        if (!stepIdOrder || !stepIdOrder.includes(requestedStep)) {
            return next(navigateTo(ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD, { step: stepIdOrder[0] }));
        }

        const currentStep = getAddEmployeeWizardStepId(getState());
        const currentStepIndex = stepIdOrder.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = stepIdOrder.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(navigateTo(ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD, { step: stepIdOrder[0] }));
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});
