import React from 'react';
import { schema } from './fluVaccineRemarksSchema';
import RemarksInput, { IFormValues } from './RemarksInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    getFluVaccinesOrderWizardEntity,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import { updateFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/actions';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';

const CLASS_NAME = 'OrderFluVaccine__Remarks';
const FORM_NAME = 'flu-vaccine-remarks-form';

const RemarksWizardFormStep = WizardFormStep<IFormValues>();

export default function Remarks(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <RemarksWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.flu_orders_new.steps.remarks.title',
                textTranslationKey: 'interventions.flu_orders_new.steps.remarks.text',
            }}
            showLoaderSelector={() => false}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getFluVaccinesOrderWizardEntity(state);

                    return {
                        referentionCode: (wizardEntity.remarks && wizardEntity.remarks.referentionCode) || '',
                        remark: (wizardEntity.remarks && wizardEntity.remarks.remark) || '',
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <RemarksInput {...renderProps} />
                    </div>,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => updateFluVaccinesOrderWizardEntity({
                    remarks: values,
                }),
            }}
        />
    );
}
