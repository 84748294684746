import { createSelector } from 'reselect';
import { IState, getReducerState } from '..';
import { IReducerState, reducerKey } from './reducer';
import { INotification, NotificationType } from '../../models/general/notification';
import { isApplicationInitializationFailed } from '../ui/form/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

const getNotifications = (state: IState) => reducerState(state).notifications;

export const hasErrorNotifications = (state: IState) => {
    if (isApplicationInitializationFailed(state)) {
        return false;
    }
    return hasNotificationsOfType(state, NotificationType.Error);
};

export const hasNewVersionNotifications = (state: IState) =>
    hasNotificationsOfType(state, NotificationType.NewVersion);

export const getCustomNotifications = createSelector(
    getNotifications,
    (notifications) => notifications.filter(getNotificationTypeFilter(NotificationType.Custom)),
);

export const getNotificationIdsByType = (state: IState, type: NotificationType) =>
    getNotificationsByType(state, type)
        .map((notification) => notification.id);

const getNotificationsByType = (state: IState, type: NotificationType) =>
    getNotifications(state)
        .filter(getNotificationTypeFilter(type));

function getNotificationTypeFilter(type: NotificationType) {
    return (notification: INotification) => notification.type === type;
}

const hasNotificationsOfType = (state: IState, type: NotificationType) =>
    getNotifications(state)
        .filter(getNotificationTypeFilter(type))
        .length > 0;
