import { Permission } from '../auth/authorisation';
import { IAddress } from '../general/address';
import { IMedicalCenter } from './employee';
import { IExaminationReason } from '../interventions/medicalExaminations';

export enum CompanyOnboardingStatus {
    TRUE = 1,
    FALSE = 0,
}

export interface ICompany {
    address?: IAddress;
    companyCode: string;
    statusNotPaid?: CompanyOnboardingStatus;
    startWizardNewCustomer?: CompanyOnboardingStatus;
    id: number;
    name: string;
}

export interface ICompanyDismissable extends ICompany {
    dismissed: boolean;
}

export interface ICompanyDismissableWithDate extends ICompanyDismissable {
    dismissalDate?: string;
}

export interface ICompanySeat {
    address?: IAddress;
    alternativeName: string;
    company: Pick<ICompany, 'companyCode' | 'id' | 'name'>;
    employeeCount?: number;
    websiteStudentWorker?: boolean;
}

export interface ICompanyInterim {
    interim: boolean;
}

export interface ICompanyCodePayload {
    companyCode: string;
}

export interface ICompanyCodeWithShowFullFamilyPayload extends ICompanyCodePayload {
    showFullFamily: boolean;
}

export interface IStartEndDatePayload {
    startDate: string;
    endDate: string;
}

export interface IFetchCompaniesPayload {
    companyCodeOrNameFilter: string;
}

export interface ICompanySeatsData {
    extraPermissions: Permission[];
    seats: ICompanySeat[];
    interim: boolean;
}

export type TCompanyOnboardingVariables = Pick<ICompany, 'startWizardNewCustomer' | 'statusNotPaid'>;

export interface ISetCompanyOnboardingVariablesPayload extends TCompanyOnboardingVariables {
    companyCode: string;
}

export interface ISelectCompanySeatPayload {
    isAllSeatsSelected: boolean;
    companySeat: ICompanySeat;
}

export interface IFetchCompanySeatsPayload {
    companyCode: string;
    showFullSeats: boolean;
    noEmployeeCount?: boolean;
}

export interface ICompanyBufferzoneMedicalCenter
    extends Pick<IMedicalCenter, 'id' | 'name' | 'code' | 'hasCabinets' | 'prefered'> {
    address?: IAddress;
    freeSlots: number;
    totalSlots: number;
}

export interface ICompanyBufferzone {
    date: string;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    medicalCenter: ICompanyBufferzoneMedicalCenter;
    startTime: string;
    endTime: string;
    planningEntityId: number;
    allowedExaminations?: IAllowedExaminationReason[];
    duration: number;
}

export interface IAllowedExaminationReason extends Pick<IExaminationReason, 'id' | 'title'> {}
