import React from 'react';
import './select-seats-field.scss';
import { IFormRenderProps } from '../../../../../common/forms/Form';
import { IUserAccountDetail, TUserAccountSeat } from '../../../../../../models/user/userAccount';
import SeatTypeahead from '../../../../../administration/Employees/shared/SeatTypeahead';
import { ICompany } from '../../../../../../models/admin/company';
import { ITranslator } from '../../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import connect from '../../../../../../utils/libs/redux/connect';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import ListItemButton from '../../../../../common/buttons/ListItemButton';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button';

interface IPrivateProps {
    translator: ITranslator;
}

interface IValues extends Pick<IUserAccountDetail, 'seats'> {
    seatsToAdd?: TUserAccountSeat[];
    seatsToRemove?: TUserAccountSeat[];
}

interface ISelectSeatsFieldProps extends IFormRenderProps<IValues> {
    disabled?: boolean;
}

const CLASS_NAME = 'SelectSeatsField';

function SelectSeatsField(props: IPrivateProps & ISelectSeatsFieldProps) {

    const { values, errors, setFieldValue, touched, translator } = props;

    return (
        <div className={CLASS_NAME}>
            {!props.disabled &&
                <>
                    <SeatTypeahead
                        id="seats-typeahead"
                        name="seats-typeahead"
                        onItemSelected={(companyCode, name, seat) => {
                            if (!companyCode || !name) {
                                return;
                            }
                            setFieldValue('seats', (values.seats || []).concat([{
                                companyCode,
                                name,
                                id: seat.company.id,
                            }]));
                            if (values.seatsToAdd && values.seatsToRemove) {
                                const index = values.seatsToRemove
                                    .map((item) => item.companyCode)
                                    .indexOf(companyCode);
                                if (index !== -1) {
                                    values.seatsToRemove.splice(index, 1);
                                    setFieldValue('seatsToRemove', values.seatsToRemove);
                                } else {
                                    setFieldValue('seatsToAdd', [
                                        ...values.seatsToAdd,
                                        { companyCode, name },
                                    ]);
                                }
                            }
                        }}
                        onAllItemsSelected={(seats) => {
                            setFieldValue('seats', seats);
                        }}
                        // eslint-disable-next-line max-len
                        placeholder={translator('account.account_settings.manage_users.select_seats_field.placeholder')}
                        excludeDivisions={true}
                        excludeCompanyCodesFromItems={(values.seats || []).map((item) => item.companyCode)}
                        isInvalid={touched.seats && !!errors.seats}
                        hasSelectAllOption={true}
                        autoSelectOnSingleOptionIfNotAsync={true}
                    />
                    {touched.seats && (
                        <FormFieldError
                            error={errors.seats}
                            placeholders={{
                                // eslint-disable-next-line max-len
                                fieldName: translator('account.account_settings.manage_users.select_seats_field.field_name'),
                            }}
                        />
                    )}
                    {Array.isArray(values.seats) && values.seats.length > 0 &&
                        <div className={`${CLASS_NAME}__clear`}>
                            <Button
                                id="select-seats-field-clear"
                                onClick={() => setFieldValue('seats', [])}
                                typeName="text"
                            >
                                {/* eslint-disable-next-line max-len */}
                                <Translate msg="account.account_settings.manage_users.select_seats_field.clear_selection" />
                            </Button>
                        </div>
                    }
                </>
            }
            <div className={`${CLASS_NAME}__list`}>
                {(values.seats || []).sort(sortCompanyByName).map((seat, index) => {
                    return (
                        <ListItemButton
                            id={`seats-typeahead-item-${index}`}
                            key={seat.companyCode}
                            onClick={() => {
                                if (props.disabled) {
                                    return;
                                }
                                const newSeats = (values.seats || []).slice();
                                newSeats.splice(index, 1);
                                setFieldValue('seats', newSeats);
                                if (values.seatsToRemove) {
                                    setFieldValue('seatsToRemove', [
                                        ...values.seatsToRemove,
                                        seat,
                                    ]);
                                }
                            }}
                            disabled={props.disabled}
                        >
                            {seat.name}
                        </ListItemButton>
                    );
                })}
            </div>
        </div>
    );
}

export default connect<IPrivateProps, ISelectSeatsFieldProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
    }),
})(SelectSeatsField);

function sortCompanyByName(a: ICompany, b: ICompany) {
    if (a && a.name && b && b.name) {
        return a.name.localeCompare(b.name);
    }
    return 0;
}
