import { createSuccessAction, createFailAction, createAction } from '../../index';
import {
    FETCH_EXECUTED_COMPANY_VISITS,
    FETCH_PLANNED_COMPANY_VISITS,
    FETCH_COMPANY_VISIT_DOCUMENTS,
    UPDATE_COMPANY_VISITS_REQUEST_WIZARD_ENTITY,
    CREATE_COMPANY_VISITS_REQUEST,
} from './types';
import {
    IExecutedCompanyVisit,
    IPlannedCompanyVisit,
    IFetchCompanyVisitDocumentsPayload,
    ICompanyVisitRequestWizardPayload,
    COMPANY_VISITS_REQUEST_WIZARD_STEP_ID,
    ICompanyVisitRequestWizardEntity,
    ICreateCompanyVisitRequest,
} from '../../../models/interventions/company-visits';
import { ITraceableApiError } from '../../../models/general/error';
import { IDocument } from '../../../models/general/documents';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';
import ROUTE_KEYS from '../../../routeKeys';
import { navigateTo } from '../../location/actions';
import { createTypeActions } from '../../../utils/libs/redux/createAction';
import { getRequestCompanyVisitWizardSteps } from '../../../config/navigation/wizardStepsMap';

// Executed company visits
export function fetchExecutedCompanyVisitsSucceeded(payload: IExecutedCompanyVisit[]) {
    return createSuccessAction<IExecutedCompanyVisit[]>(FETCH_EXECUTED_COMPANY_VISITS, payload);
}

export function fetchExecutedCompanyVisitsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXECUTED_COMPANY_VISITS, error);
}

// Planned company visits
export function fetchPlannedCompanyVisitsSucceeded(payload: IPlannedCompanyVisit[]) {
    return createSuccessAction<IPlannedCompanyVisit[]>(FETCH_PLANNED_COMPANY_VISITS, payload);
}

export function fetchPlannedCompanyVisitsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_PLANNED_COMPANY_VISITS, error);
}

// Download visit documents
export function fetchCompanyVisitDocuments(payload: IFetchCompanyVisitDocumentsPayload) {
    return createAction<IFetchCompanyVisitDocumentsPayload>(FETCH_COMPANY_VISIT_DOCUMENTS, payload);
}

export function fetchCompanyVisitDocumentsSucceeded(payload: IDocument) {
    presentDownloadedFile(payload);

    return createSuccessAction<IDocument>(FETCH_COMPANY_VISIT_DOCUMENTS, payload);
}

export function fetchCompanyVisitDocumentsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_VISIT_DOCUMENTS, error);
}

// Company visits request
function navigateToCompanyVisitRequestWizardStep(payload: ICompanyVisitRequestWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW, payload);
}

export function triggerCompanyVisitRequestWizard() {
    return navigateToCompanyVisitRequestWizardStep({
        step: getRequestCompanyVisitWizardSteps().firstStepId,
        resetDataEntity: true,
    });
}

export function changeCompanyVisitRequestWizardStep(step: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID) {
    return navigateToCompanyVisitRequestWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updateCompanyVisitRequestWizardEntity(payload: Partial<ICompanyVisitRequestWizardEntity>) {
    return createAction<object>(
        UPDATE_COMPANY_VISITS_REQUEST_WIZARD_ENTITY,
        payload,
    );
}

// Request company visit
export const createCompanyVisitRequestActions = createTypeActions<ICreateCompanyVisitRequest>({
    type: CREATE_COMPANY_VISITS_REQUEST,
});
