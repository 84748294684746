import { patch } from '../../../../utils/api/requestWrapper';
import { TUpdateTimeSlotApiPayload } from '../../../../models/interventions/timeslots';

import { BFF_URL } from '../timeSlots.const';
import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { parseTimeSlotJsonPatchBody } from '../../../../utils/api/timeSlots/update/parseJsonPatchBody';

export const updateTimeSlot = ({
    locale,
    activityId,
    timeSlotId,
    remarks,
    newTimeSlot,
}: TUpdateTimeSlotApiPayload) => {
    return patch({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.TIMESLOT,
        pathParams: {
            activityId,
            timeSlotId,
        },
        body: parseTimeSlotJsonPatchBody({
            activityId: newTimeSlot.activityId,
            timeSlotId: newTimeSlot.id,
            comment: remarks,
        }),
    });
};
