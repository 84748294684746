import flatten from 'flat';
import localStorage from '@snipsonian/browser/es/storage/localStorage';
import { IMessages, IStoredTranslations } from '../../models/general/i18n';
import { LOCALES, Locales, TRANSLATIONS_STORAGE_KEY } from '../../config/i18n.config';
import shippedTranslations from '../../views/shippedTranslationsForInitialRender';

const areTranslationsRefreshedPerLocale: {
    [locale: string]: boolean;
} = {};

const allTranslations: {[locale: string]: IMessages} = getInitialTranslations();

export function setMessagesOfLocale({ locale, messages }: {locale: Locales, messages: {} | IMessages}) {
    allTranslations[locale] = messages;
    storeRefreshedTranslations(locale, messages);
    markTranslationsRefreshedForLocale(locale);
}

export function getMessagesOfLocale({ locale }: {locale: Locales}) {
    return allTranslations[locale];
}

export function getMessage({ locale, msgKey }: {locale: Locales, msgKey: string}) {
    return getMessagesOfLocale({ locale })[msgKey];
}

function getInitialTranslations() {
    const storedTranslations = getStoredTranslations();

    return LOCALES.reduce(
        (accumulator: typeof allTranslations, locale) => {
            accumulator[locale] = (storedTranslations && storedTranslations[locale])
                ? storedTranslations[locale]
                : flatten(shippedTranslations[locale]);

            return accumulator;
        },
        {},
    );
}

function getStoredTranslations(): IStoredTranslations {
    return localStorage.read({ key: TRANSLATIONS_STORAGE_KEY, defaultValue: {} }) as IStoredTranslations;
}

function storeRefreshedTranslations(locale: Locales, translations: IMessages) {
    const prevStoredTranslations = getStoredTranslations();

    const updatedTranslations: IStoredTranslations = {
        ...prevStoredTranslations,
        [locale]: translations,
    };

    localStorage.save({ key: TRANSLATIONS_STORAGE_KEY, value: updatedTranslations });
}

function markTranslationsRefreshedForLocale(locale: Locales) {
    areTranslationsRefreshedPerLocale[locale] = true;
}

export function areTranslationsRefreshedForLocale(locale: Locales) {
    return !!areTranslationsRefreshedPerLocale[locale];
}
