export const UPDATE_WORK_POST_CARD_WIZARD_ENTITY = 'UPDATE_WORK_POST_CARD_WIZARD_ENTITY';
export const RESET_WORK_POST_CARD_WIZARD = 'RESET_WORK_POST_CARD_WIZARD';

export const FETCH_WORK_POST_CARD_BLANK_FORMS = 'FETCH_WORK_POST_CARD_BLANK_FORMS';

export const CREATE_WORK_POST_CARD_FORM = 'CREATE_WORK_POST_CARD_FORM';

export const FETCH_WORK_POST_CARD_DEFAULT_VALUES = 'FETCH_WORK_POST_CARD_DEFAULT_VALUES';

export const FETCH_WORK_POST_FILE_OF_EMPLOYEE = 'FETCH_WORK_POST_FILE_OF_EMPLOYEE';
