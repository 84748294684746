import { object } from 'yup';
import { IAddPersonalRiskPayload } from '../../../../../models/admin/employee';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    riskId: 'riskId',
} as IAddPersonalRiskPayload;

export const schema = object({
    [fields.riskId]: string().required(),
});
