import { object } from 'yup';
import isNotSetOrEmptyString from '../../../../utils/core/string/isNotSetOrEmptyString';
import { ErrorTypes } from '../../../../models/general/error';
import phoneNumber from '../../../../utils/libs/yup/phoneNumber';
import string from '../../../../utils/libs/yup/string';

export const fields = {
    phone: 'phone',
    mobilePhone: 'mobilePhone',
    email: 'email',
};

export const schema = object({
    [fields.phone]: phoneNumber(),
    [fields.mobilePhone]: phoneNumber()
        .when(fields.phone, {
            is: isNotSetOrEmptyString,
            then: phoneNumber().test(
                ErrorTypes.Custom,
                'interventions.contact_input.error.either_phone_or_mobilephone',
                (value) => !isNotSetOrEmptyString(value),
            ),
        }),
    [fields.email]: string().email().required(),
});
