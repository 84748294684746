import React, { Component } from 'react';
import classNames from 'classnames';
import '../policy-advice.scss';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import { IPolicyAdviceComment, IPolicyAdvice } from '../../../../models/interventions/policyAdvice';
import Translate from '../../../common/Translate';
import { DETAIL_TRANSLATION_PREFIX } from '../common';
import { formatDateForDisplay, formatDateForBackend } from '../../../../utils/formatting/formatDate';
import TinyLoader from '../../../common/waiting/TinyLoader';
import {
    getPolicyAdviceCommentsAsyncInfo,
    getSendPolicyAdviceCommentAsyncInfo,
    getPolicyAdvice,
    getPolicyAdviceComments,
} from '../../../../redux/intervention/policyAdvice/selectors';
import { connect } from '../../..';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import { getRoutePayload } from '../../../../redux/location/selectors';
import { getSelectedSeatCompanyCode } from '../../../../redux/company/selected/selectors';
import { sendPolicyAdviceCommentActions } from '../../../../redux/intervention/policyAdvice/actions';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import { stopPropagation } from '../../../../utils/browser/events/stopPropagation';
import { navigateTo } from '../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../routeKeys';
import Icon from '../../../common/icons/Icon';
import tinyLoaderAnimation from '../../../assets/img/animated/tiny-loader.gif';
import ReadMore from '../../../common/widget/ReadMore';

const CLASS_NAME = 'PolicyAdviceDetailContent';
const COMMENT_BASE_ID = 'policy-advice-comment';

interface IPrivateProps {
    sendPolicyAdviceCommentAsyncInfo: IAsyncFieldInfo;
    policyAdviceComments: IPolicyAdviceComment[];
    retrySendComment: (comment: string) => void;
    policyAdviceId: number;
    policyAdvice: IPolicyAdvice[];
}

class DetailContentComp extends Component<IRenderDetailContentProps<IPolicyAdviceComment[]> & IPrivateProps>{

    constructor(props: IRenderDetailContentProps<IPolicyAdviceComment[]> & IPrivateProps) {
        super(props);

        this.renderComment = this.renderComment.bind(this);
        this.previousCommentHasSameMetaData = this.previousCommentHasSameMetaData.bind(this);
        this.renderPlaceholderComment = this.renderPlaceholderComment.bind(this);
        this.previousCommentHasSameDate = this.previousCommentHasSameDate.bind(this);
    }

    public componentDidUpdate(
        prevProps: IPrivateProps & IRenderDetailContentProps<IPolicyAdviceComment[]>,
    ) {
        if (prevProps.sendPolicyAdviceCommentAsyncInfo.status !== AsyncStatus.Busy &&
            this.props.sendPolicyAdviceCommentAsyncInfo.status === AsyncStatus.Busy
        ) {
            const { policyAdviceComments } = this.props;
            const lastCommentElement = document.getElementById(`${COMMENT_BASE_ID}-${policyAdviceComments.length - 1}`);
            if (lastCommentElement) {
                lastCommentElement.scrollIntoView();
            }
        }
    }

    public render() {
        const {
            detailData: selectedPolicyAdviceComments,
            policyAdvice,
            policyAdviceId,
        } = this.props;

        const policyAdviceFromList = policyAdvice.find((item) => item.id === policyAdviceId);

        if (!policyAdviceFromList) {
            return null;
        }

        return (
            <div className={CLASS_NAME}>
                {this.renderInterventionDate(policyAdviceFromList)}
                <ReadMore
                    id="policy-advice-detail-text"
                    characterLimit={210}
                    text={policyAdviceFromList.checklistComment}
                />
                <h6><Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.overview.subtitle`} /></h6>
                <TinyLoader asyncInfoSelector={getPolicyAdviceCommentsAsyncInfo} >
                    <div className={`${CLASS_NAME}__comments-container`}>
                        {selectedPolicyAdviceComments.length > 0 ?
                            selectedPolicyAdviceComments.map(this.renderComment) :
                            <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.overview.no_feedback`} />
                        }
                    </div>
                </TinyLoader>
            </div>
        );
    }

    private renderInterventionDate(policyAdvice: IPolicyAdvice) {
        if (!policyAdvice) {
            return '';
        }

        const dateForRedirectFilters =
            policyAdvice.companyVisitDate ? formatDateForBackend(policyAdvice.companyVisitDate) : '';

        const query: IStartEndDateFilterValues = {
            startDate: dateForRedirectFilters,
            endDate: dateForRedirectFilters,
        };

        const formattedDate = policyAdvice.companyVisitDate ? formatDateForDisplay(policyAdvice.companyVisitDate) : '';

        return (
            <div className={`${CLASS_NAME}__link`}>
                <p>
                    <LinkToRoute
                        id="policy-advice-detail-company-visit-link"
                        key="policy-advice-detail-company-visit-link"
                        onClick={stopPropagation}
                        to={navigateTo(ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED, {}, query)}
                    >
                        <Translate
                            msg={`${DETAIL_TRANSLATION_PREFIX}.subtitle`}
                            placeholders={{
                                visitDate: formattedDate,
                            }}
                        />
                    </LinkToRoute>
                </p>
            </div>
        );
    }

    private renderComment(policyAdviceComment: IPolicyAdviceComment, index: number) {
        if (policyAdviceComment.placeholder) {
            return this.renderPlaceholderComment(policyAdviceComment, index);
        }

        const hideMetaData = this.previousCommentHasSameMetaData(policyAdviceComment, index);
        const formattedDate = policyAdviceComment.date ? formatDateForDisplay(policyAdviceComment.date) : '';

        const commentClasses = classNames(`${CLASS_NAME}__comment`, {
            customer: policyAdviceComment.userComment,
        });

        return (
            <div
                id={`${COMMENT_BASE_ID}-${index}`}
                className={commentClasses}
                key={index}
            >
                {!hideMetaData && (
                    <div className="metadata">
                        <span className="user">
                            {policyAdviceComment.userComment
                                ? <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.message.customer`} />
                                : <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.message.mensura`} />}
                        </span>
                        <span className="date">{formattedDate}</span>
                    </div>
                )}
                <div className="message">{policyAdviceComment.comment}</div>
            </div>
        );
    }

    private renderPlaceholderComment(
        placeholderComment: Pick<IPolicyAdviceComment, 'comment' | 'placeholder' | 'date'>,
        index: number,
    ) {
        const { sendPolicyAdviceCommentAsyncInfo, retrySendComment } = this.props;

        const hasError = !!sendPolicyAdviceCommentAsyncInfo.error;
        const isBusy = sendPolicyAdviceCommentAsyncInfo.status === AsyncStatus.Busy;

        const hideMetaData = this.previousCommentHasSameDate(placeholderComment, index);
        const formattedDate = formatDateForDisplay(placeholderComment.date);

        const commentClasses = classNames(`${CLASS_NAME}__comment`, 'customer', {
            busy: isBusy,
            error: hasError,
        });

        return (
            <div
                id={`${COMMENT_BASE_ID}-${index}`}
                className={commentClasses}
                key={index}
                onClick={hasError ? () => retrySendComment(placeholderComment.comment) : undefined}
            >
                {!hideMetaData && (
                    <div className="metadata">
                        <span className="user">
                            <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.message.customer`} />
                        </span>
                        <span className="date">{formattedDate}</span>
                    </div>
                )}
                <div className="message">
                    {placeholderComment.comment}
                    {hasError && <Icon typeName="warning" colorType="danger" circle />}
                </div>
                {isBusy && (
                    <div className="TinyLoader">
                        <div className="content">
                            <img src={tinyLoaderAnimation} />
                        </div>
                    </div>
                )}
                {hasError && (
                    <div className="error">
                        <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.message.error`} />
                    </div>
                )}
            </div>
        );
    }

    private previousCommentHasSameMetaData(
        comment: Pick<IPolicyAdviceComment, 'userComment' | 'userId' | 'date'>,
        index: number,
    ) {
        const {
            detailData: selectedPolicyAdviceComments,
        } = this.props;

        const previousComment = selectedPolicyAdviceComments[index - 1];

        if (!previousComment) {
            return false;
        }

        return (previousComment.date === comment.date &&
            previousComment.userId === comment.userId &&
            previousComment.userComment === comment.userComment);
    }

    private previousCommentHasSameDate(comment: Pick<IPolicyAdviceComment, 'date'>, index: number) {
        const {
            detailData: selectedPolicyAdviceComments,
        } = this.props;

        const previousComment = selectedPolicyAdviceComments[index - 1];

        if (!previousComment) {
            return false;
        }

        return previousComment.date === comment.date;
    }
}

export const DetailContent = connect<IPrivateProps, IRenderDetailContentProps<IPolicyAdviceComment[]>>({
    stateProps: (state) => {
        const policyAdviceId = Number(getRoutePayload<{ id: string }>(state).id);

        return {
            sendPolicyAdviceCommentAsyncInfo: getSendPolicyAdviceCommentAsyncInfo(state),
            policyAdviceComments: getPolicyAdviceComments(state),
            policyAdviceId,
            policyAdvice: getPolicyAdvice(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            retrySendComment: (comment: string) => {
                const state = getState();
                const id = Number(getRoutePayload<{ id: string }>(state).id);
                const companyCode = getSelectedSeatCompanyCode(state);

                dispatch(sendPolicyAdviceCommentActions.trigger({
                    id,
                    companyCode,
                    comment,
                }));
            },
        };
    },
})(DetailContentComp);
