import React, { Component } from 'react';
import connect from '../../../utils/libs/redux/connect';
import GreenDocumentCenterPage from '../shared/GreenDocumentCenterPage';
import DownloadDocuments, { IRenderProps } from '../shared/DownloadDocuments';
import DownloadButton from '../shared/DownloadButton';
import UploadOrSendDocument, { IUploadFileProps } from '../shared/UploadOrSendDocument';
import ListItemBordered from '../../common/list/ListItemBordered';
import Translate from '../../common/Translate';
import {
    DOC_TYPES,
    downloadIdentificationDocument,
    uploadActiveIdentificationDocument,
} from '../../../api/documentCenter/identificationDocument.api';
import { getSelectedSeatCompanyCode } from '../../../redux/company/selected/selectors';
import { DOC_URL } from '../../../config/navigation/documentCenter.config';
import openInNewTab from '../../../utils/browser/openInNewTab';
import { getLocale } from '../../../redux/i18n/selectors';
import { Locales } from '../../../config/i18n.config';

const docTypes = Object.values(DOC_TYPES);

interface IPrivateProps {
    companyCode: string;
    locale: string;
}

class ManageIdentificationDocument extends Component<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);

        this.renderDownloadBorderedList = this.renderDownloadBorderedList.bind(this);
        this.uploadSelectedIdentificationDocument = this.uploadSelectedIdentificationDocument.bind(this);
    }

    public render() {
        return (
            <GreenDocumentCenterPage
                titleTranslationKey="document_center.identification_doc.title"
                introTranslationKey="document_center.identification_doc.intro"
            >
                <div className="DocumentCenterPageContent ManageIdentificationDocument">
                    <DownloadDocuments
                        companyCode={this.props.companyCode}
                        docTypes={docTypes}
                        downloadDocumentApi={downloadIdentificationDocument}
                        renderContent={this.renderDownloadBorderedList}
                    />

                    <UploadOrSendDocument
                        baseId="identification-document"
                        uploadFileApi={this.uploadSelectedIdentificationDocument}
                    />
                </div>
            </GreenDocumentCenterPage>
        );
    }

    private renderDownloadBorderedList(renderProps: IRenderProps) {
        const {locale} = this.props;
        const docLocale = locale === Locales.fr_BE
            ? DOC_URL.psycho_social_prevention_advisors_fr
            : DOC_URL.psycho_social_prevention_advisors_nl;
        return (
            <>
                <ListItemBordered
                    text={
                        <>
                            <span>
                                <Translate msg="document_center.identification_doc.download.active_doc" />
                            </span>
                            <DownloadButton
                                id="download-active"
                                disabled={renderProps.isDownloading(DOC_TYPES.ACTIVE)}
                                onClick={() => renderProps.downloadDocument(DOC_TYPES.ACTIVE)}
                            />
                        </>
                    }
                />
                <ListItemBordered
                    text={
                        <>
                            <span>
                                <Translate msg="document_center.identification_doc.download.template_doc" />
                            </span>
                            <DownloadButton
                                id="download-template"
                                disabled={renderProps.isDownloading(DOC_TYPES.TEMPLATE)}
                                onClick={() => renderProps.downloadDocument(DOC_TYPES.TEMPLATE)}
                            />
                        </>
                    }
                />
                <ListItemBordered
                    text={
                        <>
                            <span>
                                <Translate msg="document_center.identification_doc.download.addendum" />:&nbsp;
                                {/*eslint-disable-next-line max-len*/}
                                <Translate msg="document_center.identification_doc.download.psycho_social_prevention_advisors" />
                            </span>
                            <DownloadButton
                                id="download-addendum-prev-advisors"
                                onClick={() => openInNewTab(docLocale)}
                            />
                        </>
                    }
                />
            </>
        );
    }

    private uploadSelectedIdentificationDocument({ fileToUpload }: IUploadFileProps) {
        return uploadActiveIdentificationDocument({
            companyCode: this.props.companyCode,
            fileToUpload,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        companyCode: getSelectedSeatCompanyCode(state),
        locale: getLocale(state),
    }),
})(ManageIdentificationDocument);
