import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, TDropdownItemValue } from '../';
import Translate from '../../../Translate';
import { IDropdownItem } from '../DropdownItem';
import { TBackendLocale } from '../../../../../models/general/i18n';

interface ILocaleDropdownProps {
    id: string;
    value: string;
    onItemSelected: (value: TDropdownItemValue) => void;
}

interface IState {
    isOpen: boolean;
}

interface LocaleDropDownItem extends IDropdownItem {
    value: TBackendLocale;
}

const LANGUAGE_ITEMS: LocaleDropDownItem[] = [{
    label: 'common.dropdown.locale.label.nl',
    value: 'nl',
}, {
    label: 'common.dropdown.locale.label.fr',
    value: 'fr',
}];

export default class LocaleDropdown extends PureComponent<ILocaleDropdownProps, IState>{
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.onDropdownToggle = this.onDropdownToggle.bind(this);
    }

    public render() {
        const { value, onItemSelected, id } = this.props;
        const { isOpen } = this.state;

        return (
            <Dropdown
                id={id}
                isOpen={isOpen}
                onOpenIntent={this.onDropdownToggle}
                onCloseIntent={this.onDropdownToggle}
                onItemSelected={onItemSelected}
                selectedItem={value}
                defaultLabel={<Translate msg="common.dropdown.locale.placeholder"/>}
                iconTypeName="primary"
            >
                {LANGUAGE_ITEMS.map((item: IDropdownItem) =>
                    (
                        <DropdownItem
                            key={`dropdown-item-${item.value}`}
                            value={item.value}
                        >
                            <Translate msg={item.label} />
                        </DropdownItem>
                    ),
                )}
            </Dropdown>
        );
    }

    private onDropdownToggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
