import React, { useMemo, useRef, useEffect } from 'react';
import { pathOr } from 'ramda';

import { AsyncStatus } from '../../../../../models/general/redux';
import { getDisplayName } from '../../../../../redux/auth/selectors';
import { getRequestVaccinationAsyncInfo } from '../../../../../redux/intervention/vaccinations/selectors';
import { IRequestVaccinationPayload } from '../../../../../models/interventions/vaccines';
import { requestVaccinationActions } from '../../../../../redux/intervention/vaccinations/actions';
import { usePrevious } from '../../../../../utils/hooks/usePrevious';
import { connect } from '../../../../index';
import Dialog from '../../../../common/modals/Dialog';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import Loader from '../../../../common/waiting/Loader';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import TextInput from '../../../../common/input/TextInput';
import Translate from '../../../../common/Translate';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

import {
    CLASS_NAME,
    FIELD_NAME_PREFIX,
    fields,
    FORM_NAME,
    requestVaccinationDialogInitialValues,
    TRANSLATION_PREFIX,
} from './RequestVaccinationDialog.const';
import { formatDateIfAvailable, FormTextInput, getPrefilledValues } from './RequestVaccinationDialog.helper';
import {
    IRequestVaccinationDialogPrivateProps,
    IRequestVaccinationFormValues,
    IRequestVaccinationDialogPublicProps,
    TRequestVaccinationDialogProps,
} from './RequestVaccinationDialog.type';
import { schema } from './RequestVaccinationDialog.schema';

const RequestVaccinationDialogComponent = ({
    onClose,
    show,
    asyncInfo,
    requestor,
    selectedVaccination,
    requestVaccination,
    resetAsyncInfo,
}: TRequestVaccinationDialogProps) => {
    const previousAsyncInfoStatus = usePrevious(asyncInfo.status);
    const previousShow = usePrevious(show);

    const formRender = useRef(null);

    const memoizedDate = useMemo<string>(() => {
        const date = pathOr(null, ['columns', 'dateToAdministerSort'], selectedVaccination);

        return formatDateIfAvailable(date);

    }, [selectedVaccination]);

    const initialValues = {
        ...getPrefilledValues(selectedVaccination),
        comment: '',
        phoneNumber: '',
    } as IRequestVaccinationFormValues;

    const onSubmit = (values: IRequestVaccinationFormValues) => {
        if (!selectedVaccination) {
            return;
        }

        const { phoneNumber, comment } = values;
        const employeeCode = pathOr<string>('', ['columns', 'employeeCode'], selectedVaccination);

        if (!employeeCode.length) {
            return;
        }

        requestVaccination({
            ...getPrefilledValues(selectedVaccination),
            phoneNumber,
            comment,
            employeeCode,
        });

    };

    useEffect(() => {
        if (show && !previousShow && formRender) {
            resetAsyncInfo();
            formRender.current.resetForm(requestVaccinationDialogInitialValues);
        }

        if (previousAsyncInfoStatus === AsyncStatus.Busy && asyncInfo.status === AsyncStatus.Success) {
            onClose();
        }
    }, [
        previousAsyncInfoStatus,
        asyncInfo,
        onClose,
        show,
        previousShow,
        formRender,
        resetAsyncInfo,
        initialValues,
    ]);

    return (
        <>
            <Dialog
                className={CLASS_NAME}
                show={show}
                onCloseIntent={onClose}
            >
                <h1><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h1>
                <Form
                        name={FORM_NAME}
                        handleSubmit={onSubmit}
                        schema={schema}
                        initialValues={initialValues}
                        render={(formRenderProps: IFormRenderProps<IRequestVaccinationFormValues>) => {
                            const { errors, values, handleChange } = formRenderProps;

                            formRender.current = formRenderProps;

                            return (
                                <>
                                    <Loader show={asyncInfo.status === AsyncStatus.Busy} />

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-employee-name"
                                            name="request-vaccination-employee-name"
                                            value={pathOr('', ['columns', 'name'], selectedVaccination)}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-employee-name">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.employee_name`} />
                                        </label>
                                    </FloatableTextInputWrapper>

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-employee-seat"
                                            name="request-vaccination-employee-seat"
                                            value={pathOr('', ['columns', 'employer'], selectedVaccination)}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-employee-seat">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.seat`} />
                                        </label>
                                    </FloatableTextInputWrapper>

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-type"
                                            name="request-vaccination-type"
                                            value={pathOr('', ['columns', 'description'], selectedVaccination)}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-type">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.type`} />
                                        </label>
                                    </FloatableTextInputWrapper>

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-cycle-step"
                                            name="request-vaccination-cycle-step"
                                            value={pathOr('', ['columns', 'injectionDescription'], selectedVaccination)}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-cycle-step">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.cycle_step`} />
                                        </label>
                                    </FloatableTextInputWrapper>

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-deadline"
                                            name="request-vaccination-deadline"
                                            value={memoizedDate}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-deadline">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.deadline`} />
                                        </label>
                                    </FloatableTextInputWrapper>

                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-comment"
                                            name={fields.comment}
                                            value={values.comment || ''}
                                            isInvalid={!!errors.comment}
                                            onChange={handleChange}
                                            multiLine={true}
                                            disableMultilineResize={true}
                                            rows={3}
                                            disableAutoComplete={true}
                                        />
                                        <label htmlFor="request-vaccination-comment">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.comment`} />
                                        </label>
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="request-vaccination-requestor"
                                            name="request-vaccination-requestor"
                                            value={requestor || '-'}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                        <label htmlFor="request-vaccination-requestor">
                                            <Translate msg={`${FIELD_NAME_PREFIX}.employee_name`} />
                                        </label>
                                    </FloatableTextInputWrapper>
                                    <FormTextInput
                                        name="phoneNumber"
                                        baseId={FORM_NAME}
                                        baseTranslationKey={FIELD_NAME_PREFIX}
                                        fields={fields}
                                        formRenderProps={formRenderProps}
                                    />
                                    {asyncInfo.error && <ErrorPlaceholder apiError={asyncInfo.error} />}
                                    <div className={`${CLASS_NAME}__actions`}>
                                        <SubmitButton
                                            id="request-vaccination-submit-button"
                                            formName={FORM_NAME}
                                            alwaysEnabled
                                        >
                                            <Translate msg={`${FIELD_NAME_PREFIX}.submit`} />
                                        </SubmitButton>
                                    </div>
                                </>
                            );
                        }}
                />
            </Dialog>
        </>);
};

const TranslatedRequestTimeslotDialog = (
    props: IRequestVaccinationDialogPublicProps & IRequestVaccinationDialogPrivateProps,
) => {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <RequestVaccinationDialogComponent {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
};

const RequestVaccinationDialog = connect<IRequestVaccinationDialogPrivateProps>({
    stateProps: (state) => {
        return {
            requestor: getDisplayName(state) || '',
            asyncInfo: getRequestVaccinationAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => ({
        requestVaccination: (payload: IRequestVaccinationPayload) => {
            dispatch(requestVaccinationActions.trigger(payload));
        },
        resetAsyncInfo: () => {
            dispatch(requestVaccinationActions.reset({}));
        },
    }),
})(TranslatedRequestTimeslotDialog);

export { RequestVaccinationDialog };
