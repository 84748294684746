import React, { DragEvent } from 'react';
import './top-task-list.scss';
import { ITopTask } from '../../../../../models/user/topTasks';
import Translate from '../../../../common/Translate';
import classNames from 'classnames';
import { connect } from '../../../..';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { SVG_GROUP_NAME } from '../../../../../models/general/lazyLoadSvg';
import LazyLoadSVG from '../../../../common/widget/LazyLoadSVG';

export interface ISelectableTopTask extends ITopTask {
    dirty: boolean;
    isSelected: boolean;
    selectionNumber?: number;
}

interface IPrivateProps {
    translator: ITranslator;
}

interface ITopTasksListProps {
    categoryValue: string;
    tasks: ISelectableTopTask[];
}

interface ITopTasksListItemProps {
    task: ITopTask;
    dirty: boolean;
    isSelected: boolean;
    selectionNumber?: number;
}

function TopTasksList(props: ITopTasksListProps & IPrivateProps) {
    const CLASS_NAME = 'TopTasksList';

    return (
        <div className={CLASS_NAME}>
            <header className={`${CLASS_NAME}__header`}>
                <strong><Translate msg={`home.top_tasks.categories.${props.categoryValue}`} /></strong>
            </header>
            <div className={`${CLASS_NAME}__list`}>
                {props.tasks.sort((a, b) => {
                    const labelA = props.translator(a.translationKey);
                    const labelB = props.translator(b.translationKey);
                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                }).map((task) =>
                    <TopTasksListItem
                        key={task.id}
                        task={task}
                        isSelected={task.isSelected}
                        selectionNumber={task.selectionNumber}
                        dirty={task.dirty}
                    />)}
            </div>
        </div>
    );
}

function TopTasksListItem(props: ITopTasksListItemProps) {
    const CLASS_NAME = 'TopTasksListItem';

    const onDragStart = (ev: DragEvent<HTMLDivElement>) => {
        ev.dataTransfer.setData('Text', props.task.id);
    };

    const renderContent = () => {
        return (
            <>
                <LazyLoadSVG id={props.task.lazyLoadSvgId} group={SVG_GROUP_NAME.TOP_TASKS} />
                <span className={`${CLASS_NAME}__text`}><Translate msg={props.task.translationKey} /></span>
            </>
        );
    };

    if (props.isSelected) {
        const classes = classNames(CLASS_NAME, `${CLASS_NAME}--selected`, {
            [`${CLASS_NAME}--dirty`]: props.dirty,
        });
        return (
            <div className={classes}>
                <div>
                    {renderContent()}
                    <span className={`${CLASS_NAME}__selection-number`}>{props.selectionNumber}</span>
                </div>
            </div>
        );
    }

    return (
        <div className={CLASS_NAME} draggable onDragStart={onDragStart}>
            <div>
                {renderContent()}
            </div>
        </div>
    );
}

export default connect<IPrivateProps, ITopTasksListProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
    }),
})(TopTasksList);
