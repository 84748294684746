import React, { PureComponent } from 'react';
import './address-map-link.scss';
import { ICMSCourseAddress } from '../../../../models/documentCenter/courses';
import OutgoingLink from '../OutgoingLink';
import Icon from '../../icons/Icon';
import { mapAddressToGoogleMapsUrl, formatAddressWithName } from '../../../../utils/formatting/formatAddress';

interface IPublicProps {
    address: ICMSCourseAddress; // TODO add - once needed - also IAddress interface
}

const CLASS_NAME = 'AddressMapLink';

export default class AddressMapLink extends PureComponent<IPublicProps> {
    constructor(props: IPublicProps) {
        super(props);
    }

    render() {
        const { address } = this.props;

        const formattedAddress = formatAddressWithName(address, true);

        return (
            <OutgoingLink href={mapAddressToGoogleMapsUrl(address)}>
                <div className={CLASS_NAME}>
                    <Icon typeName="location" />
                    <span>{formattedAddress}</span>
                </div>
            </OutgoingLink>
        );
    }
}
