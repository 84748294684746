import { ICompanyCodePayload } from '../../models/admin/company';
import { post } from '../../utils/api/requestWrapper';
import convertFileToBase64 from '../../utils/file/convertFileToBase64';

interface IUploadCompanyDocumentByUrlPayload extends ICompanyCodePayload {
    url: string;
    fileToUpload: File;
}

export default function uploadCompanyDocumentByUrl(
    { companyCode, url, fileToUpload }: IUploadCompanyDocumentByUrlPayload,
) {
    return convertFileToBase64(fileToUpload)
        .then((base64) => post({
            url,
            pathParams: {
                companyCode,
            },
            body: {
                attachmentBodyBase64: base64,
                attachmentFileName: fileToUpload.name,
            },
        }));
}
