import React, { ReactNode } from 'react';
import isArray from '@snipsonian/core/es/is/isArray';
import { connect } from '../../index';
import {
    mayUserAccessRoute,
    hasOneOfRequiredAccessLevels,
    hasRequiredAccessLevels,
} from '../../../redux/auth/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import { IAccessLevel } from '../../../models/auth/authorisation';

interface IPrivateProps {
    isAllowed: boolean;
}

/**
 * Input:
 * - either routeKey : child(ren) will only be visible if user is allowed to navigate to the route
 *                      (based on the 'requiredAccessLevels' of the route)
 * - or requiredAccessLevels : child(ren) only visible if user has these input access levels
 *                             (OR relation between multiple Partial's in the array)
 *                             (AND relation within a single Partial)
 * - or both : child(ren) only visible if both validations are valid
 */
interface IPublicProps {
    children: ReactNode;
    routeKey?: ROUTE_KEYS;
    requiredAccessLevels?: Partial<IAccessLevel> | Partial<IAccessLevel>[];
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const mayAccessRoute = publicProps.routeKey ?
                mayUserAccessRoute(state, publicProps.routeKey) :
                true;

            const hasRequiredAccess = publicProps.requiredAccessLevels ?
                (isArray(publicProps.requiredAccessLevels) ?
                    hasOneOfRequiredAccessLevels(state, publicProps.requiredAccessLevels as Partial<IAccessLevel>[]) :
                    hasRequiredAccessLevels(state, publicProps.requiredAccessLevels as Partial<IAccessLevel>)) :
                true;

            return {
                isAllowed: mayAccessRoute && hasRequiredAccess,
            };
        };
    },
})(ShowIfAllowed);

function ShowIfAllowed({ isAllowed, children }: IPrivateProps & IPublicProps) {
    if (!isAllowed) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
}
