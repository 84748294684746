import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    IAsyncDoField,
    createAsyncDoActionHandlers,
    getAsyncDoInitialState,
} from '../index';
import {
    FETCH_QUESTIONNAIRES_SCREENWORK,
    FETCH_QUESTIONNAIRES_JOB_STUDENTS,
    FETCH_QUESTIONNAIRE_ADVICE,
    SEND_JOB_STUDENT_REMINDER_EMAIL,
    TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
} from './types';
import ROUTE_KEYS from '../../routeKeys';
import { REDUCER_KEYS } from '../../config/redux.config';
import {
    IScreenworkQuestionnaire,
    IJobStudentQuestionnaire,
    IQuestionnaireAdvice,
} from '../../models/admin/questionnaires';
import { UPDATE_EMPLOYEE } from '../employee/info/types';
import { createActionHandler } from '../../utils/libs/redux/createActionHandler';
import { toSuccessType } from '../../utils/libs/redux/typeGenerator';
import { IUpdateEmployeeSucceededPayload } from '../../models/admin/employee';

export const reducerKey = REDUCER_KEYS.QUESTIONNAIRES;

export interface IReducerState {
    screenwork: IAsyncFetchField<IScreenworkQuestionnaire[]>;
    jobStudents: IAsyncFetchField<IJobStudentQuestionnaire[]>;
    advice: IAsyncFetchField<IQuestionnaireAdvice[]>;
    sendJobStudentReminderEmail: IAsyncDoField;
    triggerPlanJobStudentMedExam: IAsyncDoField;
}

const initialState: IReducerState = {
    screenwork: getAsyncFetchInitialState(),
    jobStudents: getAsyncFetchInitialState(),
    advice: getAsyncFetchInitialState(),
    sendJobStudentReminderEmail: getAsyncDoInitialState(),
    triggerPlanJobStudentMedExam: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IScreenworkQuestionnaire[], IReducerState, IScreenworkQuestionnaire[]>({
        baseActionType: FETCH_QUESTIONNAIRES_SCREENWORK,
        fieldName: 'screenwork',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK,
            ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK_DETAIL,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IJobStudentQuestionnaire[], IReducerState, IJobStudentQuestionnaire[]>({
        baseActionType: FETCH_QUESTIONNAIRES_JOB_STUDENTS,
        fieldName: 'jobStudents',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS,
            FETCH_QUESTIONNAIRES_JOB_STUDENTS,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IQuestionnaireAdvice[], IReducerState, IQuestionnaireAdvice[]>({
        baseActionType: FETCH_QUESTIONNAIRE_ADVICE,
        overrideTriggerActionType: [
            ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL,
        ],
        fieldName: 'advice',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SEND_JOB_STUDENT_REMINDER_EMAIL,
        fieldName: 'sendJobStudentReminderEmail',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: TRIGGER_PLAN_JOB_STUDENT_MED_EXAM,
        fieldName: 'triggerPlanJobStudentMedExam',
    }),
    [toSuccessType(UPDATE_EMPLOYEE)]:
        createActionHandler<IReducerState>(
            ({ oldState, payload }) => {
                const successPayload = payload as IUpdateEmployeeSucceededPayload;

                const jobStudents = oldState.jobStudents.data;

                if (
                    !jobStudents ||
                    !jobStudents.find((jobStudent) =>
                        jobStudent.employee.nationalRegisterNumber === successPayload.nationalRegisterNumber)
                ) {
                    return oldState;
                }

                const newJobStudents = jobStudents.map((jobStudent) => {
                    if (jobStudent.employee.nationalRegisterNumber === successPayload.nationalRegisterNumber) {
                        const { languageId, email } = successPayload;
                        return {
                            ...jobStudent,
                            employee: {
                                ...jobStudent.employee,
                                email: email || jobStudent.employee.email,
                            },
                            languageId: (languageId && languageId.toString()) || jobStudent.languageId,
                        };
                    }
                    return jobStudent;
                });

                return {
                    ...oldState,
                    jobStudents: {
                        ...oldState.jobStudents,
                        data: newJobStudents,
                    },
                };
            },
        ),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
