import React, { Component } from 'react';
import { connect } from '../../index';
import './rates.scss';
import { getCompanyRates, getFetchCompanyRatesAsyncInfo } from '../../../redux/company/info/selectors';
import { IRates } from '../../../models/admin/rates';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import Loader from '../../common/waiting/Loader';
import ListItemBordered from '../../common/list/ListItemBordered';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import openInNewTab from '../../../utils/browser/openInNewTab';

interface IRate {
    id: string;
    titleTranslationKey: string;
    url: string;
}

interface IPrivateProps {
    rates: IRates;
    isFetchingRates: boolean;
    fetchRatesAsyncInfo: IAsyncFieldInfo;
}

const CLASS_NAME = 'RatesOverview';

class Rates extends Component<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            rates, isFetchingRates, fetchRatesAsyncInfo,
        } = this.props;

        const allRates = [
            {
                id: 'rates',
                titleTranslationKey: rates && ('administration.rates.standard.rates.' + rates.companyType),
                url: rates && rates.rates,
            },
            {
                id: 'technical',
                titleTranslationKey: 'administration.rates.standard.technical.title',
                url: rates && rates.technical,
            },
        ];

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__content container`}>
                    <Loader show={isFetchingRates}>
                        <div className={`${CLASS_NAME}__list`}>
                            {fetchRatesAsyncInfo.error &&
                                <ErrorPlaceholder apiError={fetchRatesAsyncInfo.error} />
                            }
                            {rates && this.renderDownloadItems(allRates)}
                        </div>
                    </Loader>
                </div>
            </div>
        );
    }

    private renderDownloadItems(rates: IRate[]) {
        return rates.map((rate, index) => {
            return (
                <ListItemBordered
                    key={index}
                    text={
                        <>
                            <span>
                                <Translate msg={rate.titleTranslationKey} />
                            </span>
                            <Button
                                id={`download-${rate.id}`}
                                typeName="secondary"
                                size="small"
                                onClick={() => {
                                    openInNewTab(rate.url);
                                }}
                            >
                                <Icon typeName="download" />
                                <Translate msg="administration.rates.standard.download" />
                            </Button>
                        </>
                    }
                />
            );
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const fetchRatesAsyncInfo = getFetchCompanyRatesAsyncInfo(state);

        return {
            rates: getCompanyRates(state),
            fetchRatesAsyncInfo,
            isFetchingRates: fetchRatesAsyncInfo.status === AsyncStatus.Busy,
        };
    },
})(Rates);
