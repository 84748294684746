import { IFetchAppointmentsPayload, IFetchAppointmentsApiPayload } from '../../models/planning/activities';
import { ILocalePayload } from '../../models/general/i18n';

interface IParseFetchAppointmentPayloadPayload extends ILocalePayload {
    payload: IFetchAppointmentsPayload;
}

export const parseFetchAppointmentsPayload = (
    params: IParseFetchAppointmentPayloadPayload,
): IFetchAppointmentsApiPayload => {
    const { locale, payload } = params;

    return {
        locale,
        showFullFamily: payload.showFullFamily,
        start: payload.dateFrom,
        end: payload.dateUntil,
        companyCode: payload.companyCode,
        ...!!payload.query && {
            query: payload.query,
        },
    };
};
