import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { registerReducer } from '../index';
import { REDUCER_KEYS } from '../../config/redux.config';
import {
    initCookieConsentReducerBuildingBlocks,
} from '../../snipsonian/react-cookie-consent/src/redux/reducerBuildingBlocks';
import {
    ICookiesConfiguration,
    IExtraCookieMetaData,
} from '../../models/general/cookieConsent';
import { ICookieConsentState as IBaseCookieConsentState } from '../../snipsonian/react-cookie-consent/src/redux/types';

export type ICookieConsentState = IBaseCookieConsentState<ICookiesConfiguration, IExtraCookieMetaData>;

export const reducerKey = REDUCER_KEYS.COOKIE_CONSENT;

const { key, initialState: initState, actionHandlers } =
    initCookieConsentReducerBuildingBlocks<ICookiesConfiguration, IExtraCookieMetaData>({
        reducerKey,
        initialCookiesConfiguration: {
            functional_cookie: true,
            analyzing_cookie: false,
            marketing_cookie: false,
        },
    });

export const initialState = initState;

export type IReducerState = IBaseCookieConsentState<ICookiesConfiguration, IExtraCookieMetaData>;

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key,
    reducerStorageType: REDUCER_STORAGE_TYPE.CUSTOM,
    resetStateOnCompanySelection: false,
    resetStateOnSeatSelection: false,
    resetStateOnLogout: false,
    resetStateOnLocaleSwitch: false,
});
