import { IRoutes, NOT_FOUND_ROUTE_KEY } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import ROUTE_KEYS from '../../routeKeys';
import UnexpectedError, { IUnexpectedErrorProps } from './UnexpectedError';
import { PAGE_KEY } from '../../config/route.config';
import NoAccess from './NoAccess';

const NOT_FOUND_ROUTE = {
    path: '/page-not-found',
    page: {
        key: PAGE_KEY.PAGE_NOT_FOUND,
        // TODO: don't load this page async, should go into the main bundle
        component: () => import('./PageNotFound'),
    },
    template: templates.PageNotFoundTemplate,
    virtualPage: 'not_found',
    allowAnonymousAccess: true,
};

const ROUTES: IRoutes = {
    [ROUTE_KEYS[NOT_FOUND_ROUTE_KEY]]: {
        ...NOT_FOUND_ROUTE,
        // Built in error page has no actual path
        // Redirects to the error page without changing the actual url
        path: '/page-not-found',
        virtualPage: 'page_not_found',
    },
    [ROUTE_KEYS.R_NOT_FOUND]: NOT_FOUND_ROUTE,
    [ROUTE_KEYS.R_UNEXPECTED_ERROR]: {
        path: '/unexpected-error',
        page: {
            key: 'UNEXPECTED_ERROR',
            component: UnexpectedError,
        },
        template: templates.MainHeaderAndFooterTemplate,
        virtualPage: 'unexpected_error',
        allowAnonymousAccess: true,
    },
    // Built in error page has no actual path (uses the path on which the error occured)
    // Only used internally by the body switcher
    [ROUTE_KEYS.R_INITIALIZATION_ERROR]: {
        path: '/initialization-error',
        page: {
            key: 'INITIALIZATION_ERROR',
            component: UnexpectedError,
        },
        pageProps: {
            isInitializationError: true,
        } as IUnexpectedErrorProps,
        template: templates.MainHeaderAndFooterTemplate,
        virtualPage: 'initialization_error',
        allowAnonymousAccess: true,
    },
    [ROUTE_KEYS.R_NO_ACCES]: {
        path: '/no-access',
        page: {
            key: 'NO_ACCESS',
            component: NoAccess,
        },
        template: templates.PageNotFoundTemplate,
        virtualPage: 'no_access',
    },

};

export default ROUTES;
