import React, { PureComponent } from 'react';
import { IDocument } from '../../../models/general/documents';
import { AsyncStatus, IAsyncFieldInfo } from '../../../models/general/redux';
import presentDownloadedFile from '../../../utils/file/presentDownloadedFile';
import ErrorDialog from '../../common/modals/ErrorDialog';

interface IDownloadProps {
    companyCode: string;
    docType: string;
}

export interface IRenderProps {
    downloadDocument: (docType: string) => void;
    isDownloading: (docType: string) => boolean;
}

interface IPublicProps {
    companyCode: string;
    docTypes: string[];
    downloadDocumentApi: (downloadProps: IDownloadProps) => Promise<IDocument>;
    renderContent: (renderProps: IRenderProps) => React.ReactElement<{}>;
}

interface IComponentState {
    downloadStatuses: {
        [docType: string]: IAsyncFieldInfo;
    };
}

export default class DownloadDocuments extends PureComponent<IPublicProps, IComponentState> {
    constructor(props: IPublicProps) {
        super(props);

        this.state = {
            downloadStatuses: this.props.docTypes
                .reduce(
                    (accumulator, docType) => {
                        accumulator[docType] = getInitialAsyncInfo();
                        return accumulator;
                    },
                    {},
                ),
        };

        this.downloadDocument = this.downloadDocument.bind(this);
        this.isDownloading = this.isDownloading.bind(this);
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
    }

    public render() {
        return (
            <div className="DownloadDocuments">
                <>
                {this.props.renderContent({
                    downloadDocument: this.downloadDocument,
                    isDownloading: this.isDownloading,
                })}

                {this.props.docTypes
                    .map((docType) => {
                        const downloadStatus = this.state.downloadStatuses[docType];
                        return (
                            <ErrorDialog
                                key={`DownloadDocuments-error-dialog-${docType}`}
                                asyncInfo={downloadStatus}
                                titleTranslationKey="document_center.actions.download_document.error_title"
                                hideRealErrorMessage={true}
                                onCloseDialog={this.onCloseErrorDialog}
                            />
                        );
                    })}
                </>
            </div>
        );
    }

    private async downloadDocument(docType: string) {
        this.updateDownloadStatus(docType, AsyncStatus.Busy);

        try {
            const document = await this.props.downloadDocumentApi({
                companyCode: this.props.companyCode,
                docType,
            });

            this.updateDownloadStatus(docType, AsyncStatus.Success);

            presentDownloadedFile(document);
        } catch (e) {
            this.updateDownloadStatus(docType, AsyncStatus.Error, e);
        }
    }

    private updateDownloadStatus(docType: string, status: AsyncStatus, error = null) {
        this.setState({
            downloadStatuses: {
                ...this.state.downloadStatuses,
                [docType]: {
                    status,
                    error,
                },
            },
        });
    }

    private isDownloading(docType: string) {
        return this.state.downloadStatuses[docType].status === AsyncStatus.Busy;
    }

    private onCloseErrorDialog() {
        // remove error statuses
        this.setState({
            downloadStatuses: Object.keys(this.state.downloadStatuses)
                .reduce(
                    (accumulator, docType) => {
                        accumulator[docType] = {
                            status: this.state.downloadStatuses[docType].status === AsyncStatus.Error
                                ? AsyncStatus.Initial
                                : this.state.downloadStatuses[docType].status,
                            error: null,
                        };
                        return accumulator;
                    },
                    {},
                ),
        });
    }
}

function getInitialAsyncInfo() {
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
}
