import React from 'react';
import { IHeaderPagePartData } from '../../../../models/general/pageParts';
import Translate from '../../Translate';

interface IPublicProps {
    data: IHeaderPagePartData;
}

export default function HeaderPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <h2 className="HeaderPagePart">
            <Translate msg={data.title} />
        </h2>
    );
}
