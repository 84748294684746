import isBoolean from '@snipsonian/core/es/is/isBoolean';
import appendUrlParam from '@snipsonian/core/es/url/appendUrlParam';

export interface IUrlParams {
    [key: string]: number | string | boolean;
}

export default function appendQueryParams({
    url,
    params = {},
}: {
    url: string,
    params?: IUrlParams,
}) {
    return Object.keys(params)
        .filter((paramName) => !!params[paramName] || isBoolean(params[paramName]))
        .reduce(
            (accumulator, paramName) => appendUrlParam({
                paramName,
                url: accumulator,
                paramValue: params[paramName],
            }),
            url,
        );
}
