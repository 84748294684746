import { createEpic } from '../../index';
import {
    fetchCompanyUsers,
    fetchCompanyUsersSucceeded,
    fetchCompanyUsersFailed,
    fetchCompanyUserDetailsFailed,
    fetchCompanyUserDetailsSucceeded,
    updateCompanyUserDetailsActions,
    addCompanyUserActions,
    fetchCompanyUserDetails,
    removeCompanyUserActions,
} from './actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getSelectedSeatCompanyCode, getSelectedCompany, getSelectedCompanySeat,
} from '../selected/selectors';
import { getRouteKey, getRoutePayload } from '../../location/selectors';
import { isCompanyUsersDataAvailable } from './selectors';
import {
    FETCH_COMPANY_USERS,
    UPDATE_COMPANY_USER_DETAILS,
    ADD_COMPANY_USER,
    FETCH_COMPANY_USER_DETAILS,
    REMOVE_COMPANY_USER,
} from './types';
import {
    IUpdateUserAccountPayload,
    IAddUserAccountPayload,
    IRemoveUserAccountPayload,
} from '../../../models/user/userAccount';
import { mapRouteKeyToSubmittedFormActionType } from '../../../utils/logging/analytics/mapToSubmittedFormActionType';
import { getLocale } from '../../i18n/selectors';

// fetchCompanyUsersIfNotAvailableEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_DETAIL,
        ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD,
    ],
    processReturn: () => fetchCompanyUsers(),
    processFilter: ({ getState }) => {
        return !isCompanyUsersDataAvailable(getState());
    },
    latest: false,
});

// fetchCompanyUsersEpic
createEpic({
    onActionType: [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS, FETCH_COMPANY_USERS],
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const { companySeat, isAllSeatsSelected } = getSelectedCompanySeat(state);
            const result = await api.user.admin.fetchCompanyUsers(companySeat.company.companyCode, isAllSeatsSelected);
            return fetchCompanyUsersSucceeded(result);
        } catch (error) {
            return fetchCompanyUsersFailed(error);
        }
    },
    latest: false,
});

// fetchCompanyUserDetailsEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_DETAIL,
        FETCH_COMPANY_USER_DETAILS,
    ],
    processFilter: ({ getState }) => {
        const state = getState();
        const payload = getRoutePayload<{ username: string }>(state);
        return !!(payload && payload.username);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const { username } = getRoutePayload<{ username: string }>(state);

            const result = await api.user.admin.fetchCompanyUser(companyCode, username);
            return fetchCompanyUserDetailsSucceeded(result);
        } catch (error) {
            return fetchCompanyUserDetailsFailed(error);
        }
    },
    latest: true,
});

// updateCompanyUserDetailsEpic
createEpic<IUpdateUserAccountPayload>({
    onActionType: UPDATE_COMPANY_USER_DETAILS,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const selectedCompany = getSelectedCompany(state);
            const locale = getLocale(state);

            const { payload } = action;
            const { seats, seatsToAdd, seatsToRemove, accessLevel, ...updateUserInfoPayload } = payload;

            await api.user.admin.updateUserInfo(updateUserInfoPayload, locale);

            await api.user.admin.updateUserPermissions(selectedCompany.companyCode, {
                seats,
                seatsToAdd,
                seatsToRemove,
                accessLevel,
                admin: payload.admin,
                username: payload.username,
            });

            const { username } = getRoutePayload<{ username: string }>(state);
            dispatch(fetchCompanyUserDetails(username));

            dispatch(fetchCompanyUsers());
            dispatch(updateCompanyUserDetailsActions.succeeded({}));
        } catch (error) {
            dispatch(updateCompanyUserDetailsActions.failed(error));
        }
        done();
    },
    latest: false,
});

// addCompanyUserEpic
createEpic<IAddUserAccountPayload>({
    onActionType: ADD_COMPANY_USER,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const locale = getLocale(state);

            const { payload } = action;
            const { accessLevel, seats, ...createUserInfoPayload } = payload;

            const userGroupId = await api.auth.authentication.convertAccessLevelToUserGroupId({
                ...payload.accessLevel,
                extraPermissions: [],
            });

            await api.user.admin.createCompanyUser({
                ...createUserInfoPayload,
                customerIds: seats.map((seat) => seat.id),
                userGroupId,
            }, locale);

            const currentRouteKey = getRouteKey(state);
            return addCompanyUserActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: () => mapRouteKeyToSubmittedFormActionType(currentRouteKey),
                },
            );
        } catch (error) {
            return addCompanyUserActions.failed(error);
        }
    },
    latest: false,
});

// removeCompanyUserEpic
createEpic<IRemoveUserAccountPayload>({
    onActionType: REMOVE_COMPANY_USER,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const selectedCompany = getSelectedCompany(state);
            const locale = getLocale(state);

            await api.user.admin.removeCompanyUser(selectedCompany.id, action.payload, locale);

            dispatch(fetchCompanyUsers());
            dispatch(removeCompanyUserActions.succeeded({}));
        } catch (error) {
            dispatch(removeCompanyUserActions.failed(error));
        }
        done();
    },
    latest: false,
});
