import React from 'react';

import { ConstantType } from '../../../../models/general/constants';
import { ICreateAccountPayload } from '../../../../models/auth/authentication';
import ConstantsTypeahead from '../../../common/input/ConstantsTypeahead';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import FormError from '../../../common/forms/FormError';
import FormFieldError from '../../../common/forms/FormFieldError';
import GoBack from '../../../common/navigation/GoBack';
import ListFooter from '../../../common/list/ListFooter';
import Loader from '../../../common/waiting/Loader';
import SubmitButton from '../../../common/buttons/SubmitButton';
import TextInput from '../../../common/input/TextInput';
import Translate from '../../../common/Translate';
import { createAccountFields, createAccountSchema } from '../createAccountSchema';

import { CreateAccountFormProps } from './CreateAccountForm.type';
import { FORM_NAME, PLACEHOLDER_KEYS, INITIAL_VALUES } from './CreateAccountForm.const';

const CreateAccountForm = ({
    createAccountAsyncInfo,
    isRequestingOrCreatingAccount,
    onCreateAccount,
    onPreviousClick,
    translator,
}: CreateAccountFormProps) => {
    const firstNamePlaceholder = translator('auth.request_account.step_two.form.placeholders.first_name');
    const namePlaceholder = translator('auth.request_account.step_two.form.placeholders.name');
    const emailPlaceholder = translator('auth.request_account.step_two.form.placeholders.email');

    return (
        <Form
            name={FORM_NAME}
            initialValues={INITIAL_VALUES}
            handleSubmit={onCreateAccount}
            schema={createAccountSchema}
            render={({
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
            }: IFormRenderProps<ICreateAccountPayload>) => {
                return (
                    <>
                        <Loader show={isRequestingOrCreatingAccount} />
                        <h3>
                            <Translate msg="auth.request_account.step_two.title" />
                        </h3>
                        <p className="info">
                            <Translate msg="auth.request_account.step_two.info" />
                        </p>
                        <FloatableTextInputWrapper floatLabel>
                            <ConstantsTypeahead
                                id="request-account-salutation"
                                name={createAccountFields.titleId}
                                value={values.titleId}
                                constantType={ConstantType.TITLES}
                                onItemSelected={(value) => setFieldValue('titleId', Number(value))}
                                isInvalid={touched.titleId &&
                                    !!errors.titleId}
                            >
                                <label htmlFor="request-account-salutation">
                                    <Translate
                                        msg={PLACEHOLDER_KEYS.titleId}
                                    />
                                </label>
                            </ConstantsTypeahead>
                            {touched.titleId &&
                                <FormFieldError
                                    error={errors.titleId}
                                    placeholders={{
                                        fieldName: translator(
                                            PLACEHOLDER_KEYS.titleId),
                                    }}
                                />}
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper floatLabel>
                            <ConstantsTypeahead
                                id="request-account-sexid"
                                name={createAccountFields.sexId}
                                value={values.sexId}
                                constantType={ConstantType.SEXES}
                                onItemSelected={(value) => setFieldValue('sexId', Number(value))}
                                isInvalid={touched.sexId &&
                                    !!errors.sexId}
                            >
                                <label htmlFor="request-account-sexid">
                                    <Translate
                                        msg={PLACEHOLDER_KEYS.sexId}
                                    />
                                </label>
                            </ConstantsTypeahead>
                            {touched.sexId &&
                                <FormFieldError
                                    error={errors.sexId}
                                    placeholders={{
                                        fieldName: translator(
                                            PLACEHOLDER_KEYS.sexId),
                                    }}
                                />}
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id="request-account-firstname"
                                placeholder={firstNamePlaceholder}
                                name={createAccountFields.firstName}
                                value={values.firstName}
                                onChange={handleChange}
                                isInvalid={touched.firstName && !!errors.firstName}
                            />
                            <label htmlFor="request-account-firstname">
                                <Translate
                                    msg={PLACEHOLDER_KEYS.firstName}
                                />
                            </label>
                            {touched.firstName && (
                                // eslint-disable-next-line max-len
                                <FormFieldError
                                    error={errors.firstName}
                                    placeholders={{ fieldName: firstNamePlaceholder }}
                                />
                            )}
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id="request-account-name"
                                placeholder={namePlaceholder}
                                name={createAccountFields.name}
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={touched.name && !!errors.name}
                            />
                            <label htmlFor="request-account-name">
                                <Translate
                                    msg={PLACEHOLDER_KEYS.name}
                                />
                            </label>
                            {touched.name && (
                                // eslint-disable-next-line max-len
                                <FormFieldError error={errors.name} placeholders={{ fieldName: namePlaceholder }} />
                            )}
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id="request-account-email"
                                placeholder={emailPlaceholder}
                                name={createAccountFields.email}
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={touched.email && !!errors.email}
                            />
                            <label htmlFor="request-account-email">{emailPlaceholder}</label>
                            {touched.email && (
                                // eslint-disable-next-line max-len
                                <FormFieldError error={errors.email} placeholders={{ fieldName: emailPlaceholder }} />
                            )}
                        </FloatableTextInputWrapper>
                        <FormError error={createAccountAsyncInfo.error} />
                        <ListFooter
                            left={<GoBack id="go-back-to-prev-step" onClick={onPreviousClick} />}
                            right={
                                <SubmitButton id="request-account-submit" formName={FORM_NAME}>
                                    <Translate msg="auth.request_account.step_two.form.submit" />
                                </SubmitButton>
                            }
                        />
                    </>
                );
            }}
        />
    );
};

export { CreateAccountForm };
