import { createEpic, NO_RERENDER } from '../../index';
import isSet from '@snipsonian/core/es/is/isSet';
import {
    fetchSelectedCompanySeatsAndDivisionsActions,
    setCompanyOnboardingVariables,
    setInterimForCompany,
} from './actions';
import {
    FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS,
    SELECT_COMPANY_SEAT, SELECT_COMPANY,
} from './types';
import { addExtraPermissionsForCompany, fetchPerformanceDashboardAccessActions } from '../../auth/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { areSelectedCompanySeatsAndDivisionsAvailable } from './selectors';
import { getSelectedCompany } from '../selected/selectors';
import { fetchUnreadMessagesCount } from '../../inbox/actions';
import { fetchSmallCompanyDetail } from '../info/actions';
import { ISelectCompanySeatPayload, ICompany } from '../../../models/admin/company';
import { IInitAppActionPayload } from '../../../models/general/redux';
import { applicationInitializationFailed } from '../../ui/form/actions';
import isApplicationInitializationError from '../../../utils/api/isApplicationInitializationError';
import { Permission } from '../../../models/auth/authorisation';
import { hasPermission } from '../../auth/selectors';
import { fetchCompanySituationHistoryActions } from '../../preventionUnits/actions';
import { clearCompanyRelatedCustomDimensions } from '../../../utils/logging/analytics/eventLogger';

// clearCompanyRelatedCustomDimensionsEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_SELECTION,
    filter: () => {
        if (process.env.NODE_ENV === 'development') {
            console.log('Route R_COMPANY_SELECTION : clearing custom dimensions');
        }
        clearCompanyRelatedCustomDimensions();
        return true;
    },
    latest: false,
});

// fetchSelectedCompanySeatsAndDivionsIfNotAvailableEpic
createEpic({
    onActionType: ROUTE_KEYS.R_SEATS_SELECTION,
    processFilter: ({ getState }) => {
        const state = getState();
        const selectedCompany = getSelectedCompany(state);
        return selectedCompany && !areSelectedCompanySeatsAndDivisionsAvailable(state);
    },
    processReturn: () => fetchSelectedCompanySeatsAndDivisionsActions.trigger({}),
    latest: false,
});

// fetchSelectedCompanySeatsAndDivisionsEpic
createEpic <IInitAppActionPayload>({
    onActionType: FETCH_SELECTED_COMPANY_SEATS_AND_DIVISIONS,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const selectedCompany = getSelectedCompany(state);
            if (selectedCompany) {
                const { seats, extraPermissions, interim } =
                    await api.admin.company.fetchCompanySeats({
                        companyCode: selectedCompany.companyCode,
                        showFullSeats: true,
                        noEmployeeCount: true,
                    });

                if (
                    hasPermission(state, Permission.CAN_ACCESS_ALL_COMPANIES) &&
                    (!isSet(selectedCompany.startWizardNewCustomer) || !isSet(selectedCompany.statusNotPaid))
                ) {
                    const onboardingVariables = await api.admin.company.fetchCompanyOnboardingVariables({
                        companyCode: selectedCompany.companyCode,
                    });
                    dispatch(setCompanyOnboardingVariables({
                        companyCode: selectedCompany.companyCode,
                        ...onboardingVariables,
                    }));
                }

                dispatch(fetchSelectedCompanySeatsAndDivisionsActions.succeeded(seats));
                dispatch(addExtraPermissionsForCompany(extraPermissions));
                dispatch(setInterimForCompany({ interim }));
            } else {
                dispatch(fetchSelectedCompanySeatsAndDivisionsActions.succeeded(NO_RERENDER.EMPTY_LIST));
            }
            return done();
        } catch (error) {
            dispatch(fetchSelectedCompanySeatsAndDivisionsActions.failed(error));
            if (isApplicationInitializationError(action.payload, error)) {
                dispatch(applicationInitializationFailed());
            }
            return done();
        }
    },
    latest: true,
});

// fetchDataOnCompanySelectionEpic
createEpic<ICompany>({
    onActionType: SELECT_COMPANY,
    processMultiple({ action }, dispatch, done) {
        dispatch(fetchCompanySituationHistoryActions.trigger({
            companyCode: action.payload.companyCode,
        }));

        dispatch(fetchPerformanceDashboardAccessActions.trigger({}));
        return done();
    },
    latest: false,
});

// fetchDataOnSeatSelectionEpic
createEpic<ISelectCompanySeatPayload>({
    onActionType: SELECT_COMPANY_SEAT,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            dispatch(fetchUnreadMessagesCount());

            dispatch(fetchSmallCompanyDetail({ companyCode: action.payload.companySeat.company.companyCode }));

            return done();
        } catch (error) {
            dispatch(fetchSelectedCompanySeatsAndDivisionsActions.failed(error));
            return done();
        }
    },
    latest: false,
});
