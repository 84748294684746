import React from 'react';
import classNames from 'classnames';
import './wizard-flow.scss';
import { connect } from '../../../index';
import { IState } from '../../../../redux/IState';
import { ILocationAction } from '../../../../models/general/redux';
import { IStepperStep } from '../../../../models/general/stepper';
import Stepper from '../Stepper';
import Step from '../Step';
import Translate from '../../Translate';
import { isPreviousAllowedFromThisStep } from '../../../../utils/wizard/isPreviousAllowedFromThisStep';

interface IPublicProps {
    activeStepIdSelector: (state: IState) => string;
    // navigateToStepActionCreator: <Payload, Query = {}>(stepId: string) => IAction<Payload, Query>;
    navigateToStepActionCreator: (stepId: string) => ILocationAction<{}>;
    steps: IStepperStep[];
}

interface IPrivateProps {
    activeStepId: string;
    toStep: (stepId: string) => void;
}

const CLASS_NAME = 'WizardFlow';

export const WIZARDFLOW_CLASSES = {
    COMPONENT: CLASS_NAME,
    CONTENT: `${CLASS_NAME}__content`,
    CONTENT_NARROW: classNames(`${CLASS_NAME}__content`, 'narrow'),
    CONTENT_WIDE: classNames(`${CLASS_NAME}__content`, 'wide'),
    CONTENT_DIALOG: `${CLASS_NAME}__content--dialog`,
    NARROW_FORM: `${CLASS_NAME}__content__narrow-form`,
    WIDE_FORM: `${CLASS_NAME}__content__wide-form`,
    ACTIONS: `${CLASS_NAME}__content__actions`,
    BORDERED_FORM: `${CLASS_NAME}__content__bordered-form`,
};

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                activeStepId: publicProps.activeStepIdSelector(state),
            };
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                toStep: (stepId) => {
                    dispatch(publicProps.navigateToStepActionCreator(stepId));
                },
            };
        };
    },
})(Wizard);

function Wizard(props: IPublicProps & IPrivateProps) {
    const { activeStepId, toStep, steps } = props;

    return (
        <div className={WIZARDFLOW_CLASSES.COMPONENT}>
            <Stepper
                activeStepId={activeStepId}
                onStepSelectIntent={toStep}
            >
                {steps.map((step: IStepperStep) => (
                    <Step
                        id={step.id}
                        key={`step_${step.id}`}
                        disableOnClick={!isPreviousAllowedFromThisStep(step.id, steps, activeStepId)}
                        label={<Translate msg={step.translationKey} ignoreContext />}
                    >
                        {(renderProps) => {
                            return step.renderStepContent(renderProps);
                        }}
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
