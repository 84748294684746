import React, { Component, MouseEvent } from 'react';
import classNames from 'classnames';
import './header.scss';
import MobileNav from './MobileNav';
import { connect } from '../../index';
import logo from '../../assets/img/general/logo--white.svg';
import { Permission } from '../../../models/auth/authorisation';
import {
    getSelectedCompany, getSelectedCompanySeat,
    getCompanySeatsForSelectedCompany,
} from '../../../redux/company/selected/selectors';
import {
    getDisplayName, getMyCompanies, hasPermission, getMyUserProfile, isLoggedIn, getFetchMyUserAsyncInfo,
} from '../../../redux/auth/selectors';
import { getUnreadMessagesCountIfAny } from '../../../redux/inbox/selectors';
import { getRouteKey } from '../../../redux/location/selectors';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Notification from '../../common/widget/Notification';
import ROUTE_KEYS from '../../../routeKeys';
import { AVATAR_IMAGE_HEADER_PLACEHOLDER } from '../../../config/user.config';
import { USER_PROFILE_MENU_ITEMS } from '../../../config/navigation/menu.config';
import HeaderDropdown from './HeaderDropdown';
import InlineSVG from '../../common/icons/InlineSVG';
import TinyLoader from '../../common/waiting/TinyLoader';
interface IPublicProps {
    showAccountMenu: boolean;
    showCompanySelectDropdown: boolean;
    customHeaderClass?: string;
    customHeaderPart?: React.ReactElement<{}>;
}

interface IPrivateProps {
    displayName: string;
    currentRouteKey: ROUTE_KEYS;
    loggedIn: boolean;
    unreadMessagesCount: number;
    avatarUrl: string;
    areMultipleCompaniesAvailable: boolean;
    areMultipleSeatsAvailable: boolean;
    selectedCompanyCode: string;
    selectedCompanyName: string;
    selectedCompanySeatCode: string;
    selectedCompanySeatName: string;
    isAllSeatsSelected: boolean;
}

interface IHeaderState {
    mobileMenuOpen?: boolean;
    fallbackImage?: string;
}

class Header extends Component<IPrivateProps & IPublicProps, IHeaderState> {
    private userMenuClosingTimeout: number;

    constructor(props) {
        super(props);
        this.state = {
            mobileMenuOpen: false,
        };

        this.showMobileMenu = this.showMobileMenu.bind(this);
        this.hideMobileMenu = this.hideMobileMenu.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPrivateProps) {
        const { mobileMenuOpen } = this.state;
        if (mobileMenuOpen && this.props.currentRouteKey !== nextProps.currentRouteKey) {
            this.setState({
                mobileMenuOpen: false,
            });
        }
    }

    public componentWillUnmount() {
        if (this.userMenuClosingTimeout) {
            window.clearTimeout(this.userMenuClosingTimeout);
        }
    }

    public setUserFallbackImage = () => {
        this.setState({
            fallbackImage: AVATAR_IMAGE_HEADER_PLACEHOLDER,
        });
    };

    public render() {
        const { showAccountMenu, customHeaderClass, customHeaderPart, loggedIn } = this.props;
        const headerClass = classNames('Header', customHeaderClass);

        return (
            <div className={headerClass}>
                <div className="section">
                    <InlineSVG svg={logo} className="logo" />
                </div>
                {this.renderCompanySelect()}

                {
                    showAccountMenu && (
                        <div className="section section--user-nav">
                            {loggedIn ? (
                                <>
                                    <MobileNav
                                        isMenuOpen={this.state.mobileMenuOpen}
                                        onOpenMenu={this.showMobileMenu}
                                        onCloseMenu={this.hideMobileMenu}
                                    />
                                    {this.renderAccountNav()}
                                </>
                            ) : (
                                <LinkToRoute id="to-login-page-link" to={ROUTE_KEYS.R_LOGIN} className="login-link">
                                    <Translate msg="app_shell.header.nav.user.login" />
                                </LinkToRoute>
                            )}
                        </div>
                    )
                }
                {customHeaderPart}
            </div>
        );
    }

    private renderCompanySelect() {
        const {
            showCompanySelectDropdown,
            areMultipleCompaniesAvailable, areMultipleSeatsAvailable,
            selectedCompanyCode, selectedCompanyName,
            selectedCompanySeatCode, selectedCompanySeatName, isAllSeatsSelected,
        } = this.props;

        if (!showCompanySelectDropdown || !selectedCompanyCode) {
            return null;
        }

        const companySeatLabel = (
            <>
                <span>{selectedCompanyName}</span>
                <span>-</span>
                <span>
                    {(isAllSeatsSelected && areMultipleSeatsAvailable) ? (
                        <Translate msg="app_shell.header.nav.company_select.selected_all_seats" />
                    ) : selectedCompanySeatName}
                </span>
            </>
        );

        return (
            <div className="section section--company-select">
                <HeaderDropdown
                    id="header-dropdown-company-select"
                    className={classNames('company-select-dropdown', {
                        'no-selection-possible': !areMultipleCompaniesAvailable && !areMultipleSeatsAvailable,
                    })}
                    buttonContent={
                        <>
                            <span className="code">{selectedCompanySeatCode || selectedCompanyCode}</span>
                            <span className="name">{companySeatLabel}</span>
                        </>
                    }
                    dropdownContent={
                        <>
                            <span className="current">{companySeatLabel}</span>
                            {areMultipleCompaniesAvailable &&
                                <LinkToRoute id="select-other-company-link" to={ROUTE_KEYS.R_COMPANY_SELECTION}>
                                    <Translate msg="app_shell.header.nav.company_select.select_company" />
                                </LinkToRoute>
                            }
                            {areMultipleSeatsAvailable &&
                                <LinkToRoute
                                    id="select-other-company-seat-link"
                                    to={{
                                        type: ROUTE_KEYS.R_SEATS_SELECTION, payload: {
                                            companyCode: selectedCompanyCode,
                                        },
                                    }}
                                >
                                    <Translate msg="app_shell.header.nav.company_select.select_company_seat" />
                                </LinkToRoute>
                            }
                        </>
                    }
                />
            </div>
        );
    }

    private renderAccountNav() {
        const { displayName, unreadMessagesCount, avatarUrl } = this.props;
        const { fallbackImage } = this.state;

        if (!displayName || displayName === '-') {
            return null;
        }

        return (
            <HeaderDropdown
                id="account-nav-dropdown"
                className="account-dropdown"
                buttonContent={
                    <div className="user-detail">
                        <TinyLoader asyncInfoSelector={getFetchMyUserAsyncInfo} showContentOnInitialState>
                            <figure className="user-detail--figure">
                                <img onError={this.setUserFallbackImage} src={fallbackImage || avatarUrl} />
                                <Notification count={unreadMessagesCount} animate={true} />
                            </figure>
                        </TinyLoader>
                        <span className="user-detail--name">{displayName}</span>
                    </div>
                }
                dropdownContent={
                    USER_PROFILE_MENU_ITEMS
                        .map((menuItem) => (
                            <LinkToRoute
                                id={`user-profile-menu-item-${menuItem.code}-link`}
                                key={`user-profile-menu-item-${menuItem.code}`}
                                to={menuItem.linkTo}
                            >
                                {
                                    menuItem.iconTypeName &&
                                    <span>
                                        <Icon typeName={menuItem.iconTypeName} />
                                        {
                                            menuItem.iconTypeName === 'message' &&
                                            <Notification count={unreadMessagesCount} />
                                        }
                                    </span>
                                }
                                <span>
                                    <Translate msg={menuItem.translationKey} />
                                </span>
                            </LinkToRoute>
                        ))
                }
            />
        );
    }

    private showMobileMenu(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.setState({
            mobileMenuOpen: true,
        });
    }

    private hideMobileMenu(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.setState({
            mobileMenuOpen: false,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const userProfile = getMyUserProfile(state);

        const canAccessAllCompanies = hasPermission(state, Permission.CAN_ACCESS_ALL_COMPANIES);
        const myCompanies = getMyCompanies(state);
        const companySeats = getCompanySeatsForSelectedCompany(state);

        const selectedCompany = getSelectedCompany(state);
        const selectedCompanySeat = getSelectedCompanySeat(state);

        return {
            displayName: getDisplayName(state),
            currentRouteKey: getRouteKey(state),
            loggedIn: isLoggedIn(state),
            unreadMessagesCount: getUnreadMessagesCountIfAny(state),
            avatarUrl: userProfile && userProfile.avatarUrl,
            areMultipleCompaniesAvailable: canAccessAllCompanies || (myCompanies && myCompanies.length > 1),
            areMultipleSeatsAvailable: (companySeats && companySeats.length > 1),
            selectedCompanyCode: selectedCompany && selectedCompany.companyCode,
            selectedCompanyName: selectedCompany && selectedCompany.name,
            selectedCompanySeatCode: selectedCompanySeat && selectedCompanySeat.companySeat
                && selectedCompanySeat.companySeat.company.companyCode,
            selectedCompanySeatName: selectedCompanySeat && selectedCompanySeat.companySeat
                && selectedCompanySeat.companySeat.company.name,
            isAllSeatsSelected: selectedCompanySeat && selectedCompanySeat.isAllSeatsSelected,
        };
    },
})(Header);
