import React from 'react';

import StartEndDateFilter from '../../../../common/input/StartEndDateFilter';

import { TPlannedCompanyVisitsFilterContentProps } from './PlannedCompanyVisitsFilterContent.type';

const PlannedCompanyVisitsFilterContent = ({ formRenderProps }: TPlannedCompanyVisitsFilterContentProps) => {
    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.company_visits.planned.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
};

export { PlannedCompanyVisitsFilterContent };
