import { object } from 'yup';

import string from '../../../utils/libs/yup/string';

export const requestAccountFields = {
    companyCode: 'companyCode',
    ventureNumber: 'ventureNumber',
};

export const requestAccountSchema = object({
    [requestAccountFields.companyCode]: string().required(),
    [requestAccountFields.ventureNumber]: string().required(),
});
