import isSet from '@snipsonian/core/es/is/isSet';
import {
    ICompanyAvailabilities,
    ICompanyAvailabilityBlock, ICompanyAvailabilityPeriod, TCreateCompanyAvailabilities,
} from '../../../../../models/admin/companyInfo';
import {
    AVAILABILITY_DAYS,
    AVAILABILITY_HOUR_LIMITS,
} from '../../../../../config/company/companyInfo.config';
import extractHourAndMinute from '../../../../../utils/core/time/extractHourAndMinute';
import { noTimeBlockDaysSelected } from './availabilitiesValidator';

const MINUTES_PER_HOUR = 60;
const AVAILABILITY_HOURS_IN_DAY = AVAILABILITY_HOUR_LIMITS.max - AVAILABILITY_HOUR_LIMITS.min;
const AVAILABILITY_MINUTES_IN_DAY = AVAILABILITY_HOURS_IN_DAY * MINUTES_PER_HOUR;
const TOT_NR_OF_MINUTES_MIN = getTotalNrOfMinutes({ hour: AVAILABILITY_HOUR_LIMITS.min, minute: 0 });
const TOT_NR_OF_MINUTES_MAX = getTotalNrOfMinutes({ hour: AVAILABILITY_HOUR_LIMITS.max, minute: 0 });

export interface IDayAvailability {
    morning: ICompanyAvailabilityBlock;
    afternoon: ICompanyAvailabilityBlock;
}

export interface IDayAvailabilities {
    [weekDay: number]: IDayAvailability;
}

export function toDayAvailabilities(
    availabilities: ICompanyAvailabilities,
): IDayAvailabilities {
    return Object.keys(AVAILABILITY_DAYS)
        .reduce(
            (accumulator, key) => {
                const weekDay = parseInt(key, 10);
                // we filter on the available flag to be sure we only show either green or red blocks
                const dayAvailabilityBlocks = getAvailabilityBlocksOfWeekDay(availabilities, weekDay);
                const hasAvailabilities = dayAvailabilityBlocks && dayAvailabilityBlocks.length > 0;

                accumulator[weekDay] = {
                    dayName: AVAILABILITY_DAYS[weekDay],
                    active: hasAvailabilities,
                    availabilities: dayAvailabilityBlocks,
                };

                return accumulator;
            },
            {},
        );
}

/**
 * If not defined (new company) by default true (= green blocks) is returned.
 */
export function isInAvailableOrUnavailableMode(availabilities: ICompanyAvailabilities) {
    if (isSet(availabilities.available)) {
        return availabilities.available;
    }

    return true; // green blocks
}

function getAvailabilityBlocksOfWeekDay(
    availabilities: ICompanyAvailabilities,
    weekDay: number,
): ICompanyAvailabilityBlock[] {
    const dayBlocks: ICompanyAvailabilityBlock[] = [];

    if (isPeriodActivatedForWeekDay(availabilities.morning, weekDay)) {
        dayBlocks.push({
            startTime: availabilities.morning.startTime,
            endTime: availabilities.morning.endTime,
        });
    }

    if (isPeriodActivatedForWeekDay(availabilities.afternoon, weekDay)) {
        dayBlocks.push({
            startTime: availabilities.afternoon.startTime,
            endTime: availabilities.afternoon.endTime,
        });
    }

    return dayBlocks;
}

function isPeriodActivatedForWeekDay(availabilityPeriod: ICompanyAvailabilityPeriod, weekDay: number) {
    if (!availabilityPeriod || !availabilityPeriod.days || availabilityPeriod.days.length === 0) {
        return false;
    }

    if (!availabilityPeriod.startTime || !availabilityPeriod.endTime) {
        return false;
    }

    return isAvailabilityBlockWithinAllowedHourLimits(availabilityPeriod)
        && availabilityPeriod.days.includes(weekDay);
}

function isAvailabilityBlockWithinAllowedHourLimits(availabilityBlock: ICompanyAvailabilityBlock): boolean {
    const start = getTotalNrOfMinutes(extractHourAndMinute(availabilityBlock.startTime));
    const end = getTotalNrOfMinutes(extractHourAndMinute(availabilityBlock.endTime));

    return TOT_NR_OF_MINUTES_MIN <= start && end <= TOT_NR_OF_MINUTES_MAX;
}

interface IAvailabilityDisplayBlock {
    startPercentage: number;
    endPercentage: number;
}

export function toAvailabilityDisplayBlock(availability: ICompanyAvailabilityBlock): IAvailabilityDisplayBlock {
    const start = getNrOfMinutesStartingFromOffsetHour(
        extractHourAndMinute(availability.startTime),
        AVAILABILITY_HOUR_LIMITS.min,
    );
    const end = getNrOfMinutesStartingFromOffsetHour(
        extractHourAndMinute(availability.endTime),
        AVAILABILITY_HOUR_LIMITS.min,
    );

    return {
        startPercentage: start / AVAILABILITY_MINUTES_IN_DAY,
        endPercentage: end / AVAILABILITY_MINUTES_IN_DAY,
    };
}

function getNrOfMinutesStartingFromOffsetHour(
    { hour, minute }: {
        hour: number,
        minute: number,
    },
    offsetHour: number,
) {
    return getTotalNrOfMinutes({ hour: hour - offsetHour, minute });
}

function getTotalNrOfMinutes({ hour, minute }: { hour: number, minute: number }) {
    return hour * MINUTES_PER_HOUR + minute;
}

interface ITimeBlockEnabledOnDays {
    [weekDay: number]: boolean;
}

export interface IAvailabilityTimeBlock {
    id: keyof IAvailabilityTimeBlocks;
    startTime: string; // format "19:00"
    endTime: string; // format "21:00"
    enabledOnDays: ITimeBlockEnabledOnDays;
}

export interface IAvailabilityTimeBlocks {
    morning: IAvailabilityTimeBlock;
    afternoon: IAvailabilityTimeBlock;
}

export function toAvailabilityTimeBlocks(availabilities: ICompanyAvailabilities): IAvailabilityTimeBlocks {
    return {
        afternoon: createTimeBlockFromAvailabilityPeriod(availabilities.afternoon, 'afternoon'),
        morning: createTimeBlockFromAvailabilityPeriod(availabilities.morning, 'morning'),
    };
}

function createTimeBlockFromAvailabilityPeriod(
    period: ICompanyAvailabilityPeriod,
    id: keyof IAvailabilityTimeBlocks,
): IAvailabilityTimeBlock {
    const days: ITimeBlockEnabledOnDays = {};

    Object.keys(AVAILABILITY_DAYS)
        .forEach((weekDay) => {
            days[weekDay] = period.days.includes(Number(weekDay));
        });

    return {
        id,
        endTime: period.endTime,
        startTime: period.startTime,
        enabledOnDays: days,
    };
}

export function initEnabledOnDaysByDefaultFalseForEachDay() {
    return Object.keys(AVAILABILITY_DAYS)
        .reduce(
            (accumulator, weekDay) => {
                accumulator[weekDay] = false;
                return accumulator;
            },
            {},
        );
}

export function mapTimeBlocksToCompanyAvailabilities(
    timeBlocks: IAvailabilityTimeBlocks,
): TCreateCompanyAvailabilities {

    const hasMorningTimeBlock = !noTimeBlockDaysSelected(timeBlocks.morning);
    const hasAfternoonTimeBlock = !noTimeBlockDaysSelected(timeBlocks.afternoon);

    return {
        morning: hasMorningTimeBlock ? {
            endTime: timeBlocks.morning.endTime,
            startTime: timeBlocks.morning.startTime,
            days: mapTimeBlockEnabledOnDaysToCompanyAvailabilityPeriodDays(timeBlocks.morning.enabledOnDays),
        } : null,
        afternoon: hasAfternoonTimeBlock ? {
            endTime: timeBlocks.afternoon.endTime,
            startTime: timeBlocks.afternoon.startTime,
            days: mapTimeBlockEnabledOnDaysToCompanyAvailabilityPeriodDays(timeBlocks.afternoon.enabledOnDays),
        } : null,
    };
}

function mapTimeBlockEnabledOnDaysToCompanyAvailabilityPeriodDays(days: ITimeBlockEnabledOnDays): number[] {
    const enabledOnDays: number[] = [];
    Object.keys(days).forEach((weekDay) => {
        if (days[weekDay] === true) {
            enabledOnDays.push(Number(weekDay));
        }
    });

    return enabledOnDays;
}

export function areAvailabilitiesIncomplete(availabilities: ICompanyAvailabilities) {
    if (!availabilities) {
        return true;
    }

    return isAvailabilityPeriodIncomplete(availabilities.morning)
        && isAvailabilityPeriodIncomplete(availabilities.afternoon);
}

function isAvailabilityPeriodIncomplete(period: ICompanyAvailabilityPeriod) {
    if (!period) {
        return true;
    }

    if (!period.days || period.days.length <= 0) {
        return true;
    }

    return !period.startTime && !period.endTime;
}
