import React, { useState, useEffect } from 'react';

import { IRequestAccountPayload } from '../../../../models/auth/authentication';

import Form, { IFormRenderProps } from '../../../common/forms/Form';
import FormError from '../../../common/forms/FormError';
import FormFieldError from '../../../common/forms/FormFieldError';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import Loader from '../../../common/waiting/Loader';
import RequiredMarker from '../../../common/input/RequiredMarker';
import SubmitButton from '../../../common/buttons/SubmitButton';
import TextInput from '../../../common/input/TextInput';
import Translate from '../../../common/Translate';
import { requestAccountFields, requestAccountSchema } from '../requestAccountSchema';

import { FORM_NAME } from './RequestAccountForm.const';
import { RequestAccountFormProps } from './RequestAccountForm.type';

const RequestAccountForm = ({
    isRequestingOrCreatingAccount,
    onRequestAccount,
    requestAccount,
    requestAccountAsyncInfo,
    translator,
}: RequestAccountFormProps) => {
    const [initialValues, setInitialValues] = useState<IRequestAccountPayload>({
        companyCode: requestAccount.companyCode || '',
        ventureNumber: requestAccount.ventureNumber || '',
    });

    useEffect(() => {
        setInitialValues({
            companyCode: requestAccount.companyCode || '',
            ventureNumber: requestAccount.ventureNumber || '',
        });
    }, [requestAccount]);

    const companyCodePlaceholder = translator('auth.request_account.step_one.form.placeholders.company_code');
    const ventureNumberPlaceholder = translator('auth.request_account.step_one.form.placeholders.venture_number');

    return (
        <Form
            name={FORM_NAME}
            initialValues={initialValues}
            handleSubmit={onRequestAccount}
            schema={requestAccountSchema}
            render={({ values, touched, errors, handleChange }: IFormRenderProps<IRequestAccountPayload>) => {
                return (
                    <>
                        <Loader show={isRequestingOrCreatingAccount} />
                        <h3>
                            <Translate msg="auth.request_account.step_one.title" />
                        </h3>
                        <p className="info">
                            <Translate msg="auth.request_account.step_one.info" />
                        </p>
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id="request-account-company-code"
                                placeholder={companyCodePlaceholder}
                                name={requestAccountFields.companyCode}
                                value={values.companyCode}
                                onChange={handleChange}
                                isInvalid={touched.companyCode && !!errors.companyCode}
                            />
                            <label htmlFor="request-account-company-code">
                                {companyCodePlaceholder}
                                <RequiredMarker />
                            </label>
                            {touched.companyCode && (
                                <FormFieldError
                                    error={errors.companyCode}
                                    placeholders={{ fieldName: companyCodePlaceholder }}
                                />
                            )}
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper floatLabel>
                            <TextInput
                                id="request-account-venture-number"
                                placeholder={ventureNumberPlaceholder}
                                name={requestAccountFields.ventureNumber}
                                value={values.ventureNumber}
                                onChange={handleChange}
                                isInvalid={touched.ventureNumber && !!errors.ventureNumber}
                            />
                            <label htmlFor="request-account-venture-number">
                                {ventureNumberPlaceholder}
                                <RequiredMarker />
                            </label>
                            {touched.ventureNumber && (
                                <FormFieldError
                                    error={errors.ventureNumber}
                                    placeholders={{ fieldName: ventureNumberPlaceholder }}
                                />
                            )}
                        </FloatableTextInputWrapper>
                        <FormError error={requestAccountAsyncInfo.error} />
                        <div className="button-wrapper">
                            <SubmitButton id="request-account-next" formName={FORM_NAME} enabledWhenValid={true}>
                                <Translate msg="auth.request_account.step_one.form.next" />
                            </SubmitButton>
                        </div>
                    </>
                );
            }}
        />
    );
};

export { RequestAccountForm };
