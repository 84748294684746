import { object } from 'yup';
import number from '../../../../../utils/libs/yup/number';
import { ErrorTypes } from '../../../../../models/general/error';
import { isCompanyAddressRequired, isMedicalCenterRequired } from '../../../../../config/intervention.config';

export const fields = {
    vaccinationLocationId: 'vaccinationLocationId',
    companyAddress: 'companyAddress',
    medicalCenter: 'medicalCenter',
};

export const schema = object({
    [fields.vaccinationLocationId]: number()
        .test(
            ErrorTypes.Custom,
            'interventions.flu_orders_new.steps.delivery.error.location_option_required',
            (value) => value && value > 0,
        ),
    [fields.companyAddress]: object()
        .when(fields.vaccinationLocationId, {
            is: isCompanyAddressRequired,
            then: object().required(),
            otherwise: object().nullable(true),
        }),
    [fields.medicalCenter]: object().nullable(true)
        .when(fields.vaccinationLocationId, {
            is: isMedicalCenterRequired,
            then: object().required(),
            otherwise: object().nullable(true),
        }),
});
