import { object } from 'yup';
import {
    jobStudentValidation,
    fields as editJobStudentFields,
    FormValues as EditJobStudentFormValues,
} from '../../../shared/EditJobStudent/editJobStudentSchema';
import number from '../../../../../../utils/libs/yup/number';
import string from '../../../../../../utils/libs/yup/string';

export const fields = {
    ...editJobStudentFields,
    functionId: 'functionId',
    functionDescription: 'functionDescription',
};

/* "extending" the editJobStudentSchema */
export const schema = object({
    ...jobStudentValidation,
    [fields.functionId]: number().required(),
    [fields.functionDescription]: string(), // only needed for when user refreshes the page
});

export interface IFormValues extends EditJobStudentFormValues {
    functionId: number;
    functionDescription: string;
}
