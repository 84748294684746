import { object, boolean } from 'yup';
import string from '../../../../../../../utils/libs/yup/string';
import phoneNumber from '../../../../../../../utils/libs/yup/phoneNumber';
import { IEmployeeDetails } from '../../../../../../../models/admin/employee';

export type FormValues = Pick<IEmployeeDetails, 'email' | 'phone'> & { skipMail: boolean; };

export const fields = {
    email: 'email',
    phone: 'phone',
    skipMail: 'skipMail',
};

export const schema = object({
    [fields.email]: string().when(fields.skipMail, {
        is: true,
        then: string().email().nullable(true),
        otherwise: string().email().required(),
    }),
    [fields.phone]: phoneNumber(),
    [fields.skipMail]: boolean(),
});
