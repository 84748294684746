import React from 'react';

import { formatLocationAddress } from '../../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import Button from '../../../../common/buttons/Button';
import LabelInfo from '../../../../common/widget/LabelInfo';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Translate from '../../../../common/Translate';
import { CLASS_NAME } from '../AppointmentDetailDialog.const';
import { getPrefixedTranslation } from '../AppointmentDetailDialog.helper';

import { IPlannedMedicalExaminationAppointmentDialogProps } from './PlannedMedicalExaminationAppointmentDialog.type';

const PlannedMedicalExaminationAppointmentDialog = ({
    data,
    canCancelExamination,
    onDelete,
}: IPlannedMedicalExaminationAppointmentDialogProps) => {
    return (
        <>
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.where')}
                info={formatLocationAddress(data.location, true)}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.who')}
                info={formatPersonName({
                    firstName: data.employee.firstName,
                    name: data.employee.lastName,
                })}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.examination_reason')}
                info={data.examinationReason && data.examinationReason.description}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.doctor')}
                info={data.doctor && `${data.doctor.lastName} ${data.doctor.firstName}`}
                hideColon={true}
            />
            <div className={`${CLASS_NAME}__actions`}>
                {canCancelExamination &&
                    <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                        <Button
                            id="cancel-medical-examination"
                            typeName="secondary"
                            outline={true}
                            onClick={onDelete}
                        >
                            <Translate msg={getPrefixedTranslation('actions.cancel_examination')} />
                        </Button>
                    </ShowIfAllowed>
                }
            </div>
        </>
    );
};

export { PlannedMedicalExaminationAppointmentDialog };
