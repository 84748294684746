import React, { Component } from 'react';
import './work-post-card-validate.scss';
import { connect } from '../../../index';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import {
    getWorkPostCardWizardEntity,
    getCreateWorkPostCardFormAsyncInfo,
} from '../../../../redux/documentCenter/workPostCards/selectors';
import {
    IWorkPostCardWizardEntity,
    IMaternityProtection,
    IEducation,
    IWorkPostCardRisks,
    IWorkClothes,
    IWorkPostProperties,
    IWorkPostCardBaseData,
    IIdentification,
} from '../../../../models/documentCenter/workPostCards';
import Translate from '../../../common/Translate';
import { createPropertyInputForDisplay } from '../../../common/forms/FormPropertyInputForDisplay';
import { ShapeOf } from '../../../../models/ui/form';
import { camelCaseToSnakeCase } from '../../../../utils/formatting/formatTranslationKey';
import {
    createWorkPostCardFormActions,
    resetWorkPostCardWizard,
} from '../../../../redux/documentCenter/workPostCards/actions';
import { getWizardExitLinkActionMemoized } from '../../../../redux/ui/history/selectors';
import Icon from '../../../common/icons/Icon';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import Dialog from '../../../common/modals/Dialog';
import Button from '../../../common/buttons/Button';

export type FormValues =
    IEducation &
    IIdentification &
    IWorkPostCardBaseData &
    IMaternityProtection &
    IWorkPostCardRisks &
    IWorkClothes &
    IWorkPostProperties;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    createWorkPostCardForm: () => void;
    createWorkPostCardFormAsyncInfo: IAsyncFieldInfo;
    navigateToAfterSuccessRoute: () => void;
}

interface IState {
    isCreateWorkPostCardDialogOpen: boolean;
}

const FORM_NAME = 'work-post-card-validate';
const BASE_KEY = 'document_center.work_post_card_wizard.steps';
const CLASS_NAME = 'WorkPostCardValidate';
const TITLE_BASE_KEY = `${BASE_KEY}.validate.form.title`;

const BASE_KEYS: ShapeOf<IWorkPostCardWizardEntity> = {
    education: `${BASE_KEY}.education.form.placeholder`,
    identification: `${BASE_KEY}.identification.form.placeholder`,
    maternityProtection: `${BASE_KEY}.maternity.form.placeholder`,
    risks: `${BASE_KEY}.risks.form.placeholder`,
    baseData: `${BASE_KEY}.base_data.form.placeholder`,
    workClothes: `${BASE_KEY}.protection.form.placeholder`,
    workPostProperties: `${BASE_KEY}.properties.form.placeholder`,
};

const ValidatePropertyInputForDisplay = createPropertyInputForDisplay<FormValues>();

class Validate extends Component<IStepperStepRenderProps & IPrivateProps, IState> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isCreateWorkPostCardDialogOpen: false,
        };

        this.onOpenSuccessDialog = this.onOpenSuccessDialog.bind(this);
        this.onCloseCreateWorkPostCardDialog = this.onCloseCreateWorkPostCardDialog.bind(this);
        this.onCreateWorkPostCardForm = this.onCreateWorkPostCardForm.bind(this);
    }

    public render() {
        const {
            renderStepButtons, wizardEntity, createWorkPostCardFormAsyncInfo,
        } = this.props;

        const VALUES: FormValues = {
            ...wizardEntity.education,
            ...wizardEntity.identification,
            ...wizardEntity.maternityProtection,
            ...wizardEntity.risks,
            ...wizardEntity.baseData,
            ...wizardEntity.workClothes,
            ...wizardEntity.workPostProperties,
        };

        const hasCreateWorkPostCardError = createWorkPostCardFormAsyncInfo.status === AsyncStatus.Error;

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.validate.title`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT)}>
                    <div className={WIZARDFLOW_CLASSES.BORDERED_FORM}>
                        <h2><Translate msg={`${TITLE_BASE_KEY}.data.title`} /></h2>
                        <h4><Translate msg={`${TITLE_BASE_KEY}.data.identification`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="identificationDate"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="identificationIdentification"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${TITLE_BASE_KEY}.data.user`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="userName"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="userAddress"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="userExternalPbw"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="userPhoneContact"
                            baseTranslationKey={BASE_KEYS.baseData}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.identification.title`} /></h2>
                        <h4><Translate msg={`${TITLE_BASE_KEY}.identification.employment_agency`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="agencyName"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="agencyAddress"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                            valueType="address"
                        />
                        <ValidatePropertyInputForDisplay
                            name="agencyPhoneContact"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="agencyPbw"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="agencyDateOfIssue"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                            valueType="date"
                        />
                        <h4><Translate msg={`${TITLE_BASE_KEY}.identification.temporary_worker`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="workerFirstName"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="workerName"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="workerDateOfBirth"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                            valueType="date"
                        />
                        <ValidatePropertyInputForDisplay
                            name="workerPhone"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="workerQualification"
                            baseTranslationKey={BASE_KEYS.identification}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.properties`} /></h2>
                        <ValidatePropertyInputForDisplay
                            name="jobFunction"
                            baseTranslationKey={BASE_KEYS.workPostProperties}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="qualification"
                            baseTranslationKey={BASE_KEYS.workPostProperties}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="localization"
                            baseTranslationKey={BASE_KEYS.workPostProperties}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="usedWorkTools"
                            baseTranslationKey={BASE_KEYS.workPostProperties}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="forbiddenForYoungPeople"
                            baseTranslationKey={BASE_KEYS.workPostProperties}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.maternity`} /></h2>
                        <ValidatePropertyInputForDisplay
                            name="pregnantAdjustment"
                            baseTranslationKey={BASE_KEYS.maternityProtection}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="pregnantRemovalPeriod"
                            baseTranslationKey={BASE_KEYS.maternityProtection}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="breastfeedingAdjustment"
                            baseTranslationKey={BASE_KEYS.maternityProtection}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="breastfeedingRemovalPeriod"
                            baseTranslationKey={BASE_KEYS.maternityProtection}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.risks`} /></h2>
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.health_surveillance`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="healthSurveillance"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="safetyFunction"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="increasedVigilanceFunction"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.chemical`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="chemical"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.physical`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="checkNoise"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="noise"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="checkTemperature"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="temperature"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="checkIonizing"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="ionizing"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="checkOtherPhysicalResources"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="otherPhysicalResources"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.biological`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="biologicalResources"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.vaccinations`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="vaccinations"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.load`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="checkWorkStationLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="workStationLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="checkLiftingLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="liftingLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="checkOthersLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="othersLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.night_or_shift_work`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="nightOrShiftWork"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="nightOrShiftWorkRisks"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.psychosocial`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="psychosocialLoad"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.food_associated_activities`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="associatedActivities"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h4><Translate msg={`${BASE_KEY}.risks.form.title.young_people`} /></h4>
                        <ValidatePropertyInputForDisplay
                            name="youngPeople"
                            baseTranslationKey={BASE_KEYS.risks}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.protection`} /></h2>
                        {Object.keys(wizardEntity.workClothes).map((key: keyof IWorkClothes) => {
                            if (!wizardEntity.workClothes[key] || key === 'otherProtection') {
                                return null;
                            }
                            return (
                                <span key={key} className={`${CLASS_NAME}__protection_item`}>
                                    <Translate
                                        msg={`${BASE_KEY}.protection.form.placeholder.${camelCaseToSnakeCase(key)}`}
                                    />
                                </span>
                            );
                        })}
                        <ValidatePropertyInputForDisplay
                            name="otherProtection"
                            baseTranslationKey={BASE_KEYS.workClothes}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <h2><Translate msg={`${TITLE_BASE_KEY}.education`} /></h2>
                        <ValidatePropertyInputForDisplay
                            name="priorInstruction"
                            baseTranslationKey={BASE_KEYS.education}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="acquiredTraining"
                            baseTranslationKey={BASE_KEYS.education}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                        <ValidatePropertyInputForDisplay
                            name="requiredTraining"
                            baseTranslationKey={BASE_KEYS.education}
                            baseId={FORM_NAME}
                            values={VALUES}
                            readOnlyColor="primary"
                        />
                    </div>
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                onClick: this.onCreateWorkPostCardForm,
                                translationKey: `${BASE_KEY}.validate.form.download_work_post_card`,
                                renderIcon: renderDownloadIcon,
                            },
                        })}
                    </StickyFooter>
                </div>
                <Dialog
                    show={this.state.isCreateWorkPostCardDialogOpen}
                    onCloseIntent={this.onCloseCreateWorkPostCardDialog}
                    type={hasCreateWorkPostCardError ? 'error' : 'success'}
                    header={hasCreateWorkPostCardError ?
                        `${BASE_KEY}.validate.dialog.error.title` : `${BASE_KEY}.validate.dialog.success.title`}
                    showLoader={createWorkPostCardFormAsyncInfo.status === AsyncStatus.Busy}
                    disableClosing={!hasCreateWorkPostCardError}
                    className={`${CLASS_NAME}__dialog`}
                    ignoreTranslationContext
                >
                    {
                        hasCreateWorkPostCardError ? (
                            <Button
                                id="error-dialog-close"
                                typeName="secondary"
                                onClick={this.onCloseCreateWorkPostCardDialog}
                            >
                                <Translate msg="common.confirmation_dialog.close" ignoreContext />
                            </Button>
                        ) : <>
                                <p><Translate msg={`${BASE_KEY}.validate.dialog.success.text`} ignoreContext /></p>
                                <div className={`${CLASS_NAME}__dialog_success_button`}>
                                    <Button
                                        id="success-dialog-confirm"
                                        typeName="secondary"
                                        onClick={this.props.navigateToAfterSuccessRoute}
                                    >
                                        <Translate msg={`${BASE_KEY}.validate.dialog.success.button`} ignoreContext />
                                    </Button>
                                </div>
                            </>
                    }
                </Dialog>
            </>
        );
    }

    private onOpenSuccessDialog() {
        this.setState({ isCreateWorkPostCardDialogOpen: true });
    }

    private onCloseCreateWorkPostCardDialog() {
        this.setState({ isCreateWorkPostCardDialogOpen: false });
    }

    private onCreateWorkPostCardForm() {
        this.props.createWorkPostCardForm();
        this.onOpenSuccessDialog();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
            createWorkPostCardFormAsyncInfo: getCreateWorkPostCardFormAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            createWorkPostCardForm: () => {
                dispatch(createWorkPostCardFormActions.trigger({}));
            },
            navigateToAfterSuccessRoute: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
                dispatch(resetWorkPostCardWizard());
            },
        };
    },
})(Validate);

function renderDownloadIcon() {
    return (
        <Icon typeName="download" />
    );
}
