import React, { PureComponent } from 'react';
import './confirmable-property-input.scss';
import Button from '../../buttons/Button';
import Translate from '../../Translate';
import {
    TextPropertyInput, TypeaheadPropertyInput,
    AsyncTypeaheadPropertyInput, ConstantsTypeaheadPropertyInput, IConstantsTypeaheadPropertyInputProps,
} from '../PropertyInput';
import { IValidationError } from '../../../../models/general/error';
import FormFieldError from '../../forms/FormFieldError';
import TinyLoader from '../../waiting/TinyLoader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import { IState } from '../../../../redux';
import { connect } from '../../..';
import { clearErrors } from '../../../../utils/libs/redux/generic/actions';
import { TDropdownItemValue } from '../Dropdown';
import { TTypeaheadData } from '../Typeahead';
import classNames from 'classnames';
import { ConstantType } from '../../../../models/general/constants';
import { maskArray } from 'react-text-mask';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';

type TInputType = 'textInput' | 'maskedTextInput' | 'typeahead' | 'asyncTypeahead' | 'constantsTypeahead';

interface IPrivateProps {
    inputType: TInputType;
    isSaving: boolean;
    asyncFieldInfoSelector: (state: IState) => IAsyncFieldInfo;
    clearError: () => void;
}

interface IConfirmablePropertyInputProps {
    id: string;
    name: string;
    labelKey: string;
    readonly: boolean;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    initialValue?: string | number;
    requestId: string;
    onSave?: () => void;
    asyncInfoSelector: (state: IState, requestId: string) => IAsyncFieldInfo;
    validationError?: {
        error: IValidationError;
        fieldNameTranslationKey: string;
    };
    tooltipTranslationKey?: string;
}

export interface IConfirmablePropertyTextInputProps extends IConfirmablePropertyInputProps {
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hideLabel?: boolean;
}

interface IConfirmablePropertyMaskedTextInputProps extends IConfirmablePropertyTextInputProps {
    mask?: maskArray;
}

interface IConfirmablePropertyTypeaheadInputProps extends IConfirmablePropertyInputProps {
    value: TDropdownItemValue;
    data: TTypeaheadData;
    onItemSelected: (selectedValue: TDropdownItemValue) => void;
}

interface IConfirmablePropertyAsyncTypeaheadInputProps extends IConfirmablePropertyTypeaheadInputProps {
    asyncInfoSelectorData?: (state: IState) => IAsyncFieldInfo;
    onFilter?: (filter: string) => void;
}

interface IConfirmablePropertyConstantsTypeaheadInputProps extends IConfirmablePropertyInputProps {
    value: string | number;
    constantType: ConstantType;
    onItemSelected: (selectedValue: string | number) => void;
}

interface IConfirmablePropertyInputState {
    touched: boolean;
    focused: boolean;
}

const CLASS_NAME = 'ConfirmablePropertyInput';

class ConfirmablePropertyInputComp extends PureComponent<
    IPrivateProps & IConfirmablePropertyTextInputProps &
    IConfirmablePropertyTypeaheadInputProps & IConfirmablePropertyAsyncTypeaheadInputProps &
    IConstantsTypeaheadPropertyInputProps & IConfirmablePropertyMaskedTextInputProps,
    IConfirmablePropertyInputState
    > {
    constructor(props) {
        super(props);

        this.state = {
            touched: false,
            focused: false,
        };

        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    public componentDidMount() {
        if (this.props.inputType === 'textInput' || this.props.inputType === 'maskedTextInput') {
            window.addEventListener('keydown', this.onKeyDown);
        }
    }

    public componentWillUnmount() {
        if (this.props.inputType === 'textInput' || this.props.inputType === 'maskedTextInput') {
            window.removeEventListener('keydown', this.onKeyDown);
        }
    }

    public render() {
        const {
            validationError,
            readonly, isSaving,
            inputType, value,
            constantType, asyncInfoSelectorData,
            mask, tooltipTranslationKey,
        } = this.props;

        const classes = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--is-empty`]: !value,
        });

        const isInvalid = this.state.touched && validationError && !!validationError.error;

        return (
            <span className={classes}>
                {inputType === 'asyncTypeahead' && (
                    <AsyncTypeaheadPropertyInput
                        {...this.props}
                        readonly={readonly}
                        disabled={isSaving}
                        isInvalid={isInvalid}
                        onFocus={this.onFocusHandler}
                        asyncInfoSelector={asyncInfoSelectorData}
                    >
                        {this.renderContent(isInvalid)}
                    </AsyncTypeaheadPropertyInput>
                )}
                {inputType === 'typeahead' && (
                    <TypeaheadPropertyInput
                        {...this.props}
                        readonly={readonly}
                        disabled={isSaving}
                        isInvalid={isInvalid}
                        onFocus={this.onFocusHandler}
                    >
                        {this.renderContent(isInvalid)}
                    </TypeaheadPropertyInput>
                )}
                {(inputType === 'textInput' || inputType === 'maskedTextInput') && (
                    <TextPropertyInput
                        {...this.props}
                        readonly={readonly}
                        disabled={isSaving}
                        isInvalid={isInvalid}
                        onFocus={this.onFocusHandler}
                        onBlur={this.onBlurHandler}
                        hasMask={inputType === 'maskedTextInput'}
                        mask={mask}
                        tooltipTranslationKey={tooltipTranslationKey}
                    >
                        {this.renderContent(isInvalid)}
                    </TextPropertyInput>
                )}
                {inputType === 'constantsTypeahead' && (
                    <ConstantsTypeaheadPropertyInput
                        {...this.props}
                        readonly={readonly}
                        disabled={isSaving}
                        isInvalid={isInvalid}
                        onFocus={this.onFocusHandler}
                        constantType={constantType}
                        resetSelectWhenValueNotFoundInData={false}
                    >
                        {this.renderContent(isInvalid)}
                    </ConstantsTypeaheadPropertyInput>
                )}
            </span>
        );
    }

    private renderContent(isInvalid: boolean) {
        const {
            validationError, onSave,
            readonly, asyncFieldInfoSelector, name,
        } = this.props;

        const initialValue = this.props.initialValue || '';
        const value = this.props.value || '';

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        {!readonly && onSave && initialValue.toString() !== value.toString() &&
                            <TinyLoader
                                asyncInfoSelector={asyncFieldInfoSelector}
                                showContentOnInitialState={true}
                                errorPlaceholderTranslationKey="common.confirmable_property_input.error"
                            >
                                <Button
                                    id={`property-input-${name.toLowerCase()}-save`}
                                    typeName="secondary"
                                    size="small"
                                    onClick={this.onSaveButtonClick}
                                    disabled={isInvalid}
                                >
                                    <Translate msg="common.confirmable_property_input.save" />
                                </Button>
                            </TinyLoader>
                        }
                        {isInvalid &&
                            <FormFieldError
                                error={validationError.error}
                                placeholders={{
                                    fieldName: translator(
                                        validationError.fieldNameTranslationKey,
                                    ),
                                }}
                            />
                        }
                    </>
                )}
            </TranslatorContext.Consumer>
        );

    }

    private onFocusHandler(e) {
        const { clearError, onFocus } = this.props;
        clearError();
        if (typeof onFocus === 'function') {
            onFocus(e);
        }
        this.setState({ focused: true });
    }

    private onBlurHandler(e) {
        this.setState({ focused: false });

    }

    private onKeyDown(e: KeyboardEvent) {
        const {
            onSave,
            readonly,
        } = this.props;

        const initialValue = this.props.initialValue || '';
        const value = this.props.value || '';

        if (
            e.keyCode === 13 &&
            this.state.focused &&
            !readonly
            && onSave
            && initialValue.toString() !== value.toString()
        ) {
            this.onSaveButtonClick();
        }
    }

    private onSaveButtonClick() {
        const { onSave, validationError } = this.props;
        if (validationError) {
            if (!validationError.error) {
                onSave();
            }
        } else {
            onSave();
        }
        this.setState({ touched: true });
    }
}

const mapToPrivateProps = (inputType: TInputType) => ({
    statePropsDeprecated: (state, publicProps) => {
        const asyncState = publicProps.asyncInfoSelector(state, publicProps.requestId);
        return {
            inputType,
            isSaving: asyncState.status === AsyncStatus.Busy,
            asyncFieldInfoSelector: () => asyncState,
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        return {
            clearError: () => {
                const state = getState();
                const asyncState = publicProps.asyncInfoSelector(state, publicProps.requestId);
                if (asyncState.error) {
                    dispatch(clearErrors([asyncState.error.id]));
                }
            },
        };
    },
});

export const ConfirmableTextPropertyInput = connect<IPrivateProps, IConfirmablePropertyTextInputProps>(
    mapToPrivateProps('textInput'))(ConfirmablePropertyInputComp);

export const ConfirmableMaskedTextPropertyInput = connect<IPrivateProps, IConfirmablePropertyMaskedTextInputProps>(
    mapToPrivateProps('maskedTextInput'))(ConfirmablePropertyInputComp);

export const ConfirmableTypeaheadPropertyInput = connect<IPrivateProps, IConfirmablePropertyTypeaheadInputProps>(
    mapToPrivateProps('typeahead'))(ConfirmablePropertyInputComp);

export const ConfirmableAsyncTypeaheadPropertyInput = connect<
    IPrivateProps, IConfirmablePropertyAsyncTypeaheadInputProps>(
        mapToPrivateProps('asyncTypeahead'))(ConfirmablePropertyInputComp);

export const ConfirmableConstantsTypeaheadPropertyInput = connect<
    IPrivateProps, IConfirmablePropertyConstantsTypeaheadInputProps>(
        mapToPrivateProps('constantsTypeahead'))(ConfirmablePropertyInputComp);
