import { string } from 'yup';
import { ErrorTypes } from '../../../models/general/error';

// https://www.owasp.org/index.php/CSV_Injection

// -1 is allowed because we use this value in forms for UNSELECTED_ZIPCODEID
export const CSV_EXPORT_VULNERABILITIES_REGEX = /(^(?![=+@\-]).*|^-1$)/;

export default () => string()
    .matches(CSV_EXPORT_VULNERABILITIES_REGEX, { message: ErrorTypes.StartsWithEqualsPlusMinusAt });
