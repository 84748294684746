import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER, makeAsyncDoInfoSelector } from '../../index';
import { IPolicyAdvice, IPolicyAdviceComment } from '../../../models/interventions/policyAdvice';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Policy advice overview
const getPolicyAdviceAsyncField = (state: IState) => reducerState(state).policyAdvice;

export const getPolicyAdviceAsyncInfo = makeAsyncFetchInfoSelector(getPolicyAdviceAsyncField);

export const getPolicyAdvice = (state: IState) =>
    getPolicyAdviceAsyncField(state).data || NO_RERENDER.EMPTY_LIST as IPolicyAdvice[];

export const isPolicyAdviceAvailable = (state: IState) =>
    getPolicyAdviceAsyncField(state).data !== null;

// Policy advice comments
const getPolicyAdviceCommentsAsyncField = (state: IState) => reducerState(state).policyAdviceComments;

export const getPolicyAdviceCommentsAsyncInfo = makeAsyncFetchInfoSelector(getPolicyAdviceCommentsAsyncField);

export const getPolicyAdviceComments = (state: IState) =>
    getPolicyAdviceCommentsAsyncField(state).data || NO_RERENDER.EMPTY_LIST as IPolicyAdviceComment[];

// Send policy advice comment
const getSendPolicyAdviceCommentAsyncField = (state: IState) => reducerState(state).sendPolicyAdviceComment;

export const getSendPolicyAdviceCommentAsyncInfo = makeAsyncDoInfoSelector(getSendPolicyAdviceCommentAsyncField);
