import React, { PureComponent, ReactNode } from 'react';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { IState } from '../../../../redux';
import { connect } from '../../../index';
import ErrorDialog from '../../modals/ErrorDialog';
import SuccessDialog from '../../modals/SuccessDialog';
import Loader from '../../waiting/Loader';

interface IPublicProps {
    children: ReactNode;
    doActionAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    successConfig: {
        titleTranslationKey: string;
        infoTranslationKey?: string;
        // TODO optional success dialog content so that for example there could be 2 different CTA's inside
        confirmButtonTranslationKey?: string;
        onConfirm: () => void;
    };
    errorConfig: {
        titleTranslationKey: string;
        infoTranslationKey?: string;
        confirmButtonTranslationKey?: string;
        hideRealErrorMessage?: boolean;
        onConfirm: () => void;
    };
}

interface IPrivateProps {
    doActionAsyncInfo: IAsyncFieldInfo;
}

interface IComponentState {
    isActionSuccess: boolean;
}

class ResultAfterDoAction extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            isActionSuccess: false,
        };
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        if (prevProps.doActionAsyncInfo.status === AsyncStatus.Busy
            && this.props.doActionAsyncInfo.status === AsyncStatus.Success) {
            this.setState({
                isActionSuccess: true,
            });
        }
    }

    public render() {
        const { doActionAsyncInfo, successConfig, errorConfig } = this.props;
        const { isActionSuccess } = this.state;

        return (
            <>
                <Loader show={doActionAsyncInfo.status}>
                    {this.props.children}
                </Loader>
                <SuccessDialog
                    show={isActionSuccess}
                    titleTranslationKey={successConfig.titleTranslationKey}
                    infoTranslationKey={successConfig.infoTranslationKey}
                    confirmButtonTranslationKey={successConfig.confirmButtonTranslationKey}
                    onCloseDialog={successConfig.onConfirm}
                />
                <ErrorDialog
                    asyncInfo={doActionAsyncInfo}
                    titleTranslationKey={errorConfig.titleTranslationKey}
                    infoTranslationKey={errorConfig.infoTranslationKey}
                    confirmButtonTranslationKey={errorConfig.confirmButtonTranslationKey}
                    hideRealErrorMessage={errorConfig.hideRealErrorMessage}
                    onCloseDialog={errorConfig.onConfirm}
                />
            </>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                doActionAsyncInfo: publicProps.doActionAsyncInfoSelector(state),
            };
        };
    },
})(ResultAfterDoAction);
