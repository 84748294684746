import { remove, put } from '../../utils/api/requestWrapper';
import {
    IUpdateEmployeeStatutePayload,
    IRemoveEmployeeStatutePayload,
} from '../../models/admin/employee';
import { formatDateForBackend } from '../../utils/formatting/formatDate';

export const URL = {
    EMPLOYEE_STATUTE: '/employees/{employeeId}/statutes/{personalId}',
};

export function updateStatute(payload: IUpdateEmployeeStatutePayload) {
    return put({
        url: URL.EMPLOYEE_STATUTE,
        pathParams: {
            employeeId: payload.employeeId,
            personalId: payload.personalId,
        },
        queryParams: {
            dateInactive: payload.dateInactive,
        },
    });
}

export function removeStatute(payload: IRemoveEmployeeStatutePayload) {
    return remove({
        url: URL.EMPLOYEE_STATUTE,
        pathParams: {
            employeeId: payload.employeeId,
            personalId: payload.personalId,
        },
        queryParams: {
            dateInactive: formatDateForBackend(new Date()),
        },
    });
}
