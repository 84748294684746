import { pathOr } from 'ramda';

import { createEpic } from '../../index';
import {
    FETCH_DOCUMENT,
    CREATE_CONVOCATIONS,
    UPLOAD_EMPLOYEE_DOCUMENT,
    FETCH_CONVOCATION_RECIPIENTS,
} from './types';
import {
    fetchDocumentSucceeded, fetchDocumentFailed,
    createConvocationsActions,
    uploadEmployeeDocumentActions,
    fetchConvocationRecipientsActions,
} from './actions';
import Api from '../../../api';
import {
    ICreateConvocationsPayload,
    IFetchDocumentPayload,
    IUploadEmployeeDocumentPayload,
    ICreateConvocationActionPayload,
} from '../../../models/general/documents';
import { removeEmptyPropsFromObject } from '../../../utils/core/object/objectProps';
import {
    mapMedicalExaminationToSubmittedFormActionType,
} from '../../../utils/logging/analytics/mapToSubmittedFormActionType';
import {
    getPlanMedicalExaminationWizardReason,
    getPlanMedicalExaminationWizardType,
} from '../../medicalExamination/selectors';
import { getSelectedCompanyCode, getSelectedSeatCompanyId } from '../../company/selected/selectors';
import { triggerFlashThatDownloadToMessageCenterIsTriggered } from '../../flashMessages/actions';
import { getLocationState } from '../../location/selectors';
import { getLocale } from '../../i18n/selectors';

// fetchDocumentEpic
createEpic<IFetchDocumentPayload>({
    onActionType: FETCH_DOCUMENT,
    processReturn: fetchDocument,
    latest: false,
});

async function fetchDocument({ api, action, getState }: { api: typeof Api, action, getState }) {
    try {
        const file = await api.general.documents.fetchDocuments(
            action.payload,
            { routeKeyForErrorLogging: getLocationState(getState()).type },
        );
        return fetchDocumentSucceeded(file);
    } catch (error) {
        return fetchDocumentFailed(error);
    }
}

// createConvocationsEpic
createEpic<ICreateConvocationActionPayload>({
    onActionType: CREATE_CONVOCATIONS,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            const { payload } = action;

            const convocations = pathOr<ICreateConvocationsPayload[]>([], ['convocations'], payload);
            const shouldLogFormSubmissionEvent = pathOr<boolean>(false, ['convocations'], payload);

            const state = getState();
            const locale = getLocale(state);

            const customerId = getSelectedSeatCompanyId(state);
            const createConvocationsRequestPayload: ICreateConvocationsPayload[] =
                convocations.map((item) => removeEmptyPropsFromObject(item));

            const downloadAmount = convocations.filter((item) => item.requestType === 'Letter').length;

            await api.general.documents.createConvocations(
                createConvocationsRequestPayload,
                customerId,
                locale,
            );

            if (downloadAmount > 0) {
                dispatch(triggerFlashThatDownloadToMessageCenterIsTriggered());
            }

            const wizardType = getPlanMedicalExaminationWizardType(state);
            const examinationReason = getPlanMedicalExaminationWizardReason(state);

            dispatch(createConvocationsActions.succeeded(
                {},
                {
                    // Don't log form submission event when e.g. on planned examinations screen!
                    ...shouldLogFormSubmissionEvent && {
                            logFormSubmissionEvent: () => mapMedicalExaminationToSubmittedFormActionType({
                            wizardType,
                            examinationReason,
                        }),
                    },
                },
            ));

            return done();
        } catch (error) {
            dispatch(createConvocationsActions.failed(error));
            return done();
        }
    },
    latest: false,
});

// uploadEmployeeDocumentEpic
createEpic<IUploadEmployeeDocumentPayload>({
    onActionType: UPLOAD_EMPLOYEE_DOCUMENT,
    async processReturn({ action, api }) {
        try {
            const documentInfo = await api.general.documents.uploadEmployeeDocument(action.payload);
            return uploadEmployeeDocumentActions.succeeded(documentInfo);
        } catch (error) {
            return uploadEmployeeDocumentActions.failed(error);
        }
    },
    latest: false,
});

// fetchConvocationRecipientsEpic
createEpic<{}>({
    onActionType: FETCH_CONVOCATION_RECIPIENTS,
    async processReturn({ getState, api }) {
        try {
            const companyCode = getSelectedCompanyCode(getState());
            const recipients = await api.general.documents.fetchConvocationRecipients({ companyCode });
            return fetchConvocationRecipientsActions.succeeded(recipients);
        } catch (error) {
            return fetchConvocationRecipientsActions.failed(error);
        }
    },
    latest: false,
});
