import React, { ReactNode } from 'react';
import './form-field-error.scss';
import { ErrorResourceIds, ErrorTypes, IValidationError } from '../../../../models/general/error';
import Translate, { TPlaceholders } from '../../Translate';

interface IProps {
    error: IValidationError;
    placeholders?: {
        fieldName?: string;
        minValue?: string;
        maxValue?: string;
        detailsLink?: ReactNode;
    };
    ignoreTranslationContext?: boolean;
    mapErrorToResource?: (error: IValidationError) => ErrorResourceIds;
}

function FormFieldError(props: IProps) {
    if (!props.error) {
        return null;
    }

    const mapErrorType = props.mapErrorToResource || mapErrorTypeToResource;
    const errorType = mapErrorType(props.error) || mapErrorTypeToResource(props.error);
    const translationKey = (props.error.type === ErrorTypes.Custom) ?
        props.error.message :
        `error.validation.${errorType}`;

    return (
        <div className="FormFieldError">
            <p>
                <Translate
                    msg={translationKey}
                    placeholders={props.placeholders as TPlaceholders}
                    ignoreContext={props.ignoreTranslationContext}
                />
            </p>
        </div>
    );
}

export default FormFieldError;

function mapErrorTypeToResource(error: IValidationError): ErrorResourceIds {
    switch (error.type) {
        case ErrorTypes.Required:
            return ErrorResourceIds.Required;
        case ErrorTypes.InvalidEmail:
            return ErrorResourceIds.InvalidEmail;
        case ErrorTypes.InvalidNumber:
            return ErrorResourceIds.InvalidNumber;
        case ErrorTypes.InvalidNationalRegisterNumber:
            return ErrorResourceIds.InvalidNationalRegisterNumber;
        case ErrorTypes.Min:
            return ErrorResourceIds.MustBeMinimumLength;
        case ErrorTypes.MinValue:
            return ErrorResourceIds.MustBeMinimumValue;
        case ErrorTypes.InvalidTime:
            return ErrorResourceIds.InvalidTime;
        case ErrorTypes.SelectAtLeastOne:
            return ErrorResourceIds.SelectAtLeastOne;
        case ErrorTypes.InvalidPassword:
            return ErrorResourceIds.InvalidPassword;
        case ErrorTypes.PasswordConfirm:
            return ErrorResourceIds.PasswordConfirm;
        case ErrorTypes.PasswordMinLength:
            return ErrorResourceIds.PasswordMinLength;
        case ErrorTypes.MaxDate:
            return ErrorResourceIds.MustBeMaximumDate;
        case ErrorTypes.MinDate:
            return ErrorResourceIds.MustBeMinimumDate;
        case ErrorTypes.InvalidPeriod:
            return ErrorResourceIds.InvalidPeriod;
        case ErrorTypes.CountryNotSupported:
            return ErrorResourceIds.CountryNotSupported;
        case ErrorTypes.InvalidPhoneNumber:
            return ErrorResourceIds.InvalidPhoneNumber;
        case ErrorTypes.StartsWithEqualsPlusMinusAt:
            return ErrorResourceIds.StartsWithEqualsPlusMinusAt;
    }
    return ErrorResourceIds.Default;
}
