import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { MENU_ITEMS_ON_MOBILE } from '../../../config/navigation/menu.config';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Notification from '../../common/widget/Notification';
import { connect } from '../..';
import { getUnreadMessagesCountIfAny } from '../../../redux/inbox/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getSkipPerformanceDashboardWarning,
    getSkipPerformanceDashboardTutorial,
    getPerformanceDashboardAccessData,
} from '../../../redux/auth/selectors';
import { getPath } from '../../../routes';
import {
    toggleIsPerformanceDashboardDialogOpenAction,
    navigateToPerformanceDashboardAction,
} from '../../../redux/auth/actions';
import { isAllSeatsSelected } from '../../../redux/company/selected/selectors';

interface IMobileNavProps {
    isMenuOpen?: boolean;
    onOpenMenu: (e: MouseEvent<HTMLButtonElement>) => void;
    onCloseMenu: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface IPrivateProps {
    unreadMessagesCount: number;
    skipPerformanceDashboardWarning: boolean;
    skipPerformanceDashboardTutorial: boolean;
    showPerformanceDashboardLink: boolean;
    togglePerformanceDashboardDialog: () => void;
    navigateToPerformanceDashboard: () => void;
    allSeatsSelected: boolean;
}

function MobileNav({
    isMenuOpen = false,
    onOpenMenu,
    onCloseMenu,
    unreadMessagesCount,
    skipPerformanceDashboardTutorial,
    skipPerformanceDashboardWarning,
    showPerformanceDashboardLink,
    togglePerformanceDashboardDialog,
    navigateToPerformanceDashboard,
    allSeatsSelected,
}: IMobileNavProps & IPrivateProps) {
    return (
        <>
            <Button id="mobile-menu-button" className="mobile-menu-button" onClick={onOpenMenu}>
                <Translate msg="app_shell.header.nav.button_mobile" />
            </Button>
            <div
                className={classNames('mobile-nav', {
                    open: isMenuOpen,
                })}
            >
                <Button id="mobile-menu-close-button" className="mobile-nav-close-button" onClick={onCloseMenu}>
                    <Icon typeName="cross" />
                </Button>
                <nav className="mobile-nav-list">
                    {
                        MENU_ITEMS_ON_MOBILE
                            .map((menuItem) => {
                                if (menuItem.isPerformanceDashboardLink) {
                                    if (showPerformanceDashboardLink) {
                                        return (
                                            <Button
                                                id="performance-dashboard-link-mobile"
                                                typeName="text"
                                                onClick={onPerformanceDashboardClicked}
                                                key="performance-dashboard-menu-item"
                                            >
                                                <Translate msg={menuItem.translationKey} />
                                            </Button>
                                        );
                                    }
                                    return null;
                                }

                                return (
                                    <LinkToRoute
                                        id={`mobile-menu-item-${menuItem.code}-link`}
                                        key={`mobile-menu-item-${menuItem.code}`}
                                        to={menuItem.linkTo}
                                    >
                                        <Translate msg={menuItem.translationKey} />
                                        {
                                            menuItem.iconTypeName === 'message' &&
                                            <Notification count={unreadMessagesCount} />
                                        }
                                    </LinkToRoute>
                                );
                            })
                    }
                </nav>
            </div>
        </>
    );

    /*
        When changing this function, also change function in TopMenu component
    */
    function onPerformanceDashboardClicked(e: MouseEvent<HTMLButtonElement>) {
        if (skipPerformanceDashboardWarning || allSeatsSelected) {
            if (skipPerformanceDashboardTutorial) {
                navigateToPerformanceDashboard();
            } else {
                const path = getPath({ routeKey: ROUTE_KEYS.R_PERFORMANCE_DASHBOARD });
                window.open(path, '_blank');
            }
        } else {
            togglePerformanceDashboardDialog();
        }
        onCloseMenu(e);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const performanceAccess = getPerformanceDashboardAccessData(state);

        return {
            unreadMessagesCount: getUnreadMessagesCountIfAny(state),
            skipPerformanceDashboardWarning: getSkipPerformanceDashboardWarning(state),
            skipPerformanceDashboardTutorial: getSkipPerformanceDashboardTutorial(state),
            showPerformanceDashboardLink: performanceAccess && performanceAccess.grantAccess,
            allSeatsSelected: isAllSeatsSelected(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            togglePerformanceDashboardDialog: () => {
                dispatch(toggleIsPerformanceDashboardDialogOpenAction());
            },
            navigateToPerformanceDashboard: () => {
                dispatch(navigateToPerformanceDashboardAction.trigger({ openInNewTab: true }));
            },
        };
    },
})(MobileNav);
