import React, { PureComponent, MouseEvent, ChangeEvent } from 'react';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import {
    getSelectedEmployeeJobStudent,
    getSelectedEmployee,
    getUpdateEmployeeJobStudentAsyncInfo,
    getAddEmployeeJobStudentAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import FormError from '../../../../common/forms/FormError';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import TextInput from '../../../../common/input/TextInput';
import Checkbox from '../../../../common/input/Checkbox';
import {
    updateEmployeeJobStudentActions,
    addEmployeeJobStudentActions,
} from '../../../../../redux/employee/info/actions';
import RequiredMarker from '../../../../common/input/RequiredMarker';
import FormFieldError from '../../../../common/forms/FormFieldError';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { schema, FormValues } from './editJobStudentSchema';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import { isFalsyOrEmptyObject } from '../../../../../utils/core/object/isEmptyObject';

interface IPrivateProps {
    onSubmit: (values: FormValues) => void;
    initialValues: FormValues;
    isDetailsLoaded: boolean;
    clearError: (error: ITraceableApiError) => void;
    updateAsyncInfo: IAsyncFieldInfo;
    addAsyncInfo: IAsyncFieldInfo;
}

interface IEditJobStudentProps {
    onClose: () => void;
    onSave: (data: Partial<FormValues>) => void;
}

const FORM_NAME = 'edit-job-student-form';
const CLASS_NAME = 'EditJobStudent';

const TRANSLATION_PREFIX = 'administration.employees.edit_job_student';

class EditJobStudent extends PureComponent<IPrivateProps & IEditJobStudentProps> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props: IPrivateProps & IEditJobStudentProps) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeStudentWorker = this.onChangeStudentWorker.bind(this);
    }

    public render() {
        const {
            initialValues, onSubmit,
            isDetailsLoaded, updateAsyncInfo, addAsyncInfo,
        } = this.props;

        if (!isDetailsLoaded) {
            return null;
        }

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h2>
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={onSubmit}
                    schema={schema}
                    initialValues={initialValues}
                    render={(formRenderProps: IFormRenderProps<FormValues>) => {

                        const {
                            values, handleChange, touched, errors,
                        } = formRenderProps;

                        this.formRenderProps = formRenderProps;

                        const showLoader =
                            updateAsyncInfo.status === AsyncStatus.Busy ||
                            addAsyncInfo.status === AsyncStatus.Busy;

                        return (
                            <TranslatorContext.Consumer>
                                {({ translator }) => (
                                    <>
                                        <Loader show={showLoader} />
                                        {updateAsyncInfo.error && <ErrorPlaceholder apiError={updateAsyncInfo.error} />}
                                        {addAsyncInfo.error && <ErrorPlaceholder apiError={addAsyncInfo.error} />}
                                        <Checkbox
                                            name="studentWorker"
                                            checked={values.studentWorker}
                                            onChange={this.onChangeStudentWorker}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.student_worker`} />
                                        </Checkbox>
                                        <h4><Translate msg={`${TRANSLATION_PREFIX}.subtitle.parameters`} /></h4>
                                        <Checkbox
                                            name="safetyFunction"
                                            checked={values.safetyFunction}
                                            onChange={handleChange}
                                            toggleButton={true}
                                            disabled={!values.studentWorker}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.safety_function`} />
                                        </Checkbox>
                                        <Checkbox
                                            name="medicalFunction"
                                            checked={values.medicalFunction}
                                            onChange={handleChange}
                                            toggleButton={true}
                                            disabled={!values.studentWorker}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.medical_function`} />
                                        </Checkbox>
                                        <Checkbox
                                            name="sendMail"
                                            checked={values.sendMail}
                                            onChange={handleChange}
                                            toggleButton={true}
                                            disabled={!values.studentWorker}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.form.send_mail`} />
                                        </Checkbox>
                                        {values.studentWorker && values.sendMail && (
                                            <FloatableTextInputWrapper floatLabel>
                                                <TextInput
                                                    id="edit-job-student-email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="edit-job-student-email">
                                                    <Translate msg={`${TRANSLATION_PREFIX}.form.email`} />
                                                    <RequiredMarker />
                                                </label>
                                                {touched.email &&
                                                    <FormFieldError
                                                        error={errors.email}
                                                        placeholders={{
                                                            fieldName: translator(`${TRANSLATION_PREFIX}.form.email`),
                                                        }}
                                                    />}
                                            </FloatableTextInputWrapper>
                                        )}
                                        <h4><Translate msg={`${TRANSLATION_PREFIX}.subtitle.employer`} /></h4>
                                        <FloatableTextInputWrapper floatLabel>
                                            <TextInput
                                                id="edit-job-student-employer"
                                                name="employer"
                                                value={values.employer}
                                                onChange={handleChange}
                                                disabled={!values.studentWorker}
                                            />
                                            <label htmlFor="edit-job-student-employer">
                                                <Translate
                                                    msg={`${TRANSLATION_PREFIX}.form.employer`}
                                                />
                                                <RequiredMarker />
                                            </label>
                                            {touched.employer &&
                                                <FormFieldError
                                                    error={errors.employer}
                                                    placeholders={{
                                                        fieldName: translator(`${TRANSLATION_PREFIX}.form.employer`),
                                                    }}
                                                />}
                                        </FloatableTextInputWrapper>
                                        <FormError error={updateAsyncInfo.error} />
                                        <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                            <Button
                                                id="edit-job-student-cancel-button"
                                                typeName="secondary"
                                                outline={true}
                                                onClick={this.onCancelClick}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.form.cancel`} />
                                            </Button>
                                            <SubmitButton
                                                id="edit-job-student-submit-button"
                                                formName={FORM_NAME}
                                            >
                                                <Translate msg={`${TRANSLATION_PREFIX}.form.submit`} />
                                            </SubmitButton>
                                        </div>
                                    </>
                                )}
                            </TranslatorContext.Consumer>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidMount() {
        const { clearError, updateAsyncInfo, addAsyncInfo } = this.props;
        clearError(updateAsyncInfo.error);
        clearError(addAsyncInfo.error);
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            (this.props.updateAsyncInfo.status === AsyncStatus.Success &&
                prevProps.updateAsyncInfo.status === AsyncStatus.Busy) ||
            (this.props.addAsyncInfo.status === AsyncStatus.Success &&
                prevProps.addAsyncInfo.status === AsyncStatus.Busy)
        ) {
            const { email, studentWorker, sendMail } = this.formRenderProps.values;
            if (studentWorker && sendMail) {
                this.props.onSave({
                    email,
                });
            }
            this.props.onClose();
        }
    }

    private onChangeStudentWorker(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.checked) {
            this.formRenderProps.setFieldValue('medicalFunction', false);
            this.formRenderProps.setFieldValue('safetyFunction', false);
            this.formRenderProps.setFieldValue('employer', '');
        }
        this.formRenderProps.handleChange(e);
    }

    private onCancelClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearError, updateAsyncInfo, addAsyncInfo } = this.props;
        clearError(updateAsyncInfo.error);
        clearError(addAsyncInfo.error);
        onClose();
    }
}

export default connect<IPrivateProps, IEditJobStudentProps>({
    stateProps: (state) => {
        const selectedEmployeeJobStudent = getSelectedEmployeeJobStudent(state);
        const updateAsyncInfo = getUpdateEmployeeJobStudentAsyncInfo(state);
        const addAsyncInfo = getAddEmployeeJobStudentAsyncInfo(state);
        const selectedEmployee = getSelectedEmployee(state);

        return {
            isDetailsLoaded: !!selectedEmployeeJobStudent,
            initialValues: {
                employer: selectedEmployeeJobStudent.employer,
                medicalFunction: !!selectedEmployeeJobStudent.medicalFunction
                        ? selectedEmployeeJobStudent.medicalFunction : false,
                safetyFunction: !!selectedEmployeeJobStudent.safetyFunction
                        ? selectedEmployeeJobStudent.safetyFunction : false,
                studentWorker: !!selectedEmployeeJobStudent.studentWorker,
                sendMail: true,
                email: selectedEmployee && selectedEmployee.email,
            },
            updateAsyncInfo,
            addAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSubmit: (values: FormValues) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);
                const id = selectedEmployee.id;
                const jobStudent = getSelectedEmployeeJobStudent(state);

                const shouldUpdateEmail =
                    values.studentWorker &&
                    values.sendMail &&
                    selectedEmployee.email !== values.email;

                if (isFalsyOrEmptyObject(jobStudent)) {
                    dispatch(addEmployeeJobStudentActions.trigger({
                        id,
                        employer: values.employer ? values.employer : '',
                        medicalFunction: values.medicalFunction ? true : false,
                        safetyFunction: values.safetyFunction ? true : false,
                        sendMail: values.sendMail ? true : false,
                        emailAddressToUpdate: shouldUpdateEmail ? values.email : null,
                    }));
                } else {
                    dispatch(updateEmployeeJobStudentActions.trigger({
                        id,
                        interimEmployeeId: jobStudent.interimEmployeeId,
                        employer: values.employer ? values.employer : '',
                        medicalFunction: values.medicalFunction ? true : false,
                        safetyFunction: values.safetyFunction ? true : false,
                        studentWorker: values.studentWorker ? true : false,
                        sendMail: values.studentWorker ?
                            values.sendMail ? true : false :
                            false,
                        emailAddressToUpdate: shouldUpdateEmail ? values.email : null,
                    }));
                }
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditJobStudent);
