import React, { PureComponent } from 'react';
import { ICourseSessionDay } from '../../../../models/documentCenter/courses';
import { connect } from '../../..';
import { getCourseSessionDays } from '../../../../redux/documentCenter/courses/selectors';
import Translate from '../../../common/Translate';
import LabelInfo from '../../../common/widget/LabelInfo';
import { formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';

interface IPrivateProps {
    courseSessionDays: ICourseSessionDay[];
}

class CourseSessionDays extends PureComponent<IPrivateProps> {

    constructor(props: IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            courseSessionDays,
        } = this.props;

        return (
            <>
                { courseSessionDays.length > 1 &&
                    <em>
                        <Translate
                            msg="document_center.courses.shared.days.subtitle"
                            placeholders={{
                                count: courseSessionDays.length,
                            }}
                        />
                    </em>
                }
                {
                    courseSessionDays.map((day: ICourseSessionDay, index) => {
                        return (
                            <LabelInfo
                                key={index}
                                label={formatDateInLongFormat(day.startDate)}
                                // eslint-disable-next-line max-len
                                info={`${formatTimeOfDateForDisplay(day.startDate)} - ${formatTimeOfDateForDisplay(day.endDate)}`}
                            />
                        );
                    })
                }
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        courseSessionDays: getCourseSessionDays(state),
    }),
})(CourseSessionDays);
