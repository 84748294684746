import React, { useEffect } from 'react';
import { getMyUserProfile, isLoggedIn } from '../../../redux/auth/selectors';
import { getQueryParams } from '../../../redux/location/selectors';
import connect from '../../../utils/libs/redux/connect';
import Loader from '../../common/waiting/Loader';
import { redirectOrFetchSeatsAfterSuccessfulLogin } from '../../../redux/location/actions';
import {
    areSelectedCompanySeatsAndDivisionsAvailable,
    getCompanySeatsForSelectedCompany,
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
    getSelectedCompany,
    getSelectedCompanySeat,
} from '../../../redux/company/selected/selectors';
import { AsyncStatus } from '../../../models/general/redux';
import { usePrevious } from '../../../utils/hooks/usePrevious';

interface IPrivateProps {
    userIsLoggedIn: boolean;
    onLoginOrCompanySeatsFetchedSucceeded: () => void;
    fetchCompanySeatsStatus: AsyncStatus;
}

function LoginInProgress({
    userIsLoggedIn,
    onLoginOrCompanySeatsFetchedSucceeded,
    fetchCompanySeatsStatus,
}: IPrivateProps) {
    const previousUserIsLoggedIn = usePrevious(userIsLoggedIn);
    const previousFetchSeatsStatus = usePrevious(fetchCompanySeatsStatus);
    useEffect(() => {
        if (
            !previousUserIsLoggedIn && userIsLoggedIn ||
            previousFetchSeatsStatus === AsyncStatus.Busy && fetchCompanySeatsStatus === AsyncStatus.Success
        ) {
            onLoginOrCompanySeatsFetchedSucceeded();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIsLoggedIn, fetchCompanySeatsStatus]);

    return (
        <div>
            <Loader useFullScreen show />
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        userIsLoggedIn: isLoggedIn(state),
        fetchCompanySeatsStatus: getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state).status,
    }),
    dispatchProps: (dispatch, getState) => ({
        onLoginOrCompanySeatsFetchedSucceeded: () => {
            const state = getState();
            const userProfile = getMyUserProfile(state);
            const { redirectUrl } = getQueryParams<{ redirectUrl: string }>(state);
            const actions = redirectOrFetchSeatsAfterSuccessfulLogin({
                hasSelectedACompany: !!getSelectedCompany(state),
                hasSelectedASeat: !!getSelectedCompanySeat(state),
                areCompanySeatsAvailable: areSelectedCompanySeatsAndDivisionsAvailable(state),
                companySeats: getCompanySeatsForSelectedCompany(state),
                userProfile,
                redirectUrl,
            });
            actions.forEach((action) => dispatch(action));
        },
    }),
})(LoginInProgress);
