import { object, date } from 'yup';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    reasonOutService: 'reasonOutService',
    dateOutService: 'dateOutService',
    clearOutOfService: 'clearOutOfService',
};

export const schema = object({
    [fields.reasonOutService]: string(),
    [fields.dateOutService]: date().when(fields.clearOutOfService, { is: 'false', then: date().required() }),
    [fields.clearOutOfService]: string(),
});
