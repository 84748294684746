import React, { PureComponent, MouseEvent, Component } from 'react';
import './event-list.scss';
import classNames from 'classnames';
import Week from 'react-big-calendar/lib/Week';
import { ICalendarEvent } from '../../../../../models/ui/calendar';
import { getMonday, dayOffsetFromDate } from '../../../../../utils/core/date/getSpecificDate';
import { isDateBetween } from '../../../../../utils/core/date/isDateBetween';
import {
    formatDateToDayMonth,
    dateToDayOfWeek,
    formatDateForDisplay,
} from '../../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../config/calendar.config';
import Translate from '../../../Translate';
import Sticky from '../../../technical/Sticky';
import EventTitle, { getEventTitle } from '../EventTitle';
import { BigCalendarProps } from 'react-big-calendar';
import { connect } from '../../../..';
import { IAgendaExportData } from '../../../../../models/planning/agenda';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { ITranslator } from '../../../../../models/general/i18n';
import { setAgendaExportDataActions } from '../../../../../redux/planning/actions';

// Use the same names / types as provided by react-big-calendar for the Agenda
// https://github.com/intljusticemission/react-big-calendar/blob/master/src/Agenda.js
interface IEventListProps extends Pick<
    BigCalendarProps<ICalendarEvent>,
    'onSelectEvent' | 'events' | 'selected'
    > {
    date?: Date;
    selected?: ICalendarEvent;
    /* renderAllEvents is not set when 'list view' selected (upper-right), but is set when the filter is opened */
    renderAllEvents?: boolean;
    renderYear?: boolean;
}
interface IPrivateProps {
    updateAgendaListExportData?: (data: IAgendaExportData[]) => void;
}

interface IContextProps {
    translator?: ITranslator;
}

interface IColumnConfig {
    translationKey: string;
    cssClassName: string | ((renderYear: boolean) => string);
}

const COLUMNS = {
    date: {
        translationKey: 'common.calendar.list_view.columns.date',
        cssClassName: (renderYear: boolean) => {
            if (!renderYear) {
                return 'date';
            }
            return 'date--many-years date';
        },
    } as IColumnConfig,
    time: {
        translationKey: 'common.calendar.list_view.columns.time',
        cssClassName: 'time',
    } as IColumnConfig,
    type: {
        translationKey: 'common.calendar.list_view.columns.type',
        cssClassName: 'type',
    } as IColumnConfig,
    detail: {
        translationKey: 'common.calendar.list_view.columns.detail',
        cssClassName: 'detail',
    } as IColumnConfig,
};

const CLASS_NAME = 'EventList';

class EventListComp extends PureComponent<IEventListProps & IPrivateProps & IContextProps> {
    public constructor(props: IEventListProps & IPrivateProps & IContextProps) {
        super(props);

        this.createAndUpdateAgendaListExportData = this.createAndUpdateAgendaListExportData.bind(this);

    }
    public render() {
        const { renderYear } = this.props;
        return (
            <div className={CLASS_NAME}>
                <Sticky id="EventListHeader" includeTopOffsetForIds={['CalendarToolbar']}>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(COLUMNS).map((column, index) => {
                                    const columnConfig: IColumnConfig = COLUMNS[column];
                                    const className = typeof columnConfig.cssClassName === 'function'
                                        ? columnConfig.cssClassName(renderYear)
                                        : columnConfig.cssClassName;
                                    return (
                                        <th className={className} key={index}>
                                            <Translate msg={COLUMNS[column].translationKey} />
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                    </table>
                </Sticky>
                <table>
                    <tbody>
                        {this.getEventsInRange().length === 0 ? (
                            <tr className="no-results">
                                <td colSpan={Object.keys(COLUMNS).length}>
                                    <Translate msg="common.calendar.list_view.no_items" />
                                </td>
                            </tr>
                        ) : this.renderListItems()}
                    </tbody>
                </table>
            </div>
        );
    }

    public componentDidMount() {
        this.createAndUpdateAgendaListExportData();
    }

    public componentDidUpdate() {
        this.createAndUpdateAgendaListExportData();
    }

    private createAndUpdateAgendaListExportData() {
        const { translator, updateAgendaListExportData } = this.props;
        const eventExportDataList: IAgendaExportData[] = [];

        this.getEventsInRange().forEach((event, index) => {
            const eventTypeConfig = CALENDAR_EVENT_TYPES_CONFIG[event.type];
            const eventTypeTitle = eventTypeConfig
                ? eventTypeConfig.labelTranslationKey
                : '';

            const eventExportData: IAgendaExportData = {
                [translator(COLUMNS.date.translationKey)]:
                    formatDateForDisplay(event.start),
                [translator(COLUMNS.time.translationKey)]:
                    `${formatTimeOfDateForDisplay(event.start)}-${formatTimeOfDateForDisplay(event.end)}`,
                [translator(COLUMNS.type.translationKey)]:
                    translator(eventTypeTitle),
                [translator(COLUMNS.detail.translationKey)]:
                    getEventTitle({ translator, event, titleType: 'list-event' }),
            };

            eventExportDataList.push(eventExportData);
        });

        updateAgendaListExportData(eventExportDataList);
    }

    private getEventsInRange() {
        const { events, date, renderAllEvents } = this.props;
        if (!renderAllEvents) {
            const monday = getMonday(date);
            const nextMonday = getMonday(dayOffsetFromDate(date, 7).toDate());
            return events.filter(event => (
                event.start.getTime() === monday.getTime() ||
                isDateBetween(event.start, monday, nextMonday)
            ));
        }
        return events;
    }

    private renderListItems() {
        const { renderYear, onSelectEvent, selected } = this.props;

        // sort events
        const events = this.getEventsInRange().sort((a, b) => {
            const aDate = new Date(a.start);
            const bDate = new Date(b.start);

            return bDate < aDate ? 1 : bDate > aDate ? -1 : 0;
        });

        const eventRows = events.map((event, index) => {
            const eventTypeConfig = CALENDAR_EVENT_TYPES_CONFIG[event.type];
            const extraCssClassNameType = eventTypeConfig ? eventTypeConfig.cssClassName : '';
            const eventTypeTitle = eventTypeConfig
                ? eventTypeConfig.labelTranslationKey
                : '';

            const newDate = index === 0
                || (formatDateToDayMonth(events[index - 1].start) !== formatDateToDayMonth(event.start));

            const getClassName = (columnConfig: IColumnConfig) => {
                return typeof columnConfig.cssClassName === 'function'
                    ? columnConfig.cssClassName(renderYear)
                    : columnConfig.cssClassName;
            };

            const onRowClick = typeof onSelectEvent === 'function' ? (e: MouseEvent<HTMLElement>) => {
                onSelectEvent(event, e);
            } : undefined;

            const rowClassName = classNames('item', {
                selected: event.id === (selected && selected.id),
            });

            return (
                <tr className={rowClassName} key={index} onClick={onRowClick}>
                    <td
                        className={classNames(getClassName(COLUMNS.date), {
                            [`${getClassName(COLUMNS.date)}--same`]: !newDate,
                        })}
                    >
                        <Translate msg={`app_shell.dates.days.${dateToDayOfWeek(event.start)}`} /><br />
                        <div className="date">{formatDate(event, renderYear)}</div>
                    </td>
                    <td className={getClassName(COLUMNS.time)}>
                        {!event.allDay
                            ? (
                                <>
                                    {formatTimeOfDateForDisplay(event.start)}
                                    &nbsp;-&nbsp;
                                    {formatTimeOfDateForDisplay(event.end)}
                                </>
                            ) : typeof event.afterNoon === 'undefined'
                                ? <Translate msg="common.calendar.all_day" />
                                : !event.afterNoon
                                    ? <Translate msg="common.calendar.morning" />
                                    : <Translate msg="common.calendar.afternoon" />
                        }
                    </td>
                    <td
                        className={classNames(getClassName(COLUMNS.type), {
                            [extraCssClassNameType]: !!eventTypeConfig,
                        })}
                    >
                        <span>
                            <Translate msg={eventTypeTitle} />
                        </span>
                    </td>
                    <td className={getClassName(COLUMNS.detail)}>
                        <EventTitle event={event} titleType="list-event" />
                    </td>
                </tr>
            );
        });

        return eventRows;
    }
}

function formatDate(event: ICalendarEvent, renderYear: boolean) {
    if (!renderYear) {
        return formatDateToDayMonth(event.start);
    }
    return formatDateForDisplay(event.start);
}

class EventList extends Component<IEventListProps & IPrivateProps> {
    public static title = Week.title;
    public static range = Week.range;
    public static navigate = Week.navigate;

    public render() {
        return (
            <TranslatorContext.Consumer>
                {({ translator }) => <EventListComp {...this.props} translator={translator} />}
            </TranslatorContext.Consumer>
        );
    }
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch) => {
        return {
            updateAgendaListExportData: (data: IAgendaExportData[]) => {
                dispatch(setAgendaExportDataActions.trigger(data));
            },
        };
    },
})(EventList);
