import { createAction, createTypeActions } from '../../../utils/libs/redux/createAction';
import { getPlanBufferzoneWizardSteps } from '../../../config/navigation/wizardStepsMap';
import {
    IBufferzonePlanningCancelExistingPlanningPayload,
    IBufferzonePlanningCreateNewPlanningPayload,
    IBufferzonePlanningMoveExistingPlanningPayload,
    IPlanBufferzoneWizardEntity,
    IPlanBufferzoneWizardPayload,
    ISkipToPlanBufferzoneWizardStepPayload,
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
} from '../../../models/interventions/bufferzones';
import { IBufferzoneTimeslots } from '../../../models/interventions/timeslots';
import { IFetchReservedMedicalExaminationPayload } from '../../../models/interventions/medicalExaminations/reserved';
import { navigateTo } from '../../location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
    BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    CREATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
    FETCH_BUFFERZONE_TIMESLOTS,
    RESET_PLAN_BUFFERZONE_WIZARD_ENTITY,
    SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    UPDATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
} from './types';

// Plan bufferzone wizard
export function navigateToPlanBufferzoneWizardStep(payload: IPlanBufferzoneWizardPayload) {
    return navigateTo(ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW, payload);
}

export function triggerPlanBufferzoneWizard() {
    return navigateToPlanBufferzoneWizardStep({
        step: getPlanBufferzoneWizardSteps().firstStepId,
        resetDataEntity: true,
    });
}

export function changePlanBufferzoneWizardStep(step: PLAN_BUFFERZONE_WIZARD_STEP_ID) {
    return navigateToPlanBufferzoneWizardStep({
        step,
        resetDataEntity: false,
    });
}

export function updatePlanBufferzoneWizardEntity(payload: Partial<IPlanBufferzoneWizardEntity>) {
    return createAction<object>(
        UPDATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
        payload,
    );
}

export const fetchBufferzoneTimeslotsActions =
    createTypeActions<IFetchReservedMedicalExaminationPayload, IBufferzoneTimeslots>({
        type: FETCH_BUFFERZONE_TIMESLOTS,
    });

export const skipToPlanBufferzoneWizardStepActions =
    createTypeActions<ISkipToPlanBufferzoneWizardStepPayload>({
        type: SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    });

export function resetPlanBufferzoneWizardEntity() {
    return createAction(RESET_PLAN_BUFFERZONE_WIZARD_ENTITY, {});
}

export function createPlanBufferzoneWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        CREATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
        payload,
    );
}

// Bufferzone planning actions
export const bufferzonePlanningCreateNewPlanningActions =
    createTypeActions<IBufferzonePlanningCreateNewPlanningPayload>({
        type: BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    });

export const bufferzonePlanningMoveExistingPlanningActions =
    createTypeActions<IBufferzonePlanningMoveExistingPlanningPayload>({
        type: BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    });

export const bufferzonePlanningCancelExistingPlanningActions =
    createTypeActions<IBufferzonePlanningCancelExistingPlanningPayload>({
        type: BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
    });
