import React, { ReactNode, ReactElement } from 'react';
import './multi-level-slide-out-panel.scss';
import SlideOutPanel, { ISlideOutPanelProps } from '../SlideOutPanel';
import classNames from 'classnames';
import { ILocationAction } from '../../../../models/general/redux';
import GoBack from '../../navigation/GoBack';

interface IMultiLevelSlideOutPanel extends Pick<ISlideOutPanelProps, 'isOpen' | 'onCloseIntent'> {
    goBackLinks?: { [level: number]: ILocationAction<{}> };
    headers?: { [level: number]: ReactElement<{ children: ReactNode }> };
    footers?: { [level: number]: ReactElement<{ children: ReactNode }> };
    children: { [level: number]: ReactNode };
    lastLevelVisible: number;
    showOverlay?: boolean;
    overlay?: ReactElement<{ children: ReactNode }>;
}

const CLASS_NAME = 'MultiLevelSlideOutPanel';

export default function MultiLevelSlideOutPanel(props: IMultiLevelSlideOutPanel) {
    const {
        isOpen, onCloseIntent, children, footers,
        headers, lastLevelVisible, showOverlay, overlay, goBackLinks,
    } = props;

    const levelCount = Object.keys(children).length;
    const className = classNames(CLASS_NAME, {
        [`${CLASS_NAME}--2-levels`]: levelCount === 2,
        [`${CLASS_NAME}--3-levels`]: levelCount === 3,
        [`${CLASS_NAME}--${lastLevelVisible}-open-levels`]: !!lastLevelVisible,
    });

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onCloseIntent={onCloseIntent}
            className={className}
            showOverlay={showOverlay}
            overlay={overlay}
        >
            {(renderProps) => {
                return Object.keys(children).map((level) => {
                    const className = classNames(`${CLASS_NAME}__content`, {
                        [`${CLASS_NAME}__content--visible`]: Number(level) <= lastLevelVisible,
                    });

                    const content = children[level];
                    const header = headers && headers[level];
                    const footer = footers && footers[level];
                    const goBackLink: ILocationAction<{}> = goBackLinks && goBackLinks[level];
                    const showGoBackLink = goBackLink && Number(level) <= lastLevelVisible;
                    return (
                        <div key={level} className={className}>
                            {showGoBackLink && <GoBack
                                id={`slideout-panel-link-back-${level}`}
                                to={goBackLink}
                                iconTypeName="arrow-left"
                            />}
                            {header && renderProps.renderHeader(header)}
                            {renderProps.renderContent(content)}
                            {footer && renderProps.renderFooter(footer)}
                        </div>
                    );
                });
            }}
        </SlideOutPanel>
    );
}
