import { createEpic, triggerDownloadToMessageCenterFlashIfDelayed } from '../index';
import {
    FETCH_OPEN_INVOICES,
    FETCH_PAID_INVOICES,
    FETCH_INVOICE_DOCUMENT,
    FETCH_INVOICE_DETAIL,
    FETCH_INVOICE_FULL,
} from './types';
import {
    fetchOpenInvoicesCountSucceeded, fetchOpenInvoicesCountFailed,
    fetchOpenInvoicesSucceeded, fetchOpenInvoicesFailed,
    fetchPaidInvoicesSucceeded, fetchPaidInvoicesFailed,
    fetchInvoiceDocumentSucceeded, fetchInvoiceDocumentFailed,
    fetchInvoiceDetailSucceeded, fetchInvoiceDetailFailed,
    fetchInvoiceFullSucceeded, fetchInvoiceFullFailed, fetchInvoiceParametersActions,
} from './actions';
import ROUTE_KEYS from '../../routeKeys';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../company/selected/selectors';
import { INVOICE_TYPES } from '../../config/administration.config';
import { IFetchInvoicePayload, IFetchInvoicesPayload, IInvoicesFilter } from '../../models/admin/invoices';
import { getLocationState, getQueryParams } from '../location/selectors';
import { DEFAULT_OPEN_INVOICES_FILTERS, DEFAULT_PAID_INVOICES_FILTERS } from '../../api/admin/invoices.api';
import { areObjectParamsEqual } from '../../utils/core/object/diffObjects';
import { getCompanySituationHistory } from '../preventionUnits/selectors';
import { getLatestCompanySituationYear } from '../preventionUnits/companySituationHistoryUtils';
import { areOpenInvoicesAvailable, arePaidInvoicesAvailable } from './selectors';

// fetchOpenInvoicesCountEpic
createEpic<{}>({
    onActionType: ROUTE_KEYS.R_HOME,
    processFilter: ({ getState }) => {
        const state = getState();
        return !!getSelectedSeatCompanyCode(state);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const type = INVOICE_TYPES.open;

            const result = await api.admin.invoices.fetchInvoicesCount(
                { companyCode, showFullFamily, type },
            );
            return fetchOpenInvoicesCountSucceeded(result);
        } catch (error) {
            return fetchOpenInvoicesCountFailed(error);
        }
    },
    latest: false,
});

// fetchOpenInvoicesEpic
createEpic<IFetchInvoicesPayload>({
    onActionType: [ROUTE_KEYS.R_INVOICES_OPEN, FETCH_OPEN_INVOICES],
    refreshDataIf: ({ getState, action }) => {
        if (!areOpenInvoicesAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_OPEN_INVOICES_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IInvoicesFilter;
            const type = INVOICE_TYPES.open;

            const result = await api.admin.invoices.fetchInvoices(
                { companyCode, showFullFamily, type }, filterFromQuery,
            );
            return fetchOpenInvoicesSucceeded(result);
        } catch (error) {
            return fetchOpenInvoicesFailed(error);
        }
    },
    latest: false,
});

// fetchPaidInvoicesEpic
createEpic<IFetchInvoicesPayload>({
    onActionType: [ROUTE_KEYS.R_INVOICES_PAID, FETCH_PAID_INVOICES],
    refreshDataIf: ({ getState, action }) => {
        if (!arePaidInvoicesAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_PAID_INVOICES_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IInvoicesFilter;
            const type = INVOICE_TYPES.paid;

            const result = await api.admin.invoices.fetchInvoices(
                { companyCode, showFullFamily, type },
                {
                    startDate: filterFromQuery.startDate || DEFAULT_PAID_INVOICES_FILTERS.startDate,
                    endDate: filterFromQuery.endDate || DEFAULT_PAID_INVOICES_FILTERS.endDate,
                },
            );
            return fetchPaidInvoicesSucceeded(result);
        } catch (error) {
            return fetchPaidInvoicesFailed(error);
        }
    },
    latest: false,
});

// fetchInvoiceDocumentEpic
createEpic<IFetchInvoicePayload>({
    onActionType: FETCH_INVOICE_DOCUMENT,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { invoiceIds, delayed } = action.payload;

            const file = await api.admin.invoices.fetchInvoiceDocument({ invoiceIds, delayed });

            triggerDownloadToMessageCenterFlashIfDelayed(delayed);

            dispatch(fetchInvoiceDocumentSucceeded(file));
        } catch (error) {
            dispatch(fetchInvoiceDocumentFailed(error));
        }
        done();
    },
    latest: false,
});

// fetchInvoiceDetailEpic
createEpic<IFetchInvoicePayload>({
    onActionType: FETCH_INVOICE_DETAIL,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { invoiceIds, delayed } = action.payload;

            const file = await api.admin.invoices.fetchInvoiceDetail({ invoiceIds, delayed });

            triggerDownloadToMessageCenterFlashIfDelayed(delayed);

            dispatch(fetchInvoiceDetailSucceeded(file));
        } catch (error) {
            dispatch(fetchInvoiceDetailFailed(error));
        }
        done();
    },
    latest: false,
});

// fetchInvoiceFullEpic
createEpic<IFetchInvoicePayload>({
    onActionType: FETCH_INVOICE_FULL,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { invoiceIds, delayed } = action.payload;

            const file = await api.admin.invoices.fetchInvoiceFull({ invoiceIds, delayed });

            triggerDownloadToMessageCenterFlashIfDelayed(delayed);

            dispatch(fetchInvoiceFullSucceeded(file));
        } catch (error) {
            dispatch(fetchInvoiceFullFailed(error));
        }
        done();
    },
    latest: false,
});

// fetchInvoiceParamtersEpic
createEpic<{}>({
    onActionType: ROUTE_KEYS.R_COMPANY_RATES_PARAMETERS,
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const companySituationHistory = getCompanySituationHistory(state);
            const year = getLatestCompanySituationYear(companySituationHistory);

            const response = await api.admin.invoices.fetchInvoiceParameters({
                companyCode,
                year,
            });

            dispatch(fetchInvoiceParametersActions.succeeded(response));
        } catch (error) {
            dispatch(fetchInvoiceParametersActions.failed(error));
        }
        done();
    },
    latest: false,
});
