import React, { Component } from 'react';
import './flu-vaccines-orders.scss';
import { schema } from './editFluVaccinesOrderSchema';
import { TextPropertyInput, AdvancedPropertyInput } from '../../common/input/PropertyInput';
import {
    IRenderDetailHeaderProps, IRenderDetailContentProps,
} from '../../common/widget/MasterWithDetail/typings';
import Translate from '../../common/Translate';
import Loader from '../../common/waiting/Loader';
import TinyLoader from '../../common/waiting/TinyLoader';
import Form, { IFormRenderProps, TSetFieldValue } from '../../common/forms/Form';
import { ICompany, ICompanySeat } from '../../../models/admin/company';
import { ErrorTypes } from '../../../models/general/error';
import { AsyncStatus } from '../../../models/general/redux';
import {
    IFluVaccinesOrder,
    IUpdateFluVaccinesOrder,
    IFluVaccineContact,
    IFluVaccineTariffs,
} from '../../../models/interventions/fluVaccines';
import connect from '../../../utils/libs/redux/connect';
import {
    getFluVaccinesOrdersAsyncInfo, getFluVaccineTariffs, isSelectedFluVaccineOrderEditable,
} from '../../../redux/intervention/fluVaccine/selectors';
import { formatDateForDisplay } from '../../../utils/formatting/formatDate';
import { formatPrice } from '../../../utils/formatting/formatPrice';
import { formatAddress } from '../../../utils/formatting/formatAddress';
import {
    FluVaccinesOrdersDetailsOverlayType,
    IEditAmountOverlaySaveData,
    IEditLocationOverlaySaveData,
} from './detailOverlay';
import {
    isCompanyAddressRequired,
    isMedicalCenterRequired,
    DELIVERY_OPTIONS,
    VaccinationLocationId,
} from '../../../config/intervention.config';
import { updateFluVaccinesOrderActions } from '../../../redux/intervention/fluVaccine/actions';
import { formatPersonName } from '../../../utils/formatting/formatPerson';
import {
    calculateInitialFluVaccinesOrderPriceWithoutTravelExpensesFromOrderData,
    calculateTotalVaccinesPrice,
} from '../FluVaccinesOrderWizard/fluVaccinesOrderPriceCalculator';
import FormFieldError from '../../common/forms/FormFieldError';
import SubmitButton from '../../common/buttons/SubmitButton';
import { ITranslator } from '../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { removeEmptyPropsFromObject } from '../../../utils/core/object/objectProps';
import { areCompanyContactsAvailable } from '../../../redux/company/info/selectors';

export const DetailHeader = (props: IRenderDetailHeaderProps<IFluVaccinesOrder>) => {
    const {
        detailAsyncInfo,
        detailData,
    } = props;

    return (
        <>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : <Translate msg="interventions.flu_orders_detail.title" />
                }
            </h1>
            <TinyLoader asyncInfoSelector={getFluVaccinesOrdersAsyncInfo}>
                <p>
                    <Translate
                        msg="interventions.flu_orders_detail.subtitle"
                        placeholders={{ id: detailData && detailData.id }}
                    />
                </p>
            </TinyLoader>
        </>
    );
};

const CLASS_NAME = 'FluVaccinesOrdersDetail';
const FORM_NAME = 'FluVaccinesOrdersDetailForm';

interface IPrivateProps {
    mayEdit: boolean;
    translator: ITranslator;
    tarriffs: IFluVaccineTariffs;
    areContactsAvailable: boolean;
    updateFluVaccinesOrder: (values: IFormValues, detailData: IFluVaccinesOrder) => void;
}

export interface IFormValues {
    amountVaccinesOrdered: number;
    confirmed: string;
    contact: IFluVaccineContact;
    location: IEditLocationOverlaySaveData;
    seat: ICompany;
    referentionCode: string;
    remark: string;
    orderDate: string;
    travelExpenses: number;
    priceVaccines: number;
}

class DetailContentComp extends Component<IPrivateProps & IRenderDetailContentProps<IFluVaccinesOrder>> {

    constructor(props: IPrivateProps & IRenderDetailContentProps<IFluVaccinesOrder>) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleSeatChange = this.handleSeatChange.bind(this);
    }

    private resetForm: (newValues: object) => void;
    private setFormFieldValue: TSetFieldValue<IFormValues>;
    private formValues: IFormValues;

    public componentDidUpdate(
        prevProps: IPrivateProps & IRenderDetailContentProps<IFluVaccinesOrder>,
    ) {
        const switchedDetail =
            (prevProps.detailData && this.props.detailData && (prevProps.detailData.id !== this.props.detailData.id));

        const successfulUpdate = prevProps.detailUpdateAsyncInfo.status === AsyncStatus.Busy &&
            this.props.detailUpdateAsyncInfo.status === AsyncStatus.Success;

        if (this.resetForm && switchedDetail) {
            this.resetForm(mapDetailDataToInitialValues(this.props.detailData));
        }

        if (this.resetForm && successfulUpdate) {
            this.resetForm(this.formValues);
        }
    }

    public render() {
        const {
            areContactsAvailable, detailAsyncInfo, detailData,
            mayEdit, onOpenOverlay, detailUpdateAsyncInfo,
            translator,
        } = this.props;

        if (!detailData || !areContactsAvailable) {
            this.resetForm = null;
            return null;
        }

        const INITIAL_VALUES = mapDetailDataToInitialValues(detailData);

        return (
            <Form
                name={FORM_NAME}
                handleSubmit={this.handleSubmit}
                initialValues={INITIAL_VALUES}
                schema={schema}
                className={CLASS_NAME}
                footer={mayEdit && (
                    <SubmitButton
                        id="edit-flu-vaccines-order-submit-button"
                        formName={FORM_NAME}
                        disabledWhenInvalid={true}
                    >
                        <Translate
                            msg="interventions.flu_orders_detail.details.save"
                        />
                    </SubmitButton>
                )}
                render={({
                    values, handleChange, errors, setFieldValue, resetForm, touched,
                }: IFormRenderProps<IFormValues>) => {
                    if (!this.resetForm) {
                        this.resetForm = resetForm;
                    }

                    this.setFormFieldValue = setFieldValue;
                    this.formValues = values;

                    return (
                        <Loader
                            show={(detailAsyncInfo.status === AsyncStatus.Busy ||
                                detailUpdateAsyncInfo.status === AsyncStatus.Busy)}
                        >
                            <h2>
                                <Translate msg="interventions.flu_orders_detail.details.title" />
                            </h2>
                            <h4>
                                <Translate msg="interventions.flu_orders_detail.details.order_data" />
                            </h4>
                            <TextPropertyInput
                                id="orderDate"
                                name="orderDate"
                                labelKey="interventions.flu_orders_detail.details.order_date"
                                readonly={true}
                                value={values.orderDate && formatDateForDisplay(values.orderDate)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.amount"
                                readonly={!mayEdit}
                                value={values.amountVaccinesOrdered && values.amountVaccinesOrdered.toString()}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditAmount,
                                    this.handleAmountChange,
                                    values)}
                            />
                            <TextPropertyInput
                                id="confirmed"
                                name="confirmed"
                                labelKey="interventions.flu_orders_detail.details.confirmed_date"
                                readonly={true}
                                value={values.confirmed && formatDateForDisplay(values.confirmed)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.seat"
                                readonly={!mayEdit}
                                value={values.seat.name}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditSeat,
                                    this.handleSeatChange,
                                    values)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.location"
                                readonly={!mayEdit}
                                value={translator(values.location.description)}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditLocation,
                                    this.handleLocationChange,
                                    values)}
                            />
                            {isCompanyAddressRequired(values.location.vaccinationLocationId) &&
                                <AdvancedPropertyInput
                                    labelKey="interventions.flu_orders_detail.details.address"
                                    value={formatAddress(values.location.companyAddress)}
                                    readonly={!mayEdit}
                                    onClick={() => onOpenOverlay(
                                        FluVaccinesOrdersDetailsOverlayType.EditLocation,
                                        this.handleLocationChange,
                                        values)}
                                />
                            }
                            {isMedicalCenterRequired(values.location.vaccinationLocationId) &&
                                <AdvancedPropertyInput
                                    labelKey="interventions.flu_orders_detail.details.medical_center"
                                    readonly={!mayEdit}
                                    value={values.location.medicalCenter && values.location.medicalCenter.name}
                                    onClick={() => onOpenOverlay(
                                        FluVaccinesOrdersDetailsOverlayType.EditLocation,
                                        this.handleLocationChange,
                                        values)}
                                />
                            }
                            {touched.location && errors.location && errors.location.type === ErrorTypes.Required &&
                                <FormFieldError
                                    error={{
                                        type: ErrorTypes.Custom,
                                        message: 'interventions.flu_orders_detail.edit_location.incomplete_data',
                                    }}
                                />}
                            <TextPropertyInput
                                id="referentionCode"
                                name="referentionCode"
                                labelKey="interventions.flu_orders_detail.details.referention_code"
                                readonly={!mayEdit}
                                value={values.referentionCode}
                                isInvalid={touched.referentionCode && !!errors.referentionCode}
                                onChange={handleChange}
                            />
                            <TextPropertyInput
                                id="remark"
                                name="remark"
                                labelKey="interventions.flu_orders_detail.details.remark"
                                readonly={!mayEdit}
                                value={values.remark}
                                isInvalid={touched.remark && !!errors.remark}
                                onChange={handleChange}
                                multiLine={true}
                                rows={1}
                                scaleWithTextIfMultiLine={true}
                            />
                            <h4>
                                <Translate msg="interventions.flu_orders_detail.details.price.title" />
                            </h4>
                            <TextPropertyInput
                                id="price-vaccines"
                                name="price-vaccines"
                                labelKey="interventions.flu_orders_detail.details.price.vaccines"
                                readonly={true}
                                value={formatPrice(values.priceVaccines)}
                            />
                            <TextPropertyInput
                                id="price"
                                name="price-vaccines"
                                labelKey="interventions.flu_orders_detail.details.price.travel_expenses"
                                readonly={true}
                                value={formatPrice(values.travelExpenses)}
                            />
                            <TextPropertyInput
                                id="price"
                                name="price-vaccines"
                                labelKey="interventions.flu_orders_detail.details.price.total"
                                readonly={true}
                                value={formatPrice(values.priceVaccines + values.travelExpenses)}
                            />
                            <h4>
                                <Translate msg="interventions.flu_orders_detail.details.contact.title" />
                            </h4>
                            {errors.contact &&
                                <FormFieldError
                                    error={{
                                        type: ErrorTypes.Custom,
                                        message: 'interventions.flu_orders_detail.edit_contact.incomplete_data',
                                    }}
                                />}
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.contact.name"
                                readonly={!mayEdit}
                                value={formatPersonName(values.contact)}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditContact,
                                    this.handleContactChange,
                                    values)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.contact.email"
                                readonly={!mayEdit}
                                value={values.contact && values.contact.email}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditContact,
                                    this.handleContactChange,
                                    values)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.contact.phone"
                                readonly={!mayEdit}
                                value={values.contact && values.contact.phone}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditContact,
                                    this.handleContactChange,
                                    values)}
                            />
                            <AdvancedPropertyInput
                                labelKey="interventions.flu_orders_detail.details.contact.mobile"
                                readonly={!mayEdit}
                                value={values.contact && values.contact.mobilePhone}
                                onClick={() => onOpenOverlay(
                                    FluVaccinesOrdersDetailsOverlayType.EditContact,
                                    this.handleContactChange,
                                    values)}
                            />
                        </Loader>
                    );
                }}
            />
        );
    }

    private handleSubmit(values: IFormValues) {
        const { detailData, updateFluVaccinesOrder } = this.props;
        updateFluVaccinesOrder(values, detailData);
    }

    private handleAmountChange(data: IEditAmountOverlaySaveData) {
        const { tarriffs } = this.props;
        this.setFormFieldValue('amountVaccinesOrdered', data.newAmount);
        this.setFormFieldValue('travelExpenses', this.formValues.travelExpenses);
        this.setFormFieldValue('priceVaccines', calculateTotalVaccinesPrice(
            data.newAmount,
            {
                influenzaTariff: tarriffs.influenzaTariff,
                travelCostTariff: this.formValues.travelExpenses,
            },
        ));
    }

    private handleContactChange(data: IFluVaccineContact) {
        this.setFormFieldValue('contact', data);
    }

    private handleLocationChange(data: IEditLocationOverlaySaveData) {
        const { tarriffs } = this.props;

        if (data.vaccinationLocationId === VaccinationLocationId.Company) {
            this.setFormFieldValue('travelExpenses', tarriffs.travelCostTariff);
        } else {
            this.setFormFieldValue('travelExpenses', 0);
        }

        this.setFormFieldValue(
            'priceVaccines',
            calculateTotalVaccinesPrice(this.formValues.amountVaccinesOrdered, tarriffs),
        );
        this.setFormFieldValue('location', data);
    }

    private handleSeatChange(data: ICompanySeat) {
        this.setFormFieldValue('seat', data.company);
    }
}

export const DetailContent = connect<IPrivateProps, IRenderDetailContentProps<IFluVaccinesOrder>>({
    stateProps: (state) => {
        return {
            mayEdit: isSelectedFluVaccineOrderEditable(state),
            tarriffs: getFluVaccineTariffs(state),
            translator: getTranslatorDeprecated(state),
            areContactsAvailable: areCompanyContactsAvailable(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateFluVaccinesOrder: (values: IFormValues, initialData: IFluVaccinesOrder) => {
                dispatch(updateFluVaccinesOrderActions.trigger({
                    data: mapFormToUpdateFluVaccinesOrderBody(values, initialData),
                    fullOrder: mapFormToFluVaccinesOrderForListUpdate(values, initialData),
                }));
            },
        };
    },
})(DetailContentComp);

function mapFormToUpdateFluVaccinesOrderBody(
    values: IFormValues, initialData: IFluVaccinesOrder,
): IUpdateFluVaccinesOrder {
    const body = {
        id: initialData.id,
        address: values.location.companyAddress ? {
            id: values.location.companyAddress.id,
        } : null,
        amountVaccinesOrdered: values.amountVaccinesOrdered,
        company: {
            companyCode: values.seat.companyCode,
        },
        contact: values.contact ? {
            id: values.contact.id,
        } : null,
        locationId: values.location.vaccinationLocationId,
        medicalCenter: values.location.medicalCenter ? {
            id: values.location.medicalCenter.id,
        } : null,
        referentionCode: values.referentionCode,
        remark: values.remark,
    };

    return removeEmptyPropsFromObject(body);
}

function mapFormToFluVaccinesOrderForListUpdate(
    values: IFormValues, initialData: IFluVaccinesOrder,
): IFluVaccinesOrder {
    return ({
        ...initialData,
        id: initialData.id,
        address: values.location.companyAddress,
        amountVaccinesOrdered: values.amountVaccinesOrdered,
        company: values.seat,
        contact: values.contact,
        locationId: values.location.vaccinationLocationId,
        medicalCenter: values.location.medicalCenter,
        referentionCode: values.referentionCode,
        remark: values.remark,
    });
}

function mapDetailDataToInitialValues(detailData: IFluVaccinesOrder): IFormValues {
    const {
        address,
        amountVaccinesOrdered,
        company,
        confirmed,
        contact,
        locationId,
        medicalCenter,
        referentionCode,
        remark,
        orderDate,
        travelExpenses,
    } = detailData;

    const deliveryOption = DELIVERY_OPTIONS.find((option) => (
        option.vaccinationLocationId === locationId
    ));

    return {
        amountVaccinesOrdered,
        confirmed,
        contact,
        location: {
            vaccinationLocationId: locationId,
            description: deliveryOption ? deliveryOption.detailLabel : '',
            companyAddress: address,
            medicalCenter,
        },
        seat: company,
        referentionCode: referentionCode || '',
        remark: remark || '',
        priceVaccines: calculateInitialFluVaccinesOrderPriceWithoutTravelExpensesFromOrderData(detailData),
        orderDate,
        travelExpenses: typeof travelExpenses === 'string' ? parseFloat(travelExpenses) : travelExpenses,
    };
}
