import React, { PureComponent } from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import './amount-input.scss';
import { fields } from './fluVaccineAmountToOrderSchema';
import { calculateTotalVaccinesPrice, getVaccineUnitPrice } from '../../fluVaccinesOrderPriceCalculator';
import { connect } from '../../../../index';
import { FLU_VACCINES_MIN_AMOUNT_TO_ORDER } from '../../../../../config/intervention.config';
import { ITranslator } from '../../../../../models/general/i18n';
import { IFluVaccineTariffs } from '../../../../../models/interventions/fluVaccines';
import {
    getAmountOfAvailableFluVaccines,
    getFluVaccineTariffs,
} from '../../../../../redux/intervention/fluVaccine/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IFormRenderProps } from '../../../../common/forms/Form';
import FormFieldError from '../../../../common/forms/FormFieldError';
import TextInput from '../../../../common/input/TextInput';
import Dialog from '../../../../common/modals/Dialog';
import Translate from '../../../../common/Translate';
import { formatPrice } from '../../../../../utils/formatting/formatPrice';
import Button from '../../../../common/buttons/Button';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';

const CLASS_NAME = 'OrderFluVaccine__AmountInput';

export interface IFormValues {
    amountToOrder: number;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues, totalVaccinesPrice: number) => void;
    initialAmountToOrder: number;
}

interface IPrivateProps {
    tariffs: IFluVaccineTariffs;
    translator: ITranslator;
    amountAvailableFluVaccines: number;
    onClose: () => void;
}

interface IComponentState {
    totalVaccinesPrice: number;
    isEnoughAvailable: boolean;
}

class AmountInput extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            totalVaccinesPrice: calculateTotalVaccinesPrice(
                props.formRenderProps.values.amountToOrder,
                props.tariffs,
            ),
            isEnoughAvailable: this.isEnoughAvailableFluVaccines(1),
        };

        this.onChangeAmountHandler = this.onChangeAmountHandler.bind(this);
        this.onCloseNotEnoughAvailableDialog = this.onCloseNotEnoughAvailableDialog.bind(this);
        this.getMaxAvailableFluVaccines = this.getMaxAvailableFluVaccines.bind(this);
        this.isEnoughAvailableFluVaccines = this.isEnoughAvailableFluVaccines.bind(this);
    }

    public render() {
        const { formRenderProps, tariffs, translator } = this.props;
        const { totalVaccinesPrice, isEnoughAvailable } = this.state;

        return (
            <>
                <div className={CLASS_NAME}>
                    <TextInput
                        id="order-amount"
                        name={fields.amountToOrder}
                        value={formRenderProps.values.amountToOrder.toString()}
                        type="number"
                        min={FLU_VACCINES_MIN_AMOUNT_TO_ORDER}
                        onChange={this.onChangeAmountHandler}
                        isInvalid={!!formRenderProps.errors.amountToOrder}
                    />
                    <label htmlFor="order-amount">
                        <Translate
                            msg="interventions.flu_orders_new.steps.nr_of_vaccines.label"
                            placeholders={{
                                unit: formatPrice(getVaccineUnitPrice(tariffs)),
                                total: formatPrice(totalVaccinesPrice),
                            }}
                        />
                    </label>
                </div>
                <FormFieldError
                    error={formRenderProps.errors.amountToOrder}
                    placeholders={{
                        fieldName: translator(
                            'interventions.flu_orders_new.steps.nr_of_vaccines.amount_to_order',
                        ),
                        minValue: FLU_VACCINES_MIN_AMOUNT_TO_ORDER.toString(),
                    }}
                />
                <Dialog
                    show={!isEnoughAvailable}
                    onCloseIntent={this.onCloseNotEnoughAvailableDialog}
                    header="interventions.flu_orders_new.steps.nr_of_vaccines.not_enough_available_vaccines.title"
                    type="error"
                >
                    <p><Translate
                        msg="interventions.flu_orders_new.steps.nr_of_vaccines.not_enough_available_vaccines.text"
                        placeholders={{
                            max: this.getMaxAvailableFluVaccines(),
                        }}
                    /></p>
                    <Button
                        id="warning-dialog-confirm"
                        typeName="secondary"
                        onClick={this.onCloseNotEnoughAvailableDialog}
                    >
                        {/* eslint-disable-next-line max-len */}
                        <Translate msg="interventions.flu_orders_new.steps.nr_of_vaccines.not_enough_available_vaccines.confirm" />
                    </Button>
                </Dialog>
            </>
        );
    }

    public componentDidUpdate() {
        if (!this.isEnoughAvailableFluVaccines(1)) {
            this.setState({
                isEnoughAvailable: false,
            });
        }
    }

    private onChangeAmountHandler(e) {
        const newAmount = parseInt(e.target.value, 10);

        const isEnoughAvailable = this.isEnoughAvailableFluVaccines(newAmount);
        const totalVaccinesPrice = calculateTotalVaccinesPrice(newAmount, this.props.tariffs);

        this.setState({
            isEnoughAvailable,
            totalVaccinesPrice,
        });

        this.props.onChangeInput(
            { amountToOrder: newAmount },
            totalVaccinesPrice,
        );
    }

    private onCloseNotEnoughAvailableDialog() {
        // automatically change the amount to the max
        const newAmount = this.getMaxAvailableFluVaccines();

        if (newAmount === 0) {
            this.props.onClose();
            return;
        }

        const totalVaccinesPrice = calculateTotalVaccinesPrice(newAmount, this.props.tariffs);

        this.props.onChangeInput(
            { amountToOrder: newAmount },
            totalVaccinesPrice,
        );

        this.setState({
            isEnoughAvailable: true,
            totalVaccinesPrice,
        });
    }

    /**
     * Add the initialOrderAmount so that - when updating an order - only the increase (if any)
     * gets verified.
     * In case of create, the initialOrderAmount should be 0.
     */
    private getMaxAvailableFluVaccines() {
        return this.props.amountAvailableFluVaccines + this.props.initialAmountToOrder;
    }

    private isEnoughAvailableFluVaccines(orderAmount: number) {
        if (!isSet(this.props.amountAvailableFluVaccines)) {
            return true;
        }
        if (isNaN(orderAmount)) {
            return true;
        }

        return orderAmount <= this.getMaxAvailableFluVaccines();
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            tariffs: getFluVaccineTariffs(state),
            translator: getTranslatorDeprecated(state),
            amountAvailableFluVaccines: getAmountOfAvailableFluVaccines(state),
        };
    },
    dispatchProps: (dispatch, getState) => ({
        onClose: () => dispatch(getWizardExitLinkActionMemoized(getState())),
    }),
})(AmountInput);
