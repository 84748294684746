import { ICompany, ICompanyCodeWithShowFullFamilyPayload, ICompanyCodePayload } from '../admin/company';
import { IPerson, IEmployee } from '../admin/employee';
import { IPagePart } from '../general/pageParts';
import { IStepperStep } from '../general/stepper';
import { ILocation } from '../general/location';

export interface ICourseInfo {
    id: number;
    name: string;
    description: string;
}

export interface ICMSCourse extends ICourseInfo {
    nodeId: number;
    duration: string;
    price: number;
    pe: number;
    mainTheme: string;
    mainTargetGroup: string;
    learningGoal: ICMSCourseValueObject;
    pageName?: string;
    teachingType?: number;
    spotlight?: boolean;
    media?: string;
    businessSectors: ICMSCourseValueObject[];
    targetGroups: ICMSCourseValueObject[];
    startDate: string;
    hostName: string;
    languages: string[];
    financingMethods: ICMSCourseValueObject[];
    provinces: string[];
    learningMethods: ICMSCourseValueObject[];
    new: boolean;
    subTitle?: string;
    courseCertificates: ICMSCourseValueObject[];
    formUrl?: string;
    ctaText?: string;
    ctaStickyText?: string;
}

export interface ICMSCourseValueObject {
    name: string;
    colour?: string;
    description?: string;
}

export interface IFetchCMSCourseDetailPayload {
    nodeId: number;
}

export interface IFetchCMSCourseTablePayload {
    childNodeId: number;
}

export interface IFetchCMSCourseDetailByHawIdPayload {
    id: number;
}

export interface ICMSCourseDetail {
    course: ICMSCourse;
    pageParts: IPagePart[];
    children: ICMSCourseDetail[];
    related: ICMSCourseDetail[];
}

export interface ICoursePriceCustomPagePartData {
    course: ICMSCourse;
}

export interface ICourseFicheCustomPagePartData {
    ficheUrl: string;
}

export interface ICMSCourseAddress {
    id?: number;
    postcode: string;
    city: string;
    street: string;
    number: string;
    name?: string;
    website: string;
    phone: string;
    languageCode: string;
}

export interface ICMSCourseLocation {
    startHour: string; // e.g. '09:00'
    stopHour: string; // e.g. '17:00'
    address: ICMSCourseAddress;
    languageCode: string;
    sessions: ICMSCourseLocationSession[];
}

export interface ICMSCourseLocationSession {
    sessionNumber: number;
    dayNumber: number;
    start: string; // e.g. '2019-02-18'
    stop: string; // e.g. '2019-02-18'
    maxAmount: number;
    attendees: number;
    vacant: boolean;
    abnormalTime: boolean;
    referenceNumber: string;
    organizedCourseReference: string;
    price: number;
    amountPE: number;
    PETariff: number;
    coursesOrganizedId: number;
}

export interface ICourseCategory {
    category: string;
    courses: ICMSCourse[];
}

export interface ICourse {
    id: number;
    address?: ICMSCourseAddress;
    maxAmountAttendees: number;
    countAmountAttendees: number;
    amountDays: number;
    coursesOrganizedId: number;
    description: string;
    courseName: string;
    courseType: string; // nl: Open/Gesloten - fr: Ouvert/Fermé
    coordinator: string;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    startDate: string;
    endDate: string;
}

export interface IFetchCoursesPayload extends ICompanyCodeWithShowFullFamilyPayload { }

export interface ICoursesFilter {
    startDate: string;
    endDate: string;
}

export interface IFetchCourseDetailPayload extends ICompanyCodeWithShowFullFamilyPayload {
    coursesOrganizedId: number;
}

export interface IPlannedCourseAppointment {
    id: number;
    start: string;
    end: string;
    organizedCourse: IBffOrganizedCourse;
}

export interface IBffOrganizedCourse {
    id: number
    coordinator: {
        firstName: string;
        lastName: string;
    }
    course: {
        id: number;
        description: string
    }
    location: ILocation;
}
export interface ICourseSessionByLocation {
    coursesOrganizedId: number;
    start: string;
    stop: string;
    price: number;
    amountPE: number;
    PETariff: number;
    maxAmount: number;
    attendees: number;
}

export interface ICourseSession {
    coursesOrganizedId: number;
    sessionNumber: number;
    dayNumber: number;
    start: string;
    stop: string;
    maxAmount: number;
    attendees: number;
    vacant: boolean;
    abnormalTime: boolean;
    address: ICMSCourseAddress;
    language: string;
    languageCode: string;
    referenceNumber: string;
    organizedCourseReference: string;
    price: number;
    amountPE: number;
    PETariff: number;
    courseId: number;
    province: string;
}

export interface IFetchCourseSessionPayload {
    // coursesOrganizedId
    id: number;
}

export enum CourseSessionDayStatus {
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
    BUSY = 'BUSY',
    EVALUATION = 'EVALUATION'
}

export interface ICourseSessionDay {
    id: number;
    dayNumber: number;
    coursesOrganizedId: number;
    courseName: string;
    courseType: string;
    reference: string;
    coordinator: string;
    startDate: string;
    endDate: string;
    docent?: string;
    status?: CourseSessionDayStatus;
}

export interface IFetchCourseSessionDaysPayload {
    coursesOrganizedId: number;
}

export interface ICourseSessionAttendee extends IPerson {
    id: number;
    employeeId: number;
    email: string;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    external: boolean;
}

export interface IFetchCourseSessionAttendeesPayload extends ICompanyCodeWithShowFullFamilyPayload {
    coursesOrganizedId: number;
}

export interface IFetchCourseSessionCertificatesPayload extends ICompanyCodePayload {
    coursesOrganizedId: number;
}

export interface IRemoveCourseAttendeePayload extends ICompanyCodeWithShowFullFamilyPayload {
    coursesOrganizedId: number;
    employeeId: number;
    courseId: number; // only needed for event logging (GA)
}

export interface ICourseSessionAttendant extends IPerson {
    employeeId: number;
    email: string;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    attendedDays: Pick<ICourseSessionDay, 'dayNumber' | 'coordinator' | 'docent' | 'status' | 'startDate' >[];
    noShowDays: Pick<ICourseSessionDay, 'dayNumber' | 'coordinator' | 'docent' | 'status'>[];
}

export interface IFetchCourseSessionAttendancePayload extends ICompanyCodeWithShowFullFamilyPayload {
    coursesOrganizedId: number;
}

export interface ISkipToCourseEnrollmentWizardStepPayload {
    entity?: ICourseEnrollWizardEntity;
    wizardPayload: Pick<ICourseEnrollWizardPayload, 'step' | 'course'>;
}

export enum COURSE_ENROLL_WIZARD_STEP_ID {
    COURSE = 'course',
    EMPLOYEE = 'employee',
}

export interface ICourseEnrollWizardPayload {
    step: COURSE_ENROLL_WIZARD_STEP_ID;
    resetDataEntity: boolean;
    course?: ICourseInfo;
    skipToStep?: boolean;
}

export interface ITriggerCourseEnrollWizardPayload {
    course: ICourseInfo;
}

export interface ICourseEnrollWizardData {
    stepId: string;
    entity: Partial<ICourseEnrollWizardEntity>;
    course: ICourseInfo;
}

export interface ICourseEnrollWizardEntity {
    location: {
        selectedFromOverview?: boolean;
        selectedClosedCourseFromOverview?: boolean;
    } & ICMSCourseAddress;
    session: {
        selectedFromOverview?: boolean;
    } & ICourseSessionByLocation;
    employees: IEmployee[];
    externalEmployees: ICourseExternalEmployee[];
}

export interface ICourseExternalEmployee extends IPerson {
    birthDate: string;
    phone: string;
    email: string;
    function: {
        description: string;
    };
    languageId: number;
}

export interface IFetchCourseExternalEmployeePayload {
    employeeId: number;
}

export interface IUpdateCourseExternalEmployeePayload extends IFetchCourseExternalEmployeePayload {
    employeeId: number;
    fieldsToUpdate: Pick<ICourseExternalEmployee,
        'birthDate' | 'email' | 'firstName' | 'function' | 'languageId' | 'name' | 'phone'>;
}

export interface IFetchCoursePossibleLocationsPayload {
    id: number;
}

export interface IFetchCourseSessionsByLocationPayload {
    id: number; // courseid
    locationId: number;
}

export interface ICourseEnrollWizardStep extends IStepperStep {
    hide?: (entity?: ICourseEnrollWizardEntity) => boolean;
}

export interface ICertificate {
    code: string;
    description: string;
    duration: string;
}

export interface ICertificateEmployee {
    company: Pick<ICompany, 'companyCode' | 'name'>;
    description: string;
    duration: string;
    dateLast: string;
    validTill: string;
    valid: boolean;
    employee: Pick<IEmployee, 'id' | 'name' | 'firstName'>;
}

export interface IFetchCertificateDetailPayload {
    code: string;
}

// Employee courses
export interface IFetchEmployeeCoursesPayload {
    id: number;
    showFullFamily: boolean;
}

export interface IEmployeeCourse {
    courseEmployeeId: string;
    succeeded: boolean;
    course: Pick<ICourse, 'id' | 'coursesOrganizedId'
        | 'courseName' | 'courseType' | 'coordinator'
        | 'endDate'>;
}

export interface ICourseSessions {
    [sessions: string]: ICourseSession[];
}

export interface ICourseSessionsByMonth {
    [month: string]: ICourseSessions;
}
