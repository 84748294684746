import { ErrorTypes } from '../../../../models/general/error';

export function mapFieldErrorToErrorType(fieldError): ErrorTypes {
    if (fieldError.type === ErrorTypes.Min && Array.isArray(fieldError.value)) {
        return ErrorTypes.SelectAtLeastOne;
    }
    if (fieldError.type === ErrorTypes.Min && fieldError.value instanceof Date) {
        return ErrorTypes.MinDate;
    }
    return fieldError.type;
}

export function mapMessageToErrorType(message: string) {
    if (message.indexOf('email') === 0) {
        return ErrorTypes.InvalidEmail;
    }
    const errorTypeKey = Object.keys(ErrorTypes).find((key) => {
        const value = ErrorTypes[key];
        return value === message;
    });
    return errorTypeKey ? ErrorTypes[errorTypeKey] : undefined;
}
