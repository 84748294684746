import { formatDateForDisplay, formatDateInLongFormatWithDividers } from '../../../../utils/formatting/formatDate';
import { IMappedAdministeredVaccination } from '../../../../models/interventions/vaccines';
import { ListItem } from '../../../../models/general/list';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IClientSideFilterOfListDataProps,
    IShouldRenderShowAllButtonProps,
} from '../../../common/widget/MasterWithDetail/typings';

import {
    IFilterValues,
    TAdministeredVaccinationsListActiveFiltersProps,
} from './AdministeredVaccinationsFilters/AdministeredVaccinationsFilters.type';
import {
    IAdministeredVaccinationsListColumnNames,
    TAdministeredVaccinationListItem,
} from './AdministeredVaccinationsList/AdministeredVaccinationList.types';
import { DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS, DEFAULT_NR_OF_VACCINES_TO_SHOW } from './administered.const';

export function mapAdministeredVaccinationsToListItems(
    masterData: IMappedAdministeredVaccination[],
): TAdministeredVaccinationListItem[] {
    return masterData
        .map((vaccination) => ({
            id: toListId(vaccination),
            columns: {
                name: vaccination.name,
                employer: vaccination.employer,
                dateOfAdministration: formatDateInLongFormatWithDividers(vaccination.dateOfAdministration),
                dateOfAdministrationSort: vaccination.dateOfAdministration,
                description: vaccination.description,
                injectionDescription: vaccination.injectionDescription,
            },
        }));
}

export const toListId = (vaccination: IMappedAdministeredVaccination): string => {
    return vaccination.name
        + vaccination.dateOfAdministration
        + vaccination.injectionDescription
        + vaccination.description
        + vaccination.employer;
};

export const shouldRenderShowAllButton = (
    shouldRenderProps: IShouldRenderShowAllButtonProps<
        ListItem<IAdministeredVaccinationsListColumnNames>[], IFilterValues
    >,
) => {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_VACCINES_TO_SHOW;
};

export function getDefaultQueryParams({ isShowAll = true }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS;
}

export const mapListRowForExport = (administeredVaccination: IMappedAdministeredVaccination) => {
    const {
        companyCode,
        dateOfAdministration,
        description,
        employer,
        firstName,
        injectionDescription,
        lastName,
    } = administeredVaccination;

    return {
        companyCode,
        employer,
        firstName,
        lastName,
        dateOfAdministration,
        description,
        injectionDescription,
    };
};

export const transformFilterValuesToActiveFilters = (
    transformProps: TAdministeredVaccinationsListActiveFiltersProps,
) => {
    return createGenericActiveFilters<IFilterValues, IAdministeredVaccinationsListColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.vaccinations.administered.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS.startDate,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS.endDate,
            },
            employer: {
                show: true,
                translationKeySuffixOverride: 'employer',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'employer',
                        searchColumnName: 'employer',
                    },
                },
            },
            type: {
                show: true,
                translationKeySuffixOverride: 'type',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'description',
                        searchColumnName: 'description',
                    },
                },
            },
        },
    });
};

export function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IAdministeredVaccinationsListColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.employer)) {
                return true;
            }

            const employers = separateStringList(filterValues.employer);

            return employers.includes(listItem.columns.employer as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.type)) {
                return true;
            }

            const descriptions = separateStringList(filterValues.type);

            return descriptions.includes(listItem.columns.description as string);
        });
}
