import React, { ReactNode } from 'react';
import connect from '../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../common/input/Typeahead';
import { ITranslator } from '../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { getCompanyContacts } from '../../../../redux/company/info/selectors';
import { ICompanyContact } from '../../../../models/admin/companyInfo';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';

interface IPrivateProps {
    contacts: ICompanyContact[];
    contactsTypeahead: TTypeaheadData;
    translator: ITranslator;
}

interface IContactTypeaheadProps {
    id: string;
    value: number; // customerContactId
    name: string;
    onItemSelected: (contactId: number, contact: ICompanyContact) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholderTranslationKey?: string;
    disabled?: boolean;
    showContactTypeInDropdownContent?: boolean;
}

function ContactTypeahead(props: IContactTypeaheadProps & IPrivateProps) {
    const {
        children, id, value,
        name, isInvalid, onItemSelected,
        contacts, contactsTypeahead,
        placeholderTranslationKey, disabled, translator,
    } = props;

    function onItemSelectedHandler(customerContactId: number) {
        const selectedContact = contacts.find((item) => item.customerContactId === customerContactId);

        onItemSelected(customerContactId, selectedContact || null);
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={contactsTypeahead}
            placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
            disabled={disabled}
        >
            {children}
        </Typeahead>
    );
}

export default connect<IPrivateProps, IContactTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const contacts = getCompanyContacts(state);
            return {
                contacts,
                contactsTypeahead: mapContactsForTypeahead(contacts, publicProps.showContactTypeInDropdownContent),
                translator: getTranslatorDeprecated(state),
            };
        };
    },
})(ContactTypeahead);

function mapContactsForTypeahead(
    contacts: ICompanyContact[],
    showContactTypeInDropdownContent: boolean = false,
): TTypeaheadData {
    return Array.isArray(contacts) ? contacts.map((item) => ({
        value: item.customerContactId,
        label: formatPersonName(item),
        dropdownItemContent: getDropdownItemContent(item, showContactTypeInDropdownContent),
    })) : [];
}

function getDropdownItemContent(contact: ICompanyContact, showContactTypeInDropdownContent: boolean) {
    return showContactTypeInDropdownContent ?
        (
            <>
                <strong>{formatPersonName(contact)}</strong><br />
                {contact.type}
            </>
        ) : null;
}
