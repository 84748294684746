import React, { Component } from 'react';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import {
    IJobStudentQuestionnaire,
    IQuestionnaireAdvice,
    ISendJobStudentReminderEmailPayload,
} from '../../../../models/admin/questionnaires';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { schema, fields } from './jobStudentsSchema';
import {
    ConfirmableTextPropertyInput,
    ConfirmableConstantsTypeaheadPropertyInput,
} from '../../../common/input/ConfirmablePropertyInput';
import {
    getUpdateEmployeeAsyncInfo,
    getSelectedEmployee,
    getSelectedEmployeeAsyncInfo,
} from '../../../../redux/employee/info/selectors';
import { TextPropertyInput } from '../../../common/input/PropertyInput';
import { connect } from '../../..';
import { getRoutePayload } from '../../../../redux/location/selectors';
import Translate from '../../../common/Translate';
import { formatDateWithTimeForDisplay } from '../../../../utils/formatting/formatDate';
import { TEmployeeUpdateFields, IEmployeeDetails } from '../../../../models/admin/employee';
import { updateEmployee } from '../../../../redux/employee/info/actions';
import { getUpdateEmployeeRequestId } from '../../Employees/EmployeeDetails';
import { ConstantType } from '../../../../models/general/constants';
import { hasRequiredAccessLevels } from '../../../../redux/auth/selectors';
import DetailItem from '../../../common/widget/DetailItem';
import { QuestionnaireLabelDateItem } from '../shared';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import {
    getQuestionnairesAdvice,
    getQuestionnairesAdviceAsyncInfo,
    getQuestionnairesJobStudents,
    getSendJobStudentReminderEmailAsyncInfo,
    getTriggerPlanJobStudentMedExamAsyncInfo,
} from '../../../../redux/questionnaires/selectors';
import TinyLoader from '../../../common/waiting/TinyLoader';
import {
    sendJobStudentReminderEmailActions,
    triggerPlanJobStudentMedExamActions,
} from '../../../../redux/questionnaires/actions';
import { TRANSLATION_PREFIX_DETAIL } from './common';
import SendReminderDialog from './SendReminderDialog';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';

const CLASS_NAME = 'QuestionnairesJobStudentsDetail';
const FORM_NAME = 'questionnaires-job-students-detail-form';

export interface FormValues {
    email: string;
    languageId: number;
    function: string;
    seat: string;
}

interface IPrivateProps {
    mayEdit: boolean;
    selectedDetailId: number;
    advice: IQuestionnaireAdvice[];
    updateEmployeeField: (
        nationalRegisterNumber: string,
        companyCode: string,
        fieldName: keyof TEmployeeUpdateFields,
        value: string | number,
    ) => void;
    sendReminderEmail: (id: number) => void;
    sendReminderEmailsAsyncInfo: IAsyncFieldInfo;
    triggerPlanJobStudentMedExam: (id: number) => void;
    employeeDetails: IEmployeeDetails;
}

interface IState {
    isSendReminderDialogOpen: boolean;
}

class DetailComp extends Component<IPrivateProps & IRenderDetailContentProps<IJobStudentQuestionnaire>, IState> {
    private resetForm: (values: FormValues) => void;

    constructor(props: IPrivateProps & IRenderDetailContentProps<IJobStudentQuestionnaire>) {
        super(props);

        this.state = {
            isSendReminderDialogOpen: false,
        };

        this.onSendReminderEmail = this.onSendReminderEmail.bind(this);
        this.onCloseSendReminderDialog = this.onCloseSendReminderDialog.bind(this);
        this.onTriggerPlanMedExam = this.onTriggerPlanMedExam.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IRenderDetailContentProps<IJobStudentQuestionnaire>) {
        const previousIdOfEmployee = prevProps.employeeDetails && prevProps.employeeDetails.id;
        const currentIdOfEmployee = this.props.employeeDetails && this.props.employeeDetails.id;
        if (previousIdOfEmployee !== currentIdOfEmployee) {
            this.resetForm(this.createInitialValuesFromEmployeeDetails());
        }
    }

    public render() {
        const {
            detailData,
            updateEmployeeField,
            mayEdit,
            advice,
            sendReminderEmailsAsyncInfo,
            employeeDetails,
        } = this.props;

        if (!detailData) {
            return null;
        }

        const { employee } = detailData;

        const INITIAL_VALUES: FormValues = this.createInitialValuesFromEmployeeDetails();

        return (
            <div className={CLASS_NAME}>
                <DetailItem>
                    <h2>{formatPersonNameFormal(detailData.employee)}</h2>
                    <Form
                        name={FORM_NAME}
                        schema={schema}
                        initialValues={INITIAL_VALUES}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values, errors, handleChange, resetForm, setFieldValue } = formRenderProps;

                            this.resetForm = resetForm;

                            function onLanguageItemSelected(value: number) {
                                setFieldValue('languageId', value);
                            }

                            return (
                                <div>
                                    <TinyLoader asyncInfoSelector={getSelectedEmployeeAsyncInfo} >
                                        {employeeDetails && (
                                            <>
                                            <ConfirmableTextPropertyInput
                                                id="email"
                                                name={fields.email}
                                                labelKey={`${TRANSLATION_PREFIX_DETAIL}.form.placeholder.email`}
                                                readonly={!mayEdit}
                                                value={values.email}
                                                onChange={handleChange}
                                                initialValue={INITIAL_VALUES.email}
                                                asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                                                requestId={getUpdateEmployeeRequestId(
                                                    employee.nationalRegisterNumber,
                                                    fields.email,
                                                )}
                                                onSave={() => updateEmployeeField(
                                                    employee.nationalRegisterNumber,
                                                    employee.company.companyCode,
                                                    fields.email,
                                                    values.email,
                                                )}
                                                validationError={{
                                                    error: errors.email,
                                                    // eslint-disable-next-line max-len
                                                    fieldNameTranslationKey: `${TRANSLATION_PREFIX_DETAIL}.form.placeholder.email`,
                                                }}
                                            />
                                            <ConfirmableConstantsTypeaheadPropertyInput
                                                id="languageId"
                                                name={fields.languageId}
                                                labelKey={`${TRANSLATION_PREFIX_DETAIL}.form.placeholder.language`}
                                                readonly={!mayEdit}
                                                value={values.languageId}
                                                initialValue={INITIAL_VALUES.languageId}
                                                asyncInfoSelector={getUpdateEmployeeAsyncInfo}
                                                constantType={ConstantType.LANGUAGES}
                                                onItemSelected={onLanguageItemSelected}
                                                requestId={getUpdateEmployeeRequestId(
                                                    employee.nationalRegisterNumber,
                                                    fields.languageId,
                                                )}
                                                onSave={() => updateEmployeeField(
                                                    employee.nationalRegisterNumber,
                                                    employee.company.companyCode,
                                                    fields.languageId,
                                                    values.languageId,
                                                )}
                                                validationError={{
                                                    error: errors.languageId,
                                                    // eslint-disable-next-line max-len
                                                    fieldNameTranslationKey: `${TRANSLATION_PREFIX_DETAIL}.form.placeholder.language`,
                                                }}
                                            />
                                            <TextPropertyInput
                                                id="function"
                                                name={fields.function}
                                                labelKey={`${TRANSLATION_PREFIX_DETAIL}.form.placeholder.function`}
                                                readonly={true}
                                                value={values.function}
                                            />
                                            <TextPropertyInput
                                                id="seat"
                                                name={fields.seat}
                                                labelKey={`${TRANSLATION_PREFIX_DETAIL}.form.placeholder.seat`}
                                                readonly={true}
                                                value={values.seat}
                                            />
                                            </>
                                        )}
                                    </TinyLoader>
                                </div>
                            );
                        }}
                    />
                </DetailItem>
                <DetailItem>
                    {detailData.completed ?
                        <h6 className={`${CLASS_NAME}__title-with-icon`}>
                            <Icon typeName="check" />
                            <Translate msg={`${TRANSLATION_PREFIX_DETAIL}.questionnaire.title_completed`} />
                        </h6> :
                        <h6>
                            <Translate msg={`${TRANSLATION_PREFIX_DETAIL}.questionnaire.title_not_completed`} />
                        </h6>
                    }
                    <QuestionnaireLabelDateItem
                        labelKey={`${TRANSLATION_PREFIX_DETAIL}.questionnaire.send_date`}
                        date={formatDateWithTimeForDisplay(detailData.dateCreated)}
                    />
                    <QuestionnaireLabelDateItem
                        labelKey={`${TRANSLATION_PREFIX_DETAIL}.questionnaire.reminder_date`}
                        date={formatDateWithTimeForDisplay(detailData.dateLastSendPass)}
                    />
                    {detailData.completed && detailData.dateModified ?
                        <QuestionnaireLabelDateItem
                            labelKey={`${TRANSLATION_PREFIX_DETAIL}.questionnaire.completion_date`}
                            date={formatDateWithTimeForDisplay(detailData.dateModified)}
                        /> : null
                    }
                </DetailItem>
                {detailData.completed ?
                    detailData.status === 5 ?
                        <DetailItem>
                            <h6 className={`${CLASS_NAME}__title-with-icon`}>
                                <Icon typeName="conversation" />
                                <Translate msg={`${TRANSLATION_PREFIX_DETAIL}.result.title_advice`} />
                            </h6>
                            <TinyLoader asyncInfoSelector={getQuestionnairesAdviceAsyncInfo} >
                                {advice.map((item, index) => (
                                    <div key={index}>{item.information}</div>
                                ))}
                            </TinyLoader>
                        </DetailItem>
                        :
                        <DetailItem>
                            <h6><Translate msg={`${TRANSLATION_PREFIX_DETAIL}.result.title`} /></h6>
                            {detailData.statusDescription}
                            {/* TODO: display status data, depending on status */}
                        </DetailItem>
                    :
                    null
                }
                < div className={`${CLASS_NAME}__action`}>
                    {!detailData.completed && detailData.employee.id && detailData.employee.id !== 0 && (
                        <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                            <Button id="questionnaire-reminder" typeName="secondary" onClick={this.onSendReminderEmail}>
                                <Translate msg={`${TRANSLATION_PREFIX_DETAIL}.action.send_reminder`} />
                            </Button>
                        </ShowIfAllowed>
                    )}
                    {detailData.status === 1 && (
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            <TinyLoader
                                showContentOnInitialState={true}
                                asyncInfoSelector={getTriggerPlanJobStudentMedExamAsyncInfo}
                            >
                                <Button
                                    id="questionnaire-replan"
                                    typeName="secondary"
                                    onClick={this.onTriggerPlanMedExam}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX_DETAIL}.action.replan_examination`} />
                                </Button>
                            </TinyLoader>
                        </ShowIfAllowed>
                    )}
                </div>
                <SendReminderDialog
                    show={this.state.isSendReminderDialogOpen}
                    onClose={this.onCloseSendReminderDialog}
                    asyncInfo={sendReminderEmailsAsyncInfo}
                />
            </div>
        );
    }

    private onCloseSendReminderDialog() {
        this.setState({ isSendReminderDialogOpen: false });
    }

    private onSendReminderEmail() {
        const { sendReminderEmail, selectedDetailId } = this.props;
        sendReminderEmail(selectedDetailId);
        this.setState({ isSendReminderDialogOpen: true });
    }

    private onTriggerPlanMedExam() {
        const { triggerPlanJobStudentMedExam, selectedDetailId } = this.props;
        triggerPlanJobStudentMedExam(selectedDetailId);
    }

    private createInitialValuesFromEmployeeDetails(): FormValues {
        const { employeeDetails } = this.props;

        return {
            email: employeeDetails && employeeDetails.email,
            function: employeeDetails && employeeDetails.function && employeeDetails.function.description,
            languageId: employeeDetails && employeeDetails.languageId,
            seat: employeeDetails && employeeDetails.company.name,
        };
    }
}

export const Detail = connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            mayEdit: hasRequiredAccessLevels(state, { employee: 'W' }),
            selectedDetailId: getRoutePayload<{ id: number }>(state).id,
            advice: getQuestionnairesAdvice(state),
            sendReminderEmailsAsyncInfo: getSendJobStudentReminderEmailAsyncInfo(state),
            employeeDetails: getSelectedEmployee(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateEmployeeField: (
                nationalRegisterNumber: string,
                companyCode: string,
                fieldName: keyof TEmployeeUpdateFields,
                value: string | number,
            ) => {
                dispatch(updateEmployee({
                    requestId: getUpdateEmployeeRequestId(nationalRegisterNumber, fieldName),
                    nationalRegisterNumber,
                    employeeData: {
                        [fieldName]: value,
                    },
                    companyCode,
                }));
            },
            sendReminderEmail: (id: number) => {
                const jobStudent =
                    getQuestionnairesJobStudents(getState())
                        .find((jobStudent) => jobStudent.id === id);

                const payload: ISendJobStudentReminderEmailPayload = {
                    employee: {
                        id: jobStudent.employee.id,
                    },
                    interimEmployeeId: jobStudent.interimEmployeeId,
                };

                dispatch(sendJobStudentReminderEmailActions.trigger([
                    payload,
                ]));
            },
            triggerPlanJobStudentMedExam: (id: number) => {
                const jobStudent =
                    getQuestionnairesJobStudents(getState())
                        .find((jobStudent) => jobStudent.id === id);

                dispatch(triggerPlanJobStudentMedExamActions.trigger({
                    companyCode: jobStudent.employee.company.companyCode,
                    nationalRegisterNumber: jobStudent.employee.nationalRegisterNumber,
                }));
            },
        };
    },
})(DetailComp);
