import MainHeaderAndFooterTemplate from './MainHeaderAndFooterTemplate';
import NoHeaderOrFooterTemplate from './NoHeaderOrFooterTemplate';
import PageNotFoundTemplate from './PageNotFoundTemplate';
import WithMenuTemplate from './WithMenuTemplate';
import WizardTemplate from './WizardTemplate';

export default {
    MainHeaderAndFooterTemplate,
    NoHeaderOrFooterTemplate,
    PageNotFoundTemplate,
    WithMenuTemplate,
    WizardTemplate,
};
