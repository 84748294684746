export enum Permission {
    CAN_ACCESS_ALL_COMPANIES = 'CAN_ACCESS_ALL_COMPANIES',
    CAN_ADD_USER = 'CAN_ADD_USER',
    CAN_SELECT_ALL_SEATS = 'CAN_SELECT_ALL_SEATS',
    CAN_CREATE_TEMP_FUNC = 'CAN_CREATE_TEMP_FUNC',
}

export const PermissionsAllowedIfDismissedCompany = [
    Permission.CAN_ACCESS_ALL_COMPANIES,
    Permission.CAN_ADD_USER,
    Permission.CAN_SELECT_ALL_SEATS,
];

/**
 * R => Read
 * W => Write
 * nop => No permission
 */
export type AccessLevel = 'R' | 'W' | 'nop';

export interface IAccessLevel {
    company: AccessLevel;
    employee: AccessLevel;
    interventions: AccessLevel;
    invoicing: AccessLevel;
    planning: AccessLevel;
    extraPermissions: Permission[];
}
