import { formatPersonName } from './formatPerson';
import { joinParts } from './formatHelper';
import { ICompanyContact } from '../../models/admin/companyInfo';

export function formatCompanyContact(contact: ICompanyContact, onOneLine: boolean = false): string {
    if (!contact) {
        return '-';
    }

    return joinParts(
        [
            formatPersonName(contact),
            contact.phone,
            contact.mobilePhone,
            contact.email,
        ],
        onOneLine ? ', ' : '\n',
    );
}
