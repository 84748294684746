import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    IReintegrationManualEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import WizardStep from '../../../../../common/navigation/WizardStep';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import { formatDateForBackend } from '../../../../../../utils/formatting/formatDate';
import { now } from '../../../../../../utils/core/date/getSpecificDate';

const CLASS_NAME = 'PlanMedicalExamination__ReIntegrationStartDate';

export interface IContentValues {
    startDate: string;
}

const ReIntegrationStartDateWizardStep = WizardStep<IContentValues>();

export default function ReIntegrationStartDate(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <ReIntegrationStartDateWizardStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.medical_examinations.new.steps.re_integration_start_date.title',
            }}
            content={{
                initialValuesSelector: (state) => {
                    const wizardEntity =
                        getPlanMedicalExaminationWizardEntity<IReintegrationManualEntity>(state);

                    return {
                        startDate: wizardEntity.startDate || '',
                    };
                },
                renderStepContent: (renderProps) =>
                    <DatePicker
                        id="re-integration-start-date"
                        onChange={(formattedDate: string) => {
                            renderProps.onChangeInput({
                                startDate: formattedDate,
                            });
                        }}
                        maxDate={formatDateForBackend(now())}
                        value={renderProps.values.startDate}
                        inlineCalendar={true}
                        hideTextInput={true}
                    />,
                onChangeInputActionCreator: ({ values }) =>
                    updatePlanMedicalExaminationWizardEntity<IReintegrationManualEntity>({
                        startDate: values.startDate,
                    }),
            }}
            stepButtons={{
                shouldDisableNextButton: (values) => !isDateSelected(values),
            }}
        />
    );
}

function isDateSelected(values: IContentValues) {
    return isSet(values.startDate);
}
