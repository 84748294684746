import React from 'react';
import classNames from 'classnames';

import { ITextNotificationProps } from './TextNotification.type';

import './TextNotification.scss';

const TextNotification = ({
    text,
    animate = false,
    className: classNameFromProps,
}: ITextNotificationProps) => {
    const content = text.trim();

    const className = classNames('Notification', classNameFromProps, {
        'Notification--small': content.length >= 3,
        animate,
    });

    if (!content) {
        return null;
    }

    return (
        <span className={className}>{content}</span>
    );
};

export { TextNotification };
