import { NOT_FOUND, actionToPath } from 'redux-first-router';
import queryString from 'query-string';
import isString from '@snipsonian/core/es/is/isString';
import isFunction from '@snipsonian/core/es/is/isFunction';
import { IState, getReducerState, NO_RERENDER } from '../index';
import { IReducerState, reducerKey } from './reducer';
import { getRoutes, getRoute, doesRouteBelongToGroup } from '../../routes';
import ROUTE_KEYS from '../../routeKeys';
import { IBreadcrumbsPath, IRoutes, IRoute } from '../../utils/routing/typings';
import { isApplicationInitializationFailed } from '../ui/form/selectors';
import {
    getBreadcrumbForInputRoute,
    getClosestParentRouteKeyOfInputRoute,
} from '../../utils/routing/routePathUtils';
import getPayloadParamsFromPath from '../../utils/routing/getPayloadParamsFromPath';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { isSelectedCompanyDismissed } from '../company/selected/selectors';
import { PAGE_KEY } from '../../config/route.config';

export const getLocationState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export const getLocationStateAsUrl = (state: IState) => {
    const locationState = getLocationState(state);
    return actionToPath(
        {
            type: locationState.type,
            payload: locationState.payload,
            query: locationState.query,
        },
        getRoutes(),
    );
};

export const getRouteKey = (state: IState) => getLocationState(state).type;

export function getParentRouteKey(state: IState, {
    routeKey,
    routes = getRoutes(),
}: {
    routeKey?: ROUTE_KEYS;
    routes?: IRoutes;
} = {}) {
    const inputRouteKey = routeKey || getRouteKey(state);
    const inputRoute = routes[inputRouteKey];

    if (isFunction(inputRoute.overrideParentRouteKey)) {
        const potentialParentKeyOverride = inputRoute.overrideParentRouteKey(state);
        if (potentialParentKeyOverride) {
            return potentialParentKeyOverride;
        }
    }

    return getClosestParentRouteKeyOfInputRoute({ inputRouteKey, routes });
}

export function getTopMenuParentRouteKey(state: IState) {
    const breadcrumbsPath = getBreadcrumbsPath(state);

    const parentDashboardBreadcrumb = breadcrumbsPath
        .find((breadcrumb) =>
            doesRouteBelongToGroup({ routeKey: breadcrumb.type, group: ROUTE_GROUP.IS_DASHBOARD }),
        );
    if (parentDashboardBreadcrumb) {
        return parentDashboardBreadcrumb.type;
    }

    return breadcrumbsPath[0].type;
}

export function getBreadcrumbsPath(state: IState, routes: IRoutes = getRoutes()): IBreadcrumbsPath {
    const currentRouteKey = getRouteKey(state);
    const routePayload = getRoutePayload(state);

    const breadcrumbsPath: IBreadcrumbsPath = [
        getBreadcrumbForInputRoute({
            inputRouteKey: currentRouteKey,
            routePayload,
            isCurrentRoute: true,
            routes,
        }),
    ];

    return addBreadcrumbsOfParentRoute({
        state,
        breadcrumbsPath,
        childRouteKey: currentRouteKey,
        routePayload,
        routes,
    });
}

function addBreadcrumbsOfParentRoute({
    state,
    breadcrumbsPath,
    childRouteKey,
    routePayload = {},
    routes,
}: {
    state: IState;
    breadcrumbsPath: IBreadcrumbsPath;
    childRouteKey: ROUTE_KEYS;
    routePayload?: object;
    routes: IRoutes;
}): IBreadcrumbsPath {
    const parentRouteKey = getParentRouteKey(state, { routeKey: childRouteKey, routes });
    if (parentRouteKey) {
        const parentRoute = routes[parentRouteKey];

        const parentRoutePayload = getPayloadParamsFromPath(parentRoute.path)
            .reduce(
                (accumulator, payloadPropName) => {
                    accumulator[payloadPropName] = routePayload[payloadPropName] || undefined;
                    return accumulator;
                },
                {},
            );

        breadcrumbsPath.unshift(
            getBreadcrumbForInputRoute({
                inputRouteKey: parentRouteKey,
                routePayload: Object.keys(parentRoutePayload).length > 0
                    ? parentRoutePayload
                    : undefined,
                isCurrentRoute: false,
                routes,
            }),
        );

        addBreadcrumbsOfParentRoute({
            state,
            breadcrumbsPath,
            childRouteKey: parentRouteKey,
            routePayload,
            routes,
        });
    }

    return breadcrumbsPath;
}

export const getRouteInfo = (state: IState) => {
    const routeKey = isApplicationInitializationFailed(state)
        ? ROUTE_KEYS.R_INITIALIZATION_ERROR
        : getRouteKey(state);
    return getRoute({ routeKey });
};

export function getVirtualPageOfCurrentRoute(state: IState): string {
    const route = getRoute({ routeKey: getRouteKey(state) });
    if (!route) {
        const notFoundRoute = getRoute({ routeKey: ROUTE_KEYS[NOT_FOUND] });
        return getVirtualPageForRoute(state, notFoundRoute);
    }
    return getVirtualPageForRoute(state, route);
}

export function getVirtualPageForRoute(state: IState, route: IRoute): string {
    return parseVirtualPage(state, route);
}

export function getQueryParams<T extends object>(state: IState) {
    return (getLocationState(state).query || NO_RERENDER.EMPTY_OBJECT) as T;
}

export const getQueryParamFromSearchString = <Param>(state: IState, param: string): Param | null => {
    const searchString = (getLocationState(state).search || '');
    const params = queryString.parse(searchString);
    if (params[param]) {
        return params[param] as Param;
    }

    return null;
};

export const getQueryParam = <Param>(state: IState, param: string): Param | null => {
    const params = getQueryParams(state) as { [key: string]: Param };
    if (params[param]) {
        return params[param] as Param;
    }

    return null;
};

export const getRoutePayload = <T extends {}>(state: IState) => {
    const payload = getLocationState(state).payload;
    if (!payload || typeof payload !== 'object') {
        return payload as T;
    }
    return Object.keys(payload)
        .reduce(
            (accumulator, current) => {
                const value = payload[current];
                const isInteger = /^[0-9]+$/.test(value);
                if (isInteger) {
                    accumulator[current] = Number(value);
                } else {
                    accumulator[current] = typeof value === 'string' ? decodeURIComponent(value) : value;
                }
                return accumulator;
            },
            {},
        ) as T;
};

export function getPropertyFromRoutePayload(state: IState, property: string) {
    const routePayload = getRoutePayload(state);
    return routePayload[property];
}

export const isChildRouteKeyOf = (routeKey: ROUTE_KEYS, possibleParentRouteKey: string) => {
    const route = getRoute({ routeKey });
    const possibleParentRoute = getRoute({ routeKey: possibleParentRouteKey as ROUTE_KEYS });
    return route.path.indexOf(possibleParentRoute.path) === 0; // startsWith
};

function parseVirtualPage(state: IState, route: IRoute): string {
    if (Array.isArray(route.virtualPage)) {
        return route.virtualPage.map((virtualPagePart) => {
            if (typeof virtualPagePart === 'function') {
                const generatedVirtualPagePart = virtualPagePart(state);
                return generatedVirtualPagePart
                    ? isString(generatedVirtualPagePart)
                        ? generatedVirtualPagePart.toLowerCase()
                        : generatedVirtualPagePart
                    : '-';
            }
            return virtualPagePart;
        }).join('_');
    }
    return route.virtualPage;
}

export function doesCurrentRouteBelongToGroup(state: IState, group: string) {
    const currentRouteKey = getRouteKey(state);
    return doesRouteBelongToGroup({ routeKey: currentRouteKey, group });
}

export function shouldHideTopMenu(state: IState): boolean {
    const currentRoute = getRoute({ routeKey: getRouteKey(state) });

    if (currentRoute && currentRoute.page.key === PAGE_KEY.PAGE_NOT_FOUND) {
        // we always want to offer the possibility to go to the home screen via the menu
        return false;
    }

    return isSelectedCompanyDismissed(state);
}

export function shouldHideFaqFooterLink(state: IState): boolean {
    return isSelectedCompanyDismissed(state);
}
