import React, { PureComponent, ChangeEvent, ReactNode, FocusEvent, KeyboardEvent } from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import '../typeahead.scss';
import { Dropdown, DropdownItem, TDropdownItemValue, IRenderDropdownTargetProps } from '../../Dropdown';
import Translate from '../../../Translate';
import TinyLoader from '../../../waiting/TinyLoader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { IState } from '../../../../../redux';
import debounce, { TDebounced } from '../../../../../utils/core/debounce';
import { connect } from '../../../..';
import filterListItems from '../../../../../utils/list/filterListItems';
import { isKeyPressed } from '../../../../../utils/keyboard';
import TextInput from '../../TextInput';
import Icon from '../../../icons/Icon';
import classNames from 'classnames';
import { ITypeaheadDataItem, TTypeaheadData } from '..';
import { Placement } from 'popper.js';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

const DEFAULT_VALUE_IF_NOT_SELECTED = null;

interface IPrivateProps {
    isFetching: boolean;
    hasFetchError: boolean;
}

export interface IBaseTypeaheadProps {
    id: string;
    name: string;
    value?: TDropdownItemValue;
    valueIfNotSelected?: TDropdownItemValue; // Defaults to null
    valueToSetOnClear?: TDropdownItemValue;
    asyncInfoSelector?: (state?: IState) => IAsyncFieldInfo;
    asyncInfoSelectorDoesNotRequireState?: boolean;
    data: TTypeaheadData;
    onFilter?: (filter: string) => void;
    onItemSelected: (selectedValue: TDropdownItemValue) => void;
    placeholder?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    afterSearchFooter?: ReactNode;
    disableSort?: boolean;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    initialFilter?: string;
    keepFilterOnSelect?: boolean;
    disableReset?: boolean;
    minCharsToTriggerSearch?: number; // Defaults to 0
    autoFilteringDisabled: boolean;
    clearSelectedItemOnTextInput?: boolean; // Defaults to true
    autoSelectOnSingleOption?: boolean;
}

interface IComponentState {
    isOpen: boolean;
    textInput: string;
    filteredData: TTypeaheadData;
    dropdownItemsPlacement: Placement;
    isFilteringTriggered: boolean;
    lastItemSelected: TDropdownItemValue;
}

const CLASS_NAME = 'Typeahead';
const DEFAULT_MIN_CHARS_TO_TRIGGER_SEARCH = 0;

/**
 * Base Typeahead, contains all functionality
 *
 * ATTENTION: NEVER USE THIS COMPONENT DIRECTLY!
 * INSTEAD USE ONE OF THE INSTANCES WHICH IS AN ABSTRACTION
 */
class BaseTypeahead extends PureComponent<IBaseTypeaheadProps & IPrivateProps, IComponentState> {
    private onFilterDebounced: TDebounced<[string]>;
    private dropdownTargetRenderProps: IRenderDropdownTargetProps;
    private focusedItemIndex: number;
    private isUnmounted: boolean = false;
    private updatePlacementTimeout: number = null;

    constructor(props: IBaseTypeaheadProps & IPrivateProps) {
        super(props);

        this.state = {
            isOpen: false,
            textInput: props.initialFilter || '',
            filteredData: props.data,
            dropdownItemsPlacement: null,
            isFilteringTriggered: false,
            lastItemSelected: null,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.renderTargetComponent = this.renderTargetComponent.bind(this);
        this.onTextInputChanged = this.onTextInputChanged.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.updateDropdownPlacement = this.updateDropdownPlacement.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.onDropdownOpen = this.onDropdownOpen.bind(this);

        if (typeof props.onFilter === 'function') {
            this.onFilterDebounced = debounce(
                (textInput: string) => {
                    if (this.isUnmounted) {
                        return;
                    }
                    this.setState({
                        isFilteringTriggered: false,
                    });
                    props.onFilter(textInput);
                },
                300,
            );
        }
    }

    public render() {
        const { isFetching, hasFetchError } = this.getFetchInfo();
        const {
            id, value, valueIfNotSelected = DEFAULT_VALUE_IF_NOT_SELECTED,
            placeholder, asyncInfoSelector, disableReset, children, afterSearchFooter,
            disabled, minCharsToTriggerSearch = DEFAULT_MIN_CHARS_TO_TRIGGER_SEARCH,
            disableSort,
        } = this.props;
        const { isOpen, textInput, filteredData, dropdownItemsPlacement, isFilteringTriggered } = this.state;

        const showInsertText = typeof asyncInfoSelector === 'function'
            && textInput.length < minCharsToTriggerSearch
            && textInput.length === 0;
        const showMinRequiredInputChars = !showInsertText && typeof asyncInfoSelector === 'function'
            && textInput.length < minCharsToTriggerSearch;
        const showNoResults = !isFetching && !showInsertText && !showMinRequiredInputChars && !hasFetchError
            && filteredData.length === 0;
        const showDropdownItems = !isFetching && !hasFetchError && !showInsertText && !showMinRequiredInputChars;
        const showTinyLoader = isFilteringTriggered
            || (textInput.length >= minCharsToTriggerSearch && (isFetching || hasFetchError));

        const sortedData = !disableSort ? filteredData.sort(sortByName) : filteredData;

        const classes = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--open`]: isOpen,
            [`${CLASS_NAME}--placement-top`]: dropdownItemsPlacement && dropdownItemsPlacement.indexOf('top') === 0,
            disabled,
            dirty:
                (value && (typeof value === 'number' ? value > valueIfNotSelected : value.toString().length > 0))
                || textInput.length > 0,
            focused: this.hasFocus(),
        });

        return (
            <span className={classes}>
                <Dropdown
                    id={id}
                    isOpen={isOpen}
                    onOpenIntent={this.onDropdownOpen}
                    onCloseIntent={this.onDropdownClose}
                    onItemSelected={this.onItemSelected}
                    selectedItem={value}
                    defaultLabel={placeholder}
                    renderTargetComponent={this.renderTargetComponent}
                >
                    {(renderProps) => {
                        this.focusedItemIndex = renderProps.focusedItemIndex;
                        return (
                            <>
                                {showTinyLoader &&
                                    <TinyLoader asyncInfoSelector={asyncInfoSelector} />
                                }
                                {showDropdownItems && sortedData.map((option, index) => (
                                    <DropdownItem key={`select-option-${index}-${option.value}`} value={option.value}>
                                        {option.dropdownItemContent || option.label || '-'}
                                    </DropdownItem>
                                ))}
                                {showNoResults && (
                                    <>
                                        <DropdownItem
                                            key="select-option-no-results"
                                            value={null}
                                            isNotSelectable={true}
                                        >
                                            <Translate msg="common.typeahead.no_results" />
                                        </DropdownItem>
                                    </>
                                )}
                                {afterSearchFooter &&
                                    !showTinyLoader &&
                                    !showMinRequiredInputChars &&
                                    !showInsertText && (
                                        <DropdownItem
                                            key="select-option-after-search-footer"
                                            value={null}
                                            isNotSelectable={true}
                                            isAfterSearchFooter={true}
                                        >
                                            {afterSearchFooter}
                                        </DropdownItem>
                                    )}
                                {showInsertText && (
                                    <DropdownItem key="select-option-no-results" value={null} isNotSelectable={true}>
                                        <Translate msg="common.typeahead.insert_text" />
                                    </DropdownItem>
                                )}
                                {showMinRequiredInputChars && (
                                    <DropdownItem key="select-option-no-results" value={null} isNotSelectable={true}>
                                        <Translate
                                            msg="common.typeahead.not_enough_input_chars"
                                            placeholders={{
                                                min: minCharsToTriggerSearch,
                                            }}
                                        />
                                    </DropdownItem>
                                )}
                            </>
                        );
                    }}
                </Dropdown>
                {!disableReset && <Icon typeName="cross" onClick={this.clearInput} />}
                {children}
            </span>
        );
    }

    public componentDidMount() {
        const { minCharsToTriggerSearch = DEFAULT_MIN_CHARS_TO_TRIGGER_SEARCH } = this.props;
        if (this.onFilterDebounced && minCharsToTriggerSearch === 0) {
            this.onFilterDebounced(this.state.textInput);
        }
    }

    public componentDidUpdate(prevProps: IBaseTypeaheadProps) {
        const { value, data, autoSelectOnSingleOption, onItemSelected } = this.props;
        const { textInput, lastItemSelected } = this.state;

        // Logic to reset the input
        // Eg when input a changes input b is cleared (eg city / street)
        if (prevProps.value && !value && !this.hasFocus()) {
            return this.setState({
                textInput: '',
                filteredData: data,
            });
        }

        // Data has changes, reapply the filter
        if (prevProps.data !== data) {
            if (autoSelectOnSingleOption && data.length === 1 && data[0].value !== lastItemSelected) {
                onItemSelected(data[0].value);
                this.setState({
                    lastItemSelected: data[0].value,
                });
            }
            return this.setState({
                filteredData: this.filterData(textInput),
            });
        }
    }

    public componentWillUnmount() {
        this.isUnmounted = true;
        if (this.updatePlacementTimeout) {
            clearTimeout(this.updatePlacementTimeout);
        }
        this.dropdownTargetRenderProps = null;
        this.focusedItemIndex = null;
        if (this.onFilterDebounced) {
            this.onFilterDebounced.cancel();
        }
    }

    private renderTargetComponent(renderProps: IRenderDropdownTargetProps) {
        const { isFetching } = this.getFetchInfo();
        const {
            id, name, placeholder, disabled, isInvalid,
            onFocus, data, value, onBlur,
        } = this.props;
        const { textInput, isOpen } = this.state;
        const selectedOption = data
            .find((item) => (item.value && item.value.toString()) === (value ? value.toString() : ''));
        const selectedOptionLabel = selectedOption ? selectedOption.label : (textInput || '');
        const placeholderText = selectedOption && !isOpen ? selectedOptionLabel : (placeholder || '');

        this.dropdownTargetRenderProps = renderProps;

        const disableWhenSelectedLabelIsUnknownAndFetching = isFetching && !isOpen && !!value && !selectedOptionLabel;

        // Update the internal dropdown placement state on the next tick
        this.updatePlacementTimeout = window.setTimeout(this.updateDropdownPlacement, 0);

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => {
                    const textInputValue = disableWhenSelectedLabelIsUnknownAndFetching
                        ? translator('common.typeahead.loading') : (isOpen ? textInput : selectedOptionLabel);

                    return (
                        <TextInput
                            id={id}
                            name={name}
                            placeholder={placeholderText}
                            value={textInputValue}
                            onChange={this.onTextInputChanged}
                            disabled={disabled || disableWhenSelectedLabelIsUnknownAndFetching}
                            isInvalid={isInvalid}
                            onKeyDown={this.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            onClick={renderProps.onClick}
                            disableAutoComplete={true}
                            onFocus={onFocus}
                            onBlur={onBlur}
                        />
                    );
                }}
            </TranslatorContext.Consumer>

        );
    }

    private onItemSelected(selectedValue: TDropdownItemValue) {
        const { id, onItemSelected, data, keepFilterOnSelect } = this.props;

        onItemSelected(selectedValue);

        // Keep focus on current element so the user can "TAB" to the next input
        const currentElement = document.getElementById(id);
        currentElement.focus();

        if (keepFilterOnSelect && isSet(selectedValue)) {
            const matchingDataItem = data.find((item) => item.value === selectedValue);
            if (matchingDataItem) {
                const label = matchingDataItem.label;
                this.setState({
                    textInput: label,
                    filteredData: this.filterData(label),
                });

                return;
            }
        }

        // Clear the text input after selection
        this.setState({
            textInput: '',
            filteredData: data,
        });
    }

    private onTextInputChanged(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        const textInput = e.target.value;

        const {
            onItemSelected,
            minCharsToTriggerSearch = DEFAULT_MIN_CHARS_TO_TRIGGER_SEARCH,
        } = this.props;

        const filteredData = this.filterData(textInput);

        // Trigger on selected if there is a 100 match and only 1 value
        if (textInput && filteredData.length === 1 &&
            textInput.toLowerCase() === filteredData[0].label.toLowerCase()
        ) {
            onItemSelected(filteredData[0].value);

            return this.setState({
                textInput,
                isOpen: false,
                filteredData,
            });
        }

        this.setState({
            textInput,
            isOpen: true,
            filteredData,
        });

        if (this.onFilterDebounced) {
            if (textInput.length >= minCharsToTriggerSearch || !this.props.asyncInfoSelector) {
                this.setState({
                    isFilteringTriggered: true,
                });
                this.onFilterDebounced(textInput);
            } else {
                this.setState({
                    isFilteringTriggered: false,
                });
                this.onFilterDebounced.cancel();
            }
        }

        this.clearPreviousSelectedItem();

        if (textInput.length === 0) {
            this.clearInput();
        }
    }

    private clearPreviousSelectedItem() {
        const {
            clearSelectedItemOnTextInput = true,
            value,
            valueIfNotSelected = DEFAULT_VALUE_IF_NOT_SELECTED,
            onItemSelected,
        } = this.props;

        if (clearSelectedItemOnTextInput && value !== valueIfNotSelected) {
            onItemSelected(valueIfNotSelected);
        }
    }

    private onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        const isTabKeyPressed = !e.shiftKey && isKeyPressed(e, 'Tab');
        const { filteredData, isOpen } = this.state;

        // Select the focused item on Tab
        if (isOpen && isTabKeyPressed && filteredData.length > 0) {
            e.preventDefault();
            this.setState({
                textInput: filteredData[this.focusedItemIndex].label,
                isOpen: false,
            });
            return this.props.onItemSelected(filteredData[this.focusedItemIndex].value);
        }

        if (this.dropdownTargetRenderProps) {
            this.dropdownTargetRenderProps.onKeyDown(e);
        }
    }

    private onKeyUp(e: KeyboardEvent<HTMLInputElement>) {
        const { isOpen } = this.state;

        if (!isOpen && isKeyPressed(e, 'Enter')) {
            e.preventDefault();
            return;
        }

        if (!isOpen && isKeyPressed(e, 'ArrowDown')) {
            e.preventDefault();
            this.setState({
                isOpen: true,
            });
            return;
        }

        if (this.dropdownTargetRenderProps) {
            this.dropdownTargetRenderProps.onKeyUp(e);
        }
    }

    private filterData(filter: string) {
        const filteredData = [];
        if (!this.props.autoFilteringDisabled) {
            filteredData.push(...filterListItems(this.props.data, filter, { columnKeysToIgnore: ['value'] }));
        } else {
            // Filtering is done by a custom onFilter handler
            filteredData.push(...this.props.data);
        }
        return filteredData;
    }

    private clearInput() {
        const {
            valueIfNotSelected = DEFAULT_VALUE_IF_NOT_SELECTED,
            valueToSetOnClear,
        } = this.props;

        if (typeof valueToSetOnClear !== 'undefined') {
            this.onItemSelected(valueToSetOnClear);
        } else {
            this.onItemSelected(valueIfNotSelected as TDropdownItemValue);

        }

        this.setState({
            textInput: '',
        });
    }

    private updateDropdownPlacement() {
        if (!this.isUnmounted && this.dropdownTargetRenderProps) {
            const { dropdownItemsPlacement } = this.state;
            const currentPlacement = this.dropdownTargetRenderProps.dropdownItemsPlacement;
            if (currentPlacement !== dropdownItemsPlacement) {
                this.setState({ dropdownItemsPlacement: currentPlacement });
            }
        }
    }

    private onDropdownOpen() {
        if (!this.isUnmounted) {
            this.setState({ isOpen: true });
        }
    }

    private onDropdownClose() {
        if (!this.isUnmounted) {
            this.setState({ isOpen: false });
        }
    }

    private getFetchInfo() {
        const { asyncInfoSelector, isFetching, hasFetchError, asyncInfoSelectorDoesNotRequireState } = this.props;
        if (typeof asyncInfoSelector === 'function' && asyncInfoSelectorDoesNotRequireState) {
            const asyncInfo = asyncInfoSelector();
            return {
                isFetching: asyncInfo.status === AsyncStatus.Busy,
                hasFetchError: !!asyncInfo.error,
            };
        }
        return {
            isFetching,
            hasFetchError,
        };
    }

    private hasFocus(): boolean {
        const activeElement = document.activeElement;
        return activeElement && activeElement.getAttribute('id') === this.props.id;
    }
}

function sortByName(a: ITypeaheadDataItem, b: ITypeaheadDataItem) {
    if (a.sticky && !b.sticky) {
        return -1;
    }
    if (b.sticky && !a.sticky) {
        return 1;
    }
    return (a.label || '').localeCompare(b.label || '');
}

export default connect<IPrivateProps, IBaseTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            if (typeof publicProps.asyncInfoSelector !== 'function') {
                return {
                    isFetching: false,
                    hasFetchError: null,
                };
            }
            const asyncInfo = publicProps.asyncInfoSelector(state);
            return {
                isFetching: asyncInfo.status === AsyncStatus.Busy,
                hasFetchError: !!asyncInfo.error,
            };
        };
    },
})(BaseTypeahead);
