import EditSeatAndFunction, { IStateProps, IEditSeatProps } from './';
import connect from '../../../../../utils/libs/redux/connect';
import {
    getSelectedEmployee, getEmployeePersonalRisks, getUpdateEmployeeEmploymentAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { updateEmployeeEmployment } from '../../../../../redux/employee/info/actions';
import { isInThePast } from '../../../../../utils/core/date/isInThePast';

export default connect<IStateProps, IEditSeatProps>({
    stateProps: (state) => {
        const employee = getSelectedEmployee(state);
        const personalRisks = getEmployeePersonalRisks(state) || [];

        const updateAsyncInfo = getUpdateEmployeeEmploymentAsyncInfo(state);

        const isFutureEmployee = employee && !isInThePast(employee.dateInService);

        return {
            isDetailsLoaded: !!employee,
            initialValues: employee && {
                companyCode: employee.company && employee.company.companyCode,
                customerFunctionId: employee.function.id,
                dateInFunction: isFutureEmployee ? employee.dateInService : formatDateForBackend(new Date()),
            },
            translator: getTranslatorDeprecated(state),
            personName: employee && formatPersonName(employee),
            updateAsyncInfo,
            currentFunctionDescription: employee && employee.function.description,
            canTransferRisks: personalRisks.length > 0,
            minDate: isFutureEmployee ? formatDateForBackend(new Date()) : employee && employee.dateInFunction,
            id: employee && employee.id,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSubmit: (values, duplicateOptions) => {
                const state = getState();
                const selectedEmployee = getSelectedEmployee(state);

                const isFutureEmployee = !isInThePast(selectedEmployee.dateInService);

                dispatch(updateEmployeeEmployment({
                    ...values,
                    id: selectedEmployee.id,
                    dateInService: isFutureEmployee ? values.dateInFunction : selectedEmployee.dateInService,
                    duplicate: duplicateOptions,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditSeatAndFunction);
