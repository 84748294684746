import { logOutgoingLink } from '../logging/analytics/eventLogger';

interface IOptions {
    logOutgoingLinkEvent?: boolean;
}

export default function openInNewTab(
    urlToOpen: string,
    {
        logOutgoingLinkEvent = true,
    }: IOptions = {},
) {
    if (logOutgoingLinkEvent) {
        logOutgoingLink({
            outgoingUrl: urlToOpen,
        });
    }

    window.open(urlToOpen, '_blank');
}
