import React from 'react';
import './top-menu.scss';
import { connect } from '../../index';
import { TOP_MENU_ITEMS } from '../../../config/navigation/menu.config';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import { IMenuItem } from '../../../models/general/navigation';
import { getTopMenuParentRouteKey, shouldHideTopMenu } from '../../../redux/location/selectors';
import {
    getSkipPerformanceDashboardWarning,
    getSkipPerformanceDashboardTutorial,
    getPerformanceDashboardAccessData,
} from '../../../redux/auth/selectors';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import Button from '../../common/buttons/Button';
import { getPath } from '../../../routes';
import ROUTE_KEYS from '../../../routeKeys';
import PerformanceDashboardIcon from '../../assets/img/general/performance_dashboard/menu.svg';
import InlineSVG from '../../common/icons/InlineSVG';
import {
    toggleIsPerformanceDashboardDialogOpenAction,
    navigateToPerformanceDashboardAction,
} from '../../../redux/auth/actions';
import { isAllSeatsSelected } from '../../../redux/company/selected/selectors';

const getAllowedTopMenuItems = makeAllowedNavigationItemsMemoizedSelector<IMenuItem>(TOP_MENU_ITEMS);

interface IPrivateProps {
    allowedTopMenuItems: IMenuItem[];
    topParentRouteKey: string;
    shouldHideTopMenu: boolean;
    skipPerformanceDashboardWarning: boolean;
    skipPerformanceDashboardTutorial: boolean;
    showPerformanceDashboardLink: boolean;
    togglePerformanceDashboardDialog: () => void;
    navigateToPerformanceDashboard: () => void;
    allSeatsSelected: boolean;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const performanceAccess = getPerformanceDashboardAccessData(state);
        return {
            allowedTopMenuItems: getAllowedTopMenuItems(state),
            topParentRouteKey: getTopMenuParentRouteKey(state),
            shouldHideTopMenu: shouldHideTopMenu(state),
            skipPerformanceDashboardWarning: getSkipPerformanceDashboardWarning(state),
            skipPerformanceDashboardTutorial: getSkipPerformanceDashboardTutorial(state),
            showPerformanceDashboardLink: performanceAccess && performanceAccess.grantAccess,
            allSeatsSelected: isAllSeatsSelected(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            togglePerformanceDashboardDialog: () => {
                dispatch(toggleIsPerformanceDashboardDialogOpenAction());
            },
            navigateToPerformanceDashboard: () => {
                dispatch(navigateToPerformanceDashboardAction.trigger({ openInNewTab: true }));
            },
        };
    },
})(TopMenu);

function TopMenu({
    allowedTopMenuItems,
    topParentRouteKey,
    shouldHideTopMenu,
    skipPerformanceDashboardWarning,
    skipPerformanceDashboardTutorial,
    showPerformanceDashboardLink,
    togglePerformanceDashboardDialog,
    navigateToPerformanceDashboard,
    allSeatsSelected,
}: IPrivateProps) {
    if (shouldHideTopMenu) {
        return null;
    }

    return (
        <div className="TopMenu">
            <nav className="container">
                {
                    allowedTopMenuItems
                        .map((menuItem) => {
                            if (menuItem.isPerformanceDashboardLink) {
                                if (showPerformanceDashboardLink) {
                                    return (
                                        <Button
                                            id="performance-dashboard-link"
                                            typeName="text"
                                            onClick={onPerformanceDashboardClicked}
                                            key="performance-dashboard-menu-item"
                                            className="TopMenu__performance-button"
                                        >
                                            <InlineSVG svg={PerformanceDashboardIcon} className="TopMenu__icon" />
                                            <Translate msg={menuItem.translationKey} />
                                        </Button>
                                    );
                                }
                                return null;
                            }
                            return (
                                <LinkToRoute
                                    id={`top-menu-item-${menuItem.code}-link`}
                                    key={`top-menu-item-${menuItem.code}`}
                                    to={menuItem.linkTo}
                                    className="item"
                                    activeClassName="active"
                                    isActive={() => menuItem.linkTo === topParentRouteKey}
                                >
                                    {
                                        menuItem.iconTypeName &&
                                        <Icon typeName={menuItem.iconTypeName} alt={menuItem.iconAlt} />
                                    }
                                    {
                                        !menuItem.iconTypeName && (
                                            <>
                                                {/*
                                                Render label twice to avoid 'jump' (with CSS) when
                                                the active element changes, as the active element gets
                                                bold so width of this element changes too
                                             */}
                                                <Translate msg={menuItem.translationKey} />
                                                <span aria-hidden="true">
                                                    {/* aria-hidden: avoid screen reader to read the label twice */}
                                                    <Translate msg={menuItem.translationKey} />
                                                </span>
                                            </>
                                        )
                                    }
                                </LinkToRoute>
                            );
                        })
                }
            </nav>
        </div>
    );

    /*
        When changing this function, also change function in MobileNav component
    */
    function onPerformanceDashboardClicked() {
        if (skipPerformanceDashboardWarning || allSeatsSelected) {
            if (skipPerformanceDashboardTutorial) {
                navigateToPerformanceDashboard();
            } else {
                const path = getPath({ routeKey: ROUTE_KEYS.R_PERFORMANCE_DASHBOARD });
                window.open(path, '_blank');
            }
        } else {
            togglePerformanceDashboardDialog();
        }
    }
}
