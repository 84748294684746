import React, { PureComponent } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import { ICMSCookieTypeConfiguration, ICMSCookieConfiguration } from '../../../../../models/general/cookieConsent';
import { ILocationAction } from '../../../../../models/general/redux';
import {
    COOKIE_CONSENT_SETTINGS_QUERY_PARAM,
    COOKIE_CONSENT_COOKIE_TYPE_DETAILS_QUERY_PARAM,
} from '../../../../appShell/CookieConsent/route';
import { getCurrentRouteWithExtraQueryParam } from '../../../../../redux/location/actions';
import { FormContext, IFormRenderProps } from '@snipsonian/react/es/components/form/GenericForm';
import Checkbox from '../../../input/Checkbox';
import Translate from '../../../Translate';
import LinkToRoute from '../../../navigation/LinkToRoute';
import { TFormValues } from '../../../../appShell/CookieConsent/CookieConsentSettingsContent/cookieConsentSchema';
import { ErrorTypes } from '../../../../../models/general/error';
import { LEGAL_COOKIES_PAGEPART_CLASS_NAME as CLASS_NAME } from './shared';

export type ICookieType = ICMSCookieTypeConfiguration & {
    cookies: Pick<ICMSCookieConfiguration, 'name'|'description'>[],
};

interface IPublicProps {
    cookieType: ICookieType;
}

interface IPrivateProps {
    linkForCookieType: (cookieType: ICookieType) => ILocationAction<{}>;
}

class CookieType extends PureComponent<IPrivateProps & IPublicProps> {
    constructor(props: IPrivateProps & IPublicProps) {
        super(props);
    }

    render() {
        const { cookieType, linkForCookieType } = this.props;

        return (
            <FormContext.Consumer>
                {({ formRenderProps, translationPrefix, formName }) => {
                    const renderProps = formRenderProps as unknown as IFormRenderProps<TFormValues, ErrorTypes>;
                    const { values, handleChange } = renderProps;
                    const translationSuffix = cookieType.alwaysOn
                        ? 'always_on'
                        : values[cookieType.internalName]
                            ? 'on' : 'off';

                    return (
                        <>
                            <strong>{cookieType.name}</strong>
                            <div className={`${CLASS_NAME}__cookie-container`}>
                                <Checkbox
                                    name={cookieType.internalName}
                                    checked={values[cookieType.internalName] as unknown as boolean}
                                    onChange={handleChange}
                                    disabled={cookieType.alwaysOn}
                                    toggleButton
                                >
                                    <Translate msg={`${translationPrefix}.enabled.${translationSuffix}`} />
                                </Checkbox>
                                <LinkToRoute
                                    id={`details-${cookieType.internalName}`}
                                    to={linkForCookieType(cookieType)}
                                >
                                    <Translate
                                        msg={`${translationPrefix}.more_info_about_cookie_btn`}
                                    />
                                </LinkToRoute>
                            </div>
                            {renderLongDescription(cookieType)}
                        </>
                    );
                }}
            </FormContext.Consumer>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        linkForCookieType: (cookieType: ICookieType) => getCurrentRouteWithExtraQueryParam(state, {
            [COOKIE_CONSENT_SETTINGS_QUERY_PARAM]: true,
            [COOKIE_CONSENT_COOKIE_TYPE_DETAILS_QUERY_PARAM]: cookieType.internalName,
        }),
    }),
})(CookieType);

function renderLongDescription(cookieType: ICookieType) {
    if (!cookieType.longDescription) {
        return null;
    }

    if (cookieType.longDescription.indexOf('<p>') === 0) { // startsWith
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: cookieType.longDescription,
                }}
            />
        );
    }

    return (
        <p>
            {cookieType.longDescription}
        </p>
    );
}
