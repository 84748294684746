import closest from './closest';

const FOCUS_SELECTOR = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export function findFirstFocusableElement(element: HTMLElement) {
    return element.querySelector(FOCUS_SELECTOR) as HTMLElement;
}

export function findLastFocusableElement(element: HTMLElement) {
    const focusableElements = element.querySelectorAll(FOCUS_SELECTOR);
    return focusableElements[focusableElements.length - 1] as HTMLElement;
}

export function findNextFocusableElement(activeElement: HTMLElement, container?: HTMLElement) {
    const focusableElements = (container || document).querySelectorAll(FOCUS_SELECTOR);
    const currentIndex = getFocusedElementIndex(focusableElements, activeElement);

    if (currentIndex !== -1) {
        const next = focusableElements[currentIndex + 1];
        if (next) {
            return next as HTMLElement;
        }
        return focusableElements[0] as HTMLElement;
    }
    return null;
}

export function findPreviousFocusableElement(activeElement: HTMLElement, container?: HTMLElement) {
    const focusableElements = (container || document).querySelectorAll(FOCUS_SELECTOR);
    const currentIndex = getFocusedElementIndex(focusableElements, activeElement);

    if (currentIndex !== -1) {
        const previous = focusableElements[currentIndex - 1];
        if (previous) {
            return previous as HTMLElement;
        }
        return focusableElements[focusableElements.length - 1] as HTMLElement;
    }
    return null;
}

export function focusIsInContainer(container: HTMLElement, activeElement?: HTMLElement) {
    if (closest(activeElement || document.activeElement, (el) => el === container)) {
        return true;
    }
    return false;
}

function getFocusedElementIndex(focusableElements: NodeListOf<Element>, activeElement: HTMLElement) {
    let currentIndex = -1;
    for (let i = 0, length = focusableElements.length; i < length; i += 1) {
        if (focusableElements[i] === activeElement) {
            currentIndex = i;
            break;
        }
    }
    return currentIndex;
}
