import React from 'react';
import { connect } from '../../index';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import { IDocumentCenterItem } from '../../../models/general/navigation';
import {
    DOCUMENT_CENTER_ITEMS,
    DOCUMENT_CENTER_MANAGEMENT_ITEMS,
} from '../../../config/navigation/documentCenter.config';
import PageHeader from '../../appShell/PageHeader';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Tile from '../../common/widget/Tile';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const getAllowedDocCenterItems = makeAllowedNavigationItemsMemoizedSelector<IDocumentCenterItem>(
    DOCUMENT_CENTER_ITEMS,
);
const getAllowedDocCenterMgmtItems = makeAllowedNavigationItemsMemoizedSelector<IDocumentCenterItem>(
    DOCUMENT_CENTER_MANAGEMENT_ITEMS,
);

interface IPrivateProps {
    allowedDocCenterItems: IDocumentCenterItem[];
    allowedDocCenterMgmtItems: IDocumentCenterItem[];
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            allowedDocCenterItems: getAllowedDocCenterItems(state),
            allowedDocCenterMgmtItems: getAllowedDocCenterMgmtItems(state),
        };
    },
})(DocumentCenterDashboard);

function DocumentCenterDashboard(props: IPrivateProps) {
    const {
        allowedDocCenterItems,
        allowedDocCenterMgmtItems,
    } = props;

    return (
        <div className="tile-dashboard">
            <PageHeader
                title="document_center.overview.title"
                breadcrumbs={true}
            />
            <div className="container">
                <div className="sections-wrapper">
                    { allowedDocCenterItems.length > 0 && (
                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems allowedDocCenterItems={allowedDocCenterItems} />
                            </div>
                        </div>
                    )}
                    { allowedDocCenterMgmtItems.length > 0 && (
                        <div className="section-sub">
                            <div>
                                <div className="section-inner">
                                    <NavigationManagementItems allowedDocCenterMgmtItems={allowedDocCenterMgmtItems} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function NavigationItems({ allowedDocCenterItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedDocCenterItems
                    .map((docCenterItem) => (
                        <div className="tile-wrapper" key={docCenterItem.id}>
                            <LinkToRoute
                                id={docCenterItem.id}
                                to={docCenterItem.linkTo}
                            >
                                <Tile
                                    title={docCenterItem.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: docCenterItem.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.DOCUMENT_CENTER,
                                    }}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}

function NavigationManagementItems({ allowedDocCenterMgmtItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedDocCenterMgmtItems
                    .map((docCenterItem) => (
                        <div className="tile-wrapper" key={docCenterItem.id}>
                            <LinkToRoute
                                id={docCenterItem.id}
                                to={docCenterItem.linkTo}
                            >
                                <Tile
                                    title={docCenterItem.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: docCenterItem.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.DOCUMENT_CENTER,
                                    }}
                                    typeName="green"
                                    arrow={true}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}
