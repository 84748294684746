import React, { PureComponent, Fragment } from 'react';

import { AsyncStatus } from '../../../models/general/redux';
import { createAccount, requestAccount } from '../../../redux/auth/actions';
import { formatPersonName } from '../../../utils/formatting/formatPerson';
import {
    getCreateAccountAsyncInfo,
    getRequestAccountAdministrators,
    getRequestAccountAsyncInfo,
    isRequestingOrCreatingAccount,
} from '../../../redux/auth/selectors';
import { getRouteKey } from '../../../redux/location/selectors';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import { ICreateAccountPayload, IRequestAccountPayload } from '../../../models/auth/authentication';
import { navigateTo } from '../../../redux/location/actions';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import ROUTE_KEYS from '../../../routeKeys';
import { connect } from '../../index';
import Translate from '../../common/Translate';

import { IPrivateProps, IPublicProps, RequestAccountSteps, IComponentState } from './RequestAccount.types';
import { RequestAccountForm } from './RequestAccountForm/RequestAccountForm.component';
import { CreateAccountForm } from './CreateAccountForm/CreateAccountForm.component';
import './request-account.scss';

class RequestAccount extends PureComponent<IPrivateProps & IPublicProps, IComponentState> {
    constructor(props: IPrivateProps & IPublicProps) {
        super(props);

        this.state = this.getInitialState();

        this.renderSuccess = this.renderSuccess.bind(this);
        this.renderRequestAccountForm = this.renderRequestAccountForm.bind(this);
        this.renderCreateAccountForm = this.renderCreateAccountForm.bind(this);
        this.renderAdministratorsDialog = this.renderAdministratorsDialog.bind(this);
    }

    private onRequestAccount = (values: IRequestAccountPayload) => {
        this.props.onRequestAccount(values);

        this.setState({
            requestAccount: values,
        });
    };

    private goTopreviousStep = () => {
        this.setState({
            activeStep: RequestAccountSteps.Request,
        });
    };

    public componentDidUpdate(prevProps: IPrivateProps) {
        const {
            requestAccountAsyncInfo, showAdministrators,
            createAccountAsyncInfo,
        } = this.props;

        if (
            requestAccountAsyncInfo.status === AsyncStatus.Success &&
            prevProps.requestAccountAsyncInfo.status === AsyncStatus.Busy &&
            !showAdministrators
        ) {
            this.setState({
                activeStep: RequestAccountSteps.Create,
            });
        }

        if (
            createAccountAsyncInfo.status === AsyncStatus.Success &&
            prevProps.createAccountAsyncInfo.status === AsyncStatus.Busy
        ) {
            this.setState({
                activeStep: RequestAccountSteps.Success,
            });
        }
    }

    private getInitialState = () => {
        return {
            activeStep: RequestAccountSteps.Request,
            requestAccount: {
                companyCode: null,
                ventureNumber: null,
            },
        };
    };

    private onCreateAccount = (values: ICreateAccountPayload) => {
        this.props.onCreateAccount({
            ...values,
            ...this.state.requestAccount,
        });
    };

    private onSuccess = () => {
        const { navigateToLogin } = this.props;

        this.setState(this.getInitialState());

        navigateToLogin();
    };

    public render() {
        const { showAdministrators } = this.props;

        const { activeStep } = this.state;

        return (
            <div className="RequestAccount">
                {showAdministrators ?
                    this.renderAdministratorsDialog() :
                    activeStep === 0 && this.renderRequestAccountForm()
                }
                {activeStep === 1 && this.renderCreateAccountForm()}
                {activeStep === 2 && this.renderSuccess()}
            </div>
        );
    }

    private renderAdministratorsDialog() {
        const {
            requestAccountAdministrators,
        } = this.props;

        return (
            <>
                <p>
                    <Translate msg="auth.request_account.step_one.error.text" />
                </p>
                <p>
                    {
                        requestAccountAdministrators.map((administrator, index) => {
                            return (
                                <Fragment key={administrator.webUserId}>
                                    <a href={`mailto:${administrator.email}`}>
                                        {formatPersonName(administrator)}
                                    </a>
                                    {index !== requestAccountAdministrators.length - 1 && ', '}
                                </Fragment>
                            );
                        })
                    }
                </p>
            </>
        );
    }

    private renderRequestAccountForm() {
        const { requestAccount } = this.state;
        const {
            isRequestingOrCreatingAccount,
            requestAccountAsyncInfo,
            translator,
        } = this.props;

        return (
            <RequestAccountForm
                isRequestingOrCreatingAccount={isRequestingOrCreatingAccount}
                onRequestAccount={this.onRequestAccount}
                requestAccount={requestAccount}
                requestAccountAsyncInfo={requestAccountAsyncInfo}
                translator={translator}
            />
        );
    }

    private renderCreateAccountForm() {
        const {
            createAccountAsyncInfo,
            translator,
            isRequestingOrCreatingAccount,
        } = this.props;

        return (
            <CreateAccountForm
                translator={translator}
                createAccountAsyncInfo={createAccountAsyncInfo}
                isRequestingOrCreatingAccount={isRequestingOrCreatingAccount}
                onPreviousClick={this.goTopreviousStep}
                onCreateAccount={this.onCreateAccount}
            />
        );
    }

    public renderSuccess() {
        return (
            <div className="success">
                <Icon typeName="check" circle />
                <h3>
                    <Translate msg="auth.request_account.success.title" />
                </h3>
                <p>
                    <Translate msg="auth.request_account.success.info" />
                </p>
                <Button id="request-account-success-close-button" typeName="secondary" onClick={this.onSuccess}>
                    <Translate msg="auth.request_account.success.close" />
                </Button>
            </div>
        );
    }

}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
        isFormActive: getRouteKey(state) === ROUTE_KEYS.R_REQUEST_ACCOUNT,
        isRequestingOrCreatingAccount: isRequestingOrCreatingAccount(state),
        requestAccountAsyncInfo: getRequestAccountAsyncInfo(state),
        requestAccountAdministrators: getRequestAccountAdministrators(state).filter((item) => !!item.email),
        createAccountAsyncInfo: getCreateAccountAsyncInfo(state),
    }),
    dispatchProps: (dispatch) => ({
        onRequestAccount: (values: IRequestAccountPayload) => {
            dispatch(requestAccount(values));
        },
        onCreateAccount: (values: ICreateAccountPayload) => {
            dispatch(createAccount(values));
        },
        navigateToLogin: () => {
            dispatch(navigateTo(ROUTE_KEYS.R_LOGIN));
        },
    }),
})(RequestAccount);
