import { StandardAction } from 'redux-logic';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { createEpic, IState } from '../../index';
import isSet from '@snipsonian/core/es/is/isSet';
import {
    FETCH_COMPANY_FUNCTION_ISCO,
    FETCH_COMPANY_FUNCTION_RISKS,
    FETCH_COMPANY_FUNCTION_RESEARCHES,
    FETCH_COMPANY_FUNCTIONS,
    FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD,
    ADD_COMPANY_FUNCTION,
} from './types';
import {
    fetchCompanyFunctionIsco, fetchCompanyFunctionIscoSucceeded, fetchCompanyFunctionIscoFailed,
    fetchCompanyFunctionRisks, fetchCompanyFunctionRisksSucceeded, fetchCompanyFunctionRisksFailed,
    fetchCompanyFunctionResearches, fetchCompanyFunctionResearchesSucceeded, fetchCompanyFunctionResearchesFailed,
    fetchCompanyFunctions, fetchCompanyFunctionsSucceeded, fetchCompanyFunctionsFailed,
    fetchCompanyFunctionsForTypeaheadSucceeded, fetchCompanyFunctionsForTypeaheadFailed,
    addCompanyFunctionSucceeded, addCompanyFunctionFailed,
} from './actions';
import { fetchCompanyFunctionEmployees } from '../../employee/employees/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat,
} from '../selected/selectors';
import {
    getSelectedCompanyFunctionFromList,
    getSelectedCompanyFunction,
    isCompanyFunctionsDataAvailable,
} from './selectors';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import {
    IAddCompanyFunctionPayload,
    IFetchCompanyFunctionIscoPayload, IFetchCompanyFunctionsPayload,
} from '../../../models/admin/companyFunctions';
import { IFetchCompanyFunctionRisksPayload } from '../../../models/admin/risks';
import { IFetchCompanyFunctionResearchesPayload } from '../../../models/admin/researches';
import Api from '../../../api';
import { getLocationState } from '../../location/selectors';
import { IAction } from '../../../models/general/redux';

const ACTION_TYPES_THAT_FETCH_COMPANY_FUNCTION_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE);

// fetchCompanyFunctionsIfNotAvailableEpic
createEpic<IFetchCompanyFunctionsPayload>({
    onActionType: [
        FETCH_COMPANY_FUNCTIONS,
        ROUTE_KEYS.R_COMPANY_FUNCTIONS,
        ROUTE_KEYS.R_WORK_POST_CARDS,
    ],
    processReturn: fetchCompanyFunctionsList,
    refreshDataIf: ({ getState, action }) => {
        if (action.payload.forceRefresh) {
            return true;
        }
        const state = getState();
        const { type: currentRoute } = getLocationState(state);
        // when functions are available do not refresh when navigating
        // from 'add function dialog' back to the overview list (by closing the dialog)
        if (currentRoute === ROUTE_KEYS.R_COMPANY_FUNCTIONS_ADD && isCompanyFunctionsDataAvailable(state)) {
            return false;
        }
        // do not refresh if only clientside (query) filtering changed
        return !isCompanyFunctionsDataAvailable(state) || (
            action.type === ROUTE_KEYS.R_COMPANY_FUNCTIONS ||
            action.type === ROUTE_KEYS.R_WORK_POST_CARDS
        ) && currentRoute !== action.type;
    },
    latest: false,
});

async function fetchCompanyFunctionsList(
    { api, getState, action }: { api: typeof Api, getState, action: IAction<IFetchCompanyFunctionsPayload> }) {
    try {
        const state = getState();

        const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
        const showFullFamily = isSet(action.payload.showFullFamily) ?
            action.payload.showFullFamily : getSelectedCompanySeat(state).isAllSeatsSelected;

        const companyFunctions = await api.admin.functions.fetchCompanyFunctions(
            { companyCode, showFullFamily }, { showUnmanned: true },
        );
        return fetchCompanyFunctionsSucceeded(companyFunctions);
    } catch (error) {
        return fetchCompanyFunctionsFailed(error);
    }
}

// fetchCompanyFunctionDetailIfNotAlreadyAvailableEpic
createEpic<{ functionId: number }>({
    onActionType: ACTION_TYPES_THAT_FETCH_COMPANY_FUNCTION_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !getSelectedCompanyFunction(getState()),
    processMultiple: fetchCompanyFunctionDetail,
    latest: true,
});

// fetchCompanyFunctionDetailEpic
createEpic<{ functionId: number }>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL,
        ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL,
    ],
    processMultiple: fetchCompanyFunctionDetail,
    latest: true,
});

function fetchCompanyFunctionDetail(
    { action, getState }: { action: StandardAction<string, { functionId: number }>, getState: () => IState },
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) {
    try {
        const id = action.payload.functionId;
        const state = getState();

        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

        const selectedCompanyFunction = getSelectedCompanyFunctionFromList(getState(), id);
        if (!selectedCompanyFunction) {
            dispatch(fetchCompanyFunctions({ companyCode, showFullFamily, keyword: '' }));
        }

        dispatch(fetchCompanyFunctionIsco({ id }));
        dispatch(fetchCompanyFunctionEmployees({ id, companyCode }));
        dispatch(fetchCompanyFunctionRisks({ id }));
        dispatch(fetchCompanyFunctionResearches({ id }));

        return done();
    } catch (error) {
        return done();
    }
}

// fetchCompanyFunctionIscoEpic
createEpic<IFetchCompanyFunctionIscoPayload>({
    onActionType: FETCH_COMPANY_FUNCTION_ISCO,
    async processReturn({ api, action }) {
        try {
            const { payload } = action;
            const functionIsco = await api.admin.functions.fetchCompanyFunctionIsco(
                payload.id.toString(),
                payload.localeOverride,
            );
            return fetchCompanyFunctionIscoSucceeded(functionIsco);
        } catch (error) {
            return fetchCompanyFunctionIscoFailed(error);
        }
    },
    latest: true,
});

// fetchCompanyFunctionRisksEpic
createEpic<IFetchCompanyFunctionRisksPayload>({
    onActionType: FETCH_COMPANY_FUNCTION_RISKS,
    async processReturn({ api, action }) {
        try {
            const risks = await api.admin.functions.fetchCompanyFunctionRisks(action.payload.id.toString());
            return fetchCompanyFunctionRisksSucceeded(risks);
        } catch (error) {
            return fetchCompanyFunctionRisksFailed(error);
        }
    },
    latest: true,
});

// fetchCompanyFunctionResearchesEpic
createEpic<IFetchCompanyFunctionResearchesPayload>({
    onActionType: FETCH_COMPANY_FUNCTION_RESEARCHES,
    async processReturn({ api, action }) {
        try {
            const researches = await api.admin.functions.fetchCompanyFunctionResearches(action.payload.id.toString());
            return fetchCompanyFunctionResearchesSucceeded(researches);
        } catch (error) {
            return fetchCompanyFunctionResearchesFailed(error);
        }
    },
    latest: true,
});

// fetchCompanyFunctionsForTypeaheadEpic
createEpic<IFetchCompanyFunctionsPayload>({
    onActionType: FETCH_COMPANY_FUNCTIONS_FOR_TYPEAHEAD,
    async processReturn({ api, action, getState }) {
        try {
            const { keyword, companyCode, showFullFamily } = action.payload;

            const companyFunctions = await api.admin.functions.fetchCompanyFunctions(
                { companyCode, showFullFamily, keyword }, { showUnmanned: true },
            );
            return fetchCompanyFunctionsForTypeaheadSucceeded(companyFunctions);
        } catch (error) {
            return fetchCompanyFunctionsForTypeaheadFailed(error);
        }
    },
    latest: true,
});

// addCompanyFunctionEpic
createEpic<IAddCompanyFunctionPayload>({
    onActionType: ADD_COMPANY_FUNCTION,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const { descriptionNL, descriptionFR } = action.payload;

            const response = await api.admin.functions.addCompanyFunction(companyCode, action.payload);
            dispatch(addCompanyFunctionSucceeded({ id: response, descriptionNL, descriptionFR }));
        } catch (error) {
            dispatch(addCompanyFunctionFailed(error));
        }

        done();
    },
    latest: false,
});
