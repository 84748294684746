import dayjs from 'dayjs';

export function isInSameMonth(date1: Date | string, date2: Date | string): boolean {
    if (!date1 || !date2) {
        return false;
    }

    const parsedDate1 = typeof date1 === 'string' ? dayjs(date1).toDate() : date1;
    const parsedDate2 = typeof date2 === 'string' ? dayjs(date2).toDate() : date2;

    return (
        parsedDate1.getFullYear() === parsedDate2.getFullYear() &&
        parsedDate1.getMonth() === parsedDate2.getMonth()
    );
}
