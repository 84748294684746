import React from 'react';

import { ADD_EMPLOYEE_PLACEHOLDER_KEYS } from '../../../../../../../../config/administration.config';
import { ConstantType } from '../../../../../../../../models/general/constants';
import { ErrorTypes } from '../../../../../../../../models/general/error';
import { formatDateForBackend } from '../../../../../../../../utils/formatting/formatDate';
import { IOnboardEmployeeToAdd } from '../../../../../../../../models/onboarding/wizard';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import { now } from '../../../../../../../../utils/core/date/getSpecificDate';
import { ShapeOf } from '../../../../../../../../models/ui/form';

import ConstantsTypeahead from '../../../../../../../common/input/ConstantsTypeahead';
import DatePicker from '../../../../../../../common/widget/DateTimePicker/DatePicker';
import FloatableTextInputWrapper from '../../../../../../../common/forms/FloatableTextInputWrapper';
import FormFieldError from '../../../../../../../common/forms/FormFieldError';
import RequiredMarker from '../../../../../../../common/input/RequiredMarker';
import Translate from '../../../../../../../common/Translate';
import { createFormTextInput } from '../../../../../../../common/forms/FormTextInput';
import { IFormRenderProps } from '../../../../../../../common/forms/Form';
import {
    nationalityIdBelgium,
} from '../../../../../../../administration/Employees/AddEmployee/steps/PersonalData/PersonalDataSchema';

type FormValues = Pick<IOnboardEmployeeToAdd,
    'birthDate' |
    'firstName' |
    'languageId' |
    'name' |
    'nationalityId' |
    'nationalRegisterNumber' |
    'sexId'>;

interface IAddEmployeeGeneralProps {
    fields: ShapeOf<FormValues>;
    formRenderProps: IFormRenderProps<FormValues>;
    baseTranslationKey: string;
    formName: string;
    translator: ITranslator;
    isForeignEmployee?: boolean;
}

const AddEmployeeFormTextInput = createFormTextInput<FormValues>();

const AddEmployeeGeneral = (props: IAddEmployeeGeneralProps) => {

    const {
        formName,
        formRenderProps,
        baseTranslationKey,
        fields,
        translator,
        isForeignEmployee,
    } = props;

    const {
        values,
        touched,
        errors,
        setFieldValue,
    } = formRenderProps;

    function onNationalityItemSelected(value: number | string) {
        if (typeof value === 'string') {
            setFieldValue('nationalityId', parseInt(value as string, 10));
            return;
        }
        setFieldValue('nationalityId', value);
    }

    function onSexItemSelected(value: number) {
        setFieldValue('sexId', value);
    }

    function onLanguageItemSelected(value: number) {
        setFieldValue('languageId', value);
    }

    const INSZ_MISSING = 'administration.employees.add.steps.personal_data.validate.national_register_number_missing';

    return (
        <>
            { isForeignEmployee ?
                <FloatableTextInputWrapper floatLabel>
                    <DatePicker
                        id={`${formName}-birth-date`}
                        placeholder={translator(
                            ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate,
                        )}
                        value={values.birthDate}
                        name={fields.birthDate}
                        onChange={(value) => setFieldValue('birthDate', value)}
                        maxDate={formatDateForBackend(now())}
                        isInvalid={touched.birthDate && !!errors.birthDate}
                    >
                        <label htmlFor={`${formName}-birth-date`}>
                            <Translate
                                msg={ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate}
                            />
                            <RequiredMarker />
                        </label>
                    </DatePicker>
                        {touched.birthDate && (
                            <FormFieldError
                                error={errors.birthDate}
                                placeholders={{
                                    fieldName: translator(
                                        ADD_EMPLOYEE_PLACEHOLDER_KEYS.birthDate,
                                    ),
                                }}
                            />
                        )}
                </FloatableTextInputWrapper>
            :
                <AddEmployeeFormTextInput
                    baseId={formName}
                    fields={fields}
                    formRenderProps={formRenderProps}
                    name={'nationalRegisterNumber'}
                    disabled={true}
                    baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
                    markAsRequired={true}
                />
            }
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'firstName'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
                markAsRequired={true}
            />
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'name'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
                markAsRequired={true}
            />
            <FloatableTextInputWrapper floatLabel>
                <ConstantsTypeahead
                    id={`${formName}-sex-id`}
                    name={fields.sexId}
                    value={values.sexId}
                    constantType={ConstantType.SEXES}
                    onItemSelected={onSexItemSelected}
                    isInvalid={touched.sexId && !!errors.sexId}
                >
                    <label htmlFor={`${formName}-sex-id`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.sex_id`}
                        />
                        <RequiredMarker />
                    </label>
                </ConstantsTypeahead>
                {touched.sexId &&
                    <FormFieldError
                        error={errors.sexId}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.sex_id`),
                        }}
                    />}
            </FloatableTextInputWrapper>
            <FloatableTextInputWrapper floatLabel>
                <ConstantsTypeahead
                    id={`${formName}-nationality-id`}
                    name={fields.nationalityId}
                    value={values.nationalityId}
                    constantType={ConstantType.NATIONALITIES}
                    onItemSelected={onNationalityItemSelected}
                    isInvalid={touched.nationalityId && !!errors.nationalityId}
                >
                    <label htmlFor={`${formName}-nationality-id`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.nationality_id`}
                        />
                        <RequiredMarker />
                    </label>
                </ConstantsTypeahead>
                {touched.nationalityId &&
                    <FormFieldError
                        error={errors.nationalityId}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.nationality_id`),
                        }}
                    />}
                {(
                                                touched.nationalityId
                                                && values.nationalityId === nationalityIdBelgium
                                                && isForeignEmployee
                                            ) &&
                                                <FormFieldError
                                                    error={{
                                                        message: INSZ_MISSING,
                                                        type: ErrorTypes.Custom,
                                                    }}
                                                />}
            </FloatableTextInputWrapper>
            <FloatableTextInputWrapper floatLabel>
                <ConstantsTypeahead
                    id={`${formName}-language-id`}
                    name={fields.languageId}
                    value={values.languageId}
                    constantType={ConstantType.LANGUAGES}
                    onItemSelected={onLanguageItemSelected}
                    isInvalid={touched.languageId && !!errors.languageId}
                >
                    <label htmlFor={`${formName}-language-id`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.language_id`}
                        />
                        <RequiredMarker />
                    </label>
                </ConstantsTypeahead>
                {touched.languageId &&
                    <FormFieldError
                        error={errors.languageId}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.language_id`),
                        }}
                    />}
            </FloatableTextInputWrapper>
        </>
    );
};

export default AddEmployeeGeneral;
