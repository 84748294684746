import React from 'react';
import FloatableTextInputWrapper from '../../forms/FloatableTextInputWrapper';
import Translate from '../../Translate';
import { IFormRenderProps } from '../../forms/Form';
import { IStartEndHourFilterValues } from '../../../../models/ui/form';
import TimePicker from '../../widget/DateTimePicker/TimePicker';
import Icon from '../../icons/Icon';
import { ErrorTypes } from '../../../../models/general/error';
import FormFieldError from '../../forms/FormFieldError';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';

interface IStartEndHourFilterProps {
    formRenderProps: IFormRenderProps<IStartEndHourFilterValues>;
    translationKeyPrefix: string;
}

export default function StartEndHourFilter(props: IStartEndHourFilterProps) {

    const {
        formRenderProps,
        translationKeyPrefix,
    } = props;

    const { values, errors, setFieldValue, touched } = formRenderProps;

    const hasInvalidPeriodError = errors.endHour && errors.endHour.type === ErrorTypes.InvalidPeriod;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <>
                    <div className="FilterAndSearchPanel__timespan">
                        <FloatableTextInputWrapper inline>
                            <label htmlFor="filter-start-time">
                                <Icon typeName="time" />
                                <Translate msg={`${translationKeyPrefix}.start_hour`} />
                            </label>
                            <TimePicker
                                id="filter-start-time"
                                value={values.startHour}
                                onChange={(formattedTime) => setFieldValue(
                                    'startHour',
                                    formattedTime,
                                )}
                                isInvalid={touched.startHour && (!!errors.startHour || !!hasInvalidPeriodError)}
                            />
                        </FloatableTextInputWrapper>
                        <FloatableTextInputWrapper inline>
                            <label htmlFor="filter-end-time">
                                <Translate msg={`${translationKeyPrefix}.end_hour`} />
                            </label>
                            <TimePicker
                                id="filter-end-time"
                                value={values.endHour}
                                onChange={(formattedTime) => setFieldValue(
                                    'endHour',
                                    formattedTime,
                                )}
                                isInvalid={touched.endHour && !!errors.endHour}
                            />
                        </FloatableTextInputWrapper>
                    </div>
                    {hasInvalidPeriodError ?
                        <FormFieldError
                            error={touched.endHour && errors.endHour}
                        /> : (
                            <>
                                <FormFieldError
                                    error={touched.startHour && errors.startHour}
                                    placeholders={{ fieldName: translator(`${translationKeyPrefix}.start_hour`) }}
                                />
                                <FormFieldError
                                    error={touched.endHour && errors.endHour}
                                    placeholders={{ fieldName: translator(`${translationKeyPrefix}.end_hour`) }}
                                />
                            </>
                        )
                    }
                </>
            )}
        </TranslatorContext.Consumer>
    );
}
