import React, { ReactNode, ReactElement } from 'react';
import './radio-tile-group.scss';
import classNames from 'classnames';
import { IRadiobuttonProps } from '../Radiobutton';

interface IRadioTileGroupProps {
    radiobuttons: ReactElement<IRadiobuttonProps>[];
    error: ReactNode;
    size?: 'small';
}

const CLASS_NAME = 'RadioTileGroup';

const RadioTileGroup = (props: IRadioTileGroupProps) => {
    return (
        <div
            className={classNames(CLASS_NAME, {
                [`${CLASS_NAME}--${props.size}`]: !!props.size,
            })}
        >
            <div className={`${CLASS_NAME}__options`}>
                {props.radiobuttons}
            </div>
            {props.error && (
                <div className={`${CLASS_NAME}__error`}>
                    {props.error}
                </div>
            )}
        </div>
    );
};

export default RadioTileGroup;
