import React, { ReactElement } from 'react';
import './donut-chart.scss';
import ReactMinimalPieChart, { TChartData } from 'react-minimal-pie-chart';
import classNames from 'classnames';
import { ColorHex } from '../../../../models/general/colors';

const CLASS_NAME = 'DonutChart';

export interface IReactMinimalPieChartItem {
    value: number;
    color: ColorHex;
}

interface IDonutChartProps {
    className?: string;
    data: TChartData;
    lineWidth: number;
    startAngle?: number;
    label?: ReactElement<{}>;
}

export default function DonutChart(props: IDonutChartProps) {
    const data = removeItemsWithValueOfZeroFromData(props.data);
    return (
        <div className={classNames(CLASS_NAME, props.className)}>
            {data.length > 0 ? <ReactMinimalPieChart
                className="chart"
                data={data}
                lineWidth={props.lineWidth}
                startAngle={props.startAngle || 270}
            /> : null}
            <div className="label">
                {props.label}
            </div>
        </div>
    );
}

function removeItemsWithValueOfZeroFromData(data: TChartData) {
    return data.filter((item) => item.value > 0);
}
