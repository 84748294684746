import { createEpic, IParallelCallInput } from '..';
import {
    IOnboardingWizardPayload,
    ONBOARDING_WIZARD_STEP_ID,
    IFetchOnboardingSeatsAvailabilitiesPayload,
} from '../../models/onboarding/wizard';
import ROUTE_KEYS from '../../routeKeys';
import {
    triggerOnboardingWizard,
    updateOnboardingWizardEntity,
    fetchOnboardingSeatsAvailabilitiesActions,
    copyOnboardingAvailabilitiesActions,
    confirmOnboardingWizardActions,
} from './actions';
import { getOnboardingWizardStepId, getOnboardingWizardEntity } from './selectors';
import { getOnboardingWizardSteps } from '../../config/navigation/wizardStepsMap';
import { getSelectedCompany } from '../company/selected/selectors';
import {
    fetchSelectedCompanySeatsAndDivisionsActions,
    setCompanyOnboardingVariables,
} from '../company/selected/actions';
import {
    fetchSmallCompanyDetail,
    fetchContacts,
    fetchCompanyMedicalCentersActions,
    updateCompanyActions,
    fetchCompanyHolidaysActions,
} from '../company/info/actions';
import {
    FETCH_ONBOARDING_SEATS_AVAILABILITIES, COPY_ONBOARDING_AVAILABILITIES, CONFIRM_ONBOARDING_WIZARD,
} from './types';
import { CompanyAvailabilityType, ICompanyAvailabilities } from '../../models/admin/companyInfo';
import { fetchEmployeesActions } from '../employee/employees/actions';
import { isEmployeesDataAvailable } from '../employee/employees/selectors';
import { SHOWALL_FETCH_EMPLOYEES_PARAMETERS } from '../../config/administration.config';
import { getRouteKey } from '../location/selectors';
import { navigateTo } from '../location/actions';
import { CompanyOnboardingStatus, TCompanyOnboardingVariables } from '../../models/admin/company';
import { getCurrentYear } from '../../utils/core/date/getSpecificDate';

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validateOnboardingWizardStepIdEpic
createEpic<IOnboardingWizardPayload>({
    onActionType: ROUTE_KEYS.R_ONBOARDING_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const ONBOARDING_STEP_IDS = getOnboardingWizardSteps().stepIds;

        if (!ONBOARDING_STEP_IDS.includes(requestedStep)) {
            return next(triggerOnboardingWizard());
        }

        // check no step skipped
        const currentStep = getOnboardingWizardStepId(getState());
        const currentStepIndex = ONBOARDING_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = ONBOARDING_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(triggerOnboardingWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringOnboardingWizardEpic
createEpic<IOnboardingWizardPayload>({
    onActionType: [ROUTE_KEYS.R_ONBOARDING_NEW, ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL],
    async processMultiple({ action, getState }, dispatch, done) {
        const { step } = action.payload;
        const state = getState();
        const routeKey = getRouteKey(state);
        const entity = getOnboardingWizardEntity(state);

        const company = getSelectedCompany(state);

        if (step === ONBOARDING_WIZARD_STEP_ID.DATA) {
            dispatch(updateCompanyActions.reset({}));
            if (!entity.baseData) {
                dispatch(fetchSmallCompanyDetail({ companyCode: company.companyCode }));
            }

            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.SEAT) {
            dispatch(fetchSelectedCompanySeatsAndDivisionsActions.trigger({}));
            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.CONTACT) {
            const company = getSelectedCompany(state);
            dispatch(fetchContacts({ companyCode: company.companyCode }));

            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.RISK_MGMT) {
            dispatch(fetchOnboardingSeatsAvailabilitiesActions.trigger({
                availabilityType: CompanyAvailabilityType.RiskManagement,
            }));
            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.MEDICAL_EXAMINATION) {
            dispatch(copyOnboardingAvailabilitiesActions.reset({}));
            dispatch(fetchOnboardingSeatsAvailabilitiesActions.trigger({
                availabilityType: CompanyAvailabilityType.MedicalExaminations,
            }));
            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.HOLIDAY) {
            dispatch(fetchCompanyHolidaysActions.reset({}));
            dispatch(fetchCompanyHolidaysActions.trigger({
                companyCode: company.companyCode,
                year: getCurrentYear(),
            }));
            return done();
        }

        if (step === ONBOARDING_WIZARD_STEP_ID.EMPLOYEE || routeKey === ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL) {
            dispatch(confirmOnboardingWizardActions.reset({}));
            if (!isEmployeesDataAvailable(state)) {
                dispatch(fetchEmployeesActions.trigger({
                    ...SHOWALL_FETCH_EMPLOYEES_PARAMETERS,
                    refreshSources: 'true',
                }));
            }
            dispatch(fetchCompanyMedicalCentersActions.trigger({ companyCode: company.companyCode }));

            return done();
        }

        done();
    },
    latest: false,
});

// fetchOnboardingSeatsAvailabilitiesEpic
createEpic<IFetchOnboardingSeatsAvailabilitiesPayload>({
    onActionType: FETCH_ONBOARDING_SEATS_AVAILABILITIES,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const entity = getOnboardingWizardEntity(state);

            const seats = entity.seatsData;
            const availabilityType = action.payload.availabilityType;

            const availabilities: { [companyCode: string]: ICompanyAvailabilities } = {}; /* results of each call will
                                                                                            be added to this object */

            if (seats && seats.length > 0) {
                await Promise.all(
                    seats.map((seat) => fetchSeatAvailabilities(
                        availabilities,
                        seat.company.companyCode,
                        availabilityType,
                        { api },
                    )),
                ).catch(() => {
                    throw Error();
                });
            }

            if (availabilityType === CompanyAvailabilityType.MedicalExaminations) {
                dispatch(updateOnboardingWizardEntity({ medicalExaminationsAvailabilities: availabilities }));
            } else {
                dispatch(updateOnboardingWizardEntity({ riskManagementAvailabilities: availabilities }));
            }

            dispatch(fetchOnboardingSeatsAvailabilitiesActions.succeeded({}));
        } catch (error) {
            dispatch(fetchOnboardingSeatsAvailabilitiesActions.failed(error));
        }

        done();
    },
    latest: false,
});

async function fetchSeatAvailabilities(
    availabilities: {
        [companyCode: string]: ICompanyAvailabilities,
    },
    companyCode: string,
    availabilityType: CompanyAvailabilityType,
    { api }: IParallelCallInput,
) {
    try {
        const result = await api.admin.companyInfo.fetchCompanyAvailabilities({
            availabilityType,
            companyCode,
        });

        availabilities[companyCode] = result;
    } catch (error) {
        throw Error();
    }
}

// copyOnboardingAvailabilitiesEpic
createEpic({
    onActionType: COPY_ONBOARDING_AVAILABILITIES,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const entity = getOnboardingWizardEntity(state);
            const riskMgmtAvailabilities = entity.riskManagementAvailabilities;

            await Promise.all(Object.keys(riskMgmtAvailabilities)
                .map((companyCode: string) => {
                    const availabilities = riskMgmtAvailabilities[companyCode];
                    return updateMedExamAvailabilities(
                        companyCode,
                        availabilities,
                        { api },
                    );
                })).catch(() => {
                    throw Error();
                });

            dispatch(updateOnboardingWizardEntity({
                medicalExaminationsAvailabilities: { ...riskMgmtAvailabilities },
            }));
            dispatch(copyOnboardingAvailabilitiesActions.succeeded({}));
        } catch (error) {
            dispatch(copyOnboardingAvailabilitiesActions.failed(error));
        }
        done();
    },
    latest: false,
});

async function updateMedExamAvailabilities(
    companyCode: string,
    availabilities: ICompanyAvailabilities,
    { api }: IParallelCallInput,
) {
    try {
        await api.admin.companyInfo.replaceCompanyAvailabilities({
            companyCode,
            availabilityType: CompanyAvailabilityType.MedicalExaminations,
            applyFullFamily: false,
            available: availabilities.available,
            availabilities,
        });
    } catch (error) {
        throw Error();
    }
}

// confirmOnboardingWizardEpic
createEpic({
    onActionType: CONFIRM_ONBOARDING_WIZARD,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const company = getSelectedCompany(state);

            await api.admin.companyInfo.confirmOnboardingWizard({ companyCode: company.companyCode });

            /**
             * The confirm is ok >> We change the 'startWizardNewCustomer' flag ourselves
             * otherwise we would have to re-fetch the changed boolean.
             */
            const newOnboardingVariables: TCompanyOnboardingVariables = {
                startWizardNewCustomer: CompanyOnboardingStatus.FALSE,
                statusNotPaid: company.statusNotPaid, // leave unchanged
            };
            dispatch(setCompanyOnboardingVariables({
                companyCode: company.companyCode,
                ...newOnboardingVariables,
            }));

            dispatch(navigateTo(ROUTE_KEYS.R_ONBOARDING_FINISH));
            dispatch(confirmOnboardingWizardActions.succeeded({}));
        } catch (error) {
            dispatch(confirmOnboardingWizardActions.failed(error));
        }
        done();
    },
    latest: false,
});
