import { IAvailabilityTimeBlock, IAvailabilityTimeBlocks } from './availabilitiesMapper';
import { ErrorTypes, IValidationError, ValidationErrors } from '../../../../../models/general/error';
import { ShapeOf } from '../../../../../models/ui/form';

/**
 * These values are also used in translation keys, so don't just change them!
 */
const TIME_BLOCK_VALIDATION_FIELD_NAMES = {
    START_TIME: 'start_time',
    END_TIME: 'end_time',
    DAY: 'day',
};

const VALIDATION_TRANSLATION_PREFIX =
    'administration.company_info.availability_overview.edit.validation';

export interface ITimeBlocksValidationErrors {
    [timeBlockId: number]: ValidationErrors<{}>;
}

export function getFieldNameTranslationKey(blockFieldName: string) {
    return `${VALIDATION_TRANSLATION_PREFIX}.field_name.${blockFieldName}`;
}

export function validateAvailabilityTimeBLocks(
    timeBlocks: IAvailabilityTimeBlocks,
): ITimeBlocksValidationErrors {
    let errors: ShapeOf<IAvailabilityTimeBlocks, ValidationErrors<{}>> =
        {} as ShapeOf<IAvailabilityTimeBlocks, ValidationErrors<{}>>;

    const timeBlocksValuesAsArray: IAvailabilityTimeBlock[] = Object.values(timeBlocks);

    const hasMorningTimeBlock = !noTimeBlockDaysSelected(timeBlocks.morning);
    const hasAfternoonTimeBlock = !noTimeBlockDaysSelected(timeBlocks.afternoon);

    if (!hasMorningTimeBlock && !hasAfternoonTimeBlock) {
        errors[timeBlocks.morning.id] = atLeastOneBlockRequiredError;

    } else if (hasMorningTimeBlock && !hasAfternoonTimeBlock) {
        errors = validateTimeBlockAndAddErrors({
            timeBlock: timeBlocks.morning,
            allTimeBlocks: timeBlocksValuesAsArray,
            errors,
            dontCheckOverlap: true,
        });

    } else if (!hasMorningTimeBlock && hasAfternoonTimeBlock) {
        errors = validateTimeBlockAndAddErrors({
            timeBlock: timeBlocks.afternoon,
            allTimeBlocks: timeBlocksValuesAsArray,
            errors,
            dontCheckOverlap: true,
        });

    } else {
        errors = validateTimeBlockAndAddErrors({
            timeBlock: timeBlocks.morning,
            allTimeBlocks: timeBlocksValuesAsArray,
            errors,
        });
        errors = validateTimeBlockAndAddErrors({
            timeBlock: timeBlocks.afternoon,
            allTimeBlocks: timeBlocksValuesAsArray,
            errors,
        });
    }

    return errors;
}

const atLeastOneBlockRequiredError = {
    [TIME_BLOCK_VALIDATION_FIELD_NAMES.DAY]: {
        type: ErrorTypes.Custom,
        message: `${VALIDATION_TRANSLATION_PREFIX}.at_least_one_block_required`,
    },
};

function validateTimeBlockAndAddErrors(
    props: {
        errors: ShapeOf<IAvailabilityTimeBlocks, ValidationErrors<{}>>,
        timeBlock: IAvailabilityTimeBlock,
        allTimeBlocks: IAvailabilityTimeBlock[],
        dontCheckOverlap?: boolean,
    },
) {
    const validationErrors = validateTimeBlock(props.timeBlock, props.allTimeBlocks, props.dontCheckOverlap);
    if (Object.keys(validationErrors).length > 0) {
        const newErrors = { ...props.errors };
        newErrors[props.timeBlock.id] = validationErrors;
        return newErrors;
    }
    return props.errors;
}

export function noTimeBlockDaysSelected(timeBlock: IAvailabilityTimeBlock) {
    return Object.values(timeBlock.enabledOnDays).every((enabled: boolean) => !enabled);
}

function validateTimeBlock(
    timeBlock: IAvailabilityTimeBlock,
    allTimeBlocks: IAvailabilityTimeBlock[],
    dontCheckOverlap?: boolean,
): ValidationErrors<{}> {
    const timeBlockErrors: { [fieldName: string]: IValidationError } = {};
    let validationError;

    if (validationError = validateStartTime(timeBlock, allTimeBlocks, dontCheckOverlap)) {
        timeBlockErrors[TIME_BLOCK_VALIDATION_FIELD_NAMES.START_TIME] = validationError;
    }
    if (validationError = validateEndTime(timeBlock)) {
        timeBlockErrors[TIME_BLOCK_VALIDATION_FIELD_NAMES.END_TIME] = validationError;
    }
    if (validationError = validateEnabledDays(timeBlock)) {
        timeBlockErrors[TIME_BLOCK_VALIDATION_FIELD_NAMES.DAY] = validationError;
    }

    return timeBlockErrors;
}

function validateStartTime(
    timeBlock: IAvailabilityTimeBlock,
    allTimeBlocks: IAvailabilityTimeBlock[],
    dontCheckOverlap?: boolean,
): IValidationError {
    if (!timeBlock.startTime) {
        return {
            type: ErrorTypes.Required,
            message: null,
        };
    }

    if (!dontCheckOverlap && doesTimeBlockOverlapWithAnother(timeBlock, allTimeBlocks)) {
        return {
            type: ErrorTypes.Custom,
            message: `${VALIDATION_TRANSLATION_PREFIX}.no_time_overlap_allowed`,
        };
    }

    return null;
}

function validateEndTime(timeBlock: IAvailabilityTimeBlock): IValidationError {
    if (!timeBlock.endTime) {
        return {
            type: ErrorTypes.Required,
            message: null,
        };
    }

    if (timeBlock.startTime && timeBlock.startTime >= timeBlock.endTime) {
        return {
            type: ErrorTypes.Custom,
            message: `${VALIDATION_TRANSLATION_PREFIX}.end_time_should_come_after_start_time`,
        };
    }

    return null;
}

function validateEnabledDays(timeBlock: IAvailabilityTimeBlock): IValidationError {
    if (noTimeBlockDaysSelected(timeBlock)) {
        return {
            type: ErrorTypes.SelectAtLeastOne,
            message: null,
        };
    }

    return null;
}

function doesTimeBlockOverlapWithAnother(
    timeBlock: IAvailabilityTimeBlock,
    allTimeBlocks: IAvailabilityTimeBlock[],
): boolean {
    return allTimeBlocks
        .filter((otherTimeBlock) => otherTimeBlock.id !== timeBlock.id)
        .some((otherTimeBlock) =>
            isTimeWithinPeriod(timeBlock.startTime, otherTimeBlock.startTime, otherTimeBlock.endTime),
        );
}

function isTimeWithinPeriod(time: string, periodStart: string, periodEnd: string) {
    if (!time || !periodStart || !periodEnd) {
        return false;
    }

    return time >= periodStart && time < periodEnd;
}
