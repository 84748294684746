import { ListItem, ListColumns, IColumn } from '../../models/general/list';

interface IOptions {
    columnKeysToIgnore?: string[];
    columnsConfig?: ListColumns<{}>;
    acceptCommasAndPointsAsDecimalSeparator?: boolean;
}

export default function filterListItems<Item extends {}>(
    items: Item[],
    filter: string = '',
    options: IOptions = {},
): Item[] {
    const columnKeysToIgnore = getColumnKeysToIgnore(options);

    if (filter !== '') {
        return items.slice()
            .filter((data) => doesItemMatchFilter(
                data, filter, columnKeysToIgnore, options.acceptCommasAndPointsAsDecimalSeparator));
    }
    return items.slice();
}

export function getColumnKeysToIgnore(options: IOptions = {}) {
    const {
        columnKeysToIgnore = ['id'],
        columnsConfig,
    } = options;

    if (columnsConfig) {
        const fieldsToIgnore = Object.keys(columnsConfig).filter((key) => {
            const column = columnsConfig[key] as IColumn<{}>;
            if (column.includeInSearchEvenWhenHidden) {
                return false;
            }
            return column.excludeFromSearch || column.hide;
        });
        columnKeysToIgnore.push(...fieldsToIgnore);
    }

    return columnKeysToIgnore;
}

export function doesItemMatchFilter<Item extends {}>(
    item: Item,
    filter: string = '',
    columnKeysToIgnore: string[],
    acceptCommasAndPointsAsDecimalSeparator?: boolean,
) {
    const objToFilter = isListItem(item) ? item.columns : item;

    return Object.keys(objToFilter)
        .find((key) => {
            if (columnKeysToIgnore.indexOf(key) !== -1) {
                return false;
            }
            let value = objToFilter[key] || '';
            if (typeof value !== 'string') {
                value = value.toString();
            }
            if (acceptCommasAndPointsAsDecimalSeparator) {
                return value.toLowerCase().includes(filter.toLowerCase()) ||
                    value.toLowerCase().includes(filter.replace(',', '.').toLowerCase());
            }
            return value.toLowerCase().includes(filter.toLowerCase());
        });
}

function isListItem(item: ListItem<{}> | {}): item is ListItem<{}> {
    return item['columns'] !== undefined;
}
