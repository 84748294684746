import React from 'react';
import { render } from 'react-dom';

import 'normalize.css';
import 'focus-visible';
import './views/assets/scss/global.scss';
import App from './views/appShell/App';
import registerServiceWorker from './utils/serviceWorker/registerServiceWorker';
import { ROOT_ELEMENT_ID } from './config/dom.config';
import getOrCreateElementInBodyById from './utils/dom/getOrCreateElementInBodyById';
import './utils/libs/react/setupWhyDidYouUpdate';
import { initErrorReporting } from './utils/logging/errorReporter';

initErrorReporting();

render(
    <App />,
    getOrCreateElementInBodyById(ROOT_ELEMENT_ID),
);

registerServiceWorker();
