import React from 'react';
import classNames from 'classnames';

import { translateApiError } from '../../../../utils/error/translateError';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';

import { IErrorPlaceholderProps, IContextProps } from './error-placeholder.type';

import './error-placeholder.scss';

function ErrorPlaceholderComp({
    typeName = 'block',
    apiError,
    translator,
    translationKey,
    children,
    onClick,
    iconName,
    className,
    bffErrorTranslationPrefix = '',
}: IErrorPlaceholderProps & IContextProps) {
    const errorClassNames = classNames('ErrorPlaceholder', className, {
        inline: typeName === 'inline',
        block: typeName === 'block',
        clickable: !!onClick,
    });

    if (apiError && apiError.type === 'bff') {
        const { message } = apiError;

        const translatableError = translationKey
            ? translationKey
            : bffErrorTranslationPrefix
                ? `${bffErrorTranslationPrefix}.${message}`
                : `common.error_placeholder.${message}`;

        return (
            <div className={errorClassNames} onClick={onClick} >
                <Icon typeName={iconName || 'broken-link'} />
                { children
                    ? children
                    : <Translate msg={translatableError || 'common.error_placeholder.label'} />
                }
            </div>
        );
    }

    const message = typeName !== 'inline' && apiError ?
        translateApiError(translator, apiError) :
        // For inline message we show a shorter message
        <Translate msg={translationKey || 'common.error_placeholder.label'} />;

    return (
        <div className={errorClassNames} onClick={onClick} >
            <Icon typeName={iconName || 'broken-link'} />
            {children ? undefined : message}
            {children}
        </div>
    );
}

export default function ErrorPlaceholder(props: IErrorPlaceholderProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <ErrorPlaceholderComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}
