import React, { Component, ReactElement } from 'react';
import { IEmployee } from '../../../../../../models/admin/employee';
import PageHeader from '../../../../../appShell/PageHeader';
import Button from '../../../../../common/buttons/Button';
import OverlayContent from '../../../../../common/modals/OverlayContent';
import Translate, { IPublicProps as ITranslateProps } from '../../../../../common/Translate';
import EmployeeRadioSelectList from '../../shared/EmployeeRadioSelectList';
import ListFooter from '../../../../../common/list/ListFooter';
import { connect } from '../../../../..';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../models/general/redux';
import {
    getExaminationReasonsAsyncInfo,
    getPlanMedicalExaminationWizardReason,
    getValidateEmployeeToPlanAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import Loader from '../../../../../common/waiting/Loader';
import { validateEmployeeToPlanActions } from '../../../../../../redux/medicalExamination/actions';
import ValidateEmployeeToPlanDialog from '../../shared/ValidateEmployeeToPlanDialog';
import FormError from '../../../../../common/forms/FormError';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import {
    getPlanBufferzoneExaminationReasonsForSelectedBufferzone,
} from '../../../../../../redux/intervention/bufferzones/selectors';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.employees_to_plan.search_employee_overlay';

interface ISearchEmployeeToPlanOverlayProps {
    show: boolean;
    onClose: () => void;
    onSelect: (employee: IEmployee) => void;
    resetOnSelect?: boolean;
    overrideTitle?: string;
    overrideText?: string;
    disabledEmployeeIds?: number[];
    disabledEmployeeReason?: string | ReactElement<ITranslateProps>;
    searchInputTooltipTranslationKey?: string;
    enableEmployeeToPlanValidation?: boolean; // default true
    isBufferzonePlanning?: boolean; // default false;
}

interface IPrivateProps {
    examinationReasonsAsyncInfo: IAsyncFieldInfo;
    areThereAllowedExaminationReasons: boolean;
    validateEmployeeToPlanAsyncInfo: IAsyncFieldInfo;
    validateSelectedEmployee: (employeeToPlan: IEmployee, onValidationPassed: () => void) => void;
}

interface IState {
    selectedEmployee: IEmployee;
}

class SearchEmployeeToPlanOverlay extends Component<ISearchEmployeeToPlanOverlayProps & IPrivateProps, IState> {
    constructor(props: ISearchEmployeeToPlanOverlayProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedEmployee: null,
        };

        this.onEmployeeSelected = this.onEmployeeSelected.bind(this);
        this.onClickAddButton = this.onClickAddButton.bind(this);
        this.onConfirmEmployeeHandler = this.onConfirmEmployeeHandler.bind(this);
    }

    public render() {
        const {
            show, onClose, overrideTitle, disabledEmployeeIds, overrideText, disabledEmployeeReason,
            searchInputTooltipTranslationKey, examinationReasonsAsyncInfo, validateEmployeeToPlanAsyncInfo,
            areThereAllowedExaminationReasons, isBufferzonePlanning,
        } = this.props;
        const { selectedEmployee } = this.state;

        const title = overrideTitle
            ? overrideTitle
            : `${TRANSLATION_PREFIX}.title`;

        const showLoader = examinationReasonsAsyncInfo.status === AsyncStatus.Busy
            || validateEmployeeToPlanAsyncInfo.status === AsyncStatus.Busy;

        return (
            <>
                <OverlayContent
                    show={show}
                    onCloseIntent={onClose}
                >
                    <Loader show={showLoader} useFullScreen />
                    <PageHeader
                        title={title}
                        text={overrideText && overrideText}
                    />

                    {examinationReasonsAsyncInfo.error &&
                        <ErrorPlaceholder apiError={examinationReasonsAsyncInfo.error} />
                    }

                    {!areThereAllowedExaminationReasons &&
                        <ErrorPlaceholder
                            translationKey={`${TRANSLATION_PREFIX}.no_allowed_examination_reason_for_this_bufferzone`}
                        />
                    }
                    {areThereAllowedExaminationReasons &&
                        <EmployeeRadioSelectList
                            onSelect={this.onEmployeeSelected}
                            selectedEmployeeId={selectedEmployee ? selectedEmployee.id : null}
                            inOverlay={true}
                            disabledEmployeeIds={disabledEmployeeIds}
                            disabledEmployeeReason={disabledEmployeeReason}
                            searchInputTooltipTranslationKey={searchInputTooltipTranslationKey}
                            showEmployeeConditionTooltips={true}
                            isBufferzonePlanning={isBufferzonePlanning}
                            footer={
                                <ListFooter
                                    right={
                                        <Button
                                            id="search-employee-to-plan-add-button"
                                            typeName="secondary"
                                            onClick={this.onClickAddButton}
                                            disabled={!selectedEmployee}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.add_button`} />
                                        </Button>
                                    }
                                    left={(
                                        <FormError error={validateEmployeeToPlanAsyncInfo.error}/>
                                    )}
                                />
                            }
                        />
                    }
                    {show && <ValidateEmployeeToPlanDialog onConfirm={this.onConfirmEmployeeHandler} />}

                </OverlayContent>
            </>
        );
    }

    private onEmployeeSelected(employee: IEmployee) {
        this.setState({
            selectedEmployee: employee,
        });
    }

    private onClickAddButton() {
        const {
            enableEmployeeToPlanValidation = true,
            validateSelectedEmployee,
        } = this.props;
        const { selectedEmployee } = this.state;

        if (enableEmployeeToPlanValidation) {
            return validateSelectedEmployee(
                selectedEmployee,
                this.onConfirmEmployeeHandler,
            );
        }

        return this.onConfirmEmployeeHandler();
    }

    private onConfirmEmployeeHandler() {
        const { onSelect } = this.props;
        const { selectedEmployee } = this.state;

        onSelect(selectedEmployee);

        if (this.props.resetOnSelect) {
            this.setState({
                selectedEmployee: null,
            });
        }
    }
}

export default connect<IPrivateProps, ISearchEmployeeToPlanOverlayProps>({
    statePropsPerInstance: (state, publicProps) => {
        const { isBufferzonePlanning = false } = publicProps;

        return (state) => {
            return {
                examinationReasonsAsyncInfo: isBufferzonePlanning
                    ? getExaminationReasonsAsyncInfo(state)
                    : {
                        status: AsyncStatus.Success,
                        error: null,
                    },
                areThereAllowedExaminationReasons: isBufferzonePlanning
                    ? getPlanBufferzoneExaminationReasonsForSelectedBufferzone(state).length > 0
                    : true,
                validateEmployeeToPlanAsyncInfo: getValidateEmployeeToPlanAsyncInfo(state),
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            validateSelectedEmployee: (employeeToPlan: IEmployee, onValidationPassed: () => void) => {
                const examinationReason = getPlanMedicalExaminationWizardReason(getState());

                dispatch(validateEmployeeToPlanActions.trigger({
                    employeeToPlan,
                    examinationReason,
                    onValidationPassed,
                }));
            },
        };
    },
})(SearchEmployeeToPlanOverlay);
