import React, { useCallback } from 'react';
import { path } from 'ramda';

import { AsyncStatus } from '../../../../../models/general/redux';
import { createConvocationsActions } from '../../../../../redux/employee/documents/actions';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { formatDateInFullFormat } from '../../../../../utils/formatting/formatDate';
import { formatStartEndTimeOfDatesForDisplay } from '../../../../../utils/formatting/formatTime';
import { getCreateConvocationsAsyncInfo } from '../../../../../redux/employee/documents/selectors';
import { getDate, hoursOffsetFromNow } from '../../../../../utils/core/date/getSpecificDate';
import {
    getSelectedReservedMedicalExaminationPlannedExaminationsForExport,
    getSelectedReservedMedicalExaminationReservedTimeSlots,
} from '../../../../../redux/medicalExamination/selectors';
import { ListItem } from '../../../../../models/general/list';
import { navigateTo } from '../../../../../redux/location/actions';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../config/medicalExamination.config';
import { stringComparerAscending } from '../../../../../utils/list/comparerUtils';
import { TConvocationRequestType } from '../../../../../models/general/documents';
import { connect } from '../../../../index';
import { getIndicatorStatus } from '../../../../common/widget/FreeSlotsIndicator';
import CollapsibleItem from '../../../../common/widget/CollapsibleItem';
import DetailItem from '../../../../common/widget/DetailItem';
import Icon from '../../../../common/icons/Icon';
import ROUTE_KEYS from '../../../../../routeKeys';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import StatusIndicator from '../../../../common/widget/StatusIndicator';
import Translate from '../../../../common/Translate';

import { CLASS_NAME, TRANSLATION_PREFIX } from './ReservedMedicalExaminationDetail.const';
import { getListItemsMemoized } from './ReservedMedicalExaminationDetail.helper';
import {
    IReservedMedicalExaminationDetailListColumnNames,
    IReservedMedicalExaminationDetailPrivateProps,
    TReservedMedicalExaminationDetailProps,
} from './ReservedMedicalExaminationDetail.type';
import {
    PlannedMedicalExaminationsList,
} from './PlannedMedicalExaminationsList/PlannedMedicalExaminationsList.component';
import { ReservedTimeSlotsList } from './ReservedTimeSlotsList/ReservedTimeSlotsList.component';

import './ReservedMedicalExaminationDetail.scss';

const ReservedMedicalExaminationDetailComponent = ({
    detailData: reservationDetail,
    triggerPlanBufferzoneWizardWithSelectedBufferzone,
    plannedMedicalExaminations,
    navigateToEmployeeDetails,
    isDownloadingDocument,
    onDownloadClick,
    plannedMedicalExaminationListItems,
    reservedTimeSlots,
}: TReservedMedicalExaminationDetailProps) => {
    const handlePlanBufferzoneClick = useCallback(
        () => {
          triggerPlanBufferzoneWizardWithSelectedBufferzone(reservationDetail.id);
        },
        [triggerPlanBufferzoneWizardWithSelectedBufferzone, reservationDetail],
    );

    if (!reservationDetail) {
        return null;
    }

    const isPlanningAllowed =
        hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() <=
        getDate(reservationDetail.activity.end as string) && reservationDetail.isPlannable;

    const areReasonsRestricted = reservationDetail.allowedExaminationReasons
        && reservationDetail.allowedExaminationReasons.length > 0;
    const allowedExaminationReasonsInfoTranslationKey = areReasonsRestricted
        ? `${TRANSLATION_PREFIX}.details.allowed_examination_reasons.info`
        : `${TRANSLATION_PREFIX}.details.allowed_examination_reasons.info_all_allowed`;

    return (
        <div className={CLASS_NAME}>
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.details.title`}
                initialOpen={true}
            >
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.location`} /></h6>
                    <span>
                        <span>
                        {reservationDetail.location.name}
                        {reservationDetail.location.address && (
                            <>
                                <br />
                                {formatAddress({
                                    street: reservationDetail.location.address.street,
                                    number: reservationDetail.location.address.houseNumber,
                                    city: reservationDetail.location.address.city,
                                    countryCode: reservationDetail.location.address.countryCode,
                                    box: reservationDetail.location.address.box,
                                    zipCodeId: reservationDetail.location.address.zipCode,
                                    postcode: reservationDetail.location.address.zipCode,
                                }, true)}
                            </>
                        )}
                    </span>
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.seat`} /></h6>
                    <span>
                        {`${reservationDetail.branch.name} (${reservationDetail.branch.code})`}
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.date`} /></h6>
                    <span>{formatDateInFullFormat(reservationDetail.activity.start)}</span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.start_and_end_hour`} /></h6>
                    <span>
                        {formatStartEndTimeOfDatesForDisplay(
                            reservationDetail.activity.start,
                            reservationDetail.activity.end,
                        )}
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.availability.title`} /></h6>
                    <div className={`${CLASS_NAME}__availability`}>
                        <span>
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.details.availability.text`}
                                placeholders={{
                                    freeSlots: <strong>{reservationDetail.availability.plannableTimeSlots}</strong>,
                                    totalSlots: reservationDetail.availability.totalTimeSlots,
                                }}
                            />
                        </span>
                        <StatusIndicator
                            value={
                                getIndicatorStatus(
                                    reservationDetail.availability.plannableTimeSlots,
                                    reservationDetail.availability.totalTimeSlots,
                                ).statusIndicatorValue
                            }
                            className={`${CLASS_NAME}__status-indicator`}
                        />
                    </div>
                    {isPlanningAllowed &&
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            <div className={`${CLASS_NAME}__availability__plan-bufferzone`}>
                                <a onClick={handlePlanBufferzoneClick}>
                                    <Icon typeName="calendar" circle />
                                    <span>
                                        <Translate msg={`${TRANSLATION_PREFIX}.details.availability.plan_bufferzone`} />
                                    </span>
                                </a>
                            </div>
                        </ShowIfAllowed>
                    }
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.allowed_examination_reasons.title`} /></h6>
                    <div className={`${CLASS_NAME}__allowed_examination_reasons`}>
                        <span className="info-text">
                            <Translate msg={allowedExaminationReasonsInfoTranslationKey} />
                        </span>
                        {areReasonsRestricted &&
                            <ul className="reasons">
                                {reservationDetail.allowedExaminationReasons
                                    .sort((reasonA, reasonB) =>
                                        stringComparerAscending(reasonA.description, reasonB.description))
                                    .map((allowedExaminationReason) => (
                                        // eslint-disable-next-line max-len
                                        <li key={`allowedExaminationReason_${allowedExaminationReason.code}_${allowedExaminationReason.description}`}>
                                            {allowedExaminationReason.description}
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </div>
                </DetailItem>
            </CollapsibleItem>
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.planned.title`}
                initialOpen={true}
            >
                <PlannedMedicalExaminationsList
                    plannedMedicalExaminations={plannedMedicalExaminations}
                    plannedMedicalExaminationListItems={plannedMedicalExaminationListItems}
                    isDownloadingDocument={isDownloadingDocument}
                    navigateToEmployeeDetails={navigateToEmployeeDetails}
                    onDownloadClick={onDownloadClick}
                    reservedMedicalExaminationId={reservationDetail.id}
                />
            </CollapsibleItem>
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.reserved.title`}
                initialOpen={true}
            >
                <ReservedTimeSlotsList timeSlots={reservedTimeSlots} />

                {(isPlanningAllowed && reservedTimeSlots.length > 0) &&
                    <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                        <div className={`${CLASS_NAME}__availability__plan-bufferzone`}>
                            <a onClick={handlePlanBufferzoneClick}>
                                <Icon typeName="calendar" circle />
                                <span>
                                    <Translate msg={`${TRANSLATION_PREFIX}.details.availability.plan_bufferzone`} />
                                </span>
                            </a>
                        </div>
                    </ShowIfAllowed>
                }
            </CollapsibleItem>
        </div>
    );
};

export const ReservedMedicalExaminationDetail = connect<IReservedMedicalExaminationDetailPrivateProps>({
    stateProps: (state) => {
        return {
            plannedMedicalExaminations: getSelectedReservedMedicalExaminationPlannedExaminationsForExport(state),
            plannedMedicalExaminationListItems: getListItemsMemoized(state),
            isDownloadingDocument: getCreateConvocationsAsyncInfo(state).status === AsyncStatus.Busy,
            reservedTimeSlots: getSelectedReservedMedicalExaminationReservedTimeSlots(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            navigateToEmployeeDetails: (reservedMedicalExaminationId: string, idOfEmployee: number) => {
                dispatch(navigateTo(
                    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
                    {
                        reservedMedicalExaminationId,
                        id: idOfEmployee,
                    },
                ));
            },
            onDownloadClick: (item: ListItem<IReservedMedicalExaminationDetailListColumnNames>) => {
                const employmentId = path<number>(['columns', 'employmentId'], item);
                const planningRequestMsId = path<number>(['columns', 'planningRequestId'], item);
                const timeSlotId = path<number>(['columns', 'timeSlotId'], item);

                dispatch(createConvocationsActions.trigger({
                    convocations: [{
                        requestType: 'Letter' as TConvocationRequestType,
                        timeSlotId,
                        ...!!planningRequestMsId && {
                            planningRequestMsId,
                        },
                        ...!!(!planningRequestMsId && employmentId) && {
                            employmentId,
                        },
                    }],
                }));
            },
        };
    },
})(ReservedMedicalExaminationDetailComponent);
