import { ICreateCompanyVisitRequest } from '../../../../../models/interventions/company-visits';
import { SubmittedFormActionType } from '../../../../../config/analytics.config';
import { createEpic } from '../../../../index';
import { getSelectedSeatCompanyCode } from '../../../selected/selectors';
import { createCompanyVisitRequestActions } from '../../actions';
import { CREATE_COMPANY_VISITS_REQUEST } from '../../types';

createEpic<ICreateCompanyVisitRequest>({
    onActionType: CREATE_COMPANY_VISITS_REQUEST,
    async processReturn({ action, getState, api }) {
        try {
            const companyCode = getSelectedSeatCompanyCode(getState());

            await api.interventions.companyVisits.createCompanyVisitRequest(companyCode, action.payload);

            return createCompanyVisitRequestActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.COMPANY_VISIT_REQUESTED,
                },
            );
        } catch (error) {
            return createCompanyVisitRequestActions.failed(error);
        }
    },
    latest: false,
});
