import React from 'react';

import Translate from '../../../../../common/Translate';
import LinkToRoute from '../../../../../common/navigation/LinkToRoute';
import Icon from '../../../../../common/icons/Icon';
import { AGENDA_ROUTE } from '../../AgendaDashboardBlock.const';

const MiniAgendaHeader = () => {
    return (
        <header className="header">
            <div>
                <h4><Translate msg="home.dashboard.agenda.title" /></h4>
                <LinkToRoute
                    id="to-agenda-overview"
                    className="icon-link"
                    to={AGENDA_ROUTE}
                >
                    <span><Translate msg="home.dashboard.agenda.link_overview" /></span>
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </div>
        </header>
    );
};

export { MiniAgendaHeader };
