import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType, registerReducer } from '../index';
import { INotification } from '../../models/general/notification';
import { ADD_NOTIFICATION, DISMISS_NOTIFICATIONS } from './types';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.NOTIFICATIONS;

export interface IReducerState {
    notifications: INotification[];
}

const initialState: IReducerState = {
    notifications: [],
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(ADD_NOTIFICATION)
        .onTrigger<INotification>(({ oldState, payload }) => {
            const newNotifications = [...oldState.notifications];

            const notificationIndex = newNotifications.map((item) => item.id).indexOf(payload.id);
            if (notificationIndex === -1) {
                newNotifications.push(payload);
            } else {
                // Overwrite it when a notification with the same id exists
                newNotifications[notificationIndex] = payload;
            }

            return {
                ...oldState,
                notifications: newNotifications,
            };
        })
        .create(),
    ...createActionHandlersForType<IReducerState>(DISMISS_NOTIFICATIONS)
        .onTrigger<{ notificationIds: string[] }>(({ oldState, payload }) => {
            const newNotifications = [...oldState.notifications];

            payload.notificationIds
                .forEach((notificationId) => {
                    const notificationIndex = newNotifications.map((item) => item.id).indexOf(notificationId);
                    if (notificationIndex !== -1) {
                        newNotifications.splice(notificationIndex, 1);
                    }
                });

            return {
                ...oldState,
                notifications: newNotifications,
            };
        })
        .create(),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
