import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './dialog.scss';
import classNames from 'classnames';
import { isKeyPressed } from '../../../../utils/keyboard';
import OverlayCurtain from '../OverlayCurtain';
import CloseIcon from '../../icons/CloseIcon';
import Translate, { TPlaceholders } from '../../Translate';
import Icon from '../../icons/Icon';
import ContainFocus from '../../technical/ContainFocus';
import DialogHeader, { IDialogHeaderProps } from './DialogHeader';
import { ROOT_ELEMENT_ID } from '../../../../config/dom.config';
import { isExtraSmallScreen } from '../../../../utils/dom/screenSize';
import Loader from '../../waiting/Loader';
import Button from '../../buttons/Button';
import debounce, { TDebounced } from '../../../../utils/core/debounce';

const CLASS_NAME = 'Dialog';

export const DIALOG_CLASSES = {
    COMPONENT: CLASS_NAME,
    SCROLL_CONTAINER: 'DialogBox__content__children',
};

export interface IDialogProps {
    show: boolean;
    onCloseIntent: () => void;
    header?: string | IDialogHeaderProps;
    headerPlaceholders?: TPlaceholders;
    footer?: ReactNode;
    type?: 'error' | 'success';
    className?: string;
    disableClosing?: boolean;
    height?: number;
    children?: ReactNode;
    showAtTop?: boolean;
    showLoader?: boolean;
    transparent?: boolean;
    borderless?: boolean;
    ignoreTranslationContext?: boolean;
    size?: 'default' | 'large' | 'full-width';
    showScrollToTopOfModalButton?: boolean;
}

interface IDialogState {
    isAlreadyOpened: boolean;
    showToTopButton: boolean;
}

class Dialog extends PureComponent<IDialogProps, IDialogState> {
    private onScrollDebounced: TDebounced;
    private scrollContainer: HTMLDivElement;

    constructor(props: IDialogProps) {
        super(props);
        this.state = {
            isAlreadyOpened: props.show,
            showToTopButton: false,
        };

        this.triggerOnCloseIntent = this.triggerOnCloseIntent.bind(this);
        this.scrollToTopOfModal = this.scrollToTopOfModal.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollDebounced = debounce(this.onScroll, 10); // this.onScroll.bind(this);

        // Close on escape key pressed
        this.onKeyUp = this.onKeyUp.bind(this);
        if (document) {
            document.addEventListener('keyup', this.onKeyUp);
        }
    }

    public componentDidMount() {
        this.updateBodyElementOverflow(this.props.show);
    }

    public componentDidUpdate(prevProps: IDialogProps) {
        if (this.props.show && !prevProps.show) {
            this.updateBodyElementOverflow(true);
        } else if (prevProps.show && !this.props.show) {
            this.updateBodyElementOverflow(false);
        }
    }

    public UNSAFE_componentWillUpdate(nextProps: IDialogProps) {
        if (nextProps.show !== this.props.show) {
            this.setState({
                isAlreadyOpened: this.state.isAlreadyOpened || nextProps.show,
            });
        }
    }

    public componentWillUnmount() {
        if (document) {
            document.removeEventListener('keyup', this.onKeyUp);
        }
        this.updateBodyElementOverflow(false);
        this.onScrollDebounced.cancel();
    }

    public render() {
        const { isAlreadyOpened, showToTopButton } = this.state;
        const {
            children, show, header, footer, type, size, className,
            disableClosing, height, showAtTop, showLoader, headerPlaceholders,
            transparent, borderless, ignoreTranslationContext,
            showScrollToTopOfModalButton,
        } = this.props;
        const hasDialogHeaderComponent = header && typeof header !== 'string';
        const dialogHeaderColor = hasDialogHeaderComponent ? (header as IDialogHeaderProps).color : '';

        const dialogClass = classNames(DIALOG_CLASSES.COMPONENT, {
            error: type === 'error',
            success: type === 'success',
            'Dialog--full-width': size && size === 'full-width',
            'Dialog--large': size && size === 'large',
            'Dialog--with-dialog-header': !!hasDialogHeaderComponent,
            [`DialogHeader--${dialogHeaderColor}`]: !!hasDialogHeaderComponent && !!dialogHeaderColor,
            'Dialog--with-show-loader': showLoader,
            'Dialog--transparent': transparent,
            'Dialog--borderless': borderless,
        });

        const dialogInlineStyle: CSSProperties = {
            height,
            // Prevent animation on the initial render when it's hidden
            display: !isAlreadyOpened ? 'none' : undefined,
        };

        return (
            <OverlayCurtain
                show={show}
                fadeEffect="blur"
                onClick={!disableClosing && this.triggerOnCloseIntent}
                animationEffect="scale"
                showAtTop={showAtTop}
            >
                <ContainFocus hasFocus={show} priority={3}>
                    <section
                        className={classNames(dialogClass, className)}
                        style={dialogInlineStyle}
                        data-scroll-container={true}
                    >
                        {!disableClosing && <CloseIcon onClick={this.triggerOnCloseIntent} />}
                        <div className="DialogBox">
                            <Loader show={showLoader}>
                                {!showLoader && (
                                    <>
                                        <div className="DialogBox__content">
                                            {header && (
                                                typeof header === 'string' ? (
                                                    <header className="DialogBox__content__header">
                                                        {type && type === 'error' &&
                                                            <Icon typeName="warning" circle />
                                                        }
                                                        {type && type === 'success' &&
                                                            <Icon typeName="check" circle />
                                                        }
                                                        <h3>
                                                            <Translate
                                                                msg={header}
                                                                placeholders={headerPlaceholders}
                                                                ignoreContext={ignoreTranslationContext}
                                                            />
                                                        </h3>
                                                    </header>
                                                ) : this.renderDialogHeader()
                                            )}
                                            <div
                                                className={DIALOG_CLASSES.SCROLL_CONTAINER}
                                                ref={(ref) => this.scrollContainer = ref}
                                                onScroll={showScrollToTopOfModalButton && this.onScrollDebounced}
                                            >
                                                {children}
                                                {showScrollToTopOfModalButton &&
                                                    <Button
                                                        className={classNames('DialogBox__content__back-to-top', {
                                                            visible: !!showToTopButton,
                                                        })}
                                                        id="dialog-back-to-top"
                                                        typeName="secondary"
                                                        onClick={this.scrollToTopOfModal}
                                                    >
                                                        <Icon typeName="arrow-up" />
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        {footer &&
                                            <div className="DialogBox__footer">
                                                {footer}
                                            </div>
                                        }
                                    </>
                                )}
                            </Loader>
                        </div>
                    </section>
                </ContainFocus>
            </OverlayCurtain>
        );
    }

    private onKeyUp(e: KeyboardEvent) {
        if (isKeyPressed(e, 'Escape') && this.props.show && !this.props.disableClosing) {
            e.preventDefault();
            this.triggerOnCloseIntent();
        }
    }

    private triggerOnCloseIntent() {
        const { onCloseIntent } = this.props;
        if (typeof onCloseIntent === 'function') {
            onCloseIntent();
        }
    }

    private updateBodyElementOverflow(disableOverflow: boolean) {
        if (disableOverflow) {
            document.body.style.overflow = 'hidden';
            if (isExtraSmallScreen()) {
                document.getElementById(ROOT_ELEMENT_ID).style.overflow = 'hidden';
            }
        } else {
            document.body.style.overflow = '';
            document.getElementById(ROOT_ELEMENT_ID).style.overflow = '';
        }
    }

    private renderDialogHeader() {
        const { children, ...otherProps } = this.props.header as IDialogHeaderProps;
        return (
            <DialogHeader {...otherProps}>
                {children}
            </DialogHeader>
        );
    }

    private scrollToTopOfModal() {
        const modal = ReactDOM.findDOMNode(this);

        if (modal instanceof HTMLElement) {
            const modalScrollContainer = modal.querySelector(`.${DIALOG_CLASSES.SCROLL_CONTAINER}`);

            if (modalScrollContainer) {
                modalScrollContainer.scrollTop = 0;
            }
        }
    }

    private onScroll() {
        if (this.scrollContainer) {
            this.setState({
                showToTopButton: this.scrollContainer.scrollTop > 100,
            });
        }
    }
}

export default Dialog;
