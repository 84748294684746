import createAuth0Client, { Auth0ClientOptions, IdToken } from '@auth0/auth0-spa-js';

import { AUTH0_APPLICATION_CODE, AUTH0_APPLICATION_NAME } from '../../config/auth0.config';
import { IAuth0TokensPayLoad, IRedirectTo } from '../../models/auth/auth0';
import { reportError } from '../../utils/logging/errorReporter';
import UnverifiedEmailError from '../../utils/error/UnverifiedEmailError';

import { fetchEnvironmentConfig } from './env.api';

/* eslint-disable camelcase,@typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
const scopes = [
    'openid',
    'profile',
    'email',
    'read:ecia_access',
    'write:ecia_url',
    'write:employeeemail',
    'read:employeedocument',
    'write:employeeprofile',
    'read:employeeprofile',
    'write:employment',
    'write:planning',
    'read:planning',
    'read:pe_dashboard',
    'read:pe_task',
    'read:customeruser',
    'write:customeruser',
    'read:vaccination',
    'read:employeeexamination',
];

const scope = scopes.join(' ');

interface IClientProps {
    language?: 'nl' | 'fr';
    initialScreen?: 'login' | 'signUp' | 'forgotPassword';
    redirectUri?: string;
    registrationCode?: string;
    email?: string;
}

interface ILogoutProps {
    returnTo: string;
}

const initAuth0 = async (options: IClientProps) => {
    const config = await fetchEnvironmentConfig();

    const clientOptions: Auth0ClientOptions = {
        domain: config.auth0.domain,
        client_id: config.auth0.clientID,
        audience: config.auth0.audience,
        scope,
        redirect_uri: options.redirectUri,
        language: options.language,
        initialScreen: options.initialScreen,
        registrationCode: options.registrationCode,
        login_hint: options.email,
        appName: AUTH0_APPLICATION_NAME,
        appCode: AUTH0_APPLICATION_CODE,
        // Always prompt login to prevent getting stuck on MFA page without being able to switch accounts
        // prompt: 'login',
    };

    return createAuth0Client(clientOptions);
};

export const redirectToAuth0 = async (options: IClientProps) => {
    try {
        const client = await initAuth0(options);
        await client.loginWithRedirect();
    } catch (error) {
        console.error(error);
    }
};

export const getTokensFromRedirect = async (options: IClientProps): Promise<IAuth0TokensPayLoad> => {
    try {
        const client = await initAuth0(options);
        await client.handleRedirectCallback();
        const accessToken = await client.getTokenSilently();
        const idToken = await client.getIdTokenClaims();

        return {
            accessToken,
            idToken,
        };
    } catch (error) {
        console.error(error);

        if ((error.error === 'unauthorized' || error.error === 'access_denied')
            && error.error_description === 'Please verify your email before logging in.') {
            throw new UnverifiedEmailError();
        }

        // Report error to sentry
        reportError(error);

        throw new Error('Could not fetch user from idToken');
    }
};

export const renewTokens = async (options: IClientProps): Promise<IAuth0TokensPayLoad> => {
    try {
        const client = await initAuth0(options);

        const accessToken = await client.getTokenSilently();
        const idToken = await client.getIdTokenClaims();

        return {
            accessToken,
            idToken,
        };
    } catch (error) {
        console.error(error);

        throw new Error('Could not fetch accessToken');
    }
};

export const logout = async (options: ILogoutProps) => {
    try {
        const client = await initAuth0({});
        await client.logout({ returnTo: options.returnTo });
    } catch (error) {
        console.error(error);
    }
};

export const getSessionDataFromIdToken = (
    idToken: IdToken,
    accessToken: string,
    redirectTo?: IRedirectTo,
) => {
    return {
        session: {
            accessToken,
            idToken: idToken.__raw,
            expiresAt: idToken.exp,
        },
        profile: {
            updatedAt: idToken.updated_at,
            name: idToken.nickname,
        },
        redirectTo,
    };
};
