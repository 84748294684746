import React, { MouseEvent, ReactNode } from 'react';
import Translate from '../../Translate';
import ListItemButton from '../ListItemButton';

interface IActiveFilterButtonProps {
    value?: ReactNode;
    translationKey?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export default function ActiveFilterButton(props: IActiveFilterButtonProps) {

    const isEmployeeConditionFilter =
        props.translationKey === 'administration.employees.filter_buttons.condition_filter';

    const isMoreThanOneValue = (value) => {
        return (
            value.toString().includes(';')
        );
    };


    const renderValueTranslationKey = (value) => {
        if (!!value && isEmployeeConditionFilter && isMoreThanOneValue(value)) {
            return (
                <Translate
                    msg={`${props.translationKey}_values.multiple_conditions`}
                />
            );
        } if (!!value && isEmployeeConditionFilter) {
            return (
                <Translate msg={`${props.translationKey}_values.${value}`} />
            );
        } return value;
    };

    return (
        <ListItemButton
            id={`active-filter-button-${props.translationKey || ''}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <span>
                {props.translationKey &&
                    <Translate msg={props.translationKey} />
                }
                {props.translationKey && props.value &&
                    ': '
                }
                {
                    renderValueTranslationKey(props.value)
                }
            </span>
        </ListItemButton>
    );
}
