import React, { PureComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import './validate.scss';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { connect } from '../../../../../index';
import PageHeader from '../../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import LabelInfo from '../../../../../common/widget/LabelInfo';
import ResultAfterDoAction from '../../../../../common/widget/ResultAfterDoAction';
import ValidateInfo from '../../../../../common/widget/ValidateInfo';
import {
    IReintegrationManualEntity, IRequestReIntegrationPayload,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    getPlanMedicalExaminationWizardEntity, getCreateReIntegrationRequestAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button/index';
import UploadedFile from '../../../../../common/widget/UploadedFile';
import { saveAs } from 'file-saver/FileSaver';
import convertDataUrlToBlob from '../../../../../../utils/file/convertDataUrlToBlob';
import { createReIntegrationRequestActions } from '../../../../../../redux/medicalExamination/actions';
import createFileFromBase64 from '../../../../../../utils/file/createFileFromBase64';
import { getContactTypeDescription } from '../../../../../../config/constants';
import { getWizardExitLinkActionMemoized } from '../../../../../../redux/ui/history/selectors';

const CLASS_NAME = 'ReIntegration__Validate';
const EMPTY_PLACEHOLDER = '-';

interface IPrivateProps {
    wizardEntity: IReintegrationManualEntity;
    onCreateRequest: () => void;
    resetAction: () => void;
    navigateToAfterSuccessRoute: () => void;
}

class Validate extends PureComponent<IStepperStepRenderProps & IPrivateProps> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.closeCreationSuccessDialog = this.closeCreationSuccessDialog.bind(this);
        this.closeCreationErrorDialog = this.closeCreationErrorDialog.bind(this);
        this.onDownloadCertificate = this.onDownloadCertificate.bind(this);
    }

    public render() {
        const {
            renderStepButtons,
            wizardEntity,
            onCreateRequest,
        } = this.props;

        const certificate = createFileFromBase64(wizardEntity.certificate.data, wizardEntity.certificate.filename);

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.re_integration_validate.title"
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                    <ResultAfterDoAction
                        doActionAsyncInfoSelector={getCreateReIntegrationRequestAsyncInfo}
                        successConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.medical_examinations.new.steps.re_integration_validate.create_result.success.title',
                            // eslint-disable-next-line max-len
                            infoTranslationKey: 'interventions.medical_examinations.new.steps.re_integration_validate.create_result.success.text',
                            onConfirm: this.closeCreationSuccessDialog,
                        }}
                        errorConfig={{
                            // eslint-disable-next-line max-len
                            titleTranslationKey: 'interventions.medical_examinations.new.steps.re_integration_validate.create_result.error.title',
                            onConfirm: this.closeCreationErrorDialog,
                        }}
                    >
                        <p>
                            <Translate
                                // eslint-disable-next-line max-len
                                msg="interventions.medical_examinations.new.steps.re_integration_validate.subtitle"
                                placeholders={{
                                    person: formatPersonName(wizardEntity.searchEmployee.selectedEmployee),
                                }}
                            />
                        </p>
                        <Button
                            id="create-request-button"
                            typeName="secondary"
                            onClick={onCreateRequest}
                        >
                            <Translate
                                msg="interventions.medical_examinations.new.steps.re_integration_validate.finish_button"
                            />
                        </Button>
                        <h2>
                            <Translate
                                // eslint-disable-next-line max-len
                                msg="interventions.medical_examinations.new.steps.re_integration_validate.fields.title"
                                placeholders={{
                                    person: formatPersonName(wizardEntity.searchEmployee.selectedEmployee),
                                }}
                            />
                        </h2>
                        <ValidateInfo>
                            <h3>
                                <Translate
                                    // eslint-disable-next-line max-len
                                    msg="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.title"
                                />
                            </h3>
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.name"
                                info={formatPersonName(wizardEntity.searchEmployee.selectedEmployee)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.insz"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.searchEmployee.selectedEmployee.nationalRegisterNumber || EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.function"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.searchEmployee.selectedEmployee.function.description || EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.seat"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.searchEmployee.selectedEmployee.company.name || EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.employee.impacity"
                                // eslint-disable-next-line max-len
                                info={
                                    <div onClick={(e) => this.onDownloadCertificate(e, certificate)}>
                                        <UploadedFile
                                            key={wizardEntity.certificate.filename}
                                            file={certificate}
                                        />
                                    </div>}
                            />
                            <h3>
                                <Translate
                                    // eslint-disable-next-line max-len
                                    msg="interventions.medical_examinations.new.steps.re_integration_validate.fields.contact_person.title"
                                />
                            </h3>
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.contact_person.name"
                                info={formatPersonName(wizardEntity.contact)}
                                isInfoMultiLine={true}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.contact_person.function"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.contact.typeId ? getContactTypeDescription(wizardEntity.contact.typeId) : EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.contact_person.phone"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.contact.phone || EMPTY_PLACEHOLDER}
                            />
                            <LabelInfo
                                // eslint-disable-next-line max-len
                                labelKey="interventions.medical_examinations.new.steps.re_integration_validate.fields.contact_person.email"
                                // eslint-disable-next-line max-len
                                info={wizardEntity.contact.email || EMPTY_PLACEHOLDER}
                            />
                        </ValidateInfo>
                        <div className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    // eslint-disable-next-line max-len
                                    translationKey: 'interventions.medical_examinations.new.steps.re_integration_validate.finish_button',
                                    onClick: onCreateRequest,
                                },
                            })}
                        </div>
                    </ResultAfterDoAction>
                </div>
            </>
        );
    }

    private onDownloadCertificate(e: MouseEvent<HTMLElement>, certificate: File) {
        e.preventDefault();
        e.stopPropagation();

        const blob = convertDataUrlToBlob(this.props.wizardEntity.certificate.data);
        saveAs(blob, this.props.wizardEntity.certificate.filename);
    }

    private closeCreationSuccessDialog() {
        this.props.resetAction();
        this.props.navigateToAfterSuccessRoute();
    }

    private closeCreationErrorDialog() {
        this.props.resetAction();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            // eslint-disable-next-line max-len
            wizardEntity: getPlanMedicalExaminationWizardEntity<IReintegrationManualEntity>(state) as IReintegrationManualEntity,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onCreateRequest: () => {
                const wizardEntity =
                    // eslint-disable-next-line max-len
                    getPlanMedicalExaminationWizardEntity<IReintegrationManualEntity>(getState()) as IReintegrationManualEntity;

                dispatch(createReIntegrationRequestActions.trigger(
                    mapWizardEntityToRequestPayload(wizardEntity),
                ));
            },
            resetAction: () => {
                dispatch(createReIntegrationRequestActions.reset({}));
            },
            navigateToAfterSuccessRoute: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
            },
        };
    },
})(Validate);

function mapWizardEntityToRequestPayload(wizardEntity: IReintegrationManualEntity): IRequestReIntegrationPayload {
    return {
        id: wizardEntity.searchEmployee.selectedEmployee.id,
        contact: wizardEntity.contact,
        employee: wizardEntity.searchEmployee.selectedEmployee,
        startDate: wizardEntity.startDate,
        attachmentBodyBase64: wizardEntity.certificate.data,
        attachmentFileName: wizardEntity.certificate.filename,
    };
}
