import React from 'react';
import './flash-messages.scss';
import FlashMessage, { TDismissFlash } from './FlashMessage';
import { connect } from '../../index';
import { FLASH_MESSAGES_CONTAINER_ELEMENT_ID } from '../../../config/dom.config';
import { IFlashMessage } from '../../../models/general/flashMessages';
import { getFlashMessages } from '../../../redux/flashMessages/selectors';
import { dismissFlashMessage } from '../../../redux/flashMessages/actions';
import getOrCreateElementInBodyById from '../../../utils/dom/getOrCreateElementInBodyById';
import Portal from '../../common/technical/Portal';

const CLASS_NAME = 'FlashMessages';
const FLASH_MESSAGES_CONTAINER_ELEMENT = getOrCreateElementInBodyById(FLASH_MESSAGES_CONTAINER_ELEMENT_ID);

interface IPrivateProps {
    flashes: IFlashMessage<{}>[];
    dismissFlash: TDismissFlash;
}

function FlashMessages(props: IPrivateProps) {
    const { flashes, dismissFlash } = props;

    if (!flashes || flashes.length === 0) {
        return null;
    }

    return (
        <Portal domNode={FLASH_MESSAGES_CONTAINER_ELEMENT}>
            <div className={CLASS_NAME}>
                {flashes
                    .map((flash) => {
                        return (
                            <FlashMessage
                                key={`flash-message_${flash.type}`}
                                flash={flash}
                                dismissFlash={dismissFlash}
                            />
                        );
                    })
                }
            </div>
        </Portal>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        flashes: getFlashMessages(state),
    }),
    dispatchProps: (dispatch) => ({
        dismissFlash: (flash: IFlashMessage<{}>) => {
            dispatch(dismissFlashMessage(flash));
        },
    }),
})(FlashMessages);
