import React, { PureComponent } from 'react';
import { clone } from 'ramda';

import {
    DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS,
    MINMAX_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../api/interventions/medicalExaminations.api';
import { formatDateForDisplay, formatDateInLongFormat } from '../../../utils/formatting/formatDate';
import { formatPersonNameFormal } from '../../../utils/formatting/formatPerson';
import {
    getExaminationReasonsAsyncInfo,
    getMedicalExaminationsToPlan,
    getMedicalExaminationsToPlanAsyncInfo,
    getMedicalExaminationToPlanFromList,
    getPeriodicHealthAssessmentReason,
    getSkipToMedicalExaminationWizardStepAsyncInfo,
    getWizardTypeFromExaminationReasonId,
} from '../../../redux/medicalExamination/selectors';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import {
    IExaminationReason,
    IMedicalExaminationToPlan,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../models/interventions/medicalExaminations';
import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../models/general/list';
import { isBeforeOrEqual } from '../../../utils/core/date/isBeforeOrEqual';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { ITranslator } from '../../../models/general/i18n';
import {
    navigateToPlanMedicalExaminationWizardStep,
    resetPlanMedicalExaminationWizardEntity,
    skipToPlanMedicalExaminationWizardStepActions,
} from '../../../redux/medicalExamination/actions';
import { separateStringList } from '../../../utils/core/string/separatedStringList';
import ROUTE_KEYS from '../../../routeKeys';
import { connect } from '../../index';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IClientSideFilterOfListDataProps,
    IRenderFilterContentProps,
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
} from '../../common/widget/MasterWithDetail/typings';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import Button from '../../common/buttons/Button';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
// eslint-disable-next-line max-len
import getUniqueTypeaheadFilterValuesFromListItems from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import Icon from '../../common/icons/Icon';
import ListItemActions from '../../common/list/ListItemActions';
import ListWithSorting from '../../common/list/ListWithSorting';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import TextInput from '../../common/input/TextInput';
import TinyLoader from '../../common/waiting/TinyLoader';
import Translate from '../../common/Translate';

import { IMedicalExaminationsTemplateRenderProps } from './template';

import './medical-examinations.scss';

interface IPrivateProps {
    goToAutomaticPlanning: () => void;
    triggerWizardWithSelectedEmployee: (id: number) => void;
    medicalExaminationsToPlan: IMedicalExaminationToPlan[];
    translator: ITranslator;
}

type TToPlanListItem = ListItem<IColumnNames, number, IMedicalExaminationToPlan>;
type TToPlanMedicalExaminationsListProps = IRenderMasterContentProps<TToPlanListItem[], IFilterValues>;

interface IToPlanProps {
    renderProps: IMedicalExaminationsTemplateRenderProps;
}

interface IColumnNames {
    name: string;
    function: string;
    seat: string;
    deadline: string;
    deadlineSort: string;
    examinationReason: string;
    examinationReasonId: number;
    toBePlanned: string;
    actions: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    reasonIds: string;
    toBePlanned: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="interventions.medical_examinations.to_plan.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 18,
    },
    function: {
        label: <Translate msg="interventions.medical_examinations.to_plan.columns.function" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 24,
    },
    seat: {
        label: <Translate msg="interventions.medical_examinations.to_plan.columns.seat" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 23,
    },
    deadline: {
        label: <Translate msg="interventions.medical_examinations.to_plan.columns.deadline" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: TToPlanListItem) => listItem.columns.deadlineSort,
        percentWidth: 15,
    },
    deadlineSort: {
        hide: true,
        sortType: SortType.String,
        percentWidth: null,
    },
    examinationReason: {
        label: <Translate msg="interventions.medical_examinations.to_plan.columns.reason" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 13,
    },
    examinationReasonId: {
        percentWidth: null,
        hide: true,
    },
    toBePlanned: {
        percentWidth: null,
        sortType: SortType.String,
        hide: true,
    },
    actions: {
        sortable: false,
        percentWidth: 7,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'deadline',
    sortOrder: SortOrder.Ascending,
};

const BASE_NAME = 'med-exams-to-plan';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const CLASS_NAME = 'MedicalExaminationsToPlan';
const TO_BE_PLANNED_FILTER_BASE_KEY = 'interventions.medical_examinations.to_plan.filter_values.to_be_planned';

function ToPlanMedicalExaminationsComp(props: IPrivateProps & IToPlanProps) {
    function renderBulkPlanButton() {
        const { goToAutomaticPlanning } = props;
        return (
            <ShowIfAllowed routeKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD}>
                <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                    <Button
                        id="bulk-plan-button"
                        typeName="secondary"
                        onClick={goToAutomaticPlanning}
                    >
                        <Icon typeName="calendar" />
                        <Translate msg="interventions.medical_examinations.action.plan_bulk" />
                    </Button>
                </TinyLoader>
            </ShowIfAllowed>
        );
    }

    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            className={CLASS_NAME}
            getDefaultQueryParams={(input) => getDefaultQueryParams(input, props.translator)}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN,
                asyncInfoSelector: getMedicalExaminationsToPlanAsyncInfo,
                dataSelector: getMedicalExaminationsToPlan,
                transformData: mapMedicalExamsToPlanToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<TToPlanListItem[], IFilterValues>) =>
                    <ToPlanMedicalExaminationsList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<TToPlanListItem[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'medical-examinations-to-plan',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
                renderActionsRight: renderBulkPlanButton,
            }}
        />
    );
}

class ToPlanMedicalExaminationsList extends
    PureComponent<TToPlanMedicalExaminationsListProps & IPrivateProps> {

    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    constructor(props: TToPlanMedicalExaminationsListProps & IPrivateProps) {
        super(props);

        this.columns.actions.render = this.renderPlanEmployeeAction.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        return (
            <>
                <p className={`${CLASS_NAME}__pre-list`}>
                    <Translate raw msg="interventions.medical_examinations.to_plan.pre_table" />
                </p>
                <ListWithSorting
                    initialSort={INITIAL_SORT}
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
            </>
        );
    }

    private renderPlanEmployeeAction(listItem: TToPlanListItem) {
        const {
            triggerWizardWithSelectedEmployee,
        } = this.props;

        if (!listItem.data.toBePlanned) {
            return <span/>;
        }

        return (
            <ListItemActions>
                <ShowIfAllowed routeKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD}>
                    <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                        <TinyLoader
                            asyncInfoSelector={getSkipToMedicalExaminationWizardStepAsyncInfo}
                            showContentOnInitialState={true}
                        >
                            <Button
                                id="plan-employee-button"
                                onClick={() => triggerWizardWithSelectedEmployee(listItem.id as number)}
                            >
                                <span>
                                    <Translate msg="interventions.medical_examinations.to_plan.plan_examination" />
                                </span>
                                <Icon circle typeName="calendar" />
                            </Button>
                        </TinyLoader>
                    </TinyLoader>
                </ShowIfAllowed>
            </ListItemActions>
        );
    }
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }, translator: ITranslator) {
    const defaultFilters = {
        ...DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS,
        toBePlanned: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.plannable`),
    };

    return isShowAll ? {
        ...defaultFilters,
        isShowAll,
    } : defaultFilters;
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<TToPlanListItem[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.toBePlanned)) {
                return true;
            }

            const toBePlanned = separateStringList(filterValues.toBePlanned);

            return toBePlanned.includes(listItem.columns.toBePlanned as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.startDate)) {
                return true;
            }

            return listItem.columns.deadlineSort >= filterValues.startDate;
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.reasonIds)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.reasonIds);
            return reasonIds.includes(listItem.columns.examinationReasonId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.endDate)) {
                return true;
            }

            return isBeforeOrEqual(listItem.columns.deadlineSort as string, filterValues.endDate);
        });
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.planned.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.planned.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<TToPlanListItem[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
        translator,
    } = renderProps;

    const possibleReasons = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'examinationReasonId',
        'examinationReason',
    );

    // Manually add yes or no checkboxes
    const possibleToBePlannedValues = [
        {
            label: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.plannable`),
            value: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.plannable`),
        },
        {
            label: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.not_plannable`),
            value: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.not_plannable`),
        },
    ];

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.medical_examinations.planned.filter"
                formRenderProps={formRenderProps}
                maxDate={MINMAX_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS.maxEndDate}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="reason"
                labelTranslationKey="interventions.medical_examinations.planned.filter.reason"
                possibleFilterItems={possibleReasons}
                actualFilterValue={formRenderProps.values.reasonIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'reasonIds',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="toBePlanned"
                labelTranslationKey="interventions.medical_examinations.to_plan.filter.to_be_planned"
                possibleFilterItems={possibleToBePlannedValues}
                actualFilterValue={formRenderProps.values.toBePlanned}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'toBePlanned',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<TToPlanListItem[], IFilterValues>,
) {
    const { translator } = transformProps;

    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.to_plan.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS.startDate,
            },
            reasonIds: {
                show: true,
                translationKeySuffixOverride: 'reason',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'examinationReason',
                        searchColumnName: 'examinationReasonId',
                    },
                },
            },
            toBePlanned: {
                show: true,
                translationKeySuffixOverride: 'to_be_planned',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'toBePlanned',
                        searchColumnName: 'toBePlanned',
                    },
                },
                defaultValue: translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.plannable`),
            },
        },
    });
}

function toListId(medicalExamToPlan: IMedicalExaminationToPlan) {
    return medicalExamToPlan.planningRequestId;
}

function mapMedicalExamsToPlanToListItems(
    medicalExamsToPlan: IMedicalExaminationToPlan[],
    translator: ITranslator,
): TToPlanListItem[] {
    return medicalExamsToPlan.map((medicalExamToPlan) => {
        return {
            id: toListId(medicalExamToPlan),
            columns: {
                name: formatPersonNameFormal(medicalExamToPlan.employee),
                function: medicalExamToPlan.function.description,
                seat: medicalExamToPlan.company.name,
                deadline: formatDateInLongFormat(medicalExamToPlan.toBePlannedDate),
                deadlineSort: medicalExamToPlan.toBePlannedDate,
                examinationReason: medicalExamToPlan.examinationReason.title,
                examinationReasonId: medicalExamToPlan.examinationReason.id,
                actions: null,
                toBePlanned: medicalExamToPlan.toBePlanned
                    ? translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.plannable`)
                    : translator(`${TO_BE_PLANNED_FILTER_BASE_KEY}.not_plannable`),
            },
            data: medicalExamToPlan,
        };
    });
}

function mapListRowForExport(medicalExamination: IMedicalExaminationToPlan) {
    const {
        absentDescription,
        company: { companyCode },
        company: { name: companyName },
        employee: { birthDate },
        employee: { name },
        employee: { firstName },
        examinationReason: { title },
        function: { description },
        medicalCenter: { name: medicalCenterName },
        toBePlannedDate,
    } = medicalExamination;

    return {
        absentDescription,
        company: {
            companyCode,
            name: companyName,
        },
        employee: {
            birthDate,
            name,
            firstName,
        },
        examinationReason: {
            title,
        },
        function: {
            description,
        },
        medicalCenter: {
            name: medicalCenterName,
        },
        toBePlannedDate,
    };
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<TToPlanListItem[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            medicalExaminationsToPlan: getMedicalExaminationsToPlan(state),
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            goToAutomaticPlanning: () => {
                dispatch(resetPlanMedicalExaminationWizardEntity());
                dispatch(navigateToPlanMedicalExaminationWizardStep({
                    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC,
                    resetDataEntity: true,
                    reason: getPeriodicHealthAssessmentReason(getState()),
                }));
            },
            triggerWizardWithSelectedEmployee: (id: number) => {
                const state = getState();
                const examinationToPlan: IMedicalExaminationToPlan = getMedicalExaminationToPlanFromList(state, id);

                const employee = examinationToPlan.employee;

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: getWizardTypeFromExaminationReasonId(state, examinationToPlan.examinationReason.id),
                        reason: examinationToPlan.examinationReason as IExaminationReason,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: formatPersonNameFormal(employee),
                            selectedEmployee: employee,
                        },
                        selectTime: undefined,
                        selectedMedicalExaminationToPlan: examinationToPlan,
                    },
                }));
            },
        };
    },
})(ToPlanMedicalExaminationsComp);
