import { object } from 'yup';
import number from '../../../../../../utils/libs/yup/number';
import phoneNumber from '../../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../../utils/libs/yup/string';

export const fields = {
    firstName: 'firstName',
    name: 'name',
    typeId: 'typeId',
    email: 'email',
    phone: 'phone',
};

export const schema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.typeId]: number().required(),
    [fields.email]: string().email().required(),
    [fields.phone]: phoneNumber().required(),
});
