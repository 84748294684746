import { ICmsImage, ICmsVideo } from './cms';

export interface IPagePart<PagePartData = TPagePartData> {
    type: PagePartType;
    data: PagePartData;
}

export enum PagePartType {
    HEADER = 'App\\Entity\\PageParts\\HeaderPagePart',
    MENSURA_HEADER = 'App\\Website\\Entity\\PageParts\\HeaderPagePart',
    KUMA_TEXT = 'Kunstmaan\\PagePartBundle\\Entity\\TextPagePart',
    MENSURA_WEBSITE_TEXT = '"App\\Website\\Entity\\PageParts\\TextPagePart"',
    MENSURA_TEXT = 'App\\Entity\\PageParts\\TextPagePart',
    TEXT_BLOCK = 'App\\Website\\Entity\\PageParts\\TextBlockPagePart',
    MENSURA_COURSE_ANCHOR = 'App\\Website\\Entity\\PageParts\\CourseAnchorPagePart',
    MENSURA_VIDEO = 'App\\Website\\Entity\\PageParts\\VideoPagePart',
    KUMA_LINE = 'Kunstmaan\\PagePartBundle\\Entity\\LinePagePart',
    MENSURA_SPOTLIGHT = 'App\\Website\\Entity\\PageParts\\SpotlightPagePart',
    MENSURA_ICON_LEFT_WITH_TEXT = 'App\\Website\\Entity\\PageParts\\IconLeftWithTextPagePart',
    MENSURA_BUTTON = 'App\\Website\\Entity\\PageParts\\ButtonPagePart',
    MENSURA_TIMELINE = 'App\\Website\\Entity\\PageParts\\TimeLinePagePart',
    MENSURA_TIP = 'App\\Website\\Entity\\PageParts\\TipPagePart',
    // page parts just by the Frontend
    COURSE_FICHE_CUSTOM = 'MensuraKlantenzoneFrontend\\PageParts\\CourseFichePagePart',
    COURSE_PRICE_CUSTOM = 'MensuraKlantenzoneFrontend\\PageParts\\CoursePricePagePart',
    LEGAL_CENTERED_ICON = 'App\\Entity\\PageParts\\LegalCenteredIconPagePart',
    LEGAL_TIP = 'App\\Entity\\PageParts\\LegalTipPagePart',
    LEGAL_ICON_TEXT = 'App\\Entity\\PageParts\\LegalIconTextPagePart',
    LEGAL_COOKIES = 'App\\Entity\\PageParts\\LegalCookiesPagePart',
}

export type TPagePartData =
    IHeaderPagePartData |
    ITextPagePartData |
    ITextBlockPagePartData |
    IVideoPagePartData |
    ICourseAnchorPagePartData |
    ISpotlightPagePartData |
    IIconLeftWithTextPagePartData |
    IButtonPagePartData |
    ITipPagePartData |
    ITimeLinePagePartData;

export interface IHeaderPagePartData {
    niv?: number;
    title: string;
}

export interface ITextPagePartData {
    content: string;
}

export interface ITextBlockPagePartData {
    text: string;
}

export interface IVideoPagePartData {
    image: ICmsImage;
    video: ICmsVideo;
}

export interface ICourseAnchorPagePartData {
    title: string;
}

export interface ISpotlightPagePartData {
    color: string;
    description: string;
    title: string;
}

export interface IIconLeftWithTextPagePartData {
    body: string;
    imageAltText?: string;
    image: ICmsImage;
}

export interface IButtonPagePartData {
    linkText: string;
    linkUrl: string;
    position: 'left' | 'center' | 'right' | 'block';
}

export interface ITimeLinePagePartData {
    items: {
        content: string;
        title: string;
    }[];
    linkText: string;
    linkUrl: string;
}

export interface ITipPagePartData {
    description: string;
    title: string;
    ctaText?: string;
    ctaUrl?: string;
}
