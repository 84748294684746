import React, { Component } from 'react';
import '../onboarding-wizard-seats.scss';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import { IAddressSave } from '../../../../../../models/general/address';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import { connect } from '../../../../..';
import { BE_COUNTRY_CODE } from '../../../../../../config/general.config';
import { UNSELECTED_STREET } from '../../../../../common/address/StreetTypeahead';
import { UNSELECTED_ZIPCODEID } from '../../../../../common/address/CityTypeahead';
import { schema, fields } from './addSeatsSchema';
import Translate from '../../../../../common/Translate';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import Dialog from '../../../../../common/modals/Dialog';
import { createFormTextInput } from '../../../../../common/forms/FormTextInput';
import { addCompanySeatActions } from '../../../../../../redux/company/info/actions';
import { getAddCompanySeatAsyncInfo } from '../../../../../../redux/company/info/selectors';
import Button from '../../../../../common/buttons/Button';
import { getCountryDescription } from '../../../../../../config/constants';
import { IAddCompanySeatPayload } from '../../../../../../models/admin/companyInfo';
import AddressFields from '../../../../../common/address/AddressFields';

const CLASS_NAME = 'OnboardingWizardSeatsAddSeat';
const FORM_NAME = 'onboarding-wizard-seats-add-seat';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.seats.add_seat';

interface IAddSeatProps {
    onCloseIntent: () => void;
    show: boolean;
}

interface IPrivateProps {
    addSeat: (values: IAddCompanySeatPayload) => void;
    addSeatAsyncInfo: IAsyncFieldInfo;
    resetAddSeat: () => void;
}

interface IComponentState {
    isConfirmDialogOpen: boolean;
}

export interface FormValues {
    name: string;
    address: IAddressSave;
}

const AddSeatFormTextInput = createFormTextInput<FormValues>();

class AddSeat extends Component<IAddSeatProps & IPrivateProps, IComponentState> {

    constructor(props: IAddSeatProps & IPrivateProps) {
        super(props);

        this.state = {
            isConfirmDialogOpen: false,
        };

        this.onCloseIntent = this.onCloseIntent.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public componentDidUpdate(prevProps: IAddSeatProps & IPrivateProps) {
        if (!prevProps.show && this.props.show) {
            this.props.resetAddSeat();
        }
    }

    public render() {

        const { show, addSeatAsyncInfo } = this.props;

        const error = addSeatAsyncInfo.error;
        const isBusy = addSeatAsyncInfo.status === AsyncStatus.Busy;

        const INITIAL_VALUES: FormValues = {
            address: {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: UNSELECTED_STREET,
                zipCodeId: UNSELECTED_ZIPCODEID,
            },
            name: '',
        };

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    addSeatAsyncInfo.status === AsyncStatus.Initial ? (
                        <Dialog
                            className={CLASS_NAME}
                            show={show}
                            onCloseIntent={this.onCloseIntent}
                            header={`${TRANSLATION_PREFIX}.header`}
                        >
                            <Form
                                name={FORM_NAME}
                                handleSubmit={this.onSubmit}
                                initialValues={INITIAL_VALUES}
                                schema={schema}
                                render={(formRenderProps: IFormRenderProps<FormValues>) => {
                                    return (
                                        <div className={`${CLASS_NAME}__form`}>
                                            <h6><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h6>
                                            <AddSeatFormTextInput
                                                baseId={FORM_NAME}
                                                fields={fields}
                                                formRenderProps={formRenderProps}
                                                name={'name'}
                                                baseTranslationKey={`${TRANSLATION_PREFIX}.form.placeholder`}
                                                markAsRequired={true}
                                            />
                                            <AddressFields
                                                {...formRenderProps}
                                                translator={translator}
                                                initialAddress={INITIAL_VALUES.address}
                                                className={CLASS_NAME}
                                                countryAndCityOnSameLine
                                                allowInternationalAddress
                                            />
                                            <div className={`${CLASS_NAME}__submit`}>
                                                <SubmitButton
                                                    id="onboarding-wizard-seats-add-seat-submit-button"
                                                    formName={FORM_NAME}
                                                >
                                                    <Translate msg={`${TRANSLATION_PREFIX}.form.submit`} />
                                                </SubmitButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </Dialog>
                    ) : (
                            <Dialog
                                show={show}
                                onCloseIntent={this.onCloseIntent}
                                type={error ? 'error' : 'success'}
                                header={error ?
                                    `${TRANSLATION_PREFIX}.confirm_dialog.error` :
                                    `${TRANSLATION_PREFIX}.confirm_dialog.success.title`}
                                showLoader={isBusy}
                                disableClosing={!error}
                            >
                                <>
                                    {!error &&
                                        <p><Translate msg={`${TRANSLATION_PREFIX}.confirm_dialog.success.text`} /></p>
                                    }
                                    <Button
                                        id="confirm-dialog-close"
                                        typeName="secondary"
                                        onClick={this.onCloseIntent}
                                    >
                                        <Translate msg="common.confirmation_dialog.close" />
                                    </Button>
                                </>
                            </Dialog>
                        )
                )}
            </TranslatorContext.Consumer>
        );
    }

    private onSubmit(values: FormValues) {
        this.props.addSeat({
            address: {
                country: getCountryDescription(values.address.countryCode),
                number: values.address.number,
                postcode: values.address.postcode,
                street: values.address.street,
            },
            name: values.name,
        });
    }

    private onCloseIntent() {
        const { onCloseIntent } = this.props;
        onCloseIntent();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            addSeatAsyncInfo: getAddCompanySeatAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            addSeat: (payload: IAddCompanySeatPayload) => {
                dispatch(addCompanySeatActions.trigger(payload));
            },
            resetAddSeat: () => {
                dispatch(addCompanySeatActions.reset({}));
            },
        };
    },
})(AddSeat);
