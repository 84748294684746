export type TDebounced<Args extends any[] = []> = ((...args: Args) => any) & {
    cancel: () => void;
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 * If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 */
export default function debounce<Args extends any[] = []>(
    func: (...args: Args) => any, wait: number, immediate = false,
): TDebounced<Args> {
    let timeout;

    const fn = function debounceFcn(this: object) {
        // tslint:disable-next-line
        const context = this;
        const args = arguments;
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        window.clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    } as Function;

    (fn as TDebounced<Args>).cancel = () => window.clearTimeout(timeout);

    return fn as TDebounced<Args>;
}
