import React from 'react';
import Loader from '../../common/waiting/Loader';

export default function LogoutInProgress() {
    return (
        <div>
            <Loader useFullScreen show />
        </div>
    );
}
