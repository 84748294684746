/* eslint-disable max-len */

export const URL = {
    TIMESLOTS: '/medical-examinations/timeslots',
    TIMESLOT: '/medical-examinations/timeslots/{timeCellId}',
    TIMESLOT_BY_ID: '/medical-examinations/timeslots-by-id',
    AUTO_PLAN: '/medical-examinations/auto-plan',
    COMPANY_MEDICAL_CENTERS_FREESLOTS: '/medical-examinations/free-slots',
    BUFFERZONE_TIMECELLS: '/companies/{companyCode}/medical-examinations/bufferzone-planning',
    TIME_CELL_PLANNING_CONFIG: '/time-cell/{planningEntityId}/planning-config',
};

export const BFF_URL = {
    TIMESLOTS: '/activities/{activityId}/planning-slots',
    TIMESLOT: '/activities/{activityId}/planning-slots/{timeSlotId}',
    TIMESLOTS_BY_EMPLOYEE: '/companies/{organizationalUnitCode}/employees/{employeeId}/activities/medical-examinations/timeslots/plannable',
};
