import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './radiobutton.scss';

export interface IRadiobuttonProps {
    name: string;
    checked?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    value: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    className?: string;
}

const Radiobutton = (props: IRadiobuttonProps) => {
    return (
        <div className={classNames('Radiobutton', props.className)}>
            <input
                type="radio"
                name={props.name}
                id={`${props.name}-${props.value}`}
                defaultChecked={!props.onChange ? props.checked : undefined}
                checked={props.onChange ? props.checked : undefined}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                onClick={props.onClick}
            />
            <label htmlFor={`${props.name}-${props.value}`}>{props.children || null}</label>
        </div>
    );
};

export default Radiobutton;
