import { object } from 'yup';
import number from '../../../../../../../utils/libs/yup/number';
import nationalRegisterNumber from '../../../../../../../utils/libs/yup/nationalRegisterNumber';
import phoneNumber from '../../../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../../../utils/libs/yup/string';

export const fields = {
    firstName: 'firstName',
    name: 'name',
    nationalRegisterNumber: 'nationalRegisterNumber',
    titleId: 'titleId',
    languageId: 'languageId',
    email: 'email',
    phoneNumber: 'phoneNumber',
    mobilePhoneNumber: 'mobilePhoneNumber',
    admin: 'admin',
    sexId: 'sexId',
    fax: 'fax',
};

export const schema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.nationalRegisterNumber]: nationalRegisterNumber(),
    [fields.titleId]: number().required(),
    [fields.languageId]: number().required(),
    [fields.email]: string().email().required(),
    [fields.phoneNumber]: phoneNumber(),
    [fields.mobilePhoneNumber]: phoneNumber(),
    [fields.admin]: number().required(),
    [fields.sexId]: number().required(),
    [fields.fax]: phoneNumber(),
});

export const requiredOnlySchema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.titleId]: number().required(),
    [fields.languageId]: number().required(),
    [fields.email]: string().email().required(),
    [fields.admin]: number().required(),
    [fields.sexId]: number().required(),
});
