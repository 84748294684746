import { get } from '../../utils/api/requestWrapper';
import { ICmsWebAppMessages } from '../../models/general/webAppMessages';

export const URL = {
    WEB_APP_MESSAGES: '/web-app-messages',
};

export function fetchWebAppMessages() {
    return get<ICmsWebAppMessages>({
        url: URL.WEB_APP_MESSAGES,
    });
}
