import React, { PureComponent } from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { connect } from '../../../../index';
import { ListItem } from '../../../../../models/general/list';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import filterListItems from '../../../../../utils/list/filterListItems';
import Loader from '../../../waiting/Loader';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import Footer from '../Footer';
import {
    IMasterConfig,
    TMasterData,
    IFooterConfig,
    IClientSideSearchOfListData,
    TClientSideFilterOfListData,
} from '../typings';

interface IPublicProps {
    baseClassName: string;
    masterConfig: IMasterConfig;
    masterData: TMasterData;
    clientSideFilteredListItems?: TMasterData;
    filterValues: object;
    onFilterSubmit: (filters: object) => void;
    canItemBeSelected: boolean;
    detailIdRouteParamName: string;
    onItemSelected: (itemId: string | number) => void;
    footerConfig?: IFooterConfig;
    onShowAll: () => void;
}

interface IPrivateProps {
    masterAsyncInfo: IAsyncFieldInfo;
    isFetchingMaster: boolean;
    selectedDetailId: string;
}

class Master extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPrivateProps & IPublicProps) {
        super(props);
    }

    public render() {
        const {
            baseClassName,
            masterConfig, masterData, clientSideFilteredListItems,
            filterValues, canItemBeSelected,
            masterAsyncInfo, isFetchingMaster, selectedDetailId, onItemSelected,
            footerConfig, onShowAll, children,
        } = this.props;

        const footer = (
            <Footer
                isFetchingMaster={isFetchingMaster}
                footerConfig={footerConfig}
                masterData={masterData}
                clientSideFilteredListItems={clientSideFilteredListItems}
                filterValues={filterValues}
                onShowAll={onShowAll}
            />
        );

        return (
            <div className={`${baseClassName}__master`}>
                <Loader show={isFetchingMaster} />
                {masterConfig.renderContent({
                    masterAsyncInfo,
                    masterData: clientSideFilteredListItems || masterData,
                    canItemBeSelected,
                    onItemSelected,
                    selectedItemId: selectedDetailId,
                    filterValues,
                    footer,
                })}
                {children}
            </div>
        );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const masterAsyncInfo = publicProps.masterConfig.asyncInfoSelector(state);

            return {
                masterAsyncInfo,
                isFetchingMaster: masterAsyncInfo.status === AsyncStatus.Busy,
                selectedDetailId: getRoutePayload(state)[publicProps.detailIdRouteParamName],
            };
        };
    },
})(Master);

export function clientSideFilter(
    masterData: TMasterData,
    clientSideSearchOfListData: IClientSideSearchOfListData,
    clientSideFilterOfListData: TClientSideFilterOfListData,
    filterValues: object,
    customData?: object,
) {
    if (!clientSideSearchOfListData && !clientSideFilterOfListData) {
        return undefined;
    }

    const listItems = masterData as ListItem<{}>[];

    return applyFilterOnListItems(
        applySearchOnListItems(
            listItems,
            clientSideSearchOfListData,
            filterValues,
        ),
        clientSideFilterOfListData,
        filterValues,
        customData,
    );
}

function applySearchOnListItems(
    listItems: ListItem<{}>[],
    clientSideSearchOfListData: IClientSideSearchOfListData,
    filterValues: object,
): ListItem<{}>[] {
    if (!clientSideSearchOfListData) {
        return listItems;
    }

    return filterListItems(
        listItems,
        filterValues[clientSideSearchOfListData.searchFilterName],
        {
            columnsConfig: clientSideSearchOfListData.columnsConfig,
            acceptCommasAndPointsAsDecimalSeparator: clientSideSearchOfListData.acceptCommasAndPointsAsDecimalSeparator,
        },
    );
}

function applyFilterOnListItems(
    listItems: ListItem<{}>[],
    clientSideFilterOfListData: TClientSideFilterOfListData,
    filterValues: object,
    customData?: object,
): ListItem<{}>[] {
    if (!clientSideFilterOfListData) {
        return listItems;
    }

    return clientSideFilterOfListData({
        listItems,
        filterValues,
        isFilterSet,
        customData,
    });
}

export function isFilterSet(filterValue: string): boolean {
    return isSet(filterValue) && filterValue.trim().length > 0;
}
