import { createEpic } from '../../index';
import {
    CREATE_RE_INTEGRATION_REQUEST,
} from '../types';
import {
    IRequestReIntegrationPayload,
} from '../../../models/interventions/medicalExaminations';
import { createReIntegrationRequestActions } from '../actions';
import { SubmittedFormActionType } from '../../../config/analytics.config';

// createReIntegrationRequestEpic
createEpic<IRequestReIntegrationPayload>({
    onActionType: CREATE_RE_INTEGRATION_REQUEST,
    async processReturn({ action, api }) {
        try {
            await api.interventions.requestReIntegration.createReIntegrationRequest(action.payload.id, action.payload);

            return createReIntegrationRequestActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.MEDICAL_EXAM_PLANNED_RE_INTEGRATION,
                },
            );
        } catch (error) {
            return createReIntegrationRequestActions.failed(error);
        }
    },
    latest: false,
});
