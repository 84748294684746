import React, { PureComponent, MouseEvent } from 'react';
import ROUTE_KEYS from '../../../../routeKeys';
import { ILocationAction } from '../../../../models/general/redux';
import Button, { IButtonProps } from '../../buttons/Button';
import { connect } from '../../../index';
import { navigateTo } from '../../../../redux/location/actions';
import { getQueryParams } from '../../../../redux/location/selectors';

interface IPrivateProps {
    navigateToTarget: () => void;
}
interface IComponentProps extends IButtonProps {
    to: ROUTE_KEYS | ILocationAction<{}>;
    preserveQuery?: boolean;
}

class ButtonLinkToRoute extends PureComponent<IPrivateProps & IComponentProps> {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public render() {
        return (
            <Button
                {...this.props}
                onClick={this.onClick}
            >
                {this.props.children}
            </Button>
        );
    }

    private onClick(e: MouseEvent<HTMLButtonElement>) {
        if (!this.props.disabled) {
            e.preventDefault();
            if (typeof this.props.onClick === 'function') {
                this.props.onClick(e);
            }
            this.props.navigateToTarget();
        }
    }
}

export default connect<IPrivateProps, IComponentProps>({
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        return (dispatch) => {
            return {
                navigateToTarget: () => {
                    const state = getState();
                    if (typeof publicProps.to === 'string') {
                        dispatch(navigateTo(publicProps.to));
                    } else {
                        let query = null;
                        const toQuery = publicProps.to.meta && publicProps.to.meta.query;
                        if (publicProps.preserveQuery) {
                            query = {
                                ...getQueryParams(state),
                                ...toQuery,
                            };
                        } else {
                            query = {
                                ...toQuery,
                            };
                        }
                        dispatch(navigateTo(
                            publicProps.to.type,
                            publicProps.to.payload,
                            query,
                        ),
                        );
                    }
                },
            };
        };
    },
})(ButtonLinkToRoute);
