import React, { ChangeEvent } from 'react';
import { ShapeOf } from '../../../../../../../../models/ui/form';
import { IFormRenderProps } from '../../../../../../../common/forms/Form';
import { createFormTextInput } from '../../../../../../../common/forms/FormTextInput';
import FloatableTextInputWrapper from '../../../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../../../common/Translate';
import FormFieldError from '../../../../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../../../../models/general/i18n';
import DatePicker from '../../../../../../../common/widget/DateTimePicker/DatePicker/index';
import FunctionTypeahead from '../../../../../../../administration/Employees/shared/FunctionTypeahead';
import {
    MedicalCenterTypeahead,
} from '../../../../../../../administration/Employees/shared/MedicalCenterTypeahead/MedicalCenterTypeahead.component';
import Icon from '../../../../../../../common/icons/Icon';
import TooltipWithIcon from '../../../../../../../common/widget/TooltipWithIcon';
import RequiredMarker from '../../../../../../../common/input/RequiredMarker';
import SeatTypeahead from '../../../../../../../administration/Employees/shared/SeatTypeahead';
import { IOnboardEmployeeToAdd } from '../../../../../../../../models/onboarding/wizard';
import { IAddedCompanyFunction } from '../../../../../../../../models/admin/companyFunctions';
import Checkbox from '../../../../../../../common/input/Checkbox';
import TextInput from '../../../../../../../common/input/TextInput';

type FormValues = Pick<IOnboardEmployeeToAdd,
    'employeeNumber' |
    'dateInFunction' |
    'medicalCenterId' |
    'companyCode' |
    'customerFunctionId' |
    'employer' |
    'studentWorker' |
    'safetyFunction' |
    'sendMail' |
    'medicalFunction'>;

interface IAddEmployeeProfessionalProps {
    fields: ShapeOf<FormValues>;
    formRenderProps: IFormRenderProps<FormValues>;
    baseTranslationKey: string;
    formName: string;
    translator: ITranslator;
    onToggleAddFunctionDialog: (open: boolean) => void;
    hasStudentWorkers: boolean;
}

const AddEmployeeFormTextInput = createFormTextInput<FormValues>();

const AddEmployeeProfessional = (props: IAddEmployeeProfessionalProps) => {

    const {
        formName, formRenderProps, baseTranslationKey, fields, translator,
        onToggleAddFunctionDialog,
    } = props;

    const { values, touched, errors, setFieldValue, handleChange } = formRenderProps;

    const infoIcon = (
        <Icon
            typeName="info"
            circle
        />
    );

    function onFunctionItemSelected(value: number) {
        setFieldValue('customerFunctionId', value);
    }

    function onSeatItemSelected(companyCode: string) {
        setFieldValue('companyCode', companyCode);
    }

    function onStartDateChanged(date: string) {
        setFieldValue('dateInFunction', date);
    }

    function onMedicalCenterSelected(medicalCenterId: number) {
        setFieldValue('medicalCenterId', medicalCenterId);
    }

    function onFunctionAdded(companyFunction: IAddedCompanyFunction) {
        setFieldValue('customerFunctionId', companyFunction.id);
    }

    function onChangeStudentWorker(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.checked) {
            setFieldValue('medicalFunction', false);
            setFieldValue('safetyFunction', false);
            setFieldValue('employer', '');
        }
        handleChange(e);
    }

    return (
        <>
            <FloatableTextInputWrapper>
                <DatePicker
                    id={`${formName}-start-date`}
                    placeholder={translator(`${baseTranslationKey}.form.placeholder.start_date`)}
                    value={values.dateInFunction}
                    name={fields.dateInFunction}
                    onChange={onStartDateChanged}
                    isInvalid={touched.dateInFunction && !!errors.dateInFunction}
                >
                    <label htmlFor={`${formName}-start-date`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.start_date`}
                        />
                        <RequiredMarker />
                    </label>
                </DatePicker>
                {touched.dateInFunction && (
                    <FormFieldError
                        error={errors.dateInFunction}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.start_date`,
                            ),
                        }}
                    />
                )}
            </FloatableTextInputWrapper>
            <AddEmployeeFormTextInput
                baseId={formName}
                fields={fields}
                formRenderProps={formRenderProps}
                name={'employeeNumber'}
                baseTranslationKey={`${baseTranslationKey}.form.placeholder`}
                tooltipTranslationKey={`${baseTranslationKey}.form.tooltip.employee_number`}
            />
            <FloatableTextInputWrapper floatLabel>
                <SeatTypeahead
                    id={`${formName}-seat`}
                    value={values.companyCode}
                    name={fields.companyCode}
                    onItemSelected={onSeatItemSelected}
                    isInvalid={touched.companyCode && !!errors.companyCode}
                    // eslint-disable-next-line max-len
                    placeholder={translator(`${baseTranslationKey}.form.placeholder.seat`)}
                    allSeats={true}
                >
                    <label htmlFor={`${formName}-seat`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.seat`}
                        />
                        <RequiredMarker />
                    </label>
                </SeatTypeahead>
                {touched.companyCode && (
                    <FormFieldError
                        error={errors.companyCode}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.seat`,
                            ),
                        }}
                    />
                )}
            </FloatableTextInputWrapper>
            <FloatableTextInputWrapper floatLabel>
                <FunctionTypeahead
                    id={`${formName}-function`}
                    value={values.customerFunctionId}
                    name={fields.customerFunctionId}
                    onItemSelected={onFunctionItemSelected}
                    onFunctionAdded={onFunctionAdded}
                    onToggleAddFunctionDialog={onToggleAddFunctionDialog}
                    isInvalid={touched.customerFunctionId && !!errors.customerFunctionId}
                    showFullFamily={false}
                    placeholder={translator(`${baseTranslationKey}.form.placeholder.function`)}
                    immediatelyTriggerSearch={true}
                    companyCodeOverride={values.companyCode}
                >
                    <label htmlFor={`${formName}-function`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.function`}
                        />
                        <RequiredMarker />
                    </label>
                </FunctionTypeahead>
                {touched.customerFunctionId && (
                    <FormFieldError
                        error={errors.customerFunctionId}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.function`,
                            ),
                        }}
                    />
                )}
            </FloatableTextInputWrapper>
            <FloatableTextInputWrapper floatLabel>
                <MedicalCenterTypeahead
                    id={`${formName}-medical-center`}
                    value={values.medicalCenterId}
                    name={fields.medicalCenterId}
                    onItemSelected={onMedicalCenterSelected}
                    isInvalid={touched.medicalCenterId && !!errors.medicalCenterId}
                    placeholderTranslationKey={`${baseTranslationKey}.form.placeholder.medical_center`}
                    excludeInCompanyMedicalCenter={true}
                >
                    <label htmlFor={`${formName}-medical-center`}>
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.medical_center`}
                        />
                        <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                            <Translate
                                msg={`${baseTranslationKey}.form.tooltip.medical_center`}
                            />
                        </TooltipWithIcon>
                    </label>
                </MedicalCenterTypeahead>
                {touched.medicalCenterId && (
                    <FormFieldError
                        error={errors.medicalCenterId}
                        placeholders={{
                            fieldName: translator(
                                `${baseTranslationKey}.form.placeholder.medical_center`,
                            ),
                        }}
                    />
                )}
            </FloatableTextInputWrapper>
            {<div>
                <h4>
                    <Translate
                        msg={`${baseTranslationKey}.form.student_worker.title`}
                    />
                </h4>
                <Checkbox
                    name="studentWorker"
                    checked={values.studentWorker}
                    onChange={onChangeStudentWorker}
                >
                    <Translate msg={`${baseTranslationKey}.form.placeholder.student_worker`} />
                </Checkbox>
                <h6>
                    <Translate
                        msg={`${baseTranslationKey}.form.student_worker.params`}
                    />
                </h6>
                <Checkbox
                    name="safetyFunction"
                    checked={values.safetyFunction}
                    onChange={handleChange}
                    toggleButton={true}
                    disabled={!values.studentWorker}
                >
                    <Translate msg={`${baseTranslationKey}.form.placeholder.safety_function`} />
                </Checkbox>
                <Checkbox
                    name="medicalFunction"
                    checked={values.medicalFunction}
                    onChange={handleChange}
                    toggleButton={true}
                    disabled={!values.studentWorker}
                >
                    <Translate msg={`${baseTranslationKey}.form.placeholder.medical_function`} />
                </Checkbox>
                <Checkbox
                    name="sendMail"
                    checked={values.sendMail}
                    onChange={handleChange}
                    toggleButton={true}
                    disabled={!values.studentWorker}
                >
                    <Translate msg={`${baseTranslationKey}.form.placeholder.send_mail`} />
                </Checkbox>
                <h6>
                    <Translate
                        msg={`${baseTranslationKey}.form.student_worker.employer`}
                    />
                </h6>
                <FloatableTextInputWrapper floatLabel>
                    <TextInput
                        id="add-job-student-employer"
                        name="employer"
                        value={values.employer}
                        onChange={handleChange}
                        disabled={!values.studentWorker}
                    />
                    <label htmlFor="add-job-student-employer">
                        <Translate
                            msg={`${baseTranslationKey}.form.placeholder.employer`}
                        />
                        <RequiredMarker />
                    </label>
                    {touched.employer &&
                        <FormFieldError
                            error={errors.employer}
                            placeholders={{
                                fieldName: translator(`${baseTranslationKey}.form.placeholder.employer`),
                            }}
                        />}
                </FloatableTextInputWrapper>
            </div>}
        </>
    );
};

export default AddEmployeeProfessional;
