import React, { ReactNode, PureComponent } from 'react';
import './flip-dialog.scss';
import Dialog from '../Dialog';
import { IDialogProps } from '../Dialog/index';
import classNames from 'classnames';
import Translate from '../../Translate';
import CloseIcon from '../../icons/CloseIcon/index';
import GoBack from '../../navigation/GoBack';
import DialogHeader from '../Dialog/DialogHeader';

interface IFlipDialogProps {
    children: ReactNode;
    backContent: ReactNode;
    flip: boolean;
    onFlipIntent: () => void;
}

const CLASS_NAME = 'FlipDialog';

export default class FlipDialog extends PureComponent<IDialogProps & IFlipDialogProps> {
    constructor(props: IDialogProps & IFlipDialogProps) {
        super(props);

        this.triggerOnFlipIntent = this.triggerOnFlipIntent.bind(this);
    }

    public render() {
        const { show, onCloseIntent, backContent, children, flip, header } = this.props;

        return (
            <Dialog
                show={show}
                onCloseIntent={onCloseIntent}
                className={classNames(CLASS_NAME, {
                    flip: !!flip,
                })}
            >
                <div className="front">
                    {header && (
                        typeof header === 'string' ? (
                            <header>
                                <h3><Translate msg={header} /></h3>
                            </header>
                        ) : <DialogHeader {...header} />
                    )}
                    <CloseIcon onClick={onCloseIntent} />
                    <div className="content">
                        {children}
                    </div>
                </div>
                <div className="back">
                    <div className="content">
                        <div className="top">
                            <GoBack id="flip-dialog-back" onClick={this.triggerOnFlipIntent}/>
                        </div>
                        <div>
                            {backContent}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    private triggerOnFlipIntent() {
        const { onFlipIntent } = this.props;
        if (typeof onFlipIntent === 'function') {
            onFlipIntent();
        }
    }
}
