import React, { PureComponent } from 'react';
import './templates.scss';
import { ITemplatesCategory, ITemplatesCategoryDetail } from '../../../models/documentCenter/templates';
import { DOCUMENT_CENTER_ITEMS } from '../../../config/navigation/documentCenter.config';
import { getAsyncTemplates, getAsyncAllTemplates } from '../../../redux/entities/templates/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import PageHeader from '../../appShell/PageHeader';
import Icon from '../../common/icons/Icon';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import EntityWrapper from '../../common/widget/EntityWrapper';
import Tile from '../../common/widget/Tile';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';
import TemplateSearch from './TemplateSearch';

const CLASS_NAME = 'TemplatesComp';
const TRANSLATION_PREFIX = 'document_center.templates';

interface IState {
    showSearchResults: boolean;
}

class Templates extends PureComponent<{}, IState> {
    constructor(props) {
        super(props);

        this.state = {
            showSearchResults: false,
        };

        this.renderTemplateSearch = this.renderTemplateSearch.bind(this);
        this.renderTemplatesCategories = this.renderTemplatesCategories.bind(this);
        this.onShowResults = this.onShowResults.bind(this);
    }

    public render() {
        const CURRENT_DOC_CENTER_ITEM = DOCUMENT_CENTER_ITEMS.find((item) => {
            return item.linkTo === ROUTE_KEYS.R_TEMPLATES;
        });

        const lazyLoadSvgId = CURRENT_DOC_CENTER_ITEM
            ? CURRENT_DOC_CENTER_ITEM.detailLazyLoadSvgId || CURRENT_DOC_CENTER_ITEM.lazyLoadSvgId
            : undefined;

        return (
            <div className={CLASS_NAME}>
                <PageHeader
                    type="grey"
                    title={`${TRANSLATION_PREFIX}.title`}
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.DOCUMENT_CENTER,
                    }}
                    breadcrumbs={true}
                />
                <div className="container">
                    <EntityWrapper
                        asyncEntitySelector={getAsyncAllTemplates}
                        renderData={this.renderTemplateSearch}
                    />
                    {!this.state.showSearchResults && (
                        <EntityWrapper
                            asyncEntitySelector={getAsyncTemplates}
                            renderData={this.renderTemplatesCategories}
                        />
                    )}
                </div>
            </div>
        );
    }

    public renderTemplateSearch({ data }: { data: ITemplatesCategoryDetail[] }) {
        return (
            <TemplateSearch data={data} onShowResults={this.onShowResults} />
        );
    }

    public renderTemplatesCategories({ data: categories }: { data: ITemplatesCategory[] }) {
        return (
            <div className="tiles">
                {
                    categories.map((category) => (
                        <div
                            className="tile-wrapper"
                            key={category.id}
                        >
                            <LinkToRoute
                                id={`to-templates-category_${category.id}`}
                                to={{
                                    type: ROUTE_KEYS.R_TEMPLATES_CATEGORY_DETAIL,
                                    payload: {
                                        categoryId: category.id,
                                    },
                                }}
                            >
                                <Tile
                                    title={category.title}
                                    svg={category.icon && category.icon.url}
                                    svgAsImage
                                    objectFitSvg
                                    typeName="white"
                                    tooltipWithIcon={category.tooltip &&
                                        <TooltipWithIcon
                                            icon={<Icon typeName="info" circle />}
                                            typeName="info-bubble"
                                        >
                                            <span>{category.tooltip}</span>
                                        </TooltipWithIcon>
                                    }
                                />
                            </LinkToRoute>
                        </div>
                    ))
                }
            </div>
        );
    }

    private onShowResults(show: boolean) {
        this.setState({
            showSearchResults: show,
        });
    }
}

export default Templates;
