import React from 'react';

import { ListColumns, SortType, ListItem, ISortedColumn, SortOrder } from '../../../../../../../../models/general/list';
import Translate from '../../../../../../../common/Translate';

import { IColumnNames } from './reserved.type';

export const CLASS_NAME = 'BufferzoneDialogReserved';
export const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.select_timeslot.bufferzones.reserved';

export const COLUMNS: ListColumns<IColumnNames> = {
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.dateSort,
        percentWidth: 11,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 33,
    },
    freeSlots: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.free_slots`} />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 8,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 27,
    },
    companyCode: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company_code`} />,
        sortable: true,
        sortType: SortType.DotSeparatedNumber,
        percentWidth: 13,
    },
    actions: {
        sortable: false,
        percentWidth: 8,
    },
};

export const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Ascending,
};
