import { createSelector } from 'reselect';
import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector, NO_RERENDER } from '../../index';
import { getRoutePayload, getRouteKey } from '../../location/selectors';
import { IFluVaccinesOrderWizardData } from '../../../models/interventions/fluVaccines';
import ROUTE_KEYS from '../../../routeKeys';
import { isDateBetween } from '../../../utils/core/date/isDateBetween';
import { hasRequiredAccessLevels } from '../../auth/selectors';
import { getCompanyContacts } from '../../company/info/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Flu vaccines orders
const getFluVaccinesOrdersAsyncField = (state: IState) =>
    reducerState(state).fluVaccinesOrders;

export const getFluVaccinesOrdersAsyncInfo = makeAsyncFetchInfoSelector(getFluVaccinesOrdersAsyncField);

export const getFluVaccinesOrders = (state: IState) =>
    getFluVaccinesOrdersAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areFluVaccineOrdersAvailable = (state: IState) =>
    !!getFluVaccinesOrdersAsyncField(state).data;

// Flu vaccines config
const getFluVaccinesConfigAsyncField = (state: IState) =>
    reducerState(state).fluVaccinesConfig;

export const getFluVaccinesConfigAsyncInfo = makeAsyncFetchInfoSelector(getFluVaccinesConfigAsyncField);

export const getFluVaccinesConfig = (state: IState) =>
    getFluVaccinesConfigAsyncField(state).data;

export const showFluVaccinesCampaign = (state: IState) => {
    const fluVaccinesConfig = getFluVaccinesConfig(state);
    return fluVaccinesConfig && isDateBetween(new Date(), fluVaccinesConfig.startDate, fluVaccinesConfig.endDate);
};

// Flu vaccine tariffs
const getFluVaccineTariffsAsyncField = (state: IState) =>
    reducerState(state).fluVaccineTariffs;

export const getFluVaccineTariffsAsyncInfo = makeAsyncFetchInfoSelector(getFluVaccineTariffsAsyncField);

export const getFluVaccineTariffs = (state: IState) =>
    getFluVaccineTariffsAsyncField(state).data;

export const areFluVaccineTariffsAvailable = (state: IState) =>
    !!getFluVaccineTariffsAsyncField(state).data;

// Flu vaccines available
const getFluVaccinesAvailableAsyncField = (state: IState) =>
    reducerState(state).fluVaccinesAvailable;

export const getFluVaccinesAvailableAsyncInfo = makeAsyncFetchInfoSelector(getFluVaccinesAvailableAsyncField);

export const getAmountOfAvailableFluVaccines = (state: IState) => {
    const available = getFluVaccinesAvailableAsyncField(state).data;
    return available ? available.amount : null;
};

// Flu vaccines wizard
const getFluVaccinesOrderWizardData = (state: IState) =>
    (reducerState(state).fluVaccinesOrderWizardData || NO_RERENDER.EMPTY_OBJECT) as IFluVaccinesOrderWizardData;

export const getFluVaccinesOrderWizardStepId = (state: IState) =>
    getFluVaccinesOrderWizardData(state).stepId;

export const getFluVaccinesOrderWizardEntity = (state: IState) =>
    getFluVaccinesOrderWizardData(state).entity;

// Create flu vaccines order
const getCreateFluVaccinesOrderAsyncField = (state: IState) =>
    reducerState(state).createFluVaccinesOrder;

export const getCreateFluVaccinesOrderAsyncInfo = makeAsyncDoInfoSelector(getCreateFluVaccinesOrderAsyncField);

// Flu vaccines order update
const getUpdateFluVaccinesOrderAsyncField = (state: IState) =>
    reducerState(state).updateFluVaccinesOrder;

export const getUpdateFluVaccinesOrderAsyncInfo = makeAsyncDoInfoSelector(getUpdateFluVaccinesOrderAsyncField);

// Flu vaccine detail
export const doesFluVaccineOrderListContainSelectedOrderId = (state: IState, id): boolean =>
    getFluVaccinesOrders(state)
        .some((item) => item.id === id);

const getSelectedFluVaccineOrderId = (state: IState) => {
    const currentRouteKey = getRouteKey(state);
    if ([
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL,
    ].includes(currentRouteKey)) {
        return getRoutePayload<{ id: number }>(state).id;
    }

    return null;
};

export const getSelectedFluVaccineOrder = createSelector(
    getSelectedFluVaccineOrderId,
    getFluVaccinesOrders,
    (id, fluVaccines) => {
        if (id) {
            const selectedFluVaccineOrder = fluVaccines
                .find((item) => item.id === id);
            return selectedFluVaccineOrder;
        }

        return null;
    },
);

export const getEnrichedSelectedFluVaccineOrder = createSelector(
    getSelectedFluVaccineOrder,
    getCompanyContacts,
    (selectedFluVaccineOrder, internalContacts) => {
        if (selectedFluVaccineOrder) {
            const matchingInternalContact = internalContacts
                .find((internalContact) => internalContact.customerContactId === selectedFluVaccineOrder.contact.id);

            if (matchingInternalContact) {
                return {
                    ...selectedFluVaccineOrder,
                    contact: {
                        ...selectedFluVaccineOrder.contact,
                        // overwrite name and firstName as the contacts in the flu vaccine order list only contain
                        // a name (which sometimes contains both the firstName and name)
                        name: matchingInternalContact.name,
                        firstName: matchingInternalContact.firstName,
                        // overwrite email and mobilePhone as those are never set in the flu vaccine order list
                        email: matchingInternalContact.email,
                        mobilePhone: matchingInternalContact.mobilePhone,
                        // we don't copy the phone field as that should already be set correctly
                    },
                };
            }
        }

        return selectedFluVaccineOrder;
    },
);

export const isSelectedFluVaccineOrderEditable = (state: IState) => {
    if (!showFluVaccinesCampaign(state)) {
        return false;
    }

    const selectedFluVaccineOrder = getSelectedFluVaccineOrder(state);
    const fluVaccinesConfig = getFluVaccinesConfig(state);

    if (selectedFluVaccineOrder && fluVaccinesConfig) {
        return (
            hasRequiredAccessLevels(state, { company: 'W' }) &&
            isDateBetween(
                selectedFluVaccineOrder.orderDate,
                fluVaccinesConfig.startDate,
                fluVaccinesConfig.endDate,
            ) &&
            !selectedFluVaccineOrder.confirmed
        );
    }
    return false;
};
