import { ICompanyCodePayload, ICompany } from '../admin/company';
import { ITraceableApiError } from '../general/error';

export interface IUnreadMessagesCount {
    count: number;
}

export interface IFetchUserMessagesPayload extends ICompanyCodePayload {
    showFullFamily: boolean;
    dateFrom: string;
    dateUntil: string;
}

export interface IUserMessagesFilters {
    search: string;
    seat: string;
    category: string;
    startDate: string;
    endDate: string;
}

export interface IUserMessage {
    id: string;
    created: string;
    subject: string;
    typeId: number;
    company: Pick<ICompany, 'companyCode' | 'name'>;
    read: boolean;
    attachment?: boolean;
}

export interface IUserMessageWithMessageType extends IUserMessage {
    messageType: IMessageType;
}

export interface IFetchUserMessageDetailPayload {
    id: string;
}

// TODO check if they are all needed + some missing?
export enum UserMessageDetailActionType {
    downloadAttachment = 'downloadAttachment',
    invoiceAttachment = 'invoiceAttachment',
    linkEmployee = 'linkEmployee',
    linkExaminationDocument = 'linkExaminationDocument',
    linkExecutedExam = 'linkExecutedExam',
    linkInvoices = 'linkInvoices',
    linkNoshows = 'linkNoshows',
    linkPlannedExam = 'linkPlannedExam',
}

export interface IUserMessageDetailAction<Id = string> {
    actionType: UserMessageDetailActionType;
    id: Id;
    text?: string;
    date?: string; // format YYYY-MM-DD
    insz?: string;
    invoiceId?: number;
    invoiceNumber?: string;
    filename?: string;
}

export type TUserMessageDetailExtraParam = IUserMessageDetailAction | IUserMessageDetailAction[];

export interface IUserMessageDetails {
    id: string;
    subject: string;
    body: string;
    extra?: {
        [key: string]: TUserMessageDetailExtraParam; // 'attachment' can be one of the keys
    };
}

export interface IMarkMessageAsReadStatus {
    id: string;
    read: boolean;
}

export interface IDeleteUserMessagePayload {
    id: string;
}

export interface IMarkMessageAsReadStatusFailedPayload extends ITraceableApiError {
    messageId: string;
    read: boolean;
}

export interface IChangeMessageStatusPayload {
    id: string;
    read?: boolean;
    deleted?: boolean;
}

export interface IMessageType {
    userMessageTypeId?: number;
    messageTypeId: number;
    code: string;
    active: boolean;
    description: string;
    disabled?: boolean;
}

export interface IFormMessageType {
    messageTypeId: number;
    active: boolean;
    userMessageTypeId?: number;
}

export interface IUpdateUserMessageTypesPayload extends Array<IFormMessageType>{}
