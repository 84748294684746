import React, { Component } from 'react';
import './resumption-absence.scss';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import { IResumptionOfWorkManualEntity } from '../../../../../../models/interventions/medicalExaminations';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import Button from '../../../../../common/buttons/Button';
import Translate from '../../../../../common/Translate';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import ResumptionNotAbsentDialog from '../ResumptionNotAbsentDialog';

const CLASS_NAME = 'ResumptionAbsence';

interface IPrivateProps {
    wizardEntity: IResumptionOfWorkManualEntity;
    updateWizardEntity: (absent: boolean) => void;
}

interface IState {
    isDialogOpen: boolean;
}

class ResumptionAbsence extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isDialogOpen: false,
        };

        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onDialogOpen = this.onDialogOpen.bind(this);
    }

    public render() {
        const { wizardEntity } = this.props;
        const { isDialogOpen } = this.state;

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.resumption_absence.title"
                    titlePlaceholders={{
                        name: formatPersonName(wizardEntity.searchEmployee.selectedEmployee),
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <div className={`${CLASS_NAME}__actions`}>
                        <Button
                            id="resumption-absence-not-absent"
                            onClick={() => this.onDialogOpen()}
                            typeName="secondary"
                            outline={true}
                        >
                            {/* eslint-disable-next-line max-len */}
                            <Translate msg="interventions.medical_examinations.new.steps.resumption_absence.actions.not_absent" />
                        </Button>
                        <Button
                            id="resumption-absence-absent"
                            onClick={() => this.onClickNextHandler()}
                            typeName="secondary"
                            outline={true}
                        >
                            {/* eslint-disable-next-line max-len */}
                            <Translate msg="interventions.medical_examinations.new.steps.resumption_absence.actions.absent" />
                        </Button>
                    </div>
                </div>
                <ResumptionNotAbsentDialog
                    show={isDialogOpen}
                    onCloseDialog={this.onCloseDialog}
                />
            </>
        );
    }

    private onClickNextHandler() {
        this.props.updateWizardEntity(true);
        this.props.goToNextStep();
    }

    private onDialogOpen() {
        this.setState({
            isDialogOpen: true,
        });
    }

    private onCloseDialog() {
        this.setState({
            isDialogOpen: false,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            // eslint-disable-next-line max-len
            wizardEntity: getPlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>(state) as IResumptionOfWorkManualEntity,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (absent: boolean) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>({
                    absent,
                }));
            },
        };
    },
})(ResumptionAbsence);
