import { API_URL } from '../../../config/api.config';
import appendQueryParams, { IUrlParams } from './appendQueryParams';
import replacePathParams from './replacePathParams';

export { IUrlParams } from './appendQueryParams';

export default function constructResourceUrl({
    url,
    pathParams,
    queryParams,
    doNotPrefixUrl,
    api,
}: {
    url: string,
    pathParams?: IUrlParams,
    queryParams?: IUrlParams,
    doNotPrefixUrl?: boolean,
    api: API_URL,
}) {
    const prefixedUrl = `${api}${url}`;

    return [doNotPrefixUrl ? url : prefixedUrl]
        .map((url) => replacePathParams({ url, params: pathParams }))
        .map((url) => appendQueryParams({ url, params: queryParams }))
        .pop();
}
