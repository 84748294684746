import { IEnvConfig } from '../../models/auth/auth0';
import { get, IResponseType } from '../../utils/api/requestWrapper';

const URL = {
    ENV_CONFIG: '/env-config',
    APP_VERSION: '/version.txt',
};

export function fetchEnvironmentConfig() {
    return get<IEnvConfig>({
        url: URL.ENV_CONFIG,
        doNotPrefixUrl: true,
    });
}

export function fetchAppVersion() {
    return get<string>({
        url: URL.APP_VERSION,
        doNotPrefixUrl: true,
        responseType: IResponseType.text,
    });
}
