import React from 'react';
import Wizard from '../../common/navigation/Wizard/index';
import { IStepperStep } from '../../../models/general/stepper';
import Contact from './wizard/Contact';
import Remarks from './wizard/Remarks';
import Validate from './wizard/Validate';
import { COMPANY_VISITS_REQUEST_WIZARD_STEP_ID } from '../../../models/interventions/company-visits';
import { changeCompanyVisitRequestWizardStep } from '../../../redux/company/visits/actions';
import { getCompanyVisitRequestWizardStepId } from '../../../redux/company/visits/selectors';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';

const COMPANY_VISITS_REQUEST_STEPS: IStepperStep[] = [{
    id: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID.CONTACT,
    translationKey: 'interventions.company_visits_new.steps.contact.step_label',
    renderStepContent: (props) => <Contact {...props}/>,
}, {
    id: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID.REMARKS,
    translationKey: 'interventions.company_visits_new.steps.remarks.step_label',
    renderStepContent: (props) => <Remarks {...props}/>,
}, {
    id: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID.VALIDATE,
    translationKey: 'interventions.company_visits_new.steps.validate.step_label',
    renderStepContent: (props) => <Validate {...props}/>,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.REQUEST_COMPANY_VISIT,
    steps: COMPANY_VISITS_REQUEST_STEPS,
});

export default function CompanyVisitRequestWizard() {
    return (
        <div className="CompanyVisitRequestWizard">
            <Wizard
                activeStepIdSelector={getCompanyVisitRequestWizardStepId}
                navigateToStepActionCreator={changeCompanyVisitRequestWizardStep}
                steps={COMPANY_VISITS_REQUEST_STEPS}
            />
        </div>
    );
}
