import { get } from '../../utils/api/requestWrapper';
import { localeToBackendLocale } from '../../utils/formatting/formatLocale';
import { IFetchContentPagePartsInput, ICmsPage } from '../../models/general/cms';
import { CMS_CONTEXTS, CMS_PAGE_TYPE } from '../../config/cms.config';
import { API_URL } from '../../config/api.config';

const URL = {
    CONTENT_PAGES: '/public/pages',
};

export function fetchPageByInternalName({ locale, internalName, pageType }: IFetchContentPagePartsInput) {
    return get<ICmsPage>({
        url: URL.CONTENT_PAGES,
        api: API_URL.CMS,
        queryParams: {
            locale: localeToBackendLocale(locale),
            internalName,
        },
        mapResponse(data) {
            const contexts = getContextsByPageType(pageType);
            return {
                page: getPageData({ responseData: data }),
                pagePartsByRegion: getDataByContexts({ contexts, responseData: data }),
            };
        },
    });
}

function getContextsByPageType(pageType: CMS_PAGE_TYPE) {
    switch (pageType) {
        case CMS_PAGE_TYPE.content:
            return [CMS_CONTEXTS.page];
        case CMS_PAGE_TYPE.legal:
            return [CMS_CONTEXTS.legalHeader, CMS_CONTEXTS.legalMain];
        default:
            return [];
    }
}

function getDataByContexts({ contexts, responseData }) {
    if (responseData._embedded && responseData._embedded.items && responseData._embedded.items.length > 0) {
        const requestPage = responseData._embedded.items[0];

        if (requestPage.page_template
            && requestPage.page_template.contexts && requestPage.page_template.contexts.length > 0) {
            return contexts.reduce(
                (acc, context) => {
                    const contextInfo = requestPage.page_template.contexts
                        .find((c) => c.name === context);

                    return {
                        ...acc,
                        [context]: contextInfo ? contextInfo.pageParts : [],
                    };
                },
                {},
            );
        }
    }

    return contexts.reduce(
        (acc, context) => ({
            ...acc,
            [context]: [],
        }),
        {},
    );
}

function getPageData({ responseData }) {
    if (responseData._embedded && responseData._embedded.items && responseData._embedded.items.length > 0) {
        const requestPage = responseData._embedded.items[0];

        return {
            title: requestPage.node_translation.title,
        };
    }

    return null;
}
