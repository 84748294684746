import React, { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import { AsyncStatus } from '../../../../models/general/redux';
import { CalendarEventDataType, CalendarEventType} from '../../../../models/ui/calendar';
import { fetchAppointmentsActions } from '../../../../redux/planning/actions';
import {
    fetchCourseSessionAttendeesActions,
    fetchCoursesOverviewDetailByHawActions,
} from '../../../../redux/documentCenter/courses/actions';
import { getAppointmentsAsyncInfo } from '../../../../redux/planning/selectors';
import {
    getCourseSessionAttendees,
    getFetchCourseSessionAttendeesAsyncInfo,
    getCoursesOverviewDetail,
    getCoursesOverviewDetailByHawAsyncInfo,
} from '../../../../redux/documentCenter/courses/selectors';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../../../redux/company/selected/selectors';
import { IPlannedCourseAppointment } from '../../../../models/documentCenter/courses';
import { IPlannedCompanyVisit, ICompanyVisit } from '../../../../models/interventions/company-visits';
import { IInterventionAppointment } from '../../../../models/interventions/appointment';
import { IExecutedMedicalExaminationAppointment } from '../../../../models/interventions/medicalExaminations/executed';
import { IPlannedMedicalExamination } from '../../../../models/interventions/medicalExaminations/planned';
import { navigateTo } from '../../../../redux/location/actions';
import { removeTimeSlot } from '../../../../redux/medicalExamination/actions';
import { usePrevious } from '../../../../utils/hooks/usePrevious';
import connect from '../../../../utils/libs/redux/connect';
import isCancelExaminationAllowed from '../../../../utils/interventions/medicalExaminations/isCancelExaminationAllowed';
import ROUTE_KEYS from '../../../../routeKeys';
import CancelExamination from '../../../interventions/shared/CancelExamination';
import Dialog, { IDialogProps } from '../../../common/modals/Dialog';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import Loader from '../../../common/waiting/Loader';

import { IAppointmentDetailDialogProps, IAppointmentDetailDialogPrivateProps } from './AppointmentDetailDialog.type';
import getAppointmentDetailHeaderProps from './getAppointmentDetailHeaderProps';

import { CLASS_NAME } from './AppointmentDetailDialog.const';
import { getPrefixedTranslation } from './AppointmentDetailDialog.helper';
import {
    CourseAppointmentDialog,
} from './CourseAppointmentDialog/CourseAppointmentDialog.component';
import {
    ExecutedCompanyVisitAppointmentDialog,
} from './ExecutedCompanyVisitAppointmentDialog/ExecutedCompanyVisitAppointmentDialog.component';
import {
    ExecutedMedicalExaminationAppointmentDialog,
} from './ExecutedMedicalExaminationAppointmentDialog/ExecutedMedicalExaminationAppointmentDialog.component';
import {
    InterventionAppointmentDialog,
} from './InterventionAppointmentDialog/InterventionAppointmentDialog.component';
import {
    PlannedCompanyVisitAppointmentDialog,
} from './PlannedCompanyVisitAppointmentDialog/PlannedCompanyVisitAppointmentDialog.component';
import {
    PlannedMedicalExaminationAppointmentDialog,
} from './PlannedMedicalExaminationAppointmentDialog/PlannedMedicalExaminationAppointmentDialog.component';

import './AppointmentDetailDialog.scss';

const AppointmentDetailDialogComponent = ({
    asyncFetchInfo,
    cancelMedicalExamination,
    courseAttendeesAsyncFetchInfo,
    courseDetailId,
    courseSessionAttendees,
    coursesOverviewDetailByHawAsyncInfo,
    fetchCourseDetailByHawId,
    fetchPlannedCourseAttendees,
    navigateToCourseDetail,
    onClose,
    refreshAppointmentsData,
    selectedEvent,
    show,
}: IAppointmentDetailDialogProps & IAppointmentDetailDialogPrivateProps) => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const previousSelectedEvent = usePrevious(selectedEvent);

    useEffect(() => {
        const previousSelectedEventId = pathOr(null, ['data', 'organizedCourse', 'id'], previousSelectedEvent);
        const selectedEventId = pathOr(null, ['data', 'organizedCourse', 'id'], selectedEvent);
        const selectedEventType = pathOr(null, ['type'], selectedEvent);

        if (!selectedEvent || selectedEventType !== CalendarEventType.Course) {
            return;
        }

        if (previousSelectedEventId !== selectedEventId) {
            const data = selectedEvent.data as IPlannedCourseAppointment;

            fetchPlannedCourseAttendees(data.organizedCourse.id);
            fetchCourseDetailByHawId(data.organizedCourse.course.id);
        }

    }, [selectedEvent, fetchPlannedCourseAttendees, fetchCourseDetailByHawId, previousSelectedEvent]);

    const onCloseHandler = () => {
        setIsDeleteDialogOpen(false);
        setIsClosing(true);

        onClose();
    };

    const onDeleteHandler = () => {
        setIsDeleteDialogOpen(true);
    };

    const onDeleteCancelledHandler = () => {
        setIsDeleteDialogOpen(false);
    };

    const onCancelMedicalExaminationHandler = () => {
        if (canCancelExamination) {
            const medicalExamination = selectedEvent.data as IPlannedMedicalExamination;
            cancelMedicalExamination(medicalExamination);
        }
    };

    const onCancelCompletedHandler = () => {
        setIsDeleteDialogOpen(false);
        refreshAppointmentsData();
        onClose();
    };

    const canCancelExamination = () => {
        const medicalExamination = selectedEvent.data as IPlannedMedicalExamination;

        return medicalExamination && isCancelExaminationAllowed(medicalExamination.start);
    };

    const renderContent = () => {
        if (asyncFetchInfo.error) {
            return <ErrorPlaceholder apiError={asyncFetchInfo.error} />;
        }

        // When the dialog is in closing transition, we don't have a selected event anymore
        // so to prevent displaying this error, we added this state prop "isClosing".
        if (!selectedEvent && asyncFetchInfo.status === AsyncStatus.Success && !isClosing) {
            return <ErrorPlaceholder translationKey={getPrefixedTranslation('appointment_not_found')} />;
        }

        if (!selectedEvent) {
            return null;
        }

        if (selectedEvent.dataType === CalendarEventDataType.PlannedMedicalExamination) {
            const data = selectedEvent.data as IPlannedMedicalExamination;

            return (
                <PlannedMedicalExaminationAppointmentDialog
                    data={data}
                    onDelete={onDeleteHandler}
                    canCancelExamination={canCancelExamination()}
                />
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.ExecutedMedicalExamination) {
            const data = selectedEvent.data as IExecutedMedicalExaminationAppointment;

            return (
                <ExecutedMedicalExaminationAppointmentDialog
                    data={data}
                />
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.PlannedCompanyVisit) {
            const data = selectedEvent.data as IPlannedCompanyVisit;

            return (
                <PlannedCompanyVisitAppointmentDialog
                    data={data}
                />
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.ExecutedCompanyVisit) {
            const data = selectedEvent.data as ICompanyVisit;

            return (
                <ExecutedCompanyVisitAppointmentDialog
                    data={data}
                />
            );
        }

        if (
            selectedEvent.dataType === CalendarEventDataType.PlannedIntervention
            || selectedEvent.dataType === CalendarEventDataType.ExecutedIntervention
        ) {
            const data = selectedEvent.data as IInterventionAppointment;

            return (
                <InterventionAppointmentDialog
                    data={data}
                />
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.Course) {
            const data = selectedEvent.data as IPlannedCourseAppointment;

            return (
                <CourseAppointmentDialog
                    data={data}
                    courseAttendeesAsyncFetchInfo={courseAttendeesAsyncFetchInfo}
                    courseSessionAttendees={courseSessionAttendees}
                    onNavigateToCourseClick={navigateToCourseDetail}
                    coursesOverviewDetailByHawAsyncInfo={coursesOverviewDetailByHawAsyncInfo}
                    courseDetailId={courseDetailId}
                />
            );
        }

        return <>Not implemented</>;
    };

    const dialogProps: IDialogProps = isDeleteDialogOpen
        ? {
            show: true,
            onCloseIntent: onCloseHandler,
            children: (
                <CancelExamination
                    onGoBack={onDeleteCancelledHandler}
                    onCancel={onCancelMedicalExaminationHandler}
                    onCancelCompleted={onCancelCompletedHandler}
                />
            ),
        }
        : {
            show,
            header: getAppointmentDetailHeaderProps({ selectedEvent }),
            onCloseIntent: onCloseHandler,
            children: (
                <div className={CLASS_NAME}>
                    <Loader show={asyncFetchInfo.status}>
                        {renderContent()}
                    </Loader>
                </div>
            ),
        };

    return (
        <Dialog {...dialogProps} />
    );
};

const AppointmentDetailDialog = connect<IAppointmentDetailDialogPrivateProps, IAppointmentDetailDialogProps>({
    stateProps: (state) => {
        const coursesOverviewDetailByHawAsyncInfo = getCoursesOverviewDetailByHawAsyncInfo(state);
        const coursesOverviewDetail = getCoursesOverviewDetail(state);
        const courseDetailId = coursesOverviewDetailByHawAsyncInfo.status === AsyncStatus.Success
            && coursesOverviewDetail
            && coursesOverviewDetail.course.nodeId;

        return {
            asyncFetchInfo: getAppointmentsAsyncInfo(state),
            courseSessionAttendees: getCourseSessionAttendees(state),
            courseAttendeesAsyncFetchInfo: getFetchCourseSessionAttendeesAsyncInfo(state),
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            cancelMedicalExamination: (
                medicalExamination: IPlannedMedicalExamination,
            ) => {
                dispatch(removeTimeSlot({
                    timeSlotId: medicalExamination.timeSlotId,
                    activityId: medicalExamination.activityId,
                }));
            },
            refreshAppointmentsData: () => {
                dispatch(fetchAppointmentsActions.trigger({}));
            },
            navigateToCourseDetail: (nodeId: number) => {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                    nodeId,
                }));
            },
            fetchPlannedCourseAttendees: (coursesOrganizedId: number) => {
                const state = getState();
                const companyCode = getSelectedSeatCompanyCode(state);
                const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

                dispatch(fetchCourseSessionAttendeesActions.trigger({
                    companyCode,
                    showFullFamily,
                    coursesOrganizedId,
                }));
            },
            fetchCourseDetailByHawId: (id: number) => {
                dispatch(fetchCoursesOverviewDetailByHawActions.trigger({ id }));
            },
        };
    },
})(AppointmentDetailDialogComponent);

export { AppointmentDetailDialog };
