import { post } from '../../utils/api/requestWrapper';
import {
    ISendCompanyFeedbackPayload,
} from '../../models/contact/feedback';

export const URL = {
    COMPANY_FEEDBACK: '/companies/{companyCode}/send-feedback',
};

export function sendCompanyFeedback(companyCode: string, payload: ISendCompanyFeedbackPayload) {
    return post({
        url: URL.COMPANY_FEEDBACK,
        pathParams: {
            companyCode,
        },
        body: {
            ...payload,
        },
    });
}
