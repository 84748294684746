import { object } from 'yup';
import number from '../../../../../utils/libs/yup/number';
import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';
import string from '../../../../../utils/libs/yup/string';

export const fields = {
    firstName: 'firstName',
    name: 'name',
    companyCode: 'companyCode',
    companyName: 'companyName',
    languageId: 'languageId',
    typeId: 'typeId',
    titleId: 'titleId',
    email: 'email',
    phone: 'phone',
    mobilePhone: 'mobilePhone',
    fax: 'fax',
};

export const schema = object({
    [fields.firstName]: string().required(),
    [fields.name]: string().required(),
    [fields.companyCode]: string().required(),
    [fields.companyName]: string(),
    [fields.languageId]: number().required(),
    [fields.typeId]: number().required(),
    [fields.titleId]: number().nullable(true),
    [fields.email]: string().email(),
    [fields.phone]: phoneNumber(),
    [fields.mobilePhone]: phoneNumber(),
    [fields.fax]: phoneNumber(),
});
