import isFunction from '@snipsonian/core/es/is/isFunction';
import { IAction, ILocationAction } from '../../../models/general/redux';
import { toSuccessType, toFailType, toCancelType, toResetType } from './typeGenerator';
import { ITraceableApiError } from '../../../models/general/error';
import ROUTE_KEYS from '../../../routeKeys';
import { SubmittedFormActionType, TOptionalSubmittedFormActionType } from '../../../config/analytics.config';
import { logFormSubmission } from '../../logging/analytics/eventLogger';
import { reportErrorMessage } from '../../logging/errorReporter';
import { IEventCustomData } from '../../../snipsonian/analytics/src/tracker/providers/gtmTracker';

export function createAction<P extends object>(
    type: string, payload: P): IAction<P> {
    return {
        type,
        payload,
    };
}

export function createLocationAction<P extends object, Q extends object = {}>(
    type: ROUTE_KEYS, payload: P, query: Q): ILocationAction<P, Q> {
    return {
        type,
        payload,
        meta: {
            location: null,
            query,
        },
    };
}

export function createSuccessAction<P extends object>(
    type: string, payload: P): IAction<P> {
    return createAction(toSuccessType(type), payload);
}

export function createFailAction<P extends object>(
    type: string, payload: P): IAction<P> {
    return createAction(toFailType(type), payload);
}

export function createCancelAction<P extends object>(
    type: string, payload: P): IAction<P> {
    return createAction(toCancelType(type), payload);
}

export function createResetAction<P extends object>(
    type: string, payload: P): IAction<P> {
    return createAction(toResetType(type), payload);
}

interface ICreateActionsConfig<SP> {
    type: string;
    onSuccess?: (payload: SP) => void;
}

interface ISucceededActionOptions {
    logFormSubmissionEvent?: TlogFormSubmissionEvent;
    extraDataToLog?: IEventCustomData;
}

type TlogFormSubmissionEvent = SubmittedFormActionType | TSubmittedFormActionTypeGenerator;
type TSubmittedFormActionTypeGenerator = () => TOptionalSubmittedFormActionType;

export function createTypeActions<
    TP extends object = {},
    SP extends object = {},
    FP extends object = ITraceableApiError,
    CP extends object = {},
    RP extends object = {}
>(
    { type, onSuccess }: ICreateActionsConfig<SP>,
) {
    return {
        trigger: (payload: TP) => createAction(type, payload),
        succeeded: (payload: SP, options: ISucceededActionOptions = {}) => {
            if (options && options.logFormSubmissionEvent) {
                const submittedFormActionType =
                    isLogFormSubmissionEventFunctionTypeGuard(options.logFormSubmissionEvent)
                    ? options.logFormSubmissionEvent()
                    : options.logFormSubmissionEvent;

                if (submittedFormActionType) {
                    const { extraDataToLog = {} } = options;

                    logFormSubmission({
                        submittedActionType: submittedFormActionType,
                        extraData: extraDataToLog,
                    });
                } else {
                    reportErrorMessage(
                        `Form submission event not logged as the type could not be determined: action ${type}`,
                        {
                            level: 'warning',
                            extra: {
                                actionType: type,
                            },
                        },
                    );
                }
            }

            if (onSuccess) {
                onSuccess(payload);
            }

            return createSuccessAction(type, payload);
        },
        failed: (payload: FP) => createFailAction(type, payload),
        cancel: (payload: CP) => createCancelAction(type, payload),
        reset: (payload: RP) => createResetAction(type, payload),
    };
}

function isLogFormSubmissionEventFunctionTypeGuard(x: any): x is TSubmittedFormActionTypeGenerator {
    return isFunction(x);
}
