import React, { Component } from 'react';
import { connect } from '../../../index';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import { getWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/selectors';
import { IWorkPostCardWizardEntity, IEducation } from '../../../../models/documentCenter/workPostCards';
import { schema, fields } from './educationSchema';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import { createFormTextInput } from '../../../common/forms/FormTextInput';

export type FormValues = IEducation;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    updateWizardEntity: (values: FormValues) => void;
}

const FORM_NAME = 'work-post-card-education';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.education';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

const EducationFormTextInput = createFormTextInput<FormValues>();

class Education extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            renderStepButtons, wizardEntity, goToNextStep, updateWizardEntity,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            acquiredTraining: wizardEntity.education && wizardEntity.education.acquiredTraining || '',
            priorInstruction: wizardEntity.education && wizardEntity.education.priorInstruction || '',
            requiredTraining: wizardEntity.education && wizardEntity.education.requiredTraining || '',
        };

        function handleSubmit(values: FormValues) {
            updateWizardEntity(values);
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            return (
                                <>
                                    <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                        <EducationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'priorInstruction'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <EducationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'acquiredTraining'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />

                                        <EducationFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'requiredTraining'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                                disabled: false,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateWorkPostCardWizardEntity({
                    education: {
                        ...values,
                    },
                }));
            },
        };
    },
})(Education);
