import { BE_COUNTRY_CODE } from '../../config/general.config';

export function countryCodeIsBelgium(value: string) {
    return value === BE_COUNTRY_CODE;
}

export function countryCodeIsNotBelgium(value: string) {
    return value !== BE_COUNTRY_CODE;
}

export function countryCodeIsBelgiumOrFalsy(value: string) {
    if (!value) {
        return true;
    }
    return value === BE_COUNTRY_CODE;
}
