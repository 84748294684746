import { PROPERTIES_WITH_SENSITIVE_DATA } from '../../config/logging.config';
import isNotEmail from '../validation/isNotEmail';

const propNamesToRemove = PROPERTIES_WITH_SENSITIVE_DATA.map((item) => item.toLowerCase());

type Input = object | object[] | string | string[] | number | number[] | boolean | boolean[] | Error | Error[];

export default function removeSensitiveData(data: Input): Input {
    if (Array.isArray(data)) {
        return (data as Input[]).map((item) => {
            const sanitized = removeSensitiveData(item) as Input;
            return sanitized;
        });
    }

    if (typeof data === 'string') {
        return isNotEmail(data) ? data : '';
    }

    if (typeof data === 'object') {
        const clonedData = { ...data };
        let sanitizedData: object & {stack?: string} = {};
        if (data instanceof Error) {
            sanitizedData = new Error(data.message);
            sanitizedData.stack = data.stack;
        }
        Object.keys(clonedData).forEach((key) => {
            const value = clonedData[key];

            if (isNotEmail(value) && propNamesToRemove.indexOf(key.toLowerCase()) === -1) {
                if (Array.isArray(value)) {
                    sanitizedData[key] = value.map((item) => removeSensitiveData(item));
                } else {
                    sanitizedData[key] = removeSensitiveData(value);
                }
            }
        });
        return sanitizedData;
    }
    return data;
}
