import React from 'react';
import { ICompanySeat } from '../../../../models/admin/company';
import { AsyncStatus } from '../../../../models/general/redux';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader';
import {
    IRenderDetailHeaderProps,
} from '../../../common/widget/MasterWithDetail/typings';

export default function DetailHeader(props: IRenderDetailHeaderProps<ICompanySeat>) {
    const {
        detailAsyncInfo,
        detailData: selectedCompany,
    } = props;

    return (
        <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : selectedCompany && selectedCompany.company.name
                }
            </h1>
        </Loader>
    );
}
