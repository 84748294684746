import React, { Component, CSSProperties } from 'react';
import classNames from 'classnames';
import './availability-overview.scss';
import {
    IDayAvailability,
    isInAvailableOrUnavailableMode,
    toAvailabilityDisplayBlock,
    areAvailabilitiesIncomplete,
} from './availabilitiesMapper';
import AvailabilityOverviewOverlay from './AvailabilityOverviewOverlay';
import Icon from '../../../../common/icons/Icon';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import Translate from '../../../../common/Translate';
import Loader from '../../../../common/waiting/Loader';
import {
    AVAILABILITY_HOUR_LIMITS,
    AVAILABILITY_HOURS,
    AVAILABILITY_DAYS,
} from '../../../../../config/company/companyInfo.config';
import {
    CompanyAvailabilityType,
    ICompanyAvailabilities,
    ICompanyAvailabilityBlock,
    TCreateCompanyAvailabilities,
} from '../../../../../models/admin/companyInfo';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { ITranslator } from '../../../../../models/general/i18n';
import Button from '../../../../common/buttons/Button';

const CLASS_NAME = 'AvailabilityOverview';

export interface ISaveAvailabilitiesProps {
    applyFullFamily: boolean;
    availabilities: ICompanyAvailabilities;
}

export interface IWithinSidePanel {
    immediatelyShowOverlay: boolean;
    onSelectAvailabilities?: () => void; // needed when immediatelyShowOverlay=false
    closeOverlay?: () => void; // needed when immediatelyShowOverlay=true
}

interface IComponentPublicProps {
    id: string;
    availabilityType: CompanyAvailabilityType;
    availabilities: ICompanyAvailabilities;
    companyName: string;
    isCopyToAllSeatsAllowed: boolean;
    mayEdit: boolean;
    onSaveAvailabilities: (saveProps: ISaveAvailabilitiesProps) => void;
    resetSaveAvailabilities: () => void;
    saveAsyncInfo: IAsyncFieldInfo;
    translator: ITranslator;
    withinSidePanel?: IWithinSidePanel;
}

interface IComponentState {
    showOverlay: boolean;
    immediatelyShowOverlayContent: boolean;
    available: boolean;
    isHovered: boolean;
}

class AvailabilityOverview extends Component<IComponentPublicProps, IComponentState> {

    constructor(props: IComponentPublicProps) {
        super(props);

        this.state = {
            showOverlay: false,
            immediatelyShowOverlayContent: props.withinSidePanel && props.withinSidePanel.immediatelyShowOverlay,
            available: isInAvailableOrUnavailableMode(props.availabilities),
            isHovered: false,
        };

        this.onSelectAvailabilities = this.onSelectAvailabilities.bind(this);
        this.onCloseEditOverlay = this.onCloseEditOverlay.bind(this);
        this.getDay = this.getDay.bind(this);
        this.onSubmitChanges = this.onSubmitChanges.bind(this);
        this.clearSaveAsyncInfo = this.clearSaveAsyncInfo.bind(this);
        this.onMouseEnterHandle = this.onMouseEnterHandle.bind(this);
        this.onMouseLeaveHandle = this.onMouseLeaveHandle.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IComponentPublicProps) {
        if ((this.state.showOverlay || this.props.withinSidePanel)
            && nextProps.saveAsyncInfo && nextProps.saveAsyncInfo.status === AsyncStatus.Success
            && this.props.saveAsyncInfo && this.props.saveAsyncInfo.status === AsyncStatus.Busy) {
            // after successful save from withing the overlay:
            // - automatically close the overlay
            this.setState({
                showOverlay: false,
            });

            if (this.props.withinSidePanel && this.props.withinSidePanel.closeOverlay) {
                this.props.withinSidePanel.closeOverlay();
            }
        }
    }

    public shouldComponentUpdate(nextProps: IComponentPublicProps, nextState: IComponentState) {
        if (this.state.showOverlay !== nextState.showOverlay) {
            return true;
        }

        if (this.props.saveAsyncInfo !== nextProps.saveAsyncInfo ||
            this.props.saveAsyncInfo.status !== nextProps.saveAsyncInfo.status) {
            return true;
        }

        if (this.state.isHovered !== nextState.isHovered) {
            return true;
        }

        if (this.props.availabilities !== nextProps.availabilities) {
            return true;
        }

        return false;
    }

    public render() {
        const {
            availabilityType,
            companyName,
            isCopyToAllSeatsAllowed,
            mayEdit,
            saveAsyncInfo,
            translator,
            withinSidePanel,
            availabilities,
            id,
        } = this.props;
        const { showOverlay, immediatelyShowOverlayContent, available, isHovered } = this.state;

        const availabilitiesIncomplete = areAvailabilitiesIncomplete(availabilities);

        if (mayEdit && immediatelyShowOverlayContent) {
            return (
                <AvailabilityOverviewOverlay
                    id={id}
                    show={true}
                    isAlreadyWrappedInAnOverlayCurtain={true}
                    onCloseIntent={this.props.withinSidePanel.closeOverlay}
                    companyName={companyName}
                    availabilityType={availabilityType}
                    available={available}
                    availabilities={availabilities}
                    onSubmitChanges={this.onSubmitChanges}
                    saveAsyncInfo={saveAsyncInfo}
                    translator={translator}
                    isCopyToAllSeatsAllowed={isCopyToAllSeatsAllowed}
                />
            );
        }

        const overviewClass = classNames(CLASS_NAME, {
            [`${CLASS_NAME}__not-available`]: !available,
            [`${CLASS_NAME}--editable`]: !!mayEdit,
            [`${CLASS_NAME}--hover`]: isHovered && mayEdit,
            [`${CLASS_NAME}--small`]: !!withinSidePanel,
        });

        return (
            <div className={overviewClass}>
                <Loader show={saveAsyncInfo.status} />
                {mayEdit && !availabilitiesIncomplete && (
                    <div
                        className={`${CLASS_NAME}__edit`}
                        onMouseEnter={this.onMouseEnterHandle}
                        onMouseLeave={this.onMouseLeaveHandle}
                    >
                        <Button
                            id="edit-availabilities"
                            typeName="text"
                            onClick={() => {
                                this.onSelectAvailabilities();
                            }}
                        >
                            <Icon typeName="pencil" circle />
                        </Button>
                    </div>
                )}
                <div className={`${CLASS_NAME}__wrapper`}>
                    <div className="days">
                        {Object.keys(AVAILABILITY_DAYS).map((weekDayKey) =>
                            this.renderDayCheckbox(weekDayKey))
                        }
                    </div>
                    <div
                        className="availabilities"
                        onMouseEnter={this.onMouseEnterHandle}
                        onMouseLeave={this.onMouseLeaveHandle}
                        onClick={() => {
                            if (mayEdit) {
                                this.onSelectAvailabilities();
                            }
                        }}
                    >
                        {mayEdit && availabilitiesIncomplete && (
                            <div className={`${CLASS_NAME}__edit-incomplete`}>
                                <Button
                                    id="edit-incomplete-availabilities"
                                    onClick={() => {
                                        this.onSelectAvailabilities();
                                    }}
                                >
                                    <Icon typeName="pencil" />
                                    <Translate
                                        msg="administration.company_info.availability_overview.edit_incomplete"
                                    />
                                </Button>
                            </div>
                        )}
                        {Object.keys(AVAILABILITY_DAYS).map((weekDayKey, index) =>
                            this.renderDayAvailabilities(weekDayKey, index === 0))
                        }
                    </div>
                </div>
                <div className={`${CLASS_NAME}__legend`}>
                    <span className="available">
                        <Translate msg="administration.company_info.availability_overview.legend.available" />
                    </span>
                    <span className="not-available">
                        {/* eslint-disable-next-line max-len */}
                        <Translate msg="administration.company_info.availability_overview.legend.not_available" />
                    </span>
                    <ErrorDialog
                        asyncInfo={saveAsyncInfo}
                        titleTranslationKey="administration.company_info.availability_overview.save_error.title"
                        onCloseDialog={this.clearSaveAsyncInfo}
                    />
                </div>
                <AvailabilityOverviewOverlay
                    id={id}
                    show={showOverlay}
                    onCloseIntent={this.onCloseEditOverlay}
                    companyName={companyName}
                    availabilityType={availabilityType}
                    available={available}
                    availabilities={availabilities}
                    onSubmitChanges={this.onSubmitChanges}
                    saveAsyncInfo={saveAsyncInfo}
                    translator={translator}
                    isCopyToAllSeatsAllowed={isCopyToAllSeatsAllowed}
                />
            </div>
        );
    }

    private onSelectAvailabilities() {
        if (this.props.withinSidePanel) {
            this.props.withinSidePanel.onSelectAvailabilities();
        } else {
            this.setState({
                showOverlay: true,
            });
        }
    }

    private onCloseEditOverlay() {
        this.setState({
            showOverlay: false,
        });
    }

    private getDay(weekDayKey: string): IDayAvailability {
        const availabilities = this.props.availabilities;
        const dayHasMorningAvailability = availabilities.morning.days &&
            availabilities.morning.days.find((day) => day.toString() === weekDayKey);
        const dayHasAfternoonAvailability = availabilities.afternoon.days &&
            availabilities.afternoon.days.find((day) => day.toString() === weekDayKey);
        return {
            morning: {
                endTime: dayHasMorningAvailability && availabilities.morning.endTime,
                startTime: dayHasMorningAvailability && availabilities.morning.startTime,
            },
            afternoon: {
                endTime: dayHasAfternoonAvailability && availabilities.afternoon.endTime,
                startTime: dayHasAfternoonAvailability && availabilities.afternoon.startTime,
            },
        };
    }

    private onSubmitChanges(applyFullFamily: boolean, availabilitiesToSave: TCreateCompanyAvailabilities) {
        this.props.onSaveAvailabilities({
            applyFullFamily,
            availabilities: {
                afternoon: availabilitiesToSave.afternoon,
                morning: availabilitiesToSave.morning,
                available: this.state.available,
            },
        });
    }

    private clearSaveAsyncInfo() {
        this.props.resetSaveAvailabilities();
    }

    private renderDayCheckbox(weekDayKey: string) {
        const day = this.getDay(weekDayKey);

        const noAvailabilities = dayHasNoAvailabilities(day);
        const availabilitiesIncomplete = areAvailabilitiesIncomplete(this.props.availabilities);

        const dayClass = classNames('day', {
            disabled: noAvailabilities && !availabilitiesIncomplete,
        });

        return (
            <div
                key={`dayName-${weekDayKey}`}
                className={dayClass}
            >
                <>
                    <span className="short">
                        <Translate msg={`app_shell.dates.days_short.${AVAILABILITY_DAYS[weekDayKey]}`} />
                    </span>
                    <span className="full">
                        <Translate msg={`app_shell.dates.days.${AVAILABILITY_DAYS[weekDayKey]}`} />
                    </span>
                </>
            </div>
        );
    }

    private renderDayAvailabilities(weekDayKey: string, shouldRenderLabels: boolean) {
        const { mayEdit, availabilities } = this.props;
        const day = this.getDay(weekDayKey);

        const noAvailabilities = dayHasNoAvailabilities(day);
        const availabilitiesIncomplete = areAvailabilitiesIncomplete(availabilities);

        const dayClass = classNames('day', {
            disabled: noAvailabilities && !availabilitiesIncomplete,
            readonly: !mayEdit,
        });

        return (
            <div
                key={`dayAvailabilities-${weekDayKey}`}
                className={dayClass}
                onClick={() => {
                    if (mayEdit) {
                        this.onSelectAvailabilities();
                    }
                }}
            >
                <div className="hours">
                    {this.renderDayHours(shouldRenderLabels)}
                    {this.renderAvailabilityBlocks(day)}
                </div>
            </div>
        );
    }

    private renderDayHours(shouldRenderLabels: boolean) {
        return (
            <>
                {AVAILABILITY_HOURS.map((hour) => {
                    return (
                        <div
                            className="hour"
                            key={`hour-label-${hour}`}
                        >
                            {shouldRenderLabels &&
                                <span
                                    className="labels"
                                    data-start-hour={`${hour}:00`}
                                    data-end-hour={(hour + 1) === AVAILABILITY_HOUR_LIMITS.max ? `${hour + 1}:00` : ''}
                                />
                            }
                        </div>
                    );
                })}
            </>
        );
    }

    private renderAvailabilityBlocks(day: IDayAvailability) {
        return (
            <>
                {Object.keys(day).map((availability, index) => {
                    const availabilityBlock: ICompanyAvailabilityBlock = day[availability];
                    if (!availabilityBlock.endTime || !availabilityBlock.startTime) {
                        return null;
                    }
                    const styles: CSSProperties = calculateSpanProps(availabilityBlock);

                    return (
                        <div
                            className="block"
                            key={`${availabilityBlock.startTime}_${index}`}
                        >
                            <span className="indicator" style={styles} />
                        </div>
                    );
                })}
            </>
        );
    }

    private onMouseEnterHandle() {
        this.setState({
            isHovered: true,
        });
    }

    private onMouseLeaveHandle() {
        this.setState({
            isHovered: false,
        });
    }
}

export default AvailabilityOverview;

function calculateSpanProps(availability: ICompanyAvailabilityBlock) {
    const { startPercentage, endPercentage } = toAvailabilityDisplayBlock(availability);

    // 1 = full day
    const width = endPercentage - startPercentage;
    const offset = startPercentage;

    return {
        width: width > 0 ? `calc(${width * 100}% + 1px)` : 0, // + 1px to compensate the border of parent element
        marginLeft: offset > 0 ? `${offset * 100}%` : 0,
    };
}

function dayHasNoAvailabilities(day: IDayAvailability) {
    return !day.morning.startTime &&
        !day.morning.endTime &&
        !day.afternoon.startTime &&
        !day.afternoon.endTime;
}
