import isSet from '@snipsonian/core/es/is/isSet';
import { IAction } from '@snipsonian/redux/es/action/types';
import registerJourneyToFetchAsyncEntitiesOnAction
    from '@snipsonian/redux-features/es/entities/registerJourneyToFetchAsyncEntitiesOnAction';
import {
    IActionType2AsyncEntitiesToFetchMap,
} from '@snipsonian/redux-features/es/entities/types';
import { mapToFetchApiConfigMap } from '@snipsonian/redux-features/es/entities/mapAsyncEntityKey2ApiConfigMap';
import { ON_ACTION_TYPE_REGEX } from '../../config/redux.config';
import asyncEntityKey2ApiConfigMap from '../../config/entities/asyncEntityKey2ApiConfigMap';
import { IState } from '../IState';
import { getRoutes } from '../../routes';

registerJourneyToFetchAsyncEntitiesOnAction({
    onActionTypeRegex: ON_ACTION_TYPE_REGEX.ALL_ROUTE_ACTIONS,
    actionType2AsyncEntitiesToFetchMap: getActionType2AsyncEntitiesToFetchMap(),
    asyncEntityKey2ApiConfigMap: mapToFetchApiConfigMap<IState>(asyncEntityKey2ApiConfigMap),
});

function getActionType2AsyncEntitiesToFetchMap(): IActionType2AsyncEntitiesToFetchMap<IState, IAction<{}>> {
    const routes = getRoutes();

    const entitiesToFetchOnCertainRoutes = Object.keys(routes)
        .filter((routeKey) => isSet(routes[routeKey].entitiesToFetch))
        .reduce(
            (accumulator, routeKey) => {
                // eslint-disable-next-line no-param-reassign
                accumulator[routeKey] = routes[routeKey].entitiesToFetch;
                return accumulator;
            },
            {} as IActionType2AsyncEntitiesToFetchMap<IState, IAction<{}>>,
        );

    return entitiesToFetchOnCertainRoutes;
}
