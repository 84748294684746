import { AxiosRequestConfig } from 'axios';

interface IRequestCallLog {
    [key: string]: number;
}

let requestCallLog: IRequestCallLog = {};

export function markAsSuccessfulRequest(request: AxiosRequestConfig) {
    requestCallLog[request.url] = Date.now();
}

export function shouldBeRefreshed(request: AxiosRequestConfig, noRefreshPeriodInMillis: number) {
    const lastCalledOn = getLastCalledOn(request);

    if (!lastCalledOn) {
        return true;
    }

    return lastCalledOn + noRefreshPeriodInMillis < Date.now();
}

export function forceRefreshNextTime(request: AxiosRequestConfig) {
    requestCallLog[request.url] = undefined;
}

export function getLastCalledOn(request: AxiosRequestConfig) {
    return requestCallLog[request.url];
}

// for test purposes
export function getRequestCallLog() {
    return requestCallLog;
}

// for test purposes
export function clearRequestCallLog() {
    requestCallLog = {};
}
