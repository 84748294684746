import React from 'react';
import Dialog from '../../../../../common/modals/Dialog';
import Translate from '../../../../../common/Translate';
import { ICaseManager } from '../../../../../../models/contact/caseManager';
import { connect } from '../../../../..';
import { getCaseManager, getCaseManagerAsyncInfo } from '../../../../../../redux/contact/selectors';
import ContactInformation from '../../../../../contact/shared/ContactInformation';
import Loader from '../../../../../common/waiting/Loader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import './dialog.scss';

interface IPublicProps {
    open: boolean;
    onCloseDialog: () => void;
}

interface IPrivateProps {
    caseManager: ICaseManager;
    caseManagerAsyncInfo: IAsyncFieldInfo;
}

function CantRemoveCourseAttendeeDialog(props: IPrivateProps & IPublicProps) {
    const {
        open,
        onCloseDialog,
        caseManager,
        caseManagerAsyncInfo,
    } = props;

    return (
        <Dialog
            show={open}
            onCloseIntent={() => onCloseDialog()}
            header="document_center.courses.planned.detail.content.attendees.dialog.title"
            type="error"
        >
            <Loader show={caseManagerAsyncInfo.status === AsyncStatus.Busy}>
                <p>
                    <Translate msg="document_center.courses.planned.detail.content.attendees.dialog.text" />
                </p>

                <ContactInformation
                    contact={caseManager}
                    contactType={
                        <Translate msg="contact.overview.contacts.case_manager"/>
                    }
                />
            </Loader>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        caseManager: getCaseManager(state),
        caseManagerAsyncInfo: getCaseManagerAsyncInfo(state),
    }),
})(CantRemoveCourseAttendeeDialog);
