import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../index';
import {
    FETCH_LEGAL_PRIVACY_POLICY,
    FETCH_LEGAL_DISCLAIMER,
    FETCH_LEGAL_COOKIE_CONSENT,
    FETCH_COOKIES_CONFIGURATION,
} from './types';
import { REDUCER_KEYS } from '../../config/redux.config';
import { ICmsPage } from '../../models/general/cms';
import { ICMSCookiesConfiguration } from '../../models/general/cookieConsent';

export const reducerKey = REDUCER_KEYS.LEGAL;

export interface IReducerState {
    cookieConsentContent: IAsyncFetchField<ICmsPage>;
    privacyPolicyContent: IAsyncFetchField<ICmsPage>;
    disclaimerContent: IAsyncFetchField<ICmsPage>;
    cookiesConfiguration: IAsyncFetchField<ICMSCookiesConfiguration>;
}

const initialState: IReducerState = {
    cookieConsentContent: getAsyncFetchInitialState(),
    privacyPolicyContent: getAsyncFetchInitialState(),
    disclaimerContent: getAsyncFetchInitialState(),
    cookiesConfiguration: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<ICmsPage, IReducerState, ICmsPage>({
        baseActionType: FETCH_LEGAL_COOKIE_CONSENT,
        fieldName: 'cookieConsentContent',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICmsPage, IReducerState, ICmsPage>({
        baseActionType: FETCH_LEGAL_PRIVACY_POLICY,
        fieldName: 'privacyPolicyContent',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICmsPage, IReducerState, ICmsPage>({
        baseActionType: FETCH_LEGAL_DISCLAIMER,
        fieldName: 'disclaimerContent',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICMSCookiesConfiguration, IReducerState, ICMSCookiesConfiguration>({
        baseActionType: FETCH_COOKIES_CONFIGURATION,
        fieldName: 'cookiesConfiguration',
        resetDataOnTrigger: true,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
    resetStateOnLogout: false,
});
