import { object, boolean } from 'yup';
import { IEmployeeDetails, IUpdateEmployeeJobStudentPayload } from '../../../../../models/admin/employee';
import { ShapeOf } from '../../../../../models/ui/form';
import string from '../../../../../utils/libs/yup/string';

export type FormValues = // eslint-disable-next-line max-len
    Pick<IUpdateEmployeeJobStudentPayload, 'employer' | 'safetyFunction' | 'medicalFunction' | 'studentWorker' | 'sendMail'> &
    Partial<Pick<IEmployeeDetails, 'email'>>;

export const fields: ShapeOf<FormValues> = {
    medicalFunction: 'medicalFunction',
    safetyFunction: 'safetyFunction',
    studentWorker: 'studentWorker',
    employer: 'employer',
    email: 'email',
    sendMail: 'sendMail',
};

export const jobStudentValidation = {
    [fields.medicalFunction]: boolean().nullable(true),
    [fields.safetyFunction]: boolean().nullable(true),
    [fields.sendMail]: boolean().nullable(true),
    [fields.studentWorker]: boolean().nullable(true),
    [fields.employer]: string().when(fields.studentWorker, {
        is: true,
        then: string().trim().required(),
        otherwise: string(),
    }),
    [fields.email]: string().when([fields.studentWorker, fields.sendMail], {
        is: true,
        then: string().email().required(),
        otherwise: string().email().nullable(true),
    }),
};

export const schema = object(jobStudentValidation);
