import React, { ChangeEvent, useState, useEffect } from 'react';
import * as EXIF from 'exif-js';

import './UpdateAvatar.scss';

import { AsyncStatus } from '../../../../../models/general/redux';
import { AVATAR_IMAGE_TYPES } from '../../../../../config/user.config';
import { getMyUserProfile, getUpdateMyAvatarAsyncInfo } from '../../../../../redux/auth/selectors';
import { isIOS } from '../../../../../utils/browser/platform';
import { navigateTo } from '../../../../../redux/location/actions';
import { updateMyAvatarActions } from '../../../../../redux/auth/actions';
import { usePrevious } from '../../../../../utils/hooks/usePrevious';
import { validateFileIsAnImage } from '../../../../../utils/file/fileTypes';
import connect from '../../../../../utils/libs/redux/connect';
import convertBase64ToArrayBuffer from '../../../../../utils/file/convertBase64ToArrayBuffer';
import getRotationByExifOrientation from '../../../../../utils/file/getRotationByExifOrientation';
import ROUTE_KEYS from '../../../../../routeKeys';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import AvatarImage from '../AvatarImage';

import { CLASS_NAME } from './UpdateAvatar.const';
import { IAvatarState, IUpdateAvatarPrivateProps } from './UpdateAvatar.type';

const UpdateAvatar = ({
    updateAsyncInfo,
    updateAvatar,
    userProfile,
    goToAccountSettings,
}: IUpdateAvatarPrivateProps) => {
    const prevUpdateAsyncInfo = usePrevious(updateAsyncInfo);

    const [avatar, setAvatar] = useState<IAvatarState>({
        file: undefined,
        rotation: 0,
        typeErrorTranslationMsg: undefined,
    });

    // Reset image preview and close modal when file upload was successfull
    useEffect(() => {
        if (!prevUpdateAsyncInfo || !updateAsyncInfo) {
            return;
        }

        if (
            prevUpdateAsyncInfo.status === AsyncStatus.Busy
            && updateAsyncInfo.status === AsyncStatus.Success
        ) {
            setAvatar({
                typeErrorTranslationMsg: null,
                rotation: 0,
                file: null,
            });

            goToAccountSettings();
        }
    }, [updateAsyncInfo, prevUpdateAsyncInfo, goToAccountSettings]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            if (isIOS) {
                // iOS will rotate the image by itself
                setAvatar({
                    ...avatar,
                    rotation: 0,
                    file,
                });
            } else {
                const img = reader.result as string;
                const exif = EXIF.readFromBinaryFile(convertBase64ToArrayBuffer(img));
                const rotation = getRotationByExifOrientation(exif.Orientation) || 0;

                setAvatar({
                    file,
                    rotation,
                    typeErrorTranslationMsg: null,
                });
            }
        };

        if (file) {
            if (validateFileIsAnImage(file)) {
                setAvatar({
                    ...avatar,
                    typeErrorTranslationMsg: null,
                });

                reader.readAsDataURL(file);
            } else {
                setAvatar({
                    ...avatar,
                    file: null,
                    typeErrorTranslationMsg: 'account.account_settings.update_avatar.invalid_file_type',
                });
            }
        }
    };

    const onSubmit = () => {
        if (avatar.file) {
            updateAvatar({ avatarFile: avatar.file });
        }
    };

    const imageUrl = avatar.file ? URL.createObjectURL(avatar.file) : userProfile.avatarUrl;

    return (
        <Loader show={updateAsyncInfo.status}>
                <div className={CLASS_NAME}>
                <h4>
                    <Translate msg="account.account_settings.update_avatar.title" />
                </h4>
                <input
                    id="avatar-file"
                    name="avatar"
                    type="file"
                    onChange={handleFileChange}
                    accept={AVATAR_IMAGE_TYPES}
                />
                <label htmlFor="avatar-file">
                    <AvatarImage
                        imageUrl={imageUrl}
                        imageRotation={avatar.rotation}
                    />
                </label>
                <FormError error={updateAsyncInfo.error} />
                <FormError translationKey={avatar.typeErrorTranslationMsg} />
                <Button
                    id="update-avatar-submit"
                    typeName="secondary"
                    onClick={onSubmit}
                    disabled={!avatar.file}
                >
                    <Translate msg="account.account_settings.update_avatar.submit" />
                </Button>
            </div>
        </Loader>
    );

};

export default connect<IUpdateAvatarPrivateProps>({
    stateProps: (state) => {
        return {
            updateAsyncInfo: getUpdateMyAvatarAsyncInfo(state),
            userProfile: getMyUserProfile(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateAvatar: (payload) => dispatch(updateMyAvatarActions.trigger(payload)),
            goToAccountSettings: () => dispatch(navigateTo(ROUTE_KEYS.R_ACCOUNT_SETTINGS)),
        };
    },
})(UpdateAvatar);
