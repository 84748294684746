import React, { Component, PureComponent } from 'react';
import './flat-fee.scss';
import preventionUnitsIllu from '../../assets/img/illustrations/illu_preventie-eenheden.svg';
import { ListColumns, ListItem } from '../../../models/general/list';
import { AsyncStatus } from '../../../models/general/redux';
import {
    IAccomplishmentsContribution,
    IAccomplishmentsContributionDetail,
} from '../../../models/admin/preventionUnits';
import {
    getFlatFeeYears,
    getFlatFeeSelectedYear,
    getAccomplishmentsContributionsOfSelectedYear,
    getAccomplishmentsContributionsOfSelectedYearAsyncInfo,
    getAccomplishmentsContributionDetailsAsyncInfo,
    getAccomplishmentsContributionDetails,
} from '../../../redux/preventionUnits/selectors';
import {
    toAccomplishmentsContributionDetailId,
} from '../../../redux/preventionUnits/actions';
import ROUTE_KEYS from '../../../routeKeys';
import PageHeader from '../../appShell/PageHeader';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import Select from '../../common/input/Select';
import List from '../../common/list/List';
import Translate from '../../common/Translate';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IRenderActionContentProps,
    IRenderDetailContentProps, IRenderDetailHeaderProps,
} from '../../common/widget/MasterWithDetail/typings';
import FlatFeeDetail from './FlatFeeDetail';
import FlatFeeDetailHeader from './FlatFeeDetail/header';
import connect from '../../../utils/libs/redux/connect';
import { splitStringToNumericParts } from '../../../utils/list/sortListItems';
import { numberComparerAscending, partsComparer } from '../../../utils/list/comparerUtils';
import { navigateTo } from '../../../redux/location/actions';

const CLASS_NAME = 'FlatFeeOverview';
const CLASS_NAME_SLIDE_OUT_PANEL = `${CLASS_NAME}__slide-out-panel`;
const BASE_NAME = 'flat-fee-overview';
const TRANSLATION_PREFIX = 'administration.flat_fee.overview';

interface IPrivateProps {
    availableYears: number[];
    selectedYear: number;
    isFetchingContributions: boolean;
    fetchAccomplishmentsContributionsForYear: (year: number) => void;
    goToInvoices: () => void;
}

type TFlatFeeListItem = ListItem<IColumnNames, string, IFlatFeeListItemData>;

interface IFlatFeeListItemData {
    confidential: boolean;
    paragraphParts: number[];
}

interface IColumnNames {
    description: string;
    amount: number;
}

const COLUMNS: ListColumns<IColumnNames> = {
    description: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.description`} />,
        sortable: false,
        percentWidth: 80,
        render: (item) => <AccomplishmentsContributionDescription item={item as TFlatFeeListItem} />,
    },
    amount: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.amount`} />,
        sortable: false,
        percentWidth: 20,
        align: 'right',
    },
};

class FlatFeeOverview extends Component<IPrivateProps> {
    constructor(props) {
        super(props);

        this.mapAccomplishmentsContributionsToListItems = this.mapAccomplishmentsContributionsToListItems.bind(this);
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                <PageHeader
                    breadcrumbs={true}
                    title={`${TRANSLATION_PREFIX}.header.title`}
                    text={`${TRANSLATION_PREFIX}.header.text`}
                    textPlaceholders={{
                        invoicesLink: <span className="placeholderLink">
                            <a onClick={this.props.goToInvoices}>
                                <Translate msg={`${TRANSLATION_PREFIX}.header.invoices_link`} />
                            </a>
                        </span>,
                    }}
                    svg={preventionUnitsIllu}
                    type="grey"
                />
                <MasterWithDetail
                    baseName={BASE_NAME}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_FLAT_FEE_OVERVIEW,
                        asyncInfoSelector: getAccomplishmentsContributionsOfSelectedYearAsyncInfo,
                        dataSelector: getAccomplishmentsContributionsOfSelectedYear,
                        transformData: this.mapAccomplishmentsContributionsToListItems,
                        renderContent: (renderProps: IRenderMasterContentProps<TFlatFeeListItem[]>) =>
                                <FlatFeeOverviewList {...renderProps} />,
                    }}
                    headerConfig={{
                        renderActionContentOnTheLeft:
                            (renderProps: IPrivateProps & IRenderActionContentProps<TFlatFeeListItem[]>) =>
                                <ListHeaderActionContent {...renderProps} {...this.props} />,
                    }}
                    detailConfig={{
                        routeKey: ROUTE_KEYS.R_FLAT_FEE_DETAIL,
                        idRouteParamName: 'yearAndType',
                        asyncInfoSelector: getAccomplishmentsContributionDetailsAsyncInfo,
                        dataSelector: getAccomplishmentsContributionDetails,
                        slideOutPanelClassName: CLASS_NAME_SLIDE_OUT_PANEL,
                        renderContent: (
                            renderProps: IRenderDetailContentProps<IAccomplishmentsContributionDetail[]>,
                        ) => <FlatFeeDetail {...renderProps} />,
                        renderHeader: (renderProps: IRenderDetailHeaderProps<IAccomplishmentsContributionDetail[]>) =>
                            <FlatFeeDetailHeader {...renderProps} />,
                    }}
                />
            </div>
        );
    }

    private mapAccomplishmentsContributionsToListItems(masterData: IAccomplishmentsContribution[]): TFlatFeeListItem[] {
        const { selectedYear } = this.props;

        return masterData
            .map((item) => {
                return {
                    id: toAccomplishmentsContributionDetailId({
                        year: selectedYear,
                        type: item.type,
                    }),
                    columns: {
                        description: item.description,
                        amount: item.amount,
                    },
                    data: {
                        confidential: item.confidential,
                        paragraphParts: splitStringToNumericParts(item.paragraph, '.'),
                    },
                };
            })
            .sort((itemA, itemB) =>
                partsComparer(itemA.data.paragraphParts, itemB.data.paragraphParts, numberComparerAscending));
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        availableYears: getFlatFeeYears(state),
        selectedYear: getFlatFeeSelectedYear(state),
        isFetchingContributions:
            getAccomplishmentsContributionsOfSelectedYearAsyncInfo(state).status === AsyncStatus.Busy,
    }),
    dispatchProps: (dispatch) => ({
        fetchAccomplishmentsContributionsForYear: (year: number) => {
            /**
             * Dispatch of the route instead of the base trigger action so that also the detail
             * gets closed automatically (if it was open).
             */
            dispatch(navigateTo(ROUTE_KEYS.R_FLAT_FEE_OVERVIEW, { year }));
        },
        goToInvoices: () => {
            dispatch(navigateTo(ROUTE_KEYS.R_INVOICES_OPEN));
        },
    }),
})(FlatFeeOverview);

function isFlatFeeListItemClickable(item: TFlatFeeListItem) {
    return !item.data.confidential;
}

function getCustomRowClasses(item: TFlatFeeListItem) {
    const nrOfLevelsDeep = item.data.paragraphParts.length;

    if (nrOfLevelsDeep > 1) {
        return 'nested-row';
    }

    return '';
}

function FlatFeeOverviewList(listProps: IRenderMasterContentProps<TFlatFeeListItem[]>) {
    const {
        masterData: flatFeeListItems,
        selectedItemId,
        onItemSelected,
        masterAsyncInfo,
    } = listProps;

    return (
        <List
            name={BASE_NAME}
            columns={COLUMNS}
            items={flatFeeListItems}
            selectedItemIds={selectedItemId ? [selectedItemId] : []}
            isItemClickable={isFlatFeeListItemClickable}
            onItemRowClicked={onItemSelected}
            errorMessage={masterAsyncInfo.error &&
                <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
            getCustomRowClasses={getCustomRowClasses}
        />
    );
}

class ListHeaderActionContent extends PureComponent<IRenderActionContentProps<TFlatFeeListItem[]> & IPrivateProps> {
    constructor(props) {
        super(props);

        this.onChangeYear = this.onChangeYear.bind(this);
    }

    render() {
        const { selectedYear, availableYears, isFetchingContributions } = this.props;

        if (!selectedYear) {
            return null;
        }

        const yearOptions = availableYears
            .map((yearNumber) => ({
                label: yearNumber.toString(),
                value: yearNumber,
            }));

        return (
            <>
                <Select
                    id="select-flat-fee-year"
                    typeName="primary"
                    value={selectedYear}
                    options={yearOptions}
                    onChange={this.onChangeYear}
                    disabled={isFetchingContributions}
                />
            </>
        );
    }

    private onChangeYear(selectedYear: number) {
        if (selectedYear !== this.props.selectedYear) {
            this.props.fetchAccomplishmentsContributionsForYear(selectedYear);
        }
    }
}

function AccomplishmentsContributionDescription({ item }: { item: TFlatFeeListItem }) {
    return (
        <>
            {item.data.paragraphParts
                .map((part, index) => {
                    if (index === 0) {
                        return null;
                    }
                    return <span className="nested-indent" key={`nested-indent-${index}`} />;
                })
            }
            <span>{item.columns.description}</span>
        </>
    );
}
