import { FETCH_APPOINTMENTS, FETCH_UPCOMING_ACTIVITY, SET_AGENDA_EXPORT_DATA } from './types';
import { createTypeActions } from '../../utils/libs/redux/createAction';
import { TUpcomingActivity, IAppointmentsInfo } from '../../models/planning/activities';
import { IAgendaExportData } from '../../models/planning/agenda';

export const fetchAppointmentsActions = createTypeActions<{}, IAppointmentsInfo>({
    type: FETCH_APPOINTMENTS,
});

export const fetchUpcomingActivityActions = createTypeActions<{}, TUpcomingActivity>({
    type: FETCH_UPCOMING_ACTIVITY,
});

export const setAgendaExportDataActions = createTypeActions<IAgendaExportData[]>({
    type: SET_AGENDA_EXPORT_DATA,
});
