import React, { Component } from 'react';
import { connect } from '../../../index';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import {
    getWorkPostCardWizardEntity,
    getWorkPostCardDefaultValues,
    getWorkPostCardDefaultValuesAsyncInfo,
} from '../../../../redux/documentCenter/workPostCards/selectors';
import {
    IWorkPostCardWizardEntity,
    IWorkPostCardBaseData,
    IWorkPostCardDefaultValues,
} from '../../../../models/documentCenter/workPostCards';
import Translate from '../../../common/Translate';
import { schema, fields } from './dataSchema';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import { TextPropertyInput } from '../../../common/input/PropertyInput';
import { createPropertyInputForDisplay } from '../../../common/forms/FormPropertyInputForDisplay';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FormFieldError from '../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../models/general/i18n';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import {
    tryFormattingPhoneInternational,
} from '../../../../utils/formatting/formatPhone';

export type FormValues = IWorkPostCardBaseData;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    updateWizardEntity: (values: FormValues) => void;
    defaultValues: IWorkPostCardDefaultValues;
    defaultValuesAsyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    translator: ITranslator;
}

const FORM_NAME = 'work-post-card-base-data';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.base_data';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

const DataPropertyInputForDisplay = createPropertyInputForDisplay<FormValues>();

class DataComp extends Component<IStepperStepRenderProps & IPrivateProps & IContextProps> {
    private resetForm: (newValues: FormValues) => void;

    constructor(props: IStepperStepRenderProps & IPrivateProps & IContextProps) {
        super(props);
    }

    public componentDidUpdate(prevProps: IStepperStepRenderProps & IPrivateProps & IContextProps) {
        const { wizardEntity } = this.props;
        if (prevProps.defaultValuesAsyncInfo.status === AsyncStatus.Busy &&
            this.props.defaultValuesAsyncInfo.status === AsyncStatus.Success) {
            const newInitialValues: FormValues = {
                identificationDate: wizardEntity.baseData &&
                    wizardEntity.baseData.identificationDate,
                identificationIdentification: wizardEntity.baseData &&
                    wizardEntity.baseData.identificationIdentification,
                userAddress: wizardEntity.baseData &&
                    wizardEntity.baseData.userAddress,
                userExternalPbw: wizardEntity.baseData &&
                    wizardEntity.baseData.userExternalPbw,
                userName: wizardEntity.baseData &&
                    wizardEntity.baseData.userName,
                userPhoneContact: wizardEntity.baseData &&
                    wizardEntity.baseData.userPhoneContact,
            };
            this.resetForm(newInitialValues);
        }
    }

    public render() {
        const {
            renderStepButtons, wizardEntity, goToNextStep, updateWizardEntity, defaultValuesAsyncInfo, translator,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            identificationDate: wizardEntity.baseData &&
                wizardEntity.baseData.identificationDate,
            identificationIdentification: wizardEntity.baseData &&
                wizardEntity.baseData.identificationIdentification,
            userAddress: wizardEntity.baseData &&
                wizardEntity.baseData.userAddress,
            userExternalPbw: wizardEntity.baseData &&
                wizardEntity.baseData.userExternalPbw,
            userName: wizardEntity.baseData &&
                wizardEntity.baseData.userName,
            userPhoneContact: wizardEntity.baseData &&
                tryFormattingPhoneInternational(wizardEntity.baseData.userPhoneContact),
        };

        function handleSubmit(values: FormValues) {
            updateWizardEntity({
                ...values,
                userPhoneContact: tryFormattingPhoneInternational(values.userPhoneContact),
            });
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values, handleChange, resetForm, errors, touched } = formRenderProps;

                            if (this.resetForm !== resetForm) {
                                this.resetForm = resetForm;
                            }

                            return (
                                <>
                                    {!defaultValuesAsyncInfo.error && (
                                        <Loader show={defaultValuesAsyncInfo.status}>
                                            <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                                <h2><Translate msg={`${BASE_KEY}.form.title.identification`} /></h2>
                                                <DataPropertyInputForDisplay
                                                    name="identificationIdentification"
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    baseId={FORM_NAME}
                                                    values={values}
                                                />
                                                <DataPropertyInputForDisplay
                                                    name="identificationDate"
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    baseId={FORM_NAME}
                                                    values={values}
                                                />
                                                <h2><Translate msg={`${BASE_KEY}.form.title.user`} /></h2>
                                                <DataPropertyInputForDisplay
                                                    name="userName"
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    baseId={FORM_NAME}
                                                    values={values}
                                                />
                                                <DataPropertyInputForDisplay
                                                    name="userAddress"
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    baseId={FORM_NAME}
                                                    values={values}
                                                />
                                                <DataPropertyInputForDisplay
                                                    name="userExternalPbw"
                                                    baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                                    baseId={FORM_NAME}
                                                    values={values}
                                                />
                                                <TextPropertyInput
                                                    id={`${FORM_NAME}-user-phone-contact`}
                                                    labelKey={`${BASE_FIELD_TRANSLATION_KEY}.user_phone_contact`}
                                                    name={fields.userPhoneContact}
                                                    value={values.userPhoneContact}
                                                    readonly={false}
                                                    onChange={handleChange}
                                                    isInvalid={touched.userPhoneContact && !!errors.userPhoneContact}
                                                >
                                                    {touched.userPhoneContact &&
                                                        <FormFieldError
                                                            ignoreTranslationContext
                                                            error={errors.userPhoneContact}
                                                            placeholders={{
                                                                fieldName: translator(
                                                                    `${BASE_FIELD_TRANSLATION_KEY}.user_phone_contact`,
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                </TextPropertyInput>
                                            </div>
                                            <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                                {renderStepButtons({
                                                    nextButton: {
                                                        isSubmit: true,
                                                        formName: FORM_NAME,
                                                        disabled: false,
                                                    },
                                                })}
                                            </StickyFooter>
                                        </Loader>
                                    )}
                                    {defaultValuesAsyncInfo.error && (
                                        <ErrorPlaceholder apiError={defaultValuesAsyncInfo.error} />
                                    )}
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
            defaultValues: getWorkPostCardDefaultValues(state),
            defaultValuesAsyncInfo: getWorkPostCardDefaultValuesAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                dispatch(updateWorkPostCardWizardEntity({
                    baseData: {
                        ...values,
                    },
                }));
            },
        };
    },
})(Data);

function Data(props: IPrivateProps & IStepperStepRenderProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <DataComp {...props} translator={translator} />
            )}
        </TranslatorContext.Consumer>
    );
}
