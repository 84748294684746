import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import isSet from '@snipsonian/core/es/is/isSet';
import './master-with-detail.scss';
import Header from './Header';
import Master, { clientSideFilter } from './Master';
import Detail, {
    getDetailIdRouteParamName,
    getFirstLevelRouteKey,
    isOnDetailRoute,
} from './Detail';
import { connect } from '../../../index';
import { navigateTo } from '../../../../redux/location/actions';
import {
    getQueryParams, getRouteKey,
    getRoutePayload as getCurrentRoutePayload,
} from '../../../../redux/location/selectors';
import { removeEmptyPropsFromObject } from '../../../../utils/core/object/objectProps';
import { PAYLOAD_PARAM } from '../../../../utils/libs/redux/async/asyncReducerUtils';
import {
    IMasterWithDetailPublicProps, TMasterData, IActiveFilter,
} from './typings';
import { IState, NO_RERENDER } from '../../../../redux';
import ActiveFilters from './Master/ActiveFilters';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { getTranslatorForContext } from '../../../../redux/i18n/selectors';

export const CLASS_NAME = 'MasterWithDetail';

interface IPrivateProps {
    isOnDetailRoute: boolean;
    masterData: TMasterData;
    clientSideFilteredListItems?: TMasterData;
    filterValues: object;
    activeFilters: IActiveFilter[];
    showAll: () => void;
    filterAndSetUrlQueryParams: (filter: object) => void;
    selectItem: (id: string | number, firstLevelDetailIdRouteParamName: string) => void;
    closeDetail: () => void;
    firstLevelDetailIdRouteParamName: string;
    masterAsyncInfo: IAsyncFieldInfo;
}

interface IComponentState {
    isFilterPanelOpen: boolean;
    slideOutPanelElementToFocus: string;
}

class MasterWithDetail extends PureComponent<IPrivateProps & IMasterWithDetailPublicProps, IComponentState> {
    static defaultProps: any;

    constructor(props: IPrivateProps & IMasterWithDetailPublicProps) {
        super(props);

        this.state = {
            isFilterPanelOpen: false,
            slideOutPanelElementToFocus: null,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.onFilterPanelCloseIntent = this.onFilterPanelCloseIntent.bind(this);
    }

    public render() {
        const {
            baseName, masterConfig, detailConfig, headerConfig, footerConfig,
            masterData, clientSideFilteredListItems,
            filterValues, firstLevelDetailIdRouteParamName,
            className, classNameWhenOnDetailRoute, isOnDetailRoute,
            masterAsyncInfo,
        } = this.props;
        const { isFilterPanelOpen, slideOutPanelElementToFocus } = this.state;

        const masterWithDetailClasses = classNames(CLASS_NAME, className, {
            [classNameWhenOnDetailRoute]: classNameWhenOnDetailRoute && isOnDetailRoute,
        });

        return (
            <div className={masterWithDetailClasses}>
                <div className={`${CLASS_NAME}__content container`}>
                    {headerConfig &&
                        <Header
                            baseName={baseName}
                            baseClassName={CLASS_NAME}
                            masterAsyncInfoSelector={masterConfig.asyncInfoSelector}
                            headerConfig={headerConfig}
                            isFilterPanelOpen={isFilterPanelOpen}
                            slideOutPanelElementToFocus={slideOutPanelElementToFocus}
                            masterData={masterData}
                            clientSideFilteredListItems={clientSideFilteredListItems}
                            onFilterButtonClick={this.onFilterButtonClick}
                            onFilterSubmit={this.onFilterSubmit}
                            onFilterPanelCloseIntent={this.onFilterPanelCloseIntent}
                            filterValues={filterValues}
                            untransformedMasterDataSelector={masterConfig.dataSelector}
                            filterValidationSchema={masterConfig.filterValidationSchema}
                        />
                    }

                    <ActiveFilters
                        baseName={baseName}
                        baseClassName={CLASS_NAME}
                        transformFilterValuesToActiveFilters={masterConfig.transformFilterValuesToActiveFilters}
                        masterData={masterData}
                        filterValues={filterValues}
                        onFilterSubmit={this.onFilterSubmit}
                        masterAsyncInfoSelector={masterConfig.asyncInfoSelector}
                    />

                    {masterConfig.renderWarning &&
                        <div className={`${CLASS_NAME}__warning`}>
                            {masterConfig.renderWarning({
                                masterAsyncInfo,
                                masterData: clientSideFilteredListItems || masterData,
                            })}
                        </div>
                    }

                    <div className={`${CLASS_NAME}__content-wrapper`}>
                        <Master
                            baseClassName={CLASS_NAME}
                            masterConfig={masterConfig}
                            masterData={masterData}
                            clientSideFilteredListItems={clientSideFilteredListItems}
                            filterValues={filterValues}
                            onFilterSubmit={this.onFilterSubmit}
                            canItemBeSelected={!!detailConfig}
                            detailIdRouteParamName={firstLevelDetailIdRouteParamName}
                            onItemSelected={this.onItemSelected}
                            footerConfig={footerConfig}
                            onShowAll={this.props.showAll}
                        />
                        <Detail
                            detailConfig={detailConfig}
                            closeDetail={this.props.closeDetail}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onItemSelected(itemId: string | number) {
        const transformSelectedItemId = this.props.masterConfig.transformSelectedItemId;
        const id = transformSelectedItemId ? transformSelectedItemId(itemId) : itemId;

        this.setState({
            isFilterPanelOpen: false,
        });

        this.props.selectItem(id, this.props.firstLevelDetailIdRouteParamName);
    }

    private onFilterButtonClick(elementToFocus: string) {
        if (!this.state.isFilterPanelOpen) {
            if (this.props.isOnDetailRoute) {
                this.props.closeDetail();
            }
            this.setState({
                isFilterPanelOpen: true,
                slideOutPanelElementToFocus: elementToFocus,
            });
        }
    }

    public onFilterSubmit(filters: object) {
        // removes undefined, null or emptyString props from filterObj
        const newFilters = removeEmptyPropsFromObject(filters);
        this.props.filterAndSetUrlQueryParams(newFilters);

        this.setState({
            isFilterPanelOpen: false,
        });
    }

    private onFilterPanelCloseIntent() {
        this.setState({
            isFilterPanelOpen: false,
        });
    }
}

export default connect<IPrivateProps, IMasterWithDetailPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        const currentRouteKey = getRouteKey(state);
        const isOnDetailRouteMemoizedSelector = createSelector(
            getRouteKey,
            (routeKey) => isOnDetailRoute(publicProps.detailConfig, routeKey),
        );

        const masterDataMemoizedSelector = makeMasterDataMemoizedSelector(publicProps);
        const filterValuesMemoizedSelector = makeFilterValuesMemoizedSelector(publicProps);

        const clientSideFilterCustomDataSelector = publicProps.masterConfig.clientSideFilterCustomDataSelector
            ? publicProps.masterConfig.clientSideFilterCustomDataSelector
            : (state: IState) => NO_RERENDER.EMPTY_OBJECT;
        const clientSideFilteredListItemsMemoizedSelector = createSelector(
            masterDataMemoizedSelector,
            filterValuesMemoizedSelector,
            clientSideFilterCustomDataSelector,
            (masterData, filterValues, customData) => clientSideFilter(
                masterData,
                publicProps.masterConfig.clientSideSearchOfListData,
                publicProps.masterConfig.clientSideFilterOfListData,
                filterValues,
                customData,
            ),
        );

        const firstLevelDetailIdRouteParamName = getDetailIdRouteParamName({
            detailConfig: publicProps.detailConfig,
            level: 1,
            currentRouteKey,
        });

        const masterAsyncInfo = publicProps.masterConfig.asyncInfoSelector(state);

        /**
         * Return mapStateToProps function (instead of an object) so that each MasterWithDetail instance
         * will have its own mapStateToProps function (making memoization possible)
         */
        return (state) => {
            return {
                isOnDetailRoute: isOnDetailRouteMemoizedSelector(state),
                masterData: masterDataMemoizedSelector(state),
                clientSideFilteredListItems: clientSideFilteredListItemsMemoizedSelector(state),
                filterValues: filterValuesMemoizedSelector(state),
                firstLevelDetailIdRouteParamName,
                masterAsyncInfo,
            };
        };
    },
    dispatchPropsPerInstance: (dispatch, getState, publicProps) => {
        const getDefaultQueryParams = publicProps.getDefaultQueryParams || returnEmptyObject;

        function getRoutePayload() {
            return publicProps.masterConfig.routePayload
                ? unsetPreviousRouteFlags(publicProps.masterConfig.routePayload)
                : {};
        }

        return (dispatch) => {
            return {
                showAll: () => {
                    const currentQuery = getQueryParams(getState());
                    dispatch(
                        navigateTo(
                            publicProps.masterConfig.routeKey,
                            getRoutePayload(),
                            {
                                // TODO: speed up list rendering by virtualizing the list (MENSKLNTZN-592)
                                ...getDefaultQueryParams({ isShowAll: true }),
                                ...currentQuery,
                            },
                        ));
                },
                filterAndSetUrlQueryParams: (filters: object) => {
                    dispatch(
                        navigateTo(
                            publicProps.masterConfig.routeKey,
                            getRoutePayload(),
                            {
                                ...getDefaultQueryParams({ isShowAll: false }),
                                ...filters,
                            }));
                },
                selectItem: (id: string | number, firstLevelDetailIdRouteParamName: string) => {
                    const state = getState();
                    const currentRouteKey = getRouteKey(state);
                    const firstLevelRouteKey = getFirstLevelRouteKey(publicProps.detailConfig, currentRouteKey);

                    const currentQuery = getQueryParams(state);
                    const currentPayload = getRoutePayload() || getCurrentRoutePayload(state);

                    if (id !== currentPayload[firstLevelDetailIdRouteParamName]) {
                        dispatch(
                            navigateTo(
                                firstLevelRouteKey,
                                {
                                    ...currentPayload,
                                    [firstLevelDetailIdRouteParamName]: id,
                                },
                                {
                                    ...currentQuery,
                                }));
                    }
                },
                closeDetail: () => {
                    const currentQuery = getQueryParams(getState());
                    dispatch(
                        navigateTo(
                            publicProps.masterConfig.routeKey,
                            {
                                ...getRoutePayload(),
                                [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                            },
                            {
                                ...currentQuery,
                            }));
                },
            };
        };
    },
})(MasterWithDetail);

function returnEmptyObject() {
    return ({});
}

function makeMasterDataMemoizedSelector(props: IMasterWithDetailPublicProps) {
    return createSelector(
        (state: IState) => props.masterConfig.dataSelector(state),
        (state: IState) => getTranslatorForContext(state),
        (untransformedMasterData, translator) =>
            props.masterConfig.transformData
                ? props.masterConfig.transformData(untransformedMasterData, translator)
                : untransformedMasterData,
    );
}

function makeFilterValuesMemoizedSelector(props: IMasterWithDetailPublicProps) {
    const getDefaultQueryParams = props.getDefaultQueryParams || returnEmptyObject;

    return createSelector(
        getQueryParams,
        (queryParams) => ({
            ...getDefaultQueryParams({ isShowAll: false }),
            ...queryParams,
        }),
    );
}

function unsetPreviousRouteFlags(routePayload: object): object {
    if (isSet(routePayload[PAYLOAD_PARAM.SHOULD_REFRESH_DATA])) {
        const cloneRoutePayload = { ...routePayload };
        delete cloneRoutePayload[PAYLOAD_PARAM.SHOULD_REFRESH_DATA];
        return cloneRoutePayload;
    }

    return routePayload;
}
