import React, { Component } from 'react';
import './edit-contact.scss';
import { schema } from '../ContactInput/schema';
import { IFormValues as IDetailFormValues } from '../../FluVaccinesOrders/detail';
import { connect } from '../../..';
import { IFluVaccineContact } from '../../../../models/interventions/fluVaccines';
import { ICompanyContact } from '../../../../models/admin/companyInfo';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { getCompanyContactsAsyncInfo } from '../../../../redux/company/info/selectors';
import Button from '../../../common/buttons/Button';
import SubmitButton from '../../../common/buttons/SubmitButton';
import Form, { IFormRenderProps, TSetFieldValue } from '../../../common/forms/Form';
import Loader from '../../../common/waiting/Loader';
import Translate from '../../../common/Translate';
import ContactInput, { IFormValues } from '../ContactInput/ContactInput';

const CLASS_NAME = 'FluVaccinesOrderEditContact';
const FORM_NAME = 'flu-vaccine-order-edit-contact-form';

interface IPrivateProps {
    companyContactsAsyncInfo: IAsyncFieldInfo;
}
interface IEditContactProps {
    onConfirm: (data: IFluVaccineContact) => void;
    onClose: () => void;
    detailFormValues: IDetailFormValues;
}

class EditContact extends Component<IPrivateProps & IEditContactProps> {
    private setFormFieldValue: TSetFieldValue<IFormValues>;

    constructor(props) {
        super(props);

        this.contactChangedHandler = this.contactChangedHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const { onClose, companyContactsAsyncInfo, detailFormValues } = this.props;

        if (!detailFormValues) {
            return null;
        }

        const INITIAL_VALUES: IFormValues = {
            contact: mapFluVaccineContactToContact(detailFormValues.contact),
        };

        return (
            <div className={CLASS_NAME}>
                <h2>
                    <Translate
                        msg="interventions.flu_orders_detail.edit_contact.title"
                    />
                </h2>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onSubmit}
                    initialValues={INITIAL_VALUES}
                    schema={schema}
                    render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                        this.setFormFieldValue = formRenderProps.setFieldValue;

                        return (
                            <Loader show={companyContactsAsyncInfo.status}>
                                <div>
                                    <ContactInput
                                        formRenderProps={formRenderProps}
                                        onChangeInput={this.contactChangedHandler}
                                    />
                                </div>
                                <div className={`${CLASS_NAME}__actions`}>
                                    <Button
                                        id="edit-contact-cancel-button"
                                        typeName="secondary"
                                        outline={true}
                                        onClick={onClose}
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_contact.cancel" />
                                    </Button>
                                    <SubmitButton
                                        formName={FORM_NAME}
                                        id="edit-contact-submit-button"
                                    >
                                        <Translate msg="interventions.flu_orders_detail.edit_contact.submit" />
                                    </SubmitButton>
                                </div>
                            </Loader>
                        );
                    }}
                />

            </div>
        );
    }

    private contactChangedHandler(values: IFormValues) {
        this.setFormFieldValue('contact', values.contact);
    }

    private onSubmit(values: IFormValues) {
        this.props.onConfirm(mapContactToFluVaccineContact(values.contact));
    }
}

export default connect<IPrivateProps, IEditContactProps>({
    stateProps: (state) => {
        return {
            companyContactsAsyncInfo: getCompanyContactsAsyncInfo(state),
        };
    },
})(EditContact);

function mapContactToFluVaccineContact(contact: ICompanyContact): IFluVaccineContact {
    return {
        id: contact.customerContactId,
        name: contact.name,
        firstName: contact.firstName,
        email: contact.email,
        mobilePhone: contact.mobilePhone,
        phone: contact.phone,
    };
}

function mapFluVaccineContactToContact(fluVaccineContact: IFluVaccineContact): ICompanyContact {
    return {
        ...fluVaccineContact,
        id: null,
        customerContactId: fluVaccineContact.id,
    } as ICompanyContact;
}
