/* eslint-disable max-len */

import React from 'react';
import './animated-leafs.scss';

export default function AnimatedLeafs() {
    return (
        <svg className="AnimatedLeafs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 236">
            <g fill="#2F9E1E" fillRule="evenodd">
                <path
                    className="base"
                    d="M164.93359,103.537506 C166.538097,102.638458 182.921618,94.2348605 197.788379,86.5430086 L197.788379,93.5356012 C183.560914,100.378353 166.68852,108.632109 159.029506,113.0774 C146.406548,119.495601 135.212604,126.563114 129.458942,135.790839 C133.332294,133.677463 137.373964,131.885786 141.542886,130.434014 C145.443775,129.221877 149.612271,129.152526 153.551618,130.234226 C158.07683,123.578776 170.511759,122.042903 182.382604,126.937718 C194.917816,132.119728 202.02528,142.571157 198.252182,150.263009 C194.479083,157.95486 181.26697,159.990204 168.731759,154.808194 C157.713309,150.250522 150.844013,141.672109 151.922041,134.429781 C148.496039,133.17937 144.708398,133.359466 141.417534,134.929252 C135.846573,137.362252 130.558832,140.393676 125.648238,143.969675 C123.281266,149.638415 121.255332,155.442554 119.581196,161.351263 C123.015844,156.880998 129.947816,148.86449 136.779506,146.50449 C142.94683,144.731368 145.942745,147.753167 147.384294,150.812427 C156.29683,151.26195 164.845844,162.112956 166.926689,176.060681 C169.120351,190.820046 163.241337,203.444173 153.802323,204.2683 C144.363309,205.092427 134.911759,193.791898 132.718097,179.045019 C130.787675,166.046289 135.112323,154.695813 142.633449,151.57412 C141.467675,149.850945 139.524717,149.001845 136.290632,150.425337 C120.182886,156.656236 114.115844,184.127136 114.115844,184.127136 C107.986125,208.751051 111.784294,235.460257 111.784294,235.460257 L103.21021,235.322903 C97.1180973,194.690945 115.745421,149.476342 120.909928,136.390204 C123.630069,129.797189 134.372745,120.569464 145.880069,113.339622 C139.38683,109.668511 136.992604,98.3554954 140.56514,87.1174002 C144.325703,75.1550721 153.488942,67.7629028 160.934858,70.6098869 C168.380773,73.4568711 171.339083,85.4566594 167.540914,97.4189875 C166.871446,99.5373053 165.99829,101.586311 164.93359,103.537506 L164.93359,103.537506 Z"
                />
                <path
                    className="one"
                    d="M101.520577,184.031444 C95.0148022,198.341286 84.1342389,199.614936 74.0183234,221.167106 C73.7245415,206.964241 76.9674699,192.910084 83.4573374,180.260439 C87.8948022,171.819381 101.495506,160.656206 106.672549,156.535571 C107.95114,155.586577 108.715788,155.898746 108.602971,157.472079 C107.878169,166.679711 105.478322,175.679268 101.520577,184.031444 Z"
                />
                <path
                    className="two"
                    d="M84.1715493,165.218027 C68.0512676,174.745434 56.5940845,168.614429 32,182.699508 C40.0977465,170.012947 54.5633803,156.689561 69.1669014,149.284905 C79.1950704,144.140355 99.6525352,142.479614 107.687606,142.017604 C109.780986,141.880249 110.533099,142.554535 109.567887,143.765752 C102.50658,152.418977 93.8955431,159.692715 84.1715493,165.218027 Z"
                />
                <path
                    className="three"
                    d="M111.801549,134.312031 C106.51169,136.384835 93.6756338,140.055946 72.3532394,139.581449 C48.3859155,139.444094 33.0302817,126.919861 0,126.495311 C17.2609859,117.904412 41.9678873,112.759861 62.8264789,114.258274 C78.0442254,115.232242 103.653662,128.255946 111.350282,132.351607 C112.841972,133.113301 113.05507,133.85002 111.801549,134.312031 L111.801549,134.312031 Z"
                />
                <path
                    className="four"
                    d="M120.130704,126.281693 C106.147207,121.557469 93.1723228,114.273536 81.8732394,104.804444 C63.333662,89.6704762 56.8905634,64.5845503 38,50 C56.8028169,54.1456085 79.2533803,65.6833862 94.5588732,79.8808466 C105.602394,90.0201058 117.648732,115.855238 121.246338,124.258836 C121.960845,125.994497 121.559718,126.793651 120.130704,126.281693 L120.130704,126.281693 Z"
                />
                <path
                    className="five"
                    d="M124.596717,117.125926 C119.520436,103.197689 116.653107,88.5667861 116.097843,73.7593651 C114.756576,48.2613757 127.680379,21.2025397 125.812632,-1.42108547e-14 C134.800379,17.9809524 141.017843,44.0408466 140.955167,66.3047619 C140.955167,81.9631746 131.252914,108.360212 127.592632,117.725291 C126.740238,119.897989 126.652491,122.495238 124.596717,117.125926 L124.596717,117.125926 Z"
                />
            </g>
        </svg>
    );
}
