import { AsyncStatus, IInitAppActionPayload } from '../../../models/general/redux';
import {
    CompanyAvailabilityType,
    IAddCompanyContactPayload,
    IAddCompanySeatPayload,
    IFetchCompanyAvailabilitiesPayload,
    IFetchCompanyHolidaysPayload,
    IFetchCompanyInternalContactsPayload,
    IFetchCompanySeatContactsPayload,
    IRemoveCompanyContactPayload,
    IReplaceCompanyAvailabilitiesPayload,
    IUpdateCompanyContactPayload,
    IUpdateCompanyHolidaysPayload,
    IUpdateCompanyPayload,
    IUpdateCompanySingleFieldPayload,
} from '../../../models/admin/companyInfo';
import { getCurrentYear } from '../../../utils/core/date/getSpecificDate';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ICompanyCodePayload } from '../../../models/admin/company';
import { IFetchCompanyRatesPayload } from '../../../models/admin/rates';
import { Permission } from '../../../models/auth/authorisation';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import Api from '../../../api';
import isApplicationInitializationError from '../../../utils/api/isApplicationInitializationError';
import ROUTE_KEYS from '../../../routeKeys';
import { applicationInitializationFailed } from '../../ui/form/actions';
import {
    createEpic,
    IState,
    IParallelCallInput,
} from '../../index';
import { getRouteKey, getLocationState } from '../../location/selectors';
import { hasPermission } from '../../auth/selectors';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
    getSelectedCompany,
} from '../selected/selectors';

import * as COMPANY_INFO_ACTIONS from './actions';
import * as COMPANY_INFO_SELECTORS from './selectors';
import * as COMPANY_INFO_TYPES from './types';

const ACTION_TYPES_THAT_FETCH_CONTACTS_IF_NOT_AVAILABLE_YET = [
    ...getRouteKeysThatBelongToGroup(ROUTE_GROUP.COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE),
];

// fetchRisksEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_RISKS,
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!COMPANY_INFO_SELECTORS.areCompanyRisksAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(getState());
        return type !== action.type;
    },
    processMultiple: fetchRisks,
    latest: false,
});

async function fetchRisks({ api, getState }: { api: typeof Api, getState }, dispatch, done) {
    try {
        const state = getState();

        let companyCode = getSelectedSeatCompanyCode(state);
        const companySeatPayload = getSelectedCompanySeat(state);

        if (companySeatPayload.isAllSeatsSelected) {
            companyCode = companySeatPayload.companySeat.company.companyCode;
        }

        const risks = await api.admin.risks.fetchRisks(companyCode, companySeatPayload.isAllSeatsSelected);
        dispatch(COMPANY_INFO_ACTIONS.fetchRisksSucceeded(risks));
        done();
    } catch (error) {
        dispatch(COMPANY_INFO_ACTIONS.fetchRisksFailed(error));
        done();
    }
}

// fetchCompanyMedicalCentersEpic
createEpic<ICompanyCodePayload>({
    onActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_MEDICAL_CENTERS,
    refreshDataIf: ({ getState, action }) => {
        return !COMPANY_INFO_SELECTORS.areCompanyMedicalCentersAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const companyMedicalCenters = await api.admin.companyInfo.fetchCompanyMedicalCenters(companyCode);

            return COMPANY_INFO_ACTIONS.fetchCompanyMedicalCentersActions.succeeded(companyMedicalCenters);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanyMedicalCentersActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyMedicalCentersIfNotAvailableEpic
createEpic<ICompanyCodePayload>({
    onActionType: [
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
    ],
    processFilter: ({ getState }) => {
        return !COMPANY_INFO_SELECTORS.areCompanyMedicalCentersAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(getState());
        return COMPANY_INFO_ACTIONS.fetchCompanyMedicalCentersActions.trigger({ companyCode });
    },
    latest: false,
});

// fetchCompanyCostCentersEpic
createEpic<ICompanyCodePayload>({
    onActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_COST_CENTERS,
    refreshDataIf: ({ getState, action }) => {
        if (COMPANY_INFO_SELECTORS.areCompanyCostCentersAvailable(getState())) {
            return false;
        }

        return true;
    },
    async processReturn({ api, action }) {
        try {
            const { companyCode } = action.payload;
            const fetchRequest = api.admin.companyInfo.fetchCompanyCostCenters(companyCode);
            const companyCostCenters = await fetchRequest;
            return COMPANY_INFO_ACTIONS.fetchCompanyCostCentersActions.succeeded(companyCostCenters);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanyCostCentersActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyAddressesEpic
createEpic<ICompanyCodePayload>({
    onActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_ADDRESSES,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const canSelectAllSeats = hasPermission(state, Permission.CAN_SELECT_ALL_SEATS);
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const companyAddresses = await api.admin.companyInfo.fetchCompanyAddresses(companyCode, canSelectAllSeats);
            return COMPANY_INFO_ACTIONS.fetchCompanyAddressesActions.succeeded(companyAddresses);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanyAddressesActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyAddressesIfNotAvailableEpic
createEpic<ICompanyCodePayload>({
    onActionType: [ROUTE_KEYS.R_FLU_VACCINES_ORDERS, ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL],
    processFilter: ({ getState }) => {
        return !COMPANY_INFO_SELECTORS.areCompanyAddressesAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(getState());
        return COMPANY_INFO_ACTIONS.fetchCompanyAddressesActions.trigger({ companyCode });
    },
    latest: false,
});

// fetchCompanyDetailEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
    async processMultiple({ api, getState }, dispatch, done) {
        const companyCode = getSelectedSeatCompanyCode(getState());

        try {
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyHolidaysActions.trigger({
                companyCode,
                year: getCurrentYear(),
            }));

            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.MedicalExaminations,
                companyCode,
            }));
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.RiskManagement,
                companyCode,
            }));

            await fetchCompanyDetails(companyCode, { api, dispatch });

            done();
        } catch (error) {
            done();
        }
        done();
    },
    latest: false,
});

// fetchSmallCompanyDetailsEpic
createEpic<ICompanyCodePayload & IInitAppActionPayload>({
    onActionType: COMPANY_INFO_TYPES.FETCH_SMALL_COMPANY_DETAIL,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            await fetchCompanyDetails(action.payload.companyCode, { api, dispatch });
        } catch (error) {
            if (isApplicationInitializationError(action.payload, error)) {
                dispatch(applicationInitializationFailed());
            }
        }
        done();
    },
    latest: false,
});

async function fetchCompanyDetails(companyCode: string, { api, dispatch }: IParallelCallInput) {
    try {
        const companyDetail = await api.admin.companyInfo.fetchCompanyDetail(companyCode);

        dispatch(COMPANY_INFO_ACTIONS.fetchCompanyDetailSucceeded(companyDetail));
    } catch (error) {
        dispatch(COMPANY_INFO_ACTIONS.fetchCompanyDetailFailed(error));
        throw error;
    }
}

// addCompanySeatEpic
createEpic<IAddCompanySeatPayload>({
    onActionType: COMPANY_INFO_TYPES.ADD_COMPANY_SEAT,
    async processReturn({ api, action, getState }) {
        try {
            const selectedCompany = getSelectedCompany(getState());
            await api.admin.companyInfo.addCompanySeat({
                ...action.payload,
                companyCode: selectedCompany && selectedCompany.companyCode,
            });
            return COMPANY_INFO_ACTIONS.addCompanySeatActions.succeeded({});
        } catch (error) {
            return COMPANY_INFO_ACTIONS.addCompanySeatActions.failed(error);
        }
    },
    latest: false,
});

// updateCompanyEpic
createEpic<IUpdateCompanyPayload>({
    onActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY,
    async processReturn({ api, action }) {
        try {
            await api.admin.companyInfo.updateCompany(action.payload);
            return COMPANY_INFO_ACTIONS.updateCompanyActions.succeeded({});
        } catch (error) {
            return COMPANY_INFO_ACTIONS.updateCompanyActions.failed(error);
        }
    },
    latest: false,
});

// updateCompanySingleFieldEpic
createEpic<IUpdateCompanySingleFieldPayload>({
    onActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY_SINGLE_FIELD,
    latest: false, // because each update has it's own async status
    async processReturn({ api, action }) {
        const requestId = action.payload.requestId;

        try {
            await api.admin.companyInfo.updateCompany(action.payload);
            return COMPANY_INFO_ACTIONS.updateCompanySingleFieldSucceeded({
                requestId,
                companyCode: action.payload.companyCode,
                companyData: action.payload.companyData,
            });
        } catch (error) {
            return COMPANY_INFO_ACTIONS.updateCompanySingleFieldFailed({
                ...error,
                requestId,
            });
        }
    },
});

// fetchSeatDetailEpic
createEpic<{ companyCode: string }>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ],
    processMultiple({ action, getState }, dispatch, done) {
        const state = getState();
        const companyCode = action.payload.companyCode;

        const isOpenedFromDirectLink = !COMPANY_INFO_SELECTORS.areCompanySeatsWithEmployeeCountAvailable(state);

        if (isOpenedFromDirectLink) {
            // direct link to the detail page, so first fetch the list
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanySeatsWithEmployeeCountActions.trigger({}));
        }

        if (isOpenedFromDirectLink || action.type === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL) {
            dispatch(COMPANY_INFO_ACTIONS.updateCompanyActions.reset({}));

            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyHolidaysActions.trigger({
                companyCode,
                year: getCurrentYear(),
            }));

            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.MedicalExaminations,
                companyCode,
            }));
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.RiskManagement,
                companyCode,
            }));

            dispatch(COMPANY_INFO_ACTIONS.fetchCompanySeatDetailActions.trigger({}));
        }

        done();
    },
    latest: true,
});

// fetchCompanyContactsEpic
createEpic<IFetchCompanyInternalContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_CONTACTS,
        COMPANY_INFO_TYPES.FETCH_COMPANY_INTERNAL_CONTACTS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(state);
        return !COMPANY_INFO_SELECTORS.areCompanyContactsAvailable(state) || type !== action.type;
    },
    async processReturn({ api, getState, action }) {
        try {
            const state = getState();
            const selectedCompanySeat = getSelectedCompanySeat(state);
            const showFullFamily = selectedCompanySeat
                ? (action.type === ROUTE_KEYS.R_COMPANY_INFO_CONTACTS || action.payload.showFullFamilyIfApplicable)
                    ? selectedCompanySeat.isAllSeatsSelected
                    : false
                : false;
            let companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            if (!companyCode) {
                companyCode = getSelectedCompany(state).companyCode;
            }

            const contacts = await api.admin.companyInfo.fetchCompanyContacts({
                companyCode,
                showFullFamily,
            });
            return COMPANY_INFO_ACTIONS.fetchContactsSucceeded(contacts);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchContactsFailed(error);
        }
    },
    latest: false,
});

// fetchCompanySeatContactsIfNotAvailableEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ],
    processFilter: ({ getState }) => {
        const state = getState();
        return !COMPANY_INFO_SELECTORS.areCompanySeatContactsAvailable(state);
    },
    processReturn({ action }) {
        return COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.trigger({
            companyCode: action.payload.companyCode,
        });
    },
    latest: true,
});

// fetchCompanySeatContactsEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
        COMPANY_INFO_TYPES.FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
    ],
    async processReturn({ api, action }) {
        try {
            const companyCode = action.payload.companyCode;

            const contacts = await api.admin.companyInfo.fetchCompanyContacts({
                companyCode,
                filterStrict: true,
            });
            return COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.succeeded(contacts);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyContactsIfNotAvailableEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_CONTACTS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => {
        const state = getState();
        return !COMPANY_INFO_SELECTORS.areCompanyContactsAvailable(state)
            && COMPANY_INFO_SELECTORS.getCompanyContactsAsyncInfo(state).status !== AsyncStatus.Busy;
    },
    processReturn() {
        return COMPANY_INFO_ACTIONS.fetchContacts();
    },
    latest: false,
});

// fetchCompanyContactDetailEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_INFO_CONTACT_DETAIL,
    processReturn() {
        return COMPANY_INFO_ACTIONS.updateContactActions.reset({});
    },
    latest: false,
});

// removeContactEpic
createEpic<IRemoveCompanyContactPayload>({
    onActionType: COMPANY_INFO_TYPES.REMOVE_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            const state = getState();

            const companyCode = getSelectedSeatCompanyCode(getState()) || getSelectedCompany(getState()).companyCode;
            await api.admin.companyInfo.removeContact(companyCode, payload);
            dispatch(COMPANY_INFO_ACTIONS.removeContactSucceeded());

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = COMPANY_INFO_SELECTORS.getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.trigger({
                    companyCode: companySeatCode,
                }));
            } else {
                dispatch(COMPANY_INFO_ACTIONS.fetchContacts({ showFullFamilyIfApplicable: true }));
            }

            done();
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.removeContactFailed(error));
            done();
        }
    },
    latest: false,
});

// addContactEpic
createEpic<IAddCompanyContactPayload>({
    onActionType: COMPANY_INFO_TYPES.ADD_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.admin.companyInfo.addContact(action.payload);
            dispatch(COMPANY_INFO_ACTIONS.addContactSucceeded());

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = COMPANY_INFO_SELECTORS.getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.trigger({
                    companyCode: companySeatCode,
                }));
            } else {
                dispatch(COMPANY_INFO_ACTIONS.fetchContacts({ showFullFamilyIfApplicable: true }));
            }
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.addContactFailed(error));
        }
        done();
    },
    latest: false,
});

// updateContactEpic
createEpic<IUpdateCompanyContactPayload>({
    onActionType: COMPANY_INFO_TYPES.UPDATE_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.admin.companyInfo.updateContact(action.payload);
            dispatch(COMPANY_INFO_ACTIONS.updateContactActions.succeeded({}));

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = COMPANY_INFO_SELECTORS.getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(COMPANY_INFO_ACTIONS.fetchCompanySeatContactsActions.trigger({
                    companyCode: companySeatCode,
                }));
            } else {
                dispatch(COMPANY_INFO_ACTIONS.fetchContacts({ showFullFamilyIfApplicable: true }));
            }

            done();
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.updateContactActions.failed(error));
            done();
        }
    },
    latest: false,
});

// fetchCompanyAvailabilitiesEpic
createEpic<IFetchCompanyAvailabilitiesPayload>({
    onActionType: [
        COMPANY_INFO_TYPES.FETCH_COMPANY_AVAILABILITIES_MED_EXAMS,
        COMPANY_INFO_TYPES.FETCH_COMPANY_AVAILABILITIES_RISK_MGMT,
    ],
    latest: false, // because 2 simultaneous fetches will be executed for 2 different availability types
    async processMultiple({ api, action, getState }, dispatch, done) {
        const availabilityType = action.type === COMPANY_INFO_TYPES.FETCH_COMPANY_AVAILABILITIES_MED_EXAMS ?
            CompanyAvailabilityType.MedicalExaminations : CompanyAvailabilityType.RiskManagement;

        try {
            // reset the update/replace flag so that we do not see a previous update/replace error
            dispatch(COMPANY_INFO_ACTIONS.replaceCompanyAvailabilitiesReset(availabilityType));

            const companyCode = action.payload.companyCode;

            const availabilities = await api.admin.companyInfo.fetchCompanyAvailabilities({
                companyCode,
                availabilityType,
            });
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilitiesSucceeded(availabilityType, availabilities));
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilitiesFailed(availabilityType, error));
        }

        done();
    },
});

// replaceCompanyAvailabilitiesEpic
createEpic<IReplaceCompanyAvailabilitiesPayload>({
    onActionType: [
        COMPANY_INFO_TYPES.REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS,
        COMPANY_INFO_TYPES.REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        const availabilityType = action.type === COMPANY_INFO_TYPES.REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS ?
            CompanyAvailabilityType.MedicalExaminations : CompanyAvailabilityType.RiskManagement;

        try {
            const companyCode = action.payload.companyCode;

            const resultingAvailabilities = await api.admin.companyInfo.replaceCompanyAvailabilities({
                companyCode,
                availabilityType,
                applyFullFamily: action.payload.applyFullFamily,
                available: action.payload.availabilities.available,
                availabilities: action.payload.availabilities,
            });

            // 1) first refresh the availabilities ...
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyAvailabilitiesSucceeded(
                availabilityType,
                resultingAvailabilities,
            ));
            // 2) ... and then mark the replace as done
            // (in that order so that when the loader stops, the new availabilities are already there)
            dispatch(COMPANY_INFO_ACTIONS.replaceCompanyAvailabilitiesSucceeded(availabilityType));
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.replaceCompanyAvailabilitiesFailed(availabilityType, error));
        }
        done();
    },
    latest: false,
});

// fetchCompanyHolidaysEpic
createEpic<IFetchCompanyHolidaysPayload>({
    onActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_HOLIDAYS,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const holidays = await api.admin.companyInfo.fetchCompanyHolidays(action.payload);
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyHolidaysActions.succeeded(holidays));
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.fetchCompanyHolidaysActions.failed(error));
        }

        done();
    },
    latest: false,
});

// updateCompanyHolidaysEpic
createEpic<IUpdateCompanyHolidaysPayload>({
    onActionType: COMPANY_INFO_TYPES.UPDATE_COMPANY_HOLIDAYS,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            await api.admin.companyInfo.updateCompanyHolidays(action.payload);

            if (!action.payload.doNotRefreshAfterUpdate) {
                dispatch(COMPANY_INFO_ACTIONS.fetchCompanyHolidaysActions.trigger({
                    companyCode: action.payload.companyCode,
                    year: getCurrentYear(),
                }));
            }

            dispatch(COMPANY_INFO_ACTIONS.updateCompanyHolidaysActions.succeeded({}));
        } catch (error) {
            dispatch(COMPANY_INFO_ACTIONS.updateCompanyHolidaysActions.failed(error));
        }

        done();
    },
    latest: false,
});

// fetchCompanyRatesEpic
createEpic<IFetchCompanyRatesPayload>({
    onActionType: [
        COMPANY_INFO_TYPES.FETCH_COMPANY_RATES,
        ROUTE_KEYS.R_COMPANY_RATES,
    ],
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const rates = await api.admin.rates.fetchRates({ companyCode });

            return COMPANY_INFO_ACTIONS.fetchCompanyRatesActions.succeeded(rates);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanyRatesActions.failed(error);
        }
    },
    latest: false,
});

// fetchCompanySeatsWithEmployeeCount
createEpic({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS,
        COMPANY_INFO_TYPES.FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(state);
        return !COMPANY_INFO_SELECTORS.areCompanySeatsWithEmployeeCountAvailable(state) || type !== action.type;
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const selectedCompany = getSelectedCompany(state);
            const { seats } = await api.admin.company.fetchCompanySeats({
                companyCode: selectedCompany.companyCode,
                showFullSeats: true,
                noEmployeeCount: false,
            });
            return COMPANY_INFO_ACTIONS.fetchCompanySeatsWithEmployeeCountActions.succeeded(seats);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanySeatsWithEmployeeCountActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanySeatDetailsEpic
createEpic({
    onActionType: COMPANY_INFO_TYPES.FETCH_COMPANY_SEAT_DETAIL,
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const selectedCompanySeatCompanyCode = COMPANY_INFO_SELECTORS.getSelectedCompanyInfoSeatCompanyCode(state);
            const details = await api.admin.companyInfo.fetchCompanyDetail(selectedCompanySeatCompanyCode);
            return COMPANY_INFO_ACTIONS.fetchCompanySeatDetailActions.succeeded(details);
        } catch (error) {
            return COMPANY_INFO_ACTIONS.fetchCompanySeatDetailActions.failed(error);
        }
    },
    latest: true,
});

function shouldRefreshCompanySeatContacts(state: IState) {
    const routeKey = getRouteKey(state);
    return (
        routeKey === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL ||
        routeKey === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL
    );
}
