import React from 'react';
import './required-marker.scss';

interface IRequiredMarkerProps {
}

const RequiredMarker = (props: IRequiredMarkerProps) => {
    return (
        <span className="RequiredMarker">*</span>
    );
};

export default RequiredMarker;
