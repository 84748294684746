import dayjs from 'dayjs';
import { prependZeroToMax2Digits } from './formatDate';
import { TIME_FORMATS } from '../../config/i18n.config';

export function formatTimeOfDateForDisplay(date: Date | string) {
    return dayjs(date).format(TIME_FORMATS.DISPLAY);
}

export function formatStartEndTimeOfDatesForDisplay(startDate: Date | string, endDate: Date | string) {
    return `${formatTimeOfDateForDisplay(startDate)} - ${formatTimeOfDateForDisplay(endDate)}`;
}

export function stripUnallowedTimeCharacters(timeInput: string) {
    if (!timeInput) {
        return '';
    }

    return [timeInput]
        .map((val) => val.replace(/[^0-9:]/g, ''))
        .map((val) => val.trim())
        .map((val) => val.length > 5 ? val.substr(0, 5) : val)
        .map((val) => val.split('')
            .reduce(
                (timePartsAccumulator, char, index) => {
                    if (index === 2) {
                        if (char === ':') {
                            return `${timePartsAccumulator}${char}`;
                        }
                        return `${timePartsAccumulator}`;
                    }

                    if (char === ':') {
                        return `${timePartsAccumulator}`;
                    }

                    return `${timePartsAccumulator}${char}`;
                },
                '',
            ),
        )
        .pop();
}

export function formatHourAndMinute({ hour = 0, minute = 0 }: {hour?: number, minute?: number}) {
    return `${prependZeroToMax2Digits(hour)}:${prependZeroToMax2Digits(minute)}`;
}
