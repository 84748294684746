import React from 'react';

import {
    IPlanBufferzoneWizardStep,
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
} from '../../../models/interventions/bufferzones';

import { PlanBufferzone } from './wizard/PlanBufferzone/PlanBufferzone.component';
import { PlanBufferzoneWizardOverview } from './wizard/Overview/PlanBufferzoneWizardOverview.component';
import { SelectBufferzone } from './wizard/SelectBufferzone/SelectBufferzone.component';
import SelectEmployees from './wizard/SelectEmployees';

export const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps';

export const PLAN_BUFFERZONE_STEPS: IPlanBufferzoneWizardStep[] = [
    {
        id: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_BUFFERZONE,
        translationKey: `${TRANSLATION_PREFIX}.select_bufferzone.step_label`,
        renderStepContent: (props) => <SelectBufferzone {...props}/>,
        hide: (entity) =>
            entity &&
            entity.selectedBufferzone &&
            entity.selectedBufferzone.selectedFromOverview,
    },
    {
        id: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES,
        translationKey: `${TRANSLATION_PREFIX}.select_employees.step_label`,
        renderStepContent: (props) => <SelectEmployees {...props} />,
    },
    {
        id: PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING,
        translationKey: `${TRANSLATION_PREFIX}.planning.step_label`,
        renderStepContent: (props) => <PlanBufferzone {...props}/>,
    },
    {
        id: PLAN_BUFFERZONE_WIZARD_STEP_ID.OVERVIEW,
        translationKey: `${TRANSLATION_PREFIX}.overview.step_label`,
        renderStepContent: (props) => <PlanBufferzoneWizardOverview {...props}/>,
    },
];
