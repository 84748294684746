import React, { MouseEvent, PureComponent } from 'react';
import isArray from '@snipsonian/core/es/is/isArray';
import { connect } from '../../../../index';
import FilterAndSearchPanel from './FilterAndSearchPanel';
import Checkbox from '../../../input/Checkbox';
import ListActionButton from '../../../buttons/ListActionButton';
import { ITranslator } from '../../../../../models/general/i18n';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../models/general/redux';
import { IState } from '../../../../../redux';
import { IHeaderConfig, TMasterData } from '../typings';
import exportListDataToCsv from '../../../../../utils/file/csv/exportListDataToCsv';
import { ListItem, ListItemId } from '../../../../../models/general/list';
import Loader from '../../../waiting/Loader';
import ErrorDialog from '../../../modals/ErrorDialog';
import { FILTER_BUTTON_FOCUS_ELEMENT, SEARCH_BUTTON_FOCUS_ELEMENT } from '../../../../../config/dom.config';
import { MixedSchema } from 'yup';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import presentDownloadedFile from '../../../../../utils/file/presentDownloadedFile';
import { IDocument } from '../../../../../models/general/documents';

interface IPublicProps {
    baseName: string;
    baseClassName: string;
    masterAsyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    headerConfig?: IHeaderConfig;
    isFilterPanelOpen: boolean;
    slideOutPanelElementToFocus?: string;
    masterData: TMasterData;
    clientSideFilteredListItems?: TMasterData;
    onFilterButtonClick?: (elementToFocus: string) => void;
    onFilterSubmit: (filters: object) => void;
    onFilterPanelCloseIntent: () => void;
    filterValues: object;
    untransformedMasterDataSelector: (state: IState) => TMasterData;
    filterValidationSchema?: MixedSchema;
}

interface IPrivateProps {
    isFetchingMaster: boolean;
    untransformedMasterData: TMasterData;
}

interface IContextProps {
    translator: ITranslator;
}

interface IComponentState {
    fetchExportDataAsyncInfo: IAsyncFieldInfo;
}

class HeaderComp extends PureComponent<IPublicProps & IPrivateProps & IContextProps, IComponentState> {
    constructor(props: IPrivateProps & IPublicProps & IContextProps) {
        super(props);

        this.state = {
            fetchExportDataAsyncInfo: {
                status: AsyncStatus.Initial,
                error: null,
            },
        };

        this.onSubmitFilterAndSearch = this.onSubmitFilterAndSearch.bind(this);
        this.onExportData = this.onExportData.bind(this);
        this.isExportPossible = this.isExportPossible.bind(this);
        this.onCloseExportDataErrorDialog = this.onCloseExportDataErrorDialog.bind(this);
    }

    public render() {
        const {
            baseName, baseClassName, headerConfig, translator,
            isFilterPanelOpen, masterData, clientSideFilteredListItems,
            onFilterButtonClick, onFilterPanelCloseIntent,
            isFetchingMaster, filterValues,
            slideOutPanelElementToFocus, filterValidationSchema,
        } = this.props;
        const { fetchExportDataAsyncInfo } = this.state;

        // some filters add an extra filtervalue, such as refreshSources, which will stay
        // active, when the filter is technically deactivated. This would keep the buttons at active which
        // could be confusing to users.
        // other filters will have only one active filter when clicked.
        const doesFilterOnlyShowOneActive = (baseName) => {
            const singleFilterComponents = [
                'functions',
                'courses-overview',
                'prevention-units-overview',
                'work-post-cards-list',
            ];
            return singleFilterComponents.indexOf(baseName) !== -1;
        };
        const minimumNumberOfFiltersNeeded = doesFilterOnlyShowOneActive(baseName) ? 0 : 1;

        const isFilterActive = (filterValues) => {
            const onlyFilterIsNotUnmanned = (filterValues) => {
                if (Object.keys(filterValues).length === 1
                && !filterValues.showUnmanned
                && filterValues.showUnmanned === false) {
                    return false;
                } return true;
            };
            if (!!filterValues && onlyFilterIsNotUnmanned(filterValues)){
                return Object.keys(filterValues).length > minimumNumberOfFiltersNeeded;
            } return false;
        };

        return (
            <div className={`${baseClassName}__header`}>
                <div className="action-container">
                    {(headerConfig.selectAllCheckbox
                        || headerConfig.renderFilterContent
                        || headerConfig.renderSearchContent
                        || headerConfig.renderActionContentOnTheLeft) &&
                    <div className="left">
                        {headerConfig.selectAllCheckbox &&
                        <Checkbox
                            name={`${baseClassName}_action_select-all`}
                            checked={isFetchingMaster ? false : headerConfig.selectAllCheckbox.isSelected({
                                masterData: clientSideFilteredListItems || masterData,
                                filterValues,
                            })}
                            onChange={(e) => headerConfig.selectAllCheckbox.onSelectionChange({
                                isSelected: e.target.checked,
                                masterData: clientSideFilteredListItems || masterData,
                                filterValues,
                            })}
                            disabled={isFetchingMaster}
                        >
                            {headerConfig.selectAllCheckbox.renderTranslateComponent({
                                masterData: clientSideFilteredListItems || masterData,
                            })}
                        </Checkbox>
                        }

                        {headerConfig.renderFilterContent &&
                        <ListActionButton
                            id={`${baseName}-filter-button`}
                            filterActive={isFilterActive(filterValues)}
                            iconTypeName="sliders"
                            translationKey="common.master_with_detail.action.open_filter"
                            onClick={(e) => onFilterButtonClick(FILTER_BUTTON_FOCUS_ELEMENT)}
                        />
                        }
                        {headerConfig.renderSearchContent &&
                        <ListActionButton
                            id={`${baseName}-search-button`}
                            iconTypeName="search"
                            translationKey="common.master_with_detail.action.open_search"
                            onClick={(e) => onFilterButtonClick(SEARCH_BUTTON_FOCUS_ELEMENT)}
                        />
                        }
                        {headerConfig.renderActionContentOnTheLeft && headerConfig.renderActionContentOnTheLeft({
                            masterData: clientSideFilteredListItems || masterData,
                        })}
                    </div>
                    }
                    {(headerConfig.renderActionContent || headerConfig.exportButton) &&
                    <div className="right">
                        {headerConfig.renderActionContent && headerConfig.renderActionContent({
                            masterData: clientSideFilteredListItems || masterData,
                        })}
                        {headerConfig.exportButton &&
                        <>
                            <Loader show={fetchExportDataAsyncInfo.status === AsyncStatus.Busy} />
                            <ListActionButton
                                id={`${baseName}-export-button`}
                                type="text"
                                iconTypeName="excel"
                                translationKey={headerConfig.exportButton.overrideTranslationKey
                                    || 'common.master_with_detail.action.export'}
                                onClick={this.onExportData}
                                disabled={!this.isExportPossible()}
                            />
                            <ErrorDialog
                                asyncInfo={fetchExportDataAsyncInfo}
                                titleTranslationKey="common.master_with_detail.export.error_title"
                                infoTranslationKey="common.master_with_detail.export.error_during_fetch"
                                hideRealErrorMessage={true}
                                onCloseDialog={this.onCloseExportDataErrorDialog}
                            />
                        </>
                        }
                    </div>
                    }
                </div>
                <FilterAndSearchPanel
                    baseName={baseName}
                    isOpen={isFilterPanelOpen}
                    isFetchingMaster={isFetchingMaster}
                    // the filter gets all the masterData (not the potentially-clientSide-filtered-data) so
                    // that the filter panel can keep retrieving all possible values to put in a filter list
                    masterData={masterData}
                    onSubmit={this.onSubmitFilterAndSearch}
                    onCloseIntent={onFilterPanelCloseIntent}
                    translator={translator}
                    filterValues={filterValues}
                    slideOutPanelElementToFocus={slideOutPanelElementToFocus}
                    renderSearchContent={headerConfig.renderSearchContent}
                    renderFilterContent={headerConfig.renderFilterContent}
                    filterValidationSchema={filterValidationSchema}
                />
            </div>
        );
    }

    private onSubmitFilterAndSearch(filters: object) {
        if (!this.props.isFetchingMaster) {
            this.props.onFilterSubmit(filters);
        }
    }

    private onExportData(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const { fetchAllExportData } = this.props.headerConfig.exportButton;
        const isFetchNeeded = fetchAllExportData
            ? fetchAllExportData.isFetchNeeded()
            : false;

        if (isFetchNeeded) {
            this.exportDataBasedOnNewBackendFetch();
        } else {
            this.exportDataBasedOnAlreadyFetchedListItems();
        }
    }

    private async exportDataBasedOnNewBackendFetch() {
        const { apiCall, willResultAlreadyBeAFile } = this.props.headerConfig.exportButton.fetchAllExportData;

        this.updateFetchExportDataAsyncInfo(AsyncStatus.Busy);

        try {
            const apiResult = await apiCall({
                filterValues: this.props.filterValues,
            });

            if (willResultAlreadyBeAFile) {
                presentDownloadedFile(apiResult as IDocument);
            } else {
                // listData
                this.doExportData(apiResult as object[]);
            }

            this.updateFetchExportDataAsyncInfo(AsyncStatus.Success);
        } catch (e) {
            this.updateFetchExportDataAsyncInfo(AsyncStatus.Error, e);
        }
    }

    private updateFetchExportDataAsyncInfo(status: AsyncStatus, error = null) {
        this.setState({
            fetchExportDataAsyncInfo: {
                status,
                error,
            },
        });
    }

    private exportDataBasedOnAlreadyFetchedListItems() {
        const { headerConfig, masterData, clientSideFilteredListItems, untransformedMasterData } = this.props;

        let idExtractor = headerConfig.exportButton.listItemIdExtractor;
        if (!idExtractor) {
            idExtractor = (masterDataRow: object) => masterDataRow['id'];
        }

        const filteredListItems = (clientSideFilteredListItems || masterData) as ListItem<{}>[];

        const listData = getMatchingUntransformedMasterDataSoThatWeKeepTheSameClientSideFiltering(
            filteredListItems,
            untransformedMasterData as object[],
            idExtractor,
        );

        const mappedListData = headerConfig.exportButton.mapListRowForExport ?
            listData.map(headerConfig.exportButton.mapListRowForExport) : listData;

        this.doExportData(mappedListData);
    }

    private doExportData(listData: object[]) {
        const { baseFilename } = this.props.headerConfig.exportButton;

        exportListDataToCsv({
            baseFilename,
            listData,
        });
    }

    private isExportPossible() {
        const data = this.props.clientSideFilteredListItems || this.props.masterData;

        if (isArray(data)) {
            return (data as []).length > 0;
        }

        return false;
    }

    private onCloseExportDataErrorDialog() {
        this.updateFetchExportDataAsyncInfo(AsyncStatus.Initial);
    }
}

function Header(props: IPrivateProps & IPublicProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <HeaderComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                isFetchingMaster: publicProps.masterAsyncInfoSelector(state).status === AsyncStatus.Busy,
                untransformedMasterData: publicProps.untransformedMasterDataSelector(state),
            };
        };
    },
})(Header);

export function getMatchingUntransformedMasterDataSoThatWeKeepTheSameClientSideFiltering(
    filteredListItems: ListItem<{}>[],
    untransformedMasterData: object[],
    idExtractor: (masterDataRow: object) => ListItemId,
) {
    return filteredListItems
        .map((listItem) => {
            const matchingMasterData = untransformedMasterData
                .find((untransformedRow) => idExtractor(untransformedRow) === listItem.id);
            return matchingMasterData;
        })
        .filter((untransformedMasterDataRow) => !!untransformedMasterDataRow);
}
