import { path } from 'ramda';

import { ADD_EMPLOYEE_WIZARD_STEP_ID } from '../../../models/admin/employee';
import {
    AUTO_PLAN_ALLOWED_EXAMINATION_REASON_IDS,
    EXAMINATION_REASON_CODES_THAT_REQUIRE_TO_BE_PLANNED_CHECK,
} from '../../../config/medicalExamination.config';
import { EXAMINATION_REASON_CODE } from '../../../config/navigation/interventions.config';
import { formatPersonName } from '../../../utils/formatting/formatPerson';
import { getPlanMedicalExaminatonWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { IEmployeePlannedMedicalExamination } from '../../../models/admin/employee/medical-examination';
import { IFetchReservedMedicalExaminationsPayload} from '../../../models/interventions/medicalExaminations/reserved';
import {
    IInterimManualEntity,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
    IPlanMedicalExaminationWizardPayload,
    IRequestMedicalExaminationPayload,
    IResumptionOfWorkManualEntity,
    IValidateEmployeeToPlanData,
    IValidateEmployeeToPlanPayload,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../../models/interventions/medicalExaminations/planned';
import { IRequestWrapperPromise } from '../../../utils/api/requestWrapper';
import { isPreviousAllowedFromThisStep } from '../../../utils/wizard/isPreviousAllowedFromThisStep';
import { formatCurrentDateForBackend } from '../../../utils/formatting/formatDate';
import { shouldDataBeRefreshed } from '../../../utils/libs/redux/async/asyncReducerUtils';
import Api from '../../../api/index';
import ROUTE_KEYS from '../../../routeKeys';
import { addEmployeeActions, updateEmployeeAllFieldsAndEmploymentActions } from '../../employee/wizard/actions';
import {
    createConvocationsActions,
    uploadEmployeeDocumentActions,
    fetchConvocationRecipientsActions,
} from '../../employee/documents/actions';
import { createEpic } from '../../index';
import {
    fetchSmallEmployeeDetails,
    fetchEmployeeJobStudentActions,
    fetchEmployeeDetailsSucceeded,
    fetchEmployeeDetailsReset,
} from '../../employee/info/actions';
import { fetchWorkPostFileOfEmployeeActions } from '../../documentCenter/workPostCards/actions';
import { getEmployeeToAdd } from '../../employee/wizard/selectors';
import { getLocationState } from '../../location/selectors';
import { getRouteThatTriggeredWizard } from '../../ui/history/selectors';
import {
    getSelectedCompanySeat,
    getSelectedSeatCompanyCode,
    isCompanyAnInterimCompany,
} from '../../company/selected/selectors';
import { getSelectedEmployee } from '../../employee/info/selectors';
import { IState } from '../../IState';
import { redirectToRoute, navigateTo } from '../../location/actions';
import { updateUiHistory } from '../../ui/history/actions';
import { getLocale } from '../../i18n/selectors';
import { FETCH_EXAMINATION_REASONS, REQUEST_MEDICAL_EXAMINATION, VALIDATE_EMPLOYEE_TO_PLAN } from '../types';
import * as MEDICAL_EXAMINATION_ACTIONS from '../actions';
import * as MEDICAL_EXAMINATION_SELECTORS from '../selectors';

// fetchExaminationReasonsEpic
createEpic({
    onActionType: FETCH_EXAMINATION_REASONS,
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if reasons already available
        const reasons = MEDICAL_EXAMINATION_SELECTORS.getExaminationReasons(getState());
        return !reasons || reasons.length === 0;
    },
    processReturn: fetchMedicalExaminationReasons,
    latest: false,
});

// resetAndFetchDataForNewMedicalExaminationWizardEpic
createEpic<IPlannedMedicalExamination[]>({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON,
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if reasons already available
        const reasons = MEDICAL_EXAMINATION_SELECTORS.getExaminationReasons(getState());
        return !reasons || reasons.length === 0;
    },
    skipProcessIfDataNotToBeRefreshed: false,
    async processMultiple({ action, getState, api }, dispatch, done) {

        const state = getState();

        if (!shouldDataBeRefreshed(action.payload)) {
            redirectToInterimWizardIfContainsInterimReason(
                dispatch,
                state,
            );
            return done();
        }

        try {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.resetPlanMedicalExaminationWizardEntity());

            if (!MEDICAL_EXAMINATION_SELECTORS.areExaminationReasonsAvailable(state)) {
                const fetchExaminationsReasonsAction = await fetchMedicalExaminationReasons({ getState, api });
                dispatch(fetchExaminationsReasonsAction);
            } else {
                dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchExaminationReasonsSucceeded(
                    MEDICAL_EXAMINATION_SELECTORS.getExaminationReasons(state),
                ));
            }

            const updatedState = getState();

            redirectToInterimWizardIfContainsInterimReason(
                dispatch,
                updatedState,
            );
        } catch (error) {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchExaminationReasonsFailed(error));
        }
        done();
    },
    latest: false,
});

export async function fetchMedicalExaminationReasons({ getState, api }: { getState: () => IState, api: typeof Api }) {
    try {
        const companyCode = getSelectedSeatCompanyCode(getState());
        const reasons = await api.interventions.medicalExaminations.fetchExaminationReasons(companyCode);

        return MEDICAL_EXAMINATION_ACTIONS.fetchExaminationReasonsSucceeded(reasons);
    } catch (error) {
        return MEDICAL_EXAMINATION_ACTIONS.fetchExaminationReasonsFailed(error);
    }
}

/**
 * If it's an interim company, the reason is always 'interim' and we don't show the choose-reason screen.
 */
function redirectToInterimWizardIfContainsInterimReason(dispatch, state) {
    const reasons = MEDICAL_EXAMINATION_SELECTORS.getExaminationReasons(state);

    const interimReason = reasons.find((reason) => reason.code === EXAMINATION_REASON_CODE.INTERIM);

    if (interimReason) {
        /**
         * Because of a timing issue, this redirect occurs BEFORE the updateUiHistory
         * gets dispatched in the ui/history/epics, causing that the 'routeThatTriggeredWizard' gets set to the
         * R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON which is the previous route AFTER the redirect.
         * This in turn causes that the 'Stoppen' link in the Interim flow seems to be doing nothing - in the
         * case that you triggered the route from the list screen - because that link navigates to
         * routeThatTriggeredWizard=R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON which itself again results
         * in this same redirect).
         * Therefore we here do an additional updateUiHistory BEFORE redirecting.
         */
        if (!getRouteThatTriggeredWizard(state)) {
            const previousRoute = getLocationState(state).prev;
            dispatch(updateUiHistory({
                routeThatTriggeredWizard: previousRoute,
            }));
        }

        dispatch(MEDICAL_EXAMINATION_ACTIONS.navigateToPlanMedicalExaminationWizardStep(
            {
                wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.INTERIM_MANUAL,
                reason: interimReason,
                resetDataEntity: true,
            },
            true,
        ));
    }
}

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the initial step just before the flow.
 */
// validatePlanMedicalExaminationWizardStepIdEpic
createEpic<IPlanMedicalExaminationWizardPayload>({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
    transform: ({ action, getState }, { next }) => {
        const requestedWizardType = action.payload.wizardType;
        const skipToStep = action.payload.skipToStep;

        const state = getState();

        if (!Object.values(PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE).includes(requestedWizardType)) {
            return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
        }

        const stepIdOrder = getPlanMedicalExaminatonWizardSteps(requestedWizardType).stepIds;
        const requestedStep = action.payload.step;
        if (!stepIdOrder || !stepIdOrder.includes(requestedStep)) {
            return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
        }

        // remark: we don't check that the wizard type remains the same as this is possible from some screens
        // e.g. automatic flow to manual flow

        const currentStep = MEDICAL_EXAMINATION_SELECTORS.getPlanMedicalExaminationWizardStepId(state);
        const currentStepIndex = stepIdOrder.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = stepIdOrder.indexOf(requestedStep);
        if (!skipToStep && requestedStepIndex > currentStepIndex + 1) {
            return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
        }

        // for the resumption of work wizard, we can't allow the manual time step if "NO" is answered in previous step.
        if (requestedWizardType === PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL &&
            requestedStep === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME) {
                const { getPlanMedicalExaminationWizardEntity } = MEDICAL_EXAMINATION_SELECTORS;
            const wizardEntity = getPlanMedicalExaminationWizardEntity<IResumptionOfWorkManualEntity>(state);
            if (!wizardEntity.absent) {
                return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
            }
        }

        const steps = getPlanMedicalExaminatonWizardSteps(requestedWizardType).steps;
        if (!isPreviousAllowedFromThisStep(requestedStep, steps, currentStep)) {
            return next(
                navigateTo<IPlanMedicalExaminationWizardPayload>(
                    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
                    {
                        wizardType: requestedWizardType,
                        step: currentStep,
                        skipAllEpicSideEffects: true,
                    },
                ),
            );
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringPlanMedicalExaminationWizardEpic
createEpic<IPlanMedicalExaminationWizardPayload>({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
    async processMultiple({ action, getState, api }, dispatch, done) {
        const { step, skipAllEpicSideEffects, wizardType } = action.payload;
        const state = getState();

        if (skipAllEpicSideEffects) {
            return done();
        }

        const examinationReason =
            action.payload.reason
            || MEDICAL_EXAMINATION_SELECTORS.getPlanMedicalExaminationWizardReason(state);

        if (!examinationReason && wizardType !== PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING) {
            // probably because of a deep link directly to the first step
            dispatch(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON));
            return done();
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.EMPLOYEES_TO_PLAN) {
            // do not refresh if only clientside (query) filtering changed
            const {
                type: prevActionType,
                payload: prevActionPayload,
            } = getLocationState(state).prev as {
                type: string;
                payload: IPlanMedicalExaminationWizardPayload;
            };

            if (action.type !== prevActionType || step !== prevActionPayload.step) {
                try {
                    const companyCode = getSelectedSeatCompanyCode(state);
                    const { isAllSeatsSelected } = getSelectedCompanySeat(state);
                    const isAutoPlan =
                        wizardType === PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC;
                    const allowedExaminationReasonIds = isAutoPlan ?
                        AUTO_PLAN_ALLOWED_EXAMINATION_REASON_IDS : [examinationReason.id];

                    dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchEmployeesToPlan({
                        companyCode,
                        allowedExaminationReasonIds,
                    }));

                    const employeesToPlan = await api.interventions.medicalExaminations.fetchToPlan({
                        companyCode,
                        showFullFamily: isAllSeatsSelected,
                        allowedExaminationReasonIds,
                        toBePlanned: true,
                    });
                    dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchEmployeesToPlanSucceeded(employeesToPlan));
                } catch (error) {
                    dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchEmployeesToPlanFailed(error));
                }
            }
        }

        if (step === ADD_EMPLOYEE_WIZARD_STEP_ID.FUNCTION && isCompanyAnInterimCompany(state)) {
            const employeeToAdd = getEmployeeToAdd(state);
            dispatch(fetchEmployeeJobStudentActions.reset({}));
            if (employeeToAdd && employeeToAdd.id) {
                dispatch(fetchEmployeeJobStudentActions.trigger({ id: employeeToAdd.id }));
            }
        }

        if (step === ADD_EMPLOYEE_WIZARD_STEP_ID.INTERIM_STARTDATE) {
            dispatch(updateEmployeeAllFieldsAndEmploymentActions.reset({}));
            dispatch(addEmployeeActions.reset({}));
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_TIME) {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.autoPlanEmployeesReset());
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_VALIDATE) {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.autoPlanEmployeesReset());
            dispatch(MEDICAL_EXAMINATION_ACTIONS.removeTimeslotReset());
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_NOTIFY) {
            dispatch(fetchConvocationRecipientsActions.trigger({}));
            dispatch(createConvocationsActions.reset({}));
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME) {
            const state = getState();
            const selectedEmployeeDetails = getSelectedEmployee(state);
            const wizardEntity =
            MEDICAL_EXAMINATION_SELECTORS.getPlanMedicalExaminationWizardEntity<
                IPlanMedicalExaminationSingleEmployeeBaseEntity
            >(state);
            const selectedEmployee = wizardEntity && wizardEntity.searchEmployee
                && wizardEntity.searchEmployee.selectedEmployee;

            if (selectedEmployee && selectedEmployee.id !== (selectedEmployeeDetails && selectedEmployeeDetails.id)) {
                dispatch(fetchEmployeeDetailsReset());
                const employee = await api.admin.employee.fetchEmployeeDetails(selectedEmployee.id.toString());
                dispatch(fetchEmployeeDetailsSucceeded(employee));
            }

            const employeeDetailsAfterFetch = getSelectedEmployee(getState());
            const companyCode = path<string>(['company', 'companyCode'], employeeDetailsAfterFetch);

            // When we do a manual planning, we want to fetch the bufferzones
            // related to the selected employee. This ensures that we do not get
            // to see any buffer zones on which the employee cannot be scheduled.
            // To do this, we fetch the buffer zones for the employee's seat of
            // employment and pass in showFullFamily false.
            dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchReservedMedicalExaminations({
                startDate: formatCurrentDateForBackend(),
                ...!!companyCode && {
                    companyCode,
                },
                showFullFamily: false,
            } as IFetchReservedMedicalExaminationsPayload));

            if (!MEDICAL_EXAMINATION_SELECTORS.areMedicalExaminationsToPlanAvailable(state)) {
                dispatch(MEDICAL_EXAMINATION_ACTIONS.fetchMedicalExaminationsToPlan());
            }
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY) {
            dispatch(fetchConvocationRecipientsActions.trigger({}));
            fetchSmallEmployeeDetailsOfWizardEntityIfNotAvailable(getState, dispatch);
            dispatch(createConvocationsActions.reset({}));
        }

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.WORK_POST_CARD) {
            const employee = getEmployeeToAdd(state);
            const companyCode = getSelectedSeatCompanyCode(state);

            if (employee && employee.id) {
                dispatch(fetchWorkPostFileOfEmployeeActions.trigger({
                    companyCode,
                    employeeId: employee.id,
                }));
            }

            dispatch(uploadEmployeeDocumentActions.reset({}));

            dispatch(MEDICAL_EXAMINATION_ACTIONS.updatePlanMedicalExaminationWizardEntity<IInterimManualEntity>({
                searchEmployee: {
                    searchValue: formatPersonName(employee),
                    selectedEmployee: {
                        id: employee.id,
                        employeeId: employee.employeeId, // for new employees, fetch with employeeDetails on time step
                        firstName: employee.firstName,
                        name: employee.name,
                    },
                },
            }));
        }

        return done();
    },
    latest: false,
});

function fetchSmallEmployeeDetailsOfWizardEntityIfNotAvailable(getState, dispatch) {
    const state = getState();
    const selectedEmployeeDetails = getSelectedEmployee(state);
    const wizardEntity =
    MEDICAL_EXAMINATION_SELECTORS.getPlanMedicalExaminationWizardEntity<
        IPlanMedicalExaminationSingleEmployeeBaseEntity
    >(state);
    const selectedEmployee = wizardEntity && wizardEntity.searchEmployee
        && wizardEntity.searchEmployee.selectedEmployee;
    if (selectedEmployee && selectedEmployee.id !== (selectedEmployeeDetails && selectedEmployeeDetails.id)) {
        dispatch(fetchSmallEmployeeDetails({
            id: selectedEmployee.id,
        }));
    }
}

// validateEmployeeToPlanEpic
createEpic<IValidateEmployeeToPlanPayload>({
    onActionType: VALIDATE_EMPLOYEE_TO_PLAN,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            const state = getState();
            const { employeeToPlan, examinationReason, onValidationPassed } = action.payload;
            const reasonCode =
                examinationReason.code
                || MEDICAL_EXAMINATION_SELECTORS.getExaminationReasonCodeById(getState(), examinationReason.id);
            const companyCode = getSelectedSeatCompanyCode(state);
            const locale = getLocale(state);

            let plannedMedicalExaminations: IEmployeePlannedMedicalExamination[];
            let toBePlanned: boolean;

            const validationPromises: IRequestWrapperPromise<any>[] = [
                api.admin.employee.fetchEmployeePlannedMedicalExaminations({
                    id: employeeToPlan.id,
                    companyCode,
                    locale,
                })
                    .then((response) => {
                        plannedMedicalExaminations = response;
                    }),
            ];
            if (reasonCode && EXAMINATION_REASON_CODES_THAT_REQUIRE_TO_BE_PLANNED_CHECK.includes(reasonCode)) {
                validationPromises.push(
                    api.admin.employee.fetchEmployeeDetails(employeeToPlan.id.toString())
                        .then((response) => {
                            toBePlanned = response.toBePlanned;
                        }),
                );
            } else {
                /* for some (mainly non-perio reasons) we never have to show this warning */
                toBePlanned = true;
            }

            await Promise.all(validationPromises);

            const hasAlreadyPlannedMedicalExamination =
                plannedMedicalExaminations && plannedMedicalExaminations.length > 0;

            const validationResult: IValidateEmployeeToPlanData = {
                warning: !hasAlreadyPlannedMedicalExamination && toBePlanned
                    ? false
                    : {
                        isNotToBePlanned: !toBePlanned,
                        hasAlreadyPlannedMedicalExamination,
                        plannedMedicalExaminations,
                        /* can not be overruled if it's a perio(or similar) examination
                           and the employee is not flagged as toBePlanned */
                        canWarningBeOverruled: toBePlanned,
                    },
            };

            dispatch(MEDICAL_EXAMINATION_ACTIONS.validateEmployeeToPlanActions.succeeded(validationResult));

            if (!validationResult.warning) {
                onValidationPassed();
            }

            done();
        } catch (error) {
            dispatch(MEDICAL_EXAMINATION_ACTIONS.validateEmployeeToPlanActions.failed(error));
            done();
        }
    },
    latest: false,
});

// requestMedicalExaminationEpic
createEpic<IRequestMedicalExaminationPayload>({
    onActionType: REQUEST_MEDICAL_EXAMINATION,
    async processReturn({ getState, api, action }) {
        try {
            await api.interventions.medicalExaminations.requestMedicalExamination({
                companyCode: getSelectedSeatCompanyCode(getState()),
                ...action.payload,
            });
            return MEDICAL_EXAMINATION_ACTIONS.requestMedicalExaminationActions.succeeded({});
        } catch (error) {
            return MEDICAL_EXAMINATION_ACTIONS.requestMedicalExaminationActions.failed(error);
        }
    },
    latest: false,
});
