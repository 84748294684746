import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createActionHandlersForType,
} from '../index';
import {
    FETCH_OPEN_INVOICES_COUNT,
    FETCH_OPEN_INVOICES,
    FETCH_PAID_INVOICES,
    FETCH_INVOICE_DOCUMENT,
    FETCH_INVOICE_DETAIL,
    FETCH_INVOICE_FULL,
    DISMISS_INVOICE_NOT_FOUND_DIALOG,
    FETCH_INVOICE_PARAMETERS,
} from './types';
import { IInvoice, IOpenInvoicesCount, IInvoiceParameters } from '../../models/admin/invoices';
import ROUTE_KEYS from '../../routeKeys';
import { IDocument } from '../../models/general/documents';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INVOICE;

export interface IReducerState {
    openInvoicesCount: IAsyncFetchField<number>;
    openInvoices: IAsyncFetchField<IInvoice[]>;
    paidInvoices: IAsyncFetchField<IInvoice[]>;
    invoiceDocument: IAsyncFetchField<IDocument>;
    invoiceDetail: IAsyncFetchField<IDocument>;
    invoiceFull: IAsyncFetchField<IDocument>;
    invoiceParameters: IAsyncFetchField<IInvoiceParameters[]>;
}

const initialState: IReducerState = {
    openInvoicesCount: getAsyncFetchInitialState(),
    openInvoices: getAsyncFetchInitialState(),
    paidInvoices: getAsyncFetchInitialState(),
    invoiceDocument: getAsyncFetchInitialState(),
    invoiceDetail: getAsyncFetchInitialState(),
    invoiceFull: getAsyncFetchInitialState(),
    invoiceParameters: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<number, IReducerState, IOpenInvoicesCount>({
        baseActionType: FETCH_OPEN_INVOICES_COUNT,
        fieldName: 'openInvoicesCount',
        overrideTriggerActionType: ROUTE_KEYS.R_HOME,
        mapSuccessPayload: (payload) => payload.count,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IInvoice[], IReducerState, IInvoice[]>({
        baseActionType: FETCH_OPEN_INVOICES,
        fieldName: 'openInvoices',
        overrideTriggerActionType: ROUTE_KEYS.R_INVOICES_OPEN,
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IInvoice[], IReducerState, IInvoice[]>({
        baseActionType: FETCH_PAID_INVOICES,
        fieldName: 'paidInvoices',
        overrideTriggerActionType: ROUTE_KEYS.R_INVOICES_PAID,
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_INVOICE_DOCUMENT,
        fieldName: 'invoiceDocument',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_INVOICE_DETAIL,
        fieldName: 'invoiceDetail',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_INVOICE_FULL,
        fieldName: 'invoiceFull',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createActionHandlersForType<IReducerState>(DISMISS_INVOICE_NOT_FOUND_DIALOG)
        .onTrigger(({ oldState, payload }) => {
            return {
                ...oldState,
                invoiceDocument: getAsyncFetchInitialState(),
                invoiceDetail: getAsyncFetchInitialState(),
                invoiceFull: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<IInvoiceParameters[], IReducerState, IInvoiceParameters[]>({
        baseActionType: FETCH_INVOICE_PARAMETERS,
        overrideTriggerActionType: [ROUTE_KEYS.R_COMPANY_RATES_PARAMETERS],
        fieldName: 'invoiceParameters',
        resetDataOnTrigger: false,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
