import { LOCALE_TO_BACKEND_LOCALE_MAP, LOCALE_TO_BFF_LOCALE_MAP, Locales } from '../../config/i18n.config';
import { TBackendLocale, TBffLocale } from '../../models/general/i18n';

export function localeToBackendLocale(locale: Locales): TBackendLocale {
    return LOCALE_TO_BACKEND_LOCALE_MAP[locale as string];
}

export function localeToBffLocale(locale: Locales): TBffLocale {
    return LOCALE_TO_BFF_LOCALE_MAP[locale as string];
}
