import React, { ReactNode } from 'react';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import PageHeader from '../../appShell/PageHeader';
import ROUTE_KEYS from '../../../routeKeys';
import { ITabItem } from '../../../models/general/navigation';
import connect from '../../../utils/libs/redux/connect';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import {
    shouldPEOverviewBeActive,
    shouldPEPreviousYearBeActive,
    getLatestCompanySituationYear,
    getPreviousCompanySituationYear,
} from '../../../redux/preventionUnits/companySituationHistoryUtils';
import {
    getCompanySituationHistory,
    getShowPreventionUnitsPreviousYearUntilDate,
} from '../../../redux/preventionUnits/selectors';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const CLASS_NAME = 'PreventionUnits';

interface IPrivateProps {
    showOverviewTab: boolean;
    showPreviousYearTab: boolean;
    overviewYear: number;
    previousYear: number;
}

interface IPublicProps {
    children: ReactNode;
}

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const companySituationHistory = getCompanySituationHistory(state);
        const overviewYear = getLatestCompanySituationYear(companySituationHistory);
        const previousYear = getPreviousCompanySituationYear(companySituationHistory);
        const showPreviousYearUntilDate = getShowPreventionUnitsPreviousYearUntilDate(state);
        return {
            showOverviewTab: companySituationHistory && shouldPEOverviewBeActive(companySituationHistory),
            showPreviousYearTab: companySituationHistory &&
                shouldPEPreviousYearBeActive(companySituationHistory, showPreviousYearUntilDate),
            overviewYear,
            previousYear,
        };
    },
})(PreventionUnitsTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function PreventionUnitsTemplate(props: IPublicProps & IPrivateProps) {
    const {
        children,
        showOverviewTab,
        showPreviousYearTab,
        overviewYear,
        previousYear,
    } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'administration.prevention_units.overview.tab_title',
        translationPlaceholders: { year: overviewYear },
        linkTo: ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW,
        hide: !showOverviewTab,
    }, {
        translationKey: 'administration.prevention_units.previous_year.tab_title',
        translationPlaceholders: { year: previousYear },
        linkTo: ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR,
        hide: !showPreviousYearTab,
    }, {
        translationKey: 'administration.prevention_units.archive.tab_title',
        linkTo: ROUTE_KEYS.R_PREVENTION_UNITS_ARCHIVE,
    }];

    const TABS_TO_SHOW = TABS.filter((tab) => !tab.hide);

    const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className={`${CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS_TO_SHOW}
                    title="administration.prevention_units.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.ADMINISTRATION,
                    }}
                    type="grey"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
