import React, { PureComponent } from 'react';
import './seat-input.scss';
import classNames from 'classnames';
import { fields } from './fluVaccineSeatSchema';
export { schema } from './fluVaccineSeatSchema';
import { ICompanySeat } from '../../../../../models/admin/company';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { connect } from '../../../../index';
import SeatTypeahead from '../../../../administration/Employees/shared/SeatTypeahead';
import { IFormRenderProps } from '../../../../common/forms/Form';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';

const CLASS_NAME = 'OrderFluVaccine__SeatInput';

export interface IFormValues {
    seat: ICompanySeat;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues) => void;
}

interface IPrivateProps {
    translator: ITranslator;
}

class SeatInput extends PureComponent<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.onChangeSeatHandler = this.onChangeSeatHandler.bind(this);
    }

    public render() {
        const { formRenderProps, translator } = this.props;

        const selectSeatPlaceholder = translator('interventions.flu_orders_new.steps.seat.select_seat_placeholder');
        const seatPlaceholder = translator('interventions.flu_orders_new.steps.seat.seat_placeholder');

        return (
            <div className={classNames(CLASS_NAME, WIZARDFLOW_CLASSES.NARROW_FORM)}>
                <div className="SeatSelection">
                    <SeatTypeahead
                        id="flu-seat"
                        name={fields.seat}
                        value={formRenderProps.values.seat ? formRenderProps.values.seat.company.companyCode : ''}
                        onItemSelected={this.onChangeSeatHandler}
                        excludeDivisions={false}
                        // allSeats false so that the isAllSeatsSelected (chosen during login) is taken into account
                        allSeats={false}
                        isInvalid={formRenderProps.touched.seat && !!formRenderProps.errors.seat}
                        placeholder={selectSeatPlaceholder}
                    />
                    {formRenderProps.touched.seat && (
                        <FormFieldError
                            error={formRenderProps.errors.seat}
                            placeholders={{ fieldName: seatPlaceholder }}
                        />
                    )}
                </div>
            </div>
        );
    }

    private onChangeSeatHandler(companyCode: string, name: string, seat: ICompanySeat) {
        this.props.onChangeInput({
            seat,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
        };
    },
})(SeatInput);
