import { saveAs } from 'file-saver/FileSaver';

interface IDownloadableDocument {
    data: string | Blob | File;
    filename: string;
}

export default function presentDownloadedFile(payload: IDownloadableDocument) {
    if (!payload) {
        return;
    }

    const {
        data,
        filename,
    } = payload;

    if (!data || !filename) {
        return;
    }

    saveAs(data, filename);
}
