import React, { MouseEvent, PureComponent } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { clone } from 'ramda';
import {
    ICMSCourseAddress,
    ICourseSession,
    COURSE_ENROLL_WIZARD_STEP_ID,
    ICourseEnrollWizardEntity,
} from '../../../../../../../../models/documentCenter/courses';
import { ListColumns, ListItem, SortType } from '../../../../../../../../models/general/list';
import { isSelectedCompanyDismissed } from '../../../../../../../../redux/company/selected/selectors';
import { skipToCourseEnrollWizardStepActions } from '../../../../../../../../redux/documentCenter/courses/actions';
import { getCoursesOverviewDetail } from '../../../../../../../../redux/documentCenter/courses/selectors';
import { getDate } from '../../../../../../../../utils/core/date/getSpecificDate';
import { isInThePast } from '../../../../../../../../utils/core/date/isInThePast';
import { separateStringList } from '../../../../../../../../utils/core/string/separatedStringList';
import { formatDate, formatDateToDayMonth } from '../../../../../../../../utils/formatting/formatDate';
import {
    formatStartEndTimeOfDatesForDisplay,
    formatTimeOfDateForDisplay,
} from '../../../../../../../../utils/formatting/formatTime';
import Button from '../../../../../../../common/buttons/Button';
import ListWithSorting from '../../../../../../../common/list/ListWithSorting';
import AddressMapLink from '../../../../../../../common/navigation/AddressMapLink';
import Translate from '../../../../../../../common/Translate';
import FreeSlotsIndicator from '../../../../../../../common/widget/FreeSlotsIndicator';
import { connect } from '../../../../../../../index';
import { CLASS_NAME, IColumnNames, INITIAL_SORT, TRANSLATION_PREFIX } from './common';

const BASE_NAME = 'course-sessions-by-month-list';

const COLUMNS: ListColumns<IColumnNames> = {
    dateOfFirstDay: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.dateSort,
        excludeFromSearch: true,
        render: renderDate,
    },
    dateSort: {
        hide: true,
        percentWidth: 0,
    },
    dates: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.next_dates`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
        render: renderNextDates,
    },
    language: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.language`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    locationName: {
        hide: true,
        percentWidth: 0,
    },
    addressPostCode: {
        hide: true,
        percentWidth: 0,
    },
    addressCity: {
        hide: true,
        percentWidth: 0,
    },
    addressStreet: {
        hide: true,
        percentWidth: 0,
    },
    addressNumber: {
        hide: true,
        percentWidth: 0,
    },
    addressDisplay: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.address`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.locationName,
        percentWidth: 29,
        render: renderLocation,
    },
    freeSlots: {
        hide: true,
        percentWidth: 0,
    },
    maxAmount: {
        hide: true,
        percentWidth: 0,
    },
    vacant: {
        hide: true,
        percentWidth: 0,
    },
    availability: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.availability`} />,
        sortable: true,
        percentWidth: 22,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.freeSlots,
        sortType: SortType.Number,
    },
};

interface ICourseSessionsListProps {
    items: ListItem<IColumnNames>[];
}

interface IPrivateProps {
    isSelectedCompanyDismissed: boolean;
    onSubscribeClick: (session: ICourseSession) => void;
}

class CourseSessionsList extends PureComponent <ICourseSessionsListProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);

        this.onSubscribeClick = this.onSubscribeClick.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
        this.renderAvailability = this.renderAvailability.bind(this);

        this.columns.availability.render = this.renderAvailability;
    }

    public render() {
        const { items } = this.props;

        return (
            <ListWithSorting
                name={BASE_NAME}
                columns={this.columns}
                items={items}
                initialSort={INITIAL_SORT}
                getCustomRowClasses={this.getCustomRowClasses}
            />
        );
    }

    private getCustomRowClasses(listItem: ListItem<IColumnNames>) {
        return classNames('disable-click', {
            disabled: !listItem.columns.vacant,
        });
    }

    private renderAvailability(item: ListItem<IColumnNames, string, ICourseSession>) {
        const maxSlots = item.columns.maxAmount as number;
        const freeSlots = item.columns.freeSlots as number;

        return (
            <div className={`${CLASS_NAME}__col__availability`}>
                {isInThePast(item.columns.dateSort as string) ? (
                    <Translate msg={`${TRANSLATION_PREFIX}.row.ongoing`} />
                ) : (
                    !item.columns.vacant ? (
                        <Translate msg={`${TRANSLATION_PREFIX}.row.full`} />
                    ) : (
                        <>
                            {!this.props.isSelectedCompanyDismissed && (
                                <Button
                                    id={`${item.id}-select-course-session-button`}
                                    typeName="secondary"
                                    size="small"
                                    onClick={(e) => this.onSubscribeClick(e, item)}
                                >
                                    <Translate msg={`${TRANSLATION_PREFIX}.row.button`} />
                                </Button>
                            )}
                            <div>
                                <Translate
                                    msg={`${TRANSLATION_PREFIX}.row.${freeSlots === 1 ? 'free_slot' : 'free_slots'}`}
                                    placeholders={{ slots: freeSlots }}
                                />
                                <FreeSlotsIndicator
                                    maxSlots={maxSlots}
                                    freeSlots={freeSlots}
                                    hideFreeSlots
                                />
                            </div>
                        </>
                    )
                )}
            </div>
        );
    }

    private onSubscribeClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames, string, ICourseSession>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onSubscribeClick,
        } = this.props;

        onSubscribeClick(listItem.data);
    }

}

function renderDate(item: ListItem<IColumnNames>) {
    return (
        <>
            <span className={`${CLASS_NAME}__col__date ${CLASS_NAME}__col__date--dotw`}>
                {formatDate((item.columns.dateSort as string), 'dddd')}
            </span>
            <span className={`${CLASS_NAME}__col__date`}>
                {formatDateToDayMonth(dayjs(item.columns.dateSort as string).toDate())}
            </span>
        </>
    );
}

function renderNextDates(item: ListItem<IColumnNames>) {
    const dates = separateStringList(item.columns.dates as string).map((date) => {
        const dateArray = separateStringList(date, '.');
        return {
            start: dateArray[0],
            stop: dateArray[1],
        };
    });

    const allDatesHaveSameHours = dates.length <= 1 || dates.reduce((uniqueAccumulator: string[], date) => {
        const time = formatStartEndTimeOfDatesForDisplay(date.start, date.stop);
        if (!uniqueAccumulator.includes(time)) {
            uniqueAccumulator.push(time);
        }
        return uniqueAccumulator;
    }, []).length <= 1;

    const [, ...nextDates] = dates; // remove first date because its shown in another column

    return (
        <>
            <strong>
                <Translate
                    msg={`${TRANSLATION_PREFIX}.next_dates.dates`}
                    placeholders={{
                        dates: nextDates
                            .map((date) => formatDateToDayMonth(getDate(date.start)))
                            .join(', '),
                    }}
                />
            </strong>
            {!allDatesHaveSameHours ? dates.map((date) => (
                <span key={`${date.start}-${date.stop}`}>
                    <Translate
                        msg={`${TRANSLATION_PREFIX}.next_dates.time_individual`}
                        placeholders={{
                            date: formatDateToDayMonth(getDate(date.start)),
                            startTime: formatTimeOfDateForDisplay(getDate(date.start)),
                            endTime: formatTimeOfDateForDisplay(getDate(date.stop)),
                        }}
                    />
                </span>
            )) : (
                <span>
                    <Translate
                        msg={`${TRANSLATION_PREFIX}.next_dates.time_all`}
                        placeholders={{
                            startTime: formatTimeOfDateForDisplay(getDate(dates[0].start)),
                            endTime: formatTimeOfDateForDisplay(getDate(dates[0].stop)),
                        }}
                    />
                </span>
            )}
        </>
    );
}

function renderLocation(item: ListItem<IColumnNames>) {
    const address: ICMSCourseAddress = {
        postcode: item.columns.addressPostCode as string,
        city: item.columns.addressCity as string,
        street: item.columns.addressStreet as string,
        number: item.columns.addressNumber as string,
        website: '',
        phone: '',
        languageCode: '',
    };

    return (
        <>
            <span className={`${CLASS_NAME}__col__location`}>
                {item.columns.locationName}
            </span>
            <AddressMapLink address={address} />
        </>
    );
}


export default connect<IPrivateProps, ICourseSessionsListProps>({
    stateProps: (state) => ({
        isSelectedCompanyDismissed: isSelectedCompanyDismissed(state),
    }),
    dispatchProps: (dispatch, getState) => ({
        onSubscribeClick: (session: ICourseSession) => {
            const selectedCourse = getCoursesOverviewDetail(getState());
            dispatch(skipToCourseEnrollWizardStepActions.trigger({
                wizardPayload: {
                    step: COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE,
                    course: {
                        name: selectedCourse.course.name,
                        description: selectedCourse.course.description,
                        id: session.courseId,
                    },
                },
                entity: {
                    session: {
                        coursesOrganizedId: session.coursesOrganizedId,
                        start: session.start,
                        stop: session.stop,
                        price: session.price,
                        amountPE: session.amountPE,
                        PETariff: session.PETariff,
                        maxAmount: session.maxAmount,
                        attendees: session.attendees,
                    },
                    location: {
                        ...session.address,
                    },
                } as ICourseEnrollWizardEntity,
            }));
        },
    }),
})(CourseSessionsList);
