import React from 'react';

import { IPlannedTimeSlot } from '../../../../../../../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../../../../../../../models/interventions/medicalExaminations/planned';
import CollapsibleItem from '../../../../../../../common/widget/CollapsibleItem';

import CollapsibleDetailTrigger from './CollapsibleDetailTrigger';

const TRANSLATION_PREFIX =
    'interventions.medical_examinations.new.steps.select_timeslot.planned_examination_dialog.remarks';

const RemarksTrigger = <CollapsibleDetailTrigger titleTranslationKey={getPrefixedTranslation('title')} />;

interface IPublicPorps {
    baseClassName: string;
    plannedExamination: IPlannedTimeSlot | IPlannedMedicalExamination;
}

export default function RemarksDetails(props: IPublicPorps) {
    return (
        <CollapsibleItem trigger={RemarksTrigger}>
            <pre>
                {(props.plannedExamination && props.plannedExamination.remarks) || '-'}
            </pre>
        </CollapsibleItem>
    );
}

function getPrefixedTranslation(suffix: string) {
    return `${TRANSLATION_PREFIX}.${suffix}`;
}
