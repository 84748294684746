import { ICompanySeat } from '../../../../models/admin/company';
import { TUserAccountSeat } from '../../../../models/user/userAccount';

export default function filterUserAccountSeatsByCompanySeats(
    companySeats: ICompanySeat[],
    userAccountSeats: TUserAccountSeat[] = [],
    inverse: boolean = false,
): TUserAccountSeat[] {
    return userAccountSeats.filter((seat) => {
        const hasSeat = companySeats.map((item) => item.company.companyCode).includes(seat.companyCode);
        return inverse ? !hasSeat : hasSeat;
    });
}
