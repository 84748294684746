import { get, IResponseType } from '../../utils/api/requestWrapper';
import { ICompanyCodeWithShowFullFamilyPayload, ICompanyCodePayload, ICompany } from '../../models/admin/company';
import {
    IFetchAccomplishmentsContributionsOfYearPayload,
    IAccomplishmentsContribution,
    IFetchAccomplishmentsContributionDetailsOfYearAndTypePayload,
    IAccomplishmentsContributionDetail,
    IPreventionUnits,
    IFetchPreventionUnitsDetailPayload,
    IPreventionUnitTask,
    IExportPreventionUnitsPayload,
    ICompanySituationHistorySuccessPayload,
    IFetchPreventionUnitsPayload,
    IPreventionUnitsResponse,
    TPreventionUnitsGroupType,
} from '../../models/admin/preventionUnits';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';
import { sumFloatsUpToSixDigitsAfterComma } from '../../utils/core/number/calculations';

export const URL = {
    COMPANY_SITUATION_HISTORY: '/companies/{companyCode}/situation-history',
    ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR: '/companies/{companyCode}/accomplishments-contribution/{year}',
    ACCOMPLISHMENTS_CONTRIBUTION_DETAILS_OF_YEAR_AND_TYPE:
        '/companies/{companyCode}/accomplishments-contribution/{year}/{type}',
    PREVENTION_UNITS: '/companies/{companyCode}/prevention-units/{year}',
    PREVENTION_UNITS_DETAIL: '/companies/{companyCode}/prevention-units/{year}/tasks/{type}',
    EXPORT_PREVENTION_UNITS: '/companies/{companyCode}/prevention-units/{year}/export',
};

export function fetchCompanySituationHistory({ companyCode, showFullFamily }: ICompanyCodeWithShowFullFamilyPayload) {
    return get<ICompanySituationHistorySuccessPayload>({
        url: URL.COMPANY_SITUATION_HISTORY,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => {
            return {
                showPreviousYearUntilDate: response['show-previous-year-until-date'],
                situationHistory: response['situation-history'],
            };
        },
    });
}

export function fetchAccomplishmentsContributionsOfYear(
    payload: IFetchAccomplishmentsContributionsOfYearPayload & ICompanyCodeWithShowFullFamilyPayload,
) {
    const { companyCode, showFullFamily, year } = payload;

    return get<IAccomplishmentsContribution[]>({
        url: URL.ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
        pathParams: {
            companyCode,
            year,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['accomplishments-contributions'],
    });
}

export function fetchAccomplishmentsContributionDetailsOfYearAndType(
    payload: IFetchAccomplishmentsContributionDetailsOfYearAndTypePayload & ICompanyCodeWithShowFullFamilyPayload,
) {
    const { companyCode, showFullFamily, year, type } = payload;

    return get<IAccomplishmentsContributionDetail[]>({
        url: URL.ACCOMPLISHMENTS_CONTRIBUTION_DETAILS_OF_YEAR_AND_TYPE,
        pathParams: {
            companyCode,
            year,
            type,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['accomplishments-contributions-overview'],
    });
}

interface IPreventionUnitsApiResponse {
    id: number;
    headQuarterId: number;
    numberInvoiced: number;
    numberPlanned: number;
    numberRegistered: number;
    numberAssigned: number;
    description: string;
    company: Pick<ICompany, 'companyCode'>;
}

const PREVENTION_UNITS_GROUP_TYPE_MAP: {[key: string]: TPreventionUnitsGroupType} = {
    NONE: 'seat',
    PUGRP: 'company',
    VSTCLS: 'type',
};

export function fetchPreventionUnits(
    payload: IFetchPreventionUnitsPayload,
) {
    const { companyCode, year } = payload;

    return get<IPreventionUnitsResponse>({
        url: URL.PREVENTION_UNITS,
        pathParams: {
            companyCode,
            year,
        },
        mapResponse: (apiResponse) => {
            const preventionUnits = apiResponse.dashboards.map((dashboard: IPreventionUnitsApiResponse) => {
                // eslint-disable-next-line max-len
                const { numberAssigned, numberInvoiced, numberPlanned, numberRegistered, ...dashboardProps } = dashboard;

                const response: IPreventionUnits = {
                    ...dashboardProps,
                    units: {
                        assigned: numberAssigned,
                        invoiced: numberInvoiced,
                        planned: numberPlanned,
                        registered: numberRegistered,
                        remaining: sumFloatsUpToSixDigitsAfterComma(
                            [numberAssigned, -numberInvoiced, -numberPlanned, -numberRegistered],
                        ),
                        unused: sumFloatsUpToSixDigitsAfterComma([numberAssigned, -numberInvoiced]),
                    },
                };

                return response;
            });

            return {
                groupType: PREVENTION_UNITS_GROUP_TYPE_MAP[apiResponse.groupType],
                preventionUnits,
            };
        },
    });
}

export function fetchPreventionUnitsDetail(
    payload: IFetchPreventionUnitsDetailPayload,
) {
    const { companyCode, year, type } = payload;

    return get<IPreventionUnitTask[]>({
        url: URL.PREVENTION_UNITS_DETAIL,
        pathParams: {
            companyCode,
            year,
            type,
        },
        mapResponse: (response) => response.tasks,
    });
}

export function exportPreventionUnits(payload: IExportPreventionUnitsPayload & ICompanyCodePayload) {
    return get<IDocument>({
        url: URL.EXPORT_PREVENTION_UNITS,
        responseType: IResponseType.blob,
        pathParams: {
            year: payload.year,
            companyCode: payload.companyCode,
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, 'prevention-units-export.xlsx'),
            };
        },
    });
}
