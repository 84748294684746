import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import {
    DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS,
} from '../../../../views/interventions/Vaccinations/Administered/administered.const';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import {
    IVaccinationsApiResponse,
    IMappedAdministeredVaccination,
} from '../../../../models/interventions/vaccines';
import ROUTE_KEYS from '../../../../routeKeys';
import { createEpic } from '../../../index';
import { getLocale } from '../../../i18n/selectors';
import { getLocationState, getQueryParams } from '../../../location/selectors';
import { getSelectedCompany, getSelectedCompanySeat } from '../../../company/selected/selectors';
import { areAdministeredVaccinationsAvailable } from '../selectors';
import { fetchAdministeredVaccinationsFailed, fetchAdministeredVaccinationsSucceeded } from '../actions';
import { FETCH_ADMINISTERED_VACCINATIONS } from '../types';
import { mapApiResponseToAdministeredVaccinations } from '../mappers';

createEpic({
    onActionType: [
        ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED,
        FETCH_ADMINISTERED_VACCINATIONS,
    ],
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!areAdministeredVaccinationsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());

        const queryWithDefaults = {
            ...DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS,
            ...query,
        };

        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processMultiple({ getState, api }, dispatch, done) {
        try {
            const state = getState();
            const { isAllSeatsSelected, companySeat } = getSelectedCompanySeat(state);

            const { id } = getSelectedCompany(state);
            const { id: seatId } = companySeat.company;

            const {
                startDate = null,
                endDate = null,
            } = getQueryParams(state);

            const locale = getLocale(state);

            const vaccinations: IVaccinationsApiResponse[] =
                await api.interventions.vaccinations.fetchAdministeredVaccinations(
                    {
                        customerId: isAllSeatsSelected ? id : seatId,
                        mode: isAllSeatsSelected ? 'MainSeat' : 'Seat',
                        administeredDateFrom: startDate
                            ? formatDateForBackend(startDate)
                            : DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS.startDate,
                        administeredDateUntil: endDate
                            ? formatDateForBackend(endDate)
                            : DEFAULT_ADMINISTERED_VACCINATIONS_FILTERS.endDate,
                    },
                    locale,
                ) || [];

            dispatch(fetchAdministeredVaccinationsSucceeded(
                mapApiResponseToAdministeredVaccinations(vaccinations) as IMappedAdministeredVaccination[],
            ));
        } catch (error) {
            dispatch(fetchAdministeredVaccinationsFailed(error));
        }
        done();
    },
    latest: false,
});
