import React from 'react';

import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import isEmptyString from '../../../../../utils/core/string/isEmptyString';
import LabelInfo from '../../../../common/widget/LabelInfo';
import { getPrefixedTranslation } from '../AppointmentDetailDialog.helper';

import { IInterventionAppointmentDialogProps } from './InterventionAppointmentDialog.type';

const InterventionAppointmentDialog = ({
    data,
}: IInterventionAppointmentDialogProps) => {
    return (
        <>
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.where')}
                info={data.branch.name}
                hideColon={true}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('labels.who')}
                info={formatPersonName({
                    firstName: data.practitioner.firstName,
                    name: data.practitioner.lastName,
                })}
                hideColon={true}
            />
            {(
                data.interventionType
                && data.interventionType.description
                && !isEmptyString(data.interventionType.description)
            ) && (
                <LabelInfo
                    labelKey={getPrefixedTranslation('labels.intervention_type')}
                    info={data.interventionType.description}
                    hideColon={true}
                />
            )
            }
        </>
    );
};

export { InterventionAppointmentDialog };
