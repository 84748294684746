import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    IAsyncDoField,
    getAsyncFetchInitialState,
    getAsyncDoInitialState,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
    createActionHandlersForType,
    registerReducer,
    createActionHandler,
} from '../../index';
import {
    ADD_EMPLOYEE,
    UPDATE_ADD_EMPLOYEE_DATA,
    FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA,
    SET_ADD_EMPLOYEE_WIZARD_TO_UPDATE,
    UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
} from './types';
import {
    IEmployeeToAdd,
    IAddEmployeeWizardPayload,
    IUpdateEmployeeAllFieldsSucceededPayload,
    IAddEmployeeSucceededPayload,
} from '../../../models/admin/employee';
import ROUTE_KEYS from '../../../routeKeys';
import { getAddEmployeeWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { toResetType } from '../../../utils/libs/redux/typeGenerator';

export const reducerKey = REDUCER_KEYS.EMPLOYEE_WIZARD;

export interface IReducerState {
    employeeToAddInitialData: IAsyncFetchField<Partial<IEmployeeToAdd>>;
    addEmployeeWizardShouldUpdate: boolean;
    employeeToAdd: IEmployeeToAdd; // employee object that is filled partially in each wizard step
    addEmployee: IAsyncDoField; // for the async 'add employee' action at the end of the wizard
    addEmployeeWizardStepId: string;
    updateEmployeeAllFieldsAndEmployment: IAsyncDoField;
}

const initialState: IReducerState = {
    employeeToAddInitialData: getAsyncFetchInitialState(),
    addEmployeeWizardShouldUpdate: false,
    employeeToAdd: null,
    addEmployee: getAsyncDoInitialState(),
    addEmployeeWizardStepId: null,
    updateEmployeeAllFieldsAndEmployment: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<Partial<IEmployeeToAdd>, IReducerState, Partial<IEmployeeToAdd>>({
        baseActionType: FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA,
        fieldName: 'employeeToAddInitialData',
        reducerKey,
    }),
    [ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD]: createResetEmployeeToAddActionHandler(),
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD]: createResetEmployeeToAddActionHandler(),
    [toResetType(FETCH_EMPLOYEE_TO_ADD_INITIAL_DATA)]:
        createActionHandler<IReducerState, object>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    employeeToAdd: null,
                    employeeToAddInitialData: getAsyncFetchInitialState(),
                    addEmployee: getAsyncDoInitialState(),
                    addEmployeeWizardShouldUpdate: false,
                };
            },
        ),
    [SET_ADD_EMPLOYEE_WIZARD_TO_UPDATE]:
        createActionHandler<IReducerState, object>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    addEmployeeWizardShouldUpdate: true,
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_EMPLOYEE,
        fieldName: 'addEmployee',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IAddEmployeeSucceededPayload;
            return {
                ...oldState,
                employeeToAdd: {
                    ...oldState.employeeToAdd,
                    id: successPayload.id,
                    employeeId: successPayload.employeeId,
                },
                employeeToAddInitialData: {
                    ...oldState.employeeToAddInitialData,
                    data: {
                        ...oldState.employeeToAddInitialData.data,
                        ...oldState.employeeToAdd,
                        id: successPayload.id,
                        employeeId: successPayload.employeeId,
                    },
                },
                addEmployeeWizardShouldUpdate: true,
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_ALL_FIELDS_AND_EMPLOYMENT,
        fieldName: 'updateEmployeeAllFieldsAndEmployment',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IUpdateEmployeeAllFieldsSucceededPayload;
            return {
                ...oldState,
                employeeToAdd: {
                    ...oldState.employeeToAdd,
                    ...successPayload,
                },
                employeeToAddInitialData: {
                    ...oldState.employeeToAddInitialData,
                    data: {
                        ...oldState.employeeToAddInitialData.data,
                        ...oldState.employeeToAdd,
                        ...successPayload,
                    },
                },
            };
        },
    }),
    ...createActionHandlersForType<IReducerState>(UPDATE_ADD_EMPLOYEE_DATA)
        .onTrigger<Partial<IEmployeeToAdd>>(({ oldState, payload }) => {
            return {
                ...oldState,
                employeeToAdd: {
                    ...oldState.employeeToAdd,
                    ...payload,
                },
            };
        })
        .create(),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that add-employee-wizard steps are not lost when refreshing
        employeeToAdd: reducerState.employeeToAdd,
        employeeToAddInitialData: reducerState.employeeToAddInitialData,
        addEmployeeWizardStepId: reducerState.addEmployeeWizardStepId,
        addEmployeeWizardShouldUpdate: reducerState.addEmployeeWizardShouldUpdate,
    }),
});

function createResetEmployeeToAddActionHandler() {
    return createActionHandler<IReducerState, IAddEmployeeWizardPayload>(
        ({ oldState, payload }) => {
            const newState = {
                ...oldState,
                addEmployeeWizardStepId: payload.step,
            };
            // First step should be insz
            if (payload.step === getAddEmployeeWizardSteps().firstStepId) {
                newState.employeeToAdd = null;
                newState.employeeToAddInitialData = getAsyncFetchInitialState();
                newState.addEmployee = getAsyncDoInitialState();
                newState.addEmployeeWizardShouldUpdate = false;
            }
            return newState;
        },
    );
}
