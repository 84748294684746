import isObjectPure from '@snipsonian/core/es/is/isObjectPure';

const PATH_PARTS_SEPARATOR = '.';

export interface IColumnName {
    header: string;
    pathParts: string[];
}

export default function extractColumnNames(listData: object[]): IColumnName[] {
    return getDistinctColumnNames(listData);
}

function getDistinctColumnNames(listData: object[]): IColumnName[] {
    return listData.reduce(
        (distinctColumnNamesAccumulator: IColumnName[], row, index) => {
            const rowColumnNames = extractColumnNamesRecursive(row, []);

            if (index === 0) {
                return rowColumnNames;
            }

            rowColumnNames.forEach((rowColumnName) => {
                if (!distinctColumnNamesAccumulator
                    .find((distinctColumnName) => distinctColumnName.header === rowColumnName.header)) {
                    distinctColumnNamesAccumulator.push(rowColumnName);
                }
            });

            return distinctColumnNamesAccumulator;
        },
        [],
    ) as IColumnName[];
}

function extractColumnNamesRecursive(input: any, pathParts: string[]): IColumnName[] {
    if (isObjectPure(input)) {
        return Object.keys(input)
            .reduce(
                (accumulator, key) => {
                    accumulator.push(...extractColumnNamesRecursive(
                        input[key],
                        [...pathParts, key],
                    ));
                    return accumulator;
                },
                [],
            );
    }

    return [{
        header: concatenatePathParts(pathParts),
        pathParts,
    }];
}

function concatenatePathParts(pathParts: string[] = []) {
    return pathParts.join(PATH_PARTS_SEPARATOR);
}
