import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux';
import { IUiHistory } from '../../../models/ui/history';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { UPDATE_UI_HISTORY } from './types';
import { registerReducer } from '../../../utils/libs/redux/registerReducer';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';

export const reducerKey = REDUCER_KEYS.UI_HISTORY;
export const NO_ROUTE = null;

export interface IReducerState extends IUiHistory {}

const initialState: IReducerState = {
    lastDashboardRoute: NO_ROUTE,
    routeThatTriggeredWizard: NO_ROUTE,
};

const actionHandlers = {
    [UPDATE_UI_HISTORY]: createActionHandler<IReducerState, Partial<IUiHistory>>(
        ({ oldState, payload }) => {
            return {
                ...oldState,
                ...payload,
            };
        }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    /* not LOCAL so that there is a separate history per browser tab! */
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState: IReducerState) => (reducerState),
    resetStateOnLocaleSwitch: false,
});
