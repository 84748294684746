import validationErrorIf from './validationErrorIf';
import isEmptyString from '../core/string/isEmptyString';

// eslint-disable-next-line max-len
const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isNotEmail = (value: string) =>
    validationErrorIf({
        condition: isEmptyString(value) || !REGEX.test(value),
        message: 'is_not_email',
    });

export default isNotEmail;
