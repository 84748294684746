import { object } from 'yup';

import number from '../../../utils/libs/yup/number';
import string from '../../../utils/libs/yup/string';

export const createAccountFields = {
    firstName: 'firstName',
    name: 'name',
    email: 'email',
    titleId: 'titleId',
    sexId: 'sexId',
};

export const createAccountSchema = object({
    [createAccountFields.firstName]: string().required(),
    [createAccountFields.name]: string().required(),
    [createAccountFields.email]: string().email().required(),
    [createAccountFields.titleId]: number().required(),
    [createAccountFields.sexId]: number().required(),
});
