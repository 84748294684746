import * as Yup from 'yup';
import {
    ShapeOf,
    IStartEndDateFilterValues,
} from '../../../../models/ui/form';
import { datePeriodValidation } from '../../../../utils/libs/yup/period';

export const fields: ShapeOf<IStartEndDateFilterValues, string> = {
    endDate: 'endDate',
    startDate: 'startDate',
};

export const startEndDateSchema = Yup.object({
    [fields.startDate]: Yup.string().required(),
    [fields.endDate]: datePeriodValidation(Yup.ref(fields.startDate)).required(),
});
