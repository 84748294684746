import { object } from 'yup';
import { schema as editContactSchema } from './editSchema';
import { ErrorTypes } from '../../../../models/general/error';

export const fields = {
    contact: 'contact',
};

export const schema = object({
    [fields.contact]: object()
        .required()
        .test(
            ErrorTypes.Custom,
            'interventions.contact_input.error.invalid_contact',
            isValidContact,
        ),
});

function isValidContact(value: { [field: string]: string }) {
    try {
        editContactSchema.validateSync(value);
    } catch (validationError) {
        return false;
    }

    return true;
}
