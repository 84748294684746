import React from 'react';
import classNames from 'classnames';
import Header from 'react-big-calendar/lib/Header';
import { BASE_CLASS_NAME } from '../common';
import { IHeaderItemProps } from '../../../../../../common/widget/Calendar';
import Translate from '../../../../../../common/Translate';
import { ICalendarEvent, CalendarEventType } from '../../../../../../../models/ui/calendar';
import { isOnSameDay } from '../../../../../../../utils/core/date/isOnSameDay';

export default function HeaderItem(headerProps: IHeaderItemProps) {
    const { date, events } = headerProps;
    const freeTimeslotsCount = getFreeTimeslotsCountForDate(events, date);
    const hasReservedTimeslots = getHasReservedTimeslots(events, date);
    const className = classNames(`${BASE_CLASS_NAME}__calendar-header-item`, {
        'has-no-free-timeslots': freeTimeslotsCount === 0,
        'has-reserved-timeslots': hasReservedTimeslots,
    });
    return (
        <span className={className}>
            <Header {...headerProps} />
            <div className={`${BASE_CLASS_NAME}__calendar-header-item__timeslots`}>
                <Translate
                    msg="interventions.medical_examinations.new.steps.select_timeslot.calendar.free_timeslot_count"
                    placeholders={{
                        count: freeTimeslotsCount,
                    }}
                />
            </div>
        </span>
    );
}

function getFreeTimeslotsCountForDate(calendarEvents: ICalendarEvent[], date: Date) {
    return calendarEvents.filter((event) =>
        isOnSameDay(event.start, date) && event.type === CalendarEventType.FreeTimeslot,
    ).length;
}

function getHasReservedTimeslots(calendarEvents: ICalendarEvent[], date: Date) {
    return calendarEvents.some((event) =>
        isOnSameDay(event.start, date) && event.type === CalendarEventType.FreeTimeslot && event.reserved,
    );
}
