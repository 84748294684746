import React, { PureComponent, ReactNode } from 'react';
import { IStreet, IFetchStreetsPayload } from '../../../../models/general/address';
import AsyncTypeahead from '../../input/Typeahead/AsyncTypeahead';
import { IRequestWrapperPromise } from '../../../../utils/api/requestWrapper';
import { IAsyncFetchField, getAsyncFetchInitialState, getAsyncFetchInfo } from '../../../../redux';
import isTraceableApiError from '../../../../utils/api/isTraceableApiError';
import api from '../../../../api';

export const UNSELECTED_STREET = '';
export const UNSELECTED_STREET_INTERNAL = '__UNSELECTED__STREET';

interface IStreetTypeaheadProps {
    id: string;
    name: string;
    nisCode?: string;
    value: string;
    onItemSelected: (value: string) => void;
    isInvalid?: boolean;
    placeholder?: string;
    children: ReactNode;
    disabled?: boolean;
}

interface IState {
    asyncFetchInfo: IAsyncFetchField<IStreet[]>;
    hasFocus: boolean;
}

export default class StreetTypeahead extends PureComponent<IStreetTypeaheadProps, IState> {
    private streetFetch: IRequestWrapperPromise<IStreet[]>;

    constructor(props: IStreetTypeaheadProps) {
        super(props);

        this.state = {
            asyncFetchInfo: getAsyncFetchInitialState(),
            hasFocus: false,
        };

        this.onFilter = this.onFilter.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.asyncInfoSelector = this.asyncInfoSelector.bind(this);
    }

    public render() {
        const {
            id,
            name,
            children,
            isInvalid,
            placeholder,
            nisCode,
            value,
            onItemSelected,
            disabled,
        } = this.props;
        const { asyncFetchInfo, hasFocus } = this.state;
        const typeaheadData = this.mapStreetsForTypeahead(asyncFetchInfo.data, !hasFocus);

        return (
            <AsyncTypeahead
                id={id}
                value={value}
                valueIfNotSelected={UNSELECTED_STREET_INTERNAL}
                valueToSetOnClear={UNSELECTED_STREET}
                name={name}
                onItemSelected={onItemSelected}
                isInvalid={isInvalid}
                onFilter={this.onFilter}
                asyncInfoSelector={this.asyncInfoSelector}
                data={typeaheadData}
                placeholder={placeholder}
                disabled={!nisCode || disabled}
                minCharsToTriggerSearch={1}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                asyncInfoSelectorDoesNotRequireState={true}
            >
                {children}
            </AsyncTypeahead>
        );
    }

    public componentWillUnmount() {
        if (this.streetFetch) {
            this.streetFetch.cancelRequest();
        }
    }

    private mapStreetsForTypeahead(streets: IStreet[], includeCurrentSelectedItem: boolean = true) {
        const { value } = this.props;

        const streetsData = Array.isArray(streets) ? streets.map((item) => ({
            value: item.street,
            label: item.street,
        })) : [];

        // Push in the current selected item if not present and no dat has been loaded yet
        if (
            value &&
            value !== UNSELECTED_STREET_INTERNAL &&
            streetsData.length === 0 &&
            !streetsData.find((item) => item.value === value) &&
            includeCurrentSelectedItem
        ) {
            streetsData.push({
                value,
                label: value,
            });
        }

        return streetsData;
    }

    private onFilter(filter: string) {
        const { nisCode } = this.props;
        this.fetchStreets({
            nisCode,
            streetNameFilter: filter,
        });
    }

    private onFocus() {
        this.setState({
            hasFocus: true,
        });
    }

    private onBlur() {
        this.setState({
            hasFocus: false,
        });
    }

    private async fetchStreets(payload: IFetchStreetsPayload) {
        if (this.streetFetch) {
            this.streetFetch.cancelRequest();
        }
        this.setState({
            asyncFetchInfo: {
                isFetching: true,
                error: null,
                data: null,
            },
        });
        try {
            this.streetFetch = api.general.address.fetchStreets(payload);
            const streets = await this.streetFetch;
            this.setState({
                asyncFetchInfo: {
                    isFetching: false,
                    error: null,
                    data: streets,
                },
            });
        } catch (error) {
            if (isTraceableApiError(error) && error.wasCancelled) {
                return;
            }
            this.setState({
                asyncFetchInfo: {
                    isFetching: false,
                    error,
                    data: null,
                },
            });
        }
    }

    private asyncInfoSelector() {
        return getAsyncFetchInfo(this.state.asyncFetchInfo);
    }
}
