import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import CompanySelection from './CompanySelection';
import ROUTE_KEYS from '../../routeKeys';
import SeatsSelection from './SeatsSelection';
import AccountSettingsTemplate from './AccountSettings/template';
import MessageCenterTemplate from './MessageCenter/template';
import { IMainHeaderAndFooterTemplateProps } from '../appShell/templates/MainHeaderAndFooterTemplate';
import Profile from './AccountSettings/Profile';
import ManageUsers from './AccountSettings/ManageUsers';
import { Permission } from '../../models/auth/authorisation';
import AddUserWizard from './AccountSettings/ManageUsers/AddUser/wizard';
import { IWizardTemplateProps } from '../appShell/templates/WizardTemplate';
import MessageCenter from './MessageCenter';
import { getPropertyFromRoutePayload } from '../../redux/location/selectors';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';
import { getMyUserProfile, isMainAdministrator } from '../../redux/auth/selectors';

const BASE_ROUTE = '/account';
const ACCOUNT_SETTINGS_ROUTE = `${BASE_ROUTE}/settings`;
const MESSAGE_CENTER_ROUTE = `${BASE_ROUTE}/messages`;

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_COMPANY_SELECTION]: {
        path: `${BASE_ROUTE}/company-selection`,
        page: {
            key: 'COMPANY_SELECTION',
            component: CompanySelection,
        },
        template: templates.MainHeaderAndFooterTemplate,
        templateProps: {
            showAccountMenu: false,
            showCompanySelectDropdown: false,
            hideFooterRouteLinks: true,
        } as IMainHeaderAndFooterTemplateProps,
        virtualPage: 'company_selection',
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_SEATS_SELECTION]: {
        path: `${BASE_ROUTE}/seats-selection/:companyCode`,
        page: {
            key: 'SEATS_SELECTION',
            component: SeatsSelection,
        },
        template: templates.MainHeaderAndFooterTemplate,
        templateProps: {
            showAccountMenu: false,
            showCompanySelectDropdown: false,
            hideFooterRouteLinks: true,
        } as IMainHeaderAndFooterTemplateProps,
        virtualPage: 'seats_selection',
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_SETTINGS]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/profile`,
        page: {
            key: 'ACCOUNT_SETTINGS',
            component: Profile,
        },
        template: AccountSettingsTemplate,
        virtualPage: 'account_settings_profile',
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.profile.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.companyAdministrators,
            refreshMode: 'never',
            shouldFetch: ({ state }) => getMyUserProfile(state) && !isMainAdministrator(state),
            resetDataOnTrigger: false,
        }],
    },
    [ROUTE_KEYS.R_ACCOUNT_SETTINGS_UPDATE_AVATAR]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/profile/update-avatar`,
        page: {
            key: 'ACCOUNT_SETTINGS_UPDATE_AVATAR',
            component: Profile,
        },
        template: AccountSettingsTemplate,
        virtualPage: 'account_settings_update_avatar',
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.update_avatar.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/manage-users`,
        page: {
            key: 'ACCOUNT_MANAGE_USERS',
            component: ManageUsers,
        },
        template: AccountSettingsTemplate,
        virtualPage: 'account_settings_manage_users',
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.manage_users.breadcrumbs_label',
        },
        requiredAccessLevels: [{ extraPermissions: [Permission.CAN_ADD_USER] }],
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/manage-users/add`,
        page: {
            key: 'ACCOUNT_MANAGE_USERS_ADD',
            component: ManageUsers,
        },
        template: AccountSettingsTemplate,
        virtualPage: 'account_settings_manage_users_add',
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.manage_users.add.breadcrumbs_label',
        },
        requiredAccessLevels: [{ extraPermissions: [Permission.CAN_ADD_USER] }],
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/manage-users/add/internal/:stepId`,
        page: {
            key: 'ACCOUNT_MANAGE_USERS_ADD_INTERNAL',
            component: AddUserWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'account.account_settings.manage_users.add.title.add_internal',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'account_settings_manage_users_add_internal',
            (state) => getPropertyFromRoutePayload(state, 'stepId'),
        ],
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.manage_users.add.wizard.add_internal.breadcrumbs_label',
        },
        requiredAccessLevels: [{ extraPermissions: [Permission.CAN_ADD_USER] }],
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/manage-users/add/external/:stepId`,
        page: {
            key: 'ACCOUNT_MANAGE_USERS_ADD_EXTERNAL',
            component: AddUserWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'account.account_settings.manage_users.add.title.add_external',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'account_settings_manage_users_add_external',
            (state) => getPropertyFromRoutePayload(state, 'stepId'),
        ],
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.manage_users.add.wizard.add_external.breadcrumbs_label',
        },
        requiredAccessLevels: [{ extraPermissions: [Permission.CAN_ADD_USER] }],
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_DETAIL]: {
        path: `${ACCOUNT_SETTINGS_ROUTE}/manage-users/:username`,
        page: {
            key: 'ACCOUNT_MANAGE_USERS_DETAIL',
            component: ManageUsers,
        },
        template: AccountSettingsTemplate,
        virtualPage: 'account_settings_manage_users_detail',
        breadcrumbsLabel: {
            translationKey: 'account.account_settings.manage_users.detail.breadcrumbs_label',
        },
        requiredAccessLevels: [{ extraPermissions: [Permission.CAN_ADD_USER] }],
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_MESSAGE_CENTER]: {
        path: `${MESSAGE_CENTER_ROUTE}`,
        page: {
            key: 'MESSAGE_CENTER',
            component: MessageCenter,
        },
        template: MessageCenterTemplate,
        virtualPage: 'message_center_overview',
        breadcrumbsLabel: {
            translationKey: 'account.message_center.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_MESSAGE_CENTER_DETAIL]: {
        path: `${MESSAGE_CENTER_ROUTE}/detail/:id`,
        page: {
            key: 'MESSAGE_CENTER_DETAIL',
            component: MessageCenter,
        },
        template: MessageCenterTemplate,
        virtualPage: 'message_center_overview_detail',
        breadcrumbsLabel: {
            translationKey: 'account.message_center.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
};

export default ROUTES;
