import React, { Fragment } from 'react';
import AccessToList from '../../shared/AccessToList';
import connect from '../../../../../utils/libs/redux/connect';
import { IAccessLevel } from '../../../../../models/auth/authorisation';
import { getMyUserAccessLevel, isMainAdministrator } from '../../../../../redux/auth/selectors';
import Translate from '../../../../common/Translate';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { IUserAccount } from '../../../../../models/user/userAccount';
import EntityWrapper from '../../../../common/widget/EntityWrapper';
import { getAsyncCompanyAdministrators } from '../../../../../redux/entities/company/selectors';

interface IPrivateProps {
    accessLevel: IAccessLevel;
    isMainAdministrator: boolean;
}

function AccessLevels(props: IPrivateProps) {
    return (
        <>
            {!props.isMainAdministrator &&
                <EntityWrapper
                    asyncEntitySelector={getAsyncCompanyAdministrators}
                    fetch={{
                        useTinyLoader: true,
                    }}
                    renderData={renderCompanyAdministrators}
                />
            }
            <AccessToList readOnly={true} accessLevel={props.accessLevel} />
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            accessLevel: getMyUserAccessLevel(state),
            isMainAdministrator: isMainAdministrator(state),
        };
    },
})(AccessLevels);

function renderCompanyAdministrators({ data: administrators }: { data: IUserAccount[] }) {
    const administratorsWithEmail = administrators
        .filter((item) => !!item.email);

    const administratorsPlaceholder = administratorsWithEmail
        .map((administrator, index) => (
            <Fragment key={administrator.webUserId}>
                <a href={`mailto:${administrator.email}`}>
                    {formatPersonName(administrator)}
                </a>
                {index !== administratorsWithEmail.length - 1 && ', '}
            </Fragment>
        ));

    return (
        <p>
            <Translate
                msg="account.account_settings.profile.access_levels.info_message"
                placeholders={{
                    adminNames: administratorsPlaceholder,
                }}
            />
        </p>
    );
}
