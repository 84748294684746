import React, { ReactNode, PureComponent, createRef, CSSProperties } from 'react';
import './sticky.scss';
import classNames from 'classnames';
import stickyfilljs from 'stickyfilljs';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import findClosestScrollContainer from '../../../../utils/dom/findClosestScrollContainer';

const CLASS_NAME = 'Sticky';
const activeStickyElems: {
    [id: string]: {
        el: HTMLDivElement,
        stickies: stickyfilljs.Sticky[],
    },
} = {};

interface IStickyProps {
    id: string;
    children: ReactNode;
    className?: string;
    topOffset?: number;
    includeTopOffsetForIds?: string[];
}

interface IComponentState {
    isInStickyPosition: boolean;
}

export default class Sticky extends PureComponent<IStickyProps, IComponentState> {
    private elementRef = createRef<HTMLDivElement>();
    private whitespaceRef = createRef<HTMLDivElement>();
    private onResizeDebounced: TDebounced;
    private scrollContainer: HTMLElement | Window;
    private isUnmounted: boolean = false;

    constructor(props: IStickyProps) {
        super(props);

        this.state = {
            isInStickyPosition: false,
        };

        this.onScroll = this.onScroll.bind(this);
        this.onResizeDebounced = debounce(this.onResize.bind(this), 100);

        window.addEventListener('resize', this.onResizeDebounced);
    }

    public render() {
        const { children, className } = this.props;

        const cssClassName = classNames(CLASS_NAME, className);
        const top = this.calculateTopOffset();
        return (
            <>
                <div
                    className={`${CLASS_NAME}__whitespace`}
                    style={this.getWhitespaceStyles()}
                    ref={this.whitespaceRef}
                />
                <div
                    className={cssClassName}
                    style={{
                        top: isNaN(top) ? 0 : top,
                    }}
                    ref={this.elementRef}
                >
                    {children}
                </div>
            </>
        );
    }

    public componentDidMount() {
        this.elementRef.current.style.top = this.calculateTopOffset() + 'px';
        const sticky = stickyfilljs.addOne(this.elementRef.current);
        if (process.env.NODE_ENV === 'development') {
            if (activeStickyElems[this.props.id]) {
                throw new Error('There is already a Sticky component with the same id.');
            }
        }
        activeStickyElems[this.props.id] = {
            el: this.elementRef.current,
            stickies: [sticky],
        };
        this.scrollContainer = findClosestScrollContainer(this.elementRef.current);
        this.scrollContainer.addEventListener('scroll', this.onScroll);
        // Ensure it is done after all animations are finished (eg in an overlaycontent)
        window.requestAnimationFrame(() => {
            if (this.isUnmounted) {
                return;
            }
            this.setState({
                isInStickyPosition: this.isInStickyPosition(),
            });
        });
    }

    public componentWillUnmount() {
        stickyfilljs.removeOne(this.elementRef.current);
        stickyfilljs.removeOne(this.whitespaceRef.current);
        delete activeStickyElems[this.props.id];
        this.onResizeDebounced.cancel();
        window.removeEventListener('resize', this.onResizeDebounced);
        this.scrollContainer.removeEventListener('scroll', this.onScroll);
        this.isUnmounted = true;
    }

    private calculateTopOffset() {
        const { topOffset, includeTopOffsetForIds } = this.props;
        if (Array.isArray(includeTopOffsetForIds)) {
            const topOffsetsOtherStickyEls = includeTopOffsetForIds.reduce(
                (accumulator, id) => {
                    const activeEl = activeStickyElems[id];
                    const offset = activeEl
                        ? Math.ceil(activeEl.el.offsetHeight) + parseInt(activeEl.el.style.top, 10) - 1
                        : 0;
                    return accumulator + offset;
                },
                topOffset || 0,
            );
            return topOffsetsOtherStickyEls;
        }
        return topOffset || 0;
    }

    private onResize() {
        const isInStickyPosition = this.isInStickyPosition();
        this.setState({
            isInStickyPosition,
        });
        this.forceUpdate();
        this.refreshStickyElementsForLegacyBrowsers(isInStickyPosition);
    }

    private onScroll() {
        const isInStickyPosition = this.isInStickyPosition();
        this.setState({
            isInStickyPosition,
        });
        this.refreshStickyElementsForLegacyBrowsers(isInStickyPosition);
    }

    private isInStickyPosition() {
        const activeEl = activeStickyElems[this.props.id];
        if (!activeEl || !activeEl.el) {
            return false;
        }
        const yPosition = Math.floor(activeEl.el.getBoundingClientRect().top);
        const scrollEl = this.scrollContainer as HTMLElement;
        const scrollContainerMarginTop = (scrollEl && scrollEl.style
            && parseInt(getComputedStyle(scrollEl).marginTop, 10)) || 0;
        return yPosition <= (this.calculateTopOffset() + scrollContainerMarginTop);
    }

    private getWhitespaceStyles(): CSSProperties {
        const { topOffset } = this.props;
        const { isInStickyPosition } = this.state;
        return {
            display: topOffset && topOffset > 0 && isInStickyPosition ? 'block' : 'none',
            height: topOffset,
            minHeight: topOffset,
        };
    }

    private refreshStickyElementsForLegacyBrowsers(isInStickyPosition: boolean) {
        const activeEl = activeStickyElems[this.props.id];
        if (
            activeEl &&
            (activeEl.el.style.position === 'fixed' || activeEl.el.style.position === 'absolute') &&
            Array.isArray(this.props.includeTopOffsetForIds) && this.props.includeTopOffsetForIds.length > 0
        ) {
            activeEl.stickies[0].refresh();
            if (activeEl.el.style.position === 'absolute') {
                const topOffset = this.calculateTopOffset() - activeEl.el.getBoundingClientRect().top;
                activeEl.el.style.top = isInStickyPosition
                    ? topOffset + 'px'
                    : 0 + 'px';
            } else {
                activeEl.el.style.top = isInStickyPosition
                    ? this.calculateTopOffset() + 'px'
                    : 0 + 'px';
            }
        }
    }
}
