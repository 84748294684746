import React, { ReactNode } from 'react';
import './legend.scss';
import classNames from 'classnames';
import Translate, { TPlaceholders } from '../../Translate';
import { ColorHex } from '../../../../models/general/colors';
import TooltipWithIcon from '../TooltipWithIcon';
import Icon from '../../icons/Icon';

const CLASS_NAME = 'Legend';

interface ILegendBase {
    color: ColorHex | string;
    colorClassName?: string;
}

interface ILegendWithValue extends ILegendBase {
    value: number | string;
    valueColorOverride?: string;
}

interface ILegendWithLabel extends ILegendBase {
    label: ReactNode;
}

export type TLegendItem = ILegendWithValue | ILegendWithLabel | (ILegendWithLabel & ILegendWithValue);

interface ILegendProps {
    className?: string;
    data: TLegendItem[];
    titleTranslationKey?: string;
    titlePlaceholders?: TPlaceholders;
    tooltipTranslationKey?: string;
    small?: boolean;
}

export default function Legend(props: ILegendProps) {
    const infoIcon = (
        <Icon typeName="info" circle />
    );

    return (
        <div className={classNames(CLASS_NAME, props.className, { small: props.small })}>
            {props.titleTranslationKey && (
                <h6 className={`${CLASS_NAME}__title`}>
                    <Translate msg={props.titleTranslationKey} placeholders={props.titlePlaceholders} />
                    {props.tooltipTranslationKey && (
                        <TooltipWithIcon
                            icon={infoIcon}
                            tooltipBubbleIcon={infoIcon}
                            iconSize="small"
                            typeName="info-bubble"
                        >
                            <Translate msg={props.tooltipTranslationKey} />
                        </TooltipWithIcon>
                    )}
                </h6>
            )}
            <table className="keep-table-layout-on-mobile">
                <tbody>
                    {props.data.map((legendItem, index) => {
                        const item = legendItem as ILegendWithLabel & ILegendWithValue;
                        return (
                            <tr key={index}>
                                <td>
                                    <span
                                        className={classNames('item-reference', {
                                            [item.colorClassName]: !!item.colorClassName,
                                        })}
                                        style={{ backgroundColor: !item.colorClassName ? item.color : '' }}
                                    />
                                </td>
                                {item.value && (
                                    <td className="item-value" style={{ color: item.valueColorOverride || item.color }}>
                                        {item.value}
                                    </td>
                                )}
                                {item.label && <td>{item.label}</td>}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
