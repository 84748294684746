import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
    createAsyncDoActionHandlers,
    createActionHandler,
} from '../../index';
import {
    FETCH_COMPANY_USERS,
    FETCH_COMPANY_USER_DETAILS,
    UPDATE_COMPANY_USER_DETAILS,
    SET_ADD_COMPANY_USER_WIZARD_DATA,
    ADD_COMPANY_USER,
    REMOVE_COMPANY_USER,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IUserAccount, IUserAccountDetail, IAddUserAccountPayload } from '../../../models/user/userAccount';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.COMPANY_USERS;

export interface IReducerState {
    companyUsers: IAsyncFetchField<IUserAccount[]>;
    selectedCompanyUserDetails: IAsyncFetchField<IUserAccountDetail>;
    updateCompanyUserDetails: IAsyncDoField;
    userAccountWizardData: Partial<IAddUserAccountPayload>;
    addCompanyUser: IAsyncDoField;
    removeCompanyUser: IAsyncDoField;
}

const initialState: IReducerState = {
    companyUsers: getAsyncFetchInitialState(),
    selectedCompanyUserDetails: getAsyncFetchInitialState(),
    updateCompanyUserDetails: getAsyncDoInitialState(),
    userAccountWizardData: {},
    addCompanyUser: getAsyncDoInitialState(),
    removeCompanyUser: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IUserAccount[], IReducerState, IUserAccount[]>({
        baseActionType: FETCH_COMPANY_USERS,
        fieldName: 'companyUsers',
        resetDataOnTrigger: false,
        overrideTriggerActionType: [FETCH_COMPANY_USERS, ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IUserAccountDetail, IReducerState, IUserAccountDetail>({
        baseActionType: FETCH_COMPANY_USER_DETAILS,
        fieldName: 'selectedCompanyUserDetails',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_DETAIL,
            FETCH_COMPANY_USER_DETAILS,
        ],
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_COMPANY_USER_DETAILS,
        fieldName: 'updateCompanyUserDetails',
    }),
    [SET_ADD_COMPANY_USER_WIZARD_DATA]: createActionHandler<IReducerState, Partial<IAddUserAccountPayload>>(
        ({ oldState, payload }) => {
            return {
                ...oldState,
                userAccountWizardData: {
                    ...oldState.userAccountWizardData,
                    ...payload,
                },
            };
        },
    ),
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD]: createActionHandler<IReducerState>(
        ({ oldState }) => {
            return {
                ...oldState,
                userAccountWizardData: initialState.userAccountWizardData,
            };
        },
    ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_COMPANY_USER,
        fieldName: 'addCompanyUser',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_COMPANY_USER,
        fieldName: 'removeCompanyUser',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (state) => {
        return {
            userAccountWizardData: state.userAccountWizardData,
        };
    },
});
