import React from 'react';
import Icon from '../../icons/Icon';
import './search-input.scss';
import UncontrolledTextInput from '../UncontrolledTextInput';
import TooltipWithIcon from '../../widget/TooltipWithIcon';
import Translate from '../../Translate';

interface ISearchInputProps {
    placeholder: string;
    defaultValue: string;
    disabled?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isInvalid?: boolean;
    tooltipTranslationKey?: string;
}

const CLASS_NAME = 'SearchInput';

export default function SearchInput(props: ISearchInputProps) {
    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__input-wrapper`}>
                <UncontrolledTextInput
                    id="search-input"
                    placeholder={props.placeholder}
                    name="search-input"
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                    isInvalid={props.isInvalid}
                />
                <Icon typeName="search" />
            </div>
            {props.tooltipTranslationKey && (
                <TooltipWithIcon icon={<Icon typeName="info" circle/>} typeName="info-bubble" iconSize="large">
                    <Translate msg={props.tooltipTranslationKey} />
                </TooltipWithIcon>
            )}
        </div>
    );
}
