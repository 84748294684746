import React from 'react';
import { connect } from '../../index';
import
ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../common/widget/ContentWithSidebar';
import { IFaqOverview, IFaqItem, IFaqSearchPayload } from '../../../models/contact/faq';
import { getFaqOverview, getFaqOverviewAsyncInfo } from '../../../redux/contact/selectors';
import Translate from '../../common/Translate';
import ListItemBordered from '../../common/list/ListItemBordered';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import { ITranslator } from '../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import SearchInput from '../../common/input/SearchInput';
import { getQueryParams } from '../../../redux/location/selectors';
import { navigateTo } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { PAYLOAD_PARAM } from '../../../utils/libs/redux/async/asyncReducerUtils';
import isEmptyObject from '../../../utils/core/object/isEmptyObject';
import Breadcrumbs from '../../appShell/Breadcrumbs';
import './faq.scss';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../utils/core/string/anchor';
import Loader from '../../common/waiting/Loader';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import TinyLoader from '../../common/waiting/TinyLoader';
import classNames from 'classnames';

const CLASS_NAME = 'Faq';

interface IPrivateProps {
    faqOverview: IFaqOverview;
    translator: ITranslator;
    filter: IFaqSearchPayload;
    faqOverviewAsyncInfo: IAsyncFieldInfo;
    setSearchKeyword: (filter: IFaqSearchPayload) => void;
}

function FaqOverviewComp(props: IPrivateProps) {
    return (
        <div className={CLASS_NAME}>
            <Breadcrumbs />
            <div className="container">
                <ContentWithSidebar
                    sidebar={(renderProps) => <SidebarContent {...props} {...renderProps} />}
                    content={<MainContent {...props} />}
                    titleIds={Object.keys(props.faqOverview)}
                />
            </div>
        </div>
    );
}

function SidebarContent(props: IPrivateProps & IContentWithSidebarRenderProps) {
    const {
        faqOverview, activeTitleId, setActiveTitleIdOverride,
    } = props;

    return (
        <>
            <nav>
                <TinyLoader asyncInfoSelector={getFaqOverviewAsyncInfo}>
                    {!isEmptyObject(faqOverview) && (
                        <>
                            {
                                Object.keys(faqOverview).map((category, index) => {
                                    const titleClass = classNames({
                                        active: activeTitleId === category,
                                    });
                                    return (
                                        <SideBarLink
                                            key={index}
                                            href={toAnchorLinkHref(category)}
                                            className={titleClass}
                                            setActiveTitleIdOverride={() => setActiveTitleIdOverride(category)}
                                        >
                                            {category} ({faqOverview[category].length})
                                        </SideBarLink>
                                    );
                                })
                            }
                        </>
                    )}
                </TinyLoader>
            </nav>
        </>
    );
}

function MainContent(props: IPrivateProps) {
    const {
        faqOverview,
        translator,
        setSearchKeyword,
        faqOverviewAsyncInfo,
        filter,
    } = props;

    function onSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchKeyword({ keywordFilter: e.target.value });
    }

    function renderCategory(item: IFaqItem, index) {
        return (
            <ListItemBordered
                key={index}
                text={item.question}
                id={item.id}
                raw={true}
                to={{
                    type: ROUTE_KEYS.R_FAQ_DETAIL,
                    payload: {
                        id: item.id,
                    },
                }}
            />
        );
    }

    return (
        <div className="content">
            <div className="page-header">
                <h1><Translate msg="contact.faq.title" /></h1>
                <FloatableTextInputWrapper>
                    <SearchInput
                        placeholder={translator('contact.faq.filter.keyword')}
                        onChange={onSearchInput}
                        defaultValue={filter.keywordFilter}
                    />
                </FloatableTextInputWrapper>
            </div>
            <div className="page-content">
                <Loader show={faqOverviewAsyncInfo.status}>
                    {!isEmptyObject(faqOverview) ?
                        <>
                            {
                                Object.keys(faqOverview).map((category, index) => {
                                    return (
                                        <div key={index} className={`${CLASS_NAME}__section`}>
                                            <h2 id={toAnchorTargetId(category)}>
                                                {category}
                                            </h2>
                                            {
                                                // eslint-disable-next-line max-len
                                                faqOverview[category].map((item: IFaqItem, index) => renderCategory(item, index))
                                            }
                                        </div>
                                    );
                                })
                            }
                        </> :
                        <p className={`${CLASS_NAME}__no-results`}>
                            <Translate
                                msg="contact.faq.no_results"
                            />
                        </p>
                    }
                </Loader>
            </div>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const faqOverview = getFaqOverview(state);

        return {
            faqOverview,
            translator: getTranslatorDeprecated(state),
            filter: getQueryParams<IFaqSearchPayload>(state),
            faqOverviewAsyncInfo: getFaqOverviewAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setSearchKeyword: (filter: IFaqSearchPayload) => {
                const state = getState();
                const currentQuery = getQueryParams(state);
                dispatch(navigateTo(
                    ROUTE_KEYS.R_FAQ_OVERVIEW,
                    {
                        [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                    },
                    {
                        ...currentQuery,
                        ...filter,
                    },
                ));
            },
        };
    },
})(FaqOverviewComp);
