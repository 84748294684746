import React from 'react';
import Translate from '../../common/Translate';
import { connect } from '../../index';
import { getCurrentYear } from '../../../utils/core/date/getSpecificDate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import { ILocationAction } from '../../../models/general/redux';
import { getCurrentRouteWithExtraQueryParam } from '../../../redux/location/actions';
import { COOKIE_CONSENT_SETTINGS_QUERY_PARAM } from '../CookieConsent/route';
import { PRIVACY_POLICY_QUERY_PARAM } from '../PrivacyPolicy/routes';
import { DISCLAIMER_QUERY_PARAM } from '../Disclaimer/route';

const COPYRIGHT_YEAR = getCurrentYear();

// TODO once logDocumentDownload is implemented, that would be a better match than logging an outgoing link

interface IPrivateProps {
    privacyPolicyLink: ILocationAction<object>;
    cookieSettingsLink: ILocationAction<object>;
    disclaimerLink: ILocationAction<object>;
}

function FooterSubNavs({
    privacyPolicyLink,
    cookieSettingsLink,
    disclaimerLink,
}: IPrivateProps) {
    return (
        <>
            <span><Translate msg="app_shell.footer.subnav.copyright" /> &copy; {COPYRIGHT_YEAR}</span>
            <LinkToRoute
                id="disclaimer"
                to={disclaimerLink}
            >
                <Translate msg="app_shell.footer.subnav.disclaimer" />
            </LinkToRoute>
            <LinkToRoute
                id="privacy-policy"
                to={privacyPolicyLink}
            >
                <Translate msg="app_shell.footer.subnav.privacy" />
            </LinkToRoute>
            {/* <LinkToRoute
                id="cookie-settings"
                to={cookieSettingsLink}
            >
                <Translate msg="app_shell.footer.subnav.cookies" />
            </LinkToRoute> */}
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        // cookieSettingsLink: getCurrentRouteWithExtraQueryParam(state, {
        //     [COOKIE_CONSENT_SETTINGS_QUERY_PARAM]: true,
        // }),
        privacyPolicyLink: getCurrentRouteWithExtraQueryParam(state, {
            [PRIVACY_POLICY_QUERY_PARAM]: true,
        }),
        disclaimerLink: getCurrentRouteWithExtraQueryParam(state, {
            [DISCLAIMER_QUERY_PARAM]: true,
        }),
    }),
})(FooterSubNavs);
