import React, { ReactNode } from 'react';
import classNames from 'classnames';
import TextInput from '../../input/TextInput';
import './property-input.scss';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';
import Typeahead, { TTypeaheadData } from '../Typeahead';
import { TDropdownItemValue } from '../Dropdown';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { IState } from '../../../../redux';
import { ITranslator } from '../../../../models/general/i18n';
import AsyncTypeahead from '../Typeahead/AsyncTypeahead';
import { ConstantType } from '../../../../models/general/constants';
import ConstantsTypeahead from '../ConstantsTypeahead';
import { getTypeaheadData } from '../../../../config/constants';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { localeToBackendLocale } from '../../../../utils/formatting/formatLocale';
import MaskedTextInput from '../MaskedTextInput';
import { maskArray } from 'react-text-mask';
import TooltipWithIcon from '../../widget/TooltipWithIcon';

interface IContextProps {
    translator: ITranslator;
}

interface ICommonPropertyInputProps {
    id: string;
    name: string;
    labelKey: string;
    readonly: boolean;
    disabled?: boolean;
    children?: ReactNode | ((pencilIcon: ReactNode) => ReactNode);
    isInvalid?: boolean;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    readOnlyColor?: 'primary';
    multiLine?: boolean;
    rows?: number;
    scaleWithTextIfMultiLine?: boolean;
    hideLabel?: boolean;
    tooltipTranslationKey?: string;
}

export interface ITextPropertyInputProps extends ICommonPropertyInputProps {
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disableAutoComplete?: boolean;
    readOnlyHideValuePlaceholder?: boolean;
    hasMask?: boolean;
    mask?: maskArray;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export interface ITypeaheadPropertyInputProps extends ICommonPropertyInputProps {
    value: TDropdownItemValue;
    data: TTypeaheadData;
    onItemSelected: (selectedValue: TDropdownItemValue) => void;
}

export interface IConstantsTypeaheadPropertyInputProps extends ICommonPropertyInputProps {
    value: string | number;
    constantType: ConstantType;
    onItemSelected: (selectedValue: TDropdownItemValue) => void;
    resetSelectWhenValueNotFoundInData?: boolean;
}

export interface IConstantsAdvancedPropertyInputProps extends ICommonPropertyInputProps {
    onClick?: () => void;
    value: string | number;
    constantType: ConstantType;
}

export interface IAsyncTypeaheadPropertyInputProps extends ITypeaheadPropertyInputProps {
    asyncInfoSelector?: (state: IState) => IAsyncFieldInfo;
    onFilter?: (filter: string) => void;
}

interface IAdvancedPropertyInputProps {
    labelKey: string;
    value: React.ReactNode;
    readonly: boolean;
    onClick: () => void;
    children?: ReactNode | ((pencilIcon: ReactNode) => ReactNode);
    hideLabel?: boolean;
    isInvalid?: boolean;
}

function TextPropertyInputComp(props: ITextPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
        ['read-only-primary']: props.readOnlyColor === 'primary',
        ['disabled']: !!props.disabled,
    });

    return (
        <PropertyInput
            labelKey={props.labelKey}
            hideLabel={props.hideLabel}
            tooltipTranslationKey={props.tooltipTranslationKey}
        >
            {
                props.readonly ? (
                    <div className={fakeInputClasses} title={props.value ? props.value.toString() : ''}>
                        {props.value ? props.value : props.readOnlyHideValuePlaceholder ? '' : '-'}
                    </div>
                ) : props.hasMask ?
                        (
                            <MaskedTextInput
                                id={props.id}
                                name={props.name}
                                onChange={props.onChange}
                                onFocus={props.onFocus}
                                onBlur={props.onBlur}
                                disabled={props.disabled}
                                value={props.value}
                                isInvalid={props.isInvalid}
                                placeholder={props.translator(props.labelKey)}
                                disableAutoComplete={props.disableAutoComplete}
                                mask={props.mask}
                            />
                        )
                        : (
                            <TextInput
                                id={props.id}
                                name={props.name}
                                onChange={props.onChange}
                                onFocus={props.onFocus}
                                onBlur={props.onBlur}
                                disabled={props.disabled}
                                value={props.value}
                                isInvalid={props.isInvalid}
                                placeholder={props.translator(props.labelKey)}
                                multiLine={props.multiLine}
                                disableMultilineResize={true}
                                rows={props.rows}
                                scaleWithTextIfMultiLine={props.scaleWithTextIfMultiLine}
                                disableAutoComplete={props.disableAutoComplete}
                            />
                        )
            }
            {props.children}
        </PropertyInput>
    );
}

function AdvancedPropertyInputComp(props: IAdvancedPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
        ['invalid']: !!props.isInvalid,
    });

    const placeholder = (
        <span className="fake-input__placeholder">{props.translator(props.labelKey)}</span>
    );

    return (
        <PropertyInput labelKey={props.labelKey} hideLabel={props.hideLabel}>
            <div
                className={fakeInputClasses}
                onClick={props.readonly ? undefined : props.onClick}
            >
                {props.value || (!props.readonly && placeholder)}
            </div>
            {props.children}
        </PropertyInput>
    );
}

function TypeaheadPropertyInputComp(props: ITypeaheadPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
    });

    const selectedItem = props.data.find((item) => (
        item.value && item.value.toString()) === (props.value ? props.value.toString() : ''
        ));

    return (
        <PropertyInput labelKey={props.labelKey} hideLabel={props.hideLabel}>
            {(pencilIcon) => {
                return props.readonly ? (
                    <>
                        <div className={fakeInputClasses} title={props.value ? props.value.toString() : ''}>
                            {selectedItem ? selectedItem.label : '-'}
                        </div>
                        {props.children}
                    </>
                ) : (
                        <Typeahead
                            id={props.id}
                            name={props.name}
                            disabled={props.disabled}
                            isInvalid={props.isInvalid}
                            data={props.data}
                            onItemSelected={props.onItemSelected}
                            placeholder={props.translator(props.labelKey)}
                            value={props.value}
                            onFocus={props.onFocus}
                            disableReset={true}
                        >
                            {props.children}
                            {pencilIcon}
                        </Typeahead>
                    );
            }}
        </PropertyInput>
    );
}

function ConstantsTypeaheadPropertyInputComp(props: IConstantsTypeaheadPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
    });

    return (
        <TranslatorContext.Consumer>
            {({ locale }) => {
                const backendLocale = localeToBackendLocale(locale);
                const selectedItem = getTypeaheadData(props.constantType, backendLocale).find((item) => (
                    item.value && item.value.toString()) === (props.value ? props.value.toString() : ''
                    ));
                return (
                    <PropertyInput labelKey={props.labelKey} hideLabel={props.hideLabel}>
                        {(pencilIcon) => {
                            return props.readonly ? (
                                <>
                                    <div className={fakeInputClasses} title={props.value ? props.value.toString() : ''}>
                                        {selectedItem ? selectedItem.label : '-'}
                                    </div>
                                    {props.children}
                                </>
                            ) : (
                                    <ConstantsTypeahead
                                        id={props.id}
                                        name={props.name}
                                        disabled={props.disabled}
                                        isInvalid={props.isInvalid}
                                        constantType={props.constantType}
                                        onItemSelected={props.onItemSelected}
                                        placeholder={props.translator(props.labelKey)}
                                        value={props.value}
                                        onFocus={props.onFocus}
                                        disableReset={true}
                                        resetSelectWhenValueNotFoundInData={props.resetSelectWhenValueNotFoundInData}
                                    >
                                        {props.children}
                                        {pencilIcon}
                                    </ConstantsTypeahead>
                                );
                        }}
                    </PropertyInput>
                );
            }}
        </TranslatorContext.Consumer>
    );
}

function ConstantsAdvancedPropertyInputComp(props: IConstantsAdvancedPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
    });

    const placeholder = (
        <span className="fake-input__placeholder">{props.translator(props.labelKey)}</span>
    );

    return (
        <TranslatorContext.Consumer>
            {({ locale }) => {
                const backendLocale = localeToBackendLocale(locale);
                const selectedItem = getTypeaheadData(props.constantType, backendLocale).find((item) => (
                    item.value && item.value.toString()) === (props.value ? props.value.toString() : ''
                    ));

                return (
                    <PropertyInput labelKey={props.labelKey} hideLabel={props.hideLabel}>
                        <div
                            className={fakeInputClasses}
                            onClick={props.readonly ? undefined : props.onClick}
                        >
                            {(selectedItem && selectedItem.label) || (!props.readonly && placeholder)}
                        </div>
                        {props.children}
                    </PropertyInput>
                );
            }}
        </TranslatorContext.Consumer>
    );
}

function AsyncTypeaheadPropertyInputComp(props: IAsyncTypeaheadPropertyInputProps & IContextProps) {
    const fakeInputClasses = classNames('fake-input', {
        ['read-only']: !!props.readonly,
    });

    const selectedItem = props.data.find((item) => (
        item.value && item.value.toString()) === (props.value ? props.value.toString() : ''
        ));

    return (
        <PropertyInput labelKey={props.labelKey} hideLabel={props.hideLabel}>
            {(pencilIcon) => {
                return props.readonly ? (
                    <>
                        <div className={fakeInputClasses} title={props.value ? props.value.toString() : ''}>
                            {selectedItem ? selectedItem.label : '-'}
                        </div>
                        {props.children}
                    </>
                ) : (
                        <AsyncTypeahead
                            id={props.id}
                            name={props.name}
                            disabled={props.disabled}
                            isInvalid={props.isInvalid}
                            data={props.data}
                            asyncInfoSelector={props.asyncInfoSelector}
                            onFilter={props.onFilter}
                            onItemSelected={props.onItemSelected}
                            placeholder={props.translator(props.labelKey)}
                            value={props.value}
                            onFocus={props.onFocus}
                            disableReset={true}
                        >
                            {props.children}
                            {pencilIcon}
                        </AsyncTypeahead>
                    );
            }}
        </PropertyInput>
    );
}

function PropertyInput(
    {
        labelKey,
        children,
        hideLabel,
        tooltipTranslationKey,
    }: Pick<ICommonPropertyInputProps,
        'children'
        | 'labelKey'
        | 'hideLabel'
        | 'tooltipTranslationKey'
        >,
) {
    const pencilIcon = <Icon typeName="pencil" />;
    const infoIcon = <Icon
        typeName="info"
        circle
    />;

    const inputWrapperClasses = classNames('input-wrapper', {
        ['hide-label']: hideLabel,
    });

    const childrenTypedAsFunction = children as ((pencilIcon: ReactNode) => ReactNode);

    return (
        <div className="PropertyInput">
            {!hideLabel &&
                <div className="label-wrapper">
                    {!!tooltipTranslationKey
                    ?
                    <TooltipWithIcon
                        icon={infoIcon}
                        tooltipBubbleIcon={infoIcon}
                        iconSize="small"
                        typeName="info-bubble"
                        label={<Translate msg={labelKey} />}
                    >
                        <Translate msg={tooltipTranslationKey} />
                    </TooltipWithIcon>
                    :
                    <label><Translate msg={labelKey} /></label>
                    }
                    <span>:</span>
                </div>
            }
            <div className={inputWrapperClasses}>
                {typeof children === 'function' ? childrenTypedAsFunction(pencilIcon) : (
                    <>
                        {children}
                        {pencilIcon}
                    </>
                )}
            </div>
        </div>
    );
}

export const TextPropertyInput = (props: ITextPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <TextPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
export const AdvancedPropertyInput = (props: IAdvancedPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <AdvancedPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
export const TypeaheadPropertyInput = (props: ITypeaheadPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <TypeaheadPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
export const AsyncTypeaheadPropertyInput = (props: IAsyncTypeaheadPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <AsyncTypeaheadPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
export const ConstantsTypeaheadPropertyInput = (props: IConstantsTypeaheadPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <ConstantsTypeaheadPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
export const ConstantsAdvancedPropertyInput = (props: IConstantsAdvancedPropertyInputProps) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <ConstantsAdvancedPropertyInputComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);
