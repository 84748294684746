import React from 'react';

import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../../routeKeys';
import {
    getPlannedCompanyVisitsAsyncInfo, getPlannedCompanyVisits,
} from '../../../../redux/company/visits/selectors';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';

import { COLUMNS, BASE_NAME } from './planned.const';
import {
    getDefaultQueryParams,
    shouldRenderShowAllButton,
    transformFilterValuesToActiveFilters,
    toListId,
    mapListRowForExport,
    mapCompanyVisitsToListItems,
} from './planned.helper';
import {
    PlannedCompanyVisitsList,
} from './PlannedCompanyVisitsList/PlannedCompanyVisitsList.component';
import {
    PlannedCompanyVisitsFilterContent,
} from './PlannedCompanyVisitsFilterContent/PlannedCompanyVisitsFilterContent.component';
import {
    PlannedCompanyVisitsSearchContent,
} from './PlannedCompanyVisitsSearchContent/PlannedCompanyVisitsSearchContent.component';
import {
    TPlannedCompanyVisitsListProps,
} from './PlannedCompanyVisitsList/PlannedCompanyVisitsList.type';
import {
    TPlannedCompanyVisitsFilterContentProps,
} from './PlannedCompanyVisitsFilterContent/PlannedCompanyVisitsFilterContent.type';
import {
    TPlannedCompanyVisitsSearchContentProps,
} from './PlannedCompanyVisitsSearchContent/PlannedCompanyVisitsSearchContent.type';

export default function PlannedCompanyVisits() {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
                asyncInfoSelector: getPlannedCompanyVisitsAsyncInfo,
                dataSelector: getPlannedCompanyVisits,
                transformData: mapCompanyVisitsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: TPlannedCompanyVisitsListProps) =>
                    <PlannedCompanyVisitsList {...renderProps} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: TPlannedCompanyVisitsSearchContentProps) =>
                    <PlannedCompanyVisitsSearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: TPlannedCompanyVisitsFilterContentProps) =>
                        <PlannedCompanyVisitsFilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'planned-company-visits',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}
