import React from 'react';

import {
    DraggablePrefix,
    DroppablePrefix,
} from '../../../../../models/interventions/timeslots';
import { IExaminationReason } from '../../../../../models/interventions/medicalExaminations';
import { TLegendItem } from '../../../../common/widget/Legend';
import Translate from '../../../../common/Translate';

import { TRANSLATION_PREFIX } from './PlanBufferzone.const';
import { stringComparerAscending } from '../../../../../utils/list/comparerUtils';

export const isEmployeeDroppedIntoFreeTimeslot = (
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) => {
    return draggablePrefix === DraggablePrefix.Employee && droppablePrefix === DroppablePrefix.FreeTimeslot;
};

export const isPlannedExaminationDroppedIntoFreeTimeslot = (
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) => {
    return draggablePrefix === DraggablePrefix.PlannedExamination && droppablePrefix === DroppablePrefix.FreeTimeslot;
};

export const isPlannedExaminationDroppedIntoEmployees = (
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) => {
    return draggablePrefix === DraggablePrefix.PlannedExamination && droppablePrefix === DroppablePrefix.Employees;
};

export const mapExaminationReasonsToLegendData = (
    reasons: IExaminationReason[],
    showFixedPlannedSlots: boolean,
    showUnplannableTimeSlots: boolean,
) => {
    const freeSlot: TLegendItem = {
        color: 'green',
        colorClassName: 'free-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.free_time_slot`} />,
    };
    const plannedSlot: TLegendItem = {
        color: '',
        colorClassName: 'planned-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.planned_time_slot`} />,
    };
    const fixedPlannedSlot: TLegendItem = {
        color: '',
        colorClassName: 'fixed-planned-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.fixed_planned_time_slot`} />,
    };
    const unplannableTimeSlot: TLegendItem = {
        color: '',
        colorClassName: 'unplannable-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.unplannable_time_slot`}/>,
    };

    const legendData = [freeSlot, plannedSlot];

    if (showFixedPlannedSlots) {
        legendData.push(fixedPlannedSlot);
    }

    if (showUnplannableTimeSlots) {
        legendData.push(unplannableTimeSlot);
    }

    const uniqueExaminationReasons = reasons
        .sort((reasonA, reasonB) => stringComparerAscending(reasonA.title, reasonB.title))
        .map((reason): TLegendItem => ({
            color: reason.colour,
            label: reason.title,
        }));

    return [...legendData, ...uniqueExaminationReasons];
};
