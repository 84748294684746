import { IReducerState, reducerKey } from './reducer';
import {
    getReducerState,
    IState,
    makeAsyncFetchInfoSelector,
    makeAsyncDoInfoSelector,
    NO_RERENDER,
} from '../../index';
import { getCompanySeatsForSelectedCompany } from '../selected/selectors';
import filterUserAccountSeatsByCompanySeats from './helpers/filterUserAccountSeatsByCompanySeats';
import { AddUserAccountWizardStep } from '../../../models/user/userAccount';
import { getRoutePayload } from '../../location/selectors';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Users

const getFetchCompanyUsersAsyncField = (state: IState) =>
    reducerState(state).companyUsers;

export const getFetchCompanyUsersAsyncInfo = makeAsyncFetchInfoSelector(getFetchCompanyUsersAsyncField);

export const isCompanyUsersDataAvailable = (state: IState) =>
    Array.isArray(getFetchCompanyUsersAsyncField(state).data);

export const getCompanyUsers = (state: IState) =>
    getFetchCompanyUsersAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// User details

const getFetchCompanyUserDetailsAsyncField = (state: IState) =>
    reducerState(state).selectedCompanyUserDetails;

export const getFetchCompanyUserDetailsAsyncInfo = makeAsyncFetchInfoSelector(getFetchCompanyUserDetailsAsyncField);

export const getSelectedCompanyUserDetails = (state: IState) => {
    const data = getFetchCompanyUserDetailsAsyncField(state).data;
    if (data && Array.isArray(data.seats)) {
        const companySeats = getCompanySeatsForSelectedCompany(state);
        return {
            ...data,
            seats: filterUserAccountSeatsByCompanySeats(companySeats, data.seats),
        };
    }
    return data;
};

// Update user

const getUpdateCompanyUserDetailsAsyncField = (state: IState) => reducerState(state).updateCompanyUserDetails;

export const getUpdateCompanyUserDetailsAsyncInfo = makeAsyncDoInfoSelector(getUpdateCompanyUserDetailsAsyncField);

// Add user

export const getAddlUserWizardStepId = (state: IState) =>
    getRoutePayload<{ stepId: AddUserAccountWizardStep }>(state).stepId;

export const getAddUserWizardData = (state: IState) => reducerState(state).userAccountWizardData;

const getAddCompanyUserAsyncField = (state: IState) => reducerState(state).addCompanyUser;
export const getAddCompanyUserAsyncInfo = makeAsyncDoInfoSelector(getAddCompanyUserAsyncField);

// Remove user

const getRemoveCompanyUserAsyncField = (state: IState) => reducerState(state).removeCompanyUser;
export const getRemoveCompanyUserAsyncInfo = makeAsyncDoInfoSelector(getRemoveCompanyUserAsyncField);
