import React from 'react';
import ListActionButton from '../../../common/buttons/ListActionButton';
import { connect } from '../../..';
import { IAgendaExportData } from '../../../../models/planning/agenda';
import { getAgendaExportData } from '../../../../redux/planning/selectors';
import exportListDataToCsv from '../../../../utils/file/csv/exportListDataToCsv';
import { getQueryParams } from '../../../../redux/location/selectors';

const CLASS_NAME = 'AgendaExportButton';

interface IPrivateProps {
    agendaData: IAgendaExportData[];
    startDate: string;
    endDate: string;
}

function AgendaExportButton({ agendaData, startDate, endDate }: IPrivateProps) {
    function exportAgendaData() {
        exportListDataToCsv({
            baseFilename: `MyMensura-calendar-export${(startDate && endDate) ? `-${startDate}-${endDate}` : ''}`,
            listData: agendaData,
        });
    }

    return (
        <div className={CLASS_NAME}>
            <ListActionButton
                id="agenda-export-button"
                type="text"
                iconTypeName="excel"
                translationKey="common.master_with_detail.action.export"
                onClick={exportAgendaData}
            />
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const { startDate, endDate } = getQueryParams<{ startDate: string; endDate: string }>(state);
        return {
            agendaData: getAgendaExportData(state),
            startDate,
            endDate,
        };
    },
})(AgendaExportButton);
