import { ITraceableApiError } from '../../../models/general/error';

import generateErrorId from './generateErrorId';

export function createServerUnavailableError(type: 'api' | 'bff' = 'api'): ITraceableApiError {
    return {
        id: generateErrorId(),
        code: -1,
        message: 'klantenzone.error.rest.server_error',
        extraData: {},
        requestMethod: null,
        wasCancelled: false,
        type,
    };
}
