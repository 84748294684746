import React, { Component } from 'react';
import './onboarding-contacts.scss';
import { connect } from '../../../../index';
import classNames from 'classnames';
import { IOnboardingWizardEntity } from '../../../../../models/onboarding/wizard';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { getOnboardingWizardEntity } from '../../../../../redux/onboarding/selectors';
import ContactsList, { IColumnNamesWhenMainSeat } from '../../../../administration/CompanyInfo/shared/ContactsList';
import ListFooter from '../../../../common/list/ListFooter';
import {
    getCompanyContacts, getCompanyContactsAsyncInfo,
    getUpdateContactAsyncInfo,
    getAddContactAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { ICompanyContact } from '../../../../../models/admin/companyInfo';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import SlideOutPanel from '../../../../common/widget/SlideOutPanel';
import { DetailHeader, DetailContent } from '../../../../administration/CompanyInfo/Contacts/detail';
import Loader from '../../../../common/waiting/Loader';
import { ListItem } from '../../../../../models/general/list';
import { formatPersonNameFormal } from '../../../../../utils/formatting/formatPerson';
import Dialog from '../../../../common/modals/Dialog';
import AddContact from '../../../../administration/CompanyInfo/Contacts/AddContact';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../../models/general/error';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Button from '../../../../common/buttons/Button';

interface IPrivateProps {
    wizardEntity: Partial<IOnboardingWizardEntity>;
    contacts: ICompanyContact[];
    fetchAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    addAsyncInfo: IAsyncFieldInfo;
    updateContactAsyncInfo: IAsyncFieldInfo;
}

interface IComponentState {
    selectedCustomerContactId: number;
    isAddContactDialogOpen: boolean;
}

const CLASS_NAME = 'OnboardingContacts';
const LIST_NAME = 'onboarding-contacts';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.contacts';

class Contacts extends Component<IStepperStepRenderProps & IPrivateProps, IComponentState> {

    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedCustomerContactId: null,
            isAddContactDialogOpen: false,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
        this.onCloseSlideOutPanel = this.onCloseSlideOutPanel.bind(this);

        this.onOpenAddContact = this.onOpenAddContact.bind(this);
        this.onCloseAddContact = this.onCloseAddContact.bind(this);
        this.onContactAdded = this.onContactAdded.bind(this);
        this.renderAddContactButton = this.renderAddContactButton.bind(this);
    }

    public render() {
        const { renderStepButtons, goToNextStep, contacts, fetchAsyncInfo, wizardEntity } = this.props;
        const { selectedCustomerContactId, isAddContactDialogOpen } = this.state;

        const selectedContact = contacts.find((contact) => contact.customerContactId === selectedCustomerContactId);

        return (
            <div
                className={classNames(
                    'container',
                    CLASS_NAME,
                )}
            >
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                    textPlaceholders={{
                        button: this.renderTextButton(),
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <ContactsList
                        name={LIST_NAME}
                        selectedItemId={selectedContact && selectedContact.id}
                        onItemSelected={this.onItemSelected}
                        masterData={mapContactsToListItems(contacts)}
                        masterAsyncInfo={fetchAsyncInfo}
                        nrRecordsToShow={200}
                        canItemBeSelected={true}
                        isMainSeat={true}
                        filterValues={{
                            isShowAll: true,
                        }}
                        footer={
                            <ListFooter
                                left={this.renderAddContactButton()}
                                right={renderStepButtons({
                                    nextButton: {
                                        onClick: goToNextStep,
                                    },
                                })}
                            />
                        }
                    />
                    <Loader show={fetchAsyncInfo.status} />
                    <SlideOutPanel
                        className={`${CLASS_NAME}__slide-out-panel`}
                        isOpen={!!selectedContact}
                        onCloseIntent={this.onCloseSlideOutPanel}
                    >
                        {this.renderDetailContent()}
                    </SlideOutPanel>
                    <Dialog
                        show={isAddContactDialogOpen}
                        onCloseIntent={this.onCloseAddContact}
                    >
                        <AddContact
                            isMainSeat={true}
                            fixedCompanyCode={wizardEntity.baseData.companyCode}
                            onClose={this.onCloseAddContact}
                            onSucces={this.onContactAdded}
                        />
                    </Dialog>
                </div>
            </div>
        );
    }

    private renderDetailContent() {
        const { selectedCustomerContactId } = this.state;
        const { fetchAsyncInfo, updateContactAsyncInfo, contacts } = this.props;

        const selectedContact = contacts.find((contact) => contact.customerContactId === selectedCustomerContactId);

        return (
            <div className={`${CLASS_NAME}__detail`}>
                <DetailHeader
                    detailData={selectedContact}
                    detailAsyncInfo={fetchAsyncInfo}
                />
                <DetailContent
                    detailData={selectedContact}
                    detailAsyncInfo={fetchAsyncInfo}
                    detailUpdateAsyncInfo={updateContactAsyncInfo}
                    onCloseIntent={this.onCloseSlideOutPanel}
                />
            </div>
        );
    }

    private onCloseSlideOutPanel(isClickedOutside: boolean) {
        if (!isClickedOutside) {
            this.setState({ selectedCustomerContactId: null });
        }
    }

    private onItemSelected(id: number) {
        this.setState({
            selectedCustomerContactId: id,
        });
    }

    private onOpenAddContact() {
        this.setState({
            isAddContactDialogOpen: true,
        });
    }

    private onCloseAddContact() {
        const { addAsyncInfo, clearError } = this.props;

        this.setState({
            isAddContactDialogOpen: false,
        });

        clearError(addAsyncInfo.error);
    }

    private onContactAdded() {
        this.onCloseAddContact();
    }

    private renderAddContactButton() {
        const {
            fetchAsyncInfo,
        } = this.props;

        return (
            <ShowIfAllowed requiredAccessLevels={{ company: 'W' }}>
                <Button
                    id="add-contact"
                    typeName="text"
                    className="AddButton"
                    disabled={fetchAsyncInfo.status === AsyncStatus.Busy}
                    onClick={this.onOpenAddContact}
                >
                    <Icon typeName="plus" circle />
                    <span>
                        {/*eslint-disable-next-line max-len*/}
                        <Translate msg="administration.company_info.contacts.actions.add_contact" />
                    </span>
                </Button>
            </ShowIfAllowed>
        );
    }

    private renderTextButton() {
        return (
            <a
                id="onboarding-wizard-add-contact-header-button"
                className={`${CLASS_NAME}__add-contact-title`}
                onClick={this.onOpenAddContact}
            >
                <Icon typeName="plus-circle" />
                <span><Translate msg={`${TRANSLATION_PREFIX}.text_button`} /></span>
            </a>
        );
    }
}

function mapContactsToListItems(masterData: ICompanyContact[]): ListItem<IColumnNamesWhenMainSeat>[] {
    return masterData
        .map((contact) => {
            return {
                id: contact.customerContactId,
                columns: {
                    type: contact.type,
                    employee: formatPersonNameFormal(contact),
                    phone: contact.phone,
                    email: contact.email,
                    language: contact.language,
                    actions: null,
                },
            };
        },
        );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getOnboardingWizardEntity(state);
        const contacts = getCompanyContacts(state);

        return {
            wizardEntity,
            contacts,
            fetchAsyncInfo: getCompanyContactsAsyncInfo(state),
            updateContactAsyncInfo: getUpdateContactAsyncInfo(state),
            addAsyncInfo: getAddContactAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(Contacts);
