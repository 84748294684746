declare let dataLayer: any[];

/**
 * GTM = Google Tag Manager
 */

export interface IEventCustomData {
    [key: string]: any;
}

// TODO sendEvent of snipsonian should use the sendEventBase
// TODO dito for sendVirtualPageview etc.

export function sendEventBase({
    event = 'event',
    customData = {},
}: {
    event?: string;
    customData: IEventCustomData;
}) {
    dataLayer.push({
        event,
        ...customData,
    });
}

/**
 * Use for session- or user-scoped custom dimensions.
 * Hit-scoped custom dimensions should mostly be sent
 * together with other events.
 */
export function sendCustomDimension({
    key,
    value,
}: {
    key: string;
    value: string;
}) {
    dataLayer.push({
        [key]: value,
    });
}
