import { ENVS } from './envs.config';

export const RELEASE_BUILD = (window.klantenzone && window.klantenzone.BUILD_INFO)
    ? window.klantenzone.BUILD_INFO.version
    : 'unknown_release';

export const SENTRY_DSN = 'https://9c9b7906f1d843039d8113cadefa3667@sentry.io/229240';

export const SENTRY_ENVIRONMENT = getSentryEnvironmentForEnv();

const ENVS_NOT_TO_LOG = (window.klantenzone && ENVS)
    ? [
        ENVS.LOCAL.toString(),
    ]
    : [];

export const SHOULD_REPORT_CURRENT_ENV = shouldReportCurrentEnv();

export type TErrorReportLogLevel = 'critical' | 'error' | 'warning' | 'info' | 'debug';

function getSentryEnvironmentForEnv() {
    if (window.klantenzone && window.klantenzone.ENV) {
        switch (window.klantenzone.ENV) {
            case ENVS.LOCAL:
                return 'local';
            case ENVS.DEV:
                return 'development';
            case ENVS.VAL:
                return 'validation';
            case ENVS.TST:
                return 'test';
            case ENVS.PROD:
                return 'production';
        }
    }

    return 'other';
}

function shouldReportCurrentEnv(): boolean {
    if (!window.klantenzone || !window.klantenzone.ENV) {
        return false;
    }

    return ENVS_NOT_TO_LOG.indexOf(window.klantenzone.ENV) === -1;
}
