import { get } from '../../../../utils/api/requestWrapper';
import {
    ICompanyVisitDocument,
    IFetchCompanyVisitDocumentsPayload,
} from '../../../../models/interventions/company-visits';

import { URL } from '../company-visits.const';

export function fetchCompanyVisitDocuments(
    { companyCode, visitId }: IFetchCompanyVisitDocumentsPayload,
) {
    return get<ICompanyVisitDocument[]>({
        url: URL.COMPANY_VISIT_DOCUMENTS,
        pathParams: {
            companyCode,
            visitId,
        },
        mapResponse: (response) => response.documents,
    });
}
