import React, { PureComponent } from 'react';
import { IFormRenderProps } from '../Form';
import Translate from '../../Translate';
import { ShapeOf } from '../../../../models/ui/form';
import Checkbox from '../../input/Checkbox';
import { camelCaseToSnakeCase } from '../../../../utils/formatting/formatTranslationKey';

interface IFormCheckboxProps<FormValues> {
    name: keyof FormValues;
    fields: ShapeOf<FormValues>;
    formRenderProps: IFormRenderProps<FormValues>;
    baseTranslationKey?: string;
    boldOnChecked?: boolean;
    size?: 'small';
    disabled?: boolean;
}

class FormCheckbox<FormValues extends object>
    extends PureComponent<IFormCheckboxProps<FormValues>> {

    public render() {
        const {
            formRenderProps, fields, name, baseTranslationKey, boldOnChecked, size, disabled,
        } = this.props;
        const { values, handleChange } = formRenderProps;

        const fullTranslationKey = `${baseTranslationKey}.${camelCaseToSnakeCase(name as unknown as string)}`;

        return (
                <Checkbox
                    name={fields[name]}
                    checked={values[name as unknown as string]}
                    onChange={handleChange}
                    boldOnChecked={boldOnChecked}
                    size={size}
                    disabled={disabled}
                >
                    {baseTranslationKey && <Translate msg={fullTranslationKey} />}
                </Checkbox>
        );
    }
}

export function createFormCheckbox<FormValues extends object>() {
    return FormCheckbox as any as new () => FormCheckbox<FormValues>;
}
