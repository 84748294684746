import React, { Component } from 'react';
import { connect } from '../../../index';
import './work-post-card-properties.scss';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { IStepperStepRenderProps } from '../../../../models/general/stepper';
import PageHeader from '../../../appShell/PageHeader';
import classNames from 'classnames';
import StickyFooter from '../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../common/navigation/Wizard/index';
import { getWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/selectors';
import { IWorkPostCardWizardEntity, IWorkPostProperties } from '../../../../models/documentCenter/workPostCards';
import Translate from '../../../common/Translate';
import { schema, fields } from './propertiesSchema';
import { updateWorkPostCardWizardEntity } from '../../../../redux/documentCenter/workPostCards/actions';
import { getCompanyFunctionIscoAsyncInfo, getCompanyFunctionIsco } from '../../../../redux/company/functions/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { ICompanyFunctionIsco } from '../../../../models/admin/companyFunctions';
import TinyLoader from '../../../common/waiting/TinyLoader';
import { createFormTextInput } from '../../../common/forms/FormTextInput';

export type FormValues = Pick<IWorkPostProperties,
    'forbiddenForYoungPeople' | 'localization' | 'qualification' | 'usedWorkTools'>;

interface IPrivateProps {
    wizardEntity: Partial<IWorkPostCardWizardEntity>;
    updateWizardEntity: (values: FormValues) => void;
    companyFunctionIscoAsyncInfo: IAsyncFieldInfo;
    companyFunctionIsco: ICompanyFunctionIsco[];
}

const FORM_NAME = 'work-post-card-properties';
const BASE_KEY = 'document_center.work_post_card_wizard.steps.properties';
const CLASS_NAME = 'WorkPostCardProperties';
const BASE_FIELD_TRANSLATION_KEY = `${BASE_KEY}.form.placeholder`;

const PropertiesFormTextInput = createFormTextInput<FormValues>();

class Properties extends Component<IStepperStepRenderProps & IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            renderStepButtons, wizardEntity, goToNextStep, updateWizardEntity,
            companyFunctionIsco,
        } = this.props;

        const INITIAL_VALUES: FormValues = {
            forbiddenForYoungPeople: wizardEntity.workPostProperties &&
                wizardEntity.workPostProperties.forbiddenForYoungPeople || '',
            localization: wizardEntity.workPostProperties &&
                wizardEntity.workPostProperties.localization || '',
            qualification: wizardEntity.workPostProperties &&
                wizardEntity.workPostProperties.qualification || '',
            usedWorkTools: wizardEntity.workPostProperties &&
                wizardEntity.workPostProperties.usedWorkTools || '',
        };

        function handleSubmit(values: FormValues) {
            updateWizardEntity(values);
            goToNextStep();
        }

        return (
            <>
                <PageHeader
                    title={`${BASE_KEY}.title`}
                    ignoreTranslationContext
                />
                <div className={classNames('container', CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT_WIDE)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={handleSubmit}
                        initialValues={INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            return (
                                <>
                                    <div className={WIZARDFLOW_CLASSES.WIDE_FORM}>
                                        <div className={`${CLASS_NAME}__subsection_isco`}>
                                            <p><Translate msg={`${BASE_KEY}.form.placeholder.job_function`} /></p>
                                            <TinyLoader asyncInfoSelector={getCompanyFunctionIscoAsyncInfo}>
                                                {companyFunctionIsco.length > 0 ?
                                                    companyFunctionIsco.map((item) => {
                                                        return (
                                                            <span key={item.code}>
                                                                {`${item.code} ${item.description}`}
                                                            </span>
                                                        );
                                                    }) :
                                                    <p>
                                                        <Translate
                                                            msg="administration.company_function.isco.no_results"
                                                        />
                                                    </p>
                                                }
                                            </TinyLoader>
                                        </div>
                                        <PropertiesFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'qualification'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <PropertiesFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'localization'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <PropertiesFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'usedWorkTools'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                        <PropertiesFormTextInput
                                            baseId={FORM_NAME}
                                            fields={fields}
                                            formRenderProps={formRenderProps}
                                            name={'forbiddenForYoungPeople'}
                                            baseTranslationKey={BASE_FIELD_TRANSLATION_KEY}
                                        />
                                    </div>
                                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                                        {renderStepButtons({
                                            nextButton: {
                                                isSubmit: true,
                                                formName: FORM_NAME,
                                            },
                                        })}
                                    </StickyFooter>
                                </>
                            );
                        }}
                    />
                </div>
            </>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getWorkPostCardWizardEntity(state);
        return {
            wizardEntity,
            companyFunctionIscoAsyncInfo: getCompanyFunctionIscoAsyncInfo(state),
            companyFunctionIsco: getCompanyFunctionIsco(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardEntity: (values: FormValues) => {
                const wizardEntity = getWorkPostCardWizardEntity(getState());
                dispatch(updateWorkPostCardWizardEntity({
                    workPostProperties: {
                        ...wizardEntity.workPostProperties,
                        forbiddenForYoungPeople: values.forbiddenForYoungPeople,
                        localization: values.localization,
                        qualification: values.qualification,
                        usedWorkTools: values.usedWorkTools,
                    },
                }));
            },
        };
    },
})(Properties);
