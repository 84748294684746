import { ValidationErrors } from '../../models/general/error';

export default function getMultiLeveledErrors({
    errors,
    fields,
    parent,
}: {
    errors: ValidationErrors<{}>,
    fields: { [key: string]: string },
    parent: string,
}) {
    return Object.keys(fields).reduce(
        (accumulator, field) => {
            const key = `${parent}.${field}`;

            if (errors[key]) {
                accumulator[field] = errors[key];
            }

            return accumulator;
        },
        [],
    );
}
