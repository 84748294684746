import React, { PureComponent } from 'react';
import { connect } from '../../index';
import './seat-selection.scss';
import Translate from '../../common/Translate';
import {
    setSelectedCompany, setSelectedCompanySeat,
} from '../../../redux/company/selected/actions';
import { getRoutePayload, getQueryParams, getLocationState } from '../../../redux/location/selectors';
import { getCompanies } from '../../../redux/company/companies/selectors';
import {
    getSelectedCompanySeat,
    getSelectedCompany,
    getCompanySeatsForSelectedCompany,
    getFetchSelectedCompanySeatsAndDivisionsAsyncInfo,
} from '../../../redux/company/selected/selectors';
import Button from '../../common/buttons/Button';
import { redirectToUrl, navigateTo } from '../../../redux/location/actions';
import {
    ICompanyDismissableWithDate,
    ICompanyCodePayload,
    ICompanySeat,
    ISelectCompanySeatPayload,
} from '../../../models/admin/company';
import { getMyCompanies, hasPermission } from '../../../redux/auth/selectors';
import { Permission } from '../../../models/auth/authorisation';
import GoBack from '../../common/navigation/GoBack';
import ROUTE_KEYS from '../../../routeKeys';
import PageHeader from '../../appShell/PageHeader';
import SeatsList from '../SeatsList';
import { AsyncStatus, IAsyncFieldInfo, ILocationAction } from '../../../models/general/redux';
import ListFooter from '../../common/list/ListFooter';
import { IPrevLocation } from '../../../redux/location/reducer';

interface IPrivateProps {
    isSelectedCompanyAlreadyInState: boolean;
    selectedCompany: ICompanyDismissableWithDate;
    companySeats: ICompanySeat[];
    fetchAsyncInfo: IAsyncFieldInfo;
    previousSelectedCompanySeat: ISelectCompanySeatPayload;
    searchFilter: string;
    companySeatToSelectAndRedirectImmediatelly: ICompanySeat;
    canSelectAllCompanySeats: boolean;
    selectCompany: (company: ICompanyDismissableWithDate) => void;
    selectCompanySeat: (seat: ICompanySeat, allSeatsSelected?: boolean) => void;
    redirectToOriginalUrl: () => void;
    setSearchFilterUrlQueryParams: (searchFilter: string) => void;
    showGoBackToCompanySelection: boolean;
    prevLocation: IPrevLocation;
}

interface IState {
    isAllSeatsSelected: boolean;
    selectedCompanySeat: ICompanySeat;
}

const LIST_NAME = 'company-seats';
const CLASS_NAME = 'SeatsSelection';

class SeatsSelection extends PureComponent<IPrivateProps, IState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedCompanySeat: props.previousSelectedCompanySeat.companySeat,
            isAllSeatsSelected: props.previousSelectedCompanySeat.isAllSeatsSelected,
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
    }

    public render() {
        const { selectedCompanySeat, isAllSeatsSelected } = this.state;
        const {
            fetchAsyncInfo,
            searchFilter,
            canSelectAllCompanySeats, showGoBackToCompanySelection,
            companySeats, setSearchFilterUrlQueryParams,
            prevLocation,
        } = this.props;

        const companySelectionQueryFilter =
            prevLocation &&
            prevLocation.type === ROUTE_KEYS.R_COMPANY_SELECTION ?
            prevLocation.query : {};

        return (
            <div className={`${CLASS_NAME} container`}>
                <PageHeader
                    title="account.seats_selection.title"
                />
                <div className={`${CLASS_NAME}__content`}>
                    <SeatsList
                        name={LIST_NAME}
                        selectedSeatCompanyCode={selectedCompanySeat && selectedCompanySeat.company.companyCode}
                        isAllSeatsSelected={isAllSeatsSelected}
                        onSeatSelected={this.onItemSelected}
                        withSelectAll={canSelectAllCompanySeats}
                        initialSearchValue={searchFilter}
                        companySeats={companySeats}
                        fetchAsyncInfo={fetchAsyncInfo}
                        onSearchInput={setSearchFilterUrlQueryParams}
                        footer={(
                            <ListFooter
                                right={(
                                    <>
                                        {showGoBackToCompanySelection &&
                                            <GoBack
                                                id="go-back-to-company-selection-link"
                                                to={{
                                                    type: ROUTE_KEYS.R_COMPANY_SELECTION,
                                                    meta: {
                                                        query: companySelectionQueryFilter,
                                                    },
                                                    payload: {},
                                                } as ILocationAction<object>}
                                            />
                                        }
                                        <Button
                                            id="seats-selection-next-button"
                                            typeName="secondary"
                                            // eslint-disable-next-line max-len
                                            disabled={fetchAsyncInfo.status === AsyncStatus.Busy || !selectedCompanySeat}
                                            onClick={this.goToNextPage}
                                        >
                                            <Translate msg="account.seats_selection.next" />
                                        </Button>
                                    </>
                                )}
                            />
                        )}
                    />
                </div>
            </div>
        );
    }

    public componentDidMount() {
        /**
         * Update the selected company code in the global state
         * This is not always done in the company selection component
         * Reason is because the company selection is skipped when there is only one company
         */
        const { selectCompany, selectedCompany, isSelectedCompanyAlreadyInState } = this.props;
        if (selectedCompany && !isSelectedCompanyAlreadyInState) {
            selectCompany(selectedCompany);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPrivateProps) {
        const { selectedCompany, selectCompanySeat, redirectToOriginalUrl } = this.props;

        if (nextProps.companySeatToSelectAndRedirectImmediatelly) {
            selectCompanySeat(nextProps.companySeatToSelectAndRedirectImmediatelly);
            return redirectToOriginalUrl();
        }

        // Could be that the companies are loaded async (eg for a super user)
        if (
            nextProps.selectedCompany &&
            (!selectedCompany || selectedCompany.companyCode !== nextProps.selectedCompany.companyCode)
        ) {
            nextProps.selectCompany(nextProps.selectedCompany);
        }
    }

    private onItemSelected(companyCode: string, isAllSeatsSelected: boolean = false) {
        const { companySeats } = this.props;
        this.setState({
            isAllSeatsSelected,
            selectedCompanySeat: companySeats.find((seat) => seat.company.companyCode === companyCode),
        });
    }

    private goToNextPage() {
        const { selectCompanySeat, redirectToOriginalUrl } = this.props;
        const { selectedCompanySeat, isAllSeatsSelected } = this.state;
        selectCompanySeat(selectedCompanySeat, isAllSeatsSelected);
        redirectToOriginalUrl();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const canAccessAllCompanies = hasPermission(state, Permission.CAN_ACCESS_ALL_COMPANIES);
        const canSelectAllSeats = hasPermission(state, Permission.CAN_SELECT_ALL_SEATS);
        const { companyCode } = getRoutePayload<ICompanyCodePayload>(state);
        const companies = canAccessAllCompanies
            ? mergeCompanyLists(getCompanies(state), getMyCompanies(state))
            : getMyCompanies(state);
        const companySeats = getCompanySeatsForSelectedCompany(state);
        const { searchFilter } = getQueryParams<{ searchFilter: string; }>(state);
        const fetchAsyncInfo = getFetchSelectedCompanySeatsAndDivisionsAsyncInfo(state);
        const companySeatToSelect = fetchAsyncInfo.status !== AsyncStatus.Busy && companySeats.length === 1
            ? companySeats[0] : null;
        const selectedCompanyInState = getSelectedCompany(state);

        return {
            isSelectedCompanyAlreadyInState: !!selectedCompanyInState
                && (selectedCompanyInState.companyCode === companyCode),
            selectedCompany: companies.find((company) => company.companyCode === companyCode),
            companySeats,
            fetchAsyncInfo,
            previousSelectedCompanySeat: getSelectedCompanySeat(state),
            searchFilter,
            // Redirect immediatelly if there is only one company seat available
            companySeatToSelectAndRedirectImmediatelly: companySeatToSelect,
            canSelectAllCompanySeats: canSelectAllSeats,
            showGoBackToCompanySelection: canAccessAllCompanies || companies.length > 1,
            prevLocation: getLocationState(state).prev,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            selectCompany: (company: ICompanyDismissableWithDate) => {
                dispatch(setSelectedCompany(company));
            },
            selectCompanySeat: (seat: ICompanySeat, allSeatsSelected: boolean = null) => {
                const canSelectAllSeats = hasPermission(getState(), Permission.CAN_SELECT_ALL_SEATS);
                dispatch(setSelectedCompanySeat({
                    isAllSeatsSelected: allSeatsSelected !== null ? allSeatsSelected : canSelectAllSeats,
                    companySeat: seat,
                }));
            },
            setSearchFilterUrlQueryParams: (searchFilter: string) => {
                const state = getState();
                const { companyCode } = getRoutePayload<ICompanyCodePayload>(state);
                const currentQuery = getQueryParams(state);
                dispatch(navigateTo(
                    ROUTE_KEYS.R_SEATS_SELECTION,
                    {
                        companyCode,
                        /**
                         * Computed properties on object initializer are not supported on IE11
                         * See MENSKLNTZN-3469 : IE11 - TypeError: Object doesn't support this action
                         *
                         * Currently no solution found that would let the ts-loader also convert this syntax
                         * to have it work in IE11, so removed the problematic syntax.
                         */
                        // [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                        shouldRefreshData: false,
                    },
                    {
                        ...currentQuery,
                        searchFilter,
                    },
                ));
            },
            redirectToOriginalUrl: () => {
                const state = getState();
                const { redirectUrl } = getQueryParams<{ redirectUrl: string }>(state);
                dispatch(redirectToUrl(redirectUrl));
            },
        };
    },
})(SeatsSelection);

function mergeCompanyLists(
    companies: ICompanyDismissableWithDate[] = [],
    myCompanies: ICompanyDismissableWithDate[] = [],
): ICompanyDismissableWithDate[] {
    const myCompanyCodes = myCompanies.map((item) => item.companyCode);
    return [
        ...(companies || [])
            .filter((item) => !myCompanyCodes.includes(item.companyCode)),
        ...myCompanies,
    ];
}
