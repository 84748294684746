import React, { PureComponent } from 'react';
import { createSelector } from 'reselect';
import { clone, pathOr } from 'ramda';

import { AsyncStatus } from '../../../../../../../../models/general/redux';
import { formatDateForBackend } from '../../../../../../../../utils/formatting/formatDate';
import {
    getBufferzonesWithinPlanningCalendarRangeMemoized,
    getPlanMedicalExaminationWizardType,
    getReservedMedicalExaminationsAsyncInfo,
} from '../../../../../../../../redux/medicalExamination/selectors';
import { getCompanyMedicalCenters } from '../../../../../../../../redux/company/info/selectors';
import { getDate } from '../../../../../../../../utils/core/date/getSpecificDate';
import { getLocale } from '../../../../../../../../redux/i18n/selectors';
import { getSelectedEmployee } from '../../../../../../../../redux/employee/info/selectors';
import {
    IPlanMedicalExaminationBaseEntity,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../../../../../../models/interventions/medicalExaminations';
import {
    IReservedMedicalExamination,
} from '../../../../../../../../models/interventions/medicalExaminations/reserved';
import { ListColumns, ListItem } from '../../../../../../../../models/general/list';
import { REACT_LIST_DEFAULT_ITEM_SIZE } from '../../../../../../../../config/dom.config';
import getDaysInMonthView from '../../../../../../../../utils/libs/flatpickr/getDaysInMonthView';

import { connect } from '../../../../../../../index';
import Button from '../../../../../../../common/buttons/Button';
import Icon from '../../../../../../../common/icons/Icon';
import ListItemActions from '../../../../../../../common/list/ListItemActions';
import ListWithSorting from '../../../../../../../common/list/ListWithSorting';
import Loader from '../../../../../../../common/waiting/Loader';
import Translate from '../../../../../../../common/Translate';

import {
    IBufferzoneDialogProps,
    IColumnNames,
    IPrivateProps,
} from './reserved.type';
import {
    getBufferzoneId,
    mapBufferzonesToListItems,
    triggerBufferzoneWizard,
    updateWizardData,
} from './reserved.helper';
import {
    CLASS_NAME,
    COLUMNS,
    INITIAL_SORT,
    TRANSLATION_PREFIX,
} from './reserved.const';

import './reserved.scss';

class Reserved extends PureComponent<IBufferzoneDialogProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    constructor(props: IBufferzoneDialogProps & IPrivateProps) {
        super(props);

        this.renderActions = this.renderActions.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
    }

    public render() {
        const { onClose, fetchBufferzonesAsyncInfo, listItems } = this.props;

        this.columns.actions.render = this.renderActions;

        return (
            <div className={CLASS_NAME}>
                <Loader show={fetchBufferzonesAsyncInfo.status === AsyncStatus.Busy} />
                <p>
                    <Translate
                        msg={`${TRANSLATION_PREFIX}.info`}
                        placeholders={{
                            link: <a onClick={onClose}>
                                <Translate msg={`${TRANSLATION_PREFIX}.info_link`} />
                            </a>,
                        }}
                    />
                </p>
                <ListWithSorting
                    columns={this.columns}
                    items={listItems}
                    name="bufferzone-reserved-list"
                    initialSort={INITIAL_SORT}
                    onItemRowClicked={this.onLocationSelected}
                    itemSizeGetter={this.itemSizeGetter}
                />
            </div>
        );
    }

    private itemSizeGetter(index: number) {
        return REACT_LIST_DEFAULT_ITEM_SIZE;
    }

    private onLocationSelected(id: string) {
        const {
            bufferzones,
            locale,
            updateWizardData,
            onClose,
            companyMedicalCenters,
        } = this.props;

        const selectedBufferzone = bufferzones.find(
            (bufferzone) => getBufferzoneId(bufferzone) === id,
        );

        if (!selectedBufferzone) {
            return;
        }

        const { activity: { start }, location } = selectedBufferzone;

        const mainMedicalCenterCode = pathOr<string>(null, ['mainCenter', 'code'], location);

        const selectedMedicalCenter = companyMedicalCenters.find(companyMedicalCenter => {
            if (mainMedicalCenterCode) {
                return companyMedicalCenter.code === mainMedicalCenterCode;
            }

            return companyMedicalCenter.code === location.code;
        });

        const selectedMedicalCenterCode = pathOr(null, ['code'], selectedMedicalCenter);
        const medicalCenterCode = pathOr(null, ['code'], location);

        const dateObj = typeof start === 'string' ? getDate(start) : start;
        const daysInMonthView = getDaysInMonthView({
            month: dateObj.getMonth(), year: dateObj.getFullYear(), locale,
        });

        // KZUAT-1790 & KZUAT-1763
        // Disabled going to bufferzone wizard because of production crash for non 'administrator' users,
        // We need to fix this later, commented this logic for now
        // if (isPeriodicManualPlanningWizard) {
        //     triggerBufferzoneWizard(selectedBufferzone);
        // } else {
        //     updateWizardData({
        //         selectTime: {
        //             filter: {
        //                 selectedMedicalCenterId: medicalCenter.id,
        //                 selectedDate: date,
        //                 dateRangeToFetch: {
        //                     start: formatDateForBackend(daysInMonthView[0]),
        //                     end: formatDateForBackend(daysInMonthView[daysInMonthView.length - 1]),
        //                 },
        //             },
        //         },
        //     });
        //     onClose();
        // }

        updateWizardData({
            selectTime: {
                filter: {
                    selectedMedicalCenterCode: selectedMedicalCenterCode || medicalCenterCode,
                    selectedDate: formatDateForBackend(start),
                    dateRangeToFetch: {
                        start: formatDateForBackend(daysInMonthView[0]),
                        end: formatDateForBackend(daysInMonthView[daysInMonthView.length - 1]),
                    },
                },
            },
        });

        onClose();
    }

    private renderActions(listItem: ListItem<IColumnNames>) {
        return (
            <ListItemActions className={`${CLASS_NAME}__action`}>
                <Button id="select-location-button">
                    <span><Translate msg={`${TRANSLATION_PREFIX}.actions.select_location`} /></span>
                    <Icon circle typeName="calendar" />
                </Button>
            </ListItemActions>
        );
    }
}

export default connect<IPrivateProps>({
    statePropsPerInstance: (state) => {
        const bufferzonesListItemsMemoizedSelector = createSelector(
            getBufferzonesWithinPlanningCalendarRangeMemoized,
            (bufferzones) => mapBufferzonesToListItems(bufferzones),
        );

        return (innerState) => ({
            isPeriodicManualPlanningWizard: getPlanMedicalExaminationWizardType(state)
                === PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
            fetchBufferzonesAsyncInfo: getReservedMedicalExaminationsAsyncInfo(innerState),
            listItems: bufferzonesListItemsMemoizedSelector(innerState),
            bufferzones: getBufferzonesWithinPlanningCalendarRangeMemoized(innerState),
            locale: getLocale(innerState),
            selectedEmployee: getSelectedEmployee(innerState),
            companyMedicalCenters: getCompanyMedicalCenters(innerState),
        });
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values: Partial<IPlanMedicalExaminationBaseEntity>) => {
                return updateWizardData(values, getState(), dispatch);
            },
            triggerBufferzoneWizard: (bufferzone: IReservedMedicalExamination) => {
                return triggerBufferzoneWizard(bufferzone, getState(), dispatch);
            },
        };
    },
})(Reserved);
