import isSet from '@snipsonian/core/es/is/isSet';
import { doesRouteBelongToGroup, getRoutes, getRoutesAsList } from '../../routes';
import urlToLocationAction from '../../utils/routing/urlToLocationAction';
import { redirect as redirectWrapper } from 'redux-first-router';
import ROUTE_KEYS from '../../routeKeys';
import { ILocationState } from '../allReducers';
import { IState } from '../';
import { ILocationAction } from '../../models/general/redux';
import { Permission } from '../../models/auth/authorisation';
import { IUserProfile } from '../../models/auth/authentication';
import locationActionToUrl from '../../utils/routing/locationActionToUrl';
import { NOT_FOUND_ROUTE_KEY } from '../../utils/routing/typings';
import { IAddEmployeeWizardPayload } from '../../models/admin/employee';
import { createLocationAction } from '../../utils/libs/redux/createAction';
import { ICompanySeat } from '../../models/admin/company';
import {
    setSelectedCompanySeat,
    setSelectedCompany,
    fetchSelectedCompanySeatsAndDivisionsActions,
} from '../company/selected/actions';
import { Action } from 'redux';
import { getAddEmployeeWizardSteps } from '../../config/navigation/wizardStepsMap';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { getQueryParams, getRouteKey, getRoutePayload } from './selectors';

const ROUTE_KEYS_WHICH_CANNOT_BE_USED_FOR_A_REDIRECT = [
    ROUTE_KEYS.R_COMPANY_SELECTION,
    ROUTE_KEYS.R_SEATS_SELECTION,
    ROUTE_KEYS.R_UNEXPECTED_ERROR,
    ROUTE_KEYS.R_NOT_FOUND,
];

let routeKeysNotAllowedForRedirect: string[] = null;

export function redirectToUrl(redirectUrl: string) {
    if (isValidRedirectUrl(redirectUrl)) {
        const action = urlToLocationAction(redirectUrl, getRoutes());
        return redirectWrapper(action);
    }
    // If no redirect is defined we go to the home
    return redirectToHome();
}

export function redirectToRoute(
    redirectRoute: ROUTE_KEYS,
    payload: object = {},
    query?: {},
) {
    const action = createLocationAction(redirectRoute, payload, query || {});
    return redirectWrapper(action) as ILocationAction<{}>;
}

interface IRedirectAfterLoginOptions {
    hasSelectedACompany: boolean;
    hasSelectedASeat: boolean;
    areCompanySeatsAvailable: boolean;
    companySeats: ICompanySeat[];
    userProfile: IUserProfile;
    redirectUrl?: string;
}

export function redirectOrFetchSeatsAfterSuccessfulLogin({
    hasSelectedACompany,
    hasSelectedASeat,
    areCompanySeatsAvailable,
    companySeats,
    userProfile,
    redirectUrl,
}: IRedirectAfterLoginOptions): Action[] {
    const redirectAction = redirectToUrl(redirectUrl);
    const canAccessAllCompanies = userProfile && userProfile.accessLevel &&
        userProfile.accessLevel.extraPermissions.includes(Permission.CAN_ACCESS_ALL_COMPANIES);
    const canSelectAllSeats = userProfile && userProfile.accessLevel &&
        userProfile.accessLevel.extraPermissions.includes(Permission.CAN_SELECT_ALL_SEATS);

    if (hasSelectedACompany && hasSelectedASeat) {
        return [redirectAction];
    }

    if (!hasSelectedACompany) {
        // Users with access to all companies always navigate to the company selection page
        if (canAccessAllCompanies) {
            return [navigateToCompanySelection(redirectUrl)];
        }
        // Not an admin but has many companies
        if (userProfile && Array.isArray(userProfile.companies) && userProfile.companies.length > 1) {
            return [navigateToCompanySelection(redirectUrl)];
        }
    }

    if (!hasSelectedACompany && (userProfile && userProfile.companies.length === 1)) {
        const company = userProfile.companies[0];
        const setSelectedCompanyAction = setSelectedCompany(company);
        if (!areCompanySeatsAvailable) {
            return [
                setSelectedCompanyAction,
                fetchSelectedCompanySeatsAndDivisionsActions.trigger({}),
            ];
        }

        // Go to the seats selection if the user has one company and many seats
        if (companySeats.length > 0) {
            return [
                setSelectedCompanyAction,
                navigateToSeatsSelection(company.companyCode, redirectUrl),
            ];
        }

        return [
            setSelectedCompanyAction,
            setSelectedCompanySeat({
                isAllSeatsSelected: canSelectAllSeats,
                companySeat: companySeats[0],
            }),
            redirectAction,
        ];
    }

    return [redirectAction];
}

export function redirectToHome() {
    return redirectToRoute(ROUTE_KEYS.R_HOME);
}

export function redirectToLogin(locationState: ILocationState) {
    const query = getRedirectUrlQuery(locationState);
    const addRedirectUrl = canBeUsedAsRedirectUrl(query.redirectUrl);
    return redirectToRoute(ROUTE_KEYS.R_LOGIN, {}, addRedirectUrl ? query : null);
}

export function redirectToLogout({
    isSessionExpired = false,
    locationState,
}: {
    isSessionExpired?: boolean
    locationState: ILocationState,
}) {
    const query = getRedirectUrlQuery(locationState);
    const addRedirectUrl = canBeUsedAsRedirectUrl(query.redirectUrl);
    return redirectToRoute(ROUTE_KEYS.R_LOGOUT, { isSessionExpired }, addRedirectUrl ? query : null);
}

export function redirectToNotFound() {
    return redirectToRoute(ROUTE_KEYS.R_NO_ACCES);
}

export function redirectToUnexpectedError() {
    return redirectToRoute(ROUTE_KEYS.R_UNEXPECTED_ERROR);
}

export function navigateToCompanySelection(redirectUrl?: string) {
    const addRedirectUrl = canBeUsedAsRedirectUrl(redirectUrl);
    return navigateTo(ROUTE_KEYS.R_COMPANY_SELECTION, {}, {
        redirectUrl: addRedirectUrl ? redirectUrl : undefined,
    });
}

export function navigateToSeatsSelection(companyCode: string, redirectUrl?: string) {
    const addRedirectUrl = canBeUsedAsRedirectUrl(redirectUrl);
    return navigateTo(ROUTE_KEYS.R_SEATS_SELECTION, { companyCode }, {
        redirectUrl: addRedirectUrl ? redirectUrl : undefined,
    });
}

export function navigateToAddEmployee() {
    return navigateTo(
        ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD,
        { step: getAddEmployeeWizardSteps().firstStepId } as IAddEmployeeWizardPayload,
    );
}

export function navigateTo<Payload = {}, Query = {}>(
    routeKey: ROUTE_KEYS,
    payload?: Payload,
    query?: Query,
) {
    return createLocationAction(routeKey, payload || {}, query || {});
}

function getRedirectUrlQuery(locationState: ILocationState): { redirectUrl: string } {
    let queryParams;
    if (locationState) {
        const { type, payload, query, pathname } = locationState;
        if (type === NOT_FOUND_ROUTE_KEY) {
            return { redirectUrl: pathname };
        }
        queryParams = { redirectUrl: locationActionToUrl({ type, query, payload }, getRoutes()) };
    }
    return queryParams;
}

function isValidRedirectUrl(redirectUrl) {
    return isSet(redirectUrl) && (redirectUrl.length > 0) && (redirectUrl !== '/');
}

function canBeUsedAsRedirectUrl(redirectUrl: string) {
    if (!isValidRedirectUrl(redirectUrl)) {
        return false;
    }

    const redirectAction = urlToLocationAction(redirectUrl, getRoutes());

    if (getRouteKeysNotAllowedForRedirectUrl().includes(redirectAction.type)) {
        return false;
    }

    return true;
}

function getRouteKeysNotAllowedForRedirectUrl() {
    if (!routeKeysNotAllowedForRedirect) {
        /**
         * (see KZUAT-188)
         * Don't allow a wizard page as a redirectUrl, so that you will not be redirected to that page after
         * a re-login following an expired session for example.
         */
        const wizardRouteKeys = getRoutesAsList()
            .filter((routeWrapper) => doesRouteBelongToGroup({
                routeKey: routeWrapper.key,
                group: ROUTE_GROUP.IS_PART_OF_WIZARD,
            }))
            .map((routeWrapper) => routeWrapper.key);

        routeKeysNotAllowedForRedirect = [
            ...ROUTE_KEYS_WHICH_CANNOT_BE_USED_FOR_A_REDIRECT,
            ...wizardRouteKeys,
        ];
    }

    return routeKeysNotAllowedForRedirect;
}

export function getCurrentRouteWithExtraQueryParam(state: IState, queryParams: {}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query = getQueryParams(state) as { [key: string]: any };

    // Make sure we don't keep going to logout route as it will clear the state then
    const routeKey = getRouteKey(state) === ROUTE_KEYS.R_LOGOUT ? ROUTE_KEYS.R_LOGIN : getRouteKey(state);

    return navigateTo(
        routeKey,
        getRoutePayload(state),
        {
            ...query,
            ...queryParams,
        },
    );
}

export function getCurrentRouteWithoutQueryParams(state: IState, queryParams: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query = getQueryParams(state) as { [key: string]: any };
    queryParams.forEach((param) => {
        delete query[param];
    });

    return navigateTo(
        getRouteKey(state),
        getRoutePayload(state),
        query,
    );
}
