import { get, IResponseType } from '../../utils/api/requestWrapper';
import { ICompanyCodePayload } from '../../models/admin/company';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';

interface IDownloadCompanyDocumentByUrlPayload extends ICompanyCodePayload {
    url: string;
    defaultFilename: string;
}

export default function downloadCompanyDocumentByUrl({
    companyCode,
    url,
    defaultFilename,
}: IDownloadCompanyDocumentByUrlPayload) {
    return get<IDocument>({
        url,
        responseType: IResponseType.blob,
        pathParams: {
            companyCode,
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });
}
