import { createTypeActions } from '../../utils/libs/redux/createAction';
import {
    IFetchAccomplishmentsContributionsOfYearPayload,
    IAccomplishmentsContributionsOfYear,
    IFetchAccomplishmentsContributionDetailsPayload,
    IAccomplishmentsContributionDetail,
    IPreventionUnitTask,
    IExportPreventionUnitsPayload,
    ICompanySituationHistorySuccessPayload,
    IPreventionUnitsResponse,
} from '../../models/admin/preventionUnits';
import {
    FETCH_COMPANY_SITUATION_HISTORY,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
    FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
    FETCH_PREVENTION_UNITS,
    FETCH_PREVENTION_UNITS_DETAIL,
    EXPORT_PREVENTION_UNITS,
} from './types';
import { IDocument } from '../../models/general/documents';
import presentDownloadedFile from '../../utils/file/presentDownloadedFile';

// Company situation history

export const fetchCompanySituationHistoryActions = createTypeActions<
    {
        companyCode?: string;
    },
    ICompanySituationHistorySuccessPayload
>({
    type: FETCH_COMPANY_SITUATION_HISTORY,
});

// Accomplishments Contributions of year (Flat fee)

export const fetchAccomplishmentsContributionsOfYearActions = createTypeActions<
    IFetchAccomplishmentsContributionsOfYearPayload,
    IAccomplishmentsContributionsOfYear
>({
    type: FETCH_ACCOMPLISHMENTS_CONTRIBUTIONS_OF_YEAR,
});

// Accomplishments Contribution details (Flat fee)

export const fetchAccomplishmentsContributionDetailsActions = createTypeActions<
    IFetchAccomplishmentsContributionDetailsPayload,
    IAccomplishmentsContributionDetail[]
>({
    type: FETCH_ACCOMPLISHMENTS_CONTRIBUTION_DETAILS,
});

export function toAccomplishmentsContributionDetailId({ year, type }: { year: number, type: string }) {
    return `${year}-${type}`;
}

export function fromAccomplishmentsContributionDetailId(yearAndType: string) {
    const [year, type] = yearAndType.split('-', 2);
    return {
        year: Number(year),
        type,
    };
}

// Prevention units

export const fetchPreventionUnitsActions = createTypeActions<{}, IPreventionUnitsResponse>({
    type: FETCH_PREVENTION_UNITS,
});

export const fetchPreventionUnitsDetailActions = createTypeActions<{}, IPreventionUnitTask[]>({
    type: FETCH_PREVENTION_UNITS_DETAIL,
});

export const exportPreventionUnitsActions = createTypeActions<IExportPreventionUnitsPayload, IDocument>({
    type: EXPORT_PREVENTION_UNITS,
    onSuccess: (payload) => {
        presentDownloadedFile(payload);
    },
});
