import React, { Component } from 'react';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import DatePicker from '../../../../../common/widget/DateTimePicker/DatePicker';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import { getEmployeeToAdd } from '../../../../../../redux/employee/wizard/selectors';
import { updateAddEmployeeData } from '../../../../../../redux/employee/wizard/actions';
import { IEmployeeToAdd } from '../../../../../../models/admin/employee';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';

interface IPrivateProps {
    currentDateInFunction: string;
    updateEmployeeToAdd: (values: Pick<IEmployeeToAdd, 'dateInFunction' |  'dateInService'>) => void;
    employeeToAdd: IEmployeeToAdd;
}

interface IState {
    date: string;
}

class StartDate extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.currentDateInFunction,
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
    }

    public render() {
        const { renderStepButtons, employeeToAdd } = this.props;
        const { date } = this.state;
        return (
            <>
                <PageHeader
                    title="administration.employees.add.steps.startdate.title"
                    titlePlaceholders={{ name: formatPersonName(employeeToAdd) }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <DatePicker
                        id="employee-add-start-date"
                        onChange={this.onChangeHandler}
                        value={date}
                        inlineCalendar={true}
                        hideTextInput={true}
                    />
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                disabled: !date,
                                onClick: this.onClickNextHandler,
                            },
                        })}
                    </StickyFooter>
                </div>
            </>
        );
    }

    public onChangeHandler(formattedDate: string) {
        this.setState({
            date: formattedDate,
        });
    }

    private onClickNextHandler() {
        const { updateEmployeeToAdd, goToNextStep } = this.props;
        const date = this.state.date;
        updateEmployeeToAdd({ dateInFunction: date, dateInService: date });
        goToNextStep();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);

        return {
            employeeToAdd,
            currentDateInFunction: employeeToAdd && employeeToAdd.dateInFunction,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateEmployeeToAdd: (values: Pick<IEmployeeToAdd, 'dateInFunction' |  'dateInService'>) => {
                dispatch(updateAddEmployeeData({ ...values }));
            },
        };
    },
})(StartDate);
