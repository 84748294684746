import dayjs, { ConfigType as TDateToFormat, Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

// '[' indicates inclusion, '(' indicates exclusion
// https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#isbetween
type TInclusivity = '[]' | '[)' | '(]' | '()';

interface IDayJsWithPlugins extends Dayjs {
    isBetween: (start: TDateToFormat, end: TDateToFormat, unit?: null, inclusivity?: TInclusivity) => boolean;
}

export function isDateBetween(date: TDateToFormat, start: TDateToFormat, end: TDateToFormat) {
    dayjs.extend(isBetween);

    return (dayjs(date) as IDayJsWithPlugins)
        .isBetween(
            dayjs(start),
            dayjs(end),
        );
}

export function isDateBetweenInclusive(
    date: TDateToFormat,
    start: TDateToFormat,
    end: TDateToFormat,
    {
        startInclusive = true,
        endInclusive = true,
    }: {
        startInclusive?: boolean;
        endInclusive?: boolean;
    } = {},
) {
    dayjs.extend(isBetween);

    return (dayjs(date) as IDayJsWithPlugins)
        .isBetween(
            dayjs(start),
            dayjs(end),
            null,
            toInclusivity(startInclusive, endInclusive),
        );
}

function toInclusivity(startInclusive: boolean, endInclusive: boolean): TInclusivity {
    return [
        startInclusive ? '[' : '(',
        endInclusive ? ']' : ')',
    ].join('') as TInclusivity;
}

export function isDateBeforeCurrentDate(date: TDateToFormat) {
    return dayjs(date).isBefore(dayjs());
}

export function isDateBeforeGivenDate(date: TDateToFormat, givenDate: TDateToFormat) {
    return dayjs(date).isBefore(dayjs(givenDate));
}
