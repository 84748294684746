export const LOGIN = 'LOGIN';

export const UPDATE_AUTH0_SESSION = 'UPDATE_AUTH0_SESSION';

export const LOGOUT = 'LOGOUT';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const FETCH_MY_USER_INFO = 'FETCH_MY_USER_INFO';

export const UPDATE_MY_USER_INFO = 'UPDATE_MY_USER_INFO';

export const REMEMBER_ME = 'REMEMBER_ME';

export const RESET_REMEMBER_ME = 'RESET_REMEMBER_ME';

export const UPDATE_ACCESS_LEVEL = 'UPDATE_ACCESS_LEVEL';

export const ADD_EXTRA_PERMISSIONS_FOR_COMPANY = 'ADD_EXTRA_PERMISSIONS_FOR_COMPANY';

export const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const UPDATE_MY_AVATAR = 'UPDATE_MY_AVATAR';

export const UPDATE_SESSION_END_TIME = 'UPDATE_SESSION_END_TIME';
export const CLEAR_SESSION_END_TIME = 'CLEAR_SESSION_END_TIME';
export const CHECK_SESSION_END_TIME = 'CHECK_SESSION_END_TIME';
export const EXTEND_BACKEND_SESSION = 'EXTEND_BACKEND_SESSION';

export const FETCH_PERFORMANCE_DASHBOARD_ACCESS = 'FETCH_PERFORMANCE_DASHBOARD_ACCESS';
export const SET_SKIP_PERFORMANCE_DASHBOARD_WARNING = 'SET_SKIP_PERFORMANCE_DASHBOARD_WARNING';
export const SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL = 'SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL';
export const TOGGLE_PERFORMANCE_DASHBOARD_DIALOG = 'TOGGLE_PERFORMANCE_DASHBOARD_DIALOG';
export const NAVIGATE_TO_PERFORMANCE_DASHBOARD = 'NAVIGATE_TO_PERFORMANCE_DASHBOARD';
