import React from 'react';
import { path } from 'ramda';

import './finish-enrollment-dialog.scss';

import {
    fetchCourseTableActions,
    fetchSelectedPlannedCourseActions,
    resetCourseEnrollWizardEntity,
} from '../../../../../../redux/documentCenter/courses/actions';
import { getCourseEnrollWizardEntity } from '../../../../../../redux/documentCenter/courses/selectors';
import {
    getRouteThatTriggeredWizard,
    getWizardExitLinkActionMemoized,
} from '../../../../../../redux/ui/history/selectors';
import { getSelectedSeatCompanyCode, isAllSeatsSelected } from '../../../../../../redux/company/selected/selectors';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { connect } from '../../../../..';
import Dialog from '../../../../../common/modals/Dialog';
import Translate from '../../../../../common/Translate';
import Button from '../../../../../common/buttons/Button';

const CLASS_NAME = 'FinishEnrollmentDialog';

interface IFinishEnrollmentDialogProps {
    open: boolean;
    onClose: () => void;
}

interface IPrivateProps {
    navigateToEnrollAnother: () => void;
    navigateToFinishEnrollment: () => void;
}

function FinishEnrollmentDialog(props: IFinishEnrollmentDialogProps & IPrivateProps) {
    const { open, onClose, navigateToEnrollAnother, navigateToFinishEnrollment } = props;

    return (
        <Dialog
            show={open}
            onCloseIntent={onClose}
            disableClosing={true}
            type="success"
            header="document_center.courses.new.steps.finish_enrollment.title"
        >
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="finish-enrollment-start-over"
                        typeName="secondary"
                        onClick={navigateToEnrollAnother}
                    >
                        <Translate
                            msg="document_center.courses.new.steps.finish_enrollment.actions.start_over"
                        />
                    </Button>
                    <Button
                        id="finish-enrollment-ready"
                        typeName="secondary"
                        onClick={navigateToFinishEnrollment}
                    >
                        <Translate
                            msg="document_center.courses.new.steps.finish_enrollment.actions.ready"
                        />
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    dispatchProps: (dispatch, getState) => {
        return {
            navigateToEnrollAnother: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_OVERVIEW));
                dispatch(resetCourseEnrollWizardEntity());
            },
            navigateToFinishEnrollment: () => {
                const state = getState();

                const wizardEntity = getCourseEnrollWizardEntity(state);
                const routeThatTriggeredWizard = getRouteThatTriggeredWizard(state);
                const routeThatTriggeredWizardType = path<string>(['type'], routeThatTriggeredWizard);

                dispatch(getWizardExitLinkActionMemoized(state));

                // Refresh data before redirecting to page that triggered the course enrollment wizard
                if (routeThatTriggeredWizardType && typeof routeThatTriggeredWizardType === 'string') {

                    // Wizard was triggered from course detail page (course overview)
                    if (routeThatTriggeredWizardType === ROUTE_KEYS.R_COURSES_DETAIL_COURSE) {
                        // Get nodeId and childNodeId from url params.
                        const nodeId = path<number>(['payload', 'nodeId'], routeThatTriggeredWizard);
                        const childNodeId = path<number>(['payload', 'childNodeId'], routeThatTriggeredWizard);

                        if (nodeId && typeof nodeId === 'number' && childNodeId && typeof childNodeId === 'number') {
                            dispatch(fetchCourseTableActions.trigger({
                                childNodeId,
                            }));
                        }
                    }

                    // Wizard was triggered from planned course detail (planned courses overview)
                    if (routeThatTriggeredWizardType === ROUTE_KEYS.R_COURSES_PLANNED_DETAIL) {
                        // Get coursesOrganizedId from wizard entity data
                        const coursesOrganizedId = path<number>(['session', 'coursesOrganizedId'], wizardEntity);

                        const companyCode = getSelectedSeatCompanyCode(state);
                        const showFullFamily = isAllSeatsSelected(state);

                        if (coursesOrganizedId && typeof coursesOrganizedId === 'number') {
                            dispatch(fetchSelectedPlannedCourseActions.trigger({
                                companyCode,
                                showFullFamily,
                                coursesOrganizedId,
                            }));
                        }
                    }
                }

                // Clear the wizard data
                dispatch(resetCourseEnrollWizardEntity());
            },
        };
    },
})(FinishEnrollmentDialog);
