import { connectRoutes, Options } from 'redux-first-router';
import createHistory from 'history/createBrowserHistory';
import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { reducerKey as locationReducerKey } from './location/reducer';
import { getVirtualPageOfCurrentRoute, getRouteKey } from './location/selectors';
import ALL_ROUTES from '../views/allRoutes';
import { getRoute } from '../routes';
import getRoutesMap from '../utils/routing/getRoutesMap';
import {
    logCompanyCode,
    logCompanyStatisticalCode,
    logUserLocale,
    logVirtualPageView,
} from '../utils/logging/analytics/eventLogger';
import registerProvidedReducer from '../utils/libs/redux/registerProvidedReducer';
import queryString from 'query-string';
import { IState } from './index';
import { IRoute } from '../utils/routing/typings';
import { getCachedAsyncPageComponent } from '../views/appShell/BodySwitcher';
import { ILocationAction } from '../models/general/redux';
import { getLocale } from './i18n/selectors';
import { getSelectedCompanyCode } from './company/selected/selectors';
import { getLatestStatisticalCode } from './preventionUnits/companySituationHistoryUtils';
import { getCompanySituationHistory } from './preventionUnits/selectors';
import { isLoggedIn } from './auth/selectors';

let isInitialRoute: boolean = true;

export default function configureReduxFirstRouter() {
    const routerOptions: Options = {
        onAfterChange: (dispatch, getState: () => IState) => {
            const state = getState();

            if (isInitialRoute) {
                /**
                 * We set the initial custom dimensions 'before', so that they are set
                 * and will already be sent together with the first logged virtual page.
                 * They can already have a proper value for example in case of a page refresh.
                 */
                logInitialCustomDimensions(state);
                isInitialRoute = false;
            }

            const currentRouteInfo = getRoute({ routeKey: getRouteKey(state) });

            logVirtualPageView({
                virtualPage: getVirtualPageOfCurrentRoute(state),
                extraData: (currentRouteInfo && currentRouteInfo.extraDataToLog)
                    ? currentRouteInfo.extraDataToLog(state)
                    : null,
            });
        },
        onBeforeChange: (dispatch, getState: () => IState, bag) => {
            // Only required when running unit tests as jsdom has no implementation for the window.scrollTo method
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            const { type: nextRouteKey, payload } = bag.action as ILocationAction<{}>;
            if (payload.disableScrollToTop) {
                return;
            }
            const state = getState();
            const routeKey = getRouteKey(state);
            const routeInfo = getRoute({ routeKey });
            const nextRouteInfo = getRoute({ routeKey: nextRouteKey });
            if (routeInfo && nextRouteInfo) {
                const pageComponent = getPageComponent(routeInfo);
                const nextPageComponent = getPageComponent(nextRouteInfo);
                if (pageComponent !== nextPageComponent) {
                    window.scrollTo(0, 0);
                }
            }
        },
        initialDispatch: true,
        querySerializer: queryString,
        createHistory,
    };

    const {
        reducer,
        middleware,
        enhancer,
    } = connectRoutes(getRoutesMap(ALL_ROUTES), routerOptions);

    registerProvidedReducer({
        reducer,
        key: locationReducerKey,
        reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
    });

    return {
        middleware,
        enhancer,
    };
}

function getPageComponent(routeInfo: IRoute) {
    const { component, key } = routeInfo.page;
    const cachedComponent = getCachedAsyncPageComponent(key);
    return cachedComponent || component;
}

function logInitialCustomDimensions(state: IState) {
    const locale = getLocale(state);
    logUserLocale({
        locale,
    });

    if (isLoggedIn(state)) {
        const companyCode = getSelectedCompanyCode(state);
        if (companyCode) {
            logCompanyCode({ companyCode });
        }

        const companyStatisticalCode = getLatestStatisticalCode(
            getCompanySituationHistory(state),
        );
        if (companyStatisticalCode) {
            logCompanyStatisticalCode({ companyStatisticalCode });
        }

        // TODO courseId
    }
}
