import React, { PureComponent } from 'react';
import './message-center-detail.scss';
import MessageAttachment from './MessageAttachment';
import MessageBody from './MessageBody';
import connect from '../../../../utils/libs/redux/connect';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { IUserMessageDetails, IUserMessageWithMessageType } from '../../../../models/user/inbox';
import { deleteUserMessageActions, markMessageAsReadActions } from '../../../../redux/inbox/actions';
import {
    getDeleteUserMessageAsyncInfo,
    getUserMessageDetailsById,
    getUserMessagesWithMessageType,
    getFetchUserMessageDetailsAsyncInfo,
    getMarkMessageAsReadAsyncInfo,
} from '../../../../redux/inbox/selectors';
import { getRoutePayload } from '../../../../redux/location/selectors';
import Button from '../../../common/buttons/Button';
import { getMessageTypes } from '../../../../config/constants';
import Icon from '../../../common/icons/Icon';
import ConfirmationDialog from '../../../common/modals/ConfirmationDialog';
import Translate from '../../../common/Translate';
import { IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import { isToday, isYesterDay } from '../../../../utils/core/date/isToday';
import { formatDateInFullFormat } from '../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';
import TinyLoader from '../../../common/waiting/TinyLoader';
import ErrorDialog from '../../../common/modals/ErrorDialog';
import { clearErrors } from '../../../../utils/libs/redux/generic/actions';
import { ITraceableApiError } from '../../../../models/general/error';

interface IPrivateProps {
    messageId: string;
    userMessageDetails: IUserMessageDetails;
    userMessagesWithMessageType: IUserMessageWithMessageType[];
    deleteMessageAsyncInfo: IAsyncFieldInfo;
    markMessageAsUnread: (id: string) => void;
    deleteMessage: (id: string) => void;
    resetDeleteMessage: () => void;
    fetchUserMessageDetailsAsyncInfo: IAsyncFieldInfo;
    markAsReadAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
}

interface IComponentState {
    isConfirmDeleteDialogOpen: boolean;
}

const CLASS_NAME = 'MessageCenterDetail';

class MessageCenterDetail extends PureComponent<
    IPrivateProps & IRenderDetailContentProps<IUserMessageDetails>,
    IComponentState
    > {
    constructor(props: IPrivateProps & IRenderDetailContentProps<IUserMessageDetails>) {
        super(props);

        this.state = {
            isConfirmDeleteDialogOpen: false,
        };

        this.onMarkAsUnread = this.onMarkAsUnread.bind(this);
        this.askDeleteConfirmation = this.askDeleteConfirmation.bind(this);
        this.closeConfirmDeleteDialog = this.closeConfirmDeleteDialog.bind(this);
        this.onDeleteMessage = this.onDeleteMessage.bind(this);
        this.onDeleteMessageSuccess = this.onDeleteMessageSuccess.bind(this);
        this.onCloseMessage = this.onCloseMessage.bind(this);
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<IPrivateProps & IRenderDetailContentProps<IUserMessageDetails>>) {
        if (this.props.deleteMessageAsyncInfo.status === AsyncStatus.Success
            && prevProps.deleteMessageAsyncInfo.status === AsyncStatus.Busy) {
            this.onDeleteMessageSuccess();
        }
    }

    public render() {
        const {
            userMessagesWithMessageType, userMessageDetails, messageId, deleteMessageAsyncInfo, markAsReadAsyncInfo,
            fetchUserMessageDetailsAsyncInfo,
        } = this.props;
        const { isConfirmDeleteDialogOpen } = this.state;

        const selectedUserMessageFromList = userMessagesWithMessageType.find((message) => message.id === messageId);
        const fullUserMessageDetails = { ...selectedUserMessageFromList, ...userMessageDetails };

        if (!selectedUserMessageFromList) {
            return null;
        }

        const disableActions = fetchUserMessageDetailsAsyncInfo.status === AsyncStatus.Busy ||
            fetchUserMessageDetailsAsyncInfo.status === AsyncStatus.Error;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__section ${CLASS_NAME}__section--top`}>
                    <div className={`${CLASS_NAME}__label`}>
                        <Translate msg="account.message_center.detail.columns.message" />
                    </div>
                    <div className={`${CLASS_NAME}__actions`}>
                        {fullUserMessageDetails.read && (
                            <Button
                                id="message-center-mark-as-unread-button"
                                key="message-center-mark-as-unread-button"
                                typeName="text"
                                onClick={this.onMarkAsUnread}
                                size="small"
                                disabled={disableActions}
                            >
                                <Icon typeName="message" />
                                <Translate msg="account.message_center.detail.actions.mark_as_unread" />
                            </Button>
                        )}

                        <Button
                            id="message-center-delete-message-button"
                            key="message-center-delete-message-button"
                            typeName="text"
                            onClick={this.askDeleteConfirmation}
                            size="small"
                            disabled={disableActions}
                        >
                            <Icon typeName="bin" />
                            <Translate msg="account.message_center.detail.actions.delete_message" />
                        </Button>
                        <ConfirmationDialog
                            show={isConfirmDeleteDialogOpen}
                            onCancel={this.closeConfirmDeleteDialog}
                            onConfirm={this.onDeleteMessage}
                            showLoader={deleteMessageAsyncInfo.status === AsyncStatus.Busy}
                        >
                            <>
                                <h3>
                                    <Translate msg="account.message_center.detail.delete.ask_confirmation" />
                                </h3>
                                {deleteMessageAsyncInfo.status === AsyncStatus.Error &&
                                    <>
                                        <Icon typeName="warning" colorType="warning" />
                                        <Translate msg="account.message_center.detail.delete.error" />
                                    </>
                                }
                            </>
                        </ConfirmationDialog>
                        <ErrorDialog
                            asyncInfo={markAsReadAsyncInfo}
                            hideRealErrorMessage={true}
                            key="mark-as-read-error"
                            onCloseDialog={this.onCloseErrorDialog}
                            titleTranslationKey="account.message_center.detail.mark_as_unread.error"
                        />
                    </div>
                </div>
                <div className={`${CLASS_NAME}__section`}>
                    <div className={`${CLASS_NAME}__details`}>
                        <Button
                            id="message-center-back"
                            className="back-btn"
                            onClick={this.onCloseMessage}
                        >
                            <Icon typeName="arrow-left" />
                        </Button>
                        <div className="date">
                            {isToday(fullUserMessageDetails.created)
                                ? <Translate msg="common.date.today" />
                                : isYesterDay(fullUserMessageDetails.created)
                                    ? <Translate msg="common.date.yesterday" />
                                    : <span>{formatDateInFullFormat(fullUserMessageDetails.created)}</span>
                            }, <span>{formatTimeOfDateForDisplay(fullUserMessageDetails.created)}</span>
                        </div>
                        <div className="name">
                            <strong>
                                <span>
                                    {fullUserMessageDetails.messageType &&
                                        fullUserMessageDetails.messageType.description}
                                </span>
                            </strong>
                        </div>
                        <div className="subject">{fullUserMessageDetails.subject}</div>
                        {fullUserMessageDetails.attachment &&
                            <MessageAttachment messageDetail={fullUserMessageDetails} />
                        }
                    </div>
                </div>
                <div className={`${CLASS_NAME}__section`}>
                    <div className={`${CLASS_NAME}__loader`}>
                        <TinyLoader asyncInfoSelector={getFetchUserMessageDetailsAsyncInfo} >
                            <MessageBody messageDetail={fullUserMessageDetails} />
                        </TinyLoader>
                    </div>
                </div>
            </div>
        );
    }

    private onMarkAsUnread() {
        this.props.markMessageAsUnread(this.props.messageId);
    }

    private askDeleteConfirmation() {
        this.setState({
            isConfirmDeleteDialogOpen: true,
        });
    }

    private closeConfirmDeleteDialog() {
        this.setState({
            isConfirmDeleteDialogOpen: false,
        });
    }

    private onDeleteMessage() {
        this.props.deleteMessage(this.props.messageId);
    }

    private onDeleteMessageSuccess() {
        this.props.resetDeleteMessage();
        this.closeConfirmDeleteDialog();
        this.onCloseMessage();
    }

    private onCloseMessage() {
        this.props.onCloseIntent(false);
    }

    private onCloseErrorDialog() {
        this.props.clearError(this.props.markAsReadAsyncInfo.error);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const messageId = getRoutePayload<{ id: string }>(state).id;

        return {
            messageId,
            userMessagesWithMessageType: getUserMessagesWithMessageType(state),
            userMessageDetails: getUserMessageDetailsById(state, messageId),
            messageTypes: getMessageTypes(),
            deleteMessageAsyncInfo: getDeleteUserMessageAsyncInfo(state),
            fetchUserMessageDetailsAsyncInfo: getFetchUserMessageDetailsAsyncInfo(state),
            markAsReadAsyncInfo: getMarkMessageAsReadAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            markMessageAsUnread: (id: string) => {
                dispatch(markMessageAsReadActions.trigger({
                    id,
                    read: false,
                }));
            },
            deleteMessage: (id: string) => {
                dispatch(deleteUserMessageActions.trigger({ id }));
            },
            resetDeleteMessage: () => {
                dispatch(deleteUserMessageActions.reset({}));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(MessageCenterDetail);
