import React, { ReactNode, FocusEvent, PureComponent } from 'react';
import { getTypeaheadData } from '../../../../config/constants';
import { ConstantType } from '../../../../models/general/constants';
import { getFetchConstantsAsyncInfo } from '../../../../redux/constants/selectors';
import Typeahead, { TTypeaheadData } from '../Typeahead';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import connect from '../../../../utils/libs/redux/connect';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { TBackendLocale } from '../../../../models/general/i18n';
import { localeToBackendLocale } from '../../../../utils/formatting/formatLocale';

interface IConstantsTypeaheadProps {
    id: string;
    constantType: ConstantType;
    value: string | number;
    name: string;
    onItemSelected: (value: number, label: string) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholder?: string;
    disabled?: boolean;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    disableReset?: boolean;
    resetSelectWhenValueNotFoundInData?: boolean;
}

interface IPrivateProps {
    asyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    backendLocale: TBackendLocale;
}

interface IComponentState {
    data: TTypeaheadData;
}

class BaseConstantsTypeahead extends
    PureComponent<IConstantsTypeaheadProps & IPrivateProps & IContextProps, IComponentState> {

    public static defaultProps: Partial<IConstantsTypeaheadProps> = {
        resetSelectWhenValueNotFoundInData: true,
    };

    private resetFieldValueTimeout: number;

    constructor(props: IConstantsTypeaheadProps & IPrivateProps & IContextProps) {
        super(props);
        this.state = {
            data: getTypeaheadData(props.constantType, props.backendLocale),
        };

        this.onItemSelectedHandler = this.onItemSelectedHandler.bind(this);
    }

    public render() {
        const {
            children,
            id,
            value,
            name,
            isInvalid,
            placeholder,
            disabled,
            onFocus,
            disableReset,
        } = this.props;

        return (
            <Typeahead
                id={id}
                value={value}
                name={name}
                onItemSelected={this.onItemSelectedHandler}
                isInvalid={isInvalid}
                data={this.state.data}
                placeholder={placeholder}
                asyncInfoSelector={getFetchConstantsAsyncInfo}
                disabled={disabled}
                onFocus={onFocus}
                disableReset={disableReset}
            >
                {children}
            </Typeahead>
        );
    }

    public componentDidMount() {
        this.setValueToNullWhenItemNotInListOfConstants();
    }

    public componentDidUpdate(prevProps: IConstantsTypeaheadProps & IPrivateProps, prevState: IComponentState) {
        if (
            prevProps.constantType !== this.props.constantType ||
            (
                prevProps.asyncInfo.status === AsyncStatus.Busy &&
                this.props.asyncInfo.status === AsyncStatus.Success
            )
        ) {
            this.setState({
                data: getTypeaheadData(this.props.constantType, this.props.backendLocale),
            });
        }
        if (this.state.data !== prevState.data) {
            this.setValueToNullWhenItemNotInListOfConstants();
        }
    }

    public componentWillUnmount() {
        if (this.resetFieldValueTimeout) {
            clearTimeout(this.resetFieldValueTimeout);
        }
    }

    private onItemSelectedHandler(value: number) {
        const { onItemSelected } = this.props;
        const { data } = this.state;
        const dataItem = data.find(item => item.value === value);
        onItemSelected(value, dataItem && dataItem.label);
    }

    private setValueToNullWhenItemNotInListOfConstants() {
        const { asyncInfo, value, onItemSelected, resetSelectWhenValueNotFoundInData } = this.props;
        const { data } = this.state;
        if (!resetSelectWhenValueNotFoundInData || !value || !data || asyncInfo.status !== AsyncStatus.Success) {
            return;
        }
        const valueIsInData = data.find((item) => item.value === (value && value.toString()));
        if (!valueIsInData) {
            // Empty the selected value as it is non existing in contants list
            // The timeout is used to ensure it is triggered after the component which uses this is mounted
            // Eg when used in a form the setFieldValue does not work until it is mounted
            this.resetFieldValueTimeout = window.setTimeout(
                () => {
                    onItemSelected(null, null);
                },
                0,
            );
        }
    }
}

export default connect<IPrivateProps, IConstantsTypeaheadProps>({
    stateProps: (state) => ({
        asyncInfo: getFetchConstantsAsyncInfo(state),
    }),
})(ConstantsTypeahead);

function ConstantsTypeahead(props: IPrivateProps & IConstantsTypeaheadProps) {
    return (
        <TranslatorContext.Consumer>
            {({ locale }) => <BaseConstantsTypeahead {...props} backendLocale={localeToBackendLocale(locale)} />}
        </TranslatorContext.Consumer>
    );
}
