import { createAction, createSuccessAction, createFailAction, createTypeActions } from '../index';
import {
    LOGIN,
    LOGOUT,
    FORGOT_PASSWORD,
    REMEMBER_ME, RESET_REMEMBER_ME,
    UPDATE_ACCESS_LEVEL,
    ADD_EXTRA_PERMISSIONS_FOR_COMPANY,
    FETCH_MY_USER_INFO,
    UPDATE_MY_USER_INFO,
    REQUEST_ACCOUNT,
    CREATE_ACCOUNT,
    UPDATE_MY_AVATAR,
    UPDATE_SESSION_END_TIME,
    CLEAR_SESSION_END_TIME,
    CHECK_SESSION_END_TIME,
    EXTEND_BACKEND_SESSION,
    FETCH_PERFORMANCE_DASHBOARD_ACCESS,
    SET_SKIP_PERFORMANCE_DASHBOARD_WARNING,
    SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL,
    TOGGLE_PERFORMANCE_DASHBOARD_DIALOG,
    NAVIGATE_TO_PERFORMANCE_DASHBOARD,
    UPDATE_AUTH0_SESSION,
} from './types';
import { IAccessLevel, Permission } from '../../models/auth/authorisation';
import {
    ILoggedInUser,
    IRequestAccountPayload,
    ICreateAccountPayload,
    IUpdateAvatarPayload,
    IMyUserInfo,
} from '../../models/auth/authentication';
import { ITraceableApiError } from '../../models/general/error';
import isTraceableApiError from '../../utils/api/isTraceableApiError';
import { IUserAccount, IUpdateMyUserAccountInfoPayload } from '../../models/user/userAccount';
import { createResetAction } from '../../utils/libs/redux/createAction';
import {
    IPerformanceDashboardAccessData,
    INavigateToPerformanceDashboardPayload,
} from '../../models/general/performance';
import { IUpdateAuth0SessionPayload } from '../../models/auth/auth0';

const ACCEPTED_LOGIN_ERROR_MESSAGES = [
    'klantenzone.error.user.locked_too_many_retries',
];

// login

export function login() {
    return createAction(LOGIN, {});
}

export function loginSucceeded(payload: ILoggedInUser & { loginCount: number }) {
    return createSuccessAction<ILoggedInUser & { loginCount: number }>(LOGIN, payload);
}

export function loginFailed(error: ITraceableApiError) {
    if (isTraceableApiError(error)) {
        if (error.code === 404) {
            if (!ACCEPTED_LOGIN_ERROR_MESSAGES.includes(error.message)) {
                error.message = 'auth.login.invalid';
            }
        }
    }
    return createFailAction<ITraceableApiError>(LOGIN, error);
}

// Auth0 login
export function updateAuth0Session(payload: IUpdateAuth0SessionPayload) {
    return createAction(
        UPDATE_AUTH0_SESSION,
        payload,
    );
}

// logout

export function logoutSucceeded() {
    return createSuccessAction(LOGOUT, {});
}

export function logoutFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(LOGOUT, error);
}

// forgot password

export const forgotPasswordActions = createTypeActions({
    type: FORGOT_PASSWORD,
});

// Request account

export function requestAccount(payload: IRequestAccountPayload) {
    return createAction<IRequestAccountPayload>(REQUEST_ACCOUNT, payload);
}

export function requestAccountSucceeded(payload: IUserAccount[]) {
    return createSuccessAction<IUserAccount[]>(REQUEST_ACCOUNT, payload);
}

export function requestAccountFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(REQUEST_ACCOUNT, error);
}

// Create account

export function createAccount(payload: ICreateAccountPayload) {
    return createAction<ICreateAccountPayload>(CREATE_ACCOUNT, payload);
}

export function createAccountSucceeded() {
    return createSuccessAction(CREATE_ACCOUNT, {});
}

export function createAccountFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(CREATE_ACCOUNT, error);
}

// fetch my user info

export function fetchMyUserInfo() {
    return createAction(FETCH_MY_USER_INFO, {});
}

export function fetchMyUserInfoSucceeded(payload: ILoggedInUser<IMyUserInfo>) {
    return createSuccessAction<ILoggedInUser<IMyUserInfo>>(FETCH_MY_USER_INFO, payload);
}

export function fetchMyUserInfoFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_MY_USER_INFO, error);
}

// update my user info

export function updateMyUserInfo(payload: IUpdateMyUserAccountInfoPayload) {
    return createAction<IUpdateMyUserAccountInfoPayload>(UPDATE_MY_USER_INFO, payload);
}

export function updateMyUserInfoSucceeded() {
    return createSuccessAction(UPDATE_MY_USER_INFO, {});
}

export function updateMyUserInfoFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(UPDATE_MY_USER_INFO, error);
}

export function resetUpdateMyUserInfo() {
    return createResetAction(UPDATE_MY_USER_INFO, {});
}

// remember me

export function rememberMe(username: string) {
    return createAction(REMEMBER_ME, { username });
}

export function resetRememberMe() {
    return createAction(RESET_REMEMBER_ME, {});
}

// dev panel permissions

export function updateAccessLevel(accessLevel: IAccessLevel) {
    return createAction<IAccessLevel>(UPDATE_ACCESS_LEVEL, accessLevel);
}

export function addExtraPermissionsForCompany(permissions: Permission[]) {
    return createAction<Permission[]>(ADD_EXTRA_PERMISSIONS_FOR_COMPANY, permissions);
}

// update my avatar

export const updateMyAvatarActions = createTypeActions<IUpdateAvatarPayload>({
    type: UPDATE_MY_AVATAR,
});

// Session timer
export function updateSessionEndTime() {
    return createAction(UPDATE_SESSION_END_TIME, {});
}

export function clearSessionEndTime() {
    return createAction(CLEAR_SESSION_END_TIME, {});
}

export function checkSessionEndTime() {
    return createAction(CHECK_SESSION_END_TIME, {});
}

export function extendBackEndSession() {
    return createAction(EXTEND_BACKEND_SESSION, {});
}

// Performance Dashboard
export const fetchPerformanceDashboardAccessActions = createTypeActions<{}, IPerformanceDashboardAccessData>({
    type: FETCH_PERFORMANCE_DASHBOARD_ACCESS,
});

export function setSkipPerformanceDialogWarningAction() {
    return createAction(SET_SKIP_PERFORMANCE_DASHBOARD_WARNING, {});
}

export function setSkipPerformanceDialogTutorialAction() {
    return createAction(SET_SKIP_PERFORMANCE_DASHBOARD_TUTORIAL, {});
}

export function toggleIsPerformanceDashboardDialogOpenAction() {
    return createAction(TOGGLE_PERFORMANCE_DASHBOARD_DIALOG, {});
}

export const navigateToPerformanceDashboardAction = createTypeActions<INavigateToPerformanceDashboardPayload>({
    type: NAVIGATE_TO_PERFORMANCE_DASHBOARD,
});
