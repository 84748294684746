import { IReservedMedicalExaminationWithoutId } from '../../../models/interventions/medicalExaminations/reserved';

// We asked Mensura IL team to add an id property per buffer zone object.
// The answer was that they cannot add it because it does not exist.
// A buffer zone is unique based on a combination of 2 parameters:
//   - activityId: activity on which timeslots are reserved (not unique)
//   - branchCode: organizationalUnitCode of the branch/company that has reserved timeslots

// Making an id on the back-end based on 2 parameters is considered an anti-pattern.
// Therefore we add the id property based on the 2 parameters above: activityId-branchCode.
export const composeReservedMedicalExaminationId = (
    reservedMedicalExamination: IReservedMedicalExaminationWithoutId,
): string => {
    const { activity, branch } = reservedMedicalExamination;

    return `${activity.id}-${branch.code}`;
};

export const parseReservedMedicalExaminationIdInActivityIdAndBranchCode = (id: string): [number, string] | [] => {
    try {
        const [activityIdAsString, branchCode] = id.split('-');

        let activityId: number;

        if (typeof activityIdAsString === 'string' && activityIdAsString.length > 0) {
            activityId = parseInt(activityIdAsString, 10);
        }

        return [activityId, branchCode];
    } catch (error) {
        return [];
    }
};

export const parseReservedMedicalExaminationActivityIdFromId = (id: string): number | null => {
    try {
        const activityIdAsString = id.split('-')[0];

        return parseInt(activityIdAsString, 10);
    } catch (error) {
        return null;
    }
};

export const parseReservedMedicalExaminationBranchCodeFromId = (id: string): string | null => {
    try {
        return id.split('-')[1];
    } catch (error) {
        return null;
    }
};
