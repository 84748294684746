import { Permission } from '../models/auth/authorisation';
import { ONE_SECOND, ONE_MINUTE } from '../utils/core/time/periodsInMillis';
import { STATE_STORAGE_KEY } from './redux.config';

export const SESSION_STORAGE_KEY = `${STATE_STORAGE_KEY}_SSSN`;

export const PERMISSIONS_DEPENDENT_ON_COMPANY = [
    Permission.CAN_CREATE_TEMP_FUNC,
];

export const SESSION_LENGTH_IN_MILLIS = 15 * ONE_MINUTE;
export const SESSION_END_WARNING_IN_MINUTES = 5;
export const SESSION_END_WARNING_IN_MILLIS = SESSION_END_WARNING_IN_MINUTES * ONE_MINUTE;
export const CHECK_SESSION_END_INTERVAL_IN_MILLIS = 30 * ONE_SECOND;
export const CHECK_APP_VERSION_INTERVAL_IN_MILLIS = ONE_MINUTE;
