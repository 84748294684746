import React, { MouseEvent } from 'react';
import './uploaded-file.scss';
import pretty from 'prettysize';
import Icon from '../../icons/Icon';
import fileIcon from '../../../assets/img/icons/file.svg';
import Translate from '../../Translate';
import InlineSVG from '../../icons/InlineSVG';

interface IPublicProps {
    file: File;
    onFileDelete?: (e: MouseEvent<HTMLElement>, file: File) => void;
    invalid?: boolean;
}

const CLASS_NAME = 'UploadedFile';

export default function UploadedFile(props: IPublicProps) {
    const {
        file, onFileDelete, invalid,
    } = props;

    return (
        <div className={CLASS_NAME}>
            <InlineSVG svg={fileIcon} className={`${CLASS_NAME}__figure`} />
            <div className={`${CLASS_NAME}__content`}>
                <span>{file.name}</span>
                { invalid ? (
                    <small className="error">
                        <Translate msg="common.uploaded_file.invalid_file"/>
                    </small>
                ) : (
                    <small>{pretty(file.size)}</small>
                )}
            </div>
            <div className={`${CLASS_NAME}__actions`}>
                {onFileDelete &&
                    <span className={`${CLASS_NAME}__actions__item`}>
                        <Icon typeName="bin" onClick={(e) => onFileDelete(e, file)} />
                    </span>
                }
            </div>
        </div>
    );
}
