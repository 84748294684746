import React from 'react';

import Translate from '../../../../../common/Translate';
import { formatTimeOfDateForDisplay } from '../../../../../../utils/formatting/formatTime';
import { CLASS_NAME } from '../ReservedMedicalExaminationDetail.const';

import { IReservedTimeSlotsListProps } from './ReservedTimeSlotsList.type';

import './ReservedTimeSlotsList.scss';

const ReservedTimeSlotsList = ({ timeSlots }: IReservedTimeSlotsListProps) => {
    if (timeSlots.length === 0) {
        return (
            <div className={`${CLASS_NAME}__reserved-time-slots`}>
                <p className="no-results">
                    <Translate msg="common.list.no_items" />
                </p>
            </div>
        );
    }

    return (
        <div className={`${CLASS_NAME}__reserved-time-slots`}>
            <ul className={`${CLASS_NAME}__reserved-list`}>
                {timeSlots.map((timeSlot) => (
                    <li className="item" key={timeSlot.id}>{formatTimeOfDateForDisplay(timeSlot.start)}</li>
                ))}
            </ul>
        </div>
    );
};

export { ReservedTimeSlotsList };
