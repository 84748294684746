import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { registerReducer } from '../../index';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';
import { FORM_MOUNTED, FORM_UNMOUNTED, FORM_UPDATED, APPLICATION_INITIALIZATION_FAILED } from './types';
import { IActiveForm } from '../../../models/ui/form';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.UI;

export interface IReducerState {
    activeForms: IActiveForm[];
    applicationInitilizationFailed: boolean;
}

const initialState: IReducerState = {
    activeForms: [],
    applicationInitilizationFailed: false,
};

const actionHandlers = {
    [FORM_MOUNTED]: createActionHandler<IReducerState, IActiveForm>(
        ({ oldState, payload }) => {
            return {
                ...oldState,
                activeForms: [
                    ...oldState.activeForms,
                    payload,
                ],
            };
        }),
    [FORM_UNMOUNTED]: createActionHandler<IReducerState, { name: string }>(
        ({ oldState, payload }) => {
            const newForms = oldState.activeForms.filter((form) => form.name !== payload.name);
            return {
                ...oldState,
                activeForms: newForms,
            };
        }),
    [FORM_UPDATED]: createActionHandler<IReducerState, IActiveForm>(
        ({ oldState, payload }) => {
            const newForms = oldState.activeForms.filter((form) => form.name !== payload.name);
            return {
                ...oldState,
                activeForms: [
                    ...newForms,
                    payload,
                ],
            };
        }),
    [APPLICATION_INITIALIZATION_FAILED]: createActionHandler<IReducerState>(
        ({ oldState }) => {
            return {
                ...oldState,
                applicationInitilizationFailed: true,
            };
        }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
    resetStateOnLocaleSwitch: false,
});
