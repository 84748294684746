import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import useResizeObserver from 'use-resize-observer';

import { setCssVarScrollbarWidth } from '../../../utils/dom/setCssVarScrollbarWidth';
import store from '../../../redux/store';
import initApp from '../../../redux/initApp';

import ConnectedApp from './ConnectedApp';

import './App.scss';

export default function App() {
    const { ref, height } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        setCssVarScrollbarWidth();
    }, [height]);

    return (
        <Provider store={store}>
            <div className="App" ref={ref}>
                <ConnectedApp />
            </div>
        </Provider>
    );
}

initApp();
