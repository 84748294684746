import { head } from 'ramda';

import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { get } from '../../../../utils/api/requestWrapper';
import {
    IFetchUpcomingActivityPayload,
    TUpcomingActivity,
} from '../../../../models/planning/activities';

import { BFF_URL } from '../activities.const';

export function fetchUpcomingActivity({
    companyCode,
    showFullFamily,
    locale,
}: IFetchUpcomingActivityPayload) {
    return get<TUpcomingActivity>({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.COMPANY_NEXT_ACTIVITY,
        pathParams: {
            organizationalUnitCode: companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (appointments: TUpcomingActivity[] | TUpcomingActivity) => {
            if (!appointments) {
                return {} as TUpcomingActivity;
            }

            if (Array.isArray(appointments)) {
                return head(appointments);
            }

            if (typeof appointments === 'object') {
                return appointments;
            }

            return null;
        },
    });
}
