import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

import { AsyncStatus, IAsyncDoField } from '../../models/general/redux';
import { CalendarEventType, CalendarEventDataType, ICalendarEvent } from '../../models/ui/calendar';
import { IN_COMPANY_MEDICAL_CENTER_CODE } from '../../config/planning.config';
import { EXAMINATION_REASON_CODE } from '../../config/navigation/interventions.config';
import { formatDateForBackend, formatDateForBackendFull } from '../../utils/formatting/formatDate';
import { formatPersonName } from '../../utils/formatting/formatPerson';
import {
    getDate,
    getDateWithoutTime,
    hoursOffsetFromNow,
} from '../../utils/core/date/getSpecificDate';
import { getPlanMedicalExaminatonWizardSteps } from '../../config/navigation/wizardStepsMap';
import { ICompanyMedicalCenter } from '../../models/admin/companyInfo';
import { IEmployeeDetails } from '../../models/admin/employee';
import {
    IExaminationReason,
    IMedicalExaminationDocument,
    IMedicalExaminationDocuments,
    IMedicalExaminationToPlan,
    IPeriodicHealthAssessmentAutomaticEntity,
    IPlanMedicalExaminationBaseEntity,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
    IPlanMedicalExaminationWizardData,
    PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    MedicalExaminationTimeSlotType,
    IMedicalExaminationTimeSlot,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../models/interventions/medicalExaminations';
import { ILocationTypeCodes } from '../../models/general/location';
import { IPlannedMedicalExamination } from '../../models/interventions/medicalExaminations/planned';
import { isAfterOrEqual } from '../../utils/core/date/isAfterOrEqual';
import { isDateBetweenInclusive } from '../../utils/core/date/isDateBetween';
import { isPlannedMedicalExaminationInCompany } from '../../utils/interventions/medicalExaminations/isInCompany';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../config/medicalExamination.config';
import getDaysInMonthView from '../../utils/libs/flatpickr/getDaysInMonthView';
import {
    mergeSimultaniousMedicalExaminationTimeSlots,
    mergeSimultaniousPlannedMedicalExaminations,
} from '../../utils/calendar/mergeSimultaniousEventsWithTheSameType';
import {
    canBePlannedOnBufferzone,
    canExaminationReasonBePlannedOnBufferzone,
} from '../company/info/bufferzoneUtils';
import {
    getAsyncDoInfo,
    getReducerState,
    IState,
    makeAsyncDoInfoSelector,
    makeAsyncFetchInfoSelector,
    NO_RERENDER,
} from '../index';
import { getCompanyMedicalCenters } from '../company/info/selectors';
import { getCreateConvocationsAsyncInfo } from '../employee/documents/selectors';
import { getLocale } from '../i18n/selectors';
import { getPropertyFromRoutePayload, getRoutePayload } from '../location/selectors';
import { getSelectedEmployeeAsyncInfo, getSelectedEmployee } from '../employee/info/selectors';

import { mapBffMedicalExaminationForPlannedMedicalExaminationExport } from './mappers';
import { IReducerState, reducerKey } from './reducer';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// To plan medical examinations

const getMedicalExaminationsToPlanAsyncField = (state: IState) => reducerState(state).medicalExaminationsToPlan;

export const getMedicalExaminationsToPlanAsyncInfo = makeAsyncFetchInfoSelector(getMedicalExaminationsToPlanAsyncField);
export const getMedicalExaminationsToPlan = (state: IState) =>
    getMedicalExaminationsToPlanAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const areMedicalExaminationsToPlanAvailable = (state: IState) =>
    getMedicalExaminationsToPlanAsyncField(state).data !== null;

/**
 * Fetches the plan info (e.g. contains the planningRequestId) if there is any that matches the given employee.
 *
 * This is used to conditionally pass the planningRequestId when planning a new timeslot or when updating
 * an existing timeslot in the case of a 'replace employee' scenario.
 * If there is no match on the input reason, this function will return null when the input examinationReason
 * is not 'perio'. This is because in prod there will only be examinations-to-plan for 'perio' reasons, but
 * this can include the basic 'perio' but also for example the 'perio + safety' reasons. So we want to take the
 * first planningRecord which has the flag 'periodical' regardless of the perio-reason, as long as the user is
 * planning a 'perio' examination.
 */
export function getMedicalExaminationToPlanInfoByEmployeeId(
    state: IState,
    { employeeId, examinationReason }: {
        employeeId: number;
        examinationReason: {
            id: number;
            code?: string;
        };
    },
): IMedicalExaminationToPlan {
    const examinationsToPlan = getMedicalExaminationsToPlan(state);
    const examinationsToPlanForEmployee = examinationsToPlan
        && examinationsToPlan.filter((examination) => examination.employee.id === employeeId);

    if (!examinationsToPlanForEmployee || examinationsToPlanForEmployee.length === 0) {
        return null;
    }

    const toPlanMatchedOnIdenticalReason = examinationsToPlanForEmployee
        .find((examination) => examination.examinationReason.id === examinationReason.id);

    if (toPlanMatchedOnIdenticalReason) {
        return toPlanMatchedOnIdenticalReason;
    }

    const reasonCode = examinationReason.code || getExaminationReasonCodeById(state, examinationReason.id);
    if (reasonCode !== EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT) {
        return null;
    }

    const periodicalExaminationsToPlanForEmployee = examinationsToPlanForEmployee
        .filter((examination) => examination.periodical && examination.periodical === true);

    return periodicalExaminationsToPlanForEmployee[0] || null;
}

export const getMedicalExaminationToPlanFromList = (state: IState, planningRequestId: number) =>
    getMedicalExaminationsToPlan(state)
        .find((item) => item.planningRequestId === planningRequestId);

// Planned medical examinations

const getPlannedMedicalExaminationsAsyncField = (state: IState) => reducerState(state).plannedMedicalExaminations;

export const getPlannedMedicalExaminationsAsyncInfo =
    makeAsyncFetchInfoSelector(getPlannedMedicalExaminationsAsyncField);

export const getPlannedMedicalExaminations = (state: IState) =>
    getPlannedMedicalExaminationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getPlannedMedicalExaminationsAsCalendarEvents = createSelector(
    getPlannedMedicalExaminations,
    (examinations) => {
        return examinations
            .map((examination): ICalendarEvent<IPlannedMedicalExamination[]> => {
                const start = getDate(examination.start);
                const end = getDate(examination.end);

                const isInCompany = isPlannedMedicalExaminationInCompany(examination);

                return {
                    id: `${CalendarEventType.MedicalExamination}_${examination.timeSlotId}`,
                    start,
                    end,
                    allDay: false,
                    type: isInCompany
                        ? CalendarEventType.MedicalExaminationInCompany
                        : CalendarEventType.MedicalExamination,
                    data: [examination],
                    title: formatPersonName({
                        firstName: examination.employee.firstName,
                        name: examination.employee.lastName,
                    }),
                    dataType: CalendarEventDataType.PlannedMedicalExamination,
                    isConcatenated: false,
                };
            });
    },
);

export const getConcatenatedPlannedMedicalExaminationsAsCalendarEvents = (state: IState) => {
    const calendarEvents = getPlannedMedicalExaminationsAsCalendarEvents(state);

    return mergeSimultaniousPlannedMedicalExaminations(calendarEvents);
};

export const arePlannedMedicalExaminationsAvailable = (state: IState) =>
    getPlannedMedicalExaminationsAsyncField(state).data !== null;

export const getPlannedMedicalExaminationFromList = (state: IState, id: number) => {
    return getPlannedMedicalExaminations(state)
        .find((item) => item.timeSlotId === id);
};

const getSelectedPlannedMedicalExaminationAsyncField = (state: IState) =>
    reducerState(state).selectedPlannedMedicalExamination;

export const getSelectedPlannedMedicalExaminationAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedPlannedMedicalExaminationAsyncField);

export const getSelectedPlannedMedicalExamination = (state: IState) =>
    getSelectedPlannedMedicalExaminationAsyncField(state).data;

// Reserved medical examinations
const getReservedMedicalExaminationsAsyncField = (state: IState) => reducerState(state).reservedMedicalExaminations;

export const getReservedMedicalExaminationsAsyncInfo = makeAsyncFetchInfoSelector(
    getReservedMedicalExaminationsAsyncField,
);

export const getReservedMedicalExaminations = (state: IState) =>
    getReservedMedicalExaminationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Selected reserved medical examinations
export const getSelectedReservedMedicalExaminationAsyncField = (state: IState) => {
    return reducerState(state).selectedReservedMedicalExamination;
};

export const getSelectedReservedMedicalExaminationAsyncInfo = makeAsyncFetchInfoSelector(
    getSelectedReservedMedicalExaminationAsyncField,
);

export const getSelectedReservedMedicalExamination = (state: IState) => {
    // Try to retrieve most up to date version of selected reserved medical examination
    // The data can be found in the store at 2 places:

    //   * selectedReservedMedicalExamination (fetched 1 reserved medical examination):
    //     -> Triggered from Reserved Medical Examination detail page
    //     -> Prefered way
    //     -> Return when activity id matches up

    //   * reservedMedicalExaminations (fetched all medical examinations in a date range):
    //     -> Triggered from Reserved Medical Examination overview page
    //     -> Fallback
    //     -> Try to find the selected reserved medical examination by activity Id
    //        in the list of all reserved medical examinations

    // First: check store for selectedReservedMedicalExamination field
    const id = getSelectedReservedMedicalExaminationIdFromPathParams(state);
    const reservedMedicalExamination = getSelectedReservedMedicalExaminationAsyncField(state).data || null;

    // Return stored selectedReservedMedicalExamination
    if (reservedMedicalExamination && reservedMedicalExamination.id === id) {
        return reservedMedicalExamination;
    }

    // Fallback: search for selected activity in all reserved medical examinations
    const reservedMedicalExaminations = getReservedMedicalExaminations(state);

    if (id && reservedMedicalExaminations.length > 0) {
        return reservedMedicalExaminations.find(
            (reservedMedicalExamination) => reservedMedicalExamination.id === id,
        );
    }

    return null;
};

export const getSelectedReservedMedicalExaminationIdFromPathParams = (state: IState) => {
    return getRoutePayload<{ reservedMedicalExaminationId: string }>(state).reservedMedicalExaminationId;
};

export const getSelectedReservedMedicalExaminationPlannedExaminations = (state: IState) => {
    const selectedMedicalExamination = getSelectedReservedMedicalExamination(state);

    if (
        !selectedMedicalExamination ||
        (!!selectedMedicalExamination && selectedMedicalExamination.plannedMedicalExaminations.length === 0)
    ) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return selectedMedicalExamination.plannedMedicalExaminations;
};

export const getSelectedReservedMedicalExaminationReservedTimeSlots = (state: IState) => {
    const selectedMedicalExamination = getSelectedReservedMedicalExamination(state);

    if (
        !selectedMedicalExamination ||
        (!!selectedMedicalExamination && selectedMedicalExamination.reservedTimeSlots.length === 0)
    ) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return selectedMedicalExamination.reservedTimeSlots;
};

export const getSelectedReservedMedicalExaminationPlannedExaminationsForExport = (state: IState) => {
    const selectedMedicalExamination = getSelectedReservedMedicalExamination(state);

    if (
        !selectedMedicalExamination ||
        (!!selectedMedicalExamination && selectedMedicalExamination.plannedMedicalExaminations.length === 0)
    ) {
        return NO_RERENDER.EMPTY_LIST;
    }

    return selectedMedicalExamination.plannedMedicalExaminations
        .map((medicalExamination) => mapBffMedicalExaminationForPlannedMedicalExaminationExport(
            medicalExamination,
            selectedMedicalExamination,
        ));
};

export const getCompanyBufferzonesForMedicalCenterCode = (state: IState, medicalCenterCode: string) =>
    getReservedMedicalExaminations(state).filter(
        (bufferzone) => {
            // A bufferzone is "in firma" when no bufferzone location code can be found
            const bufferzoneLocationCode = pathOr(
                bufferzone.location.typeCode === ILocationTypeCodes.CUSTOMER
                    ? IN_COMPANY_MEDICAL_CENTER_CODE
                    : null,
                ['location', 'code'],
                bufferzone,
            );

            return bufferzoneLocationCode === medicalCenterCode;
        },
    );

// Executed medical examinations
const getExecutedMedicalExaminationsAsyncField = (state: IState) => reducerState(state).executedMedicalExaminations;
export const getExecutedMedicalExaminationsAsyncInfo =
    makeAsyncFetchInfoSelector(getExecutedMedicalExaminationsAsyncField);
export const getExecutedMedicalExaminations = (state: IState) =>
    getExecutedMedicalExaminationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

const getSelectedExecutedMedicalExaminationAsyncField = (state: IState) =>
    reducerState(state).selectedExecutedMedicalExamination;

export const getSelectedExecutedMedicalExaminationAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedExecutedMedicalExaminationAsyncField);

export const getSelectedExecutedMedicalExamination = (state: IState) =>
    getSelectedExecutedMedicalExaminationAsyncField(state).data;

export const areExecutedMedicalExaminationsAvailable = (state: IState) =>
    getExecutedMedicalExaminationsAsyncField(state).data !== null;

export const getExecutedMedicalExaminationFromList = (state: IState, id: number) =>
    getExecutedMedicalExaminations(state)
        .find((item) => item.id === id);

// Examination reasons

const getExaminationReasonsAsyncField = (state: IState) => reducerState(state).examinationReasons;

export const getExaminationReasonsAsyncInfo = makeAsyncFetchInfoSelector(getExaminationReasonsAsyncField);
export const getExaminationReasons = (state: IState) =>
    getExaminationReasonsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getActiveExaminationReasons = createSelector(
    getExaminationReasons,
    (reasons) => reasons.filter((reason) => !!reason.active),
);

function getExaminationReasonById(state: IState, examinationReasonId: number) {
    return getExaminationReasons(state)
        .find((reason) => reason.id === examinationReasonId);
}

export function getExaminationReasonCodeById(state: IState, examinationReasonId: number) {
    const matchingReason = getExaminationReasonById(state, examinationReasonId);
    return matchingReason
        ? matchingReason.code
        : null;
}

export function getExaminationReasonByCode(state: IState, examinationReasonCode: string) {
    const matchingReason = getExaminationReasons(state)
        .find((reason) => reason.code === examinationReasonCode);

    return matchingReason;
}

export const areExaminationReasonsAvailable = (state: IState) => getExaminationReasons(state).length > 0;

export const getPeriodicHealthAssessmentReason = (state: IState) =>
    getExaminationReasons(state).find((reason) => (
        reason.code === EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT
    ));

export const getWizardTypeFromExaminationReasonId = (state: IState, examinationReasonId: number) => {
    const examinationReasons: IExaminationReason[] = getExaminationReasons(state);
    const examinationReason: IExaminationReason =
        examinationReasons.find((reason) => reason.id === examinationReasonId);

    if (!examinationReason) {
        return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.OTHER_MANUAL;
    }

    // TODO: add all possible wizard types (like 'moederschapsverlof' wizard)
    switch (examinationReason.code) {
        case EXAMINATION_REASON_CODE.INTERIM:
            return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.INTERIM_MANUAL;
        case EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT:
            return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL;
        case EXAMINATION_REASON_CODE.RE_INTEGRATION:
            return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RE_INTEGRATION_MANUAL;
        case EXAMINATION_REASON_CODE.RESUMPTION_OF_WORK:
            return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL;
        default:
            return PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.OTHER_MANUAL;
    }
};

// Plan wizard

const getPlanMedicalExaminationWizardData = <Entity>(state: IState) =>
    // eslint-disable-next-line max-len
    (reducerState(state).planMedicalExaminationWizardData || NO_RERENDER.EMPTY_OBJECT) as IPlanMedicalExaminationWizardData<Entity>;

export const getPlanMedicalExaminationWizardStepId = (state: IState) =>
    getPlanMedicalExaminationWizardData<object>(state).stepId;
export const getPlanMedicalExaminationWizardType = (state: IState) =>
    getPlanMedicalExaminationWizardData<object>(state).wizardType;
export const getPlanMedicalExaminationWizardReason = (state: IState) =>
    getPlanMedicalExaminationWizardData<object>(state).examinationReason;
export const getPlanMedicalExaminationWizardEntity = <Entity>(state: IState) =>
    getPlanMedicalExaminationWizardData<Entity>(state).entity;
export const getPlanMedicalExaminationWizardStepConfig = (state: IState) => {
    const currentStepId = getPlanMedicalExaminationWizardStepId(state);
    const currentWizardType = getPlanMedicalExaminationWizardType(state);
    const stepConfig = getPlanMedicalExaminatonWizardSteps(currentWizardType).steps;
    return stepConfig ? stepConfig.find((step) => step.id === currentStepId) : undefined;
};

export const getPlanMedicalExaminationFunnelTypeForAnalytics =
    (state: IState): PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE => {
        const examinationReason = getPlanMedicalExaminationWizardReason(state);
        const wizardType = getPropertyFromRoutePayload(state, 'wizardType');

        if (wizardType && examinationReason) {
            switch (wizardType) {
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL;
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC;
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.INTERIM_MANUAL:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.INTERIM_MANUAL;
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RE_INTEGRATION_MANUAL:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.RE_INTEGRATION_MANUAL;
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.RESUMPTION_OF_WORK_MANUAL;
                case PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.MOVE_PLANNING;
            }

            switch (examinationReason.code) {
                case EXAMINATION_REASON_CODE.MATERNITY_PROTECTION:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.MATERNITY_PROTECTION;
                case EXAMINATION_REASON_CODE.SPONTANEOUS_CONSULTATION:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.SPONTANEOUS_CONSULTATION;
                case EXAMINATION_REASON_CODE.PERIODIC_CHECK_PLUS_SAFETY_FUNCTION:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.PERIODIC_CHECK_PLUS_SAFETY_FUNCTION;
                case EXAMINATION_REASON_CODE.PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT:
                    return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT;
            }
        }

        // default fallback
        return PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE.OTHER_MANUAL;
    };

export const getAutoPlanMedicalExaminationWizardEntityLocationIds = (state: IState) =>
    // eslint-disable-next-line max-len
    getPlanMedicalExaminationWizardData<IPeriodicHealthAssessmentAutomaticEntity>(state).entity.addedLocationIds || NO_RERENDER.EMPTY_LIST;

const medicalCenterCodeIsInList = (medicalCenters: ICompanyMedicalCenter[], code: string) => {
    return !!medicalCenters.find((item) => item.code === code);
};

const getMedicalCenterCodeFromEmployeeDetails = (
    employeeDetails: IEmployeeDetails,
    medicalCenters: ICompanyMedicalCenter[] = [],
): string | null => {
    if (!employeeDetails) {
        return null;
    }

    const selectedMedicalCenter = medicalCenters.find((medicalCenter) => {
        if (
            employeeDetails.medicalCenterCode &&
            (medicalCenter.code === employeeDetails.medicalCenterCode)
        ) {
            return true;
        }

        if (
            employeeDetails.medicalCenterCodeSeat &&
            (medicalCenter.code === employeeDetails.medicalCenterCodeSeat)
        ) {
            return true;
        }
        if (
            employeeDetails.medicalCenterCodeCompany &&
            (medicalCenter.code === employeeDetails.medicalCenterCodeCompany)
        ) {
            return true;
        }

        return false;
    });

    if (selectedMedicalCenter) {
        return selectedMedicalCenter.code;
    }

    return null;
};

export const getDefaultSelectedMedicalCenterCode = (state: IState) => {
    const selectedEmployeeDetails = getSelectedEmployee(state);
    const medicalCenters = getCompanyMedicalCenters(state);

    if (!Array.isArray(medicalCenters)) {
        return null;
    }

    const medicalCenterCodeFromEmployeeDetails = getMedicalCenterCodeFromEmployeeDetails(
        selectedEmployeeDetails,
        medicalCenters,
    );

    if (medicalCenterCodeFromEmployeeDetails) {
        return medicalCenterCodeFromEmployeeDetails;
    }

    if (medicalCenterCodeIsInList(medicalCenters, IN_COMPANY_MEDICAL_CENTER_CODE)) {
        return IN_COMPANY_MEDICAL_CENTER_CODE;
    }

    if (medicalCenters[0]) {
        return medicalCenters[0].code;
    }

    return null;
};

export const getDefaultSelectedMedicalCenterCodeWithoutDefault = (state: IState) => {
    const selectedEmployeeDetails = getSelectedEmployee(state);
    const medicalCenters = getCompanyMedicalCenters(state);

    if (!Array.isArray(medicalCenters)) {
        return null;
    }

    const medicalCenterCodeFromEmployeeDetails = getMedicalCenterCodeFromEmployeeDetails(
        selectedEmployeeDetails,
        medicalCenters,
    );

    if (medicalCenterCodeFromEmployeeDetails) {
        return medicalCenterCodeFromEmployeeDetails;
    }

    return null;
};

// Employees to plan

const getEmployeesToPlanAsyncField = (state: IState) => reducerState(state).employeesToPlan;

export const getEmployeesToPlanAsyncInfo = makeAsyncFetchInfoSelector(getEmployeesToPlanAsyncField);
export const getEmployeesToPlan = (state: IState) => getEmployeesToPlanAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getAddedEmployees = (state: IState) =>
    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state).addedEmployees
    || NO_RERENDER.EMPTY_LIST;

export const getEmployeesToPlanCombinedWithAddedEmployees = createSelector(
    getEmployeesToPlan,
    getAddedEmployees,
    (employeesToPlan, addedEmployees) => employeesToPlan.concat(addedEmployees),
);

// Timeslots

const getAddTimeslotAsyncField = (state: IState) => reducerState(state).addTimeslot;
const getUpdateTimeslotAsyncField = (state: IState) => reducerState(state).updateTimeslot;
const getRemoveTimeslotAsyncField = (state: IState) => reducerState(state).removeTimeSlot;

export const getAddTimeslotAsyncInfo = makeAsyncDoInfoSelector(getAddTimeslotAsyncField);
export const getUpdateTimeslotAsyncInfo = makeAsyncDoInfoSelector(getUpdateTimeslotAsyncField);
export const getRemoveTimeslotAsyncInfo = makeAsyncDoInfoSelector(getRemoveTimeslotAsyncField);

export const getLastUpdatedTimeslotId = (state: IState) => reducerState(state).lastUpdatedTimeslotId;

// Auto plan

const getAutoPlanEmployeesAsyncField = (state: IState) => reducerState(state).autoPlanEmployees;

export const getAutoPlanEmployeesAsyncInfo = makeAsyncDoInfoSelector(getAutoPlanEmployeesAsyncField);

// Medical center free slots requests
const getMedicalCenterFreeSlotsRequestsAsyncField = (state: IState) =>
    reducerState(state).companyMedicalCentersFreeSlotsRequests;

// TODO memoization selector
export const getMedicalCenterFreeSlotsRequestsAsyncInfo = (state: IState, medicalCenterId: number) => {
    const medicalCenterFreeSlotsRequestsAsyncField = getMedicalCenterFreeSlotsRequestsAsyncField(state);

    const fieldNames = findFieldNamesThatIncludeId(medicalCenterFreeSlotsRequestsAsyncField, medicalCenterId);
    const fieldName = returnFirstFieldNameThatIsDefined(medicalCenterFreeSlotsRequestsAsyncField, fieldNames);

    const asynDoStatus = medicalCenterFreeSlotsRequestsAsyncField[fieldName];

    if (asynDoStatus) {
        return getAsyncDoInfo(asynDoStatus);
    }
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
};

// Company Medical center Timeslots

const getCompanyMedicalCenterTimeslotsAsyncField = (state: IState) => reducerState(state).companyMedicalCenterTimeslots;

export const getCompanyMedicalCenterTimeslotsAsyncInfo =
    makeAsyncFetchInfoSelector(getCompanyMedicalCenterTimeslotsAsyncField);

export const getBufferzonesWithinPlanningCalendarRangeMemoized = createSelector(
    getReservedMedicalExaminations,
    (state) => getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state),
    getPlanMedicalExaminationWizardReason,
    (reservedMedicalExaminations, wizardEntity, selectedExaminationReason) => {
        const minDate = pathOr(
            formatDateForBackendFull(hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED)),
            ['selectTime', 'minDate'],
            wizardEntity,
        );
        const maxDate = pathOr(null, ['selectTime', 'maxDate'], wizardEntity);

        return reservedMedicalExaminations
            /* filter by calendar range */
            .filter((reservedMedicalExamination) => {
                if (!maxDate) {
                    return isAfterOrEqual(reservedMedicalExamination.activity.start, minDate);
                }

                return isDateBetweenInclusive(reservedMedicalExamination.activity.start, minDate, maxDate);
            })
            /* filter by examination reason (so that we don't show those reserved Medical Examinations that do not
                match the selected
                reason based on the allowed reasons of the reservedMedicalExamination) */
            .filter((reservedMedicalExamination) => {
                if (selectedExaminationReason) {

                    // Check if we are allowed to plan a medical examination on this bufferzone
                    if (!canBePlannedOnBufferzone({ bufferzone: reservedMedicalExamination })) {
                        /* if allowedExaminations is null (indicating a configuration exception)
                            we have to include the reserved Medical Examination so that an error can be
                            shown once it is selected */
                        return true;
                    }

                    return canExaminationReasonBePlannedOnBufferzone({
                        bufferzone: reservedMedicalExamination,
                        examinationReasonCode: selectedExaminationReason.code,
                    });
                }

                return true;
            });
    },
);

export const getCompanyMedicalCenterTimeslotsAsCalendarEvents =
    createSelector(
        (state: IState) => getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(
            state,
        ),
        (state: IState) => getCompanyMedicalCenterTimeslotsAsyncField(state).data || NO_RERENDER.EMPTY_LIST,
        (wizardEntity, timeSlots): ICalendarEvent<IMedicalExaminationTimeSlot[]>[] => {
            const minDate = pathOr<string>(
                formatDateForBackendFull(hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED)),
                ['selectTime', 'minDate'],
                wizardEntity,
            );

            return timeSlots.reduce(
                (accumulator, timeSlot) => {
                    const start = getDate(timeSlot.start);
                    const end = getDate(timeSlot.end);

                    const repeatedItemIndex = accumulator.findIndex((item) =>
                        item.start.getTime() === start.getTime());

                    if (isTimeSlotToBeShown({ start, minDate: getDate(minDate) })) {
                        const event = {
                            id: `${CalendarEventType.FreeTimeslot}_${timeSlot.id}`,
                            start,
                            end,
                            allDay: false,
                            type: CalendarEventType.FreeTimeslot,
                            data: [timeSlot],
                            dataType: CalendarEventDataType.TimeCell,
                            isConcatenated: false,
                            reserved: timeSlot.type === MedicalExaminationTimeSlotType.Reserved,
                        };

                        if (accumulator.find(e => e.id === event.id)) {
                            return accumulator;
                        }

                        // Little code block to decide which events get pushed in the accumulator.
                        // if the repeatedItem is reserved, it will be pushed.
                        if (repeatedItemIndex > 0
                            && !accumulator[repeatedItemIndex].reserved){
                            accumulator[repeatedItemIndex] = event;
                        } else {
                        accumulator.push(event);
                        }
                    }
                    return accumulator;
                },
                [] as ICalendarEvent<IMedicalExaminationTimeSlot[]>[],
            );
        },
    );

interface IIsTimeSlotToBeShownPayload {
    start: Date;
    minDate: Date;
}

function isTimeSlotToBeShown({start, minDate }: IIsTimeSlotToBeShownPayload): boolean {
    if (start < minDate) {
        return false;
    }

    return true;
}

export const getConcatenatedCompanyMedicalCenterTimeslotsAsCalendarEvents = (state: IState) => {
    const calendarEvents = getCompanyMedicalCenterTimeslotsAsCalendarEvents(state);

    return mergeSimultaniousMedicalExaminationTimeSlots(calendarEvents);
};

export const FREESLOTS_REQUEST_ID_SEPERATOR = '_';

function findFieldNamesThatIncludeId(
    asyncField: { [requestId: string]: IAsyncDoField },
    medicalCenterId: number,
) {
    return Object.keys(asyncField)
        .filter((key) => key.split(FREESLOTS_REQUEST_ID_SEPERATOR).includes(medicalCenterId.toString()));
}

function returnFirstFieldNameThatIsDefined(
    asyncField: { [requestId: string]: IAsyncDoField },
    fieldNames: string[],
) {
    return fieldNames.find((name) => !!asyncField[name]);
}

// Notify Employee step
export const getMedicalExaminationNotifyIsBusy = (state: IState) => {
    const fetchSmallEmployeeDetailsAsyncInfo = getSelectedEmployeeAsyncInfo(state);
    const createConvocationsAsyncInfo = getCreateConvocationsAsyncInfo(state);
    return (
        fetchSmallEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy ||
        createConvocationsAsyncInfo.status === AsyncStatus.Busy
    );
};

export const getMedicalExaminationNotifyError = (state: IState) => {
    const fetchSmallEmployeeDetailsAsyncInfo = getSelectedEmployeeAsyncInfo(state);
    const createConvocationsAsyncInfo = getCreateConvocationsAsyncInfo(state);
    return (
        fetchSmallEmployeeDetailsAsyncInfo.error ||
        createConvocationsAsyncInfo.error
    );
};

// Re-integration flow create request
const getCreateReIntegrationRequestAsyncField = (state: IState) =>
    reducerState(state).createReIntegrationRequest;

export const getCreateReIntegrationRequestAsyncInfo = makeAsyncDoInfoSelector(getCreateReIntegrationRequestAsyncField);

// Examination documents
const getMedicalExaminationDocumentsAsyncField = (state: IState) =>
    reducerState(state).medicalExaminationDocuments;

export const getMedicalExaminationDocumentsAsyncInfo =
    makeAsyncFetchInfoSelector(getMedicalExaminationDocumentsAsyncField);

export const getMedicalExaminationDocuments = (state: IState): IMedicalExaminationDocuments =>
    getMedicalExaminationDocumentsAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

export function toFlattenExaminationDocuments(examinationDocuments: IMedicalExaminationDocuments): string[] {
    return Object.keys(examinationDocuments)
        .reduce(
            (documentAccumulator, documentClass) => {
                return documentAccumulator.concat(
                    examinationDocuments[documentClass]
                        .map((document: IMedicalExaminationDocument) => (document.id.toString())),
                );
            },
            [],
        );
}

// Executed examination documents and files
const getExaminationDocumentsAndFilesAsyncField = (state: IState) =>
    reducerState(state).examinationDocumentsFiles;

export const getExaminationDocumentsAndFilesAsyncInfo =
    makeAsyncFetchInfoSelector(getExaminationDocumentsAndFilesAsyncField);

// No shows examination
const getNoShowsMedicalExaminationsAsyncField = (state: IState) =>
    reducerState(state).noShowsMedicalExaminations;

export const getNoShowsMedicalExaminationsAsyncInfo =
    makeAsyncFetchInfoSelector(getNoShowsMedicalExaminationsAsyncField);

export const getNoShowsMedicalExaminatons = (state: IState) =>
    getNoShowsMedicalExaminationsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const areNoShowsMedicalExaminationsAvailable = (state: IState) =>
    getNoShowsMedicalExaminationsAsyncField(state).data !== null;

// Skip to plan medical examinations step

const getSkipToMedicalExaminationWizardStepAsyncField = (state: IState) =>
    reducerState(state).skipToPlanMedicalExaminationWizardStep;

export const getSkipToMedicalExaminationWizardStepAsyncInfo =
    makeAsyncDoInfoSelector(getSkipToMedicalExaminationWizardStepAsyncField);

// Validate employee to plan
const getValidateEmployeeToPlanAsyncField = (state: IState) =>
    reducerState(state).validateEmployeeToPlan;

export const getValidateEmployeeToPlanAsyncInfo =
    makeAsyncFetchInfoSelector(getValidateEmployeeToPlanAsyncField);

export const getValidateEmployeeToPlanData = (state: IState) =>
    getValidateEmployeeToPlanAsyncField(state).data;

// RequestMedicalExamination
const getRequestMedicalExaminationAsyncField = (state: IState) =>
    reducerState(state).requestMedicalExamination;

export const getRequestMedicalExaminationAsyncInfo =
    makeAsyncDoInfoSelector(getRequestMedicalExaminationAsyncField);

// SelectFreeTimeslot Daterange
const dateRangeFilterMemoizedSelector = createSelector(
    (state: IState) => {
        const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
        return wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.dateRangeToFetch
            && wizardEntity.selectTime.filter.dateRangeToFetch.start;
    },
    (state: IState) => {
        const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
        return wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.dateRangeToFetch
            && wizardEntity.selectTime.filter.dateRangeToFetch.end;
    },
    (start, end) => ({ start, end }),
);

export const dateRangeToFetchMemoizedSelector = createSelector(
    (state: IState) => {
        const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
        return wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.selectedDate;
    },
    (state: IState) => {
        const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
        return (wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.minDate) || formatDateForBackendFull(hoursOffsetFromNow(4));
    },
    (state: IState) => {
        const wizardEntity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
        return wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.maxDate;
    },
    dateRangeFilterMemoizedSelector,
    getLocale,
    (selectedDate, minDate, maxDate, dateRangeToFetchFilter, locale) => {
        let dateRangeToFetch = dateRangeToFetchFilter;

        if (!dateRangeToFetch) {
            const dateObj = selectedDate ? getDate(selectedDate) : new Date();
            const daysInMonthView = getDaysInMonthView({
                month: dateObj.getMonth(),
                year: dateObj.getFullYear(),
                locale,
                minDate: minDate && getDateWithoutTime(minDate),
                maxDate: maxDate && getDateWithoutTime(maxDate),
            });
            dateRangeToFetch = {
                start: formatDateForBackend(daysInMonthView[0]),
                end: formatDateForBackend(daysInMonthView[daysInMonthView.length - 1]),
            };
        }
        return dateRangeToFetch;
    },
);
