import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import { IExecutedIntervention } from '../../../models/interventions/interventions';
import {
    FETCH_EXECUTED_INTERVENTIONS,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.INTERVENTION_EXECUTED;

export interface IReducerState {
    executedInterventions: IAsyncFetchField<IExecutedIntervention[]>;
}

const initialState: IReducerState = {
    executedInterventions: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IExecutedIntervention[], IReducerState, IExecutedIntervention[]>({
        baseActionType: FETCH_EXECUTED_INTERVENTIONS,
        fieldName: 'executedInterventions',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
