import React from 'react';
import './questionnaires.scss';
import TranslatorContext from '../../appShell/contexts/TranslatorContext';

export const OVERVIEW_CLASS_NAME = 'QuestionnairesOverview';

interface IQuestionnaireLabelDateItemProps {
    labelKey: string;
    date: string;
}

export function QuestionnaireLabelDateItem(props: IQuestionnaireLabelDateItemProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <div className="QuestionnaireLabelDateItem">
                    <span className="label">{translator(props.labelKey)}</span>
                    <span className="date">{props.date}</span>
                </div>
            )}
        </TranslatorContext.Consumer>
    );
}
