import React, { PureComponent, MouseEvent } from 'react';
import { BASE_CLASS_NAME } from '../common';
import { isCourseOfTypeOpen } from '../../shared/courseUtils';
import { IRenderDetailFooterProps } from '../../../../common/widget/MasterWithDetail/typings';
import connect from '../../../../../utils/libs/redux/connect';
import {
    COURSE_ENROLL_WIZARD_STEP_ID,
    ICourse,
    ICourseEnrollWizardEntity,
    ICourseSession,
} from '../../../../../models/documentCenter/courses';
import Translate from '../../../../common/Translate';
import Button from '../../../../common/buttons/Button/index';
import {
    fetchCourseSessionCertificatesActions,
    skipToCourseEnrollWizardStepActions,
} from '../../../../../redux/documentCenter/courses/actions';
import Icon from '../../../../common/icons/Icon';
import {
    getFetchCourseSessionCertificatesAsyncInfo,
} from '../../../../../redux/documentCenter/courses/selectors';
import { AsyncStatus } from '../../../../../models/general/redux';
import Loader from '../../../../common/waiting/Loader/index';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { getSelectedSeatCompanyCode } from '../../../../../redux/company/selected/selectors';

interface IPrivateProps {
    onDownloadCertificates: () => void;
    isDownloadingCertificates: boolean;
    courseSession: ICourseSession;
    toRegisterCourseWizard: (course: ICourse, session: ICourseSession) => void;
}

class DetailFooter extends
    PureComponent<IPrivateProps & IRenderDetailFooterProps<ICourse>> {

    constructor(props: IPrivateProps & IRenderDetailFooterProps<ICourse>) {
        super(props);

        this.onDownloadCertificates = this.onDownloadCertificates.bind(this);
    }

    public render() {
        if (!this.props.detailData) {
            return null;
        }

        const {
            isDownloadingCertificates,
        } = this.props;

        return (
            <div className={`${BASE_CLASS_NAME}__details__footer`}>
                <Button
                    id="download-certificates-button"
                    typeName="secondary"
                    onClick={this.onDownloadCertificates}
                    disabled={isDownloadingCertificates}
                >
                    <Icon typeName="download" />
                    <span>
                        <Translate msg="document_center.courses.followed.detail.footer.download_certificates" />
                    </span>
                </Button>
                <Loader show={isDownloadingCertificates} />
            </div>
        );
    }

    private onDownloadCertificates(e: MouseEvent<HTMLElement>) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onDownloadCertificates();
    }
}

export default connect<IPrivateProps, IRenderDetailFooterProps<ICourse>>({
    stateProps: (state) => {
        const downloadAsyncInfo = getFetchCourseSessionCertificatesAsyncInfo(state);
        const isDownloadingCertificates = downloadAsyncInfo.status === AsyncStatus.Busy;

        return {
            isDownloadingCertificates,
        };
    },

    dispatchProps: (dispatch, getState) => ({
        onDownloadCertificates: () => {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const { coursesOrganizedId } = getRoutePayload<{ coursesOrganizedId: number }>(state);

            dispatch(fetchCourseSessionCertificatesActions.trigger({ companyCode, coursesOrganizedId }));
        },
        toRegisterCourseWizard: (course: ICourse, session: ICourseSession) => {
            dispatch(skipToCourseEnrollWizardStepActions.trigger({
                wizardPayload: {
                    step: COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE,
                    course: {
                        name: course.courseName,
                        description: course.description,
                        id: course.id,
                    },
                },
                entity: {
                    session: {
                        coursesOrganizedId: course.coursesOrganizedId,
                        start: course.startDate,
                        stop: course.endDate,
                        price: session.price,
                        amountPE: session.amountPE,
                        maxAmount: course.maxAmountAttendees,
                        attendees: course.countAmountAttendees,
                        selectedFromOverview: true,
                    },
                    location: {
                        ...session.address,
                        selectedFromOverview: true,
                        selectedClosedCourseFromOverview: !isCourseOfTypeOpen(course),
                    },
                } as ICourseEnrollWizardEntity,
            }));
        },
    }),
})(DetailFooter);
