import React from 'react';

import WizardFormStep from '../../../../../common/navigation/WizardFormStep';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    getCourseEnrollWizardEntity, getFetchCoursePossibleLocationsAsyncInfo,
    getFetchCourseSessionsByLocationsAsyncInfo,
    getCourseEnrollWizardCourse,
    getCourseSessionsAsyncInfo,
} from '../../../../../../redux/documentCenter/courses/selectors';
import { updateCourseEnrollWizardEntity } from '../../../../../../redux/documentCenter/courses/actions';
import CourseInput, { IFormValues, schema } from './CourseInput';
import { AsyncStatus } from '../../../../../../models/general/redux';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard';

const CLASS_NAME = 'CourseEnrollment__course';
const FORM_NAME = 'course-enrollment-course-form';

const CourseFormStep = WizardFormStep<IFormValues>();

export default function Course(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <CourseFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'document_center.courses.new.steps.course.title',
                titlePlaceholders: (state) => {
                    const wizardCourse = getCourseEnrollWizardCourse(state);

                    return {
                        name: wizardCourse.name,
                    };
                },
            }}
            showLoaderSelector={(state) => {
                const locationsAsyncInfo = getFetchCoursePossibleLocationsAsyncInfo(state);
                const sessionsAsyncInfo = getFetchCourseSessionsByLocationsAsyncInfo(state);
                const courseSessionsAsyncInfo = getCourseSessionsAsyncInfo(state);

                return locationsAsyncInfo.status === AsyncStatus.Busy ||
                       sessionsAsyncInfo.status === AsyncStatus.Busy ||
                       courseSessionsAsyncInfo.status === AsyncStatus.Busy;
            }}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const entity = getCourseEnrollWizardEntity(state);
                    return {
                        location: entity.location,
                        session: entity.session,
                    };
                },
                renderStepFormContent: (renderProps) => (
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <CourseInput {...renderProps} />
                    </div>
                ),
                onChangeInputActionCreator: ({ values }) => updateCourseEnrollWizardEntity({
                    location: values.location,
                    session: values.session,
                }),
            }}
        />
    );
}
