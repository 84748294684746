import {
    COMPANY_VISITS_REQUEST_WIZARD_STEP_ID,
    ICompanyVisitRequestWizardPayload,
} from '../../../../../models/interventions/company-visits';
import ROUTE_KEYS from '../../../../../routeKeys';
import { createEpic } from '../../../../index';
import { fetchContacts } from '../../../info/actions';

// fetchDataDuringCompanyVisitRequestWizardEpic
createEpic<ICompanyVisitRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW,
    async processMultiple({ action }, dispatch, done) {
        const { step } = action.payload;

        if (step === COMPANY_VISITS_REQUEST_WIZARD_STEP_ID.CONTACT) {
            dispatch(fetchContacts());

            return done();
        }

        done();
    },
    latest: false,
});
