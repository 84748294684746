import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';
import { getRouteKey } from '../../location/selectors';
import { getRoute } from '../../../routes';
import { BASE_ROUTES } from '../../../config/route.config';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Employees

const getEmployeesAsyncField = (state: IState) => reducerState(state).employees;

export const getEmployees = (state: IState) => getEmployeesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getEmployeesAsyncInfo = makeAsyncFetchInfoSelector(getEmployeesAsyncField);
export const isEmployeesDataAvailable = (state: IState) => !!getEmployeesAsyncField(state).data;

// Employees count

export const getTotalEmployeesCount = (state: IState) => reducerState(state).totalEmployeesCount;

// Company function employees

const getCompanyFunctionEmployeesAsyncField = (state: IState) =>
    reducerState(state).companyFunctionEmployees;

export const getCompanyFunctionEmployeesAsyncInfo = makeAsyncFetchInfoSelector(getCompanyFunctionEmployeesAsyncField);

export const getCompanyFunctionEmployees = (state: IState) =>
    getCompanyFunctionEmployeesAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const isOnboardingWizardRoute = (state: IState) => {
    const routeKey = getRouteKey(state);
    const route = getRoute({ routeKey });

    return route.path.indexOf(BASE_ROUTES.onboarding) === 0; // startsWith
};

// Employees without email

const getEmployeesWithoutEmailAsyncField = (state: IState) => reducerState(state).employeesWithoutEmail;

export const getEmployeesWithoutEmail = (state: IState) =>
    getEmployeesWithoutEmailAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

export const getEmployeesWithoutEmailAsyncInfo = makeAsyncFetchInfoSelector(getEmployeesWithoutEmailAsyncField);
