import EditAddress, {
    IPublicProps, IEditAddressProps,
} from '../../../Employees/shared/EditAddress';
import connect from '../../../../../utils/libs/redux/connect';
import { getSelectedCompanyInfoSeat } from '../../../../../redux/company/info/selectors';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { BE_COUNTRY_CODE } from '../../../../../config/general.config';

export default connect<IPublicProps, IEditAddressProps>({
    stateProps: (state) => {
        const selectedCompany = getSelectedCompanyInfoSeat(state);

        return {
            isDetailsLoaded: !!selectedCompany,
            initialValues: selectedCompany && selectedCompany.address ? {
                countryCode: selectedCompany.address.countryCode || '',
                box: selectedCompany.address.box || '',
                city: selectedCompany.address.city || '',
                number: selectedCompany.address.number || '',
                postcode: selectedCompany.address.postcode || '',
                street: selectedCompany.address.street || '',
                zipCodeId: selectedCompany.address.zipCodeId || -1,
            } : {
                countryCode: BE_COUNTRY_CODE,
                box: '',
                city: '',
                number: '',
                postcode: '',
                street: '',
                zipCodeId: -1,
            },
            personName: selectedCompany && selectedCompany.company.name,
            allowInternationalAddress: true,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(EditAddress);
