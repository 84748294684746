import { ITraceableApiError, TRACEABLE_API_PREFIX } from '../../models/general/error';

export default function isTraceableApiError(error: object): error is ITraceableApiError {
    const traceableAPiError = error as ITraceableApiError;
    return (
        error &&
        typeof traceableAPiError.code === 'number' &&
        typeof traceableAPiError.id === 'string' &&
        traceableAPiError.id.indexOf(TRACEABLE_API_PREFIX) === 0
    );
}
