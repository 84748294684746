import React, { ReactNode } from 'react';
import './company-info.scss';
import PageHeader from '../../appShell/PageHeader';
import connect from '../../../utils/libs/redux/connect';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import { ITabItem } from '../../../models/general/navigation';
import ROUTE_KEYS from '../../../routeKeys';
import { ICompany } from '../../../models/admin/company';
import { getSelectedCompany } from '../../../redux/company/selected/selectors';

interface IPrivateProps {
    company: ICompany;
}

interface IPublicProps {
    children: ReactNode;
}

const ConnectedTemplate = connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            company: getSelectedCompany(state),
        };
    },
})(CompanyInfoTemplate);

export default function Template({
    children,
}: IPublicProps) {
    return (
        <ConnectedTemplate children={children} />
    );
}

function CompanyInfoTemplate(props: IPrivateProps & IPublicProps) {
    const {
        children,
        company,
    } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'administration.company_info.general.tab_title',
        linkTo: ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
    }, {
        translationKey: 'administration.company_info.seats.tab_title',
        linkTo: ROUTE_KEYS.R_COMPANY_INFO_SEATS,
    }, {
        translationKey: 'administration.company_info.contacts.tab_title',
        linkTo: ROUTE_KEYS.R_COMPANY_INFO_CONTACTS,
    }];

    return (
        <WithMenuTemplate>
            <div className="CompanyInfo">
                <PageHeader
                    breadcrumbs={true}
                    tabs={TABS}
                    title="administration.company_info.title"
                    titlePlaceholders={{ company_name: company ? company.name : '' }}
                    type="green"
                />
                <div className="container">
                    {children}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
