import { get, post } from '../../utils/api/requestWrapper';
import {
    ILoggedInUser,
    IUpdateAvatarPayload,
    IMyUserInfo,
} from '../../models/auth/authentication';
import { HEADER_NAME } from '../../config/api.config';
import buildMultiPartForm from '../../utils/api/buildMultiPartForm';

export const URL = {
    ME: '/users/me',
    UPLOAD_AVATAR: '/uploads/users/avatar',
};

export function fetchMyUserInfo() {
    return get<ILoggedInUser<IMyUserInfo>>({
        url: URL.ME,
        mapResponse: (data, headers) => {
            return {
                user: data.me,
                csrfToken: getCsrfTokenFromResponseHeader(headers),
            };
        },
    });
}

export function updateMyAvatar(payload: IUpdateAvatarPayload) {
    const formData = buildMultiPartForm({ mediaUpload: payload.avatarFile });

    return post({
        url: URL.UPLOAD_AVATAR,
        body: formData,
        headers: {}, // Content type header must not be set as it's not a json
    });
}

function getCsrfTokenFromResponseHeader(headers) {
    return headers && headers[HEADER_NAME.CSRF_TOKEN];
}
