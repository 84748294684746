import { ListItem } from '../../models/general/list';
import { getFieldAscendingComparator } from '../core/object/sortObjects';
import { ITypeaheadDataItem } from '../../views/common/input/Typeahead';

export default function getUniqueTypeaheadFilterValuesFromListItems<ColumnNames>(
    allListItems: ListItem<ColumnNames>[],
    valueColumnName: keyof ColumnNames,
    labelColumnName: keyof ColumnNames,
): ITypeaheadDataItem<string>[] {
    return allListItems.reduce(
        (uniqueAccumulator, listItem) => {
            if (listItem.columns[valueColumnName]) {
                const value = listItem.columns[valueColumnName].toString();

                if (uniqueAccumulator.every((entry) =>
                        entry.value !== value)
                ) {
                    uniqueAccumulator.push({
                        value,
                        label: listItem.columns[labelColumnName],
                    });
                }
            }
            return uniqueAccumulator;
        },
        [],
    )
        .sort(getFieldAscendingComparator<ITypeaheadDataItem>('label'));
}
