import React, {
    ChangeEvent,
    FocusEvent,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
    useRef,
} from 'react';
import classNames from 'classnames';
import './form.scss';
import { FormikTouched, useFormik, validateYupSchema } from 'formik';
import { MixedSchema } from 'yup';
import { ValidationErrors } from '../../../../models/general/error';
import convertYupErrors from '../../../../utils/error/convertYupErrors';
import connect from '../../../../utils/libs/redux/connect';
import { getFormInfo } from '../../../../redux/ui/form/selectors';
import { formMounted, formUnmounted, formUpdated } from '../../../../redux/ui/form/actions';
import { IActiveForm } from '../../../../models/ui/form';

export type TSetFieldValue<Values> = (
    field: keyof Values,
    value: string | number | object | boolean,
    shouldValidate?: boolean | undefined,
) => void;

export interface IFormRenderProps<Values = {}, Errors = ValidationErrors<Values>> {
    values: Values;
    errors: Errors;
    handleChange: (e: ChangeEvent<{}>) => void;
    handleBlur: (e: FocusEvent<{}>) => void;
    dirty: boolean;
    isValid: boolean;
    touched: FormikTouched<Values>;
    setFieldValue: TSetFieldValue<Values>;
    submitForm: () => void;
    setErrors: (errors) => void;
    resetForm: (newValues: Values) => void;
}

interface IPrivateProps {
    formInfo: IActiveForm;
    formMounted: (form: IActiveForm) => void;
    formUnmounted: (formName: string) => void;
    formUpdated: (form: IActiveForm) => void;
}

interface IPublicProps<Values = {}> {
    /**
     * Friendly name for the form
     */
    name: string;
    /**
     * Schema to use to validate the form values
     */
    schema?: MixedSchema;
    /**
     * Custom validation for when schema validation would not be sufficient (e.g. when complex validations)
     */
    customValidator?: (values: Values) => ValidationErrors<Values>;
    /**
     * Handle a form submit
     */
    handleSubmit?: (values: object) => void;
    /**
     * Initial values to be shown on the form
     */
    initialValues: object;
    /**
     * The form content
     */
    render: (props: IFormRenderProps<object>) => ReactElement<{}>;
    className?: string;
    footer?: ReactNode;
    setRef?: (ref: HTMLFormElement) => void;
    enableReinitialize?: boolean;
}

const CLASS_NAME = 'Form';

function Form(props: IPublicProps & IPrivateProps) {
    const [isInitialValid, setIsInitialValid] = useState<boolean>();
    const [isUnmounted, setIsUnmounted] = useState<boolean>();
    const mounted = useRef(false);

    const formik = useFormik({
        enableReinitialize: props.enableReinitialize,
        initialValues: props.initialValues,
        validate: (values) => {
            if (props.schema) {
                return validateYupSchema(values, props.schema, false, { abortEarly: false })
                    .then(() => {
                        return {};
                    })
                    .catch((validationError) => {
                        return convertYupErrors({ values, validationError });
                    });
            }

            // TODO if needed, we could both support schema validation and custom validation together
            // by first doing the schema validation, and then, when there are no schema errors, also
            // do the custom validation
            if (props.customValidator) {
                return props.customValidator(values);
            }

            return {};
        },
        onSubmit: (values) => {
            if (typeof props.handleSubmit === 'function') {
                props.handleSubmit(values);
            }
        },
    });

    useEffect(
        () => {
            mounted.current = true;

            async function onMount() {
                const { name, formMounted } = props;
                const { values, setErrors, dirty } = formik;
                const errors = await formik.validateForm(values);

                if (!isUnmounted && mounted.current) {
                    setErrors(errors);
                    const isValid = Object.keys(errors).length === 0;
                    setIsInitialValid(isValid);

                    if (formMounted) {
                        formMounted({
                            name,
                            isValid,
                            dirty,
                        });
                    }
                }
            }

            function onUnmount() {
                setIsUnmounted(true);

                const { formUnmounted, name } = props;

                if (formUnmounted) {
                    formUnmounted(name);
                }
            }

            onMount();

            return () => {
                mounted.current = false;

                onUnmount();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // Run on every render
    useEffect(() => {
        const { formUpdated, name, formInfo } = props;
        const { dirty } = formik;
        const isValid = isFormValid();

        if (!formInfo.name || isUnmounted) {
            return;
        }

        if (formInfo.dirty !== dirty || formInfo.isValid !== isValid) {
            if (formUpdated) {
                formUpdated({
                    name,
                    isValid,
                    dirty,
                });
            }
        }
    });

    const isValid = isFormValid();

    return (
        <form
            id={props.name}
            name={props.name}
            onSubmit={formik.handleSubmit}
            className={classNames(CLASS_NAME, {
                [props.className]: !!props.className,
                [`${CLASS_NAME}--in-SlideOutPanel`]: !!props.footer,
            })}
            ref={props.setRef}
        >
            <div className={`${CLASS_NAME}__content`}>
                {props.render({
                    values: formik.values,
                    errors: formik.errors,
                    handleChange: formik.handleChange,
                    handleBlur: formik.handleBlur,
                    dirty: formik.dirty,
                    isValid,
                    touched: formik.touched,
                    setFieldValue: formik.setFieldValue,
                    submitForm: formik.submitForm,
                    resetForm: (values) => formik.resetForm({ values }),
                    setErrors: formik.setErrors,
                })}
            </div>

            {props.footer && <div className={`${CLASS_NAME}__footer`}>{props.footer}</div>}
        </form>
    );

    function isFormValid() {
        const { dirty, errors } = formik;
        if (!dirty) {
            return isInitialValid;
        }
        // The default isValid check from Formik uses the dirty flag in combination with the isInitialValid property
        // To avoid having to specifiy the isInitialValid the form is validated when it is mounted,
        // but this does not reset the dirty flag
        return Object.keys(errors).length === 0;
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            return {
                formInfo: getFormInfo(state, publicProps.name),
            };
        };
    },
    dispatchProps: (dispatch) => {
        return {
            formMounted: (form: IActiveForm) => dispatch(formMounted(form)),
            formUnmounted: (formName: string) => dispatch(formUnmounted(formName)),
            formUpdated: (form: IActiveForm) => dispatch(formUpdated(form)),
        };
    },
})(Form);
