import React, { ChangeEvent, useState } from 'react';
import './address-fields.scss';
import classNames from 'classnames';
import { IFormRenderProps } from '../../forms/Form';
import { IAddressSave, ICity } from '../../../../models/general/address';
import { ITranslator } from '../../../../models/general/i18n';
import getMultiLeveledErrors from '../../../../utils/error/multiLeveledErrors';
import { fields } from '../schema';
import { BE_COUNTRY_CODE } from '../../../../config/general.config';
import { ErrorTypes } from '../../../../models/general/error';
import FloatableTextInputWrapper from '../../forms/FloatableTextInputWrapper';
import ConstantsTypeahead from '../../input/ConstantsTypeahead';
import { ConstantType } from '../../../../models/general/constants';
import RequiredMarker from '../../input/RequiredMarker';
import Translate from '../../Translate';
import FormFieldError from '../../forms/FormFieldError';
import CityTypeahead from '../CityTypeahead';
import TextInput from '../../input/TextInput';
// import StreetTypeahead from '../StreetTypeahead';

const PLACEHOLDER_KEYS = {
    country: 'common.address.country',
    cityWithPostcode: 'common.address.city_with_postcode',
    street: 'common.address.street',
    number: 'common.address.number',
    box: 'common.address.box',
    city: 'common.address.city',
    postcode: 'common.address.postcode',
};

interface FormValues {
    address: IAddressSave;
}

interface IAddressFieldsProps extends IFormRenderProps<FormValues> {
    initialAddress: IAddressSave;
    translator: ITranslator;
    className?: string;
    countryAndCityOnSameLine?: boolean;
    hideRequiredMarkers?: boolean;
    allowInternationalAddress?: boolean;
}

const CLASS_NAME = 'AddressFields';

export default function AddressFields(props: IAddressFieldsProps) {
    const [selectedCity, onCitySelected] = useState<ICity>(null);
    const {
        values, setFieldValue,
        touched, initialAddress, translator,
        className, countryAndCityOnSameLine,
        hideRequiredMarkers, allowInternationalAddress,
    } = props;

    const addressValues = values.address;
    const addressTouched = touched.address;

    const errors = getMultiLeveledErrors({
        errors: props.errors,
        fields,
        parent: 'address',
    });

    const selectedCountryNotSupported = errors && errors[fields.countryCode] &&
        errors[fields.countryCode].type === ErrorTypes.CountryNotSupported;

    const isBelgiumSelected = addressValues[fields.countryCode] === BE_COUNTRY_CODE;
    const isInternationalAddress = !isBelgiumSelected && allowInternationalAddress;

    return (
        <div
            className={classNames(CLASS_NAME, {
                [className]: !!className,
                [`${CLASS_NAME}__country-and-city-on-same-line`]: !!countryAndCityOnSameLine,
            })}
        >
            <div className={`${CLASS_NAME}__country-city`}>
                <FloatableTextInputWrapper className={`${CLASS_NAME}__country-city__country`}>
                    <ConstantsTypeahead
                        id={`address-fields-${fields.countryCode}`}
                        constantType={ConstantType.COUNTRIES}
                        placeholder={translator(PLACEHOLDER_KEYS.country)}
                        value={addressValues && addressValues[fields.countryCode]}
                        name={fields.countryCode}
                        onItemSelected={(value) => {
                            if (!value || value.toString() !== addressValues[fields.countryCode]) {
                                setFieldValue('address', {
                                    [fields.countryCode]: value,
                                    [fields.zipCodeId]: '',
                                    [fields.street]: '',
                                    [fields.number]: '',
                                    [fields.box]: '',
                                    [fields.postcode]: '',
                                    [fields.city]: '',
                                });
                                onCitySelected(null);
                            }
                        }}
                        isInvalid={selectedCountryNotSupported ||
                            (addressTouched && addressTouched[fields.countryCode]
                                && errors && !!errors[fields.countryCode])}
                    >
                        <label htmlFor={`address-fields-${fields.zipCodeId}`}>
                            <Translate msg={PLACEHOLDER_KEYS.country} />
                            {!hideRequiredMarkers && <RequiredMarker />}
                        </label>
                    </ConstantsTypeahead>
                    {selectedCountryNotSupported ? (
                        <FormFieldError error={errors && errors[fields.countryCode]} />
                    ) :
                        addressTouched && addressTouched[fields.countryCode] && (
                            <FormFieldError
                                error={errors && errors[fields.countryCode]}
                                placeholders={{
                                    fieldName: translator(PLACEHOLDER_KEYS.country),
                                }}
                            />
                        )}
                </FloatableTextInputWrapper>
                {!isInternationalAddress ? (
                    <FloatableTextInputWrapper className={`${CLASS_NAME}__country-city__city`}>
                        <CityTypeahead
                            id={`address-fields-${fields.zipCodeId}`}
                            name={fields.zipCodeId}
                            countryCode={addressValues && addressValues[fields.countryCode]}
                            value={addressValues && addressValues[fields.zipCodeId]}
                            onItemSelected={(value, city) => {
                                if (value !== addressValues[fields.zipCodeId]) {
                                    setFieldValue('address', {
                                        ...addressValues,
                                        [fields.zipCodeId]: value,
                                        [fields.street]: '',
                                        [fields.number]: '',
                                        [fields.box]: '',
                                        [fields.postcode]: city ? city.postalCode : '',
                                        [fields.city]: city ? city.name : '',
                                    });
                                }
                                onCitySelected(city);
                            }}
                            isInvalid={addressTouched && addressTouched[fields.zipCodeId]
                                && errors && !!errors[fields.zipCodeId]}
                            initialPostcode={initialAddress && initialAddress.postcode}
                        >
                            <label htmlFor={`address-fields-${fields.zipCodeId}`}>
                                <Translate msg={PLACEHOLDER_KEYS.cityWithPostcode} />
                                {!hideRequiredMarkers && <RequiredMarker />}
                            </label>
                        </CityTypeahead>
                        {addressTouched && addressTouched[fields.zipCodeId] && (
                            <FormFieldError
                                error={errors && errors[fields.zipCodeId]}
                                placeholders={{
                                    fieldName: translator(PLACEHOLDER_KEYS.cityWithPostcode),
                                }}
                            />
                        )}
                    </FloatableTextInputWrapper>
                ) : (
                        <div className={`${CLASS_NAME}__postcode-city`}>
                            <FloatableTextInputWrapper className={`${CLASS_NAME}__postcode-city__postcode`}>
                                <TextInput
                                    id={`address-fields-${fields.postcode}`}
                                    value={addressValues && addressValues[fields.postcode]}
                                    name={fields.postcode}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('address', {
                                            ...addressValues,
                                            [fields.postcode]: e.target.value,
                                        });
                                    }}
                                    isInvalid={addressTouched && addressTouched[fields.postcode]
                                        && errors && !!errors[fields.postcode]}
                                />
                                <label htmlFor={`address-fields-${fields.postcode}`}>
                                    <Translate msg={PLACEHOLDER_KEYS.postcode} />
                                    {!hideRequiredMarkers && <RequiredMarker />}
                                </label>
                                {addressTouched && addressTouched[fields.postcode] && (
                                    <FormFieldError
                                        error={errors && errors[fields.postcode]}
                                        placeholders={{
                                            fieldName: translator(PLACEHOLDER_KEYS.postcode),
                                        }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper className={`${CLASS_NAME}__postcode-city__city`}>
                                <TextInput
                                    id={`address-fields-${fields.city}`}
                                    value={addressValues && addressValues[fields.city]}
                                    name={fields.city}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('address', {
                                            ...addressValues,
                                            [fields.city]: e.target.value,
                                        });
                                    }}
                                    isInvalid={addressTouched && addressTouched[fields.city]
                                        && errors && !!errors[fields.city]}
                                />
                                <label htmlFor={`address-fields-${fields.city}`}>
                                    <Translate msg={PLACEHOLDER_KEYS.city} />
                                    {!hideRequiredMarkers && <RequiredMarker />}
                                </label>
                                {addressTouched && addressTouched[fields.city] && (
                                    <FormFieldError
                                        error={errors && errors[fields.city]}
                                        placeholders={{
                                            fieldName: translator(PLACEHOLDER_KEYS.city),
                                        }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                        </div>
                    )}
            </div>
            <div className={`${CLASS_NAME}__street`}>
                <FloatableTextInputWrapper>
                    {/* TODO: Temporarily removed until CRAB is fixed */}
                    {/* {!isInternationalAddress ? (
                        <StreetTypeahead
                            id={`address-fields-${fields.street}`}
                            name={fields[fields.street]}
                            nisCode={selectedCity && selectedCity.nisCode}
                            placeholder={translator(PLACEHOLDER_KEYS.street)}
                            value={addressValues && addressValues[fields.street]}
                            onItemSelected={(value) => {
                                setFieldValue('address', {
                                    ...addressValues,
                                    [fields.street]: value,
                                });
                            }}
                            isInvalid={addressTouched && addressTouched[fields.street]
                                && errors && !!errors[fields.street]}
                        >
                            <label htmlFor={`address-fields-${fields.street}`}>
                                <Translate msg={PLACEHOLDER_KEYS.street} />
                                {!hideRequiredMarkers && <RequiredMarker />}
                            </label>
                        </StreetTypeahead>
                    ) : ( */}
                            <>
                                <TextInput
                                    id={`address-fields-${fields.street}`}
                                    value={addressValues && addressValues[fields.street]}
                                    name={fields.street}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('address', {
                                            ...addressValues,
                                            [fields.street]: e.target.value,
                                        });
                                    }}
                                    isInvalid={addressTouched && addressTouched[fields.street]
                                        && errors && !!errors[fields.street]}
                                />
                                <label htmlFor={`address-fields-${fields.street}`}>
                                    <Translate msg={PLACEHOLDER_KEYS.street} />
                                    {!hideRequiredMarkers && <RequiredMarker />}
                                </label>
                            </>
                        {/* )} */}
                    {addressTouched && addressTouched[fields.street] && (
                        <FormFieldError
                            error={errors && errors[fields.street]}
                            placeholders={{
                                fieldName: translator(PLACEHOLDER_KEYS.street),
                            }}
                        />
                    )}
                </FloatableTextInputWrapper>
                <FloatableTextInputWrapper className={`${CLASS_NAME}__street__number`}>
                    <TextInput
                        id={`address-fields-${fields.number}`}
                        placeholder={translator(PLACEHOLDER_KEYS.number)}
                        value={addressValues && addressValues[fields.number]}
                        name={fields.number}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('address', {
                                ...addressValues,
                                [fields.number]: e.target.value,
                            });
                        }}
                        isInvalid={addressTouched && addressTouched[fields.number]
                            && errors && !!errors[fields.number]}
                    />
                    <label htmlFor={`address-fields-${fields.number}`}>
                        <Translate msg={PLACEHOLDER_KEYS.number} />
                        {!hideRequiredMarkers && <RequiredMarker />}
                    </label>
                    {addressTouched && addressTouched[fields.number] && (
                        <FormFieldError
                            error={errors && errors[fields.number]}
                            placeholders={{
                                fieldName: translator(PLACEHOLDER_KEYS.number),
                            }}
                        />
                    )}
                </FloatableTextInputWrapper>
                <FloatableTextInputWrapper className={`${CLASS_NAME}__street__box`}>
                    <TextInput
                        id={`address-fields-${fields.box}`}
                        placeholder={translator(PLACEHOLDER_KEYS.box)}
                        value={addressValues && addressValues[fields.box]}
                        name={fields.box}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('address', {
                                ...addressValues,
                                [fields.box]: e.target.value,
                            });
                        }}
                        isInvalid={addressTouched && addressTouched[fields.box]
                            && errors && !!errors[fields.box]}
                    />
                    <label htmlFor={`address-fields-${fields.box}`}>
                        <Translate
                            msg={PLACEHOLDER_KEYS.box}
                        />
                    </label>
                    {addressTouched && addressTouched[fields.box] && (
                        <FormFieldError
                            error={errors && errors[fields.box]}
                            placeholders={{
                                fieldName: translator(PLACEHOLDER_KEYS.box),
                            }}
                        />
                    )}
                </FloatableTextInputWrapper>
            </div>
        </div>
    );
}
