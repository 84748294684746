import { createSelector } from 'reselect';
import { ILinkableNavigationItem } from '../../models/general/navigation';
import { IState } from '../IState';
import { getStore } from '../storeNoCircularDependencies';
import { mayUserAccessRoute } from './selectors';
import { getCompanySituationHistory } from '../preventionUnits/selectors';
import { isSelectedCompanyDismissed } from '../company/selected/selectors';

/**
 * For the memoization selector, an equivalent of "getMyUserAccessLevel" is used as input - even though
 * it's not really needed to determine the actual result (mayUserAccessRoute does that) - so that
 * the selector's cache will be cleared when the user's permissions change.
 */
export default function makeAllowedNavigationItemsMemoizedSelector<NavigationItem extends ILinkableNavigationItem>(
    navigationItems: NavigationItem[],
): (state: IState) => NavigationItem[] {
    return createSelector(
        /* we don't directly call the getMyUserAccessLevel selector here, because then we got a circular dependency
           error when running the jest tests (the test failed with the following error: 'Selector creators expect all
           input-selectors to be functions, instead received the following types: [undefined]') */
        (state: IState) => state.auth.userProfile && state.auth.userProfile.accessLevel,
        /* we re-calculate the allowed navigation items when the situation history changes, as that history can affect
           if certain PreventionUnits-, PolicyAdvice- or FlatFee-routes are available or not */
        getCompanySituationHistory,
        /* re-calculate if the selected company is active or dismissed, as most routes are not allowed for
           dismissed companies */
        isSelectedCompanyDismissed,
        (userAccessLevel, companySituationHistory, isCompanyDismissed) => {
            const state = getStore().getState();
            return navigationItems
                .filter((navigationItem) => mayUserAccessRoute(state, navigationItem.linkTo));
        },
    );
}
