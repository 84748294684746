import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './add-contact.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import { fields, schema } from './addContactSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import TextInput from '../../../../common/input/TextInput';
import FormError from '../../../../common/forms/FormError';
import Button from '../../../../common/buttons/Button';
import { IAddCompanyContactPayload, ICompanyContactToAdd } from '../../../../../models/admin/companyInfo';
import ConstantsTypeahead from '../../../../common/input/ConstantsTypeahead';
import { ConstantType } from '../../../../../models/general/constants';
import { addContact } from '../../../../../redux/company/info/actions';
import {
    getAddContactAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import SeatTypeahead from '../../../Employees/shared/SeatTypeahead';
import { removeEmptyPropsFromObject } from '../../../../../utils/core/object/objectProps';
import RequiredMarker from '../../../../common/input/RequiredMarker';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel/index';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { tryFormattingPhoneForBackend } from '../../../../../utils/formatting/formatPhone';

export interface ICompanyContactToAddFormValues extends ICompanyContactToAdd {
    companyCode: string;
    companyName?: string;
}

export type TFormValuesContactToAdd = Pick<ICompanyContactToAddFormValues,
    'firstName' |
    'name' |
    'languageId' |
    'typeId' |
    'titleId' |
    'email' |
    'phone' |
    'mobilePhone' |
    'companyCode' |
    'companyName' |
    'fax'>;

interface IPrivateProps {
    onAddContact: (values: TFormValuesContactToAdd) => void;
    translator: ITranslator;
    addAsyncInfo: IAsyncFieldInfo;
}

interface IPublicProps {
    onClose: () => void;
    onSucces: () => void;
    isMainSeat?: boolean;
    fixedCompanyCode?: string;
}

interface TComponentState {
    addAnotherContactClicked: boolean;
}

const CLASS_NAME = 'AddContact';
const FORM_NAME = 'add-contact-form';

class AddContact extends PureComponent<IPrivateProps & IPublicProps, TComponentState> {
    private resetForm: (newValues: object) => void;
    private submitForm: () => void;

    constructor(props) {
        super(props);

        this.state = {
            addAnotherContactClicked: false,
        };

        this.addAnotherContact = this.addAnotherContact.bind(this);
        this.onAddContact = this.onAddContact.bind(this);
    }

    public render() {
        const {
            translator, addAsyncInfo,
            fixedCompanyCode, isMainSeat,
        } = this.props;

        const INITIAL_VALUES = this.getContactInitialValues();

        const titlePlaceholder = translator('administration.company_info.contacts.add_contact.form.fields.title');
        const languagePlaceholder = translator('administration.company_info.contacts.add_contact.form.fields.language');
        const typePlaceholder = translator('administration.company_info.contacts.add_contact.form.fields.type');
        const seatPlaceholder = translator('administration.company_info.contacts.add_contact.form.fields.seat');

        return (
            <div className={CLASS_NAME}>
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    { isMainSeat ?
                        <div className={`${SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}__main-seat`}>
                            {/* eslint-disable-next-line max-len */}
                            <h3><Translate msg={'administration.company_info.contacts.add_contact.title_main_seat'}/></h3>
                            {/* eslint-disable-next-line max-len */}
                            <h4><Translate msg={'administration.company_info.contacts.add_contact.subtitle_main_seat'}/></h4>
                        </div> :
                        <h3><Translate msg={'administration.company_info.contacts.add_contact.title'}/></h3>
                    }
                </header>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onAddContact}
                    initialValues={INITIAL_VALUES}
                    schema={schema}
                    render={({
                        values, touched, errors, setFieldValue, handleChange, resetForm,
                        submitForm,
                    }: IFormRenderProps<TFormValuesContactToAdd>) => {
                        if (!this.resetForm) {
                            this.resetForm = resetForm;
                        }
                        if (!this.submitForm) {
                            this.submitForm = submitForm;
                        }
                        function onSeatItemSelected(companyCode: string) {
                            setFieldValue('companyCode', companyCode);
                        }
                        return (
                            <>
                                <Loader
                                    show={
                                        addAsyncInfo.status === AsyncStatus.Busy
                                    }
                                    showImmediatelly={true}
                                />
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-first-name"
                                        name={fields.firstName}
                                        value={values.firstName}
                                        onChange={handleChange}
                                        isInvalid={touched.firstName && !!errors.firstName}
                                    />
                                    <label htmlFor="add-contact-first-name">
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="administration.company_info.contacts.add_contact.form.fields.first_name"
                                        />
                                        <RequiredMarker />
                                    </label>
                                    {touched.firstName &&
                                        <FormFieldError
                                            error={errors.firstName}
                                            placeholders={{
                                                // eslint-disable-next-line max-len
                                                fieldName: translator('administration.company_info.contacts.add_contact.form.fields.first_name'),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-name"
                                        name={fields.name}
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    />
                                    <label htmlFor="add-contact-name">
                                        <Translate
                                            msg="administration.company_info.contacts.add_contact.form.fields.name"
                                        />
                                        <RequiredMarker />
                                    </label>
                                    {touched.name &&
                                        <FormFieldError
                                            error={errors.name}
                                            placeholders={{
                                                fieldName: translator(
                                                    'administration.company_info.contacts.add_contact.form.fields.name',
                                                ),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <ConstantsTypeahead
                                        id="add-contact-title-id"
                                        constantType={ConstantType.CONTACT_TITLES}
                                        name={fields.titleId}
                                        placeholder={titlePlaceholder}
                                        value={values.titleId}
                                        onItemSelected={(value) => setFieldValue('titleId', value)}
                                        isInvalid={touched.titleId && !!errors.titleId}
                                    >
                                        <label htmlFor="add-contact-title-id">
                                            <Translate
                                                msg={titlePlaceholder}
                                            />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.titleId && (
                                        <FormFieldError
                                            error={errors.titleId}
                                            placeholders={{ fieldName: titlePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <ConstantsTypeahead
                                        id="add-contact-language"
                                        constantType={ConstantType.LANGUAGES}
                                        name={fields.languageId}
                                        placeholder={languagePlaceholder}
                                        value={values.languageId}
                                        onItemSelected={(value) => setFieldValue('languageId', value)}
                                        isInvalid={touched.languageId && !!errors.languageId}
                                    >
                                        <label htmlFor="add-contact-language">
                                            <Translate
                                                msg={languagePlaceholder}
                                            />
                                            <RequiredMarker />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.languageId && (
                                        <FormFieldError
                                            error={errors.languageId}
                                            placeholders={{ fieldName: languagePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <ConstantsTypeahead
                                        id="add-contact-type-id"
                                        constantType={ConstantType.CONTACT_TYPES}
                                        name={fields.typeId}
                                        placeholder={typePlaceholder}
                                        value={values.typeId}
                                        onItemSelected={(value) => setFieldValue('typeId', value)}
                                        isInvalid={touched.typeId && !!errors.typeId}
                                    >
                                        <label htmlFor="add-contact-type-id">
                                            <Translate
                                                msg={typePlaceholder}
                                            />
                                            <RequiredMarker />
                                        </label>
                                    </ConstantsTypeahead>
                                    {touched.typeId && (
                                        <FormFieldError
                                            error={errors.typeId}
                                            placeholders={{ fieldName: typePlaceholder }}
                                        />
                                    )}
                                </FloatableTextInputWrapper>
                                {!fixedCompanyCode &&
                                    <FloatableTextInputWrapper floatLabel>
                                        <SeatTypeahead
                                            id="add-contact-seat"
                                            name={fields.companyCode}
                                            onItemSelected={onSeatItemSelected}
                                            value={values.companyCode}
                                            isInvalid={touched.companyCode && !!errors.companyCode}
                                            placeholder={seatPlaceholder}
                                        >
                                            <label htmlFor="add-contact-seat">
                                                <Translate
                                                    msg={seatPlaceholder}
                                                />
                                                <RequiredMarker />
                                            </label>
                                        </SeatTypeahead>
                                        {touched.companyCode && (
                                            <FormFieldError
                                                error={errors.companyCode}
                                                placeholders={{ fieldName: seatPlaceholder }}
                                            />
                                        )}
                                    </FloatableTextInputWrapper>
                                }
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-email"
                                        name={fields.email}
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <label htmlFor="add-contact-email">
                                        <Translate
                                            msg="administration.company_info.contacts.add_contact.form.fields.email"
                                        />
                                    </label>
                                    {touched.email &&
                                        <FormFieldError
                                            error={errors.email}
                                            placeholders={{
                                                fieldName: translator(
                                                    // eslint-disable-next-line max-len
                                                    'administration.company_info.contacts.add_contact.form.fields.email',
                                                ),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-phone"
                                        name={fields.phone}
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={touched.phone && !!errors.phone}
                                    />
                                    <label htmlFor="add-contact-phone">
                                        <Translate
                                            msg="administration.company_info.contacts.add_contact.form.fields.phone"
                                        />
                                    </label>
                                    {touched.phone &&
                                        <FormFieldError
                                            error={errors.phone}
                                            placeholders={{
                                                fieldName: translator(
                                                    // eslint-disable-next-line max-len
                                                    'administration.company_info.contacts.add_contact.form.fields.phone',
                                                ),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-fax"
                                        name={fields.fax}
                                        value={values.fax}
                                        onChange={handleChange}
                                        isInvalid={touched.fax && !!errors.fax}
                                    />
                                    <label htmlFor="add-contact-fax">
                                        <Translate
                                            msg="administration.company_info.contacts.add_contact.form.fields.fax"
                                        />
                                    </label>
                                    {touched.fax &&
                                        <FormFieldError
                                            error={errors.fax}
                                            placeholders={{
                                                fieldName: translator(
                                                    'administration.company_info.contacts.add_contact.form.fields.fax',
                                                ),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <TextInput
                                        id="add-contact-mobile-phone"
                                        name={fields.mobilePhone}
                                        value={values.mobilePhone}
                                        onChange={handleChange}
                                        isInvalid={touched.mobilePhone && !!errors.mobilePhone}
                                    />
                                    <label htmlFor="add-contact-mobile-phone">
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="administration.company_info.contacts.add_contact.form.fields.mobile_phone"
                                        />
                                    </label>
                                    {touched.mobilePhone &&
                                        <FormFieldError
                                            error={errors.mobilePhone}
                                            placeholders={{
                                                fieldName: translator(
                                                    // eslint-disable-next-line max-len
                                                    'administration.company_info.contacts.add_contact.form.fields.mobile_phone',
                                                ),
                                            }}
                                        />}
                                </FloatableTextInputWrapper>
                                <FormError error={addAsyncInfo.error} />
                                <div className={classNames(SLIDEOUTPANEL_CLASSES.ACTIONS, `${CLASS_NAME}__actions`)}>
                                    <SubmitButton
                                        id="add-contact-submit-button"
                                        formName={FORM_NAME}
                                        alwaysEnabled={true}
                                    > {/*eslint-disable-next-line max-len*/}
                                        <Translate msg="administration.company_info.contacts.add_contact.form.submit" />
                                    </SubmitButton>
                                    <Button
                                        id="add-another-contact-submit-button"
                                        typeName="secondary"
                                        onClick={this.addAnotherContact}
                                    > {/*eslint-disable-next-line max-len*/}
                                        <Translate msg="administration.company_info.contacts.add_contact.form.add_another" />
                                    </Button>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.resetForm &&
            this.props.addAsyncInfo.status === AsyncStatus.Success &&
            prevProps.addAsyncInfo.status === AsyncStatus.Busy
        ) {
            if (!this.state.addAnotherContactClicked) {
                this.props.onSucces();
            }

            this.setState({
                addAnotherContactClicked: false,
            });

            this.resetForm(this.getContactInitialValues());
        }
    }

    private onAddContact(values: TFormValuesContactToAdd) {
        this.props.onAddContact(values);
    }

    private addAnotherContact() {
        this.setState({
            addAnotherContactClicked: true,
        });

        this.submitForm();
    }

    private getContactInitialValues(): TFormValuesContactToAdd {
        return {
            firstName: '',
            name: '',
            languageId: null,
            typeId: null,
            titleId: null,
            email: '',
            phone: '',
            mobilePhone: '',
            companyCode: this.props.fixedCompanyCode || '',
            companyName: '',
            fax: '',
        };
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        const addAsyncInfo = getAddContactAsyncInfo(state);
        return {
            translator: getTranslatorDeprecated(state),
            addAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onAddContact: (values) => {
                dispatch(
                    addContact({
                        ...removeEmptyPropsFromObject(transformToAddContactPayload({ ...values })),
                    }),
                );
            },
        };
    },
})(AddContact);

function transformToAddContactPayload(payload: ICompanyContactToAddFormValues): IAddCompanyContactPayload {
    const { companyCode, companyName, ...other } = payload;

    const transformed: IAddCompanyContactPayload = {
        companyCode,
        contactData: {
            ...other,
            mobilePhone: tryFormattingPhoneForBackend(other.mobilePhone),
            phone: tryFormattingPhoneForBackend(other.phone),
        },
    };

    return transformed;
}
