import React from 'react';

import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import { ISortedColumn, SortOrder, ListColumns, SortType, ListItem } from '../../../../models/general/list';
import { yearOffsetFromNow } from '../../../../utils/core/date/getSpecificDate';
import Translate from '../../../common/Translate';
import { VACCINATIONS_TRANS_PREFIX } from '../vaccines.const';

import {
    IToAdministerVaccinationsListColumnNames,
} from './ToAdministerVaccinationsList/ToAdministerVaccinationList.types';

export const BASE_NAME = 'to-administer-vaccinations';
export const TRANSLATION_PREFIX = `${VACCINATIONS_TRANS_PREFIX}.to_administer`;
export const DEFAULT_NR_OF_VACCINES_TO_SHOW = 20;

export const INITIAL_SORT: ISortedColumn<IToAdministerVaccinationsListColumnNames> = {
    name: 'dateToAdminister',
    sortOrder: SortOrder.Descending,
};

export const DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-2)),
    endDate: formatDateForBackend(yearOffsetFromNow(1)),
};

export const COLUMNS: ListColumns<IToAdministerVaccinationsListColumnNames> = {
    name: {
        label: <Translate msg="interventions.vaccinations.to_administer.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 18,
    },
    employer: {
        label: <Translate msg="interventions.vaccinations.to_administer.columns.employer" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 24,
    },
    dateToAdminister: {
        label: <Translate msg="interventions.vaccinations.to_administer.columns.date_to_administer" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IToAdministerVaccinationsListColumnNames>) => (
            listItem.columns.dateToAdministerSort
        ),
        percentWidth: 15,
    },
    dateToAdministerSort: {
        hide: true,
        sortType: SortType.String,
        percentWidth: null,
    },
    description: {
        label: <Translate msg="interventions.vaccinations.to_administer.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    injectionDescription: {
        label: <Translate msg="interventions.vaccinations.to_administer.columns.step" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 13,
    },
    employeeCode: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};
