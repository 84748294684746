import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import {
    IAddTimeSlotApiPayload,
    IAddTimeSlotByPlanningRequestIdApiPayload,
} from '../../../../models/interventions/timeslots';
import { post } from '../../../../utils/api/requestWrapper';
import { BFF_URL } from '../timeSlots.const';

export const addTimeslot = ({
    activityId,
    remarks,
    employmentId,
    examinationReasonCode,
    locale,
    timeSlotId,
}: IAddTimeSlotApiPayload) => post({
    ...bffDefaultApiConfig({ locale }),
    url: BFF_URL.TIMESLOTS,
    pathParams: {
        activityId,
    },
    body: {
        comment: remarks,
        employmentId,
        examinationReasonCode,
        timeSlotId,
    },
});

export const addTimeslotByPlanningRecordId = ({
    activityId,
    remarks,
    locale,
    timeSlotId,
    planningRequestMSId,
}: IAddTimeSlotByPlanningRequestIdApiPayload) => post({
    ...bffDefaultApiConfig({ locale }),
    url: BFF_URL.TIMESLOTS,
    pathParams: {
        activityId,
    },
    body: {
        timeSlotId,
        comment: remarks,
        planningRequestMSId,
    },
});
