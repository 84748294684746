import { object } from 'yup';
import { FLU_VACCINES_MIN_AMOUNT_TO_ORDER } from '../../../../../config/intervention.config';
import number from '../../../../../utils/libs/yup/number';

export { FLU_VACCINES_MIN_AMOUNT_TO_ORDER } from '../../../../../config/intervention.config';

export const fields = {
    amountToOrder: 'amountToOrder',
};

export const schema = object({
    [fields.amountToOrder]: number({
        minValue: FLU_VACCINES_MIN_AMOUNT_TO_ORDER,
    }).required(),
});
