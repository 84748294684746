import React from 'react';
import './carousel.scss';

interface IPublicProps {
    children: React.ReactNode;
}

export default function PerformanceTemplate({ children }: IPublicProps) {
    return (
        <div className="PerformanceTemplate">
            {children}
        </div>
    );
}
