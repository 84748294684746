import React, { Component } from 'react';
import { connect } from '../../../../index';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import { getDocumentAsyncInfo } from '../../../../../redux/employee/documents/selectors';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import { fetchDocument, resetFetchDocument } from '../../../../../redux/employee/documents/actions';
import Icon from '../../../../common/icons/Icon';
import Loader from '../../../../common/waiting/Loader';

interface IPublicProps {
    documentId: string;
    documentDescription: string;
    defaultFilename: string;
}

interface IPrivateProps {
    fetchDocumentAsyncInfo: IAsyncFieldInfo;
    downloadDocument: (documentId: string, defaultFilename: string) => void;
    resetFetchDocumentAsyncInfo: () => void;
}

class FetchDocumentAction extends Component<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.downloadDocumentHandler = this.downloadDocumentHandler.bind(this);
    }

    public render() {
        const {
            documentDescription,
            fetchDocumentAsyncInfo,
            resetFetchDocumentAsyncInfo,
        } = this.props;

        return (
            <>
                <Loader show={fetchDocumentAsyncInfo.status} />

                <div
                    className="download-file"
                    onClick={this.downloadDocumentHandler}
                >
                    <Icon typeName="doc"/>
                    <span>{documentDescription}</span>
                </div>

                <ErrorDialog
                    asyncInfo={fetchDocumentAsyncInfo}
                    titleTranslationKey="account.message_center.detail.body.download_document.error_title"
                    hideRealErrorMessage={true}
                    onCloseDialog={resetFetchDocumentAsyncInfo}
                />
            </>
        );
    }

    private downloadDocumentHandler() {
        const { documentId, defaultFilename } = this.props;
        this.props.downloadDocument(documentId, defaultFilename);
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            fetchDocumentAsyncInfo: getDocumentAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            downloadDocument: (documentId: string, defaultFilename: string) =>
                dispatch(fetchDocument({
                    ids: [documentId],
                    defaultFilename,
                })),
            resetFetchDocumentAsyncInfo: () => dispatch(resetFetchDocument()),
        };
    },
})(FetchDocumentAction);
