import { pathOr } from 'ramda';

import { formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import { IPlannedTimeSlot } from '../../../../../models/interventions/medicalExaminations';
import { ListItem } from '../../../../../models/general/list';

import { IPlanBufferzoneWizardOverviewListColumnNames } from './PlanBufferzoneWizardOverview.type';

export const mapBufferzonePlannedSlotsToListItems = (examinations: IPlannedTimeSlot[]) => {
    return examinations.map((exam) => {
        const item: ListItem<IPlanBufferzoneWizardOverviewListColumnNames> = {
            id: exam.timeSlotId,
            columns: {
                company: pathOr('', ['employee', 'employment', 'branch', 'name'], exam),
                date: formatDateForDisplay(exam.start),
                dateSort: exam.start,
                employee: formatPersonName({
                    firstName: exam.employee.firstName,
                    name: exam.employee.lastName,
                }),
                location: pathOr('', ['location', 'name'], exam),
                time: formatTimeOfDateForDisplay(exam.start),
            },
        };
        return item;
    });
};
