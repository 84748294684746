import { CalendarEventType } from '../models/ui/calendar';
import { TDialogHeaderColor } from '../views/common/modals/Dialog/DialogHeader';
import { formatDateForBackend } from '../utils/formatting/formatDate';
import { getStartAndEndDateOfWeek, now } from '../utils/core/date/getSpecificDate';

const TRANSLATION_PREFIX = 'planning.agenda.appointment_types';

export function getDefaultSearchDates(selectedDate: string | Date) {
    const { startOfWeek, endOfWeek } = getStartAndEndDateOfWeek(selectedDate || now());
    return {
        defaultSearchFrom: formatDateForBackend(startOfWeek),
        defaultSearchUntil: formatDateForBackend(endOfWeek),
    };
}

interface ICalendarEventTypeConfig {
    cssClassName: string;
    filterTranslationKey?: string;
    appointmentDetailHeaderColor?: TDialogHeaderColor;
    appointmentDetailTitleTranslationKey?: string;
    calendarEventTranslationKey: string;
    listEventTranslationKey?: string;
    labelTranslationKey?: string;
    subTypes?: { type: CalendarEventType, translationKey: string, cssClassName: string }[];
}

/**
 * Only the configs with a 'filterTranslationKey' will be shown in the Calendar legend
 */
export const CALENDAR_EVENT_TYPES_CONFIG: { [key in CalendarEventType]: ICalendarEventTypeConfig} = {
    course: {
        appointmentDetailHeaderColor: 'conifer',
        appointmentDetailTitleTranslationKey: `${TRANSLATION_PREFIX}.course.appointment_detail.title`,
        calendarEventTranslationKey: `${TRANSLATION_PREFIX}.course.calendar_event.title`,
        filterTranslationKey: `${TRANSLATION_PREFIX}.course.filter_label`,
        labelTranslationKey: `${TRANSLATION_PREFIX}.course.label`,
        cssClassName: 'course',
    },
    medicalExamination: getMedicalExaminationConfig({ includeFilterTranslationKey: true }),
    medicalExaminationInCompany: {
        appointmentDetailHeaderColor: 'blue--light',
        appointmentDetailTitleTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.appointment_detail.title`,
        calendarEventTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.calendar_event.title`,
        cssClassName: 'medical-examination-in-company',
        labelTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.label`,
    },
    companyVisit: {
        appointmentDetailHeaderColor: 'yellow',
        appointmentDetailTitleTranslationKey: `${TRANSLATION_PREFIX}.company_visit.appointment_detail.title`,
        calendarEventTranslationKey: `${TRANSLATION_PREFIX}.company_visit.calendar_event.title`,
        listEventTranslationKey: `${TRANSLATION_PREFIX}.company_visit.list_event.title`,
        filterTranslationKey: `${TRANSLATION_PREFIX}.company_visit.filter_label`,
        labelTranslationKey: `${TRANSLATION_PREFIX}.company_visit.label`,
        cssClassName: 'company-visit',
    },
    intervention: {
        appointmentDetailHeaderColor: 'primary--dark',
        appointmentDetailTitleTranslationKey: `${TRANSLATION_PREFIX}.intervention.appointment_detail.title`,
        calendarEventTranslationKey: `${TRANSLATION_PREFIX}.intervention.calendar_event.title`,
        filterTranslationKey: `${TRANSLATION_PREFIX}.intervention.filter_label`,
        labelTranslationKey: `${TRANSLATION_PREFIX}.intervention.label`,
        cssClassName: 'intervention',
    },
    freeTimeslot: {
        // eslint-disable-next-line max-len
        calendarEventTranslationKey: 'interventions.medical_examinations.new.steps.select_timeslot.calendar.free_timeslot_label',
        cssClassName: 'free-time-slot',
    },
    droppableFreeTimeslot: {
        calendarEventTranslationKey: 'interventions.plan_bufferzone.calendar.free_time_slot_label',
        cssClassName: 'droppable-free-time-slot',
    },
    draggableMedicalExamination: getMedicalExaminationConfig({ includeFilterTranslationKey: false }),
};

function getMedicalExaminationConfig({
    includeFilterTranslationKey,
}: {
    includeFilterTranslationKey: boolean;
}): ICalendarEventTypeConfig {
    const filterTranslationKey = includeFilterTranslationKey
        ? `${TRANSLATION_PREFIX}.medical_examination.filter_label`
        : undefined;
    return {
        appointmentDetailHeaderColor: 'tertiary',
        appointmentDetailTitleTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.appointment_detail.title`,
        calendarEventTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.calendar_event.title`,
        filterTranslationKey,
        labelTranslationKey: `${TRANSLATION_PREFIX}.medical_examination.label`,
        cssClassName: 'medical-examination',
        subTypes: [{
            type: CalendarEventType.MedicalExaminationInCompany,
            translationKey: `${TRANSLATION_PREFIX}.medical_examination.sub_filters.in_company_label`,
            cssClassName: 'medical-examination-in-company',
        }],
    };
}
