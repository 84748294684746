import { bffDefaultApiConfig } from '../../../../config/api/bffApiParameters.config';
import { IRemoveTimeSlotApiPayload } from '../../../../models/interventions/timeslots';
import { remove } from '../../../../utils/api/requestWrapper';
import { BFF_URL } from '../timeSlots.const';

export const removeTimeSlot = (payload: IRemoveTimeSlotApiPayload) => {
    const { timeSlotId, activityId, locale } = payload;

    return remove({
        ...bffDefaultApiConfig({ locale }),
        url: BFF_URL.TIMESLOT,
        pathParams: {
            activityId,
            timeSlotId,
        },
    });
};
