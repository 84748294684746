import React, { PureComponent } from 'react';
import './search-content.scss';
import Loader from '../../../common/waiting/Loader';
import Sticky from '../../../common/technical/Sticky';
import * as screenSizeUtils from '../../../../utils/dom/screenSize';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import connect from '../../../../utils/libs/redux/connect';
import { getAppointmentsAsyncInfo, getAppointments } from '../../../../redux/planning/selectors';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { CalendarEventType, ICalendarEvent } from '../../../../models/ui/calendar';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import EventList from '../../../common/widget/Calendar/EventList';
import { getEventTitle } from '../../../common/widget/Calendar/EventTitle';
import getOverlappingEventsCount from '../../../../utils/calendar/getOverlappingEventsCount';
import { ITranslator } from '../../../../models/general/i18n';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import AgendaFilterForm from './AgendaFilterForm';
import SlideOutPanel from '../../../common/widget/SlideOutPanel';
import ListActionButton from '../../../common/buttons/ListActionButton';
import { getQueryParams } from '../../../../redux/location/selectors';
import { IAgendaQueryParams } from '../../../../models/planning/agenda';
import ActiveFilterButton from '../../../common/buttons/ActiveFilterButton';
import {
    getDefaultSearchDates,
} from '../../../../config/calendar.config';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import AgendaExportButton from '../AgendaExportButton';

const CLASS_NAME = 'SearchContent';

interface ISearchContentProps {
    selectedEvent: ICalendarEvent;
    onEventSelected: (event: ICalendarEvent) => void;
}

interface IPrivateProps {
    asyncFetchInfo: IAsyncFieldInfo;
    events: ICalendarEvent[];
    filterPanelOpenOnInitialRender: boolean;
    activeFilters: IAgendaQueryParams;
}

interface IContextProps {
    translator: ITranslator;
}

interface IComponentState {
    eventTypesIncludedInFilter: CalendarEventType[];
    isSmallScreen: boolean;
    calendarEvents: ICalendarEvent[];
    isFilterPanelOpen: boolean;
}

class SearchContent extends PureComponent<ISearchContentProps & IPrivateProps & IContextProps, IComponentState> {
    private onResizeDebounced: TDebounced;

    constructor(props: ISearchContentProps & IPrivateProps & IContextProps) {
        super(props);

        const eventTypesIncludedInFilter = Object.values(CalendarEventType);
        const calendarEvents = this.getEventsForCalendar(eventTypesIncludedInFilter);

        this.state = {
            eventTypesIncludedInFilter,
            isSmallScreen: screenSizeUtils.isSmallScreen(),
            calendarEvents,
            isFilterPanelOpen: props.filterPanelOpenOnInitialRender,
        };

        this.onResize = this.onResize.bind(this);
        this.onEventTypeFilterChange = this.onEventTypeFilterChange.bind(this);
        this.onFilterPanelClose = this.onFilterPanelClose.bind(this);
        this.onFilterPanelOpen = this.onFilterPanelOpen.bind(this);
        this.renderExportButton = this.renderExportButton.bind(this);

        this.onResizeDebounced = debounce(this.onResize, 100);

        window.addEventListener('resize', this.onResizeDebounced);
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                {this.state.isSmallScreen ? (
                    <>
                        <div className={`${CLASS_NAME}__header`}>
                            <div className={`${CLASS_NAME}__filter-buttons`}>
                                <ListActionButton
                                    id="agenda-filter-button"
                                    iconTypeName="sliders"
                                    translationKey="planning.agenda.search.buttons.filter"
                                    onClick={this.onFilterPanelOpen}
                                />
                                <ListActionButton
                                    id="agenda-search-button"
                                    iconTypeName="search"
                                    translationKey="planning.agenda.search.buttons.search"
                                    onClick={this.onFilterPanelOpen}
                                />
                            </div>
                            {this.renderExportButton()}
                        </div>
                        <div className={`${CLASS_NAME}__active-filters`}>
                            {Object.keys(this.props.activeFilters).map((filterKey: keyof IAgendaQueryParams) => {
                                const activeFilter = this.props.activeFilters[filterKey] as string;
                                const translationKey = getTranslationKeyForFilter(filterKey);
                                return (
                                    <ActiveFilterButton
                                        key={`agenda-search-active-filter-${filterKey}`}
                                        disabled={true}
                                        translationKey={`planning.agenda.search.filter.${translationKey}`}
                                        value={filterKey !== 'search'
                                            ? formatDateForDisplay(activeFilter) : activeFilter}
                                    />
                                );
                            })}
                        </div>
                    </>
                ) : (
                        this.renderExportButton()
                    )}
                {this.renderContent()}
            </div>
        );
    }

    public componentDidUpdate(
        prevProps: ISearchContentProps & IPrivateProps & IContextProps,
        prevState: IComponentState,
    ) {
        if (
            prevProps.events !== this.props.events ||
            prevState.eventTypesIncludedInFilter !== this.state.eventTypesIncludedInFilter ||
            prevProps.translator !== this.props.translator
        ) {
            return this.updateEventsDataState();
        }
    }

    public componentWillUnmount() {
        this.onResizeDebounced.cancel();
        window.removeEventListener('resize', this.onResizeDebounced);
    }

    private renderError() {
        return (
            <ErrorPlaceholder apiError={this.props.asyncFetchInfo.error} />
        );
    }

    private renderExportButton() {
        return (
            <div className={`${CLASS_NAME}__export`}>
                <AgendaExportButton />
            </div>
        );
    }

    private renderContent() {
        const { asyncFetchInfo, onEventSelected, selectedEvent } = this.props;
        const { isSmallScreen, eventTypesIncludedInFilter, calendarEvents, isFilterPanelOpen } = this.state;
        const topOffset = isSmallScreen ? 10 : 60;
        return (
            <div className={`${CLASS_NAME}__content`}>
                <Loader show={asyncFetchInfo.status} />
                <div className={`${CLASS_NAME}__content-left`}>
                    {!isSmallScreen && (
                        <Sticky id="SearchContentLeft" topOffset={topOffset}>
                            <AgendaFilterForm
                                calendarEvents={calendarEvents}
                                eventTypesIncludedInFilter={eventTypesIncludedInFilter}
                                onEventTypeFilterChange={this.onEventTypeFilterChange}
                            />
                        </Sticky>
                    )}
                </div>
                <div className={`${CLASS_NAME}__content-right`}>
                    {
                        asyncFetchInfo.error
                            ? this.renderError()
                            : (
                                <EventList
                                    events={calendarEvents}
                                    renderAllEvents={true}
                                    renderYear={true}
                                    onSelectEvent={onEventSelected}
                                    selected={selectedEvent}
                                />
                            )
                    }
                </div>
                {isSmallScreen && (
                    <SlideOutPanel
                        isOpen={isFilterPanelOpen}
                        onCloseIntent={this.onFilterPanelClose}
                    >
                        <AgendaFilterForm
                            calendarEvents={calendarEvents}
                            eventTypesIncludedInFilter={eventTypesIncludedInFilter}
                            onEventTypeFilterChange={this.onEventTypeFilterChange}
                            onSearchTriggered={this.onFilterPanelClose}
                            hidePreviousButton={true}
                        />
                    </SlideOutPanel>
                )}
            </div>
        );
    }

    private onEventTypeFilterChange(newSelectedEventTypes: CalendarEventType[]) {
        this.setState({
            eventTypesIncludedInFilter: newSelectedEventTypes,
        });
    }

    private onResize() {
        this.setState({
            isSmallScreen: screenSizeUtils.isSmallScreen(),
        });
    }

    private updateEventsDataState() {
        const calendarEvents = this.getEventsForCalendar(this.state.eventTypesIncludedInFilter);

        this.setState({
            calendarEvents,
        });
    }

    private getEventsForCalendar(eventTypesIncludedInFilter: CalendarEventType[]) {
        const { events, translator } = this.props;
        const filteredEvents = events.filter((event) => eventTypesIncludedInFilter.includes(event.type));
        return filteredEvents.map((event) => ({
            ...event,
            title: getEventTitle({ event, translator, titleType: 'calendar-event' }),
            overlappingEventsCount: getOverlappingEventsCount(event, filteredEvents),
        }));
    }

    private onFilterPanelOpen() {
        this.setState({
            isFilterPanelOpen: true,
        });
    }

    private onFilterPanelClose() {
        this.setState({
            isFilterPanelOpen: false,
        });
    }
}

const SearchContentWithState = connect<IPrivateProps, ISearchContentProps & IContextProps>({
    stateProps: (state) => {
        const { search: searchValue } = getQueryParams<IAgendaQueryParams>(state);
        const queryParams = getQueryParams<IAgendaQueryParams>(state);
        const { defaultSearchFrom, defaultSearchUntil } = getDefaultSearchDates(queryParams.selectedDate);
        return {
            asyncFetchInfo: getAppointmentsAsyncInfo(state),
            events: getAppointments(state),
            filterPanelOpenOnInitialRender: !searchValue,
            activeFilters: {
                search: queryParams.search,
                startDate: queryParams.startDate || defaultSearchFrom,
                endDate: queryParams.endDate || defaultSearchUntil,
            },
        };
    },
})(SearchContent);

export default function SearchContentWithContext(publicProps: ISearchContentProps) {
    return (
        <TranslatorContext.Consumer>
            {(props) => <SearchContentWithState {...publicProps} translator={props.translator} />}
        </TranslatorContext.Consumer>
    );
}

function getTranslationKeyForFilter(filterKey: keyof IAgendaQueryParams) {
    if (filterKey === 'endDate') {
        return 'period_to';
    }
    if (filterKey === 'startDate') {
        return 'period_from';
    }
    return 'keyword';
}
