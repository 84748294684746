import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

import { AsyncStatus } from '../../../models/general/redux';
import { EXAMINATION_REASON_CODE } from '../../../config/navigation/interventions.config';
import { formatPersonName } from '../../../utils/formatting/formatPerson';
import {
    IBufferzonePlannedMedicalExamination,
    IMedicalExaminationToAdd,
    IPlannedTimeSlot,
} from '../../../models/interventions/medicalExaminations';
import { IPlanBufferzoneWizardData } from '../../../models/interventions/bufferzones';
import {
    mapBufferzoneFreeTimeslotsToCalendarEvents,
    mapPlannedMedicalExaminationsToCalendarEvents,
} from '../../../views/interventions/PlanBufferzoneWizard/wizard/PlanBufferzone/PlanBufferzone.mapper';
import { stringComparerAscending } from '../../../utils/list/comparerUtils';
import {
    getActiveExaminationReasons,
    getEmployeesToPlan,
    getExaminationReasons,
} from '../../medicalExamination/selectors';
import { getReducerState, IState } from '../../IState';
import { ITimeSlotBase } from '../../../models/interventions/timeslots';
import { makeAsyncDoInfoSelector, makeAsyncFetchInfoSelector, NO_RERENDER } from '../../index';

import { IReducerState, reducerKey } from './reducer';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Plan bufferzone wizard
const getPlanBufferzoneWizardData = (state: IState) =>
    (reducerState(state).planBufferzoneWizardData || NO_RERENDER.EMPTY_OBJECT) as IPlanBufferzoneWizardData;

export const getPlanBufferzoneWizardStepId = (state: IState) =>
    getPlanBufferzoneWizardData(state).stepId;

export const getPlanBufferzoneWizardEntity = (state: IState) =>
    getPlanBufferzoneWizardData(state).entity;

export const getPlanBufferzoneSelectedBufferzone = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);
    if (entity) {
        return entity.selectedBufferzone;
    }

    return null;
};

export const getPlanBufferzoneModifiedTimeCellIds = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);
    return entity && entity.modifiedTimeCellIds || NO_RERENDER.EMPTY_LIST;
};

export const getPlanBufferzoneWizardEntityEmployeesToPlan = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);

    if (entity) {
        return entity.employeesToPlan || NO_RERENDER.EMPTY_LIST;
    }
    return NO_RERENDER.EMPTY_LIST;
};

export const getAddedEmployees = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);

    if (entity) {
        return entity.addedEmployees || NO_RERENDER.EMPTY_LIST;
    }
    return NO_RERENDER.EMPTY_LIST;
};

export const getPlanBufferzoneEmployeesToPlanCombinedWithAddedEmployees = createSelector(
    getEmployeesToPlan,
    getAddedEmployees,
    (employeesToPlan, addedEmployees) => employeesToPlan.concat(addedEmployees),
);

// Fetch Bufferzone Timeslots
const getBufferzoneTimeslotsAsyncField = (state: IState) =>
    reducerState(state).bufferzoneTimeslots;

export const getBufferzoneTimeslotsAsyncInfo = makeAsyncFetchInfoSelector(getBufferzoneTimeslotsAsyncField);

export const getBufferzoneTimeslots = (state: IState) =>
    getBufferzoneTimeslotsAsyncField(state).data;

export const getBufferzoneFreeTimeslots = (state: IState): ITimeSlotBase[] => {
    const timeslots = getBufferzoneTimeslots(state);

    return pathOr<ITimeSlotBase[]>(
        NO_RERENDER.EMPTY_LIST,
        ['freeTimeslots'],
        timeslots,
    );
};

export const getBufferzonePlannedTimeslots = (state: IState): IPlannedTimeSlot[] => {
    const timeslots = getBufferzoneTimeslots(state);

    return pathOr<IPlannedTimeSlot[]>(
        NO_RERENDER.EMPTY_LIST,
        ['plannedExaminations'],
        timeslots,
    );
};

export const getBufferzoneTotalTimeslots = (state: IState): number => {
    const timeslots = getBufferzoneTimeslots(state);

    return pathOr<number>(
        0,
        ['totalTimeslots'],
        timeslots,
    );
};

// Bufferzone planning actions
const getPlanBufferzoneCreateNewPlanningAsyncField = (state: IState) =>
    reducerState(state).createNewPlanning;

export const getPlanBufferzoneCreateNewPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneCreateNewPlanningAsyncField);

const getPlanBufferzoneMoveExistingPlanningAsyncField = (state: IState) =>
    reducerState(state).moveExistingPlanning;

export const getPlanBufferzoneMoveExistingPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneMoveExistingPlanningAsyncField);

const getPlanBufferzoneCancelExistingPlanningAsyncField = (state: IState) =>
    reducerState(state).cancelExistingPlanning;

export const getPlanBufferzoneCancelExistingPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneCancelExistingPlanningAsyncField);

export const getBufferzonePlanningStepAsyncInfo = (state: IState) => {
    const fetchBufferzoneTimeslotsAsyncInfo = getBufferzoneTimeslotsAsyncInfo(state);
    const cancelPlanningAsyncInfo = getPlanBufferzoneCancelExistingPlanningAsyncInfo(state);
    const movePlanningAsyncInfo = getPlanBufferzoneMoveExistingPlanningAsyncInfo(state);
    const createPlanningAsyncInfo = getPlanBufferzoneCreateNewPlanningAsyncInfo(state);

    // Errors
    if (fetchBufferzoneTimeslotsAsyncInfo.status === AsyncStatus.Error) {
        return fetchBufferzoneTimeslotsAsyncInfo;
    }
    if (cancelPlanningAsyncInfo.status === AsyncStatus.Error) {
        return cancelPlanningAsyncInfo;
    }
    if (movePlanningAsyncInfo.status === AsyncStatus.Error) {
        return movePlanningAsyncInfo;
    }
    if (createPlanningAsyncInfo.status === AsyncStatus.Error) {
        return createPlanningAsyncInfo;
    }

    // Busy
    if (fetchBufferzoneTimeslotsAsyncInfo.status === AsyncStatus.Busy) {
        return fetchBufferzoneTimeslotsAsyncInfo;
    }
    if (cancelPlanningAsyncInfo.status === AsyncStatus.Busy) {
        return cancelPlanningAsyncInfo;
    }
    if (movePlanningAsyncInfo.status === AsyncStatus.Busy) {
        return movePlanningAsyncInfo;
    }
    return createPlanningAsyncInfo;
};

export const getPlanBufferzoneExaminationReasons = createSelector(
    getActiveExaminationReasons,
    (reasons) => reasons.filter((reason) => reason.code !== EXAMINATION_REASON_CODE.RE_INTEGRATION),
);

export const getPlanBufferzoneExaminationReasonsForSelectedBufferzone = createSelector(
    getPlanBufferzoneExaminationReasons,
    getPlanBufferzoneSelectedBufferzone,
    (potentialReasons, selectedBufferzone) => {
        const allowedExaminationReasons = pathOr([], ['allowedExaminationReasons'], selectedBufferzone);

        // no allowed examinations configured on the bufferzone,
        // so all reasons (without the already filtered ones like RE_INTEGRATION) are allowed.
        if (allowedExaminationReasons.length === 0) {
            return potentialReasons;
        }

        const allowedExaminationCodesOnBufferzone = allowedExaminationReasons
            .map((allowedExaminationReason) => allowedExaminationReason.code);

        return potentialReasons
            .filter((potentialReason) => allowedExaminationCodesOnBufferzone.includes(potentialReason.code));
    },
);

export const getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized = createSelector(
    getPlanBufferzoneWizardEntity,
    getExaminationReasons,
    getBufferzonePlannedTimeslots,
    (entity, examinationReasons, plannedTimeslots) => {
        const employeesToPlan: IMedicalExaminationToAdd[] = pathOr<IMedicalExaminationToAdd[]>(
            [],
            ['employeesToPlan'],
            entity,
        );

        if (!employeesToPlan) {
            return NO_RERENDER.EMPTY_LIST;
        }

        // Step 1: Get modified planned time slots
        const modifiedPlannedTimeslots = plannedTimeslots.filter((exam: IBufferzonePlannedMedicalExamination) => {
            return entity.modifiedTimeCellIds && entity.modifiedTimeCellIds.includes(exam.timeSlotId);
        });


        // Step 2: Get examination code for every employee to plan
        const examinationReasonCodesFromEmployees = employeesToPlan.reduce((codes, currentEmployee) => {
            const employeeExaminationReason = examinationReasons.find(
                (reason) => reason.code === currentEmployee.examinationReason.code,
            );

            if (employeeExaminationReason) {
                codes.push(employeeExaminationReason.code);
            }

            return codes;
        }, []);

        // Step 3: Add unique examination reasons from modified planned time slots
        const examinationReasonsToIncludeInLegend = modifiedPlannedTimeslots.reduce(
            (codes, exam) => {
                if (!codes.includes(exam.examinationReason.code)) {
                    codes.push((exam.examinationReason.code));
                }
                return codes;
            },
            examinationReasonCodesFromEmployees,
        );

        return examinationReasons.filter((reason) => examinationReasonsToIncludeInLegend.includes(reason.code));
    },
);

export const getMergedCalendarEventsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getBufferzoneFreeTimeslots,
    (examinations, timeslots) => [
        ...mapPlannedMedicalExaminationsToCalendarEvents(examinations),
        ...mapBufferzoneFreeTimeslotsToCalendarEvents(timeslots),
    ],
);

export const getEmployeesToPlanSortedMemoizedSelector = createSelector(
    getPlanBufferzoneWizardEntityEmployeesToPlan,
    (employeesToPlan) => employeesToPlan.sort(
        (a, b) => {
            const comparedByEximationReason = stringComparerAscending(
                a.examinationReason.title,
                b.examinationReason.title,
            );
            if (comparedByEximationReason !== 0) {
                return comparedByEximationReason;
            }

            return stringComparerAscending(
                formatPersonName(a.employee).toLowerCase(),
                formatPersonName(b.employee).toLowerCase(),
            );
        },
    ),
);
