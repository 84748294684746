import { Locales } from '../../config/i18n.config';
import { ICompany } from '../admin/company';
import { IEmployee, IEmployeeDetails } from '../admin/employee';
import { ILocalePayload } from '../general/i18n';

import { IExaminationReason, IPlannedTimeSlot } from './medicalExaminations';

interface ITimeslotEmployee {
    id: number;
    employeeId: number;
}

export interface ITimeSlotBase {
    id: number;
    start: string;
    end: string;
    isPlannable: boolean;
}

export interface IAddTimeSlotBasePayload {
    remarks: string;
    timeSlotId: number;
    activityId: number;
}

export interface IAddTimeSlotPayload extends IAddTimeSlotBasePayload {
    employmentId: number;
    examinationReasonCode: string;
    planningRequestMSId?: never;
}

export interface IAddTimeSlotApiPayload extends IAddTimeSlotPayload {
    locale: Locales;
}

export interface IAddTimeSlotByPlanningRequestIdPayload extends IAddTimeSlotBasePayload {
    planningRequestMSId: number;
    employmentId?: never;
    examinationReasonCode?: never;
}

export type TAddTimeSlotPayload = IAddTimeSlotPayload | IAddTimeSlotByPlanningRequestIdPayload;

export interface IAddTimeSlotByPlanningRequestIdApiPayload extends IAddTimeSlotByPlanningRequestIdPayload {
    locale: Locales;
}

export interface IAddTimeslotSuccessPayload {
    timeSlotId: number;
}

interface IUpdateTimeslotBasePayload extends
    Pick<IAddTimeSlotPayload, 'timeSlotId' | 'activityId' | 'remarks'> {}

// TODO: Enable replace employee update time slot : fix payload after api rework to Mensura KZ BFF
export interface IReplaceEmployeeUpdateTimeslotPayload extends IUpdateTimeslotBasePayload {
    employee: ITimeslotEmployee;
    planningEntityId: number;
    planningRequestId: number; // of the new employee if he/she has a planning record
}

export interface IMoveUpdateTimeslotPayload extends IUpdateTimeslotBasePayload {
    newTimeSlot: {
        activityId: number;
        id: number;
    }
}

export type TUpdateTimeSlotPayload = IReplaceEmployeeUpdateTimeslotPayload | IMoveUpdateTimeslotPayload;
export type TUpdateTimeSlotApiPayload = IMoveUpdateTimeslotPayload & ILocalePayload;

export interface IParseTimeSlotToJsonPatchPayload {
    timeSlotId?: number;
    activityId?: number;
    comment?: string;
}

export interface IUpdateTimeSlotSuccessPayload {
    timeSlotId: number;
}

export interface IRemoveTimeSlotPayload {
    activityId: number;
    timeSlotId: number;
}

export interface IRemoveTimeSlotApiPayload extends IRemoveTimeSlotPayload, ILocalePayload {}

export interface IAutoPlanEmployeePayload {
    company: Pick<ICompany, 'companyCode'>;
    employee: Pick<IEmployee, 'id'>;
    startDate: string;
    startTime: string;
    endTime: string;
    planningRequestId?: number;
    oldTimeCellId?: number;
    locationId?: number;
    examinationReason: Pick<IExaminationReason, 'id'>;
}

export interface IAutoPlanEmployeeSuccessResponse {
    employee: IEmployeeDetails;
    examinationReason: {
        id: number;
        code: string;
    };
    location: {
        id: number;
        code: string;
        name: string;
    };
    timeSlot: {
        start: string;
        end: string;
        id: number;
        activityId: number;
    };
    startDate?: string;
    startTime?: string;
    endTime?: string;
    planningRequestId?: number;
}

export interface IAutoPlanEmployeeFailedResponse {
    employee: IEmployeeDetails;
    errorMessage: string;
    planningRequestId?: number;
    startDate?: string;
    startTime?: string;
    endTime?: string;
}

export type TAutoPlanEmployeeResponse = IAutoPlanEmployeeSuccessResponse | IAutoPlanEmployeeFailedResponse;

export interface IAutoPlanEmployeeApiSuccessResponse {
    employee: IEmployeeDetails;
    location: {
        id: number;
        code: string;
        name: string;
    };
    activityId: number;
    start: string;
    end: string;
    examinationReason: {
        id: number;
        code: string;
    };
    planningRequestId?: number;
    timeSlotId: number;
}

export interface IAutoPlanEmployeeApiFailedResponse {
    employee: IEmployeeDetails;
    errorMessage: string;
    errorTrace: string;
    planningRequestId?: number;
    moreInfo: string[];
}

export type TAutoPlanEmployeeApiResponse = IAutoPlanEmployeeApiFailedResponse | IAutoPlanEmployeeApiSuccessResponse;

export interface IAutoPlanApiResponse {
    count: number;
    plannedEmployees: TAutoPlanEmployeeApiResponse[];
}

// Drag and Drop Config
export enum DraggablePrefix {
    Employee = 'draggable-employee',
    PlannedExamination = 'draggable-plannedexamination',
}

export enum DroppablePrefix {
    Employees = 'droppable-employees',
    FreeTimeslot = 'droppable-freetimeslot',
    PlannedExamination = 'droppable-plannedexamination',
}

export interface IDroppableFreeTimeslotData {
    timeCell: ITimeSlotBase;
    minutesAvailableAfterTimeslot: number;
    amountOfFreeTimeslotsWithSameTime: number;
}

export interface IBufferzoneTimeslots {
    freeTimeslots: ITimeSlotBase[];
    plannedExaminations: IPlannedTimeSlot[];
    totalTimeslots: number;
}
