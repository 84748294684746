import React from 'react';
import './privacy-policy-modal.scss';
import PrivacyPolicyContent from '../PrivacyPolicyContent';
import { connect } from '../../..';
import Dialog from '../../../common/modals/Dialog';
import { getQueryParams, getRouteKey, getRoutePayload } from '../../../../redux/location/selectors';
import { PRIVACY_POLICY_QUERY_PARAM } from '../routes';
import { navigateTo } from '../../../../redux/location/actions';
import { getPrivacyPolicyContentAsyncInfo } from '../../../../redux/legal/selectors';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';
import { IAsyncFieldInfo } from '../../../../models/general/redux';

const CLASS_NAME = 'PrivacyModal';

interface IPrivateProps {
    isShown: boolean;
    onCloseIntent: () => void;
    privacyPolicyContentAsyncInfo: IAsyncFieldInfo;
}

function PrivacyModal({ isShown, onCloseIntent, privacyPolicyContentAsyncInfo }: IPrivateProps) {
    return (
        <Dialog
            className={CLASS_NAME}
            show={isShown}
            onCloseIntent={onCloseIntent}
            size="large"
            showLoader={privacyPolicyContentAsyncInfo.status === AsyncStatus.Busy}
            showScrollToTopOfModalButton={true}
        >
            {(isShown || privacyPolicyContentAsyncInfo.status === AsyncStatus.Success) && (
                <PrivacyPolicyContent />
            )}
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        isShown: Object.keys(getQueryParams(state)).includes(PRIVACY_POLICY_QUERY_PARAM),
        privacyPolicyContentAsyncInfo: getPrivacyPolicyContentAsyncInfo(state),
    }),
    dispatchProps: (dispatch, getState) => ({
        onCloseIntent: (() => {
            const state = getState();
            const query = getQueryParams(state) as { [key: string]: any };
            dispatch(navigateTo(
                getRouteKey(state),
                getRoutePayload(state),
                Object.keys(query)
                    .reduce(
                        (acc, currentKey) => {
                            if (currentKey === PRIVACY_POLICY_QUERY_PARAM) {
                                return acc;
                            }

                            return {
                                ...acc,
                                [currentKey]: query[currentKey],
                            };
                        },
                        {},
                    ),
            ));
        }),
    }),
})(PrivacyModal);
