import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, TDropdownItemValue } from '../';
import Translate from '../../../Translate';
import { IDropdownItem } from '../DropdownItem';

interface IYearDropdownProps {
    id: string;
    value: string;
    onItemSelected: (value: TDropdownItemValue) => void;
    min: number;
    max: number;
    size?: 'small';
}

interface IState {
    isOpen: boolean;
}

export default class YearDropdown extends PureComponent<IYearDropdownProps, IState>{
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.onDropdownToggle = this.onDropdownToggle.bind(this);
    }

    public render() {
        const { value, onItemSelected, id, min, max, size } = this.props;
        const { isOpen } = this.state;

        const YEAR_ITEMS: IDropdownItem[] = [];

        for (let i = max; i >= min; i = i - 1) {
            YEAR_ITEMS.push({
                label: i.toString(),
                value: i.toString(),
            });
        }

        return (
            <Dropdown
                id={id}
                isOpen={isOpen}
                onOpenIntent={this.onDropdownToggle}
                onCloseIntent={this.onDropdownToggle}
                onItemSelected={onItemSelected}
                selectedItem={value}
                defaultLabel={<Translate msg="common.dropdown.year.placeholder"/>}
                size={size}
                iconTypeName="primary"
            >
                {YEAR_ITEMS.map((item: IDropdownItem) =>
                    (
                        <DropdownItem
                            key={`dropdown-item-${item.value}`}
                            value={item.value}
                        >
                            <Translate msg={item.label} />
                        </DropdownItem>
                    ),
                )}
            </Dropdown>
        );
    }

    private onDropdownToggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
