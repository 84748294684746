import { post } from '../../utils/api/requestWrapper';
import { IRequestReIntegrationPayload } from '../../models/interventions/medicalExaminations';

export const URL = {
    RE_INTEGRATION_REQUEST: '/employees/{id}/reintegration-examination',
};

export function createReIntegrationRequest(id: number, body: IRequestReIntegrationPayload) {
    return post({
        url: URL.RE_INTEGRATION_REQUEST,
        body,
        pathParams: {
            id,
        },
    });
}
