import { equals, eqProps } from 'ramda';
import isArray from '@snipsonian/core/es/is/isArray';
import isObjectPure from '@snipsonian/core/es/is/isObjectPure';

import { hasProps } from './objectProps';

export function areObjectsEqual(obj = {}, otherObj = {}) {
    return equals(obj, otherObj);
}

export function areObjectParamsEqual<ParamNames = string>(obj = {}, otherObj = {}, paramsToCheck: ParamNames[]) {
    return paramsToCheck
        .every((param) => eqProps(param, obj, otherObj));
}

/**
 * Return true if all props of the master object are included in the other object and have the same value.
 * It does not matter if the other object has more props that the master object.
 */
export function areAllMasterParamsEqualInOtherObject(master: object, other: object) {
    const masterParams = Object.keys(master);
    return areObjectParamsEqual(master, other, masterParams);
}

export function compareObjectsAndReturnParamsWithDifferentValuesThanOtherObj(
    props: {
        obj: {},
        otherObj: {},
        paramsToIgnore?: string[],
        recursive?: boolean, // internal use only
    },
) {
    const { obj, recursive, paramsToIgnore } = props;

    const objKeys = Object.keys(obj);
    const keysWithDifferentValue: string[] = objKeys
        .filter((key) => onlyKeepParamsThatAreNotInParamsToIgnore(key, paramsToIgnore))
        .filter((key) => keepAllParamsThatDoNotExistOnOtherObjOrAreDifferentFromOtherObj(key, props));

    if (recursive) {
        return keysWithDifferentValue.length > 0;
    }

    return mapParamsWithDifferentValueToNewObject(obj, keysWithDifferentValue);
}

function onlyKeepParamsThatAreNotInParamsToIgnore(key: string, paramsToIgnore: string[]) {
    return paramsToIgnore ? !paramsToIgnore.includes(key) : true;
}

function keepAllParamsThatDoNotExistOnOtherObjOrAreDifferentFromOtherObj(
    key: string,
    props: {
        obj: {},
        otherObj: {},
        recursive?: boolean,
    },
) {
    const { obj, otherObj } = props;

    if (typeof obj[key] === 'object' && typeof otherObj[key] === 'object') {
        return compareObjectsAndReturnParamsWithDifferentValuesThanOtherObj({
            obj: obj[key],
            otherObj: otherObj[key],
            recursive: true,
        });
    }

    if (!otherObj.hasOwnProperty(key)) {
        return true;
    }

    return obj[key] !== otherObj[key];
}

function mapParamsWithDifferentValueToNewObject(
    obj: {},
    keysWithDifferentValue: string[],
) {
    const newObj = new Object();

    keysWithDifferentValue.forEach((key) => newObj[key] = obj[key]);

    return newObj;
}

/**
 * Only returns the (nested) props of the master object that:
 * - either are not found in the other object
 * - OR have a different value than the equivalent prop in the other object
 */
export function diffPropValuesDeep({
    master,
    other,
    propNamesToIgnore = [],
    ignoreArrays = false,
}: {
    master: object;
    other: object;
    propNamesToIgnore?: string[];
    ignoreArrays?: boolean;
}): object {
    return Object.keys(master)
        .reduce(
            (diffAccumulator, masterKey) => {
                if (propNamesToIgnore && propNamesToIgnore.indexOf(masterKey) > -1) {
                    return diffAccumulator;
                }

                const masterValue = master[masterKey];
                const otherValue = other[masterKey];

                if (!other.hasOwnProperty(masterKey)) {
                    diffAccumulator[masterKey] = masterValue;
                } else if (isObjectPure(masterValue) && isObjectPure(otherValue)) {
                    const nestedDiffs = diffPropValuesDeep({
                        master: masterValue,
                        other: otherValue,
                        propNamesToIgnore,
                        ignoreArrays,
                    });
                    if (hasProps(nestedDiffs)) {
                        diffAccumulator[masterKey] = nestedDiffs;
                    }
                } else if (isArray(masterValue) && isArray(otherValue)) {
                    if (!ignoreArrays && !equals(masterValue, otherValue)) {
                        diffAccumulator[masterKey] = masterValue;
                    }
                } else if (masterValue !== other[masterKey]) {
                    diffAccumulator[masterKey] = masterValue;
                }

                return diffAccumulator;
            },
            {},
        );
}
