import React, { PureComponent } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { ICMSCourseAddress, ICourseSessionByLocation } from '../../../../../../models/documentCenter/courses';
import { IFormRenderProps } from '../../../../../common/forms/Form';
import { connect } from '../../../../../index';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import { fields } from './courseInputSchema';
import CourseLocationTypeahead from '../../../shared/CourseLocationTypeahead';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import { fetchCourseSessionsByLocationActions } from '../../../../../../redux/documentCenter/courses/actions';
import { getCourseEnrollWizardCourse } from '../../../../../../redux/documentCenter/courses/selectors';
import CourseSessionsTypeahead from '../../../shared/CourseSessionsTypeahead';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../common/Translate';
import RequiredMarker from '../../../../../common/input/RequiredMarker';
import { COURSE_REGISTRATION_DUE_DATE } from '../../../../../../config/courses.config';
import { PassedRegistrationDueDateDialog } from '../../../shared/PassedRegistrationDueDateDialog';
export { schema } from './courseInputSchema';

const CLASS_NAME = 'CourseEnrollment__CourseInput';
const TRANSLATION_PREFIX = 'document_center.courses.new.steps.course';

export interface IFormValues {
    location: ICMSCourseAddress;
    session?: ICourseSessionByLocation;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues) => void;
}

interface IPrivateProps {
    fetchCourseSessionsByLocation: (locationId: number) => void;
}

interface IState {
    isPassedRegistrationDueDateDialogOpen: boolean;
}

class CourseInput extends PureComponent<IPublicProps & IPrivateProps, IState> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            isPassedRegistrationDueDateDialogOpen: false,
        };

        this.onChangeLocationHandler = this.onChangeLocationHandler.bind(this);
        this.onChangeSessionHandler = this.onChangeSessionHandler.bind(this);
        this.onClosePassedRegistrationDueDateDialog = this.onClosePassedRegistrationDueDateDialog.bind(this);
    }

    public render() {
        const { formRenderProps } = this.props;
        const { isPassedRegistrationDueDateDialogOpen } = this.state;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => {
                    const selectLocationPlaceholderKey = `${TRANSLATION_PREFIX}.fields.location`;
                    const selectSessionPlaceholderKey = `${TRANSLATION_PREFIX}.fields.session`;

                    return (
                        <div className={classNames(CLASS_NAME, WIZARDFLOW_CLASSES.NARROW_FORM)}>
                            <PassedRegistrationDueDateDialog
                                open={isPassedRegistrationDueDateDialogOpen}
                                onClose={this.onClosePassedRegistrationDueDateDialog}
                            />
                            <FloatableTextInputWrapper floatLabel>
                                <CourseLocationTypeahead
                                    id="edit-location"
                                    name={fields.location}
                                    value={
                                        formRenderProps.values.location ?
                                            formRenderProps.values.location.id : null}
                                    onItemSelected={this.onChangeLocationHandler}
                                    isInvalid={formRenderProps.touched.location && !!formRenderProps.errors.location}
                                    placeholderTranslationKey={selectLocationPlaceholderKey}
                                >
                                    <label htmlFor="edit-location">
                                        <Translate
                                            msg={selectLocationPlaceholderKey}
                                        />
                                        <RequiredMarker />
                                    </label>
                                </CourseLocationTypeahead>
                                {formRenderProps.touched.location && (
                                    <FormFieldError
                                        error={formRenderProps.errors.location}
                                        placeholders={{ fieldName: translator(selectLocationPlaceholderKey) }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper floatLabel>
                                <CourseSessionsTypeahead
                                    id="edit-session"
                                    name={fields.session}
                                    disabled={!formRenderProps.values.location}
                                    value={
                                        formRenderProps.values.session ?
                                            formRenderProps.values.session.coursesOrganizedId : null}
                                    onItemSelected={this.onChangeSessionHandler}
                                    isInvalid={formRenderProps.touched.session && !!formRenderProps.errors.session}
                                    placeholderTranslationKey={selectSessionPlaceholderKey}
                                >
                                    <label htmlFor="edit-session">
                                        <Translate
                                            msg={selectSessionPlaceholderKey}
                                        />
                                        <RequiredMarker />
                                    </label>
                                </CourseSessionsTypeahead>
                                {formRenderProps.touched.session && (
                                    <FormFieldError
                                        error={formRenderProps.errors.session}
                                        placeholders={{ fieldName: translator(selectSessionPlaceholderKey) }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                        </div>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }

    private onChangeLocationHandler(id: number, location: ICMSCourseAddress) {
        this.props.onChangeInput({
            location,
        });

        if (id) {
            // When refreshing page, or deeplinking without sessions available but with a location selected,
            // the sessions are fetched in the epics.
            this.props.fetchCourseSessionsByLocation(id);
        }
    }

    private onClosePassedRegistrationDueDateDialog() {
        this.setState({ isPassedRegistrationDueDateDialogOpen: false });
    }

    private onChangeSessionHandler(id: number, session: ICourseSessionByLocation) {
        const { location } = this.props.formRenderProps.values;

        if (session && dayjs(session.start).isBefore(COURSE_REGISTRATION_DUE_DATE)) {
            this.setState({ isPassedRegistrationDueDateDialogOpen: true });
            return;
        }

        this.props.onChangeInput({
            location,
            session,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    dispatchProps: (dispatch, getState) => ({
        fetchCourseSessionsByLocation: (locationId: number) => {
            const wizardCourse = getCourseEnrollWizardCourse(getState());
            dispatch(fetchCourseSessionsByLocationActions.trigger({
                id: wizardCourse.id,
                locationId,
            }));
        },
    }),
})(CourseInput);
