import { pathOr } from 'ramda';

import { DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS } from '../../../../../api/interventions/medicalExaminations.api';
import { doExportPlannedExaminationsCall } from '../../../../../redux/medicalExamination/exportExaminationsHelper';
import { formatPersonNameFormal } from '../../../../../utils/formatting/formatPerson';
import { formatLocationAddress } from '../../../../../utils/formatting/formatAddress';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { getXlsxFilename } from '../../../../../utils/file/csv/exportListDataToXlsx';
import { getStore } from '../../../../../redux/storeNoCircularDependencies';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/planned';
import { IPlannedTimeSlot } from '../../../../../models/interventions/medicalExaminations';
import { ListItem } from '../../../../../models/general/list';
import { MAX_NR_OF_DOCUMENT_DOWNLOADS } from '../../../../../config/general.config';
import { separateStringList } from '../../../../../utils/core/string/separatedStringList';
import api from '../../../../../api';
import { createGenericActiveFilters } from '../../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    IClientSideFilterOfListDataProps,
    ITransformToActiveFiltersProps,
    IFetchAllExportDataProps,
    IShouldRenderShowAllButtonProps,
} from '../../../../common/widget/MasterWithDetail/typings';

import { IColumnNames, IFilterValues } from './PlannedMedicalExaminations.type';
import {
    DEFAULT_NR_OF_RECORDS_TO_SHOW,
    EXPORT_BASE_FILENAME,
    TRANSLATION_PREFIX,
} from './PlannedMedicalExaminations.const';

export const mapMedicalExaminationsToListItems = (
    masterData: IPlannedMedicalExamination[],
): ListItem<IColumnNames, number>[] => {
    return masterData
        .map((examination) => {
            const locationAddress = formatLocationAddress(examination.location, true);

            return {
            id: examination.timeSlotId,
            columns: {
                employee: formatPersonNameFormal({
                    firstName: examination.employee.firstName || '',
                    name: examination.employee.lastName || '',
                }),
                examinationReason: examination.examinationReason.description,
                employeeCustomerId: examination.employee.id,
                examinationReasonId: examination.examinationReason.code,
                employmentId: examination.employee.employment.id,
                planningRequestId: examination.planningRequestId,
                location: locationAddress,
                locationId: locationAddress,
                day: formatDateInLongFormat(examination.start),
                daySort: examination.start,
                hour: formatTimeOfDateForDisplay(examination.start),
                actions: null,
            },
        };
    });
};

export function mapPlannedMedicalExaminationForExport(
    medicalExamination: IPlannedMedicalExamination | IPlannedTimeSlot,
) {
    const {
        employee = {},
        location = {},
        examinationReason = {},
        start = '',
        doctor = {},
    } = medicalExamination;

    let doctorFullName = '';

    const doctorFirstName = pathOr('', ['firstName'], doctor);
    const doctorLastName = pathOr('', ['lastName'], doctor);

    if (doctorFirstName.trim().length && doctorLastName.trim().length) {
        doctorFullName = formatPersonName({
            firstName: doctorFirstName,
            name: doctorLastName,
        });
    }

    return {
        company: {
            companyCode: pathOr('', ['employment', 'branch', 'code'], employee),
            name: pathOr('', ['employment', 'branch', 'name'], employee),
        },
        dateTimeStart: start,
        doctor: doctorFullName,
        examinationReason: {
            title: pathOr('', ['description'], examinationReason),
        },
        firstName: pathOr('', ['firstName'], employee),
        medicalCenter: {
            address: {
                city: pathOr('', ['address', 'city'], location),
                postcode: pathOr('', ['address', 'zipCode'], location),
                street: pathOr('', ['address', 'street'], location),
            },
            name: pathOr('', ['name'], location),
        },
        name: pathOr('', ['lastName'], employee),
        time: formatTimeOfDateForDisplay(start),
        toPerformMedicalExaminations: pathOr('', ['description'], examinationReason),
    };
}


export const getMaxNrSelected = (filterValues: IFilterValues) => {
    return filterValues.isShowAll
        ? MAX_NR_OF_DOCUMENT_DOWNLOADS
        : Math.min(MAX_NR_OF_DOCUMENT_DOWNLOADS, DEFAULT_NR_OF_RECORDS_TO_SHOW);
};

export const clientSideFilterOfListData = (
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) => {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.locationIds)) {
                return true;
            }

            const locationIds = separateStringList(filterValues.locationIds);
            return locationIds.includes(listItem.columns.locationId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.reasonIds)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.reasonIds);
            return reasonIds.includes(listItem.columns.examinationReasonId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.startHour)) {
                return true;
            }

            return listItem.columns.hour >= filterValues.startHour;
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.endHour)) {
                return true;
            }

            return listItem.columns.hour <= filterValues.endHour;
        });
};

export const getDefaultQueryParams = ({ isShowAll }: { isShowAll: boolean }) => {
    return isShowAll ? {
        ...DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS;
};

export const transformFilterValuesToActiveFilters = (
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) => {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            endHour: {
                show: true,
            },
            startHour: {
                show: true,
            },
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
            },
            locationIds: {
                show: true,
                translationKeySuffixOverride: 'location',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'location',
                        searchColumnName: 'locationId',
                    },
                },
            },
            reasonIds: {
                show: true,
                translationKeySuffixOverride: 'reason',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'examinationReason',
                        searchColumnName: 'examinationReasonId',
                    },
                },
            },
        },
    });
};

export const shouldRenderShowAllButton = (
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) => {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
};

export async function fetchAllExportDataApiCall(fetchProps: IFetchAllExportDataProps<IFilterValues>) {
    const document = await doExportPlannedExaminationsCall({
        state: getStore().getState(),
        api,
        defaultFilename: getXlsxFilename(EXPORT_BASE_FILENAME),
        filter: {
            startDate: fetchProps.filterValues.startDate,
            endDate: fetchProps.filterValues.endDate,
        },
    });

    return document;
}
