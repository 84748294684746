import React from 'react';
import './maintenance-bar.scss';
import EntityWrapper from '../../../common/widget/EntityWrapper';
import { IMaintenanceMessage } from '../../../../models/general/maintenance';
import { getAsyncMaintenanceMessages } from '../../../../redux/entities/selectors';
import Translate from '../../../common/Translate';
import Icon from '../../../common/icons/Icon';
import { isDateBetween } from '../../../../utils/core/date/isDateBetween';
import { now } from '../../../../utils/core/date/getSpecificDate';

const CLASS_NAME = 'MaintenanceBar';

export default function MaintenanceBar() {
    return (
        <div className={CLASS_NAME}>
            <EntityWrapper
                asyncEntitySelector={getAsyncMaintenanceMessages}
                renderData={renderMaintenanceMessages}
            />
        </div>
    );

    function renderMaintenanceMessages({ data: maintenanceMessages }: { data: IMaintenanceMessage[] }) {
        const activeMessages = maintenanceMessages.filter((message) => {
            return isDateBetween(now(), message.startDate, message.endDate);
        });

        if (activeMessages.length === 0) {
            return null;
        }

        return (
            <div className={`${CLASS_NAME}__content`}>
                <div className={`${CLASS_NAME}__messages`}>
                    {activeMessages.map((item, index) => (
                        <div
                            className={`${CLASS_NAME}__message`}
                            key={index}
                        >
                            <Icon typeName="warning-triangle" />
                            <div className={`${CLASS_NAME}__message__content`}>
                                <Translate msg={item.message} raw />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
