import React, { PureComponent, ComponentClass, ComponentType, createRef, RefObject } from 'react';
import './calendar.scss';
import classNames from 'classnames';
import BigCalendar, {
    BigCalendarProps, View, HeaderProps,
    Messages, Components, Views,
} from 'react-big-calendar';
import Header from 'react-big-calendar/lib/Header';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import localizer from './localizer';
import CalendarToolbar, { ICalendarToolbarProps } from './CalendarToolbar';
import { ITranslator } from '../../../../models/general/i18n';
import TimeGridEvent from './TimeGridEvent';
import { ICalendarEvent } from '../../../../models/ui/calendar';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import * as screenSizeUtils from '../../../../utils/dom/screenSize';
import {
    getDate, getDateWithoutTime, getMonday, dayOffsetFromDate, minutesOffsetFromDate,
} from '../../../../utils/core/date/getSpecificDate';
import { AVAILABILITY_HOUR_LIMITS } from '../../../../config/company/companyInfo.config';
import { isValidBackendDate } from '../../../../utils/core/date/isValid';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import EventList from './EventList';
import { ICustomDayStyle } from '../DateTimePicker/DatePicker/customDayPlugin';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { getMinutesBetweenDates } from '../../../../utils/core/date/getDifferenceBetweenDates';

const DEFAULT_CALENDAR_STEP = 15;
const DEFAULT_QUARTER_HOUR_BLOCK_HEIGHT = 45; // 45px per 15min
// TODO: Contribute to Definitely Typed
const VIEWS = {
    day: true,
    month: false,
    week: true,
    agenda: EventList as ComponentType,
} as object as Views;
const localizerInstance = localizer();

export interface IHeaderItemProps extends HeaderProps {
    events: ICalendarEvent[];
}

interface IContextProps {
    translator: ITranslator;
}

interface ICalendarProps extends Pick<
    BigCalendarProps<ICalendarEvent>,
    'onSelectEvent' | 'onNavigate' | 'events'
    > {
    selectedDate?: string;
    selectedEvent?: ICalendarEvent;
    selectable: boolean;
    hideAllDaySection?: boolean;
    hideTodayButton?: boolean;
    hideViewToggle?: boolean;
    hideToolbar?: boolean;
    minDate?: string;
    maxDate?: string;
    minTime?: string;
    maxTime?: string;
    toolbarChildrenComponent?: ComponentType<{}>;
    view?: 'day' | 'week' | 'agenda';
    hideCurrentTimeIndicator?: boolean;
    onViewChanged?: (view: View) => void;
    headerItemComponent?: ComponentType<IHeaderItemProps>;
    inlineDatePicker?: boolean;
    customDayStyles: ICustomDayStyle[];
    rowHeight?: number;
    step?: number;
}

interface IState {
    isExtraSmallScreen: boolean;
    timeRangeToDisplayMinDate: Date;
    timeRangeToDisplayMaxDate: Date;
    selectedDate: Date;
    messages: Messages;
}

class CalendarComp extends PureComponent<ICalendarProps & IContextProps, IState> {
    private elRef: RefObject<HTMLDivElement> = createRef();
    private onResizeDebounced: TDebounced;
    private customComponents: Components = null;

    constructor(props: ICalendarProps & IContextProps) {
        super(props);

        const isExtraSmallScreen = screenSizeUtils.isExtraSmallScreen();
        const selectedDate = parseSelectedDate(props.selectedDate);
        const { min, max } = getMinAndMaxHourFromDisplayedEvents(
            props.events,
            this.getView(isExtraSmallScreen),
            selectedDate,
        );

        this.state = {
            isExtraSmallScreen,
            timeRangeToDisplayMinDate: (props.minTime && getDate(props.minTime)) || new Date(1970, 1, 1, min, 0),
            timeRangeToDisplayMaxDate: (props.maxTime && getDate(props.maxTime)) || new Date(1970, 1, 1, max - 1, 59),
            selectedDate,
            messages: this.getMessages(),
        };

        this.onResizeDebounced = debounce(() => this.onWindowResize(), 100);
        this.onViewChanged = this.onViewChanged.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.renderHeader = this.renderHeader.bind(this);

        this.customComponents = {
            toolbar: this.renderToolbar,
            eventWrapper: TimeGridEvent,
            header: this.renderHeader,
        };
    }

    public render() {
        const {
            isExtraSmallScreen,
            messages,
            selectedDate,
            timeRangeToDisplayMaxDate,
            timeRangeToDisplayMinDate,
        } = this.state;

        const height = this.props.view !== 'agenda' && calculateHeight(
            this.getHeaderHeight(),
            timeRangeToDisplayMinDate,
            timeRangeToDisplayMaxDate,
            this.props.rowHeight || DEFAULT_QUARTER_HOUR_BLOCK_HEIGHT,
            this.props.step || DEFAULT_CALENDAR_STEP,
        );
        return (
            <div
                style={{
                    height,
                }}
                ref={this.elRef}
            >
                <BigCalendar
                    className={classNames('Calendar', {
                        hideCurrentTimeIndicator: !!this.props.hideCurrentTimeIndicator,
                        hideAllDaySection: !!this.props.hideAllDaySection,
                    })}
                    selected={this.props.selectedEvent}
                    selectable={this.props.selectable}
                    onSelectEvent={this.props.onSelectEvent}
                    onNavigate={this.props.onNavigate}
                    localizer={localizerInstance}
                    events={this.props.events}
                    date={selectedDate}
                    components={this.customComponents}
                    view={this.getView(isExtraSmallScreen)}
                    onView={this.onViewChanged}
                    views={VIEWS}
                    messages={messages}
                    step={this.props.step || DEFAULT_CALENDAR_STEP}
                    timeslots={1}
                    min={timeRangeToDisplayMinDate}
                    max={timeRangeToDisplayMaxDate}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: ICalendarProps & IContextProps) {
        if (prevProps.selectedDate !== this.props.selectedDate) {
            const selectedDate = parseSelectedDate(this.props.selectedDate);
            this.setState({
                selectedDate,
            });
        }
        if (prevProps.translator !== this.props.translator) {
            this.setState({
                messages: this.getMessages(),
            });
        }

        if (prevProps.events !== this.props.events || prevProps.selectedDate !== this.props.selectedDate) {
            const selectedDate = parseSelectedDate(this.props.selectedDate);
            const { min, max } = getMinAndMaxHourFromDisplayedEvents(
                this.props.events, this.getView(this.state.isExtraSmallScreen), selectedDate,
            );
            if (
                min !== this.state.timeRangeToDisplayMinDate.getHours() ||
                max !== this.state.timeRangeToDisplayMaxDate.getHours()
            ) {
                const { minTime, maxTime } = this.props;
                this.setState({
                    timeRangeToDisplayMinDate: (minTime && getDate(minTime)) || new Date(1970, 1, 1, min, 0),
                    timeRangeToDisplayMaxDate: (maxTime && getDate(maxTime)) || new Date(1970, 1, 1, max, 59),
                });
            }
        }
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResizeDebounced);
    }

    public componentWillUnmount() {
        this.onResizeDebounced.cancel();
        window.removeEventListener('resize', this.onResizeDebounced);
    }

    private onWindowResize() {
        const isExtraSmallScreen = screenSizeUtils.isExtraSmallScreen();
        if (this.state.isExtraSmallScreen !== isExtraSmallScreen) {
            this.setState({
                isExtraSmallScreen,
            });
        }
    }

    private onViewChanged(view: View) {
        const { onViewChanged } = this.props;
        if (typeof onViewChanged === 'function') {
            onViewChanged(view);
        }
    }

    private renderToolbar(toolbarProps: ICalendarToolbarProps) {
        const {
            minDate, maxDate, hideTodayButton, hideViewToggle,
            toolbarChildrenComponent: ToolbarChildrenComponent,
            inlineDatePicker, customDayStyles, hideToolbar,
        } = this.props;

        if (hideToolbar) {
            return null;
        }

        return (
            <CalendarToolbar
                {...toolbarProps}
                hideTodayButton={hideTodayButton}
                hideViewToggle={hideViewToggle}
                minDate={minDate}
                maxDate={maxDate}
                selectedDate={formatDateForBackend(this.state.selectedDate)}
                inlineDatePicker={inlineDatePicker}
                isExtraSmallScreen={this.state.isExtraSmallScreen}
                customDayStyles={customDayStyles}
            >
                {ToolbarChildrenComponent && <ToolbarChildrenComponent />}
            </CalendarToolbar>
        );
    }

    private renderHeader(headerProps: HeaderProps) {
        const { headerItemComponent: HeaderItem, events } = this.props;
        if (!HeaderItem) {
            return (
                <Header {...headerProps} />
            );
        }
        return (
            <HeaderItem {...headerProps} events={events} />
        );
    }

    private getMessages() {
        const { translator } = this.props;
        return {
            agenda: translator('common.calendar.agenda'),
            allDay: translator('common.calendar.all_day'),
            date: translator('common.calendar.date'),
            day: translator('common.calendar.day'),
            event: translator('common.calendar.event'),
            month: translator('common.calendar.month'),
            next: translator('common.calendar.next'),
            previous: translator('common.calendar.previous'),
            showMore: (count) => translator({
                msg: 'common.calendar.show_more',
                placeholders: { count: count.toString() },
            }),
            time: translator('common.calendar.time'),
            today: translator('common.calendar.today'),
            tomorrow: translator('common.calendar.tomorrow'),
            week: translator('common.calendar.week'),
            work_week: translator('common.calendar.work_week'),
            yesterday: translator('common.calendar.yesterday'),
        };
    }

    private getView(isExtraSmallScreen: boolean) {
        return isExtraSmallScreen && this.props.view !== 'agenda'
            ? 'day'
            : this.props.view;
    }

    private getHeaderHeight() {
        if (!this.elRef.current) {
            return 0;
        }
        const stickyElements = this.elRef.current.querySelectorAll('.Sticky');
        let height = 0;
        if (stickyElements) {
            for (let i = 0, length = stickyElements.length; i < length; i += 1) {
                height += (stickyElements[i] as HTMLElement).offsetHeight;
            }
        }
        return height;
    }
}

(CalendarComp as ComponentClass<ICalendarProps>).defaultProps = {
    view: 'week',
};

export default function Calendar(props: ICalendarProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <CalendarComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

function getMinAndMaxHourFromDisplayedEvents(events: ICalendarEvent[], view: View, selectedDate: Date) {
    return events.reduce(
        ({ min, max }, event) => {
            const next = { min, max };

            if (event.allDay || !isEventDisplayed(event, view, selectedDate)) {
                return next;
            }

            if (event.start.getHours() < min) {
                next.min = event.start.getHours();
            }

            if (event.end.getHours() > max) {
                next.max = event.end.getHours();
            }

            return next;
        },
        {
            min: AVAILABILITY_HOUR_LIMITS.min,
            max: AVAILABILITY_HOUR_LIMITS.max,
        },
    );
}

function calculateHeight(headerHeight: number, minTime: Date, maxTime: Date, rowHeight: number, step: number) {
    // +1min because BigCalendar also shows the timecell that starts on the maxTime
    // if maxTime = 10:00 => BigCalendar will also show the 10:00 - 10:15 timecell
    const maxTimeVisible = minutesOffsetFromDate(maxTime, 1).toDate();
    const diff = getMinutesBetweenDates(maxTimeVisible, minTime);
    const totalQuartersInRange = Math.ceil(diff / step);
    return headerHeight + (totalQuartersInRange * rowHeight);
}

function isEventDisplayed(event: ICalendarEvent, view: View, selectedDate: Date) {
    const monday = getDateWithoutTime(getMonday(selectedDate));
    const nextMonday = dayOffsetFromDate(monday, 8).toDate();
    const start = getDateWithoutTime(event.start);
    const end = getDateWithoutTime(event.end);
    const selected = getDateWithoutTime(selectedDate);
    const dayAfterSelected = dayOffsetFromDate(selected, 1).toDate();
    if (
        view === 'week' &&
        (
            start.getTime() < monday.getTime() ||
            end.getTime() > nextMonday.getTime()
        )
    ) {
        return false;
    }
    if (view === 'day' &&
        (
            start.getTime() < selected.getTime() ||
            end.getTime() > dayAfterSelected.getTime()
        )
    ) {
        return false;
    }
    return true;
}

function parseSelectedDate(selectedDate: string) {
    if (!selectedDate) {
        return new Date();
    }
    return isValidBackendDate(selectedDate) ?
        getDate(selectedDate) :
        new Date();
}
