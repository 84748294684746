import React, { useState } from 'react';
import { ConfirmableTextPropertyInput } from '../../../common/input/ConfirmablePropertyInput';
import { getUpdateEmployeeAsyncInfo } from '../../../../redux/employee/info/selectors';
import { getUpdateEmployeeRequestId } from '../EmployeeDetails';
import { TEmployeeUpdateFields } from '../../../../models/admin/employee';
import isNotEmail from '../../../../utils/validation/isNotEmail';
import { ErrorTypes } from '../../../../models/general/error';

interface IPublicProps {
    id: number;
    initialValue: string;
    onSave: (id: number, fieldName: keyof TEmployeeUpdateFields, value: string) => void;
}

export default function AddEmployeeEmailField({ id, onSave, initialValue }: IPublicProps) {
    const [value, setValue] = useState<string>(initialValue);
    const validationError = isNotEmail(value);

    return (
        <ConfirmableTextPropertyInput
            id={`add-email-${id}`}
            name="email"
            labelKey="administration.employees.add_emails.field.email_placeholder"
            value={value}
            initialValue={initialValue}
            hideLabel={true}
            readonly={false}
            onChange={(e) => setValue(e.target.value)}
            asyncInfoSelector={getUpdateEmployeeAsyncInfo}
            requestId={getUpdateEmployeeRequestId(id, 'email')}
            onSave={() => {
                onSave(id, 'email', value);
            }}
            validationError={{
                error: validationError && {
                    type: ErrorTypes.InvalidEmail,
                    message: validationError.message,
                },
                fieldNameTranslationKey: 'administration.employees.add_emails.field.email_field',
            }}
        />
    );
}
