import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    registerStorageTypeForProvidedReducer, getRegisteredReducers,
} from '@snipsonian/redux/es/reducer/reducerManager';

export default function registerProvidedReducer({
    key,
    reducer,
    reducerStorageType = REDUCER_STORAGE_TYPE.INHERIT,
    transformReducerStateForStorage = (reducerState) => reducerState,
}) {
    registerStorageTypeForProvidedReducer({
        key,
        reducerStorageType,
        transformReducerStateForStorage,
    });

    getRegisteredReducers()[key] = reducer;
}
