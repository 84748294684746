import dayjs from 'dayjs';
import localStorage from '@snipsonian/browser/es/storage/localStorage';
import {
    SESSION_STORAGE_KEY,
    SESSION_LENGTH_IN_MILLIS,
    SESSION_END_WARNING_IN_MILLIS,
} from '../../config/authorisation';
import { now } from '../core/date/getSpecificDate';

const NO_SESSION_END_TIME = '';

export interface ISessionManager {
    getSessionEndTime: () => string;
    updateSessionEndTime: (optionsForTesting?: IUpdateSessionEndTimeOptionsForTesting) => void;
    clearSessionEndTime: () => void;
    isSessionAboutToExpire: () => boolean;
    isSessionExpired: () => boolean;
    isValidSessionEndTime: () => boolean;
    usesLocalStorage: boolean;
}

interface IUpdateSessionEndTimeOptionsForTesting {
    sessionLengthInMillis?: number;
}

interface ISessionManagerData {
    sessionEndTime: string;
}

const sessionManager = initSessionManager();

export default sessionManager;

export function initSessionManager(): ISessionManager {
    let localStorageFallback: ISessionManagerData = { sessionEndTime: NO_SESSION_END_TIME };
    const usesLocalStorage = localStorage.isSupported;

    return {
        getSessionEndTime,
        updateSessionEndTime,
        clearSessionEndTime,
        isSessionAboutToExpire,
        isSessionExpired,
        isValidSessionEndTime,
        usesLocalStorage,
    };

    function getSessionEndTime() {
        if (usesLocalStorage) {
            const data = localStorage.read({ key: SESSION_STORAGE_KEY }) as ISessionManagerData;
            return (data && data.sessionEndTime) || NO_SESSION_END_TIME;
        }
        return localStorageFallback.sessionEndTime;
    }

    /* options can be passed for testing purposes, but should in real life not be used */
    function updateSessionEndTime({
        sessionLengthInMillis = SESSION_LENGTH_IN_MILLIS,
    }: IUpdateSessionEndTimeOptionsForTesting = {}) {
        const newSessionEndTime = dayjs().add(sessionLengthInMillis, 'millisecond').toISOString();

        storeSessionManagerData({
            sessionEndTime: newSessionEndTime,
        });
    }

    function clearSessionEndTime() {
        storeSessionManagerData({
            sessionEndTime: NO_SESSION_END_TIME,
        });
    }

    function isSessionAboutToExpire(sessionEndTime: string = getSessionEndTime()) {
        if (!isValidSessionEndTime(sessionEndTime)) {
            return false;
        }

        if (isSessionExpired(sessionEndTime)) {
            return false;
        }

        return dayjs(sessionEndTime)
            .subtract(SESSION_END_WARNING_IN_MILLIS, 'millisecond')
            .isBefore(dayjs(now()));
    }

    function isSessionExpired(sessionEndTime: string = getSessionEndTime()) {
        if (!isValidSessionEndTime(sessionEndTime)) {
            return true;
        }

        return dayjs(sessionEndTime)
            .isBefore(dayjs(now()));
    }

    function storeSessionManagerData(data: ISessionManagerData) {
        if (usesLocalStorage) {
            localStorage.save({ key: SESSION_STORAGE_KEY, value: data });
        } else {
            localStorageFallback = data;
        }
    }

    function isValidSessionEndTime(sessionEndTime: string = getSessionEndTime()) {
        return sessionEndTime !== NO_SESSION_END_TIME;
    }
}
