import { object } from 'yup';
import { ErrorTypes, IValidationError } from '../../../../models/general/error';
import isNotSetOrEmptyString from '../../../../utils/core/string/isNotSetOrEmptyString';
import { EMPLOYEE_SEARCH_MIN_NR_OF_CHARS } from '../../../../config/administration.config';
import string from '../../../../utils/libs/yup/string';

export const EMPLOYEE_SEARCH_NOT_ENOUGH_INPUT_CHARS_WARNING: IValidationError = {
    type: ErrorTypes.Custom,
    message: 'error.validation.employee_search_minimum_length',
};

export const fields = {
    search: 'search',
};

export const schema = object({
    [fields.search]: string()
        .test(
            EMPLOYEE_SEARCH_NOT_ENOUGH_INPUT_CHARS_WARNING.type,
            EMPLOYEE_SEARCH_NOT_ENOUGH_INPUT_CHARS_WARNING.message,
            (value) => isValidWhenEmptyOrHasMinNumberOfChars(value, EMPLOYEE_SEARCH_MIN_NR_OF_CHARS),
        ),
});

function isValidWhenEmptyOrHasMinNumberOfChars(value: string, minNrOfChars: number) {
    return isNotSetOrEmptyString(value) || value.trim().length >= minNrOfChars;
}
