import React, { PureComponent } from 'react';
import './suggestions-dashboard-block.scss';
import './suggestion-item.scss';
import Translate from '../../../common/Translate';
import ROUTE_KEYS from '../../../../routeKeys';
import LinkToRoute from '../../../common/navigation/LinkToRoute';
import Icon from '../../../common/icons/Icon';
import { ICMSCourse } from '../../../../models/documentCenter/courses';
import { ILocationAction } from '../../../../models/general/redux';
import ThemeOfTheMonth from './ThemeOfTheMonth/index';
import EntityWrapper from '../../../common/widget/EntityWrapper';
import { getAsyncSpotlightCourse } from '../../../../redux/entities/selectors';
import { NO_SPOTLIGHT_COURSE_ID } from '../../../../config/courses.config';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';

const CLASS_NAME = 'SuggestionsDashboardBlock';
const TRANSLATION_KEY_PREFIX = 'home.dashboard.suggestions';
const TRANSLATION_CODE = {
    TEMPLATES: 'templates',
    COURSES: 'courses',
};
const BASE_ID = {
    TEMPLATES_SUGGESTION: 'templates-suggestion-item-link',
    COURSES_SUGGESTION: 'courses-suggestion-item-link',
};
const HEADER_TARGET_ROUTE_KEY = {
    COURSES_SUGGESTION: ROUTE_KEYS.R_COURSES_OVERVIEW,
    TEMPLATES_SUGGESTION: ROUTE_KEYS.R_DOCUMENT_CENTER,
};

interface ISuggestionContentRenderProps {
    baseId: string;
    translationCode: string;
}

export default class SuggestionsDashboardBlock extends PureComponent {
    constructor(props) {
        super(props);

        this.renderLinkToStandardDocs = this.renderLinkToStandardDocs.bind(this);
        this.renderAsyncSpotlightCourse = this.renderAsyncSpotlightCourse.bind(this);
        this.renderSpotlightCourseData = this.renderSpotlightCourseData.bind(this);
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                <h2><Translate msg={`${TRANSLATION_KEY_PREFIX}.title`} /></h2>
                <div className={`${CLASS_NAME}__wrapper`}>
                    <div className={`${CLASS_NAME}__main`}>
                        <div>
                            <ShowIfAllowed routeKey={HEADER_TARGET_ROUTE_KEY.TEMPLATES_SUGGESTION}>
                                <SuggestionItem
                                    baseId={BASE_ID.TEMPLATES_SUGGESTION}
                                    translationCode={TRANSLATION_CODE.TEMPLATES}
                                    headerTargetRouteKey={HEADER_TARGET_ROUTE_KEY.TEMPLATES_SUGGESTION}
                                    renderContent={this.renderLinkToStandardDocs}
                                />
                            </ShowIfAllowed>
                            <ShowIfAllowed routeKey={HEADER_TARGET_ROUTE_KEY.COURSES_SUGGESTION}>
                                <SuggestionItem
                                    baseId={BASE_ID.COURSES_SUGGESTION}
                                    translationCode={TRANSLATION_CODE.COURSES}
                                    headerTargetRouteKey={HEADER_TARGET_ROUTE_KEY.COURSES_SUGGESTION}
                                    renderContent={this.renderAsyncSpotlightCourse}
                                />
                            </ShowIfAllowed>
                        </div>
                    </div>
                    <div className={`${CLASS_NAME}__aside`}>
                        <div>
                            <ThemeOfTheMonth />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLinkToStandardDocs({ baseId, translationCode }: ISuggestionContentRenderProps) {
        return (
            <SuggestionContentLink
                baseId={baseId}
                translationCode={translationCode}
                targetTo={ROUTE_KEYS.R_TEMPLATES}
            />
        );
    }

    renderAsyncSpotlightCourse({ baseId, translationCode }: ISuggestionContentRenderProps) {
        return (
            <EntityWrapper
                asyncEntitySelector={getAsyncSpotlightCourse}
                renderData={this.renderSpotlightCourseData}
                fetch={{
                    useTinyLoader: true,
                }}
            />
        );
    }

    renderSpotlightCourseData({ data: spotlightCourse }: { data: ICMSCourse }) {
        if (spotlightCourse.nodeId === NO_SPOTLIGHT_COURSE_ID) {
            // There should always be some courses in the spotlight, so we don't do anything special
            return <span />;
        }

        return (
            <SuggestionContentLink
                baseId={BASE_ID.COURSES_SUGGESTION}
                translationCode={TRANSLATION_CODE.COURSES}
                targetTo={{
                    type: ROUTE_KEYS.R_COURSES_DETAIL_INTRO,
                    payload: {
                        nodeId: spotlightCourse.nodeId,
                    },
                }}
                targetTitle={spotlightCourse.name}
            />
        );
    }
}

function SuggestionItem({
    baseId,
    translationCode,
    headerTargetRouteKey,
    renderContent,
}: {
    baseId: string;
    translationCode: string;
    headerTargetRouteKey: ROUTE_KEYS;
    renderContent: (renderProps: ISuggestionContentRenderProps) => React.ReactElement<{}>;
}) {
    const CLASS_NAME = 'SuggestionItem';

    return (
        <div className={CLASS_NAME}>
            <header className={`${CLASS_NAME}__header`}>
                <h6><Translate msg={`home.dashboard.suggestions.entry_points.${translationCode}.header.title`} /></h6>
                <LinkToRoute
                    id={`${baseId}-header`}
                    to={headerTargetRouteKey}
                    className="icon-link"
                >
                    <span>
                        <Translate
                            msg={`home.dashboard.suggestions.entry_points.${translationCode}.header.subtitle`}
                        />
                    </span>
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </header>
            <div className={`${CLASS_NAME}__content`}>
                {renderContent({ baseId, translationCode })}
            </div>
        </div>
    );
}

function SuggestionContentLink({
    baseId,
    translationCode,
    targetTo,
    targetTitle,
}: ISuggestionContentRenderProps & {
    targetTo: ROUTE_KEYS | ILocationAction<object>;
    targetTitle?: string;
}) {
    return (
        <LinkToRoute id={`${baseId}-content`} to={targetTo}>
            <h6>
                {targetTitle || (
                    <Translate
                        msg={`home.dashboard.suggestions.entry_points.${translationCode}.content.title`}
                    />
                )}
            </h6>
            <p>
                <Translate
                    msg={`home.dashboard.suggestions.entry_points.${translationCode}.content.text`}
                />
            </p>
            <Icon typeName="chevron-right" />
        </LinkToRoute>
    );
}
