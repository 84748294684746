import React, { useEffect } from 'react';

import { getLatestStatisticalCode } from '../../../redux/preventionUnits/companySituationHistoryUtils';
import { getCompanySituationHistory } from '../../../redux/preventionUnits/selectors';
import { isLoggedIn, loginCount } from '../../../redux/auth/selectors';
import connect from '../../../utils/libs/redux/connect';

import { IHelloCustomerPrivateProps } from './HelloCustomer.type';
import { LOGIN_COUNT_SHOW_HELLO_CUSTOMER_THRESHOLD, modalSurvey } from './HelloCustomer.const';

const HelloCustomerPopup = ({ isLoggedIn, companyStatisticalCode, loginCount }: IHelloCustomerPrivateProps) => {
    useEffect(() => {
        if (!companyStatisticalCode) {
            return () => {};
        }

        if (isLoggedIn && companyStatisticalCode && loginCount > LOGIN_COUNT_SHOW_HELLO_CUSTOMER_THRESHOLD) {
            const threeMinutes = (1000 * 60 * 3);
            setTimeout(() => {
                modalSurvey(companyStatisticalCode).show();
            }, threeMinutes);
        }

        return () => {};
    }, [isLoggedIn, companyStatisticalCode, loginCount]);

    return <></>;
};

export default connect<IHelloCustomerPrivateProps>({
    stateProps: (state) => {
        const companyStatisticalCode = getLatestStatisticalCode(
            getCompanySituationHistory(state),
        );

        return {
            isLoggedIn: isLoggedIn(state) || false,
            loginCount: loginCount(state),
            companyStatisticalCode,
        };
    },
})(HelloCustomerPopup);
