import React from 'react';
import Translate from '../../../common/Translate';
import { connect } from '../../../index';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../models/general/i18n';
import FunctionTypeahead from '../shared/FunctionTypeahead';
import SeatTypeahead from '../shared/SeatTypeahead';
import {
    getSeatsAndDivisionsForSelectedCompany,
    getSelectedCompanySeat,
} from '../../../../redux/company/selected/selectors';
import {
    IRenderSearchContentProps,
    IRenderFilterContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import FormFieldError from '../../../common/forms/FormFieldError';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import { IColumnNames } from '../index';
import { ListItem } from '../../../../models/general/list';
import { ITypeaheadDataItem } from '../../../common/input/Typeahead';
import { EMPLOYEE_CONDITION } from '../../../../models/admin/employee';

interface IPrivateProps {
    translator: ITranslator;
    showSeatsFilter: boolean;
    showFullFamily: boolean;
}
export interface IFilterValues {
    search: string;
    functionFilter: string;
    seatFilter: string;
    refreshSources: 'true' | 'false';
    conditionFilter?: string;
}

function EmployeeSearchComp(props: IPrivateProps & IRenderSearchContentProps<IFilterValues>) {
    const { errors, touched } = props.formRenderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="employee-filter-search"
                name="search"
                // eslint-disable-next-line max-len
                placeholder={props.translator('administration.employees.filter.search')}
                value={props.formRenderProps.values.search || ''}
                onChange={props.formRenderProps.handleChange}
                isInvalid={touched.search && !!errors.search}
            />
            <label htmlFor="employee-filter-search">
                <Translate msg="administration.employees.filter.search" />
            </label>
            <FormFieldError error={touched.search && errors.search} />
        </FloatableTextInputWrapper>
    );
}

export const EmployeeSearch = connect<IPrivateProps, IRenderSearchContentProps<IFilterValues>>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
        };
    },
})(EmployeeSearchComp);

function EmployeeFilterComp(props: IPrivateProps & IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const { values, setFieldValue } = props.formRenderProps;

    function setFilter(key: keyof IFilterValues, value: string) {
        if (values.refreshSources !== 'false') {
            setFieldValue('refreshSources', 'false');
        }
        setFieldValue(key, value);
    }

    // NOTE: we can't get unique possible conditions from list items, since we don't fetch ALL items
    const possibleConditions: ITypeaheadDataItem[] = Object.values(EMPLOYEE_CONDITION).map((condition) => ({
        label: condition,
        value: condition,
    }));

    return (
        <>
            <FloatableTextInputWrapper floatLabel>
                <FunctionTypeahead
                    id="employee-filter-function"
                    name="functionFilter"
                    value={values.functionFilter || ''}
                    initialFilter={values.functionFilter || ''}
                    onItemSelected={(value, companyFunction) =>
                        setFilter('functionFilter', companyFunction ? companyFunction.description : null)}
                    onFilter={(filter) =>
                        setFilter('functionFilter', filter)}
                    showFullFamily={props.showFullFamily}
                    placeholder={props.translator('administration.employees.filter.function')}
                    isFreeText={true}
                >
                    <label htmlFor="employee-filter-function">
                        <Translate msg="administration.employees.filter.function" />
                    </label>
                </FunctionTypeahead>
            </FloatableTextInputWrapper>
            {props.showSeatsFilter &&
                <FloatableTextInputWrapper floatLabel>
                    <SeatTypeahead
                        id="employee-filter-seat"
                        name="seatFilter"
                        placeholder={props.translator('administration.employees.filter.seat')}
                        value={values.seatFilter || ''}
                        initialFilter={values.seatFilter || ''}
                        onItemSelected={(companyCode, name) =>
                            setFilter('seatFilter', name)}
                        onFilter={(filter) =>
                            setFilter('seatFilter', filter)}
                        isFreeText={true}
                    >
                        <label htmlFor="employee-filter-seat">
                            <Translate msg="administration.employees.filter.seat" />
                        </label>
                    </SeatTypeahead>
                </FloatableTextInputWrapper>
            }
            {!!possibleConditions && possibleConditions.length > 0 &&
                <CheckboxesOrTypeaheadFilter
                    filterName="employee-filter-condition"
                    labelTranslationKey="administration.employees.filter.condition"
                    showCheckboxesUptoThisValuesLength={7}
                    possibleFilterItems={possibleConditions}
                    actualFilterValue={values.conditionFilter}
                    filterItemTranslationKey={'administration.employees.filter.condition_filter'}
                    onChange={(newFilterValue) => setFilter('conditionFilter', newFilterValue)}
                />
            }
        </>
    );
}

// eslint-disable-next-line max-len
export const EmployeeFilter = connect<IPrivateProps, IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>>({
    stateProps: (state) => {
        const companySeat = getSelectedCompanySeat(state);
        return {
            translator: getTranslatorDeprecated(state),
            showSeatsFilter: getSeatsAndDivisionsForSelectedCompany(state).length > 1,
            showFullFamily: companySeat && companySeat.isAllSeatsSelected,
        };
    },
})(EmployeeFilterComp);
