import React, { PureComponent } from 'react';
import PageHeader from '../../appShell/PageHeader';
import Translate from '../../common/Translate';
import ListWithSorting from '../../common/list/ListWithSorting';
import { ListItem, ListColumns, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import {
    getRisks,
    getRisksAsyncInfo,
} from '../../../redux/company/info/selectors';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import './risks.scss';
import { IRisk } from '../../../models/admin/risks';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IRenderSearchContentProps, IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
} from '../../common/widget/MasterWithDetail/typings';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

interface IColumnNames {
    description: string;
}

interface IFilterValues {
    search: string;
    isShowAll: boolean;
}

interface IColumnNames {
    description: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    description: {
        label: <Translate msg="administration.risks_and_dangers.columns.risk" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 100,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'description',
    sortOrder: SortOrder.Ascending,
};

type TRisksListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const BASE_NAME = 'risks';

export default function Risks() {
    const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_COMPANY_RISKS;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <div className={BASE_NAME}>
            <PageHeader
                breadcrumbs={true}
                title="administration.risks_and_dangers.title"
                text="administration.risks_and_dangers.text"
                isTextRaw
                lazyLoadSvg={lazyLoadSvgId && {
                    id: lazyLoadSvgId,
                    group: SVG_GROUP_NAME.ADMINISTRATION,
                }}
                type="grey"
            />
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_COMPANY_RISKS,
                    asyncInfoSelector: getRisksAsyncInfo,
                    dataSelector: getRisks,
                    transformData: mapRisksToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: TRisksListProps) =>
                        <RisksList {...renderProps} />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    exportButton: {
                        baseFilename: 'risks',
                        listItemIdExtractor: toListId,
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        </div>
    );
}

class RisksList extends PureComponent<TRisksListProps> {
    constructor(props: TRisksListProps) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            filterValues,
            footer,
        } = this.props;

        return (
            <>
                <ListWithSorting
                    columns={COLUMNS}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    initialSort={INITIAL_SORT}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
            </>
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('administration.company_info.contacts.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="administration.company_info.contacts.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
function toListId(risk: IRisk) {
    return risk.riskCode;
}

function getDefaultQueryParams({ isShowAll }: {isShowAll: boolean}) {
    return isShowAll ? {
        isShowAll,
    } : {};
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'administration.risks_and_dangers.filter',
        filters: {
            search: {
                show: true,
            },
            isShowAll: {
                show: false,
            },
        },
    });
}

function mapRisksToListItems(risks: IRisk[]): ListItem<IColumnNames>[] {
    return risks.map((risk) => {
        return {
            id: toListId(risk),
            columns: {
                description: risk.description,
            },
        };
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
