import {
    FETCH_OPEN_INVOICES_COUNT,
    FETCH_OPEN_INVOICES,
    FETCH_PAID_INVOICES,
    FETCH_INVOICE_DOCUMENT,
    FETCH_INVOICE_DETAIL,
    FETCH_INVOICE_FULL,
    DISMISS_INVOICE_NOT_FOUND_DIALOG,
    FETCH_INVOICE_PARAMETERS,
} from './types';
import { createAction, createSuccessAction, createFailAction, createResetAction } from '../index';
import { IOpenInvoicesCount, IInvoice, IFetchInvoicePayload, IInvoiceParameters } from '../../models/admin/invoices';
import { ITraceableApiError } from '../../models/general/error';
import { IDocument } from '../../models/general/documents';
import presentDownloadedFile from '../../utils/file/presentDownloadedFile';
import { createTypeActions } from '../../utils/libs/redux/createAction';

// Open invoices count
export function fetchOpenInvoicesCountSucceeded(payload: IOpenInvoicesCount) {
    return createSuccessAction<IOpenInvoicesCount>(FETCH_OPEN_INVOICES_COUNT, payload);
}

export function fetchOpenInvoicesCountFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_OPEN_INVOICES_COUNT, error);
}

// Open invoices
export function fetchOpenInvoicesSucceeded(payload: IInvoice[]) {
    return createSuccessAction<IInvoice[]>(FETCH_OPEN_INVOICES, payload);
}

export function fetchOpenInvoicesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_OPEN_INVOICES, error);
}

// Paid invoices
export function fetchPaidInvoicesSucceeded(payload: IInvoice[]) {
    return createSuccessAction<IInvoice[]>(FETCH_PAID_INVOICES, payload);
}

export function fetchPaidInvoicesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_PAID_INVOICES, error);
}

// Download invoice document
export function fetchInvoiceDocument(payload: IFetchInvoicePayload) {
    return createAction<IFetchInvoicePayload>(FETCH_INVOICE_DOCUMENT, payload);
}

export function fetchInvoiceDocumentSucceeded(payload: IDocument) {
    presentDownloadedFile(payload);

    return createSuccessAction(FETCH_INVOICE_DOCUMENT, {});
}

export function fetchInvoiceDocumentFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_INVOICE_DOCUMENT, error);
}

export function resetFetchInvoiceDocument() {
    return createResetAction(FETCH_INVOICE_DOCUMENT, {});
}

export function dismissInvoiceNotFoundDialog() {
    return createAction(DISMISS_INVOICE_NOT_FOUND_DIALOG, {});
}

// Download invoice detail
export function fetchInvoiceDetail(payload: IFetchInvoicePayload) {
    return createAction<IFetchInvoicePayload>(FETCH_INVOICE_DETAIL, payload);
}

export function fetchInvoiceDetailSucceeded(payload: IDocument) {
    presentDownloadedFile(payload);

    return createSuccessAction(FETCH_INVOICE_DETAIL, {});
}

export function fetchInvoiceDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_INVOICE_DETAIL, error);
}

// Download invoice full
export function fetchInvoiceFull(payload: IFetchInvoicePayload) {
    return createAction<IFetchInvoicePayload>(FETCH_INVOICE_FULL, payload);
}

export function fetchInvoiceFullSucceeded(payload: IDocument) {
    presentDownloadedFile(payload);

    return createSuccessAction(FETCH_INVOICE_FULL, {});
}

export function fetchInvoiceFullFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_INVOICE_FULL, error);
}

// Invoice parameters

export const fetchInvoiceParametersActions =
    createTypeActions<{}, IInvoiceParameters[]>({
        type: FETCH_INVOICE_PARAMETERS,
    });
