import { ICreateCompanyVisitRequest } from '../../../../models/interventions/company-visits';
import { post } from '../../../../utils/api/requestWrapper';

import { URL } from '../company-visits.const';

export function createCompanyVisitRequest(companyCode: string, body: ICreateCompanyVisitRequest) {
    return post({
        url: URL.COMPANY_VISIT_REQUEST,
        body,
        pathParams: {
            companyCode,
        },
    });
}
