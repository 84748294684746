import { ListColumns } from '../../../../../../models/general/list';

import { IReservedMedicalExaminationDetailListColumnNames } from '../ReservedMedicalExaminationDetail.type';

export const COLUMNS: ListColumns<IReservedMedicalExaminationDetailListColumnNames> = {
    time: {
        label: '',
        percentWidth: 20,
    },
    name: {
        label: '',
        percentWidth: 50,
    },
    actions: {
        label: '',
        percentWidth: 30,
    },
    idOfEmployee: {
        hide: true,
        percentWidth: null,
    },
    employmentId: {
        hide: true,
        percentWidth: null,
    },
    planningRequestId: {
        hide: true,
        percentWidth: null,
    },
    timeSlotId: {
        hide: true,
        percentWidth: null,
    },
};
