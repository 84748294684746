import React from 'react';
import { ITipPagePartData } from '../../../../../models/general/pageParts';
import './tip-page-part.scss';
import CollapsibleItem from '../../../widget/CollapsibleItem';
import WysiwygContentType from '../WysiwygContentType';
import OutgoingLink from '../../../navigation/OutgoingLink';

interface IPublicProps {
    data: ITipPagePartData;
}

const CLASS_NAME = 'TipPagePart';

export default function TipPagePart(props: IPublicProps) {
    const { data } = props;

    return (
        <div className={CLASS_NAME}>
            <CollapsibleItem
                trigger={data.title}
                triggerTagName="h4"
            >
                <WysiwygContentType html={data.description} />
                {(data.ctaText && data.ctaUrl) && (
                    <OutgoingLink href={data.ctaUrl} className={`${CLASS_NAME}__cta`}>
                        {data.ctaText}
                    </OutgoingLink>
                )}
            </CollapsibleItem>
        </div>
    );
}
