export const VIRTUAL_URL_PREFIX = '/';

// TODO check if all these are needed + maybe others are missing
/**
 * Don't just change these enum values as they are used in GTM.
 */
export enum SubmittedFormActionType {
    COMPANY_VISIT_REQUESTED = 'company-visit-requested',

    EMPLOYEE_ADDED = 'employee-added',

    /* the feedback types start with the same prefix so that they can be easily grouped in gtm */
    FEEDBACK_GIVEN_COMPLAINT = 'feedback-given_complaint',
    FEEDBACK_GIVEN_QUESTION = 'feedback-given_question',
    FEEDBACK_GIVEN_SUGGESTION = 'feedback-given_suggestion',

    FLU_VACCINES_ORDERED = 'flu-vaccines-ordered',

    /* the intervention types start with the same prefix so that they can be easily grouped in gtm */
    INTERVENTION_REQUESTED_ENVIRONMENTAL = 'intervention-requested_environmental',
    INTERVENTION_REQUESTED_ERGONOMICS = 'intervention-requested_ergonomics',
    INTERVENTION_REQUESTED_HEALTH = 'intervention-requested_health',
    INTERVENTION_REQUESTED_HYG_TOX = 'intervention-requested_hyg-tox',
    INTERVENTION_REQUESTED_OTHER = 'intervention-requested_other',
    INTERVENTION_REQUESTED_PSYCHOSOCIAL = 'intervention-requested_psychosocial',
    INTERVENTION_REQUESTED_SAFETY = 'intervention-requested_safety',

    MEDICAL_EXAM_PLANNED_PERIODIC_HEALTH_ASSESSMENT = 'medical-exam-planned_po-manual',
    MEDICAL_EXAM_PLANNED_PERIODIC_HEALTH_ASSESSMENT_BULK = 'medical-exam-planned_po-bulk',
    MEDICAL_EXAM_PLANNED_INTERIM = 'medical-exam-planned_interim',
    MEDICAL_EXAM_PLANNED_RE_INTEGRATION = 'medical-exam-planned_re-integration',
    MEDICAL_EXAM_PLANNED_RESUMPTION_OF_WORK = 'medical-exam-planned_resumption-of-work',
    MEDICAL_EXAM_PLANNED_MATERNITY_PROTECTION = 'medical-exam-planned_maternity-protection',
    MEDICAL_EXAM_PLANNED_SPONTANEOUS_CONSULTATION = 'medical-exam-planned_spontaneous',
    MEDICAL_EXAM_PLANNED_PERIODIC_CHECK_PLUS_SAFETY_FUNCTION = 'medical-exam-planned_po-and-safety-function',
    MEDICAL_EXAM_PLANNED_PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT = 'medical-exam-planned_recruitment',
    /**
     * Used when a medical examination was planned with an unexpected examination reason
     * (mensura can add/remove reasons which we then get via an api call)
     */
    MEDICAL_EXAM_PLANNED_OTHER = 'medical-exam-planned_other',

    MEDICAL_EXAM_MOVE_PLANNING = 'medical-exam-move-planning',

    TRAINING_COURSE_ENROLLED = 'training-course-enrolled',
    TRAINING_COURSE_REMOVED = 'training-course-removed',

    USER_ADDED_EXTERNAL = 'user-added-external',
    USER_ADDED_INTERNAL = 'user-added-internal',

    WORK_POST_CARD_CREATED = 'work-post-card-created',
}

export type TOptionalSubmittedFormActionType = SubmittedFormActionType | false;
