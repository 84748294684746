import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    createActionHandlersForType,
    registerReducer,
    createAsyncDoActionHandlers,
} from '../../index';
import {
    FETCH_DOCUMENT,
    DISMISS_DOCUMENT_DIALOG,
    CREATE_CONVOCATIONS,
    UPLOAD_EMPLOYEE_DOCUMENT,
    FETCH_CONVOCATION_RECIPIENTS,
} from './types';
import { IDocument, IConvocationRecipient } from '../../../models/general/documents';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.EMPLOYEE_CONVOCATION;

export interface IReducerState {
    document: IAsyncFetchField<IDocument>;
    createConvocations: IAsyncDoField;
    createConvocationsInBulk: IAsyncDoField;
    uploadEmployeeDocument: IAsyncDoField;
    convocationRecipients: IAsyncFetchField<IConvocationRecipient[]>;
}

const initialState: IReducerState = {
    document: getAsyncFetchInitialState(),
    createConvocations: getAsyncDoInitialState(),
    createConvocationsInBulk: getAsyncDoInitialState(),
    uploadEmployeeDocument: getAsyncDoInitialState(),
    convocationRecipients: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(DISMISS_DOCUMENT_DIALOG)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                document: getAsyncFetchInitialState(),
                employeesConvocationDocument: getAsyncFetchInitialState(),
                employeeConvocationDocument: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<IDocument, IReducerState, IDocument>({
        baseActionType: FETCH_DOCUMENT,
        fieldName: 'document',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_CONVOCATIONS,
        fieldName: 'createConvocations',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPLOAD_EMPLOYEE_DOCUMENT,
        fieldName: 'uploadEmployeeDocument',
    }),
    ...createAsyncFetchActionHandlers<IConvocationRecipient[], IReducerState, IConvocationRecipient[]>({
        baseActionType: FETCH_CONVOCATION_RECIPIENTS,
        fieldName: 'convocationRecipients',
        resetDataOnTrigger: false,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
