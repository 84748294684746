import { get, post } from '../../utils/api/requestWrapper';
import {
    IFetchPolicyAdvicePayload,
    IPolicyAdvice,
    IFetchPolicyAdviceCommentsPayload,
    IPolicyAdviceComment,
    ISendPolicyAdviceCommentPayload,
} from '../../models/interventions/policyAdvice';
import { IFilterValues } from '../../views/interventions/PolicyAdvice';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, getDate } from '../../utils/core/date/getSpecificDate';

export const URL = {
    POLICY_ADVICE: '/companies/{companyCode}/policy-advice',
    POLICY_ADVICE_COMMENTS: '/companies/{companyCode}/policy-advice/{id}',
    SEND_POLICY_ADVICE_COMMENT: '/companies/{companyCode}/policy-advice/{id}',
};

export const DEFAULT_POLICY_ADVICE_FILTERS: Partial<IFilterValues> = {
    startDate: formatDateForBackend(getDate('01/01/2016')),
    endDate: formatDateForBackend(now()),
    status: '0',
};

export function fetchPolicyAdvice(
    { companyCode, showFullFamily }: IFetchPolicyAdvicePayload,
) {
    return get<IPolicyAdvice[]>({
        url: URL.POLICY_ADVICE,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['policy-advice'],
    });
}

export function fetchPolicyAdviceComments(
    { id, companyCode, showFullFamily }: IFetchPolicyAdviceCommentsPayload,
) {
    return get<IPolicyAdviceComment[]>({
        url: URL.POLICY_ADVICE_COMMENTS,
        pathParams: {
            companyCode,
            id,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['policy-advice-comments'],
    });
}

export function sendPolicyAdviceComment({ companyCode, id, comment }: ISendPolicyAdviceCommentPayload) {
    return post({
        url: URL.SEND_POLICY_ADVICE_COMMENT,
        pathParams: {
            companyCode,
            id,
        },
        body: {
            comment,
        },
    });
}
