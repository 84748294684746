import React, { PureComponent } from 'react';
import './contact-input.scss';
import { schema as editContactSchema, fields as editContactFields } from './editSchema';
import { ITranslator } from '../../../../models/general/i18n';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { ICompanyContact, IUpdateCompanyContactPayload } from '../../../../models/admin/companyInfo';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { updateContactActions } from '../../../../redux/company/info/actions';
import { getUpdateContactAsyncInfo } from '../../../../redux/company/info/selectors';
import connect from '../../../../utils/libs/redux/connect';
import ContactTypeahead from '../ContactTypeahead';
import PageHeader from '../../../appShell/PageHeader';
import Button from '../../../common/buttons/Button';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import FormError from '../../../common/forms/FormError';
import FormFieldError from '../../../common/forms/FormFieldError';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import RequiredMarker from '../../../common/input/RequiredMarker';
import TextInput from '../../../common/input/TextInput';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader';
import Dialog from '../../../common/modals/Dialog';
import {
    tryFormattingPhoneInternational,
    tryFormattingPhoneForBackend,
} from '../../../../utils/formatting/formatPhone';
import { userIsAdminOrSuperAdmin } from '../../../../redux/auth/selectors';

const CLASS_NAME = 'ContactInput';
const CLASS_NAME_OVERLAY = 'ContactOverlay';
const EDIT_CONTACT_FORM_NAME = 'edit-contact-form';

const SELECT_CONTACT_PLACEHOLDER_TRANS_KEY = 'interventions.contact_input.fields.contact.select_placeholder';

export interface IFormValues {
    contact: ICompanyContact;
}

interface IEditContactFormValues {
    phone: string;
    mobilePhone: string;
    email: string;
}

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues) => void;
    /**
     * disableContactUpdate Default false
     *                      If true, then the (potential) contact changes will only be used for the request,
     *                      and will not be saved on the contact itself.
     */
    disableContactUpdate?: boolean;
}

interface IPrivateProps {
    translator: ITranslator;
    updateContactAsyncInfo: IAsyncFieldInfo;
    updateContact: (contactId: number, changedContact: ICompanyContact) => void;
    resetUpdateContact: () => void;
    userIsNotAdmin: boolean;
}

interface IComponentState {
    showOverlay: boolean;
    selectedContact: ICompanyContact;
}

class ContactInput extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    private resetEditContactForm: (newValues: IEditContactFormValues) => void;

    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        const initialContact = props.formRenderProps.values.contact;

        this.state = {
            showOverlay: false,
            selectedContact: initialContact,
        };

        if (initialContact) {
            /**
             * Immediately update the wizard form because this can be the "default" initial contact.
             * Otherwise, when the user would click on the next button, this input form would be valid (as
             * there is a contact) but the wizard entity wouldn't have the contact field set yet.
             *
             * p.s. This initial contact can also just be a previously selected contact after clicking the
             * previous button, but re-updating the wizard form is not an issue.
             */
            props.onChangeInput({
                contact: initialContact,
            });
        }

        this.onSelectContactHandler = this.onSelectContactHandler.bind(this);
        this.onUpdateContactHandler = this.onUpdateContactHandler.bind(this);
        this.onConfirmContactHandler = this.onConfirmContactHandler.bind(this);
        this.showOverlay = this.showOverlay.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        if (prevProps.updateContactAsyncInfo && prevProps.updateContactAsyncInfo.status === AsyncStatus.Busy
            && this.props.updateContactAsyncInfo && this.props.updateContactAsyncInfo.status === AsyncStatus.Success) {
            /* close/confirm the overlay after a successful contact update */
            this.onConfirmContactHandler(this.state.selectedContact);
        }
    }

    public render() {
        const { formRenderProps, translator } = this.props;

        const contactPlaceholder = translator(
            'interventions.contact_input.fields.contact.placeholder');

        return (
            <div className={CLASS_NAME}>
                <div className="ContactSelection">
                    <ContactTypeahead
                        id="edit-contact"
                        name="contact"
                        value={formRenderProps.values.contact ? formRenderProps.values.contact.customerContactId : null}
                        onItemSelected={this.onSelectContactHandler}
                        isInvalid={formRenderProps.touched.contact && !!formRenderProps.errors.contact}
                        placeholderTranslationKey={SELECT_CONTACT_PLACEHOLDER_TRANS_KEY}
                        showContactTypeInDropdownContent={true}
                    />
                    {formRenderProps.touched.contact && (
                        <FormFieldError
                            error={formRenderProps.errors.contact}
                            placeholders={{ fieldName: contactPlaceholder }}
                        />
                    )}
                </div>
                {this.renderEditContactOverlay()}
            </div>
        );
    }

    private renderEditContactOverlay() {
        const { translator, updateContactAsyncInfo, userIsNotAdmin } = this.props;
        const { showOverlay, selectedContact } = this.state;

        if (!selectedContact) {
            return null;
        }

        const phoneTranslationKey =
            'interventions.contact_input.overlay.fields.phone.label';
        const mobilePhoneTranslationKey =
            'interventions.contact_input.overlay.fields.mobile_phone.label';
        const emailTranslationKey =
            'interventions.contact_input.overlay.fields.email.label';

        const initialValues = getEditContactInitialValues(selectedContact);

        return (
            <Dialog
                show={showOverlay}
                onCloseIntent={this.hideOverlay}
            >
                <div className="ContactInputOverlay">
                    <PageHeader
                        title="interventions.contact_input.overlay.title"
                        text={`interventions.contact_input.overlay.text${userIsNotAdmin ? '_non_admin' : ''}`}
                        titlePlaceholders={{
                            firstName: selectedContact.firstName,
                            lastName: selectedContact.name,
                        }}
                    />
                    <div className="container">
                        <div className={`${CLASS_NAME_OVERLAY}__content`}>
                            <Form
                                name={EDIT_CONTACT_FORM_NAME}
                                handleSubmit={this.onUpdateContactHandler}
                                initialValues={initialValues}
                                schema={editContactSchema}
                                render={({
                                    values, handleChange, touched, errors, submitForm, resetForm,
                                }: IFormRenderProps<IEditContactFormValues>) => {
                                    this.resetEditContactForm = resetForm;

                                    return (
                                        <>
                                            <Loader show={updateContactAsyncInfo.status === AsyncStatus.Busy} />
                                            <FloatableTextInputWrapper>
                                                <TextInput
                                                    id="contact-confirm-phone"
                                                    name={editContactFields.phone}
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={touched.phone && !!errors.phone}
                                                    disabled={userIsNotAdmin}
                                                    // eslint-disable-next-line max-len
                                                    placeholder={translator('interventions.contact_input.overlay.fields.phone.placeholder')}
                                                />
                                                <label htmlFor="contact-confirm-phone">
                                                    <Translate msg={phoneTranslationKey} />
                                                </label>
                                                {touched.phone && (
                                                    <FormFieldError
                                                        error={errors.phone}
                                                        placeholders={{ fieldName: translator(phoneTranslationKey) }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>

                                            <FloatableTextInputWrapper>
                                                <TextInput
                                                    id="contact-confirm-mobilePhone"
                                                    name={editContactFields.mobilePhone}
                                                    value={values.mobilePhone}
                                                    onChange={handleChange}
                                                    isInvalid={touched.mobilePhone && !!errors.mobilePhone}
                                                    // eslint-disable-next-line max-len
                                                    placeholder={translator('interventions.contact_input.overlay.fields.mobile_phone.placeholder')}
                                                    disabled={userIsNotAdmin}
                                                />
                                                <label htmlFor="contact-confirm-mobilePhone">
                                                    <Translate msg={mobilePhoneTranslationKey} />
                                                </label>
                                                {touched.mobilePhone && (
                                                    <FormFieldError
                                                        error={errors.mobilePhone}
                                                        // eslint-disable-next-line max-len
                                                        placeholders={{ fieldName: translator(mobilePhoneTranslationKey) }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>

                                            <FloatableTextInputWrapper>
                                                <TextInput
                                                    id="contact-confirm-email"
                                                    name={editContactFields.email}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={touched.email && !!errors.email}
                                                    // eslint-disable-next-line max-len
                                                    placeholder={translator('interventions.contact_input.overlay.fields.email.placeholder')}
                                                    disabled={userIsNotAdmin}
                                                />
                                                <label htmlFor="contact-confirm-email">
                                                    <Translate msg={emailTranslationKey} />
                                                    <RequiredMarker />
                                                </label>
                                                {touched.email && (
                                                    <FormFieldError
                                                        error={errors.email}
                                                        placeholders={{ fieldName: translator(emailTranslationKey) }}
                                                    />
                                                )}
                                            </FloatableTextInputWrapper>
                                            <div className="actions">
                                                <Button
                                                    id="confirm-data-contact"
                                                    typeName="secondary"
                                                    onClick={submitForm}
                                                >
                                                    <Translate msg="interventions.contact_input.overlay.save" />
                                                </Button>
                                                {updateContactAsyncInfo.error &&
                                                    <FormError error={updateContactAsyncInfo.error} />
                                                }
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    private onSelectContactHandler(contactId: number, contact: ICompanyContact) {
        this.props.onChangeInput({
            contact,
        });

        this.setState({
            selectedContact: contact,
        });

        if (this.resetEditContactForm) {
            this.resetEditContactForm(contact);
        }
        this.props.resetUpdateContact();
        this.showOverlay();
    }

    private onUpdateContactHandler(editContactValues: IEditContactFormValues) {
        if (areContactFieldsChanged(this.state.selectedContact, editContactValues)) {
            const changedContact = {
                ...this.state.selectedContact,
                ...editContactValues,
            };

            this.setState({
                selectedContact: changedContact,
            });

            if (this.props.disableContactUpdate) {
                this.onConfirmContactHandler(changedContact);
            } else {
                this.props.updateContact(
                    this.state.selectedContact.customerContactId,
                    changedContact,
                );
            }
        } else {
            this.onConfirmContactHandler(this.state.selectedContact);
        }
    }

    private onConfirmContactHandler(contact: ICompanyContact) {
        this.props.onChangeInput({
            contact,
        });

        this.hideOverlay();

        // submit the outer form (to directly go to the next step)
        // Fix because setFieldValue does not return a promise.
        // https://github.com/jaredpalmer/formik/issues/529
        setTimeout(this.props.formRenderProps.submitForm, 1);
    }

    private showOverlay() {
        this.setState({
            showOverlay: true,
        });
    }

    private hideOverlay() {
        this.setState({
            showOverlay: false,
        });
    }
}

export default connect<IPrivateProps, IPublicProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            updateContactAsyncInfo: getUpdateContactAsyncInfo(state),
            userIsNotAdmin: !userIsAdminOrSuperAdmin(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            updateContact: (contactId: number, changedContact: ICompanyContact) => {
                dispatch(updateContactActions.trigger(
                    transformToUpdateCompanyContactPayload(contactId, changedContact),
                ));
            },
            resetUpdateContact: () => {
                dispatch(updateContactActions.reset({}));
            },
        };
    },
})(ContactInput);

function areContactFieldsChanged(origContact: ICompanyContact, editContactValues: IEditContactFormValues) {
    return origContact.email !== editContactValues.email
        || origContact.mobilePhone !== editContactValues.mobilePhone
        || origContact.phone !== editContactValues.phone;
}

function getEditContactInitialValues(contact: ICompanyContact): IEditContactFormValues {
    return {
        phone: tryFormattingPhoneInternational(contact.phone) || '',
        mobilePhone: tryFormattingPhoneInternational(contact.mobilePhone) || '',
        email: contact.email || '',
    };
}

function transformToUpdateCompanyContactPayload(
    customerContactId: number,
    changedContact: ICompanyContact,
): IUpdateCompanyContactPayload {
    const {
        company,
        firstName,
        name,
        typeId,
        email,
        fax,
        languageId,
        mobilePhone,
        phone,
        newsletter,
        titleContactId: titleId,
    } = changedContact;

    const updateContactData = {
        firstName,
        name,
        typeId,
        email,
        fax: tryFormattingPhoneInternational(fax),
        languageId,
        mobilePhone: tryFormattingPhoneForBackend(mobilePhone),
        phone: tryFormattingPhoneForBackend(phone),
        newsletter,
        titleId,
    };

    return {
        companyCode: company.companyCode,
        contactData: updateContactData,
        customerContactId,
    };
}
