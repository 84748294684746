import { object, string } from 'yup';

import phoneNumber from '../../../../../utils/libs/yup/phoneNumber';

import { IRequestVaccinationFormValues } from './RequestVaccinationDialog.type';

export const schema = object<IRequestVaccinationFormValues>({
    comment: string(),
    type: string(),
    phoneNumber: phoneNumber(),
    seat: string(),
    stepCyclus: string(),
    deadline: string(),
    name: string(),
});
