import { IAgendaRoutePayload, IAgendaQueryParams } from '../../../../models/planning/agenda';
import { ILocationAction } from '../../../../models/general/redux';
import ROUTE_KEYS from '../../../../routeKeys';

export const PLAN_INTERVENTION_ROUTE_KEY = ROUTE_KEYS.R_PLAN_INTERVENTION;

export const AGENDA_ROUTE: ILocationAction<IAgendaRoutePayload, IAgendaQueryParams> = {
    type: ROUTE_KEYS.R_AGENDA,
    payload: {
        viewType: 'calendar',
    },
};
