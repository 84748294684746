import { createSelector } from 'reselect';
import { ILocationAction } from '../../../models/general/redux';
import {
    IPlanMedicalExaminationWizardPayload,
    PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
} from '../../../models/interventions/medicalExaminations';
import { getReducerState, IState } from '../../IState';
import ROUTE_KEYS from '../../../routeKeys';
import { IReducerState, reducerKey } from './reducer';
import { getPropertyFromRoutePayload, getRouteKey } from '../../location/selectors';
import { navigateTo } from '../../location/actions';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

export const getLastDashboardRoute = (state: IState) => reducerState(state).lastDashboardRoute;

export const getRouteThatTriggeredWizard = (state: IState) => reducerState(state).routeThatTriggeredWizard;

interface IRoutePayloadRelevantForExitLink {
    step: string;
    prestationCode: string;
}

/**
 * Some pre-created link actions to avoid unnecessary re-renders.
 * Only the link actions that are conditional will be generated on the fly.
 */
const WIZARD_EXIT_LINK_ACTIONS = {
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL]: navigateTo(ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS),
    [ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL]: navigateTo(ROUTE_KEYS.R_ACCOUNT_MANAGE_USERS),
    [ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW]: navigateTo(ROUTE_KEYS.R_COMPANY_VISITS_PLANNED),
    [ROUTE_KEYS.R_COURSE_ENROLL_NEW]: navigateTo(ROUTE_KEYS.R_COURSES_OVERVIEW),
    [ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD]: navigateTo(ROUTE_KEYS.R_EMPLOYEES),
    [ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS]: navigateTo(ROUTE_KEYS.R_EMPLOYEES),
    [ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW]: navigateTo(ROUTE_KEYS.R_FLU_VACCINES_ORDERS),
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES]: navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN),
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON]: navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN),
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD]: navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN),
    [ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW]: navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES),
    [ROUTE_KEYS.R_ONBOARDING_NEW]: navigateTo(ROUTE_KEYS.R_COMPANY_SELECTION),
    [ROUTE_KEYS.R_ONBOARDING_FINISH]: navigateTo(ROUTE_KEYS.R_COMPANY_SELECTION),
    [ROUTE_KEYS.R_WORK_POST_CARD_NEW]: navigateTo(ROUTE_KEYS.R_WORK_POST_CARDS),
    TO_AUTO_VALIDATE_STEP_OF_NEW_MEDICAL_EXAMINATION: navigateTo<IPlanMedicalExaminationWizardPayload>(
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
        createExitAutoPlanWizardPayload(PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_VALIDATE),
    ),
    TO_INTERVENTIONS: navigateTo(ROUTE_KEYS.R_INTERVENTIONS),
    DEFAULT: navigateTo(ROUTE_KEYS.R_HOME),
};

export const getWizardExitLinkActionMemoized = createSelector(
    getRouteThatTriggeredWizard,
    getRouteKey,
    /* separate selectors for each route prop because the getRoutePayload selector always returns a new object
       which would result in always running this function again (and possibly unneeded re-renders) */
    (state: IState) => getPropertyFromRoutePayload(state, 'step'),
    (state: IState) => getPropertyFromRoutePayload(state, 'prestationCode'), // IInterventionRequestWizardPayload
    (routeThatTriggeredWizard, currentRouteKey, stepRouteProp, prestationCodeRouteProp): ILocationAction<{}> => {
        const exitLinkActionThatTakesPrecedence = getWizardExitLinkActionThatTakesPrecedenceOverTheTriggerRoute({
            currentRouteKey,
            currentRoutePayload: {
                step: stepRouteProp,
                prestationCode: prestationCodeRouteProp,
            },
        });

        if (exitLinkActionThatTakesPrecedence) {
            return exitLinkActionThatTakesPrecedence;
        }

        if (routeThatTriggeredWizard) {
            return navigateTo(
                routeThatTriggeredWizard.type,
                routeThatTriggeredWizard.payload,
                routeThatTriggeredWizard.query,
            );
        }

        if (WIZARD_EXIT_LINK_ACTIONS[currentRouteKey]) {
            return WIZARD_EXIT_LINK_ACTIONS[currentRouteKey];
        }

        switch (currentRouteKey) {
            case ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW:
                if (!prestationCodeRouteProp) {
                    // Did not found a previous prestation code, so go back to interventions dashboard
                    return WIZARD_EXIT_LINK_ACTIONS.TO_INTERVENTIONS;
                }

                // We can navigate to a specific prestationCode intervention list
                return navigateTo(ROUTE_KEYS.R_EXECUTED_INTERVENTIONS, {
                    prestationCode: prestationCodeRouteProp,
                });
            default:
                return WIZARD_EXIT_LINK_ACTIONS.DEFAULT;
        }
    },
);

function getWizardExitLinkActionThatTakesPrecedenceOverTheTriggerRoute({
    currentRouteKey,
    currentRoutePayload,
}: {
    currentRouteKey: ROUTE_KEYS,
    currentRoutePayload: IRoutePayloadRelevantForExitLink,
}): ILocationAction<{}> | false {
    if (currentRouteKey === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD) {
        const { step } = currentRoutePayload;

        if (step === PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_VALIDATE) {
            return WIZARD_EXIT_LINK_ACTIONS.TO_AUTO_VALIDATE_STEP_OF_NEW_MEDICAL_EXAMINATION;
        }
    }

    return false;
}

function createExitAutoPlanWizardPayload(step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID) {
    return {
        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC,
        step,
        action: PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION.exit,
    };
}
