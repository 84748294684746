import { ICompanyDetail, CompanyAvailabilityType, ICompanyAvailabilities } from '../admin/companyInfo';
import { ICompanySeat } from '../admin/company';
import { IEmployeeToAdd, IUpdateEmployeeJobStudentPayload } from '../admin/employee';

export enum ONBOARDING_WIZARD_STEP_ID {
    DATA = 'data',
    SEAT = 'seat',
    CONTACT = 'contact',
    RISK_MGMT = 'risk-mgmt',
    MEDICAL_EXAMINATION = 'medical-examination',
    HOLIDAY = 'holiday',
    EMPLOYEE = 'employee',
}

export interface IOnboardingWizardPayload {
    step: ONBOARDING_WIZARD_STEP_ID;
    resetDataEntity: boolean;
}

export interface IOnboardingWizardEntity {
    baseData: IOnboardingData;
    seatsData: ICompanySeat[];
    riskManagementAvailabilities: {
        [companyCode: string]: ICompanyAvailabilities;
    };
    medicalExaminationsAvailabilities: {
        [companyCode: string]: ICompanyAvailabilities;
    };
    copiedMedExamAvailabilities: boolean;
    noFixedHolidaysChecked: boolean;
}

export interface IOnboardingWizardData {
    stepId: ONBOARDING_WIZARD_STEP_ID;
    entity: Partial<IOnboardingWizardEntity>;
}

export type IOnboardingData = Pick<ICompanyDetail,
    'name' | 'address' | 'ventureNumber' | 'companyCode' |
    'languageId' | 'email' | 'phone' | 'fax'
>;

export interface IFetchOnboardingSeatsAvailabilitiesPayload {
    availabilityType: CompanyAvailabilityType;
}

type IOnboardingEmployee = Pick<IEmployeeToAdd,
    'address'
    | 'birthDate'
    | 'dateInFunction'
    | 'email'
    | 'employeeId'
    | 'employeeNumber'
    | 'firstName'
    | 'languageId'
    | 'mobilePhone'
    | 'medicalCenterId'
    | 'name'
    | 'nationalityId'
    | 'nationalRegisterNumber'
    | 'phone'
    | 'sexId'
    | 'studentWorker'
>
& Pick<
    IUpdateEmployeeJobStudentPayload,
    'employer'
    | 'medicalFunction'
    | 'safetyFunction'
    | 'sendMail'
>;

export interface IOnboardEmployeeToAdd extends IOnboardingEmployee {
    birthDate: string;
    companyCode: string;
    customerFunctionId: number;
    isForeignEmployee?: boolean;
}
