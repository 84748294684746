import React from 'react';
import classNames from 'classnames';
import isSet from '@snipsonian/core/es/is/isSet';
import isEmptyString from '@snipsonian/core/es/string/isEmptyString';
import './templates-category-detail.scss';
import {
    ITemplatesCategoryDetail,
    ITemplatesCategoryDocumentClass,
} from '../../../../models/documentCenter/templates';
import { ListColumns, ListItem } from '../../../../models/general/list';
import { getAsyncTemplatesCategory } from '../../../../redux/entities/templates/selectors';
import PageHeader from '../../../appShell/PageHeader';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import SearchInput from '../../../common/input/SearchInput';
import List from '../../../common/list/List';
import ListItemActions from '../../../common/list/ListItemActions';
import Translate from '../../../common/Translate';
import ContentWithSidebar, {
    IContentWithSidebarRenderProps,
    SideBarLink,
} from '../../../common/widget/ContentWithSidebar';
import EntityWrapper from '../../../common/widget/EntityWrapper';
import { toAnchorLinkHref, toAnchorTargetId } from '../../../../utils/core/string/anchor';
import openInNewTab from '../../../../utils/browser/openInNewTab';

const CLASS_NAME = 'TemplatesCategoryDetail';
const TRANSLATION_PREFIX = 'document_center.templates.detail';

interface IColumnNames {
    title: string;
    actions: string;
    url: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    title: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.files.columns.title`} />,
        sortable: false,
        percentWidth: 90,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
        align: 'right',
        mobileLabel: <Translate msg={`${TRANSLATION_PREFIX}.files.actions.download_file`} />,
        render: (categoryDocumentItem: ListItem<IColumnNames>) => renderDownloadAction({ categoryDocumentItem }),
    },
    url: {
        hide: true,
        percentWidth: null,
    },
};

export default function TemplatesCategoryDetail() {
    return (
        <div className={CLASS_NAME}>
            <EntityWrapper
                asyncEntitySelector={getAsyncTemplatesCategory}
                renderData={renderTemplatesCategory}
            />
        </div>
    );

    function renderTemplatesCategory({ data }: { data: ITemplatesCategoryDetail }) {
        return (
            <TemplatesCategoryContentWithSidebar categoryDetail={data} />
        );
    }
}

interface IDetailProps {
    categoryDetail: ITemplatesCategoryDetail;
}

interface ISearchInputState {
    searchInput: string;
}

interface ISearchInputProps extends ISearchInputState {
    onSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class TemplatesCategoryContentWithSidebar extends React.PureComponent<IDetailProps, ISearchInputState> {
    constructor(props: IDetailProps) {
        super(props);

        this.state = {
            searchInput: '',
        };

        this.onSearchInput = this.onSearchInput.bind(this);
    }

    render() {
        const { categoryDetail } = this.props;
        const { searchInput } = this.state;
        const categoryDetailFiltered = filterTemplatesBySearchInput(categoryDetail, searchInput);

        return (
            <>
                <PageHeader
                    type="grey"
                    title={categoryDetail.title}
                    breadcrumbs={true}
                    customContent={<p>{categoryDetail.description}</p>}
                />
                <div className="container">
                    <ContentWithSidebar
                        sidebar={(renderProps) =>
                            <SidebarContent
                                categoryDetail={categoryDetailFiltered}
                                {...renderProps}
                            />
                        }
                        content={
                            <MainContent
                                categoryDetail={categoryDetailFiltered}
                                searchInput={searchInput}
                                onSearchInput={this.onSearchInput}
                            />
                        }
                        titleIds={categoryDetailFiltered.documentClasses
                            .map((documentClass) => spacesToDashes(documentClass.title))}
                    />
                </div>
            </>
        );
    }

    onSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchInput: event.target.value,
        });
    }
}

function SidebarContent(props: IDetailProps & IContentWithSidebarRenderProps) {
    const {
        categoryDetail,
        activeTitleId,
        setActiveTitleIdOverride,
    } = props;

    return (
        <nav>
            {categoryDetail.documentClasses.map((categoryDocumentClass, index) => {
                const classLinkTitle = spacesToDashes(categoryDocumentClass.title);
                const titleClass = classNames({
                    active: activeTitleId === classLinkTitle,
                });

                return (
                    <SideBarLink
                        key={`category-doc-class-sidebar-${index}`}
                        href={toAnchorLinkHref(classLinkTitle)}
                        className={titleClass}
                        setActiveTitleIdOverride={() => setActiveTitleIdOverride(classLinkTitle)}
                    >
                        {categoryDocumentClass.title}
                    </SideBarLink>
                );
            })}
        </nav>
    );
}

function MainContent(props: IDetailProps & ISearchInputProps) {
    const { categoryDetail, searchInput, onSearchInput } = props;

    return (
        <div className="content">
            <div className={`${CLASS_NAME}__actions`}>
                <TranslatorContext.Consumer>
                    {({ translator }) => (
                        <SearchInput
                            placeholder={translator(`${TRANSLATION_PREFIX}.search.placeholder`)}
                            defaultValue={searchInput}
                            onChange={onSearchInput}
                        />
                    )}
                </TranslatorContext.Consumer>
            </div>

            {categoryDetail.documentClasses
                && categoryDetail.documentClasses.length > 0
                && categoryDetail.documentClasses.map((categoryDocumentClass, index) => (
                <div
                    key={`category-doc-class-main-${index}`}
                    className={`${CLASS_NAME}__section`}
                >
                    <h2
                        id={toAnchorTargetId(spacesToDashes(categoryDocumentClass.title))}
                        className={`${CLASS_NAME}__title`}
                    >
                        {categoryDocumentClass.title}
                    </h2>
                    <p className={`${CLASS_NAME}__subtext`}>
                        {categoryDocumentClass.description}
                    </p>
                    <List
                        name="DownloadList"
                        columns={COLUMNS}
                        items={mapCategoryDocumentsToListItems({ categoryDocumentClass })}
                        selectedItemIds={[]}
                        hideHeaders
                    />
                </div>
            ))}
        </div>
    );
}

function mapCategoryDocumentsToListItems({
    categoryDocumentClass,
}: {
    categoryDocumentClass: ITemplatesCategoryDocumentClass;
}): ListItem<IColumnNames>[] {
    console.log('mapCategoryDocumentsToListItems # of docs', categoryDocumentClass.documents.length);
    return categoryDocumentClass.documents
        .map((categoryDocument, index) => ({
            id: spacesToDashes(`document_${categoryDocumentClass.title}_${index}`),
            columns: {
                title: categoryDocument.title,
                actions: null,
                url: categoryDocument.url,
            },
        }));
}

function renderDownloadAction({ categoryDocumentItem }: { categoryDocumentItem: ListItem<IColumnNames> }) {
    return (
        <ListItemActions>
            <Button
                id={`download_${categoryDocumentItem.id}`}
                onClick={() => openInNewTab(categoryDocumentItem.columns.url as string)}
            >
                <span><Translate msg={`${TRANSLATION_PREFIX}.files.actions.download_file`} /></span>
                <Icon circle typeName="download-file" />
            </Button>
        </ListItemActions>
    );
}

function spacesToDashes(input: string): string {
    if (!isSet(input)) {
        return '';
    }

    return input.replace(' ', '-');
}

function filterTemplatesBySearchInput(data: ITemplatesCategoryDetail, searchInput: string): ITemplatesCategoryDetail {
    return {
        ...data,
        documentClasses: data.documentClasses
            .reduce(
                (accumulator, documentClass) => {
                    const matchingDocuments = documentClass.documents.filter((categoryDocument) => {
                        if (!isSet(searchInput) || isEmptyString(searchInput)) {
                            return true;
                        }

                        return categoryDocument.title.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
                    });

                    if (matchingDocuments && matchingDocuments.length > 0) {
                        accumulator.push({
                            ...documentClass,
                            documents: matchingDocuments,
                        });
                    }

                    return accumulator;
                },
                [] as ITemplatesCategoryDocumentClass[],
            ),
    };
}
