import { createEpic } from '../index';
import { reportError } from '../../utils/logging/errorReporter';
import { redirectToUnexpectedError } from '../location/actions';
import { getRouteKey } from '../location/selectors';
import ROUTE_KEYS from '../../routeKeys';

/**
 * https://github.com/jeffbski/redux-logic/blob/master/docs/api.md
 *
 * processOptions.failType - if set to an action type string or an action creator function it will use this to
 *      create the action from the dispatched error or rejected promise value or errored observable similar to how
 *      successType works.
 *      If failType was a string then it uses the FSA error format otherwise it calls the action creator provided.
 *      If failType is not defined and an error is thrown or dispatched that does not itself have a type (action type),
 *      then an UNHANDLED_LOGIC_ERROR will be dispatched with the error as the payload. Default undefined.
 */

// handleUnexpectedLogicErrorEpic
createEpic({
    onActionType: 'UNHANDLED_LOGIC_ERROR',
    processMultiple: ({ action, getState }, dispatch, done) => {
        const error = action.payload as Error;

        reportError(error, {
            extra: {
                actionType: action.type,
            },
        });

        // TODO remove this comment once tested ok [see MENSKLNTZN-3256]
        if (getRouteKey(getState()) !== ROUTE_KEYS.R_LOGOUT) {
            dispatch(redirectToUnexpectedError());
        }

        done();
    },
    latest: false,
});
