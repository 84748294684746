import React from 'react';
import { ITimeLinePagePartData } from '../../../../../models/general/pageParts';
import './timeline-page-part.scss';
import OutgoingLink from '../../../navigation/OutgoingLink';
import Translate from '../../../Translate';
import { slugifyStringAsId } from '../../../../../utils/core/string/slugify';
import WysiwygContentType from '../WysiwygContentType';

interface IPublicProps {
    data: ITimeLinePagePartData;
}

const CLASS_NAME = 'TimeLinePagePart';

export default function TimeLinePagePart(props: IPublicProps) {
    const { data } = props;
    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__wrapper`}>
                {data.items.map((item, index) => (
                    <div key={slugifyStringAsId(item.title)} className={`${CLASS_NAME}__item`}>
                        <strong className={`${CLASS_NAME}__index`}>{index + 1}</strong>
                        <h4 className={`${CLASS_NAME}__title`}>{item.title}</h4>
                        <WysiwygContentType
                            className={`${CLASS_NAME}__content`}
                            html={item.content}
                        />
                    </div>
                ))}
            </div>
            {(data.linkText && data.linkUrl) && (
                <div className={`${CLASS_NAME}__sub`}>
                    <h4 className={`${CLASS_NAME}__sub__title`}>
                        <Translate msg="common.pagepart.timeline.convinced" />
                    </h4>
                    <OutgoingLink href={data.linkUrl} className={`${CLASS_NAME}__sub__btn`}>
                        {data.linkText}
                    </OutgoingLink>
                </div>
            )}
        </div>
    );
}
