import React from 'react';
import { IDismissedCompanyDashboardItem } from '../../../models/general/navigation';
import {
    DISMISSED_COMPANY_ITEMS,
    DISMISSED_COMPANY_MANAGEMENT_ITEMS,
} from '../../../config/navigation/dismissedCompany.config';
import makeAllowedNavigationItemsMemoizedSelector from '../../../redux/auth/makeAllowedNavigationItemsMemoizedSelector';
import { connect } from '../../index';
import PageHeader from '../../appShell/PageHeader';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import Tile from '../../common/widget/Tile';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const getAllowedDismissedCompanyItems = makeAllowedNavigationItemsMemoizedSelector<IDismissedCompanyDashboardItem>(
    DISMISSED_COMPANY_ITEMS,
);
const getAllowedDismissedCompanyMgmtItems = makeAllowedNavigationItemsMemoizedSelector<IDismissedCompanyDashboardItem>(
    DISMISSED_COMPANY_MANAGEMENT_ITEMS,
);

interface IPrivateProps {
    allowedDashboardItems: IDismissedCompanyDashboardItem[];
    allowedDashboardMgmtItems: IDismissedCompanyDashboardItem[];
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            allowedDashboardItems: getAllowedDismissedCompanyItems(state),
            allowedDashboardMgmtItems: getAllowedDismissedCompanyMgmtItems(state),
        };
    },
})(DismissedCompanyDashboard);

function DismissedCompanyDashboard(props: IPrivateProps) {
    const {
        allowedDashboardItems,
        allowedDashboardMgmtItems,
    } = props;

    return (
        <div className="tile-dashboard">
            <PageHeader
                title="home.dismissed_company.dashboard.title"
                text="home.dismissed_company.dashboard.header_text"
                isTextRaw={true}
                breadcrumbs={true}
            />
            <div className="container">
                <div className="sections-wrapper">
                    { allowedDashboardItems.length > 0 && (
                        <div className="section-main">
                            <div className="section-inner">
                                <NavigationItems allowedDashboardItems={allowedDashboardItems} />
                            </div>
                        </div>
                    )}
                    { allowedDashboardMgmtItems.length > 0 && (
                        <div className="section-sub">
                            <div>
                                <div className="section-inner">
                                    <NavigationManagementItems allowedDashboardMgmtItems={allowedDashboardMgmtItems} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function NavigationItems({ allowedDashboardItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedDashboardItems
                    .map((docCenterItem) => (
                        <div className="tile-wrapper" key={docCenterItem.id}>
                            <LinkToRoute
                                id={docCenterItem.id}
                                to={docCenterItem.linkTo}
                            >
                                <Tile
                                    title={docCenterItem.titleTranslationKey}
                                    lazyLoadSvg={{
                                        id: docCenterItem.lazyLoadSvgId,
                                        group: SVG_GROUP_NAME.DOCUMENT_CENTER,
                                    }}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}

function NavigationManagementItems({ allowedDashboardMgmtItems }: Partial<IPrivateProps>) {
    return (
        <>
            {
                allowedDashboardMgmtItems
                    .map((docCenterItem) => (
                        <div className="tile-wrapper" key={docCenterItem.id}>
                            <LinkToRoute
                                id={docCenterItem.id}
                                to={docCenterItem.linkTo}
                            >
                                <Tile
                                    title={docCenterItem.titleTranslationKey}
                                    svg={docCenterItem.svgSrc}
                                    typeName="green"
                                    arrow={true}
                                />
                            </LinkToRoute>
                        </div>
                    ))
            }
        </>
    );
}
