import { get } from '../../../../../utils/api/requestWrapper';
import { IActivityReport, IFetchActivityReportsPayload } from '../../../../../models/general/documents';
import { ONE_MINUTE } from '../../../../../utils/core/time/periodsInMillis';
import { URL } from '../../documents.const';

export function fetchActivityReports(payload: IFetchActivityReportsPayload) {
    return get<IActivityReport[]>({
        url: URL.ACTIVITY_REPORTS,
        pathParams: {
            companyCode: payload.companyCode,
            year: payload.year,
        },
        queryParams: {
            showFullFamily: payload.showFullFamily,
        },
        mapResponse: (response) => response['activity-reports'],
        timeoutInMillis: ONE_MINUTE,
    });
}
