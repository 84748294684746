import React, { ReactNode, PureComponent, RefObject } from 'react';
import './sticky-footer.scss';
import classNames from 'classnames';
import isInViewport from '../../../../utils/dom/isInViewport';
import * as screenSizeUtils from '../../../../utils/dom/screenSize';

interface IStickyFooterProps {
    children: ReactNode;
    className?: string;
}

interface IComponentState {
    isFixed: boolean;
    width: number;
}

const CLASS_NAME = 'StickyFooter';

export default class StickyFooter extends PureComponent<IStickyFooterProps, IComponentState> {
    private refStickyFooter: RefObject<HTMLDivElement>;
    private checkFixedElementsStatesTimeout: number;

    constructor(props: IStickyFooterProps) {
        super(props);

        this.state = {
            isFixed: false,
            width: 0,
        };

        this.refStickyFooter = React.createRef();

        this.onScroll = this.onScroll.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }

    public render() {
        const { children, className } = this.props;
        const { isFixed, width } = this.state;

        return (
            <>
                <div className={classNames(CLASS_NAME, { hidden: !!isFixed })} ref={this.refStickyFooter}>
                    <div className={className}>
                        {children}
                    </div>
                </div>
                <div
                    className={classNames(CLASS_NAME, `${CLASS_NAME}--fixed`, { fixed: !!isFixed })}
                    style={{ width }}
                >
                    <div className={className}>
                        {children}
                    </div>
                </div>
            </>
        );
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onWindowResize);

        this.checkFixedElementsStatesTimeout = window.setTimeout(() => this.checkFixedElementsStates(), 300);
    }

    public componentWillUnmount() {
        if (this.checkFixedElementsStatesTimeout) {
            window.clearTimeout(this.checkFixedElementsStatesTimeout);
        }

        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate() {
        this.checkFixedElementsStates();
        this.updateWidth();
    }

    private onScroll() {
        this.checkFixedElementsStates();
    }

    private onWindowResize() {
        this.checkFixedElementsStates();
        this.updateWidth();
    }

    private checkFixedElementsStates() {
        if (this.refStickyFooter.current) {
            const el = this.refStickyFooter.current;

            this.setState({
                isFixed: !isInViewport(el),
            });
        }
    }

    private updateWidth() {
        if (screenSizeUtils.isExtraSmallScreen()) {
            return;
        }

        if (this.refStickyFooter.current) {
            this.setState({
                width: this.refStickyFooter.current.offsetWidth,
            });
        }
    }
}
