import { object } from 'yup';
import { ErrorTypes } from '../../../../../models/general/error';
import string from '../../../../../utils/libs/yup/string';
import { internationalAddressSchema } from '../../../../common/address/schema';

export const fields = {
    companyCode: 'companyCode',
    name: 'name',
    address: 'address',
};

export const schema = object({
    [fields.companyCode]: string().required(),
    [fields.name]: string().required(),
    [fields.address]: object()
        .test(
            ErrorTypes.Required,
            'The \'${path}\' is not valid',
            isValidAddress,
        ),
});

function isValidAddress(value: { [field: string]: string }) {
    try {
        internationalAddressSchema.validateSync(value);
    } catch (validationError) {
        return false;
    }

    return true;
}
