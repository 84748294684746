import React, { Component } from 'react';
import classNames from 'classnames';

import { fetchReservedMedicalExaminations } from '../../../../../redux/medicalExamination/actions';
import {
    getBufferzonePlannedTimeslots,
    getBufferzoneTimeslotsAsyncInfo,
} from '../../../../../redux/intervention/bufferzones/selectors';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';
import { IPlanBufferzoneWizardPayload } from '../../../../../models/interventions/bufferzones';
import { ListItem } from '../../../../../models/general/list';
import { resetPlanBufferzoneWizardEntity } from '../../../../../redux/intervention/bufferzones/actions';
import exportListDataToCsv from '../../../../../utils/file/csv/exportListDataToCsv';
import ROUTE_KEYS from '../../../../../routeKeys';
import { connect } from '../../../../index';
import { IRenderMasterContentProps } from '../../../../common/widget/MasterWithDetail/typings';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Checkbox from '../../../../common/input/Checkbox';
import ListActionButton from '../../../../common/buttons/ListActionButton';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import PageHeader from '../../../../appShell/PageHeader';
import Translate from '../../../../common/Translate';
import {
    mapPlannedMedicalExaminationForExport,
} from '../../../MedicalExaminations/Planned/index/PlannedMedicalExaminations.helper';

import {
    BASE_NAME,
    TRANSLATION_PREFIX,
} from './PlanBufferzoneWizardOverview.const';
import {
    IPlanBufferzoneWizardOverviewComponentState,
    IPlanBufferzoneWizardOverviewListColumnNames,
    IPlanBufferzoneWizardOverviewPrivateProps,
    IPlanBufferzoneWizardOverviewProps,
} from './PlanBufferzoneWizardOverview.type';
import {
    modifiedExaminationsAsListItemsMemoizedSelector,
    modifiedExaminationsMemoizedSelector,
    plannedExaminationsAsListItemsMemoizedSelector,
} from './PlanBufferzoneWizardOverview.selector';
import {
    PlanBufferzoneWizardOverviewList,
} from './PlanBufferzoneWizardOverviewList/PlanBufferzoneWizardOverviewList.component';

class OverPlanBufferzoneWizardOverviewComponent extends Component<
    IPlanBufferzoneWizardOverviewProps,
    IPlanBufferzoneWizardOverviewComponentState
> {
    public constructor(props: IPlanBufferzoneWizardOverviewProps) {
        super(props);

        this.state = {
            showOnlyModifiedTimeslots: false,
        };

        this.toggleView = this.toggleView.bind(this);
        this.onExport = this.onExport.bind(this);
    }

    public render() {
        const { currentRoutePayload, renderStepButtons, modifiedExams, plannedExams, finishWizard } = this.props;
        const { showOnlyModifiedTimeslots } = this.state;

        const disableExport = showOnlyModifiedTimeslots ?
            modifiedExams.length <= 0 : plannedExams.length <= 0;

        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        masterConfig={{
                            routeKey: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
                            routePayload: currentRoutePayload,
                            asyncInfoSelector: getBufferzoneTimeslotsAsyncInfo,
                            // ListItems rendered directly in OverviewList
                            dataSelector: () => [],
                            renderContent: (
                                renderProps: IRenderMasterContentProps<
                                    ListItem<IPlanBufferzoneWizardOverviewListColumnNames>[]
                                >,
                            ) => <PlanBufferzoneWizardOverviewList {...this.props} {...renderProps} {...this.state} />,
                        }}
                        headerConfig={{
                            renderActionContentOnTheLeft: () => (
                                <div>
                                    <Checkbox
                                        name="toggle-view"
                                        checked={showOnlyModifiedTimeslots}
                                        onChange={this.toggleView}
                                    >
                                        <Translate msg={`${TRANSLATION_PREFIX}.toggle_view`} />
                                    </Checkbox>
                                </div>
                            ),
                            renderActionContent: () => (
                                <ListActionButton
                                    id="bufferzone-planned-medexams-export"
                                    type="text"
                                    iconTypeName="excel"
                                    translationKey="common.master_with_detail.action.export"
                                    onClick={this.onExport}
                                    disabled={disableExport}
                                />
                            ),
                        }}
                        footerConfig={{
                            renderActionsRight: () =>
                                renderStepButtons({
                                    nextButton: {
                                        alwaysEnabled: true,
                                        onClick: finishWizard,
                                        translationKey: `${TRANSLATION_PREFIX}.finish_planning`,
                                    },
                                }),
                        }}
                    />
                </div>
            </>
        );
    }

    private toggleView(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ showOnlyModifiedTimeslots: e.target.checked });
    }

    private onExport() {
        const { plannedExams, modifiedExams } = this.props;
        const { showOnlyModifiedTimeslots } = this.state;

        const itemsToExport = showOnlyModifiedTimeslots ? modifiedExams : plannedExams;

        const exportItems = itemsToExport.map(mapPlannedMedicalExaminationForExport);

        exportListDataToCsv({
            // eslint-disable-next-line max-len
            baseFilename: `MyMensura-bufferzone-${showOnlyModifiedTimeslots ? 'new' : 'all'}-medical-examinations-export`,
            listData: exportItems,
        });
    }
}

export const PlanBufferzoneWizardOverview = connect<IPlanBufferzoneWizardOverviewPrivateProps>({
    stateProps: (state) => {
        return {
            currentRoutePayload: getRoutePayload<IPlanBufferzoneWizardPayload>(state),
            plannedExamsListItems: plannedExaminationsAsListItemsMemoizedSelector(state),
            modifiedExamsListItems: modifiedExaminationsAsListItemsMemoizedSelector(state),
            plannedExams: getBufferzonePlannedTimeslots(state),
            modifiedExams: modifiedExaminationsMemoizedSelector(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            finishWizard: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
                dispatch(resetPlanBufferzoneWizardEntity());
                dispatch(fetchReservedMedicalExaminations({}));
            },
        };
    },
})(OverPlanBufferzoneWizardOverviewComponent);
