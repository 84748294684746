import React from 'react';
import SeatInput, { IFormValues, schema } from './SeatInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { updateFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/actions';
import { getFluVaccinesOrderWizardEntity } from '../../../../../redux/intervention/fluVaccine/selectors';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';

const CLASS_NAME = 'OrderFluVaccine__Seat';
const FORM_NAME = 'flu-vaccine-seat-form';

const SeatWizardFormStep = WizardFormStep<IFormValues>();

export default function Seat(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <SeatWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.flu_orders_new.steps.seat.title',
            }}
            showLoaderSelector={() => false}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    return {
                        seat: getFluVaccinesOrderWizardEntity(state).seat,
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <SeatInput {...renderProps} />,
                onChangeInputActionCreator: ({ values }) => updateFluVaccinesOrderWizardEntity({
                    seat: values.seat,
                }),
            }}
        />
    );
}
