import { createEpic } from '../../../index';
import {
    fetchNoShowsExaminationsActions,
} from '../../actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
} from '../../../company/selected/selectors';
import { getQueryParams, getLocationState } from '../../../location/selectors';
import {
    INoShowsMedicalExaminationsFilter,
    INoShowMedicalExamination,
} from '../../../../models/interventions/medicalExaminations';
import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import {
    DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../../api/interventions/medicalExaminations.api';
import Api from '../../../../api';
import ROUTE_KEYS from '../../../../routeKeys';
import {
    FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
} from '../../types';
import { areNoShowsMedicalExaminationsAvailable } from '../../selectors';

// fetchNoShowsMedicalExaminationsEpic
createEpic<INoShowMedicalExamination[], INoShowsMedicalExaminationsFilter>({
    onActionType: [
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS,
        FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    ],
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS,
            ...query,
        };
        return type !== ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
            || !areNoShowsMedicalExaminationsAvailable(getState());
    },
    processReturn: fetchNoShowsMedicalExaminations,
    latest: false,
});

async function fetchNoShowsMedicalExaminations({ api, getState }: { api: typeof Api, getState }) {
    try {
        const state = getState();
        const filterFromQuery = getQueryParams(state) as INoShowsMedicalExaminationsFilter;

        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

        const noShowsMedicalExaminations = await api.interventions.medicalExaminations
            .fetchNoShows(
                companyCode,
                filterFromQuery,
                showFullFamily,
            );

        return fetchNoShowsExaminationsActions.succeeded(noShowsMedicalExaminations);
    } catch (error) {
        return fetchNoShowsExaminationsActions.failed(error);
    }
}
