import React from 'react';
import { IEventProps } from '../../../TimeGridEvent';
import classNames from 'classnames';
import './default-event.scss';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../../../config/calendar.config';

function DefaultEvent({
    label, title, continuesEarlier,
    continuesLater, selected, className,
    event,
}: IEventProps) {
    const config = CALENDAR_EVENT_TYPES_CONFIG[event.type];
    const extraCssClassName = config ? config.cssClassName : '';
    const cssClassName = classNames('rbc-event', className, {
        'rbc-selected': selected,
        'rbc-event-continues-earlier': continuesEarlier,
        'rbc-event-continues-later': continuesLater,
        'rbc-event-allday': event.allDay,
        [extraCssClassName]: !!config,
    });

    if (event.allDay) {
        return (
            <button className={cssClassName}>
                <div className="rbc-event-content">
                    {title}
                </div>
            </button>
        );
    }

    return (
        <span className={cssClassName}>
            <div className="rbc-event-label">
                {label}
            </div>
            <div className="rbc-event-content">
                {title}
            </div>
        </span>
    );
}

export default DefaultEvent;
