import registerCookieConsentJourneys
    from '../../snipsonian/react-cookie-consent/src/redux/registerCookieConsentJourneys';
import api from '../../api';
import { ICookiesConfiguration, IExtraCookieMetaData } from '../../models/general/cookieConsent';
import { getLocale } from '../i18n/selectors';
import { IState } from '../IState';

registerCookieConsentJourneys<ICookiesConfiguration, IExtraCookieMetaData>({
    getConfiguration: ({ getState }) => {
        const state = getState();
        return api.general.cookies.memoizedCookiesConfiguration({
            locale: getLocale((state as unknown as IState)),
        });
    },
    preCookiesUpdate: () => {
        return api.general.cookies.createCookieLogEntry();
    },
});
