import React from 'react';

import {
    getToAdministerVaccinations,
    getToAdministerVaccinationsAsyncInfo,
} from '../../../../redux/intervention/vaccinations/selectors';
import { ListItem } from '../../../../models/general/list';
import ROUTE_KEYS from '../../../../routeKeys';
import { connect } from '../../../index';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import { RequestVaccinationDialog } from '../shared/RequestVaccinationDialog/RequestVaccinationDialog.component';

import { ToAdministerVaccinationsList } from './ToAdministerVaccinationsList/ToAdministerVaccinationsList';
import {
    IToAdministerVaccinationsListColumnNames,
    TToAdministerVaccinationsListProps,
} from './ToAdministerVaccinationsList/ToAdministerVaccinationList.types';
import {
    ToAdministerVaccinationsFilters,
} from './ToAdministerVaccinationsFilters/ToAdministerVaccinationsFilters.component';
import {
    TToAdministerVaccinationsFilterContentProps,
} from './ToAdministerVaccinationsFilters/ToAdministerVaccinationsFilters.type';
import { BASE_NAME } from './to-administer.const';
import {
    clientSideFilterOfListData,
    getDefaultQueryParams,
    mapListRowForExport,
    mapToAdministerVaccinationsToListItems,
    shouldRenderShowAllButton,
    toListId,
    transformFilterValuesToActiveFilters,
} from './to-administer.helpers';
import { IToAdministerVaccinationsState, IToAdministerVaccinationsPrivateProps } from './to-administer.types';

class VaccinationsToAdminister extends React.Component<
    IToAdministerVaccinationsPrivateProps,
    IToAdministerVaccinationsState
> {
    constructor(props) {
        super(props);

        this.state = {
            isRequestTimeslotOpen: false,
            selectedVaccination: null,
        };
    }

    private handleOpenRequestTimeslotDialog = () => {
        this.setState({
            isRequestTimeslotOpen: true,
        });
    };

    private handleCloseRequestTimeslotDialog = () => {
        this.setState({
            isRequestTimeslotOpen: false,
        });
    };

    public handlePlanVaccination = (listItem: ListItem<IToAdministerVaccinationsListColumnNames>) => {
        this.handleOpenRequestTimeslotDialog();

        this.setState({
            selectedVaccination: listItem,
        });
    };

    render () {
        const { isRequestTimeslotOpen, selectedVaccination } = this.state;

        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    asyncInfoSelector: getToAdministerVaccinationsAsyncInfo,
                    clientSideFilterOfListData,
                    dataSelector: getToAdministerVaccinations,
                    filterValidationSchema: startEndDateSchema,
                    routeKey: ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER,
                    transformData: mapToAdministerVaccinationsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: TToAdministerVaccinationsListProps) => {
                        return (
                            <>
                                <ToAdministerVaccinationsList
                                    {...renderProps}
                                    {...this.props}
                                    {...this.state}
                                    onPlanVaccination={this.handlePlanVaccination}
                                />
                                <RequestVaccinationDialog
                                    show={isRequestTimeslotOpen}
                                    onClose={this.handleCloseRequestTimeslotDialog}
                                    selectedVaccination={selectedVaccination}
                                />
                            </>
                        );
                    },
                }}
                headerConfig={{
                    renderFilterContent:
                        (renderProps: TToAdministerVaccinationsFilterContentProps) =>
                            <ToAdministerVaccinationsFilters {...renderProps} />,
                    exportButton: {
                        baseFilename: 'to-administer-vaccinations',
                        listItemIdExtractor: toListId,
                        mapListRowForExport,
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        );
    }
}

export default connect<IToAdministerVaccinationsPrivateProps>({
    stateProps: (state) => ({}),
    dispatchProps: (dispatch) => ({}),
})(VaccinationsToAdminister);
