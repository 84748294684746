import React, { ReactNode } from 'react';
import BaseTypeahead, { IBaseTypeaheadProps } from './BaseTypeahead';
import { TDropdownItemValue } from '../Dropdown';

export interface ITypeaheadDataItem<Value = TDropdownItemValue> {
    label: string;
    dropdownItemContent?: ReactNode; // When not set the label is used
    value: Value;
    sticky?: boolean;
    disableSort?: boolean;
}

export type TTypeaheadData = ITypeaheadDataItem[];

export interface ITypeaheadProps extends Pick<
    IBaseTypeaheadProps,
    'id' | 'name' | 'data' | 'onItemSelected' | 'placeholder' | 'isInvalid' |
    'disabled' | 'children' | 'onFocus' | 'disableReset' | 'asyncInfoSelector' |
    'onBlur' | 'disableSort' | 'autoSelectOnSingleOption'
> {
    value: TDropdownItemValue;
}

/**
 * Simple version of the Typeahead
 * Only supports data which is directly available on the client (no async fetching via for example ajax calls)
 */
function Typeahead(props: ITypeaheadProps) {
    return <BaseTypeahead {...props} autoFilteringDisabled={false} />;
}

export default Typeahead;
