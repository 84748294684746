import React, { Component, ReactElement } from 'react';
import './company-list.scss';
import { ICompanyDismissableWithDate } from '../../../models/admin/company';
import { ITranslator } from '../../../models/general/i18n';
import { ListColumns, SortType, SortOrder, ISortedColumn, ListItem } from '../../../models/general/list';
import { IAsyncFieldInfo } from '../../../models/general/redux';
import { connect } from '../../index';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import ListActions from '../../common/list/ListActions';
import ListWithRadioButtonSelect from '../../common/list/ListWithRadioButtonSelect';
import Translate from '../../common/Translate';
import { formatAddressStreet, formatAddressCity } from '../../../utils/formatting/formatAddress';
import filterListItems from '../../../utils/list/filterListItems';
import { IListFooterProps } from '../../common/list/ListFooter';
import CompanyConditionTooltip from './CompanyConditionTooltip';
import Checkbox from '../../common/input/Checkbox';

interface IPrivateProps {
    listItems: ListItem<IColumnNames>[];
    translator: ITranslator;
}

interface ICompanyListProps {
    name: string;
    selectedCompanyCompanyCode: string;
    withSearch?: boolean;
    onCompanySelected: (itemId: string | number) => void;
    initialSearchValue?: string;
    companies: ICompanyDismissableWithDate[];
    fetchAsyncInfo: IAsyncFieldInfo;
    onSearchInput?: (searchValue: string) => void;
    footer?: ReactElement<IListFooterProps>;
}

interface IState {
    searchValue: string;
    showOnlyActiveCompanies: boolean;
}

interface IColumnNames {
    condition: string;
    dismissed: boolean;
    name: string;
    companyCode: string;
    street: string;
    city: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'name',
    sortOrder: SortOrder.Ascending,
};

const CLASS_NAME = 'CompanyList';

class CompanyList extends Component<IPrivateProps & ICompanyListProps, IState> {
    private columns: ListColumns<IColumnNames>;

    constructor(props: IPrivateProps & ICompanyListProps) {
        super(props);

        this.columns = {
            condition: {
                sortable: false,
                render: this.renderCompanyConditionTooltip.bind(this),
                percentWidth: 5,
            },
            dismissed: {
                hide: true,
                percentWidth: null,
            },
            name: {
                label: <Translate msg="account.company_selection.columns.name" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 36,
            },
            companyCode: {
                label: <Translate msg="account.company_selection.columns.company_code" />,
                sortable: true,
                sortType: SortType.DotSeparatedNumber,
                percentWidth: 14,
            },
            street: {
                label: <Translate msg="account.company_selection.columns.street" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 25,
            },
            city: {
                label: <Translate msg="account.company_selection.columns.city" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 20,
            },
        };

        this.state = {
            searchValue: props.initialSearchValue,
            showOnlyActiveCompanies: true,
        };

        this.onSearchInput = this.onSearchInput.bind(this);
        this.onToggleShowInactive = this.onToggleShowInactive.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
    }

    public render() {
        const {
            name, initialSearchValue, listItems,
            translator, selectedCompanyCompanyCode, onCompanySelected,
            fetchAsyncInfo, footer, withSearch,
        } = this.props;

        const { searchValue, showOnlyActiveCompanies } = this.state;

        const activeCompanies = listItems.filter((item) => !item.columns.dismissed);
        const inactiveCompanies = listItems.filter((item) => item.columns.dismissed);
        const displayItems = showOnlyActiveCompanies ?
            filterListItems(activeCompanies, searchValue) :
            filterListItems(listItems, searchValue);
        const inactiveFilteredCompanies = filterListItems(inactiveCompanies, searchValue);

        return (
            <>
                <ListActions
                    name={name}
                    withSelectAll={false}
                    withSearch={withSearch}
                    searchPlaceholder={translator('account.company_selection.search_placeholder')}
                    onSearchInput={this.onSearchInput}
                    initialSearchValue={initialSearchValue}
                    searchDisabled={!!fetchAsyncInfo.error}
                    customListAction={inactiveCompanies.length > 0 && (
                        <div className={`${CLASS_NAME}__list-action`}>
                            <Checkbox
                                name="toggle-visibility-inactive-companies-from-list"
                                checked={!showOnlyActiveCompanies}
                                onChange={this.onToggleShowInactive}
                            >
                                <Translate
                                    msg="account.company_selection.toggle_inactive_companies"
                                    placeholders={{ count: inactiveFilteredCompanies.length }}
                                />
                            </Checkbox>
                        </div>
                    )}
                />
                <div className={`${CLASS_NAME}__list`}>
                    <ListWithRadioButtonSelect
                        withSorting={true}
                        initialSort={INITIAL_SORT}
                        name={name}
                        columns={this.columns}
                        items={displayItems || []}
                        onItemSelected={onCompanySelected}
                        selectedItemId={selectedCompanyCompanyCode ? selectedCompanyCompanyCode : null}
                        errorMessage={fetchAsyncInfo.error &&
                            <ErrorPlaceholder
                                typeName="block"
                                apiError={fetchAsyncInfo.error}
                            />
                        }
                        footer={footer}
                        getCustomRowClasses={this.getCustomRowClasses}
                    />
                </div>
            </>
        );
    }

    private onSearchInput(searchValue: string) {
        this.setState({
            searchValue,
        });
        if (typeof this.props.onSearchInput === 'function') {
            this.props.onSearchInput(searchValue);
        }
    }

    private getCustomRowClasses(listItem: ListItem<IColumnNames>) {
        const { companies } = this.props;
        const matchingCompany = companies.find((company) => company.companyCode === listItem.id);

        return (matchingCompany && matchingCompany.dismissed)
            ? 'inactive'
            : null;
    }

    private renderCompanyConditionTooltip(listItem: ListItem<IColumnNames>) {
        const company = this.props.companies
            .find((company) => company.companyCode === listItem.id);

        return (
            <CompanyConditionTooltip company={company} />
        );
    }

    private onToggleShowInactive() {
        this.setState((prevState) => ({
            showOnlyActiveCompanies: !prevState.showOnlyActiveCompanies,
        }));
    }
}

export default connect<IPrivateProps, ICompanyListProps>({
    statePropsDeprecated: (state, pulicProps) => {
        return {
            listItems: mapCompaniesToListItems(pulicProps.companies),
            translator: getTranslatorDeprecated(state),
        };
    },
})(CompanyList);

function mapCompaniesToListItems(companies: ICompanyDismissableWithDate[]): ListItem<IColumnNames>[] {
    return companies.map((company) => {
        return {
            id: company.companyCode,
            columns: {
                condition: null,
                dismissed: company.dismissed,
                name: company.name,
                companyCode: company.companyCode,
                street: formatAddressStreet(company.address),
                city: formatAddressCity(company.address),
            },
        };
    });
}
