import React, { ReactNode } from 'react';
import './medical-examinations.scss';
import connect from '../../../utils/libs/redux/connect';
import WithMenuTemplate from '../../appShell/templates/WithMenuTemplate';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import PageHeader from '../../appShell/PageHeader';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import ROUTE_KEYS from '../../../routeKeys';
import { navigateTo } from '../../../redux/location/actions';
import { ITabItem } from '../../../models/general/navigation';
import { getMedicalExaminationsToPlan } from '../../../redux/medicalExamination/selectors';
import { ITemplate } from '../../../routes';
import { INTERVENTION_ITEMS } from '../../../config/navigation/interventions.config';
import { isCompanyAnInterimCompany } from '../../../redux/company/selected/selectors';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

interface IPrivateProps {
    goToPlanMedicalExamination: () => void;
    showPlannableRecordsNotification: boolean;
    isInterimCompany: boolean;
}

export interface IMedicalExaminationsTemplateRenderProps {
    renderPlanButton?: () => JSX.Element;
}

export interface IMedicalExaminationsTemplateProps {
    children: (renderProps: IMedicalExaminationsTemplateRenderProps) => ReactNode;
}

const HAS_TO_BE_PLANNED_EMPLOYEES_NOTIFICATION_TEXT = '!';

const ConnectedTemplate = connect<IPrivateProps, IMedicalExaminationsTemplateProps>({
    stateProps: (state) => {
        const medicalExaminationsToPlan = getMedicalExaminationsToPlan(state);

        const hasMedicalExaminationsToPlan = medicalExaminationsToPlan.some((medicalExamination) => {
            return medicalExamination.toBePlanned;
        });

        return {
            showPlannableRecordsNotification: hasMedicalExaminationsToPlan,
            isInterimCompany: isCompanyAnInterimCompany(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            goToPlanMedicalExamination: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES));
            },
        };
    },
})(MedicalExaminationsTemplate);

const Template: ITemplate = function Template({
    children,
}: IMedicalExaminationsTemplateProps) {
    return (
        <ConnectedTemplate>
            {children}
        </ConnectedTemplate>
    );
};

Template.hasRenderProps = true;

export default Template;

function MedicalExaminationsTemplate(props: IPrivateProps & IMedicalExaminationsTemplateProps) {
    const { children, goToPlanMedicalExamination, showPlannableRecordsNotification, isInterimCompany } = props;

    const TABS: ITabItem[] = [{
        translationKey: 'interventions.medical_examinations.to_plan.tab_title',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN,
        hide: isInterimCompany,
        ...showPlannableRecordsNotification && {
            notificationContent: HAS_TO_BE_PLANNED_EMPLOYEES_NOTIFICATION_TEXT,
        },
    }, {
        translationKey: 'interventions.medical_examinations.planned.tab_title',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
    }, {
        translationKey: 'interventions.medical_examinations.bufferzones.tab_title',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES,
        hide: isInterimCompany,
    }, {
        translationKey: 'interventions.medical_examinations.executed.tab_title',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED,
    }, {
        translationKey: 'interventions.medical_examinations.no_shows.tab_title',
        linkTo: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS,
        tooltipTranslationKey: 'interventions.medical_examinations.no_shows.tab_tooltip',
    }];

    const DISPLAY_TABS = TABS.filter((tab) => !tab.hide);

    const buttonElement = (
        <ShowIfAllowed routeKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES}>
            <Button
                id="page-header-calendar-button"
                typeName="secondary"
                onClick={goToPlanMedicalExamination}
            >
                <Icon typeName="calendar" />
                <Translate msg="interventions.medical_examinations.action.plan" />
            </Button>
        </ShowIfAllowed>
    );

    const CURRENT_ITEM = INTERVENTION_ITEMS.find((item) => {
        return item.linkTo === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN;
    });

    const lazyLoadSvgId = CURRENT_ITEM
        ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
        : undefined;

    return (
        <WithMenuTemplate>
            <div className="MedicalExaminations">
                <PageHeader
                    breadcrumbs={true}
                    button={buttonElement}
                    tabs={DISPLAY_TABS}
                    title="interventions.medical_examinations.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.INTERVENTIONS,
                    }}
                    type="grey"
                />
                <div className="container">
                    {children({ renderPlanButton: () => buttonElement })}
                </div>
            </div>
        </WithMenuTemplate>
    );
}
