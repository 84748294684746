import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER, makeAsyncDoInfoSelector } from '../../index';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Fetch activity reports

const getActivityReportsAsyncField = (state: IState) => reducerState(state).activityReports;

export const getActivityReports = (state: IState) =>
    getActivityReportsAsyncField(state).data || NO_RERENDER.EMPTY_LIST;
export const getActivityReportsAsyncInfo = makeAsyncFetchInfoSelector(getActivityReportsAsyncField);

// Generate activity report

const getGenerateActivityReportsAsyncField = (state: IState) => reducerState(state).generateActivityReport;

export const getGenerateActivityReportsAsyncInfo = makeAsyncDoInfoSelector(getGenerateActivityReportsAsyncField);

// Generate company and employee report

const getGenerateCompanyAndEmployeeReportAsyncField =
    (state: IState) => reducerState(state).generateCompanyAndEmployeeReport;

export const getGenerateCompanyAndEmployeeReportAsyncInfo =
    makeAsyncDoInfoSelector(getGenerateCompanyAndEmployeeReportAsyncField);

// Activity report detail document

const getActivityReportDetailAsyncField =
    (state: IState) => reducerState(state).activityReportDetail;

export const getActivityReportDetailAsyncInfo =
    makeAsyncDoInfoSelector(getActivityReportDetailAsyncField);
