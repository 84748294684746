export default function formatNationalRegisterNumber(natRegNr: string) {
    return [natRegNr]
        .map(stripExistingNationalRegisterNumberFormatting)
        .map((unformatted) => formatIfRequiredLength(unformatted, natRegNr))
        .pop();
}

export function stripExistingNationalRegisterNumberFormatting(natRegNr: string) {
    const nr = natRegNr || '';
    return nr
        .trim()
        .replace(/\./g, '')
        .replace(/\-/g, '');
}

function formatIfRequiredLength(unformatted: string, original: string) {
    if (unformatted.length !== 11) {
        return original;
    }

    return unformatted.substr(0, 2)
        + '.' + unformatted.substr(2, 2)
        + '.' + unformatted.substr(4, 2)
        + '-' + unformatted.substr(6, 3)
        + '.' + unformatted.substr(9, 2);
}

export function getBirthDateFromNationalRegisterNumber(natRegNr: string) {
    return [natRegNr]
        .map(stripExistingNationalRegisterNumberFormatting)
        .map((unformatted) => extractBirthDateFromNationalRegisterNumber(unformatted))
        .pop();
}

function extractBirthDateFromNationalRegisterNumber(unformatted: string) {
    const controlNumber = parseInt(unformatted.substr(9, 2), 10);
    const numberForCalculatingControlNumber = unformatted.substr(0, 9);

    const yearLastTwoNumbers = unformatted.substr(0, 2);
    // month starts from 0
    const month = parseInt(unformatted.substr(2, 2), 10) - 1;
    const day = parseInt(unformatted.substr(4, 2), 10);

    // '2' gets added before calculation on birthdates after 2000
    const numberForCalculatingControlNumberAfterTwoThousand = parseInt('2' + numberForCalculatingControlNumber, 10);
    const expectedControlNumberResultBornAfterTwoThousand = numberForCalculatingControlNumberAfterTwoThousand % 97;

    const year = controlNumber === 97 - expectedControlNumberResultBornAfterTwoThousand ?
        parseInt('20' + yearLastTwoNumbers, 10) :
        parseInt('19' + yearLastTwoNumbers, 10);

    const birthDate = new Date(year, month, day);

    return birthDate;
}
