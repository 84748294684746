import { TPlaceholderKeys } from './ConfirmEmailModal.type';

export const FORM_NAME = 'user-profile-confirm-email';
export const CLASS_NAME_BASE = 'user-profile-confirm-email-modal';

export const fields = {
    email: 'email',
};

export const TRANSLATION_KEY_PREFIX = 'account.account_settings.profile.personal_data.confirm_email';

export const PLACEHOLDER_KEYS: TPlaceholderKeys = {
    email: `${TRANSLATION_KEY_PREFIX}.fields.email`,
    confirmEmail: `${TRANSLATION_KEY_PREFIX}.placeholder`,
};

export const TRANSLATION_KEYS = {
    title: `${TRANSLATION_KEY_PREFIX}.title`,
    changeTo: `${TRANSLATION_KEY_PREFIX}.change_to`,
    logoutWarning: `${TRANSLATION_KEY_PREFIX}.logout_warning`,
    submit: `${TRANSLATION_KEY_PREFIX}.submit`,
};
