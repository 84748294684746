import { createEpic } from '../../index';
import {
    getSelectedSeatCompanyCode, getSelectedCompanySeat, isAllSeatsSelected,
} from '../../company/selected/selectors';
import { getLocationState, getQueryParams, getRoutePayload } from '../../location/selectors';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IFetchCoursesPayload, ICoursesFilter,
    IFetchCourseDetailPayload,
    IFetchCourseSessionPayload,
    IFetchCourseSessionDaysPayload,
    IFetchCourseSessionAttendeesPayload,
    IFetchCMSCourseDetailPayload,
    IRemoveCourseAttendeePayload,
    IFetchCourseSessionAttendancePayload,
    ICourseEnrollWizardPayload,
    COURSE_ENROLL_WIZARD_STEP_ID,
    IFetchCoursePossibleLocationsPayload,
    IFetchCourseSessionsByLocationPayload,
    ISkipToCourseEnrollmentWizardStepPayload,
    ICourseEnrollWizardEntity,
    IFetchCourseSessionCertificatesPayload,
    IFetchEmployeeCoursesPayload,
    IFetchCourseExternalEmployeePayload,
    IUpdateCourseExternalEmployeePayload,
    IFetchCMSCourseTablePayload,
    IFetchCMSCourseDetailByHawIdPayload,
} from '../../../models/documentCenter/courses';
import {
    areCoursesPlannedAvailable, getSelectedPlannedCourseFromList,
    areCoursesFollowedAvailable, getSelectedFollowedCourseFromList,
    isCoursesOverviewAvailable,
    getSelectedFollowedCourse,
    areCoursePossibleLocationsAvailable,
    getCourseEnrollWizardStepId,
    getCourseEnrollWizardEntity,
    getCoursesOverviewDetail,
    getSelectedPlannedCourse,
    getCourseEnrollWizardCourse,
} from './selectors';
import {
    fetchCoursesPlannedActions, fetchSelectedPlannedCourseActions,
    fetchCourseSessionActions, fetchCourseSessionDaysActions,
    fetchCourseSessionAttendeesActions, fetchCoursesFollowedActions,
    fetchCourseSessionCertificatesActions,
    fetchCoursesOverviewActions, fetchCoursesOverviewDetailActions,
    removeCourseAttendeeActions, setRemoveAttendeeNotPossible, fetchCourseSessionAttendanceActions,
    fetchCoursePossibleLocationsActions,
    fetchCourseSessionsByLocationActions,
    resetCourseEnrollWizardEntity,
    createCourseEnrollWizardEntity,
    navigateToCourseEnrollWizardStep,
    skipToCourseEnrollWizardStepActions,
    createCourseEnrollmentActions,
    fetchEmployeeCoursesActions,
    fetchCourseExternalEmployeeActions,
    updateCourseExternalEmployeeActions,
    fetchCourseTableActions,
    fetchCoursesOverviewDetailByHawActions,
} from './actions';
import {
    DEFAULT_COURSES_PLANNED_FILTER, DEFAULT_COURSES_FOLLOWED_FILTER,
} from '../../../api/documentCenter/courses.api';
import Api from '../../../api';
import { IState } from '../../IState';
import { createNotFoundError } from '../../../utils/api/error/createNotFoundError';
import {
    CREATE_COURSE_ENROLLMENT,
    FETCH_COURSE_EXTERNAL_EMPLOYEE,
    FETCH_COURSE_POSSIBLE_LOCATIONS,
    FETCH_COURSE_SESSION,
    FETCH_COURSE_SESSION_ATTENDANCE,
    FETCH_COURSE_SESSION_ATTENDEES,
    FETCH_COURSE_SESSION_DAYS,
    FETCH_COURSE_SESSIONS_BY_LOCATION,
    FETCH_COURSE_TABLE,
    FETCH_COURSES_FOLLOWED,
    FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
    FETCH_COURSES_PLANNED_DETAIL,
    FETCH_COURSES_SESSION_CERTIFICATES,
    FETCH_EMPLOYEE_COURSES,
    REMOVE_COURSE_ATTENDEE,
    SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
    UPDATE_COURSE_EXTERNAL_EMPLOYEE,
    CREATE_ELEARNING_COURSE_ENROLLMENT,
} from './types';
import { ArgumentAction, StandardAction } from 'redux-logic/definitions/action';
import { ITraceableApiError } from '../../../models/general/error';
import { COURSE_ATTENDEE_REMOVE_NOT_POSSIBLE_ERROR } from '../../../config/courses.config';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { IAction } from '../../../models/general/redux';
import { redirectToRoute } from '../../location/actions';
import { SubmittedFormActionType } from '../../../config/analytics.config';
import { getEnrollCourseWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { isFalsyOrEmptyObject } from '../../../utils/core/object/isEmptyObject';
import { CUSTOM_DIMENSION_COURSE_ID } from '../../../utils/logging/analytics/hitScopedCustomDimensions';
import { isCourseOfTypeOpen } from '../../../views/documentCenter/Courses/shared/courseUtils';
import { getLocale } from '../../i18n/selectors';
import { localeToBackendLocale } from '../../../utils/formatting/formatLocale';
import { toFlattenedFirstdaySessions } from '../../../utils/documentCenter/courses/sessions';

const ACTION_TYPES_THAT_FETCH_FOLLOWED_COURSE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.FOLLOWED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE);

const ACTION_TYPES_THAT_FETCH_PLANNED_COURSE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.PLANNED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE);

// fetchCoursesPlannedEpic
createEpic<IFetchCoursesPayload>({
    onActionType: ROUTE_KEYS.R_COURSES_PLANNED,
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_COURSES_PLANNED_FILTER,
            ...query,
        };

        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
            || !areCoursesPlannedAvailable(getState());
    },
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const result = await doFetchPlannedCoursesCall(api, getState(), dispatch);
            dispatch(fetchCoursesPlannedActions.succeeded(result));
        } catch (error) {
            dispatch(fetchCoursesPlannedActions.failed(error));
        }
        return done();
    },
    latest: false,
});

function doFetchPlannedCoursesCall(
    api: typeof Api, state: IState, dispatch: (action: ArgumentAction) => void, dispatchTrigger: boolean = false) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
    const filterFromQuery = getQueryParams(state) as ICoursesFilter;

    if (dispatchTrigger) {
        dispatch(fetchCoursesPlannedActions.trigger({ companyCode, showFullFamily }));
    }

    return api.documentCenter.courses.fetchCoursesPlanned(
        { companyCode, showFullFamily }, filterFromQuery,
    );
}

// fetchPlannedCourseDetailIfNotAlreadyAvailableEpic
createEpic<IFetchCourseDetailPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_PLANNED_COURSE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => isFalsyOrEmptyObject(getSelectedPlannedCourse(getState())),
    processMultiple: fetchPlannedCourseDetail,
    latest: false,
});

// fetchPlannedCourseDetailEpic
createEpic<IFetchCourseDetailPayload>({
    onActionType: [
        ROUTE_KEYS.R_COURSES_PLANNED_DETAIL,
        FETCH_COURSES_PLANNED_DETAIL,
        ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL,
        ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL,
        ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL,
    ],
    processMultiple: fetchPlannedCourseDetail,
    latest: true,
});

async function fetchPlannedCourseDetail(
    // eslint-disable-next-line max-len
    { action, getState, api }: { action: StandardAction<string, IFetchCourseDetailPayload>, getState: () => IState, api: typeof Api },
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) {
    try {
        const { coursesOrganizedId } = action.payload;
        const state = getState();

        let plannedCourseFromList = getSelectedPlannedCourseFromList(state, Number(coursesOrganizedId));

        // Always refresh when specificly fetching planned detail again
        if (!plannedCourseFromList || action.type === FETCH_COURSES_PLANNED_DETAIL) {
            const plannedCourses = await doFetchPlannedCoursesCall(api, state, dispatch, true);

            dispatch(fetchCoursesPlannedActions.succeeded(plannedCourses));

            plannedCourseFromList = plannedCourses
                .find((item) => item.coursesOrganizedId === Number(coursesOrganizedId));
        }

        if (plannedCourseFromList) {
            fetchCourseDetails(coursesOrganizedId, isCourseOfTypeOpen(plannedCourseFromList), dispatch);

            // Fetch course session attendees.
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            dispatch(fetchCourseSessionAttendeesActions.trigger({
                companyCode,
                showFullFamily,
                coursesOrganizedId: plannedCourseFromList.coursesOrganizedId,
            }));
            dispatch(fetchCoursesOverviewDetailByHawActions.trigger({ id: plannedCourseFromList.id }));
            dispatch(fetchSelectedPlannedCourseActions.succeeded(plannedCourseFromList));
        } else {
            dispatch(fetchSelectedPlannedCourseActions.failed(createNotFoundError()));
        }
        return done();
    } catch (error) {
        dispatch(fetchSelectedPlannedCourseActions.failed(error));
        return done();
    }
}

// fetchCourseSessionEpic
createEpic<IFetchCourseSessionPayload>({
    onActionType: FETCH_COURSE_SESSION,
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSession(action.payload);

            return fetchCourseSessionActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionActions.failed(error);
        }
    },
    latest: true,
});

// fetchCourseSessionDaysEpic
createEpic<IFetchCourseSessionDaysPayload>({
    onActionType: FETCH_COURSE_SESSION_DAYS,
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSessionDays(action.payload);

            return fetchCourseSessionDaysActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionDaysActions.failed(error);
        }
    },
    latest: true,
});

// fetchCourseSessionAttendeesEpic
createEpic<IFetchCourseSessionAttendeesPayload>({
    onActionType: FETCH_COURSE_SESSION_ATTENDEES,
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSessionAttendees(action.payload);

            return fetchCourseSessionAttendeesActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionAttendeesActions.failed(error);
        }
    },
    latest: true,
});

// fetchCoursesFollowedEpic
createEpic<IFetchCoursesPayload>({
    onActionType: [
        ROUTE_KEYS.R_COURSES_FOLLOWED,
        FETCH_COURSES_FOLLOWED,
    ],
    refreshDataIf: ({ getState, action }) => {
        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_COURSES_FOLLOWED_FILTER,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate'])
            || !areCoursesFollowedAvailable(getState());
    },
    processReturn: doFetchFollowedCoursesCall,
    latest: false,
});

async function doFetchFollowedCoursesCall(
    { api, getState, action }: { api: typeof Api, getState, action: IAction<IFetchCoursesPayload> }) {
    try {
        const state = getState();
        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
        const filterFromQuery = getQueryParams(state) as ICoursesFilter;

        const result = await api.documentCenter.courses.fetchCoursesFollowed(
            { companyCode, showFullFamily }, filterFromQuery,
        );

        return fetchCoursesFollowedActions.succeeded(result);
    } catch (error) {
        return fetchCoursesFollowedActions.failed(error);
    }
}

// fetchFollowedCourseDetailIfNotAlreadyAvailableEpic
createEpic<IFetchCourseDetailPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_FOLLOWED_COURSE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !getSelectedFollowedCourse(getState()),
    processMultiple: fetchFollowedCourseDetail,
    latest: false,
});

// fetchFollowedCourseDetailEpic
createEpic<IFetchCourseDetailPayload>({
    onActionType: [
        ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL,
        ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL,
        ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL,
        ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL,
    ],
    processMultiple: fetchFollowedCourseDetail,
    latest: true,
});

function fetchFollowedCourseDetail(
    { action, getState }: { action: StandardAction<string, IFetchCourseDetailPayload>, getState: () => IState },
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) {
    try {
        const { coursesOrganizedId } = action.payload;
        const state = getState();

        const followedCourseFromList = getSelectedFollowedCourseFromList(state, Number(coursesOrganizedId));

        if (!followedCourseFromList) {
            dispatch(fetchCoursesFollowedActions.trigger(action.payload));
        }

        fetchCourseDetails(
            coursesOrganizedId,
            followedCourseFromList && isCourseOfTypeOpen(followedCourseFromList),
            dispatch,
        );

        // Fetch course details from CMS
        dispatch(fetchCoursesOverviewDetailByHawActions.trigger({ id: followedCourseFromList.id }));

        // Fetch course session attendance.
        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
        dispatch(fetchCourseSessionAttendanceActions.trigger({
            companyCode,
            showFullFamily,
            coursesOrganizedId,
        }));
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return done();
}

function fetchCourseDetails(
    coursesOrganizedId: number, fetchSessions: boolean, dispatch: (action: ArgumentAction) => void) {
    if (fetchSessions) {
        // Fetch course session.
        dispatch(fetchCourseSessionActions.trigger({ id: coursesOrganizedId }));
    } else {
        dispatch(fetchCourseSessionActions.reset({}));
    }
    // Fetch course session days.
    dispatch(fetchCourseSessionDaysActions.trigger({
        coursesOrganizedId,
    }));
}

// fetchCourseSessionCertificatesEpic
createEpic<IFetchCourseSessionCertificatesPayload>({
    onActionType: FETCH_COURSES_SESSION_CERTIFICATES,
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSessionCertificates(
                action.payload,
            );

            return fetchCourseSessionCertificatesActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionCertificatesActions.failed(error);
        }
    },
    latest: true,
});

// fetchCoursesOverviewEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COURSES_OVERVIEW,
    refreshDataIf: ({ getState, action }) => {
        const { type } = getLocationState(getState());
        return type !== action.type
            || !isCoursesOverviewAvailable(getState());
    },
    async processReturn({ api }) {
        try {
            const result = await api.documentCenter.courses.fetchCoursesOverview();
            return fetchCoursesOverviewActions.succeeded(result);
        } catch (error) {
            return fetchCoursesOverviewActions.failed(error);
        }
    },
    latest: false,
});

const coursesDetailRoutes: ROUTE_KEYS[] = [
    ROUTE_KEYS.R_COURSES_DETAIL_INTRO,
    ROUTE_KEYS.R_COURSES_DETAIL_COURSE,
];

// fetchCoursesOverviewDetailEpic
createEpic<IFetchCMSCourseDetailPayload>({
    onActionType: coursesDetailRoutes,
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        const { type: prevRoute, payload } = getLocationState(state);
        const courseOverviewDetail = getCoursesOverviewDetail(state);
        const requestedRoute = action.type as ROUTE_KEYS;
        const prevRoutePayload = payload as { nodeId: number };

        if (!courseOverviewDetail) {
            return true;
        }

        // when navigating between pages of the detail, do not refresh data if same nodeId
        if (
            coursesDetailRoutes.includes(prevRoute)
            && coursesDetailRoutes.includes(requestedRoute)
            && action.payload.nodeId
            && prevRoutePayload.nodeId
            && action.payload.nodeId === prevRoutePayload.nodeId
        ) {
            return false;
        }

        // only refresh data if other courseId in state
        if (
            action.payload.nodeId
            && courseOverviewDetail.course
            && action.payload.nodeId === courseOverviewDetail.course.nodeId
        ) {
            return false;
        }

        return true;
    },
    async processReturn({ api, action }) {
        try {
            const courseDetail = await api.documentCenter.courses.fetchCoursesOverviewDetail(action.payload);
            return fetchCoursesOverviewDetailActions.succeeded(courseDetail);
        } catch (error) {
            return fetchCoursesOverviewDetailActions.failed(error);
        }
    },
    latest: true,
});

createEpic<IFetchCMSCourseDetailByHawIdPayload>({
    onActionType: FETCH_COURSES_OVERVIEW_DETAIL_BY_HAW,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const courseDetail = await api.documentCenter.courses.fetchCourseByHawId(action.payload);

            dispatch(fetchCoursesOverviewDetailActions.succeeded(courseDetail));
            dispatch(fetchCoursesOverviewDetailByHawActions.succeeded({}));

            return done();
        } catch (error) {
            dispatch(fetchCoursesOverviewDetailByHawActions.failed(error));
            return done();
        }
    },
    latest: true,
});

// fetchCourseTableEpic
createEpic<IFetchCMSCourseTablePayload>({
    onActionType: [
        ROUTE_KEYS.R_COURSES_DETAIL_COURSE,
        FETCH_COURSE_TABLE,
    ],
    async processReturn({ api, action }) {
        try {
            const courseTable = await api.documentCenter.courses.fetchCourseTable(action.payload);
            return fetchCourseTableActions.succeeded(courseTable);
        } catch (error) {
            return fetchCourseTableActions.failed(error);
        }
    },
    latest: true,
});

// removeCourseAttendeeEpic
createEpic<IRemoveCourseAttendeePayload>({
    onActionType: REMOVE_COURSE_ATTENDEE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.documentCenter.courses.removeCourseAttendee(action.payload);

            dispatch(removeCourseAttendeeActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.TRAINING_COURSE_REMOVED,
                    extraDataToLog: {
                        [CUSTOM_DIMENSION_COURSE_ID]: action.payload.courseId,
                    },
                }));
        } catch (error) {
            const errorObj = error as ITraceableApiError;
            if (
                errorObj.message === COURSE_ATTENDEE_REMOVE_NOT_POSSIBLE_ERROR
            ) {
                // Dispatch this action so a dialog will appear.
                dispatch(setRemoveAttendeeNotPossible());
            }

            dispatch(removeCourseAttendeeActions.failed(error));
        }

        return done();
    },
    latest: false,
});

// fetchCourseSessionAttendanceEpic
createEpic<IFetchCourseSessionAttendancePayload>({
    onActionType: [
        FETCH_COURSE_SESSION_ATTENDANCE,
    ],
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSessionAttendance(action.payload);

            return fetchCourseSessionAttendanceActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionAttendanceActions.failed(error);
        }
    },
    latest: true,
});

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the course detail
 */
// validateCourseEnrollWizardStepIdEpic
createEpic<ICourseEnrollWizardPayload>({
    onActionType: ROUTE_KEYS.R_COURSE_ENROLL_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const { step, skipToStep } = action.payload;
        const currentCourse = getCoursesOverviewDetail(getState());

        const COURSE_ENROLL_STEP_IDS =
            getEnrollCourseWizardSteps().stepIds;
        if (!COURSE_ENROLL_STEP_IDS.includes(step)) {
            if (currentCourse && currentCourse.course) {
                return next(redirectToRoute(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                    courseId: currentCourse.course.nodeId,
                }));
            }
            return next(redirectToRoute(ROUTE_KEYS.R_COURSES_OVERVIEW));
        }

        // check no step skipped
        const currentStep = getCourseEnrollWizardStepId(getState());
        const currentStepIndex = COURSE_ENROLL_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = COURSE_ENROLL_STEP_IDS.indexOf(step);
        if (!skipToStep && requestedStepIndex > currentStepIndex + 1) {
            if (currentCourse && currentCourse.course) {
                return next(redirectToRoute(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                    courseId: currentCourse.course.nodeId,
                }));
            }
            return next(redirectToRoute(ROUTE_KEYS.R_COURSES_OVERVIEW));
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringCourseEnrollmentWizardEpic
createEpic<ICourseEnrollWizardPayload>({
    onActionType: ROUTE_KEYS.R_COURSE_ENROLL_NEW,
    async processMultiple({ api, action, getState }, dispatch, done) {
        const { step } = action.payload;
        const state = getState();

        const wizardEntity = getCourseEnrollWizardEntity(state);
        const wizardCourse = getCourseEnrollWizardCourse(state);

        if (!wizardEntity) {
            // probably because of a deep link directly to the first step
            dispatch(redirectToRoute(ROUTE_KEYS.R_COURSES_OVERVIEW));
            return done();
        }

        if (step === COURSE_ENROLL_WIZARD_STEP_ID.COURSE) {
            dispatch(fetchCoursePossibleLocationsActions.trigger({
                id: wizardCourse.id,
            }));

            // If we already have a location selected, fetch epics when deeplinking or refreshing page
            const location = wizardEntity.location;
            if (location && location.id) {
                dispatch(fetchCourseSessionsByLocationActions.trigger({
                    id: wizardCourse.id,
                    locationId: location.id,
                }));
            }

            return done();
        }

        if (step === COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE) {
            // Fetch course session attendees.
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            if (wizardEntity.session) {
                dispatch(fetchCourseSessionAttendeesActions.trigger({
                    companyCode,
                    showFullFamily,
                    coursesOrganizedId: wizardEntity.session.coursesOrganizedId,
                }));
            }

            return done();
        }
        done();
    },
    latest: false,
});

// fetchCoursePossibleLocationsEpic
createEpic<IFetchCoursePossibleLocationsPayload>({
    onActionType: [
        FETCH_COURSE_POSSIBLE_LOCATIONS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        const { type } = getLocationState(state);
        return !areCoursePossibleLocationsAvailable(state) || type !== action.type;
    },
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCoursePossibleLocations(action.payload);
            return fetchCoursePossibleLocationsActions.succeeded(result);
        } catch (error) {
            return fetchCoursePossibleLocationsActions.failed(error);
        }
    },
    latest: false,
});

// fetchCourseSessionsByLocationEpic
createEpic<IFetchCourseSessionsByLocationPayload>({
    onActionType: [
        FETCH_COURSE_SESSIONS_BY_LOCATION,
    ],
    async processReturn({ api, action }) {
        try {
            const result = await api.documentCenter.courses.fetchCourseSessionsByLocation(action.payload);
            return fetchCourseSessionsByLocationActions.succeeded(result);
        } catch (error) {
            return fetchCourseSessionsByLocationActions.failed(error);
        }
    },
    latest: true,
});

// createCourseEnrollmentEpic
createEpic({
    onActionType: [
        CREATE_COURSE_ENROLLMENT,
    ],
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const wizardEntity = getCourseEnrollWizardEntity(state);
            const wizardCourse = getCourseEnrollWizardCourse(state);
            const companyCode = getSelectedSeatCompanyCode(state);

            await api.documentCenter.courses.createCourseEnrollment(companyCode, wizardEntity);

            return createCourseEnrollmentActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.TRAINING_COURSE_ENROLLED,
                    extraDataToLog: {
                        [CUSTOM_DIMENSION_COURSE_ID]: wizardCourse.id,
                    },
                },
            );
        } catch (error) {
            return createCourseEnrollmentActions.failed(error);
        }
    },
    latest: false,
});

// skipToCourseEnrollmentWizardStepEpic
createEpic<ISkipToCourseEnrollmentWizardStepPayload>({
    onActionType: SKIP_TO_COURSE_ENROLLMENT_WIZARD_STEP,
    async processMultiple({ action }, dispatch, done) {
        try {
            const payload = action.payload;

            dispatch(resetCourseEnrollWizardEntity());
            dispatch(createCourseEnrollWizardEntity(payload.entity));
            dispatch(navigateToCourseEnrollWizardStep({
                step: getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(payload, payload.entity),
                skipToStep: true,
                resetDataEntity: false,
                course: action.payload.wizardPayload.course,
            }));
            dispatch(skipToCourseEnrollWizardStepActions.succeeded({}));
        } catch (error) {
            dispatch(skipToCourseEnrollWizardStepActions.failed(error));
        }
        done();
    },
    latest: false,
});

function getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(
    payload: ISkipToCourseEnrollmentWizardStepPayload,
    entityData: ICourseEnrollWizardEntity,
) {
    if (payload.wizardPayload.step) {
        return payload.wizardPayload.step;
    }
    const wizardSteps = getEnrollCourseWizardSteps().steps;
    const firstVisibleStep = wizardSteps.find((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(entityData);
        }
        return true;
    });
    return firstVisibleStep && firstVisibleStep.id as COURSE_ENROLL_WIZARD_STEP_ID;
}

// fetchCertificatesEpic
// createEpic({
//     onActionType: [ROUTE_KEYS.R_COURSES_CERTIFICATES, FETCH_CERTIFICATES],
//     refreshDataIf: ({ getState }) => {
//         const state = getState();

//         if (!areCertificatesAvailable(state)) {
//             return true;
//         }

//         const {
//             type: prevRouteKey,
//         } = getLocationState(state);

//         if (prevRouteKey === ROUTE_KEYS.R_COURSES_CERTIFICATES_DETAIL) {
//             /* No refresh if we navigate from details back to the overview */
//             return false;
//         }

//         if (prevRouteKey === ROUTE_KEYS.R_COURSES_CERTIFICATES) {
//             /* No refresh if we stay on the overview and only client side filtering changed */
//             return false;
//         }

//         return true;
//     },
//     async processReturn({ api, getState }) {
//         try {
//             const state = getState();
//             const companyCode = getSelectedSeatCompanyCode(state);
//             const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

//             const result = await api.documentCenter.courses.fetchCertificates({ companyCode, showFullFamily });

//             return fetchCertificatesActions.succeeded(result);
//         } catch (error) {
//             return fetchCertificatesActions.failed(error);
//         }
//     },
//     latest: false,
// });

// fetchCertificateDetailEpic
/* createEpic<IFetchCertificateDetailPayload>({
    onActionType: ROUTE_KEYS.R_COURSES_CERTIFICATES_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            fetchCertificatesIfNotAvailable(state, dispatch);
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const result = await api.documentCenter.courses.fetchCertificateDetail({
                companyCode,
                showFullFamily,
                code: action.payload.code,
            });
            dispatch(fetchCertificateDetailActions.succeeded(result));
        } catch (error) {
            dispatch(fetchCertificateDetailActions.failed(error));
        }

        return done();
    },
    latest: true,
}); */

// fetchEmployeeCoursesEpic
createEpic<IFetchEmployeeCoursesPayload>({
    onActionType: FETCH_EMPLOYEE_COURSES,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const courses = await api.documentCenter.courses
                .fetchEmployeeCourses(action.payload);

            dispatch(fetchEmployeeCoursesActions.succeeded(courses));
        } catch (error) {
            dispatch(fetchEmployeeCoursesActions.failed(error));
        }
        done();
    },
    latest: true,
});

/* function fetchCertificatesIfNotAvailable(
    state: IState,
    dispatch: (action: ArgumentAction<string, undefined, undefined>) => void,
) {
    if (!areCertificatesAvailable(state)) {
        dispatch(fetchCertificatesActions.trigger({}));
    }
} */

// fetchCourseExternalEmployee
createEpic<IFetchCourseExternalEmployeePayload>({
    onActionType: FETCH_COURSE_EXTERNAL_EMPLOYEE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const companyCode = getSelectedSeatCompanyCode(getState());
            const courseExternalEmployee = await api.documentCenter.courses
                .fetchCourseExternalEmployee({ ...action.payload, companyCode });

            dispatch(fetchCourseExternalEmployeeActions.succeeded(courseExternalEmployee));
        } catch (error) {
            dispatch(fetchCourseExternalEmployeeActions.failed(error));
        }
        done();
    },
    latest: true,
});

// fetchCourseExternalEmployee
createEpic<IUpdateCourseExternalEmployeePayload>({
    onActionType: UPDATE_COURSE_EXTERNAL_EMPLOYEE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = isAllSeatsSelected(state);
            const coursesOrganizedId = getRoutePayload<{ coursesOrganizedId: string }>(state).coursesOrganizedId;

            await api.documentCenter.courses
                .updateCourseExternalEmployee({ ...action.payload, companyCode });

            dispatch(fetchCourseSessionAttendeesActions.trigger({
                companyCode,
                showFullFamily,
                coursesOrganizedId: Number(coursesOrganizedId),
            }));

            dispatch(updateCourseExternalEmployeeActions.succeeded({}));
        } catch (error) {
            dispatch(updateCourseExternalEmployeeActions.failed(error));
        }
        done();
    },
    latest: true,
});


// startElearningCourseEnrollment
createEpic<IFetchCMSCourseTablePayload>({
    onActionType: CREATE_ELEARNING_COURSE_ENROLLMENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const locale = localeToBackendLocale(getLocale(state));
            const selectedCourse = getCoursesOverviewDetail(getState());

            const courseTable = await api.documentCenter.courses.fetchCourseTable(action.payload);
            const currentLocaleSession = toFlattenedFirstdaySessions(courseTable)
                .find((session) => session.languageCode.toLowerCase() === locale);

            if (selectedCourse && currentLocaleSession) {
                dispatch(skipToCourseEnrollWizardStepActions.trigger({
                    wizardPayload: {
                        step: COURSE_ENROLL_WIZARD_STEP_ID.EMPLOYEE,
                        course: {
                            name: selectedCourse.course.name,
                            description: selectedCourse.course.description,
                            id: currentLocaleSession.courseId,
                        },
                    },
                    entity: {
                        session: {
                            coursesOrganizedId: currentLocaleSession.coursesOrganizedId,
                            start: currentLocaleSession.start,
                            stop: currentLocaleSession.stop,
                            price: currentLocaleSession.price,
                            amountPE: currentLocaleSession.amountPE,
                            PETariff: currentLocaleSession.PETariff,
                            maxAmount: currentLocaleSession.maxAmount,
                            attendees: currentLocaleSession.attendees,
                        },
                        location: {
                            ...currentLocaleSession.address,
                        },
                    } as ICourseEnrollWizardEntity,
                }));
            }
            dispatch(fetchCourseTableActions.succeeded(courseTable));
        } catch (error) {
            dispatch(fetchCourseTableActions.failed(error));
        }
        done();
    },
    latest: true,
});
