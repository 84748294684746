import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_RISK_MANAGEMENT_DOCUMENTS,
    FETCH_RISK_MANAGEMENT_DOCUMENTS_FILES,
    FETCH_RISK_MANAGEMENT_DOCUMENT_FILES_IN_BULK,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { IRiskManagementDocument } from '../../../models/documentCenter/riskManagementDocuments';
import { IDocument } from '../../../models/general/documents';
import { DISMISS_DOCUMENT_DIALOG } from '../../employee/documents/types';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.DOCUMENT_CENTER_RISK_MANAGEMENT_DOCUMENTS;

export interface IReducerState {
    riskManagementDocuments: IAsyncFetchField<IRiskManagementDocument[]>;
    riskManagementDocumentsFiles: IAsyncFetchField<IDocument[]>;
    riskManagementDocumentFilesInBulk: IAsyncFetchField<{}>;
}

const initialState: IReducerState = {
    riskManagementDocuments: getAsyncFetchInitialState(),
    riskManagementDocumentsFiles: getAsyncFetchInitialState(),
    riskManagementDocumentFilesInBulk: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(DISMISS_DOCUMENT_DIALOG)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                riskManagementDocumentsFiles: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<IRiskManagementDocument[], IReducerState, IRiskManagementDocument[]>({
        baseActionType: FETCH_RISK_MANAGEMENT_DOCUMENTS,
        fieldName: 'riskManagementDocuments',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_RISK_MGMT_DOCUMENTS,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_RISK_MANAGEMENT_DOCUMENTS_FILES,
        fieldName: 'riskManagementDocumentsFiles',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<{}, IReducerState, {}>({
        baseActionType: FETCH_RISK_MANAGEMENT_DOCUMENT_FILES_IN_BULK,
        fieldName: 'riskManagementDocumentFilesInBulk',
        resetDataOnTrigger: true,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});
