import React, { PureComponent, MouseEvent } from 'react';
import './edit-statutes.scss';
import Form, { IFormRenderProps } from '../../../../common/forms/Form';
import connect from '../../../../../utils/libs/redux/connect';
import {
    IEmployeeStatute,
    IRemoveEmployeeStatutePayload,
    IUpdateEmployeeStatutePayload,
} from '../../../../../models/admin/employee';
import { fields, schema } from './updateStatuteSchema';
import Loader from '../../../../common/waiting/Loader';
import Translate from '../../../../common/Translate';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import Button from '../../../../common/buttons/Button';
import FormError from '../../../../common/forms/FormError';
import FormFieldError from '../../../../common/forms/FormFieldError';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    getSelectedEmployee,
    getSelectedEmployeeStatutes,
    getSelectedEmployeeStatutesAsyncInfo,
    getUpdateEmployeeStatuteAsyncInfo,
    getRemoveEmployeeStatuteAsyncInfo,
} from '../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import DatePicker from '../../../../common/widget/DateTimePicker/DatePicker';
import TextInput from '../../../../common/input/TextInput';
import Icon from '../../../../common/icons/Icon';
import AddStatute from '../AddStatute';
import ConfirmationDialog from '../../../../common/modals/ConfirmationDialog';
import { getStatuteDescription } from '../../../../../config/constants';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import {
    updateEmployeeStatutesActions, removeEmployeeStatuteActions,
} from '../../../../../redux/employee/info/actions';
import { SLIDEOUTPANEL_CLASSES } from '../../../../common/widget/SlideOutPanel';
import SubmitButton from '../../../../common/buttons/SubmitButton';

interface IPrivateProps {
    onUpdateStatute: (values: IUpdateEmployeeStatutePayload) => void;
    onRemoveStatute: (values: IRemoveEmployeeStatutePayload) => void;
    translator: ITranslator;
    employeeId: number;
    employeeName: string;
    statutes: IEmployeeStatute[];
    isDetailsLoaded: boolean;
    updateAsyncInfo: IAsyncFieldInfo;
    removeAsyncInfo: IAsyncFieldInfo;
    fetchStatutesAsyncInfo: IAsyncFieldInfo;
    clearPreviousErrors: () => void;
}

interface IEditStatutesProps {
    onClose: () => void;
}

interface IState {
    isAddStatuteOpen: boolean;
    isConfirmationDialogOpen: boolean;
    statuteToRemove: IEmployeeStatute;
}

const CLASS_NAME = 'EditStatutes';

class EditStatutes extends PureComponent<IPrivateProps & IEditStatutesProps, IState> {
    private resetLastSubmittedForm: (values: IUpdateEmployeeStatutePayload) => void;
    private activeStatutePersonalId: string;

    constructor(props) {
        super(props);

        this.state = {
            isAddStatuteOpen: false,
            isConfirmationDialogOpen: false,
            statuteToRemove: null,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onNewClick = this.onNewClick.bind(this);
    }

    public render() {
        const {
            isDetailsLoaded, employeeName, statutes, employeeId,
            updateAsyncInfo, removeAsyncInfo, fetchStatutesAsyncInfo,
        } = this.props;
        const { isConfirmationDialogOpen, statuteToRemove } = this.state;

        if (this.state.isAddStatuteOpen) {
            return <AddStatute onClose={() => this.setState({ isAddStatuteOpen: false })} />;
        }

        if (!isDetailsLoaded) {
            return null;
        }

        return (
            <div className={CLASS_NAME}>
                <Loader
                    show={
                        updateAsyncInfo.status === AsyncStatus.Busy ||
                        removeAsyncInfo.status === AsyncStatus.Busy ||
                        fetchStatutesAsyncInfo.status === AsyncStatus.Busy
                    }
                    showImmediatelly={true}
                />
                <header className={SLIDEOUTPANEL_CLASSES.OVERLAY.HEADER}>
                    <h2>
                        <Translate
                            msg={'administration.employees.edit_statutes.title'}
                            placeholders={{ name: employeeName }}
                        />
                    </h2>
                </header>
                <div className={`${CLASS_NAME}__actions`}>
                    <Button
                        id="edit-statutes-new-button"
                        typeName="secondary"
                        onClick={this.onNewClick}
                    >
                        <Icon typeName="plus-circle" />
                        <span><Translate msg="administration.employees.edit_statutes.new" /></span>
                    </Button>
                </div>
                <div className={`${CLASS_NAME}__content`}>
                    {statutes.map((statute, index) => {
                        return (
                            <div key={`form-${statute.personalId}`} className={`${CLASS_NAME}__form`}>
                                {index === 0 && <hr />}
                                {this.renderStatuteForm(statute, index)}
                                <hr />
                            </div>
                        );
                    })}
                    {statutes.length === 0 &&
                        <div className={`${CLASS_NAME}__form`}>
                            <Translate
                                msg="administration.employees.edit_statutes.no_statutes"
                            />
                        </div>
                    }
                </div>
                <div className={`${CLASS_NAME}__close`}>
                    <Button
                        id="edit-statutes-close-button"
                        typeName="primary"
                        onClick={this.onCloseClick}
                        size="small"
                    >
                        <Translate
                            msg="administration.employees.edit_statutes.close"
                        />
                    </Button>
                </div>
                <ConfirmationDialog
                    show={isConfirmationDialogOpen}
                    onCancel={() => this.setState({ isConfirmationDialogOpen: false })}
                    onConfirm={() => {
                        this.setState({ isConfirmationDialogOpen: false });
                        this.props.onRemoveStatute({
                            personalId: statuteToRemove.personalId,
                            employeeId,
                        });
                    }}
                >
                    <Translate
                        msg="administration.employees.edit_statutes.confirm_delete"
                        placeholders={{
                            type: statuteToRemove && getStatuteDescription(statuteToRemove.code),
                        }}
                    />
                </ConfirmationDialog>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (
            this.props.fetchStatutesAsyncInfo.status === AsyncStatus.Success &&
            prevProps.fetchStatutesAsyncInfo.status === AsyncStatus.Busy
        ) {
            // Reset the form so the dirty flag is resetted
            const updatedStatute = this.props.statutes
                .find((statute) => statute.personalId === this.activeStatutePersonalId);
            if (updatedStatute) {
                this.resetLastSubmittedForm(getStatuteInitialValues(this.props.employeeId, updatedStatute));
            }
        }
    }

    private renderStatuteForm(statute: IEmployeeStatute, index: number) {
        const {
            translator, onUpdateStatute,
            updateAsyncInfo, removeAsyncInfo,
            employeeId,
        } = this.props;

        const formName = `edit-statutes-form-${index}`;
        const initialValues = getStatuteInitialValues(employeeId, statute);

        const namePlaceholder = translator('administration.employees.edit_statutes.form.name');
        const startDatePlaceholder = translator('administration.employees.edit_statutes.form.start_date');
        const dateInactivePlaceholder = translator('administration.employees.edit_statutes.form.date_inactive');

        const formError = updateAsyncInfo.error || removeAsyncInfo.error;
        const isErrorForCurrentForm = formError && formError.extraData.personalId === statute.personalId;

        return (
            <Form
                name={formName}
                handleSubmit={(values) => {
                    this.activeStatutePersonalId = statute.personalId;
                    onUpdateStatute(values as IUpdateEmployeeStatutePayload);
                }}
                initialValues={initialValues}
                schema={schema}
                render={({
                    values, touched, errors, setFieldValue, resetForm,
                }: IFormRenderProps<IUpdateEmployeeStatutePayload>) => {
                    this.resetLastSubmittedForm = resetForm;
                    function onDateInactiveChanged(date: string) {
                        setFieldValue('dateInactive', date);
                    }

                    return (
                        <>
                            <FloatableTextInputWrapper>
                                <TextInput
                                    id={`edit-statute-name-${index}`}
                                    name="edit-statute-name"
                                    placeholder={namePlaceholder}
                                    value={statute.description}
                                    disabled={true}
                                />
                                <label htmlFor={`edit-statute-name-${index}`}>
                                    <Translate
                                        msg="administration.employees.edit_statutes.form.name"
                                    />
                                </label>
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper>
                                <DatePicker
                                    id={`edit-statute-start-date-${index}`}
                                    name="edit-statute-start-date"
                                    placeholder={startDatePlaceholder}
                                    value={statute.beginDate}
                                    disabled={true}
                                >
                                    <label htmlFor={`edit-statute-start-date-${index}`}>
                                        <Translate
                                            msg="administration.employees.edit_statutes.form.start_date"
                                        />
                                    </label>
                                </DatePicker>
                            </FloatableTextInputWrapper>
                            <FloatableTextInputWrapper>
                                <DatePicker
                                    id={`edit-statute-date-inactive-${index}`}
                                    placeholder={dateInactivePlaceholder}
                                    value={values.dateInactive}
                                    name={fields.dateInactive}
                                    onChange={onDateInactiveChanged}
                                    isInvalid={touched.dateInactive && !!errors.dateInactive}
                                    minDate={statute.beginDate}
                                >
                                    <label htmlFor={`edit-statute-date-inactive-${index}`}>
                                        <Translate
                                            msg="administration.employees.edit_statutes.form.date_inactive"
                                        />
                                    </label>
                                </DatePicker>
                                {touched.dateInactive && (
                                    <FormFieldError
                                        error={errors.dateInactive}
                                        placeholders={{ fieldName: dateInactivePlaceholder }}
                                    />
                                )}
                            </FloatableTextInputWrapper>
                            <FormError error={isErrorForCurrentForm && formError} />
                            <div className={SLIDEOUTPANEL_CLASSES.ACTIONS}>
                                <Button
                                    id={`edit-statute-delete-button-${index}`}
                                    typeName="secondary"
                                    outline={true}
                                    onClick={(e) => this.onRemoveStatuteClicked(e, statute)}
                                >
                                    <Icon typeName="bin" />
                                    <Translate
                                        msg="administration.employees.edit_statutes.form.delete"
                                    />
                                </Button>
                                <SubmitButton
                                    id={`edit-statute-submit-button-${index}`}
                                    formName={formName}
                                >
                                    <Translate
                                        msg="administration.employees.edit_statutes.form.submit"
                                    />
                                </SubmitButton>
                            </div>
                        </>
                    );
                }}
            />
        );
    }

    private onRemoveStatuteClicked(
        e: MouseEvent<HTMLButtonElement>,
        statute: IEmployeeStatute,
    ) {
        e.preventDefault();
        this.setState({
            isConfirmationDialogOpen: true,
            statuteToRemove: statute,
        });
    }

    private onCloseClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const { onClose, clearPreviousErrors } = this.props;
        clearPreviousErrors();
        onClose();
    }

    private onNewClick() {
        this.setState({
            isAddStatuteOpen: true,
        });
    }
}

export default connect<IPrivateProps, IEditStatutesProps>({
    stateProps: (state) => {
        const selectedEmployee = getSelectedEmployee(state);
        const updateAsyncInfo = getUpdateEmployeeStatuteAsyncInfo(state);
        const removeAsyncInfo = getRemoveEmployeeStatuteAsyncInfo(state);
        const fetchStatutesAsyncInfo = getSelectedEmployeeStatutesAsyncInfo(state);
        return {
            isDetailsLoaded: !!selectedEmployee,
            translator: getTranslatorDeprecated(state),
            employeeId: selectedEmployee && selectedEmployee.id,
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            statutes: getSelectedEmployeeStatutes(state) || [],
            updateAsyncInfo,
            removeAsyncInfo,
            fetchStatutesAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        const clearPreviousErrors = () => {
            const state = getState();
            const updateAsyncInfo = getUpdateEmployeeStatuteAsyncInfo(state);
            const removeAsyncInfo = getRemoveEmployeeStatuteAsyncInfo(state);

            const errorIds = [];
            if (updateAsyncInfo.error) {
                errorIds.push(updateAsyncInfo.error.id);
            }
            if (removeAsyncInfo.error) {
                errorIds.push(removeAsyncInfo.error.id);
            }
            dispatch(clearErrors(errorIds));
        };
        return {
            onUpdateStatute: (values) => {
                clearPreviousErrors();
                dispatch(updateEmployeeStatutesActions.trigger(values));
            },
            onRemoveStatute: (values) => {
                clearPreviousErrors();
                dispatch(removeEmployeeStatuteActions.trigger(values));
            },
            clearPreviousErrors,
        };
    },
})(EditStatutes);

function getStatuteInitialValues(employeeId: number, statute: IEmployeeStatute): IUpdateEmployeeStatutePayload {
    return {
        dateInactive: statute.endDate || '',
        personalId: statute.personalId,
        employeeId,
    };
}
