import { IStateStorage } from '@snipsonian/redux/es/middleware/getStateStorageMiddlewareFactory';
import { ICookieConfig, IReducerCookieConfig } from './types';

const isSupported = document.cookie !== undefined;

// @todo create a default storage type from this in snipsonian
export function createCookieStorage({
    reducerConfigs,
}: { reducerConfigs: IReducerCookieConfig[]}): IStateStorage {
    const cookieNamesRegexPart = reducerConfigs.map((config) => normalizeKey(config.cookieName)).join('|');
    return {
        isSupported,
        exists: function exists(): boolean {
            return (new RegExp(`(?:^|;\\s*)(${cookieNamesRegexPart})\\s*\\=`)).test(document.cookie);
        },
        save: function save({
            value,
        }) {
            saveCookies(value as { [key: string]: any });
        },
        read: function read({
            key,
            defaultValue,
        }) {
            if (!key || !this.exists({ key })) {
                return defaultValue;
            }

            const currentCookies = document.cookie;
            const collectedCookies: { [key: string]: object|string|number|boolean } = {};
            const re =
                new RegExp(`(?:^|;\\s*)(${cookieNamesRegexPart})\\s*\\=\\s*((?:[^;](?!;))*[^;]?)`, 'gi');
            let match: RegExpExecArray;

            while (match = re.exec(currentCookies)) {
                collectedCookies[decodeURIComponent(match[1])] = JSON.parse(decodeURIComponent(match[2]));
            }

            return Object.keys(collectedCookies)
                .reduce(
                    (acc, currentCookieName) => ({
                        ...acc,
                        [reducerConfigs.find((config) => config.cookieName === currentCookieName).reducerKey]:
                        collectedCookies[currentCookieName],
                    }),
                    {},
                );
        },
        remove: function remove() {
            const currentCookies = document.cookie;
            const cookiesToUpdate: { [key: string]: null} = {};
            const re = new RegExp(`(?:^|;\\s*)(${cookieNamesRegexPart})\\s*\\=`);
            let match: RegExpExecArray;

            while (match = re.exec(currentCookies)) { // eslint-disable-line no-cond-assign
                cookiesToUpdate[decodeURIComponent(match[1])] = null;
            }

            saveCookies(cookiesToUpdate, new Date(0));
        },
        readOrSave: function readOrSave({
            key,
            valueToSaveIfNotThere,
        }) {
            const value = this.read({ key, defaultValue: null });

            if (value === null) {
                this.save({ key, value: valueToSaveIfNotThere });
                return valueToSaveIfNotThere;
            }

            return value;
        },
    };

    function getConfigurationFor(key: string): ICookieConfig {
        const cookieConfig = reducerConfigs.find((c) => c.reducerKey === key);
        if (!cookieConfig) {
            throw Error(`No cookie configuration found for ${key}`);
        }

        return cookieConfig;
    }

    function saveCookies(
        cookiesToSave: { [key: string]: any },
        expiryDate?: Date,
    ) {
        Object.keys(cookiesToSave).forEach((cookieKey) => {
            const config = getConfigurationFor(cookieKey);
            const value = cookiesToSave[cookieKey];

            saveCookie(
                config,
                value ? JSON.stringify(value) : '',
                expiryDate,
            );
        });
    }

    function saveCookie(
        config: ICookieConfig,
        value: string,
        expiryDate?: Date,
    ) {
        const notOptionalExpiryDate = expiryDate || (new Date(new Date().getTime() + config.ttlInMillis));
        const params = [`expires=${notOptionalExpiryDate.toUTCString()}`];
        if (config.domain) {
            params.push(`domain=${config.domain}`);
        }
        if (config.path) {
            params.push(`path=${config.path}`);
        }
        if (config.secure && config.secure === true) {
            params.push('secure');
        }

        // eslint-disable-next-line max-len
        document.cookie = `${encodeURIComponent(normalizeKey(config.cookieName))}=${encodeURIComponent(value)};${params.join(';')}`;
    }
}

function normalizeKey(key: string) {
    return escape(key).replace(/[-.+*]/g, '\\$&');
}
