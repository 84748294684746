import { IReducerState, reducerKey } from './reducer';
import { getReducerState, IState, makeAsyncFetchInfoSelector, NO_RERENDER, makeAsyncDoInfoSelector } from '../index';
import { getQueryParams, getRoutePayload } from '../location/selectors';
import { IFaqOverview, IFaqItem } from '../../models/contact/faq';
import { IMensuraContactPerson } from '../../models/admin/companyInfo';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// FAQ overview
const getFaqOverviewAsyncField = (state: IState) =>
    reducerState(state).faqOverview;

export const getFaqOverviewAsyncInfo = makeAsyncFetchInfoSelector(getFaqOverviewAsyncField);

export const isFaqOverviewAvailable = (state: IState) =>
    getFaqOverviewAsyncField(state).data !== null;

export const getFaqOverview = (state: IState) => {
    const faqOverview: IFaqOverview = getFaqOverviewAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;
    const { keywordFilter } = getQueryParams(state);

    if (!keywordFilter) {
        return faqOverview;
    }

    const filtered = Object.keys(faqOverview)
        .reduce(
            (accumulator, category) => {
                const categoryFaqItems = faqOverview[category] || [];

                if (doesMatchKeywordFilter(category, keywordFilter)) {
                    accumulator[category] = categoryFaqItems;
                    return accumulator;
                }

                const filteredCategoryFaqItems = categoryFaqItems.filter((faqItem) => {
                    return doesMatchKeywordFilter(faqItem.answer, keywordFilter) ||
                        doesMatchKeywordFilter(faqItem.question, keywordFilter);
                });

                if (filteredCategoryFaqItems.length > 0) {
                    accumulator[category] = filteredCategoryFaqItems;
                }

                return accumulator;
            },
            {},
        );

    return filtered;
};

function doesMatchKeywordFilter(item, filter) {
    return item.toLowerCase().includes(filter.toLowerCase());
}

export const getSelectedFaqItem = (state: IState) => {
    const { id } = getRoutePayload<{ id: string }>(state);

    const faqOverview = getFaqOverview(state);

    return toFlattenedFaqItemsContainingCategory(faqOverview)
        .find((faqItem) => faqItem.id.toString() === id.toString());
};

function toFlattenedFaqItemsContainingCategory(faqOverview: IFaqOverview): IFaqItem[] {
    return Object.keys(faqOverview)
        .reduce(
            (faqItemsAccumulator, category) => {
                return faqItemsAccumulator.concat(
                    faqOverview[category]
                        .map((faqItem) => ({
                            ...faqItem,
                            category,
                        })),
                );
            },
            [],
        );
}

// Send company feedback

const getSendCompanyFeedbackAsyncField = (state: IState) => reducerState(state).sendCompanyFeedback;
export const getSendCompanyFeedbackAsyncInfo = makeAsyncDoInfoSelector(getSendCompanyFeedbackAsyncField);

// Get case manager
const getCaseManagerAsyncField = (state: IState) =>
    reducerState(state).caseManager;

export const getCaseManagerAsyncInfo = makeAsyncFetchInfoSelector(getCaseManagerAsyncField);

export const getCaseManager = (state: IState) =>
    getCaseManagerAsyncField(state).data;

export const isCaseManagerAvailable = (state: IState) => !!getCaseManagerAsyncField(state).data;

// Get contacts mensura
const getFetchMensuraContactsAsyncField = (state: IState) =>
    reducerState(state).fetchMensuraContacts;

export const getMensuraContactsAsyncInfo = makeAsyncDoInfoSelector(getFetchMensuraContactsAsyncField);

const getMensuraContacts = (state: IState) =>
    reducerState(state).mensuraContacts;

export const getMensuraContactsForSeat = (state: IState, companyCode: string) => {
    const mensuraContacts = getMensuraContacts(state);

    return mensuraContacts[companyCode] || NO_RERENDER.EMPTY_LIST;
};

export const getMensuraContactsForSeatSorted = (state: IState, companyCode: string) => {
    return getMensuraContactsForSeat(state, companyCode).sort(sortMensuraContacts) || NO_RERENDER.EMPTY_LIST;
};

function sortMensuraContacts(a: IMensuraContactPerson, b: IMensuraContactPerson) {
    if (a.type < b.type) { return -1; }
    if (a.type > b.type) { return 1; }
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    if (a.firstName < b.firstName) { return -1; }
    if (a.firstName > b.firstName) { return 1; }
    return 0;
}
