import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from '../../../../index';
import ErrorPlaceholder from '../../../error/ErrorPlaceholder';
import Loader from '../../../waiting/Loader';
import MultiLevelSlideOutPanel from '../../MultiLevelSlideOutPanel';
import { PAYLOAD_PARAM } from '../../../../../utils/libs/redux/async/asyncReducerUtils';
import { ILevelDetails, ILevelInfo } from '../typings';
import DetailPanelHeader from './DetailPanelHeader';
import DetailPanelFooter from './DetailPanelFooter';
import { IAction, ILocationAction } from '../../../../../models/general/redux';
import ROUTE_KEYS from '../../../../../routeKeys';
import { getRouteKey, getParentRouteKey, isChildRouteKeyOf } from '../../../../../redux/location/selectors';
import { CLASS_NAME as DetailPanelClassName } from './DetailPanel';
import { navigateTo } from '../../../../../redux/location/actions';

interface IPublicProps {
    isOpen: boolean;
    onCloseIntent: (isClickedOutside: boolean) => void;
    levelsConfig: { [level: number]: ILevelDetails[] };
    lastLevelVisible: number;
}

interface IPrivateProps {
    levelsInfo: {
        [level: number]: ILevelInfo;
    };
    currentRouteKey: ROUTE_KEYS;
    parentRouteKey: ROUTE_KEYS;
    resetUpdateAsyncInfoField: (updateAsyncInfoResetAction: IAction<{}>) => void;
    navigateToRoute: (routeKey: ROUTE_KEYS, routePayload: object) => void;
}

interface IComponentState {
    overlayLevel: number;
    isOverlayOpen: boolean;
    overlayType: string | number;
}

class MultiLevelDetailPanel extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    private onOverlaySave: (data: object) => void = null;

    constructor(props) {
        super(props);

        this.state = MultiLevelDetailPanel.getInitialState();
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        const { lastLevelVisible, levelsInfo, resetUpdateAsyncInfoField } = this.props;
        const prevInfo = prevProps.levelsInfo[prevProps.lastLevelVisible];
        const currentInfo = levelsInfo[lastLevelVisible];
        if (
            lastLevelVisible !== prevProps.lastLevelVisible ||
            prevInfo.id !== currentInfo.id
        ) {
            this.setState(MultiLevelDetailPanel.getInitialState());
            const config = getDetailsConfigForRoute(
                prevProps.levelsConfig[prevProps.lastLevelVisible],
                prevProps.currentRouteKey,
            );
            if (config.updateInfo && config.updateInfo.updateAsyncInfoResetAction) {
                resetUpdateAsyncInfoField(config.updateInfo.updateAsyncInfoResetAction);
            }
        }
    }

    private static getInitialState(): IComponentState {
        return {
            overlayLevel: null,
            isOverlayOpen: false,
            overlayType: null,
        };
    }

    private closeOverlay() {
        this.setState({
            isOverlayOpen: false,
        });
    }

    public render() {
        const { isOpen, onCloseIntent, lastLevelVisible, levelsConfig, currentRouteKey } = this.props;
        const { isOverlayOpen, overlayType, overlayLevel } = this.state;
        const levelDetails = levelsConfig[overlayLevel] || [] as ILevelDetails[];
        const { renderOverlay } = getDetailsConfigForRoute(levelDetails, currentRouteKey) || { renderOverlay: null };

        return (
            <MultiLevelSlideOutPanel
                isOpen={isOpen}
                onCloseIntent={onCloseIntent}
                headers={this.getHeaders()}
                footers={this.getFooters()}
                lastLevelVisible={lastLevelVisible}
                showOverlay={isOverlayOpen}
                overlay={renderOverlay ? renderOverlay({
                    overlayType,
                    closeOverlay: this.closeOverlay,
                    onSave: (data) => {
                        if (typeof this.onOverlaySave === 'function') {
                            this.onOverlaySave(data);
                        }
                    },
                }) : undefined}
                goBackLinks={isOpen && this.getGoBackLinks()}
            >
                {this.renderContent()}
            </MultiLevelSlideOutPanel>
        );
    }

    private renderContent() {
        const {
            levelsInfo, levelsConfig, currentRouteKey, onCloseIntent, navigateToRoute, lastLevelVisible,
        } = this.props;
        return Object.keys(levelsInfo)
            .reduce(
                (accumulator, level) => {
                    const {
                        detailAsyncInfo, detailData, detailUpdateAsyncInfo,
                    } = levelsInfo[level] as ILevelInfo;
                    const levelDetails = levelsConfig[level] as ILevelDetails[];
                    const {
                        renderContent, isForm, routeKey, routePayload,
                    } = getDetailsConfigForRoute(levelDetails, currentRouteKey);

                    const detailPanelClasses = classNames(DetailPanelClassName, {
                        [`${DetailPanelClassName}--form`]: isForm,
                    });

                    const content = (
                        <section className={detailPanelClasses}>
                            {detailAsyncInfo.error &&
                                <ErrorPlaceholder apiError={detailAsyncInfo.error} />
                            }
                            <Loader show={detailAsyncInfo.status}>
                                {renderContent({
                                    detailLevel: Number(level),
                                    detailAsyncInfo,
                                    detailData,
                                    detailUpdateAsyncInfo,
                                    onOpenOverlay: (overlayType, onSave) => {
                                        if (lastLevelVisible !== Number(level)) {
                                            navigateToRoute(routeKey, {
                                                ...routePayload,
                                                [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                                            });
                                        }
                                        this.onOverlaySave = onSave;
                                        this.setState({
                                            overlayLevel: Number(level),
                                            isOverlayOpen: true,
                                            overlayType,
                                        });
                                    },
                                    onCloseIntent,
                                })}
                            </Loader>
                        </section>
                    );
                    accumulator[level] = content;
                    return accumulator;
                },
                {},
            );
    }

    private getHeaders() {
        const { levelsInfo, levelsConfig, currentRouteKey } = this.props;
        return Object.keys(levelsInfo)
            .reduce(
                (accumulator, level) => {
                    const { detailAsyncInfo, detailData, detailUpdateAsyncInfo } = levelsInfo[level] as ILevelInfo;
                    const levelDetails = levelsConfig[level] as ILevelDetails[];
                    const { renderHeader, updateInfo } = getDetailsConfigForRoute(levelDetails, currentRouteKey);

                    accumulator[level] = (
                        <DetailPanelHeader
                            renderHeader={renderHeader}
                            detailAsyncInfo={detailAsyncInfo}
                            detailData={detailData}
                            detailUpdateAsyncInfo={detailUpdateAsyncInfo}
                            detailUpdateInfo={updateInfo}
                            detailLevel={Number(level)}
                        />
                    );

                    return accumulator;
                },
                {},
            );
    }

    private getFooters() {
        const { levelsInfo, levelsConfig, currentRouteKey } = this.props;
        return Object.keys(levelsInfo)
            .reduce(
                (accumulator, level) => {
                    const { detailAsyncInfo, detailData, detailUpdateAsyncInfo } = levelsInfo[level] as ILevelInfo;
                    const levelDetails = levelsConfig[level] as ILevelDetails[];
                    const { renderFooter } = getDetailsConfigForRoute(levelDetails, currentRouteKey);

                    accumulator[level] = (
                        <DetailPanelFooter
                            renderFooter={renderFooter}
                            detailAsyncInfo={detailAsyncInfo}
                            detailData={detailData}
                            detailUpdateAsyncInfo={detailUpdateAsyncInfo}
                        />
                    );

                    return accumulator;
                },
                {},
            );
    }

    private getGoBackLinks(): { [level: number]: ILocationAction<{}> } {
        const { levelsInfo, levelsConfig, parentRouteKey } = this.props;
        return Object.keys(levelsInfo)
            .reduce(
                (accumulator, levelValue) => {
                    const level = Number(levelValue);
                    const prevLevel = levelsConfig[level - 1];

                    if (prevLevel) {
                        const prevLevelConfig = getDetailsConfigForRoute(prevLevel, parentRouteKey);
                        accumulator[level] = {
                            type: prevLevelConfig.routeKey,
                            payload: {
                                [PAYLOAD_PARAM.SHOULD_REFRESH_DATA]: false,
                                ...prevLevelConfig.routePayload,
                            },
                        };
                    }

                    return accumulator;
                },
                {},
            );
    }
}

export default connect<IPrivateProps, IPublicProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const currentRouteKey = getRouteKey(state);
            const levelsInfo = Object.keys(publicProps.levelsConfig)
                .reduce(
                    (accumulator, level) => {
                        const levelConfig = getDetailsConfigForRoute<ILevelDetails>(
                            publicProps.levelsConfig[level],
                            currentRouteKey,
                        );
                        const detailUpdateAsyncInfo = levelConfig.updateInfo &&
                            levelConfig.updateInfo.updateAsyncInfoSelector(state);
                        accumulator[level] = {
                            detailAsyncInfo: levelConfig.detailAsyncInfoSelector(state),
                            id: levelConfig.detailIdSelector(state),
                            detailData: levelConfig.detailDataSelector(state),
                            detailUpdateAsyncInfo,
                        };
                        return accumulator;
                    },
                    {},
                );
            return {
                levelsInfo,
                currentRouteKey,
                parentRouteKey: getParentRouteKey(state),
            };
        };
    },
    dispatchProps: (dispatch) => {
        return {
            resetUpdateAsyncInfoField: (updateAsyncInfoResetAction: IAction<{}>) => {
                dispatch(updateAsyncInfoResetAction);
            },
            navigateToRoute: (routeKey: ROUTE_KEYS, routePayload: object) => {
                dispatch(navigateTo(routeKey, routePayload));
            },
        };
    },
})(MultiLevelDetailPanel);

export function getDetailsConfigForRoute<T extends { routeKey: ROUTE_KEYS }>(
    levelDetails: T[],
    routeKey: ROUTE_KEYS,
): T {
    const configForRoute = levelDetails.find((config) => {
        if (config.routeKey === routeKey) {
            return true;
        }

        return isChildRouteKeyOf(routeKey, config.routeKey);
    });

    return configForRoute || levelDetails[0];
}
