export const getBufferzoneCompanyCode = (
    allSeatsSelected: boolean,
    bufferzoneBranchCode: string,
    isHeadSeat: boolean,
    isSubSeat: boolean,
    selectedCompany: string,
    selectedSeat: string,
): string => {
    if (allSeatsSelected) {
        return bufferzoneBranchCode;
    } if (isHeadSeat) {
        return bufferzoneBranchCode;
    } if (isSubSeat) {
        if (bufferzoneBranchCode === selectedCompany || bufferzoneBranchCode === selectedSeat) {
            return selectedSeat;
        // eslint-disable-next-line max-len
        } if ((bufferzoneBranchCode !== selectedCompany) && (bufferzoneBranchCode !== selectedSeat)){
            return bufferzoneBranchCode;
        }
    }
};
