import { object, boolean } from 'yup';
import string from '../../../utils/libs/yup/string';

export const fields = {
    companyFunctions: 'companyFunctions',
    companyData: 'companyData',
    employeeList: 'employeeList',
    companyVisits: 'companyVisits',
    riskManagement: 'riskManagement',
    locale: 'locale',
    linkedEmployees: 'linkedEmployees',
    selectAll: 'selectAll',
};

export const schema = object({
    [fields.companyFunctions]: boolean()
        .when(
            fields.linkedEmployees, {
                is: true,
                then: boolean().required(),
                otherwise: boolean(),
            },
        ),
    [fields.companyData]: boolean(),
    [fields.employeeList]: boolean(),
    [fields.companyVisits]: boolean(),
    [fields.riskManagement]: boolean(),
    [fields.locale]: string().required(),
    [fields.linkedEmployees]: boolean(),
    [fields.selectAll]: boolean(),
});
