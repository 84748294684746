import React, { ReactElement, ReactNode } from 'react';
import { IPublicProps as ITranslateProps } from '../../Translate';
import classNames from 'classnames';

export interface ITabProps {
    title: string | ReactElement<ITranslateProps>;
    children: ReactNode;
    onClick?: (tabIndex: number) => void;
    tabIndex?: number;
    isActive?: boolean;
}

const CLASS_NAME = 'Tab';

export default function Tab(props: ITabProps) {
    return (
        <div
            className={classNames(CLASS_NAME, {
                active: !!props.isActive,
            })}
            onClick={(event) => {
                event.preventDefault();
                props.onClick(props.tabIndex);
            }}
        >
            <span>{props.title}</span>
        </div>
    );
}
