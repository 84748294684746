import React, { PureComponent } from 'react';
import { path } from 'ramda';

import './time-slot-remarks.scss';

import {
    addTimeslotActions,
    updatePlanMedicalExaminationWizardEntity,
    triggerMoveUpdateTimeSlot,
} from '../../../../../../redux/medicalExamination/actions';
import { AsyncStatus } from '../../../../../../models/general/redux';
import {
    getAddTimeslotAsyncInfo,
    getMedicalExaminationToPlanInfoByEmployeeId,
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
    getUpdateTimeslotAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { getSelectedEmployee } from '../../../../../../redux/employee/info/selectors';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { IEmployee } from '../../../../../../models/admin/employee';
import {
    IMedicalExaminationTimeSlot,
    IMovePlanningData,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import { TAddTimeSlotPayload, IMoveUpdateTimeslotPayload } from '../../../../../../models/interventions/timeslots';
import connect from '../../../../../../utils/libs/redux/connect';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import FormError from '../../../../../common/forms/FormError';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import Loader from '../../../../../common/waiting/Loader';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import TextInput from '../../../../../common/input/TextInput';
import Translate from '../../../../../common/Translate';

import { addOrMoveTimeslotSucceeded, showLoaderIfBusy } from './TimeSlotRemarks.helper';
import { CLASS_NAME, FORM_NAME } from './TimeSlotRemarks.const';
import { fields, schema } from './medicalExaminationRemarksSchema';
import {
    ITimeSlotRemarksFormValues,
    ITimeSlotRemarksPrivateProps,
    ITimeSlotRemarksProps,
} from './TimeSlotRemarks.type';
class TimeslotRemarks extends PureComponent<ITimeSlotRemarksPrivateProps & ITimeSlotRemarksProps> {

    public render() {
        const {
            translator,
            remarks,
            updateWizardData,
            employee,
            addTimeSlot,
            selectedTimeslot,
            examinationReason,
            planningRequestId,
            addTimeSlotAsyncInfo,
            moveTimeSlot,
            moveTimeSlotAsyncInfo,
            movePlanning,
        } = this.props;
        const initialValues: ITimeSlotRemarksFormValues = {
            remarks: remarks || '',
        };

        const remarksTranslationKey =
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.label';
        const submitTranslationKey = movePlanning ?
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.submit_move' :
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.submit';

        return (
            <Form
                className={CLASS_NAME}
                name={FORM_NAME}
                schema={schema}
                initialValues={initialValues}
                handleSubmit={(values: ITimeSlotRemarksFormValues) => {
                    updateWizardData({
                        selectTime: {
                            remarks: values.remarks,
                        },
                    });
                    movePlanning ?
                        moveTimeSlot({
                            activityId: movePlanning.timeSlot.activityId,
                            timeSlotId: movePlanning.timeSlot.id,
                            remarks: values.remarks,
                            newTimeSlot: {
                                activityId: selectedTimeslot.activityId,
                                id: selectedTimeslot.id,
                            },
                        }) :
                        !!planningRequestId
                            ? addTimeSlot({
                                activityId: selectedTimeslot.activityId,
                                planningRequestMSId: planningRequestId,
                                remarks: values.remarks,
                                timeSlotId: selectedTimeslot.id,
                            })
                            : addTimeSlot({
                                activityId: selectedTimeslot.activityId,
                                employmentId: employee.id,
                                examinationReasonCode: examinationReason.code,
                                remarks: values.remarks,
                                timeSlotId: selectedTimeslot.id,
                            });
                }}
                render={(formRenderProps: IFormRenderProps<ITimeSlotRemarksFormValues>) => {
                    return <Loader show={showLoaderIfBusy(addTimeSlotAsyncInfo, moveTimeSlotAsyncInfo)}>
                        <FloatableTextInputWrapper>
                            <TextInput
                                id="examination-remark"
                                name={fields.remarks}
                                value={formRenderProps.values.remarks}
                                onChange={formRenderProps.handleChange}
                                isInvalid={formRenderProps.touched.remarks && !!formRenderProps.errors.remarks}
                                multiLine={true}
                                rows={6}
                                // eslint-disable-next-line max-len
                                placeholder={translator('interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.placeholder')}
                            />
                            <label htmlFor="examination-remark">
                                <Translate msg={remarksTranslationKey} />
                            </label>
                            {formRenderProps.touched.remarks &&
                                <FormFieldError
                                    error={formRenderProps.errors.remarks}
                                    placeholders={{
                                        fieldName: translator(remarksTranslationKey),
                                    }}
                                />}
                            <p className="remark-warning">
                                {/* eslint-disable-next-line max-len */}
                                <Translate msg={'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.warning'} />
                            </p>
                        </FloatableTextInputWrapper>
                        <FormError error={addTimeSlotAsyncInfo.error} />
                        <div className={`${CLASS_NAME}__buttons`}>
                            <SubmitButton id="submit-medical-examination" formName={FORM_NAME} enabledWhenValid={true}>
                                <Translate
                                    msg={submitTranslationKey}
                                />
                            </SubmitButton>
                        </div>
                    </Loader>;
                }}
            />);
    }

    public componentDidUpdate(prevProps: ITimeSlotRemarksPrivateProps & ITimeSlotRemarksProps) {
        const { onTimeslotPlanned } = this.props;
        if (addOrMoveTimeslotSucceeded(prevProps, this.props)) {
            onTimeslotPlanned();
        }
    }
}

export default connect<ITimeSlotRemarksPrivateProps, ITimeSlotRemarksProps>({
    stateProps: (state) => {
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);

        const selectedEventId = path(['selectTime', 'selectedEventId'], wizardEntity);
        const selectedTimeslot = path<IMedicalExaminationTimeSlot>(['selectTime', 'selectedTimeslot'], wizardEntity);

        const selectedMedicalCenterCode = path(['selectTime', 'filter', 'selectedMedicalCenterCode'], wizardEntity);
        const selectedEmployee = path<Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>>(
            ['searchEmployee', 'selectedEmployee'],
            wizardEntity,
        );

        const employee = getSelectedEmployee(state);

        const remarks = path<string>(['searchEmployee', 'remarks'], wizardEntity);
        const movePlanning = path<IMovePlanningData>(['movePlanning'], wizardEntity);

        const examinationReason = getPlanMedicalExaminationWizardReason(state);
        const mainMedicalCenter = getCompanyMedicalCenters(state)
            .find((item) => item.code === selectedMedicalCenterCode) || null;

        const selectedMedicalExaminationToPlan = path(['selectedMedicalExaminationToPlan'], wizardEntity);

        const extraToPlanInfo = selectedMedicalExaminationToPlan
            || (selectedEmployee && getMedicalExaminationToPlanInfoByEmployeeId(
                state,
                { employeeId: selectedEmployee.id, examinationReason },
            ));

        const planningRequestId = path<number>(['planningRequestId'], extraToPlanInfo);

        return {
            selectedEventId,
            selectedTimeslot,
            mainMedicalCenter,
            translator: getTranslatorDeprecated(state),
            addTimeSlotAsyncInfo: getAddTimeslotAsyncInfo(state),
            employee,
            examinationReason,
            planningRequestId,
            remarks,
            moveTimeSlotAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            movePlanning,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                    },
                }));
            },
            addTimeSlot: (payload: TAddTimeSlotPayload) => {
                const state = getState();
                const addTimeSlotAsyncInfo = getAddTimeslotAsyncInfo(state);

                if (addTimeSlotAsyncInfo.status === AsyncStatus.Busy) {
                    return;
                }

                dispatch(addTimeslotActions.trigger(payload));
            },
            moveTimeSlot: (payload: IMoveUpdateTimeslotPayload) => {
                dispatch(triggerMoveUpdateTimeSlot(payload));
            },
        };
    },
})(TimeslotRemarks);
