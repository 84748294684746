import React from 'react';
import { IPagePartComponentProps } from '@snipsonian/react/es/components/pageParts/pageParts.models';
import './legal-tip-pagepart.scss';
import WysiwygContentType from '../WysiwygContentType';

const CLASS_NAME = 'LegalTipPagePart';

export interface ILegalTipPagePartData {
    content: string;
}

export default function LegalTipPagePart(props: IPagePartComponentProps<ILegalTipPagePartData>) {
    const { data } = props;

    return (
        <div className={CLASS_NAME}>
            <svg width="27px" height="34px" viewBox="0 0 27 34" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    {/* eslint-disable-next-line max-len */}
                    <path d="M11.1297845,0 L15.8704051,0 L15.8704051,4.8571429 L11.1297845,4.8571429 L11.1297845,0 Z M24.6806238,5.2343164 L27.05046,9.3401678 L22.8436886,11.7682535 L20.4738523,7.6624021 L24.6806238,5.2343164 Z M2.39896096,5.2114118 L6.50481242,7.581248 L4.0767267,11.7880194 L-0.029124752,9.4181832 L2.39896096,5.2114118 Z M21.7961808,15.7857143 C21.7961808,18.666 20.3834758,21.2038571 18.2407154,22.7411429 L18.2407154,26.7142857 L8.75947427,26.7142857 L8.75947427,22.7411429 C6.61671378,21.2038571 5.20400886,18.666 5.20400886,15.7857143 C5.20400886,11.0985714 8.92539599,7.2857143 13.5000948,7.2857143 C18.0747936,7.2857143 21.7961808,11.0985714 21.7961808,15.7857143 Z M11.1297845,29.1428571 L15.8704051,29.1428571 L15.8704051,34 L11.1297845,34 L11.1297845,29.1428571 Z" id="kmcc-tip-pp-icon" fill="#49A5DD" fillRule="nonzero" />
                </g>
            </svg>
            <WysiwygContentType html={data.content} />
        </div>
    );
}
