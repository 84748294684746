import React from 'react';
import { path, pathOr } from 'ramda';

import {
    getEmployeeMedicalExaminations,
    getEmployeeMedicalExaminationsAsyncInfo,
} from '../../../../../../redux/employee/info/selectors';
import {
    getExaminationReasonsAsyncInfo,
    getExaminationReasonByCode,
    getDefaultSelectedMedicalCenterCode,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    getRouteKey,
    getRoutePayload,
} from '../../../../../../redux/location/selectors';
import { formatDateForDisplay } from '../../../../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { getDate, monthOffsetFromDate } from '../../../../../../utils/core/date/getSpecificDate';
import { IEmployeeDetails } from '../../../../../../models/admin/employee';
import {
    IEmployeeExecutedMedicalExamination,
    IEmployeePlannedMedicalExamination,
    IEmployeeNextMedicalExamination,
} from '../../../../../../models/admin/employee/medical-examination';
import { ITranslator } from '../../../../../../models/general/i18n';
import { NO_RERENDER } from '../../../../../../redux/index';
import { PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE } from '../../../../../../models/interventions/medicalExaminations';
import { skipToPlanMedicalExaminationWizardStepActions } from '../../../../../../redux/medicalExamination/actions';
import isEmptyObject from '../../../../../../utils/core/object/isEmptyObject';
import { connect } from '../../../../../index';
import {
    toMedicalExaminationToPlan,
} from '../../../../../interventions/PlanMedicalExamination/PlanMedicalExaminationWizard/automatic/EmployeesToPlan';
import Button from '../../../../../common/buttons/Button';
import ListItem from '../../../../../common/list/ListItem';
import ShowIfAllowed from '../../../../../auth/ShowIfAllowed';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import Translate from '../../../../../common/Translate';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';

import { renderExecutedExaminations, renderPlannedExaminations } from './EmployeeMedicalExaminations.helper';
import { IPrivateProps, IEmployeeMedicalExaminationsProps } from './EmployeeMedicalExaminations.type';

const renderNextExaminationNotPlannable = (
    examination: IEmployeeNextMedicalExamination,
    translator: ITranslator,
) => {
    const date = formatDateForDisplay(
        monthOffsetFromDate(getDate(examination.toBePlannedDate), -6),
    );

    return (
        <ListItem
            title={translator({
                msg: 'administration.employees.detail.medical_examination.to_plan.to_be_planned_from',
                placeholders: {
                    date,
                },
            })}
            text={examination.examinationReason.title}
            arrow={true}
        />
    );
};

function EmployeeMedicalExaminationsComp(props: IPrivateProps & IEmployeeMedicalExaminationsProps) {
    const {
        employee,
        employeeMedicalExaminations,
        executedMedicalExaminationRouteKey,
        extraRoutePayload,
        plannedMedicalExaminationRouteKey,
        selectedExecutedExaminationId,
        selectedPlannedExaminationId,
        triggerWizardWithSelectedEmployee,
    } = props;


    const renderNextMedicalExamination = (translator: ITranslator) => {
        const hasNextExamination = path(['next'], employeeMedicalExaminations);

        if (!hasNextExamination) {
            return (
                <p>
                    <Translate msg="administration.employees.detail.medical_examination.to_plan.no_results" />
                </p>
            );
        }

        if (!employeeMedicalExaminations.next.toBePlanned) {
            return renderNextExaminationNotPlannable(
                employeeMedicalExaminations.next,
                translator,
            );
        }

        return (
            !employeeMedicalExaminations.next.toBePlanned ?
                renderNextExaminationNotPlannable(
                    employeeMedicalExaminations.next,
                    translator,
                ) : (
                    <ListItem
                        title={translator({
                            msg: 'administration.employees.detail.medical_examination.to_plan.deadline',
                            placeholders: {
                                date: formatDateForDisplay(employeeMedicalExaminations.next.toBePlannedDate),
                            },
                        })}
                        text={employeeMedicalExaminations.next.examinationReason.title}
                        arrow={true}
                        button={
                            <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                                <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                                    <Button
                                        id="plan-employee-button"
                                        typeName="secondary"
                                        size="small"
                                        onClick={() => triggerWizardWithSelectedEmployee(
                                            employee,
                                            employeeMedicalExaminations.next,
                                        )}
                                    >
                                        <Translate
                                            msg="administration.employees.detail.medical_examination.to_plan.plan"
                                        />
                                    </Button>
                                </TinyLoader>
                            </ShowIfAllowed>}
                    />
                )
        );
    };

    const renderPlannedMedicalExaminationList = () => {
        const plannedExaminations = pathOr<IEmployeePlannedMedicalExamination[]>(
            NO_RERENDER.EMPTY_LIST,
            ['planned'],
            employeeMedicalExaminations,
        );

        if (plannedExaminations.length === 0) {
            return (
                <p>
                    <Translate msg="administration.employees.detail.medical_examination.planned.no_results" />
                </p>
            );
        }

        return renderPlannedExaminations(
            employee.id,
            plannedMedicalExaminationRouteKey,
            plannedExaminations,
            selectedPlannedExaminationId,
            extraRoutePayload,
        );
    };

    const renderExecutedMedicalExaminationList = () => {
        const executedExaminations = pathOr<IEmployeeExecutedMedicalExamination[]>(
            NO_RERENDER.EMPTY_LIST,
            ['executed'],
            employeeMedicalExaminations,
        );

        if (executedExaminations.length === 0) {
            return (
                <p>
                    <Translate msg="administration.employees.detail.medical_examination.executed.no_results" />
                </p>
            );
        }

        return renderExecutedExaminations(
            employee.id,
            executedMedicalExaminationRouteKey,
            executedExaminations,
            selectedExecutedExaminationId,
            extraRoutePayload,
        );
    };

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <TinyLoader asyncInfoSelector={getEmployeeMedicalExaminationsAsyncInfo}>
                    {!isEmptyObject(employeeMedicalExaminations) ?
                        <>
                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.to_plan.title"
                                />
                            </h4>
                            {renderNextMedicalExamination(translator)}

                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.planned.title"
                                />
                            </h4>
                            {renderPlannedMedicalExaminationList()}
                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.executed.title"
                                />
                            </h4>
                            {renderExecutedMedicalExaminationList()}
                        </> :
                        <p>
                            <Translate
                                msg="administration.employees.detail.risks.no_results"
                            />
                        </p>
                    }
                </TinyLoader>
            )}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps, IEmployeeMedicalExaminationsProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const routeKey = getRouteKey(state);
            const {
                examinationId,
                timeSlotId,
            } = getRoutePayload<{ examinationId?: number, timeSlotId?: number }>(state);

            return {
                employeeMedicalExaminations: getEmployeeMedicalExaminations(state),
                selectedPlannedExaminationId:
                    routeKey === publicProps.plannedMedicalExaminationRouteKey && timeSlotId,
                selectedExecutedExaminationId:
                    routeKey === publicProps.executedMedicalExaminationRouteKey && examinationId,
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerWizardWithSelectedEmployee: (
                employee: IEmployeeDetails,
                nextMedicalExamination: IEmployeeNextMedicalExamination,
            ) => {
                const state = getState();

                if (!nextMedicalExamination.examinationReason.code) {
                    console.error(
                        'No examination reason found to plan in next medical examination',
                        nextMedicalExamination,
                    );
                    return;
                }

                const examinationReason = getExaminationReasonByCode(
                    state,
                    nextMedicalExamination.examinationReason.code,
                );

                if (!examinationReason) {
                    console.error(
                        'No examination reason found to plan in next medical examination',
                        nextMedicalExamination,
                    );
                    return;
                }

                const medicalCenterCode = getDefaultSelectedMedicalCenterCode(state);

                const medicalCenter = getCompanyMedicalCenters(state)
                    .find((item) => item.code === medicalCenterCode);

                const selectedMedicalExaminationToPlan = toMedicalExaminationToPlan(
                    employee,
                    examinationReason,
                    nextMedicalExamination.id,
                );

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                        reason: examinationReason,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: formatPersonName(employee),
                            selectedEmployee: employee,
                        },
                        selectTime: undefined,
                        // Parse selected medical examination to plan so
                        // planning flow uses planningRequestId
                        selectedMedicalExaminationToPlan: {
                            ...selectedMedicalExaminationToPlan,
                            employee: {
                                ...selectedMedicalExaminationToPlan.employee,
                                birthDate: employee.birthDate,
                            },
                            ...!!medicalCenter && {
                                medicalCenter,
                            },
                        },
                    },
                }));
            },
        };
    },
})(EmployeeMedicalExaminationsComp);
