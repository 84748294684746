import { IStartEndDateFilterValues } from '../../models/ui/form';
import Api from '../../api';
import { getLocale } from '../i18n/selectors';
import { getSelectedCompanySeat, getSelectedSeatCompanyCode } from '../company/selected/selectors';
import { IState } from '../IState';

export function doExportPlannedExaminationsCall({
    state,
    api,
    defaultFilename,
    filter,
}: {
    state: IState,
    api: typeof Api,
    defaultFilename: string,
    filter: IStartEndDateFilterValues,
}) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
    const locale = getLocale(state);

    return api.interventions.medicalExaminations
        .exportPlannedExaminations(
            { companyCode, showFullFamily, ...filter },
            defaultFilename,
            locale,
        );
}

export function doExportExecutedExaminationsCall({
    state,
    api,
    defaultFilename,
    filter,
}: {
    state: IState,
    api: typeof Api,
    defaultFilename: string,
    filter: IStartEndDateFilterValues,
}) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

    return api.interventions.medicalExaminations
        .exportExecutedExaminations(
            { companyCode, showFullFamily, ...filter },
            defaultFilename,
        );
}
