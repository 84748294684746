import { object } from 'yup';
import { FormValues } from '.';
import { ShapeOf } from '../../../../models/ui/form';
import string from '../../../../utils/libs/yup/string';

export const fields: ShapeOf<FormValues> = {
    forbiddenForYoungPeople: 'forbiddenForYoungPeople',
    localization: 'localization',
    qualification: 'qualification',
    usedWorkTools: 'usedWorkTools',
};

export const schema = object({
    [fields.forbiddenForYoungPeople]: string(),
    [fields.localization]: string(),
    [fields.qualification]: string(),
    [fields.usedWorkTools]: string(),
});
