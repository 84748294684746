import React, { useState } from 'react';
import classNames from 'classnames';
import './lazy-load-embed.scss';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';

interface ILazyLoadEmbedProps {
    embedTitle: string;
    embedUrl: string;
    placeholderUrl: string;
    placeholderAlt: string;
    hidePlayButton?: boolean;
}

const CLASS_NAME = 'LazyLoadEmbed';

export default function LazyLoadEmbed({
    embedTitle,
    embedUrl,
    placeholderUrl,
    placeholderAlt,
    hidePlayButton,
}: ILazyLoadEmbedProps) {
    const [loadEmbedVideo, setLoadEmbedVideo] = useState(false);

    return (
        <div className={classNames(CLASS_NAME, { loaded: loadEmbedVideo })}>
            {placeholderUrl && (
                <figure className={`${CLASS_NAME}__link`} onClick={() => setLoadEmbedVideo(true)}>
                    <img src={placeholderUrl} alt={placeholderAlt} />
                    {!hidePlayButton && <Icon typeName="play" circle className={`${CLASS_NAME}__link__icon`} />}
                </figure>
            )}
            {loadEmbedVideo && (
                embedUrl ? (
                    <iframe
                        title={embedTitle}
                        src={embedUrl}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        width="100%"
                        height="100%"
                        className={`${CLASS_NAME}__embed`}
                    />
                ) : (
                    <p><Translate msg="Sorry, this provider is not supported yet."/></p>
                )
            )}
        </div>
    );
}
