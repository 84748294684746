import React, { useMemo } from 'react';
import { path } from 'ramda';

import Translate from '../../../../../../common/Translate';

import { TUpcomingActivityBlockTitleProps } from './UpcomingActivityBlockTitle.type';

const UpcomingActivityBlockTitle = ({ upcomingActivity }: TUpcomingActivityBlockTitleProps) => {
    const appointmentTypeForTranslation = useMemo(() => {
        return upcomingActivity.type.replace('-', '_');
    }, [upcomingActivity.type]);

    const participant = path(['participant'], upcomingActivity);

    if (upcomingActivity.count > 1) {
        return (
            <span>
                <Translate
                    msg={`home.dashboard.agenda.multiple.${appointmentTypeForTranslation}`}
                    placeholders={{ count: upcomingActivity.count }}
                />
            </span>
        );
    }

    return (
        <span>
            <Translate msg={`home.dashboard.agenda.type.${appointmentTypeForTranslation}`} />
            {upcomingActivity.description && `: ${upcomingActivity.description}`}
            {participant && (upcomingActivity.participant.firstName && upcomingActivity.participant.lastName) && (
                <>
                    <br />
                    {`${upcomingActivity.participant.firstName} ${upcomingActivity.participant.lastName}`}
                </>
            )}
        </span>
    );
};

export { UpcomingActivityBlockTitle };
