import React, { Component } from 'react';
import { connect } from '../../..';
import { IRenderDetailFooterProps } from '../../../common/widget/MasterWithDetail/typings';
import { IPolicyAdviceComment, IPolicyAdvice } from '../../../../models/interventions/policyAdvice';
import Form, { IFormRenderProps } from '../../../common/forms/Form';
import { schema, fields } from './detailFooterSchema';
import SubmitButton from '../../../common/buttons/SubmitButton';
import Translate from '../../../common/Translate';
import { DETAIL_TRANSLATION_PREFIX } from '../common';
import TextInput from '../../../common/input/TextInput';
import FormFieldError from '../../../common/forms/FormFieldError';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import { getRoutePayload } from '../../../../redux/location/selectors';
import { sendPolicyAdviceCommentActions } from '../../../../redux/intervention/policyAdvice/actions';
import { getSelectedSeatCompanyCode } from '../../../../redux/company/selected/selectors';
import {
    getSendPolicyAdviceCommentAsyncInfo,
    getPolicyAdviceCommentsAsyncInfo,
    getPolicyAdvice,
} from '../../../../redux/intervention/policyAdvice/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import { PolicyAdviceStatus } from '../../../../config/intervention.config';

const FORM_NAME = 'policy-advice-feedback-form';
const CLASS_NAME = 'PolicyAdviceDetailFooter';

interface IPrivateProps {
    policyAdviceId: number;
    policyAdvice: IPolicyAdvice;
    sendComment: (values: FormValues) => void;
    sendPolicyAdviceCommentAsyncInfo: IAsyncFieldInfo;
    policyAdviceCommentsAsyncInfo: IAsyncFieldInfo;
}

export interface FormValues {
    feedback: string;
}

const POLICY_ADVICE_FEEDBACK_INITIAL_VALUES: FormValues = {
    feedback: '',
};

class DetailFooterComp extends Component<IPrivateProps & IRenderDetailFooterProps<IPolicyAdviceComment[]>> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props: IPrivateProps & IRenderDetailFooterProps<IPolicyAdviceComment[]>) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidUpdate(
        prevProps: IPrivateProps & IRenderDetailFooterProps<IPolicyAdviceComment[]>,
    ) {
        const switchedDetail = prevProps.policyAdviceId !== this.props.policyAdviceId;

        if (this.formRenderProps && switchedDetail) {
            this.formRenderProps.resetForm({ feedback: '' });
        }
    }

    public render() {
        const { sendPolicyAdviceCommentAsyncInfo, policyAdviceCommentsAsyncInfo, policyAdvice } = this.props;

        const isSendingComment = sendPolicyAdviceCommentAsyncInfo.status === AsyncStatus.Busy;
        const isFetchingComments = policyAdviceCommentsAsyncInfo.status === AsyncStatus.Busy;
        const isResolved = policyAdvice && policyAdvice.resolved === PolicyAdviceStatus.Resolved;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <Form
                        name={FORM_NAME}
                        handleSubmit={this.handleSubmit}
                        initialValues={POLICY_ADVICE_FEEDBACK_INITIAL_VALUES}
                        schema={schema}
                        render={(formRenderProps: IFormRenderProps<FormValues>) => {
                            const { values, errors, touched, handleChange } = formRenderProps;

                            this.formRenderProps = formRenderProps;

                            return (
                                <div className={CLASS_NAME}>
                                    <FloatableTextInputWrapper>
                                        <TextInput
                                            id="policy-advice-feedback-form"
                                            name={fields.feedback}
                                            value={values.feedback}
                                            onChange={handleChange}
                                            isInvalid={touched.feedback && !!errors.feedback}
                                            placeholder={translator(
                                                `${DETAIL_TRANSLATION_PREFIX}.feedback.form.placeholder`)}
                                            multiLine={true}
                                            rows={3}
                                            disableAutoComplete={true}
                                            disableMultilineResize={true}
                                            disabled={isResolved}
                                        />
                                        <label htmlFor="policy-advice-feedback-form" >
                                            <Translate
                                                msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.form.label`}
                                            />
                                        </label>
                                        {touched.feedback &&
                                            <FormFieldError
                                                error={errors.feedback}
                                                placeholders={{
                                                    fieldName: translator(
                                                        `${DETAIL_TRANSLATION_PREFIX}.feedback.form.error_placeholder`),
                                                }}
                                            />}
                                    </FloatableTextInputWrapper>

                                    <SubmitButton
                                        formName={FORM_NAME}
                                        id="policy-advice-feedback-submit"
                                        disabledWhenInvalid={true}
                                        disabled={isResolved || isSendingComment || isFetchingComments}
                                    >
                                        <Translate msg={`${DETAIL_TRANSLATION_PREFIX}.feedback.form.submit`} />
                                    </SubmitButton>
                                </div>
                            );
                        }}
                    />
                )}
            </TranslatorContext.Consumer>
        );
    }

    private handleSubmit(values: FormValues) {
        const { policyAdvice, sendComment } = this.props;

        const isResolved = policyAdvice && policyAdvice.resolved === PolicyAdviceStatus.Resolved;

        if (!isResolved) {
            sendComment(values);
            this.formRenderProps.resetForm({ feedback: '' });
        }
    }
}

export const DetailFooter = connect<IPrivateProps, IRenderDetailFooterProps<IPolicyAdviceComment[]>>({
    stateProps: (state) => {
        const policyAdviceId = Number(getRoutePayload<{ id: string }>(state).id);
        const policyAdvice = getPolicyAdvice(state).find((item) => item.id === policyAdviceId);

        return {
            policyAdviceId,
            policyAdvice,
            sendPolicyAdviceCommentAsyncInfo: getSendPolicyAdviceCommentAsyncInfo(state),
            policyAdviceCommentsAsyncInfo: getPolicyAdviceCommentsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            sendComment: (values: FormValues) => {
                const state = getState();
                const id = Number(getRoutePayload<{ id: string }>(state).id);
                const companyCode = getSelectedSeatCompanyCode(state);
                dispatch(sendPolicyAdviceCommentActions.trigger({
                    id,
                    companyCode,
                    comment: values.feedback,
                }));
            },
        };
    },
})(DetailFooterComp);
