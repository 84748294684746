import React from 'react';
import './index-info.box.scss';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';

const CLASS_NAME = 'IndexInfoBox';

export default function IndexInfoBox({ titleTranslationKey, descriptionTranslationKey }: {
    titleTranslationKey: string;
    descriptionTranslationKey: string;
}) {
    return (
        <div className={CLASS_NAME}>
            <Icon typeName="info" />
            <div className={`${CLASS_NAME}__content`}>
                <h6 className={`${CLASS_NAME}__title`}>
                    <Translate msg={titleTranslationKey} />
                </h6>
                <p><Translate msg={descriptionTranslationKey} raw /></p>
            </div>
        </div>
    );
}
