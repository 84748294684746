import { createEpic } from '../../index';
import {
    FETCH_ACTIVITY_REPORTS,
    GENERATE_ACTIVITY_REPORT,
    GENERATE_COMPANY_AND_EMPLOYEE_REPORT,
    FETCH_ACTIVITY_REPORT_DETAIL,
} from './types';
import {
    fetchActivityReportsActions,
    generateActivityReportActions,
    generateCompanyAndEmployeeReportActions,
    fetchActivityReportDetailActions,
} from './actions';
import {
    IFetchActivityReportsPayload,
    IGenerateActivityReportPayload,
    IGenerateCompanyAndEmployeeReportPayload,
    IFetchActivityReportDetailPayload,
} from '../../../models/general/documents';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat, isAllSeatsSelected } from '../selected/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import { resetFetchDocument } from '../../employee/documents/actions';
import { triggerFlashThatDownloadToMessageCenterIsTriggered } from '../../flashMessages/actions';

// fetchActivityReportsEpic
createEpic<IFetchActivityReportsPayload>({
    onActionType: FETCH_ACTIVITY_REPORTS,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

            dispatch(resetFetchDocument());

            const payload: IFetchActivityReportsPayload = {
                year: action.payload.year,
                companyCode,
                showFullFamily,
            };

            const activityReports = await api.general.documents.fetchActivityReports(payload);
            dispatch(fetchActivityReportsActions.succeeded(activityReports));
        } catch (error) {
            dispatch(fetchActivityReportsActions.failed(error));
        }
        done();
    },
    latest: false,
});

// resetActivityReportsEpic
createEpic({
    onActionType: ROUTE_KEYS.R_ACTIVITY_REPORT,
    async processMultiple({ }, dispatch, done) {
        dispatch(generateActivityReportActions.reset({}));
        dispatch(fetchActivityReportDetailActions.reset({}));
        dispatch(resetFetchDocument());
        done();
    },
    latest: false,
});

// resetCompanyAndEmployeeReportEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_AND_EMPLOYEE_REPORT,
    async processReturn({ }) {
        return generateCompanyAndEmployeeReportActions.reset({});
    },
    latest: false,
});

// generateActvityReportEpic
createEpic<IGenerateActivityReportPayload>({
    onActionType: GENERATE_ACTIVITY_REPORT,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const allSeatsSelected = isAllSeatsSelected(state);

            const payload: IGenerateActivityReportPayload = {
                companyCode,
                showFullFamily: allSeatsSelected,
                individualSeats: action.payload.individualSeats,
                locale: action.payload.locale,
            };

            await api.general.documents.generateActivityReport(payload);
            return generateActivityReportActions.succeeded({});
        } catch (error) {
            return generateActivityReportActions.failed(error);
        }
    },
    latest: false,
});

// generateCompanyAndEmployeeReportEpic
createEpic<IGenerateCompanyAndEmployeeReportPayload>({
    onActionType: GENERATE_COMPANY_AND_EMPLOYEE_REPORT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const allSeatsSelected = isAllSeatsSelected(state);

            const payload: IGenerateCompanyAndEmployeeReportPayload = {
                ...action.payload,
                companyCode,
                showFullFamily: allSeatsSelected,
            };

            await api.general.documents.generateCompanyAndEmployeeReport(payload);
            dispatch(triggerFlashThatDownloadToMessageCenterIsTriggered());

            dispatch(generateCompanyAndEmployeeReportActions.succeeded({}));
        } catch (error) {
            dispatch(generateCompanyAndEmployeeReportActions.failed(error));
        }
        done();
    },
    latest: false,
});

// fetchActivityReportDetailEpic
createEpic<IFetchActivityReportDetailPayload>({
    onActionType: FETCH_ACTIVITY_REPORT_DETAIL,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const allSeatsSelected = isAllSeatsSelected(state);

            const payload: IFetchActivityReportDetailPayload = {
                ...action.payload,
                companyCode,
                showFullFamily: allSeatsSelected,
            };

            const file = await api.general.documents.fetchActivityReportDetail(payload);

            return fetchActivityReportDetailActions.succeeded(file);
        } catch (error) {
            return fetchActivityReportDetailActions.failed(error);
        }
    },
    latest: false,
});
