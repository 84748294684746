import React from 'react';
import {
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
    IPlanMedicalExaminationWizardStep,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
} from '../../../../models/interventions/medicalExaminations';
import { ADD_EMPLOYEE_WIZARD_STEP_ID } from '../../../../models/admin/employee';
import SearchEmployeeToPlan from './shared/SearchEmployeeToPlan';
import BookTimeslot from './manual/BookTimeslot';
import NotifyEmployee from './shared/NotifyEmployee';
import EmployeesToPlan from './automatic/EmployeesToPlan';
import AutomaticStartDate from './automatic/AutomaticStartDate';
import AutomaticTime from './automatic/AutomaticTime';
import AutomaticValidate from './automatic/AutomaticValidate';
import AutomaticNotify from './automatic/AutomaticNotify';
import AddEmployeePersonalData from '../../../administration/Employees/AddEmployee/steps/PersonalData';
import AddEmployeeSeat from '../../../administration/Employees/AddEmployee/steps/Seat';
import AddEmployeeFunction from '../../../administration/Employees/AddEmployee/steps/Function';
import InterimNationalRegisterNumber from './interim/InterimNationalRegisterNumber';
import InterimStartDate from './interim/InterimStartDate';
import InterimWorkPostCard from './interim/InterimWorkPostCard';
import ResumptionAbsence from './resumption/ResumptionAbsence';
import ReIntegrationDetails from './reIntegration/ReIntegrationDetails';
import ReIntegrationStartDate from './reIntegration/ReIntegrationStartDate';
import ContactPersonManual from './shared/ContactPersonManual';
import ReIntegrationValidate from './reIntegration/ReIntegrationValidate';
import ResumptionStartDate from './resumption/ResumptionStartDate';
import { registerWizardSteps, WIZARD_CODE } from '../../../../config/navigation/wizardStepsMap';

const PERIODIC_HEALTH_ASSESSMENT_MANUAL_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.SEARCH_EMPLOYEE,
    translationKey: 'interventions.medical_examinations.new.steps.search_employee.step_label',
    renderStepContent: (props) => <SearchEmployeeToPlan {...props} />,
    hide: (entity: IPlanMedicalExaminationSingleEmployeeBaseEntity) => {
        return entity.searchEmployee && entity.searchEmployee.autoSelected;
    },
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.select_timeslot.step_label',
    renderStepContent: (props) => <BookTimeslot {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify.step_label',
    renderStepContent: (props) => <NotifyEmployee {...props} />,
    hideExitLink: true,
    doNotAllowPreviousFromThisStep: true,
}];

const PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.EMPLOYEES_TO_PLAN,
    translationKey: 'interventions.medical_examinations.new.steps.employees_to_plan.step_label',
    renderStepContent: (props) => <EmployeesToPlan {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_START_DATE,
    translationKey: 'interventions.medical_examinations.new.steps.start_date.step_label',
    renderStepContent: (props) => <AutomaticStartDate {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.time.step_label',
    renderStepContent: (props) => <AutomaticTime {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_VALIDATE,
    translationKey: 'interventions.medical_examinations.new.steps.validate.step_label',
    renderStepContent: (props) => <AutomaticValidate {...props} />,
    doNotAllowPreviousFromThisStep: true,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify_auto_plan.step_label',
    renderStepContent: (props) => <AutomaticNotify {...props} />,
    hideExitLink: true,
}];

const INTERIM_MANUAL_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.NATIONAL_REGISTER_NUMBER,
    translationKey: 'administration.employees.add.steps.national_register_number.step_label',
    renderStepContent: (props) => <InterimNationalRegisterNumber {...props} />,
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.PERSONAL_DATA,
    translationKey: 'administration.employees.add.steps.personal_data.step_label',
    renderStepContent: (props) => <AddEmployeePersonalData {...props} />,
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.SEAT,
    translationKey: 'administration.employees.add.steps.seat.step_label',
    renderStepContent: (props) => <AddEmployeeSeat {...props} />,
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.FUNCTION,
    translationKey: 'administration.employees.add.steps.function.step_label',
    renderStepContent: (props) => <AddEmployeeFunction {...props} />,
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.INTERIM_STARTDATE,
    translationKey: 'administration.employees.add.steps.startdate.step_label',
    renderStepContent: (props) => <InterimStartDate {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.WORK_POST_CARD,
    translationKey: 'interventions.medical_examinations.new.steps.work_post_card.step_label',
    renderStepContent: (props) => <InterimWorkPostCard {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.select_timeslot.step_label',
    renderStepContent: (props) => <BookTimeslot {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify.step_label',
    renderStepContent: (props) => <NotifyEmployee {...props} />,
    hideExitLink: true,
    doNotAllowPreviousFromThisStep: true,
}];

const RESUMPTION_OF_WORK_MANUAL_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.SEARCH_EMPLOYEE,
    translationKey: 'interventions.medical_examinations.new.steps.search_employee.step_label',
    renderStepContent: (props) => <SearchEmployeeToPlan {...props} />,
    hide: (entity: IPlanMedicalExaminationSingleEmployeeBaseEntity) => {
        return entity.searchEmployee && entity.searchEmployee.autoSelected;
    },
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.RESUMPTION_ABSENCE,
    translationKey: 'interventions.medical_examinations.new.steps.resumption_absence.step_label',
    renderStepContent: (props) => <ResumptionAbsence {...props} />,
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.STARTDATE,
    translationKey: 'interventions.medical_examinations.new.steps.resumption_start_date.step_label',
    renderStepContent: (props) => <ResumptionStartDate {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.select_timeslot.step_label',
    renderStepContent: (props) => <BookTimeslot {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify.step_label',
    renderStepContent: (props) => <NotifyEmployee {...props} />,
    hideExitLink: true,
    doNotAllowPreviousFromThisStep: true,
}];

const RE_INTEGRATION_MANUAL_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.SEARCH_EMPLOYEE,
    translationKey: 'interventions.medical_examinations.new.steps.search_employee.step_label',
    renderStepContent: (props) => <SearchEmployeeToPlan {...props} />,
    hide: (entity: IPlanMedicalExaminationSingleEmployeeBaseEntity) => {
        return entity.searchEmployee && entity.searchEmployee.autoSelected;
    },
}, {
    id: ADD_EMPLOYEE_WIZARD_STEP_ID.STARTDATE,
    translationKey: 'interventions.medical_examinations.new.steps.re_integration_start_date.step_label',
    renderStepContent: (props) => <ReIntegrationStartDate {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.RE_INTEGRATION_DETAILS,
    translationKey: 'interventions.medical_examinations.new.steps.re_integration_details.step_label',
    renderStepContent: (props) => <ReIntegrationDetails {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.CONTACT_PERSON_MANUAL,
    translationKey: 'interventions.medical_examinations.new.steps.contact_person_manual.step_label',
    renderStepContent: (props) => <ContactPersonManual {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.RE_INTEGRATION_VALIDATE,
    translationKey: 'interventions.medical_examinations.new.steps.re_integration_validate.step_label',
    renderStepContent: (props) => <ReIntegrationValidate {...props} />,
}];

const OTHER_MANUAL_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.SEARCH_EMPLOYEE,
    translationKey: 'interventions.medical_examinations.new.steps.search_employee.step_label',
    renderStepContent: (props) => <SearchEmployeeToPlan {...props} />,
    hide: (entity: IPlanMedicalExaminationSingleEmployeeBaseEntity) => {
        return entity.searchEmployee && entity.searchEmployee.autoSelected;
    },
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.select_timeslot.step_label',
    renderStepContent: (props) => <BookTimeslot {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify.step_label',
    renderStepContent: (props) => <NotifyEmployee {...props} />,
    hideExitLink: true,
    doNotAllowPreviousFromThisStep: true,
}];

const MOVE_PLANNING_STEPS: IPlanMedicalExaminationWizardStep[] = [{
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
    translationKey: 'interventions.medical_examinations.new.steps.select_timeslot.step_label',
    renderStepContent: (props) => <BookTimeslot {...props} />,
}, {
    id: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
    translationKey: 'interventions.medical_examinations.new.steps.notify.step_label',
    renderStepContent: (props) => <NotifyEmployee {...props} />,
    hideExitLink: true,
    doNotAllowPreviousFromThisStep: true,
}];

export default function registerPlanMedicalExaminationWizardSteps() {
    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_PO_MANUAL,
        steps: PERIODIC_HEALTH_ASSESSMENT_MANUAL_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_PO_AUTOMATIC,
        steps: PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_INTERIM,
        steps: INTERIM_MANUAL_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_RESUMPTION_OF_WORK,
        steps: RESUMPTION_OF_WORK_MANUAL_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_RE_INTEGRATION,
        steps: RE_INTEGRATION_MANUAL_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_OTHER,
        steps: OTHER_MANUAL_STEPS,
    });

    registerWizardSteps({
        wizardCode: WIZARD_CODE.PLAN_MED_EXAM_MOVE_PLANNING,
        steps: MOVE_PLANNING_STEPS,
    });
}
