import { get, IResponseType } from '../../../../../../utils/api/requestWrapper';
import { IDocument, IFetchActivityReportDetailPayload } from '../../../../../../models/general/documents';
import { ONE_MINUTE } from '../../../../../../utils/core/time/periodsInMillis';
import getFilenameFromHeaders from '../../../../getFilenameFromHeaders';
import { URL } from '../../../documents.const';

export function fetchActivityReportDetail(payload: IFetchActivityReportDetailPayload) {
    const { companyCode, showFullFamily, id, year } = payload;
    return get<IDocument>({
        url: URL.ACTIVITY_REPORT_DETAIL,
        responseType: IResponseType.blob,
        timeoutInMillis: 2 * ONE_MINUTE,
        pathParams: {
            companyCode,
            year,
            id,
        },
        queryParams: {
            showFullFamily,
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, 'activity-report-detail.xls'),
            };
        },
    });
}
