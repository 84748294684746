import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';
import { getCourseSessionsByLocation } from '../../../../../redux/documentCenter/courses/selectors';
import { ICourseSessionByLocation } from '../../../../../models/documentCenter/courses';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import { formatDateWithHoursAndMinutes } from '../../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';

interface IPrivateProps {
    sessions: ICourseSessionByLocation[];
    sessionsTypeahead: TTypeaheadData;
}

interface ICourseSessionsTypeaheadProps {
    id: string;
    value: number; // session id
    name: string;
    onItemSelected: (id: number, session: ICourseSessionByLocation) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholderTranslationKey?: string;
    disabled?: boolean;
}

function CourseLocationTypeahead(props: ICourseSessionsTypeaheadProps & IPrivateProps) {
    const {
        children, id, value,
        name, isInvalid, onItemSelected,
        sessions, sessionsTypeahead,
        placeholderTranslationKey, disabled,
    } = props;

    function onItemSelectedHandler(coursesOrganizedId: number) {
        const selectedSession = sessions.find((item) => item.coursesOrganizedId === coursesOrganizedId);

        onItemSelected(coursesOrganizedId, selectedSession || null);
    }

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <Typeahead
                    id={id}
                    value={value}
                    name={name}
                    onItemSelected={onItemSelectedHandler}
                    isInvalid={isInvalid}
                    data={sessionsTypeahead}
                    disableSort={true}
                    placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
                    disabled={disabled}
                >
                    {children}
                </Typeahead>
            )}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps, ICourseSessionsTypeaheadProps>({
    statePropsPerInstance: (state) => {
        return (state) => {
            const sessions = getCourseSessionsByLocation(state);
            return {
                sessions,
                sessionsTypeahead: mapSessionsForTypeahead(sessions),
            };
        };
    },
})(CourseLocationTypeahead);

function mapSessionsForTypeahead(
    sessions: ICourseSessionByLocation[],
): TTypeaheadData {
    return Array.isArray(sessions) ? sessions
        .sort(sortByDate)
        .map((item: ICourseSessionByLocation) => ({
            value: item.coursesOrganizedId,
            label: `${formatDateWithHoursAndMinutes(item.start)}-${formatTimeOfDateForDisplay(item.stop)}`,
        })) : [];
}

function sortByDate(a: ICourseSessionByLocation, b: ICourseSessionByLocation) {
    return (
        a.start < b.start ?
            -1 : a.start > b.start ?
                1 : 0
    );
}
