import React from 'react';
import { schema } from './companyVisitRemarksSchema';
import RemarksInput, { IFormValues } from './RemarksInput';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard/index';
import WizardFormStep from '../../../../common/navigation/WizardFormStep';
import { getCompanyVisitRequestWizardEntity } from '../../../../../redux/company/visits/selectors';
import { updateCompanyVisitRequestWizardEntity } from '../../../../../redux/company/visits/actions';

const CLASS_NAME = 'RequestCompanyVisit__Remarks';
const FORM_NAME = 'request-company-visit-remarks-form';

const RemarksWizardFormStep = WizardFormStep<IFormValues>();

export default function Remarks(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <RemarksWizardFormStep
            {...stepperStepRenderProps}
            baseClassName={CLASS_NAME}
            header={{
                titleTranslationKey: 'interventions.company_visits_new.steps.remarks.title',
            }}
            showLoaderSelector={() => false}
            form={{
                name: FORM_NAME,
                schema,
                initialValuesSelector: (state) => {
                    const wizardEntity = getCompanyVisitRequestWizardEntity(state);

                    return {
                        remark: (wizardEntity.remarks && wizardEntity.remarks.remark) || '',
                    };
                },
                renderStepFormContent: (renderProps) =>
                    <div className={WIZARDFLOW_CLASSES.NARROW_FORM}>
                        <RemarksInput {...renderProps} />
                    </div>,
            }}
            stepButtons={{
                onConfirmActionCreator: ({ values }) => updateCompanyVisitRequestWizardEntity({
                    remarks: {
                        remark: values.remark,
                    },
                }),
            }}
        />
    );
}
