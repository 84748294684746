import { IDroppableFreeTimeslotData, ITimeSlotBase } from '../../../../../models/interventions/timeslots';
import { CalendarEventType, CalendarEventDataType, ICalendarEvent } from '../../../../../models/ui/calendar';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { getDate, getDateWithoutSeconds } from '../../../../../utils/core/date/getSpecificDate';
import { getLatestPossibleTimeOnDate } from '../../../../../utils/core/date/getLatestPossibleTimeOnDate';
import {
    getMinutesAvailableAfterTimeslot,
} from '../../../../../utils/interventions/medicalExaminations/getMinutesAvailableAfterTimeslot';
import { IPlannedTimeSlot } from '../../../../../models/interventions/medicalExaminations';
import { isOnSameDay } from '../../../../../utils/core/date/isOnSameDay';

export const mapBufferzoneFreeTimeslotsToCalendarEvents = (timeCells: ITimeSlotBase[]) => {
    return timeCells.map(
        (timeCell) => {
            const start = getDate(timeCell.start);
            let end = getDate(timeCell.end);

            if (!isOnSameDay(start, end)) {
                end = getDate(getLatestPossibleTimeOnDate(timeCell.start));
            }

            const event: ICalendarEvent<IDroppableFreeTimeslotData> = {
                id: `${CalendarEventType.DroppableFreeTimeslot}_${timeCell.id}`,
                start,
                end,
                allDay: false,
                type: CalendarEventType.DroppableFreeTimeslot,
                data: {
                    timeCell,
                    minutesAvailableAfterTimeslot: getMinutesAvailableAfterTimeslot(timeCell, timeCells),
                    amountOfFreeTimeslotsWithSameTime: 1,
                },
                dataType: CalendarEventDataType.TimeCell,
                isConcatenated: false,
                reserved: false,
            };

            return event;
        },
    ).reduce(
        (accumulator, event) => {
            const { start, end } = event;
            const eventWithSameStartAndEndTimeIndex = accumulator.findIndex((item) => {
                return (
                    item.allDay === event.allDay &&
                    item.type === event.type &&
                    getDateWithoutSeconds(item.start).getTime() === getDateWithoutSeconds(start).getTime() &&
                    getDateWithoutSeconds(item.end).getTime() === getDateWithoutSeconds(end).getTime()
                );
            });
            event.isConcatenated = true;
            if (eventWithSameStartAndEndTimeIndex !== -1) {
                const overlappingEvent =
                    accumulator[eventWithSameStartAndEndTimeIndex] as ICalendarEvent<IDroppableFreeTimeslotData>;
                overlappingEvent.data.amountOfFreeTimeslotsWithSameTime += 1;
            } else {
                accumulator.push(event);
            }
            return accumulator;
        },
        [],
    );
};

export const mapPlannedMedicalExaminationsToCalendarEvents = (examinations: IPlannedTimeSlot[]) => {
    return examinations.map((examination): ICalendarEvent<IPlannedTimeSlot> => {
        const start = getDate(examination.start);
        let end = getDate(examination.end);

        // BigCalender shows events which are spread over multiple days, as day events (on the top).
        // to prevent an event with startTime Fri ... 23:45 and endTime Sat ... 0:00 we adjust the endTime
        // to to latest possible time on the day of the startdate (Fri 23:59:59)
        if (!isOnSameDay(end, start)) {
            end = getDate(getLatestPossibleTimeOnDate(examination.start));
        }

        return {
            id: `${CalendarEventType.DraggableMedicalExamination}_${examination.timeSlotId}`,
            start,
            end,
            allDay: false,
            type: CalendarEventType.DraggableMedicalExamination,
            data: examination,
            dataType: CalendarEventDataType.DraggablePlannedMedicalExamination,
            isConcatenated: false,
            title: formatPersonName({
                firstName: examination.employee.firstName,
                name: examination.employee.lastName,
            }),
        };
    });
};
