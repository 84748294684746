import React, { PureComponent } from 'react';
import { IRenderDetailHeaderProps } from '../../../common/widget/MasterWithDetail/typings';
import { ICourse } from '../../../../models/documentCenter/courses';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import Loader from '../../../common/waiting/Loader/index';
import { AsyncStatus } from '../../../../models/general/redux';

export default class DetailHeader extends
    PureComponent<IRenderDetailHeaderProps<ICourse>> {

    constructor(props: IRenderDetailHeaderProps<ICourse>) {
        super(props);
    }

    public render() {
        const {
            detailAsyncInfo,
            detailData: selectedCourse,
        } = this.props;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                     <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
                        <h1>
                            {detailAsyncInfo.error
                                ? translator('error.title')
                                : selectedCourse && selectedCourse.courseName
                            }
                        </h1>
                    </Loader>
                )}
            </TranslatorContext.Consumer>
        );
    }
}
