import React, { Component } from 'react';
import WizardStep from '../../../../../common/navigation/WizardStep';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    getCourseEnrollWizardCourse, getCourseEnrollWizardEntity, getCreateCourseEnrollAsyncInfo,
} from '../../../../../../redux/documentCenter/courses/selectors';
import {
    updateCourseEnrollWizardEntity, createCourseEnrollmentActions,
} from '../../../../../../redux/documentCenter/courses/actions';
import EmployeeList, { IContentValues } from './list';
import Loader from '../../../../../common/waiting/Loader';
import { connect } from '../../../../../index';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import FormError from '../../../../../common/forms/FormError';
import FinishEnrollmentDialog from '../FinishEnrollmentDialog';
import { ICourseEnrollWizardEntity } from '../../../../../../models/documentCenter/courses';
import { ITraceableApiError } from '../../../../../../models/general/error';
import { clearErrors } from '../../../../../../utils/libs/redux/generic/actions';

const CLASS_NAME = 'CourseEnrollment__employee';

const EmployeeStep = WizardStep<IContentValues>();

interface IPrivateProps {
    createCourseEnrollmentAsyncInfo: IAsyncFieldInfo;
    wizardCourseEntity: Partial<ICourseEnrollWizardEntity>;
    clearError: (error: ITraceableApiError) => void;
}

interface IComponentState {
    isFinishDialogOpen: boolean;
}

class Employee extends Component<IPrivateProps & IStepperStepRenderProps, IComponentState> {
    constructor(props) {
        super(props);

        this.state = {
            isFinishDialogOpen: false,
        };

        this.onCloseFinishDialogHandler = this.onCloseFinishDialogHandler.bind(this);
        this.areEmployeesSelected = this.areEmployeesSelected.bind(this);
        this.shouldDisableNextButton = this.shouldDisableNextButton.bind(this);
    }

    public componentDidUpdate(
        prevProps: IPrivateProps & IStepperStepRenderProps,
    ) {
        if (prevProps.createCourseEnrollmentAsyncInfo.status === AsyncStatus.Busy &&
            this.props.createCourseEnrollmentAsyncInfo.status === AsyncStatus.Success) {
            this.setState({
                isFinishDialogOpen: true,
            });
        }
    }

    public componentDidMount() {
        const { clearError, createCourseEnrollmentAsyncInfo } = this.props;
        clearError(createCourseEnrollmentAsyncInfo.error);
    }

    public render() {
        const {
            createCourseEnrollmentAsyncInfo,
        } = this.props;

        const {
            isFinishDialogOpen,
        } = this.state;

        return (
            <EmployeeStep
                {...this.props}
                baseClassName={CLASS_NAME}
                header={{
                    titleTranslationKey: 'document_center.courses.new.steps.employee.title',
                    titlePlaceholders: (state) => {
                        const wizardCourse = getCourseEnrollWizardCourse(state);

                        return {
                            name: wizardCourse.name,
                        };
                    },
                }}
                showLoaderSelector={() => false}
                content={{
                    initialValuesSelector: (state) => {
                        const entity = getCourseEnrollWizardEntity(state);

                        return {
                            selectedEmployees: entity.employees || [],
                            selectedExternalEmployees: entity.externalEmployees || [],
                        };
                    },
                    renderStepContent: (renderProps) => (
                        <>
                            <EmployeeList {...renderProps} />
                            <FormError error={createCourseEnrollmentAsyncInfo.error} />
                            <Loader show={createCourseEnrollmentAsyncInfo.status} />
                            <FinishEnrollmentDialog
                                open={isFinishDialogOpen}
                                onClose={this.onCloseFinishDialogHandler}
                            />
                        </>
                    ),
                    onChangeInputActionCreator: ({ values }) => updateCourseEnrollWizardEntity({
                        employees: values.selectedEmployees,
                        externalEmployees: values.selectedExternalEmployees,
                    }),
                }}
                stepButtons={{
                    nextButtonTranslationKey: 'document_center.courses.new.steps.employee.submit',
                    onConfirmActionCreator: this.areEmployeesSelected() ?
                        () => createCourseEnrollmentActions.trigger({}) : null,
                    isFinalStep: true,
                    shouldDisableNextButton: this.shouldDisableNextButton,
                }}
            />
        );
    }

    private areEmployeesSelected() {
        const { wizardCourseEntity } = this.props;

        const areEmployeesSelected = wizardCourseEntity &&
            ((wizardCourseEntity.employees && wizardCourseEntity.employees.length > 0) ||
                (wizardCourseEntity.externalEmployees && wizardCourseEntity.externalEmployees.length > 0));

        return areEmployeesSelected;
    }

    private shouldDisableNextButton(values: IContentValues) {
        const areEmployeesSelected = values &&
            ((values.selectedEmployees && values.selectedEmployees.length > 0) ||
                (values.selectedExternalEmployees && values.selectedExternalEmployees.length > 0));

        return !areEmployeesSelected;
    }

    private onCloseFinishDialogHandler() {
        this.setState({ isFinishDialogOpen: false });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            createCourseEnrollmentAsyncInfo: getCreateCourseEnrollAsyncInfo(state),
            wizardCourseEntity: getCourseEnrollWizardEntity(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            clearError: (error: ITraceableApiError) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(Employee);
