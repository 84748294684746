import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './floatable-text-input-wrapper.scss';

interface IPublicProps {
    children?: ReactNode;
    floatLabel?: boolean;
    inline?: boolean;
    hide?: boolean;
    className?: string;
    size?: 'small';
    type?: 'sub';
}

const FloatableTextInputWrapper = (props: IPublicProps) => {
    if (props.hide) { return (<></>); }

    const wrapperClass = classNames('FloatableTextInputWrapper', {
        'FloatableTextInputWrapper--float': props.floatLabel,
        'FloatableTextInputWrapper--inline': props.inline,
        'FloatableTextInputWrapper--small': props.size === 'small',
        'FloatableTextInputWrapper--sub': props.type === 'sub',
    });

    return (
        <div className={classNames(wrapperClass, props.className)}>
            {props.children}
        </div>
    );
};

export default FloatableTextInputWrapper;
