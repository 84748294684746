import React from 'react';
import { IBaseContactPerson, IBaseContactPersonWithMobilePhone } from '../../../../models/admin/companyInfo';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import './contact-information.scss';
import isSetString from '@snipsonian/core/es/string/isSetString';

interface IPublicProps {
    contact: IBaseContactPerson | IBaseContactPersonWithMobilePhone;
    contactType?: string | React.ReactElement<{
        msg: string;
        placeholders?: { [key: string]: string }
    }>;
    showMobilePhone?: boolean; // default false
}

const CLASS_NAME = 'ContactInformation';

export default function ContactInformation(props: IPublicProps) {
    const {
        contact, contactType,
        showMobilePhone = false,
    } = props;

    if (!contact) {
        return null;
    }

    return (
        <div className={CLASS_NAME}>
            <div className="section">
                <strong className="name">{formatPersonName(contact)}</strong><br/>
                {contact.type || contactType}
            </div>
            <div className="section">
                <a href={`mailto:${contact.email}`}>
                    {contact.email}
                </a><br/>
                {getContactNumber(contact, showMobilePhone)}
            </div>
        </div>
    );
}

function getContactNumber(
    contact: IBaseContactPerson | IBaseContactPersonWithMobilePhone,
    showMobilePhone: boolean,
) {
    if (showMobilePhone && hasMobilePhone(contact)) {
        return contact.mobilePhone;
    }

    return contact.phone;
}

function hasMobilePhone(
    contact: IBaseContactPerson | IBaseContactPersonWithMobilePhone,
): contact is IBaseContactPersonWithMobilePhone {
    return isSetString(contact['mobilePhone']);
}
