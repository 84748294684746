import isSet from '@snipsonian/core/es/is/isSet';
import { isTravelCostCharged } from '../../../config/intervention.config';
import { IFluVaccineTariffs, IFluVaccinesOrder } from '../../../models/interventions/fluVaccines';

export function getVaccineUnitPrice(tariffs: IFluVaccineTariffs) {
    return tariffs ? tariffs.influenzaTariff : 0;
}

export function getTravelCost(tariffs: IFluVaccineTariffs, vaccinationLocationId: number) {
    if (isTravelCostCharged(vaccinationLocationId)) {
        return tariffs ? tariffs.travelCostTariff : 0;
    }

    return 0;
}

export function calculateTotalVaccinesPrice(
    nrOfVaccines: number,
    tariffs: IFluVaccineTariffs,
): number {
    return getTotalVaccinesPrice({
        nrOfVaccines,
        unitPrice: getVaccineUnitPrice(tariffs),
    });
}

export function calculateTotalVaccinesOrderPrice(
    nrOfVaccines: number,
    tariffs: IFluVaccineTariffs,
    vaccinationLocationId: number,
): number {
    return getTotalVaccinesOrderPrice({
        totalVaccinesPrice: calculateTotalVaccinesPrice(nrOfVaccines, tariffs),
        travelCost: getTravelCost(tariffs, vaccinationLocationId),
    });
}

export function calculateInitialFluVaccinesOrderPriceFromOrderData(
    order: Pick<IFluVaccinesOrder, 'unitPrice' | 'travelExpenses' | 'amountVaccinesOrdered'>,
) {
    if (!isSet(order.unitPrice)) {
        return null; // will be displayed as '-' by the formatPrice
    }

    const travelCost = typeof order.travelExpenses === 'string' ?
        parseFloat(order.travelExpenses) : order.travelExpenses;

    return getTotalVaccinesOrderPrice({
        totalVaccinesPrice: calculateInitialFluVaccinesOrderPriceWithoutTravelExpensesFromOrderData(order),
        travelCost,
    });
}

export function calculateInitialFluVaccinesOrderPriceWithoutTravelExpensesFromOrderData(
    order: Pick<IFluVaccinesOrder, 'unitPrice' | 'amountVaccinesOrdered'>,
) {
    const unitPrice = typeof order.unitPrice === 'string' ?
        parseFloat(order.unitPrice) : order.unitPrice;

    return getTotalVaccinesPrice({
        nrOfVaccines: order.amountVaccinesOrdered,
        unitPrice,
    });
}

function getTotalVaccinesPrice({
    nrOfVaccines,
    unitPrice = 0,
}: {
    nrOfVaccines: number,
    unitPrice: number,
}) {
    return nrOfVaccines * unitPrice;
}

function getTotalVaccinesOrderPrice({
    totalVaccinesPrice,
    travelCost = 0,
}: {
    totalVaccinesPrice: number,
    travelCost: number,
}) {
    return totalVaccinesPrice + travelCost;
}
