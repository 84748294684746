import React, { useEffect, useState, useMemo } from 'react';
import { clone } from 'ramda';

import { getDate, hoursOffsetFromNow } from '../../../../../../../utils/core/date/getSpecificDate';
import { ListColumns, ListItem } from '../../../../../../../models/general/list';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../../../config/medicalExamination.config';
import ErrorPlaceholder from '../../../../../../common/error/ErrorPlaceholder';
import ListWithRadioButtonSelect from '../../../../../../common/list/ListWithRadioButtonSelect';
import { CLASS_NAME, BASE_NAME } from '../SelectBufferzoneOverview.const';
import { ISelectBufferzoneOverviewColumnNames } from '../SelectBufferzoneOverview.type';

import { COLUMNS, INITIAL_SORT } from './BufferzonesList.const';
import { renderFreeSlots } from './BufferzonesList.helper';
import { TBufferzonesListProps } from './BufferzonesList.type';

const BufferzonesList = ({
    masterAsyncInfo,
    masterData: clientSideFilteredlistItems,
    values,
    onChangeInput,
    bufferzones,
}: TBufferzonesListProps) => {
    const [selectedBufferzoneId, setSelectedBufferzoneId] = useState();
    const columns = useMemo<ListColumns<ISelectBufferzoneOverviewColumnNames>>(() => clone(COLUMNS), []);

    // Set selected bufferzone
    useEffect(() => {
        if (!selectedBufferzoneId) {
            return;
        }

        const bufferzone = bufferzones.find((bufferzone) => bufferzone.activity.id === selectedBufferzoneId);

        if (bufferzone) {
            onChangeInput({ selectedBufferzone: bufferzone });
        }
    }, [bufferzones, selectedBufferzoneId, onChangeInput]);

    useEffect(() => {
        columns.freeSlots.render = renderFreeSlots;
    }, [columns]);

    const getCustomRowClasses = (listItem: ListItem<ISelectBufferzoneOverviewColumnNames>) => {
        if (!isItemClickable(listItem)) {
            return 'disabled';
        }
        return '';
    };

    const isItemClickable = (listItem: ListItem<ISelectBufferzoneOverviewColumnNames>) => {
        return (
            listItem.columns.freeSlots > 0 &&
            hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() <=
            getDate(listItem.columns.endTime as string)
        );
    };

    const onItemSelected = (id: React.ReactText) => {
        setSelectedBufferzoneId(id);
    };

    return (
        <div className={CLASS_NAME}>
            <ListWithRadioButtonSelect
                withSorting={true}
                columns={columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error && <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemId={values && values.selectedBufferzone && values.selectedBufferzone.activity.id}
                initialSort={INITIAL_SORT}
                onItemSelected={onItemSelected}
                getCustomRowClasses={getCustomRowClasses}
                isItemClickable={isItemClickable}
            />
        </div>
    );
};

export { BufferzonesList };
