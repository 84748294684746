import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import SearchEmployeeInput, { IContentValues } from '../SearchEmployeeInput';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    updatePlanMedicalExaminationWizardEntity,
    validateEmployeeToPlanActions,
} from '../../../../../../redux/medicalExamination/actions';
import {
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../redux/medicalExamination/selectors';
import WizardStep from '../../../../../common/navigation/WizardStep';
import ValidateEmployeeToPlanDialog from '../ValidateEmployeeToPlanDialog';

const CLASS_NAME = 'PlanMedicalExamination__SearchEmployee';

const SearchEmployeeWizardStep = WizardStep<IContentValues>();

export default function SearchEmployeeToPlan(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <>
            <ValidateEmployeeToPlanDialog onConfirm={stepperStepRenderProps.goToNextStep} />
            <SearchEmployeeWizardStep
                {...stepperStepRenderProps}
                baseClassName={CLASS_NAME}
                header={{
                    titleTranslationKey: 'interventions.medical_examinations.new.steps.search_employee.title',
                    textTranslationKey: 'interventions.medical_examinations.new.steps.search_employee.text',
                }}
                content={{
                    initialValuesSelector: (state) => {
                        const wizardEntity = // eslint-disable-next-line max-len
                            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                        const examinationReason = getPlanMedicalExaminationWizardReason(state);

                        return {
                            searchValue: (wizardEntity.searchEmployee && wizardEntity.searchEmployee.searchValue)
                                || '', // eslint-disable-next-line max-len
                            selectedEmployee: (wizardEntity.searchEmployee && wizardEntity.searchEmployee.selectedEmployee)
                                || null,
                            examinationReason,
                        };
                    },
                    renderStepContent: (renderProps) =>
                        <SearchEmployeeInput {...renderProps} />,
                    onChangeInputActionCreator: ({ values }) =>
                        updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>({
                            searchEmployee: values,
                        }),
                }}
                stepButtons={{
                    shouldDisableNextButton: (values) => !isEmployeeSelected(values),
                    disbaleGoToNextStepByDefault: true,
                    onConfirmActionCreator: ({ values }) => {
                        return validateEmployeeToPlanActions.trigger({
                            employeeToPlan: values.selectedEmployee,
                            examinationReason: values.examinationReason,
                            onValidationPassed: stepperStepRenderProps.goToNextStep,
                        });
                    },
                }}
            />
        </>
    );
}

export function isEmployeeSelected(values: IContentValues) {
    return isSet(values.selectedEmployee) && isSet(values.selectedEmployee.id);
}
