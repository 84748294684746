import React, { PureComponent } from 'react';
import { connect } from '../../..';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';
import { getPrivacyPolicyContent, getPrivacyPolicyContentAsyncInfo } from '../../../../redux/legal/selectors';
import { fetchPrivacyPolicyContentActions } from '../../../../redux/legal/actions';
import HeaderWithSidebarContent from '../../../common/widget/HeaderWithSidebarContent/index';
import PagePart from '../../../common/page/PagePart';
import { toAnchorTargetId } from '../../../../utils/core/string/anchor';
import { IHeaderPagePartData, PagePartType } from '../../../../models/general/pageParts';
import { slugifyStringAsId } from '../../../../utils/core/string/slugify';
import { isFalsyOrEmptyObject } from '../../../../utils/core/object/isEmptyObject';
import { ICmsPage } from '../../../../models/general/cms';

interface IPrivateProps {
    privacyPolicyContentAsyncInfo: IAsyncFieldInfo;
    privacyPolicyContent: ICmsPage;
    fetchPrivacyPolicyContent: () => void;
}

class PrivacyPolicyContent extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);
    }

    public componentDidMount() {
        const { privacyPolicyContentAsyncInfo, fetchPrivacyPolicyContent } = this.props;

        if (privacyPolicyContentAsyncInfo.status === AsyncStatus.Initial) {
            fetchPrivacyPolicyContent();
        }
    }

    public render() {
        const { privacyPolicyContent } = this.props;

        if (isFalsyOrEmptyObject(privacyPolicyContent)) {
            return null;
        }

        return (
            <>
                {privacyPolicyContent.page && privacyPolicyContent.page.title && (
                    <h1>{privacyPolicyContent.page.title}</h1>
                )}
                <HeaderWithSidebarContent
                    header={this.renderHeader()}
                    sidebar={this.renderSidebar()}
                    content={this.renderContent()}
                />
            </>
        );
    }

    private renderHeader() {
        const { legal_header: pageParts } = this.props.privacyPolicyContent.pagePartsByRegion;

        return pageParts.map((pagePart, index) => {
            const pagePartId = pagePart.anchorId
                ? toAnchorTargetId(pagePart.anchorId)
                : `pagePart-without-title-${index}`;

            return (
                <div
                    key={`privacy-policy-header-pagePart-${index}`}
                    id={pagePartId}
                >
                    <PagePart pagePart={pagePart} />
                </div>
            );
        });
    }

    private renderSidebar() {
        const { legal_main: pageParts } = this.props.privacyPolicyContent.pagePartsByRegion;
        const headerPageParts = pageParts.map((pagePart, index) => ({
                ...pagePart,
                anchor: (pagePart.anchorId
                    ? toAnchorTargetId(pagePart.anchorId)
                    : pagePart.type === PagePartType.HEADER
                        ? slugifyStringAsId((pagePart.data as IHeaderPagePartData).title)
                        : `pagePart-without-title-${index}`
                ),
            }))
            .filter((pagePart) =>
                pagePart.type === PagePartType.HEADER && (pagePart.data as IHeaderPagePartData).niv === 2);

        return (
            <ul className="">
                { headerPageParts.map((pagePart) => {
                    const headerData = pagePart.data as IHeaderPagePartData;
                    return (
                        <li key={pagePart.anchor}><a href={`#${pagePart.anchor}`}>{headerData.title}</a></li>
                    );
                })}
            </ul>
        );
    }

    private renderContent() {
        const { legal_main: pageParts } = this.props.privacyPolicyContent.pagePartsByRegion;

        return pageParts.map((pagePart, index) => {
            const pagePartId = pagePart.anchorId
                ? toAnchorTargetId(pagePart.anchorId)
                : pagePart.type === PagePartType.HEADER
                    ? slugifyStringAsId((pagePart.data as IHeaderPagePartData).title)
                    : `pagePart-without-title-${index}`;

            return (
                <div
                    key={`privacy-policy-main-pagePart-${index}`}
                    id={pagePartId}
                >
                    <PagePart pagePart={pagePart} />
                </div>
            );
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            privacyPolicyContent: getPrivacyPolicyContent(state),
            privacyPolicyContentAsyncInfo: getPrivacyPolicyContentAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            fetchPrivacyPolicyContent: () => dispatch(fetchPrivacyPolicyContentActions.trigger({})),
        };
    },
})(PrivacyPolicyContent);
